import * as React from 'react';
import { skeletonMonth } from '../../../../utils';
import '../../../../styles/elements/month/standard/index.scss';

const namespace = (): string => 'elements--month';

export interface Props {
};

const StandardMonthSkeleton: React.SFC<Props> = ({  }: Props): React.ReactElement<Props> => {
  const weeks = skeletonMonth;

  return (
    <div className={`${namespace()} skeleton`}>
      <div className={`${namespace()}--header`}>
        blank
      </div>
      <div className={`${namespace()}--week-days`}>
        <ul className={`${namespace()}--week-days--list`}>
          <li className={`${namespace()}--week-days--list--item`}></li>
          <li className={`${namespace()}--week-days--list--item`}></li>
          <li className={`${namespace()}--week-days--list--item`}></li>
          <li className={`${namespace()}--week-days--list--item`}></li>
          <li className={`${namespace()}--week-days--list--item`}></li>
          <li className={`${namespace()}--week-days--list--item`}></li>
          <li className={`${namespace()}--week-days--list--item`}></li>
        </ul>
      </div>
      <div className={`${namespace()}--weeks`}>
        <ul className={`${namespace()}--weeks--list`}>
          {weeks.map((week, index) => (
            <li key={index} className={`${namespace()}--weeks--list--item`}>
              <ul className={`${namespace()}--weeks--list--item--days`}>
                {week.map(day => (
                  <li
                    key={day.date}
                    className={`${namespace()}--weeks--list--item--days--day`}
                  >
                    {day.day}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StandardMonthSkeleton;
export { default as StandardMonthRangeSkeleton } from './Range';
