import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Month from './CompactMonthSkeleton';
import '../../../../styles/elements/month/compact/range.scss';
import {noop} from "../../../../utils";

export const namespace = (): string => 'elements--compact-month-range';

export interface Props {
  hidden?: boolean;
}


class CompactMonthRangeSkeleton extends React.Component<Props, {}> {
  public static contextTypes = {
    addTitle: PropTypes.func,
    addOnClickTitle: PropTypes.func,
    onScroll: PropTypes.func,
    addSubTitle: PropTypes.func,
    removeSubTitle: PropTypes.func,
  };

  public componentWillMount() {
    this.context.addTitle('');
    this.context.addOnClickTitle(noop);
  }

  public render() {
    const { hidden } = this.props;
    return (
      <div className={`${namespace()} ${hidden ? 'hidden' : ''} skeleton`}>
        <div className={`${namespace()}--title`}/>
        <div className={`${namespace()}--months`}>
          {Array.apply(null, Array(4)).map((value, index) => {
            const month = moment().startOf('month').add(index, 'months');
            return (
              <div className={`${namespace()}--months--month`} key={index}>
                <div className={`${namespace()}--months--month--header`}>
                  <div
                    className={`${namespace()}--months--month--header--text`}
                  >
                  </div>
                </div>
                <Month/>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CompactMonthRangeSkeleton;
