import * as M from "../../../constants/messages/generic";
import {EMAIL_MATCH} from "../../../constants/messages/createAccount";
import { Validator } from "../../../utils/validator/models";

export interface IValidator {
  Email: Validator;
  ConfirmEmail: Validator;
}

export const FormDefinition: any = {
  Email: {
    key: 'Email',
    equalityDependant: 'ConfirmEmail',
    validatejs: {
      Email: {
        presence: {message: M.S_REQUIRED('New Email')},
        email: {message: M.S_INVALID('New Email')},
        length: {
          minimum: 3,
          maximum: 50,
          tooLong: M.S_TOO_LONG('New Email', 50),
          tooShort: M.S_TOO_SHORT('New Email', 3)
        }
      }
    }
  },
  ConfirmEmail: {
    key: 'ConfirmEmail',
    validatejs: {
      ConfirmEmail: {
        presence: {message: M.S_REQUIRED('Confirm Email')},
        email: {message: M.S_INVALID('Confirm Email')},
        equality: {
          attribute: "Email",
          message: '^' + EMAIL_MATCH
        },
        length: {
          minimum: 3,
          maximum: 50,
          tooLong: M.S_TOO_LONG('Confirm Email', 50),
          tooShort: M.S_TOO_SHORT('Confirm Email', 3)
        }
      }
    }
  }
};
