import * as React from 'react';
import '../../../styles/elements/class-list/index.scss';
import {namespace} from "./constants";

export interface ClassListProps {
  children?: React.ReactNode;
}

const ClassList: React.SFC<ClassListProps> = ({ children }: ClassListProps): React.ReactElement<ClassListProps> => (
  <ul className={namespace()}>
    {children}
  </ul>
);

export default ClassList;
export { default as ClassListItem } from './Item';
