import { ToggleNotFound } from "../../store/App/actions";
import { SaveState } from "../../store/Rollback/actions";
import { extractRouteParams, isIdFromUrlValid, isIdValid } from "../urlHelper";
import { GetFacilityType } from "../../store/AdminFacilityLocation/CacheTwoFacilityTypes/actions";
import { UpdateFacilityTypeSubmitActions } from "../../store/AdminFacilityLocation/Facilities/FacilityType/Form/actions";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import { reduxStoreService } from "../../store/service";
import type AdminFacilitiesLocationCacheManager from "../cacheManagers/adminFacilitiesLocationCacheManager";
import { LoadAdminFacilityCacheTwoFacilityTypeParams } from "./helpers/models";
import {isAdminFacilityLocationCacheTwoFacilityTypePopulated} from "../cachePopulatedCheckers/adminFacilities";

export const ensureAdminFacilityLocationCacheTwoFacilityType = (
  params: LoadAdminFacilityCacheTwoFacilityTypeParams & {
    cacheManager: AdminFacilitiesLocationCacheManager;
    facilityTypeId: number;
  },
): boolean => {
  const { props, isEdit, cacheManager, facilityTypeId, isStateNavigated } = params;

  if (!isIdFromUrlValid(props.params.locationId)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const rootState = reduxStoreService().getState();
  const adminFacilityLocationCacheTwoFacilityTypes = rootState.adminFacilityLocation.cacheTwoFacilityTypes;

  let requestedData = false;
  if (isEdit) {
    if (!isIdValid(facilityTypeId)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }

    if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminFacilityLocationCacheTwoFacilityTypePopulated(adminFacilityLocationCacheTwoFacilityTypes)) {
      if (isStateNavigated) {
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      const params = {
        ID: facilityTypeId,
        ...extractRouteParams(props),
      };
      reduxStoreService().dispatch(GetFacilityType.request(params));

      requestedData = true;
    }
  } else if (isStateNavigated) {
    requestedData = cacheManager.loadAdminFacilitiesLocationCacheOne({
      props,
      isStateNavigated,
    });

    if (!requestedData) {
      reduxStoreService().dispatch(UpdateFacilityTypeSubmitActions.updateForm());
      reduxStoreService().dispatch(new SaveState());
    }
  }

  return requestedData;
};