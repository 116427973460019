import {SaveState} from "../../store/Rollback/actions";
import {EVENT, eventNumbersInitBody, E_REGISTER} from "../../constants/urls";
import {replace} from "react-router-redux";
import { shouldCloseWizardOnLoad } from "./helpers/blockers";
import { getGroupID } from "../helpers/endUserPageHelper";
import { EventNumbersCalc } from "../../store/CacheFourEventsNumbers/actions";
import { reduxStoreService } from "../../store/service";
import { LoadEndUserCacheFourEventNumbersParams } from "./helpers/models";
import {isCacheFourEventsNumbersPopulated, isCacheThreeEventsPopulated, isEndUserCacheOnePopulated, isEndUserCacheTwoEventsPopulated} from "../cachePopulatedCheckers/endUser";

function redirectToEventRegistration(currentPath: string, currentQuery: string) {
  // Derive `END_USER_EVENT_REGISTRATION_PATH` from `NUMBERS_WIZARD_PATH`
  const newPath = currentPath.replace(`${EVENT.REGISTER}/${E_REGISTER.NUMBERS}`, EVENT.REGISTRATION);
  reduxStoreService().dispatch(replace({
    pathname: newPath,
    query: currentQuery,
  }));
}

export const ensureCacheFourEventsNumbers = (
  params: LoadEndUserCacheFourEventNumbersParams
): boolean => {
  const {props, isStateNavigated} = params;
  if (shouldCloseWizardOnLoad(props.location)) {
    redirectToEventRegistration(props.location.pathname, props.location.query);
    return false;
  }

  const rootState = reduxStoreService().getState();
  const {cacheZero, cacheOne, cacheTwoEvents, cacheThreeEvents, cacheFourEventsNumbers} = rootState;

  /**
   * NOTE: cache four loaders will never get triggered on direct visit,
   * and can only get triggered by in-app navigation. That's said, we
   * can assume these ids from urls are already validated from parent caches
   */
  const eventTypeId = Number(props.params.eventTypeId);
  const eventId = Number(props.params.eventId);

  let requestedData = false;
  if (isCacheFourEventsNumbersPopulated(cacheFourEventsNumbers)) {
    if (isStateNavigated) {
      reduxStoreService().dispatch(new SaveState());
    }
  } else {
    const groupID = getGroupID(props);
    if (
      !groupID ||
      !cacheZero.options ||
      !cacheZero.options.Group ||
      !isEndUserCacheOnePopulated(cacheOne) ||
      !isEndUserCacheTwoEventsPopulated(cacheTwoEvents) ||
      !isCacheThreeEventsPopulated(cacheThreeEvents)
    ) {
      const route = reduxStoreService().getState().routing.locationBeforeTransitions;
      redirectToEventRegistration(route.pathname, route.query);
      return false;
    } else {
      reduxStoreService().dispatch(
        EventNumbersCalc.request(
          eventNumbersInitBody({
            GroupIDi: groupID,
            GroupTS: cacheZero.options.Group.TS,
            EventTypeID: eventTypeId,
            EventIDi: eventId,
            ParticipantTypes: !cacheThreeEvents.EventInfoParticipantTypes ? [] : cacheThreeEvents.EventInfoParticipantTypes.map((pType) => pType.ID),
          }),
        )
      );

      requestedData = true;
    }
  }

  return requestedData;
};