import { SaveState } from "../../store/Rollback/actions";
import { GetEventTypeData } from "../../store/CacheTwoEvents/actions";
import { ToggleNotFound } from "../../store/App/actions";
import { getEventTypeDataBody } from "../../constants/urls";
import { getGroupID } from "../helpers/endUserPageHelper";
import { makeSelectedEventTypeSelector } from "../../store/CacheTwoEvents";
import { isIdFromUrlValid, isIdValid } from "../urlHelper";
import { captureTentarooError, captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from "../dataHelper";
import { reduxStoreService } from "../../store/service";
import { LoadEndUserCacheTwoEventParams } from "./helpers/models";
import {isCacheZeroPopulated, isEndUserCacheOnePopulated, isEndUserCacheTwoEventsPopulated} from "../cachePopulatedCheckers/endUser";

export const ensureCacheTwoEvents = (
  params: LoadEndUserCacheTwoEventParams,
): boolean => {
  const { props, isStateNavigated } = params;

  const groupID = getGroupID(props);
  if (!isIdValid(groupID) || !isIdFromUrlValid(props.params.id)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }
  const selectedEventTypeSelector = makeSelectedEventTypeSelector();
  const rootState = reduxStoreService().getState();
  const selectedEventType = selectedEventTypeSelector(rootState);

  const { cacheTwoEvents, cacheZero, cacheOne } = rootState;

  let requestedData = false;
  if (isEndUserCacheTwoEventsPopulated(cacheTwoEvents) && !selectedEventType) {
    if (isStateNavigated) {
      reduxStoreService().dispatch(new SaveState());
    }
  } else if (!isEndUserCacheTwoEventsPopulated(cacheTwoEvents)) {
    if (!isCacheZeroPopulated(cacheZero)) {
      // Came from page load, need everything
      reduxStoreService().dispatch(GetEventTypeData.request({
        EventTypeID: Number(props.params.id),
        GroupIDi: groupID,
        GroupTS: 0,
        GetOptions: true,
        GetGroupData: true,
      }));

      requestedData = true;
    } else if (
      isCacheZeroPopulated(cacheZero) &&
      (!cacheZero.options!.Group || !isEndUserCacheOnePopulated(cacheOne))
    ) {
      reduxStoreService().dispatch(GetEventTypeData.request({
        EventTypeID: Number(props.params.id),
        GroupIDi: groupID,
        GroupTS: 0,
        GetOptions: false,
        GetGroupData: true,
      }));

      requestedData = true;
    } else {
      if (!cacheZero.options!.Group) {
        captureTentarooError(new Error("Group not available when dispatching GetEventTypeData in cacheTwoEventsLoader"));
        return false;
      }
      reduxStoreService().dispatch(GetEventTypeData.request({
        EventTypeID: Number(props.params.id),
        GroupIDi: cacheZero.options!.Group.IDi,
        GroupTS: cacheZero.options!.Group.TS,
        GetOptions: false,
        GetGroupData: false,
      }));

      requestedData = true;
    }
  } else if (isStateNavigated) {
    reduxStoreService().dispatch(new SaveState());
  }

  return requestedData;
};

export const getCacheTwoEventsEmptyCartBody = (params: { id: string }) => {
  const rootState = reduxStoreService().getState();

  let debugPayload: TentarooDebugPayload | undefined;

  if (!isIdFromUrlValid(params.id)) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Invalid id in getCacheTwoEventsEmptyCartBody");
  }

  const eventTypeId = Number(params.id);

  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when getCacheTwoEventsEmptyCartBody");
  }
  if (!rootState.cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when getCacheTwoEventsEmptyCartBody");
  }
  const body: any = getEventTypeDataBody({
    EventTypeID: eventTypeId,
    GroupIDi: rootState.cacheZero.options?.Group?.IDi as any,
    GroupTS: rootState.cacheZero.options?.Group?.TS as any,
    GetOptions: false,
    GetGroupData: true
  });
  body.AppState.EmptyCart = true;

  if (debugPayload) {
    return withTentarooDebugPayload(body, debugPayload);
  } else {
    return body;
  }
};
