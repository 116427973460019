import {makeRequestEpic} from "../../../../../Validation/epicCreator";
import {eventParticipantsInit, updateGroupRosterPerson} from "../../../../../../constants/urls";
import {ApiDeleteRosterActions, ApiRosterSubmitActions} from "./actions";
import {DELETE_ROSTER_SUCCESS} from "../../../../../../constants/messages/participants";

export const participantsRosterNext = makeRequestEpic(
  ApiRosterSubmitActions,
  eventParticipantsInit,
  undefined, // body is passed in as is in action.value
  true
);

export const participantDeleteRoster = makeRequestEpic(
  ApiDeleteRosterActions,
  updateGroupRosterPerson,
  undefined,
  true,
  () => DELETE_ROSTER_SUCCESS
);
