import { RouteComponentProps } from "react-router";
import type { ApplicationState } from "../../store";
import { QP_ACCOUNT, ROOT, URLS, getFacilitiesHomeRootUrl, getFacilitiesLocationSettingsHomeRootUrl, getPagesRootUrl, isAdminCMSSitePage, isAdminEventsPage, isAdminFacilityLocationPage, isAdminPage, isAuthenticatedPage, isEndUserPage } from "../../constants/urls";
import { reduxStoreService } from "../../store/service";
import { LoadingAllAction, SetAfterLoginPath, StopLoadingAllAction, ToggleNotFound } from "../../store/App/actions";
import { navReplace } from "../../utils";
import { ADMIN_CMS_PAGE_PATH, ADMIN_EVENTS_EVENTS_PATH, ADMIN_EVENTS_SETTINGS_PATH, ADMIN_EVENT_DASHBOARD_PATH, ADMIN_FACILITY_LOCATION_FACILITY_EDIT_PATH } from "../../routes";
import { getAdminEventsSettingsHomeRootUrl, getEventsHomeRootUrl } from "../../constants/adminEventsUrls";
import { isPathnameMatchingRoute, shouldTryToLoadDataWithId } from "../../utils/urlHelper";
import { getEventTypeId } from "../../utils/helpers/adminEventsPageHelper";
import { getFacilityLocationId } from "../../utils/helpers/adminFacilityLocationPageHelper";
import { getSiteID } from "../../utils/helpers/adminCMSPageHelper";
import {isCacheZeroPopulated} from "../../utils/cachePopulatedCheckers/endUser";
import {isAdminEventsCacheOnePopulated} from "../../utils/cachePopulatedCheckers/adminEvents";
import {isAdminCMSCacheOnePopulated} from "../../utils/cachePopulatedCheckers/adminCMS";
import {isAdminFacilitiesLocationCacheOnePopulated} from "../../utils/cachePopulatedCheckers/adminFacilities";

export const toggleLoadingAllIfNeeded = (rootState: ApplicationState, componentProps: RouteComponentProps<any, {}>) => {
  // Note that we are not using any cacheManager here just to avoid circular import
  const inAdminCMSButCacheOneNotPopulated = (
    isAdminCMSSitePage(componentProps.location.pathname) &&
    !isAdminCMSCacheOnePopulated(rootState.adminCMSSite.cacheOne)
  );
  const inAdminCMSAndCacheOnePopulated = (
    isAdminCMSSitePage(componentProps.location.pathname) &&
    isAdminCMSCacheOnePopulated(rootState.adminCMSSite.cacheOne)
  );
  const inAdminFacilitiesButCacheOneNotPopulated = (
    isAdminFacilityLocationPage(componentProps.location.pathname) &&
    !isAdminFacilitiesLocationCacheOnePopulated(rootState.adminFacilityLocation.cacheOne)
  );
  const inAdminFacilitiesAndCacheOnePopulated = (
    isAdminFacilityLocationPage(componentProps.location.pathname) &&
    isAdminFacilitiesLocationCacheOnePopulated(rootState.adminFacilityLocation.cacheOne)
  );
  const inAdminEventsButCacheOneNotPopulated = (
    isAdminEventsPage(componentProps.location.pathname) &&
    !isAdminEventsCacheOnePopulated(rootState.adminEvents.cacheOne)
  );
  const inAdminEventsAndCacheOnePopulated = (
    isAdminEventsPage(componentProps.location.pathname) &&
    isAdminEventsCacheOnePopulated(rootState.adminEvents.cacheOne)
  );
  const inEndUserButGroupNotPopulated = (
    isEndUserPage(componentProps.location.pathname) &&
    (!rootState.cacheZero.options || !rootState.cacheZero.options.Group)
    // Object.keys(rootState.cacheOne).length <= MIN_REQUIRED_FIELDS_CACHE_ONE
  );
  const inEndUserAndGroupPopulated = (
    isEndUserPage(componentProps.location.pathname) &&
    (rootState.cacheZero.options && rootState.cacheZero.options.Group)
    // Object.keys(rootState.cacheOne).length > MIN_REQUIRED_FIELDS_CACHE_ONE
  );
  const inAddGroupButCacheZeroNotPopulated = (
    componentProps.location.pathname === URLS.ADD_GROUP &&
    !isCacheZeroPopulated(rootState.cacheZero)
  );
  const inAddGroupAndCacheZeroPopulated = (
    componentProps.location.pathname === URLS.ADD_GROUP &&
    isCacheZeroPopulated(rootState.cacheZero)
  );
  const inUnauthenticatedPagesButSystemSettingsNotPopulated = (
    !isAuthenticatedPage(componentProps.location.pathname) &&
    !rootState.session.SystemSettings
  );
  const inUnauthenticatedPagesAndSystemSettingsPopulated = (
    !isAuthenticatedPage(componentProps.location.pathname) &&
    !!rootState.session.SystemSettings
  );

  if (!rootState.app.loadingAll) {
    if (
      inAdminCMSButCacheOneNotPopulated ||
      inAdminFacilitiesButCacheOneNotPopulated ||
      inAdminEventsButCacheOneNotPopulated ||
      inEndUserButGroupNotPopulated ||
      inAddGroupButCacheZeroNotPopulated ||
      inUnauthenticatedPagesButSystemSettingsNotPopulated
    ) {
      reduxStoreService().dispatch(new LoadingAllAction());
    }
  } else {
    if (
      inAdminCMSAndCacheOnePopulated ||
      inAdminFacilitiesAndCacheOnePopulated ||
      inAdminEventsAndCacheOnePopulated ||
      inEndUserAndGroupPopulated ||
      inAddGroupAndCacheZeroPopulated ||
      inUnauthenticatedPagesAndSystemSettingsPopulated
    ) {
      reduxStoreService().dispatch(new StopLoadingAllAction());
    }
  }
};

export const handleGeneralRedirection = (rootState: ApplicationState, componentProps: RouteComponentProps<any, {}> | undefined) => {
  const userState = rootState.user.user;

  if (componentProps) {
    // This block is moved from `App.tsx`, and should be run BEFORE any cache loader
    // If any redirect is happening, should return and skip the actual cache loader run
    const apiSaving = rootState.app.apiSaving;
    const apiLoading = rootState.app.apiLoading;
    const locationPathname = componentProps.location.pathname;
    const router = componentProps.router;
    const routes = componentProps.routes;
    const route = routes[routes.length - 1];

    if (apiLoading !== 0 || apiSaving !== 0) return true;

    /* Redirection based on userType and the target page */
    if (
      locationPathname !== URLS.LOGIN && locationPathname !== URLS.NEW_ACCOUNT && 
      (!userState || userState.IDi <= 0)
    ) {
      // If I'm on an authenticated page and I haven't logged in, send me back to login page
      if (componentProps.location.query && componentProps.location.query.ResetCode) {
        navReplace(router, URLS.LOGIN, undefined, {pathname: URLS.LOGIN, query: componentProps.location.query});
      } else {
        navReplace(router, URLS.LOGIN);
      }
      return false;
    } else if (
      (locationPathname === URLS.LOGIN || locationPathname === URLS.NEW_ACCOUNT) &&
      (userState && userState.IDi > 0)
    ) {
      const { afterLoginPath, afterLoginAccount } = reduxStoreService().getState().app;

      if (!afterLoginPath || !afterLoginPath.startsWith(ROOT)) {
        // If logged in but visiting unauthenticated pages, and there's no
        // `afterLoginPath` configured, redirect to default page accordingly.
        if (userState.userType === 'admin') {
          navReplace(router, URLS.ADD_GROUP);
        } else {
          navReplace(router, URLS.HOME);
        }
      } else {
        reduxStoreService().dispatch(new SetAfterLoginPath(undefined, undefined));
        /**
         * When there is an `afterLoginPath`
         *   - if current user is NOT an admin user, only proceed redirect to `afterLoginPath` when it
         *     is not an admin page; othwerwise, fallback to HOME page
         *   - if current user is an admin user
         *     - If `afterLoginAccount` is configured, make sure to navigate to page with account query in URL
         *     - Otherwise, always navigate to `afterLoginPath`, because admin user can navigate to any page
         */
        if (userState.userType === "group") {
          if (isAdminPage(afterLoginPath)) {
            navReplace(router, URLS.HOME);
          } else {
            navReplace(router, afterLoginPath);
          }
        } else if (userState.userType === 'admin') {
          if (afterLoginAccount) {
            navReplace(router, afterLoginPath, undefined, {
              pathname: afterLoginPath,
              query: {
                [QP_ACCOUNT]: afterLoginAccount,
              },
            });
          } else {
            if (isEndUserPage(afterLoginPath)) {
              // If navigating to end user page without `afterLoginAccount`, navigate to addGroup page
              navReplace(router, URLS.ADD_GROUP);
            } else {
              navReplace(router, afterLoginPath);
            }
          }
        }
      }
      return false;
    } else if (
      isAdminPage(locationPathname) && userState && userState.IDi > 0 &&
      userState.userType === "group"
    ) {
      // If logged in as a Group user but trying to visit admin page, redirect back to end user's home page
      navReplace(router, URLS.HOME);
      return false;
    } else if (
      !!rootState.routing.locationBeforeTransitions.query[QP_ACCOUNT] &&
      userState && userState.IDi > 0 &&
      userState.userType === "group"
    ) {
      // If logged in as a Group user, but trying to visit an end user page with `QP_ACCOUNT` query in url, remove that query.
      // This is the same condition in `loginForm` epic when handling the nextUrl
      navReplace(router, rootState.routing.locationBeforeTransitions.pathname);
      return false;
    } else if (
      (
        !rootState.routing.locationBeforeTransitions.query[QP_ACCOUNT] ||
        isNaN(Number(rootState.routing.locationBeforeTransitions.query[QP_ACCOUNT]))
      ) &&
      userState && userState.IDi > 0 && userState.userType === "admin" && 
      isEndUserPage(locationPathname) 
    ) {
      // If logged in as an Admin, but trying to visit an end user page without `QP_ACCOUNT` query in url, redirect to ADD_GROUP
      navReplace(router, URLS.ADD_GROUP);
      return false;
    }

    /* Redirection if admin event/facility is inactive */
    const EventsEventType = rootState.adminEvents.cacheOne.EventsEventType;

    if (
      isAdminEventsPage(locationPathname) &&
      route.path !== ADMIN_EVENTS_SETTINGS_PATH &&
      EventsEventType && EventsEventType.EventTypeRow.Inactive &&
      Number(componentProps.params.eventTypeId) === EventsEventType.EventTypeRow.ID
    ) {
      navReplace(router, getAdminEventsSettingsHomeRootUrl({
        eventTypeId: EventsEventType.EventTypeRow.ID,
        eventTypeName: EventsEventType.EventTypeRow.Name,
      }));
      return false;
    }

    const FacilitiesLocation = rootState.adminFacilityLocation.cacheOne.FacilitiesLocation;
    if (
      isAdminFacilityLocationPage(locationPathname) &&
      FacilitiesLocation && FacilitiesLocation.Inactive &&
      Number(componentProps.params.locationId) === FacilitiesLocation.ID
    ) {
      navReplace(router, getFacilitiesLocationSettingsHomeRootUrl({
        locationId: FacilitiesLocation.ID,
        locationName: FacilitiesLocation.Name,
      }));
      return false;
    }
  }

  return true;
};


/**
 * Handle redirection logic for optimistic save: if id is negative and
 * optimistic save data is not there, we should redirect. This should guard
 * - AdminEventType Add/Duplicate
 * - AdminEvent Add
 * - AdminFacility Add
 * - AdminPage Add/Duplicate
 * 
 * @returns {false} if any needed id is negative, and hence should stop downstream loaders from running
 */
export const handleOptimisticSaveRedirection = (rootState: ApplicationState, componentProps: RouteComponentProps<any, {}> | undefined) => {
  if (componentProps) {
    const {params, location, router} = componentProps;
    if (isPathnameMatchingRoute(location.pathname, ADMIN_EVENTS_EVENTS_PATH)) {
      if (shouldTryToLoadDataWithId(params.eventTypeId, -2)) {
        return true;
      } else {
        const EventsEventType = rootState.adminEvents.cacheOne.EventsEventType;

        // when eventType is negative but data needed for optimistically load is not there,
        // show page not found
        if (!EventsEventType) reduxStoreService().dispatch(new ToggleNotFound());

        // Stop loaders when optimistic save
        return false;
      }
    }
      
    if (isPathnameMatchingRoute(location.pathname, ADMIN_EVENT_DASHBOARD_PATH)) {
      if (shouldTryToLoadDataWithId(params.eventId, -1)) {
        return true;
      } else {
        const adminEventsCacheTwoEvent = rootState.adminEvents.cacheTwoEvent;
        const eventInfo = adminEventsCacheTwoEvent.EventsEvent;

        // when eventId is negative but data needed for optimistically load is not there,
        // redirect back to list page. Fallback to root if cacheOne is not loaded yet
        if (!eventInfo) {
          const eventTypeId = getEventTypeId(componentProps);
          const redirectUrl = eventTypeId ? getEventsHomeRootUrl({
            eventTypeId,
            eventTypeName: 'AdminEventType',
          }) : '/admin2';

          navReplace(router, redirectUrl);
        }

        // Stop loaders when optimistic save
        return false;
      }
    }

    if (isPathnameMatchingRoute(location.pathname, ADMIN_FACILITY_LOCATION_FACILITY_EDIT_PATH)) {
      if (shouldTryToLoadDataWithId(params.facilityId, -1)) {
        return true;
      } else {
        const adminFacilityLocationCacheTwoFacility = rootState.adminFacilityLocation.cacheTwoFacility;
        // when facilityId is negative but data needed for optimistically load is not there,
        // redirect back to list page
        const facilityInfo = adminFacilityLocationCacheTwoFacility.FacilitiesFacility;
        // fall back to root
        if (!facilityInfo) {
          const locationId = getFacilityLocationId(componentProps);
          const redirectUrl = locationId ? getFacilitiesHomeRootUrl({
            locationId,
            locationName: "AdminFacilityLocation"
          }) : '/admin2';

          navReplace(router, redirectUrl);
        }

        // Stop loaders when optimistic save
        return false;
      }
    }
    
    if (isPathnameMatchingRoute(location.pathname, ADMIN_CMS_PAGE_PATH)) {
      if (shouldTryToLoadDataWithId(params.pageId, -2)) {
        return true;
      } else {
        const adminCMSCacheTwoPage = rootState.adminCMSSite.cacheTwoPage;
        // when pageId is negative but data needed for optimistically load is not there,
        // redirect back to list page
        const pageInfo = adminCMSCacheTwoPage.CMSPage;
        // fall back to root
        if (!pageInfo) {
          const siteId = getSiteID(componentProps);
          const redirectUrl = siteId ? getPagesRootUrl({
            siteId,
            domain: "AdminCMS",
          }) : '/admin2';

          navReplace(router, redirectUrl);
        }

        // Always stop loaders when id is negative
        return false;
      }
    }
  }

  return true;
};