import { createSelector } from "reselect";

import type { ApplicationState } from "../../../..";

import { AdminEventChildClass, AdminEventClass } from "../../../../../models/api/adminEventsCacheTwoEvent";
import { ClassType } from "../../../../../models/api/options";
import { notEmptyFilter } from "../../../../../utils/dataHelper";

import { listSelector } from "../../../../../utils/reselectHelper";
import { sortByName, sortByOrd } from "../../../../CacheZero";
import { classTypeSelector } from "../../../../CacheZero/selectors";
import { reduxStoreService } from "../../../../service";


export const allClassTypesSelector = (state: ApplicationState) => state.cacheZero.options ? state.cacheZero.options.ClassTypes || [] : [];
const adminEventClassesSelector = listSelector(
  (state: ApplicationState) => state.adminEvents.cacheTwoEvent.EventsEventClasses || [],
);
const showDeletedSelector = (state: ApplicationState) => state.adminEvents.events.event.classes.classesTab.ActiveForm.ShowDeleted;
const filteredTextSelector = (state: ApplicationState) => state.adminEvents.events.event.classes.classesTab.ActiveForm.FilterText;
const kindOfClassSelector = (state: ApplicationState) => state.adminEvents.events.event.classes.classesTab.ActiveForm.KindOfClass;
const registerUnderSelector = (state: ApplicationState) => state.adminEvents.events.event.classes.classesTab.ActiveForm.RegisterUnder;
export const filteredAdminEventClassesCombiner = (
  classes: AdminEventClass[],
  showDeleted: boolean,
  filteredText: string,
  kindOfClass: number,
  registerUnder: number,
  allClassTypes: ClassType[],
  selectedClassIds?: number[],
): AdminEventClass[] => {
  if (!classes) return [];
  const mapped = classes.map((c) => {
    let ComboClasses: AdminEventChildClass[] | null = null;
    
    if (c.IsCombo && c.ComboClasses) {
      ComboClasses = c.ComboClasses.map((comboClass) => {
        const classOption = allClassTypes.find((o) => o.IDi === comboClass.ClassTypeIDi);
  
        if (classOption) {
          return {
            ...classOption,
            IDi: comboClass.IDi,
            ClassTypeIDi: comboClass.ClassTypeIDi,
            mbID: comboClass.mbID,
          };
        }
  
        return classOption;
      }).filter(notEmptyFilter);
      ComboClasses = ComboClasses.sort(sortByName);
    }
    
    return {
      ...c,
      ComboClasses,
    };
  });
  const filtered = mapped.filter((c) => {
    const optionMatches = kindOfClass === 0 || (kindOfClass === 1 && !c.IsOption) || (kindOfClass === 2 && c.IsOption);
    const registerUnderMatches = registerUnder === 0 || (registerUnder === 1 && !c.IsRegisterUnderIndividuals) || (registerUnder === 2 && c.IsRegisterUnderIndividuals);
    const showDeletedMatch = showDeleted || !c.Inactive;
    let nameMatches;

    if (!filteredText) nameMatches = true;
    else {
      const f = filteredText.toLowerCase();
      const classType = classTypeSelector(reduxStoreService().getState(),  c.ClassTypeIDi);
      if (!c.IsCombo && classType) {
        nameMatches = (`${c.ClassCode ? c.ClassCode + ' - ' : ''}` + classType.Name).toLowerCase().includes(f);
      } else if (c.IsCombo && c.ComboClasses) {
        nameMatches = (`${c.ClassCode ? c.ClassCode + ' - ' : ''}` + c.ComboName).toLowerCase().includes(f);

        c.ComboClasses.forEach((comboClass) => {
          nameMatches = nameMatches || (!!comboClass && comboClass.Name.toLowerCase().includes(f));
        });
      }
    }

    return optionMatches && registerUnderMatches && showDeletedMatch && nameMatches;
  });

  const sorted = filtered.sort(sortByOrd);

  if (selectedClassIds) {
    return sorted.map((c) => {
      const existed = selectedClassIds.findIndex((cId) => cId === c.IDi);
      return {...c, selected: existed !== -1};
    });
  }

  return sorted;
};
export const makeFilteredAdminEventClassesSelector = () => {
  return createSelector(
    [adminEventClassesSelector, showDeletedSelector, filteredTextSelector, kindOfClassSelector, registerUnderSelector, allClassTypesSelector],
    filteredAdminEventClassesCombiner,
  );
};

export const makeActiveAdminEventClassesSelector = () => {
  return createSelector(
    [adminEventClassesSelector],
    (classes: AdminEventClass[]) => {
      if (!classes) return [];
      return classes.filter((c) => !c.Inactive);
    }
  );
};