import * as React from 'react';
import {RosterItem} from '../../../../../../Elements';
import ContextMenu from './ContextMenu';
import {GetGroupRosterPersonParams} from "../../../../../../../constants/urls";
import {SimplePerson} from "../../../../../../../store/Events/Event/Register/Participant/Roster";

export interface RosterItemProps {
  children?: React.ReactNode;
  person: SimplePerson
  GroupIDi: number;
  GroupTS: number;
  onSelect: (params: GetGroupRosterPersonParams) => void;
  onDelete: ((person: SimplePerson) => void)
}

class RosterItemComponent extends React.Component<RosterItemProps,{}> {
  props: RosterItemProps;

  onDelete = () => {
    this.props.onDelete(this.props.person);
  };

  render () {
    const {children, person, onSelect, GroupIDi, GroupTS} = this.props;

    return <RosterItem
      Name={person.Name}
      IDi={person.PersonIDi}
      isYouth={person.IsYouth}
      contextMenu={<ContextMenu personIDi={person.PersonIDi} onDelete={this.onDelete}/>}
      onClick={() => onSelect({
        PersonIDi: person.PersonIDi,
        GroupIDi: GroupIDi,
        GroupTS: GroupTS,
        IsYouth: person.IsYouth
      })}
    >
      {person.Name}
    </RosterItem>;
  }
}

export default RosterItemComponent;
