import { typeName, Action } from '../../../../utils/StrongActions';
import { ActionCreator } from '../../../index';
import {ReservationPage} from "./index";

@typeName("FACILITIES_RESERVATION_SELECT_PAGE") export class FacilitiesReservationSelectPage extends Action {
  constructor(public selectedPage: ReservationPage) { super(); }
}
@typeName("FACILITIES_RESERVATION_RESET") export class FacilitiesReservationReset extends Action {}

@typeName("FACILITIES_RESERVATION_SET_PENDING_PAGE")
export class FacilitiesReservationSetPendingPage extends Action {
  constructor(public pendingPage?: ReservationPage) { super(); }
}
@typeName("FACILITIES_RESERVATION_TRANSITION_TO_PENDING")
export class FacilitiesReservationTransitionToPending extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  selectPage: (selectedPage: ReservationPage): ActionCreator => dispatch => dispatch(new FacilitiesReservationSelectPage(selectedPage)),
  reset: (): ActionCreator => dispatch => dispatch(new FacilitiesReservationReset()),
  setPendingPage: (pendingPage?: ReservationPage): ActionCreator => dispatch => dispatch(new FacilitiesReservationSetPendingPage(pendingPage)),
  transitionToPending: (): ActionCreator => dispatch => dispatch(new FacilitiesReservationTransitionToPending()),
};
