import * as React from 'react';
import {
  Modal, ModalHeader, ModalContent, SimpleList, Row, SimpleListElement
} from '../../../../Elements';
import { withRouter, RouteComponentProps } from 'react-router';
import {actionCreators as appActionCreators} from '../../../../../store/App/actions';
import { bindActionCreators } from 'redux';
import '../../../../../styles/pages/admin/modals/cms-sites/index.scss';
import {makeCMSSitesSelector} from "../../../../../store/CacheZero/index";
import {CMSSite} from "../../../../../models/api/options";
import { getPagesRootUrl } from '../../../../../constants/urls';
import { ApplicationState } from '../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { GetSiteCache } from '../../../../../store/AdminCMSSite/CacheOne/actions';
import { extractRouteParams } from '../../../../../utils/urlHelper';
import { SaveState } from '../../../../../store/Rollback/actions';
import { navPush } from '../../../../../utils';
import { reduxStoreService } from '../../../../../store/service';
import {WithInertAttribute} from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin--modals--cms-sites';

type Props = WithInertAttribute<{}>;
type ConnectedProps = 
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

@(withRouter as any)
class AdminCMSSiteModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  onClickElement = (index, key, site: CMSSite) => {
    const {cacheOne, router} = this.props;

    reduxStoreService().dispatch(new SaveState());
    navPush(
      router,
      getPagesRootUrl({
        siteId: site.ID,
        domain: site.SiteDomain,
      }),
    );
    if (cacheOne.CMSSite && cacheOne.CMSSite.ID === site.ID) {
      reduxStoreService().dispatch(GetSiteCache.request({...extractRouteParams(this.props)}));
    }
  };

  itemRenderer = (index) => {
    if (!this.props.sites || this.props.sites.length === 0) return null;
    const site: CMSSite = this.props.sites[index];
    return (<SimpleListElement key={index} index={index} itemValue={site} label={site.SiteDomain} onClick={this.onClickElement}/>);
  };

  public render() {
    const {sites, inert} = this.props;
    return (
      <Modal inert={inert} big mobileFullScreen className={namespace()}>
        <ModalHeader className={`${namespace()}--header`}>
          Websites
        </ModalHeader>
        <ModalContent paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0}>
          {sites && <Row className={`${namespace()}--row-1`}>
            <SimpleList itemRenderer={this.itemRenderer} totalLength={sites.length}/>
          </Row>}
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const sitesSelector = makeCMSSitesSelector();
  return {
    cacheZero: state.cacheZero,
    cacheOne: state.adminCMSSite.cacheOne,
    sites: sitesSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({ ...appActionCreators}, dispatch) });

const ConnectedAdminCMSSiteModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(AdminCMSSiteModal);

export default ConnectedAdminCMSSiteModal;
