import * as React from 'react';
import { Main, MainContent } from '../../../Layouts';
import { ContactCard, ContentBlock, Media, PageLoader, Tab, Tabs, Title, Alert, Row, Loader } from '../../../Elements';
import CampInfo from '../CampInfo';
import Sidebar from '../Sidebar';
import CheckDates from './CheckDates';
import { RouteComponentProps, withRouter } from "react-router";
import {
  makeCurrentTripSelector,
  makeNoFilterCurrentTripSelector,
  makeNoFilterPastTripSelector,
  makePastTripSelector
} from "../../../../store/Facilities/Location/index";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../../../store/Facilities/Location/actions";
import { actionCreators as rollbackActionCreators } from "../../../../store/Rollback/actions";
import { getFullAddress, spaceTo_ } from '../../../../utils';
import { navPush } from "../../../../utils/navHelper";
import { URLS } from "../../../../constants/urls";
import {
  actionCreators as cacheTwoFacilitiesActionCreators,
  GetFacilityLocation,
} from "../../../../store/CacheTwoFacilities/actions";
import {
  actionCreators as cacheTwoBFacilitiesActionCreators,
} from "../../../../store/CacheTwoBFacilities/actions";
import {
  actionCreators as cacheOneActionCreators,
  ClearCacheBelowOne,
} from "../../../../store/CacheOne/actions";
import {
  actionCreators as cacheZeroActionCreators,
} from "../../../../store/CacheZero/actions";
import {
  makeSelectedFacilityTypeSelector,
  makeSelectedLocationSelector
} from "../../../../store/CacheTwoFacilities";
import HomepageTrip from '../../Home/MyTrips/Trip';
import { FacilityLocation_Trip } from "../../../../models/api/cacheTwoFacilties";
import { FacilitiesIcon } from "../../../Icons";
import EmptyMessage from "../../../Elements/EmptyMessage";
import { generateDOMId } from '../../../../utils/cypressHelper';
import EndUserCacheManager from '../../../../utils/cacheManagers/endUserCacheManager';
import { shouldReconfigRouter } from '../../../../utils/cacheLoaders/reloaderHelper';
import { isPathUnderEndUserFacilityCacheTwo } from '../../../../utils/helpers/endUserPageHelper';
import { captureTentarooError } from '../../../../utils/dataHelper';
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../store/service';
import { ComponentUpdateTemplate } from '../../../Templates/ComponentUpdateTemplate';
import {isEndUserCacheTwoFacilitiesPopulated} from '../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--event-type-info';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, { id: string; name: string; }>
>;

export default (multiple: boolean = false) => {
  @(withRouter as any)
  class Location extends ComponentUpdateTemplate<ConnectedProps> {
    public props: ConnectedProps;
    private nextLocation;

    public componentDidMount() {
      super.loadAndSetData(
        this.props,
        (isStateNavigated) => {
          EndUserCacheManager.getInstance().loadCacheTwoFacilities({
            props: this.props,
            isStateNavigated,
          });
        }
      );
      this.configRouter();
    }

    componentWillReceiveProps(nextProps) {
      super.loadAndSetData(
        nextProps,
        (isStateNavigated) => {
          EndUserCacheManager.getInstance().loadCacheTwoFacilities({
            props: nextProps,
            isStateNavigated,
          });
        });
    }

    componentDidUpdate(prevProps: ConnectedProps) {
      if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
    }
  
    configRouter() {
      const { router, routes } = this.props;
      router.setRouteLeaveHook(routes[routes.length - 1], this.routerWillLeave);
    }

    routerWillLeave = (nextLocation) => {
      this.nextLocation = nextLocation;
    };

    private resetRouteLeaveHook() {
      const {router, routes} = this.props;
  
      router.setRouteLeaveHook(routes[routes.length - 1], () => {});
    }

    componentWillUnmount() {
      const {cacheTwoFacilities} = this.props;
  
      // if we are not navigating to paths under cache three facilities, clear cache
      if (
        this.nextLocation &&
        !isPathUnderEndUserFacilityCacheTwo(this.nextLocation.pathname) &&
        isEndUserCacheTwoFacilitiesPopulated(cacheTwoFacilities)
      ) {
        reduxStoreService().dispatch(new ClearCacheBelowOne());
      }
      this.resetRouteLeaveHook();
    }

    closeSidebar = () => this.props.actions.showSidebar(false);
    openSidebar = () => this.props.actions.showSidebar(true);
    onSelectCurrentTrip = () => this.props.actions.selectTripTab('current');
    onSelectPastTrip = () => this.props.actions.selectTripTab('past');

    public render() {
      const {
        SystemSettings, selectedLocation, apiLoadingMap, facilityLocation: { showMapMobile, showSidebar },
        inert,
        actions, apiSaving, cacheTwoFacilities: { FacilityLocationPermissions }
      } = this.props;
      const loading = apiLoadingMap[GetFacilityLocation.requestType];

      if (!SystemSettings) return null;

      return (
        <Main
          inert={inert}
          leftSidebar={selectedLocation ? <Sidebar
            systemSettings={SystemSettings}
            location={selectedLocation.Location}
            contactInfo={selectedLocation.FacilitiesContactInfo}
            open={!!showSidebar}
            onClose={this.closeSidebar}
            contactInfoLabel="Facilities Contact"
          /> : undefined}
          rightGutter={false}
          isLoading={apiSaving > 0}
        >
          <MainContent
            header={selectedLocation ? <CampInfo
              FacilityLocationPermissions={FacilityLocationPermissions}
              facilityLocation={selectedLocation}
              tentarooLocation={selectedLocation.Location}
              contactInfo={selectedLocation.FacilitiesContactInfo}
              image={selectedLocation.FeaturedImage}
              systemSettings={SystemSettings}
              showMapMobile={showMapMobile}
              onShowMapMobile={actions.toggleMapMobile}
              apiSaving={apiSaving}

            /> : undefined}
            rightGutter
          >
            {loading && <PageLoader height="300px" />}
            {!loading && selectedLocation && this.renderContent()}
          </MainContent>
          {apiSaving > 0 && <Loader cover center />}
        </Main>
      );
    }

    private renderTrips = () => {
      const { pastTrips, currentTrips, noFilterPastTrips, noFilterCurrentTrips, router, facilityLocation: { selectedTripTab }, cacheTwoFacilities: { locationID } } = this.props;
      let trips: Array<FacilityLocation_Trip> = [];
      let allTrips: Array<FacilityLocation_Trip> = [];
      if (selectedTripTab === 'current') {
        trips = currentTrips;
        allTrips = noFilterCurrentTrips;
      } else {
        trips = pastTrips;
        allTrips = noFilterPastTrips;
      }

      if (trips.length > 0) {
        return trips.map((trip, index) => {
          return <HomepageTrip
            key={index}
            id={generateDOMId(`trip-${trip.GroupTripID}`)}
            onClick={() => navPush(router, `${URLS.FACILITY_TRIP}/${locationID}/${trip.GroupTripID}/${spaceTo_(trip.Name)}`)}
            dateStart={trip.StartDateTime}
            dateEnd={trip.EndDateTime}
            name={trip.Name}
            total={trip.TotalAmount}
            balance={trip.Balance}
            cart={trip.InCart !== 0}
            youth={trip.NumYouth}
            adults={trip.NumAdults}
          />;
        });
      } else {
        let emptyMessage = 'No Trips Found';
        if (allTrips.length === 0) {
          if (selectedTripTab === 'current') emptyMessage = 'You have no upcoming trips at this location.';
          else emptyMessage = 'You have no past trips at this location.';
        }
        return <EmptyMessage icon={FacilitiesIcon} description={emptyMessage} />;
      }
    };

    private renderContent = () => {
      const {
        selectedFacilityType, actions, apiLoadingMap, selectedLocation, router, noFilterPastTrips, noFilterCurrentTrips,
        cacheTwoFacilities, facilityLocation, cacheZero, cacheTwoFacilities: { FacilityLocationPermissions }
      } = this.props;
      if (selectedLocation) {
        const loading = apiLoadingMap[GetFacilityLocation.requestType];
        const showAlert = !loading && selectedLocation && FacilityLocationPermissions && !FacilityLocationPermissions.hasAllowNewTrip;
        const ret: Array<any> = [
          <Media tablet newApi key="1">
            <ContentBlock layout="vertical" marginBottom={showAlert ? 16 : undefined} mobileMarginBottom={0}>
              <ContactCard
                map={!multiple}
                multiple={multiple}
                onMapClick={this.openSidebar}
                onContactsClick={this.openSidebar}
                title={selectedLocation.Name}
                fullAddress={getFullAddress(selectedLocation.Location)}
              />
            </ContentBlock>
          </Media>
        ];
        if (showAlert && FacilityLocationPermissions) {
          ret.push(<Row mobileMarginBottom={16}><Alert key="1a" >{FacilityLocationPermissions.hasAllowNewTripReason}</Alert></Row>);
        }
        ret.push(
          <ContentBlock key="2">
            <Title
              size={28} mobileSize={22} num={noFilterPastTrips.length + noFilterCurrentTrips.length}
              mobileMarginTop={24 - 16}
            >
              Trips
            </Title>
            <Tabs>
              <Tab selected={facilityLocation.selectedTripTab === 'current'} onClick={this.onSelectCurrentTrip}>CURRENT</Tab>
              <Tab selected={facilityLocation.selectedTripTab === 'past'} onClick={this.onSelectPastTrip}>PAST</Tab>
            </Tabs>
            {this.renderTrips()}
          </ContentBlock>
        );
        ret.push(
          <CheckDates
            key="3"
            cacheZero={cacheZero}
            facilityLocation={facilityLocation}
            cacheTwoFacilities={cacheTwoFacilities}
            selectedLocation={selectedLocation}
            showLoaders={false}
            actions={actions}
            apiLoadingMap={apiLoadingMap}
            selectedFacilityType={selectedFacilityType}
          />
        );

        return ret;
      }
      captureTentarooError(new Error('Tried to render main content of facilies location, but there was no selected facility.'));
      return null;
    };
  }

  const ConnectedLocation = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Location);
  return ConnectedLocation;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedLocation = makeSelectedLocationSelector();
  const currentTrips = makeCurrentTripSelector();
  const pastTrips = makePastTripSelector();
  const noFilterCurrentTrips = makeNoFilterCurrentTripSelector();
  const noFilterPastTrips = makeNoFilterPastTripSelector();
  const selectedFacilityType = makeSelectedFacilityTypeSelector();
  return {
    facilityLocation: state.facilities.location,
    SystemSettings: state.session.SystemSettings,
    facilitiesLocations: state.facilities.locations,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheTwoFacilities: state.cacheTwoFacilities,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    selectedLocation: selectedLocation(state),
    currentTrips: currentTrips(state),
    pastTrips: pastTrips(state),
    noFilterCurrentTrips: noFilterCurrentTrips(state),
    noFilterPastTrips: noFilterPastTrips(state),
    selectedFacilityType: selectedFacilityType(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheTwoFacilitiesActionCreators,
    ...cacheTwoBFacilitiesActionCreators,
    ...cacheOneActionCreators,
    ...cacheZeroActionCreators,
    ...rollbackActionCreators,
  }, dispatch)
});

export { default as FacilitiesLocationHeader } from './Header';
