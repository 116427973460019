import { DATE_PICKER_FORMAT } from "@tentaroo/shared";

import {
  FacilitiesReservationReservationReset,
  FacilitiesReservationReservationShowMore,
  ReservationChange,
  ValidateActions,
} from "./actions";
import {FormDefinition, IValidator} from "./validation";
import {FacilitiesCalc, FacilitiesRecalc} from "../../../../CacheFourFacilities/actions";
import {UpdateValue, WithRootState} from "../../../../Validation/actionCreator";
import * as moment from 'moment';
import {API_DATE_FORMAT, convertObjToMoment} from "../../../../../utils/dateHelper";
import {apiValidationReducerCreator} from "../../../../Validation/reducerCreator";
import * as M from "../../../../../constants/messages/generic";
import {
  FacilitiesReservationReset,
  FacilitiesReservationSelectPage,
  FacilitiesReservationSetPendingPage
} from "../actions";
import { captureTentarooError } from '../../../../../utils/dataHelper';
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../../utils/StrongActions";
import { SilentCancelAllAction } from '../../../../App/actions';


export interface ReservationActiveForm {
  NumAdults?: number;
  NumYouth?: number;
  StartDateTime?: any;
  EndDateTime?: any;
  SumPeople?: number;
}

export interface FacilitiesReservationReservationState {
  ActiveForm: ReservationActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
  showMore: boolean;
  recalcSuccess: boolean;
}
const getInitialState = (): FacilitiesReservationReservationState => {
  return {
    ActiveForm: {
      NumAdults: 0,
      NumYouth: 0
    },
    ValidationRules: {...FormDefinition},
    showMore: false,
    recalcSuccess: true
  };
};


const determineInlineError = (errors: any) => {
  let message = '';
  if (errors) {
    if (errors.apiError) message = errors.apiError[0];
    else if (errors.DateRange) message = errors.DateRange[0];
    else if (errors.SumPeople) message = errors.SumPeople[0];
  }
  return message;
};

// weird bug, for some reason I have to hard code the SUFFIXs below...
// const checkApiValidation = apiValidationReducerCreator(SUFFIX, determineInlineError, true);
const checkApiValidation = apiValidationReducerCreator('__RESERVATION_FORM', determineInlineError, true);
// const checkChangeValidation = apiValidationReducerCreator(SUFFIX_CHANGE, determineInlineError, true);
const checkChangeValidation = apiValidationReducerCreator('__RESERVATION_FORM_CHANGE', determineInlineError, true);

const FacilitiesReservationReservation: Reducer<FacilitiesReservationReservationState> = (oldState, action: WithRootState<AnyAction>) => {

  const s = checkApiValidation(oldState, action);
  const state = checkChangeValidation(s, action);
  if (
    action.type === FacilitiesRecalc.successType || action.type === FacilitiesCalc.successType ||
    ((action.type === FacilitiesRecalc.failureType || action.type === FacilitiesCalc.failureType) && (action.response.status === 400))
  ) {
    let res;
    if (action.response.response) {
      res = action.response.response;
    } else if (action.response.xhr && action.response.xhr.response) {
      res = action.response.xhr.response;
    } else {
      return state;
    }
    if (!res.Reservation || !res.FacilityAvailabilitiesDates) {
      return state;
    }
    const Reservation = res.Reservation;
    const FacilityAvailabilitiesDates = res.FacilityAvailabilitiesDates;
    const newState = {
      ...state,
      ActiveForm: {
        ...state.ActiveForm
      },
      ValidationRules: {
        ...state.ValidationRules
      }
    };
    if (Reservation && (Reservation.StartDateTime || Reservation.EndDateTime)) {
      newState.ActiveForm.NumYouth = Reservation.NumYouth ? Reservation.NumYouth : 0;
      newState.ActiveForm.NumAdults = Reservation.NumAdults ? Reservation.NumAdults : 0;

      if (Reservation.StartDateTime) {
        newState.ActiveForm.StartDateTime = moment(Reservation.StartDateTime, API_DATE_FORMAT);
      }

      if (Reservation.EndDateTime) {
        newState.ActiveForm.EndDateTime = moment(Reservation.EndDateTime, API_DATE_FORMAT);
      }

    }

    const latest = moment(FacilityAvailabilitiesDates[FacilityAvailabilitiesDates.length - 1].Date, API_DATE_FORMAT);
    const earliest = moment(FacilityAvailabilitiesDates[0].Date, API_DATE_FORMAT);

    newState.ValidationRules.StartDateTime = {
      ...state.ValidationRules.StartDateTime,
      validatejs: {
        ...state.ValidationRules.StartDateTime.validatejs,
        StartDateTime: {
          ...state.ValidationRules.StartDateTime.validatejs.StartDateTime,
          datetime: {
            latest: latest.format(DATE_PICKER_FORMAT),
            earliest: earliest.format(DATE_PICKER_FORMAT),
            notValid: `^${M.INVALID_DATE}`,
            tooEarly: `^${M.OUTSIDE_DATE}`,
            tooLate: `^${M.OUTSIDE_DATE}`
          }
        }
      }
    };

    newState.ValidationRules.EndDateTime = {
      ...state.ValidationRules.EndDateTime,
      validatejs: {
        ...state.ValidationRules.EndDateTime.validatejs,
        EndDateTime: {
          ...state.ValidationRules.EndDateTime.validatejs.EndDateTime,
          datetime: {
            latest: latest.format(DATE_PICKER_FORMAT),
            earliest: earliest.format(DATE_PICKER_FORMAT),
            notValid: `^${M.INVALID_DATE}`,
            tooEarly: `^${M.OUTSIDE_DATE}`,
            tooLate: `^${M.OUTSIDE_DATE}`
          }
        }
      }
    };

    return newState;
  } else if (
    action.type === ReservationChange.successType ||
    ((action.type === ReservationChange.failureType) && action.response.status === 400)
  ) {
    let res;
    if (action.response.response) {
      res = action.response.response;
    } else if (action.response.xhr && action.response.xhr.response) {
      res = action.response.xhr.response;
    } else {
      // This should never happen because the backend will only throw 400 without Reservation on the final submit
      // The final submit is handled in another reducer
      return {
        ...state,
        recalcSuccess: false
      };
    }
    // This should never happen because the backend will only throw 400 without Reservation on the final submit
    // The final submit is handled in another reducer
    if (!res.Reservation) {
      return {
        ...state,
        recalcSuccess: false
      };
    }
    const Reservation = res.Reservation;
    const newState = {
      ...state,
      ActiveForm: {
        ...state.ActiveForm
      },
      ValidationRules: {
        ...state.ValidationRules,
        StartDateTime: {
          ...state.ValidationRules.StartDateTime
        },
        EndDateTime: {
          ...state.ValidationRules.EndDateTime
        }
      }
    };

    const reserv = convertObjToMoment(Reservation, ['StartDateTime', 'EndDateTime']);

    newState.ActiveForm.StartDateTime = reserv.StartDateTime;
    newState.ActiveForm.EndDateTime = reserv.EndDateTime;

    // We need to reset field error here because in this form we have a special case where we will
    // send out request when fields have `Required.` error.
    newState.ValidationRules.StartDateTime.errors = false;
    newState.ValidationRules.EndDateTime.errors = false;
    newState.ActiveForm.NumYouth = reserv.NumYouth;
    newState.ActiveForm.NumAdults = reserv.NumAdults;
    newState.recalcSuccess = true;

    return newState;
  } else if (action.type === ReservationChange.requestType) {
    return { ...state, recalcSuccess: false };
  } else if (isActionType(action, SilentCancelAllAction) && !action.isDuringNavigation) {
    return {
      ...state,
      recalcSuccess: true,
    };
  } else if (isActionType(action, FacilitiesReservationReservationShowMore)) {
    return {...state, showMore: action.show};
  } else if (action.type === ValidateActions.updateType) {
    const valueAction = action as WithRootState<UpdateValue>;
    if (valueAction.changeKey === 'StartDateTime' || valueAction.changeKey === 'EndDateTime') {
      const rootState = action.rootState;

      if (!rootState.cacheFourFacilities.FacilityAvailabilities) {
        captureTentarooError(new Error("cacheFourFacilities.FacilityAvailabilities not available when validating Reservation"));
        return state;
      }
      const availabilities = rootState.cacheFourFacilities.FacilityAvailabilities.Availabilities;
      const availDates = rootState.cacheFourFacilities.FacilityAvailabilitiesDates || [];
      const val = valueAction.errorsAndValues.Values[valueAction.changeKey];
      if (typeof val === 'string' || !val) {
        return state;
      }
      const date = val as moment.Moment;
      const dateKey = date.format(API_DATE_FORMAT);
      const index = availDates.findIndex((a) => a.Date === dateKey);
      if (index === -1) {
        return state;
      }
      const aID = availabilities[index].AvailID;
      if (aID === 1 || aID === 2) {
        return state;
      }
      if (valueAction.changeKey === 'EndDateTime' && aID === 3) {
        return state;
      }
      const newState = {
        ...state,
        ValidationRules: {
          ...state.ValidationRules,

        }
      };
      newState.ValidationRules[valueAction.changeKey] = {
        ...newState.ValidationRules[valueAction.changeKey],
        errors: [M.UNAVAIL_DATE]
      };
      return newState;

    }
    return state;
  } else if (isActionType(action, FacilitiesReservationReservationReset) || isActionType(action, FacilitiesReservationReset)) {
    return {...getInitialState()};
  } else if (isActionType(action, FacilitiesReservationSelectPage)) {
    if (action.selectedPage === 'type') {
      return {...getInitialState()};
    }
    return state;
  } else if (isActionType(action, FacilitiesReservationSetPendingPage)) {
    if (action.pendingPage === 'reservation') {
      return {...state, showMore: true};
    }
  }
  return state || {...getInitialState()};
};

export default FacilitiesReservationReservation;
