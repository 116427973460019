import { ActionCreator } from "../../../..";
import {createApiValidateActions, createValidateActions, createRequestActions} from '../../../../Validation/actionCreator';
import { SaveState } from "../../../../Rollback/actions";
import { typeName, Action } from "../../../../../utils/StrongActions";

export const SUFFIX = '__ADMIN_SETTINGS_GL_ACCOUNT_MODAL';
export const GetGLAccountList = createRequestActions(SUFFIX);

@typeName("FILTER" + SUFFIX)
export class GLAccountModalFilter extends Action {
  constructor(public filterString: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class GLAccountToggleFilterTabs extends Action {
  constructor(public searching: boolean) { super(); }
}

@typeName("RESET" + SUFFIX) export class ResetGLAccountModal extends Action {}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);


export type Actions = typeof actionCreators;

export const actionCreators = {
  filterAccounts: (filterString: string): ActionCreator => dispatch => dispatch(new GLAccountModalFilter
    (filterString)),
  reset: (): ActionCreator => dispatch => dispatch(new ResetGLAccountModal()),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new GLAccountToggleFilterTabs(searching)),
  refreshGLAccounts: (): ActionCreator => dispatch => {
    dispatch(new SaveState());
    dispatch(GetGLAccountList.request({}));
  },
};