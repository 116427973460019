import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import {
  actionCreators as cacheZeroActionCreators
} from '../../../../../store/CacheZero/actions';
import {
    actionCreators as adminCMSCacheOneActionCreators,
} from '../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators } from '../../../../../store/AdminCMSSite/Settings/Messages/actions';
import { 
  actionCreators as rollbackActionCreators,
} from '../../../../../store/Rollback/actions';
import { Card, FieldSet, Row, Column, TextField, RadioGroupOption, Text, Alert } from '../../../../../components/Elements';
import '../../../../../styles/pages/cms-settings/messages.scss';
import { CMSSiteMessage } from '../../../../../models/api/adminCMSCacheOne';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';

export const namespace = (): string => 'pages--cms--settings-messages';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {resourceId: string}>;

interface Props {
  disabled?: boolean;
}

class MessagesSettings extends React.Component<Props, {}> {
  public props: ConnectedProps & Props;

  renderSiteMessageCard = (siteMessage: CMSSiteMessage, index: number) => {
    const {disabled, adminCMSCacheOne, actions, messagesSettings: {ActiveForm, ValidationRules}} = this.props;
    if (siteMessage.ID > 0) {
      return (
        <Card className={`${namespace()}--card`}
        ​      component={<section/>}
        ​      template={'mobile-no-margin'}
              marginTop={24}
              mobileMarginTop={16}
        ​      mobileMarginBottom={0}
        ​      marginBottom={0}
            >
          <FieldSet mobileMarginBottom={16} marginBottom={24} legendMarginBottom={0} fontSize={18} name={siteMessage.EmbedName}></FieldSet>
          <Row>
            <Column span={12} mobileSpan={12}>
              <TextField
                label="Title"
                disabled={disabled}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules[`${siteMessage.EmbedName}Title`]}
                value={ActiveForm[`${siteMessage.EmbedName}Title`]}/>
            </Column>
          </Row>
          <Row>
            <Column span={12} mobileSpan={12}>
              <TextField
                label="Description"
                rows={4}
                disabled={disabled}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules[`${siteMessage.EmbedName}Description`]}
                value={ActiveForm[`${siteMessage.EmbedName}Description`]}/>
            </Column>
          </Row>
          <Row>
            <Column span={6} mobileSpan={12}>
              <TextField
                label="Button Label"
                disabled={disabled}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules[`${siteMessage.EmbedName}ButtonLabel`]}
                value={ActiveForm[`${siteMessage.EmbedName}ButtonLabel`]}/>
            </Column>
          </Row>
          <Row>
            <Column span={12} mobileSpan={12}>
              <TextField
                label="Button Link"
                disabled={disabled}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules[`${siteMessage.EmbedName}ButtonURL`]}
                value={ActiveForm[`${siteMessage.EmbedName}ButtonURL`]}/>
            </Column>
          </Row>
          <div className={`${namespace()}--card--field-label`}>Open In</div>
          <Row>
            <Column mobileMarginBottom={0} layout='horizontal' span={6} mobileSpan={12}>
              <RadioGroupOption
                value={!!!ActiveForm[`${siteMessage.EmbedName}IsNewWindow`]}
                selected={!!!ActiveForm[`${siteMessage.EmbedName}IsNewWindow`]}
                rawValue={false}
                ValidationRules={ValidationRules[`${siteMessage.EmbedName}IsNewWindow`]}
                onChange={actions.updateValue}
                disabled={disabled}
                newDesign
                label={<Text color='dark-gray' weight="regular" size={16} marginBottom={0}>Same Window</Text>}
              />
              
            </Column>
            <Column layout='horizontal' span={6} mobileSpan={12}>
              <RadioGroupOption
                value={!!ActiveForm[`${siteMessage.EmbedName}IsNewWindow`]}
                selected={!!ActiveForm[`${siteMessage.EmbedName}IsNewWindow`]}
                rawValue={true}
                ValidationRules={ValidationRules[`${siteMessage.EmbedName}IsNewWindow`]}
                onChange={actions.updateValue}
                disabled={disabled}
                newDesign
                label={<Text color='dark-gray' weight="regular"  size={16} marginBottom={0}>New Window</Text>}
              />
            </Column>
          </Row>
        </Card>
      );
    } else {
      return (
        <Card className={`${namespace()}--card`}
        ​      component={<section/>}
        ​      template={'mobile-no-margin'}
              marginTop={24}
              mobileMarginTop={16}
        ​      mobileMarginBottom={0}
        ​      marginBottom={0}
            >
          <FieldSet mobileMarginBottom={16} marginBottom={24} legendMarginBottom={0} fontSize={18} name={siteMessage.EmbedName}></FieldSet>
          <Row>
            <Column span={12} mobileSpan={12}>
            <TextField
              label="Newsletter Code"
              rows={6}
              disabled={disabled || (adminCMSCacheOne.CMSSite ? !adminCMSCacheOne.CMSSite.canChangeHTML : true)}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules[`h${siteMessage.EmbedName.substr(1)}Description`]}
              value={ActiveForm[`h${siteMessage.EmbedName.substr(1)}Description`]}/>
            </Column>
          </Row>
        </Card>
      );
    }

    // any other cases should be added here
    return null;
  };

  public render() {
    const {adminCMSCacheOne, messagesSettings: {SubmitErrorMessage}} = this.props;

    const siteMessages = adminCMSCacheOne.CMSSiteMessages;
    return (
      <div className={`${namespace()}`}>
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        {adminCMSCacheOne.CMSSite && adminCMSCacheOne.CMSSite.hasSettingsEditReason ? <Alert className={`${namespace()}--alert`}>{adminCMSCacheOne.CMSSite.hasSettingsEditReason}</Alert> : null}
        {siteMessages && siteMessages.map((s, index) => {
          return this.renderSiteMessageCard(s, index);
        })}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    messagesSettings: state.adminCMSSite.settings.messages,
    cacheZero: state.cacheZero,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheZeroActionCreators,
    ...adminCMSCacheOneActionCreators,
    ...appActionCreators,
    ...rollbackActionCreators,
  }, dispatch)
});

const ConnectedMessagesSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(MessagesSettings);

export default withRouter<Props>(ConnectedMessagesSettings);
