import {MIN_DATE} from "@tentaroo/shared";

import * as M from '../../../../constants/messages/generic';
import {chainMerge} from '../../../../utils/validator';
import {makeSelectedLocationSelector} from "../../../CacheTwoFacilities";
import {MAX_LENGTH, MIN_LENGTH} from "../../../../constants/messages/generic";
import { Validator } from "../../../../utils/validator/models";
import { toNumber } from "../../../../utils/dataHelper";

export interface IValidator {
  Name: Validator;
  ContactName: Validator;
  ContactPhone: Validator;
  ContactEmail: Validator;
  ContactName2: Validator;
  ContactPhone2: Validator;
  ContactEmail2: Validator;
  NumAdults: Validator;
  NumYouth: Validator;
  GroupNotes: Validator;
  AdminNotes: Validator;
  StartDateTime: Validator;
  EndDateTime: Validator;
  SumPeople: Validator;
  DateRange: Validator;
}

const selectedLocation = makeSelectedLocationSelector();

export const FormDefinition: IValidator = {
  Name: {
    key: 'Name',
    validatejs: {
      Name: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 150,
          tooLong: MAX_LENGTH(150)
        }
      }
    },
  },
  ContactName: {
    key: 'ContactName',
    validatejs: {
      ContactName: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 150,
          tooLong: MAX_LENGTH(150)
        }
      }
    },
  },
  ContactPhone: {
    key: 'ContactPhone',
    isRequired: (rootState) => {
      if (selectedLocation(rootState)) {
        return selectedLocation(rootState).RequireTripContactPhone;
      }
      return false;
    },
    validatejs: {
      ContactPhone: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 50,
          tooLong: MAX_LENGTH(50)
        }
      }
    },
  },
  ContactEmail: {
    key: 'ContactEmail',
    validatejs: {
      ContactEmail: {
        presence: {message: '^' + M.REQUIRED},
        email: {message: '^' + M.INVALID},
        length: {
          minimum: 3,
          maximum: 50,
          tooLong: MAX_LENGTH(50),
          tooShort: MIN_LENGTH(3)
        }
      }
    }
  },
  ContactName2: {
    key: 'ContactName2',
    validatejs: {
      ContactName2: {
        length: {
          maximum: 150,
          tooLong: MAX_LENGTH(150)
        }
      }
    },
  },
  ContactPhone2: {
    key: 'ContactPhone2',
    validatejs: {
      ContactPhone2: {
        length: {
          maximum: 50,
          tooLong: MAX_LENGTH(50)
        }
      }
    },
  },
  ContactEmail2: {
    key: 'ContactEmail2',
    validatejs: {
      ContactEmail2: {
        length: {
          minimum: 3,
          tooShort: MIN_LENGTH(3),
          maximum: 50,
          tooLong: MAX_LENGTH(50)
        }
      }
    },
  },
  NumAdults: {
    key: 'NumAdults',
    isChainRoot: true,
    chainValue: (rootState, values) => {
      return values.NumAdults;
    },
    chainDependants: ['SumPeople'],
    validatejs: {
      NumAdults: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: '^Must be at least 0.',
          greaterThan: -1,
          notLessThan: '^Must be no more than 800.',
          lessThan: 801
        }
      }
    },
  },
  NumYouth: {
    key: 'NumYouth',
    isChainRoot: true,
    chainValue: (rootState, values) => {
      return values.NumYouth;
    },
    chainDependants: ['SumPeople'],
    validatejs: {
      NumYouth: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: '^Must be at least 0.',
          greaterThan: -1,
          notLessThan: '^Must be no more than 800.',
          lessThan: 801
        }
      }
    },
  },
  GroupNotes: {
    key: 'GroupNotes',
    validatejs: {
      GroupNotes: {
        length: {
          maximum: 1024,
          tooLong: MAX_LENGTH(1024)
        }
      }
    },
  },
  AdminNotes: {
    key: 'AdminNotes',
    validatejs: {
      AdminNotes: {
        length: {
          maximum: 2048,
          tooLong: MAX_LENGTH(2048)
        }
      }
    },
  },
  StartDateTime: {
    key: 'StartDateTime',
    validatejs: {
      StartDateTime: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          earliest: MIN_DATE,
          notValid: `^${M.INVALID_DATE}`,
          tooEarly: `^${M.TOO_EARLY}`,
        }
      }
    }
  },
  EndDateTime: {
    key: 'EndDateTime',
    validatejs: {
      EndDateTime: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          earliest: MIN_DATE,
          notValid: `^${M.INVALID_DATE}`,
          tooEarly: `^${M.TOO_EARLY}`
        }
      }
    }
  },
  SumPeople: {
    key: 'SumPeople',
    chainValue: (rootState, values) => {
      const ActiveForm = rootState.facilities.trip.form.ActiveForm;
      const v = chainMerge(ActiveForm, values);
      return toNumber(v.NumYouth) + toNumber(v.NumAdults);
    },
    customValidate: (rootState) => {
      const ActiveForm = rootState.facilities.trip.form.ActiveForm;

      const SumPeople = toNumber(ActiveForm.SumPeople);
      if (SumPeople <= 0) {
        return M.AT_LEAST_ONE_PERSON;
      }
    }
  },
  DateRange: {
    key: 'DateRange',
    customValidate: (rootState) => {
      const {StartDateTime, EndDateTime} = rootState.facilities.trip.form.ActiveForm;
      if (EndDateTime && StartDateTime && EndDateTime.isBefore && StartDateTime.isBefore && EndDateTime.isBefore(StartDateTime)) {
        return M.BAD_DATE_RANGE;
      }
    }
  }
};
