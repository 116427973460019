import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import Loader from '../Loader';
import { namespaceWindow } from './constants';
import '../../../styles/elements/modal/content.scss';
import { disableBodyScroll, enableBodyScroll } from '../../../utils/navHelper';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

export const namespace = (): string => `${namespaceWindow}--content`;

interface Props {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
  center?: boolean;
  loading?: boolean;
  neverLoading?: boolean;
  mobileFullScreen?: boolean;
  scrollable?: boolean;
  paddingTop?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  paddingBottom?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  paddingLeft?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  paddingRight?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  marginBottom?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  verticalAlign?: 'center'; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  refCallback?: any;
  hasPadding?: boolean;
  noBodyScrollLock?: boolean;
}

type ConnectedProps = ReturnType<typeof mapStateToProps>

class Content extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  private contentRef;

  public componentDidMount() {
    if (!this.props.noBodyScrollLock) disableBodyScroll(this.contentRef);
  }

  public componentWillUnmount() {
    if (!this.props.noBodyScrollLock) enableBodyScroll(this.contentRef);
  }

  refCallback = (ref) => {
    if (this.props.refCallback) this.props.refCallback(ref);
    this.contentRef = ref;
  };

  public render() {
    const {
      children, mobileFullScreen, center, scrollable, marginBottom, paddingTop, paddingBottom, paddingLeft, hasPadding,
      paddingRight, verticalAlign, apiSaving, loading, neverLoading
    } = this.props;

    let className = mergeClassNames(namespace(), this.props);
    if (center) className += ' center';
    if (mobileFullScreen) className += ' mobile-full-screen';
    if (scrollable) className += ' scrollable';
    if (verticalAlign) className += ` vertical-align-${verticalAlign}`;
    if (loading || (apiSaving > 0 && !neverLoading)) className += ' loading';

    const style: React.CSSProperties = { ...this.props.style };
    if (marginBottom !== null && marginBottom !== undefined) {
      className += ' no-bottom-padding';
      style.marginBottom = `${marginBottom}px`;
    }
    if (paddingTop || paddingTop === 0) style.paddingTop = `${paddingTop}px`;
    if (paddingBottom || paddingBottom === 0) style.paddingBottom = `${paddingBottom}px`;
    if (paddingLeft || paddingLeft === 0) style.paddingLeft = `${paddingLeft}px`;
    if (paddingRight || paddingRight === 0) style.paddingRight = `${paddingRight}px`;
    if (hasPadding) className += ' has-padding';

    return (
      <div ref={this.refCallback} style={style} className={className}>
        {((apiSaving > 0 && !neverLoading) || loading) && <Loader className={`${namespace()}--loader`}/>}
        {children}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({apiSaving: state.app.apiSaving});

const ConnectedContent = connect(
  mapStateToProps,
  null,
  getMergeProps<Props>(),
)(Content);

export default ConnectedContent;
