import * as React from 'react';

import AddedTemplate from "../../Added";
import AddedTemplateTabs from "../../Added/Tabs";
import AddedTemplateShowSideBar from "../../Added/ShowSideBar";

import { Media, EmptyMessage, Button, Tab } from '../../../Elements';
import { ProductIcon, AddedIcon } from '../../../Icons';
import { WithInertAttribute } from '../../../Elements/WithInert';

type Props = WithInertAttribute<{
  children?: React.ReactNode;
  footer?: React.ReactElement<any>;
  header?: React.ReactElement<any>;
  sideBar?: React.ReactElement<any>;
  addedItems: Array<any>;
  addedCount?: number;
  className?: string;
  emptyMessage?: string;
  loading?: boolean;
  hideSidebar?: boolean;
}>;

const ProductsTemplate: React.SFC<Props> = (
  { children, footer, loading, header, sideBar, addedCount, className, emptyMessage, addedItems, hideSidebar, inert }: Props
): React.ReactElement<Props> => (
  <AddedTemplate
    inert={inert}
    className={className}
    footer={footer}
    header={header}
    sideBar={sideBar}
    addedItems={addedItems}
    hideSidebar={hideSidebar}
    loading={loading}
    tabs={(
      <AddedTemplateTabs>
        <Tab selected>Products</Tab>
      </AddedTemplateTabs>
    )}
    empty={
      <EmptyMessage
        icon={ProductIcon}
        description={emptyMessage ?? ""}
        actions={[
          <Media tablet key="tablet">
            <AddedTemplateShowSideBar>
              <Button color="green" big icon={<AddedIcon style={{ width: '21px', height: '14px' }}/>}>
                SHOW ADDED
              </Button>
            </AddedTemplateShowSideBar>
          </Media>,
          <Media mobile key="mobile">
            <AddedTemplateShowSideBar>
              <Button color="green" big icon={<AddedIcon style={{ width: '21px', height: '14px' }}/>}>
                SHOW ADDED
              </Button>
            </AddedTemplateShowSideBar>
          </Media>
        ]}
      />
    }
    showEmpty={!!emptyMessage}
  >
    {children}
  </AddedTemplate>
);

export default ProductsTemplate;
export { AddedTemplateContent as ProductsFormTemplateContent } from '../../index';
export { AddedTemplateSideBar as ProductsFormTemplateSideBar } from '../../index';
