import * as React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/elements/class/class-group.scss';

const namespace = (): string => 'elements--class-group';

export interface ClassGroupProps {
  children?: React.ReactNode;
  loading?: boolean;
}

type ClassGroupState = {
  selected?: any
};

// @deprecated: use card group instead
class ClassGroup extends React.Component<ClassGroupProps, ClassGroupState> {
  public static childContextTypes = {
    select: PropTypes.func,
    isSelected: PropTypes.func,
    unSelect: PropTypes.func
  };

  public props: ClassGroupProps;
  public state: ClassGroupState = {
    selected: null
  };

  public getChildContext = () => ({
    select: (instance) => this.setState({ selected: instance }),
    isSelected: (instance) => instance === this.state.selected,
    unSelect: (instance) => { if (instance === this.state.selected) this.setState({ selected: null }); }
  });

  public render() {
    return (
      <div className={`${namespace()} ${this.props.loading ? 'loading' : ''}`}>
        {React.Children.map(this.props.children, (child: React.ReactElement<any>) => {
          if (!child) return null;
          return React.cloneElement(child, { ...child.props, selectedClass: this.state.selected });
        })}
      </div>
    );
  }
}

export default ClassGroup;
