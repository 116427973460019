import type {ActionCreator, ApplicationState} from '../../../../';
import {
  createApiSubmitActions,
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions
} from "../../../../Validation/actionCreator";
import {SaveState} from "../../../../Rollback/actions";
import {composeClassRegistrations} from "../../../../CacheFourEventsNumbers/actions";
import {eventParticipantsRecalcBody} from "../../../../../constants/urls";
import {PostEventRegistrationClass} from "../../../../../models/api/cacheFourEvents";
import {validateAll} from "../../../../../utils";
import {ShowTopFloatingAlert} from "../../../../App/actions";
import { typeName, Action } from '../../../../../utils/StrongActions';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../../utils/dataHelper';

export const SUFFIX = '__REGISTER_OVERRIDE_FEE';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ApiSubmitActions = createApiSubmitActions(SUFFIX, true);

@typeName(`SAVE${SUFFIX}`)
export class RegisterOverrideFeeSave extends Action {
  // @todo: probably need more params here
  constructor(public Amount: number) { super(); }
}

export type Actions = typeof actionCreators;

const formCreator = (rootState: ApplicationState, Amount: number, AmountMin: number) => {
  const c4 = rootState.cacheFourEventsParticipants;

  let debugPayload: TentarooDebugPayload | undefined;
  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("rootState.cacheZero.options not available when submitting RegisterOverrideFee Form");
  }
  if (!c4.EventRegistrationPerson) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("c4.EventRegistrationPerson not available when submitting RegisterOverrideFee Form");
  }
  if (!rootState.cacheFourEventsParticipants) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("rootState.cacheFourEventsParticipants not available when submitting RegisterOverrideFee Form");
  }
  if (!c4.EventRegistrationPaymentStatus) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("c4.EventRegistrationPaymentStatus not available when submitting RegisterOverrideFee Form");
  }
  const options = rootState.cacheZero.options;
  const eventTypeID = rootState.cacheTwoEvents.eventTypeID;
  const eventID = rootState.cacheThreeEvents.eventID;

  const classRegistrations: Array<PostEventRegistrationClass> = composeClassRegistrations(c4.EventClassesIndividualRegistered);
  const pTypeID = rootState.events.event.register.participant.type.selectedTypeID;

  const route = rootState.routing.locationBeforeTransitions;
  const sp = route.pathname.split('/');
  let HasMultiWeekDiscount = c4.EventRegistrationPerson?.HasMultiWeekDiscount;
  if (sp.length === 8) { // then we're adding
    const pType = !rootState.cacheFourEventsParticipants.EventRegistrationParticipantTypes ? null : rootState.cacheFourEventsParticipants.EventRegistrationParticipantTypes.find(pt => pt.ID === pTypeID);
    if (!pType) {
      debugPayload = captureTentarooErrorAndGetRequestPayload('pType not found when saving participant - in RegisterOverrideFee');
    } else {
      if (pType.HasMultiWeekDiscount !== undefined) {
        HasMultiWeekDiscount = pType.HasMultiWeekDiscount;
      }
    }
  }

  if (c4.isYouth === undefined) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheFourEventsParticipants.isYouth is undefined when performing eventParticipantsRecalc");
  }

  if (!options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group is not available when performing eventParticipantsRecalc");
  }

  const payload = eventParticipantsRecalcBody(
    {
      GroupIDi: options?.Group?.IDi as any,
      GroupTS: options?.Group?.TS as any,
      EventTypeID: eventTypeID,
      EventIDi: eventID,
      GroupWeekIDi: c4.EventRegistrationPaymentStatus?.IDi as any,
      PersonIDi: c4.GroupRosterPerson ? c4.GroupRosterPerson.IDi : null,
      IsYouth: c4.isYouth!,
      ClassRegistrations: classRegistrations,
      ParticipantTypeID: pTypeID,
      EventRegistrationPerson: {
        ...(c4.EventRegistrationPerson || {} as any),
        Amount: Amount,
        AmountMin: AmountMin
      },
      ParticipantRegistrationIDi: c4.EventRegistrationPerson?.IDi ? c4.EventRegistrationPerson?.IDi : null,
      HasMultiWeekDiscount: HasMultiWeekDiscount as any,
      ParticipantTypes: !rootState.cacheThreeEvents.EventInfoParticipantTypes ? [] : rootState.cacheThreeEvents.EventInfoParticipantTypes.map((pType) => pType.ID),
    }
  );

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  saveRegisterOverride: (AmountMin: number, Amount?: number): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const validationResults = validateAll(s => s.events.event.modals.registerOverrideFee);
    if (!validationResults) {
      // this is success, we need to start the epic and only close if successful
      // reduxStoreService().dispatch(new OpenModal());
      // reduxStoreService().dispatch(new ClassOverrideFeeSave(OverridingClass, ActiveForm.NumOverride, ActiveForm.Amount, ActiveForm.AmountMin));
      dispatch(ApiSubmitActions.request(formCreator(getState(), Amount as number, AmountMin), null));
    } else {
      dispatch(ApiSubmitActions.clientFailure(validationResults, true));
      let errorMessage = 'Amount is required.';
      dispatch(new ShowTopFloatingAlert(errorMessage, true, 'orange'));
    }
  },
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.events.event.modals.overrideFee),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
};
