import * as React from 'react';
import {EnterHook, Route} from 'react-router';
import App from './components/App';
import Nav from './components/App/Nav';
import * as Pages from './components/Pages';
import NumbersWizardHeader from './components/Pages/Events/Event/Register/Numbers/Header';
import ProductsWizardHeader from './components/Pages/Events/Event/Register/Products/Header';
import ParticipantWizardHeader from './components/Pages/Events/Event/Register/Participant/Header';
import {
  E_REGISTER,
  E_REGISTRATION,
  E_RER_PARTICIPANT,
  EVENT,
  FAC_ADD,
  FAC_FACILITY,
  FAC_TRIP,
  FAC_TRIP_ADD,
  FAC_TRIP_EDIT,
  FAC_TRIP_RESERVATION,
  MY_ROASTER_ADD,
  NAVS,
  URLS,
  CMS_PATHS,
  CMS_CONTACTS,
  CMS_RESOURCES,
  CMS_SITE_MENUS,
  CMS_PAGE_MENUS,
  FACILITY_LOCATION_PATH,
  FACILITY_LOCATION_FACILITY,
  FACILITY_LOCATION_FACILITY_TYPE,
  FACILITY_LOCATION_BLACKOUT_DATE,
  FAC_TRIP_RESERVATION_EDIT,
} from './constants/urls';
import {
  ADMIN_EVENTS_PATH,
  ADMIN_EVENTS_EVENT_PATH,
  ADMIN_EVENT_CLASS_TYPE,
  ADMIN_EVENT_CLASS,
  ADMIN_EVENT_MESSAGE,
  ADMIN_EVENT_PRODUCT,
} from './constants/adminEventsUrls';
import FacilityWizard from './components/Pages/Facilities/Trip/Reservation/index';
import Home from './components/Pages/Home/index';

export const END_USER_EVENT_TYPE_PATH = `${URLS.EVENT_TYPES}/:id/:name`;
export const VIEW_ORDER_PATH = `${URLS.VIEW_ORDER_ROOT}/:id`;

// End user events related paths
export const END_USER_EVENT_PATH = `${URLS.EVENTS}/:eventTypeId/:eventId/:name`;
export const END_USER_EVENT_REGISTRATION_PATH = `${URLS.EVENTS}/:eventTypeId/:eventId/:name/${EVENT.REGISTRATION}`;
export const END_USER_EVENT_CLASSES_PATH = `${URLS.EVENTS}/:eventTypeId/:eventId/:name/${EVENT.CLASSES}`;
export const END_USER_EVENT_REGISTRATION_CLASSES_PATH = `${URLS.EVENTS}/:eventTypeId/:eventId/:name/${EVENT.REGISTRATION}/${E_REGISTRATION.CLASSES}`;
// CacheFourEventsParticipants paths
export const PARTICIPANT_WIZARD_PATH = `${URLS.EVENTS}/:eventTypeId/:eventId/:name/${EVENT.REGISTER}/${E_REGISTER.PARTICIPANT}`;
export const PARTICIPANT_WIZARD_EDIT_PATH = `${PARTICIPANT_WIZARD_PATH}/:ya/:pId/:pName/${E_RER_PARTICIPANT.EDIT}`;
export const END_USER_EVENT_REGISTRATION_PARTICIPANT_PATH = `${URLS.EVENTS}/:eventTypeId/:eventId/:name/${EVENT.REGISTRATION}/:ya/:pId/:pName`;
// CacheFourNumbers paths
export const NUMBERS_WIZARD_PATH = `${URLS.EVENTS}/:eventTypeId/:eventId/:name/${EVENT.REGISTER}/${E_REGISTER.NUMBERS}`;
// CacheFourProducts paths
export const PRODUCTS_WIZARD_PATH = `${URLS.EVENTS}/:eventTypeId/:eventId/:name/${EVENT.REGISTER}/${E_REGISTER.PRODUCTS}`;


// End user facilities related paths
export const END_USER_FACILITY_PATH = `${URLS.FACILITIES}/:id/:name`;
export const END_USER_FACILITYB_PATH_WITH_FTBID = `${URLS.FACILITIES}/:id/:name/${FAC_FACILITY}/:facId/:ftbId/:facName`;
export const END_USER_FACILITYB_PATH = `${URLS.FACILITIES}/:id/:name/${FAC_FACILITY}/:facId/:facName`;
export const END_USER_ADD_TRIP_PATH = `${URLS.FACILITIES}/:locId/:name/${FAC_TRIP}/${FAC_ADD}`;
// Cache three paths
export const END_USER_TRIP_SUMMARY_PATH = `${URLS.FACILITY_TRIP}/:locId/:tripId/:name`;
export const END_USER_EDIT_TRIP_PATH = `${URLS.FACILITY_TRIP}/:locId/:tripId/:name/${FAC_TRIP_EDIT}`;
export const END_USER_VIEW_RESERVATION_PATH = `${URLS.FACILITY_TRIP}/:locId/:tripId/:name/${FAC_TRIP_RESERVATION}/:resId/:resName`;
// CacheFourFacilities paths
export const FACILITY_WIZARD_PATH = `${URLS.FACILITY_TRIP}/:locId/:tripId/:name/${FAC_TRIP_RESERVATION}/${FAC_TRIP_ADD}`;
export const FACILITY_WIZARD_EDIT_PATH = `${URLS.FACILITY_TRIP}/:locId/:tripId/:name/${FAC_TRIP_RESERVATION}/:resId/:resName/${FAC_TRIP_RESERVATION_EDIT}`;


// End user roster related paths
export const ROSTER_ADD_PATH = `${URLS.MY_ROSTER}/:ya/${MY_ROASTER_ADD}`;
export const ROSTER_EDIT_PATH = `${URLS.MY_ROSTER}/:ya/:id/:name`;


// CMS related paths
export const ADMIN_CMS_PAGES_PATH = `${URLS.ADMIN_SITES}/:siteId/:domain/${CMS_PATHS.PAGES}`;
export const ADMIN_CMS_PAGE_PATH = `${URLS.ADMIN_PAGES}/:siteId/:pageId(/:name)`;

export const ADMIN_CMS_CONTACTS_PATH = `${URLS.ADMIN_SITES}/:siteId/:domain/${CMS_PATHS.CONTACTS}`;
export const ADMIN_CMS_CONTACT_NEW_PATH = `${URLS.ADMIN_SITES}/:siteId/:domain/${CMS_PATHS.CONTACTS}/${CMS_CONTACTS.NEW}`;

export const ADMIN_CMS_CONTACT_PREVIEW_PATH = `${URLS.ADMIN_CONTACTS}/:siteId/:contactId(/:name)`;
export const ADMIN_CMS_CONTACT_EDIT_PATH = `${URLS.ADMIN_CONTACTS}/:siteId/:contactId(/:name)/${CMS_CONTACTS.EDIT}`;

export const ADMIN_CMS_SITE_MENUS_PATH = `${URLS.ADMIN_SITES}/:siteId/:domain/${CMS_PATHS.SITE_MENU}`;
export const ADMIN_CMS_SITE_MENU_NEW_PATH = `${URLS.ADMIN_SITES}/:siteId/:domain/${CMS_PATHS.SITE_MENU}/${CMS_SITE_MENUS.NEW}`;
export const ADMIN_CMS_SITE_MENU_EDIT_PATH = `${URLS.ADMIN_SITE_MENU}/:siteId/:menuItemId(/:name)`;

export const ADMIN_CMS_PAGE_MENUS_PATH = `${URLS.ADMIN_SITES}/:siteId/:domain/${CMS_PATHS.PAGE_MENUS}`;
export const ADMIN_CMS_PAGE_MENU_NEW_PATH = `${URLS.ADMIN_SITES}/:siteId/:domain/${CMS_PATHS.PAGE_MENUS}/:parentItemId/${CMS_PAGE_MENUS.NEW}`;
export const ADMIN_CMS_PAGE_MENU_EDIT_PATH = `${URLS.ADMIN_PAGE_MENU}/:siteId/:parentItemId/:menuItemId(/:name)`;

export const ADMIN_CMS_RESOURCES_PATH = `${URLS.ADMIN_SITES}/:siteId/:domain/${CMS_PATHS.RESOURCES}`;
export const ADMIN_CMS_RESOURCES_CATEGORIES_PATH = `${URLS.ADMIN_SITES}/:siteId/:domain/${CMS_PATHS.RESOURCES_CATEGORIES}`;
export const ADMIN_CMS_RESOURCES_NEW_PATH = `${URLS.ADMIN_SITES}/:siteId/:domain/${CMS_PATHS.RESOURCES}/${CMS_RESOURCES.NEW}`;
export const ADMIN_CMS_RESOURCES_EDIT_PATH = `${URLS.ADMIN_RESOURCES}/:siteId/:resourceId(/:name)/${CMS_RESOURCES.EDIT}`;
export const ADMIN_CMS_RESOURCES_PREVIEW_PATH = `${URLS.ADMIN_RESOURCES}/:siteId/:resourceId(/:name)`;

export const ADMIN_CMS_SETTINGS_GENERAL_PATH = `${URLS.ADMIN_SITES}/:siteId/:domain/${CMS_PATHS.SETTINGS_GENERAL}`;
export const ADMIN_CMS_SETTINGS_MESSAGES_PATH = `${URLS.ADMIN_SITES}/:siteId/:domain/${CMS_PATHS.SETTINGS_MESSAGES}`;

import ResourcesBody from './components/Pages/CMS/Websites/Resources/Home';
import ResourceHeader from './components/Pages/CMS/Websites/Resources/Resource/Header';
import ResourceBody from './components/Pages/CMS/Websites/Resources/Resource/index';

export {default as PageListBody} from './components/Pages/CMS/Websites/Pages/Home/PageList';
import PagesBody from './components/Pages/CMS/Websites/Pages/Home';
import EditPageBody from './components/Pages/CMS/Websites/Pages/Page';
import EditPageHeader from './components/Pages/CMS/Websites/Pages/Page/Header';

import ContactsBody from './components/Pages/CMS/Websites/Contacts/Home';
import ContactHeader from './components/Pages/CMS/Websites/Contacts/Contact/Header';
import ContactBody from './components/Pages/CMS/Websites/Contacts/Contact/index';

import MenusBody from './components/Pages/CMS/Websites/Menus/Home';

import SettingsBody from './components/Pages/CMS/Websites/Settings';

// Admin Facilites related paths
export const ADMIN_FACILITY_LOCATION_FACILITIES_PATH = `${URLS.ADMIN_FACILITY_LOCATION}/:locationId/:locationName/${FACILITY_LOCATION_PATH.FACILITIES}`;
export const ADMIN_FACILITY_LOCATION_FACILITY_NEW_PATH = `${URLS.ADMIN_FACILITY_LOCATION}/:locationId/:locationName/${FACILITY_LOCATION_PATH.FACILITIES}/${FACILITY_LOCATION_FACILITY.NEW}`;
export const ADMIN_FACILITY_LOCATION_FACILITY_EDIT_PATH = `${URLS.ADMIN_FACILITY_LOCATION_FACILITY}/:locationId/:facilityId/:name`;
export const ADMIN_FACILITY_LOCATION_FACILITY_TYPES_PATH = `${URLS.ADMIN_FACILITY_LOCATION}/:locationId/:locationName/${FACILITY_LOCATION_PATH.TYPES}`;
export const ADMIN_FACILITY_LOCATION_FACILITY_TYPE_NEW_PATH = `${URLS.ADMIN_FACILITY_LOCATION}/:locationId/:locationName/${FACILITY_LOCATION_PATH.TYPES}/${FACILITY_LOCATION_FACILITY_TYPE.NEW}`;
export const ADMIN_FACILITY_LOCATION_FACILITY_TYPE_EDIT_PATH = `${URLS.ADMIN_FACILITY_LOCATION_TYPE}/:locationId/:facilityTypeId/:name`;
export const ADMIN_FACILITY_LOCATION_AVAILABILITIES_PATH = `${URLS.ADMIN_FACILITY_LOCATION}/:locationId/:locationName/${FACILITY_LOCATION_PATH.AVAILABILITIES}`;
export const ADMIN_FACILITY_LOCATION_BLACKOUT_DATES_PATH = `${URLS.ADMIN_FACILITY_LOCATION}/:locationId/:locationName/${FACILITY_LOCATION_PATH.BLACKOUT_DATES}`;
export const ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_NEW_PATH = `${URLS.ADMIN_FACILITY_LOCATION}/:locationId/:locationName/${FACILITY_LOCATION_PATH.BLACKOUT_DATES}/${FACILITY_LOCATION_BLACKOUT_DATE.NEW}`;
export const ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH = `${URLS.ADMIN_FACILITY_LOCATION_BLACKOUT_DATE}/:locationId/:blackoutDateId/:name`;
export const ADMIN_FACILITY_LOCATION_REPORTS_PATH = `${URLS.ADMIN_FACILITY_LOCATION}/:locationId/:locationName/${FACILITY_LOCATION_PATH.REPORTS}`;
export const ADMIN_FACILITY_LOCATION_SETTINGS_PATH = `${URLS.ADMIN_FACILITY_LOCATION}/:locationId/:locationName/${FACILITY_LOCATION_PATH.SETTINGS}`;

import FacilitiesHome from './components/Pages/FacilityLocation/Facilities/Home';
import FacilityTypeBody from './components/Pages/FacilityLocation/Facilities/FacilityType';
import FacilityTypeHeader from './components/Pages/FacilityLocation/Facilities/FacilityType/Header';
import FacilityPageBody from './components/Pages/FacilityLocation/Facilities/Facility';
import FacilityHeader from './components/Pages/FacilityLocation/Facilities/Facility/Header';

import AvailabilitiesHome from './components/Pages/FacilityLocation/Availabilities/Home';

import BlackoutDatesHome from './components/Pages/FacilityLocation/BlackoutDates/Home';

import ReportsHome from './components/Pages/FacilityLocation/Reports/Home';

import SettingsHome from './components/Pages/FacilityLocation/Settings/Home';

// Admin Events related paths
export const ADMIN_EVENTS_EVENTS_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventTypeName/${ADMIN_EVENTS_PATH.EVENTS}`;

export const ADMIN_EVENTS_PRODUCTS_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventTypeName/${ADMIN_EVENTS_PATH.PRODUCTS}`;
export const ADMIN_EVENTS_NEW_PRODUCT_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventTypeName/${ADMIN_EVENTS_PATH.PRODUCTS}/${ADMIN_EVENT_PRODUCT.NEW}`;
export const ADMIN_EVENTS_EDIT_PRODUCT_PATH = `${URLS.ADMIN_EVENT_PRODUCT}/:eventTypeId/:productId/:productName`;

export const ADMIN_EVENTS_REPORTS_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventTypeName/${ADMIN_EVENTS_PATH.REPORTS}`;
export const ADMIN_EVENTS_YEAR_OVERVIEW_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventTypeName/${ADMIN_EVENTS_PATH.REPORTS}/${ADMIN_EVENTS_PATH.YEAR_OVERVIEW}`;

export const ADMIN_EVENTS_MESSAGE_CENTER_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventTypeName/${ADMIN_EVENTS_PATH.MESSAGE_CENTER}`;
export const ADMIN_EVENTS_NEW_MESSAGE_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventTypeName/${ADMIN_EVENTS_PATH.MESSAGE_CENTER}/${ADMIN_EVENT_MESSAGE.NEW}`;
export const ADMIN_EVENTS_EDIT_MESSAGE_PATH = `${URLS.ADMIN_EVENT_MESSAGE}/:eventTypeId/:messageId/:messageName`;

export const ADMIN_EVENTS_SETTINGS_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventTypeName/${ADMIN_EVENTS_PATH.SETTINGS}`;
export const ADMIN_EVENT_DASHBOARD_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventId/:eventName/${ADMIN_EVENTS_EVENT_PATH.DASHBOARD}`;
export const ADMIN_EVENT_SETTINGS_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventId/:eventName/${ADMIN_EVENTS_EVENT_PATH.SETTINGS}`;

export const ADMIN_EVENT_CLASS_TYPES_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventId/:eventName/${ADMIN_EVENTS_EVENT_PATH.CLASS_TYPES}`;
export const ADMIN_EVENT_NEW_CLASS_TYPE_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventId/:eventName/${ADMIN_EVENTS_EVENT_PATH.CLASS_TYPES}/${ADMIN_EVENT_CLASS_TYPE.NEW}`;
export const ADMIN_EVENT_EDIT_CLASS_TYPE_PATH = `${URLS.ADMIN_EVENT_CLASS_TYPE}/:eventTypeId/:eventId/:classTypeId/:classTypeName`;


export const ADMIN_EVENT_CLASSES_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventId/:eventName/${ADMIN_EVENTS_EVENT_PATH.CLASS_LIST}`;
export const ADMIN_EVENT_NEW_CLASS_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventId/:eventName/${ADMIN_EVENTS_EVENT_PATH.CLASS_LIST}/${ADMIN_EVENT_CLASS.NEW}`;
export const ADMIN_EVENT_EDIT_CLASS_PATH = `${URLS.ADMIN_EVENT_CLASS}/:eventTypeId/:eventId/:classId/:className`;

export const ADMIN_EVENT_TIME_BLOCKS_PATH = `${URLS.ADMIN_EVENTS}/:eventTypeId/:eventId/:eventName/${ADMIN_EVENTS_EVENT_PATH.TIME_BLOCKS}`;


import EventsHome from './components/Pages/AdminEvents/Events/Home';
import ProductsHome from './components/Pages/AdminEvents/Products/Home';
import AdminEventsReportsHome from './components/Pages/AdminEvents/Reports/Home';
import AdminEventsMessageCenterHome from './components/Pages/AdminEvents/MessageCenter/Home';
import AdminEventsSettingsHome from './components/Pages/AdminEvents/Settings/Home';
import AdminEventsEventDashboard from './components/Pages/AdminEvents/Events/Event/Dashboard';
import AdminEventsEventClasses from './components/Pages/AdminEvents/Events/Event/Classes';
import AdminEventsEventSettings from './components/Pages/AdminEvents/Events/Event/Settings';
import {WithInertPage} from './components/Elements/WithInert';


export default () => {
  const routes = [
      <Route
        path={URLS.LOGIN}
        components={{ body: WithInertPage(Pages.Login), header: Pages.LoginHeader }}
      />,
      <Route
        path={URLS.NEW_ACCOUNT}
        components={{ body: WithInertPage(Pages.NewAccount), header: Pages.NewAccountHeader }}
      />,
      <Route
        path={URLS.HOME}
        components={{ body: WithInertPage(Home), nav: Nav(NAVS.HOME), header: Pages.HomeHeader()}}
      />,
      <Route
        path={URLS.PROFILE}
        components={{ body: WithInertPage(Pages.Profile), nav: Nav(NAVS.SETTINGS), header: Pages.SettingsHeader({ selected: 'profile', refresh: false }) }}
      />,
      <Route
        path={URLS.MY_ROSTER}
        components={{ body: WithInertPage(Pages.Roster), nav: Nav(NAVS.SETTINGS), header: Pages.SettingsHeader({ selected: 'my_roster', search: true, refresh: true }) }}
      />,
      <Route
        path={ROSTER_ADD_PATH}
        components={{ body: WithInertPage(Pages.RosterPerson), nav: Nav(NAVS.SETTINGS), header: Pages.RosterPersonHeader({ selected: 'my_roster', back: URLS.MY_ROSTER}) }}
      />,
      <Route
        path={ROSTER_EDIT_PATH}
        components={{ body: WithInertPage(Pages.RosterPerson), nav: Nav(NAVS.SETTINGS), header: Pages.RosterPersonHeader({ selected: 'my_roster', back: URLS.MY_ROSTER }) }}
      />,
      <Route
        path={URLS.ORDER_HISTORY}
        components={{ body: WithInertPage(Pages.Orders), nav: Nav(NAVS.SETTINGS), header: Pages.SettingsHeader({ selected: 'order_history', refresh: true, info: true }) }}
      >,
      <Route
        path={VIEW_ORDER_PATH}
        components={{ body: WithInertPage(Pages.Orders), nav: Nav(NAVS.SETTINGS), header: Pages.SettingsHeader({ selected: 'order_history', refresh: true, info: true }) }}
      />,
      </Route>,
      <Route
        path={URLS.CHECKOUT}
        components={{ body: WithInertPage(Pages.Checkout), nav: Nav(NAVS.CHECKOUT), header: Pages.CheckoutHeader }}
      />,
      <Route
        path={URLS.EVENT_TYPES}
        components={{ body: WithInertPage(Pages.EventTypes), nav: Nav(NAVS.EVENTS), header: Pages.EventTypesHeader }}
      />,
      <Route
        path={END_USER_EVENT_TYPE_PATH}
        components={{ body: WithInertPage(Pages.EventType), nav: Nav(NAVS.EVENTS), header: Pages.EventTypeHeader }}
      />,
      <Route
        path={END_USER_EVENT_PATH}
        components={{ body: WithInertPage(Pages.Event), nav: Nav(NAVS.EVENTS), header: Pages.EventHeader('info')}}
      />,
      <Route
        path={END_USER_EVENT_REGISTRATION_PATH}
        components={{ body: WithInertPage(Pages.EventRegistration), nav: Nav(NAVS.EVENTS), header: Pages.EventHeader('registration') }}
      />,
      <Route
        path={END_USER_EVENT_CLASSES_PATH}
        components={{ body: WithInertPage(Pages.EventClasses), nav: Nav(NAVS.EVENTS), header: Pages.EventClassesHeader() }}
      />,
      <Route
        path={END_USER_EVENT_REGISTRATION_CLASSES_PATH}
        components={{ body: WithInertPage(Pages.EventRegistrationClasses), nav: Nav(NAVS.EVENTS), header: Pages.EventRegistrationClassesHeader() }}
      />,
      <Route
        path={END_USER_EVENT_REGISTRATION_PARTICIPANT_PATH}
        components={{ body: WithInertPage(Pages.EventRegistrationParticipant), nav: Nav(NAVS.EVENTS), header: Pages.EventRegistrationParticipantHeader() }}
      />,
      <Route
        path={PRODUCTS_WIZARD_PATH}
        components={{ body: WithInertPage(Pages.EventRegisterProducts), nav: Nav(NAVS.EVENTS), header: ProductsWizardHeader() }}
      />,
      <Route
        path={NUMBERS_WIZARD_PATH}
        components={{ body: WithInertPage(Pages.EventRegisterNumbers), nav: Nav(NAVS.EVENTS), header: NumbersWizardHeader() }}
      />,
      <Route
        path={PARTICIPANT_WIZARD_PATH}
        components={{ body: WithInertPage(Pages.EventRegisterParticipants), nav: Nav(NAVS.EVENTS), header: ParticipantWizardHeader() }}
      />,
      <Route
        path={PARTICIPANT_WIZARD_EDIT_PATH}
        components={{ body: WithInertPage(Pages.EventRegisterParticipants), nav: Nav(NAVS.EVENTS), header: ParticipantWizardHeader() }}
      />,
      <Route
        path={URLS.FACILITIES}
        components={{ body: WithInertPage(Pages.FacilitiesLocations), nav: Nav(NAVS.FACILITIES), header: Pages.FacilitiesLocationsHeader }}
      />,
      <Route
        path={END_USER_FACILITY_PATH}
        components={{ body: WithInertPage(Pages.FacilitiesLocation(false)), nav: Nav(NAVS.FACILITIES), header: Pages.FacilitiesLocationHeader }}
      />,
      <Route
        path={END_USER_FACILITYB_PATH_WITH_FTBID}
        components={{ body: WithInertPage(Pages.FacilitiesFacility), nav: Nav(NAVS.FACILITIES), header: Pages.FacilitiesFacilityHeader }}
      />,
      <Route
        path={END_USER_FACILITYB_PATH}
        components={{ body: WithInertPage(Pages.FacilitiesFacility), nav: Nav(NAVS.FACILITIES), header: Pages.FacilitiesFacilityHeader }}
      />,
      <Route
        path={END_USER_ADD_TRIP_PATH}
        components={{ body: WithInertPage(Pages.AddTrip), nav: Nav(NAVS.FACILITIES), header: Pages.FacilitiesTripTripHeader }}
      />,
      <Route
        path={END_USER_TRIP_SUMMARY_PATH}
        components={{ body: WithInertPage(Pages.FacilitiesTripSummary), nav: Nav(NAVS.FACILITIES), header: Pages.FacilitiesTripSummaryHeader }}
      />,
      <Route
        path={END_USER_EDIT_TRIP_PATH}
        components={{ body: WithInertPage(Pages.EditTrip), nav: Nav(NAVS.FACILITIES), header: Pages.FacilitiesTripTripHeader }}
      />,
      <Route
        path={FACILITY_WIZARD_PATH}
        components={{ body: WithInertPage(FacilityWizard), nav: Nav(NAVS.FACILITIES), header: Pages.FacilitiesTripReservationHeader() }}
      />,
      <Route
        path={END_USER_VIEW_RESERVATION_PATH}
        components={{ body: WithInertPage(Pages.ViewReservation), nav: Nav(NAVS.FACILITIES), header: Pages.ViewReservationHeader }}
      />,
      <Route
        path={FACILITY_WIZARD_EDIT_PATH}
        components={{ body: WithInertPage(FacilityWizard), nav: Nav(NAVS.FACILITIES), header: Pages.FacilitiesTripReservationHeader() }}
      />,
      <Route
        path={URLS.ADD_GROUP}
        components={{ body: WithInertPage(Pages.AddGroup), nav: Nav(NAVS.ADMIN), header: Pages.AddGroupHeader() }}
      />,
      // pages
      <Route
        path={ADMIN_CMS_PAGES_PATH}
        components={{ body: WithInertPage(PagesBody), nav: Nav(NAVS.WEBSITES), header: Pages.CMSHeader({ selected: 'pages', refresh: true, add: true, mobileAdd: true })}}
      />,
      <Route
        path={ADMIN_CMS_PAGE_PATH}
        components={{ body: WithInertPage(EditPageBody), nav: Nav(NAVS.WEBSITES), header: EditPageHeader }}
      />,
      // CMS Contact
      <Route
        path={ADMIN_CMS_CONTACTS_PATH}
        components={{ body: WithInertPage(ContactsBody), nav: Nav(NAVS.WEBSITES), header: Pages.CMSHeader({ selected: 'contacts', add: true, mobileAdd: true, refresh: true })}}
      />,
      <Route
        path={ADMIN_CMS_CONTACT_NEW_PATH}
        components={{ body: WithInertPage(ContactBody), nav: Nav(NAVS.WEBSITES), header: ContactHeader}}
      />,
      <Route
        path={ADMIN_CMS_CONTACT_EDIT_PATH}
        components={{ body: WithInertPage(ContactBody), nav: Nav(NAVS.WEBSITES), header: ContactHeader}}
      />,
      <Route
        path={ADMIN_CMS_CONTACT_PREVIEW_PATH}
        components={{ body: WithInertPage(ContactsBody), nav: Nav(NAVS.WEBSITES), header: Pages.CMSHeader({ selected: 'contacts', refresh: true })}}
      />,
      // CMS Settings
      <Route
        path={ADMIN_CMS_SETTINGS_GENERAL_PATH}
        components={{ body: WithInertPage(SettingsBody), nav: Nav(NAVS.WEBSITES), header: Pages.CMSHeader({ selected: 'settings', refresh: true })}}
      />,
      <Route
        path={ADMIN_CMS_SETTINGS_MESSAGES_PATH}
        components={{ body: WithInertPage(SettingsBody), nav: Nav(NAVS.WEBSITES), header: Pages.CMSHeader({ selected: 'settings', refresh: true })}}
      />,
      // CMS Resources
      <Route
        path={ADMIN_CMS_RESOURCES_NEW_PATH}
        components={{ body: WithInertPage(ResourceBody), nav: Nav(NAVS.WEBSITES), header: ResourceHeader}}
      />,
      <Route
        path={ADMIN_CMS_RESOURCES_PATH}
        components={{ body: WithInertPage(ResourcesBody), nav: Nav(NAVS.WEBSITES), header: Pages.CMSHeader({ selected: 'resources', add: true, mobileAdd: true, refresh: true, })}}
      />,
      <Route
        path={ADMIN_CMS_RESOURCES_CATEGORIES_PATH}
        components={{ body: WithInertPage(ResourcesBody), nav: Nav(NAVS.WEBSITES), header: Pages.CMSHeader({ selected: 'resources', refresh: true, add: true, })}}
      />,
      <Route
        path={ADMIN_CMS_RESOURCES_PREVIEW_PATH}
        components={{ body: WithInertPage(ResourcesBody), nav: Nav(NAVS.WEBSITES), header: Pages.CMSHeader({ selected: 'resources', refresh: true, })}}
      />,
      <Route
        path={ADMIN_CMS_RESOURCES_EDIT_PATH}
        components={{ body: WithInertPage(ResourceBody), nav: Nav(NAVS.WEBSITES), header: ResourceHeader}}
      />,
      // Site Menus
      <Route
        path={ADMIN_CMS_SITE_MENUS_PATH}
        components={{ body: WithInertPage(MenusBody), nav: Nav(NAVS.WEBSITES), header: Pages.CMSHeader({ selected: 'menus', add: true, refresh: true })}}
      />,
      <Route
        path={ADMIN_CMS_SITE_MENU_NEW_PATH}
        components={{ body: WithInertPage(MenusBody), nav: Nav(NAVS.WEBSITES), header: Pages.CMSHeader({ selected: 'menus' })}}
      />,
      <Route
        path={ADMIN_CMS_SITE_MENU_EDIT_PATH}
        components={{ body: WithInertPage(MenusBody), nav: Nav(NAVS.WEBSITES), header: Pages.CMSHeader({ selected: 'menus', refresh: true })}}
      />,
      // Page Menus
      <Route
        path={ADMIN_CMS_PAGE_MENUS_PATH}
        components={{ body: WithInertPage(MenusBody), nav: Nav(NAVS.WEBSITES), header: Pages.CMSHeader({ selected: 'menus', add: true, refresh: true })}}
      />,
      <Route
        path={ADMIN_CMS_PAGE_MENU_NEW_PATH}
        components={{ body: WithInertPage(MenusBody), nav: Nav(NAVS.WEBSITES), header: Pages.CMSHeader({ selected: 'menus' })}}
      />,
      <Route
        path={ADMIN_CMS_PAGE_MENU_EDIT_PATH}
        components={{ body: WithInertPage(MenusBody), nav: Nav(NAVS.WEBSITES), header: Pages.CMSHeader({ selected: 'menus', refresh: true })}}
      />,

      // AdminEvents - Events
      <Route
        path={ADMIN_EVENTS_EVENTS_PATH}
        components={{ body: WithInertPage(EventsHome), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsHeader({ selected: 'events', refresh: true, add: true, mobileAdd: true })}}
      />,
      <Route
        path={ADMIN_EVENTS_PRODUCTS_PATH}
        components={{ body: WithInertPage(ProductsHome), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsHeader({ selected: 'products', refresh: true, add: true, mobileAdd: true })}}
      />,
      <Route
        path={ADMIN_EVENTS_NEW_PRODUCT_PATH}
        components={{ body: WithInertPage(ProductsHome), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsHeader({ selected: 'products' })}}
      />,
      <Route
        path={ADMIN_EVENTS_EDIT_PRODUCT_PATH}
        components={{ body: WithInertPage(ProductsHome), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsHeader({ selected: 'products', refresh: true })}}
      />,
      <Route
        path={ADMIN_EVENTS_REPORTS_PATH}
        components={{ body: WithInertPage(AdminEventsReportsHome), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsHeader({ selected: 'reports', refresh: true })}}
      />,
      <Route
        path={ADMIN_EVENTS_YEAR_OVERVIEW_PATH}
        components={{ body: WithInertPage(AdminEventsReportsHome), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsHeader({ selected: 'reports', refresh: true })}}
      />,
      <Route
        path={ADMIN_EVENTS_MESSAGE_CENTER_PATH}
        components={{ body: WithInertPage(AdminEventsMessageCenterHome), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsHeader({ selected: 'message_center', refresh: true, add: true, mobileAdd: true })}}
      />,
      <Route
        path={ADMIN_EVENTS_NEW_MESSAGE_PATH}
        components={{ body: WithInertPage(AdminEventsMessageCenterHome), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsHeader({ selected: 'message_center' })}}
      />,
      <Route
        path={ADMIN_EVENTS_EDIT_MESSAGE_PATH}
        components={{ body: WithInertPage(AdminEventsMessageCenterHome), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsHeader({ selected: 'message_center', refresh: true })}}
      />,
      <Route
        path={ADMIN_EVENTS_SETTINGS_PATH}
        components={{ body: WithInertPage(AdminEventsSettingsHome), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsHeader({ selected: 'settings', refresh: true })}}
      />,
      // AdminEvents - Event
      <Route
        path={ADMIN_EVENT_TIME_BLOCKS_PATH}
        components={{ body: WithInertPage(AdminEventsEventClasses), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsEventHeader({ selected: 'classes', refresh: true })}}
      />,
      <Route
        path={ADMIN_EVENT_DASHBOARD_PATH}
        components={{ body: WithInertPage(AdminEventsEventDashboard), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsEventHeader({ selected: 'dashboard', refresh: true })}}
      />,
      <Route
        path={ADMIN_EVENT_CLASS_TYPES_PATH}
        components={{ body: WithInertPage(AdminEventsEventClasses), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsEventHeader({ selected: 'classes', refresh: true, add: true, mobileAdd: true })}}
      />,
      <Route
        path={ADMIN_EVENT_CLASSES_PATH}
        components={{ body: WithInertPage(AdminEventsEventClasses), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsEventHeader({ selected: 'classes', refresh: true, add: true, mobileAdd: true })}}
      />,
      <Route
        path={ADMIN_EVENT_NEW_CLASS_PATH}
        components={{ body: WithInertPage(AdminEventsEventClasses), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsEventHeader({ selected: 'classes' })}}
      />,
      <Route
        path={ADMIN_EVENT_EDIT_CLASS_PATH}
        components={{ body: WithInertPage(AdminEventsEventClasses), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsEventHeader({ selected: 'classes', refresh: true })}}
      />,
      <Route
        path={ADMIN_EVENT_SETTINGS_PATH}
        components={{ body: WithInertPage(AdminEventsEventSettings), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsEventHeader({ selected: 'settings', refresh: true })}}
      />,
      <Route
        path={ADMIN_EVENT_NEW_CLASS_TYPE_PATH}
        components={{ body: WithInertPage(AdminEventsEventClasses), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsEventHeader({ selected: 'classes' })}}
      />,
      <Route
        path={ADMIN_EVENT_EDIT_CLASS_TYPE_PATH}
        components={{ body: WithInertPage(AdminEventsEventClasses), nav: Nav(NAVS.ADMIN_EVENTS), header: Pages.AdminEventsEventHeader({ selected: 'classes', refresh: true })}}
      />,
      

      // FacilityLocation - Facilities
      <Route
        path={ADMIN_FACILITY_LOCATION_FACILITIES_PATH}
        components={{ body: WithInertPage(FacilitiesHome), nav: Nav(NAVS.FACILITY_LOCATION), header: Pages.AdminFacilityLocationHeader({ selected: 'facilities', refresh: true, add: true, mobileAdd: true })}}
      />,
      <Route
        path={ADMIN_FACILITY_LOCATION_FACILITY_TYPES_PATH}
        components={{ body: WithInertPage(FacilitiesHome), nav: Nav(NAVS.FACILITY_LOCATION), header: Pages.AdminFacilityLocationHeader({ selected: 'facilities', refresh: true, add: true, mobileAdd: true })}}
      />,
      <Route
        path={ADMIN_FACILITY_LOCATION_FACILITY_TYPE_NEW_PATH}
        components={{ body: WithInertPage(FacilityTypeBody), nav: Nav(NAVS.FACILITY_LOCATION), header: FacilityTypeHeader}}
      />,
      <Route
        path={ADMIN_FACILITY_LOCATION_FACILITY_TYPE_EDIT_PATH}
        components={{ body: WithInertPage(FacilityTypeBody), nav: Nav(NAVS.FACILITY_LOCATION), header: FacilityTypeHeader}}
      />,
      <Route
        path={ADMIN_FACILITY_LOCATION_FACILITY_EDIT_PATH}
        components={{ body: WithInertPage(FacilityPageBody), nav: Nav(NAVS.FACILITY_LOCATION), header: FacilityHeader}}
      />,

      // FacilityLocation - Availabilities
      <Route
        path={ADMIN_FACILITY_LOCATION_AVAILABILITIES_PATH}
        components={{ body: WithInertPage(AvailabilitiesHome), nav: Nav(NAVS.FACILITY_LOCATION), header: Pages.AdminFacilityLocationHeader({ selected: 'availabilities', refresh: true })}}
      />,

      // FacilityLocation - BlackoutDates
      <Route
        path={ADMIN_FACILITY_LOCATION_BLACKOUT_DATES_PATH}
        components={{ body: WithInertPage(BlackoutDatesHome), nav: Nav(NAVS.FACILITY_LOCATION), header: Pages.AdminFacilityLocationHeader({ selected: 'blackout_dates', refresh: true, add: true, mobileAdd: true })}}
      />,
      <Route
        path={ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_NEW_PATH}
        components={{ body: WithInertPage(BlackoutDatesHome), nav: Nav(NAVS.FACILITY_LOCATION), header: Pages.AdminFacilityLocationHeader({ selected: 'blackout_dates' })}}
      />,
      <Route
        path={ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH}
        components={{ body: WithInertPage(BlackoutDatesHome), nav: Nav(NAVS.FACILITY_LOCATION), header: Pages.AdminFacilityLocationHeader({ selected: 'blackout_dates', refresh: true })}}
      />,
      // FacilityLocation - Reports
      <Route
        path={ADMIN_FACILITY_LOCATION_REPORTS_PATH}
        components={{ body: WithInertPage(ReportsHome), nav: Nav(NAVS.FACILITY_LOCATION), header: Pages.AdminFacilityLocationHeader({ selected: 'reports', refresh: false })}}
      />,

      // FacilityLocation - Settings
      <Route
        path={ADMIN_FACILITY_LOCATION_SETTINGS_PATH}
        components={{ body: WithInertPage(SettingsHome), nav: Nav(NAVS.FACILITY_LOCATION), header: Pages.AdminFacilityLocationHeader({ selected: 'settings', refresh: true })}}
      />,
      <Route
        path="*"
        components={{ body: WithInertPage(Pages.NotFound), nav: Nav(), header: Pages.NotFoundHeader()}}
      />,
  ];
  return (
    <Route onEnter={forceNoTrailingSlash} component={App} children={routes} />
  );
};

/**
 * Use this function to enforce no trailing slash in pathname, so that we can safely perform
 * pattern match when comparing current pathname to routes defined above
 */
const forceNoTrailingSlash: EnterHook = (nextState, replace) => {
  const path = nextState.location.pathname;
  const trailingSlashes = /\/+$/;
  if (path.match(trailingSlashes)) {
    replace({
      ...nextState.location,
      pathname: path.replace(trailingSlashes, ""),
    });
  }
};