import * as React from 'react';
import { Modal, ModalTitle, ModalActions, Button, ModalContent } from '../../../../../Elements';
import {withRouter, RouteComponentProps} from "react-router";
import {bindActionCreators} from "redux";
import {actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import {ApplicationState} from "../../../../../../store";
import '../../../../../../styles/pages/admin-events/event-types/modal/replace-participant-type/index.scss';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--modal--replace-participant-type';

type Props = WithInertAttribute<{}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

@(withRouter as any)
class RemoveParticipantTypeModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  
  onCancel = (e?: any) => {
    this.props.actions.popModal(false, true, ModalTypes.REMOVE_PARTICIPANT_TYPE);
  };

  onDelete = () => {
  };

  public render() {
    const {apiSaving, actions, inert, apiLoading} = this.props;
    
    return (
      <Modal inert={inert} disableDynamicHeight bodyScrollLock big higherZIndex>
        <ModalTitle marginBottom={8}>{`Participant Type can't be removed`}</ModalTitle>
        <ModalContent paddingTop={0} paddingBottom={0}>
          <div className={`${namespace()}--helper-text`}>At least one youth and one adult participant type is required. Please first add another participant type or simply rename this type to continue.</div>
        </ModalContent>
        <ModalActions
          noPadding
          loading={apiSaving > 0 || apiLoading > 0}
          stacked
          alignRight
          left={<Button flat textColor="blue" onClick={this.onCancel}>CLOSE</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...appActionCreators,
}, dispatch)});

const ConnectedRemoveParticipantTypeModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(RemoveParticipantTypeModal);

export default ConnectedRemoveParticipantTypeModal;
