import * as React from 'react';
import Both from './Both';
import Participant from './Participant';
import {CacheTwoEventsState} from "../../../../../../../store/CacheTwoEvents/index";
import {ApplicationState} from "../../../../../../../store";
import {
  CacheFourEventsParticipantsState,
  makeAvailableClassesFilter,
  makeAvailableClassesNoTextFilter,
  makeConflictingClassesNoTextFilter,
  makeRegisteredClassesFilter
} from "../../../../../../../store/CacheFourEventsParticipants";
import {
  makeYouthParticipantTypesSelector,
  makeAdultParticipantTypesSelector
} from "../../../../../../../store/CacheFourEventsParticipants";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../../../../../../store/Events/Event/Register/Participant/Type/actions";
import {actionCreators as appActionCreators} from "../../../../../../../store/App/actions";
import {actionCreators as registerOverrideFeeActionsCreators} from "../../../../../../../store/Events/Event/Modals/RegisterOverrideFee/actions";
import {
  CacheThreeEventsState, makeAdultParticipantTypeSelector,
  makeYouthParticipantTypeSelector
} from "../../../../../../../store/CacheThreeEvents";
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

// @todo: in the other tabs we pass this in
type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = WithInertAttribute<{
  participantId?: string;
  cacheTwoEvents: CacheTwoEventsState;
  cacheThreeEvents: CacheThreeEventsState;
  cacheFourEventsParticipants: CacheFourEventsParticipantsState;
  apiLoading: number;
}>;

const Type: React.SFC<Props> = ({
  actions,
  youthTypes,
  adultTypes,
  user,
  cacheTwoEvents,
  cacheFourEventsParticipants,
  cacheThreeEvents,
  cacheThreeYouthTypes,
  cacheThreeAdultTypes,
  apiLoading,
  type,
  participantId,
  noFilterAvailableClasses,
  noFilterRegisteredClasses,
  noFilterConflictingClasses,
  inert,
}: ConnectedProps & Props): React.ReactElement<Props> => {
  if (cacheTwoEvents.eventType === 'participant') {
    return <Participant
      inert={inert}
      participantId={participantId}
      type={type}
      pTypes={cacheFourEventsParticipants.isYouth ? youthTypes : adultTypes}
      user={user}
      cacheFourEventsParticipants={cacheFourEventsParticipants}
      cacheThreeEvents={cacheThreeEvents}
      cacheThreePTypes={cacheFourEventsParticipants.isYouth ? cacheThreeYouthTypes : cacheThreeAdultTypes}
      cacheTwoEvents={cacheTwoEvents}
      onSelect={actions.selectType}
      apiLoading={apiLoading}
      actions={actions}
      availableClasses={noFilterAvailableClasses}
      conflictingClasses={noFilterConflictingClasses}
      registeredClasses={noFilterRegisteredClasses}
    />;
  } else if (cacheTwoEvents.eventType === 'both') {
    return <Both
      inert={inert}
      participantId={participantId}
      type={type}
      cacheFourEventsParticipants={cacheFourEventsParticipants}
      cacheThreeEvents={cacheThreeEvents}
      cacheTwoEvents={cacheTwoEvents}
      pTypes={cacheFourEventsParticipants.isYouth ? youthTypes : adultTypes}
      cacheThreePTypes={cacheFourEventsParticipants.isYouth ? cacheThreeYouthTypes : cacheThreeAdultTypes}
      apiLoading={apiLoading}
      onSelect={actions.selectType}
      availableClasses={noFilterAvailableClasses}
      conflictingClasses={noFilterConflictingClasses}
      registeredClasses={noFilterRegisteredClasses}
    />;
  }
  return <div></div>;
};

const mapStateToProps = (state: ApplicationState) => {
  const youthTypes = makeYouthParticipantTypesSelector();
  const adultTypes = makeAdultParticipantTypesSelector();
  const cacheThreeYouthTypes = makeYouthParticipantTypeSelector();
  const cacheThreeAdultTypes = makeAdultParticipantTypeSelector();
  const noFilterAvailableClasses = makeAvailableClassesNoTextFilter();
  const noFilterRegisteredClasses = makeRegisteredClassesFilter();
  const noFilterConflictingClasses = makeConflictingClassesNoTextFilter();
  return {
    user: state.user,
    type: state.events.event.register.participant.type,
    youthTypes: youthTypes(state),
    adultTypes: adultTypes(state),
    cacheThreeYouthTypes: cacheThreeYouthTypes(state),
    cacheThreeAdultTypes: cacheThreeAdultTypes(state),
    noFilterAvailableClasses: noFilterAvailableClasses(state),
    noFilterRegisteredClasses: noFilterRegisteredClasses(state),
    noFilterConflictingClasses: noFilterConflictingClasses(state)
  };
};

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
  ...registerOverrideFeeActionsCreators
}, dispatch)});

const ConnectedType = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(Type);

export default ConnectedType;
