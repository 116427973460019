import * as React from 'react';
import { ContentBlock, Title, Button, ShowMoreButton, EmptyMessage} from '../../../Elements';
import {ProductIcon} from '../../../Icons';
import { withRouter, RouteComponentProps } from 'react-router';
import '../../../../styles/pages/events/event/groupProducts.scss';
import {Product} from "../../../../models/product";

export const namespace = (): string => 'pages--events--event--group-products';

// refactoring this to use on both pages
interface Props {
  children?: React.ReactNode; // @todo: should just pass in products, but "ordered" attribute gets in the way, change it when we hook up to API and see how ordered is determined
  title: string;
  emptyMessage?: string;
  emptyButtonText?: string;
  hideManage?: boolean;
  eventType: 'numbers' | 'participant' | 'both';
  numProducts: number;
  showMoreProducts: boolean;
  onShowMore: () => void;
  onManage?: () => void;
}

type ConnectedProps =  RouteComponentProps<{}, {}>;

class GroupProducts extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;


  public render() {
    const { onManage, children, title, emptyMessage, emptyButtonText, hideManage, eventType, numProducts, onShowMore, showMoreProducts } = this.props;
    const hideManageButton = hideManage;

    return (<ContentBlock className={namespace()}>
      <Title
        size={28}
        mobileSize={22}
        num={numProducts}
        controls={hideManageButton ? undefined : <Button color="white" onClick={onManage}>MANAGE</Button>}
        controlsWrap={false}
      >
        {title}
      </Title>
      {!!emptyMessage && !!emptyButtonText && numProducts === 0 ? (
          <EmptyMessage
            small
            fixedMargin
            icon={ProductIcon}
            description={emptyMessage}
            actions={<Button color="green" onClick={onManage}>{emptyButtonText}</Button>}
          />
        ) : null}
      {children}
      {numProducts < 4 ? null : <ShowMoreButton showLess={showMoreProducts} onClick={onShowMore}/>}
    </ContentBlock>);
  }
}


export default withRouter(GroupProducts as any);
