import { makeRequestEpic } from "../../../../../store/Validation/epicCreator";
import { AddGLAccountSubmitActions } from "./actions";
import { getUpdateGLAccountUrl } from '../../../../../constants/urls';
import { AjaxResponse, Observable } from "rxjs/Rx";
import { ENTITY_PARSE_ERROR, ENTITY_EDITED, ENTITY_CREATED } from "../../../../../constants/messages/adminCMS";
import { GL_ACCOUNT } from "../../../../../constants/messages/adminSettings";
import { GLAccount } from "../../../../../models/api/options";
import {validate} from "../../../../../utils";
import { ValidateActions as FacilityFormValidateActions } from '../../../../../store/AdminFacilityLocation/Facilities/Facility/Form/actions';
import { ValidateActions as ClassFormValidateActions } from '../../../../../store/AdminEvents/Classes/Form/actions';
import { ValidateActions as ProductFormValidateActions } from '../../../../../store/AdminEvents/Products/Form/actions';
import { ValidateActions as EventFormValidateActions } from '../../../../../store/AdminEvents/Events/Event/Form/actions';
import { FacilityDetailFormDefinition } from '../../../../../store/AdminFacilityLocation/Facilities/Facility/Form/validation';
import { AdminEventClassFormDefinition } from '../../../../../store/AdminEvents/Classes/Form/validation';
import { AdminEventProductFormDefinition } from '../../../../../store/AdminEvents/Products/Form/validation';
import { FormDefinition as AdminEventFormDefinition } from '../../../../../store/AdminEvents/Events/Event/Form/validation';
import { ModalTypes, isModalOpened } from "../../../../../utils/modalHelper";
import { captureTentarooError } from "../../../../../utils/dataHelper";
import { popModalObservables } from "../../../../../utils/modalHelper";
import { NoOp } from "../../../../App/actions";
import { Action } from "redux";
import { reduxStoreService } from "../../../../service";
import {makeFormModalPropSelector} from "../../../../App";

export const adminSettingsAddGLAccountEpic = makeRequestEpic(
  AddGLAccountSubmitActions,
  getUpdateGLAccountUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      if (body.row.ID) {
        return ENTITY_EDITED(GL_ACCOUNT);
      }
      return ENTITY_CREATED(GL_ACCOUNT);
    } catch (e) {
      console.log(ENTITY_PARSE_ERROR(GL_ACCOUNT));
      return '';
    }
  },
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  (payload): Observable<Action> => {
    const GLAccount = payload.response.GLAccount as GLAccount;
    const state = reduxStoreService().getState();
    const bodyStr = payload.request.body;

    if (!isModalOpened(ModalTypes.SELECT_GL_ACCOUNTS)) return Observable.of(new NoOp());
    else {
      const contextSelector = makeFormModalPropSelector(
        ModalTypes.SELECT_GL_ACCOUNTS,
        "glAccountContext"
      );
      const context = contextSelector(state);
      try {
        const validateObservables: Observable<any>[] = [];

        if (context === "facility-form") {
          const validationResults = validate(
            FacilityDetailFormDefinition.GLAccountID,
            GLAccount.ID,
            () => state.adminFacilityLocation.facilities.facility.form.Detail,
          );
          validateObservables.push(
            Observable.of(FacilityFormValidateActions.updateCurr(GLAccount.ID, 'GLAccountID', FacilityDetailFormDefinition.GLAccountID)),
            Observable.of(FacilityFormValidateActions.update(validationResults, 'GLAccountID')),
          );
        } else if (context === "class-form") {
          const validationResults = validate(
            AdminEventClassFormDefinition.GLAccountID,
            GLAccount.ID,
            () => state.adminEvents.classes.form,
          );
          validateObservables.push(
            Observable.of(ClassFormValidateActions.updateCurr(GLAccount.ID, 'GLAccountID', AdminEventClassFormDefinition.GLAccountID)),
            Observable.of(ClassFormValidateActions.update(validationResults, 'GLAccountID')),
          );
        } else if (context === "product-form") {
          const validationResults = validate(
            AdminEventProductFormDefinition.GLAccountID,
            GLAccount.ID,
            () => state.adminEvents.products.form,
          );
          validateObservables.push(
            Observable.of(ProductFormValidateActions.updateCurr(GLAccount.ID, 'GLAccountID', AdminEventProductFormDefinition.GLAccountID)),
            Observable.of(ProductFormValidateActions.update(validationResults, 'GLAccountID')),
          );
        } else if (context === "event-form") {
          const validationResults = validate(
            AdminEventFormDefinition.GLAccountID,
            GLAccount.ID,
            () => state.adminEvents.events.event.form,
          );
          validateObservables.push(
            Observable.of(EventFormValidateActions.updateCurr(GLAccount.ID, 'GLAccountID', AdminEventFormDefinition.GLAccountID)),
            Observable.of(EventFormValidateActions.update(validationResults, 'GLAccountID')),
          );
        }
  
        return Observable.concat(
          ...validateObservables,
          // NOTE: No need to set saveAfter: true here because this callback will be executed
          // before the `SaveState` in epicCreator success routine
          ...popModalObservables({
            modal: ModalTypes.SELECT_GL_ACCOUNTS,
            saveBefore: false,
            saveAfter: false,
            transformToObservable: true,
          })
        );
  
      } catch(e) {
        captureTentarooError(new Error(ENTITY_PARSE_ERROR(GL_ACCOUNT)));

        return Observable.of(new NoOp());
      }
    }
  },
);