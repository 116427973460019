import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { isNode, fixedHeight } from '../../../utils';
import { SlideIn } from '../../Elements';
import {actionCreators} from "../../../store/App/actions";
import { bindActionCreators } from 'redux';
import '../../../styles/app/nav/container.scss';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';
import {Nullable} from '../../../utils/dataHelper';
import { WithInertAttribute, WithInertPage } from '../../Elements/WithInert';

const namespace = (): string => 'app--nav-container';

interface Props {
  children?: React.ReactNode;
}

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
>;

@fixedHeight((component) => (findDOMNode(component) as Nullable<Element>)?.getElementsByClassName(`${namespace()}--drawer`)[0])
class Container extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;
  private hammerTime: any;

  public componentDidMount() {
    if (!isNode()) {
      const Hammer: any = require('hammerjs');
      this.hammerTime = new Hammer(findDOMNode(this), {});
      this.hammerTime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
      this.hammerTime.on('swipe', (event) => {
        if (event.direction === Hammer.DIRECTION_LEFT) this.handleSwipeLeft();
      });
    }
  }

  public handleSwipeLeft = () => {
    this.props.actions.hideDrawer();
  };

  public shouldComponentUpdate(nextProps: Props & ConnectedProps) {
    return this.props.app.isDrawerOpen !== nextProps.app.isDrawerOpen;
  }

  public handleBackdropClick = (): void => {
    this.props.actions.hideDrawer();
  };

  public render() {
    const { children, app, inert } = this.props;
    return (
      <div inert={inert} className={namespace()}>
        <div
          className={`${namespace()}--backdrop` + (app.isDrawerOpen ? ' visible' : '')}
          onClick={this.handleBackdropClick}
        />
        <SlideIn className={`${namespace()}--drawer`} open={app.isDrawerOpen ? true : false}>
          {children}
        </SlideIn>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({app: state.app});
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(actionCreators, dispatch) });

const ConnectedNavContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(Container);

export default WithInertPage<Props>(ConnectedNavContainer);
