import isNode from './isNode';

let whichTransitionEvent : string | undefined = undefined;

export default () : string | undefined => {
  if (isNode()) return undefined;
  if (whichTransitionEvent) return whichTransitionEvent;

  var t;
  var el = document.createElement('fakeelement');
  var transitions = {
    'transition':'transitionend',
    'OTransition':'oTransitionEnd',
    'MozTransition':'transitionend',
    'WebkitTransition':'webkitTransitionEnd'
  };

  for (t in transitions) {
    if (el.style[t] !== undefined) {
      return whichTransitionEvent = transitions[t];
    }
  }
};
