import { ActionCreator, appActionCreators, ApplicationState } from "../../../..";
import {createApiValidateActions, createValidateActions, createApiSubmitActions, createUpdateValueMethod, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra} from '../../../../Validation/actionCreator';
import { GetDefaultForPrimaryEventPageParams, getDefaultForPrimaryEventPageBody, getAddPageBody, getEditPageRootUrl } from "../../../../../constants/urls";
import { GetDefaultForPrimaryEventPageSubmitActions } from "../../Page/Form/actions";
import { navPush } from "../../../../../utils";
import { ADMIN_CMS_MODULE_SUFFIX } from "../../../../../store/AdminCMSSite/CacheOne/actions";
import { scrollModalToTop } from "../../../../../utils/helpers/adminCMSPageHelper";
import { UPDATE_CMS_PAGE_FORM_SUFFIX } from "../../Page/Form/actions";
import { ModalTypes } from "../../../../../utils/modalHelper";
import { typeName, Action } from "../../../../../utils/StrongActions";

export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__PAGE_FORM__MODAL' + UPDATE_CMS_PAGE_FORM_SUFFIX;
@typeName("INIT" + SUFFIX) export class InitNewPageModal extends Action {}

@typeName("RESET" + SUFFIX) export class ResetNewPageModal extends Action {}

// will submit data to cache two pages
@typeName("CLIENT_SUBMIT_FORM" + SUFFIX) export class ClientSubmitForm extends Action { constructor(public data: any) { super(); }}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const AddPageSubmitActions = createApiSubmitActions(SUFFIX);
export const namespace = (): string => 'pages--cms--modals--new-page';

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.pages.modals.newPage),
  updateValueWithSave: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.pages.modals.newPage, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  getDefaultForPrimaryEventPage: (params: GetDefaultForPrimaryEventPageParams): ActionCreator => dispatch => dispatch(GetDefaultForPrimaryEventPageSubmitActions.request(getDefaultForPrimaryEventPageBody(params), null)),
  reset: (): ActionCreator => dispatch => dispatch(new ResetNewPageModal()),
  init: (): ActionCreator => dispatch => dispatch(new InitNewPageModal()),
  clientSubmitForm: (data: any): ActionCreator => dispatch => dispatch(new ClientSubmitForm(data)),
  apiSubmitForm: (router: any): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const valid = innerApiSubmitFormMethod(
      dispatch,
      AddPageSubmitActions,
      (s: ApplicationState) => s.adminCMSSite.pages.modals.newPage,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
    );
    if (valid) {
      // Here the `innerApiSubmitFormMethod` will do a SaveState before all the local changes
      // for optimistic save
      dispatch(new ResetNewPageModal());
      dispatch(appActionCreators.popModal(false, false, ModalTypes.NEW_PAGE) as any);
      const body = getAddPageBody({}, state.adminCMSSite.pages.modals.newPage.ActiveForm);
      if (state.adminCMSSite.cacheOne.CMSSite) {
        dispatch(new ClientSubmitForm(state.adminCMSSite.pages.modals.newPage.ActiveForm));
        navPush(router, getEditPageRootUrl({
          siteId: state.adminCMSSite.cacheOne.CMSSite.ID,
          domain: state.adminCMSSite.cacheOne.CMSSite.SiteDomain,
          pageId: -1,   // meaning this is a placeholder in edit page
          pageName: state.adminCMSSite.pages.modals.newPage.ActiveForm.Name,
        }));
      }
      dispatch(AddPageSubmitActions.request(
          body,
          getCacheLevelExtra(
            false,
            false,
          ),
        )
      );
    } else {
      scrollModalToTop(namespace());
    }
  },
};