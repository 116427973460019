import { ActionCreator } from "../../../..";
import {
  createApiValidateActions,
  createValidateActions,
} from "../../../../Validation/actionCreator";
import { typeName, Action } from "../../../../../utils/StrongActions";

export const SUFFIX = "__ADMIN_SELECT_MERIT_BADGE_MODAL";

@typeName("FILTER" + SUFFIX)
export class SelectMeritBadgeModalFilter extends Action {
  constructor(public filterString: string) {
    super();
  }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class SelectMeritBadgeToggleFilterTabs extends Action {
  constructor(public searching: boolean) {
    super();
  }
}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export const actionCreators = {
  filter:
    (filterString: string): ActionCreator =>
    (dispatch) =>
      dispatch(new SelectMeritBadgeModalFilter(filterString)),
  toggleFilter:
    (searching: boolean): ActionCreator =>
    (dispatch) =>
      dispatch(new SelectMeritBadgeToggleFilterTabs(searching)),
};
