import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/app/header/back.scss';
import { ActionButton } from '../../Elements';
import { BackIcon } from '../../Icons';

export const namespace = (): string => `${ns()}--back`;

interface Props {
  disabled?: boolean;
  id?: string;
  onClick?: () => void;
}

const Back: React.SFC<Props> = ({ disabled, id, onClick }: Props): React.ReactElement<Props> => (
  <div className={`${namespace()} ${disabled ? 'disabled' : ''}`}>
    <ActionButton
      id={id}
      icon={BackIcon}
      onClick={onClick}
      className={`${namespace()}--button`}
    />
  </div>
);

export default Back;
