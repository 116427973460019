import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../../../../styles/pages/menus/home/site-menu-filters/index.scss';
import { TextField, Switch, ActionButton } from '../../../../../Elements';
import { SearchIcon, FilterIcon } from '../../../../../Icons';
import { CANCEL_ORDER_BEFORE_FILTERING } from '../../../../../../constants/messages/generic';
import { MenusHomeState } from '../../../../../../store/AdminCMSSite/Menus/Home';
import { Validator } from '../../../../../../utils/validator/models';

export const namespace = (): string => 'elements--site-menu-filters';

export interface PageMenuFiltersProps {
  className?: string;
  disabled?: boolean;
  ActiveForm: any;
  ValidationRules: any;
  menusHome: MenusHomeState;
  expand?: boolean;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
    toggleExpandFilter: (expand: boolean, type: 'sitemenu' | 'pagemenu') => any,
    showTopFloatingAlert: (message: string, inModal?: boolean, color?: 'green' | 'orange') => any,
  };
  control: React.ReactNode;
}

interface State {
  showSearch?: boolean;
}

class PageMenuFilters extends React.Component<PageMenuFiltersProps, State> {
  state = {
    showSearch: false,
  };

  public static defaultProps = {

  };
  public props: PageMenuFiltersProps;

  onSearchChange = (val: string, vObj: Validator) => {
    if (this.props.menusHome.orderingPageMenu) {
      this.props.reduxActions.showTopFloatingAlert(CANCEL_ORDER_BEFORE_FILTERING, undefined, 'orange');
      return;
    }
    if (val) {
      this.setState({
        showSearch: true,
      });
    }
    this.props.reduxActions.simpleUpdate(val, vObj);
  };
  onSearchCancel = () => {
    const {ValidationRules} = this.props;
    this.setState({showSearch: false});
    this.props.reduxActions.updateValue(undefined, ValidationRules.PageMenusFilterText);
  };

  onFilterClick = () => {
    this.props.reduxActions.toggleExpandFilter(!this.props.expand, 'pagemenu');
  };
  onShowDeleteChange = (val: boolean, vObj: Validator) => {
    if (this.props.menusHome.orderingPageMenu) {
      this.props.reduxActions.showTopFloatingAlert(CANCEL_ORDER_BEFORE_FILTERING, undefined, 'orange');
      return;
    }
    this.props.reduxActions.updateValue(val, vObj);
  };

  public render() {
    const props = this.props;
    const {ActiveForm, ValidationRules, reduxActions, disabled, control, expand} = props;

    // @todo - use css instead of `Media` component
    return (
      <div className={`${mergeClassNames(namespace(), props)}`}>
        <div className={`${namespace()}--row1 site-menu-filters ${this.state.showSearch ? 'show-search' : ''}`}>
          <TextField
            noStateValue
            className={`${namespace()}--search`}
            disabled={disabled}
            hideOptional
            onCancel={!!ActiveForm.PageMenusFilterText ? this.onSearchCancel : undefined}
            icon={<SearchIcon />}
            onChange={this.onSearchChange}
            onBlur={reduxActions.updateValue}
            value={ActiveForm.PageMenusFilterText}
            validationRules={ValidationRules.PageMenusFilterText}
            label='Search' />
          <Switch
            className={`${namespace()}--show-deleted`}
              label="Show Deleted"
              newDesign
              onChange={this.onShowDeleteChange}
              value={!!ActiveForm.PageMenusShowDeleted}
              validationRules={ValidationRules.PageMenusShowDeleted}
          />
          <ActionButton lightSelected className={`${namespace()}--expand`} square onClick={() => this.onFilterClick()} icon={FilterIcon} selected={expand} />
          {control}
        </div>
        {expand ? <div className={`${namespace()}--row2`}>
          <Switch
            className={`${namespace()}--show-deleted mobile`}
            label="Show Deleted"
            newDesign
            onChange={this.onShowDeleteChange}
            value={!!ActiveForm.PageMenusShowDeleted}
            validationRules={ValidationRules.PageMenusShowDeleted}
          />
        </div> : null} 
      </div>
    );
  }
}

export default PageMenuFilters;