import * as React from 'react';

import { FacilityTypeFormSection } from '../../../../../store/AdminFacilityLocation/Facilities/FacilityType/Form/actions';
import { AdminFacilityLocationCacheOneState } from '../../../../../store/AdminFacilityLocation/CacheOne';
import '../../../../../styles/pages/admin-facility-location/blackout-dates/blackout-date/index.scss';
import { Main, MainContent } from '../../../../Layouts';
import { Form, Button, Row, Column, TextField, FieldSet, DatePicker, Alert } from '../../../../Elements';
import TimePicker from '../../../../../components/Elements/TimePicker';
import { BlackoutDateFormState } from '../../../../../store/AdminFacilityLocation/BlackoutDates/Form';
import { AdminFacility } from '../../../../../models/api/adminFacilitiesCacheOne';
import AdminFacilityCard, { AdminFacilityCardType } from '../../../../../components/Elements/AdminFacility/Card';
import { CardCategory } from '../../../../../components/Elements/Card';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { Validator } from '../../../../../utils/validator/models';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--facility-location--blackout-date';

export type Props = WithInertAttribute<{
  action: 'add' | 'edit';
  disabled: boolean;
  loading?: boolean;
  adminFacilityLocationCacheOne: AdminFacilityLocationCacheOneState;
  showSection?: FacilityTypeFormSection;
  handleCompact?: boolean;
  hideFormAction?: boolean;
  blackoutDateForm: BlackoutDateFormState;
  selectedFacilities: AdminFacility[];
  onSave?: () => void;
  onDelete?: () => void;
  reduxActions: {
    simpleUpdate(value: any, vObj: Validator);
    updateValue: (value: any, validationRules?: Validator) => any;
    pushMultipleFacilitiesModal: () => void;
    removeFacility: (facilityId: number) => void;
  };
}>;

class BlackoutDateForm extends React.Component<Props, {}> {
  public props: Props;

  onAddFacility = () => {
    this.props.reduxActions.pushMultipleFacilitiesModal();
  };

  onRemoveFacility = (facility: AdminFacility) => {
    this.props.reduxActions.removeFacility(facility.ID);
  };

  isFormDisabled = () => {
    const { adminFacilityLocationCacheOne, action } = this.props;

    if (!adminFacilityLocationCacheOne.FacilitiesLocation) return true;

    return action === 'add' ? !adminFacilityLocationCacheOne.FacilitiesLocation.hasBlackoutAdd : !adminFacilityLocationCacheOne.FacilitiesLocation.hasBlackoutEdit;
  };

  getPermissionReason = () => {
    const { adminFacilityLocationCacheOne, action } = this.props;

    if (!adminFacilityLocationCacheOne.FacilitiesLocation) return '';

    return action === 'add' ? adminFacilityLocationCacheOne.FacilitiesLocation.hasBlackoutAddReason : adminFacilityLocationCacheOne.FacilitiesLocation.hasBlackoutEditReason;
  };

  public render() {
    const {onSave, inert, onDelete, adminFacilityLocationCacheOne, action, disabled, reduxActions, blackoutDateForm: {ActiveForm, ValidationRules, SubmitErrorMessage}, loading, selectedFacilities, handleCompact, hideFormAction} = this.props;

    const reason = this.getPermissionReason();

    return (
      <Main inert={inert}>
        <MainContent loading={loading} className={`${namespace()}--main-content`} handleCompact={handleCompact}>
            <Form
              onSubmit={e => e.preventDefault()}
            >
            {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
            {reason ? <Alert className={`${namespace()}--alert`}>{reason}</Alert> : null}
              <Row marginBottom={8} mobileMarginBottom={8}>
                <Column mobileMarginBottom={8} span={4} mobileSpan={12}>
                  <DatePicker
                    label="Start Date"
                    disabled={disabled || this.isFormDisabled()}
                    value={ActiveForm.StartDate}
                    validationRules={ValidationRules.StartDate}
                    onSelect={reduxActions.updateValue}
                    onChangeRaw={reduxActions.simpleUpdate}
                    popperPlacement='bottom-start'
                    popperModifiers={{
                      flip: {
                          behavior: ["bottom"] // don't allow it to flip to be above
                      },
                      preventOverflow: {
                          enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                      },
                      hide: {
                          enabled: false // turn off since needs preventOverflow to be enabled
                      }
                    }}
                    removeMax
                  />
                </Column>
                <Column span={8} mobileSpan={12}>
                  <TimePicker
                    label="Time"
                    disabled={disabled || this.isFormDisabled()}
                    customizedId='startDateTime'
                    onChangeValue={reduxActions.updateValue}
                    hourValue={ActiveForm.StartHour}
                    minValue={ActiveForm.StartMin}
                    periodValue={ActiveForm.StartPeriod}
                    hourValidationRules={ValidationRules.StartHour}
                    minValidationRules={ValidationRules.StartMin}
                    periodValidationRules={ValidationRules.StartPeriod}
                  />
                </Column>
              </Row>
              <Row marginBottom={8} mobileMarginBottom={8}>
                <Column mobileMarginBottom={8} span={4} mobileSpan={12}>
                  <DatePicker
                    label="End Date"
                    disabled={disabled || this.isFormDisabled()}
                    value={ActiveForm.EndDate}
                    validationRules={ValidationRules.EndDate}
                    onSelect={reduxActions.updateValue}
                    onChangeRaw={reduxActions.simpleUpdate}
                    popperPlacement='bottom-start'
                    popperModifiers={{
                      flip: {
                          behavior: ["bottom"] // don't allow it to flip to be above
                      },
                      preventOverflow: {
                          enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                      },
                      hide: {
                          enabled: false // turn off since needs preventOverflow to be enabled
                      }
                    }}
                    removeMax
                  />
                </Column>
                <Column span={8} mobileSpan={12}>
                  <TimePicker
                    label="Time"
                    disabled={disabled || this.isFormDisabled()}
                    customizedId='endDateTime'
                    onChangeValue={reduxActions.updateValue}
                    hourValue={ActiveForm.EndHour}
                    minValue={ActiveForm.EndMin}
                    periodValue={ActiveForm.EndPeriod}
                    hourValidationRules={ValidationRules.EndHour}
                    minValidationRules={ValidationRules.EndMin}
                    periodValidationRules={ValidationRules.EndPeriod}
                  />
                </Column>
              </Row>
              <Row marginBottom={24} mobileMarginBottom={24}>
                <Column span={12} mobileSpan={12}>
                  <TextField
                    id={generateDOMId("admin-blackout-reason-field")}
                    label="Reason"
                    disabled={disabled || this.isFormDisabled()}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Reason}
                    value={ActiveForm.Reason}
                  />
                </Column>
              </Row>
              <FieldSet
                keepControlsPositionInMobile
                className={`${namespace()}--field-set--with-btn`}
                marginBottom={16} mobileMarginBottom={16} legendMarginBottom={0}
                fixedLegendFontSize
                emptyFieldSet
                newDesign
                fontSize={18} name="Facilities"
                controls={selectedFacilities && selectedFacilities.length > 0 && (
                  <Button disabled={disabled || this.isFormDisabled()} onClick={this.onAddFacility} color='white' textColor='black'>ADD</Button>
                )} />
              {!selectedFacilities || selectedFacilities.length === 0 && (
                <Row>
                  <Button id={generateDOMId("add-facilities-btn")} textColor='black' disabled={disabled || this.isFormDisabled()} color='white' onClick={this.onAddFacility}>ADD FACILITIES</Button>
                </Row>
              )}
              {selectedFacilities && selectedFacilities.length > 0 && selectedFacilities.map((f) => {
                return (
                  <AdminFacilityCard
                    key={f.ID}
                    facility={f}
                    disabled={disabled || this.isFormDisabled()}
                    type={AdminFacilityCardType.BLACKOUT_DATE}
                    category={CardCategory.LIST}
                    onRemove={this.onRemoveFacility}
                    adminFacilityLocationCacheOne={adminFacilityLocationCacheOne}
                  />
                );
              })}
            </Form>
        </MainContent>
      </Main>
    );
  }
}

export default BlackoutDateForm;