import * as React from 'react';
import { namespace as ns } from './constants';
import CartIcon from "../CartIcon";
import '../../../styles/elements/my-event/amounts.scss';

export const namespace = (): string => `${ns()}--amounts`;

export interface AmountsProps {
  total?: number;
  balance?: number;
  quantity: React.ReactElement<any>;
  cart?: boolean;
}

const Amounts: React.SFC<AmountsProps> = ({ total, balance, quantity, cart }: AmountsProps): React.ReactElement<AmountsProps> => (
  <div className={namespace()}>
    <div className={`${namespace()}--total-group`}>
      {cart ? (
        <div className={`${namespace()}--total-group--cart-icon`}>
          <CartIcon className={`${namespace()}--total-group--cart-icon--image`}/>
        </div>
      ) : null}
      {total !== undefined ? (
        <div className={`${namespace()}--total-group--total`}>
          Total: {(total).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
        </div>
      ) : null}
      {balance !== undefined ? (
        <div className={`${namespace()}--total-group--balance`}>
          Balance: {(balance).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
        </div>
      ) : null}
    </div>
    <div className={`${namespace()}--quantity`}>
      {quantity}
    </div>
  </div>
);

export default Amounts;
