import { bindActionCreators } from "redux";
import { ApplicationState } from "../../../store";
import "../../../styles/app/admin-header/index.scss";
import * as React from 'react';
import { ActionButton, Button } from "../../Elements";
import { isAdminCMSSitePage as _isAdminCMSSitePage, isAdminFacilityLocationPage as _isAdminFacilityLocationPage, isAdminEventsPage as _isAdminEventsPage } from '../../../constants/urls';
import moment from "moment";
import { RouteComponentProps, withRouter, WithRouterProps } from "react-router";
import { ADMIN_EVENTS_SETTINGS_PATH, ADMIN_EVENT_DASHBOARD_PATH, ADMIN_EVENT_SETTINGS_PATH } from "../../../routes";
import { GetAdminEvent } from "../../../store/AdminEvents/CacheTwoEvent/actions";
import { actionCreators as appActionCreators } from "../../../store/App/actions";
import { actionCreators as eventTypeFormActionCreators } from "../../../store/AdminEvents/EventTypes/EventType/Form/actions";
import { OptionsIcon } from "../../Icons";
import ContextMenuComponent from '../../Pages/AdminEvents/Settings/Home/ContextMenu';
import { displayTimeFrame } from "../../../utils";
import { checkEventTypePermission } from "../../../utils/helpers/adminEventsPageHelper";
import { ModalTypes } from "../../../utils/modalHelper";
import { generateDOMId } from "../../../utils/cypressHelper";
import { connect } from "react-redux";
import { getMergeProps } from "../../../utils/reduxHelper";
import { noOpenedModals } from "../../../utils/modalHelper";

export const namespace = (): string => 'app--admin-header';

interface Props {

}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

interface State {

}
class AdminHeader extends React.Component<Props & ConnectedProps, State> {

  onSave = () => {
    const {eventTypeFormActions, cacheZero, adminEventsCacheOne, routes, router} = this.props;

    checkEventTypePermission(
      () => {
        eventTypeFormActions.apiSubmitForm(routes, router);
      },
      cacheZero,
      adminEventsCacheOne,
      undefined,
      true,
    );
  };

  onDelete = () => {
    const {eventTypeFormActions, cacheZero, adminEventsCacheOne} = this.props;

    checkEventTypePermission(
      () => {
        if (!adminEventsCacheOne.EventsEventType) return;
    
        eventTypeFormActions.deleteEventType(adminEventsCacheOne.EventsEventType.EventTypeRow.ID as number, true);
      },
      cacheZero,
      adminEventsCacheOne,
      undefined,
      true,
    );
  };

  onDuplicate = () => {
    const {actions, cacheZero, adminEventsCacheOne} = this.props;

    checkEventTypePermission(
      () => {
        if (!adminEventsCacheOne.EventsEventType) return;

        actions.pushFormModal(ModalTypes.NEW_EVENT_TYPE, false, {duplicateEventTypeId: adminEventsCacheOne.EventsEventType.EventTypeRow.ID as number});
      },
      cacheZero,
    );
  };

  onRestoreAdminEventType = () => {
    const {eventTypeFormActions, cacheZero, adminEventsCacheOne} = this.props;

    checkEventTypePermission(
      () => {
        if (!adminEventsCacheOne.EventsEventType) return;
        
        if (!adminEventsCacheOne.EventsEventType) return;

        eventTypeFormActions.deleteEventType(adminEventsCacheOne.EventsEventType.EventTypeRow.ID as number, false);
      },
      cacheZero,
      adminEventsCacheOne,
      undefined,
      true,
    );
  };

  renderAdminEventTypeSettingsActions = () => {
    const {adminEventsCacheOne, apiLoading} = this.props;

    const inactive = !adminEventsCacheOne.EventsEventType || adminEventsCacheOne.EventsEventType.EventTypeRow.Inactive;
    const unauthorized = adminEventsCacheOne.EventsEventType && !adminEventsCacheOne.EventsEventType.Permissions.hasEditEventType;
    const isLoading = apiLoading > 0 && noOpenedModals();
    return (
      <div className={`${namespace()}--admin-events--actions`}>
        {!inactive && <ActionButton
          disabled={unauthorized || isLoading}
          id={generateDOMId("eventtype-settings-options")}
          icon={OptionsIcon}
          className={`${namespace()}--admin-events--options`}
          contextMenu={<ContextMenuComponent
            direction='bottom'
            onDelete={this.onDelete}
            onSave={this.onSave}
            onDuplicate={this.onDuplicate} />}
        />}
        {!inactive && <Button id={generateDOMId("admin-event-type-settings-save-btn")} disabled={unauthorized || isLoading} className={`${namespace()}--admin-events--save`} onClick={this.onSave} color='green' textColor='white'>SAVE</Button>}
        {inactive && <Button id={generateDOMId("eventtype-restore")} flat disabled={unauthorized || isLoading} className={`${namespace()}--admin-events--restore`} onClick={this.onRestoreAdminEventType} color='white' textColor='red'>RESTORE</Button>}
      </div>
    );
  };

  render() {
    const { cacheZero, adminCMSSiteCacheOne, adminFacilityLocationCacheOne, adminEventsCacheTwoEvent, adminEventsCacheOne, apiLoadingMap, routes } = this.props;
    const pathname = location.pathname;

    const route = routes[routes.length - 1];

    const isAdminCMSSitePage = _isAdminCMSSitePage(pathname);
    const isAdminFacilityLocationPage = _isAdminFacilityLocationPage(pathname);
    const isAdminEventsPage = _isAdminEventsPage(pathname);

    if ((isAdminCMSSitePage && adminCMSSiteCacheOne.CMSSite) || (isAdminFacilityLocationPage && adminFacilityLocationCacheOne.FacilitiesLocation) || (isAdminEventsPage && adminEventsCacheOne.EventsEventType)) {
      let title;
      let subtitle;
      let wide;
      let hasLeftGutter;
      let hasRightGutter;
      let handleCompact;
      let hasActions;

      if (isAdminCMSSitePage) {
        title = adminCMSSiteCacheOne.CMSSite ? adminCMSSiteCacheOne.CMSSite.SiteDomain : '';
        handleCompact = true;
      } else if (isAdminFacilityLocationPage) {
        const location = adminFacilityLocationCacheOne.FacilitiesLocation;
        title = location ? location.Name : '';
        subtitle = location ? `${location.Address}, ${location.City}, ${location.State} ${location.Zip}` : '';
        handleCompact = true;
      } else if (isAdminEventsPage && adminEventsCacheTwoEvent.EventsEvent) {
        const location = cacheZero.options && cacheZero.options.Locations ? cacheZero.options.Locations.find((l) => !!adminEventsCacheOne.EventsEventType && l.ID === adminEventsCacheOne.EventsEventType.EventTypeRow.LocationID) : null;
        const startDateMoment = moment(adminEventsCacheTwoEvent.EventsEvent.StartDate);
        const endDateMoment = moment(adminEventsCacheTwoEvent.EventsEvent.EndDate);

        title = adminEventsCacheTwoEvent.EventsEvent.Name;
        const timeFrame = displayTimeFrame(startDateMoment, endDateMoment);
        subtitle = location && adminEventsCacheOne.EventsEventType ? [
          <span key='timeframe'>{timeFrame}</span>,
          <span key='info'>{`${adminEventsCacheOne.EventsEventType.EventTypeRow.Name} @ ${location.Name}`}</span>
        ] : '';
        wide = route.path === ADMIN_EVENT_SETTINGS_PATH || route.path === ADMIN_EVENT_DASHBOARD_PATH;
        hasLeftGutter = route.path === ADMIN_EVENT_DASHBOARD_PATH;
        hasRightGutter = route.path === ADMIN_EVENT_DASHBOARD_PATH;
      } else if (isAdminEventsPage && adminEventsCacheOne.EventsEventType) {
        // if cache one is NOT loaded and we are loading, dont show this header;
        // but if cache one is loaded, then even when we are doing a load(i.e. refresh), we should keep this header
        if (!adminEventsCacheOne.EventsEventType && apiLoadingMap[GetAdminEvent.requestType]) return null;

        const location = cacheZero.options && cacheZero.options.Locations ? cacheZero.options.Locations.find((l) => !!adminEventsCacheOne.EventsEventType && l.ID === adminEventsCacheOne.EventsEventType.EventTypeRow.LocationID) : null;
        title = adminEventsCacheOne.EventsEventType.EventTypeRow.Name;
        subtitle = location ? location.Name : '';
        handleCompact = true;
        hasActions = route.path === ADMIN_EVENTS_SETTINGS_PATH;
      }

      return (
        <div className={`${namespace()}${isAdminFacilityLocationPage ? ' facility-location' : ''}${isAdminEventsPage ? ' admin-events' : ''}${hasLeftGutter ? ' has-left-gutter' : ''}${handleCompact ? ' handle-compact' : ''}${hasActions ? ' has-actions' : ''}`}>
          {hasLeftGutter && <div className={`${namespace()}--left-gutter`} />}
          <div className={`${namespace()}--content`}>
            <div className={`${namespace()}--text-wrapper${wide ? ' wide' : ''}`}>
              <div className={`${namespace()}--title`}>{title}</div>
              {!!subtitle && <div className={`${namespace()}--subtitle`}>{subtitle}</div>}
            </div>
            {route.path === ADMIN_EVENTS_SETTINGS_PATH && adminEventsCacheOne.EventsEventType && adminEventsCacheOne.EventsEventType.EventTypeRow.ID > 0 && this.renderAdminEventTypeSettingsActions()}
          </div>
          {hasRightGutter && <div className={`${namespace()}--right-gutter`} />}
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    cacheZero: state.cacheZero,
    adminCMSSiteCacheOne: state.adminCMSSite.cacheOne,
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsCacheTwoEvent: state.adminEvents.cacheTwoEvent,
    apiLoadingMap: state.app.apiLoadingMap, 
    apiLoading: state.app.apiLoading,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
  }, dispatch),
  eventTypeFormActions: bindActionCreators({
    ...eventTypeFormActionCreators,
  }, dispatch),
});

const ConnectedAdminHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>()
)(AdminHeader);
export default withRouter<Props, {}>(ConnectedAdminHeader);
