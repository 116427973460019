
import { Actions as CacheOneActions } from '../../store/AdminFacilityLocation/CacheOne/actions';
import { ADMIN_FACILITY_LOCATION_FACILITY_TYPES_PATH, ADMIN_FACILITY_LOCATION_FACILITIES_PATH, ADMIN_FACILITY_LOCATION_SETTINGS_PATH, ADMIN_FACILITY_LOCATION_AVAILABILITIES_PATH, ADMIN_FACILITY_LOCATION_BLACKOUT_DATES_PATH, ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH } from '../../routes';
import { extractRouteParams } from '../urlHelper';
import { AdminFacilityType } from '../../models/api/adminFacilitiesCacheOne';
import { Actions as AvailabilitiesHomeActions } from "../../store/AdminFacilityLocation/Availabilities/Home/actions";
import { Actions as CacheTwoBlackoutDateActions } from "../../store/AdminFacilityLocation/CacheTwoBlackoutDates/actions";
import { AvailabilitiesHomeState } from '../../store/AdminFacilityLocation/Availabilities/Home';
import { formatDate } from '../dateHelper';
import { AdminFacilityLocationCacheOneState } from '../../store/AdminFacilityLocation/CacheOne';
import { checkPermission } from '../permissionHelper';
import { CacheZeroState } from '../../store/CacheZero';
import { RouteComponentProps } from 'react-router';

type RefreshActions = CacheOneActions & AvailabilitiesHomeActions & CacheTwoBlackoutDateActions;

export interface IAdminFacilitiesLocationRouterParams {
  [key: string]: any;
  locationId: string;
  locationName?: string;
}
export interface IAdminBlackoutDateRouterParams extends IAdminFacilitiesLocationRouterParams {
  blackoutDateId: string | undefined;
}
export interface IAdminFacilityRouterParams extends IAdminFacilitiesLocationRouterParams {
  facilityId: string | undefined;
}
export interface IAdminFacilityTypeRouterParams extends IAdminFacilitiesLocationRouterParams {
  facilityTypeId: string | undefined;
}

interface RefreshProps extends RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}> {
  availabilitiesHome: AvailabilitiesHomeState;
}

const refreshFacilityTypes = (props: RefreshProps, actions: RefreshActions) => {
  // refresh facility types
  actions.refresh({
    GetFacilityTypes: true,
    MaxFacilityTypeID: 0,
    ...extractRouteParams(props),
  }, false);
};

const refreshFacilities = (props: RefreshProps, actions: RefreshActions) => {
  // refresh facilities
  actions.refresh({
    GetFacilities: true,
    MaxFacilityID: 0,
    GetFacilityTypes: true,
    ...extractRouteParams(props),
  }, false);
};

const refreshSettings = (props: RefreshProps, actions: RefreshActions) => {
  actions.refresh({
    LocationTS: 0,
    GetFacilitiesOptions: false,
    ...extractRouteParams(props),
  }, true);
};

const refreshAvailabilities = (props: RefreshProps, actions: RefreshActions) => {
  if (props.availabilitiesHome.selectedTabId === undefined) return;
  actions.reset();
  actions.getAvailabilities({
    FacilityTypeID: props.availabilitiesHome.selectedTabId,
    StartDate: formatDate(props.availabilitiesHome.startDate),
  }, props);
};

const refreshBlackoutDates = (props: RefreshProps, actions: RefreshActions) => {
  actions.refresh({
    GetBlackoutDates: true,
    ...extractRouteParams(props),
  }, false);
};

const refreshBlackoutDate = (props: RefreshProps, actions: RefreshActions) => {
  actions.refreshBlackoutDate({
    GetBlackoutDates: true,
    // NOTE: It is safe to do a type cast here because this can only be called in Edit BlackoutDate page
    ID: getBlackoutDateIdFromPath(props.params as IAdminBlackoutDateRouterParams),
    ...extractRouteParams(props),
  });
};

export const getAdminFacilityLocationCustomRefresh = (props: RefreshProps, actions: RefreshActions) => {
  const route = props.routes[props.routes.length - 1];

  switch (route.path) {
    // Facility Types
    case ADMIN_FACILITY_LOCATION_FACILITY_TYPES_PATH:
      return () => refreshFacilityTypes(props, actions);
    // Facilities
    case ADMIN_FACILITY_LOCATION_FACILITIES_PATH:
      return () => refreshFacilities(props, actions);
    // Settings
    case ADMIN_FACILITY_LOCATION_SETTINGS_PATH:
      return () => refreshSettings(props, actions);
    // Availabilities
    case ADMIN_FACILITY_LOCATION_AVAILABILITIES_PATH:
      return () => refreshAvailabilities(props, actions);
    // Blackout Dates
    case ADMIN_FACILITY_LOCATION_BLACKOUT_DATES_PATH:
      return () => refreshBlackoutDates(props, actions);
    // Edit Blackout Date
    case ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH:
      return () => refreshBlackoutDate(props, actions);
    default:
      return undefined;
  }
};

export const getFacilityTypesMap = (facilityTypes: AdminFacilityType[]): {[key: string]: AdminFacilityType} => {
  const facilityTypesMap: {[key: string]: AdminFacilityType} = {};

  facilityTypes && facilityTypes.forEach((type) => {
    facilityTypesMap[type.ID] = type;
  });

  return facilityTypesMap;
};

export const formatAccountNum = (AccountNum: number) => {
  let AccountNumStr = `${AccountNum}`;
  let formatted = '';

  while (AccountNumStr.length < 10) {
    AccountNumStr = `0${AccountNumStr}`;
  }

  formatted += AccountNumStr[0] + '-';
  formatted += AccountNumStr.substr(1, 4) + '-';
  formatted += AccountNumStr.substr(5, 3 + AccountNumStr.length - 10) + '-';
  formatted += AccountNumStr.substr(AccountNumStr.length - 2, 2);

  return formatted;
};

export const checkFacilityPermission = (
  onSuccess: () => void,
  adminFacilityLocationCacheOne: AdminFacilityLocationCacheOneState,
  isEdit?: boolean,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (adminFacilityLocationCacheOne.FacilitiesLocation) {
    checkPermission(
      () => onSuccess(),
      isEdit ? adminFacilityLocationCacheOne.FacilitiesLocation.hasFacilityEdit : adminFacilityLocationCacheOne.FacilitiesLocation.hasFacilityAdd,
      isEdit ? adminFacilityLocationCacheOne.FacilitiesLocation.hasFacilityEditReason : adminFacilityLocationCacheOne.FacilitiesLocation.hasFacilityAddReason,
      inModal,
      noAlertOnFail,
    );
  }
};

export const checkFacilityTypePermission = (
  onSuccess: () => void,
  cacheZero: CacheZeroState,
  isEdit?: boolean,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (cacheZero.options && cacheZero.options.GeneralPermissions) {
    checkPermission(
      () => onSuccess(),
      isEdit ? cacheZero.options.GeneralPermissions.hasFacilityTypeEdit : cacheZero.options.GeneralPermissions.hasFacilityTypeAdd,
      isEdit ? cacheZero.options.GeneralPermissions.hasFacilityTypeEditReason : cacheZero.options.GeneralPermissions.hasFacilityTypeAddReason,
      inModal,
      noAlertOnFail,
    );
  }
};

export const checkBlackoutDatePermission = (
  onSuccess: () => void,
  adminFacilityLocationCacheOne: AdminFacilityLocationCacheOneState,
  isEdit?: boolean,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (adminFacilityLocationCacheOne.FacilitiesLocation) {
    checkPermission(
      () => onSuccess(),
      isEdit ? adminFacilityLocationCacheOne.FacilitiesLocation.hasBlackoutEdit : adminFacilityLocationCacheOne.FacilitiesLocation.hasBlackoutAdd,
      isEdit ? adminFacilityLocationCacheOne.FacilitiesLocation.hasBlackoutEditReason : adminFacilityLocationCacheOne.FacilitiesLocation.hasBlackoutAddReason,
      inModal,
      noAlertOnFail,
    );
  }
};

export const getFacilityLocationId = (props: RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}>): number => {
  if (props.params.locationId) {
    return Number(props.params.locationId);
  }
  return 0;
};

export const getFacilityLocationName = (props: RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}>): string => {
  if (props.params.locationName) {
    return props.params.locationName;
  }
  return '';
};

export const getBlackoutDateIdFromPath = (params: IAdminBlackoutDateRouterParams): number => {
  if (params.blackoutDateId) {
    return Number(params.blackoutDateId);
  }
  return 0;
};

export const getFacilityIdFromPath = (params: IAdminFacilityRouterParams): number => {
  if (params.facilityId) {
    return Number(params.facilityId);
  }
  return 0;
};

export const getFacilityTypeIdFromPath = (params: IAdminFacilityTypeRouterParams): number => {
  if (params.facilityTypeId) {
    return Number(params.facilityTypeId);
  }
  return 0;
};