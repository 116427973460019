import {
  createApiSubmitActions,
  createRequestActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions,
  innerApiSubmitFormMethod
} from "../Validation/actionCreator";
import {ActionCreator, ApplicationState} from "../index";
import {CreditCardType} from "./index";
import {getCheckoutCartBody} from "../../constants/urls";
import {CheckoutMessage} from "../../models/api/cacheOne";
import {PopModal, ShowModalAlert, ShowTopFloatingAlert} from "../App/actions";
import {scrollToErrorCheckout} from "./uiHelpers";
import { ApiSubmitActions as SubmitProfileActions } from "../Settings/Profile/actions";
import { ModalTypes, isModalOpened } from "../../utils/modalHelper";
import { SaveState } from "../Rollback/actions";
import { TentarooDebugPayload, captureTentarooError, captureTentarooErrorAndGetRequestPayload, withTentarooDebugPayload } from "../../utils/dataHelper";
import { appActionCreators } from "../App";
import { typeName, Action } from "../../utils/StrongActions";
import {Dispatch} from "react";
import {AnyAction} from "redux";

export const SUFFIX = '__CHECKOUT';

export const ValidateActions = createValidateActions(SUFFIX);
export const CheckoutSubmitActions = createApiSubmitActions(SUFFIX);

export const CHANGE_SUFFIX = '__CHANGE_PAYMENT';
export const ChangePaymentTypeActions = createRequestActions(CHANGE_SUFFIX, true);

export const FINISH_CHECKOUT_SUFFIX = '__FINISH_CHECKOUT';

@typeName(`CHANGE_CREDIT_CARD_TYPE${SUFFIX}`)
export class ChangeCreditCardType extends Action {
  constructor(public creditCardType?: CreditCardType) { super(); }
}

@typeName(`TOGGLE_CART_ITEMS${SUFFIX}`)
export class ToggleCartItems extends Action {}

@typeName(`INIT${SUFFIX}`)
export class CheckoutInit extends Action {
  constructor(public rootState: ApplicationState) { super(); }
}

@typeName(`CANCEL_CHECKOUT${SUFFIX}`)
export class CancelCheckout extends Action {}

@typeName(`CONTINUE_CHECKOUT${SUFFIX}`)
export class ContinueCheckout extends Action {
}

@typeName(`TOGGLE_AGREE_MESSAGE${SUFFIX}`)
export class ToggleAgreeMessage extends Action {}

@typeName(`RESET${SUFFIX}`)
export class CheckoutReset extends Action {
  constructor(public rootState: ApplicationState) { super(); }
}

@typeName(`SET_FULL_CLASSES${SUFFIX}`)
export class SetFullClasses extends Action {
  constructor(public fullClassesErrorMessage: string, public url: string) { super(); }
}

export type Actions = typeof actionCreators;

/**
 * FormCreator to create a form containing profile related fields, which will be
 * part of the body sent over request
 * 
 * @param activeForm - the active form under `profile` node in state tree
 */
const profileFormCreator = (activeForm) => {
  return {
    Group: {
      FirstName: activeForm.FirstName,
      LastName: activeForm.LastName,
      MI: activeForm.MI,
      Suffix: activeForm.Suffix,
      Email: activeForm.Email,
      PhoneNumber: activeForm.PhoneNumber,
      PhoneNumber2: activeForm.PhoneNumber2,
      Address: activeForm.Address,
      Address2: activeForm.Address2,
      City: activeForm.City,
      StateID: activeForm.StateID,
      Zip: activeForm.Zip,
      Contact2Name: activeForm.Contact2Name,
      Contact2Phone: activeForm.Contact2Phone,
      Contact2Email: activeForm.Contact2Email,
    },
  };
};

const submitCheckout = (rootState: ApplicationState, dispatch: Dispatch<any>, viewedMessages: boolean) => {
  if (!rootState.cacheOne.CartOrder) {
    captureTentarooError(new Error("cacheOne.CartOrder not available when submitting checkout"));
    return;
  }
  dispatch(appActionCreators.pushModal(ModalTypes.SUBMITTING_PAYMENT, false, false) as any);
  const payload = Object.assign({}, getCheckoutCartBody(rootState.checkout.ActiveForm, viewedMessages), profileFormCreator(rootState.settings.profile.ActiveForm));
  dispatch(CheckoutSubmitActions.request(
    payload,
    {
      inMatchingCacheLevelOn409: undefined,
      leavingCacheLevelOnSuccess: undefined,
      cartOrderID: rootState.cacheOne.CartOrder.ID,
    }),
  );
};

function validateAndCheckout(dispatch: Dispatch<AnyAction>, getState: () => ApplicationState, viewedMessages: boolean) {
  const validCheckout = innerApiSubmitFormMethod(
    dispatch,
    CheckoutSubmitActions,
    (s) => s.checkout,
    undefined,      // since skipApiCall is true, we could stop passing `formCreator` for simplicity
    undefined,
    true,
    true,
    false,
    undefined,
  );
  const validProfile = innerApiSubmitFormMethod(
    dispatch,
    SubmitProfileActions,
    (s) => {
      // only check fields shown in checkout page
      const profileState = {
        ...s.settings.profile,
        ValidationRules: {
          FirstName: s.settings.profile.ValidationRules.FirstName,
          MI: s.settings.profile.ValidationRules.MI,
          LastName: s.settings.profile.ValidationRules.LastName,
          Suffix: s.settings.profile.ValidationRules.Suffix,
          Email: s.settings.profile.ValidationRules.Email,
          PhoneNumber: s.settings.profile.ValidationRules.PhoneNumber,
          PhoneNumber2: s.settings.profile.ValidationRules.PhoneNumber2,
          Address: s.settings.profile.ValidationRules.Address,
          Address2: s.settings.profile.ValidationRules.Address2,
          City: s.settings.profile.ValidationRules.City,
          StateID: s.settings.profile.ValidationRules.StateID,
          Zip: s.settings.profile.ValidationRules.Zip,
          Contact2Name: s.settings.profile.ValidationRules.Contact2Name,
          Contact2Email: s.settings.profile.ValidationRules.Contact2Email,
          Contact2Phone: s.settings.profile.ValidationRules.Contact2Phone,
        }
      };
      return profileState;
    },
    undefined,
    undefined,
    true,
    true,
    false,
    undefined,
  );

  if (validCheckout && validProfile) {
    const rootState = getState();
  
    if (isModalOpened(ModalTypes.CHECKOUT_MESSAGES)) {
      dispatch(appActionCreators.popModal(false, false, ModalTypes.CHECKOUT_MESSAGES) as any);
    }
    submitCheckout(rootState, dispatch, viewedMessages);
  } else {
    const checkoutState = getState().checkout;
  
    if (isModalOpened(ModalTypes.CHECKOUT_MESSAGES)) {
      dispatch(appActionCreators.popModal(false, true, ModalTypes.CHECKOUT_MESSAGES) as any);
    }

    if (checkoutState.ValidationRules.NotApplied && checkoutState.ValidationRules.NotApplied.errors) {
      // show top floating alert for the specific scenario: when unapplied is invalid regardless of any other errors,
      // and disable the inline alert
      dispatch(new ShowTopFloatingAlert(checkoutState.ValidationRules.NotApplied.errors[0], false, "orange"));
      dispatch(CheckoutSubmitActions.clientFailure({Errors: null}, true, true));
    } else {
      scrollToErrorCheckout();
    }
  }
}

export const actionCreators = {
  changeCreditCardType: (creditCardType?: CreditCardType): ActionCreator => dispatch => dispatch(new ChangeCreditCardType(creditCardType)),
  updateValue: createUpdateValueMethod(
    ValidateActions,
    undefined,
    (s) => s.checkout,
  ),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  // placeOrder: createApiSubmitFormMethod(CheckoutSubmitActions, (s) => s.checkout, formCreator),
  placeOrder: (): ActionCreator => (dispatch, getState) => {
    validateAndCheckout(dispatch, getState, false);
  },
  toggleCartItems: (): ActionCreator => dispatch => dispatch(new ToggleCartItems()),
  init: (): ActionCreator => (dispatch, getState) => {
    dispatch(new CheckoutInit(getState()));
    dispatch(new SaveState());
  },
  validatePaymentTypeUponLoading: (): ActionCreator => dispatch => {
    // Upon initialization (page mounted), we need to do the `innerApiSubmitFormMethod`, but only for `PaymentType`,
    // so that if the selected payment type is not allowed, the corresponding error will be displayed at the
    // very beginning. However, we might not want the SubmitErrorMessage to be shown in this case, and hence we disable it
    // after the `innerApiSubmitFormMethod` method
    const validCheckout = innerApiSubmitFormMethod(
      dispatch,
      CheckoutSubmitActions,
      (s) => {
        const checkoutState = {...s.checkout, ActiveForm: {PaymentType: s.checkout.ActiveForm.PaymentType}, ValidationRules: {PaymentType: s.checkout.ValidationRules.PaymentType}};
        return checkoutState;
      },
      undefined,      // since skipApiCall is true, we could stop passing `formCreator` for simplicity
      undefined,
      true,           // skipApi is true, so it wont call any submit action
      true,
      false,
      undefined,
    );
    if (!validCheckout) dispatch(CheckoutSubmitActions.clientFailure({Errors: null}, true, true));
  },
  changePaymentType: (PaymentTypeID: number, UseCredit: boolean): ActionCreator => (dispatch, getState) => {
    const options = getState().cacheZero.options;

    if (!options) {
      captureTentarooError(new Error("cacheZero.options not available when performing changePaymentType"));
      return;
    }
    if (!options.Group) {
      captureTentarooError(new Error("Group not available when performing changePaymentType"));
      return;
    }
    dispatch(ChangePaymentTypeActions.request({
      GroupIDi: options.Group.IDi,
      GroupTS: options.Group.TS,
      PaymentTypeID,
      UseCredit: UseCredit,
    }));
  },
  cancelCheckout: (): ActionCreator => dispatch => dispatch(new CancelCheckout()),
  continueCheckout: (checkoutMessage: CheckoutMessage, agreed?: boolean): ActionCreator => dispatch => {
    if (checkoutMessage.ShowAgreement) {
      if (agreed) {
        dispatch(new ContinueCheckout());
      } else {
        dispatch(new ShowModalAlert('Please agree before proceeding'));
      }
    } else {
      dispatch(new ContinueCheckout());
    }

  },
  finishCheckout: (checkoutMessage: CheckoutMessage, agreed?: boolean): ActionCreator => (dispatch, getState) => {
    if (checkoutMessage.ShowAgreement && !agreed) {
      dispatch(new ShowModalAlert('Please agree before proceeding'));
      return;
    }

    validateAndCheckout(dispatch, getState, true);
  },
  toggleAgreeMessage: (): ActionCreator => dispatch => dispatch(new ToggleAgreeMessage()),
  checkoutReset: (): ActionCreator => (dispatch, getState) => dispatch(new CheckoutReset(getState())),
};
