import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/image/index.scss';
import { MoreCircleIcon } from '../../../components/Icons';
import ActionButton from '../ActionButton';
import device from 'current-device';
import Loader from '../Loader';
import { generateDOMId } from '../../../utils/cypressHelper';

const namespace = (): string => 'elements--image';

interface Props {
  className?: string;
  source: string;
  width?: string | number;
  height?: string | number;
  cover?: boolean;
  placeholder?: React.ReactNode;
  borderRadius?: string;
  onError?: () => void;
  contextMenu?: React.ReactNode;
  deleted?: boolean;
  onClick?: () => void;
  loading?: boolean;
  uploading?: boolean;
  id?: number;
}
interface State {
  imageLoaded?: boolean;
}

class Image extends React.Component<Props, State> {

  // This state is safe for rollback. In a rollback scenario, it will always reset to false and then usually
  // get reset to true when it realizes the image is already loaded.
  public state: State = {
    imageLoaded: false
  };

  loaded = () => this.setState({imageLoaded: true});
  error = () => this.props.onError && this.props.onError();

  public render() {
    const {source, width, height, cover, placeholder, borderRadius, contextMenu, deleted, onClick, loading, id, uploading} = this.props;
    const style: React.CSSProperties = {};
    if (typeof width === 'string') style.width = width;
    else if (width || width === 0) style.width = `${width}px`;
    if (typeof height === 'string') style.height = height;
    else if (height || height === 0) style.height = `${height}px`;
    if (borderRadius) style.borderRadius = borderRadius;
    if (deleted) style.opacity = 0.3;
    const isMobile =  device.mobile() || device.tablet();

    if (contextMenu) {
      return (
        <div id={!id ? undefined : generateDOMId(`image-${id}`)} className={`${namespace()}--wrapper ${isMobile ? 'is-mobile' : ''}`} onClick={() => onClick ? onClick() : null}>
          <div
            onClick={() => onClick ? onClick() : null}
            style={{
              ...style,
              backgroundImage: `url(${source})`
            }}
            className={mergeClassNames(`${namespace()} cover`, this.props)}
          >
          </div>
          {!loading && !uploading ? <ActionButton id={!id ? undefined : generateDOMId(`image-${id}-options`)} contextMenu={contextMenu as any} icon={MoreCircleIcon} /> : null}
          {loading || uploading ? <Loader className={`${namespace()}--page-loader`} /> : null}
        </div>
      );
    }

    if (!this.state.imageLoaded) {
      return <div className={`${namespace()}--loader ${contextMenu ? 'has-context-menu' : ''}`}>
        {placeholder}
        <img src={source} style={{display: 'none'}} onError={this.error} className={namespace()} onLoad={this.loaded}/>
      </div>;
    }

    if (cover) {
      return (
        <div
          onClick={() => onClick ? onClick() : null}
          style={{
            ...style,
            backgroundImage: `url(${source})`
          }}
          className={mergeClassNames(`${namespace()} cover`, this.props)}
        >
        </div>
      );
    }

    return (
      <img onClick={() => onClick ? onClick() : null} src={source} onError={this.error} style={style} className={namespace()}/>
    );
  }
}
export default Image;
