import * as React from 'react';
import {Main, MainContent} from '../../../../../../Layouts';
import {Alert} from '../../../../../../Elements';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import {NumbersConnectedProps} from "../index";
import {bindActionCreators} from 'redux';
import {makeFormPreferredCampsitesSelector} from "../../../../../../../store/Events/Event/Register/Numbers/Campsite/index";
import Spots from './Spots';
import FinancialSummary from '../../../Registration/FinancialSummary';
import {actionCreators} from "../../../../../../../store/Events/Event/Register/Numbers/Confirmation/actions";
import {
  actionCreators as rollbackActionCreators,
} from "../../../../../../../store/Rollback/actions";
import {EventTypeRegistrationSettings} from "../../../../../../../models/api/cacheTwoEvents";
import {EventRegistrationParticipantType} from "../../../../../../../models/api/cacheFourEvents";
import {getGlobalError} from "../../../../../../../utils/classesHelper";
import {makeAddedNumbersProductsFilter, makeAssignedCampsiteSelector} from "../../../../../../../store/CacheFourEventsNumbers";
import {makePreviousProductsProductsFilter} from "../../../../../../../store/CacheFourEventsProducts";
import { ApplicationState } from '../../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

const namespace = () => 'pages--events--event--register--numbers--confirmation';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = WithInertAttribute<{
  numberProps: NumbersConnectedProps;
}>;

class Confirmation extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  public render() {
    const { spots, assignedCampsites, preferredCampsites, confirmation, actions, campsite, addedProducts, previousProducts, inert } = this.props;
    const { ActiveForm: { selectedFinance, otherValue }, ValidationRules } = confirmation;
    const {
      apiSaving,
      user,
      cacheFourEventsNumbers: {
        EventRegistrationParticipantTypes, EventRegistrationPaymentStatus, EventProductsOrdered
      },
      cacheTwoEvents: {
        EventTypeRegistrationSettings
      },
    } = this.props.numberProps;

    const {
      NumbersRegistrationSettings: { ShowGroupRegistrationNotes, ShowIsCampingOvernight, ShowCampsiteRanking, PromptGroupRegistrationNotes }
    } = EventTypeRegistrationSettings as EventTypeRegistrationSettings;

    let warningMessage = getGlobalError(this.props);

    let showSpots = false;
    if (!EventRegistrationPaymentStatus) return null;

    if (EventRegistrationParticipantTypes) {
      showSpots = !!EventRegistrationParticipantTypes.find((pType: EventRegistrationParticipantType) => pType.EstimateTotal > 0);
    }

    const fees: Array<{label: string; value: number;}> = [];
    fees.push({
      label: 'Registration Fees',
      value: EventRegistrationPaymentStatus.RegistrationTotal
    });
    fees.push({
      label: 'Options',
      value: EventRegistrationPaymentStatus.ClassAmount
    });
    if (addedProducts.length > 0 || previousProducts.length > 0) {
      fees.push({
        label: 'Products',
        value: EventRegistrationPaymentStatus.ProductAmount
      });
    }

    return (
      <Main
        inert={inert}
        key="numbers"
        mobileBackground="white"
        header={<HeaderProgress loading={apiSaving > 0} c2={this.props.numberProps.cacheTwoEvents} c4={this.props.numberProps.cacheFourEventsNumbers} selected="confirmation" />}
        footer={<FooterProgress selected="confirmation" />}
      >
        <MainContent loading={apiSaving > 0} noMobileMarginBottom>
          {warningMessage && <Alert noFlex>{warningMessage}</Alert>}
          {showSpots && <Spots
            EventRegistrationParticipantTypes={EventRegistrationParticipantTypes ? EventRegistrationParticipantTypes : []}
            ShowCampsiteRanking={ShowCampsiteRanking}
            ShowIsCampingOvernight={ShowIsCampingOvernight}
            ShowGroupRegistrationNotes={ShowGroupRegistrationNotes}
            PromptGroupRegistrationNotes={PromptGroupRegistrationNotes}
            SpotsActiveForm={spots.ActiveForm}
            assignedCampsites={assignedCampsites}
            preferredCampsites={preferredCampsites}
          />}
          <FinancialSummary
            title="Total Event Fees"
            fees={fees}
            paymentStatus={EventRegistrationPaymentStatus}
            showLoaders={false}
            selected={selectedFinance as 'min' | 'full' | 'other' | 'credit'}
            isFinanceDirty={false}
            onSelect={actions.updateValue}
            updateValue={actions.updateValue}
            simpleUpdate={actions.simpleUpdate}
            validationRules={ValidationRules}
            isSaving={false}
            otherValue={otherValue}
            IsAdmin={user.user ? user.user.str_permissions.hasAdminAccess : false}
            isWizard
            hasAccess
            hasAccessMessage={null}
          />
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const addedProducts = makeAddedNumbersProductsFilter();
  const previousProducts = makePreviousProductsProductsFilter();
  const assignedCampsitesSelector = makeAssignedCampsiteSelector(true);
  const makeformPreferredCampsitesSelector = makeFormPreferredCampsitesSelector();
  return {
    confirmation: state.events.event.register.numbers.confirmation,
    spots: state.events.event.register.numbers.spots,
    classes: state.events.event.register.numbers.classes,
    campsite: state.events.event.register.numbers.campsite,
    addedProducts: addedProducts(state),
    previousProducts: previousProducts(state),
    assignedCampsites: assignedCampsitesSelector(state),
    preferredCampsites: makeformPreferredCampsitesSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...rollbackActionCreators
  }, dispatch)
});

const ConnectedConfirmation = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(Confirmation);

export default ConnectedConfirmation;
