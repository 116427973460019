import type { ApplicationState } from "../../../../../store";
import { SelectValidator } from "../../../../../utils/validator/models";

export interface IValidator {
  SelectedFacilityTypeID: SelectValidator;
}

export const getFacilityTypeValues = (rootState: ApplicationState) => {
  return rootState.adminFacilityLocation.cacheOne.FacilitiesTypes ? rootState.adminFacilityLocation.cacheOne.FacilitiesTypes.filter((ft) => !ft.Inactive) : [];
};

export const FormDefinition: IValidator = {
  SelectedFacilityTypeID: {
    key: 'SelectedFacilityTypeID',
    options: {
      values: (rootState) => [{ NamePlural: 'All Facilities', ID: 0 }, ...getFacilityTypeValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'NamePlural'
    },
    defaultValue: () => {
      return 0;
    },
  },
};