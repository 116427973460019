import * as M from '../../../../../constants/messages/generic';
import { Validator } from '../../../../../utils/validator/models';


export interface IValidator {
  SelectedFacilityTypeID: Validator;
  Name: Validator;
  Description: Validator;
}


export const FormDefinition: IValidator = {
  SelectedFacilityTypeID: {
    key: 'SelectedFacilityTypeID',
  },
  Name: {
    key: 'Name',
    isRequired: () => true,
    defaultValue: () => '',
    validatejs: {
      Name: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 150,
          tooLong: M.MAX_LENGTH(150)
        },
      },
    },
  },
  Description: {
    key: 'Description',
    isRequired: () => false,
    defaultValue: () => '',
  },
};