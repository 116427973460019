import * as React from 'react';

interface State {
  width: number;
}
export default function <P>(
): (WrappedComponent: React.ClassType<P, any, any>) => React.ClassType<P, any, any> {
  return (WrappedComponent) => {
    class Component extends React.Component<P, State> {
      public state: State = {
        width: 0,
      };
      resize = () => {
        if (!this.state.width || Math.abs(window.innerWidth - this.state.width) > 50) {
          this.setState({width: window.innerWidth});
          this.forceUpdate();
        }
      };
    
      componentDidMount() {
        window.addEventListener('resize', this.resize);
      }
    
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      public render() {
        return (
          <WrappedComponent {...this.props}/>
        );
      }
    }

    return Component as any;
  };
};
