import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { namespace as ns } from './constants';
import '../../../styles/elements/financial-summary/actions.scss';

const namespace = (): string => `${ns()}--actions`;

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const Actions: React.SFC<Props> = ({ children, ...props }: Props): React.ReactElement<Props> => (
  <div className={mergeClassNames(namespace(), props)}>
    {children}
  </div>
);

export default Actions;
