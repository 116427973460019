import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import Button from './index';
import '../../../styles/elements/button/remove-button.scss';

export const namespace = (): string => 'elements--remove-button';

export interface Props {
  children?: React.ReactNode;
  className?: string;
  onClick?: React.EventHandler<any>;
  disabled?: boolean;
  admin?: boolean;
}

const RemoveButton: React.SFC<Props> = ({ onClick, disabled, admin, ...props }: Props): React.ReactElement<Props> => {
  const cn = mergeClassNames(namespace(), props);
  return (
    <Button flat admin={admin} textColor="red" className={cn} onClick={onClick} disabled={disabled}>
      {props.children}
    </Button>
  );
};

export default RemoveButton;
