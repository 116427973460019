import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as blackoutDatesHomeActionCreators } from '../../../../../store/AdminFacilityLocation/BlackoutDates/Home/actions';
import { actionCreators as cacheTwoBlackoutDatesActionCreators } from '../../../../../store/AdminFacilityLocation/CacheTwoBlackoutDates/actions';
import { actionCreators as cacheOneActionCreators, GetFacilityLocationCache } from '../../../../../store/AdminFacilityLocation/CacheOne/actions';
import { actionCreators } from "../../../../../store/AdminFacilityLocation/CacheOne/actions";
import { LoadingAll, EmptyMessage, Button, SimpleList, Loader } from '../../../../Elements';
import { Main, MainContent } from '../../../../Layouts';
import '../../../../../styles/pages/admin-facility-location/blackout-dates/home/index.scss';
import BlackoutDatesFilter from './BlackoutDatesFilter';
import { makeBlackoutDatesSelector } from '../../../../../store/AdminFacilityLocation/BlackoutDates/Home';
import { CalendarEventIcon } from '../../../../../components/Icons';
import { EmptyMessageType } from '../../../../../components/Elements/EmptyMessage';
import BlackoutDateCard, { BlackoutDateCardType } from '../../../../../components/Elements/BlackoutDate/Card';
import { CardCategory } from '../../../../../components/Elements/Card';
import { ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH, ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_NEW_PATH, ADMIN_FACILITY_LOCATION_BLACKOUT_DATES_PATH } from '../../../../../routes';
import BlackoutDateForm from '../BlackoutDate';
import { getNewBlackoutDateUrl, constructAdminFacilityLocationBlackoutDateUrlParams, getEditBlackoutDateUrl, getBlackoutDatesHomeRootUrl, constructAdminFacilityLocationUrlParams } from '../../../../../constants/urls';
import { navPush } from '../../../../../utils';
import { FacilitiesBlackoutDate } from '../../../../../models/api/adminFacilitiesCacheOne';
import { isPathnameMatchingRoute } from '../../../../../utils/urlHelper';
import { checkBlackoutDatePermission, IAdminBlackoutDateRouterParams } from '../../../../../utils/helpers/adminFacilityLocationPageHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import AdminFacilitiesLocationCacheManager from '../../../../../utils/cacheManagers/adminFacilitiesLocationCacheManager';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { shouldBlockActions } from '../../../../../utils/cacheLoaders/helpers/blockers';
import { SaveState } from '../../../../../store/Rollback/actions';
import { noOpenedModals } from '../../../../../utils/modalHelper';
import { reduxStoreService } from '../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isAdminFacilitiesLocationCacheOnePopulated} from '../../../../../utils/cachePopulatedCheckers/adminFacilities';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--facility-location--blackout-dates';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminBlackoutDateRouterParams, {}>
>;

class BlackoutDatesHome extends ComponentUpdateTemplate<ConnectedProps, {}> {
  public props: ConnectedProps;

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        const {routes} = this.props;
        const route = routes[routes.length - 1];
        if (route.path === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH ||
          route.path == ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_NEW_PATH) {
            AdminFacilitiesLocationCacheManager.getInstance().loadCacheTwoBlackoutDate({
            props: this.props,
            isStateNavigated,
            isEdit: route.path === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH,
          });
        } else {
          AdminFacilitiesLocationCacheManager.getInstance().loadAdminFacilitiesLocationCacheOne({
            props: this.props,
            isStateNavigated,
          });
        }
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    const {routes} = this.props;
    const thisRoute = routes[routes.length - 1];
    const prevRoute = prevProps.routes[prevProps.routes.length - 1];
    const thisPath = thisRoute.path;
    const prevPath = prevRoute.path;

    if (shouldBlockActions()) return;

    if (
      thisPath !== prevPath &&
      thisPath === ADMIN_FACILITY_LOCATION_BLACKOUT_DATES_PATH &&
      prevPath !== ADMIN_FACILITY_LOCATION_BLACKOUT_DATES_PATH
    ) {
      reduxStoreService().dispatch(new SaveState());
    }
  }

  componentWillUnmount() {
    this.props.actions.reset();
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    const {routes} = nextProps;
    const thisRoute = routes[routes.length - 1];
    const thisPath = thisRoute.path;

    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        if (thisPath === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH ||
            thisPath === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_NEW_PATH) {
              AdminFacilitiesLocationCacheManager.getInstance().loadCacheTwoBlackoutDate({
            props: nextProps,
            isStateNavigated,
            isEdit: thisPath === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH,
          });
        } else {
          AdminFacilitiesLocationCacheManager.getInstance().loadAdminFacilitiesLocationCacheOne({
            props: nextProps,
            isStateNavigated,
          });
        }
      }
    );
  }

  onDeleteBlackoutDate = (blackoutDate: FacilitiesBlackoutDate) => {
    checkBlackoutDatePermission(
      () => {
        this.props.actions.configDeleteBlackoutDateModal(blackoutDate);
        this.props.actions.pushDeleteBlackoutDateModal();
      },
      this.props.adminFacilityLocationCacheOne,
      true,
    );
  };

  onNewBlackoutDate = () => {
    const {routes} = this.props;
    const route = routes[routes.length - 1];

    checkBlackoutDatePermission(
      () => {
        // dont respond when we are editing blackout date
        if (route.path === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH) return;
        
        const newBlackoutDateUrl = getNewBlackoutDateUrl(constructAdminFacilityLocationBlackoutDateUrlParams(this.props, this.props.adminFacilityLocationCacheOne));
    
        navPush(this.props.router, newBlackoutDateUrl);
      },
      this.props.adminFacilityLocationCacheOne,
    );
  };

  onEditBlackoutDate = (blackoutDate: FacilitiesBlackoutDate) => {
    const editBlackoutDateUrl = getEditBlackoutDateUrl(constructAdminFacilityLocationBlackoutDateUrlParams(this.props, this.props.adminFacilityLocationCacheOne, blackoutDate));
    if (!editBlackoutDateUrl) return;
    navPush(this.props.router, editBlackoutDateUrl);
  };

  onClickMain = (e) => {
    const {routes, router, adminFacilityLocationCacheOne} = this.props;
    e.stopPropagation();
    const thisRoute = routes[routes.length - 1];

    if (thisRoute.path === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH || thisRoute.path === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_NEW_PATH) {
      const backUrl = getBlackoutDatesHomeRootUrl(constructAdminFacilityLocationUrlParams(this.props, adminFacilityLocationCacheOne));
      navPush(router, backUrl);
    }
  };

  blackoutDateRenderer = (index) => {
    const {routes, blackoutDates} = this.props;

    if (blackoutDates.length === 0) return null;

    const blackoutDate = blackoutDates[index];

    if (!blackoutDate) {
      return <div className={`${namespace()}--list-wrapper--empty-card`} key={`blackout_date_card${index}`}></div>;
    }
    const selected = isPathnameMatchingRoute(location.pathname, ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH, 'blackoutDateId', blackoutDate.ID);

    return (
      <BlackoutDateCard
        key={`blackout_date_card${index}`}
        type={BlackoutDateCardType.LIST}
        category={CardCategory.LIST}
        blackoutDate={blackoutDate}
        onEdit={this.onEditBlackoutDate}
        onDelete={this.onDeleteBlackoutDate}
        routes={routes}
        selected={selected}
      />
    );
  };

  renderContent = () => {
    const {blackoutDates, adminFacilityLocationCacheOne: {FacilitiesLocation}, routes} = this.props;
    if (blackoutDates.length === 0 || !FacilitiesLocation) return null;
    return (
      <div className={`${namespace()}--list-wrapper`}>
        <SimpleList
          itemRenderer={this.blackoutDateRenderer}
          totalLength={blackoutDates.length}
          threshold={200}
        />
      </div>
    );
  };

  public render() {
    const {routes, params, location, apiSaving, apiLoadingMap, actions, blackoutDatesHome: {ActiveForm, ValidationRules}, inert, adminFacilityLocationCacheOne} = this.props;
    if (!isAdminFacilitiesLocationCacheOnePopulated()) {
      return <LoadingAll />;
    }
    const thisRoute = routes[routes.length - 1];

    const refreshingList = apiLoadingMap[GetFacilityLocationCache.requestType] && noOpenedModals();
    const saving = apiSaving > 0 && noOpenedModals();

    return (
      <Main inert={inert} isLoading={refreshingList} onClick={this.onClickMain} isAdminFacilityLocationPage>
        <MainContent className={namespace()} handleCompact>
          <div className={`${namespace()}--list-actions`}>
            <BlackoutDatesFilter
              location={location}
              routes={routes}
              params={params}
              BlackoutDatesFilters={adminFacilityLocationCacheOne.BlackoutDatesFilters}
              reduxActions={actions}
              ActiveForm={ActiveForm}
              ValidationRules={ValidationRules}
              newButton={
                <Button id={generateDOMId("admin-blackout-date-add-btn")} className='control' color='green' textColor='white' onClick={this.onNewBlackoutDate}>New</Button>}
            />
          </div>
          {adminFacilityLocationCacheOne.FacilitiesBlackoutDates && adminFacilityLocationCacheOne.FacilitiesBlackoutDates.length > 0 ? this.renderContent() : null}
          {!adminFacilityLocationCacheOne.FacilitiesBlackoutDates || adminFacilityLocationCacheOne.FacilitiesBlackoutDates.length === 0 ? (
            <EmptyMessage
              type={EmptyMessageType.PAGE_MARGIN}
              admin
              icon={CalendarEventIcon}
              iconHeight='96px'
              iconWidth='96px'
              fixedFontSize
              description={`No ${adminFacilityLocationCacheOne.BlackoutDatesFilters && adminFacilityLocationCacheOne.BlackoutDatesFilters.IncludeExpired ? '' : 'current / future '}blackout dates added yet`}
              detail='Use blackout dates to prevent facilities from being booked on specific dates, times.'
              actions={<Button className={`${namespace()}--empty-message-btn`} onClick={this.onNewBlackoutDate} color="green">NEW BLACKOUT DATE</Button>}
            />
          ) : null}
          {(thisRoute.path === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_NEW_PATH || thisRoute.path === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH) && <BlackoutDateForm
            inert={inert}
            type={thisRoute.path === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_NEW_PATH ? 'add' : 'edit'}
            onDelete={thisRoute.path === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH ? this.onDeleteBlackoutDate : undefined}
          />}
          {saving && <Loader className={`${namespace()}--loader`} center />}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const blackoutDatesSelector = makeBlackoutDatesSelector();
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
    adminFacilityLocationCacheTwoBlackoutDates: state.adminFacilityLocation.cacheTwoBlackoutDate,
    blackoutDatesHome: state.adminFacilityLocation.blackoutDates.home,
    blackoutDates: blackoutDatesSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...blackoutDatesHomeActionCreators,
    ...cacheTwoBlackoutDatesActionCreators,
    ...cacheOneActionCreators,
  }, dispatch),
});

const ConnectedBlackoutDatesHome = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(BlackoutDatesHome);

export default withRouter<{}>(ConnectedBlackoutDatesHome);
