import * as React from 'react';
import PropTypes from 'prop-types';

export default function(Component: any): any {
  const Wrapper: React.SFC<any> = (props, { initialRoute }: { initialRoute: boolean }): React.ReactElement<any> => (
    <Component {...props} initialRoute={initialRoute}/>
  );
  Wrapper.contextTypes = { initialRoute: PropTypes.bool };
  return Wrapper;
};
