import * as React from 'react';
import {bindActionCreators} from 'redux';
import {actionCreators as eventTypeActionCreators} from "../../../store/Events/EventType/actions";
import Sidebar from '../Facilities/Sidebar';
import {makeSelectedEventTypeSelector} from "../../../store/CacheTwoEvents/index";
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
const EventSidebar:React.SFC<ConnectedProps> = (props: ConnectedProps):React.ReactElement<{}> => {
  if (!props.selectedEventType) return <div></div>; // @todo: what should I return here? should be loadingAll whenever this is true, so I don't think I care
  return <Sidebar
    systemSettings={props.systemSettings!}
    location={props.selectedEventType.DefaultLocation}
    multiple={props.selectedEventType.DefaultLocation.UpcomingEventsAtOtherLocations}
    contactInfo={props.selectedEventType.EventContactInfo}
    open={!!props.eventType.showSidebar}
    onClose={() => props.actions.showSidebar(false)}
    contactInfoLabel="Event Contact"
  />;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedEventType = makeSelectedEventTypeSelector();

  return {
    eventType: state.events.eventType,
    systemSettings: state.session.SystemSettings,
    selectedEventType: selectedEventType(state)
  };
};

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({...eventTypeActionCreators}, dispatch)});

const ConnectedEventSidebar = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(EventSidebar);

export default ConnectedEventSidebar;
