import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/elements/side-modal/actions.scss';

export const namespace = (): string => `${ns()}--actions`;

export interface ComponentProps {
  children?: React.ReactNode;
  className?: string;
}

const Component:React.SFC<any> = (props:ComponentProps):React.ReactElement<ComponentProps> => (
  <div className={namespace()}>
    {props.children}
  </div>
);

export default Component;
