import { GetAdminEventClasseCacheBodyParams } from "../../../constants/adminEventsUrls";
import { CLEAR_CACHE_PREFIX, createRequestActions } from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { HideDrawer, SilentCancelAllAction } from '../../../store/App/actions';
import { ADMIN_EVENT_TYPES_SUFFIX, ADMIN_EVENTS_MODULE_SUFFIX } from "../CacheOne/actions";
import { UPDATE_EVENT_CLASS_FORM_SUFFIX } from "../Classes/Form/actions";
import { ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX, UPDATE_EVENT_FORM_SUFFIX } from "../CacheTwoEvent/actions";
import { typeName, Action } from "../../../utils/StrongActions";


export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + UPDATE_EVENT_CLASS_FORM_SUFFIX + ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + UPDATE_EVENT_FORM_SUFFIX +'__CACHE_THREE_CLASS';
export const SUFFIX_SAVE = ADMIN_EVENT_TYPES_SUFFIX + '__CACHE_THREE_CLASS_SAVING';

export const GetAdminEventClass = createRequestActions(SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminEventsCacheThreeClass extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  getClass: (params: GetAdminEventClasseCacheBodyParams): ActionCreator => dispatch => {
    dispatch(GetAdminEventClass.request(params));
  },
  refreshClass: (params: GetAdminEventClasseCacheBodyParams): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    dispatch(GetAdminEventClass.request(params));
  },
  clearAdminEventsCacheThreeClass: (): ActionCreator => dispatch => {
    dispatch(new ClearAdminEventsCacheThreeClass());
  },
};