import * as React from 'react';
import { ConfirmationModal, ConfirmationModalContent, ConfirmationModalActions } from '../../../Templates';
import { Paragraph, Button } from '../../../Elements';
import MailIcon from '../../../../images/icons/mail.svg';
import { appActionCreators } from '../../../../store';
import { ModalTypes } from '../../../../utils/modalHelper';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';
import { WithInertAttribute } from '../../../Elements/WithInert';

type Props = WithInertAttribute<{}>

type ConnectedProps = ReturnType<typeof mapDispatchToProps>;

class ActivateAccount extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  onClose = () => {
    this.props.actions.popModal(false, true, ModalTypes.ACTIVATE_ACCOUNT);
  };

  // @todo: resend email doesn't do anything
  public render() {
    const {inert} = this.props;
    return (
      <ConfirmationModal inert={inert} icon={MailIcon} title="Activate Your Account">
        <ConfirmationModalContent>
          <Paragraph>
            Please check your email for a verification link.
          </Paragraph>
          <Paragraph>
            If you still don't get the email after 10 minutes, check your Junk/Spam folder or try to create an account again making sure to use a valid email address. You may also reach out to your Council so they can create an account for you.
          </Paragraph>
        </ConfirmationModalContent>
        <ConfirmationModalActions
          right={<Button flat onClick={this.onClose}>Close</Button>}
        />
      </ConfirmationModal>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...appActionCreators,
}, dispatch) });

const ConnectedActivateAccountModal = connect(
  null,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ActivateAccount);
export default ConnectedActivateAccountModal;
