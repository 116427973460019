import * as React from 'react';

import { Select, TextField, FieldSet, Button, Row, Column, Form, FormActions, Alert, Switch, Text, Media } from '../../../Elements';
import { ActiveForm } from '../../../../store/AddGroup/index';
import { IValidator } from '../../../../store/AddGroup/validation';
import '../../../../styles/pages/settings/profile.scss';
import {CacheZeroState} from "../../../../store/CacheZero/index";
import {checkPermission} from "../../../../utils/permissionHelper";
import { generateDOMId } from '../../../../utils/cypressHelper';
import { isUnitGenderIDShown } from '../../../../store/AddGroup/validationHelpers';
import { appActionCreators } from '../../../../store';
import { ModalTypes } from '../../../../utils/modalHelper';
import { reduxStoreService } from '../../../../store/service';
import { Validator } from '../../../../utils/validator/models';

export const namespace = (): string => 'pages--settings--profile';

export interface ProfileFormProps {
  isCreateAccount?: boolean;
  isAdmin?: boolean;
  ActiveForm: ActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
  cacheZero: CacheZeroState;
  apiSaving: number;
  apiLoading: number;
  inactive?: boolean;
  actions: {
    updateValue: (value: any, vObj: Validator) => void,
    simpleUpdate: (value: any, vObj: Validator) => void,
    apiSubmitForm: () => void,
    resetForm?: () => void,
    apiDeleteGroup?: (form: { IDi: number, restore?: boolean  } ) => void;
  }
}

class ProfileForm extends React.PureComponent<ProfileFormProps, {}> {

  public handleSubmit = e => {
    const { apiLoading, apiSaving, cacheZero } = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (!cacheZero?.options?.GeneralPermissions) return;

    checkPermission(
      () => {
        const rootState = reduxStoreService().getState();
        if (!(rootState.app.apiLoading > 0 || rootState.app.apiSaving > 0)) this.props.actions.apiSubmitForm();
      },
      cacheZero.options.GeneralPermissions.hasGroup,
      cacheZero.options.GeneralPermissions.hasGroupReason,
    );
  };

  public componentWillUnmount() {
    this.props.actions.resetForm && this.props.actions.resetForm();
  }

  private usernameOnBlur = (value: string | number | undefined, validationRules: Validator) => {
    if (!this.props.ActiveForm.Username) {
      this.props.actions.updateValue(value, validationRules);
    }
  };

  onSendResetLink = (e) => {
    const {cacheZero, ActiveForm} = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (!cacheZero?.options?.Group || !cacheZero?.options?.GeneralPermissions) return;

    const noUnsavedChanges = (
      cacheZero.options.Group.Email === ActiveForm.Email &&
      cacheZero.options.Group.Contact2Email === ActiveForm.Contact2Email
    );
    checkPermission(
      () => {
        checkPermission(
          () => {
            reduxStoreService().dispatch(appActionCreators.pushModal(ModalTypes.CONFIRM_SEND_RESET_LINK, false, true) as any);
          },
          noUnsavedChanges,
          'Please save your changes before sending a password reset link to this account.',
        );
      },
      cacheZero.options.GeneralPermissions.hasGroup,
      cacheZero.options.GeneralPermissions.hasGroupReason,
    );
  };

  onEditPassword = (e) => {
    const {cacheZero} = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (!cacheZero?.options?.GeneralPermissions) return;

    checkPermission(
      () => {
        reduxStoreService().dispatch(appActionCreators.pushModal(ModalTypes.CHANGE_PASSWORD, false, false) as any);
      },
      cacheZero.options.GeneralPermissions.hasGroup,
      cacheZero.options.GeneralPermissions.hasGroupReason,
    );
  };

  onEditEmail = (e) => {
    const {cacheZero} = this.props;
    e.preventDefault();
    e.stopPropagation();

    if (!cacheZero?.options?.GeneralPermissions) return;

    checkPermission(
      () => {
        reduxStoreService().dispatch(appActionCreators.pushModal(ModalTypes.EDIT_EMAIL, false, true) as any);
      },
      cacheZero.options.GeneralPermissions.hasGroup,
      cacheZero.options.GeneralPermissions.hasGroupReason,
    );
  };

  onDeleteGroup = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {actions: { apiDeleteGroup }, cacheZero: {options}} = this.props;
    if (!options?.GeneralPermissions) return;

    checkPermission(
      () => {
        if (apiDeleteGroup && options && options.Group) apiDeleteGroup({ IDi: options.Group.IDi});
      },
      options.GeneralPermissions.hasGroup,
      options.GeneralPermissions.hasGroupReason,
    );
  };

  onRestoreGroup = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {actions: { apiDeleteGroup }, cacheZero: {options}} = this.props;
    if (!options?.GeneralPermissions) return;

    checkPermission(
      () => {
        if (apiDeleteGroup && options && options.Group) apiDeleteGroup({ IDi: options.Group.IDi, restore: true});
      },
      options.GeneralPermissions.hasGroup,
      options.GeneralPermissions.hasGroupReason,
    );
  };

  /**
   * Whether a field or a button should be disabled
   * 
   * @param {boolean} skipPermissionCheck - skip permission check for buttons, because we do permission check on click
   * @returns 
   */
  private disabled(skipPermissionCheck?: boolean) {
    const {inactive, cacheZero, apiLoading, apiSaving} = this.props;

    if (skipPermissionCheck) {
      return inactive;
    }

    return inactive || !cacheZero.options?.GeneralPermissions?.hasGroup;
  }

  private renderFirstRow() {
    const {isCreateAccount, ActiveForm, actions, ValidationRules} = this.props;
    if (isCreateAccount) {
      return (
        <FieldSet newDesign fontSize={18} name="Account">
          <Row>
            <Column span={6} mobileSpan={12}>
              <TextField disabled={this.disabled()}
                         value={ActiveForm.Username}
                         label="Username"
                         onChange={actions.updateValue}
                         onBlur={this.usernameOnBlur}
                         validationRules={ValidationRules.Username}/>
            </Column>
          </Row>
          <Row>
            <Column span={6} mobileSpan={12}>
              <TextField disabled={this.disabled()}
                         value={ActiveForm.Password}
                         autocomplete="off"
                         label="Password"
                         type="password"
                         onBlur={actions.updateValue}
                         onChange={actions.simpleUpdate}
                         validationRules={ValidationRules.Password}/>
            </Column>
            <Column span={6} mobileSpan={12}>
              <TextField disabled={this.disabled()}
                         value={ActiveForm.ConfirmPassword}
                         autocomplete="off"
                         label="Confirm Password"
                         type="password"
                         onBlur={actions.updateValue}
                         onChange={actions.simpleUpdate}
                         validationRules={ValidationRules.ConfirmPassword}/>
            </Column>
          </Row>
        </FieldSet>
      );
    } else {
      return (
        <Row key="username-row" marginBottom={24} mobileMarginBottom={24}>
          <Column span={6} mobileSpan={12}>
            <TextField value={ActiveForm.Username}
                        disabled={this.disabled()}
                        label="Username"
                        onChange={actions.updateValue}
                        onBlur={this.usernameOnBlur}
                        validationRules={ValidationRules.Username}/>
          </Column>
        </Row>
      );
    }
  }

  private renderPasswordSection() {
    const {isAdmin, isCreateAccount} = this.props;
    if (isCreateAccount || this.disabled()) return null;
    return (
      <FieldSet
        fixedLegendFontSize newDesign
        legendMarginBottom={16} fontSize={18}
        className={`${namespace()}--password-section`}
        name="Password">
        <div className={`${namespace()}--password-button-wrapper`}>
          <Button color="white" id={generateDOMId("edit-password-btn")} onClick={this.onEditPassword}>Change password</Button>
          {isAdmin && <Button style={{maxHeight: "36px"}} color="white" admin onClick={this.onSendResetLink}>Send Reset Link</Button>}
        </div>
      </FieldSet>
    );
  }

  renderUnitFields = () => {
    const {isAdmin, ActiveForm, ValidationRules, actions } = this.props;
    return (
      [(
        <Media tabletAndGreater key="unit-tablet-and-greater">
          <Row>
            <Column span={6}>
              <Select label="Unit Type"
                      disabled={this.disabled()}
                      editable={!!isAdmin}
                      onChangeValue={actions.updateValue}
                      value={ActiveForm.UnitTypeID}
                      validationRules={ValidationRules.UnitTypeID}
                      isNumber
              />
            </Column>
            <Column span={3}>
              <TextField label="Unit"
                          disabled={this.disabled()}
                          editable={!!isAdmin}
                          onChange={actions.simpleUpdate}
                          onBlur={actions.updateValue}
                          validationRules={ValidationRules.Unit}
                          value={ActiveForm.Unit}/>
            </Column>
            {isUnitGenderIDShown(() => ActiveForm) && <Column span={3}>
              <Select label="Gender"
                          disabled={this.disabled()}
                          editable={!!isAdmin}
                          onChangeValue={actions.updateValue}
                          value={ActiveForm.UnitGenderID}
                          validationRules={ValidationRules.UnitGenderID}
                          isNumber/>
            </Column>}
          </Row>
        </Media>
      ), (
        <Media mobile key="unit-mobile">
          <Row>
            <Column span={12}>
              <Select label="Unit Type"
                      disabled={this.disabled()}
                      editable={!!isAdmin}
                      onChangeValue={actions.updateValue}
                      value={ActiveForm.UnitTypeID}
                      validationRules={ValidationRules.UnitTypeID}
                      isNumber
              />
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <TextField label="Unit"
                          disabled={this.disabled()}
                          editable={!!isAdmin}
                          onChange={actions.simpleUpdate}
                          onBlur={actions.updateValue}
                          validationRules={ValidationRules.Unit}
                          value={ActiveForm.Unit}/>
            </Column>
            {isUnitGenderIDShown(() => ActiveForm) && <Column span={6}>
              <Select label="Gender"
                          disabled={this.disabled()}
                          editable={!!isAdmin}
                          onChangeValue={actions.updateValue}
                          value={ActiveForm.UnitGenderID}
                          validationRules={ValidationRules.UnitGenderID}
                          isNumber/>
            </Column>}
          </Row>
        </Media>
      ), (
        <Row key="council-district-row">
          <Column span={6} mobileSpan={12}>
            <Select label="Council"
                    disabled={this.disabled()}
                    editable={!!isAdmin}
                    onChangeValue={actions.updateValue}
                    value={ActiveForm.CouncilIDi}
                    validationRules={ValidationRules.CouncilIDi}
                    isNumber
            />
          </Column>
          <Column span={6} mobileSpan={12}>
            <Select label="District"
                    disabled={this.disabled()}
                    editable={!!isAdmin}
                    onChangeValue={actions.updateValue}
                    value={ActiveForm.DistrictIDi}
                    validationRules={ValidationRules.DistrictIDi}
                    isNumber
            />
          </Column>
        </Row>
      ), (
        <Row key="sponsoring-org-row">
          <Column span={12} mobileSpan={12}>
            <TextField label="Sponsoring Organization"
                        disabled={this.disabled()}
                        editable={!!isAdmin}
                        onChange={actions.simpleUpdate}
                        onBlur={actions.updateValue}
                        validationRules={ValidationRules.UnitSponsor}
                        value={ActiveForm.UnitSponsor}/>
          </Column>
        </Row>
      )]
    );
  };

  public render(): any {
    const {isAdmin, inactive, cacheZero, isCreateAccount, ActiveForm, ValidationRules, actions,
      SubmitErrorMessage, apiLoading, apiSaving } = this.props;
    return [
      SubmitErrorMessage ? (
        <Row key="alert">
          <Alert>{SubmitErrorMessage}</Alert>
        </Row>
      ) : null,
      <Form
        key="form"
        className={`${namespace()}--form`}
        actions={(
          <FormActions handleCompact>
            <Row>
              <Column expand>
                {/* CreateAccount form is never disabled */
                  isCreateAccount && (
                    <Button textColor="black" flat big submit disabled={apiLoading > 0 || apiSaving > 0 || this.disabled(true)} onClick={this.handleSubmit}>CREATE ACCOUNT</Button>
                  )
                }
                {!isCreateAccount && !inactive && (<Button color="white" textColor="black" flat big submit disabled={apiLoading > 0 || apiSaving > 0 || this.disabled(true)} onClick={this.handleSubmit}>Save changes</Button>)}
              </Column>
              {isAdmin && !isCreateAccount ? (
                <Column>
                  {!inactive && <Button color="white" textColor="red" disabled={apiLoading > 0 || apiSaving > 0 || this.disabled(true)} flat big onClick={this.onDeleteGroup}>DELETE ACCOUNT</Button>}
                  {
                    /* We dont use `this.disabled(true)` here because restore button should NOT be disabled when inactive */
                    inactive && <Button color="white" textColor="red" disabled={apiLoading > 0 || apiSaving > 0} flat big onClick={this.onRestoreGroup}>RESTORE ACCOUNT</Button>
                  }
                </Column>
              ) : null}
            </Row>
          </FormActions>
        )}
        disabled={this.disabled()}
        loading={apiSaving > 0}
      >
        {this.renderFirstRow()}
        {this.renderPasswordSection()}
        <FieldSet newDesign fontSize={18} name="Group">
          <Row>
            <Column span={6} mobileSpan={12}>
              <Select
                label="Account Type"
                disabled={this.disabled()}
                editable={!!isAdmin}
                onChangeValue={actions.updateValue}
                value={ActiveForm.GroupTypeID}
                validationRules={ValidationRules.GroupTypeID}
                isNumber
              />
            </Column>
          </Row>
          {ActiveForm.GroupTypeID === 4 && (<Row>
            <Column span={12} mobileSpan={12}>
              <TextField label="Organization"
                         disabled={this.disabled()}
                         onChange={actions.simpleUpdate}
                         onBlur={actions.updateValue}
                         validationRules={ValidationRules.Organization}
                         value={ActiveForm.Organization}/>
            </Column>
          </Row>)}
          {ActiveForm.GroupTypeID === 1 && this.renderUnitFields()}
          <Row>
            <Column span={6} mobileSpan={12}>
              <Select label="Events Pricing"
                      disabled={this.disabled()}
                      editable={!!isAdmin}
                      onChangeValue={actions.updateValue}
                      value={ActiveForm.PricingTypeID}
                      validationRules={ValidationRules.PricingTypeID}
                      isNumber
              />
            </Column>
            <Column span={6} mobileSpan={12}>
              <Select label="Facilities Pricing"
                      disabled={this.disabled()}
                      editable={!!isAdmin}
                      onChangeValue={actions.updateValue}
                      value={ActiveForm.FacilityPricingTypeID}
                      validationRules={ValidationRules.FacilityPricingTypeID}
                      isNumber
              />
            </Column>
          </Row>
          <Row>
            <Column span={6} mobileSpan={12}>
              <Switch
                newDesign
                label="Allow Facility Reservations"
                disabled={this.disabled()}
                editable={!!isAdmin}
                onChange={actions.updateValue}
                value={!!ActiveForm.AllowOnlineBooking}
                validationRules={ValidationRules.AllowOnlineBooking}
              />
            </Column>
          </Row>
        </FieldSet>
        <FieldSet newDesign fontSize={18} name="Primary Contact">
          <Row>
            <Column span={6} mobileSpan={8}>
              <TextField label="First Name"
                         id={generateDOMId("addgroup-primary-firstname")}
                         disabled={this.disabled()}
                         onChange={actions.simpleUpdate}
                         onBlur={actions.updateValue}
                         validationRules={ValidationRules.FirstName}
                         value={ActiveForm.FirstName}/>
            </Column>
            <Column span={3} mobileSpan={4}>
              <TextField label="MI"
                         disabled={this.disabled()}
                         onChange={actions.simpleUpdate}
                         onBlur={actions.updateValue}
                         validationRules={ValidationRules.MI}
                         value={ActiveForm.MI}/>
            </Column>
          </Row>
          <Row>
            <Column span={6} mobileSpan={8}>
              <TextField label="Last Name"
                         disabled={this.disabled()}
                         onChange={actions.simpleUpdate}
                         onBlur={actions.updateValue}
                         validationRules={ValidationRules.LastName}
                         value={ActiveForm.LastName}/>
            </Column>
            <Column span={3} mobileSpan={4}>
              <TextField label="Suffix"
                         disabled={this.disabled()}
                         onChange={actions.simpleUpdate}
                         onBlur={actions.updateValue}
                         validationRules={ValidationRules.Suffix}
                         value={ActiveForm.Suffix}/>
            </Column>
          </Row>
          <Row>
            <Column span={6} mobileSpan={12}>
              <TextField label="Email"
                         disabled={!isAdmin || this.disabled()}
                         onChange={actions.simpleUpdate}
                         onBlur={actions.updateValue}
                         validationRules={ValidationRules.Email}
                         value={ActiveForm.Email}/>
            </Column>
            {!isAdmin && !isCreateAccount && 
              <Column span={6} mobileSpan={12}>
                {!this.disabled() && <Button id={generateDOMId("edit-email-btn")} className={`${namespace()}--no-admin-edit-email`} color="white" marginBottom={24} onClick={this.onEditEmail}>
                  Edit email
                </Button>}
              </Column>}
          </Row>

          <Row>
            <Column span={6} mobileSpan={12}>
              <TextField label="Phone Number"
                         disabled={this.disabled()}
                         onChange={actions.simpleUpdate}
                         onBlur={actions.updateValue}
                         validationRules={ValidationRules.PhoneNumber}
                         value={ActiveForm.PhoneNumber}/>
            </Column>
            <Column span={6} mobileSpan={12}>
              <TextField label="Alternate Phone Number"
                         disabled={this.disabled()}
                         onChange={actions.simpleUpdate}
                         onBlur={actions.updateValue}
                         validationRules={ValidationRules.PhoneNumber2}
                         value={ActiveForm.PhoneNumber2}/>
            </Column>
          </Row>
          <Row>
            <Column span={6} mobileSpan={12}>
              <TextField label="Address Line 1"
                         disabled={this.disabled()}
                         onChange={actions.simpleUpdate}
                         onBlur={actions.updateValue}
                         validationRules={ValidationRules.Address}
                         value={ActiveForm.Address}/>
            </Column>
            <Column span={6} mobileSpan={12}>
                <TextField label="Address Line 2"
                          disabled={this.disabled()}
                          onChange={actions.simpleUpdate}
                          onBlur={actions.updateValue}
                          validationRules={ValidationRules.Address2}
                          value={ActiveForm.Address2}/>
              </Column>
          </Row>
          <Media tabletAndGreater>
            <Row>
              <Column span={6}>
                <TextField label="City"
                          disabled={this.disabled()}
                          onChange={actions.simpleUpdate}
                          onBlur={actions.updateValue}
                          validationRules={ValidationRules.City}
                          value={ActiveForm.City}/>
              </Column>
              <Column span={3}>
                <Select label="State"
                        disabled={this.disabled()}
                        onChangeValue={actions.updateValue}
                        value={ActiveForm.StateID}
                        validationRules={ValidationRules.StateID}
                        isNumber
                />
              </Column>
              <Column span={3}>
                <TextField label="ZIP Code"
                          disabled={this.disabled()}
                          onChange={actions.simpleUpdate}
                          onBlur={actions.updateValue}
                          validationRules={ValidationRules.Zip}
                          value={ActiveForm.Zip}/>
              </Column>
            </Row>
          </Media>
          <Media mobile>
            <Row>
              <Column mobileSpan={12}>
                <TextField label="City"
                          disabled={this.disabled()}
                          onChange={actions.simpleUpdate}
                          onBlur={actions.updateValue}
                          validationRules={ValidationRules.City}
                          value={ActiveForm.City}/>
              </Column>
            </Row>
            <Row>
              <Column mobileSpan={6}>
                <Select label="State"
                        customizedId={'mobile-elements--select-state'}
                        disabled={this.disabled()}
                        onChangeValue={actions.updateValue}
                        value={ActiveForm.StateID}
                        validationRules={ValidationRules.StateID}
                        isNumber
                />
              </Column>
              <Column mobileSpan={6}>
                <TextField label="ZIP Code"
                          disabled={this.disabled()}
                          onChange={actions.simpleUpdate}
                          onBlur={actions.updateValue}
                          validationRules={ValidationRules.Zip}
                          value={ActiveForm.Zip}/>
              </Column>
            </Row>
          </Media>
        </FieldSet>

        <FieldSet newDesign fontSize={18} name="Alternate Contact">
          <Row>
            <Column span={6} mobileSpan={12}>
              <TextField label="Name"
                         disabled={this.disabled()}
                         onChange={actions.simpleUpdate}
                         onBlur={actions.updateValue}
                         validationRules={ValidationRules.Contact2Name}
                         value={ActiveForm.Contact2Name}/>
            </Column>
            <Column span={6} mobileSpan={12}>
              <TextField label="Phone Number"
                         id="ContactPhoneNumber"
                         disabled={this.disabled()}
                         onChange={actions.simpleUpdate}
                         onBlur={actions.updateValue}
                         validationRules={ValidationRules.Contact2Phone}
                         value={ActiveForm.Contact2Phone}/>
            </Column>
          </Row>
          <Row>
            <Column span={6} mobileSpan={12}>
              <TextField label="Email"
                         id="ContactEmail"
                         disabled={this.disabled()}
                         onChange={actions.simpleUpdate}
                         onBlur={actions.updateValue}
                         validationRules={ValidationRules.Contact2Email}
                         value={ActiveForm.Contact2Email}/>
            </Column>
          </Row>
        </FieldSet>
      </Form>
    ];
  }
}

export default ProfileForm;
