import '../../../styles/layouts/filter/filter-row.scss';
import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import {default as attributes, MarginProps, PaddingProps} from "../../../styles/index";

export const namespace = (): string => 'layouts--filter-row';


interface Props extends MarginProps, PaddingProps {
  className?: string;
  isExpanded?: boolean;
  forceMobile?: boolean;
  style?: React.CSSProperties;
  wrapInMobile?: boolean;
}

class FilterRow extends React.Component<Props, {}> {

  public render() {
    const {isExpanded, forceMobile, style, wrapInMobile} = this.props;

    let className = attributes(namespace(), this.props.className, this.props);

    if (isExpanded) className += ' is-expanded';
    if (forceMobile) className += ' force-mobile';
    if (wrapInMobile) className += ' wrap-in-mobile';

    return (
      <div style={style} className={`${mergeClassNames(namespace(), this.props)} ${className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default FilterRow;