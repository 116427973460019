import {
  createValidateActions,
  createApiValidateActions,
  createUpdateValueMethod,
  createSimpleUpdateValueMethod,
  createRequestActions,
} from "../../../Validation/actionCreator";
import { ActionCreator, ApplicationState } from '../../..';
import { ADMIN_FACILITY_MODULE_SUFFIX } from "../../../AdminFacilityLocation/CacheOne/actions";
import { GetAvailabilitiesParams } from "../../../../constants/urls";
import { extractRouteParams } from "../../../../utils/urlHelper";
import { ViewControlsOptions } from "../../../../components/Elements";
import moment from "moment";
import { typeName, Action } from "../../../../utils/StrongActions";

export const SUFFIX = ADMIN_FACILITY_MODULE_SUFFIX + '__AVAILABILITIES_HOME';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const GetAvailabilitiesAction = createRequestActions(SUFFIX);

@typeName('RESET' + SUFFIX)
export class ResetAvailabilities extends Action {
  constructor() { super(); }
}
@typeName("SET_VIEW" + SUFFIX)
export class SetView extends Action {
  constructor(public view: ViewControlsOptions) {super();}
}
@typeName("CHANGE_DATE" + SUFFIX)
export class ChangeDate extends Action {
  constructor(public date: moment.Moment) {super();}
}
@typeName("SHOW_MONTH_MENU" + SUFFIX)
export class ShowMonthMenu extends Action {
  constructor(public show: boolean) { super(); }
}
@typeName("SELECT_TAB" + SUFFIX)
export class SelectTab extends Action {
  constructor(public tabId: number) { super(); }
}

export type Actions = typeof actionCreators;
export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminFacilityLocation.availabilities.home),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  getAvailabilities: (params: GetAvailabilitiesParams, props: any) => dispatch => {
    dispatch(GetAvailabilitiesAction.request({
      FacilityTypeID: params.FacilityTypeID,
      StartDate: params.StartDate,
      ...extractRouteParams(props),
    }));
  },
  reset: (): ActionCreator => dispatch => dispatch(new ResetAvailabilities()),
  setView: (view: ViewControlsOptions): ActionCreator => dispatch => dispatch(new SetView(view)),
  changeDate: (date: moment.Moment): ActionCreator => dispatch => dispatch(new ChangeDate(date)),
  showMonthMenu: (show: boolean): ActionCreator => dispatch => dispatch(new ShowMonthMenu(show)),
  selectTab: (tabId: number): ActionCreator => dispatch => dispatch(new SelectTab(tabId))
};