import * as React from 'react';
import { Media, ClassGroup, Title, Class, Alert } from '../../../../Elements';
import { BaseEndUserClass, RegisteredClass } from '../../../../../models/class';
import moment from 'moment';

export interface AddedProps {
  onRemove?: (clazz: RegisteredClass) => any;
  onManage?: (c: RegisteredClass) => any;
  classes?: Array<RegisteredClass> | null;
  individual?: boolean;
  individualDOB?: moment.Moment;
  SubmitErrorMessage?: string;
  numYouth: number;
  numAdults: number;
  loading?: boolean;
  isAdmin?: boolean;
  enableWaitlist?: boolean;
  eventStateDate?: moment.Moment;
  hideForYouth?: boolean;
  labelOptions?: boolean;
}

class ClassesAdded extends React.PureComponent<AddedProps, {}> {
  public props: AddedProps;

  renderClass = (c: RegisteredClass, index: number) => {
    const { hideForYouth, onRemove, onManage, numYouth, numAdults, individual, isAdmin, enableWaitlist, eventStateDate, individualDOB } = this.props;

    return (<Class
      numYouth={numYouth}
      numAdults={numAdults}
      isPreview={false}
      key={index}
      onRemoveClass={onRemove}
      onManageClass={onManage}
      renderMobile
      classModel={c}
      individual={individual}
      individualDOB={individualDOB}
      hideForYouth
      isRegistration
      isAdmin={isAdmin}
      enableWaitlist={!!enableWaitlist}
      eventStateDate={eventStateDate}
    />);
  };

  public render() {
    const { labelOptions, classes, SubmitErrorMessage, loading } = this.props;
    if (!classes) return null;
    return (
      <ClassGroup loading={loading}>
        <Media desktop tablet>
          <Title num={classes.length}>{`Added ${labelOptions ? 'Options' : 'Classes'}`}</Title>
        </Media>
        {SubmitErrorMessage && <Media mobile>
          <Alert noFlex showMargins>{SubmitErrorMessage}</Alert>
        </Media>}
        {classes.map(this.renderClass)}
      </ClassGroup>
    );
  }
}

export default ClassesAdded;
