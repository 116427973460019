import {ActionCreator, ApplicationState} from '../../../../../';
import {eventNumbersRecalcBody} from '../../../../../../constants/urls';
import {SaveState} from "../../../../../Rollback/actions";
import {Product} from "../../../../../../models/product";
import {EventRegisterNumbersSelectPage, EventRegisterNumbersSetPendingPage} from "../Main/actions";
import {
  createApiSubmitActions,
  createApiValidateActions,
  createValidateActions
} from "../../../../../Validation/actionCreator";
import {
  composeClassRegistrations,
  composeNumbersProposed,
  composeOrderedProducts
} from "../../../../../CacheFourEventsNumbers/actions";
import {NumberProposed, PostEventRegistrationClass} from "../../../../../../models/api/cacheFourEvents";
import {ShowTopFloatingAlert} from "../../../../../App/actions";
import { typeName, Action } from '../../../../../../utils/StrongActions';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../../../utils/dataHelper';

export const SUFFIX = '__NUMBERS_PRODUCT_PAGE';

// @todo: classes doesn't realy have a form....
export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ApiSubmitActionsNumbersProducts = createApiSubmitActions(SUFFIX, true);

@typeName(`ADD_PRODUCT${SUFFIX}`) export class EventRegisterNumbersProductAdd extends Action {
  constructor(public product: Product) { super(); }
}

@typeName(`REMOVE_PRODUCT${SUFFIX}`) export class EventRegisterNumbersProductRemove extends Action {
  constructor(public product: Product) { super(); }
}

@typeName(`SET_NOT_ADDED_QUANTITY${SUFFIX}`)
export class SetNumbersNotAdded extends Action {
  constructor(public product: Product, public attrs: any) {
    super();
  }
}

@typeName(`SET_ADDED_QUANTITY${SUFFIX}`)
export class SetNumbersAdded extends Action {
  constructor(public product: Product, public attrs: any) {
    super();
  }
}

@typeName(`MANAGE_PRODUCT_NUMBERS${SUFFIX}`)
export class ManageProductNumbers extends Action {
  constructor(public product: Product) {
    super();
  }
}

@typeName(`CANCEL_PRODUCT_NUMBERS${SUFFIX}`)
export class CancelProductNumbers extends Action {
  constructor(public product: Product) {
    super();
  }
}

@typeName(`COMMIT_PRODUCT_NUMBERS${SUFFIX}`)
export class CommitProductNumbers extends Action {
  constructor(public product: Product) {
    super();
  }
}

@typeName(`RESET_MANAGING_PRODUCTS_NUMBERS${SUFFIX}`)
export class ResetManagingProductsNumbers extends Action {}


@typeName(`SHOW_ERROR${SUFFIX}`) export class EventRegisterNumbersProductShowError extends Action {
  constructor(public error: string, public isError: boolean) { super(); }
}

@typeName(`CLEAR_ERROR${SUFFIX}`) export class ClearErrorNumbersProduct extends Action {}


export type Actions = typeof actionCreators;

const formCreator = (rootState: ApplicationState) => {
  const options = rootState.cacheZero.options;
  const eventTypeID = rootState.cacheTwoEvents.eventTypeID;
  const eventID = rootState.cacheThreeEvents.eventID;
  const c4 = rootState.cacheFourEventsNumbers;
  const c3 = rootState.cacheThreeEvents;
  const numbersProposed: Array<NumberProposed> = composeNumbersProposed(rootState);
  const classRegistrations: Array<PostEventRegistrationClass> = composeClassRegistrations(c4.EventClassesGroupRegistered);

  let debugPayload: TentarooDebugPayload | undefined;
  if (!options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("options not available when submitting Register Numbers Product form");
  }
  if (!options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when submitting Register Numbers Product form");
  }
  if (!c4.EventRegistrationNumbers) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("c4.EventRegistrationNumbers not available when submitting Register Numbers Product form");
  }

  // composeOrderedProducts
  const products = composeOrderedProducts(c4.EventProductsOrdered, c4.EventProductsOrdered_Updates);

  const payload = eventNumbersRecalcBody(
    {
      GroupIDi: options?.Group?.IDi as any,
      GroupTS: options?.Group?.TS as any,
      EventTypeID: eventTypeID,
      EventIDi: eventID,
      GroupWeekIDi: c4.EventRegistrationNumbers?.IDi as any,
      NumbersProposed: numbersProposed,
      ClassRegistrations: classRegistrations,
      ProductOrders: products,
      CampsiteDepositAmount: rootState.events.event.register.numbers.spots.ActiveForm.CampsiteDepositAmount,
      ParticipantTypes: !c3.EventInfoParticipantTypes ? [] : c3.EventInfoParticipantTypes.map((pType) => pType.ID),
    }
  );

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  productsSubmit: (page: string, isNext: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());

    const orderedProducts = getState().cacheFourEventsNumbers.EventProductsOrdered;
    let isValid = true;
    if (orderedProducts) {
      for (let i = 0; i < orderedProducts.length; i++) {
        const product = orderedProducts[i];
        if (product.isManaging) {
          isValid = false;
          break;
        }
      }
    }
    if (!isValid) {
      if (!isNext) {
        dispatch(new ResetManagingProductsNumbers());
      } else {
        dispatch(new ShowTopFloatingAlert('Please update or cancel all product orders that are being edited.', false, 'orange'));
        return;
      }
    }

    if (!getState().events.event.register.numbers.products.isDirty) {
      dispatch(new EventRegisterNumbersSelectPage(page as 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'confirmation'));
      return;
    }

    dispatch(new EventRegisterNumbersSetPendingPage(page as 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'confirmation'));
    dispatch(ApiSubmitActionsNumbersProducts.request(formCreator(getState()), null));
  },
  addProduct: (product: Product): ActionCreator => dispatch => dispatch(new EventRegisterNumbersProductAdd(product)),
  removeProduct: (product: Product): ActionCreator => dispatch => dispatch(new EventRegisterNumbersProductRemove(product)),
  showError: (error: string, isError: boolean): ActionCreator => dispatch => dispatch(new EventRegisterNumbersProductShowError(error, isError)),
  setNotAdded: (product: Product, attrs: any): ActionCreator => dispatch => dispatch(new SetNumbersNotAdded(product, attrs)),
  setAdded: (product: Product, attrs: any): ActionCreator => dispatch => dispatch(new SetNumbersAdded(product, attrs)),
  manageProduct: (product: Product): ActionCreator => dispatch => dispatch(new ManageProductNumbers(product)),
  cancelProduct: (product: Product): ActionCreator => dispatch => dispatch(new CancelProductNumbers(product)),
  commitProduct: (product: Product): ActionCreator => dispatch => dispatch(new CommitProductNumbers(product)),
  resetManagingProducts: (): ActionCreator => dispatch => dispatch(new ResetManagingProductsNumbers())
};

