import * as React from 'react';
import {
  Modal, ModalHeader, ModalContent, Column, Row, Text, View, Button, ModalActions,
  ClassImage, IntegerField, MoneyField, Ellipsis
} from '../../../../../Elements';
import {bindActionCreators} from 'redux';
import { actionCreators } from "../../../../../../store/Events/Event/Modals/ClassOverrideFee/actions";
import { actionCreators as appActionCreators } from "../../../../../../store/App/actions";
import '../../../../../../styles/pages/events/event/modals/class-override-fee/index.scss';
import {RegisteredClass} from "../../../../../../models/class";
import {standardCurrencyFormat, determineNumParticipants} from "../../../../../../utils/classesHelper";
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--event--modals--class-override-fee';

type Props = WithInertAttribute<{}>

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class OverrideFeeModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  overrideSentence = (c: RegisteredClass) => {
    let sentence = 'Number of spots to override. Leave 0 to apply to ';
    if (c.JustYouth === 1) sentence += 'all youth.';
    else if (c.JustYouth === 0) sentence += 'all adults.';
    else sentence += 'everyone.';
    return sentence;
  };

  getNumSpots = (clazz: RegisteredClass) => {
    const {ActiveForm: {NumOverride}, numYouth, numAdults} = this.props;

    if (numYouth === undefined || numAdults === undefined) return 0;
    return determineNumParticipants(clazz.JustYouth, NumOverride ? NumOverride : 0, numYouth, numAdults);
  };

  getSpotText = (clazz: RegisteredClass) => {
    const numSpots = this.getNumSpots(clazz);
    if (this.props.individual) return 'Total Fees';
    let spots = '';
    if (!numSpots) spots = '';
    else if (numSpots === 1) spots = ' (1 spot)';
    else if (numSpots > 1) spots = ' (' + numSpots + ' spots)';
    return 'Total Fees' + spots;
  };

  calculateTotalFee = (clazz: RegisteredClass) => {
    const {ActiveForm: {NumOverride, Amount}} = this.props;
    let s = this.getNumSpots(clazz), f = Amount;
    if (f === undefined || isNaN(f)) f = 0;
    return standardCurrencyFormat(s * f);
  };

  public render() {
    // const { name, youth, icon, color, initials, individual } = this.props;
    const {
      individual, inert,
      ActiveForm, ValidationRules, OverridingClass,
      actions: {simpleUpdate, updateValue, saveOverride}
    } = this.props;
    const c = OverridingClass;
    if (!c) return;

    const {Name, JustYouth} = c;


    // @todo use proper validation?
    // this seems to help to make the $ grey
    // https://stackoverflow.com/questions/18397450/select-first-letter-of-input-value-and-change-its-color-via-css

    // this can help with number format
    // https://github.com/s-yadav/react-number-format/blob/master/src/number_format.js

    return (
      <Modal inert={inert} big mobileFullScreen expand className={namespace()}>
        <ModalHeader red className={`${namespace()}--header`}>
          Override Fees
        </ModalHeader>
        <ModalContent paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0}>
          {<Column gutter={false} layout="vertical" expand>
            <View layout="vertical"
              paddingTop={24}
              mobilePaddingTop={16}
              expand
              className={`${namespace()}--override-view`}
            >
              <View layout="vertical">
                <Row className={`${namespace()}--title-block--title-row`} marginBottom={16} paddingHorizontal={24} mobilePaddingHorizontal={16}>
                  <Column gutter={false} marginRight={16} expand>
                    <ClassImage classModel={c}/>
                  </Column>
                  <Column className={`${namespace()}--name-column`} gutter={false} overflowHidden marginRight={16} layout="vertical">
                    <div className={`${namespace()}--name-div`}>
                      <Ellipsis
                        isStatic
                        className={`${namespace()}--name`}
                        configs={{
                          lines: 2,
                          text: Name,
                        }} 
                      />
                    </div>
                    <div>
                      <Text size={14} color="gray">
                        {JustYouth === 1 ? 'For youth' : (JustYouth === 0) ? 'For adults' : 'For everyone'}
                      </Text>
                    </div>
                  </Column>
                </Row>
                {!individual &&<Row marginBottom={8} paddingHorizontal={24} mobilePaddingHorizontal={16} verticalAlignment="middle">
                  <Text size={13} color="gray">{this.overrideSentence(c)}</Text>
                </Row>}
                {!individual && <Row marginBottom={24} paddingHorizontal={24} mobilePaddingHorizontal={16} verticalAlignment="middle" horizontalAlignment="left">
                  <IntegerField onChange={simpleUpdate}
                                onBlur={updateValue}
                                validationRules={ValidationRules.NumOverride}
                                value={ActiveForm.NumOverride}
                                padding={false}
                                hideError
                  />
                </Row>}
              </View>
              <View layout="vertical" className={`${namespace()}--reg-fees-block`}>
                <Row className={`${namespace()}--reg-fees`} paddingTop={16} paddingHorizontal={24} mobilePaddingHorizontal={16} verticalAlignment="middle">
                  <Text size={15} weight="medium" marginRight="auto" marginVertical={4} >{individual ? 'Registration Fee' : 'Registration Fee / Spot'}</Text>
                  <MoneyField onChange={simpleUpdate}
                              onBlur={updateValue}
                              validationRules={ValidationRules.Amount}
                              value={ActiveForm.Amount}
                              padding={false}
                              placeHolder="$0.00"
                              hideError
                  />
                </Row>
                {!individual && (ActiveForm.Amount === undefined || ActiveForm.Amount >= 0) && <Row paddingHorizontal={24} mobilePaddingHorizontal={16} verticalAlignment="middle">
                  <Text size={15} weight="medium" marginRight="auto" marginVertical={4}>{individual ? 'Upfront Amount Due' : 'Upfront Amount Due / Spot'}</Text>
                  <MoneyField onChange={simpleUpdate}
                              onBlur={updateValue}
                              validationRules={ValidationRules.AmountMin}
                              value={ActiveForm.AmountMin}
                              padding={false}
                              placeHolder="$0.00"
                              hideError
                  />
                </Row>}
              </View>

              {(!individual || c.AmountPaid !== 0) && <View layout="vertical" className={`${namespace()}--total-fees-block`}>
                {!individual && <Row className={`${namespace()}--total-fees`} paddingHorizontal={24} mobilePaddingHorizontal={16} paddingTop={24} verticalAlignment="middle">
                  <Text size={15} marginRight="auto">{this.getSpotText(c)}</Text>
                  <Text size={15} weight="medium">{this.calculateTotalFee(c)}</Text>
                </Row>}
                {c.AmountPaid !== 0 && <Row paddingHorizontal={24} mobilePaddingHorizontal={16} verticalAlignment="middle">
                  <Text size={15} marginRight="auto">Amount Paid</Text>
                  <Text size={15} weight="medium">{standardCurrencyFormat(c.AmountPaid ? c.AmountPaid : 0)}</Text>
                </Row>}
              </View>}

            </View>
          </Column>}
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button flat textColor="black" onClick={saveOverride}>SAVE CHANGES</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const numYouthSelector = makeFormModalPropSelector(ModalTypes.OVERRIDE_FEES, 'numYouth');
  const numAdultsSelector = makeFormModalPropSelector(ModalTypes.OVERRIDE_FEES, 'numAdults');
  const individualSelector = makeFormModalPropSelector(ModalTypes.OVERRIDE_FEES, 'individual');
  return {
    ActiveForm: state.events.event.modals.overrideFee.ActiveForm,
    ValidationRules: state.events.event.modals.overrideFee.ValidationRules,
    OverridingClass: state.events.event.modals.overrideFee.OverridingClass,
    numYouth: numYouthSelector(state),
    numAdults: numAdultsSelector(state),
    individual: individualSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators
}, dispatch)});
const ConnectedOverrideFeeModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(OverrideFeeModal);

export default ConnectedOverrideFeeModal;
