
import { ToggleNotFound } from "../../store/App/actions";
import { SaveState } from "../../store/Rollback/actions";
import { extractRouteParams, isIdFromUrlValid } from "../urlHelper";
import { GetGLAccount } from "../../store/AdminSettings/CacheTwoGLAccount/actions";
import { AddGLAccountSubmitActions } from "../../store/AdminSettings/GLAccounts/GLAccount/Form/actions";
import { getGLAccountIdFromPath } from "../helpers/adminSettingsPageHelper";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import { reduxStoreService } from "../../store/service";
import { LoadAdminSettingsGLAccountParams } from "./helpers/models";
import {isAdminSettingsCacheTwoGLAccountPopulated} from "../cachePopulatedCheckers/adminSettings";


export const ensureAdminSettingsCacheTwoGLAccount = (
  params: LoadAdminSettingsGLAccountParams & {
    glAccountId: number | undefined,
  }
): boolean => {
  const { props, isEdit, glAccountId, isStateNavigated } = params;

  const rootState = reduxStoreService().getState();
  const adminSettingsCacheTwoGLAccount = rootState.adminSettings.cacheTwoGLAccount;

  //TODO - Add SetEmptyCart, also cache 1 for Settings if needed when adding.

  let requestedData = false;
  if (isEdit) {
    if (!isIdFromUrlValid(props.params.glAccountId) && !glAccountId) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }

    if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminSettingsCacheTwoGLAccountPopulated(adminSettingsCacheTwoGLAccount)) {
      if (isStateNavigated) {
        reduxStoreService().dispatch(new SaveState());
      }

      return false;
    } else {
      const id = getGLAccountIdFromPath(props) || glAccountId;

      const params = {
        ID: id,
        ...extractRouteParams(props),
      };
      reduxStoreService().dispatch(GetGLAccount.request(params));

      requestedData = true;
    }
  } else if (isStateNavigated) {
    reduxStoreService().dispatch(AddGLAccountSubmitActions.updateForm());
    reduxStoreService().dispatch(new SaveState());
  }

  return requestedData;
};