import * as React from 'react';
import {Alert, Button, Class, Row, Text} from '../../../../../../Elements';
import {ArrowIcon} from '../../../../../../Icons';
import {ClassesFormTemplate} from '../../../../../../Templates';
import {Props as ClassesFormTemplateProps} from '../../../../../../Templates/Forms/Classes';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import ClassesAdded from '../../ClassesAdded';
import {bindActionCreators} from 'redux';
import {actionCreators} from "../../../../../../../store/Events/Event/Modals/ClassOverrideFee/actions";
import '../../../../../../../styles/pages/events/event/register/classes.scss';
import {
  CacheFourEventsParticipantsState,
  makeAvailableClassesFilter,
  makeAvailableClassesNoTextFilter,
  makeConflictingClassesFilter,
  makeConflictingClassesNoTextFilter,
  makeRegisteredClassesFilter
} from "../../../../../../../store/CacheFourEventsParticipants";
import {CacheTwoEventsState} from "../../../../../../../store/CacheTwoEvents";
import {
  actionCreators as appActionCreators,
  ShowTopFloatingAlert
} from "../../../../../../../store/App/actions";
import {
  actionCreators as classActionCreators,
  Actions as ClassActions
} from "../../../../../../../store/Events/Event/Register/Participant/Classes/actions";
import {CacheThreeEventsState} from "../../../../../../../store/CacheThreeEvents";
import {EventTypeRegistrationSettings} from "../../../../../../../models/api/cacheTwoEvents";
import {EventInfo} from "../../../../../../../models/api/cacheThreeEvents";
import {ApplicationState} from "../../../../../../../store";
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { shouldBlockActions } from '../../../../../../../utils/cacheLoaders/helpers/blockers';
import { generateDOMId } from '../../../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../../store/service';
import { makeTextForConflictingExpander } from '../../../../../../../utils/classesHelper';
import {BaseEndUserClass} from '../../../../../../../models/class';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

export const namespace = (): string => `pages--events--event--classes--participant-numbers`;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = WithInertAttribute<{
  apiLoading: number;
  cacheTwoEvents: CacheTwoEventsState;
  cacheThreeEvents: CacheThreeEventsState;
  cacheFourEventsParticipants: CacheFourEventsParticipantsState;
}>;

interface State {
  conflictingExpanded: boolean;
}

// @todo: this is the same very similar to numbers, maybe they should be the same
class Classes extends React.Component<Props, State> {
  public state: State = {
    conflictingExpanded: false
  };
  public props: Props & ConnectedProps;

  toggleConflicting = () => this.setState({ conflictingExpanded: !this.state.conflictingExpanded });

  componentWillReceiveProps(nextProps: Props & ConnectedProps) {
    if (shouldBlockActions()) return;
    if (nextProps.dispatchWarning)  {
      this.props.actions.clearWarning();
      reduxStoreService().dispatch(new ShowTopFloatingAlert(nextProps.dispatchWarning, false, 'orange'));
    }
  }

  onAddUnscheduled = () => {
    const { user, actions } = this.props;
    if (!user.user || !user.user.str_permissions.hasAdminAccess) return undefined;
    actions.pushModal(ModalTypes.ADD_UNSCHEDULED, false, true);
  };

  getOnManageClass = () => {
    const { user, actions, cacheFourEventsParticipants } = this.props;
    if (!user.user.str_permissions.hasAdminAccess) return undefined;
    return (c) => {
      actions.pushModal(ModalTypes.OVERRIDE_FEES, false, true, {
        numYouth: cacheFourEventsParticipants.isYouth ? 1 : 0,
        numAdults: cacheFourEventsParticipants.isYouth ? 0 : 1,
        individual: true,
        class: c,
      });
    };
  };

  getOnJoinWaitlist = () => {
    const { actions } = this.props;
    return (c) => {
      actions.waitlistClass(c);
      actions.pushModal(
        ModalTypes.JOIN_WAITLIST,
        false,
        true,
      );
    };
  };

  renderClass = (actions: ClassActions, labelOptions: boolean, c: BaseEndUserClass, index: number) => {
    const {cacheTwoEvents, cacheFourEventsParticipants} = this.props;
    const EventTypeRegistrationSettings = cacheTwoEvents.EventTypeRegistrationSettings as EventTypeRegistrationSettings;
    const isAdmin = this.props.user.user.str_permissions.hasAdminAccess;

    return (<Class
      numYouth={cacheFourEventsParticipants.isYouth ? 1 : 0}
      numAdults={cacheFourEventsParticipants.isYouth ? 0 : 1}
      enableWaitlist={EventTypeRegistrationSettings.NamesRegistrationSettings.EnableClassWaitingList}
      isPreview={false}
      key={index}
      onAddClass={actions.addClass}
      onJoinWaitlist={this.getOnJoinWaitlist()}
      classModel={c}
      isAdmin={isAdmin}
      isRegistration={false}
      individual
      hideForYouth
      labelOptions={labelOptions}
    />);
  };

  renderConflictingExpander = (classesString?: string) => {
    const arrowStyle: any = {marginRight: '16px', marginLeft: '8px'};
    if (this.state.conflictingExpanded) {
      arrowStyle.transform = 'rotateZ(180deg)';
    }
    return (
      <Row className={`${namespace()}--conflict`} onClick={this.toggleConflicting} verticalAlignment="middle" marginTop={16} mobileMarginTop={8} marginBottom={24} mobileMarginBottom={16} key="conflict-row">
        <ArrowIcon color="red" style={arrowStyle}/>
        <Text weight="medium" color="red" size={16}>{makeTextForConflictingExpander(!!classesString ? 'Options' : 'Classes')}</Text>
      </Row>
    );
  };

  renderConflictingClasses = (classesString?: string) => {
    const {actions, conflictingClasses} = this.props;
    if (conflictingClasses.length === 0) {
      return null;
    } else if (!this.state.conflictingExpanded) {
      return this.renderConflictingExpander(classesString);
    }
    return [
      this.renderConflictingExpander(classesString),
      ...conflictingClasses.map(this.renderClass.bind(this, actions, !!classesString))
    ];
  };

  public render(): any {
    const {
      availableClasses, registeredClasses, noFilterAvailableClasses, noFilterConflictingClasses, actions, user,
      cacheFourEventsParticipants, cacheTwoEvents, apiLoading, classes:{ SubmitErrorMessage, isError }, cacheThreeEvents, roster, inert
    } = this.props;
    const isNewlyAdding = cacheFourEventsParticipants.EventRegistrationPerson && !cacheFourEventsParticipants.EventRegistrationPerson.IDi;
    const EventTypeRegistrationSettings = cacheTwoEvents.EventTypeRegistrationSettings as EventTypeRegistrationSettings;
    const EventInfo = cacheThreeEvents.EventInfo as EventInfo;
    let classesString;
    if (EventTypeRegistrationSettings.NamesRegistrationSettings.ShowParticipantClassesAs) {
      classesString = EventTypeRegistrationSettings.NamesRegistrationSettings.ShowParticipantClassesAs;
    }
    let emptyMessage: string | undefined;
    if (noFilterAvailableClasses && noFilterAvailableClasses.length === 0 && noFilterConflictingClasses && noFilterAvailableClasses.length === 0) {
      emptyMessage = `No ${classesString ? 'options' : 'classes'} are available`;
    } else if (noFilterAvailableClasses && noFilterAvailableClasses.length === 0) {
      emptyMessage = `You've added all available ${classesString ? 'options' : 'classes'}`;
    } else if (availableClasses && availableClasses.length === 0) {
      emptyMessage = `No available ${classesString ? 'options' : 'classes'} found`;
    }

    const templateProps: ClassesFormTemplateProps & {key: string} = {
      inert,
      key: 'participants',
      footer: <FooterProgress
        selected="classes"
        availableClasses={noFilterAvailableClasses}
        conflictingClasses={noFilterConflictingClasses}
        registeredClasses={registeredClasses}
      />,
      header: <HeaderProgress
        selected="classes"
        cacheTwoEvents={cacheTwoEvents}
        cacheFourEventsParticipants={cacheFourEventsParticipants}
        loading={apiLoading > 0}
        availableClasses={noFilterAvailableClasses}
        conflictingClasses={noFilterConflictingClasses}
        registeredClasses={registeredClasses}
      />,
      sideBar: <ClassesAdded
        classes={registeredClasses}
        onRemove={actions.removeClass}
        onManage={this.getOnManageClass()}
        numYouth={cacheFourEventsParticipants.isYouth ? 1 : 0}
        numAdults={cacheFourEventsParticipants.isYouth ? 0 : 1}
        loading={apiLoading > 0}
        isAdmin={this.props.user.user.str_permissions.hasAdminAccess}
        individual
        hideForYouth
        individualDOB={roster.ActiveForm.DOB}
        enableWaitlist={EventTypeRegistrationSettings.NamesRegistrationSettings.EnableClassWaitingList}
        eventStateDate={EventInfo.StartDateTime}
        labelOptions={!!classesString}
        SubmitErrorMessage={(isError || !isNewlyAdding) ? SubmitErrorMessage : undefined}
      />,
      emptyMessage: emptyMessage,
      addedItems: registeredClasses,
      loading: apiLoading > 0,
      classesString: classesString
    };
    if ((user.user && user.user.str_permissions.hasAdminAccess) || (SubmitErrorMessage && (isError || !isNewlyAdding))) {
      templateProps.preEmptyChildren = [];
      if (user.user && user.user.str_permissions.hasAdminAccess) {
        templateProps.preEmptyChildren.push(<Row marginBottom={16}>
          <Button id={generateDOMId("add-unscheduled-class")} admin color="white" onClick={this.onAddUnscheduled}>{`ADD UNSCHEDULED ${classesString ? 'OPTIONS' : 'CLASSES'}`}</Button>
        </Row>);
      }
      if (SubmitErrorMessage && (isError || !isNewlyAdding)) {
        templateProps.preEmptyChildren.push(<Row marginBottom={16}>
          <Alert>{SubmitErrorMessage}</Alert>
        </Row>);
      }
    }
    return [
      <ClassesFormTemplate {...templateProps}>
        {availableClasses && availableClasses.map(this.renderClass.bind(this, actions, !!classesString))}
        {this.renderConflictingClasses(classesString)}
      </ClassesFormTemplate>,
    ];
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const noFilterAvailableClasses = makeAvailableClassesNoTextFilter();
  const noFilterConflictingClasses = makeConflictingClassesNoTextFilter();
  
  const availableClasses = makeAvailableClassesFilter();
  const conflictingClasses = makeConflictingClassesFilter();
  const registeredClasses = makeRegisteredClassesFilter();
  return {
    classes: state.events.event.register.participant.classes,
    roster: state.events.event.register.participant.roster,
    user: state.user,
    availableClasses: availableClasses(state),
    conflictingClasses: conflictingClasses(state),
    registeredClasses: registeredClasses(state),
    noFilterAvailableClasses: noFilterAvailableClasses(state),
    noFilterConflictingClasses: noFilterConflictingClasses(state),
    dispatchWarning: state.cacheFourEventsParticipants.dispatchWarning,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...classActionCreators,
    ...actionCreators
  }, dispatch)
});

const ConnectedClasses = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(Classes);

export default ConnectedClasses;
