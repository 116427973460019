import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra
} from '../../../../Validation/actionCreator';
import { ActionCreator, appActionCreators, ApplicationState } from '../../../..';
import { navPush } from '../../../../../utils';
import { getEditPageRootUrl, getDuplicatePageBody } from '../../../../../constants/urls';
import { ADMIN_CMS_MODULE_SUFFIX } from '../../../../../store/AdminCMSSite/CacheOne/actions';
import { scrollModalToTop } from '../../../../../utils/helpers/adminCMSPageHelper';
import { ClientSubmitForm } from '../NewPage/actions';
import { UPDATE_CMS_PAGE_FORM_SUFFIX } from '../../Page/Form/actions';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { typeName, Action } from '../../../../../utils/StrongActions';
import { SaveState } from '../../../../Rollback/actions';
import { isModalOpened } from '../../../../../utils/modalHelper';

export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__DUPLICATE_PAGE_FORM' + UPDATE_CMS_PAGE_FORM_SUFFIX;

@typeName('RESET_FORM' + SUFFIX)
export class DuplicatePageResetForm extends Action {}
@typeName('INIT_FORM' + SUFFIX)
export class DuplicatePageInitForm extends Action {
  constructor() { super(); }
}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const DuplicatePageSubmitActions = createApiSubmitActions(SUFFIX);

export const namespace = (): string => 'pages--cms--modals--duplicate-page-form';

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.pages.modals.duplicatePageForm),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  initForm: (): ActionCreator => dispatch => {
    dispatch(new DuplicatePageInitForm());
    dispatch(new SaveState());
  },
  resetForm: (): ActionCreator => dispatch => dispatch(new DuplicatePageResetForm()),
  apiSubmit: (router: any): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const valid = innerApiSubmitFormMethod(
      dispatch,
      DuplicatePageSubmitActions,
      (s: ApplicationState) => s.adminCMSSite.pages.modals.duplicatePageForm,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
    );
    if (valid && state.adminCMSSite.cacheOne.CMSSite) {
      const body = getDuplicatePageBody(
        state.adminCMSSite.pages.modals.duplicatePageForm.ActiveForm.originalPageId,
        state.adminCMSSite.pages.modals.duplicatePageForm.ActiveForm,
      );
      
      navPush(router, getEditPageRootUrl({
        siteId: state.adminCMSSite.cacheOne.CMSSite.ID,
        domain: state.adminCMSSite.cacheOne.CMSSite.SiteDomain,
        pageId: -2,   // meaning this is a placeholder in edit page
        pageName: state.adminCMSSite.pages.modals.newPage.ActiveForm.Name,
      }));

      // Here because `DuplicatePage` can only be fired in Page/index.tsx, and a `navPush` will
      // trigger `routerWillLeave` in the page, so we will rely on the `SaveState` dispached in
      // Page/index.tsx to save before all the local changes for optimistic save
      dispatch(new DuplicatePageResetForm());
      if (isModalOpened(ModalTypes.DUPLICATE_PAGE)) {
        dispatch(appActionCreators.popModal(false, false, ModalTypes.DUPLICATE_PAGE) as any);
      }
      dispatch(new ClientSubmitForm(state.adminCMSSite.pages.modals.duplicatePageForm.ActiveForm));
      dispatch(DuplicatePageSubmitActions.request(
          body,
          getCacheLevelExtra(
            false,
            false,
          ),
        )
      );
    } else {
      // need to scroll to modal top
      scrollModalToTop(namespace());
    }
  }
};