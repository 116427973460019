import * as React from 'react';
import '../../../styles/elements/view/index.scss';
import {default as attributes, AlignmentProps, MarginProps, LayoutProps, PaddingProps} from "../../../styles/index";

export const namespace = (): string => 'elements--view';

export interface ViewProps extends MarginProps, PaddingProps, LayoutProps, AlignmentProps {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  gutter?: boolean;
  expand?: boolean;
  onClick?: () => void;
}

const View: React.SFC<ViewProps> = (
  {children, style: styleOverride, className: classNameOverride, gutter, expand, onClick, ...props}: ViewProps
): React.ReactElement<ViewProps> => {
  let className = attributes(namespace(), classNameOverride, props);
  if (gutter) className += ' with-gutter';
  if (expand) className += ' expand';
  if (onClick) className += ' clickable';
  let style: React.CSSProperties = { ...styleOverride };

  return (
    <div className={className} style={style} onClick={onClick}>
      {children}
    </div>
  );
};

export default View;
