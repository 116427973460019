import * as NM from "../../../../../../constants/messages/numbersWizard";
import { AddCampsiteAssignment, ApiSubmitActionsNumbersCampsite, CampsiteIdClientFailure, SUFFIX } from './actions';
import { apiValidationReducerCreator, chainSetDefault } from '../../../../../Validation/reducerCreator';
import {APIFailure, APISuccess, WithRootState} from "../../../../../Validation/actionCreator";
import {EventNumbersCalc, EventNumbersSave} from "../../../../../CacheFourEventsNumbers/actions";
import {CacheFourEventsNumbersCore} from "../../../../../CacheFourEventsNumbers/index";
import {createCampsiteIDiValidationRule, createCampsiteNumAdultsValidationRule, createCampsiteNumYouthValidationRule, FormDefinition, ICampsiteValidator, IValidator, getCampsiteTempId, createCampsiteInactiveValidationRule} from "./validation";
import {EventRegisterNumbersReset, EventRegisterNumbersSelectPage, EventRegisterNumbersTransitionToPending} from "../Main/actions";
import {EventRegisterNumbersResetCampsiteError} from "./actions";
import {ApiSubmitActionsNumbersSpots} from "../Spots/actions";
import {ApiSubmitActionsNumbersClasses} from "../Classes/actions";
import {ApiSubmitActionsNumbersProducts} from "../Products/actions";
import { createSelector } from "reselect";
import { ApplicationState } from "../../../../..";
import { EventRegistrationNumbers } from "../../../../../../models/api/cacheThreeEvents";
import { EventRegistrationCampsiteAssignment, EventRegistrationCampsiteRankingOption } from "../../../../../../models/api/cacheFourEvents";
import { createCampsiteFormFilter } from "../../../../../../utils/eventsHelper";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../../../utils/StrongActions";

export interface ActiveForm {
}

export interface CampsiteActiveForm extends EventRegistrationCampsiteAssignment {

}

export interface CampsiteAssigmentFormState {
  ActiveForm: CampsiteActiveForm;
  ValidationRules: ICampsiteValidator;
}

export interface NumbersWizardCampsiteStateActiveForm {
  CampsiteRank1?: number;
  CampsiteRank2?: number;
  CampsiteRank3?: number;
  TotalNumberAssigned?: number;
  NumYouthTotal?: number;
  NumAdultsTotal?: number;
}

export interface NumbersWizardCampsiteState {
  ActiveForm: NumbersWizardCampsiteStateActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
  EventRegistrationCampsiteAssignments: CampsiteAssigmentFormState[];
}

const determineInlineError = (errors: any) => {
  let message = '';
  if (errors) {
    if (errors.CampsiteOptions) message = errors.CampsiteOptions[0];
    else if (errors.CampsiteRank1 || errors.CampsiteRank2 || errors.CampsiteRank3) message = NM.RANK_CAMPSITES;
    else if (errors.TotalNumberAssigned) {
      message = NM.CAMPSITE_OVERBOOKED_INLINE;
    }
    else message = 'At least one selected campsite is invalid';
  }

  return message;
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, determineInlineError);
const checkCampsiteApiValidation = apiValidationReducerCreator(SUFFIX + '__CAMPSITE');

export const makeFilteredEventRegistrationCampsiteAssignmentsSelector = () => {
  return createSelector(
    [
      (state: ApplicationState) => state.events.event.register.numbers.campsite.EventRegistrationCampsiteAssignments,
    ],
    (EventRegistrationCampsiteAssignments: CampsiteAssigmentFormState[]) => {
      return EventRegistrationCampsiteAssignments
              .filter(createCampsiteFormFilter(true))
              .sort((a, b) => {
                if (!a.ActiveForm.CampsiteIDi) return 1;
                if (!b.ActiveForm.CampsiteIDi) return -1;

                return 0;
              });
    }
  );
};

const getInitialState = () => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition},
  EventRegistrationCampsiteAssignments: [],
});

export const makePreferredCampsitesSelector = () => {
  return createSelector(
    [
      (state: ApplicationState) => state.cacheFourEventsNumbers.EventRegistrationNumbers,
      (state: ApplicationState) => state.cacheFourEventsNumbers.EventRegistrationCampsiteRankingOptions,
    ],
    (EventRegistrationNumbers?: EventRegistrationNumbers | null, options?: EventRegistrationCampsiteRankingOption[]) => {
      const result: EventRegistrationCampsiteRankingOption[] = [];
      if (EventRegistrationNumbers && options) {
        if (EventRegistrationNumbers.CampsiteRank1IDi) {
          const CampsiteRank1 = options.find((o) => o.IDi === EventRegistrationNumbers.CampsiteRank1IDi);
          if (CampsiteRank1) result.push(CampsiteRank1);
        }
        if (EventRegistrationNumbers.CampsiteRank2IDi) {
          const CampsiteRank2 = options.find((o) => o.IDi === EventRegistrationNumbers.CampsiteRank2IDi);
          if (CampsiteRank2) result.push(CampsiteRank2);
        }
        if (EventRegistrationNumbers.CampsiteRank3IDi) {
          const CampsiteRank3 = options.find((o) => o.IDi === EventRegistrationNumbers.CampsiteRank3IDi);
          if (CampsiteRank3) result.push(CampsiteRank3);
        }
      }

      return result;
    }
  );
};

export const makeFormPreferredCampsitesSelector = () => {
  return createSelector(
    [
      (state: ApplicationState) => state.events.event.register.numbers.campsite.ActiveForm,
      (state: ApplicationState) => state.cacheFourEventsNumbers.EventRegistrationCampsiteRankingOptions,
    ],
    (form: NumbersWizardCampsiteStateActiveForm, options?: EventRegistrationCampsiteRankingOption[]) => {
      const result: EventRegistrationCampsiteRankingOption[] = [];
      if (form && options) {
        if (form.CampsiteRank1) {
          const CampsiteRank1 = options.find((o) => o.IDi === form.CampsiteRank1);
          if (CampsiteRank1) result.push(CampsiteRank1);
        }
        if (form.CampsiteRank2) {
          const CampsiteRank2 = options.find((o) => o.IDi === form.CampsiteRank2);
          if (CampsiteRank2) result.push(CampsiteRank2);
        }
        if (form.CampsiteRank3) {
          const CampsiteRank3 = options.find((o) => o.IDi === form.CampsiteRank3);
          if (CampsiteRank3) result.push(CampsiteRank3);
        }
      }

      return result;
    }
  );
};

const NumbersWizardCampsite: Reducer<NumbersWizardCampsiteState> = (oldState = getInitialState(), act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, act);

  if (oldState.EventRegistrationCampsiteAssignments) {
    state.EventRegistrationCampsiteAssignments = oldState.EventRegistrationCampsiteAssignments.map((assignment) => {
      const action = act as any;
      let result = assignment;
      // Handles onBlur/onChange of input in each campsite row
      if (action.vObj && action.vObj.extraInfo && action.vObj.extraInfo.id === assignment.ActiveForm.tempID) {
        result = checkCampsiteApiValidation(assignment, act);
      }
      
      const rootState = act.rootState;
      if ((isActionType(act, EventRegisterNumbersSelectPage) && act.selectedPage === "campsite_assignment") || (isActionType(act, EventRegisterNumbersTransitionToPending) && rootState.events.event.register.numbers.main.pendingPage === "campsite_assignment")) {
        const values = {};

        chainSetDefault(act.rootState, values, assignment.ValidationRules.NumAdults, oldState.ValidationRules);
        chainSetDefault(act.rootState, values, assignment.ValidationRules.NumYouth, oldState.ValidationRules);
        state.ActiveForm = { ...state.ActiveForm, ...values };
      }

      return result;
    });
  }

  if (
    act.type === EventNumbersCalc.successType ||
    act.type === ApiSubmitActionsNumbersSpots.successType ||
    act.type === ApiSubmitActionsNumbersClasses.successType ||
    act.type === ApiSubmitActionsNumbersCampsite.successType ||
    act.type === ApiSubmitActionsNumbersProducts.successType ||
    (act.type === EventNumbersSave.failureType && (act as WithRootState<APIFailure>).response.status === 400 && (act as WithRootState<APIFailure>).response.xhr.response.error.Detail === "Canceled")) {
    const action = <WithRootState<APISuccess>> act;
    const ValidationRules = {...FormDefinition};
    const ActiveForm = {...state.ActiveForm};
    let data: CacheFourEventsNumbersCore;
    if (
      act.type === EventNumbersCalc.successType ||
      act.type === ApiSubmitActionsNumbersSpots.successType ||
      act.type === ApiSubmitActionsNumbersClasses.successType ||
      act.type === ApiSubmitActionsNumbersCampsite.successType ||
      act.type === ApiSubmitActionsNumbersProducts.successType
    ) {
      data = action.response.response;
    } else {
      data = action.response.xhr.response;
    }
    if (data.EventRegistrationNumbers) {
      ActiveForm.CampsiteRank1 = data.EventRegistrationNumbers.CampsiteRank1IDi;
      ActiveForm.CampsiteRank2 = data.EventRegistrationNumbers.CampsiteRank2IDi;
      ActiveForm.CampsiteRank3 = data.EventRegistrationNumbers.CampsiteRank3IDi;
    }

    let EventRegistrationCampsiteAssignments: any[] = [];
    if (data.EventRegistrationCampsiteAssignments) {
      let tempID = 0;
      EventRegistrationCampsiteAssignments = data.EventRegistrationCampsiteAssignments.map((assignment) => {
        tempID = getCampsiteTempId(act.rootState, data, tempID);
        return {
          ActiveForm: {
            ...assignment,
            CampsiteIDi: assignment.CampsiteIDi || 0,
            tempID,
          },
          ValidationRules: {
            CampsiteIDi: createCampsiteIDiValidationRule(tempID),
            NumAdults: createCampsiteNumAdultsValidationRule(tempID),
            NumYouth: createCampsiteNumYouthValidationRule(tempID),
            Inactive: createCampsiteInactiveValidationRule(tempID),
          }
        };
      });
    }
    return {
      ActiveForm: ActiveForm,
      ValidationRules: ValidationRules,
      EventRegistrationCampsiteAssignments,
    };
  } else if (isActionType(act, CampsiteIdClientFailure)) {
    const newState = {...state};
    const {errors} = act;

    errors.forEach((err) => {
      newState.EventRegistrationCampsiteAssignments.forEach((_assignment, index) => {
        if (_assignment.ActiveForm.tempID === err.campsiteTempID) {
          newState.EventRegistrationCampsiteAssignments[index].ValidationRules.CampsiteIDi = {
            ...newState.EventRegistrationCampsiteAssignments[index].ValidationRules.CampsiteIDi,
            errors: [err.message],
          };
        }
      });
    });

    return newState;
  } else if (isActionType(act, AddCampsiteAssignment)) {
    const EventRegistrationCampsiteAssignments = [...state.EventRegistrationCampsiteAssignments];

    const tempID = getCampsiteTempId(act.rootState);
    EventRegistrationCampsiteAssignments.push({
      ActiveForm: {
        ID: null,
        CampsiteIDi: 0,
        NumAdults: 0,
        NumYouth: 0,
        tempID,
      },
      ValidationRules: {
        CampsiteIDi: createCampsiteIDiValidationRule(tempID),
        NumAdults: createCampsiteNumAdultsValidationRule(tempID),
        NumYouth: createCampsiteNumYouthValidationRule(tempID),
        Inactive: createCampsiteInactiveValidationRule(tempID),
      },
    });

    return {
      ...state,
      EventRegistrationCampsiteAssignments,
    };
  } else if (isActionType(act, EventRegisterNumbersResetCampsiteError)) {
    return {...state, SubmitErrorMessage: undefined};
  } else if (isActionType(act, EventRegisterNumbersReset)) {
    return getInitialState();
  }
  return state;
};

export default NumbersWizardCampsite;
