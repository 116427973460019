import * as React from 'react';
import {
  Modal, ModalTitle, ModalActions, Button } from '../../../../../../Elements';
import {withRouter, RouteComponentProps} from "react-router";
import {bindActionCreators} from "redux";
import {actionCreators as appActionCreators} from "../../../../../../../store/App/actions";
import {actionCreators} from "../../../../../../../store/AdminCMSSite/Menus/Home/actions";
import {actionCreators as menuActionCreators} from "../../../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/actions";
import {ApplicationState} from "../../../../../../../store";
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../../../utils/cypressHelper';
import {WithInertAttribute} from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--settings--order--manage';

type Props = WithInertAttribute<{}>

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

@(withRouter as any)
class DeleteMenuItemModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  
  onCancel = (e?: any) => {
    if (e) e.stopPropagation();
    this.props.actions.popModal(false, true, ModalTypes.DELETE_MENU_ITEM);
  };

  onDelete = () => {
    const {menusHome} = this.props;
    if (menusHome.deletingSiteMenuItem) {
      this.props.actions.deleteMenuItem(menusHome.deletingSiteMenuItem.ID, false, false, this.props.routes, this.props.router);
    } else if (menusHome.deletingPageMenuItem) {
      this.props.actions.deleteMenuItem(menusHome.deletingPageMenuItem.ID, false, true, this.props.routes, this.props.router);
    }
  };

  public render() {
    const {apiSaving, apiLoading, menusHome, inert} = this.props;

    return (
      <Modal inert={inert} disableDynamicHeight bodyScrollLock big higherZIndex>
        <ModalTitle marginBottom={46}>{`Delete "${menusHome.deletingSiteMenuItem ? menusHome.deletingSiteMenuItem.Name.trim() : (menusHome.deletingPageMenuItem ? menusHome.deletingPageMenuItem.Name.trim() : '')}"?`}</ModalTitle>
        <ModalActions
          noPadding
          loading={apiSaving > 0 || apiLoading > 0}
          stacked
          alignRight
          left={<Button id={generateDOMId("cancel-delete-menu")} flat textColor="blue" onClick={this.onCancel}>CANCEL</Button>}
          right={<Button textColor="red" flat onClick={this.onDelete}>DELETE</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
    menusHome: state.adminCMSSite.menus.home
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...menuActionCreators,
  ...appActionCreators,
}, dispatch)});
const ConnectedDeleteModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(DeleteMenuItemModal);

export default ConnectedDeleteModal;
