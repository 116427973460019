import * as React from 'react';
import Card from '../../Card';
import '../../../../styles/elements/skeleton-event/index.scss';

export const namespace = (): string => 'elements--skeleton-event';

export interface SkeletonEventProps {
}

const SkeletonEvent: React.SFC<SkeletonEventProps> = (
  { }
: SkeletonEventProps): React.ReactElement<SkeletonEventProps> => {
  return (
    <Card
      className={`${namespace()}`}
      padding="small"
      layout="vertical"
    >
      <div className={`${namespace()}--row-1`}>
        <div className={`${namespace()}--date-start`}></div>
        <div className={`${namespace()}--date-end`}></div>
      </div>
      <div className={`${namespace()}--row-2`}>
        <div className={`${namespace()}--event-name`}></div>
        <div className={`${namespace()}--num-tickets`}></div>
      </div>
    </Card>
  );
};

export default SkeletonEvent;
