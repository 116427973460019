
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, Row, SimpleList, EmptyMessage, Button, AdminEventTypeCard, Switch, Loader,
} from '../../../../../Elements';
import '../../../../../../styles/pages/admin-events/event-types/modal/select-event-types/index.scss';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../store/Rollback/actions';
import { actionCreators as cacheZeroActionCreators } from '../../../../../../store/CacheZero/actions';
import { actionCreators as eventTypeFormActionCreators } from "../../../../../../store/AdminEvents/EventTypes/EventType/Form/actions";
import { actionCreators } from '../../../../../../store/AdminEvents/EventTypes/Modals/SelectEventTypes/actions';
import { actionCreators as cacheOneActionCreators, GetEventTypeCacheAction, GetEventTypesAction } from '../../../../../../store/AdminEvents/CacheOne/actions';
import { ApplicationState } from '../../../../../../store';
import { navPush } from '../../../../../../utils';
import { EmptyMessageType } from '../../../../../Elements/EmptyMessage';
import { ModalHeight } from '../../../../../Elements/Modal';
import { CardCategory } from '../../../../../Elements/Card';
import { makeFilteredEventTypesSelector } from '../../../../../../store/AdminEvents/EventTypes/Modals/SelectEventTypes';
import { EventsTypesIcon } from '../../../../../Icons';
import { EventType } from '../../../../../../models/api/options';
import { AdminEventTypeCardType } from '../../../../../Elements/AdminEventType/Card';
import { getEventsHomeRootUrl, getAdminEventsSettingsHomeRootUrl, constructAdminEventsUrlParams } from '../../../../../../constants/adminEventsUrls';
import { disableBodyScroll, enableBodyScroll } from '../../../../../../utils/navHelper';
import { checkEventTypePermission } from '../../../../../../utils/helpers/adminEventsPageHelper';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { extractRouteParams } from '../../../../../../utils/urlHelper';
import { reduxStoreService } from '../../../../../../store/service';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--modals--event-types';

export type SelectLocationModalContext = 'admin-cms-edit-page' | 'admin-cms-add-page' | 'admin-facility-location';
type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> &RouteComponentProps<{}, {}>;
type Props = WithInertAttribute<{}>

@(withRouter as any)
class EventTypesModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;
  private modalContentRef;
  private eventTypeListWrapperRef;

  componentWillUnmount() {
    enableBodyScroll(this.eventTypeListWrapperRef);
    this.onClose();
  }

  componentDidMount() {
    // We dont need an init in this modal because we always reset on close
    disableBodyScroll(this.eventTypeListWrapperRef);
  }

  onClose = () => {
    this.props.actions.resetEventTypesModal();
  };
  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    if (this.props.eventTypesModal.filterString) {
      this.scrollToTop();
    }
    this.props.actions.toggleFilter(false);
    this.props.actions.filterEventTypes('');
  };
  onAddClick = () => {
    const {actions, cacheZero} = this.props;
    checkEventTypePermission(
      () => {
        actions.pushFormModal(ModalTypes.NEW_EVENT_TYPE, false);
      },
      cacheZero,
      undefined,
      undefined,
      false,
      true,
    );
  };
  onRefreshClick = () => {
    const {actions, routes} = this.props;

    actions.saveState();
    actions.getEventTypes();
  };
  onRestoreEventType = (eventType: EventType) => {
    const {eventTypeFormActions, cacheZero} = this.props;

    checkEventTypePermission(
      () => {
        eventTypeFormActions.deleteEventType(eventType.ID as number, false);
      },
      cacheZero,
      undefined,
      eventType,
      true,
      true,
    );
  };
  onDeleteEventType = (eventType: EventType) => {
    const {eventTypeFormActions, cacheZero} = this.props;

    checkEventTypePermission(
      () => {
        eventTypeFormActions.deleteEventType(eventType.ID as number, true);
      },
      cacheZero,
      undefined,
      eventType,
      true,
      true,
    );
  };
  onSelectEventType = (eventType: EventType) => {
    const {actions, router, adminEventsCacheOne} = this.props;

    actions.saveState();
    navPush(
      router,
      getEventsHomeRootUrl({
        eventTypeId: eventType.ID,
        eventTypeName: eventType.Name,
      }),
    );
    if (adminEventsCacheOne.EventsEventType && adminEventsCacheOne.EventsEventType.EventTypeRow.ID === eventType.ID) {
      reduxStoreService().dispatch(GetEventTypeCacheAction.request({
        ...extractRouteParams(this.props),
        EventTypeID: eventType.ID,
        GetYearOverview: false,
      }));
    }
  };
  onEditEventType = (eventType: EventType) => {
    const url = getAdminEventsSettingsHomeRootUrl(constructAdminEventsUrlParams(this.props, {EventsEventType: {EventTypeRow: {Name: eventType.Name}}} as any, eventType.ID as any));

    navPush(this.props.router, url);
  };
  onDuplicateEventType = (eventType: EventType) => {
    const {adminEventsCacheOne, actions, cacheZero} = this.props;
    checkEventTypePermission(
      () => {
        if (!adminEventsCacheOne.EventsEventType || adminEventsCacheOne.EventsEventType.EventTypeRow.ID !== eventType.ID) {
          actions.pushFormModal(ModalTypes.NEW_EVENT_TYPE, true, {duplicateEventTypeId: eventType.ID as any});
        } else {
          actions.pushFormModal(ModalTypes.NEW_EVENT_TYPE, false, {duplicateEventTypeId: eventType.ID as any});
        }
      },
      cacheZero,
      undefined,
      undefined,
      false,
      true,
    );
  };
  itemRenderer = (index) => {
    const { filteredEventTypes, cacheZero } = this.props;
    const eventType: EventType = filteredEventTypes[index];
    const location = cacheZero.options && cacheZero.options.Locations ? cacheZero.options.Locations.find((l) => l.ID === eventType.LocationID) : null;
    return (
      <AdminEventTypeCard
        key={`event_type${eventType.ID}`}
        type={AdminEventTypeCardType.SELECT_EVENT_TYPE_MODAL}
        eventType={eventType}
        category={CardCategory.LIST_MOBILE}
        onOpen={this.onSelectEventType}
        onEdit={this.onEditEventType}
        locationName={location ? location.Name : undefined}
        onRestore={this.onRestoreEventType}
        onDuplicate={this.onDuplicateEventType}
        onDelete={this.onDeleteEventType}
      />
    );
  };

  renderEmptyMessage = () => {
    return (
      <EmptyMessage
        icon={EventsTypesIcon}
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description='No event types found'
        actions={<Button className={`${namespace()}--empty-message-btn`} color='white' textColor='black' onClick={this.onAddClick}>NEW EVENT TYPE</Button>}
      /> 
    );
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };
  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filterEventTypes(val);
  };

  public render() {
    const { apiLoading, apiSaving, apiLoadingMap, eventTypesModal: {isTextSearching, ActiveForm, ValidationRules}, filteredEventTypes, actions, inert } = this.props;

    return (
      <Modal inert={inert} height={ModalHeight.HEIGHT_425} onClose={this.onClose} big mobileFullScreen className={namespace()}>
        <ModalHeader
          isSearching={isTextSearching}
          onAdd={this.onAddClick}
          onRefresh={this.onRefreshClick}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={this.onSearchChange}
          onSearchBack={this.onSearchBack}
          subtitle={''}
          className={`${namespace()}--header`}>
            Event Types
        </ModalHeader>
        <ModalContent
          noBodyScrollLock
          refCallback={(ref) => this.modalContentRef = ref}
          neverLoading
          paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0} >
          <Row
            refCallback={(ref) => this.eventTypeListWrapperRef = ref}
            className={`${namespace()}--row-1`}>
            {!apiLoadingMap[GetEventTypesAction.requestType] && filteredEventTypes.length > 0 && <SimpleList
              itemRenderer={this.itemRenderer}
              totalLength={filteredEventTypes ? filteredEventTypes.length : 0}
            />}
            {filteredEventTypes.length === 0 && !apiLoadingMap[GetEventTypesAction.requestType] && this.renderEmptyMessage()}
          </Row>
          <Row className={`${namespace()}--row-2`}>
            <Switch
              id={generateDOMId("select-event-types-show-deleted")}
              label="Show Deleted"
              onChange={(val, vObj) => actions.updateValue(val, vObj as any)}
              value={!!ActiveForm.ShowDeleted}
              validationRules={ValidationRules.ShowDeleted}
            />
          </Row>
          {(apiSaving > 0 || apiLoading > 0) && <Loader className={`${namespace()}--loader`} />}
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredEventTypesSelector = makeFilteredEventTypesSelector();
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    eventTypesModal: state.adminEvents.eventTypes.modals.selectEventTypes,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    filteredEventTypes: filteredEventTypesSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...cacheZeroActionCreators,
    ...cacheOneActionCreators,
    ...rollbackActionCreators,
  }, dispatch),
  eventTypeFormActions: bindActionCreators({
    ...eventTypeFormActionCreators,
  } as any, dispatch),
});
const ConnectedEventTypesModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(EventTypesModal);

export default ConnectedEventTypesModal;