
import { ApplicationState } from "../../../..";
import { SelectValidator } from "../../../../../utils/validator/models";

export interface IValidator {
  SelectedResourceCategoryID: SelectValidator;
}

export const getResourceCategoryValues = (rootState: ApplicationState) => {
  return rootState.adminCMSSite.cacheOne.CMSResourceCategories ? rootState.adminCMSSite.cacheOne.CMSResourceCategories.filter((rc) => !rc.Inactive) : [];
};

export const FormDefinition: IValidator = {
  SelectedResourceCategoryID: {
    key: 'SelectedResourceCategoryID',
    options: {
      values: (rootState) => [{ Name: 'All Categories', ID: 0 }, ...getResourceCategoryValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'Name'
    },
    defaultValue: () => {
      return 0;
    },
  },
};