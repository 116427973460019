import { ValidationResults } from "@tentaroo/core";

import type {ActionCreator, ApplicationState} from '../../../';
import {
  createApiSubmitActions,
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions
} from "../../../Validation/actionCreator";
import * as moment from 'moment';
import {getAuditLogBody} from "../../../../constants/urls";
import {AuditLogForm} from "./index";
import {validateAll} from "../../../../utils/validator";
import {ShowTopFloatingAlert} from "../../../App/actions";
import {SaveState} from "../../../Rollback/actions";
import { typeName, Action } from '../../../../utils/StrongActions';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../utils/dataHelper';
import { ModalTypes, isModalOpened } from '../../../../utils/modalHelper';

export const SUFFIX = '__AUDIT_LOG';

@typeName(`RESET${SUFFIX}`)
export class AuditLogReset extends Action {}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ReportAuditLogActions = createApiSubmitActions(SUFFIX, true);

export type Actions = typeof actionCreators;

export const formCreator = (rootState: ApplicationState, activeForm: AuditLogForm) => {
  let debugPayload: TentarooDebugPayload | undefined;
  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when submting AuditLog form");
  }
  const Group = rootState.cacheZero.options?.Group;
  const payload = getAuditLogBody({
    GroupIDi: Group?.IDi as any,
    StartDate: activeForm.StartDate!
  });

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  resetAuditLog: (): ActionCreator => dispatch => dispatch(new AuditLogReset()),
  reportAuditLog: (GroupIDi: number, StartDate: moment.Moment): ActionCreator => dispatch => {
    dispatch(ReportAuditLogActions.request({GroupIDi, StartDate}, null));
  },
  // apiSubmitForm: createApiSubmitFormMethod(ReportAuditLogActions, (s) => s.settings.prevOrders.auditLog, formCreator),
  apiSubmitForm: (): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const validationResults = validateAll(s => s.settings.prevOrders.auditLog);
    if (!validationResults) {
      const activeForm = getState().settings.prevOrders.auditLog.ActiveForm;
      dispatch(ReportAuditLogActions.request(formCreator(getState(), activeForm), null));
    } else {
      dispatch(ReportAuditLogActions.clientFailure(validationResults, isModalOpened(ModalTypes.PAYMENT_TOOLS)));
      dispatch(new ShowTopFloatingAlert(validationResults.Errors[Object.keys(validationResults.Errors)[0]][0], isModalOpened(ModalTypes.PAYMENT_TOOLS), 'orange'));
    }
  },
  updateValue: createUpdateValueMethod(
    ValidateActions,
    ApiValidateActions,
    (s) => s.settings.prevOrders.auditLog,
    undefined,
    undefined,
    undefined,
    (validationResults: ValidationResults, key: string, dispatch) => {
      dispatch(new ShowTopFloatingAlert(validationResults.Errors[Object.keys(validationResults.Errors)[0]][0], isModalOpened(ModalTypes.PAYMENT_TOOLS), 'orange'));
    }),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
};
