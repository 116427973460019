import * as React from 'react';
import { slug } from '../../../utils';
import '../../../styles/elements/modal-select/index.scss';
import Button from '../Button';
import { default as attributes,  PaddingProps } from '../../../styles';
import { generateDOMId } from '../../../utils/cypressHelper';
import { BaseValidator } from '../../../utils/validator/models';

export const namespace = (): string => 'elements--modal-select';

export interface ModalSelectProps extends PaddingProps {
  className?: string;
  label?: string;
  value?: any;
  validationRules?: BaseValidator;
  hideError?: boolean;
  disabled?: boolean;
  onChange?: React.EventHandler<any>;
  selectButtonLabel?: string;
  onClickSelectButton: () => any;
  selectedCard?: React.ReactNode;
}

class ModalSelect extends React.Component<ModalSelectProps, {}> {
  public static defaultProps = {

  };
  public props: ModalSelectProps;

  public render() {
    const props = this.props;
    const {validationRules, hideError, value, selectButtonLabel, onClickSelectButton, selectedCard, disabled, className: classNameOverride} = props;
    const className = attributes(namespace(), classNameOverride, props);

    // setup label
    const id = props.label ? `${namespace()}-${slug(props.label)}` : undefined;
    const labelClassName = `${namespace()}--label fixed`;
    const label = (
      <label htmlFor={id} className={labelClassName}>{props.label}</label>
    );

    const error = validationRules && !hideError ? validationRules.errors : undefined;

    return (
      <div className={`${className}`}>
        <div className={`${namespace()}--container`}>
          {label}
          {!value && (
            <Button
              id={generateDOMId(`${selectButtonLabel ? slug(selectButtonLabel) : "modal-select-btn"}`)}
              disabled={disabled}
              color='white'
              className={`${namespace()}--select-button`}
              onClick={() => onClickSelectButton()}
            >{selectButtonLabel}</Button>
          )}
          {value ? selectedCard : null}
        </div>
        {error && error.length > 0 && <div className={`${namespace()}--error`}>{error[0]}</div>}
      </div>
    );
  }
}

export default ModalSelect;