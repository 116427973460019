import { makeRequestEpic } from "../../../../store/Validation/epicCreator";
import { getUpdateSiteMenuOrderUrl, getUpdatePageMenuOrderUrl } from "../../../../constants/urls";
import { AjaxResponse } from "rxjs/Rx";
import { SubmitSiteMenuOrderActions, SubmitPageMenuOrderActions } from "./actions";

export const adminCMSSiteUpdateSiteMenuOrderEpic = makeRequestEpic(
  SubmitSiteMenuOrderActions,
  getUpdateSiteMenuOrderUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    return 'Changes saved';
  }
);

export const adminCMSSiteUpdatePageMenuOrderEpic = makeRequestEpic(
  SubmitPageMenuOrderActions,
  getUpdatePageMenuOrderUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    return 'Changes saved';
  }
);
