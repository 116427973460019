import * as React from 'react';
import {Card, Column, RadioGroupOption, Row, Text} from '../../../../../../Elements';
import {CacheFourEventsParticipantsState} from "../../../../../../../store/CacheFourEventsParticipants";
import {EventRegistrationParticipantType} from "../../../../../../../models/api/cacheFourEvents";
import {RED_THRESHOLD} from "../../../constants";
import { generateDOMId } from '../../../../../../../utils/cypressHelper';

interface Props {
  cacheFourEventsParticipants: CacheFourEventsParticipantsState;
  selected: boolean;
  pType: EventRegistrationParticipantType;
  NumSpotsPending: number;
  NumNamesPending: number;
  disableRadio?: boolean;
  isAdding?: boolean;
  onSelect: Function;
  editMode?: boolean;
}

export class ParticipantType extends React.Component<Props, {}> {
  props: Props;

  public render() {
    const {pType, selected, NumSpotsPending, NumNamesPending, disableRadio, isAdding, onSelect, editMode} = this.props;

    const quantity = NumSpotsPending - NumNamesPending;
    let quantityText = `${quantity} spot${quantity !== 1 ? 's' : ''} available`;

    if (NumSpotsPending === 0) {
      quantityText = 'No spots purchased';
    } else if (quantity === 0) {
      quantityText = 'All spots in your registration are used';
    }

    return <Card template="mobile-stretch" component="label" padding="small">
      <Row>
        <Column layout="vertical" expand>
          <Text color={(isAdding && quantity <= 0) ? 'gray' : undefined} weight="medium" size={18} marginBottom={0}>
            {pType.Name}
          </Text>
          {!disableRadio && <Text color={quantity < RED_THRESHOLD ? 'red' : 'gray'} size={14}>
            {quantityText}
          </Text>}
        </Column>
        {(editMode || quantity > 0) && <Column marginLeft={20}>
          <RadioGroupOption
            id={generateDOMId(`participant-type-${pType.ID}`)}
            value={pType.ID}
            selected={selected || !!disableRadio}
            disabled={disableRadio}
            onChange={onSelect}
          />
        </Column>}
      </Row>
    </Card>;
  }
}
