import * as React from 'react';
import { namespace as ns } from './constants';
import ValidImage from '../../../images/elements/text-field/valid.svg';

const namespace = (): string => `${ns()}--marker`;

const ValidIcon:React.SFC<{}> = ():React.ReactElement<{}> =>
  <i className={`${namespace()} ${namespace()}--valid-icon`}>
    <ValidImage width="17px" height="12px" className={`${namespace()}--valid-icon--image`}/>
  </i>;

export default ValidIcon;
