import * as React from 'react';
import {CardGroup} from '../../../../Elements';
import '../../../../../styles/pages/facilities/trip/summary/facility-group.scss';

const namespace = (): string => 'pages--facilities--trip--summary--facility-group';

interface Props {
  children?: React.ReactNode;
}

const FacilityGroup: React.SFC<Props> = ({ children }: Props): React.ReactElement<Props> => (
  <CardGroup className={namespace()}>
    {children}
  </CardGroup>
);

export default FacilityGroup;
