import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import * as M from '../../../../../constants/messages/generic';
import { getSocialTypeValidationRule, getSocialTypesValue } from "../../../Settings/General/validation";
import * as GENERIC_M from "../../../../../constants/messages/generic";
import { CMSSocial } from "../../../../../models/api/adminCMSCacheOne";
import { ApplicationState } from "../../../../../store";
import { makeDeletedPrefixItemMapper } from "../../../../../utils/dataHelper";
import { SelectValidator, Validator } from "../../../../../utils/validator/models";

const socialMediaKey = {
  SocialTypeID: true,
  SocialUser: true,
};

const getOwnerValues = (rootState: ApplicationState) => {
  const sites = rootState.cacheZero && rootState.cacheZero.options && rootState.cacheZero.options.CMSSites;
  if (sites) return sites.filter((s) => {
    return !s.Inactive || (s.Inactive && s.ID === rootState.adminCMSSite.contacts.contact.form.ActiveForm.SiteID);
  }).map(makeDeletedPrefixItemMapper('SiteDomain'));
  return [];
};

export const getSocialUserValidationRule = (key: string, socialMedia: CMSSocial) => {
  if (socialMedia.SocialTypeID === 15) {
    return {
      key,
      isRequired: () => false,
      validatejs: {
        [key]: {
          url: {message: '^' + GENERIC_M.INVALID_FULL_URL},
          presence: {message: '^' + M.REQUIRED},
          length: {
            maximum: 100,
            tooLong: M.MAX_LENGTH(100),
          },
        },
      },
    };
  } else {
    return {
      key,
      isRequired: () => false,
      validatejs: {
        [key]: {
          presence: {message: '^' + M.REQUIRED},
          length: {
            maximum: 100,
            tooLong: M.MAX_LENGTH(100),
          },
        },
      },
    };
  }
};

export const getSocialMediaDefaultDefinition = (rootState: ApplicationState) => {
  const list = getSocialTypesValue(rootState);
  const definition = {
    SocialTypeID0: getSocialTypeValidationRule('SocialTypeID0'),
    SocialUser0: getSocialUserValidationRule('SocialUser0', {SocialTypeID: list.length > 0 ? list[0].ID : 1} as any),
  };

  return definition;
};

export const getSocialMediasDefinition = (rootState: ApplicationState, contactSocials?: CMSSocial[]) => {
  let definition = {};

  if (contactSocials && contactSocials.length > 0) {
    contactSocials.forEach((ss, index) => {
      for (let key of Object.keys(ss)) {
        if (socialMediaKey[key]) {
          const ruleKey = `${key}${index}`;

          switch (key) {
            case 'SocialTypeID':
              definition[ruleKey] = getSocialTypeValidationRule(ruleKey);
              break;
            case 'SocialUser':
              definition[ruleKey] = getSocialUserValidationRule(ruleKey, ss);
              break;
          }
        }
      }
    });
  } else {
    definition = getSocialMediaDefaultDefinition(rootState);
  }

  return definition;
};

export interface IValidator {
  LastName: Validator;
  FirstName: Validator;
  MI: Validator;
  Suffix: Validator;
  Title: Validator;
  Company: Validator;
  CategoryID: SelectValidator;
  Phone: Validator;
  PhoneExt: Validator;
  MobilePhone: Validator;
  Fax: Validator;
  Email: Validator;
  FeaturedImage: Validator;
  SiteID: SelectValidator;
}
export const FormDefinition: IValidator = {
  LastName: {
    key: 'LastName',
    isRequired: (rootState) => {
      return !!!rootState.adminCMSSite.contacts.contact.form.ActiveForm.Title;
    },
    defaultValue: () => '',
    skipResetLocalDependantsToDefault: true,
    localDependants: ['Title'],
    validatejs: {
      LastName: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50),
        },
      },
    },
  },
  FirstName: {
    key: 'FirstName',
    isRequired: (rootState) => {
      return !!!rootState.adminCMSSite.contacts.contact.form.ActiveForm.Title;
    },
    defaultValue: () => '',
    skipResetLocalDependantsToDefault: true,
    localDependants: ['Title'],
    validatejs: {
      FirstName: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50),
        },
      },
    },
  },
  MI: {
    key: 'MI',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      MI: {
        length: {
          maximum: 10,
          tooLong: M.MAX_LENGTH(10)
        },
      },
    },
  },
  Suffix: {
    key: 'Suffix',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      Suffix: {
        length: {
          maximum: 10,
          tooLong: M.MAX_LENGTH(10)
        },
      },
    },
  },
  Title: {
    key: 'Title',
    isRequired: (rootState) => {
      return !!!rootState.adminCMSSite.contacts.contact.form.ActiveForm.FirstName || !!!rootState.adminCMSSite.contacts.contact.form.ActiveForm.LastName;
    },
    defaultValue: () => '',
    skipResetLocalDependantsToDefault: true,
    localDependants: ['FirstName', 'LastName'],
    validatejs: {
      Title: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 1024,
          tooLong: M.MAX_LENGTH(1024)
        },
      },
    },
  },
  Company: {
    key: 'Company',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      Company: {
        length: {
          maximum: 200,
          tooLong: M.MAX_LENGTH(200)
        },
      },
    },
  },
  CategoryID: {
    key: 'CategoryID',
    isRequired: () => true,
    options: {
      values: (rootState) => {
        return rootState.adminCMSSite.cacheOne.ContactCategories ? [{ID: 0, Name: ''}, ...rootState.adminCMSSite.cacheOne.ContactCategories] : [];
      },
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: 0,
    },
    defaultValue: () => 0,
    validatejs: {
      CategoryID: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0
        }
      }
    }
  },
  Phone: {
    key: 'Phone',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      Phone: {
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50),
        },
      },
    },
  },
  PhoneExt: {
    key: 'PhoneExt',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      PhoneExt: {
        length: {
          maximum: 10,
          tooLong: M.MAX_LENGTH(10),
        },
      },
    },
  },
  MobilePhone: {
    key: 'MobilePhone',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      MobilePhone: {
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50),
        },
      },
    },
  },
  Fax: {
    key: 'Fax',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      Fax: {
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50),
        },
      },
    },
  },
  Email: {
    key: 'Email',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      Email: {
        email: {message: '^' + M.INVALID},
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50)
        },
      },
    },
  },
  FeaturedImage: {
    key: 'FeaturedImage',
    isRequired: () => false,
  },
  SiteID: {
    key: 'SiteID',
    isRequired: (rootState) => {
      return !!rootState.adminCMSSite.cacheTwoContacts.CMSContact;
    },
    options: {
      values: (rootState) => [...getOwnerValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'SiteDomain'
    },
    customValidate: (rootState) => {
      const SiteID = rootState.adminCMSSite.contacts.contact.form.ActiveForm.SiteID;

      if (SiteID) {
        const site = rootState.cacheZero.options && rootState.cacheZero.options.CMSSites ? rootState.cacheZero.options.CMSSites.find((s) => s.ID === SiteID) : null;

        // if REQUIRED and INVALID
        if (!!rootState.adminCMSSite.cacheTwoContacts.CMSContact && site && site.Inactive) {
          return M.SELECTED_DROPDOWN_ITEM_DELETED;
        }
      }
      
      return undefined;
    },
    defaultValue: (rootState) => {
      const owners = getOwnerValues(rootState);
      return owners.length > 0 ? owners[0].ID : -1;
    },
    validatejs: {
      SiteID: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          greaterThan: 0,
          notGreaterThan: SELECT_NOT_GREATER_THAN,
        },
      },
    },
  },
};