import { FormDefinition } from './validation';
import { apiValidationReducerCreator } from '../../../../Validation/reducerCreator';
import { SUFFIX, PageTypesToggleFilterTabs, PageTypesModalFilter, ResetPageTypesModal, PageTypesShowAdvancedFilter, InitPageTypesModal, ToggleFilterInvalidInSelectPageModal } from './actions';
import { ApplicationState } from '../../../..';
import { CMSPage } from '../../../../../models/api/adminCMSCacheOne';
import { createSelector } from 'reselect';
import * as moment from 'moment';
import { convertObjToMoment } from '../../../../../utils';
import { GetSiteCache } from '../../../CacheOne/actions';
import { APISuccess, WithRootState } from '../../../../Validation/actionCreator';
import { GetSiteMenuItem } from '../../../CacheTwoSiteMenus/actions';
import { GetPageMenuItem } from '../../../CacheTwoPageMenus/actions';
import { compareDataByRowNum } from '../../../../../utils/dateHelper';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import { PageTypeModalState } from './index.type';
import {setDefaults} from '../../../../../utils/validator';

const getInitialState = () => ({
  isRefreshing: false,
  filterString: '',
  isTextSearching: false,
  showAdvancedFilter: false,
  ValidationRules: {...FormDefinition},
  ActiveForm: {
  },
  pages: [],
  filterInvalid: false,
});

export const pagesSelector = (state: ApplicationState) => {
  if (state.adminCMSSite && state.adminCMSSite.pages.modals.pageType.pages) return state.adminCMSSite.pages.modals.pageType.pages;
  return [];
};

const filterSelector = (state: ApplicationState) => state.adminCMSSite.pages.modals.pageType.filterString;

export const pageFilterMatch = (p: CMSPage, filter: string) => {
  const f = filter.toLowerCase();
  if (!f || f === '') return true;
  const nameMatches = p.Name && p.Name.toLowerCase().includes(f);
  const urlMatches = p.ShortURL && p.ShortURL.toLowerCase().includes(f);
  const eventTypeMatches = p.EventType && p.EventType.toLowerCase().includes(f);
  const facilityLocationMatches = p.FacilityLocation && p.FacilityLocation.toLowerCase().includes(f);
  const districtMatches = p.District && p.District.toLowerCase().includes(f);

  return (nameMatches || urlMatches || eventTypeMatches || facilityLocationMatches || districtMatches);
};

export const makeFilteredPagesSelector = () => {
  return createSelector(
    [pagesSelector, filterSelector],
    (pages: Array<CMSPage>, filter: string) => {
      if (!pages) return [];
      if (!filter || filter === '') return pages.filter((p) => !p.Inactive).sort(compareDataByRowNum);
      return pages.filter(p => pageFilterMatch(p, filter) && !p.Inactive).sort(compareDataByRowNum);
    }
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const PageTypeModalReducer: Reducer<PageTypeModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);
  if (act.type === GetSiteCache.successType || act.type === GetSiteMenuItem.successType || act.type === GetPageMenuItem.successType) {
    // Will update the filter in this node whenever server returns one
    // Here we wont need to handle special case for PageList scenario, because every time 
    // the page modal is opened, it will call the `Init` action to retreive the default value
    // from cache one. See handling of `InitPageTypesModal` below
    const action = <WithRootState<APISuccess>>act;
    const newState = {...state};
    const res = action.response.xhr.response;

    // get CMSPageFilters
    const CMSPagesFilters = res.CMSPagesFilters;
    if (CMSPagesFilters) {
      const filters = convertObjToMoment(CMSPagesFilters, ['EventStartDate']);
      if (!filters.EventStartDate) filters.EventStartDate = moment();
      newState.ActiveForm = {
        ...newState.ActiveForm,
        ...filters,
        EventCategoryID: filters.EventCategoryID ? filters.EventCategoryID : 0,
      };
    }
    
    // only update pages in page type modal node if
    // 1. We are reqeusting site cache, that is to refresh cache one
    // 2. We are requesting menu items, and `CMSPages` exists in res, meaning we are retreiving cache one from menu item endpoint
    if (act.type === GetSiteCache.successType || ((act.type === GetSiteMenuItem.successType || act.type === GetPageMenuItem.successType) && res.CMSPages)) {
      newState.pages = res.CMSPages;
    }

    return newState;
  } else if (isActionType(act, PageTypesToggleFilterTabs)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, PageTypesModalFilter)) {
    return {
      ...state,
      filterString: act.filterString,
    };
  } else if (isActionType(act, PageTypesShowAdvancedFilter)) {
    return {
      ...state,
      showAdvancedFilter: act.show,
    };
  } else if (isActionType(act, ToggleFilterInvalidInSelectPageModal)) {
    return {
      ...state,
      filterInvalid: act.invalid,
    };
  } else if (isActionType(act, InitPageTypesModal)) {
    const {CMSPagesFilters, CMSPages} = act;

    // First init state with default value
    const stateWithDefaultValue = setDefaults(act.rootState, getInitialState());

    // Then override form with existing filters
    return {
      ...stateWithDefaultValue,
      ActiveForm: {
        ...stateWithDefaultValue.ActiveForm,
        PageTypeID: CMSPagesFilters ? CMSPagesFilters.PageTypeID : state.ActiveForm.PageTypeID,
        IncludePastEvents: CMSPagesFilters ? CMSPagesFilters.IncludePastEvents : state.ActiveForm.IncludePastEvents,
        IncludeExpiredBlogArticles: CMSPagesFilters ? CMSPagesFilters.IncludeExpiredBlogArticles : state.ActiveForm.IncludeExpiredBlogArticles,
        EventCategoryID: (CMSPagesFilters ? CMSPagesFilters.EventCategoryID : state.ActiveForm.EventCategoryID) || 0,
        EventStartDate: CMSPagesFilters ? CMSPagesFilters.EventStartDate : state.ActiveForm.EventStartDate,
      },
      showAdvancedFilter: false,
      pages: CMSPages,
      // On initializing this modal, filter should always be valid here because
      // we are grabing data from `cacheOne.CMSPagesFilters`, and that field will
      // only contain valid data for pages filters
      filterInvalid: false,
    };
  } else if (isActionType(act, ResetPageTypesModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default PageTypeModalReducer;