import * as React from 'react';
import {bindActionCreators} from 'redux';
import {RouteComponentProps, withRouter} from "react-router";
import RosterComponent from './Roster';
import Type from './Type';
import Classes from './Classes';
import Confirmation from './Confirmation';
import {actionCreators} from '../../../../../../store/Events/Event/Register/Participant/Main/actions';
import {
  actionCreators as cacheFourActionCreators,
} from '../../../../../../store/CacheFourEventsParticipants/actions';
import {
  actionCreators as cacheThreeActionCreators,
} from '../../../../../../store/CacheThreeEvents/actions';
import {LoadingAll} from '../../../../../Elements';
import { ApplicationState } from '../../../../../../store';
import EndUserCacheManager from '../../../../../../utils/cacheManagers/endUserCacheManager';
import { isPathnameMatchingRoute } from '../../../../../../utils/urlHelper';
import { END_USER_EVENT_REGISTRATION_PARTICIPANT_PATH } from '../../../../../../routes';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isCacheFourEventsParticipantsPopulated} from '../../../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--event';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string, ya: string, pId: string, pName: string}>
>;

@(withRouter as any)
class EventRegisterParticipant extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
  private nextLocation;

  componentDidMount() {
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheFourEventsParticipants({
          props: this.props,
          isStateNavigated,
        });
      }
    );
    this.configRouter();
  }

  componentWillReceiveProps(nextProps) {
    // We need to call the loaders on props change, so that redirection can happen
    // on logout.
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheFourEventsParticipants({
          props: nextProps,
          isStateNavigated,
        });
      },
    );
  }

  configRouter() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  public componentWillUnmount() {
    const {cacheFourEventsParticipants} = this.props;

    // TODO: Later, make it consistent with admin module:
    // use `clearCacheBelowThreeEvents` to clear both form and caches, and always init on mount
    this.props.actions.reset();
    // if we are not navigating to EventRegistrationParticipant page, clear cache
    if (
      this.nextLocation &&
      isPathnameMatchingRoute(this.nextLocation.pathname, END_USER_EVENT_REGISTRATION_PARTICIPANT_PATH)
    ) {
      if (isCacheFourEventsParticipantsPopulated(cacheFourEventsParticipants)) {
        this.props.actions.clearCache();
      }
    } else {
      this.props.actions.clearCacheBelowThreeEvents();
    }

    this.resetRouteLeaveHook();
  }

  public render() {
    const {
      eventRegisterParticipants: {selectedPage}, apiLoading, params, apiSaving, inert,
      cacheTwoEvents, cacheThreeEvents, cacheFourEventsParticipants, options, actions
    } = this.props;
    if (!isCacheFourEventsParticipantsPopulated(cacheFourEventsParticipants) || !options?.Group) return <LoadingAll/>;

    if (selectedPage === 'roster') {
      return <RosterComponent
        inert={inert}
        Group={options.Group}
        cacheTwoEvents={cacheTwoEvents}
        cacheThreeEvents={cacheThreeEvents}
        cacheFourEventsParticipants={cacheFourEventsParticipants}
        cacheFourActions={actions}
      />;
    } else if (selectedPage === 'type') {
      return <Type
        inert={inert}
        cacheTwoEvents={cacheTwoEvents}
        cacheThreeEvents={cacheThreeEvents}
        cacheFourEventsParticipants={cacheFourEventsParticipants}
        participantId={params.pId}
        apiLoading={apiLoading}
      />;
    } else if (selectedPage === 'classes') {
      return <Classes
        inert={inert}
        cacheTwoEvents={cacheTwoEvents}
        cacheThreeEvents={cacheThreeEvents}
        cacheFourEventsParticipants={cacheFourEventsParticipants}
        apiLoading={apiLoading}
      />;
    } else if (selectedPage === 'confirmation') {
      return <Confirmation
        inert={inert}
        cacheTwoEvents={cacheTwoEvents}
        cacheThreeEvents={cacheThreeEvents}
        cacheFourEventsParticipants={cacheFourEventsParticipants}
        apiSaving={apiSaving}
      />;
    }
    return null;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    eventRegisterParticipants: state.events.event.register.participant.main,
    options: state.cacheZero.options,
    cacheOne: state.cacheOne,
    cacheTwoEvents: state.cacheTwoEvents,
    cacheThreeEvents: state.cacheThreeEvents,
    cacheFourEventsParticipants: state.cacheFourEventsParticipants,
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...cacheFourActionCreators,
  ...cacheThreeActionCreators,
}, dispatch)});
const ConnectedEventRegisterParticipant = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(EventRegisterParticipant);

export default ConnectedEventRegisterParticipant;
