import * as React from 'react';
import {Button, Column, DatePicker, Row} from '../../../../Elements';
import {bindActionCreators} from "redux";
import {actionCreators} from "../../../../../store/Settings/PrevOrders/AuditLog/actions";
import {ApplicationState} from "../../../../../store";
import {checkPermission} from "../../../../../utils/permissionHelper";
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { makeCurrentModalSelector } from '../../../../../store/App';
import { ModalTypes } from '../../../../../utils/modalHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface Props {
  inModal?: boolean;
}

class ExportLogs extends React.Component<Props,{}> {
  props: Props & ConnectedProps;

  onExport = () => {
    const {cacheZero: {options}, actions} = this.props;
    checkPermission(
      () => actions.apiSubmitForm(),
      options!.GeneralPermissions!.hasAuditLog,
      options!.GeneralPermissions!.hasAuditLogReason
    );
  };

  render() {
    const {auditLog: {ActiveForm, ValidationRules}, apiLoading, currentModal, actions} = this.props;

    const isInModal = currentModal && currentModal.type === ModalTypes.PAYMENT_TOOLS;
    return (
      <Row verticalAlignment="middle">
        <Column expand>
          <DatePicker
            label="Start Date"
            disabled={apiLoading > 0}
            value={ActiveForm.StartDate}
            validationRules={ValidationRules.StartDate}
            onSelect={actions.updateValue}
            onChangeRaw={actions.simpleUpdate}
            /*
            popperPlacement={!isInModal ? undefined : 'bottom-end'}
            popperModifiers={!isInModal ? undefined : {
              flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
              },
              preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
              }
            }}
            */
            hideError
          />
        </Column>
        <Column>
          <Button disabled={apiLoading > 0} color="white" style={{ marginBottom: '10px' }} onClick={this.onExport}>
            EXPORT
          </Button>
        </Column>
      </Row>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const currentModalSelector = makeCurrentModalSelector();
  return {
    cacheZero: state.cacheZero,
    apiLoading: state.app.apiLoading,
    auditLog: state.settings.prevOrders.auditLog,
    currentModal: currentModalSelector(state),

  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators
}, dispatch)});

const ConnectedExportLogs = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ExportLogs);

export default ConnectedExportLogs;
