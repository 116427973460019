import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import { MAX_LENGTH } from "../../../../constants/messages/generic";
import * as GENERIC_M from "../../../../constants/messages/generic";
import { ApplicationState } from "../../../../store";
import { CMSSocial, SocialType } from "../../../../models/api/adminCMSCacheOne";
import { SelectValidator, Validator } from "../../../../utils/validator/models";


export interface IValidator {
  // Contact Section
  ContactName: Validator;
  Phone: Validator;
  Fax: Validator;
  Email: Validator;
  Address: Validator;
  Address2: Validator;
  StateID: SelectValidator;
  City: Validator;
  Zip: Validator;

  // Donate Section
  DonateURL: Validator;
  DonateName: Validator;
  IsDonateNewWindow: Validator;

  // Join Section
  JoinURL: Validator;
  JoinName: Validator;
  IsJoinNewWindow: Validator;

  // Calendar Section
  IncludeAllCategoriesInCalendar: Validator;
  CalendarPrimaryCategoryID: SelectValidator;
  IncludeDistrictsInPrimaryCalendar: Validator;
  ShowCalendarOnHomepage: Validator;
  FeaturedCalendarCodeHTML: Validator;

  // Social Section will be generated dynamically

  SEOTitle: Validator;
  SEODescription: Validator;
  SEOKeywords: Validator;
  GoogleAnalytics: Validator;
  MapInFooterHTML: Validator;
  SocialWidgetHTML: Validator;
}

const socialMediaKey = {
  SocialTypeID: true,
  SocialUser: true,
};

export const getSocialTypeValidationRule = (key: string) => {
  return {
    key,
    defaultValue: (rootState) => {
      const list = getSocialTypesValue(rootState);
      return list.length > 0 ? list[0].ID : 1;
    },
    isRequired: () => false,
    options: {
      values: (rootState) => [ ...getSocialTypesValue(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
    },
    validatejs: {
      [key]: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        numericality : {
          notGreaterThan: '^' + GENERIC_M.REQUIRED,
          greaterThan: 0
        }
      }
    }
  };
};

export const getSocialUserValidationRule = (key: string, socialMedia: CMSSocial) => {
  if (socialMedia.SocialTypeID === 15) {
    return {
      key,
      isRequired: () => false,
      validatejs: {
        [key]: {
          url: {message: '^' + GENERIC_M.INVALID_FULL_URL},
          presence: {message: '^' + GENERIC_M.REQUIRED},
        },
      },
    };
  } else {
    return {
      key,
      isRequired: () => false,
      validatejs: {
        [key]: {
          presence: {message: '^' + GENERIC_M.REQUIRED},
        },
      },
    };
  }
};

export const getSocialMediasDefinition = (rootState: ApplicationState) => {
  const definition = {};

  if (rootState.adminCMSSite && rootState.adminCMSSite.cacheOne && rootState.adminCMSSite.cacheOne.CMSSiteSocials) {
    rootState.adminCMSSite.cacheOne.CMSSiteSocials.forEach((ss, index) => {
      for (let key of Object.keys(ss)) {
        if (socialMediaKey[key]) {
          const ruleKey = `${key}${index}`;

          switch (key) {
            case 'SocialTypeID':
              definition[ruleKey] = getSocialTypeValidationRule(ruleKey);
              break;
            case 'SocialUser':
              definition[ruleKey] = getSocialUserValidationRule(ruleKey, ss);
              break;
          }
        }
      }
    });
  }

  return definition;
};

const getValues = (rootState: ApplicationState, key) => {
  const opts = rootState.cacheZero.options;
  if (opts) return opts[key];
  return [];
};

const getSiteEventCategoriesValue = (rootState: ApplicationState) => {
  const eventCategories = rootState.adminCMSSite.cacheOne.SiteEventCategories;

  if (eventCategories) return eventCategories;
  return [];
};

export const getSocialTypesValue = (rootState: ApplicationState): SocialType[] => {
  if (!rootState.adminCMSSite.cacheOne.SocialTypes) return [];
  const socialTypes = rootState.adminCMSSite.cacheOne.SocialTypes.filter((s) => !s.Inactive);

  if (socialTypes) return socialTypes;
  return [];
};

export const FormDefinition: IValidator = {
  ContactName: {
    key: 'ContactName',
    isRequired: () => true,
    validatejs: {
      ContactName: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 200,
          tooLong: GENERIC_M.MAX_LENGTH(200),
        },
      }
    },
  },
  Phone: {
    key: 'Phone',
    isRequired: () => true,
    validatejs: {
      Phone: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 50,
          tooLong: MAX_LENGTH(50),
        },
      },
    },
  },
  Fax: {
    key: 'Fax',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      Fax: {
        length: {
          maximum: 50,
          tooLong: MAX_LENGTH(50),
        },
      },
    },
  },
  Email: {
    key: 'Email',
    isRequired: () => true,
    validatejs: {
      Email: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        email: {message: '^' + GENERIC_M.INVALID},
        length: {
          maximum: 50,
          tooLong: MAX_LENGTH(50),
        }
      }
    }
  },
  Address: {
    key: 'Address',
    isRequired: () => true,
    validatejs: {
      Address: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 200,
          tooLong: MAX_LENGTH(200),
        }
      }
    }
  },
  Address2: {
    key: 'Address2',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      Address2: {
        length: {
          maximum: 200,
          tooLong: MAX_LENGTH(200),
        }
      }
    }
  },
  StateID: {
    key: 'StateID',
    defaultValue: (rootState) => {
      if (!rootState.session.SystemSettings) {
        return undefined;
      }
      return rootState.session.SystemSettings.StateID;
    },
    isRequired: () => true,
    options: {
      values: (rootState) => [{ Name: '', ID: 0 }, ...getValues(rootState, 'StateOptions')],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: 0,
    },
    validatejs: {
      StateID: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0
        }
      }
    }
  },
  City: {
    key: 'City',
    isRequired: () => true,
    validatejs: {
      City: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 50,
          tooLong: MAX_LENGTH(50),
        }
      }
    }
  },
  Zip: {
    key: 'Zip',
    isRequired: () => true,
    validatejs: {
      Zip: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 50,
          tooLong: MAX_LENGTH(50),
        },
      },
    },
  },
  DonateURL: {
    key: 'DonateURL',
    isRequired: () => true,
    validatejs: {
      DonateURL: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 1024,
          tooLong: MAX_LENGTH(1024),
        },
      },
    },
  },
  DonateName: {
    key: 'DonateName',
    isRequired: () => true,
    validatejs: {
      DonateName: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 1024,
          tooLong: MAX_LENGTH(1024),
        },
      }
    },
  },
  IsDonateNewWindow: {
    key: 'IsDonateNewWindow',
    defaultValue: () => false,
  },
  JoinURL: {
    key: 'JoinURL',
    isRequired: () => true,
    validatejs: {
      JoinURL: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 1024,
          tooLong: MAX_LENGTH(1024),
        }
      }
    },
  },
  JoinName: {
    key: 'JoinName',
    isRequired: () => true,
    validatejs: {
      JoinName: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 1024,
          tooLong: MAX_LENGTH(1024),
        },
      }
    },
  },
  IsJoinNewWindow: {
    key: 'IsJoinNewWindow',
    defaultValue: () => false,
  },
  IncludeAllCategoriesInCalendar: {
    key: 'IncludeAllCategoriesInCalendar',
    defaultValue: () => false,
  },
  CalendarPrimaryCategoryID: {
    key: 'CalendarPrimaryCategoryID',
    defaultValue: () => 0,
    isRequired: (rootState) => {
      return !rootState.adminCMSSite.settings.general.ActiveForm.IncludeAllCategoriesInCalendar;
    },
    options: {
      values: (rootState) => [{ NameAndIsHidden: '', ID: 0 }, ...getSiteEventCategoriesValue(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'NameAndIsHidden',
      emptyValue: 0,
    },
    validatejs: {
      CalendarPrimaryCategoryID: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        }
      }
    }
  },
  IncludeDistrictsInPrimaryCalendar: {
    key: 'IncludeDistrictsInPrimaryCalendar',
    defaultValue: () => false,
  },
  ShowCalendarOnHomepage: {
    key: 'ShowCalendarOnHomepage',
    defaultValue: () => false,
  },
  FeaturedCalendarCodeHTML: {
    key: 'FeaturedCalendarCodeHTML',
    isRequired: () => false,
    validatejs: {
      FeaturedCalendarCodeHTML: {
        length: {
          maximum: 2048,
          tooLong: MAX_LENGTH(2048),
        },
      }
    },
  },
  SEOTitle: {
    key: 'SEOTitle',
    isRequired: () => true,
    validatejs: {
      SEOTitle: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 255,
          tooLong: MAX_LENGTH(255),
        },
      }
    },
  },
  SEODescription: {
    key: 'SEODescription',
    isRequired: () => true,
    validatejs: {
      SEODescription: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 2048,
          tooLong: MAX_LENGTH(2048),
        },
      }
    },
  },
  SEOKeywords: {
    key: 'SEOKeywords',
    isRequired: () => true,
    validatejs: {
      SEOKeywords: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 2048,
          tooLong: MAX_LENGTH(2048),
        },
      }
    },
  },
  GoogleAnalytics: {
    key: 'GoogleAnalytics',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      GoogleAnalytics: {
        length: {
          maximum: 2048,
          tooLong: MAX_LENGTH(2048),
        },
      }
    },
  },
  MapInFooterHTML: {
    key: 'MapInFooterHTML',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      MapInFooterHTML: {
        length: {
          maximum: 2048,
          tooLong: MAX_LENGTH(2048),
        },
      }
    },
  },
  SocialWidgetHTML: {
    key: 'SocialWidgetHTML',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      SocialWidgetHTML: {
        length: {
          maximum: 2048,
          tooLong: MAX_LENGTH(2048),
        },
      }
    },
  },
};