
import { SUFFIX, UPDATE_EVENT_CLASS_FORM_SUFFIX, CheckTimeBlock, CheckDay, CheckParticipantType, ToggleParticipantTypes, UpdateSelectedClassType, RemoveClassType, ResetToSingleClassType } from "./actions";
import { apiValidationReducerCreator, shouldSkipUpdateForm } from "../../../../store/Validation/reducerCreator";
import { createSelector } from "reselect";
import { IAdminEventClassValidator, AdminEventClassFormDefinition } from "./validation";
import { ApplicationState } from "../../../../store";
import { setDefaults } from "../../../../utils/validator";
import { API_UPD } from "../../../../constants/request";
import { ClearAdminEventsCacheThreeClass } from "../../CacheThreeClass/actions";
import { ClearAdminEventsCacheBelowOne } from "../../CacheOne/actions";
import { ClearAdminEventsCacheBelowTwoEvent, ClearAdminEventsCacheTwoEvent } from "../../CacheTwoEvent/actions";
import { AdminEventsEventClass } from "../../../../models/api/adminEventsCacheThreeClass";
import { GLAccountsSelector, sortByName } from "../../../CacheZero";
import { GLAccount, ClassType } from "../../../../models/api/options";
import { adminEventParticipantTypesFromCacheSelector } from "../../EventTypes/EventType/Form";
import { ParticipantType } from "../../../../models/api/adminEventsCacheOne";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../utils/StrongActions";
import { allClassTypesSelector } from "../../Events/Event/Classes";
import { WithRootState } from "../../../Validation/actionCreator";

export interface AdminEventClassActiveForm {
  IDi?: number;
  IsRegisterUnderIndividuals?: boolean;
  IsOption?: boolean;
  IsCombo?: boolean;
  ComboName?: string;
  ClassCode?: string;
  Location?: string;
  TimeBlocks?: number[];
  IsEntireEvent?: boolean;
  Days?: number[];
  Amount?: number;
  IsAmountActual?: boolean;
  UpfrontPaymentID?: number;
  UpfrontPaymentSpecific?: number;
  GLAccountID?: number;
  MaxParticipants?: number;
  MinimumAge?: number;
  AllowSelfRegistration?: boolean;
  InstructorName?: string;
  InstructorInitials?: string;
  ShowYouth?: boolean;
  ShowAdults?: boolean;
  ParticipantTypes?: number[];
  SelectedClassTypeIds?: number[];
  TS?: number;
}

export interface AdminEventClassFormState {
  ActiveForm: AdminEventClassActiveForm;
  ValidationRules: IAdminEventClassValidator;
  SubmitErrorMessage?: string;
}

const getInitialState = () => {
  return {
    ActiveForm: {
      ParticipantTypes: [] as number[],
    },
    ValidationRules: {...AdminEventClassFormDefinition},
  };
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX);


const setClass = (state: AdminEventClassFormState, NumberOfTimeBlocks: number, adminClass?: AdminEventsEventClass) => {
  if (!adminClass) return state;
  const newState: AdminEventClassFormState = {
    ...state,
    ActiveForm: {
      IDi: adminClass.IDi,
      AllowSelfRegistration: adminClass.AllowSelfRegistration,
      Amount: adminClass.Amount,
      ClassCode: adminClass.ClassCode,
      ComboName: adminClass.ComboName,
      Days: adminClass.Days,
      GLAccountID: adminClass.GLAccountID,
      InstructorName: adminClass.InstructorName,
      InstructorInitials: adminClass.InstructorInitials,
      IsAmountActual: adminClass.IsAmountActual,
      IsCombo: adminClass.IsCombo,
      IsOption: adminClass.IsOption,
      IsEntireEvent: adminClass.IsEntireEvent,
      IsRegisterUnderIndividuals: adminClass.IsRegisterUnderIndividuals,
      Location: adminClass.Location,
      MaxParticipants: adminClass.MaxParticipants,
      MinimumAge: adminClass.MinimumAge,
      ParticipantTypes: adminClass.ParticipantTypes || [],
      ShowAdults: adminClass.ShowAdults,
      ShowYouth: adminClass.ShowYouth,
      TS: adminClass.TS,
      TimeBlocks: adminClass.TimeBlocks.filter((tb, index) => {
        return index < NumberOfTimeBlocks;
      }),
      UpfrontPaymentID: adminClass.UpfrontPaymentID,
      UpfrontPaymentSpecific: adminClass.UpfrontPaymentSpecific,
      SelectedClassTypeIds: adminClass.IsCombo ? (adminClass.ComboClasses ? [...adminClass.ComboClasses.map((child) => child.ClassTypeIDi)] : []) : [adminClass.ClassTypeIDi],
    },
    ValidationRules: {...AdminEventClassFormDefinition},
  };

  return newState;
};

const GLAccountIDSelector = (state: ApplicationState) => state.adminEvents.classes.form.ActiveForm.GLAccountID;
export const makeSelectedGLAccountSelector = () => {
  return createSelector(
    [GLAccountsSelector, GLAccountIDSelector],
    (accounts: GLAccount[], GLAccountID: number) => {
      return accounts.find((a) => a.ID === GLAccountID);
    }
  );
};

const selectedClassTypeIdsSelector = (state: ApplicationState) => state.adminEvents.classes.form.ActiveForm.SelectedClassTypeIds;
export const makeSelectedClassTypesSelector = () => {
  return createSelector(
    [selectedClassTypeIdsSelector, allClassTypesSelector],
    (classTypeIds: number[], classTypes: ClassType[]) => {
      if (!classTypeIds) return null;
      return classTypeIds.map((id) => {
        return classTypes.find((o) => o.IDi === id) as any;
      }).filter((ct) => !!ct).sort(sortByName);
    }
  );
};

export const makeYouthParticipantTypesSelector = () => {
  return createSelector(
    [adminEventParticipantTypesFromCacheSelector],
    (participantTypes: ParticipantType[]) => {
      const youth = participantTypes.filter((pt) => pt.IsYouth);

      return [...youth.sort(sortByName)];
    }
  );
};
export const makeAdultsParticipantTypesSelector = () => {
  return createSelector(
    [adminEventParticipantTypesFromCacheSelector],
    (participantTypes: ParticipantType[]) => {
      const adults = participantTypes.filter((pt) => !pt.IsYouth);

      return [...adults.sort(sortByName)];
    }
  );
};

const AdminEventClassFormReducer: Reducer<AdminEventClassFormState> = (s, act: WithRootState<AnyAction>) => {

  const state = {
    ...getInitialState(),
    ...checkApiValidation(s, act),
  };

  if (act.type.includes(API_UPD) && act.type.includes(UPDATE_EVENT_CLASS_FORM_SUFFIX)) {
    if (shouldSkipUpdateForm(act)) return state;

    // get initial state
    let newState = getInitialState() as any;

    // set default values
    newState = setDefaults(act.rootState, newState, state);
    
    const rootState = act.rootState;
    const youthParticipantTypesSelector = makeYouthParticipantTypesSelector();
    const adultsParticipantTypesSelector = makeAdultsParticipantTypesSelector();

    const youthParticipantTypes = youthParticipantTypesSelector(rootState);
    const adultsParticipantTypes = adultsParticipantTypesSelector(rootState);

    newState.ActiveForm = {
      ...newState.ActiveForm,
      ParticipantTypes: [...youthParticipantTypes.map((pt) => pt.ID), ...adultsParticipantTypes.map((pt) => pt.ID)],
    };

    // load from cache two if set
    if (rootState.adminEvents.cacheThreeClass && rootState.adminEvents.cacheThreeClass.EventsEventClass && rootState.adminEvents.cacheTwoEvent.EventsEvent) {
      const adminClass = rootState.adminEvents.cacheThreeClass.EventsEventClass;
      return setClass(newState as any, rootState.adminEvents.cacheTwoEvent.EventsEvent.TimeBlocks_Settings.NumberOfTimeBlocks, adminClass);
    }
    
    return newState;
  } else if (isActionType(act, UpdateSelectedClassType)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedClassTypeIds: act.classTypeIds,
      },
      ValidationRules: {
        ...state.ValidationRules,
        SelectedClassTypeIds: AdminEventClassFormDefinition.SelectedClassTypeIds,
      },
    };
  } else if (isActionType(act, RemoveClassType)) {
    const newState = {...state};

    const index = newState.ActiveForm.SelectedClassTypeIds.findIndex((id) => id === act.id);

    if (index !== -1) {
      newState.ActiveForm.SelectedClassTypeIds.splice(index, 1);
    }

    return newState;
  } else if (isActionType(act, CheckTimeBlock)) {
    let newTimeBlocks;
    if (act.checked) {
      newTimeBlocks = [...(state.ActiveForm.TimeBlocks || []), act.id];
    } else {
      const index = state.ActiveForm.TimeBlocks.findIndex((id) => id === act.id);
      newTimeBlocks = [...state.ActiveForm.TimeBlocks];
      newTimeBlocks.splice(index, 1);
    }
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        TimeBlocks: newTimeBlocks,
      },
    };
  } else if (isActionType(act, CheckDay)) {
    let newDays;
    if (act.checked) {
      newDays = [...(state.ActiveForm.Days || []), act.id];
    } else {
      const index = state.ActiveForm.Days.findIndex((id) => id === act.id);
      newDays = [...state.ActiveForm.Days];
      newDays.splice(index, 1);
    }
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        Days: newDays,
      },
    };
  } else if (isActionType(act, ToggleParticipantTypes)) {
    const newState = {...state};
    const targetParticipantTypes = act.allParticipantTypes.filter((pt) => act.isYouth ? pt.IsYouth : !pt.IsYouth);

    if (act.select) {
      targetParticipantTypes.forEach((pt) => {
        const existed = state.ActiveForm.ParticipantTypes.find((id) => id === pt.ID);

        if (!existed) newState.ActiveForm.ParticipantTypes.push(pt.ID);
      });
    } else {
      targetParticipantTypes.forEach((pt) => {
        const index = state.ActiveForm.ParticipantTypes.findIndex((id) => id === pt.ID);

        if (index !== -1) newState.ActiveForm.ParticipantTypes.splice(index, 1);
      });
    }

    return newState;
  } else if (isActionType(act, CheckParticipantType)) {
    let newParticipantTypes;
    if (act.checked) {
      newParticipantTypes = [...(state.ActiveForm.ParticipantTypes || []), act.id];
    } else {
      const index = state.ActiveForm.ParticipantTypes.findIndex((id) => id === act.id);
      newParticipantTypes = [...state.ActiveForm.ParticipantTypes];
      newParticipantTypes.splice(index, 1);
    }
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        ParticipantTypes: newParticipantTypes,
      },
    };
  } else if (isActionType(act, ResetToSingleClassType)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedClassTypeIds: [act.classIdToKeep],
      }
    };
  } else if (
    isActionType(act, ClearAdminEventsCacheThreeClass) ||
    isActionType(act, ClearAdminEventsCacheBelowOne) ||
    isActionType(act, ClearAdminEventsCacheBelowTwoEvent) ||
    isActionType(act, ClearAdminEventsCacheTwoEvent)
  ) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default AdminEventClassFormReducer;