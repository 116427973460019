import * as React from 'react';
import { ContextMenu, ContextMenuItem } from '../../../../../Elements';
import { Props as ContextMenuProps } from '../../../../../Elements/ContextMenu/index';
import { LinkIcon, PublicWebsiteIcon, CopyIcon, CloseIcon } from '../../../../../../components/Icons';
import { generateDOMId } from '../../../../../../utils/cypressHelper';

interface Props {
  onVisitPage: (e) => void;
  onCopyLink: (e) => void
  onDuplicate: (e) => void;
  onDelete: (e) => void;
  renderIds?: boolean;
}

const ContextMenuComponent: React.SFC<ContextMenuProps & Props> = (props: ContextMenuProps & Props): React.ReactElement<ContextMenuProps & Props> => (
  <ContextMenu
    {...props}
    consistentInMobile
    title=""
  >
    <ContextMenuItem admin onClick={props.onVisitPage}><PublicWebsiteIcon width={24} height={24} />Visit Page</ContextMenuItem>
    <ContextMenuItem admin onClick={props.onCopyLink}><LinkIcon />Copy link</ContextMenuItem>
    <ContextMenuItem id={props.renderIds? generateDOMId("duplicate-page-btn") : undefined} admin onClick={props.onDuplicate}><CopyIcon />Duplicate page</ContextMenuItem>
    <ContextMenuItem admin onClick={props.onDelete}>
      <div style={{
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '16px',
      }}><CloseIcon /></div>
      Delete
    </ContextMenuItem>
  </ContextMenu>
);

export default ContextMenuComponent;
