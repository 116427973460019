import * as React from 'react';
import {Card, CardTitle, Days, Row, View} from '../../../../../Elements';
import {RouteComponentProps} from "react-router";
import '../../../../../../styles/pages/facilities/trip/reservation/type/compact.scss';
import {
  FacilitiesAvailabilitiesDate,
  FacilitiesAvailability,
  FacilityLocation_FacilityType
} from "../../../../../../models/api/cacheTwoFacilties";
import Moment from 'moment';
import * as Range from 'moment-range';
import {FacilityTrip} from "../../../../../../models/api/cacheThreeFacilities";
import { ArrowIcon } from '../../../../../Icons';

const namespace = (): string => 'pages--facilities--trip--reservation--type--compact';
const moment = (Range as any).extendMoment(Moment);

interface Props {
  trip: FacilityTrip;
  FacilitiesAvailabilities: Array<FacilitiesAvailability>;
  type: FacilityLocation_FacilityType;
  availabilities: Array<FacilitiesAvailabilitiesDate>;
  onClick: (avail: FacilitiesAvailability, trip: FacilityTrip, type: FacilityLocation_FacilityType) => void;
}

type ConnectedProps = RouteComponentProps<{}, {}>;

const Compact: React.SFC<Props> = ({ trip, FacilitiesAvailabilities, type, availabilities, onClick }: Props & ConnectedProps):React.ReactElement<Props> => {

  const range = moment.range(trip.StartDateTime, trip.EndDateTime);

  return <Card template="mobile-stretch">
    {FacilitiesAvailabilities.map((avail) => {
      const onClickFac = () => {
        onClick(avail, trip, type);
      };
      return (
        <View layout="vertical" onClick={onClickFac} marginBottom={24} mobileMarginBottom={16}>
          <Row>
            <CardTitle className={`${namespace()}--title`} size={18} marginBottom={8}>{avail.Name}</CardTitle>
              <div className={`${namespace()}--arrow`}>
              <ArrowIcon />
            </div>
          </Row>
          <Days
            avail={avail}
            range={range}
            availabilities={availabilities}
          />
        </View>
      );
    })}
  </Card>;
};

export default Compact;
