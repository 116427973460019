import { GetPageMenuItemParams } from "../../../constants/urls";
import { CLEAR_CACHE_PREFIX, createRequestActions } from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { ADMIN_CMS_MODULE_SUFFIX } from "../CacheOne/actions";
import { HideDrawer, SilentCancelAllAction } from '../../../store/App/actions';
import { SaveState } from "../../../store/Rollback/actions";
import { UPDATE_CMS_PAGE_MENU_FORM_SUFFIX } from "../Menus/SiteMenuItem/Form/actions";
import { typeName, Action } from "../../../utils/StrongActions";


export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__CACHE_TWO_PAGE_MENUS';
export const SUFFIX_SAVE = ADMIN_CMS_MODULE_SUFFIX + '__CACHE_TWO_PAGE_MENUS_SAVING';

export const GetPageMenuItem = createRequestActions(SUFFIX + UPDATE_CMS_PAGE_MENU_FORM_SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminCMSCacheTwoPageMenuItem extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  getPageMenuItem: (params: GetPageMenuItemParams): ActionCreator => dispatch => {
    dispatch(GetPageMenuItem.request(params));
  },
  refreshPageMenuItem: (params: GetPageMenuItemParams): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    dispatch(GetPageMenuItem.request(params));
  },
  clearAdminCMSCacheTwoPageMenuItem: (save?: boolean): ActionCreator => dispatch => {
    dispatch(new ClearAdminCMSCacheTwoPageMenuItem());
    if (save) dispatch(new SaveState());
  },
};