// Facility Types
export const FACILITY_TYPE = 'facility type';
export const FACILITY_TYPES = 'facility types';
export const FACILITIES = 'facilities';
export const FACILITY = 'facility';
export const FACILITY_TYPE_NOT_ADDED_DETAIL = 'Add a facility type for each group of similar facilities. For example, you could add a "Cabins" type if all cabins have the same the same check-in/out times.';
export const INVALID_FACILITY_TYPE = 'Facility type has been deleted. Please restore from list and try again.';
export const INVALID_FACILITY = 'Facility has been deleted. Please restore from list and try again.';
export const PHOTO = 'photo';

// Blackout Date
export const BLACKOUT_DATE = 'facility blackout date';
export const INVALID_BLACKOUT_DATE = 'Blackout date has been deleted.';

// Reports
export const FACILITY_REPORTS = 'facility reports';

// Settings
export const FACILITY_SETTINGS = 'Facility location settings';

// Utils functions
export const ENTITY_IMPORTED = (name: string, plural?: boolean) => `${name} ${plural ? 'were' : 'was'} imported`;
export const EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE = (action: string) => `Please either wait for the current upload(s) to finish or cancel before ${action}.`;