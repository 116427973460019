import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/layouts/filter/index.scss';
import * as React from 'react';

export const namespace = (): string => 'layouts--filter';


interface Props {
  className?: string;
}

interface State {
};


class Filter extends React.Component<Props, State> {

  public render() {
    return (
      <div className={`${mergeClassNames(namespace(), this.props)}`}>
        {this.props.children}
      </div>
    );
  }
}

export default Filter;