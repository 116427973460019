import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/initials/index.scss';
import { createInitials } from '../../../utils';
import { FacilitiesIcon } from '../../Icons';

const namespace = (): string => 'elements--initials';

interface Props {
  className?: string;
  color?: string;
  width?: number;
  height?: number;
  name?: string;
  facility?: boolean;
  skeleton?: boolean;
  opacity?: number;
  borderRadius?: string;
}

const ensureColorStyle = (color?: string) => {
  let result = color;
  if (!!color && !color.startsWith("#")) {
    result = `#${color}`;
  }

  return result;
};

const Initials: React.SFC<Props> = ({ color, width, height, name, facility, borderRadius, skeleton, opacity, ...props }: Props): React.ReactElement<Props> => {
  const style: React.CSSProperties = {
    backgroundColor: ensureColorStyle(color),
    opacity: opacity,
  };
  if (width || width === 0) style.width = `${width}px`;
  if (height || height === 0) style.height = `${height}px`;
  if (borderRadius) style.borderRadius = borderRadius;

  return (
    <div className={mergeClassNames(namespace(), props)} style={style}>
      {facility ?
        ( !skeleton ? <FacilitiesIcon width={54} height={54} className={`${namespace()}--icon`}/> : '' ) :
        null
      }
      {!facility && name ?
        <span className={`${namespace()}--text`}>{createInitials(name)}</span> :
        null
      }
    </div>
  );
};

export default Initials;
