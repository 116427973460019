import { createValidateActions, createApiValidateActions, createApiSubmitActions, createUpdateValueMethod, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra } from '../../../../../store/Validation/actionCreator';
import { ApplicationState, ActionCreator } from '../../../../../store';
import { scrollModalToTop } from '../../../../../utils/helpers/adminCMSPageHelper';
import { getSubmitGLAccountBody } from '../../../../../constants/urls';
import { ToggleModalSaving } from '../../../../../store/App/actions';
import { ADMIN_SETTINGS_SUFFIX } from '../../../../../store/AdminSettings/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { ModalTypes, isModalOpened } from '../../../../../utils/modalHelper';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../../../../utils/suffix';
import { ClearAdminSettingsCacheTwoGLAccount } from '../../../CacheTwoGLAccount/actions';

export const UPDATE_GL_ACCOUNT_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__GL_ACCOUNT';
export const SUFFIX = ADMIN_SETTINGS_SUFFIX + '__GL_ACCOUNT_FORM' + UPDATE_GL_ACCOUNT_FORM_SUFFIX;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const AddGLAccountSubmitActions = createApiSubmitActions(SUFFIX, false);

export const GLAccountFormModalNamespace = (): string => 'pages--settings--modal--gl-account-form';


export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminSettings.glAccounts.glAccount.form),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  apiSubmitForm: (routes): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const isGLAccountFormModalOpened = isModalOpened(ModalTypes.GL_ACCOUNT_FORM);
    const valid = innerApiSubmitFormMethod(
      dispatch,
      AddGLAccountSubmitActions,
      (s: ApplicationState) => s.adminSettings.glAccounts.glAccount.form,
      undefined,
      undefined,
      isGLAccountFormModalOpened,
      true,
      undefined,
      undefined,
    );

    if (valid) {
      const submitFormBody = getSubmitGLAccountBody(state.adminSettings.glAccounts.glAccount.form.ActiveForm);
      if (isGLAccountFormModalOpened) {
        dispatch(appActionCreators.popModal(false, false, ModalTypes.GL_ACCOUNT_FORM) as any);
        dispatch(new ToggleModalSaving(true));
      }
      dispatch(
        AddGLAccountSubmitActions.request(
          submitFormBody,
          getCacheLevelExtra(
            true,
            !!state.adminSettings.cacheTwoGLAccount.GLAccount,
          ),
        )
      );

      if (isGLAccountFormModalOpened && !!getState().adminSettings.cacheTwoGLAccount.GLAccount) {
        dispatch(new ClearAdminSettingsCacheTwoGLAccount());
      }
      return true;
    } else {
      if (isGLAccountFormModalOpened) {
        scrollModalToTop(GLAccountFormModalNamespace());
      }
      return false;
    }
  },
};