import * as React from 'react';
import * as M from '../../../../../constants/messages/events';
import {
  Button, Text, Title, View, RosterCard, RosterCardTitle, RosterList, RosterItem, RosterListEmpty,
  AddButton, Alert, ContextMenu, ContextMenuItem, ContextMenuInfo, Media, ContentBlock
} from '../../../../Elements';
import CheckMark from '../../../../../images/pages/events/event/registration/checkmark.svg';
import {withRouter, RouteComponentProps} from "react-router";
import {navPush} from "../../../../../utils";
import {
  getEventRegisterNumbersUrl,
  getEventRegisterParticipantUrl,
  getEventRegistrationUrl
} from "../../../../../constants/urls";
import {
  EventRegistrationParticipant,
  EventInfoParticipantType,
  EventInfoPermissions
} from "../../../../../models/api/cacheThreeEvents";
import {standardCurrencyFormat} from "../../../../../utils/classesHelper";
import {determineAvailability} from "../../../../../utils/eventsHelper";
import {RED_THRESHOLD} from "../constants";
import {spaceTo_} from "../../../../../utils";
import '../../../../../styles/pages/events/event/registration/participants.scss';
import {checkPermission} from "../../../../../utils/permissionHelper";
import { generateDOMId } from '../../../../../utils/cypressHelper';

export const namespace = (): string => 'pages--events--event--registration--participants';

interface Props {
  EventInfoParticipantTypes?: Array<EventInfoParticipantType> | null;
  sortedParticipants?: any;
  participantTypeMap?: any;
  ParticipantAmountMinBalance: number | null;
  total: number;
  eventType: 'participant' | 'both';
  eventSpotsAvailable: number;
  MaxParticipants: number;
  showYouth: boolean;
  showAdult: boolean;
  EventInfoPermissions: EventInfoPermissions;
  onRestoreClick?: (ParticipantIDi: number, IsYouth: boolean, Name: string) => void;
  onReportInvoice: () => void;
  onReportParticipantRoster: () => void;
  onReportClassSchedule: () => void;
  onReportClassSchedulePerson: () => void;
  onReportRequirementsCompleted: () => void;
  onReportRequirementsCompletedPDF: () => void;
  onReportRequirementsScoutbook: () => void;
  onReportBlueCardsFront: () => void;
  onReportBlueCardsBack: () => void;
}
type ConnectedProps = RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string}>;

/*
@todo: put this button back
 <Alert
 actions={(
 <AlertActions>
 <Button flat textColor="white" icon={CartIcon}>Add to cart</Button>
 </AlertActions>
 )}
 >

 */



class Participants extends React.Component<Props, {}> {
  props: Props & ConnectedProps;

  onAddParticipant = () => {
    const {EventInfoPermissions} = this.props;
    if (!EventInfoPermissions) return;

    checkPermission(
      () => navPush(this.props.router, getEventRegisterParticipantUrl(this.props)),
      EventInfoPermissions.hasRegistrationNames,
      EventInfoPermissions.hasRegistrationNamesReason
    );
  };

  onAddSpots = () => {
    const {EventInfoPermissions} = this.props;
    if (!EventInfoPermissions) return;

    checkPermission(
      () => navPush(this.props.router, getEventRegisterNumbersUrl(this.props)),
      EventInfoPermissions.hasRegistrationNumbersChange,
      EventInfoPermissions.hasRegistrationNumbersChangeReason
    );
  };

  renderShowAvailableBoth = (participantType: EventInfoParticipantType, navParticipant) => {
    const remaining = participantType.NumSpotsPending - participantType.NumNamesPending;
    if (remaining === 0) {
      // All spots registered
      return (
        <View verticalAlignment="middle" mobileMarginTop={0}>
          <Media tabletAndGreater><Text icon={CheckMark} color="green" mobileSize={14}>All spots registered</Text></Media>
          <Media mobile><Text icon={CheckMark} color="green" mobileSize={14}>All registered</Text></Media>
        </View>
      );
    }
    return (
      <View verticalAlignment="middle" mobileMarginTop={0}>
        <Text weight="medium">{M.N_SPOTS_LEFT(remaining)}</Text>
        <AddButton green thick marginLeft={16} onClick={navParticipant}/>
      </View>
    );
  };

  renderShowAvailable = (available: number, navParticipant, participantLength) => (
    <View verticalAlignment="middle" mobileMarginTop={0}>
      <Media tablet desktop><Text color={available < RED_THRESHOLD ? 'red' : 'gray'} size={14}>
        {M.N_SPOTS_LEFT(available)}
      </Text></Media>
      <AddButton green thick marginLeft={16} onClick={navParticipant}/>
    </View>
  );

  renderControls = (eventType, navParticipant) => {
    if (eventType !== 'participant') {
      return <Button id={generateDOMId("add-participant-btn")} key="add" color="green" onClick={navParticipant}>ADD PARTICIPANT</Button>;
    }
    return [
      <Media mobileAndSmaller>
        <Button key="add" color="green" onClick={navParticipant} marginRight={16}>ADD PARTICIPANT</Button>
        <Button key="generate" color="white" contextMenu={(
          <ContextMenu title="Generate Reports" info={<ContextMenuInfo>Reports will not include any changes that are still in the cart</ContextMenuInfo>}>
            <ContextMenuItem onClick={this.props.onReportInvoice}>Invoice, PDF</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportParticipantRoster}>Participant Roster, PDF</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportClassSchedule}>Class Schedule, PDF</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportClassSchedulePerson}>Class Schedule by Person, PDF</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportRequirementsCompleted}>Class Schedule, Excel/CSV</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportRequirementsCompletedPDF}>Requirements Completed, PDF</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportRequirementsCompleted}>Requirements Completed, Excel/CSV</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportRequirementsScoutbook}>Requirements for Scoutbook, Excel/CSV</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportBlueCardsFront}>Blue Cards Front, PDF</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportBlueCardsBack}>Blue Cards Back, PDF</ContextMenuItem>
          </ContextMenu>
        )}>
          REPORTS
        </Button>
      </Media>,
      <Media tabletAndGreater>
        <Button key="generate" color="white" marginRight={16} contextMenu={(
          <ContextMenu title="Generate Reports" info={<ContextMenuInfo>Reports will not include any changes that are still in the cart</ContextMenuInfo>}>
            <ContextMenuItem onClick={this.props.onReportInvoice}>Invoice, PDF</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportParticipantRoster}>Participant Roster, PDF</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportClassSchedule}>Class Schedule, PDF</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportClassSchedulePerson}>Class Schedule by Person, PDF</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportRequirementsCompleted}>Class Schedule, Excel/CSV</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportRequirementsCompletedPDF}>Requirements Completed, PDF</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportRequirementsCompleted}>Requirements Completed, Excel/CSV</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportRequirementsScoutbook}>Requirements for Scoutbook, Excel/CSV</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportBlueCardsFront}>Blue Cards Front, PDF</ContextMenuItem>
            <ContextMenuItem onClick={this.props.onReportBlueCardsBack}>Blue Cards Back, PDF</ContextMenuItem>
          </ContextMenu>
        )}>
          REPORTS
        </Button>
        <Button key="add" color="green" onClick={navParticipant}>ADD PARTICIPANT</Button>
      </Media>
    ];
  };

  render() {
    const {
      eventType, total, router, ParticipantAmountMinBalance, sortedParticipants, participantTypeMap, eventSpotsAvailable,
      MaxParticipants, showYouth, showAdult, onRestoreClick, EventInfoPermissions, EventInfoParticipantTypes
    } = this.props;
    return (<ContentBlock className={`${namespace()}--roster-card`}>
      <Title
        className={`${namespace()}--title`}
        size={28} mobileSize={22} num={total}
        controls={this.renderControls(eventType, this.onAddParticipant)}
        controlsWrap={eventType === 'participant' ? true : false}
      >
        Participants
      </Title>
      {eventType === 'both' && ParticipantAmountMinBalance !== null && ParticipantAmountMinBalance > 0 && <Alert>
        {`Please add ${standardCurrencyFormat(ParticipantAmountMinBalance)} to your cart before adding participants.`}
      </Alert>}

      {!!EventInfoParticipantTypes && !!sortedParticipants && EventInfoParticipantTypes.map((pt) => {
        const participants: Array<EventRegistrationParticipant> = sortedParticipants[pt.ID];
        const participantType = participantTypeMap[pt.ID];
        const available: number | undefined = determineAvailability(eventSpotsAvailable, participantType.NumEventSpotsAvailablePending, MaxParticipants, participantType.MaxParticipants);

        if ((!showYouth && participantType.IsYouth) || (!showAdult && !participantType.IsYouth)) return null;

        // Note: eventType numbers is not possible, this entire component should not be rendered in that case.
        let availableComponent;
        if (eventType === 'both' && participantType.NumSpotsPending > 0) {
          availableComponent = this.renderShowAvailableBoth(participantType, this.onAddParticipant);
        } else if (eventType === 'participant' && available !== undefined) {
          availableComponent = this.renderShowAvailable(available, this.onAddParticipant, participants.length);
        }

        let emptyButtonText = 'ADD SPOTS';
        let emptyMessage = M.NO_SPOTS_PURCHASED;
        let emptyAction = this.onAddSpots;
        if (eventType === 'participant' || participantType.NumSpotsPending !== 0) {
          emptyButtonText = 'ADD PARTICIPANT';
          emptyMessage = M.NO_PARTICIPANTS_REGISTERED;
          emptyAction = this.onAddParticipant;
        }

        return (<RosterCard
          key={pt.ID}
          title={
            <RosterCardTitle mobileMarginBottom={16} mobileTopMargin={true} strong controls={availableComponent}>
              {participantType.NameWithYA}
            </RosterCardTitle>
          }
          template="mobile-no-margin"
        >
          {(!participants || participants.length === 0) &&
          <RosterListEmpty message={emptyMessage}
                           button={<Button color="green" onClick={emptyAction}>{emptyButtonText}</Button>}
          />}
          {participants && participants.length > 0 && <RosterList>
            {participants.map((p: EventRegistrationParticipant, i) => {
              return (
                <RosterItem
                  Name={p.Name}
                  IDi={p.IDi}
                  isYouth={p.IsYouth}
                  key={i}
                  cart={p.InCart === 1}
                  deleted={p.InCart === -1}
                  onRestoreClick={onRestoreClick}
                  onClick={() => {
                    navPush(router, `${getEventRegistrationUrl(this.props)}/${p.IsYouth ? 'y' : 'a'}/${p.IDi}/${spaceTo_(p.Name)}`);
                  }}>
                  {p.Name}
                </RosterItem>
              );
            })}
          </RosterList>}
        </RosterCard>);
      })}

    </ContentBlock>);
  }
}

export default withRouter(Participants);
