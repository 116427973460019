import { createSelector } from 'reselect';
import { ApplicationState } from '../../../..';
import { IValidator, FormDefinition, ClassRequirementFormDefinition, IClassRequirementValidator } from './validation';
import { SUFFIX } from './actions';
import * as Actions from './actions';
import { apiValidationReducerCreator, shouldSkipUpdateForm } from '../../../../Validation/reducerCreator';
import { setDefaults } from '../../../../../utils/validator';
import { AdminEventClass, AdminEventClassParticipant, AdminEventClassParticipantAttendance, AdminClassParticipantStatus, AdminEventChildClass, AdminEventClassRequirements } from '../../../../../models/api/adminEventsCacheTwoEvent';
import { allClassTypesSelector, filteredAdminEventClassesCombiner } from '../../../../../store/AdminEvents/Events/Event/Classes';
import { getOverallAttendanceStatus } from '../../../../../utils/helpers/adminEventsPageHelper';
import { API_UPD } from '../../../../../constants/request';
import { adminEventClassesSelector } from '../../../CacheTwoEvent';
import { UPDATE_ADMIN_CLASS_REQUIREMENT_SUFFIX } from '../../../CacheThreeClassRequirements/actions';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import { WithRootState } from '../../../../Validation/actionCreator';

export type EnterClassRequirementClassRequirementsActiveForm = Partial<AdminEventClassRequirements>;

export interface EnterClassRequirementModalState {
  ActiveForm: {
    IncludeBlankRows?: boolean;
    KindOfClass?: number;
    RegisterUnder?: number;
    SelectedClass?: AdminEventClass | AdminEventChildClass;
    ParticipantFilterText?: string;
  },
  ValidationRules: IValidator;
  ClassRequirements: {
    ActiveForm: EnterClassRequirementClassRequirementsActiveForm;
    ValidationRules: IClassRequirementValidator;
  };
  ClassParticipants: AdminEventClassParticipant[];
  AttendArr?: AdminEventClassParticipantAttendance[];
  FilterText?: string;
  isTextSearching: boolean;
}
const getInitialState = () => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition},
});

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);
const checkClassRequirementGeneralApiValidation = apiValidationReducerCreator(SUFFIX + '__CLASS_REQUIREMENT', undefined, true);
const checkClassParticipantGeneralApiValidation = apiValidationReducerCreator(SUFFIX + '__CLASS_PARTICIPANT', undefined, true);

const filteredTextSelector = (state: ApplicationState) => state.adminEvents.events.modals.enterClassRequirement.FilterText;
const showDeletedSelector = (state: ApplicationState) => false;
const kindOfClassSelector = (state: ApplicationState) => state.adminEvents.events.modals.enterClassRequirement.ActiveForm.KindOfClass || 0;
const registerUnderSelector = (state: ApplicationState) => state.adminEvents.events.modals.enterClassRequirement.ActiveForm.RegisterUnder || 0;

export const makeFilteredClassesSelector = () => {
  return createSelector(
    [adminEventClassesSelector, showDeletedSelector, filteredTextSelector, kindOfClassSelector, registerUnderSelector, allClassTypesSelector],
    filteredAdminEventClassesCombiner,
  );
};
const requirementExisted = (Requirements: (string | number)[], requirement: string | number) => {
  return Requirements.find((r) => r === requirement);
};

export const sortRequirements = (requirements: string[]) => {
  const result = requirements.sort((r1, r2) => {
    const r1_tokens = r1.split(/^[0-9]*/);
    const r2_tokens = r2.split(/^[0-9]*/);

    if (r1_tokens.length === 1 && r2_tokens.length > 1) {
      // r1 has no number but r2 has, sort r2 to lower index
      return 1;
    } else if (r1_tokens.length > 1 && r2_tokens.length === 1) {
      // r2 has no number but r1 has, sort r1 to lower index
      return -1;
    } else if (r1_tokens.length === 1 && r2_tokens.length === 1) {
      // neither r1 nor r2 has numbers
      return r1.toLowerCase().localeCompare(r2.toLowerCase());
    } else {
      // r1 and r2 both have number and non-number
      const r1_non_number = r1_tokens[1];
      const r2_non_number = r2_tokens[1];

      const r1_number = r1_non_number === "" ? r1 : r1.substr(0, r1.indexOf(r1_non_number));
      const r2_number = r2_non_number === "" ? r2 : r2.substr(0, r2.indexOf(r2_non_number));

      return Number(r1_number) < Number(r2_number) ? -1 : (Number(r1_number) > Number(r2_number) ? 1 : r1_non_number.toLowerCase().localeCompare(r2_non_number.toLowerCase()));
    }
  });

  return result;
};

const classParticipantsSelector = (state: ApplicationState) =>
  state.adminEvents.events.modals.enterClassRequirement.ClassParticipants || [];
const classParticipantFilterTextSelector = (state: ApplicationState) =>
  state.adminEvents.events.modals.enterClassRequirement.ActiveForm
    .ParticipantFilterText || "";
export const makeFilteredParticipantsSelector = () => {
  return createSelector(
    [
      classParticipantsSelector,
      classParticipantFilterTextSelector,
    ],
    (participants: AdminEventClassParticipant[], filterText: string) => {
      return participants.filter((p) =>
        p.ParticipantName.toLowerCase().includes(filterText.toLowerCase())
      );
    }
  );
};
export const getClassParticipantNewRequirementKey = (p: AdminEventClassParticipant) => `NewRequirement${p.IDi}`;

const refreshOverallAttendance = (ClassParticipants: AdminEventClassParticipant[]) => {
  if (ClassParticipants) {
    const Attend = ClassParticipants[0].Attend;
    const AttendArr: AdminEventClassParticipantAttendance[] = [];

    for (let key of Object.keys(Attend)) {
      const status = getOverallAttendanceStatus(ClassParticipants, key);
      AttendArr.push({
        label: key,
        checked: status === 1,
        status: status as any,
      });
    }

    return AttendArr;
  }

  return null;
};

const EnterClassRequirementModalReducer: Reducer<EnterClassRequirementModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);

  if (s && s.ClassRequirements) state.ClassRequirements = checkClassRequirementGeneralApiValidation(s.ClassRequirements, act);
  if (s && s.ClassParticipants) state.ClassParticipants = s.ClassParticipants.map((p) => {
    const action = act as any;
    if (action.vObj && action.vObj.extraInfo && action.vObj.extraInfo.id === p.IDi) {
      return checkClassParticipantGeneralApiValidation(p, act);
    } else {
      return p;
    }
  });

  if (isActionType(act, Actions.FilterClass)) {
    return {
      ...state,
      FilterText: act.filterText,
    };
  } else if (act.type.includes(API_UPD) && act.type.includes(UPDATE_ADMIN_CLASS_REQUIREMENT_SUFFIX)) {
    if (shouldSkipUpdateForm(act)) return state;

    // get initial state
    let newState = {...state};

    // set default values
    newState = setDefaults(act.rootState, newState, state);

    // load from cache three if set
    const rootState = act.rootState;

    if (rootState.adminEvents.cacheThreeClassRequirements && rootState.adminEvents.cacheThreeClassRequirements.EventsEventClassRequirements) {

      newState = {
        ...newState as any,
        ClassRequirements: {
          ActiveForm: {
            ...rootState.adminEvents.cacheThreeClassRequirements.EventsEventClassRequirements,
          },
          ValidationRules: {...ClassRequirementFormDefinition},
        },
        ClassParticipants: !rootState.adminEvents.cacheThreeClassRequirements.
        EventsEventClassParticipants ? null : rootState.adminEvents.cacheThreeClassRequirements.EventsEventClassParticipants.map((p) => {
          const Status = p.CompletionID;
          const statusKey = Actions.getClassParticipantStatusKey(p);
          const newRequirementKey = getClassParticipantNewRequirementKey(p);
          const AttendArr: AdminEventClassParticipantAttendance[] = [];
  
          for (let key of Object.keys(p.Attend)) {
            AttendArr.push({
              label: key,
              checked: p.Attend[key],
            });
          }
          return {
            ...p,
            Status,
            Requirements: p.Requirements || [],
            ActiveForm: {
              [statusKey]: Status,
              [newRequirementKey]: '',
            },
            ValidationRules: {
              [statusKey]: {
                key: statusKey,
                options: {
                  values: (rootState) => {
                    return rootState.cacheZero.options && rootState.cacheZero.options.EventTypes ? rootState.cacheZero.options.ClassCompletionOptions : [];
                  },
                  valueKey: (v) => v.ID,
                  labelKey: 'Name',
                },
                extraInfo: {
                  id: p.IDi,
                },
              },
              [newRequirementKey]: {
                key: newRequirementKey,
                defaultValue: () => '',
                extraInfo: {
                  id: p.IDi,
                },
              }
            },
            AttendArr,
          };
        }) || [],
      };

      newState.AttendArr = refreshOverallAttendance(newState.ClassParticipants);
    }

    return newState;
  } else if (isActionType(act, Actions.MassUpdateStatus)) {
    const newState = {
      ...state,
      ClassParticipants: state.ClassParticipants ? state.ClassParticipants.map((p) => {
        const statusKey = Actions.getClassParticipantStatusKey(p);
        if (p.ActiveForm[statusKey] === AdminClassParticipantStatus.NO_SHOW) return p;

        return {
          ...p,
          ActiveForm: {
            ...p.ActiveForm,
            [statusKey]: act.statusOptionId,
          },
        };
      }) : [],
    };

    newState.AttendArr = refreshOverallAttendance(newState.ClassParticipants);

    return newState;
  } else if (isActionType(act, Actions.MassUpdateRequirement)) {
    const {requirement} = act;
    return {
      ...state,
      ClassParticipants: state.ClassParticipants ? state.ClassParticipants.map((p) => {
        // if no show, skip
        const statusKey = Actions.getClassParticipantStatusKey(p);
        if (p.ActiveForm[statusKey] === AdminClassParticipantStatus.NO_SHOW) return p;
        const Requirements = [...p.Requirements];

        if (typeof requirement === "string") {
          // Add requirement to participant in old tracking way
          if (!requirementExisted(Requirements, requirement)) {
            Requirements.push(requirement);
          }
        } else {
          // Add requirement to participant in new tracking way
          if (!requirementExisted(Requirements, requirement.ID)) {
            Requirements.push(requirement.ID);
          }
        }

        return {
          ...p,
          Requirements,
        };
      }) : [],
    };
  } else if (isActionType(act, Actions.UpdateRequirementsForParticipant)) {
    const newState = {...state};
    const index = newState.ClassParticipants.findIndex((p) => p.IDi === act.participantId);

    if (index !== -1) {
      newState.ClassParticipants = [...newState.ClassParticipants];
      newState.ClassParticipants[index] = {...newState.ClassParticipants[index]};
      const participant = newState.ClassParticipants[index];

      participant.Requirements = [...act.reqIDs];
    }

    return newState;
  } else if (isActionType(act, Actions.MassUpdateAttendance)) {
    const newState = {
      ...state,
      ClassParticipants: state.ClassParticipants ? state.ClassParticipants.map((p) => {
        // if no show, skip
        const statusKey = Actions.getClassParticipantStatusKey(p);
        if (p.ActiveForm[statusKey] === AdminClassParticipantStatus.NO_SHOW) return p;
        const Attend = {...p.Attend};
        const AttendArr = [...p.AttendArr];
        
        Attend[act.attendanceLabel] = act.checked;

        return {
          ...p,
          Attend,
          AttendArr: AttendArr.map((attend) => {
            if (attend.label === act.attendanceLabel) return {...attend, checked: !!act.checked};
            return attend;
          }),
        };
      }) : [],
    };

    newState.AttendArr = refreshOverallAttendance(newState.ClassParticipants);

    return newState;
  } else if (isActionType(act, Actions.ToggleParticipantAttendance)) {
    const newState = {...state} as EnterClassRequirementModalState;

    const index = newState.ClassParticipants.findIndex((p) => p.IDi === act.participantId);

    if (index !== -1) {
      newState.ClassParticipants = [...newState.ClassParticipants];
      newState.ClassParticipants[index].AttendArr = newState.ClassParticipants[index].AttendArr.map((attend) => {
        return {
          ...attend,
          checked: attend.label === act.attendance.label ? !attend.checked : attend.checked,
        };
      });
    }

    return newState;
  } else if (isActionType(act, Actions.AddAllRequirementsToParticipant_OldTracking)) {
    /**
     * Add all requirements to a participant, this should only be used
     * for old tracking class
     */
    const newState = {...state} as EnterClassRequirementModalState;

    const index = newState.ClassParticipants.findIndex((p) => p.IDi === act.participantId);

    if (index !== -1) {
      newState.ClassParticipants = [...newState.ClassParticipants];
      newState.ClassRequirements.ActiveForm.Requirements && newState.ClassRequirements.ActiveForm.Requirements.forEach((r) => {
        newState.ClassParticipants[index] = {...newState.ClassParticipants[index]};
        const participant = newState.ClassParticipants[index];

        if (!requirementExisted(participant.Requirements, r)) {
          participant.Requirements.push(r);
        }
      });
    }

    return newState;
  } else if (isActionType(act, Actions.RemoveAllRequirementsFromParticipant_OldTracking)) {
    /**
     * Remove all requirements from a participant, this should only be triggered
     * for old tracking class
     */
    const newState = {...state} as EnterClassRequirementModalState;

    const index = newState.ClassParticipants.findIndex((p) => p.IDi === act.participantId);

    if (index !== -1) {
      newState.ClassParticipants = [...newState.ClassParticipants];
      newState.ClassParticipants[index] = {...newState.ClassParticipants[index]};
      newState.ClassParticipants[index].Requirements = [];
    }

    return newState;
  } else if (isActionType(act, Actions.RemoveRequirementFromParticipant)) {
    const newState = {...state} as EnterClassRequirementModalState;

    const index = newState.ClassParticipants.findIndex((p) => p.IDi === act.participantId);

    if (index !== -1) {
      newState.ClassParticipants = [...newState.ClassParticipants];
      const rIndex = newState.ClassParticipants[index].Requirements.findIndex((r) => r === act.requirement);

      if (rIndex !== -1) {
        newState.ClassParticipants[index] = {...newState.ClassParticipants[index]};
        newState.ClassParticipants[index].Requirements = [...newState.ClassParticipants[index].Requirements];
        newState.ClassParticipants[index].Requirements.splice(rIndex, 1);
      }
    }

    return newState;
  } else if (isActionType(act, Actions.AddNewRequirementsToParticipant_OldTracking)) {
    /**
     * Add one or more requirements to a participant, this should only be used
     * for old tracking class
     */
    const newState = {...state} as EnterClassRequirementModalState;

    const index = newState.ClassParticipants.findIndex((p) => p.IDi === act.participantId);

    if (index !== -1) {
      newState.ClassParticipants = [...newState.ClassParticipants];
      const requirements = act.requirements.split(',').map((r) => r.trim()).filter(r => !!r);
      newState.ClassParticipants[index] = {...newState.ClassParticipants[index]};
      const participant = newState.ClassParticipants[index];
      participant.Requirements = [...participant.Requirements];
      
      requirements.forEach((r) => {
        if (!requirementExisted(participant.Requirements, r)) {
          participant.Requirements.push(r);
        }
      });
    }

    return newState;
  } else if (isActionType(act, Actions.SelectClassForEnterClassRequirementModal)) {
    const newState = {...state};

    newState.ActiveForm = {
      ...newState.ActiveForm,
      SelectedClass: act.selectedClass,
    };

    return newState;
  } else if (isActionType(act, Actions.EnterClassRequirementModalToggleFilter)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, Actions.InitEnterClassRequirementModal)) {
    const newState = {
      ...setDefaults(act.rootState, state),
    };

    return newState;
  } else if (isActionType(act, Actions.RefreshMassUpdateAttendance)) {
    const newState = {...state};

    newState.AttendArr = refreshOverallAttendance(newState.ClassParticipants);

    return newState;
  } else if (isActionType(act, Actions.ResetEnterClassRequirementModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default EnterClassRequirementModalReducer;