import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';
import { namespace as ns } from './constants';
import '../../../styles/elements/camp-info/action.scss';
import { default as Button, ButtonProps } from '../Button';

export const namespace = (): string => `${ns()}--action`;

export interface ActionProps extends ButtonProps {
  className?: string;
  onClick: React.EventHandler<any>;
  children?: React.ReactNode;
}

const Action: React.SFC<ActionProps> = ({ children, onClick, ...props }: ActionProps): React.ReactElement<ActionProps> => (
  <Button onClick={onClick} expand big color="green" {...props} className={mergeClassNames(namespace(), props)}>
    {children}
  </Button>
);

export default Action;
