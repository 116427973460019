import * as React from 'react';
import { Modal, ModalTitle, ModalContent, ModalActions, Button, Paragraph } from '../../../../Elements';
import {actionCreators as appActionCreators} from "../../../../../store/App/actions";
import {actionCreators} from '../../../../../store/Settings/Roster/Add/actions';
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import { captureTentarooError } from '../../../../../utils/dataHelper';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import {WithInertAttribute} from '../../../../Elements/WithInert';


type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = WithInertAttribute<{}>

class ProfileDeletePerson extends React.Component<Props & ConnectedProps, {}> {

  onConfirm = (e) => {
    e.preventDefault();
    this.props.actions.apiDeletePerson();
  };
  onCancel = (e) => {
    e.preventDefault();
    this.props.actions.popModal(false, true, ModalTypes.PROFILE_DELETE_PERSON);
  };

  render() {
    const {cacheThreeRoster, inert} = this.props;

    if (!cacheThreeRoster.GroupRosterPerson) {
      captureTentarooError(new Error("cacheThreeRoster.GroupRosterPerson not available when opening DeletePerson modal"));

      return null;
    }

    const {Name, FirstName} = cacheThreeRoster.GroupRosterPerson;
    return (
      <Modal inert={inert} disableDynamicHeight big>
        <ModalTitle>Remove {Name}?</ModalTitle>
        <ModalContent paddingBottom={0} paddingTop={0}>
          <Paragraph color="gray" marginBottom={16}>
            You will be able to restore {FirstName} any time.
          </Paragraph>
        </ModalContent>
        <ModalActions noPadding stacked>
          <Button id={generateDOMId("close-delete-person-btn")} flat onClick={this.onCancel}>CANCEL</Button>
          <Button flat textColor="red" onClick={this.onConfirm}>REMOVE</Button>
        </ModalActions>
      </Modal>
    );
  }
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    cacheThreeRoster: state.cacheThreeRoster,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actionCreators, ...appActionCreators }, dispatch)
});

const ConnectedProfileDeletePerson = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ProfileDeletePerson);

export default ConnectedProfileDeletePerson;
