import { ActionCreator } from '../../../../.';
import {
  createApiSubmitActions,
  createApiValidateActions, createSimpleUpdateValueMethod, createUpdateValueMethod,
  createValidateActions
} from "../../../../Validation/actionCreator";
import {validateAll} from "../../../../../utils/validator";
import {FacilityTrip} from "../../../../../models/api/cacheThreeFacilities";
import {getFacilitiesReservationsRecalcBody} from "../../../../../constants/urls";
import * as M from "../../../../../constants/messages/generic";
import {ShowTopFloatingAlert, actionCreators as appActionCreators} from "../../../../App/actions";
import { captureTentarooError } from '../../../../../utils/dataHelper';
import { ReservationSaveOverrideFees } from '../../../../CacheFourFacilities/actions';
import { SaveState } from '../../../../Rollback/actions';
import { ModalTypes } from '../../../../../utils/modalHelper';

export const SUFFIX = '__RESERVATION_OVERRIDE_FEE';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ApiSubmitActions = createApiSubmitActions(SUFFIX);


export const SUFFIX_CHANGE = '__RESERVATION_OVERRIDE_FEE_FORM_CHANGE';
export const OverrideReservationChange = createApiSubmitActions(SUFFIX_CHANGE, true);

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.facilities.trip.modals.reservationOverrideFee),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  overrideReservationChange: (
    typeID: number,
    trip: FacilityTrip,
    FlatRate?: number,
    PerPersonRate?: number,
    resId?: number,
    ftbID?: number | null
  ): ActionCreator => (dispatch, getState) => {
    const rootState = getState();

    if (!rootState.cacheZero.options) {
      captureTentarooError(new Error("cacheZero.options not available when performing overrideReservationChange"));
      return;
    }
    if (!rootState.cacheZero.options.Group) {
      captureTentarooError(new Error("Group not available when performing overrideReservationChange"));
      return;
    }
    const GroupIDi = rootState.cacheZero.options.Group.IDi;
    const GroupTS = rootState.cacheZero.options.Group.TS;
    const FacilityLocationID = rootState.cacheTwoFacilities.locationID;
    const FacilityTripID = rootState.cacheThreeFacilities.tripID;
    const Reservation = rootState.cacheFourFacilities.Reservation!;
    const FacilityTypeID = typeID;
    const {StartDateTime, EndDateTime, NumYouth, NumAdults} = rootState.facilities.trip.reservation.reservation.ActiveForm;

    if (Reservation.FacilityID === null) {
      captureTentarooError(new Error("Reservation.FacilityID is null when performing overrideReservationChange"));
      return;
    }
    if (NumYouth === undefined) {
      captureTentarooError(new Error("NumYouth is undefined when performing overrideReservationChange"));
      return;
    }
    if (Reservation.MinimumPeopleBilledAtPerPersonRates === null) {
      captureTentarooError(new Error("Reservation.MinimumPeopleBilledAtPerPersonRates is null when performing overrideReservationChange"));
      return;
    }
    if (NumAdults === undefined) {
      captureTentarooError(new Error("NumAdults is undefined when performing overrideReservationChange"));
      return;
    }

    const results = validateAll(s => s.facilities.trip.modals.reservationOverrideFee);

    if (!results) {
      // We don't do any save state before the request here because we are relying on the following save state to ensure the save point
      // 1. The one in line #128 when dont send out a new request
      // 2. The one dispatched when reservationChange is successful
      dispatch(OverrideReservationChange.request(getFacilitiesReservationsRecalcBody(
        {
          GroupIDi,
          GroupTS,
          FacilityLocationID,
          FacilityTripID,
          FacilityTypeID,
          ReservationID: resId
        },
        Reservation.FacilityID,
        NumYouth,
        NumAdults,
        FlatRate!,
        PerPersonRate!,
        Reservation.MinimumPeopleBilledAtPerPersonRates,
        ftbID ? ftbID : null,
        StartDateTime,
        EndDateTime,
        false
      ), null));
    } else {
      dispatch(ApiSubmitActions.clientFailure(results, true));
      let errorMessage = M.SUBMIT_ERROR;
      if (results.Errors.PerPersonRate) errorMessage = results.Errors.PerPersonRate[0];
      else if (results.Errors.FlatRate) errorMessage = results.Errors.FlatRate[0];
      dispatch(new SaveState());
      dispatch(new ShowTopFloatingAlert(errorMessage, true, 'orange'));
    }
  },
  saveOverride: (
    FlatRate: number,
    PerPersonRate: number,
  ): ActionCreator => (dispatch, getState) => {
    const results = validateAll(s => s.facilities.trip.modals.reservationOverrideFee);
    if (!results) {
      dispatch(
        new ReservationSaveOverrideFees(
          FlatRate,
          PerPersonRate,
          getState().facilities.trip.modals.reservationOverrideFee.amount,
          getState().facilities.trip.modals.reservationOverrideFee.AmountChangeInCart,
          getState().facilities.trip.modals.reservationOverrideFee.TripPaymentStatus,
        ));

      dispatch(appActionCreators.popModal(false, true, ModalTypes.RESERVATION_OVERRIDE_FEE) as any);
    } else {
      dispatch(ApiSubmitActions.clientFailure(results, true));
      let errorMessage = M.SUBMIT_ERROR;
      if (results.Errors.PerPersonRate) errorMessage = results.Errors.PerPersonRate[0];
      else if (results.Errors.FlatRate) errorMessage = results.Errors.FlatRate[0];
      dispatch(new ShowTopFloatingAlert(errorMessage, true, 'orange'));
    }
  },
};
