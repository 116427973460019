import * as React from 'react';

import { Form, Row, Column, TextField, Image, RadioGroupOption, Text, DatePicker, Alert } from '../../../../../../Elements';
import { Main, MainContent } from '../../../../../../../components/Layouts';
import { IValidator } from '../../../../../../../store/AdminCMSSite/Pages/Modals/ImageForm/validation';
import { ImageActiveForm } from '../../../../../../../store/AdminCMSSite/Pages/Modals/ImageForm';
import { CMSPageGalleryImage } from '../../../../../../../models/api/adminCMSCacheTwoPage';
import { getImagePath } from '../../../../../../../utils';
import CKEditor from '../../../../../../../components/Elements/CKEditor';
import { DEFAULT_CKEDITOR_CONFIG, CKEDITOR_MODE, registerSourceModeInputEvent } from '../../../../../../../utils/ckeditorHelper';
import { Validator } from '../../../../../../../utils/validator/models';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

export type Props = WithInertAttribute<{
  action: 'add' | 'edit';
  disabled?: boolean;
  ActiveForm: ImageActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage: string;
  loading?: boolean;
  image?: CMSPageGalleryImage;
  hideFormAction?: boolean;
  handleCompact?: boolean;
  namespace: () => string;
  onSave?: () => void;
  onDelete?: () => void;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
  };
  onCKEditorReady?: (eventInfo) => void;
}>

interface State {
  ckeditorMode: CKEDITOR_MODE;
}

class ImageForm extends React.Component<Props, State> {
  public props: Props;

  state = {
    ckeditorMode: CKEDITOR_MODE.WYSIWYG,
  };

  onImageClick = () => {
    // if not uploaded - do nothing
    if (!this.props.image || !this.props.image.filePath) return;
    const url = getImagePath(this.props.image, '2200px');
    window.open(url);
  };

  onCKEditorSourceModeInput = (inputEventInfo) => {
    const {ValidationRules, reduxActions} = this.props;
    reduxActions.simpleUpdate(inputEventInfo.data.$.target.value, ValidationRules.Description);
  };

  onCKEditorModeChange = (eventInfo) => {
    if (eventInfo.editor && eventInfo.editor.mode) {
      this.setState({
        ckeditorMode: eventInfo.editor.mode,
      });
      
      registerSourceModeInputEvent(eventInfo, this.onCKEditorSourceModeInput);
    }
  };

  public render() {
    const {handleCompact, disabled, reduxActions, namespace, onCKEditorReady, ValidationRules, ActiveForm, image, SubmitErrorMessage, inert} = this.props;
    const source = image ? (ActiveForm.dataUrl ? image.dataUrl as any : getImagePath(image)) : '';
    return (
        <Main inert={inert}>
          <MainContent className={`${namespace()}--main-content`} handleCompact={handleCompact}>
            <Form
              onSubmit={e => e.preventDefault()}
            >
              {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
              <Row horizontalAlignment='center' marginBottom={24}>
                <Image onClick={this.onImageClick} width={144} height={144} source={source} />
              </Row>
              <Row marginBottom={24}>
                <Column span={12}>
                  <CKEditor
                    config={{...DEFAULT_CKEDITOR_CONFIG, startupMode: this.state.ckeditorMode, height: 150}}
                    data={ActiveForm.Description}
                    onChange={(e) => reduxActions.simpleUpdate(e.editor.getData(), ValidationRules.Description)}
                    onMode={this.onCKEditorModeChange}
                    onReady={onCKEditorReady}
                  />
                </Column>
              </Row>
              <Row>
                <Column span={12}>
                  <TextField
                    label="Alt Text"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Name}
                    value={ActiveForm.Name}/>
                </Column>
              </Row>
              <Row>
                <Column span={12}>
                  <TextField
                    label="Link"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.LinkURL}
                    value={ActiveForm.LinkURL}/>
                </Column>
              </Row>
              <div className={`${namespace()}--field-label`}>Open In</div>
              <Row marginBottom={24}>
                <Column mobileMarginBottom={0} layout='horizontal' span={6} mobileSpan={12}>
                  <RadioGroupOption
                    value={!!!ActiveForm.IsLinkNewWindow}
                    selected={!!!ActiveForm.IsLinkNewWindow}
                    ValidationRules={ValidationRules.IsLinkNewWindow}
                    rawValue={false}
                    onChange={reduxActions.updateValue}
                    disabled={disabled}
                    newDesign
                    label={<Text color='dark-gray' weight="regular" size={16} marginBottom={0}>Same Window</Text>}
                  />
                  
                </Column>
                <Column layout='horizontal' span={6} mobileSpan={12}>
                  <RadioGroupOption
                    value={!!ActiveForm.IsLinkNewWindow}
                    selected={!!ActiveForm.IsLinkNewWindow}
                    disabled={disabled}
                    ValidationRules={ValidationRules.IsLinkNewWindow}
                    rawValue={true}
                    onChange={reduxActions.updateValue}
                    newDesign
                    label={<Text color='dark-gray' weight="regular"  size={16} marginBottom={0}>New Window</Text>}
                  />
                  
                </Column>
              </Row>
              <Row mobileMarginBottom={16}>
                <Column span={6} mobileSpan={12}>
                  <DatePicker
                    className={`${namespace()}--date`}
                    label="Publish Date"
                    value={ActiveForm.PublishDate}
                    validationRules={ValidationRules.PublishDate}
                    onSelect={reduxActions.updateValue}
                    onChangeRaw={reduxActions.simpleUpdate}
                    removeMax
                  />
                </Column>
                <Column span={6} mobileSpan={12}>
                  <DatePicker
                    className={`${namespace()}--date`}
                    label="Expire Date"
                    value={ActiveForm.ExpireDate}
                    validationRules={ValidationRules.ExpireDate}
                    onSelect={reduxActions.updateValue}
                    onChangeRaw={reduxActions.simpleUpdate}
                    removeMax
                  />
                </Column>
              </Row>
            </Form>
          </MainContent>
        </Main>
    );
  }
}

export default ImageForm;
