import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminFacilityLocationCacheOneContext } from "@tentaroo/shared";

import { ApplicationState } from '../../../../../store';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as cacheOneActionCreators, GetFacilityLocationCache } from '../../../../../store/AdminFacilityLocation/CacheOne/actions';
import { actionCreators } from "../../../../../store/AdminFacilityLocation/Settings/actions";
import { LoadingAll, Button, ActionButton, Media, Alert, Card, FieldSet, TextField, Row, Column, Select, Switch, Form, FormActions, PageLoader } from '../../../../Elements';
import { Main, MainContent } from '../../../../Layouts';
import '../../../../../styles/pages/admin-facility-location/settings/home/index.scss';
import CKEditor from '../../../../../components/Elements/CKEditor';
import { CKEDITOR_MODE, registerSourceModeInputEvent, DEFAULT_CKEDITOR_CONFIG } from '../../../../../utils/ckeditorHelper';
import { spaceTo_, copyStringToClipboard } from '../../../../../utils';
import { CopyIcon } from '../../../../../components/Icons';
import { LINK_COPIED } from '../../../../../constants/messages/generic';
import { getDomain } from '../../../../../utils/urlHelper';
import AdminFacilitiesLocationCacheManager from '../../../../../utils/cacheManagers/adminFacilitiesLocationCacheManager';
import { IAdminFacilitiesLocationRouterParams } from '../../../../../utils/helpers/adminFacilityLocationPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { noOpenedModals } from '../../../../../utils/modalHelper';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isAdminFacilitiesLocationCacheOnePopulated} from '../../../../../utils/cachePopulatedCheckers/adminFacilities';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--facility-location--settings';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}>
>;

interface State {
  ckeditorMode: CKEDITOR_MODE;
}

class SettingsHome extends ComponentUpdateTemplate<ConnectedProps, State> {
  public props: ConnectedProps;
  private editor: any;

  constructor(props) {
    super(props, {
      ckeditorMode: CKEDITOR_MODE.WYSIWYG,
    });
  }

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        AdminFacilitiesLocationCacheManager.getInstance().loadAdminFacilitiesLocationCacheOne({
          props: this.props,
          isStateNavigated,
          context: AdminFacilityLocationCacheOneContext.SETTINGS,
        });
      }
    );
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        AdminFacilitiesLocationCacheManager.getInstance().loadAdminFacilitiesLocationCacheOne({
          props: nextProps,
          isStateNavigated,
          context: AdminFacilityLocationCacheOneContext.SETTINGS,
        });
      }
    );
  }

  componentWillUnmount() {
    if (this.editor && this.editor.getCommand("maximize").state === 1) {
      // if ckeditor is maximized, minimize it before unloading this page
      this.editor.execCommand('maximize');
    }
    this.props.actions.reset();
  }

  onCopyDeepLink = (deepLink: string) => {
    copyStringToClipboard(deepLink);
    this.props.actions.showSnackbarItem(LINK_COPIED);
  };

  isFormDisabled = () => {
    const {apiSaving, adminFacilityLocationCacheOne } = this.props;

    if (!adminFacilityLocationCacheOne.FacilitiesLocation) return true;

    return apiSaving > 0 || !!!adminFacilityLocationCacheOne.FacilitiesLocation.hasFacilitySettings;
  };

  renderGeneralSection = () => {
    const {adminFacilityLocationCacheOne, actions, settingsFormState: {ActiveForm, ValidationRules}, apiSaving} = this.props;
    const domain = getDomain(false);
    const location = adminFacilityLocationCacheOne.FacilitiesLocation;
    const deepLink = !location ? '' : `${domain}/admin2/facilities/${location.ID}/${spaceTo_(location.Name)}`;

    return (
      <Card
  ​      className={`${namespace()}--card`}
  ​      component={<section/>}
  ​      template={'mobile-no-margin'}
        marginTop={0}
        mobileMarginTop={0}
  ​      mobileMarginBottom={0}
  ​      marginBottom={0}
      >
        <FieldSet
          fixedLegendFontSize
          marginBottom={24} mobileMarginBottom={16}
          newDesign legendMarginBottom={0}
          fontSize={22} name="General"
        />
        <Row marginBottom={8} mobileMarginBottom={8}>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Location Name"
              disabled={this.isFormDisabled()}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.Name}
              value={ActiveForm.Name}
            />
          </Column>
        </Row>
        <Row marginBottom={8} mobileMarginBottom={8}>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Address"
              disabled={this.isFormDisabled()}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.Address}
              value={ActiveForm.Address}
            />
          </Column>
        </Row>
        <Media tabletAndGreater>
          <Row marginBottom={8} mobileMarginBottom={8}>
            <Column span={6}>
              <TextField
                label="City"
                disabled={this.isFormDisabled()}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules.City}
                value={ActiveForm.City}
              />
            </Column>
            <Column span={3}>
              <Select label="State"
                      disabled={this.isFormDisabled()}
                      onChangeValue={actions.updateValue}
                      value={ActiveForm.StateID}
                      validationRules={ValidationRules.StateID}
                      isNumber
              />
            </Column>
            <Column span={3}>
              <TextField
                label="Zip"
                disabled={this.isFormDisabled()}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules.Zip}
                value={ActiveForm.Zip}
              />
            </Column>
          </Row>
        </Media>
        <Media mobile style={{flexDirection: 'column'}}>
          <Row marginBottom={8} mobileMarginBottom={8}>
            <Column mobileSpan={12}>
              <TextField
                label="City"
                disabled={this.isFormDisabled()}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules.City}
                value={ActiveForm.City}
              />
            </Column>
          </Row>
          <Row marginBottom={8} mobileMarginBottom={8}>
            <Column mobileSpan={6}>
              <Select label="State"
                      disabled={this.isFormDisabled()}
                      onChangeValue={actions.updateValue}
                      value={ActiveForm.StateID}
                      validationRules={ValidationRules.StateID}
                      isNumber
              />
            </Column>
            <Column mobileSpan={6}>
              <TextField
                label="Zip"
                disabled={this.isFormDisabled()}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules.Zip}
                value={ActiveForm.Zip}
              />
            </Column>
          </Row>
        </Media>
        <Row marginBottom={8} mobileMarginBottom={8}>
          <Column marginBottom={0} mobileMarginBottom={8} span={6} mobileSpan={12}>
            <TextField
              label="Phone"
              disabled={this.isFormDisabled()}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.PhoneNumber}
              value={ActiveForm.PhoneNumber}
            />
          </Column>
          <Column span={6} mobileSpan={12}>
            <TextField
              label="Fax"
              disabled={this.isFormDisabled()}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.FaxNumber}
              value={ActiveForm.FaxNumber}
            />
          </Column>
        </Row>
        <Row marginBottom={16} mobileMarginBottom={16}>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Facilities Website Link"
              disabled={this.isFormDisabled()}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              info='Shown on the end user side, should link to a page on your website with more details about facility booking at this location.'
              validationRules={ValidationRules.FacilitiesWebsiteURL}
              value={ActiveForm.FacilitiesWebsiteURL}
            />
          </Column>
        </Row>
        <Row marginBottom={0} mobileMarginBottom={0}>
          <Column span={12} mobileSpan={12} style={{flexDirection: 'column'}}>
            <div className={`${namespace()}--label`}>Deep Link</div>
            <div className={`${namespace()}--deep-link-content`}>
              <div>{deepLink}</div>
              <ActionButton icon={CopyIcon} onClick={() => this.onCopyDeepLink(deepLink)} />
            </div>
          </Column>
        </Row>
      </Card>
    );
  };

  renderRuleTextfield = (label1: string, label2: string, helperTexts: string[], value, vObj) => {
    const {apiSaving, actions} = this.props;
    return (
      <div className={`${namespace()}--rule--textfield--wrapper`}>
        <Row marginTop={16}>
          <Column span={7} mobileSpan={12} className={`${namespace()}--rule--textfield--label label1`}>{label1}</Column>
          <Column span={5} mobileSpan={12}>
            <Row mobileMarginTop={8} wrap={false}>
              <div className={`${namespace()}--rule--textfield--text`}>
                <TextField
                  disabled={this.isFormDisabled()}
                  onChange={actions.simpleUpdate}
                  onBlur={actions.updateValue}
                  validationRules={vObj}
                  noPaddingBottom={!(vObj.errors && vObj.errors.length > 0)}
                  value={value}
                />
              </div>
              <div className={`${namespace()}--rule--textfield--label label2`}>
                {label2}
              </div>
            </Row>
          </Column>
        </Row>
        {!(vObj.errors && vObj.errors.length > 0) && helperTexts.map((text) => <div className={`${namespace()}--rule--textfield--helper-text`}>{text}</div>)}
      </div>
    );
  };

  renderRulesSection = () => {
    const {adminFacilityLocationCacheOne, settingsFormState: {ActiveForm, ValidationRules}, apiSaving, actions} = this.props;

    return (
      <Card
  ​      className={`${namespace()}--card`}
  ​      component={<section/>}
  ​      template={'mobile-no-margin'}
        marginTop={24}
        mobileMarginTop={16}
  ​      mobileMarginBottom={0}
  ​      marginBottom={0}
      >
        <FieldSet
          fixedLegendFontSize
          marginBottom={16} mobileMarginBottom={16}
          newDesign legendMarginBottom={0}
          fontSize={22} name="Rules"
        />
        <Row>
          <Column span={12} mobileSpan={12}>
            <Switch
                disabled={this.isFormDisabled()}
                label="Allow Online Booking"
                multilineLabel
                newDesign
                onChange={actions.updateValue}
                value={!!ActiveForm.AllowOnlineFacilityBooking}
                validationRules={ValidationRules.AllowOnlineFacilityBooking}
              />
            </Column>
        </Row>
        <Row>
          <Column span={12} mobileSpan={12}>
            <Switch
                disabled={this.isFormDisabled()}
                label="Require Trip Contact Phone"
                multilineLabel
                newDesign
                onChange={actions.updateValue}
                value={!!ActiveForm.RequireTripContactPhone}
                validationRules={ValidationRules.RequireTripContactPhone}
              />
            </Column>
        </Row>
        {this.renderRuleTextfield('Allow Booking Maximum', 'Months Ahead', ['How many months in advance may a reservation be made by your groups?'], ActiveForm.AllowBookingMaxMonthsAhead, ValidationRules.AllowBookingMaxMonthsAhead)}
        {this.renderRuleTextfield('Allow Booking Minimum', 'Days Prior', ['How close to the current date can new facility or excursion reservations be made?', 'For example, is a new reservation for a campsite allowed as early as tomorrow? If yes - enter 1 Days Prior.'], ActiveForm.AllowBookingMinDaysPrior, ValidationRules.AllowBookingMinDaysPrior)}
        {this.renderRuleTextfield('Allow Automatic Credit', 'Days Prior', ['For how many days prior to the reservation should groups be allowed to remove reservations and automatically get credit for any reductions?'], ActiveForm.AllowCreditNumDaysPrior, ValidationRules.AllowCreditNumDaysPrior)}
        {this.renderRuleTextfield('Allow Changes By Group', 'Days Prior', ['For how many days prior to the reservation should changes be allowed?'], ActiveForm.AllowChangesNumDaysPrior, ValidationRules.AllowChangesNumDaysPrior)}
      </Card>
    );
  };

  renderPrimaryContactSection = () => {
    const {adminFacilityLocationCacheOne, actions, settingsFormState: {ActiveForm, ValidationRules}, apiSaving} = this.props;

    return (
      <Card
  ​      className={`${namespace()}--card`}
  ​      component={<section/>}
  ​      template={'mobile-no-margin'}
        marginTop={24}
        mobileMarginTop={16}
  ​      mobileMarginBottom={0}
  ​      marginBottom={0}
      >
        <FieldSet
          fixedLegendFontSize
          marginBottom={24} mobileMarginBottom={16}
          newDesign legendMarginBottom={0}
          fontSize={22} name="Primary Contact"
        />
        <Row marginBottom={8} mobileMarginBottom={8}>
          <Column span={12} mobileSpan={12}>
              <TextField
                label="Full Name"
                disabled={this.isFormDisabled()}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules.PrimaryContactName}
                value={ActiveForm.PrimaryContactName}
              />
            </Column>
        </Row>
        <Row marginBottom={0} mobileMarginBottom={0}>
          <Column span={6} mobileSpan={12} marginBottom={0} mobileMarginBottom={8}>
            <TextField
              label="Email"
              disabled={this.isFormDisabled()}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.PrimaryContactEmail}
              value={ActiveForm.PrimaryContactEmail}
            />
          </Column>
          <Column span={6} mobileSpan={12}>
            <TextField
              label="Phone"
              disabled={this.isFormDisabled()}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.PrimaryContactPhone}
              value={ActiveForm.PrimaryContactPhone}
            />
          </Column>
        </Row>
      </Card>
    );
  };


  onCKEditorModeChange = (eventInfo) => {
    if (eventInfo.editor && eventInfo.editor.mode) {
      this.setState({
        ckeditorMode: eventInfo.editor.mode,
      });
      
      registerSourceModeInputEvent(eventInfo, this.onCKEditorSourceModeInput);
    }
  };

  onCKEditorSourceModeInput = (inputEventInfo) => {
    const {settingsFormState: {ValidationRules}, actions} = this.props;
    actions.simpleUpdate(inputEventInfo.data.$.target.value, ValidationRules.TripNotificationHTML);
  };

  onCKEditorReady = (eventInfo) => {
    if (this.editor !== eventInfo.editor) this.editor = eventInfo.editor;
    this.editor.setReadOnly(this.isFormDisabled());
  };
  renderTripNotificationCustomMessageSection = () => {
    const {adminFacilityLocationCacheOne, actions, settingsFormState: {ActiveForm, ValidationRules}, apiSaving} = this.props;

    return (
      <Card
  ​      className={`${namespace()}--card`}
  ​      component={<section/>}
  ​      template={'mobile-no-margin'}
        marginTop={24}
        mobileMarginTop={16}
  ​      mobileMarginBottom={0}
  ​      marginBottom={0}
        paddingBottom={0}
      >
        <FieldSet
          fixedLegendFontSize
          marginBottom={8} mobileMarginBottom={8}
          newDesign legendMarginBottom={0}
          fontSize={22} name="Trip Notification Custom Message"
        />
        <div className={`${namespace()}--helper-text`}>Enter text to show up on Trip Change Notifications. Can add refund policies, etc.</div>
        <Row className={`${namespace()}--ckeditor-row`}>
          <Column span={12}>
            <CKEditor
              config={{...DEFAULT_CKEDITOR_CONFIG, startupMode: this.state.ckeditorMode, height: 150}}
              data={ActiveForm.TripNotificationHTML}
              onChange={(e) => actions.simpleUpdate(e.editor.getData(), ValidationRules.TripNotificationHTML)}
              onMode={this.onCKEditorModeChange}
              onReady={this.onCKEditorReady}
            />
          </Column>
        </Row>
      </Card>
    );
  };

  onSave = () => {
    const {actions, settingsFormState: {ValidationRules},} = this.props;
    const ckeditorData = !this.editor ? '' : this.editor.getData();
    if (ckeditorData) actions.simpleUpdate(ckeditorData, ValidationRules.TripNotificationHTML);
    this.props.actions.apiSubmitForm();
  };
  public render() {
    const {apiSaving, apiLoadingMap, settingsFormState: {SubmitErrorMessage, ActiveForm}, adminFacilityLocationCacheOne, inert} = this.props;
    if (!isAdminFacilitiesLocationCacheOnePopulated(undefined, ActiveForm)) {
      return <LoadingAll />;
    }

    const refreshing = apiLoadingMap[GetFacilityLocationCache.requestType] && noOpenedModals();
    const saving = apiSaving > 0 && noOpenedModals();

    return (
      <Main inert={inert} isLoading={refreshing} isAdminFacilityLocationPage>
        <MainContent className={namespace()} handleCompact>
          <Form
              onSubmit={e => e.preventDefault()}
              actions={
                <FormActions handleCompact>
                  <Button disabled={this.isFormDisabled()} flat textColor="black" big onClick={this.onSave}>SAVE</Button>
                </FormActions>
              }
            >
            {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
            {adminFacilityLocationCacheOne.FacilitiesLocation && adminFacilityLocationCacheOne.FacilitiesLocation.hasFacilitySettingsReason ? <Alert className={`${namespace()}--alert`}>{adminFacilityLocationCacheOne.FacilitiesLocation.hasFacilitySettingsReason}</Alert> : null}
            {this.renderGeneralSection()}
            {this.renderRulesSection()}
            {this.renderPrimaryContactSection()}
            {this.renderTripNotificationCustomMessageSection()}
          </Form>
        </MainContent>
        {saving && <PageLoader fullScreen />}
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
    settingsFormState: state.adminFacilityLocation.settings,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...cacheOneActionCreators,
  }, dispatch),
});
const ConnectedSettingsHome = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(SettingsHome);

export default withRouter<{}>(ConnectedSettingsHome);
