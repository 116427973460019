import type {AdminSettingsCacheTwoLocationState} from "../../store/AdminSettings/CacheTwoLocation";
import type {LocationActiveForm} from "../../store/AdminSettings/Modals/LocationForm";
import type { Location } from "../../models/api/adminCMSCacheOne";
import type {AdminSettingsCacheTwoGLAccountState} from "../../store/AdminSettings/CacheTwoGLAccount";
import type {GLAccountActiveForm} from "../../store/AdminSettings/GLAccounts/GLAccount/Form";
import type {GLAccount} from "../../models/api/options";

import {isCacheLevelPopulated} from "./common";


const MIN_REQUIRED_FIELDS_SHARED_LOCATION = 0;
const MIN_REQUIRED_FIELDS_ADMIN_SETTINGS_GL_ACCOUNT = 0;

// Cache Two
export function isAdminSettingsCacheTwoLocationPopulated(sharedCacheTwoLocation: AdminSettingsCacheTwoLocationState, locationForm?: LocationActiveForm) {
  return isCacheLevelPopulated<Location>(
    sharedCacheTwoLocation.Location,
    MIN_REQUIRED_FIELDS_SHARED_LOCATION,
    () => !locationForm || (!!sharedCacheTwoLocation.Location && locationForm.ID === sharedCacheTwoLocation.Location.ID),
  );
};

export function isAdminSettingsCacheTwoGLAccountPopulated(cacheTwoGLAccount: AdminSettingsCacheTwoGLAccountState, GLAccountForm?: GLAccountActiveForm) {
  return isCacheLevelPopulated<GLAccount>(
    cacheTwoGLAccount.GLAccount,
    MIN_REQUIRED_FIELDS_ADMIN_SETTINGS_GL_ACCOUNT,
    () => !GLAccountForm || (!!cacheTwoGLAccount.GLAccount && GLAccountForm.ID === cacheTwoGLAccount.GLAccount.ID),
  );
};