import {
  ApiSubmitActionsNumbersProducts,
  ClearErrorNumbersProduct, CommitProductNumbers,
  EventRegisterNumbersProductAdd,
  EventRegisterNumbersProductRemove,
  EventRegisterNumbersProductShowError
} from "./actions";
import {EventRegisterNumbersReset} from "../Main/actions";
import {ApiSubmitActionsNumbersClasses} from "../Classes/actions";
import {ApiSubmitActionsNumbersSpots} from "../Spots/actions";
import { ApiSubmitActionsNumbersCampsite } from '../Campsite/actions';
import { Reducer } from "redux";
import { isActionType } from "../../../../../../utils/StrongActions";

export interface NumbersWizardProductsState {
  isDirty?: boolean;
  SubmitErrorMessage?: string;
  isError?: boolean;
}

const getInitialState = () => ({
});

const NumbersWizardProducts: Reducer<NumbersWizardProductsState> = (state = getInitialState(), act) => {
  if (
    act.type === ApiSubmitActionsNumbersSpots.successType ||
    act.type === ApiSubmitActionsNumbersClasses.successType ||
    act.type === ApiSubmitActionsNumbersCampsite.successType ||
    act.type === ApiSubmitActionsNumbersProducts.successType
  ) {
    return getInitialState();
  } else if (isActionType(act, EventRegisterNumbersProductShowError)) {
    window.scrollTo(0, 0);
    return {...state, SubmitErrorMessage: act.error, isError: act.isError};
  } else if (isActionType(act, EventRegisterNumbersReset)) {
    return getInitialState();
  } else if (
    isActionType(act, EventRegisterNumbersProductAdd) || isActionType(act, EventRegisterNumbersProductRemove) ||
    isActionType(act, CommitProductNumbers)
  ) {
    return {...state, isDirty: true};
  } else if (isActionType(act, ClearErrorNumbersProduct)) {
    return {...state, SubmitErrorMessage: undefined};
  }
  return state;
};

export default NumbersWizardProducts;
