
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, Row, TextField, Column, ModalActions, Button, Alert, Loader,
} from '../../../../../../Elements';
import { actionCreators } from "../../../../../../../store/AdminCMSSite/Menus/Modals/PageMenuItem/actions";
import { actionCreators as appActionCreators } from "../../../../../../../store/App/actions";
import { actionCreators as menuHomeActionCreators } from "../../../../../../../store/AdminCMSSite/Menus/Home/actions";
import {
    actionCreators as adminCMSCacheOneActionCreators,
} from '../../../../../../../store/AdminCMSSite/CacheOne/actions';
import '../../../../../../../styles/pages/menus/modals/page-menu-item/index.scss';
import { ApplicationState } from '../../../../../../../store';
import { checkPageMenuPermission, IAdminCMSMenuRouterParams } from '../../../../../../../utils/helpers/adminCMSPageHelper';
import { actionCreators as adminCMSCacheTwoPageMenusActions} from "../../../../../../../store/AdminCMSSite/CacheTwoPageMenus/actions";
import { INVALID_MENU_ITEM } from '../../../../../../../constants/messages/adminCMS';
import AdminCMSCacheManager from '../../../../../../../utils/cacheManagers/adminCMSCacheManager';
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { makeFormModalPropSelector } from '../../../../../../../store/App';
import { generateDOMId } from '../../../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { ComponentUpdateTemplate } from '../../../../../../Templates/ComponentUpdateTemplate';
import {isAdminCMSCacheTwoPageMenuItemPopulated} from '../../../../../../../utils/cachePopulatedCheckers/adminCMS';
import {WithInertAttribute} from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--modals--page-menu-item-form';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminCMSMenuRouterParams, {}>
>;

@(withRouter as any)
class PageMenuItemFormModal extends ComponentUpdateTemplate<ConnectedProps, {}> {

  public props: ConnectedProps;

  componentDidMount() {
    super.loadAndSetData(
      this.props,
      () => {
        AdminCMSCacheManager.getInstance().loadCacheTwoPageMenuItem({
          props: this.props,
          isStateNavigated: true,
          isEdit: !!this.props.menuId,
          pageMenuIdFromModalProps: this.props.menuId,
        });
      }
    );
  }

  componentWillUnmount() {
    this.onClose();
  }

  onClose = () => {
    const {actions, adminCMSCacheTwoPageMenuItems} = this.props;
    if (isAdminCMSCacheTwoPageMenuItemPopulated(adminCMSCacheTwoPageMenuItems)) actions.clearAdminCMSCacheTwoPageMenuItem();
  };
  onSave = () => {
    checkPageMenuPermission(
      () => {
        this.props.actions.apiSubmitForm();
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };
  onDelete = () => {
    checkPageMenuPermission(
      () => {
        this.props.menuHomeActions.configDeletePageMenuItemModal(this.props.pageMenuItemForm.ActiveForm as any);
        this.props.actions.pushDeleteMenuItemModal();
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };

  onInactive = () => {
    const {actions} = this.props;
    actions.showTopFloatingAlert(INVALID_MENU_ITEM, true, 'orange');
  };

  public render() {
    const {apiSaving, actions, adminCMSCacheOne, adminCMSCacheTwoPageMenuItems, pageMenuItemForm: {ActiveForm, ValidationRules, SubmitErrorMessage}, inert, menuId} = this.props;
    const subtitle = adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.SiteDomain : '';

    const inactive = !!menuId && (adminCMSCacheTwoPageMenuItems.CMSPageMenuItem && adminCMSCacheTwoPageMenuItems.CMSPageMenuItem.Inactive);
    const loading = (!!menuId && !isAdminCMSCacheTwoPageMenuItemPopulated(adminCMSCacheTwoPageMenuItems, ActiveForm));

    return (
      <Modal
        inert={inert}
        big
        mobileFullScreen
        onClose={this.onClose}
        inactive={inactive}
        onInactive={() => this.onInactive()}
        className={`${namespace()} ${!!SubmitErrorMessage ? 'has-error' : ''}`}>
        <ModalHeader
          subtitle={subtitle}
          className={`${namespace()}--header`}>
          {!menuId ? 'New Menu' : 'Edit Menu'}
        </ModalHeader>

        <ModalContent hasPadding>
          {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
          {!loading && <Row>
            <Column span={12} mobileSpan={12}>
              <TextField
                id={generateDOMId("menu-name-field")}
                label="Menu Title"
                disabled={apiSaving > 0}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules.Name}
                value={ActiveForm.Name}/>
            </Column>
          </Row>}
          {loading && <Loader className={`${namespace()}--loader`} center />}
        </ModalContent>
        <ModalActions
          loading={apiSaving > 0}
          sticky
          notFixed
          left={!!menuId ? <Button id={generateDOMId("page-menu-modal-save-btn")} disabled={loading} flat textColor="black" onClick={this.onSave}>SAVE CHANGES</Button> : <Button flat textColor="black" onClick={this.onSave}>CREATE</Button>}
          right={!menuId ? undefined : (<Button disabled={loading} flat textColor="red" onClick={this.onDelete}>DELETE</Button>)}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const pageMenuIdSelector = makeFormModalPropSelector(ModalTypes.PAGE_MENU_ITEM_FORM, 'id');
  return {
    pageMenuItemForm: state.adminCMSSite.menus.modals.pageMenuItem,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheZero: state.cacheZero,
    adminCMSCacheTwoPageMenuItems: state.adminCMSSite.cacheTwoPageMenuItems,
    menuId: pageMenuIdSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = 
(dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...adminCMSCacheTwoPageMenusActions,
    ...adminCMSCacheOneActionCreators,
  }, dispatch),
  menuHomeActions: bindActionCreators({
    ...menuHomeActionCreators,
  }, dispatch),
});

const ConnectedPageMenuItemFormModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PageMenuItemFormModal);

export default ConnectedPageMenuItemFormModal;