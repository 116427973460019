
import { AnyAction, Reducer } from 'redux';

import {
  ManageOrderReset,
  SetInactiveItem,
  ShowManageOrder,
  SubmitManageOrderActions,
  SUFFIX
} from './actions';
import {FormDefinition} from "./validation";
import {apiValidationReducerCreator} from "../../../Validation/reducerCreator";
import {GetPrevOrder} from "../../../CacheThreePrevOrders/actions";
import {validateUpdateAction, WithRootState} from "../../../Validation/actionCreator";
import {PreviousOrder, PreviousOrderItem} from "../../../../models/api/cacheTwoPrevOrders";
import {AMOUNT_TOO_LOW} from "../../../../constants/messages/events";
import {convertObjToMoment} from "../../../../utils";
import {
  getAmountKey,
  getAppliedCreditKey,
  getInactiveKey,
  getInactiveWithIDKey,
  getTotalAmountKey,
} from "./uiHelpers";
import {
  deleteOrder,
  updateOrderTotal,
  findC3OrderItem,
  mergeItems,
  mergeOrder,
} from "./reducerHelpers";
import * as M from "../../../../constants/messages/generic";
import { ClearAllCache, ClearAllEndUserCacheButOptions, PopModal, PushModal, SetCacheAction } from '../../../App/actions';
import { ClearCacheBelowTwoPrevOrders } from '../../../CacheTwoPrevOrders/actions';
import { ClearCacheBelowOne } from '../../../CacheOne/actions';
import { isActionType } from '../../../../utils/StrongActions';
import { ModalTypes } from '../../../../utils/modalHelper';

export interface ManageOrderState {
  showManageOrder?: boolean;
    // Notes?: string;
    // DatePayment?: any;
    // Amount?: number;
    // NotApplied?: number;
    // AppliedCredit?: number;
  ActiveForm: any;
  /**
   * This field is to backup the ActiveForm when DeleteOrder modal is opened, so
   * that we can use it to rollback to a state where the order doesn't yet have delete
   * change apply to it.
   */
  ActiveFormBackup: any;
  ValidationRules: any;
  SubmitErrorMessage?: string;
  deleteMessage?: string;
}
const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const getInitialState = (): ManageOrderState => {
  return {
    ActiveForm: {},
    ActiveFormBackup: {},
    ValidationRules: {...FormDefinition}};
};

const updateForm = (
  newState: ManageOrderState,
  items: Array<PreviousOrderItem> | null | undefined,
  order: PreviousOrder
) => {
    newState.ActiveForm = {};
    newState.ValidationRules = {...FormDefinition};

    if (items) {
      items.forEach((item: PreviousOrderItem) => {
        const newRule = {
          key: getTotalAmountKey(item),
          decimalOnly: true,
          allowNegative: () => false,
          validatejs: {}
        };
        const newInactive = {
          key: getTotalAmountKey(item)
        };
        newRule.validatejs[getTotalAmountKey(item)] = {
          presence: {message: M.S_REQUIRED('Item amount')},
          numericality: {
            notValid: M.S_INVALID('Item amount'),
            notGreaterThanOrEqualTo: '^' + AMOUNT_TOO_LOW(0),
            greaterThanOrEqualTo: 0,
          }
        };

        // (newRule as any).customValidate = () => {
        //   const currentAmount = reduxStoreService().getState().settings.prevOrders.manageOrder.ActiveForm.Amount;
        //   const oldAmount = reduxStoreService().getState().cacheThreePrevOrders.PreviousOrder.Amount;
				//
        //   if (oldAmount < 0 && currentAmount > 0) {
        //     return 'The order amount cannot be more than $0.';
        //   }
        //   if (oldAmount > 0 && currentAmount < 0) {
        //     return 'The order amount cannot be less than $0.';
        //   }
        //   if (Math.abs(currentAmount) > Math.abs(oldAmount)) {
        //     return `^The order amount cannot be more than ${oldAmount}`;
        //   }
				//
        //   return undefined;
        // };

        newState.ValidationRules[getTotalAmountKey(item)] = newRule;
        newState.ActiveForm[getTotalAmountKey(item)] = item.TotalAmount;
        newState.ValidationRules[getInactiveKey(item)] = newInactive;
        newState.ActiveForm[getInactiveKey(item)] = false;
        newState.ValidationRules[getAmountKey(item)] = {key: getAmountKey(item)};
        newState.ActiveForm[getAmountKey(item)] = item.Amount;
        newState.ValidationRules[getAppliedCreditKey(item)] = {key: getAppliedCreditKey(item)};
        newState.ActiveForm[getAppliedCreditKey(item)] = item.AppliedCredit;
      });
    }
    newState.ActiveForm.Notes = order.Notes;
    newState.ActiveForm.DatePayment = order.DatePayment;
    newState.ActiveForm.Amount = order.Amount;
    newState.ActiveForm.NotApplied = order.NotApplied;
    newState.ActiveForm.AppliedCredit = order.AppliedCredit;
};


const ManageOrder: Reducer<ManageOrderState> = (oldState, action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, action);
  if (action.type === validateUpdateAction + SUFFIX || isActionType(action, SetInactiveItem)) {
    const newState = {...state};
    newState.ActiveForm = {...state.ActiveForm};
    if (isActionType(action, SetInactiveItem)) {
      newState.ActiveForm[getInactiveWithIDKey(action.OrderID)] = action.Inactive;
      if (action.Inactive) {
        const c3Item = findC3OrderItem(action.rootState, action.OrderID);
        if (c3Item) newState.ActiveForm[getTotalAmountKey(c3Item)] = c3Item.TotalAmount;
      }
    }

    const PreviousOrder = mergeOrder(action.rootState, newState.ActiveForm);
    const PreviousOrderItems = mergeItems(action.rootState, newState.ActiveForm);

    updateOrderTotal(action.rootState, PreviousOrder, PreviousOrderItems);
    newState.ActiveForm.Amount = PreviousOrder.Amount;
    newState.ActiveForm.NotApplied = PreviousOrder.NotApplied;
    newState.ActiveForm.AppliedCredit = PreviousOrder.AppliedCredit;

    if (PreviousOrderItems) {
      for (let i = 0; i < PreviousOrderItems.length; i++) {
        const item = PreviousOrderItems[i];
        newState.ActiveForm[getTotalAmountKey(item)] = item.TotalAmount;
        newState.ActiveForm[getAppliedCreditKey(item)] = item.AppliedCredit;
        newState.ActiveForm[getInactiveKey(item)] = item.Inactive;
        newState.ActiveForm[getAmountKey(item)] = item.Amount;
      }
    }
    return newState;
  } else if (
    action.type === GetPrevOrder.successType ||
    action.type === SubmitManageOrderActions.successType ||
    (isActionType(action, SetCacheAction) && action.actions.requestType === SubmitManageOrderActions.requestType)
  ) {
    const successAction = action as any;
    const items = successAction.response.response.PreviousOrderItems as Array<PreviousOrderItem>;
    const order = convertObjToMoment(successAction.response.response.PreviousOrder, ['DatePayment']) as PreviousOrder;
    const newState = {...state};
    
    updateForm(newState, items, order);
    if (action.type === SubmitManageOrderActions.successType) {
      newState.showManageOrder = false;
    }
    return newState;
  } else if (isActionType(action, ShowManageOrder)) {
    const {show, items, order} = action;

    const newState = {...state};
    newState.showManageOrder = show;

    if (show && order) {
      updateForm(newState, items, order);
    }

    return newState;
  } else if (isActionType(action, PushModal) && action.modal === ModalTypes.DELETE_ORDER) {

    const newState = {...state};
    const PreviousOrder = mergeOrder(action.rootState, newState.ActiveForm);
    const PreviousOrderItems = mergeItems(action.rootState, newState.ActiveForm);

    const deleteMessage = deleteOrder(action.rootState, PreviousOrder, PreviousOrderItems);
    newState.deleteMessage = deleteMessage;
    newState.ActiveFormBackup = state.ActiveForm;
    newState.ActiveForm = {...newState.ActiveForm};
    newState.ActiveForm.Amount = PreviousOrder.Amount;
    newState.ActiveForm.NotApplied = PreviousOrder.NotApplied;
    newState.ActiveForm.AppliedCredit = PreviousOrder.AppliedCredit;

    if (PreviousOrderItems) {
      for (let i = 0; i < PreviousOrderItems.length; i++) {
        const item = PreviousOrderItems[i];
        newState.ActiveForm[getTotalAmountKey(item)] = item.TotalAmount;
        newState.ActiveForm[getAppliedCreditKey(item)] = item.AppliedCredit;
        newState.ActiveForm[getInactiveKey(item)] = item.Inactive;
        newState.ActiveForm[getAmountKey(item)] = item.Amount;
      }
    }
    return newState;
  } else if (isActionType(action, PopModal) && action.modal === ModalTypes.DELETE_ORDER && action.saveBefore === false && action.saveAfter === true) {
    // NOTE: Only need to restore ActiveForm to its backup when popping the modal
    // without a save (i.e. saveBefore === false && saveAfter === true), because
    // when saving, this reducer will update form accordingly from the response.
    // See `SubmitManageOrderActions.successType` above.
    const newState = {...state};

    newState.ActiveForm = {...state.ActiveFormBackup};
    newState.ActiveFormBackup = {};

    return newState;
  } else if (
    isActionType(action, ManageOrderReset) ||
    isActionType(action, ClearCacheBelowTwoPrevOrders) ||
    isActionType(action, ClearAllCache) ||
    isActionType(action, ClearAllEndUserCacheButOptions) ||
    isActionType(action, ClearCacheBelowOne)
  ) {
    return {
      ...getInitialState()
    };
  }

  return state || { ...getInitialState()};
};

export default ManageOrder;
