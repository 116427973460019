import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra
} from '../../../../Validation/actionCreator';
import type { ActionCreator, ApplicationState } from '../../../../';
import { SaveState } from '../../../../../store/Rollback/actions';
import { scrollModalToTop } from '../../../../../utils/helpers/adminCMSPageHelper';
import { ADMIN_FACILITY_MODULE_SUFFIX } from '../../../../../store/AdminFacilityLocation/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { convertTimeDigit } from '../../../../../utils/dateHelper';
import { getUpdateFacilityTypeBody, getFacilityTypesRootUrl } from '../../../../../constants/urls';
import { navPush } from '../../../../../utils';
import { SectionCreator } from '../../../../../utils/dataHelper';
import { ADMIN_FACILITY_LOCATION_FACILITY_EDIT_PATH } from '../../../../../routes';
import { ClearAdminFacilityLocationCacheTwoFacilityType } from '../../../../../store/AdminFacilityLocation/CacheTwoFacilityTypes/actions';
import { ModalTypes, isModalOpened } from '../../../../../utils/modalHelper';
import { UPDATE_FACILITY_FACILITY_TYPE_FORM_SUFFIX } from '../../../../../utils/suffix';
import { PlainRoute } from 'react-router';
import { typeName, Action } from '../../../../../utils/StrongActions';

export enum FacilityTypeFormSection {
  GENERAL=1,
  DESCRIPTION,
  ATTRIBUTES,
  BOOKING_TIMES,
}

export const SUFFIX = ADMIN_FACILITY_MODULE_SUFFIX + UPDATE_FACILITY_FACILITY_TYPE_FORM_SUFFIX + '__FACILITY_TYPE_FORM';
export const DEL_SUFFIX = ADMIN_FACILITY_MODULE_SUFFIX + UPDATE_FACILITY_FACILITY_TYPE_FORM_SUFFIX + '__DELETE_FACILITY_TYPE';

export const FacilityTypeSharedSettingsModalNmespace = (): string => 'pages--facility-location--modals--facility-type--shared-settings';
export const FacilityTypeFormModalNamespace = (): string => 'pages--admin-facility-location--modal--facility-type-form';

const getSubmitFormExtra = (routes, getState) => {
  const route = routes[routes.length - 1];
  const state = getState();
  
  return getCacheLevelExtra(
    route.path !== ADMIN_FACILITY_LOCATION_FACILITY_EDIT_PATH,
    !!state.adminFacilityLocation.cacheTwoFacilityTypes.FacilitiesType,
  );
};
@typeName('DELETE_ATTRIBUTE' + SUFFIX)
export class DeleteAttribute extends Action {
  constructor(public index: number, public restore?: boolean) { super(); }
}
@typeName('NEW_ATTRIBUTE' + SUFFIX)
export class NewAttribute extends Action {}
@typeName('CLOSE_CONFIRMATION' + SUFFIX)
export class CloseConfirmation extends Action {}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const GeneralValidateActions = createValidateActions(SUFFIX + '__GENERAL');
export const GeneralApiValidateActions = createApiValidateActions(SUFFIX + '__GENERAL');
export const BookingTimesValidateActions = createValidateActions(SUFFIX + '__BOOKING_TIMES');
export const BookingTimesApiValidateActions = createApiValidateActions(SUFFIX + '__BOOKING_TIMES');
export const AttributesValidateActions = createValidateActions(SUFFIX + '__ATTRIBUTES');
export const AttributesApiValidateActions = createApiValidateActions(SUFFIX + '__ATTRIBUTES');

export const UpdateFacilityTypeSubmitActions = createApiSubmitActions(SUFFIX);
export const UpdateFacilityTypeSubmitGeneralActions = createApiSubmitActions(SUFFIX + '__GENERAL');
export const UpdateFacilityTypeSubmitBookingTimesActions = createApiSubmitActions(SUFFIX + '__BOOKING_TIMES');
export const UpdateFacilityTypeSubmitAttributesActions = createApiSubmitActions(SUFFIX + '__ATTRIBUTES');
export const DeleteFacilityTypeSubmitActions = createApiSubmitActions(DEL_SUFFIX);

const GeneralSectionKeys = {
  Name: true,
  NamePlural: true,
  ShowAsID: true,
  AllowOverlapping: true,
  PropertyMapURL: true,
  AdditionalNotify1_Name: true,
  AdditionalNotify1_Email: true,
  AdditionalNotify2_Name: true,
  AdditionalNotify2_Email: true,
};

const DescriptionSectionKeys = {
  Description: true,
};

const BookingTimesSectionKeys = {
  IsCheckoutNextDay: true,
};

const formCreator = (rootState: ApplicationState, body: any, section?: FacilityTypeFormSection) => {
  const facilityTypeForm = rootState.adminFacilityLocation.facilities.facilityType.form;
  const facilityTypeCache = rootState.adminFacilityLocation.cacheTwoFacilityTypes.FacilitiesType;

  body.ID = rootState.adminFacilityLocation.cacheTwoFacilityTypes.FacilitiesType ? rootState.adminFacilityLocation.cacheTwoFacilityTypes.FacilitiesType.FacilityTypeRow.ID : undefined;
  if (!section || section === FacilityTypeFormSection.GENERAL) {
    body.General = [];

    if (body.ID && facilityTypeCache) {
      body.General.push(SectionCreator(facilityTypeCache.General, GeneralSectionKeys, true));
    }
    body.General.push(SectionCreator(facilityTypeForm.General.ActiveForm, GeneralSectionKeys));
  }

  if (!section || section === FacilityTypeFormSection.DESCRIPTION) {
    body.Description = [];

    if (body.ID && facilityTypeCache) {
      body.Description.push(SectionCreator(facilityTypeCache.FacilityTypeRow, DescriptionSectionKeys, true));
    }
    body.Description.push(SectionCreator(facilityTypeForm.FacilityTypeRow.ActiveForm, DescriptionSectionKeys));
  }

  if (!section || section === FacilityTypeFormSection.BOOKING_TIMES) {
    body.BookingTimes = [];
    if (body.ID && facilityTypeCache) {
      body.BookingTimes.push({
        ...SectionCreator(facilityTypeCache.BookingTimes, BookingTimesSectionKeys, true),
        CheckinTime: facilityTypeCache.BookingTimes.CheckinTime,
        CheckoutTime: facilityTypeCache.BookingTimes.CheckoutTime,
      });
    }
    const BookingTimesActiveForm = facilityTypeForm.BookingTimes.ActiveForm;
    body.BookingTimes.push({
      ...SectionCreator(facilityTypeForm.BookingTimes.ActiveForm, BookingTimesSectionKeys),
      CheckinTime: `${convertTimeDigit(BookingTimesActiveForm.StartHour, BookingTimesActiveForm.StartPeriod)}:${convertTimeDigit(BookingTimesActiveForm.StartMin)}:00`,
      CheckoutTime: `${convertTimeDigit(BookingTimesActiveForm.EndHour, BookingTimesActiveForm.EndPeriod)}:${convertTimeDigit(BookingTimesActiveForm.EndMin)}:00`,
    });
  }

  if (!section || section === FacilityTypeFormSection.ATTRIBUTES) {
    const AttributesActiveForm = facilityTypeForm.Attributes.ActiveForm;
    body.Attributes = facilityTypeForm.Attributes.Values.filter((attr, index) => {
      const newName = AttributesActiveForm[`AttributeName${index}`];
      const newInactive = AttributesActiveForm[`AttributeInactive${index}`];
      return attr.ID !== -1 || (newName && !newInactive);
    }).map((attr, index) => {
      const newName = AttributesActiveForm[`AttributeName${index}`];
      return {
        ID: attr.ID === -1 ? null : attr.ID,
        Name: newName,
        Inactive: !!newName ? AttributesActiveForm[`AttributeInactive${index}`] : true,
        TS: attr.TS ? attr.TS : null,
      };
    });
  }

  if (rootState.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility) {
    body.AppState.FacilityID = rootState.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility.ID;
  }

  return body;
};

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateFacilityTypeRowValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminFacilityLocation.facilities.facilityType.form.FacilityTypeRow),
  updateFacilityTypeGeneralValue: createUpdateValueMethod(GeneralValidateActions, GeneralApiValidateActions, (s: ApplicationState) => s.adminFacilityLocation.facilities.facilityType.form.General),
  updateFacilityTypeBookingTimesValue: createUpdateValueMethod(BookingTimesValidateActions, BookingTimesApiValidateActions, (s: ApplicationState) => s.adminFacilityLocation.facilities.facilityType.form.BookingTimes),
  updateFacilityTypeAttributesValue: createUpdateValueMethod(AttributesValidateActions, AttributesApiValidateActions, (s: ApplicationState) => s.adminFacilityLocation.facilities.facilityType.form.Attributes),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  simpleUpdateGeneral: createSimpleUpdateValueMethod(GeneralValidateActions),
  simpleUpdateBookingTimes: createSimpleUpdateValueMethod(BookingTimesValidateActions),
  simpleUpdateAttributes: createSimpleUpdateValueMethod(AttributesValidateActions),
  apiSubmitForm: (routes: any, router?: any, section?: FacilityTypeFormSection): ActionCreator => (dispatch, getState) => {
    const state = getState();
    // form validation (not including file)
    const validGeneral = (!section || section === FacilityTypeFormSection.GENERAL) ? innerApiSubmitFormMethod(
      dispatch,
      UpdateFacilityTypeSubmitGeneralActions,
      (s: ApplicationState) => s.adminFacilityLocation.facilities.facilityType.form.General,
      undefined,
      undefined,
      !!section,
      true,
      section === FacilityTypeFormSection.GENERAL ? undefined : true,
      undefined,
    ) : true;
    const validRow = (!section || section === FacilityTypeFormSection.DESCRIPTION) ? innerApiSubmitFormMethod(
      dispatch,
      UpdateFacilityTypeSubmitActions,
      (s: ApplicationState) => s.adminFacilityLocation.facilities.facilityType.form.FacilityTypeRow,
      undefined,
      undefined,
      !!section,
      true,
      section === FacilityTypeFormSection.DESCRIPTION ? undefined : true,
      undefined,
    ) : true;
    const validBookingTimes = (!section || section === FacilityTypeFormSection.BOOKING_TIMES) ? innerApiSubmitFormMethod(
      dispatch,
      UpdateFacilityTypeSubmitBookingTimesActions,
      (s: ApplicationState) => s.adminFacilityLocation.facilities.facilityType.form.BookingTimes,
      undefined,
      undefined,
      !!section,
      true,
      section === FacilityTypeFormSection.BOOKING_TIMES ? undefined : true,
      undefined,
    ) : true;
    const validAttributes = (!section || section === FacilityTypeFormSection.ATTRIBUTES) ? innerApiSubmitFormMethod(
      dispatch,
      UpdateFacilityTypeSubmitAttributesActions,
      (s: ApplicationState) => s.adminFacilityLocation.facilities.facilityType.form.Attributes,
      undefined,
      undefined,
      !!section,
      true,
      undefined,  // use the last validation to perform save state
      undefined,
    ) : true;
    
    if (validGeneral && validRow && validBookingTimes && validAttributes) {
      const isFacilityTypeFormModalOpened = isModalOpened(ModalTypes.FACILITY_TYPE_FORM);
      const submitFormBody = formCreator(getState(), getUpdateFacilityTypeBody(), section);
      const formExtra = getSubmitFormExtra(routes, getState);

      if (section || isFacilityTypeFormModalOpened) {
        if (section) {
          dispatch(appActionCreators.popModal(false, false, ModalTypes.FACILITY_TYPE_SHARED_SETTINGS) as any);
        } else {
          dispatch(appActionCreators.popModal(false, false, ModalTypes.FACILITY_TYPE_FORM) as any);
        }
      } else if (state.adminFacilityLocation.cacheOne.FacilitiesLocation) {
        navPush(router, getFacilityTypesRootUrl({
          locationId: state.adminFacilityLocation.cacheOne.FacilitiesLocation.ID,
          locationName: state.adminFacilityLocation.cacheOne.FacilitiesLocation.Name,
        }));
      }
      dispatch(UpdateFacilityTypeSubmitActions.request(submitFormBody, formExtra));

      // Only clear cache when submitting `FACILITY_TYPE_FORM` because SharedSettings modal
      // doesn't require a clear cache, and FacilityType form page will clear cache on its unmount
      if (isFacilityTypeFormModalOpened && !!getState().adminFacilityLocation.cacheTwoFacilityTypes.FacilitiesType) dispatch(new ClearAdminFacilityLocationCacheTwoFacilityType());
      return true;
    } else {
      if (section) {
        scrollModalToTop(FacilityTypeSharedSettingsModalNmespace());
      } else if (isModalOpened(ModalTypes.FACILITY_TYPE_FORM)) {
        scrollModalToTop(FacilityTypeFormModalNamespace());
      }
      return false;
    }
  },
  deleteFacilityType: (routes: PlainRoute<any>[], router: any, facilityTypeId: number, restore?: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const state = getState();

    if (isModalOpened(ModalTypes.DELETE_FACILITY_TYPE)) {
      dispatch(appActionCreators.popModal(false, false, ModalTypes.DELETE_FACILITY_TYPE) as any);
    } else {
      const location = state.adminFacilityLocation.cacheOne.FacilitiesLocation;
      
      if (location) {
        navPush(router, getFacilityTypesRootUrl({
          locationId: location.ID,
          locationName: location.Name,
        }));
      }
    }
    const route = routes[routes.length - 1];
    const body = getUpdateFacilityTypeBody();
    dispatch(DeleteFacilityTypeSubmitActions.request(
      {
        ...body,
        ID: facilityTypeId,
        Inactive: !restore,
      },
      getSubmitFormExtra(routes, getState),
      route,
    ));
  },
  initFacilityTypeForm: (): ActionCreator => dispatch => {
    dispatch(UpdateFacilityTypeSubmitActions.updateForm());
    dispatch(new SaveState());
  },
  deleteAttribute: (index: number, restore?: boolean) => dispatch => dispatch(new DeleteAttribute(index, restore)),
  newAttribute: () => dispatch => dispatch(new NewAttribute()),
  closeConfirmation: (): ActionCreator => dispatch => dispatch(new CloseConfirmation()),
};