import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminCMSCacheOneContext, AdminCMSCacheTwoContactContext } from "@tentaroo/shared";

import { ApplicationState } from '../../../../../../store';
import {
    actionCreators as cacheZeroActionCreators
} from '../../../../../../store/CacheZero/actions';
import {
    actionCreators as adminCMSCacheOneActionCreators, GetSiteCache,
} from '../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as cacheOneActions} from "../../../../../../store/CacheOne/actions";
import { actionCreators } from "../../../../../../store/AdminCMSSite/Contacts/Home/actions";
import { LoadingAll, EmptyMessage, Button, SimpleList, Loader } from '../../../../../Elements';
import { Main, MainContent } from '../../../../../Layouts';
import { PeopleContactIcon } from '../../../../../Icons';
import { navPush } from '../../../../../../utils';
import { getAddContactUrl, getEditContactUrl, getContactPreviewUrl, getContactsRootUrl, constructCMSSiteUrlParams, constructCMSSiteContactUrlParams } from '../../../../../../constants/urls';
import '../../../../../../styles/pages/contacts/home/index.scss';
import { CMSContact, ContactCategory } from '../../../../../../models/api/adminCMSCacheOne';
import ContactCard, { AdminCMSContactCardType } from '../../../../../Elements/AdminContact/CMSCard';
import ContactsFilters from './ContactFilters';
import { makeFilteredContactsSelector, makeGroupedContactsSelector, activeContactsSelector } from '../../../../../../store/AdminCMSSite/Contacts/Home';
import ContactPreview from './ContactPreview';
import { ADMIN_CMS_CONTACTS_PATH, ADMIN_CMS_CONTACT_PREVIEW_PATH } from '../../../../../../routes';
import {
    actionCreators as adminCMSCacheTwoContactsActionCreators,
} from '../../../../../../store/AdminCMSSite/CacheTwoContacts/actions';
import { actionCreators as contactFormActionCreators} from "../../../../../../store/AdminCMSSite/Contacts/Contact/Form/actions";
import { checkContactPermission, IAdminCMSContactRouterParams } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { EmptyMessageType } from '../../../../../../components/Elements/EmptyMessage';
import { CardCategory } from '../../../../../../components/Elements/Card';
import { CONTACTS, ENTITY_NOT_ADDED, ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import AdminCMSCacheManager from '../../../../../../utils/cacheManagers/adminCMSCacheManager';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import { SaveState } from '../../../../../../store/Rollback/actions';
import { noOpenedModals } from '../../../../../../utils/modalHelper';
import { reduxStoreService } from '../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isAdminCMSCacheOnePopulated} from '../../../../../../utils/cachePopulatedCheckers/adminCMS';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--contacts';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminCMSContactRouterParams, {}>
>;

class Contacts extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;

  public componentDidMount() {
    this.props.actions.showAdminPageHeader(true);
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        const {route} = this.props;

        if (route.path === ADMIN_CMS_CONTACT_PREVIEW_PATH) {
          AdminCMSCacheManager.getInstance().loadCacheTwoContact({
            props: this.props,
            isStateNavigated,
            isEdit: true,
            context: AdminCMSCacheTwoContactContext.CONTACT_PREVIEW,
          });
        } else {
          AdminCMSCacheManager.getInstance().loadAdminCMSCacheOne({
            props: this.props,
            isStateNavigated,
            context: AdminCMSCacheOneContext.CONTACTS_LIST,
          });
        }
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    const {routes} = this.props;
    const thisRoute = routes[routes.length - 1];
    const prevRoute = prevProps.routes[prevProps.routes.length - 1];
    const thisPath = thisRoute.path;
    const prevPath = prevRoute.path;

    if (shouldBlockActions()) return;

    if (
      thisPath !== prevPath &&
      thisPath === ADMIN_CMS_CONTACTS_PATH &&
      prevPath !== ADMIN_CMS_CONTACTS_PATH
    ) {
      reduxStoreService().dispatch(new SaveState());
    }
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        const {routes} = nextProps;
        const nextPath = routes[routes.length - 1].path;
    
        if (nextPath === ADMIN_CMS_CONTACT_PREVIEW_PATH) {
          AdminCMSCacheManager.getInstance().loadCacheTwoContact({
            props: nextProps,
            isStateNavigated,
            isEdit: true,
            context: AdminCMSCacheTwoContactContext.CONTACT_PREVIEW,
          });
        } else {
          AdminCMSCacheManager.getInstance().loadAdminCMSCacheOne({
            props: nextProps,
            isStateNavigated,
            context: nextPath !== ADMIN_CMS_CONTACT_PREVIEW_PATH ? AdminCMSCacheOneContext.CONTACTS_LIST : undefined,
          });
        }
      }
    );
  }

  componentWillUnmount() {
  }

  onContactEditClick = (contact: CMSContact) => {
    checkContactPermission(
      () => {
        const editContactUrl = getEditContactUrl(constructCMSSiteContactUrlParams(this.props, this.props.adminCMSCacheOne, contact));
        navPush(this.props.router, editContactUrl);
      },
      this.props.cacheZero,
      this.props.adminCMSCacheOne,
      contact.SiteID,
    );
  };
  onContactPreviewClick = (contact: CMSContact) => {
    const contactPreviewUrl = getContactPreviewUrl(constructCMSSiteContactUrlParams(this.props, this.props.adminCMSCacheOne, contact));
    navPush(this.props.router, contactPreviewUrl);
  };
  onContactRestoreClick = (contact: CMSContact) => {
    const {routes, router} = this.props;
    checkContactPermission(
      () => {
        this.props.contactFormActions.deleteContact(contact.ID, router, routes, true);
      },
      this.props.cacheZero,
      this.props.adminCMSCacheOne,
      contact.SiteID,
    );
  };
  onContactDeleteClick = (contact: CMSContact) => {
    const {routes, router} = this.props;
    checkContactPermission(
      () => {
        this.props.contactFormActions.deleteContact(contact.ID, router, routes);
      },
      this.props.cacheZero,
      this.props.adminCMSCacheOne,
      contact.SiteID,
    );
  };

  onNewContact = (e) => {
    const route = this.props.routes[this.props.routes.length - 1];

    // dont respond when we are previewing contact
    if (route.path === ADMIN_CMS_CONTACT_PREVIEW_PATH) return;
    checkContactPermission(
      () => {
        const newContactUrl =  getAddContactUrl(constructCMSSiteUrlParams(this.props, this.props.adminCMSCacheOne));
        navPush(this.props.router, newContactUrl);
      },
      this.props.cacheZero,
      this.props.adminCMSCacheOne,
    );
  };

  renderEmptyMessage = (emptyMessage) => {
    return (
      <EmptyMessage
        icon={PeopleContactIcon}
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={emptyMessage} 
        actions={<Button id={generateDOMId("admin-contact-add-btn")} className={`${namespace()}--empty-message-btn`} color="green" onClick={this.onNewContact}>NEW CONTACT</Button>}/>
    );
  };

  onFilterClick = () => {
    this.props.actions.toggleExpandFilter(!this.props.contactsHome.expandFilter);
  };

  onClickMain = (e) => {
    const { routes, router, adminCMSCacheOne} = this.props;
    const thisRoute = routes[routes.length - 1];

    if (reduxStoreService().getState().app.apiSaving <= 0 && adminCMSCacheOne.CMSSite) {
      if (thisRoute.path === ADMIN_CMS_CONTACT_PREVIEW_PATH) {
        this.props.actions.clearAdminCMSSiteCacheBelowOne();
        const backUrl = getContactsRootUrl(constructCMSSiteUrlParams(this.props, this.props.adminCMSCacheOne));
        navPush(router, backUrl);
      }
    }
  };
  groupedContactsRenderer = (index) => {
    const {groupedContacts} = this.props;

    if (!groupedContacts[index]) {
      // render an empty paragraph at last (the last item will be `null`)
      return <div className={`${namespace()}--list-wrapper--group-title`} key={`conatct_null_${index}`}></div>;
    } else if (groupedContacts[index].CategoryID) {
      const item = groupedContacts[index] as CMSContact;
      return (
        <ContactCard
          key={`grouped_contact_${index}`}
          onRestore={this.onContactRestoreClick}
          onEdit={this.onContactEditClick}
          onPreview={this.onContactPreviewClick}
          onDelete={this.onContactDeleteClick}
          routes={this.props.routes}
          adminCMSCacheOne={this.props.adminCMSCacheOne}
          cardCategory={CardCategory.LIST}
          type={AdminCMSContactCardType.LIST}
          contact={item} />
      );
    } else {
      const item = groupedContacts[index] as ContactCategory;
      return <div className={`${namespace()}--list-wrapper--group-title`} key={`grouped_contact_${index}`}>{item.Name}</div>;
    }
  };

  filteredContactsRenderer = (index) => {
    const {filteredContacts} = this.props;

    if (!filteredContacts[index]) {
      return <div className={`${namespace()}--list-wrapper--group-title`} key={`filtered_contact_${index}`}></div>;
    }

    return (
        <div className={`filtered_contact_${index}`}>
          <ContactCard
            onRestore={this.onContactRestoreClick}
            onEdit={this.onContactEditClick}
            onPreview={this.onContactPreviewClick}
            onDelete={this.onContactDeleteClick}
            routes={this.props.routes}
            adminCMSCacheOne={this.props.adminCMSCacheOne}
            type={AdminCMSContactCardType.LIST}
            cardCategory={CardCategory.LIST}
            contact={filteredContacts[index]} />
        </div>
    );
  };

  renderContactsContent = () => {
    const {contactsHome, filteredContacts, groupedContacts} = this.props;

    if (contactsHome.ActiveForm.SelectedCategoryID === 0) {
      return (
        <div className={`${namespace()}--list-wrapper`} style={{marginTop: '-44px'}}>
          <SimpleList
            itemRenderer={this.groupedContactsRenderer}
            totalLength={groupedContacts.length}
            threshold={200}
          />
        </div>
      );
    } else {
      return (
        <div className={`${namespace()}--list-wrapper`}>
          <SimpleList
            itemRenderer={this.filteredContactsRenderer}
            totalLength={filteredContacts.length}
            threshold={200}
          />
        </div>
      );
    }
  };

  public render() {
    const {routes, activeContacts, actions, adminCMSCacheOne, contactsHome, apiLoadingMap, groupedContacts, apiSaving, filteredContacts, inert} = this.props;
    if (!isAdminCMSCacheOnePopulated()) {
        return <LoadingAll />;
    }

    let emptyMessage;
    if (activeContacts.length === 0 && (contactsHome.ActiveForm.SelectedCategoryID === 0 ? groupedContacts.length === 0 : filteredContacts.length === 0)) {
      emptyMessage = ENTITY_NOT_ADDED(CONTACTS);
    } else if (contactsHome.ActiveForm.SelectedCategoryID === 0 && groupedContacts.length === 0) {
      emptyMessage = ENTITY_NOT_FOUND(CONTACTS);
    } else if (contactsHome.ActiveForm.SelectedCategoryID && contactsHome.ActiveForm.SelectedCategoryID > 0 && filteredContacts.length === 0) {
      emptyMessage = ENTITY_NOT_FOUND(CONTACTS);
    }
    const thisRoute = routes[routes.length - 1];

    const refreshingList = apiLoadingMap[GetSiteCache.requestType] && noOpenedModals();
    const saving = apiSaving > 0 && noOpenedModals();

    return (
      <Main inert={inert} isLoading={refreshingList} onClick={this.onClickMain} isCMSSite>
        <MainContent className={namespace()} handleCompact>
          <div className={`${namespace()}--list-actions`}>
            <ContactsFilters
              disabled={apiSaving > 0}
              ActiveForm={contactsHome.ActiveForm}
              ValidationRules={contactsHome.ValidationRules}
              preventActions={thisRoute.path === ADMIN_CMS_CONTACT_PREVIEW_PATH}
              reduxActions={actions}
              expand={contactsHome.expandFilter}
              newButton={
                <Button id={generateDOMId("admin-contact-add-btn")} className='control' color='green' textColor='white' onClick={this.onNewContact}>New</Button>
              } />
          </div>
          {!emptyMessage && this.renderContactsContent()}
          {emptyMessage && this.renderEmptyMessage(emptyMessage)}
          {thisRoute.path === ADMIN_CMS_CONTACT_PREVIEW_PATH && <ContactPreview />}
        </MainContent>
        {saving && <Loader className={`${namespace()}--loader`} center />}
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredContactsSelector = makeFilteredContactsSelector();
  const groupedContactsSelector = makeGroupedContactsSelector();
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    adminCMSCacheTwoContact: state.adminCMSSite.cacheTwoContacts,
    cacheZero: state.cacheZero,
    cacheOne: state.cacheOne,
    contactsHome: state.adminCMSSite.contacts.home,
    filteredContacts: filteredContactsSelector(state),
    groupedContacts: groupedContactsSelector(state),
    activeContacts: activeContactsSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheZeroActionCreators,
    ...appActionCreators,
    ...cacheOneActions,
    ...adminCMSCacheOneActionCreators,
    ...adminCMSCacheTwoContactsActionCreators,
  }, dispatch),
  contactFormActions: bindActionCreators({
    ...contactFormActionCreators,
  }, dispatch),
});

const ConnectedContacts = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(Contacts);

export default withRouter<{}>(ConnectedContacts);
