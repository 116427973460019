import { IValidator, FormDefinition } from "./validation";
import { SUFFIX, SiteMenuItemInitForEditPageForm, PAGE_MENU_SUFFIX, UpdateSelectedPage, UPDATE_CMS_SITE_MENU_FORM_SUFFIX, UPDATE_CMS_PAGE_MENU_FORM_SUFFIX } from "./actions";
import { apiValidationReducerCreator, shouldSkipUpdateForm } from "../../../../../store/Validation/reducerCreator";
import { APISuccess, APIUpdateFormSubmit, WithRootState } from "../../../../../store/Validation/actionCreator";
import { ClearAdminCMSCacheTwoSiteMenuItem } from "../../../CacheTwoSiteMenus/actions";
import { CMSPage, LinkTypeValue } from "../../../../../models/api/adminCMSCacheOne";
import { ClearAdminCMSCacheTwoPageMenuItem } from "../../../../../store/AdminCMSSite/CacheTwoPageMenus/actions";
import { setDefaults } from "../../../../../utils/validator";
import { SetCacheAction } from "../../../../../store/App/actions";
import { UPDATE_SITE_MENU_ITEM_SUFFIX } from "../../../../../store/AdminCMSSite/Pages/Page/Form/actions";
import { ADMIN_CMS_MODULE_SUFFIX, ClearAdminCMSSiteCacheBelowOne } from "../../../../../store/AdminCMSSite/CacheOne/actions";
import { API_UPD } from "../../../../../constants/request";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../../utils/StrongActions";

export interface SiteMenuItemActiveForm {
  ID?: number;
  LinkTypeID?: number;

  IsLinkNewWindow?: boolean;
  PageID?: number;
  EventCategoryID?: number;
  MenuLevel?: number;
  DistrictIDi?: number;
  ResourceID?: number;
  ParentID?: number;
  ExternalURL?: string;
  Name?: string;
  Title?: string;
  MenuStyle?: string;
  IsAlignRight?: boolean;
  IsPrimaryMenu?: boolean;
  Ord?: number;
  SelectedPage?: CMSPage;

  TS?: number;
}

export interface SiteMenuItemFormState {
  ActiveForm: SiteMenuItemActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
  isSiteMenu?: boolean;
}

const getInitialState = () => {
  return {
    ActiveForm: {
      IsAlignRight: false,
      IsPrimaryMenu: false,
      LinkTypeID: LinkTypeValue.NO_LINK,
    },
    ValidationRules: { ...FormDefinition },
  };
};

// this reducer is used in three places: SiteMenuItem, PageMenuItem, SiteMenu in EditPage, and so we
// need to respond to the corresponding validation in these places
const checkApiValidation = apiValidationReducerCreator(SUFFIX);
const siteMenuCheckApiValidation = apiValidationReducerCreator(SUFFIX + UPDATE_CMS_SITE_MENU_FORM_SUFFIX);
const pageMenuCheckApiValidation = apiValidationReducerCreator(PAGE_MENU_SUFFIX + UPDATE_CMS_PAGE_MENU_FORM_SUFFIX);
const editPageSiteMenuCheckApiValidation = apiValidationReducerCreator(UPDATE_SITE_MENU_ITEM_SUFFIX);

const SiteMenuItemFormReducer: Reducer<SiteMenuItemFormState> = (s, act: WithRootState<AnyAction>) => {
  const state = editPageSiteMenuCheckApiValidation(pageMenuCheckApiValidation(siteMenuCheckApiValidation(checkApiValidation(s, act), act), act), act);

  if ((isActionType(act, SetCacheAction) && act.actions.requestType.includes(ADMIN_CMS_MODULE_SUFFIX))) {
    // Keep this to handle 409 under EditPage -> Edit PageSiteMenuItem
    const action = <APISuccess>act;
    const res = action.response.xhr.response;

    if ('CMSPageSiteMenuItems' in res) {
      // This would only happen on 409 under EditPage -> Edit PageSiteMenuItem
      // Otherwise, don't need to update state, since the side modal will be closed
      const item = res.CMSPageSiteMenuItems ? res.CMSPageSiteMenuItems.find((item) => item.ID === state.ActiveForm.ID) : null;
      const rootState = act.rootState;
      if (item && rootState.adminCMSSite.cacheTwoPage.CMSPage) {
        return {
          ...state,
          ActiveForm: {
            ...item,
            MenuLevel: item.ParentID ? 2 : 1,
            PageID: rootState.adminCMSSite.cacheTwoPage.CMSPage.ID,
          },
        };
      }
    }
    return state;
  } else if (isActionType(act, UpdateSelectedPage)) {
    const rootState = act.rootState;
    if (!rootState.adminCMSSite.pages.modals.pageType.pages) return state;
    const selectedPage = rootState.adminCMSSite.pages.modals.pageType.pages.find((p) => p.ID === act.pageId);

    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedPage: selectedPage,
      },
    };
  } else if (isActionType(act, SiteMenuItemInitForEditPageForm)) {
    const rootState = act.rootState;
    if (act.menuItemId && rootState.adminCMSSite.pages.page.form.ActiveForm.CMSPageSiteMenuItems) {
      const menuItem = rootState.adminCMSSite.pages.page.form.ActiveForm.CMSPageSiteMenuItems.find((m) => m.ID === act.menuItemId);

      const newState = setDefaults(act.rootState, {...state}, state);
      if (menuItem && rootState.adminCMSSite.cacheTwoPage.CMSPage) {
        return {
          ...newState,
          ActiveForm: {
            ...newState.ActiveForm,
            ...menuItem,
            MenuLevel: menuItem.ParentID ? 2 : 1,
            PageID: rootState.adminCMSSite.cacheTwoPage.CMSPage.ID,
          },
          isSiteMenu: true,
        };
      }
    }
    const initState = getInitialState();
    const newState = setDefaults(act.rootState, initState, state);
    return {
      ...newState,
      ActiveForm: {
        ...newState.ActiveForm,
        LinkTypeID: LinkTypeValue.PAGE,
        PageID: rootState.adminCMSSite.cacheTwoPage.CMSPage ? rootState.adminCMSSite.cacheTwoPage.CMSPage.ID: 0,
        isSiteMenu: true,
      },
    };
  } else if (act.type.includes(API_UPD) && (act.type.includes(UPDATE_CMS_SITE_MENU_FORM_SUFFIX) || act.type.includes(UPDATE_CMS_PAGE_MENU_FORM_SUFFIX))) {
    if (shouldSkipUpdateForm(act)) return state;
    const rootState = act.rootState;
    
    // ParentID for new PageMenu item with MenuLevel === 2 will be set when clicking the `New` Button in UI, so we should NOT override it if it is set
    let ParentID;
    const action = <APIUpdateFormSubmit> act;
    if (action.type.includes(UPDATE_CMS_PAGE_MENU_FORM_SUFFIX) && action.extra) {
      ParentID = action.extra.ParentID;
    }
    const newState = setDefaults(act.rootState, getInitialState());
    if (ParentID) {
      newState.ActiveForm.ParentID = ParentID;
      newState.ActiveForm.MenuLevel = 2;

      if (!newState.ActiveForm.LinkTypeID || newState.ActiveForm.LinkTypeID === LinkTypeValue.NO_LINK) {
        newState.ActiveForm.LinkTypeID = LinkTypeValue.PAGE;
      }
    }

    let menuItem;

    if (act.type.includes(UPDATE_CMS_SITE_MENU_FORM_SUFFIX)) {
      menuItem = rootState.adminCMSSite.cacheTwoSiteMenuItems.CMSSiteMenuItem;
    } else if (act.type.includes(UPDATE_CMS_PAGE_MENU_FORM_SUFFIX)) {
      menuItem = rootState.adminCMSSite.cacheTwoPageMenuItems.CMSPageMenuItem;
    }

    if (menuItem) {
      return {
        ...newState,
        ActiveForm: {
          ...menuItem,
          MenuLevel: menuItem.ParentID? 2 : 1,
        },
        isSiteMenu: act.type.includes(UPDATE_CMS_SITE_MENU_FORM_SUFFIX),
      };
    }

    return newState;
  } else if (isActionType(act, ClearAdminCMSCacheTwoSiteMenuItem) || isActionType(act, ClearAdminCMSSiteCacheBelowOne) || isActionType(act, ClearAdminCMSCacheTwoPageMenuItem)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default SiteMenuItemFormReducer;