import * as React from 'react';
import {namespace as ns} from './constants';
import '../../../styles/elements/my-event/details.scss';

const namespace = (): string => `${ns()}--details`;

interface Props {
  children?: React.ReactNode;
}

const Details: React.SFC<Props> = ({ children }: Props): React.ReactElement<Props> => (
  <div className={namespace()}>{children}</div>
);

export default Details;
