import { makeRequestEpic } from "../../../../../store/Validation/epicCreator";
import { AddFacilitySubmitActions } from './actions';
import { getUpdateFacilityUrl, getEditFacilityUrl } from "../../../../../constants/urls";
import { AjaxResponse, Observable } from "rxjs/Rx";
import { ENTITY_CREATED } from "../../../../../constants/messages/adminCMS";
import { FACILITY } from "../../../../../constants/messages/adminFacilityLocation";
import { AdminFacility } from "../../../../../models/api/adminFacilitiesCacheOne";
import { reduxStoreService } from "../../../../service";

export const adminFacilityLocationAddFacilityEpic = makeRequestEpic(
  AddFacilitySubmitActions,
  getUpdateFacilityUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    return ENTITY_CREATED(FACILITY);
  },
  (response: AjaxResponse) => {
    const facility = response.response.FacilitiesFacility as AdminFacility;
    const state = reduxStoreService().getState();
    const location = state.adminFacilityLocation.cacheOne.FacilitiesLocation;

    if (facility && location) {
      return getEditFacilityUrl({
        locationId: location.ID,
        locationName: location.Name,
        facilityId: facility.ID,
        facilityName: facility.Name,
      });
    }

    return '';
  },
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  true,
);