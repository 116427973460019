import * as React from 'react';
import { Modal, ModalHeader, ModalContent } from '../../../../Elements';
import AddCart from './AddCart';
import ExportLogs from './ExportLogs';
import '../../../../../styles/pages/settings/orders/payment-tools.scss';
import {bindActionCreators} from "redux";
import {
  actionCreators as cacheZeroActionCreators,
} from "../../../../../store/CacheZero/actions";
import {
  actionCreators as cacheOneActionCreators,
} from "../../../../../store/CacheOne/actions";
import {actionCreators} from "../../../../../store/App/actions";
import {
  actionCreators as cacheTwoPrevOrdersActionCreators,
} from "../../../../../store/CacheTwoPrevOrders/actions";
import {
  actionCreators as cacheThreePrevOrdersActionCreators,
} from "../../../../../store/CacheThreePrevOrders/actions";
import {actionCreators as refundOrFeeActionCreators} from "../../../../../store/Settings/PrevOrders/RefundOrFee/actions";
import {actionCreators as auditLogActionCreators, ReportAuditLogActions} from "../../../../../store/Settings/PrevOrders/AuditLog/actions";
import {RouteComponentProps} from "react-router";
import { SaveState } from '../../../../../store/Rollback/actions';
import { ApplicationState } from '../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../store/service';
import {WithInertAttribute} from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--settings--order--payment-tools';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {id: string}>;

export type PaymentToolsProps = WithInertAttribute<{}>;

class PaymentTools extends React.PureComponent<PaymentToolsProps, {}> {
  props: PaymentToolsProps & ConnectedProps;

  componentDidMount() {
    this.reset();
    reduxStoreService().dispatch(new SaveState());
  }
  
  componentWillUnmount() {
    this.reset();
  }

  reset = () => {
    // NOTE: These reset are also called when closing the modal, this is because
    // PaymentTools sidebar _could be_ behind the modal, and they both connect
    // to the same form, it is better to reset both
    this.props.actions.resetRefundOrFee();
    this.props.actions.resetAuditLog();
  };

  public render() {
    const {apiLoadingMap, inert, apiSaving} = this.props;

    const loading = apiLoadingMap[ReportAuditLogActions.requestType] || apiSaving > 0;

    return (
      <Modal inert={inert} onClose={this.reset} big mobileFullScreen expand>
        <ModalHeader red>
          Order/Payment Tools
        </ModalHeader>
        <ModalContent loading={loading} paddingLeft={0} paddingRight={0}>
          <div className={`${namespace()}--add-cart`}>
            <h2 className={`${namespace()}--export-logs--title`}>Manage Available Credit</h2>
            <AddCart/>
          </div>
          <div className={`${namespace()}--export-logs`}>
            <h2 className={`${namespace()}--export-logs--title`} style={{marginBottom: '76px'}}>Export Audit Log</h2>
            <ExportLogs inModal/>
          </div>
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheTwoPrevOrders: state.cacheTwoPrevOrders,
    cacheThreePrevOrders: state.cacheThreePrevOrders,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...cacheZeroActionCreators,
  ...cacheOneActionCreators,
  ...actionCreators,
  ...cacheTwoPrevOrdersActionCreators,
  ...cacheThreePrevOrdersActionCreators,
  ...refundOrFeeActionCreators,
  ...auditLogActionCreators
}, dispatch)});

const ConnectedPaymentTools = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<PaymentToolsProps>(),
)(PaymentTools);

export default ConnectedPaymentTools;
