import * as React from 'react';
import { findDOMNode } from 'react-dom';
import {mergeClassNames} from '@tentaroo/shared';

import Shadow from '../Shadow';
import '../../../styles/elements/side-modal/index.scss';
import { initialRoute, fixedHeight } from '../../../utils';
import { actionCreators as appActionCreators } from '../../../store/App/actions';
import { bindActionCreators } from 'redux';
import { getWindowHeight } from '../../../utils/navHelper';
import { isMobile } from '../../../utils/isMobile';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

import {namespace} from "./constants";
import {Nullable} from '../../../utils/dataHelper';


interface SideModalProps {
  children?: React.ReactNode;
  header?: React.ReactNode;
  className?: string;
  stickyHeader?: boolean;
  initialRoute?: boolean;
  loading?: boolean;
  id?: string;
  /**
   * Use this flag to ensure that this side modal is forced to be
   * visible with opacity=1
   */
  ensureVisibility?: boolean;
  onClick?: (e) => void;
  allowShadowClickDefault?: boolean;
  onShadowClick?: () => void;
  disableDynamicHeight?: boolean;
}

type ConnectedProps = ReturnType<typeof mapDispatchToProps>;

interface State {
  height?: number;
}

@initialRoute
@fixedHeight((component) => (findDOMNode(component) as Nullable<Element>)?.getElementsByTagName('section')[0] as Nullable<Element>)
class SideModal extends React.Component<SideModalProps, State> {

  state: State = {

  };
  public props: SideModalProps & ConnectedProps;

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }
  componentWillMount() {
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  public handleResize = () => {
    if (!this.props.disableDynamicHeight && isMobile) this.setState({height: getWindowHeight()});
  };

  onMainClick = (e) => {
    const {allowShadowClickDefault} = this.props;
    if (!allowShadowClickDefault) e.preventDefault();
    e.stopPropagation();
  };

  public render() {
    const { children, stickyHeader, header, loading, onClick, ensureVisibility, onShadowClick } = this.props;

    let className = mergeClassNames(namespace(), this.props);
    let outsideHeaderClassName = `${namespace()}--outside-header`;

    if (stickyHeader) className += ' sticky-header';
    if (loading) className += ' loading';

    // Here we dont want the SideModal to be transparent in any cases
    return (
      <div className={ensureVisibility ? `${namespace()}--wrapper` : ''} onClick={onClick}>
        {onShadowClick ? <Shadow shadow={1} className={`${namespace()}--shadow`} onClick={onShadowClick} component={<div />} /> : null}
        <Shadow
          onClick={this.onMainClick}
          component={<section/>}
          shadow={2}
          className={className}
        >
          {header}
          {children}
        </Shadow>
        <div className={outsideHeaderClassName}>
          {header}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
  }, dispatch)
});

const ConnectedSideModal = connect(
  null,
  mapDispatchToProps,
  getMergeProps<SideModalProps>(),
)(SideModal);

export default ConnectedSideModal;
export { default as SideModalHeader } from './Header';
export { default as SideModalContent } from './Content';
export { default as SideModalContentPadding } from './ContentPadding';
export { default as SideModalActions } from './Actions';
