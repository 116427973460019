import { ActionCreator, ApplicationState } from "../../../..";
import {createApiValidateActions, createValidateActions, createApiSubmitActions, createUpdateValueMethod, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra} from '../../../../Validation/actionCreator';
import { scrollModalToTop } from "../../../../../utils/helpers/adminCMSPageHelper";
import { getAddFacilityBody, getEditFacilityUrl } from "../../../../../constants/urls";
import { actionCreators as appActionCreators } from "../../../../../store/App/actions";
import { navPush } from "../../../../../utils";
import { ADMIN_FACILITY_MODULE_SUFFIX } from "../../../../../store/AdminFacilityLocation/CacheOne/actions";
import { ModalTypes } from "../../../../../utils/modalHelper";
import { UPDATE_FORM_ACTION_SUFFIX } from "../../../../../utils/suffix";
import { typeName, Action } from "../../../../../utils/StrongActions";
// TODO: - cannot do this because this will create circular import and result in `undefined`, probably move all SUFFIX to a separated file?
import { SaveState } from "../../../../Rollback/actions";
// import { UPDATE_FACILITY_FACILITY_FORM_SUFFIX } from "../../Facility/Form/actions";

export const SUFFIX = '__FACILITY_FORM__MODAL' + '__UPDATE_FACILITY_FACILITY_FORM__' + ADMIN_FACILITY_MODULE_SUFFIX + UPDATE_FORM_ACTION_SUFFIX + '__FACILITY_FACILITY_FORM';
@typeName("INIT" + SUFFIX) export class InitNewFacilityModal extends Action {}

@typeName("RESET" + SUFFIX) export class ResetNewFacilityModal extends Action {}

@typeName("FILTER_FACILITY_TYPE" + SUFFIX) export class FilterFacilityType extends Action {
  constructor(public filterText: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class NewFacilityToggleFilter extends Action {
  constructor(public searching: boolean) { super(); }
}
// will submit data to cache two facility
@typeName("CLIENT_SUBMIT_FORM" + SUFFIX) export class ClientSubmitForm extends Action { constructor(public data: any) { super(); }}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const AddFacilitySubmitActions = createApiSubmitActions(SUFFIX);

export const NewFacilityModalNamespace = (): string => 'pages--facility-location--modals--new-facility';

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.pages.modals.newPage),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  filterFacilityType: (filterText: string): ActionCreator => dispatch => dispatch(new FilterFacilityType(filterText)),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new NewFacilityToggleFilter(searching)),
  apiSubmitForm: (router): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const valid = innerApiSubmitFormMethod(
      dispatch,
      AddFacilitySubmitActions,
      (s: ApplicationState) => s.adminFacilityLocation.facilities.modals.newFacility,
      undefined,
      undefined,
      true,
      true,
      undefined,
      undefined,
    );

    if (valid) {
      dispatch(appActionCreators.popModal(false, false, ModalTypes.NEW_FACILITY) as any);

      const body = getAddFacilityBody(state.adminFacilityLocation.facilities.modals.newFacility.ActiveForm);
      if (state.adminFacilityLocation.cacheOne.FacilitiesLocation) {
        dispatch(new ClientSubmitForm(state.adminFacilityLocation.facilities.modals.newFacility.ActiveForm));
        navPush(router, getEditFacilityUrl({
          locationId: state.adminFacilityLocation.cacheOne.FacilitiesLocation.ID,
          locationName: state.adminFacilityLocation.cacheOne.FacilitiesLocation.Name,
          facilityId: -1,
          facilityName: state.adminFacilityLocation.facilities.modals.newFacility.ActiveForm.Name,
        }));
      }
      dispatch(AddFacilitySubmitActions.request(
          body,
          getCacheLevelExtra(
            false,
            false,
          ),
        )
      );
    } else {
      scrollModalToTop(NewFacilityModalNamespace());
    }
  },
  reset: (): ActionCreator => dispatch => dispatch(new ResetNewFacilityModal()),
  init: (): ActionCreator => dispatch => {
    dispatch(new InitNewFacilityModal());
    dispatch(new SaveState());
  },
  clientSubmitForm: (data: any): ActionCreator => dispatch => dispatch(new ClientSubmitForm(data)),
};