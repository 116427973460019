import {SUFFIX, ValidateActions} from './actions';
import * as NM from '../../../../../../constants/messages/numbersWizard';
import {apiValidationReducerCreator} from '../../../../../Validation/reducerCreator';
import {APIFailure, APISuccess, UpdateCurrValue, WithRootState} from "../../../../../Validation/actionCreator";
import {EventNumbersCalc, EventNumbersSave, SUFFIX_SAVE} from "../../../../../CacheFourEventsNumbers/actions";
import {FormDefinition} from "./validation";
import {ApiSubmitActionsNumbersSpots} from "../Spots/actions";
import {ApiSubmitActionsNumbersClasses} from "../Classes/actions";
import {EventRegisterNumbersReset} from "../Main/actions";
import {FinancialSummaryInit, initFinancialSummary} from "../../../../../../utils/classesHelper";
import {CacheFourEventsNumbersCore} from "../../../../../CacheFourEventsNumbers";
import {ApiSubmitActionsNumbersProducts} from "../Products/actions";
import { ApiSubmitActionsNumbersCampsite } from '../Campsite/actions';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../../utils/StrongActions';

export interface NumbersWizardConfirmationState {
  ActiveForm: {
    selectedFinance?: 'min' | 'full' | 'other' | 'credit';
    otherValue?: number;
  },
  ValidationRules: any;
  SubmitErrorMessage?: string;
  minValue?: number;
  maxValue?: number;
}

const determineInlineError = (errors: any) => {
  let message = '';
  if (errors) {
    if (errors.otherValue) message = NM.INVALID_PAYMENT;
  }

  return message;
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, determineInlineError);
const checkCache4NumbersSaveValidation = apiValidationReducerCreator(SUFFIX_SAVE, determineInlineError);

const getInitialState = (): NumbersWizardConfirmationState => {
  return {
    ActiveForm: {
      selectedFinance: 'min',
      otherValue: 0
    }, ValidationRules: {...FormDefinition}};
};

const NumbersWizardConfirmation: Reducer<NumbersWizardConfirmationState> = (oldState = {...getInitialState()}, act: WithRootState<AnyAction>) => {
  const s = checkApiValidation(oldState, act);
  const state = checkCache4NumbersSaveValidation(s, act);

  if (
    act.type === EventNumbersCalc.successType ||
    act.type === ApiSubmitActionsNumbersSpots.successType ||
    act.type === ApiSubmitActionsNumbersClasses.successType ||
    act.type === ApiSubmitActionsNumbersCampsite.successType ||
    act.type === ApiSubmitActionsNumbersProducts.successType ||
    (act.type === EventNumbersSave.failureType && (act as WithRootState<APIFailure>).response.status === 400 && (act as WithRootState<APIFailure>).response.xhr.response.error.Detail === "Canceled")) {
    const action = <WithRootState<APISuccess>> act;
    let data: CacheFourEventsNumbersCore;
    if (
      act.type === EventNumbersCalc.successType ||
      act.type === ApiSubmitActionsNumbersSpots.successType ||
      act.type === ApiSubmitActionsNumbersClasses.successType ||
      act.type === ApiSubmitActionsNumbersCampsite.successType ||
      act.type === ApiSubmitActionsNumbersProducts.successType
    ) {
      data = action.response.response;
    } else {
      data = action.response.xhr.response;
    }
    if (data.EventRegistrationPaymentStatus) {
      const vals: FinancialSummaryInit = initFinancialSummary(
        action.rootState,
        data.EventRegistrationPaymentStatus,
        (rootState) => rootState.events.event.register.numbers.confirmation.ActiveForm.selectedFinance === 'other',
        true,
      );

      return {
        ...state,
        ActiveForm: {selectedFinance: vals.paymentType, otherValue: vals.otherDefaultValue},
        ValidationRules: {...state.ValidationRules, otherValue: vals.otherValueRules},
        minValue: data.EventRegistrationPaymentStatus.AmountMin,
        maxValue: data.EventRegistrationPaymentStatus.AmountMax
      };
    }
  } else if (act.type === ValidateActions.updateCurrType) {
    const action = <WithRootState<UpdateCurrValue>> act;
    const newState = {...state};
    newState.ActiveForm = {...state.ActiveForm};
    if (action.value === 'full') {
      newState.ActiveForm.otherValue = state.maxValue;
    } else if (action.value === 'min') {
      newState.ActiveForm.otherValue = state.minValue;
    } else if (action.value === 'credit') {
      newState.ActiveForm.otherValue = 0;
    } else {
      return state;
    }
    return newState;
  } else if (isActionType(act, EventRegisterNumbersReset)) {
    return getInitialState();
  }

  return state;
};

export default NumbersWizardConfirmation;
