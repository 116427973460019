import {EventProductsCalc, EventProductsSave} from './actions';
import {APIFailure, APISuccess} from "../Validation/actionCreator";
import {ClearAllCache, ClearAllEndUserCacheButOptions} from "../App/actions";
import {ClearCacheBelowOne} from "../CacheOne/actions";
import {ClearCacheBelowTwoEvents} from "../CacheTwoEvents/actions";
import {ClearCacheBelowThreeEvents} from "../CacheThreeEvents/actions";
import {OrderedProduct, Product, ProductUpdate} from "../../models/product";
import {listSelector, makeNoFilterSelector, makeSimpleSelector} from "../../utils/reselectHelper";
import {
  ApiSubmitActionsProductsProducts,
  CancelProductProducts,
  CommitProductProducts,
  EventRegisterProductsProductAdd,
  EventRegisterProductsProductRemove,
  ManageProductProducts,
  ResetManagingProductsProducts,
  SetAdded,
  SetNotAdded
} from "../Events/Event/Register/Products/Products/actions";
import {
  addProductToState,
  cancelAddedProductToState,
  commitAddedProductToState,
  manageAddedProductToState, productAvailableSort, productOrderedSort,
  removeProductToState,
  resetManagingProductsToState,
  updateAddedProductToState,
  updateAvailableProductToState
} from "./reducerHelpers";
import {EventRegistrationPaymentStatus} from "../../models/api/cacheThreeEvents";
import {convertObjToMoment, convertToMoment} from "../../utils/dateHelper";
import { Reducer } from 'redux';
import { Action, isActionType } from '../../utils/StrongActions';

const eventProductsSelector = listSelector(state => state.cacheFourEventsProducts.EventProductsAvailable);
const eventProductsOrderedSelector = listSelector(state => state.cacheFourEventsProducts.EventProductsOrdered);

const availableProductFilter = (p: Product) => !p.isAdded;
const addedProductFilter = (p: OrderedProduct) => !!p.IsNewlyAdding && !p.Inactive;
const previousProductFilter = (p: OrderedProduct) => !p.IsNewlyAdding && !p.Inactive;

export const makeAvailableProductsProductsFilter = makeNoFilterSelector(
  availableProductFilter,
  eventProductsSelector,
  productAvailableSort
);

export const makeAddedProductsProductsFilter = makeNoFilterSelector(
  addedProductFilter,
  eventProductsOrderedSelector,
  productOrderedSort
);

export const makePreviousProductsProductsFilter = makeNoFilterSelector(
  previousProductFilter,
  eventProductsOrderedSelector,
  productOrderedSort
);

export const makeAvailableProductsProductsSearchFilter = makeSimpleSelector(
  availableProductFilter,
  (p: Product, f: string) => p.Name.toLowerCase().includes(f),
  eventProductsSelector,
  state => state.app.searchFilter, // @todo: this should be defaultFilterSelector, but I had a weird bug that makes it not work??
  productAvailableSort
);

const AVAILABLE_PRODUCT_KEY = 'EventProductsAvailable';
const ADDED_PRODUCT_KEY = 'EventProductsOrdered';

export interface CacheFourEventsProductsCore {
  EventRegistrationPaymentStatus?: EventRegistrationPaymentStatus | null;
  EventProductsAvailable?: Array<Product> | null;
  EventProductsOrdered?: Array<OrderedProduct> | null;
  EventProductsOrdered_Updates?: Array<ProductUpdate> | null;
}

export interface CacheFourEventsProductsState extends CacheFourEventsProductsCore {
}

const setCacheFourData = (data: CacheFourEventsProductsCore): CacheFourEventsProductsState => {
  let availableProducts = data.EventProductsAvailable;
  if (data.EventProductsAvailable) {
    availableProducts = [];
    data.EventProductsAvailable.forEach((avail) => {
      (availableProducts as any).push({
        ...avail,
        Quantity: 1
      });
    });
  }

  return {
    EventRegistrationPaymentStatus: convertObjToMoment(data.EventRegistrationPaymentStatus, ['DepositDueDate']),
    EventProductsAvailable: availableProducts,
    EventProductsOrdered: convertToMoment(data.EventProductsOrdered, ['DateOrdered']),
    EventProductsOrdered_Updates: data.EventProductsOrdered_Updates
  };
};

const updateCacheFourData = (newState: CacheFourEventsProductsState, data: any): CacheFourEventsProductsState => {
  if (data.EventRegistrationPaymentStatus) {
    newState.EventRegistrationPaymentStatus = convertObjToMoment(data.EventRegistrationPaymentStatus, ['DepositDueDate']);
  }
  if (data.EventProductsOrdered) {
    newState.EventProductsOrdered = convertToMoment(data.EventProductsOrdered, ['DateOrdered']);
  }
  if (data.EventProductsOrdered_Updates) {
    newState.EventProductsOrdered_Updates = data.EventProductsOrdered_Updates;
  }
  return {...newState};
};

const getInitialState = () => ({
  EventProductsAvailable: [],
  EventProductsOrdered: []
});

const CacheFourEventsProducts: Reducer<CacheFourEventsProductsState> = (state, act: Action) => {
  if (act.type === EventProductsCalc.successType) { // initial load
    const action = <APISuccess> act;
    const data = action.response.response;
    const nState = setCacheFourData(data);
    const newState = updateCacheFourData(nState, data);
    return newState;
  } else if (act.type === ApiSubmitActionsProductsProducts.successType) {
    const action = <APISuccess> act;
    return updateCacheFourData({...state}, action.response.response);
  } else if (act.type === EventProductsSave.failureType) {
    const action = <APIFailure> act;
    if (action.response.status === 400 && action.response.xhr.response.error.Detail === "Canceled") {
      return updateCacheFourData({...state}, action.response.response);
    }
    return state;
  } else if (isActionType(act, EventRegisterProductsProductAdd)) {
    return addProductToState(state, AVAILABLE_PRODUCT_KEY, ADDED_PRODUCT_KEY, act);
  } else if (isActionType(act, EventRegisterProductsProductRemove)) {
    return removeProductToState(state, AVAILABLE_PRODUCT_KEY, ADDED_PRODUCT_KEY, act);
  } else if (isActionType(act, SetNotAdded)) {
    return updateAvailableProductToState(state, AVAILABLE_PRODUCT_KEY, act);
  } else if (isActionType(act, SetAdded)) {
    return updateAddedProductToState(state, ADDED_PRODUCT_KEY, act);
  } else if (isActionType(act, ManageProductProducts)) {
    return manageAddedProductToState(state, ADDED_PRODUCT_KEY, act);
  } else if (isActionType(act, CommitProductProducts)) {
    return commitAddedProductToState(state, ADDED_PRODUCT_KEY, act);
  } else if (isActionType(act, CancelProductProducts)) {
    return cancelAddedProductToState(state, ADDED_PRODUCT_KEY, act);
  } else if (isActionType(act, ResetManagingProductsProducts)) {
    return resetManagingProductsToState(state, ADDED_PRODUCT_KEY);
  } else if (isActionType(act, ClearAllCache) || isActionType(act, ClearAllEndUserCacheButOptions) ||
    isActionType(act, ClearCacheBelowOne) || isActionType(act, ClearCacheBelowTwoEvents) ||
    isActionType(act, ClearCacheBelowThreeEvents)
  ) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default CacheFourEventsProducts;
