import * as React from 'react';
import '../../../../styles/elements/contact/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card} from '../../index';
import { CardCategory } from '../../Card';
import { PhoneIcon, MailIcon } from '../../../../components/Icons';
import { Row, Column } from '../../Grid';

export const namespace = (): string => 'elements--contact--card';

export enum AdminFacilityLocationContactCardType {
  FACILITY_TYPE_PRIMARY_CONTACT='facility-type-primary-contact',
}
interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin';
  type: AdminFacilityLocationContactCardType;
  category: CardCategory;
  contact: {
    Name?: string;
    Phone?: string;
    Email?: string;
  }
  routes?: any;
  checked?: boolean;
  disabled?: boolean;
}

const ContactCard: React.SFC<Props> = ({ children, type, template, checked, contact, category, routes, disabled, ...props }: Props): React.ReactElement<Props> => {
  let className = attribute(namespace(), undefined, props);

  const onCardClick = (e) => {

  };
  const renderPrimaryCard = () => {
    return (
      <div className={`${namespace()}--${type}`}>
        <Row className={`${namespace()}--${type}--name`}>{contact.Name}</Row>
        <Row className={`${namespace()}--${type}--info`}>
          <Column span={6} mobileSpan={12} className={`${namespace()}--${type}--info--item email`}>
            <div className={`${namespace()}--${type}--info--item--icon`}><MailIcon /></div>
            <div className={`${namespace()}--${type}--info--item--text`}>{contact.Email}</div>
          </Column>
          <Column span={6} mobileSpan={12} className={`${namespace()}--${type}--info--item phone`}>
            <div className={`${namespace()}--${type}--info--item--icon`}><PhoneIcon /></div>
            <div className={`${namespace()}--${type}--info--item--text`}>{contact.Phone}</div>
          </Column>
        </Row>
      </div>
    );
  };

  if (checked) className += ' checked';
  if (disabled) className += ' disabled';
  className += ` ${type}`;
  className += ` ${category}`;

  return (
    <Card
      className={className}
      component={<section/>}
      template={template}
      category={category}
      padding="none"
    >
      <div onClick={onCardClick}>
        {type === AdminFacilityLocationContactCardType.FACILITY_TYPE_PRIMARY_CONTACT ? renderPrimaryCard() : null}
      </div>
    </Card>
  );
};

ContactCard.defaultProps = {
  template: 'mobile-no-margin',
};

export default ContactCard;