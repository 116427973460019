import * as React from 'react';
import {default as AppHeader} from '../../../../App/Header';
import {RouteComponentProps, withRouter} from "react-router";
import {getEventRootUrl} from "../../../../../constants/urls";

type ConnectedProps = RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string}>;

export default () => {
  const EventHeader: React.SFC<ConnectedProps> = (props: ConnectedProps): React.ReactElement<ConnectedProps> => {
    return <AppHeader
      showDrawer
      cart
      mobileCart
      refresh
      search
      title="Classes"
      back={getEventRootUrl(props)}
    />;
  };
  return withRouter(EventHeader as any);
};
