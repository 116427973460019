import {PaymentType} from "../../../../models/api/options";
import {RefundProduct} from "../../../../components/Elements/Cart/Item/constants";
import { captureTentarooError } from "../../../../utils/dataHelper";
import { reduxStoreService } from "../../../service";
import {PreviousOrderItem} from "../../../../models/api/cacheTwoPrevOrders";


export const getTotalAmountKey = (item: PreviousOrderItem) => `TotalAmount${item.ID}`;
export const getAppliedCreditKey = (item: PreviousOrderItem) => `AppliedCredit${item.ID}`;
export const getInactiveKey = (item: PreviousOrderItem) => getInactiveWithIDKey(item.ID);
export const getInactiveWithIDKey = (id: number) => `Inactive${id}`;
export const getAmountKey = (item: PreviousOrderItem) => `Amount${item.ID}`;

export const isRefund = (
  rootState = reduxStoreService().getState(),
  PaymentTypeID: number,
) => {
  if (!rootState.cacheZero.options) {
    captureTentarooError(new Error("cacheZero.options not available when getting isRefund in ManageOrder"));
    return false;
  }
  const PaymentTypes = rootState.cacheZero.options.PaymentTypes as Array<PaymentType>;
  const PaymentType = PaymentTypes.find((pt) => pt.ID === PaymentTypeID);
  if (!PaymentType) {
    captureTentarooError(new Error(`Cannot find payment type ${PaymentTypeID} when updateOrderTotal`));
    return false;
  }
  return PaymentType.Refund;
};

export const fpr = (number: number, precision: number = 5): number => {
  //default returns (10000 * number) / 10000
  //should correct very small floating point errors

  var correction: number = Math.pow(10, precision);
  return Math.round(correction * number) / correction;
};

export const allowInactive = (
  itemType: string,
  productIDi: number,
  PaymentTypeID: number,
  rootState = reduxStoreService().getState(),
): boolean => {
  // allow removing of order items that are not refund products.
  let refundOrder: boolean = isRefund(rootState, PaymentTypeID);
  return (itemType !== 'Product' || productIDi !== RefundProduct || !refundOrder);
};

export const allowPaymentAmountChange = (ItemType:String): boolean => {
  return (ItemType !== "Product");
};

export const enableAmountChange = (PaymentTypeID: number): boolean => {
  return (PaymentTypeID !== 2 && PaymentTypeID !== 12);
};