import * as React from 'react';
import {Alert, Button, Modal, ModalActions, ModalContent, ModalHeader, Row} from '../../../../../Elements';
import FinancialSummary from '../../../../Events/Event/Registration/FinancialSummary';
import {actionCreators} from "../../../../../../store/Facilities/Trip/Modals/CancelReservation/actions";
import {
  actionCreators as appActionCreators,
} from "../../../../../../store/App/actions";
import {bindActionCreators} from "redux";
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import '../../../../../../styles/pages/facilities/trip/modals/cancel-reservation/index.scss';
import EndUserCacheManager from '../../../../../../utils/cacheManagers/endUserCacheManager';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { ClearCacheBelowThreeFacilities } from '../../../../../../store/CacheThreeFacilities/actions';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { reduxStoreService } from '../../../../../../store/service';
import { CancelReservationRouterParams } from '../../../../../../utils/helpers/endUserPageHelper';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isCacheFourFacilitiesPopulated} from '../../../../../../utils/cachePopulatedCheckers/endUser';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--facilities--trip--modals--cancel-reservation';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<CancelReservationRouterParams, {}>
>;

class CancelReservation extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;

  componentDidMount() {
    super.loadAndSetData(
      this.props,
      () => {
        EndUserCacheManager.getInstance().loadCancelReservation({
          props: this.props,
          actions: this.props.actions,
          ReservationID: this.props.ReservationID,
          InCart: this.props.InCart,
          isStateNavigated: true,
        });
      }
    );
  }

  componentWillUnmount() {
    this.onClose();
  }

  getDeleteWarning = () => {
    if (this.props.InCart === -1) {
      return 'Please confirm the new financial totals for this trip to restore this registration.';
    } else if (this.props.WasFinalized) {
      return 'Since this reservation has been finalized, a removal item will be added to your cart. You\'ll need to checkout for this removal to take effect.';
    } else {
      return 'Please confirm the new financial totals for this trip to delete this reservation.';
    }
  };

  getDeleteHeader = () => {
    if (this.props.InCart === -1) {
      return `Restore Reservation: ${this.props.reservationName}`;
    } else if (this.props.WasFinalized) {
      return `Cancel Reservation: ${this.props.reservationName}`;
    } else {
      return `Delete Reservation: ${this.props.reservationName}`;
    }
  };

  getDeleteButtonText = () => {
    if (this.props.InCart === -1) {
      return 'RESTORE';
    } else if (this.props.WasFinalized) {
      return 'CANCEL';
    } else {
      return 'DELETE';
    }
  };

  onCancel = () => {
    reduxStoreService().dispatch(new ClearCacheBelowThreeFacilities());
    this.props.actions.popModal(false, true, ModalTypes.CANCEL_RESERVATION);
  };

  onClose = () => {
    reduxStoreService().dispatch(new ClearCacheBelowThreeFacilities());
  };
  onConfirmAndDelete = () => {
    const { actions, ReservationID, InCart } = this.props;

    if (ReservationID === undefined) return;

    actions.updateReservation(ReservationID, InCart !== -1);
  };

  public render() {
    const {cacheFourFacilities, cancelReservation, actions, user, inert} = this.props;
    const {TripPaymentStatus} = cacheFourFacilities;
    const { ActiveForm: { selectedFinance, otherValue }, ValidationRules } = cancelReservation;

    // `TripPaymentStatus` must be loaded if cacheFourFacilities is populated
    if (!isCacheFourFacilitiesPopulated(cacheFourFacilities) || !TripPaymentStatus) return null;
    const fees: Array<{label: string; value: number;}> = [];
    if (TripPaymentStatus && TripPaymentStatus.ReservationTotalsByType) {
      TripPaymentStatus.ReservationTotalsByType.forEach((r) => {
        fees.push({
          label: r.Name,
          value: r.Amount
        });
      });
    }
    return (
      <Modal inert={inert} onClose={this.onClose} big mobileFullScreen expand className={namespace()}>
        <ModalHeader className={`${namespace()}--header`}>
          {this.getDeleteHeader()}
        </ModalHeader>
        <ModalContent paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0}>
          <Row className={`${namespace()}--row`}>
            <Alert noFlex>{this.getDeleteWarning()}</Alert>
          </Row>
          <Row className={`${namespace()}--row`}>
            <FinancialSummary
              title="Total Trip Fees"
              fees={fees}
              paymentStatus={TripPaymentStatus}
              showLoaders={false}
              selected={selectedFinance as 'min' | 'full' | 'other' | 'credit'}
              isFinanceDirty={false}
              onSelect={actions.updateValue}
              updateValue={actions.updateValue}
              simpleUpdate={actions.simpleUpdate}
              validationRules={ValidationRules}
              isSaving={false}
              otherValue={otherValue}
              IsAdmin={user.user ? user.user.str_permissions.hasAdminAccess : false}
              isWizard
              hasAccess
              hasAccessMessage=""
            />
          </Row>
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button flat textColor="black" id={generateDOMId("close-cancel-reservation-modal")} onClick={this.onCancel}>CANCEL</Button>}
          right={<Button id={generateDOMId("submit-update-reservation")} flat textColor="red" onClick={this.onConfirmAndDelete}>{`CONFIRM AND ${this.getDeleteButtonText()}`}</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const inCartSelector = makeFormModalPropSelector(ModalTypes.CANCEL_RESERVATION, 'inCart');
  const reservationIDSelector = makeFormModalPropSelector(ModalTypes.CANCEL_RESERVATION, 'reservationID');
  const reservationNameSelector = makeFormModalPropSelector(ModalTypes.CANCEL_RESERVATION, 'reservationName');
  const wasFinalizedSelector = makeFormModalPropSelector(ModalTypes.CANCEL_RESERVATION, 'wasFinalized');
  return {
    cancelReservation: state.facilities.trip.modals.cancelReservation,
    user: state.user,
    SystemSettings: state.session.SystemSettings,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheFourFacilities: state.cacheFourFacilities,
    cacheThreeFacilities: state.cacheThreeFacilities,
    cacheTwoFacilities: state.cacheTwoFacilities,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    InCart: inCartSelector(state),
    ReservationID: reservationIDSelector(state),
    reservationName: reservationNameSelector(state),
    WasFinalized: wasFinalizedSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators
  }, dispatch)
});

const ConnectedCancelReservation = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(CancelReservation);

export default withRouter<{}>(ConnectedCancelReservation);
