import * as M from '../../../../../constants/messages/generic';
import { getTimePickerHourValidationRule, getTimePickerMinValidationRule, getTimePickerPeriodValidationRule } from "../../../../../store/AdminCMSSite/Pages/Modals/NewPage/validation";
import { AdminFacilityTypeAttribute } from "../../../../../models/api/adminFacilitiesCacheTwoFacilityType";
import { SelectValidator, Validator } from '../../../../../utils/validator/models';

export interface IFacilityTypeRowValidator {
  Description: Validator;
}

export interface IFacilityTypeGeneralValidator {
  Name?: Validator;
  NamePlural?: Validator;
  ShowAsID: Validator;
  AllowOverlapping: Validator;
  PropertyMapURL: Validator;
  AdditionalNotify1_Name: Validator;
  AdditionalNotify1_Email: Validator;
  AdditionalNotify2_Name: Validator;
  AdditionalNotify2_Email: Validator;
  GLAccountID_Default?: Validator;
}

export interface IFacilityTypeBookingTimesValidator {
  StartHour: SelectValidator;
  StartMin: SelectValidator;
  StartPeriod: SelectValidator;
  EndHour: SelectValidator;
  EndMin: SelectValidator;
  EndPeriod: SelectValidator;
  IsCheckoutNextDay: Validator;
}

export interface IFacilityTypeAttributesValidator {

}

export const getAttributeNameValidationRule = (key: string) => {
  return {
    key,
    validatejs: {
      [key]: {
        length: {
          maximum: 150,
          tooLong: M.MAX_LENGTH(150)
        },
      },
    },
  };
};

export const getAttributeInactiveValidationRule = (key: string) => {
  return {
    key,
    isRequired: () => false,
  };
};

export const getAttributeDefinition = (attributes?: AdminFacilityTypeAttribute[]) => {
  let definition = {};

  if (attributes && attributes.length > 0) {
    attributes.forEach((attr, index) => {
      definition[`AttributeName${index}`] = getAttributeNameValidationRule(`AttributeName${index}`);
      definition[`AttributeInactive${index}`] = getAttributeInactiveValidationRule(`AttributeInactive${index}`);
    });
  } else {
    definition = {
      AttributeName0: getAttributeNameValidationRule(`AttributeName0`),
      AttributeInactive0: getAttributeInactiveValidationRule(`AttributeInactive0`),
    };
  }

  return definition;
};

export const FacilityTypeRowFormDefinition: IFacilityTypeRowValidator = {
  Description: {
    key: 'Description',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      Description: {
        length: {
          maximum: 1024,
          tooLong: M.MAX_LENGTH(1024)
        },
      },
    }
  },
};

export const FacilityTypeGeneralFormDefinition: IFacilityTypeGeneralValidator = {
  Name: {
    key: 'Name',
    isRequired: () => true,
    defaultValue: () => '',
    validatejs: {
      Name: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 100,
          tooLong: M.MAX_LENGTH(100)
        },
      },
    },
  },
  NamePlural: {
    key: 'NamePlural',
    isRequired: () => true,
    defaultValue: () => '',
    validatejs: {
      NamePlural: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 100,
          tooLong: M.MAX_LENGTH(100)
        },
      },
    },
  },
  ShowAsID: {
    key: 'ShowAsID',
    isRequired: () => true,
    defaultValue: () => 1,
    validatejs: {
      ShowAsID: {
        presence: {message: '^' + M.REQUIRED},
      },
    },
  },
  AllowOverlapping: {
    key: 'AllowOverlapping',
    defaultValue: () => false,
  },
  PropertyMapURL: {
    key: 'PropertyMapURL',
    defaultValue: () => '',
    validatejs: {
      PropertyMapURL: {
        length: {
          maximum: 1024,
          tooLong: M.MAX_LENGTH(1024)
        },
        url: {
          message: '^' + M.INVALID_FULL_URL,
        },
      },
    },
  },
  AdditionalNotify1_Name: {
    key: 'AdditionalNotify1_Name',
    defaultValue: () => '',
    validatejs: {
      AdditionalNotify1_Name: {
        length: {
          maximum: 150,
          tooLong: M.MAX_LENGTH(150)
        },
      },
    },
  },
  AdditionalNotify1_Email: {
    key: 'AdditionalNotify1_Email',
    defaultValue: () => '',
    validatejs: {
      AdditionalNotify1_Email: {
        email: {message: '^' + M.INVALID},
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50)
        },
      },
    },
  },
  AdditionalNotify2_Name: {
    key: 'AdditionalNotify2_Name',
    defaultValue: () => '',
    validatejs: {
      AdditionalNotify2_Name: {
        length: {
          maximum: 150,
          tooLong: M.MAX_LENGTH(150)
        },
      },
    },
  },
  AdditionalNotify2_Email: {
    key: 'AdditionalNotify2_Email',
    defaultValue: () => '',
    validatejs: {
      AdditionalNotify2_Email: {
        email: {message: '^' + M.INVALID},
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50)
        },
      },
    },
  },
};

export const FacilityTypeBookingTimesFormDefinition: IFacilityTypeBookingTimesValidator = {
  StartHour: {
    ...getTimePickerHourValidationRule('StartHour'),
    defaultValue: () => 0,
  },
  StartMin: getTimePickerMinValidationRule('StartMin'),
  StartPeriod: {
    ...getTimePickerPeriodValidationRule('StartPeriod'),
    defaultValue: () => 1,
  },
  EndHour: {
    ...getTimePickerHourValidationRule('EndHour'),
    defaultValue: () => 11,
  },
  EndMin: getTimePickerMinValidationRule('EndMin'),
  EndPeriod: getTimePickerPeriodValidationRule('EndPeriod'),
  IsCheckoutNextDay: {
    key: 'IsCheckoutNextDay',
    defaultValue: () => true,
  },
};

export const FacilityTypeAttributesFormDefinition: IFacilityTypeAttributesValidator = {

};