export const scrollToErrorCheckout = () => {
  setTimeout(
    () => {
      const scrollPos = document.getElementsByClassName('pages--checkout--error')[0] as any;
      if (scrollPos) {
        window.scrollTo(0, scrollPos.offsetTop - 120);
      }
    },
    0
  );
};
