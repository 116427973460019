import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import {AdminEventsCacheTwoEventContext} from "@tentaroo/shared";

import { ApplicationState } from '../../../../../../store';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as cacheOneActionCreators } from '../../../../../../store/AdminEvents/CacheOne/actions';
import { actionCreators as cacheThreeClassTypeActionCreators } from '../../../../../../store/AdminEvents/CacheThreeClassType/actions';
import { actionCreators as cacheThreeClassActionCreators } from '../../../../../../store/AdminEvents/CacheThreeClass/actions';
import { LoadingAll, Tabs, Tab } from '../../../../../Elements';
import { Main, MainContent } from '../../../../../Layouts';
import '../../../../../../styles/pages/admin-events/events/classes/index.scss';
import { ADMIN_EVENT_CLASS_TYPES_PATH, ADMIN_EVENT_CLASSES_PATH, ADMIN_EVENT_TIME_BLOCKS_PATH, ADMIN_EVENT_NEW_CLASS_TYPE_PATH, ADMIN_EVENT_EDIT_CLASS_TYPE_PATH, ADMIN_EVENT_NEW_CLASS_PATH, ADMIN_EVENT_EDIT_CLASS_PATH } from '../../../../../../routes';
import { getAdminEventsEventClassesUrl, constructAdminEventsEventUrlParams, getAdminEventsEventTimeBlocksUrl, getAdminEventsEventClassTypesUrl } from '../../../../../../constants/adminEventsUrls';
import { navPush } from '../../../../../../utils';
import ClassTypesTab from './ClassTypesTab';
import ClassesTab from './ClassesTab';
import TimeBlocksTab from './TimeBlocksTab';
import { actionCreators as cacheTwoEventActionCreators, GetAdminEvent } from '../../../../../../store/AdminEvents/CacheTwoEvent/actions';
import NotFound from '../../../../NotFound';
import { INVALID_EVENT } from '../../../../../../constants/messages/adminEvents';
import { actionCreators as eventFormActionCreators } from '../../../../../../store/AdminEvents/Events/Event/Form/actions';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import AdminEventsCacheManager from '../../../../../../utils/cacheManagers/adminEventsCacheManager';
import { getEventId, IAdminClassRouterParams, IAdminClassTypeRouterParams } from '../../../../../../utils/helpers/adminEventsPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import { SaveState } from '../../../../../../store/Rollback/actions';
import { noOpenedModals } from '../../../../../../utils/modalHelper';
import { GenerateAdminEventsEventReportsActions } from '../../../../../../store/AdminEvents/Events/Event/Dashboard/actions';
import { reduxStoreService } from '../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isAdminEventsCacheTwoEventPopulated} from '../../../../../../utils/cachePopulatedCheckers/adminEvents';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--event-classes';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminClassTypeRouterParams & IAdminClassRouterParams, {}>
>;

class AdminEventClasses extends ComponentUpdateTemplate<ConnectedProps, {}> {
  public props: ConnectedProps;

  componentDidMount() {
    const {routes, actions} = this.props;
    actions.showAdminPageHeader(true);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        const route = routes[routes.length - 1];
    
        if (
          route.path === ADMIN_EVENT_EDIT_CLASS_TYPE_PATH ||
          route.path === ADMIN_EVENT_NEW_CLASS_TYPE_PATH
        ) {
          AdminEventsCacheManager.getInstance().loadCacheThreeClassType({
            props: this.props,
            isStateNavigated,
            isEdit: route.path === ADMIN_EVENT_EDIT_CLASS_TYPE_PATH,
          });
        } else if (
          route.path === ADMIN_EVENT_EDIT_CLASS_PATH ||
          route.path === ADMIN_EVENT_NEW_CLASS_PATH
        ) {
          AdminEventsCacheManager.getInstance().loadCacheThreeClass({
            props: this.props,
            isStateNavigated,
            isEdit: route.path === ADMIN_EVENT_EDIT_CLASS_PATH,
          });
        } else {
          AdminEventsCacheManager.getInstance().loadCacheTwoEvent({
            props: this.props,
            isEdit: true,
            context: route.path === ADMIN_EVENT_CLASSES_PATH ? AdminEventsCacheTwoEventContext.CLASSES_TAB :
            (route.path === ADMIN_EVENT_CLASS_TYPES_PATH ? AdminEventsCacheTwoEventContext.CLASS_TYPES_TAB : AdminEventsCacheTwoEventContext.TIMEBLOCKS_TAB),
            isStateNavigated,
          });
        }
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    const {routes} = this.props;
    const thisRoute = routes[routes.length - 1];
    const prevRoute = prevProps.routes[prevProps.routes.length - 1];
    const thisPath = thisRoute.path;
    const prevPath = prevRoute.path;

    if (shouldBlockActions()) return;

    if (
      thisPath !== prevPath &&
      (thisPath === ADMIN_EVENT_CLASSES_PATH || thisPath === ADMIN_EVENT_CLASS_TYPES_PATH || thisPath === ADMIN_EVENT_TIME_BLOCKS_PATH) &&
      prevPath !== ADMIN_EVENT_CLASSES_PATH && prevPath !== ADMIN_EVENT_CLASS_TYPES_PATH && prevPath !== ADMIN_EVENT_TIME_BLOCKS_PATH
    ) {
      reduxStoreService().dispatch(new SaveState());
    }
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    const {routes} = nextProps;
    const thisRoute = routes[routes.length - 1];
    const prevRoute = this.props.routes[this.props.routes.length - 1];
    const thisPath = thisRoute.path;
    const prevPath = prevRoute.path;

    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        if (thisPath === ADMIN_EVENT_EDIT_CLASS_PATH || thisPath === ADMIN_EVENT_NEW_CLASS_PATH) {
          AdminEventsCacheManager.getInstance().loadCacheThreeClass({
            props: nextProps,
            isStateNavigated,
            isEdit: thisPath === ADMIN_EVENT_EDIT_CLASS_PATH,
          });
        } else if (thisPath === ADMIN_EVENT_EDIT_CLASS_TYPE_PATH || thisPath === ADMIN_EVENT_NEW_CLASS_TYPE_PATH) {
          AdminEventsCacheManager.getInstance().loadCacheThreeClassType({
            props: nextProps,
            isStateNavigated,
            isEdit: thisPath === ADMIN_EVENT_EDIT_CLASS_TYPE_PATH,
          });
        } else {
          AdminEventsCacheManager.getInstance().loadCacheTwoEvent({
            props: nextProps,
            isEdit: true,
            context: (
              thisPath === ADMIN_EVENT_CLASSES_PATH &&
              prevPath !== ADMIN_EVENT_EDIT_CLASS_PATH &&
              prevPath !== ADMIN_EVENT_NEW_CLASS_PATH
            ) ? AdminEventsCacheTwoEventContext.CLASSES_TAB :
            (
              thisPath === ADMIN_EVENT_CLASS_TYPES_PATH &&
              prevPath !== ADMIN_EVENT_EDIT_CLASS_TYPE_PATH &&
              prevPath !== ADMIN_EVENT_NEW_CLASS_TYPE_PATH ? AdminEventsCacheTwoEventContext.CLASS_TYPES_TAB : AdminEventsCacheTwoEventContext.TIMEBLOCKS_TAB),
              isStateNavigated,
          });
        }
      }
    );
  }

  getSelectedTab = () => {
    const {routes} = this.props;
    const route = routes[routes.length - 1];

    switch (route.path) {
      case ADMIN_EVENT_CLASS_TYPES_PATH:
      case ADMIN_EVENT_NEW_CLASS_TYPE_PATH:
      case ADMIN_EVENT_EDIT_CLASS_TYPE_PATH:
        return 'class-types';
      case ADMIN_EVENT_CLASSES_PATH:
      case ADMIN_EVENT_NEW_CLASS_PATH:
      case ADMIN_EVENT_EDIT_CLASS_PATH:
        return 'classes';
      case ADMIN_EVENT_TIME_BLOCKS_PATH:
        return 'time-blocks';
    }
    
    return '';
  };

  onClickClassesTab = () => {
    const { adminEventsCacheTwoEvent } = this.props;
    if (!adminEventsCacheTwoEvent.EventsEvent) return;
    const url = getAdminEventsEventClassesUrl(constructAdminEventsEventUrlParams(this.props, getEventId(this.props.params), adminEventsCacheTwoEvent.EventsEvent.Name));

    navPush(this.props.router, url);
  };

  onClickTimeBlocksTab = () => {
    const { adminEventsCacheTwoEvent } = this.props;
    if (!adminEventsCacheTwoEvent.EventsEvent) return;
    const url = getAdminEventsEventTimeBlocksUrl(constructAdminEventsEventUrlParams(this.props, getEventId(this.props.params), adminEventsCacheTwoEvent.EventsEvent.Name));

    navPush(this.props.router, url);
  };

  onClickMain = (e) => {
    // if we are clicking on the `tab`, dont do anything in this callback (i.e. manually stop propagation)
    if (e.target.classList.contains('mdl-tabs__tab')) return;
    e.stopPropagation();
    const { routes, router} = this.props;
    const thisRoute = routes[routes.length - 1];

    if (reduxStoreService().getState().app.apiSaving <= 0 && this.props.adminEventsCacheTwoEvent.EventsEvent) {
      if (thisRoute.path === ADMIN_EVENT_NEW_CLASS_TYPE_PATH || thisRoute.path === ADMIN_EVENT_EDIT_CLASS_TYPE_PATH) {
        const backUrl = getAdminEventsEventClassTypesUrl(constructAdminEventsEventUrlParams(this.props, this.props.adminEventsCacheTwoEvent.EventsEvent.IDi, this.props.adminEventsCacheTwoEvent.EventsEvent.Name));
        navPush(router, backUrl);
      } else if (thisRoute.path === ADMIN_EVENT_NEW_CLASS_PATH || thisRoute.path === ADMIN_EVENT_EDIT_CLASS_PATH) {
        const backUrl = getAdminEventsEventClassesUrl(constructAdminEventsEventUrlParams(this.props, this.props.adminEventsCacheTwoEvent.EventsEvent.IDi, this.props.adminEventsCacheTwoEvent.EventsEvent.Name));
        navPush(router, backUrl);
      }
    }
  };

  onClickClassTypesClick = () => {
    const { adminEventsCacheTwoEvent } = this.props;
    if (!adminEventsCacheTwoEvent.EventsEvent) return;
    const url = getAdminEventsEventClassTypesUrl(constructAdminEventsEventUrlParams(this.props, getEventId(this.props.params), adminEventsCacheTwoEvent.EventsEvent.Name));

    navPush(this.props.router, url);
  };

  public render() {
    const {apiLoadingMap, adminEventsCacheTwoEvent, inert } = this.props;

    if (
      !isAdminEventsCacheTwoEventPopulated(this.props.adminEventsCacheTwoEvent) ||
      !this.props.adminEventsCacheTwoEvent.EventsEvent?.TimeBlocks_Settings
    ) {
      return <LoadingAll />;
    }

    const selectedTab = this.getSelectedTab();
    const inactive = adminEventsCacheTwoEvent.EventsEvent && adminEventsCacheTwoEvent.EventsEvent.Inactive;
    const loading = (apiLoadingMap[GetAdminEvent.requestType] || apiLoadingMap[GenerateAdminEventsEventReportsActions.requestType]) && noOpenedModals();

    return (
      <Main inert={inert} isLoading={loading} isAdminEvents onClick={this.onClickMain}>
        <MainContent className={`${namespace()}`}>
          {!inactive && <Tabs className={`${namespace()}--tabs`}>
            <Tab id={generateDOMId("tentaroo-admin-classes-tab")} selected={selectedTab === 'classes'} onClick={this.onClickClassesTab}>CLASSES</Tab>
            <Tab id={generateDOMId("tentaroo-admin-timeblocks-tab")} selected={selectedTab === 'time-blocks'} onClick={this.onClickTimeBlocksTab}>TIME BLOCKS</Tab>
            <Tab id={generateDOMId("tentaroo-admin-classtypes-tab")} selected={selectedTab === 'class-types'} onClick={this.onClickClassTypesClick}>CLASS TYPES</Tab>
          </Tabs>}
          {!inactive && selectedTab === 'classes' ? <ClassesTab inert={inert} /> : null}
          {!inactive && selectedTab === 'time-blocks' ? <TimeBlocksTab inert={inert} /> : null}
          {!inactive && selectedTab === 'class-types' ? <ClassTypesTab inert={inert} /> : null}
          {inactive && <NotFound showCMSSiteHeader message={INVALID_EVENT} />}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsCacheTwoEvent: state.adminEvents.cacheTwoEvent,
    adminEventsCacheThreeClass: state.adminEvents.cacheThreeClass,
    adminEventsCacheThreeClassTypes: state.adminEvents.cacheThreeClassType,
    eventForm: state.adminEvents.events.event.form,
    cacheZero: state.cacheZero,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...cacheOneActionCreators,
    ...cacheTwoEventActionCreators,
    ...cacheThreeClassTypeActionCreators,
    ...cacheThreeClassActionCreators,
    ...eventFormActionCreators,
  }, dispatch),
});

const ConnectedAdminEventClasses = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(AdminEventClasses);

export default withRouter<{}>(ConnectedAdminEventClasses);
