import { SaveState } from "../../store/Rollback/actions";
import { ToggleNotFound } from "../../store/App/actions";
import { getEventDataBody } from "../../constants/urls";
import { ApplicationState } from "../../store";
import { GetEventData } from "../../store/CacheThreeEvents/actions";
import { getGroupID, IEventRouterParams } from "../helpers/endUserPageHelper";
import { isIdFromUrlValid, isIdValid } from '../urlHelper';
import { captureTentarooError, captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../dataHelper';
import { reduxStoreService } from "../../store/service";
import { LoadEndUserCacheThreeEventParams } from "./helpers/models";
import {isCacheThreeEventsPopulated, isCacheZeroPopulated, isEndUserCacheOnePopulated} from "../cachePopulatedCheckers/endUser";

export const ensureCacheThreeEvents = (
  params: LoadEndUserCacheThreeEventParams,
): boolean => {
  const { props, isStateNavigated } = params;
  const groupID = getGroupID(props);
  if (
    !isIdValid(groupID) || 
    !isIdFromUrlValid(props.params.eventTypeId) ||
    !isIdFromUrlValid(props.params.eventId)
  ) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  };

  const rootState = reduxStoreService().getState() as ApplicationState;
  const { cacheTwoEvents, cacheThreeEvents, cacheZero, cacheOne } = rootState;

  let requestedData = false;

  const eventTypeId = Number(props.params.eventTypeId);
  const eventId = Number(props.params.eventId);

  if (isCacheThreeEventsPopulated(cacheThreeEvents)) {
    if (isStateNavigated) {
      reduxStoreService().dispatch(new SaveState());
    }
  } else {
    if (!isCacheZeroPopulated(cacheZero)) {
      // need everything
      reduxStoreService().dispatch(GetEventData.request({
        EventTypeID: eventTypeId,
        GroupIDi: groupID,
        GroupTS: 0,
        GetOptions: true,
        GetGroupData: true,
        EventIDi: eventId,
        GetEventTypeData: true,
      }));

      requestedData = true;
    } else if (
      isCacheZeroPopulated(cacheZero) &&
      (!cacheZero.options!.Group || !isEndUserCacheOnePopulated(cacheOne))
    ) {
      reduxStoreService().dispatch(GetEventData.request({
        EventTypeID: eventTypeId,
        GroupIDi: groupID,
        GroupTS: 0,
        GetOptions: false,
        GetGroupData: true,
        EventIDi: eventId,
        GetEventTypeData: true,
      }));

      requestedData = true;
    } else {
      if (!cacheZero.options!.Group) {
        captureTentarooError(new Error("Group not available when dispatching GetEventData in cacheThreeEventsLoader"));
        return false;
      }
      reduxStoreService().dispatch(GetEventData.request({
        EventTypeID: eventTypeId,
        GroupIDi: groupID,
        GroupTS: cacheZero.options!.Group.TS,
        GetOptions: false,
        GetGroupData: false,
        EventIDi: eventId,
        GetEventTypeData: Object.keys(cacheTwoEvents).length === 2,
      }));

      requestedData = true;
    }
  }

  return requestedData;
};

export const getCacheThreeEventsEmptyCartBody = (params: IEventRouterParams) => {
  const rootState = reduxStoreService().getState();

  let debugPayload: TentarooDebugPayload | undefined;

  if (!isIdFromUrlValid(params.eventTypeId) || !isIdFromUrlValid(params.eventId)) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Invalid eventTypeId OR eventId in getCacheThreeEventsEmptyCartBody");
  }

  const eventTypeId = Number(params.eventTypeId);
  const eventId = Number(params.eventId);

  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when getCacheThreeEventsEmptyCartBody");
  }
  if (!rootState.cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when getCacheThreeEventsEmptyCartBody");
  }

  const body: any = getEventDataBody({
    EventTypeID: eventTypeId,
    GroupIDi: rootState.cacheZero.options?.Group?.IDi as any,
    GroupTS: rootState.cacheZero.options?.Group?.TS as any,
    GetOptions: false,
    GetGroupData: true,
    EventIDi: eventId,
    GetEventTypeData: true
  });
  body.AppState.EmptyCart = true;


  if (debugPayload) {
    return withTentarooDebugPayload(body, debugPayload);
  } else {
    return body;
  }
};
