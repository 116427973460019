import {RefundOrFeeActions} from './actions';
import {getAddRefundOrFeeUrl} from '../../../../constants/urls';
import {makeRequestEpic} from '../../../Validation/epicCreator';

export const refundOrFeeEpic = makeRequestEpic(
  RefundOrFeeActions,
  getAddRefundOrFeeUrl,
  undefined,
  true,
  "Item added to cart",
);
