import * as React from 'react';
import '../../../../styles/elements/gl-account/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton, Vignette} from '../../index';
import { CloseIcon, OptionsIcon, GLAccountIcon, EditIcon } from '../../../../components/Icons';
import { SELECTED_ITEM_DELETED } from '../../../../constants/messages/generic';
import { CardCategory } from '../../Card';
import { namespace as cardNS } from "../../Card/constants";
import { Row } from '../../Grid';
import { GLAccount } from '../../../../models/api/options';
import { formatAccountNum } from '../../../../utils/helpers/adminFacilityLocationPageHelper';
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--admin-facility--gl-account--card';

export enum GLAccountCardType {
  MODAL_SELECT='modal-select',
  LIST='list'
}

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin';
  type: GLAccountCardType;
  onEdit?: (account: GLAccount) => void;
  onRestore?: (account: GLAccount) => void;
  onRemove?: () => void;
  onDelete?: (account: GLAccount) => void;
  onClick?: (account: GLAccount) => void;
  disabled?: boolean;
  routes?: any;
  account: GLAccount;
  showDeletedPrompt?: boolean;
  compact?: boolean;
  params?: any;
  cardCategory?: CardCategory;
}

const GLAccountCard: React.SFC<Props> = ({ children, type, template, onEdit, onDelete, onRemove, onRestore, routes, onClick, compact, showDeletedPrompt, cardCategory, params, disabled, account, ...props }: Props): React.ReactElement<Props> => {
  let className = attribute(namespace(), undefined, props);

  className += ` ${type}`;
  const deleted = account.Inactive;
  if (deleted) className += ' deleted';
  if (showDeletedPrompt) className += ' show-deleted-prompt';

  const onCardClick = (e) => {
    if (disabled) return;
    switch (type) {
      case GLAccountCardType.LIST:
        onClick && onClick(account);
        break;
      default:
        break;
    }
  };

  const getSubtitle = () => {
    return formatAccountNum(account.AccountNum);
  };

  const onRemoveClick = () => {
    onRemove && onRemove();
  };

  const renderSelected = () => {
    return (
      <Row className={`${namespace()}--main`}>
        <Row className={`${namespace()}--main--text`}>
          <div className={`${namespace()}--main--text--title`}>{account.Name}</div>
          <div className={`${namespace()}--main--text--subtitle`}>{getSubtitle()}</div>
        </Row>
        {!account.Inactive && <ActionButton
          id={generateDOMId(`gl-account-card-edit-btn-${account.ID}`)}
          disabled={disabled}
          onClick={onEditClick}
          icon={EditIcon}
          className={`${namespace()}--main--edit ${cardNS()}--edit ${cardCategory} ${disabled ? 'disabled' : ''}`}
        />}
        <ActionButton
          id={generateDOMId("gl-account-card-remove-btn")}
          disabled={disabled}
          onClick={onRemoveClick}
          icon={CloseIcon}
          className={`${namespace()}--main--close ${cardNS()}--close ${cardCategory} ${disabled ? 'disabled' : ''}`}
        />
      </Row>
    );
  };

  const onEditClick = (e) => {
    e.stopPropagation();
    !disabled && onEdit && onEdit(account);
  };

  const onRestoreClick = (e) => {
    e.stopPropagation();
    !disabled && onRestore && onRestore(account);
  };

  const onDeleteClick = (e) => {
    e.stopPropagation();
    !disabled && onDelete && onDelete(account);
  };

  const renderInList = () => {
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>{account.Name}</Row>
          <Row className={`${namespace()}--main--text--second-row`}>
            <div className={`${namespace()}--main--text--subtitle`}>{getSubtitle()}</div>
          </Row>
        </div>
        <ActionButton
          id={generateDOMId(`glaccount-${account.ID}-edit-btn`)}
          disabled={disabled}
          onClick={onEditClick}
          icon={EditIcon}
          className={`${namespace()}--main--edit ${cardNS()}--edit ${cardCategory} ${disabled ? 'disabled' : ''}`}
        />
      </Row>
    );
  };

  let selected = false;


  return (
    <Card
      className={className}
      component={<section/>}
      template={template}
      selected={!!selected}
      padding="none"
      category={cardCategory || CardCategory.LIST}
    >
      <div className={`${namespace()}--wrapper`}
          onClick={onCardClick}>
        {type === GLAccountCardType.MODAL_SELECT && <GLAccountIcon className='gl-account-icon' />}
        {type === GLAccountCardType.MODAL_SELECT && renderSelected()}
        {type === GLAccountCardType.LIST && renderInList()}
      </div>
      {deleted && showDeletedPrompt && <section className={`${namespace()}--deleted-prompt`}>{SELECTED_ITEM_DELETED}</section>}
    </Card>
  );
};

GLAccountCard.defaultProps = {
  template: 'mobile-no-margin',
};

export default GLAccountCard;