import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { isNode } from '../../../utils';
import '../../../styles/elements/slide-show/index.scss';

import {namespace} from "./constants";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

interface State {
  first?: boolean;
  selected: number;
  direction?: number;
  previous?: number;
};

class SlideShow extends React.Component<Props, State> {
  public props: Props;
  public state: State = {
    first: true,
    selected: 0
  };
  public refs: {
    swipe: React.ReactInstance
  };
  private hammerTime: any;

  public handlePageClick = (index: number) => {
    const direction = (index > this.state.selected) ? 1 : -1;
    this.setState({ selected: index, direction, previous: this.state.selected, first: false });
  };

  public handlePrevClick = () => {
    const max: number = React.Children.count(this.props.children);
    const next = (this.state.selected - 1) < 0 ? max - 1 : this.state.selected - 1;
    this.setState({ selected: next, direction: -1, previous: this.state.selected, first: false });
  };

  public handleNextClick = () => {
    const max: number = React.Children.count(this.props.children);
    const next = (this.state.selected + 1) >= max ? 0 : this.state.selected + 1;
    this.setState({ selected: next, direction: 1, previous: this.state.selected, first: false });
  };

  public componentDidMount() {
    if (!isNode()) {
      const Hammer: any = require('hammerjs');
      this.hammerTime = new Hammer(this.refs.swipe, {});
      this.hammerTime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
      this.hammerTime.on('swipe', (event) => {
        if (event.direction === Hammer.DIRECTION_LEFT) this.handleNextClick();
        else if (event.direction === Hammer.DIRECTION_RIGHT) this.handlePrevClick();
      });
    }
  }

  public componentWillUnmount() {
    if (!isNode()) {
      this.hammerTime.destroy();
    }
  }

  public render() {
    const { children } = this.props;
    const { first, selected, direction, previous } = this.state;

    let selectedClassName = `${namespace()}--slides--slide-container`;
    let previousClassName = `${namespace()}--slides--slide-container`;
    if (!first && direction && direction > 0) {
      selectedClassName += ' enter';
      previousClassName += ' leave';
    } else if (!first && direction && direction < 0) {
      selectedClassName += ' enter-reverse';
      previousClassName += ' leave-reverse';
    }

    // put these arrows back down below when we have many images
    /*
          <a className={`${namespace()}--arrow ${namespace()}--arrow--left`} onClick={this.handlePrevClick}>
            <ArrowLeft className={`${namespace()}--arrow--image`}/>
          </a>

          <a className={`${namespace()}--arrow ${namespace()}--arrow--right`} onClick={this.handleNextClick}>
            <ArrowRight className={`${namespace()}--arrow--image`}/>
          </a>
     */

    return (
      <div className={mergeClassNames(namespace(), this.props)}>
        <div className={`${namespace()}--container`}>
          <ul className={`${namespace()}--slides`} ref="swipe">
            {React.Children.map(children, (child: React.ReactElement<any>, index: number) => {
              if (index === selected) return <li className={selectedClassName} key={index}>{child}</li>;
              else if (index === previous) return <li className={previousClassName} key={index}>{child}</li>;
            })}
          </ul>
        </div>
        <div className={`${namespace()}--pagination`}>
          <ul className={`${namespace()}--pagination--list`}>
            {React.Children.map(children, this.renderPage)}
          </ul>
        </div>
      </div>
    );
  }

  private renderPage = (child, index: number) => {
    const selected = this.state.selected === index;
    // Put link dot back below when multiple items
    /*
          <div className={`${namespace()}--pagination--list--item--link--dot`}/>
     */


    return (
      <li className={`${namespace()}--pagination--list--item`}>
        <a
          onClick={() => this.handlePageClick(index)}
          className={`${namespace()}--pagination--list--item--link` + (selected ? ' selected' : '')}
        >
        </a>
      </li>
    );
  };
}

export default SlideShow;
export { default as Slide } from './Slide';
