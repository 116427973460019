import {
  ApiTypeSubmitActions,
  EventRegisterParticipantSelectType,
  EventRegisterParticipantShowError,
  ResetReservationType
} from "./actions";
import {EventRegisterParticipantReset} from "../Main/actions";
import {APISuccess} from "../../../../../Validation/actionCreator";
import {ApiSubmitActions} from "../../../Modals/RegisterOverrideFee/actions";
import {ApiRosterSubmitActions} from "../Roster/actions";
import {EventParticipantCalc} from "../../../../../CacheFourEventsParticipants/actions";
import { captureTentarooError } from '../../../../../../utils/dataHelper';
import { Reducer } from "redux";
import { isActionType } from "../../../../../../utils/StrongActions";

export interface EventRegisterParticipantTypeState {
  selectedTypeID?: number;
  SubmitErrorMessage?: string;
  overwrittenTypes: {
    [pID: number]: {
      Amount: number;
      AmountMin: number;
    };
  }
  forceRecalc?: boolean;
}

const getInitialState = () => ({
  overwrittenTypes: {}
});

const EventRegisterParticipantType: Reducer<EventRegisterParticipantTypeState> = (state: EventRegisterParticipantTypeState, act: any) => {
  if (isActionType(act, EventRegisterParticipantSelectType)) {
    return {...state, selectedTypeID: Number(act.pTypeID), SubmitErrorMessage: undefined};
  } else if (isActionType(act, EventRegisterParticipantShowError)) {
    return {...state, SubmitErrorMessage: act.errorMessage};
  } else if (isActionType(act, EventRegisterParticipantReset)) {
    return {...getInitialState()};
  } else if (act.type === ApiSubmitActions.successType) {
    const action = act as APISuccess;
    if (state.selectedTypeID) {
      return {
        ...state,
        overwrittenTypes: {
          ...state.overwrittenTypes,
          [state.selectedTypeID]: {
            Amount: action.response.response.EventRegistrationPerson.Amount,
            AmountMin: action.response.response.EventRegistrationPerson.AmountMin
          }
        }
      };
    } else {
      captureTentarooError(new Error('No selectedTypeID when performing an override action'));
    }
    return state;
  } else if (act.type === EventParticipantCalc.successType) {
    const action = act as APISuccess;
    if (action.response.response.EventRegistrationPerson.ParticipantTypeID) {
      return {
        ...state,
        selectedTypeID: action.response.response.EventRegistrationPerson.ParticipantTypeID
      };
    }
    return state;
  } else if (act.type === ApiRosterSubmitActions.successType) {
    return {...state, forceRecalc: true};
  } else if (act.type === ApiTypeSubmitActions.successType) {
    return {...state, forceRecalc: false};
  } else if (isActionType(act, ResetReservationType)) {
  // } else if (act.type === ApiRosterSubmitActions.successType) {
  //   // if we're editing, don't reset the selected pTypes
  //   const sp = reduxStoreService().getState().routing.locationBeforeTransitions.pathname.split('/');
  //   if (sp[sp.length - 1] === E_RER_PARTICIPANT.EDIT) {
  //     return state;
  //   }
  //   return {...getInitialState()};
    return {...getInitialState()};
  }
  // if I ever succesfully recalc, the selectedTypeID should be overwriten by the values in EventRegistrationPerson
  // event if I didn't override... this behaviour should still be ok
  return state || {...getInitialState()};
};

export default EventRegisterParticipantType;
