import * as React from 'react';
import { namespace } from './index';
import { TextField, Row, Column, CreditCard, CreditCardTypes, Select, Text } from '../../../Elements';
import {CacheOneState} from "../../../../store/CacheOne";
import {CheckoutState} from "../../../../store/Checkout";
import {Actions as AppActions} from "../../../../store/App/actions";
import {Actions as CacheZeroActions} from "../../../../store/CacheZero/actions";
import {Actions as CacheOneActions} from "../../../../store/CacheOne/actions";
import {Actions} from "../../../../store/Checkout/actions";

export interface CreditCardProps {
  cacheOne: CacheOneState;
  checkout: CheckoutState;
  actions: Actions & AppActions & CacheOneActions & CacheZeroActions;
  ShowAmEx: boolean;
  ShowCCDiscover: boolean;
  disabled: boolean;
}

const CreditCardComponent: React.SFC<any> = (props: CreditCardProps): React.ReactElement<CreditCardProps> => (
  <div>
    <Row>
      <Column span={3} mobileSpan={6}>
        <Select label="Exp Month"
                disabled={props.disabled}
                onChangeValue={props.actions.updateValue}
                value={props.checkout.ActiveForm.CCExpireMonth}
                validationRules={props.checkout.ValidationRules.CCExpireMonth}
                isNumber
        />
      </Column>
      <Column span={3} mobileSpan={6}>
        <Select label="Exp Year"
                disabled={props.disabled}
                onChangeValue={props.actions.updateValue}
                value={props.checkout.ActiveForm.CCExpireYear}
                validationRules={props.checkout.ValidationRules.CCExpireYear}
                isNumber
        />
      </Column>
      <Column span={3} mobileSpan={6}>
        <TextField value={props.checkout.ActiveForm.CCCode}
                   label="CVV2 / CVC"
                   disabled={props.disabled}
                   onChange={props.actions.simpleUpdate}
                   onBlur={props.actions.updateValue}
                   validationRules={props.checkout.ValidationRules.CCCode}
        />
      </Column>
    </Row>
  </div>
);

export default CreditCardComponent;
