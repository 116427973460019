import * as React from 'react';
import { Tab as MdlTab } from 'react-mdl';
import { withRouter, InjectedRouter } from 'react-router';
import {mergeClassNames} from '@tentaroo/shared';

import { namespace as ns } from './constants';

import '../../../styles/elements/tabs/tab.scss';

export const namespace = (): string => `${ns()}--tab`;

export interface TabProps {
  className?: string;
  children?: React.ReactNode;
  selected?: boolean;
  onClick?: () => void;
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
  horizontalAlign?: 'start' | 'center' | 'end';
  id?: string;
}

class Tab extends React.Component<TabProps, {}> {
  public props: Readonly<TabProps> & { router: InjectedRouter };

  // @todo: this blocks updating the count on register classes..
  /*
  shouldComponentUpdate(nextProps: TabProps) {
    if (nextProps.selected !== this.props.selected) {
      return true;
    }
    return false;
  }
  */

  public render() {
    const { children, id, onClick, router, selected, mobile, tablet, desktop, horizontalAlign } = this.props;

    let className = mergeClassNames(namespace(), this.props);
    if (mobile || tablet || desktop) {
      className += ' hide-all-except';
      if (mobile) className += ' show-mobile';
      if (tablet) className += ' show-tablet';
      if (desktop) className += ' show-desktop';
    }

    if (horizontalAlign) className += ` horizontal-align-${horizontalAlign}`;
    if (selected) className += ' selected';

    return (
      <MdlTab
        cssPrefix="mdl-tabs"
        active={!!selected}
        id={id}
        onTabClick={() => {
          if (onClick) onClick();
        }}
        className={className}
      >
        {children}
      </MdlTab>
    );
  }
}

export default withRouter(Tab as any);
