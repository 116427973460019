import { createSelector } from 'reselect';
import { ResourcesToggleFilterTabs, ResourcesModalFilter, ResetResourcesModal, InitResourcesModal } from './actions';
import { ApplicationState } from '../../../../../store';
import { CMSResource, CMSResourceCategory } from '../../../../../models/api/adminCMSCacheOne';
import { IValidator, FormDefinition } from './validation';
import { apiValidationReducerCreator } from '../../../../../store/Validation/reducerCreator';
import { SUFFIX } from './actions';
import { resourceCategoriesSelector } from '../ResourceCategory';
import { resourceFilterMatch } from '../MultipleResources';
import { compareDataByRowNum } from '../../../../../utils/dateHelper';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import {setDefaults} from '../../../../../utils/validator';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface ResourceModalActiveFormState {
  SelectedResourceCaetgoryID?: number;
}

export interface ResourceModalState {
  isRefreshing: boolean;
  filterString: string;
  isTextSearching: boolean;
  ValidationRules: IValidator;
  ActiveForm: ResourceModalActiveFormState;
}
const getInitialState = () => ({
  isRefreshing: false,
  filterString: '',
  isTextSearching: false,
  ValidationRules: {...FormDefinition},
  ActiveForm: {},
});

export const resourcesSelector = (state: ApplicationState) => {
  if (state.adminCMSSite && state.adminCMSSite.cacheOne) return state.adminCMSSite.cacheOne.CMSResources;
  return [];
};

const filterSelector = (state: ApplicationState) => state.adminCMSSite.resources.modals.resource.filterString;

const selectedResourceCategorySelector = (state: ApplicationState) => state.adminCMSSite.resources.modals.resource.ActiveForm.SelectedResourceCaetgoryID;

export const makeFilteredResourcesSelector = () => {
  return createSelector(
    [resourcesSelector, filterSelector, selectedResourceCategorySelector, resourceCategoriesSelector],
    (resources: Array<CMSResource>, filter: string, selectedResourceCategoryID: number, resourceCategories: CMSResourceCategory[]) => {
      let result;
      if (selectedResourceCategoryID === 0) {
        result = resources.filter((r) => {
          const resourceCategory = resourceCategories.find((rc) => rc.ID === r.CategoryID);
          return !r.Inactive && (resourceCategory && !resourceCategory.Inactive) && resourceFilterMatch(r, filter);
        });
      } else {
        result = resources.filter((r) => {
          const resourceCategory = resourceCategories.find((rc) => rc.ID === r.CategoryID);
          return r.CategoryID === selectedResourceCategoryID && !r.Inactive && (resourceCategory && !resourceCategory.Inactive) && resourceFilterMatch(r, filter);
        });
      }
      result.sort(compareDataByRowNum);

      return result;
    }
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const ResourceModalReducer: Reducer<ResourceModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);
  if (isActionType(act, ResourcesToggleFilterTabs)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, ResourcesModalFilter)) {
    return {
      ...state,
      filterString: act.filterString,
    };
  } else if (isActionType(act, ResetResourcesModal)) {
    return getInitialState();
  } else if (isActionType(act, InitResourcesModal)) {
    return setDefaults(act.rootState, getInitialState());
  }
  return state || getInitialState();
};

export default ResourceModalReducer;