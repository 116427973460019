import { GetSiteMenuItemParams } from "../../../constants/urls";
import { CLEAR_CACHE_PREFIX, createRequestActions } from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { ADMIN_CMS_MODULE_SUFFIX } from "../CacheOne/actions";
import { HideDrawer, SilentCancelAllAction } from '../../../store/App/actions';
import { UPDATE_CMS_SITE_MENU_FORM_SUFFIX } from "../Menus/SiteMenuItem/Form/actions";
import { typeName, Action } from "../../../utils/StrongActions";


export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__CACHE_TWO_SITE_MENUS';
export const SUFFIX_SAVE = ADMIN_CMS_MODULE_SUFFIX + '__CACHE_TWO_SITE_MENUS_SAVING';

export const GetSiteMenuItem = createRequestActions(SUFFIX + UPDATE_CMS_SITE_MENU_FORM_SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminCMSCacheTwoSiteMenuItem extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  getSiteMenuItem: (params: GetSiteMenuItemParams): ActionCreator => dispatch => {
    dispatch(GetSiteMenuItem.request(params));
  },
  refreshSiteMenuItem: (params: GetSiteMenuItemParams): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    dispatch(GetSiteMenuItem.request(params));
  },
  clearAdminCMSCacheTwoSiteMenuItem: (): ActionCreator => dispatch => {
    dispatch(new ClearAdminCMSCacheTwoSiteMenuItem());
  },
};