import { createSelector } from 'reselect';
import { MultipleResourcesToggleFilterTabs, MultipleResourcesModalFilter, ResetMultipleResourcesModal, ToggleResourceSelect, SelectAll, Deselect, InitMultipleResourcesModal } from './actions';
import { ApplicationState } from '../../../..';
import { IValidator, FormDefinition } from './validation';
import { apiValidationReducerCreator } from '../../../../Validation/reducerCreator';
import { SUFFIX } from './actions';
import { resourcesSelector } from '../../Home';
import { CMSResource, CMSResourceCategory } from '../../../../../models/api/adminCMSCacheOne';
import { resourceCategoriesSelector } from '../ResourceCategory';
import { compareDataByRowNum } from '../../../../../utils/dateHelper';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import {setDefaults} from '../../../../../utils/validator';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface MultipleResourcesModalActiveFormState {
  SelectedResourceCategoryID?: number;
  SelectedResourceIds: number[];
}

export interface MultipleResourcesModalState {
  isRefreshing: boolean;
  filterString: string;
  isTextSearching: boolean;
  ValidationRules: IValidator;
  ActiveForm: MultipleResourcesModalActiveFormState;
}
const getInitialState = () => ({
  isRefreshing: false,
  filterString: '',
  isTextSearching: false,
  ValidationRules: {...FormDefinition},
  ActiveForm: {
    SelectedResourceIds: [],
  },
});

const filterSelector = (state: ApplicationState) => state.adminCMSSite.resources.modals.multipleResources.filterString;

const selectedResourceCategorySelector = (state: ApplicationState) => state.adminCMSSite.resources.modals.multipleResources.ActiveForm.SelectedResourceCategoryID;

const selectedResourceIdsSelector = (state: ApplicationState) => state.adminCMSSite.resources.modals.multipleResources.ActiveForm.SelectedResourceIds;

export const resourceFilterMatch = (resource: CMSResource, filter: string) => {
  const f = filter.toLowerCase();
  if (!f || f === '') return true;
  const nameMatches = resource.Name && resource.Name.toLowerCase().includes(f);
  const descMatches = resource.Description && resource.Description.toLowerCase().includes(f);
  const filenameMatches = resource.File && resource.File.Filename.toLowerCase().includes(f);
  return nameMatches || descMatches || filenameMatches;
};

export const makeFilteredResourcesSelector = () => {
  return createSelector(
    [resourcesSelector, selectedResourceCategorySelector, filterSelector, resourceCategoriesSelector, selectedResourceIdsSelector],
  (resources: CMSResource[], selectedResourceCategoryID: number, filter: string, resourceCategories: CMSResourceCategory[], selectedResourceIds) => {
    let result;
    if (selectedResourceCategoryID === 0) {
      result = resources.filter((r) => {
        const resourceCategory = resourceCategories.find((rc) => rc.ID === r.CategoryID);
        return !r.Inactive && (resourceCategory && !resourceCategory.Inactive) && resourceFilterMatch(r, filter);
      });
    } else {
      result = resources.filter((r) => {
        const resourceCategory = resourceCategories.find((rc) => rc.ID === r.CategoryID);
        return r.CategoryID === selectedResourceCategoryID && !r.Inactive && (resourceCategory && !resourceCategory.Inactive) && resourceFilterMatch(r, filter);
      });
    }
    result.sort(compareDataByRowNum);
    const a = result.map((item) => {
      const existed = selectedResourceIds.findIndex((rId) => rId === item.ID);
      return {...item, selected: existed !== -1};
    });

    return a;
  });
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const MultipleResourcesModalReducer: Reducer<MultipleResourcesModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);
  if (isActionType(act, MultipleResourcesToggleFilterTabs)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, InitMultipleResourcesModal)) {
    // First set default values
    const stateWithDefaultValue = setDefaults(act.rootState, state);

    // Then override default values with passed in data
    return {
      ...stateWithDefaultValue,
      ActiveForm: {
        ...stateWithDefaultValue.ActiveForm,
        SelectedResourceIds: act.resourceIds,
        SelectedResourceCategoryID: act.resourceCategoryId || 0,
      },
    };
  } else if (isActionType(act, MultipleResourcesModalFilter)) {
    return {
      ...state,
      filterString: act.filterString,
    };
  } else if (isActionType(act, ToggleResourceSelect)) {
    const resourceIds = [...state.ActiveForm.SelectedResourceIds];
    if (act.show) {
      const existed = resourceIds.find((id) => id === act.resourceId);
      if (!existed && existed !== -1) {
        resourceIds.push(act.resourceId);
      }
    } else {
      const index = resourceIds.findIndex((id) => id === act.resourceId);
      if (index !== -1) {
        resourceIds.splice(index, 1);
      }
    }
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedResourceIds: resourceIds,
      },
    };
  } else if (isActionType(act, SelectAll)) {
    const resourceIds = [...state.ActiveForm.SelectedResourceIds];

    act.resourceIds.forEach((id) => {
      const existed = resourceIds.find((rId) => rId === id);
      if (!existed && id !== -1) {
        resourceIds.push(id);
      }
    });
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedResourceIds: resourceIds,
      },
    };
  } else if (isActionType(act, Deselect)) {
    const resourceIds = [...state.ActiveForm.SelectedResourceIds];
    const newResourceIds: number[] = [];

    resourceIds.forEach((id, index) => {
      const existed = act.resourceIds.find((rId) => rId === id);
      if (!existed && id !== -1) {
        newResourceIds.push(id);
      }
    });
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedResourceIds: newResourceIds,
      },
    };
  } else if (isActionType(act, ResetMultipleResourcesModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default MultipleResourcesModalReducer;