import * as React from 'react';

import { Form, Row, Column, TextField, ModalSelect, Select, RadioGroupOption, Text, Switch, Alert } from '../../../../../Elements';
import ResourceCard, { ResourceCardType } from '../../../../../Elements/Resource/Card';
import { IValidator } from '../../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/validation';
import { SiteMenuItemActiveForm } from '../../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form';
import { LinkTypeValue, CMSResource, CMSPage, SiteEventCategory } from '../../../../../../models/api/adminCMSCacheOne';
import CMSPageCard, { PageCardType } from '../../../../../Elements/CMSPage/Card';
import { AdminCMSSiteCacheOneState } from '../../../../../../store/AdminCMSSite/CacheOne';
import { CacheZeroState } from '../../../../../../store/CacheZero';
import { checkResourcePermission } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { CardCategory } from '../../../../../../components/Elements/Card';
import { ModalProps, ModalTypes } from '../../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { Validator } from '../../../../../../utils/validator/models';

export const namespace = (): string => 'cms--site-menu-item-form';

export interface Props {
  action: 'add' | 'edit';
  parentContext?: 'edit-page';
  disabled: boolean;
  disabledReason?: string;
  cacheZero: CacheZeroState;
  ActiveForm: SiteMenuItemActiveForm;
  SubmitErrorMessage?: string;
  ValidationRules: IValidator;
  selectedResource?: CMSResource | null;
  selectedEventCategory?: SiteEventCategory | null;
  adminCMSCacheOne: AdminCMSSiteCacheOneState;
  selectedPage?: CMSPage;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
    pushSelectPageModal: () => any,
    updateSelectedPage(pageId: number),
    pushResourcesModal: () => any,
    pushFormModal: (modal: ModalTypes, isEdit: boolean, props?: ModalProps) => any,
  };
}

class SiteMenuItemForm extends React.Component<Props, {}> {
  public props: Props;

  // common fields
  renderName = () => {
    const {ActiveForm, disabled, reduxActions, ValidationRules} = this.props;
    return (
      <Row>
        <Column span={12} mobileSpan={12}>
            <TextField
              label="Name"
              id={generateDOMId("menu-name-field")}
              disabled={disabled}
              onChange={reduxActions.simpleUpdate}
              onBlur={reduxActions.updateValue}
              validationRules={ValidationRules.Name}
              value={ActiveForm.Name}/>
        </Column>
      </Row>
    );
  };
  renderTitle = () => {
    const {ActiveForm, disabled, reduxActions, ValidationRules} = this.props;
    return (
      <Row>
        <Column span={12} mobileSpan={12}>
            <TextField
              label="Title"
              disabled={disabled}
              onChange={reduxActions.simpleUpdate}
              onBlur={reduxActions.updateValue}
              validationRules={ValidationRules.Title}
              value={ActiveForm.Title}/>
        </Column>
      </Row>
    );
  };
  renderMenuLevel = () => {
    const {ActiveForm, disabled, reduxActions, ValidationRules} = this.props;
    return (
      <Row>
        <Select
          label="Menu Level"
          disabled={disabled}
          onChangeValue={reduxActions.updateValue}
          value={ActiveForm.MenuLevel}
          validationRules={ValidationRules.MenuLevel}
          isNumber />
      </Row>
    );
  };
  renderParent = () => {
    const {ActiveForm, disabled, reduxActions, ValidationRules} = this.props;
    return (
      <Row>
        <Select
          label="Parent"
          disabled={disabled}
          onChangeValue={reduxActions.updateValue}
          value={ActiveForm.ParentID}
          validationRules={ValidationRules.ParentID}
          isNumber />
      </Row>
    );
  };
  renderLinkType = () => {
    const {ActiveForm, disabled, reduxActions, ValidationRules, parentContext} = this.props;
    return (parentContext === 'edit-page') ? null : (
      <Row>
        <Select
          label="Link Type"
          disabled={disabled}
          onChangeValue={reduxActions.updateValue}
          value={ActiveForm.LinkTypeID}
          validationRules={ValidationRules.LinkTypeID}
          isNumber />
      </Row>
    );
  };

  renderMenuStyle = () => {
    const {ActiveForm, disabled, reduxActions, ValidationRules} = this.props;
    return ActiveForm.MenuLevel === 1 ? (
      <Row>
        <Column layout='horizontal' span={12} mobileSpan={12}>
          <TextField
            label="Styles"
            disabled={disabled}
            onChange={reduxActions.simpleUpdate}
            onBlur={reduxActions.updateValue}
            validationRules={ValidationRules.MenuStyle}
            rows={5}
            value={ActiveForm.MenuStyle}/>
        </Column>
      </Row>
    ) : null;
  };

  renderIsAlignRight = () => {
    const {ActiveForm, disabled, reduxActions, ValidationRules} = this.props;
    return ActiveForm.MenuLevel === 1 ? (
      <Row mobileMarginBottom={0} marginBottom={16}>
        <Column className="new-design-switch-wrapper" span={12} mobileSpan={12}>
          <Switch
              label="Right-Align Menu On Page"
              newDesign
              disabled={disabled}
              onChange={reduxActions.updateValue}
              value={!!ActiveForm.IsAlignRight}
              validationRules={ValidationRules.IsAlignRight}
            />
        </Column>
      </Row>
    ) : null;
  };
  renderIsLinkNewWidnow = () => {
    const {ActiveForm, disabled, reduxActions, ValidationRules} = this.props;
    return (
      <Row marginBottom={16}>
        <Column mobileMarginBottom={0} layout='horizontal' span={6} mobileSpan={12}>
          <RadioGroupOption
            value={!!!ActiveForm.IsLinkNewWindow}
            selected={!!!ActiveForm.IsLinkNewWindow}
            ValidationRules={ValidationRules.IsLinkNewWindow}
            rawValue={false}
            disabled={disabled}
            onChange={reduxActions.updateValue}
            newDesign
            label={<Text color='dark-gray' weight="regular" size={16} marginBottom={0}>Same Window</Text>}
          />
        </Column>
        <Column layout='horizontal' span={6} mobileSpan={12}>
          <RadioGroupOption
            value={!!ActiveForm.IsLinkNewWindow}
            selected={!!ActiveForm.IsLinkNewWindow}
            ValidationRules={ValidationRules.IsLinkNewWindow}
            rawValue={true}
            onChange={reduxActions.updateValue}
            disabled={disabled}
            newDesign
            label={<Text color='dark-gray' weight="regular"  size={16} marginBottom={0}>New Window</Text>}
          />
        </Column>
      </Row>
    );
  };

  onRemovePage = () => {
    this.props.reduxActions.updateSelectedPage(-1);
    this.props.reduxActions.updateValue(undefined, this.props.ValidationRules.PageID);
  };
  onClickSelectPageButton = () => {
    this.props.reduxActions.pushSelectPageModal();
  };
  renderPage = () => {
    const {ActiveForm, disabled, disabledReason, SubmitErrorMessage, selectedPage, adminCMSCacheOne, ValidationRules, parentContext} = this.props;
    const pageType = adminCMSCacheOne.PageTypes && selectedPage ? adminCMSCacheOne.PageTypes.find((p) => p.ID === selectedPage.PageTypeID) : null;
    
    return (
      <Form
        onSubmit={e => e.preventDefault()}>
        {disabledReason ? <Alert className={`${namespace()}--alert`}>{disabledReason}</Alert> : null}
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        {this.renderName()}
        {this.renderMenuLevel()}
        {ActiveForm.MenuLevel === 2 ? this.renderParent() : null}
        {this.renderLinkType()}
        {parentContext === 'edit-page' ? null : <Row>
          <Column span={12} mobileSpan={12}>
            <ModalSelect
              label="Page"
              selectButtonLabel="SELECT PAGE"
              disabled={disabled}
              paddingBottom={24}
              mobilePaddingBottom={24}
              hideError={!!selectedPage}
              selectedCard={selectedPage ?
                <CMSPageCard
                  type={PageCardType.IN_MENU}
                  disabled={disabled}
                  showDeletedPrompt
                  page={selectedPage}
                  pageTypeName={pageType ? pageType.Name : ''}
                  onRemove={this.onRemovePage}
                  cardCategory={CardCategory.LIST_MOBILE}
                /> : null}
              validationRules={ValidationRules.PageID}
              onClickSelectButton={this.onClickSelectPageButton}
              value={ActiveForm.PageID}/>
          </Column>
        </Row>}
        <div className={`${namespace()}--field-label`}>Open In</div>
        {this.renderIsLinkNewWidnow()}
        {this.renderIsAlignRight()}
        {this.renderMenuStyle()}
      </Form>
    );
  };

  onRemoveResource = () => {
    this.props.reduxActions.updateValue(undefined, this.props.ValidationRules.ResourceID);
  };
  onClickSelectResourceButton = () => {
    this.props.reduxActions.pushResourcesModal();
  };
  onEditResource = () => {
    const {selectedResource, cacheZero, adminCMSCacheOne} = this.props;
    if (!selectedResource) return;
    checkResourcePermission(
      () => {
        this.props.reduxActions.pushFormModal(ModalTypes.RESOURCE_FORM, true, {id: selectedResource.ID});
      },
      cacheZero,
      adminCMSCacheOne,
      selectedResource.SiteID,
      true,
    );
  };
  renderResource = () => {
    const {ActiveForm, disabled, disabledReason, selectedResource, SubmitErrorMessage, ValidationRules} = this.props;
    
    return (
      <Form
        onSubmit={e => e.preventDefault()}>
        {disabledReason ? <Alert className={`${namespace()}--alert`}>{disabledReason}</Alert> : null}
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        {this.renderName()}
        {this.renderMenuLevel()}
        {ActiveForm.MenuLevel === 2 ? this.renderParent() : null}
        {this.renderLinkType()}
        <Row>
          <Column span={12} mobileSpan={12}>
            <ModalSelect
              label="Resource"
              selectButtonLabel="SELECT RESOURCE"
              disabled={disabled}
              paddingBottom={24}
              mobilePaddingBottom={24}
              hideError={!!selectedResource}
              selectedCard={selectedResource ?
                <ResourceCard
                  type={ResourceCardType.MODAL_SELECT}
                  showDeletedPrompt
                  marginTop={12}
                  cardCategory={CardCategory.LIST_MOBILE}
                  disabled={disabled}
                  onRemove={this.onRemoveResource}
                  onEdit={this.onEditResource}
                  resource={selectedResource} /> : null}
              validationRules={ValidationRules.ResourceID}
              onClickSelectButton={this.onClickSelectResourceButton}
              value={ActiveForm.ResourceID}/>
          </Column>
        </Row>
        <div className={`${namespace()}--field-label`}>Open In</div>
        {this.renderIsLinkNewWidnow()}
        {this.renderIsAlignRight()}
        {this.renderMenuStyle()}
      </Form>
    );
  };
  renderCalendar = () => {
    const {ActiveForm, disabled, disabledReason, SubmitErrorMessage, reduxActions, ValidationRules, selectedEventCategory} = this.props;
    return (
      <Form
        onSubmit={e => e.preventDefault()}>
        {disabledReason ? <Alert className={`${namespace()}--alert`}>{disabledReason}</Alert> : null}
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        {this.renderName()}
        {this.renderMenuLevel()}
        {ActiveForm.MenuLevel === 2 ? this.renderParent() : null}
        {this.renderLinkType()}
        <Row>
          <Select
            label="Event Category"
            disabled={disabled}
            onChangeValue={reduxActions.updateValue}
            value={ActiveForm.EventCategoryID}
            validationRules={ValidationRules.EventCategoryID}
            isNumber />
        </Row>
        {selectedEventCategory && selectedEventCategory.IsDistricts && <Row>
          <Select
            label="District"
            disabled={disabled}
            onChangeValue={reduxActions.updateValue}
            value={ActiveForm.DistrictIDi}
            validationRules={ValidationRules.DistrictIDi}
            isNumber />
        </Row>}
        <div className={`${namespace()}--field-label`}>Open In</div>
        {this.renderIsLinkNewWidnow()}
        {this.renderIsAlignRight()}
        {this.renderMenuStyle()}
      </Form>
    );
  };

  renderNoLink = () => {
    const {ActiveForm, disabledReason, SubmitErrorMessage} = this.props;
    
    return (
      <Form
        onSubmit={e => e.preventDefault()}>
        {disabledReason ? <Alert className={`${namespace()}--alert`}>{disabledReason}</Alert> : null}
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        {this.renderName()}
        {this.renderMenuLevel()}
        {ActiveForm.MenuLevel === 2 ? this.renderParent() : null}
        {this.renderLinkType()}
        {this.renderIsAlignRight()}
        {this.renderMenuStyle()}
      </Form>
    );
  };

  renderUrl = () => {
    const {ActiveForm, disabled, reduxActions, ValidationRules, disabledReason, SubmitErrorMessage} = this.props;
    
    return (
      <Form
        onSubmit={e => e.preventDefault()}>
        {disabledReason ? <Alert className={`${namespace()}--alert`}>{disabledReason}</Alert> : null}
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        {this.renderName()}
        {this.renderMenuLevel()}
        {ActiveForm.MenuLevel === 2 ? this.renderParent() : null}
        {this.renderLinkType()}
        <Row>
          <Column span={12} mobileSpan={12}>
              <TextField
                label="URL"
                disabled={disabled}
                onChange={reduxActions.simpleUpdate}
                onBlur={reduxActions.updateValue}
                validationRules={ValidationRules.ExternalURL}
                value={ActiveForm.ExternalURL}/>
          </Column>
        </Row>
        <div className={`${namespace()}--field-label`}>Open In</div>
        {this.renderIsLinkNewWidnow()}
        {this.renderIsAlignRight()}
        {this.renderMenuStyle()}
      </Form>
    );
  };

  public render() {
    const {ActiveForm} = this.props;
    return (
      <div className={`${namespace()}`}>
        {ActiveForm.LinkTypeID === LinkTypeValue.PAGE && this.renderPage()}
        {ActiveForm.LinkTypeID === LinkTypeValue.CALENDAR && this.renderCalendar()}
        {ActiveForm.LinkTypeID === LinkTypeValue.RESOURCE && this.renderResource()}
        {ActiveForm.LinkTypeID === LinkTypeValue.NO_LINK && this.renderNoLink()}
        {ActiveForm.LinkTypeID === LinkTypeValue.URL && this.renderUrl()}
      </div>
    );
  }
}

export default SiteMenuItemForm;
