import * as React from 'react';
import { Button } from 'react-mdl';
import { namespace as ns } from './constants';
import '../../../styles/elements/footer/progress.scss';
import Back from '../../../images/elements/footer/progress/back.svg';
import Next from '../../../images/elements/footer/progress/next.svg';
import { isMobile } from '../../../utils/isMobile';
import { generateDOMId } from '../../../utils/cypressHelper';

export const namespace = (): string => `${ns()}--progress`;


export interface ProgressProps {
  steps: number;
  currentStep: number;
  onNextClick?: (backParam?: string, isNext?: boolean) => void;
  onBackClick?: (nextParam?: string, isNext?: boolean) => void;
  backParam?: string;
  nextParam?: string;
  onComplete?: () => void;
  loading?: boolean;
}

class Progress extends React.PureComponent<ProgressProps, {}> {
  public props: ProgressProps;


  onNextClick = () => {
    const { onNextClick, nextParam } = this.props;
    if (onNextClick) onNextClick(nextParam, true);
  };

  onNextClickWithComplete = () => {
    const {onComplete, onNextClick } = this.props;

    if (onNextClick) onNextClick();
    if (onComplete) onComplete();
  };

  onBackClick = () => {
    const {onBackClick, backParam } = this.props;
    if (onBackClick) onBackClick(backParam, false);
  };

  public render() {
    const { currentStep, steps, loading } = this.props;
    return (
      <section className={`${namespace()} ${loading ? 'loading' : ''}`}>
        <div className={`${namespace()}--button-container back`}>
          {currentStep > 1 ? (
            <Button
              id={generateDOMId("footer-progress-back")}
              ripple={!isMobile}
              className={`${namespace()}--back`}
              onClick={this.onBackClick}
              disabled={loading}
            >
              <Back className={`${namespace()}--back--icon`}/>
              <span className={`${namespace()}--back--text`}>BACK</span>
            </Button>
          ) : null}
        </div>
        <nav className={`${namespace()}--nav`}>
          <ul className={`${namespace()}--nav--list`}>
            {this.renderSteps()}
          </ul>
        </nav>
        <div className={`${namespace()}--button-container next`}>
          {steps !== currentStep ? (
            <Button
              id={generateDOMId("footer-progress-next")}
              ripple={!isMobile} className={`${namespace()}--next`} onClick={this.onNextClick} disabled={loading}>
              <span className={`${namespace()}--next--text`}>NEXT</span>
              <Next className={`${namespace()}--next--icon`}/>
            </Button>
          ) : (
            <Button 
              ripple={!isMobile} className={`${namespace()}--next`} onClick={this.onNextClickWithComplete} disabled={loading}>
              <span className={`${namespace()}--next--text green`}>COMPLETE</span>
            </Button>
            )}
        </div>
      </section>
    );
  }

  private renderSteps() {
    const { steps, currentStep } = this.props;
    let children: Array<any> = [];
    for (let step = 1; step <= steps; ++step) {
        children.push(
          <li className={`${namespace()}--nav--list--item-container`} key={step}>
            <a className={`${namespace()}--nav--list--item-container--item` + (step === currentStep ? ' selected' : '')}/>
          </li>
        );
    }
    return children;
  }
}

export default Progress;
