import {createApiValidateActions, createValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod} from '../../../../Validation/actionCreator';
import { ActionCreator, ApplicationState } from "../../../../../store";
import { typeName, Action } from '../../../../../utils/StrongActions';
import { SaveState } from "../../../../Rollback/actions";

export const SUFFIX = '__IMPORT_FROM_FACILITY_FORM__MODAL';
@typeName("INIT" + SUFFIX) export class InitImportFromFacilityModal extends Action { constructor(public currentFacilityTypeID: number) { super(); }}

@typeName("RESET" + SUFFIX) export class ResetImportFromFacilityModal extends Action {}

@typeName("FILTER_FACILITY" + SUFFIX) export class FilterFacility extends Action {
  constructor(public filterText: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class ImportFromFacilityToggleFilter extends Action {
  constructor(public searching: boolean) { super(); }
}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminFacilityLocation.facilities.modals.importFromFacility, true, undefined, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  filterFacility: (filterText: string): ActionCreator => dispatch => dispatch(new FilterFacility(filterText)),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new ImportFromFacilityToggleFilter(searching)),
  reset: (): ActionCreator => dispatch => dispatch(new ResetImportFromFacilityModal()),
  init: (): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    
    dispatch(new InitImportFromFacilityModal(state.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility ? state.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility.FacilityTypeID : 0));
    dispatch(new SaveState());
  },
};