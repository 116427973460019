import * as React from 'react';
import { SlideShowModal, SlideShow, Slide } from '../../../../Elements';
import {Image} from "../../../../../models/api/cacheOne";
import {getImagePath} from "../../../../../utils";
import {getLargeImagePath} from "../../../../../utils/imageHelper";

interface Props {
  image: Image;
}

const SlideShowComponent: React.SFC<Props> = ({image}): React.ReactElement<Props> => (
  <SlideShowModal>
    <SlideShow>
      <Slide image={getLargeImagePath(image as any)}/>
    </SlideShow>
  </SlideShowModal>
);

export default SlideShowComponent;
