import * as React from 'react';
import Moment from 'moment';
import * as Range from 'moment-range';
import '../../../styles/elements/days/index.scss';
import {FacilitiesAvailabilitiesDate, FacilitiesAvailability} from "../../../models/api/cacheTwoFacilties";
import {API_DATE_FORMAT} from "../../../utils/dateHelper";
import {isDaySame} from "../../../utils";

const moment = (Range as any).extendMoment(Moment);

const namespace = (): string => 'elements--days';

interface Props {
  StartDateTime?: Moment.Moment;
  EndDateTime?: Moment.Moment;
  avail: FacilitiesAvailability;
  range?: any; // using "any' instead of "moment.Range" because the definition of the "by" method is wrong in "@types/moment-range@2.0.33"
  availabilities?: Array<FacilitiesAvailabilitiesDate>;
  small?: boolean;
  disabled?: boolean;
  onSelect?: (start: Moment.Moment, end: Moment.Moment) => void;
}

interface State {
  selectionStarted: boolean;
  over: Moment.Moment | null;
  start: Moment.Moment | null;
  end: Moment.Moment | null;
}

class Days extends React.Component<Props, State> {
  public state: State = {
    selectionStarted: false,
    over: null,
    start: null,
    end: null
  };

  public handleClick = (date: Moment.Moment) => {
    // if (!this.state.selectionStarted) {
    //   document.addEventListener('click', this.handleOutsideClick);
    //   this.setState({ start: date, end: null, selectionStarted: true })
    // } else {
    //   document.removeEventListener('click', this.handleOutsideClick);
    //   this.setState({ end: date, over: null, selectionStarted: false })
    //   this.handleOnChange(this.state.start, date);
    // }
  };

  public handleMouseEnter = (date: Moment.Moment) => {
    // if (this.state.selectionStarted) {
    //   this.setState({ over: date })
    // }
  };

  public handleMouseLeave = (date: Moment.Moment) => {
    // if (this.state.selectionStarted && this.state.over && this.state.over.isSame(date)) {
    //   this.setState({ over: null })
    // }
  };

  public handleOutsideClick = () => {
    // document.removeEventListener('click', this.handleOutsideClick);
    // this.setState({ end: this.state.start, selectionStarted: false, over: null })
    // this.handleOnChange(this.state.start, this.state.start);
  };

  public handleOnChange = (start, end) => {
    // if (this.props.onSelect) {
    //
    //   if (start.isAfter(end)) this.props.onSelect(end, start);
    //   else this.props.onSelect(start, end);
    //   this.setState({start: null, end: null})
    // }
  };

  public componentWillReceiveProps(nextProps: Readonly<Props>) {
    const { start, end } = this.state;
    if (start && end) {
      let range: any; // @todo: typescript Moment.Range;
      if (start.isAfter(end)) range = moment.range(end, start);
      else range = moment.range(start, end);
    }
  }

  public render() {
    const { StartDateTime, EndDateTime, avail, range, availabilities, small, disabled, onSelect } = this.props;

    let className = namespace();
    let daysClassName = `${namespace()}--days`;
    let daysListClassName = `${namespace()}--days--list`;
    let daysListDayClassName = `${namespace()}--days--list--day`;
    let daysListDayButtonClassName = `${namespace()}--days--list--day--button`;
    let daysListDayButtonWeekClassName = `${namespace()}--days--list--day--button--day-of-week`;
    let daysListDayButtonDayClassName = `${namespace()}--days--list--day--button--day-of-month`;

    if (small) {
      className += ' small';
      daysListClassName += ' small';
      daysListDayClassName += ' small';
      daysListDayButtonClassName += ' small';
      daysListDayButtonWeekClassName += ' small';
      daysListDayButtonDayClassName += ' small';
    }
    if (disabled) {
      className += ' disabled';
      daysListClassName += ' disabled';
      daysListDayClassName += ' disabled';
      daysListDayButtonClassName += ' disabled';
      daysListDayButtonWeekClassName += ' disabled';
      daysListDayButtonDayClassName += ' disabled';
    }

    const isSelectable = onSelect ? true : false;
    // const isSelectable = false;
    const { start, over, end, selectionStarted } = this.state;

    return (
      <div className={className}>
        <div className={daysClassName}>
          <ul className={daysListClassName}>
            {Array.from(range.by('day')).map((date: Moment.Moment, index) => {
              let availability = '';
              if (availabilities) {
                const stringDate = date.format(API_DATE_FORMAT);
                const index = availabilities.findIndex((a) => a.Date === stringDate);
                if (index > -1) {
                  if (avail.Availabilities[index].AvailID === 1 || avail.Availabilities[index].AvailID === 2) {
                    availability = 'available';

                  } else if (avail.Availabilities[index].AvailID === 3) {
                    availability = 'half-available';
                  }
                }
              }
              const content = [
                <span key={0} className={`${daysListDayButtonWeekClassName} ${availability}`}>
                  {date.format('ddd')}
                </span>,
                  <span key={1} className={`${daysListDayButtonDayClassName} ${availability}`}>
                  {date.format('D')}
                </span>
              ];
              let isSelected = false;
              if (start) {
                if (isDaySame(date, start)) {
                  isSelected = true;
                }
              } else {
                if (StartDateTime && isDaySame(date, StartDateTime)) {
                  isSelected = true;
                }
              }
              if (end) {
                if (isDaySame(date, end)) {
                  isSelected = true;
                }
              } else {
                if (EndDateTime && isDaySame(date, EndDateTime)) {
                  isSelected = true;
                }
              }
              if (!isSelected) {
                if (selectionStarted && start && over) {
                  if (start && over && start.isAfter(over)) isSelected = date.isBetween(over, start);
                  else isSelected = date.isBetween(start, over);
                } else if (start && end) {
                  if (start.isAfter(end)) isSelected = date.isBetween(end, start);
                  else isSelected = date.isBetween(start, end);
                } else if (StartDateTime && EndDateTime) {
                  isSelected = date.isBetween(StartDateTime, EndDateTime);
                }
              }

              return (
                <li
                  key={index}
                  className={`${daysListDayClassName} ${availability}`}
                >
                  {isSelectable ? (
                    <a
                      className={`${daysListDayButtonClassName} selectable ${availability} ${isSelected ? 'selected' : ''}`}
                      onClick={() => this.handleClick(date)}
                      onMouseEnter={() => this.handleMouseEnter(date)}
                      onMouseLeave={() => this.handleMouseLeave(date)}
                    >
                      {content}
                    </a>
                  ) : (
                    <div className={`${daysListDayButtonClassName} ${availability}`}>
                      {content}
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default Days;
