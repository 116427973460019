import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { RouteComponentProps, withRouter } from 'react-router';

import '../../../../styles/app/header/search/index.scss';
import { isNode } from '../../../../utils';
import Back from './Back';
import { namespace } from "./constants";
import {Nullable} from '../../../../utils/dataHelper';
import { ComponentUpdateTemplate } from '../../../Templates/ComponentUpdateTemplate';
import {AppState, ApplicationState} from '../../../../store';
import { RollbackState } from '../../../../store/Rollback';

type Props = {
  onClose: () => any;
  onSearch: (searchString: string) => void;
  value?: string;
};

// Not really connecting this component to `apiLoadingMap` nor `apiSavingMap` nor `isRollbackJustfinished`, because
// this is not a page component. Just declaring the type here to fulfill the typing requirements
// we set in <ComponentUpdateTemplate />
type ConnectedProps = Pick<AppState, "apiLoadingMap" | "apiSavingMap"> & Pick<RollbackState, "isRollbackJustFinished"> & RouteComponentProps<{}, {}>;

/**
 * This component has some similarity with modals, and also some differences between modals.
 * 
 * Similarity:
 * 1. onLocationChange, we want to unmount this component, just like we want to close all modals on location change
 * 
 * Difference:
 * 1. We _need to_ reset the filterText value on closing this component, because
 *    the `filterText` value affects rendering of other components
 */
class Search extends ComponentUpdateTemplate<Props & ConnectedProps> {
  public props: Props & ConnectedProps;

  public componentDidMount() {
    if (!isNode()) {
      const node = findDOMNode(this) as Nullable<Element>;
      const input: HTMLInputElement = node ? node.getElementsByTagName('input')[0] : null as any;
      if (input) input.focus();
    }

    const isStateNavigated = super.checkIsStateNavigated(this.props);
    if (isStateNavigated) {
      // On mount, reset search filter to empty
      this.props.onSearch('');
    }
  }

  public componentWillUnmount() {
  }

  onChange = (e) => {
    this.props.onSearch(e.target.value);
  };

  public render() {
    const { onClose, value } = this.props;

    return (
      <div className={namespace()}>
        <div className={`${namespace()}--container`}>
          <Back onClick={onClose}/>
          <input type="search" placeholder="Search" className={`${namespace()}--input`} value={value} onChange={this.onChange}/>
        </div>
      </div>
    );
  }
}

export default withRouter<Props>(Search);
