import {makeRequestEpic} from "../Validation/epicCreator";
import {EmptyCartActions, RemoveItemFromCartActions} from "./actions";
import {Observable} from "rxjs/Rx";
import {PopModal} from "../App/actions";
import {push} from "react-router-redux";
import { ModalTypes } from "../../utils/modalHelper";
import { popModalObservables } from "../../utils/modalHelper";
import { Action } from "../../utils/StrongActions";

export const emptyCartFromModal = makeRequestEpic(
  EmptyCartActions,
  (value) => value.url,
  (value) => value.body,
  true,
  'Cart has been emptied.'
);

export const closeCartAfterEmpty = action$ => (
  action$.filter(action => action.type && action.type === EmptyCartActions.successType)
    .switchMap((action): Observable<Action> => {
      const popDeleteItermOrCartModal = popModalObservables({
        modal: ModalTypes.DELETE_ITEM_OR_CART,
        saveBefore: false,
        saveAfter: false,
        transformToObservable: true,
      });
      const popCartModal = popModalObservables({
        modal: ModalTypes.CART,
        saveBefore: false,
        saveAfter: false,
        transformToObservable: true,
      });
      if (action.extra && action.extra.postNavigate) {
        return Observable.concat(
          ...popDeleteItermOrCartModal,
          ...popCartModal,
          Observable.of(push(action.extra.postNavigate))
        );
      }
      return Observable.concat(
        ...popDeleteItermOrCartModal,
        ...popCartModal,
      );
    })
);

export const removeItemFromCartEpic = makeRequestEpic(
  RemoveItemFromCartActions,
  (value) => value.url,
  (value) => value.body,
  true,
  'Item has been removed from cart.'
);

export const closeAfterRemoveItemEpic = action$ => (
  action$.filter(action => action.type && action.type === RemoveItemFromCartActions.successType)
    .switchMap((action): Observable<Action> => {
      const popDeleteItermOrCartModal = popModalObservables({
        modal: ModalTypes.DELETE_ITEM_OR_CART,
        saveBefore: false,
        saveAfter: false,
        transformToObservable: true,
      });
      const popCartModal = popModalObservables({
        modal: ModalTypes.CART,
        saveBefore: false,
        saveAfter: false,
        transformToObservable: true,
      });
      if (action.extra && action.extra.postNavigate) {
        return Observable.concat(
          ...popDeleteItermOrCartModal,
          ...popCartModal,
          Observable.of(push(action.extra.postNavigate))
        );
      }
      return Observable.concat(
        ...popDeleteItermOrCartModal,
        ...popCartModal,
      );
    })
);
