import {createSelector} from "reselect";
import type {ApplicationState} from "../../..";
import {Options} from "../../../../models/api/options";
import {
  createFormDefinition,
  GetRankOptions,
  PersonFormValidatior,
  ShowIsScoutField, ShowRankField, ShowUnitFields
} from "../../../../utils/personFormValidationRules";
import { districtsSelector, filteredDistrictsCombiner } from "../../../CacheZero/selectors";

const RetrieveRankOptions = (rootState: ApplicationState) => {
  const { RankOptions, CubRankOptions, Group, EventTypeRegistrationSettings_Roster} = rootState.cacheZero.options as Options;
  const {ActiveForm} = rootState.settings.roster.add;
  let ScoutTypeID = FormDefinition.ScoutTypeID.defaultValue?.(rootState);
  if (ActiveForm.ScoutTypeID) ScoutTypeID = ActiveForm.ScoutTypeID;
  let UnitTypeID = FormDefinition.UnitTypeID.defaultValue?.(rootState);
  if (ActiveForm.UnitTypeID) UnitTypeID = ActiveForm.UnitTypeID;

  if (!Group) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    // captureTentarooError(new Error("Group not available when RetrieveRankOptions in RosterAdd validation"))
    return [];
  }
  return GetRankOptions(
    RankOptions as Array<any>,
    CubRankOptions as Array<any>,
    EventTypeRegistrationSettings_Roster.NamesRegistrationSettings.AllowNonScouterParticipants,
    ScoutTypeID,
    Group.GroupTypeID,
    Group.UnitTypeID,
    UnitTypeID
  );
};


const ShowUnit = (rootState: ApplicationState) => {
  const options = rootState.cacheZero.options;
  let ScoutTypeID = FormDefinition.ScoutTypeID.defaultValue?.(rootState);
  if (rootState.settings.roster.add.ActiveForm.ScoutTypeID) ScoutTypeID = rootState.settings.roster.add.ActiveForm.ScoutTypeID;

  if (!options) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    // captureTentarooError(new Error("cacheZero.options not available when calculating ShowUnits"));
    return false;
  }
  if (!options.Group) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    // captureTentarooError(new Error("Group not available when calculating ShowUnits"));
    return false;
  }
  return ShowUnitFields(
    options.Group.GroupTypeID,
    options.EventTypeRegistrationSettings_Roster.NamesRegistrationSettings.AllowNonScouterParticipants,
    ScoutTypeID,
    isYouth(rootState)
  );
};

const ShowRank = (rootState: ApplicationState) => {
  const options = rootState.cacheZero.options;
  let ScoutTypeID = FormDefinition.ScoutTypeID.defaultValue?.(rootState);
  if (rootState.settings.roster.add.ActiveForm.ScoutTypeID) ScoutTypeID = rootState.settings.roster.add.ActiveForm.ScoutTypeID;

  if (!options) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    // captureTentarooError(new Error("cacheZero.options not available when calculating ShowUnits"));
    return false;
  }
  if (!options.Group) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    // captureTentarooError(new Error("Group not available when calculating ShowUnits"));
    return false;
  }
  return ShowRankField(
    options.EventTypeRegistrationSettings_Roster.NamesRegistrationSettings.AllowNonScouterParticipants,
    options.Group.GroupTypeID,
    ScoutTypeID
  );
};

const ShowScoutField = (rootState: ApplicationState) => {
  const options = rootState.cacheZero.options;
  let OldScoutTypeID = 2; // legacy defaults to 2 --- "//No need to override and show field if adding. ScoutTypeID hasn't been calculated yet.  //this way, ScoutTypeID won't be shown if adding a new camper under event which requires scouters."
  if (rootState.cacheThreeRoster.GroupRosterPerson) OldScoutTypeID = rootState.cacheThreeRoster.GroupRosterPerson.ScoutTypeID;
  let ScoutTypeID = FormDefinition.ScoutTypeID.defaultValue?.(rootState);
  if (rootState.settings.roster.add.ActiveForm.ScoutTypeID) ScoutTypeID = rootState.settings.roster.add.ActiveForm.ScoutTypeID;

  if (!options) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    // captureTentarooError(new Error("cacheZero.options not available when calculating ShowUnits"));
    return false;
  }
  if (!options.Group) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    // captureTentarooError(new Error("Group not available when calculating ShowUnits"));
    return false;
  }
  return ShowIsScoutField(
    options.Group.GroupTypeID,
    options.EventTypeRegistrationSettings_Roster.NamesRegistrationSettings.AllowNonScouterParticipants,
    OldScoutTypeID,
    isYouth(rootState)
  );
};

const filterCouncilSelector = (state: ApplicationState) => state.settings.roster.add.ActiveForm.CouncilIDi;

export const makeFilteredDistrictSelector = () => {
  return createSelector(
    [districtsSelector, filterCouncilSelector],
    filteredDistrictsCombiner,
  );
};

const filteredDistrictSelector = makeFilteredDistrictSelector();

const getYAKey = (rootState: ApplicationState, key: string) => {
  const {cacheZero} = rootState;

  if (!cacheZero.options) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    return "";
  }
  if (isYouth(rootState)) {
    return cacheZero.options.EventTypeRegistrationSettings_Roster.NamesRegistrationSettings[`${key}Youth`];
  } else {
    return cacheZero.options.EventTypeRegistrationSettings_Roster.NamesRegistrationSettings[`${key}Adult`];
  }
};

const isYouth = (rootState: ApplicationState) => {
  const path = rootState.routing.locationBeforeTransitions.pathname.split('/');
  const isYouth = path[3] === 'youth';
  return isYouth;
};

const getRequireEmergencyContact = (rootState: ApplicationState) => {
  if (!rootState.cacheZero.options) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    return false;
  }
  return rootState.cacheZero.options.EventTypeRegistrationSettings_Roster.NamesRegistrationSettings.RequireEmergencyContact;
};

const getAllowNonScouterParticipants = (rootState: ApplicationState) => {
  if (!rootState.cacheZero.options) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    return false;
  }
  return rootState.cacheZero.options.EventTypeRegistrationSettings_Roster.NamesRegistrationSettings.AllowNonScouterParticipants;
};

const getPerson = (rootState: ApplicationState) => rootState.cacheThreeRoster.GroupRosterPerson;

const getActiveForm = (rootState: ApplicationState) => rootState.settings.roster.add.ActiveForm;

export const FormDefinition: PersonFormValidatior = createFormDefinition(
  getYAKey,
  isYouth,
  ShowUnit,
  RetrieveRankOptions,
  getRequireEmergencyContact,
  getAllowNonScouterParticipants,
  getPerson,
  getActiveForm,
  ShowScoutField,
  filteredDistrictSelector,
  ShowRank
);
