import { ActionCreator, ApplicationState } from "../../../..";
import {createApiValidateActions, createValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod} from '../../../../Validation/actionCreator';
import { typeName, Action } from "../../../../../utils/StrongActions";
import { SaveState } from "../../../../Rollback/actions";

export const SUFFIX = '__CMS_MULTIPLE_GROUPS_MODAL';

@typeName("FILTER" + SUFFIX)
export class MultipleGroupsModalFilter extends Action {
  constructor(public filterString: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class MultipleGroupsToggleFilterTabs extends Action {
  constructor(public searching: boolean) { super(); }
}

@typeName("RESET" + SUFFIX) export class ResetMultipleGroupsModal extends Action {}
@typeName("INIT" + SUFFIX)
export class InitMultipleGroupsModal extends Action {
  constructor(public groupIds: number[]) { super(); }
}

@typeName("TOGGLE_GROUP_SELECT" + SUFFIX)
export class ToggleGroupSelect extends Action {
  constructor(public show: boolean, public groupId: number) { super(); }
}
@typeName("SELECT_ALL" + SUFFIX)
export class SelectAll extends Action { constructor(public groupIds: number[]) { super(); }}
@typeName("DESELECT" + SUFFIX)
export class Deselect extends Action { constructor(public groupIds: number[]) { super(); }}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export type Actions = typeof actionCreators;

export const actionCreators = {
  groupModalUpdateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.events.modals.multipleGroups),
  groupModalSimpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  filterMultipleGroups: (filterString: string): ActionCreator => dispatch => dispatch(new MultipleGroupsModalFilter(filterString)),
  init: (groupIds: number[]): ActionCreator => dispatch => {
    dispatch(new InitMultipleGroupsModal(groupIds));
    dispatch(new SaveState());
  },
  reset: (): ActionCreator => dispatch => dispatch(new ResetMultipleGroupsModal()),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new MultipleGroupsToggleFilterTabs(searching)),
  toggleGroupSelect: (show: boolean, groupId: number): ActionCreator => dispatch => dispatch(new ToggleGroupSelect(show, groupId)),
  selectAll: (groupIds: number[]): ActionCreator => dispatch => dispatch(new SelectAll(groupIds)),
  deselect: (groupIds: number[]): ActionCreator => dispatch => dispatch(new Deselect(groupIds)),
};