import {makeRequestEpic, makeSequentialRequestEpic} from "../../../../Validation/epicCreator";
import {DeleteFacilitySubmitActions, ImportBookingTimesSubmitActions, ImportImagesSubmitActions, ImportPricingSubmitActions, UpdateFacilitySubmitActions, EditFacilityAddImageSubmitActions, EditFacilityDeleteImageSubmitActions, UPDATE_IMAGE_PREFIX} from "./actions";
import {getUpdateFacilityUrl, getImportBookingTimesUrl, getImportPricingUrl, getImportImagesUrl, getEditFacilityAddImage, getEditFacilityEditImage} from "../../../../../constants/urls";
import {AjaxResponse} from "rxjs/ajax";
import { ENTITY_DELETED, ENTITY_RESTORED, ENTITY_EDITED, ENTITY_CREATED } from "../../../../../constants/messages/adminCMS";
import { FACILITY, ENTITY_IMPORTED, PHOTO } from "../../../../../constants/messages/adminFacilityLocation";
import { GalleryImage } from "../../../../../models/api/cacheOne";

export const adminFacilityLocationDeleteFacilityEpic = makeRequestEpic(
  DeleteFacilitySubmitActions,
  getUpdateFacilityUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const facility = response.response.FacilitiesFacility;

    if (!facility || facility.Inactive) {
      return ENTITY_DELETED(FACILITY);
    } else {
      return ENTITY_RESTORED(FACILITY);
    }
  },
);

export const adminFacilityLocationUpdateFacilityEpic = makeRequestEpic(
  UpdateFacilitySubmitActions,
  getUpdateFacilityUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const facility = response.response.FacilitiesFacility;

    return ENTITY_EDITED(FACILITY);
  },
);

export const adminFacilityLocationImportBookingTimesEpic = makeRequestEpic(
  ImportBookingTimesSubmitActions,
  getImportBookingTimesUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    return ENTITY_IMPORTED('Selected Booking Times', true);
  },
);

export const adminFacilityLocationImportPricingEpic = makeRequestEpic(
  ImportPricingSubmitActions,
  getImportPricingUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    return ENTITY_IMPORTED('Selected Pricing');
  },
);

export const adminFacilityLocationImportImagesEpic = makeRequestEpic(
  ImportImagesSubmitActions,
  getImportImagesUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    return ENTITY_IMPORTED('Photos', true);
  },
);

export const adminFacilityUpdateImageEpic = makeSequentialRequestEpic(
  [EditFacilityAddImageSubmitActions, EditFacilityDeleteImageSubmitActions],
  (actionType: string) => {
    switch (actionType) {
      case (EditFacilityAddImageSubmitActions.requestType):
        return getEditFacilityAddImage();
      case (EditFacilityDeleteImageSubmitActions.requestType):
        return getEditFacilityEditImage();
      default:
        return '';
    }
  },
  UPDATE_IMAGE_PREFIX,
  undefined,
  (response: AjaxResponse, action) => {
    const image = response.response.FacilityImage as GalleryImage;
    return action.extra && action.extra.tempId ? ENTITY_CREATED(PHOTO): (image.Inactive ? ENTITY_DELETED(PHOTO) : ENTITY_RESTORED(PHOTO));
  }
);