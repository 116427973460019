import { Reducer } from 'redux';
import { createSelector } from 'reselect';
import { CMSResourceCategory } from '../../../../../models/api/adminCMSCacheOne';
import { Action, isActionType } from '../../../../../utils/StrongActions';
import { ResourceCategoriesToggleFilterTabs, ResourceCategoriesModalFilter, ResetResourceCategoriesModal } from './actions';

export interface ResourceCategoryModalState {
  isRefreshing: boolean;
  filterString: string;
  isTextSearching: boolean;
}
const getInitialState = () => ({
  isRefreshing: false,
  filterString: '',
  isTextSearching: false,
});

export const resourceCategoriesSelector = state => {
  if (state.adminCMSSite && state.adminCMSSite.cacheOne) return state.adminCMSSite.cacheOne.CMSResourceCategories;
  return [];
};

const filterSelector = state => state.adminCMSSite.resources.modals.resourceCategory.filterString;

export const makeFilteredResourceCategoriesSelector = () => {
  return createSelector(
    [resourceCategoriesSelector, filterSelector],
    (resourceCategories: Array<CMSResourceCategory>, filter: string) => {
      // use `ClientInactive` to check if the resource category is just deleted within the modal
      if (!filter || filter === '') return resourceCategories.filter((c) => !c.Inactive || (c.Inactive && c.ClientInactive));
      const f = filter.toLowerCase();
      return resourceCategories.filter(category => (category.Name.toLowerCase().includes(f)) && (!category.Inactive || category.ClientInactive)).sort((a, b) => a.Name.localeCompare(b.Name));
    }
  );
};

export const makeResourceCategoriesMapSelector = () => {
  return createSelector(
    [resourceCategoriesSelector],
    (resourceCategories: Array<CMSResourceCategory>) => {
      const map: {[key: string]: CMSResourceCategory} = {};

      resourceCategories && resourceCategories.forEach((r) => {
        map[r.ID.toString()] = r;
      });

      return map;
  });
};

const ResourceCategoryModalReducer: Reducer<ResourceCategoryModalState> = (state: ResourceCategoryModalState, act: Action) => {
  if (isActionType(act, ResourceCategoriesToggleFilterTabs)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, ResourceCategoriesModalFilter)) {
    return {
      ...state,
      filterString: act.filterString,
    };
  } else if (isActionType(act, ResetResourceCategoriesModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default ResourceCategoryModalReducer;