import * as React from 'react';
import '../../../../styles/elements/blackout-date/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton, Vignette} from '../../index';
import { OptionsIcon } from '../../../../components/Icons';
import { CardCategory } from '../../Card';
import { namespace as cardNS } from "../../Card/constants";
import { ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH, ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_NEW_PATH } from '../../../../routes';
import { AdminFacilityLocationCacheOneState } from '../../../../store/AdminFacilityLocation/CacheOne';
import { URLS } from '../../../../constants/urls';
import { Row } from '../../Grid';
import { FacilitiesBlackoutDate } from '../../../../models/api/adminFacilitiesCacheOne';
import moment from 'moment';
import { FULL_DATE_FORMAT2, TIME_DISPLAY_FORMAT } from '../../../../utils/dateHelper';
import ContextMenuComponent from './ContextMenu';
import { isCardClicked } from '../../../../utils/navHelper';
import Media from '../../Media';
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--blackout-date--card';

export enum BlackoutDateCardType {
  LIST='list',
} 

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin' | 'info' | 'mobile-full';
  type: BlackoutDateCardType;
  category: CardCategory;
  noBoxShadow?: boolean;
  selected?: boolean;
  blackoutDate: FacilitiesBlackoutDate;
  onEdit?: (blackoutDate: FacilitiesBlackoutDate) => void;
  onDelete?: (blackoutDate: FacilitiesBlackoutDate) => void;
  onRestore?: (blackoutDate: FacilitiesBlackoutDate) => void;
  disabled?: boolean;
  routes?: any;
  adminFacilityLocationCacheOne?: AdminFacilityLocationCacheOneState;
  hideOptions?: boolean;
}


class BlackoutDateCard extends React.Component<Props> {

  shouldAllowPropagation = (e) => {
    const { routes } = this.props;
    const route = routes[routes.length - 1];
    const clickedOnCard = isCardClicked(e, namespace);
    return ((route.path === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH || route.path === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_NEW_PATH) && !clickedOnCard);
  };
  onEditClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onEdit, blackoutDate} = this.props;
    
    e.stopPropagation();
    onEdit && onEdit(blackoutDate);
  };
  onDeleteClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onDelete, blackoutDate} = this.props;
    e.stopPropagation();
    !this.props.disabled && onDelete && onDelete(blackoutDate);
  };
  onRestoreClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onRestore, blackoutDate} = this.props;
    !this.props.disabled && e.stopPropagation();
    onRestore && onRestore(blackoutDate);
  };
  onCardClick = (e) => {
    const {type, blackoutDate, disabled} = this.props;

    if (disabled) return;

    switch (type) {
      case BlackoutDateCardType.LIST:
        this.onEditClick(e);
        break;
    }
  };

  getDateText = () => {
    const {blackoutDate} = this.props;

    const startDate = moment(blackoutDate.StartDate);
    const endDate = moment(blackoutDate.EndDate);

    if (startDate.diff(endDate, 'days') === 0) {
      return [
        <Media key='same_date_tablet' tabletAndGreater>{`${startDate.format(FULL_DATE_FORMAT2)} - ${endDate.format(TIME_DISPLAY_FORMAT)}`}</Media>,
        <Media key='same_date_mobile' mobileAndSmaller>
          <div>{`Start: ${startDate.format(FULL_DATE_FORMAT2)}`}</div>
          <div>{`End: ${endDate.format(FULL_DATE_FORMAT2)}`}</div>
        </Media>,
      ];
    }

    return [
      <Media key='date_tablet' tabletAndGreater>{`${startDate.format(FULL_DATE_FORMAT2)} - ${endDate.format(FULL_DATE_FORMAT2)}`}</Media>,
      <Media key='date_mobile' mobileAndSmaller>
        <div>{`Start: ${startDate.format(FULL_DATE_FORMAT2)}`}</div>
        <div>{`End: ${endDate.format(FULL_DATE_FORMAT2)}`}</div>
      </Media>
    ];
  };

  renderInList = () => {
    const {blackoutDate, routes} = this.props;
    const route = routes[routes.length - 1];

    return (
      <div className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>{blackoutDate.Reason}</Row>
          <Row className={`${namespace()}--main--text--subtitle`}>{this.getDateText()}</Row>
        </div>
        <ActionButton
          id={generateDOMId(`blackout-date-${blackoutDate.ID}-option`)}
          icon={OptionsIcon}
          allowPropagation={route.path === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH || route.path === ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_NEW_PATH}
          className={`${namespace()}--options ${cardNS()}--options`}
          contextMenu={<ContextMenuComponent
            blackoutDateId={blackoutDate.ID}
            onEdit={this.onEditClick}
            onDelete={this.onDeleteClick} />}
        />
      </div>
    );
  };

  render() {
    const {category, noBoxShadow, template, blackoutDate, selected, type, adminFacilityLocationCacheOne, disabled} = this.props;
    let className = attribute(namespace(), undefined, this.props);

    if (disabled) className += ' deleted';
    className += ` ${type}`;
    

    return (
      <Card
        noBoxShadow={noBoxShadow}
        className={className}
        component={<section/>}
        template={template || 'mobile-no-margin'}
        category={category}
        padding="none"
        selected={selected}
      >
        <div id={generateDOMId(`blackout-date-card-${blackoutDate.ID}`)} onClick={this.onCardClick}>
          {type === BlackoutDateCardType.LIST ? this.renderInList() : null}
        </div>
      </Card>
    );
  }
}

export default BlackoutDateCard;