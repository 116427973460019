import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import * as M from '../../../../../constants/messages/generic';
import { ApplicationState } from "../../../../../store";
import { PageTypeIDValue, SiteEventCategory, PhotoLyaoutIDValue } from "../../../../../models/api/adminCMSCacheOne";
import { getEventTypeValues, getTimePickerHourValidationRule, getTimePickerPeriodValidationRule, getTimePickerMinValidationRule } from "../../Modals/NewPage/validation";
import { getEventCategoryIDValues } from "../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/validation";
import moment from "moment";
import { makeSelectedEventCategorySelector } from ".";
import { FormDefinition as NewPageModalDefinition} from '../../Modals/NewPage/validation';
import { makeDeletedPrefixItemMapper } from "../../../../../utils/dataHelper";
import { SelectValidator, Validator } from "../../../../../utils/validator/models";

const getContactsLayoutIDValues = (rootState: ApplicationState) => {
  return rootState.adminCMSSite.cacheOne.ContactLayouts ? rootState.adminCMSSite.cacheOne.ContactLayouts : [];
};

export const getPhotoLayoutValues = (rootState: ApplicationState) => {
  if (rootState.adminCMSSite.pages.page.form.ActiveForm.PageTypeID === PageTypeIDValue.HOMEPAGE) {
    return rootState.adminCMSSite.cacheOne.PhotoLayouts ? rootState.adminCMSSite.cacheOne.PhotoLayouts.filter((l) => l.ID === PhotoLyaoutIDValue.SLIDER || l.ID === PhotoLyaoutIDValue.FULL_WIDTH_INLINE || l.ID === PhotoLyaoutIDValue.FULL_WIDTH_SLIDER) : [];
  }

  return rootState.adminCMSSite.cacheOne.PhotoLayouts ? rootState.adminCMSSite.cacheOne.PhotoLayouts : [];
};

const isPageType = (rootState: ApplicationState, targetPageType: PageTypeIDValue) => {
  return rootState.adminCMSSite.pages.page.form.ActiveForm.PageTypeID === targetPageType;
};

export interface IValidator {
  // Page Settings
  Name: Validator;
  ShortURL: Validator;
  IsFeatured: Validator;
  StartDate: Validator;
  EndDate: Validator;
  EventTypeID: SelectValidator;
  IsPrimaryEventPage: Validator;
  ShowRegistrationLinks: Validator;
  IncludeModuleContacts: Validator;
  LocationID: Validator;
  // Calendar Event
  EventCategoryID: SelectValidator;
  IsAllDay: Validator;
  DistrictIDi?: SelectValidator;
  StartHour: SelectValidator;
  StartMin: SelectValidator;
  StartPeriod: SelectValidator;
  EndHour: SelectValidator;
  EndMin: SelectValidator;
  EndPeriod: SelectValidator;
  SiteID: SelectValidator;

  // Contact Section
  ContactsLayoutID: SelectValidator;

  // Map section
  MapHTML: Validator;

  // Page Content
  PageContent: Validator;

  // Embed Form section
  FormBuilderHTML: Validator;

  // Sidebar Content section
  Sidebar1Title: Validator;
  Sidebar1Content: Validator;

  // SEO section
  SEOTitle: Validator;
  SEODescription: Validator;
  SEOKeywords: Validator;

  // Top Gallery
  Photos1LayoutID: SelectValidator;
  TopGalleryShowDeleted: Validator;

  // Bottom Gallery
  Photos2LayoutID: SelectValidator;
  BottomGalleryShowDeleted: Validator;
}

const getSiteIDValues = (rootState: ApplicationState) => {
  const sites = rootState.cacheZero && rootState.cacheZero.options && rootState.cacheZero.options.CMSSites;
  if (sites) {
    return sites
    .filter((s) => {
      const selected = s.Inactive && s.ID === rootState.adminCMSSite.pages.page.form.ActiveForm.SiteID;
      return !s.Inactive || selected;
    })
    .map(makeDeletedPrefixItemMapper('SiteDomain'));
  }
  return [];
};

export const FormDefinition: IValidator = {
  Name: NewPageModalDefinition.Name,
  ShortURL: NewPageModalDefinition.ShortURL,
  StartDate: {
    ...NewPageModalDefinition.StartDate,
    isRequired: (rootState) => isPageType(rootState, PageTypeIDValue.BLOG_ARTICLE) || isPageType(rootState, PageTypeIDValue.CALENDAR_EVENT),
    defaultValue: (rootState) => {
      if (isPageType(rootState, PageTypeIDValue.BLOG_ARTICLE)) {
        return moment();
      } else {
        return null;
      }
    },
  },
  EndDate: {
    ...NewPageModalDefinition.EndDate,
    defaultValue: () => null,
  },
  IsFeatured: {
    key: 'IsFeatured',
    defaultValue: () => false,
  },
  EventTypeID: {
    ...NewPageModalDefinition.EventTypeID,
    // required when in Event Registration Module
    isRequired: (rootState) => isPageType(rootState, PageTypeIDValue.EVENT_MODULES),
    options: {
      ...NewPageModalDefinition.EventTypeID.options,
      values: (rootState) => {
        const values = getEventTypeValues(rootState);
        return [
          {ID: 0, Name: ''},
          ...values
          .filter((et) => !et.Inactive || (et.Inactive && rootState.adminCMSSite.pages.page.form.ActiveForm.EventTypeID === et.ID))
          .map(makeDeletedPrefixItemMapper('Name'))
        ];
      },
    },
    // TODO: This is duplicated with the one in NewPage/validation, we can
    // combine them when using pattern like `makeGroupIDiValidationObject` to
    // share validation
    validatejs: {
      EventTypeID: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        }
      },
    },
  },
  IsPrimaryEventPage: NewPageModalDefinition.IsPrimaryEventPage,
  ShowRegistrationLinks: NewPageModalDefinition.ShowRegistrationLinks,
  IncludeModuleContacts: NewPageModalDefinition.IncludeModuleContacts,
  LocationID: {
    ...NewPageModalDefinition.LocationID,
    isRequired: (rootState) => isPageType(rootState, PageTypeIDValue.FACILITY_MODULES) || isPageType(rootState, PageTypeIDValue.CALENDAR_EVENT),
    customValidate: (rootState) => {
      if (!(isPageType(rootState, PageTypeIDValue.FACILITY_MODULES) || isPageType(rootState, PageTypeIDValue.CALENDAR_EVENT))) return;
      const form = rootState.adminCMSSite.pages.page.form.ActiveForm;
      if (!form.LocationID) {
        return M.INVALID_SELECTED_LOCATION;
      } else {
        const location = rootState.cacheZero.options && rootState.cacheZero.options.Locations ? rootState.cacheZero.options.Locations.find((l) => l.ID === form.LocationID) : null;

        if (location && (location as any).Inactive) {
          return M.INVALID_SELECTED_LOCATION;
        }
      }
    },
  },
  MapHTML: {
    key: 'MapHTML',
    validatejs: {
      MapHTML: {
        length: {
          maximum: 2048,
          tooLong: M.MAX_LENGTH(2048)
        },
      },
    },
  },
  PageContent: {
    key: 'PageContent',
    defaultValue: () => '',
  },
  FormBuilderHTML: {
    key: 'FormBuilderHTML',
    defaultValue: () => '',
    validatejs: {
      FormBuilderHTML: {
        length: {
          maximum: 2048,
          tooLong: M.MAX_LENGTH(2048)
        },
      },
    },
  },
  Sidebar1Title: {
    key: 'Sidebar1Title',
    defaultValue: () => '',
    validatejs: {
      Sidebar1Title: {
        length: {
          maximum: 200,
          tooLong: M.MAX_LENGTH(200)
        },
      },
    },
  },
  Sidebar1Content: {
    key: 'Sidebar1Content',
    defaultValue: () => '',
    validatejs: {
      Sidebar1Content: {
        length: {
          maximum: 2048,
          tooLong: M.MAX_LENGTH(2048)
        },
      },
    },
  },
  SEOTitle: {
    key: 'SEOTitle',
    defaultValue: () => '',
    validatejs: {
      SEOTitle: {
        length: {
          maximum: 255,
          tooLong: M.MAX_LENGTH(255)
        },
      },
    },
  },
  SEODescription: {
    key: 'SEODescription',
    defaultValue: () => '',
    validatejs: {
      SEODescription: {
        length: {
          maximum: 2048,
          tooLong: M.MAX_LENGTH(2048)
        },
      },
    },
  },
  SEOKeywords: {
    key: 'SEOKeywords',
    defaultValue: () => '',
    validatejs: {
      SEOKeywords: {
        length: {
          maximum: 2048,
          tooLong: M.MAX_LENGTH(2048)
        },
      },
    },
  },
  EventCategoryID: {
    ...NewPageModalDefinition.EventCategoryID,
    isRequired: (rootState) => isPageType(rootState, PageTypeIDValue.CALENDAR_EVENT),
    options: {
      ...NewPageModalDefinition.EventCategoryID.options,
      values: (rootState) => {
        const values = getEventCategoryIDValues(rootState);
        
        return [
          {ID: 0, NameAndIsHidden: ''},
          ...values
          .filter((s) => {
            return !s.Inactive || (s.Inactive && s.ID === rootState.adminCMSSite.pages.page.form.ActiveForm.EventCategoryID);
          })
          .map(makeDeletedPrefixItemMapper('Name'))
        ];
      },
    },
    // TODO: This is duplicated with the one in NewPage/validation, we can
    // combine them when using pattern like `makeGroupIDiValidationObject` to
    // share validation
    validatejs: {
      EventCategoryID: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        }
      },
    },
  },
  DistrictIDi: {
    ...NewPageModalDefinition.DistrictIDi,
    isRequired: (rootState) => {
      const selectedCategorySelector = makeSelectedEventCategorySelector();
      const selectedCategory = selectedCategorySelector(rootState) as SiteEventCategory;

      return isPageType(rootState, PageTypeIDValue.DISTRICT) || (isPageType(rootState, PageTypeIDValue.CALENDAR_EVENT) && selectedCategory && selectedCategory.IsDistricts);
    },
    // TODO: This is duplicated with the one in NewPage/validation, we can
    // combine them when using pattern like `makeGroupIDiValidationObject` to
    // share validation
    validatejs: {
      DistrictIDi: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        }
      },
    },
  },
  IsAllDay: NewPageModalDefinition.IsAllDay,
  StartHour: getTimePickerHourValidationRule('StartHour'),
  StartMin: getTimePickerMinValidationRule('StartMin'),
  StartPeriod: getTimePickerPeriodValidationRule('StartPeriod'),
  EndHour: getTimePickerHourValidationRule('EndHour'),
  EndMin: getTimePickerMinValidationRule('EndMin'),
  EndPeriod: getTimePickerPeriodValidationRule('EndPeriod'),
  SiteID: {
    key: 'SiteID',
    isRequired: (rootState) => isPageType(rootState, PageTypeIDValue.CALENDAR_EVENT),
    customValidate: (rootState) => {
      const SiteID = rootState.adminCMSSite.pages.page.form.ActiveForm.SiteID;

      if (SiteID) {
        const site = rootState.cacheZero.options && rootState.cacheZero.options.CMSSites ? rootState.cacheZero.options.CMSSites.find((s) => s.ID === SiteID) : null;

        if (site && site.Inactive) {
          return M.SELECTED_DROPDOWN_ITEM_DELETED;
        }
      }

      return undefined;
    },
    options: {
      values: (rootState) => [...getSiteIDValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'SiteDomain'
    },
    defaultValue: (rootState) => {
      const owners = getSiteIDValues(rootState);

      if (rootState.adminCMSSite.cacheOne.CMSSite) {
        return rootState.adminCMSSite.cacheOne.CMSSite.ID;
      }
      return owners.length > 0 ? owners[0].ID : -1;
    },
    validatejs: {
      SiteID: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          greaterThan: 0,
          notGreaterThan: SELECT_NOT_GREATER_THAN,
        },
      },
    },
  },
  ContactsLayoutID: {
    key: 'ContactsLayoutID',
    isRequired: () => true,
    options: {
      values: (rootState) => [...getContactsLayoutIDValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
    },
    validatejs: {
      ContactsLayoutID: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          greaterThan: 0,
          notGreaterThan: SELECT_NOT_GREATER_THAN,
        },
      },
    },
  },
  Photos1LayoutID: {
    key: 'Photos1LayoutID',
    isRequired: () => true,
    options: {
      values: (rootState) => [...getPhotoLayoutValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
    },
    validatejs: {
      Photos1LayoutID: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          greaterThan: 0,
          notGreaterThan: SELECT_NOT_GREATER_THAN,
        },
      },
    },
  },
  Photos2LayoutID: {
    key: 'Photos2LayoutID',
    isRequired: () => true,
    options: {
      values: (rootState) => [...getPhotoLayoutValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
    },
    validatejs: {
      Photos2LayoutID: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          greaterThan: 0,
          notGreaterThan: SELECT_NOT_GREATER_THAN,
        },
      },
    },
  },
  TopGalleryShowDeleted: {
    key: 'TopGalleryShowDeleted',
  },
  BottomGalleryShowDeleted: {
    key: 'BottomGalleryShowDeleted',
  },
};