import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';
import '../../../styles/elements/tag/index.scss';

export const namespace = (): string => 'elements--tag';

export interface TagProps {
  className?: string;
  icon?: React.ReactNode;
  text: string;
  backgroundColor: 'orange';
  removeIcon?: React.ReactNode;
  onRemove?: () => void;
}

class Tag extends React.PureComponent<TagProps, {}> {
  public props: TagProps;

  onRemoveClick = () => {
    const {onRemove} = this.props;

    onRemove && onRemove();
  };

  public render() {
    const { icon, text, backgroundColor, removeIcon, ...props } = this.props;

    let className = mergeClassNames(namespace(), props);

    if (backgroundColor) className += ` ${backgroundColor}`;

    return (
      <div className={className}>
        {icon ? <div className={`${namespace()}--icon`}>{icon}</div> : null}
        <div className={`${namespace()}--text ${icon ? 'has-icon' : ''}`}>{text}</div>
        {removeIcon ? <div onClick={this.onRemoveClick} className={`${namespace()}--remove-icon`}>{removeIcon}</div> : null}
      </div>
    );
  }
}

export default Tag;
