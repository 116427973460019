import { IValidator, FormDefinition } from "./validation";
import { apiValidationReducerCreator, shouldSkipUpdateForm } from "../../../../../store/Validation/reducerCreator";
import { SUFFIX, CopyPricingTier, getAdminEventCapacityNameKey, getPricingAmountKey } from './actions';
import { setDefaults } from "../../../../../utils/validator";
import type { ApplicationState } from "../../../../../store";
import { GLAccount } from "../../../../../models/api/options";
import { API_UPD } from "../../../../../constants/request";
import { APISuccess, APIUpdateForm, WithRootState } from "../../../../../store/Validation/actionCreator";
import { createSelector } from "reselect";
import { ParticipantType } from "../../../../../models/api/adminEventsCacheOne";
import moment from "moment";
import { AdminEventsCacheTwoEventState } from "../../../CacheTwoEvent";
import { convertObjToMoment } from "../../../../../utils";
import { processHour } from "../../../../../utils/dateHelper";
import { AdminEvent, AdminEventCapacity, AdminEventPricing } from "../../../../../models/api/adminEventsCacheTwoEvent";
import { sortByName } from "../../../../CacheZero";
import * as M from '../../../../../constants/messages/generic';
import { ClearAdminEventsCacheBelowOne } from "../../../CacheOne/actions";
import { ClearAdminEventsCacheTwoEvent, UPDATE_EVENT_FORM_SUFFIX } from "../../../CacheTwoEvent/actions";
import { UpdateAdminEventTypeSubmitActions } from "../../../EventTypes/EventType/Form/actions";
import { UpdateEventImportPricingSubmitActions } from "../../Modals/ImportFromEvent/actions";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../../utils/StrongActions";
import { Validator } from "../../../../../utils/validator/models";

export interface EventActiveForm {
  ID: number;
  Name: string;
  IsAllDay: boolean;

  StartDate: moment.Moment;
  EndDate: moment.Moment;
  StartHour?: number;
  StartMin?: number;
  StartPeriod?: number | null;
  EndHour?: number;
  EndMin?: number;
  EndPeriod?: number | null;

  RegistrationStartDateNumbers: moment.Moment;
  NumbersRegistrationStartHour?: number;
  NumbersRegistrationStartMin?: number;
  NumbersRegistrationStartPeriod?: number | null;

  RegistrationStartDateNames: moment.Moment;
  NamesRegistrationStartHour?: number;
  NamesRegistrationStartMin?: number;
  NamesRegistrationStartPeriod?: number | null;

  Capacities: AdminEventCapacity[];
  GLAccountID: number;
  AllowReductionsUntilDate: moment.Moment;

  YouthDepositDueDate: moment.Moment;
  YouthDepositAmount: number;
  CampsiteDepositDefaultAmount: number;

  BasePricingStartDate: moment.Moment;
  LatePricingStartDate: moment.Moment;

  Inactive: boolean;
  TS: number;
}

const getInitialState = () => {
  return {
    ActiveForm: { },
    ValidationRules: { ...FormDefinition }
  };
};

const capacitiesSelctor = (state: ApplicationState) => state.adminEvents.events.event.form.ActiveForm.Capacities;
const participantTypesSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.Pricing4.ParticipantTypes : [];
export const makeAdminEventCapacityParticipantTypesSelector = () => {
  return createSelector(
    [capacitiesSelctor, participantTypesSelector],
    (capacities: AdminEventCapacity[], participantTypes: ParticipantType[]) => {
      if (!capacities) return [];
      const result = capacities.map((c) => {
        if (!c.ParticipantTypeID) {
          return {
            ID: null,
            Name: 'Event Capacity',
          };
        }

        const participantType = participantTypes.find((pt) => pt.ID === c.ParticipantTypeID);
        if (participantType) {
          return {
            ID: participantType.ID,
            Name: `${participantType.Name} (${participantType.IsYouth ? 'Y' : 'A'})`,
            IsYouth: participantType.IsYouth,
          };
        }
        return null;
      });

      const noID = result.filter((r) => !!r && !r.ID);
      const youth = result.filter((r) => !!r && r.IsYouth === true);
      const adults = result.filter((r) => !!r && r.IsYouth === false);

      return [...noID, ...(youth as any).sort(sortByName), ...(adults as any).sort(sortByName)];
    }
  );
};

const selectedGLAccountIDSelector = (state: ApplicationState) => state.adminEvents.events.event.form.ActiveForm.GLAccountID;
const glAccountsSelector = (state: ApplicationState) => state.cacheZero.options ? state.cacheZero.options.GLAccounts : [];
export const makeAdminEventSelectedGLAccountSelector = () => {
  return createSelector(
    [glAccountsSelector, selectedGLAccountIDSelector],
    (glAccounts: GLAccount[], id: number) => {
      return glAccounts ? glAccounts.find((a) => a.ID === id) : null;
    },
  );
};

export interface EventFormState {
  ActiveForm: EventActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage: string;
}

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const getPricingAmountValidationRule = (key: string, pricingTierID: number): Validator => {
  return {
    key,
    isRequired: (rootState) => {

      const pricingTier = rootState.adminEvents.cacheOne.EventsEventType ? rootState.adminEvents.cacheOne.EventsEventType.Pricing4.PricingTiers.find((pt) => pt.ID === pricingTierID) : null;

      return !!pricingTier && pricingTier.IsEnabled;
    },
    skip: (rootState) => {
      const pricingTier = rootState.adminEvents.cacheOne.EventsEventType ? rootState.adminEvents.cacheOne.EventsEventType.Pricing4.PricingTiers.find((pt) => pt.ID === pricingTierID) : null;

      return !pricingTier || !pricingTier.IsEnabled;
    },
    decimalOnly: true,
    defaultValue: () => 0,
    validatejs: {
      [key]: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
        },
      },
    },
  };
};

const setEvent = (rootState: ApplicationState, state: EventFormState, event?: AdminEventsCacheTwoEventState) => {
  const newState = { ...state };
  newState.ValidationRules = { ...FormDefinition };
  if (event && event.EventsEvent) {
    const eventWithMoment = convertObjToMoment(event.EventsEvent, ['StartDate', 'EndDate', 'RegistrationStartDateNumbers', 'RegistrationStartDateNames', 'AllowReductionsUntilDate', 'BasePricingStartDate', 'LatePricingStartDate', 'YouthDepositDueDate']);
    const StartHour = eventWithMoment.StartDate ? eventWithMoment.StartDate.hours() : null;
    const StartMin = eventWithMoment.StartDate ? eventWithMoment.StartDate.minutes() : null;
    const EndHour = eventWithMoment.EndDate ? eventWithMoment.EndDate.hours() : null;
    const EndMin = eventWithMoment.EndDate ? eventWithMoment.EndDate.minute() : null;

    const NumbersRegistrationStartHour = eventWithMoment.RegistrationStartDateNumbers ? eventWithMoment.RegistrationStartDateNumbers.hours() : null;
    const NumbersRegistrationStartMin = eventWithMoment.RegistrationStartDateNumbers ? eventWithMoment.RegistrationStartDateNumbers.minutes() : null;
    const NamesRegistrationStartHour = eventWithMoment.RegistrationStartDateNames ? eventWithMoment.RegistrationStartDateNames.hours() : null;
    const NamesRegistrationStartMin = eventWithMoment.RegistrationStartDateNames ? eventWithMoment.RegistrationStartDateNames.minutes() : null;

    newState.ActiveForm = {
      ...newState.ActiveForm,
      ID: event.EventsEvent.IDi,
      Name: event.EventsEvent.Name,

      // Dates section
      IsAllDay: event.EventsEvent.IsAllDay,
      StartDate: eventWithMoment.StartDate,
      StartHour: StartHour >= 0 ? processHour(StartHour) : 0,
      StartMin,
      StartPeriod: StartHour === null ? null : (StartHour > 11 ? 1 : 0),

      EndDate: eventWithMoment.EndDate,
      EndHour: EndHour >= 0 ? processHour(EndHour) : 0,
      EndPeriod: EndHour === null ? null : (EndHour > 11 ? 1 : 0),
      EndMin,

      RegistrationStartDateNumbers: eventWithMoment.RegistrationStartDateNumbers,
      NumbersRegistrationStartHour: NumbersRegistrationStartHour >= 0 ? processHour(NumbersRegistrationStartHour) : 0,
      NumbersRegistrationStartMin,
      NumbersRegistrationStartPeriod: NumbersRegistrationStartHour === null ? null : (NumbersRegistrationStartHour > 11 ? 1 : 0),

      RegistrationStartDateNames: eventWithMoment.RegistrationStartDateNames,
      NamesRegistrationStartHour: NamesRegistrationStartHour >= 0 ? processHour(NamesRegistrationStartHour) : 0,
      NamesRegistrationStartMin,
      NamesRegistrationStartPeriod: NamesRegistrationStartHour === null ? null : (NamesRegistrationStartHour > 11 ? 1 : 0),

      Capacities: event.EventsEvent.Capacities,
      GLAccountID: event.EventsEvent.GLAccountID,
      AllowReductionsUntilDate: eventWithMoment.AllowReductionsUntilDate,

      YouthDepositDueDate: eventWithMoment.YouthDepositDueDate,
      YouthDepositAmount: event.EventsEvent.YouthDepositAmount,
      CampsiteDepositDefaultAmount: event.EventsEvent.CampsiteDepositDefaultAmount,

      BasePricingStartDate: eventWithMoment.BasePricingStartDate,
      LatePricingStartDate: eventWithMoment.LatePricingStartDate,
    };
    
    event.EventsEvent.Capacities.forEach((c) => setCapacityName(newState, c));
    event.EventsEvent.Pricing.forEach((p) => setPricingTier(rootState, newState, p));
  }

  return newState;
};

const setCapacityName = (newState: EventFormState, capacity: AdminEventCapacity, preventIfExisted?: boolean) => {
  const key = getAdminEventCapacityNameKey(capacity.ParticipantTypeID || 0);

  if (preventIfExisted && newState.ValidationRules[key]) return;
  newState.ActiveForm[key] = capacity.MaxParticipants;
  newState.ValidationRules[key] = {
    key,
    isRequired: () => false,
    integerOnly: true,
  };
};

const setPricingTier = (rootState: ApplicationState, newState: EventFormState, p: AdminEventPricing, preventIfExisted?: boolean) => {
  const amountEarlyKey = getPricingAmountKey(p.PricingTierID, p.ParticipantTypeID, 'AmountEarly');
  const amountBaseKey = getPricingAmountKey(p.PricingTierID, p.ParticipantTypeID, 'AmountBase');
  const amountLateKey = getPricingAmountKey(p.PricingTierID, p.ParticipantTypeID, 'AmountLate');
  if (preventIfExisted && newState.ValidationRules[amountEarlyKey]) return;
  newState.ActiveForm[amountEarlyKey] = p.AmountEarly;
  newState.ActiveForm[amountBaseKey] = p.AmountBase;
  newState.ActiveForm[amountLateKey] = p.AmountLate;

  const pricingTier = rootState.adminEvents.cacheOne.EventsEventType ? rootState.adminEvents.cacheOne.EventsEventType.Pricing4.PricingTiers.find((pt) => pt.ID === p.PricingTierID) : null;

  if (pricingTier) {
    newState.ValidationRules[amountEarlyKey] = getPricingAmountValidationRule(amountEarlyKey, pricingTier.ID);
    newState.ValidationRules[amountBaseKey] = getPricingAmountValidationRule(amountBaseKey, pricingTier.ID);
    newState.ValidationRules[amountLateKey] = getPricingAmountValidationRule(amountLateKey, pricingTier.ID);
  }
};

const EventFormReducer: Reducer<EventFormState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act) as EventFormState;

  if (act.type.includes(API_UPD) && act.type.includes(UPDATE_EVENT_FORM_SUFFIX)) {
    const action = <APIUpdateForm>act;

    if (shouldSkipUpdateForm(action)) return state;

    // set initial and default value
    const newState = setDefaults(act.rootState, getInitialState());

    if (act.rootState.adminEvents.cacheTwoEvent.EventsEvent) {
      return setEvent(act.rootState, newState, act.rootState.adminEvents.cacheTwoEvent);
    }

    return newState;
  } else if (act.type === UpdateEventImportPricingSubmitActions.successType) {
    const newState = {...state};
    const action = <WithRootState<APISuccess>>act;
    if (!action.response.response) return state;

    const event = action.response.response.EventsEvent as AdminEvent;

    if (event) {
      const eventWithMoment = convertObjToMoment(event, ['StartDate', 'EndDate', 'RegistrationStartDateNumbers', 'RegistrationStartDateNames', 'AllowReductionsUntilDate', 'BasePricingStartDate', 'LatePricingStartDate', 'YouthDepositDueDate']);
      newState.ActiveForm = {
        ...newState.ActiveForm,
        GLAccountID: event.GLAccountID,
        AllowReductionsUntilDate: eventWithMoment.AllowReductionsUntilDate,
        YouthDepositDueDate: eventWithMoment.YouthDepositDueDate,
        YouthDepositAmount: event.YouthDepositAmount,
        CampsiteDepositDefaultAmount: event.CampsiteDepositDefaultAmount,
        BasePricingStartDate: eventWithMoment.BasePricingStartDate,
        LatePricingStartDate: eventWithMoment.LatePricingStartDate,
      };

      // clean up any previous pricings
      for (let key of Object.keys(newState.ActiveForm)) {
        if (key.includes('_ParticipantType')) {
          delete newState.ActiveForm[key];
          delete newState.ValidationRules[key];
        }
      }
      // set pricings from response
      event.Pricing.forEach((p) => setPricingTier(act.rootState, newState, p));

      return newState;
    }
    return state;
  } else if (act.type === UpdateAdminEventTypeSubmitActions.successType) {
    const newState = {...state};
    const action = <WithRootState<APISuccess>>act;

    if (action.response.response.EventsEventType) {
      const rootState = act.rootState;
      const event = rootState.adminEvents.cacheTwoEvent;

      if (event && event.EventsEvent) {
        event.EventsEvent.Capacities.forEach((c) => setCapacityName(newState, c, true));
        event.EventsEvent.Pricing.forEach((p) => setPricingTier(rootState, newState, p, true));
      }
    }

    return newState;
  } else if (isActionType(act, CopyPricingTier)) {
    const newState = { ...state };
    const rootState = act.rootState;

    if (rootState.adminEvents.cacheTwoEvent.EventsEvent) {
      rootState.adminEvents.cacheTwoEvent.EventsEvent.Pricing.forEach((p) => {
        const fromAmountEarlyKey = getPricingAmountKey(1, p.ParticipantTypeID, 'AmountEarly');
        const fromAmountBaseKey = getPricingAmountKey(1, p.ParticipantTypeID, 'AmountBase');
        const fromAmountLateKey = getPricingAmountKey(1, p.ParticipantTypeID, 'AmountLate');

        const toAmountEarlyKey = getPricingAmountKey(act.id, p.ParticipantTypeID, 'AmountEarly');
        const toAmountBaseKey = getPricingAmountKey(act.id, p.ParticipantTypeID, 'AmountBase');
        const toAmountLateKey = getPricingAmountKey(act.id, p.ParticipantTypeID, 'AmountLate');

        newState.ActiveForm[toAmountEarlyKey] = newState.ActiveForm[fromAmountEarlyKey];
        newState.ActiveForm[toAmountBaseKey] = newState.ActiveForm[fromAmountBaseKey];
        newState.ActiveForm[toAmountLateKey] = newState.ActiveForm[fromAmountLateKey];
      });
    }

    return newState;
  } else if (isActionType(act, ClearAdminEventsCacheBelowOne) ||isActionType(act, ClearAdminEventsCacheTwoEvent)) {
    return getInitialState();
  }

  return state || getInitialState();
};

export default EventFormReducer;