import {
  createApiSubmitActions,
  createValidateActions,
  createApiValidateActions,
  createUpdateValueMethod,
  createSimpleUpdateValueMethod,
  innerApiSubmitFormMethod,
  getCacheLevelExtra,
} from "../../../Validation/actionCreator";
import { ActionCreator, ApplicationState } from '../../..';
import { GeneralSettingsActiveForm } from ".";
import { getUpdateSiteSettingsGeneralBody } from "../../../../constants/urls";
import { ADMIN_CMS_MODULE_SUFFIX, UPDATE_ADMIN_CMS_SETTINGS_FORM_SUFFIX } from "../../CacheOne/actions";
import { typeName, Action } from "../../../../utils/StrongActions";
import { Validator } from "../../../../utils/validator/models";

export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__GENERAL_SETTINGS' + UPDATE_ADMIN_CMS_SETTINGS_FORM_SUFFIX;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const GeneralSettingsSubmitActions = createApiSubmitActions(SUFFIX);


@typeName('CHECK_EVENT_CATEGORY_SHOWN_IN_SITE')
export class CheckEventCategoryShownInSite extends Action {
  constructor(public id: number) { super(); }
}
@typeName('REMOVE_SITE_SOCIAL' + SUFFIX)
export class RemoveSiteSocial extends Action {
  constructor(public siteSocialIndex: number) { super(); }
}
@typeName('ADD_SITE_SOCIAL' + SUFFIX)
export class AddSiteSocial extends Action { constructor() { super(); } }

@typeName('UPDATE_SOCIAL_TYPE' + SUFFIX)
export class UpdateSocialType extends Action {constructor(public key: string, public vObj: Validator) { super(); }}


export type Actions = typeof actionCreators;

const updateGeneralSettingsFormCreator = (activeForm: GeneralSettingsActiveForm) => {
  // get `Socials`
  let foundAllSocialMedias = false;
  let index = 0;
  const Socials: any[] = [];

  while (!foundAllSocialMedias) {
    if (!activeForm[`SocialTypeID${index}`] || !activeForm[`SocialUser${index}`]) {
      foundAllSocialMedias = true;
      break;
    }

    Socials.push({
      ID: activeForm[`SocialID${index}`],
      SocialTypeID: activeForm[`SocialTypeID${index}`],
      SocialUser: activeForm[`SocialUser${index}`],
    });
    index += 1;
  }

  // get `EventCategories`
  const EventCategories = activeForm.SiteEventCategories ? activeForm.SiteEventCategories.filter((c) => c.IsShownOnSite).map((c) => ({ID: c.ID})) : [];

  const body = getUpdateSiteSettingsGeneralBody(activeForm);
  body.Socials = Socials;
  body.EventCategories = EventCategories;

  return body;
};

export const actionCreators = {
  updateSocialType: (value: any, vObj: Validator): ActionCreator => (dispatch, getState) => {
    dispatch(new UpdateSocialType(value, vObj));
    createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.adminCMSSite.settings.general)(value, vObj)(dispatch, () => getState());
  },
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.adminCMSSite.settings.general),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  checkEventCategoryShownInSite: (id: number): ActionCreator => dispatch => dispatch(new CheckEventCategoryShownInSite(id)),
  removeSiteSocial: (siteSocialIndex: number): ActionCreator => dispatch => dispatch(new RemoveSiteSocial(siteSocialIndex)),
  addSiteSocial: (): ActionCreator => dispatch => dispatch(new AddSiteSocial()),
  apiSubmitGeneralSettings: (): ActionCreator => (dispatch, getState) => {
    const state = getState();
    // form validation (not including file)
    const valid = innerApiSubmitFormMethod(
      dispatch,
      GeneralSettingsSubmitActions,
      (s: ApplicationState) => s.adminCMSSite.settings.general,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
    );

    if (valid) {
      dispatch(GeneralSettingsSubmitActions.request(
        updateGeneralSettingsFormCreator(state.adminCMSSite.settings.general.ActiveForm),
        getCacheLevelExtra(
          false,
          true,
          // We are updating cache 1
          1
        ),
      ));
    }
  },
};