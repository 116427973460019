import * as React from 'react';
import { namespace as ns } from './constants';
import { CloseIcon } from '../../Icons';

const namespace = (): string => `${ns()}--marker`;

const InvalidImage:React.SFC<{grey?: boolean}> = ({grey}):React.ReactElement<{}> =>
  <i className={`${namespace()} ${namespace()}--invalid-icon ${grey ? 'grey' : ''}`}>
    <CloseIcon width="17px" height="12px" className={`${namespace()}--invalid-icon--image`}/>
  </i>;

export default InvalidImage;
