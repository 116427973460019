import moment from 'moment';

type monthDate = { day: number, date: string };
type monthDates = Array<Array<monthDate>>;

export const skeletonMonth : monthDates = [];
for (let i:number = 0; i < 6; i++) {
  skeletonMonth.push([]);
  for (let j:number = 0; j < 7; j++) {
    skeletonMonth[i].push({day: i*10 + j, date: `skele-${i}-${j}`});
  }
}

export default (month: moment.Moment): monthDates => {
  const days : Array<monthDate> = [];
  const lastMonth = month.clone().subtract(1, 'day');
  const lastMonthEnd : number = Number(lastMonth.format('DD'));
  const lastMonthMonth : number = Number(lastMonth.format('MM'));
  const lastMonthYear : number = Number(lastMonth.format('YYYY'));
  const startOffset : number = month.weekday();
  for (let i = startOffset - 1, len = 0; i >= len; --i) days.push({
    day: lastMonthEnd - i,
    date: `${lastMonthYear}-${lastMonthMonth}-${lastMonthEnd - i}`
  });

  const thisMonthMonth = month.format('MM');
  const thisMonthYear = month.format('YYYY');
  for (let i = 1, len = Number(month.clone().endOf('month').format('DD')); i <= len; ++i) days.push({
    day: i,
    date: `${thisMonthYear}-${thisMonthMonth}-${i < 10 ? `0${i}` : i}`
  });

  const nextMonth = month.clone().endOf('month').add(1, 'day');
  const nextMonthMonth = nextMonth.format('MM');
  const nextMonthYear = nextMonth.format('YYYY');
  let endOffset = 6 - month.clone().endOf('month').weekday();
  if (days.length === 28) endOffset += 7;
  for (let i = 1, len = endOffset; i <= len; ++i) days.push({
    day: i,
    date: `${nextMonthYear}-${nextMonthMonth}-${i < 10 ? `0${i}` : i}`
  });

  const weeks : monthDates = [];
  for (let i = 0, len = 6; i < len; ++i) {
    weeks.push(days.slice(i * 7, (i + 1) * 7));
  }

  return weeks;
};
