import * as React from 'react';
import {Card, ClassList, ClassListItem, ContentBlock, CardShowMore, Title, Button, ContextMenu, ContextMenuInfo, ContextMenuItem, EmptyMessage} from '../../../../Elements';
import {RegisteredClass} from "../../../../../models/class";
import {EventsIcon} from '../../../../Icons';
import {EventInfoPermissions} from "../../../../../models/api/cacheThreeEvents";
import {checkPermission} from "../../../../../utils/permissionHelper";
import '../../../../../styles/pages/events/event/registration/groupClasses.scss';

const namespace = () => `pages--events--event--registration--group-classes`;

export interface Props {
  classes?: Array<RegisteredClass> | null;
  EventInfoPermissions: EventInfoPermissions;
  onClickItem: () => void;
  onManage: () => void;
}

class GroupClasses extends React.Component<Props, {}> {
  props: Props;

  render() {
    const {onClickItem, onManage, classes} = this.props;
    let first6Classes;
    if (classes && classes.length > 0) {
      first6Classes = classes.slice(0, 6);
    }

    return (
      <ContentBlock>
        <Title
          className={`${namespace()}--title`}
          size={28}
          mobileSize={22}
          num={first6Classes ? first6Classes.length : undefined}
          controls={[
            <Button marginRight={16} color="white" key={1} onClick={onManage}>MANAGE</Button>,
            <Button color="white" key={2} onClick={onClickItem}>SCHEDULE</Button>
          ]}
        >
          Registration Options
        </Title>
        <Card footer={first6Classes ? <CardShowMore onClick={onClickItem}/> : undefined} template="mobile-no-margin" marginBottom={0} padding="none">
          {first6Classes && <ClassList>
            {first6Classes.map((c, i) => <ClassListItem key={i} clazz={c} onClick={onClickItem}/>)}
          </ClassList>}
          {!first6Classes && <EmptyMessage small icon={EventsIcon} description="No options registered"/>}
        </Card>
      </ContentBlock>
    );
  }
}

export default GroupClasses;
