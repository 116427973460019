import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';
import { namespace as ns } from './constants';
import '../../../styles/elements/card/actions.scss';

export const namespace = (): string => `${ns()}--actions`;

interface ActionsProps {
  children?: React.ReactNode;
  className?: string;
  hidden?: boolean;
}

const Actions: React.SFC<ActionsProps> = ({ children, hidden, ...props }: ActionsProps): React.ReactElement<ActionsProps> => {
  let className: string = mergeClassNames(namespace(), props);
  if (hidden) className += ' hidden';
  return (
    <div className={className}>
      {children}
    </div>
  );
};

export default Actions;
