import { ActionCreator, ApplicationState } from '../../../index';
import { SaveState } from '../../../Rollback/actions';
import { createValidateActions, createApiValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod } from '../../../Validation/actionCreator';
import { typeName, Action } from '../../../../utils/StrongActions';

export const SUFFIX = '__PAGES_HOME';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

@typeName('RESET' + SUFFIX) export class PagesHomeReset extends Action {}

@typeName('UPDATE_FILTER_TEXT' + SUFFIX)
export class UpdatePagesFilterText extends Action {
  constructor(public filterText: string) { super(); }
}

@typeName('TOGGLE_EXPAND_FILTER' + SUFFIX)
export class ToggleExpandFilterAction extends Action {
  constructor(public expand: boolean) { super(); }
}
@typeName('TOGGLE_FILTER_INVALID' + SUFFIX)
export class ToggleFilterInvalid extends Action {
  constructor(public invalid: boolean) { super(); }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.pages.home, true, undefined),
  updateValueWithoutSave: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.pages.home),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  toggleExpandFilter: (expand: boolean): ActionCreator => dispatch => {
    dispatch(new ToggleExpandFilterAction(expand));
    dispatch(new SaveState());
  },
  reset: (): ActionCreator => dispatch => dispatch(new PagesHomeReset()),
};