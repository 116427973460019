import * as React from 'react';

import {Label, Select, Text, TextField} from '../../../Elements';
import {EventRegistrationPersonCustomField} from '../../../../models/api/cacheFourEvents';
import { SelectValidator, Validator } from '../../../../utils/validator/models';

export interface Props {
  value: any;
  validationRules: Validator | SelectValidator;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
  };
  customField: EventRegistrationPersonCustomField;
  disabled?: boolean;
  info?: string;
}

export class CustomField extends React.Component<Props, {}> {
  props: Props;

  render(): any {
    const {value, validationRules, reduxActions, disabled, customField: {Type, ElementID, Name}, info} = this.props;
    let comp: any;
    if (Type === 'label') {
      comp = <Label>{Name}</Label>;
    } else if (Type === 'notes') {
      comp = <Text marginLeft={16} weight="bold" marginTop={8}>{Name}</Text>;
    } else if (Type === 'textinput') {
      comp = <TextField
        label={Name}
        value={value}
        validationRules={validationRules}
        onChange={reduxActions.simpleUpdate}
        onBlur={reduxActions.updateValue}
        disabled={disabled}
        info={info}
      />;
    } else if (Type === 'yesno') {
      comp = <Select label={Name}
                     onChangeValue={reduxActions.updateValue}
                     value={value}
                     validationRules={validationRules as SelectValidator}
                     disabled={disabled}
                     isNumber
                     info={info}
      />;
    } else if (Type === 'combobox') {
      comp = <Select label={Name}
                     onChangeValue={reduxActions.updateValue}
                     value={value}
                     validationRules={validationRules as SelectValidator}
                     disabled={disabled}
                     isNumber
                     info={info}
      />;
    }

    return comp;
  }
}
