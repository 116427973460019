import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { namespaceWindow } from './constants';
import Button from '..//Button';
import '../../../styles/elements/modal/actions.scss';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

export const namespace = (): string => `${namespaceWindow}--actions`;

type ConnectedProps = ReturnType<typeof mapStateToProps>;
export interface ActionsProps {
  children?: React.ReactNode;
  className?: string;
  right?: React.ReactElement<any>;
  left?: React.ReactElement<any>;
  sticky?: boolean;
  notFixed?: boolean; // @todo: hopefully I can simplify one day and get rid of this and sticky. Seems unneeded
  mobileFullScreen?: boolean;
  borderTop?: boolean;
  stacked?: boolean;
  loading?: boolean;
  alignRight?: boolean;
  noPadding?: boolean;
}

const ModalActions: React.SFC<ActionsProps> = (props:ActionsProps & ConnectedProps):React.ReactElement<ActionsProps> => {
  let { left, right, sticky, mobileFullScreen, borderTop, stacked, notFixed, children, loading, alignRight, noPadding, apiSaving, apiLoading } = props;

  if (left && left.type === Button) {
    left = React.cloneElement(left, {
      flat:true,
      small: true,
      disabled: apiSaving > 0,
      ...left.props
    });
  }

  if (right && right.type === Button) {
    right = React.cloneElement(right, {
      flat:true,
      small: true,
      disabled: apiSaving > 0,
      ...right.props
    });
  }

  if (stacked) {
    children = React.Children.map(children, (child: React.ReactElement<any>) => {
      if (child.type === Button) {
        return React.cloneElement(child, {
          flat:true,
          small: true,
          disabled: apiSaving > 0,
          ...child.props
        });
      }
      return child;
    });
  }

  let className: string = mergeClassNames(namespace(), props);
  if (sticky) className += ' sticky';
  if (mobileFullScreen) className += ' mobile-full-screen';
  if (borderTop) className += ' border-top';
  if (stacked) className += ' stacked';
  if (!notFixed) className += ' fixed';
  if (loading) className += ' loading';
  if (alignRight) className += ' align-right';

  return (
    <div className={`${className} ${noPadding ? 'no-padding' : ''}`}>
      {left ? <div className={`${namespace()}--left`}>{left}</div> : null}
      {right ? <div className={`${namespace()}--right`}>{right}</div> : null}
      {children}
    </div>
  );
};

const mapStateToProps = (s: ApplicationState) => {
  return {
    apiSaving: s.app.apiSaving,
    apiLoading: s.app.apiLoading,
  };
};

const ConnectedModalActions = connect(
  mapStateToProps,
  null,
  getMergeProps<ActionsProps>(),
)(ModalActions);

// NOTE: This flag is needed and used in <Modal />, so that we can use and pass down the same `onClose`
// method from <Modal />
(ConnectedModalActions as any).isModalActions = true;

export default ConnectedModalActions;
