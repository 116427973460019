import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { makeFilteredParticipantsSelector } from '../../../../../../store/Admin/Modals/Accounts';
import { ApplicationState } from '../../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../../../../../store/Admin/Modals/Accounts/actions";
import {actionCreators as appActionCreators} from '../../../../../../store/App/actions';
import {Participant} from '../../../../../../models/api/options';
import { actionCreators as rollbackActionCreators, SaveState } from '../../../../../../store/Rollback/actions';
import { SimpleList, DetailedListElement, EmptyMessage } from '../../../../../Elements';
import { URLS, EVENT } from '../../../../../../constants/urls';
import { navPush, spaceTo_ } from '../../../../../../utils';
import { EventsTypesIcon, PeopleContactIcon } from '../../../../../../components/Icons';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { EmptyMessageType } from '../../../../../Elements/EmptyMessage';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../store/service';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';

interface Props {
  scrollToTop: () => void;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;


@(withRouter as any)
class ParticipantsContent extends React.PureComponent<Props, {}> {
  public props: ConnectedProps & Props;

  componentDidMount() {
    if (shouldBlockActions()) return;

    this.props.actions.init();
  }

  onSearchBack = () => {
    // @todo-minor-performance: just use 1 action
    if (this.props.adminAccounts.filterString) {
      this.props.scrollToTop();
    }
    this.props.actions.showFilterTabs(true);
    this.props.actions.filterAccounts('');
  };

  onClickParticipantElement = (index: number, key: string | number, participant: Participant) => {
    const {actions, router, cacheZero, cacheTwoEvents, cacheThreeEvents, cacheFourEventsViewParticipant, participantEventTypeID} = this.props;
    const trimmedEventName = participant.EventName.substr(0, participant.EventName.lastIndexOf(","));
    const trimmedName = participant.ParticipantName.replace(',', '');
    const pushUrl = `${URLS.EVENTS}/${participantEventTypeID}/${participant.EventIDi}/` +
      `${spaceTo_(trimmedEventName)}/${EVENT.REGISTRATION}/${participant.IsYouth ? 'y' : 'a'}/` +
      `${participant.ParticipantEventIDi}/${spaceTo_(trimmedName)}`;

    reduxStoreService().dispatch(new SaveState());
    this.onSearchBack();

    navPush(
      router,
      pushUrl,
      participant.GroupIDi,
    );

    if (
      (cacheZero.options && cacheZero.options.Group && cacheZero.options.Group.IDi === participant.GroupIDi) &&
      cacheTwoEvents.eventTypeID === participantEventTypeID &&
      cacheThreeEvents.eventID === participant.EventIDi &&
      cacheFourEventsViewParticipant.participantIDi === participant.ParticipantEventIDi
    ) {
      // if same page is selected, then refresh. We are relying on clearAllEndUserCacheButOptions here to
      // do refresh because that is what the page is using right now. It works in this case because there
      // is no "deeper" form under `cacheFourEventsViewParticipant` that will do a SaveState on routerWillLeave
      // Later, if we added routerWillLeave to wizards, then we need to change this to do refresh, and support
      // loader for refresh in UI.
      actions.clearAllEndUserCacheButOptions(true);
    }
  };

  participantItemRender = (index) => {
    const participant: Participant = this.props.filteredParticipants[index];
    return (<DetailedListElement
      key={`${participant.ParticipantEventIDi}_${participant.IsYouth ? 'Y' : 'A'}`}
      index={index}
      label={`${participant.ParticipantName} - ${participant.GroupName}`}
      mobileLabels={[participant.ParticipantName, participant.GroupName]}
      description={participant.EventName}
      onClick={this.onClickParticipantElement}
      deleted={false}
      itemValue={participant}
    />);
  };

  render() {
    const {filteredParticipants, adminAccounts: {ActiveForm}} = this.props;

    if (!ActiveForm.EventTypeID || ActiveForm.EventTypeID === -1 || !ActiveForm.EventTypeID_Final || ActiveForm.EventTypeID_Final === -1) {
      return (
        <EmptyMessage
          icon={EventsTypesIcon}
          type={EmptyMessageType.PAGE_MARGIN}
          iconHeight='96px'
          iconWidth='96px'
          description='Select Event Type first'
          admin
        />
      );
    }

    if (!filteredParticipants || filteredParticipants.length === 0) {
      return (
        <EmptyMessage
          icon={PeopleContactIcon}
          type={EmptyMessageType.PAGE_MARGIN}
          iconHeight='96px'
          iconWidth='96px'
          description={`${ENTITY_NOT_FOUND('participants')}`}
          admin
        />
      );
    }
    return <SimpleList itemRenderer={this.participantItemRender} totalLength={filteredParticipants ? filteredParticipants.length : 0}/>;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredParticipantsSelector = makeFilteredParticipantsSelector();
  return {
    adminAccounts: state.adminAccounts,
    filteredParticipants: filteredParticipantsSelector(state),
    participantEventTypeID: state.adminAccounts.ActiveForm.EventTypeID_Final,
    cacheZero: state.cacheZero,
    cacheTwoEvents: state.cacheTwoEvents,
    cacheThreeEvents: state.cacheThreeEvents,
    cacheFourEventsViewParticipant: state.cacheFourEventsViewParticipant,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
  };
};
const mapDispatchToProps = (dispatch) => (
  {
    actions: bindActionCreators({
      ...actionCreators,
      ...appActionCreators,
      ...rollbackActionCreators
    },
    dispatch
  )}
);

const ConnectedParticipantsContent = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ParticipantsContent);

export default ConnectedParticipantsContent;
