import {IValidator, FormDefinition} from './validation';
import { apiValidationReducerCreator } from '../../../../../store/Validation/reducerCreator';
import { SUFFIX, ResetImportFromEventModal, FilterEvent, ImportFromEventToggleFilter, InitImportFromEventModal, UpdateFinalEventTypeID, GetEventsList, GetSourceClassesList, SelectAll, Deselect, ToggleClassSelect, ResetStepTwo, ResetStepThree, UpdateEventImportClassesSubmitActions } from './actions';
import { createSelector } from 'reselect';
import { ApplicationState } from '../../../../../store';
import { eventsSelector } from '../../Home';
import { AdminEventsEvent } from '../../../../../models/api/adminEventsCacheOne';
import { setDefaults } from '../../../../../utils/validator';
import { APIFailure, APISuccess, WithRootState } from '../../../../Validation/actionCreator';
import { AdminEventClass } from '../../../../../models/api/adminEventsCacheTwoEvent';
import { allClassTypesSelector, filteredAdminEventClassesCombiner } from '../../../../../store/AdminEvents/Events/Event/Classes';
import moment from 'moment';
import { Reducer } from 'redux';
import { Action, isActionType } from '../../../../../utils/StrongActions';
import { ClassType } from '../../../../../models/api/options';

export interface ImportFromEventActiveForm {
  SelectedEventType?: number;
  SelectedEventType_Final?: number;
  SelectedEvent?: number;
  SelectedClasses?: boolean;
  KindOfClass?: number;
  RegisterUnder?: number;
  SelectedClassIds?: number[];
  ShowEventsFromPastYear?: boolean;
}

export interface ImportFromEventModalState {
  ActiveForm: ImportFromEventActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
  FilterText?: string;
  isTextSearching: boolean;
  EventsEvents?: AdminEventsEvent[];
  EventsEventClasses?: AdminEventClass[];
}

const getInitialState = (): ImportFromEventModalState => {
  return {
    ActiveForm: {
      SelectedClassIds: [],
    },
    ValidationRules: { ...FormDefinition },
    isTextSearching: false,
  };
};

const selectedEventIDSelector = (state: ApplicationState) => state.adminEvents.events.modals.importFromEvent.ActiveForm.SelectedEvent;
const filterTextSelector = (state: ApplicationState) => state.adminEvents.events.modals.importFromEvent.FilterText || '';
const currentEventTypeEventsSelector = (state: ApplicationState) => state.adminEvents.events.modals.importFromEvent.EventsEvents || [];
const showEventsFromPastYearsSelector = (state: ApplicationState) => state.adminEvents.events.modals.importFromEvent.ActiveForm.ShowEventsFromPastYear;
const currentEventIdSelector = (state: ApplicationState) => state.adminEvents.cacheTwoEvent.EventsEvent ? state.adminEvents.cacheTwoEvent.EventsEvent.IDi : 0;

export const filteredEventsFromCurrentEventTypeCombiner = (events: AdminEventsEvent[], filterText: string, showEventsFromPastYears: boolean, currentEventId?: number) => {
  if (!events) return [];
  const text = filterText.toLowerCase();
  const result = events.filter((e) => {
    const now = moment();
    const eventTime = moment(e.StartDate);

    return !e.Inactive && e.EventName.toLowerCase().includes(text) && (showEventsFromPastYears || eventTime.year() >= now.year()) && (!currentEventId || e.IDi !== currentEventId);
  }).sort((e1, e2) => {
    const e1Moment = moment(e1.StartDate);
    const e2Moment = moment(e2.StartDate);

    if (e1Moment.isAfter(e2Moment)) {
      return -1;
    } else if (e2Moment.isAfter(e1Moment)) {
      return 1;
    } else {
      return e1.EventName.toLowerCase().localeCompare(e2.EventName.toLowerCase());
    }
  });

  return result;
};
export const makeFilteredEventsSelector = (useCacheOne?: boolean) => {
  return createSelector(
    [useCacheOne ? eventsSelector : currentEventTypeEventsSelector, filterTextSelector, showEventsFromPastYearsSelector, currentEventIdSelector],
    filteredEventsFromCurrentEventTypeCombiner,
  );
};

const kindOfClassSelector = (state: ApplicationState) => state.adminEvents.events.modals.importFromEvent.ActiveForm.KindOfClass || 0;
const registerUnderSelector = (state: ApplicationState) => state.adminEvents.events.modals.importFromEvent.ActiveForm.RegisterUnder || 0;
const adminEventClassesSelector = (state: ApplicationState) => state.adminEvents.events.modals.importFromEvent.EventsEventClasses || [];
const filteredTextSelector = (state: ApplicationState) => state.adminEvents.events.modals.importFromEvent.FilterText;
const showDeletedSelector = (state: ApplicationState) => false;
const selectedClassIdsSelector = (state: ApplicationState) => state.adminEvents.events.modals.importFromEvent.ActiveForm.SelectedClassIds;
export const makeFilteredClassesSelector = () => {
  return createSelector(
    [adminEventClassesSelector, showDeletedSelector, filteredTextSelector, kindOfClassSelector, registerUnderSelector, allClassTypesSelector, selectedClassIdsSelector],
    filteredAdminEventClassesCombiner,
  );
};

export const makeSelectedClassesSelector = () => {
  return createSelector(
    [selectedClassIdsSelector, adminEventClassesSelector, allClassTypesSelector],
    (ids: number[], classes: AdminEventClass[], allClassTypes: ClassType[]) => {
      if (!classes) return [];
      const selectedClasses = classes.filter((c) => ids.find((id) => c.IDi === id));

      return filteredAdminEventClassesCombiner(
        selectedClasses,
        false,
        '',
        0,
        0,
        allClassTypes,
      );
    }
  );
};


export const makeSelectedEventSelector = (useCacheOne?: boolean) => {
  return createSelector(
    [useCacheOne ? eventsSelector : currentEventTypeEventsSelector, selectedEventIDSelector],
    (events: AdminEventsEvent[], selectedEvent: number) => {
      if (!events) return undefined;
      return events.find((e) => e.IDi === selectedEvent);
    }
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const getSelectedClassIds = (classes, state: any) => {
  return classes ? [...state.ActiveForm.SelectedClassIds].filter((id) => {
    const adminClass = classes.find((c) => c.IDi === id);

    return adminClass && !adminClass.IsDuplicate;
  }) : [];
};

const ImportFromEventModalReducer: Reducer<ImportFromEventModalState> = (s, act: WithRootState<Action>) => {
  const state = checkApiValidation(s, act);
  
  if (isActionType(act, FilterEvent)) {
    return {
      ...state,
      FilterText: act.filterText,
    };
  } else if (act.type === GetSourceClassesList.successType) {
    const action = <WithRootState<APISuccess>> act;

    if (!action.response || !action.response.response) return state;

    const EventsEventClasses = action.response.response.EventsEventClassesOptions;
    const SelectedClassIds = getSelectedClassIds(EventsEventClasses, state);

    return {
      ...state,
      EventsEventClasses,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedClassIds,
      },
    };
  } else if (act.type === UpdateEventImportClassesSubmitActions.failureType) {
    const action = act as WithRootState<APIFailure>;
    if (action.response.status === 400) {
      const EventsEventClasses = action.response.response.EventsEventClassesOptions;
      if (EventsEventClasses === undefined) return state;

      const SelectedClassIds = getSelectedClassIds(EventsEventClasses, state);

      return {
        ...state,
        EventsEventClasses,
        ActiveForm: {
          ...state.ActiveForm,
          SelectedClassIds,
        },
      };
    }
  } else if (act.type === GetEventsList.successType) {
    const action = <WithRootState<APISuccess>> act;

    if (!action.response || !action.response.response) return state;

    return {
      ...state,
      EventsEvents: action.response.response.EventsEvents,
    };
  } else if (isActionType(act, ImportFromEventToggleFilter)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, InitImportFromEventModal)) {
    const rootState = act.rootState;

    // First set default value
    const newState = {...setDefaults(act.rootState, state)};

    // Then override it with loaded data
    return {
      ...newState,
      ActiveForm: {
        ...newState.ActiveForm,
        SelectedEventType_Final: rootState.adminEvents.cacheOne.EventsEventType ? rootState.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID : 0,
      },
      EventsEvents: rootState.adminEvents.cacheOne.EventsEvents || [],
    };
  } else if (isActionType(act, UpdateFinalEventTypeID)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedEventType_Final: act.id,
      },
    };
  } else if (isActionType(act, ToggleClassSelect)) {
    const classIds = [...state.ActiveForm.SelectedClassIds];
    if (act.show) {
      const existed = classIds.find((id) => id === act.classId);
      if (!existed && existed !== -1) {
        classIds.push(act.classId);
      }
    } else {
      const index = classIds.findIndex((id) => id === act.classId);
      if (index !== -1) {
        classIds.splice(index, 1);
      }
    }
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedClassIds: classIds,
      },
    };
  } else if (isActionType(act, SelectAll)) {
    const classIds = [...state.ActiveForm.SelectedClassIds];

    act.classIds.forEach((id) => {
      const existed = classIds.find((cId) => cId === id);
      if (!existed && id !== -1) {
        classIds.push(id);
      }
    });
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedClassIds: classIds,
      },
    };
  }else if (isActionType(act, Deselect)) {
    const classIds = [...state.ActiveForm.SelectedClassIds];
    const newClassIds: number[] = [];

    classIds.forEach((id, index) => {
      const existed = act.classIds.find((cId) => cId === id);
      if (!existed && id !== -1) {
        newClassIds.push(id);
      }
    });
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedClassIds: newClassIds,
      },
    };
  } else if (isActionType(act, ResetStepTwo)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedEvent: undefined,
        SelectedClasses: false,
        KindOfClass: FormDefinition.KindOfClass.defaultValue && FormDefinition.KindOfClass.defaultValue(act.rootState),
        RegisterUnder: FormDefinition.RegisterUnder.defaultValue && FormDefinition.RegisterUnder.defaultValue(act.rootState),
        SelectedClassIds: [],
      }
    };
  } else if (isActionType(act, ResetStepThree)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedClasses: false,
      }
    };
  } else if (isActionType(act, ResetImportFromEventModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default ImportFromEventModalReducer;