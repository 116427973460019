import {SaveState} from "../../store/Rollback/actions";
import {replace} from "react-router-redux";
import {EVENT, eventParticipantsInitBody} from "../../constants/urls";
import { shouldCloseWizardOnLoad } from "./helpers/blockers";
import { getGroupID } from "../helpers/endUserPageHelper";
import { EventParticipantCalc } from "../../store/CacheFourEventsParticipants/actions";
import { captureTentarooError } from '../dataHelper';
import { reduxStoreService } from "../../store/service";
import { LoadEndUserCacheFourEventParticipantsParams } from "./helpers/models";
import {isCacheFourEventsParticipantsPopulated, isCacheThreeEventsPopulated, isEndUserCacheOnePopulated, isEndUserCacheTwoEventsPopulated} from "../cachePopulatedCheckers/endUser";

function redirectToEventRegistration(currentPath: string, currentQuery: string) {
  // Derive `END_USER_EVENT_REGISTRATION_PATH` from either
  // `PARTICIPANT_WIZARD_PATH` (add case) or `PARTICIPANT_WIZARD_EDIT_PATH` (edit case)
  const newPath = `${currentPath.split(`/${EVENT.REGISTER}`)[0]}/${EVENT.REGISTRATION}`;

  reduxStoreService().dispatch(replace({
    pathname: newPath,
    query: currentQuery,
  }));
}

export const ensureCacheFourEventsParticipants = (
  params: LoadEndUserCacheFourEventParticipantsParams,
): boolean => {
  const {props, isStateNavigated} = params;

  if (shouldCloseWizardOnLoad(props.location)) {
    redirectToEventRegistration(props.location.pathname, props.location.query);
    return false;
  }

  /**
   * NOTE: cache four loaders will never get triggered on direct visit,
   * and can only get triggered by in-app navigation. That's said, we
   * can assume these ids from urls are already validated from parent caches
   */
  const IsYouth = props.params.ya === "y";
  const ParticipantIDi = Number(props.params.pId);
  const eventTypeId = Number(props.params.eventTypeId);
  const eventId = Number(props.params.eventId);

  const rootState = reduxStoreService().getState();
  const {cacheFourEventsParticipants, cacheOne, cacheTwoEvents, cacheThreeEvents} = rootState;
  const options = rootState.cacheZero.options;

  let requestedData = false;
  if (isCacheFourEventsParticipantsPopulated(cacheFourEventsParticipants)) {
    if (isStateNavigated) {
      reduxStoreService().dispatch(new SaveState());
    }
  } else {
    const groupID = getGroupID(props);
    
    if (
      !groupID ||
      !options ||
      !options.Group ||
      !isEndUserCacheOnePopulated(cacheOne) ||
      !isEndUserCacheTwoEventsPopulated(cacheTwoEvents) ||
      !isCacheThreeEventsPopulated(cacheThreeEvents)
    ) {
      const route = reduxStoreService().getState().routing.locationBeforeTransitions;

      redirectToEventRegistration(route.pathname, route.query);

      return false;
    }

    if (!options.Group) {
      captureTentarooError(new Error("Group not available when dispatching EventParticipantCalc in cacheForuEventsParticipantsLoader"));
      return false;
    }
    if (props.params.ya && props.params.pId) {
      // its an edit
      reduxStoreService().dispatch(
        EventParticipantCalc.request(
          eventParticipantsInitBody({
            GroupIDi: groupID,
            GroupTS: options.Group.TS,
            EventTypeID: eventTypeId,
            EventIDi: eventId,
            ParticipantRegistrationIDi: ParticipantIDi,
            IsYouth: IsYouth,
            ParticipantTypes: !cacheThreeEvents.EventInfoParticipantTypes ? [] : cacheThreeEvents.EventInfoParticipantTypes.map((pType) => pType.ID),
          }),
        )
      );

      requestedData = true;
    } else {
      // its an add
      reduxStoreService().dispatch(
        EventParticipantCalc.request(
          eventParticipantsInitBody({
            GroupIDi: groupID,
            GroupTS: options.Group.TS,
            EventTypeID: eventTypeId,
            EventIDi: eventId,
            IsYouth: true, // doesn't really matter what I pass in here
            ParticipantTypes: !cacheThreeEvents.EventInfoParticipantTypes ? [] : cacheThreeEvents.EventInfoParticipantTypes.map((pType) => pType.ID),
          }),
        )
      );

      requestedData = true;
    }
  }

  return requestedData;
};