import { createSelector } from 'reselect';
import { ApplicationState } from '../../../..';
import { IValidator, FormDefinition } from './validation';
import { EventTypesModalFilter, EventTypesToggleFilterTabs, ResetSelectEventTypesModal, SUFFIX } from './actions';
import { EventType } from '../../../../../models/api/options';
import { sortByName } from '../../../../../store/CacheZero';
import { apiValidationReducerCreator } from '../../../../Validation/reducerCreator';
import { listSelector } from '../../../../../utils/reselectHelper';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface EventTypesModalState {
  isRefreshing: boolean;
  filterString: string;
  isTextSearching: boolean;
  ActiveForm: {
    ShowDeleted?: boolean;
  },
  ValidationRules: IValidator;
}
const getInitialState = () => ({
  isRefreshing: false,
  filterString: '',
  isTextSearching: false,
  ActiveForm: {},
  ValidationRules: {...FormDefinition},
});

const adminEventTypesSelector = listSelector(
  (state: ApplicationState) => state.cacheZero.options ? state.cacheZero.options.EventTypes : [],
);
const adminEventTypesShowDeletedSelector = (state: ApplicationState) => state.adminEvents.eventTypes.modals.selectEventTypes.ActiveForm.ShowDeleted;
const adminEventTypesFilterTextSelector = (state: ApplicationState) => state.adminEvents.eventTypes.modals.selectEventTypes.filterString;
export const makeFilteredEventTypesSelector = () => {
  return createSelector(
    [adminEventTypesSelector, adminEventTypesShowDeletedSelector, adminEventTypesFilterTextSelector],
    (eventTypes: EventType[], showDeleted: boolean, filter: string) => {
      const filtered = eventTypes.filter((e) => {
        const f = filter.toLowerCase();
        if (!f) return !e.Inactive || showDeleted;
        
        return e.Name.toLowerCase().includes(f) && (!e.Inactive || showDeleted);
      });

      const hasEvents = filtered.filter((et) => et.NumCurrentOrFutureEvents > 0);
      const noEvents = filtered.filter((et) => et.NumCurrentOrFutureEvents <= 0);

      return [...hasEvents.sort(sortByName), ...noEvents.sort(sortByName)];
    }
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const EventTypesModalReducer: Reducer<EventTypesModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);

  if (isActionType(act, EventTypesToggleFilterTabs)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, EventTypesModalFilter)) {
    return {
      ...state,
      filterString: act.filterString,
    };
  } else if (isActionType(act, ResetSelectEventTypesModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default EventTypesModalReducer;