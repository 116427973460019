import {SUFFIX} from './actions';
import {FormDefinition, IValidator} from "./validation";
import {apiValidationReducerCreator} from "../../../../Validation/reducerCreator";
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import { PushModal } from '../../../../App/actions';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface RegisterOverrideFeeState {
  ActiveForm: {
    Amount?: number;
  },
  ValidationRules: IValidator;

  quantity?: number;
  Name: string;
  spotsAvailableText: string;
  Amount: number;
  AmountMin: number;
  MaxParticipants: number;
}

const getInitialState = () => {
  return {
    ActiveForm: {},
    ValidationRules: FormDefinition,
    Name: '',
    spotsAvailableText: '',
    Amount: 0,
    AmountMin: 0,
    MaxParticipants: 0
  };
};
const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const OverrideFee: Reducer<RegisterOverrideFeeState> = (oldState = {...getInitialState()}, action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, action);

  if (isActionType(action, PushModal) && action.modal === ModalTypes.REGISTER_OVERRIDE_FEE) {
    const props = action.props;
    return {
      ...state,
      ActiveForm: {
        Amount: props!.pTypeAmount,
      },
      ValidationRules: {
        Amount: {...FormDefinition.Amount},
      },
      Name: props!.pTypeName,
      spotsAvailableText: props!.spotsAvailableText,
      Amount: props!.pTypeAmount,
      AmountMin: props!.pTypeAmountMin,
      MaxParticipants: props!.maxParticipants,
      quantity: props!.quantity,
    };
  }
  return state || {};
};

export default OverrideFee;
