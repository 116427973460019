import {SaveState} from "../../store/Rollback/actions";
import {ToggleNotFound} from "../../store/App/actions";
import {RouteComponentProps} from "react-router";
import {makeSelectedParticipantSelector} from "../../store/CacheFourEventsViewParticipant/index";
import {CacheFourEventsParticipantNoRequestNeeded, GetEventParticipantData} from "../../store/CacheFourEventsViewParticipant/actions";
import {getCacheThreeEventsEmptyCartBody} from "./cacheThreeEventsLoader";
import {EventRegistrationParticipant} from "../../models/api/cacheThreeEvents";
import {
  EVENT,
  getEventData,
  getEventParticipantData,
  getEventParticipantDataBody,
  getEventRootUrl,
} from "../../constants/urls";
import { getGroupID, IEventParticipantRouterParams } from "../helpers/endUserPageHelper";
import { ApplicationState } from "../../store";
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../dataHelper';
import { reduxStoreService } from "../../store/service";
import {isIdFromUrlValid, isIdValid} from '../urlHelper';
import type EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import { LoadEndUserCacheFourViewParticipantParams } from "./helpers/models";
import { safelySetEmptyCart } from "./cacheLoaderHelpers";
import {isCacheFourEventsViewParticipantPopulated, isCacheThreeEventsPopulated, isCacheZeroPopulated, isEndUserCacheOnePopulated, isEndUserCacheTwoEventsPopulated} from "../cachePopulatedCheckers/endUser";

export const ensureCacheFourEventsViewParticipant = (
  params: LoadEndUserCacheFourViewParticipantParams,
): boolean => {
  const {props, isStateNavigated} = params;
  const rootState = reduxStoreService().getState() as ApplicationState;
  const {cacheTwoEvents, cacheThreeEvents, cacheFourEventsViewParticipant, cacheZero, cacheOne} = rootState;
  const groupID = getGroupID(props);

  if (
    !isIdValid(groupID) ||
    !isIdFromUrlValid(props.params.eventTypeId) ||
    !isIdFromUrlValid(props.params.eventId) ||
    !isIdFromUrlValid(props.params.pId)
  ) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  let isYouth: boolean = false;
  
  if (props.params.ya !== 'y' && props.params.ya !== 'a') {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  } else if (props.params.ya === 'y') {
    isYouth = true;
  } else if (props.params.ya === 'a') {
    isYouth = false;
  }

  let requestedData = false;

  const eventTypeId = Number(props.params.eventTypeId);
  const eventId = Number(props.params.eventId);
  const pId = Number(props.params.pId);
  if (isCacheFourEventsViewParticipantPopulated(cacheFourEventsViewParticipant)) {
    if (isStateNavigated) {
      reduxStoreService().dispatch(new SaveState());
    }
  } else {
    const groupID = getGroupID(props);
    if (!groupID) return false;
    if (!isCacheZeroPopulated(cacheZero)) {
      reduxStoreService().dispatch(GetEventParticipantData.request({
        EventTypeID: eventTypeId,
        GroupIDi: groupID,
        GroupTS: 0,
        GetOptions: true,
        GetGroupData: true,
        EventIDi: eventId,
        GetEventTypeData: true,
        GetEventData: true,
        ParticipantIDi: pId,
        IsYouth: isYouth,
      }));

      requestedData = true;
    } else if (
      isCacheZeroPopulated(cacheZero) &&
      (!cacheZero.options!.Group || !isEndUserCacheOnePopulated(cacheOne))
    ) {
      reduxStoreService().dispatch(GetEventParticipantData.request({
        EventTypeID: eventTypeId,
        GroupIDi: groupID,
        GroupTS: 0,
        GetOptions: false,
        GetGroupData: true,
        EventIDi: eventId,
        GetEventTypeData: true,
        GetEventData: true,
        ParticipantIDi: pId,
        IsYouth: isYouth,
      }));
      
      requestedData = true;
    } else if (
      isCacheZeroPopulated(cacheZero) &&
      cacheZero.options!.Group &&
      isEndUserCacheOnePopulated(cacheOne) &&
      !isEndUserCacheTwoEventsPopulated(cacheTwoEvents)
    ) {
      reduxStoreService().dispatch(GetEventParticipantData.request({
        EventTypeID: eventTypeId,
        GroupIDi: groupID,
        GroupTS: cacheZero.options!.Group.TS,
        GetOptions: false,
        GetGroupData: false,
        EventIDi: eventId,
        GetEventTypeData: true,
        GetEventData: true,
        ParticipantIDi: pId,
        IsYouth: isYouth,
      }));

      requestedData = true;
    } else if (
      isCacheZeroPopulated(cacheZero) &&
      cacheZero.options!.Group &&
      isEndUserCacheOnePopulated(cacheOne) &&
      isEndUserCacheTwoEventsPopulated(cacheTwoEvents) &&
      !isCacheThreeEventsPopulated(cacheThreeEvents)
    ) {
      reduxStoreService().dispatch(GetEventParticipantData.request({
        EventTypeID: eventTypeId,
        GroupIDi: groupID,
        GroupTS: cacheZero.options!.Group.TS,
        GetOptions: false,
        GetGroupData: false,
        EventIDi: eventId,
        GetEventTypeData: false,
        GetEventData: true,
        ParticipantIDi: pId,
        IsYouth: isYouth,
      }));

      requestedData = true;
    } else if (
      isCacheZeroPopulated(cacheZero) &&
      cacheZero.options!.Group &&
      isEndUserCacheOnePopulated(cacheOne) &&
      isEndUserCacheTwoEventsPopulated(cacheTwoEvents) &&
      isCacheThreeEventsPopulated(cacheThreeEvents)
    ) {
      // Only do this request is NumClasses is 0. It differs from other ensureCacheLoaders in this way
      if (!cacheThreeEvents.EventRegistrationParticipants || cacheThreeEvents.EventRegistrationParticipants.length === 0) {
        // Don't think this can happen at this time
        reduxStoreService().dispatch(new ToggleNotFound());
        reduxStoreService().dispatch(new SaveState());
      } else {
        const participant = cacheThreeEvents.EventRegistrationParticipants.find((p: EventRegistrationParticipant) => {
          return p.IsYouth === isYouth && p.IDi === pId;
        });
        if (!participant) {
          // Don't think this can happen at this time
          reduxStoreService().dispatch(new ToggleNotFound());
          reduxStoreService().dispatch(new SaveState());
        } else {
          if (participant.NumClasses === 0 || participant.InCart === -1) {
            reduxStoreService().dispatch(new CacheFourEventsParticipantNoRequestNeeded(participant.IDi, isYouth, participant.ParticipantTypeID));
            reduxStoreService().dispatch(new SaveState());
          } else {
            reduxStoreService().dispatch(GetEventParticipantData.request({
              EventTypeID: eventTypeId,
              GroupIDi: groupID,
              GroupTS: cacheZero.options!.Group.TS,
              GetOptions: false,
              GetGroupData: false,
              EventIDi: eventId,
              GetEventTypeData: false,
              GetEventData: false,
              ParticipantIDi: pId,
              IsYouth: isYouth,
            }));
            
            requestedData = true;
          }
        }
      }
    }
  }

  return requestedData;
};

export const setEmptyCartForCacheFourEventsViewParticipant = (props: RouteComponentProps<IEventParticipantRouterParams, {}>) => {
  const rootState = reduxStoreService().getState() as ApplicationState;
  const selectedParticipantSelector = makeSelectedParticipantSelector();
  const selectedParticipant = selectedParticipantSelector(rootState);
  // If emptying a cart in ViewParticipant, and the participant is NOT finalized,
  // we need to redirect away after emptying the cart because that participant will be deleted
  if (selectedParticipant) {
    if (selectedParticipant.IsNewlyAdding) {
      let postNavigate = `${getEventRootUrl(props)}/${EVENT.REGISTRATION}`;
      if (props.location.search) {
        postNavigate = postNavigate + props.location.search;
      }
      safelySetEmptyCart(getEventData, getCacheThreeEventsEmptyCartBody, postNavigate);
    } else {
      safelySetEmptyCart(getEventParticipantData, getCacheFourEventsViewParticipantEmptyCartBody);
    }
  }
};


export const getCacheFourEventsViewParticipantEmptyCartBody = (params: {eventTypeId: string, eventId: string, name: string, ya: string, pId: string, pName: string}) => {
  let isYouth: boolean = false;
  if (params.ya === 'y') {
    isYouth = true;
  } else if (params.ya === 'a') {
    isYouth = false;
  }

  let debugPayload: TentarooDebugPayload | undefined;
  if (
    !isIdFromUrlValid(params.eventTypeId) ||
    !isIdFromUrlValid(params.eventId) ||
    !isIdFromUrlValid(params.pId)
  ) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Invalid eventTypeId OR eventId OR pId in getCacheFourEventsViewParticipantEmptyCartBody");
  }
  const rootState = reduxStoreService().getState();
  const eventTypeId = Number(params.eventTypeId);
  const eventId = Number(params.eventId);
  const pId = Number(params.pId);

  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when getCacheFourEventsViewParticipantEmptyCartBody");
  }

  if (!rootState.cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when getCacheFourEventsViewParticipantEmptyCartBody");
  }

  const body: any = getEventParticipantDataBody({
    EventTypeID: eventTypeId,
    GroupIDi: rootState.cacheZero.options?.Group?.IDi as any,
    GroupTS: rootState.cacheZero.options?.Group?.TS as any,
    GetOptions: false,
    GetGroupData: true,
    EventIDi: eventId,
    GetEventTypeData: true,
    GetEventData: true,
    IsYouth: isYouth,
    ParticipantIDi: pId
  });
  body.AppState.EmptyCart = true;

  if (debugPayload) {
    return withTentarooDebugPayload(body, debugPayload);
  } else {
    return body;
  }
};
