import {CancelRegistrationReset, SUFFIX, ValidateActions} from './actions';
import * as NM from '../../../../../constants/messages/numbersWizard';
import {apiValidationReducerCreator} from '../../../../Validation/reducerCreator';
import {APISuccess, UpdateCurrValue, WithRootState} from "../../../../Validation/actionCreator";
import {FormDefinition} from "./validation";
import {FinancialSummaryInit, initFinancialSummary} from "../../../../../utils/classesHelper";
import {EventParticipantCalc} from "../../../../CacheFourEventsParticipants/actions";
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import { ClearAllCache, ClearAllEndUserCacheButOptions } from '../../../../App/actions';
import { ClearCacheBelowOne } from '../../../../CacheOne/actions';

export interface CancelRegistrationState {
  ActiveForm: {
    selectedFinance?: 'min' | 'full' | 'other' | 'credit';
    otherValue?: number;
  },
  ValidationRules: any;
  SubmitErrorMessage?: string;
  minValue?: number;
  maxValue?: number;
}

export const determineError = (errors: any) => {
  let message = '';
  if (errors) {
    if (errors.otherValue) message = NM.INVALID_PAYMENT;
  }

  return message;
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, determineError);

const getInitialState = (): CancelRegistrationState => {
  return {
    ActiveForm: {
      selectedFinance: 'min',
      otherValue: 0
    }, ValidationRules: {...FormDefinition}};
};

const CancelRegistration: Reducer<CancelRegistrationState> = (oldState = {...getInitialState()}, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, act);
  if (act.type === EventParticipantCalc.successType ) {
    const action = <WithRootState<APISuccess>> act;
    const vals: FinancialSummaryInit = initFinancialSummary(
      action.rootState,
      action.response.response.EventRegistrationPaymentStatus,
      (rootState) => rootState.events.event.modals.cancelRegistration.ActiveForm.selectedFinance === 'other',
      true,
    );

    return {
      ...state,
      ActiveForm: {selectedFinance: vals.paymentType, otherValue: vals.otherDefaultValue},
      ValidationRules: {...state.ValidationRules, otherValue: vals.otherValueRules},
      minValue: action.response.response.EventRegistrationPaymentStatus.AmountMin,
      maxValue: action.response.response.EventRegistrationPaymentStatus.AmountMax
    };
  } else if (act.type === ValidateActions.updateCurrType) {
    const action = <WithRootState<UpdateCurrValue>> act;
    const newState = {...state};
    newState.ActiveForm = {...state.ActiveForm};
    // since `otherValue` is being set to a default value in cases of 
    // `full`, `min` and `credit`, we should delete the related ValidationRules error
    if (action.value === 'full') {
      newState.ActiveForm.otherValue = state.maxValue;
    } else if (action.value === 'min') {
      newState.ActiveForm.otherValue = state.minValue;
    } else if (action.value === 'credit') {
      newState.ActiveForm.otherValue = 0;
    } else {
      return state;
    }
    return newState;
  } else if (
    isActionType(act, CancelRegistrationReset) ||
    isActionType(act, ClearAllCache) ||
    isActionType(act, ClearAllEndUserCacheButOptions) ||
    isActionType(act, ClearCacheBelowOne)
  ) {
    return getInitialState();
  }
  return state;
};

export default CancelRegistration;
