import { getResourceCategoryValues } from "../../Home/validation";
import { SelectValidator } from "../../../../../utils/validator/models";

export interface IValidator {
  SelectedResourceCaetgoryID: SelectValidator;
}

export const FormDefinition: IValidator = {
  SelectedResourceCaetgoryID: {
    key: 'SelectedResourceCaetgoryID',
    options: {
      values: (rootState) => [{ Name: 'All Categories', ID: 0 }, ...getResourceCategoryValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
    },
    defaultValue: () => {
      return 0;
    },
  },
};