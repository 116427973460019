import * as React from 'react';
import { namespace as ns, actionNamespace } from './constants';
import { AddedIcon } from '../../../Icons';
import { ActionButton, Media } from '../../../Elements';
import { bindActionCreators } from 'redux';
import { appActionCreators, ApplicationState } from "../../../../store";
import '../../../../styles/app/header/actions/added.scss';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';

export const namespace = (): string => `${ns()}--added`;
interface Props {
  addedLength: number;
}
type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface State {
  animateEmpty?: number | null;
  animateMore?: boolean | null;
  empty?: boolean | null;
}

class Added extends React.Component<Props, State> {
  public props: Props & ConnectedProps;

  public state: State = {
    animateEmpty: null,
    animateMore: false,
    empty: null
  };

  public constructor(props: Props) {
    super(props);
    this.state.empty = props.addedLength === 0;
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (nextProps.addedLength !== this.props.addedLength) {
      if (nextProps.addedLength === 0) this.animateEmpty(this.props.addedLength);
      else this.animateMore();
    }
  }

  public animateEmpty = (currentNum: number) => {
    setTimeout(() => {
      this.setState({ animateEmpty: null, empty: true });
    }, 500);
    this.setState({ animateEmpty: currentNum });
  };

  public animateMore = () => {
    setTimeout(() => {
      this.setState({ animateMore: false });
    }, 500);
    this.setState({ animateMore: true, empty: false });
  };

  public render() {
    const { addedLength } = this.props;
    const { empty, animateEmpty, animateMore } = this.state;
    let className: string = `${actionNamespace()} ${namespace()}`;
    let containerClassName: string = `${namespace()}--container`;
    let actionButtonClassName: string = `${namespace()}--container--action-button`;
    let imageClassName: string = `${namespace()}--container--action-button--image`;
    let numClassName: string = `${namespace()}--container--action-button--num`;
    if (animateEmpty !== null) {
      containerClassName += ' animate-empty';
      actionButtonClassName += ' animate-empty';
      imageClassName += ' animate-empty';
      numClassName += ' animate-empty';
      className += ' animate-empty';
    } else if (animateMore) {
      containerClassName += ' animate-more';
      actionButtonClassName += ' animate-more';
      imageClassName += ' animate-more';
      numClassName += ' animate-more';
      className += ' animate-more';
    } else if (empty) {
      containerClassName += ' empty';
      actionButtonClassName += ' empty';
      imageClassName += ' empty';
      numClassName += ' empty';
      className += ' empty';
    }

    let tabletClassName = '';
    if (!this.props.showAddedHeaderAction) {
      className += ' empty';
      tabletClassName += ' empty';
    }
    return (
      <div className={className}>
        <Media tablet mobile className={containerClassName + tabletClassName}>
          <ActionButton selected={this.props.showAddedSideBar} className={actionButtonClassName + tabletClassName} onClick={this.props.actions.toggleAddedSideBar}>
            <AddedIcon className={imageClassName + tabletClassName} width="21px" height="21px"/>
            <span className={numClassName + tabletClassName}>
              {animateEmpty !== null ? animateEmpty : addedLength}
            </span>
          </ActionButton>
        </Media>
        <Media desktop className={containerClassName}>
          <div className={actionButtonClassName}>
            <AddedIcon className={imageClassName} width="21px" height="21px"/>
            <span className={numClassName}>
              {animateEmpty !== null ? animateEmpty : addedLength}
            </span>
          </div>
        </Media>
      </div>
    );
  }
}

const mapStateToProps = (s: ApplicationState) => {
  return {
    showAddedSideBar: s.app.showAddedSideBar,
    showAddedHeaderAction: s.app.showAddedHeaderAction,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
});

const ConnectedAdded = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(Added);

export default ConnectedAdded;
