import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import Arrow from '../../../images/elements/accordion/arrow.svg';
import '../../../styles/elements/accordion/index.scss';
import { Button as MdlButton } from 'react-mdl';
import { isMobile } from '../../../utils/isMobile';

export const namespace = (): string => 'elements--accordion';

export interface AccordionProps {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  name: string;
  open?: boolean;
  defaultOpen?: boolean;
  marginTop?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  onClick?: (show: boolean) => void;
}

// @todo: is it acceptable to leave this here?
type AccordionState = {
  open?: boolean;
};

class Accordion extends React.Component<AccordionProps, AccordionState> {
  public props: AccordionProps;
  public state: AccordionState = {
    open: false
  };

  public constructor(props) {
    super(props);
    this.state = { open: props.defaultOpen ? true : false };
  }

  public handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick(!this.props.open);
    } else {
      this.setState({ open: !this.state.open });
    }
  };

  public render() {
    const { name, children, style, marginTop } = this.props;
    const open = this.props.open !== undefined ? this.props.open : this.state.open;
    const finalStyle: any = { ...style };
    if (marginTop) finalStyle.marginTop = `${marginTop}px`;

    return (
      <div className={mergeClassNames(namespace(), this.props) + (open ? ' open' : ' closed')} style={finalStyle}>
        <MdlButton
          ripple={!isMobile}
          className={`${namespace()}--name`}
          onClick={this.handleClick}
        >
          <div className={`${namespace()}--name--arrow ` + (open ? 'open' : 'closed')}>
            <Arrow className={`${namespace()}--name--arrow--image`} width="12px" height="12px"/>
          </div>
          <span className={`${namespace()}--name--text`}>{name}</span>
        </MdlButton>
        <div className={`${namespace()}--content` + (open ? ' open' : '')}>
          {children}
        </div>
      </div>
    );
  }
}

export default Accordion;
