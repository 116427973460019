import * as React from 'react';
import { EventsIcon } from '../../../Icons';
import {
  MyEvent, MyEventQuantity, Column, MyEventDateRange, MyEventName, MyEventAmounts, MyEventDetails, MyEventWarning
} from '../../../Elements';
import * as moment from 'moment';
import '../../../../styles/pages/home/my-events/events.scss';

export const namespace = (): string => 'pages--home--my-events--event';

interface Props {
  onClick: () => void;
  name: string;
  warning?: string;
  details: string;
  total: number;
  balance: number;
  ticketsNames?: number;
  ticketsNumbers?: number;
  dateStart: moment.Moment;
  dateEnd: moment.Moment;
  cart: boolean;
}

const Event: React.SFC<Props> = ({
  onClick, details, dateStart, dateEnd, name, total, balance, ticketsNames, ticketsNumbers, cart, warning
}: Props): React.ReactElement<Props> => {
  return (
    <MyEvent onClick={onClick} cart={cart} className={namespace()}>
      <Column layout="vertical" expand>
        <MyEventDateRange dateStart={dateStart} dateEnd={dateEnd}/>
        <MyEventName>{name}</MyEventName>
        <MyEventDetails>{details}</MyEventDetails>
        {warning ? <MyEventWarning>{warning}</MyEventWarning> : null}
      </Column>
      <Column mobileSpan={12} mobileMarginTop={4}>
        <MyEventAmounts
          total={total}
          balance={balance}
          cart={cart}
          quantity={
            <MyEventQuantity icon={EventsIcon} quantity={ticketsNames} total={ticketsNumbers}/>
          }
        />
      </Column>
    </MyEvent>
  );
};

export default Event;
