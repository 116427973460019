import { SaveState } from "../../store/Rollback/actions";
import {
  getFacilityAvailabilityBody,
} from "../../constants/urls";
import { ToggleNotFound } from "../../store/App/actions";
import { makeSelectedFacilityTypeSelectorB } from "../../store/CacheTwoBFacilities";
import { GetFacilityAvailabilitiesB, SetCacheTwoBFacilities } from "../../store/CacheTwoBFacilities/actions";
import { formatDate } from "../dateHelper";
import { getGroupID } from "../helpers/endUserPageHelper";
import { isIdFromUrlValid, isIdValid } from '../urlHelper';
import { captureTentarooError, captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../dataHelper';
import { reduxStoreService } from "../../store/service";
import { LoadEndUserCacheTwoBFacilityParams } from "./helpers/models";
import {isCacheTwoBFacilitiesPopulated, isCacheZeroPopulated, isEndUserCacheOnePopulated} from "../cachePopulatedCheckers/endUser";

export const ensureCacheTwoBFacilities = (
  params: LoadEndUserCacheTwoBFacilityParams,
): boolean => {
  const { props, isStateNavigated } = params;

  const groupID = getGroupID(props);
  if (
    !isIdValid(groupID) ||
    !isIdFromUrlValid(props.params.id) ||
    !isIdFromUrlValid(props.params.facId)
  ) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const rootState = reduxStoreService().getState();
  const { cacheTwoFacilities, cacheTwoBFacilities, cacheOne, cacheZero } = rootState;
  const selectedFacilityTypeSelector = makeSelectedFacilityTypeSelectorB();
  const selectedFacilityType = selectedFacilityTypeSelector(rootState);
  const facility = rootState.facilities.facility;
  const location = rootState.facilities.location;
  const startDate = formatDate(facility.startDate);

  const locationId = Number(props.params.id);
  const facId = Number(props.params.facId);

  // NOTE: ftbId is optional in url
  let ftbId: number | null = null;
  if (props.params.ftbId) {
    ftbId = Number(props.params.ftbId);

    if (isNaN(ftbId)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }
  }

  let requestedData = false;
  if (isCacheTwoBFacilitiesPopulated(cacheTwoBFacilities)) {
    if (selectedFacilityType && isStateNavigated) {
      reduxStoreService().dispatch(new SaveState());
    }
  } else {
    if (!isCacheZeroPopulated(cacheZero)) {
      // Came from page load, need everything
      reduxStoreService().dispatch(GetFacilityAvailabilitiesB.request({
        GroupIDi: groupID,
        GroupTS: 0,
        FacilityLocationID: locationId,
        GetOptions: true,
        GetGroupData: true,
        GetFacilityLocationData: true,
        FacilityID: facId,
        ftbID: ftbId,
        StartDate: startDate,
      }));

      requestedData = true;
    } else if (
      isCacheZeroPopulated(cacheZero) &&
      (!cacheZero.options!.Group || !isEndUserCacheOnePopulated(cacheOne))
    ) {
      reduxStoreService().dispatch(GetFacilityAvailabilitiesB.request({
        GroupIDi: groupID,
        GroupTS: 0,
        FacilityLocationID: locationId,
        GetOptions: false,
        GetGroupData: true,
        GetFacilityLocationData: true,
        FacilityID: facId,
        ftbID: ftbId,
        StartDate: startDate,
      }));

      requestedData = true;
    } else {
      const facilityAvail = cacheTwoFacilities.FacilitiesAvailabilities ? cacheTwoFacilities.FacilitiesAvailabilities.find((availability) => availability.FacilityID === facId && (ftbId === null || availability.ftbID === ftbId)) : undefined;
      if (facilityAvail && cacheTwoFacilities.FacilitiesAvailabilitiesDates) {
        reduxStoreService().dispatch(new SetCacheTwoBFacilities(
          facilityAvail.FacilityID,
          facilityAvail.ftbID,
          facilityAvail,
          cacheTwoFacilities.FacilitiesAvailabilitiesDates,
          location.startDate,
        ));
        reduxStoreService().dispatch(new SaveState());
      } else {
        if (!cacheZero.options!.Group) {
          captureTentarooError(new Error("Group not available when dispatching GetFacilityAvailabilitiesB in cacheTwoBFacilitiesLoader"));
          return false;
        }
        reduxStoreService().dispatch(GetFacilityAvailabilitiesB.request({
          GroupIDi: cacheZero.options!.Group.IDi,
          GroupTS: cacheZero.options!.Group.TS,
          FacilityLocationID: locationId,
          GetOptions: false,
          GetGroupData: false,
          GetFacilityLocationData: true,
          FacilityID: facId,
          ftbID: ftbId,
          StartDate: startDate,
        }));

        requestedData = true;
      }
    }
  }

  return requestedData;
};

export const getCacheTwoBFacilitiesEmptyCartBody = (params: { id: string; name: string; facId: string; ftbId: string | undefined | null; facName: string }) => {
  const rootState = reduxStoreService().getState();

  let debugPayload: TentarooDebugPayload | undefined;
  if (
    !isIdFromUrlValid(params.id) ||
    !isIdFromUrlValid(params.facId)
  ) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Invalid id OR facId OR ftbID when getCacheTwoBFacilitiesEmptyCartBody");
  }
  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when getCacheTwoBFacilitiesEmptyCartBody");
  }

  if (!rootState.cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when getCacheTwoBFacilitiesEmptyCartBody");
  }


  const locationId = Number(params.id);
  const facId = Number(params.facId);
  // NOTE: ftbId is optional in url
  const ftbId = params.ftbId ? Number(params.ftbId) : null;

  const body: any = getFacilityAvailabilityBody({
    FacilityLocationID: locationId,
    GroupIDi: rootState.cacheZero.options?.Group?.IDi as any,
    GroupTS: rootState.cacheZero.options?.Group?.TS as any,
    GetOptions: false,
    GetFacilityLocationData: false,
    GetGroupData: true,
    FacilityID: facId,
    ftbID: ftbId,
    StartDate: formatDate(rootState.facilities.facility.startDate),
  });
  body.AppState.EmptyCart = true;

  if (debugPayload) {
    return withTentarooDebugPayload(body, debugPayload);
  } else {
    return body;
  }
};
