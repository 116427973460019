import * as React from 'react';
import { namespace as ns } from './constants';
import {default as attributes, MarginProps, PaddingProps, AlignmentProps, LayoutProps} from '../../../styles';
import '../../../styles/elements/grid/row.scss';

const namespace = (): string => `${ns()}--row`;

export interface RowProps extends MarginProps, PaddingProps, AlignmentProps, LayoutProps {
  className?: string;
  children?: React.ReactNode;
  height?: number;
  wrap?: boolean;
  wrapInMobile?: boolean;
  renderMobile?: boolean;
  onClick?: React.EventHandler<any>;
  hardOpacity?: boolean;
  styles?: React.CSSProperties;
  refCallback?: any;
  id?: string;
}

const Row: React.SFC<RowProps> = (
  {hardOpacity, id, children, className: classNameOverride, height, wrap, renderMobile, onClick, styles, wrapInMobile, ...props}
: RowProps): React.ReactElement<RowProps> => {
  let className = attributes(namespace(), classNameOverride, renderMobile, props);
  if (!wrap) className += ' no-wrap';
  if (wrapInMobile) className += ' wrap-mobile';
  if (hardOpacity) className += ' hard-opacity';

  let _styles: any = {};
  if (height) _styles.height = `${height}px`;
  if (styles) _styles = Object.assign({}, _styles, styles);

  return (
    <div id={id} ref={props.refCallback} className={className} style={_styles} onClick={onClick}>
      {children}
    </div>
  );
};

Row.defaultProps = {
  wrap: true
};

export default Row;
