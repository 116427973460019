import { ActionCreator, ApplicationState } from "../../../..";
import { createValidateActions, createApiValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod, getCacheLevelExtra } from "../../../../Validation/actionCreator";
import { AdminEventClassParticipantAttendance, AdminEventClass, AdminEventClassParticipant, MBRequirement } from "../../../../../models/api/adminEventsCacheTwoEvent";
import { ClearAdminEventsCacheThreeClassRequirements, GetAdminClassRequirements, UpdateAdminClassRequirements } from "../../../CacheThreeClassRequirements/actions";
import { getUpdateClassRequirementsBody } from "../../../../../constants/adminEventsUrls";
import { SaveState } from "../../../../Rollback/actions";
import { appActionCreators } from "../../../../App";
import { ModalTypes } from "../../../../../utils/modalHelper";
import { typeName, Action } from "../../../../../utils/StrongActions";

export const getClassParticipantStatusKey = (p: AdminEventClassParticipant) => `Status${p.IDi}`;
export const SUFFIX = '__ENTER_CLASS_REQUIREMENT';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ClassRequirementValidateActions = createValidateActions(SUFFIX + '__CLASS_REQUIREMENT');
export const ClassRequirementApiValidateActions = createApiValidateActions(SUFFIX + '__CLASS_REQUIREMENT');

export const ClassParticipantValidateActions = createValidateActions(SUFFIX + '__CLASS_PARTICIPANT');
export const ClassParticipantApiValidateActions = createApiValidateActions(SUFFIX + '__CLASS_PARTICIPANT');

@typeName("INIT" + SUFFIX)
export class InitEnterClassRequirementModal extends Action { constructor() { super(); } }

@typeName("SELECT_CLASS" + SUFFIX)
export class SelectClassForEnterClassRequirementModal extends Action { constructor(public selectedClass: AdminEventClass) { super(); } }

@typeName("RESET" + SUFFIX) export class ResetEnterClassRequirementModal extends Action {}

@typeName("FILTER_CLASS" + SUFFIX) export class FilterClass extends Action {
  constructor(public filterText: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class EnterClassRequirementModalToggleFilter extends Action {
  constructor(public searching: boolean) { super(); }
}
@typeName("TOGGLE_PARTICIPANT_ATTENDANCE" + SUFFIX)
export class ToggleParticipantAttendance extends Action {
  constructor(public participantId: number, public attendance: AdminEventClassParticipantAttendance) { super(); }
}
@typeName("ADD_ALL_REQUIREMENTS_TO_PARTICIPANT_OLD_TRACKING" + SUFFIX)
export class AddAllRequirementsToParticipant_OldTracking extends Action {
  constructor(public participantId: number) { super(); }
}
@typeName("REMOVE_ALL_REQUIREMENTS_FROM_PARTICIPANT_OLD_TRACKING" + SUFFIX)
export class RemoveAllRequirementsFromParticipant_OldTracking extends Action {
  constructor(public participantId: number) { super(); }
}
@typeName("REMOVE_REQUIREMENT_FROM_PARTICIPANT" + SUFFIX)
export class RemoveRequirementFromParticipant extends Action {
  constructor(public participantId: number, public requirement: string | number) { super(); }
}
@typeName("ADD_NEW_REQUIREMENTS_TO_PARTICIPANT_OLD_TRACKING" + SUFFIX)
export class AddNewRequirementsToParticipant_OldTracking extends Action {
  constructor(public participantId: number, public requirements: string) { super(); }
}
@typeName("UPDATE_REQUIREMENTS_FOR_PARTICIPANT" + SUFFIX)
export class UpdateRequirementsForParticipant extends Action {
  constructor(public participantId: number, public reqIDs: number[]) { super(); }
}

@typeName("MASS_UPDATE_STATUS" + SUFFIX)
export class MassUpdateStatus extends Action {
  constructor(public statusOptionId: number) { super(); }
}

@typeName("MASS_UPDATE_REQUIREMENT" + SUFFIX)
export class MassUpdateRequirement extends Action {
  constructor(public requirement: string | MBRequirement) { super(); }
}

@typeName("MASS_UPDATE_ATTENDANCE" + SUFFIX)
export class MassUpdateAttendance extends Action {
  constructor(public attendanceLabel: string, public checked: boolean) { super(); }
}

@typeName("REFRESH_MASS_UPDATE_ATTENDANCE" + SUFFIX)
export class RefreshMassUpdateAttendance extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  init: (): ActionCreator => dispatch => {
    dispatch(new InitEnterClassRequirementModal());
    dispatch(new SaveState());
  },
  selectClass: (selectedClassId?: number, childClassId?: number): ActionCreator  => (dispatch, getState) => {
    const state = getState() as ApplicationState;

    let selectedClass = state.adminEvents.cacheTwoEvent.EventsEventClasses && state.adminEvents.cacheTwoEvent.EventsEventClasses ? state.adminEvents.cacheTwoEvent.EventsEventClasses.find((o) => o.IDi === selectedClassId) : undefined;

    if (selectedClass) {
      selectedClass = {...selectedClass};
      const allClassTypes = state.cacheZero.options && state.cacheZero.options.ClassTypes ? state.cacheZero.options.ClassTypes : [];

      if (selectedClass.IsCombo && childClassId && selectedClass.ComboClasses) {
        const childClass = selectedClass.ComboClasses.find((child) => child.IDi === childClassId);

        if (childClass) {
          selectedClass.ClassTypeIDi = childClass.ClassTypeIDi;
          selectedClass.IDi = childClass.IDi;
          selectedClass.mbID = childClass.mbID;
        }
        // Only affecting the card layout in this modal
        selectedClass.IsCombo = false;
      }
    }

    if (selectedClass) dispatch(new SelectClassForEnterClassRequirementModal(selectedClass));
  },
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.events.modals.enterClassRequirement),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),

  updateClassRequirementValue: createUpdateValueMethod(ClassRequirementValidateActions, ClassRequirementApiValidateActions, (s: ApplicationState) => s.adminEvents.events.modals.enterClassRequirement.ClassRequirements),
  simpleUpdateClassRequirement: createSimpleUpdateValueMethod(ClassRequirementValidateActions),

  updateClassParticipantValue: createUpdateValueMethod(ClassParticipantValidateActions, ClassParticipantApiValidateActions, (s: ApplicationState) => s.adminEvents.events.modals.enterClassRequirement.ClassRequirements),
  simpleUpdateClassParticipant: createSimpleUpdateValueMethod(ClassParticipantValidateActions),

  resetEnterClassRequirementModal: (): ActionCreator => dispatch => dispatch(new ResetEnterClassRequirementModal()),
  filterClass: (filterText: string): ActionCreator => dispatch => dispatch(new FilterClass(filterText)),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new EnterClassRequirementModalToggleFilter(searching)),
  toggleParticipantAttendance: (participantId: number, attendance: AdminEventClassParticipantAttendance): ActionCreator => dispatch => dispatch(new ToggleParticipantAttendance(participantId, attendance)),
  /**
   * Add all requirements to a participant, this should only be used
   * for old tracking class
   */
  addAllRequirementsToParticipant_OldTracking: (participantId: number): ActionCreator => dispatch => dispatch(new AddAllRequirementsToParticipant_OldTracking(participantId)),
  /**
   * Remove all requirements from a participant, this should only be used
   * for old tracking class
   */
  removeAllRequirementsFromParticipant_OldTracking: (participantId: number): ActionCreator => dispatch => dispatch(new RemoveAllRequirementsFromParticipant_OldTracking(participantId)),
  /**
   * Remove one requirement from participant. This method is used for both
   * new AND old tracking class.
   * 
   * When requirement is a type of number, it used by a new tracking class;
   * otherwise it isued by an old tracking class.
   */
  removeRequirementFromParticipant: (participantId: number, requirement: string | number): ActionCreator => dispatch => dispatch(new RemoveRequirementFromParticipant(participantId, requirement)),
  /**
   * Add one or more requirements to a participant, this should only be used
   * for old tracking class
   */
  addNewRequirementsToParticipant_OldTracking: (participantId: number, requirements: string): ActionCreator => dispatch => dispatch(new AddNewRequirementsToParticipant_OldTracking(participantId, requirements)),
  updateRequirementsForParticipant: (participantId: number, reqIDs: number[]): ActionCreator => dispatch => dispatch(new UpdateRequirementsForParticipant(participantId, reqIDs)),
  massUpdateStatus: (statusOptionId: number): ActionCreator => dispatch => dispatch(new MassUpdateStatus(statusOptionId)),
  massUpdateRequirement: (requirement: string | MBRequirement): ActionCreator => dispatch => dispatch(new MassUpdateRequirement(requirement)),
  massUpdateAttendance: (attendanceLabel: string, checked: boolean): ActionCreator => dispatch => dispatch(new MassUpdateAttendance(attendanceLabel, checked)),
  refreshMassUpdateAttendance: (): ActionCreator => dispatch => dispatch(new RefreshMassUpdateAttendance()),
  cancelGetClassRequirements: (): ActionCreator => dispatch => dispatch(GetAdminClassRequirements.cancel()),
  submit: (): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    const cache = state.adminEvents.cacheThreeClassRequirements;
    const form = state.adminEvents.events.modals.enterClassRequirement;

    const body = getUpdateClassRequirementsBody() as any;

    body.Class = [];
    if (cache.EventsEventClassRequirements) {
      body.Class.push({
        IsPrevious: true,
        InstructorName: cache.EventsEventClassRequirements.InstructorName,
        InstructorInitials: cache.EventsEventClassRequirements.InstructorInitials,
      });
    }

    body.Class.push({
      IsPrevious: false,
      InstructorName: form.ClassRequirements.ActiveForm.InstructorName,
      InstructorInitials: form.ClassRequirements.ActiveForm.InstructorInitials,
    });

    body.Participants = [];

    if (cache.EventsEventClassParticipants) {
      cache.EventsEventClassParticipants.forEach((pt) => {
        body.Participants.push({
          IsPrevious: true,
          IDi: pt.IDi,
          CompletionID: pt.CompletionID,
          ...pt.Attend,
          ReqsCompletedCSV: cache.EventsEventClassRequirements?.mbID ? undefined : pt.ReqsCompletedCSV,
          Requirements: cache.EventsEventClassRequirements?.mbID ? pt.Requirements : undefined,
        });
      });
    }

    form.ClassParticipants.forEach((pt) => {
      const Attend = {} as any;
      
      pt.AttendArr.forEach((attend) => {
        Attend[attend.label] = attend.checked;
      });
      body.Participants.push({
        IsPrevious: false,
        IDi: pt.IDi,
        CompletionID: pt.ActiveForm[getClassParticipantStatusKey(pt)],
        ...Attend,
        // NOTE: If status is NO_SHOW, send empty array to backend
        Requirements: pt.ActiveForm[getClassParticipantStatusKey(pt)] === 3 ? [] : pt.Requirements,
      });
    });

    dispatch(new SaveState());
    dispatch(new ResetEnterClassRequirementModal());
    dispatch(appActionCreators.popModal(false, false, ModalTypes.ENTER_CLASS_REQUIREMENT_COMPLETED) as any);
    dispatch(UpdateAdminClassRequirements.request(
      body,
      getCacheLevelExtra(true, true),
    ));
    dispatch(new ClearAdminEventsCacheThreeClassRequirements());
  },
};