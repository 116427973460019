import {GetEventParticipantData, CacheFourEventsParticipantNoRequestNeeded} from './actions';
import {APISuccess, APIRequest, WithRootState} from "../Validation/actionCreator";
import {ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction} from "../App/actions";
import {ClearCacheBelowOne} from "../CacheOne/actions";
import {RegisteredClass} from "../../models/class";
import {ClearCacheBelowTwoEvents} from "../CacheTwoEvents/actions";
import {ClearCacheBelowThreeEvents} from "../CacheThreeEvents/actions";
import {makeSingleSelector} from "../../utils/reselectHelper";
import {EventRegistrationParticipant, EventInfoParticipantType} from "../../models/api/cacheThreeEvents";
import {createSelector} from "reselect";
import {participantsSelector, participantTypesSelector} from "../CacheThreeEvents/index";
import {EmptyCartActions, RemoveItemFromCartActions} from "../Cart/actions";
import { ApplicationState } from '..';
import { captureTentarooError } from '../../utils/dataHelper';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../utils/StrongActions';
import { EventParticipantCalc } from '../CacheFourEventsParticipants/actions';
import EndUserCacheManager from '../../utils/cacheManagers/endUserCacheManager';
import {isCacheFourEventsViewParticipantPopulated} from '../../utils/cachePopulatedCheckers/endUser';

export const participantIDiSelector = (state: ApplicationState) => state.cacheFourEventsViewParticipant.participantIDi;
export const participantTypeIDSelector = (state: ApplicationState) => state.cacheFourEventsViewParticipant.participantTypeID;
export const isYouthSelector = (state: ApplicationState) => !!state.cacheFourEventsViewParticipant.isYouth;

export const makeSelectedParticipantSelector = () => createSelector(
  [participantsSelector, participantIDiSelector, isYouthSelector],
  (items: Array<EventRegistrationParticipant>, id: number, isYouth: boolean) => {
    if (!id) return undefined;
    if (!items || items.length === 0) return undefined;
    const fItems = items.filter((item: EventRegistrationParticipant) => item.IsYouth === isYouth && item.IDi === id);
    if (fItems.length === 0) return undefined;
    return fItems[0];
  }
);

export const makeSelectedParticipantTypeSelector = makeSingleSelector(
  (item: EventInfoParticipantType, id: number) => item.ID === id,
  participantTypesSelector,
  participantTypeIDSelector
);

export interface CacheFourEventsViewParticipantCore {
  EventClassesIndividualRegistered?: Array<RegisteredClass> | null;
}

export interface CacheFourEventsViewParticipantState extends CacheFourEventsViewParticipantCore {
  participantIDi: number;
  participantTypeID: number;
  isYouth?: boolean;
}

const setCacheFourData = (data: CacheFourEventsViewParticipantCore, pIDi: number, isYouth: boolean, ptID: number): CacheFourEventsViewParticipantState => {
  return {
    EventClassesIndividualRegistered: data.EventClassesIndividualRegistered,
    participantIDi: pIDi,
    participantTypeID: ptID,
    isYouth: isYouth
  };
};

const CacheFourEventsViewParticipant: Reducer<CacheFourEventsViewParticipantState> = (state: CacheFourEventsViewParticipantState, act: WithRootState<AnyAction>) => {
  if (act.type === GetEventParticipantData.successType || act.type === EmptyCartActions.successType || act.type === RemoveItemFromCartActions.successType) {
    const action = <WithRootState<APISuccess>> act;
    const bodyStr = action.response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      let participants: Array<EventRegistrationParticipant>;
      if (action.response.response.EventRegistrationParticipants) participants = action.response.response.EventRegistrationParticipants;
      else participants = action.rootState.cacheThreeEvents.EventRegistrationParticipants || [];

      // @todo: we don't actually need to do this every time, only when requestType was unable
      const participant = participants.find((p) => p.IDi === body.ParticipantIDi);
      if (!participant) {
        return state;
      }

      return setCacheFourData(action.response.response, body.ParticipantIDi, body.IsYouth, participant.ParticipantTypeID);
    } catch (e) {
      captureTentarooError(new Error('Unable to parse request body for cache 4 event participant!'));
      return state;
    }
  } else if (isActionType(act, SetCacheAction) && (
    act.actions.requestType === EventParticipantCalc.requestType
  )) {
    if (isCacheFourEventsViewParticipantPopulated(state)) {
      const action = <WithRootState<APISuccess>> act;
      const data = action.response.response;
      return {
        ...state,
        EventClassesIndividualRegistered: data.EventClassesIndividualRegistered,
      };
    }

    return state;
  } else if (isActionType(act, CacheFourEventsParticipantNoRequestNeeded)) {
    return {
      participantIDi: act.pId,
      isYouth: act.isYouth,
      participantTypeID: act.typeId,
      EventClassesIndividualRegistered: null
    };
  } else if (act.type === GetEventParticipantData.requestType) {
    // @todo: set participantIDi, isYouth, and participantTypeID
    const action = <WithRootState<APIRequest>> act;
    const participants = action.rootState.cacheThreeEvents.EventRegistrationParticipants;
    if (participants) {
      const participant = participants.find((p) => p.IDi === action.value.ParticipantIDi);

      if (participant) {
        return {
          ...state,
          participantIDi: action.value.ParticipantIDi,
          participantTypeID: participant.ParticipantTypeID,
          isYouth: action.value.IsYouth
        };
      }
    }
    // Then there's no data for partial loading
    return state;
  } else if (
    isActionType(act, ClearAllCache) ||
    isActionType(act, ClearAllEndUserCacheButOptions) ||
    isActionType(act, ClearCacheBelowOne) ||
    isActionType(act, ClearCacheBelowTwoEvents) ||
    isActionType(act, ClearCacheBelowThreeEvents)
  ) {
    return {participantIDi: 0, participantTypeID: 0};
  }
  return state || {participantIDi: 0, participantTypeID: 0};
};

export default CacheFourEventsViewParticipant;
