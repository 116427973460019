import {
  APIRequestActions,
  SAVE,
  SubmitActions,
  DelayQueueActions,
  CLEAR_CACHE_PREFIX,
  ValidateActions,
  SEQUENTIAL_SUBMIT_REQUEST,
} from './actionCreator';
import {ClearSavePointDuringSave, RestoreState, SaveState} from '../Rollback/actions';
import {Observable} from "rxjs";
import {AjaxResponse} from "rxjs/ajax";
import {ajaxPost, parsePayload} from '../../utils/ajaxHelper';
import {SetCacheAction, ShowSnackbarItem, ToggleModalSaving, NoOp, ShowTopFloatingAlertInQueue, SetAfterLoginPath, ClearAllCache, SilentCancelAllAction} from "../App/actions";
import {push, replace} from 'react-router-redux';
import {API_REQ} from "../../constants/request";
import {getReportUrl, QP_ACCOUNT, URLS} from "../../constants/urls";
import {FacilitiesCalc} from "../CacheFourFacilities/actions";
import {
  FSSubmitActions,
  ReportBlueCardsActions,
  ReportInvoiceActions,
  ReportRequirementsCompletedActions,
  ReportRequirementsScoutbookActions,
  ReportRosterActions
} from "../Events/Event/Registration/actions";
import {ReportAuditLogActions} from "../Settings/PrevOrders/AuditLog/actions";
import { getMessageFromErrorResponse } from '../../utils';
import { delay } from 'rxjs/operators';
import { ReportGroupTripsActions, ReportGroupReservationsActions } from '../AdminFacilityLocation/Reports/actions';
import { GenerateAdminEventsEventTypeReportsActions } from '../AdminEvents/Reports/GenerateReports/actions';
import { GenerateAdminEventsEventReportsActions } from '../AdminEvents/Events/Event/Dashboard/actions';
import { ModalTypes, isModalOpened } from '../../utils/modalHelper';
import { AddResourceSubmitActions } from '../AdminCMSSite/Resources/Resource/Form/actions';
import { AddTripSubmitActions } from '../Facilities/Trip/Form/actions';
import { ApplicationState } from '..';
import { ApiSubmitActions as CreateAccountApiSubmitActions } from '../CreateAccount/actions';
import { FacFSSubmitActions } from '../Facilities/Trip/Summary/actions';
import { EmptyCartActions, RemoveItemFromCartActions } from '../Cart/actions';
import { RestoreGroupActions } from '../Admin/Modals/Accounts/actions';
import { ChangePaymentTypeActions, CheckoutSubmitActions } from '../Checkout/actions';
import { ApiSubmitActions as ProfileApiSubmitActions, ApiSubmitDeleteActions as ProfileApiSubmitDeleteActions, ResetPasswordUpdateGroupSendResetLinkSubmitActions } from '../Settings/Profile/actions';
import { RestoreGroupRoster } from '../Settings/Roster/Main/actions';
import { ApiSubmitActions as AddGroupApiSubmitActions } from '../AddGroup/actions';
import { ResetPasswordSearchSubmitActions, ResetPasswordUpdateSubmitActions } from '../ResetPassword/actions';
import { EditEmailActions } from '../Settings/EditEmail/actions';
import { ChangePasswordActions } from '../Settings/ChangePassword/actions';
import { SubmitManageOrderActions } from '../Settings/PrevOrders/ManageOrder/actions';
import { RefundOrFeeActions } from '../Settings/PrevOrders/RefundOrFee/actions';
import { ApiDeleteRosterActions } from '../Events/Event/Register/Participant/Roster/actions';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../utils/suffix';
import { captureTentarooError } from '../../utils/dataHelper';
import {AppState} from "../App";
import { deepCompare } from './epicHelpers';
import { pushModalObservables } from '../../utils/modalHelper';
import { isActionType } from '../../utils/StrongActions';
import { reduxStoreService } from '../service';
import { Validator } from '../../utils/validator/models';
import {restoreStateDirectlyOrThroughBrowser} from '../../rollback/helpers';

// use to test Rollback with commented code below
// window.failNext = false;
// window.testError = 'something';

// use to test parse payload with commented code below - could test in `CreateAccount` scene
// window.debugParsePayload = true;

const isSilentCancelAllAction = (action) => {
  return isActionType(action, SilentCancelAllAction) || (isActionType(action, RestoreState) && action.cancelRequests);
};

const shouldCancelBackendValidate = (action, rootState: ApplicationState, requestType: string, cancelType: string, key: string) => {
  const result = ((action.type === cancelType && action.vObj && action.vObj.key === key) ||
  isSilentCancelAllAction(action) ||
  (action.type.startsWith(API_REQ) && action.type.includes(SAVE))) && rootState && rootState.app.apiLoadingMap[requestType];
  return result;
};

export const makeBackendValidateCancelEpic = (key, actions: ValidateActions, vObj: Validator) => {
  return (action$, state$) => {
    return action$
      .filter((a) => shouldCancelBackendValidate(a, state$.getState(), actions.requestType, actions.cancelType, key))
      .map((a) => {
        return {
          type: actions.cancelType,
          vObj,
          rollback: true
        };
      })
      .take(1)
      .repeat();
  };
};

export const makeBackendValidateEpic = (key, actions: ValidateActions, debounceTime?: number) => {
  return (action$, state$) => {
    return action$
      .filter((a) => a.type === actions.requestType && a.vObj.key === key)
      .debounceTime(debounceTime ? debounceTime : 250)
      .switchMap((action) =>
        Observable.ajax(
          ajaxPost(
            action.vObj.apiCheck.url(),
            action.vObj.apiCheck.body(reduxStoreService().getState(), action.value),
          )
        )
        .mergeMap((p: any) => {
          if (p.type) {
            return Observable.of(p);
          }
          
          const payload = parsePayload(p);

          if (payload.parseError) {
            return Observable.of({
              type: actions.failureType,
              vObj: action.vObj,
              response: payload,
              rollback: false,
            });
          }
          // if (window.failNext) {
          //   return {
          //     type: validateFailureAction + suffix,
          //     vObj: action.vObj,
          //     response: {
          //       status: 500,
          //       message: 'test error'
          //     }
          //   };
          // }
          return Observable.of({
            type: actions.successType,
            vObj: action.vObj,
            response: payload,
            rollback: false
          });
        })
        .catch(p => {
          const payload = parsePayload(p);
          const failureAction = {
            type: actions.failureType,
            vObj: action.vObj,
            response: payload
          };
          return handleError(payload, actions, undefined, failureAction);
        })
    )
    .takeUntil(action$.filter((a) => shouldCancelBackendValidate(a, state$.getState(), actions.requestType, actions.cancelType, key)))
    .repeat();
  };
};

export const makeDebounceEpic = (
  actions: APIRequestActions<any> | SubmitActions,
  url: (value?: any) => string,
  body?: (value?: any) => any,
  debounceTime?: number
) => {

  return (action$) => {
    return action$.filter(a => {
      return a.type === actions.requestType;
    })
      .debounceTime(debounceTime ? debounceTime : 250)
      .switchMap((action) =>
        Observable.ajax(ajaxPost(url(), body ? body(action.value) : (action.form ? action.form : action.value)))
          .mergeMap((p: any) => {

            // if (window.failNext) {
            //   return Observable.concat(
            //     Observable.of(...rollback()),
            //     Observable.of(actions.failure({
            //       ...payload,
            //       status: 400,
            //       xhr: {
            //         ...payload.xhr,
            //         response: {
            //           ...payload.xhr.response,
            //           error: {
            //             ...payload.xhr.response.error,
            //             Detail: 'Incorrect Payment'
            //           }
            //         }
            //       }
            //     }))
            //   );
            // }
            const payload = parsePayload(p);

            // if failed to parse payload
            if (payload.parseError) return handleError(payload, actions);

            return Observable.merge(
              Observable.of(actions.success(payload, action.extra)),
              Observable.of(new SaveState()),
            );
          })
          .catch(p => {
            const payload = parsePayload(p);
            return handleError(payload, actions, undefined, undefined, true);
          })
      )
      .takeUntil(action$.filter((a) => isSilentCancelAllAction(a)))
      .repeat();
  };
};

type AjaxFunction = (res?: AjaxResponse, action?: any) => string;

export const makeSavePointDuringSaveEpic = () => {
  return (action$, state$) => {
    return action$
      .filter((action) => {
        const rootState = state$.getState() as ApplicationState;
        // Do the second save point immediately after request is sent when
        // 1. We see a clear cache action AND its NOT during a save NOR during a rollback
        //    - This is because usually clear cache actions will be dispatched on component unmount, which will be triggered
        //      after the request is sent out
        // 2. Some special cases where we dont clear the cache after the request is sent
        const isSpecialCase = 
          /* Special cases in end user side */
          // Rosters
          (action.type === RestoreGroupRoster.requestType && action.currentRoute.path === URLS.MY_ROSTER) ||
          // Orders
          action.type === SubmitManageOrderActions.requestType ||
          action.type === RefundOrFeeActions.requestType ||
          // Reset Password
          action.type === ResetPasswordSearchSubmitActions.requestType ||
          action.type === ResetPasswordUpdateSubmitActions.requestType ||
          // Profile/Group
          action.type === EditEmailActions.requestType ||
          action.type === ChangePasswordActions.requestType ||
          action.type === ProfileApiSubmitActions.requestType ||
          action.type === ProfileApiSubmitDeleteActions.requestType ||
          action.type === RestoreGroupActions.requestType ||
          action.type === AddGroupApiSubmitActions.requestType ||
          action.type === CreateAccountApiSubmitActions.requestType ||
          action.type === ResetPasswordUpdateGroupSendResetLinkSubmitActions.requestType ||
          // Checkout
          action.type === CheckoutSubmitActions.requestType ||
          action.type === RemoveItemFromCartActions.requestType ||
          action.type === EmptyCartActions.requestType ||
          action.type === ChangePaymentTypeActions.requestType ||
          // Cache Three Facilities
          action.type === AddTripSubmitActions.requestType ||
          action.type === FacFSSubmitActions.requestType ||
          // Cache Three Event
          action.type === FSSubmitActions.requestType ||
          // Participant Wizard - Deleting from roster within wizard
          action.type === ApiDeleteRosterActions.requestType ||
          
          /* Special cases in admin side */
          // CMS - Resource
          // Special case is saving an existing resource via modal.
          // In this case we are not leaving the form on save so upload can complete
          action.type === AddResourceSubmitActions.requestType;
        return (
          (
            // When saving form that is leaving the page, we want to perform the second save point AFTER clear cache is done
            action.type.startsWith(CLEAR_CACHE_PREFIX) ||

            // Saves for data not loaded into cache.
            // - For example, deleting an item from the list. If false, we weren’t at the cache level so we won’t clear cache.
            // - inMatchingCacheLevelOn409 will be false when adding, so SaveState during save won't have form clear, and if we rollback during save, form data will
            // persist after save completes.
            (action.extra && action.extra.inMatchingCacheLevelOn409 === false) ||

            // Saves that don’t leave cache on success, there won’t be a clear cache in this case. 
            (action.extra && action.extra.leavingCacheLevelOnSuccess === false) || 

            isSpecialCase
          ) &&
          !action.type.includes(SEQUENTIAL_SUBMIT_REQUEST) &&
          rootState && rootState.app.apiSaving > 0 &&
          !rootState.rollback.savePointDuringSave
        );
      })
      .map(() => new SaveState(true));
  };
};

// @todo: maybe restore should default to true
// @todo: this should implement blockScroll somehow
// TODO: Later, change this parameter passing to something like:
// makeRequestEpic(options: IOptions), where options contains all parameters, that way
// it is way easier to use and read the usage of this function
export const makeRequestEpic = (
  actions: APIRequestActions<any> | SubmitActions,
  url: (value?: any) => string,
  body?: (value?: any) => any,
  restore?: boolean, // @todo: remove restore
  successMessage?: AjaxFunction | string,
  nextUrl?: AjaxFunction | string,
  populate?: () => void,
  ajaxObj?: any,
  noSaveOnFinish?: boolean,
  formDataRequest?: boolean,  // if the request is sending `form-data`
  successCallback?: (payload, extra?: any) => any,
  // TODO: lets leave it here for now, to avoid regression. When removing it, as well as
  // removing the `restore` param above, use the `options: IOptions` proposal above, to make this API
  // clearer and more stable
  toggleModalSavingOff?: boolean, // deprecated
  customTimeout?: number,
  shouldReplaceNextUrl?: boolean,
) => {
  // TODO: Typing for these streams?
  return (action$, state$) => {
    return action$.ofType(actions.requestType)
    // I don't think we really care to have debounce here anyway, it only affects the "spam refresh" cases, which get canceled anyway
    //   .debounceTime(250) // @todo: if this is not 0, it is possible to fire master cancel action and not cancel a request, ie case 2: eventtype > event > new group > back > back
      .switchMap((action) => {
        const postObj = ajaxPost(
          url(action.value !== undefined ? action.value : action.form),
          body ? body(action.value !== undefined ? action.value : action.form) : (action.form ? action.form : action.value),
          formDataRequest,
          customTimeout,
        );
        const rootState = state$.getState() as ApplicationState;

        if (action.type?.includes(SAVE)) {
          /**
           * NOTE: Because epic is triggered _after_ redux is mutated. At this point, `apiSaving` flag
           * is always set to 1. Hence, we need to check `apiSavingMap` to figure out whether or not
           * there are other save requests running
           */
          const isRunningOtherSaveRequests = Object.keys(rootState.app.apiSavingMap)
                                .filter((key) => key !== action.type)
                                .map((key) => rootState.app.apiSavingMap[key])
                                .some((value) => Boolean(value));
          if (isRunningOtherSaveRequests) {
            captureTentarooError(new Error(`Dispatching save ${action.type} during another save`));
          }
        }

        return Observable.merge(
                Observable.ajax(ajaxObj ? ajaxObj : postObj)
                .race(
                  action$.filter((a) => {
                    if (actions.requestType.includes(SAVE)) {
                      // The only way to cancel save is with the explicit cancel action
                      return a.type === actions.cancelType;
                    }
                    // Load will be canceled by its cancel, cancel alls and any other request
                    return (
                      a.type === actions.cancelType ||
                      isSilentCancelAllAction(a) ||
                      (a.type && a.type.startsWith(API_REQ) && a.type !== actions.requestType)
                    );
                  })
                    .map((cancelAction) => {
                      // console.log('canceled: ' + actions.requestType);
                      if (cancelAction.type === actions.cancelType && (!cancelAction.extra || !cancelAction.extra.skipRestoreState)) {
                        /**
                         * Keep this RestoreState instead of moving it to `boot-client` also.
                         * This one is for specific cancel action. For example, in ClassRequirement modal, we would cancel the request
                         * when user navs back during load. In that case, there is no location change, but we need a RestoreState.
                         */
                        return new RestoreState(cancelAction.extra ? cancelAction.extra.retainNodeNames : false);
                      }

                      if (isSilentCancelAllAction(cancelAction)) {
                        if (isActionType(cancelAction, SilentCancelAllAction) && !cancelAction.isDuringNavigation && !cancelAction.skipRestoreState) {
                          return new RestoreState();
                        }
                      }
                      return new NoOp();
                    })
                    .take(1)
                )
                .mergeMap((p: any) => {
                  if (p.type) {
                    return Observable.of(p);
                  }
                  const payload = parsePayload(p);
                  if (payload.parseError) {
                    return handleError(payload, actions);
                  }

                  try {
                    if (actions.requestType.includes(SAVE)) {
                      const rootState = reduxStoreService().getState();
                      const savePointDuringSave = rootState.rollback.savePointDuringSave;
                      if (!savePointDuringSave || !savePointDuringSave.oldState) {
                        captureTentarooError(new Error(`Save request ${actions.requestType} is missing a save point during save`));
                      } else {
                        Object.keys(rootState).forEach((key: keyof ApplicationState | "routing") => {
                          if (key === "rollback" || key === "routing") return;

                          if (key === "app") {
                            /**
                             * Fields we are skipping:
                             * - `topFloatingAlert`, `topFloatingAlertColor` & `snackbar`, because they could be shown after the request completes, which doesn't match the save point
                             * - `showAdminPageHeader` is set in admin pages' componentDidMount call if needed,
                             * and hence will be set after the save point during save when for example saving AdminPage/AdminFacility.
                             *   In those cases, it will be set again after rollback to save point during save, so we can safely ignore it.
                             */
                            const appKeyToSkip: (keyof AppState)[] = [
                              "topFloatingAlert",
                              "topFloatingAlertColor",
                              "alertInModal",
                              "snackbar",
                              "showAdminPageHeader",
                            ];

                            deepCompare(
                              rootState.app,
                              savePointDuringSave.oldState.app,
                              actions.requestType,
                              `app`,
                              appKeyToSkip,
                            );
                          } else {
                            // Compare OTHER values under state
                            deepCompare(
                              rootState[key],
                              savePointDuringSave.oldState[key],
                              actions.requestType,
                            );
                          }
                        });
                      }
                    }
                  } catch (e) {
                    captureTentarooError(e);
                  }

                  return Observable.merge(
                    Observable.of(actions.success(payload, action.extra)),
                    Observable.if (
                      () => actions.requestType.includes(SAVE),
                      Observable.of(new ClearSavePointDuringSave()),
                    ),
                    Observable.if (
                      () => {
                        const shouldUpdateForm = actions.updateFormType.includes(UPDATE_FORM_ACTION_SUFFIX);
                        if (actions.requestType.includes(SAVE)) {
                          /**
                           * If it's a save request, as long as updateForm suffix is defined, we dispatch
                           * updateForm action, and let cache/form reducer to handle it.
                           * 
                           * TODO: Later, also handle !409 case here, prevent action from dispatching? See conditions in `shouldSkipUpdateForm`
                           */
                          return shouldUpdateForm;
                        } else {
                          /**
                           * If it's a load request, only dispatch `updateForm` when `extra.skipUpdateForm` is
                           * NOT set to true
                           * 
                           * TODO: discuss - always config `skipUpdateForm` to true/false for load request?
                           */
                          return shouldUpdateForm && (!action.extra || action.extra.skipUpdateForm === false);
                        }
                        
                      },
                      Observable.of(actions.updateForm(action.extra))
                    ),
                    Observable.if(
                      () => !!populate,
                      Observable.of(!!populate ? populate() : undefined)
                    ),
                    Observable.if(
                      // push to next url if
                      // - nextUrl is provided (i.e. not blank) and
                      //    - nextUrl is a string OR
                      //    - nextUrl is a function and the result is not empty
                      () => !!nextUrl && (typeof nextUrl === 'string' || !!nextUrl(payload, action)),
                      Observable.of(
                        shouldReplaceNextUrl ? 
                        replace(nextUrl ? (typeof nextUrl === 'string' ? nextUrl : nextUrl(payload, action)) : '') : 
                        push(nextUrl ? (typeof nextUrl === 'string' ? nextUrl : nextUrl(payload, action)) : '')
                      )
                    ),
                    Observable.if(
                      () => !!successMessage,
                      Observable.of(new ShowSnackbarItem(successMessage === undefined ? '' : (typeof successMessage === 'string' ? successMessage : successMessage(payload, action))))
                    ),
                    Observable.if(
                      () => !!successCallback,
                      successCallback && successCallback(payload, action.extra),
                    ),
                    Observable.if(
                      /**
                       * Because there are many requests can get `Login.IsTemporaryPassword`,
                       * we handle them all here in one place
                       * 
                       * NOTE: because this modal will ONLY be shown when a request comes back, 404 page will never
                       * reach here - we never send any load request in 404 pages
                       */
                      () => (
                        !isModalOpened(ModalTypes.CHANGE_PASSWORD) &&
                        (
                          (payload.response && payload.response.Login && payload.response.Login.IsTemporaryPassword) ||
                          // Also checking `IsTemporaryPassword` in store here, so that if the ChangePassword
                          // modal is closed by browser back/forward, it will be re-opened again after
                          // any subsequent load during the app, so that user is required to change password.
                          reduxStoreService().getState().cacheZero.options?.Login?.IsTemporaryPassword
                        )
                      ),
                      Observable.concat(
                          ...pushModalObservables({
                          modal: ModalTypes.CHANGE_PASSWORD,
                          saveBefore: false,
                          saveAfter: false,
                          transformToObservable: true,
                        }),
                      ),
                    ),
                    Observable.if(
                      () => !!reduxStoreService().getState().app.isModalSaving,
                      Observable.of(new ToggleModalSaving(false)),
                    ),
                    Observable.if(
                      () => !noSaveOnFinish,
                      Observable.of(new SaveState())
                    ),
                    Observable.if(
                      () => action && action.extra && action.extra.nextRequest,
                      Observable.of(action && action.extra && action.extra.nextRequest ? action.extra.nextRequest : new NoOp()),
                    ),
                  );
                })
                .catch(p => {
                  const payload = parsePayload(p);
                  return handleError(payload, actions, action.extra);
                }),
              );
        }
      );
  };
};

// @todo: simplify the epic by removing any unnecessary handlings regarding to image upload/delete/restore
// @todo: check conditions and handlers of cancellation to cancel the save request properly - similar to `makeDebounceEpic`
export const makeSequentialRequestEpic = (
  actions: SubmitActions[],
  url: (actionType: string) => string,
  actionTypeSuffix: string,   // we add this mandatory field to group related action to one queue (i.e. one epic)
  body?: (value?: any) => any,
  successMessage?: AjaxFunction | string,
  noSaveOnFinish?: boolean,
) => {
  return action$ => {
    return action$.filter((a) => a.type.includes(actionTypeSuffix) && a.type.includes(SAVE))
      .concatMap((action) => {
        return Observable.ajax(ajaxPost(url(action.type), body ? body(action.value) : (action.form ? action.form : action.value), action.extra ? action.extra.formDataRequest : false))
          .mergeMap((p: any) => {
            // we accept an array of actions to be processed by this epic, and therefore we need to find out
            // which exact action we are processing right now in order to obtain payload, type, etc.
            // Same in the `catch` block
            const _actions = actions.find((a) => a.requestType === action.type);
            if (p.type) {
              return Observable.of(p);
            }
            const payload = parsePayload(p);
            if (payload.parseError) {
              return handleSequentialRequestError(payload, _actions, action.extra);
            }

            return Observable.merge(
              Observable.of(_actions ? _actions.success(payload, action.extra) : new NoOp()),
              Observable.if (
                () => _actions ? _actions.updateFormType.includes(UPDATE_FORM_ACTION_SUFFIX) : false,
                Observable.of(_actions ? _actions.updateForm(action.extra) : new NoOp())
              ),
              Observable.if(
                () => !!successMessage,
                Observable.of(new ShowSnackbarItem(successMessage === undefined ? '' : (typeof successMessage === 'string' ? successMessage : successMessage(payload, action))))
              ),
              Observable.if(
                () => !noSaveOnFinish,
                Observable.of(new SaveState())
              ),
            );
          })
          .catch(p => {
            const _actions = actions.find((a) => a.requestType === action.type);
            const payload = parsePayload(p);
            return handleSequentialRequestError(payload, _actions, action.extra);
          });
        }
      )
      .takeUntil(action$.filter((a) => isSilentCancelAllAction(a)))
      .repeat();
  };
};

export const makeDelayQueueEpic = (
  actions: DelayQueueActions,
  delayTime: number,
) => {
  return (action$) => {
    return action$.filter((a) => a.type === actions.requestType)
          .concatMap((action) => {
            return Observable.of(actions.success(action.payload)).pipe(delay(delayTime));
          })
          .takeUntil(action$.filter((a) => isSilentCancelAllAction(a)))
          .repeat();
  };
};

const getActionsOn401Error = () => {
  return [
    new SetAfterLoginPath(
      reduxStoreService().getState().routing.locationBeforeTransitions.pathname,
      reduxStoreService().getState().routing.locationBeforeTransitions.query[QP_ACCOUNT]
    ),
    // This `ClearAllCache` will also clear sessions, and this will cause `handleRedirection` in cacheLoaderHelper.ts
    // because every page is connected to the data
    new ClearAllCache(true),
  ];
};

const handleSequentialRequestError = (payload, actions, extra) => {
  // on any error, we would simply call the action failure, no rollback, no SetCacheAction
  const ret: Array<any> = [actions.failure(payload, undefined, undefined, undefined, extra)];
  
  if (payload.status === 400) {
    // we need to dispatch the top floating alert in queue here, so cannot do it in Reducer, but need
    // to dispatch the `ShowTopFloatingAlertInQueue` here
    const errorMessage = getMessageFromErrorResponse(payload);
    if (errorMessage) ret.push(ShowTopFloatingAlertInQueue.request({
      message: errorMessage,
      inModal: false,
      color: 'orange',
    }));
  }
  if (payload.status === 401) {
    ret.push(...getActionsOn401Error());
  }
  ret.push(new SaveState());

  return ret;
};

const handleError = (payload, actions, extra?: any, customFailureAction?: any, shouldSilentCancelAll?: boolean) => {
  const failureAction = customFailureAction ? customFailureAction : actions.failure(payload);
  let ret: Array<any> = [
    failureAction,
  ];

  if (actions.requestType.includes(SAVE)) {
    ret.push(new ClearSavePointDuringSave());
  }
  // 400, 401, 406, 409, 422, 500, timeout
  // 404 doesn't
  if (
    payload.status !== 404 &&
    (payload.status !== 400 || actions.failureType !== FacilitiesCalc.failureType)
  ) {
    // When we are handling a custom failure action (for backend validation)
    // we shouldn't rollback
    ret = customFailureAction ? [] : [...restoreStateDirectlyOrThroughBrowser(true)];

    if (actions.requestType.includes(SAVE)) {
      ret.unshift(new ClearSavePointDuringSave());
    }
    ret.push(failureAction);
    // ret = [rollback(), actions.failure({
    //   ...payload,
    //   status: 400,
    //   xhr: {
    //     ...payload.xhr,
    //     response: {
    //       ...payload.xhr.response,
    //       error: {
    //         ...payload.xhr.response.error,
    //         Detail: 'something else'
    //       }
    //     }
    //   }
    // })];
  }
  if (payload.status === 401) {
    ret.push(...getActionsOn401Error());
  } else if (!customFailureAction && payload.status === 409) {
    return Observable.merge(
      Observable.if(
        () => actions.requestType.includes(SAVE),
        Observable.of(new ClearSavePointDuringSave()),
      ),
      ...restoreStateDirectlyOrThroughBrowser(true).map((a) => Observable.of(a)),
      Observable.of(failureAction),
      Observable.of(new SetCacheAction(payload, actions, extra)),
      Observable.of(actions.updateForm({...extra, is409: true})),
      Observable.of(new SaveState()),
    );
  }

  return ret;
};


export const jsonReportSuccessEpic = action$ => (
  action$.filter(action => action.type &&
    (
      action.type === ReportInvoiceActions.successType || action.type === ReportRequirementsCompletedActions.successType ||
      action.type === ReportBlueCardsActions.successType || action.type === ReportAuditLogActions.successType ||
      action.type === ReportGroupTripsActions.successType || action.type === ReportGroupReservationsActions.successType ||
      action.type === ReportRosterActions.successType || action.type === GenerateAdminEventsEventTypeReportsActions.successType ||
      action.type === GenerateAdminEventsEventReportsActions.successType || action.type === ReportRequirementsScoutbookActions.successType
    )
  )
    .switchMap((act) => Observable.concat(
      ...pushModalObservables({
        modal: ModalTypes.REPORT_FINISHED,
        saveBefore: false,
        saveAfter: false,
        modalProps: {reportURL: getReportUrl(
          act.response.response.Filename,
          act.response.response.ReportPath,
        )},
        transformToObservable: true,
      }),
    ))
);