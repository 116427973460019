import * as React from 'react';

import { Chip, ContentBlock, Title, TextField, Alert, Row } from '../../../../../../../Elements';
import { PersonFormTemplate } from '../../../../../../../Templates';
import { EventTypeRegistrationSettings } from '../../../../../../../../models/api/cacheTwoEvents';
import { EventRegistrationPersonCustomField } from '../../../../../../../../models/api/cacheFourEvents';
import {EventInfo} from "../../../../../../../../models/api/cacheThreeEvents";
import { Validator } from '../../../../../../../../utils/validator/models';

export interface Props {
  preventUnselect?: boolean;
  onUnselect: (person: any) => void;
  EventTypeRegistrationSettings: EventTypeRegistrationSettings;
  isYouth: boolean;
  groupUnitType: string;
  GroupTypeID: number;
  OldScoutTypeID: number;
  ValidationRules: any;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
  };
  ActiveForm: any;
  EventRegistrationPersonCustomFields?: Array<EventRegistrationPersonCustomField> | null;
  SubmitErrorMessage?: string;
  EventInfo: EventInfo;
}

class Person extends React.Component<Props, {}> {
  public props: Props;

  unselect = () => this.props.onUnselect(undefined);
  public render(): any {
    const {
      EventTypeRegistrationSettings, isYouth, groupUnitType, GroupTypeID, OldScoutTypeID,
      ValidationRules, ActiveForm, reduxActions, EventRegistrationPersonCustomFields, SubmitErrorMessage,
      preventUnselect, EventInfo
    } = this.props;
    let showNotes = false;
    if (isYouth) {
      showNotes = EventTypeRegistrationSettings.NamesRegistrationSettings.ShowRegistrationNotesYouth;
    } else {
      showNotes = EventTypeRegistrationSettings.NamesRegistrationSettings.ShowRegistrationNotesAdult;
    }

    return [
      SubmitErrorMessage ? <Alert noFlex>{SubmitErrorMessage}</Alert> : null,
      !preventUnselect ? <Chip onClose={this.unselect}>{`${ActiveForm.Name}`}</Chip> : null,
      <PersonFormTemplate
        reduxActions={reduxActions}
        ActiveForm={ActiveForm}
        ValidationRules={ValidationRules}
        GroupTypeID={GroupTypeID}
        groupUnitType={groupUnitType}
        OldScoutTypeID={OldScoutTypeID}
        isYouth={isYouth}
        EventTypeRegistrationSettings={EventTypeRegistrationSettings}
        customFields={EventRegistrationPersonCustomFields}
        EventInfo={EventInfo}
      />,
      showNotes ? <ContentBlock>
        <Title size={20} mobileSize={20} mobileMarginBottom={4}>
          Registration Notes
        </Title>
        <Row>
          <TextField
            label="Registration Notes"
            value={ActiveForm.RegistrationNotes}
            onChange={reduxActions.simpleUpdate}
            onBlur={reduxActions.updateValue}
            validationRules={ValidationRules.RegistrationNotes}
            rows={3}/>
        </Row>
      </ContentBlock> : null
    ];
  };
};

export default Person;
