import { ToggleNotFound } from "../../store/App/actions";
import { SaveState } from "../../store/Rollback/actions";
import { GetLocation } from "../../store/AdminSettings/CacheTwoLocation/actions";
import { UpdateLocationSubmitActions } from "../../store/AdminSettings/Modals/LocationForm/actions";
import { reduxStoreService } from "../../store/service";
import {isIdValid} from "../urlHelper";
import { LoadAdminSettingsLocationParams } from "./helpers/models";
import {isAdminSettingsCacheTwoLocationPopulated} from "../cachePopulatedCheckers/adminSettings";

export const ensureAdminSettingsCacheTwoLocation = (
  params: LoadAdminSettingsLocationParams & {
    locationId?: number;
  },
): boolean => {
  const {isEdit, locationId, isStateNavigated} = params;
  const rootState = reduxStoreService().getState();
  const sharedCacheTwoLocation = rootState.adminSettings.cacheTwoLocation;

  let requestedData = false;
  if (isEdit) {
    if (!isIdValid(locationId)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }

    if (isAdminSettingsCacheTwoLocationPopulated(sharedCacheTwoLocation)) {
      if (isStateNavigated) {
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      reduxStoreService().dispatch(GetLocation.request({
        ID: locationId,
      }));

      requestedData = true;
    }
  } else if (isStateNavigated) {
    reduxStoreService().dispatch(UpdateLocationSubmitActions.updateForm());
    reduxStoreService().dispatch(new SaveState());
  }

  return requestedData;
};