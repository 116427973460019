import { createSelector } from "reselect";
import { CMSResource, SiteEventCategory } from "../../../../../models/api/adminCMSCacheOne";
import type { ApplicationState } from "../../../..";
import { resourcesSelector } from "../../../../../store/AdminCMSSite/Resources/Home";

const selectedResourceIDSelector = (state: ApplicationState) => {
  if (state.adminCMSSite.menus && state.adminCMSSite.menus.siteMenuItem && state.adminCMSSite.menus.siteMenuItem.form) return state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.ResourceID;
  return -1;
};
const selectedPageIDSelector = (state: ApplicationState) => {
  if (state.adminCMSSite.menus && state.adminCMSSite.menus.siteMenuItem && state.adminCMSSite.menus.siteMenuItem.form) return state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.PageID;
  return -1;
};
const selectedEventCategoryIDSelector = (state: ApplicationState) => {
  if (state.adminCMSSite.menus && state.adminCMSSite.menus.siteMenuItem && state.adminCMSSite.menus.siteMenuItem.form) return state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.EventCategoryID;
  return -1;
};

export const siteEventCategoriesSelector = (state: ApplicationState) => {
  if (!state.adminCMSSite.cacheOne.SiteEventCategories) return [];

  return state.adminCMSSite.cacheOne.SiteEventCategories;
};

export const makeSelectedEventCategorySelector = () => {
  return createSelector(
    [siteEventCategoriesSelector, selectedEventCategoryIDSelector],
    (eventCategories: Array<SiteEventCategory>, categorId: number) => {
      if (categorId === -1 || !eventCategories || eventCategories.length === 0) return null;

      return eventCategories.find((c) => c.ID === categorId);
    },
  );
};

export const makeSelectedResourceSelector = () => {
  return createSelector(
    [resourcesSelector, selectedResourceIDSelector],
    (resources: Array<CMSResource>, resourceId: number) => {
      if (resourceId === -1 || !resources || resources.length === 0) return null;

      return resources.find((r) => r.ID === resourceId);
    },
  );
};