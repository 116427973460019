import * as React from 'react';
import Card from "../../Card";
import CardPadding from "../../Card/Padding";
import Text from "../../Text";
import Vignette from "../../Vignette";
import MonthRange from "../../Month/Range";
import StandardMonthRangeSkeleton from "../../Month/StandardSkeleton/Range";
import Row from "../../Grid/Row";
import Column from "../../Grid/Column";
import '../../../../styles/elements/facility/card/index.scss';
import moment from 'moment';
import {FacilityLocation} from "../../../../models/api/cacheOne";
import {
  Availability, FacilitiesAvailabilitiesDate, FacilitiesAvailability, FacilityImage,
  isEndUserFacilitiesAvailability,
} from "../../../../models/api/cacheTwoFacilties";
import {getImagePath} from "../../../../utils";
import {getLocationPricingInfo} from "../../../../utils/helpers/facilityHelper";
import { FacilitiesLocation } from '../../../../models/api/adminFacilitiesCacheOne';
import { AdminFacilitiesAvailability } from '../../../../models/api/adminFacilitiesAvailability';
import ActionButton from '../../ActionButton';
import { EditIcon, ArrowIcon } from '../../../../components/Icons';
import AdminBadge from '../../AdminBadge';
import { generateDOMId } from '../../../../utils/cypressHelper';

const namespace = (): string => "elements--facility--card";

interface Props {
  facilityLocation: FacilityLocation | FacilitiesLocation;
  name: string;
  skeleton?: boolean;
  availabilities?: Array<Availability>;
  availabilityDates?: Array<FacilitiesAvailabilitiesDate>;
  avail: FacilitiesAvailability | AdminFacilitiesAvailability;
  onClick?: () => void;
  startTime: moment.Moment;
  endTime: moment.Moment;
  nameSingular: string;
  image?: FacilityImage;
  hideImage?: boolean;
  color?: string;
  nameLayout?: 'vertical' | 'horizontal';
  onEdit?: () => void;
  showAdminBadge?: boolean;
  /**
   * This flag shows a right arrow icon indicates that the card is clickable
   */
  showArrowIcon?: boolean;
  wrapName?: boolean;
}

const FacilityCard: React.SFC<Props> = ({ avail, image, nameSingular, name, color, skeleton, availabilities, availabilityDates, nameLayout, onEdit, facilityLocation, startTime, endTime, showArrowIcon, onClick, showAdminBadge, hideImage, wrapName }: Props): React.ReactElement<Props> => {
  let perPersonRatePerDay = '';
  let flatRatePerDay = '';
  let minPeople = '';
  let maxPeople = '';
  let hasPricing = isEndUserFacilitiesAvailability(avail) && avail.Pricing !== null && avail.Pricing !== undefined;
  if (!skeleton && hasPricing && isEndUserFacilitiesAvailability(avail)) {
    const info = getLocationPricingInfo(avail, nameSingular);
    perPersonRatePerDay = info.perPersonRatePerDay;
    flatRatePerDay = info.flatRatePerDay;
    minPeople = info.minPeople;
    maxPeople = info.maxPeople;
  }

  return (
    <Card template="mobile-no-margin" padding="none" className={`${namespace()} ${skeleton ? 'skeleton' : ''}`}>
      <Row id={generateDOMId(`fac-card-${(avail as FacilitiesAvailability).FacilityID}`)} className={`${namespace()}--description ${skeleton ? '' : 'clickable'}`} onClick={onClick}>
        {!hideImage && <Vignette
          className={`${namespace()}--image`}
          width={180}
          minHeight={180}
          color={color}
          name={name}
          image={image ? getImagePath(image as any) : undefined}
          skeleton={skeleton}
          mobileFullWidth
          topLeftBorderRadius
        />}
        <Column expand verticalAlignment="top">
          <CardPadding style={wrapName ? {minWidth: 0} : {}} layout={nameLayout || 'vertical'}>
            <Row className={wrapName ? `${namespace()}--name--row` : ''} styles={{alignItems: 'center'}}>
              <Text className={`${namespace()}--name-text ${wrapName ? `${namespace()}--name` : ''}`} weight="medium" size={18} marginBottom={8}>{skeleton ? '' : name}</Text>
              {showAdminBadge && <AdminBadge className="admin-badge" marginLeft={8} />}
              {showArrowIcon && <div className={`${namespace()}--name--arrow`}><ArrowIcon /></div>}
            </Row>
            {(!!flatRatePerDay || !!maxPeople) && hasPricing && <div className={`${namespace()}--description--price-info`}>
              <Text weight="bold" color="green" size={16}>{flatRatePerDay}</Text>
              <Text color="gray" size={14}>{maxPeople}</Text>
            </div>}
            {hasPricing && <div className={`${namespace()}--description--price-info`}>
              <Text weight="bold" color="green" size={16}>{perPersonRatePerDay}</Text>
              <Text color="gray" size={14}>{minPeople}</Text>
            </div>}
            {onEdit && <ActionButton icon={EditIcon} onClick={onEdit} />}
          </CardPadding>
        </Column>
      </Row>
      <Row>
        {skeleton ? <StandardMonthRangeSkeleton/> : <MonthRange
          facilityLocation={facilityLocation}
          start={startTime.startOf('month')}
          end={endTime.startOf('month')}
          availabilities={availabilities}
          availabilityDates={availabilityDates}
        />}
      </Row>
    </Card>
  );
};

export default FacilityCard;
