import { UnselectGroup } from "./actions";
import { FacilityType, Options, CMSSite, ClassType } from "../../models/api/options";
import { APISuccess } from "../Validation/actionCreator";
import {
  ClearAllCache,
  ClearAllEndUserCacheButOptions,
  SetCacheAction,
} from "../App/actions";
import { listSelector, makeNoFilterSelector } from "../../utils/reselectHelper";
import { ApplicationState } from "..";
import { API_SUC } from "../../constants/request";
import { Reducer } from "redux";
import { Action, isActionType } from "../../utils/StrongActions";
import { RestoreGroupActions } from "../Admin/Modals/Accounts/actions";
import { ChangePasswordActions } from "../Settings/ChangePassword/actions";
import { cacheZeroKeys } from "./constants";
import { captureTentarooError } from "../../utils/dataHelper";

const facilityTypeSelector = (state) => {
  if (state.cacheZero.options && state.cacheZero.options.FacilityTypes)
    return state.cacheZero.options.FacilityTypes;
  return [];
};

export const GLAccountsSelector = listSelector((state: ApplicationState) =>
  state.cacheZero.options ? state.cacheZero.options.GLAccounts : []
);

export const groupsSelector = (state: ApplicationState) =>
  state.cacheZero.options ? state.cacheZero.options.Groups : [];

export const makeFacilityTypesSelector = makeNoFilterSelector(
  (f: FacilityType) => !f.Inactive,
  facilityTypeSelector
);
export const allMeritBadgesSelector = (state: ApplicationState) =>
  state.cacheZero.options?.MeritBadges || [];
// TODO: "Group" actually should exist in cache one instead of zero
export interface CacheZeroState {
  options?: Options;
}

export const sortByName = (a: { Name: string }, b: { Name: string }) => {
  return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase());
};

export const sortByOrd = (a: { Ord: number }, b: { Ord: number }) => {
  return a.Ord < b.Ord ? -1 : a.Ord === b.Ord ? 0 : 1;
};

export const makeSortByString = (stringName: string) => {
  return (a: any, b: any) => {
    return a[stringName]
      .toLowerCase()
      .localeCompare(b[stringName].toLowerCase());
  };
};

const cmsSitesSelector = (state: ApplicationState) => {
  if (state.cacheZero.options && state.cacheZero.options.CMSSites)
    return state.cacheZero.options.CMSSites;

  return [];
};
export const makeCMSSitesSelector = makeNoFilterSelector(
  (s: CMSSite) => !s.Inactive,
  cmsSitesSelector
);

const updateGroup = (newState, group, updateGroupOnlyWhenMatch?: boolean) => {
  if (!newState.options) {
    newState.options = {
      Group: { ...group },
    };
  } else {
    // Dont update group if deleting/restoring a different group than the one currently in cache OR no group in the cache
    const shouldUpdateGroup =
      !updateGroupOnlyWhenMatch ||
      (newState.options.Group && group.IDi === newState.options.Group.IDi);
    newState.options = {
      ...newState.options,
      Group: shouldUpdateGroup ? { ...group } : newState.options.Group,
    };
    if (newState.options.Groups) {
      newState.options.Groups = [...newState.options.Groups];
      const i = newState.options.Groups.findIndex((g) => g.IDi === group.IDi);
      if (i >= 0) {
        // preserve RowNum when updating
        newState.options.Groups[i] = {
          ...group,
          RowNum: newState.options.Groups[i].RowNum,
        };
      } else {
        newState.options.Groups.push(group);
      }
    }
  }
};

const updateLocation = (newState, location) => {
  newState.options = { ...newState.options };
  if (!newState.options.Locations) {
    newState.options.Locations = [location];
  } else {
    newState.options.Locations = [...newState.options.Locations];
    const i = newState.options.Locations.findIndex((l) => l.ID === location.ID);
    if (i >= 0) {
      newState.options.Locations[i] = { ...location };
    } else {
      newState.options.Locations.push(location);
    }
  }
};

const updateGLAccount = (newState, glAccount) => {
  newState.options = { ...newState.options };
  if (!newState.options.GLAccounts) {
    newState.options.GLAccounts = [glAccount];
  } else {
    newState.options.GLAccounts = [...newState.options.GLAccounts];
    const i = newState.options.GLAccounts.findIndex(
      (l) => l.ID === glAccount.ID
    );
    if (i >= 0) {
      newState.options.GLAccounts[i] = { ...glAccount };
    } else {
      newState.options.GLAccounts.push(glAccount);
    }
  }
};

const updateEventType = (newState, eventType) => {
  newState.options = { ...newState.options };
  if (!newState.options.EventTypes) {
    newState.options.EventTypes = [eventType.EventTypeRow];
  } else {
    newState.options.EventTypes = [...newState.options.EventTypes];
    const i = newState.options.EventTypes.findIndex(
      (et) => et.ID === eventType.EventTypeRow.ID
    );
    if (i >= 0) {
      newState.options.EventTypes[i] = { ...eventType.EventTypeRow };
    } else {
      newState.options.EventTypes.push(eventType.EventTypeRow);
    }
  }
};

const updateClassType = (newState, classType: ClassType) => {
  newState.options = { ...newState.options };

  if (!newState.options.ClassTypes) {
    newState.options.ClassTypes = [classType];
  } else {
    newState.options.ClassTypes = [...newState.options.ClassTypes];
    const i = newState.options.ClassTypes.findIndex(
      (et) => et.IDi === classType.IDi
    );
    if (i >= 0) {
      newState.options.ClassTypes[i] = { ...classType };
    } else {
      newState.options.ClassTypes.push(classType);
    }
  }
};

const CacheZero: Reducer<CacheZeroState> = (s: CacheZeroState, act: Action) => {
  const state = s;

  // @todo: I should delete this?
  if (act.type.startsWith(API_SUC) || isActionType(act, SetCacheAction)) {
    const action = <APISuccess>act;
    if (!action.response || !action.response.response) {
      return state;
    }
    const res = action.response.xhr.response;

    const newState = { ...state } as any;
    let hasNewCacheZeroKey = false;
    for (let k in res) {
      if (cacheZeroKeys[k]) {
        if (k === "MeritBadges" && Boolean(s.options?.MeritBadges)) {
          captureTentarooError(
            new Error(
              `Receiving MeritBadges data when it is already loaded, check parameter for this request: ${act.type}`
            )
          );
        }
        hasNewCacheZeroKey = true;
        if (!newState.options) newState.options = {};
        newState.options[k] = res[k];
      }
    }

    if (hasNewCacheZeroKey) {
      newState.options = { ...newState.options };

      if (res.Districts) {
        newState.options.Districts = newState.options.Districts;
      }
    }

    if (res.Location) {
      updateLocation(newState, res.Location);
    }
    if (res.FacilitiesLocation) {
      updateLocation(newState, res.FacilitiesLocation);
    }

    if (res.GLAccount) {
      updateGLAccount(newState, res.GLAccount);
    }

    if (res.Group) {
      updateGroup(
        newState,
        res.Group,
        act.type === RestoreGroupActions.successType
      );
    }

    if (res.EventsEventType) {
      updateEventType(newState, res.EventsEventType);
    }

    if (res.ClassType) {
      updateClassType(newState, res.ClassType);
    }

    // Clean up Login.IsTemporaryPassword when user successfully update his temp password
    if (
      action.type === ChangePasswordActions.successType &&
      newState.options?.Login?.IsTemporaryPassword
    ) {
      newState.options.Login.IsTemporaryPassword = false;

      //Make sure cache 0 state is updated. Currently, Group is in cache 0 so that will also cause
      //the state update in the condition below; however, we'll eventually move Group out of cache 0.
      hasNewCacheZeroKey = true;
    }

    if (
      hasNewCacheZeroKey ||
      res.Location ||
      res.FacilitiesLocation ||
      res.GLAccount ||
      res.Group ||
      res.EventsEventType ||
      res.ClassType
    ) {
      return newState;
    }
    return state;
  } else if (
    isActionType(act, UnselectGroup) ||
    isActionType(act, ClearAllEndUserCacheButOptions)
  ) {
    const action = <any>act;
    if (
      isActionType(action, ClearAllEndUserCacheButOptions) &&
      action.preserveGroup
    )
      return state;
    if (!state.options) {
      return state;
    }
    return { ...state, options: { ...state.options, Group: undefined } };
  } else if (isActionType(act, ClearAllCache)) {
    return { ...state, options: undefined };
  }

  return state || {};
};

export default CacheZero;
