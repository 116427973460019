import * as React from 'react';
import {
  Modal, ModalActions, Button, ModalContent, ModalHeader, Row, Column, Switch, RadioGroupOption, Text, DatePicker, TextField, MoneyField, PageLoader, Alert } from '../../../../../Elements';
import {withRouter, RouteComponentProps} from "react-router";
import {bindActionCreators} from "redux";
import {actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import {actionCreators as sharedActionCreators, GetRegisteredGroupsAction} from "../../../../../../store/AdminEvents/Shared/actions";
import {actionCreators as generateInvoicesActionCreators, namespace} from "../../../../../../store/AdminEvents/Events/Modals/GenerateInvoices/actions";
import {ApplicationState} from "../../../../../../store";
import '../../../../../../styles/pages/admin-events/events/modals/generate-invoices/index.scss';
import { makeMultipleSelectedGroupsSelector } from '../../../../../../store/AdminEvents/Events/Modals/GenerateInvoices';
import { ModalHeight } from '../../../../../Elements/Modal';
import Tag from '../../../../../Elements/Tag';
import { CloseCircleIcon } from '../../../../../Icons';
import { checkAdminEventGenerateInvoicesPermission } from '../../../../../../utils/helpers/adminEventsPageHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

type Props = WithInertAttribute<{}>

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

@(withRouter as any)
class GenerateInvoicesModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  componentDidMount() {
    if (shouldBlockActions()) return;

    this.props.actions.init();
  }

  componentWillUnmount() {
    this.onClose();
  }
  
  onExportPDF = (e?: any) => {
    if (e) e.stopPropagation();
    this.props.actions.submit(true);
  };

  onSendToClients = (e?: any) => {
    const {actions, cacheOne} = this.props;
    if (e) e.stopPropagation();

    checkAdminEventGenerateInvoicesPermission(
      () => {
        actions.submit();
      },
      cacheOne,
      true,
      true,
    );
  };

  onSelectGroups = () => {
    const {actions, cacheTwoEvent} = this.props;

    if (!cacheTwoEvent.EventsEvent) return;

    actions.pushMultipleGroupsModal({
      multipleGroupsContext: 'generate-invoices',
      selectedEventId: cacheTwoEvent.EventsEvent.IDi,
    });
  };

  onClose = () => {
    this.props.actions.resetGenerateInvoicesModal();
  };

  public render() {
    const {apiSaving, inert, modalState: {ActiveForm, ValidationRules, SubmitErrorMessage}, cacheTwoEvent, adminEventsShared, apiLoadingMap, selectedGroups, actions, apiLoading} = this.props;

    let subtitle = cacheTwoEvent.EventsEvent ? cacheTwoEvent.EventsEvent.Name : '';
    const loading = apiLoadingMap[GetRegisteredGroupsAction.requestType];

    return (
      <Modal inert={inert} height={ModalHeight.AUTO} expand onClose={this.onClose} className={`${namespace()}`} mobileFullScreen bodyScrollLock big higherZIndex>
        <ModalHeader className={`${namespace()}--header`} subtitle={subtitle}>Generate Invoices</ModalHeader>
        <ModalContent hasPadding>
          {loading && <PageLoader className={`${namespace()}--loader`} />}
          {!loading && <div>
            {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
            <div className={`${namespace()}--field-label`}>Recipient</div>
            <Row>
              <Column span={12}>
                <RadioGroupOption
                  value={!!ActiveForm.IsAllRegisteredGroups}
                  selected={!!ActiveForm.IsAllRegisteredGroups}
                  onChange={actions.updateValue}
                  ValidationRules={ValidationRules.IsAllRegisteredGroups}
                  rawValue={true}
                  newDesign
                  label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>All Registered Groups</Text>}
                />
              </Column>
            </Row>
            <Row>
              <Column span={12}>
                <RadioGroupOption
                  id={generateDOMId("specific-group-radio")}
                  value={!!!ActiveForm.IsAllRegisteredGroups}
                  selected={!!!ActiveForm.IsAllRegisteredGroups}
                  onChange={actions.updateValue}
                  ValidationRules={ValidationRules.IsAllRegisteredGroups}
                  rawValue={false}
                  newDesign
                  label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>Specific Groups</Text>}
                />
              </Column>
            </Row>
            {!ActiveForm.IsAllRegisteredGroups && selectedGroups && selectedGroups.length > 0 ? (
            <Row marginTop={8} marginLeft={36} className={`${namespace()}--tag-row`}>
              {selectedGroups.map((group) => {
                if (!group) return null;
                return (
                  <div className={`${namespace()}--tag-wrapper`}>
                    <Tag
                      className={`${namespace()}--tag`}
                      onRemove={() => this.props.actions.removeGroup(group.GroupIDi)}
                      removeIcon={<CloseCircleIcon />}
                      text={group.GroupName}
                      backgroundColor='orange'
                    />
                  </div>
                );
              })}
            </Row>
          ) : null}
            {!ActiveForm.IsAllRegisteredGroups && <Row marginLeft={36} marginTop={8} styles={{width: 'calc(100% - 36px)'}}>
              <Column style={{display: 'flex', flexDirection: 'column'}}>
                <Button id={generateDOMId("select-groups")} expand color="white" textColor="black" onClick={this.onSelectGroups}>SELECT</Button>
                {ValidationRules.SelectedGroups.errors && ValidationRules.SelectedGroups.errors.length > 0 ? <div className={`${namespace()}--select-groups--error`}>{ValidationRules.SelectedGroups.errors[0]}</div> : null}
              </Column>
            </Row>}
            <div style={{marginTop: '16px'}} className={`${namespace()}--field-label`}>Amount</div>
            <Row>
              <Column span={12}>
                <RadioGroupOption
                  value={!!ActiveForm.BillEntireBalance}
                  selected={!!ActiveForm.BillEntireBalance}
                  ValidationRules={ValidationRules.BillEntireBalance}
                  rawValue={true}
                  onChange={actions.updateValue}
                  newDesign
                  label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>Entire Remaining Balance</Text>}
                />
              </Column>
            </Row>
            <Row>
              <Column span={12}>
                <RadioGroupOption
                  value={!!!ActiveForm.BillEntireBalance}
                  selected={!!!ActiveForm.BillEntireBalance}
                  ValidationRules={ValidationRules.BillEntireBalance}
                  rawValue={false}
                  onChange={actions.updateValue}
                  newDesign
                  label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>Amount To Bill Per Youth</Text>}
                />
              </Column>
            </Row>
            {!ActiveForm.BillEntireBalance && <Row styles={{width: 'calc(100% - 36px)'}} marginLeft={36} marginTop={8}>
              <Column span={4}>
                <MoneyField
                  dollarSignPrefix
                  value={ActiveForm.BillAmountPerYouth}
                  validationRules={ValidationRules.BillAmountPerYouth}
                  onBlur={actions.updateValue}
                  onChange={actions.simpleUpdate}
                  pullDefaultValueFromRule
                  label='Amount'
                  placeHolder="$0.00"
                />
              </Column>
            </Row>}
            <Row marginTop={8}>
              <Column span={12}>
                <Switch
                  margin={false}
                  newDesign
                  label='Include Registrations With No Balance Due'
                  multilineLabel
                  value={!!ActiveForm.IncludeNoBalanceDue}
                  validationRules={ValidationRules.IncludeNoBalanceDue}
                  onChange={actions.updateValue}
                />
              </Column>
            </Row>
            <Row marginTop={16}>
              <Column span={6} mobileSpan={12} mobileMarginTop={12}>
                <DatePicker
                  label="Due Date"
                  value={ActiveForm.DueDate}
                  validationRules={ValidationRules.DueDate}
                  onSelect={actions.updateValue}
                  onChangeRaw={actions.simpleUpdate}
                  removeMax
                />
              </Column>
            </Row>
            <Row marginTop={8} marginBottom={16}>
              <Column span={12}>
                <TextField
                  label="Invoice Notes"
                  onChange={actions.simpleUpdate}
                  onBlur={actions.updateValue}
                  validationRules={ValidationRules.InvoiceNotes}
                  value={ActiveForm.InvoiceNotes}
                  rows={4}
                />
              </Column>
            </Row>
          </div>}
        </ModalContent>
        <ModalActions
          noPadding
          sticky
          loading={apiSaving > 0 || apiLoading > 0}
          stacked
          left={<Button expand flat textColor="black" onClick={this.onExportPDF}>EXPORT PDF</Button>}
          right={<Button flat textColor="black" onClick={this.onSendToClients}>SEND TO CLIENTS</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedGroupsSelector = makeMultipleSelectedGroupsSelector();
  
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    cacheOne: state.adminEvents.cacheOne,
    cacheTwoEvent: state.adminEvents.cacheTwoEvent,
    adminEventsShared: state.adminEvents.shared,
    modalState: state.adminEvents.events.modals.generateInvoices,
    selectedGroups: selectedGroupsSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...appActionCreators,
  ...generateInvoicesActionCreators,
  ...sharedActionCreators,
}, dispatch)});

const ConnectedGenerateInvoicesModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(GenerateInvoicesModal);

export default ConnectedGenerateInvoicesModal;
