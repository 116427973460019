import { ActionCreator, ApplicationState } from '../../../../index';
import { SaveState } from '../../../../Rollback/actions';
import { createValidateActions, createApiValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod, createApiSubmitActions, getCacheLevelExtra } from '../../../../../store/Validation/actionCreator';
import { ADMIN_EVENT_TYPES_SUFFIX } from '../../../CacheOne/actions';
import { getUpdateAdminEventTimeBlocksBody } from '../../../../../constants/adminEventsUrls';
import { convertTimeDigit } from '../../../../../utils/dateHelper';
import { ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX, UPDATE_EVENT_FORM_SUFFIX } from '../../../CacheTwoEvent/actions';
import { typeName, Action } from '../../../../../utils/StrongActions';

export const getTimeBlockAttrKey = (id: number, attrKey: string) => {
  return `TimeBlock${id}_${attrKey}`;
}; 

export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + '__TIME_BLOCKS_TAB' + ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX + UPDATE_EVENT_FORM_SUFFIX;

export const UpdateTimeBlocksActions = createApiSubmitActions(SUFFIX);

export type Actions = typeof actionCreators;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.events.event.classes.timeBlocksTab, true, undefined, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  submit: (): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    const cache = state.adminEvents.cacheTwoEvent.EventsEvent ? state.adminEvents.cacheTwoEvent.EventsEvent : null;
    const form = state.adminEvents.events.event.classes.timeBlocksTab;

    const body = getUpdateAdminEventTimeBlocksBody() as any;

    body.General = [];

    if (cache) {
      body.General.push({
        IsPrevious: true,
        NumberOfTimeBlocks: cache.TimeBlocks_Settings.NumberOfTimeBlocks,
      });
    }

    body.General.push({
      IsPrevious: false,
      NumberOfTimeBlocks: form.ActiveForm.NumberOfTimeBlocks,
    });

    body.TimeBlocks = [];

    if (cache) {
      cache.TimeBlocks_Settings.TimeBlocks.forEach((tb, index) => {
        if (form.ActiveForm.NumberOfTimeBlocks && index >= form.ActiveForm.NumberOfTimeBlocks) return;
        body.TimeBlocks.push({
          IsPrevious: true,
          ...tb,
        });

        const ShowTimeBlockKey = getTimeBlockAttrKey(tb.ID, 'ShowTimeBlock');
        const ShowStartTimeKey = getTimeBlockAttrKey(tb.ID, 'ShowStartTime');
        const StartHourKey = getTimeBlockAttrKey(tb.ID, 'StartHour');
        const StartMinKey = getTimeBlockAttrKey(tb.ID, 'StartMin');
        const StartPeriodKey = getTimeBlockAttrKey(tb.ID, 'StartPeriod');
        const EndHourKey = getTimeBlockAttrKey(tb.ID, 'EndHour');
        const EndMinKey = getTimeBlockAttrKey(tb.ID, 'EndMin');
        const EndPeriodKey = getTimeBlockAttrKey(tb.ID, 'EndPeriod');

        const StartHour = form.ActiveForm[StartHourKey];
        const StartMin = form.ActiveForm[StartMinKey];
        const StartPeriod = form.ActiveForm[StartPeriodKey];
        const EndHour = form.ActiveForm[EndHourKey];
        const EndMin = form.ActiveForm[EndMinKey];
        const EndPeriod = form.ActiveForm[EndPeriodKey];

        body.TimeBlocks.push({
          IsPrevious: false,
          ID: tb.ID,
          ShowTimeBlock: form.ActiveForm[ShowTimeBlockKey],
          ShowStartTime: form.ActiveForm[ShowStartTimeKey],
          StartTime: `${convertTimeDigit(StartHour, StartPeriod)}:${convertTimeDigit(StartMin)}:00`,
          EndTime: `${convertTimeDigit(EndHour, EndPeriod)}:${convertTimeDigit(EndMin)}:00`,
        });
      });
    }
    
    dispatch(new SaveState());
    dispatch(UpdateTimeBlocksActions.request(body, getCacheLevelExtra(false, true)));
  }
};