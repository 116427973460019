import * as React from 'react';
import '../../../../../../../styles/pages/events/event/register/numbers/spots.scss';
import { default as attribute, MarginProps } from '../../../../../../../styles';
import {Card, ActionButton, Row, Text, Column, NumberField, TextField, MoneyField} from '../../../../../../Elements';
import { CloseIcon } from '../../../../../../../components/Icons';
import Button from '../../../../../../Elements/Button';
import CheckMark from '../../../../../../../images/pages/events/event/registration/checkmark.svg';
import { CardCategory } from '../../../../../../Elements/Card';
import { EventRegistrationParticipantType } from '../../../../../../../models/api/cacheFourEvents';
import { standardCurrencyFormat } from '../../../../../../../utils/classesHelper';
import { getParticipantTypeKey, makeSpotFilter } from '../../../../../../../store/Events/Event/Register/Numbers/Spots/validation';
import * as NM from "../../../../../../../constants/messages/numbersWizard";

export const namespace = (): string => 'elements--register-numbers--spots--card';

export enum SpotsCardType {
  SPOTS_ONLY='spots',
  SPOTS_AND_AMOUNT='spots-and-amount',
}

export interface SpotItem {
  Name: string;
  Available: number;

}

interface Props extends MarginProps {
  children?: React.ReactNode;
  type: SpotsCardType;
  cardCategory?: CardCategory;
  pType: EventRegistrationParticipantType;
  available?: number;
  MaxParticipants: number;
  showCheck: boolean;
  ParticipantTypeForm: {
    ActiveForm: any;
    ValidationRules: any
  };
  SpotForms: {
    ActiveForm: any;
    ValidationRules: any
  }[];
  onSpotsSimpleUpdate?: (value, vObj) => void;
  onSpotsChange: (value, vObj) => void;
  onAddSpot?: (PTID: number, PTIsYouth: boolean) => void;
  showAvailable: boolean;
  spotError?: string;
}

class RegisterNumbersSpotCard extends React.Component<Props, {}> {

  getSpotsAvailableText = (available: number) => {
    if (available < 0) return 'FULL';
    return `${available} spot${available !== 1 ? 's' : ''} available`;
  };

  onSpotsChange = (value) => {
    const {pType, type, ParticipantTypeForm} = this.props;

    const k = getParticipantTypeKey(pType);

    this.props.onSpotsChange(value, ParticipantTypeForm.ValidationRules[k]);
  };

  showError = (errors?: Array<string>) => {
    if (!errors || errors.length === 0) return null;
    return <Text size={12} weight="regular" color="red" className={`${namespace()}--fullWidth ${namespace()}--spots--error`}>
      {errors[0]}
    </Text>;
  };

  renderSpotsOnlyContent = () => {
    const {type, ParticipantTypeForm, showCheck, available, MaxParticipants, pType} = this.props;
    const k = getParticipantTypeKey(pType);

    // @todo - always check both maximum (800) and minimum (NumAdded) for participant type
    return [
      <Row key={`${pType.ID}_row3`}>
        <Column span={6} mobileSpan={12}>
          <NumberField
            label="# Spots"
            value={ParticipantTypeForm.ActiveForm[k]}
            validationRules={ParticipantTypeForm.ValidationRules[k]}
            maximum={800}
            minimum={pType.NumAdded}
            onChange={this.onSpotsChange}
            noWidthLimit
            noNegative
          />
        </Column>
      </Row>
    ];
  };

  renderSpotsAndAmountContent = () => {
    const {type, SpotForms, onAddSpot, showCheck, available, MaxParticipants, onSpotsChange, onSpotsSimpleUpdate, pType} = this.props;

    const rows = SpotForms
      .filter(makeSpotFilter(true))
      .map((spot, index) => {
      return (
        <Row className={`${namespace()}--spot--row`} key={`${pType.ID}_spot_${index}`}>
          <Column className={`${namespace()}--spot--col`}>
            <TextField
              label="# Spots"
              onChange={onSpotsChange}
              onBlur={onSpotsChange}
              validationRules={spot.ValidationRules.NumSpots}
              value={spot.ActiveForm.NumSpots}
              hideOptional
            />
          </Column>
          <Column className={`${namespace()}--spot--col`}>
            <MoneyField
              dollarSignPrefix
              label="Amount"
              onChange={onSpotsSimpleUpdate}
              onBlur={onSpotsChange}
              validationRules={spot.ValidationRules.Amount}
              value={spot.ActiveForm.Amount}
              hideOptional
            />
          </Column>
          <div className={`${namespace()}--spot--remove`}>
            <ActionButton
              icon={CloseIcon}
              onClick={() => onSpotsChange(true, spot.ValidationRules.Inactive)}
            />
          </div>
        </Row>
      );
    });
    return [
      ...rows,
      <Row key={`${pType.ID}_new_btn`}>
        <Button onClick={() => onAddSpot && onAddSpot(pType.ID, pType.IsYouth)} color="white" textColor="black">NEW</Button>
      </Row>
    ];
  };

  render() {
    const {type, showCheck, available, MaxParticipants, spotError, pType} = this.props;
    let className = attribute(namespace(), undefined, this.props);
    className += ` ${type}`;

    if (spotError) className += ' error';

    return (
      <Card
        className={className}
        component={<section/>}
        padding="small"
      >
        <div className={`${namespace()}--main`}>
          <Row marginBottom={4} className={`${namespace()}--main--row1`}>
            <Column expand>
              <Text weight="medium" color="black-87" size={18}>{pType.Name}</Text>
            </Column>
            <Column mobileMarginTop={8} mobileSpan={12} className={`${namespace()}--main--row1--col2`}>
              <Text weight="medium" color="black-87" size={18}>
                {standardCurrencyFormat(pType.CurrentAmount)}
              </Text>
              <div className={`${namespace()}--main--upfront mobile`}>
                <Text color="gray" size={14}>
                  {`${standardCurrencyFormat(pType.AmountMin)} Upfront`}
                </Text>
              </div>
            </Column>
          </Row>
          <Row marginBottom={16} className={`${namespace()}--main--row2`}>
            {MaxParticipants !== 0 && available !== undefined && <div className={`${namespace()}--main--available`}>
              <Text color="gray" size={14}>
                {this.getSpotsAvailableText(available)}
              </Text>
            </div>}
            {showCheck && <div className={`${namespace()}--main--registered`}>
              <Text icon={CheckMark} weight="medium" color="green" size={14} className={`${namespace()}--spots--column-1--registered`}>
                {`${pType.NumAdded} participant${pType.NumAdded !== 1 ? 's' : ''} registered`}
              </Text>
            </div>}
            <div className={`${namespace()}--main--upfront desktop`}>
              <Text color="gray" size={14}>
                {`${standardCurrencyFormat(pType.AmountMin)} Upfront`}
              </Text>
            </div>
          </Row>
          {type === SpotsCardType.SPOTS_ONLY ? this.renderSpotsOnlyContent() : null}
          {type === SpotsCardType.SPOTS_AND_AMOUNT ? this.renderSpotsAndAmountContent() : null}
          {type === SpotsCardType.SPOTS_AND_AMOUNT && spotError && <Text size={12} weight="regular" className={`${namespace()}--spot-error`} color="red">{spotError}</Text>}
        </div>
      </Card>
    );
  }
}

export default RegisterNumbersSpotCard;