import * as React from 'react';
import {default as AppHeader} from '../../../../../App/Header';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { getPagesRootUrl } from '../../../../../../constants/urls';
import { bindActionCreators } from 'redux';
import {
    actionCreators as appActionCreators
} from '../../../../../../store/App/actions';
import {
    actionCreators as cacheTwoContactsActionCreators
} from '../../../../../../store/AdminCMSSite/CacheTwoContacts/actions';
import {
    actionCreators as rollbackActionCreators
} from '../../../../../../store/Rollback/actions';
import { ApplicationState } from '../../../../../../store';
import { actionCreators as cacheTwoPagesActionCreators } from '../../../../../../store/AdminCMSSite/CacheTwoPages/actions';
import { actionCreators as pageFormActionCreators } from '../../../../../../store/AdminCMSSite/Pages/Page/Form/actions';
import { extractRouteParams } from '../../../../../../utils/urlHelper';
import ContextMenuComponent from './ContextMenu';
import { ActionButton } from '../../../../../../components/Elements';
import { OptionsIcon } from '../../../../../../components/Icons';
import { copyStringToClipboard } from '../../../../../../utils';
import { getPageUrl, checkPagePermission, getPageIdFromPath, IAdminCMSPageRouterParams } from '../../../../../../utils/helpers/adminCMSPageHelper';
import '../../../../../../styles/pages/cms-pages/edit-page/header.scss';
import { EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE } from '../../../../../../constants/messages/adminCMS';
import { LINK_COPIED } from '../../../../../../constants/messages/generic';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';

const namespace = () => 'pages--cms--page--header';
type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminCMSPageRouterParams, {}>;

const Header = (props: ConnectedProps): React.ReactElement<ConnectedProps> => {


  const pageId = getPageIdFromPath(props.params);
  const customRefresh = () => {
    // during editing, should always save state before a refresh
    props.actions.saveState();
    if (props.pageForm.showAddSiteMenuSidebar) {
      props.actions.toggleAddMenuSidebar(false);
    }
    if (props.pageForm.editSiteMenuId && props.pageForm.editSiteMenuId > 0) {
      props.actions.toggleEditMenuSidebar(-1);
    }
    props.actions.refreshPage({
      ID: pageId,
      GetContacts: true,
      GetResources: true,
      GetResourceCategories: true,
      GetSiteMenus: true,
      ...extractRouteParams(props),
    });
    
  };

  const onDeletePageClick = () => {
    const {pageForm, router, routes, actions} = props;
    if (pageForm.processingImages) {
      actions.showTopFloatingAlert(EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('deleting this page'), undefined, 'orange');
    }
    
    checkPagePermission(
      () => {
        actions.deletePage(pageForm.ActiveForm.ID, router, routes);
      },
      props.adminCMSCacheOne,
      undefined,
      props.adminCMSCacheTwoPage,
    );
  };

  const onVisitPageClick = () => {
    const {adminCMSCacheTwoPage, adminCMSCacheOne} = props;
    if (!adminCMSCacheTwoPage.CMSPage) return;
    const url = getPageUrl(adminCMSCacheTwoPage.CMSPage, adminCMSCacheOne);
    window.open(url);

  };

  const onCopyLinkClick = () => {
    const {adminCMSCacheTwoPage, adminCMSCacheOne, actions} = props;
    if (!adminCMSCacheTwoPage.CMSPage) return;
    const url = getPageUrl(adminCMSCacheTwoPage.CMSPage, adminCMSCacheOne);
    copyStringToClipboard(url);
    actions.showSnackbarItem(LINK_COPIED);

  };

  const onDuplicateClick = () => {
    const {pageForm, actions, adminCMSCacheTwoPage} = props;
    if (pageForm.processingImages) {
      actions.showTopFloatingAlert(EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('duplicating this page'), undefined, 'orange');
    }
    if (!adminCMSCacheTwoPage.CMSPage) return;
    
    checkPagePermission(
      () => {
        actions.pushDuplicatePageModal();
      },
      props.adminCMSCacheOne,
      undefined,
      props.adminCMSCacheTwoPage,
    );
  };
  // fall back to root
  const backUrl = props.CMSSite ? getPagesRootUrl({
    siteId: props.CMSSite.ID,
    domain: props.CMSSite.SiteDomain
  }) : '/admin2';
  const page = props.adminCMSCacheTwoPage && props.adminCMSCacheTwoPage.CMSPage ? props.adminCMSCacheTwoPage.CMSPage : (props.adminCMSCacheOne.CMSPages ? props.adminCMSCacheOne.CMSPages.find((p) => p.ID === pageId) : null);

  return <AppHeader
    showDrawer
    back={backUrl}
    disableBack={!props.CMSSite}
    subTitle={page ? page.Name : ''}
    title={'Edit Page'}
    customRefresh={customRefresh}
    refresh={true}
    optionsControl={<ActionButton className={`${namespace()}--options`}
      icon={OptionsIcon}
      contextMenu={<ContextMenuComponent
        onDelete={onDeletePageClick}
        onVisitPage={onVisitPageClick}
        onCopyLink={onCopyLinkClick}
        onDuplicate={onDuplicateClick} />}
    />}
  />;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    CMSSite: state.adminCMSSite.cacheOne.CMSSite,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    adminCMSCacheTwoPage: state.adminCMSSite.cacheTwoPage,
    cacheZero: state.cacheZero,
    pageForm: state.adminCMSSite.pages.page.form,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...cacheTwoContactsActionCreators,
    ...rollbackActionCreators,
    ...cacheTwoPagesActionCreators,
    ...appActionCreators,
    ...pageFormActionCreators,
  }, dispatch),
});

const ConnectedHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<ConnectedProps & WithRouterProps>(),
)(Header);

export default withRouter<{}>(ConnectedHeader);
