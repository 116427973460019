import {GetOptions, GetLocations} from './actions';
import { getOptions, getOptionsBody, getLocations, getLocationsBody } from '../../constants/urls';

import {makeRequestEpic} from "../Validation/epicCreator";

export const cacheZeroEpic = makeRequestEpic(
  GetOptions,
  getOptions,
  getOptionsBody,
  true
);

export const cacheZeroLocationsEpic = makeRequestEpic(
  GetLocations,
  getLocations,
  getLocationsBody,
  true,
);