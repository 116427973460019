import {
  EmptyMessage,
  MeritBadgeCard,
  Modal,
  ModalContent,
  ModalHeader,
  Row,
  VirtualList,
  VirtualListWrapper,
} from "../../../../../../components/Elements";
import { ApplicationState } from "../../../../../../store";
import { actionCreators } from "../../../../../../store/AdminEvents/Events/Modals/SelectMeritBadge/actions";
import { actionCreators as appActionCreators } from "../../../../../../store/App/actions";
import { actionCreators as classTypeFormActionCreators } from "../../../../../../store/AdminEvents/ClassTypes/Form/actions";
import { getMergeProps } from "../../../../../../utils/reduxHelper";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeFilteredMeritBadgesSelector } from "../../../../../../store/AdminEvents/Events/Modals/SelectMeritBadge";
import { ClassTypesIcon } from "../../../../../../components/Icons";
import { EmptyMessageType } from "../../../../../../components/Elements/EmptyMessage";
import { ENTITY_NOT_FOUND } from "../../../../../../constants/messages/adminCMS";
import { MERIT_BADGES } from "../../../../../../constants/messages/adminEvents";
import { MeritBadgeCardType } from "../../../../../Elements/MeritBadge/Card";
import { CardCategory } from "../../../../../Elements/Card";
import { MeritBadge } from "../../../../../../models/api/options";
import { ModalTypes } from "../../../../../../utils/modalHelper";
import { AdminEventClassTypeFormDefinition } from "../../../../../../store/AdminEvents/ClassTypes/Form/validation";
import { GetAdminEventClassTypeAllRequirements } from "../../../../../../store/AdminEvents/CacheThreeClassType/actions";
import { reduxStoreService } from "../../../../../../store/service";
import {WithInertAttribute} from "../../../../../Elements/WithInert";

const namespace = () => "pages--events--modal--select-merit-badge-modal";

type ConnectedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type Props = WithInertAttribute<{}>

class SelectMeritBadgeModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  private modalContentRef;
  private _listWrapper;

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    if (this.props.modalState.filterString) {
      this.scrollToTop();
    }
    this.props.actions.toggleFilter(false);
    this.props.actions.filter("");
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };

  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filter(val);
  };

  renderEmptyMessage = () => {
    return (
      <EmptyMessage
        icon={ClassTypesIcon}
        admin
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight="96px"
        iconWidth="96px"
        fixedFontSize
        description={ENTITY_NOT_FOUND(MERIT_BADGES)}
      />
    );
  };

  onSelect = (meritBadge: MeritBadge) => {
    this.props.actions.popModal(true, false, ModalTypes.SELECT_MERIT_BADGE);
    this.props.classTypeFormActions.updateValue(meritBadge.ID, AdminEventClassTypeFormDefinition.mbID);
    this.props.classTypeFormActions.updateValue([], AdminEventClassTypeFormDefinition.RequirementsArray);

    // NOTE: This action should have no impact to forms
    reduxStoreService().dispatch(GetAdminEventClassTypeAllRequirements.request({mbID: meritBadge.ID}, {skipUpdateForm: true}));
  };

  rowRenderer = ({index}) => {
    const { filteredMeritBadges } = this.props;
    return (
      <MeritBadgeCard
        type={MeritBadgeCardType.SELECT_SINGLE}
        cardCategory={CardCategory.LIST_MOBILE}
        noBoxShadow
        meritBadge={filteredMeritBadges[index]}
        onClick={this.onSelect}
      />
    );
  };

  render() {
    const {
      modalState: { isTextSearching },
      filteredMeritBadges,
      inert,
    } = this.props;
    const title = "Select Merit Badge";
    return (
      <Modal
        inert={inert}
        className={`${namespace()}`}
        mobileFullScreen
        bodyScrollLock
        big
        higherZIndex
      >
        <ModalHeader
          isSearching={isTextSearching}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={this.onSearchChange}
          onSearchBack={this.onSearchBack}
          className={`${namespace()}--header`}
        >
          {title}
        </ModalHeader>
        <ModalContent
          refCallback={(ref) => (this.modalContentRef = ref)}
          paddingLeft={0}
          paddingRight={0}
          paddingTop={0}
          paddingBottom={0}
        >
          <VirtualListWrapper forwardRef={(ref) => this._listWrapper = ref}>
            {filteredMeritBadges && filteredMeritBadges.length > 0 && <VirtualList
              rowCount={filteredMeritBadges.length}
              scrollElement={this._listWrapper}
              rowRenderer={this.rowRenderer}
              list={filteredMeritBadges}
              rowHeight={64}
              mobileRowHeight={64}
            />}
            {filteredMeritBadges.length === 0 && this.renderEmptyMessage()}
          </VirtualListWrapper>
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredMeritBadgesSelector = makeFilteredMeritBadgesSelector();
  return {
    modalState: state.adminEvents.events.modals.selectMeritBadge,
    classTypeForm: state.adminEvents.classTypes.form,
    filteredMeritBadges: filteredMeritBadgesSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...actionCreators,
      ...appActionCreators,
    },
    dispatch
  ),
  classTypeFormActions: bindActionCreators(
    {
      ...classTypeFormActionCreators,
    },
    dispatch
  ),
});

const ConnectedSelectMeritBadgeModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>()
)(SelectMeritBadgeModal);

export default ConnectedSelectMeritBadgeModal;
