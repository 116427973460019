import {APISuccess, WithRootState} from "../Validation/actionCreator";
import {ClearAllCache, ClearAllEndUserCacheButOptions} from "../App/actions";
import {ClearCacheBelowOne} from "../CacheOne/actions";
import {EventClassIndividualAvailable, RegisteredClass} from "../../models/class";
import {ClearCacheBelowTwoEvents} from "../CacheTwoEvents/actions";
import {ClearCacheBelowThreeEvents} from "../CacheThreeEvents/actions";
import {EventRegistrationPaymentStatus} from "../../models/api/cacheThreeEvents";
import {
  EventNotRegisteredPerson,
  EventRegistrationGroupClass,
  EventRegistrationParticipantsParticipantType,
  EventRegistrationPerson,
  EventRegistrationPersonCustomField
} from "../../models/api/cacheFourEvents";
import {convertObjToMoment} from "../../utils/dateHelper";
import {ClearCacheFourEventParticipants, EventParticipantCalc, EventParticipantsGetPerson, UnselectParticipant} from './actions';
import {FullGroupRosterPerson} from '../../models/api/cacheTwoRoster';
import {
  ApiDeleteRosterActions,
  ApiRosterSubmitActions as RosterApiSubmitActions,
  EventRegisterParticipantRosterSelectTab
} from "../Events/Event/Register/Participant/Roster/actions";
import {
  ApiTypeSubmitActions, EventRegisterParticipantSelectType,
} from "../Events/Event/Register/Participant/Type/actions";
import {
  ApiSubmitActionsParticipantsClasses,
  EventRegisterParticipantsClassesAdd,
  EventRegisterParticipantsClassesRemove
} from "../Events/Event/Register/Participant/Classes/actions";
import {classSort, determineConflict} from "../../utils/classesHelper";
import {AddUnscheduledAdd} from "../Events/Event/Modals/ClassAddUnscheduled/actions";
import {ClassOverrideFeeSave} from "../Events/Event/Modals/ClassOverrideFee/actions";
import {ApiSubmitActions} from "../Events/Event/Modals/RegisterOverrideFee/actions";
import {EventRegisterParticipantsClearWarning} from "../Events/Event/Register/Participant/Classes/actions";
import { captureTentarooError } from '../../utils/dataHelper';
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../utils/StrongActions";

export interface CacheFourEventsParticipantsCore {
  EventRegistrationParticipantTypes?: Array<EventRegistrationParticipantsParticipantType> | null;
  EventRegistrationPaymentStatus?: EventRegistrationPaymentStatus | null;
  EventRegistrationGroupClasses?: Array<EventRegistrationGroupClass> | null;
  EventRegistrationPerson?: EventRegistrationPerson | null;
  EventRegistrationPersonCustomFields?: Array<EventRegistrationPersonCustomField> | null;
  EventClassesIndividualAvailable: Array<EventClassIndividualAvailable>;
  EventClassesIndividualRegistered: Array<RegisteredClass>;
  GroupRosterPerson?: FullGroupRosterPerson | null;
  EventRegistrationPeopleNotRegisteredAdults?: Array<EventNotRegisteredPerson> | null;
  EventRegistrationPeopleNotRegisteredYouth?: Array<EventNotRegisteredPerson> | null;
}

export interface CacheFourEventsParticipantsState extends CacheFourEventsParticipantsCore {
  isYouth?: boolean;
  lastIsYouth?: boolean;
  dispatchWarning?: string;
}

const setCacheFourData = (data: CacheFourEventsParticipantsCore): CacheFourEventsParticipantsState => {
  return {
    EventRegistrationParticipantTypes: data ? data.EventRegistrationParticipantTypes : null,
    EventRegistrationPaymentStatus: convertObjToMoment(data.EventRegistrationPaymentStatus, ['DepositDueDate']),
    EventRegistrationGroupClasses: data.EventRegistrationGroupClasses,
    EventRegistrationPerson: convertObjToMoment(data.EventRegistrationPerson, ['DateAdded']),
    EventRegistrationPersonCustomFields: data.EventRegistrationPersonCustomFields,
    EventClassesIndividualAvailable: data.EventClassesIndividualAvailable ? data.EventClassesIndividualAvailable : [],
    EventClassesIndividualRegistered: data.EventClassesIndividualRegistered ? data.EventClassesIndividualRegistered : [],
    GroupRosterPerson: convertObjToMoment(data.GroupRosterPerson, ['DOB']),
    EventRegistrationPeopleNotRegisteredAdults: data.EventRegistrationPeopleNotRegisteredAdults,
    EventRegistrationPeopleNotRegisteredYouth: data.EventRegistrationPeopleNotRegisteredYouth
  };
};

const updateCacheFourData = (newState: CacheFourEventsParticipantsState, data: any): CacheFourEventsParticipantsState => {
  if (data.EventRegistrationPaymentStatus) {
    newState.EventRegistrationPaymentStatus = convertObjToMoment(data.EventRegistrationPaymentStatus, ['DepositDueDate']);
  }
  if (data.EventRegistrationIndividualClasses && newState.EventClassesIndividualRegistered) {
    newState.EventClassesIndividualRegistered = [...newState.EventClassesIndividualRegistered];
    data.EventRegistrationIndividualClasses.forEach((c: EventRegistrationGroupClass) => {
      const index = newState.EventClassesIndividualRegistered.findIndex((cr: RegisteredClass) => {
        return cr.ClassIDi === c.ClassIDi && !!cr.Inactive === !!c.Inactive;
      });
      if (index > -1) {
        newState.EventClassesIndividualRegistered[index] = {
          ...newState.EventClassesIndividualRegistered[index],
          AmountChangeInCart: c.AmountChangeInCart
        };
      }
    });
  }
  if (data.EventRegistrationParticipantTypes) {
    newState.EventRegistrationParticipantTypes = [...data.EventRegistrationParticipantTypes];
  }
  if (data.EventRegistrationPerson) {
    newState.EventRegistrationPerson = {...data.EventRegistrationPerson};
  }
  return {...newState};
};

const getInitialState = () => ({
  EventClassesIndividualAvailable: [],
  EventClassesIndividualRegistered: []
});

const CacheFourEventsParticipants: Reducer<CacheFourEventsParticipantsState> = (state: CacheFourEventsParticipantsState, act: WithRootState<AnyAction>) => {
  if (act.type === EventParticipantCalc.successType) { // initial load
    const action = <WithRootState<APISuccess>> act;
    const data = action.response.response;
    const nState = setCacheFourData(data);
    const newState = updateCacheFourData(nState, data);
    
    // When data is invalid (i.e. we could put more validation logic here), mark warning
    // in state tree
    if (!data) {
      newState.dispatchWarning = "Invalid response when retrieving event paticipants";
    }
    if (!newState.EventClassesIndividualAvailable) newState.EventClassesIndividualAvailable = [];
    if (!newState.EventClassesIndividualRegistered) newState.EventClassesIndividualRegistered = [];
    // set conflictStatus
    const availableClasses: Array<EventClassIndividualAvailable> = [];
    newState.EventClassesIndividualAvailable.forEach((ca: EventClassIndividualAvailable) => {
      const rootState = act.rootState;
      if (rootState.cacheTwoEvents.EventTypeRegistrationSettings) {
        const {conflictStatus} = determineConflict(
          ca,
          newState.EventClassesIndividualRegistered,
          rootState.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.RequireUniqueClassType,
        );
        availableClasses.push({...ca, conflictStatus});
      }
    });
    newState.EventClassesIndividualAvailable = availableClasses;
    if (newState.GroupRosterPerson) {
      newState.isYouth = newState.GroupRosterPerson.IsYouth;
    }
    return newState;
  } else if (isActionType(act, AddUnscheduledAdd)) {
    const newState = {...state};
    newState.EventClassesIndividualAvailable = state.EventClassesIndividualAvailable ? [...state.EventClassesIndividualAvailable] : [];
    newState.EventClassesIndividualRegistered = state.EventClassesIndividualRegistered ? [...state.EventClassesIndividualRegistered] : [];
    const index = state.EventClassesIndividualRegistered.findIndex((r) => r.ClassTypeIDi === act.clazz.IDi && r.FirstTimeBlock === 500);
    if (index > -1) {
      const newClass = {...state.EventClassesIndividualRegistered[index], Inactive: false};
      newState.EventClassesIndividualRegistered[index] = newClass;
    } else {
      const newClass: RegisteredClass = {
        IDi: 0,
        OldIDi: 0,
        ParentIDi: 0,
        FirstTimeBlock: 500,
        StartTime: '',
        ShowTimeBlock: true,
        ShowStartTime: false,
        ClassIDi: 0,
        ClassTypeIDi: act.clazz.IDi,
        IsUnscheduled: true,
        InCart: 1,
        Amount: 0,
        AmountEstimated: 0,
        AmountMin: 0,
        AmountChangeInCart: 0,
        AmountPaid: 0,
        AmountInCart: 0,
        MinimumAge: '',
        Days: [],
        Maximum: 1000,
        RegistrationRank: 0,
        MaxRegistrationRank: 10000000,
        NumSpotsAvailable: 0,
        AllTimeBlocksDisplay: "-",
        DaysDisplay: "",
        IsAllWeek: true,
        FirstClassDay: 0,
        Inactive: false,
        AllowSelfRegistration: true,
        IsBaseFee: false,
        JustYouth: -1,
        NumOverride: 0, // was '0' in old app;
        Location: '',
        Name: act.clazz.Name,
        mbID: act.clazz.mbID,
        ParticipantTypes: [],
      };
      newState.EventClassesIndividualRegistered.push(newClass);
    }

    return newState;
  } else if (isActionType(act, ClassOverrideFeeSave)) {
    if (state.EventClassesIndividualRegistered.length === 0) return state;
    const newState = {...state};
    newState.EventClassesIndividualRegistered = state.EventClassesIndividualRegistered ? [...state.EventClassesIndividualRegistered] : [];
    const updatingIndex = newState.EventClassesIndividualRegistered.findIndex((c: RegisteredClass) =>
      c.ClassIDi === act.c.ClassIDi && c.ClassTypeIDi === act.c.ClassTypeIDi && !c.Inactive && c.FirstTimeBlock === act.c.FirstTimeBlock);
    if (updatingIndex === -1) {
      // console.log(`Cannot override class with ClassIDi ${act.c.ClassIDi}`);
      return state;
    }
    newState.EventClassesIndividualRegistered[updatingIndex] = {...state.EventClassesIndividualRegistered[updatingIndex]};
    newState.EventClassesIndividualRegistered[updatingIndex].Amount = act.Amount;
    if (act.Amount < 0) {
      newState.EventClassesIndividualRegistered[updatingIndex].AmountMin = act.Amount;
    }
    newState.EventClassesIndividualRegistered.sort(classSort);
    return newState;
  } else if (act.type === ApiTypeSubmitActions.successType || isActionType(act, EventRegisterParticipantSelectType)) {
    const action = act as WithRootState<APISuccess>;
    const nState = {...state};
    let newState;
    let newPType;
    if (act.type === ApiTypeSubmitActions.successType) {
      newPType = action.response.response.EventRegistrationPerson.ParticipantTypeID as number;
      newState = updateCacheFourData(nState, action.response.response);
      newState.EventClassesIndividualRegistered = [...state.EventClassesIndividualRegistered];
    } else if (isActionType(act, EventRegisterParticipantSelectType)) {
      newPType = act.pTypeID;
      newState = nState;
    }

    // todo: could probably do this only on EventRegisterParticipantSelectType now.
    // remove any registered classes where the pType no longer matches
    const indexesToRemove: Array<number> = [];
    for (let i = 0; i < state.EventClassesIndividualRegistered.length; i++) {
      const clazz = state.EventClassesIndividualRegistered[i];
      if (clazz.ParticipantTypes) {
        const found = clazz.ParticipantTypes.find((pt) => pt.ID === newPType);
        if (!found) {
          indexesToRemove.push(i);
        }
      }
    }

    for (let i = indexesToRemove.length - 1; i >= 0; i--) {
      newState.EventClassesIndividualRegistered.splice(indexesToRemove[i], 1);
    }

    const rootState = act.rootState;
    state.EventClassesIndividualAvailable.forEach((avail: EventClassIndividualAvailable, index: number) => {

      if (!rootState.cacheTwoEvents.EventTypeRegistrationSettings) {
        captureTentarooError(new Error("cacheTwoEvents.EventTypeRegistrationSettings not available when handling CacheFourParticipants Submit/Select Type action"));
        return;
      }
      const {conflictStatus} = determineConflict(
        avail,
        newState.EventClassesIndividualRegistered,
        rootState.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.RequireUniqueClassType,
      );

      newState.EventClassesIndividualAvailable[index] = {
        ...state.EventClassesIndividualAvailable[index],
        conflictStatus,
      };
    });

    return newState;
  } else if (isActionType(act, EventRegisterParticipantsClassesAdd)) {
    const newState = {...state};
    newState.EventClassesIndividualAvailable = state.EventClassesIndividualAvailable ? [...state.EventClassesIndividualAvailable] : [];
    newState.EventClassesIndividualRegistered = state.EventClassesIndividualRegistered ? [...state.EventClassesIndividualRegistered] : [];
    const addingIndex = state.EventClassesIndividualAvailable.findIndex((c: EventClassIndividualAvailable) => c.IDi === act.clazz.IDi);
    if (addingIndex === -1) {
      captureTentarooError(new Error(`Could not add class with IDi ${act.clazz.IDi}`));
      return state;
    }
    const addingClass = {...state.EventClassesIndividualAvailable[addingIndex]};
    const addingClasses: Array<EventClassIndividualAvailable> = [{...addingClass}];
    const addingIndexes: Array<number> = [addingIndex];
    if (addingClass.ParentIDi !== 0) {
      state.EventClassesIndividualAvailable.forEach((avail: EventClassIndividualAvailable, index: number) => {
        if (addingClass.IDi !== avail.IDi && addingClass.ParentIDi === avail.ParentIDi) {
          addingClasses.push(avail);
          addingIndexes.push(index);
        }
      });
    }
    const rootState = act.rootState;

    if (!rootState.cacheTwoEvents.EventTypeRegistrationSettings) {
      captureTentarooError(new Error("cacheTwoEvents.EventTypeRegistrationSettings not available when handling EventRegisterParticipantsClassesAdd action"));
      return;
    }
    // Check pre added state to see if there's conflicts that need to be removed
    const {conflicts} = determineConflict(
      addingClass,
      state.EventClassesIndividualRegistered,
      rootState.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.RequireUniqueClassType,
      true,
    );
    let doesConflictAdmin = false;
    conflicts.forEach((index: number) => {
      if (!newState.EventClassesIndividualRegistered[index].AllowSelfRegistration) doesConflictAdmin = true;
      newState.EventClassesIndividualRegistered[index] = {...newState.EventClassesIndividualRegistered[index], Inactive: true};
    });

    if (!rootState.user.user.str_permissions.hasAdminAccess && doesConflictAdmin) {
      // todo: this logic needs to be refactored into action to do this properly...
      // dispatchWarning should be removed completely
      return {
        ...state,
        dispatchWarning: 'This class cannot be added since it conflicts with an admin-only class that cannot be removed.'
      };
    }

    addingClasses.forEach((adding: EventClassIndividualAvailable) => {
      const index = state.EventClassesIndividualRegistered.findIndex((r) => r.ClassIDi === adding.IDi);
      let newClass; // @todo: type
      if (index > -1) {
        newClass = {...state.EventClassesIndividualRegistered[index], Inactive: false};
        newState.EventClassesIndividualRegistered[index] = newClass;
      } else {
        newClass = {
          ...adding,
          IsUnscheduled: false,
          IDi: 0,
          OldIDi: 0,
          ClassIDi: adding.IDi,
          AmountPaid: 0.00,
          NumOverride: 0, // was '0' in old app
          Inactive: false,
          AmountChangeInCart: 0.00, // will get from server
          AmountInCart: 0.00,
          InCart: 1
        };
        newState.EventClassesIndividualRegistered.push(newClass);
      }
    });
    // now check each available class to see if it conflicts
    state.EventClassesIndividualAvailable.forEach((avail: EventClassIndividualAvailable, index: number) => {
      if (!rootState.cacheTwoEvents.EventTypeRegistrationSettings) {
        captureTentarooError(new Error("cacheTwoEvents.EventTypeRegistrationSettings not available when handling EventRegisterParticipantsClassesAdd action"));
        return;
      }
      const {conflictStatus} = determineConflict(avail, newState.EventClassesIndividualRegistered, rootState.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.RequireUniqueClassType);

      newState.EventClassesIndividualAvailable[index] = {
        ...state.EventClassesIndividualAvailable[index],
        conflictStatus,
      };
    });

    newState.EventClassesIndividualRegistered.sort(classSort);
    window.scrollTo(0, 0);

    return newState;
  } else if (isActionType(act, EventRegisterParticipantsClassesRemove)) {
    const newState = {...state};
    newState.EventClassesIndividualAvailable = state.EventClassesIndividualAvailable ? [...state.EventClassesIndividualAvailable] : [];
    newState.EventClassesIndividualRegistered = state.EventClassesIndividualRegistered ? [...state.EventClassesIndividualRegistered] : [];
    const removingIndex = state.EventClassesIndividualRegistered.findIndex((c: RegisteredClass) =>
      c.ClassIDi === act.clazz.ClassIDi &&
      !c.Inactive &&
      act.clazz.FirstTimeBlock === c.FirstTimeBlock &&
      act.clazz.ClassTypeIDi === c.ClassTypeIDi // need this to differentinate between 2 different unscheduled classes
    );
    if (removingIndex === -1) {
      // console.log(`Cannot remove class with idi ${act.clazz.ClassIDi}`);
      return state;
    }
    const removingIndexes: Array<number> = [removingIndex];
    const removingParentIDi = state.EventClassesIndividualRegistered[removingIndex].ParentIDi;
    if (removingParentIDi !== 0) {
      state.EventClassesIndividualRegistered.forEach((c: RegisteredClass, index: number) => {
        if (c.ParentIDi === removingParentIDi && index !== removingIndex) removingIndexes.push(index);
      });
    }

    removingIndexes.forEach((index: number) => {
      newState.EventClassesIndividualRegistered[index] = {
        ...newState.EventClassesIndividualRegistered[index],
        Inactive: true
      };
    });

    const rootState = act.rootState;
    state.EventClassesIndividualAvailable.forEach((avail: EventClassIndividualAvailable, index: number) => {
      if (!rootState.cacheTwoEvents.EventTypeRegistrationSettings) {
        captureTentarooError(new Error("cacheTwoEvents.EventTypeRegistrationSettings not available when handling EventRegisterParticipantsClassesRemove action"));
        return;
      }
      const {conflictStatus} = determineConflict(avail, newState.EventClassesIndividualRegistered, rootState.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.RequireUniqueClassType);

      newState.EventClassesIndividualAvailable[index] = {
        ...state.EventClassesIndividualAvailable[index],
        conflictStatus,
      };
    });

    window.scrollTo(0, 0);
    return newState;
  } else if (act.type === ApiDeleteRosterActions.successType) {
    const action = <WithRootState<APISuccess>> act;
    const person = action.response.response.GroupRosterPerson;
    const newState = {...state};
    if (person.IsYouth) {
      if (state.EventRegistrationPeopleNotRegisteredYouth && state.EventRegistrationPeopleNotRegisteredYouth.length > 0) {
        const found = state.EventRegistrationPeopleNotRegisteredYouth.findIndex((p) => p.PersonIDi === person.IDi);
        if (found > -1) {
          newState.EventRegistrationPeopleNotRegisteredYouth = [...state.EventRegistrationPeopleNotRegisteredYouth];
          newState.EventRegistrationPeopleNotRegisteredYouth.splice(found, 1);
        } else {
          captureTentarooError(new Error('Could not find person ' + person.Name + ' to remove from cache four'));
        }
      } else {
        captureTentarooError(new Error('There are no youth in cache four, how did you do this?'));
      }
    } else {
      if (state.EventRegistrationPeopleNotRegisteredAdults && state.EventRegistrationPeopleNotRegisteredAdults.length > 0) {
        const found = state.EventRegistrationPeopleNotRegisteredAdults.findIndex((p) => p.PersonIDi === person.IDi);
        if (found > -1) {
          newState.EventRegistrationPeopleNotRegisteredAdults = [...state.EventRegistrationPeopleNotRegisteredAdults];
          newState.EventRegistrationPeopleNotRegisteredAdults.splice(found, 1);
        } else {
          captureTentarooError(new Error('Could not find person ' + person.Name + ' to remove from cache four'));
        }
      } else {
        captureTentarooError(new Error('There are no youth in cache four, how did you do this?'));
      }
    }
    return newState;
  } else if (act.type === EventParticipantsGetPerson.successType) {
    const action = <WithRootState<APISuccess>> act;
    const newState = {...state};
    newState.GroupRosterPerson = {
      ...action.response.response.GroupRosterPerson
    };
    return newState;
  } else if (act.type === RosterApiSubmitActions.successType) {
    const action = <WithRootState<APISuccess>> act;
    try {
      const body = JSON.parse(action.response.request.body);
      const newState = updateCacheFourData({...state, isYouth: body.IsYouth}, action.response.response);

      // If switching between Youth and Adult, reset `EventRegistrationPerson.ParticipantTypeID`
      if (body.IsYouth !== newState.lastIsYouth && newState.EventRegistrationPerson) {
        newState.EventRegistrationPerson.ParticipantTypeID = -1;
      }
      newState.lastIsYouth = body.IsYouth;
      
      return newState;
    } catch (e) {
      captureTentarooError(new Error('Unable to parse request body for cache 4 event participants, cannot find isYouth!'));
      return state;
    }
  } else if (act.type === ApiSubmitActionsParticipantsClasses.successType || act.type === ApiSubmitActions.successType) {
    const action = <WithRootState<APISuccess>> act;
    const newState = updateCacheFourData({...state}, action.response.response);
    return newState;
  } else if (isActionType(act, EventRegisterParticipantsClearWarning)) {
    return {...state, dispatchWarning: undefined};
  } else if (isActionType(act, ClearAllCache) || isActionType(act, ClearAllEndUserCacheButOptions) ||
    isActionType(act, ClearCacheBelowOne) || isActionType(act, ClearCacheBelowTwoEvents) ||
    isActionType(act, ClearCacheBelowThreeEvents) || isActionType(act, ClearCacheFourEventParticipants)
  ) {
    return getInitialState();
  } else if (isActionType(act, UnselectParticipant) || isActionType(act, EventRegisterParticipantRosterSelectTab)) {
    return {...state, GroupRosterPerson: undefined};

  }
  return state || getInitialState();
};

export default CacheFourEventsParticipants;
