import { IValidator, FormDefinition } from "./validation";
import { SUFFIX } from './actions';
import { apiValidationReducerCreator } from "../../../../../store/Validation/reducerCreator";
import { createSelector } from "reselect";
import * as Actions from './actions';
import { ClearAdminEventsCacheBelowOne } from "../../../CacheOne/actions";
import { ApplicationState } from "../../../../../store";
import { sortByName } from "../../../../CacheZero";
import { ClassType } from "../../../../../models/api/options";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../../utils/StrongActions";
import { allClassTypesSelector } from "../Classes";
import { WithRootState } from "../../../../Validation/actionCreator";
import { setDefaults } from "../../../../../utils/validator";
import {ClearAllCache} from "../../../../App/actions";

export interface AdminEventsEventClassTypesTabState {
  ActiveForm: AdminEventsEventClassTypesTabActiveForm;
  ValidationRules: IValidator;
}

export interface AdminEventsEventClassTypesTabActiveForm {
  ShowDeleted?: boolean;
  FilterText?: string;
}

const getInitialState: () => AdminEventsEventClassTypesTabState = () => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition},
});

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const filterSelector = (state: ApplicationState) => state.adminEvents.events.event.classes.classTypesTab.ActiveForm.FilterText;
const showDeletedSelector = (state: ApplicationState) => state.adminEvents.events.event.classes.classTypesTab.ActiveForm.ShowDeleted;

export const makeActiveClassTypesSelector = () => {
  return createSelector(
    [allClassTypesSelector],
    (classTypes: ClassType[]) => {
      if (!classTypes) return [];
      return classTypes.filter((o) => !!o.Inactive).sort(sortByName);
    }
  );
};

export const filteredClassTypesCombiner = 
(classTypes: ClassType[], filterText: string, showDeleted: boolean, selectedClassTypeIds?: number[]) => {
  if (!classTypes) return [];
  const result = classTypes
    .filter((o) => {
      const f = filterText ? filterText.toLowerCase() : '';

      const nameMatch = o.Name.toLowerCase().includes(f);

      return (!f || nameMatch) && (showDeleted || !o.Inactive);
    })
    .sort(sortByName);

  if (selectedClassTypeIds) {
    const mapped = result.map((item) => {
      const existed = selectedClassTypeIds.findIndex((ctId) => ctId === item.IDi);
      return {...item, selected: existed !== -1};
    });

    return mapped;
  }

  return result;
};
export const makeFilteredClassTypesSelector = () => {
  return createSelector(
    [allClassTypesSelector, filterSelector, showDeletedSelector],
    filteredClassTypesCombiner,
  );
};

const AdminEventsEventClassTypesTabReducer: Reducer<AdminEventsEventClassTypesTabState> = (s, action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, action);
  if (isActionType(action, ClearAdminEventsCacheBelowOne) || isActionType(action, ClearAllCache)) {
    return setDefaults(action.rootState, getInitialState());
  } else if (isActionType(action, Actions.AdminEventsEventClassTypesTabReset)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        FilterText: '',
      },
    };
  }
  return state || setDefaults(action.rootState, getInitialState());
};

export default AdminEventsEventClassTypesTabReducer;