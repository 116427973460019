import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra
} from '../../../Validation/actionCreator';
import { ActionCreator, ApplicationState } from '../../..';
import { SaveState } from '../../../Rollback/actions';
import { scrollSideModalToTop } from '../../../../utils/helpers/adminCMSPageHelper';
import { navPush } from '../../../../utils';
import { ADMIN_EVENTS_MODULE_SUFFIX, ADMIN_EVENT_TYPES_SUFFIX } from '../../CacheOne/actions';
import { ParticipantType } from '../../../../models/api/adminEventsCacheOne';
import { getAdminEventsEventClassesUrl, getDeleteAdminEventClassBody, getUpdateAdminEventClassBody } from '../../../../constants/adminEventsUrls';
import { ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX, UPDATE_EVENT_FORM_SUFFIX } from '../../CacheTwoEvent/actions';
import { AdminEventClassFormState } from '.';
import { SectionCreator } from '../../../../utils/dataHelper';
import { ShowTopFloatingAlert } from '../../../App/actions';
import { isMobileAndSmallerScreenSize } from '../../../../utils/isMobile';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../../../utils/suffix';
import { PlainRoute } from 'react-router';
import { typeName, Action } from '../../../../utils/StrongActions';

export const UPDATE_EVENT_CLASS_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__$ADMIN_EVENT_CLASS$';
export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX + UPDATE_EVENT_FORM_SUFFIX + '__ADMIN_EVENT_CLASS_FORM' + UPDATE_EVENT_CLASS_FORM_SUFFIX;
export const DEL_SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX + UPDATE_EVENT_FORM_SUFFIX + '__DELETE_ADMIN_CLASS' + UPDATE_EVENT_CLASS_FORM_SUFFIX;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const UpdateAdminEventClassSubmitActions = createApiSubmitActions(SUFFIX);
export const DeleteAdminEventClassSubmitActions = createApiSubmitActions(DEL_SUFFIX);

export const AdminEventClassFormNamespace = (): string => 'pages--admin-events--class';

@typeName('UPDATE_SELECTED_CLASS_TYPE' + SUFFIX)
export class UpdateSelectedClassType extends Action { constructor(public classTypeIds: number[]) { super(); }}

@typeName('CHECK_TIME_BLOCK' + SUFFIX)
export class CheckTimeBlock extends Action {
  constructor(public id: number, public checked: boolean) { super(); }
}
@typeName('CHECK_DAY' + SUFFIX)
export class CheckDay extends Action {
  constructor(public id: number, public checked: boolean) { super(); }
}
@typeName('CHECK_PARTICIPANT_TYPE' + SUFFIX)
export class CheckParticipantType extends Action {
  constructor(public id: number, public checked: boolean) { super(); }
}

@typeName('REMOVE_CLASS_TYPE' + SUFFIX)
export class RemoveClassType extends Action {
  constructor(public id: number) { super(); }
}

@typeName('RESET_TO_SINGLE_CLASS_TYPE' + SUFFIX)
export class ResetToSingleClassType extends Action {
  constructor(public classIdToKeep: number) { super(); }
}

@typeName('TOGGLE_PARTICIPANT_TYPES' + SUFFIX)
export class ToggleParticipantTypes extends Action {
  constructor(public select:  boolean, public isYouth: boolean, public allParticipantTypes: ParticipantType[]) { super(); }
}

export type Actions = typeof actionCreators;

const ClassFormKeys = {
  IsRegisterUnderIndividuals: true,
  IsOption: true,
  IsCombo: true,
  ComboName: true,
  ClassCode: true,
  IsEntireEvent: true,
  ShowYouth: true,
  ShowAdults: true,
  Amount: true,
  IsAmountActual: true,
  UpfrontPaymentID: true,
  UpfrontPaymentSpecific: true,
  Location: true,
  MaxParticipants: true,
  MinimumAge: true,
  InstructorName: true,
  InstructorInitials: true,
  GLAccountID: true,
  AllowSelfRegistration: true,
};

const formCreator = (form: AdminEventClassFormState) => {
  const body = getUpdateAdminEventClassBody({
    IDi: form.ActiveForm.IDi,
    TS: form.ActiveForm.TS,
  }) as any;

  const row = SectionCreator(form.ActiveForm, ClassFormKeys, false, form.ValidationRules);

  if (!form.ActiveForm.IDi) {
    // if adding
    if (form.ActiveForm.IsCombo) {
      body.ComboClasses = form.ActiveForm.SelectedClassTypeIds;
    } else {
      if (form.ActiveForm.SelectedClassTypeIds) {
        row.ClassTypeIDi = form.ActiveForm.SelectedClassTypeIds[0];
      }
    }
  }

  delete row.IsPrevious;

  body.row = {
    ...body.row,
    ...row,
  };

  body.TimeBlocks = form.ActiveForm.TimeBlocks;
  body.Days = form.ActiveForm.Days;
  body.ParticipantTypes = form.ActiveForm.ParticipantTypes;

  return body;
};

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.classes.form),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  apiSubmitForm: (router: any, routes: any): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    // form validation (not including file)
    const valid = innerApiSubmitFormMethod(
      dispatch,
      UpdateAdminEventClassSubmitActions,
      (s: ApplicationState) => s.adminEvents.classes.form,
      undefined,
      undefined,
      true,
      true,
      undefined,
      undefined,
    );
    
    if (valid) {
      const formState = state.adminEvents.classes.form;
      if (!formState.ActiveForm.TimeBlocks || formState.ActiveForm.TimeBlocks.length === 0) {
        dispatch(new ShowTopFloatingAlert('Please select at least one Time Block and try again.', isMobileAndSmallerScreenSize(), 'orange'));
        return;
      }

      if (!formState.ActiveForm.ShowYouth && !formState.ActiveForm.ShowAdults) {
        dispatch(new ShowTopFloatingAlert('This class must be enabled for either Youth and/or Adults.', isMobileAndSmallerScreenSize(), 'orange'));
        return;
      }

      if (formState.ActiveForm.IsRegisterUnderIndividuals && formState.ActiveForm.ParticipantTypes) {
        const participantTypes = formState.ActiveForm.ParticipantTypes.map((ptId) => {
          return state.adminEvents.cacheOne.EventsEventType && state.adminEvents.cacheOne.EventsEventType.Pricing4.ParticipantTypes.find((_pt) => _pt.ID === ptId);
        });
        if (formState.ActiveForm.ShowYouth) {
          const hasYouth = participantTypes.filter((pt) => pt && pt.IsYouth).length > 0;

          if (!hasYouth) {
            dispatch(new ShowTopFloatingAlert('Please choose at least one participant type for Youth.', isMobileAndSmallerScreenSize(), 'orange'));
            return;
          }
        }
        if (formState.ActiveForm.ShowAdults) {
          const hasAdults = participantTypes.filter((pt) => pt && !pt.IsYouth).length > 0;

          if (!hasAdults) {
            dispatch(new ShowTopFloatingAlert('Please choose at least one participant type for Adults.', isMobileAndSmallerScreenSize(), 'orange'));
            return;
          }
        }
      }

      const body = formCreator(formState);

      dispatch(new SaveState());
      if (state.adminEvents.cacheOne.EventsEventType && state.adminEvents.cacheTwoEvent.EventsEvent) {
        navPush(router, getAdminEventsEventClassesUrl({
          eventTypeId: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID,
          eventId: state.adminEvents.cacheTwoEvent.EventsEvent.IDi,
          eventName: state.adminEvents.cacheTwoEvent.EventsEvent.Name,
        }));
      }

      dispatch(UpdateAdminEventClassSubmitActions.request(
        body,
        getCacheLevelExtra(
          true,
          !!state.adminEvents.cacheThreeClass.EventsEventClass,
          // In most cases, we check `leavingCacheLevelOnSuccess` for cache 2,
          // but here we want to skip checking it, and so we pass in 3
          3,
        ),
      ));
    } else {
      scrollSideModalToTop(AdminEventClassFormNamespace());
    }
  },
  deleteAdminEventClass: (classId: number, router: any, routes: PlainRoute<any>[], inactive: boolean): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const route = routes[routes.length - 1];
    dispatch(new SaveState());
    const body = getDeleteAdminEventClassBody({
      IDi: classId,
      Inactive: inactive,
    });

    // NOTE: `navPush` below will trigger `onBrowserUrlChange` which will close
    // all modals. We are relying on that to pop the DeleteClass modal
    // at the right timing, and make sure SavePoint happens before popping the modal
    if (state.adminEvents.cacheOne.EventsEventType && state.adminEvents.cacheTwoEvent.EventsEvent) {
      navPush(router, getAdminEventsEventClassesUrl({
        eventTypeId: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID || 0,
        eventId: state.adminEvents.cacheTwoEvent.EventsEvent.IDi || 0,
        eventName: state.adminEvents.cacheTwoEvent.EventsEvent.Name || '',
      }));
    }

    dispatch(DeleteAdminEventClassSubmitActions.request(
      body,
      getCacheLevelExtra(
        true,
        !!state.adminEvents.cacheThreeClass.EventsEventClass,
        // In most cases, we check `leavingCacheLevelOnSuccess` for cache 2,
        // but here we want to skip checking it, and so we pass in 3
        3,
      ),
      route,
    ));
  },
  initAdminEventClassForm: (): ActionCreator => dispatch => {
    dispatch(UpdateAdminEventClassSubmitActions.updateForm());
    dispatch(new SaveState());
  },
  checkTimeBlock: (id: number, checked: boolean): ActionCreator => dispatch => dispatch(new CheckTimeBlock(id, checked)),
  checkParticipantType: (id: number, checked: boolean): ActionCreator => dispatch => dispatch(new CheckParticipantType(id, checked)),
  toggleParticipantTypes: (select: boolean, isYouth: boolean): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    dispatch(new ToggleParticipantTypes(select, isYouth, state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.Pricing4.ParticipantTypes || [] : []));
  },
  updateSelectedClassType: (classTypeIds: number[]): ActionCreator => dispatch => dispatch(new UpdateSelectedClassType(classTypeIds)),
  removeClassType: (id: number): ActionCreator => dispatch => dispatch(new RemoveClassType(id)),
  resetToSingleClassType: (classIdToKeep: number): ActionCreator => dispatch => dispatch(new ResetToSingleClassType(classIdToKeep)),
  checkDay: (id: number, checked: boolean): ActionCreator => dispatch => dispatch(new CheckDay(id, checked)),
};