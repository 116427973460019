import {createRequestActions} from "../Validation/actionCreator";
import {ActionCreator} from "../index";
import { SaveState } from "../Rollback/actions";

export const SUFFIX = '__CART';
export const EmptyCartActions = createRequestActions(SUFFIX, true);
export const REMOVE_ITEM_SUFFIX = '__REMOVE_ITEM_FROM_CART';
export const RemoveItemFromCartActions = createRequestActions(REMOVE_ITEM_SUFFIX, true);

export interface CartActions {
  emptyCart(url: string, body: any, postNavigate?: string);
  removeItemFromCart(url: string, body: any, postNavigate?: string);
}

export const cartActionCreators = {
  emptyCart: (url: string, body: any, postNavigate?: string): ActionCreator => dispatch => {
    dispatch(new SaveState());
    dispatch(EmptyCartActions.request({url, body}, {postNavigate}));
  },
  removeItemFromCart: (url: string, body: any, postNavigate?: string): ActionCreator => dispatch => {
    dispatch(new SaveState());
    dispatch(RemoveItemFromCartActions.request({url, body}, {postNavigate}));
  },
};
