import {getPrevOrderBody, getPrevOrdersUrl} from '../../constants/urls';
import {makeRequestEpic} from "../Validation/epicCreator";
import {GetPrevOrders} from "./actions";

export const cacheTwoPrevOrdersEpic = makeRequestEpic(
  GetPrevOrders,
  getPrevOrdersUrl,
  getPrevOrderBody,
  true
);
