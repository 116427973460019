import {IValidator, FormDefinition} from "./validation";
import { apiValidationReducerCreator } from "../../../Validation/reducerCreator";
import {SUFFIX} from "./actions";
import { createSelector } from "reselect";
import { CMSContact, ContactCategory } from "../../../../models/api/adminCMSCacheOne";
import * as Actions from './actions';
import { ApplicationState } from "../../..";
import { ClearAllAdminCMSSiteCache } from "../../CacheOne/actions";
import { compareDataByRowNum } from "../../../../utils/dateHelper";
import { listSelector } from "../../../../utils/reselectHelper";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../utils/StrongActions";
import {setDefaults} from "../../../../utils/validator";
import { WithRootState } from "../../../Validation/actionCreator";
import {ClearAllCache} from "../../../App/actions";

export interface ContactsHomeState {
  ActiveForm: ContactHomeActiveForm;
  ValidationRules: IValidator;
  expandFilter: boolean;
  unmountPreview?: boolean;
}

export interface ContactHomeActiveForm {
  ShowDeleted?: boolean;
  FilterText?: string;
  SelectedCategoryID?: number;
}

const getInitialState: () => ContactsHomeState = () => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition},
  expandFilter: false,
  unmountPreview: false,
});
export const contactCategoriesSelector = (state: ApplicationState) => {
  if (state.adminCMSSite.cacheOne) return state.adminCMSSite.cacheOne.ContactCategories;
  return [];
};
const selectedContactCategoryIDSelector = (state: ApplicationState) => {
  return state.adminCMSSite.contacts.home.ActiveForm.SelectedCategoryID;
};
const contactShowDeletedSelector = (state: ApplicationState) => {
  return state.adminCMSSite.contacts.home.ActiveForm.ShowDeleted;
};
const contactFilterTextSelector = (state: ApplicationState) => {
  return state.adminCMSSite.contacts.home.ActiveForm.FilterText;
};

const _contactsSelector = (state: ApplicationState) => {
  if (state.adminCMSSite.cacheOne) return state.adminCMSSite.cacheOne.CMSContacts;
  return [];
};
export const contactsSelector = listSelector(_contactsSelector);

// @todo - utilize the "makeFilteredSelector" helper method
export const makeGroupedContactsSelector = () => {
  return createSelector(
    [contactsSelector, contactCategoriesSelector, contactShowDeletedSelector, contactFilterTextSelector],
    (contacts: CMSContact[], contactCategories: ContactCategory[], showDeleted: boolean, filterText: string) => {
      const groupedContacts: {[key: string]: any[]} = {};

      if (contacts) {
        contacts.forEach((contact) => {
          if (contact.CategoryID) {
            if (!groupedContacts[contact.CategoryID.toString()]) {
              groupedContacts[contact.CategoryID.toString()] = [];
            }
            // filter deleted
            if ((showDeleted || !contact.Inactive)) {
              // filter by filter text if set
              if (!filterText) {
                groupedContacts[contact.CategoryID.toString()].push(contact);
              } else {
                const f = filterText.toLowerCase();
                const nameMatches = contact.Name && contact.Name.toLowerCase().includes(f);
                const titleMatches = contact.Title && contact.Title.toLowerCase().includes(f);
                const companyMatches = contact.Company && contact.Company.toLowerCase().includes(f);
                if (nameMatches || titleMatches || companyMatches) {
                  groupedContacts[contact.CategoryID.toString()].push(contact);
                }
              }
            }
          }
        });
      }
      for (const key of Object.keys(groupedContacts)) {
        groupedContacts[key].sort(compareDataByRowNum);
        const contactCategory = contactCategories && contactCategories.find((c) => c.ID === Number(key));
        if (contactCategory && groupedContacts[key].length > 0) {
          groupedContacts[key].unshift(contactCategory);
        }
      }
      let result: any[] = [];
      for (const key of Object.keys(groupedContacts)) {
        result = result.concat(groupedContacts[key]);
      }
      

      // push an empty paragraph if there are any contacts
      if (result.length > 0) result.push(null);
      return result;
    }
  );
};

export const activeContactsSelector = listSelector(contactsSelector, true);

// @todo - utilize the "makeFilteredSelector" helper method
export const makeFilteredContactsSelector = () => {
  return createSelector(
    [contactsSelector, selectedContactCategoryIDSelector, contactShowDeletedSelector, contactFilterTextSelector],
    (contacts: CMSContact[], selectedCategoryId: number, showDeleted: boolean, filterText: string) => {
      if (selectedCategoryId !== 0 && contacts) {
        const result: any[] = contacts.filter(
          (contact) => {
            let result = false;
            // filter deleted
            if (contact.CategoryID === selectedCategoryId && (showDeleted || !contact.Inactive)) {
              // filter by filter text if set
              if (!filterText) {
                result = true;
              } else {
                const f = filterText.toLowerCase();
                const nameMatches = contact.Name && contact.Name.toLowerCase().includes(f);
                const titleMatches = contact.Title && contact.Title.toLowerCase().includes(f);
                const companyMatches = contact.Company && contact.Company.toLowerCase().includes(f);
                if (nameMatches || titleMatches || companyMatches) {
                  result = true;
                }
              }
            }
            return result;
          }
        ).sort(compareDataByRowNum);

        // push an empty paragraph if there are any filtered contacts
        if (result.length > 0) result.push(null);
        return result;
      }
      return [];
    }
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const ContactsHomeReducer: Reducer<ContactsHomeState> = (s, action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, action);
  if (isActionType(action, Actions.ToggleExpandFilterAction)) {
    return {...state, expandFilter: action.expand};
  } else if (isActionType(action, ClearAllAdminCMSSiteCache) || isActionType(action, ClearAllCache)) {
    return setDefaults(action.rootState, getInitialState());
  } else if (isActionType(action, Actions.ContactsHomeReset)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        FilterText: '',
      },
    };
  }
  return state || setDefaults(action.rootState, getInitialState());
};

export default ContactsHomeReducer;