
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetAdminEventClass} from "./actions";
import { getAdminEventClassCache, getAdminEventClassBody } from "../../../constants/adminEventsUrls";

export const adminEventsCacheThreeClassEpic = makeRequestEpic(
  GetAdminEventClass,
  getAdminEventClassCache,
  getAdminEventClassBody,
  true,
);