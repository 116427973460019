import * as M from '../../../../../constants/messages/generic';
import { FormDefinition as ClassesFormDefinition } from '../../Event/Classes/validation';
import { SelectValidator, Validator } from '../../../../../utils/validator/models';

export interface IValidator {
  SelectedEvent: Validator;
  SelectedClasses: Validator;
  SelectedEventType: SelectValidator;
  KindOfClass: SelectValidator;
  RegisterUnder: SelectValidator;
  ShowEventsFromPastYear: Validator;
}

export const FormDefinition: IValidator = {
  SelectedEvent: {
    key: 'SelectedEvent',
    defaultValue: () => 0,
  },
  SelectedEventType: {
    key: 'SelectedEventType',
    defaultValue: (rootState) => {
      return rootState.adminEvents.cacheOne.EventsEventType ? rootState.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID : 0;
    },
    options: {
      values: (rootState) => {
        return rootState.cacheZero.options && rootState.cacheZero.options.EventTypes ? rootState.cacheZero.options.EventTypes.filter((et) => !et.Inactive) || [] : [];
      },
      valueKey: (v) => v.ID,
      labelKey: 'Name',
    },
    validatejs: {
      SelectedEventType: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: '^' + M.REQUIRED,
          greaterThan: 0,
        }
      },
    },
  },
  SelectedClasses: {
    key: 'SelectedClasses',
  },
  KindOfClass: ClassesFormDefinition.KindOfClass,
  RegisterUnder: ClassesFormDefinition.RegisterUnder,
  ShowEventsFromPastYear: {
    key: 'ShowEventsFromPastYear',
  },
};