import { createSelector } from 'reselect';
import { ApplicationState } from '../../../..';
import { IValidator, FormDefinition } from './validation';
import { ResetGenerateInvoicesModal, SUFFIX, AddGroups, RemoveGroup, InitGenerateInvoicesModal, ClearError } from './actions';
import { SUFFIX as DASHBOARD_SUFFIX } from '../../Event/Dashboard/actions';
import { apiValidationReducerCreator } from '../../../../Validation/reducerCreator';
import moment from 'moment';
import { multipleSelectedGroupsCombiner } from '../../../MessageCenter/Modals/SendMessage';
import { groupsSelector } from '../MultipleGroups';
import { setDefaults } from '../../../../../utils/validator';
import { Reducer } from 'redux';
import { Action, isActionType } from '../../../../../utils/StrongActions';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface GenerateInvoicesModalState {
  ActiveForm: {
    IsAllRegisteredGroups?: boolean;
    BillEntireBalance?: boolean;
    IncludeNoBalanceDue?: boolean;
    DueDate?: moment.Moment;
    InvoiceNotes?: string;
    BillAmountPerYouth?: number;
    SelectedGroups?: number[];
  },
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
}
const getInitialState = () => ({
  ActiveForm: { },
  ValidationRules: {...FormDefinition},
});

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);
const generateReportsCheckApiValidation = apiValidationReducerCreator('__GENERATE_REPORTS' + DASHBOARD_SUFFIX, undefined, true);

const selectedGroupIdsSelector = (state: ApplicationState) => state.adminEvents.events.modals.generateInvoices.ActiveForm.SelectedGroups;
export const makeMultipleSelectedGroupsSelector = () => {
  return createSelector(
    [groupsSelector, selectedGroupIdsSelector],
    multipleSelectedGroupsCombiner,
  );
};

const GenerateInvoicesModalReducer: Reducer<GenerateInvoicesModalState> = (s, act: WithRootState<Action>) => {
  const state = generateReportsCheckApiValidation(checkApiValidation(s, act), act);

  if (isActionType(act, AddGroups)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedGroups: [...act.groupsIds],
      },
    };
  } else if (isActionType(act, RemoveGroup)) {
    const newState = {...state};
    const index = newState.ActiveForm.SelectedGroups.findIndex((g) => g === act.groupId);
    newState.ActiveForm.SelectedGroups.splice(index, 1);

    return newState;
  } else if (isActionType(act, InitGenerateInvoicesModal)) {
    const newState = {...state};
    return setDefaults(act.rootState, newState, state);
  } else if (isActionType(act, ClearError)) {
    return {
      ...state,
      SubmitErrorMessage: undefined,
    };
  } else if (isActionType(act, ResetGenerateInvoicesModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default GenerateInvoicesModalReducer;