import * as React from 'react';
import PropTypes from 'prop-types';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/tooltip/index.scss';
import Tip from './Tip';
import Box from './Box';

import {namespace} from "./constants";

export interface TooltipProps {
  className?: string;
  target?: () => Element;
  children?: React.ReactNode;
  hideTip?: boolean;
  attachment?: 'top' | 'bottom' | 'right';
  onBackdropClick?: React.ReactEventHandler<any>;
}

type TooltipState = {
  attachment?: 'top' | 'bottom' | 'right';
};

class Tooltip extends React.PureComponent<TooltipProps, TooltipState> {
  public static childContextTypes = {
    changeAttachment: PropTypes.func
  };

  public static defaultProps = {
    attachment: 'top'
  };

  public props: TooltipProps;
  public state: TooltipState = {
    attachment: 'top'
  };

  public constructor(props: TooltipProps) {
    super(props);
    this.state.attachment = props.attachment;
  }

  public getChildContext = () => ({
    changeAttachment: this.changeAttachment
  });

  public changeAttachment = (attachment: 'bottom' | 'top' | 'right') => this.setState({ attachment });

  public render() {
    const { target, children, hideTip, onBackdropClick, ...props } = this.props;
    const { attachment } = this.state;
    return (
      <div className={mergeClassNames(namespace(), props)}>
        {onBackdropClick && <div className={`${namespace()}--backdrop`} onClick={onBackdropClick}></div>}
        {children && target && attachment && <Box target={target} content={children} attachment={attachment}/>}
        {!hideTip && attachment && <Tip attachment={attachment}/>}
      </div>
    );
  }
}

export default Tooltip;
