import {makeRequestEpic} from "../../../Validation/epicCreator";
import {GeneralSettingsSubmitActions} from "./actions";
import {AjaxResponse} from "rxjs/ajax";
import { SITE_SETTINGS, ENTITY_SAVED } from "../../../../constants/messages/adminCMS";
import { getUpdateSiteSettingsGeneralUrl } from "../../../../constants/urls";

export const adminCMSSiteGeneralSettingsSubmitEpic = makeRequestEpic(
  GeneralSettingsSubmitActions,
  getUpdateSiteSettingsGeneralUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    return ENTITY_SAVED(SITE_SETTINGS);
  },
);