
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import { EmptyMessage, AdminEventCard } from '../../../../../Elements';
import { actionCreators } from "../../../../../../store/AdminEvents/MessageCenter/Modals/SendMessage/actions";
import '../../../../../../styles/pages/admin-events/message-center/modals/send-message/index.scss';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as adminEventsSharedActionCreators } from '../../../../../../store/AdminEvents/Shared/actions';
import { ApplicationState } from '../../../../../../store';
import { CardCategory } from '../../../../../Elements/Card';
import { EmptyMessageType } from '../../../../../../components/Elements/EmptyMessage';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { makeFilteredEventsSelector } from '../../../../../../store/AdminEvents/MessageCenter/Modals/SendMessage';
import { AdminEventsEvent } from '../../../../../../models/api/adminEventsCacheOne';
import { Facilities2Icon } from '../../../../../Icons';
import { ADMIN_EVENTS } from '../../../../../../constants/messages/adminEvents';
import { AdminEventCardType } from '../../../../../Elements/AdminEvent/Card';
import { disableBodyScroll, enableBodyScroll } from '../../../../../../utils/navHelper';
import { GetEventTypeCacheAction } from '../../../../../../store/AdminEvents/CacheOne/actions';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';

export const namespace = (): string => 'pages--admin-events--modal--send-message';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> &RouteComponentProps<{}, {}>;
interface Props {
}

@(withRouter as any)
class SelectMessageModalStepOne extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;
  private listRef;

  componentDidMount() {
    disableBodyScroll(this.listRef);
  }
  componentDidUpdate() {
    disableBodyScroll(this.listRef);
  }
  componentWillUnmount() {
    enableBodyScroll(this.listRef);
  }

  onEventClick = (event: AdminEventsEvent) => {
    const { actions, cacheOne, sendMessageModal: {ActiveForm, ValidationRules} } = this.props;

    if (!cacheOne.EventsEventType) return;
    actions.getRegisteredGroups(cacheOne.EventsEventType.EventTypeRow.ID as number, event.IDi);
    actions.updateValueWithoutSave(event.IDi, ValidationRules.SelectedEventID);
    actions.initStep2();
  };

  renderEventCard = (e: AdminEventsEvent) => {
    return (
      <AdminEventCard
        key={e.IDi}
        event={e}
        type={AdminEventCardType.IMPORT_FROM_MODAL_LIST}
        category={CardCategory.LIST_MOBILE}
        onClick={this.onEventClick}
      />
    );
  };

  renderEmptyMessage = () => {
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        admin
        icon={Facilities2Icon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={ENTITY_NOT_FOUND(ADMIN_EVENTS)}
      />
    );
  };

  public render() {
    const { apiLoading, apiLoadingMap, sendMessageModal: { ActiveForm, isTextSearching }, actions, filteredEvents, apiSaving} = this.props;
    const loading = apiLoadingMap[GetEventTypeCacheAction.requestType];;
    return (
      <div ref={(ref) => this.listRef = ref} className={`${namespace()}--events-list${loading ? ' loading' : ''}`}>
        {filteredEvents && filteredEvents.map(this.renderEventCard)}
        {!filteredEvents || filteredEvents.length === 0 ? this.renderEmptyMessage() : null}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredEventsSelector = makeFilteredEventsSelector();

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    cacheOne: state.adminEvents.cacheOne,
    sendMessageModal: state.adminEvents.messageCenter.modals.sendMessage,
    filteredEvents: filteredEventsSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...adminEventsSharedActionCreators,
  }, dispatch),
});

const ConnectedSelectMessageModalStepOne = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(SelectMessageModalStepOne);

export default ConnectedSelectMessageModalStepOne;