import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { default as Text, TextProps } from '../Text/index';
import '../../../styles/elements/label/index.scss';

const namespace = (): string => 'elements--label';

interface Props extends TextProps {
  children?: React.ReactNode;
}

const Label: React.SFC<Props> = ({ children, ...props }: Props): React.ReactElement<Props> => (
  <label className={mergeClassNames(namespace(), props)}>
    {typeof children === 'string' ? (
      <Text {...props} className={`${namespace()}--text`}>
        {children}
      </Text>
    ) : children}
  </label>
);

export default Label;
