import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/app/header/title.scss';

export const namespace = (): string => `${ns()}--title`;

interface Props {
  children?: React.ReactNode;
  className?: string;
  subTitle?: string;
}

const Title: React.SFC<Props> = ({ subTitle, children }: Props): React.ReactElement<Props> => (
  <div className={namespace()}>
    <span className={`${namespace()}--text` + (subTitle ? ' with-subtitle' : '')}>
      {children}
    </span>
    {subTitle ? (
      <span className={`${namespace()}--sub-title`}>
        {subTitle}
      </span>
    ) : null}
  </div>
);

export default Title;
