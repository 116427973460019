import { SelectValidator } from "../../../../../utils/validator/models";


export interface IValidator {
  RequirementTypeID: SelectValidator;
}

export const FormDefinition: IValidator = {
  RequirementTypeID: {
    key: 'RequirementTypeID',
    options: {
      values: () => [{
        ID: 0,
        Name: 'Show Requirements Taught at Camp',
      }, {
        ID: 1,
        Name: 'Show All Requirements',
      }],
      valueKey: (v) => v.ID,
      labelKey: 'Name'
    },
    defaultValue: () => 0,
  }
};
