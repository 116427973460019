import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/elements/card/padding.scss';
import { default as attributes, PaddingProps as AttributesPaddingProps, LayoutProps } from '../../../styles';

export const namespace = (): string => `${ns()}--padding`;

export interface PaddingProps extends AttributesPaddingProps, LayoutProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  overflowHidden?: boolean;
  padding?: 'none' | 'regular' | 'small' | 'compact';
}

const Padding: React.SFC<PaddingProps> = (
  {children, className, style, padding, overflowHidden, ...props}: PaddingProps
): React.ReactElement<PaddingProps> => {
  className = attributes(namespace(), className, props);
  if (padding) className += ` padding-${padding}`;
  if (overflowHidden) className += ' overflow-hidden';

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

Padding.defaultProps = {
  padding: 'regular',
  layout: 'vertical'
};

export default Padding;
