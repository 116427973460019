import { createSelector } from "reselect";

import type { ApplicationState } from "../../../..";

import {facilitiesSelector, sortFacilities, facilityTypesSelector} from '../../../../AdminFacilityLocation/Facilities/Home';
import { AdminFacility, AdminFacilityType } from "../../../../../models/api/adminFacilitiesCacheOne";

const selectedFacilityTypeSelector = (state: ApplicationState) => state.adminFacilityLocation.facilities.modals.importFromFacility.ActiveForm.SelectedFacilityType || 0;
const selectedFacilitySelector = (state: ApplicationState) => state.adminFacilityLocation.facilities.modals.importFromFacility.ActiveForm.SelectedFacility;
const filterTextSelector = (state: ApplicationState) => state.adminFacilityLocation.facilities.modals.importFromFacility.FilterText || '';
const currentFacilityIDSelector = (state: ApplicationState) => state.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility ? state.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility.ID : 0;

export const makeFilteredFacilitiesSelector = () => {
  return createSelector(
    [facilitiesSelector, selectedFacilityTypeSelector, filterTextSelector, currentFacilityIDSelector, facilityTypesSelector],
    (facilities: AdminFacility[], selectedFacilityTypeID: number, filterText: string, currentFacilityID: number, facilityTypes: AdminFacilityType[]) => {
      const text = filterText.toLowerCase();
      const result = facilities.filter((f) => {
        const facilityType = facilityTypes.find((ft) => ft.ID === f.FacilityTypeID);

        // Facilities shown in Import modal, filter by
        // 1. Active FacilityType only
        // 2. Selected facility type
        // 3. Active Facility
        // 4. Match text filter
        // 5. Not current facility
        return (facilityType && !facilityType.Inactive) && (selectedFacilityTypeID === 0 || (f.FacilityTypeID === selectedFacilityTypeID)) && !f.Inactive && f.Name.toLowerCase().includes(text) && f.ID !== currentFacilityID;
      });

      return sortFacilities(result, true);
    }
  );
};

export const makeSelectedFacilitySelector = () => {

  return createSelector(
    [facilitiesSelector, selectedFacilitySelector],
    (facilities: AdminFacility[], selectedFacility: number) => {
      return facilities.find((f) => f.ID === selectedFacility);
    }
  );
};