import { ApplicationState } from "../../..";
import { makeDeletedPrefixItemMapper } from "../../../../utils/dataHelper";
import { SelectValidator, Validator } from "../../../../utils/validator/models";

export const getResourceCategoryValues = (rootState: ApplicationState) => {
  const opts = rootState.adminCMSSite.cacheOne.CMSResourceCategories;
  if (opts) return opts.filter((category) => {
      // filter by inactive, and if inactive, but already selected, still return it
      return !category.Inactive ? true : (category.ID === rootState.adminCMSSite.resources.home.ActiveForm.SelectedCategoryID || category.ID === rootState.adminCMSSite.resources.modals.resource.ActiveForm.SelectedResourceCaetgoryID);
    })
    .map(makeDeletedPrefixItemMapper('Name'))
    .sort((a, b) => a.Inactive ? -1 : (b.Inactive ? 1 : 0));
  return [];
};

export interface IValidator {
  ShowDeleted: Validator;
  FilterText: Validator;
  SelectedCategoryID: SelectValidator;
  ResourceCategoryShowDeleted: Validator;
  ResourceCategoryFilterText: Validator;
}

export const FormDefinition: IValidator = {
  ShowDeleted: {
    key: 'ShowDeleted',
    isRequired: () => false,
    defaultValue: () => false,
  },
  FilterText: {
    key: 'FilterText',
    isRequired: () => false,
    defaultValue: () => '',
  },
  SelectedCategoryID: {
    key: 'SelectedCategoryID',
    options: {
      values: (rootState) => [{ Name: 'All Categories', ID: 0 }, ...getResourceCategoryValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'Name'
    },
    defaultValue: () => {
      return 0;
    },
  },
  ResourceCategoryShowDeleted: {
    key: 'ResourceCategoryShowDeleted',
    isRequired: () => false,
    defaultValue: () => false,
  },
  ResourceCategoryFilterText: {
    key: 'ResourceCategoryFilterText',
    isRequired: () => false,
    defaultValue: () => '',
  }
};