import * as React from 'react';
import '../../../styles/layouts/main/index.scss';
import Loader from '../../Elements/Loader';

import {namespace} from "./constants";
import {WithInertAttribute} from '../../Elements/WithInert';

type MainProps = WithInertAttribute<{
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  mobileBackground?: string;
  header?: React.ReactElement<any>;
  footer?: React.ReactElement<any>;
  leftSidebar?: React.ReactElement<any>;
  rightSidebar?: React.ReactElement<any>;
  rightGutter?: boolean;
  hasCompactMenu?: boolean;
  hideEarly?: boolean;
  /**
   * This flag will disable pointer event of this component, and shows direct children
   * as disabled (opacity: 0.5). Also, by default it will show a loader on top of this component
   */
  isLoading?: boolean;
  /**
   * If you dont want to show a loader while `isLoading === true`, set this
   * `noLoaderWhenIsLoading` flag
   */
  noLoaderWhenIsLoading?: boolean;
  isCMSSite?: boolean;
  isAdminFacilityLocationPage?: boolean;
  isAdminEvents?: boolean;
  onClick?: (e?:any) => void;
}>;

const Main: React.SFC<MainProps> = (
  { children, mobileBackground, header, className: overrideClassName, leftSidebar, rightSidebar, footer, style, rightGutter, hasCompactMenu, hideEarly, isLoading, noLoaderWhenIsLoading, onClick, isAdminFacilityLocationPage, isCMSSite, isAdminEvents, inert }
    : MainProps): React.ReactElement<MainProps> => {
  let className = namespace() + `${overrideClassName ? ' ' + overrideClassName : ''}`;
  if (mobileBackground) {
    className += ` mobile-background-${mobileBackground}`;
  }
  if (isLoading) className += ' loading';
  if (isCMSSite) className += ' cms-site';
  if (isAdminFacilityLocationPage) className += ' admin-facility-location';
  if (isAdminEvents) className += ' admin-events';

  return (
    <div inert={inert} className={className} style={style} onClick={onClick}>
      {header ? (
        <div className={`${namespace()}--header`}>
          {header}
        </div>
      ) : null}
      <div className={`${namespace()}--container`}>
        {leftSidebar ? leftSidebar : (rightSidebar ? <div className={`${namespace()}--empty-left-sidebar ${hideEarly ? 'hide-early' : ''}`}/> : null)}
        {children}
        {rightSidebar ? rightSidebar : (leftSidebar && rightGutter ? <div className={`${namespace()}--empty-right-sidebar ${hasCompactMenu ? 'compact' : ''}`}/> : null)}
        {isLoading && !noLoaderWhenIsLoading ? (
          <div className={`${namespace()}--loader`}>
            <Loader cover pop />
          </div>
        ) : null}
      </div>
      {footer ? (
        <div className={`${namespace()}--footer`}>
          {footer}
        </div>
      ) : null}
    </div>
  );
};

Main.defaultProps = {
  rightGutter: true
};

// todo remove main from layout
export default Main;
export { SideBarContent } from './SideBar';
export { default as MainLeftSideBar } from './LeftSideBar';
export { default as MainRightSideBar} from './RightSideBar';
export { default as MainContent } from './Content';
export { default as MainContentContainer } from './Content/Container';
