export const NO_YOUTH_AVAILABLE = 'There are no youth already in your account to choose from. Please choose the New Youth tab above to add a new youth.';
export const NO_ADULTS_AVAILABLE = 'There are no adults already in your account to choose from. Please choose the New Adult tab above to add a new adult.';
export const NEED_PERSON = 'Please select a person or create a new one.';
export const ONLY_SCOUTERS = 'Only scouters can be added to this event.';
export const PTYPE_REQUIRED = 'Please select a participant type.';

export const DOB_REQUIRED = 'Please add a date of birth for this participant so that the minimum age requirement can be checked.';
export const MIN_AGE_FAILED = 'This participant does not meet the minimum age requirement for this class.';

export const NO_DOB = "This participant does not have a date of birth on file, minimum age requirements cannot be checked for selected classes.";
export const LOW_DOB = "This participant does not meet the minimum age requirements for at least one class.";

export const DELETE_WARNING = 'Since this registration has been finalized, a removal item will be added' +
  ' to your cart. You\'ll need to checkout for this removal to take effect.';
export const DELETE_WARNING_IN_CART = 'Please confirm the new financial totals for this event to delete this registration.';
export const RESTORE_WARNING = 'Please confirm the new financial totals for this event to restore this registration.';
