import * as React from 'react';
import { default as TextField, TextFieldProps } from '../TextField';

class IntegerField extends React.PureComponent<TextFieldProps, {}> {
  public props: TextFieldProps;

  public componentDidMount() {
  }

  onChange = (val, validationRules) => {
    const intVal = parseInt(val);
    if (this.props.onChange && !isNaN(val) && val !== '') this.props.onChange(intVal, validationRules);
    else if (this.props.onChange && val === '') this.props.onChange('', validationRules);
  };

  onBlur = (val, validationRules) => {
    // validate.js's "length" check does not work on numeric, only string, so we can't parseInt here
    if (this.props.onBlur) this.props.onBlur(val, validationRules);
  };

  public render() {
    return (
      <TextField {...this.props} onChange={this.onChange} onBlur={this.onBlur}/>
    );
  }
}

export default IntegerField;
