import { GetPageParams } from "../../../constants/urls";
import { CLEAR_CACHE_PREFIX, createRequestActions } from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { ADMIN_CMS_MODULE_SUFFIX } from "../CacheOne/actions";
import { HideDrawer, SilentCancelAllAction } from '../../../store/App/actions';
import { UPDATE_CMS_PAGE_FORM_SUFFIX } from "../Pages/Page/Form/actions";
import { typeName, Action } from "../../../utils/StrongActions";


export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__CACHE_TWO_PAGE';
export const SUFFIX_SAVE = ADMIN_CMS_MODULE_SUFFIX + '__CACHE_TWO_PAGE_SAVING';

export const GetPage = createRequestActions(SUFFIX + UPDATE_CMS_PAGE_FORM_SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminCMSCacheTwoPage extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  getPage: (params: GetPageParams): ActionCreator => dispatch => {
    dispatch(GetPage.request(params));
  },
  refreshPage: (params: GetPageParams): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    dispatch(GetPage.request(params));
  },
  clearAdminCMSCacheTwoPage: (): ActionCreator => dispatch => {
    dispatch(new ClearAdminCMSCacheTwoPage());
  },
};