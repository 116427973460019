import React, { CSSProperties, useCallback, useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import {mergeClassNames} from '@tentaroo/shared';

import _LineEllipsis from "@tentaroo/libraries/src/react-lines-ellipsis/lib/";
import responsiveHOC from "@tentaroo/libraries/src/react-lines-ellipsis/lib/responsiveHOC";
const LinesEllipsis = responsiveHOC()(_LineEllipsis);

import '../../../styles/elements/ellipsis/index.scss';
import Arrow from '../../../images/icons/arrow.svg';

interface ClampTextConfig {
  text: string;
  lines?: number;
  expanded?: boolean;
  debounceTime?: number;
  charWidth?: number;
}

interface EllipsisProps {
  configs: ClampTextConfig;
  className?: string;
  onlyExpandOnArrow?: boolean;
  /**
   * CSS Style applied to the wrapper
   */
  width?: string;
  fontSize?: number;
  marginBottom?: number;
  /**
   * When specified, can not expand the text
   */
  isStatic?: boolean;
  /**
   * Use these following two fields to support some customized html
   * before the text you want to truncate. The measurement will count
   * the `precedingTag`
   */
  precedingTag?: string;
  /**
   * Make the truncated text inline
   */
  inline?: boolean;
}

const namespace = () => "elements--ellipsis";

const DEFAULT_LINES = 3;
const DEFAULT_WIDTH = "100%";
const SPACING_FOR_ARROW_EXPAND = 28;

function Ellipsis(props: EllipsisProps) {
  const {configs, className, precedingTag, inline, isStatic, onlyExpandOnArrow, fontSize, width, marginBottom} = props;

  const [expanded, setExpanded] = useState(false);
  const [clamped, setClamped] = useState(false);
  const linesEllipsisRef = useRef(null);

  const onReflow = ({clamped, text}: {clamped: boolean, text: string}) => {
    setClamped(clamped);
  };
  
  const onToggleExpanded = useCallback((e) => {
    if (!clamped || isStatic) return;
    e.stopPropagation();
    setExpanded(!expanded);
  }, [clamped, isStatic, expanded]);

  const wrapperStyle = useMemo((): CSSProperties => {
    return {
      fontSize: fontSize,
      marginBottom: marginBottom ? `${marginBottom}px` : undefined,
      width: width || DEFAULT_WIDTH,
    };
  }, [fontSize, marginBottom, width]);

  const clampedClassName = `${clamped ? ' clamped' : ''}`;
  const isStaticClassName = `${isStatic ? ' is-static' : ''}`;
  const isInlineClass = `${inline ? ' inline' : ' normal'}`;
  const mergedClassNames = mergeClassNames(`${namespace()}`, props);

  const renderTextEllipsis = () => {
    return (
      <div
        className={`${mergedClassNames}${clampedClassName}${isStaticClassName}${isInlineClass}`}
        onClick={onlyExpandOnArrow ? undefined : onToggleExpanded}
        style={wrapperStyle}
      >
        <LinesEllipsis
          text={configs.text}
          innerRef={linesEllipsisRef}
          component="article"
          basedOn="words"
          maxLine={configs.lines || DEFAULT_LINES}
          onReflow={onReflow}
          ellipsis="..."
          precedingTag={precedingTag}
          spaceForCustomExpand={isStatic ? undefined : SPACING_FOR_ARROW_EXPAND}
          customExpand={clamped && !isStatic ? <Arrow onClick={onlyExpandOnArrow ? onToggleExpanded : undefined} className={`${namespace()}--arrow`} /> : null}
        />
      </div>
    );
  };

  return expanded ? (
    <div
      className={`${mergedClassNames}${isStaticClassName}${isInlineClass} expanded`}
      onClick={onlyExpandOnArrow ? undefined : onToggleExpanded}
      style={wrapperStyle}
    >
      <span className="ellipsis-expanded" dangerouslySetInnerHTML={{__html: (precedingTag ? precedingTag : "") + `${configs.text}`}} />
      <Arrow onClick={onlyExpandOnArrow ? onToggleExpanded : undefined} className={`${namespace()}--arrow expanded`} />
    </div>
  ) : renderTextEllipsis();
}

export default Ellipsis;