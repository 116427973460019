import { UnauthenticatedCacheContext } from "@tentaroo/shared";
import { GetRegisterFormActions, InitRegisterFormOtherFields } from "../../store/CreateAccount/actions";
import { SaveState } from "../../store/Rollback/actions";
import { APIRequestGetLoginForm } from "../../store/Session/actions";
import { reduxStoreService } from "../../store/service";
import type UnauthenticatedCacheManager from "../cacheManagers/unauthenticatedCacheManager";
import { LoadLoginFormParams } from "./helpers/models";
import {isCreateFormCachePopulated, isLoginCachePopulated} from "../cachePopulatedCheckers/unauthenticated";

export const ensureUnauthenticatedCache = (
  params: LoadLoginFormParams & {
    context: UnauthenticatedCacheContext,
    resetCode?: string,
  }
): boolean => {
  const {context, resetCode, isStateNavigated} = params;
  if (
    (context === UnauthenticatedCacheContext.CREATE_ACCOUNT && isCreateFormCachePopulated()) ||
    (context === UnauthenticatedCacheContext.LOGIN && isLoginCachePopulated())
  ) {
    if (isStateNavigated) {
      if (
        context === UnauthenticatedCacheContext.CREATE_ACCOUNT
      ) {
        reduxStoreService().dispatch(GetRegisterFormActions.updateForm());
        reduxStoreService().dispatch(new InitRegisterFormOtherFields());
      }
      reduxStoreService().dispatch(new SaveState());
    }
    return false;
  } else {
    if (context === UnauthenticatedCacheContext.LOGIN) {
      reduxStoreService().dispatch(new APIRequestGetLoginForm(resetCode));
    } else if (context === UnauthenticatedCacheContext.CREATE_ACCOUNT) {
      reduxStoreService().dispatch(GetRegisterFormActions.request({}));
    }
    return true;
  }
};