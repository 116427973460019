import * as React from 'react';
import {Main, MainContent} from '../../../../../Layouts';
import {Media, PageLoader, Tab, Tabs, ViewControls} from '../../../../../Elements';
import HeaderProgress from '../HeaderProgress';
import Moment from 'moment';
import * as Range from 'moment-range';
import Facility from './Facility';
import Compact from './Compact';
import {ShowTopFloatingAlert} from "../../../../../../store/App/actions";
import {CacheFourFacilitiesState} from "../../../../../../store/CacheFourFacilities";
import {CacheTwoFacilitiesState} from "../../../../../../store/CacheTwoFacilities";
import {CacheThreeFacilitiesState} from "../../../../../../store/CacheThreeFacilities";
import {actionCreators} from "../../../../../../store/Facilities/Trip/Reservation/Type/actions";
import {bindActionCreators} from 'redux';
import {
  Actions as CacheFourFacilitiesActions,
  FacilitiesRecalc,
  FacilitiesTypeC4
} from "../../../../../../store/CacheFourFacilities/actions";
import {CacheZeroState} from "../../../../../../store/CacheZero";
import {FacilityTrip} from "../../../../../../models/api/cacheThreeFacilities";
import {FacilitiesAvailability, FacilityLocation_FacilityType} from "../../../../../../models/api/cacheTwoFacilties";
import '../../../../../../styles/pages/facilities/trip/reservation/type/facility.scss';
import {UserState} from "../../../../../../store/User";
import {NO_BOOKING} from "../../../../../../constants/messages/generic";
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../store/service';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--facilities--trip--reservation--type--facility';

const moment = (Range as any).extendMoment(Moment);

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = WithInertAttribute<{
  user: UserState;
  selectedFacilityType: FacilityLocation_FacilityType;
  apiLoadingMap: any;
  parentActions: CacheFourFacilitiesActions;
  cacheZero: CacheZeroState;
  cacheTwoFacilities: CacheTwoFacilitiesState;
  cacheThreeFacilities: CacheThreeFacilitiesState;
  cacheFourFacilities: CacheFourFacilitiesState;
  apiLoading: number;
}>;

class Type extends React.Component<Props, {}> {
  props: Props & ConnectedProps;

  public componentDidMount() {
  }

  onChangeTab = (view) => this.props.actions.selectView(view);
  onSelectType = (facTypeId: number) => {
    const {actions, parentActions, cacheZero, cacheTwoFacilities, cacheThreeFacilities} = this.props;

    if (!cacheZero.options?.Group) return;

    actions.selectType(facTypeId);
    parentActions.getFacilitiesTypes({
      GroupIDi: cacheZero.options.Group.IDi,
      GroupTS: cacheZero.options.Group.TS,
      FacilityLocationID: cacheTwoFacilities.locationID,
      FacilityTripID: cacheThreeFacilities.tripID,
      FacilityTypeID: facTypeId
    });
  };

  onSelectFacility = (avail: FacilitiesAvailability, trip: FacilityTrip, type: FacilityLocation_FacilityType) => {
    const {user, actions} = this.props;
    if (!user.user.str_permissions.hasAdminAccess && !avail.AllowOnlineFacilityBooking) {
      reduxStoreService().dispatch(new ShowTopFloatingAlert(NO_BOOKING, false, 'orange'));
    } else {
      actions.typeSubmit(
        type.ID,
        avail,
        trip
      );
    }
  };

  public render() {
    const {
      selectedFacilityType,
      facilitiesReservationType: {selectedView, selectedType},
      cacheTwoFacilities: {FacilityLocation_FacilityTypes},
      cacheThreeFacilities: {FacilityTrip},
      cacheFourFacilities: {FacilitiesAvailabilitiesDates, FacilitiesAvailabilities},
      actions, apiLoadingMap, apiLoading, inert,
    } = this.props;

    if (!FacilityTrip || !FacilitiesAvailabilitiesDates || !FacilitiesAvailabilities) return null;
    // todo what if no FacilitiesAvailabilities?
    const isLoading = apiLoadingMap[FacilitiesTypeC4.requestType];
    return (
      <Main inert={inert} mobileBackground="white">
        <MainContent
          loading={apiLoadingMap[FacilitiesRecalc.requestType]}
          header={(
            <HeaderProgress selected="type" tabs={(
              <Tabs
                controls={<ViewControls selected={selectedView} onChange={actions.selectView}/>}
              >
                {FacilityLocation_FacilityTypes?.map((type, index) => (
                  <Tab
                    selected={(!selectedType && index === 0) ? true : selectedType === type.ID}
                    onClick={() => this.onSelectType(type.ID)}
                  >
                    {type.Name.toUpperCase()}
                  </Tab>
                ))}
              </Tabs>
            )}/>
          )}
          className={`${namespace()}--content`}
        >
          <Media tablet desktop expand={true}>
            <Tabs
              className={`${namespace()}--tabs`}
              controls={<ViewControls selected={selectedView} onChange={actions.selectView}/>}
            >
              {FacilityLocation_FacilityTypes?.map((type, index) => (
                <Tab
                  selected={(!selectedType && index === 0) ? true : selectedType === type.ID}
                  onClick={() => this.onSelectType(type.ID)}
                >
                  {type.Name.toUpperCase()}
                </Tab>
              ))}
            </Tabs>
          </Media>
          {!isLoading ? (selectedView === 'grid' ? FacilitiesAvailabilities.map((avail, index) => {
            return <Facility
              key={index}
              trip={FacilityTrip}
              avail={avail}
              type={selectedFacilityType}
              availabilities={FacilitiesAvailabilitiesDates}
              onClick={this.onSelectFacility}
            />;
          }) : (<Compact
            trip={FacilityTrip}
            FacilitiesAvailabilities={FacilitiesAvailabilities}
            type={selectedFacilityType}
            availabilities={FacilitiesAvailabilitiesDates}
            onClick={this.onSelectFacility}
          />)) : <PageLoader/>}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    facilitiesReservationType: state.facilities.trip.reservation.type
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators
  }, dispatch)
});
const ConnectedType = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(Type);

export default ConnectedType;
