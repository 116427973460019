import * as React from 'react';
import moment from 'moment';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as cacheOneActionCreators } from "../../../../../store/AdminFacilityLocation/CacheOne/actions";
import { actionCreators, GetAvailabilitiesAction } from "../../../../../store/AdminFacilityLocation/Availabilities/Home/actions";
import { LoadingAll, EmptyMessage, FacilityCard, Tabs, Tab, Title, MonthPicker, ViewControls } from '../../../../Elements';
import { Main, MainContent } from '../../../../Layouts';
import '../../../../../styles/pages/admin-facility-location/availabilities/home/index.scss';
import { makeActiveFacilityTypesSelector } from '../../../../../store/AdminFacilityLocation/Availabilities/Home';
import { formatDate } from '../../../../../utils/dateHelper';
import CompactView from '../../../../../components/Pages/Facilities/Location/CompactView';
import { noop, navPush } from '../../../../../utils';
import { FacilitiesAvailability } from '../../../../../models/api/cacheTwoFacilties';
import { AdminFacilitiesAvailability } from '../../../../../models/api/adminFacilitiesAvailability';
import { EmptyMessageType } from '../../../../../components/Elements/EmptyMessage';
import { Facilities2Icon } from '../../../../../components/Icons';
import { ENTITY_NOT_FOUND } from '../../../../../constants/messages/adminCMS';
import { FACILITIES } from '../../../../../constants/messages/adminFacilityLocation';
import { getEditFacilityUrl, constructAdminFacilityLocationFacilityUrlParams } from '../../../../../constants/urls';
import AdminFacilitiesLocationCacheManager from '../../../../../utils/cacheManagers/adminFacilitiesLocationCacheManager';
import { IAdminFacilitiesLocationRouterParams } from '../../../../../utils/helpers/adminFacilityLocationPageHelper';
import { SaveState } from '../../../../../store/Rollback/actions';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isAdminFacilitiesLocationCacheOnePopulated} from '../../../../../utils/cachePopulatedCheckers/adminFacilities';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--facility-location--availabilities';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}>
>;

class AvailabilitiesHome extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;

  componentDidUpdate(prevProps: ConnectedProps) {
    const { actions, activeFacilityTypes, location, availabilitiesHome: { startDate, selectedTabId }, apiLoading, apiLoadingMap } = this.props;
    
    /*
    // if cache one loaded && has active facility types
    if (this.hasActiveFacilityTypes()) {
      if (!selectedTabId) actions.selectTab(activeFacilityTypes[0].ID)
      // no availabilities are loaded yet - direct visit OR rollback to a state with `hasActiveFacilityTypes()` && `!hasAvailabilities()`
      if (!this.hasAvailabilities()) {
        // only perform GetAvailbilities request when the following conditions ALL hold
        // 1. A save point has been made - in this particular scenario, it was made via `ensureAdminFacilityLocationCacheOne`
        // 2. Not ongoing GetAvailabilitiesAction
        // 3. Old state's rollback is `undefined` - it would NOT be `undefined` during rollback, so this would prevent a load from rollback
        if (rollback.oldState && !apiLoadingMap[GetAvailabilitiesAction.requestType] && !rollback.oldState.rollback) {
          actions.getAvailabilities({
            FacilityTypeID: activeFacilityTypes[0].ID,
            StartDate: startDate.format(API_DATE_FORMAT),
          }, this.props);
        }
      }
    }
    */
  }

  componentDidMount() {
    const { actions, activeFacilityTypes, availabilitiesHome: {selectedTabId} } = this.props;
    actions.showAdminPageHeader(true);

    if (this.hasActiveFacilityTypes()) {
      const selectedTab = selectedTabId ? this.props.activeFacilityTypes.find((ft) => ft.ID === selectedTabId) : null;
      if (!selectedTab) {
        this.onClickTab(activeFacilityTypes[0].ID);
      } else {
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      super.loadAndSetData(
        this.props,
        (isStateNavigated) => {
          AdminFacilitiesLocationCacheManager.getInstance().loadAdminFacilitiesLocationCacheOne({
            props: this.props,
            isGetAvailabilities: true,
            isStateNavigated,
          });
        }
      );
    }
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        AdminFacilitiesLocationCacheManager.getInstance().loadAdminFacilitiesLocationCacheOne({
          props: nextProps,
          isGetAvailabilities: true,
          isStateNavigated,
        });
      }
    );
  }

  hasAvailabilities = () => {
    const { availabilitiesHome } = this.props;

    return availabilitiesHome.FacilitiesAvailabilities && availabilitiesHome.FacilitiesAvailabilitiesDates;
  };
  hasActiveFacilityTypes = () => {
    return this.props.activeFacilityTypes && this.props.activeFacilityTypes.length > 0;
  };

  onClickTab = (facilityTypeId: number) => {
    const { actions, availabilitiesHome: { startDate } } = this.props;
    actions.reset();
    actions.selectTab(facilityTypeId);
    actions.getAvailabilities({
      FacilityTypeID: facilityTypeId,
      StartDate: formatDate(startDate),
    }, this.props);
  };

  renderTabs = () => {
    const { availabilitiesHome: {selectedTabId}} = this.props;
    if (this.hasActiveFacilityTypes()) {
      return (
        <Tabs className={`${namespace()}--tabs`}>
          {this.props.activeFacilityTypes.map((ft, index) => {
            return (
              <Tab key={ft.ID} selected={(!selectedTabId && index === 0) ? true : selectedTabId === ft.ID} onClick={() => this.onClickTab(ft.ID)}>{ft.NamePlural}</Tab>
            );
          })}
        </Tabs>
      );
    }
    return null;
  };
  onChangeView = (view) => {
    this.props.actions.setView(view);
  };
  onChangeMonth = (startDate: moment.Moment) => {
    const { actions, availabilitiesHome: {selectedTabId}} = this.props;
    if (!selectedTabId) return;
    actions.changeDate(startDate);
    actions.getAvailabilities({
      FacilityTypeID: selectedTabId,
      StartDate: formatDate(startDate),
    }, this.props);
  };

  gotoFacility = (avail: AdminFacilitiesAvailability) => {
    const editFacilityUrl = getEditFacilityUrl(constructAdminFacilityLocationFacilityUrlParams(this.props, this.props.adminFacilityLocationCacheOne, {ID: avail.ID, Name: avail.FacilityName} as any));
    navPush(this.props.router, editFacilityUrl);
  };
  onClickFacilityCard = (avail: AdminFacilitiesAvailability) => {
    this.gotoFacility(avail);
  };
  onClickAvail = (avail: FacilitiesAvailability) => {
    this.gotoFacility(avail as any);
  };

  private renderCompactView() {
    const {
      availabilitiesHome: {startDate, FacilitiesAvailabilities}, availabilitiesHome, apiLoadingMap
    } = this.props;
    const end = moment(startDate).startOf('month').add(3, 'months');
    return <CompactView
      FacilitiesAvailabilities={FacilitiesAvailabilities}
      startTime={startDate}
      endTime={end}
      loading={apiLoadingMap[GetAvailabilitiesAction.requestType]}
      onClickAvail={this.onClickAvail}
      showAdminBadge
      hasEditButton
    />;
  }

  private renderGridView() {
    const {
      adminFacilityLocationCacheOne: { FacilitiesLocation },
      availabilitiesHome: {startDate, FacilitiesAvailabilities, FacilitiesAvailabilitiesDates}, apiLoadingMap
    } = this.props;
    if (!FacilitiesLocation) return null;
    const end = moment(startDate).startOf('month').add(3, 'months');
    if (FacilitiesAvailabilities === undefined) {
      return <FacilityCard
        facilityLocation={FacilitiesLocation}
        nameLayout='horizontal'
        onClick={noop}
        name="skeleton"
        skeleton
        availabilities={undefined}
        availabilityDates={undefined}
        startTime={startDate}
        endTime={end}
        nameSingular={FacilitiesLocation.Name}
        avail={{} as FacilitiesAvailability}
        wrapName
        hideImage
      />;
    }
    if (FacilitiesAvailabilities === null || FacilitiesAvailabilities.length === 0) {
      return (
        <EmptyMessage
          type={EmptyMessageType.PAGE_MARGIN}
          icon={Facilities2Icon}
          iconHeight='96px'
          iconWidth='96px'
          fixedFontSize
          description={ENTITY_NOT_FOUND(FACILITIES)}
        />
      );
    }
    return FacilitiesAvailabilities.map((avail) => <FacilityCard
      facilityLocation={FacilitiesLocation}
      nameLayout='horizontal'
      key={`${avail.ID}_${avail.ftbID}`}
      onClick={() => this.onClickFacilityCard(avail)}
      name={avail.Name}
      availabilities={avail.Availabilities}
      availabilityDates={apiLoadingMap[GetAvailabilitiesAction.requestType] ? undefined : FacilitiesAvailabilitiesDates}
      startTime={startDate}
      endTime={end}
      avail={avail}
      showAdminBadge={avail.AllowOnlineBookingID !== 1}
      nameSingular={FacilitiesLocation.Name}
      image={undefined}
      onEdit={() => this.onClickFacilityCard(avail)}
      wrapName
      hideImage
    />);
  }

  renderContent = () => {
    const {activeFacilityTypes, availabilitiesHome: {view}} = this.props;

    if (activeFacilityTypes.length === 0) {
        return (
          <EmptyMessage
            type={EmptyMessageType.PAGE_MARGIN}
            icon={Facilities2Icon}
            iconHeight='96px'
            iconWidth='96px'
            fixedFontSize
            description={ENTITY_NOT_FOUND(FACILITIES)}
          />
        );
    } else {
      return view === 'grid' ? this.renderGridView() : this.renderCompactView();
    }
  };

  public render() {
    const {actions, activeFacilityTypes, availabilitiesHome: {view, startDate, disableNext, showContextMenu, disablePrev}, inert} = this.props;
    if (!isAdminFacilitiesLocationCacheOnePopulated()) {
      return <LoadingAll />;
    }

    return (
      <Main inert={inert} isAdminFacilityLocationPage>
        <MainContent className={namespace()} handleCompact>
          {this.renderTabs()}
          {activeFacilityTypes.length > 0 && <Title
            className={'check-dates-top'}
            center
            size={28}
            mobileSize={22}
            controls={<ViewControls selected={view as any} onChange={this.onChangeView}/>}
            extra={<MonthPicker
              date={startDate}
              disableNext={disableNext}
              disablePrev={disablePrev}
              showContextMenu={showContextMenu}
              onChange={this.onChangeMonth}
              onShowMonthMenu={actions.showMonthMenu}
            />}
          />}
          {this.renderContent()}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const activeFacilityTypesSelector = makeActiveFacilityTypesSelector();
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
    availabilitiesHome: state.adminFacilityLocation.availabilities.home,
    activeFacilityTypes: activeFacilityTypesSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheOneActionCreators,
    ...appActionCreators,
  }, dispatch),
});

const ConnectedAvailabilitiesHome = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(AvailabilitiesHome);

export default withRouter<{}>(ConnectedAvailabilitiesHome);
