
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import { AdminEventCard, AdminClassCard } from '../../../../../Elements';
import { actionCreators } from "../../../../../../store/AdminEvents/Events/Modals/ImportFromEvent/actions";
import '../../../../../../styles/pages/admin-events/events/modals/import-from-event/index.scss';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { ApplicationState } from '../../../../../../store';
import { enableBodyScroll, disableBodyScroll } from '../../../../../../utils/navHelper';
import { CardCategory } from '../../../../../Elements/Card';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { makeSelectedEventSelector, makeSelectedClassesSelector } from '../../../../../../store/AdminEvents/Events/Modals/ImportFromEvent';
import { AdminEventCardType } from '../../../../../Elements/AdminEvent/Card';
import { AdminClassCardType } from '../../../../../Elements/AdminClass/Card';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { captureTentarooError } from '../../../../../../utils/dataHelper';

export const namespace = (): string => 'pages--admin-events--modals--import-from-event';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;
interface Props {
}

export enum ImportFromEventType {
  TIME_BLOCKS,
  PRICING,
  CLASSES,
}

@(withRouter as any)
class ImportFromEventConfirmation extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;

  private ref;

  componentDidMount() {
    disableBodyScroll(this.ref);
  }

  componentWillUnmount() {
    enableBodyScroll(this.ref);
  }

  onComplete = () => {
    if (this.props.type !== undefined) {
      this.props.actions.popModal(true, false, ModalTypes.IMPORT_FROM_EVENT);
    }
  };

  public render() {
    const { type, modalState: {ActiveForm}, selectedClasses, selectedEvent } = this.props;

    return (
      <div className={`${namespace()}--step-wrapper`}>
        <div className={`${namespace()}--step-prompt${type === ImportFromEventType.CLASSES && !ActiveForm.SelectedClasses ? ' has-filter' : ''}`}>
          <span>{`${type === ImportFromEventType.CLASSES ? 'Step 3 of 3' : 'Step 2 of 2'}`}</span>
          <span>Confirm</span>
        </div>
        <div className={`${namespace()}--step2--content`} ref={(ref) => this.ref = ref}>
          <div className={`${namespace()}--helper-text`}>
            {type === ImportFromEventType.CLASSES ? 'The following classes / options will be copied to the current event:' : `${type === ImportFromEventType.TIME_BLOCKS ? 'Time Block' : (type === ImportFromEventType.PRICING ? ' All Pricing' : '')} settings will be imported from:`}
          </div>
          {type !== ImportFromEventType.CLASSES && <AdminEventCard
            event={selectedEvent!}
            type={AdminEventCardType.IMPORT_FROM_MODAL_LIST_SELECTED}
            category={CardCategory.LIST}
          />}
          {type === ImportFromEventType.CLASSES && selectedClasses.map((adminClass) => {
            return (
              <AdminClassCard
                key={`selected_class__card${adminClass.IDi}`}
                type={AdminClassCardType.IMPORT_CLASS_SELECTED}
                category={CardCategory.LIST}
                adminEventClass={adminClass}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const importFromEventSelector = makeFormModalPropSelector(ModalTypes.IMPORT_FROM_EVENT, 'importFromEventType');
  const selectedEventSelector = makeSelectedEventSelector();
  const selectedClassesSelector = makeSelectedClassesSelector();
  
  const selectedEvent = selectedEventSelector(state);

  if (!selectedEvent) {
    captureTentarooError(new Error("selectedEvent undefined in ImportFromEvent modal, Confirmation step"));
  }
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    modalState: state.adminEvents.events.modals.importFromEvent,
    type: importFromEventSelector(state),
    selectedEvent: selectedEventSelector(state),
    selectedClasses: selectedClassesSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
  }, dispatch),
});

const ConnectedImportFromEventConfirmation = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ImportFromEventConfirmation);

export default ConnectedImportFromEventConfirmation;