import * as React from 'react';
import {
  Modal, ModalActions, Button, ModalContent, ModalHeader, SimpleList, GroupCard, PageLoader, EmptyMessage } from '../../../../../Elements';
import {withRouter, RouteComponentProps} from "react-router";
import {bindActionCreators} from "redux";
import {actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import {actionCreators as rollbackActionCreators} from "../../../../../../store/Rollback/actions";
import {actionCreators} from "../../../../../../store/AdminEvents/Events/Modals/MultipleGroups/actions";
import {actionCreators as cacheTwoEventActionCreators, GetAdminEvent} from "../../../../../../store/AdminEvents/CacheTwoEvent/actions";
import {ApplicationState} from "../../../../../../store";
import '../../../../../../styles/pages/admin-events/events/modals/multiple-groups/index.scss';
import { actionCreators as sendMessageFormActionCreators } from '../../../../../../store/AdminEvents/MessageCenter/Modals/SendMessage/actions';
import { actionCreators as generateInvoicesActionCreators } from '../../../../../../store/AdminEvents/Events/Modals/GenerateInvoices/actions';
import { makeFilteredGroupsSelector } from '../../../../../../store/AdminEvents/Events/Modals/MultipleGroups';
import { CardCategory } from '../../../../../Elements/Card';
import { GroupCardType } from '../../../../../Elements/Group/Card';

import { actionCreators as accountModalActionCreators } from "../../../../../../store/Admin/Modals/Accounts/actions";
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { AdminEventsGroupEventRegistration } from '../../../../../../models/api/adminEventsCacheTwoEvent';
import { extractRouteParams } from '../../../../../../utils/urlHelper';
import { PeopleContactIcon } from '../../../../../Icons';
import { EmptyMessageType } from '../../../../../Elements/EmptyMessage';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { actionCreators as adminEventsSharedActionCreators, GetRegisteredGroupsAction } from '../../../../../../store/AdminEvents/Shared/actions';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

const namespace = () => 'pages--events--modal--multile-groups-modal';

type Props = WithInertAttribute<{}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

@(withRouter as any)
class MultipleGroupsModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  private modalContentRef;

  componentDidMount() {
    const { actions, context, sendMessageForm, generateInvoicesModal } = this.props;
    
    if (shouldBlockActions()) return;

    if (context === 'send-message') {
      actions.init(sendMessageForm.ActiveForm.SelectedGroups || []);
    } else if (context === 'generate-invoices') {
      actions.init(generateInvoicesModal.ActiveForm.SelectedGroups || []);
    }
  }

  componentWillUnmount() {
    this.onClose();
  }
  
  onContinue = (e?: any) => {
    if (e) e.stopPropagation();
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };


  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    if (this.props.modalState.filterString) {
      this.scrollToTop();
    }
    this.props.actions.toggleFilter(false);
    this.props.actions.filterMultipleGroups('');
  };
  
  onClose = () => {
    this.props.actions.reset();
  };
  onSave = () => {
    const {actions, sendMessageFormActions, generateInvoicesActions, modalState, context} = this.props;
    if (context === 'send-message') {
      sendMessageFormActions.addGroups(modalState.ActiveForm.SelectedGroupIds);
    } else if (context === 'generate-invoices') {
      generateInvoicesActions.addGroups(modalState.ActiveForm.SelectedGroupIds);
    }
    this.onClose();
    this.props.actions.popModal(false, true, ModalTypes.MULTIPLE_GROUPS);
  };
  onRefreshClick = () => {
    const {actions, cacheTwoEvent, cacheOne, selectedEventId} = this.props;

    if (cacheTwoEvent.EventsEvent) {
      actions.saveState();
      actions.refreshEvent({
        GetGroupEventRegistrations: true,
        EventIDi: selectedEventId,
        ...extractRouteParams(this.props),
      }, false);
    } else if (cacheOne.EventsEventType && selectedEventId !== undefined) {
      actions.getRegisteredGroups(cacheOne.EventsEventType.EventTypeRow.ID, selectedEventId);
    }
  };

  renderEmptyMessage = () => {
    return (
      <EmptyMessage
        icon={PeopleContactIcon}
        admin
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={ENTITY_NOT_FOUND('groups')}
      /> 
    );
  };

  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filterMultipleGroups(val);
  };

  onSelectAll = () => {
    const {filteredGroups} = this.props;
    this.props.actions.selectAll(filteredGroups.filter((g) => !!g).map((g) => g.GroupIDi));
  };

  onDeselect = () => {
    const {filteredGroups} = this.props;
    this.props.actions.deselect(filteredGroups.map((g) => g.GroupIDi));
  };

  itemRenderer = (index: number) => {
    const { filteredGroups } = this.props;
    const group = filteredGroups[index];
    return (
      <GroupCard
        group={group}
        category={CardCategory.LIST_MOBILE}
        type={GroupCardType.MULTIPLE_SELECT}
        onMultipleSelect={this.onGroupSelect}
        checked={!!group.selected}
      />
    );
  };
  onGroupSelect = (group: AdminEventsGroupEventRegistration) => {
    this.props.actions.toggleGroupSelect(!group.selected, group.GroupIDi);
  };

  public render() {
    const {modalState: {ActiveForm, ValidationRules, isTextSearching}, apiLoadingMap, actions, inert, apiLoading, filteredGroups, cacheTwoEvent} = this.props;
    const loading = cacheTwoEvent.EventsEvent ? apiLoadingMap[GetAdminEvent.requestType] : apiLoadingMap[GetRegisteredGroupsAction.requestType];

    return (
      <Modal inert={inert} onClose={this.onClose} className={`${namespace()}`} mobileFullScreen bodyScrollLock big higherZIndex>
        <ModalHeader
          isSearching={isTextSearching}
          onRefresh={this.onRefreshClick}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={this.onSearchChange}
          onSearchBack={this.onSearchBack}
          className={`${namespace()}--header`}>
            Select Groups
        </ModalHeader>
        <ModalContent
          paddingLeft={0}
          paddingRight={0}
          paddingTop={12}
          paddingBottom={12}
          refCallback={(ref) => this.modalContentRef = ref}
        >
          {!loading && filteredGroups && filteredGroups.length > 0 && (
            <SimpleList
              itemRenderer={this.itemRenderer}
              totalLength={filteredGroups ? filteredGroups.length : 0}
            />
          )}
          {!loading && (!filteredGroups || filteredGroups.length === 0 && this.renderEmptyMessage())}
          {loading && <PageLoader className={`${namespace()}--page--loader`} />}
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button textColor='black' disabled={loading} flat onClick={this.onSave}>SAVE</Button>}
          right={<div className={`${namespace()}--right-actions`}>
            {ActiveForm.SelectedGroupIds.length > 0 ? <Button textColor='black' disabled={loading} flat onClick={this.onDeselect}>DESELECT</Button> : null}
            <Button textColor='black' disabled={loading} flat onClick={this.onSelectAll}>SELECT ALL</Button>
          </div>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredGroupsSelector = makeFilteredGroupsSelector();
  const contextSelector = makeFormModalPropSelector(ModalTypes.MULTIPLE_GROUPS, 'multipleGroupsContext');
  const selectedEventIdSelector = makeFormModalPropSelector(ModalTypes.MULTIPLE_GROUPS, 'selectedEventId');

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    cacheOne: state.adminEvents.cacheOne,
    modalState: state.adminEvents.events.modals.multipleGroups,
    sendMessageForm: state.adminEvents.messageCenter.modals.sendMessage,
    generateInvoicesModal: state.adminEvents.events.modals.generateInvoices,
    cacheTwoEvent: state.adminEvents.cacheTwoEvent,
    filteredGroups: filteredGroupsSelector(state),
    context: contextSelector(state),
    selectedEventId: selectedEventIdSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...actionCreators,
    ...cacheTwoEventActionCreators,
    ...adminEventsSharedActionCreators,
    ...rollbackActionCreators,
  }, dispatch),
  accountActions: bindActionCreators({
    ...accountModalActionCreators,
  }, dispatch),
  sendMessageFormActions: bindActionCreators({
    ...sendMessageFormActionCreators,
  }, dispatch),
  generateInvoicesActions: bindActionCreators({
    ...generateInvoicesActionCreators,
  }, dispatch),
});

const ConnectedMultipleGroupsModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(MultipleGroupsModal);

export default ConnectedMultipleGroupsModal;
