import * as React from 'react';
import { Button } from 'react-mdl';
import { namespace as ns } from './constants';
import '../../../styles/elements/card/show-more.scss';
import styles from '../../../styles';
import { isMobile } from '../../../utils/isMobile';
import { generateDOMId } from '../../../utils/cypressHelper';

const namespace = (): string => `${ns()}--show-more`;

interface Props {
  className?: string;
  onClick?: () => void;
  showLess?: boolean;
}

const ShowMore: React.SFC<Props> = ({ onClick, showLess, className }: Props): React.ReactElement<Props> => (
  <div className={styles(namespace(), className)}>
    <Button
      id={generateDOMId("show-more")}
      ripple={!isMobile}
      className={`${namespace()}--button`}
      onClick={onClick}
    >
      {showLess ? 'Show less' : 'Show more'}
    </Button>
  </div>
);

export default ShowMore;
