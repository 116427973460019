
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetPage} from "./actions";
import { getPage, getPageBody } from "../../../constants/urls";

export const adminCMSSiteCacheTwoPageEpic = makeRequestEpic(
  GetPage,
  getPage,
  getPageBody,
  true,
);