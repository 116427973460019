import {
  createValidateActions,
  createApiValidateActions,
  createUpdateValueMethod,
  createSimpleUpdateValueMethod,
} from "../../../Validation/actionCreator";
import { ActionCreator, ApplicationState } from '../../..';
import { ADMIN_FACILITY_MODULE_SUFFIX } from "../../../AdminFacilityLocation/CacheOne/actions";
import { FacilitiesBlackoutDate } from "../../../../models/api/adminFacilitiesCacheOne";
import { typeName, Action } from "../../../../utils/StrongActions";

export const SUFFIX = ADMIN_FACILITY_MODULE_SUFFIX + '__BLACKOUT_DATES_HOME';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

@typeName('CONFIG_DELETE_BLACKOUT_DATE_MODAL' + SUFFIX)
export class ConfigDeleteBlackoutDateModal extends Action {
  constructor(public blackoutDate: FacilitiesBlackoutDate) { super(); }
}

@typeName('RESET' + SUFFIX)
export class ResetBlackoutDateHome extends Action {}


export type Actions = typeof actionCreators;
export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminFacilityLocation.blackoutDates.home),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  configDeleteBlackoutDateModal: (blackoutDate: FacilitiesBlackoutDate): ActionCreator => dispatch => {
    dispatch(new ConfigDeleteBlackoutDateModal(blackoutDate));
  },
  reset: (): ActionCreator => dispatch => dispatch(new ResetBlackoutDateHome()),
};