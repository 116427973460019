import {ChangePasswordInit, SUFFIX} from './actions';
import {FormDefinition, IValidator} from "./validation";
import {apiValidationReducerCreator} from "../../Validation/reducerCreator";
import { isActionType } from '../../../utils/StrongActions';
import { AnyAction, Reducer } from "redux";
import { WithRootState } from '../../Validation/actionCreator';

export interface EditPasswordForm {
  CurrentPassword?: string;
  NewPassword?: string;
  ConfirmNewPassword?: string;
}

export interface ChangePasswordState {
  ActiveForm: EditPasswordForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
}
const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const getInitialState = (): ChangePasswordState => {
  return {
    ActiveForm: {
    }, ValidationRules: {...FormDefinition}};
};

const EditPassword: Reducer<ChangePasswordState> = (oldState, action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, action);

  if (isActionType(action, ChangePasswordInit)) {
    return {
      ...getInitialState()
    };
  }

  return state || { ...getInitialState()};
};

export default EditPassword;
