import { SELECT_NOT_GREATER_THAN } from '@tentaroo/core';

import * as M from '../../constants/messages/createAccount';
import * as GM from '../../constants/messages/generic';
import { addGroupCheckUsername } from '../../constants/urls';
import { CouncilIDiDefaultValue } from '../../utils/validator';
import { createSelector } from 'reselect';
import {MAX_LENGTH, MIN_LENGTH} from "../../constants/messages/generic";
import { USERNAME_REGEX } from '../../utils/stringHelper';
import { districtsSelector } from '../CacheZero/selectors';
import { getUnitGenderCustomValidate, getUnitGenderOptionValues, getValues, isUnitGenderIDShown } from './validationHelpers';
import { ApiValidator, SelectValidator, Validator } from '../../utils/validator/models';
import {isCacheZeroPopulated} from '../../utils/cachePopulatedCheckers/endUser';

export interface IValidator {
  Username: ApiValidator;
  Password: Validator;
  ConfirmPassword: Validator; // client only
  Email: Validator;

  GroupTypeID: SelectValidator;
  UnitTypeID: SelectValidator;
  UnitGenderID: SelectValidator;
  Unit: Validator;
  CouncilIDi: SelectValidator;
  DistrictIDi: SelectValidator;

  Suffix: Validator;
  FirstName: Validator;
  LastName: Validator;

  PhoneNumber: Validator;
  PhoneNumber2: Validator; // alternate phone number
  Address: Validator;
  Address2: Validator;
  StateID: SelectValidator;
  City: Validator;
  Zip: Validator;

  Contact2Name: Validator;
  Contact2Phone: Validator;
  Contact2Email: Validator;

  AllowOnlineBooking: Validator;
  UnitSponsor: Validator; // text; show only for unit (1) max 100
  Organization: Validator; // show only if Non-profit (4); max 100
  PricingTypeID: SelectValidator; // dropdown; admin visible only; use admin options
  FacilityPricingTypeID: SelectValidator; // dropdown; admin visible only; use admin options
  MI: Validator; // always show; max 2
  MarketingSource: Validator; // how did you hear about us? max 1024
  Inactive: Validator;
}

const filterCouncilSelector = state => state.addGroup.ActiveForm.CouncilIDi;

export const makeFilteredDistrictSelector = () => {
  return createSelector(
    [districtsSelector, filterCouncilSelector],
    (districts, filter) => {
      if (filter === undefined || filter === null) return districts;
      return districts.filter(district => district.CouncilIDi === filter || district.CouncilIDi === 0);
    }
  );
};

const filteredDistrictSelector = makeFilteredDistrictSelector();

export const getDefaultTier = (activeForm, systemSettings, pricingArray, getDefaultCouncilIDi) => {
  // WHEN @GroupTypeID <> 1 AND [HAS THIRD TIER] AND [THIRD TIER].IsDefaultForNonUnit THEN [THIRD TIER]
  // WHEN @GroupTypeID <> 1 THEN 1
  // WHEN @CouncilIDi IS NOT NULL AND @CouncilIDi = @SystemCouncilIDi THEN 1
  // ELSE 2 END,
  if (activeForm.GroupTypeID !== 1 && pricingArray.length === 3 && pricingArray[2].IsDefaultForNonUnit) {
    return pricingArray[2].ID;
  } else if (activeForm.GroupTypeID !== 1) {
    return pricingArray[0].ID;
  } else if (activeForm.CouncilIDi === systemSettings.CouncilIDi || (activeForm.CouncilIDi === undefined && getDefaultCouncilIDi() === systemSettings.CouncilIDi)) {
    return pricingArray[0].ID;
  }
  return pricingArray[1].ID;

};

export const FormDefinition: IValidator = {
  Username: {
    key: 'Username',
    apiCheck: {
      url: addGroupCheckUsername, // See Postman > Groups > Update Group - Check Username for existing group, use updateGroup to check username
      body: (rootState, val) => {
        if (!rootState.session.SystemSettings) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side

          return {};
        }
        return {CampID: rootState.session.SystemSettings.CampID, Username: val, IDi: 0};
      },
      determineError: (response) => {
        if (response.status === 200) {
          return false; // there is no error
        }
        return [M.USERNAME_TAKEN];
      },
      lastValue: ''
    },
    customizedRegexOnly: USERNAME_REGEX,
    validatejs: {
      Username: {
        presence: {message: '^' + GM.REQUIRED},
        length: {
          minimum: 3,
          maximum: 50,
          tooLong: MAX_LENGTH(50),
          tooShort: MIN_LENGTH(3)
        },
        format: {
          pattern: USERNAME_REGEX,
          flags: "i",
          message: '^' + GM.INVALID,
        }
      }
    }
  },
  Password: {
    key: 'Password',
    equalityDependant: 'ConfirmPassword',
    validatejs: {
      Password: {
        presence: {message: '^' + GM.REQUIRED},
        length: {
          minimum: 8,
          maximum: 50,
          tooLong: MAX_LENGTH(50),
          tooShort: MIN_LENGTH(8)
        }
      }
    }
  },
  ConfirmPassword: {
    key: 'ConfirmPassword',
    validatejs: {
      ConfirmPassword: {
        presence: {message: '^' + GM.REQUIRED},
        equality: {
          attribute: "Password",
          message: '^' + M.PASSWORD_MATCH
        },
        length: {
          minimum: 8,
          maximum: 50,
          tooLong: MAX_LENGTH(50),
          tooShort: MIN_LENGTH(8)
        }
      }
    }
  },
  Email: {
    key: 'Email',
    validatejs: {
      Email: {
        presence: {message: '^' + GM.REQUIRED},
        email: {message: '^' + GM.INVALID},
        length: {
          minimum: 3,
          maximum: 50,
          tooLong: MAX_LENGTH(50),
          tooShort: MIN_LENGTH(3)
        }
      }
    }
  },

  GroupTypeID: {
    key: 'GroupTypeID',
    options: {
      values: (rootState) => getValues(rootState, 'GroupTypes'),
      valueKey: (v) => v.ID,
      labelKey: 'Name'
    },
    localDependants: ['UnitTypeID', 'Unit', 'CouncilIDi', 'DistrictIDi', 'UnitSponsor', 'Organization', 'PricingTypeID', 'FacilityPricingTypeID', 'AllowOnlineBooking', 'UnitGenderID'],
    defaultValue: () => {
      return 1;
    },
    validatejs: {
      GroupTypeID: {
        presence: {message: '^' + GM.REQUIRED}
      }
    }
  },
  UnitTypeID: {
    key: 'UnitTypeID',
    options: {
      values: (rootState) => getValues(rootState, 'UnitTypes'),
      valueKey: (v) => v.ID,
      labelKey: 'Name'
    },
    localDependants: ['UnitGenderID'],
    validateLocalDependants: true,
    defaultValue: () => {
      return 1;
    },
    validatejs: {
      UnitTypeID: {
        presence: {message: '^' + GM.REQUIRED}
      }
    }
  },
  UnitGenderID: {
    key: 'UnitGenderID',
    isRequired: () => false,
    skip: (rootState) => !isUnitGenderIDShown(() => rootState.addGroup.ActiveForm),
    options: {
      values: (rootState) => getUnitGenderOptionValues(
        rootState,
        () => rootState.addGroup.ActiveForm,
      ),
      valueKey: (v) => v.ID,
      labelKey: "Name",
      emptyValue: 0,
    },
    customValidate: (rootState) => getUnitGenderCustomValidate(() => rootState.addGroup.ActiveForm),
    defaultValue: () => 0,
  },
  Unit: {
    key: 'Unit',
    defaultValue: () => '',
    isRequired: (rootState) => {
      return rootState.addGroup.ActiveForm.GroupTypeID === 1;
    },
    validatejs: {
      Unit: {
        presence: {message: '^' + GM.REQUIRED},
        length: {
          maximum: 10,
          tooLong: MAX_LENGTH(10)
        }
      }
    }
  },
  CouncilIDi: {
    key: 'CouncilIDi',
    defaultValue: CouncilIDiDefaultValue,
    options: {
      values: (rootState) => [{IDi: 0, Name: ""}, ...getValues(rootState, 'Councils')],
      valueKey: (v) => v.IDi,
      labelKey: 'Name',
      emptyValue: 0,
    },
    localDependants: ['DistrictIDi', 'PricingTypeID', 'FacilityPricingTypeID'],
    isRequired: () => true,
    skip: (rootState) => rootState.addGroup.ActiveForm.GroupTypeID !== 1,
    validatejs: {
      CouncilIDi: {
        presence: {message: '^' + GM.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        },
      },
    }
  },
  DistrictIDi: {
    key: 'DistrictIDi',
    options: {
      values: (rootState) => {
        return filteredDistrictSelector(rootState);
      },
      valueKey: (v) => v.IDi,
      labelKey: 'Name'
    },
    defaultValue: () => 1,
    isRequired: () => {
      // NOTE: Not required on admin side
      return false;
    },
  },

  Suffix: {
    key: 'Suffix',
    validatejs: {
      Suffix: {
        length: {
          maximum: 6,
          tooLong: MAX_LENGTH(6),
        }
      }
    },
  },
  FirstName: {
    key: 'FirstName',
    validatejs: {
      FirstName: {
        presence: {message: '^' + GM.REQUIRED},
        length: {
          maximum: 20,
          tooLong: MAX_LENGTH(20),
        }
      }
    },
  },
  LastName: {
    key: 'LastName',
    validatejs: {
      LastName: {
        presence: {message: '^' + GM.REQUIRED},
        length: {
          maximum: 50,
          tooLong: MAX_LENGTH(50),
        }
      }
    }
  },

  PhoneNumber: {
    key: 'PhoneNumber',
    isRequired: (rootState) => {
      if (!rootState.session.SystemSettings) {
        // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
        return false;
      }
      
      return rootState.session.SystemSettings.RequireGroupPhone;
    },
    validatejs: {
      PhoneNumber: {
        presence: {message: '^' + GM.REQUIRED},
        length: {
          maximum: 20,
          tooLong: MAX_LENGTH(20),
        }
      }
    }
  },
  PhoneNumber2: {
    key: 'PhoneNumber2',
    validatejs: {
      PhoneNumber2: {
        length: {
          maximum: 20,
          tooLong: MAX_LENGTH(20),
        }
      }
    }
  },
  Address: {
    key: 'Address',
    isRequired: (rootState) => {
      if (!rootState.session.SystemSettings) {
        // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
        return false;
      }
      
      return rootState.session.SystemSettings.RequireGroupAddress;
    },
    validatejs: {
      Address: {
        presence: {message: '^' + GM.REQUIRED},
        length: {
          maximum: 30,
          tooLong: MAX_LENGTH(30),
        }
      }
    }
  },
  Address2: {
    key: 'Address2',
    validatejs: {
      Address2: {
        length: {
          maximum: 30,
          tooLong: MAX_LENGTH(30),
        }
      }
    }
  },
  StateID: {
    key: 'StateID',
    defaultValue: (rootState) => {
      if (!rootState.session.SystemSettings) {
        // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
        return -1;
      }
      return rootState.session.SystemSettings.StateID;
    },
    isRequired: (rootState) => {
      if (!rootState.session.SystemSettings) {
        // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
        return false;
      }
      
      return rootState.session.SystemSettings.RequireGroupAddress;
    },
    options: {
      values: (rootState) => [{ Name: '', ID: -1 }, ...getValues(rootState, 'StateOptions')],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: -1
    },
    validatejs: {
      StateID: {
        presence: {message: '^' + GM.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0
        }
      }
    }
  },
  City: {
    key: 'City',
    isRequired: (rootState) => {

      if (!rootState.session.SystemSettings) {
        // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
        return false;
      }
      
      return rootState.session.SystemSettings.RequireGroupAddress;
    },
    validatejs: {
      City: {
        presence: {message: '^' + GM.REQUIRED},
        length: {
          maximum: 30,
          tooLong: MAX_LENGTH(30),
        }
      }
    }
  },
  Zip: {
    key: 'Zip',
    isRequired: (rootState) => {
      if (!rootState.session.SystemSettings) {
        // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
        return false;
      }
      
      return rootState.session.SystemSettings.RequireGroupAddress;
    },
    validatejs: {
      Zip: {
        presence: {message: '^' + GM.REQUIRED},
        length: {
          maximum: 10,
          tooLong: MAX_LENGTH(10),
        }
      }
    }
  },
  Contact2Name: {
    key: 'Contact2Name',
    validatejs: {
      Contact2Name: {
        length: {
          maximum: 200,
          tooLong: MAX_LENGTH(200),
        }
      }
    }
  },
  Contact2Email: {
    key: 'Contact2Email',
    // isRequired: () => false,
    validatejs: {
      Contact2Email: {
        email: {message: '^' + GM.INVALID},
        length: {
          minimum: 3,
          maximum: 50,
          tooLong: MAX_LENGTH(50),
          tooShort: MIN_LENGTH(3)
        }
      }
    }
  },
  Contact2Phone: {
    key: 'Contact2Phone',
    validatejs: {
      Contact2Phone: {
        length: {
          maximum: 20,
          tooLong: MAX_LENGTH(20),
        }
      }
    }
  },


  UnitSponsor: {
    key: 'UnitSponsor', // only show is GroupTypeID is 1
    defaultValue: () => '',
    validatejs: {
      UnitSponsor: {
        length: {
          maximum: 100,
          tooLong: MAX_LENGTH(100),
        }
      }
    }
  },
  Organization: {
    key: 'Organization', // only show is GroupTypeID is 4
    isRequired: (rootState) => {
      return rootState.addGroup.ActiveForm.GroupTypeID === 4;
    },
    defaultValue: () => '',
    validatejs: {
      Organization: {
        presence: {message: '^' + GM.REQUIRED},
        length: {
          maximum: 100,
          tooLong: MAX_LENGTH(100),
        }
      }
    }
  },
  PricingTypeID: {
    key: 'PricingTypeID',
    defaultValue: (rootState) => {
      const {cacheZero, addGroup, session} = rootState;
      const form = addGroup.ActiveForm;
      const settings = session.SystemSettings;

      if (isCacheZeroPopulated(cacheZero) && cacheZero.options) return getDefaultTier(form, settings, cacheZero.options.EventPricingTypes, FormDefinition.CouncilIDi.defaultValue);
      return 1;
    },
    options: {
      values: (rootState) => getValues(rootState, 'EventPricingTypes'),
      valueKey: (v) => v.ID,
      labelKey: 'Name'
    },
    validatejs: {
      PricingTypeID: {
        presence: {message: '^' + GM.REQUIRED}
      }
    }
  },
  FacilityPricingTypeID: {
    key: 'FacilityPricingTypeID',
    defaultValue: (rootState) => {
      const {cacheZero, addGroup, session} = rootState;
      const form = addGroup.ActiveForm;
      const settings = session.SystemSettings;

      if (isCacheZeroPopulated(cacheZero) && cacheZero.options) return getDefaultTier(form, settings, cacheZero.options.FacilityPricingTypes, FormDefinition.CouncilIDi.defaultValue);
      return 1;
    },
    options: {
      values: (rootState) => getValues(rootState, 'FacilityPricingTypes'),
      valueKey: (v) => v.ID,
      labelKey: 'Name'
    },
    validatejs: {
      FacilityPricingTypeID: {
        presence: {message: '^' + GM.REQUIRED}
      }
    }
  },
  MI: {
    key: 'MI',
    validatejs: {
      MI: {
        length: {
          maximum: 2,
          tooLong: MAX_LENGTH(2),
        }
      }
    }
  },
  MarketingSource: {
    key: 'MarketingSource',
    validatejs: {
      MarketingSource: {
        length: {
          maximum: 1024,
          tooLong: MAX_LENGTH(1024),
        }
      }
    }
  },
  AllowOnlineBooking: {
    key: 'AllowOnlineBooking',
    defaultValue: (rootState) => rootState.addGroup.ActiveForm.GroupTypeID === 1
  },
  Inactive: {
    key: 'Inactive'
  }

};
