import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";

import { ApplicationState } from '../../../../../../store';
import { actionCreators as adminCMSCacheOneActionCreators, GetSiteCache } from '../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators, EditPageUpdateSiteMenuItemSubmitActions, DeletePageSubmitActions, UpdatePageSubmitActions, GetDefaultForPrimaryEventPageSubmitActions } from '../../../../../../store/AdminCMSSite/Pages/Page/Form/actions';
import { actionCreators as adminCMSCacheTwoPageActionCreators, GetPage } from '../../../../../../store/AdminCMSSite/CacheTwoPages/actions';
import { actionCreators as appActionCreators, SilentCancelAllAction } from '../../../../../../store/App/actions';
import { actionCreators as rollbackActions} from "../../../../../../store/Rollback/actions";
import { FormDefinition } from "../../../../../../store/AdminCMSSite/Pages/Page/Form/validation";
import { LoadingAll, Loader, Row, Column, TextField, Button, DatePicker, Switch, Select, ModalSelect, Link, EmptyMessage, Form, FormActions, ActionButton, Alert } from '../../../../../Elements';
import ContactCard, { AdminCMSContactCardType } from '../../../../../Elements/AdminContact/CMSCard';
import { Main, MainContent } from '../../../../../Layouts';
import '../../../../../../styles/pages/cms-pages/edit-page/index.scss';
import PageInfo from './PageInfo';
import PageList from '../Home/PageList';
import { makeFilteredPagesSelector, makeActivePagesSelector } from '../../../../../../store/AdminCMSSite/Pages/Home';
import { CMSSiteMenuItem, PageTypeIDValue, Location, CMSResource, CMSContact } from '../../../../../../models/api/adminCMSCacheOne';
import { actionCreators as pageHomeActionCreators} from "../../../../../../store/AdminCMSSite/Pages/Home/actions";
import PageSection from './Section';
import { makeSelectedLocationSelector, makePageResourcesSelector, makePageContactsSelector, makeFilteredPhotoGallery1Selector, makeFilteredPhotoGallery2Selector, makeSelectedEventCategorySelector, hasTempThumbnails } from '../../../../../../store/AdminCMSSite/Pages/Page/Form';
import { getPageIcon, getPageUrl, sectionHasError, checkLocationPermission, checkPagePermission, checkSiteMenuPermission, checkContactPermission, checkResourcePermission, getPageIdFromPath, IAdminCMSPageRouterParams } from '../../../../../../utils/helpers/adminCMSPageHelper';
import SiteMenuItem from '../../Menus/SiteMenuItem';
import SiteMenuCard, { MenuCardType } from '../../../../../../components/Elements/CMSSiteMenu/Card';
import * as generic from '../../../../../../constants/messages/adminCMS';
import LocationCard, { LocationCardType } from '../../../../../../components/Elements/CMSLocation/Card';
import { getPagesRootUrl, openSupportForm } from '../../../../../../constants/urls';
import TimePicker from '../../../../../../components/Elements/TimePicker';
import ResourceCard, { ResourceCardType } from '../../../../../../components/Elements/Resource/Card';
import { makeResourceCategoriesMapSelector } from '../../../../../../store/AdminCMSSite/Resources/Modals/ResourceCategory';
import { PageIcon, PeopleContactIcon, OptionsIcon } from '../../../../../../components/Icons';
import ContextMenuComponent from './ContextMenu';
import SocialMediaField from '../../../../../../components/Elements/SocialMediaField';
import { shallowEqual, copyStringToClipboard } from '../../../../../../utils';
import { CMSPageGalleryImage } from '../../../../../../models/api/adminCMSCacheTwoPage';
import Gallery from '../../../../../Elements/Gallery';
import GalleryUpload from '../../../../../Elements/Gallery/GalleryUpload';
import { NotFound } from '../../../../../../components/Pages';
import device from 'current-device';
import CKEditor from '../../../../../../components/Elements/CKEditor';
import { getValidPageGalleryImageTypesString, validateGalleryImage } from '../../../../../../utils/fileHelper';
import * as M from '../../../../../../constants/messages/generic';
import { EmptyMessageType } from '../../../../../../components/Elements/EmptyMessage';
import { CKEDITOR_MODE, DEFAULT_CKEDITOR_CONFIG, registerSourceModeInputEvent } from '../../../../../../utils/ckeditorHelper';
import { CardCategory } from '../../../../../../components/Elements/Card';
import { isMobile } from '../../../../../../utils/isMobile';
import { ModalTypes, noOpenedModals } from '../../../../../../utils/modalHelper';
import AdminCMSCacheManager from '../../../../../../utils/cacheManagers/adminCMSCacheManager';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { hasOngoingSequentialRequest, hasQueuedReduxActions } from '../../../../../../store/App/reducerHelpers';
import { reduxStoreService } from '../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isAdminCMSCacheOnePopulated, isAdminCMSCacheTwoPagePopulated} from '../../../../../../utils/cachePopulatedCheckers/adminCMS';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--page';
const PAGE_SETTINGS_CONTENT = `${namespace()}--page-settings--content`;

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminCMSPageRouterParams, {}>
>;

interface State {
  showFormActions: boolean;
  ckeditorMode: CKEDITOR_MODE;
}

const PAGE_HEADER_HEIGHT = 95;

class EditPage extends ComponentUpdateTemplate<ConnectedProps, State> {
  public props: ConnectedProps;
  private editor: any;
  private routeParamsBeforeUnmount;

  constructor(props) {
    super(props, {
      showFormActions: false,
      ckeditorMode: CKEDITOR_MODE.WYSIWYG,
    });
  }

  public componentDidMount() {
    const {actions} = this.props;
    actions.showAdminPageHeader(false);
    window.addEventListener('scroll', this.scrollHandler);
    this.configRouter();

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        AdminCMSCacheManager.getInstance().loadCacheTwoPage({
          props: this.props,
          isStateNavigated,
          isEdit: true,
        });
      }
    );
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        AdminCMSCacheManager.getInstance().loadCacheTwoPage({
          props: nextProps,
          isEdit: true,
          isStateNavigated,
        });

        if (isStateNavigated) {
          window.scrollTo(0, 0);
        }
      }
    );

    // We treat this as a special modal case where we dont wrap the loader within the template,
    // because we cannot browser back to this side bar, the difference is that we do load for this
    // side bar in onPropsChange whereas we do load for modal in onMount
    if (shouldBlockActions()) return;
    const isStateNavigatedToSiteMenu = (
      ((!this.props.pageForm.editSiteMenuId || this.props.pageForm.editSiteMenuId === -1) && !!nextProps.pageForm.editSiteMenuId && nextProps.pageForm.editSiteMenuId !== -1) ||
      (!this.props.pageForm.showAddSiteMenuSidebar && !!nextProps.pageForm.showAddSiteMenuSidebar)
    );

    if (isStateNavigatedToSiteMenu) {
      AdminCMSCacheManager.getInstance().loadCacheTwoSiteMenuItem({
        props: nextProps,
        isStateNavigated: isStateNavigatedToSiteMenu,
        isEdit: nextProps.pageForm.editSiteMenuId !== -1,
        menuItemIdFromEditPageSideBar: nextProps.pageForm.editSiteMenuId,
        parentContext: "edit-page",
      });
    }
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    const rootState = reduxStoreService().getState();
    if (!!!hasOngoingSequentialRequest(rootState) && hasTempThumbnails(rootState)) {
      this.props.actions.clearThumbnails();
    }

    const isNavigatedFromSiteMenu = (
      ((!this.props.pageForm.editSiteMenuId || this.props.pageForm.editSiteMenuId === -1) && !!prevProps.pageForm.editSiteMenuId && prevProps.pageForm.editSiteMenuId !== -1) ||
      (!this.props.pageForm.showAddSiteMenuSidebar && !!prevProps.pageForm.showAddSiteMenuSidebar)
    );

    if (shouldBlockActions()) return;
    
    if (isNavigatedFromSiteMenu) {
      this.props.actions.saveState();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandler);
    if (this.editor && this.editor.getCommand("maximize").state === 1) {
      // if ckeditor is maximized, minimize it before unloading this page
      this.editor.execCommand('maximize');
    }
    const {actions, adminCMSCacheTwoPage} = this.props;

    const rootState = reduxStoreService().getState();

    if (
      isAdminCMSCacheTwoPagePopulated(adminCMSCacheTwoPage) &&
      this.routeParamsBeforeUnmount &&
      Number(this.routeParamsBeforeUnmount.pageId) > 0 &&
      rootState.adminCMSSite.cacheTwoPage.CMSPage && !rootState.adminCMSSite.cacheTwoPage.CMSPage.originalPageId
    ) {
      actions.clearAdminCMSCacheTwoPage();
    }

    this.resetRouteLeaveHook();
  }

  routerWillLeave = (nextLocation) => {
    const {actions, apiLoading, adminCMSCacheTwoPage, apiSavingMap, params} = this.props;

    this.routeParamsBeforeUnmount = params;
    // Call SilentCancellAll if
    // 1. There are any ongoing sequential request - cancel and clear them
    // 2. OR there are queued redux actions, this could happen even after an image finishes/fails
    //    uploading. So we want to always clear them (TopFloatingAlert) on leaving
    const rootState = reduxStoreService().getState();
    if (hasOngoingSequentialRequest(rootState) || hasQueuedReduxActions(rootState)) {
      // Cancel any ongoing sequential requests (image upload), and also note
      // that this `SilentCancelAll` will reset apiSaving because `isDuringNavigation`
      // param is not set here.
      reduxStoreService().dispatch(new SilentCancelAllAction());
    }
    if (rootState.app.apiLoading === 0) {
      if (isAdminCMSCacheTwoPagePopulated(adminCMSCacheTwoPage)) {
        actions.saveState();
      }
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  configRouter () {
    const {router, routes} = this.props;
    router.setRouteLeaveHook(routes[routes.length - 1], this.routerWillLeave);
  }

  onSectionExpand = (index: number, expand: boolean) => {
    this.props.actions.toggleSectionExpand(index, expand, device.mobile());
  };

  scrollHandler = () => {
    if (window.scrollY >= PAGE_HEADER_HEIGHT && !this.state.showFormActions) {
      this.setState({
        showFormActions: true,
      });
    } else if (window.scrollY < PAGE_HEADER_HEIGHT && this.state.showFormActions) {
      this.setState({
        showFormActions: false,
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState);
  }

  checkPermission = (onSuccess: () => void) => {
    if (!this.props.adminCMSCacheTwoPage.CMSPage) return;
    checkPagePermission(
      () => onSuccess(),
      this.props.adminCMSCacheOne,
      undefined,
      this.props.adminCMSCacheTwoPage,
    );
  };

  onAddSiteMenuClick = (e) => {
    const {pageForm} = this.props;
    e.stopPropagation();
    if (pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    this.checkPermission(
      () => {
        checkSiteMenuPermission(
          () => {
            this.props.actions.toggleAddMenuSidebar(true);
          },
          this.props.adminCMSCacheOne,
        );
      },
    );
  };

  onEditSiteMenuClick = (menuItem: CMSSiteMenuItem) => {
    const {pageForm} = this.props;
    if (pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    this.checkPermission(
      () => {
        checkSiteMenuPermission(
          () => {
            this.props.actions.toggleEditMenuSidebar(menuItem.ID);
          },
          this.props.adminCMSCacheOne,
          true,
        );
      },
    );
  };
  onRemoveSiteMenuClick = (menuItem: CMSSiteMenuItem) => {
    const {pageForm} = this.props;
    if (pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('removing menu items'), undefined, 'orange');
      return;
    }
    this.checkPermission(
      () => {
        checkSiteMenuPermission(
          () => {
            this.props.actions.deleteSiteMenuItem(menuItem, this.props.pageForm.ActiveForm.ID || 0);
          },
          this.props.adminCMSCacheOne,
          true,
        );
      },
    );
  };
  onSiteMenuInactive = () => {
    this.props.actions.toggleEditMenuSidebar(-1);
    this.props.actions.showTopFloatingAlert(generic.INVALID_MENU_ITEM, true, 'orange');
  };
  renderSiteMenus = () => {
    const {pageForm: {ActiveForm}, adminCMSCacheOne, routes} = this.props;
    return (
      <div className={`${namespace()}--menu-section`}>
        <div className={`${namespace()}--menu-section--title`}>Site Navigation</div>
        {ActiveForm.CMSPageSiteMenuItems ? ActiveForm.CMSPageSiteMenuItems.map((item, index) => {
          return <SiteMenuCard
                  disabled={this.isFormDisabled()}
                  key={`edit_page_sitemenu_item_${item.ID}`}
                  menuItem={item}
                  adminCMSCacheOne={adminCMSCacheOne}
                  routes={routes}
                  cardCategory={CardCategory.LIST}
                  type={MenuCardType.EDIT_PAGE}
                  onRemove={this.onRemoveSiteMenuClick}
                  onEdit={this.onEditSiteMenuClick} />;
        }) : null}
        <Button
          id={generateDOMId("admin-page-add-menu")}
          style={{flex: '0 0 auto', height: '36px'}}
          color='white'
          textColor='black'
          onClick={this.onAddSiteMenuClick}>ADD TO MENU</Button>
      </div>
    );
  };
  onSiteMenuItemBack = () => {
    if (this.props.pageForm.showAddSiteMenuSidebar) {
      this.props.actions.toggleAddMenuSidebar(false);
    }
    if (this.props.pageForm.editSiteMenuId && this.props.pageForm.editSiteMenuId > 0) {
      this.props.actions.toggleEditMenuSidebar(-1);
    }
  };

  renderBlogArticlePageForm = () => {
    const {actions, pageForm: {ActiveForm, ValidationRules}} = this.props;

    return (<div className={PAGE_SETTINGS_CONTENT}>
      <Row>
        <Column span={6} mobileSpan={12}>
          <Switch
            label="Featured"
            disabled={this.isFormDisabled()}
            newDesign
            multilineLabel
            onChange={actions.updateValue}
            value={!!ActiveForm.IsFeatured}
            validationRules={ValidationRules.IsFeatured}
          />
        </Column>
      </Row>
      <Row marginTop={16}>
        <Column span={6} mobileSpan={12}>
          <DatePicker
            className={`${namespace()}--advanced-select`}
            label="Publish Date"
            disabled={this.isFormDisabled()}
            value={ActiveForm.StartDate}
            validationRules={ValidationRules.StartDate}
            onSelect={actions.updateValue}
            onChangeRaw={actions.simpleUpdate}
            removeMax
          />
        </Column>
        <Column span={6} mobileSpan={12}>
          <DatePicker
            className={`${namespace()}--advanced-select`}
            label="Expiration Date"
            disabled={this.isFormDisabled()}
            value={ActiveForm.EndDate}
            validationRules={ValidationRules.EndDate}
            onSelect={actions.updateValue}
            onChangeRaw={actions.simpleUpdate}
            removeMax
          />
        </Column>
      </Row>
    </div>);
  };

  renderDistrictPageForm = () => {
    const {cacheZero, pageForm: {ActiveForm, ValidationRules}} = this.props;
    const district = cacheZero.options && cacheZero.options.Districts ? cacheZero.options.Districts.find((d) => d.IDi === ActiveForm.DistrictIDi) : null;

    return district ? <div className={`${namespace()}--page-district--wrapper`}>
      <div className={`${namespace()}--page-district--wrapper--label`}>District</div>
      <div className={`${namespace()}--page-type--wrapper--name`}>{district.Name}</div>
    </div> : null;
  };

  onChangeEventType = (val: any, vObj: any) => {
    const {actions, params, adminCMSCacheTwoPage, pageForm} = this.props;

    if (pageForm.processingImages) {
      actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('changing the Event Type'), undefined, 'orange');
      return;
    }
    actions.updateValueWithSave(val, vObj);

    if (val) {
      actions.getDefaultForPrimaryEventPage({
        EventTypeID: Number(val),
        ID: adminCMSCacheTwoPage.CMSPage ? adminCMSCacheTwoPage.CMSPage.ID : 0,
        params,
      });
    }
  };

  renderEventModulesPageForm = () => {
    const {actions, pageForm: {ActiveForm, ValidationRules}} = this.props;

    return (<div className={PAGE_SETTINGS_CONTENT}>
      <Row marginTop={16}>
        <Column span={6} mobileSpan={12}>
          <Switch
            label="Featured"
            disabled={this.isFormDisabled()}
            newDesign
            multilineLabel
            onChange={actions.updateValue}
            value={!!ActiveForm.IsFeatured}
            validationRules={ValidationRules.IsFeatured}
          />
        </Column>
      </Row>
      <Row marginTop={16}>
        <Column span={12}>
          <Select
            label="Event Type"
            disabled={this.isFormDisabled()}
            onChangeValue={this.onChangeEventType}
            value={ActiveForm.EventTypeID}
            validationRules={ValidationRules.EventTypeID}
            isNumber />
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <Switch
            label="Primary Event Page"
            multilineLabel
            disabled={this.isFormDisabled()}
            newDesign
            onChange={actions.updateValue}
            value={!!ActiveForm.IsPrimaryEventPage}
            validationRules={ValidationRules.IsPrimaryEventPage}
          />
        </Column>
      </Row>
      <div className={`${namespace()}--note regular large`}>{generic.PRIMARY_EVENT_PAGE_NOTE}</div>
      <Row marginTop={8}>
        <Column span={12}>
          <Switch
            label="Registration Buttons"
            multilineLabel
            disabled={this.isFormDisabled()}
            newDesign
            onChange={actions.updateValue}
            value={!!ActiveForm.ShowRegistrationLinks}
            validationRules={ValidationRules.ShowRegistrationLinks}
          />
        </Column>
      </Row>
      <div className={`${namespace()}--note regular large`}>{generic.SHOW_REGISTRATION_LINKS_NOTE}</div>
      <Row marginTop={8}>
        <Column span={12}>
          <Switch
            label="Show Primary Contact From Event"
            disabled={this.isFormDisabled()}
            multilineLabel
            newDesign
            onChange={actions.updateValue}
            value={!!ActiveForm.IncludeModuleContacts}
            validationRules={ValidationRules.IncludeModuleContacts}
          />
        </Column>
      </Row>
      <div className={`${namespace()}--note ${namespace()}--note--margin-bottom regular large`}>{generic.INCLUDE_EVENT_MODULE_CONTACTS_NOTE}</div>
    </div>);
  };
  onRemoveSelectedLocation = () => {
    this.checkPermission(
      () => {
        this.props.actions.updateValue(undefined, FormDefinition.LocationID);
      },
    );
  };
  onEditSelectedLocation = (location: Location) => {
    if (!location.Inactive) {
      checkLocationPermission(
        () => {
          this.props.actions.pushFormModal(ModalTypes.LOCATION_FORM, true, {id: location.ID});
        },
        this.props.cacheZero,
        location,
        true,
      );
    }
  };

  onClickSelectLocationButton = (isFacilities?: boolean) => {
    this.checkPermission(
      () => {
        this.props.actions.pushSelectLocationModal({context: 'admin-cms-edit-page', isFacilities: !!isFacilities});
      },
    );
  };
  renderFacilityModulesPageForm = () => {
    const {actions, selectedLocation, pageForm: {ActiveForm, ValidationRules}} = this.props;
    return (<div className={PAGE_SETTINGS_CONTENT}>
      <Row marginTop={16}>
        <Column span={12} mobileSpan={12}>
          <ModalSelect
            label="Facility Location"
            disabled={this.isFormDisabled()}
            selectButtonLabel="SELECT LOCATION"
            hideError={!!selectedLocation}
            selectedCard={selectedLocation ? <LocationCard
              showDeletedPrompt
              disabled={this.isFormDisabled()}
              location={selectedLocation}
              type={LocationCardType.MODAL_SELECT}
              cardCategory={CardCategory.LIST}
              onRemove={this.onRemoveSelectedLocation}
              onEdit={this.onEditSelectedLocation}
            /> : null}
            validationRules={ValidationRules.LocationID}
            onClickSelectButton={() => this.onClickSelectLocationButton(true)}
            value={ActiveForm.LocationID}/>
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <Switch
            label="Show Primary Contact From Facility Location"
            disabled={this.isFormDisabled()}
            multilineLabel
            newDesign
            onChange={actions.updateValue}
            value={!!ActiveForm.IncludeModuleContacts}
            validationRules={ValidationRules.IncludeModuleContacts}
          />
        </Column>
      </Row>
      <div className={`${namespace()}--note ${namespace()}--note--margin-bottom regular large`}>{generic.INCLUDE_FACILITY_MODULE_CONTACTS_NOTE}</div>
    </div>);
  };

  renderCalendarEventPageForm = () => {
    const {actions, selectedLocation, pageForm: {ActiveForm, ValidationRules}, selectedEventCategory} = this.props;

    return (<div className={PAGE_SETTINGS_CONTENT}>
      <Row marginTop={16}>
        <Column span={6} mobileSpan={12}>
          <Switch
            label="Featured"
            disabled={this.isFormDisabled()}
            multilineLabel
            newDesign
            onChange={actions.updateValue}
            value={!!ActiveForm.IsFeatured}
            validationRules={ValidationRules.IsFeatured}
          />
        </Column>
      </Row>
      <Row marginTop={16}>
        <Column span={12} mobileSpan={12}>
          <ModalSelect
            label="Event Location"
            disabled={this.isFormDisabled()}
            paddingBottom={24}
            mobilePaddingBottom={16}
            hideError={!!selectedLocation}
            selectButtonLabel="SELECT LOCATION"
            selectedCard={selectedLocation ? <LocationCard
              showDeletedPrompt
              disabled={this.isFormDisabled()}
              location={selectedLocation}
              type={LocationCardType.MODAL_SELECT}
              cardCategory={CardCategory.LIST}
              onRemove={this.onRemoveSelectedLocation}
              onEdit={this.onEditSelectedLocation}
            /> : null}
            validationRules={ValidationRules.LocationID}
            onClickSelectButton={this.onClickSelectLocationButton}
            value={ActiveForm.LocationID}/>
        </Column>
      </Row>
      <Row>
        <Column span={6} mobileSpan={12}>
          <Select
            label="Event Category"
            disabled={this.isFormDisabled()}
            onChangeValue={actions.updateValue}
            value={ActiveForm.EventCategoryID}
            validationRules={ValidationRules.EventCategoryID}
            info={<div className={`${namespace()}--card--help-text`}>To add a new category, please <Link black medium externalLink={openSupportForm(true)}>contact Tentaroo</Link></div>}
            isNumber />
        </Column>
        {selectedEventCategory && selectedEventCategory.IsDistricts && <Column span={6} mobileSpan={12}>
          <Select
            label="District"
            disabled={this.isFormDisabled()}
            onChangeValue={actions.updateValue}
            value={ActiveForm.DistrictIDi}
            validationRules={ValidationRules.DistrictIDi}
            isNumber />
        </Column>}
      </Row>
      <Row marginTop={16}>
        <Column span={6} mobileSpan={12}>
          <Switch
            className={`${namespace()}--center-switch`}
            label="All-Day Event"
            multilineLabel
            disabled={this.isFormDisabled()}
            newDesign
            onChange={actions.updateValue}
            value={!!ActiveForm.IsAllDay}
            validationRules={ValidationRules.IsAllDay}
          />
        </Column>
      </Row>
      <Row marginTop={16}>
        <Column span={6} mobileSpan={12}>
          <DatePicker
            label="Start Date"
            disabled={this.isFormDisabled()}
            value={ActiveForm.StartDate}
            validationRules={ValidationRules.StartDate}
            onSelect={actions.updateValue}
            onChangeRaw={actions.simpleUpdate}
            removeMax
          />
        </Column>
        {!ActiveForm.IsAllDay ? <Column span={6} mobileSpan={12}>
          <TimePicker
            label="Time"
            customizedId='startDateTime'
            disabled={this.isFormDisabled()}
            onChangeValue={actions.updateValue}
            hourValue={ActiveForm.StartHour}
            minValue={ActiveForm.StartMin}
            periodValue={ActiveForm.StartPeriod}
            hourValidationRules={ValidationRules.StartHour}
            minValidationRules={ValidationRules.StartMin}
            periodValidationRules={ValidationRules.StartPeriod}
          />
        </Column> : null}
      </Row>
      <Row>
        <Column span={6} mobileSpan={12}>
          <DatePicker
            label="End Date"
            disabled={this.isFormDisabled()}
            value={ActiveForm.EndDate}
            validationRules={ValidationRules.EndDate}
            onSelect={actions.updateValue}
            onChangeRaw={actions.simpleUpdate}
            removeMax
          />
        </Column>
        {!ActiveForm.IsAllDay ? <Column span={6} mobileSpan={12}>
          <TimePicker
            label="Time"
            disabled={this.isFormDisabled()}
            customizedId='endDateTime'
            onChangeValue={actions.updateValue}
            hourValue={ActiveForm.EndHour}
            minValue={ActiveForm.EndMin}
            periodValue={ActiveForm.EndPeriod}
            hourValidationRules={ValidationRules.EndHour}
            minValidationRules={ValidationRules.EndMin}
            periodValidationRules={ValidationRules.EndPeriod}
          />
        </Column> : null}
      </Row>
      <Row>
        <Column span={6} mobileSpan={12}>
          <Select
            label="Owner"
            disabled={this.isFormDisabled()}
            onChangeValue={actions.updateValue}
            value={ActiveForm.SiteID}
            validationRules={ValidationRules.SiteID}
            isNumber />
        </Column>
      </Row>
    </div>);
  };
  renderPageMenus = () => {
    const {pageForm: {ActiveForm}, adminCMSCacheOne, routes} = this.props;
    if (!ActiveForm.MenuOnPage) return null;
    return (
      <div className={`${namespace()}--menu-section page-menu`}>
        <div className={`${namespace()}--menu-section--title page-menu`}>Menu on Page</div>
        <div className={`${namespace()}--menu-section--subtitle`}>To change the page menu shown on this page, go to Menus, then Menus On Pages under this site.</div>
        <SiteMenuCard
          disabled={this.isFormDisabled()}
          menuItem={{Name: ActiveForm.MenuOnPage} as any}
          adminCMSCacheOne={adminCMSCacheOne}
          routes={routes}
          cardCategory={CardCategory.LIST}
          type={MenuCardType.EDIT_PAGE_PAGE_MENU} />
      </div>
    );
  };

  renderPageSettings = () => {
    const {actions, adminCMSCacheOne, adminCMSCacheTwoPage, pageForm: {ActiveForm, ValidationRules, mobileExpandSectionIndex, sectionExpand}} = this.props;

    if (!adminCMSCacheTwoPage.CMSPage || !adminCMSCacheOne.CMSSite) return null;

    const pageType = adminCMSCacheOne.PageTypes ? adminCMSCacheOne.PageTypes.find((p) => !!adminCMSCacheTwoPage.CMSPage && p.ID === adminCMSCacheTwoPage.CMSPage.PageTypeID) : undefined;
    const keysToValidate = [
      'Name',
      'ShortURL',
    ];
    if (ActiveForm.PageTypeID === PageTypeIDValue.BLOG_ARTICLE) {
      keysToValidate.push('IsFeatured', 'StartDate', 'EndDate');
    } else if (ActiveForm.PageTypeID === PageTypeIDValue.EVENT_MODULES) {
      keysToValidate.push('IsFeatured', 'EventTypeID', 'IsPrimaryEventPage', 'ShowRegistrationLinks', 'IncludeModuleContacts');
    } else if (ActiveForm.PageTypeID === PageTypeIDValue.FACILITY_MODULES) {
      keysToValidate.push('LocationID', 'IncludeModuleContacts');
    } else if (ActiveForm.PageTypeID === PageTypeIDValue.CALENDAR_EVENT) {
      keysToValidate.push('IsFeatured', 'LocationID', 'EventCategoryID', 'IsAllDay', 'StartDate', 'StartHour', 'StartMin', 'StartPeriod', 'EndDate', 'EndHour', 'EndMin', 'EndPeriod');
    }
    const hasError = sectionHasError(ValidationRules, keysToValidate);
    let pageUrlInfo = `${adminCMSCacheOne.CMSSite.SiteDomain}/`;

    if (adminCMSCacheTwoPage.CMSPage.PageTypeID === PageTypeIDValue.CALENDAR_EVENT && adminCMSCacheTwoPage.CMSPage.SiteID !== adminCMSCacheOne.CMSSite.ID) {
      pageUrlInfo += `${adminCMSCacheTwoPage.CMSPage.SiteID}/`;
    }
    return (
      <PageSection
        expand={device.mobile() ? mobileExpandSectionIndex === 0 : sectionExpand[0]}
        onExpand={(expand) => this.onSectionExpand(0, expand)}
        error={hasError}
        title='Page Settings'>
        {pageType ? <div className={`${namespace()}--page-type--wrapper ${ActiveForm.PageTypeID === PageTypeIDValue.HOMEPAGE ? 'homepage' : ''}`}>
          <div className={`${namespace()}--page-type--wrapper--label`}>Page Type</div>
          <div className={`${namespace()}--page-type--wrapper--content`}>
            <div className={`${namespace()}--page-type--wrapper--content--icon`}>{getPageIcon(adminCMSCacheTwoPage.CMSPage)}</div>
            <div className={`${namespace()}--page-type--wrapper--content--name`}>{ActiveForm.PageTypeID === PageTypeIDValue.HOMEPAGE ? 'Homepage' : pageType.AddingName}</div>
          </div>
        </div> : null}
        <Row>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Page Title"
              id={generateDOMId("admin-page-title-field")}
              disabled={this.isFormDisabled()}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.Name}
              value={ActiveForm.Name}/>
          </Column>
        </Row>
        {ActiveForm.PageTypeID !== PageTypeIDValue.HOMEPAGE ? <Row marginBottom={16}>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Page URL"
              disabled={this.isFormDisabled()}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.ShortURL}
              value={ActiveForm.ShortURL}
              info={ActiveForm.ShortURL ? `${pageUrlInfo}` : 'Set this if you want the page at a specific URL.'}
              appendedBoldInfoTextColor="black"
              appendedBoldInfo={ActiveForm.ShortURL ? ActiveForm.ShortURL : undefined} />
              
          </Column>
        </Row> : null}
        {ActiveForm.PageTypeID === PageTypeIDValue.BLOG_ARTICLE ? this.renderBlogArticlePageForm() : null}
        {ActiveForm.PageTypeID === PageTypeIDValue.DISTRICT ? this.renderDistrictPageForm() : null}
        {ActiveForm.PageTypeID === PageTypeIDValue.EVENT_MODULES ? this.renderEventModulesPageForm() : null}
        {ActiveForm.PageTypeID === PageTypeIDValue.FACILITY_MODULES ? this.renderFacilityModulesPageForm() : null}
        {ActiveForm.PageTypeID === PageTypeIDValue.CALENDAR_EVENT ? this.renderCalendarEventPageForm(): null}
        {ActiveForm.PageTypeID !== PageTypeIDValue.HOMEPAGE && this.renderSiteMenus()}
        {ActiveForm.PageTypeID !== PageTypeIDValue.HOMEPAGE && this.renderPageMenus()}
      </PageSection>
    );
  };

  onTopGalleryUpload = (files: any[]) => {
    this.checkPermission(
      () => {
        if (files.length > 0) {
          files.forEach((file) => {
            if (!validateGalleryImage(file, this.props.actions)) return;
            const reader = new FileReader();
      
            reader.onabort = () => console.log('file reading was aborted for top gallery');
            reader.onerror = () => console.log('file reading has failed for top gallery');
            reader.onload = () => {
              // Do whatever you want with the file contents
              const dataUrl = reader.result;
              // `dataUrl` for local thumbnail (local update), `file` for submit the file (server call)
              this.props.actions.submitTopGalleryImage(file, dataUrl);
            };
            reader.readAsDataURL(file);
          });
        }
      },
    );
  };
  onEditTopGalleryImage = (image: CMSPageGalleryImage) => {
    const {pageForm} = this.props;
    if (pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    this.checkPermission(
      () => {
        this.props.actions.pushModal(ModalTypes.GALLERY_IMAGE_FORM, false, false, {id: image.ID, target: 'top-gallery'});
      },
    );
  };
  onDeleteTopGalleryImage = (image: CMSPageGalleryImage) => {
    this.checkPermission(
      () => {
        this.props.actions.deleteGalleryImage('top', image.ID);
      },
    );
  };
  onRestoreTopGalleryImage = (image: CMSPageGalleryImage) => {
    this.checkPermission(
      () => {
        this.props.actions.deleteGalleryImage('top', image.ID, true);
      }
    );
  };
  onTopGalleryItemSortEnd = (oldIndex: number, newIndex: number, list) => {
    const {pageForm: {ActiveForm}, pageForm} = this.props;
    if (pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('change image order'), undefined, 'orange');
      return;
    }
    this.checkPermission(
      () => {
        const newIndexItem = list[newIndex];
        const oldIndexItem = list[oldIndex];
        const fullListNewIndex = ActiveForm.CMSPageGallery1.findIndex((item) => item.ID === newIndexItem.ID);
        const fullListOldIndex = ActiveForm.CMSPageGallery1.findIndex((item) => item.ID === oldIndexItem.ID);
        const offset = fullListNewIndex - fullListOldIndex;
        this.props.actions.moveTopGalleryItem(oldIndexItem, offset);
      }
    );
  };

  renderTopGallery = () => {
    const {actions, filteredTopGallery, pageForm: {ActiveForm, ValidationRules, sectionExpand, mobileExpandSectionIndex}} = this.props;
    return (
      <PageSection
        expand={device.mobile() ? mobileExpandSectionIndex === 1 : sectionExpand[1]}
        onExpand={(expand) => this.onSectionExpand(1, expand)}
        title='Top Gallery'>
        <div className={`${namespace()}--gallery--row`}>
          <Select
            disabled={this.isFormDisabled()}
            customizedId="topgallerylayout"
            label="Layout"
            onChangeValue={actions.updateValue}
            value={ActiveForm.Photos1LayoutID}
            validationRules={ValidationRules.Photos1LayoutID}
            isNumber />
          <div className={`${namespace()}--gallery--actions`}>
            <Switch
              disabled={this.isFormDisabled()}
              className={`${filteredTopGallery.length > 0 ? 'has-images' : ''}`}
              label="Show Deleted"
              multilineLabel
              newDesign
              onChange={actions.updateValue}
              value={!!ActiveForm.TopGalleryShowDeleted}
              validationRules={ValidationRules.TopGalleryShowDeleted}
            />
            <Button
              className={`${namespace()}--gallery--button ${filteredTopGallery.length > 0 ? 'has-images' : ''}`}
              color="white"
              textColor='black'
              onClick={this.onTopGalleryUpload}>
              UPLOAD
                {this.isFormDisabled() ? null : <input
                  type='file'
                  multiple
                  onChange={(e) => this.onTopGalleryUpload((e.target.files || []) as any[])} />}
            </Button>
          </div>
        </div>
        {filteredTopGallery.length > 0 && !isMobile ? <div className={`${namespace()}--gallery--note`}>You can drag and drop images to change their order</div> : null}
        <Gallery
          id='top-gallery-list'
          helperClass='top-gallery-sort-helper'
          list={filteredTopGallery}
          onGalleryItemSortEnd={this.onTopGalleryItemSortEnd}
          onEditGalleryImage={this.onEditTopGalleryImage}
          onDeleteGalleryImage={this.onDeleteTopGalleryImage}
          onRestoreGalleryImage={this.onRestoreTopGalleryImage}
          />
        <GalleryUpload 
          disabled={this.isFormDisabled()}
          supportedFormat={getValidPageGalleryImageTypesString()}
          hasImages={filteredTopGallery.length > 0}
          onUploadImage={this.onTopGalleryUpload} />
      </PageSection>
    );
  };

  onCKEditorSourceModeInput = (inputEventInfo) => {
    const {pageForm: {ValidationRules}, actions} = this.props;
    actions.simpleUpdate(inputEventInfo.data.$.target.value, ValidationRules.PageContent);
  };

  onCKEditorModeChange = (eventInfo) => {
    if (eventInfo.editor && eventInfo.editor.mode) {
      this.setState({
        ckeditorMode: eventInfo.editor.mode,
      });
      
      registerSourceModeInputEvent(eventInfo, this.onCKEditorSourceModeInput);
    }
  };

  onPageContentChange = (eventInfo) => {
    const {actions, pageForm: {ValidationRules}} = this.props;
    actions.simpleUpdate(eventInfo.editor.getData(), ValidationRules.PageContent);
  };

  onCKEditorReady = (eventInfo) => {
    if (this.editor !== eventInfo.editor) this.editor = eventInfo.editor;
    this.editor.setReadOnly(this.isFormDisabled());
  };

  renderPageContent = () => {
    const {actions, pageForm: {ActiveForm, ValidationRules, sectionExpand, mobileExpandSectionIndex}} = this.props;
    return (
      <PageSection
        expand={device.mobile() ? mobileExpandSectionIndex === 2 : sectionExpand[2]}
        onExpand={(expand) => this.onSectionExpand(2, expand)}
        noPadding
        title='Content'>
        <CKEditor
          config={{...DEFAULT_CKEDITOR_CONFIG, startupMode: this.state.ckeditorMode}}
          data={ActiveForm.PageContent}
          readOnly={this.isFormDisabled()}
          onChange={this.onPageContentChange}
          onMode={this.onCKEditorModeChange}
          onReady={this.onCKEditorReady}
        />
      </PageSection>
    );
  };

  onBottomGalleryUpload = (files: any[]) => {
    this.checkPermission(
      () => {
        if (files.length > 0) {
          files.forEach((file) => {
            if (!validateGalleryImage(file, this.props.actions)) return;
            const reader = new FileReader();
      
            reader.onabort = () => console.log('file reading was aborted for top gallery');
            reader.onerror = () => console.log('file reading has failed for top gallery');
            reader.onload = () => {
            // Do whatever you want with the file contents
              const dataUrl = reader.result;
              this.props.actions.submitBottomGalleryImage(file, dataUrl);
            };
            reader.readAsDataURL(file);
          });
        }
      }
    );
  };
  onEditBottomGalleryImage = (image: CMSPageGalleryImage) => {
    const {pageForm} = this.props;
    if (pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    this.checkPermission(
      () => {
        this.props.actions.pushModal(ModalTypes.GALLERY_IMAGE_FORM, false, false, {id: image.ID, target: 'bottom-gallery'});
      },
    );
  };
  onDeleteBottomGalleryImage = (image: CMSPageGalleryImage) => {
    this.checkPermission(
      () => {
        this.props.actions.deleteGalleryImage('bottom', image.ID);
      },
    );
  };
  onRestoreBottomGalleryImage = (image: CMSPageGalleryImage) => {
    this.checkPermission(
      () => {
        this.props.actions.deleteGalleryImage('bottom', image.ID, true);
      },
    );
  };
  onBottomGalleryItemSortEnd = (oldIndex: number, newIndex: number, list) => {
    const {pageForm: {ActiveForm}, pageForm, actions} = this.props;
    if (pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('change image order'), undefined, 'orange');
      return;
    }
    
    this.checkPermission(
      () => {
        const newIndexItem = list[newIndex];
        const oldIndexItem = list[oldIndex];
        const fullListNewIndex = ActiveForm.CMSPageGallery2.findIndex((item) => item.ID === newIndexItem.ID);
        const fullListOldIndex = ActiveForm.CMSPageGallery2.findIndex((item) => item.ID === oldIndexItem.ID);
        const offset = fullListNewIndex - fullListOldIndex;
        this.props.actions.moveBottomGalleryItem(oldIndexItem, offset);
      }
    );
  };

  renderBottomGallery = () => {
    const {actions, filteredBottomGallery, pageForm: {ActiveForm, ValidationRules, mobileExpandSectionIndex, sectionExpand}} = this.props;
    return (
      <PageSection
        expand={device.mobile() ? mobileExpandSectionIndex === 3 : sectionExpand[3]}
        onExpand={(expand) => this.onSectionExpand(3, expand)}
        title='Bottom Gallery'>
        <div className={`${namespace()}--gallery--row`}>
          <Select
            disabled={this.isFormDisabled()}
            customizedId="bottomgallerylayout"
            label="Layout"
            onChangeValue={actions.updateValue}
            value={ActiveForm.Photos2LayoutID}
            validationRules={ValidationRules.Photos2LayoutID}
            isNumber />
          <div className={`${namespace()}--gallery--actions`}>
            <Switch
              disabled={this.isFormDisabled()}
              className={`${filteredBottomGallery.length > 0 ? 'has-images' : ''}`}
              label="Show Deleted"
              multilineLabel
              newDesign
              onChange={actions.updateValue}
              value={!!ActiveForm.BottomGalleryShowDeleted}
              validationRules={ValidationRules.BottomGalleryShowDeleted}
            />
            <Button
              className={`${namespace()}--gallery--button ${filteredBottomGallery.length > 0 ? 'has-images' : ''}`}
              color="white"
              textColor='black'
              onClick={this.onBottomGalleryUpload}>
              UPLOAD
              {this.isFormDisabled() ? null : <input
                type='file' multiple
                onChange={(e) => this.onBottomGalleryUpload((e.target.files || []) as any[])} />}
            </Button>
          </div>
        </div>
        {filteredBottomGallery.length > 0 && !isMobile ? <div className={`${namespace()}--gallery--note`}>You can drag and drop images to change their order</div> : null}
        <Gallery
          id='bottom-gallery-list'
          helperClass='bottom-gallery-sort-helper'
          list={filteredBottomGallery}
          onGalleryItemSortEnd={this.onBottomGalleryItemSortEnd}
          onEditGalleryImage={this.onEditBottomGalleryImage}
          onDeleteGalleryImage={this.onDeleteBottomGalleryImage}
          onRestoreGalleryImage={this.onRestoreBottomGalleryImage}
          />
        <GalleryUpload
          disabled={this.isFormDisabled()}
          supportedFormat={getValidPageGalleryImageTypesString()}
          hasImages={filteredBottomGallery.length > 0}
          onUploadImage={this.onBottomGalleryUpload} />
      </PageSection>
    );
  };
  onRemoveSocialType = (id: number) => {
    this.props.actions.removeSocial(id);
  };

  onAddSocialType = () => {
    this.checkPermission(
      () => {
        this.props.actions.addSocial();
      },
    );
  };

  renderSocialMedia = () => {
    const {actions, pageForm: {ActiveForm, ValidationRules, mobileExpandSectionIndex, sectionExpand}} = this.props;
    const socialMedias: any[] = [];
    const keysToValidate: string[] = [];
    for (let key of Object.keys(ActiveForm)) {
      if (key.includes("SocialTypeID")) {
        const index = Number(key.replace('SocialTypeID', ''));
        keysToValidate.push(`SocialUser${index}`, `SocialTypeID${index}`);
        socialMedias.push(
          <Row key={`edit_page_ssn_${index}`} className={`${namespace()}--social-media`}>
            <Column span={12} mobileSpan={12}>
              <SocialMediaField
                disabled={this.isFormDisabled()}
                onSocialUserChange={actions.simpleUpdate}
                onSocialUserBlur={actions.updateValue}
                onSocialTypeChange={actions.updateSocialType}
                onCancelClick={() => this.onRemoveSocialType(index)}
                iconType='color'
                socialTypeValue={ActiveForm[`SocialTypeID${index}`]}
                socialUserValue={ActiveForm[`SocialUser${index}`]}
                socialUserValidationRule={(ValidationRules as any)[`SocialUser${index}`]}
                socialTypeValidationRule={(ValidationRules as any)[`SocialTypeID${index}`]} />
            </Column>
          </Row>
        );
      }
    }
    const hasError = sectionHasError(ValidationRules, keysToValidate);
    return (
      <PageSection
        expand={device.mobile() ? mobileExpandSectionIndex === 4 : sectionExpand[4]}
        onExpand={(expand) => this.onSectionExpand(4, expand)}
        error={hasError}
        title='Social Media'>
        {socialMedias}
        <Row marginTop={8} mobileMarginBottom={8}>
          <Column>
            <Button
              className={`${namespace()}`}
              color="white"
              textColor='black'
              onClick={this.onAddSocialType}>ADD ACCOUNT</Button>
          </Column>
        </Row>
      </PageSection>
    );
  };

  renderEmptyContacts = () => {

    return (
      <EmptyMessage
        icon={PeopleContactIcon}
        type={EmptyMessageType.CARD}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description='No contacts selected'
        actions={
          <Button
            className={`${namespace()}--empty-message-btn`}
            color="white"
            textColor='black'
            onClick={this.onManageContactsClick}>ADD CONTACTS</Button>}
      /> 
    );
  };

  onManageContactsClick = (e) => {
    e.stopPropagation();
    const {pageForm} = this.props;
    if (pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    this.checkPermission(
      () => {
        this.props.actions.pushModal(ModalTypes.MULTIPLE_CONTACTS, false, false);
      },
    );
  };
  onEditContactClick = (contact: CMSContact) => {
    const {pageForm} = this.props;
    if (pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    checkContactPermission(
      () => {
        this.props.actions.pushFormModal(ModalTypes.CONTACT_FORM, true, {id: contact.ID});
      },
      this.props.cacheZero,
      this.props.adminCMSCacheOne,
      contact.SiteID,
    );
  };
  onRemoveContactClick = (contact: CMSContact) => {
    this.checkPermission(
      () => {
        this.props.actions.removeContactFromPage(contact.ID);
      },
    );
  };

  renderContacts = () => {
    const {pageForm: {ActiveForm, ValidationRules, mobileExpandSectionIndex, sectionExpand}, pageContacts, actions, routes, adminCMSCacheOne} = this.props;
    return (
      <PageSection
        expand={device.mobile() ? mobileExpandSectionIndex === 5 : sectionExpand[5]}
        onExpand={(expand) => this.onSectionExpand(5, expand)}
        title='Contacts'>
        <Row>
          <Column className={`${namespace()}--contact-layout--col1`} span={6}>
            <Select
              label="Layout"
              disabled={this.isFormDisabled()}
              onChangeValue={actions.updateValue}
              value={ActiveForm.ContactsLayoutID}
              validationRules={ValidationRules.ContactsLayoutID}
              isNumber />
          </Column>
          <Column className={`${namespace()}--contact-layout--col2`} span={6}>
            <Button
              id={generateDOMId("multi-contact-manage-btn")}
              className={`${namespace()}--contacts--manage control`}
              color='white'
              textColor='black'
              onClick={this.onManageContactsClick}>MANAGE</Button>
          </Column>
        </Row>
        {pageContacts && pageContacts.length > 0 && pageContacts.map((c, index) => {
          return (
            <ContactCard
              disabled={this.isFormDisabled()}
              key={`edit_page_contact_${c.ID}`}
              onEdit={this.onEditContactClick}
              onRemove={this.onRemoveContactClick}
              routes={routes}
              adminCMSCacheOne={adminCMSCacheOne}
              contact={c}
              cardCategory={CardCategory.LIST_MOBILE}
              type={AdminCMSContactCardType.EDIT_PAGE}
            />
          );
        })}
        {!pageContacts || pageContacts.length === 0 ? this.renderEmptyContacts() : null}
      </PageSection>
    );
  };

  onManageResourcesClick = (e) => {
    e.stopPropagation();
    const {pageForm} = this.props;
    if (pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    this.checkPermission(
      () => {
        // NOTE: MultipleResources modal will do a save state after init on didMount
        this.props.actions.pushModal(ModalTypes.MULTIPLE_RESOURCES, false, false);
      },
    );
  };

  onEditResourceClick = (resource: CMSResource) => {
    const {pageForm, cacheZero, adminCMSCacheOne} = this.props;
    if (pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    checkResourcePermission(
      () => {
        this.props.actions.pushFormModal(ModalTypes.RESOURCE_FORM, true, {id: resource.ID});
      },
      this.props.cacheZero,
      this.props.adminCMSCacheOne,
      resource.SiteID,
    );
  };
  onRemoveResourceClick = (resource: CMSResource) => {
    this.checkPermission(
      () => {
        this.props.actions.removeResourceFromPage(resource.ID as any);
      },
    );
  };

  renderEmptyResources = () => {

    return (
      <EmptyMessage
        icon={PageIcon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        type={EmptyMessageType.CARD}
        description='No resources selected'
        actions={<Button
          className={`${namespace()}--empty-message-btn`}
          color="white"
          textColor='black'
          onClick={this.onManageResourcesClick}>ADD RESOURCES</Button>}
      /> 
    );
  };

  renderResources = () => {
    const {pageForm: {mobileExpandSectionIndex, sectionExpand}, resourceCategoriesMap, pageResources} = this.props;
    return (
      <PageSection
        expand={device.mobile() ? mobileExpandSectionIndex === 6 : sectionExpand[6]}
        onExpand={(expand) => this.onSectionExpand(6, expand)}
        title='Resources'
        headerControl={
          <Button
            id={generateDOMId("multi-resource-manage-btn")}
            style={{flex: '0 0 auto', height: '36px'}}
            className={`${namespace()}--resource--manage control`}
            color='white'
            textColor='black'
            onClick={this.onManageResourcesClick}>MANAGE</Button>}
        >
          {pageResources && pageResources.length > 0 ? <Button
            style={{flex: '0 0 auto', height: '36px'}}
            className={`${namespace()}--resource--manage--mobile control`}
            color='white'
            textColor='black'
            onClick={this.onManageResourcesClick}>MANAGE</Button> : null}
          {pageResources && pageResources.length > 0 && pageResources.map((r) => {
            return (
              <ResourceCard
                disabled={this.isFormDisabled()}
                key={`edit_page_resource_${r.ID}`}
                onEdit={this.onEditResourceClick}
                onRemove={this.onRemoveResourceClick}
                onCardClick={this.onEditResourceClick}
                categoryName={r.CategoryID ? resourceCategoriesMap[r.CategoryID.toString()].Name : undefined}
                resource={r}
                cardCategory={CardCategory.LIST_MOBILE}
                type={ResourceCardType.EDIT_PAGE}
              />
            );
          })}
          {!pageResources || pageResources.length === 0 ? this.renderEmptyResources() : null}
      </PageSection>
    );
  };

  renderMap = () => {
    const {adminCMSCacheTwoPage, actions, pageForm: {ActiveForm, ValidationRules, mobileExpandSectionIndex, sectionExpand}} = this.props;
    const keysToValidate = ['MapHTML'];
    const hasError = sectionHasError(ValidationRules, keysToValidate);
    return (
      <PageSection
        error={hasError}
        expand={device.mobile() ? mobileExpandSectionIndex === 7 : sectionExpand[7]}
        onExpand={(expand) => this.onSectionExpand(7, expand)}
        title='Map'>
        <Row>
          <Column span={12}>
            <TextField
              label="Enter code"
              disabled={this.isFormDisabled() || (!adminCMSCacheTwoPage.CMSPage || !adminCMSCacheTwoPage.CMSPage.canChangeHTML)}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.MapHTML}
              rows={5}
              value={ActiveForm.MapHTML}/>
          </Column>
        </Row>
      </PageSection>
    );
  };

  renderEmbedForm = () => {
    const {actions, pageForm: {ActiveForm, ValidationRules, mobileExpandSectionIndex, sectionExpand}, adminCMSCacheTwoPage} = this.props;
    const keysToValidate = ['FormBuilderHTML'];
    const hasError = sectionHasError(ValidationRules, keysToValidate);
    return (
      <PageSection
        error={hasError}
        expand={device.mobile() ? mobileExpandSectionIndex === 8 : sectionExpand[8]}
        onExpand={(expand) => this.onSectionExpand(8, expand)}
        title='Embed Form'>
        <Row>
          <Column span={12}>
            <TextField
              label="Enter code"
              disabled={this.isFormDisabled() || (!adminCMSCacheTwoPage.CMSPage || !adminCMSCacheTwoPage.CMSPage.canChangeHTML)}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.FormBuilderHTML}
              rows={5}
              value={ActiveForm.FormBuilderHTML}/>
          </Column>
        </Row>
      </PageSection>
    );
  };

  renderSidebarContent = () => {
    const {adminCMSCacheTwoPage, actions, pageForm: {ActiveForm, ValidationRules, mobileExpandSectionIndex, sectionExpand}} = this.props;
    const keysToValidate = ['Sidebar1Title', 'Sidebar1Content'];
    const hasError = sectionHasError(ValidationRules, keysToValidate);
    return (
      <PageSection
        error={hasError}
        expand={device.mobile() ? mobileExpandSectionIndex === 9 : sectionExpand[9]}
        onExpand={(expand) => this.onSectionExpand(9, expand)}
        title='Sidebar Content'>
        <Row>
          <Column span={12}>
            <TextField
              label="Title"
              disabled={this.isFormDisabled() || (!adminCMSCacheTwoPage.CMSPage || !adminCMSCacheTwoPage.CMSPage.canChangeHTML)}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.Sidebar1Title}
              value={ActiveForm.Sidebar1Title}/>
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <TextField
              label="Enter HTML markup"
              disabled={this.isFormDisabled() || (!adminCMSCacheTwoPage.CMSPage || !adminCMSCacheTwoPage.CMSPage.canChangeHTML)}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.Sidebar1Content}
              rows={5}
              value={ActiveForm.Sidebar1Content}/>
          </Column>
        </Row>
      </PageSection>
    );
  };

  renderSearchEngineMetadata = () => {
    const {actions, pageForm: {ActiveForm, ValidationRules, mobileExpandSectionIndex, sectionExpand}} = this.props;
    const keysToValidate = ['SEOTitle', 'SEODescription', 'SEOKeywords'];
    const hasError = sectionHasError(ValidationRules, keysToValidate);
    return (
      <PageSection
        error={hasError}
        expand={device.mobile() ? mobileExpandSectionIndex === 10 : sectionExpand[10]}
        onExpand={(expand) => this.onSectionExpand(10, expand)}
        title='Search Engine Metadata'>
        <Row>
          <Column span={12}>
            <TextField
              label="Title"
              disabled={this.isFormDisabled()}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.SEOTitle}
              value={ActiveForm.SEOTitle}/>
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <TextField
              label="Description"
              disabled={this.isFormDisabled()}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.SEODescription}
              rows={5}
              value={ActiveForm.SEODescription}/>
          </Column>
          <Column span={12}>
            <TextField
              label="Keywords"
              disabled={this.isFormDisabled()}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.SEOKeywords}
              rows={5}
              value={ActiveForm.SEOKeywords}/>
          </Column>
        </Row>
      </PageSection>
    );
  };
  
  onSave = () => {
    const {pageForm: {ActiveForm, ValidationRules}, pageForm, actions, router} = this.props;
    if (pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('saving this page'), undefined, 'orange');
      return;
    }
    const ckeditorData = !this.editor ? '' : this.editor.getData();
    if (ckeditorData) actions.simpleUpdate(ckeditorData, ValidationRules.PageContent);
    
    this.checkPermission(() => actions.updatePageSubmit(router));
  };

  onVisitPageClick = () => {
    const {adminCMSCacheTwoPage, adminCMSCacheOne} = this.props;
    if (!adminCMSCacheTwoPage.CMSPage) return;
    const url = getPageUrl(adminCMSCacheTwoPage.CMSPage, adminCMSCacheOne);
    window.open(url);
  };
  onCopyLinkClick = () => {
    const {pageForm: {ActiveForm}, adminCMSCacheTwoPage, adminCMSCacheOne} = this.props;
    const url = getPageUrl(adminCMSCacheTwoPage.CMSPage as any, adminCMSCacheOne);
    copyStringToClipboard(url);
    this.props.actions.showSnackbarItem(M.LINK_COPIED);
  };
  onDuplicateClick = () => {
    const {adminCMSCacheTwoPage, pageForm} = this.props;
    if (pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('duplicating this page'), undefined, 'orange');
      return;
    }
    if (!adminCMSCacheTwoPage.CMSPage) return;
    this.checkPermission(
      () => {
        this.props.actions.pushDuplicatePageModal();
      },
    );
  };
  onDeletePageClick = () => {
    const {pageForm: {ActiveForm}, pageForm} = this.props;
    if (pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(generic.EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('deleting this page'), undefined, 'orange');
      return;
    }

    this.checkPermission(
      () => {
        this.props.actions.deletePage(pageForm.ActiveForm.ID, this.props.router, this.props.routes);
      },
    );
  };

  isFormDisabled = () => {
    const {adminCMSCacheTwoPage, apiSavingMap} = this.props;

    if (apiSavingMap[UpdatePageSubmitActions.requestType]) return true;

    const pagesRow = adminCMSCacheTwoPage.CMSPagesRow;
    if (!pagesRow) return true;

    return !pagesRow.hasEdit;
  };
  renderContent = () => {
    const {pageForm: {ActiveForm, SubmitErrorMessage}} = this.props;
    return (
      <Form
        onSubmit={e => e.preventDefault()}
        className={`${namespace()}--page-content`}
        actions = {
          this.state.showFormActions ? <FormActions wide handleLeftContent leftGutter leftGutterSize={356}>
          <Row>
            <Column expand>
              <Button disabled={this.isFormDisabled()} color="white" flat textColor="black" big onClick={this.onSave}>SAVE</Button>
            </Column>
            <Column>
              <ActionButton
                icon={OptionsIcon}
                className={`${namespace()}--options`}
                contextMenu={<ContextMenuComponent
                  direction='top'
                  onDelete={this.onDeletePageClick}
                  onVisitPage={this.onVisitPageClick}
                  onCopyLink={this.onCopyLinkClick}
                  onDuplicate={this.onDuplicateClick} />}
              />
            </Column>
          </Row>
          </FormActions> : null
        }
        >
        {!this.isFormDisabled() && SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null }
        {this.isFormDisabled() ? <Alert className={`${namespace()}--alert`}>{generic.PAGE_INSUFFICIENT_EDIT_PERMISSION}</Alert> : null}
        {this.renderPageSettings()}
        {this.renderTopGallery()}
        {this.renderPageContent()}
        {ActiveForm.PageTypeID !== PageTypeIDValue.HOMEPAGE && this.renderBottomGallery()}
        {ActiveForm.PageTypeID !== PageTypeIDValue.HOMEPAGE && this.renderSocialMedia()}
        {ActiveForm.PageTypeID !== PageTypeIDValue.HOMEPAGE && this.renderContacts()}
        {ActiveForm.PageTypeID !== PageTypeIDValue.HOMEPAGE && this.renderResources()}
        {ActiveForm.PageTypeID !== PageTypeIDValue.HOMEPAGE && this.renderMap()}
        {ActiveForm.PageTypeID !== PageTypeIDValue.HOMEPAGE && this.renderEmbedForm()}
        {ActiveForm.PageTypeID !== PageTypeIDValue.HOMEPAGE && this.renderSidebarContent()}
        {ActiveForm.PageTypeID !== PageTypeIDValue.HOMEPAGE && this.renderSearchEngineMetadata()}
      </Form>
    );
  };

  onClickMain = () => {
    this.onSiteMenuItemBack();
  };

  getPageInfo = () => {
    const {params, adminCMSCacheOne, adminCMSCacheTwoPage} = this.props;

    const pageInfo = (
      adminCMSCacheTwoPage.CMSPage ||
      (adminCMSCacheOne.CMSPages ?
        adminCMSCacheOne.CMSPages.find((p) => !!params.pageId && p.ID === getPageIdFromPath(params)) :
        undefined)
    );

    return pageInfo;
  };

  public render() {
    const {actions, adminCMSCacheOne, filterInvalid, apiLoadingMap, adminCMSCacheTwoPage, pageForm, apiSaving, apiSavingMap, apiLoading, pagesHome, filteredPages, activePages, pageHomeActions, inert} = this.props;
    if (!isAdminCMSCacheOnePopulated(adminCMSCacheOne)) {
        return <LoadingAll />;
    }

    if (adminCMSCacheTwoPage.CMSPage) {
      if (adminCMSCacheTwoPage.CMSPagesRow && adminCMSCacheTwoPage.CMSPagesRow.Inactive) {
        return (
          <NotFound message={generic.INVALID_PAGE}/>
        );
      }
    }
    const pageLoading = !isAdminCMSCacheTwoPagePopulated(adminCMSCacheTwoPage, pageForm.ActiveForm) || (isAdminCMSCacheTwoPagePopulated(adminCMSCacheTwoPage, pageForm.ActiveForm) && apiLoadingMap[GetPage.requestType]) && noOpenedModals();
    const saving = (
      apiSavingMap[EditPageUpdateSiteMenuItemSubmitActions.requestType] ||
      apiSavingMap[DeletePageSubmitActions.requestType]
    ) && noOpenedModals();
    const otherLoading = (apiLoadingMap[GetSiteCache.requestType] || apiLoadingMap[GetDefaultForPrimaryEventPageSubmitActions.requestType]) && noOpenedModals();

    const pageInfo = this.getPageInfo();
    const pageIdFromUrl = getPageIdFromPath(this.props.params);

    return (
      <Main inert={inert} onClick={this.onClickMain}>
        <MainContent
          wide
          leftContent={
            <div className={`${namespace()}--sidebar`}>
              <PageList
                key='edit-page-list'
                adminCMSCacheOne={adminCMSCacheOne}
                actions={pageHomeActions}
                pageFormActions={actions}
                apiSaving={apiSaving}
                apiLoading={apiLoading}
                apiLoadingMap={apiLoadingMap}
                pagesHome={pagesHome}
                pageForm={pageForm}
                filteredPages={filteredPages}
                activePages={activePages}
                disabled={pageLoading}
                cardCategory={CardCategory.LIST_MOBILE}
                context='edit-page'
                filterInvalid={filterInvalid}
                compact />
            </div>
          }
          rightGutter
          header={<PageInfo
            hasEdit={!!adminCMSCacheTwoPage.CMSPagesRow && !!adminCMSCacheTwoPage.CMSPagesRow.hasEdit}
            page={pageInfo}
            onSave={this.onSave}
            onDelete={this.onDeletePageClick}
            onDuplicate={this.onDuplicateClick}
            onVisitPage={this.onVisitPageClick}
            onCopyLink={this.onCopyLinkClick}
          />}
          className={namespace()}
        >
          {!pageLoading && pageIdFromUrl > 0 ? this.renderContent() : null}
          {pageLoading && pageIdFromUrl > 0 && <Loader className={`${namespace()}--loader ${otherLoading ? 'other': ''}`} center />}
        </MainContent>
        {pageForm.showAddSiteMenuSidebar && <SiteMenuItem onShadowClick={this.onClickMain} parentContext='edit-page' type='add' onBack={this.onSiteMenuItemBack} key='edit-page-edit-site-menu-item-modal' />}
        {pageForm.editSiteMenuId && pageForm.editSiteMenuId > 0 && <SiteMenuItem onShadowClick={this.onClickMain} onInactive={this.onSiteMenuInactive} parentContext='edit-page' menuItemId={pageForm.editSiteMenuId} type='edit' onBack={this.onSiteMenuItemBack} key='edit-page-edit-site-menu-item-modal' />}
        {(otherLoading || saving || pageIdFromUrl < 0) && <Loader className={`${namespace()}--loader ${otherLoading || saving || pageIdFromUrl < 0 ? 'other': ''}`} center />}
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredPagesSelector = makeFilteredPagesSelector();
  const activePagesSelector = makeActivePagesSelector();
  const selectedLocationSelector = makeSelectedLocationSelector();
  const pageResourcesSelector = makePageResourcesSelector();
  const pageContactsSelector = makePageContactsSelector();
  const resourceCategoriesMapSelector = makeResourceCategoriesMapSelector();
  const filteredTopGallerySelector = makeFilteredPhotoGallery1Selector();
  const filteredBottomGallerySelector = makeFilteredPhotoGallery2Selector();
  const selectedEventCategorySelector = makeSelectedEventCategorySelector();

  return {
    apiSaving: state.app.apiSaving,
    apiSavingMap: state.app.apiSavingMap,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    cacheZero: state.cacheZero,
    cacheOne: state.cacheOne,
    adminCMSCacheTwoPage: state.adminCMSSite.cacheTwoPage,
    pagesHome: state.adminCMSSite.pages.home,
    pageForm: state.adminCMSSite.pages.page.form,
    newPageForm: state.adminCMSSite.pages.modals.newPage,
    duplicatePageForm: state.adminCMSSite.pages.modals.duplicatePageForm,
    filteredPages: filteredPagesSelector(state),
    activePages: activePagesSelector(state),
    selectedLocation: selectedLocationSelector(state),
    pageResources: pageResourcesSelector(state),
    pageContacts: pageContactsSelector(state),
    resourceCategoriesMap: resourceCategoriesMapSelector(state),
    filteredTopGallery: filteredTopGallerySelector(state),
    filteredBottomGallery: filteredBottomGallerySelector(state),
    selectedEventCategory: selectedEventCategorySelector(state),
    filterInvalid: state.adminCMSSite.pages.home.filterInvalid,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...adminCMSCacheTwoPageActionCreators,
    ...rollbackActions,
  }, dispatch),
  pageHomeActions: bindActionCreators({
    ...pageHomeActionCreators,
    ...appActionCreators,
    ...adminCMSCacheOneActionCreators,
    ...rollbackActions,
  }, dispatch),
});
const ConnectedEditPage = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(EditPage);

export default withRouter<{}>(ConnectedEditPage);
