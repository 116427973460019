import * as React from 'react';

import AddButton from "../../AddButton";
import Card from "../../Card";
import Title from './Title';

import '../../../../styles/elements/roster/card/index.scss';
import {default as attributes, MarginProps} from "../../../../styles/index";

import {namespace} from "./constants";

interface Props extends MarginProps {
  children?: React.ReactNode;
  title: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin';
  onClick?: () => void;
  isWizard?: boolean;
  mobileBottomBorder?: boolean;
  id?: string;
}

const RosterCard: React.SFC<Props> = ({ children, title, template, id, isWizard, onClick, mobileBottomBorder, ...props }: Props): React.ReactElement<Props> => {
  let className = attributes(namespace(), undefined, props);
  return (
    <Card
      className={className}
      component={<section/>}
      template={template}
      padding="none"
      mobileMarginBottom={isWizard ? 0 : undefined}
      mobileBottomBorder={mobileBottomBorder}
    >
      {typeof title === 'string' ? <Title mobileTopMargin={isWizard} extra={onClick? <AddButton id={id} onClick={onClick} marginLeft={14}/> : null}>{title}</Title> : title}
      {children}
    </Card>
  );
};

RosterCard.defaultProps = {
  template: 'mobile-stretch'
};

export default RosterCard;
export { default as RosterCardTitle } from './Title';
