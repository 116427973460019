
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetSiteCache} from "./actions";
import { getSiteCache, getSiteCacheBody } from "../../../constants/urls";
import { Observable } from "rxjs";
import { ToggleFilterInvalid } from "../Pages/Home/actions";
import { NoOp } from "../../App/actions";
import { ToggleFilterInvalidInSelectPageModal } from "../Pages/Modals/SelectPage/actions";
import { ModalTypes, isModalOpened } from "../../../utils/modalHelper";
import { CMSPage } from "../../../models/api/adminCMSCacheOne";
import {  } from "../../../utils/navHelper";
import { reduxStoreService } from "../../service";

export const adminCMSSiteCacheOneEpic = makeRequestEpic(
  GetSiteCache,
  getSiteCache,
  getSiteCacheBody,
  true,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  (response) => {
    const Pages = response.response.CMSPages as (CMSPage[] | undefined);
    // Toggle page filter invalid flag off when successfully retrieving pages, because
    // a. we won't send request when the page filter is invalid
    // b. successful response will return a valid page filter as well
    if (Pages) {
      if (isModalOpened(ModalTypes.SELECT_PAGE)) {
        if (reduxStoreService().getState().adminCMSSite.pages.modals.pageType.filterInvalid) return Observable.of(new ToggleFilterInvalidInSelectPageModal(false));
      } else {
        if (reduxStoreService().getState().adminCMSSite.pages.home.filterInvalid) return Observable.of(new ToggleFilterInvalid(false));
      }
    }

    return Observable.of(new NoOp());
  }
);