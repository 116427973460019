
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetAdminClassRequirements, UpdateAdminClassRequirements} from "./actions";
import { getClassRequirement, getClassRequirementBody, getUpdateClassRequirements } from "../../../constants/adminEventsUrls";
import { AjaxResponse } from "rxjs/Rx";

export const adminEventsCacheThreeClassRequirementsEpic = makeRequestEpic(
  GetAdminClassRequirements,
  getClassRequirement,
  getClassRequirementBody,
  true,
);

export const adminEventsUpdateClassRequirementsEpic = makeRequestEpic(
  UpdateAdminClassRequirements,
  getUpdateClassRequirements,
  undefined,
  true,
  (response: AjaxResponse) => {
    return 'Requirements completed have been updated successfully.';
  }
);