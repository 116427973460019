import { getMaxId } from "../../constants/urls";
import { reduxStoreService } from "../../store/service";

export function withMaxClassTypeIDiAndHasMeritBadges<AppStateBase>(params: AppStateBase): AppStateBase & {MaxClassTypeIDi?: number} & { HasMeritBadges?: boolean } {
  const state = reduxStoreService().getState();

  /**
    * Use this to fetch all `ClassTypes` when first needed OR
    * when the list is out-of-date
    * 
    * We only configure this flag for the following cases:
    * - Admin side - Get in admin events cache 2 or below
    *   - Including _all loads_ at this level or deeper if the load is also able to load higher cache levels (supports direct visit).
    *   - Also pass to GetSourceClasses even tho it doesn't load parent cache level. When this modal is opened, it still needs to be able to refresh class types as needed in order to have a matching class type for every class. 
    * - End user side - Get in events cache 3 or below.
    *   - Include wizard saving and loads, since that reloads the higher caches
   */
  const MaxClassTypeIDi = state.cacheZero.options && state.cacheZero.options.ClassTypes ? getMaxId(state.cacheZero.options.ClassTypes, true) : 0;

  /**
    * Use this to fetch `MeritBadges` when first needed
    * 
    * We only configure this flag for the following cases:
    * - Admin side - Get in admin events cache 2 or below
    * - End user side - Get in events cache 3 or below 
    *   - Include wizard saving, since that reloads the higher caches
    *   - Also pass on wizard loads & cancel registration loads so it’s there in case of 409, which will load the higher caches. 
   */
  const HasMeritBadges =
    !!state.cacheZero.options?.MeritBadges &&
    state.cacheZero.options?.MeritBadges.length > 0
      ? true
      : undefined;

  return {
    ...params,
    MaxClassTypeIDi,
    HasMeritBadges,
  };
}