import * as React from 'react';
import {bindActionCreators} from 'redux';
import {RouteComponentProps} from "react-router";
import {RegisterFooterProgress} from '../../../../../Elements';
import {appActionCreators} from "../../../../../../store/App/index";
import {actionCreators} from "../../../../../../store/Events/Event/Register/Participant/Main/actions";
import {Step} from '../../../../../Elements/Register/FooterProgress';
import {ParticipantTab} from "../../../../../../store/Events/Event/Register/Participant/Main";
import {showClasses, typeNext} from "../../../../../../store/Events/Event/Register/Participant/Main/uiHelpers";
import {
  actionCreators as cacheFourActionCreators,
} from '../../../../../../store/CacheFourEventsParticipants/actions';
import {
  actionCreators as rosterActionCreators,
} from '../../../../../../store/Events/Event/Register/Participant/Roster/actions';
import {
  actionCreators as typeActionCreators,
} from '../../../../../../store/Events/Event/Register/Participant/Type/actions';
import {
  actionCreators as classesActionCreators,
} from '../../../../../../store/Events/Event/Register/Participant/Classes/actions';
import {EventClassIndividualAvailable, RegisteredClass} from "../../../../../../models/class";
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';

export interface FooterProgressProps {
  selected: ParticipantTab;
  availableClasses: Array<EventClassIndividualAvailable>;
  conflictingClasses: Array<EventClassIndividualAvailable>;
  registeredClasses: Array<RegisteredClass>;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string}>;

const FooterProgress: React.SFC<FooterProgressProps> = ({selected, actions, router, params, cacheFourEventsParticipants, apiSaving, apiLoading, availableClasses, conflictingClasses, registeredClasses}: FooterProgressProps & ConnectedProps):React.ReactElement<FooterProgressProps> => {
  const steps: Array<Step> = [
    { selected: selected === 'roster'},
    { selected: selected === 'type'}
  ];

  const sClasses = showClasses(availableClasses, conflictingClasses, registeredClasses);
  if (sClasses) steps.push({selected: selected === 'classes'});
  steps.push({ selected: selected === 'confirmation'});

  let onNextClick, onBackClick, backParam, nextParam;
  if (selected === 'roster') {
    onNextClick = actions.rosterSubmit;
    nextParam = 'type';
  } else if (selected === 'type') {
    onNextClick = actions.typeSubmit;
    onBackClick = actions.typeSubmit;
    backParam = 'roster';
    nextParam = typeNext(availableClasses, conflictingClasses, registeredClasses);
  } else if (selected === 'classes') {
    onBackClick = actions.classesSubmit;
    onNextClick = actions.classesSubmit;
    backParam = 'type';
    nextParam = 'confirmation';
  } else if (selected === 'confirmation') {
    if (sClasses) onBackClick = () => actions.selectPage('classes');
    else onBackClick = () => actions.selectPage('type');
  }

    // onFinish={actions.participantsSave}
    // onComplete={actions.participantsSave}
  // @todo: complete message onComplete={() => actions.showTopFloatingAlert('Moishe Groger has been added to the cart')}
  return <RegisterFooterProgress
    loading={apiSaving > 0 || apiLoading > 0}
    onComplete={actions.participantsSave}
    onBackClick={onBackClick}
    backParam={backParam}
    onNextClick={onNextClick}
    nextParam={nextParam}
    steps={steps}
  />;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
    cacheFourEventsParticipants: state.cacheFourEventsParticipants
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...appActionCreators,
  ...cacheFourActionCreators,
  ...rosterActionCreators,
  ...typeActionCreators,
  ...classesActionCreators,
  ...actionCreators
} as any, dispatch) });
const ConnectedFooterProgress = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<FooterProgressProps>(),
)(FooterProgress);

export default ConnectedFooterProgress;
