import * as Actions from './actions';
import {AppState} from "../App/index";
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../utils/StrongActions';
import { ApplicationState } from '..';
import { hasOngoingSequentialRequest } from '../App/reducerHelpers';
import { WithRootState } from '../Validation/actionCreator';

export interface RollbackState {
  normalSavePoint?: {
    oldState?: any;
    oldUrl?: string;
    oldQuery?: any;
  };
  isRollbackJustFinished: boolean;
  savePointDuringSave?: {
    oldState?: any;
    oldUrl?: string;
    oldQuery?: any;
  }
}

const Rollback: Reducer<RollbackState> = (state: RollbackState, action: WithRootState<AnyAction>) => {
  if (isActionType(action, Actions.ClearSavePointDuringSave)) {
    return {
      ...state,
      savePointDuringSave: undefined,
    };
  } else if (isActionType(action, Actions.SaveState)) {
    const app: AppState = action.rootState.app;
    // blocks saving when
    // 1. we have an ongoing loading request
    // 2. we have an ongoing saving request, AND it is not a sequential one (i.e. image upload/delete/restore in EditPage)
    // 3. save is blocked
    if ((app.apiLoading > 0 || (app.apiSaving > 0 && !hasOngoingSequentialRequest(action.rootState))) && !(action as Actions.SaveState).savePointDuringSave) {
      console.log('Save state block because something is loading or saving');
      return state;
    }
    const prevState = action.rootState;
    const saveState = {} as Partial<ApplicationState>;
    for (let key in prevState) {
      saveState[key] = { ...prevState[key] };
    }
    // as we are saving the current state, we should set `rollback` to be null
    // to avoid nested rollback in state tree
    saveState.rollback = undefined;
    
    // Never save `isRouterChangeRollbackFinished` flag
    if (saveState.app) {
      saveState.app.isRouterChangeRollbackFinished = true;
    }
    let oldUrl = '';
    let oldQuery = '';
    if (prevState && prevState.routing && prevState.routing.locationBeforeTransitions) {
      oldUrl = prevState.routing.locationBeforeTransitions.pathname;
      oldQuery = prevState.routing.locationBeforeTransitions.query;
    }

    if ((action as Actions.SaveState).savePointDuringSave) {
      console.log('Save state during save occurred!');
      return {
        ...state,
        savePointDuringSave: {
          oldState: saveState,
          oldUrl,
          oldQuery,
        },
      };
    }
    console.log('Save state occurred!');
    return {
      ...state,
      normalSavePoint: {
        oldState: saveState,
        oldUrl,
        oldQuery,
      },
    };
  } else if (isActionType(action, Actions.ClearRollback)) {
    return { ...state, isRollbackJustFinished: false };
  }

  return state || {};
};

export default Rollback;
