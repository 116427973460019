import {ActionCreator} from '../';
import { typeName, Action } from '../../utils/StrongActions';
import {CLEAR_CACHE_PREFIX, createRequestActions} from '../Validation/actionCreator';

export const SUFFIX = '__CACHE_ONE';
export const SUFFIX_SAVE = '__CACHE_ONE_SAVING';
export const GetGroupCache = createRequestActions<IGetGroupCacheParams>(SUFFIX);


@typeName(`${CLEAR_CACHE_PREFIX}_CACHE_BELOW_ONE`) export class ClearCacheBelowOne extends Action {}


export type Actions = typeof actionCreators;

export interface IGetGroupCacheParams {
  GroupIDi: number,
  GroupTS: number,
  GetOptions?: boolean,
  GetGroupData?: boolean
}
export const actionCreators = {
  getGroupCache: (
    GroupIDi: number,
    GroupTS: number,
    GetOptions?: boolean,
    GetGroupData?: boolean
  ): ActionCreator => dispatch => {
    const params = {
      GroupIDi,
      GroupTS,
      GetOptions,
      GetGroupData
    };
    dispatch(GetGroupCache.request(params));
  },
  clearCacheBelowOne: (): ActionCreator => dispatch => dispatch(new ClearCacheBelowOne())
};
