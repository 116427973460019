import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../../../store';
import { appActionCreators, makeFormModalPropSelector } from '../../../../store/App';
import { ModalTypes } from '../../../../utils/modalHelper';
import { getMergeProps } from '../../../../utils/reduxHelper';
import {
  Modal, ModalTitle, Icon, ModalContent, ModalActions, Text, Paragraph, Button
} from '../../../Elements';
import { RemoveIcon } from '../../../Icons';
import {WithInertAttribute} from '../../../Elements/WithInert';

type Props = WithInertAttribute<{}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class PaymentFailed extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  onClose = () => {
    this.props.actions.popModal(false, true, ModalTypes.PAYMENT_FAILED);
  };

  render() {
    const newlineMessage = this.props.errorMessage ? this.props.errorMessage.replace(/\r/g, '\n') : '';

    return (
      <Modal inert={this.props.inert} disableDynamicHeight big>
        <ModalTitle center>
          <Icon red icon={RemoveIcon}/>
          <span>Payment Failed</span>
        </ModalTitle>
        <ModalContent center paddingTop={0}>
          <Paragraph color="gray">
            Please verify your payment details and try again.
          </Paragraph>
          <Text color="red" size={13} preWrap>
            {newlineMessage}
          </Text>
        </ModalContent>
        <ModalActions
          noPadding
          right={<Button onClick={this.onClose} flat>CLOSE</Button>}
        />
      </Modal>
    );
  }
};


const mapStateToProps = (state: ApplicationState) => {
  const paymentErrorMessageSelector = makeFormModalPropSelector(ModalTypes.PAYMENT_FAILED, 'paymentErrorMessage');
  return {
    errorMessage: paymentErrorMessageSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...appActionCreators }, dispatch)
});


const ConnectedPaymentFailed = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(PaymentFailed);

export default ConnectedPaymentFailed;
