import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/check-box/index.scss';
import { noop } from '../../../utils';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core';

const AnyMaterialCheckbox = MaterialCheckbox as any;

export const namespace = (): string => 'elements--check-box';

// What's the difference beteen value and selected?
// label doesn't work...
interface Props {
  label?: string | React.ReactElement;
  value: boolean | number | string;
  className?: string;
  selected: boolean;
  disabled?: boolean;
  onChange: Function;
  newDesign?: boolean;
  indeterminate?: boolean;
  classes?: any;
}

const StatelessCheckbox: React.SFC<Props> = ({ disabled, label, value, classes, selected, onChange, newDesign, indeterminate, ...props }: Props): React.ReactElement<Props> => {
  let finalValue: string | number;
  if (typeof value === 'boolean') finalValue = value ? '1' : '0';
  else finalValue = value;
  let className = mergeClassNames(namespace(), props);
  if (selected) className += ' checked';
  if (disabled) className += ' disabled';
  if (indeterminate) className += ' indeterminate';

  if (newDesign) {
    return (
      <div className={`${namespace()}--wrapper`}>
        <div className={`${namespace()}--content`}>
          <FormControlLabel
            control={<AnyMaterialCheckbox
              value={finalValue}
              className={className}
              checked={selected}
              disabled={disabled}
              indeterminate={!!indeterminate}
              onChange={onChange ? (event) => {
                event.stopPropagation();
                const element: HTMLInputElement = event.target as any;
                if (typeof value === 'boolean') onChange(element.value == '1' ? true : false);
                else onChange(element.value);
              } : noop}
            />}
            label={<div className={`${namespace()}--content--label${disabled ? ' disabled' : ''}`}>{label}</div>}
          />
        </div>
      </div>
    );
  }
  return (
    <AnyMaterialCheckbox
      label={label}
      value={finalValue}
      className={className}
      checked={selected}
      disabled={disabled}
      onChange={onChange ? (event) => {
        event.stopPropagation();
        const element: HTMLInputElement = event.target as any;
        if (typeof value === 'boolean') onChange(element.value == '1' ? true : false);
        else onChange(element.value);
      } : noop}
    />
  );
};

const styles = {
  cssIndeterminate: {
  },
};

export default withStyles(styles)(StatelessCheckbox);
