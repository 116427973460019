import * as EM from "../../../../../constants/messages/events";
import { toNumber } from "../../../../../utils/dataHelper";
import { Validator } from "../../../../../utils/validator/models";

export interface IValidator {
  NumOverride: Validator;
  Amount: Validator;
  AmountMin: Validator;
  AllowNegative: Validator;
  MinLessThanAmount: Validator;
}

export const FormDefinition: IValidator = {
  NumOverride: {
    key: 'NumOverride',
    integerOnly: true,
    validatejs: {
      NumOverride: {
        presence: {message: '^' + EM.OVERRIDE_REQUIRED},
      }
    }
  },
  Amount: {
    key: 'Amount',
    decimalOnly: true,
    allowNegative: (rootState) => {
      return !rootState.events.event.modals.overrideFee.OverridingClass ? false : !!rootState.events.event.modals.overrideFee.OverridingClass.IsBaseFee;
    },
    validatejs: {
      Amount: {
        presence: {message: '^' + EM.FEE_REQUIRED},
        numericality: {
          notValid: `^${EM.FEE_INVALID}`
        }
      }
    }
  },
  AmountMin: {
    key: 'AmountMin',
    isRequired: (rootState) => {
      return rootState.events.event.modals.overrideFee.ActiveForm.Amount === undefined || rootState.events.event.modals.overrideFee.ActiveForm.Amount >= 0;
    },
    decimalOnly: true,
    allowNegative: (rootState) => {
      return !rootState.events.event.modals.overrideFee.OverridingClass ? false : !!rootState.events.event.modals.overrideFee.OverridingClass.IsBaseFee;
    },
    validatejs: {
      AmountMin: {
        presence: {message: '^' + EM.UPFRONT_REQUIRED},
        numericality: {
          notValid: `^${EM.UPFRONT_INVALID}`
        }
      }
    }
  },
  AllowNegative: {
    key: 'AllowNegative',
    customValidate: (rootState) => {
      const {OverridingClass, ActiveForm } = rootState.events.event.modals.overrideFee;

      const Amount = toNumber(ActiveForm.Amount);

      if (!OverridingClass) return;
      if (Amount < 0 && !OverridingClass.IsBaseFee) {
        return EM.NEGATIVE_OVERRIDE;
      }
      return;
    }
  },
  MinLessThanAmount: {
    key: 'MinLessThanAmount',
    customValidate: (rootState) => {
      const {OverridingClass, ActiveForm} = rootState.events.event.modals.overrideFee;

      const Amount = toNumber(ActiveForm.Amount);
      const AmountMin = toNumber(ActiveForm.AmountMin);

      if (!OverridingClass) return;
      if (Amount >= 0 && (AmountMin < 0 || AmountMin > Amount)) {
        return EM.UPFRONT_TOO_MUCH;
      }
      return;
    }
  }
};
