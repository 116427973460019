import { AdminFacilityLocationCacheOneContext } from "@tentaroo/shared";

import { FacilitiesBlackoutDate } from "../../models/api/adminFacilitiesCacheOne";
import { ClearAdminFacilityLocationCacheBelowOne, ClearAllAdminFacilityLocationCache } from "../../store/AdminFacilityLocation/CacheOne/actions";
import { AdminFacilityLocationCacheTwoBlackoutDatesState } from "../../store/AdminFacilityLocation/CacheTwoBlackoutDates";
import { safelySetEmptyCart } from "../cacheLoaders/cacheLoaderHelpers";
import CacheManager from "./cacheManager";
import { AdminFacilityLocationCacheTwoFacilityState } from "../../store/AdminFacilityLocation/CacheTwoFacility";
import { AdminFacilityDetail } from "../../models/api/adminFacilitiesCacheTwoFacility";
import { AdminFacilityLocationCacheTwoFacilityTypesState } from "../../store/AdminFacilityLocation/CacheTwoFacilityTypes";
import { AdminFacilityTypeDetail } from "../../models/api/adminFacilitiesCacheTwoFacilityType";
import { AdminFacilityLocationCacheOneState } from "../../store/AdminFacilityLocation/CacheOne";
import { ModalTypes, isModalOpened } from "../modalHelper";
import { ClearAdminFacilityLocationCacheTwoFacilityType } from "../../store/AdminFacilityLocation/CacheTwoFacilityTypes/actions";
import EndUserCacheManager from "./endUserCacheManager";
import { RouteComponentProps } from "react-router";
import { getBlackoutDateIdFromPath, getFacilityIdFromPath, getFacilityLocationId, getFacilityTypeIdFromPath, IAdminBlackoutDateRouterParams, IAdminFacilitiesLocationRouterParams, IAdminFacilityRouterParams, IAdminFacilityTypeRouterParams } from "../helpers/adminFacilityLocationPageHelper";
import { ensureAdminFacilityLocationCacheTwoBlackoutDate } from "../cacheLoaders/adminFacilityLocationCacheTwoBlackoutDateLoader";
import { ensureAdminFacilityLocationCacheTwoFacility } from "../cacheLoaders/adminFacilityLocationCacheTwoFacilityLoader";
import { ensureAdminFacilityLocationCacheTwoFacilityType } from "../cacheLoaders/adminFacilityLocationCacheTwoFacilityTypesLoader";
import { ensureAdminFacilityLocationCacheOne } from "../cacheLoaders/adminFacilityLocationCacheOneLoader";
import { getDataIdFromUrlOrModalProp } from "../dataHelper";
import {getGroupCache} from "../../constants/urls";
import {getCacheOneEmptyCartBody} from "../cacheLoaders/cacheLoaders";
import { reduxStoreService } from "../../store/service";
import { FacilitiesHomeReset } from "../../store/AdminFacilityLocation/Facilities/Home/actions";
import { ResetBlackoutDateHome } from "../../store/AdminFacilityLocation/BlackoutDates/Home/actions";
import { LoadAdminFacilityCacheOneParams, LoadAdminFacilityCacheTwoBlackoutDateParams, LoadAdminFacilityCacheTwoFacilityParams, LoadAdminFacilityCacheTwoFacilityTypeParams } from "../cacheLoaders/helpers/models";
import {isAdminFacilitiesLocationCacheOnePopulated, isAdminFacilityLocationCacheTwoBlackoutDatePopulated, isAdminFacilityLocationCacheTwoFacilityPopulated, isAdminFacilityLocationCacheTwoFacilityTypePopulated} from "../cachePopulatedCheckers/adminFacilities";

export default class AdminFacilitiesLocationCacheManager extends CacheManager {
  private static _instance: AdminFacilitiesLocationCacheManager;

  public static getInstance(): AdminFacilitiesLocationCacheManager {
    if (!this._instance) {
      this._instance = new AdminFacilitiesLocationCacheManager();
    }

    return this._instance;
  }

  constructor() {
    super();
  }

  // Cache One
  public initCacheOneListPage(
    context: AdminFacilityLocationCacheOneContext
  ) {
    if (context === AdminFacilityLocationCacheOneContext.FACILITIES_LIST) {
      reduxStoreService().dispatch(new FacilitiesHomeReset());
    } else if (context === AdminFacilityLocationCacheOneContext.BLACKOUT_DATES_LIST) {
      reduxStoreService().dispatch(new ResetBlackoutDateHome());
    }
  }
  private isPopulatedCacheOneInvalid(cacheOne: AdminFacilityLocationCacheOneState, facilityLocationId: number) {
    if (this._isPopulatedCacheInvalid<AdminFacilityLocationCacheOneState>(
      cacheOne,
      (cacheOne) => cacheOne.FacilitiesLocation ? cacheOne.FacilitiesLocation.ID : 0,
      `${facilityLocationId}`,
    )) {
      reduxStoreService().dispatch(new ClearAllAdminFacilityLocationCache());
      return true;
    }
  
    return false;
  };

  public loadAdminFacilitiesLocationCacheOne(
    params: LoadAdminFacilityCacheOneParams,
  ) {
    const {props, isStateNavigated} = params;

    // Only SetEmptyCart when first navigating to the any of the cache one pages
    // but not due to a rollback
    if (isStateNavigated) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }
    if (this.cacheOneNeedsReload(
      props,
      getFacilityLocationId(props),
    ) || isStateNavigated) {
      return ensureAdminFacilityLocationCacheOne({
        ...params,
        cacheManager: this,
      });
    }

    return false;
  };

  private cacheOneNeedsReload(
    props: RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}>,
    facilityLocationId: number,
  ) {
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const cacheOne = reduxStoreService().getState().adminFacilityLocation.cacheOne;
    const isCacheInvalid = this.isPopulatedCacheOneInvalid(cacheOne, facilityLocationId);

    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      !isAdminFacilitiesLocationCacheOnePopulated()
    );
  }

  // Blackout date
  private isPopulatedCacheTwoBlackoutDateInvalid(
    cacheTwoBlackoutDates: AdminFacilityLocationCacheTwoBlackoutDatesState,
    facLocId: number,
    blackoutDateId: number,
  ) {
    const cacheOne = reduxStoreService().getState().adminFacilityLocation.cacheOne;

    if (this.isPopulatedCacheOneInvalid(cacheOne, facLocId)) return true;

    const isInvalid = this._isPopulatedCacheInvalid<FacilitiesBlackoutDate>(
      cacheTwoBlackoutDates.FacilitiesBlackoutDate,
      (blackoutDate) => blackoutDate.ID,
      blackoutDateId,
    );

    if (isInvalid) {
      reduxStoreService().dispatch(new ClearAdminFacilityLocationCacheBelowOne());
    }

    return isInvalid;
  };

  public loadCacheTwoBlackoutDate(
    params: LoadAdminFacilityCacheTwoBlackoutDateParams
  ) {
    const {props, isStateNavigated, isEdit} = params;
    const rootState = reduxStoreService().getState();
    const blackoutDateId = getBlackoutDateIdFromPath(props.params);

    // Only SetEmptyCart when first navigating to balckout date form page but not
    // due to a rollback
    if (isStateNavigated) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }
    if (this.adminFacilityLocationCacheTwoBlackoutDateNeedsReload(
      rootState.adminFacilityLocation.cacheTwoBlackoutDate,
      getFacilityLocationId(props),
      blackoutDateId,
      props,
      isEdit,
    ) || isStateNavigated) {
      return ensureAdminFacilityLocationCacheTwoBlackoutDate({
        ...params,
        cacheManager: this,
      });
    }

    return false;
  };

  private adminFacilityLocationCacheTwoBlackoutDateNeedsReload(
    cacheTwoBlackoutDates: AdminFacilityLocationCacheTwoBlackoutDatesState,
    facLocId: number,
    blackoutDateId: number,
    props: RouteComponentProps<IAdminBlackoutDateRouterParams, {}>,
    isEdit: boolean,
  ) {
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const isCacheInvalid = this.isPopulatedCacheTwoBlackoutDateInvalid(cacheTwoBlackoutDates, facLocId, blackoutDateId);

    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      (isEdit && !isAdminFacilityLocationCacheTwoBlackoutDatePopulated(cacheTwoBlackoutDates))
    );
  }

  // Facility
  private isPopulatedFacilityInvalid(
    adminFacilityLocationCacheTwoFacility: AdminFacilityLocationCacheTwoFacilityState,
    facLocId: number,
    facilityId: number,
  ) {
    const cacheOne = reduxStoreService().getState().adminFacilityLocation.cacheOne;

    if (this.isPopulatedCacheOneInvalid(cacheOne, facLocId)) return true;
    const isInvalid = this._isPopulatedCacheInvalid<AdminFacilityDetail>(
      adminFacilityLocationCacheTwoFacility.FacilitiesFacility,
      (facility) => facility.ID,
      facilityId,
    );

    if (isInvalid) {
      // @todo - add condition here when modal is implemented
      let facilityModalOpened = false;
      reduxStoreService().dispatch(new ClearAdminFacilityLocationCacheBelowOne());
    }

    return isInvalid;
  };

  public loadCacheTwoFacility(
    params: LoadAdminFacilityCacheTwoFacilityParams
  ) {
    const {props, isStateNavigated, isEdit} = params;
    const rootState = reduxStoreService().getState();
    const facilityId = getFacilityIdFromPath(props.params);

    // Only SetEmptyCart when first navigating to facility form page but not
    // due to a rollback
    if (isStateNavigated && isEdit) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }
    if (this.adminFacilityLocationCacheTwoFacilityNeedsReload(
      rootState.adminFacilityLocation.cacheTwoFacility,
      getFacilityLocationId(props),
      facilityId,
      props,
      isEdit,
    ) || isStateNavigated) {
      return ensureAdminFacilityLocationCacheTwoFacility({
        ...params,
        cacheManager: this,
      });
    }

    return false;
  };

  private adminFacilityLocationCacheTwoFacilityNeedsReload(
    adminFacilityLocationCacheTwoFacility: AdminFacilityLocationCacheTwoFacilityState,
    facLocId: number,
    facilityId: number,
    props: RouteComponentProps<IAdminFacilityRouterParams, {}>,
    isEdit: boolean,
  ) {
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const isCacheInvalid = this.isPopulatedFacilityInvalid(adminFacilityLocationCacheTwoFacility, facLocId, facilityId);

    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      (isEdit && !isAdminFacilityLocationCacheTwoFacilityPopulated(adminFacilityLocationCacheTwoFacility))
    );
  }

  // Facility type
  public loadCacheTwoFacilityType(
    params: LoadAdminFacilityCacheTwoFacilityTypeParams
  ) {
    const {props, facilityTypeIdFromModalProps, isStateNavigated, isEdit} = params;
    const rootState = reduxStoreService().getState();
    const facilityTypeId = getDataIdFromUrlOrModalProp<IAdminFacilityTypeRouterParams>(
      getFacilityTypeIdFromPath,
      props.params,
      facilityTypeIdFromModalProps,
    );

    // Only SetEmptyCart when first navigating to facility type form page but not
    // due to a rollback
    if (isStateNavigated && !isModalOpened(ModalTypes.FACILITY_TYPE_FORM)) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }
    if (this.adminFacilityLocationCacheTwoFacilityTypeNeedsReload(
      rootState.adminFacilityLocation.cacheTwoFacilityTypes,
      getFacilityLocationId(props),
      facilityTypeId,
      props,
      isEdit,
    ) || isStateNavigated) {
      return ensureAdminFacilityLocationCacheTwoFacilityType({
        ...params,
        cacheManager: this,
        facilityTypeId,
      });
    }

    return false;
  };

  private isPopulatedFacilityTypeInvalid(
    cacheTwoFacilityTypes: AdminFacilityLocationCacheTwoFacilityTypesState,
    facLocId: number,
    facilityTypeId: number,
  ) {
    const cacheOne = reduxStoreService().getState().adminFacilityLocation.cacheOne;

    if (this.isPopulatedCacheOneInvalid(cacheOne, facLocId)) return true;

    let facilityTypeModalOpened = isModalOpened(ModalTypes.FACILITY_TYPE_FORM);
  
    const isInvalid = this._isPopulatedCacheInvalid<AdminFacilityTypeDetail>(
      cacheTwoFacilityTypes.FacilitiesType,
      (facilityType) => facilityType.FacilityTypeRow.ID,
      facilityTypeId,
      () => !facilityTypeModalOpened
    );

    if (isInvalid) {
      if (!facilityTypeModalOpened) reduxStoreService().dispatch(new ClearAdminFacilityLocationCacheBelowOne());
      if (facilityTypeModalOpened) reduxStoreService().dispatch(new ClearAdminFacilityLocationCacheTwoFacilityType());
    }

    return isInvalid;
  };

  private adminFacilityLocationCacheTwoFacilityTypeNeedsReload(
    cacheTwoFacilityTypes: AdminFacilityLocationCacheTwoFacilityTypesState,
    facLocId: number,
    facilityTypeId: number,
    props: RouteComponentProps<IAdminFacilityTypeRouterParams, {}>,
    isEdit: boolean,
  ) {
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const isCacheInvalid = this.isPopulatedFacilityTypeInvalid(cacheTwoFacilityTypes, facLocId, facilityTypeId);

    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      (isEdit && !isAdminFacilityLocationCacheTwoFacilityTypePopulated(cacheTwoFacilityTypes))
    );
  }
}