import * as React from 'react';
import { namespace as ns } from './constants';

import '../../../styles/elements/my-event/quantity.scss';
import { EventsIcon } from '../../Icons';

export const namespace = (): string => `${ns()}--quantity`;

export interface QuantityProps {
  icon?: React.ComponentClass<any> | React.StatelessComponent<any> | 'tickets';
  text?: string | React.ReactNode;
  quantity?: number;
  total?: number;
}

// @todo: make sure this works properly with the backend data
const Quantity: React.SFC<QuantityProps> = (
  { icon, text, quantity, total }
: QuantityProps): React.ReactElement<QuantityProps> => {
  let quantityComponent;
  if (quantity !== undefined && total !== undefined) {
    quantityComponent = (
      <span className={`${namespace()}--quantity`}>
          <span className={`${namespace()}--quantity--quantity`}>{quantity}</span>
          <span className={`${namespace()}--quantity--slash`}>/</span>
          <span className={`${namespace()}--quantity--total`}>{total}</span>
        </span>
    );
  } else if (quantity !== undefined) {
    quantityComponent = <span className={`${namespace()}--quantity`}>{quantity}</span>;
  } else if (total !== undefined) {
    quantityComponent = <span className={`${namespace()}--quantity`}>{total}</span>;
  }

  let iconComponent: React.ReactElement<any> | null = null;
  if (icon && typeof icon === 'string') {
    switch (icon) {
      case 'tickets':
        iconComponent = <EventsIcon width={18} height={18} className={`${namespace()}--icon`}/>;
        break;
    }
  } else if (icon) {
    const Icon: React.ComponentClass<any> = icon as any;
    iconComponent = <Icon width={18} height={18} className={`${namespace()}--icon`}/>;
  }

  return (
    <div className={namespace()}>
      {iconComponent}
      {text ? <span className={`${namespace()}--text`}>{text}</span> : null}
      {quantityComponent}
    </div>
  );
};

export default Quantity;
