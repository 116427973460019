import * as React from 'react';
import {namespace as ns} from './constants';
import RadioGroup from '../Radio/Group';
import '../../../styles/elements/financial-summary/options.scss';
import {default as attributes, MarginProps} from '../../../styles';

const namespace = (): string => `${ns()}--options`;

interface Props extends MarginProps {
  className?: string;
  children?: React.ReactNode;
}

const Options: React.SFC<Props> = (
  { children, className, ...props }: Props
): React.ReactElement<Props> => (
  <RadioGroup className={attributes(namespace(), className, props)}>
    {children}
  </RadioGroup>
);

export default Options;
