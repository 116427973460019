import { ActionCreator } from '../../index';
import {FacilityLocation} from "../../../models/api/cacheOne";
import {ToggleNotFound} from '../../App/actions';
import * as moment from 'moment';
import {SaveState} from "../../Rollback/actions";
import {ViewControlsOptions} from "../../../components/Elements/Controls/View";
import { captureTentarooError } from '../../../utils/dataHelper';
import { typeName, Action } from '../../../utils/StrongActions';

@typeName("FACILITIES_LOCATION_INIT") export class FacilitiesLocationInit extends Action {
  constructor(public loc: FacilityLocation) { super(); }
}
@typeName("FACILITIES_LOCATION_RESET") export class FacilitiesLocationReset extends Action {}
@typeName("FACILITIES_LOCATION_TOGGLE_MAP_MOBILE") export class FacilitiesLocationToggleMapMobile extends Action {}
@typeName("FACILITIES_LOCATION_SHOW_SIDEBAR") export class FacilitiesLocationShowSidebar extends Action {
  constructor(public show: boolean) { super(); }
}
export const FACILITIES_LOCATION_SELECT_FACILITY_TYPE_TAB = "FACILITIES_LOCATION_SELECT_FACILITY_TYPE_TAB";
@typeName(FACILITIES_LOCATION_SELECT_FACILITY_TYPE_TAB)
export class SelectFacilityTypeTab extends Action {
  constructor(public typeID: number) { super(); };
}
@typeName("FACILITIES_LOCATION_SELECT_TRIP_TAB")
export class SelectTripTab extends Action {
  constructor(public tripType: 'current' | 'past') {super();}
}
export const FACILITIES_LOCATION_CHANGE_DATE = 'FACILITIES_LOCATION_CHANGE_DATE';
@typeName("FACILITIES_LOCATION_CHANGE_DATE")
export class ChangeDate extends Action {
  constructor(public date: moment.Moment) {super();}
}
@typeName("FACILITIES_LOCATION_SHOW_MONTH_MENU")
export class ShowMonthMenu extends Action {
  constructor(public show: boolean) {super();}
}
@typeName("FACILITIES_LOCATION_SET_VIEW")
export class SetView extends Action {
  constructor(public view: ViewControlsOptions) {super();}
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  init: (id: number): ActionCreator => (dispatch, getState) => {
    const co = getState().cacheOne;
    if (co.FacilityLocations !== undefined) {
      if (co.FacilityLocations === null) {
        dispatch(new ToggleNotFound());
      } else {
        const loc = co.FacilityLocations.find((l: FacilityLocation) => l.ID === id);
        if (!loc) dispatch(new ToggleNotFound());
        else dispatch(new FacilitiesLocationInit(loc));
      }
      return;
    }
    captureTentarooError(new Error('Attempted to initialize facility location, but cacheOne is not yet loaded.'));
  },
  reset: (): ActionCreator => dispatch => dispatch(new FacilitiesLocationReset()),
  toggleMapMobile: (): ActionCreator => dispatch => dispatch(new FacilitiesLocationToggleMapMobile()),
  showSidebar: (show: boolean): ActionCreator => dispatch => dispatch(new FacilitiesLocationShowSidebar(show)),
  selectFacilityTypeTab: (typeID: number): ActionCreator => dispatch => dispatch(new SelectFacilityTypeTab(typeID)),
  selectTripTab: (tripType: 'current' | 'past'): ActionCreator => dispatch => {
    dispatch(new SelectTripTab(tripType));
    dispatch(new SaveState());
  },
  changeDate: (date: moment.Moment): ActionCreator => dispatch => dispatch(new ChangeDate(date)),
  showMonthMenu: (show: boolean): ActionCreator => dispatch => dispatch(new ShowMonthMenu(show)),
  setView: (view: ViewControlsOptions): ActionCreator => dispatch => {
    dispatch(new SetView(view));
    dispatch(new SaveState());
  },

};
