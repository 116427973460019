import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators as appActionCreators, SilentCancelAllAction } from '../../../../../store/App/actions';
import { actionCreators as cacheTwoFacilityActionCreators, GetFacility } from '../../../../../store/AdminFacilityLocation/CacheTwoFacility/actions';
import { actionCreators as cacheTwoFacilityTypeActionCreators } from '../../../../../store/AdminFacilityLocation/CacheTwoFacilityTypes/actions';
import { actionCreators as facilityFormActionCreators, DeleteFacilitySubmitActions, ImportBookingTimesSubmitActions, ImportPricingSubmitActions, ImportImagesSubmitActions, UpdateFacilitySubmitActions } from '../../../../../store/AdminFacilityLocation/Facilities/Facility/Form/actions';
import { actionCreators } from "../../../../../store/AdminFacilityLocation/CacheOne/actions";
import { LoadingAll, Button, Checkbox, Card, Row, Column, TextField, FormSection, Link, FieldSet, Text, Select, NumberField, Switch, RadioGroupOption, ActionButton, MoneyField, Loader, Alert, FormActions, Form, ModalSelect, Ellipsis } from '../../../../Elements';
import { Main, MainContent } from '../../../../Layouts';
import '../../../../../styles/pages/admin-facility-location/facilities/facility/index.scss';
import FacilityList from '../Home/FacilityList';
import { makeShowAsOptionSelector, hasTempThumbnails, makeFilteredFacilityPhotosSelector, makeSelectedGLAccountSelector } from '../../../../../store/AdminFacilityLocation/Facilities/Facility/Form';
import { CardCategory } from '../../../../../components/Elements/Card';
import ValidIcon from '../../../../../components/Elements/TextField/ValidIcon';
import InvalidIcon from '../../../../../components/Elements/TextField/InvalidIcon';
import { LinkIcon, CloseIcon } from '../../../../../components/Icons';
import { AdminFacilityTypeAttribute } from '../../../../../models/api/adminFacilitiesCacheTwoFacilityType';
import { processHour, convertTimeDigit, getPeriodText, TimeAttributeKeys } from '../../../../../utils/dateHelper';
import TimePicker from '../../../../../components/Elements/TimePicker';
import { getTimeBlockAttrKey, getFacilityPricingAttrKey, FacilityPricingAttributeKeys } from '../../../../../store/AdminFacilityLocation/Facilities/Facility/Form/validation';
import { GLAccount } from '../../../../../models/api/options';
import { getFacilitiesHomeRootUrl, openPricingTierForm } from '../../../../../constants/urls';
import { makeSortedFacilityTypeAttributesSelector } from '../../../../../store/AdminFacilityLocation/CacheTwoFacilityTypes';
import GalleryUpload from '../../../../Elements/Gallery/GalleryUpload';
import { getValidPageGalleryImageTypesString, validateGalleryImage } from '../../../../../utils/fileHelper';
import Gallery from '../../../../Elements/Gallery';
import { ImportType } from '../Modals/ImportFromFacility';
import GLAccountCard, { GLAccountCardType } from '../../../../Elements/GLAccount/Card';
import { checkGLAccountPermission } from '../../../../../utils/helpers/adminSettingsPageHelper';
import { EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE } from '../../../../../constants/messages/adminFacilityLocation';
import { checkFacilityPermission, getFacilityIdFromPath, IAdminFacilityRouterParams } from '../../../../../utils/helpers/adminFacilityLocationPageHelper';
import { shallowEqual } from '../../../../../utils';
import NotFound from '../../../../../components/Pages/NotFound';
import { INVALID_FACILITY } from '../../../../../constants/messages/adminFacilityLocation';
import { UpdateFacilityTypeSubmitActions, FacilityTypeFormSection } from '../../../../../store/AdminFacilityLocation/Facilities/FacilityType/Form/actions';
import { isMobile } from '../../../../../utils/isMobile';
import { ModalTypes } from '../../../../../utils/modalHelper';
import AdminFacilitiesLocationCacheManager from '../../../../../utils/cacheManagers/adminFacilitiesLocationCacheManager';
import { shouldBlockActions } from '../../../../../utils/cacheLoaders/helpers/blockers';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { SaveState } from '../../../../../store/Rollback/actions';
import { shouldReconfigRouter } from '../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { hasOngoingSequentialRequest, hasQueuedReduxActions } from '../../../../../store/App/reducerHelpers';
import { GalleryImage } from '../../../../../models/api/cacheOne';
import { reduxStoreService } from '../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isAdminFacilitiesLocationCacheOnePopulated, isAdminFacilityLocationCacheTwoFacilityPopulated} from '../../../../../utils/cachePopulatedCheckers/adminFacilities';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--facility-location--facility';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminFacilityRouterParams, {}>
>;

class AdminFacilityPage extends ComponentUpdateTemplate<ConnectedProps, {}> {
  public props: ConnectedProps;
  private routeParamsBeforeUnmount;

  componentDidMount() {
    this.props.actions.showAdminPageHeader(false);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        // `handleOptimisticSaveRedirection` will get called first and prevent downstream
        // loaders from running if optimistic
        AdminFacilitiesLocationCacheManager.getInstance().loadCacheTwoFacility({
          props: this.props,
          isEdit: true,
          isStateNavigated,
        });
      }
    );
    this.configRouter();
  }

  componentDidUpdate(prevProps) {
    if (!!!hasOngoingSequentialRequest(reduxStoreService().getState()) && hasTempThumbnails(reduxStoreService().getState())) {
      this.props.actions.clearThumbnails();
    }
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        AdminFacilitiesLocationCacheManager.getInstance().loadCacheTwoFacility({
          props: nextProps,
          isEdit: true,
          isStateNavigated,
        });

        if (isStateNavigated) {
          window.scrollTo(0, 0);
        }
      }
    );
  }

  configRouter() {
    const {router, routes} = this.props;
    router.setRouteLeaveHook(routes[routes.length - 1], this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    const {apiLoading, adminFacilityLocationCacheTwoFacility, params} = this.props;

    this.routeParamsBeforeUnmount = params;

    // Call SilentCancellAll if
    // 1. There are any ongoing sequential request - cancel and clear them
    // 2. OR there are queued redux actions, this could happen even after an image finishes/fails
    //    uploading. So we want to always clear them (TopFloatingAlert) on leaving
    const rootState = reduxStoreService().getState();
    if (hasOngoingSequentialRequest(rootState) || hasQueuedReduxActions(rootState)) {
      // Cancel any ongoing sequential requests (image upload), and also note
      // that this `SilentCancelAll` will reset apiSaving because `isDuringNavigation`
      // param is not set here.
      reduxStoreService().dispatch(new SilentCancelAllAction());
    }
    if (rootState.app.apiLoading === 0) {
      if (isAdminFacilityLocationCacheTwoFacilityPopulated(adminFacilityLocationCacheTwoFacility)) {
        reduxStoreService().dispatch(new SaveState());
      }
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentWillUnmount() {
    const {params, actions, adminFacilityLocationCacheTwoFacility} = this.props;

    if (
      this.routeParamsBeforeUnmount &&
      isAdminFacilityLocationCacheTwoFacilityPopulated(adminFacilityLocationCacheTwoFacility) &&
      Number(this.routeParamsBeforeUnmount.facilityId) > 0
    ) {
      actions.clearAdminFacilityLocationCacheTwoFacility();
    }

    this.resetRouteLeaveHook();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState);
  }

  onAttributeCheck = (a: AdminFacilityTypeAttribute) => {
    this.props.actions.checkAttribute(a.ID);
  };

  onOpenSharedSettings = (callback) => {
    const {actions, facilityFormState} = this.props;
    if (facilityFormState && facilityFormState.processingImages) {
      actions.showTopFloatingAlert(EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE('editing shared settings'), undefined, 'orange');
      return;
    }
    callback();
  };

  renderGeneralSection = () => {
    const {apiSaving, actions, facilityFormState, showAsOption, adminFacilityLocationCacheOne, adminFacilityLocationCacheTwoFacilityTypes, sortedFacilityTypeAttributes} = this.props;
    
    const DetailActiveForm = facilityFormState.Detail.ActiveForm;
    const facilityType = adminFacilityLocationCacheTwoFacilityTypes.FacilitiesType;

    if (!facilityType) return null;

    const ManageSharedAttributeButton = <Button disabled={this.isFormDisabled()} onClick={() => this.onOpenSharedSettings(() => actions.pushFacilityTypeSharedSettingsModal({facilityTypeFormSection: FacilityTypeFormSection.ATTRIBUTES}))} color='white' textColor='black'>MANAGE SHARED ATTRIBUTES</Button>;

    return (
      <Card
        component={<section/>}
        category={CardCategory.CONTENT}
        marginBottom={24}
        mobileMarginBottom={16}
      >
        <div className={`${namespace()}--card--main`}>
          <Row marginBottom={24} mobileMarginBottom={16}>
            <Column span={12} mobileSpan={12}>
              <TextField
                id={generateDOMId(`admin-fac-name-field`)}
                label="Name"
                disabled={this.isFormDisabled()}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateFacilityDetailValue}
                validationRules={facilityFormState.Detail.ValidationRules.Name}
                value={facilityFormState.Detail.ActiveForm.Name}
              />
            </Column>
          </Row>
          <Row marginBottom={24} mobileMarginBottom={16}>
            <FormSection
              title={`Shared Settings - ${facilityType.General.NamePlural}`}
              disabled={this.isFormDisabled()}
              onEditClick={() => this.onOpenSharedSettings(() => actions.pushFacilityTypeSharedSettingsModal({facilityTypeFormSection: FacilityTypeFormSection.GENERAL}))}
            >
              <Row marginBottom={16}>
                <Column className={`${namespace()}--card--main--col`} span={12} mobileSpan={12}>
                  <div className={`${namespace()}--card--main--field-label`}>Facility Type</div>
                  <div className={`${namespace()}--card--main--field-value`}>{`${facilityType.General.NamePlural} (${showAsOption?.Name})`}</div>
                </Column>
              </Row>
              <Row marginBottom={(!adminFacilityLocationCacheOne.FacilitiesLocation || !adminFacilityLocationCacheOne.FacilitiesLocation.PrimaryContactName) && (!facilityType.General.AdditionalNotify1_Name && !facilityType.General.AdditionalNotify2_Name) ? 0 : 16}>
                <Column mobileMarginBottom={!!facilityType.General.PropertyMapURL ? 16 : 0} className={`${namespace()}--card--main--col`} span={6} mobileSpan={12}>
                  <div className={`${namespace()}--card--main--field-label`}>Overlapping Groups</div>
                  <div className={`${namespace()}--card--main--field-value--row`}>
                    <div className={`${namespace()}--card--main--field-value--icon`}>
                      {facilityType.General.AllowOverlapping ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--main--field-value ${!facilityType.General.AllowOverlapping ? 'invalid' : ''}`}>{`${facilityType.General.AllowOverlapping ? 'Allowed' : 'Not Allowed'}`}</div>
                  </div>
                </Column>
                {facilityType.General.PropertyMapURL ? <Column className={`${namespace()}--card--main--col`}>
                  <div className={`${namespace()}--card--main--field-label`}>Property Map</div>
                  <div className={`${namespace()}--card--main--field-value`}>
                    <Link className={`${namespace()}--card--main--field-open-link`} externalLink={facilityType.General.PropertyMapURL} size={14} icon={LinkIcon}>OPEN LINK</Link>
                  </div>
                </Column> : null}
              </Row>
              {adminFacilityLocationCacheOne.FacilitiesLocation && adminFacilityLocationCacheOne.FacilitiesLocation.PrimaryContactName ? <Row  marginBottom={!!facilityType.General.AdditionalNotify1_Name || !!facilityType.General.AdditionalNotify2_Name ? 16 : 0}>
                <Column className={`${namespace()}--card--main--col`} span={6} mobileSpan={12}>
                  <div className={`${namespace()}--card--main--field-label`}>Primary Contact</div>
                  <div className={`${namespace()}--card--main--field-value`}>{`${adminFacilityLocationCacheOne.FacilitiesLocation.PrimaryContactName}`}</div>
                </Column>
              </Row> : null}
              {!!facilityType.General.AdditionalNotify1_Name || !!facilityType.General.AdditionalNotify2_Name ? <Row marginBottom={0}>
                {!!facilityType.General.AdditionalNotify1_Name && <Column mobileMarginBottom={!!facilityType.General.AdditionalNotify2_Name ? 16 : 0} className={`${namespace()}--card--main--col`} span={6} mobileSpan={12}>
                  <div className={`${namespace()}--card--main--field-label`}>Additional Notify Contact</div>
                  <div className={`${namespace()}--card--main--field-value`}>{`${facilityType.General.AdditionalNotify1_Name}`}</div>
                </Column>}
                {!!facilityType.General.AdditionalNotify2_Name && <Column className={`${namespace()}--card--main--col`} span={6} mobileSpan={12}>
                  <div className={`${namespace()}--card--main--field-label`}>Additional Notify Contact</div>
                  <div className={`${namespace()}--card--main--field-value`}>{`${facilityType.General.AdditionalNotify2_Name}`}</div>
                </Column>}
              </Row> : null}
            </FormSection>
          </Row>
          <Row marginBottom={24} mobileMarginBottom={16}>
            <Column span={12} mobileSpan={12}>
              <TextField
                label="Description"
                rows={4}
                disabled={this.isFormDisabled()}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateFacilityDetailValue}
                validationRules={facilityFormState.Detail.ValidationRules.Description}
                value={facilityFormState.Detail.ActiveForm.Description}
              />
            </Column>
          </Row>
          <Row marginBottom={24} mobileMarginBottom={24}>
            <FormSection
              title={`Shared Settings - ${facilityType.General.NamePlural}`}
              disabled={this.isFormDisabled()}
              onEditClick={() => this.onOpenSharedSettings(() => actions.pushFacilityTypeSharedSettingsModal({facilityTypeFormSection: FacilityTypeFormSection.DESCRIPTION}))}
            >
              <Row>
                <Column className={`${namespace()}--card--main--col`} span={12} mobileSpan={12}>
                  <div className={`${namespace()}--card--main--field-label`}>Shared Description</div>
                  <div className={`${namespace()}--card--main--field-value ${!facilityType.FacilityTypeRow.Description ? 'empty' : ''}`}>{!facilityType ? '' : facilityType.FacilityTypeRow.Description ? (
                    <Ellipsis
                      configs={{
                        text: facilityType.FacilityTypeRow.Description,
                        lines: 5,
                      }}
                    />
                  ) : 'No Shared Description'}</div>
                </Column>
              </Row>
            </FormSection>
          </Row>
          <FieldSet
            className={sortedFacilityTypeAttributes && sortedFacilityTypeAttributes.length > 0 ? `${namespace()}--card--main--field-set--with-btn mobile-wrap-btn` : ''}
            fixedLegendFontSize
            marginBottom={0} mobileMarginBottom={0}
            newDesign legendMarginBottom={16}
            fontSize={18} name="Attributes"
            controls={sortedFacilityTypeAttributes && sortedFacilityTypeAttributes.length > 0 ? ManageSharedAttributeButton : null}>
            {!sortedFacilityTypeAttributes || sortedFacilityTypeAttributes.length === 0 ? ManageSharedAttributeButton : null}
            {sortedFacilityTypeAttributes && sortedFacilityTypeAttributes.length > 0 ? <Row styles={{flexWrap: 'wrap'}} className={`${namespace()}--card--main--attr-row`}>
              {sortedFacilityTypeAttributes.map((a) => {
                return (
                  <div className={`${namespace()}--card--main--attr`}>
                    <Checkbox
                      value={a.ID.toString()}
                      disabled={this.isFormDisabled()}
                      selected={DetailActiveForm.Attributes ? (!!DetailActiveForm.Attributes.find((attr) => attr === a.ID) || false) : false}
                      newDesign
                      label={<Text color='dark-gray' weight="regular"  size={16} marginBottom={0}>{a.Name}</Text>}
                      onChange={() => this.onAttributeCheck(a)}
                    />
                  </div>
                );
              })}
            </Row> : null}
          </FieldSet>
        </div>
      </Card>
    );
  };

  onPhotoUpload = (files: any[]) => {
    const { actions } = this.props;
    checkFacilityPermission(
      () => {
        if (files.length > 0) {
          files.forEach((file) => {
            if (!validateGalleryImage(file, this.props.actions)) return;
            const reader = new FileReader();
      
            reader.onabort = () => console.log('file reading was aborted for top gallery');
            reader.onerror = () => console.log('file reading has failed for top gallery');
            reader.onload = () => {
              // Do whatever you want with the file contents
              const dataUrl = reader.result;
              // `dataUrl` for local thumbnail (local update), `file` for submit the file (server call)
              this.props.actions.submitPhoto(file, dataUrl);
            };
            reader.readAsDataURL(file);
          });
        }
      },
      this.props.adminFacilityLocationCacheOne,
      true,
    );
  };
  onPhotoImport = (e) => {
    const {facilityFormState, actions} = this.props;
    e.stopPropagation();

    if (facilityFormState.processingImages) {
      actions.showTopFloatingAlert(EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE('importing'), undefined, 'orange');
      return;
    }

    actions.pushImportFromFacilityModal({importFromFacilityType: ImportType.PHOTOS});
  };
  onPhotosItemSortEnd = (oldIndex: number, newIndex: number, list) => {
    const {facilityFormState: {ActiveForm, Detail}, facilityFormState, adminFacilityLocationCacheOne} = this.props;
    if (facilityFormState.processingImages) {
      this.props.actions.showTopFloatingAlert(EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE('changing image order'), undefined, 'orange');
      return;
    }

    checkFacilityPermission(
      () => {
        const newIndexItem = list[newIndex];
        const oldIndexItem = list[oldIndex];
        const fullListNewIndex = Detail.ActiveForm.Images.findIndex((item) => item.ID === newIndexItem.ID);
        const fullListOldIndex = Detail.ActiveForm.Images.findIndex((item) => item.ID === oldIndexItem.ID);
        const offset = fullListNewIndex - fullListOldIndex;
        this.props.actions.moveGalleryItem(oldIndexItem, offset);
      },
      adminFacilityLocationCacheOne,
      true,
    );
  };
  onDeletePhoto = (image: GalleryImage) => {
    checkFacilityPermission(
      () => {
        this.props.actions.deletePhoto(image.ID);
      },
      this.props.adminFacilityLocationCacheOne,
      true,
    );
  };
  onRestorePhoto = (image: GalleryImage) => {
    checkFacilityPermission(
      () => {
        this.props.actions.deletePhoto(image.ID, true);
      },
      this.props.adminFacilityLocationCacheOne,
      true,
    );
  };
  renderPhotosSection = () => {
    const {apiSaving, actions, adminFacilityLocationCacheOne, facilityFormState: {ActiveForm, ValidationRules}, filteredPhotos} = this.props;
    return (
      <Card
        component={<section/>}
        category={CardCategory.CONTENT}
        marginBottom={24}
        mobileMarginBottom={16}
      >
        <div className={`${namespace()}--card--main`}>
          <FieldSet
            fixedLegendFontSize
            marginBottom={16} mobileMarginBottom={16}
            newDesign legendMarginBottom={0}
            fontSize={22} name="Photos"
            keepControlsPositionInMobile
          />
          <Row className={`${namespace()}--photos--row`}>
            <Column span={6} mobileSpan={12}>
              <Switch
                  disabled={this.isFormDisabled()}
                  className={`${namespace()}--photos--show-deleted`}
                  label="Show Deleted"
                  multilineLabel
                  newDesign
                  onChange={actions.updateValue}
                  value={!!ActiveForm.ShowDeleted}
                  validationRules={ValidationRules.ShowDeleted}
                />
            </Column>
            {filteredPhotos.length > 0 ? <div className={`${namespace()}--photos--actions`}>
              <Button
                id={generateDOMId("import-photos")}
                mobileMarginLeft={16}
                className={`${namespace()}--photos--button ${filteredPhotos.length > 0 ? 'has-images' : ''}`}
                color="white"
                disabled={this.isFormDisabled()}
                textColor='black'
                onClick={this.onPhotoImport}>
                IMPORT
              </Button>
              <Button
                className={`${namespace()}--photos--button ${filteredPhotos.length > 0 ? 'has-images' : ''}`}
                color="white"
                disabled={this.isFormDisabled()}
                textColor='black'
                onClick={this.onPhotoUpload}>
                UPLOAD
                  <input
                    disabled={this.isFormDisabled()}
                    type='file'
                    multiple
                    onChange={(e) => this.onPhotoUpload((e.target.files || []) as any[])} />
              </Button>
            </div> : null}
          </Row>
          {filteredPhotos.length > 0 && !isMobile ? <div className={`${namespace()}--photos--note`}>You can drag and drop images to change their order</div> : null}
          <Gallery
            id='photo-list'
            helperClass='photos-sort-helper'
            list={filteredPhotos}
            onGalleryItemSortEnd={this.onPhotosItemSortEnd}
            onDeleteGalleryImage={this.onDeletePhoto}
            onRestoreGalleryImage={this.onRestorePhoto}
            />
          <GalleryUpload 
            disabled={this.isFormDisabled()}
            supportedFormat={getValidPageGalleryImageTypesString()}
            hasImages={filteredPhotos.length > 0}
            onUploadImage={this.onPhotoUpload}
            onImportImage={this.onPhotoImport}
          />
        </div>
      </Card>
    );
  };

  onNumberChange = (value, validationRule) => {
    const {actions} = this.props;
    let v;
    if (value || value === 0) v = parseInt(value);
    if (isNaN(v)) actions.updateFacilityDetailValue(0, validationRule);
    else actions.updateFacilityDetailValue(v, validationRule);
  };

  renderBookingSettingsSection = () => {
    const {apiSaving, actions, facilityFormState} = this.props;

    const DetailActiveForm = facilityFormState.Detail.ActiveForm;
    return (
      <Card
        component={<section/>}
        category={CardCategory.CONTENT}
        marginBottom={24}
        mobileMarginBottom={16}
      >
        <div className={`${namespace()}--card--main`}>
          <FieldSet
            marginBottom={24} mobileMarginBottom={16}
            legendMarginBottom={0}
            fixedLegendFontSize
            emptyFieldSet
            newDesign
            fontSize={22} name="Booking Settings" />
          <Row marginBottom={8} mobileMarginBottom={8}>
            <Column span={12} mobileSpan={12}>
              <Select
                label='Online Booking'
                disabled={this.isFormDisabled()}
                hideOptional
                onChangeValue={actions.updateFacilityDetailValue}
                value={DetailActiveForm.AllowOnlineBookingID}
                validationRules={facilityFormState.Detail.ValidationRules.AllowOnlineBookingID}
                isNumber />
            </Column>
          </Row>
          <Row marginBottom={24} mobileMarginBottom={24}>
            <Column span={6} mobileSpan={12}>
              <TextField
                label="Maximum Accommodation"
                disabled={this.isFormDisabled()}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateFacilityDetailValue}
                validationRules={facilityFormState.Detail.ValidationRules.MaxCapacity}
                value={DetailActiveForm.MaxCapacity}
              />
            </Column>
          </Row>
          <FieldSet
            marginBottom={0} mobileMarginBottom={0}
            legendMarginBottom={16}
            fixedLegendFontSize
            newDesign
            fontSize={18} name="Booking Length"
          >
            <Row wrapInMobile wrap={false} marginBottom={8} mobileMarginBottom={8}>
              <Column marginRight={24} mobileMarginBottom={8} mobileMarginRight={0} span={6} mobileSpan={12}>
                <NumberField
                  label="Minimum Days"
                  disabled={this.isFormDisabled()}
                  value={DetailActiveForm.MinBookingLength}
                  validationRules={facilityFormState.Detail.ValidationRules.MinBookingLength}
                  minimum={0}
                  noWidthLimit
                  maximum={DetailActiveForm.MaxBookingLength}
                  noNegative
                  onChange={(v) => this.onNumberChange(v, facilityFormState.Detail.ValidationRules.MinBookingLength)}
                />
              </Column>
              <Column span={6} mobileSpan={12}>
                <NumberField
                  label="Maximum Days"
                  disabled={this.isFormDisabled()}
                  value={DetailActiveForm.MaxBookingLength}
                  validationRules={facilityFormState.Detail.ValidationRules.MaxBookingLength}
                  minimum={DetailActiveForm.MinBookingLength}
                  noWidthLimit
                  noNegative
                  onChange={(v) => this.onNumberChange(v, facilityFormState.Detail.ValidationRules.MaxBookingLength)}
                />
              </Column>
            </Row>
            <Row>
              <Column span={12} mobileSpan={12}>
                <Switch
                    margin={false}
                    disabled={this.isFormDisabled()}
                    newDesign
                    multilineLabel
                    helperText={['This will override Minimum Booking Length to require the entire weekend if the reservation is on the weekend.']}
                    label="Require Entire Weekend"
                    value={DetailActiveForm.RequireEntireWeekend as boolean}
                    validationRules={facilityFormState.Detail.ValidationRules.RequireEntireWeekend}
                    onChange={actions.updateFacilityDetailValue}
                />
              </Column>
            </Row>
            <Row>
              <Column span={12} mobileSpan={12}>
                <Switch
                    margin={false}
                    disabled={this.isFormDisabled()}
                    newDesign
                    multilineLabel
                    label="Require Start on Sunday"
                    value={DetailActiveForm.RequireStartOnSunday as boolean}
                    validationRules={facilityFormState.Detail.ValidationRules.RequireStartOnSunday}
                    onChange={actions.updateFacilityDetailValue}
                />
              </Column>
            </Row>
          </FieldSet>
        </div>
      </Card>
    );
  };

  onBookingTimesImport = () => {
    const {facilityFormState, actions} = this.props;
    if (facilityFormState.processingImages) {
      actions.showTopFloatingAlert(EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE('importing'), undefined, 'orange');
      return;
    }
    actions.pushImportFromFacilityModal({importFromFacilityType: ImportType.BOOKING_TIMES});
  };
  onRemoveTimeBlock = (index: number) => {
    this.props.actions.removeTimeBlock(index);
  };
  onNewTimeBlock = () => {
    this.props.actions.addTimeBlock();
  };
  renderBookingTimesSection = () => {
    const {apiSaving, actions, facilityFormState, adminFacilityLocationCacheTwoFacilityTypes} = this.props;

    const DetailActiveForm = facilityFormState.Detail.ActiveForm;
    const DetailValidationRules = facilityFormState.Detail.ValidationRules;
    const facilityType = adminFacilityLocationCacheTwoFacilityTypes.FacilitiesType;

    if (!facilityType) return null;
    return (
      <Card
        component={<section/>}
        category={CardCategory.CONTENT}
        marginBottom={24}
        mobileMarginBottom={16}
      >
        <div className={`${namespace()}--card--main ${!!DetailActiveForm.IsAllDay ? 'no-padding-bottom' : ''}`}>
          <FieldSet
            className={`${namespace()}--card--main--field-set--with-btn`}
            fixedLegendFontSize
            marginBottom={16} mobileMarginBottom={16}
            newDesign legendMarginBottom={0}
            fontSize={22} name="Booking Times"
            keepControlsPositionInMobile
            controls={<Button disabled={this.isFormDisabled()} onClick={this.onBookingTimesImport} color='white' textColor='black'>IMPORT</Button>} />
          <Row marginBottom={16} mobileMarginBottom={16}>
            <Column span={12} mobileSpan={12}>
              <Switch
                  margin={false}
                  disabled={this.isFormDisabled()}
                  newDesign
                  multilineLabel
                  label="Show Check-In/Out Times"
                  value={DetailActiveForm.ShowCheckInOutTimes as boolean}
                  validationRules={DetailValidationRules.ShowCheckInOutTimes}
                  onChange={actions.updateFacilityDetailValue}
              />
            </Column>
          </Row>
          <div className={`${namespace()}--card--main--field-label book-by`}>Book by</div>
          <Row marginBottom={24} mobileMarginBottom={16}>
            <Column style={{height: '40px', alignItems: 'center'}} layout='horizontal' span={6} mobileSpan={12}>
              <RadioGroupOption
                value={!!DetailActiveForm.IsAllDay}
                selected={!!DetailActiveForm.IsAllDay}
                ValidationRules={DetailValidationRules.IsAllDay}
                rawValue={true}
                onChange={actions.updateFacilityDetailValue}
                disabled={this.isFormDisabled()}
                newDesign
                label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>Full Day</Text>}
              />
            </Column>
            <Column style={{height: '40px', alignItems: 'center'}} layout='horizontal' span={6} mobileSpan={12}>
              <RadioGroupOption
                value={!!!DetailActiveForm.IsAllDay}
                selected={!!!DetailActiveForm.IsAllDay}
                ValidationRules={DetailValidationRules.IsAllDay}
                rawValue={false}
                onChange={actions.updateFacilityDetailValue}
                disabled={this.isFormDisabled()}
                newDesign
                label={<Text wrap={false} color='dark-gray' weight="regular"  size={16} marginBottom={0}>Custom Time Blocks</Text>}
              />
            </Column>
          </Row>
          {!!DetailActiveForm.IsAllDay ? (
            <Row marginBottom={0} mobileMarginBottom={0}>
              <FormSection
                title={`Shared Settings - ${facilityType.General.NamePlural}`}
                disabled={this.isFormDisabled()}
                onEditClick={() => this.onOpenSharedSettings(() => actions.pushFacilityTypeSharedSettingsModal({facilityTypeFormSection: FacilityTypeFormSection.BOOKING_TIMES}))}
              >
                <Row>
                  <Column mobileMarginBottom={16} className={`${namespace()}--card--main--col`} span={6} mobileSpan={12}>
                    <div className={`${namespace()}--card--main--field-label`}>{facilityType.General.ShowAsID === 1 ? 'Check-In' : 'Start'}</div>
                    <div className={`${namespace()}--card--main--field-value`}>{`${processHour(facilityType.BookingTimes.StartHour || 0)}:${convertTimeDigit(facilityType.BookingTimes.StartMin)} ${getPeriodText(facilityType.BookingTimes.StartPeriod)}`}</div>
                  </Column>
                  <Column className={`${namespace()}--card--main--col`} span={6} mobileSpan={12}>
                    <div className={`${namespace()}--card--main--field-label`}>{facilityType.General.ShowAsID === 1 ? 'Check-Out' : 'End'}</div>
                    <div className={`${namespace()}--card--main--field-value`}>{`${processHour(facilityType.BookingTimes.EndHour || 0)}:${convertTimeDigit(facilityType.BookingTimes.EndMin)} ${getPeriodText(facilityType.BookingTimes.EndPeriod)}${facilityType.BookingTimes.IsCheckoutNextDay ? ', Next Day' : ''}`}</div>
                  </Column>
                </Row>
              </FormSection>
            </Row>) : null
          }
          {!DetailActiveForm.IsAllDay && DetailActiveForm.TimeBlocks ? 
            <FieldSet
              fixedLegendFontSize
              marginTop={0} mobileMarginTop={0}
              marginBottom={0} mobileMarginBottom={0}
              newDesign legendMarginBottom={16}
              fontSize={18} name="Time Blocks"
              keepControlsPositionInMobile
              hideControlsInDesktop
              className={`${namespace()}--card--main--field-set--with-btn`}
              controls={<Button disabled={this.isFormDisabled()} onClick={this.onNewTimeBlock} color='white' textColor='black'>NEW</Button>}>
              {DetailActiveForm.TimeBlocks.map((tb, index) => {
                if (tb.Inactive) return null;
                const key = `timeblock_${tb.ID || (index * -1)}`;
                return (
                  <Row
                    className={`${namespace()}--time-block--row`}
                    key={key}
                    marginTop={0} mobileMarginTop={index === 0 ? 0 : 16} marginBottom={8} mobileMarginBottom={0}
                    wrap={false}
                  >
                    <Row wrapInMobile wrap={false}>
                      <Column span={6} mobileSpan={12} mobileMarginBottom={8} marginRight={24} mobileMarginRight={0}>
                        <TimePicker
                          label={facilityType.General.ShowAsID === 1 ? 'Check-In' : 'Start'}
                          disabled={this.isFormDisabled()}
                          onChangeValue={actions.updateFacilityDetailValue}
                          hourValue={DetailActiveForm[getTimeBlockAttrKey(index, TimeAttributeKeys.START_HOUR)]}
                          minValue={DetailActiveForm[getTimeBlockAttrKey(index, TimeAttributeKeys.START_MIN)]}
                          periodValue={DetailActiveForm[getTimeBlockAttrKey(index, TimeAttributeKeys.START_PERIOD)]}
                          hourValidationRules={DetailValidationRules[getTimeBlockAttrKey(index, TimeAttributeKeys.START_HOUR)]}
                          minValidationRules={DetailValidationRules[getTimeBlockAttrKey(index, TimeAttributeKeys.START_MIN)]}
                          periodValidationRules={DetailValidationRules[getTimeBlockAttrKey(index, TimeAttributeKeys.START_PERIOD)]}
                          customizedId={`${key}_start`}
                        />
                      </Column>
                      <Column span={6} mobileSpan={12} marginRight={0} mobileMarginRight={0}>
                        <TimePicker
                          label={facilityType.General.ShowAsID === 1 ? 'Check-Out' : 'End'}
                          disabled={this.isFormDisabled()}
                          onChangeValue={actions.updateFacilityDetailValue}
                          hourValue={DetailActiveForm[getTimeBlockAttrKey(index, TimeAttributeKeys.END_HOUR)]}
                          minValue={DetailActiveForm[getTimeBlockAttrKey(index, TimeAttributeKeys.END_MIN)]}
                          periodValue={DetailActiveForm[getTimeBlockAttrKey(index, TimeAttributeKeys.END_PERIOD)]}
                          hourValidationRules={DetailValidationRules[getTimeBlockAttrKey(index, TimeAttributeKeys.END_HOUR)]}
                          minValidationRules={DetailValidationRules[getTimeBlockAttrKey(index, TimeAttributeKeys.END_MIN)]}
                          periodValidationRules={DetailValidationRules[getTimeBlockAttrKey(index, TimeAttributeKeys.END_PERIOD)]}
                          customizedId={`${key}_end`}
                        />
                      </Column>
                    </Row>
                    <ActionButton disabled={this.isFormDisabled()} className={`${namespace()}--time-block--close`} icon={CloseIcon} onClick={() => this.onRemoveTimeBlock(index)} />
                    {DetailActiveForm.TimeBlocks && index !== DetailActiveForm.TimeBlocks.length - 1 ? <div className={`${namespace()}--time-block--divider`} /> : null}
                  </Row>
                );
              })}
            </FieldSet> : null
          }
          {!DetailActiveForm.IsAllDay ? <Row><Button disabled={this.isFormDisabled()} onClick={this.onNewTimeBlock} color='white' textColor='black' className={`${namespace()}--time-block--new`}>NEW</Button></Row> : null}
        </div>
      </Card>
    );
  };

  onPricingImport = () => {
    const {facilityFormState, actions} = this.props;
    if (facilityFormState.processingImages) {
      actions.showTopFloatingAlert(EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE('importing'), undefined, 'orange');
      return;
    }
    actions.pushImportFromFacilityModal({importFromFacilityType: ImportType.PRICING});
  };
  onCopyFromInCouncil = (PricingTierID: number) => {
    this.props.actions.copyFromInCouncil(PricingTierID);
  };
  onClickSelectGLAccountButton = () => {
    if (this.props.facilityFormState && this.props.facilityFormState.processingImages) {
      this.props.actions.showTopFloatingAlert(EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    this.props.actions.pushSelectGLAccountModal({glAccountContext: 'facility-form'});
  };
  onEditGLAccount = (account: GLAccount) => {
    if (this.props.facilityFormState && this.props.facilityFormState.processingImages) {
      this.props.actions.showTopFloatingAlert(EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    checkGLAccountPermission(
      () => {
        this.props.actions.pushFormModal(ModalTypes.GL_ACCOUNT_FORM, true, {id: account.ID});
      },
      this.props.cacheZero,
      true,
    );
  };
  onRemoveGLAccount = () => {
    const {facilityFormState} = this.props;
    this.props.actions.updateFacilityDetailValue(undefined, facilityFormState.Detail.ValidationRules.GLAccountID);
  };
  renderPricingSection = () => {
    const {apiSaving, actions, facilityFormState, facilityPricingTypes, selectedGLAccount} = this.props;
    const DetailActiveForm = facilityFormState.Detail.ActiveForm;
    const DetailValidationRules = facilityFormState.Detail.ValidationRules;
    return (
      <Card
        component={<section/>}
        category={CardCategory.CONTENT}
        marginBottom={24}
        mobileMarginBottom={16}
      >
        <div className={`${namespace()}--card--main`}>
          <FieldSet
            className={`${namespace()}--card--main--field-set--with-btn`}
            fixedLegendFontSize
            marginBottom={5} mobileMarginBottom={5}
            newDesign legendMarginBottom={0}
            fontSize={22} name="Pricing"
            keepControlsPositionInMobile
            controls={<Button disabled={this.isFormDisabled()} onClick={this.onPricingImport} color='white' textColor='black'>IMPORT</Button>} />
          <div className={`${namespace()}--contact-tentaroo`}>
            {'To add or remove pricing tiers, '}<Link black medium externalLink={openPricingTierForm()}>contact Tentaroo</Link>
          </div>
          <Row>
            <Column span={12} mobileSpan={12}>
              <ModalSelect
                label='GL Account'
                selectButtonLabel="SELECT GL ACCOUNT"
                disabled={this.isFormDisabled()}
                paddingBottom={24}
                mobilePaddingBottom={24}
                hideError={!!selectedGLAccount}
                selectedCard={selectedGLAccount ?
                  <GLAccountCard
                    type={GLAccountCardType.MODAL_SELECT}
                    showDeletedPrompt
                    disabled={this.isFormDisabled()}
                    marginTop={12}
                    onEdit={this.onEditGLAccount}
                    onRemove={this.onRemoveGLAccount}
                    account={selectedGLAccount}
                  /> : null}
                onClickSelectButton={this.onClickSelectGLAccountButton}
                validationRules={DetailValidationRules.GLAccountID}
                value={DetailActiveForm.GLAccountID}
              />
            </Column>
          </Row>
          <Row marginBottom={24} mobileMarginBottom={24} styles={{position: 'relative'}}>
            <Column span={6} mobileSpan={12}>
              <NumberField
                  label="Minimum Number of Per Person Fees"
                  disabled={this.isFormDisabled()}
                  value={DetailActiveForm.MinPerPersonFeesToBill}
                  validationRules={DetailValidationRules.MinPerPersonFeesToBill}
                  minimum={0}
                  maximum={32767}
                  noWidthLimit
                  noNegative
                  helperText='Charge Per Person Per Day rates for a minimum number of people, even if less people are set in the reservation.'
                  onChange={(v) => this.onNumberChange(v, DetailValidationRules.MinPerPersonFeesToBill)}
                />
            </Column>
          </Row>
          {DetailActiveForm.Pricing ? DetailActiveForm.Pricing.map((p, index) => {
            const pricingType = !facilityPricingTypes ? null : facilityPricingTypes.find((pt) => pt.ID === p.PricingTierID);
            if (!pricingType) return null;
            const isLastPricing = DetailActiveForm.Pricing && index === DetailActiveForm.Pricing.length - 1;
            return (
              <FieldSet
                key={`pricing_${p.PricingTierID}`}
                className={pricingType.ID !== 1 ? `${namespace()}--card--main--field-set--with-btn mobile-wrap-btn` : ''}
                fixedLegendFontSize
                marginBottom={isLastPricing ? 0 : 16} mobileMarginBottom={isLastPricing ? 0 : 16}
                newDesign legendMarginBottom={16}
                fontSize={18} name={pricingType.Name}
                controls={pricingType.ID !== 1 ? <Button disabled={this.isFormDisabled()} onClick={() => this.onCopyFromInCouncil(p.PricingTierID)} color='white' textColor='black'>COPY FROM DEFAULT / IN-COUNCIL</Button> : null}>
                  <Row wrap={false} marginBottom={isLastPricing ? 0 : 8} mobileMarginBottom={isLastPricing ? 0 : 8}>
                    <Column marginRight={24} span={5} mobileSpan={6}>
                      <MoneyField
                        disabled={this.isFormDisabled()}
                        dollarSignPrefix
                        value={DetailActiveForm[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_DAY_AMOUNT)]}
                        validationRules={DetailValidationRules[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_DAY_AMOUNT)]}
                        onBlur={actions.updateFacilityDetailValue}
                        onChange={actions.simpleUpdate}
                        useFourDecimals
                        label='Per Day Rate'
                        placeHolder="$0.0000"
                        overflowLabel
                      />
                    </Column>
                    <Column span={5} mobileSpan={6}>
                      <MoneyField
                        disabled={this.isFormDisabled()}
                        dollarSignPrefix
                        value={DetailActiveForm[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_DAY_AMOUNT_UPFRONT)]}
                        validationRules={DetailValidationRules[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_DAY_AMOUNT_UPFRONT)]}
                        onBlur={actions.updateFacilityDetailValue}
                        onChange={actions.simpleUpdate}
                        useFourDecimals
                        label='Portion Due Upfront'
                        placeHolder="$0.0000"
                        overflowLabel
                      />
                    </Column>
                  </Row>
                  <Row wrap={false} marginBottom={isLastPricing ? 0 : 8} mobileMarginBottom={isLastPricing ? 0 :8}>
                    <Column marginRight={24} span={5} mobileSpan={6}>
                      <MoneyField
                        disabled={this.isFormDisabled()}
                        dollarSignPrefix
                        value={DetailActiveForm[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT)]}
                        validationRules={DetailValidationRules[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT)]}
                        onBlur={actions.updateFacilityDetailValue}
                        onChange={actions.simpleUpdate}
                        useFourDecimals
                        label='Per Person Per Day Rate'
                        placeHolder="$0.0000"
                        overflowLabel
                      />
                    </Column>
                    <Column span={5} mobileSpan={6}>
                      <MoneyField
                        disabled={this.isFormDisabled()}
                        dollarSignPrefix
                        value={DetailActiveForm[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT_UPFRONT)]}
                        validationRules={DetailValidationRules[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT_UPFRONT)]}
                        onBlur={actions.updateFacilityDetailValue}
                        onChange={actions.simpleUpdate}
                        useFourDecimals
                        label='Portion Due Upfront'
                        placeHolder="$0.0000"
                        overflowLabel
                      />
                    </Column>
                  </Row>
              </FieldSet>
            );
          }) : null}
        </div>
      </Card>
    );
  };

  isFormDisabled = () => {
    const {apiSavingMap, adminFacilityLocationCacheOne} = this.props;

    if (!adminFacilityLocationCacheOne.FacilitiesLocation) return true;

    return apiSavingMap[UpdateFacilitySubmitActions.requestType] || !adminFacilityLocationCacheOne.FacilitiesLocation.hasFacilityEdit;
  };

  onSave = () => {
    const {facilityFormState, actions} = this.props;
    if (facilityFormState.processingImages) {
      actions.showTopFloatingAlert(EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE('saving this facility'), undefined, 'orange');
      return;
    }
    checkFacilityPermission(
      () => {
        this.props.actions.apiSubmitForm(this.props.router, this.props.routes);
      },
      this.props.adminFacilityLocationCacheOne,
      true,
    );
  };

  onDelete = () => {
    const { actions, facilityFormState, adminFacilityLocationCacheTwoFacility } = this.props;
    if (facilityFormState.processingImages) {
      actions.showTopFloatingAlert(EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE('deleting this facility'), undefined, 'orange');
      return;
    }

    checkFacilityPermission(
      () => {
        if (adminFacilityLocationCacheTwoFacility.FacilitiesFacility) {
          actions.deleteFacility(
            adminFacilityLocationCacheTwoFacility.FacilitiesFacility.ID,
            this.props.router,
            this.props.routes,
          );
        }
      },
      this.props.adminFacilityLocationCacheOne,
      true,
    );
  };

  public render() {
    const {params, inert, apiLoadingMap, apiSavingMap, openedModals, adminFacilityLocationCacheOne, adminFacilityLocationCacheTwoFacility, facilityFormState: {SubmitErrorMessage, Detail: {ActiveForm}}, adminFacilityLocationCacheTwoFacilityTypes} = this.props;
    if (!isAdminFacilitiesLocationCacheOnePopulated()) {
      return <LoadingAll />;
    }
    if (adminFacilityLocationCacheTwoFacility.FacilitiesFacility && adminFacilityLocationCacheTwoFacility.FacilitiesFacility.Inactive) {
      return (
        <NotFound message={INVALID_FACILITY} />
      );
    }
    const pageLoading = !isAdminFacilityLocationCacheTwoFacilityPopulated(adminFacilityLocationCacheTwoFacility, ActiveForm) || (isAdminFacilityLocationCacheTwoFacilityPopulated(adminFacilityLocationCacheTwoFacility, ActiveForm) && apiLoadingMap[GetFacility.requestType] && (!openedModals || openedModals.length === 0));
    const saving = apiSavingMap[DeleteFacilitySubmitActions.requestType] || apiSavingMap[ImportBookingTimesSubmitActions.requestType] || apiSavingMap[ImportPricingSubmitActions.requestType] || apiSavingMap[ImportImagesSubmitActions.requestType] || apiSavingMap[UpdateFacilitySubmitActions.requestType] || apiSavingMap[UpdateFacilityTypeSubmitActions.requestType];
    const idFromUrl = getFacilityIdFromPath(params);
    return (
      <Main inert={inert} isAdminFacilityLocationPage>
        <MainContent
          wide
          className={namespace()}
          leftContent={
            <div className={`${namespace()}--sidebar`}>
              <FacilityList
                showFilters
                forceMobile
              />
            </div>
          }
          header={<div />}
          rightGutter
        >
          <Form
            onSubmit={e => e.preventDefault()}
            className={`${namespace()}--page-content`}
            actions = {
              <FormActions wide handleLeftContent leftGutter leftGutterSize={356}>
                <Row>
                  <Column expand>
                    <Button id={generateDOMId("admin-fac-save-btn")} disabled={this.isFormDisabled() || pageLoading} color="white" flat textColor="black" big onClick={this.onSave}>SAVE</Button>
                  </Column>
                  <Column>
                    <Button disabled={this.isFormDisabled() || pageLoading} color="white" flat textColor="red" big onClick={this.onDelete}>DELETE</Button>
                  </Column>
                </Row>
              </FormActions>
            }>
            {!pageLoading && SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
            {!pageLoading && adminFacilityLocationCacheOne.FacilitiesLocation && adminFacilityLocationCacheOne.FacilitiesLocation.hasFacilityEditReason ? <Alert className={`${namespace()}--alert`}>{adminFacilityLocationCacheOne.FacilitiesLocation.hasFacilityEditReason}</Alert> : null}
            {!pageLoading && idFromUrl > 0 ? this.renderGeneralSection() : null}
            {!pageLoading && idFromUrl > 0 ? this.renderPhotosSection() : null}
            {!pageLoading && idFromUrl > 0 ? this.renderBookingSettingsSection() : null}
            {!pageLoading && idFromUrl > 0 ? this.renderBookingTimesSection() : null}
            {!pageLoading && idFromUrl > 0 ? this.renderPricingSection() : null}
            {pageLoading && idFromUrl > 0 ? <Loader className={`${namespace()}--loader`} center /> : null}
          </Form>
        </MainContent>
        {(idFromUrl < 0 || saving) && <Loader className={`${namespace()}--fullscreen-loader`} center />}
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const showAsOptionSelector = makeShowAsOptionSelector();
  const filteredPhotosSelector = makeFilteredFacilityPhotosSelector();
  const sortedFacilityTypeAttributesSelector = makeSortedFacilityTypeAttributesSelector();
  const selectedGLAccountSelector = makeSelectedGLAccountSelector();
  return {
    cacheZero: state.cacheZero,
    apiSaving: state.app.apiSaving,
    apiSavingMap: state.app.apiSavingMap,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
    adminFacilityLocationCacheTwoFacility: state.adminFacilityLocation.cacheTwoFacility,
    adminFacilityLocationCacheTwoFacilityTypes: state.adminFacilityLocation.cacheTwoFacilityTypes,
    facilityFormState: state.adminFacilityLocation.facilities.facility.form,
    facilityTypeFormState: state.adminFacilityLocation.facilities.facilityType.form,
    sortedFacilityTypeAttributes: sortedFacilityTypeAttributesSelector(state),
    showAsOption: showAsOptionSelector(state),
    facilityPricingTypes: state.cacheZero.options ? state.cacheZero.options.FacilityPricingTypes : [],
    filteredPhotos: filteredPhotosSelector(state),
    selectedGLAccount: selectedGLAccountSelector(state),
    openedModals: state.app.openedModals,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...cacheTwoFacilityActionCreators,
    ...cacheTwoFacilityTypeActionCreators,
    ...facilityFormActionCreators,
  }, dispatch),
});
const ConnectedAdminFacilityPage = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(AdminFacilityPage);

export default withRouter<{}>(ConnectedAdminFacilityPage);
