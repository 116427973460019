import * as React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import '../../../styles/elements/bottom-bar/index.scss';
import isDescendant from '../../../utils/isDescendant';
import whichAnimationEvent from "../../../utils/whichAnimationEvent";

import {namespace} from "./constants";
import {Nullable} from '../../../utils/dataHelper';

interface Props {
  open?: boolean;
  onOpen?: () => any;
  onClose?: () => any;
  children?: React.ReactNode;
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
};

interface State {
  closing?: boolean;
}

class BottomBar extends React.Component<Props, State> {
  public static childContextTypes = {
    close: PropTypes.func
  };

  public state: State = {
    closing: false
  };

  public getChildContext() {
    return {
      close: this.close
    };
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (nextProps.open && !this.props.open && this.props.onOpen) {
      this.props.onOpen();
    } else if (!nextProps.open && this.props.open && !this.state.closing) {
      this.close();
    }
    if (nextProps.open) {
      document.addEventListener('click', this.handleDocumentClick);
    } else {
      document.removeEventListener('click', this.handleDocumentClick);
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  public handleDocumentClick = (event: PointerEvent) => {
    if (event.target !== findDOMNode(this) && !isDescendant(findDOMNode(this) as Nullable<Element>, event.target as Nullable<Element>)) {
      this.close();
    }
  };

  public close = () => {
    const el = (findDOMNode(this) as Nullable<Element>)?.getElementsByClassName(`${namespace()}--container`)[0] as Nullable<HTMLDivElement>;
    const animationCallback = () => {
      const whichAni = whichAnimationEvent();
      if (whichAni) el?.removeEventListener(whichAni, animationCallback);
      if (this.props.onClose) this.props.onClose();
      this.setState({ closing: false });
    };
    const whichAni = whichAnimationEvent();
    if (whichAni) el?.addEventListener(whichAni, animationCallback);
    this.setState({ closing: true });
  };

  public render() {
    const { open, children, mobile, tablet, desktop } = this.props;
    const { closing } = this.state;

    let className = namespace();
    if (mobile || tablet || desktop) {
      className += ' hide-all-except';
      if (mobile) className += ' show-mobile';
      if (tablet) className += ' show-tablet';
      if (desktop) className += ' show-desktop';
    }

    return (
      <div className={className + (closing ? ` closing` : (open ? ' open' : ''))}>
        <div className={`${namespace()}--backdrop`} onClick={this.close}/>
        <div className={`${namespace()}--container`}>
          {children}
        </div>
      </div>
    );
  }
}

export default BottomBar;
export { default as BottomBarActions } from './Actions';
