import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/elements/feature-list/item.scss';
import { CheckMarkIcon, CloseIcon } from '../../Icons';

const namespace = (): string => `${ns()}--item`;

interface Props {
  children?: React.ReactNode;
  available?: boolean;
}

const Item: React.SFC<Props> = ({ children, available }: Props): React.ReactElement<Props> => (
  <li className={namespace()}>
    <div className={`${namespace()}--wrapper`}>
      {available ? (
        <CheckMarkIcon color="green" width={15} height={15} className={`${namespace()}--wrapper--icon available`}/>
      ) : (
        <CloseIcon color="gray" width={15} height={15} className={`${namespace()}--wrapper--icon`}/>
      )}
      <span className={`${namespace()}--wrapper--text` + (available ? ' available' : '')}>{children}</span>
    </div>
  </li>
);

export default Item;
