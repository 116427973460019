import {ActionCreator} from "../index";
import {createRequestActions} from '../Validation/actionCreator';
import {FacilitiesAvailabilitiesDate, FacilitiesAvailability} from "../../models/api/cacheTwoFacilties";
import { typeName, Action } from "../../utils/StrongActions";
import { Moment } from "moment";

export const SUFFIX = '__CACHE_TWOB_FACILITIES';
export const GetFacilityAvailabilitiesB = createRequestActions(SUFFIX);

@typeName('SET_CACHE_TWOB_FACILITIES')
export class SetCacheTwoBFacilities extends Action {
  constructor(
    public facilityID: number,
    public ftbID: number | null,
    public FacilityAvailabilities: FacilitiesAvailability,
    public FacilityAvailabilitiesDates: Array<FacilitiesAvailabilitiesDate>,
    public startDate: Moment,
  ) {super();}
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  getFacilityAvailabilitiesB: (
    GroupIDi: number,
    GroupTS: number,
    FacilityLocationID: number,
    GetOptions: boolean,
    GetGroupData: boolean,
    GetFacilityLocationData: boolean,
    FacilityID: number,
    ftbID: number | null,
    StartDate?: string | null,
  ): ActionCreator => dispatch => {
    dispatch(GetFacilityAvailabilitiesB.request(
      {GroupIDi, GroupTS, FacilityLocationID, GetOptions, GetGroupData, GetFacilityLocationData, FacilityID, StartDate, ftbID}
    ));
  },
  setCacheTwoBFacilities: (
    facilityID: number,
    ftbID: number | null,
    FacilityAvailabilities: FacilitiesAvailability,
    FacilityAvailabilitiesDates: Array<FacilitiesAvailabilitiesDate>
  ): ActionCreator => (dispatch, getState) => {
    dispatch(new SetCacheTwoBFacilities(facilityID, ftbID, FacilityAvailabilities, FacilityAvailabilitiesDates, getState().facilities.location.startDate));
  }
};

