import * as React from 'react';
import { default as attributes, MarginProps } from '../../../styles';
import '../../../styles/elements/title/index.scss';
import '../../../styles/elements/title/controls.scss';
import Text from "../Text";
import Num from "../Num";

export const namespace = (): string => 'elements--title';

export interface TitleProps extends MarginProps {
  style?: React.CSSProperties;
  importance?: 1 | 2 | 3 | 4 | 5 | 6;
  children?: React.ReactNode;
  extra?: React.ReactNode;
  className?: string;
  additionalInfo?: boolean;
  size?: number;
  mobileSize?: number;
  line?: number;
  num?: number;
  controls?: React.ReactNode;
  controlsWrap?: boolean;
  required?: boolean;
  center?: boolean;
  color?: 'gray';
  mobileColor?: 'gray';
  onClick?: () => void;
}

const Title: React.SFC<TitleProps> = (
  {
    additionalInfo, children, num, style: inheritedStyle, size, mobileSize, controls, line, importance,
    controlsWrap, required, mobileColor, color, extra, className, onClick, center, ...props
  }: TitleProps
):React.ReactElement<TitleProps> => {
  className = attributes(namespace(), className, props);
  if (additionalInfo) className += ' additional-info';
  if (size) className += ` size-${size}`;
  if (mobileSize) className += ` mobile-size-${mobileSize}`;
  if (!controlsWrap) className += ' controls-nowrap';
  if (color) className += ` ${color}`;
  if (mobileColor) className += ` mobile-${mobileColor}`;

  const style: React.CSSProperties = { ...inheritedStyle };
  if (line) style.lineHeight = `${line}px`;

  if (additionalInfo) return (<h4 className={className}>{children}</h4>);

  return React.createElement(
    `h${importance}`,
    {className, style},
    <div className={`${namespace()}--wrapper ${center ? 'center' : ''}`} onClick={onClick}>
      <span className={`${namespace()}--text`}>{children}</span>
      {extra ? extra : null}
      {num || num === 0 ? <Num className={`${namespace()}--num`}>{num}</Num> : null}
      {required ? <Text color="red" size={14} className={`${namespace()}--required`}>required</Text> : null}
    </div>,
    (controls ? <div className={`${namespace()}--controls`}>{controls}</div> : null)
  );
};

Title.defaultProps = {
  controlsWrap: true,
  importance: 1
};

export default Title;
