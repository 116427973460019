import { ActionCreator } from '../../index';
import * as moment from 'moment';
import { typeName, Action } from '../../../utils/StrongActions';

// @typeName("FACILITIES_LOCATION_INIT") export class FacilitiesLocationInit extends Action {
//   constructor(public loc: FacilityLocation) { super(); }
// }
@typeName("FACILITY_LOCATION_FACILITY_RESET") export class FacilitiesFacilityReset extends Action {}
export const FACILITY_LOCATION_FACILITY_CHANGE_DATE = 'FACILITY_LOCATION_FACILITY_CHANGE_DATE';
@typeName("FACILITY_LOCATION_FACILITY_CHANGE_DATE")
export class ChangeDate extends Action {
  constructor(public date: moment.Moment) {super();}
}
@typeName("FACILITY_LOCATION_FACILITY_SHOW_MONTH_MENU")
export class ShowMonthMenu extends Action {
  constructor(public show: boolean) {super();}
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  reset: (): ActionCreator => dispatch => dispatch(new FacilitiesFacilityReset()),
  changeDate: (date: moment.Moment): ActionCreator => dispatch => dispatch(new ChangeDate(date)),
  showMonthMenu: (show: boolean): ActionCreator => dispatch => dispatch(new ShowMonthMenu(show)),
};
