import * as React from 'react';
import {Title, ViewControls, MonthPicker, Tabs, Tab, FacilityCard, ContentBlock} from '../../../Elements';
import CompactView from './CompactView';
import {withRouter, RouteComponentProps} from "react-router";
import {navPush} from "../../../../utils/navHelper";
import {FAC_FACILITY, URLS} from "../../../../constants/urls";
import {FacilityLocation} from "../../../../models/api/cacheOne";
import {noop, spaceTo_} from "../../../../utils";
import {CacheTwoFacilitiesState} from "../../../../store/CacheTwoFacilities";
import {FacilityLocationState} from "../../../../store/Facilities/Location";
import {Actions as CachTwoActions, GetFacilityAvailabilities} from "../../../../store/CacheTwoFacilities/actions";
import {Actions as CacheTwoBActions} from "../../../../store/CacheTwoBFacilities/actions";
import {Actions as CacheOneActions} from "../../../../store/CacheOne/actions";
import {Actions as RollbackActions} from "../../../../store/Rollback/actions";
import {Actions} from "../../../../store/Facilities/Location/actions";
import {Actions as CacheZeroActions} from "../../../../store/CacheZero/actions";
import * as moment from 'moment';
import {CacheZeroState} from "../../../../store/CacheZero";
import {API_DATE_FORMAT} from "../../../../utils/dateHelper";
import {
  FacilitiesAvailability, FacilityLocation_FacilityType,
} from "../../../../models/api/cacheTwoFacilties";
import { generateDOMId } from '../../../../utils/cypressHelper';

interface Props {
  selectedLocation: FacilityLocation;
  cacheZero: CacheZeroState;
  cacheTwoFacilities: CacheTwoFacilitiesState;
  facilityLocation: FacilityLocationState;
  showLoaders?: boolean;
  actions: Actions & CachTwoActions & CacheOneActions & CacheZeroActions & CacheTwoBActions & RollbackActions;
  apiLoadingMap: any;
  selectedFacilityType: FacilityLocation_FacilityType;
}

type ConnectedProps = RouteComponentProps<{}, {}>;

class CheckDates extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  onSelectNewFacilityType = (id: number) => {
    this.props.actions.selectFacilityTypeTab(id);
    this.props.actions.getFacilityAvailabilities(
      this.props.cacheZero.options!.Group!.IDi,
      this.props.cacheZero.options!.Group!.TS,
      this.props.cacheTwoFacilities.locationID,
      false,
      false,
      id,
      this.props.facilityLocation.startDate.format(API_DATE_FORMAT)
    );
  };

  onChangeMonth = (startDate: moment.Moment) => {
    this.props.actions.changeDate(startDate);
    this.props.actions.getFacilityAvailabilities(
      this.props.cacheZero.options!.Group!.IDi,
      this.props.cacheZero.options!.Group!.TS,
      this.props.cacheTwoFacilities.locationID,
      false,
      false,
      this.props.facilityLocation.selectedFacilityTypeTab!,
      startDate.format(API_DATE_FORMAT)
    );
  };

  onChangeView = (view) => {
    this.props.actions.setView(view);
  };

  public render() {
    const {
      actions, cacheTwoFacilities: {FacilityLocation_FacilityTypes, FacilityLocation_SelectedFacilityTypeID},
      facilityLocation: {selectedFacilityTypeTab, startDate, disableNext, disablePrev, showContextMenu, view}
    } = this.props;
    return (
      <ContentBlock>
        <Title
          className={'check-dates-top'}
          size={28}
          mobileSize={22}
          extra={<ViewControls selected={view as any} onChange={this.onChangeView}/>}
          controls={<MonthPicker
            date={startDate}
            disableNext={disableNext}
            disablePrev={disablePrev}
            showContextMenu={showContextMenu}
            onChange={this.onChangeMonth}
            onShowMonthMenu={actions.showMonthMenu}
          />}
        >
          Check Dates
        </Title>
        <Tabs>
          {FacilityLocation_FacilityTypes!.map((type, index) => (
            <Tab
              id={generateDOMId(`fac-type-tab-${type.ID}`)}
              selected={(!selectedFacilityTypeTab && index == 0) ? true : selectedFacilityTypeTab === type.ID}
              onClick={() => this.onSelectNewFacilityType(type.ID)}
            >
              {type.Name.toUpperCase()}
            </Tab>
          ))}
        </Tabs>
        {view === 'grid' ? this.renderGridView() : this.renderCompactView()}
      </ContentBlock>
    );
  }

  onClickFacilityCard = (avail: FacilitiesAvailability) => {
    const {selectedLocation, actions, apiLoadingMap, facilityLocation: {startDate}} = this.props;

    let url = `${URLS.FACILITIES}/${selectedLocation.ID}/${spaceTo_(selectedLocation.Name)}/${FAC_FACILITY}`;
    if (avail.ftbID) {
      url = `${url}/${avail.FacilityID}/${avail.ftbID}/${spaceTo_(avail.Name)}`;
    } else {
      url = `${url}/${avail.FacilityID}/${spaceTo_(avail.Name)}`;
    }
    navPush(this.props.router, url);
  };

  private renderGridView() {
    const {
      apiLoadingMap,
      selectedLocation, cacheTwoFacilities: {FacilitiesAvailabilities, FacilitiesAvailabilitiesDates},
      facilityLocation: {startDate}, selectedFacilityType
    } = this.props;
    // todo: use real start and end dates
    const end = moment(startDate).startOf('month').add(3, 'months');
    if (FacilitiesAvailabilities === undefined) {
      return <FacilityCard
        facilityLocation={selectedLocation}
        onClick={noop}
        name="skeleton"
        skeleton
        availabilities={undefined}
        availabilityDates={undefined}
        startTime={startDate}
        endTime={end}
        nameSingular={selectedFacilityType.NameSingular}
        avail={{} as FacilitiesAvailability}
      />;
    }
    if (FacilitiesAvailabilities === null || FacilitiesAvailabilities.length === 0) {
      // todo: what if no FacilitiesAvailabilities?
    }
    return FacilitiesAvailabilities.map((avail) => <FacilityCard
      facilityLocation={selectedLocation}
      key={0}
      onClick={() => this.onClickFacilityCard(avail)}
      name={avail.Name}
      availabilities={avail.Availabilities}
      availabilityDates={apiLoadingMap[GetFacilityAvailabilities.requestType] ? undefined : FacilitiesAvailabilitiesDates}
      startTime={startDate}
      endTime={end}
      avail={avail}
      nameSingular={selectedFacilityType.NameSingular}
      image={(avail.Images && avail.Images.length > 0) ? avail.Images[0] : undefined}
      color={`#${avail.Color}`}
      showArrowIcon
    />);
  }

  private renderCompactView() {
    const {
      selectedLocation, cacheTwoFacilities, facilityLocation: {startDate}, apiLoadingMap
    } = this.props;
    // todo: use real start and end dates
    const end = moment(startDate).startOf('month').add(3, 'months');
    return <CompactView
      cacheTwoFacilities={cacheTwoFacilities}
      facilityLocation={selectedLocation}
      startTime={startDate}
      endTime={end}
      loading={apiLoadingMap[GetFacilityAvailabilities.requestType]}
      onClickAvail={this.onClickFacilityCard}
      hasArrowIcon
    />;
  }
}

export default withRouter(CheckDates as any);
