import { IValidator, FormDefinition } from "./validation";
import { SUFFIX, ConfigDeleteBlackoutDateModal, ResetBlackoutDateHome } from "./actions";
import { apiValidationReducerCreator } from "../../../Validation/reducerCreator";
import { BlackoutDate } from "../../../../models/api/options";
import { ClearAllCache, PopModal } from "../../../../store/App/actions";
import { ApplicationState } from "../../..";
import { createSelector } from "reselect";
import { FacilitiesBlackoutDate } from "../../../../models/api/adminFacilitiesCacheOne";
import { ModalTypes } from "../../../../utils/modalHelper";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../utils/StrongActions";
import { ADMIN_FACILITY_MODULE_SUFFIX, ClearAllAdminFacilityLocationCache } from "../../CacheOne/actions";
import { APISuccess, SUBMIT_SUCCESS_ACTION, SUCCESS_ACTION, WithRootState } from "../../../Validation/actionCreator";
import { setDefaults } from "../../../../utils/validator";

export interface BlackoutDatesHomeActiveForm {
  ShowExpired?: boolean;
}

export interface BlackoutDatesHomeState {
  ActiveForm: BlackoutDatesHomeActiveForm;
  ValidationRules: IValidator;
  deletingBlackoutDate?: BlackoutDate;
}

const getInitialState = () => {
  return {
    ActiveForm: {
    },
    ValidationRules: { ...FormDefinition }
  };
};

export const makeBlackoutDatesSelector = () => {
  return createSelector(
    (state: ApplicationState) => state.adminFacilityLocation.cacheOne.FacilitiesBlackoutDates || [],
    (blackoutDates: FacilitiesBlackoutDate[]) => {
      const result: (FacilitiesBlackoutDate | null)[] = [...blackoutDates];

      if (result.length > 0) result.push(null);
      
      return result;
    }
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const BlackoutDatesHomeReducer: Reducer<BlackoutDatesHomeState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);

  // TODO: - use init action - another form that need to be init together with Settings
  if (act.type.includes(ADMIN_FACILITY_MODULE_SUFFIX) && (act.type.includes(SUCCESS_ACTION) || act.type.includes(SUBMIT_SUCCESS_ACTION))) {
    const action = <WithRootState<APISuccess>>act;

    const res = action.response.xhr.response;

    if (res.BlackoutDatesFilters) {
      return {
        ...state,
        ActiveForm: {
          ShowExpired: res.BlackoutDatesFilters.IncludeExpired,
        },
      };
    }
  } else if (isActionType(act, ConfigDeleteBlackoutDateModal)) {
    return {
      ...state,
      deletingBlackoutDate: act.blackoutDate,
    };
  } else if (isActionType(act, PopModal) && act.modal === ModalTypes.DELETE_BLACKOUT_DATE) {
    return {
      ...state,
      deletingBlackoutDate: null,
    };
  } else if (isActionType(act, ClearAllAdminFacilityLocationCache) || isActionType(act, ClearAllCache)) {
    return setDefaults(act.rootState, getInitialState());
  } else if (isActionType(act, ResetBlackoutDateHome)) {
    // TODO: Later, after we refactored the DeleteBlackoutDate modal, we should be able
    // to remove this reset action, because the only thing to reset is the delete modal,
    // and that will be managed by the app reducer
    return {
      ...state,
      deletingBlackoutDate: undefined,
    };
  }
  return state || setDefaults(act.rootState, getInitialState());
};

export default BlackoutDatesHomeReducer;