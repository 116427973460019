
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, Row, SimpleList, SimpleListElement, PageLoader, EmptyMessage, Button,
} from '../../../../../../Elements';
import {
  actionCreators,
} from "../../../../../../../store/AdminCMSSite/Resources/Modals/ResourceCategory/actions";
import { makeFilteredResourceCategoriesSelector } from '../../../../../../../store/AdminCMSSite/Resources/Modals/ResourceCategory';
import {
  actionCreators as resourceFormActinoCreators,
} from "../../../../../../../store/AdminCMSSite/Resources/Resource/Form/actions";
import { FormDefinition } from "../../../../../../../store/AdminCMSSite/Resources/Resource/Form/validation";
import { CMSResourceCategory } from '../../../../../../../models/api/adminCMSCacheOne';
import '../../../../../../../styles/pages/resources/modals/resource-category/index.scss';
import ContextMenuComponent from './ContextMenu';
import { actionCreators as appActionCreators } from '../../../../../../../store/App/actions';
import {
    actionCreators as adminCMSCacheOneActionCreators, GetSiteCache,
} from '../../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as resourceCategoryFormActionCreators} from "../../../../../../../store/AdminCMSSite/Resources/Modals/ResourceCategoryForm/actions";
import { ApplicationState } from '../../../../../../../store';
import { extractRouteParams } from '../../../../../../../utils/urlHelper';
import { PageIcon } from '../../../../../../../components/Icons';
import { ENTITY_NOT_ADDED, RESOURCE_CATEGORIES, ENTITY_NOT_FOUND } from '../../../../../../../constants/messages/adminCMS';
import { checkResourceCategoryPermission } from '../../../../../../../utils/helpers/adminCMSPageHelper';
import { EmptyMessageType } from '../../../../../../../components/Elements/EmptyMessage';
import { ModalHeight } from '../../../../../../../components/Elements/Modal';
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import {WithInertAttribute} from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--modals--resource-category';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;
type Props = WithInertAttribute<{}>

@(withRouter as any)
class ResourceCategoryModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;
  private modalContentRef;

  componentWillUnmount() {
    this.onClose();
  }

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };

  onClose = () => {
    this.onSearchBack();
    this.props.actions.reset();
  };
  onSearchBack = () => {
    if (this.props.resourceCategoryModal.filterString) {
      this.scrollToTop();
    }
    this.props.actions.toggleFilter(false);
    this.props.actions.filterResourceCategories('');
  };
  onAddClick = () => {
    checkResourceCategoryPermission(
      () => {
        this.props.actions.pushModal(ModalTypes.RESOURCE_CATEGORY_FORM, false, true);
      },
      this.props.adminCMSCacheOne,
      undefined,
      true,
    );
  };
  onRefreshClick = () => {
    const {actions} = this.props;
    actions.getSiteCache(
      {
        GetResourceCategories: true,
        MaxResourceCategoryID: 0,
        ...extractRouteParams(this.props),
      },
      false,
      undefined,
      true,
    );
  };
  onClickResourceCategoryElement = (index: number, key: string | number, category: CMSResourceCategory) => {
    if (!category.Inactive && !category.ClientInactive) {
      this.props.actions.updateValue(category.ID, FormDefinition.CategoryID);
      this.onClose();
      this.props.actions.popModal(false, true, ModalTypes.SELECT_RESOURCE_CATEGORIES);
    }
  };
  onRestoreResourceCategoryElement = (index: number) => {
    checkResourceCategoryPermission(
      () => {
        const resourceCategory = this.props.filteredResourceCategories[index];
        this.props.resourceCategoryFormActions.deleteResourceCategory(resourceCategory.ID, true);
      },
      this.props.adminCMSCacheOne,
      true,
      true,
    );
  };
  onElementEditClick = (index: number) => {
    checkResourceCategoryPermission(
      () => {
        const resourceCategory = this.props.filteredResourceCategories[index];
        this.props.actions.pushModal(ModalTypes.RESOURCE_CATEGORY_FORM, false, true, {id: resourceCategory.ID});
      },
      this.props.adminCMSCacheOne,
      true,
      true,
);
  };
  onElementDeleteClick = (index: number) => {
    checkResourceCategoryPermission(
      () => {
        const resourceCategory = this.props.filteredResourceCategories[index];
        this.props.resourceCategoryFormActions.deleteResourceCategory(resourceCategory.ID);
      },
      this.props.adminCMSCacheOne,
      true,
      true,
);
  };

  itemRenderer = (index) => {
    const resourceCategory: CMSResourceCategory = this.props.filteredResourceCategories[index];
    return (
    <SimpleListElement
      hasMenu
      key={resourceCategory.ID}
      index={index}
      label={resourceCategory.Name}
      onClick={this.onClickResourceCategoryElement}
      onRestore={this.onRestoreResourceCategoryElement}
      deleted={resourceCategory.Inactive || resourceCategory.ClientInactive}
      itemValue={resourceCategory}
      canRestore={this.props.apiSaving === 0}
      contextMenu={<ContextMenuComponent
        onDelete={(e) => {e.stopPropagation(); this.onElementDeleteClick(index);}}
        onEdit={(e) => {e.stopPropagation(); this.onElementEditClick(index);}} />}
    />);
  };

  renderEmptyMessage = () => {
    const {allResourceCategories} = this.props;

    const message = !allResourceCategories || allResourceCategories.length === 0 ? ENTITY_NOT_ADDED(RESOURCE_CATEGORIES) : ENTITY_NOT_FOUND(RESOURCE_CATEGORIES);
    return (
      <EmptyMessage
        icon={PageIcon}
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        actions={<Button className={`${namespace()}--empty-message-btn`} color='white' textColor='black' onClick={this.onAddClick}>NEW RESOURCE CATEGORY</Button>}
        description={message}
      /> 
    );
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };
  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filterResourceCategories(val);
  };

  public render() {
    const { apiLoading, apiLoadingMap, resourceCategoryModal: { isTextSearching }, cacheTwoResources, actions: {filterResourceCategories}, inert, apiSaving, filteredResourceCategories, resourceForm, allResourceCategories} = this.props;
    // subtitle should be
    // - if resource name is blank, show `New Resource` or `Edit Resource`
    // - otherwise, show name of the resource (the one stored in ActiveForm)
    const subtitle = resourceForm.ActiveForm && resourceForm.ActiveForm.Name ? resourceForm.ActiveForm.Name : (cacheTwoResources.CMSResource ? 'Edit Resource' : 'New Resource');
    return (
      <Modal inert={inert} height={ModalHeight.HEIGHT_425} onClose={this.onClose} big mobileFullScreen className={namespace()}>
        <ModalHeader
          isSearching={isTextSearching}
          onAdd={this.onAddClick}
          onRefresh={this.onRefreshClick}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={this.onSearchChange}
          onSearchBack={this.onSearchBack}
          subtitle={subtitle}
          className={`${namespace()}--header`}>
          Select Resource Category
        </ModalHeader>

        <ModalContent
          refCallback={(ref) => this.modalContentRef = ref}
          neverLoading
          paddingLeft={0} paddingRight={0} paddingTop={8} paddingBottom={0}
        >
          <Row className={`${namespace()}--row-1`}>
            {!apiLoadingMap[GetSiteCache.requestType] && filteredResourceCategories.length > 0 && <SimpleList
              itemRenderer={this.itemRenderer}
              totalLength={filteredResourceCategories ? filteredResourceCategories.length : 0}
            />}
            {!apiLoadingMap[GetSiteCache.requestType] && (!allResourceCategories || allResourceCategories.length === 0 || filteredResourceCategories.length === 0) ? this.renderEmptyMessage() : null}
            {(apiLoading > 0 || apiSaving > 0) && <PageLoader className={`${namespace()}--page-loader`}/>}
          </Row>
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredResourceCategorySelector = makeFilteredResourceCategoriesSelector();
  return {
    resourceCategoryModal: state.adminCMSSite.resources.modals.resourceCategory,
    resourceForm: state.adminCMSSite.resources.resource.form,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    cacheTwoResources: state.adminCMSSite.cacheTwoResources,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    filteredResourceCategories: filteredResourceCategorySelector(state),
    allResourceCategories: state.adminCMSSite.cacheOne.CMSResourceCategories,
    cacheZero: state.cacheZero,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...adminCMSCacheOneActionCreators,
    ...resourceFormActinoCreators,
  }, dispatch),
  resourceCategoryFormActions: bindActionCreators({
    ...resourceCategoryFormActionCreators,
  }, dispatch),
});

const ConnectedResourceCategoryModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(ResourceCategoryModal);

export default ConnectedResourceCategoryModal;