import {
  GetParticipantsActions, GetGroupsActions, RestoreGroupActions, GetRegistrationsActions, GetTripsActions, GetReservationsActions
} from './actions';
import {
  getParticipants, getParticipantsBody, getGroups, getGroupsBody, deleteGroup, getTrips, getTripsBody, getReservations, getReservationsBody,
  activateGroupBody, getRegistrationsBody, getRegistrations,
} from '../../../../constants/urls';
import { makeRequestEpic } from '../../../Validation/epicCreator';

export const getParticipantsEpic = makeRequestEpic(GetParticipantsActions, getParticipants, getParticipantsBody, true);
export const getGroupsEpic = makeRequestEpic(GetGroupsActions, getGroups, getGroupsBody, true);
export const restoreGroupEpic = makeRequestEpic(
  RestoreGroupActions,
  deleteGroup,
  activateGroupBody,
  true,
  'The group was successfully restored'
);

export const getRegistrationsEpic = makeRequestEpic(
  GetRegistrationsActions,
  getRegistrations,
  getRegistrationsBody,
  true,
);

export const getTripsEpic = makeRequestEpic(
  GetTripsActions,
  getTrips,
  getTripsBody,
  true,
);

export const getReservationsEpic = makeRequestEpic(
  GetReservationsActions,
  getReservations,
  getReservationsBody,
  true,
);