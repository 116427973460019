import * as React from 'react';
import '../../../styles/elements/static-text-field/index.scss';

export const namespace = 'elements--static-text-field';

export interface StaticTextFieldProps {
  className?: string;
  label?: string;
  floatingLabel?: boolean;
  value?: string | number;
}

const StaticTextField: React.SFC<StaticTextFieldProps> = (props: StaticTextFieldProps): React.ReactElement<StaticTextFieldProps> => (
  <div className={`${namespace} ${props.className}`}>
    <label className={`${namespace}--label`}>{props.label}</label>
    <span className={`${namespace}--value`}>
      {props.value}
    </span>
  </div>
);

export default StaticTextField;
