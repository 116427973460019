import { IEventTypeGeneralValidator, EventTypeGeneralFormDefinition, IEventTypePricing1Validator, EventTypePricing1FormDefinition, IEventTypePricing2Validator, EventTypePricing2FormDefinition, IEventTypePricing3Validator, EventTypePricing3FormDefinition, IEventTypePricing4Validator, EventTypePricing4FormDefinition, getAdminEventTypePricingTierValidationRule, getAdminEventTypeParticipantTypeIsYouthValidationRule, getAdminEventTypeParticipantTypeNameValidationRule, IEventTypePricing5Validator, EventTypePricing5FormDefinition, IEventTypeGroupRegistrationValidator, EventTypeGroupRegistrationFormDefinition, IEventTypeClassSchedulingValidator, EventTypeClassSchedulingFormDefinition, IEventTypeNamedParticipants_AdultsValidator, EventTypeNamedParticipants_AdultsFormDefinition, IEventTypeNamedParticipants_OAFieldsValidator, EventTypeNamedParticipants_OAFieldsFormDefinition, IEventTypeNamedParticipants_YouthValidator, EventTypeNamedParticipants_YouthFormDefinition } from "./validation";
import { apiValidationReducerCreator, shouldSkipUpdateForm } from "../../../../../store/Validation/reducerCreator";
import { SUFFIX, AddParticipantType, RemoveParticipantType, getAdminEventTypePricingTierKey, AdminEventTypeParticipantTypeKeys, getAdminEventTypeParticipantTypeAttrKey } from './actions';
import { setDefaults } from "../../../../../utils/validator";
import { API_UPD } from "../../../../../constants/request";
import { ClearAdminEventsCacheTwoEventType } from "../../../../../store/AdminEvents/CacheTwoEventType/actions";
import { APIUpdateForm, WithRootState, submitClientFailureAction } from "../../../../../store/Validation/actionCreator";
import { EventsEventType, EventsEventTypePricing2, EventsEventTypePricing1, EventsEventTypePricing3, EventsEventTypePricing4, EventsEventTypePricing5, EventsEventTypeGroupAndRegistration, EventsEventTypeClassScheduling, EventsEventTypeNamedParticipantYouth, EventsEventTypeNamedParticipantAdult, EventsEventTypeNamedParticipantOAFields } from "../../../../../models/api/adminEventsCacheOne";
import { ClearAllAdminEventsCache, UPDATE_EVENT_TYPE_FORM_SUFFIX } from "../../../CacheOne/actions";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../../utils/StrongActions";
import { ClearAllCache } from "../../../../App/actions";

export interface EventTypeRowActiveForm {
  ID?: number;
  Name?: string;
  LocationID?: number;
  NumCurrentOrFutureEvents?: number;
  Inactive?: boolean;
  TS?: number;
}

export interface EventTypeGeneralActiveForm {
  ID?: number;
  Name?: string;
  Description?: string;
  LeadersGuideURL?: string;
  WebsiteURL: string;
  PrimaryContactName?: string;
  PrimaryContactPhone?: string;
  PrimaryContactEmail?: string;
  AdditionalNotify1_Name?: string;
  AdditionalNotify1_Email?: string;
  AdditionalNotify2_Name?: string;
  AdditionalNotify2_Email?: string;
  LocationID?: number;
  NewRegistrationMessageID?: number;
  EventCategoryID?: number;
  DistrictIDi?: number;

  RegistrationMethodID?: number;
  Inactive?: boolean;
  TS?: number;
}

export interface EventTypePricing1ActiveForm extends EventsEventTypePricing1 {
}

export interface EventTypePricing2ActiveForm extends EventsEventTypePricing2 {

}

export interface EventTypePricing3ActiveForm extends EventsEventTypePricing3 {

}

export interface EventTypePricing4ActiveForm extends EventsEventTypePricing4 {

}

export interface EventTypePricing5ActiveForm extends EventsEventTypePricing5 {

}

export interface EventTypeNamedParticipants_YouthActiveForm extends EventsEventTypeNamedParticipantYouth {

}
export interface EventTypeNamedParticipants_AdultsActiveForm extends EventsEventTypeNamedParticipantAdult {
  
}
export interface EventTypeNamedParticipants_OAFieldsActiveForm extends EventsEventTypeNamedParticipantOAFields {
  
}

export interface EventTypeGroupRegistrationActiveForm extends EventsEventTypeGroupAndRegistration {

}

export interface EventsEventTypeClassSchedulingActiveForm extends EventsEventTypeClassScheduling {

}

const getInitialState = () => {
  return {
    EventTypeRow: {
      ActiveForm: { },
    },
    General: {
      ActiveForm: { },
      ValidationRules: { ...EventTypeGeneralFormDefinition },
    },
    Pricing1: {
      ActiveForm: { },
      ValidationRules: { ...EventTypePricing1FormDefinition },
    },
    Pricing2: {
      ActiveForm: { },
      ValidationRules: { ...EventTypePricing2FormDefinition },
    },
    Pricing3: {
      ActiveForm: { },
      ValidationRules: { ...EventTypePricing3FormDefinition },
    },
    Pricing4: {
      ActiveForm: { },
      ValidationRules: { ...EventTypePricing4FormDefinition },
    },
    Pricing5: {
      ActiveForm: { },
      ValidationRules: { ...EventTypePricing5FormDefinition },
    },
    NamedParticipants: {
      Youth: {
        ActiveForm: { },
        ValidationRules: { ...EventTypeNamedParticipants_YouthFormDefinition },
      },
      Adults: {
        ActiveForm: { },
        ValidationRules: { ...EventTypeNamedParticipants_AdultsFormDefinition },
      },
      OAFields: {
        ActiveForm: { },
        ValidationRules: { ...EventTypeNamedParticipants_OAFieldsFormDefinition },
      },
    },
    GroupAndRegistration: {
      ActiveForm: { },
      ValidationRules: { ...EventTypeGroupRegistrationFormDefinition },
    },
    ClassScheduling: {
      ActiveForm: { },
      ValidationRules: { ...EventTypeClassSchedulingFormDefinition },
    }
  };
};

export interface EventTypeFormState {
  EventTypeRow: {
    ActiveForm: EventTypeRowActiveForm;
    SubmitErrorMessage?: string;
  };
  General: {
    ActiveForm: EventTypeGeneralActiveForm;
    ValidationRules: IEventTypeGeneralValidator;
    SubmitErrorMessage?: string;
  };
  Pricing1: {
    ActiveForm: EventTypePricing1ActiveForm;
    ValidationRules: IEventTypePricing1Validator,
    SubmitErrorMessage?: string;
  };
  Pricing2: {
    ActiveForm: EventTypePricing2ActiveForm;
    ValidationRules: IEventTypePricing2Validator;
    SubmitErrorMessage?: string;
  };
  Pricing3: {
    ActiveForm: EventTypePricing3ActiveForm;
    ValidationRules: IEventTypePricing3Validator;
    SubmitErrorMessage?: string;
  };
  Pricing4: {
    ActiveForm: EventTypePricing4ActiveForm;
    ValidationRules: IEventTypePricing4Validator;
    SubmitErrorMessage?: string;
  };
  Pricing5: {
    ActiveForm: EventTypePricing5ActiveForm;
    ValidationRules: IEventTypePricing5Validator;
    SubmitErrorMessage?: string;
  };
  GroupAndRegistration: {
    ActiveForm: EventTypeGroupRegistrationActiveForm;
    ValidationRules: IEventTypeGroupRegistrationValidator;
    SubmitErrorMessage?: string;
  };
  NamedParticipants: {
    Youth: {
      ActiveForm: EventTypeNamedParticipants_YouthActiveForm;
      ValidationRules: IEventTypeNamedParticipants_YouthValidator;
      SubmitErrorMessage?: string;
    },
    Adults: {
      ActiveForm: EventTypeNamedParticipants_AdultsActiveForm;
      ValidationRules: IEventTypeNamedParticipants_AdultsValidator;
      SubmitErrorMessage?: string;
    },
    OAFields: {
      ActiveForm: EventTypeNamedParticipants_OAFieldsActiveForm;
      ValidationRules: IEventTypeNamedParticipants_OAFieldsValidator;
      SubmitErrorMessage?: string;
    }
  };
  ClassScheduling: {
    ActiveForm: EventsEventTypeClassSchedulingActiveForm;
    ValidationRules: IEventTypeClassSchedulingValidator;
    SubmitErrorMessage?: string;
  }
}

const checkApiValidation = apiValidationReducerCreator(SUFFIX);
const checkGeneralApiValidation = apiValidationReducerCreator(SUFFIX + '__GENERAL');

const checkPricing1ApiValidation = apiValidationReducerCreator(SUFFIX + '__PRICING1');
const checkPricing2ApiValidation = apiValidationReducerCreator(SUFFIX + '__PRICING2');
const checkPricing3ApiValidation = apiValidationReducerCreator(SUFFIX + '__PRICING3');
const checkPricing4ApiValidation = apiValidationReducerCreator(SUFFIX + '__PRICING4');
const checkPricing5ApiValidation = apiValidationReducerCreator(SUFFIX + '__PRICING5');

const checkNamedParticipants_YouthApiValidation = apiValidationReducerCreator(SUFFIX + '__NAMED_PARTICIPANTS_YOUTH');
const checkNamedParticipants_AdultsApiValidation = apiValidationReducerCreator(SUFFIX + '__NAMED_PARTICIPANTS_ADULTS');
const checkNamedParticipants_OAFieldsApiValidation = apiValidationReducerCreator(SUFFIX + '__NAMED_PARTICIPANTS_OAFIELDS');
const checkParticipantTypeApiValidation = apiValidationReducerCreator(SUFFIX + '__PARTICIPANT_TYPE');

const checkGroupRegistrationApiValidation = apiValidationReducerCreator(SUFFIX + '__GROUP_REGISTRATION');
const checkClassSchedulingApiValidation = apiValidationReducerCreator(SUFFIX + '__CLASS_SCHEDULING');

const setEventType = (state: EventTypeFormState, eventType: EventsEventType) => {
  const newState: EventTypeFormState = {
    ...state,
    EventTypeRow: {
      ActiveForm: {
        ...(eventType.EventTypeRow as any),
      },
    },
    General: {
      ActiveForm: { ...(eventType.General as any) },
      ValidationRules: { ...EventTypeGeneralFormDefinition },
    },
    Pricing1: {
      ActiveForm: { ...(eventType.Pricing1 as any) },
      ValidationRules: { ...EventTypePricing1FormDefinition },
    },
    Pricing2: {
      ActiveForm: {
        ...(eventType.Pricing2 as any),
        UpfrontPaymentSpecific_Youth: eventType.Pricing2.UpfrontPaymentSpecific_Youth,
        UpfrontPaymentSpecific_Adults: eventType.Pricing2.UpfrontPaymentSpecific_Adults,
        MultipleEventDiscountAmount: eventType.Pricing2.MultipleEventDiscountAmount
      },
      ValidationRules: { ...EventTypePricing2FormDefinition },
    },
    Pricing3: {
      ActiveForm: { ...(eventType.Pricing3 as any) },
      ValidationRules: { ...EventTypePricing3FormDefinition },
    },
    Pricing4: {
      ActiveForm: { 
        ...(eventType.Pricing4 as any),
        ParticipantTypes: eventType.Pricing4.ParticipantTypes.map((pt) => {
          const isYouthKey = getAdminEventTypeParticipantTypeAttrKey(pt.ID, AdminEventTypeParticipantTypeKeys.IS_YOUTH);
          const nameKey = getAdminEventTypeParticipantTypeAttrKey(pt.ID, AdminEventTypeParticipantTypeKeys.NAME);

          return {
            ...pt,
            ActiveForm: {
              [isYouthKey]: pt.IsYouth ? 0 : 1,
              [nameKey]: pt.Name,
            },
            ValidationRules: {
              [isYouthKey]: getAdminEventTypeParticipantTypeIsYouthValidationRule(isYouthKey, pt.ID),
              [nameKey]: getAdminEventTypeParticipantTypeNameValidationRule(nameKey, pt.ID),
            }
          };
        })
      },
      ValidationRules: { ...EventTypePricing4FormDefinition },
    },
    Pricing5: {
      ActiveForm: { ...(eventType.Pricing5 as any) },
      ValidationRules: { ...EventTypePricing5FormDefinition },
    },
    NamedParticipants: {
      Youth: {
        ActiveForm: { ...(eventType.NamedParticipants.Youth) as any},
        ValidationRules: { ...EventTypeNamedParticipants_YouthFormDefinition },
      },
      Adults: {
        ActiveForm: { ...(eventType.NamedParticipants.Adults) as any},
        ValidationRules: { ...EventTypeNamedParticipants_AdultsFormDefinition },
      },
      OAFields: {
        ActiveForm: { ...(eventType.NamedParticipants.OAFields) as any},
        ValidationRules: { ...EventTypeNamedParticipants_OAFieldsFormDefinition },
      },
    },
    GroupAndRegistration: {
      ActiveForm: {
        ...(eventType.GroupAndRegistration as any),
        PaymentRequirementsMinimumPercentage: eventType.GroupAndRegistration.PaymentRequirementsMinimumPercentage,
      },
      ValidationRules: { ...EventTypeGroupRegistrationFormDefinition },
    },
    ClassScheduling: {
      ActiveForm: { ...(eventType.ClassScheduling) as any },
      ValidationRules: { ...EventTypeClassSchedulingFormDefinition },
    }
  };


  eventType.Pricing4.PricingTiers.forEach((p) => {
    const key = getAdminEventTypePricingTierKey(p.ID);
    newState.Pricing4.ActiveForm[key] = p.IsEnabled;
    newState.Pricing4.ValidationRules[key] = getAdminEventTypePricingTierValidationRule(key);
  });

  return newState;
};

const EventTypeFormReducer: Reducer<EventTypeFormState> = (s, act: WithRootState<AnyAction>) => {
  const state = { ...getInitialState(), ...s } as any;
  if (s && s.EventTypeRow) state.EventTypeRow = checkApiValidation(s.EventTypeRow, act);
  if (s && s.General) state.General = checkGeneralApiValidation(s.General, act);

  if (s && s.Pricing1) state.Pricing1 = checkPricing1ApiValidation(s.Pricing1, act);
  if (s && s.Pricing2) state.Pricing2 = checkPricing2ApiValidation(s.Pricing2, act);
  if (s && s.Pricing3) state.Pricing3 = checkPricing3ApiValidation(s.Pricing3, act);
  if (s && s.Pricing4) state.Pricing4 = checkPricing4ApiValidation(s.Pricing4, act);
  if (s && s.Pricing5) state.Pricing5 = checkPricing5ApiValidation(s.Pricing5, act);

  if (s && s.Pricing4 && s.Pricing4.ActiveForm.ParticipantTypes) {
    s.Pricing4.ActiveForm.ParticipantTypes = s.Pricing4.ActiveForm.ParticipantTypes.map((pt) => {
      const action = act as any;
      if (action.vObj && action.vObj.extraInfo && action.vObj.extraInfo.id === pt.ID) {
        return checkParticipantTypeApiValidation(pt, act);
      } else if (action.type.startsWith(submitClientFailureAction) && action.errors && action.errors.Errors) {
        return checkParticipantTypeApiValidation(pt, act);
      } else {
        return pt;
      }
    });
  }

  if (s && s.NamedParticipants && s.NamedParticipants.Youth) state.NamedParticipants.Youth = checkNamedParticipants_YouthApiValidation(s.NamedParticipants.Youth, act);
  if (s && s.NamedParticipants && s.NamedParticipants.Adults) state.NamedParticipants.Adults = checkNamedParticipants_AdultsApiValidation(s.NamedParticipants.Adults, act);
  if (s && s.NamedParticipants && s.NamedParticipants.OAFields) state.NamedParticipants.OAFields = checkNamedParticipants_OAFieldsApiValidation(s.NamedParticipants.OAFields, act);

  if (s && s.GroupAndRegistration) state.GroupAndRegistration = checkGroupRegistrationApiValidation(s.GroupAndRegistration, act);
  if (s && s.ClassScheduling) state.ClassScheduling = checkClassSchedulingApiValidation(s.ClassScheduling, act);
  
  if (act.type.includes(API_UPD) && act.type.includes(UPDATE_EVENT_TYPE_FORM_SUFFIX)) {
    const action = <APIUpdateForm>act;

    // This skip is based on `skipUpdateForm`, `leavingCacheLevelOnSuccess` and `inMatchingCacheLevelOn409`
    if (shouldSkipUpdateForm(action)) return state;

    const rootState = act.rootState;
    let newState = getInitialState() as EventTypeFormState;
    // set initial and default value
    newState.EventTypeRow = setDefaults(act.rootState, newState.EventTypeRow, state.General);
    newState.General = setDefaults(act.rootState, newState.General, state.General);

    if (rootState.adminEvents.cacheOne.EventsEventType) {
      return setEventType(newState, rootState.adminEvents.cacheOne.EventsEventType);
    }

    return newState;
  } else if (isActionType(act, AddParticipantType)) {
    const newState = { ...state } as EventTypeFormState;
    let minTempParticipantID = 0;

    newState.Pricing4.ActiveForm.ParticipantTypes.forEach((pt) => {
      if (pt.ID < minTempParticipantID) minTempParticipantID = pt.ID;
    });
    const tempId = minTempParticipantID - 1;
    const isYouthKey = getAdminEventTypeParticipantTypeAttrKey(tempId, AdminEventTypeParticipantTypeKeys.IS_YOUTH);
    const nameKey = getAdminEventTypeParticipantTypeAttrKey(tempId, AdminEventTypeParticipantTypeKeys.NAME);

    newState.Pricing4.ActiveForm.ParticipantTypes.push({
      ID: tempId,
      IsYouth: false,
      Name: '',
      ActiveForm: {
        [isYouthKey]: 0,
        [nameKey]: '',
      },
      ValidationRules: {
        [isYouthKey]: getAdminEventTypeParticipantTypeIsYouthValidationRule(isYouthKey, tempId),
        [nameKey]: getAdminEventTypeParticipantTypeNameValidationRule(nameKey, tempId),
      },
    });

    return newState;
  } else if (isActionType(act, RemoveParticipantType)) {
    const newState = { ...state } as EventTypeFormState;

    newState.Pricing4.ActiveForm.ParticipantTypes[act.index].Inactive = true;
    newState.Pricing4.ActiveForm.ParticipantTypes[act.index].ReplacementID = act.replacementId;

    newState.Pricing4.ActiveForm.ParticipantTypes = newState.Pricing4.ActiveForm.ParticipantTypes.map((pt) => {
      if (pt.ID !== act.pt.ID) {
        // We already handled the participant type being removed above, so skip here

        if (pt.ReplacementID && pt.ReplacementID === act.pt.ID) {
          pt.ReplacementID = act.replacementId;
        }

        if (pt.ID === act.replacementId) {
          pt.IsReplacement = true;
        }

        return {...pt};
      }
      return pt;
    });

    return newState;
  } else if (
    isActionType(act, ClearAdminEventsCacheTwoEventType) ||
    isActionType(act, ClearAllCache) ||
    isActionType(act, ClearAllAdminEventsCache)
  ) {
    return getInitialState();
  }

  return state || getInitialState();
};

export default EventTypeFormReducer;