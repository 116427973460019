import * as React from 'react';
import '../../../../styles/pages/checkout/security.scss';
import { namespace as ns } from '../constants';
import SecurityIcon from '../../../../images/pages/checkout/security.svg';

export const namespace = (): string => `${ns()}--security`;

const Security: React.SFC<{}> = ():React.ReactElement<undefined> => (
  <div className={namespace()}>
    <div className={`${namespace()}--icon`}>
      <SecurityIcon className={`${namespace()}--icon--image`}/>
    </div>
    <div className={`${namespace()}--content`}>
      <div className={`${namespace()}--content--line1`}>
        100% Secure
      </div>
      <div className={`${namespace()}--content--line2`}>
        We don't store your payment information.
      </div>
    </div>
  </div>
);

export default Security;
