import * as Actions from './actions';
import { createSelector } from 'reselect';
import {myTripsSelector, myEventsSelector} from '../CacheOne';
import {HomepageMyTrip, HomepageMyEvent} from "../../models/api/cacheOne";
import { ClearAllCache, ClearAllEndUserCacheButOptions } from '../App/actions';
import { Reducer } from 'redux';
import { isActionType } from '../../utils/StrongActions';

const filterSelector = state => state.app.searchFilter;

export const makeMyTripsSelector = () => {
  return createSelector(
    [myTripsSelector, filterSelector],
    (trips: Array<HomepageMyTrip>, filter) => {
      if (!filter || filter === '') return trips;
      const f = filter.toLowerCase();
      return trips.filter(trip => (trip.Name.toLowerCase().includes(f) || trip.Location.Name.toLowerCase().includes(f)));
    }
  );
};

export const makeMyEventsSelector = () => {
  return createSelector(
    [myEventsSelector, filterSelector],
    (events: Array<HomepageMyEvent>, filter) => {
      if (!filter || filter === '') return events;
      const f = filter.toLowerCase();
      return events.filter(event => (event.Name.toLowerCase().includes(f) || event.EventType.toLowerCase().includes(f)));
    }
  );
};

export interface HomeState {
  selectedTab: 'myEvents' | 'myTrips';
}

const getInitialState: () => HomeState = () => ({ selectedTab: 'myEvents'});

const Home: Reducer<HomeState> = (state, action) => {
  if (isActionType(action, Actions.HomeSelectTab)) {
    return {...state, selectedTab: action.selectedTab};
  } else if (isActionType(action, ClearAllCache) || isActionType(action, ClearAllEndUserCacheButOptions)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default Home;
