import {GetEventTypeData} from './actions';
import {APISuccess, APIRequest, APISuccessSubmit} from "../Validation/actionCreator";
import {convertToMoment} from '../../utils';
import {ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction} from "../App/actions";
import {
  EventTypeRegistrationSettings, EventTypeEvent, EventTypeCustomFieldOption
} from "../../models/api/cacheTwoEvents";
import {ClearCacheBelowOne} from "../CacheOne/actions";
import {GetEventData} from "../CacheThreeEvents/actions";
import {GetEventParticipantData} from "../CacheFourEventsViewParticipant/actions";
import {EventNumbersSave} from "../CacheFourEventsNumbers/actions";
import { FSSubmitActions } from '../Events/Event/Registration/actions';
import {EmptyCartActions, RemoveItemFromCartActions} from "../Cart/actions";
import {GetFacilityTripData} from "../CacheThreeFacilities/actions";
import {EventProductsSave} from "../CacheFourEventsProducts/actions";
import { captureTentarooError } from '../../utils/dataHelper';
import { Reducer } from 'redux';
import { Action, isActionType } from '../../utils/StrongActions';

export interface CacheTwoEventsState {
  EventTypeCustomFieldOptions?: Array<EventTypeCustomFieldOption> | null;
  EventTypeRegistrationSettings?: EventTypeRegistrationSettings | null;
  EventTypeEvents?: Array<EventTypeEvent> | null;
  eventType: 'numbers' | 'participant' | 'both';
  eventTypeID: number;
}

const setCacheTwoData = (data: {
  EventTypeCustomFieldOptions?: Array<EventTypeCustomFieldOption> | null;
  EventTypeRegistrationSettings: EventTypeRegistrationSettings;
  EventTypeEvents?: Array<EventTypeEvent> | null;
  eventType: 'numbers' | 'participant' | 'both';
}, eventTypeID: number) => {
  let eventType: 'numbers' | 'participant' | 'both'  = 'both';
  const {EventTypeRegistrationSettings: {RegistrationSettings}} = data;
  if (RegistrationSettings.HasNamesRegistration && RegistrationSettings.HasNumbersRegistration) {
    eventType = 'both';
  } else if (RegistrationSettings.HasNamesRegistration) {
    eventType = 'participant';
  } else if  (RegistrationSettings.HasNumbersRegistration) {
    eventType = 'numbers';
  }
  // data.EventTypeRegistrationSettings.NumbersRegistrationSettings.ShowIsCampingOvernight = true;
  // data.EventTypeRegistrationSettings.NumbersRegistrationSettings.RequireCampsiteRanking = true;
  // data.EventTypeRegistrationSettings.NamesRegistrationSettings.ShowRegistrationNotesYouth = true;
  // data.EventTypeRegistrationSettings.NamesRegistrationSettings.ShowSummerCampYearYouth = true;
  // data.EventTypeRegistrationSettings.NamesRegistrationSettings.ShowIsStaffYouth = true;
  // data.EventTypeRegistrationSettings.NamesRegistrationSettings.ShowParticipantClassesAs = 'Tests';
  return {
    EventTypeCustomFieldOptions: data.EventTypeCustomFieldOptions,
    EventTypeRegistrationSettings: data.EventTypeRegistrationSettings,
    EventTypeEvents: convertToMoment(data.EventTypeEvents, ['StartDateTime', 'EndDateTime']),
    eventType,
    eventTypeID: eventTypeID
  };
};

const handleResponse = (state, responseData, request) => {
  if (responseData.EventTypeRegistrationSettings !== undefined && responseData.EventTypeEvents !== undefined){
    const bodyStr = request.body;
    try {
      const body = JSON.parse(bodyStr);
      return setCacheTwoData(responseData, body.AppState.EventTypeID);
    } catch (e) {
      captureTentarooError(new Error('Unable to parse request body for cache 2!'));
      return state;
    }
  }
  return state;
};

const CacheTwoEvents: Reducer<CacheTwoEventsState> = (state, act: Action) => {
  if (
    act.type === GetEventTypeData.successType || act.type === GetEventData.successType ||
    act.type === GetEventParticipantData.successType || act.type === EventNumbersSave.successType ||
    act.type === EventProductsSave.successType ||
    // TODO: circular dependency here - needs to move `ApplicationState` interface to a separate file
    // to get rid of direct dependency to `store/index.ts`
    (act.type === 'API_SUCCESS__SAVING$_APIFINISHSAVE_$__CACHE_FOUR_EVENTS_PARTICIPANTS_SAVE' && (act as APISuccessSubmit).extra?.isCacheThreeEventLoaded) ||
    act.type === FSSubmitActions.successType || act.type === EmptyCartActions.successType ||
    act.type === RemoveItemFromCartActions.successType || act.type === GetFacilityTripData.successType
  ) {
    const action = <APISuccess> act;
    return handleResponse(state, action.response.response, action.response.request);
  } else if (isActionType(act, SetCacheAction)) {
    return handleResponse(state, act.response.xhr.response, act.response.request);
  } else if (act.type === GetEventTypeData.requestType || act.type === GetEventData.requestType || act.type === GetEventParticipantData.requestType) {
    const action = <APIRequest> act;
    // Set the eventTypeId early so we can partial load if we can
    return {...state, eventTypeID: action.value.EventTypeID};
  } else if (
    // if you update this one, you should also update EventType/index.ts
    isActionType(act, ClearAllCache) || isActionType(act, ClearAllEndUserCacheButOptions) || isActionType(act, ClearCacheBelowOne)
  ) {
    return {eventType: 'both', eventTypeID: 0};
  }
  return state || {eventType: 'both', eventTypeID: 0};
};

export default CacheTwoEvents;
