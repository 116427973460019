import * as React from 'react';
import '../../../../../../../../styles/pages/admin-events/classes/class/index.scss';
import { Main, MainContent } from '../../../../../../../Layouts';
import { Form, Button, Row, Column, TextField, Switch, RadioGroupOption, FieldSet, Alert, Text, Checkbox, MoneyField, ModalSelect, FormSection, AdminClassTypeCard } from '../../../../../../../Elements';
import { CardCategory } from '../../../../../../../../components/Elements/Card';
import { actionCreators } from '../../../../../../../../store/AdminEvents/Classes/Form/actions';
import { actionCreators as appActionCreators } from '../../../../../../../../store/App/actions';
import { AdminEventClass, AdminEventTimeBlock, EventsEventDay } from '../../../../../../../../models/api/adminEventsCacheTwoEvent';
import { makeSelectedGLAccountSelector, makeYouthParticipantTypesSelector, makeAdultsParticipantTypesSelector, makeSelectedClassTypesSelector } from '../../../../../../../../store/AdminEvents/Classes/Form';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../../../../../../../store';
import { makeSelectedRegistrationMethodSelector } from '../../../../../../../../store/AdminEvents/EventTypes/EventType/Form';
import { ParticipantType } from '../../../../../../../../models/api/adminEventsCacheOne';
import { makeEventDaysSelector } from '../../../../../../../../store/AdminEvents/CacheTwoEvent';
import { GLAccount, ClassType } from '../../../../../../../../models/api/options';
import GLAccountCard, { GLAccountCardType } from '../../../../../../../Elements/GLAccount/Card';
import { AdminClassTypeCardType } from '../../../../../../../Elements/AdminClassType/Card';
import { isUsingNames, isUsingNumbers } from '../../../../../../../../store/AdminEvents/EventTypes/EventType/Form/uiHelpers';
import moment from 'moment';
import { checkAdminEventClassTypePermission } from '../../../../../../../../utils/helpers/adminEventsPageHelper';
import { checkGLAccountPermission } from '../../../../../../../../utils/helpers/adminSettingsPageHelper';
import { ModalTypes } from '../../../../../../../../utils/modalHelper';
import { isMobileAndSmallerScreenSize } from '../../../../../../../../utils/isMobile';
import { generateDOMId } from '../../../../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../../../store/service';
import { WithInertAttribute } from '../../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--class';

export type Props = WithInertAttribute<{
  action: 'add' | 'edit';
  disabled: boolean;
  loading?: boolean;
  handleCompact?: boolean;
  hideFormAction?: boolean;
  onSave?: () => void;
  onDelete?: () => void;
}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class AdminEventClassTypeForm extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  onAddClass = () => {
  };

  onRemoveClass = (adminClass: AdminEventClass) => {
  };

  isFormDisabled = () => {
    const { adminEventsCacheTwoEvent, disabled, action } = this.props;
    
    if (disabled) return true;
    if (adminEventsCacheTwoEvent.EventsEvent) {
      const permissions = adminEventsCacheTwoEvent.EventsEvent.Permissions;
      
      if (action === 'add') {
        return !permissions.hasAddClass;
      } else {
        return !permissions.hasEditClass;
      }
    }
  };

  onChangeIsRegisterUnderIndividuals = (v, vObj) => {
    const {actions, classForm: {ActiveForm}} = this.props;

    if (ActiveForm.IsRegisterUnderIndividuals && ActiveForm.IsCombo) {
      actions.showTopFloatingAlert("Group options/classes don't support combination classes.", isMobileAndSmallerScreenSize(), 'orange');
      return;
    }

    actions.updateValue(v, vObj);
  };

  renderRegisterUnderAndKindOfClass = () => {

    const {action, actions, classForm: {ActiveForm, ValidationRules}} = this.props;

    if (action === 'add') {
      return (
        <section className={`${namespace()}--register-under-kind-class-add`}>
          {isUsingNames() && isUsingNumbers() && <div className={`${namespace()}--label`}>Register Under</div>}
          {isUsingNames() && isUsingNumbers() && <Row>
            <Column span={6} mobileSpan={12}>
              <RadioGroupOption
                disabled={this.isFormDisabled()}
                value={!!ActiveForm.IsRegisterUnderIndividuals}
                selected={!!ActiveForm.IsRegisterUnderIndividuals}
                ValidationRules={ValidationRules.IsRegisterUnderIndividuals}
                rawValue={true}
                onChange={this.onChangeIsRegisterUnderIndividuals}
                newDesign
                label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>Individual</Text>}
              />
            </Column>
            <Column span={6} mobileSpan={12}>
              <RadioGroupOption
                disabled={this.isFormDisabled()}
                value={!!!ActiveForm.IsRegisterUnderIndividuals}
                selected={!!!ActiveForm.IsRegisterUnderIndividuals}
                ValidationRules={ValidationRules.IsRegisterUnderIndividuals}
                rawValue={false}
                onChange={this.onChangeIsRegisterUnderIndividuals}
                newDesign
                label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>Group</Text>}
              />
            </Column>
          </Row>}
          {isUsingNames() && isUsingNumbers() && <Row marginTop={12} marginBottom={4}>
            <div className={`${namespace()}--label bold`}>Registration Type can't be changed after a class/option is created. Please make sure you have selected the right type.</div>
          </Row>}
          <div className={`${namespace()}--label`}>Kind of Class</div>
          <Row>
            <Column span={12}>
              <RadioGroupOption
                disabled={this.isFormDisabled()}
                value={!!!ActiveForm.IsOption}
                selected={!!!ActiveForm.IsOption}
                ValidationRules={ValidationRules.IsOption}
                rawValue={false}
                onChange={actions.updateValue}
                newDesign
                helperText='Standard type of class which can be attended. Can also use for extra fees.'
                label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>Class</Text>}
              />
            </Column>
            <Column span={12}>
              <RadioGroupOption
                disabled={this.isFormDisabled()}
                value={!!ActiveForm.IsOption}
                selected={!!ActiveForm.IsOption}
                ValidationRules={ValidationRules.IsOption}
                rawValue={true}
                onChange={actions.updateValue}
                newDesign
                helperText='Treat as part of event registration fees instead of a separate class, same GL Account as event. Can use for discounts/camperships since amount can be negative.'
                label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>Registration Option</Text>}
              />
            </Column>
          </Row>
          <Row marginTop={12}>
            <div className={`${namespace()}--label bold`}>Kind of Class can't be changed after a class/option is created. Please make sure you have selected the right kind of class.</div>
          </Row>
        </section>
      );
    } else {
      return (
        <section className={`${namespace()}--register-under-kind-class-edit`}>
          <Row>
            <Column span={6} mobileSpan={12} style={{display: 'flex', flexDirection: 'column'}}>
              <div className={`${namespace()}--label`}>Register Under</div>
              <div className={`${namespace()}--value`}>{!!ActiveForm.IsRegisterUnderIndividuals ? 'Individual' : 'Group'}</div>
            </Column>
            <Column mobileMarginTop={16} span={6} mobileSpan={12} style={{display: 'flex', flexDirection: 'column'}}>
              <div className={`${namespace()}--label`}>Kind of Class</div>
              <div className={`${namespace()}--value`}>{!!ActiveForm.IsOption ? 'Registration Option' : 'Class'}</div>
            </Column>
          </Row>
        </section>
      );
    }
  };

  onToggleCombination = (val, vObj) => {
    const { actions, classForm: {ActiveForm}, selectedClassTypes } = this.props;

    if (!!val && !ActiveForm.IsRegisterUnderIndividuals) {
      actions.showTopFloatingAlert("Group options/classes don't support combination classes.", isMobileAndSmallerScreenSize(), 'orange');
      return;
    }

    if (!val && selectedClassTypes && selectedClassTypes.length > 0) {
      actions.resetToSingleClassType(selectedClassTypes[0].IDi);
    }
    actions.updateValue(val, vObj);
  };

  onCheckTimeBlock = (tb: AdminEventTimeBlock, newVal: boolean) => {
    this.props.actions.checkTimeBlock(tb.ID, newVal);
  };

  onCheckDay = (d: EventsEventDay, newVal: boolean) => {
    this.props.actions.checkDay(d.ID, newVal);
  };

  onCheckParticipantType = (d: ParticipantType, newVal: boolean) => {
    this.props.actions.checkParticipantType(d.ID, newVal);
  };

  onEditGLAccount = (account: GLAccount) => {
    const {actions, cacheZero} = this.props;
    checkGLAccountPermission(
      () => {
      actions.pushFormModal(ModalTypes.GL_ACCOUNT_FORM, true, {id: account.ID});
      },
      cacheZero,
      true,
    );
  };
  onRemoveGLAccount = () => {
    const {classForm, actions} = this.props;
    actions.updateValue(undefined, classForm.ValidationRules.GLAccountID);
  };
  onClickSelectGLAccountButton = () => {
    this.props.actions.pushSelectGLAccountModal({glAccountContext: 'class-form'});
  };
  onToggleShowAdults = (val, vObj) => {
    const { actions } = this.props;

    actions.updateValue(val, vObj);
    actions.toggleParticipantTypes(!!val, false);
  };
  onToggleShowYouth = (val, vObj) => {
    const { actions } = this.props;

    actions.updateValue(val, vObj);
    actions.toggleParticipantTypes(!!val, true);
  };

  onSelectClassTypes = () => {
    const { actions, classForm: {ActiveForm} } = this.props;
    actions.pushModal(ModalTypes.SELECT_CLASS_TYPE, false, false, {selectClassTypeModalType: ActiveForm.IsCombo ? 'multiple' : 'single'});
  };

  onRemoveClassType = (option: ClassType) => {
    this.props.actions.removeClassType(option.IDi);
  };

  onEditClassType = (option: ClassType) => {
    const {actions, cacheZero} = this.props;

    checkAdminEventClassTypePermission(
      () => {
        actions.pushFormModal(ModalTypes.CLASS_TYPE_FORM, true, {id: option.IDi});
      },
      cacheZero,
      true,
    );
  };

  renderTimeBlocks = () => {
    const {adminEventsCacheTwoEvent, disabled, classForm: {ActiveForm}} = this.props;

    if (!adminEventsCacheTwoEvent.EventsEvent) return null;

    const col1 = [] as any[];
    const col2 = [] as any[];

    const maxTimeBlocksNums = Math.min(adminEventsCacheTwoEvent.EventsEvent.TimeBlocks_Settings.TimeBlocks.length, adminEventsCacheTwoEvent.EventsEvent.TimeBlocks_Settings.NumberOfTimeBlocks);
    const halfLength = Math.ceil(maxTimeBlocksNums / 2);
    adminEventsCacheTwoEvent.EventsEvent && adminEventsCacheTwoEvent.EventsEvent.TimeBlocks_Settings.TimeBlocks.forEach((tb, index) => {
      if (adminEventsCacheTwoEvent.EventsEvent && index >= adminEventsCacheTwoEvent.EventsEvent.TimeBlocks_Settings.NumberOfTimeBlocks) return;
      const selected = ActiveForm.TimeBlocks && ActiveForm.TimeBlocks.find((id) => id  === tb.ID);

      const StartTime = tb.StartTime ? moment(tb.StartTime, 'HH:mm:ss').format('h:mm A') : '';
      const checkbox = (
        <Checkbox
          key={`timeblock${tb.ID}`}
          value={tb.ID}
          disabled={this.isFormDisabled()}
          selected={!!selected}
          newDesign
          label={<Text color='dark-gray' weight="regular"  size={16} marginBottom={0}>{`${tb.ID}${tb.ShowStartTime ? ' - ' + StartTime : ''}`}</Text>}
          onChange={() => this.onCheckTimeBlock(tb, !selected)} />
      );
      if (index < halfLength) col1.push(checkbox);
      else col2.push(checkbox);
    });

    return [
      <Column className={`${namespace()}--tb-col`} span={6} mobileSpan={12} key='timeblock_col1'>{col1}</Column>,
      <Column className={`${namespace()}--tb-col`} span={6} mobileSpan={12} key='timeblock_col2'>{col2}</Column>
    ];
  };

  renderDays = () => {
    const {adminEventsCacheTwoEvent, eventDays, classForm: {ActiveForm}} = this.props;

    if (!adminEventsCacheTwoEvent.EventsEvent) return null;

    const col1 = [] as any[];
    const col2 = [] as any[];

    const halfLength = Math.ceil(eventDays.length / 2);
    eventDays.forEach((d, index) => {
      const selected = ActiveForm.Days && ActiveForm.Days.find((id) => id  === d.ID);
      const day = (
        <Checkbox value={d.ID}
          disabled={this.isFormDisabled()}
          selected={!!selected}
          newDesign
          label={<Text color='dark-gray' weight="regular"  size={16} marginBottom={0}>{d.Name}</Text>}
          onChange={() => this.onCheckDay(d, !selected)} />
      );
      if (index < halfLength) col1.push(day);
      else col2.push(day);
    });

    return [
      <Column className={`${namespace()}--event-day-col`} span={6} mobileSpan={12} key='day_col1'>{col1}</Column>,
      <Column className={`${namespace()}--event-day-col`} span={6} mobileSpan={12} key='day_col2'>{col2}</Column>
    ];
  };

  public render() {
    const {onSave, onDelete, selectedGLAccount, adminEventsCacheTwoEvent, adminEventsCacheOne, action, disabled, actions, classForm: {ActiveForm, ValidationRules, SubmitErrorMessage}, loading, selectedClassTypes, handleCompact, inert, hideFormAction, eventDays, youthParticipantTypes, adultsParticipantTypes} = this.props;

    const permissions = adminEventsCacheTwoEvent.EventsEvent ? adminEventsCacheTwoEvent.EventsEvent.Permissions : null;

    return (
      <Main inert={inert}>
        <MainContent loading={loading} className={`${namespace()}--main-content`} handleCompact={handleCompact}>
            <Form
              onSubmit={e => e.preventDefault()}
            >
              {action === 'add' && permissions && permissions.hasAddClassReason ? <Alert className={`${namespace()}--alert`}>{permissions.hasAddClassReason}</Alert> : null}
              {action === 'edit' && permissions && permissions.hasEditClassReason ? <Alert className={`${namespace()}--alert`}>{permissions.hasEditClassReason}</Alert> : null}
              {SubmitErrorMessage && <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert>}
              {this.renderRegisterUnderAndKindOfClass()}
              <Row marginTop={action === 'add' ? 4 : 16}>
                <Column span={12}>
                  <Switch
                    margin={false}
                    newDesign
                    disabled={disabled || action === 'edit' || this.isFormDisabled()}
                    label="Combination"
                    value={!!ActiveForm.IsCombo}
                    validationRules={ValidationRules.IsCombo}
                    multilineLabel
                    onChange={this.onToggleCombination}
                    helperText={action === 'add' ? ['For example, select this option if multiple merit badges are taught as part of the same class.'] : undefined}
                  />
                </Column>
              </Row>
              <Row marginTop={16}>
                <div className={`${namespace()}--label`}>Class Type</div>
              </Row>
              <Row>
                {selectedClassTypes && selectedClassTypes.map((ct) => {
                  return (
                    <Column span={12}>
                      <AdminClassTypeCard
                        key={`selected_class_type_card${ct.IDi}`}
                        type={action === 'add' ? AdminClassTypeCardType.SELECTED_NEW_CLASS : AdminClassTypeCardType.SELECTED_EDIT_CLASS}
                        category={CardCategory.LIST_MOBILE}
                        option={ct}
                        showDeletedPrompt={action === 'add'}
                        disabled={this.isFormDisabled()}
                        onRemove={this.onRemoveClassType}
                        onEdit={this.onEditClassType}
                      />
                    </Column>
                  );
                })}
              </Row>
              {action === 'add' && (ActiveForm.IsCombo || (!ActiveForm.IsCombo && (!selectedClassTypes || selectedClassTypes.length === 0))) && <Row>
                <Button id={generateDOMId("select-class-type")} onClick={this.onSelectClassTypes} textColor='black' color='white' disabled={this.isFormDisabled()}>SELECT</Button>
              </Row>}
              {action === 'add'&& (!ValidationRules.SelectedClassTypeIds.errors || ValidationRules.SelectedClassTypeIds.errors.length === 0) && <Row marginTop={!ActiveForm.IsCombo && selectedClassTypes && selectedClassTypes.length > 0 ? 0 : 12}>
                <div className={`${namespace()}--label bold`}>Selected Class Type(s) can't be changed after a class/option is created. Please make sure you have selected the right class type(s).</div>
              </Row>}
              {action === 'add' && ValidationRules.SelectedClassTypeIds.errors && ValidationRules.SelectedClassTypeIds.errors.length > 0 ? <div className={`${namespace()}--field-error`}>{ValidationRules.SelectedClassTypeIds.errors}</div> : null}
              {ActiveForm.IsCombo && <Row marginTop={12}>
                <Column span={12} mobileSpan={12}>
                  <TextField
                    label="Name"
                    disabled={this.isFormDisabled()}
                    onChange={actions.simpleUpdate}
                    onBlur={actions.updateValue}
                    validationRules={ValidationRules.ComboName}
                    value={ActiveForm.ComboName}
                  />
                </Column>
              </Row>}
              <Row marginTop={ActiveForm.IsCombo ? 8 : 12} styles={{position: 'relative'}}>
                <Column span={6} mobileSpan={12}>
                  <TextField
                    label="Class Code"
                    disabled={this.isFormDisabled()}
                    onChange={actions.simpleUpdate}
                    onBlur={actions.updateValue}
                    validationRules={ValidationRules.ClassCode}
                    value={ActiveForm.ClassCode}
                    info='An optional short code that can be used to identify scheduled classes. If you use a code for each class in your class schedule, you can enter it here to be displayed before the class name.'
                    infoOverflow
                  />
                </Column>
              </Row>
              {adminEventsCacheOne.EventsEventType && adminEventsCacheOne.EventsEventType.ClassScheduling.ShowClassLocation && <Row marginTop={16} styles={{position: 'relative'}}>
                <Column span={6} mobileSpan={12}>
                  <TextField
                    label="Location"
                    disabled={this.isFormDisabled()}
                    onChange={actions.simpleUpdate}
                    onBlur={actions.updateValue}
                    validationRules={ValidationRules.Location}
                    value={ActiveForm.Location}
                    info='Location will be displayed on the Participant Class Schedule reports prior to the event start.'
                    infoOverflow
                  />
                </Column>
              </Row>}
              <FieldSet
                marginBottom={0} marginTop={24} legendMarginBottom={8}
                fixedLegendFontSize
                newDesign
                className={`${namespace()}--time-blocks`}
                fontSize={18} 
                name="Time Blocks"
              >
                <div className={`${namespace()}--label bold`} style={{marginBottom: '16px'}}>Only check off more than one time block to create longer classes; create multiple classes for multiple sessions.</div>
                <Row>{this.renderTimeBlocks()}</Row>
                <Row marginTop={16}>
                  <Column span={12}>
                    <Switch
                      margin={false}
                      newDesign
                      disabled={this.isFormDisabled()}
                      label="All Days in Event"
                      multilineLabel
                      value={!!ActiveForm.IsEntireEvent}
                      validationRules={ValidationRules.IsEntireEvent}
                      onChange={actions.updateValue}
                    />
                  </Column>
                </Row>
                {!ActiveForm.IsEntireEvent && <Row marginTop={16}>
                  <div className={`${namespace()}--label`}>Class Days</div>
                </Row>}
                {!ActiveForm.IsEntireEvent && <Row>{this.renderDays()}</Row>}
              </FieldSet>
                <Row marginTop={16}>
                  <Column span={3} mobileSpan={6}>
                    <MoneyField
                      disabled={this.isFormDisabled()}
                      dollarSignPrefix
                      value={ActiveForm.Amount}
                      validationRules={ValidationRules.Amount}
                      onBlur={actions.updateValue}
                      onChange={actions.simpleUpdate}
                      id={generateDOMId("tentaroo-admin-class-amount-field")}
                      label='Amount'
                      placeHolder="$0.00"
                    />
                  </Column>
                </Row>
                <Row marginTop={8}>
                  <div className={`${namespace()}--label`}>Amount Type</div>
                </Row>
                <Row>
                  <Column span={12}>
                    <RadioGroupOption
                      disabled={this.isFormDisabled()}
                      value={!!ActiveForm.IsAmountActual}
                      selected={!!ActiveForm.IsAmountActual}
                      onChange={actions.updateValue}
                      ValidationRules={ValidationRules.IsAmountActual}
                      rawValue={true}
                      newDesign
                      helperText='Bill a specific amount, track with other fees due in the system.'
                      label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>Actual</Text>}
                    />
                  </Column>
                  <Column span={12}>
                    <RadioGroupOption
                      disabled={this.isFormDisabled()}
                      value={!!!ActiveForm.IsAmountActual}
                      selected={!!!ActiveForm.IsAmountActual}
                      ValidationRules={ValidationRules.IsAmountActual}
                      rawValue={false}
                      onChange={actions.updateValue}
                      newDesign
                      helperText="Amount to be displayed for information purposes only. Not included in the fee breakdown for the user - not on the user's Home tab, on invoices, etc."
                      label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>Estimated</Text>}
                    />
                  </Column>
                </Row>
                {ActiveForm.IsAmountActual && (ActiveForm.Amount === undefined || ActiveForm.Amount >= 0) && <Row marginTop={16}>
                  <div className={`${namespace()}--label`}>Upfront Payment</div>
                </Row>}
                {ActiveForm.IsAmountActual && (ActiveForm.Amount === undefined || ActiveForm.Amount >= 0) && <Row>
                  {adminEventsCacheOne.EventTypeUpfrontPaymentOptions ? adminEventsCacheOne.EventTypeUpfrontPaymentOptions.map((o, index) => {
                    return (
                      <Column layout='horizontal' span={12}>
                        <RadioGroupOption
                          value={ActiveForm.UpfrontPaymentID === o.ID}
                          selected={ActiveForm.UpfrontPaymentID === o.ID}
                          disabled={this.isFormDisabled()}
                          ValidationRules={ValidationRules.UpfrontPaymentID}
                          rawValue={o.ID}
                          onChange={actions.updateValue}
                          newDesign
                          label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>{o.Name}</Text>}
                          helperText={o.Description}
                        />
                      </Column>
                    );
                  }) : null}
                </Row>}
                {ActiveForm.IsAmountActual && (ActiveForm.Amount === undefined || ActiveForm.Amount >= 0) && ActiveForm.UpfrontPaymentID === 2 && <Row className={`${namespace()}--specific-amount-row`} marginTop={8}>
                  <Column span={3} mobileSpan={6}>
                    <MoneyField
                      disabled={this.isFormDisabled()}
                      dollarSignPrefix
                      value={ActiveForm.UpfrontPaymentSpecific}
                      validationRules={ValidationRules.UpfrontPaymentSpecific}
                      onBlur={actions.updateValue}
                      onChange={actions.simpleUpdate}
                      pullDefaultValueFromRule
                      label='Amount'
                      placeHolder="$0.00"
                    />
                  </Column>
                </Row>}
                {(ActiveForm.IsRegisterUnderIndividuals || !ActiveForm.IsOption) && <Row marginTop={16}>
                  <Column span={12} mobileSpan={12}>
                    <ModalSelect
                      label={ActiveForm.IsOption ? 'Source GL Account' : 'GL Account'}
                      selectButtonLabel="SELECT GL ACCOUNT"
                      disabled={this.isFormDisabled()}
                      hideError={!!selectedGLAccount}
                      selectedCard={selectedGLAccount ?
                        <GLAccountCard
                          type={GLAccountCardType.MODAL_SELECT}
                          showDeletedPrompt
                          disabled={this.isFormDisabled()}
                          marginTop={12}
                          onEdit={this.onEditGLAccount}
                          onRemove={this.onRemoveGLAccount}
                          account={selectedGLAccount}
                        /> : null}
                      onClickSelectButton={this.onClickSelectGLAccountButton}
                      validationRules={ValidationRules.GLAccountID}
                      value={ActiveForm.GLAccountID}
                    />
                  </Column>
                </Row>}
                <Row marginTop={ActiveForm.IsRegisterUnderIndividuals || !ActiveForm.IsOption ? (!!selectedGLAccount ? 0 : 8) : 8} styles={{position: 'relative'}}>
                  <Column span={6} mobileSpan={12}>
                    <TextField
                      label="Maximum Capacity"
                      disabled={this.isFormDisabled()}
                      onChange={actions.simpleUpdate}
                      onBlur={actions.updateValue}
                      validationRules={ValidationRules.MaxParticipants}
                      value={ActiveForm.MaxParticipants}
                      info='For no maximum, leave blank or set to 0.'
                      infoOverflow
                    />
                  </Column>
                </Row>
                {ActiveForm.IsRegisterUnderIndividuals && <Row marginTop={16} styles={{position: 'relative'}}>
                  <Column span={6} mobileSpan={12}>
                    <TextField
                      label="Minimum Age"
                      disabled={this.isFormDisabled()}
                      onChange={actions.simpleUpdate}
                      onBlur={actions.updateValue}
                      validationRules={ValidationRules.MinimumAge}
                      value={ActiveForm.MinimumAge}
                      info='This will only apply to youth participants. For no minimum age, leave blank or set to 0.'
                      infoOverflow
                    />
                  </Column>
                </Row>}
                <Row marginTop={16}>
                  <Column span={12}>
                    <Switch
                      margin={false}
                      newDesign
                      disabled={this.isFormDisabled()}
                      label="Allow Self Registration"
                      multilineLabel
                      value={!!ActiveForm.AllowSelfRegistration}
                      validationRules={ValidationRules.AllowSelfRegistration}
                      onChange={actions.updateValue}
                    />
                  </Column>
                </Row>
                {!ActiveForm.IsRegisterUnderIndividuals && <Row>
                  <Column span={12}>
                    <Switch
                      margin={false}
                      newDesign
                      disabled={this.isFormDisabled()}
                      label="Show Youth"
                      multilineLabel
                      value={!!ActiveForm.ShowYouth}
                      validationRules={ValidationRules.ShowYouth}
                      onChange={actions.updateValue}
                    />
                  </Column>
                </Row>}
                {!ActiveForm.IsRegisterUnderIndividuals && <Row>
                  <Column span={12}>
                    <Switch
                      margin={false}
                      newDesign
                      disabled={this.isFormDisabled()}
                      label="Show Adults"
                      multilineLabel
                      value={!!ActiveForm.ShowAdults}
                      validationRules={ValidationRules.ShowAdults}
                      onChange={actions.updateValue}
                    />
                  </Column>
                </Row>}
                <FieldSet
                  marginBottom={0} marginTop={24} legendMarginBottom={8}
                  fixedLegendFontSize
                  newDesign
                  className={`${namespace()}--instructors`}
                  fontSize={18} 
                  name="Instructor"
                >
                  <div className={`${namespace()}--label`}>Info will be used for Blue Cards, Classes Export, Instructor Class Roster and Participant Class Schedule export.</div>
                  <Row marginTop={4}>
                    <Column span={6} mobileSpan={12}>
                      <TextField
                        label="Instructor Full Name"
                        disabled={this.isFormDisabled()}
                        onChange={actions.simpleUpdate}
                        onBlur={actions.updateValue}
                        validationRules={ValidationRules.InstructorName}
                        value={ActiveForm.InstructorName}
                      />
                    </Column>
                    <Column span={3} mobileSpan={6} mobileMarginTop={8}>
                      <TextField
                        label="Initials"
                        disabled={this.isFormDisabled()}
                        onChange={actions.simpleUpdate}
                        onBlur={actions.updateValue}
                        validationRules={ValidationRules.InstructorInitials}
                        value={ActiveForm.InstructorInitials}
                      />
                    </Column>
                  </Row>
                </FieldSet>
                {ActiveForm.IsRegisterUnderIndividuals && <FieldSet
                  marginBottom={0} marginTop={24} legendMarginBottom={8}
                  fixedLegendFontSize
                  newDesign
                  className={`${namespace()}--participant-types`}
                  fontSize={18} 
                  name="Participant Types"
                >
                  <div className={`${namespace()}--label`}>Any new Participant Types will need to be manually selected.</div>
                  <Row marginTop={4}>
                    <Column span={12}>
                      <FormSection
                        title='Youth'
                        className={`${namespace()}--youth-section`}
                        toggle={<Switch
                          margin={false}
                          newDesign
                          disabled={this.isFormDisabled()}
                          value={!!ActiveForm.ShowYouth}
                          validationRules={ValidationRules.ShowYouth}
                          onChange={this.onToggleShowYouth}
                        />}
                        hideContent={!ActiveForm.ShowYouth}
                      >
                        {youthParticipantTypes && youthParticipantTypes.map((p) => {
                          const selected = ActiveForm.ParticipantTypes && ActiveForm.ParticipantTypes.find((id) => id  === p.ID);
                          return (
                            <Column className={`${namespace()}--participant-type-col`} span={12} mobileSpan={12}>
                              <Checkbox value={p.ID}
                                disabled={this.isFormDisabled()}
                                selected={!!selected}
                                newDesign
                                label={<Text color='dark-gray' weight="regular"  size={16} marginBottom={0}>{p.Name}</Text>}
                                onChange={() => this.onCheckParticipantType(p, !selected)} />
                              
                            </Column>
                          );
                        })}
                      </FormSection>
                    </Column>
                  </Row>
                  <Row marginTop={16}>
                    <Column span={12}>
                      <FormSection
                        title='Adults'
                        className={`${namespace()}--adults-section`}
                        toggle={<Switch
                          margin={false}
                          newDesign
                          disabled={this.isFormDisabled()}
                          value={!!ActiveForm.ShowAdults}
                          validationRules={ValidationRules.ShowAdults}
                          onChange={this.onToggleShowAdults}
                        />}
                        hideContent={!ActiveForm.ShowAdults}
                      >
                        {adultsParticipantTypes && adultsParticipantTypes.map((p) => {
                          const selected = ActiveForm.ParticipantTypes && ActiveForm.ParticipantTypes.find((id) => id  === p.ID);
                          return (
                            <Column className={`${namespace()}--participant-type-col`} span={12} mobileSpan={12}>
                              <Checkbox value={p.ID}
                                disabled={this.isFormDisabled()}
                                selected={!!selected}
                                newDesign
                                label={<Text color='dark-gray' weight="regular"  size={16} marginBottom={0}>{p.Name}</Text>}
                                onChange={() => this.onCheckParticipantType(p, !selected)} />
                              
                            </Column>
                          );
                        })}
                      </FormSection>
                    </Column>
                  </Row>
                </FieldSet>}
            </Form>
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedRegistrationMethodSelector = makeSelectedRegistrationMethodSelector(true);
  const eventDaysSelector = makeEventDaysSelector();
  const selectedGLAccountSelector = makeSelectedGLAccountSelector();
  const youthParticipantTypesSelector = makeYouthParticipantTypesSelector();
  const adultsParticipantTypesSelector = makeAdultsParticipantTypesSelector();
  const selectedClassTypesSelector = makeSelectedClassTypesSelector();
  
  return {
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsCacheTwoEvent: state.adminEvents.cacheTwoEvent,
    classForm: state.adminEvents.classes.form,
    selectedRegistrationMethod: selectedRegistrationMethodSelector(state),
    eventDays: eventDaysSelector(state),
    selectedGLAccount: selectedGLAccountSelector(state),youthParticipantTypes: youthParticipantTypesSelector(state),
    adultsParticipantTypes: adultsParticipantTypesSelector(state),
    selectedClassTypes: selectedClassTypesSelector(state),
    cacheZero: state.cacheZero,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
  }, dispatch),
});

const ConnectedAdminEventClassTypeForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(AdminEventClassTypeForm);

export default ConnectedAdminEventClassTypeForm;
