import { RouteComponentProps, withRouter } from "react-router";
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { ApplicationState } from "../../../../../../store";
import { Modal, ModalHeader, ModalContent, Button, ModalActions } from "../../../../../../components/Elements";
import { actionCreators, FacilityTypeSharedSettingsModalNmespace as namespace } from "../../../../../../store/AdminFacilityLocation/Facilities/FacilityType/Form/actions";
import { actionCreators as cacheOneActionCreators } from "../../../../../../store/AdminFacilityLocation/CacheOne/actions";
import { actionCreators as appActionCreators } from "../../../../../../store/App/actions";
import { actionCreators as rollbackActionCreators } from "../../../../../../store/Rollback/actions";
import { makeFacilityTypeSharedSettingsSectionSelector } from "../../../../../../store/AdminFacilityLocation/Facilities/FacilityType/Form";
import FacilityTypeForm from "../../FacilityType/Form";
import '../../../../../../styles/pages/admin-facility-location/facilities/modals/facility-type-shared-settings/index.scss';
import { connect } from "react-redux";
import { getMergeProps } from "../../../../../../utils/reduxHelper";
import { shouldBlockActions } from "../../../../../../utils/cacheLoaders/helpers/blockers";
import {WithInertAttribute} from "../../../../../Elements/WithInert";

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

type Props = WithInertAttribute<{}>;

@(withRouter as any)
class FacilityShareSettings extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;
  private modalContentRef;

  componentDidMount() {
    if (shouldBlockActions()) return;
    
    this.props.actions.initFacilityTypeForm();
  }

  componentWillUnmount() {
  }

  onSave = () => {
    this.props.actions.apiSubmitForm(this.props.routes, this.props.router, this.props.showSection);
  };

  public render() {
    const {adminFacilityLocationCacheOne,  cacheZero, formState, actions, apiSaving, showSection, adminFacilityLocationCacheTwoFacilityTypes, inert} = this.props;
    const subtitle = adminFacilityLocationCacheTwoFacilityTypes.FacilitiesType ? adminFacilityLocationCacheTwoFacilityTypes.FacilitiesType.General.NamePlural : '';

    return (
      <Modal inert={inert} wideModal big mobileFullScreen className={namespace()}>
        <ModalHeader
          subtitle={subtitle}
          className={`${namespace()}--header`}
        >
          <div className={`${namespace()}--header--title`}>Edit Facility Type Shared Settings</div>
          <div className={`${namespace()}--header--title mobile`}>Edit Shared Settings</div>
        </ModalHeader>
        <ModalContent refCallback={(ref) => this.modalContentRef = ref} hasPadding>
          <FacilityTypeForm
            inert={inert}
            handleCompact
            showSection={showSection}
            cacheZero={cacheZero}
            adminFacilityLocationCacheOne={adminFacilityLocationCacheOne}
            formState={formState}
            reduxActions={actions}
            disabled={apiSaving > 0}
            hideFormAction
            loading={apiSaving > 0}
            action={'edit'}
          />
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button textColor='black' disabled={apiSaving > 0 || !cacheZero.options || !cacheZero.options.GeneralPermissions || !cacheZero.options.GeneralPermissions.hasFacilityTypeEdit} flat onClick={this.onSave}>SAVE</Button>}
        />
      </Modal>
    );
  }
}


const mapStateToProps = (state: ApplicationState) => {
  const sectionSelector = makeFacilityTypeSharedSettingsSectionSelector();
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
    adminFacilityLocationCacheTwoFacilityTypes: state.adminFacilityLocation.cacheTwoFacility,
    formState: state.adminFacilityLocation.facilities.facilityType.form,
    showSection: sectionSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...cacheOneActionCreators,
  ...appActionCreators,
  ...rollbackActionCreators,
}, dispatch) });

const ConnectedFacilityShareSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(FacilityShareSettings);

export default ConnectedFacilityShareSettings;