
import {makeRequestEpic} from "../../Validation/epicCreator";
import { GetRegisteredGroupsAction } from "./actions";
import { getRegisteredGroups, getRegisteredGroupsBody } from "../../../constants/adminEventsUrls";

export const getRegisteredGroupsEpic = makeRequestEpic(
  GetRegisteredGroupsAction,
  getRegisteredGroups,
  getRegisteredGroupsBody,
  true,
);