import {
  createApiSubmitActions,
  createValidateActions,
  createApiValidateActions,
  createUpdateValueMethod,
  createSimpleUpdateValueMethod,
} from "../../../Validation/actionCreator";
import { ApplicationState } from '../../..';

export const SUFFIX = '__ADMIN_EVENTS__YEAR_OVERVIEW';
export const UPDATE_YEAR_OVERVIEW_SUFFIX = '__UPDATE_YEAR_OVERVIEW__';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export type Actions = typeof actionCreators;
export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.reports.yearOverview),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
};