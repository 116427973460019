import * as React from 'react';
import {bindActionCreators} from 'redux';
import {RouteComponentProps, withRouter} from "react-router";
import {actionCreators} from '../../../../../../store/Events/Event/Register/Products/Main/actions';
import Products from './Products';
import Confirmation from './Confirmation';
import {
  actionCreators as cacheFourEventsProductsActionCreators,
} from '../../../../../../store/CacheFourEventsProducts/actions';
import {
  actionCreators as cacheThreeEventsActionCreators,
} from '../../../../../../store/CacheThreeEvents/actions';
import {LoadingAll} from "../../../../../Elements";
import { ApplicationState } from '../../../../../../store';
import EndUserCacheManager from '../../../../../../utils/cacheManagers/endUserCacheManager';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isCacheFourEventsProductsPopulated} from '../../../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--event';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string}>
>;

@withRouter
class EventRegisterProducts extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
	//
  // componentWillMount() {
  //   const {location} = this.props;
  //   if (location.action === 'POP') {
  //     reduxStoreService().dispatch(replace({
  //       pathname: `${location.pathname.split(`/${EVENT.REGISTER}`)[0]}/${EVENT.REGISTRATION}`,
  //       query: location.query
  //     }));
  //   }
  // }

  componentDidMount() {
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheFourEventsProducts({
          props: this.props,
          isStateNavigated,
        });
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    // We need to call the loaders on props change, so that redirection can happen
    // on logout.
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheFourEventsProducts({
          props: nextProps,
          isStateNavigated,
        });

      }
    );
  }

  public componentWillUnmount() {
    const {actions, cacheFourEventsProducts} = this.props;
    // TODO: Later, make it consistent with admin module:
    // use `clearCacheBelowThreeEvents` to clear both form and caches, and always init on mount
    actions.reset();

    if (isCacheFourEventsProductsPopulated(cacheFourEventsProducts)) {
      actions.clearCacheBelowThreeEvents();
    }
  }

  public render() {
    const {eventRegisterProducts: {selectedPage}, cacheFourEventsProducts, inert} = this.props;
    if (!isCacheFourEventsProductsPopulated(cacheFourEventsProducts)) return <LoadingAll/>;
    if (selectedPage === 'products') {
      return <Products inert={inert} />;
    } else if (selectedPage === 'confirmation') {
      return <Confirmation inert={inert} />;
    }
    return null;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    eventRegisterProducts: state.events.event.register.products.main,
    user: state.user,
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheFourEventsProducts: state.cacheFourEventsProducts,
    cacheThreeEvents: state.cacheThreeEvents,
    cacheTwoEvents: state.cacheTwoEvents,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...cacheFourEventsProductsActionCreators,
  ...cacheThreeEventsActionCreators,
}, dispatch)});

const ConnectedEventRegisterProducts = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(EventRegisterProducts);

export default ConnectedEventRegisterProducts;

