import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import * as M from "../../../../../../constants/messages/generic";
import * as MP from "../../../../../../constants/messages/participantsWizard";
import {createSelector} from "reselect";
import { Options } from "../../../../../../models/api/options";
import { EventTypeRegistrationSettings } from "../../../../../../models/api/cacheTwoEvents";
import { ApplicationState } from "../../../../..";
import {
  ShowIsScoutField, PersonFormValidatior, createFormDefinition,
  GetRankOptions, ShowRankField
} from "../../../../../../utils/personFormValidationRules";
import { YesNoBlankOptions } from "../../../../../../utils/validator";
import {MAX_LENGTH} from "../../../../../../constants/messages/generic";
import { districtsSelector, filteredDistrictsCombiner } from "../../../../../CacheZero/selectors";
import { captureTentarooError } from "../../../../../../utils/dataHelper";
import { SelectValidator, Validator } from "../../../../../../utils/validator/models";

export const ShowUnitFields = (GroupTypeID: number, AllowNonScouter: boolean, ScoutTypeID: number, IsYouth: boolean) => {
  return GroupTypeID !== 1 && (!AllowNonScouter || ScoutTypeID === 2 || (ScoutTypeID === 3 && IsYouth));
};

const RetrieveRankOptions = (rootState: ApplicationState) => {
  const { RankOptions, CubRankOptions, Group} = rootState.cacheZero.options as Options;
  const {ActiveForm} = rootState.events.event.register.participant.roster;
  const EventTypeRegistrationSettings = rootState.cacheTwoEvents.EventTypeRegistrationSettings as EventTypeRegistrationSettings;
  let ScoutTypeID = FormDefinition.ScoutTypeID.defaultValue?.(rootState);
  if (ActiveForm.ScoutTypeID) ScoutTypeID = ActiveForm.ScoutTypeID;
  let UnitTypeID = FormDefinition.UnitTypeID.defaultValue?.(rootState);
  if (ActiveForm.UnitTypeID) UnitTypeID = ActiveForm.UnitTypeID;

  if (!Group) {
    captureTentarooError(new Error("Group not available when RetrieveRankOptions in ParticipantRoster"));
    return [];
  }
  return GetRankOptions(
    RankOptions as Array<any>,
    CubRankOptions as Array<any>,
    EventTypeRegistrationSettings.NamesRegistrationSettings.AllowNonScouterParticipants,
    ScoutTypeID,
    Group.GroupTypeID,
    Group.UnitTypeID,
    UnitTypeID
  );
};


const ShowUnit = (rootState: ApplicationState) => {
  const options = rootState.cacheZero.options;
  const {ActiveForm} = rootState.events.event.register.participant.roster;
  const settings = rootState.cacheTwoEvents.EventTypeRegistrationSettings;
  let ScoutTypeID = FormDefinition.ScoutTypeID.defaultValue?.(rootState);

  if (!settings) {
    captureTentarooError(new Error("EventTypeRegistrationSettings not available when computing ShowUnit in ParticipantRoster"));
    return false;
  }
  if (!options) {
    captureTentarooError(new Error("options not available when computing ShowUnit in ParticipantRoster"));
    return false;
  }
  if (!options.Group) {
    captureTentarooError(new Error("Group not available when computing ShowUnit in ParticipantRoster"));
    return false;
  }
  if (ActiveForm.ScoutTypeID) ScoutTypeID = ActiveForm.ScoutTypeID;
  return ShowUnitFields(
    options.Group.GroupTypeID,
    settings.NamesRegistrationSettings.AllowNonScouterParticipants,
    ScoutTypeID,
    isYouth(rootState)
  );
};

const ShowScoutField = (rootState: ApplicationState) => {
  const options = rootState.cacheZero.options;
  const settings = rootState.cacheTwoEvents.EventTypeRegistrationSettings;
  if (!settings) {
    captureTentarooError(new Error("EventTypeRegistrationSettings not available when computing ShowScoutField in ParticipantRoster"));
    return false;
  }
  if (!options) {
    captureTentarooError(new Error("options not available when computing ShowScoutField in ParticipantRoster"));
    return false;
  }
  if (!options.Group) {
    captureTentarooError(new Error("Group not available when computing ShowScoutField in ParticipantRoster"));
    return false;
  }
  // let ScoutTypeID = (FormDefinition.ScoutTypeID as any).defaultValue();
  let ScoutTypeID = 0;
  if (rootState.events.event.register.participant.roster.ActiveForm.ScoutTypeID) ScoutTypeID = rootState.events.event.register.participant.roster.ActiveForm.ScoutTypeID;
  return ShowIsScoutField(
    options.Group.GroupTypeID,
    settings.NamesRegistrationSettings.AllowNonScouterParticipants,
    ScoutTypeID,
    isYouth(rootState)
  );
};

const ShowRank = (rootState: ApplicationState) => {
  const options = rootState.cacheZero.options;
  const {ActiveForm} = rootState.events.event.register.participant.roster;
  let ScoutTypeID = FormDefinition.ScoutTypeID.defaultValue?.(rootState);
  const settings = rootState.cacheTwoEvents.EventTypeRegistrationSettings;
  if (!settings) {
    captureTentarooError(new Error("EventTypeRegistrationSettings not available when computing ShowRank in ParticipantRoster"));
    return false;
  }
  if (!options) {
    captureTentarooError(new Error("options not available when computing ShowRank in ParticipantRoster"));
    return false;
  }
  if (!options.Group) {
    captureTentarooError(new Error("Group not available when computing ShowRank in ParticipantRoster"));
    return false;
  }
  if (ActiveForm.ScoutTypeID) ScoutTypeID = ActiveForm.ScoutTypeID;
  return ShowRankField(
    settings.NamesRegistrationSettings.AllowNonScouterParticipants,
    options.Group.GroupTypeID,
    ScoutTypeID
  );
};

const filterCouncilSelector = (state: ApplicationState) => state.events.event.register.participant.roster.ActiveForm.CouncilIDi;

export const makeFilteredDistrictSelector = () => {
  return createSelector(
    [districtsSelector, filterCouncilSelector],
    filteredDistrictsCombiner,
  );
};

const filteredDistrictSelector = makeFilteredDistrictSelector();

const getYAKey = (rootState: ApplicationState, key: string) => {
  const {cacheTwoEvents} = rootState;

  if (!cacheTwoEvents.EventTypeRegistrationSettings) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    return "";
  }
  if (isYouth(rootState)) {
    return cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings[`${key}Youth`];
  } else {
    return cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings[`${key}Adult`];
  }
};

export const isYouth = (rootState: ApplicationState) => {
  // /events/4/5288/Week_1_2018/register/participant/y/544329/Jon_Testerr/edit
  // /events/4/5288/Week_1_2018/register/participant
  const path = rootState.routing.locationBeforeTransitions.pathname.split('/');
  if (path.length === 12) {
    return path[8] === 'y';
  } else if (path.length === 8) {
    const selectedTab = rootState.events.event.register.participant.roster.selectedTab;
    if (selectedTab === 'new_youth') {
      return true;
    } else if (selectedTab === 'new_adult') {
      return false;
    } else {
      const selectedPerson = rootState.cacheFourEventsParticipants.GroupRosterPerson;
      if (selectedPerson) {
        return selectedPerson.IsYouth;
      }
      // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
      return true;
    }
  }
  // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
  return true;
};

const getRequireEmergencyContact = (rootState: ApplicationState) => {
  return !rootState.cacheTwoEvents.EventTypeRegistrationSettings ? false : rootState.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.RequireEmergencyContact;
};

const getAllowNonScouterParticipants = (rootState: ApplicationState) => {
  return !rootState.cacheTwoEvents.EventTypeRegistrationSettings ? false : rootState.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.AllowNonScouterParticipants;
};

const showSummerCamp = (rootState: ApplicationState) => {
  return !rootState.cacheTwoEvents.EventTypeRegistrationSettings ? false : rootState.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.ShowSummerCampYearYouth;
};

const getPerson = (rootState: ApplicationState) => rootState.cacheFourEventsParticipants;
const getActiveForm = (rootState: ApplicationState) => rootState.events.event.register.participant.roster.ActiveForm;

export interface EventPersonFormValidator {
  IsStaff: SelectValidator,
  SummerCampYear: Validator,
  InCampAllWeek: Validator,
  InCampMon: Validator,
  InCampTue: Validator,
  InCampWed: Validator,
  InCampThu: Validator,
  InCampFri: Validator,
  InCampSat: Validator,
  InCampSun: Validator,
  RegistrationNotes: Validator
}

const scoutCustomValidate = (rootState: ApplicationState) => {
  const {ActiveForm} = rootState.events.event.register.participant.roster;

  if (!rootState.cacheTwoEvents.EventTypeRegistrationSettings) {
    return undefined;
  }
  const {NamesRegistrationSettings} = rootState.cacheTwoEvents.EventTypeRegistrationSettings;
  if ((isYouth(rootState) && ActiveForm.ScoutTypeID !== undefined && ActiveForm.ScoutTypeID > 3) || (!isYouth(rootState) && ActiveForm.ScoutTypeID !== undefined && ActiveForm.ScoutTypeID > 2)) {
    if (!NamesRegistrationSettings.AllowNonScouterParticipants && ShowScoutField(rootState)) {
      return MP.ONLY_SCOUTERS;
    }
  }
  return undefined;
};

export const FormDefinition: PersonFormValidatior & EventPersonFormValidator = {
  ...createFormDefinition(
    getYAKey,
    isYouth,
    ShowUnit,
    RetrieveRankOptions,
    getRequireEmergencyContact,
    getAllowNonScouterParticipants,
    getPerson as any,
    getActiveForm,
    ShowScoutField,
    filteredDistrictSelector,
    ShowRank,
    scoutCustomValidate
  ),
  IsStaff: {
    key: 'IsStaff',
    defaultValue: (rootState) => {
      return !!rootState.cacheTwoEvents.EventTypeRegistrationSettings && rootState.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.ShowIsStaffYouth ? -1 : 0;
    },
    options: {
      values: () => [...YesNoBlankOptions],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: -1
    },
    isRequired: (rootState) => {
      return isYouth(rootState) && !!rootState.cacheTwoEvents.EventTypeRegistrationSettings && rootState.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.ShowIsStaffYouth;
    },
    skip: (rootState) => {
      if (!isYouth(rootState)) return true;
      return !!rootState.cacheTwoEvents.EventTypeRegistrationSettings && !rootState.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.ShowIsStaffYouth;
    },
    validatejs: {
      IsStaff: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: -1
        }
      }
    }
  },
  SummerCampYear: {
    key: 'SummerCampYear',
    integerOnly: true,
    allowNegative: () => false,
    isRequired: (rootState) => isYouth(rootState) && showSummerCamp(rootState),
    skip: (rootState) => {
      if (!isYouth(rootState)) return true;
      return !showSummerCamp(rootState);
    },
    validatejs: {
      SummerCampYear: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          onlyInteger: true,
          notValid: '^' + M.MUST_BE_NUMBER,
        },
        length: {
          maximum: 2,
          tooLong: MAX_LENGTH(2)
        }
      }
    }
  },
  InCampAllWeek: {
    key: 'InCampAllWeek'
  },
  InCampMon: {
    key: 'InCampMon'
  },
  InCampTue: {
    key: 'InCampTue'
  },
  InCampWed: {
    key: 'InCampWed'
  },
  InCampThu: {
    key: 'InCampThu'
  },
  InCampFri: {
    key: 'InCampFri'
  },
  InCampSat: {
    key: 'InCampSat'
  },
  InCampSun: {
    key: 'InCampSun'
  },
  RegistrationNotes: {
    key: 'RegistrationNotes',

    validatejs: {
      RegistrationNotes: {
        length: {
          maximum: 1024,
          tooLong: MAX_LENGTH(1024)
        }
      }
    }
  }
};
