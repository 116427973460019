import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminEventsCacheOneContext } from '@tentaroo/shared';

import { ApplicationState } from '../../../../../store';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as cacheOneActionCreators, GetEventTypeCacheAction } from '../../../../../store/AdminEvents/CacheOne/actions';
import { actionCreators as cacheTwoProductsActionCreators } from '../../../../../store/AdminEvents/CacheTwoProduct/actions';
import { actionCreators as productFormActionCreators } from '../../../../../store/AdminEvents/Products/Form/actions';
import { LoadingAll, EmptyMessage, Button, Loader } from '../../../../Elements';
import { Main, MainContent } from '../../../../Layouts';
import '../../../../../styles/pages/admin-events/products/home/index.scss';
import ProductsFilter from './ProductsFilter';
import { makeActiveProductsSelector, makeFilteredProductsSelector } from '../../../../../store/AdminEvents/Products/Home';
import { actionCreators } from '../../../../../store/AdminEvents/Products/Home/actions';
import { AdminEventsProduct } from '../../../../../models/api/adminEventsCacheOne';
import { ENTITY_NOT_ADDED, ENTITY_NOT_FOUND } from '../../../../../constants/messages/adminCMS';
import { ADMIN_PRODUCTS } from '../../../../../constants/messages/adminEvents';
import { EmptyMessageType } from '../../../../Elements/EmptyMessage';
import { ProductIcon } from '../../../../Icons';
import AdminProductCard, { AdminProductCardType } from '../../../../Elements/AdminProduct/Card';
import { CardCategory } from '../../../../Elements/Card';
import { ADMIN_EVENTS_NEW_PRODUCT_PATH, ADMIN_EVENTS_EDIT_PRODUCT_PATH, ADMIN_EVENTS_PRODUCTS_PATH } from '../../../../../routes';
import ProductForm from '../Product';
import { constructAdminEventsProductUrlParams, getEditProductUrl, getNewProductUrl, getProductsHomeRootUrl, constructAdminEventsUrlParams } from '../../../../../constants/adminEventsUrls';
import { navPush } from '../../../../../utils';
import { isPathnameMatchingRoute } from '../../../../../utils/urlHelper';
import { checkAdminEventProductPermission, IAdminProductRouterParams } from '../../../../../utils/helpers/adminEventsPageHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import AdminEventsCacheManager from '../../../../../utils/cacheManagers/adminEventsCacheManager';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { shouldBlockActions } from '../../../../../utils/cacheLoaders/helpers/blockers';
import { SaveState } from '../../../../../store/Rollback/actions';
import { noOpenedModals } from '../../../../../utils/modalHelper';
import { reduxStoreService } from '../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isAdminEventsCacheOnePopulated} from '../../../../../utils/cachePopulatedCheckers/adminEvents';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--products';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminProductRouterParams, {}>
>;

class ProductsHome extends ComponentUpdateTemplate<ConnectedProps, {}> {
  public props: ConnectedProps;

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        const {routes} = this.props;
        const route = routes[routes.length - 1];
        if (route.path === ADMIN_EVENTS_EDIT_PRODUCT_PATH || route.path === ADMIN_EVENTS_NEW_PRODUCT_PATH) {
          AdminEventsCacheManager.getInstance().loadCacheTwoProduct({
            props: this.props,
            isStateNavigated,
            isEdit: route.path === ADMIN_EVENTS_EDIT_PRODUCT_PATH,
          });
        } else {
          AdminEventsCacheManager.getInstance().loadAdminEventsCacheOne({
            props: this.props,
            isStateNavigated,
            context: AdminEventsCacheOneContext.PRODUCTS_LIST,
          });
        }
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    const {routes} = this.props;
    const thisRoute = routes[routes.length - 1];
    const prevRoute = prevProps.routes[prevProps.routes.length - 1];
    const thisPath = thisRoute.path;
    const prevPath = prevRoute.path;

    if (shouldBlockActions()) return;

    if (
      thisPath !== prevPath &&
      thisPath === ADMIN_EVENTS_PRODUCTS_PATH &&
      prevPath !== ADMIN_EVENTS_PRODUCTS_PATH
    ) {
      reduxStoreService().dispatch(new SaveState());
    }
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    const {routes} = nextProps;
    const thisRoute = routes[routes.length - 1];
    const prevRoute = this.props.routes[this.props.routes.length - 1];
    const thisPath = thisRoute.path;
    const prevPath = prevRoute.path;

    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        if (thisPath === ADMIN_EVENTS_EDIT_PRODUCT_PATH || thisPath === ADMIN_EVENTS_NEW_PRODUCT_PATH) {
          AdminEventsCacheManager.getInstance().loadCacheTwoProduct({
            props: nextProps,
            isStateNavigated,
            isEdit: thisPath === ADMIN_EVENTS_EDIT_PRODUCT_PATH,
          });
        } else {
          AdminEventsCacheManager.getInstance().loadAdminEventsCacheOne({
            props: nextProps,
            isStateNavigated,
            context: prevPath !== ADMIN_EVENTS_EDIT_PRODUCT_PATH && prevPath !== ADMIN_EVENTS_NEW_PRODUCT_PATH ? AdminEventsCacheOneContext.PRODUCTS_LIST : undefined
          });
        }
      }
    );
  }

  componentWillUnmount() {
  }

  onNewProductClick = (product: AdminEventsProduct) => {
    const {routes, adminEventsCacheOne} = this.props;

    checkAdminEventProductPermission(
      () => {
        const route = routes[routes.length - 1];
        if (route.path === ADMIN_EVENTS_EDIT_PRODUCT_PATH) return;
        if (!adminEventsCacheOne || !adminEventsCacheOne.EventsEventType) return;
            
        const newProductUrl = getNewProductUrl(constructAdminEventsProductUrlParams(this.props, adminEventsCacheOne, product));
        navPush(this.props.router, newProductUrl);
      },
      adminEventsCacheOne,
    );
  };
  onEditProductClick = (product: AdminEventsProduct) => {
    const {routes, adminEventsCacheOne } = this.props;
    const route = routes[routes.length - 1];
    if (!adminEventsCacheOne || !adminEventsCacheOne.EventsEventType) return;
    if (!adminEventsCacheOne || !adminEventsCacheOne.EventsEventType) return;
        
    const editProductUrl = getEditProductUrl(constructAdminEventsProductUrlParams(this.props, adminEventsCacheOne, product));
    navPush(this.props.router, editProductUrl);
  };
  onDeleteProductClick = (product: AdminEventsProduct) => {
    const {productFormActions, router, routes, adminEventsCacheOne} = this.props;

    checkAdminEventProductPermission(
      () => {
        productFormActions.deleteAdminEventProduct(product.IDi, router, routes, true);
      },
      adminEventsCacheOne,
      true,
    );
  };
  onRestoreProductClick = (product: AdminEventsProduct) => {
    const {productFormActions, adminEventsCacheOne, router, routes} = this.props;

    checkAdminEventProductPermission(
      () => {
        productFormActions.deleteAdminEventProduct(product.IDi, router, routes, false);
      },
      adminEventsCacheOne,
      true,
    );
  };

  renderEmptyMessage = (emptyMessage: string) => {
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        admin
        icon={ProductIcon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        noMobilePadding
        description={emptyMessage}
        actions={<Button className={`${namespace()}--empty-message-btn`} color="green" onClick={this.onNewProductClick}>{'NEW PRODUCT'}</Button>}/>
    );
  };

  renderContent = () => {
    const {filteredProducts} = this.props;

    return (
      <div className={`${namespace()}--list-wrapper`}>
        {filteredProducts.map((p, index) => {
          const selected = isPathnameMatchingRoute(location.pathname, ADMIN_EVENTS_EDIT_PRODUCT_PATH, 'productId', p.IDi);
          return (
            <AdminProductCard
              key={`product_card${p.IDi}`}
              type={AdminProductCardType.PRODUCT_LIST}
              category={CardCategory.LIST}
              product={p}
              onEdit={this.onEditProductClick}
              onRestore={this.onRestoreProductClick}
              onDelete={this.onDeleteProductClick}
              selected={selected}
            />
          );
        })}
      </div>
    );

  };

  onClickMain = (e) => {
    // if we are clicking on the `tab`, dont do anything in this callback (i.e. manually stop propagation)
    if (e.target.classList.contains('mdl-tabs__tab')) return;
    e.stopPropagation();
    const { routes, router, adminEventsCacheOne} = this.props;
    const thisRoute = routes[routes.length - 1];

    if (reduxStoreService().getState().app.apiSaving <= 0 && adminEventsCacheOne.EventsEventType) {
      if (thisRoute.path === ADMIN_EVENTS_NEW_PRODUCT_PATH || thisRoute.path === ADMIN_EVENTS_EDIT_PRODUCT_PATH) {
        const backUrl = getProductsHomeRootUrl(constructAdminEventsUrlParams(this.props, adminEventsCacheOne));
        navPush(router, backUrl);
      }
    }
  };

  public render() {
    const {productsHomeForm, routes, adminEventsCacheOne, actions, apiLoadingMap, activeProducts, filteredProducts, apiSaving, inert } = this.props;
    if (!isAdminEventsCacheOnePopulated(adminEventsCacheOne)) {
      return <LoadingAll />;
    }
    let emptyMessage;
    const thisRoute = routes[routes.length - 1];

    if (activeProducts.length === 0 && filteredProducts.length === 0) {
      emptyMessage = ENTITY_NOT_ADDED(ADMIN_PRODUCTS);
    } else if (filteredProducts.length === 0) {
      emptyMessage = ENTITY_NOT_FOUND(ADMIN_PRODUCTS);
    }

    const loading = apiLoadingMap[GetEventTypeCacheAction.requestType] && noOpenedModals();
    const saving = apiSaving > 0 && noOpenedModals();

    return (
      <Main inert={inert} isLoading={loading} isAdminEvents onClick={this.onClickMain}>
        <MainContent className={namespace()} handleCompact>
          <div className={`${namespace()}--list-actions`}>
            <ProductsFilter
              ActiveForm={productsHomeForm.ActiveForm}
              ValidationRules={productsHomeForm.ValidationRules}
              reduxActions={actions}
              newButton={
                <Button id={generateDOMId("admin-product-add-btn")} className='control' color='green' textColor='white' onClick={this.onNewProductClick}>New</Button>}
            />
          </div>
          {emptyMessage && this.renderEmptyMessage(emptyMessage)}
          {!emptyMessage && this.renderContent()}
          {saving && <Loader className={`${namespace()}--loader`} center />}
          {(thisRoute.path === ADMIN_EVENTS_NEW_PRODUCT_PATH || thisRoute.path === ADMIN_EVENTS_EDIT_PRODUCT_PATH) && (
            <ProductForm
              inert={inert}
              onDelete={thisRoute.path === ADMIN_EVENTS_EDIT_PRODUCT_PATH ? this.onDeleteProductClick : undefined}
              type={thisRoute.path === ADMIN_EVENTS_EDIT_PRODUCT_PATH ? 'edit' : 'add'}
              key='product-sidebar'
            />
          )}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const activeProductsSelector = makeActiveProductsSelector();
  const filteredProductsSelector = makeFilteredProductsSelector();

  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsCacheTwoProduct: state.adminEvents.cacheTwoProduct,
    productsHomeForm: state.adminEvents.products.home,
    cacheZero: state.cacheZero,
    activeProducts: activeProductsSelector(state),
    filteredProducts: filteredProductsSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...cacheOneActionCreators,
    ...cacheTwoProductsActionCreators,
  }, dispatch),
  productFormActions: bindActionCreators({
    ...productFormActionCreators,
  }, dispatch),
});
const ConnectedProductsHome = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(ProductsHome);
export default withRouter<{}>(ConnectedProductsHome);
