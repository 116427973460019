import { IValidator, FormDefinition } from "./validation";
import { SUFFIX, ResetNewPageModal, InitNewPageModal } from "./actions";
import { apiValidationReducerCreator } from "../../../../../store/Validation/reducerCreator";
import { createSelector } from "reselect";
import { APISuccess, WithRootState } from "../../../../../store/Validation/actionCreator";
import { PageTypeIDValue, PageType, SiteEventCategory } from "../../../../../models/api/adminCMSCacheOne";
import { ApplicationState } from "../../../../../store";
import * as moment from 'moment';
import { locationsSelector } from "../../../../AdminSettings/Modals/Location";
import { GetDefaultForPrimaryEventPageSubmitActions } from "../../Page/Form/actions";
import { setDefaults } from "../../../../../utils/validator";
import { siteEventCategoriesSelector } from "../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../../utils/StrongActions";

export interface NewPageModalActiveForm {
  PageTypeID?: PageTypeIDValue;
  Name?: string;
  ShortURL?: string;
  StartDate?: moment.Moment;
  EndDate?: moment.Moment;
  DistrictIDi?: number;
  EventTypeID?: number;
  IsPrimaryEventPage?: boolean;
  ShowRegistrationLinks?: boolean;
  IncludeModuleContacts?: boolean;
  LocationID?: number;
  EventCategoryID?: number;
  IsAllDay?: boolean;
  StartHour?: number;
  StartMin?: number;
  StartPeriod?: number;
  EndHour?: number;
  EndMin?: number;
  EndPeriod?: number;
}

export interface NewPageModalState {
  ActiveForm: NewPageModalActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
}

const getInitialState = () => {
  return {
    ActiveForm: {
      EventTypeID: 0,
    },
    ValidationRules: { ...FormDefinition }
  };
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

export const pageTypesSelector = (state: ApplicationState) => state.adminCMSSite.cacheOne.PageTypes;

export const makeFilteredPageTypesSelector = () => {
  return createSelector(
    [pageTypesSelector],
    (pageTypes) => {
      if (!pageTypes) return [];

      return pageTypes.filter((p) => p.ShowWhenAdding).sort((a, b) => a.AddingOrder - b.AddingOrder);
    }
  );
};

const selectedLocationIDSelector = (state: ApplicationState) => state.adminCMSSite.pages.modals.newPage.ActiveForm.LocationID;

export const makeSelectedLocationSelector = () => {
  return createSelector(
    [locationsSelector, selectedLocationIDSelector],
    (locations: any[], locationId: number) => {
      if (locations && locationId) return locations.find((l) => l.ID === locationId);

      return null;
    }
  );
};

const selectedEventCategoryIDSelector = (state: ApplicationState) => {
  if (state.adminCMSSite.pages && state.adminCMSSite.pages.modals && state.adminCMSSite.pages.modals.newPage) return state.adminCMSSite.pages.modals.newPage.ActiveForm.EventCategoryID;
  return -1;
};

export const makeSelectedEventCategorySelector = () => {
  return createSelector(
    [siteEventCategoriesSelector, selectedEventCategoryIDSelector],
    (eventCategories: Array<SiteEventCategory>, categorId: number) => {
      if (categorId === -1 || !eventCategories || eventCategories.length === 0) return null;

      return eventCategories.find((c) => c.ID === categorId);
    },
  );
};

const NewPageModalReducer: Reducer<NewPageModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);
  
  if (act.type === GetDefaultForPrimaryEventPageSubmitActions.successType) {
    const action = <WithRootState<AnyAction>>act;
    const res = action.response.xhr.response;
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        IsPrimaryEventPage: res.IsPrimaryEventPage,
      },
    };
  } else if (isActionType(act, ResetNewPageModal)) {
    return getInitialState();
  } else if (isActionType(act, InitNewPageModal)) {
    const newState = setDefaults(act.rootState, {...state}, state);
    return newState;
  }
  return state || getInitialState();
};

export default NewPageModalReducer;