
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetAdminEvent} from "./actions";
import { getAdminEventBody, getAdminEventCache } from "../../../constants/adminEventsUrls";

export const adminEventsCacheTwoEventEpic = makeRequestEpic(
  GetAdminEvent,
  getAdminEventCache,
  getAdminEventBody,
  true,
);