import { createValidateActions, createApiValidateActions, createApiSubmitActions, createUpdateValueMethod, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra } from '../../../../../store/Validation/actionCreator';
import type { ApplicationState, ActionCreator } from '../../../../../store';
import { scrollModalToTop } from '../../../../../utils/helpers/adminCMSPageHelper';
import { navPush } from '../../../../../utils/navHelper';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { ADMIN_EVENTS_MODULE_SUFFIX, ADMIN_EVENT_TYPES_SUFFIX } from '../../../CacheOne/actions';
import { SaveState } from '../../../../Rollback/actions';
import { getAdminEventsEventDashboardUrl, getDeleteAdminEventBody, getEventsHomeRootUrl, getUpdateAdminEventBody } from '../../../../../constants/adminEventsUrls';
import { ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX, UPDATE_EVENT_FORM_SUFFIX } from '../../../CacheTwoEvent/actions';
import { SectionCreator } from '../../../../../utils/dataHelper';
import { constructDateTime, formatDate } from '../../../../../utils/dateHelper';
import { ParticipantType } from '../../../../../models/api/adminEventsCacheOne';
import { ModalTypes, isModalOpened } from '../../../../../utils/modalHelper';
import { PlainRoute } from 'react-router';
import { typeName, Action } from '../../../../../utils/StrongActions';

export const getAdminEventCapacityNameKey = (id: number) => {
  return `Capacity${id ? id : 0}MaxParticipant`;
};

export const getPricingAmountKey = (pricingTierID: number, participantTypeID: number, key: string) => {
  return `PricingTier${pricingTierID}_ParticipantType${participantTypeID}_${key}`;
};

export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + '__EVENT_FORM' + UPDATE_EVENT_FORM_SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const UpdateEventSubmitActions = createApiSubmitActions(SUFFIX);

export const DeleteAdminEventSubmitActions = createApiSubmitActions(SUFFIX + '__DEL');

const getSubmitFormExtra = (rootState: ApplicationState, routes, eventId?: number) => {
  const route = routes[routes.length - 1];
  
  return getCacheLevelExtra(
    true,
    !!rootState.adminEvents.cacheTwoEvent.EventsEvent && !!eventId && rootState.adminEvents.cacheTwoEvent.EventsEvent.IDi === eventId,
  );
};

@typeName('COPY_PRICING_TIER' + SUFFIX)
export class CopyPricingTier extends Action {
  constructor(public id: number) { super(); }
}
// will submit data to cache two event
@typeName("CLIENT_SUBMIT_FORM" + SUFFIX) export class ClientSubmitForm extends Action { constructor(public data: any) { super(); }}

export const EventFormModalNamespace = (): string => 'pages--events--modal--event-form';

const GeneralSectionKeys = {
  Name: true,
  IsAllDay: true,
  StartDate: true,
  EndDate: true,
  RegistrationStartDateNumbers: true,
  RegistrationStartDateNames: true,
  GLAccountID: true,
  AllowReductionsUntilDate: true,
  YouthDepositDueDate: true,
  YouthDepositAmount: true,
  CampsiteDepositDefaultAmount: true,
  BasePricingStartDate: true,
  LatePricingStartDate: true,
};

const formCreator = (rootState: ApplicationState, body: any, action: 'add' | 'edit') => {
  const eventTypeCache = rootState.adminEvents.cacheOne.EventsEventType;
  const eventForm = rootState.adminEvents.events.event.form;
  const eventCache = rootState.adminEvents.cacheTwoEvent.EventsEvent;

  // General Section
  body.General = [];

  if (eventCache) {
    body.General.push(SectionCreator(eventCache, GeneralSectionKeys, true));
  }
  const newGeneralSection = SectionCreator(eventForm.ActiveForm, GeneralSectionKeys, false, eventForm.ValidationRules);

  if (newGeneralSection.RegistrationStartDateNumbers) {
    const date = formatDate(newGeneralSection.RegistrationStartDateNumbers);
    newGeneralSection.RegistrationStartDateNumbers = constructDateTime(date, eventForm.ActiveForm.NumbersRegistrationStartHour, eventForm.ActiveForm.NumbersRegistrationStartMin, eventForm.ActiveForm.NumbersRegistrationStartPeriod);
  }

  if (newGeneralSection.RegistrationStartDateNames) {
    const date = formatDate(newGeneralSection.RegistrationStartDateNames);
    newGeneralSection.RegistrationStartDateNames = constructDateTime(date, eventForm.ActiveForm.NamesRegistrationStartHour, eventForm.ActiveForm.NamesRegistrationStartMin, eventForm.ActiveForm.NamesRegistrationStartPeriod);
  }
  const startDate = formatDate(newGeneralSection.StartDate);
  const endDate = formatDate(newGeneralSection.EndDate);
  newGeneralSection.StartDate = constructDateTime(startDate, eventForm.ActiveForm.StartHour, eventForm.ActiveForm.StartMin, eventForm.ActiveForm.StartPeriod);
  newGeneralSection.EndDate = constructDateTime(endDate, eventForm.ActiveForm.EndHour, eventForm.ActiveForm.EndMin, eventForm.ActiveForm.EndPeriod);

  if (action === 'edit') {
    if (newGeneralSection.AllowReductionsUntilDate) newGeneralSection.AllowReductionsUntilDate = formatDate(newGeneralSection.AllowReductionsUntilDate);
    if (newGeneralSection.YouthDepositDueDate) newGeneralSection.YouthDepositDueDate = formatDate(newGeneralSection.YouthDepositDueDate);
    if (newGeneralSection.BasePricingStartDate) newGeneralSection.BasePricingStartDate = formatDate(newGeneralSection.BasePricingStartDate);
    if (newGeneralSection.LatePricingStartDate) newGeneralSection.LatePricingStartDate = formatDate(newGeneralSection.LatePricingStartDate);
  }

  body.General.push(newGeneralSection);

  if (action === 'edit') {
    const ParticipantTypes = rootState.adminEvents.cacheOne.EventsEventType ? rootState.adminEvents.cacheOne.EventsEventType.Pricing4.ParticipantTypes as ParticipantType[] : [];
    // Capacities
    body.Capacities = [];

    if (eventCache && eventCache.Capacities && eventCache.Capacities.length > 0) {
      eventCache.Capacities.forEach((c) => {
        // filter out missing participant type
        if (c.ParticipantTypeID) {
          const participantType = ParticipantTypes.find((pt) => pt.ID === c.ParticipantTypeID);
          if (!participantType || participantType.Inactive) return;
        }

        body.Capacities.push({
          IsPrevious: true,
          ParticipantTypeID: c.ParticipantTypeID,
          MaxParticipants: c.MaxParticipants,
        });

        body.Capacities.push({
          IsPrevious: false,
          ParticipantTypeID: c.ParticipantTypeID,
          MaxParticipants: eventForm.ActiveForm[getAdminEventCapacityNameKey(c.ParticipantTypeID || 0)],
        });
      });
    }

    // Pricing
    body.Pricing = [];

    if (eventCache && eventCache.Pricing && eventCache.Pricing.length > 0) {
      eventCache.Pricing.forEach((p) => {
        // filter out missing participant type
        const participantType = ParticipantTypes.find((pt) => pt.ID === p.ParticipantTypeID);
        if (!participantType || participantType.Inactive) return;
        body.Pricing.push({
          IsPrevious: true,
          ...p,
        });

        if (eventTypeCache) {
          const pricingTier = eventTypeCache.Pricing4.PricingTiers.find((pt => pt.ID === p.PricingTierID));
          // if PricingTier is disabled, use data from Cache
          if (!pricingTier || !pricingTier.IsEnabled) {
            body.Pricing.push({
              IsPrevious: false,
              ...p,
            });
            return;
          }
        }
        // Otherwise, grab data from Form
        body.Pricing.push({
          IsPrevious: false,
          PricingTierID: p.PricingTierID,
          ParticipantTypeID: p.ParticipantTypeID,
          AmountEarly: parseFloat(eventForm.ActiveForm[getPricingAmountKey(p.PricingTierID, p.ParticipantTypeID, 'AmountEarly')]),
          AmountBase: parseFloat(eventForm.ActiveForm[getPricingAmountKey(p.PricingTierID, p.ParticipantTypeID, 'AmountBase')]),
          AmountLate: parseFloat(eventForm.ActiveForm[getPricingAmountKey(p.PricingTierID, p.ParticipantTypeID, 'AmountLate')]),
        });
      });
    }
  }

  return body;
};

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminSettings.glAccounts.glAccount.form),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  apiSubmitForm: (routes, action: 'add' | 'edit', router: any): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const validGeneral = innerApiSubmitFormMethod(
      dispatch,
      UpdateEventSubmitActions,
      (s: ApplicationState) => s.adminEvents.events.event.form,
      undefined,
      undefined,
      isModalOpened(ModalTypes.EVENT_FORM),
      true,
      undefined,
      undefined,
    );

    if (validGeneral) {
      const submitFormBody = formCreator(getState(), getUpdateAdminEventBody(), action);

      dispatch(new SaveState());

      if (isModalOpened(ModalTypes.EVENT_FORM)) {
        // Save in modal
        dispatch(appActionCreators.popModal(false, false, ModalTypes.EVENT_FORM) as any);

        if (state.adminEvents.cacheOne.EventsEventType) {
          dispatch(new ClientSubmitForm(state.adminEvents.events.event.form.ActiveForm));
          navPush(router, getAdminEventsEventDashboardUrl({
            eventTypeId: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID,
            eventId: -1,
            eventName: state.adminEvents.events.event.form.ActiveForm.Name,
          }));
        }
      }
      dispatch(UpdateEventSubmitActions.request(
        submitFormBody,
        getCacheLevelExtra(
          false,
          true,
        ),
      ));
    } else {
      if (isModalOpened(ModalTypes.EVENT_FORM)) {
        scrollModalToTop(EventFormModalNamespace());
      }
      return false;
    }
  },
  initEventForm: (): ActionCreator => dispatch => {
    dispatch(UpdateEventSubmitActions.updateForm());
    dispatch(new SaveState());
  },
  copyPricingTier: (id: number): ActionCreator => dispatch => dispatch(new CopyPricingTier(id)),
  deleteEvent: (EventIDi: number, Inactive: boolean, routes: PlainRoute<any>[], router: any): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const state = getState() as ApplicationState;
    const body = getDeleteAdminEventBody(EventIDi, Inactive);
    const route = routes[routes.length - 1];
    // Save in Settings form
    if (
      state.adminEvents.cacheOne.EventsEventType && state.adminEvents.cacheTwoEvent.EventsEvent &&
      EventIDi === state.adminEvents.cacheTwoEvent.EventsEvent.IDi
    ) {
      navPush(router, getEventsHomeRootUrl({
        eventTypeId: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID,
        eventTypeName: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.Name,
      }));
    }
    dispatch(DeleteAdminEventSubmitActions.request(
      body,
      getSubmitFormExtra(getState(), routes, EventIDi),
      route,
    ));
  },
};