import {
  CancelCheckout,
  ChangeCreditCardType,
  CheckoutInit,
  CheckoutReset,
  CheckoutSubmitActions,
  ContinueCheckout,
  SetFullClasses,
  SUFFIX,
  ToggleAgreeMessage,
  ToggleCartItems
} from './actions';
import {apiValidationReducerCreator} from '../Validation/reducerCreator';
import {allowCCForm, alloweCheckForm, FormDefinition, IValidator} from "./validation";
import {ApiSubmitActions, ApiSubmitDeleteActions} from "../Settings/Profile/actions";
import {GetOptions} from "../CacheZero/actions";
import {
  APIFailure,
  APISuccess, LOAD_FINISH, SAVE_FINISH, SUBMIT_FAILURE_ACTION, UpdateCurrValue,
  UpdateValue,
  validateUpdateAction,
  validateUpdateCurrAction,
  WithRootState
} from "../Validation/actionCreator";
import moment from "moment";
import {CheckoutMessage} from "../../models/api/cacheOne";
import {GetGroupCache} from "../CacheOne/actions";
import {ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction} from "../App/actions";
import {scrollToErrorCheckout} from "./uiHelpers";
import {RemoveItemFromCartActions} from "../Cart/actions";
import { createSelector } from 'reselect';
import { PaymentType, PaymentTypes } from '../../models/api/options';
import { captureTentarooError } from '../../utils/dataHelper';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../utils/StrongActions';
import {setDefaults} from '../../utils/validator';
import type { ApplicationState } from '..';

export interface ActiveForm {
  FirstName?: string;
  LastName?: string;
  NotApplied?: number;
  Address?: string;
  City?: string;
  StateID?: number;
  Zip?: string;
  PaymentType?: number;
  CCNum?: string; // includes spaces
  CCCode?: number;
  CCExpireMonth?: number;
  CCExpireYear?: number;
  AccountType?: number;
  BankName?: string;
  NameOnAccount?: string;
  RoutingNumber?: number;
  ConfirmRoutingNumber?: number;
  AccountNumber?: number;
  ReceiptNumber?: string;
  OrderDate?: any;
  SendReceipt: boolean
  Notes?: string;
  UseCredit?: boolean;
}

export type CreditCardType = 'visa' | 'mastercard' | 'amex' | 'discover';

export interface CheckoutState {
  ActiveForm: ActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
  creditCardType?: CreditCardType;
  cartItemsExpanded: boolean;
  CheckoutMessages?: Array<CheckoutMessage>;
  currCheckoutIndex?: number;
  agreed?: boolean;
  fullClassesErrorMessage?: string;
  fullClassesUrl?: string;
}

/**
 * Helper method to determine if payment type is allowed or not
 * 
 * @param paymentType - payment type we are checking
 */
export const isPaymentTypeAllowed = (rootState: ApplicationState, paymentType: PaymentType) => {
  if (!rootState.cacheOne.CartOrder || !rootState.session.SystemSettings) return false;
  const {AllowECheckPayment, AllowCCPayment } = rootState.session.SystemSettings;
  const {Amount, RefundAmount} = rootState.cacheOne.CartOrder;
  const isAdmin = rootState.user.user.str_permissions.hasAdminAccess;

  // If ECheckPayment is not allowed, and the given payment type is 3 (i.e. ECheck), return false
  // Same rule applies to CreditCard and Refund (i.e. 2 || 12)
  if (!AllowECheckPayment && paymentType.ID === PaymentTypes.ECHECK) return false;
  if (!AllowCCPayment && (paymentType.ID === PaymentTypes.CREDIT_CARD || paymentType.ID === PaymentTypes.REFUND_CC_REFUND)) return false;

  // if only admin can view the payment type, but current user doesnt have admin access, return false
  if (paymentType.IsAdminOnly && !isAdmin) {
    return false;
  }

  if (Amount < 0) {
    return (paymentType.Refund &&
      (RefundAmount === 0 || paymentType.ID !== PaymentTypes.REFUND_ADD_CREDIT_TO_ACCOUNT) &&
      (RefundAmount > 0 || isAdmin || paymentType.ID !== PaymentTypes.REFUND_CC_REFUND));
  } else {
    return !paymentType.Refund;
  }
};

const allowUserCheckoutSelector = (state: ApplicationState) => {
  if (!state.checkout.ValidationRules || !state.cacheOne.CartOrder || !state.checkout.ActiveForm) return false;
  const paymentTypes = state.checkout.ValidationRules.PaymentType.options.values(state);
  const {Amount} = state.cacheOne.CartOrder;
  const {ActiveForm} = state.checkout;
  const NotApplied = ActiveForm.NotApplied ? parseFloat(ActiveForm.NotApplied as any) : 0;

  // if Amount and NotApplied is zero, we allow entering payment method and checkout
  if  (Amount === 0 && NotApplied === 0) return true;

  // if we had payment method left after filtering, we need to look at more detail
  // When there are payment method left after filtering, there are two cases:
  // 1. The filtered payment methods are all allowed
  // 2. One of the payment methods is not allowed, but because it is already selected, and hence it was not filtered
  // Hence we need to double check and make sure that if the second case happened, we are acting as expected
  if (paymentTypes.length === 1) {
    const paymentType = paymentTypes[0];
    return isPaymentTypeAllowed(state, paymentType);
  } else if (paymentTypes.length > 1) {
    return true;
  }

  // If all payment methods were filtered out, we hide the payment method
  return false;
};
export const makeAllowUserCheckoutSelector = () => createSelector([allowUserCheckoutSelector], (allowUserCheckout) => allowUserCheckout);

// TODO: Later should make the scroll consistent with `scrollToErrorCheckout`
const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const getInitialState = () => ({
  ActiveForm: {
    SendReceipt: true,
  },
  ValidationRules: {...FormDefinition},
  cartItemsExpanded: false
});

const Checkout: Reducer<CheckoutState> = (oldState = getInitialState(), act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, act);


  if (act.type === validateUpdateCurrAction + SUFFIX) {
    const action = <WithRootState<UpdateCurrValue>> act;
    if (action.changeKey === 'PaymentType') {
      const newState = {...state};
      if (!allowCCForm(action.value, newState.ActiveForm.Amount, newState.ActiveForm.NotApplied) && !alloweCheckForm(action.value, newState.ActiveForm.Amount, newState.ActiveForm.NotApplied)) {
        newState.ValidationRules = {
          ...state.ValidationRules,
          Address: {
            ...state.ValidationRules.Address,
            errors: false
          },
          City: {
            ...state.ValidationRules.City,
            errors: false
          },
          StateID: {
            ...state.ValidationRules.StateID,
            errors: false
          },
          Zip: {
            ...state.ValidationRules.Zip,
            errors: false
          }
        };
      }
      return newState;
    }
  }
  if ((act.type === `${SUBMIT_FAILURE_ACTION}${SAVE_FINISH}${SUFFIX}` || act.type === `${SUBMIT_FAILURE_ACTION}${LOAD_FINISH}${SUFFIX}`) &&
      (act as any).response && !(act as any).response.parseError) {
    scrollToErrorCheckout();
  }

  if (act.type === validateUpdateAction + SUFFIX) {
    const action = <WithRootState<UpdateValue>> act;
    const newState = {...state};
    if (action.errorsAndValues.Values.PaymentType) {
      const paymentType = state.ValidationRules.PaymentType.options.values(act.rootState).find((pt) => pt.ID === action.errorsAndValues.Values.PaymentType);
      if (paymentType && paymentType.Refund) {
        newState.ActiveForm = {
          ...state.ActiveForm,
          NotApplied: 0
        };
      }
    }
    return newState;
  } else if (isActionType(act, ChangeCreditCardType)) {
    return {...state, creditCardType: act.creditCardType};
  } else if (act.type === GetGroupCache.successType ||
    act.type === GetOptions.successType ||
    act.type === ApiSubmitDeleteActions.successType ||
    act.type === RemoveItemFromCartActions.successType ||
    ((act.type === ApiSubmitDeleteActions.failureType ||
    act.type === ApiSubmitActions.failureType) && !(act as any).response.parseError) ||
    isActionType(act, SetCacheAction)
  ) {
    const action = <WithRootState<APIFailure>> act;
    let CartOrder, GroupBillingAddress;
    const rootState = action.rootState;
    
    CartOrder = action.response.xhr.response.CartOrder;
    GroupBillingAddress = action.response.xhr.response.GroupBillingAddress;
    if (!CartOrder || !GroupBillingAddress) return state;

    // TODO: When refactor form init, should call setDefaults here to
    // set default values based on the latest cache
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        NotApplied: CartOrder.NotApplied ? CartOrder.NotApplied : 0,
        OrderDate: moment(),
        FirstName: GroupBillingAddress.FirstName,
        LastName: GroupBillingAddress.LastName,
        Address: GroupBillingAddress.Address,
        City: GroupBillingAddress.City,
        StateID: GroupBillingAddress.StateID ? GroupBillingAddress.StateID : (rootState.session.SystemSettings ? rootState.session.SystemSettings.StateID : undefined),
        Zip: GroupBillingAddress.Zip,
        PaymentType: CartOrder.PaymentTypeID,
        UseCredit: CartOrder.UseCredit,
      },
    };
  } else if (act.type === CheckoutSubmitActions.successType) {
    const action = <WithRootState<APISuccess>> act;
    if (action.response.response.CheckoutMessages) {
      return {
        ...state,
        CheckoutMessages: action.response.response.CheckoutMessages,
        currCheckoutIndex: 0,
        agreed: false,
      };
    }
    return state;
  } else if (isActionType(act, SetFullClasses)) {
    return {
      ...state,
      fullClassesErrorMessage: act.fullClassesErrorMessage,
      fullClassesUrl: act.url
    };
  } else if (isActionType(act, CheckoutInit)) {
    /**
     * Checkout should NOT be _init_ when there is no CartOrder in cacheOne
     */
    if (!act.rootState.cacheOne.CartOrder) {
      captureTentarooError(new Error('/checkout initialized, but there was no CartOrder in cacheOne'));
    }
    
    return setDefaults(act.rootState, getInitialState());
  } else if (isActionType(act, ContinueCheckout)) {
    document.getElementsByClassName('elements--modal--window--content')[0].scrollTop = 0;
    const nextIndex = state.currCheckoutIndex + 1;
    if (nextIndex >= state.CheckoutMessages.length) {
      captureTentarooError(new Error('Continue attempted at the end of messages!'));
      return state;
    }
    return {
      ...state,
      currCheckoutIndex: nextIndex,
      agreed: false,
    };
  } else if (isActionType(act, ToggleAgreeMessage)) {
    return {
      ...state,
      agreed: !state.agreed
    };
  } else if (isActionType(act, CancelCheckout)) {
    return {
      ...state,
      CheckoutMessages: undefined,
      currCheckoutIndex: undefined,
      agreed: undefined
    };
  } else if (isActionType(act, ToggleCartItems)) {
    return {...state, cartItemsExpanded: !state.cartItemsExpanded};
  } else if (
    isActionType(act, ClearAllCache) ||
    isActionType(act, ClearAllEndUserCacheButOptions) ||
    isActionType(act, CheckoutReset)
  ) {
    /**
     * NOTE: Checkout could be _reset_ when there is no CartOrder in cacheOne:
     * When an unauthenticated user visits Checkout/Profile page, Checkout/Profile page
     * will be rendered for a really short moment: after `GetLoginForm` completes and before
     * redirection. Then, redirection will trigger `componentWillUnmount` and resets form
     */
    return getInitialState();
  }
  return state;
};

export default Checkout;
