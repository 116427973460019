
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetPageMenuItem} from "./actions";
import { getPageMenuItem, getPageMenuItemBody } from "../../../constants/urls";
import { AjaxResponse, Observable } from "rxjs/Rx";

export const adminCMSSiteCacheTwoPageMenuItemsEpic = makeRequestEpic(
  GetPageMenuItem,
  getPageMenuItem,
  getPageMenuItemBody,
  true,
);