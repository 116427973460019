import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { makeFilteredRegistrationsSelector, makeRegistrationSelectedEventSelector } from '../../../../../../store/Admin/Modals/Accounts';
import { ApplicationState } from '../../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../../../../../store/Admin/Modals/Accounts/actions";
import {actionCreators as appActionCreators} from '../../../../../../store/App/actions';
import {AccountsEventRegistration} from '../../../../../../models/api/options';
import { actionCreators as rollbackActionCreators, SaveState } from '../../../../../../store/Rollback/actions';
import { SimpleList, DetailedListElement, EmptyMessage } from '../../../../../Elements';
import { URLS, EVENT } from '../../../../../../constants/urls';
import { navPush, spaceTo_ } from '../../../../../../utils';
import { EventsTypesIcon, EventsIcon } from '../../../../../../components/Icons';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { EmptyMessageType } from '../../../../../Elements/EmptyMessage';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../store/service';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';

interface Props {
  scrollToTop: () => void;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;


@(withRouter as any)
class RegistrationsContent extends React.PureComponent<Props, {}> {
  public props: ConnectedProps & Props;

  componentDidMount() {
    if (shouldBlockActions()) return;

    this.props.actions.init();
  }

  onSearchBack = () => {
    // @todo-minor-performance: just use 1 action
    if (this.props.adminAccounts.filterString) {
      this.props.scrollToTop();
    }
    this.props.actions.showFilterTabs(true);
    this.props.actions.filterAccounts('');
  };

  onClickRegistrationElement = (index: number, key: string | number, registration: AccountsEventRegistration) => {
    const {actions, router, adminAccounts, cacheTwoEvents, cacheThreeEvents, cacheZero, location} = this.props;

    const event = adminAccounts.AccountsEventTypeEvents.find((e) => e.IDi === registration.EventIDi);
    if (event) {
      const trimmedEventName = event.EventName.substr(0, event.EventName.lastIndexOf(","));
      const pushUrl = `${URLS.EVENTS}/${adminAccounts.ActiveForm.RegistrationsEventTypeID}/${event.IDi}/` +
        `${spaceTo_(trimmedEventName)}/${EVENT.REGISTRATION}`;

      reduxStoreService().dispatch(new SaveState());
      this.onSearchBack();
      navPush(
        router,
        pushUrl,
        registration.GroupIDi,
      );

      if (
        (cacheZero.options && cacheZero.options.Group && cacheZero.options.Group.IDi === registration.GroupIDi) &&
        cacheTwoEvents.eventTypeID === adminAccounts.ActiveForm.RegistrationsEventTypeID &&
        cacheThreeEvents.eventID === registration.EventIDi
      ) {
        // We are relying on clearAllEndUserCacheButOptions here to
        // do refresh because that is what the page is using right now. It works in this case because there
        // is no "deeper" form under `cacheFourEventsViewParticipant` that will do a SaveState on routerWillLeave
        // Later, if we added routerWillLeave to wizards, then we need to change this to do refresh, and support
        // loader for refresh in UI.
        actions.clearAllEndUserCacheButOptions(true);
      }
    }
  };

  registrationItemRender = (index) => {
    const { filteredRegistrations, adminAccounts: {ActiveForm, AccountsEventTypeEvents}, registrationSelectedEvent } = this.props;
    const registration: AccountsEventRegistration = filteredRegistrations[index];
    const registrationEvent = AccountsEventTypeEvents.find((e) => e.IDi === registration.EventIDi);
    return (<DetailedListElement
      key={`${registration.GroupIDi}_${registration.GroupEventIDi}`}
      index={index}
      label={`${registrationSelectedEvent || !registrationEvent ? `${registration.GroupName}` : `${registrationEvent.EventName} - ${registration.GroupName}`}`}
      mobileLabels={registrationSelectedEvent || !registrationEvent ? [`${registration.GroupName}`] : [
        `${registrationEvent.EventName}`,
        `${registration.GroupName}`,
      ]}
      descriptions={[
        `${registration.NumYouth} Youth`,
        `${registration.NumAdults} Adults`,
        `Amount: $${registration.Amount.toFixed(2)}`,
        `Balance: $${registration.Balance.toFixed(2)}`,
      ]}
      onClick={this.onClickRegistrationElement}
      deleted={false}
      itemValue={registration}
    />);
  };

  render() {
    const {filteredRegistrations, adminAccounts: {ActiveForm}} = this.props;

    if (!ActiveForm.RegistrationsEventTypeID || ActiveForm.RegistrationsEventTypeID === -1 || !ActiveForm.RegistrationsEventTypeID_Final || ActiveForm.RegistrationsEventTypeID_Final === -1) {
      return (
        <EmptyMessage
          icon={EventsTypesIcon}
          iconHeight='96px'
          iconWidth='96px'
          description='Select Event Type first'
          type={EmptyMessageType.PAGE_MARGIN}
          admin
        />
      );
    }

    if (!filteredRegistrations || filteredRegistrations.length === 0) {
      return (
        <EmptyMessage
          icon={EventsIcon}
          iconHeight='96px'
          iconWidth='96px'
          description={ENTITY_NOT_FOUND('registrations')}
          type={EmptyMessageType.PAGE_MARGIN}
          admin
        />
      );
    }

    return <SimpleList itemRenderer={this.registrationItemRender} totalLength={filteredRegistrations ? filteredRegistrations.length : 0}/>;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredRegistrationsSelector = makeFilteredRegistrationsSelector();
  const registrationSelectedEventSelector = makeRegistrationSelectedEventSelector();

  return {
    adminAccounts: state.adminAccounts,
    filteredRegistrations: filteredRegistrationsSelector(state),
    registrationSelectedEvent: registrationSelectedEventSelector(state),
    cacheZero: state.cacheZero,
    cacheTwoEvents: state.cacheTwoEvents,
    cacheThreeEvents: state.cacheThreeEvents,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...rollbackActionCreators
  }, dispatch)
});

const ConnectedRegistrationsContent = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(RegistrationsContent);

export default ConnectedRegistrationsContent;