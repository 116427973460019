
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../../store/AdminEvents/EventTypes/Modals/NewEventType/actions';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as cacheOneActionCreators } from '../../../../../../store/AdminEvents/CacheOne/actions';
import { ApplicationState } from '../../../../../../store';
import '../../../../../../styles/pages/admin-events/event-types/modal/new-event-type/index.scss';
import EventTypeForm from '../../EventType/Form';
import { makeSelectedLocationSelector, makeSelectedRegistrationMethodSelector, makeSelectedEventCategorySelector } from '../../../../../../store/AdminEvents/EventTypes/Modals/NewEventType';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;
type Props = WithInertAttribute<{}>;

@(withRouter as any)
class NewEventTypeModalStepTwo extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;

  public render() {
    const { selectedEventCategory, selectedLocation, selectedRegistrationMethod, newEventTypeForm, inert, apiSaving, actions} = this.props;

    if (!selectedRegistrationMethod) return null;
    return (
      <EventTypeForm
        inert={inert}
        action='add'
        reduxActions={actions as any}
        formState={newEventTypeForm as any}
        SubmitErrorMessage={newEventTypeForm.General.SubmitErrorMessage}
        hideFormAction
        selectedEventCategoryInNew={selectedEventCategory}
        selectedLocationInNew={selectedLocation}
        selectedRegistrationMethodInNew={selectedRegistrationMethod}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedLocationSelector = makeSelectedLocationSelector();
  const selectedRegistrationMethodSelector = makeSelectedRegistrationMethodSelector();
  const selectedEventCategorySelector = makeSelectedEventCategorySelector();

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    newEventTypeForm: state.adminEvents.eventTypes.modals.newEventType,
    selectedLocation: selectedLocationSelector(state),
    selectedRegistrationMethod: selectedRegistrationMethodSelector(state),
    selectedEventCategory: selectedEventCategorySelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...appActionCreators,
  ...actionCreators,
  ...cacheOneActionCreators,
}, dispatch) });
const ConnectedNewEventTypeModalStepTwo = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(NewEventTypeModalStepTwo);

export default ConnectedNewEventTypeModalStepTwo;