import * as React from 'react';
import {Column, Row, Select, TextField} from '../../../Elements';
import {Actions as AppActions} from "../../../../store/App/actions";
import {Actions as CacheZeroActions} from "../../../../store/CacheZero/actions";
import {CacheOneState} from "../../../../store/CacheOne";
import {Actions as CacheOneActions} from "../../../../store/CacheOne/actions";
import {Actions} from "../../../../store/Checkout/actions";
import {CheckoutState} from "../../../../store/Checkout";

export interface CreditCardProps {
  cacheOne: CacheOneState;
  checkout: CheckoutState;
  actions: Actions & AppActions & CacheOneActions & CacheZeroActions;
  disabled: boolean;
}

const CreditCardComponent: React.SFC<any> = (props: CreditCardProps): React.ReactElement<CreditCardProps> => (
  <div>
    <Row>
      <Column span={6} mobileSpan={12}>
        <Select label="Account Type"
                disabled={props.disabled}
                onChangeValue={props.actions.updateValue}
                value={props.checkout.ActiveForm.AccountType}
                validationRules={props.checkout.ValidationRules.AccountType}
                isNumber
        />
      </Column>
      <Column span={6} mobileSpan={12}>
        <TextField value={props.checkout.ActiveForm.BankName}
                   label="Bank Name"
                   disabled={props.disabled}
                   onChange={props.actions.simpleUpdate}
                   onBlur={props.actions.updateValue}
                   validationRules={props.checkout.ValidationRules.BankName}
        />
      </Column>
    </Row>
    <Row>
      <Column span={6} mobileSpan={12}>
        <TextField value={props.checkout.ActiveForm.RoutingNumber}
                   label="Routing Number"
                   disabled={props.disabled}
                   onChange={props.actions.simpleUpdate}
                   onBlur={props.actions.updateValue}
                   validationRules={props.checkout.ValidationRules.RoutingNumber}
        />
      </Column>
      <Column span={6} mobileSpan={12}>
        <TextField value={props.checkout.ActiveForm.ConfirmRoutingNumber}
                   label="Confirm Routing Number"
                   disabled={props.disabled}
                   onChange={props.actions.simpleUpdate}
                   onBlur={props.actions.updateValue}
                   validationRules={props.checkout.ValidationRules.ConfirmRoutingNumber}
        />
      </Column>
    </Row>
    <Row>
      <Column span={6} mobileSpan={12}>
        <TextField value={props.checkout.ActiveForm.NameOnAccount}
                   label="Name On Account"
                   disabled={props.disabled}
                   onChange={props.actions.simpleUpdate}
                   onBlur={props.actions.updateValue}
                   validationRules={props.checkout.ValidationRules.NameOnAccount}
        />
      </Column>
      <Column span={6} mobileSpan={12}>
        <TextField value={props.checkout.ActiveForm.AccountNumber}
                   label="Account Number"
                   disabled={props.disabled}
                   onChange={props.actions.simpleUpdate}
                   onBlur={props.actions.updateValue}
                   validationRules={props.checkout.ValidationRules.AccountNumber}
        />
      </Column>
    </Row>
  </div>
);

export default CreditCardComponent;
