import { DATE_PICKER_FORMAT } from '@tentaroo/shared';
import moment, { Moment } from 'moment';

// export const API_DATE_TIME_FORMAT = 'YYYY-MM-DDT00:00:00';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_DISPLAY_FORMAT = 'h:mm A';

export const TIME_FORMAT = 'HH:mm:ss';
export const FULL_DATE_FORMAT = 'ddd, MMM D, hh:mm A';
export const FULL_DATE_FORMAT2 = 'M/DD/YYYY h:mm A';
export const SIMPLE_FORMAT = 'MMM D, YYYY';

// @todo - use this for all hard-coded keys
export enum TimeAttributeKeys {
  ID = 'ID',
  START_HOUR = 'StartHour',
  START_MIN = 'StartMin',
  START_PERIOD = 'StartPeriod',
  END_HOUR = 'EndHour',
  END_MIN = 'EndMin',
  END_PERIOD = 'EndPeriod',
  INACTIVE = 'Inactive',
}

/*
Given an array of objects, convert each given key to a moment object and return new array
 */
export const convertToMoment = (array?: Array<any> | null, keys?: Array<string>) => {
  if (array === undefined) return undefined;
  if (array === null) return null;
  const newArray: Array<any> = [];
  if (!keys) return array;
  array.forEach((elem) => {
    const newElem = {...elem};
    keys.forEach((key) => {
      if (elem[key]) newElem[key] = moment(elem[key]);
      else newElem[key] = null;
    });
    newArray.push(newElem);
  });
  return newArray;
};

export const convertObjToMoment = (obj?: any | null, keys?: Array<string>) => {
  if (obj === undefined) return undefined;
  if (obj === null) return null;
  if (!keys) return obj;
  const newElem = {...obj};
  keys.forEach((key) => {
    if (obj[key]) newElem[key] = moment(obj[key]);
    else newElem[key] = null;
  });
  return newElem;
};

export const validateDate = (str: string): string | undefined => {
  const vals = str.split('/');
  if (vals.length !== 3) return undefined;
  if (vals[2].length !== 4) return undefined;
  const date = moment(str).format(DATE_PICKER_FORMAT);
  if (date === 'Invalid date') return undefined;
  return date;
};

export const isDaySame = (date1: moment.Moment, date2: moment.Moment) => {
  return date1.isSame(date2, 'day');
};

export const displayTimeFrame = (dateStart: moment.Moment, dateEnd: moment.Moment) => {
  if (isDaySame(dateStart, dateEnd)) {
    return dateStart.format(SIMPLE_FORMAT);
  }
  if (dateStart.isSame(dateEnd, 'year')) {
    return `${dateStart.format('MMM D')} - ${dateEnd.format(SIMPLE_FORMAT)}`;
  }
  return `${dateStart.format(SIMPLE_FORMAT)} - ${dateEnd.format(SIMPLE_FORMAT)}`;

};

// Jun 10, 2018 at 11:59 PM
export const displayDate = (d: moment.Moment) => {
  return d.format('MMM D, YYYY [at] h:mm A');
};

export const displaySimpleDate = (d: moment.Moment) => {
  return d.format(DATE_PICKER_FORMAT);
};

export const getPrevAndNext = (startDate: moment.Moment): {disablePrev: boolean; disableNext: boolean;} => {
  let disablePrev = false, disableNext = false;
  if (startDate.diff(moment().startOf('month'), 'months') >= 23) {
    disableNext = true;
  } else if (startDate.diff(moment().startOf('month'), 'months') === 0) {
    disablePrev = true;
  }
  return {
    disableNext,
    disablePrev
  };
};

export const convertTimeDigit = (time?: number, period?: number) => {
  if (time === undefined) return `00`;
  let d = time;
  if (period !== undefined) {
    d = period === 1 ? (time % 12) + 12 : (time % 12);
  }
  return d !== undefined ? (d > 9 ? `${d}` : `0${d}`) : `00`;
};

export const processHour = (hour: number) => {
  // the input range is [0, 23], output range is [1, 12] (differ by period - a.m./p.m.)
  if (hour === 0) return 12;        // 00:00 is means 12:00 a.m.
  if (hour <= 12) return hour;      // 01:00 - 11:00 means 01:00 a.m. - 11:00 a.m.; 12:00 means 12:00p.m.
  return hour % 12;  // 13:00 - 23:00 means 01:00 p.m. - 11:00 p.m.
};

export const getPeriodText = (period?: number) => {
  return period === 1 ? 'PM' : 'AM';
};

export const getHourMinPeriodFromString = (time: string) => {
  const tokens = time.split(':');
  const hour = Number(tokens[0]) >= 0 ? processHour(Number(tokens[0])) : 0;
  const min = Number(tokens[1]);
  const period = Number(tokens[0]) > 11 ? 1 : 0;

  return {
    hour,
    min,
    period,
  };
};

export const compareDate = (a: moment.Moment, b: moment.Moment) => {
  return a.isBefore(b) ? -1 : a.isSame(b) ? 0 : 1;
};

export const constructDateTime = (date, hour, min, period) => {
  return !date ? null : `${date}T${convertTimeDigit(hour, period)}:${convertTimeDigit(min)}:00`;
};

export const compareDataByRowNum = (a: {RowNum?: number}, b: {RowNum?: number}) => {
  return (!a.RowNum || !b.RowNum) ? 0 : (a.RowNum < b.RowNum ? -1 : (a.RowNum > b.RowNum ? 1 : 0));
};

export const formatDate = (date?: string | Moment, format = API_DATE_FORMAT) => {
  if (!date) return null;
  if (typeof date === "string") return moment(date).format(format);

  return date.format(format);
};

/**
 * Given a dateTime string or moment object, parse the time portion from
 * it, construct and return a moment object for that time portion
 */
export const getTimeMomentFromDateTime = (dateTime: string | moment.Moment): moment.Moment => {
  const dateTimeMoment = typeof dateTime === "string" ? moment(dateTime) : dateTime;

  const timeMoment = moment(dateTimeMoment.format(TIME_FORMAT), TIME_FORMAT);

  return timeMoment;
};

/**
 * This is a helper function to help decide what helper text we will be showing for the
 * built-in Grade field.
 * 
 * Spec and more detail: https://mailchi.mp/tentaroo.com/new-feature-expanded-options-for-pricing-calculations-4761524?e=dcce31822c
 * 
 * @param dateString - the date string of the event. For example: "2050-09-01"
 * @returns if the date is within the first term (i.e. between Sept 1st and May 31st)
 */
export const isCurrentGrade = (dateString: any) => {
  const dateMoment = moment(dateString);
  const month = dateMoment.month();

  // NOTE: moment index equals month - 1
  return !(month >= 5 && month <= 7);
};