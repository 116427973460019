import * as React from 'react';

import { Filter, FilterRow, FilterColumn, FilterSearch } from '../../../../../../components/Layouts';
import { Select, Switch, ActionButton } from '../../../../../Elements';
import { FilterIcon } from '../../../../../Icons';
import { Validator } from '../../../../../../utils/validator/models';

export const namespace = (): string => 'elements--contact-filters';

export interface ContactsFiltersProps {
  className?: string;
  disabled?: boolean;
  expand?: boolean;
  ActiveForm: any;
  ValidationRules: any;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
    toggleExpandFilter: (expand: boolean) => any,
  };
  preventActions?: boolean;
  newButton: React.ReactNode;
  forceMobile?: boolean;
}

interface State {
}

class ContactsFilters extends React.Component<ContactsFiltersProps, State> {

  state = {
    showContactSearch: false,
  };
  public static defaultProps = {

  };
  public props: ContactsFiltersProps;

  onExpandClick = () => {
    this.props.reduxActions.toggleExpandFilter(!this.props.expand);
  };

  public render() {
    const props = this.props;
    const {ActiveForm, ValidationRules, newButton, forceMobile, reduxActions, disabled, expand} = props;

    return (
      <Filter className={namespace()}>
        <FilterRow style={{height: '68px'}} mobileMarginBottom={expand ? 8 : 0} forceMobile={forceMobile}>
          <FilterSearch
            mobileMarginRight={8}
            disabled={disabled}
            ActiveForm={ActiveForm}
            searchKey='FilterText'
            ValidationRules={ValidationRules}
            reduxActions={reduxActions}
            forceMobile={forceMobile}
            width='198'
          />
          <FilterColumn
            order={-1}
            mobileMarginRight={16}
            marginRight={24}
            forceMobile={forceMobile}
            width='half'
          >
            <Select
              label="Contact Category"
              disabled={disabled}
              hideOptional
              onChangeValue={reduxActions.updateValue}
              value={ActiveForm.SelectedCategoryID}
              validationRules={ValidationRules.SelectedCategoryID}
              isNumber />
          </FilterColumn>
          <FilterColumn
            marginRight={24}
            mobileMarginRight={0}
            marginBottom={21}
            forceMobile={forceMobile}
            width='none'
            alignSelf='flex-end'
            keep
          >
            <ActionButton
              lightSelected
              square
              onClick={() => this.onExpandClick()}
              icon={FilterIcon}
              selected={expand} />
          </FilterColumn>
          <FilterColumn alignSelf='flex-end' marginBottom={21} forceMobile={forceMobile} hideInMobile width='none'>
            {newButton}
          </FilterColumn>
        </FilterRow>
        {expand ? <FilterRow isExpanded forceMobile={forceMobile}>
          <FilterColumn
            mobileMarginRight={16}
            forceMobile={forceMobile}
            marginRight={24}
            width='half'
          >
            <Switch
              label="Show Deleted"
              newDesign
              onChange={reduxActions.updateValue}
              value={!!ActiveForm.ShowDeleted}
              validationRules={ValidationRules.ShowDeleted}
            />
          </FilterColumn>
        </FilterRow> : null}
      </Filter>
    );
  }
}

export default ContactsFilters;