import * as React from 'react';
import '../../../../styles/elements/roster/list/index.scss';

import {namespace} from "./constants";

interface Props {
  children?: React.ReactNode;
}

const RosterList: React.SFC<Props> = ({ children }: Props): React.ReactElement<Props> => (
  <ul className={namespace()}>
    {React.Children.map(children, (child) => <li className={`${namespace()}--item`}>{child}</li>)}
  </ul>
);

export default RosterList;
export { default as RosterListEmpty } from './Empty';
