import * as React from 'react';
import {Main, MainContent} from '../../../Layouts';
import {Button, HeaderAmount, LoadingAll, PageLoader, SubTitle, EmptyMessage} from '../../../Elements';
import OrderCard from './OrderCard';
import RightSideBar from './PaymentTools/RightSidebar';
import {RouteComponentProps, withRouter} from 'react-router';
import Order from '../Order';
import ManageOrder from '../Order/Manage';
import {URLS} from '../../../../constants/urls';
import '../../../../styles/pages/settings/orders/index.scss';
import {navPush} from "../../../../utils/navHelper";
import {actionCreators} from "../../../../store/App/actions";
import {bindActionCreators} from 'redux';
import {
} from "../../../../utils/cacheLoaders/cacheTwoPrevOrdersLoader";
import {actionCreators as cacheOneActionCreators, ClearCacheBelowOne} from "../../../../store/CacheOne/actions";
import {actionCreators as cacheZeroActionCreators} from "../../../../store/CacheZero/actions";
import {
  actionCreators as cacheTwoPrevOrdersActionCreators,
  GetPrevOrders
} from "../../../../store/CacheTwoPrevOrders/actions";
import {
  actionCreators as cacheThreePrevOrdersActionCreators,
  GetPrevOrder
} from "../../../../store/CacheThreePrevOrders/actions";
import {GroupedOrder} from "../../../../store/CacheTwoPrevOrders";
import {actionCreators as manageOrderActionCreators} from "../../../../store/Settings/PrevOrders/ManageOrder/actions";
import {actionCreators as auditLogActionCreators} from "../../../../store/Settings/PrevOrders/AuditLog/actions";
import {actionCreators as refundOrFeeActionCreators} from "../../../../store/Settings/PrevOrders/RefundOrFee/actions";
import {PreviousOrder} from "../../../../models/api/cacheTwoPrevOrders";
import {standardCurrencyFormat} from "../../../../utils/classesHelper";
import {ApplicationState} from "../../../../store";
import {ReportAuditLogActions} from "../../../../store/Settings/PrevOrders/AuditLog/actions";
import {CartIcon} from "../../../Icons";
import { ModalTypes, isModalOpened } from '../../../../utils/modalHelper';
import EndUserCacheManager from '../../../../utils/cacheManagers/endUserCacheManager';
import { IPrevOrderRouterParams } from '../../../../utils/helpers/endUserPageHelper';
import { shouldReconfigRouter } from '../../../../utils/cacheLoaders/reloaderHelper';
import { isPathnameMatchingRoute } from '../../../../utils/urlHelper';
import { VIEW_ORDER_PATH } from '../../../../routes';
import { connect } from 'react-redux';
import { generateDOMId } from '../../../../utils/cypressHelper';
import { SaveState } from '../../../../store/Rollback/actions';
import { reduxStoreService } from '../../../../store/service';
import { ComponentUpdateTemplate } from '../../../Templates/ComponentUpdateTemplate';
import {isCacheTwoPrevOrdersPopulated, isEndUserCacheOnePopulated} from '../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../Elements/WithInert';

export const namespace = (): string => 'pages--settings--orders';

export type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IPrevOrderRouterParams, {}>
>;

// this broke with react 16
// import { CSSTransitionGroup } from 'react-transition-group'
{/*<CSSTransitionGroup*/}
  {/*transitionName="aniSlide"*/}
  {/*transitionEnterTimeout={500}*/}
  {/*transitionLeaveTimeout={300}*/}
{/*>*/}
  {/*{(thisRoute.path === '/order_history/order/:id' || thisRoute.path === '/order_history/order/:id/edit') && <Order key="order-modal" id={params.id}/>}*/}
{/*</CSSTransitionGroup>*/}
{/*<CSSTransitionGroup*/}
{/*transitionName="aniSlide"*/}
{/*transitionEnterTimeout={500}*/}
{/*transitionLeaveTimeout={300}*/}
  {/*>*/}
  {/*{thisRoute.path === '/order_history/order/:id/edit' && <ManageOrder onBack={() => browserHistory.push(URLS.VIEW_ORDER_ROOT + '/' + params.id)}/>}*/}
{/*</CSSTransitionGroup>*/}

@(withRouter as any)
class Orders extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
  private nextLocation;

  onClickOrder = (order: PreviousOrder) => {
    const {actions, router, showManageOrder, apiSaving} = this.props;
    if (apiSaving > 0) return;
    // actions.setPrevOrder(order);
    navPush(router,`${URLS.VIEW_ORDER_ROOT}/${order.OrderID}`);
    
    // If clicking on the same Order, we want to close its ManageOrder modal
    // If clicking on a different order, call `showManageOrder` after navigation
    // guarantees that it happens after the SaveState in Order.tsx -> routerWillLeave
    if (showManageOrder) actions.showManageOrder(false, false);
  };

  // NOTE: There will be a save state in <PaymentTools /> modal's componentDidMount
  onOpenPayment = () => this.props.actions.pushModal(ModalTypes.PAYMENT_TOOLS, false, false);

  onClickMain = (e) => {
    const { showManageOrder, actions, routes, router, apiSaving} = this.props;
    const thisRoute = routes[routes.length - 1];

    // only trigger click event when we are clicking on the same elements (i.e. mouse did not move a lot between
    // mouse up and mouse down)
    // should prevent any action when saving is in progress
    if (apiSaving <= 0)
    {
      if (showManageOrder) {
        actions.showManageOrder(false, true);
      } else if (thisRoute.path === VIEW_ORDER_PATH) {
        navPush(router,`${URLS.ORDER_HISTORY}`);
      }
    }
  };

  resetAll = () => {
    this.props.actions.resetManageOrder();
    this.props.actions.resetAuditLog();
    this.props.actions.resetRefundOrFee();
  };

  public componentDidMount() {
    const {routes} = this.props;
    const route = routes[routes.length - 1];
    
    // TODO: Refactor this into loader, but only need to do this on first mount
    this.resetAll();
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        if (route.path === URLS.ORDER_HISTORY) {
          EndUserCacheManager.getInstance().loadCacheTwoPrevOrders({
            props: this.props,
            isStateNavigated,
          });
        } else {
          EndUserCacheManager.getInstance().loadCacheThreePrevOrder({
            props: this.props,
            isStateNavigated,
          });
        }
      }
    );
    this.configRouteLeaveHook();
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    const {routes} = this.props;

    const route = routes[routes.length - 1];
    if (shouldReconfigRouter(prevProps, this.props) && route.path !== VIEW_ORDER_PATH) this.configRouteLeaveHook();

    const thisRoute = routes[routes.length - 1];
    const prevRoute = prevProps.routes[prevProps.routes.length - 1];
    const thisPath = thisRoute.path;
    const prevPath = prevRoute.path;
    if (thisPath !== prevPath && thisPath === URLS.ORDER_HISTORY && prevPath !== URLS.ORDER_HISTORY) {
      reduxStoreService().dispatch(new SaveState());
    }
  }

  componentWillUnmount() {
    const {cacheTwoPrevOrders} = this.props;

    // if we are not navigating to caches below it, clear cache
    if (
      this.nextLocation &&
      !isPathnameMatchingRoute(this.nextLocation.pathname, VIEW_ORDER_PATH) &&
      isCacheTwoPrevOrdersPopulated(cacheTwoPrevOrders)
    ) {
      reduxStoreService().dispatch(new ClearCacheBelowOne());
    }
    this.resetRouteLeaveHook();
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  public componentWillReceiveProps(nextProps) {
    const nextRoute = nextProps.routes[nextProps.routes.length - 1];
    const nextPath = nextRoute.path;
    
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        if (nextPath && nextPath.includes(URLS.VIEW_ORDER_ROOT)) {
          EndUserCacheManager.getInstance().loadCacheThreePrevOrder({
            props: nextProps,
            isStateNavigated,
          });
        } else {
          EndUserCacheManager.getInstance().loadCacheTwoPrevOrders({
            props: nextProps,
            isStateNavigated,
          });
        }
      }
    );
  }
  
  public render() {
    const { showManageOrder, inert, user, routes, apiLoadingMap, apiSaving, cacheTwoPrevOrders, cacheOne, cacheOne: {CartOrder}} = this.props;
    const thisRoute = routes[routes.length - 1];
    
    if (!isEndUserCacheOnePopulated(cacheOne) || !CartOrder) return <LoadingAll/>;

    const isAdmin = user.user && user.user.str_permissions.hasAdminAccess;
    const showLoaderInList = apiLoadingMap[ReportAuditLogActions.requestType] || apiSaving > 0 && !isModalOpened(ModalTypes.PAYMENT_TOOLS);
    const loading = apiLoadingMap[GetPrevOrders.requestType] || (apiLoadingMap[GetPrevOrder.requestType] && !isCacheTwoPrevOrdersPopulated(cacheTwoPrevOrders));
    return (
      <Main
        inert={inert}
        mobileBackground="white"
        rightSidebar={isAdmin && !loading ? <RightSideBar /> : undefined}
        header={
          <HeaderAmount
            label="Available credit:"
            amount={standardCurrencyFormat(CartOrder.AvailableCredit)}
          >
            {isAdmin ? <Button id={generateDOMId("open-payment-tool-modal")} disabled={loading} admin color="white" onClick={this.onOpenPayment}>ORDER/PAYMENT TOOLS</Button> : undefined}
          </HeaderAmount>
        }
        onClick={this.onClickMain}
      >
        {!loading && this.renderList(loading, showLoaderInList, thisRoute)}
        {loading && <PageLoader/>}
        {!loading && thisRoute.path === VIEW_ORDER_PATH && showManageOrder && <ManageOrder />}
      </Main>
    );
  }

  public renderList = (loading, showLoaderInList, thisRoute) => {
    const {params, cacheTwoPrevOrders: {groupedOrders}, apiLoadingMap} = this.props;
    return (
      <MainContent loading={showLoaderInList} enableClickThroughLoader={apiLoadingMap[GetPrevOrder.requestType]}>
        {(!groupedOrders || groupedOrders.length === 0) && <EmptyMessage
          icon={CartIcon}
          description="There are no previous orders"
        />}
        {groupedOrders && groupedOrders.map((gOrders: GroupedOrder) => {
          return [
            <SubTitle>{gOrders.year}</SubTitle>,
            gOrders.orders.map((order: PreviousOrder) => {
              return <OrderCard
                order={order}
                onClick={this.onClickOrder}
                key={order.OrderID}
              />;
            })
          ];
        })}
        {!loading && thisRoute.path === VIEW_ORDER_PATH &&
          <Order key="order-modal" id={params.id} {...this.props} showLoaderInList={showLoaderInList} />}
      </MainContent>
    );
  };
}

const mapStateToProps = (state: ApplicationState) => {

  return {
    getEmptyCartBody: state.app.getEmptyCartBody,
    getEmptyCartUrl: state.app.getEmptyCartUrl,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheTwoPrevOrders: state.cacheTwoPrevOrders,
    cacheThreePrevOrders: state.cacheThreePrevOrders,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    user: state.user,
    showManageOrder: state.settings.prevOrders.manageOrder.showManageOrder,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...cacheZeroActionCreators,
  ...cacheOneActionCreators,
  ...actionCreators,
  ...cacheTwoPrevOrdersActionCreators,
  ...cacheThreePrevOrdersActionCreators,
  ...auditLogActionCreators,
  ...refundOrFeeActionCreators,
  ...manageOrderActionCreators
}, dispatch)});

const ConnectedOrders = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Orders);

export default ConnectedOrders;
