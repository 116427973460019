import * as React from 'react';

import { Form, Row, Column, TextField, ModalSelect, Select, Alert, RadioGroupOption, Text, Switch } from '../../../../../Elements';
import ResourceCard, { ResourceCardType } from '../../../../../../components/Elements/Resource/Card';
import { IValidator } from '../../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/validation';
import { SiteMenuItemActiveForm } from '../../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form';
import { LinkTypeValue, CMSResource, CMSPage, SiteEventCategory } from '../../../../../../models/api/adminCMSCacheOne';
import CMSPageCard, { PageCardType } from '../../../../../../components/Elements/CMSPage/Card';
import { AdminCMSSiteCacheOneState } from '../../../../../../store/AdminCMSSite/CacheOne';
import '../../../../../../styles/pages/menus/site-menu-item/form.scss';
import { CardCategory } from '../../../../../../components/Elements/Card';
import { checkResourcePermission } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { CacheZeroState } from '../../../../../../store/CacheZero';
import { ModalProps, ModalTypes } from '../../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { Validator } from '../../../../../../utils/validator/models';

export const namespace = (): string => 'cms--site-menu-item-form';

export interface Props {
  action: 'add' | 'edit';
  disabled: boolean;
  disabledReason?: string;
  cacheZero: CacheZeroState;
  ActiveForm: SiteMenuItemActiveForm;
  SubmitErrorMessage?: string;
  ValidationRules: IValidator;
  loading: boolean;
  selectedResource?: CMSResource | null;
  selectedEventCategory?: SiteEventCategory | null;
  adminCMSCacheOne: AdminCMSSiteCacheOneState;
  selectedPage?: CMSPage;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
    pushSelectPageModal: () => any,
    pushResourcesModal: () => any,
    pushFormModal: (modal: ModalTypes, isEdit: boolean, props?: ModalProps) => any,
  };
}

class Contact extends React.Component<Props, {}> {
  public props: Props;

  // common fields
  renderName = () => {
    const {ActiveForm, disabled, reduxActions, ValidationRules} = this.props;
    return (
      <Row>
        <Column span={12} mobileSpan={12}>
            <TextField
              id={generateDOMId("menu-name-field")}
              label="Name"
              disabled={disabled}
              onChange={reduxActions.simpleUpdate}
              onBlur={reduxActions.updateValue}
              validationRules={ValidationRules.Name}
              value={ActiveForm.Name}/>
        </Column>
      </Row>
    );
  };
  renderTitle = () => {
    const {ActiveForm, disabled, reduxActions, ValidationRules} = this.props;
    return (
      <Row>
        <Column span={12} mobileSpan={12}>
            <TextField
              label="Title"
              disabled={disabled}
              onChange={reduxActions.simpleUpdate}
              onBlur={reduxActions.updateValue}
              validationRules={ValidationRules.Title}
              value={ActiveForm.Title}/>
        </Column>
      </Row>
    );
  };
  renderParent = () => {
    const {ActiveForm, disabled, reduxActions, adminCMSCacheOne} = this.props;
    const parent = adminCMSCacheOne.CMSPageMenuItems ? adminCMSCacheOne.CMSPageMenuItems.find((p) => p.ID === ActiveForm.ParentID) : null;
    return (
      <Row>
        <TextField
          className={`${namespace()}--static-textfield`}
          editable={false}
          label="Parent"
          value={parent ? parent.Name : ''}/>
      </Row>
    );
  };
  renderLinkType = () => {
    const {ActiveForm, disabled, reduxActions, ValidationRules} = this.props;
    return (
      <Row>
        <Select
          label="Link Type"
          disabled={disabled}
          onChangeValue={reduxActions.updateValue}
          value={ActiveForm.LinkTypeID}
          validationRules={ValidationRules.LinkTypeID}
          isNumber />
      </Row>
    );
  };
  renderIsLinkNewWidnow = () => {
    const {ActiveForm, disabled, reduxActions, ValidationRules} = this.props;
    return (
      <Row>
        <Column mobileMarginBottom={0} layout='horizontal' span={6} mobileSpan={12}>
          <RadioGroupOption
            value={!!!ActiveForm.IsLinkNewWindow}
            selected={!!!ActiveForm.IsLinkNewWindow}
            ValidationRules={ValidationRules.IsLinkNewWindow}
            rawValue={false}
            disabled={disabled}
            onChange={reduxActions.updateValue}
            newDesign
            label={<Text color='dark-gray' weight="regular" size={16} marginBottom={0}>Same Window</Text>}
          />
        </Column>
        <Column layout='horizontal' span={6} mobileSpan={12}>
          <RadioGroupOption
            value={!!ActiveForm.IsLinkNewWindow}
            selected={!!ActiveForm.IsLinkNewWindow}
            ValidationRules={ValidationRules.IsLinkNewWindow}
            rawValue={true}
            onChange={reduxActions.updateValue}
            disabled={disabled}
            newDesign
            label={<Text color='dark-gray' weight="regular"  size={16} marginBottom={0}>New Window</Text>}
          />
        </Column>
      </Row>
    );
  };
  renderIsPrimaryMenu = () => {
    const {ActiveForm, disabled, reduxActions, ValidationRules, adminCMSCacheOne} = this.props;
    const parent = adminCMSCacheOne.CMSPageMenuItems ? adminCMSCacheOne.CMSPageMenuItems.find((p) => p.ID === ActiveForm.ParentID) : null;
    return (
      <Row marginBottom={8}>
        <Column span={12} mobileSpan={12}>
          <Switch
              newDesign
              label={`Show '${parent ? parent.Name : ''}' Menu On This Page`}
              multilineLabel
              disabled={disabled}
              onChange={reduxActions.updateValue}
              value={!!ActiveForm.IsPrimaryMenu}
              validationRules={ValidationRules.IsPrimaryMenu}
            />
        </Column>
      </Row>
    );
  };

  onRemovePage = () => {
    this.props.reduxActions.updateValue(undefined, this.props.ValidationRules.PageID);
  };
  onClickSelectPageButton = () => {
    this.props.reduxActions.pushSelectPageModal();
  };
  renderPage = () => {
    const {ActiveForm, disabled, disabledReason, SubmitErrorMessage, selectedPage, adminCMSCacheOne, ValidationRules} = this.props;
    const pageType = adminCMSCacheOne.PageTypes && selectedPage ? adminCMSCacheOne.PageTypes.find((p) => p.ID === selectedPage.PageTypeID) : null;
    
    return (
      <Form
        onSubmit={e => e.preventDefault()}>
        {disabledReason ? <Alert className={`${namespace()}--alert`}>{disabledReason}</Alert> : null}
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        {this.renderName()}
        {ActiveForm.MenuLevel === 2 ? this.renderParent() : null}
        {this.renderLinkType()}
        <Row>
          <Column span={12} mobileSpan={12}>
            <ModalSelect
              label="Page"
              selectButtonLabel="SELECT PAGE"
              disabled={disabled}
              hideError={!!selectedPage}
              selectedCard={selectedPage ?
                <CMSPageCard
                  type={PageCardType.IN_MENU}
                  showDeletedPrompt
                  page={selectedPage}
                  disabled={disabled}
                  pageTypeName={pageType ? pageType.Name : ''}
                  onRemove={this.onRemovePage}
                  cardCategory={CardCategory.LIST_MOBILE}
                /> : null}
              validationRules={ValidationRules.PageID}
              onClickSelectButton={this.onClickSelectPageButton}
              value={ActiveForm.PageID}/>
          </Column>
        </Row>
        {this.renderIsPrimaryMenu()}
        <div className={`${namespace()}--field-label`}>Open In</div>
        {this.renderIsLinkNewWidnow()}
      </Form>
    );
  };

  onRemoveResource = () => {
    this.props.reduxActions.updateValue(undefined, this.props.ValidationRules.ResourceID);
  };
  onClickSelectResourceButton = () => {
    this.props.reduxActions.pushResourcesModal();
  };
  onEditResource = () => {
    const {selectedResource, cacheZero, adminCMSCacheOne} = this.props;
    if (!selectedResource) return;
    checkResourcePermission(
      () => {
        this.props.reduxActions.pushFormModal(ModalTypes.RESOURCE_FORM, true, {id: selectedResource.ID});
      },
      cacheZero,
      adminCMSCacheOne,
      selectedResource.SiteID,
      true,
    );
  };
  renderResource = () => {
    const {ActiveForm, disabled, SubmitErrorMessage, disabledReason, selectedResource, ValidationRules} = this.props;
    
    return (
      <Form
        onSubmit={e => e.preventDefault()}>
        {disabledReason ? <Alert className={`${namespace()}--alert`}>{disabledReason}</Alert> : null}
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        {this.renderName()}
        {ActiveForm.MenuLevel === 2 ? this.renderParent() : null}
        {this.renderLinkType()}
        <Row>
          <Column span={12} mobileSpan={12}>
            <ModalSelect
              label="Resource"
              selectButtonLabel="SELECT RESOURCE"
              disabled={disabled}
              paddingBottom={24}
              mobilePaddingBottom={24}
              hideError={!!selectedResource}
              selectedCard={selectedResource ?
                <ResourceCard
                  type={ResourceCardType.MODAL_SELECT}
                  showDeletedPrompt
                  disabled={disabled}
                  marginTop={12}
                  onEdit={this.onEditResource}
                  onRemove={this.onRemoveResource}
                  resource={selectedResource} /> : null}
              validationRules={ValidationRules.ResourceID}
              onClickSelectButton={this.onClickSelectResourceButton}
              value={ActiveForm.ResourceID}
            />
          </Column>
        </Row>
        <div className={`${namespace()}--field-label`}>Open In</div>
        {this.renderIsLinkNewWidnow()}
      </Form>
    );
  };

  renderNoLink = () => {
    const {ActiveForm, disabledReason, SubmitErrorMessage} = this.props;
    
    return (
      <Form
        onSubmit={e => e.preventDefault()}>
        {disabledReason ? <Alert className={`${namespace()}--alert`}>{disabledReason}</Alert> : null}
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        {this.renderName()}
        {ActiveForm.MenuLevel === 2 ? this.renderParent() : null}
        {this.renderLinkType()}
      </Form>
    );
  };

  renderUrl = () => {
    const {ActiveForm, disabled, SubmitErrorMessage, disabledReason, reduxActions, ValidationRules} = this.props;
    
    return (
      <Form
        onSubmit={e => e.preventDefault()}>
        {disabledReason ? <Alert className={`${namespace()}--alert`}>{disabledReason}</Alert> : null}
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        {this.renderName()}
        {ActiveForm.MenuLevel === 2 ? this.renderParent() : null}
        {this.renderLinkType()}
        <Row>
          <Column span={12} mobileSpan={12}>
              <TextField
                label="URL"
                disabled={disabled}
                onChange={reduxActions.simpleUpdate}
                onBlur={reduxActions.updateValue}
                validationRules={ValidationRules.ExternalURL}
                value={ActiveForm.ExternalURL}/>
          </Column>
        </Row>
        <div className={`${namespace()}--field-label`}>Open In</div>
        {this.renderIsLinkNewWidnow()}
      </Form>
    );
  };
  renderCalendar = () => {
    const {ActiveForm, disabled, disabledReason, reduxActions, ValidationRules, selectedEventCategory, SubmitErrorMessage} = this.props;
    return (
      <Form
        onSubmit={e => e.preventDefault()}>
        {disabledReason ? <Alert className={`${namespace()}--alert`}>{disabledReason}</Alert> : null}
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        {this.renderName()}
        {ActiveForm.MenuLevel === 2 ? this.renderParent() : null}
        {this.renderLinkType()}
        <Row>
          <Select
            label="Event Category"
            disabled={disabled}
            onChangeValue={reduxActions.updateValue}
            value={ActiveForm.EventCategoryID}
            validationRules={ValidationRules.EventCategoryID}
            isNumber />
        </Row>
        {selectedEventCategory && selectedEventCategory.IsDistricts && <Row>
          <Select
            label="District"
            disabled={disabled}
            onChangeValue={reduxActions.updateValue}
            value={ActiveForm.DistrictIDi}
            validationRules={ValidationRules.DistrictIDi}
            isNumber />
        </Row>}
        <div className={`${namespace()}--field-label`}>Open In</div>
        {this.renderIsLinkNewWidnow()}
      </Form>
    );
  };

  public render() {
    const {action, disabled, reduxActions, ValidationRules, ActiveForm, loading} = this.props;
    return (
      <div className={`${namespace()}`}>
        {ActiveForm.LinkTypeID === LinkTypeValue.PAGE && this.renderPage()}
        {ActiveForm.LinkTypeID === LinkTypeValue.CALENDAR && this.renderCalendar()}
        {ActiveForm.LinkTypeID === LinkTypeValue.RESOURCE && this.renderResource()}
        {ActiveForm.LinkTypeID === LinkTypeValue.NO_LINK && this.renderNoLink()}
        {ActiveForm.LinkTypeID === LinkTypeValue.URL && this.renderUrl()}
      </div>
    );
  }
}

export default Contact;
