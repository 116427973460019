import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../../store';
import { makeFormModalPropSelector } from '../../../store/App';
import { ModalTypes } from '../../../utils/modalHelper';
import { getMergeProps } from '../../../utils/reduxHelper';
import {
  Modal, ModalTitle, ModalContent, ModalActions, Button, Paragraph
} from '../index';
import {
  actionCreators as appActionCreators,
} from "../../../store/App/actions";
import {
  actionCreators as classActionCreators,
} from "../../../store/Events/Event/Register/Participant/Classes/actions";
import { captureTentarooError } from '../../../utils/dataHelper';
import {WithInertAttribute} from '../WithInert';

type Props = WithInertAttribute<{}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
class JoinWaitingListModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  onAcceptWaitlist = (e: MouseEvent) => {
    e.preventDefault();
    const {actions, classes: {waitlistClass}} = this.props;
    if (waitlistClass) actions.addClass(waitlistClass);
    else {
      captureTentarooError(new Error('waitlist modal opened without waitlist'));
    }
    this.props.actions.popModal(false, true, ModalTypes.JOIN_WAITLIST);
  };

  onCancel = (e: MouseEvent) => {
    e.preventDefault();
    this.props.actions.popModal(false, true, ModalTypes.JOIN_WAITLIST);
  };

  getWaitlistText = () => {
    const {classes: {waitlistClass}} = this.props;

    if (!waitlistClass) return "";
    const numBefore = waitlistClass.RegistrationRank - waitlistClass.Maximum - 1;
    if (numBefore === 0) {
      return `If you continue, you'll be placed at the`
        + ` first available spot on the waiting list. You'll be notified by email if enough spots open up so that`
        + ` you're no longer on the waiting list.`;
    } else if (numBefore === 1) {
      return `There is 1 other person already on the waiting list. If you continue, you'll be placed at the`
        + ` next available spot on the waiting list. You'll be notified by email if enough spots open up so that`
        + ` you're no longer on the waiting list.`;
    } else {
      return `There are ${numBefore} other people already on the waiting list. If you continue, you'll be placed at the`
        + ` next available spot on the waiting list. You'll be notified by email if enough spots open up so that`
        + ` you're no longer on the waiting list.`;
    }
  };

  public render() {
    return (
      <Modal inert={this.props.inert} disableDynamicHeight big>
        <ModalTitle>Join Waitlist?</ModalTitle>
        <ModalContent paddingBottom={0} paddingTop={0}>
          <Paragraph color="gray">
            {this.getWaitlistText()}
          </Paragraph>
        </ModalContent>
        <ModalActions noPadding stacked>
          <Button onClick={this.onCancel} flat>CANCEL</Button>
          <Button flat onClick={this.onAcceptWaitlist}>JOIN WAITLIST</Button>
        </ModalActions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    classes: state.events.event.register.participant.classes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...classActionCreators,
  }, dispatch)
});

const ConnectedJoinWaitingListModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(JoinWaitingListModal);
export default ConnectedJoinWaitingListModal;
