import * as React from 'react';
import AddedIcon from '../../../images/elements/added/added.svg';
import '../../../styles/elements/added/index.scss';
import { appActionCreators } from "../../../store/index";
import { onViewportLeave } from '../../../utils';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

export const namespace = (): string => 'elements--added';

interface Props {
  addedLength: number;
  onClick?: () => any;
  isVisible?: boolean;
}

type ConnectedProps = ReturnType<typeof mapDispatchToProps>;

@onViewportLeave(() => ({ isVisible: true }), () => ({ isVisible: false }))
class Added extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.isVisible !== this.props.isVisible && this.props.actions) {
      if (this.props.isVisible) this.props.actions.hideAddedHeaderAction();
      else this.props.actions.showAddedHeaderAction();
    }
  }

  public render() {
    const { onClick, addedLength } = this.props;
    return (
      <div className={namespace()} onClick={onClick}>
        <div className={`${namespace()}--button`}>
          <AddedIcon className={`${namespace()}--button--icon`}/>
          <span className={`${namespace()}--button--text`}>ADDED</span>
        </div>
        <div className={`${namespace()}--number`}>{addedLength}</div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => (
  { actions: bindActionCreators(appActionCreators, dispatch) }
);
const ConnectedAdded = connect(
  null,
  mapDispatchToProps,
  getMergeProps<Props>(),
);

export default ConnectedAdded(Added);
