
import { ParticipantTab } from ".";
import {BaseEndUserClass, RegisteredClass} from "../../../../../../models/class";
import { reduxStoreService } from '../../../../../service';

export const showClasses = (
  availableClasses: Array<BaseEndUserClass>,
  conflictingClasses: Array<BaseEndUserClass>,
  registeredClasses: Array<RegisteredClass>,
) => {
  if (reduxStoreService().getState().events.event.register.participant.main.selectedPage === 'classes') return true;
  return (availableClasses && availableClasses.length > 0) || (conflictingClasses && conflictingClasses.length > 0) || (registeredClasses && registeredClasses.length > 0);
};

export const typeNext = (
  availableClasses: Array<BaseEndUserClass>,
  conflictingClasses: Array<BaseEndUserClass>,
  registeredClasses: Array<RegisteredClass>,
): ParticipantTab => {
  if (showClasses(availableClasses, conflictingClasses, registeredClasses)) return 'classes';
  return 'confirmation';
};

export const confirmationBack = (
  availableClasses: Array<BaseEndUserClass>,
  conflictingClasses: Array<BaseEndUserClass>,
  registeredClasses: Array<RegisteredClass>,
): ParticipantTab => {
  if (showClasses(availableClasses, conflictingClasses, registeredClasses)) return 'classes';
  return 'type';
};