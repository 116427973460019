import { EventType } from "../../models/api/cacheOne";
import { makeSingleSelector } from "../../utils/reselectHelper";
import { eventTypeSelector } from "../CacheOne";

export const eventTypeIDSelector = (state) => state.cacheTwoEvents.eventTypeID;

export const makeSelectedEventTypeSelector = makeSingleSelector((et: EventType, id: number) => et.ID === id, eventTypeSelector, eventTypeIDSelector);
export const showYouthSelector = (state) => {
  if (state.cacheTwoEvents.EventTypeRegistrationSettings) {
    return state.cacheTwoEvents.EventTypeRegistrationSettings.RegistrationSettings.ShowYouth;
  }
  return false;
};
export const showAdultSelector = (state) => {
  if (state.cacheTwoEvents.EventTypeRegistrationSettings) {
    return state.cacheTwoEvents.EventTypeRegistrationSettings.RegistrationSettings.ShowAdults;
  }
  return false;
};

export const eventsSelector = state => {
  if (state.cacheTwoEvents.EventTypeEvents) return state.cacheTwoEvents.EventTypeEvents;
  return [];
};