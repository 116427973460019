import type { ApplicationState } from "../../..";
import { CMSSocial, SocialType } from "../../../../models/api/adminCMSCacheOne";

const sortSocials = (rootState: ApplicationState, socials: CMSSocial[], socialTypes?: SocialType[]) => {
  const finalSocialTypes = socialTypes || rootState.adminCMSSite.cacheOne.SocialTypes as SocialType[];
  // cant sort if no ssn in state tree
  if (!finalSocialTypes) return socials;
  return socials.sort((a, b) => {
    const ssnA = finalSocialTypes.find((s) => s.ID === a.SocialTypeID);
    const ssnB = finalSocialTypes.find((s) => s.ID === b.SocialTypeID);
    if (ssnA && ssnB) {
      if (ssnA.Ord !== ssnB.Ord) {
        return ssnA.Ord - ssnB.Ord;
      } else {
        // @todo - sort by value?
      }
    }
    // dont sort
    return 0;
  });
};

export const initSocialTypesActiveForm = (rootState: ApplicationState, socials: CMSSocial[], socialTypes?: SocialType[]) => {
  let activeForm = {};
  if (socials && socials.length > 0) {
    const sorted = sortSocials(rootState, socials, socialTypes);
    
    if (sorted && sorted.length > 0) {
      sorted.forEach((ss, index) => {
        activeForm[`SocialID${index}`] = ss.ID;
        activeForm[`SocialTypeID${index}`] = ss.SocialTypeID;
        activeForm[`SocialUser${index}`] = ss.SocialUser;
      });
    }
  } else {
    activeForm = {
      SocialTypeID0: 1,
      SocialUser0: '',
    };
  }

  return activeForm;
};