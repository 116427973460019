import { AdminFacilityDetail } from "../../../models/api/adminFacilitiesCacheTwoFacility";
import { ADMIN_FACILITY_MODULE_SUFFIX, ClearAdminFacilityLocationCacheBelowOne, ClearAllAdminFacilityLocationCache } from "../CacheOne/actions";
import { APISuccess } from "../../../store/Validation/actionCreator";
import { ClearAdminFacilityLocationCacheTwoFacility, GetFacility } from './actions';
import { ClearAllCache, SetCacheAction } from "../../../store/App/actions";
import { AdminFacilityTypeDetail } from "../../../models/api/adminFacilitiesCacheTwoFacilityType";
import { ClientSubmitForm, AddFacilitySubmitActions } from "../Facilities/Modals/NewFacility/actions";
import { ImportBookingTimesSubmitActions, ImportPricingSubmitActions, ImportImagesSubmitActions, EditFacilityAddImageSubmitActions, EditFacilityDeleteImageSubmitActions } from "../Facilities/Facility/Form/actions";
import { shouldSkipResponseForCache } from "../../Validation/reducerCreator";
import { Action, isActionType } from "../../../utils/StrongActions";
import { Reducer } from "redux";
import { GalleryImage } from "../../../models/api/cacheOne";

export interface AdminFacilityLocationCacheTwoFacilityState {
  FacilitiesFacility?: AdminFacilityDetail;
  FacilitiesType?: AdminFacilityTypeDetail;
}

const setAdminFacilityLocationCacheTwoFacilityData = (data): AdminFacilityLocationCacheTwoFacilityState => {
    return {
      FacilitiesFacility: {
        ...data.FacilitiesFacility,
      },
      FacilitiesType: {
        ...data.FacilitiesType,
      },
    };
};

const handleResponse = (innerResponse,) => {
  if (innerResponse.FacilitiesFacility) {
    const newState: AdminFacilityLocationCacheTwoFacilityState = setAdminFacilityLocationCacheTwoFacilityData(innerResponse);
    return newState;
  }
};

export const updateImages = (images: GalleryImage[] | undefined, image: GalleryImage, tempId?: number, handleNewImage?: boolean, preserveOrd?: boolean) => {
  if (!images) return [];
  let found = false;
  const result = images.map((item) => {
    if (tempId ? (item.tempId === tempId) : (item.ID === image.ID)) {
      found = true;
      return {
        ...image,
        loading: false,
        uploading: false,
        Ord: preserveOrd ? item.Ord : image.Ord,
      };
    }
    return item;
  });

  if (handleNewImage && !found) {
    if (image.dataUrl) delete image.dataUrl;
    result.push(image);
  }

  return result;
};

const AdminCMSSiteCacheTwoFacility: Reducer<AdminFacilityLocationCacheTwoFacilityState> = (state: AdminFacilityLocationCacheTwoFacilityState, act: Action) => {
  if (act.type === GetFacility.successType || (isActionType(act, SetCacheAction) && act.actions.requestType.includes(ADMIN_FACILITY_MODULE_SUFFIX)) || act.type === AddFacilitySubmitActions.successType) {
    const action = <APISuccess> act;

    if (shouldSkipResponseForCache(act)) return state;

    const newState = handleResponse(action.response.response) || {...state};
    return newState;
  } else if (act.type === ImportBookingTimesSubmitActions.successType || act.type === ImportPricingSubmitActions.successType || act.type === ImportImagesSubmitActions.successType) {
    if (shouldSkipResponseForCache(act)) return state;

    const action = <APISuccess> act;
    const newState: AdminFacilityLocationCacheTwoFacilityState = { ...state };
    if (!newState.FacilitiesFacility || !action.response.response.FacilitiesFacility) return newState;

    const Facility = action.response.response.FacilitiesFacility;
    if (act.type === ImportBookingTimesSubmitActions.successType) {
      newState.FacilitiesFacility.TimeBlocks = Facility.TimeBlocks;
      newState.FacilitiesFacility.ShowCheckInOutTimes = Facility.ShowCheckInOutTimes;
      newState.FacilitiesFacility.IsAllDay = Facility.IsAllDay;
    }
    if (act.type === ImportPricingSubmitActions.successType) {
      newState.FacilitiesFacility.Pricing = Facility.Pricing;
      newState.FacilitiesFacility.GLAccountID = Facility.GLAccountID;
      newState.FacilitiesFacility.MinPerPersonFeesToBill = Facility.MinPerPersonFeesToBill;
    }

    if (act.type === ImportImagesSubmitActions.successType) {
      newState.FacilitiesFacility.Images = Facility.Images;
    }

    return newState;
  } else if (act.type === EditFacilityAddImageSubmitActions.successType || act.type === EditFacilityDeleteImageSubmitActions.successType || act.type === EditFacilityDeleteImageSubmitActions.failureType || (isActionType(act, SetCacheAction) && (act.actions.requestType === EditFacilityDeleteImageSubmitActions.requestType))) {
    if (shouldSkipResponseForCache(act)) return state;
    
    const action = <APISuccess> act;
    const res = action.response.response;
    const image = res.FacilityImage as GalleryImage;

    if (image && state.FacilitiesFacility) {
      return {
        ...state,
        FacilitiesFacility: {
          ...state.FacilitiesFacility,
          Images: updateImages(state.FacilitiesFacility.Images, image, undefined, true, act.type === EditFacilityDeleteImageSubmitActions.successType),
        }
      };
    }
  } else if (isActionType(act, ClientSubmitForm)) {
    return {
      ...state,
      FacilitiesFacility: {...act.data},
    };
  } else if (isActionType(act, ClearAdminFacilityLocationCacheBelowOne) || isActionType(act, ClearAdminFacilityLocationCacheTwoFacility) || isActionType(act, ClearAllAdminFacilityLocationCache) || isActionType(act, ClearAllCache)) {
    return {};
  }
  return state || {};
};
  
export default AdminCMSSiteCacheTwoFacility;