import * as React from "react";
import "../../../../../../../../styles/pages/admin-events/class-types/class-type/index.scss";
import { Main, MainContent } from "../../../../../../../Layouts";
import {
  Form,
  Button,
  Row,
  Column,
  TextField,
  FieldSet,
  Alert,
  FileUploader,
  Vignette,
  ModalSelect,
  MeritBadgeCard,
} from "../../../../../../../Elements";
import { AdminEventsCacheOneState } from "../../../../../../../../store/AdminEvents/CacheOne";
import { AdminEventClassTypeFormState } from "../../../../../../../../store/AdminEvents/ClassTypes/Form";
import { Actions } from "../../../../../../../../store/AdminEvents/ClassTypes/Form/actions";
import { Actions as AppActions } from "../../../../../../../../store/App/actions";
import { createInitials, getImagePath } from "../../../../../../../../utils";
import {
  getValidFeatureImageTypeString,
  stripFileExtension,
  validateFileType,
  MB,
  VALID_IMAGE_TYPES,
  checkFileSize,
} from "../../../../../../../../utils/fileHelper";
import { isArray, isObject } from "util";
import * as M from "../../../../../../../../constants/messages/generic";
import { CacheZeroState } from "../../../../../../../../store/CacheZero";
import { isMobileAndSmallerScreenSize } from "../../../../../../../../utils/isMobile";
import { generateDOMId } from "../../../../../../../../utils/cypressHelper";
import {
  AdminEventsCacheThreeClassType_MeritBadge,
  AdminEventsCacheThreeClassType_Old,
  isClassType_NewTracking,
  isClassType_OldTracking,
} from "../../../../../../../../models/api/adminEventsCacheThreeClassType";
import { ModalTypes } from "../../../../../../../../utils/modalHelper";
import { MeritBadge } from "../../../../../../../../models/api/options";
import { MeritBadgeCardType } from "../../../../../../../Elements/MeritBadge/Card";
import Tag from "../../../../../../../Elements/Tag";
import {MBRequirement} from "../../../../../../../../models/api/adminEventsCacheTwoEvent";
import {CloseCircleIcon} from "../../../../../../../Icons";
import { AdminEventsCacheThreeClassTypeState } from "../../../../../../../../store/AdminEvents/CacheThreeClassType";
import { WithInertAttribute } from "../../../../../../../Elements/WithInert";

export const namespace = (): string => "pages--admin-events--class-type";

export type Props = WithInertAttribute<{
  action: "add" | "edit";
  disabled: boolean;
  loading?: boolean;
  adminEventsCacheOne: AdminEventsCacheOneState;
  adminEventsCacheThreeClassTypes: AdminEventsCacheThreeClassTypeState;
  handleCompact?: boolean;
  hideFormAction?: boolean;
  classTypeForm: AdminEventClassTypeFormState;
  cacheZero: CacheZeroState;
  onSave?: () => void;
  onDelete?: () => void;
  reduxActions: Actions & AppActions;
  selectedMeritBadge?: MeritBadge;
}>;

class AdminEventClassTypeForm extends React.Component<Props, {}> {
  public props: Props;

  onCancelImage = () => {
    const {
      classTypeForm: { ValidationRules },
    } = this.props;
    this.props.reduxActions.updateValue(
      undefined,
      ValidationRules.FeaturedImage
    );
  };
  onFileChange = (files: any) => {
    const file =
      isArray(files) && files.length > 0
        ? files[0]
        : isObject(files)
        ? files[0]
        : null;

    const isOversized = checkFileSize(
      file.name,
      file.size,
      10,
      (oversizeMessage) => {

        this.props.reduxActions.showTopFloatingAlert(
          oversizeMessage,
          isMobileAndSmallerScreenSize(),
          "orange"
        );
      }
    );
    if (isOversized) {
      return;
    }

    const result = stripFileExtension(file.name);
    if (result) {
      const validType = validateFileType(result.extension, VALID_IMAGE_TYPES);
      if (!validType) {
        this.props.reduxActions.showTopFloatingAlert(
          M.UNSUPPORTED_FILE_TYPES(file.name, (file.size / MB).toFixed(2)),
          isMobileAndSmallerScreenSize(),
          "orange"
        );
        return;
      }
    }

    const reader = new FileReader();

    reader.onabort = () =>
      console.log("file reading was aborted for top gallery");
    reader.onerror = () =>
      console.log("file reading has failed for top gallery");
    reader.onload = () => {
      // Do whatever you want with the file contents
      const dataUrl = reader.result;

      this.props.reduxActions.updateValue(
        {
          Filename: file.name,
          inputObj: file,
          dataUrl,
        },
        this.props.classTypeForm.ValidationRules.FeaturedImage
      );
    };
    reader.readAsDataURL(file);
  };

  isFormDisabled = () => {
    const { cacheZero, disabled, action } = this.props;

    if (disabled) return true;
    if (cacheZero.options && cacheZero.options.GeneralPermissions) {
      const permissions = cacheZero.options.GeneralPermissions;

      if (action === "add") {
        return !permissions.hasClassTypeAdd;
      } else {
        return !permissions.hasClassTypeEdit;
      }
    }
  };

  onClickSelectMeritBadgeButton = () => {
    this.props.reduxActions.pushModal(
      ModalTypes.SELECT_MERIT_BADGE,
      false,
      true
    );
  };

  onRemoveMeritBadge = () => {
    const { classTypeForm, reduxActions } = this.props;

    reduxActions.updateValue(null, classTypeForm.ValidationRules.mbID);
    reduxActions.updateValue([], classTypeForm.ValidationRules.RequirementsArray);
    reduxActions.updateValue("", classTypeForm.ValidationRules.Requirements);
  };

  renderMeritBadgeConfig = () => {
    const {
      classTypeForm: { ActiveForm },
      selectedMeritBadge,
    } = this.props;

    return (
      <ModalSelect
        value={ActiveForm.mbID}
        className={`${namespace()}--select-merit-badge`}
        label="Merit Badge"
        selectButtonLabel="SELECT"
        disabled={this.isFormDisabled()}
        selectedCard={
          !selectedMeritBadge ? null : (
            <MeritBadgeCard
              type={MeritBadgeCardType.MODAL_SELECT}
              meritBadge={selectedMeritBadge}
              disabled={this.isFormDisabled()}
              marginTop={12}
              onRemove={this.onRemoveMeritBadge}
              showDeletedPrompt
            />
          )
        }
        onClickSelectButton={this.onClickSelectMeritBadgeButton}
      />
    );
  };

  onManageRequirements = () => {
    const {reduxActions, adminEventsCacheThreeClassTypes, classTypeForm: {ActiveForm}} = this.props;

    reduxActions.pushModal(ModalTypes.MANAGE_REQUIREMENTS, false, true, {
      manageRequirementsContext: "class-type",
      allRequirements: adminEventsCacheThreeClassTypes.ClassType_AllMBRequirements,
      selectedRequirements: ActiveForm.RequirementsArray,
    });
  };

  onRemoveRequirement = (req: MBRequirement) => {
    this.props.reduxActions.removeRequirement(req.ID);
  };

  renderRequirementsTaught_NewTracking = (ActiveForm: AdminEventsCacheThreeClassType_MeritBadge) => {
    const {
      adminEventsCacheThreeClassTypes,
    } = this.props;
    return (
      <FieldSet
        fixedLegendFontSize
        newDesign
        marginTop={24}
        marginBottom={0}
        legendMarginBottom={ActiveForm.RequirementsArray?.length > 0 ? 16 : 0}
        fontSize={22}
        name="Requirements Taught"
        className={`${namespace()}--requirements-taught`}
        controls={
          <Button
            onClick={this.onManageRequirements}
            color="white"
            textColor="black"
            disabled={this.isFormDisabled()}
          >
            MANAGE
          </Button>
        }
      >
        <div className={`${namespace()}--req-chips`}>
          {!adminEventsCacheThreeClassTypes.ClassType_AllMBRequirements ? null : adminEventsCacheThreeClassTypes.ClassType_AllMBRequirements
          /* We dont need a sort here because the order is inherited from the order of `EventsEventClass_AllMBRequirements` */
          .filter((req) => {
            return Boolean(ActiveForm.RequirementsArray?.find((reqID) => reqID === req.ID));
          })
          .map((req) => {
            return (
              <Tag
                className={`${namespace()}--req-tag`}
                onRemove={() => this.onRemoveRequirement(req)}
                removeIcon={<CloseCircleIcon />}
                text={req.req}
                backgroundColor="orange"
              />
            );
          })}
        </div>
      </FieldSet>
    );
  };

  renderRequirementsTaught_OldTracking = (ActiveForm: AdminEventsCacheThreeClassType_Old) => {
    const {
      reduxActions,
      classTypeForm: { ValidationRules },
    } = this.props;
    return (
      <TextField
        label="Requirements Taught"
        disabled={this.isFormDisabled()}
        info="Enter a comma separated list of requirements that will be taught at the event. Any details in parentheses will be ignored."
        onChange={reduxActions.simpleUpdate}
        onBlur={reduxActions.updateValue}
        validationRules={ValidationRules.Requirements}
        value={ActiveForm.Requirements}
        rows={5}
      />
    );
  };

  public render() {
    const {
      cacheZero,
      action,
      reduxActions,
      classTypeForm: { ActiveForm, ValidationRules, SubmitErrorMessage },
      loading,
      handleCompact,
      inert,
    } = this.props;

    const permissions =
      cacheZero.options && cacheZero.options.GeneralPermissions
        ? cacheZero.options.GeneralPermissions
        : null;
    return (
      <Main inert={inert}>
        <MainContent
          loading={loading}
          className={`${namespace()}--main-content`}
          handleCompact={handleCompact}
        >
          <Form onSubmit={(e) => e.preventDefault()}>
            {action === "add" &&
            permissions &&
            permissions.hasClassTypeAddReason ? (
              <Alert className={`${namespace()}--alert`}>
                {permissions.hasClassTypeAddReason}
              </Alert>
            ) : null}
            {action === "edit" &&
            permissions &&
            permissions.hasClassTypeEditReason ? (
              <Alert className={`${namespace()}--alert`}>
                {permissions.hasClassTypeEditReason}
              </Alert>
            ) : null}
            {SubmitErrorMessage ? (
              <Alert className={`${namespace()}--alert`}>
                {SubmitErrorMessage}
              </Alert>
            ) : null}
            <Row>
              <Column span={12}>
                <TextField
                  label="Name"
                  id={generateDOMId("tentaroo-admin-classtype-name-field")}
                  disabled={this.isFormDisabled()}
                  onChange={reduxActions.simpleUpdate}
                  onBlur={reduxActions.updateValue}
                  validationRules={ValidationRules.Name}
                  value={ActiveForm.Name}
                />
              </Column>
            </Row>
            <Row marginTop={24}>
              <Column span={12}>{this.renderMeritBadgeConfig()}</Column>
            </Row>
            <Row marginTop={24}>
              <Column span={12}>
                <FieldSet
                  fixedLegendFontSize
                  marginBottom={0}
                  newDesign
                  legendMarginBottom={0}
                  fontSize={20}
                  name={isClassType_NewTracking(ActiveForm) ? "Custom Image" : "Image"}
                />
              </Column>
            </Row>
            <Row marginTop={8}>
              <Column span={12}>
                <FileUploader
                  className="no-padding"
                  isImage
                  disabled={this.isFormDisabled()}
                  component={
                    <Vignette
                      width={144}
                      height={144}
                      disabled={this.isFormDisabled()}
                      onCancel={this.onCancelImage}
                      className={`${namespace()}--avatar`}
                      name={createInitials(`${ActiveForm.Name}`)}
                      color={`#${ActiveForm.Color}`}
                      image={
                        ActiveForm.FeaturedImage
                          ? ActiveForm.FeaturedImage.dataUrl
                            ? ActiveForm.FeaturedImage.dataUrl
                            : getImagePath(ActiveForm.FeaturedImage)
                          : undefined
                      }
                      borderRadius="72px"
                    />
                  }
                  maxFileSize={0}
                  supportedFormatsPrompt="Image up to 10MB. Supported formats: JPG, PNG, GIF, TIFF"
                  supportedFormat={getValidFeatureImageTypeString()}
                  value={ActiveForm.FeaturedImage}
                  onChange={this.onFileChange}
                  validationRules={ValidationRules.FeaturedImage}
                />
              </Column>
            </Row>
            <Row marginTop={16}>
              <Column span={12}>
                <TextField
                  label="Description"
                  disabled={this.isFormDisabled()}
                  onChange={reduxActions.simpleUpdate}
                  onBlur={reduxActions.updateValue}
                  validationRules={ValidationRules.Description}
                  value={ActiveForm.Description}
                  rows={5}
                />
              </Column>
            </Row>
            <Row marginTop={8}>
              {isClassType_NewTracking(ActiveForm) ? (
                <Column span={12}>{this.renderRequirementsTaught_NewTracking(ActiveForm)}</Column>
              ) : null}
              {isClassType_OldTracking(ActiveForm) ? (
                <Column span={12}>{this.renderRequirementsTaught_OldTracking(ActiveForm)}</Column>
              ) : null}
            </Row>
          </Form>
        </MainContent>
      </Main>
    );
  }
}

export default AdminEventClassTypeForm;
