import * as React from 'react';
import Media from "../../Media";
import Header from "../Header";
import { namespace as ns } from '../constants';
import '../../../../styles/elements/header/filters/index.scss';

export const namespace = (): string => `${ns()}--filters`;

export interface FiltersProps {
  children?: React.ReactNode;
}

const Filters: React.SFC<FiltersProps> = ({ children }: FiltersProps): React.ReactElement<FiltersProps> => (
  <div className={namespace()}>
    <Media tablet desktop className={`${namespace()}--desktop`}>
      <div className={`${namespace()}--desktop--wrapper`}>
        {children}
      </div>
    </Media>
    <Header mobile className={`${namespace()}--mobile`}>
      {children}
    </Header>
  </div>
);

export default Filters;
