import {makeRequestEpic} from "../../Validation/epicCreator";
import {EditEmailActions} from "./actions";
import {getUpdateEmailUrl} from "../../../constants/urls";

export const editEmailEpic = makeRequestEpic(
  EditEmailActions,
  getUpdateEmailUrl,
  undefined,
  true,
  () => 'Email has been changed successfully.'
);

