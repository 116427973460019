import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/elements/header/tabs.scss';

export const namespace = (): string => `${ns()}--tabs`;

export interface TabsProps {
  children?: React.ReactNode;
}

const Tabs: React.SFC<TabsProps> = ({ children }: TabsProps): React.ReactElement<TabsProps> => (
  <div className={namespace()}>
    {children}
  </div>
);

export default Tabs;
