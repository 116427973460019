import {makeRequestEpic} from "../../Validation/epicCreator";
import {FacilityLocationSettingsSubmitActions} from "./actions";
import {AjaxResponse} from "rxjs/ajax";
import { FACILITY_SETTINGS } from "../../../constants/messages/adminFacilityLocation";
import { getUpdateFacilityLocationSettingsUrl } from "../../../constants/urls";
import { ENTITY_SAVED } from "../../../constants/messages/adminCMS";

export const adminFacilityLocationSettingsSubmitEpic = makeRequestEpic(
  FacilityLocationSettingsSubmitActions,
  getUpdateFacilityLocationSettingsUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    return ENTITY_SAVED(FACILITY_SETTINGS);
  },
);