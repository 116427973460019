import {FormDefinition, IValidator} from "./validation";
import {apiValidationReducerCreator} from "../../../../Validation/reducerCreator";
import {OverrideReservationChange} from "./actions";
import {PopModal, PushModal} from "../../../../App/actions";
import { ModalTypes } from '../../../../../utils/modalHelper';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import { TripPaymentStatus } from '../../../../../models/api/cacheFourFacilities';
import { WithRootState } from "../../../../Validation/actionCreator";

export interface ActiveForm {
  FlatRate?: number;
  PerPersonRate?: number;
}

// @todo: initialize with the registration
export interface ReservationOverrideFeeState {
  ActiveForm: ActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
  name: string;
  accommodationName: string;
  amount: number;
  recalcSuccess: boolean;
  TripPaymentStatus?: TripPaymentStatus | null;
  AmountChangeInCart: number;
}

export const getInitialState = (): ReservationOverrideFeeState => {
  return {
    ActiveForm: {
      FlatRate: 0,
      PerPersonRate: 0
    },
    ValidationRules: {
      ...FormDefinition
    },
    accommodationName: '',
    name: '',
    amount: 0,
    recalcSuccess: true,
    AmountChangeInCart: 0,
  };
};

// dunno why I have to hardcode this...
// const checkApiValidation = apiValidationReducerCreator(SUFFIX);
const checkApiValidation = apiValidationReducerCreator('__RESERVATION_OVERRIDE_FEE');

// TODO: When doing form refactor, remember this is also a form. But this
// form is NOT sending any request, but submitting the result to cache directly
const ReservationOverrideFee: Reducer<ReservationOverrideFeeState> = (oldState = getInitialState(), action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, action);
  if (isActionType(action, PushModal) && action.modal === ModalTypes.RESERVATION_OVERRIDE_FEE) {
    const props = action.props;
    return {
      ...state,
      ActiveForm: {
        FlatRate: props!.reservation!.FlatRate,
        PerPersonRate: props!.reservation!.PerPersonRate
      },
      ValidationRules: {
        ...FormDefinition
      },
      Accommodation: 0,
      name: props!.facilityAvailabilitiesName,
      accommodationName: props!.accommodation,
      amount: props!.reservation!.Amount,
      AmountChangeInCart: props!.reservation!.AmountChangeInCart,
      TripPaymentStatus: props!.tripPaymentStatus,
    };
  } else if (action.type === OverrideReservationChange.requestType) {
    return {...state, recalcSuccess: false};
  } else if (action.type === OverrideReservationChange.successType ||
            (action.type === OverrideReservationChange.failureType && (action as any).response.status === 400)) {
    const act = action as any;
    let res;
    if (act.response.response) {
      res = act.response.response;
    } else if (act.response.xhr && act.response.xhr.response) {
      res = act.response.xhr.response;
    } else {
      return {...state, recalcSuccess: false};
    }
    if (!res.Reservation) {
      return {...state, recalcSuccess: false};
    }
    const Reservation = res.Reservation;
    return {
      ...state,
      amount: Reservation.Amount,
      AmountChangeInCart: Reservation.AmountChangeInCart,
      ActiveForm: {
        ...state.ActiveForm,
        FlatRate: Reservation.FlatRate,
        PerPersonRate: Reservation.PerPersonRate
      },
      recalcSuccess: true,
      TripPaymentStatus: res.TripPaymentStatus,
    };
  } else if (isActionType(action, PopModal) && (action.modal === ModalTypes.RESERVATION_OVERRIDE_FEE)) {
    return {...getInitialState()};
  }
  return state;
};

export default ReservationOverrideFee;
