import * as React from 'react';
import { default as SideBar, SideBarProps } from '../SideBar';
import '../../../../styles/layouts/main/left-side-bar/index.scss';
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';

export interface LeftSideBarProps extends SideBarProps {}

type ConnectedProps = ReturnType<typeof mapStateToProps>;

const LeftSideBar: React.SFC<LeftSideBarProps> = (props: LeftSideBarProps & ConnectedProps): React.ReactElement<LeftSideBarProps> => (
  <SideBar {...props} side="left" loading={props.apiSaving > 0}/>
);

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving
  };
};

const ConnectedLeftSideBar = connect(
  mapStateToProps,
  null,
  getMergeProps<LeftSideBarProps>(),
)(LeftSideBar);

export default ConnectedLeftSideBar;
