
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {Row, PageLoader, EmptyMessage, Select, AdminClassCard} from '../../../../../Elements';
import { actionCreators, GetSourceClassesList } from "../../../../../../store/AdminEvents/Events/Modals/ImportFromEvent/actions";
import '../../../../../../styles/pages/admin-events/events/modals/import-from-event/index.scss';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { ApplicationState } from '../../../../../../store';
import { enableBodyScroll, disableBodyScroll } from '../../../../../../utils/navHelper';
import { CardCategory } from '../../../../../Elements/Card';
import { EmptyMessageType } from '../../../../../Elements/EmptyMessage';
import { ClassTypesIcon } from '../../../../../Icons';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { makeFilteredClassesSelector } from '../../../../../../store/AdminEvents/Events/Modals/ImportFromEvent';
import { ADMIN_EVENT_CLASSES } from '../../../../../../constants/messages/adminEvents';
import { AdminEventClass } from '../../../../../../models/api/adminEventsCacheTwoEvent';
import { AdminClassCardType } from '../../../../../Elements/AdminClass/Card';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';

export const namespace = (): string => 'pages--admin-events--modals--import-from-event';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;
interface Props {
  onSearchBack: () => any;
}

export enum ImportFromEventType {
  TIME_BLOCKS,
  PRICING,
  CLASSES,
}

@(withRouter as any)
class ImportFromEventModalSelectClasses extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;
  private classListRef;

  componentDidMount() {
    disableBodyScroll(this.classListRef);
  }
  componentDidUpdate() {
    disableBodyScroll(this.classListRef);
  }

  componentWillUnmount() {
    enableBodyScroll(this.classListRef);
  }

  renderFilters = () => {
    const {type, modalState: {ActiveForm, ValidationRules}} = this.props;
    
    return (
      <Row key='filter_2' className={`${namespace()}--filter filter2`}>
        <div className={`${namespace()}--filter--col`}>
          <Select
            label='Kind of Class'
            disabled={false}
            hideOptional
            onChangeValue={this.props.actions.updateValueWithoutSave}
            value={ActiveForm.KindOfClass}
            validationRules={ValidationRules.KindOfClass}
            isNumber />
        </div>
        <div className={`${namespace()}--filter--col`}>
          <Select
            label='Register Under'
            disabled={false}
            hideOptional
            onChangeValue={this.props.actions.updateValueWithoutSave}
            value={ActiveForm.RegisterUnder}
            validationRules={ValidationRules.RegisterUnder}
            isNumber />
          </div>
      </Row>
    );
  };

  onSaveClasses = () => {
    const { modalState: {ValidationRules} } = this.props;
    this.props.actions.updateValue(true, ValidationRules.SelectedClasses);
    this.props.onSearchBack();
  };

  renderEmptyClasses = () => {
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        admin
        icon={ClassTypesIcon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={ENTITY_NOT_FOUND(ADMIN_EVENT_CLASSES)}
      />
    );
  };
  onClassSelect = (adminClass: AdminEventClass) => {
    this.props.actions.toggleClassSelect(!adminClass.selected, adminClass.IDi);
  };

  public render() {
    const { actions, apiLoadingMap, type, filteredClasses, modalState: {ActiveForm, ValidationRules, isTextSearching}, eventFormState, apiSaving } = this.props;
    const loading = apiLoadingMap[GetSourceClassesList.requestType];

    return (
      <div className={`${namespace()}--step-wrapper`}>
        <div className={`${namespace()}--step-prompt has-filter`}>
          <span>Step 2 of 3:</span>
          <span>Select Classes / Options</span>
        </div>
        {this.renderFilters()}
        {loading && <PageLoader className={`${namespace()}--page--loader`} />}
        {!loading ? <div className={`${namespace()}--step2--content classes`} ref={(ref) => this.classListRef = ref}>
          {!filteredClasses || filteredClasses.length === 0 ? this.renderEmptyClasses() : null}
          {filteredClasses && filteredClasses.length > 0 ? filteredClasses.map((adminClass) => {
            return (
              <AdminClassCard
                key={`import_class__card${adminClass.IDi}`}
                type={AdminClassCardType.IMPORT_CLASS_SELECT}
                category={CardCategory.LIST_MOBILE}
                adminEventClass={adminClass}
                onMultipleSelectClick={this.onClassSelect}
                selected={!!adminClass.selected}
              />
            );
          }) : null}
        </div> : null}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const importFromEventSelector = makeFormModalPropSelector(ModalTypes.IMPORT_FROM_EVENT, 'importFromEventType');
  const filteredClassesSelector = makeFilteredClassesSelector();

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    modalState: state.adminEvents.events.modals.importFromEvent,
    type: importFromEventSelector(state),
    cacheTwoEvent: state.adminEvents.cacheTwoEvent,
    eventFormState: state.adminEvents.events.event.form,
    filteredClasses: filteredClassesSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
  }, dispatch),
});

const ConnectedImportFromEventModalSelectClasses = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ImportFromEventModalSelectClasses);

export default ConnectedImportFromEventModalSelectClasses;