import * as React from 'react';
import { Button, Card, CardTitle, CardSubTitle, ContentBlock, View, Text, Ellipsis } from '../../../../Elements';
import Spot from './Spot';
import {EventInfoParticipantType, EventRegistrationNumbers} from "../../../../../models/api/cacheThreeEvents";
import {determineAvailability} from "../../../../../utils/eventsHelper";
import {namespace as ns} from './index';
import '../../../../../styles/pages/events/event/registration/spots.scss';
import { NumbersRegistrationSettings } from '../../../../../models/api/cacheTwoEvents';
import { EmailIcon, PhoneIcon } from '../../../../Icons';
import {displayOvernight} from '../../../../../store/Events/Event/Register/Numbers/Spots/validation';

export const namespace = (): string => `${ns()}--spots`;

interface Props {
  youthTypes: Array<EventInfoParticipantType>;
  adultTypes: Array<EventInfoParticipantType>;
  totalSpots: number;
  eventType: 'numbers' | 'both';
  eventSpotsAvailable: number;
  onClickSpots: () => void;
  MaxParticipants: number;
  EventRegistrationNumbers?: EventRegistrationNumbers | null;
  NumbersRegistrationSettings: NumbersRegistrationSettings;
}

const Spots: React.SFC<Props> = ({ eventType, youthTypes, adultTypes, onClickSpots, EventRegistrationNumbers, totalSpots, eventSpotsAvailable, NumbersRegistrationSettings, MaxParticipants }: Props): React.ReactElement<Props> => {

  return (
    <ContentBlock>
      <Card
        template="mobile-no-margin"
        padding="none"
        marginBottom={0}
      >
        <CardTitle
          marginTop={24} marginLeft={24} marginRight={24}
          mobileMarginTop={16} mobileMarginLeft={16} mobileMarginRight={16}
          num={totalSpots}
          controls={<Button color="white" onClick={onClickSpots}>Manage Spots</Button>}
          controlsWrap={false}
        >
          {'Spots'}
        </CardTitle>
        {youthTypes.length > 0 && <CardSubTitle marginLeft={24} mobileMarginLeft={16}>Youth</CardSubTitle>}
        {youthTypes.map((type: EventInfoParticipantType, i) => (
          <Spot
            key={i}
            pType={type}
            isLast={i === youthTypes.length - 1}
            available={determineAvailability(eventSpotsAvailable, type.NumEventSpotsAvailablePending, MaxParticipants, type.MaxParticipants)}
            eventType={eventType}
            eventMaxParticipants={MaxParticipants}
          />
        ))}

        {adultTypes.length > 0 && <CardSubTitle marginLeft={24} mobileMarginLeft={16}>Adults</CardSubTitle>}
        {adultTypes.map((type: EventInfoParticipantType, i) => (
          <Spot
            key={i}
            isLast={i === adultTypes.length - 1}
            pType={type}
            available={determineAvailability(eventSpotsAvailable, type.NumEventSpotsAvailablePending, MaxParticipants, type.MaxParticipants)}
            eventType={eventType}
            eventMaxParticipants={MaxParticipants}
          />
        ))}
        {EventRegistrationNumbers && <div className={`${namespace()}--registration-fields`}>
          {NumbersRegistrationSettings.ShowIsCampingOvernight && EventRegistrationNumbers.IsCampingOvernight > -1 && <View layout="vertical" className={`${namespace()}--section`}>
            <Text size={12} color="gray" marginBottom={4}>{displayOvernight(EventRegistrationNumbers.IsCampingOvernight)}</Text>
          </View>}
          {NumbersRegistrationSettings.ShowGroupRegistrationNotes && EventRegistrationNumbers.GroupRegistrationNotes && <View layout="vertical" className={`${namespace()}--section`}>
            <Text size={12} color="gray" marginBottom={4}>
              {NumbersRegistrationSettings.PromptGroupRegistrationNotes ? NumbersRegistrationSettings.PromptGroupRegistrationNotes : 'Registration Notes:'}
              </Text>
            <Ellipsis
              configs={{
                lines: 5,
                text: EventRegistrationNumbers.GroupRegistrationNotes,
              }}
            />
          </View>}
          {EventRegistrationNumbers.NotesForGroup && <View layout="vertical" className={`${namespace()}--section`}>
            <Text size={12} color="gray" marginBottom={4}>Notes for Group</Text>
            <Ellipsis
              configs={{
                lines: 5,
                text: EventRegistrationNumbers.NotesForGroup,
              }}
            />
          </View>}
          {EventRegistrationNumbers.AdminNotes && <View layout="vertical" className={`${namespace()}--section`}>
            <Text size={12} color="gray" marginBottom={4}>Admin Notes</Text>
            <Ellipsis
              configs={{
                lines: 5,
                text: EventRegistrationNumbers.AdminNotes,
              }}
            />
          </View>}
          {(EventRegistrationNumbers.ContactName || EventRegistrationNumbers.ContactEmail || EventRegistrationNumbers.ContactPhone) && <View layout="vertical" className={`${namespace()}--section`}>
            <Text size={12} color="gray" marginBottom={4}>Primary Event Contact</Text>
            {EventRegistrationNumbers.ContactName && <Text>{EventRegistrationNumbers.ContactName}</Text>}
            {(EventRegistrationNumbers.ContactEmail || EventRegistrationNumbers.ContactPhone) && <div className={`${namespace()}--contact--row`}>
              {EventRegistrationNumbers.ContactEmail && <div className={`${namespace()}--contact--col`}>
                <div className={`${namespace()}--contact--icon`}><EmailIcon /></div>
                <div className={`${namespace()}--contact--text`}>{EventRegistrationNumbers.ContactEmail}</div>
              </div>}
              {EventRegistrationNumbers.ContactPhone && <div className={`${namespace()}--contact--col`}>
                <div className={`${namespace()}--contact--icon`}><PhoneIcon /></div>
                <div className={`${namespace()}--contact--text`}>{EventRegistrationNumbers.ContactPhone}</div>
              </div>}
            </div>}
          </View>}
          {(EventRegistrationNumbers.ContactName2 || EventRegistrationNumbers.ContactEmail2 || EventRegistrationNumbers.ContactPhone2) && <View layout="vertical" className={`${namespace()}--section`}>
            <Text size={12} color="gray" marginBottom={4}>Alternate Contact</Text>
            {EventRegistrationNumbers.ContactName2 && <Text marginBottom={4}>{EventRegistrationNumbers.ContactName2}</Text>}
            {(EventRegistrationNumbers.ContactEmail2 || EventRegistrationNumbers.ContactPhone2) && <div className={`${namespace()}--contact--row`}>
              {EventRegistrationNumbers.ContactEmail2 && <div className={`${namespace()}--contact--col`}>
                <div className={`${namespace()}--contact--icon`}><EmailIcon /></div>
                <div className={`${namespace()}--contact--text`}>{EventRegistrationNumbers.ContactEmail2}</div>
              </div>}
              {EventRegistrationNumbers.ContactPhone2 && <div className={`${namespace()}--contact--col`}>
                <div className={`${namespace()}--contact--icon`}><PhoneIcon /></div>
                <div className={`${namespace()}--contact--text`}>{EventRegistrationNumbers.ContactPhone2}</div>
              </div>}
            </div>}
          </View>}
          {NumbersRegistrationSettings.ShowCampsiteRanking && !EventRegistrationNumbers.Campsites && EventRegistrationNumbers.PreferredCampsitesRanking && EventRegistrationNumbers.PreferredCampsitesRanking.length > 0 && <View layout="vertical" className={`${namespace()}--section`}>
            <Text size={15} color="gray" marginBottom={4}>Preferred Campsites</Text>
            {EventRegistrationNumbers.PreferredCampsitesRanking
              .map((a) => {
                return (
                  <Text size={15}>{a}</Text>
                );
              })}
            </View>}
          {EventRegistrationNumbers.Campsites && <View layout="vertical" className={`${namespace()}--section`}>
            <Text size={15} color="gray" marginBottom={4}>Campsites</Text>
            <Text size={15}>{EventRegistrationNumbers.Campsites}</Text>
          </View>}
        </div>}
      </Card>
    </ContentBlock>
  );
};

// const provider = provide(
//   ({ cacheTwoEvents }:{ cacheTwoEvents: CacheTwoEventsState }) => ({ cacheTwoEvents }),
//   null
// ).withExternalProps<Props>();
//
// export default provider.connect(Spots as any);
export default Spots;
