import { ApplicationState } from "../../..";
import * as M from "../../../../constants/messages/generic";
import {toNumber} from "../../../../utils/dataHelper";
import { Validator } from "../../../../utils/validator/models";

export interface IValidator {
  Refund: Validator;
  ConvenienceFee: Validator;
}

const refundOrFeeCustomValidate = (rootState: ApplicationState) => {
  const CartOrder = rootState.cacheOne.CartOrder;
  const {ActiveForm: {Refund, ConvenienceFee}} = rootState.settings.prevOrders.refundOrFee;

  if (!CartOrder) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    return "";
  }if (Refund === undefined) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    return "";
  }if (ConvenienceFee === undefined) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    return "";
  }
  if (toNumber(ConvenienceFee) + toNumber(Refund) > CartOrder.AvailableCredit) {
    return "Please try again, there is not enough available credit.";
  } else if (toNumber(ConvenienceFee) + toNumber(Refund) <= 0) {
    return "Please enter a refund amount and/or convenience fee before submitting.";
  }
};
export const FormDefinition: IValidator = {
  Refund: {
    key: 'Refund',
    decimalOnly: true,
    defaultValue: () => "0.00",
    customValidate: refundOrFeeCustomValidate,
    validatejs: {
      Refund: {
        presence: {message: M.S_REQUIRED('Refund Amount')},
        numericality: {
          notValid: `${M.S_INVALID("Refund Amount")}`
        },
      },
    },
  },
  ConvenienceFee: {
    key: 'ConvenienceFee',
    defaultValue: () => "0.00",
    decimalOnly: true,
    customValidate: refundOrFeeCustomValidate,
    validatejs: {
      ConvenienceFee: {
        presence: {message: M.S_REQUIRED('Convenience Fee')},
        numericality: {
          notValid: `${M.S_INVALID("Convenience Fee")}`
        },
      }
    }
  },
};
