import * as React from 'react';
import { connect } from 'react-redux';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/page-loader/index.scss';
import View from "../View";
import Loader from "../Loader";

import { ApplicationState } from '../../../store';
import { getMergeProps } from '../../../utils/reduxHelper';

export const namespace = 'elements--page-loader';

export interface PageLoaderProps {
  className?: string;
  height?: string;
  isGlobalLoader?: boolean; // This flag is only set when this component is used in App.tsx
  // TODO: later, when combining `<Loader />` component with this one, can use this flag
  fullScreen?: boolean;
}

type ConnectedProps = ReturnType<typeof mapStateToProps>;

class PageLoader extends React.Component<PageLoaderProps, {}> {
  public props: ConnectedProps & PageLoaderProps;
  render() {
    const {height, isModalSaving, isGlobalLoader, fullScreen} = this.props;
    let className = mergeClassNames(namespace, this.props);
    if (fullScreen) className += " full-screen";
    let style: any = {};
    if (height) {
      style.height = height;
    }

    // If `isModalSaving` is true, we show a loader in App.tsx so dont render anything
    // here to avoid duplicates.
    if (isModalSaving && !isGlobalLoader) return null;
    return (
      <View 
        verticalAlignment="middle" 
        className={`${className} ${!height ? 'full' : ''}`} 
        style={style}
      >
        <Loader isGlobalLoader={isGlobalLoader} />
      </View>
    );
  }
  
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    isModalSaving: state.app.isModalSaving,
  };
};
const mapDispatchToProps = (dispatch) => ({});
const ConnectedPageLoader = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<PageLoaderProps>(),
)(PageLoader);
export default ConnectedPageLoader;
