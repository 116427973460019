import {
  ApiSubmitActionsParticipantsClasses,
  EventRegisterParticipantsClassesAdd,
  EventRegisterParticipantsClassesRemove,
  EventRegisterParticipantsClearError,
  EventRegisterParticipantsShowError
} from './actions';
import {eventParticipantsInit} from '../../../../../../constants/urls';
import {makeRequestEpic} from '../../../../../Validation/epicCreator';
import {Observable} from "rxjs/Rx";
import {checkClasses} from "../../../../../../utils/classesHelper";
import {makeAvailableClassesFilter} from "../../../../../CacheFourEventsParticipants";
import { NoOp } from '../../../../../App/actions';
import { captureTentarooError } from '../../../../../../utils/dataHelper';
import { isActionType } from '../../../../../../utils/StrongActions';
import { reduxStoreService } from '../../../../../service';

export const participantsClassesNext = makeRequestEpic(
  ApiSubmitActionsParticipantsClasses,
  eventParticipantsInit,
  undefined,
  true
);

export const participantsAddClassValidateEpic = action$ => (
  action$.filter(action => isActionType(action, EventRegisterParticipantsClassesAdd) || isActionType(action, EventRegisterParticipantsClassesRemove))
    .mergeMap((action) => {
      const rootState = reduxStoreService().getState();
      const c2 = rootState.cacheTwoEvents;
      const c4 = rootState.cacheFourEventsParticipants;
      const {ActiveForm} = rootState.events.event.register.participant.roster;
      const isAdmin = !!rootState.user.user.str_permissions.hasAdminAccess;
      const availableClasses = makeAvailableClassesFilter();

      if (c4.isYouth === undefined) {
        captureTentarooError(new Error("cacheFourEventsParticipants.IsYouth is not available in participantsAddClassValidateEpic."));
        return Observable.of(new NoOp());
      }
      if (c2.EventTypeRegistrationSettings) {
        const results = checkClasses(
          c4.EventClassesIndividualRegistered,
          c4.EventClassesIndividualAvailable,
          availableClasses(rootState),
          c4.isYouth,
          false,
          c2.EventTypeRegistrationSettings.NamesRegistrationSettings.EnableClassWaitingList,
          c4.isYouth ? 1 : 0,
          c4.isYouth ? 0 : 1,
          isAdmin,
          ActiveForm.DOB
        );
        if (results.warnings.length > 0) {
          return Observable.of(new EventRegisterParticipantsShowError(results.warnings[0].message, results.error));
        } else {
          return Observable.of(new EventRegisterParticipantsClearError());
        }
      }

      return Observable.of(new NoOp());
    })
);
