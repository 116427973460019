import * as React from 'react';
import { FABButton as OriginalFABButton } from 'react-mdl';
import {mergeClassNames} from '@tentaroo/shared';
import '../../../styles/elements/action-button/index.scss';

import { isMobile } from '../../../utils/isMobile';

export const namespace = (): string => 'elements--action-button';

export interface ActionButtonProps {
  children?: React.ReactNode;
  contextMenu?: React.ReactElement<any>;
  onClick?: React.EventHandler<any>;
  className?: string;
  ripple?: boolean;
  icon?: React.ReactType | React.ReactNode;
  selected?: boolean;
  rotate?: number;
  title?: string;
  shadow?: boolean;
  disabled?: boolean;
  background?: 'white';
  square?: boolean;
  wrapperStyle?: any; // css style
  allowPropagation?: boolean;
  lightSelected?: boolean;    // use this to indicate whether or not to use a lighter backgroundColor when selected
  type?: string;
  id?: string;
}

type ActionButtonState = {
  contextMenuOpen: boolean;
};

const FABButton: any = OriginalFABButton;

class ActionButton extends React.Component<ActionButtonProps, ActionButtonState> {
  public static defaultProps = {
    type: 'button',
    ripple: true
  };

  public props: ActionButtonProps;
  public state: ActionButtonState = {
    contextMenuOpen: false
  };

  public componentDidMount() {
    this.props.contextMenu && document.addEventListener('click', this.handleClickOutside);
  }

  public componentWillUnmount() {
    this.props.contextMenu && document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (!this.refs.actionButtonRef || !(this.refs.actionButtonRef as any).contains(e.target)) {
      this.setState({
        contextMenuOpen: false,
      });
    }
  };

  public render() {
    const {
      children, icon, id, selected, onClick, rotate, contextMenu, ripple, title, disabled, shadow, background, wrapperStyle, type, square, allowPropagation, lightSelected
    } = this.props;

    let className = mergeClassNames(namespace(), this.props);
    let buttonClassName = `${namespace()}--button`;

    if (square) buttonClassName += ' square';
    if (selected) buttonClassName += ' selected';
    if (disabled) buttonClassName += ' disabled';
    if (shadow) buttonClassName += ' shadow';
    if (background) buttonClassName += ` background-${background}`;
    // @todo - need to handle the same hovering/ripple effect if migrating to mui
    if (isMobile) buttonClassName += ' mobile';
    if (this.state.contextMenuOpen) className += ' menu-opened';
    if (lightSelected) buttonClassName += ' light';

    let finalChildren: React.ReactElement<any> = children as any;
    if (rotate) {
      finalChildren = React.cloneElement(finalChildren, {
        ...finalChildren.props,
        style: {
          ...finalChildren.props.style,
          transform: `rotateZ(${rotate}deg)`
        }
      });
    }

    let iconComponent: React.ReactElement<any> | undefined;
    if (icon) {
      if (React.isValidElement(icon)) {
        iconComponent = icon;
      } else {
        const Icon: React.ReactType = icon as any;
        iconComponent = <Icon/>;
      }
    }

    const button = (
      <FABButton
        ripple={!isMobile}
        className={buttonClassName}
        onClick={disabled ? null : onClick}
        title={title}
        type={type}
        id={id}
      >
        {iconComponent}
        {finalChildren}
      </FABButton>
    );

    if (contextMenu) {
      return (
        <div ref='actionButtonRef' className={className}>
          {React.cloneElement(button, { ...button.props, onClick: disabled ? null : (e) => {
            if (!allowPropagation) {
              e.stopPropagation();
              this.setState({ contextMenuOpen: !this.state.contextMenuOpen });
            }
          }})}
          {React.cloneElement(contextMenu, {
            ...contextMenu.props,
            open: this.state.contextMenuOpen,
            onClose: (e) => {
              this.setState({ contextMenuOpen: false });
              if (contextMenu.props.onClose) contextMenu.props.onClose();
            }
          })}
        </div>
      );
    }
    return (
      <div className={className} style={wrapperStyle}>
        {button}
      </div>
    );
  }
}

export default ActionButton;
