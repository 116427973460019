import * as React from 'react';
import {bindActionCreators} from 'redux';
import {withRouter, RouteComponentProps} from "react-router";
import {actionCreators} from '../../../../../../store/Events/Event/Register/Numbers/Main/actions';
import Spots from './Spots';
import Classes from './Classes';
import CampsiteRanking from './CampsiteRanking';
import Confirmation from './Confirmation';
import Products from './Products';
import {LoadingAll} from "../../../../../Elements";
import {actionCreators as cacheFourActionCreators} from "../../../../../../store/CacheFourEventsNumbers/actions";
import {actionCreators as cacheThreeActionCreators} from "../../../../../../store/CacheThreeEvents/actions";
import {
  makeYouthParticipantTypeSelector, makeAdultParticipantTypeSelector, makeEventRegistrationYouthSpotsSelector, makeEventRegistrationAdultSpotsSelector
} from "../../../../../../store/CacheFourEventsNumbers/index";
import { spotsOnlySelector } from '../../../../../../utils/eventsHelper';
import CampsiteAssignment from './CampsiteAssignment';
import { ApplicationState } from '../../../../../../store';
import EndUserCacheManager from '../../../../../../utils/cacheManagers/endUserCacheManager';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isCacheFourEventsNumbersPopulated} from '../../../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--event';

export type NumbersConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string}>
>;

@(withRouter as any)
class EventRegisterNumbers extends ComponentUpdateTemplate<NumbersConnectedProps> {
  public props: NumbersConnectedProps;

  componentDidMount() {
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheFourEventsNumbers({
          props: this.props,
          isStateNavigated,
        });
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    // We need to call the loaders on props change, so that redirection can happen
    // on logout.
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheFourEventsNumbers({
          props: nextProps,
          isStateNavigated,
        });

      }
    );
  }

  componentWillUnmount() {
    const {actions, cacheFourEventsNumbers} = this.props;
    // TODO: Later, make it consistent with admin module:
    // use `clearCacheBelowThreeEvents` to clear both form and caches, and always init on mount
    actions.reset();

    if (isCacheFourEventsNumbersPopulated(cacheFourEventsNumbers)) {
      actions.clearCacheBelowThreeEvents();
    }
  }

  public render() {
    const {eventRegisterNumbers: {selectedPage}, cacheThreeEvents, cacheFourEventsNumbers, inert} = this.props;
    if (!isCacheFourEventsNumbersPopulated(cacheFourEventsNumbers)) return <LoadingAll/>;
    if (selectedPage === 'spots') {
      return <Spots
        inert={inert}
        numberProps={this.props}
      />;
    } else if (selectedPage === 'classes') {
      return <Classes
        inert={inert}
        numberProps={this.props}
      />;
    } else if (selectedPage === 'products') {
      return <Products
        inert={inert}
        numberProps={this.props}
      />;
    } else if (selectedPage === 'campsite_ranking') {
      return <CampsiteRanking
        inert={inert}
        numberProps={this.props}
      />;
    } else if (selectedPage === 'campsite_assignment') {
      return <CampsiteAssignment
        inert={inert}
        numberProps={this.props}
      />;
    } else if (selectedPage === 'confirmation') {
      return <Confirmation
        inert={inert}
        numberProps={this.props}
      />;

    }
    return null;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const youthParticipantTypeSelector = makeYouthParticipantTypeSelector();
  const eventRegistrationYouthSpotsSelector = makeEventRegistrationYouthSpotsSelector();
  const adultParticipantTypeSelector = makeAdultParticipantTypeSelector();
  const eventRegistrationAdultSpotsSelector = makeEventRegistrationAdultSpotsSelector();
  const SpotsOnly = spotsOnlySelector(state);
  const youthTypes = SpotsOnly ? youthParticipantTypeSelector : eventRegistrationYouthSpotsSelector;
  const adultTypes = SpotsOnly ? adultParticipantTypeSelector : eventRegistrationAdultSpotsSelector;
  return {
    eventRegisterNumbers: state.events.event.register.numbers.main,
    user: state.user,
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheFourEventsNumbers: state.cacheFourEventsNumbers,
    cacheThreeEvents: state.cacheThreeEvents,
    cacheTwoEvents: state.cacheTwoEvents,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    youthTypes: youthTypes(state),
    adultTypes: adultTypes(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...cacheFourActionCreators,
  ...cacheThreeActionCreators,
}, dispatch)});

const ConnectedEventRegisterNumbers = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(EventRegisterNumbers);

export default ConnectedEventRegisterNumbers;

