import * as React from 'react';
import MyTrips from './MyTrips';
import {LoadingAll} from '../../Elements';
import MyEvents from './MyEvents';
import {RouteComponentProps, withRouter} from "react-router";
import {bindActionCreators} from 'redux';
import {actionCreators} from "../../../store/Home/actions";
import {actionCreators as cacheOneActionCreators} from "../../../store/CacheOne/actions";
import {actionCreators as cacheZeroActionCreators} from "../../../store/CacheZero/actions";
import {actionCreators as appActionCreators} from "../../../store/App/actions";
import { ApplicationState } from '../../../store';
import EndUserCacheManager from '../../../utils/cacheManagers/endUserCacheManager';
import { captureTentarooError } from '../../../utils/dataHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';
import { ComponentUpdateTemplate } from '../../Templates/ComponentUpdateTemplate';
import {isEndUserCacheOnePopulated} from '../../../utils/cachePopulatedCheckers/endUser';
import {WithInertAttribute} from '../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>
>;

@(withRouter as any)
class Home extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;

  public componentDidMount() {
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheOne({
          props: this.props,
          isStateNavigated,
        });
      }
    );
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheOne({
          props: nextProps,
          isStateNavigated,
        });
      }
    );
    
  }

  public render() {
    const {home: {selectedTab}, inert, cacheOne} = this.props;
    if (!isEndUserCacheOnePopulated(cacheOne)) {
      return <LoadingAll/>;
    }

    if (!cacheOne.EventEventTypes && !cacheOne.FacilityLocations) {
      captureTentarooError(new Error('There is no events or facilities after cacheOne loaded!!! This should never happen'));
      return null;
    }
    if (selectedTab === 'myEvents') {
      if (!cacheOne.EventEventTypes && cacheOne.FacilityLocations) {
        // if i have myEvents selected, but I don't have events and I have facilities, then render MyTrips
        return <MyTrips inert={inert} />;
      }
      return <MyEvents inert={inert} />;
    } else {
      if (cacheOne.EventEventTypes && !cacheOne.FacilityLocations) {
        // if i have myTrips selected, but I don't have facilities and I have events, then render MyEvents
        return <MyEvents inert={inert} />;
      }
      return <MyTrips inert={inert} />;
    }
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    home: state.home,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...cacheOneActionCreators,
  ...cacheZeroActionCreators,
  ...appActionCreators,
}, dispatch)});

const ConnectedHome = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(Home);

export default ConnectedHome;

export { default as HomeHeader } from './Header';
export { default as HomeRightSidebar } from './RightSidebar';
