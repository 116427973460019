import {Location} from "history";
import { replace } from 'react-router-redux';
import {AnyAction} from 'redux';

import type { ApplicationState } from "../store";
import {isCacheZeroPopulated} from "../utils/cachePopulatedCheckers/endUser";
import {EMPTY_USER} from "../store/User/constants";
import {hasLocationChanged} from "../utils/navHelper";
import {reduxStoreService} from "../store/service";
import {ClearAllCache, SetAfterLoginPath, ShowTopFloatingAlert, SilentCancelAllAction} from "../store/App/actions";
import {ClearRollback, RestoreState} from '../store/Rollback/actions';
import {QP_ACCOUNT, URLS} from "../constants/urls";

export const getIsRollingBackThroughBrowserState = (location: Location): boolean => {
  return location && location.state && location.state.tentarooPerformRollback && location.action !== 'POP';
};

export const getIsFromHandleError = (location: Location) => {
  return !!location && !!location.state && !!location.state.isFromHandleError && location.action !== 'POP';
};

export const shouldRollbackOnBrowserUrlChange = (rootState: ApplicationState, duringSave: boolean, isRollingBackThroughBrowserState?: boolean) => {
  const app = rootState.app;

  if (!duringSave) {
    return app.apiLoading > 0;
  } else {
    return app.apiSaving > 0 && !!isRollingBackThroughBrowserState;
  }
};

export const rollbackStateExists = (rootState: ApplicationState) => {
  const normalSavePoint = rootState.rollback.normalSavePoint;

  return normalSavePoint && normalSavePoint.oldState;
};

/**
 * There are two cases where this could happen:
 * 1. We are canceling the second request after Login via browser back/forward
 * 2. We are canceling the second request after GetLoginForm 200, via browser back/forward
 */
export const isCancelingRequestBeforeCacheZeroIsLoaded = (rootState: ApplicationState, isFromHandleError?: boolean) => {
  return (
    rootState.user.user &&
    rootState.user.user.IDi !== EMPTY_USER.IDi &&
    !isCacheZeroPopulated(rootState.cacheZero) &&
    !isFromHandleError
  );
};

export const shouldRedirectToLogin = (isOnBrowserUrlChange?: boolean) => {
  if (!rollbackStateExists(reduxStoreService().getState())) {
    let result: any[];
    if (reduxStoreService().getState().routing && reduxStoreService().getState().routing.locationBeforeTransitions.pathname === URLS.NEW_ACCOUNT) {
      /**
       * If I'm in NewAccount page AND the GetRegisterForm request just failed, navigate
       * back to LOGIN.
       * 
       * This is different than other requests because other requests happen after a successful login,
       * so we need to trigger `ClearAllCache(true)` to remove the login session so we can show the login form again.
       * However, in this case, the user isn’t logged in so we can simply redirect to login.
       */
      result = [
        replace({
          pathname: URLS.LOGIN
        }),
      ];
    } else {
      // ALWAYS redirect to login page if we dont have a place to rollback to
      // if Rolling back to something empty, clear session and go to login page instead.
      result = [
        new ClearAllCache(true),
        new SetAfterLoginPath(
          reduxStoreService().getState().routing.locationBeforeTransitions.pathname,
          reduxStoreService().getState().routing.locationBeforeTransitions.query[QP_ACCOUNT]
        ),
        replace({
          pathname: URLS.LOGIN
        }),
      ];
    }

    if (isOnBrowserUrlChange) {
      result.unshift(new SilentCancelAllAction(true));
    }

    return result;
  }

  return null;
};


/**
 * This function is meant to replicate this behavior that happen when there's
 * an error from a request: we may or may not need to rollback through browser state,
 * changing the url back to what we have in save state. We can't call `handleRollbackOnBrowserUrlChange`
 * directly in both cases because it doesn't rollback the url.
 * 
 * However, unlike error handling, we do need to manually cancel the load request, more
 * like the code that runs in onBrowserUrlChange. This function should never be triggered by
 * browser url change, we only call this when we need to cancel a load for some other
 * reason.
 * 
 * On modal open/close or logout, if there is any ongoing load, we should either
 * 1. Rollback both state and url if the load was started before modal open or logout, and comes from a navigation.
 *    This rollback will result in a url change and will do a SilentCancelAll onBrowserUrlChange
 * 2. Otherwise, no navigation or modal close, should manually dispatch SilentCancelAllAction.
 * 
 * In this function we immediately dispatch a `ClearRollback` action right after
 * the rollback, to make the rollback atomic here rather than relying on App.tsx to
 * clear it. By doing so we ensure the rollback we perform here is atomic and won't
 * affect any subsequent actions (e.x. won't block subsequent actions because the rollback
 * flag is not cleared in time)
 */
export const cancelLoadAndRollback = (): boolean => {
  if (reduxStoreService().getState().app.apiLoading > 0) {
    const rollbackActions = restoreStateDirectlyOrThroughBrowser(false);
    rollbackActions.forEach((action) => reduxStoreService().dispatch(action));

    return false;
  }

  if (reduxStoreService().getState().app.apiSaving > 0) {
    reduxStoreService().dispatch(new ShowTopFloatingAlert(
      "Currently saving, please wait for save to complete before going elsewhere.",
      undefined,
      "orange",
    ));
    return true;
  }

  return false;
};

/**
 * This function is used by the error handling code and cancelLoadAndRollback function, where we might
 * need to initiate a restore through the browser.
 * 
 * This function returns an array of actions that when dispatched, trigger the rollback process.
 * - If there's no save point to rollback to, it returns an array of actions to redirect to login page
 * - If there's a save point, but no location change, it returns a RestoreState action
 * - If there's a save point, and there's a location change, it returns a router replace action with `tentarooPerformRollback`
 *   flag, which then triggers the rollback through browser state process. The actual RestoreState action is dispatched in
 *   onBrowserUrlChange -> handleRollbackOnBrowserUrlChange
 */
export const restoreStateDirectlyOrThroughBrowser = (isFromHandleError: boolean): Array<any> => {
  const rootState = reduxStoreService().getState();
  const redirectActions = shouldRedirectToLogin();

  if ((redirectActions && redirectActions.length > 0)) {
    return redirectActions;
  }

  if (!rootState.rollback.normalSavePoint) {
    return [];
  }

  // If `normalSavePoint` is not there, we should get redirectActions and return,
  // So the dereference here is safe
  const { oldQuery, oldUrl } = rootState.rollback.normalSavePoint;

  if (hasLocationChanged({
    previousUrl: oldUrl,
    previousQuery: oldQuery,
    nextUrl: rootState.routing.locationBeforeTransitions.pathname,
    nextQuery: rootState.routing.locationBeforeTransitions.query,
  })) {
    // if (oldUrl !== reduxStoreService().getState().routing.locationBeforeTransitions.pathname) {
    if (!oldUrl) {
      return [
        new ClearAllCache(true),
        replace({
          pathname: URLS.LOGIN,
          query: oldQuery,
          state: {
            tentarooPerformRollback: true,
            isFromHandleError,
          }
        }),
      ];
    } else {
      return [replace({
        pathname: oldUrl,
        query: oldQuery,
        state: {
          tentarooPerformRollback: true,
          isFromHandleError,
        }
      })];
    }
  }

  // No url change case
  const actions: AnyAction[] = [];
  actions.push(new RestoreState(
    undefined,
    reduxStoreService().getState().app.apiSaving > 0,
    // When `isFromHandleError` is truthy, the request doesn't need to be
    // canceled because it has an error
    !isFromHandleError,
  ));

  if (!isFromHandleError) {
    // Immediately dispatch a `ClearRollback` action here, so we dispatch it right
    // after a `RestoreState`, which guarantees rollback is atomic
    actions.push(new ClearRollback());
  }
  return actions;
};

if (process.env.NODE_ENV === "development") {
  window.restoreStateDirectlyOrThroughBrowser = restoreStateDirectlyOrThroughBrowser;
  window.doRollback = () => {
    window.restoreStateDirectlyOrThroughBrowser(true).forEach((action) => reduxStoreService().dispatch(action));
  };
}