import { createSelector } from "reselect";

import type { ApplicationState } from "../../..";
import { facilitiesSelector, sortFacilities, facilityTypesSelector } from "../../Facilities/Home";
import { AdminFacility, AdminFacilityType } from "../../../../models/api/adminFacilitiesCacheOne";


const selectedFacilityIdsSelector = (state: ApplicationState) => state.adminFacilityLocation.blackoutDates.form.ActiveForm.Facilities;

export const makeSelectedFacilitiesSelector = () => {
  return createSelector(
    [facilitiesSelector, selectedFacilityIdsSelector, facilityTypesSelector],
    (facilities: AdminFacility[], selectedFacilityIds: number[], facilityTypes: AdminFacilityType[]) => {
      if (!selectedFacilityIds || selectedFacilityIds.length === 0) return [];
      const result: any[] = selectedFacilityIds.map((id) => {
        const facility = facilities.find((f) => f.ID === id);

        return facility;
      }).filter((f) => {
        const facilityType = facilityTypes.find((ft) => !!f && ft.ID === f.FacilityTypeID);

        return !!f && !f.Inactive && (facilityType && !facilityType.Inactive);
      });

      return sortFacilities(result, true);
    },
  );
};