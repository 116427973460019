import {GetEventData} from './actions';
import {APIRequest, APISuccess, APISuccessSubmit} from "../Validation/actionCreator";
import {ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction} from "../App/actions";
import {EventTypeEvent} from "../../models/api/cacheTwoEvents";
import {ClearCacheBelowOne} from "../CacheOne/actions";
import {BaseEndUserClass, RegisteredClass} from "../../models/class";
import {OrderedProduct, Product} from "../../models/product";
import {ClearCacheBelowTwoEvents} from "../CacheTwoEvents/actions";
import {listSelector, makeNoFilterSelector, makeSingleSelector} from "../../utils/reselectHelper";
import {eventsSelector, showAdultSelector, showYouthSelector} from "../CacheTwoEvents/index";
import {
  EventInfo,
  EventInfoParticipantType,
  EventInfoPermissions,
  EventRegistrationNumbers,
  EventRegistrationParticipant,
  EventRegistrationPaymentStatus
} from "../../models/api/cacheThreeEvents";
import {convertObjToMoment, convertToMoment} from "../../utils/dateHelper";
import moment from 'moment';
import {createSelector} from "reselect";
import {GetEventParticipantData} from "../CacheFourEventsViewParticipant/actions";
import {EventNumbersSave} from "../CacheFourEventsNumbers/actions";
import {FSSubmitActions} from '../Events/Event/Registration/actions';
import {EventParticipantsSave} from "../CacheFourEventsParticipants/actions";
import {EmptyCartActions, RemoveItemFromCartActions} from "../Cart/actions";
import {EventProductsSave} from "../CacheFourEventsProducts/actions";
import {productOrderedSort} from "../CacheFourEventsProducts/reducerHelpers";
import { captureTentarooError } from '../../utils/dataHelper';
import { Reducer } from 'redux';
import { Action, isActionType } from '../../utils/StrongActions';

export const eventIDSelector = (state) => state.cacheThreeEvents.eventID;
export const participantTypesSelector = (state) => {
  if (state.cacheThreeEvents.EventInfoParticipantTypes) return state.cacheThreeEvents.EventInfoParticipantTypes;
  return [];
};
export const participantsSelector = (state) => {
  if (state.cacheThreeEvents.EventRegistrationParticipants) return state.cacheThreeEvents.EventRegistrationParticipants;
  return [];
};

export const makeSelectedEventSelector = makeSingleSelector((et: EventTypeEvent, id: number) => et.IDi === id, eventsSelector, eventIDSelector);
export const makeYouthParticipantTypeSelector = makeNoFilterSelector((t: EventInfoParticipantType) => t.IsYouth, participantTypesSelector);
export const makeAdultParticipantTypeSelector = makeNoFilterSelector((t: EventInfoParticipantType) => !t.IsYouth, participantTypesSelector);
export const makeParticipantTypeSelector = () => {
  return createSelector(
    [participantTypesSelector, showYouthSelector, showAdultSelector],
    (pTypes: Array<EventInfoParticipantType>, showYouth: boolean, showAdult: boolean) => {
      if (!showYouth && !showAdult) return [];
      const filteredPTypes =  pTypes.filter((pType) => {
        if (pType.IsYouth) {
          return showYouth;
        }
        return showAdult;
      });
      filteredPTypes.sort(pTypeSort);
      return filteredPTypes;
    }
  );
};

const pTypeSort = (a: EventInfoParticipantType, b: EventInfoParticipantType) => {
  // FirstTimeBlock, FirstClassDay, Name
  if (!a.IsYouth && b.IsYouth) {
    return 1;
  } else if (a.IsYouth && !b.IsYouth) {
    return -1;
  }

  if (a.Name.toLowerCase() === b.Name.toLowerCase()) {
    return 0;
  } else if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
    return -1;
  } else {
    return 1;
  }
};

const orderedProductFilter = (p: OrderedProduct) => !p.Inactive;
const eventProductsOrderedSelector = listSelector(state => state.cacheThreeEvents.EventProductsOrdered);

export const makeC3OrderedProductsFilter = makeNoFilterSelector(
  orderedProductFilter,
  eventProductsOrderedSelector,
  productOrderedSort
);

export interface CacheThreeEventsCore {
  EventClassesIndividualAvailable?: Array<BaseEndUserClass> | null;
  EventClassesGroupAvailable?: Array<BaseEndUserClass> | null;
  EventClassesGroupRegistered?: Array<RegisteredClass> | null;
  EventProductsAvailable?: Array<Product> | null;
  EventProductsOrdered?: Array<OrderedProduct> | null;
  EventInfo?: EventInfo;
  EventInfoParticipantTypes?: Array<EventInfoParticipantType> | null;
  EventRegistrationNumbers?: EventRegistrationNumbers | null;
  EventRegistrationParticipants?: Array<EventRegistrationParticipant> | null;
  EventRegistrationPaymentStatus?: EventRegistrationPaymentStatus | null;
  EventInfoPermissions?: EventInfoPermissions | null;
}

export interface CacheThreeEventsState extends CacheThreeEventsCore {
  eventID: number;
  sortedParticipants?: any;
  participantTypeMap?: any;
}

// const test = (classes) => {
//   const ret = [];
//   classes.forEach((c) => {
//     const n = {...c};
//     n.NumClasses = 0;
//     ret.push(n);
//   });
//   return ret;
// };

const setCacheThreeData = (data: CacheThreeEventsCore, eventID: number): CacheThreeEventsState => {
  // data.EventRegistrationParticipants = test(data.EventRegistrationParticipants); // for testing
  const ei = convertObjToMoment(data.EventInfo, ['StartDateTime', 'EndDateTime']);
  ei.PricingInfo.NextPricingDateDisplay = moment(ei.PricingInfo.NextPricingDateDisplay);
  // ei.NumEventSpotsAvailablePending = 0; // for testing
  const sortedParticipants = {};
  const participantTypeMap = {};
  if (data.EventInfoParticipantTypes && data.EventInfoParticipantTypes.length > 0) {
    const allIDs = {};

    data.EventInfoParticipantTypes.forEach((type) => {
      allIDs[type.ID] = true;
      participantTypeMap[type.ID] = type;
      return allIDs;
    });

    Object.keys(allIDs).forEach((id: string) => {
      if (!data.EventRegistrationParticipants || data.EventRegistrationParticipants.length === 0) {
        sortedParticipants[id] = [];
        return;
      }
      sortedParticipants[id] = data.EventRegistrationParticipants.filter((p) => p.ParticipantTypeID === Number(id));
      sortedParticipants[id].sort((a: EventRegistrationParticipant, b: EventRegistrationParticipant) => {
        if (a.LastName.toLowerCase() !== b.LastName.toLowerCase()) {
          if (a.LastName.toLowerCase() < b.LastName.toLowerCase()) return -1;
          return 1;
        }
        if (a.FirstName.toLowerCase() !== b.FirstName.toLowerCase()) {
          if (a.FirstName.toLowerCase() < b.FirstName.toLowerCase()) return -1;
          return 1;
        }
        if (a.MI.toLowerCase() !== b.MI.toLowerCase()) {
          if (a.MI.toLowerCase() < b.MI.toLowerCase()) return -1;
          return 1;
        }
        return 0;
      });
    });
  }
  if (data.EventProductsAvailable) {
    data.EventProductsAvailable.sort((a: Product, b: Product) => {
      if (a.Name.toLowerCase() !== b.Name.toLowerCase()) {
        if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
        return 1;
      }
      return 0;
    });
  }
  let OrderedProducts;
  if (data.EventProductsOrdered) {
    OrderedProducts = convertToMoment(data.EventProductsOrdered, ['DateOrdered']);
    // Order by IsNewlyAdding DESC, DateOrdered DESC, Name ASC
    // todo: sort this
    OrderedProducts.sort((a: OrderedProduct, b: OrderedProduct) => {
      if (a.IsNewlyAdding !== b.IsNewlyAdding) {
        if (a.IsNewlyAdding && !b.IsNewlyAdding) return -1;
        return 1;
      }

      if (a.DateOrdered && !b.DateOrdered) return -1;
      if (!a.DateOrdered && b.DateOrdered) return 1;
      if (a.DateOrdered && b.DateOrdered) {
        if (a.DateOrdered.isAfter(b.DateOrdered)) return -1;
        else if (b.DateOrdered.isAfter(a.DateOrdered)) return 1;
      }

      if (a.Name.toLowerCase() !== b.Name.toLowerCase()) {
        if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
        return 1;
      }
      return 0;
    });
  }

  return {
    EventClassesIndividualAvailable: data.EventClassesIndividualAvailable,
    EventClassesGroupAvailable: data.EventClassesGroupAvailable,
    EventClassesGroupRegistered: data.EventClassesGroupRegistered,
    EventProductsAvailable: data.EventProductsAvailable,
    EventProductsOrdered: OrderedProducts,
    // EventProductsOrdered: [],
    EventInfo: ei,
    EventInfoParticipantTypes: data.EventInfoParticipantTypes,
    EventRegistrationNumbers: convertObjToMoment(data.EventRegistrationNumbers, ['DateRegistration']),
    EventRegistrationParticipants: data.EventRegistrationParticipants,
    EventRegistrationPaymentStatus: convertObjToMoment(data.EventRegistrationPaymentStatus, ['DepositDueDate']),

    // EventInfoPermissions: {
    //   EventIDi: 0,
    //   IsRegistrationReductionAllowed: false,
    //   IsRegistrationReductionAllowedReason: 'IsRegistrationReductionAllowedReason',
    //   hasEventRegistrationsGroup: false,
    //   hasEventRegistrationsGroupReason: 'hasEventRegistrationsGroupReason',
    //   hasRegistrationNames: false,
    //   hasRegistrationNamesReason: 'hasRegistrationNamesReason',
    //   hasRegistrationNumbersAdd: false,
    //   hasRegistrationNumbersAddReason: 'hasRegistrationNumbersAddReason',
    //   hasRegistrationNumbersChange: false,
    //   hasRegistrationNumbersChangeReason: 'hasRegistrationssNumbersChangeReason',
    //   hasViewReports: false,
    //   hasViewReportsReason: 'hasViewReportsReason',
    //   hasRegistrationProducts: false,
    //   hasRegistrationProductsReason: 'hasRegistrationProductsReason'
    // },
    EventInfoPermissions: data.EventInfoPermissions,

    eventID: eventID,
    sortedParticipants: sortedParticipants,
    participantTypeMap: participantTypeMap
  };
};

const handleResponse = (state, responseData, request) => {
  if (responseData.EventInfoPermissions !== undefined){
    const bodyStr = request.body;
    try {
      const body = JSON.parse(bodyStr);
      return setCacheThreeData(responseData, body.AppState.EventIDi);
    } catch (e) {
      captureTentarooError(new Error('Unable to parse request body for cache 3!'));
      return state;
    }
  }
  return state;
};

const CacheThreeEvents: Reducer<CacheThreeEventsState> = (state, act: Action) => {
  if (
    act.type === GetEventData.successType || act.type === GetEventParticipantData.successType ||
    act.type === EventProductsSave.successType ||
    act.type === EventNumbersSave.successType || act.type === FSSubmitActions.successType ||
    (act.type === EventParticipantsSave.successType && (act as APISuccessSubmit).extra?.isCacheThreeEventLoaded) ||
    act.type === EmptyCartActions.successType || act.type === RemoveItemFromCartActions.successType
  ) {
    const action = <APISuccess> act;
    return handleResponse(state, action.response.response, action.response.request);
  } else if (isActionType(act, SetCacheAction)) {
    return handleResponse(state, act.response.xhr.response, act.response.request);
  } else if (act.type === GetEventData.requestType || act.type === GetEventParticipantData.requestType) {
    const action = <APIRequest> act;
    return {...state, eventID: action.value.EventIDi};
  } else if (
    isActionType(act, ClearAllCache) || isActionType(act, ClearAllEndUserCacheButOptions) || isActionType(act, ClearCacheBelowOne) ||
    isActionType(act, ClearCacheBelowTwoEvents)
  ) {
    return {eventID: 0};
  }
  return state || {eventID: 0};
};

export default CacheThreeEvents;
