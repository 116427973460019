import * as React from 'react';
import { bindActionCreators } from 'redux';
import { actionCreators as sessionActionCreators } from '../../../../store/Session/actions';
import { actionCreators as loginActionCreators, LoginActions } from '../../../../store/Login/actions';
import { actionCreators as appActionCreators } from '../../../../store/App/actions';
import { actionCreators as rollbackActionCreators } from '../../../../store/Rollback/actions';
import { Form, TextField, Button, Media, View, Card, Loader, Alert, LoadingAll, Text, BulletListItem, BulletList } from '../../../Elements';
import { Main, MainContent } from '../../../Layouts';
import { RouteComponentProps } from 'react-router';
import '../../../../styles/pages/auth/login/index.scss';
import '../../../../styles/pages/auth/login/logo.scss';
import Footer from './Footer';
import Logo from '../../../Elements/Logo/LogoComponent';
import { withRouter } from 'react-router';
import { navPush } from '../../../../utils';
import { URLS, openSupportForm } from '../../../../constants/urls';
import NotFound from "../../NotFound";
import {GET_LOGIN_FAIL_MESSAGE, GET_LOGIN_FAIL_TITLE} from "../../../../constants/messages/login";
import { generateDOMId } from '../../../../utils/cypressHelper';
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';
import UnauthenticatedCacheManager from '../../../../utils/cacheManagers/unauthenticatedCacheManager';
import { ModalTypes } from '../../../../utils/modalHelper';
import { ResetPasswordContext } from '../../../../store/ResetPassword';
import { ResetPasswordUpdateSubmitActions } from '../../../../store/ResetPassword/actions';
import { reduxStoreService } from '../../../../store/service';

import {namespace} from "./constants";
import { ComponentUpdateTemplate } from '../../../Templates/ComponentUpdateTemplate';
import { WithInertAttribute } from '../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>
>;

@(withRouter as any)
class Login extends ComponentUpdateTemplate<ConnectedProps> {
  public handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    if (reduxStoreService().getState().app.apiSaving === 0) {
      this.props.actions.apiRequestLogin();
    }
  };

  public componentDidMount() {
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        UnauthenticatedCacheManager.getInstance().loadLoginForm({
          props: this.props,
          isStateNavigated,
        });
      }
    );
  }

  public componentWillReceiveProps(nextProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        UnauthenticatedCacheManager.getInstance().loadLoginForm({
          props: nextProps,
          isStateNavigated,
        });
      }
    );
  }

  onClickCreateAccount = () => navPush(this.props.router, URLS.NEW_ACCOUNT);
  onForgotPassword = () => {
    reduxStoreService().dispatch(
      appActionCreators.pushModal(ModalTypes.RESET_PASSWORD, false, false, {resetPasswordContext: ResetPasswordContext.SEND_RESET_LINK}) as any
    );
  };

  getWelcomeCardTitle = () => {
    const {session: {SystemSettings}} = this.props;

    if (!SystemSettings) return '';

    let text = `Welcome to ${SystemSettings.CampName}'s`;
    if (SystemSettings.HasActiveEventTypes) text += ' event registration';
    if (SystemSettings.HasActiveEventTypes && SystemSettings.HasActiveFacilities) text += ' and';
    if (SystemSettings.HasActiveFacilities) text += ' facility booking';
    text += " system.";

    return text;
  };

  onFindOtherSystems = () => {
    window.open("https://users.tentaroo.com/findmycouncil");
  };

  onContactCouncil = () => {
    window.open(openSupportForm(true));
  };

  renderWelcomeCard = () => {
    const {session: {SystemSettings}} = this.props;

    if (!SystemSettings) return null;
    return (
      <Card template="mobile-no-margin">
        <Text marginBottom={16} size={16}>{this.getWelcomeCardTitle()}</Text>
        <BulletList marginBottom={0}>
          <BulletListItem className={`${namespace()}--list-item`} size={16}>
            <Text size={16} inline>Accounts are specific to each council's system. If you can't login, it may be because your login is for a different system.</Text>
          </BulletListItem>
          <Button className={`${namespace()}--welcome-button`} flat big onClick={this.onFindOtherSystems}>
            FIND OTHER SYSTEMS
          </Button>
          <BulletListItem className={`${namespace()}--list-item`} size={16}>
            <Text size={16} inline>For unit accounts, only one account is allowed per unit, per system. Please reach out to your unit leadership or council for access if you're unable to create a new unit account.</Text>
          </BulletListItem>
          <Button className={`${namespace()}--welcome-button`} flat big onClick={this.onContactCouncil}>
            CONTACT COUNCIL
          </Button>
        </BulletList>
      </Card>
    );
  };

  public render() {
    const { apiSavingMap, inert, apiSaving, login: {SubmitErrorMessage, ActiveForm, ValidationRules},
      session: {AllowSelfSignup, getLoginFormFailed, SystemSettings}, actions } = this.props;
    if (!SystemSettings) return <LoadingAll/>;
    if (getLoginFormFailed) return <NotFound title={GET_LOGIN_FAIL_TITLE} message={GET_LOGIN_FAIL_MESSAGE}/>;

    const isLoggingIn = apiSavingMap[LoginActions.requestType];
    const isUpdatingPassword = apiSavingMap[ResetPasswordUpdateSubmitActions.requestType];

    const loading = isLoggingIn || isUpdatingPassword;
    const disabled = apiSaving > 0;
    return (
      <Main
        inert={inert}
        isLoading={loading}
        /**
         * We specify this flag here because we only use `isLoading` to disable the component,
         * Then, we show the loader inside the Login form card
         */
        noLoaderWhenIsLoading={!isUpdatingPassword}
        footer={<Footer/>}
        header={<Media mobile className={`${namespace()}--logo` + (isLoggingIn ? ' faded' : '')}><Logo position="login" className={`${namespace()}--logo--logo`}/></Media>}
      >
        <MainContent verticalAlign="center" className={namespace()}>
          <section className={`${namespace()}--welcome-section`}>
            {this.renderWelcomeCard()}
          </section>
          <section className={`${namespace()}--login-section`}>
            <Card template="mobile-no-margin" className={`${namespace()}--card`}>
              {isLoggingIn && <div className={`${namespace()}--loader`}><Loader cover pop disablePointerEvents/></div>}
              {SubmitErrorMessage && <Alert>{SubmitErrorMessage}</Alert>}
              <Form
                disabled={disabled}
                onSubmit={this.handleSubmit}
                className={`${namespace()}--form` + (isLoggingIn ? ' hidden' : '')}
              >
                <TextField
                  id={generateDOMId("tentaroo-username")}
                  label="Username"
                  autocomplete="username"
                  value={ActiveForm.Username}
                  onChange={actions.simpleUpdate}
                  onBlur={actions.updateValue}
                  validationRules={ValidationRules.Username}
                  disabled={disabled}
                />
                <TextField
                  id={generateDOMId("tentaroo-password")}
                  label="Password"
                  type="password"
                  autocomplete="current-password"
                  value={ActiveForm.Password}
                  onChange={actions.simpleUpdate}
                  onBlur={actions.updateValue}
                  validationRules={ValidationRules.Password}
                  disabled={disabled}
                />
                <Button disabled={disabled} id={generateDOMId("tentaroo-login")} marginTop={16} submit color="green" textColor="white" big className={`${namespace()}--submit`}>Log In</Button>
              </Form>
              <View className={`${namespace()}--forgot-password`}>
                <Button disabled={disabled} id={generateDOMId("forgot-password-btn")} flat big onClick={this.onForgotPassword}>
                  Forgot Password?
                </Button>
              </View>
            </Card>
            {AllowSelfSignup && (<View className={`${namespace()}--account`}>
              <span className={`${namespace()}--account--text`}>Don’t have an account?</span>
              <Button disabled={disabled} big color="blue" textColor="white" onClick={this.onClickCreateAccount} className={`${namespace()}--account--button`}>Create Account</Button>
            </View>)}
          </section>
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  session: state.session,
  login: state.login,
  apiSaving: state.app.apiSaving,
  apiSavingMap: state.app.apiSavingMap,
  apiLoadingMap: state.app.apiLoadingMap,
  isRollbackJustFinished: state.rollback.isRollbackJustFinished,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...sessionActionCreators, ...loginActionCreators, ...appActionCreators, ...rollbackActionCreators }, dispatch)
});

const ConnectedLogin = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(Login);

export default ConnectedLogin;
export { default as LoginHeader } from './Header';
