import { CMSContact, CMSPage, CMSPageMenuItem, CMSResource, CMSSiteMenuItem } from "../../models/api/adminCMSCacheOne";
import { ClearAdminCMSSiteCacheBelowOne, ClearAllAdminCMSSiteCache } from "../../store/AdminCMSSite/CacheOne/actions";
import { AdminCMSSiteCacheTwoContactsState } from "../../store/AdminCMSSite/CacheTwoContacts";
import { ModalTypes, isModalOpened } from "../modalHelper";
import { safelySetEmptyCart } from "../cacheLoaders/cacheLoaderHelpers";
import CacheManager from "./cacheManager";
import { AdminCMSSiteCacheTwoPageState } from "../../store/AdminCMSSite/CacheTwoPages";
import { AdminCMSSiteCacheTwoPageMenuItemsState } from "../../store/AdminCMSSite/CacheTwoPageMenus";
import { AdminCMSSiteCacheTwoResourcesState } from "../../store/AdminCMSSite/CacheTwoResources";
import { AdminCMSSiteCacheTwoSiteMenuItemsState } from "../../store/AdminCMSSite/CacheTwoSiteMenus";
import { AdminCMSSiteCacheOneState } from "../../store/AdminCMSSite/CacheOne";
import { ClearAdminCMSCacheTwoResource } from "../../store/AdminCMSSite/CacheTwoResources/actions";
import { ClearAdminCMSCacheTwoContact } from "../../store/AdminCMSSite/CacheTwoContacts/actions";
import EndUserCacheManager from "./endUserCacheManager";
import { RouteComponentProps } from "react-router";
import { getContactIdFromPath, getMenuItemIdFromPath, getPageIdFromPath, getResourceIdFromPath, getSiteID, IAdminCMSContactRouterParams, IAdminCMSMenuRouterParams, IAdminCMSPageRouterParams, IAdminCMSResourceRouterParams, IAdminCMSRouterParams } from "../helpers/adminCMSPageHelper";
import { ensureCacheTwoResource } from "../cacheLoaders/adminCMSCacheTwoResourceLoader";
import { ensureCacheTwoContact } from "../cacheLoaders/adminCMSCacheTwoContactLoader";
import { ensureCacheTwoPage } from "../cacheLoaders/adminCMSCacheTwoPageLoader";
import { ensureCacheTwoPageMenuItem } from "../cacheLoaders/adminCMSCacheTwoPageMenuItemLoader";
import { ensureCacheTwoSiteMenuItem } from "../cacheLoaders/adminCMSCacheTwoSiteMenuItemLoader";
import { ensureAdminCMSCacheOne } from "../cacheLoaders/adminCMSCacheOneLoader";
import { getDataIdFromUrlOrModalProp } from "../dataHelper";
import {getGroupCache} from "../../constants/urls";
import {getCacheOneEmptyCartBody} from "../cacheLoaders/cacheLoaders";
import { reduxStoreService } from "../../store/service";
import {LoadAdminCMSCacheOneParams, LoadAdminCMSCacheTwoContactParams, LoadAdminCMSCacheTwoPageMenuItemParams, LoadAdminCMSCacheTwoPageParams, LoadAdminCMSCacheTwoResourceParams, LoadAdminCMSCacheTwoSiteMenuItemParams} from "../cacheLoaders/helpers/models";
import { AdminCMSCacheOneContext, AdminCMSCacheTwoContactContext, AdminCMSCacheTwoResourceContext } from "@tentaroo/shared";
import { PagesHomeReset } from "../../store/AdminCMSSite/Pages/Home/actions";
import { ContactsHomeReset } from "../../store/AdminCMSSite/Contacts/Home/actions";
import { MenusHomeReset } from "../../store/AdminCMSSite/Menus/Home/actions";
import { ResourcesHomeReset } from "../../store/AdminCMSSite/Resources/Home/actions";
import {isAdminCMSCacheOnePopulated, isAdminCMSCacheTwoContactPopulated, isAdminCMSCacheTwoPageMenuItemPopulated, isAdminCMSCacheTwoPagePopulated, isAdminCMSCacheTwoResourcePopulated, isAdminCMSCacheTwoSiteMenuItemPopulated} from "../cachePopulatedCheckers/adminCMS";

export default class AdminCMSCacheManager extends CacheManager {
  private static _instance: AdminCMSCacheManager;

  public static getInstance(): AdminCMSCacheManager {
    if (!this._instance) {
      this._instance = new AdminCMSCacheManager();
    }

    return this._instance;
  }

  constructor() {
    super();
  }

  // Cache One
  public initCacheOneListPage(
    context: AdminCMSCacheOneContext | AdminCMSCacheTwoContactContext | AdminCMSCacheTwoResourceContext,
  ) {
    if (context === AdminCMSCacheOneContext.PAGES_LIST) {
      reduxStoreService().dispatch(new PagesHomeReset());
    } else if (
      context === AdminCMSCacheOneContext.CONTACTS_LIST ||
      context === AdminCMSCacheTwoContactContext.CONTACT_PREVIEW
    ) {
      reduxStoreService().dispatch(new ContactsHomeReset());
    } else if (context === AdminCMSCacheOneContext.MENUS_LIST) {
      reduxStoreService().dispatch(new MenusHomeReset());
    } else if (
      context === AdminCMSCacheOneContext.RESOURCES_LIST ||
      context === AdminCMSCacheTwoResourceContext.RESOURCE_PREVIEW
    ) {
      reduxStoreService().dispatch(new ResourcesHomeReset());
    }
  }
  private isPopulatedAdminCMSCacheOneInvalid(cacheOne: AdminCMSSiteCacheOneState, siteId: number){
    if (this._isPopulatedCacheInvalid<AdminCMSSiteCacheOneState>(
      cacheOne,
      (cacheOne) => cacheOne.CMSSite ? cacheOne.CMSSite.ID : 0,
      siteId,
    )) {
      reduxStoreService().dispatch(new ClearAllAdminCMSSiteCache());

      return true;
    }

    return false;
  };

  public loadAdminCMSCacheOne(
    params: LoadAdminCMSCacheOneParams,
  ): boolean  {
    const {isStateNavigated, props} = params;

    // Only SetEmptyCart when first navigating to the any of the cache one pages
    if (isStateNavigated) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }

    if (this.cacheOneNeedsReload(
      props,
      getSiteID(props),
    ) || isStateNavigated) {
      return ensureAdminCMSCacheOne({
        ...params,
        cacheManager: this,
      });
    }

    return false;
  };

  private cacheOneNeedsReload(
    props: RouteComponentProps<IAdminCMSRouterParams, {}>,
    siteId: number,
  ) {
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const cacheOne = reduxStoreService().getState().adminCMSSite.cacheOne;
    const isCacheInvalid = this.isPopulatedAdminCMSCacheOneInvalid(cacheOne, siteId);
    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      !isAdminCMSCacheOnePopulated()
    );
  }

  // Contact
  private isPopulatedCacheTwoContactInvalid(
    adminCMSCacheTwoContact: AdminCMSSiteCacheTwoContactsState,
    siteId: number,
    contactId: number,
  ) {
    const cacheOne = reduxStoreService().getState().adminCMSSite.cacheOne;

    if (this.isPopulatedAdminCMSCacheOneInvalid(cacheOne, siteId)) return true;

    const contactModalOpened = isModalOpened(ModalTypes.CONTACT_FORM);
    const isInvalid = this._isPopulatedCacheInvalid<CMSContact>(
      adminCMSCacheTwoContact.CMSContact,
      (contact) => contact.ID,
      contactId,
      () => !contactModalOpened
    );

    if (isInvalid) {
      // when modal is not opened, we need to clear all caches below one
      if (!contactModalOpened) reduxStoreService().dispatch(new ClearAdminCMSSiteCacheBelowOne());
      // when modal is opened, we only need to clear cache two, since the modal might pop up in other cache two pages
      if (contactModalOpened) reduxStoreService().dispatch(new ClearAdminCMSCacheTwoContact());
    }

    return isInvalid;
  };

  public loadCacheTwoContact(
    params: LoadAdminCMSCacheTwoContactParams
  ): boolean {
    const {isEdit, props, contactIdFromModalProps, isStateNavigated} = params;
    // Non-optimistic saving - Only run loader if editing or isStateNavigated.
    // Prevent invalid check from running after add request, will have cache data
    // but be at the wrong URL before redirecting.
    if (!isEdit && !isStateNavigated) return false;

    const rootState = reduxStoreService().getState();
    const contactId = getDataIdFromUrlOrModalProp<IAdminCMSContactRouterParams>(
      getContactIdFromPath,
      props.params,
      contactIdFromModalProps,
    );

    // Only SetEmptyCart when first navigating to the contact form page
    if (isStateNavigated && !isModalOpened(ModalTypes.CONTACT_FORM)) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }

    if (this.cacheTwoContactNeedsReload(
      rootState.adminCMSSite.cacheTwoContacts,
      getSiteID(props),
      contactId,
      props,
      isEdit,
    ) || isStateNavigated) {
      return ensureCacheTwoContact({
        ...params,
        cacheManager: this,
        contactId,
      });
    }

    return false;
  };

  private cacheTwoContactNeedsReload(
    adminCMSCacheTwoContact: AdminCMSSiteCacheTwoContactsState,
    siteId: number,
    contactId: number,
    props: RouteComponentProps<IAdminCMSContactRouterParams, {}>,
    isEdit: boolean,
  ) {
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const isCacheInvalid = this.isPopulatedCacheTwoContactInvalid(adminCMSCacheTwoContact, siteId, contactId);

    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      (isEdit && !isAdminCMSCacheTwoContactPopulated(adminCMSCacheTwoContact))
    );
  };

  // Page
  private isPopulatedCacheTwoPageInvalid(
    cacheTwoPage: AdminCMSSiteCacheTwoPageState,
    siteId: number,
    pageId: number,
  ) {
    const cacheOne = reduxStoreService().getState().adminCMSSite.cacheOne;

    if (this.isPopulatedAdminCMSCacheOneInvalid(cacheOne, siteId)) return true;

    const isInvalid = this._isPopulatedCacheInvalid<CMSPage>(
      cacheTwoPage.CMSPage,
      (page) => page.ID,
      pageId,
    );

    if (isInvalid) {
      reduxStoreService().dispatch(new ClearAdminCMSSiteCacheBelowOne());
    }

    return isInvalid;
  };

  public loadCacheTwoPage(
    params: LoadAdminCMSCacheTwoPageParams,
  ): boolean {
    const {props, isStateNavigated, isEdit} = params;
    const rootState = reduxStoreService().getState();
    const pageId = getPageIdFromPath(props.params);

    // Only SetEmptyCart when first navigating to the page form page but
    // not due to rollback
    if (isStateNavigated && isEdit) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }
    if (this.cacheTwoPageNeedsReload(
      rootState.adminCMSSite.cacheTwoPage,
      getSiteID(props),
      pageId,
      props,
      isEdit,
    ) || isStateNavigated) {
      return ensureCacheTwoPage({
        ...params,
        cacheManager: this,
      });
    }

    return false;
  };

  private cacheTwoPageNeedsReload(
    cacheTwoPage: AdminCMSSiteCacheTwoPageState,
    siteId: number,
    pageId: number,
    props: RouteComponentProps<IAdminCMSPageRouterParams, {}>,
    isEdit: boolean,
  ) {
    const isCacheInvalid = this.isPopulatedCacheTwoPageInvalid(cacheTwoPage, siteId, pageId);
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      (isEdit && !isAdminCMSCacheTwoPagePopulated(cacheTwoPage))
    );
  }

  // Resource
  private isPopulatedCacheTwoResourceInvalid(
    cacheTwoResource: AdminCMSSiteCacheTwoResourcesState,
    siteId: number,
    resourceId: number,
  ) {
    const rootState = reduxStoreService().getState();
    const cacheOne = rootState.adminCMSSite.cacheOne;

    if (this.isPopulatedAdminCMSCacheOneInvalid(cacheOne, siteId)) return true;

    const resourceModalOpened = isModalOpened(ModalTypes.RESOURCE_FORM);
    const isInvalid =  AdminCMSCacheManager.getInstance()._isPopulatedCacheInvalid<CMSResource>(
      cacheTwoResource.CMSResource,
      (resource) => resource.ID,
      resourceId,
      () => !resourceModalOpened
    );

    if (isInvalid) {
      // when modal is not opened, we need to clear all caches below one
      if (!resourceModalOpened) reduxStoreService().dispatch(new ClearAdminCMSSiteCacheBelowOne());
      // when modal is opened, we only need to clear cache two, since the modal might pop up in other cache two pages
      else reduxStoreService().dispatch(new ClearAdminCMSCacheTwoResource());
    }

    return isInvalid;
  };

  public loadCacheTwoResource(
    params: LoadAdminCMSCacheTwoResourceParams
  ): boolean {
    const {isEdit, isStateNavigated, props, resourceIdFromModalProps} = params;
    // Non-optimistic saving - Only run loader if editing or isStateNavigated.
    // Prevent invalid check from running after add request, will have cache data
    // but be at the wrong URL before redirecting.
    if (!isEdit && !isStateNavigated) return false;
    
    const rootState = reduxStoreService().getState();
    const resourceId = getDataIdFromUrlOrModalProp<IAdminCMSResourceRouterParams>(
      getResourceIdFromPath,
      props.params,
      resourceIdFromModalProps,
    );

    // Only SetEmptyCart when first navigating to the resource form page but
    // not due to rollback
    if (isStateNavigated && !isModalOpened(ModalTypes.RESOURCE_FORM)) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    } 

    if (this.cacheTwoResourceNeedsReload(
      rootState.adminCMSSite.cacheTwoResources,
      getSiteID(props),
      resourceId,
      props,
      isEdit,
    ) || isStateNavigated) {
      return ensureCacheTwoResource({
        ...params,
        cacheManager: this,
        resourceId,
      });
    }

    return false;
  };

  private cacheTwoResourceNeedsReload(
    cacheTwoResource: AdminCMSSiteCacheTwoResourcesState,
    siteId: number,
    resourceId: number,
    props: RouteComponentProps<IAdminCMSResourceRouterParams, {}>,
    isEdit: boolean,
  ) {
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const isCacheInvalid = this.isPopulatedCacheTwoResourceInvalid(cacheTwoResource, siteId, resourceId);

    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      (isEdit && !isAdminCMSCacheTwoResourcePopulated(cacheTwoResource))
    );
  }

  // Site Menu
  private isPopulatedCacheTwoSiteMenuItemInvalid(
    cacheTwoSiteMenuItem: AdminCMSSiteCacheTwoSiteMenuItemsState,
    siteId: number,
    menuItemId: number,
  ) {
    const cacheOne = reduxStoreService().getState().adminCMSSite.cacheOne;

    if (this.isPopulatedAdminCMSCacheOneInvalid(cacheOne, siteId)) return true;

    const isInvalid = this._isPopulatedCacheInvalid<CMSSiteMenuItem>(
      cacheTwoSiteMenuItem.CMSSiteMenuItem,
      (siteMenuItem) => siteMenuItem.ID,
      menuItemId,
    );

    if (isInvalid) {
      reduxStoreService().dispatch(new ClearAdminCMSSiteCacheBelowOne());
    }

    return isInvalid;
  };

  public loadCacheTwoSiteMenuItem(
    params: LoadAdminCMSCacheTwoSiteMenuItemParams
  ): boolean {
    const {props, isStateNavigated, menuItemIdFromEditPageSideBar, parentContext, isEdit} = params;
    /**
     * This loader is different from others even it can still receive an `id` from component.
     * Here we do not unify the menuId like we did for PageMenuItem/Resource/Contact, etc., because
     * it is already distinguished by `parentContext` in both `cacheTwoSiteMenuItemNeedsReload` and 
     * `ensureCacheTwoSiteMenuItem`
     */

    const rootState = reduxStoreService().getState();
    const menuItemId = getMenuItemIdFromPath(props.params);

    // Only SetEmptyCart when first navigating to the site-menu form page but
    // not due to rollback, AND not in EditPage
    if (isStateNavigated && !parentContext) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }

    if (this.cacheTwoSiteMenuItemNeedsReload(
      rootState.adminCMSSite.cacheTwoSiteMenuItems,
      getSiteID(props),
      menuItemId,
      props,
      isEdit,
      parentContext,
    ) || isStateNavigated) {
      return ensureCacheTwoSiteMenuItem({
        ...params,
        cacheManager: this,
        menuItemIdFromEditPageSideBar,
      });
    }

    return false;
  };

  private cacheTwoSiteMenuItemNeedsReload(
    cacheTwoSiteMenuItem: AdminCMSSiteCacheTwoSiteMenuItemsState,
    siteId: number,
    menuItemId: number,
    props: RouteComponentProps<IAdminCMSMenuRouterParams | IAdminCMSPageRouterParams, {}>,
    isEdit: boolean,
    parentContext?: 'edit-page',
  ) {
    if (parentContext) return true;
    
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const isCacheInvalid = this.isPopulatedCacheTwoSiteMenuItemInvalid(cacheTwoSiteMenuItem, siteId, menuItemId);

    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      (isEdit && !isAdminCMSCacheTwoSiteMenuItemPopulated(cacheTwoSiteMenuItem))
    );
  }

  // Page Menu
  private isPopulatedCacheTwoPageMenuItemInvalid(
    adminCMSCacheTwoPageMenuItems: AdminCMSSiteCacheTwoPageMenuItemsState,
    siteId: number,
    menuItemId: number,
  ) {
    const cacheOne = reduxStoreService().getState().adminCMSSite.cacheOne;

    if (this.isPopulatedAdminCMSCacheOneInvalid(cacheOne, siteId)) return true;

    const isInvalid = this._isPopulatedCacheInvalid<CMSPageMenuItem>(
      adminCMSCacheTwoPageMenuItems.CMSPageMenuItem,
      (pageMenuItem) => pageMenuItem.ID,
      menuItemId,
    );

    if (isInvalid) {
      reduxStoreService().dispatch(new ClearAdminCMSSiteCacheBelowOne());
    }

    return isInvalid;
  };

  public loadCacheTwoPageMenuItem(
    params: LoadAdminCMSCacheTwoPageMenuItemParams,
  ): boolean {
    const {props, isStateNavigated, isEdit, pageMenuIdFromModalProps} = params;

    const rootState = reduxStoreService().getState();
    const pageMenuId = getDataIdFromUrlOrModalProp<IAdminCMSMenuRouterParams>(
      getMenuItemIdFromPath,
      props.params,
      pageMenuIdFromModalProps,
    );
    // Only SetEmptyCart when first navigating to the contact form page but
    // not due to rollback
    if (isStateNavigated && !isModalOpened(ModalTypes.PAGE_MENU_ITEM_FORM)) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }
    if (this.cacheTwoPageMenuItemNeedsReload(
      rootState.adminCMSSite.cacheTwoPageMenuItems,
      getSiteID(props),
      pageMenuId,
      props,
      isEdit
    ) || isStateNavigated) {
      return ensureCacheTwoPageMenuItem({
        ...params,
        cacheManager: this,
        pageMenuId,
      });
    }

    return false;
  };

  private cacheTwoPageMenuItemNeedsReload(
    adminCMSCacheTwoPageMenuItems: AdminCMSSiteCacheTwoPageMenuItemsState,
    siteID: number,
    menuItemId: number,
    props: RouteComponentProps<IAdminCMSMenuRouterParams, {}>,
    isEdit: boolean,
  ) {
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const isCacheInvalid = this.isPopulatedCacheTwoPageMenuItemInvalid(adminCMSCacheTwoPageMenuItems, siteID, menuItemId);
    
    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      (isEdit && !isAdminCMSCacheTwoPageMenuItemPopulated(adminCMSCacheTwoPageMenuItems))
    );
  }
}