import * as React from 'react';
import {default as AppHeader, Nav, NavItem, Props as AppHeaderProps} from '../../App/Header';
import Info from './Orders/Info';
import {URLS} from "../../../constants/urls";
import { generateDOMId } from '../../../utils/cypressHelper';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

export interface HeaderProps extends AppHeaderProps {
  title?: string;
}

type ConnectedProps = ReturnType<typeof mapStateToProps>;

export type headerOptions = {
  selected: string;
  back?: string;
  title?: string;
  search?: boolean;
  refresh?: boolean;
  info?: boolean;
};

export default (options: headerOptions) => {
  const Header = ({ title, cacheOne, ...props }: HeaderProps & ConnectedProps): React.ReactElement<HeaderProps> => {
    const { info } = options;
    let nav: React.ReactElement<any> | undefined = undefined;
    let availableCredit = 0;
    if (cacheOne.CartOrder) {
      availableCredit = cacheOne.CartOrder.AvailableCredit;
    }

    if (!options.back) {
      nav = (
        <Nav>
          <NavItem to={URLS.PROFILE} selected={options.selected === 'profile'}>Profile</NavItem>
          <NavItem id={generateDOMId("tab-my-group")} to={URLS.MY_ROSTER} selected={options.selected === 'my_roster'}>My Group</NavItem>
          <NavItem id={generateDOMId("tab-orders")} to={URLS.ORDER_HISTORY} selected={options.selected === 'order_history'}>Orders</NavItem>
        </Nav>
      );
    }
    return (
      <AppHeader
        {...props}
        cart
        nav={nav}
        back={options.back}
        title={title ? title : options.title}
        search={options.search}
        refresh={options.refresh}
        info={info ? <Info AvailableCredit={availableCredit}/> : undefined}
      />
    );
  };

  const ConnectedHeader = connect(
    mapStateToProps,
    null,
    getMergeProps<HeaderProps>(),
  )(Header);

  return ConnectedHeader;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    cacheOne: state.cacheOne
  };
};