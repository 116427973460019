import * as React from 'react';
import { Card, Row, Column, Text } from '../../../../Elements';
import {RouteComponentProps, withRouter} from "react-router";
import {PreviousOrder} from "../../../../../models/api/cacheTwoPrevOrders";
import {standardCurrencyFormat} from "../../../../../utils/classesHelper";
import {SIMPLE_FORMAT} from "../../../../../utils/dateHelper";
import { isPathnameMatchingRoute } from '../../../../../utils/urlHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { VIEW_ORDER_PATH } from '../../../../../routes';

export interface OrderCardProps {
  order: PreviousOrder;
  onClick: (order: PreviousOrder) => void;
  className?: string;
}

type ConnectedProps = RouteComponentProps<{},{}>;

class OrderCard extends React.Component<OrderCardProps,{}> {
  props: OrderCardProps & ConnectedProps;

  onClick = (e) => {
    e.stopPropagation();
    this.props.onClick(this.props.order);
  };

  render() {
    const {order, location} = this.props;

    return <Card
      id={generateDOMId(`prev-order-${order.OrderID}`)}
      template="mobile-stretch"
      padding="small"
      selected={isPathnameMatchingRoute(location.pathname, VIEW_ORDER_PATH, 'id', order.OrderID)}
      onClick={this.onClick}
    >
      <Row>
        <Column expand layout="vertical">
          <Text weight="medium" marginBottom={4}>{order.PaymentType}</Text>
        </Column>
        <Column verticalAlignment="top">
          <Text weight="medium">
            {standardCurrencyFormat(order.Amount)}
          </Text>
        </Column>
      </Row>
      <Row>
        {order.Notes ? <Text marginBottom={4} wrap={false}>{order.Notes}</Text> : null}
      </Row>
      <Row>
        <Text color="gray" size={14}>{order.DatePayment.format(SIMPLE_FORMAT)}</Text>
      </Row>
    </Card>;
  }
}

export default withRouter(OrderCard as any);
