import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";
import { FUTURE_DATE, MIN_DATE, DATE_PICKER_FORMAT } from '@tentaroo/shared';
import * as GENERIC_M from '../../constants/messages/generic';
import * as M from "../../constants/messages/createAccount";
import {NameAndID, PaymentType, PaymentTypes} from "../../models/api/options";
import {MAX_LENGTH, MIN_LENGTH} from "../../constants/messages/generic";
import { isPaymentTypeAllowed } from '.';
import { makeDeletedPrefixItemMapper, toNumber } from '../../utils/dataHelper';
import moment from 'moment';
import { SelectValidator, Validator } from "../../utils/validator/models";
import type { ApplicationState } from "..";

export interface IValidator {
  FirstName: Validator;
  LastName: Validator;
  NotApplied: Validator;
  Address: Validator;
  City: Validator;
  Zip: Validator;
  StateID: SelectValidator;
  PaymentType: SelectValidator;
  OrderDate: Validator;
  Notes: Validator;
  CCNum: Validator;
  CCCode: Validator;
  CCExpireMonth: SelectValidator;
  CCExpireYear: SelectValidator;
  AccountType: SelectValidator;
  BankName: Validator;
  NameOnAccount: Validator;
  RoutingNumber: Validator;
  ConfirmRoutingNumber: Validator;
  AccountNumber: Validator;
  ReceiptNumber: Validator;
  SendReceipt: Validator;
  UseCredit: Validator;
}

export const allowCCForm = (PaymentTypeID: number, Amount: number, NotApplied: number): boolean => {
  return ((PaymentTypeID === 2 || PaymentTypeID === 12) && (Amount !== 0 || NotApplied > 0));
};

export const alloweCheckForm = (PaymentTypeID: number, Amount: number, NotApplied: number): boolean => {
  return (PaymentTypeID === 3 && (Amount !== 0 || NotApplied > 0));
};

export const allowAtCouncilForm = (PaymentTypeID: number, Amount: number, NotApplied: number): boolean => {
  return ((PaymentTypeID === 4 || PaymentTypeID === 11) && (Amount !== 0 || NotApplied > 0));
};

const skip = (rootState: ApplicationState) => {
  const CartOrder = rootState.cacheOne.CartOrder;
  const ActiveForm = rootState.checkout.ActiveForm;
  const NotApplied = toNumber(ActiveForm.NotApplied);
  return !CartOrder || (CartOrder.Amount === 0 && NotApplied === 0);
};

const showCCForm = (rootState: ApplicationState) => {
  if (skip(rootState)) return false;
  
  const {PaymentType, NotApplied} = rootState.checkout.ActiveForm;
  if (!rootState.cacheOne.CartOrder || PaymentType === undefined || NotApplied === undefined) {
    return false;
  }
  const {Amount} = rootState.cacheOne.CartOrder;
  return allowCCForm(PaymentType, Amount, NotApplied);
};

const showeCheckForm = (rootState: ApplicationState) => {
  if (skip(rootState)) return false;
  
  const {PaymentType, NotApplied} = rootState.checkout.ActiveForm;
  if (!rootState.cacheOne.CartOrder || PaymentType === undefined || NotApplied === undefined) {
    return false;
  }
  const {Amount} = rootState.cacheOne.CartOrder;
  return alloweCheckForm(PaymentType, Amount, NotApplied);
};

const showAtCouncilForm = (rootState: ApplicationState) => {
  if (skip(rootState)) return false;
  
  const {PaymentType, NotApplied} = rootState.checkout.ActiveForm;
  if (!rootState.cacheOne.CartOrder || PaymentType === undefined || NotApplied === undefined) {
    return false;
  }
  const {Amount} = rootState.cacheOne.CartOrder;
  return allowAtCouncilForm(PaymentType, Amount, NotApplied);
};

const requireAddress = (rootState: ApplicationState) => {
  return showCCForm(rootState) || showeCheckForm(rootState);
};

const getValues = (rootState: ApplicationState, key) => {
  const opts = rootState.cacheZero.options;
  if (opts) return opts[key];
  return [];
};

export const FormDefinition: IValidator = {
  FirstName: {
    key: 'FirstName',
    isRequired: requireAddress,
    defaultValue: (rootState) => {
      const {GroupBillingAddress} = rootState.cacheOne;

      return !GroupBillingAddress ? undefined : GroupBillingAddress.FirstName;
    },
    validatejs: {
      FirstName: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 20,
          tooLong: MAX_LENGTH(20),
        }
      }
    },
  },
  LastName: {
    key: 'LastName',
    isRequired: requireAddress,
    defaultValue: (rootState) => {
      const {GroupBillingAddress} = rootState.cacheOne;

      return !GroupBillingAddress ? undefined : GroupBillingAddress.LastName;
    },
    validatejs: {
      LastName: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 50,
          tooLong: MAX_LENGTH(50),
        }
      }
    }
  },
  NotApplied: {
    key: 'NotApplied',
    decimalOnly: true,
    defaultValue: (rootState) => {
      const {CartOrder} = rootState.cacheOne;

      return CartOrder?.NotApplied ? CartOrder.NotApplied : 0;
    },
    validatejs: {
      NotApplied: {
        presence: {message: GENERIC_M.S_REQUIRED("Unapplied")},
        numericality: {
          notValid: GENERIC_M.S_INVALID("Unapplied"),
        },
      },
    },
  },
  Address: {
    key: 'Address',
    defaultValue: (rootState) => {
      return !rootState.cacheOne.GroupBillingAddress ? undefined : rootState.cacheOne.GroupBillingAddress.Address;
    },
    isRequired: requireAddress,
    skip: skip,
    validatejs: {
      Address: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 200,
          tooLong: MAX_LENGTH(200)
        }
      }
    }
  },
  City: {
    key: 'City',
    defaultValue: (rootState) => {
      return !rootState.cacheOne.GroupBillingAddress ? undefined : rootState.cacheOne.GroupBillingAddress.City;
    },
    isRequired: requireAddress,
    skip: skip,
    validatejs: {
      City: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 100,
          tooLong: MAX_LENGTH(100)
        }
      }
    }
  },
  Zip: {
    key: 'Zip',
    defaultValue: (rootState) => {
      return !rootState.cacheOne.GroupBillingAddress ? undefined : rootState.cacheOne.GroupBillingAddress.Zip;
    },
    isRequired: requireAddress,
    skip: skip,
    validatejs: {
      Zip: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 10,
          tooLong: MAX_LENGTH(10)
        }
      }
    }
  },
  StateID: {
    key: 'StateID',
    defaultValue: (rootState) => {
      return !rootState.cacheOne.GroupBillingAddress ? undefined : rootState.cacheOne.GroupBillingAddress.StateID;
    },
    isRequired: requireAddress,
    skip: skip,
    options: {
      values: (rootState) => [{ Name: '', ID: -1 }, ...getValues(rootState, 'StateOptions')],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: -1
    },
    validatejs: {
      StateID: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0
        }
      }
    }
  },
  PaymentType: {
    key: 'PaymentType',
    defaultValue: (rootState) => {
      return !rootState.cacheOne.CartOrder ? undefined : rootState.cacheOne.CartOrder.PaymentTypeID;
    },
    skip: skip,
    isRequired: (rootState) => !skip(rootState),
    options: {
      values: (rootState) => {
        const paymentTypes = rootState.cacheZero.options?.PaymentTypes;

        if (!paymentTypes) return [];
        const filteredPayments = paymentTypes.filter((paymentType: PaymentType) => {
          if (rootState.cacheOne.CartOrder && rootState.cacheOne.CartOrder.PaymentTypeID === paymentType.ID) {
            return true;
          }
          if (paymentType.Inactive) return false;

          return isPaymentTypeAllowed(rootState, paymentType);
        });
        return [...filteredPayments.map(makeDeletedPrefixItemMapper("Name"))];
      },
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: -1
    },
    customValidate: (rootState) => {
      if (skip(rootState)) return undefined;
      const isAdmin = rootState.user.user.str_permissions.hasAdminAccess;
      const {ActiveForm} = rootState.checkout;
      const NotApplied = toNumber(ActiveForm.NotApplied);

      if (!rootState.cacheOne.CartOrder) {
        // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
        return "";
      }
      if (!rootState.cacheZero.options) {
        // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
        return "";
      }
      if (!rootState.session.SystemSettings) {
        // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
        return "";
      }
      const Amount = rootState.cacheOne.CartOrder.Amount;
      const paymentTypeID = rootState.checkout.ActiveForm.PaymentType;
      const paymentTypes = rootState.cacheZero.options.PaymentTypes || [];
      const paymentType = paymentTypes.find((pt) => pt.ID === paymentTypeID);
      if (
        !rootState.session.SystemSettings.AllowCCPayment &&
        (paymentTypeID === 1 || paymentTypeID === PaymentTypes.CREDIT_CARD || paymentTypeID === PaymentTypes.REFUND_CC_REFUND) &&
        (Amount !== 0 || NotApplied > 0)
      ) {
        return M.NO_CC;
      }
      if (!rootState.session.SystemSettings.AllowECheckPayment && (paymentTypeID === PaymentTypes.ECHECK)) {
        return M.NO_ECHECK;
      }
      if (!!paymentType && paymentType.IsAdminOnly && !isAdmin) {
        return M.DIFF_PAYMENT_TYPE;
      }
      if (paymentTypeID === undefined || paymentTypeID < 0) {
        return GENERIC_M.REQUIRED;
      }

      if (!!paymentType && paymentType.Inactive) {
        return GENERIC_M.SELECTED_DROPDOWN_ITEM_DELETED;
      }
      return undefined;
    },
    localDependants: ['CCNum', 'CCCode', 'CCExpireMonth', 'CCExpireYear', 'AccountType', 'BankName', 'NameOnAccount', 'RoutingNumber', 'AccountNumber', 'ReceiptNumber'],
    validatejs: {
      PaymentType: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        numericality: {
          greaterThan: 0,
          notGreaterThan: SELECT_NOT_GREATER_THAN,
        }
      }
    }
  },
  OrderDate: {
    key: 'OrderDate',
    defaultValue: () => moment(),
    validatejs: {
      OrderDate: {
        datetime: {
          earliest: MIN_DATE.format(DATE_PICKER_FORMAT),
          latest: FUTURE_DATE.format(DATE_PICKER_FORMAT),
          notValid: `^${GENERIC_M.INVALID_DATE}`,
        }
      }
    },
  },
  Notes: {
    key: 'Notes',
    skip: skip,
    validatejs: {
      Notes: {
        length: {
          maximum: 1024,
          tooLong: MAX_LENGTH(1024)
        }
      }
    }
  },
  CCNum: {
    key: 'CCNum',
    integerOnly: true,
    skip: (rootState) => !showCCForm(rootState),
    isRequired: showCCForm,
    validatejs: {
      CCNum: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          minimum: 17, // should be 15, but credit card component is a string that returns spaces and there's max 3 spaces
          tooShort: '^Min length of 15 characters required.',
          maximum: 19, // should be 16, but credit card component is a string that returns spaces and there's max 3 spaces
          tooLong: MAX_LENGTH(16)
        }
      }
    }
  },
  CCCode: {
    key: 'CCCode',
    integerOnly: true,
    skip: (rootState) => !showCCForm(rootState),
    isRequired: showCCForm,
    validatejs: {
      CCCode: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          minimum: 3,
          tooShort: MIN_LENGTH(3),
          maximum: 4,
          tooLong: MAX_LENGTH(4)
        }
      }
    }
  },
  CCExpireMonth: {
    key: 'CCExpireMonth',
    integerOnly: true,
    skip: (rootState) => !showCCForm(rootState),
    isRequired: showCCForm,
    options: {
      values: () => [
        { Name: '', ID: -1 },
        { Name: '01', ID: 1 },
        { Name: '02', ID: 2 },
        { Name: '03', ID: 3 },
        { Name: '04', ID: 4 },
        { Name: '05', ID: 5 },
        { Name: '06', ID: 6 },
        { Name: '07', ID: 7 },
        { Name: '08', ID: 8 },
        { Name: '09', ID: 9 },
        { Name: '10', ID: 10 },
        { Name: '11', ID: 11 },
        { Name: '12', ID: 12 }
      ],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: -1
    },
    defaultValue: () => -1,
    validatejs: {
      CCExpireMonth: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: -1
        }
      }
    }
  },
  CCExpireYear: {
    key: 'CCExpireYear',
    integerOnly: true,
    skip: (rootState) => !showCCForm(rootState),
    isRequired: showCCForm,
    options: {
      values: () => {
        const years = [{ Name: '', ID: -1 }];
        const currentYear = (new Date()).getFullYear();
        for (let i = currentYear; i < currentYear + 11; i++) {
          years.push({ Name: `${i % 1000}`, ID: i});
        }
        return years;
      },
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: -1
    },
    defaultValue: () => -1,
    validatejs: {
      CCExpireYear: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: -1
        }
      }
    }
  },
  AccountType: {
    key: 'AccountType',
    options: {
      values: (rootState) => [...getValues(rootState, 'ABAAccountTypes')],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: -1
    },
    defaultValue: (rootState) => getValues(rootState, 'ABAAccountTypes')[0].ID,
    skip: (rootState) => !showeCheckForm(rootState),
    isRequired: showeCheckForm,
    validatejs: {
      AccountType: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: -1
        }
      }
    }
  },
  BankName: {
    key: 'BankName',
    skip: (rootState) => !showeCheckForm(rootState),
    isRequired: showeCheckForm,
    defaultValue: () => '',
    validatejs: {
      BankName: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 50,
          tooLong: MAX_LENGTH(50)
        }
      }
    }
  },
  NameOnAccount: {
    key: 'NameOnAccount',
    skip: (rootState) => !showeCheckForm(rootState),
    isRequired: showeCheckForm,
    defaultValue: () => '',
    validatejs: {
      NameOnAccount: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 50,
          tooLong: MAX_LENGTH(50)
        }
      }
    }
  },
  RoutingNumber: {
    key: 'RoutingNumber',
    skip: (rootState) => !showeCheckForm(rootState),
    isRequired: showeCheckForm,
    defaultValue: () => '',
    integerOnly: true,
    validatejs: {
      RoutingNumber: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          minimum: 9,
          tooShort: '^' + M.ROUTING_NUMBER_LENGTH,
          maximum: 9,
          tooLong: '^' + M.ROUTING_NUMBER_LENGTH,
        }
      }
    }
  },
  ConfirmRoutingNumber: {
    key: 'ConfirmRoutingNumber',
    skip: (rootState) => !showeCheckForm(rootState),
    isRequired: showeCheckForm,
    defaultValue: () => '',
    integerOnly: true,
    validatejs: {
      ConfirmRoutingNumber: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        equality: {
          attribute: "RoutingNumber",
          message: '^' + M.ROUTING_NUMBER_MATCH
        },
        length: {
          minimum: 9,
          tooShort: '^' + M.ROUTING_NUMBER_LENGTH,
          maximum: 9,
          tooLong: '^' + M.ROUTING_NUMBER_LENGTH
        }
      }
    }
  },
  AccountNumber: {
    key: 'AccountNumber',
    integerOnly: true,
    skip: (rootState) => !showeCheckForm(rootState),
    isRequired: showeCheckForm,
    defaultValue: () => '',
    validatejs: {
      AccountNumber: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        length: {
          maximum: 20,
          tooLong: MAX_LENGTH(20)
        }
      }
    }
  },
  // This is not required
  ReceiptNumber: {
    key: 'ReceiptNumber',
    defaultValue: () => '',
    validatejs: {
      ReceiptNumber: {
        length: {
          maximum: 50,
          tooLong: MAX_LENGTH(50)
        }
      }
    }
  },
  SendReceipt: {
    key: 'SendReceipt',
    defaultValue: () => true,
  },
  UseCredit: {
    key: 'UseCredit',
    defaultValue: (rootState) => {
      return !rootState.cacheOne.CartOrder ? undefined : rootState.cacheOne.CartOrder.UseCredit;
    },
  },
};