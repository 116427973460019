import * as React from 'react';
import { ContextMenu, ContextMenuItem } from '../..';
import { generateDOMId } from '../../../../utils/cypressHelper';
import { Props as ContextMenuProps } from '../../ContextMenu/index';

interface Props {
  isParent?: boolean;
  isChild?: boolean;
  IsRegisterUnderIndividuals?: boolean;
  IsUnscheduled?: boolean;
  onEdit: (e) => void;
  onExportInstructorClassRoster: (e) => void;
  onEnterRequirementsCompleted: (e) => void;
  onDelete: (e) => void;
  classIDi: number;
}

const ContextMenuComponent: React.SFC<ContextMenuProps & Props> = (props: ContextMenuProps & Props): React.ReactElement<ContextMenuProps & Props> => (
  <ContextMenu
    {...props}
    consistentInMobile
    title=""
  >
    {(props.isParent === true || (!props.isParent && !props.isChild)) && !props.IsUnscheduled && <ContextMenuItem admin onClick={props.onEdit}>Edit</ContextMenuItem>}
    {(props.isChild === true || (!props.isParent && !props.isChild)) && props.IsRegisterUnderIndividuals && <ContextMenuItem id={generateDOMId(`export-instructor-class-roster-${props.classIDi}`)} admin onClick={props.onExportInstructorClassRoster}>Export Instructor Class Roster</ContextMenuItem>}
    {(props.isChild === true || (!props.isParent && !props.isChild)) && props.IsRegisterUnderIndividuals && <ContextMenuItem admin onClick={props.onEnterRequirementsCompleted}>Enter Requirements Completed</ContextMenuItem>}
    {(props.isParent === true || (!props.isParent && !props.isChild)) && !props.IsUnscheduled && <ContextMenuItem id={generateDOMId(`admin-class-${props.classIDi}-delete`)} admin onClick={props.onDelete}>Delete</ContextMenuItem>}
  </ContextMenu>
);

export default ContextMenuComponent;
