import * as React from 'react';
import {Button, Modal, ModalActions, ModalContent, ModalHeader, Switch} from '../../../Elements';
import Paragraph from "../../../Elements/Paragraph";
import {actionCreators, ChangePaymentTypeActions} from "../../../../store/Checkout/actions";
import {actionCreators as appActionCreators} from "../../../../store/App/actions";
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../../../store';
import { EventParticipantsSave } from '../../../../store/CacheFourEventsParticipants/actions';
import { ModalTypes } from '../../../../utils/modalHelper';
import { captureTentarooError } from '../../../../utils/dataHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';
import {WithInertAttribute} from '../../../Elements/WithInert';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export type Props = WithInertAttribute<{}>

class CheckoutMessagesModal extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  render() {
    const { checkout: {CheckoutMessages, agreed, currCheckoutIndex}, app: {apiSavingMap}, inert} = this.props;

    const onCancel = () => {
      this.props.actions.cancelCheckout();
      this.props.actions.popModal(false, true, ModalTypes.CHECKOUT_MESSAGES);
    };

    const onClose = () => {
      this.props.actions.cancelCheckout();
    };

    const onAgree = () => {
      this.props.actions.toggleAgreeMessage();
    };

    const onNext = () => {
      this.props.actions.continueCheckout(checkoutMessage, agreed);
    };

    const onFinish = () => {
      this.props.actions.finishCheckout(checkoutMessage, agreed);
    };


    if (currCheckoutIndex === undefined) {
      captureTentarooError(new Error("currCheckoutIndex is undefined when opening CheckoutMessagesModal"));
      return null;
    }
    
    if (!CheckoutMessages) {
      captureTentarooError(new Error("CheckoutMessages is not available when opening CheckoutMessagesModal"));
      return null;
    }
    
    if (currCheckoutIndex >= CheckoutMessages.length) {
      captureTentarooError(new Error("currCheckoutIndex >= CheckoutMessages.length when opening CheckoutMessagesModal"));
      return null;
    }

    const checkoutMessage = CheckoutMessages[currCheckoutIndex];
    const isLast = currCheckoutIndex === CheckoutMessages.length - 1;
    const isLoading = apiSavingMap[ChangePaymentTypeActions.requestType] || apiSavingMap[EventParticipantsSave.requestType];

    return (
      <Modal inert={inert} disableDynamicHeight big mobileFullScreen onClose={onClose}>
        <ModalHeader>{checkoutMessage.Title}</ModalHeader>
        <ModalContent hasPadding scrollable>
          <Paragraph preWrap>{checkoutMessage.Message}</Paragraph>
          {checkoutMessage.ShowAgreement && <Switch
            noMarginTop
            label={checkoutMessage.AgreementMessage}
            onChange={onAgree}
            value={!!agreed}
          />}
        </ModalContent>
        <ModalActions
          loading={isLoading}
          sticky
          notFixed
          left={<Button flat textColor="black" onClick={onCancel}>CANCEL</Button>}
          right={<Button textColor="green" flat onClick={isLast ? () => onFinish() : () => onNext()}>CONTINUE</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    checkout: state.checkout,
    app: state.app,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actionCreators, ...appActionCreators }, dispatch)
});
const ConnectedCheckoutMessagesModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(CheckoutMessagesModal);

export default ConnectedCheckoutMessagesModal;
