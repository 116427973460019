import { ActionCreator, ApplicationState } from "../../../..";
import {createApiValidateActions, createValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod, createApiSubmitActions, innerApiSubmitFormMethod} from '../../../../Validation/actionCreator';
import { validate } from "../../../../../utils/validator";
import { actionCreators as messageFormActionCreators } from '../../../MessageCenter/Form/actions';
import {FormDefinition} from './validation';
import { PlainRoute } from "react-router";
import { typeName, Action } from "../../../../../utils/StrongActions";

export const SUFFIX = '__ADMIN_EVENTS_SEND_MESSAGE_MODAL';

@typeName("FILTER" + SUFFIX)
export class SendMessageModalFilter extends Action {
  constructor(public filterString: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class SendMessageToggleFilterTabs extends Action {
  constructor(public searching: boolean) { super(); }
}

@typeName('ADD_GROUPS' + SUFFIX)
export class AddGroups extends Action {
  constructor(public groupsIds: number[]) { super(); }
}

@typeName('REMOVE_GROUP' + SUFFIX)
export class RemoveGroup extends Action {
  constructor(public groupId: number) { super(); }
}

@typeName("RESET" + SUFFIX) export class ResetSendMessageModal extends Action {}

@typeName("INIT_STEP_TWO" + SUFFIX) export class InitStepTwo extends Action {}

@typeName(`CLEAR_ERROR${SUFFIX}`) export class ClearError extends Action {}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export const SendMessageActions = createApiSubmitActions(SUFFIX);

export type Actions = typeof actionCreators;

const validateSelectedGroups = (dispatch, state: ApplicationState) => {
  const modal = state.adminEvents.messageCenter.modals.sendMessage;
  dispatch(ValidateActions.updateCurr(modal.ActiveForm.SelectedGroups, FormDefinition.SelectedGroups.key, FormDefinition.SelectedGroups));
  let validationResults = validate(FormDefinition.SelectedGroups, modal.ActiveForm.SelectedGroups, (s: ApplicationState) => s.adminEvents.messageCenter.modals.sendMessage);
  dispatch(ValidateActions.update(validationResults, FormDefinition.SelectedGroups.key));

  if (!validationResults.Errors || !validationResults.Errors.SelectedGroups) {
    dispatch(new ClearError());
  }
};

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.messageCenter.modals.sendMessage, true, undefined, true),
  updateValueWithoutSave: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.messageCenter.modals.sendMessage),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  filterMessages: (filterString: string): ActionCreator => dispatch => dispatch(new SendMessageModalFilter(filterString)),
  reset: (): ActionCreator => dispatch => dispatch(new ResetSendMessageModal()),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new SendMessageToggleFilterTabs(searching)),
  addGroups: (groupIds: number[]): ActionCreator => (dispatch, getState) => {
    dispatch(new AddGroups(groupIds));
    const state = getState() as ApplicationState;
    validateSelectedGroups(dispatch, state);
  },
  removeGroup: (groupId: number): ActionCreator => (dispatch, getState) => {
    dispatch(new RemoveGroup(groupId));
    const state = getState() as ApplicationState;
    validateSelectedGroups(dispatch, state);
  },
  initStep2: (): ActionCreator => dispatch => dispatch(new InitStepTwo()),
  submit: (router: any, routes: PlainRoute<any>[]): ActionCreator => (dispatch, getState) => {
    const valid = innerApiSubmitFormMethod(
      dispatch,
      SendMessageActions,
      (s: ApplicationState) => s.adminEvents.messageCenter.modals.sendMessage,
      undefined,
      undefined,
      true,
      true,
    );

    if (valid) {
      dispatch(messageFormActionCreators.apiSubmitForm(router, routes, false, true) as any);
    }
  },
};