import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra
} from '../../../Validation/actionCreator';
import { ActionCreator, ApplicationState } from '../../..';
import { getUpdateLocationBody } from '../../../../constants/urls';
import { ToggleModalSaving } from '../../../App/actions';
import { scrollModalToTop } from '../../../../utils/helpers/adminCMSPageHelper';
import { actionCreators as appActionCreators } from '../../../App/actions';
import { ClearAdminSettingsCacheTwoLocation, UPDATE_LOCATION_FORM_SUFFIX } from '../../CacheTwoLocation/actions';
import { ModalTypes, isModalOpened } from '../../../../utils/modalHelper';

export const SUFFIX = '__LOCATION_FORM' + UPDATE_LOCATION_FORM_SUFFIX;
export const DEL_SUFFIX = '__DELETE_LOCATION' + UPDATE_LOCATION_FORM_SUFFIX;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const UpdateLocationSubmitActions = createApiSubmitActions(SUFFIX);

export const LocationFormModalNamespace = (): string => 'pages--cms--modals--location-form';


export type Actions = typeof actionCreators;
export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminSettings.modals.locationForm),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  apiSubmitLocationForm: (): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const locationFormModalOpened = isModalOpened(ModalTypes.LOCATION_FORM);
    
    const cacheLevelExtra = getCacheLevelExtra(
      true,
      !!state.adminSettings.cacheTwoLocation.Location,
    );

    // form validation
    const valid = innerApiSubmitFormMethod(
      dispatch,
      UpdateLocationSubmitActions,
      (s: ApplicationState) => s.adminSettings.modals.locationForm,
      undefined,
      undefined,
      locationFormModalOpened,
      true,
      undefined,
      undefined,
    );

    if (valid) {
      const form = getUpdateLocationBody({}, state.adminSettings.modals.locationForm.ActiveForm);

      if (locationFormModalOpened) {
        dispatch(appActionCreators.popModal(false, false, ModalTypes.LOCATION_FORM) as any);
        dispatch(new ToggleModalSaving(true));
      }
      dispatch(UpdateLocationSubmitActions.request(
          form,
          cacheLevelExtra,
        )
      );

      if (locationFormModalOpened && !!state.adminSettings.cacheTwoLocation.Location) {
        dispatch(new ClearAdminSettingsCacheTwoLocation());
      }

      return true;
    } else {
      if (locationFormModalOpened) {
        scrollModalToTop(LocationFormModalNamespace());
      }
      return false;
    }
  },
};