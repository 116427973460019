import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/paragraph/index.scss';
import { default as Text, TextProps } from '../Text';

export const namespace = (): string => 'elements--paragraph';

export interface ParagraphProps extends TextProps, React.ClassAttributes<any> {
  marginBottom?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  preWrap?: boolean;
}

const Paragraph: React.SFC<ParagraphProps> = ({ marginBottom, preWrap, ...props }: ParagraphProps): React.ReactElement<ParagraphProps> => {
  let className = mergeClassNames(namespace(), props);

  const styles: any = {};
  if (marginBottom) styles.marginBottom = `${marginBottom}px`;
  if (preWrap) className += ' pre-wrap';

  const nextProps = { ...props };
  delete nextProps.className;
  return <Text {...nextProps as any} component={<p/>} className={className} style={styles}/>;
};

export default Paragraph;
