import type { ApplicationState } from "..";
import { SEQUENTIAL_SUBMIT_REQUEST } from "../Validation/actionCreator";

export const hasOngoingSequentialRequest = (rootState: ApplicationState) => {
  const app = rootState.app;

  let hasRequests = 0;
  for (let key of Object.keys(app.apiSavingMap)) {
    if (key.includes(SEQUENTIAL_SUBMIT_REQUEST) && app.apiSavingMap[key] > 0) {
      hasRequests += app.apiSavingMap[key];
    } 
  }

  return hasRequests;
};

export const hasQueuedReduxActions = (rootState: ApplicationState) => {
  let hasActions = false;
  for (let key of Object.keys(rootState.app.queuedActionsMap)) {
    if (rootState.app.queuedActionsMap[key] > 1) {
      hasActions = true;
    }
  }
  
  return hasActions;
};