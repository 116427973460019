import * as React from 'react';
import { RegisterHeaderProgress, RegisterHeaderProgressStep } from '../../../../Elements';
import {isUrlAddReservation} from "../../../../../store/Facilities/Trip/Reservation";
import {RouteComponentProps, withRouter} from 'react-router';

export interface HeaderProgressProps {
  selected: 'type' | 'reservation' | 'confirmation';
  tabs?: React.ReactNode;
}

const HeaderProgress: React.SFC<HeaderProgressProps> = ({ selected, tabs, routes }: HeaderProgressProps & RouteComponentProps<{}, {}>): React.ReactElement<HeaderProgressProps> => {
  const route = routes[routes.length - 1];
  return (
    <RegisterHeaderProgress tabs={tabs}>
      {isUrlAddReservation(route.path!) && <RegisterHeaderProgressStep selected={selected === 'type'}>Facility</RegisterHeaderProgressStep>}
      <RegisterHeaderProgressStep selected={selected === 'reservation'}>Reservation</RegisterHeaderProgressStep>
      <RegisterHeaderProgressStep selected={selected === 'confirmation'}>Confirmation</RegisterHeaderProgressStep>
    </RegisterHeaderProgress>
  );
};

export default withRouter<HeaderProgressProps>(HeaderProgress);
