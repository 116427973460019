import {
  FAC_TRIP_ADD,
  FAC_TRIP_RES_ADD,
  FAC_TRIP_RESERVATION,
  FAC_TRIP_RESERVATION_EDIT,
  getFacilitiesAvailitiesC4,
  getFacilityReservation, URLS
} from '../../constants/urls';
import {makeDebounceEpic, makeRequestEpic} from "../Validation/epicCreator";
import {FacilitiesCalc, FacilitiesRecalc, FacilitiesSave, FacilitiesTypeC4} from "./actions";
import {Observable} from "rxjs/Rx";
import {FacilitiesReservationTransitionToPending} from "../Facilities/Trip/Reservation/actions";
import {NoOp, ShowSnackbarItem, ShowTopFloatingAlert} from "../App/actions";
import {replace} from "react-router-redux";
import {ReservationChange} from "../Facilities/Trip/Reservation/Reservation/actions";
import {TripReservation} from "../../models/api/cacheThreeFacilities";
import {getMessageFromErrorResponse} from "../../utils/stringHelper";
import {ClearCacheBelowThreeFacilities} from "../CacheThreeFacilities/actions";
import { OverrideReservationChange } from '../Facilities/Trip/Modals/ReservationOverrideFee/actions';
import { reduxStoreService } from '../service';

export const cacheFourFacilitiesEpic = makeRequestEpic(
  FacilitiesCalc,
  getFacilityReservation,
  undefined, // body is passed in as is in action.value
  true
);

export const cacheFourFacilityAvailabilitiesEpic = makeDebounceEpic(
  FacilitiesTypeC4,
  getFacilitiesAvailitiesC4,
  v => v // body is passed in as is in action.value
);

export const cacheFourFacilityRecalcsEpic = makeRequestEpic(
  FacilitiesRecalc,
  getFacilityReservation,
  undefined, // body is passed in as is in action.value
  true
);

export const cacheFourFacilitiesRecalcSuccessEpic = action$ => {
  return action$.filter((a) => a.type === FacilitiesRecalc.successType || a.type === ReservationChange.successType)
    .mergeMap((action) => {
      return Observable.of(new FacilitiesReservationTransitionToPending());
    });
};

export const cacheFourFacilitiesFailureEpic = action$ => {
  return action$.filter((a) => (
    a.type === FacilitiesSave.failureType || a.type === FacilitiesRecalc.failureType ||
    a.type === ReservationChange.failureType || a.type === FacilitiesCalc.failureType ||
    a.type === OverrideReservationChange.failureType
  ))
    .mergeMap((action) => {
      const route = reduxStoreService().getState().routing.locationBeforeTransitions;

      /**
       * NOTE: We set `newPath` here mainly for the case when we submit the
       * request _from wizard_, in that case, if the request failed due to
       * 400/409, we want to exit the wizard and navigate to the parent cache
       * level page. In other words, this is not a rollback location case
       * 
       * If `newPath` is empty, it means the request was NOT submitted
       * from wizard, our rollback should handle location change as expected
       * 
       * Similarly, if request failed other than 400/409, for example,
       * due to offline, we do want to rollback to the wizard submit screen
       */
      let newPath = '';
      const s = route.pathname.split('/');
      if (route.pathname.includes(`/${FAC_TRIP_RESERVATION}/`)) {
        newPath = route.pathname.split(`/${FAC_TRIP_RESERVATION}/`)[0];
      } else if (route.pathname.includes(`/${FAC_TRIP_RESERVATION_EDIT}`)) {
        newPath = route.pathname.split(`/${FAC_TRIP_RESERVATION_EDIT}`)[0];
      }
      if (action.response.status === 400) {
         if (action.response.xhr.response.error.Detail === 'Incorrect Payment') {
           if (newPath) {
             return Observable.merge(
               Observable.of(replace(`${newPath}${route.search}`)),
               Observable.of(new ShowSnackbarItem(getMessageFromErrorResponse(action.response)))
             );
           }
          return Observable.merge(
            Observable.of(new ShowSnackbarItem(getMessageFromErrorResponse(action.response)))
          );
        } else if (action.response.xhr.response.error.Detail === 'Unavailable Dates') {
           const path = reduxStoreService().getState().routing.locationBeforeTransitions.pathname;
           const sp = path.split('/');
           if (sp.length === 9 && sp[6] === FAC_TRIP_RESERVATION && sp[8] !== FAC_TRIP_RESERVATION_EDIT && sp[8] !== FAC_TRIP_ADD) {
             // then we on view reservation
             return Observable.merge(
               Observable.of(new ClearCacheBelowThreeFacilities()),
               Observable.of(new ShowTopFloatingAlert(getMessageFromErrorResponse(action.response), false, 'orange'))
             );
           } else if (sp.length === 6 && path.startsWith(URLS.FACILITY_TRIP)) {
             // then we on reservation summary
             return Observable.merge(
               Observable.of(new ClearCacheBelowThreeFacilities()),
               Observable.of(new ShowTopFloatingAlert(getMessageFromErrorResponse(action.response), false, 'orange'))
             );
           }
           return Observable.merge(
             Observable.of(ReservationChange.clientFailure({
               Errors: {
                 apiError: [action.response.xhr.response.error.Message]
               }
             }, true))
             // Observable.of(new ShowSnackbarItem(getMessageFromErrorResponse(action.response)))
           );
         }
      } else if (action.response.status === 409 && newPath) {
        return Observable.merge(
          Observable.of(replace(`${newPath}${route.search}`)),
        );
      }

      return Observable.of(new NoOp());
    });
};


export const cacheFourFacilitiesSaveEpic = makeRequestEpic(
  FacilitiesSave,
  getFacilityReservation,
  undefined, // body is passed in as is in action.value
  true,
  (payload, action) => {
    let resId;
    if (action.value && action.value.ReservationID) {
      resId = action.value.ReservationID;
    } else if (action.form && action.form.ReservationID) {
      resId = action.form.ReservationID;
    }
    if (resId && payload && payload.response) {
      const {TripReservations} = payload.response;
      let tripReservation;
      if (TripReservations) {
        tripReservation = TripReservations.find((trip: TripReservation) => {
          return trip.ID === resId;
        });
      }
      if (!tripReservation) {
        return 'Reservation has been removed.';
      } else if (tripReservation && tripReservation.InCart === -1) {
        return 'Removal of reservation has been added to your cart.';
      } else if (tripReservation && tripReservation.InCart === 1) {
        return 'Changes to reservation have been added to your cart.';
      } else {
        return 'Changes to reservation have been saved.';
      }
    } else {
      return 'New reservation has been added to your cart.';
    }
  }
);
