import * as React from 'react';
import { GroupIcon } from '../../../Icons';
import Arrow from '../../../../images/app/header/account/arrow.svg';
import { namespace as ns } from '../constants';
import '../../../../styles/app/nav/account/index.scss';
import { isMobile } from '../../../../utils/isMobile';
import { Button } from 'react-mdl';
import { Ellipsis } from '../../../Elements';

export const namespace = (): string => `${ns()}--account`;

interface AccountProps {
  onClick?: React.EventHandler<any>;
  selectedClient?: string;
  disabled?: boolean;
}

const Account: React.SFC<AccountProps> = ({ onClick, selectedClient, disabled, ...props }): React.ReactElement<AccountProps> => (
  <div className={`${namespace()} ${disabled ? 'disabled' : ''}`}>
    <Button ripple={!isMobile} className={`${namespace()}--button`} onClick={onClick}>
      <GroupIcon className={`${namespace()}--button--icon`}/>
      <span className={`${namespace()}--button--text ${selectedClient ? '' : 'not-selected'}`}>
        <Ellipsis
          configs={{
            lines: 2,
            text: selectedClient ? selectedClient : 'Select client'
          }}
          isStatic
        />
      </span>
      <Arrow className={`${namespace()}--button--arrow`}/>
    </Button>
  </div>
);

export default Account;
