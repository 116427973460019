import {SaveState} from "../../store/Rollback/actions";
import {EventRegistrationPerson} from "../../models/api/cacheFourEvents";
import { getGroupID } from "../helpers/endUserPageHelper";
import { captureTentarooError } from "../dataHelper";
import { reduxStoreService } from "../../store/service";
import { LoadCancelRegistrationParams } from "./helpers/models";
import {isCacheFourEventsParticipantsPopulated} from "../cachePopulatedCheckers/endUser";

export const ensureCancelRegistration = (
  params: LoadCancelRegistrationParams,
) => {
  const {props, actions, IsYouth, groupWeekIDi, ParticipantIDi, eventId, eventTypeId, isRestore, isStateNavigated} = params;

  const rootState = reduxStoreService().getState();
  const {
    cacheZero, cacheFourEventsParticipants, cacheThreeEvents,
  } = rootState;
  if (isCacheFourEventsParticipantsPopulated(cacheFourEventsParticipants)) {
    if (isStateNavigated) {
      reduxStoreService().dispatch(new SaveState());
    }
  } else {

    const groupID = getGroupID(props);

    // you're opening restore/delete wizard
    if (!groupWeekIDi) {
      captureTentarooError(new Error('if youre recalcing, you must have groupWeekIDi'));
      return;
    }

    if (ParticipantIDi ) {
      // its cart, checkout or /registration
      actions.participantsRecalc({
        GroupIDi: groupID,
        GroupTS: cacheZero.options!.Group!.TS,
        EventTypeID: eventTypeId,
        EventIDi: eventId,
        ParticipantRegistrationIDi: ParticipantIDi,
        IsYouth: IsYouth,
        EventRegistrationPerson: {
          Amount: 0,
          AmountChangeInCart: 0
        } as EventRegistrationPerson,
        ClassRegistrations: [],
        ProposedInactive: isRestore ? false : true,
        ParticipantTypeID: undefined,
        PersonIDi: undefined,
        GroupWeekIDi: groupWeekIDi,
        HasMultiWeekDiscount: false,
        ParticipantTypes: !cacheThreeEvents.EventInfoParticipantTypes ? [] : cacheThreeEvents.EventInfoParticipantTypes.map((pType) => pType.ID),
      });
    }
  }
};
