import {ActionCreator} from '../';
import { typeName, Action } from '../../utils/StrongActions';
import {CLEAR_CACHE_PREFIX, createRequestActions} from '../Validation/actionCreator';

export const SUFFIX = '__CACHE_TWO_ROSTER';
export const GetGroupRosterCache = createRequestActions(SUFFIX);

@typeName(`${CLEAR_CACHE_PREFIX}_CACHE_BELOW_TWO_ROSTER`)
export class ClearCacheBelowTwoRoster extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  getGroupRosterCache: (
    GroupIDi: number,
    GroupTS: number,
    GetOptions: boolean,
    GetGroupData: boolean,
    IncludeInactive: boolean
  ): ActionCreator => dispatch => {
    dispatch(GetGroupRosterCache.request({GroupIDi, GroupTS, GetOptions, GetGroupData, IncludeInactive}));
  },
  clearCacheBelowTwoRoster: (): ActionCreator => dispatch => {
    dispatch(new ClearCacheBelowTwoRoster());
  },
};
