import * as React from 'react';
import '../../../styles/elements/class/image.scss';
import { BaseEndUserClass, RegisteredClass } from '../../../models/class';
import {getImagePath, isImageValid} from "../../../utils";
import Vignette from '../Vignette';
import { getClassImage } from '../../../utils/imageHelper';

export const namespace = (): string => `elements--class-image`;

export interface Props {
  classModel: BaseEndUserClass | RegisteredClass;
}


class ClassImage extends React.PureComponent<Props, {}> {
  public props: Props;

  public render() {
    const { classModel} = this.props;
    const {Name} = classModel;

    const image = getClassImage(classModel);

    if (!image) return null;
    return (
      <Vignette
        className={`${namespace()}--image`}
        borderRadius='50%'
        width={50}
        height={50}
        name={image.initial}
        color={image.color}
        image={isImageValid(image) ? getImagePath(image, "th150px_auto") : undefined}
      />
    );
  }
}

export default ClassImage;
