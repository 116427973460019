
import { CLEAR_CACHE_PREFIX, createRequestActions } from '../../Validation/actionCreator';
import { ActionCreator } from '../..';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../../utils/suffix';
import { typeName, Action } from '../../../utils/StrongActions';


export const UPDATE_LOCATION_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__LOCATION';
export const SUFFIX = '__ADMIN_SETTINGS_CACHE_TWO_LOCATION';
export const SUFFIX_SAVE = '__ADMIN_SETTINGS_CACHE_TWO_LOCATION_SAVING';

export const GetLocation = createRequestActions(SUFFIX + UPDATE_LOCATION_FORM_SUFFIX);

@typeName(`${CLEAR_CACHE_PREFIX}_ADMIN_SETTINGS_CACHE_TWO_LOCATION`) export class ClearAdminSettingsCacheTwoLocation extends Action {}

export type Actions = typeof actionCreators;
  
export const actionCreators = {
  getLocation: (params: {ID: number}): ActionCreator => dispatch => {
    dispatch(GetLocation.request(params));
  },
  clearAdminSettingsCacheTwoLocation: (): ActionCreator => dispatch => {
    dispatch(new ClearAdminSettingsCacheTwoLocation());
  },
};