import * as React from 'react';
import {
  Modal, ModalHeader, ModalContent, ModalActions, Column, Row, Text, MoneyField, View, Button
} from '../../../../../Elements';
import '../../../../../../styles/pages/events/event/modals/register-override-fee/index.scss';
import {actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import {actionCreators} from "../../../../../../store/Events/Event/Modals/RegisterOverrideFee/actions";
import {bindActionCreators} from 'redux';
import {RED_THRESHOLD} from "../../constants";
import {standardCurrencyFormat} from "../../../../../../utils/classesHelper";
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--event--modals--register-override-fee';

type Props = WithInertAttribute<{}>

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class RegisterOverrideFeeModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;


  onSave = () => {
    const {actions: {saveRegisterOverride}, registerOverrideFee: { ActiveForm: {Amount}, AmountMin}} = this.props;
    saveRegisterOverride(AmountMin, Amount);
  };

  public render() {
    const {
      actions: {simpleUpdate, updateValue}, apiLoading, apiSaving, inert,
      registerOverrideFee: {Name, Amount, AmountMin, MaxParticipants, spotsAvailableText, quantity, ActiveForm, ValidationRules}
    } = this.props;

    const loading = apiSaving > 0 || apiLoading > 0;

    return (
      <Modal inert={inert} big mobileFullScreen expand className={namespace()}>
        <ModalHeader red className={`${namespace()}--header`}>
          Override Fees
        </ModalHeader>
        <ModalContent paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0} loading={loading}>
          {<Column gutter={false} expand layout="vertical">
            <View layout="vertical" expand className={`${namespace()}--override-view`}>
              <Row className={`${namespace()}--ticket-row-1`} marginTop={24} mobileMarginTop={16} marginBottom={4} paddingHorizontal={24} mobilePaddingHorizontal={16} verticalAlignment="middle">
                <Text size={18} weight="medium">{Name}</Text>
                <Text className={`${namespace()}--price`} size={16} weight="bold" color="green">{standardCurrencyFormat(Amount)}</Text>
              </Row>
              <Row className={`${namespace()}--ticket-row-2`} paddingHorizontal={24} mobilePaddingHorizontal={16} paddingBottom={24} mobilePaddingBottom={16} marginBottom={24} mobileMarginBottom={16} verticalAlignment="middle">
                {MaxParticipants !== 0 && quantity !== undefined && <Text size={14} color={quantity < RED_THRESHOLD ? 'red' : 'gray'} >{spotsAvailableText}</Text>}
                <Text className={`${namespace()}--upfront`} size={13} weight="medium" color="gray">{standardCurrencyFormat(AmountMin)} upfront</Text>
              </Row>
              <Row className={`${namespace()}--reg-fees`} paddingHorizontal={24} mobilePaddingHorizontal={16} marginBottom={16} verticalAlignment="middle">
                <Text size={15} weight="medium" marginRight="auto" marginVertical={4} >Registration Fee</Text>
                <MoneyField onChange={simpleUpdate}
                            onBlur={updateValue}
                            validationRules={ValidationRules.Amount}
                            value={ActiveForm.Amount}
                            padding={false}
                            placeHolder="$0.00"
                            hideError
                />
              </Row>
              {/*
              <Row paddingHorizontal={24} mobilePaddingHorizontal={16} verticalAlignment="middle" paddingBottom={24} mobilePaddingBottom={16}>
                <Text size={15} marginRight="auto">Amount Paid</Text>
                <Text size={15} weight="medium">$10.00</Text>
              </Row>
              */}
            </View>
          </Column>}
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button flat textColor="black" onClick={this.onSave}>SAVE CHANGES</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    registerOverrideFee: state.events.event.modals.registerOverrideFee,
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators
}, dispatch)});

const ConnectedRegisterOverrideFeeModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(RegisterOverrideFeeModal);

export default ConnectedRegisterOverrideFeeModal;
