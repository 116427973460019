import * as React from 'react';
import '../../../styles/elements/vignette/index.scss';
import { default as attributes } from '../../../styles';
import Initials from "../Initials";
import Image from "../Image";
import { CloseCircleIcon } from '../../../components/Icons';

// @todo: remove this, some other stuff is using it though
export type VignetteColors = 'blue' | 'blue2' | 'orange' | 'purple';

const namespace = (): string => 'elements--vignette';

interface Props {
  className?: string;
  color?: string;
  name?: string;
  image?: string;
  width?: number;
  height?: number;
  minWidth?: number;
  minHeight?: number;
  mobileWidth?: number;
  mobileHeight?: number;
  mobileMinWidth?: number;
  mobileMinHeight?: number;
  mobileFullWidth?: boolean;
  topLeftBorderRadius?: boolean;
  bottomLeftBorderRadius?: boolean;
  topRightBorderRadius?: boolean;
  bottomRightBorderRadius?: boolean;
  mobileTopLeftBorderRadius?: boolean;
  mobileBottomLeftBorderRadius?: boolean;
  mobileTopRightBorderRadius?: boolean;
  mobileBottomRightBorderRadius?: boolean;
  borderRadius?: string;
  skeleton?: boolean;
  onClick?: React.ReactEventHandler<any>;
  onCancel?: React.ReactEventHandler<any>;
  disabled?: boolean;
}

interface State {
  loadError: boolean;
}

class Vignette extends React.Component<Props, State>{
  state = {
    loadError: false,
  };

  onError = () => {
    this.setState({
      loadError: true,
    });
  };

  render() {
    const {
      className: overrideClassName, width, height, minWidth, minHeight, mobileWidth, mobileHeight, mobileMinWidth, mobileMinHeight, mobileFullWidth, color,
      name, image, skeleton, topLeftBorderRadius, bottomLeftBorderRadius, topRightBorderRadius, bottomRightBorderRadius, disabled,
      mobileTopLeftBorderRadius, mobileBottomLeftBorderRadius, mobileTopRightBorderRadius, mobileBottomRightBorderRadius, borderRadius, onClick, onCancel
    } = this.props;
    const {loadError} = this.state;

    let className = attributes(namespace(), overrideClassName);
    if (width || width === 0) className += ` width-${width}`;
    if (height || height === 0) className += ` height-${height}`;
    if (minWidth || minWidth === 0) className += ` min-width-${minWidth}`;
    if (minHeight || minHeight === 0) className += ` min-height-${minHeight}`;
    if (mobileWidth || mobileWidth === 0) className += ` mobile-width-${mobileWidth}`;
    if (mobileHeight || mobileHeight === 0) className += ` mobile-height-${mobileHeight}`;
    if (mobileMinWidth || mobileMinWidth === 0) className += ` mobile-min-width-${mobileMinWidth}`;
    if (mobileMinHeight || mobileMinHeight === 0) className += ` mobile-min-height-${mobileMinHeight}`;
    if (mobileFullWidth) className += ` ${namespace()}--mobile-full-width`;
    if (topLeftBorderRadius) className += ` top-left-border-radius`;
    if (bottomLeftBorderRadius) className += ` bottom-left-border-radius`;
    if (topRightBorderRadius) className += ` top-right-border-radius`;
    if (bottomRightBorderRadius) className += ` bottom-right-border-radius`;
    if (mobileTopLeftBorderRadius) className += ` mobile-top-left-border-radius`;
    if (mobileBottomLeftBorderRadius) className += ` mobile-bottom-left-border-radius`;
    if (mobileTopRightBorderRadius) className += ` mobile-top-right-border-radius`;
    if (mobileBottomRightBorderRadius) className += ` mobile-bottom-right-border-radius`;
    if (!onClick) className += ` not-clickable`;
  
    let finalColor : string | undefined;
    let opacity : number | undefined;
  
    if (!skeleton && color) {
      finalColor = color;
    } else if (skeleton) {
      finalColor = '#000000';
      opacity = 0.05;
    }
  
    const init = <Initials borderRadius={borderRadius} skeleton={skeleton} color={finalColor} opacity={opacity} name={name} className={`${namespace()}--initials`} />;
  
    return (
      <div className={className} onClick={onClick}>
        {onCancel && !disabled && <div className='close' onClick={disabled ? undefined : onCancel}><CloseCircleIcon className={`${namespace()}--close-icon`} /></div>}
        {image && !loadError ?
          ( <Image borderRadius={borderRadius} cover source={image} onError={this.onError} className={`${namespace()}--image`} placeholder={init}/> ) :
          (color ?
            ( init ) :
            null
          )
        }
      </div>
    );
  };
}

  

export default Vignette;
