import * as M from "../../../../constants/messages/generic";
import { SelectValidator } from "../../../../utils/validator/models";
import { getYearDefinition } from "../../../Admin/Modals/Accounts/validation";



export enum YearOverViewType {
  BY_COUNCIL=1,
  BY_EVENT,
  BY_DISTRICT,
  BY_PARTICIPANT,
}
export interface IValidator {
  Year: SelectValidator;
  View: SelectValidator;
}
export const FormDefinition: IValidator = {
  Year: {
    ...getYearDefinition('Year'),
    isRequired: () => true,
  },
  View: {
    key: 'View',
    defaultValue: () => YearOverViewType.BY_COUNCIL,
    options: {
      values: () => [
        {
          ID: YearOverViewType.BY_COUNCIL,
          Name: 'Year Overview',
        },
        {
          ID: YearOverViewType.BY_DISTRICT,
          Name: 'By District',
        },
        {
          ID: YearOverViewType.BY_EVENT,
          Name: 'By Event',
        },
        {
          ID: YearOverViewType.BY_PARTICIPANT,
          Name: 'By Participant Type',
        },
      ],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
    },
    isRequired: () => true,
    validatejs: {
      View: {
        presence: {message: '^' + M.REQUIRED},
        // NOTE: There's no blank option, so setting numericality to undefined
        numericality: undefined,
      }
    }
  },
};