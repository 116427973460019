import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, SEQUENTIAL_SUBMIT_REQUEST, getCacheLevelExtra
} from '../../../../Validation/actionCreator';
import type { ActionCreator, ApplicationState } from '../../../../';
import { ShowTopFloatingAlert } from '../../../../../store/App/actions';
import { ADMIN_FACILITY_MODULE_SUFFIX } from '../../../../../store/AdminFacilityLocation/CacheOne/actions';
import { SaveState } from '../../../../../store/Rollback/actions';
import { getDeleteRestoreFacilityBody, getImportFromFacilityBody, getUpdateFacilityBody, getFacilitiesHomeRootUrl, getEditFacilityAddImageBody, getEditFacilityEditImageBody } from '../../../../../constants/urls';
import { ImportType } from '../../../../../components/Pages/FacilityLocation/Facilities/Modals/ImportFromFacility';
import { SectionCreator } from '../../../../../utils/dataHelper';
import { getTimeBlockAttrKey, getFacilityPricingAttrKey, FacilityPricingAttributeKeys } from './validation';
import { TimeAttributeKeys, convertTimeDigit } from '../../../../../utils/dateHelper';
import { navPush } from '../../../../../utils';
import { v4 as uuidv4 } from 'uuid';
import { ResetImportFromFacilityModal } from '../../Modals/ImportFromFacility/actions';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../../../../utils/suffix';
import { PlainRoute } from 'react-router';
import { typeName, Action } from '../../../../../utils/StrongActions';
import { GalleryImage } from '../../../../../models/api/cacheOne';

export const UPDATE_FACILITY_FACILITY_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__FACILITY_FACILITY_FORM';
export const SUFFIX = ADMIN_FACILITY_MODULE_SUFFIX + '__FACILITY_FORM';
export const SUBMIT_SUFFIX = SUFFIX + UPDATE_FACILITY_FACILITY_FORM_SUFFIX;
export const DEL_SUFFIX = SUFFIX + UPDATE_FACILITY_FACILITY_FORM_SUFFIX + '__DELETE_FACILITY';
export const UPDATE_IMAGE_PREFIX = SEQUENTIAL_SUBMIT_REQUEST + '__UPDATE_IMAGE';
export const ADD_IMAGE_SUFFIX = UPDATE_IMAGE_PREFIX + '__ADD_IMAGE' + SUFFIX;
export const DELETE_IMAGE_SUFFIX = UPDATE_IMAGE_PREFIX + '__DELETE_IMAGE' + SUFFIX;

const addPhotoFormCreator = (rootState: ApplicationState, file: any) => {
  const formData = new FormData();
  if (rootState.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility) {
    formData.append('JSONData', JSON.stringify(getEditFacilityAddImageBody(rootState.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility.ID)));
  }

  formData.append('File', file);

  return formData;
};

const getSubmitFormExtra = (rootState: ApplicationState, routes, facilityId?: number) => {
  const route = routes[routes.length - 1];
  
  return getCacheLevelExtra(
    true,
    !!rootState.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility && !!facilityId && rootState.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility.ID === facilityId,
  );
};

@typeName('CHECK_ATTRIBUTE' + SUFFIX)
export class CheckAttribute extends Action { constructor(public attrId: number) { super(); }}
@typeName('REMOVE_TIME_BLOCK')
export class RemoveTimeBlock extends Action {
  constructor(public index: number) { super(); }
}
@typeName('ADD_TIME_BLOCK')
export class AddTimeBLock extends Action {}
@typeName('COPY_FROM_IN_COUNCIL' + SUFFIX)
export class CopyFromInCouncil extends Action { constructor(public PricingTierID: number) { super(); }}

@typeName('MOVE_GALLERY_ITEM' + SUFFIX)
export class MoveGalleryItem extends Action {
  constructor(public item: GalleryImage, public offset: number) { super(); }
}
@typeName('ADD_PHOTO' + SUFFIX)
export class AddPhoto extends Action {
  constructor(public dataUrl: string | ArrayBuffer | null, public tempId: number) {super();}
}
@typeName('CLEAR_TEMP_THUMBNAILS' + SUFFIX)
export class ClearTempThumbnails extends Action { constructor() { super(); } }

const GeneralSectionKeys = {
  FacilityTypeID: true,
  Name: true,
  AllowOnlineBookingID: true,
  Description: true,
  MaxCapacity: true,
  MinBookingLength: true,
  MaxBookingLength: true,
  RequireEntireWeekend: true,
  RequireStartOnSunday: true,
  ShowCheckInOutTimes: true,
  IsAllDay: true,
  GLAccountID: true,
  MinPerPersonFeesToBill: true,
};

const AttributesSectionKeys = {
  Attributes: true,
};

const formCreator = (rootState: ApplicationState, body: any) => {
  const facilityForm = rootState.adminFacilityLocation.facilities.facility.form;
  const facilityCache = rootState.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility;

  if (facilityCache) {
    const ActiveForm = facilityForm.Detail.ActiveForm;
    body.General = [
      SectionCreator(facilityCache, GeneralSectionKeys, true),
      {
        ...SectionCreator(ActiveForm, GeneralSectionKeys),
        FacilityTypeID: facilityCache.FacilityTypeID,
      }
    ];

    if (ActiveForm.Pricing) {
      body.Pricing = [
        ...facilityCache.Pricing.map((p) => {
          return {
            IsPrevious: true,
            ...p,
          };
        }),
        ...ActiveForm.Pricing.map((p) => {
          return {
            IsPrevious: false,
            PricingTierID: p.PricingTierID,
            PerDayAmount: parseFloat(ActiveForm[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_DAY_AMOUNT)]),
            PerDayAmountUpfront: parseFloat(ActiveForm[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_DAY_AMOUNT_UPFRONT)]),
            PerPersonPerDayAmount: parseFloat(ActiveForm[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT)]),
            PerPersonPerDayAmountUpfront: parseFloat(ActiveForm[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT_UPFRONT)]),
          };
        }),
      ];
    }

    if (ActiveForm.TimeBlocks) {
      if (facilityCache.TimeBlocks) {
        body.TimeBlocks = [
          ...facilityCache.TimeBlocks.filter((t) => !!t.ID).map((t) => {
            return {
              IsPrevious: true,
              ...t,
            };
          }),
        ];
      } else {
        body.TimeBlocks = [];
      }
      const newTimeBlocks = ActiveForm.TimeBlocks.map((t, index) => {
        if (!(!t.Inactive || !!t.ID)) return null;
        const StartHour = ActiveForm[getTimeBlockAttrKey(index, TimeAttributeKeys.START_HOUR)];
        const StartMin = ActiveForm[getTimeBlockAttrKey(index, TimeAttributeKeys.START_MIN)];
        const StartPeriod = ActiveForm[getTimeBlockAttrKey(index, TimeAttributeKeys.START_PERIOD)];
        const EndHour = ActiveForm[getTimeBlockAttrKey(index, TimeAttributeKeys.END_HOUR)];
        const EndMin = ActiveForm[getTimeBlockAttrKey(index, TimeAttributeKeys.END_MIN)];
        const EndPeriod = ActiveForm[getTimeBlockAttrKey(index, TimeAttributeKeys.END_PERIOD)];
        return {
          IsPrevious: false,
          ID: ActiveForm[getTimeBlockAttrKey(index, TimeAttributeKeys.ID)],
          StartTime: `${convertTimeDigit(StartHour, StartPeriod)}:${convertTimeDigit(StartMin)}:00`,
          EndTime: `${convertTimeDigit(EndHour, EndPeriod)}:${convertTimeDigit(EndMin)}:00`,
          Inactive: ActiveForm[getTimeBlockAttrKey(index, TimeAttributeKeys.INACTIVE)],
        };
      }).filter((tb) => !!tb);
      body.TimeBlocks.push(
        ...newTimeBlocks,
      );
    }
    body.Attributes = [
      SectionCreator(facilityCache, AttributesSectionKeys, true),
      SectionCreator(ActiveForm, AttributesSectionKeys),
    ];

    body.Images = ActiveForm.Images ? ActiveForm.Images.filter((image) => !image.Inactive).map((image, index) => {
      return {
        ID: image.ID,
        Ord: index + 1,
      };
    }) : [];
  }

  return body;
};

export const ValidateActions = createValidateActions(SUBMIT_SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUBMIT_SUFFIX);
export const UpdateFacilitySubmitActions = createApiSubmitActions(SUBMIT_SUFFIX);
export const DeleteFacilitySubmitActions = createApiSubmitActions(DEL_SUFFIX);
export const ImportBookingTimesSubmitActions = createApiSubmitActions('__IMPORT_BOOKING_TIMES' + SUFFIX);
export const ImportPricingSubmitActions = createApiSubmitActions('__IMPORT_PRICING' + SUFFIX);
export const ImportImagesSubmitActions = createApiSubmitActions('__IMPORT_IMAGES' + SUFFIX);
export const EditFacilityAddImageSubmitActions = createApiSubmitActions(ADD_IMAGE_SUFFIX);
export const EditFacilityDeleteImageSubmitActions = createApiSubmitActions(DELETE_IMAGE_SUFFIX);


export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminFacilityLocation.facilities.facility.form),
  updateFacilityDetailValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminFacilityLocation.facilities.facility.form.Detail),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  checkAttribute: (attrId: number): ActionCreator => dispatch => {
    dispatch(new CheckAttribute(attrId));
  },
  apiSubmitForm: (router: any, routes: any): ActionCreator => (dispatch, getState) => {
    const state = getState();
    // form validation (not including file)
    const valid = innerApiSubmitFormMethod(
      dispatch,
      UpdateFacilitySubmitActions,
      (s: ApplicationState) => s.adminFacilityLocation.facilities.facility.form,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
    );
    const validDetail = innerApiSubmitFormMethod(
      dispatch,
      UpdateFacilitySubmitActions,
      (s: ApplicationState) => s.adminFacilityLocation.facilities.facility.form.Detail,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
    );
    
    const cacheOneFacility = state.adminFacilityLocation.cacheOne.FacilitiesLocation;
    if (valid && validDetail && cacheOneFacility) {
      const facilityForm = state.adminFacilityLocation.facilities.facility.form;
      const body = getUpdateFacilityBody(facilityForm.Detail.ActiveForm.ID);
      
      const form = formCreator(getState(), body);
      navPush(router, getFacilitiesHomeRootUrl({
        locationId: cacheOneFacility.ID,
        locationName: cacheOneFacility.Name,
      }));
      dispatch(UpdateFacilitySubmitActions.request(
          form,
          getCacheLevelExtra(
            true,
            true,
          ),
        )
      );
    } else {
    }
  },
  removeTimeBlock: (index: number): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const timeBlocks = state.adminFacilityLocation.facilities.facility.form.Detail.ActiveForm.TimeBlocks;

    if (timeBlocks && timeBlocks.filter((tb) => !tb.Inactive).length === 1) {
      dispatch(new ShowTopFloatingAlert('At least one time block is required.', undefined, 'orange'));
    } else {
      dispatch(new RemoveTimeBlock(index));
    }
  },
  addTimeBlock: (): ActionCreator => dispatch => dispatch(new AddTimeBLock()),
  copyFromInCouncil: (PricingTierID: number): ActionCreator => dispatch => dispatch(new CopyFromInCouncil(PricingTierID)),
  moveGalleryItem: (item: GalleryImage, offset: number): ActionCreator => dispatch => dispatch(new MoveGalleryItem(item, offset)),
  deleteFacility: (facilityId: number, router: any, routes: PlainRoute<any>[], restore?: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const route = routes[routes.length - 1];

    const state = getState();
    const location = state.adminFacilityLocation.cacheOne.FacilitiesLocation;
    
    const body = getDeleteRestoreFacilityBody(facilityId, !restore);
    if (
      location && state.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility &&
      state.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility.ID === facilityId
    ) {
      navPush(router, getFacilitiesHomeRootUrl({
        locationId: location.ID,
        locationName: location.Name,
      }));
    }
    dispatch(
      DeleteFacilitySubmitActions.request(
        body,
        getSubmitFormExtra(getState(), routes, facilityId),
        route,
      )
    );
  },
  importFromFacility: (type: ImportType): ActionCreator => (dispatch, getState) => {
    const state = getState();

    const facility = state.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility;
    const fromID = state.adminFacilityLocation.facilities.modals.importFromFacility.ActiveForm.SelectedFacility;
    dispatch(new ResetImportFromFacilityModal());
    if (facility && fromID) {
      switch(type) {
        case ImportType.BOOKING_TIMES:
          dispatch(ImportBookingTimesSubmitActions.request(
            getImportFromFacilityBody(facility.ID, fromID),
            getCacheLevelExtra(false, true),
          ));
          break;
        case ImportType.PRICING:
          dispatch(ImportPricingSubmitActions.request(
            getImportFromFacilityBody(facility.ID, fromID),
            getCacheLevelExtra(false, true),
          ));
          break;
        case ImportType.PHOTOS:
          dispatch(ImportImagesSubmitActions.request(
            getImportFromFacilityBody(facility.ID, fromID),
            getCacheLevelExtra(false, true),
          ));
          break;
      }
    }
  },
  submitPhoto: (file: any, dataUrl: any): ActionCreator => (dispatch, getState) => {
    const tempId = uuidv4();
    // dispatch the sequential request first, otherwise the thumbnail would be cleared
    dispatch(EditFacilityAddImageSubmitActions.request(
      addPhotoFormCreator(getState(), file),
      {
        formDataRequest: true,
        tempId,
        leavingCacheLevelOnSuccess: false,
        inMatchingCacheLevelOn409: false,
      },
    ));
    dispatch(new AddPhoto(dataUrl, tempId));
  },
  deletePhoto: (imageId: number, restore?: boolean): ActionCreator => (dispatch, getState) => {
    const state = getState();
    if (state.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility) {
      dispatch(EditFacilityDeleteImageSubmitActions.request(
        getEditFacilityEditImageBody(state.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility.ID, imageId, !restore),
        null,
      ));
    }
  },
  clearThumbnails: (): ActionCreator => dispatch => dispatch(new ClearTempThumbnails()),
};