import * as React from 'react';
import '../../../styles/elements/scroll-top/index.scss';
import { FABButton } from 'react-mdl';
import ArrowIcon from '../../../images/elements/scroll-top/arrow.svg';
import { isNode } from '../../../utils';
import { isMobile } from '../../../utils/isMobile';

const namespace = (): string => 'elements--scroll-top';

interface State {
  show: boolean;
};

class ScrollTop extends React.Component<{}, State> {
  public state: State = {
    show: false
  };

  public componentWillMount() {
    if (!isNode()) {
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  public componentWillUnmount() {
    if (!isNode()) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  }

  public handleScroll = () => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
    if (top > 0 && !this.state.show) {
      this.setState({ show: true });
    } else if (top === 0 && this.state.show) {
      this.setState({ show: false });
    }
  };

  public handleClick = () => {
    window.scrollTo(0, 0);
  };

  public render() {
    const { show } = this.state;
    return (
      <div className={namespace() + (show ? ' show' : '')}>
        <FABButton ripple={!isMobile} className={`${namespace()}--button`} onClick={this.handleClick}>
          <ArrowIcon className={`${namespace()}--button--icon`}/>
        </FABButton>
      </div>
    );
  }
}

export default ScrollTop;
