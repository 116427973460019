import {makeRequestEpic} from "../../../../Validation/epicCreator";
import {DeleteAdminEventSubmitActions, UpdateEventSubmitActions} from "./actions";
import {AjaxResponse} from "rxjs/ajax";
import { ENTITY_CREATED, ENTITY_EDITED, ENTITY_PARSE_ERROR, ENTITY_DELETED, ENTITY_RESTORED } from "../../../../../constants/messages/adminCMS";
import { getAdminEventsEventDashboardUrl, getUpdateAdminEvent } from "../../../../../constants/adminEventsUrls";
import { ADMIN_EVENT } from "../../../../../constants/messages/adminEvents";
import { AdminEvent } from "../../../../../models/api/adminEventsCacheTwoEvent";
import { captureTentarooError } from "../../../../../utils/dataHelper";
import { reduxStoreService } from "../../../../service";

export const adminEventsUpdateEventEpic = makeRequestEpic(
  UpdateEventSubmitActions,
  getUpdateAdminEvent,
  undefined,
  true,
  (response: AjaxResponse) => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      if (body.AppState.EventIDi) {
        return ENTITY_EDITED(ADMIN_EVENT);
      }
      return ENTITY_CREATED(ADMIN_EVENT);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(ADMIN_EVENT)));
      return '';
    }
  },
  (response: AjaxResponse) => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      if (body.AppState.EventIDi) {
        return '';
      }
      const event = response.response.EventsEvent as AdminEvent;
      const rootState = reduxStoreService().getState();

      if (!rootState.adminEvents.cacheOne.EventsEventType) return '';
      return getAdminEventsEventDashboardUrl({
        eventTypeId: rootState.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID,
        eventId: event.IDi,
        eventName: event.Name,
      });
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(ADMIN_EVENT)));
      return '';
    }
  },
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  true,
);

export const adminEventsDeleteEventEpic = makeRequestEpic(
  DeleteAdminEventSubmitActions,
  getUpdateAdminEvent,
  undefined,
  true,
  (response: AjaxResponse) => {
    const EventsEventRow = response.response.EventsEvent;

    if (EventsEventRow.Inactive) {
      return ENTITY_DELETED(ADMIN_EVENT);
    }
    return ENTITY_RESTORED(ADMIN_EVENT);
  },
);