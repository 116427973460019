import * as React from 'react';
import { Tabs as MdlTabs } from 'react-mdl';
import {mergeClassNames} from '@tentaroo/shared';

import { namespace } from './constants';

interface Props {
  children?: React.ReactNode;
  className?: string;
  activeTab?: number;
}

const Tabs: React.SFC<Props> = ({ children, activeTab, ...props }: Props): React.ReactElement<Props> => (
  <MdlTabs ripple className={mergeClassNames(`${namespace()}--tabs`, props)} activeTab={activeTab}>
    {children}
  </MdlTabs>
);

export default Tabs;
export { default as Tab } from './Tab';
