import { typeName, Action } from '../../../utils/StrongActions';
import { ActionCreator } from '../../index';
import {SaveState} from "../../Rollback/actions";

@typeName("FACILITIES_LOCATIONS_SELECT_TAB") export class FacilitiesLocationSelectTab extends Action {
  constructor(public selectedTab: 'available' | 'myTrips') { super(); }
}
export const FILTER_TYPE = 'FACILITIES_LOCATIONS_FILTER';

export type Actions = typeof actionCreators;

export const actionCreators = {
  facilitiesSelectTab: (selectedTab: 'available' | 'myTrips'): ActionCreator => dispatch => {
    dispatch(new FacilitiesLocationSelectTab(selectedTab));
    dispatch(new SaveState());
  },
};
