import {
  createApiSubmitActions,
  createValidateActions,
  createApiValidateActions,
  createUpdateValueMethod,
  createSimpleUpdateValueMethod,
  innerApiSubmitFormMethod,
  getCacheLevelExtra,
} from "../../Validation/actionCreator";
import { ActionCreator, ApplicationState } from '../..';
import { ADMIN_FACILITY_MODULE_SUFFIX, UPDATE_ADMIN_FACILITIES_SETTINGS_FORM_SUFFIX } from "../../AdminFacilityLocation/CacheOne/actions";
import { getUpdateAdminFacilityLocationSettingsBody } from "../../../constants/urls";
import { typeName, Action } from "../../../utils/StrongActions";

export const SUFFIX = ADMIN_FACILITY_MODULE_SUFFIX + '__SETTINGS' + UPDATE_ADMIN_FACILITIES_SETTINGS_FORM_SUFFIX;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const FacilityLocationSettingsSubmitActions = createApiSubmitActions(SUFFIX);


@typeName('RESET_FORM' + SUFFIX)
export class FacilitiesSettingsResetForm extends Action {}


export type Actions = typeof actionCreators;
export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminFacilityLocation.settings),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  apiSubmitForm: (): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const valid = innerApiSubmitFormMethod(
      dispatch,
      FacilityLocationSettingsSubmitActions,
      (s: ApplicationState) => s.adminFacilityLocation.settings,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
    );

    if (valid) {
      dispatch(FacilityLocationSettingsSubmitActions.request(
        getUpdateAdminFacilityLocationSettingsBody(state.adminFacilityLocation.settings.ActiveForm),
        getCacheLevelExtra(
          false,
          true,
          // We are updating cache 1
          1,
        ),
      ));
    }
  },
  reset: (): ActionCreator => dispatch => dispatch(new FacilitiesSettingsResetForm()),
};