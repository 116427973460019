
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, ModalActions, Button, Loader
} from '../../../../../Elements';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as cacheOneActionCreators } from "../../../../../../store/AdminEvents/CacheOne/actions";
import { actionCreators as cacheZeroActionCreators } from "../../../../../../store/CacheZero/actions";
import { actionCreators as cacheThreeClassTypeActionCreators, GetAdminEventClassTypeAllRequirements } from "../../../../../../store/AdminEvents/CacheThreeClassType/actions";
import { actionCreators, AdminEventClassTypeFormNamespace as FormNamespace } from "../../../../../../store/AdminEvents/ClassTypes/Form/actions";
import { ApplicationState } from '../../../../../../store';
import '../../../../../../styles/pages/admin-events/events/modals/class-type-form/index.scss';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import Form from '../../Event/Classes/ClassTypesTab/ClassType/Form';
import { INVALID_CLASS_TYPE } from '../../../../../../constants/messages/adminEvents';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import AdminEventsCacheManager from '../../../../../../utils/cacheManagers/adminEventsCacheManager';
import { IAdminClassTypeRouterParams } from '../../../../../../utils/helpers/adminEventsPageHelper';
import { connect } from 'react-redux';
import {makeSelectedMeritBadgeSelector} from '../../../../../../store/AdminEvents/ClassTypes/Form';
import { reduxStoreService } from '../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isAdminEventsCacheThreeClassTypePopulated} from '../../../../../../utils/cachePopulatedCheckers/adminEvents';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

const namespace = () => 'pages--admin-events--class-type--modal';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminClassTypeRouterParams, {}>
>;
@(withRouter as any)
class ClassTypeFormModal extends ComponentUpdateTemplate<ConnectedProps, {}> {

  public props: ConnectedProps;

  componentDidMount() {
    super.loadAndSetData(
      this.props,
      () => {
        AdminEventsCacheManager.getInstance().loadCacheThreeClassType({
          props: this.props,
          isStateNavigated: true,
          isEdit: !!this.props.classTypeId,
          classTypeIdFromModalProps: this.props.classTypeId,
        });
      }
    );
  }

  componentWillUnmount() {
    this.onClose();
  }

  onClassTypeInactive() {
    const {actions} = this.props;

    actions.showTopFloatingAlert(INVALID_CLASS_TYPE, true, 'orange');
  }
  onSave = () => {
    const {actions, routes, router} = this.props;

    actions.apiSubmitForm(routes, router);
  };

  onClose = () => {
    const {actions} = this.props;
    if (isAdminEventsCacheThreeClassTypePopulated(reduxStoreService().getState().adminEvents.cacheThreeClassType)) {
      actions.clearAdminEventsCacheThreeClassType();
    }
  };

  public render() {
    const { selectedMeritBadge, cacheZero, apiSaving, actions, adminEventsCacheOne, classTypeId, adminEventsCacheThreeClassTypes, formState, apiLoadingMap, inert } = this.props;

    const title = classTypeId ? 'Edit Class Type' : 'New Class Type';
    const subtitle = adminEventsCacheOne.EventsEventType ? adminEventsCacheOne.EventsEventType.General.Name : '';
    const loading = !!classTypeId && !isAdminEventsCacheThreeClassTypePopulated(adminEventsCacheThreeClassTypes, formState.ActiveForm);

    const reloadingAllRequirements = apiLoadingMap[GetAdminEventClassTypeAllRequirements.requestType];
    const inactive = !!classTypeId && (adminEventsCacheThreeClassTypes.ClassType && adminEventsCacheThreeClassTypes.ClassType.Inactive);

    const noSavePermission = !cacheZero.options || !cacheZero.options.GeneralPermissions || (!!this.props.classTypeId ? !cacheZero.options.GeneralPermissions.hasClassTypeEdit : !cacheZero.options.GeneralPermissions.hasClassTypeAdd);

    return (
      <Modal
        inert={inert}
        onClose={this.onClose}
        inactive={inactive}
        onInactive={() => this.onClassTypeInactive()}
        wideModal
        higherZIndex
        big
        mobileFullScreen className={`${namespace()}`}
      >
        <ModalHeader subtitle={subtitle} className={`${FormNamespace()}--header`}>{title}</ModalHeader>
        <ModalContent hasPadding>
          {!loading && 
            <Form
              inert={inert}
              classTypeForm={formState}
              cacheZero={cacheZero}
              disabled={apiSaving > 0}
              reduxActions={actions}
              adminEventsCacheOne={adminEventsCacheOne}
              adminEventsCacheThreeClassTypes={adminEventsCacheThreeClassTypes}
              action={!!classTypeId ? 'edit' : 'add'}
              selectedMeritBadge={selectedMeritBadge}
            />}
          {(loading || reloadingAllRequirements) && <Loader className={`${FormNamespace()}--loader`} center />}
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button id={generateDOMId("admin-classtype-modal-save-btn")} textColor='black' disabled={loading || reloadingAllRequirements || apiSaving > 0 || noSavePermission} flat onClick={this.onSave}>{this.props.classTypeId ? 'SAVE' : 'CREATE'}</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const classTypeFormIdSelector = makeFormModalPropSelector(ModalTypes.CLASS_TYPE_FORM, 'id');
  const selectedMeritBadgeSelector = makeSelectedMeritBadgeSelector();
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheZero: state.cacheZero,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsCacheTwoEvent: state.adminEvents.cacheTwoEvent,
    adminEventsCacheThreeClassTypes: state.adminEvents.cacheThreeClassType,
    formState: state.adminEvents.classTypes.form,
    classTypeId: classTypeFormIdSelector(state),
    selectedMeritBadge: selectedMeritBadgeSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...cacheOneActionCreators,
  ...cacheZeroActionCreators,
  ...cacheThreeClassTypeActionCreators,
  ...appActionCreators,
}, dispatch) });

const ConnectedClassTypeFormModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClassTypeFormModal);

export default ConnectedClassTypeFormModal;