import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/radio/group.scss';

export const namespace = (): string => 'elements--radio-group';

interface Props {
  children?: React.ReactNode;
  className?: string;
}

class Group extends React.Component<Props, {}> {

  public render() {
    const { children, ...props } = this.props;
    return (
      <div className={mergeClassNames(namespace(), props)}>
        {children}
      </div>
    );
  }
}

export default Group;
