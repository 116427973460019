import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/icon-row/index.scss';

export const namespace = (): string => 'elements--icon-row-link';

export interface IconRowLinkProps {
  children?: React.ReactNode;
  className?: string;
  icon?: any;
  iconNode?: React.ReactNode;
  truncate?: boolean;
  /**
   * Followings are used in the <a> tag
   */
  noLinkDecoration?: boolean;
  href?: string;
  target?: string;
}

const IconRowLink:React.SFC<IconRowLinkProps> = (props:IconRowLinkProps):React.ReactElement<IconRowLinkProps> => {
  const { truncate, href, target, noLinkDecoration, iconNode } = props;

  let className = mergeClassNames(namespace(), props);
  let textClassName = `${namespace()}--link--text`;
  if (truncate) {
    className += ' truncate';
    textClassName += ' truncate';
  }

  return (
    <div className={mergeClassNames(className, props)}>
      <a className={`${namespace()}--link ${noLinkDecoration ? 'no-decoration' : ''}`} href={href} target={target}>
        <div className={`${namespace()}--link--icon`}>
          {!iconNode && <props.icon/>}
          {iconNode}
        </div>
        <div className={textClassName}>
          {props.children}
        </div>
      </a>
    </div>
  );
};

export default IconRowLink;
