import * as React from 'react';
import '../../../styles/pages/cms-pages/edit-page/gallery-upload.scss';
import { CameraIcon } from '../../Icons';
import { Button, Media } from '..';
import {useDropzone} from 'react-dropzone';
import { isMobile } from '../../../utils/isMobile';

export const namespace = (): string => 'pages--cms--page--gallery-upload';

interface Props {
  onUploadImage: (files: any[]) => void;
  onImportImage?: (e) => void;
  hasImages?: boolean;
  supportedFormat?: string;
  disabled?: boolean;
}

const GalleryUpload: React.SFC<Props> = ({onUploadImage, hasImages, supportedFormat, disabled, onImportImage, ...props}: Props): React.ReactElement<Props> => {
  const onDrop = React.useCallback((files) => {
    onUploadImage(files);
  }, []);

  const onDragOver = (e) => {
    if (isMobile) return;
    const elem = document.getElementsByClassName(namespace())[0];
    const hasDragEnter = elem.classList.contains('dragover');

    if (!!!hasDragEnter) {
      elem.classList.add('dragover');
    }
  };
  const onDragLeave = (e) => {
    if (isMobile) return;
    const elem = document.getElementsByClassName(namespace())[0];
    const hasDragEnter = elem.classList.contains('dragover');
  
    if (!!hasDragEnter) {
      elem.classList.remove('dragover');
    }
  };
  const {getRootProps, getInputProps} = useDropzone({onDrop});
  return (
    <div {...getRootProps()} onDragEnter={onDragOver} onDragOver={onDragOver} onDragLeave={onDragLeave} className={`${namespace()} ${disabled ? 'disabled' : ''} ${hasImages ? 'has-images' : ''}`}>
      <input disabled={disabled} multiple {...getInputProps()} accept={supportedFormat} />
      <CameraIcon width={96} height={96} />
      {!isMobile && <div className={`${namespace()}--title`}>Drag here or click to upload</div>}
      {isMobile && <div className={`${namespace()}--title`}>Tap to upload</div>}
      <div className={`${namespace()}--subtitle`}>Images up to 10MB each. Supported formats: JPG, PNG, GIF, TIFF</div>
      <div className={`${namespace()}--buttons`}>
        {onImportImage ? <Button disabled={disabled} className={`${namespace()}--button import`} color="white" textColor='black' onClick={onImportImage}>IMPORT</Button> :null}
        <Button disabled={disabled} className={`${namespace()}--button`} color="white" textColor='black' onClick={onUploadImage}>{onImportImage ? 'UPLOAD' : 'UPLOAD IMAGES'}</Button>
        </div>
    </div>
  );
};
  

export default GalleryUpload;
