import React from "react";

import Card, { CardCategory } from "../../Card";
import { namespace as cardNS } from "../../Card/constants";
import { default as attribute, MarginProps } from "../../../../styles";
import "../../../../styles/elements/merit-badge/card/index.scss";
import { generateDOMId } from "../../../../utils/cypressHelper";
import { MeritBadge } from "../../../../models/api/options";
import { Row } from "../../Grid";
import Vignette from "../../Vignette";
import { createInitials, getImagePath } from "../../../../utils";
import ActionButton from "../../ActionButton";
import { CloseIcon } from "../../../Icons";
import { SELECTED_ITEM_DELETED } from "../../../../constants/messages/generic";

export const namespace = (): string => "elements--merit-badge--card";

export enum MeritBadgeCardType {
  SELECT_SINGLE = "select-single",
  MODAL_SELECT = "modal-select",
}

interface Props extends MarginProps {
  type: MeritBadgeCardType;
  cardCategory?: CardCategory;
  noBoxShadow?: boolean;
  meritBadge: MeritBadge;
  disabled?: boolean;
  onClick?: (meritBadge: MeritBadge) => void;
  onRemove?: () => void;
  showDeletedPrompt?: boolean;
}

class MeritBadgeCard extends React.Component<Props> {
  onCardClick = (e) => {
    const { onClick, meritBadge } = this.props;
    !this.props.disabled && e.stopPropagation();
    onClick && onClick(meritBadge);
  };

  onRemoveClick = (e) => {
    const { onRemove } = this.props;
    !this.props.disabled && e.stopPropagation();
    onRemove && onRemove();
  };

  renderInSelectList = () => {
    const { meritBadge } = this.props;
    return (
      <Row className={`${namespace()}--main`}>
        <Row className={`${namespace()}--main--text`}>
          <div className={`${namespace()}--main--text--title`}>
            {meritBadge.Name}
          </div>
          <div className={`${namespace()}--main--text--subtitle`}>
            {meritBadge.VersionYear}
          </div>
        </Row>
      </Row>
    );
  };

  renderSelected = () => {
    const { meritBadge, disabled, cardCategory } = this.props;
    return (
      <Row className={`${namespace()}--main`}>
        <Row className={`${namespace()}--main--text`}>
          <div className={`${namespace()}--main--text--title`}>
            {meritBadge.Name}
          </div>
          <div className={`${namespace()}--main--text--subtitle`}>
            {meritBadge.VersionYear}
          </div>
        </Row>
        <ActionButton
          id={generateDOMId("merit-badge-card-remove-btn")}
          disabled={disabled}
          onClick={this.onRemoveClick}
          icon={CloseIcon}
          className={`${namespace()}--main--close ${cardNS()}--close ${cardCategory} ${
            disabled ? "disabled" : ""
          }`}
        />
      </Row>
    );
  };

  render() {
    const { type, cardCategory, noBoxShadow, showDeletedPrompt, meritBadge } =
      this.props;
    let className = attribute(namespace(), undefined, this.props);

    className += ` ${type}`;

    const initial = createInitials(meritBadge.Name);
    return (
      <Card
        noBoxShadow={noBoxShadow}
        className={className}
        component={<section />}
        template={"mobile-no-margin"}
        category={cardCategory || CardCategory.LIST}
        padding="none"
      >
        <div
          id={generateDOMId(`tentaroo-merit-badge-${meritBadge.ID}`)}
          className={`${namespace()}--wrapper`}
          onClick={this.onCardClick}
        >
          <Vignette
            className={`${namespace()}--avatar`}
            width={40}
            height={40}
            borderRadius={`${40}px`}
            name={initial}
            color={meritBadge.Color}
            image={
              meritBadge && meritBadge.ImageOriginal ? getImagePath(meritBadge, "th150px_auto") : undefined
            }
          />
          {type === MeritBadgeCardType.SELECT_SINGLE &&
            this.renderInSelectList()}
          {type === MeritBadgeCardType.MODAL_SELECT &&
            this.renderSelected()}
        </div>
        {meritBadge.Inactive && showDeletedPrompt && (
          <section className={`${namespace()}--deleted-prompt`}>
            {SELECTED_ITEM_DELETED}
          </section>
        )}
      </Card>
    );
  }
}

export default MeritBadgeCard;
