import * as React from 'react';
import '../../../styles/elements/chip/index.scss';
import Close from '../../../images/elements/chip/close.svg';

const namespace = (): string => 'elements--chip';

interface Props {
  children?: React.ReactNode;
  onClose?: () => void;
}

const Chip: React.SFC<Props> = ({ children, onClose }: Props): React.ReactElement<Props> => (
  <div className={namespace()}>
    <span className={`${namespace()}--text`}>
      {children}
    </span>
    {onClose ? (
      <a onClick={onClose} className={`${namespace()}--close`}>
        <Close/>
      </a>
    ) : null}
  </div>
);

export default Chip;
