import * as React from 'react';
import {namespace as ns} from './constants';

import '../../../styles/elements/my-event/warning.scss';
import {MarginProps} from '../../../styles';


const namespace = (): string => `${ns()}--warning`;

interface Props extends MarginProps {
  children?: React.ReactNode;
}

const Warning: React.SFC<Props> = ({ children, marginTop, marginBottom }: Props): React.ReactElement<Props> => {
  // only add inline styles when they are specified in props
  const styles: any = {};
  styles.marginTop = marginTop !== null ? `${marginTop}px` : `0px`;
  styles.marginBottom = marginBottom !== null ? `${marginBottom}px` : `0px`;

  return(
    <div className={namespace()} style={styles}>{children}</div>
  );
};

export default Warning;
