import * as React from 'react';
import { ContextMenu, ContextMenuItem } from '../../../Elements';
import { Props as ContextMenuProps } from '../../../Elements/ContextMenu/index';

interface Props {
  onMoveDown: (e) => void;
  onMoveUp: (e) => void;
  onEdit: (e) => void;
  onDelete: (e) => void;
  id: number;
}

const ContextMenuComponent: React.SFC<ContextMenuProps & Props> = (props: ContextMenuProps & Props): React.ReactElement<ContextMenuProps & Props> => (
  <ContextMenu
    {...props}
    consistentInMobile
    title=""
  >
    <ContextMenuItem admin onClick={props.onMoveUp}>Move Up</ContextMenuItem>
    <ContextMenuItem admin onClick={props.onMoveDown}>Move Down</ContextMenuItem>
    <ContextMenuItem admin onClick={props.onEdit}>Edit</ContextMenuItem>
    <ContextMenuItem id={`site-menu-${props.id}-delete`} admin onClick={props.onDelete}>Delete</ContextMenuItem>
  </ContextMenu>
);

export default ContextMenuComponent;
