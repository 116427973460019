import * as Actions from './actions';
import { createSelector } from 'reselect';
import {myTripsSelector, facilityLocationsSelector} from '../../CacheOne';
import {HomepageMyTrip, FacilityLocation} from "../../../models/api/cacheOne";
import { ClearAllCache, ClearAllEndUserCacheButOptions } from '../../App/actions';
import { Reducer } from 'redux';
import { isActionType } from '../../../utils/StrongActions';
import { ApplicationState } from '../..';

const filterSelector = (state: ApplicationState) => state.app.searchFilter;;

export const makeMyTripsSelector = () => {
  return createSelector(
    [myTripsSelector, filterSelector],
    (trips: Array<HomepageMyTrip>, filter) => {
      if (!filter || filter === '') return trips;
      const f = filter.toLowerCase();
      return trips.filter(trip => (trip.Name.toLowerCase().includes(f) || trip.Location.Name.toLowerCase().includes(f)));
    }
  );
};

export const makeFacilityLocationsSelector = () => {
  return createSelector(
    [facilityLocationsSelector, filterSelector],
    (locs: Array<FacilityLocation>, filter) => {
      if (!filter || filter === '') return locs;
      const f = filter.toLowerCase();
      return locs.filter(loc => (loc.Name.toLowerCase().includes(f)));
    }
  );
};

export interface FacilitiesLocationState {
  selectedTab: 'available' | 'myTrips';
  filterString?: string;
}
const getInitialState: () => FacilitiesLocationState = () => ({selectedTab: 'available'});

const FacilitiesLocation: Reducer<FacilitiesLocationState> = (state, action) => {
  if (isActionType(action, Actions.FacilitiesLocationSelectTab)) {
    return {...state, selectedTab: action.selectedTab};
  } else if (isActionType(action, ClearAllCache) || isActionType(action, ClearAllEndUserCacheButOptions)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default FacilitiesLocation;
