import * as React from 'react';
import {
  Modal, ModalTitle, ModalActions, Button, ModalContent, Row, Column, Select } from '../../../../../Elements';
import {withRouter, RouteComponentProps} from "react-router";
import {bindActionCreators} from "redux";
import {actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import {actionCreators} from "../../../../../../store/AdminEvents/EventTypes/Modals/ReplaceParticipantType/actions";
import {actionCreators as eventTypeFormActionCreators} from "../../../../../../store/AdminEvents/EventTypes/EventType/Form/actions";
import {ApplicationState} from "../../../../../../store";
import '../../../../../../styles/pages/admin-events/event-types/modal/replace-participant-type/index.scss';
import { makeDeletingParticipantTypeSelector } from '../../../../../../store/AdminEvents/EventTypes/Modals/ReplaceParticipantType';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--modal--replace-participant-type';

type Props = WithInertAttribute<{}>

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

@(withRouter as any)
class ReplaceParticipantTypeModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  
  componentDidMount() {
    if (shouldBlockActions()) return;

    this.props.actions.initReplaceParticipantTypeModal();
  }
  componentWillUnmount() {
    this.onClose();
  }

  onCancel = (e?: any) => {
    this.onClose();
    this.props.actions.popModal(false, true, ModalTypes.REPLACE_PARTICIPANT_TYPE);
  };

  onDelete = () => {
    const {deletingParticipantType, deletingParticipantTypeIndex, actions} = this.props;

    if (!deletingParticipantType || deletingParticipantTypeIndex === undefined) return;
    
    actions.submit(deletingParticipantType, deletingParticipantTypeIndex);
  };

  onClose = () => {
    this.props.actions.resetReplaceParticipantTypeModal();
  };

  public render() {
    const {apiSaving, actions, apiLoading, modalForm: {ActiveForm, ValidationRules}, inert, deletingParticipantType} = this.props;

    if (!deletingParticipantType) return null;
    
    return (
      <Modal inert={inert} onClose={this.onClose} disableDynamicHeight bodyScrollLock big higherZIndex>
        <ModalTitle marginBottom={8}>{`Remove Participant Type`}</ModalTitle>
        <ModalContent paddingTop={0} paddingBottom={0}>
          <div className={`${namespace()}--helper-text`}>Removing will make this participant type no longer available for all events, all classes and all event registrations.</div>
          <br />
          <div className={`${namespace()}--helper-text`}>To continue, please select a replacement participant type for all pre-existing classes and registrations.</div>
          <br />
          <div className={`${namespace()}--helper-text bold`}>{`Removing Participant Type: ${deletingParticipantType.Name} (${deletingParticipantType.IsYouth ? 'Y' : 'A'})`}</div>
          <Row marginTop={8}>
            <Column span={12}>
              <Select
                customizedId={generateDOMId("replacement-type")}
                label="Replacement Type"
                onChangeValue={actions.updateValue}
                value={ActiveForm.ParticipantTypeID}
                validationRules={ValidationRules.ParticipantTypeID}
                isNumber />
            </Column>
          </Row>
        </ModalContent>
        <ModalActions
          noPadding
          loading={apiSaving > 0 || apiLoading > 0}
          stacked
          alignRight
          left={<Button id={generateDOMId("cancel")} flat textColor="blue" onClick={this.onCancel}>CANCEL</Button>}
          right={<Button textColor="red" flat onClick={this.onDelete}>REMOVE</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const deletingParticipantTypeSelector = makeDeletingParticipantTypeSelector();
  const modalPropsSelector = makeFormModalPropSelector(ModalTypes.REPLACE_PARTICIPANT_TYPE, 'participantTypeIndex');

  return {
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
    deletingParticipantType: deletingParticipantTypeSelector(state),
    modalForm: state.adminEvents.eventTypes.modals.replaceParticipantType,
    deletingParticipantTypeIndex: modalPropsSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
  ...eventTypeFormActionCreators,
}, dispatch)});
const ConnectedReplaceParticipantTypeModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ReplaceParticipantTypeModal);

export default ConnectedReplaceParticipantTypeModal;
