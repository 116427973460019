import {ActionCreator} from '../';
import { typeName, Action } from '../../utils/StrongActions';
import {CLEAR_CACHE_PREFIX, createRequestActions} from '../Validation/actionCreator';

export const SUFFIX = '__CACHE_THREE_FACILITIES';
export const GetFacilityTripData = createRequestActions(SUFFIX);

@typeName(`${CLEAR_CACHE_PREFIX}_CACHE_BELOW_THREE_FACILITIES`) export class ClearCacheBelowThreeFacilities extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  getFacilityTripData: (
    GroupIDi: number,
    GroupTS: number,
    FacilityLocationID: number,
    FacilityTripID: number,
    GetOptions: boolean,
    GetGroupData: boolean,
    GetFacilityLocationData: boolean,
    GetFacilityTripDataParam: boolean
  ): ActionCreator => dispatch => {
    dispatch(GetFacilityTripData.request(
      {
        GroupIDi,
        GroupTS,
        FacilityLocationID,
        FacilityTripID,
        GetOptions,
        GetGroupData,
        GetFacilityLocationData,
        GetFacilityTripData: GetFacilityTripDataParam
      }
    ));
  },
  clearCacheBelowThreeFacilities: (): ActionCreator => dispatch => dispatch(new ClearCacheBelowThreeFacilities())
};
