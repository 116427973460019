import * as React from 'react';
import '../../../styles/elements/feature-list/index.scss';

import {namespace} from "./constants";

interface Props {
  children?: React.ReactNode;
}

const FeatureList: React.SFC<Props> = ({ children }: Props): React.ReactElement<Props> => (
  <ul className={namespace()}>
    {children}
  </ul>
);

export default FeatureList;
export { default as FeatureListItem } from './Item';
