import {RefundOrFeeActions, RefundOrFeeReset, SUFFIX} from './actions';
import {FormDefinition, IValidator} from "./validation";
import {apiValidationReducerCreator} from "../../../Validation/reducerCreator";
import { isActionType } from '../../../../utils/StrongActions';
import { AnyAction, Reducer } from 'redux';
import { ClearCacheBelowOne } from '../../../CacheOne/actions';
import { ClearAllCache, ClearAllEndUserCacheButOptions } from '../../../App/actions';
import { setDefaults } from '../../../../utils/validator';
import { WithRootState } from '../../../Validation/actionCreator';

export interface RefundOrFeeForm {
  Refund?: number;
  ConvenienceFee?: number;
}

export interface RefundOrFeeState {
  ActiveForm: RefundOrFeeForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
}
const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const getInitialState = (): RefundOrFeeState => {
  return {
    ActiveForm: {},
    ValidationRules: {...FormDefinition}};
};

const RefundOrFee: Reducer<RefundOrFeeState> = (oldState, action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, action);

  if (
    action.type === RefundOrFeeActions.successType ||
    isActionType(action, RefundOrFeeReset) ||
    isActionType(action, ClearCacheBelowOne) ||
    isActionType(action, ClearAllCache) ||
    isActionType(action, ClearAllEndUserCacheButOptions)
  ) {
    return setDefaults(action.rootState, getInitialState());
  }

  return state || { ...getInitialState()};
};

export default RefundOrFee;
