import * as React from 'react';
import {Main, MainContent} from '../../../../Layouts';
import {PersonFormTemplate} from '../../../../Templates';
import {Alert, Button, Column, FormActions, LoadingAll, Row} from '../../../../Elements';
import {bindActionCreators} from 'redux';
import {actionCreators as appActionCreators} from '../../../../../store/App/actions';
import {
  actionCreators as cacheThreeActionCreators,
} from '../../../../../store/CacheThreeRoster/actions';
import {
  actionCreators as cacheTwoActionCreators,
  ClearCacheBelowTwoRoster
} from '../../../../../store/CacheTwoRoster/actions';
import {
  actionCreators as cacheOneActionCreators,
  ClearCacheBelowOne
} from '../../../../../store/CacheOne/actions';
import {
  actionCreators as cacheZeroActionCreators,
} from '../../../../../store/CacheZero/actions';
import {actionCreators as rosterMainActionCreators} from '../../../../../store/Settings/Roster/Main/actions';
import {actionCreators} from '../../../../../store/Settings/Roster/Add/actions';
import {ApplicationState} from "../../../../../store/index";
import {RouteComponentProps, withRouter} from "react-router";
import {EventTypeRegistrationSettings} from "../../../../../models/api/cacheTwoEvents";
import {FullGroup} from '../../../../../models/api/options';
import { ModalTypes } from '../../../../../utils/modalHelper';
import EndUserCacheManager from '../../../../../utils/cacheManagers/endUserCacheManager';
import { SaveState } from '../../../../../store/Rollback/actions';
import { shouldReconfigRouter } from '../../../../../utils/cacheLoaders/reloaderHelper';
import { isPathUnderCacheTwoRoster } from '../../../../../utils/helpers/endUserPageHelper';
import { checkPermission } from '../../../../../utils/permissionHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isCacheThreeRosterPopulated, isCacheTwoRosterPopulated} from '../../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{},{id: string, name: string, ya: string}>
>;

@(withRouter as any)
class Person extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
  private nextLocation;

  public componentDidMount() {
    this.configRouter();
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeRoster({
          props: this.props,
          isEdit: this.props.params.id !== undefined && this.props.params.id !== null,
          isStateNavigated,
        });
      }
    );
  }

  componentWillUnmount() {
    const {params, cacheTwoRoster, cacheThreeRoster} = this.props;

    if (
      this.nextLocation &&
      !isPathUnderCacheTwoRoster(this.nextLocation.pathname) &&
      isCacheTwoRosterPopulated(cacheTwoRoster)
    ) {
      reduxStoreService().dispatch(new ClearCacheBelowOne());
    } else if (!params.id || isCacheThreeRosterPopulated(cacheThreeRoster)) {
      reduxStoreService().dispatch(new ClearCacheBelowTwoRoster());
    }

    this.resetRouteLeaveHook();
  }

  configRouter() {
    const {router, routes} = this.props;
    router.setRouteLeaveHook(routes[routes.length - 1], this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    const {params, cacheZero, apiLoading, cacheThreeRoster} = this.props;

    this.nextLocation = nextLocation;
    if (reduxStoreService().getState().app.apiLoading === 0 && cacheZero.options) {
      if (!params.id || isCacheThreeRosterPopulated(cacheThreeRoster)) {
        reduxStoreService().dispatch(new SaveState());
      }
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentDidUpdate(nextProps) {
    if (shouldReconfigRouter(this.props, nextProps)) this.configRouter();
  }

  public componentWillReceiveProps(nextProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeRoster({
          props: nextProps,
          isEdit: nextProps.params.id !== undefined && nextProps.params.id !== null,
          isStateNavigated,
        });
      }
    );
  }

  public get isEditing() {
    return this.props.params.id !== undefined && this.props.params.id !== null;
  }

  onDelete = (e) => {
    e.preventDefault();
    const {actions, cacheZero: {options}} = this.props;

    if (!options?.GeneralPermissions) return;

    checkPermission(
      () => {
        actions.pushModal(ModalTypes.PROFILE_DELETE_PERSON, false, true);
      },
      options.GeneralPermissions.hasGroup,
      options.GeneralPermissions.hasGroupReason
    );
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {actions, cacheZero: {options}} = this.props;

    if (!options?.GeneralPermissions) return;

    checkPermission(
      () => {
        actions.apiSubmitForm();
      },
      options.GeneralPermissions.hasGroup,
      options.GeneralPermissions.hasGroupReason
    );
  };
  
  isRosterPersonInactive = () => {
    const {cacheThreeRoster, params} = this.props;
    return !!params.id && (!cacheThreeRoster.GroupRosterPerson || cacheThreeRoster.GroupRosterPerson.Inactive);
  };

  renderActiveFormActions = () => {
    const {cacheZero} = this.props;
    const hasAccess = cacheZero.options?.GeneralPermissions?.hasGroup;
    return (
      <FormActions>
        <Row>
          <Column expand>
            {this.isEditing ? (
               <Button disabled={!hasAccess} textColor="black" flat submit onClick={this.onSubmit}>
                SAVE CHANGES
               </Button>
             ) : (
               <Button disabled={!hasAccess} flat textColor="black" submit onClick={this.onSubmit}>
                COMPLETE
               </Button>
             )}
          </Column>
          {this.isEditing ? (
            <Column>
              <Button disabled={!hasAccess} id={generateDOMId("delete-roster-person-btn")} color="white" textColor="red" big onClick={this.onDelete}>DELETE</Button>
            </Column>
          ) : null}
        </Row>
      </FormActions>
    );
  };

  onRestoreRosterPerson = () => {
    const {actions, cacheZero: {options}, cacheThreeRoster, routes} = this.props;

    if (!options?.GeneralPermissions) return;

    checkPermission(
      () => {
        if (!cacheThreeRoster.GroupRosterPerson) return;
        actions.apiRestorePerson(cacheThreeRoster.GroupRosterPerson.IDi, cacheThreeRoster.GroupRosterPerson.IsYouth, routes);
      },
      options.GeneralPermissions.hasGroup,
      options.GeneralPermissions.hasGroupReason
    );
  };

  renderInactiveFormActions = () => {
    const {cacheZero} = this.props;
    const hasAccess = cacheZero.options?.GeneralPermissions?.hasGroup;
    return (
      <FormActions>
        <Row>
          <Column expand></Column>
          <Column>
            <Button disabled={!hasAccess} color="white" textColor="red" flat big onClick={this.onRestoreRosterPerson}>RESTORE</Button>
          </Column>
        </Row>
      </FormActions>
    );
  };

  public render() {
    const {actions, inert, rosterAdd, cacheZero, cacheTwoRoster, cacheThreeRoster, params} = this.props;
    const cacheLoaded = isCacheThreeRosterPopulated(cacheThreeRoster);

    const loading = (
      (params.id && !cacheLoaded) ||
      !isCacheTwoRosterPopulated(cacheTwoRoster)
    );
    if (loading || (!cacheLoaded && params.id) || !cacheZero.options) return <LoadingAll/>;

    let OldScoutTypeID = 2; // legacy defaults to 2 --- "//No need to override and show field if adding. ScoutTypeID hasn't been calculated yet.  //this way, ScoutTypeID won't be shown if adding a new camper under event which requires scouters."
    if (cacheThreeRoster.GroupRosterPerson) {
      OldScoutTypeID = cacheThreeRoster.GroupRosterPerson.ScoutTypeID;
    }
    const hasAccess = cacheZero.options?.GeneralPermissions?.hasGroup;
    return (
      <Main inert={inert} mobileBackground="white">
        <MainContent>
          {!hasAccess && <Row hardOpacity key="access">
            <Alert>{cacheZero.options?.GeneralPermissions?.hasGroupReason}</Alert>
          </Row>}
          {rosterAdd.SubmitErrorMessage && <Alert noFlex>{rosterAdd.SubmitErrorMessage}</Alert>}
          <PersonFormTemplate
            actions={this.isRosterPersonInactive() ? this.renderInactiveFormActions() : this.renderActiveFormActions()}
            disabled={this.isRosterPersonInactive() || !hasAccess}
            reduxActions={actions}
            ActiveForm={rosterAdd.ActiveForm}
            ValidationRules={rosterAdd.ValidationRules}
            GroupTypeID={(cacheZero.options.Group as FullGroup).GroupTypeID}
            groupUnitType={(cacheZero.options.Group as FullGroup).UnitType}
            OldScoutTypeID={OldScoutTypeID}
            isYouth={params.ya === 'youth'}
            EventTypeRegistrationSettings={
              (cacheZero.options.EventTypeRegistrationSettings_Roster as EventTypeRegistrationSettings)
            }
          />
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    user: state.user,
    cacheZero: state.cacheZero,
    cacheOne: state.cacheOne,
    cacheTwoRoster: state.cacheTwoRoster,
    cacheThreeRoster: state.cacheThreeRoster,
    rosterAdd: state.settings.roster.add,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...cacheThreeActionCreators,
    ...cacheTwoActionCreators,
    ...cacheOneActionCreators,
    ...cacheZeroActionCreators,
    ...rosterMainActionCreators,
  }, dispatch)
});

const ConnectedPerson = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(Person);

export default ConnectedPerson;
export { default as Header } from './Header';
