import * as React from 'react';
import Card from "../../Card";
import CardActions from "../../Card/Actions";
import CardTitle from "../../Card/Title";
import CardPadding from "../../Card/Padding";
import Button from "../../Button";
import '../../../../styles/elements/cart/card/index.scss';

export const namespace = (): string => 'elements--cart--card';

export interface CardProps {
  numCartItems: number;
  open: boolean;
  onEmptyCart: () => void;
  onToggle?: () => void;
  children?: React.ReactNode;
}

class CardComponent extends React.Component<CardProps, {}> {
  public props: CardProps;

  onToggle = () => {
    if (this.props.onToggle) {
      this.props.onToggle();
    }
  };

  public render() {
    const { children, numCartItems, open, onToggle, onEmptyCart } = this.props;
    return (
      <Card
        footer={(
          <CardActions hidden={!open}>
            <Button color="red" textColor='dark-black' flat onClick={onEmptyCart}>EMPTY CART</Button>
          </CardActions>
        )}
        padding="none"
        template="mobile-no-margin"
        className={namespace()}
      >
        <CardPadding paddingBottom={open ? 0 : undefined}>
          <CardTitle
            num={numCartItems}
            toggled={open}
            onToggle={this.onToggle}
            marginBottom={open ? 16 : 0}
          >
            Items
          </CardTitle>
        </CardPadding>
        {open ? children : null}
      </Card>
    );
  }
}

export default CardComponent;
