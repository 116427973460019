import * as React from 'react';
import { Card, CardShowMore, ClassList, ClassListItem } from '../../../Elements';
import { namespace as ns } from './constants';
import {BaseEndUserClass, RegisteredClass} from "../../../../models/class";

interface Props {
  classes: Array<BaseEndUserClass | RegisteredClass>;
  onClickItem: () => void;
}

export const namespace = (): string => `${ns()}--classes-list`;

const Classes: React.SFC<Props> = ({onClickItem, classes}): React.ReactElement<Props> => {
  const first6Classes = classes.slice(0,6);
  return (
    <Card
      className={namespace()}
      footer={<CardShowMore onClick={onClickItem}/>} template="mobile-no-margin"
      padding="none"
    >
      <ClassList>
        {first6Classes.map((c: BaseEndUserClass | RegisteredClass, i) => <ClassListItem clazz={c} onClick={onClickItem} key={i}/>)}
      </ClassList>
    </Card>
  );
};



export default Classes;

