import {ActionCreator} from '../';
import { typeName, Action } from '../../utils/StrongActions';
import {CLEAR_CACHE_PREFIX, createRequestActions} from '../Validation/actionCreator';

export const SUFFIX = '__CACHE_TWO_PREV_ORDERS';
export const GetPrevOrders = createRequestActions(SUFFIX);

@typeName(`${CLEAR_CACHE_PREFIX}_CACHE_BELOW_TWO_PREV_ORDERS`) export class ClearCacheBelowTwoPrevOrders extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  getPrevOrders: (
    GroupIDi: number,
    GroupTS: number,
    GetOptions?: boolean,
    GetGroupData?: boolean
  ): ActionCreator => dispatch => {
    dispatch(GetPrevOrders.request(
      {GroupIDi, GroupTS, GetOptions, GetGroupData}
    ));
  },
  clearCacheBelowTwoPrevOrders: (): ActionCreator => dispatch => dispatch(new ClearCacheBelowTwoPrevOrders())
};
