import { ActionCreator, ApplicationState } from '../../../index';
import { createValidateActions, createApiValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod } from '../../../../store/Validation/actionCreator';
import { ADMIN_EVENT_TYPES_SUFFIX } from '../../CacheOne/actions';
import { typeName, Action } from '../../../../utils/StrongActions';

export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + '__MESSAGE_CENTER_HOME';

@typeName('RESET' + SUFFIX) export class MessageCenterHomeReset extends Action {}

export type Actions = typeof actionCreators;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.messageCenter.home, true, undefined, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  reset: (): ActionCreator => dispatch => dispatch(new MessageCenterHomeReset()),
};