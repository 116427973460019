import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import moment from 'moment';
import { DATE_PICKER_FORMAT } from '@tentaroo/shared';
import { makeFilteredReservationsSelector } from '../../../../../../store/Admin/Modals/Accounts';
import { ApplicationState } from '../../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../../../../../store/Admin/Modals/Accounts/actions";
import {actionCreators as appActionCreators} from '../../../../../../store/App/actions';
import {AccountsReservation} from '../../../../../../models/api/options';
import { actionCreators as rollbackActionCreators, SaveState } from '../../../../../../store/Rollback/actions';
import { SimpleList, DetailedListElement, EmptyMessage } from '../../../../../Elements';
import { URLS } from '../../../../../../constants/urls';
import { navPush, spaceTo_ } from '../../../../../../utils';
import { LocationsIcon, Facilities2Icon } from '../../../../../../components/Icons';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { EmptyMessageType } from '../../../../../Elements/EmptyMessage';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { GetFacilityTripData } from '../../../../../../store/CacheThreeFacilities/actions';
import { reduxStoreService } from '../../../../../../store/service';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import {isCacheZeroPopulated} from '../../../../../../utils/cachePopulatedCheckers/endUser';

interface Props {
  scrollToTop: () => void;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;


@(withRouter as any)
class ReservationsContent extends React.PureComponent<Props, {}> {
  public props: ConnectedProps & Props;

  componentDidMount() {
    if (shouldBlockActions()) return;

    this.props.actions.init();
  }

  onSearchBack = () => {
    // @todo-minor-performance: just use 1 action
    if (this.props.adminAccounts.filterString) {
      this.props.scrollToTop();
    }
    this.props.actions.showFilterTabs(true);
    this.props.actions.filterAccounts('');
  };

  onClickReservationElement = (index: number, key: string | number, reservation: AccountsReservation) => {
    const {actions, router, adminAccounts, cacheZero, cacheTwoFacilities, cacheThreeFacilities} = this.props;

    const pushUrl = `${URLS.FACILITY_TRIP}/${adminAccounts.ActiveForm.ReservationsLocationID}/${reservation.GroupTripID}/` +
      `${spaceTo_(reservation.TripName)}`;

    reduxStoreService().dispatch(new SaveState());
    this.onSearchBack();

    navPush(
      router,
      pushUrl,
      reservation.GroupIDi,
    );
    if (
      (isCacheZeroPopulated(cacheZero) && cacheZero.options!.Group && cacheZero.options!.Group.IDi === reservation.GroupIDi) &&
      cacheTwoFacilities.locationID === adminAccounts.ActiveForm.ReservationsLocationID &&
      cacheThreeFacilities.tripID === reservation.GroupTripID
    ) {
      // if same page is selected, then refresh
      reduxStoreService().dispatch(GetFacilityTripData.request({
        GroupIDi: cacheZero.options!.Group.IDi,
        GroupTS: cacheZero.options!.Group.TS,
        FacilityLocationID: adminAccounts.ActiveForm.ReservationsLocationID,
        FacilityTripID: reservation.GroupTripID,
        GetOptions: false,
        GetGroupData: false,
        GetFacilityLocationData: false,
        GetFacilityTripDataParam: true,
      }));
    }
  };

  reservationItemRender = (index) => {
    const { filteredReservations, adminAccounts: {ActiveForm, AccountsLocationFacilityTypes} } = this.props;
    const reservation: AccountsReservation = filteredReservations[index];

    let label = `${reservation.Facility}`;
    let mobileLabels;

    if (!ActiveForm.ReservationsFacilityTypeID) {
      const facilityType = AccountsLocationFacilityTypes.find((ft) => ft.ID === reservation.FacilityTypeID);
      if (facilityType) {
        label += ` - ${facilityType.NamePlural}`;
      }
    }
    mobileLabels = [label, reservation.GroupName];
    label += ` - ${reservation.GroupName}`;
    return (<DetailedListElement
      key={`${reservation.GroupIDi}_${reservation.ReservationID}`}
      index={index}
      label={label}
      mobileLabels={mobileLabels}
      descriptions={[
        `Start: ${moment(reservation.StartDate).format(DATE_PICKER_FORMAT)}`,
        `End: ${moment(reservation.EndDate).format(DATE_PICKER_FORMAT)}`,
        `Amount: $${reservation.Amount.toFixed(2)}`,
        `Balance: $${reservation.Balance.toFixed(2)}`,
      ]}
      onClick={this.onClickReservationElement}
      deleted={false}
      itemValue={reservation}
    />);
  };

  render() {
    const {filteredReservations, adminAccounts: {ActiveForm}} = this.props;

    if (!ActiveForm.ReservationsLocationID || ActiveForm.ReservationsLocationID === -1 || !ActiveForm.ReservationsLocationID_Final || ActiveForm.ReservationsLocationID_Final === -1) {
      return (
        <EmptyMessage
          icon={LocationsIcon}
          type={EmptyMessageType.PAGE_MARGIN}
          iconHeight='96px'
          iconWidth='96px'
          description='Select Location first'
          admin
        />
      );
    }

    if (!filteredReservations || filteredReservations.length === 0) {
      return (
        <EmptyMessage
          icon={Facilities2Icon}
          type={EmptyMessageType.PAGE_MARGIN}
          iconHeight='96px'
          iconWidth='96px'
          description={ENTITY_NOT_FOUND('reservations')}
          admin
        />
      );
    }

    return <SimpleList itemRenderer={this.reservationItemRender} totalLength={filteredReservations ? filteredReservations.length : 0}/>;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredReservationsSelector = makeFilteredReservationsSelector();
  return {
    adminAccounts: state.adminAccounts,
    filteredReservations: filteredReservationsSelector(state),
    cacheZero: state.cacheZero,
    cacheTwoFacilities: state.cacheTwoFacilities,
    cacheThreeFacilities: state.cacheThreeFacilities,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
  ...rollbackActionCreators}, dispatch) });

const ConnectedReservationsContent = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ReservationsContent);

export default ConnectedReservationsContent;
