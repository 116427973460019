import * as React from 'react';
import {Card, Column, RadioGroupOption, Row, Text, Button} from '../../../../../../Elements';
import {CacheFourEventsParticipantsState} from "../../../../../../../store/CacheFourEventsParticipants";
import {
  EventRegistrationParticipantsParticipantType,
  EventRegistrationParticipantType
} from "../../../../../../../models/api/cacheFourEvents";
import {RED_THRESHOLD} from "../../../constants";
import {
  EventInfo, EventInfoParticipantType,
  EventRegistrationPaymentStatus
} from "../../../../../../../models/api/cacheThreeEvents";
import {standardCurrencyFormat} from "../../../../../../../utils/classesHelper";
import {Actions as RegisterOverrideFeeActions} from "../../../../../../../store/Events/Event/Modals/RegisterOverrideFee/actions";
import {Actions as AppActions} from "../../../../../../../store/App/actions";
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../../../utils/cypressHelper';

interface Props {
  cacheFourEventsParticipants: CacheFourEventsParticipantsState;
  selected: boolean;
  pType: EventRegistrationParticipantsParticipantType; // from c4 participants
  pTypeC3: EventInfoParticipantType; // from c3
  EventRegistrationPaymentStatus: EventRegistrationPaymentStatus;
  EventInfo: EventInfo;
  hideRadio?: boolean;
  isAdmin: boolean;
  actions: RegisterOverrideFeeActions & AppActions;
  isAdding?: boolean;
  quantity?: number;
  InCart?: boolean;
  onSelect: Function;
}

export class NamesParticipantType extends React.Component<Props, {}> {
  props: Props;

  getSpotsAvailableText = (quantity: number) => {
    if (quantity <= 0) return 'FULL';
    return `${quantity} spot${quantity !== 1 ? 's' : ''} available`;
  };

  onOverride = () => {
    const {pType, pTypeC3, quantity} = this.props;

    this.props.actions.pushModal(
      ModalTypes.REGISTER_OVERRIDE_FEE,
      false,
      true,
      {
        pTypeAmount: pType.Amount,
        pTypeAmountMin: pType.AmountMin,
        pTypeName: pType.Name,
        spotsAvailableText: quantity ? this.getSpotsAvailableText(quantity) : '',
        maxParticipants: pTypeC3.MaxParticipants,
        quantity,
      }
    );
  };

  public render() {
    const {pType, pTypeC3, selected, hideRadio, isAdmin, EventInfo, quantity, isAdding, InCart, onSelect} = this.props;
    let showSpots = false;
    if (pTypeC3.MaxParticipants !== 0 && (isAdding || (!isAdding && InCart))) {
      showSpots = true;
    }

    return <Card template="mobile-stretch" component="label" padding="small">
      <Row>
        <Column layout="vertical" expand>
          <Text color={(isAdding && (EventInfo.NumEventSpotsAvailablePending <= 0 || (quantity !== undefined && quantity <= 0))) ? 'gray' : undefined} weight="medium" size={18} marginBottom={0}>
            {pType.Name}
          </Text>
          {showSpots && quantity !== undefined && <Text color={quantity <= 0 || quantity < RED_THRESHOLD ? 'red' : 'gray'} size={14}>
            {this.getSpotsAvailableText(quantity)}
          </Text>}
        </Column>
        <Column layout="vertical" horizontalAlignment="right" gutter={false}>
          <Text weight="bold" color="green" size={18} marginBottom={0}>
            {standardCurrencyFormat(pType.Amount)}
          </Text>
          <Text color="gray" size={14}>
            {`${standardCurrencyFormat(pType.AmountMin)} upfront`}
          </Text>
          {selected && isAdmin && <Button id={generateDOMId(`participant-type-${pType.ID}-override`)} onClick={this.onOverride} color="white" textColor="black" admin flat marginTop={4}>OVERRIDE</Button>}
        </Column>
        {(quantity === undefined || quantity > 0) && !hideRadio && <Column marginLeft={16}>
          <RadioGroupOption
            value={pType.ID}
            selected={selected}
            disabled={hideRadio}
            onChange={onSelect}
          />
        </Column>}
      </Row>
    </Card>;
  }
}
