import * as React from 'react';
import moment from 'moment';
import '../../../../styles/elements/month/compact/index.scss';
import {Availability} from "../../../../models/api/cacheTwoFacilties";

import {monthNamespace as namespace} from "./constants";

export interface Props {
  month: moment.Moment;
  availabilities?: Array<Availability>;
  offset: number;
}


export const getAvail = (offset: number, dayIndex: number, availabilities?: Array<Availability>) => {
  const index = offset + dayIndex;
  if (availabilities && index < availabilities.length) {
    const availID = availabilities[index].AvailID;
    if (availID === 1 || availID === 2) return ' available';
    if (availID === 3) return ' half-available';
    return '';
  }
  return ' loading';
};

const Month: React.SFC<Props> = ({ month, availabilities, offset }: Props): React.ReactElement<Props> => {
  const yearMonth = month.format('YYYY-MM-');
  return (
    <div className={namespace()}>
      <div className={`${namespace()}--days`}>
        <ul className={`${namespace()}--days--list`}>
          {Array.apply(null, Array(month.daysInMonth())).map((value, index) => {
            const date = yearMonth + (index < 9 ? `0${index + 1}` : index + 1);
            return (
              <li
                key={index}
                className={`${namespace()}--days--list--day ${getAvail(offset, index, availabilities)}`}
              >
                <span className={`${namespace()}--days--list--day--day-of-week`}>
                  {moment(date).format('ddd')}
                </span>
                <span className={`${namespace()}--days--list--day--day-of-month`}>
                  {index + 1}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Month;