import {
  FSSubmitActions,
  ReportBlueCardsActions,
  ReportInvoiceActions,
  ReportRequirementsCompletedActions,
  ReportRequirementsScoutbookActions,
  ReportRosterActions,
} from './actions';
import {
  getBlueCardBody,
  getBlueCardUrl,
  getInvoiceBody,
  getInvoiceUrl,
  getRequirementsCompletedBody,
  getRequirementsCompletedUrl,
  getUpdateEventPayment,
  getReportRosterUrl,
  getReportRosterBody,
  getRequirementsScoutbookUrl,
  getRequirementsScoutbookBody
} from '../../../../constants/urls';
import {makeRequestEpic} from '../../../Validation/epicCreator';

export const eventRegistrationSaveFinanceEpic = makeRequestEpic(
  FSSubmitActions,
  getUpdateEventPayment,
  undefined,
  true
);

export const reportInvoiceEpic = makeRequestEpic(
  ReportInvoiceActions,
  getInvoiceUrl,
  getInvoiceBody,
  true
);

export const reportRequirementsCompletedEpic = makeRequestEpic(
  ReportRequirementsCompletedActions,
  getRequirementsCompletedUrl,
  getRequirementsCompletedBody,
  true
);

export const reportRequirementsScoutbookEpic = makeRequestEpic(
  ReportRequirementsScoutbookActions,
  getRequirementsScoutbookUrl,
  getRequirementsScoutbookBody,
  true,
);


export const reportBlueCardsEpic = makeRequestEpic(
  ReportBlueCardsActions,
  getBlueCardUrl,
  getBlueCardBody,
  true
);

export const reportRosterEpic = makeRequestEpic(
  ReportRosterActions,
  getReportRosterUrl,
  getReportRosterBody,
  true,
);