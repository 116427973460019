import * as NM from "../../../../../../constants/messages/numbersWizard";
import {ClearSpotsError, AddSpot, SUFFIX, CopyFromProfile} from './actions';
import { apiValidationReducerCreator, chainSetDefault } from '../../../../../Validation/reducerCreator';
import {APISuccess, APIFailure, POPULATE_ACTION, WithRootState} from "../../../../../Validation/actionCreator";
import {EventNumbersCalc, EventNumbersSave} from "../../../../../CacheFourEventsNumbers/actions";
import {CacheFourEventsNumbersCore} from "../../../../../CacheFourEventsNumbers/index";
import {
  EventRegistrationParticipantType,
} from "../../../../../../models/api/cacheFourEvents";
import {createParticipantTypeValidation, createSpotAmountValidation, createSpotInactiveValidationRule, createSpotNumSpotsValidation, FormDefinition, getParticipantTypeKey, getSpotTempId, PT_PREFIX} from "./validation";
import {EventRegisterNumbersReset} from "../Main/actions";
import {ApiSubmitActionsNumbersSpots} from "./actions";
import {ApiSubmitActionsNumbersClasses} from "../Classes/actions";
import {ApiSubmitActionsNumbersProducts} from "../Products/actions";
import { spotsOnlySelector, createSpotsMap } from "../../../../../../utils/eventsHelper";
import { convertObjToMoment } from "../../../../../../utils";
import moment from "moment";
import { ApiSubmitActionsNumbersCampsite } from "../Campsite/actions";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../../../utils/StrongActions";

export const EventRegistrationNumbersKeys = {
  RegistrationNotes: true,
  CampsiteDepositAmount: true,
  GroupRegistrationNotes: true,
  AdminNotes: true,
  ContactEmail: true,
  ContactEmail2: true,
  ContactName: true,
  ContactName2: true,
  ContactPhone: true,
  ContactPhone2: true,
  NotesForGroup: true,
};

export interface SpotsActiveForm {
  SumParticipants: number;
  CampingOvernight?: number;
  NumCampersAdult?: number;
  NumCampersYouth?: number;
  DateRegistration?: moment.Moment;
  RegistrationNotes?: string;
  CampsiteDepositAmount?: number;
  AdminNotes?: string;
  ContactEmail?: string;
  ContactEmail2?: string;
  ContactName?: string;
  ContactName2?: string;
  ContactPhone?: string;
  ContactPhone2?: string;
  GroupRegistrationNotes?: string,
  NotesForGroup?: string,

}

export interface NumbersWizardSpotActiveForm {
  PTID: number;
  SpotID?: number;
  PTIsYouth?: boolean;
  NumSpots: number | null;
  Amount: number;
  Inactive?: boolean;
  tempID?: number;
}

export interface NumbersWizardSpotFormState {
  ActiveForm: NumbersWizardSpotActiveForm;
  ValidationRules: any;
}

export interface NumbersWizardSpotsState {
  ActiveForm: SpotsActiveForm;
  ValidationRules: any;
  SubmitErrorMessage?: string;
  EventRegistrationParticipantTypes: {
    ActiveForm: any;
    ValidationRules: any;
  }[],
  EventRegistrationSpots: NumbersWizardSpotFormState[];
}

const determineInlineError = (errors: any) => {
  let message = '';
  if (errors) {
    if (errors.OverbookedParticipantTypeTotal) message = errors.OverbookedParticipantTypeTotal[0];
    else if (errors.NewEventOverbooked) message = errors.NewEventOverbooked[0];
    else if (errors.SumParticipants) message = errors.SumParticipants[0];
    else if (errors.AmountTotalChange)  message = errors.AmountTotalChange[0];
    Object.keys(errors).forEach((k) => {
      if (k.startsWith(PT_PREFIX)) {
        message = NM.PARTICIPANT_OVERBOOKED;
      }
    });
  }

  return message;
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, determineInlineError);
const checkSpotApiValidation = apiValidationReducerCreator(SUFFIX + '__SPOT');

const getInitialState = () => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition}
} as any);

const NumbersWizardSpots: Reducer<NumbersWizardSpotsState> = (oldState = getInitialState(), act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, act);

  if (oldState.EventRegistrationParticipantTypes) {
    state.EventRegistrationParticipantTypes = oldState.EventRegistrationParticipantTypes.map((pt) => {
      const action = act as any;
      let result = pt;
      if (action.vObj && action.vObj.extraInfo && action.vObj.extraInfo.id === pt.ActiveForm.ID) {
        result = checkSpotApiValidation(pt, act);
      }
      
      if (act.type === POPULATE_ACTION + SUFFIX) {
        const values = {};

        const k = getParticipantTypeKey({ID: pt.ActiveForm.ID});
        chainSetDefault(act.rootState, values, result.ValidationRules[k], oldState.ValidationRules);
        state.ActiveForm = { ...state.ActiveForm, ...values };
      }
      
      return result;
    });
  }

  if (oldState.EventRegistrationSpots) {
    state.EventRegistrationSpots = oldState.EventRegistrationSpots.map((spot) => {
      const action = act as any;
      let result = spot;
      if (action.vObj && action.vObj.extraInfo && action.vObj.extraInfo.PTID === spot.ActiveForm.PTID && action.vObj.extraInfo.SpotID === spot.ActiveForm.SpotID) {
        result = checkSpotApiValidation(spot, act);
      }
      
      if (act.type === POPULATE_ACTION + SUFFIX) {
        const values = {};
  
        chainSetDefault(act.rootState, values, result.ValidationRules.NumSpots, oldState.ValidationRules);
        state.ActiveForm = { ...state.ActiveForm, ...values };
      }
  
      return result;
    });
  }
  if (
    act.type === EventNumbersCalc.successType ||
    act.type === ApiSubmitActionsNumbersSpots.successType ||
    act.type === ApiSubmitActionsNumbersClasses.successType ||
    act.type === ApiSubmitActionsNumbersCampsite.successType ||
    act.type === ApiSubmitActionsNumbersProducts.successType ||
    (act.type === EventNumbersSave.failureType && (act as WithRootState<APIFailure>).response.status === 400 && (act as WithRootState<APIFailure>).response.xhr.response.error.Detail === "Canceled")
  ) {
    // Create the active from and the validation rules based off the data.
    // The form has a numeric field for each ParticipantType and a Notes area sometimes
    const action = <WithRootState<APISuccess>> act;
    const ValidationRules = {...FormDefinition};
    const ActiveForm = {...state.ActiveForm};
    const data: CacheFourEventsNumbersCore = action.response.response;
    let EventRegistrationParticipantTypes: any[] = [];
    let EventRegistrationSpots: any[] = [];
    const spotsOnly = spotsOnlySelector(action.rootState);
    if (spotsOnly) {
      if (data.EventRegistrationParticipantTypes) {
        EventRegistrationParticipantTypes = data.EventRegistrationParticipantTypes.map((pType: EventRegistrationParticipantType) => {
          const ruleSet = createParticipantTypeValidation(act.rootState, pType, data.EventRegistrationPaymentStatus);

          return {
            ActiveForm: {
              [ruleSet.key]: pType.EstimateTotal,
              ID: pType.ID,
              IsYouth: pType.IsYouth,
            },
            ValidationRules: {
              [ruleSet.key]: ruleSet.rule,
            },
          };
        });
      }
    } else {
      if (data.EventRegistrationSpots && data.EventRegistrationParticipantTypes) {
        let SpotID = 0;
        const _EventRegistrationSpots = data.EventRegistrationSpots.map((spot) => {
          SpotID = getSpotTempId(action.rootState, data, SpotID);

          return {
            ...spot,
            SpotID,
          };
        });
        const map = createSpotsMap(_EventRegistrationSpots, data.EventRegistrationParticipantTypes);
        
        const result = Array.from(map, ([name, value]) => value);

        result.forEach((val) => {
          val.spots.forEach((s, index) => {
            if (!s.SpotID) return;
            EventRegistrationSpots.push({
              ActiveForm: {
                PTID: val.pt.ID,
                SpotID: s.SpotID,
                PTIsYouth: val.pt.IsYouth,
                NumSpots: s.NumSpots,
                Amount: s.Amount,
                Inactive: s.Inactive,
              },
              ValidationRules: {
                NumSpots: createSpotNumSpotsValidation(val.pt, s.SpotID, data.EventRegistrationPaymentStatus),
                Amount: createSpotAmountValidation(val.pt, s.SpotID, data.EventRegistrationPaymentStatus),
                Inactive: createSpotInactiveValidationRule(val.pt, s.SpotID),
              },
            });
          });
        });
      }
    }
    const settings = action.rootState.cacheTwoEvents.EventTypeRegistrationSettings;
    if (settings && settings.NumbersRegistrationSettings.ShowGroupRegistrationNotes) {
      if (data.EventRegistrationNumbers) {
        ActiveForm.GroupRegistrationNotes = data.EventRegistrationNumbers.GroupRegistrationNotes;
      }
    }

    if (data.EventRegistrationNumbers && data.EventRegistrationNumbers.IsCampingOvernight !== null) {
      ActiveForm.CampingOvernight = data.EventRegistrationNumbers.IsCampingOvernight;
    }

    if (data.EventRegistrationNumbers) {
      if (data.EventRegistrationNumbers.DateRegistration) {
        const objWithMoment = convertObjToMoment(data.EventRegistrationNumbers, ['DateRegistration']);
        ActiveForm.DateRegistration = objWithMoment.DateRegistration;
      }

      for (let key of Object.keys(data.EventRegistrationNumbers)) {
        // will skip if a field is undefined
        if (EventRegistrationNumbersKeys[key] && data.EventRegistrationNumbers[key] !== undefined) {
          ActiveForm[key] = data.EventRegistrationNumbers[key];
        }
      }
    }

    return {
      ActiveForm: ActiveForm,
      ValidationRules: ValidationRules,
      EventRegistrationParticipantTypes,
      EventRegistrationSpots: EventRegistrationSpots.sort((a, b) => {
        return a.ActiveForm.Amount > b.ActiveForm.Amount ? -1 : 1;
      }),
    };
  } else if (isActionType(act, AddSpot)) {
    const rootState = act.rootState;
    const EventRegistrationSpots = [...state.EventRegistrationSpots];

    const participantType = rootState.cacheFourEventsNumbers.EventRegistrationParticipantTypes ? rootState.cacheFourEventsNumbers.EventRegistrationParticipantTypes.find((pType) => pType.ID === act.PTID) : null;

    const pt = {
      ID: act.PTID,
      IsYouth: act.PTISYouth,
    } as any;

    const SpotID = getSpotTempId(act.rootState);
    EventRegistrationSpots.push({
      ActiveForm: {
        PTID: act.PTID,
        SpotID,
        PTIsYouth: act.PTISYouth,
        NumSpots: 0,
        Amount: participantType ? participantType.CurrentAmount : 0,
        Inactive: false,
      },
      ValidationRules: {
        NumSpots: createSpotNumSpotsValidation(pt, SpotID),
        Amount: createSpotAmountValidation(pt, SpotID),
        Inactive: createSpotInactiveValidationRule(pt, SpotID),
      },
    });

    return {
      ...state,
      EventRegistrationSpots,
    };
  } else if (isActionType(act, CopyFromProfile)) {
    const rootState = act.rootState;

    if (rootState.cacheZero.options && rootState.cacheZero.options.Group) {
      const Group = rootState.cacheZero.options.Group;
      return {
        ...state,
        ActiveForm: {
          ...state.ActiveForm,
          ContactName: Group.ContactName,
          ContactEmail: Group.Email,
          ContactPhone: Group.PhoneNumber,
        },
        ValidationRules: {
          ...state.ValidationRules,
          ContactName: FormDefinition.ContactName,
          ContactEmail: FormDefinition.ContactEmail,
          ContactPhone: FormDefinition.ContactPhone,
        },
      };
    }

    return state;
  } else if (isActionType(act, ClearSpotsError)) {
    return {
      ...state,
      SubmitErrorMessage: undefined
    };
  } else if (isActionType(act, EventRegisterNumbersReset)) {
    return getInitialState();
  }
  return state;
};

export default NumbersWizardSpots;
