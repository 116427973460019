import { createSelector } from "reselect";

import type { ApplicationState } from "../../../..";

import { locationsSelector } from "../../../../AdminSettings/Modals/Location";
import { eventsEmailMessagesSelector } from "../../../CacheOne";
import { openedModalsSelector } from "../../../../App";
import { sortByName } from "../../../../CacheZero";
import { IModal, ModalTypes } from "../../../../../utils/modalHelper";
import { districtsSelector } from "../../../../CacheZero/selectors";
import { AdminEventsEventCategory, AdminEventsOption, EventsEventTypePricingTier, ParticipantType } from "../../../../../models/api/adminEventsCacheOne";
import { District, GroupType, UnitType } from "../../../../../models/api/options";


const selectedLocationIDSelector = (state: ApplicationState) => state.adminEvents.eventTypes.eventType.form.General.ActiveForm.LocationID;
const selectedLocationIDFromCacheSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.General.LocationID : 0;
export const makeSelectedLocationSelector = (useCache?: boolean) => {
  return createSelector(
    [locationsSelector, useCache ? selectedLocationIDFromCacheSelector : selectedLocationIDSelector],
    (locations: any[], locationId: number) => {
      if (locations && locationId) return locations.find((l) => l.ID === locationId);

      return null;
    }
  );
};

const selectedEventCategoryIDFromCacheSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.General.EventCategoryID : 0;
const selectedEventCategoryIDSelector = (state: ApplicationState) => state.adminEvents.eventTypes.eventType.form.General.ActiveForm.EventCategoryID || 0;
const eventCategoriesSelector = (state: ApplicationState) => state.cacheZero.options ? state.cacheZero.options.EventCategories : [];
export const makeSelectedEventCategory = (useCache?: boolean) => {
  return createSelector(
    [eventCategoriesSelector, useCache ? selectedEventCategoryIDFromCacheSelector : selectedEventCategoryIDSelector],
    (eventCategories, id) => {
      return eventCategories?.find((category) => category.ID === id);
    }
  );
};

const selectedDistrictIDSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.General.DistrictIDi || 0 : 0;
export const makeSelectedDistrictSelector = () => {
  return createSelector(
    [districtsSelector, selectedDistrictIDSelector],
    (districts: District[], id: number) => {
      return districts.find((d) => d.IDi === id);
    }
  );
};

const pricingTiersSelector = (state: ApplicationState) => state.adminEvents.eventTypes.eventType.form.Pricing4.ActiveForm.PricingTiers;
const pricingTiersFromCacheSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.Pricing4.PricingTiers : [];
export const makePricingTiersSelector = (useCache?: boolean) => {
  return createSelector(
    [useCache ? pricingTiersFromCacheSelector : pricingTiersSelector],
    (pricingTiers: EventsEventTypePricingTier[]) => {
      return pricingTiers || [];
    }
  );
};

const selectedUpfrontPaymentIDSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.Pricing2.UpfrontPaymentID : 0;
const upfrontPaymentsSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventTypeUpfrontPaymentOptions || [];
export const makeSelectedUpfrontPaymentSelector = () => {
  return createSelector(
    [upfrontPaymentsSelector, selectedUpfrontPaymentIDSelector],
    (paymentOptions: AdminEventsOption[], id: number) => {
      return paymentOptions.find((o) => o.ID === id);
    }
  );
};

const adminEventParticipantTypesSelector = (state: ApplicationState) => state.adminEvents.eventTypes.eventType.form.Pricing4.ActiveForm.ParticipantTypes || [];
export const adminEventParticipantTypesFromCacheSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.Pricing4.ParticipantTypes : [];
export const makeParticipantTypesSelector = (useCache?: boolean) => {
  return createSelector(
    [useCache ? adminEventParticipantTypesFromCacheSelector : adminEventParticipantTypesSelector],
    (participantTypes: ParticipantType[]) => {
      const youth = participantTypes.filter((pt) => pt.IsYouth);
      const adults = participantTypes.filter((pt) => !pt.IsYouth);

      return [...youth.sort(sortByName), ...adults.sort(sortByName)];
    }
  );
};

const selectedLockInPricingBasedOnIDSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.Pricing4.LockInPricingBasedOnID : 0;
const lockInPricingBasedOnOptionsSelector= (state: ApplicationState) => state.adminEvents.cacheOne.EventTypeLockInPricingBasedOnOptions || [];
export const makeSelectedLockInPricingBasedOnOptions = () => {
  return createSelector(
    [lockInPricingBasedOnOptionsSelector, selectedLockInPricingBasedOnIDSelector],
    (lockinPricingBasedOnOptions: AdminEventsOption[], id: number) => {
      return lockinPricingBasedOnOptions.find((o) => o.ID === id);
    }
  );
};

const selectedMultipleEventDiscountIDSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.Pricing2.MultipleEventDiscountID : 0;
const eventTypeMultipleEventDiscountOptionsSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventTypeMultipleEventDiscountOptions || [];
export const makeSelectedMultipleEventDiscountSelector = () => {
  return createSelector(
    [eventTypeMultipleEventDiscountOptionsSelector, selectedMultipleEventDiscountIDSelector],
    (multipleEventDiscountOptions: AdminEventsOption[], id: number) => {
      return multipleEventDiscountOptions.find((o) => o.ID === id);
    }
  );
};


const groupTypesSelector = (state: ApplicationState) => state.cacheZero.options ? state.cacheZero.options.GroupTypes : [];
const defaultGroupTypeIDSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.GroupAndRegistration.DefaultGroupTypeID : 0;
export const makeDefaultGroupTypeSelector = () => {
  return createSelector(
    [groupTypesSelector, defaultGroupTypeIDSelector],
    (groupTypes: GroupType[], id: number) => {
      return groupTypes.find((gt) => gt.ID === id);
    }
  );
};

const unitTypesSelector = (state: ApplicationState) => state.cacheZero.options ? state.cacheZero.options.UnitTypes : [];
const defaultUnitTypeIDSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.GroupAndRegistration.DefaultUnitTypeID : 0;
export const makeDefaultUnitTypeSelector = () => {
  return createSelector(
    [unitTypesSelector, defaultUnitTypeIDSelector],
    (unitTypes: UnitType[], id: number) => {
      return unitTypes.find((ut) => ut.ID === id);
    }
  );
};

const selectedPaymentRequirementsToAddParticipantsOptionID = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.GroupAndRegistration.PaymentRequirementsToAddParticipantsID : 0;
export const makeSelectedPaymentRequirementsToAddParticipantsOptionSelector = () => {
  return createSelector(
    [
      (state: ApplicationState) => state.adminEvents.cacheOne.EventTypePaymentRequirementsToAddParticipantsOptions || [],
      selectedPaymentRequirementsToAddParticipantsOptionID,
    ],
    (options: AdminEventsOption[], id: number) => {
      return options.find((o) => o.ID === id);
    }
  );
};

export const makeEventTypeSharedSettingsSectionSelector = () => {
  return createSelector(
    [openedModalsSelector],
    (openedModals: IModal[]) => {
      const modal = openedModals ? openedModals.find((m) => m.type === ModalTypes.EVENT_TYPE_SHARED_SETTINGS) : null;

      if (modal) {
        return modal.props ? modal.props.eventTypeFormSection : undefined;
      }

      return undefined;
    }
  );
};

const selectedMessageIDSelector = (state: ApplicationState) => state.adminEvents.eventTypes.eventType.form.General.ActiveForm.NewRegistrationMessageID;
const selectedMessageIDFromCacheSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.General.NewRegistrationMessageID : 0;
export const makeSelectedMessageSelector = (useCache?: boolean) => {
  return createSelector(
    [eventsEmailMessagesSelector, useCache ? selectedMessageIDFromCacheSelector : selectedMessageIDSelector],
    (messages: any[], messageId: number) => {
      if (messages && messageId) return messages.find((m) => m.ID === messageId);

      return null;
    }
  );
};

const eventTypeRegistrationMethodOptionsSelector = (state: ApplicationState) => state.cacheZero.options ? state.cacheZero.options.EventTypeRegistrationMethodOptions : [];
const selectedRegistrationMethodIDSelector = (state: ApplicationState) => state.adminEvents.eventTypes.eventType.form.General.ActiveForm.RegistrationMethodID;
const selectedRegistrationMethodIDFromCacheSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.General.RegistrationMethodID : 0;
export const makeSelectedRegistrationMethodSelector = (useCache?: boolean) => {
  return createSelector(
    [eventTypeRegistrationMethodOptionsSelector, useCache ? selectedRegistrationMethodIDFromCacheSelector : selectedRegistrationMethodIDSelector],
    (options: AdminEventsOption[], id: number) => options ? options.find((o) => o.ID === id) : null
  );
};