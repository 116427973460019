import * as M from '../../constants/messages/generic';
import { FormDefinition as EditPasswordFormDefinition } from '../Settings/ChangePassword/validation';
import {MAX_LENGTH, MIN_LENGTH} from "../../constants/messages/generic";
import { Validator } from '../../utils/validator/models';

export interface IUpdatePasswordValidator {
  NewPassword: Validator;
  ConfirmNewPassword: Validator;
}

export interface IResetPasswordSearchValidator {
  EmailOrUsername: Validator;
}

export const ResetPasswordSearchFormDefinition: IResetPasswordSearchValidator = {
  EmailOrUsername: {
    key: 'EmailOrUsername',
    isRequired: () => true,
    validatejs: {
      EmailOrUsername: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          minimum: 3,
          maximum: 50,
          tooLong: MAX_LENGTH(50),
          tooShort: MIN_LENGTH(3)
        }
      }
    }
  },
};

export const UpdatePasswordFormDefinition: IUpdatePasswordValidator = {
  NewPassword: {
    ...EditPasswordFormDefinition.NewPassword,
    isRequired: () => true,
  },
  ConfirmNewPassword: {
    ...EditPasswordFormDefinition.ConfirmNewPassword,
    isRequired: () => true,
  },
};
