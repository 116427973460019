
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, ModalActions, Button, Loader
} from '../../../../Elements';
import '../../../../../styles/pages/shared/modals/location-form/index.scss';
import { actionCreators, LocationFormModalNamespace as namespace } from '../../../../../store/AdminSettings/Modals/LocationForm/actions';
import { actionCreators as adminCMSCacheOneActionCreators } from '../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as sharedCacheTwoLocationActionCreators } from '../../../../../store/AdminSettings/CacheTwoLocation/actions';
import { ApplicationState } from '../../../../../store';
import '../../../../../styles/pages/resources/modals/resource-form/index.scss';
import LocationForm from './Form';
import { INVALID_LOCATION } from '../../../../../constants/messages/adminCMS';
import { makeFormModalPropSelector } from '../../../../../store/App';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import AdminSettingsCacheManager from '../../../../../utils/cacheManagers/adminSettingsCacheManager';
import { connect } from 'react-redux';
import { reduxStoreService } from '../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isAdminSettingsCacheTwoLocationPopulated} from '../../../../../utils/cachePopulatedCheckers/adminSettings';
import { WithInertAttribute } from '../../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {locationId: string}>
>;

@(withRouter as any)
class LocationFormModal extends ComponentUpdateTemplate<ConnectedProps> {

  public props: ConnectedProps;

  componentDidMount() {
    super.loadAndSetData(
      this.props,
      () => {
        return AdminSettingsCacheManager.getInstance().loadCacheTwoLocation({
          props: this.props,
          isStateNavigated: true,
          isEdit: !!this.props.locationId,
          locationIdFromProps: this.props.locationId,
        });
      }
    );
  }

  componentWillUnmount() {
    this.onClose();
  }

  onLocationInactive = () => {
    const {actions} = this.props;

    actions.showTopFloatingAlert(INVALID_LOCATION, true, 'orange');
  };

  onSave = () => {
    this.props.actions.apiSubmitLocationForm();
  };

  onClose = () => {
    const {actions} = this.props;
    
    if (isAdminSettingsCacheTwoLocationPopulated(reduxStoreService().getState().adminSettings.cacheTwoLocation)) {
      actions.clearAdminSettingsCacheTwoLocation();
    }
  };
  

  public render() {
    const { locationId, sharedCacheTwoLocation, actions, inert, locationFormState} = this.props;

    const title = locationId ? 'Edit Location' : 'New Location';
    const loading = !!locationId && !isAdminSettingsCacheTwoLocationPopulated(sharedCacheTwoLocation, locationFormState.ActiveForm);

    return (
      <Modal
        onClose={this.onClose}
        inactive={!!locationId && (!!sharedCacheTwoLocation.Location && sharedCacheTwoLocation.Location.Inactive)}
        onInactive={this.onLocationInactive}
        wideModal big mobileFullScreen
        className={namespace()}
        inert={inert}
      >
        <ModalHeader className={`${namespace()}--header`}>{title}</ModalHeader>
        <ModalContent hasPadding>
          {!locationId || !loading ? <LocationForm
            inert={inert}
            action={locationId ? 'edit' : 'add'}
            ActiveForm={locationFormState.ActiveForm}
            ValidationRules={locationFormState.ValidationRules}
            SubmitErrorMessage={locationFormState.SubmitErrorMessage}
            reduxActions={actions}
            disabled={loading}
          /> : null}
          {loading && <Loader className={`${namespace()}--loader`} center />}
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button id={generateDOMId("location-form-save-btn")} textColor='black' disabled={loading} flat onClick={this.onSave}>{locationId ? 'SAVE' : 'CREATE'}</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const locationIdSelector = makeFormModalPropSelector(ModalTypes.LOCATION_FORM, 'id');
  const isFacilitiesSelector = makeFormModalPropSelector(ModalTypes.LOCATION_FORM, 'isFacilities');
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    locationFormState: state.adminSettings.modals.locationForm,
    sharedCacheTwoLocation: state.adminSettings.cacheTwoLocation,
    locationId: locationIdSelector(state),
    isFacilities: isFacilitiesSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...appActionCreators,
  ...actionCreators,
  ...adminCMSCacheOneActionCreators,
  ...sharedCacheTwoLocationActionCreators,
}, dispatch) });

const ConnectedLocationFormModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocationFormModal);

export default ConnectedLocationFormModal;