export default (name: string) => {
  if (!name) return '';
  const parts = name.split(' ');
  if (parts.length === 1) {
    return parts[0].substr(0, 2).toUpperCase();
  } else if (parts.length >= 2) {
    return parts[0].substr(0, 1).toUpperCase() + parts[1].substr(0, 1).toUpperCase();
  }
  return '';
};
