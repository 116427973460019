import * as React from 'react';
import {
  Modal, ModalActions, Button, ModalContent, ModalHeader, SimpleList, PageLoader, AdminClassTypeCard, EmptyMessage } from '../../../../../Elements';
import {withRouter, RouteComponentProps} from "react-router";
import {bindActionCreators} from "redux";
import {actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import {actionCreators as classActionCreators} from "../../../../../../store/AdminEvents/Classes/Form/actions";
import {AdminEventClassFormDefinition} from "../../../../../../store/AdminEvents/Classes/Form/validation";
import {actionCreators as cacheTwoEventActionCreators, GetAdminEvent} from "../../../../../../store/AdminEvents/CacheTwoEvent/actions";
import {actionCreators} from "../../../../../../store/AdminEvents/Events/Modals/SelectClassType/actions";
import {ApplicationState} from "../../../../../../store";
import { sortByName } from '../../../../../../store/CacheZero';
import '../../../../../../styles/pages/admin-events/events/modals/select-class-type/index.scss';
import { actionCreators as rollbackActionCreators } from '../../../../../../store/Rollback/actions';
import { ClassType } from '../../../../../../models/api/options';
import { CardCategory } from '../../../../../Elements/Card';

import { makeFormModalPropSelector } from '../../../../../../store/App';
import { makeFilteredClassTypesSelector, makeActiveClassTypesSelector } from '../../../../../../store/AdminEvents/Events/Modals/SelectClassType';
import { AdminClassTypeCardType } from '../../../../../Elements/AdminClassType/Card';
import { extractRouteParams } from '../../../../../../utils/urlHelper';
import { EmptyMessageType } from '../../../../../Elements/EmptyMessage';
import { ClassTypesIcon } from '../../../../../Icons';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { ADMIN_EVENT_CLASS_TYPES } from '../../../../../../constants/messages/adminEvents';
import { checkAdminEventClassTypePermission } from '../../../../../../utils/helpers/adminEventsPageHelper';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

const namespace = () => 'pages--events--modal--select-class-type-modal';

type Props = WithInertAttribute<{}>

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

@(withRouter as any)
class SelectClassTypeModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  private modalContentRef;

  componentDidMount() {
    if (shouldBlockActions()) return;

    const {classForm, actions} = this.props;

    actions.init(classForm.ActiveForm.SelectedClassTypeIds || []);
  }

  componentWillUnmount() {
    this.onClose();
  }
  
  onContinue = (e?: any) => {
    if (e) e.stopPropagation();
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };

  renderEmptyMessage = () => {
    return (
      <EmptyMessage
        icon={ClassTypesIcon}
        admin
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={ENTITY_NOT_FOUND(ADMIN_EVENT_CLASS_TYPES)}
      /> 
    );
  };

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    if (this.props.modalState.filterString) {
      this.scrollToTop();
    }
    this.props.actions.toggleFilter(false);
    this.props.actions.filterClassTypes('');
  };
  
  onClose = () => {
    this.props.actions.reset();
  };
  onSave = () => {
    const {actions, classFormActions, acitveClassTypes, modalType, modalState} = this.props;
    this.onClose();

    if (modalType === 'multiple') {
      const newComboName = acitveClassTypes.filter((ct) => modalState.ActiveForm.SelectedClassTypeIds.indexOf(ct.IDi) !== -1).sort(sortByName).map((ct) => ct.Name).join(' / ').substr(0, 1024);
      classFormActions.updateSelectedClassType(modalState.ActiveForm.SelectedClassTypeIds);
      classFormActions.updateValue(newComboName, AdminEventClassFormDefinition.ComboName);
    }
    this.props.actions.popModal(false, true, ModalTypes.SELECT_CLASS_TYPE);
  };
  onRefreshClick = () => {
    const {actions, cacheTwoEvent} = this.props;
    if (!cacheTwoEvent.EventsEvent) return;
    actions.saveState();
    actions.refreshEvent({
      EventIDi: cacheTwoEvent.EventsEvent.IDi,
      MaxClassTypeIDi: 0,
      ...extractRouteParams(this.props),
    }, false);
  };
  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filterClassTypes(val);
  };

  onSelectAll = () => {
    const {filteredClassTypes} = this.props;
    this.props.actions.selectAll(filteredClassTypes.filter((ct) => !!ct).map((ct) => ct.IDi));
  };

  onDeselect = () => {
    const {filteredClassTypes} = this.props;
    this.props.actions.deselect(filteredClassTypes.map((ct) => ct.IDi));
  };

  onMultipleSelectClick = (option: ClassType) => {
    this.props.actions.toggleClassTypeSelect(!option.selected, option.IDi);
  };

  onEditClassType = (option: ClassType) => {
    const {actions, cacheZero} = this.props;

    checkAdminEventClassTypePermission(
      () => {
        actions.pushFormModal(ModalTypes.CLASS_TYPE_FORM, true, {id: option.IDi});
      },
      cacheZero,
      true,
      true,
    );
  };

  itemRenderer = (index: number) => {
    const { filteredClassTypes, modalType } = this.props;
    const classType = filteredClassTypes[index];
    return (
      <AdminClassTypeCard
        key={`class_type_card${classType.IDi}`}
        type={modalType === 'multiple' ? AdminClassTypeCardType.SELECT_MULTIPLE : AdminClassTypeCardType.SELECT_SINGLE}
        category={CardCategory.LIST_MOBILE}
        option={classType}
        onClick={this.onClassTypeSelect}
        checked={classType.selected}
        onMultipleSelectClick={this.onMultipleSelectClick}
        onEdit={this.onEditClassType}
      />
    );
  };
  onClassTypeSelect = (option: ClassType) => {
    const {modalType, actions, classFormActions} = this.props;

    if (modalType === 'single') {
      classFormActions.updateSelectedClassType([option.IDi]);
      actions.reset();
      actions.popModal(false, true, ModalTypes.SELECT_CLASS_TYPE);
    }
  };
  onAddClick = () => {
    const {actions, cacheZero} = this.props;

    checkAdminEventClassTypePermission(
      () => {
        actions.pushFormModal(ModalTypes.CLASS_TYPE_FORM, false);
      },
      cacheZero,
      false,
      true,
    );
  };

  public render() {
    const {modalType, modalState: {ActiveForm, ValidationRules, isTextSearching}, apiLoadingMap, inert, actions, apiLoading, filteredClassTypes} = this.props;
    const loading = apiLoadingMap[GetAdminEvent.requestType];

    const title = `Select Class Type${modalType === 'multiple' ? 's' : ''}`;
    return (
      <Modal
        inert={inert}
        onClose={this.onClose}
        className={`${namespace()}`}
        mobileFullScreen bodyScrollLock big higherZIndex
      >
        <ModalHeader
          onAdd={this.onAddClick}
          isSearching={isTextSearching}
          onRefresh={this.onRefreshClick}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={this.onSearchChange}
          onSearchBack={this.onSearchBack}
          className={`${namespace()}--header`}>
            {title}
        </ModalHeader>
        <ModalContent
          refCallback={(ref) => this.modalContentRef = ref}
        >
          {!loading && filteredClassTypes && filteredClassTypes.length > 0 && (
            <SimpleList
              itemRenderer={this.itemRenderer}
              totalLength={filteredClassTypes ? filteredClassTypes.length : 0}
            />
          )}
          {!loading && filteredClassTypes.length === 0 && this.renderEmptyMessage()}
          {loading && <PageLoader className={`${namespace()}--page--loader`} />}
        </ModalContent>
        {modalType === 'multiple' ? <ModalActions
          sticky
          notFixed
          left={<Button textColor='black' disabled={loading} flat onClick={this.onSave}>SAVE</Button>}
          right={<div className={`${namespace()}--right-actions`}>
            {ActiveForm.SelectedClassTypeIds.length > 0 ? <Button textColor='black' disabled={loading} flat onClick={this.onDeselect}>DESELECT</Button> : null}
            <Button textColor='black' disabled={loading} flat onClick={this.onSelectAll}>SELECT ALL</Button>
          </div>}
        /> : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const modalTypeSelector = makeFormModalPropSelector(ModalTypes.SELECT_CLASS_TYPE, 'selectClassTypeModalType');
  const filteredClassTypesSelector = makeFilteredClassTypesSelector();
  const acitveClassTypesSelector = makeActiveClassTypesSelector();

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    cacheTwoEvent: state.adminEvents.cacheTwoEvent,
    modalState: state.adminEvents.events.modals.selectClassType,
    modalType: modalTypeSelector(state),
    classForm: state.adminEvents.classes.form,
    filteredClassTypes: filteredClassTypesSelector(state),
    acitveClassTypes: acitveClassTypesSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...actionCreators,
    ...rollbackActionCreators,
    ...cacheTwoEventActionCreators,
  }, dispatch),
  classFormActions: bindActionCreators({
    ...classActionCreators,
  }, dispatch),
});

const ConnectedSelectClassTypeModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(SelectClassTypeModal);

export default ConnectedSelectClassTypeModal;
