import { RouteComponentProps, withRouter } from "react-router";
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { ApplicationState } from "../../../../../../store";
import { Modal, ModalHeader, ModalContent, Row, EmptyMessage, Button, PageLoader, Form, Alert, Column, TextField, ModalActions } from "../../../../../../components/Elements";
import { actionCreators, NewFacilityModalNamespace as namespace } from "../../../../../../store/AdminFacilityLocation/Facilities/Modals/NewFacility/actions";
import { actionCreators as appActionCreators } from "../../../../../../store/App/actions";
import { actionCreators as cacheOneActionCreators, GetFacilityLocationCache } from "../../../../../../store/AdminFacilityLocation/CacheOne/actions";
import { makeFilteredFacilityTypesSelector } from "../../../../../../store/AdminFacilityLocation/Facilities/Modals/NewFacility";
import '../../../../../../styles/pages/admin-facility-location/facilities/modals/new-facility/index.scss';
import { AdminFacilityType } from "../../../../../../models/api/adminFacilitiesCacheOne";
import FacilityTypeCard, { FacilityTypeCardType } from "../../../../../../components/Elements/FacilityType/Card";
import { CardCategory } from "../../../../../../components/Elements/Card";
import { makeActiveFacilityTypesSelector } from "../../../../../../store/AdminFacilityLocation/Facilities/Home";
import { FacilitiesTypes2Icon, CloseCircleIcon } from "../../../../../../components/Icons";
import { ENTITY_NOT_ADDED, ENTITY_NOT_FOUND } from "../../../../../../constants/messages/adminCMS";
import { FACILITY_TYPES, FACILITY_TYPE_NOT_ADDED_DETAIL } from "../../../../../../constants/messages/adminFacilityLocation";
import { extractRouteParams } from "../../../../../../utils/urlHelper";
import Tag from "../../../../../../components/Elements/Tag";
import { EmptyMessageType } from "../../../../../../components/Elements/EmptyMessage";
import { ModalHeight } from "../../../../../../components/Elements/Modal";
import { disableBodyScroll, enableBodyScroll } from "../../../../../../utils/navHelper";
import { checkFacilityTypePermission } from "../../../../../../utils/helpers/adminFacilityLocationPageHelper";
import { ModalTypes } from "../../../../../../utils/modalHelper";
import { connect } from "react-redux";
import { getMergeProps } from "../../../../../../utils/reduxHelper";
import { reduxStoreService } from "../../../../../../store/service";
import { shouldBlockActions } from "../../../../../../utils/cacheLoaders/helpers/blockers";
import {WithInertAttribute} from "../../../../../Elements/WithInert";

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

type Props = WithInertAttribute<{}>

@(withRouter as any)
class NewFacilityModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  private modalContentRef;
  private facilityTypeSelectionRef;

  componentWillUnmount() {
    enableBodyScroll(this.facilityTypeSelectionRef);
    this.onClose();
  }

  componentDidMount() {
    disableBodyScroll(this.facilityTypeSelectionRef);
    if (shouldBlockActions()) return;
    
    this.props.actions.init();
  }
  componentDidUpdate(prevProps: Props & ConnectedProps) {
    if (prevProps.newFacilityModalForm.ActiveForm.SelectedFacilityTypeID !== this.props.newFacilityModalForm.ActiveForm.SelectedFacilityTypeID) {
      if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
    }
    if (this.props.newFacilityModalForm.ActiveForm.SelectedFacilityTypeID !== prevProps.newFacilityModalForm.ActiveForm.SelectedFacilityTypeID) {
      enableBodyScroll(this.facilityTypeSelectionRef);
      if (!this.props.newFacilityModalForm.ActiveForm.SelectedFacilityTypeID) {
        disableBodyScroll(this.facilityTypeSelectionRef);
      } else {
        disableBodyScroll(this.modalContentRef);
      }
    }
  }

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    this.props.actions.toggleFilter(false);
    this.props.actions.filterFacilityType('');
  };
  onAddClick = () => {
    checkFacilityTypePermission(
      () => {
        this.props.actions.pushFormModal(ModalTypes.FACILITY_TYPE_FORM, false);
      },
      this.props.cacheZero,
      false,
      true,
    );
  };

  onClose = () => {
    this.props.actions.reset();
    this.onSearchBack();
  };
  onFacilityTypeCardClick = (facilityType: AdminFacilityType) => {
    const {newFacilityModalForm: {ValidationRules}} = this.props;
    this.props.actions.updateValue(facilityType.ID, ValidationRules.SelectedFacilityTypeID);
  };
  onRefreshClick = () => {
    const {actions} = this.props;

    actions.getFacilityLocationCache({
      MaxFacilityTypeID: 0,
      GetFacilityTypes: true,
      ...extractRouteParams(this.props),
    }, false, undefined, true);
  };

  renderEmptyMessage = () => {
    const {filteredFacilityTypes, activeFacilityTypes} = this.props;

    if (activeFacilityTypes.length === 0) {
      return (
        <EmptyMessage
          type={EmptyMessageType.PAGE_MARGIN}
          icon={FacilitiesTypes2Icon}
          iconHeight='96px'
          iconWidth='96px'
          admin
          fixedFontSize
          description={ENTITY_NOT_ADDED(FACILITY_TYPES)}
          detail={FACILITY_TYPE_NOT_ADDED_DETAIL}
          actions={<Button className={`${namespace()}--empty-message-btn`} color="white" textColor='black' onClick={this.onAddClick}>NEW FACILITY TYPE</Button>}
        />
      );
    } else if (filteredFacilityTypes.length === 0) {
      return (
        <EmptyMessage
          type={EmptyMessageType.PAGE_MARGIN}
          admin
          icon={FacilitiesTypes2Icon}
          detail={FACILITY_TYPE_NOT_ADDED_DETAIL}
          iconHeight='96px'
          iconWidth='96px'
          fixedFontSize
          description={ENTITY_NOT_FOUND(FACILITY_TYPES)}
          actions={<Button className={`${namespace()}--empty-message-btn`} color="green" textColor='white' onClick={this.onAddClick}>NEW FACILITY TYPE</Button>}
        />
      );
    }
  };

  onEditFacilityType = (facilityType: AdminFacilityType) => {
    this.props.actions.pushFormModal(ModalTypes.FACILITY_TYPE_FORM, true, {id: facilityType.ID});
  };

  renderFacilityTypeSelection = (loading?: boolean) => {
    const {filteredFacilityTypes, activeFacilityTypes} = this.props;
    return (
      <div className={`${namespace()}--facility-type-selection`}>
        <div className={`${namespace()}--facility-type-selection--title`}>Select Facility Type</div>
        <div
          ref={(ref) => this.facilityTypeSelectionRef = ref}
          className={`${namespace()}--facility-type-selection--wrapper`}>
          {!loading && filteredFacilityTypes.length > 0 ? filteredFacilityTypes.map((ft) => {
            return (
              <FacilityTypeCard
                noBoxShadow
                type={FacilityTypeCardType.NEW_FACILITY_MODAL}
                category={CardCategory.LIST_MOBILE}
                facilityType={ft}
                onEdit={this.onEditFacilityType}
                onClick={this.onFacilityTypeCardClick}
              />
            );
          }) : null}
        </div>
        {!loading && (activeFacilityTypes.length === 0 || filteredFacilityTypes.length === 0) ? this.renderEmptyMessage() : null}
      </div>
    );
  };

  newFacilityFormDisabled = () => {
    const {apiSaving, adminFacilityLocationCacheOne} = this.props;

    if (!adminFacilityLocationCacheOne.FacilitiesLocation) return true;

    return apiSaving > 0;
  };

  renderNewFacilityForm = () => {
    const {newFacilityModalForm: { ActiveForm, ValidationRules, SubmitErrorMessage }, adminFacilityLocationCacheOne, apiSaving, actions} = this.props;

    const facilityType = adminFacilityLocationCacheOne.FacilitiesTypes ? adminFacilityLocationCacheOne.FacilitiesTypes.find((ft) => ft.ID === ActiveForm.SelectedFacilityTypeID) : null;

    if (!facilityType) return null;
    return (
      <div className={`${namespace()}--form`}>
        <Form
          onSubmit={e => e.preventDefault()}>
          {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
          <Row wrap={false} marginBottom={12}>
            <div className={`${namespace()}--form--field--label`}>Facility Type: </div>
            <Tag
              onRemove={() => this.props.actions.init()}
              removeIcon={<CloseCircleIcon />}
              text={facilityType.NamePlural}
              backgroundColor='orange' />
          </Row>
          <div className={`${namespace()}--form--helper-text`}>Facility Type can't be changed after the facility is created. Please make sure you have selected the right type.</div>
          <Row marginBottom={8}>
            <Column span={12} mobileSpan={12}>
              <TextField
                label="Name"
                disabled={this.newFacilityFormDisabled()}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules.Name}
                value={ActiveForm.Name}/>
            </Column>
          </Row>
          <Row marginBottom={8}>
            <Column span={12} mobileSpan={12}>
              <TextField
                label="Description"
                disabled={this.newFacilityFormDisabled()}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                rows={4}
                validationRules={ValidationRules.Description}
                value={ActiveForm.Description}/>
            </Column>
          </Row>
        </Form>
      </div>
    );
  };

  onCreate = () => {
    this.props.actions.apiSubmitForm(this.props.router);
  };

  public render() {
    const {actions, apiLoadingMap, newFacilityModalForm: {ActiveForm, ValidationRules, isTextSearching}, inert, adminFacilityLocationCacheOne, apiSaving} = this.props;
    const subtitle = adminFacilityLocationCacheOne.FacilitiesLocation ? adminFacilityLocationCacheOne.FacilitiesLocation.Name : '';
    const facilityTypeSelected = !!ActiveForm.SelectedFacilityTypeID;
    const loading = apiLoadingMap[GetFacilityLocationCache.requestType];

    return (
      <Modal inert={inert} height={facilityTypeSelected ? undefined : ModalHeight.HEIGHT_425} onClose={this.onClose} wideModal big mobileFullScreen className={namespace()}>
        <ModalHeader
          subtitle={subtitle}
          className={`${namespace()}--header`}
          isSearching={!facilityTypeSelected ? isTextSearching : false}
          onAdd={!facilityTypeSelected ? this.onAddClick : undefined}
          onRefresh={!facilityTypeSelected ? this.onRefreshClick : undefined}
          onSearchOpen={!facilityTypeSelected ? this.onSearchOpen : undefined}
          onSearchChange={!facilityTypeSelected ? actions.filterFacilityType : undefined}
          onSearchBack={!facilityTypeSelected ? this.onSearchBack : undefined}
        >
          New Facility
        </ModalHeader>
        <ModalContent
          noBodyScrollLock={!ActiveForm.SelectedFacilityTypeID}
          refCallback={(ref) => this.modalContentRef = ref} paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0}>
          {!facilityTypeSelected ? this.renderFacilityTypeSelection(loading) : this.renderNewFacilityForm()}
          {loading && <PageLoader className={`${namespace()}--page--loader`} />}
        </ModalContent>
        {ActiveForm.SelectedFacilityTypeID ? <ModalActions
          sticky
          notFixed
          left={<Button textColor='black' disabled={this.newFacilityFormDisabled()} flat onClick={this.onCreate}>CREATE</Button>}
        /> : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredFacilityTypesSelector = makeFilteredFacilityTypesSelector();
  const activeFacilityTypesSelector = makeActiveFacilityTypesSelector();
  return {
    cacheZero: state.cacheZero,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    newFacilityModalForm: state.adminFacilityLocation.facilities.modals.newFacility,
    filteredFacilityTypes: filteredFacilityTypesSelector(state),
    activeFacilityTypes: activeFacilityTypesSelector(state),
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
  ...cacheOneActionCreators,
}, dispatch) });

const ConnectedNewFacilityModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(NewFacilityModal);

export default ConnectedNewFacilityModal;