
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {Row, PageLoader, EmptyMessage, Select, AdminEventCard} from '../../../../../Elements';
import { actionCreators, GetEventsList } from "../../../../../../store/AdminEvents/Events/Modals/ImportFromEvent/actions";
import '../../../../../../styles/pages/admin-events/events/modals/import-from-event/index.scss';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as cacheOneActionCreators, GetEventTypeCacheAction } from '../../../../../../store/AdminEvents/CacheOne/actions';
import { ApplicationState } from '../../../../../../store';
import { enableBodyScroll, disableBodyScroll } from '../../../../../../utils/navHelper';
import { CardCategory } from '../../../../../Elements/Card';
import { EmptyMessageType } from '../../../../../Elements/EmptyMessage';
import { Facilities2Icon } from '../../../../../Icons';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { makeFilteredEventsSelector } from '../../../../../../store/AdminEvents/Events/Modals/ImportFromEvent';
import { ADMIN_EVENTS } from '../../../../../../constants/messages/adminEvents';
import { AdminEventsEvent } from '../../../../../../models/api/adminEventsCacheOne';
import { AdminEventCardType } from '../../../../../Elements/AdminEvent/Card';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import {reduxStoreService} from '../../../../../../store/service';

export const namespace = (): string => 'pages--admin-events--modals--import-from-event';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;
interface Props {
  onSearchBack: () => void;
}

export enum ImportFromEventType {
  TIME_BLOCKS,
  PRICING,
  CLASSES,
}

@(withRouter as any)
class ImportFromEventModalSelectEvents extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;
  private listRef;

  componentDidMount() {
    disableBodyScroll(this.listRef);
  }
  componentDidUpdate() {
    disableBodyScroll(this.listRef);
    const {onSearchBack} = this.props;

    if (reduxStoreService().getState().rollback.isRollbackJustFinished) {
      onSearchBack();
    }
  }

  componentWillUnmount() {
    enableBodyScroll(this.listRef);
  }

  onEventClick = (event: AdminEventsEvent) => {
    const { actions, type, modalState: {ActiveForm, ValidationRules} } = this.props;

    if (type === ImportFromEventType.CLASSES && ActiveForm.SelectedEventType) {
      actions.getClassesList(ActiveForm.SelectedEventType, event.IDi);
    }
    this.props.onSearchBack();
    actions.updateValueWithoutSave(event.IDi, ValidationRules.SelectedEvent);
  };

  onEventTypeChange = (val, vObj) => {
    this.props.actions.getEventsList(val);
    this.props.actions.updateValueWithoutSave(val, vObj);
  };

  renderEmptyMessage = () => {
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        admin
        icon={Facilities2Icon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={ENTITY_NOT_FOUND(ADMIN_EVENTS)}
      />
    );
  };

  renderEventCard = (e: AdminEventsEvent) => {
    return (
      <AdminEventCard
        key={`event_card${e.IDi}`}
        event={e}
        type={AdminEventCardType.IMPORT_FROM_MODAL_LIST}
        category={CardCategory.LIST_MOBILE}
        onClick={this.onEventClick}
      />
    );
  };

  renderFilters = () => {
    const {type, modalState: {ActiveForm, ValidationRules}} = this.props;
    
    return (
      type === ImportFromEventType.CLASSES ? <Row className={`${namespace()}--filter`} key='filter_1'>
        <Select
          disabled={false}
          label='Event Type'
          hideOptional
          onChangeValue={this.onEventTypeChange}
          value={ActiveForm.SelectedEventType}
          validationRules={ValidationRules.SelectedEventType}
          isNumber />
      </Row> : null
    );
  };

  renderContent = (loading: boolean) => {
    const { modalState: { ActiveForm }, filteredEventsByEventType, filteredEvents, type, apiSaving } = this.props;

    return (
    !ActiveForm.SelectedEvent && !loading ? <div ref={(ref) => this.listRef = ref} className={`${namespace()}--events-list`}>
      {type === ImportFromEventType.CLASSES && (filteredEventsByEventType && filteredEventsByEventType.map(this.renderEventCard))}
      {type !== ImportFromEventType.CLASSES && (filteredEvents && filteredEvents.map(this.renderEventCard))}
      {type !== ImportFromEventType.CLASSES && (!filteredEvents || filteredEvents.length === 0) ? this.renderEmptyMessage() : null}
      {type === ImportFromEventType.CLASSES && (!filteredEventsByEventType || filteredEventsByEventType.length === 0) ? this.renderEmptyMessage() : null}
    </div> : null
    );
  };
  public render() {
    const { actions, apiLoadingMap, type, modalState: {ActiveForm}, eventFormState, apiSaving } = this.props;
    const loading = apiLoadingMap[GetEventTypeCacheAction.requestType] || apiLoadingMap[GetEventsList.requestType];

    return (
      <div className={`${namespace()}--step-wrapper`}>
        <div className={`${namespace()}--step-prompt${type === ImportFromEventType.CLASSES ? ' has-filter' : ''}`}>
          <span>{`Step 1 of ${type === ImportFromEventType.CLASSES ? '3' : '2'}`}</span>
          <span>Select Event</span>
        </div>
        {this.renderFilters()}
        {this.renderContent(loading)}
        {loading && <PageLoader className={`${namespace()}--page--loader`} />}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const importFromEventSelector = makeFormModalPropSelector(ModalTypes.IMPORT_FROM_EVENT, 'importFromEventType');
  const filteredEventsSelector = makeFilteredEventsSelector(true);
  const filteredEventsByEventTypeSelector = makeFilteredEventsSelector();

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    modalState: state.adminEvents.events.modals.importFromEvent,
    type: importFromEventSelector(state),
    eventFormState: state.adminEvents.events.event.form,
    filteredEvents: filteredEventsSelector(state),
    filteredEventsByEventType: filteredEventsByEventTypeSelector(state),
    rollback: state.rollback,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...cacheOneActionCreators,
  }, dispatch),
});

const ConnectedImportFromEventModalSelectEvents = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ImportFromEventModalSelectEvents);

export default ConnectedImportFromEventModalSelectEvents;