import * as React from 'react';
import {
  Modal, ModalHeader, ModalContent, ModalActions, Button, Paragraph
} from '../../../../Elements';
import {withRouter, RouteComponentProps} from "react-router";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../../../../store/Settings/PrevOrders/ManageOrder/actions";
import {actionCreators as appActionCreators} from "../../../../../store/App/actions";
import {ApplicationState} from "../../../../../store";
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { ModalTypes } from '../../../../../utils/modalHelper';
import {WithInertAttribute} from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--settings--order--manage';

type Props = WithInertAttribute<{}>

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

@(withRouter as any)
class DeleteOrderAndPaymentModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  onCancel = (e?: any) => {
    if (e) e.stopPropagation();
    
    this.props.actions.popModal(false, true, ModalTypes.DELETE_ORDER);
  };

  componentWillUnmount() {
  }

  onContinue = () => {
    this.props.actions.manageApiSubmitForm();
  };

  public render() {
    const {apiSaving, inert, apiLoading, manageOrders: {deleteMessage}, actions} = this.props;

    return (
      <Modal inert={inert} big mobileFullScreen higherZIndex>
        <ModalHeader>Deleting Order and Payment</ModalHeader>
        <ModalContent loading={apiSaving > 0} scrollable>
          <Paragraph preWrap>{deleteMessage}</Paragraph>
        </ModalContent>
        <ModalActions
          loading={apiSaving > 0 || apiLoading > 0}
          sticky
          notFixed
          left={<Button flat textColor="black" onClick={this.onCancel}>CANCEL</Button>}
          right={<Button textColor="green" flat onClick={this.onContinue}>CONTINUE</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    cacheOne: state.cacheOne,
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
    user: state.user,
    manageOrders: state.settings.prevOrders.manageOrder
  };
};

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
}, dispatch)});

const ConnectedDeleteModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(DeleteOrderAndPaymentModal);

export default ConnectedDeleteModal;
