import * as React from 'react';

import {
  Modal, ModalHeader, ModalContent, Column, Row, Text, View, Button, ModalActions,
  Form, FieldSet, MoneyField
} from '../../../../../Elements';
import {
  actionCreators,
  OverrideReservationChange
} from '../../../../../../store/Facilities/Trip/Modals/ReservationOverrideFee/actions';
import { bindActionCreators } from 'redux';
import '../../../../../../styles/pages/facilities/trip/modals/reservation-override-fee/index.scss';
import {
  actionCreators as reservationActionCreators,
} from '../../../../../../store/Facilities/Trip/Reservation/Reservation/actions';
import {standardCurrencyFormat} from "../../../../../../utils/classesHelper";
import {ApplicationState} from "../../../../../../store";
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { RouteComponentProps, withRouter } from 'react-router';
import { Validator } from '../../../../../../utils/validator/models';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--facilities--trip--modals--reservation-override-fee';

type Props = WithInertAttribute<{}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{resId: string}, {}>;

@(withRouter as any)
class ReservationOverrideFeeModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  public prevent = e => e.preventDefault();

  getResId = (): number | undefined => {
    const {params} = this.props;
    if (params && params.resId) {
      return Number(params.resId);
    }
    return undefined;
  };

  onChangeFlatRate = (value: any, vObj: Validator) => {
    const {
      actions, cacheThreeFacilities: {FacilityTrip}, cacheFourFacilities: {Reservation, FacilityAvailabilities},
      reservationOverrideFee: { ActiveForm }
    } = this.props;
    if (!Reservation || !FacilityTrip || !FacilityAvailabilities) return;

    actions.simpleUpdate(value, vObj);
    actions.overrideReservationChange(Reservation.Type.ID, FacilityTrip, value, ActiveForm.PerPersonRate, this.getResId(), FacilityAvailabilities.ftbID);
  };

  onChangePerPersonRate = (value: any, vObj: Validator) => {
    const {
      actions, cacheThreeFacilities: {FacilityTrip}, cacheFourFacilities: {Reservation, FacilityAvailabilities},
      reservationOverrideFee: { ActiveForm }
    } = this.props;
    if (!Reservation || !FacilityTrip || !FacilityAvailabilities) return;

    actions.simpleUpdate(value, vObj);
    actions.overrideReservationChange(Reservation.Type.ID, FacilityTrip, ActiveForm.FlatRate, value, this.getResId(), FacilityAvailabilities.ftbID);
  };

  onSave = () => {
    const {
      actions,
      reservationOverrideFee: { ActiveForm }
    } = this.props;
    if (ActiveForm.FlatRate == undefined || ActiveForm.PerPersonRate === undefined) return;

    // if (validateAll(s => s.facilities.trip.modals.reservationOverrideFee) && ActiveForm.PerPersonRate && ActiveForm.FlatRate) {
    actions.saveOverride(ActiveForm.FlatRate, ActiveForm.PerPersonRate);
    // }
  };

  public render() {
    const {
      actions, reservationOverrideFee: { accommodationName, name, amount, ActiveForm, ValidationRules }, inert,
      apiLoadingMap, apiLoading, recalcSuccess
    } = this.props;

    let total = '$0';
    if (apiLoadingMap[OverrideReservationChange.requestType] || !recalcSuccess) {
      total = 'Calculating...';
    } else {
      total = standardCurrencyFormat(amount);
    }
    return (
      <Modal inert={inert} big mobileFullScreen expand className={namespace()}>
        <ModalHeader red className={`${namespace()}--header`}>
          Override Fees
        </ModalHeader>
        <ModalContent paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0}>
          <Form
            onSubmit={this.prevent}
          >
            <Column gutter={false} layout="vertical" expand>
              <View layout="vertical"
                    paddingTop={24}
                    mobilePaddingTop={16}
                    expand
                    className={`${namespace()}--override-view`}
              >
                <Row marginBottom={4} paddingHorizontal={24} mobilePaddingHorizontal={16}>
                  <Text size={26} weight="medium">{name}</Text>
                </Row>
                <Row marginBottom={24} mobileMarginBottom={16} paddingHorizontal={24} mobilePaddingHorizontal={16}>
                  <Text size={16} color="gray">{accommodationName}</Text>
                </Row>
                <FieldSet>
                  <Row marginBottom={8} paddingHorizontal={24} mobilePaddingHorizontal={16}>
                    <Text size={15} weight="medium">Per Day Rate</Text>
                    <MoneyField
                      id={generateDOMId("per-day-rate-textfield")}
                      className={`${namespace()}--money-field`}
                      value={ActiveForm.FlatRate}
                      validationRules={ValidationRules.FlatRate}
                      onBlur={actions.updateValue}
                      onChange={this.onChangeFlatRate}
                      padding={false}
                      placeHolder="$0.00"
                      hideError
                      useFourDecimals
                    />
                  </Row>
                </FieldSet>
                <FieldSet>
                  <Row marginBottom={8} paddingHorizontal={24} mobilePaddingHorizontal={16}>
                    <Text size={15} weight="medium">Per Person Per Day Rate</Text>
                    <MoneyField
                      className={`${namespace()}--money-field`}
                      value={ActiveForm.PerPersonRate}
                      validationRules={ValidationRules.PerPersonRate}
                      onBlur={actions.updateValue}
                      onChange={this.onChangePerPersonRate}
                      padding={false}
                      placeHolder="$0.00"
                      hideError
                      useFourDecimals
                    />
                  </Row>
                </FieldSet>
                <Row className={`${namespace()}--accomondation-row`} marginBottom={24} paddingHorizontal={24} mobilePaddingHorizontal={16} verticalAlignment="middle">
                  <Text className={`${namespace()}--accomondation-name`} size={18} weight="medium" marginRight="auto">{accommodationName}</Text>
                  <Text className={`${namespace()}--accomondation-total`} size={18} weight="medium">{`${total}`}</Text>
                </Row>
              </View>
            </Column>
          </Form>
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button flat textColor="black" onClick={this.onSave} disabled={apiLoading > 0}>SAVE CHANGES</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    cacheThreeFacilities: state.cacheThreeFacilities,
    cacheFourFacilities: state.cacheFourFacilities,
    reservationOverrideFee: state.facilities.trip.modals.reservationOverrideFee,
    recalcSuccess: state.facilities.trip.reservation.reservation.recalcSuccess,
    apiLoadingMap: state.app.apiLoadingMap,
    apiLoading: state.app.apiLoading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...reservationActionCreators,
    ...actionCreators
  }, dispatch)
});

const ConnectedReservationOverrideFeeModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ReservationOverrideFeeModal);

export default ConnectedReservationOverrideFeeModal;
