import * as React from 'react';
import {default as AppHeader, Nav, NavItem, Props as AppHeaderProps} from '../../App/Header';
import {bindActionCreators} from 'redux';
import {actionCreators} from '../../../store/CacheOne/actions';
import {actionCreators as homeActionCreators} from '../../../store/Home/actions';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & AppHeaderProps;

const renderNav = (props: ConnectedProps) => {
  const {cacheOne: {FacilityLocations, EventEventTypes}, actions, home} = props;
  if (FacilityLocations && EventEventTypes) {
    return (
      <Nav>
        <NavItem
          counter={props.cacheOne.HomepageMyEvents ? props.cacheOne.HomepageMyEvents.length : 0}
          onClick={() => props.actions.selectedTab('myEvents')}
          selected={props.home.selectedTab === 'myEvents'}
        >
          My Events
        </NavItem>
        <NavItem
          counter={props.cacheOne.HomepageMyTrips ? props.cacheOne.HomepageMyTrips.length : 0}
          onClick={() => props.actions.selectedTab('myTrips')}
          selected={props.home.selectedTab === 'myTrips'}
        >
          My Trips
        </NavItem>
      </Nav>
    );
  } else if (!FacilityLocations && EventEventTypes) {
    return (
      <Nav>
        <NavItem
          counter={props.cacheOne.HomepageMyEvents ? props.cacheOne.HomepageMyEvents.length : 0}
          selected
        >
          My Events
        </NavItem>
      </Nav>
    );
  } else if (FacilityLocations && !EventEventTypes) {
    return (
      <Nav>
        <NavItem
          counter={props.cacheOne.HomepageMyTrips ? props.cacheOne.HomepageMyTrips.length : 0}
          selected
        >
          My Trips
        </NavItem>
      </Nav>
    );
  } else {
    return undefined; // @todo: what if neither?
  }
};

const mapStateToProps = (state: ApplicationState) => ({
  cacheOne: state.cacheOne,
  cacheZero: state.cacheZero,
  home: state.home,
});
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({...actionCreators, ...homeActionCreators} as any, dispatch) });

export default () => {
  const Header:React.SFC<ConnectedProps> = (props: ConnectedProps):React.ReactElement<ConnectedProps> => (
    <AppHeader
      refresh
      cart
      search
      nav={renderNav(props)}
      {...props}
    />
  );

  const ConnectedHeader = connect(
    mapStateToProps,
    mapDispatchToProps,
    getMergeProps<{}>(),
  )(Header);;

  return ConnectedHeader;
};
