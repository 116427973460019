import * as React from 'react';

import Row from "../Grid/Row";
import Column from "../Grid/Column";
import Text from "../Text";
import TextField from "../TextField";
import AdminBadge from "../AdminBadge";
import { namespace as ns } from './constants';
import '../../../styles/elements/financial-summary/line.scss';
import { Validator } from '../../../utils/validator/models';

export const namespace = (): string => `${ns()}--line`;

interface Props {
  label: string;
  disabled: boolean;
  info?: string;
  value?: string | number;
  editable?: boolean;
  admin?: boolean;
  hideError?: boolean;
  onEditChange?:( value: string | number | undefined, validationRules?: Validator) => any;
  onEditBlur?:( value: string | number | undefined, validationRules?: Validator) => any;
  editValidationRules?: Validator;
  limitPriceWidth?: boolean;
  lineSwitch?: any;
  hideTextField?: boolean;
  centerItems?: boolean;
}

const Line: React.SFC<Props> = ({ label, value, disabled, editable, admin, info, hideTextField, limitPriceWidth, onEditChange, onEditBlur, editValidationRules, hideError, lineSwitch, centerItems }: Props): React.ReactElement<Props> => {
  let extraClassNames = (info ? ' has-info' : '');
  if (centerItems) extraClassNames += ' center-items';
  return (
    <Row className={namespace() + extraClassNames}>
      <Column expand className={`${namespace()}--label` + extraClassNames}>
        <Text size={15} className={`${namespace()}--label--text` + extraClassNames}>
          {label}
        </Text>
        {info ? (
          <Text mobileMarginBottom={0} size={14} color="gray" className={`${namespace()}--label--info` + extraClassNames}>
            {info}
          </Text>
        ) : null}
        {admin ? <AdminBadge marginLeft={10} className={`${namespace()}--label--admin` + extraClassNames}/> : null}
      </Column>
      {lineSwitch && <Column className={`${namespace()}--switch`}>
        { lineSwitch }
      </Column>}
      {hideTextField ? null : <Column className={`${namespace()}--price` + extraClassNames}
              style={{width: limitPriceWidth ? '100px' : 'unset', flexDirection: 'row-reverse', alignItems: 'center'}}>
        {(editable ? (
          <TextField
            disabled={disabled}
            value={value}
            padding={false}
            align="right"
            placeHolder="$0.00"
            className={`${namespace()}--price--field` + extraClassNames}
            onChange={onEditChange ? onEditChange : undefined}
            onBlur={onEditBlur ? onEditBlur : undefined}
            validationRules={editValidationRules}
            hideOptional
            hideError={hideError}
          />
        ) : (
          <Text size={15} weight="medium" className={`${namespace()}--price--text` + extraClassNames}>
            {value}
          </Text>
        ))}
      </Column>}
    </Row>
  );
};

export default Line;
