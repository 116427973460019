import * as React from 'react';
import Grid from '../../../../images/elements/controls/view/grid.svg';
import Compact from '../../../../images/elements/controls/view/compact.svg';
import '../../../../styles/elements/controls/view/index.scss';

const namespace = (): string => `elements--controls--view`;

export type ViewControlsOptions = 'grid' | 'compact';

interface Props {
  selected: ViewControlsOptions;
  onChange: (view: ViewControlsOptions) => void;
}

const View: React.SFC<Props> = ({ selected, onChange }: Props): React.ReactElement<Props> => (
  <div className={namespace()}>
    <ul className={`${namespace()}--list`}>
      <li className={`${namespace()}--list--item`}>
        <a
          title="Grid"
          className={`${namespace()}--list--item--button` + (selected === 'grid' ? ' selected' : '')}
          onClick={() => onChange('grid')}
        >
          <Grid className={`${namespace()}--list--item--button--icon`}/>
        </a>
      </li>
      <li className={`${namespace()}--list--item`}>
        <a
          title="Compact"
          className={`${namespace()}--list--item--button` + (selected === 'compact' ? ' selected' : '')}
          onClick={() => onChange('compact')}
        >
          <Compact className={`${namespace()}--list--item--button--icon`}/>
        </a>
      </li>
    </ul>
  </div>
);

export default View;
