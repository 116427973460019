import * as React from 'react';
import { default as AppHeader } from '../../../../../App/Header';
import {RouteComponentProps, WithRouterProps} from "react-router";
import {bindActionCreators} from 'redux';
import {URLS, EVENT} from "../../../../../../constants/urls";
import {withRouter} from "react-router";
import {
  showClasses
} from "../../../../../../store/Events/Event/Register/Participant/Main/uiHelpers";
import {actionCreators} from "../../../../../../store/Events/Event/Register/Participant/Main/actions";
import {navPush} from "../../../../../../utils/navHelper";
import {ApiRosterSubmitActions} from "../../../../../../store/Events/Event/Register/Participant/Roster/actions";
import {ApiTypeSubmitActions} from "../../../../../../store/Events/Event/Register/Participant/Type/actions";
import {ApiSubmitActionsParticipantsClasses} from "../../../../../../store/Events/Event/Register/Participant/Classes/actions";
import {EventType} from "../../../../../../models/api/cacheOne";
import {EventTypeEvent} from "../../../../../../models/api/cacheTwoEvents";
import {makeSelectedEventSelector} from "../../../../../../store/CacheThreeEvents";
import {makeSelectedEventTypeSelector} from "../../../../../../store/CacheTwoEvents";
import {makeSubtitle} from "../Numbers/Header";
import {
  makeAvailableClassesNoTextFilter, makeConflictingClassesNoTextFilter,
  makeRegisteredClassesFilter
} from "../../../../../../store/CacheFourEventsParticipants";
import {
  actionCreators as typeActionCreators,
} from '../../../../../../store/Events/Event/Register/Participant/Type/actions';
import {
  actionCreators as classesActionCreators,
} from '../../../../../../store/Events/Event/Register/Participant/Classes/actions';
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';

interface HeaderSpec {
  added: boolean;
  addedLength?: number;
  search: boolean;
  onBack: () => void;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string}>;

const getHeaderSpecs = (props: ConnectedProps): HeaderSpec => {
  const {participant, roster, actions, router, params} = props;
  if (participant.selectedPage === 'roster') {
    let prevLocation = `${URLS.EVENTS}/${params.eventTypeId}/${params.eventId}/${params.name}/${EVENT.REGISTRATION}`;
    if (props.previousLocation) prevLocation = props.previousLocation.pathname;
    if (roster.selectedTab === 'roster') {
      return {added: true, search: false, onBack: () => navPush(router, prevLocation)};
    } else if (roster.selectedTab === 'new_youth') {
      return {added: false, search: false, onBack: () => navPush(router, prevLocation)};
    } else {
      return {added: false, search: false, onBack: () => navPush(router, prevLocation)};
    }
  } else if (participant.selectedPage === 'type') {
    return {added: false, search: false, onBack: () => actions.typeSubmit('roster', false)};
  } else if (participant.selectedPage === 'classes') {
    return {added: true, search: true, onBack: () => actions.classesSubmit('type', false), addedLength: props.noFilterRegisteredClasses ? props.noFilterRegisteredClasses.length : 0};
  } else {
    if (showClasses(props.noFilterAvailableClasses, props.noFilterConflictingClasses, props.noFilterRegisteredClasses)) {
      return {added: false, search: false, onBack: () => actions.selectPage('classes')};
    } else {
      return {added: false, search: false, onBack: () => actions.selectPage('type')};
    }
  }
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedEvent = makeSelectedEventSelector();
  const selectedEventType = makeSelectedEventTypeSelector();
  const noFilterAvailableClasses = makeAvailableClassesNoTextFilter();
  const noFilterRegisteredClasses = makeRegisteredClassesFilter();
  const noFilterConflictingClasses = makeConflictingClassesNoTextFilter();
  return {
    participant: state.events.event.register.participant.main,
    roster: state.events.event.register.participant.roster,
    previousLocation: state.app.previousLocation,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    selectedEvent: selectedEvent(state),
    selectedEventType: selectedEventType(state),
    noFilterAvailableClasses: noFilterAvailableClasses(state),
    noFilterRegisteredClasses: noFilterRegisteredClasses(state),
    noFilterConflictingClasses: noFilterConflictingClasses(state)
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...typeActionCreators,
  ...classesActionCreators
}, dispatch)});
// @todo: get rid of any when implementing this for real
export default ():any => {
  // @todo: subtitle
  const NumbersWizardHeader = (props: ConnectedProps): React.ReactElement<{}> => {
    const specs = getHeaderSpecs(props);
    const loading = props.apiSaving > 0 || props.apiLoadingMap[ApiRosterSubmitActions.requestType]
      || props.apiLoadingMap[ApiTypeSubmitActions.requestType] || props.apiLoadingMap[ApiSubmitActionsParticipantsClasses.requestType];
    return <AppHeader
      showDrawer
      added={specs.added}
      addedLength={specs.added ? specs.addedLength : undefined}
      search={specs.search}
      mobileSearch={specs.search}
      title="Register Participant"
      subTitle={makeSubtitle(props.selectedEvent as EventTypeEvent, props.selectedEventType as EventType)}
      onBack={specs.onBack}
    />;
  };

  const ConnectedNumbersWizardHeader = connect(
    mapStateToProps,
    mapDispatchToProps,
    getMergeProps<WithRouterProps>(),
  )(NumbersWizardHeader);
  return withRouter<{}>(ConnectedNumbersWizardHeader);
};

