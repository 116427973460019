import * as React from 'react';
import styles from '../../../styles';
import '../../../styles/elements/separator/index.scss';

export const namespace = (): string => 'elements--separator';

export interface SeparatorProps {
  className?: string;
  margin?: boolean;
  marginVertical?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  mobileMarginVertical?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
}

const Separator: React.SFC<SeparatorProps> = ({ margin, mobileMarginVertical, marginVertical, className }: SeparatorProps): React.ReactElement<SeparatorProps> => {
  const style: React.CSSProperties = {};
  if (marginVertical || marginVertical === 0) {
    style.marginTop = `${marginVertical}px`;
    style.marginBottom = `${marginVertical}px`;
  }
  if (!margin) {
    style.marginTop = '0px';
    style.marginBottom = '0px';
  }

  className = styles(namespace(), className, {
    mobileMarginVertical
  });

  return (
    <hr className={className} style={style}/>
  );
};

Separator.defaultProps = {
  margin: true
};

export default Separator;
