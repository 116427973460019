import { createSelector } from 'reselect';
import { ApplicationState } from '../../../..';
import { IValidator, FormDefinition } from './validation';
import { ResetExportInstructorRosterModal, SUFFIX, InitExportInstructorRosterModal, FilterClass, ExportInstructorRosterModalToggleFilter, ResetStepTwo } from './actions';
import { apiValidationReducerCreator } from '../../../../Validation/reducerCreator';
import { setDefaults } from '../../../../../utils/validator';
import { AdminEventChildClass, AdminEventClass } from '../../../../../models/api/adminEventsCacheTwoEvent';
import { allClassTypesSelector, filteredAdminEventClassesCombiner } from '../../../../../store/AdminEvents/Events/Event/Classes';
import { adminEventClassesSelector } from '../../../CacheTwoEvent';
import { Reducer } from 'redux';
import { Action, isActionType } from '../../../../../utils/StrongActions';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface ExportInstructorRosterModalState {
  ActiveForm: {
    IncludeBlankRows?: boolean;
    KindOfClass?: number;
    RegisterUnder?: number;
    SelectedClass?: AdminEventClass | AdminEventChildClass;
  },
  FilterText?: string;
  isTextSearching: boolean;
  ValidationRules: IValidator;
}
const getInitialState = () => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition},
});

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const filteredTextSelector = (state: ApplicationState) => state.adminEvents.events.modals.exportInstructorRoster.FilterText;
const showDeletedSelector = (state: ApplicationState) => false;
const kindOfClassSelector = (state: ApplicationState) => state.adminEvents.events.modals.exportInstructorRoster.ActiveForm.KindOfClass || 0;
const registerUnderSelector = (state: ApplicationState) => state.adminEvents.events.modals.exportInstructorRoster.ActiveForm.RegisterUnder || 0;

export const makeFilteredClassesSelector = () => {
  return createSelector(
    [adminEventClassesSelector, showDeletedSelector, filteredTextSelector, kindOfClassSelector, registerUnderSelector, allClassTypesSelector],
    filteredAdminEventClassesCombiner,
  );
};

const ExportInstructorRosterModalReducer: Reducer<ExportInstructorRosterModalState> = (s, act: WithRootState<Action>) => {
  const state = checkApiValidation(s, act);

  if (isActionType(act, FilterClass)) {
    return {
      ...state,
      FilterText: act.filterText,
    };
  } else if (isActionType(act, ExportInstructorRosterModalToggleFilter)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else  if (isActionType(act, InitExportInstructorRosterModal)) {
    const newState = {
      ...setDefaults(act.rootState, state),
    };

    if (act.selectedClass) {
      newState.ActiveForm.SelectedClass = act.selectedClass;
    }

    return newState;
  } else if (isActionType(act, ResetStepTwo)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        IncludeBlankRows: undefined,
        SelectedClass: undefined,
      },
    };
  } else if (isActionType(act, ResetExportInstructorRosterModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default ExportInstructorRosterModalReducer;