import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { namespace as ns } from './constants';
import '../../../styles/elements/side-modal/content.scss';
import { disableBodyScroll, enableBodyScroll } from '../../../utils/navHelper';
import { isMobileAndSmallerScreenSize } from '../../../utils/isMobile';

export const namespace = (): string => `${ns()}--content`;

export interface ContentProps {
  children?: React.ReactNode;
  className?: string;
  lockBodyScroll?: boolean;
  lockAllPlatform?: boolean;
  disableDynamicHeight?: boolean;
}

class Content extends React.PureComponent<ContentProps, {}> {
  private ref;

  componentDidMount() {
    if (this.props.lockBodyScroll && (isMobileAndSmallerScreenSize() || this.props.lockAllPlatform)) disableBodyScroll(this.ref);
    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    //Disable body scroll if shown as sidebar
    //See modal code - Don't need to scroll to top in landscape since sidebars should still be in sidebar mode in landscape.
    if (this.props.lockBodyScroll && (isMobileAndSmallerScreenSize() || this.props.lockAllPlatform))
      disableBodyScroll(this.ref);
    else if (!isMobileAndSmallerScreenSize())
      enableBodyScroll(this.ref, 0);
  };

  componentWillUnmount() {
    enableBodyScroll(this.ref, 0);
    window.removeEventListener('resize', this.handleResize);
  }

  public render() {
    const props = this.props;
    return (
      <div ref={(ref) => this.ref = ref} className={mergeClassNames(namespace(), props)}>
        {props.children}
      </div>
    );
  }
}

export default Content;
