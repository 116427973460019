import { RemoveRequirement, SUFFIX, UPDATE_EVENT_CLASS_TYPE_FORM_SUFFIX } from "./actions";
import {
  apiValidationReducerCreator,
  shouldSkipUpdateForm,
} from "../../../../store/Validation/reducerCreator";
import {
  IAdminEventClassTypeValidator,
  AdminEventClassTypeFormDefinition,
} from "./validation";
import { setDefaults } from "../../../../utils/validator";
import { API_UPD } from "../../../../constants/request";
import { AdminEventsCacheThreeClassType, isClassType_NewTracking } from "../../../../models/api/adminEventsCacheThreeClassType";
import { ClearAdminEventsCacheThreeClassType, GetAdminEventClassTypeAllRequirements } from "../../CacheThreeClassType/actions";
import { ClearAdminEventsCacheBelowOne } from "../../CacheOne/actions";
import {
  ClearAdminEventsCacheBelowTwoEvent,
  ClearAdminEventsCacheTwoEvent,
} from "../../CacheTwoEvent/actions";
import { isActionType } from "../../../../utils/StrongActions";
import { AnyAction, Reducer } from "redux";
import {ApplicationState} from "../../..";
import {createSelector} from "reselect";
import {allMeritBadgesSelector} from "../../../CacheZero";
import { APISuccess, WithRootState } from "../../../Validation/actionCreator";

export type AdminEventClassTypeActiveForm =
  Partial<AdminEventsCacheThreeClassType>;

export interface AdminEventClassTypeFormState {
  ActiveForm: AdminEventClassTypeActiveForm;
  ValidationRules: IAdminEventClassTypeValidator;
  SubmitErrorMessage?: string;
}

const getInitialState = () => {
  return {
    ActiveForm: {},
    ValidationRules: { ...AdminEventClassTypeFormDefinition },
  };
};

const meritBadgeIDSelector = (state: ApplicationState) => state.adminEvents.classTypes.form.ActiveForm.mbID;
export const makeSelectedMeritBadgeSelector = () => createSelector(
  [allMeritBadgesSelector, meritBadgeIDSelector],
  (meritBadges, mbID) => {
    return meritBadges.find((mb) => mb.ID === mbID);
  }
);

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const setClassType = (
  state: AdminEventClassTypeFormState,
  classType?: AdminEventsCacheThreeClassType
) => {
  const newState: AdminEventClassTypeFormState = {
    ...state,
    ActiveForm: {
      ...state.ActiveForm,
      ...classType,
    },
    ValidationRules: { ...AdminEventClassTypeFormDefinition },
  };

  return newState;
};

const AdminEventClassTypeFormReducer: Reducer<AdminEventClassTypeFormState> = (
  s,
  act: WithRootState<AnyAction>,
) => {
  const state = {
    ...getInitialState(),
    ...checkApiValidation(s, act),
  };

  if (
    act.type.includes(API_UPD) &&
    act.type.includes(UPDATE_EVENT_CLASS_TYPE_FORM_SUFFIX)
  ) {
    if (shouldSkipUpdateForm(act)) return state;

    // get initial state
    let newState = getInitialState();

    // set default values
    newState = setDefaults(act.rootState, newState, state);

    // load from cache two if set
    const rootState = act.rootState;
    if (
      rootState.adminEvents.cacheThreeClassType &&
      rootState.adminEvents.cacheThreeClassType.ClassType
    ) {
      const classType = rootState.adminEvents.cacheThreeClassType.ClassType;
      return setClassType(newState as any, classType);
    }

    return newState;
  } else if (act.type === GetAdminEventClassTypeAllRequirements.successType) {
    const newState = {...state};
    const action = <WithRootState<APISuccess>> act;

    if (action.response.response.ClassType_AllMBRequirements) {
      newState.ActiveForm.RequirementsArray = action.response.response.ClassType_AllMBRequirements
        .filter((req) => req.IsCheckable)
        .map((req) => req.ID);
    }

    return newState;
  } else if (isActionType(act, RemoveRequirement)) {
    const {reqID} = act;
    const ActiveForm = state.ActiveForm;

    if (isClassType_NewTracking(ActiveForm) && ActiveForm.RequirementsArray) {
      const index = ActiveForm.RequirementsArray.findIndex((id) => id === reqID);

      if (index !== -1) {
        const RequirementsArray = [...ActiveForm.RequirementsArray];
        RequirementsArray.splice(index, 1);
        return {
          ...state,
          ActiveForm: {
            ...state.ActiveForm,
            RequirementsArray,
          }
        };
      }
    }
  } else if (
    isActionType(act, ClearAdminEventsCacheThreeClassType) ||
    isActionType(act, ClearAdminEventsCacheBelowOne) ||
    isActionType(act, ClearAdminEventsCacheBelowTwoEvent) ||
    isActionType(act, ClearAdminEventsCacheTwoEvent)
  ) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default AdminEventClassTypeFormReducer;
