import { createValidateActions, createApiValidateActions, createApiSubmitActions, createUpdateValueMethod, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, SubmitActions, getCacheLevelExtra } from '../../../../../store/Validation/actionCreator';
import { ApplicationState, ActionCreator } from '../../../../../store';
import { scrollModalToTop } from '../../../../../utils/helpers/adminCMSPageHelper';
import { ShowTopFloatingAlert } from '../../../../../store/App/actions';
import { navPush } from '../../../../../utils/navHelper';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { SectionCreator } from '../../../../../utils/dataHelper';
import { ADMIN_EVENT_TYPES_SUFFIX, ADMIN_EVENTS_MODULE_SUFFIX, UPDATE_EVENT_TYPE_FORM_SUFFIX, GetEventTypeCacheAction } from '../../../CacheOne/actions';
import { getAdminEventsSettingsHomeRootUrl, getUpdateAdminEventTypeBody } from '../../../../../constants/adminEventsUrls';
import { ParticipantType } from '../../../../../models/api/adminEventsCacheOne';
import { getDeleteAdminEventTypeBody } from '../../../../../constants/adminEventsUrls';
import { SaveState } from '../../../../Rollback/actions';
import { ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX } from '../../../CacheTwoEvent/actions';
import { RegistrationMethodType } from '../../../CacheOne';
import { ModalTypes, isModalOpened } from '../../../../../utils/modalHelper';
import { typeName, Action } from '../../../../../utils/StrongActions';

export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + '__EVENT_TYPE_FORM' + UPDATE_EVENT_TYPE_FORM_SUFFIX + ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX;

export const getAdminEventTypePricingTierKey = (id: number) => {
  return `AdminEventTypePricingTier${id}`;
};

export enum AdminEventTypeParticipantTypeKeys {
  ID = 'ID',
  IS_YOUTH = 'IsYouth',
  NAME = 'Name',
}

export const getAdminEventTypeParticipantTypeAttrKey = (id: number, key: string) => {
  return `AdminEventTypeParticipantType${id}${key}`;
};

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const GeneralValidateActions = createValidateActions(SUFFIX + '__GENERAL');
export const GeneralApiValidateActions = createApiValidateActions(SUFFIX + '__GENERAL');
export const Pricing1ValidateActions = createValidateActions(SUFFIX + '__PRICING1');
export const Pricing1ApiValidateActions = createApiValidateActions(SUFFIX + '__PRICING1');
export const Pricing2ValidateActions = createValidateActions(SUFFIX + '__PRICING2');
export const Pricing2ApiValidateActions = createApiValidateActions(SUFFIX + '__PRICING2');
export const Pricing3ValidateActions = createValidateActions(SUFFIX + '__PRICING3');
export const Pricing3ApiValidateActions = createApiValidateActions(SUFFIX + '__PRICING3');
export const Pricing4ValidateActions = createValidateActions(SUFFIX + '__PRICING4');
export const Pricing4ApiValidateActions = createApiValidateActions(SUFFIX + '__PRICING4');
export const Pricing5ValidateActions = createValidateActions(SUFFIX + '__PRICING5');
export const Pricing5ApiValidateActions = createApiValidateActions(SUFFIX + '__PRICING5');

export const ParticipantTypeValidateActions = createValidateActions(SUFFIX + '__PARTICIPANT_TYPE');
export const ParticipantTypeApiValidateActions = createApiValidateActions(SUFFIX + '__PARTICIPANT_TYPE');

export const NamedParticipants_YouthValidateActions = createValidateActions(SUFFIX + '__NAMED_PARTICIPANTS_YOUTH');
export const NamedParticipants_YouthApiValidateActions = createApiValidateActions(SUFFIX + '__NAMED_PARTICIPANTS_YOUTH');
export const NamedParticipants_AdultsValidateActions = createValidateActions(SUFFIX + '__NAMED_PARTICIPANTS_ADULTS');
export const NamedParticipants_AdultsApiValidateActions = createApiValidateActions(SUFFIX + '__NAMED_PARTICIPANTS_ADULTS');
export const NamedParticipants_OAFieldsValidateActions = createValidateActions(SUFFIX + '__NAMED_PARTICIPANTS_OAFIELDS');
export const NamedParticipants_OAFieldsApiValidateActions = createApiValidateActions(SUFFIX + '__NAMED_PARTICIPANTS_OAFIELDS');

export const GroupRegistrationValidateActions = createValidateActions(SUFFIX + '__GROUP_REGISTRATION');
export const GroupRegistrationApiValidateActions = createApiValidateActions(SUFFIX + '__GROUP_REGISTRATION');
export const ClassSchedulingValidateActions = createValidateActions(SUFFIX + '__CLASS_SCHEDULING');
export const ClassSchedulingApiValidateActions = createApiValidateActions(SUFFIX + '__CLASS_SCHEDULING');

export const UpdateAdminEventTypeSubmitActions = createApiSubmitActions(SUFFIX);
export const UpdateAdminEventTypeSubmitGeneralActions = createApiSubmitActions(SUFFIX + '__GENERAL');
export const UpdateAdminEventTypeSubmitPricing1Actions = createApiSubmitActions(SUFFIX + '__PRICING1');
export const UpdateAdminEventTypeSubmitPricing2Actions = createApiSubmitActions(SUFFIX + '__PRICING2');
export const UpdateAdminEventTypeSubmitPricing3Actions = createApiSubmitActions(SUFFIX + '__PRICING3');
export const UpdateAdminEventTypeSubmitPricing4Actions = createApiSubmitActions(SUFFIX + '__PRICING4');
export const UpdateAdminEventTypeSubmitPricing5Actions = createApiSubmitActions(SUFFIX + '__PRICING5');
export const UpdateAdminEventTypeSubmitParticipantTypesActions = createApiSubmitActions(SUFFIX + '__PARTICIPANT_TYPE');
export const UpdateAdminEventTypeSubmitClassSchedulingActions = createApiSubmitActions(SUFFIX + '__CLASS_SCHEDULING');
export const UpdateAdminEventTypeSubmitGroupRegistrationActions = createApiSubmitActions(SUFFIX + '__GROUP_REGISTRATION');

export const DeleteAdminEventTypeSubmitActions = createApiSubmitActions(SUFFIX + '__DEL');

@typeName('ADD_PARTICIPANT_TYPE' + SUFFIX)
export class AddParticipantType extends Action { constructor() { super(); }}
@typeName('REMOVE_PARTICIPANT_TYPE' + SUFFIX)
export class RemoveParticipantType extends Action { constructor(public pt: ParticipantType, public index: number, public replacementId?: number) { super(); }}

export const EventTypeFormModalNamespace = (): string => 'pages--events--modal--event-type-form';

const GeneralSectionKeys = {
  Name: true,
  Description: true,
  LeadersGuideURL: true,
  WebsiteURL: true,
  LocationID: true,
  EventCategoryID: true,
  DistrictIDi: true,
  PrimaryContactName: true,
  PrimaryContactPhone: true,
  PrimaryContactEmail: true,
  NewRegistrationMessageID: true,
  AdditionalNotify1_Name: true,
  AdditionalNotify1_Email: true,
  AdditionalNotify2_Name: true,
  AdditionalNotify2_Email: true,
};

const Pricing1SectionKeys = {
  AllowSelfRegistration: true,
  MinNumberOfParticipants: true,
  AllowIncreasesToRegistration: true,
};

const Pricing2SectionKeys = {
  UpfrontPaymentID: true,
  UpfrontPaymentSpecific_Youth: true,
  UpfrontPaymentSpecific_Adults: true,
  MultipleEventDiscountForYouth: true,
  MultipleEventDiscountID: true,
  MultipleEventDiscountAmount: true,
};

const Pricing3SectionKeys = {
  PricingLevelsByDate_Early: true,
  PricingLevelsByDate_Base: true,
  PricingLevelsByDate_Late: true,
  AllowLateRegistrations: true,
  NumberOfDaysBeforeEventToCloseRegistration: true,
};

const Pricing4SectionKeys = {
  LockInPricingBasedOnID: true,
};

const Pricing5SectionKeys = {
  AllowCCPayment: true,
  CCFeePercent: true,
  AllowECheckPayment: true,
  eCheckFeePercent: true,
};

const NamedParticipantYouthSectionKeys = {
  ShowYouth: true,
  ShowGenderYouth: true,
  RequireGenderYouth: true,
  ShowDOBYouth: true,
  RequireDOBYouth: true,
  ShowAllergiesAndDietaryYouth: true,
  ShowSpecialNeedsNotesYouth: true,
  ShowRegistrationNotesYouth: true,
  RequireEmailYouth: true,
  ShowPhoneYouth: true,
  RequirePhoneYouth: true,
  ShowAddressYouth: true,
  RequireAddressYouth: true,
  ShowEmergencyContact: true,
  RequireEmergencyContact: true,
  ShowAllowPhotographyYouth: true,
  ShowShirtSizeYouth: true,
  RequireShirtSizeYouth: true,
  ShowGradeYouth: true,
  RequireGradeYouth: true,
  RequireRankYouth: true,
  ShowSummerCampYearYouth: true,
  ShowUnitLeaderYouth: true,
  RequireUnitLeaderYouth: true,
  ShowUnitPositionYouth: true,
  RequireUnitPositionYouth: true,
  ShowBSAIDYouth: true,
  RequireBSAIDYouth: true,
  ShowIsStaffYouth: true,
};

const NamedParticipantAdultSectionKeys = {
  ShowAdults: true,
  RequireGenderAdult: true,
  ShowDOBAdult: true,
  RequireDOBAdult: true,
  ShowAllergiesAndDietaryAdult: true,
  ShowSpecialNeedsNotesAdult: true,
  ShowRegistrationNotesAdult: true,
  RequireEmailAdult: true,
  ShowPhoneAdult: true,
  RequirePhoneAdult: true,
  ShowAddressAdult: true,
  RequireAddressAdult: true,
  ShowShirtSizeAdult: true,
  RequireShirtSizeAdult: true,
  ShowAllowPhotographyAdult: true,
  ShowUnitPositionAdult: true,
  RequireUnitPositionAdult: true,
  ShowDateYouthProtectionTrainingAdult: true,
  RequireDateYouthProtectionTrainingAdult: true,
  ShowDateBalooTrainingAdult: true,
  RequireDateBalooTrainingAdult: true,
  ShowDateBasicTrainingForPositionAdult: true,
  RequireDateBasicTrainingForPositionAdult: true,
  ShowDaysInCampAdult: true,
  ShowBSAIDAdult: true,
  RequireBSAIDAdult: true,
};

const NamedParticipantOAFieldsSectionKeys = {
  ShowOAFields: true,
};

const GroupAndRegistrationSectionKeys = {
  DefaultGroupTypeID: true,
  DefaultUnitTypeID: true,
  ShowGroupTypeDuringAccountCreation: true,
  ShowYouth: true,
  ShowAdults: true,
  AllowNonScouterParticipants: true,
  PaymentRequirementsToAddParticipantsID: true,
  PaymentRequirementsMinimumPercentage: true,
  ShowCampsiteRanking: true,
  RequireCampsiteRanking: true,
  ShowEndUserRegistrationNotes: true,
  RequireEndUserRegistrationNotes: true,
  PromptEndUserRegistrationNotes: true,
  ShowEventContact: true,
  RequireEventContact: true,
};

const ClassSchedulingSectionKeys = {
  EnableClassWaitingListForIndividuals: true,
  ShowFullClassesForIndividuals: true,
  ShowFullClassesGroup: true,
  RequireUniqueClassTypes: true,
  ShowClassLocation: true,
  CustomLabelForParticipantClasses: true,
  MinimumScheduledTimeBlocks_Youth: true,
  MinimumScheduledTimeBlocks_Adults: true,
  MinimumScheduledTimeBlocks_Group: true,
};

const formCreator = (rootState: ApplicationState, body: any, section?: EventTypeFormSection) => {
  const eventTypeForm = rootState.adminEvents.eventTypes.eventType.form;
  const eventTypeCache = rootState.adminEvents.cacheOne.EventsEventType;

  if (!section || section === EventTypeFormSection.GENERAL) {
    body.General = [];
    if (eventTypeCache) {
      body.General.push(SectionCreator(eventTypeCache.General, GeneralSectionKeys, true));
    }
    body.General.push(SectionCreator(eventTypeForm.General.ActiveForm, GeneralSectionKeys, false, eventTypeForm.General.ValidationRules));
  }

  if (!section || section === EventTypeFormSection.PRICING1) {
    body.Pricing1 = [];
    if (eventTypeCache) {
      body.Pricing1.push(SectionCreator(eventTypeCache.Pricing1, Pricing1SectionKeys, true));
    }
    body.Pricing1.push(SectionCreator(eventTypeForm.Pricing1.ActiveForm, Pricing1SectionKeys, false, eventTypeForm.Pricing1.ValidationRules));
  }

  if (!section || section === EventTypeFormSection.PRICING2) {
    body.Pricing2 = [];
    if (eventTypeCache) {
      body.Pricing2.push(SectionCreator(eventTypeCache.Pricing2, Pricing2SectionKeys, true));
    }
    body.Pricing2.push(SectionCreator(eventTypeForm.Pricing2.ActiveForm, Pricing2SectionKeys, false, eventTypeForm.Pricing2.ValidationRules));
  }

  if (!section || section === EventTypeFormSection.PRICING3) {
    body.Pricing3 = [];
    if (eventTypeCache) {
      body.Pricing3.push(SectionCreator(eventTypeCache.Pricing3, Pricing3SectionKeys, true));
    }
    body.Pricing3.push(SectionCreator(eventTypeForm.Pricing3.ActiveForm, Pricing3SectionKeys, false, eventTypeForm.Pricing3.ValidationRules));
  }

  if (!section || section === EventTypeFormSection.PRICING4) {
    body.Pricing4 = [];
    body.Pricing4_PricingTiers = [];
    body.Pricing4_ParticipantTypes = [];

    if (eventTypeCache) {
      body.Pricing4.push(SectionCreator(eventTypeCache.Pricing4, Pricing4SectionKeys, true));
      eventTypeCache.Pricing4.PricingTiers.forEach((pt) => {
        body.Pricing4_PricingTiers.push({
          IsPrevious: true,
          ID: pt.ID,
          IsEnabled: pt.IsEnabled,
        });
      });
      eventTypeCache.Pricing4.ParticipantTypes.forEach((pt) => {
        body.Pricing4_ParticipantTypes.push({
          IsPrevious: true,
          ID: pt.ID,
          IsYouth: pt.IsYouth,
          Name: pt.Name,
        });
      });
    }
    body.Pricing4.push(SectionCreator(eventTypeForm.Pricing4.ActiveForm, Pricing4SectionKeys, false, eventTypeForm.Pricing4.ValidationRules));
    eventTypeForm.Pricing4.ActiveForm.PricingTiers.forEach((pt) => {
      const key = getAdminEventTypePricingTierKey(pt.ID);
      body.Pricing4_PricingTiers.push({
        IsPrevious: false,
        ID: pt.ID,
        IsEnabled: eventTypeForm.Pricing4.ActiveForm[key],
      });
    });
    eventTypeForm.Pricing4.ActiveForm.ParticipantTypes.forEach((pt) => {
      // skip if ID < 0 and Inactive
      if (pt.ID < 0 && pt.Inactive) return;
      const nameKey = getAdminEventTypeParticipantTypeAttrKey(pt.ID, AdminEventTypeParticipantTypeKeys.NAME);
      const isYouthKey = getAdminEventTypeParticipantTypeAttrKey(pt.ID, AdminEventTypeParticipantTypeKeys.IS_YOUTH);
      body.Pricing4_ParticipantTypes.push({
        IsPrevious: false,
        ID: pt.ID,
        IsYouth: pt.ActiveForm[isYouthKey] === 0,
        Name: pt.ActiveForm[nameKey],
        Inactive: !!pt.Inactive,
        ReplacementID: pt.ReplacementID,
      });
    });
  }

  if (!section || section === EventTypeFormSection.PRICING5) {
    body.Pricing5 = [];
    if (eventTypeCache) {
      body.Pricing5.push(SectionCreator(eventTypeCache.Pricing5, Pricing5SectionKeys, true));
    }
    body.Pricing5.push(SectionCreator(eventTypeForm.Pricing5.ActiveForm, Pricing5SectionKeys, false, eventTypeForm.Pricing5.ValidationRules));
  }

  if (!section || section === EventTypeFormSection.NAMED_PARTICIPANTS) {
    body.NamedParticipants = [];
    if (eventTypeCache) {
      const Youth = SectionCreator(eventTypeCache.NamedParticipants.Youth, NamedParticipantYouthSectionKeys, true);
      const Adult = SectionCreator(eventTypeCache.NamedParticipants.Adults, NamedParticipantAdultSectionKeys, true);
      const OAFields = SectionCreator(eventTypeCache.NamedParticipants.OAFields, NamedParticipantOAFieldsSectionKeys, true);
      body.NamedParticipants.push({
        ...Youth,
        ...Adult,
        ...OAFields,
      });
    }
    const Youth = SectionCreator(eventTypeForm.NamedParticipants.Youth.ActiveForm, NamedParticipantYouthSectionKeys);
    const Adult = SectionCreator(eventTypeForm.NamedParticipants.Adults.ActiveForm, NamedParticipantAdultSectionKeys);
    const OAFields = SectionCreator(eventTypeForm.NamedParticipants.OAFields.ActiveForm, NamedParticipantOAFieldsSectionKeys);
    body.NamedParticipants.push({
      ...Youth,
      ...Adult,
      ...OAFields,
    });
  }

  if (!section || section === EventTypeFormSection.GROUP_REGISTRATION) {
    body.GroupAndRegistration = [];
    if (eventTypeCache) {
      body.GroupAndRegistration.push(SectionCreator(eventTypeCache.GroupAndRegistration, GroupAndRegistrationSectionKeys, true));
    }
    body.GroupAndRegistration.push(SectionCreator(eventTypeForm.GroupAndRegistration.ActiveForm, GroupAndRegistrationSectionKeys, false, eventTypeForm.GroupAndRegistration.ValidationRules));
  }

  if (!section || section === EventTypeFormSection.CLASS_SCHEDULING) {
    body.ClassScheduling = [];
    if (eventTypeCache) {
      body.ClassScheduling.push(SectionCreator(eventTypeCache.ClassScheduling, ClassSchedulingSectionKeys, true));
    }
    body.ClassScheduling.push(SectionCreator(eventTypeForm.ClassScheduling.ActiveForm, ClassSchedulingSectionKeys, false, eventTypeForm.ClassScheduling.ValidationRules));
  }

  if (rootState.adminEvents.cacheTwoEvent.EventsEvent) {
    body.AppState.EventIDi = rootState.adminEvents.cacheTwoEvent.EventsEvent.IDi;
  }

  return body;
};

export enum EventTypeFormSection {
  GENERAL=1,
  PRICING1,
  PRICING2,
  PRICING3,
  PRICING4,
  PRICING5,
  NAMED_PARTICIPANTS,
  GROUP_REGISTRATION,
  CLASS_SCHEDULING,
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateEventTypeRowValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form.EventTypeRow),
  simpleUpdateEventTypeRow: createSimpleUpdateValueMethod(ValidateActions),
  updateEventTypeGeneralValue: createUpdateValueMethod(GeneralValidateActions, GeneralApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form.General),
  simpleUpdateEventTypeGeneral: createSimpleUpdateValueMethod(GeneralValidateActions),

  // Pricings
  updateEventTypePricing1Value: createUpdateValueMethod(Pricing1ValidateActions, Pricing1ApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form.Pricing1),
  simpleUpdateEventTypePricing1: createSimpleUpdateValueMethod(Pricing1ValidateActions),
  updateEventTypePricing2Value: createUpdateValueMethod(Pricing2ValidateActions, Pricing2ApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form.Pricing2),
  simpleUpdateEventTypePricing2: createSimpleUpdateValueMethod(Pricing2ValidateActions),
  updateEventTypePricing3Value: createUpdateValueMethod(Pricing3ValidateActions, Pricing3ApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form.Pricing3),
  simpleUpdateEventTypePricing3: createSimpleUpdateValueMethod(Pricing3ValidateActions),
  updateEventTypePricing4Value: createUpdateValueMethod(Pricing4ValidateActions, Pricing4ApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form.Pricing4),
  simpleUpdateEventTypePricing4: createSimpleUpdateValueMethod(Pricing4ValidateActions),
  updateEventTypePricing5Value: createUpdateValueMethod(Pricing5ValidateActions, Pricing5ApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form.Pricing5),
  simpleUpdateEventTypePricing5: createSimpleUpdateValueMethod(Pricing5ValidateActions),
  updateParticipantTypeValue: createUpdateValueMethod(ParticipantTypeValidateActions, ParticipantTypeApiValidateActions, (s: ApplicationState) => s.adminEvents.events.modals.enterClassRequirement.ClassRequirements),
  simpleUpdateParticipantType: createSimpleUpdateValueMethod(ParticipantTypeValidateActions),
  
  // Named Participants
  updateEventTypeNamedParticipants_YouthValue: createUpdateValueMethod(NamedParticipants_YouthValidateActions, NamedParticipants_YouthApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form.NamedParticipants.Youth),
  simpleUpdateEventTypeNamedParticipants_Youth: createSimpleUpdateValueMethod(NamedParticipants_YouthValidateActions),
  updateEventTypeNamedParticipants_AdultsValue: createUpdateValueMethod(NamedParticipants_AdultsValidateActions, NamedParticipants_AdultsApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form.NamedParticipants.Adults),
  simpleUpdateEventTypeNamedParticipants_Adults: createSimpleUpdateValueMethod(NamedParticipants_AdultsValidateActions),
  updateEventTypeNamedParticipants_OAFieldsValue: createUpdateValueMethod(NamedParticipants_OAFieldsValidateActions, NamedParticipants_OAFieldsApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form.NamedParticipants.OAFields),
  simpleUpdateEventTypeNamedParticipants_OAFields: createSimpleUpdateValueMethod(NamedParticipants_OAFieldsValidateActions),

  updateEventTypeGroupRegistrationValue: createUpdateValueMethod(GroupRegistrationValidateActions, GroupRegistrationApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form.GroupAndRegistration),
  simpleUpdateEventTypeGroupRegistration: createSimpleUpdateValueMethod(GroupRegistrationValidateActions),
  updateEventTypeClassSchedulingValue: createUpdateValueMethod(ClassSchedulingValidateActions, ClassSchedulingApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form.ClassScheduling),
  simpleUpdateEventTypeClassScheduling: createSimpleUpdateValueMethod(ClassSchedulingValidateActions),
  deleteEventType: (EventTypeID: number, Inactive: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const state = getState() as ApplicationState;
    dispatch(DeleteAdminEventTypeSubmitActions.request(
      getDeleteAdminEventTypeBody(EventTypeID, Inactive),
      getCacheLevelExtra(
        !state.adminEvents.cacheOne.EventsEventType || state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID !== EventTypeID,
        !!state.adminEvents.cacheOne.EventsEventType && state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID === EventTypeID,
        // We are updating cache 1
        1,
      ),
    ));
  },
  apiSubmitForm: (routes, router: any, section?: EventTypeFormSection): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    const validGeneral = validateFormSection(dispatch, UpdateAdminEventTypeSubmitGeneralActions, EventTypeFormSection.GENERAL, 'General', section);
    const validPricing1 = validateFormSection(dispatch, UpdateAdminEventTypeSubmitPricing1Actions, EventTypeFormSection.PRICING1, 'Pricing1', section);
    const validPricing2 = validateFormSection(dispatch, UpdateAdminEventTypeSubmitPricing2Actions, EventTypeFormSection.PRICING2, 'Pricing2', section);
    const validPricing3 = validateFormSection(dispatch, UpdateAdminEventTypeSubmitPricing3Actions, EventTypeFormSection.PRICING3, 'Pricing3', section);
    const validPricing4 = validateFormSection(dispatch, UpdateAdminEventTypeSubmitPricing4Actions, EventTypeFormSection.PRICING4, 'Pricing4', section);
    const validPricing5 = validateFormSection(dispatch, UpdateAdminEventTypeSubmitPricing5Actions, EventTypeFormSection.PRICING5, 'Pricing5', section);
    const validClassScheduling = validateFormSection(dispatch, UpdateAdminEventTypeSubmitClassSchedulingActions, EventTypeFormSection.CLASS_SCHEDULING, 'ClassScheduling', section);
    const validGroupRegistration = validateFormSection(dispatch, UpdateAdminEventTypeSubmitGroupRegistrationActions, EventTypeFormSection.GROUP_REGISTRATION, 'GroupAndRegistration', section);

    let validParticipantTypes = true;
    if (!section || section === EventTypeFormSection.PRICING4) {
      state.adminEvents.eventTypes.eventType.form.Pricing4.ActiveForm.ParticipantTypes.forEach((pt, index) => {
        if (!pt.Inactive) {
          const valid = innerApiSubmitFormMethod(
            dispatch,
            UpdateAdminEventTypeSubmitParticipantTypesActions,
            (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form.Pricing4.ActiveForm.ParticipantTypes[index],
            undefined,
            undefined,
            isModalOpened(ModalTypes.EVENT_TYPE_SHARED_SETTINGS),
            true,
            true,
          );

          validParticipantTypes = validParticipantTypes && valid;
        }
      });
    }

    if (validGeneral && validPricing1 && validPricing2 && validPricing3 && validPricing4 && validParticipantTypes && validPricing5 && validClassScheduling && validGroupRegistration) {
      let validShowYouthAdults = true;
      if (state.adminEvents.eventTypes.eventType.form.General.ActiveForm.RegistrationMethodID === RegistrationMethodType.JUST_NUMBERS) {
        const GroupAndRegistrationForm = state.adminEvents.eventTypes.eventType.form.GroupAndRegistration.ActiveForm;
        if (!GroupAndRegistrationForm.ShowAdults && !GroupAndRegistrationForm.ShowYouth) {
          validShowYouthAdults = false;
        }
      } else {
        const NamedParticipants_AdultsForm = state.adminEvents.eventTypes.eventType.form.NamedParticipants.Adults.ActiveForm;
        const NamedParticipants_YouthForm = state.adminEvents.eventTypes.eventType.form.NamedParticipants.Youth.ActiveForm;
        if (!NamedParticipants_AdultsForm.ShowAdults && !NamedParticipants_YouthForm.ShowYouth) {
          validShowYouthAdults = false;
        }
      }
      if (!validShowYouthAdults) {
        dispatch(new ShowTopFloatingAlert('Either youth and/or adults must be enabled for this event type. Please toggle Show Youth and/or Show Adults and try again.', isModalOpened(ModalTypes.EVENT_TYPE_SHARED_SETTINGS), 'orange'));
        return;
      }

      const submitFormBody = formCreator(
        getState(),
        getUpdateAdminEventTypeBody(),
        section,
      );

      dispatch(new SaveState());
      if (section) {
        dispatch(appActionCreators.popModal(false, false, ModalTypes.EVENT_TYPE_SHARED_SETTINGS) as any);
      } else if (state.adminEvents.cacheOne.EventsEventType) {
        navPush(router, getAdminEventsSettingsHomeRootUrl({
          eventTypeId: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID,
          eventTypeName: state.adminEvents.cacheOne.EventsEventType.General.Name,
        }));
      }

      dispatch(UpdateAdminEventTypeSubmitActions.request(submitFormBody, getCacheLevelExtra(
        false,
        true,
        // We are updating cache 1
        1,
      )));
      return true;
    } else {
      if (isModalOpened(ModalTypes.EVENT_TYPE_SHARED_SETTINGS)) {
        scrollModalToTop(EventTypeFormModalNamespace());
      }
      return false;
    }
  },
  initEventTypeForm: (): ActionCreator => dispatch => {
    dispatch(GetEventTypeCacheAction.updateForm());
    dispatch(new SaveState());
  },
  addParticipantType: (): ActionCreator => dispatch => dispatch(new AddParticipantType()),
  removeParticipantType: (pt: ParticipantType, index: number, replacementId?: number): ActionCreator => dispatch => {
    dispatch(new RemoveParticipantType(pt, index, replacementId));
  },
};

const validateFormSection = (dispatch, submitActions: SubmitActions, targetSection: EventTypeFormSection, sectionKey: string, section?: EventTypeFormSection) => {
  return (!section || section === targetSection) ? innerApiSubmitFormMethod(
    dispatch,
    submitActions,
    (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form[sectionKey],
    undefined,
    undefined,
    isModalOpened(ModalTypes.EVENT_TYPE_SHARED_SETTINGS),
    true,
    true,
  ) : true;
};