import type { ApplicationState } from "../../..";
import { SelectValidator, Validator } from "../../../../utils/validator/models";


export interface IValidator {
  ShowDeleted: Validator;
  FilterText: Validator;
  SelectedCategoryID: SelectValidator;
}

const getValues = (rootState: ApplicationState) => {
  const categories = rootState.adminCMSSite.cacheOne.ContactCategories;
  if (categories) return categories;
  return [];
};

export const FormDefinition: IValidator = {
  ShowDeleted: {
    key: 'ShowDeleted',
    isRequired: () => false,
    defaultValue: () => false,
  },
  FilterText: {
    key: 'FilterText',
    isRequired: () => false,
    defaultValue: () => '',
  },
  SelectedCategoryID: {
    key: 'SelectedCategoryID',
    options: {
      values: (rootState) => [{ Name: 'All Categories', ID: 0 }, ...getValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'Name'
    },
    defaultValue: () => 0,
  },
};