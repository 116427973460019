import Session, { cookieName, SessionState } from "./Session";
import { FormDefinition as LoginFormDefinition } from "./Login/validation";
import { AppState, default as App } from "./App";
import { default as Login, LoginState } from "./Login";
import { default as ResetPassword, ResetPasswordState } from "./ResetPassword";
import { default as User, UserState } from "./User";
import { EMPTY_USER } from "./User/constants";
import { default as Rollback, RollbackState } from "./Rollback";
import {
  AccountsState,
  default as AdminModalAccounts,
} from "./Admin/Modals/Accounts";
import {
  default as EventsEventTypes,
  EventsEventTypesState,
} from "./Events/EventTypes";
import {
  default as EventsEventType,
  EventsEventTypeState,
} from "./Events/EventType";
import {
  default as EventClasses,
  EventClassesState,
} from "./Events/Event/Classes";
import {
  default as EventProducts,
  EventProductsState,
} from "./Events/Event/Products";
import { default as Event, EventState } from "./Events/Event/Main";
import {
  default as EventRegistration,
  EventRegistrationState,
} from "./Events/Event/Registration";
import {
  default as OverrideFee,
  OverrideFeeState,
} from "./Events/Event/Modals/ClassOverrideFee";
import {
  default as RegisterOverrideFee,
  RegisterOverrideFeeState,
} from "./Events/Event/Modals/RegisterOverrideFee";
import {
  default as CancelRegistration,
  CancelRegistrationState,
} from "./Events/Event/Modals/CancelRegistration";
import {
  AddUnscheduledClassesState,
  default as AddUnscheduledClasses,
} from "./Events/Event/Modals/ClassAddUnscheduled";
import {
  default as EventRegisterParticipant,
  EventRegisterParticipantState,
} from "./Events/Event/Register/Participant/Main";
import {
  default as EventRegisterParticipantRoster,
  EventRegisterParticipantRosterState,
} from "./Events/Event/Register/Participant/Roster";
import {
  default as EventRegisterNumbers,
  EventRegisterNumbersState,
} from "./Events/Event/Register/Numbers/Main";
import {
  default as EventRegisterProducts,
  EventRegisterProductsState,
} from "./Events/Event/Register/Products/Main";
import {
  default as FacilitiesLocation,
  FacilitiesLocationState,
} from "./Facilities/Locations";
import {
  default as TripSummary,
  TripSummaryState,
} from "./Facilities/Trip/Summary";
import {
  default as FacilitiesReservation,
  FacilitiesReservationState,
} from "./Facilities/Trip/Reservation";
import {
  default as ReservationOverrideFee,
  ReservationOverrideFeeState,
} from "./Facilities/Trip/Modals/ReservationOverrideFee";
import { CreateAccountState, default as CreateAccount } from "./CreateAccount";
import { AddGroupState, default as AddGroup } from "./AddGroup";
import { default as Home, HomeState } from "./Home";
import { CacheZeroState, default as CacheZero } from "./CacheZero";
import { default as Profile, ProfileState } from "./Settings/Profile";
import {
  addHeaderFilter,
  hideSnackbar,
  hideSnackbarApiFail,
  showTopFloatingAlertInQueueEpic,
} from "./App/epics";
import { loginEpic, loginForm, logoutEpic } from "./Session/epics";
import {
  emailBackendValidate,
  emailBackendValidateCancel,
  getRegisterEpic,
  submitForm,
  usernameBackendValidate,
  usernameBackendValidateCancel,
} from "./CreateAccount/epics";
import {
  addGroupSubmitForm,
  addGroupUsernameBackendValidate,
  addGroupUsernameBackendValidateCancel,
} from "./AddGroup/epics";
import { cacheTwoEventsEpic } from "./CacheTwoEvents/epics";
import { cacheFourEventsViewParticipantEpic } from "./CacheFourEventsViewParticipant/epics";
import {
  editProfileDeleteForm,
  editProfileSubmitForm,
  editProfileUsernameBackendValidate,
} from "./Settings/Profile/epics";
import {
  getGroupsEpic,
  getParticipantsEpic,
  restoreGroupEpic,
  getRegistrationsEpic,
  getTripsEpic,
  getReservationsEpic,
} from "./Admin/Modals/Accounts/epics";
import {
  eventRegistrationSaveFinanceEpic,
  reportInvoiceEpic,
  reportRequirementsCompletedEpic,
  reportBlueCardsEpic,
  reportRosterEpic,
  reportRequirementsScoutbookEpic,
} from "./Events/Event/Registration/epics";
import {
  resetPasswordSearchEpic,
  resetPasswordUpdateEpic,
  resetPasswordUpdateGroupSendResetLinkEpic,
} from "./ResetPassword/epics";
import { combineEpics } from "redux-observable";
import { combineReducers } from "redux";
import { isNode } from "../utils";
import * as Cookies from "js-cookie";
import { CacheOneState, default as CacheOne } from "./CacheOne/reducer";
import { cacheOneEpic, refreshRosterOnConflict } from "./CacheOne/epics";
import { cacheZeroEpic, cacheZeroLocationsEpic } from "./CacheZero/epics";
import { cacheThreeEventsEpic } from "./CacheThreeEvents/epics";
import {
  cacheFourEventsNumbersEpic,
  cacheFourEventsNumbersLoadPopulateEpic,
  cacheFourEventsNumbersLoadSuccessEpic,
  cacheFourEventsNumbersRecalcSuccessEpic,
  cacheFourEventsNumbersSaveEpic,
  cacheFourEventsNumbersSaveFailureEpic,
} from "./CacheFourEventsNumbers/epics";
import {
  default as FacilityLocation,
  FacilityLocationState,
} from "./Facilities/Location/index";
import {
  CacheTwoEventsState,
  default as CacheTwoEvents,
} from "./CacheTwoEvents/reducer";
import {
  CacheThreeEventsState,
  default as CacheThreeEvents,
} from "./CacheThreeEvents/index";
import {
  CacheFourEventsViewParticipantState,
  default as CacheFourEventsViewParticipant,
} from "./CacheFourEventsViewParticipant/index";
import {
  CacheFourEventsProductsState,
  default as CacheFourEventsProducts,
} from "./CacheFourEventsProducts/index";
import {
  CacheFourEventsNumbersState,
  default as CacheFourEventsNumbers,
} from "./CacheFourEventsNumbers/reducer";
import {
  default as NumbersWizardSpots,
  NumbersWizardSpotsState,
} from "./Events/Event/Register/Numbers/Spots/index";
import {
  default as NumbersWizardCampsite,
  NumbersWizardCampsiteState,
} from "./Events/Event/Register/Numbers/Campsite/index";
import {
  default as NumbersWizardClasses,
  NumbersWizardClassesState,
} from "./Events/Event/Register/Numbers/Classes/index";
import {
  default as NumbersWizardProducts,
  NumbersWizardProductsState,
} from "./Events/Event/Register/Numbers/Products/index";
import {
  default as NumbersWizardConfirmation,
  NumbersWizardConfirmationState,
} from "./Events/Event/Register/Numbers/Confirmation/index";
import {
  default as RosterAdd,
  RosterAddState,
} from "./Settings/Roster/Add/index";
import { numbersSpotsNext } from "./Events/Event/Register/Numbers/Spots/epics";
import {
  numbersAddClassValidateEpic,
  numbersClassesNext,
} from "./Events/Event/Register/Numbers/Classes/epics";
import { restoreRosterPersonEpic } from "./Settings/Roster/Main/epics";
import { cacheTwoRosterEpic } from "./CacheTwoRoster/epics";
import {
  CacheTwoRosterState,
  default as CacheTwoRoster,
} from "./CacheTwoRoster/index";
import {
  deleteRosterPersonEpic,
  updateRosterPersonEpic,
} from "./Settings/Roster/Add/epics";
import {
  cacheFourEventsParticipantsEpic,
  cacheFourEventsParticipantsFailureEpic,
  cacheFourEventsParticipantsGetPersonEpic,
  cacheFourEventsParticipantsRecalcSuccessEpic,
  cacheFourEventsParticipantsSaveEpic,
} from "./CacheFourEventsParticipants/epics";
import {
  CacheFourEventsParticipantsState,
  default as CacheFourEventsParticipants,
} from "./CacheFourEventsParticipants/reducer";
import {
  participantDeleteRoster,
  participantsRosterNext,
} from "./Events/Event/Register/Participant/Roster/epics";
import {
  default as EventRegisterParticipantType,
  EventRegisterParticipantTypeState,
} from "./Events/Event/Register/Participant/Type";
import {
  participantsTypeNext,
  participantsTypeResetEpic,
} from "./Events/Event/Register/Participant/Type/epics";
import {
  default as ParticipantsWizardClasses,
  ParticipantsWizardClassesState,
} from "./Events/Event/Register/Participant/Classes";
import {
  participantsAddClassValidateEpic,
  participantsClassesNext,
} from "./Events/Event/Register/Participant/Classes/epics";
import {
  default as ParticipantWizardConfirmation,
  ParticipantWizardConfirmationState,
} from "./Events/Event/Register/Participant/Confirmation";
import { default as Checkout, CheckoutState } from "./Checkout";
import {
  registerOverrideFeeSave,
  registerOverrideFeeSuccessEpic,
} from "./Events/Event/Modals/RegisterOverrideFee/epics";
import {
  default as CacheTwoFacilities,
  CacheTwoFacilitiesState,
} from "./CacheTwoFacilities";
import {
  changePaymentType,
  checkoutCart,
  checkoutSuccessEpic,
  checkoutFailureEpic,
} from "./Checkout/epics";
import {
  closeCartAfterEmpty,
  emptyCartFromModal,
  removeItemFromCartEpic,
  closeAfterRemoveItemEpic,
} from "./Cart/epics";
import {
  cacheTwoFacilitiesAvailabilitiesEpic,
  cacheTwoFacilitiesEpic,
} from "./CacheTwoFacilities/epics";
import {
  default as FacilityFacilityReducer,
  FacilityFacilityState,
} from "./Facilities/Facility";
import { cacheTwoBFacilitiesAvailabilitiesEpic } from "./CacheTwoBFacilities/epics";
import {
  default as CacheTwoBFacilities,
  CacheTwoBFacilitiesState,
} from "./CacheTwoBFacilities";
import { cacheThreeFacilitiesEpic } from "./CacheThreeFacilities/epics";
import {
  default as CacheThreeFacilities,
  CacheThreeFacilitiesState,
} from "./CacheThreeFacilities";
import { tripSummarySaveFinanceEpic } from "./Facilities/Trip/Summary/epics";
import {
  default as ViewReservation,
  ViewReservationState,
} from "./Facilities/Trip/ViewReservation";
import { default as TripForm, TripFormState } from "./Facilities/Trip/Form";
import { addTripEpic, deleteTripEpic } from "./Facilities/Trip/Form/epics";
import CacheFourFacilities, {
  CacheFourFacilitiesState,
} from "./CacheFourFacilities";
import {
  cacheFourFacilitiesEpic,
  cacheFourFacilitiesRecalcSuccessEpic,
  cacheFourFacilitiesSaveEpic,
  cacheFourFacilityAvailabilitiesEpic,
  cacheFourFacilityRecalcsEpic,
  cacheFourFacilitiesFailureEpic,
} from "./CacheFourFacilities/epics";
import {
  default as FacilitiesReservationType,
  FacilitiesReservationTypeState,
} from "./Facilities/Trip/Reservation/Type";
import {
  default as FacilitiesReservationReservation,
  FacilitiesReservationReservationState,
} from "./Facilities/Trip/Reservation/Reservation";
import { reservationChangeEpic } from "./Facilities/Trip/Reservation/Reservation/epics";
import {
  default as FacilitiesWizardConfirmation,
  FacilitiesWizardConfirmationState,
} from "./Facilities/Trip/Reservation/Confirmation";
import {
  default as CancelReservation,
  CancelReservationState,
} from "./Facilities/Trip/Modals/CancelReservation";
import {
  overrideReservationChangeEpic,
  closeOverrideEpic,
} from "./Facilities/Trip/Modals/ReservationOverrideFee/epics";
import { cacheTwoPrevOrdersEpic } from "./CacheTwoPrevOrders/epics";
import { cacheThreePrevOrdersEpic } from "./CacheThreePrevOrders/epics";
import {
  default as CacheThreePrevOrder,
  CacheThreePrevOrderState,
} from "./CacheThreePrevOrders";
import {
  default as CacheTwoPrevOrder,
  CacheTwoPrevOrderState,
} from "./CacheTwoPrevOrders";
import { reportAuditLogEpic } from "./Settings/PrevOrders/AuditLog/epics";
import {
  default as AuditLog,
  AuditLogState,
} from "./Settings/PrevOrders/AuditLog";
import {
  default as ManageOrder,
  ManageOrderState,
} from "./Settings/PrevOrders/ManageOrder";
import { manageOrderSaveEpic } from "./Settings/PrevOrders/ManageOrder/epics";
import { cacheThreeRosterEpic } from "./CacheThreeRoster/epics";
import {
  default as CacheThreeRoster,
  CacheThreeRosterState,
} from "./CacheThreeRoster";
import { refundOrFeeEpic } from "./Settings/PrevOrders/RefundOrFee/epics";
import {
  default as RefundOrFee,
  RefundOrFeeState,
} from "./Settings/PrevOrders/RefundOrFee";
import { changePasswordEpic } from "./Settings/ChangePassword/epics";
import { editEmailEpic } from "./Settings/EditEmail/epics";
import { default as EditEmail, EditEmailState } from "./Settings/EditEmail";
import {
  default as ChangePassword,
  ChangePasswordState,
} from "./Settings/ChangePassword";
import {
  jsonReportSuccessEpic,
  makeSavePointDuringSaveEpic,
} from "./Validation/epicCreator";
import {
  cacheFourEventsProductsEpic,
  cacheFourEventsProductsRecalcSuccessEpic,
  cacheFourEventsProductsSaveEpic,
  cacheFourEventsProductsSaveFailureEpic,
} from "./CacheFourEventsProducts/epics";
import {
  ProductsWizardProductsState,
  default as ProductsWizardProducts,
} from "./Events/Event/Register/Products/Products";
import { numbersProductsNext } from "./Events/Event/Register/Numbers/Products/epics";
import { productsProductsNext } from "./Events/Event/Register/Products/Products/epics";
import {
  default as ProductsWizardConfirmation,
  ProductsWizardConfirmationState,
} from "./Events/Event/Register/Products/Confirmation";
import { AdminCMSSiteCacheOneState } from "./AdminCMSSite/CacheOne";
import { adminCMSSiteCacheOneEpic } from "./AdminCMSSite/CacheOne/epics";
import { ResourcesHomeState } from "./AdminCMSSite/Resources/Home";
import { ResourceFormState } from "./AdminCMSSite/Resources/Resource/Form";
import { ResourceCategoryModalState } from "./AdminCMSSite/Resources/Modals/ResourceCategory";
import { ResourceCategoryFormState } from "./AdminCMSSite/Resources/Modals/ResourceCategoryForm";
import { adminCMSSiteCacheTwoResourcesEpic } from "./AdminCMSSite/CacheTwoResources/epics";
import { adminCMSSiteCacheTwoContactsEpic } from "./AdminCMSSite/CacheTwoContacts/epics";
import { AdminCMSSiteCacheTwoResourcesState } from "./AdminCMSSite/CacheTwoResources";
import { GeneralSettingsFormState } from "./AdminCMSSite/Settings/General";
import { MessagesSettingsFormState } from "./AdminCMSSite/Settings/Messages";
import { ContactsHomeState } from "./AdminCMSSite/Contacts/Home";
import { ContactFormState } from "./AdminCMSSite/Contacts/Contact/Form";
import { AdminCMSSiteCacheTwoContactsState } from "./AdminCMSSite/CacheTwoContacts";
import { AdminCMSSiteCacheTwoSiteMenuItemsState } from "./AdminCMSSite/CacheTwoSiteMenus";
import { SiteMenuItemFormState } from "./AdminCMSSite/Menus/SiteMenuItem/Form";
import { adminCMSSiteCacheTwoSiteMenuItemsEpic } from "./AdminCMSSite/CacheTwoSiteMenus/epics";
import { SettingsMainState } from "./AdminCMSSite/Settings/Main";
import { MenusHomeState } from "./AdminCMSSite/Menus/Home";
import { ResourceModalState } from "./AdminCMSSite/Resources/Modals/Resource";
import { PageMenuItemFormState } from "./AdminCMSSite/Menus/Modals/PageMenuItem";
import { adminCMSSiteCacheTwoPageMenuItemsEpic } from "./AdminCMSSite/CacheTwoPageMenus/epics";
import { AdminCMSSiteCacheTwoPageMenuItemsState } from "./AdminCMSSite/CacheTwoPageMenus";
import { NewPageModalState } from "./AdminCMSSite/Pages/Modals/NewPage";
import { LocationModalState } from "./AdminSettings/Modals/Location";
import { LocationFormState } from "./AdminSettings/Modals/LocationForm";
import { adminSettingsCacheTwoLocationEpic } from "./AdminSettings/CacheTwoLocation/epics";
import { AdminSettingsCacheTwoLocationState } from "./AdminSettings/CacheTwoLocation";
import { AdminCMSSiteCacheTwoPageState } from "./AdminCMSSite/CacheTwoPages";
import { adminCMSSiteCacheTwoPageEpic } from "./AdminCMSSite/CacheTwoPages/epics";
import { PageFormState } from "./AdminCMSSite/Pages/Page/Form";
import { MultipleContactsModalState } from "./AdminCMSSite/Contacts/Modals/MultipleContacts";
import { MultipleResourcesModalState } from "./AdminCMSSite/Resources/Modals/MultipleResources";
import { ImageFormState } from "./AdminCMSSite/Pages/Modals/ImageForm";
import { DuplicatePageFormState } from "./AdminCMSSite/Pages/Modals/DuplicatePageForm";
import {
  adminCMSSiteAddResourceEpic,
  adminCMSSiteDeleteResourceEpic,
} from "./AdminCMSSite/Resources/Resource/Form/epics";
import {
  adminCMSSiteAddResourceCategoryEpic,
  adminCMSSiteDeleteResourceCategoryEpic,
} from "./AdminCMSSite/Resources/Modals/ResourceCategoryForm/epics";
import {
  adminCMSSiteAddContactEpic,
  adminCMSSiteDeleteContactEpic,
} from "./AdminCMSSite/Contacts/Contact/Form/epics";
import {
  getDefaultForPrimaryEventPageEpic,
  editPageUpdateSiteMenuItemEpic,
  adminCMSSiteEditGalleryImageEpic,
  adminCMSSiteUpdateGalleryImageEpic,
  adminCMSSiteDeleteRestorePageEpic,
  adminCMSSiteUpdatePageEpic,
} from "./AdminCMSSite/Pages/Page/Form/epics";
import {
  adminCMSSiteAddSiteMenuEpic,
  adminCMSSiteDeleteSiteMenuEpic,
  adminCMSSiteAddPageMenuEpic,
  adminCMSSiteDeletePageMenuEpic,
  adminCMSSiteAddPageMenuModalEpic,
} from "./AdminCMSSite/Menus/SiteMenuItem/Form/epics";
import {
  adminCMSSiteUpdateSiteMenuOrderEpic,
  adminCMSSiteUpdatePageMenuOrderEpic,
} from "./AdminCMSSite/Menus/Home/epics";
import { adminCMSSiteAddLocationEpic } from "./AdminSettings/Modals/LocationForm/epics";
import { adminCMSSiteAddPageEpic } from "./AdminCMSSite/Pages/Modals/NewPage/epics";
import { adminCMSSiteGeneralSettingsSubmitEpic } from "./AdminCMSSite/Settings/General/epics";
import { adminCMSSiteMessagesSettingsSubmitEpic } from "./AdminCMSSite/Settings/Messages/epics";
import { adminCMSSiteDuplicatePageEpic } from "./AdminCMSSite/Pages/Modals/DuplicatePageForm/epics";
import { injectReducers } from "../config/createStore";
import { AdminFacilityLocationCacheOneState } from "./AdminFacilityLocation/CacheOne";
import {
  adminFacilityLocationCacheOneEpic,
  adminFacilityLocationGetAvailabilitiesEpic,
} from "./AdminFacilityLocation/CacheOne/epics";
import { FacilitiesHomeState } from "./AdminFacilityLocation/Facilities/Home";
import { adminFacilityLocationCacheTwoFacilityTypesEpic } from "./AdminFacilityLocation/CacheTwoFacilityTypes/epics";
import { AdminFacilityLocationCacheTwoFacilityTypesState } from "./AdminFacilityLocation/CacheTwoFacilityTypes";
import { FacilityTypeFormState } from "./AdminFacilityLocation/Facilities/FacilityType/Form";
import { NewFacilityModalState } from "./AdminFacilityLocation/Facilities/Modals/NewFacility";
import { adminFacilityLocationCacheTwoFacilityEpic } from "./AdminFacilityLocation/CacheTwoFacility/epics";
import { AdminFacilityLocationCacheTwoFacilityState } from "./AdminFacilityLocation/CacheTwoFacility";
import { FacilityFormState } from "./AdminFacilityLocation/Facilities/Facility/Form";
import { ImportFromFacilityModalState } from "./AdminFacilityLocation/Facilities/Modals/ImportFromFacility";
import { FacilitiesSettingsFormState } from "./AdminFacilityLocation/Settings";
import { FacilitiesReportsFormState } from "./AdminFacilityLocation/Reports";
import {
  reportGroupTripsEpic,
  reportGroupReservationsEpic,
} from "./AdminFacilityLocation/Reports/epics";
import { BlackoutDatesHomeState } from "./AdminFacilityLocation/BlackoutDates/Home";
import { adminFacilityLocationCacheTwoBlackoutDatesEpic } from "./AdminFacilityLocation/CacheTwoBlackoutDates/epics";
import { AdminFacilityLocationCacheTwoBlackoutDatesState } from "./AdminFacilityLocation/CacheTwoBlackoutDates";
import { BlackoutDateFormState } from "./AdminFacilityLocation/BlackoutDates/Form";
import { MultipleFacilitiesModalState } from "./AdminFacilityLocation/Facilities/Modals/MultipleFacilities";
import { AvailabilitiesHomeState } from "./AdminFacilityLocation/Availabilities/Home";
import { adminFacilityLocationAvailabilitiesEpic } from "./AdminFacilityLocation/Availabilities/Home/epics";
import { GLAccountModalState } from "./AdminSettings/GLAccounts/Modals/GLAccount";
import { GLAccountFormState } from "./AdminSettings/GLAccounts/GLAccount/Form";
import { adminSettingsCacheTwoGLAccountEpic } from "./AdminSettings/CacheTwoGLAccount/epics";
import { AdminSettingsCacheTwoGLAccountState } from "./AdminSettings/CacheTwoGLAccount";
import { adminSettingsAddGLAccountEpic } from "./AdminSettings/GLAccounts/GLAccount/Form/epics";
import { adminFacilityLocationSettingsSubmitEpic } from "./AdminFacilityLocation/Settings/epics";
import {
  adminFacilityLocationUpdateBlackoutDateEpic,
  adminFacilityLocationDeleteBlackoutDateEpic,
} from "./AdminFacilityLocation/BlackoutDates/Form/epics";
import {
  adminFacilityLocationUpdateFacilityTypeEpic,
  adminFacilityLocationDeleteFacilityTypeEpic,
} from "./AdminFacilityLocation/Facilities/FacilityType/Form/epics";
import { adminFacilityLocationAddFacilityEpic } from "./AdminFacilityLocation/Facilities/Modals/NewFacility/epics";
import {
  adminFacilityLocationDeleteFacilityEpic,
  adminFacilityLocationImportBookingTimesEpic,
  adminFacilityLocationImportPricingEpic,
  adminFacilityLocationImportImagesEpic,
  adminFacilityLocationUpdateFacilityEpic,
  adminFacilityUpdateImageEpic,
} from "./AdminFacilityLocation/Facilities/Facility/Form/epics";
import { EventTypesModalState } from "./AdminEvents/EventTypes/Modals/SelectEventTypes";
import {
  getAdminEventTypesEpic,
  getAdminEventsCacheOneEpic,
} from "./AdminEvents/CacheOne/epics";
import { AdminEventsCacheTwoEventTypeState } from "./AdminEvents/CacheTwoEventType";
import { EventTypeFormState } from "./AdminEvents/EventTypes/EventType/Form";
import { AdminEventsCacheOneState } from "./AdminEvents/CacheOne";
import { EventsHomeState } from "./AdminEvents/Events/Home";
import { EventFormState } from "./AdminEvents/Events/Event/Form";
import { ProductsHomeState } from "./AdminEvents/Products/Home";
import { AdminEventsReportsFormState } from "./AdminEvents/Reports/GenerateReports";
import { AdminEventsYearOverviewFormState } from "./AdminEvents/Reports/YearOverview";
import { MessageCenterHomeState } from "./AdminEvents/MessageCenter/Home";
import { AdminEventsEventClassesTabState } from "./AdminEvents/Events/Event/Classes";
import { AdminEventsEventTimeBlocksTabState } from "./AdminEvents/Events/Event/TimeBlocks";
import { AdminEventsEventClassTypesTabState } from "./AdminEvents/Events/Event/ClassTypes";
import { AdminEventsCacheTwoEventState } from "./AdminEvents/CacheTwoEvent";
import { adminEventsCacheTwoEventEpic } from "./AdminEvents/CacheTwoEvent/epics";
import { SelectMessageModalState } from "./AdminEvents/MessageCenter/Modals/SelectMessage";
import { ReplaceParticipantTypeModalState } from "./AdminEvents/EventTypes/Modals/ReplaceParticipantType";
import { ImportFromEventModalState } from "./AdminEvents/Events/Modals/ImportFromEvent";
import {
  adminEventImportClassEpic,
  adminEventImportPricingEpic,
  adminEventImportTimeBlocksEpic,
  adminEventsGetEventsListEpic,
  getSourceClassesListEpic,
} from "./AdminEvents/Events/Modals/ImportFromEvent/epics";
import { NewEventTypeFormState } from "./AdminEvents/EventTypes/Modals/NewEventType";
import { AdminEventsEventDashboardState } from "./AdminEvents/Events/Event/Dashboard";
import { ExportInstructorRosterModalState } from "./AdminEvents/Events/Modals/ExportInstructorRoster";
import { GenerateInvoicesModalState } from "./AdminEvents/Events/Modals/GenerateInvoices";
import { MultipleGroupsModalState } from "./AdminEvents/Events/Modals/MultipleGroups";
import { adminEventsCacheThreeClassTypeEpic, adminEventsClassTypeAllRequirementsEpic } from "./AdminEvents/CacheThreeClassType/epics";
import { AdminEventsCacheThreeClassTypeState } from "./AdminEvents/CacheThreeClassType";
import { AdminEventClassTypeFormState } from "./AdminEvents/ClassTypes/Form";
import { adminEventsCacheThreeClassEpic } from "./AdminEvents/CacheThreeClass/epics";
import { AdminEventsCacheThreeClassState } from "./AdminEvents/CacheThreeClass";
import { AdminEventClassFormState } from "./AdminEvents/Classes/Form";
import { adminEventsCacheTwoProductEpic } from "./AdminEvents/CacheTwoProduct/epics";
import { AdminEventsCacheTwoProductState } from "./AdminEvents/CacheTwoProduct";
import { AdminEventProductFormState } from "./AdminEvents/Products/Form";
import { adminEventsCacheTwoMessageEpic } from "./AdminEvents/CacheTwoMessage/epics";
import { AdminEventsCacheTwoMessageState } from "./AdminEvents/CacheTwoMessage";
import { AdminEventMessageFormState } from "./AdminEvents/MessageCenter/Form";
import { SendMessageModalState } from "./AdminEvents/MessageCenter/Modals/SendMessage";
import { EnterClassRequirementModalState } from "./AdminEvents/Events/Modals/EnterClassRequirement";
import {
  adminEventsCacheThreeClassRequirementsEpic,
  adminEventsUpdateClassRequirementsEpic,
} from "./AdminEvents/CacheThreeClassRequirements/epics";
import { AdminEventsCacheThreeClassRequirementsState } from "./AdminEvents/CacheThreeClassRequirements";
import { SelectClassTypeModalState } from "./AdminEvents/Events/Modals/SelectClassType";
import {
  adminEventsAddEventTypeEpic,
  getAdminEventsTypeEpic,
} from "./AdminEvents/EventTypes/Modals/NewEventType/epics";
import { adminSettingsGetGLAccountsEpic } from "./AdminSettings/GLAccounts/Modals/GLAccount/epics";
import { AdminEventsSharedState } from "./AdminEvents/Shared";
import { getRegisteredGroupsEpic } from "./AdminEvents/Shared/epics";
import { generateAdminEventsEventTypeReportsEpic } from "./AdminEvents/Reports/GenerateReports/epics";
import {
  generateAdminEventsEventReportsEpic,
  recalculatePricingEpic,
  sendToClientsEpic,
} from "./AdminEvents/Events/Event/Dashboard/epics";
import { ExportParticipantClassScheduleModalState } from "./AdminEvents/Events/Modals/ExportParticipantClassSchedule";
import {
  adminEventsDeleteMessageEpic,
  adminEventsUpdateMessageEpic,
} from "./AdminEvents/MessageCenter/Form/epics";
import {
  adminEventsDeleteProductEpic,
  adminEventsUpdateProductEpic,
} from "./AdminEvents/Products/Form/epics";
import {
  adminEventsDeleteClassTypeEpic,
  adminEventsUpdateClassTypeEpic,
} from "./AdminEvents/ClassTypes/Form/epics";
import {
  adminEventsDeleteEventTypeEpic,
  adminEventsUpdateEventTypeEpic,
} from "./AdminEvents/EventTypes/EventType/Form/epics";
import {
  adminEventsUpdateEventEpic,
  adminEventsDeleteEventEpic,
} from "./AdminEvents/Events/Event/Form/epics";
import { adminEventsUpdateTimeBlocksEpic } from "./AdminEvents/Events/Event/TimeBlocks/epics";
import {
  adminEventsDeleteClassEpic,
  adminEventsUpdateClassEpic,
} from "./AdminEvents/Classes/Form/epics";
import { numbersCampsitesNext } from "./Events/Event/Register/Numbers/Campsite/epics";
import { ActionCreatorGeneric } from "../utils/StrongActions";
import { PageTypeModalState } from "./AdminCMSSite/Pages/Modals/SelectPage/index.type";
import { PagesHomeState } from "./AdminCMSSite/Pages/Home/index.types";
import { SelectMeritBadgeModalState } from "./AdminEvents/Events/Modals/SelectMeritBadge";
import {ManageRequirementsModalState} from "./AdminEvents/Events/Modals/ManageRequirements";
import { RouterState } from "react-router-redux";

export type ApplicationState = {
  cacheZero: CacheZeroState;
  cacheOne: CacheOneState;
  cacheTwoFacilities: CacheTwoFacilitiesState;
  cacheTwoBFacilities: CacheTwoBFacilitiesState;
  cacheThreeFacilities: CacheThreeFacilitiesState;
  cacheFourFacilities: CacheFourFacilitiesState;
  cacheTwoRoster: CacheTwoRosterState;
  cacheThreeRoster: CacheThreeRosterState;
  cacheTwoPrevOrders: CacheTwoPrevOrderState;
  cacheThreePrevOrders: CacheThreePrevOrderState;
  cacheTwoEvents: CacheTwoEventsState;
  cacheThreeEvents: CacheThreeEventsState;
  cacheFourEventsViewParticipant: CacheFourEventsViewParticipantState;
  cacheFourEventsNumbers: CacheFourEventsNumbersState;
  cacheFourEventsParticipants: CacheFourEventsParticipantsState;
  cacheFourEventsProducts: CacheFourEventsProductsState;
  adminCMSSite: {
    cacheOne: AdminCMSSiteCacheOneState;
    cacheTwoResources: AdminCMSSiteCacheTwoResourcesState;
    cacheTwoContacts: AdminCMSSiteCacheTwoContactsState;
    cacheTwoSiteMenuItems: AdminCMSSiteCacheTwoSiteMenuItemsState;
    cacheTwoPageMenuItems: AdminCMSSiteCacheTwoPageMenuItemsState;
    cacheTwoPage: AdminCMSSiteCacheTwoPageState;
    resources: {
      home: ResourcesHomeState;
      modals: {
        resource: ResourceModalState;
        resourceCategory: ResourceCategoryModalState;
        resourceCategoryForm: ResourceCategoryFormState;
        multipleResources: MultipleResourcesModalState;
      };
      resource: {
        form: ResourceFormState;
      };
    };
    contacts: {
      home: ContactsHomeState;
      contact: {
        form: ContactFormState;
      };
      modals: {
        multipleContacts: MultipleContactsModalState;
      };
    };
    menus: {
      siteMenuItem: {
        form: SiteMenuItemFormState;
      };
      modals: {
        pageMenuItem: PageMenuItemFormState;
      };
      home: MenusHomeState;
    };
    settings: {
      main: SettingsMainState;
      general: GeneralSettingsFormState;
      messages: MessagesSettingsFormState;
    };
    pages: {
      modals: {
        pageType: PageTypeModalState;
        newPage: NewPageModalState;
        imageForm: ImageFormState;
        duplicatePageForm: DuplicatePageFormState;
      };
      page: {
        form: PageFormState;
      };
      home: PagesHomeState;
    };
  };
  adminFacilityLocation: {
    cacheOne: AdminFacilityLocationCacheOneState;
    cacheTwoFacilityTypes: AdminFacilityLocationCacheTwoFacilityTypesState;
    cacheTwoFacility: AdminFacilityLocationCacheTwoFacilityState;
    cacheTwoBlackoutDate: AdminFacilityLocationCacheTwoBlackoutDatesState;
    facilities: {
      home: FacilitiesHomeState;
      facilityType: {
        form: FacilityTypeFormState;
      };
      facility: {
        form: FacilityFormState;
      };
      modals: {
        newFacility: NewFacilityModalState;
        importFromFacility: ImportFromFacilityModalState;
        multipleFacilities: MultipleFacilitiesModalState;
      };
    };
    blackoutDates: {
      home: BlackoutDatesHomeState;
      form: BlackoutDateFormState;
    };
    availabilities: {
      home: AvailabilitiesHomeState;
    };
    reports: FacilitiesReportsFormState;
    settings: FacilitiesSettingsFormState;
  };
  adminEvents: {
    cacheOne: AdminEventsCacheOneState;
    cacheTwoEvent: AdminEventsCacheTwoEventState;
    cacheTwoEventType: AdminEventsCacheTwoEventTypeState;
    cacheTwoProduct: AdminEventsCacheTwoProductState;
    cacheTwoMessage: AdminEventsCacheTwoMessageState;
    cacheThreeClass: AdminEventsCacheThreeClassState;
    cacheThreeClassRequirements: AdminEventsCacheThreeClassRequirementsState;
    cacheThreeClassType: AdminEventsCacheThreeClassTypeState;
    shared: AdminEventsSharedState;
    events: {
      home: EventsHomeState;
      event: {
        form: EventFormState;
        classes: {
          classesTab: AdminEventsEventClassesTabState;
          timeBlocksTab: AdminEventsEventTimeBlocksTabState;
          classTypesTab: AdminEventsEventClassTypesTabState;
        };
        dashboard: AdminEventsEventDashboardState;
      };
      modals: {
        generateInvoices: GenerateInvoicesModalState;
        importFromEvent: ImportFromEventModalState;
        exportInstructorRoster: ExportInstructorRosterModalState;
        exportParticipantClassSchedule: ExportParticipantClassScheduleModalState;
        multipleGroups: MultipleGroupsModalState;
        enterClassRequirement: EnterClassRequirementModalState;
        selectClassType: SelectClassTypeModalState;
        selectMeritBadge: SelectMeritBadgeModalState;
        manageRequirements: ManageRequirementsModalState;
      };
    };
    classTypes: {
      form: AdminEventClassTypeFormState;
    };
    classes: {
      form: AdminEventClassFormState;
    };
    products: {
      home: ProductsHomeState;
      form: AdminEventProductFormState;
    };
    eventTypes: {
      eventType: {
        form: EventTypeFormState;
      };
      modals: {
        selectEventTypes: EventTypesModalState;
        replaceParticipantType: ReplaceParticipantTypeModalState;
        newEventType: NewEventTypeFormState;
      };
    };
    reports: {
      generateReports: AdminEventsReportsFormState;
      yearOverview: AdminEventsYearOverviewFormState;
    };
    messageCenter: {
      home: MessageCenterHomeState;
      form: AdminEventMessageFormState;
      modals: {
        selectMessage: SelectMessageModalState;
        sendMessage: SendMessageModalState;
      };
    };
  };
  adminSettings: {
    cacheTwoGLAccount: AdminSettingsCacheTwoGLAccountState;
    glAccounts: {
      modals: {
        glAccount: GLAccountModalState;
      };
      glAccount: {
        form: GLAccountFormState;
      };
    };
    cacheTwoLocation: AdminSettingsCacheTwoLocationState;
    modals: {
      location: LocationModalState;
      locationForm: LocationFormState;
    };
  };
  rollback: RollbackState;
  session: SessionState;
  app: AppState;
  user: UserState;
  login: LoginState;
  resetPassword: ResetPasswordState;
  home: HomeState;
  createAccount: CreateAccountState;
  checkout: CheckoutState;
  events: {
    eventType: EventsEventTypeState;
    eventTypes: EventsEventTypesState;
    event: {
      main: EventState;
      classes: EventClassesState;
      products: EventProductsState;
      registration: EventRegistrationState;
      modals: {
        overrideFee: OverrideFeeState;
        registerOverrideFee: RegisterOverrideFeeState;
        addUnscheduledClass: AddUnscheduledClassesState;
        cancelRegistration: CancelRegistrationState;
      };
      register: {
        participant: {
          main: EventRegisterParticipantState;
          roster: EventRegisterParticipantRosterState;
          type: EventRegisterParticipantTypeState;
          classes: ParticipantsWizardClassesState;
          confirmation: ParticipantWizardConfirmationState;
        };
        numbers: {
          main: EventRegisterNumbersState;
          spots: NumbersWizardSpotsState;
          campsite: NumbersWizardCampsiteState;
          classes: NumbersWizardClassesState;
          products: NumbersWizardProductsState;
          confirmation: NumbersWizardConfirmationState;
        };
        products: {
          main: EventRegisterProductsState;
          products: ProductsWizardProductsState;
          confirmation: ProductsWizardConfirmationState;
        };
      };
    };
  };
  facilities: {
    facility: FacilityFacilityState;
    locations: FacilitiesLocationState;
    location: FacilityLocationState;
    trip: {
      form: TripFormState;
      summary: TripSummaryState;
      modals: {
        reservationOverrideFee: ReservationOverrideFeeState;
        cancelReservation: CancelReservationState;
      };
      viewReservation: ViewReservationState;
      reservation: {
        main: FacilitiesReservationState;
        type: FacilitiesReservationTypeState;
        reservation: FacilitiesReservationReservationState;
        confirmation: FacilitiesWizardConfirmationState;
      };
    };
  };
  settings: {
    profile: ProfileState;
    changePassword: ChangePasswordState;
    editEmail: EditEmailState;
    roster: {
      add: RosterAddState;
    };
    prevOrders: {
      refundOrFee: RefundOrFeeState;
      auditLog: AuditLogState;
      manageOrder: ManageOrderState;
    };
  };
  adminAccounts: AccountsState;
  addGroup: AddGroupState;
  routing: RouterState;
  locationBeforeTransitions?: any;
}

export class InitialApplicationState implements ApplicationState {
  cacheZero: CacheZeroState = {
    options: undefined,
  };
  cacheOne: CacheOneState;
  cacheTwoFacilities: CacheTwoFacilitiesState;
  cacheTwoBFacilities: CacheTwoBFacilitiesState;
  cacheThreeFacilities: CacheThreeFacilitiesState;
  cacheFourFacilities: CacheFourFacilitiesState;
  cacheTwoRoster: CacheTwoRosterState;
  cacheThreeRoster: CacheThreeRosterState;
  cacheTwoPrevOrders: CacheTwoPrevOrderState;
  cacheThreePrevOrders: CacheThreePrevOrderState;
  cacheTwoEvents: CacheTwoEventsState;
  cacheThreeEvents: CacheThreeEventsState;
  cacheFourEventsViewParticipant: CacheFourEventsViewParticipantState;
  cacheFourEventsNumbers: CacheFourEventsNumbersState;
  cacheFourEventsParticipants: CacheFourEventsParticipantsState;
  cacheFourEventsProducts: CacheFourEventsProductsState;
  adminCMSSite: {
    cacheOne: AdminCMSSiteCacheOneState;
    cacheTwoResources: AdminCMSSiteCacheTwoResourcesState;
    cacheTwoContacts: AdminCMSSiteCacheTwoContactsState;
    cacheTwoSiteMenuItems: AdminCMSSiteCacheTwoSiteMenuItemsState;
    cacheTwoPageMenuItems: AdminCMSSiteCacheTwoPageMenuItemsState;
    cacheTwoPage: AdminCMSSiteCacheTwoPageState;
    resources: {
      home: ResourcesHomeState;
      modals: {
        resource: ResourceModalState;
        resourceCategory: ResourceCategoryModalState;
        resourceCategoryForm: ResourceCategoryFormState;
        multipleResources: MultipleResourcesModalState;
      };
      resource: {
        form: ResourceFormState;
      };
    };
    contacts: {
      home: ContactsHomeState;
      contact: {
        form: ContactFormState;
      };
      modals: {
        multipleContacts: MultipleContactsModalState;
      };
    };
    menus: {
      siteMenuItem: {
        form: SiteMenuItemFormState;
      };
      modals: {
        pageMenuItem: PageMenuItemFormState;
      };
      home: MenusHomeState;
    };
    settings: {
      main: SettingsMainState;
      general: GeneralSettingsFormState;
      messages: MessagesSettingsFormState;
    };
    pages: {
      modals: {
        pageType: PageTypeModalState;
        newPage: NewPageModalState;
        imageForm: ImageFormState;
        duplicatePageForm: DuplicatePageFormState;
      };
      page: {
        form: PageFormState;
      };
      home: PagesHomeState;
    };
  };
  adminFacilityLocation: {
    cacheOne: AdminFacilityLocationCacheOneState;
    cacheTwoFacilityTypes: AdminFacilityLocationCacheTwoFacilityTypesState;
    cacheTwoFacility: AdminFacilityLocationCacheTwoFacilityState;
    cacheTwoBlackoutDate: AdminFacilityLocationCacheTwoBlackoutDatesState;
    facilities: {
      home: FacilitiesHomeState;
      facilityType: {
        form: FacilityTypeFormState;
      };
      facility: {
        form: FacilityFormState;
      };
      modals: {
        newFacility: NewFacilityModalState;
        importFromFacility: ImportFromFacilityModalState;
        multipleFacilities: MultipleFacilitiesModalState;
      };
    };
    blackoutDates: {
      home: BlackoutDatesHomeState;
      form: BlackoutDateFormState;
    };
    availabilities: {
      home: AvailabilitiesHomeState;
    };
    reports: FacilitiesReportsFormState;
    settings: FacilitiesSettingsFormState;
  };
  adminEvents: {
    cacheOne: AdminEventsCacheOneState;
    cacheTwoEvent: AdminEventsCacheTwoEventState;
    cacheTwoEventType: AdminEventsCacheTwoEventTypeState;
    cacheTwoProduct: AdminEventsCacheTwoProductState;
    cacheTwoMessage: AdminEventsCacheTwoMessageState;
    cacheThreeClass: AdminEventsCacheThreeClassState;
    cacheThreeClassRequirements: AdminEventsCacheThreeClassRequirementsState;
    cacheThreeClassType: AdminEventsCacheThreeClassTypeState;
    shared: AdminEventsSharedState;
    events: {
      home: EventsHomeState;
      event: {
        form: EventFormState;
        classes: {
          classesTab: AdminEventsEventClassesTabState;
          timeBlocksTab: AdminEventsEventTimeBlocksTabState;
          classTypesTab: AdminEventsEventClassTypesTabState;
        };
        dashboard: AdminEventsEventDashboardState;
      };
      modals: {
        generateInvoices: GenerateInvoicesModalState;
        importFromEvent: ImportFromEventModalState;
        exportInstructorRoster: ExportInstructorRosterModalState;
        exportParticipantClassSchedule: ExportParticipantClassScheduleModalState;
        multipleGroups: MultipleGroupsModalState;
        enterClassRequirement: EnterClassRequirementModalState;
        selectClassType: SelectClassTypeModalState;
        selectMeritBadge: SelectMeritBadgeModalState;
        manageRequirements: ManageRequirementsModalState;
      };
    };
    classTypes: {
      form: AdminEventClassTypeFormState;
    };
    classes: {
      form: AdminEventClassFormState;
    };
    products: {
      home: ProductsHomeState;
      form: AdminEventProductFormState;
    };
    eventTypes: {
      eventType: {
        form: EventTypeFormState;
      };
      modals: {
        selectEventTypes: EventTypesModalState;
        replaceParticipantType: ReplaceParticipantTypeModalState;
        newEventType: NewEventTypeFormState;
      };
    };
    reports: {
      generateReports: AdminEventsReportsFormState;
      yearOverview: AdminEventsYearOverviewFormState;
    };
    messageCenter: {
      home: MessageCenterHomeState;
      form: AdminEventMessageFormState;
      modals: {
        selectMessage: SelectMessageModalState;
        sendMessage: SendMessageModalState;
      };
    };
  };
  adminSettings: {
    cacheTwoGLAccount: AdminSettingsCacheTwoGLAccountState;
    glAccounts: {
      modals: {
        glAccount: GLAccountModalState;
      };
      glAccount: {
        form: GLAccountFormState;
      };
    };
    cacheTwoLocation: AdminSettingsCacheTwoLocationState;
    modals: {
      location: LocationModalState;
      locationForm: LocationFormState;
    };
  };
  rollback: RollbackState = {
    isRollbackJustFinished: false,
  };
  session: SessionState = {};
  app: AppState = {
    isDrawerOpen: undefined,
    topFloatingAlert: undefined,
    gutter: undefined,
    showAddedHeaderAction: undefined,
    showAddedSideBar: undefined,
    snackbar: undefined,
    apiLoading: 0,
    apiSaving: 0,
    apiLoadingCount: 0,
    apiSavingCount: 0,
    apiLoadingMap: {},
    apiSavingMap: {},
    queuedActionsMap: {},
    isRouterChangeRollbackFinished: true,
    openedModals: [],
  };
  user: UserState = {
    user: { ...EMPTY_USER },
  };
  login: LoginState = {
    ActiveForm: {},
    ValidationRules: { ...LoginFormDefinition },
  };
  resetPassword: ResetPasswordState;
  home: HomeState;
  createAccount: CreateAccountState;
  checkout: CheckoutState;
  events: {
    eventType: EventsEventTypeState;
    eventTypes: EventsEventTypesState;
    event: {
      main: EventState;
      classes: EventClassesState;
      products: EventProductsState;
      registration: EventRegistrationState;
      modals: {
        overrideFee: OverrideFeeState;
        addUnscheduledClass: AddUnscheduledClassesState;
        cancelRegistration: CancelRegistrationState;
        registerOverrideFee: RegisterOverrideFeeState;
      };
      register: {
        participant: {
          main: EventRegisterParticipantState;
          roster: EventRegisterParticipantRosterState;
          type: EventRegisterParticipantTypeState;
          classes: ParticipantsWizardClassesState;
          confirmation: ParticipantWizardConfirmationState;
        };
        numbers: {
          main: EventRegisterNumbersState;
          spots: NumbersWizardSpotsState;
          campsite: NumbersWizardCampsiteState;
          classes: NumbersWizardClassesState;
          products: NumbersWizardProductsState;
          confirmation: NumbersWizardConfirmationState;
        };
        products: {
          main: EventRegisterProductsState;
          products: ProductsWizardProductsState;
          confirmation: ProductsWizardConfirmationState;
        };
      };
    };
  };
  facilities: {
    facility: FacilityFacilityState;
    locations: FacilitiesLocationState;
    location: FacilityLocationState;
    trip: {
      form: TripFormState;
      summary: TripSummaryState;
      modals: {
        reservationOverrideFee: ReservationOverrideFeeState;
        cancelReservation: CancelReservationState;
      };
      reservation: {
        main: FacilitiesReservationState;
        type: FacilitiesReservationTypeState;
        reservation: FacilitiesReservationReservationState;
        confirmation: FacilitiesWizardConfirmationState;
      };
      viewReservation: ViewReservationState;
    };
  };
  settings: {
    profile: ProfileState;
    changePassword: ChangePasswordState;
    editEmail: EditEmailState;
    roster: {
      add: RosterAddState;
    };
    prevOrders: {
      refundOrFee: RefundOrFeeState;
      auditLog: AuditLogState;
      manageOrder: ManageOrderState;
    };
  };

  adminAccounts: AccountsState;
  addGroup: AddGroupState;
  routing: RouterState;

  public constructor(state?: ApplicationState) {
    if (state) Object.assign(this, state);
    if (!isNode() && process.env.NODE_ENV === "development") {
      if (Cookies.get(cookieName)) {
        this.session.id = Cookies.get(cookieName);
      }
    }
  }
}

export const getApplicationInitialState = (state?: any) => {
  const iState = new InitialApplicationState(state);
  return iState;
};

export const getReducers = async () => {
  return injectReducers(reducers);
};

export const reducers = {
  cacheZero: CacheZero,
  cacheOne: CacheOne,
  cacheTwoFacilities: CacheTwoFacilities,
  cacheTwoBFacilities: CacheTwoBFacilities,
  cacheThreeFacilities: CacheThreeFacilities,
  cacheFourFacilities: CacheFourFacilities,
  cacheTwoRoster: CacheTwoRoster,
  cacheThreeRoster: CacheThreeRoster,
  cacheTwoPrevOrders: CacheTwoPrevOrder,
  cacheThreePrevOrders: CacheThreePrevOrder,
  cacheTwoEvents: CacheTwoEvents,
  cacheThreeEvents: CacheThreeEvents,
  cacheFourEventsViewParticipant: CacheFourEventsViewParticipant,
  cacheFourEventsNumbers: CacheFourEventsNumbers,
  cacheFourEventsParticipants: CacheFourEventsParticipants,
  cacheFourEventsProducts: CacheFourEventsProducts,
  rollback: Rollback,
  session: Session,
  app: App,
  user: User,
  login: Login,
  resetPassword: ResetPassword,
  home: Home,
  createAccount: CreateAccount,
  checkout: Checkout,
  events: combineReducers({
    eventType: EventsEventType,
    eventTypes: EventsEventTypes,
    event: combineReducers({
      main: Event,
      classes: EventClasses,
      products: EventProducts,
      registration: EventRegistration,
      modals: combineReducers({
        overrideFee: OverrideFee,
        addUnscheduledClass: AddUnscheduledClasses,
        cancelRegistration: CancelRegistration,
        registerOverrideFee: RegisterOverrideFee,
      }),
      register: combineReducers({
        participant: combineReducers({
          main: EventRegisterParticipant,
          roster: EventRegisterParticipantRoster,
          type: EventRegisterParticipantType,
          classes: ParticipantsWizardClasses,
          confirmation: ParticipantWizardConfirmation,
        }),
        numbers: combineReducers({
          main: EventRegisterNumbers,
          spots: NumbersWizardSpots,
          campsite: NumbersWizardCampsite,
          classes: NumbersWizardClasses,
          products: NumbersWizardProducts,
          confirmation: NumbersWizardConfirmation,
        }),
        products: combineReducers({
          main: EventRegisterProducts,
          products: ProductsWizardProducts,
          confirmation: ProductsWizardConfirmation,
        }),
      }),
    }),
  }),
  facilities: combineReducers({
    facility: FacilityFacilityReducer,
    locations: FacilitiesLocation,
    location: FacilityLocation,
    trip: combineReducers({
      form: TripForm,
      summary: TripSummary,
      modals: combineReducers({
        reservationOverrideFee: ReservationOverrideFee,
        cancelReservation: CancelReservation,
      }),
      reservation: combineReducers({
        main: FacilitiesReservation,
        type: FacilitiesReservationType,
        reservation: FacilitiesReservationReservation,
        confirmation: FacilitiesWizardConfirmation,
      }),
      viewReservation: ViewReservation,
    }),
  }),
  settings: combineReducers({
    profile: Profile,
    changePassword: ChangePassword,
    editEmail: EditEmail,
    roster: combineReducers({
      add: RosterAdd,
    }),
    prevOrders: combineReducers({
      refundOrFee: RefundOrFee,
      auditLog: AuditLog,
      manageOrder: ManageOrder,
    }),
  }),
  adminAccounts: AdminModalAccounts,
  addGroup: AddGroup,
};

export const rootEpic = combineEpics(
  hideSnackbar,
  hideSnackbarApiFail,
  addHeaderFilter,
  loginEpic,
  logoutEpic,
  loginForm,
  getRegisterEpic,
  emailBackendValidate,
  emailBackendValidateCancel,
  usernameBackendValidate,
  usernameBackendValidateCancel,
  submitForm,
  addGroupSubmitForm,
  addGroupUsernameBackendValidate,
  // addGroupUsernameBackendValidateCancel,
  getParticipantsEpic,
  getRegistrationsEpic,
  getTripsEpic,
  getReservationsEpic,

  restoreRosterPersonEpic,
  getGroupsEpic,
  restoreGroupEpic,
  editProfileSubmitForm,
  editProfileUsernameBackendValidate,
  // editProfileUsernnameBackendValidateCancel,
  editProfileDeleteForm,
  changePasswordEpic,
  editEmailEpic,

  // CMS
  adminCMSSiteCacheOneEpic,
  adminCMSSiteCacheTwoResourcesEpic,
  adminCMSSiteCacheTwoContactsEpic,
  adminCMSSiteCacheTwoSiteMenuItemsEpic,
  adminCMSSiteCacheTwoPageMenuItemsEpic,
  adminCMSSiteCacheTwoPageEpic,
  adminCMSSiteAddResourceEpic,
  adminCMSSiteDeleteResourceEpic,
  adminCMSSiteAddResourceCategoryEpic,
  adminCMSSiteDeleteResourceCategoryEpic,
  adminCMSSiteAddContactEpic,
  adminCMSSiteDeleteContactEpic,
  getDefaultForPrimaryEventPageEpic,
  adminCMSSiteAddSiteMenuEpic,
  adminCMSSiteDeleteSiteMenuEpic,
  adminCMSSiteAddPageMenuEpic,
  adminCMSSiteAddPageMenuModalEpic,
  adminCMSSiteDeletePageMenuEpic,
  adminCMSSiteUpdateSiteMenuOrderEpic,
  adminCMSSiteUpdatePageMenuOrderEpic,
  editPageUpdateSiteMenuItemEpic,
  adminCMSSiteEditGalleryImageEpic,
  adminCMSSiteUpdateGalleryImageEpic, // this epic will act as a queue for gallery image delete/restore/add
  adminCMSSiteAddLocationEpic,
  adminCMSSiteAddPageEpic,
  adminCMSSiteDeleteRestorePageEpic,
  adminCMSSiteUpdatePageEpic,
  adminCMSSiteGeneralSettingsSubmitEpic,
  adminCMSSiteMessagesSettingsSubmitEpic,
  adminCMSSiteDuplicatePageEpic,

  // FacilityLocation
  adminFacilityLocationCacheOneEpic,
  adminFacilityLocationCacheTwoFacilityTypesEpic,
  adminFacilityLocationCacheTwoFacilityEpic,
  adminFacilityLocationCacheTwoBlackoutDatesEpic,
  reportGroupTripsEpic,
  reportGroupReservationsEpic,
  adminFacilityLocationAvailabilitiesEpic,
  adminFacilityLocationSettingsSubmitEpic,
  adminFacilityLocationUpdateBlackoutDateEpic,
  adminFacilityLocationDeleteBlackoutDateEpic,
  adminFacilityLocationUpdateFacilityTypeEpic,
  adminFacilityLocationAddFacilityEpic,
  adminFacilityLocationDeleteFacilityEpic,
  adminFacilityLocationImportBookingTimesEpic,
  adminFacilityLocationImportPricingEpic,
  adminFacilityLocationImportImagesEpic,
  adminFacilityLocationUpdateFacilityEpic,
  adminFacilityUpdateImageEpic,
  adminFacilityLocationDeleteFacilityTypeEpic,
  adminFacilityLocationGetAvailabilitiesEpic,

  // Admin EventTypes
  getAdminEventsCacheOneEpic,
  getAdminEventTypesEpic,
  adminEventsCacheTwoEventEpic,
  adminEventsGetEventsListEpic,
  getSourceClassesListEpic,
  adminEventsCacheThreeClassTypeEpic,
  adminEventsCacheThreeClassEpic,
  adminEventsCacheTwoProductEpic,
  adminEventsCacheTwoMessageEpic,
  adminEventsCacheThreeClassRequirementsEpic,
  getAdminEventsTypeEpic,
  getRegisteredGroupsEpic,
  generateAdminEventsEventTypeReportsEpic,
  generateAdminEventsEventReportsEpic,
  sendToClientsEpic,
  adminEventsUpdateMessageEpic,
  adminEventsDeleteMessageEpic,
  adminEventsUpdateProductEpic,
  adminEventsDeleteProductEpic,
  adminEventsUpdateClassTypeEpic,
  adminEventsDeleteClassTypeEpic,
  adminEventsAddEventTypeEpic,
  adminEventsUpdateEventTypeEpic,
  adminEventsDeleteEventTypeEpic,
  recalculatePricingEpic,
  adminEventsDeleteEventEpic,
  adminEventsUpdateEventEpic,
  adminEventImportPricingEpic,
  adminEventImportTimeBlocksEpic,
  adminEventImportClassEpic,
  adminEventsUpdateTimeBlocksEpic,
  adminEventsUpdateClassRequirementsEpic,
  adminEventsDeleteClassEpic,
  adminEventsUpdateClassEpic,
  adminEventsClassTypeAllRequirementsEpic,

  // Admin Settings
  adminSettingsCacheTwoGLAccountEpic,
  adminSettingsAddGLAccountEpic,
  adminSettingsGetGLAccountsEpic,
  adminSettingsCacheTwoLocationEpic,

  // Queues
  showTopFloatingAlertInQueueEpic,

  // Password Reset
  resetPasswordSearchEpic,
  resetPasswordUpdateEpic,
  resetPasswordUpdateGroupSendResetLinkEpic,

  cacheZeroEpic,
  cacheZeroLocationsEpic,
  cacheOneEpic,
  refreshRosterOnConflict,
  cacheTwoFacilitiesEpic,
  cacheTwoRosterEpic,
  cacheThreeRosterEpic,
  cacheTwoPrevOrdersEpic,
  refundOrFeeEpic,
  cacheThreePrevOrdersEpic,
  manageOrderSaveEpic,
  cacheTwoFacilitiesAvailabilitiesEpic,
  addTripEpic,
  deleteTripEpic,
  cacheTwoBFacilitiesAvailabilitiesEpic,
  cacheThreeFacilitiesEpic,
  tripSummarySaveFinanceEpic,
  cacheFourFacilitiesEpic,
  cacheFourFacilityAvailabilitiesEpic,
  cacheFourFacilitiesRecalcSuccessEpic,
  cacheFourFacilityRecalcsEpic,
  cacheFourFacilitiesSaveEpic,
  cacheFourFacilitiesFailureEpic,
  reservationChangeEpic,
  overrideReservationChangeEpic,
  closeOverrideEpic,
  cacheTwoEventsEpic,
  cacheThreeEventsEpic,
  cacheFourEventsNumbersEpic,
  cacheFourEventsNumbersSaveEpic,
  cacheFourEventsNumbersLoadSuccessEpic,
  cacheFourEventsNumbersSaveFailureEpic,
  cacheFourEventsNumbersRecalcSuccessEpic,
  cacheFourEventsNumbersLoadPopulateEpic,
  numbersAddClassValidateEpic,
  numbersSpotsNext,
  numbersCampsitesNext,
  numbersClassesNext,
  numbersProductsNext,
  cacheFourEventsViewParticipantEpic,
  cacheFourEventsParticipantsEpic,
  cacheFourEventsParticipantsGetPersonEpic,
  cacheFourEventsParticipantsRecalcSuccessEpic,
  cacheFourEventsParticipantsSaveEpic,
  cacheFourEventsParticipantsFailureEpic,
  participantsAddClassValidateEpic,
  participantDeleteRoster,
  participantsRosterNext,
  participantsTypeResetEpic,
  participantsTypeNext,
  participantsClassesNext,
  cacheFourEventsProductsEpic,
  cacheFourEventsProductsRecalcSuccessEpic,
  cacheFourEventsProductsSaveFailureEpic,
  cacheFourEventsProductsSaveEpic,
  productsProductsNext,
  eventRegistrationSaveFinanceEpic,
  updateRosterPersonEpic,
  deleteRosterPersonEpic,
  registerOverrideFeeSave,
  registerOverrideFeeSuccessEpic,
  changePaymentType,
  checkoutCart,
  checkoutSuccessEpic,
  emptyCartFromModal,
  closeCartAfterEmpty,
  removeItemFromCartEpic,
  closeAfterRemoveItemEpic,
  checkoutFailureEpic,

  reportInvoiceEpic,
  reportRequirementsCompletedEpic,
  reportRequirementsScoutbookEpic,
  reportBlueCardsEpic,
  reportRosterEpic,
  reportAuditLogEpic,
  jsonReportSuccessEpic,
  makeSavePointDuringSaveEpic()
);

export type ActionCreator = ActionCreatorGeneric<ApplicationState>;
export type { AppState, AppActions } from "./App";
export { appActionCreators } from "./App";
