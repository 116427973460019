import { ValidationResults } from "@tentaroo/core";

import type {ActionCreator, ApplicationState} from '../../../';

import { actionCreators as appActionCreators } from "../../../App/actions";
import {
  createApiSubmitActions,
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions
} from "../../../Validation/actionCreator";
import {validateAll} from "../../../../utils/validator";
import {ShowTopFloatingAlert} from "../../../App/actions";
import {getUpdateOrderBody} from "../../../../constants/urls";
import {SaveState} from "../../../Rollback/actions";
import { typeName, Action } from '../../../../utils/StrongActions';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../utils/dataHelper';
import { PreviousOrder, PreviousOrderItem } from '../../../../models/api/cacheTwoPrevOrders';
import { ModalTypes, isModalOpened } from '../../../../utils/modalHelper';

export const SUFFIX = '__MANAGE_ORDER';
export const DELETE_SUFFIX = '__DELETE_MANAGE_ORDER';

@typeName(`RESET${SUFFIX}`)
export class ManageOrderReset extends Action {}

@typeName(`SHOW${SUFFIX}`)
export class ShowManageOrder extends Action {
  constructor(public show: boolean, public items: PreviousOrderItem[] | null | undefined, public order: PreviousOrder | null | undefined) {
    super();
  }
}

@typeName(`SET_INACTIVE_ITEM${SUFFIX}`)
export class SetInactiveItem extends Action {
  constructor(public OrderID: number, public Inactive: boolean) {
    super();
  }
}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const SubmitManageOrderActions = createApiSubmitActions(SUFFIX);

export type Actions = typeof actionCreators;

export const formCreator = (rootState: ApplicationState, activeForm) => {
  let debugPayload: TentarooDebugPayload | undefined;
  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when submitting ManageOrder form");
  }
  if (!rootState.cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when submitting ManageOrder form");
  }
  if (!rootState.cacheThreePrevOrders.PreviousOrder) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheThreePrevOrders.PreviousOrder not available when submitting ManageOrder form");
  }

  const IDi = rootState.cacheZero.options?.Group?.IDi as any;
  const TS = rootState.cacheZero.options?.Group?.TS as any;
  const OrderID = rootState.cacheThreePrevOrders.PreviousOrder?.OrderID as any;
  const OrderTS = rootState.cacheThreePrevOrders.PreviousOrder?.TS as any;
  const Items = rootState.cacheThreePrevOrders.PreviousOrderItems || [];
  const payload = getUpdateOrderBody(
    IDi,
    TS,
    OrderID,
    activeForm,
    OrderTS,
    Items
  );

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  setInactive: (OrderID: number, Inactive: boolean): ActionCreator => dispatch => dispatch(new SetInactiveItem(OrderID, Inactive)),
  showManageOrder: (show: boolean, saveAfter: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new ShowManageOrder(
      show,
      getState().cacheThreePrevOrders.PreviousOrderItems,
      getState().cacheThreePrevOrders.PreviousOrder,
    ));
    if (saveAfter) {
      dispatch(new SaveState());
    }
  },
  resetManageOrder: (): ActionCreator => dispatch => dispatch(new ManageOrderReset()),
  manageApiSubmitForm: (): ActionCreator => (dispatch, getState) => {
    const validationResults = validateAll(s => s.settings.prevOrders.manageOrder);
    const isDeleteOrderModalOpened = isModalOpened(ModalTypes.DELETE_ORDER);
    if (!validationResults) {
      const activeForm = getState().settings.prevOrders.manageOrder.ActiveForm;

      if (isDeleteOrderModalOpened) {
        dispatch(appActionCreators.popModal(true, false, ModalTypes.DELETE_ORDER) as any);
      } else {
        dispatch(new SaveState());
      }
      dispatch(SubmitManageOrderActions.request(formCreator(getState(), activeForm), null));
    } else {
      dispatch(SubmitManageOrderActions.clientFailure(validationResults, false));
      dispatch(new ShowTopFloatingAlert(validationResults.Errors[Object.keys(validationResults.Errors)[0]][0], window.innerWidth < 640 || isDeleteOrderModalOpened, 'orange'));
    }
  },
  manageUpdateValue: createUpdateValueMethod(
    ValidateActions,
    ApiValidateActions,
    (s) => s.settings.prevOrders.manageOrder,
    undefined,
    undefined,
    undefined,
    (validationResults: ValidationResults, key: string, dispatch) => {
      dispatch(new ShowTopFloatingAlert(validationResults.Errors[Object.keys(validationResults.Errors)[0]][0], window.innerWidth < 640, 'orange'));
    }
  ),
  manageSimpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  showDeleteModal: (): ActionCreator => dispatch => {
    const validationResults = validateAll(s => s.settings.prevOrders.manageOrder);
    if (validationResults) {
      dispatch(new ShowTopFloatingAlert('Please correct any invalid fields before deleting this order.', window.innerWidth < 640, 'orange'));
    } else {
      dispatch(appActionCreators.pushModal(ModalTypes.DELETE_ORDER, false, true) as any);
    }
  },
};
