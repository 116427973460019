import * as React from 'react';
import * as moment from 'moment';

import {BulletList, BulletListItem} from '../../../Elements';
import '../../../../styles/pages/facilities/facility/index.scss';
import {FacilitiesAvailability} from "../../../../models/api/cacheTwoFacilties";
import {getCheckInTimes} from "../../../../utils/helpers/facilityHelper";
import {TripReservation, isTripReservation} from '../../../../models/api/cacheThreeFacilities';

export interface Props {
  facilityInfo: FacilitiesAvailability | TripReservation
  startTime: string | moment.Moment;
  endTime: string | moment.Moment;
  IsFacilityType: boolean;
}

const namespace = (): string => 'pages--facilities--facility';

class FacilityStatuses extends React.Component<Props, {}> {
  public props: Props;

  render() {
    const {facilityInfo} = this.props;
    const {startTime, endTime} = this.props;
    const messages: Array<string> = [];
    const timeMessage = getCheckInTimes(
      facilityInfo.ShowTimes,
      startTime!,
      endTime!,
      this.props.IsFacilityType
    );
    if (timeMessage) {
      messages.push(timeMessage);
    }
    if (facilityInfo.MaxAccommodation && facilityInfo.MaxAccommodation > 0) {
      messages.push(`Maximum capacity of ${facilityInfo.MaxAccommodation} people.`);
    }
    if (!isTripReservation(facilityInfo)) {
      if (facilityInfo.MinBookingLength && facilityInfo.MinBookingLength > 0) {
        messages.push(`Minimum booking length of ${facilityInfo.MinBookingLength} days.`);
      }
      if (facilityInfo.MaxBookingLength && facilityInfo.MaxBookingLength > 0) {
        messages.push(`Maximum booking length of ${facilityInfo.MaxBookingLength} days.`);
      }
      if (facilityInfo.RequireEntireWeekend) {
        messages.push(`Entire weekend must be booked if booking on a weekend.`);
      }
      if (facilityInfo.RequireSundayStart) {
        messages.push(`Reservations must start on a Sunday.`);
      }
    }
    if (facilityInfo.Pricing.MinimumPeopleBilledAtPerPersonRates > 0) {
      messages.push(`Per-person rate will be billed for a minimum of ${facilityInfo.Pricing.MinimumPeopleBilledAtPerPersonRates} people.`);
    }

    if (messages.length === 0) return null;

    return <BulletList className={`${namespace()}--facility--list`}>
      {messages.map(m => <BulletListItem size={14}>{m}</BulletListItem>)}
    </BulletList>;
  };
}

export default FacilityStatuses;
