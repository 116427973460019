import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminCMSCacheOneContext, AdminCMSCacheTwoResourceContext } from "@tentaroo/shared";

import { ApplicationState } from '../../../../../../store';
import {
  actionCreators as cacheZeroActionCreators
} from '../../../../../../store/CacheZero/actions';
import {
  actionCreators as adminCMSCacheOneActionCreators, GetSiteCache,
} from '../../../../../../store/AdminCMSSite/CacheOne/actions';
import {
  actionCreators as adminCMSCacheTwoResourcesActionCreators,
} from '../../../../../../store/AdminCMSSite/CacheTwoResources/actions';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as cacheOneActions} from "../../../../../../store/CacheOne/actions";
import { actionCreators as resourceFormActionCreators} from "../../../../../../store/AdminCMSSite/Resources/Resource/Form/actions";
import { actionCreators as resourceCategoryFormActionCreators} from "../../../../../../store/AdminCMSSite/Resources/Modals/ResourceCategoryForm/actions";
import { actionCreators } from "../../../../../../store/AdminCMSSite/Resources/Home/actions";
import { FormDefinition } from "../../../../../../store/AdminCMSSite/Resources/Home/validation";
import { LoadingAll, Tab, Tabs, EmptyMessage, Button, SimpleList, Loader } from '../../../../../Elements';
import { Main, MainContent } from '../../../../../Layouts';
import { PageIcon, DeleteIcon } from '../../../../../Icons';
import { navPush, getResourceUrl, copyStringToClipboard } from '../../../../../../utils';
import { getAddResourceUrl, getEditResourceUrl, getResourcePreviewUrl, getResourcesRootUrl, getResourcesCategoriesRootUrl, constructCMSSiteUrlParams, constructCMSSiteResourceUrlParams } from '../../../../../../constants/urls';
import ResourceFilters from './ResourceFilters';
import '../../../../../../styles/pages/resources/home/index.scss';
import { makeGroupedResourcesSelector, makeFilteredResourcesSelector, makeFilteredResourceCategoriesSelector, activeResourcesSelector, activeResourceCategoriesSelector } from '../../../../../../store/AdminCMSSite/Resources/Home';
import { CMSResource, CMSResourceCategory } from '../../../../../../models/api/adminCMSCacheOne';
import ResourceCard, { ResourceCardType } from '../../../../../../components/Elements/Resource/Card';
import ResourceCategoryFilters from "./ResourceCategoryFilters";
import ResourceCategoryCard, { ResourceCategoryCardType } from '../../../../../../components/Elements/ResourceCategory/Card';
import ResourcePreview from './ResourcePreview';
import { ADMIN_CMS_RESOURCES_PREVIEW_PATH, ADMIN_CMS_RESOURCES_PATH, ADMIN_CMS_RESOURCES_CATEGORIES_PATH } from '../../../../../../routes';
import { ENTITY_NOT_ADDED, ENTITY_NOT_FOUND, RESOURCES, RESOURCE_CATEGORIES } from '../../../../../../constants/messages/adminCMS';
import { checkResourcePermission, checkResourceCategoryPermission, IAdminCMSResourceRouterParams } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { LINK_COPIED } from '../../../../../../constants/messages/generic';
import { EmptyMessageType } from '../../../../../../components/Elements/EmptyMessage';
import { CardCategory } from '../../../../../../components/Elements/Card';
import AdminCMSCacheManager from '../../../../../../utils/cacheManagers/adminCMSCacheManager';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { ModalTypes, noOpenedModals } from '../../../../../../utils/modalHelper';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import { SaveState } from '../../../../../../store/Rollback/actions';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isAdminCMSCacheOnePopulated} from '../../../../../../utils/cachePopulatedCheckers/adminCMS';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--resources';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminCMSResourceRouterParams, {}>
>;

enum ResourcesHomeTabs {
  RESOURCES,
  RESOURCE_CATEGORIES,
}

class Resources extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        const {route} = this.props;

        if (route.path === ADMIN_CMS_RESOURCES_PREVIEW_PATH) {
          AdminCMSCacheManager.getInstance().loadCacheTwoResource({
            props: this.props,
            isStateNavigated: false,
            isEdit: true,
            context: AdminCMSCacheTwoResourceContext.RESOURCE_PREVIEW,
          });
        } else {
          AdminCMSCacheManager.getInstance().loadAdminCMSCacheOne({
            props: this.props,
            isStateNavigated,
            context: AdminCMSCacheOneContext.RESOURCES_LIST,
          });
        }
      }
    );
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        const {routes} = nextProps;
    
        const nextPath = routes[routes.length - 1].path;
    
        if (nextPath === ADMIN_CMS_RESOURCES_PREVIEW_PATH) {
          AdminCMSCacheManager.getInstance().loadCacheTwoResource({
            props: nextProps, 
            isStateNavigated,
            isEdit: true,
            context: AdminCMSCacheTwoResourceContext.RESOURCE_PREVIEW,
          });
        } else {
          AdminCMSCacheManager.getInstance().loadAdminCMSCacheOne({
            props: nextProps,
            isStateNavigated,
            context: nextPath !== ADMIN_CMS_RESOURCES_PREVIEW_PATH ? AdminCMSCacheOneContext.RESOURCES_LIST : undefined,
          });
        }
      }
    );
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    const {routes} = this.props;
    const thisRoute = routes[routes.length - 1];
    const prevRoute = prevProps.routes[prevProps.routes.length - 1];
    const thisPath = thisRoute.path;
    const prevPath = prevRoute.path;

    if (shouldBlockActions()) return;

    if (
      thisPath !== prevPath &&
      (thisPath === ADMIN_CMS_RESOURCES_PATH || thisPath === ADMIN_CMS_RESOURCES_CATEGORIES_PATH) &&
      prevPath !== ADMIN_CMS_RESOURCES_PATH && prevPath !== ADMIN_CMS_RESOURCES_CATEGORIES_PATH
    ) {
      reduxStoreService().dispatch(new SaveState());
    }
  }

  onClickResources = () => {
    const url = getResourcesRootUrl(constructCMSSiteUrlParams(this.props, this.props.adminCMSCacheOne));
    this.props.actions.updateValue(undefined, FormDefinition.ResourceCategoryFilterText);
    
    navPush(this.props.router, url);
  };
  onClickCategories = () => {
    const url = getResourcesCategoriesRootUrl(constructCMSSiteUrlParams(this.props, this.props.adminCMSCacheOne));
    this.props.actions.updateValue(undefined, FormDefinition.FilterText);

    navPush(this.props.router, url);
  };

  onResourceEditClick = (resource: CMSResource) => {
    this.checkResourcePermission(
      () => {
        const editResourceUrl = getEditResourceUrl(constructCMSSiteResourceUrlParams(this.props, this.props.adminCMSCacheOne, resource));
        navPush(this.props.router, editResourceUrl);
      },
      resource.SiteID,
    );
  };
  onResourcePreviewClick = (resource: CMSResource) => {
    const resourcePreviewUrl = getResourcePreviewUrl(constructCMSSiteResourceUrlParams(this.props, this.props.adminCMSCacheOne, resource));
    navPush(this.props.router, resourcePreviewUrl);
  };
  onCopyLinkClick = (resource: CMSResource) => {
    if (!this.props.adminCMSCacheOne || !this.props.adminCMSCacheOne.CMSSite) return;
    const url = getResourceUrl(this.props.adminCMSCacheOne.CMSSite.SiteDomain, resource);
    copyStringToClipboard(url);
    this.props.actions.showSnackbarItem(LINK_COPIED);
  };
  onResourceRestoreClick = (resource: CMSResource) => {
    this.checkResourcePermission(
      () => {
        this.props.resourceFormActions.deleteResource(resource.ID, this.props.router, this.props.routes, true);
      },
      resource.SiteID,
    );
  };
  onResourceDeleteClick = (resource: CMSResource) => {
    this.checkResourcePermission(
      () => {
        this.props.resourceFormActions.deleteResource(resource.ID, this.props.router, this.props.routes);
      },
      resource.SiteID,
    );
  };

  onResourceCategoryEditClick = (resourceCategory: CMSResourceCategory) => {
    this.checkResourceCategoryPermission(
      () => {
        this.props.actions.pushModal(ModalTypes.RESOURCE_CATEGORY_FORM, false, true, {id: resourceCategory.ID});
      },
      true,
    );
  };
  onResourceCategoryDeleteClick = (resourceCategory: CMSResourceCategory) => {
    this.checkResourceCategoryPermission(
      () => {
        this.props.resourceCategoryFormActions.deleteResourceCategory(resourceCategory.ID);
      },
      true,
    );
  };
  onResourceCategoryRestoreClick = (resourceCategory: CMSResourceCategory) => {
    this.checkResourceCategoryPermission(
      () => {
        this.props.resourceCategoryFormActions.deleteResourceCategory(resourceCategory.ID, true);
      },
      true,
    );
  };

  onNewResource = (e) => {
    const route = this.props.routes[this.props.routes.length - 1];

    // dont respond when we are previewing resource
    if (route.path === ADMIN_CMS_RESOURCES_PREVIEW_PATH) return;
    this.checkResourcePermission(
      () => {
        const newResourceUrl = getAddResourceUrl(constructCMSSiteUrlParams(this.props, this.props.adminCMSCacheOne));
        navPush(this.props.router, newResourceUrl);
      }
    );
  };
  checkResourcePermission = (onSuccess: () => void, siteId?: number) => {
    const {cacheZero, adminCMSCacheOne} = this.props;
    
    checkResourcePermission(onSuccess, cacheZero, adminCMSCacheOne, siteId, true);
  };

  checkResourceCategoryPermission = (onSuccess: () => void, isEdit?: boolean) => {
    const {adminCMSCacheOne} = this.props;
    
    checkResourceCategoryPermission(onSuccess, adminCMSCacheOne, isEdit);
  };
  onNewResourceCategory = () => {
    this.checkResourceCategoryPermission(
      () => {
        this.props.actions.pushModal(ModalTypes.RESOURCE_CATEGORY_FORM, false, true);
      }
    );
  };

  getSelectedTab = () => {
    const {routes} = this.props;
    const thisRoute = routes[routes.length - 1];
    const selectedTab = (thisRoute.path === ADMIN_CMS_RESOURCES_PATH || thisRoute.path === ADMIN_CMS_RESOURCES_PREVIEW_PATH) ? ResourcesHomeTabs.RESOURCES : ResourcesHomeTabs.RESOURCE_CATEGORIES;

    return selectedTab;
  };

  renderEmptyMessage = (emptyMessage) => {
    const selectedTab = this.getSelectedTab();
    return (
      <EmptyMessage
        icon={PageIcon}
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={emptyMessage} 
        actions={<Button className={`${namespace()}--empty-message-btn`} color="green" onClick={selectedTab === ResourcesHomeTabs.RESOURCES ? this.onNewResource : this.onNewResourceCategory}>{selectedTab === ResourcesHomeTabs.RESOURCES ? 'NEW RESOURCE' : 'NEW RESOURCE CATEGORY'}</Button>}/>
    );
  };

  groupedResourcesRenderer = (index) => {
    const {groupedResources} = this.props;

    if (!groupedResources[index]) {
      // render empty paragraph
      return <div key={`resource_null_${index}`} className={`${namespace()}--list-wrapper--group-title`}></div>;
    } else if (groupedResources[index].CategoryID) {
      const item = groupedResources[index] as CMSResource;
      return (
        <ResourceCard
          onRestore={this.onResourceRestoreClick}
          onEdit={this.onResourceEditClick}
          onPreview={this.onResourcePreviewClick}
          onDelete={this.onResourceDeleteClick}
          onCopyLink={this.onCopyLinkClick}
          type={ResourceCardType.LIST}
          resource={item} />
      );
    } else {
      const item = groupedResources[index] as CMSResourceCategory;
      return <div key={`grouped_resource_${index}`} className={`${namespace()}--list-wrapper--group-title`}><span>{item.Name}</span></div>;
    }
  };
  groupedResourcesItemSizeGetter = () => {
    return 84;
  };
  filteredResourcesRenderer = (index) => {
    const {filteredResources} = this.props;

    if (!filteredResources[index]) {
      return <div key={`filtered_resource_${index}`} className={`${namespace()}--list-wrapper--group-title`}></div>;
    }
    return (
      <div key={`filter_resource_${index}`}>
        <ResourceCard
          onRestore={this.onResourceRestoreClick}
          onEdit={this.onResourceEditClick}
          onPreview={this.onResourcePreviewClick}
          onDelete={this.onResourceDeleteClick}
          onCopyLink={this.onCopyLinkClick}
          type={ResourceCardType.LIST}
          resource={filteredResources[index]} />
      </div>
    );
  };

  renderResourcesContent = () => {
    const {resourcesHome, filteredResources, groupedResources} = this.props;

    if (resourcesHome.ActiveForm.SelectedCategoryID === 0) {
      return (
        <div className={`${namespace()}--list-wrapper`} style={{marginTop: '-44px'}}>
          <SimpleList
            itemRenderer={this.groupedResourcesRenderer}
            totalLength={groupedResources.length}
            threshold={200}
          />
        </div>
      );
    } else {
      return (
        <div className={`${namespace()}--list-wrapper`}>
          <SimpleList
            itemRenderer={this.filteredResourcesRenderer}
            totalLength={filteredResources.length}
            threshold={200}
          />
        </div>
      );
    }
  };

  filteredResourceCategoriesRenderer = (index) => {

    const {filteredResourceCategories} = this.props;
    const category = filteredResourceCategories[index];

    // render empty paragraph
    if (!filteredResourceCategories[index]) {
      return <div className={`${namespace()}--list-wrapper--group-title`}></div>;
    }
    return (
      <ResourceCategoryCard
        id={category.ID}
        type={ResourceCategoryCardType.LIST}
        cardCategory={CardCategory.LIST}
        name={category.Name}
        removeIcon={DeleteIcon}
        deleted={category.Inactive}
        onRestore={() => this.onResourceCategoryRestoreClick(category)}
        onRemove={() => this.onResourceCategoryDeleteClick(category)}
        onEdit={() => this.onResourceCategoryEditClick(category)} />
    );
  };

  renderResourceCategoriesContent = () => {
    const {filteredResourceCategories} = this.props;

    return (
      <div className={`${namespace()}--list-wrapper`}>
        <SimpleList
          itemRenderer={this.filteredResourceCategoriesRenderer}
          totalLength={filteredResourceCategories.length}
          threshold={200}
        />
      </div>
    );
  };

  onClickMain = (e) => {
    // if we are clicking on the `tab`, dont do anything in this callback (i.e. manually stop propagation)
    if (e.target.classList.contains('mdl-tabs__tab')) return;
    e.stopPropagation();
    const { routes, router, apiSaving, adminCMSCacheOne} = this.props;
    const thisRoute = routes[routes.length - 1];

    if (reduxStoreService().getState().app.apiSaving <= 0 && adminCMSCacheOne.CMSSite) {
      if (thisRoute.path === ADMIN_CMS_RESOURCES_PREVIEW_PATH) {
        this.props.actions.clearAdminCMSSiteCacheBelowOne();
        const backUrl = getResourcesRootUrl(constructCMSSiteUrlParams(this.props, adminCMSCacheOne));
        navPush(router, backUrl);
      }
    }
  };

  public render() {
    const {routes, actions, resourcesHome, filteredResourceCategories, activeResourceCategories, activeResources, apiLoadingMap, inert, filteredResources, apiSaving, groupedResources} = this.props;
    if (!isAdminCMSCacheOnePopulated()) {
        return <LoadingAll />;
    }
    const thisRoute = routes[routes.length - 1];
    const selectedTab = this.getSelectedTab();

    let emptyResourcesMessage;
    if (selectedTab === ResourcesHomeTabs.RESOURCES) {
      // @todo - if all resources are deleted, should also show `No resources added yet`
      if (activeResources.length === 0 && (resourcesHome.ActiveForm.SelectedCategoryID === 0 ? groupedResources.length === 0 : filteredResources.length === 0)) {
        emptyResourcesMessage = ENTITY_NOT_ADDED(RESOURCES);
      } else if (resourcesHome.ActiveForm.SelectedCategoryID === 0 && groupedResources.length === 0) {
        emptyResourcesMessage = ENTITY_NOT_FOUND(RESOURCES);
      } else if (resourcesHome.ActiveForm.SelectedCategoryID && filteredResources.length === 0) {
        emptyResourcesMessage = ENTITY_NOT_FOUND(RESOURCES);
      }
    }
    let emptyResourceCategoriesMessage;
    if (selectedTab === ResourcesHomeTabs.RESOURCE_CATEGORIES) {
      if (activeResourceCategories.length === 0 && filteredResourceCategories.length === 0) {
        emptyResourceCategoriesMessage = ENTITY_NOT_ADDED(RESOURCE_CATEGORIES);
      } else if (filteredResourceCategories.length === 0) {
        emptyResourcesMessage = ENTITY_NOT_FOUND(RESOURCE_CATEGORIES);
      }
    }

    const refreshingList = apiLoadingMap[GetSiteCache.requestType] && noOpenedModals();
    const saving = apiSaving > 0 && noOpenedModals();

    return (
      <Main inert={inert} isLoading={refreshingList} onClick={this.onClickMain} isCMSSite>
        <MainContent className={namespace()} handleCompact>
          <Tabs className={`${namespace()}--tabs`}>
            <Tab selected={selectedTab === ResourcesHomeTabs.RESOURCES} onClick={this.onClickResources}>RESOURCES</Tab>
            <Tab id={generateDOMId("resource-categories-tab")} selected={selectedTab === ResourcesHomeTabs.RESOURCE_CATEGORIES} onClick={this.onClickCategories}>CATEGORIES</Tab>
          </Tabs>
          {selectedTab === ResourcesHomeTabs.RESOURCES && <div className={`${namespace()}--list-actions`}>
            <ResourceFilters
              disabled={apiSaving > 0}
              ActiveForm={resourcesHome.ActiveForm}
              ValidationRules={resourcesHome.ValidationRules}
              preventActions={thisRoute.path === ADMIN_CMS_RESOURCES_PREVIEW_PATH}
              reduxActions={actions}
              expand={resourcesHome.expandFilter}
              newButton={
                <Button id={generateDOMId("admin-resource-add-btn")} className='control' color='green' textColor='white' onClick={this.onNewResource}>New</Button>
              } />
          </div>}
          {selectedTab === ResourcesHomeTabs.RESOURCE_CATEGORIES && <div className={`${namespace()}--category-list-actions`}>
            <ResourceCategoryFilters
              disabled={apiSaving > 0}
              ActiveForm={resourcesHome.ActiveForm}
              ValidationRules={resourcesHome.ValidationRules}
              reduxActions={actions}
              newButton={
                <Button className='control' color='green' textColor='white' onClick={this.onNewResourceCategory}>New</Button>
              } />
          </div>}
          {!emptyResourcesMessage && selectedTab === ResourcesHomeTabs.RESOURCES && this.renderResourcesContent()}
          {emptyResourcesMessage && this.renderEmptyMessage(emptyResourcesMessage)}
          {!emptyResourceCategoriesMessage && selectedTab === ResourcesHomeTabs.RESOURCE_CATEGORIES && this.renderResourceCategoriesContent()}
          {emptyResourceCategoriesMessage && this.renderEmptyMessage(emptyResourceCategoriesMessage)}
          {thisRoute.path === ADMIN_CMS_RESOURCES_PREVIEW_PATH && <ResourcePreview />}
        </MainContent>
        {saving && <Loader className={`${namespace()}--loader`} center />}
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const groupedResourcesSelector = makeGroupedResourcesSelector();
  const filteredResourcesSelector = makeFilteredResourcesSelector();
  const filteredResourceCategorySelector = makeFilteredResourceCategoriesSelector();

  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    adminCMSCacheTwoResources: state.adminCMSSite.cacheTwoResources,
    cacheZero: state.cacheZero,
    cacheOne: state.cacheOne,
    resourcesHome: state.adminCMSSite.resources.home,
    groupedResources: groupedResourcesSelector(state),
    activeResources: activeResourcesSelector(state),
    filteredResources: filteredResourcesSelector(state),
    filteredResourceCategories: filteredResourceCategorySelector(state),
    activeResourceCategories: activeResourceCategoriesSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheZeroActionCreators,
    ...appActionCreators,
    ...cacheOneActions,
    ...adminCMSCacheOneActionCreators,
    ...adminCMSCacheTwoResourcesActionCreators,
  }, dispatch),
  resourceFormActions: bindActionCreators({
    ...resourceFormActionCreators,
  }, dispatch),
  resourceCategoryFormActions: bindActionCreators({
    ...resourceCategoryFormActionCreators,
  }, dispatch),
});

const ConnectedResources = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(Resources);

export default withRouter<{}>(ConnectedResources);
