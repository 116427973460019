import * as React from 'react';
import { ModalActions } from '../../Elements';
import { ActionsProps } from '../../Elements/Modal/Actions';

interface Props extends ActionsProps {
  children?: React.ReactNode;
  right?: React.ReactElement<any>;
  left?: React.ReactElement<any>;
}

const Actions: React.SFC<Props> = ({ ...props }: Props): React.ReactElement<Props> => (
  <ModalActions {...props}/>
);

export default Actions;
