import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../../../../store';
import { bindActionCreators } from 'redux';
import {
    actionCreators as adminEventsCacheThreeClassActionCreators, GetAdminEventClass,
} from '../../../../../../../../store/AdminEvents/CacheThreeClass/actions';
import {
    actionCreators as adminEventsCacheTwoEventActionCreators, ClearAdminEventsCacheTwoEvent,
} from '../../../../../../../../store/AdminEvents/CacheTwoEvent/actions';
import { actionCreators as appActionCreators } from '../../../../../../../../store/App/actions';
import { 
    actionCreators as rollbackActionCreators,} from '../../../../../../../../store/Rollback/actions';
import { actionCreators, AdminEventClassFormNamespace as namespace } from "../../../../../../../../store/AdminEvents/Classes/Form/actions";
import { SideModal, SideModalContent, SideModalHeader, Loader, SideModalActions, Button } from '../../../../../../../../components/Elements';
import Form from './Form';
import '../../../../../../../../styles/pages/admin-events/classes/class/index.scss';
import { navPush } from '../../../../../../../../utils';
import { NotFound } from '../../../../../../../../components/Pages';
import { INVALID_CLASS } from '../../../../../../../../constants/messages/adminEvents';
import { getAdminEventsEventClassesUrl, constructAdminEventsEventUrlParams } from '../../../../../../../../constants/adminEventsUrls';
import { checkAdminEventClassPermission } from '../../../../../../../../utils/helpers/adminEventsPageHelper';
import { ModalTypes, isModalOpened } from '../../../../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../../../../utils/cypressHelper';
import { shouldReconfigRouter } from '../../../../../../../../utils/cacheLoaders/reloaderHelper';
import { isPathUnderAdminEvent } from '../../../../../../../../utils/eventsHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../../utils/reduxHelper';
import { AdminEventClass } from '../../../../../../../../models/api/adminEventsCacheTwoEvent';
import { reduxStoreService } from '../../../../../../../../store/service';
import {isAdminEventsCacheThreeClassPopulated, isAdminEventsCacheTwoEventPopulated} from '../../../../../../../../utils/cachePopulatedCheckers/adminEvents';
import {WithInertAttribute} from '../../../../../../../Elements/WithInert';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{classId: string, eventId: string}, {}>;

type Props = WithInertAttribute<{
  type: 'add' | 'edit';
  classId?: number;
  onDelete?: (adminClass: AdminEventClass) => void;
  onBack?: () => void;
}>;
class ClassSideModal extends React.Component<Props, {}> {
  public props: ConnectedProps & Props;
  private nextLocation;

  componentDidUpdate(prevProps: ConnectedProps & Props) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
  }

  private configRouter() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    const {actions, params, adminEventsCacheThreeClass} = this.props;

    this.nextLocation = nextLocation;
    if (reduxStoreService().getState().app.apiLoading === 0) {
      if (
        !params.classId ||
        isAdminEventsCacheThreeClassPopulated(adminEventsCacheThreeClass)
      ) {
        actions.saveState();
      }
    }
  };

  componentWillUnmount() {
    const {type, actions, adminEventsCacheTwoEvent, adminEventsCacheThreeClass} = this.props;
    if (
      this.nextLocation &&
      !isPathUnderAdminEvent(this.nextLocation.pathname) &&
      isAdminEventsCacheTwoEventPopulated(adminEventsCacheTwoEvent)
    ) {
      reduxStoreService().dispatch(new ClearAdminEventsCacheTwoEvent());
    } else if (
      type === "add" ||
      isAdminEventsCacheThreeClassPopulated(adminEventsCacheThreeClass)
    ) {
      actions.clearAdminEventsCacheThreeClass();
    }

    this.resetRouteLeaveHook();
  }

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);
    this.configRouter();
  }

  onSave = () => {
    const {type, actions, router, adminEventsCacheTwoEvent, routes} = this.props;

    checkAdminEventClassPermission(
      () => {
        actions.apiSubmitForm(router, routes);
      },
      adminEventsCacheTwoEvent,
      true,
    );
  };
  onDelete = () => {
    const {actions, adminEventsCacheTwoEvent, adminEventsCacheThreeClass} = this.props;
    
    checkAdminEventClassPermission(
      () => {
        if (!adminEventsCacheThreeClass.EventsEventClass) return;
        actions.pushDeleteAdminEventClassModal({id: adminEventsCacheThreeClass.EventsEventClass.IDi});
      },
      adminEventsCacheTwoEvent,
      true,
    );
  };
  onClickModal = (e) => {
    e && e.stopPropagation();
  };
  onBack = () => {
    if (!this.props.adminEventsCacheTwoEvent.EventsEvent) return;
    if (this.props.onBack) {
      this.props.onBack();
      return;
    }
    const backUrl = getAdminEventsEventClassesUrl(constructAdminEventsEventUrlParams(this.props, this.props.adminEventsCacheTwoEvent.EventsEvent.IDi, this.props.adminEventsCacheTwoEvent.EventsEvent.Name));
    navPush(this.props.router, backUrl);
  };

  renderNotFound = () => {
    return (
      <NotFound showCMSSiteHeader inModal message={INVALID_CLASS}/>
    );
  };

  isDisabled = () => {
    const {type, adminEventsCacheTwoEvent} = this.props;

    if (!adminEventsCacheTwoEvent.EventsEvent) return;

    if (type === 'add') {
      return !adminEventsCacheTwoEvent.EventsEvent.Permissions.hasAddClass;
    } else {
      return !adminEventsCacheTwoEvent.EventsEvent.Permissions.hasEditClass;
    }
  };

  renderContent = (empty?: boolean) => {
    const { type, apiSaving, inert, adminEventsCacheOne, actions, cacheZero, formState} = this.props;

    return [
      <SideModalContent lockBodyScroll key='admin-event-class-content'>
        {!empty && <Form
          inert={inert}
          disabled={apiSaving > 0}
          action={type}
        />}
      </SideModalContent>,
      empty ? null : <SideModalActions key='admin-event-class-actions'>
        {type === 'add' && <Button disabled={this.isDisabled()} flat textColor="black" onClick={this.onSave}>CREATE</Button>}
        {type === 'edit' && <Button id={generateDOMId("tentaroo-save-class-btn")} disabled={this.isDisabled()} flat textColor="black" onClick={this.onSave}>SAVE</Button>}
        {type === 'edit' && <Button id={generateDOMId("tentaroo-delete-class-btn")} disabled={this.isDisabled()} flat textColor="red" onClick={this.onDelete}>DELETE</Button>}
      </SideModalActions>,
    ];
  };

  public render() {
    const {adminEventsCacheTwoEvent, type, adminEventsCacheThreeClass, formState: {ActiveForm}, adminEventsCacheOne, apiLoadingMap } = this.props;
    const isSelectClassTypesModalOpened = isModalOpened(ModalTypes.SELECT_CLASS_TYPE);
    const loading = isSelectClassTypesModalOpened ? false : (
      type === "edit" && (
        !isAdminEventsCacheThreeClassPopulated(adminEventsCacheThreeClass, ActiveForm) ||
        (isAdminEventsCacheThreeClassPopulated(adminEventsCacheThreeClass, ActiveForm) && apiLoadingMap[GetAdminEventClass.requestType]))
    );
    const subtitle = adminEventsCacheTwoEvent.EventsEvent ? adminEventsCacheTwoEvent.EventsEvent.Name : '';

    let content;

    if (type === 'add') {
      content = this.renderContent();
    } else {
      if (!isAdminEventsCacheThreeClassPopulated(adminEventsCacheThreeClass, ActiveForm) && apiLoadingMap[GetAdminEventClass.requestType]) {
        content = this.renderContent(true);
      } else if (!adminEventsCacheThreeClass.EventsEventClass || adminEventsCacheThreeClass.EventsEventClass.Inactive) {
        content = this.renderNotFound();
      } else if (adminEventsCacheThreeClass.EventsEventClass) {
        content = this.renderContent();
      }
    }

    return (
      <SideModal
        allowShadowClickDefault
        className={`${namespace()}`}
        onClick={this.onClickModal}
        stickyHeader
        header={<SideModalHeader
                  className={`${namespace()}--header`}
                  onBack={this.onBack}
                  subtitle={subtitle}
                  onlyShowSubTitleInMobile
                  title={type === 'add' ? 'New Class' : "Edit Class"} />}
        >
          {content}
        {loading ? <Loader className={`${namespace()}--loader`} center /> : null}
      </SideModal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSavingMap: state.app.apiSavingMap,
    cacheZero: state.cacheZero,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    formState: state.adminEvents.classes.form,
    adminEventsCacheThreeClass: state.adminEvents.cacheThreeClass,
    adminEventsCacheTwoEvent: state.adminEvents.cacheTwoEvent,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...rollbackActionCreators,
    ...adminEventsCacheThreeClassActionCreators,
    ...appActionCreators,
    ...adminEventsCacheTwoEventActionCreators,
  }, dispatch),
});

const ConnectedClassSideModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(ClassSideModal);

export default withRouter<Props>(ConnectedClassSideModal);
