
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetResource} from "./actions";
import { getResource, getResourceBody } from "../../../constants/urls";

export const adminCMSSiteCacheTwoResourcesEpic = makeRequestEpic(
  GetResource,
  getResource,
  getResourceBody,
  true,
);