import * as React from 'react';
import Title from "../Title";
import ActionButton from "../ActionButton";
import { namespace as ns } from './constants';
import '../../../styles/elements/card/title.scss';
import Arrow from '../../../images/elements/cart/arrow.svg';
import {default as attributes, MarginProps, PaddingProps} from "../../../styles/index";

export const namespace = (): string => `${ns()}--title`;

export interface TitleProps extends PaddingProps, MarginProps {
  className?: string;
  children?: React.ReactNode;
  extra?: React.ReactNode;
  num?: number;
  controls?: React.ReactNode;
  size?: number;
  mobileSize?: number;
  line?: number;
  controlsWrap?: boolean;
  onToggle?: () => void;
  toggled?: boolean;
  color?: 'gray';
  onClick?: () => void;
}

const TitleComponent: React.SFC<TitleProps> = (
  { children, className: classNameOverride, extra, num, controls, size, line, controlsWrap, mobileSize, onToggle, toggled, color, onClick, ...props }: TitleProps
): React.ReactElement<TitleProps> => {
  let className: string = attributes(namespace(), classNameOverride, props);

  if (onToggle) {
    controls = (
      <ActionButton selected={toggled} onClick={onToggle} rotate={toggled ? 180 : undefined}><Arrow/></ActionButton>
    );
    controlsWrap = false;
  }

  return (
    <Title
      extra={extra}
      className={className}
      num={num}
      controls={controls}
      size={size}
      mobileSize={mobileSize}
      line={line}
      controlsWrap={controlsWrap}
      color={color}
      onClick={onClick}
    >
      {children}
    </Title>
  );
};

TitleComponent.defaultProps = {
  size: 22,
  controlsWrap: true
} as TitleProps;

export default TitleComponent;
