import * as React from 'react';
import { namespace, actionNamespace } from './constants';
import { ActionButton } from '../../../Elements';
import { SearchIcon } from '../../../Icons';
import '../../../../styles/app/header/actions/search.scss';

interface Props {
  onClick: React.EventHandler<any>;
}

const Search: React.SFC<Props> = ({ onClick }: Props): React.ReactElement<Props> => (
  <ActionButton className={`${actionNamespace()} ${namespace()}--search`} onClick={onClick}>
    <SearchIcon className={`${namespace()}--search--image`} width="18px" height="18px"/>
  </ActionButton>
);

export default Search;
