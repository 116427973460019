import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import * as M from '../../../../../constants/messages/generic';
import { AdminEventsEventCategory } from "../../../../../models/api/adminEventsCacheOne";
import { EventTypeGeneralFormDefinition } from "../../EventType/Form/validation";
import { makeSelectedEventCategorySelector } from ".";
import { SelectValidator, Validator } from "../../../../../utils/validator/models";

export interface INewEventTypeValidator {
  Name: Validator;
  Description: Validator;
  LeadersGuideURL: Validator;
  WebsiteURL: Validator;
  PrimaryContactName: Validator;
  PrimaryContactPhone: Validator;
  PrimaryContactEmail: Validator;
  RegistrationMethodID: Validator;
  LocationID: Validator;
  EventCategoryID: SelectValidator;
  DistrictIDi?: SelectValidator;
}
export const NewEventTypeFormDefinition: INewEventTypeValidator = {
  RegistrationMethodID: {...EventTypeGeneralFormDefinition.RegistrationMethodID},
  Name: {...EventTypeGeneralFormDefinition.Name},
  Description: {...EventTypeGeneralFormDefinition.Description},
  LeadersGuideURL: {...EventTypeGeneralFormDefinition.LeadersGuideURL},
  WebsiteURL: {...EventTypeGeneralFormDefinition.WebsiteURL},
  PrimaryContactName: {...EventTypeGeneralFormDefinition.PrimaryContactName},
  PrimaryContactPhone: {...EventTypeGeneralFormDefinition.PrimaryContactPhone},
  PrimaryContactEmail: {...EventTypeGeneralFormDefinition.PrimaryContactEmail},
  LocationID: {
    ...EventTypeGeneralFormDefinition.LocationID,
    customValidate: (rootState) => {
      const form = rootState.adminEvents.eventTypes.modals.newEventType.General.ActiveForm;
      if (!form.LocationID) {
        return M.INVALID_SELECTED_LOCATION;
      } else {
        const location = rootState.cacheZero.options && rootState.cacheZero.options.Locations ? rootState.cacheZero.options.Locations.find((l) => l.ID === form.LocationID) : null;

        if (!location || (location as any).Inactive) {
          return M.INVALID_SELECTED_LOCATION;
        }
      }
    },
  },
  EventCategoryID: {
    ...EventTypeGeneralFormDefinition.EventCategoryID,
    customValidate: (rootState) => {
      const EventCategoryID = rootState.adminEvents.eventTypes.modals.newEventType.General.ActiveForm.EventCategoryID;

      if (EventCategoryID) {
        const eventCategory = rootState.cacheZero.options && rootState.cacheZero.options.EventCategories ? rootState.cacheZero.options.EventCategories.find((c) => c.ID === EventCategoryID) : null;

        if (eventCategory && eventCategory.Inactive) {
          return M.SELECTED_DROPDOWN_ITEM_DELETED;
        }
      }
    },
  },
  // TODO: This is duplicated with the one in EventTypeGeneralFormDefinition, we can
  // combine them when using pattern like `makeGroupIDiValidationObject` to
  // share validation
  DistrictIDi: {
    ...EventTypeGeneralFormDefinition.DistrictIDi,
    isRequired: (rootState) => {
      const selectedCategorySelector = makeSelectedEventCategorySelector();
      const selectedCategory = selectedCategorySelector(rootState) as AdminEventsEventCategory;

      return selectedCategory && selectedCategory.IsDistricts;
    },
    validatejs: {
      DistrictIDi: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        },
      },
    },
  },
};