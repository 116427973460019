import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { default as TextField, TextFieldProps } from '../TextField';
import Cleave from 'cleave.js';
import CardIcon from '../../../images/elements/credit-card-types/card.svg';
import '../../../styles/elements/credit-card/index.scss';
import {CreditCardType} from "../../../store/Checkout";
import { PaymentTypeVisaIcon, PaymentTypeMasterCardIcon, PaymentTypeAmericanExpressIcon, PaymentTypeDiscoverIcon } from '../../../components/Icons';
import {Nullable} from '../../../utils/dataHelper';

export const namespace = (): string => 'elements--credit-card';

export interface CreditCardProps extends TextFieldProps {
  creditCardType?: CreditCardType;
  onCreditCardTypeChange: (creditCardType?: CreditCardType) => void;
}

class CreditCard extends React.Component<CreditCardProps, {}> {
  public props: CreditCardProps;

  public componentDidMount() {
    const inputElement = (findDOMNode(this) as Nullable<Element>)?.getElementsByTagName('input')[0];
    if (inputElement) {
      new Cleave(inputElement, {
        creditCard: true,
        onCreditCardTypeChanged: this.handleTypeChange
      });
    }
  }

  public handleTypeChange = (type: string) => {
    // console.log(type);
    switch (type) {
      case 'visa':
      case 'mastercard':
      case 'amex':
      case 'discover':
        this.props.onCreditCardTypeChange(type);
        break;
      default:
        this.props.onCreditCardTypeChange();
        break;
    }
  };

  public render() {
    const { creditCardType } = this.props;
    let iconClassName = `${namespace()}--card-icon`;
    if (creditCardType) {
      iconClassName += ' has-type';
    }

    return (
      <TextField
        icon={(
          <div className={iconClassName}>
            {!creditCardType ? <CardIcon className={`${namespace()}--card-icon--svg`}/> : null}
            {creditCardType === 'visa' ? <PaymentTypeVisaIcon /> : null}
            {creditCardType === 'mastercard' ? <PaymentTypeMasterCardIcon /> : null}
            {creditCardType === 'amex' ? <PaymentTypeAmericanExpressIcon /> : null}
            {creditCardType === 'discover' ? <PaymentTypeDiscoverIcon /> : null}
          </div>
        )}
        {...this.props}
      />
    );
  }
}

export default CreditCard;
