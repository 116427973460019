import {UserModel} from "../../models/api/user";

export const EMPTY_USER: UserModel = {
    appVersion: '',
    CampID: -1,
    str_permissions: {},
    LastName: '',
    userType: 'group',
    FirstName: '',
    Email: '',
    IDi: -1
  };