
import * as React from 'react';

import { Row, SimpleListFilter } from '../../../../../../components/Elements';
import { namespace } from '../';
import { ApplicationState } from '../../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../../../../../store/Admin/Modals/Accounts/actions";
import {actionCreators as appActionCreators} from '../../../../../../store/App/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../store/Rollback/actions';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { SelectValidator } from '../../../../../../utils/validator/models';

interface Props {

}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class ReservationsFilters extends React.PureComponent<Props, {}> {
  public props: ConnectedProps & Props;

  onChangeLocationFilter = (value: number, vObj: SelectValidator) => {
    this.props.actions.updateValue(value, vObj);
    if (this.props.adminAccounts.ActiveForm.ReservationsLocationID !== undefined) this.props.actions.getReservations(value, undefined, undefined, true);
  };

  onChangeFacilityTypeFilter = (value: number, vObj: SelectValidator) => {
    this.props.actions.updateValueAndSaveAfter(value, vObj);
  };

  onChangeYearFilter = (value: number, vObj: SelectValidator) => {
    const {actions: { updateValue, getReservations }} = this.props;

    updateValue(value, vObj);
    if (this.props.adminAccounts.ActiveForm.ReservationsYear !== undefined) {
      getReservations(undefined, value, undefined, true);
    }
  };

  render() {
    const { adminAccounts: { ActiveForm, ValidationRules } } = this.props;
    return (
      <Row className={`${namespace()}--filter-row shadow`}>
        <SimpleListFilter
          label="Location"
          className={`${namespace()}--location-filter reservations`}
          onChange={this.onChangeLocationFilter}
          value={ActiveForm.ReservationsLocationID}
          validationRules={ValidationRules.ReservationsLocationID}
        />
        <SimpleListFilter
          label="Facility Type"
          className={`${namespace()}--facility-type-filter registrations`}
          onChange={this.onChangeFacilityTypeFilter}
          value={ActiveForm.ReservationsFacilityTypeID}
          validationRules={ValidationRules.ReservationsFacilityTypeID}
        />
        <SimpleListFilter
          className={`${namespace()}--year-filter reservations`}
          label="Year"
          onChange={this.onChangeYearFilter}
          value={ActiveForm.ReservationsYear}
          validationRules={ValidationRules.ReservationsYear}
        />
      </Row>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    adminAccounts: state.adminAccounts,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...rollbackActionCreators
  },
  dispatch)
});

const ConnectedReservationsFilters = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ReservationsFilters);

export default ConnectedReservationsFilters;