import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import Shadow from "../Shadow";

import '../../../styles/elements/header/index.scss';

import {namespace} from "./constants";

export interface HeaderProps {
  className?: string;
  children?: React.ReactNode;
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
  layout?: 'vertical' | 'horizontal';
}

const Header: React.SFC<HeaderProps> = ({ mobile, tablet, desktop, children, layout, ...props }: HeaderProps): React.ReactElement<HeaderProps> => {
  let className = mergeClassNames(namespace(), props);
  if (mobile || tablet || desktop) {
    className += ' hide-all-except';
    if (mobile) className += ' show-mobile';
    if (tablet) className += ' show-tablet';
    if (desktop) className += ' show-desktop';
  }

  return (
    <Shadow shadow={0} className={className}>
      <div className={`${namespace()}--content` + (layout === 'vertical' ? ' layout-vertical' : '')}>
        {children}
      </div>
    </Shadow>
  );
};

export default Header;