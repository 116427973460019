import {BaseEndUserClass, Day, RegisteredClass} from "../models/class";
import moment from 'moment';
import * as M from '../constants/messages/generic';
import * as NM from '../constants/messages/numbersWizard';
import * as PM from '../constants/messages/participantsWizard';
import {activeRegisteredFilter} from "../store/CacheFourEventsNumbers/uiHelpers";
import {NumbersWizardConfirmationState} from "../store/Events/Event/Register/Numbers/Confirmation/index";
import {NumbersWizardSpotsState} from "../store/Events/Event/Register/Numbers/Spots/index";
import {NumbersWizardClassesState} from "../store/Events/Event/Register/Numbers/Classes/index";
import {NumbersWizardCampsiteState} from "../store/Events/Event/Register/Numbers/Campsite/index";
import {AMOUNT_TOO_HIGH, AMOUNT_TOO_LOW} from "../constants/messages/events";
import { reduxStoreService } from "../store/service";
import { Validator } from './validator/models';
import type { ApplicationState } from "../store";

export interface ClassViewState {
  statusText: string;
  tooltip: string;
  state: 'waitlist' | 'full' | 'warning' | null;
  numParticipants: number;
  availNum: number;
}

const warningSpotsThreshold: number = 3;

//Determine # in group class, or would be in group class.
export const determineNumParticipants = (
  JustYouth: 1 | 0 | -1,
  NumOverride: number | undefined,
  NumYouth: number,
  NumAdults: number,
) => {
  let numParticipants: number;

  if (JustYouth === -1) numParticipants = NumYouth + NumAdults;
  else if (JustYouth === 1) numParticipants = NumYouth;
  else numParticipants = NumAdults;

  numParticipants = NumOverride !== undefined && NumOverride > 0 && NumOverride < numParticipants ? NumOverride : numParticipants;
  return numParticipants;
};
export const determineNumParticipantsAdmin = (JustYouth: 1 | 0 | -1, NumYouth: number, NumAdults: number, isAdmin: boolean) => {
  let num: number = 0;
  if (JustYouth === -1) num = NumYouth + NumAdults;
  else if (JustYouth == 1) num = NumYouth;
  else num = NumAdults;
  if (isAdmin) num = 1; // Make sure at least one spot is available (instead of a spot for each participant), otherwise, can't use NumOverride to change.
  return num;
};

export const getClassViewState = (
  c: BaseEndUserClass | RegisteredClass,
  IsIndividualRegistration: boolean,
  EnableClassWaitingList: boolean,
  NumYouth: number,
  NumAdults: number,
  isRegistration: boolean, // data is ClassRegistrationVO --- registered classes
  isRegistrationOption: boolean, // data is ClassRegistrationOptionVO --- available classes
  isPreview: boolean,
  numOverride: number | undefined,
  isAdmin: boolean
):ClassViewState => {
  const {Maximum, RegistrationRank, MaxRegistrationRank, NumSpotsAvailable, JustYouth} = c;

  const IsUnscheduled = "IsUnscheduled" in c ? c.IsUnscheduled : undefined;
  const InCart = "InCart" in c ? c.InCart : undefined;
  // const NumOverride = c.NumOverride !== undefined ? c.NumOverride : 10000;

  let NumOverride = numOverride;
  if (isAdmin && isRegistrationOption) {
    NumOverride = 1;
  } else if (!NumOverride) {
    NumOverride = 10000;
  }

  //Calculate # available spots. Remove 1 from RegistrationRank as rank represents CURRENT/PENDING spot,
  //  change to # of spots BEFORE this one.
  let availNum: number = 0;
  if (IsIndividualRegistration) {
    availNum = (Maximum === 0 || IsUnscheduled) ? 10000 : (Maximum - (RegistrationRank - 1));
  } else {
    availNum = NumSpotsAvailable;
  }

  //Additional notice regarding waiting list position.
  let tentativeSpotTooltip: string = '';
  if (isRegistration && InCart !== undefined && InCart === 1) {
    tentativeSpotTooltip = " NOTE: The position on the waiting list is NOT guaranteed until you " +
      "checkout to finalize this class registration.";
  }

  let rank: number = RegistrationRank;
  if (isPreview) rank = MaxRegistrationRank;

  let numParticipants: number = 1;

  if (!IsIndividualRegistration)
    numParticipants = determineNumParticipants(JustYouth, NumOverride, NumYouth, NumAdults);


  /* If this is an option (not in class schedule) and the registration rank is locked in
   - show generic descriptions for spots available.
   If RegistrationRank <> MaxRegistrationRank (<), locked into an earlier rank either because
   this registration has been finalized, OR because the rank was inherited from some removal
   either under this participant or a different participant in the same group/event registration. */
  if (!isPreview && IsIndividualRegistration && isRegistrationOption && RegistrationRank !== MaxRegistrationRank && availNum <= 5000 && Maximum >= 0) {
    if (availNum <= 0 && EnableClassWaitingList) {
      //Add one to get position of current spot on waiting list.
      return {
        statusText: `${convertNumberToOrdinal(Math.abs(availNum) + 1)} on Waitlist Available`,
        tooltip: `The ${convertNumberToOrdinal(Math.abs(availNum) + 1)} spot ` +
          'on the waiting list is available either because this participant has already ' +
          'registered for it, or because this class is being removed from another participant.' +
          tentativeSpotTooltip,
        state: 'waitlist',
        numParticipants: numParticipants,
        availNum: availNum
      };

    } else if (availNum > 0) {
      return {
        statusText: `Available`,
        tooltip: `A spot is available in this class either because this participant has already ` +
        'registered for it, or because this class is being removed from another participant.',
        state: null,
        numParticipants: numParticipants,
        availNum: availNum
      };
    }
  }

  //Group Classes
  if (!IsIndividualRegistration && Maximum > 0) {

    if (isRegistration) {
      //Registration (on left)
      if (availNum < numParticipants){
        return {
          statusText: 'FULL',
          tooltip: `This class is overbooked by ${(numParticipants - availNum).toString()} participant${((numParticipants - availNum != 1) ? 's' : '')}` +
          '. Please reduce your event registration or remove this class/option and try again.',
          state: 'full',
          numParticipants: numParticipants,
          availNum: availNum
        };
      } else {
        return {
          statusText: ((availNum - numParticipants) == 1) ?
            "One Additional Spot Available" :
            (availNum - numParticipants).toString() +" Additional Spots Available",
          tooltip: '',
          state: null,
          numParticipants: numParticipants,
          availNum: availNum
        };
      }
    } else {
      if (availNum < 1) {
        return {
          statusText: 'FULL',
          tooltip: 'This class is full and cannot be added to your schedule.',
          state: 'full',
          numParticipants: numParticipants,
          availNum: availNum
        };
      } else {
        return {
          statusText: availNum.toString() +" Spot"+ (availNum==1 ? '' : 's') +
          " Available",
          tooltip: '',
          state: null,
          numParticipants: numParticipants,
          availNum: availNum
        };
      }
    }
  }

  //Determine availability text, other cases.

  if (availNum > 5000) {
    return {
      statusText: 'Unlimited',
      tooltip: '',
      state: null,
      numParticipants: numParticipants,
      availNum: availNum
    };
  } else if (Maximum < 0) {
    return {
      statusText: 'UNAVAILABLE',
      tooltip: "This class is no longer available since it has been removed from the class schedule for this event." +
        ((isRegistration) ? " You'll need to remove this class before checking out." : ""),
      state: 'full',
      numParticipants: numParticipants,
      availNum: availNum
    };
  } else if (availNum <= 0 && (!EnableClassWaitingList || !IsIndividualRegistration)) {
    //No waiting list, class is full or overfull.
    if (InCart !== 1 && IsIndividualRegistration && isRegistration) {
      return {
        statusText: "Current spot: "+ rank.toString() +"/"+ Maximum,
        tooltip: "This class is overbooked; however, this registration was already finalized " +
          "so you don't need to remove this unless requested by the Camping Department.",
        state: null,
        numParticipants: numParticipants,
        availNum: availNum
      };
    } else {
      return {
        statusText: 'FULL',
        tooltip: (isRegistration) ?
          "This class is full and will need to be removed before checking out." :
          "This class is full and cannot be added to your schedule.",
        state: 'full',
        numParticipants: numParticipants,
        availNum: availNum
      };
    }
  } else if (availNum <= 0) {
    if (availNum == 0 && isRegistrationOption) {
      return {
        statusText: "Empty"+ (InCart === 1 ? "*" : "") +" Waiting List",
        tooltip: "This class is full; however, you can add yourself to the waiting list. " +
          "No one else is on the waiting list yet."+ tentativeSpotTooltip,
        state: 'waitlist',
        numParticipants: numParticipants,
        availNum: availNum
      };
    } else if (isRegistration) {
      return {
        statusText: convertNumberToOrdinal(Math.abs(availNum) + 1) +
          (InCart === 1 ? "*" : "") + " on Waiting List",
        tooltip: "Your position on the waiting list is #"+ (Math.abs(availNum) + 1).toString() +"."+
          tentativeSpotTooltip,
        state: 'waitlist',
        numParticipants: numParticipants,
        availNum: availNum
      };
    } else {
      return {
        statusText: Math.abs(availNum).toString() +
          " on Waiting List",
        tooltip: (availNum == -1) ?
          ("There is one other person already on the waiting list."+ tentativeSpotTooltip) :
          ("There are "+ Math.abs(availNum).toString() +
          " other people already on the waiting list."+ tentativeSpotTooltip),
        state: 'waitlist',
        numParticipants: numParticipants,
        availNum: availNum
      };
    }
  } else {
    if (IsIndividualRegistration && isRegistration) {
      return {
        statusText: "Current spot: "+ rank.toString() +"/"+ Maximum,
        tooltip: '',
        state: null,
        numParticipants: numParticipants,
        availNum: availNum
      };
    } else {
      return {
        statusText: availNum.toString() +" Spot"+ (availNum==1 ? '' : 's') + " Available",
        tooltip: '',
        state: (availNum <= warningSpotsThreshold) ? 'warning' : null,
        numParticipants: numParticipants,
        availNum: availNum
      };
    }
  }
};

export const convertNumberToOrdinal = (i: number) => {
  let j = i % 10;
  let k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
};

export const standardCurrencyFormat = (amount: number) => {
  const absAmount = Math.abs(amount);
  if (amount < 0) return `-$${absAmount.toFixed(2)}`;
  return `$${absAmount.toFixed(2)}`;
};

export const calcRowColor = (ErrorStringClass:string):string => {
  if(!ErrorStringClass && ErrorStringClass !== '') {
    return '#ff8080';
  } else {
    return '#ffffff';
  }
};

export const getYAText = (c: BaseEndUserClass | RegisteredClass, IsIndividualRegistration: boolean) : String => {
  const {JustYouth} = c;
  if (IsIndividualRegistration) return "";
  switch (JustYouth) {
    case 1:
      return " (Youth)";
    case 0:
      return " (Adults)";
    default:
      return " (Both Y/A)";
  }
};

// @todo: make sure i don't show tooltip if blank string
export const getAmountTooltip = (
  c: BaseEndUserClass | RegisteredClass,
  IsIndividualRegistration: boolean,
  isRegistration: boolean, // data is ClassRegistrationVO --- registered classes
  IsAdmin: boolean,
  labelOptions?: boolean
) : string => {
  const {Amount, AmountMin, AmountEstimated, IsBaseFee, JustYouth} = c;
  const AmountPaid = "AmountPaid" in c ? c.AmountPaid : undefined;
  const NumOverride = "NumOverride" in c ? c.NumOverride : undefined;
  let tooltip: string = "";

  //Group or Individual Options
  if (IsBaseFee && IsIndividualRegistration) {
    tooltip = "This option will adjust your event registration fees by "+ standardCurrencyFormat(Amount) +". This option will always appear as $0 in the cart since it is considered part of your registration fees.";
    return tooltip;
  }
  //Group Options
  else if (IsBaseFee) {
    tooltip = "This option will adjust your event registration fees by "+ standardCurrencyFormat(Amount);
    switch (JustYouth) {
      case 1:
        tooltip = tooltip + " per youth.";
        break;
      case 0:
        tooltip = tooltip + " per adult.";
        break;
      default:
        tooltip = tooltip + " per person.";
    }

    if (NumOverride) {
      tooltip = tooltip + " This option has been overridden to apply to a maximum of "+
        NumOverride.toString() + " participants in this registration.";
    }

    return tooltip;
  }

  //Class, not Option. First, handle classes for individuals.
  if (IsIndividualRegistration && (AmountPaid || Amount || AmountEstimated)) {
    if (AmountPaid) {
      tooltip = standardCurrencyFormat(AmountPaid) +` has been paid towards registration fees for this ${labelOptions ?'option':'class'}.`;
      if (Amount > AmountPaid) {
        tooltip = tooltip + ' There is a remaining balance of '+ standardCurrencyFormat(Amount - AmountPaid) +` (not including any payments in your cart) on your account for this ${labelOptions ?'option':'class'}.`;
      } else if (Amount < AmountPaid) {
        tooltip = tooltip + ' A credit of '+ standardCurrencyFormat(AmountPaid - Amount) +
          ' will be added to your account when this registration is saved.';
      }
    }
    /* No payment yet. */
    else if (Amount && (!isRegistration || !c.IDi)) {
      //Newly adding, or still in options.
      tooltip = `This ${labelOptions ?'option':'class'} has a fee of `+ standardCurrencyFormat(Amount);
      if (Amount <= AmountMin) {
        tooltip = tooltip + ' which is due upfront and must be paid when checking out in order to finalize the registration.';
      } else if (AmountMin > 0) {
        tooltip = tooltip + ', of which '+ standardCurrencyFormat(Math.min(Amount, AmountMin)) +
          ' is due upfront and must be paid when checking out in order to finalize the registration.';
      } else {
        tooltip = tooltip + `. The fees for this ${labelOptions ?'option':'class'} will be added to your event registration balance upon checkout.`;
      }
    } else if (Amount) {
      tooltip = `This ${labelOptions ?'option':'class'} has a fee of `+ standardCurrencyFormat(Amount) +
        '. This fee is part of your current balance.';
    } else if (AmountEstimated) {
      return 'An estimated amount of '+ standardCurrencyFormat(AmountEstimated)+ ` will need to be paid at the event for this ${labelOptions ?'option':'class'}. Because this is an estimated amount, it will not show up on your event balances or on your invoices.`;
    }
  }

  //Group Class
  else if (!IsIndividualRegistration && (AmountPaid || Amount)) {
    if (AmountPaid){
      tooltip = standardCurrencyFormat(AmountPaid) +' has been paid towards this option. ';
    }
    tooltip = tooltip +"This option has a fee of "+ standardCurrencyFormat(Amount);
    switch (JustYouth) {
      case 1:
        tooltip = tooltip + " per youth.";
        break;
      case 0:
        tooltip = tooltip + " per adult.";
        break;
      default:
        tooltip = tooltip + " per person.";
    }

    if (!Amount && AmountEstimated) {
      tooltip = 'An estimated amount of '+ standardCurrencyFormat(AmountEstimated)+ ` will need to be paid at camp for this option. Because this is an estimated amount, it will not show up on your event balances or on your invoices.`;
    }

    if (NumOverride) {
      tooltip = tooltip + " This option has been overridden to apply to a maximum of "+
        NumOverride.toString() +" participants in this registration.";
    }

    return tooltip;
  }
  //Group or individual class, no Amount or AmountPaid.
  // else if (
  //   Amount === 0 && AmountEstimated === 0 &&
  //   (
  //     (!IsIndividualRegistration && IsAdmin) ||
  //     (registrationRow != null && IsAdmin && !(registrationRow.Inactive || registrationRow.PendingInactive))
  //   )
  // ) {
  //   return 'Click to add a fee to this class registration.';
  // }

  return tooltip;
};


export interface ClassValidation {
  error: boolean;
  warnings: Array<WarningMessage>;
}


export const checkClasses = (
  EventClassesRegistered: Array<RegisteredClass>,
  EventClassesAvailable: Array<BaseEndUserClass>,
  filteredAvailableClasses: Array<BaseEndUserClass>, // should not contain conflicts
  IsYouth: boolean,
  isGroupClasses: boolean,
  EnableClassWaitingList: boolean,
  NumYouth: number,
  NumAdults: number,
  isAdmin: boolean,
  dateOfBirth?: moment.Moment
): ClassValidation => {
  let validClassOverbooked: boolean = true;
  let validClassAge: boolean = true;
  let hasPartialSchedule: boolean = true;
  let hasEmptyClassSchedule: boolean = true;

  //Review each class registration
  EventClassesRegistered.forEach((cr: RegisteredClass) => {
    if (!cr.Inactive) {
      if (validClassOverbooked && !checkClassOverbooked(cr, isGroupClasses, EnableClassWaitingList, NumYouth, NumAdults)) {
        validClassOverbooked = false;
      }
      if (validClassAge && validClassOverbooked) {
        validClassAge = checkClassAge(cr, isGroupClasses, IsYouth, dateOfBirth);
      }
    }
  });

  let useOptionsTerm = shouldUseOptionsTerm(isGroupClasses);

  if (!validClassOverbooked) {
    return {error: true, warnings: [{
      title: "Error - Class Schedule",
      message: NM.ONE_UNAVAILABLE(useOptionsTerm)
    }]};
  }
  if (!validClassAge && !isAdmin) {
    // The warning version of this is below
    return {error: true, warnings: [{
      title: "Error - Class Schedule",
      message: NM.CLASSES_MINIMUM_AGE_WARNING(useOptionsTerm)
    }]};
  }


  //If we haven't returned yet, check for missing/unfinished class schedule.
  // First, check for minimum TB to require.
  const s = reduxStoreService().getState(); // @todo: maybe pass in? shouldn't matter
  let requireFullClassScheduleTB: number = 0;

  if (s.cacheTwoEvents.EventTypeRegistrationSettings) {
    if (isGroupClasses) {
      requireFullClassScheduleTB = s.cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.RequireFullClassScheduleTBGroup;
    } else if (IsYouth) {
      requireFullClassScheduleTB = s.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.RequireFullClassScheduleTBYouth;
    } else {
      requireFullClassScheduleTB = s.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.RequireFullClassScheduleTBAdult;
    }
  }

  hasPartialSchedule = checkPartialClassSchedule(filteredAvailableClasses, isGroupClasses, requireFullClassScheduleTB);
  hasEmptyClassSchedule = checkEmptyClassSchedule(EventClassesRegistered, filteredAvailableClasses);

  const warnings: Array<WarningMessage> = checkClassShowWarnings(
    validClassAge,
    hasPartialSchedule,
    hasEmptyClassSchedule,
    IsYouth,
    isGroupClasses,
    requireFullClassScheduleTB,
    dateOfBirth
  );

  return {error: false, warnings: warnings};
};

const checkClassOverbooked = (
  cr: RegisteredClass,
  isGroupClasses: boolean,
  EnableClassWaitingList: boolean,
  NumYouth: number,
  NumAdults: number,
): boolean => {
  //Never valid if class has been deleted (Unavailable)
  if (cr.Maximum < 0) return false;

  //Always valid if unscheduled, or unlimited, or if the waiting list is enabled.
  if (cr.IsUnscheduled || cr.Maximum == 0 || (!isGroupClasses && EnableClassWaitingList)) return true;

  //Otherwise, compare numbers to see if enough spots are available.
  if (isGroupClasses) {
    let availNum: number = cr.NumSpotsAvailable;
    let numParticipants: number = determineNumParticipants(cr.JustYouth, cr.NumOverride, NumYouth, NumAdults);
    return availNum >= numParticipants;
  }

  //If here, class is for individuals and no waiting list.
  if (cr.InCart !== 1) return true; //  @todo: this was !InCart

  //If still in cart, enforce RegistrationRank.
  return cr.RegistrationRank <= cr.Maximum;
};

export const checkClassAge = (
  cr: {MinimumAge: string},
  isGroupClasses: boolean,
  IsYouth: Boolean,
  dateOfBirth?: moment.Moment
): boolean => {
  const rootState = reduxStoreService().getState();
  
  // skip validation if DOBYouth is hidden - treat it as valid
  if (rootState.cacheTwoEvents && rootState.cacheTwoEvents.EventTypeRegistrationSettings && !rootState.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.ShowDOBYouth) return true;
  //Check to see if the selected class has a minimum age requirement (only check if registration is valid otherwise)
  if (Number(cr.MinimumAge) > 0 && !isGroupClasses && IsYouth && rootState.cacheThreeEvents.EventInfo) {
    //Check to see if the participant is old enough (if the DOB entered is valid).
    let validClassAge: boolean = false;
    if (dateOfBirth) {
      validClassAge = true;
      const eventStart = rootState.cacheThreeEvents.EventInfo.StartDateTime;
      const duration = moment.duration(eventStart.diff(dateOfBirth));
      const years = duration.asYears();
      if (years < Number(cr.MinimumAge)) {
        validClassAge = false;
      }
    }

    return validClassAge;
  }
  return true;
};

const checkPartialClassSchedule = (
  filteredAvailableClasses: Array<BaseEndUserClass>,
  isGroupClasses: boolean,
  requireFullClassScheduleTB: number
): boolean => {
  if (requireFullClassScheduleTB > 0) {
    //Check to see if the number of timeblocks & offsets is less than a full schedule
    //Check available classes - if there are still classes available then the class schedule is not yet full.
    const partial = filteredAvailableClasses.find((ca: BaseEndUserClass) => ca.FirstTimeBlock <= requireFullClassScheduleTB);
    return !!partial;
  }
  return false;
};

const checkEmptyClassSchedule = (
  EventClassesGroupRegistered: Array<RegisteredClass>,
  filteredAvailableClasses: Array<BaseEndUserClass>
): boolean => {
  const activeRegistered = EventClassesGroupRegistered.filter(activeRegisteredFilter);
  return (activeRegistered.length === 0 && filteredAvailableClasses.length !== 0);
};

export interface WarningMessage {
  title: string;
  message: string;
}

// @todo: should probably return string
const checkClassShowWarnings = (
  validClassAge: boolean,
  hasPartialSchedule: boolean,
  hasEmptyClassSchedule: boolean,
  IsYouth: boolean,
  isGroupClasses: boolean,
  requireFullClassScheduleTB: number,
  dateOfBirth?: moment.Moment,
): Array<WarningMessage> => {
  const warnings: Array<WarningMessage> = [];
  const settings = reduxStoreService().getState().cacheTwoEvents.EventTypeRegistrationSettings;

  let useOptionsTerm = shouldUseOptionsTerm(isGroupClasses);

  // three cases, participant with without ClassesAs and group
  if (hasPartialSchedule && !hasEmptyClassSchedule && requireFullClassScheduleTB > 0) {
    if (!isGroupClasses) {
      warnings.push({message: NM.SCHEDULE_NOT_FULL_PART(useOptionsTerm), title: `Warning - Not All ${useOptionsTerm ? 'Classes' : 'Options'} Scheduled`});
    } else {
      warnings.push({message: NM.SCHEDULE_NOT_FULL_GROUP(true), title: `Warning - Not All Options Scheduled`});
    }
  }

  if (hasEmptyClassSchedule) {
    let message: string = NM.NO_CLASS_YET(useOptionsTerm);

    if (requireFullClassScheduleTB > 0) {
      message += NM.MUST_FULL(useOptionsTerm);
    }
    warnings.push({message: message, title: `Warning - No ${useOptionsTerm ? 'Classes' : 'Options'} Scheduled`});
  }

  if (!validClassAge) {
    let title = "Warning - Admin Override";
    let message = "";

    if (!validClassAge && !dateOfBirth) {
      message = PM.NO_DOB;
    } else if (!validClassAge) {
      message = PM.LOW_DOB;
    }
    warnings.push({message: message, title: title});
  }

  // The old code displayed warning in reverse order, so here we'll reverse the warnings to match
  warnings.reverse();

  return warnings;
};

/*
 * Returns the first error from any page, and then warnings. (only classes actually has warning though.
 * Spots and Confirmation page use this, however Confirmation shows its own error before any others.
 */
export const getGlobalError = (props: {
  confirmation: NumbersWizardConfirmationState;
  spots: NumbersWizardSpotsState;
  classes: NumbersWizardClassesState;
  campsite: NumbersWizardCampsiteState;
}): string | undefined => {
  const {spots, classes, campsite, confirmation} = props;
  if (spots.SubmitErrorMessage) return spots.SubmitErrorMessage;
  else if (classes.SubmitErrorMessage && classes.isError) return classes.SubmitErrorMessage;
  else if (campsite.SubmitErrorMessage) return campsite.SubmitErrorMessage;
  else if (confirmation.SubmitErrorMessage) return confirmation.SubmitErrorMessage;
  else if (classes.SubmitErrorMessage && !classes.isError) return classes.SubmitErrorMessage;
  return undefined;
};

const shouldUseOptionsTerm = (isGroupClasses: boolean) => {
  const settings = reduxStoreService().getState().cacheTwoEvents.EventTypeRegistrationSettings;

  // let ShowClassesAs = "classes";
  // if (isGroupClasses) {
  //   ShowClassesAs = 'options';
  // } else if (settings.NamesRegistrationSettings.ShowParticipantClassesAs.length > 0) {
  //   ShowClassesAs = settings.NamesRegistrationSettings.ShowParticipantClassesAs;
  // }
  let useClassesTerm = true;
  if (isGroupClasses || (settings && settings.NamesRegistrationSettings.ShowParticipantClassesAs.length > 0)) {
    useClassesTerm = false;
  }
  return !useClassesTerm;
};

export interface FinancialSummaryInit {
  paymentType: 'min' | 'full' | 'other' | 'credit',
  otherValueRules: Validator,
  otherDefaultValue?: number;
}

export const initFinancialSummary = (
  rootState: ApplicationState,
  PaymentStatus: {
    AmountMin: number;
    AmountMax: number;
    AmountInCart: number;
    AmountPaid: number;
  },
  isRequired: (rootState: ApplicationState) => boolean,
  isEventModule: boolean,
): FinancialSummaryInit => {
  const {AmountMin, AmountMax, AmountInCart, AmountPaid} = PaymentStatus;

  let paymentType: 'min' | 'full' | 'other' | 'credit' = 'other';
  let otherDefaultValue: number | undefined;

  if ((AmountMin !== AmountMax || AmountMin < 0) && AmountInCart === AmountMin) {
    paymentType = 'min';
    otherDefaultValue = AmountMin;
  } else if (AmountMin < 0 && AmountMax > 0 && AmountInCart === 0) {
    paymentType = 'credit';	//showApplyCreditPaymentAmount
    otherDefaultValue = 0;
  } else if (AmountInCart === AmountMax) {
    paymentType = 'full';
    otherDefaultValue = AmountMax;
  } else {
    paymentType = 'other';
    otherDefaultValue = AmountInCart;
  }

  const otherValueRules = {
    key: 'otherValue',
    isRequired: isRequired,
    decimalOnly: true,
    allowNegative: () => true,
    validatejs: {
      otherValue: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          notGreaterThanOrEqualTo: '^' + AMOUNT_TOO_LOW(`${AmountMin}`),
          greaterThanOrEqualTo: AmountMin,
          notLessThanOrEqualTo: '^' + AMOUNT_TOO_HIGH(`${AmountMax}`),
          lessThanOrEqualTo: AmountMax,
        }
      }
    }
  };

  if (rootState.user.user.str_permissions.hasAdminAccess && isEventModule) {
    const min = AmountPaid * -1;
    otherValueRules.validatejs.otherValue.numericality.notGreaterThanOrEqualTo = `^${AMOUNT_TOO_LOW(min)}`;
    otherValueRules.validatejs.otherValue.numericality.greaterThanOrEqualTo = min;
  }

  return {
    paymentType: paymentType,
    otherDefaultValue: otherDefaultValue,
    otherValueRules: otherValueRules
  };
};

export const availableFilter = (
  c: { NumSpotsAvailable: number; Maximum: number; JustYouth: 1 | 0 | -1; IsUnscheduled?: boolean; RegistrationRank: number},
  ShowFullClassesGroup: boolean,
  NumCampersYouth: number,
  NumCampersAdult: number,
  IsIndividualRegistration: boolean
) => {
  if (IsIndividualRegistration) return true;

  const isAdmin = !!reduxStoreService().getState().user.user.str_permissions.hasAdminAccess;

  let availNum: number = c.NumSpotsAvailable;

  let numParticipants: number = 0;
  if (c.Maximum !== 0 && !ShowFullClassesGroup) {
    numParticipants = determineNumParticipantsAdmin(c.JustYouth, NumCampersYouth, NumCampersAdult, isAdmin);
  }

  if (c.Maximum > 0 && availNum < numParticipants && !ShowFullClassesGroup) {
    //Class is too full, not showing full group classes.
    return false;
  }

  return true;
};

// @todo: sort out the proper class types here.
export const determineConflict = (
  checkClass: BaseEndUserClass,       //Either class being added, or looping through available classes
  registrations: Array<RegisteredClass>,
  RequireUniqueClassType: boolean,
  findAll?: boolean
): {
  conflictStatus: BaseEndUserClass["conflictStatus"];
  conflicts: Array<number>;
} => {
  let conflictStatus: BaseEndUserClass["conflictStatus"];
  const matches: Array<number> = [];

  for (let i = 0; i < registrations.length; i++) {
    let regist = registrations[i];
    if (regist.Inactive) continue; // If inactive, it cannot conflict
    if (regist.ClassIDi === checkClass.IDi) conflictStatus = "registered";
    
    if (RequireUniqueClassType && !regist.IsUnscheduled && checkClass.ClassTypeIDi === regist.ClassTypeIDi) {
      matches.push(i);
      if (!findAll) break;
      else continue;
    } else if (checkClass.ClassTypeIDi === regist.ClassTypeIDi && checkClass.IDi === regist.ClassIDi) {
      // cannot have same class twice
      matches.push(i);
      if (!findAll) break;
      else continue;
    }
    if (!checkClass.ParentIDi || checkClass.ParentIDi !== regist.ParentIDi) {
      const availTimeBlocks: Array<string> = checkClass.AllTimeBlocksDisplay.split(', ');
      const matchBlock = availTimeBlocks.find((checkBlock: string) => {
        const registTimeBlocks: Array<string> = regist.AllTimeBlocksDisplay.split(', ');
        const match = registTimeBlocks.find((rBlock: string) => rBlock === checkBlock);
        return !!match;
      });
      if (matchBlock) {
        //If IsAllWeek is set for either class, consider a conflict, even if no days have been selected for one of these classes.
        if (checkClass.IsAllWeek || regist.IsAllWeek) {
          matches.push(i);
          if (!findAll) break;
          else continue;
        }
        if (checkClass.Days === null || regist.Days === null) {
          continue; // cannot match timeblocks if you have no days
        }
        const matchDay = checkClass.Days.find((checkClassDay: Day) => {
          const match = regist.Days.find((registDay: Day) => registDay.DayOfEvent === checkClassDay.DayOfEvent);
          return !!match;
        });
        if (matchDay) {
          matches.push(i);
          if (!findAll) break;
          else continue;
        }
      }
    }
  }

  if (!conflictStatus) conflictStatus = matches.length > 0 ? "conflict" : "available";
  return {
    conflictStatus,
    conflicts: matches,
  };
};

interface ClassSortAttrs {
  FirstTimeBlock: number;
  FirstClassDay: number;
  Name: string;
}

export const classSort = (a: ClassSortAttrs, b: ClassSortAttrs) => {
  // FirstTimeBlock, FirstClassDay, Name
  if (a.FirstTimeBlock < b.FirstTimeBlock) {
    return -1;
  } else if (a.FirstTimeBlock > b.FirstTimeBlock) {
    return 1;
  }

  if (a.FirstClassDay < b.FirstClassDay) {
    return -1;
  } else if (a.FirstClassDay > b.FirstClassDay) {
    return 1;
  }

  if (a.Name.toLowerCase() === b.Name.toLowerCase()) {
    return 0;
  } else if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
    return -1;
  } else {
    return 1;
  }
};

export function makeTextForConflictingExpander(subject: "Options" | "Classes") {
  return `${subject} that would conflict with your current schedule`;
}