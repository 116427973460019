import {ApiSubmitActions} from "./actions";
import {makeRequestEpic} from "../../../../Validation/epicCreator";
import {eventParticipantsInit} from "../../../../../constants/urls";
import { ModalTypes } from "../../../../../utils/modalHelper";
import { popModalObservables } from "../../../../../utils/modalHelper";
import { Observable } from "rxjs";
import { Action } from "redux";

export const registerOverrideFeeSave = makeRequestEpic(
  ApiSubmitActions,
  eventParticipantsInit,
  undefined, // body is passed in as is in action.value
  true
);


export const registerOverrideFeeSuccessEpic = action$ => {
  return action$.filter((a) => a.type === ApiSubmitActions.successType)
    .mergeMap((action): Observable<Action> => {
      return Observable.concat(
        ...popModalObservables({
          modal: ModalTypes.REGISTER_OVERRIDE_FEE,
          saveBefore: false,
          saveAfter: false,
          transformToObservable: true,
        })
      );
    });
};
