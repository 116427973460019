import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/elements/grid/column.scss';
import attributes, {AlignmentProps, MarginProps, LayoutProps} from '../../../styles';

export const namespace = (): string => `${ns()}--column`;

export interface ColumnProps extends AlignmentProps, MarginProps, LayoutProps {
  children?: React.ReactNode;
  component?: React.ReactType | React.ReactElement<any> | 'string';
  className?: string;
  style?: React.CSSProperties;
  span?: number;
  mobileSpan?: number;
  width?: number;
  height?: number;
  expand?: boolean;
  bottom?: boolean;
  overflowHidden?: boolean;
  gutter?: boolean;
  renderMobile?: boolean;
}

const Column: React.SFC<ColumnProps> = ({
  span, mobileSpan, width, expand, bottom, style: inheritedStyle, gutter, overflowHidden, children, height,
  component, className: inheritedClassName, renderMobile, ...props
}: ColumnProps):React.ReactElement<ColumnProps> => {
  const style: React.CSSProperties = { ...inheritedStyle };
  let className = attributes(namespace(), inheritedClassName, renderMobile, props);

  if (span) className += ` span-${span}`;
  if (mobileSpan) className += ` mobile-span-${mobileSpan}`;
  if (expand) className += ' expand';
  if (width || width === 0) {
    style.width = `${width}px`;
    className += ' width';
  }
  if (height || height === 0) {
    style.height = `${height}px`;
    className += ' height';
  }
  if (bottom) className += ' bottom';
  if (!gutter) className += ' no-gutter';
  if (overflowHidden) className += ' overflow-hidden';

  if (component && React.isValidElement(component)) {
    const element: React.ReactElement<any> = component;
    return React.cloneElement(element, {
      ...element.props,
      className: className + (element.props.className ? ` ${element.props.className}` : ''),
      style: { ...style, ...element.props.style }
    });
  }

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

Column.defaultProps = {
  gutter: true
};

export default Column;
