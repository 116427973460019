import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/icon-row/index.scss';

export const namespace = (): string => 'elements--icon-row';

export interface IconRowProps {
  children?: React.ReactNode;
  className?: string;
  icon?: any;
  truncate?: boolean;
}

const IconRow: React.SFC<IconRowProps> = (props: IconRowProps): React.ReactElement<IconRowProps> => {
  const { truncate } = props;

  let className = mergeClassNames(namespace(), props);
  let textClassName = `${namespace()}--text`;
  if (truncate) {
    className += ' truncate';
    textClassName += ' truncate';
  }

  return (
    <div className={className}>
      <div className={`${namespace()}--icon`}>
        <props.icon/>
      </div>
      <div className={textClassName}>
        {props.children}
      </div>
    </div>
  );
};

export default IconRow;
