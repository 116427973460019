import * as React from 'react';
import {
  Button,
  Column,
  Row,
  SideModal,
  SideModalContent,
  SideModalHeader,
  Loader,
  ActionButton,
  Ellipsis,
} from '../../../../../Elements';
import '../../../../../../styles/pages/resources/home/preview.scss';
import {bindActionCreators} from "redux";
import {ApplicationState} from "../../../../../../store";
import { getResourcesRootUrl, getEditResourceUrl, constructCMSSiteUrlParams, constructCMSSiteResourceUrlParams } from '../../../../../../constants/urls';
import { navPush, getResourceUrl, copyStringToClipboard } from '../../../../../../utils';
import ResourceCard, { ResourceCardType } from '../../../../../../components/Elements/Resource/Card';
import { CMSResource, CMSResourceCategory } from '../../../../../../models/api/adminCMSCacheOne';
import { actionCreators as adminCMSCacheOneActionCreator} from "../../../../../../store/AdminCMSSite/CacheOne/actions";
import { actionCreators as appActionCreator} from "../../../../../../store/App/actions";
import { actionCreators as adminCMSCacheTwoResourcetActionCreator, GetResource} from "../../../../../../store/AdminCMSSite/CacheTwoResources/actions";
import { actionCreators as resourceFormActionCreator} from "../../../../../../store/AdminCMSSite/Resources/Resource/Form/actions";
import { withRouter, RouteComponentProps, WithRouterProps } from 'react-router';
import { EditIcon } from '../../../../../../components/Icons';
import { NotFound } from '../../../../../../components/Pages';
import { INVALID_RESOURCE } from '../../../../../../constants/messages/adminCMS';
import { checkResourcePermission, getResourceIdFromPath, IAdminCMSResourceRouterParams } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { LINK_COPIED } from '../../../../../../constants/messages/generic';
import { CardCategory } from '../../../../../../components/Elements/Card';
import { isPathnameMatchingRoute } from '../../../../../../utils/urlHelper';
import { ADMIN_CMS_RESOURCES_EDIT_PATH } from '../../../../../../routes';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { shouldReconfigRouter } from '../../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import {isAdminCMSCacheOnePopulated, isAdminCMSCacheTwoResourcePopulated} from '../../../../../../utils/cachePopulatedCheckers/adminCMS';

export const namespace = (): string => 'pages--resource--preview';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminCMSResourceRouterParams, {}>;
class ResourcePreview extends React.Component<{},{}> {
  props: ConnectedProps;
  private nextLocation;

  configRouter() {
    const {routes, router} = this.props;
    const route = routes[routes.length - 1];
    router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);
    this.configRouter();
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) {
      this.configRouter();
    }
  }

  componentWillUnmount() {
    const {actions, adminCMSCacheTwoResources} = this.props;

    // if we are not navigating to EditResource page, clear cache
    if (
      this.nextLocation &&
      !isPathnameMatchingRoute(this.nextLocation.pathname, ADMIN_CMS_RESOURCES_EDIT_PATH) &&
      isAdminCMSCacheTwoResourcePopulated(adminCMSCacheTwoResources)
    ) {
      actions.clearAdminCMSCacheTwoResource();
    }
    this.resetRouteLeaveHook();
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  onClickModal = (e) => {
    e && e.stopPropagation();
  };
  
  onBack = () => {
    if (!this.props.adminCMSCacheOne.CMSSite) return;
    this.props.actions.clearAdminCMSSiteCacheBelowOne();
    const backUrl = getResourcesRootUrl(constructCMSSiteUrlParams(this.props, this.props.adminCMSCacheOne));
    navPush(this.props.router, backUrl);
  };
  onEditClick = (resource: CMSResource) => {
    const {cacheZero, adminCMSCacheOne, adminCMSCacheTwoResources} = this.props;
    if (!this.props.adminCMSCacheOne.CMSSite || !adminCMSCacheTwoResources.CMSResource) return;
    checkResourcePermission(
      () => {
        const editResourceUrl = getEditResourceUrl(constructCMSSiteResourceUrlParams(this.props, this.props.adminCMSCacheOne, resource));
        navPush(this.props.router, editResourceUrl);
      },
      cacheZero,
      adminCMSCacheOne,
      adminCMSCacheTwoResources.CMSResource.SiteID,
      true,
    );
  };

  onDownloadClick = (resource: CMSResource) => {
    if (!this.props.adminCMSCacheOne.CMSSite) return;
    const url = getResourceUrl(this.props.adminCMSCacheOne.CMSSite.SiteDomain as string, resource);
    window.open(url);
  };
  onCopyClick = (resource: CMSResource) => {
    if (!this.props.adminCMSCacheOne || !this.props.adminCMSCacheOne.CMSSite) return;
    const url = getResourceUrl(this.props.adminCMSCacheOne.CMSSite.SiteDomain, resource);
    copyStringToClipboard(url);
    this.props.actions.showSnackbarItem(LINK_COPIED);
  };

  renderNotFound = (message?: string) => {
    return (
      <NotFound showCMSSiteHeader inModal message={message || INVALID_RESOURCE}/>
    );
  };

  renderContent = (resourceCategory: CMSResourceCategory, resource: CMSResource, CMSResource?: CMSResource) => {
    return (<div className={`${namespace()}--content-wrapper`}>
      <Row marginBottom={0} mobileMarginTop={16} marginTop={32}>
        <Column span={12} mobileSpan={12}>
          <div className={`${namespace()}--name`}>{CMSResource ? CMSResource.Name : resource.Name}</div>
        </Column>
      </Row>
      <Row marginBottom={0} marginTop={8}>
        <Column span={12} mobileSpan={12}>
          <div className={`${namespace()}--category`}>{resourceCategory.Name}</div>
        </Column>
      </Row>
      {resource.Description && <Row marginBottom={0} mobileMarginTop={16} marginTop={24}>
        <Column span={12} mobileSpan={12} style={{display: "flex", flexDirection: "column"}}>
          <Ellipsis
            fontSize={14}
            configs={{
              lines: 5,
              text: (CMSResource ? CMSResource.Description : resource.Description) || ""
            }}
          />
        </Column>
      </Row>}
      <Row marginTop={32} mobileMarginTop={24}>
        <Column span={12} mobileSpan={12}>
          <ResourceCard
            type={ResourceCardType.PREVIEW}
            cardCategory={CardCategory.LIST_MOBILE}
            resource={CMSResource ? CMSResource : resource}
            onDownload={this.onDownloadClick}
            onCopyLink={this.onCopyClick} />
        </Column>
      </Row>
    </div>);
  };

  render() {
    const {adminCMSCacheOne, apiLoadingMap, params, adminCMSCacheTwoResources} = this.props;
    const loading = (
      !isAdminCMSCacheOnePopulated() ||
      // from list -> preview
      !isAdminCMSCacheTwoResourcePopulated(adminCMSCacheTwoResources) ||
      // refresh
      apiLoadingMap[GetResource.requestType]
    );
    // data from cache two
    const {CMSResource} = adminCMSCacheTwoResources;

    // data from cache one
    const resource = (
      adminCMSCacheOne.CMSResources &&
      adminCMSCacheOne.CMSResources.find(
        (resource) => resource.ID === getResourceIdFromPath(params)
      )
    );
    if (!resource) return null;
    const resourceCategory = adminCMSCacheOne.CMSResourceCategories && adminCMSCacheOne.CMSResourceCategories.find((category) => category.ID === (CMSResource ? CMSResource.CategoryID : resource.CategoryID));

    const resourceInactive = CMSResource ? CMSResource.Inactive : resource.Inactive;

    // could render when we have data in cache one
    return resource && resourceCategory ? (
        <SideModal
          className={`${namespace()} ${resourceInactive ? 'inactive' : ''}`}
          onClick={this.onClickModal}
          stickyHeader
          header={<SideModalHeader
                    className={`${namespace()}--header`}
                    controls={resourceInactive ? null : <Button textColor='black'
                                      id={generateDOMId("resource-edit-btn")}
                                      disabled={loading}
                                      onClick={() => this.onEditClick(resource)}
                                      color='white'>EDIT</Button>}
                    mobileControls={<ActionButton disabled={loading} onClick={() => this.onEditClick(resource)} icon={EditIcon} />}
                    title="Resource Preview"
                    onlyShowSubTitleInMobile
                    subtitle={adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.SiteDomain : ''}
                    onBack={this.onBack}/>}
        >
          {resourceInactive ? this.renderNotFound() : <SideModalContent lockBodyScroll>
            {this.renderContent(resourceCategory, resource, CMSResource) }
          </SideModalContent>}
          {loading && <Loader className={`${namespace()}--loader`} center />}
        </SideModal>
    ) : null;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheZero: state.cacheZero,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    adminCMSCacheTwoResources: state.adminCMSSite.cacheTwoResources,
  };
};

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...adminCMSCacheTwoResourcetActionCreator,
  ...adminCMSCacheOneActionCreator,
  ...appActionCreator,
  ...resourceFormActionCreator,
}, dispatch)});

const ConnectedResourcePreview = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(ResourcePreview);

export default withRouter<{}>(ConnectedResourcePreview);
