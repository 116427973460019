import * as React from 'react';
import '../../../styles/elements/link/index.scss';
import { Link as RouterLink } from 'react-router';
import attributes, {AlignmentProps, MarginProps, LayoutProps} from '../../../styles';

export const namespace = 'elements--link';

export interface LinkProps extends AlignmentProps, MarginProps, LayoutProps {
  children?: React.ReactNode;
  icon?: React.ReactElement<any> | React.ReactType;
  externalLink?: string;
  onClick?: () => any;
  className?: string;
  black?: boolean;
  uppercase?: boolean;
  size?: number;
  medium?: boolean;
}

const Link: React.SFC<LinkProps> = (
  { black, uppercase, size, medium, children, icon, marginRight, externalLink, onClick, ...props }
: LinkProps): React.ReactElement<LinkProps> => {
  let className = attributes(namespace, props);
  if (props.className) className += ' ' + props.className;
  if (black) className += ' black';
  if (uppercase) className += ' uppercase';
  if (medium) className += ' medium';
  if (size) className += ` size-${size}`;

  let iconComponent: any;
  if (icon && !React.isValidElement(icon)) {
    const Icon: React.ReactType = icon as any;
    iconComponent = <Icon className={`${namespace}--wrapper--icon`}/>;
  } else if (icon) {
    iconComponent = icon as React.ReactElement<any>;
    iconComponent = React.cloneElement(iconComponent, {
      ...iconComponent.props,
      className: iconComponent.props.className ? iconComponent.props.className + ` ${namespace}--wrapper--icon` : `${namespace}--wrapper--icon`
    });
  }

  const style: React.CSSProperties = {};
  if (marginRight) style.marginRight = `${marginRight}px`;

  if (externalLink) {
    if (iconComponent) {
      return (
        <a className={className} style={style} href={externalLink} target="_blank">
          <div className={`${namespace}--wrapper`}>
            {iconComponent}
            <span className={`${namespace}--wrapper--text`}>{children}</span>
          </div>
        </a>
      );
    } else {
      return (
        <a className={className} href={externalLink} target="_blank">
          {children}
        </a>
      );
    }
  }
  return <RouterLink className={className} style={iconComponent ? style : undefined} to="" onClick={onClick}>
    {iconComponent && <div className={`${namespace}--wrapper`}>
      {iconComponent}
      <span className={`${namespace}--wrapper--text`}>{children}</span>
    </div>}
    {!iconComponent && children}
  </RouterLink>;
};

export default Link;
