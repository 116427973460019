import {
  ApiSubmitActions, GetRegisterFormActions,
  ApiValidateActions,
  ApiEmailValidateActions,
  InitRegisterFormOtherFields,
} from './actions';
import {getRegistrationForm, registerNewAccount, URLS} from '../../constants/urls';
import { makeBackendValidateCancelEpic, makeBackendValidateEpic, makeRequestEpic } from '../Validation/epicCreator';
import { FormDefinition } from './validation';
import { Observable } from 'rxjs';
import { Action } from 'redux';
import { ModalTypes, pushModalObservables } from '../../utils/modalHelper';

export const emailBackendValidate = makeBackendValidateEpic(FormDefinition.Email.key, ApiEmailValidateActions);
export const emailBackendValidateCancel = makeBackendValidateCancelEpic(FormDefinition.Email.key, ApiEmailValidateActions, FormDefinition.Email);
export const usernameBackendValidate = makeBackendValidateEpic(FormDefinition.Username.key, ApiValidateActions);
export const usernameBackendValidateCancel = makeBackendValidateCancelEpic(FormDefinition.Username.key, ApiValidateActions, FormDefinition.Username);

export const getRegisterEpic = makeRequestEpic(
  GetRegisterFormActions,
  getRegistrationForm,
  undefined,
  true,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  () => Observable.of(new InitRegisterFormOtherFields()),
);

export const submitForm = makeRequestEpic(
  ApiSubmitActions,
  registerNewAccount,
  undefined,
  true,
  undefined,
  URLS.LOGIN,
  undefined, undefined,
  undefined,
  undefined,
  (): Observable<Action> => {
    return Observable.concat(
      // NOTE: No need to set saveAfter: true here because this callback will be executed
      // before the `SaveState` in epicCreator success routine
      ...pushModalObservables({
        modal: ModalTypes.ACTIVATE_ACCOUNT,
        saveBefore: false,
        saveAfter: false,
        transformToObservable: true,
      })
    );
  }
);
