
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, ModalActions, Button, Loader
} from '../../../../../Elements';
import { actionCreators, GLAccountFormModalNamespace as namespace } from '../../../../../../store/AdminSettings/GLAccounts/GLAccount/Form/actions';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { actionCreators as cacheTwoGLAccountActionCreators } from '../../../../../../store/AdminSettings/CacheTwoGLAccount/actions';
import { ApplicationState } from '../../../../../../store';
import GLAccount from '../../GLAccount/Form';
import '../../../../../../styles/pages/admin-settings/gl-accounts/modals/gl-account-form/index.scss';
import { INVALID_GL_ACCOUNT } from '../../../../../../constants/messages/adminSettings';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import AdminSettingsCacheManager from '../../../../../../utils/cacheManagers/adminSettingsCacheManager';
import { connect } from 'react-redux';
import { reduxStoreService } from '../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isAdminSettingsCacheTwoGLAccountPopulated} from '../../../../../../utils/cachePopulatedCheckers/adminSettings';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> &RouteComponentProps<{}, {glAccountId: string}>
>;

@(withRouter as any)
class GLAccountFormModal extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;

  componentDidMount() {
    super.loadAndSetData(
      this.props,
      () => {
        AdminSettingsCacheManager.getInstance().loadCacheTwoGLAccount({
          props: this.props,
          isStateNavigated: true,
          isEdit: !!this.props.glAccountId,
          glAccountIdFromModalProps: this.props.glAccountId,
        });
      }
    );
  }

  componentWillUnmount() {
    this.onClose();
  }

  onAccountInactive() {
    const {actions} = this.props;

    actions.showTopFloatingAlert(INVALID_GL_ACCOUNT, true, 'orange');
  }
  onSave = () => {
    const {actions, routes} = this.props;

    actions.apiSubmitForm(routes);
  };

  onClose = () => {
    const {actions} = this.props;

    if (isAdminSettingsCacheTwoGLAccountPopulated(reduxStoreService().getState().adminSettings.cacheTwoGLAccount)) {
      actions.clearAdminSettingsCacheTwoGLAccount();
    }
  };

  public render() {
    const { adminSettingsCacheTwoGLAccount, inert, glAccountId, apiSaving, actions, glAccountForm} = this.props;

    const title = glAccountId ? 'Edit GL Account' : 'New GL Account';
    const inactive = !!glAccountId && (adminSettingsCacheTwoGLAccount.GLAccount && adminSettingsCacheTwoGLAccount.GLAccount.Inactive);
    const loading = !!glAccountId && !isAdminSettingsCacheTwoGLAccountPopulated(adminSettingsCacheTwoGLAccount, glAccountForm.ActiveForm);

    return (
      <Modal
        inert={inert}
        inactive={inactive}
        onInactive={() => this.onAccountInactive()}
        big mobileFullScreen
        className={namespace()}
        onClose={this.onClose}
      >
        <ModalHeader className={`${namespace()}--header`}>{title}</ModalHeader>
        <ModalContent hasPadding>
          {!loading &&
            <GLAccount
              inert={inert}
              hideFormAction
              ActiveForm={glAccountForm.ActiveForm}
              ValidationRules={glAccountForm.ValidationRules}
              reduxActions={actions}
              SubmitErrorMessage={glAccountForm.SubmitErrorMessage}
              disabled={apiSaving > 0}
              action={!!glAccountId ? 'edit' : 'add'}
              onSave={this.onSave}
            />}
          {loading && <Loader className={`${namespace()}--loader`} center />}
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button 
            id={generateDOMId("gl-account-modal-save-btn")}textColor='black' disabled={loading || apiSaving > 0} flat onClick={this.onSave}>{this.props.glAccountId ? 'SAVE' : 'CREATE'}</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const glAccountIdSelector = makeFormModalPropSelector(ModalTypes.GL_ACCOUNT_FORM, 'id');

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    glAccountForm: state.adminSettings.glAccounts.glAccount.form,
    adminSettingsCacheTwoGLAccount: state.adminSettings.cacheTwoGLAccount,
    glAccountId: glAccountIdSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...appActionCreators,
  ...cacheTwoGLAccountActionCreators,
  ...actionCreators,
}, dispatch) });

const ConnectedGLAccountFormModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GLAccountFormModal);

export default ConnectedGLAccountFormModal;