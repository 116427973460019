import * as React from 'react';

import '../../../styles/elements/number-field/index.scss';
import MaterialButton from '@material-ui/core/Button';
import Down from '../../../images/elements/number-field/down.svg';
import Up from '../../../images/elements/number-field/up.svg';
import { Validator } from '../../../utils/validator/models';

export const namespace = (): string => 'elements--number-field';

export interface NumberFieldProps {
  disabled?: boolean;
  id?: string;
  onChange?: (value: number) => void;
  required?: boolean;
  value?: number;
  name?: string;
  validationRules?: Validator;
  // todo: pull these from ValidatonRules
  minimum?: number;
  maximum?: number;
  step?: number | 'any';
  label?: string;
  labelBig?: boolean;
  labelWarning?: string;
  noNegative?: boolean;
  helperText?: string;
  noWidthLimit?: boolean;
  hideError?: boolean;
}

type NumberFieldState = {
  value?: number;
  paddingBottom?: number;
};

class NumberField extends React.Component<NumberFieldProps, NumberFieldState> {
  public props: NumberFieldProps;
  private helperTextRef;
  private errorTextRef;

  public static defaultProps = {
    step: 1
  };

  public state: NumberFieldState = {
    value: 0
  };

  constructor(props) {
    super(props);
    if (props.value !== undefined) this.state.value = props.value;
  }

  private timeout;
  private interval;

  public componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  public componentDidUpdate() {
    this.handleResize();
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getError = () => {
    const {validationRules} = this.props;
    if (validationRules) {
      if (validationRules.errors && validationRules.errors.length > 0) return validationRules.errors[0];
    }

    return "";
  };

  shouldDisplayHelperText = () => {
    const {helperText} = this.props;
    const error = this.getError();

    return !error && helperText;
  };

  shouldDisplayError = () => {
    const {hideError} = this.props;
    const error = this.getError();

    return !hideError && error;
  };

  handleResize = () => {
    const {validationRules, hideError, helperText} = this.props;
    if (!this.helperTextRef && !this.errorTextRef) {
      this.state.paddingBottom !== 0 && this.setState({
        paddingBottom: 0,
      });
      return;
    }
    
    const error = this.getError();

    let updatedPaddingBottom;
    if (this.shouldDisplayError()) {
      updatedPaddingBottom = this.errorTextRef.clientHeight + 16;
    } else {
      updatedPaddingBottom = 0;
    }

    // helper text has `position: absolute`, so we need to manually the control's padding height to make enough space for it
    if (this.shouldDisplayHelperText() && this.helperTextRef.clientHeight > 24) {
      updatedPaddingBottom = this.helperTextRef.clientHeight + 4;
    } else if (this.shouldDisplayHelperText()) {
      updatedPaddingBottom = 24;
    }

    if (this.state.paddingBottom !== updatedPaddingBottom) {
      this.setState({
        paddingBottom: updatedPaddingBottom,
      });
    }
  };

  public handleMouseDownOnDown = () => {
    this.clearInterval();
    this.decrement();
    this.timeout = setTimeout(() => {
      this.interval = setInterval(this.decrement, 75);
    }, 350);
  };

  public handleMouseDownOnUp = () => {
    this.clearInterval();
    this.increment();
    this.timeout = setTimeout(() => {
      this.interval = setInterval(this.increment, 75);
    }, 350);
  };

  public clearInterval = () => {
    clearTimeout(this.timeout);
    clearInterval(this.interval);
  };

  public decrement = () => {
    let value = this.props.onChange ? this.props.value : this.state.value;
    if (!value) value = 0;
    const nextValue = parseInt(`${value}`) - 1;
    if (this.props.minimum === undefined || this.props.minimum === null || nextValue >= this.props.minimum) {
      if (this.props.onChange) {
        this.props.onChange(nextValue);
      } else {
        this.setState({ value: nextValue });
      }
    }
  };

  public increment = () => {
    let value = this.props.onChange ? this.props.value : this.state.value;
    if (!value) value = 0;
    const nextValue = parseInt(`${value}`) + 1;
    if (this.props.maximum === undefined || this.props.maximum === null || nextValue <= this.props.maximum) {
      if (this.props.onChange) {
        this.props.onChange(nextValue);
      } else {
        this.setState({ value: nextValue });
      }
    }
  };

  public handleChange = (event: React.ChangeEvent<any>) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    } else {
      this.setState({ value: event.target.value });
    }
  };

  onKeyPress = (e) => {
    const keyCode = e.which ? e.which : e.keyCode;
    // always block e,E and .   sometimes block -
    if (keyCode === 69 || keyCode === 101 || keyCode === 190) e.preventDefault();
    else if (this.props.noNegative && keyCode === 45) e.preventDefault();
  };

  onPaste = (e) => {
    try {
      const string = e.clipboardData.getData('Text');
      if (string.includes('e') || string.includes('E') || string.includes('.')) {
        e.preventDefault();
      } else if (string.includes('-') && this.props.noNegative) {
        e.preventDefault();
      }
    } catch(e) {
      // console.log('Could not get pasted data in text field, not alphanumeric');
    }
  };

  public render() {
    const { minimum, step, onChange, value: propsValue, label, labelBig, labelWarning, validationRules, hideError, noWidthLimit, helperText, disabled } = this.props;
    const { value: stateValue, paddingBottom } = this.state;

    const value = onChange ? propsValue : stateValue;
    const error = this.getError();

    return (
      <div className={`${namespace()} ${!hideError && !!error ? 'error-padding' : ''} ${noWidthLimit ? 'no-width-limit' : ''}`} style={paddingBottom ? {paddingBottom: `${paddingBottom}px`} : {}}>
        {label ? (
          <div className={`${namespace()}--label` + (labelBig ? ' big' : '')}>
            <span className={`${namespace()}--label--text` + (labelBig ? ' big' : '')}>{label}</span>
            {labelWarning ? <span className={`${namespace()}--label--warning`}>{labelWarning}</span> : null}
          </div>
        ) : null}
        <div className={`${namespace()}--wrapper`}>
          <MaterialButton
            disabled={value === minimum || disabled}
            variant='text'
            className={`${namespace()}--wrapper--spin-down mdl-button mdl-js-button mdl-button--fab` + (value === minimum ? ' blocked' : '')}
            onMouseDown={this.decrement}
          >
            <Down/>
          </MaterialButton>
          <label className={`${namespace()}--wrapper--container`}>
            <input
              className={`${namespace()}--wrapper--container--input`}
              min={minimum !== undefined ? minimum : 0}
              step={step}
              pattern="[0-9]"
              disabled={disabled}
              value={value}
              onChange={this.handleChange}
              onKeyPress={this.onKeyPress}
              onPaste={this.onPaste}
            />
            <div className={`${namespace()}--wrapper--container--border`}/>
          </label>
          <MaterialButton
            variant='text'
            disabled={disabled}
            className={`${namespace()}--wrapper--spin-up mdl-button mdl-js-button mdl-button--fab`}
            onMouseDown={this.increment}
          >
            <Up/>
          </MaterialButton>
        </div>
        {this.shouldDisplayError() && <div ref={(ref) => this.errorTextRef = ref} className={`${namespace()}--error`}>{error}</div>}
        {this.shouldDisplayHelperText() && <div ref={(ref) => this.helperTextRef = ref} className={`${namespace()}--helper-text`}>{helperText}</div>}
      </div>
    );
  }
}

export default NumberField;
