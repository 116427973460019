import {apiValidationReducerCreator} from '../Validation/reducerCreator';
import {IValidator, FormDefinition} from './validation';
import {LogoutSuccess} from '../Session/actions';
import {LoginActions, SUFFIX} from './actions';
import {APIFailure, WithRootState} from "../Validation/actionCreator";
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../utils/StrongActions';

export interface ActiveForm {
  Username?: string;
  Password?: string;
}

export interface LoginState {
  // These are the required fields for validation
  ActiveForm: ActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
}

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const getInitialState: () => LoginState = () => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition}
});

const Login: Reducer<LoginState> = (oldState = getInitialState(), action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, action);
  if (action.type === LoginActions.failureType) {
    const castedAction = <WithRootState<APIFailure>> action;
    // #648 Only show inline alert for 401 and 400
    if (castedAction.response.status === 401 || castedAction.response.status === 400) {
      if (!castedAction.response.xhr.response.error) return state;
      return {...state, SubmitErrorMessage: castedAction.response.xhr.response.error.Message};
    }
    return state;
  } else if (isActionType(action, LogoutSuccess)) {
    return {
      ...state,
      ActiveForm: {},
      ValidationRules: {...FormDefinition}
    };
  }

  return state || {};
};

export default Login;
