import { createSelector } from 'reselect';
import { withScope } from '@sentry/browser';
import * as Actions from './actions';
import {SilentCancelAllAction} from './actions';
import {
  getLoginFormFailureAction,
  LogoutFailure,
  LogoutRequest,
  LogoutSuccess
} from '../Session/actions';
import {LoginActions} from '../Login/actions';
import {API_CAN, API_FAI, API_PRE, API_REQ, API_SUC} from '../../constants/request';
import {
  LOAD,
  LOAD_FINISH,
  SAVE,
  SAVE_FINISH,
  validateCancelAction,
  validateFailureAction,
  validateRequestAction,
  validateSuccessAction,
  SEQUENTIAL_SUBMIT_REQUEST,
  DELAY_QUEUE_ACTION,
  WithRootState,
} from '../Validation/actionCreator';
import {isAdminEventsPage, QP_ACCOUNT, ROOT, URLS} from '../../constants/urls';
import NProgress from 'nprogress';
import * as GMessage from '../../constants/messages/generic';
import {getMessageFromErrorResponse} from "../../utils/stringHelper";
import {CheckoutSubmitActions} from "../Checkout/actions";
import {ReservationChange} from "../Facilities/Trip/Reservation/Reservation/actions";
import { GetSiteCache } from '../AdminCMSSite/CacheOne/actions';
import { GetFacilityLocationCache } from '../AdminFacilityLocation/CacheOne/actions';
import { isAdminCMSSitePage, isAdminFacilityLocationPage } from '../../constants/urls';
import { ApplicationState } from '..';
import { GetBlackoutDate } from '../AdminFacilityLocation/CacheTwoBlackoutDates/actions';
import { scrollModalToTop } from '../../utils/helpers/adminCMSPageHelper';
import { IModal, ModalProps, ModalTypes, isModalOpened } from '../../utils/modalHelper';
import { captureTentarooError } from '../../utils/dataHelper';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../utils/StrongActions';
import { RestoreState } from '../Rollback/actions';
import { getDomain } from '../../utils/urlHelper';

NProgress.configure({showSpinner: false});

export interface SnackbarItem {
  message: string;
  action?: () => any;
  actionLabel?: string;
}

export const defaultFilterSelector = state => state.app.searchFilter;

export const openedModalsSelector = (state: ApplicationState) => state.app.openedModals;

// return a specific modal prop field, or the entire prop object
// TODO: Fix typing here, it would be better to establish relationship between propName and modalType, so
// that typing tells us certain props should present for that modal. Remove `as any` cast
export const makeFormModalPropSelector = <T extends keyof ModalProps>(modalType: ModalTypes, propName: T) => {
  return createSelector(
    [openedModalsSelector],
    (openedModals: IModal[]): ModalProps[T] | undefined => {
      const modal = openedModals ? openedModals.find((m) => m.type === modalType) : undefined;

      if (modal) {
        return modal.props ? modal.props[propName] : undefined;
      }

      return undefined;
    }
  );
};

export const makeCurrentModalSelector = () => {
  return createSelector(
    [openedModalsSelector],
    (openedModals: IModal[]) => {
      if (openedModals) {
        return openedModals[openedModals.length - 1];
      }
      
      return null;
    }
  );
};

export interface AppGutter {
  height: number;
  mobile?: boolean;
}

// @todo: is apiValidating
export interface AppState {
  apiLoading: number;
  apiLoadingCount: number;
  apiLoadingMap: any;
  apiSaving: number;
  apiSavingCount: number;
  apiSavingMap: any;
  queuedActionsMap: any;
  loadingAll?: boolean;
  isDrawerOpen?: boolean;
  topFloatingAlert?: string;
  topFloatingAlertColor?: 'green' | 'orange';
  alertInModal?: boolean; // @todo: hm I wanna get rid of this...
  gutter?: AppGutter;
  showAddedHeaderAction?: boolean;
  showAddedSideBar?: boolean;
  openedModals: IModal[];
  // TODO: combine this with `openedModal`
  showModal?: boolean;
  getEmptyCartUrl?: () => string;
  getEmptyCartBody?: (params: any) => void;
  emptyCartPostNavigate?: string;
  viewReceiptUrl?: string;
  reportUrl?: string;

  snackbar?: SnackbarItem;
  isNotFound?: boolean; // This is used when you're on a correct route, but still need to show the "not found" page
  isNotFoundMessage?: string;
  afterLoginPath?: string;
  afterLoginAccount?: string;
  isLoggingOut?: boolean;
  showNProgress?: boolean;
  searchFilter?: string;
  showAdminPageHeader?: boolean;
  isModalSaving?: boolean;
  isRouterChangeRollbackFinished?: boolean;
  previousLocation?: any;
  // Controls the visibility of search bar in top blue bar
  globalSearchOpen?: boolean;
}

const removeFromLoadMap = (newState, actionType) => {
  let requestName;
  if (actionType.includes(LOAD_FINISH)) {
    requestName = actionType.replace(LOAD_FINISH, LOAD);
    requestName = requestName.replace(API_SUC, API_REQ);
    requestName = requestName.replace(API_FAI, API_REQ);
    requestName = requestName.replace(API_CAN, API_REQ);
  } else if (actionType.startsWith(validateSuccessAction)) {
    requestName = actionType.replace(validateSuccessAction, validateRequestAction);
  } else if (actionType.startsWith(validateFailureAction)) {
    requestName = actionType.replace(validateFailureAction, validateRequestAction);
  } else if (actionType.startsWith(validateCancelAction)) {
    requestName = actionType.replace(validateCancelAction, validateRequestAction);
  }
  if (requestName && newState.apiLoadingMap[requestName]) {
    const newLoadingMap = {...newState.apiLoadingMap};
    newLoadingMap[requestName] = false;
    newState.apiLoadingMap = newLoadingMap;
    newState.apiLoading = newState.apiLoading - 1;
  }
};
const removeFromSaveMap = (newState, actionType) => {
  let requestName;
  if (actionType.includes(SAVE_FINISH)) {
    requestName = actionType.replace(SAVE_FINISH, SAVE);
    requestName = requestName.replace(API_SUC, API_REQ);
    requestName = requestName.replace(API_FAI, API_REQ);
    requestName = requestName.replace(API_CAN, API_REQ);
  }
  if (requestName && newState.apiSavingMap[requestName]) {
    const newSavingMap = {...newState.apiSavingMap};
    if (actionType.includes(SEQUENTIAL_SUBMIT_REQUEST)) {
      // if this is a sequential request, we dont simply toggle the field with true/false, but decrementing
      // the count
      newSavingMap[requestName] -= 1;
      if (newSavingMap[requestName] === 0) {
        newState.apiSaving = newState.apiSaving - 1;
      }
    } else {
      newSavingMap[requestName] = false;
      newState.apiSaving = newState.apiSaving - 1;
    }
    newState.apiSavingMap = newSavingMap;
  }
};
const showNProgress = (newState) => {
  if (newState.apiLoading > 0 && newState.apiSaving === 0 && !newState.showNProgress) {
    newState.showNProgress = true;
    NProgress.start();
  } else if (newState.apiSaving > 0 && newState.showNProgress) {
    newState.showNProgress = false;
    NProgress.done();
  } else if (newState.apiLoading === 0 && newState.showNProgress) {
    newState.showNProgress = false;
    NProgress.done();
  }
};

const showModalAlert = (newState: AppState, message) => {
  if (newState.openedModals.length > 0) {
    newState.topFloatingAlert = message;
    newState.topFloatingAlertColor = 'orange';
    newState.alertInModal = true;
  }
};

// WARNING! Careful catching new actions here! some of the if conditions are not strict! (aka use "includes" or "startsWith" on the type)
const App: Reducer<AppState> = (state: AppState, action: WithRootState<AnyAction>) => { // @todo: remove any, why does User/index.ts work but this one doesn't? I fixed user somehow last week (early september 2017)
  console.log(action.type + ': ' + (action.value ? action.value.EventTypeID : ''));

  // Handle `DELAY_QEUUE_ACTION` in parallel, here it is only updaing the `queuedActionsMap`
  // We do this in parallel because we would need to handle each success queued action separately
  // see `ShowTopFloatingAlertInQueue.successType` block below as an example
  if (action.type.includes(DELAY_QUEUE_ACTION)) {
    state = {...state};
    if (!action.type.includes('_SUCCESS_')) {
      // only do this against DelayQueue request actions
      const type = action.type.replace(DELAY_QUEUE_ACTION, '');
      if (state.queuedActionsMap[type]) {
        state.queuedActionsMap[type] += 1;
      } else {
        state.queuedActionsMap[type] = 1;
      }
    } else {
      const type = action.type.replace(DELAY_QUEUE_ACTION + '_SUCCESS_', '');
      state.queuedActionsMap[type] -= 1;
    }
  }

  if (
    isActionType(action, Actions.LoadingAllAction)
  ) {
    return {
      ...state,
      loadingAll: true,
    };
  } else if (isActionType(action, Actions.ClearAllEndUserCacheButOptions)) {
    return {
      ...state,
      previousLocation: undefined,
    };
  } else if (isActionType(action, Actions.StopLoadingAllAction) || isActionType(action, Actions.ClearAllCache)) {
    return { ...state, loadingAll: false};
  } else if (isActionType(action, Actions.SetIsRouterChangeRollbackFinished)) {
    return {...state, isRouterChangeRollbackFinished: action.finished};
  } else if (isActionType(action, Actions.HideDrawer)) {
    return {...state, isDrawerOpen: false};
  } else if (isActionType(action, Actions.ShowDrawer)) {
    return {...state, isDrawerOpen: true};
  } else if (isActionType(action, Actions.ShowTopFloatingAlert)) {
    return {...state, topFloatingAlertColor: action.color ? action.color : 'green', topFloatingAlert: action.message, alertInModal: !!action.inModal};
  } else if (action.type === Actions.ShowTopFloatingAlertInQueue.successType) {
    return {...state, topFloatingAlertColor: action.payload.color ? action.payload.color : 'green', topFloatingAlert: action.payload.message, alertInModal: !!action.payload.inModal};
  } else if (isActionType(action, Actions.HideTopFloatingAlert)) {
    return {...state, topFloatingAlert: undefined, topFloatingAlertColor: undefined};
  } else if (isActionType(action, Actions.AddGutter)) {
    return {...state, gutter: {height: action.height, mobile: action.mobile}};
  } else if (isActionType(action, Actions.UpdateGlobalSearchText)) {
    return {...state, searchFilter: action.str};
  } else if (action.type === '@@router/LOCATION_CHANGE') {
    return {...state, searchFilter: '', globalSearchOpen: false};
  } else if (isActionType(action, Actions.RemoveGutter)) {
    return {...state, gutter: undefined};
  } else if (isActionType(action, Actions.ShowAddedHeaderAction)) {
    return {...state, showAddedHeaderAction: true};
  } else if (isActionType(action, Actions.HideAddedHeaderAction)) {
    return {...state, showAddedHeaderAction: false};
  } else if (isActionType(action, Actions.ShowAddedSideBar)) {
    return {...state, showAddedSideBar: true};
  } else if (isActionType(action, Actions.HideAddedSideBar)) {
    return {...state, showAddedSideBar: false};
  } else if (isActionType(action, Actions.ToggleAddedSideBar)) {
    return {...state, showAddedSideBar: !state.showAddedSideBar};
  } else if (isActionType(action, Actions.SetEmptyCart)) {
    if (state.openedModals.length > 0) return state;

    return {
      ...state,
      getEmptyCartBody: action.getEmptyCartBody,
      getEmptyCartUrl: action.getEmptyCartUrl,
      emptyCartPostNavigate: action.postNavigate
    };
  } else if (isActionType(action, Actions.PushModal)) {
    const modalExisted = state.openedModals ? state.openedModals.find((m) => m.type === action.modal) : null;

    // if trying to open the same modal more than once, prevent it
    if (modalExisted) {
      return {...state};
    }
    const newModal: IModal = {
      type: action.modal,
      saveBefore: action.saveBefore,
      saveAfter: action.saveAfter,
      props: action.props,
      namespace: action.modalNamespace,
    };
    return {
      ...state,
      openedModals: state.openedModals ? [...state.openedModals, newModal] : [newModal],
    };
  } else if (isActionType(action, Actions.PopModal)) {
    const openedModals = state.openedModals ? [...state.openedModals] : [];
    if (openedModals.length > 0) {
      if (openedModals[openedModals.length - 1].type === action.modal) {
        openedModals.pop();
      }
    }
    return {
      ...state,
      openedModals,
    };
  } else if (isActionType(action, Actions.CloseAllModals)) {
    return {
      ...state,
      openedModals: [],
    };
  }
  // Progress actions have no redux state since nprogress library handles it.
  else if (isActionType(action, Actions.StartProgress)) {
    NProgress.start();
    return {...state, showNProgress: true};
  } else if (isActionType(action, Actions.SetProgress)) {
    NProgress.set(action.percent);
  } else if (isActionType(action, Actions.IncrementProgress)) {
    NProgress.inc(action.percent);
  } else if (isActionType(action, Actions.FinishProgress)) {
    NProgress.done();
  } else if (isActionType(action, Actions.ShowModalAlert)) {
    const newState = {...state};
    showModalAlert(newState, action.message);
    return newState;
  } else if (isActionType(action, Actions.ShowSnackbarItem)) {
    return {...state, snackbar: {message: action.message}};
  } else if (isActionType(action, Actions.SetAfterLoginPath)) {
    if (action.path === undefined || (!action.path.startsWith(URLS.NEW_ACCOUNT) && !action.path.startsWith(URLS.LOGIN) && action.path !== ROOT && action.path !== `${ROOT}/`)) {
      return {
        ...state,
        afterLoginPath: action.path,
        afterLoginAccount: action.account,
      };
    }
    return state;
  } else if (isActionType(action, Actions.SetPreviousLocation)) {
    return {
      ...state,
      previousLocation: {...action.location},
    };
  } else if (isActionType(action, Actions.ToggleGlobalSearch)) {
    return {
      ...state,
      globalSearchOpen: action.show,
    };
  } else if (action.type === '@@router/LOCATION_CHANGE' && action.payload && action.payload.state) {
    if (action.payload.state.snackbarMessage) {
      return {...state, snackbar: {message: action.payload.state.snackbarMessage}};
    } else if (action.payload.state.topAlertMessage) {
      return {...state, topFloatingAlert: action.payload.state.topAlertMessage, topFloatingAlertColor: 'orange'};
    }
    return state;
  } else if (action.type.startsWith(API_FAI)) {
    // @todo: action and actionlabel
    // @todo: only error 500, what errors don't we show? don't show 200, 409 etc
    // @todo: 400 could use the top alert, show whatever message came back with it

    let message = action.message;
    if (!message) {
      message = getMessageFromErrorResponse(action.response);
    }
    if (!message) {
      if (action.response) {
        if (action.response.parseError) {
          message = GMessage.PARSE_PAYLOAD_ERROR;
        } else if (action.response.message === 'ajax error 500' || !action.response.message) {
          message = 'An unknown error has occurred';
        } else {
          message = action.response.message;
        }
      } else if (!navigator.onLine) {
        message = GMessage.NO_INTERNET;
      } else {
        message = GMessage.CONNECTION_ISSUE;
      }
    }
    const newState = {
      ...state
    };
    if (isActionType(action, LogoutFailure)) {
      newState.isLoggingOut = false;
    }
    if (action.type.startsWith(validateFailureAction) || action.type.includes(LOAD_FINISH)) {
      removeFromLoadMap(newState, action.type);
    }
    if (action.type.includes(SAVE_FINISH)) {
      removeFromSaveMap(newState, action.type);
    }
    if (
      (action.type === getLoginFormFailureAction && action.response.status === 401) ||
      (action.type === LoginActions.failureType && action.response.status === 401)
    ) {
      const path = action.rootState.routing.locationBeforeTransitions.pathname;

      // Configure `afterLoginPath`
      if (!path.startsWith(URLS.NEW_ACCOUNT) && !path.startsWith(URLS.LOGIN) && path !== ROOT && path !== `${ROOT}/`) {
        newState.afterLoginPath = path;
        newState.afterLoginAccount = action.rootState.routing.locationBeforeTransitions.query[QP_ACCOUNT];
      }

      return newState;
    }

    if (action.response.status === 401) {
      // probably don't need this anymore
      const path = action.rootState.routing.locationBeforeTransitions.pathname;
      if (!path.startsWith(URLS.NEW_ACCOUNT) && !path.startsWith(URLS.LOGIN) && path !== ROOT && path !== `${ROOT}/`) {
        newState.afterLoginPath = path;
        newState.afterLoginAccount = action.rootState.routing.locationBeforeTransitions.query[QP_ACCOUNT];
      }
    } else if (action.response.status === 404) {
      const rootState = action.rootState;

      if (action.type !== GetBlackoutDate.failureType || !rootState.adminFacilityLocation.cacheOne || !rootState.adminFacilityLocation.cacheOne.FacilitiesLocation) {
        newState.isNotFound = true;
        newState.isNotFoundMessage = message;
      }
      return newState;
    } else if (action.response.status === 0) {
      if (action.response.name === "AjaxTimeoutError") {
        message = GMessage.CONNECTION_TIMEOUT;
      } else {
        message = GMessage.CONNECTION_ISSUE;
      }
    } else if (action.response.status === 503) {
      message = GMessage.SERVER_UNAVAILABLE;
    } else if (action.response.status === 400 && action.response.xhr.response.error.Detail === 'Payment Error') {
      return newState;
    } else if (action.response.status === 400) {
      if (
        isAdminCMSSitePage(location.pathname) ||
        isAdminFacilityLocationPage(location.pathname) ||
        (isAdminEventsPage(location.pathname) && !isModalOpened(ModalTypes.IMPORT_FROM_EVENT, action.rootState) && !isModalOpened(ModalTypes.ENTER_CLASS_REQUIREMENT_COMPLETED, action.rootState) && !isModalOpened(ModalTypes.EXPORT_INSTRUCTOR_ROSTER, action.rootState)) ||
        isModalOpened(ModalTypes.CHANGE_PASSWORD, action.rootState) || isModalOpened(ModalTypes.RESET_PASSWORD, action.rootState)
      ) {
        // Since TopFloatingAlert is skipped for this cases, we want to scroll to
        // the top of the form so that users can see the 400 message as inline alerts

        if (state.openedModals && state.openedModals.length === 0) {
          // only do this when no modal is opened.
          // For modal cases, we usually supports optimistic close,
          // and so when 400, modal is reopened and scrolled to top by default.
          // Exception: ResourceForm?
          window.scrollTo(0, 0);
        } else {
          // @TODO: maybe we should make `openedModal.namespace` required, even though its only used for resourceForm modal now
          const openedModal = state.openedModals ? state.openedModals[state.openedModals.length - 1] : null;

          if (openedModal && openedModal.namespace) {
            scrollModalToTop(openedModal.namespace);
          }
        }
        return newState;
      } else if (
        action.response.xhr.response.error.Detail !== 'Incorrect Payment'
      ) {
        // Show top floating alert only if we have a modal opened.
        // Excluding CheckoutMessages modal because that modal will be closed, and the form is actually behind the modal
        // This code is mostly for older form modals that don't support inline
        // validation error within the modal
        if (isModalOpened(ModalTypes.CHECKOUT_MESSAGES, action.rootState)) {
          return newState;
        }

        showModalAlert(newState, message);
        return newState;
      }
    }

    if (action.type === CheckoutSubmitActions.failureType && action.response.status === 423) {
      // don't show snackbar at all when
      // 1. It is a 423 error returned from `CheckoutSubmitActions`
    } else {
      newState.snackbar = {message};
    }

    return newState;
  } else if (isActionType(action, Actions.HideSnackbarItem)) {
    return {...state, snackbar: undefined};
  } else if (
    isActionType(action, SilentCancelAllAction) ||
    (isActionType(action, RestoreState) && action.cancelRequests)
  ) {
    if (state.showNProgress) NProgress.done();
    // We can return the state without processing anything if the SilentCancelAll action
    // doesn't skip a follow up RestoreState action, because RestoreState action should clear the loading/saving
    // maps and `showNProgress` flag for us via a rollback
    if (isActionType(action, RestoreState) || (action.isDuringNavigation && !action.skipRestoreState)) return state;
    let hasSequentialRequestActions;

    // clean up any sequential request stored in apiSavingMap by setting them to 0
    // and also reset `apiSaving` if any sequential request is in progress
    // we should create a new object instance here, otherwise the same obj reference will be modified (e.x. the one in oldState)
    const apiSavingMap = {...state.apiSavingMap};
    for (let key of Object.keys(apiSavingMap)) {
      if (key.includes(SEQUENTIAL_SUBMIT_REQUEST) && apiSavingMap[key] > 0) {
        hasSequentialRequestActions = true;
        apiSavingMap[key] = 0;
      }
    }

    // clean up ANY queued actions stored in queuedActionsMap, we should reset ALL queued actions when we encounter
    // a `SilentCancelAll` action
    const queuedActionsMap = state.queuedActionsMap;
    for (let key of Object.keys(queuedActionsMap)) {
      queuedActionsMap[key] = 0;
    }

    return {
      ...state, apiLoadingMap: {},
      apiLoading: 0,
      showNProgress: false,
      apiSaving: hasSequentialRequestActions ? 0 : state.apiSaving,
      apiSavingMap,
      // If we skipped the restore state, then we should manually set back the `isRouterChangeRollbackFinished` flag
      isRouterChangeRollbackFinished: isActionType(action, SilentCancelAllAction) && action.isDuringNavigation && action.skipRestoreState ? true : state.isRouterChangeRollbackFinished,
    };
  } else if (action.type.startsWith(validateRequestAction) || action.type.includes(LOAD)) {
    if (state.apiLoadingMap[action.type]) {
      return state;
    }
    const newState = {...state, apiLoading: state.apiLoading + 1, apiLoadingCount: state.apiLoadingCount + 1};
    const newLoadingMap = {...newState.apiLoadingMap};
    let actionType = action.type;
    if (actionType.startsWith(API_PRE)) {
      actionType = actionType.replace(API_PRE, API_REQ,);
    }
    newLoadingMap[actionType] = true;
    newState.apiLoadingMap = newLoadingMap;
    if (action.type === ReservationChange.requestType) {
      newState.showNProgress = true;
      NProgress.start();
    }
    return newState;
  } else if (action.type.startsWith(validateSuccessAction) || action.type.startsWith(validateCancelAction) || action.type.includes(LOAD_FINISH)) {
    const newState = {...state};
    removeFromLoadMap(newState, action.type);
    if (action.type === ReservationChange.successType || action.type === ReservationChange.failureType || action.type === GetSiteCache.successType || action.type === GetSiteCache.failureType || action.type === GetFacilityLocationCache.successType || action.type === GetFacilityLocationCache.failureType) {
      newState.showNProgress = false;
      NProgress.done();
    }
    return newState;
  } else if (action.type.includes(SAVE)) {
    const newState = {...state};
    if (action.type === LoginActions.requestType) {
      newState.loadingAll = true;
    } else if (isActionType(action, LogoutRequest)) {
      newState.isLoggingOut = true;
    }
    let actionType = action.type;
    if (actionType.startsWith(API_PRE)) {
      actionType = actionType.replace(API_PRE, API_REQ);
    }
    // if its not a sequential request, we could stop here and return right away. However, if it
    // is a sequential request, we need to furhter process it
    if (state.apiSavingMap[actionType] && !actionType.includes(SEQUENTIAL_SUBMIT_REQUEST)) {
      return newState;
    }

    const newSavingMap = {...newState.apiSavingMap};
    if (actionType.includes(SEQUENTIAL_SUBMIT_REQUEST)) {
      // if this is a sequential submit request, we dont simply toggle the field with true/false value, but
      // actually counting it
      if (newSavingMap[actionType]) {
        newSavingMap[actionType] += 1;
      }
      else {
        // only increment `apiSaving` once, because we only reset it once all sequential requests are done
        newState.apiSaving = newState.apiSaving + 1;
        newState.apiSavingCount = newState.apiSavingCount + 1;
        newSavingMap[actionType] = 1;
      }
    } else {
      newSavingMap[actionType] = true;
      newState.apiSaving = newState.apiSaving + 1;
      newState.apiSavingCount = newState.apiSavingCount + 1;
    }
    newState.apiSavingMap = newSavingMap;
    return newState;
  } else if (action.type.includes(SAVE_FINISH)) {
    const newState = {...state};
    removeFromSaveMap(newState, action.type);
    if (isActionType(action, LogoutSuccess)) {
      newState.afterLoginPath = undefined;
      newState.afterLoginAccount = undefined;
      newState.isLoggingOut = false;
    } else if (action.type === CheckoutSubmitActions.successType) {
      newState.viewReceiptUrl = `${getDomain(true)}/receipt.cfm?GroupOrder=${action.extra.cartOrderID}`;
    }
    return newState;
  } else if (isActionType(action, Actions.ToggleNotFound)) {
    return {...state, isNotFound: !state.isNotFound};
  } else if (isActionType(action, Actions.ShowAdminPageHeader)) {
    return {...state, showAdminPageHeader: action.show};
  } else if (isActionType(action, Actions.ToggleModalSaving)) {
    return {...state, isModalSaving: action.saving};
  }

  return state || {};
};

export default App;
export type { Actions as AppActions } from "./actions";
export {actionCreators as appActionCreators} from './actions';
