import {makeRequestEpic} from "../../../Validation/epicCreator";
import {MessagesSettingsSubmitActions} from "./actions";
import {AjaxResponse} from "rxjs/ajax";
import { ENTITY_SAVED, SITE_MESSAGES } from "../../../../constants/messages/adminCMS";
import { getUpdateSiteSettingsMessagesUrl } from "../../../../constants/urls";

export const adminCMSSiteMessagesSettingsSubmitEpic = makeRequestEpic(
  MessagesSettingsSubmitActions,
  getUpdateSiteSettingsMessagesUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    return ENTITY_SAVED(SITE_MESSAGES);
  },
);