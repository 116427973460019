import { Reducer } from 'redux';

import * as SessionActions from '../Session/actions';
import {LoginActions} from '../Login/actions';
import { UserModel } from '../../models/api/user';
import { APISuccessSubmit } from '../Validation/actionCreator';
import { setSentryContext } from '../../config/sentry';
import { captureTentarooError } from '../../utils/dataHelper';
import { Action, isActionType } from '../../utils/StrongActions';
import { ClearAllCache } from '../App/actions';
import {EMPTY_USER} from "./constants";

export interface UserState {
  id?: string; // @todo: remove
  user: UserModel;
}

const sendErrorToSentry = () => {
  setSentryContext();
  captureTentarooError(new Error("user.IDi not set"));
};

const getInitialState: () => UserState = () => ({
  user: {...EMPTY_USER},
});

const User: Reducer<UserState> = (state: UserState = getInitialState(), action: Action) => {
  if (isActionType(action, SessionActions.APISuccessGetLoginForm)) {
    if (!action.form.str_user || !action.form.str_user.IDi) {
      sendErrorToSentry();
      return state;
    } else {
      return { ...state, user: { ...action.form.str_user } };
    }
  } else if (action.type === LoginActions.successType) {
    const castAction = <APISuccessSubmit> action;
    if (!castAction.response.response.str_user || !castAction.response.response.str_user.IDi) {
      sendErrorToSentry();
      return state;
    } else {
      return { ...state, user: { ...castAction.response.response.str_user } };
    }
  } else if (
    (isActionType(action, ClearAllCache) && action.clearSession) ||
    isActionType(action, SessionActions.LogoutSuccess)
  ) {
    return { ...state, user: { ...EMPTY_USER } };
  }

  return state || { user: {...EMPTY_USER} };
};

export default User;
