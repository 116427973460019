import * as moment from 'moment';
import * as M from "../../../../constants/messages/generic";
import { Validator } from '../../../../utils/validator/models';

export interface IValidator {
  StartDate: Validator;
}

export const FormDefinition: IValidator = {
  StartDate: {
    key: 'StartDate',
    defaultValue: () => moment().add(-2, 'month'),
    validatejs: {
      StartDate: {
        presence: {message: M.S_REQUIRED('Start Date')},
        datetime: {
          notValid: M.S_INVALID_DATE('Start Date')
        }
      }
    }
  },
};
