import * as React from 'react';
import Tabs from './Tabs';
import { namespace } from './constants';
import '../../../../styles/app/header/nav.scss';

interface Props {
  children?: React.ReactNode;
}

const Nav: React.SFC<Props> = ({ children }: Props): React.ReactElement<Props> => (
  <Tabs className={namespace()}>
    {children}
  </Tabs>
);

export default Nav;
