import * as React from 'react';

import { FacilityTypeFormState } from '../../../../../store/AdminFacilityLocation/Facilities/FacilityType/Form';
import { FacilityTypeFormSection } from '../../../../../store/AdminFacilityLocation/Facilities/FacilityType/Form/actions';
import { AdminFacilityLocationCacheOneState } from '../../../../../store/AdminFacilityLocation/CacheOne';
import '../../../../../styles/pages/admin-facility-location/facilities/facility-type/index.scss';
import { Main, MainContent } from '../../../../Layouts';
import { Form, FormActions, Button, Row, Column, TextField, Switch, RadioGroupLabel, RadioGroupOption, FieldSet, Alert } from '../../../../Elements';
import ContactCard, { AdminFacilityLocationContactCardType } from '../../../../../components/Elements/AdminContact/FacilityLocationCard';
import { CardCategory } from '../../../../../components/Elements/Card';
import TimePicker from '../../../../../components/Elements/TimePicker';
import { CloseIcon } from '../../../../../components/Icons';
import { AdminFacilityTypeAttribute } from '../../../../../models/api/adminFacilitiesCacheTwoFacilityType';
import { CacheZeroState } from '../../../../../store/CacheZero';
import { SUBMIT_ERROR } from '../../../../../constants/messages/generic';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { Validator } from '../../../../../utils/validator/models';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--facility-location--facility-type';

export type Props = WithInertAttribute<{
  action: 'add' | 'edit';
  disabled: boolean;
  formState: FacilityTypeFormState;
  cacheZero: CacheZeroState;
  loading?: boolean;
  adminFacilityLocationCacheOne: AdminFacilityLocationCacheOneState;
  showSection?: FacilityTypeFormSection;
  handleCompact?: boolean;
  hideFormAction?: boolean;
  onSave?: () => void;
  onDelete?: (id: number) => void;
  reduxActions: {
    updateFacilityTypeRowValue(value: any, vObj: Validator);
    updateFacilityTypeGeneralValue(value: any, vObj: Validator);
    updateFacilityTypeBookingTimesValue(value: any, vObj: Validator);
    updateFacilityTypeAttributesValue(value: any, vObj: Validator);
    deleteAttribute(index: number, restore?: boolean);
    newAttribute();
    simpleUpdate(value: any, vObj: Validator);
    simpleUpdateGeneral(value: any, vObj: Validator);
    simpleUpdateBookingTimes(value: any, vObj: Validator);
    simpleUpdateAttributes(value: any, vObj: Validator);
  };
}>;

class FacilityTypeForm extends React.Component<Props, {}> {
  public props: Props;

  renderPrimaryContact = () => {
    const { adminFacilityLocationCacheOne } = this.props;

    return (
      <ContactCard
        type={AdminFacilityLocationContactCardType.FACILITY_TYPE_PRIMARY_CONTACT}
        category={CardCategory.CONTENT}
        contact={{
          Name: !adminFacilityLocationCacheOne.FacilitiesLocation ? '' : adminFacilityLocationCacheOne.FacilitiesLocation.PrimaryContactName,
          Phone: !adminFacilityLocationCacheOne.FacilitiesLocation ? '' : adminFacilityLocationCacheOne.FacilitiesLocation.PrimaryContactPhone,
          Email: !adminFacilityLocationCacheOne.FacilitiesLocation ? '' : adminFacilityLocationCacheOne.FacilitiesLocation.PrimaryContactEmail
        }}
      />
    );

    return null;
  };

  renderPrimaryContactHelperText = (hasPrimaryContact: boolean) => {
    const text = hasPrimaryContact ? 'The Primary Contact can be changed under Settings for facilities at this location.' : (!!this.props.showSection ? 'Please add a Primary Contact under Settings for facilities at this location.' : 'Please add a Primary Contact under Settings for facilities at this location. The Settings tab will be in the top blue bar once you return to the Facility Types list.');

    return <div className={`${namespace()}--primary-contact--helper-text`}>{text}</div>;
  };

  onRemoveAttribute = (attr: AdminFacilityTypeAttribute, index: number) => {
    const {reduxActions, formState} = this.props;

    if (this.isFormDisabled()) return;
    
    reduxActions.updateFacilityTypeAttributesValue(!attr.Inactive, formState.Attributes.ValidationRules[`AttributeInactive${index}`]);
    reduxActions.deleteAttribute(index, !attr.Inactive);
  };

  onNewAttribute = () => {
    const {reduxActions} = this.props;

    reduxActions.newAttribute();
  };

  showGeneralSection = () => {
    return !this.props.showSection || this.props.showSection === FacilityTypeFormSection.GENERAL;
  };

  showDescriptionSection = () => {
    return !this.props.showSection || this.props.showSection === FacilityTypeFormSection.DESCRIPTION;
  };

  showBookingTimesSection = () => {
    return !this.props.showSection || this.props.showSection === FacilityTypeFormSection.BOOKING_TIMES;
  };

  showAttributesSection = () => {
    return !this.props.showSection || this.props.showSection === FacilityTypeFormSection.ATTRIBUTES;
  };

  getSubmitErrorMessage = () => {
    const { formState } = this.props;

    if (formState.General.SubmitErrorMessage && formState.General.SubmitErrorMessage !== SUBMIT_ERROR) return formState.General.SubmitErrorMessage;
    if (formState.FacilityTypeRow.SubmitErrorMessage && formState.FacilityTypeRow.SubmitErrorMessage !== SUBMIT_ERROR) return formState.FacilityTypeRow.SubmitErrorMessage;
    if (formState.BookingTimes.SubmitErrorMessage && formState.BookingTimes.SubmitErrorMessage !== SUBMIT_ERROR) return formState.BookingTimes.SubmitErrorMessage;
    if (formState.Attributes.SubmitErrorMessage && formState.Attributes.SubmitErrorMessage !== SUBMIT_ERROR) return formState.Attributes.SubmitErrorMessage;

    return formState.General.SubmitErrorMessage || formState.FacilityTypeRow.SubmitErrorMessage || formState.BookingTimes.SubmitErrorMessage || formState.Attributes.SubmitErrorMessage;
  };

  isFormDisabled = () => {
    const { cacheZero, action } = this.props;

    if (!cacheZero.options || !cacheZero.options.GeneralPermissions) return true;

    return action === "edit" ? !cacheZero.options.GeneralPermissions.hasFacilityTypeEdit : !cacheZero.options.GeneralPermissions.hasFacilityTypeAdd;
  };

  public render() {
    const {onSave, onDelete, adminFacilityLocationCacheOne, action, disabled, reduxActions, formState, cacheZero, inert, loading, handleCompact, hideFormAction} = this.props;

    const hasPrimaryContact = !!adminFacilityLocationCacheOne.FacilitiesLocation && !!adminFacilityLocationCacheOne.FacilitiesLocation.PrimaryContactName && !!adminFacilityLocationCacheOne.FacilitiesLocation.PrimaryContactEmail && !!adminFacilityLocationCacheOne.FacilitiesLocation.PrimaryContactPhone;

    const SubmitErrorMessage = this.getSubmitErrorMessage();
    return (
      <Main inert={inert}>
        <MainContent loading={loading} className={`${namespace()}--main-content`} handleCompact={handleCompact}>
            <Form
              onSubmit={e => e.preventDefault()}
              actions={
                hideFormAction ? null : (action === 'add' ? (
                  <FormActions><Button disabled={this.isFormDisabled()} flat textColor="black" big onClick={onSave}>CREATE</Button></FormActions>
                ) : (
                  <FormActions>
                    <Row>
                      <Column expand>
                        <Button id={generateDOMId("admin-facility-type-save-btn")} disabled={this.isFormDisabled()} color="white" flat textColor="black" big onClick={onSave}>SAVE</Button>
                      </Column>
                      <Column>
                        <Button id={generateDOMId("admin-facility-type-delete-btn")} disabled={this.isFormDisabled()} color="white" flat textColor="red" big onClick={() => onDelete && onDelete(formState.FacilityTypeRow.ActiveForm.ID)}>DELETE</Button>
                      </Column>
                    </Row>
                  </FormActions>)
                )
              }
            >
              {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
              {action === 'edit' && cacheZero.options && cacheZero.options.GeneralPermissions && cacheZero.options.GeneralPermissions.hasFacilityTypeEditReason ? <Alert className={`${namespace()}--alert`}>{cacheZero.options.GeneralPermissions.hasFacilityTypeEditReason}</Alert> : null}
              {action === 'add' && cacheZero.options && cacheZero.options.GeneralPermissions && cacheZero.options.GeneralPermissions.hasFacilityTypeAddReason ? <Alert className={`${namespace()}--alert`}>{cacheZero.options.GeneralPermissions.hasFacilityTypeAddReason}</Alert> : null}
              {!this.showGeneralSection() ? null : <Row className={`${namespace()}--row`} marginBottom={8} mobileMarginBottom={8}>
                <Column marginRight={24} mobileMarginRight={0} mobileMarginBottom={8} span={6} mobileSpan={12}>
                  <TextField
                    label="Name"
                    id={generateDOMId("admin-facility-type-name-field")}
                    disabled={disabled || this.isFormDisabled()}
                    onChange={reduxActions.simpleUpdateGeneral}
                    onBlur={reduxActions.updateFacilityTypeGeneralValue}
                    validationRules={formState.General.ValidationRules.Name}
                    value={formState.General.ActiveForm.Name}
                  />
                </Column>
                <Column span={6} mobileSpan={12}>
                  <TextField
                    label="Name In Plural"
                    disabled={disabled || this.isFormDisabled()}
                    onChange={reduxActions.simpleUpdateGeneral}
                    onBlur={reduxActions.updateFacilityTypeGeneralValue}
                    validationRules={formState.General.ValidationRules.NamePlural}
                    value={formState.General.ActiveForm.NamePlural}
                  />
                </Column>
              </Row>}
              {!this.showGeneralSection() ? null : <RadioGroupLabel marginBottom={12}>Show As</RadioGroupLabel>}
              {this.showGeneralSection() && adminFacilityLocationCacheOne.ShowAsOptions && adminFacilityLocationCacheOne.ShowAsOptions.map((o, index) => {
                return (
                  <Row
                    key={`option_${index}`}
                    marginBottom={adminFacilityLocationCacheOne.ShowAsOptions && index === adminFacilityLocationCacheOne.ShowAsOptions.length - 1 ? 16 : 0}>
                    <Column span={12} mobileSpan={12}>
                      <RadioGroupOption
                        value={formState.General.ActiveForm.ShowAsID === o.ID}
                        selected={formState.General.ActiveForm.ShowAsID === o.ID}
                        ValidationRules={formState.General.ValidationRules.ShowAsID}
                        rawValue={o.ID}
                        newDesign
                        label={o.Name}
                        disabled={disabled || this.isFormDisabled()}
                        helperText={o.ID === 1 ? 'Show times as Check-In/Out' : 'Show times as Start/End'}
                        onChange={reduxActions.updateFacilityTypeGeneralValue}
                      />
                    </Column>
                  </Row>
                );
              })}
              {!this.showGeneralSection() ? null : <Row marginBottom={16} mobileMarginBottom={16}>
                <Column span={12} mobileSpan={12}>
                  <Switch
                      margin={false}
                      newDesign
                      disabled={disabled || this.isFormDisabled()}
                      label="Allow Overlapping Groups"
                      value={!!formState.General.ActiveForm.AllowOverlapping}
                      validationRules={formState.General.ValidationRules.AllowOverlapping}
                      onChange={reduxActions.updateFacilityTypeGeneralValue}
                      helperText={[
                        'Allow multiple reservations at the same time.',
                        'Maximum capacity is not currently enforced across multiple simultaneous reservations.',
                      ]}
                  />
                </Column>
              </Row>}
              {!this.showGeneralSection() ? null : <Row marginBottom={8} mobileMarginBottom={8}>
                <Column span={12} mobileSpan={12}>
                  <TextField
                    label="Property Map URL"
                    disabled={disabled || this.isFormDisabled()}
                    onChange={reduxActions.simpleUpdateGeneral}
                    onBlur={reduxActions.updateFacilityTypeGeneralValue}
                    validationRules={formState.General.ValidationRules.PropertyMapURL}
                    value={formState.General.ActiveForm.PropertyMapURL}
                  />
                </Column>
              </Row>}
              {!this.showDescriptionSection() ? null : <Row marginBottom={32} mobileMarginBottom={24}>
                <Column span={12} mobileSpan={12}>
                  <TextField
                    label="Description"
                    disabled={disabled || this.isFormDisabled()}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateFacilityTypeRowValue}
                    validationRules={formState.FacilityTypeRow.ValidationRules.Description}
                    value={formState.FacilityTypeRow.ActiveForm.Description}
                    rows={4}
                  />
                </Column>
              </Row>}
              {!this.showGeneralSection() ? null : <FieldSet marginTop={this.props.showSection === FacilityTypeFormSection.GENERAL ? 24 : 0} mobileMarginTop={this.props.showSection === FacilityTypeFormSection.GENERAL ? 16 : 0} fixedLegendFontSize emptyFieldSet newDesign legendMarginBottom={0} fontSize={22} name="Notify Contacts"></FieldSet>}
              {!this.showGeneralSection() ? null : <FieldSet fixedLegendFontSize className={`${namespace()}--primary-contact`} newDesign marginBottom={24} mobileMarginBottom={24} legendMarginBottom={16} fontSize={18} name="Primary Contact">
                {hasPrimaryContact ? this.renderPrimaryContact() : null}
                {this.renderPrimaryContactHelperText(hasPrimaryContact)}
              </FieldSet>}
              {!this.showGeneralSection() ? null : <FieldSet fixedLegendFontSize marginBottom={16} newDesign legendMarginBottom={16} fontSize={18} name="Additional Notify Contact">
                <Row className={`${namespace()}--row`} marginBottom={8} mobileMarginBottom={8}>
                  <Column marginRight={24} mobileMarginRight={0} mobileMarginBottom={8} span={6} mobileSpan={12}>
                    <TextField
                      label="Full Name"
                      disabled={disabled || this.isFormDisabled()}
                      onChange={reduxActions.simpleUpdateGeneral}
                      onBlur={reduxActions.updateFacilityTypeGeneralValue}
                      validationRules={formState.General.ValidationRules.AdditionalNotify1_Name}
                      value={formState.General.ActiveForm.AdditionalNotify1_Name}
                    />
                  </Column>
                  <Column span={6} mobileSpan={12}>
                    <TextField
                      label="Email"
                      disabled={disabled || this.isFormDisabled()}
                      onChange={reduxActions.simpleUpdateGeneral}
                      onBlur={reduxActions.updateFacilityTypeGeneralValue}
                      validationRules={formState.General.ValidationRules.AdditionalNotify1_Email}
                      value={formState.General.ActiveForm.AdditionalNotify1_Email}
                    />
                  </Column>
                </Row>
              </FieldSet>}
              {!this.showGeneralSection() ? null : <FieldSet fixedLegendFontSize marginBottom={24} mobileMarginBottom={16} newDesign legendMarginBottom={16} fontSize={18} name="Additional Notify Contact">
                <Row className={`${namespace()}--row`} marginBottom={8} mobileMarginBottom={8}>
                  <Column marginRight={24} mobileMarginRight={0} mobileMarginBottom={8} span={6} mobileSpan={12}>
                    <TextField
                      label="Full Name"
                      disabled={disabled || this.isFormDisabled()}
                      onChange={reduxActions.simpleUpdateGeneral}
                      onBlur={reduxActions.updateFacilityTypeGeneralValue}
                      validationRules={formState.General.ValidationRules.AdditionalNotify2_Name}
                      value={formState.General.ActiveForm.AdditionalNotify2_Name}
                    />
                  </Column>
                  <Column span={6} mobileSpan={12}>
                    <TextField
                      label="Email"
                      disabled={disabled || this.isFormDisabled()}
                      onChange={reduxActions.simpleUpdateGeneral}
                      onBlur={reduxActions.updateFacilityTypeGeneralValue}
                      validationRules={formState.General.ValidationRules.AdditionalNotify2_Email}
                      value={formState.General.ActiveForm.AdditionalNotify2_Email}
                    />
                  </Column>
                </Row>
              </FieldSet>}
              {!this.showBookingTimesSection() ? null : <FieldSet fixedLegendFontSize marginBottom={32} mobileMarginBottom={24} newDesign legendMarginBottom={0} fontSize={22} name="Booking Times">
                <Row marginTop={24} mobileMarginTop={16} marginBottom={8}>
                  <Column span={6} mobileSpan={12}>
                    <TimePicker
                      label={formState.General.ActiveForm.ShowAsID === 1 ? "Check-In" : "Start"}
                      customizedId='startDateTime'
                      disabled={disabled || this.isFormDisabled()}
                      onChangeValue={reduxActions.updateFacilityTypeBookingTimesValue}
                      hourValue={formState.BookingTimes.ActiveForm.StartHour}
                      minValue={formState.BookingTimes.ActiveForm.StartMin}
                      periodValue={formState.BookingTimes.ActiveForm.StartPeriod}
                      hourValidationRules={formState.BookingTimes.ValidationRules.StartHour}
                      minValidationRules={formState.BookingTimes.ValidationRules.StartMin}
                      periodValidationRules={formState.BookingTimes.ValidationRules.StartPeriod}
                    />
                  </Column>
                </Row>
                <Row marginBottom={this.props.showSection === FacilityTypeFormSection.BOOKING_TIMES ? 16 : 0} className={`${namespace()}--row`}>
                  <Column marginRight={24} mobileMarginRight={0} mobileMarginBottom={8} span={6} mobileSpan={12}>
                    <TimePicker
                      label={formState.General.ActiveForm.ShowAsID === 1 ? "Check-Out" : "End"}
                      customizedId='endDateTime'
                      disabled={disabled || this.isFormDisabled()}
                      onChangeValue={reduxActions.updateFacilityTypeBookingTimesValue}
                      hourValue={formState.BookingTimes.ActiveForm.EndHour}
                      minValue={formState.BookingTimes.ActiveForm.EndMin}
                      periodValue={formState.BookingTimes.ActiveForm.EndPeriod}
                      hourValidationRules={formState.BookingTimes.ValidationRules.EndHour}
                      minValidationRules={formState.BookingTimes.ValidationRules.EndMin}
                      periodValidationRules={formState.BookingTimes.ValidationRules.EndPeriod}
                    />
                  </Column>
                  <Column marginTop={10} mobileMarginTop={0} span={6} mobileSpan={12}>
                    <Switch
                        margin={false}
                        newDesign
                        disabled={disabled || this.isFormDisabled()}
                        label="Next Day"
                        value={!!formState.BookingTimes.ActiveForm.IsCheckoutNextDay}
                        validationRules={formState.BookingTimes.ValidationRules.IsCheckoutNextDay}
                        onChange={reduxActions.updateFacilityTypeBookingTimesValue}
                    />
                  </Column>
                </Row>
                {this.props.showSection === FacilityTypeFormSection.BOOKING_TIMES ? <div className={`${namespace()}--booking-times--helper-text`}>Please note that the check-in/out times for any pre-existing reservations will not be affected by this change.</div> : null}
              </FieldSet>}
              {!this.showAttributesSection() ? null : <FieldSet mobileMarginBottom={32} fixedLegendFontSize newDesign legendMarginBottom={8} fontSize={22} name="Attributes">
                <div className={`${namespace()}--attributes--desc`}>Attributes can be shown as checked or unchecked under each facility.</div>
                <div className={`${namespace()}--attributes--desc`}>For example, add "Kitchen" to show whether or not each cabin has a kitchen.</div>
                {formState.Attributes.Values.map((attr, index) => {
                  if (attr.Inactive && attr.ID === -1) return null;
                  return (
                    <Row key={`attr_${index}`} marginBottom={8} mobileMarginBottom={8}>
                      <Column style={{alignItems: 'center'}} span={12} mobileSpan={12}>
                        <TextField
                          label="Attribute"
                          hideOptional
                          disabled={disabled || attr.Inactive || this.isFormDisabled()}
                          onChange={reduxActions.simpleUpdateAttributes}
                          onBlur={reduxActions.updateFacilityTypeAttributesValue}
                          validationRules={formState.Attributes.ValidationRules[`AttributeName${index}`]}
                          value={formState.Attributes.ActiveForm[`AttributeName${index}`]}
                        />
                        {!attr.Inactive && <div onClick={() => this.onRemoveAttribute(attr, index)} className={`${namespace()}--attribute-remove ${this.isFormDisabled() ? 'disabled' : ''}`}>
                          <CloseIcon width='14px' height='14px' />
                        </div>}
                        {attr.Inactive && <Button disabled={this.isFormDisabled()} className={`${namespace()}--attribute-restore`} onClick={() => this.onRemoveAttribute(attr, index)} textColor='red' flat color='white'>RESTORE</Button>}
                      </Column>
                    </Row>
                  );
                })}
                <Button disabled={disabled || this.isFormDisabled()} className={`${namespace()}--attribute-new`} onClick={this.onNewAttribute} color='white' textColor='black'>NEW</Button>
              </FieldSet>}
            </Form>
        </MainContent>
      </Main>
    );
  }
}

export default FacilityTypeForm;