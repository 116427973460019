import { typeName, Action } from '../../utils/StrongActions';
import { ActionCreator } from '../index';
import {SaveState} from "../Rollback/actions";

export const FILTER_TYPE = 'HOME_FILTER';

@typeName('HOME_SELECT_TAB') export class HomeSelectTab extends Action {
  constructor(public selectedTab: 'myEvents' | 'myTrips') { super(); }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  selectedTab: (selectedTab: 'myEvents' | 'myTrips'): ActionCreator => dispatch => {
    dispatch(new HomeSelectTab(selectedTab));
    dispatch(new SaveState());
  },
};
