import * as React from 'react';
import { bindActionCreators } from 'redux';
import { ConfirmationModal, ConfirmationModalContent, ConfirmationModalActions } from '../../../../../Templates';
import { Paragraph, Button } from '../../../../../Elements';
import { actionCreators } from '../../../../../../store/AdminFacilityLocation/CacheTwoFacilityTypes/actions';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

type Props = WithInertAttribute<{}>;

type ConnectedProps = ReturnType<typeof mapDispatchToProps>;

class FacilityTypeConfirmation extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  onClose = () => {
    this.props.actions.popModal(false, true, ModalTypes.FACILITY_TYPE_CONFIRMATION);
  };

  // @todo: resend email doesn't do anything
  public render() {
    const {inert} = this.props;
    return (
      <ConfirmationModal inert={inert}>
        <ConfirmationModalContent>
          <Paragraph>
            Please note that the check-in/out times for any pre-existing reservations will not be affected by this change.
          </Paragraph>
        </ConfirmationModalContent>
        <ConfirmationModalActions
          right={<Button flat onClick={this.onClose}>Close</Button>}
        />
      </ConfirmationModal>
    );
  }
}


const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
  }, dispatch),
});

const ConnectedFacilityTypeConfirmation = connect(
  null,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(FacilityTypeConfirmation);

export default ConnectedFacilityTypeConfirmation;
