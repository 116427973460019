import {APISuccess} from "../Validation/actionCreator";
import {ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction} from "../App/actions";
import {ClearCacheBelowOne} from "../CacheOne/actions";
import {GetGroupRosterPerson} from "./actions";
import {convertObjToMoment} from "../../utils/dateHelper";
import {EmptyCartActions, RemoveItemFromCartActions} from "../Cart/actions";
import {FullGroupRosterPerson} from "../../models/api/cacheTwoRoster";
import {ClearCacheBelowTwoRoster} from "../CacheTwoRoster/actions";
import { Reducer } from "redux";
import { Action, isActionType } from "../../utils/StrongActions";

interface CacheThreeRosterCore {
  GroupRosterPerson?: FullGroupRosterPerson | null; // @todo: this type is wrong, it needs to be a FULLGroupRosterPerson or something
}

export interface CacheThreeRosterState extends CacheThreeRosterCore {
}

const setCacheThreeData = (data: CacheThreeRosterCore) => {
  return {
    GroupRosterPerson: convertObjToMoment(data.GroupRosterPerson, ['DOB']),
  };
};

const handleResponse = (state, responseData, request) => {
  if (responseData.GroupRosterPerson !== undefined){
    return setCacheThreeData(responseData);
  }
  return state;
};

const getInitialState = () => ({});

const CacheThreeRoster: Reducer<CacheThreeRosterState> = (state, act: Action) => {
  if (
    act.type === GetGroupRosterPerson.successType ||
    act.type === EmptyCartActions.successType ||
    act.type === RemoveItemFromCartActions.successType
  ) {
    const action = <APISuccess> act;
    return handleResponse(state, action.response.response, action.response.request);
  } else if (isActionType(act, SetCacheAction)) {
    return handleResponse(state, act.response.xhr.response, act.response.request);
  } else if (
    isActionType(act, ClearAllCache) || isActionType(act, ClearAllEndUserCacheButOptions) || isActionType(act, ClearCacheBelowOne) ||
    isActionType(act, ClearCacheBelowTwoRoster)
  ) {
    return {...getInitialState()};
  }
  return state || {...getInitialState()};
};

export default CacheThreeRoster;
