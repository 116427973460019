import {push} from "react-router-redux";

import type {ActionCreator} from '../../../../index';
import {actionCreators as appActionCreators} from "../../../../App/actions";
import {SaveState} from "../../../../Rollback/actions";
import {FacilitiesRecalc, FacilitiesSave} from "../../../../CacheFourFacilities/actions";
import {
  FAC_TRIP_RESERVATION,
  getFacilitiesReservationsCancelLoadBody,
  getFacilitiesReservationsSaveBody,
  GetFacilityReservationInitParams
} from "../../../../../constants/urls";
import {
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions
} from "../../../../Validation/actionCreator";
import {validateAll} from "../../../../../utils/validator";
import {getTotalAmount} from "../../../../../utils/helpers/financialSummaryHelper";
import { ShowTopFloatingAlert} from "../../../../App/actions";
import { captureTentarooError } from '../../../../../utils/dataHelper';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { typeName, Action } from '../../../../../utils/StrongActions';
import { ClearCacheBelowThreeFacilities } from '../../../../CacheThreeFacilities/actions';
import {determineError} from "./reducerHelpers";
import { Validator } from "../../../../../utils/validator/models";

export const SUFFIX = '__CANCEL_RESERVATION';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

@typeName(`RESET${SUFFIX}`) export class CancelReservationReset extends Action {}

export type Actions = typeof actionCreators;

const shouldSave = (value: any, vObj: Validator) => {
  return false;
};

export const actionCreators = {
  cancelReservationLoad: (ReservationID: number, ProposedInactive: boolean): ActionCreator => (dispatch, getState) => {
    // is is always next
    // dispatch(new SaveState());

    const rootState = getState();

    const GroupIDi = rootState.cacheZero.options!.Group!.IDi;
    const GroupTS = rootState.cacheZero.options!.Group!.TS;
    const FacilityLocationID = rootState.cacheTwoFacilities.locationID;
    const FacilityTripID = rootState.cacheThreeFacilities.tripID;

    const body = getFacilitiesReservationsCancelLoadBody(
      {
        GroupIDi,
        GroupTS,
        FacilityLocationID,
        FacilityTripID,
        ReservationID,
        FacilityTypeID: null as any,
      }
    );
    body.ProposedInactive = ProposedInactive;

    dispatch(FacilitiesRecalc.request(body));
  },
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.facilities.trip.modals.cancelReservation, true, shouldSave, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  reset: (): ActionCreator => dispatch => dispatch(new CancelReservationReset()),
  updateReservation: (ReservationID: number, ProposedInactive: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());

    const rootState = getState();
    const c4 = rootState.cacheFourFacilities;
    const options = rootState.cacheZero.options;
    const Reservation = c4.Reservation!;

    if (!c4.TripPaymentStatus) {
      captureTentarooError(new Error("c4.TripPaymentStatus not available when performing updateReservation."));
      return;
    }
    if (!options) {
      captureTentarooError(new Error("options not available when performing updateReservation."));
      return;
    }
    if (!options.Group) {
      captureTentarooError(new Error("Group not available when performing updateReservation."));
      return;
    }

    const validationResults = validateAll(s => s.facilities.trip.modals.cancelReservation);

    if (validationResults) {
      const errorMessage = determineError(validationResults.Errors);
      if (errorMessage) dispatch(new ShowTopFloatingAlert(errorMessage, true, 'orange'));
    } else {
      // let newAmount = 0;
      let newAmount: undefined | number = getTotalAmount(
        rootState.facilities.trip.modals.cancelReservation.ActiveForm,
        c4.TripPaymentStatus
      );
      if (newAmount !== undefined) {
        newAmount = newAmount - c4.TripPaymentStatus.AmountInCart;
      }

      const route = rootState.routing.locationBeforeTransitions;
      const s = route.pathname.split(`/`);
      if (route.pathname.includes(`/${FAC_TRIP_RESERVATION}/`)) {
        const sp = route.pathname.split(`/${FAC_TRIP_RESERVATION}/`);
        dispatch(push(`${sp[0]}${route.search}`));
      }
      // NOTE: There is a save state at the beginning, so here we set both saveBefore and saveAfter to false
      dispatch(appActionCreators.popModal(false, false, ModalTypes.CANCEL_RESERVATION) as any);
      // move before request so the LoadingAll spinner won't block you

      const params: GetFacilityReservationInitParams = {
        GroupIDi: options.Group.IDi,
        GroupTS: options.Group.TS,
        FacilityLocationID: getState().cacheTwoFacilities.locationID,
        FacilityTripID: getState().cacheThreeFacilities.tripID,
        FacilityTypeID: null as any
      };
      if (Reservation.ReservationID) {
        params.ReservationID = Reservation.ReservationID;
      }

      if (!Reservation.FacilityID) {
        captureTentarooError(new Error("Reservation.FacilityID is null when performing updateReservation"));
        return;
      }
      if (Reservation.FlatRate === null) {
        captureTentarooError(new Error("Reservation.FlatRate is null when performing updateReservation"));
        return;
      }
      if (Reservation.PerPersonRate === null) {
        captureTentarooError(new Error("Reservation.PerPersonRate is null when performing updateReservation"));
        return;
      }
      if (Reservation.MinimumPeopleBilledAtPerPersonRates === null) {
        captureTentarooError(new Error("Reservation.MinimumPeopleBilledAtPerPersonRates is null when performing updateReservation"));
        return;
      }
      const body: any = getFacilitiesReservationsSaveBody(
        params,
        Reservation.FacilityID,
        Reservation.NumYouth!,
        Reservation.NumAdults!,
        Reservation.FlatRate,
        Reservation.PerPersonRate,
        Reservation.MinimumPeopleBilledAtPerPersonRates,
        null,
        Reservation.StartDateTime!,
        Reservation.EndDateTime!,
        Reservation.AmountChangeInCart!,
        newAmount!
      );

      body.ProposedInactive = ProposedInactive;

      dispatch(FacilitiesSave.request(body, null));
      dispatch(new ClearCacheBelowThreeFacilities());
    }
  },
};
