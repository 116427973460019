import { ActionCreator } from "../../../..";
import { typeName, Action } from "../../../../../utils/StrongActions";

export const SUFFIX = '__CMS_RESOURCE_CATEGORIES_MODAL';

@typeName("FILTER" + SUFFIX)
export class ResourceCategoriesModalFilter extends Action {
  constructor(public filterString: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class ResourceCategoriesToggleFilterTabs extends Action {
  constructor(public searching: boolean) { super(); }
}

@typeName("RESET" + SUFFIX) export class ResetResourceCategoriesModal extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  filterResourceCategories: (filterString: string): ActionCreator => dispatch => dispatch(new ResourceCategoriesModalFilter(filterString)),
  reset: (): ActionCreator => dispatch => dispatch(new ResetResourceCategoriesModal()),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new ResourceCategoriesToggleFilterTabs(searching)),
};