import * as React from 'react';
import '../../../../styles/elements/contact/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton, Vignette, Checkbox} from '../../index';
import { CMSContact } from '../../../../models/api/adminCMSCacheOne';
import ContextMenuComponent from "./ContextMenu";
import { OptionsIcon, EditIcon, CloseIcon } from '../../../Icons';
import { createInitials, getImagePath } from '../../../../utils';
import { AdminCMSSiteCacheOneState } from '../../../../store/AdminCMSSite/CacheOne';
import { ADMIN_CMS_CONTACT_PREVIEW_PATH } from '../../../../routes';
import { CardCategory } from '../../Card';
import { Row } from '../../Grid';
import { namespace as cardNS } from "../../Card/constants";
import { isCardClicked } from '../../../../utils/navHelper';
import { isMobile } from '../../../../utils/isMobile';
import { isPathnameMatchingRoute } from '../../../../utils/urlHelper';
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--contact--card';

export enum AdminCMSContactCardType {
  LIST='list',
  EDIT_PAGE='edit-page',
  MULTIPLE_CONTACTS='multiple-contacts',
}

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin';
  type: AdminCMSContactCardType;
  contact: CMSContact;
  onEdit?: (contact: CMSContact) => void;
  onPreview?: (contact: CMSContact) => void;
  onRemove?: (contact: CMSContact) => void;
  onRestore?: (contact: CMSContact) => void;
  onDelete?: (contact: CMSContact) => void;
  onMultipleSelect?: (contact: CMSContact) => void;
  adminCMSCacheOne?: AdminCMSSiteCacheOneState
  routes?: any;
  checked?: boolean;
  disabled?: boolean;
  cardCategory?: CardCategory;
}

const ContactCard: React.SFC<Props> = ({ children, type, template, onEdit, onDelete, contact, onPreview, onRestore, onRemove, onMultipleSelect, adminCMSCacheOne, checked, routes, disabled, cardCategory, ...props }: Props): React.ReactElement<Props> => {
  let className = attribute(namespace(), undefined, props);

  const shouldAllowPropagation = (e) => {
    const route = routes[routes.length - 1];
    const clickedOnCard = isCardClicked(e, namespace);
    return route.path === ADMIN_CMS_CONTACT_PREVIEW_PATH && !clickedOnCard;
  };
  const onPreviewClick = (e) => {
    // prevent responding to click and propagate to parent if we are already in the preview page
    if (shouldAllowPropagation(e)) return;
    e.stopPropagation();
    !disabled && onPreview && onPreview(contact);
  };
  const onEditClick = (e) => {
    if (shouldAllowPropagation(e)) return;
    e.stopPropagation();
    !disabled && onEdit && onEdit(contact);
  };
  const onRestoreClick = (e) => {
    if (shouldAllowPropagation(e)) return;
    e.stopPropagation();
    !disabled && onRestore && onRestore(contact);
  };
  const onRemoveClick = (e) => {
    if (shouldAllowPropagation(e)) return;
    e.stopPropagation();
    !disabled && onRemove && onRemove(contact);
  };
  const onMultipleSelectClick = (e) => {
    !disabled && onMultipleSelect && onMultipleSelect(contact);
  };
  const renderForList = () => {
    const route = routes[routes.length - 1];
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>{contact.Name ? contact.Name : contact.Title}</Row>
          <Row className={`${namespace()}--main--text--subtitle`}>
            {contact.Name ? contact.Title : contact.Company}
          </Row>
        </div>
        {!contact.Inactive && <ActionButton
          id={generateDOMId(`admin-cms-contact-${contact.ID}-option-btn`)}
          allowPropagation={route.path === ADMIN_CMS_CONTACT_PREVIEW_PATH}
          icon={OptionsIcon}
          className={`${namespace()}--options ${cardNS()}--options`}
          contextMenu={<ContextMenuComponent
            contactId={contact.ID}
            onPreview={onPreviewClick}
            onEdit={onEditClick}
            onDelete={onDeleteClick} />}
        />}
        {contact.Inactive && onRestore && <Button  className={`${cardNS()}--main--restore`} flat textColor="red" onClick={onRestoreClick}>RESTORE</Button>}
      </Row>
    );
  };
  const onDeleteClick = (e) => {
    e.stopPropagation();
    onDelete && onDelete(contact);
  };
  const renderInMultipleContacts = () => {
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>{contact.Name ? contact.Name : contact.Title}</Row>
          <Row className={`${namespace()}--main--text--subtitle`}>
            {contact.Name ? contact.Title : contact.Company}
          </Row>
        </div>
        <ActionButton
          id={generateDOMId(`multiple-contact-edit-btn-${contact.ID}`)}
          icon={EditIcon}
          className={`${cardNS()}--edit ${cardCategory}`}
          onClick={onEditClick}
        />
      </Row>
    );
  };

  const renderInEditPage = () => {
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>{contact.Name ? contact.Name : contact.Title}</Row>
          <Row className={`${namespace()}--main--text--subtitle`}>
            {contact.Name ? contact.Title : contact.Company}
          </Row>
        </div>
        <ActionButton
          id={generateDOMId(`contact-edit-btn-${contact.ID}`)}
          icon={EditIcon}
          disabled={disabled}
          className={`${cardNS()}--edit`}
          onClick={onEditClick}
        />
        <ActionButton
          icon={CloseIcon}
          disabled={disabled}
          className={`${cardNS()}--close ${cardCategory}`}
          onClick={onRemoveClick}
        />
      </Row>
    );
  };

  const prepareInitial = (name: string, title: string) => {
    let result;
    if (name) {
      const tokens = name.split(' ');
      // use the first and last word from name
      result = tokens.length > 1 ? `${tokens[0]} ${tokens[tokens.length - 1]}` : tokens[0];
    } else {
      const tokens = title.split(' ');
      // use the first two words from title
      result = tokens.length > 1 ? `${tokens[0]} ${tokens[1]}` : tokens[0];
    }

    return result;
  };

  if ((type === AdminCMSContactCardType.LIST || type === AdminCMSContactCardType.MULTIPLE_CONTACTS) && contact.Inactive) className += ' deleted';
  if (checked) className += ' checked';
  if (disabled) className += ' disabled';
  className += ` ${type}`;
  if (isMobile) className += ' mobile';

  const onCardClick = (e) => {
    if (contact.Inactive || disabled) return;
    switch (type) {
      case AdminCMSContactCardType.LIST:
        onPreviewClick(e);
        break;
      case AdminCMSContactCardType.EDIT_PAGE:
        onEditClick(e);
        break;
      case AdminCMSContactCardType.MULTIPLE_CONTACTS:
        onMultipleSelectClick(e);
        break;
      default:
        break;
    }
  };

  const selected = (type === AdminCMSContactCardType.LIST || type === AdminCMSContactCardType.MULTIPLE_CONTACTS) && isPathnameMatchingRoute(location.pathname, ADMIN_CMS_CONTACT_PREVIEW_PATH, 'contactId', contact.ID);

  return (
    <Card
      className={className}
      component={<section/>}
      template={template}
      selected={selected}
      category={cardCategory || CardCategory.LIST}
      padding="none"
    >
      <div id={generateDOMId(`admin-contact-card-${contact.ID}`)} className={`${namespace()}--wrapper`}
          onClick={onCardClick}>
        {type === AdminCMSContactCardType.MULTIPLE_CONTACTS ? 
        <Checkbox
          selected={!!checked}
          value={!!checked}
          onChange={onMultipleSelectClick}
        /> : null}
        <Vignette
          className={`${namespace()}--${type}-avatar`}
          width={40}
          height={40}
          name={createInitials(prepareInitial(contact.Name, contact.Title))}
          color={`#${contact.Color}`}
          image={contact.FeaturedImage ? getImagePath(contact.FeaturedImage) : undefined}
          borderRadius='20px' />
        {type === AdminCMSContactCardType.LIST && renderForList()}
        {type === AdminCMSContactCardType.EDIT_PAGE && renderInEditPage()}
        {type === AdminCMSContactCardType.MULTIPLE_CONTACTS && renderInMultipleContacts()}
      </div>
    </Card>
  );
};

ContactCard.defaultProps = {
  template: 'mobile-no-margin',
};

export default ContactCard;