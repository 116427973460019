import {
  createUpdateValueMethod, createSimpleUpdateValueMethod,
  createValidateActions, createApiValidateActions, createApiSubmitActions
} from "../../../../../Validation/actionCreator";
import { Validator } from '../../../../../../utils/validator/models';
export const SUFFIX = '__PRODUCTS_WIZARD_CONFIRMATION';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ApiSubmitProductsConfirmationActions = createApiSubmitActions(SUFFIX);

export type Actions = typeof actionCreators;

const shouldSave = (value: any, vObj: Validator) => {
  return false;
};

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.events.event.register.products.confirmation, true, shouldSave, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),

};
