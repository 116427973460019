import { RouteComponentProps } from "react-router";
import { CacheZeroState } from "../../store/CacheZero";
import { checkPermission } from "../permissionHelper";

export const checkGLAccountPermission = (
  onSuccess: () => void,
  cacheZero: CacheZeroState,
  isEdit?: boolean,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (cacheZero.options && cacheZero.options.GeneralPermissions) {
    const GeneralPermissions = cacheZero.options.GeneralPermissions;
    checkPermission(
      () => onSuccess(),
      isEdit ? !!GeneralPermissions.hasGLAccountEdit : !!GeneralPermissions.hasGLAccountAdd,
      isEdit ? GeneralPermissions.hasGLAccountEditReason : GeneralPermissions.hasGLAccountAddReason || null,
      inModal,
      noAlertOnFail,
    );
  }
};

export interface IGLAccountRouterParams {
  glAccountId: string;
}

export const getGLAccountIdFromPath = (props: RouteComponentProps<IGLAccountRouterParams, {}>): number => {
  if (props.params.glAccountId) {
    return Number(props.params.glAccountId);
  }
  return 0;
};