import { AdminCMSCacheOneContext } from "@tentaroo/shared";

import {SaveState} from "../../store/Rollback/actions";
import { GetSiteCache } from "../../store/AdminCMSSite/CacheOne/actions";
import { extractRouteParams, isIdFromUrlValid } from "../urlHelper";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import type AdminCMSCacheManager from "../cacheManagers/adminCMSCacheManager";
import { reduxStoreService } from "../../store/service";
import {ToggleNotFound} from '../../store/App/actions';
import { LoadAdminCMSCacheOneParams } from "./helpers/models";
import {isAdminCMSCacheOnePopulated} from "../cachePopulatedCheckers/adminCMS";

// Admin CMS Cache One
export const ensureAdminCMSCacheOne = (
  params: LoadAdminCMSCacheOneParams & {cacheManager: AdminCMSCacheManager},
): boolean => {
  const {props, cacheManager, context, isStateNavigated} = params;

  if (!isIdFromUrlValid(props.params.siteId)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  if (isStateNavigated && context) {
    cacheManager.initCacheOneListPage(context);
  }
  
  let requestedData = false;
  if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminCMSCacheOnePopulated()) {
    if (isStateNavigated) {
      // Initializing Settings page DOES require cache one to be loaded, so we can put it here
      // These inits will happen when navigating to cache one form without any load,
      // and we shouldn't skip form update in this case
      if (
        context === AdminCMSCacheOneContext.SETTINGS_GENERAL ||
        context === AdminCMSCacheOneContext.SETTINGS_MESSAGES
      ) {
        reduxStoreService().dispatch(GetSiteCache.updateForm());
      }
      reduxStoreService().dispatch(new SaveState());
    }
  } else {
    // when cache one is not presented in state tree
    // After this load request is completed, a `updateForm` for cache one form will be dispatched,
    // we set `skipUpdateForm` to true when we are not in the form pages, to skip updating the form
    reduxStoreService().dispatch(GetSiteCache.request(
      {...extractRouteParams(props)},
      {
        skipUpdateForm: (
          context !== AdminCMSCacheOneContext.SETTINGS_GENERAL &&
          context !== AdminCMSCacheOneContext.SETTINGS_MESSAGES
        )
      },
    ));
    
    requestedData = true;
  }

  return requestedData;
};