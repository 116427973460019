import * as React from 'react';

const namespace = (): string => 'templates--added--side-bar-content';

interface Props {
  children?: React.ReactElement<any>;
}

const SideBarContent: React.SFC<Props> = ({ children }: Props): React.ReactElement<Props> => (
  <div className={namespace()}>
    {children}
  </div>
);

export default SideBarContent;
