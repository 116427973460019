import {validateAll} from "../../utils/validator";
import {checkClasses, ClassValidation} from "../../utils/classesHelper";
import {makeAvailableClassesNoFilter} from "./index";
import { spotsOnlySelector } from "../../utils/eventsHelper";
import { ApplicationState } from "..";
import { checkSpotsAndAmountValidation, getParticipantTypeKey, makeSpotFilter } from "../Events/Event/Register/Numbers/Spots/validation";
import { captureTentarooError } from "../../utils/dataHelper";
import { reduxStoreService } from "../service";

export interface NumbersErrors {
  spots: any;
  classes: ClassValidation;
  campsite: any;
  // We need this to find sub-form and update its select field's validation error on submit
  campsiteIDValidationResults: any;
}

export const validateAllNumbersPages = (): Partial<NumbersErrors> => {
  const state = reduxStoreService().getState() as ApplicationState;
  // spots
  const registrationFieldsValidationResult = validateAll(s => s.events.event.register.numbers.spots);

  const spotValidationResult: string[] = [];
  const SpotsOnly = spotsOnlySelector(state);

  if (!SpotsOnly) {
    const EventRegistrationSpots = state.events.event.register.numbers.spots.EventRegistrationSpots;
    EventRegistrationSpots.filter(makeSpotFilter()).forEach((spot) => {
      // Re-validate each spot row (i.e. sub-form) on submit
      const result = validateAll(
        (state) => spot,
      );
      if (result?.Errors?.Amount && result?.Errors?.Amount.length > 0) {
        spotValidationResult.push(result?.Errors?.Amount[0]);
      }
      if (result?.Errors?.NumSpots && result?.Errors?.NumSpots.length > 0) {
        spotValidationResult.push(result?.Errors?.NumSpots[0]);
      }
    });

    state.cacheFourEventsNumbers.EventRegistrationParticipantTypes && state.cacheFourEventsNumbers.EventRegistrationParticipantTypes.forEach((pType) => {
      let SpotSum = 0;
      EventRegistrationSpots
      .filter(makeSpotFilter(true))
      .filter((spot) => {
        return spot.ActiveForm.PTID === pType.ID;
      }).forEach((form) => {
        SpotSum += parseInt(`${form.ActiveForm.NumSpots || 0}`);
      });

      // Manual validation for SpotSum
      const spotError = checkSpotsAndAmountValidation(state, pType, SpotSum);

      if (spotError) {
        spotValidationResult.push(spotError);
      }
    });
  } else {
    state.events.event.register.numbers.spots.EventRegistrationParticipantTypes.forEach((pType) => {
      // Re-validate each participantType (i.e. sub-form) on submit
      const result = validateAll(
        (state) => pType,
      );

      const key = getParticipantTypeKey({ID: pType.ActiveForm.ID});
      if (result?.Errors?.[key] && (result?.Errors?.[key]).length > 0) {
        spotValidationResult.push((result?.Errors?.[key])[0]);
      }
    });
  }

  // classes
  let {NumCampersYouth, NumCampersAdult} = reduxStoreService().getState().events.event.register.numbers.spots.ActiveForm;

  if (NumCampersYouth === undefined) {
    captureTentarooError(new Error("NumCampersYouth is undefined when performing validateAllNumbersPages"));
    return {};
  }
  if (NumCampersAdult === undefined) {
    captureTentarooError(new Error("NumCampersAdult is undefined when performing validateAllNumbersPages"));
    return {};
  }
  const c4 = reduxStoreService().getState().cacheFourEventsNumbers;
  const isAdmin = !!reduxStoreService().getState().user.user.str_permissions.hasAdminAccess;
  const noFilterAvailableClasses = makeAvailableClassesNoFilter();
  const classValidation = checkClasses(
    c4.EventClassesGroupRegistered,
    c4.EventClassesGroupAvailable,
    noFilterAvailableClasses(reduxStoreService().getState()),
    false,
    true,
    false,
    NumCampersYouth,
    NumCampersAdult,
    isAdmin,
    undefined,
  );

  // nothing to do for products. impossible to be invalid once you get past the page

  // campsite
  const campsiteResults = validateAll(s => s.events.event.register.numbers.campsite);
  const campsiteValidationResult: string[] = [];

  // We need this to find sub-form and update its select field's validation error on submit
  const campsiteIDValidationResults: any[] = [];
  const EventRegistrationCampsiteAssignments = state.events.event.register.numbers.campsite.EventRegistrationCampsiteAssignments;
  if (EventRegistrationCampsiteAssignments) {
    EventRegistrationCampsiteAssignments
      .filter((assignmentForm) => {
        return !assignmentForm.ActiveForm.Inactive && !!assignmentForm.ActiveForm.CampsiteIDi;
      }).forEach((assignmentForm) => {
        // Re-validate each campsite row (i.e. sub-form) on submit
        const result = validateAll((s) => assignmentForm);

        if (result?.Errors?.CampsiteIDi && result?.Errors?.CampsiteIDi.length > 0) {
          // NOTE: We also push CampsiteIDi to `campsiteIDValidationResults` because we need it to
          // update validation result in sub-form on submission. We are doing this for select field in
          // sub-form only because other fields already have validation result from onBlur at this point,
          // AND we dont support saving validation result back to the fields onSubmit yet for sub-forms,
          // and we will address this in a future release. 
          campsiteIDValidationResults.push({
            campsiteTempID: assignmentForm.ActiveForm.tempID,
            message: result.Errors.CampsiteIDi,
          });
          campsiteValidationResult.push(result?.Errors?.CampsiteIDi[0]);
        }
        if (result?.Errors?.NumYouth && result?.Errors?.NumYouth.length > 0) {
          campsiteValidationResult.push(result?.Errors?.NumYouth[0]);
        }
        if (result?.Errors?.NumAdults && result?.Errors?.NumAdults.length > 0) {
          campsiteValidationResult.push(result?.Errors?.NumAdults[0]);
        }
      });
  }

  /**
   * Next, assemble validation result for Spots step by merging validation result
   * from Spots main form and from each spot OR participant row, if any
   */
  let spotStepValidationResult;
  if (registrationFieldsValidationResult || spotValidationResult.length > 0) {
    spotStepValidationResult = {Errors: {}};
    if (registrationFieldsValidationResult) {
      spotStepValidationResult.Errors = {...registrationFieldsValidationResult.Errors};
    }

    if (spotValidationResult.length > 0) {
      spotStepValidationResult.Errors = {...spotValidationResult};
    }
  }

  /**
   * Next, assemble validation result for Campsites step by merging validation result
   * from Campsite main form and from each campsite row, if any.
   */
  let campsiteStepValidationResult;
  if (campsiteResults || campsiteValidationResult.length > 0) {
    campsiteStepValidationResult = {Errors: {}};
    if (campsiteResults) {
      campsiteStepValidationResult.Errors = {...campsiteResults.Errors};
    }

    if (campsiteValidationResult.length > 0) {
      campsiteStepValidationResult.Errors = {...campsiteValidationResult};
    }
  }
  return {
    spots: spotStepValidationResult,
    classes: classValidation,
    campsite: campsiteStepValidationResult,
    campsiteIDValidationResults: campsiteIDValidationResults.length > 0 ? campsiteIDValidationResults : undefined,
  };
};
