import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra
} from '../../../Validation/actionCreator';
import { ActionCreator, ApplicationState } from '../../..';
import { SaveState } from '../../../Rollback/actions';
import { scrollSideModalToTop } from '../../../../utils/helpers/adminCMSPageHelper';
import { navPush } from '../../../../utils';
import { ADMIN_EVENTS_MODULE_SUFFIX, ADMIN_EVENT_TYPES_SUFFIX } from '../../CacheOne/actions';
import { AdminEventProductActiveForm } from '.';
import { getDeleteAdminEventProductBody, getProductsHomeRootUrl, getUpdateAdminEventProductBody } from '../../../../constants/adminEventsUrls';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../../../utils/suffix';
import { PlainRoute } from 'react-router';

export const UPDATE_EVENT_PRODUCT_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__ADMIN_EVENT_PRODUCT';
export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + '__ADMIN_EVENT_PRODUCT_FORM' + UPDATE_EVENT_PRODUCT_FORM_SUFFIX;
export const DEL_SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + '__DELETE_ADMIN_PRODUCT' + UPDATE_EVENT_PRODUCT_FORM_SUFFIX;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const UpdateAdminEventProductSubmitActions = createApiSubmitActions(SUFFIX);
export const DeleteAdminEventProductSubmitActions = createApiSubmitActions(DEL_SUFFIX);

export const AdminEventProductFormNamespace = (): string => 'pages--admin-events--product';

const formCreator = (ActiveForm: AdminEventProductActiveForm) => {
  const IDi = ActiveForm.IDi;
  const formData = new FormData();
  formData.append('JSONData', JSON.stringify(getUpdateAdminEventProductBody({
    IDi: IDi,
    Name: ActiveForm.Name,
    IsAdminOnly: ActiveForm.IsAdminOnly,
    Amount: ActiveForm.Amount,
    GLAccountID: ActiveForm.GLAccountID,
    Description: ActiveForm.Description,
    TS: ActiveForm.TS || 0,
    RemoveImage: !!IDi && !ActiveForm.FeaturedImage,
  })));

  if (ActiveForm.FeaturedImage && ActiveForm.FeaturedImage.inputObj) formData.append('File', ActiveForm.FeaturedImage.inputObj);

  return formData;
};

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.products.form),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  apiSubmitForm: (router: any): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    // form validation (not including file)
    const valid = innerApiSubmitFormMethod(
      dispatch,
      UpdateAdminEventProductSubmitActions,
      (s: ApplicationState) => s.adminEvents.products.form,
      undefined,
      true,
      true,
      true,
      undefined,
      undefined,
    );
    
    if (valid) {
      const body = formCreator(state.adminEvents.products.form.ActiveForm);
      if (state.adminEvents.cacheOne.EventsEventType) {
        navPush(router, getProductsHomeRootUrl({
          eventTypeId: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID,
          eventTypeName: state.adminEvents.cacheOne.EventsEventType.General.Name,
        }));
      }
      dispatch(UpdateAdminEventProductSubmitActions.request(
        body,
        getCacheLevelExtra(true, !!state.adminEvents.cacheTwoProduct.Product),
      ));
    } else {
      scrollSideModalToTop(AdminEventProductFormNamespace());
    }
  },
  deleteAdminEventProduct: (productId: number, router: any, routes: PlainRoute<any>[], inactive: boolean): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const route = routes[routes.length - 1];
    if (!state.adminEvents.cacheOne.EventsEventType) return;

    dispatch(new SaveState());

    const body = getDeleteAdminEventProductBody({
      IDi: productId,
      Inactive: inactive,
    });
    navPush(router, getProductsHomeRootUrl({
      eventTypeId: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID,
      eventTypeName: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.Name,
    }));

    dispatch(DeleteAdminEventProductSubmitActions.request(
      body,
      getCacheLevelExtra(true, !!state.adminEvents.cacheTwoProduct.Product),
      route,
    ));
  },
};