import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/loader/index.scss';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

const LoaderImage = require("../../../images/elements/loader/loader.png") + '';
const LoaderImageWhite = require("../../../images/elements/loader/loader-white.png") + '';

export const namespace = 'elements--loader';

export interface LoaderProps {
  children?: React.ReactNode;
  className?: string;
  cover?: boolean;
  center?: boolean;
  size?: string;
  disablePointerEvents?: boolean;
  coverParent?: boolean;
  marginBottom?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  pop?: boolean;
  color?: 'orange' | 'white';
  hideLoader?: boolean;
  isGlobalLoader?: boolean;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

// TODO: Later - combine <Loader /> and <PageLoader />?
// A good separation could be using <Loader /> in component, and then use <PageLoader /> in full page
class Loader extends React.Component<LoaderProps, {}> {
  public props: ConnectedProps & LoaderProps;
  render() {
    const { hideLoader, pop, isGlobalLoader, marginBottom, color, isModalSaving } = this.props;
    let className = mergeClassNames(namespace, this.props);
    if (pop) className += ' pop';

    const style: React.CSSProperties = {};
    if (marginBottom) style.marginBottom = `${marginBottom}px`;
    let loaderImage: string = LoaderImage;
    if (color === 'white') loaderImage = LoaderImageWhite;

    // If `isModalSaving` is set, we show a global page loader in App.tsx, and so
    // we shouldn't render anything in that case
    if (isModalSaving && !isGlobalLoader) return null;
    const loader = (
        <img
          onDragStart={e => e.preventDefault()}
          width={this.props.size || "44px"}
          height={this.props.size || "44px"}
          src={loaderImage}
          className={`${namespace}--image`}
          style={style}
        />
    );

    let disablePointerEvents;
    if (this.props.disablePointerEvents) {
      disablePointerEvents = (
        <div className={`${namespace}--backdrop`}/>
      );
    }

    if (this.props.cover) {
      return (
        <div className={className}>
          {disablePointerEvents}
          <div className={`${namespace}--cover`}/>
          <div className={`${namespace}--wrapper` + (this.props.center ? ` center` : '')}>
            {hideLoader ? null : loader}
          </div>
        </div>
      );
    }

    return (
      <div className={className}>
        {disablePointerEvents}
        {hideLoader ? null : loader}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    isModalSaving: state.app.isModalSaving,
  };
};
const mapDispatchToProps = (dispatch) => ({});
const ConnectedLoader = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<LoaderProps>(),
)(Loader);
export default ConnectedLoader;