import * as Actions from './actions';
import { Reducer } from 'redux';
import { isActionType } from '../../../../../../utils/StrongActions';

export type ParticipantTab = 'roster' | 'type' | 'classes' | 'confirmation';

export interface EventRegisterParticipantState {
  selectedPage: ParticipantTab;
  pendingPage?: ParticipantTab;
}
const getInitialState: () => EventRegisterParticipantState = () => ({selectedPage: 'roster'});

const EventRegisterParticipant: Reducer<EventRegisterParticipantState> = (state: EventRegisterParticipantState, action) => {
  if (isActionType(action, Actions.EventRegisterParticipantSelectPage)) {
    window.scrollTo(0,0);
    return {...state, selectedPage: action.selectedPage};
  } else if (isActionType(action, Actions.EventRegisterParticipantSetPendingPage)) {
    return {...state, pendingPage: action.pendingPage};
  } else if (isActionType(action, Actions.EventRegisterParticipantTransitionToPending)) {
    if (state.pendingPage) {
      window.scrollTo(0,0);
      return {...state, selectedPage: state.pendingPage, pendingPage: undefined};
    }
    return state;
  } else if (isActionType(action, Actions.EventRegisterParticipantReset)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default EventRegisterParticipant;
