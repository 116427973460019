import * as React from 'react';
import '../../../styles/elements/footer/index.scss';

import {namespace} from "./constants";

export interface FooterProps {
  children?: React.ReactNode;
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
  height?: number;
  leftGutter?: boolean;
  leftGutterSize?: number;
  handleCompact?: boolean;
  wide?: boolean;
  handleLeftContent?: boolean;  // set to true when use footer along with MainContant that has `leftContent`
  noPadding?: boolean;
}

const Footer: React.SFC<any> = ({ mobile, tablet, desktop, children, height, leftGutter, leftGutterSize, handleCompact, wide, handleLeftContent, noPadding }: FooterProps): React.ReactElement<FooterProps> => {
  let className = namespace();
  if (mobile || tablet || desktop) {
    className += ' hide-all-except';
    if (mobile) className += ' show-mobile';
    if (tablet) className += ' show-tablet';
    if (desktop) className += ' show-desktop';
  }

  if (handleCompact) className += ' handle-compact';

  const style: React.CSSProperties = {};
  if (height || height === 0) style.height = `${height}px`;

  return (
    <div className={className} style={style}>
      <div className={`${namespace()}--wrapper`} style={style}>
        {leftGutter && <div className={`${namespace()}--left-gutter ${wide ? 'wide' : ''} ${handleLeftContent ? 'handle-left-content' : ''}`} style={leftGutterSize ? {maxWidth: `${leftGutterSize}px`, flex: `1 0 ${leftGutterSize}px`} : {}} />}
        <div className={`${namespace()}--container ${wide ? 'wide' : ''}`}>
          <div className={`${namespace()}--content ${noPadding ? 'no-padding' : ''}`}>
            {children}
          </div>
        </div>
        {leftGutter && <div className={`${namespace()}--right-gutter ${wide ? 'wide' : ''} ${handleLeftContent ? 'handle-left-content' : ''}`} />}
      </div>
    </div>
  );
};

export default Footer;
export { default as FooterProgress } from './Progress';
export { default as FooterActions } from './Actions';
