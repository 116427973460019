import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import {
    actionCreators as adminEventsCacheTwoProductActionCreators, GetAdminEventProduct,
} from '../../../../../store/AdminEvents/CacheTwoProduct/actions';
import { actionCreators as appActionCreators, Actions as AppActions } from '../../../../../store/App/actions';
import { 
  actionCreators as rollbackActionCreators,
} from '../../../../../store/Rollback/actions';
import { actionCreators, AdminEventProductFormNamespace as namespace } from "../../../../../store/AdminEvents/Products/Form/actions";
import { SideModal, SideModalContent, SideModalHeader, Loader, SideModalActions, Button } from '../../../../../components/Elements';
import Form from './Form';
import '../../../../../styles/pages/admin-events/products/product/index.scss';
import { navPush } from '../../../../../utils';
import { NotFound } from '../../../../../components/Pages';
import { INVALID_PRODUCT } from '../../../../../constants/messages/adminEvents';
import { getProductsHomeRootUrl, constructAdminEventsUrlParams } from '../../../../../constants/adminEventsUrls';
import { makeSelectedGLAccountSelector } from '../../../../../store/AdminEvents/Products/Form';
import { checkAdminEventProductPermission } from '../../../../../utils/helpers/adminEventsPageHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { shouldReconfigRouter } from '../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { noOpenedModals } from '../../../../../utils/modalHelper';
import { reduxStoreService } from '../../../../../store/service';
import {isAdminEventsCacheTwoProductPopulated} from '../../../../../utils/cachePopulatedCheckers/adminEvents';
import { WithInertAttribute } from '../../../../Elements/WithInert';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> &RouteComponentProps<{}, {productId: string}>;

type Props = WithInertAttribute<{
  type: 'add' | 'edit';
  productId?: number;
  onDelete?: (item: any) => void;
  onBack?: () => void;
}>;
class ProductSideModal extends React.Component<Props, {}> {
  public props: ConnectedProps & Props;

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);
    this.configRouter();
  }

  componentDidUpdate(prevProps: ConnectedProps & Props){
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
  }

  configRouter() {
    const {router, routes} = this.props;
    router.setRouteLeaveHook(routes[routes.length - 1], this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    const {type, actions, adminEventsCacheTwoProduct} = this.props;

    if (reduxStoreService().getState().app.apiLoading === 0) {
      if (type === "add" || isAdminEventsCacheTwoProductPopulated(adminEventsCacheTwoProduct)) {
        actions.saveState();
      }
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentWillUnmount() {
    const {type, actions, adminEventsCacheTwoProduct} = this.props;

    if (type === "add" || isAdminEventsCacheTwoProductPopulated(adminEventsCacheTwoProduct)) {
      actions.clearAdminEventsCacheTwoProduct();
    }

    this.resetRouteLeaveHook();
  }

  onSave = () => {
    const {type, actions, router, adminEventsCacheOne} = this.props;

    checkAdminEventProductPermission(
      () => {
        actions.apiSubmitForm(router);
      },
      adminEventsCacheOne,
      type === 'edit',
    );
  };
  onDelete = () => {
    const {actions, router, routes, adminEventsCacheOne, formState: {ActiveForm}} = this.props;

    checkAdminEventProductPermission(
      () => {
        if (!ActiveForm.IDi) return;
        actions.deleteAdminEventProduct(ActiveForm.IDi, router, routes, true);
      },
      adminEventsCacheOne,
      true,
    );
  };
  onClickModal = (e) => {
    e && e.stopPropagation();
  };
  onBack = () => {
    if (this.props.onBack) {
      this.props.onBack();
      return;
    }
    const backUrl = getProductsHomeRootUrl(constructAdminEventsUrlParams(this.props, this.props.adminEventsCacheOne));
    this.props.actions.clearAdminEventsCacheTwoProduct();
    navPush(this.props.router, backUrl);
  };

  renderNotFound = () => {
    return (
      <NotFound showCMSSiteHeader inModal message={INVALID_PRODUCT}/>
    );
  };

  isDisabled = () => {
    const {type, adminEventsCacheOne} = this.props;

    if (!adminEventsCacheOne.EventsEventType) return;

    if (type === 'add') {
      return !adminEventsCacheOne.EventsEventType.Permissions.hasAddProduct;
    } else {
      return !adminEventsCacheOne.EventsEventType.Permissions.hasEditProduct;
    }
  };

  renderContent = (empty?: boolean) => {
    const { type, apiSaving, adminEventsCacheOne, selectedGLAccount, actions, cacheZero, formState, inert} = this.props;

    return [
      <SideModalContent lockBodyScroll key='admin-event-product-content'>
        {!empty && <Form
          inert={inert}
          cacheZero={cacheZero}
          productForm={formState}
          disabled={apiSaving > 0}
          reduxActions={actions}
          adminEventsCacheOne={adminEventsCacheOne}
          selectedGLAccount={selectedGLAccount}
          action={type}
        />}
      </SideModalContent>,
      empty ? null : <SideModalActions key='admin-event-product-actions'>
        {type === 'add' && <Button id={generateDOMId("tentaroo-admin-product-save-btn")} disabled={this.isDisabled()} flat textColor="black" onClick={this.onSave}>CREATE</Button>}
        {type === 'edit' && <Button id={generateDOMId("tentaroo-admin-product-save-btn")} disabled={this.isDisabled()} flat textColor="black" onClick={this.onSave}>SAVE</Button>}
        {type === 'edit' && <Button disabled={this.isDisabled()} flat textColor="red" onClick={this.onDelete}>DELETE</Button>}
      </SideModalActions>,
    ];
  };

  public render() {
    const {type, formState: {ActiveForm}, adminEventsCacheTwoProduct, adminEventsCacheOne, apiLoadingMap } = this.props;
    const loading = type === 'edit' && (
      !isAdminEventsCacheTwoProductPopulated(adminEventsCacheTwoProduct, ActiveForm) ||
      (isAdminEventsCacheTwoProductPopulated(adminEventsCacheTwoProduct, ActiveForm) && apiLoadingMap[GetAdminEventProduct.requestType])
    ) && noOpenedModals();
    const subtitle = adminEventsCacheOne.EventsEventType ? adminEventsCacheOne.EventsEventType.General.Name : '';

    let content;

    if (type === 'add') {
      content = this.renderContent();
    } else {
      if (!isAdminEventsCacheTwoProductPopulated(adminEventsCacheTwoProduct) && apiLoadingMap[GetAdminEventProduct.requestType]) {
        content = this.renderContent(true);
      } else if (!adminEventsCacheTwoProduct.Product || adminEventsCacheTwoProduct.Product.Inactive) {
        content = this.renderNotFound();
      } else if (adminEventsCacheTwoProduct.Product) {
        content = this.renderContent();
      }
    }

    return (
      <SideModal
        allowShadowClickDefault
        className={`${namespace()}`}
        onClick={this.onClickModal}
        stickyHeader
        header={<SideModalHeader
                  className={`${namespace()}--header`}
                  onBack={this.onBack}
                  subtitle={subtitle}
                  onlyShowSubTitleInMobile
                  title={type === 'add' ? 'New Product' : "Edit Product"} />}
        >
          {content}
        {loading ? <Loader className={`${namespace()}--loader`} center /> : null}
      </SideModal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedGLAccountSelector = makeSelectedGLAccountSelector();

  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    rollback: state.rollback,
    cacheZero: state.cacheZero,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    formState: state.adminEvents.products.form,
    adminEventsCacheTwoProduct: state.adminEvents.cacheTwoProduct,
    selectedGLAccount: selectedGLAccountSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...rollbackActionCreators,
    ...adminEventsCacheTwoProductActionCreators,
    ...appActionCreators,
  }, dispatch),
});
const ConnectedProductSideModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps & Props>(),
)(ProductSideModal);
export default withRouter<Props>(ConnectedProductSideModal);
