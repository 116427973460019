import * as React from 'react';
import {Main, MainContent} from '../../../../../../Layouts';
import {ContentBlock, RadioGroup, Tickets, Alert} from '../../../../../../Elements';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import {UserState} from '../../../../../../../store/User';
import {EventRegistrationParticipantsParticipantType} from "../../../../../../../models/api/cacheFourEvents";
import {CacheFourEventsParticipantsState} from "../../../../../../../store/CacheFourEventsParticipants";
import {CacheThreeEventsState} from "../../../../../../../store/CacheThreeEvents";
import {CacheTwoEventsState} from "../../../../../../../store/CacheTwoEvents";
import PricingInfo from '../../../PricingInfo';
import {
  EventInfo,
  EventInfoParticipantType,
  EventRegistrationPaymentStatus
} from "../../../../../../../models/api/cacheThreeEvents";
import {EventRegisterParticipantTypeState} from "../../../../../../../store/Events/Event/Register/Participant/Type";
import {NamesParticipantType} from "./NamesParticipantType";
import {Actions as AppActions} from "../../../../../../../store/App/actions";
import {Actions as RegisterOverrideFeeActions} from "../../../../../../../store/Events/Event/Modals/RegisterOverrideFee/actions";
import '../../../../../../../styles/pages/events/event/register/participant/type.scss';
import {EventClassIndividualAvailable, RegisteredClass} from "../../../../../../../models/class";
import {determineAvailability} from "../../../../../../../utils/eventsHelper";
import { captureTentarooError } from '../../../../../../../utils/dataHelper';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--event--register--participants--type';

type Props = WithInertAttribute<{
  participantId?: string;
  type: EventRegisterParticipantTypeState;
  pTypes: Array<EventRegistrationParticipantsParticipantType>;
  user: UserState;
  cacheFourEventsParticipants: CacheFourEventsParticipantsState;
  cacheThreeEvents: CacheThreeEventsState;
  cacheTwoEvents: CacheTwoEventsState;
  cacheThreePTypes: Array<EventInfoParticipantType>;
  onSelect: (pTypeID: number) => void;
  actions: AppActions & RegisterOverrideFeeActions;
  apiLoading: number;
  availableClasses: Array<EventClassIndividualAvailable>;
  conflictingClasses: Array<EventClassIndividualAvailable>;
  registeredClasses: Array<RegisteredClass>;
}>;

export default class Type extends React.Component<{}, {}> {
  public props: Props;

  componentWillMount() {
    const {pTypes, onSelect, participantId} = this.props;
    if (pTypes.length === 1) {
      let quantity : number | undefined;
      if (!participantId) { // add
        quantity = this.getQuantity(0);
      }
      if (participantId || quantity === undefined || quantity > 0) {
          onSelect(pTypes[0].ID);
      }
    }
  }

  getQuantity = (index: number) => {
    const {pTypes, participantId} = this.props;
    const EventInfo = this.props.cacheThreeEvents.EventInfo as EventInfo;
    let quantity : number | undefined;
    let cacheThreePType = this.props.cacheThreePTypes.find((t) => t.ID === pTypes[index].ID);
    if (!cacheThreePType) {
      captureTentarooError(new Error(
        `Could not find Participant Type from cache three: ${pTypes[index].ID}. In Participant.tsx`
      ));
      return;
    }
    if (!participantId) { // add
      quantity = determineAvailability(
        EventInfo.NumEventSpotsAvailablePending,
        cacheThreePType.NumEventSpotsAvailablePending,
        EventInfo.MaxParticipants,
        cacheThreePType.MaxParticipants,
        true
      );
    } else {
      quantity = determineAvailability(
        EventInfo.NumEventSpotsAvailablePending + 1,
        cacheThreePType.NumEventSpotsAvailablePending + 1,
        EventInfo.MaxParticipants,
        cacheThreePType.MaxParticipants,
        true
      );
    }
    return quantity;
  };


  mapPTypes = (pt: EventRegistrationParticipantsParticipantType, index: number) => {
    const {cacheThreeEvents: {EventRegistrationPaymentStatus}, cacheThreePTypes, user, actions, type, participantId} = this.props;
    const EventInfo = this.props.cacheThreeEvents.EventInfo as EventInfo;
    let cacheThreePType = cacheThreePTypes.find((t) => t.ID === pt.ID);
    if (!cacheThreePType) {
      captureTentarooError(new Error(
        `Could not find Participant Type from cache three: ${pt.ID}. In Participant.tsx`
      ));
      return;
    }
    let mergedPType = pt;
    if (type.overwrittenTypes[pt.ID]) {
      mergedPType = {
        ...pt,
        Amount: type.overwrittenTypes[pt.ID].Amount,
        AmountMin: type.overwrittenTypes[pt.ID].AmountMin
      };
    }

    let quantity : number | undefined = this.getQuantity(index);

    return <NamesParticipantType
      cacheFourEventsParticipants={this.props.cacheFourEventsParticipants}
      selected={this.props.type.selectedTypeID === pt.ID}
      EventInfo={EventInfo as EventInfo}
      EventRegistrationPaymentStatus={EventRegistrationPaymentStatus as EventRegistrationPaymentStatus}
      pType={mergedPType}
      pTypeC3={cacheThreePType}
      isAdmin={!!user.user.str_permissions.hasAdminAccess}
      actions={actions}
      quantity={quantity}
      isAdding
      onSelect={this.props.onSelect}
    />;
  };

  public render() {
    const {
      availableClasses, registeredClasses, apiLoading, user, cacheTwoEvents, cacheThreeEvents, cacheFourEventsParticipants, inert,
      participantId, cacheThreePTypes, pTypes, type, actions, conflictingClasses
    } = this.props;
    const EventInfo = cacheThreeEvents.EventInfo as EventInfo;
    let c3SingleSelectedPType, singleSelectedPType, singleMergedPType; // only set this in edit mode

    if (!cacheFourEventsParticipants.EventRegistrationPerson) return null;
    if (participantId) {
      c3SingleSelectedPType = cacheThreePTypes.find((t) => t.ID === type.selectedTypeID);
      singleSelectedPType = pTypes.find((t) => t.ID === type.selectedTypeID);
      singleMergedPType = singleSelectedPType;
      if (singleMergedPType) {
        if (type.overwrittenTypes[singleSelectedPType.ID]) {
          singleMergedPType = {
            ...singleSelectedPType,
            Amount: type.overwrittenTypes[singleSelectedPType.ID].Amount,
            AmountMin: type.overwrittenTypes[singleSelectedPType.ID].AmountMin
          };
        }
      }
    }
    const NumEventSpotsAvailablePending = (cacheThreeEvents.EventInfo as EventInfo).NumEventSpotsAvailablePending;
    let quantity : number | undefined;
    let ticketsText: string = '';
    let isRed : boolean | undefined;
    if (NumEventSpotsAvailablePending !== null) {
      let numEventSpotsAvailable = NumEventSpotsAvailablePending;
      if (participantId) { // editing case
        numEventSpotsAvailable += 1;
        quantity = this.getQuantity(0);
      }
      if (numEventSpotsAvailable <= 0) {
        ticketsText = `FULL`;
        isRed = true;
      } else {
        ticketsText = `${numEventSpotsAvailable} spot${numEventSpotsAvailable !== 1 ? 's' : ''} available`;
      }
    }
    const loading = apiLoading > 0;

    return (
      <Main
        inert={inert}
        key="participants"
        mobileBackground="white"
        header={<HeaderProgress
          availableClasses={availableClasses}
          conflictingClasses={conflictingClasses}
          registeredClasses={registeredClasses}
          selected="type"
          cacheTwoEvents={cacheTwoEvents}
          cacheFourEventsParticipants={cacheFourEventsParticipants}
          loading={false}
        />}
        footer={<FooterProgress
          selected="type"
          availableClasses={availableClasses}
          conflictingClasses={conflictingClasses}
          registeredClasses={registeredClasses}
        />}
      >
        <MainContent loading={loading}>
          <ContentBlock>
            {type.SubmitErrorMessage && <Alert className={`${namespace()}--alert`} noFlex>{type.SubmitErrorMessage}</Alert>}
            <PricingInfo EventInfo={cacheThreeEvents.EventInfo}/>
            {cacheThreeEvents.EventInfo && cacheThreeEvents.EventInfo.MaxParticipants > 0 && (cacheFourEventsParticipants.EventRegistrationPerson.InCart || !participantId) &&
              <Tickets text={ticketsText} strong red={isRed}/>}
            <RadioGroup>
              {!singleMergedPType && pTypes.map(this.mapPTypes)}
              {singleMergedPType && <NamesParticipantType
                cacheFourEventsParticipants={this.props.cacheFourEventsParticipants}
                selected
                EventInfo={cacheThreeEvents.EventInfo as EventInfo}
                EventRegistrationPaymentStatus={cacheThreeEvents.EventRegistrationPaymentStatus as EventRegistrationPaymentStatus}
                pType={singleMergedPType}
                pTypeC3={c3SingleSelectedPType}
                actions={actions}
                isAdmin={!!user.user.str_permissions.hasAdminAccess}
                quantity={quantity}
                hideRadio
                InCart={cacheFourEventsParticipants.EventRegistrationPerson.InCart}
                onSelect={this.props.onSelect}
              />}
            </RadioGroup>
          </ContentBlock>
        </MainContent>
      </Main>
    );
  }
}
