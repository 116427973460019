import type {CMSContact, CMSPage, CMSPageMenuItem, CMSResource, CMSSite, CMSSiteMenuItem} from "../../models/api/adminCMSCacheOne";
import type {AdminCMSSiteCacheOneState} from "../../store/AdminCMSSite/CacheOne";
import type {AdminCMSSiteCacheTwoContactsState} from "../../store/AdminCMSSite/CacheTwoContacts";
import type {AdminCMSSiteCacheTwoPageMenuItemsState} from "../../store/AdminCMSSite/CacheTwoPageMenus";
import type {AdminCMSSiteCacheTwoPageState} from "../../store/AdminCMSSite/CacheTwoPages";
import type {AdminCMSSiteCacheTwoResourcesState} from "../../store/AdminCMSSite/CacheTwoResources";
import type {AdminCMSSiteCacheTwoSiteMenuItemsState} from "../../store/AdminCMSSite/CacheTwoSiteMenus";
import type {ContactActiveForm} from "../../store/AdminCMSSite/Contacts/Contact/Form";
import type {SiteMenuItemActiveForm} from "../../store/AdminCMSSite/Menus/SiteMenuItem/Form";
import type {PageActiveForm} from "../../store/AdminCMSSite/Pages/Page/Form";
import type {ResourceActiveForm} from "../../store/AdminCMSSite/Resources/Resource/Form";
import type {GeneralSettingsActiveForm} from "../../store/AdminCMSSite/Settings/General";
import {reduxStoreService} from "../../store/service";
import {isCacheLevelPopulated} from "./common";

const MIN_REQUIRED_FIELDS_CMS_RESOURCE = 0;
const MIN_REQUIRED_FIELDS_CMS_PAGE = 0;
const MIN_REQUIRED_FIELDS_CMS_PAGE_MENU_ITEM = 0;
const MIN_REQUIRED_FIELDS_CMS_PAGE_SITE_ITEM = 0;
const MIN_REQUIRED_FIELDS_CMS_CONTACT = 0;
const MIN_REQUIRED_FIELDS_CMS_CACHE_ONE = 0;

// Cache One
export function isAdminCMSCacheOnePopulated(
  adminCMSCacheOne?: AdminCMSSiteCacheOneState,
  generalSettingsForm?: GeneralSettingsActiveForm,
) {
  const rootState = reduxStoreService().getState();
  const cacheOne = adminCMSCacheOne ? adminCMSCacheOne : rootState.adminCMSSite.cacheOne;
  return isCacheLevelPopulated<CMSSite>(
    cacheOne.CMSSite,
    MIN_REQUIRED_FIELDS_CMS_CACHE_ONE,
    () => !generalSettingsForm || (!!cacheOne.CMSSite && generalSettingsForm.ID === cacheOne.CMSSite.ID),
  );
};

// Cache Two
export function isAdminCMSCacheTwoResourcePopulated(cacheTwoResource: AdminCMSSiteCacheTwoResourcesState, resourceForm?: ResourceActiveForm) {
  return isCacheLevelPopulated<CMSResource>(
    cacheTwoResource.CMSResource,
    MIN_REQUIRED_FIELDS_CMS_RESOURCE,
    () => !resourceForm || (!!cacheTwoResource.CMSResource && resourceForm.ID === cacheTwoResource.CMSResource.ID)
  );
};

export function isAdminCMSCacheTwoContactPopulated(cacheTwoContact: AdminCMSSiteCacheTwoContactsState, contactForm?: ContactActiveForm) {
  return isCacheLevelPopulated<CMSContact>(
    cacheTwoContact.CMSContact,
    MIN_REQUIRED_FIELDS_CMS_CONTACT,
    () => !contactForm || (!!cacheTwoContact.CMSContact && contactForm.ID === cacheTwoContact.CMSContact.ID),
  );
};

export function isAdminCMSCacheTwoPagePopulated(cacheTwoPage: AdminCMSSiteCacheTwoPageState, pageForm?: PageActiveForm) {
  return isCacheLevelPopulated<CMSPage>(
    cacheTwoPage.CMSPage,
    MIN_REQUIRED_FIELDS_CMS_PAGE,
    () => !pageForm || !!(cacheTwoPage.CMSPage && pageForm.ID === cacheTwoPage.CMSPage.ID),
  );
};

export function isAdminCMSCacheTwoSiteMenuItemPopulated(cacheTwoSiteMenuItem: AdminCMSSiteCacheTwoSiteMenuItemsState, siteMenuForm?: SiteMenuItemActiveForm) {
  return isCacheLevelPopulated<CMSSiteMenuItem>(
    cacheTwoSiteMenuItem.CMSSiteMenuItem,
    MIN_REQUIRED_FIELDS_CMS_PAGE_SITE_ITEM,
    () => !siteMenuForm || (!!cacheTwoSiteMenuItem.CMSSiteMenuItem && siteMenuForm.ID === cacheTwoSiteMenuItem.CMSSiteMenuItem.ID),
  );
};

export function isAdminCMSCacheTwoPageMenuItemPopulated(cacheTwoPageMenuItem: AdminCMSSiteCacheTwoPageMenuItemsState, pageMenuForm?: SiteMenuItemActiveForm) {
  return isCacheLevelPopulated<CMSPageMenuItem>(
    cacheTwoPageMenuItem.CMSPageMenuItem,
    MIN_REQUIRED_FIELDS_CMS_PAGE_MENU_ITEM,
    () => !pageMenuForm || (!!cacheTwoPageMenuItem.CMSPageMenuItem && pageMenuForm.ID === cacheTwoPageMenuItem.CMSPageMenuItem.ID),
  );
};