import * as React from 'react';
import { default as TextField , TextFieldProps } from '../TextField';

export interface TextAreaProps extends TextFieldProps {
}

const TextArea: React.SFC<TextAreaProps> = (props: TextAreaProps): React.ReactElement<TextAreaProps> => (
  <TextField {...props}/>
);

TextArea.defaultProps = {
  rows: 3
};

export default TextArea;
