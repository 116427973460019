import { createSelector } from "reselect";

import { AdminFacilityType } from "../../../../models/api/adminFacilitiesCacheOne";
import { facilityTypesSelector } from "../../Facilities/Home";
import { reduxStoreService } from "../../../service";


export const makeActiveFacilityTypesSelector = () => {
  return createSelector(
    [facilityTypesSelector],
    (facilityTypes: AdminFacilityType[]) => {
      if (facilityTypes) {
        const rootState = reduxStoreService().getState();
        const actives = facilityTypes.filter((ft) => !ft.Inactive);
        const result: any[] = [];
        actives.forEach((ft) => {
          const facilitiesNum = rootState.adminFacilityLocation.cacheOne.Facilities ? rootState.adminFacilityLocation.cacheOne.Facilities.filter((f) => !f.Inactive && f.FacilityTypeID === ft.ID).length : 0;
          if (facilitiesNum > 0) {
            result.push(ft);
          }
        });

        return result;
      }
      return [];
    }
  );
};