import { CacheOneContext, CacheThreeFacilitiesContext } from "@tentaroo/shared";

import { CacheTwoFacilitiesState } from "../../store/CacheTwoFacilities";
import { safelySetEmptyCart } from "../cacheLoaders/cacheLoaderHelpers";
import CacheManager from "./cacheManager";
import { Location } from "history";
import { CacheTwoEventsState } from "../../store/CacheTwoEvents";
import { CacheTwoBFacilitiesState } from "../../store/CacheTwoBFacilities";
import { CacheTwoRosterState } from "../../store/CacheTwoRoster";
import { CacheTwoPrevOrderState } from "../../store/CacheTwoPrevOrders";
import { CacheThreePrevOrderState } from "../../store/CacheThreePrevOrders";
import { CacheThreeEventsState } from "../../store/CacheThreeEvents";
import { CacheFourEventsProductsState } from "../../store/CacheFourEventsProducts";
import { CacheFourEventsParticipantsState } from "../../store/CacheFourEventsParticipants";
import { CacheFourEventsViewParticipantState } from "../../store/CacheFourEventsViewParticipant";
import { CacheFourEventsNumbersState } from "../../store/CacheFourEventsNumbers";
import { CacheFourFacilitiesState } from "../../store/CacheFourFacilities";
import { CacheThreeFacilitiesState } from "../../store/CacheThreeFacilities";
import { PreviousOrder } from "../../models/api/cacheTwoPrevOrders";
import { FAC_TRIP_RESERVATION, getEventData, getEventTypeData, getFacilityAvailabilitiesUrl, getFacilityLocationUrl, getFacilityTripData, getFacilityTripDataBody, getGroupCache, getGroupRosterCache, getGroupRosterPerson, getGroupRosterPersonBody, getPrevOrderBody, getPrevOrdersUrl, getPrevOrderUrl, isAdminModulePage } from "../../constants/urls";
import { CacheThreeRosterState } from "../../store/CacheThreeRoster";
import { CacheOneState } from "../../store/CacheOne";
import { ClearAllEndUserCacheButOptions, SetEmptyCart } from "../../store/App/actions";
import { ClearCacheBelowTwoPrevOrders } from "../../store/CacheTwoPrevOrders/actions";
import { ManageOrderReset } from "../../store/Settings/PrevOrders/ManageOrder/actions";
import { AuditLogReset } from "../../store/Settings/PrevOrders/AuditLog/actions";
import { RefundOrFeeReset } from "../../store/Settings/PrevOrders/RefundOrFee/actions";
import { ClearCacheBelowTwoRoster } from "../../store/CacheTwoRoster/actions";
import { Reset } from "../../store/Settings/Roster/Add/actions";
import { ClearCacheBelowOne } from "../../store/CacheOne/actions";
import { FacilitiesLocationReset } from "../../store/Facilities/Location/actions";
import { ClearCacheBelowTwoFacilities } from "../../store/CacheTwoFacilities/actions";
import { TripResetForm } from "../../store/Facilities/Trip/Form/actions";
import { ViewReservationReset } from "../../store/Facilities/Trip/ViewReservation/actions";
import { TripSummaryReset } from "../../store/Facilities/Trip/Summary/actions";
import { EventTypeReset } from "../../store/Events/EventType/actions";
import { ClearCacheBelowTwoEvents } from "../../store/CacheTwoEvents/actions";
import { EventReset } from "../../store/Events/Event/Main/actions";
import { EventsEventRegistrationReset } from "../../store/Events/Event/Registration/actions";
import { FacilitiesFacilityReset } from "../../store/Facilities/Facility/actions";
import { RouteComponentProps } from "react-router";
import { getGroupIDFromUrl, IBFacilityRouterParams, IEventParticipantRouterParams, IEventRouterParams, IEventTypeRouterParams, IFacilityCacheFourRouterParams, IFacilityRouterParams, IFacilityTypeRouterParams, IPrevOrderRouterParams, IPrevOrdersRouterParams, IRosterRouterParams } from "../helpers/endUserPageHelper";
import { ensureCacheTwoPrevOrders } from "../cacheLoaders/cacheTwoPrevOrdersLoader";
import { ensureCacheThreePrevOrder } from "../cacheLoaders/cacheThreePrevOrderLoader";
import { ensureCacheTwoRoster, getCacheTwoRosterEmptyCartBody } from "../cacheLoaders/cacheTwoRosterLoader";
import { ensureCacheThreeRoster } from "../cacheLoaders/cacheThreeRosterLoader";
import { ensureCacheOne, getCacheOneEmptyCartBody } from "../cacheLoaders/cacheLoaders";
import { ensureCacheTwoEvents, getCacheTwoEventsEmptyCartBody } from "../cacheLoaders/cacheTwoEventsLoader";
import { ensureCacheThreeEvents, getCacheThreeEventsEmptyCartBody } from "../cacheLoaders/cacheThreeEventsLoader";
import { setEmptyCartForCacheFourEventsViewParticipant, ensureCacheFourEventsViewParticipant } from "../cacheLoaders/cacheFourEventsViewParticipantLoader";
import { ClearCacheBelowThreeEvents } from "../../store/CacheThreeEvents/actions";
import { ensureCacheTwoFacilities, getCacheTwoFacilitiesEmptyCartBody } from "../cacheLoaders/cacheTwoFacilitiesLoader";
import { ensureCacheThreeFacilities } from "../cacheLoaders/cacheThreeFacilitiesLoader";
import { ensureCacheTwoBFacilities, getCacheTwoBFacilitiesEmptyCartBody } from "../cacheLoaders/cacheTwoBFacilitiesLoader";
import { EventRegisterNumbersReset } from "../../store/Events/Event/Register/Numbers/Main/actions";
import { ensureCacheFourEventsNumbers } from "../cacheLoaders/cacheFourEventsNumbersLoader";
import { EventRegisterParticipantReset } from "../../store/Events/Event/Register/Participant/Main/actions";
import { ensureCacheFourEventsParticipants } from "../cacheLoaders/cacheFourEventsParticipantsLoader";
import { EventRegisterProductsReset } from "../../store/Events/Event/Register/Products/Main/actions";
import { ensureCacheFourEventsProducts } from "../cacheLoaders/cacheFourEventsProductsLoader";
import { FacilitiesReservationReset } from "../../store/Facilities/Trip/Reservation/actions";
import { ensureCacheFourFacilities } from "../cacheLoaders/cacheFourFacilitiesLoader";
import { FullGroup } from "../../models/api/options";
import { ensureCacheZero } from "../cacheLoaders/cacheZeroLoader";
import { reduxStoreService } from "../../store/service";
import { LoadEndUserCacheFourEventParticipantsParams, LoadCacheZeroParams, LoadEndUserCacheFourEventProductParams, LoadEndUserCacheOneParams, LoadEndUserCacheThreeEventParams, LoadEndUserCacheThreePrevOrderParams, LoadEndUserCacheThreeRosterParams, LoadEndUserCacheTwoBFacilityParams, LoadEndUserCacheTwoEventParams, LoadEndUserCacheTwoFacilityParams, LoadEndUserCacheTwoRosterParams, LoadEndUserCacheTwoPrevOrdersParams, LoadEndUserCacheFourViewParticipantParams, LoadEndUserCacheFourEventNumbersParams, LoadEndUserCacheFourFacilityParams, LoadEndUserCacheThreeFacilityParams, LoadCancelRegistrationParams, LoadCancelReservationParams } from "../cacheLoaders/helpers/models";
import { makeSelectedReservationSelector } from "../../store/Facilities/Trip/ViewReservation";
import { ensureCancelRegistration } from "../cacheLoaders/cancelRegistrationLoader";
import { ensureCancelReservation } from "../cacheLoaders/cancelReservationLoader";
import {captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload} from "../dataHelper";
import {isIdFromUrlValid} from "../urlHelper";
import {isCacheFourEventsNumbersPopulated, isCacheFourEventsParticipantsPopulated, isCacheFourEventsProductsPopulated, isCacheFourEventsViewParticipantPopulated, isCacheFourFacilitiesPopulated, isCacheThreeEventsPopulated, isCacheThreeFacilitiesPopulated, isCacheThreePrevOrdersPopulated, isCacheThreeRosterPopulated, isCacheTwoBFacilitiesPopulated, isCacheTwoPrevOrdersPopulated, isCacheTwoRosterPopulated, isCacheZeroPopulated, isEndUserCacheOnePopulated, isEndUserCacheTwoEventsPopulated, isEndUserCacheTwoFacilitiesPopulated, isEndUserCacheTwoPopulated} from "../cachePopulatedCheckers/endUser";

const getCachePrevOrdersEmptyCartBody = (params: { id: string }) => {
  const rootState = reduxStoreService().getState();

  let debugPayload: TentarooDebugPayload | undefined;

  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when getCachePrevOrdersEmptyCartBody");
  }

  if (!rootState.cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when getCachePrevOrdersEmptyCartBody");
  }
  const body: any = getPrevOrderBody({
    GroupIDi: rootState.cacheZero.options?.Group?.IDi as any,
    GroupTS: rootState.cacheZero.options?.Group?.TS as any,
    GetOptions: false,
    GetGroupData: true
  });
  body.AppState.EmptyCart = true;

  // Only validate and set OrderID when in cacheThreePrevOrders
  if (
    isCacheThreePrevOrdersPopulated(rootState.cacheThreePrevOrders)
  ) {
    if (!isIdFromUrlValid(params.id)) {
      debugPayload = captureTentarooErrorAndGetRequestPayload("Invalid id in getCachePrevOrdersEmptyCartBody");
    }
    body.OrderID = Number(params.id);
  }

  if (debugPayload) {
    return withTentarooDebugPayload(body, debugPayload);
  } else {
    return body;
  }
};

const getCacheThreeRosterEmptyCartBody = (params: IRosterRouterParams) => {
  const isYouth = params.ya === 'youth';
  const rootState = reduxStoreService().getState();

  let debugPayload: TentarooDebugPayload | undefined;

  // ONLY need to validate person id from url when editting
  if (
    isCacheThreeRosterPopulated(rootState.cacheThreeRoster) &&
    !isIdFromUrlValid(params.id)
  ) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Invalid id in getCacheThreeRosterEmptyCartBody");
  }
  const PersonIDi = Number(params.id);

  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when getCacheThreeRosterEmptyCartBody");
  }

  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when getCacheThreeRosterEmptyCartBody");
  }

  if (!rootState.cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Goup not available when getCacheThreeRosterEmptyCartBody");
  }
  const body: any = getGroupRosterPersonBody({
    PersonIDi,
    GroupIDi: rootState.cacheZero.options?.Group?.IDi as any,
    GroupTS: rootState.cacheZero.options?.Group?.TS as any,
    IsYouth: isYouth,
    GetOptions: false,
    GetGroupData: true
  });
  body.AppState.EmptyCart = true;

  if (debugPayload) {
    return withTentarooDebugPayload(body, debugPayload);
  } else {
    return body;
  }
};

const getCacheThreeFacilitiesEmptyCartBody = (params: { locId: string; tripId: string; name: string; }) => {
  const rootState = reduxStoreService().getState();

  let debugPayload: TentarooDebugPayload | undefined;

  // ONLY need to validate trip id from url when editing
  if (
    !isIdFromUrlValid(params.locId) ||
    isCacheThreeFacilitiesPopulated(rootState.cacheThreeFacilities) && !isIdFromUrlValid(params.tripId)
  ) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Invalid locationId OR tripId in getCacheThreeFacilitiesEmptyCartBody");
  }
  const locationId = Number(params.locId);
  const tripId = Number(params.tripId);

  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when getCacheThreeFacilitiesEmptyCartBody");
  }

  if (!rootState.cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when getCacheThreeFacilitiesEmptyCartBody");
  }
  const body: any = getFacilityTripDataBody({
    GroupIDi: rootState.cacheZero.options?.Group?.IDi as any,
    GroupTS: rootState.cacheZero.options?.Group?.TS as any,
    FacilityLocationID: locationId,
    GetOptions: false,
    GetGroupData: true,
    FacilityTripID: tripId,
    GetFacilityLocationData: false,
    GetFacilityTripData: false
  });
  body.AppState.EmptyCart = true;

  if (debugPayload) {
    return withTentarooDebugPayload(body, debugPayload);
  } else {
    return body;
  }
};
export default class EndUserCacheManager extends CacheManager {
  private static _instance: EndUserCacheManager;

  public static getInstance(): EndUserCacheManager {
    if (!this._instance) {
      this._instance = new EndUserCacheManager();
    }

    return this._instance;
  }

  constructor() {
    super();
  }

  public loadCacheZero(params: LoadCacheZeroParams): boolean {
    const {props, isStateNavigated} = params;
    if (this.cacheZeroNeedsReload(props, isStateNavigated)) {
      return ensureCacheZero(params);
    }

    return false;
  };

  private cacheZeroNeedsReload(props: RouteComponentProps<{}, {}>, isStateNavigated: boolean) {
    const rootState = reduxStoreService().getState();

    const needsReload = (
      !isCacheZeroPopulated(rootState.cacheZero) ||
      isStateNavigated
    );

    if (needsReload) {
      // Will always clear all end user cache when navigating to ADD_GROUP page and 
      // there is end user cache populated, because QP_ACCOUNT will be undefined in 
      // URL, and hence be marked as invalid
      this.isPopulatedGroupInvalid(props.location);
    }

    return needsReload;
  }

  // Cache One
  public isPopulatedGroupInvalid(location: Location) {
    const rootState = reduxStoreService().getState();

    // TODO: combine GroupIDi setup with `getGroupID`
    const { userType, IDi } = rootState.user.user;
    const GroupIDi = userType === "admin" ? getGroupIDFromUrl(location) : IDi;
    const Group = rootState.cacheZero.options ? rootState.cacheZero.options.Group : undefined;

    const isInvalid = this._isPopulatedCacheInvalid<FullGroup>(
      Group,
      (group) => group.IDi,
      `${GroupIDi}`,
    );

    if (isInvalid && isEndUserCacheOnePopulated(rootState.cacheOne)) {
      reduxStoreService().dispatch(new ClearAllEndUserCacheButOptions());
    }

    return isInvalid;
  };

  public loadCacheOne(
    params: LoadEndUserCacheOneParams,
  ): boolean {
    const {isStateNavigated, props} = params;
    // Only SetEmptyCart when first navigating to the any of the cache one pages
    // but not due to a rollback
    if (isStateNavigated) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }
    if (this.cacheOneNeedsReload(props) || isStateNavigated) {
      return ensureCacheOne(params);
    }

    return false;
  };

  public cacheOneNeedsReload(props: RouteComponentProps<{}, {}>) {
    const rootState = reduxStoreService().getState();

    // TODO: combine GroupIDi setup with `getGroupID`
    const { userType, IDi } = rootState.user.user;
    const GroupIDi = userType === "admin" ? getGroupIDFromUrl(props.location) : IDi;

    const needsReload =
      (
        this.isPopulatedGroupInvalid(props.location) ||
        // We still need to check `GroupIDi` here because `cacheOneNeedsReload` is being called in admin side loaders
        (!!GroupIDi && GroupIDi > 0 && !isEndUserCacheOnePopulated(rootState.cacheOne))
      );

    /**
     * Manually ClearCacheBelowOne when we are at admin pages. Not counting ADD_GROUP, end user cache will always be cleared there.
     * In this case, we need to clear everything except cacheOne for EndUser.
     */
    if (
      !needsReload && 
      isAdminModulePage(props.location.pathname) &&
      isEndUserCacheOnePopulated(rootState.cacheOne) &&
      isEndUserCacheTwoPopulated()
    ) {
      reduxStoreService().dispatch(new ClearCacheBelowOne());
    }

    return needsReload;
  };

  // Cache Two Facilities
  private isPopulatedCacheTwoFacilitiesInvalid(cacheTwoFacilities: CacheTwoFacilitiesState, id: string, location: Location) {
    if (this.isPopulatedGroupInvalid(location)) return true;

    const isInvalid = this._isPopulatedCacheInvalid<CacheTwoFacilitiesState>(
      cacheTwoFacilities,
      (cache) => cache.locationID,
      id,
    );

    if (isInvalid) {
      reduxStoreService().dispatch(new FacilitiesLocationReset());
      reduxStoreService().dispatch(new ClearCacheBelowOne());
    }

    return isInvalid;
  };

  public loadCacheTwoFacilities(
    params: LoadEndUserCacheTwoFacilityParams
  ): boolean {
    const {isStateNavigated, props} = params;
    // Only SetEmptyCart when first navigating to any cacheTwoFacilities pages
    // but not due to a rollback
    if (isStateNavigated) {
      safelySetEmptyCart(getFacilityLocationUrl, getCacheTwoFacilitiesEmptyCartBody);
    }
    if (this.cacheTwoFacilitiesNeedsReload(props) || isStateNavigated) {
      return ensureCacheTwoFacilities({
        ...params,
        cacheManager: this,
      });
    }

    return false;
  };

  private cacheTwoFacilitiesNeedsReload(
    props: RouteComponentProps<IFacilityTypeRouterParams, {}>,
  ) {
    const cacheTwoFacilities = reduxStoreService().getState().cacheTwoFacilities;
    return (
      this.isPopulatedCacheTwoFacilitiesInvalid(cacheTwoFacilities, props.params.id, props.location) ||
      !isEndUserCacheTwoFacilitiesPopulated(cacheTwoFacilities)
    );
  }

  // Cache Two Events
  private isPopulatedCacheTwoEventsInvalid(cacheTwoEvents: CacheTwoEventsState, id: string, location: Location){
    if (this.isPopulatedGroupInvalid(location)) return true;
    const isInvalid = this._isPopulatedCacheInvalid<CacheTwoEventsState>(
      cacheTwoEvents,
      (cache) => cache.eventTypeID,
      id,
    );

    if (isInvalid) {
      reduxStoreService().dispatch(new EventTypeReset());
      reduxStoreService().dispatch(new ClearCacheBelowOne());
    }

    return isInvalid;
  };

  public loadCacheTwoEvent(
    params: LoadEndUserCacheTwoEventParams
  ): boolean {
    const {isStateNavigated, props} = params;
    const rootState = reduxStoreService().getState();
    // Only SetEmptyCart when first navigating to any cacheTwoEvents pages
    // but not due to a rollback
    if (isStateNavigated) {
      safelySetEmptyCart(getEventTypeData, getCacheTwoEventsEmptyCartBody);
    }

    if (this.cacheTwoEventsNeedsReload(
      rootState.cacheTwoEvents,
      props,
    ) || isStateNavigated) {
      return ensureCacheTwoEvents(params);
    }

    return false;
  };

  private cacheTwoEventsNeedsReload(
    cacheTwoEvents: CacheTwoEventsState,
    props: RouteComponentProps<IEventTypeRouterParams, {}>,
  ) {
    return (
      this.isPopulatedCacheTwoEventsInvalid(cacheTwoEvents, props.params.id, props.location) ||
      !isEndUserCacheTwoEventsPopulated(cacheTwoEvents)
    );
  }

  // Cache Two BFacilities
  private isPopulatedCacheTwoBFacilitiesInvalid(
    cacheTwoFacilities: CacheTwoFacilitiesState,
    cacheTwoBFacilities: CacheTwoBFacilitiesState,
    params: { id: string, facId: string, ftbId: string | undefined | null, facName: string },
    location: Location,
  ){
    if (this.isPopulatedCacheTwoFacilitiesInvalid(
      cacheTwoFacilities,
      params.id,
      location,
    )) return true;
    const isInvalid =
      (
        this._isPopulatedCacheInvalid<CacheTwoBFacilitiesState>(
          cacheTwoBFacilities,
          (cache) => cache.facilityID,
          params.facId,
        )
      ) || (
        params.ftbId && this._isPopulatedCacheInvalid<CacheTwoBFacilitiesState>(
          cacheTwoBFacilities,
          (cache) => cache.ftbID,
          params.ftbId,
        )
      ) || (
        !params.ftbId && cacheTwoBFacilities.ftbID
      );

    if (isInvalid) {
      reduxStoreService().dispatch(new FacilitiesFacilityReset());
      reduxStoreService().dispatch(new ClearCacheBelowTwoFacilities());
    }

    return Boolean(isInvalid);
  };

  public loadCacheTwoBFacilities(
    params: LoadEndUserCacheTwoBFacilityParams,
  ): boolean{
    const {isStateNavigated, props} = params;
    const rootState = reduxStoreService().getState();
    // Only SetEmptyCart when first navigating to any cacheTwoBFacilities pages
    // but not due to a rollback
    if (isStateNavigated) {
      safelySetEmptyCart(getFacilityAvailabilitiesUrl, getCacheTwoBFacilitiesEmptyCartBody);
    }

    if (this.cacheTwoBFacilitiesNeedsReload(
      rootState.cacheTwoFacilities,
      rootState.cacheTwoBFacilities,
      props,
    ) || isStateNavigated) {
      return ensureCacheTwoBFacilities(params);
    }

    return false;
  };

  private cacheTwoBFacilitiesNeedsReload(
    cacheTwoFacilities: CacheTwoFacilitiesState,
    cacheTwoBFacilities: CacheTwoBFacilitiesState,
    props: RouteComponentProps<IBFacilityRouterParams, {}>,
  ) {
    return (
      this.isPopulatedCacheTwoBFacilitiesInvalid(cacheTwoFacilities, cacheTwoBFacilities, props.params, props.location) ||
      !isCacheTwoBFacilitiesPopulated(cacheTwoBFacilities)
    );
  }

  // Cache Two Roster
  private isPopulatedCacheTwoRosterInvalid(location: Location) {
    if (this.isPopulatedGroupInvalid(location)) return true;

    return false;
  }

  public loadCacheTwoRoster(
    params: LoadEndUserCacheTwoRosterParams,
  ): boolean {
    const {props, isStateNavigated} = params;
    const rootState = reduxStoreService().getState();
    // Only SetEmptyCart when first navigating to any cacheTwoRoster pages
    // but not due to a rollback
    if (isStateNavigated) {
      safelySetEmptyCart(getGroupRosterCache, getCacheTwoRosterEmptyCartBody);
    }
    if (this.cacheTwoRosterNeedsReload(
      rootState.cacheTwoRoster,
      props,
    ) || isStateNavigated) {
      return ensureCacheTwoRoster(params);
    }

    return false;
  };

  private cacheTwoRosterNeedsReload(
    cacheTwoRoster: CacheTwoRosterState,
    props: RouteComponentProps<{}, {}>,
  ) {
    return (
      this.isPopulatedCacheTwoRosterInvalid(props.location) ||
      !isCacheTwoRosterPopulated(cacheTwoRoster)
    );
  }

  // Cache Three Roster
  private isPopulatedCacheThreeRosterInvalid(
    cacheThreeRoster: CacheThreeRosterState,
    location: Location,
    id: string,
    ya: string,
  ) {
    if (this.isPopulatedCacheTwoRosterInvalid(location)) return true;
    const isInvalid = cacheThreeRoster.GroupRosterPerson &&
        (cacheThreeRoster.GroupRosterPerson.IDi !== Number(id) ||
        (cacheThreeRoster.GroupRosterPerson.IsYouth ? "youth" : "adult") !== ya);

    if (isInvalid) {
      // @todo - later, should use ClearCache action to clear all...
      reduxStoreService().dispatch(new ClearCacheBelowTwoRoster());
      reduxStoreService().dispatch(new Reset());
    }

    return Boolean(isInvalid);
  };

  public loadCacheThreeRoster(
    params: LoadEndUserCacheThreeRosterParams,
  ): boolean {
    const {props, isStateNavigated, isEdit} = params;
    const rootState = reduxStoreService().getState();
    // Only SetEmptyCart when first navigating to any cacheThreeRosters pages
    // but not due to a rollback
    if (isStateNavigated) {
      safelySetEmptyCart(getGroupRosterPerson, getCacheThreeRosterEmptyCartBody);
    }

    if (this.cacheThreeRosterNeedsReload(
      rootState.cacheThreeRoster,
      props,
      isEdit,
    ) || isStateNavigated) {
      return ensureCacheThreeRoster({
        ...params,
        cacheManager: this,
      });
    }

    return false;
  };

  private cacheThreeRosterNeedsReload(
    cacheThreeRoster: CacheThreeRosterState,
    props: RouteComponentProps<IRosterRouterParams, {}>,
    isEdit: boolean,
  ) {
    return (
      this.isPopulatedCacheThreeRosterInvalid(cacheThreeRoster, props.location, props.params.id, props.params.ya) ||
      (isEdit && !isCacheThreeRosterPopulated(cacheThreeRoster))
    );
  }

  // Cache Two PrevOrders
  public loadCacheTwoPrevOrders(
    params: LoadEndUserCacheTwoPrevOrdersParams,
  ): boolean {
    const {props, isStateNavigated} = params;
    const rootState = reduxStoreService().getState();
    // Only SetEmptyCart when first navigating to any cacheTwoPrevOrders pages
    // but not due to a rollback
    if (isStateNavigated) {
      safelySetEmptyCart(getPrevOrdersUrl, getCachePrevOrdersEmptyCartBody);
    }

    if (
      this.cachePrevOrdersNeedsReload(rootState.cacheTwoPrevOrders, props) ||
      isStateNavigated
    ) {
      return ensureCacheTwoPrevOrders(params);
    }

    return false;
  };

  private cachePrevOrdersNeedsReload(
    cacheTwoPrevOrders: CacheTwoPrevOrderState,
    props: RouteComponentProps<IPrevOrdersRouterParams, {}>,
  ) {
    return (
      this.isPopulatedCacheTwoPrevOrdersInvalid(cacheTwoPrevOrders, props.location) ||
      !isCacheTwoPrevOrdersPopulated(cacheTwoPrevOrders)
    );
  }

  private isPopulatedCacheTwoPrevOrdersInvalid(cacheTwoPrevOrders: CacheTwoPrevOrderState, location: Location) {
    if (this.isPopulatedGroupInvalid(location)) {
      // @todo - later, should use ClearCache action to clear all...
      reduxStoreService().dispatch(new ManageOrderReset());
      reduxStoreService().dispatch(new AuditLogReset());
      reduxStoreService().dispatch(new RefundOrFeeReset());
      return true;
    }
    return false;
  };

  // Cache Three PrevOrder
  private isPopulatedCacheThreePrevOrdersInvalid(
    cacheThreePrevOrders: CacheThreePrevOrderState,
    id: string,
    location: Location
  ) {
    const rootState = reduxStoreService().getState();

    if (this.isPopulatedCacheTwoPrevOrdersInvalid(rootState.cacheTwoPrevOrders, location)) return true;

    const isInvalid = (
      this._isPopulatedCacheInvalid<PreviousOrder>(
        cacheThreePrevOrders.PreviousOrder,
        (order) => order.OrderID,
        id,
      )
    );

    if (isInvalid) {
      // @todo - later, should use ClearCache action to clear all...
      reduxStoreService().dispatch(new ManageOrderReset());
      reduxStoreService().dispatch(new AuditLogReset());
      reduxStoreService().dispatch(new RefundOrFeeReset());
      reduxStoreService().dispatch(new ClearCacheBelowTwoPrevOrders());
    }

    return isInvalid;
  };

  public loadCacheThreePrevOrder(
    params: LoadEndUserCacheThreePrevOrderParams
  ): boolean {
    const {props, isStateNavigated} = params;
    const rootState = reduxStoreService().getState();

    // Only SetEmptyCart when first navigating to any cacheThreePrevOrder pages
    // but not due to a rollback
    if (isStateNavigated) {
      safelySetEmptyCart(getPrevOrderUrl, getCachePrevOrdersEmptyCartBody);
    }

    if (
      this.cacheThreePrevOrderNeedsReload(
        rootState.cacheThreePrevOrders,
        props,
      ) || isStateNavigated
    ) {
      return ensureCacheThreePrevOrder(params);
    }

    return false;
  };

  private cacheThreePrevOrderNeedsReload(
    cacheThreePrevOrders: CacheThreePrevOrderState,
    props: RouteComponentProps<IPrevOrderRouterParams, {}>,
  ) {
    return (
      this.isPopulatedCacheThreePrevOrdersInvalid(cacheThreePrevOrders, props.params.id, props.location) ||
      !isCacheThreePrevOrdersPopulated(cacheThreePrevOrders)
    );
  }

  // Cache Three Events
  public loadCacheThreeEvent(
    params: LoadEndUserCacheThreeEventParams
  ): boolean {
    const {props, isStateNavigated} = params;
    const rootState = reduxStoreService().getState();

    // Only SetEmptyCart when first navigating to any cacheThreeEvents pages
    // but not due to a rollback
    if (isStateNavigated) {
      safelySetEmptyCart(getEventData, getCacheThreeEventsEmptyCartBody);
    }
    if (this.cacheThreeEventsNeedsReload(
      rootState.cacheTwoEvents,
      rootState.cacheThreeEvents,
      props.params.eventTypeId,
      props.params.eventId,
      props
    ) || isStateNavigated) {
      return ensureCacheThreeEvents(params);
    }

    return false;
  };

  private isPopulatedCacheThreeEventsInvalid(
    cacheTwoEvents: CacheTwoEventsState,
    cacheThreeEvents: CacheThreeEventsState,
    eventTypeId: string,
    eventId: string,
    location: Location,
  ) {
    if (this.isPopulatedCacheTwoEventsInvalid(
      cacheTwoEvents,
      eventTypeId,
      location,
    )) return true;
    const isInvalid = this._isPopulatedCacheInvalid<CacheThreeEventsState>(
      cacheThreeEvents,
      (cache) => cache.eventID,
      eventId,
    );

    if (isInvalid) {
      reduxStoreService().dispatch(new EventTypeReset());
      reduxStoreService().dispatch(new EventReset());
      reduxStoreService().dispatch(new EventsEventRegistrationReset());
      reduxStoreService().dispatch(new ClearCacheBelowTwoEvents());
    }

    return isInvalid;
  };

  private cacheThreeEventsNeedsReload(
    cacheTwoEvents: CacheTwoEventsState,
    cacheThreeEvents: CacheThreeEventsState,
    eventTypeId: string,
    eventId: string,
    props: RouteComponentProps<IEventRouterParams, {}>,
  ) {
    return (
      this.isPopulatedCacheThreeEventsInvalid(
        cacheTwoEvents,
        cacheThreeEvents,
        eventTypeId,
        eventId,
        props.location,
      ) ||
      !isCacheThreeEventsPopulated(cacheThreeEvents)
    );
  }

  // Cache Three Facilities
  private isPopulatedCacheThreeFacilitiesInvalid(
    cacheTwoFacilities: CacheTwoFacilitiesState,
    cacheThreeFacilities: CacheThreeFacilitiesState,
    locId: string,
    tripId: string,
    location: Location
  ) {
    if (this.isPopulatedCacheTwoFacilitiesInvalid(cacheTwoFacilities, locId, location)) return true;
    const isInvalid = this._isPopulatedCacheInvalid<CacheThreeFacilitiesState>(
      cacheThreeFacilities,
      (cache) => cache.tripID,
      tripId,
    );

    if (isInvalid) {
      // @todo - later, should use ClearCache action to clear all...
      reduxStoreService().dispatch(new ClearCacheBelowTwoFacilities());
      reduxStoreService().dispatch(new TripResetForm());
      reduxStoreService().dispatch(new ViewReservationReset());
      reduxStoreService().dispatch(new TripSummaryReset());
      reduxStoreService().dispatch(new FacilitiesLocationReset());
    }

    return isInvalid;
  };

  public loadCacheThreeFacilities(
    params: LoadEndUserCacheThreeFacilityParams
  ): boolean {
    const {props, isEdit, isStateNavigated} = params;
    // Non-optimistic saving - Only run loader if editing or isStateNavigated.
    // Prevent invalid check from running after add request, will have cache data
    // but be at the wrong URL before redirecting.
    if (!isEdit && !isStateNavigated) return false;

    const rootState = reduxStoreService().getState();

    // NOTE: Special case compared to other SetEmptyCart, we need data to be loaded before
    // we run set empty cart
    if (isStateNavigated && isCacheThreeFacilitiesPopulated(rootState.cacheThreeFacilities)) {
      let postNavigate: string | undefined;
    
      const rootState = reduxStoreService().getState();
      const selectedReservationSelector = makeSelectedReservationSelector();
      const selectedReservation = selectedReservationSelector(rootState);
      // If emptying a cart in a reservation, and the reservation is NOT finalized,
      // we need to redirect away after emptying the cart because that reservation will be deleted
      if (selectedReservation) {
        if (!selectedReservation.WasFinalized) {
          postNavigate = rootState.routing.locationBeforeTransitions.pathname.split(`/${FAC_TRIP_RESERVATION}/`)[0] + reduxStoreService().getState().routing.locationBeforeTransitions.search;
        }
      }
      safelySetEmptyCart(getFacilityTripData, getCacheThreeFacilitiesEmptyCartBody, postNavigate);
    }
    if (this.cacheThreeFacilitiesNeedsReload(
      rootState.cacheTwoFacilities,
      rootState.cacheThreeFacilities,
      props,
      isEdit,
    ) || isStateNavigated) {
      return ensureCacheThreeFacilities({
        ...params,
        cacheManager: this,
      });
    }

    return false;
  };

  private cacheThreeFacilitiesNeedsReload(
    cacheTwoFacilities: CacheTwoFacilitiesState,
    cacheThreeFacilities: CacheThreeFacilitiesState,
    props: RouteComponentProps<IFacilityRouterParams, {}>,
    isEdit: boolean,
  ) {
    return (
      this.isPopulatedCacheThreeFacilitiesInvalid(
        cacheTwoFacilities,
        cacheThreeFacilities,
        props.params.locId,
        props.params.tripId,
        props.location,
      ) ||
      (isEdit && !isCacheThreeFacilitiesPopulated(cacheThreeFacilities))
    );
  }

  // Cache Four Events Product
  public loadCacheFourEventsProducts(
    params: LoadEndUserCacheFourEventProductParams,
  ): boolean {
    const {props, isStateNavigated} = params;
    if (this.cacheFourEventsProductsNeedsReload(props) || isStateNavigated) {
      return ensureCacheFourEventsProducts(params);
    }

    return false;
  };

  private cacheFourEventsProductsNeedsReload(
    props: RouteComponentProps<IEventRouterParams, {}>,
  ) {
    const rootState = reduxStoreService().getState();
    return (
      this.isCacheFourEventsProductsInvalid(props) ||
      !isCacheFourEventsProductsPopulated(rootState.cacheFourEventsProducts)
    );
  }

  private isCacheFourEventsProductsInvalid(
    props: RouteComponentProps<IEventRouterParams, {}>,
  ) {
    const rootState = reduxStoreService().getState();
    const isCacheThreeEventsInvalid = this.isPopulatedCacheThreeEventsInvalid(
      rootState.cacheTwoEvents,
      rootState.cacheThreeEvents,
      props.params.eventTypeId,
      props.params.eventId,
      props.location,
    );

    if (isCacheThreeEventsInvalid) {
      reduxStoreService().dispatch(new EventRegisterProductsReset());
    }

    return isCacheThreeEventsInvalid;
  };

  // Cache Four Events Participants
  private isCacheFourEventsParticipantsInvalid(
    cacheTwoEvents: CacheTwoEventsState,
    cacheThreeEvents: CacheThreeEventsState,
    eventTypeId: string,
    eventId: string,
    location: Location,
  ) {
    const isCacheThreeEventsInvalid = this.isPopulatedCacheThreeEventsInvalid(
      cacheTwoEvents,
      cacheThreeEvents,
      eventTypeId,
      eventId,
      location,
    );

    if (isCacheThreeEventsInvalid) {
      reduxStoreService().dispatch(new EventRegisterParticipantReset());
    }

    return isCacheThreeEventsInvalid;
  };

  public loadCacheFourEventsParticipants(
    params: LoadEndUserCacheFourEventParticipantsParams,
  ): boolean {
    const {props, isStateNavigated} = params;
    if (this.cacheFourEventsParticipantsNeedsReload(props) || isStateNavigated) {
      return ensureCacheFourEventsParticipants(params);
    }

    return false;
  };

  private cacheFourEventsParticipantsNeedsReload(
    props: RouteComponentProps<IEventParticipantRouterParams, {}>,
  ) {
    const rootState = reduxStoreService().getState();
    return (
      this.isCacheFourEventsParticipantsInvalid(
        rootState.cacheTwoEvents,
        rootState.cacheThreeEvents,
        props.params.eventTypeId,
        props.params.eventId,
        props.location,
      ) ||
      !isCacheFourEventsParticipantsPopulated(rootState.cacheFourEventsParticipants)
    );
  }

  // Cache Four Participant
  private isPopulatedCacheFourEventsViewParticipantInvalid(
    cacheTwoEvents: CacheTwoEventsState,
    cacheThreeEvents: CacheThreeEventsState,
    cacheFourEventsViewParticipant: CacheFourEventsViewParticipantState,
    eventTypeId: string,
    eventId: string,
    ya: string,
    pId: string,
    location: Location,
  ) {
    if (this.isPopulatedCacheThreeEventsInvalid(
      cacheTwoEvents,
      cacheThreeEvents,
      eventTypeId,
      eventId,
      location,
    )) return true;

    const isInvalid = (
      cacheFourEventsViewParticipant.participantIDi !== 0 && (
        (cacheFourEventsViewParticipant.isYouth && ya === 'a') ||
        (!cacheFourEventsViewParticipant.isYouth && ya === 'y') ||
        cacheFourEventsViewParticipant.participantIDi !== Number(pId)
      )
    );

    if (isInvalid) {
      reduxStoreService().dispatch(new ClearCacheBelowThreeEvents());
    }

    return isInvalid;
  };

  public loadCacheFourEventsViewParticipant(
    params: LoadEndUserCacheFourViewParticipantParams,
  ): boolean {
    const {props, isStateNavigated} = params;
    const rootState = reduxStoreService().getState();
    // NOTE: Special case compared to other SetEmptyCart, we need data to be loaded before
    // we run set empty cart
    if (isStateNavigated && isCacheFourEventsViewParticipantPopulated(rootState.cacheFourEventsViewParticipant)) {
      setEmptyCartForCacheFourEventsViewParticipant(props);
    }
    
    if (this.cacheFourEventsViewParticipantNeedsReload(
      rootState.cacheTwoEvents,
      rootState.cacheThreeEvents,
      rootState.cacheFourEventsViewParticipant,
      props,
    ) || isStateNavigated) {
      return ensureCacheFourEventsViewParticipant(params);
    }

    return false;
  };

  private cacheFourEventsViewParticipantNeedsReload(
    cacheTwoEvents: CacheTwoEventsState,
    cacheThreeEvents: CacheThreeEventsState,
    cacheFourEventsViewParticipant: CacheFourEventsViewParticipantState,
    props: RouteComponentProps<IEventParticipantRouterParams, {}>,
  ) {
    return (
      this.isPopulatedCacheFourEventsViewParticipantInvalid(
        cacheTwoEvents,
        cacheThreeEvents,
        cacheFourEventsViewParticipant,
        props.params.eventTypeId,
        props.params.eventId,
        props.params.ya,
        props.params.pId,
        props.location,
      ) ||
      !isCacheFourEventsViewParticipantPopulated(cacheFourEventsViewParticipant)
    );
  }

  // Cache Four Events Numbers
  public loadCacheFourEventsNumbers(
    params: LoadEndUserCacheFourEventNumbersParams,
  ): boolean {
    const {props, isStateNavigated} = params;
    if (this.cacheFourEventsNumbersNeedsReload(props) || isStateNavigated) {
      return ensureCacheFourEventsNumbers(params);
    }

    return false;
  };

  private isPopulatedCacheFourEventsNumbersInvalid(
    props: RouteComponentProps<IEventRouterParams, {}>,
  ) {
    const rootState = reduxStoreService().getState();
    const isCacheThreeEventsInvalid = this.isPopulatedCacheThreeEventsInvalid(
      rootState.cacheTwoEvents,
      rootState.cacheThreeEvents,
      props.params.eventTypeId,
      props.params.eventId,
      props.location,
    );

    if (isCacheThreeEventsInvalid) {
      reduxStoreService().dispatch(new EventRegisterNumbersReset());
    }

    return isCacheThreeEventsInvalid;
  };

  private cacheFourEventsNumbersNeedsReload(
    props: RouteComponentProps<IEventRouterParams, {}>,
  ) {
    const rootState = reduxStoreService().getState();
    return (
      this.isPopulatedCacheFourEventsNumbersInvalid(props) ||
      !isCacheFourEventsNumbersPopulated(rootState.cacheFourEventsNumbers)
    );
  }

  // Cache Four Facilities
  private isPopulatedCacheFourFacilitiesInvalid(
    props: RouteComponentProps<IFacilityCacheFourRouterParams, {}>,
  ) {
    const rootState = reduxStoreService().getState();
    const isCacheInvalid = this.isPopulatedCacheThreeFacilitiesInvalid(
      rootState.cacheTwoFacilities,
      rootState.cacheThreeFacilities,
      props.params.locId,
      props.params.tripId,
      props.location,
    );

    if (isCacheInvalid) {
      reduxStoreService().dispatch(new FacilitiesReservationReset());
    }

    return isCacheInvalid;
  };

  public loadCacheFourFacilities(
    params: LoadEndUserCacheFourFacilityParams,
  ): boolean {
    const {props, isStateNavigated} = params;
    if (this.cacheFourFacilitiesNeedsReload(props) || isStateNavigated) {
      return ensureCacheFourFacilities(params);
    }

    return false;
  };

  private cacheFourFacilitiesNeedsReload(
    props: RouteComponentProps<IFacilityCacheFourRouterParams, {}>,
  ) {
    const rootState = reduxStoreService().getState();
    return (
      this.isPopulatedCacheFourFacilitiesInvalid(props) ||
      !isCacheFourFacilitiesPopulated(rootState.cacheFourFacilities)
    );
  }

  public loadCancelRegistration(
    params: LoadCancelRegistrationParams,
  ) {
    const {isStateNavigated} = params;

    if (isStateNavigated) {
      ensureCancelRegistration(params);
    }
  }

  public loadCancelReservation(
    params: LoadCancelReservationParams,
  ) {
    const {isStateNavigated} = params;

    if (isStateNavigated) {
      ensureCancelReservation(params);
    }
  }
}