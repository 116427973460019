import * as React from 'react';
import {default as AppHeader} from '../../../../../App/Header';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import {bindActionCreators} from 'redux';
import {EVENT, URLS} from "../../../../../../constants/urls";
import {
  campsiteBack,
  productsBack,
  showCampsite,
  showClasses
} from "../../../../../../store/Events/Event/Register/Numbers/Main/uiHelpers";
import {actionCreators} from "../../../../../../store/Events/Event/Register/Numbers/Main/actions";
import {
  actionCreators as classesActionCreators,
} from "../../../../../../store/Events/Event/Register/Numbers/Classes/actions";
import {
  actionCreators as productsActionCreators,
} from "../../../../../../store/Events/Event/Register/Numbers/Products/actions";
import {
  actionCreators as campsiteActionCreators,
} from "../../../../../../store/Events/Event/Register/Numbers/Campsite/actions";
import {navPush} from "../../../../../../utils/navHelper";
import {
  makeAddedNumbersProductsFilter
} from "../../../../../../store/CacheFourEventsNumbers/index";
import {makeSelectedEventSelector} from "../../../../../../store/CacheThreeEvents";
import {makeSelectedEventTypeSelector} from "../../../../../../store/CacheTwoEvents";
import {EventType} from "../../../../../../models/api/cacheOne";
import {EventTypeEvent} from "../../../../../../models/api/cacheTwoEvents";
import {displayTimeFrame} from "../../../../../../utils/dateHelper";
import {
  makePreviousNumbersProductsFilter,
  makeRegisteredClassesFilter
} from "../../../../../../store/CacheFourEventsNumbers";
import { isAdminSelector } from '../../../../../../utils/permissionHelper';
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';

interface HeaderSpec {
  added: boolean;
  addedLength?: number;
  search: boolean;
  onBack: () => void;
  add?: boolean;
  mobileAdd?: boolean;
  onClickAdd?: () => void;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string}>;

const getHeaderSpecs = (props: ConnectedProps): HeaderSpec => {
  const {numbers, actions, router, params, cacheFourEventsNumbers, cacheTwoEvents, registeredClasses, addedProducts, isAdmin, previousProducts} = props;
  if (numbers.selectedPage === 'spots') {
    let prevLocation = `${URLS.EVENTS}/${params.eventTypeId}/${params.eventId}/${params.name}/${EVENT.REGISTRATION}`;
    if (props.previousLocation) prevLocation = props.previousLocation.pathname;

    return {
      onBack: () => navPush(router, prevLocation),
      added: false,
      search: false
    };
  } else if (numbers.selectedPage === 'classes') {
    return {
      onBack: () => actions.classesSubmit('spots', false),
      added: true,
      addedLength: registeredClasses ? registeredClasses.length : 0,
      search: true
    };
  } else if (numbers.selectedPage === 'products') {
    let addedLength = 0;
    if (addedProducts) addedLength += addedProducts.length;
    if (previousProducts) addedLength += previousProducts.length;
    return {
      onBack: () => actions.productsSubmit(productsBack(cacheTwoEvents, cacheFourEventsNumbers), false),
      added: true,
      addedLength: addedLength,
      search: true
    };
  } else if (numbers.selectedPage === 'campsite_ranking' || numbers.selectedPage === "campsite_assignment") {
    return {
      onBack: () => actions.campsiteSubmit(campsiteBack(cacheFourEventsNumbers), false),
      added: false,
      search: false,
      add: numbers.selectedPage === "campsite_assignment",
      mobileAdd: numbers.selectedPage === "campsite_assignment",
      onClickAdd: () => actions.addCampsiteAssignment(),
    };
  } else {
    let onBackClick;
    if (showCampsite(cacheTwoEvents)) onBackClick = () => actions.selectPage('campsite_ranking');
    else if (isAdmin) {
      onBackClick = () => actions.selectPage('campsite_assignment');
    }
    else if (showClasses(cacheFourEventsNumbers)) onBackClick = () => actions.selectPage('classes');
    else onBackClick = () => actions.selectPage('spots');
    return {
      onBack: onBackClick,
      added: false,
      search: false
    };
  }
};

export default ():any => {
  const NumbersWizardHeader = (props: ConnectedProps): React.ReactElement<{}> => {
    const specs = getHeaderSpecs(props);
    
    return <AppHeader
      showDrawer
      added={specs.added}
      addedLength={specs.added ? specs.addedLength : undefined}
      add={specs.add}
      mobileAdd={specs.mobileAdd}
      onClickAdd={specs.onClickAdd}
      search={specs.search}
      mobileSearch={specs.search}
      title="Event Registration"
      subTitle={makeSubtitle(props.selectedEvent as EventTypeEvent, props.selectedEventType as EventType)}
      onBack={specs.onBack}
    />;
  };

  const ConnectedNumbersWizardHeader = connect(
    mapStateToProps,
    mapDispatchToProps,
    getMergeProps<WithRouterProps>(),
  )(NumbersWizardHeader);

  return withRouter<{}>(ConnectedNumbersWizardHeader);
};

export const makeSubtitle = (selectedEvent: EventTypeEvent, selectedEventType: EventType) => {
  if (!selectedEvent || !selectedEventType) return '';
  return `${selectedEvent.Name}, ${selectedEventType.Name} @ ${selectedEventType.DefaultLocation.Name} ${displayTimeFrame(selectedEvent.StartDateTime, selectedEvent.EndDateTime)}`;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedEvent = makeSelectedEventSelector();
  const selectedEventType = makeSelectedEventTypeSelector();
  const registeredClasses = makeRegisteredClassesFilter();
  const addedProducts = makeAddedNumbersProductsFilter();
  const previousProducts = makePreviousNumbersProductsFilter();
  return {
    cacheTwoEvents: state.cacheTwoEvents,
    cacheFourEventsNumbers: state.cacheFourEventsNumbers,
    numbers: state.events.event.register.numbers.main,
    previousLocation: state.app.previousLocation,
    addedProducts: addedProducts(state),
    previousProducts: previousProducts(state),
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    registeredClasses: registeredClasses(state),
    selectedEvent: selectedEvent(state),
    selectedEventType: selectedEventType(state),
    isAdmin: isAdminSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...classesActionCreators,
  ...productsActionCreators,
  ...campsiteActionCreators
}, dispatch)});
