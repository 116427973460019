import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import * as M from '../../../../../constants/messages/generic';
import { ApplicationState } from "../../../../../store";
import { PageTypeIDValue, SiteEventCategory } from "../../../../../models/api/adminCMSCacheOne";
import { getDistrictsValues, getEventCategoryIDValues } from "../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/validation";
import * as moment from 'moment';
import { makeSelectedEventCategorySelector } from ".";
import { makeDeletedPrefixItemMapper } from "../../../../../utils/dataHelper";
import { SelectValidator, Validator } from "../../../../../utils/validator/models";


export interface IValidator {
  PageTypeID: Validator;
  Name: Validator;
  ShortURL: Validator;

  // Blog Articles
  StartDate: Validator;
  EndDate: Validator;

  // District
  DistrictIDi: SelectValidator;

  // Event Module
  EventTypeID: SelectValidator;
  IsPrimaryEventPage: Validator;
  ShowRegistrationLinks: Validator;
  IncludeModuleContacts: Validator;

  // Facility Module
  LocationID: Validator;

  // Calendar Event
  EventCategoryID: SelectValidator;
  IsAllDay: Validator;
  StartHour: SelectValidator;
  StartMin: SelectValidator;
  StartPeriod: SelectValidator;
  EndHour: SelectValidator;
  EndMin: SelectValidator;
  EndPeriod: SelectValidator;
}

const isPageType = (rootState: ApplicationState, targetPageType: PageTypeIDValue) => {
  return rootState.adminCMSSite.pages.modals.newPage.ActiveForm.PageTypeID === targetPageType;
};

export const getEventTypeValues = (rootState: ApplicationState) => {
  return rootState.cacheZero.options ? (rootState.cacheZero.options.EventTypes || []) : [];
};

const getTimePickerHourValues = () => {
  const max = 12;
  const result: any[] = [];

  for (let i = 0; i < max; i++) {
    result.push({
      ID: i + 1,
      Name: (i + 1).toString(),
    });
  }

  return result;
};

export const getTimePickerHourValidationRule = (key: string, isRequired?: () => boolean): SelectValidator => {
  return {
    key,
    isRequired: isRequired ? isRequired : () => false,
    options: {
      values: () => [...getTimePickerHourValues()],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
    },
    defaultValue: () => 12,
    validatejs: {
      [key]: {
        presence: {message: '^' + M.REQUIRED},
        // NOTE: There's no blank option, so setting numericality to undefined
        numericality: undefined,
      },
    },
  };
};

const getTimePickerMinValues = () => {
  const max = 60;
  const result: any[] = [];


  for (let i = 0; i < max; i++) {
    result.push({
      ID: i,
      Name: i < 10 ? `0${i}` : `${i}`,
    });
  }

  return result;
};

export const getTimePickerMinValidationRule = (key: string, isRequired?: () => boolean): SelectValidator => {
  return {
    key,
    isRequired: isRequired ? isRequired : () => false,
    options: {
      values: () => [...getTimePickerMinValues()],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
    },
    defaultValue: () => 0,
    validatejs: {
      [key]: {
        presence: {message: '^' + M.REQUIRED},
        // NOTE: There's no blank option, so setting numericality to undefined
        numericality: undefined,
      },
    },
  };
};

export const getTimePickerPeriodValidationRule = (key: string, isRequired?: () => boolean): SelectValidator => {
  return {
    key,
    isRequired: isRequired ? isRequired : () => false,
    options: {
      values: () => [{ID: 0, Name: 'AM'}, {ID: 1, Name: 'PM'}],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
    },
    defaultValue: () => 0,
    validatejs: {
      [key]: {
        presence: {message: '^' + M.REQUIRED},
        // NOTE: There's no blank option, so setting numericality to undefined
        numericality: undefined,
      },
    },
  };
};

export const FormDefinition: IValidator = {
  PageTypeID: {
    key: 'PageTypeID',
    isRequired: () => true,
    localDependants: ['EventCategoryID'],
  },
  Name: {
    key: 'Name',
    isRequired: () => true,
    validatejs: {
      Name: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 200,
          tooLong: M.MAX_LENGTH(200)
        },
      },
    },
  },
  ShortURL: {
    key: 'ShortURL',
    isRequired: () => false,
    defaultValue: () => '',
    customizedRegexOnly: /^[a-z0-9A-Z\-_]+$/,
    validatejs: {
      ShortURL: {
        format: {
          pattern: "[a-z0-9A-Z\-_]+",
          flags: "i",
          message: '^' + M.INVALID,
        },
        length: {
          maximum: 20,
          tooLong: M.MAX_LENGTH(20)
        },
      },
    },
  },
  StartDate: {
    key: 'StartDate',
    isRequired: (rootState) => isPageType(rootState, PageTypeIDValue.BLOG_ARTICLE) || isPageType(rootState, PageTypeIDValue.CALENDAR_EVENT),
    defaultValue: (rootState) => {
      if (isPageType(rootState, PageTypeIDValue.BLOG_ARTICLE)) {
        return moment();
      } else {
        return null;
      }
    },
    validatejs: {
      StartDate: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          notValid: `^${M.INVALID_DATE}`,
        }
      },
    },
  },
  EndDate: {
    key: 'EndDate',
    isRequired: () => false,
    defaultValue: () => null,
    validatejs: {
      EndDate: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          notValid: `^${M.INVALID_DATE}`,
        }
      },
    },
  },
  DistrictIDi: {
    key: 'DistrictIDi',
    // required when in district OR Calendar Event && IsDistricts category is selected
    isRequired: (rootState) => {
      const selectedCategorySelector = makeSelectedEventCategorySelector();
      const selectedCategory = selectedCategorySelector(rootState) as SiteEventCategory;
      return isPageType(rootState, PageTypeIDValue.DISTRICT) || (isPageType(rootState, PageTypeIDValue.CALENDAR_EVENT) && selectedCategory && selectedCategory.IsDistricts);
    },
    options: {
      values: (rootState) => [{IDi: 0, Name: ''}, ...getDistrictsValues(rootState)],
      valueKey: (v) => v.IDi,
      labelKey: 'Name',
      emptyValue: 0,
    },
    validatejs: {
      DistrictIDi: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        }
      },
    },
  },
  EventTypeID: {
    key: 'EventTypeID',
    // required when in Event Registration Module
    isRequired: (rootState) => isPageType(rootState, PageTypeIDValue.EVENT_MODULES),
    customValidate: (rootState) => {
      const EventTypeID = rootState.adminCMSSite.pages.modals.newPage.ActiveForm.EventTypeID;

      if (EventTypeID) {
        const eventType = rootState.cacheZero.options && rootState.cacheZero.options.EventTypes ? rootState.cacheZero.options.EventTypes.find((e) => e.ID === EventTypeID) : null;

        if (eventType && eventType.Inactive) {
          return M.SELECTED_DROPDOWN_ITEM_DELETED;
        }
      }

      return undefined;
    },
    options: {
      values: (rootState) => {
        const values = getEventTypeValues(rootState);
        return [
          {ID: 0, Name: ''},
          ...values
          .filter((et) => !et.Inactive || (et.Inactive && rootState.adminCMSSite.pages.modals.newPage.ActiveForm.EventTypeID === et.ID))
          .map(makeDeletedPrefixItemMapper('Name'))
        ];
      },
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: 0,
    },
    validatejs: {
      EventTypeID: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        }
      },
    },
  },
  IsPrimaryEventPage: {
    key: 'IsPrimaryEventPage',
    defaultValue: () => false,
  },
  ShowRegistrationLinks: {
    key: 'ShowRegistrationLinks',
    defaultValue: () => false,
  },
  IncludeModuleContacts: {
    key: 'IncludeModuleContacts',
    defaultValue: () => false,
  },
  LocationID: {
    key: 'LocationID',
    // required when in facility OR calendar event
    isRequired: (rootState) => isPageType(rootState, PageTypeIDValue.FACILITY_MODULES) || isPageType(rootState, PageTypeIDValue.CALENDAR_EVENT),
    customValidate: (rootState) => {
      if (!(isPageType(rootState, PageTypeIDValue.FACILITY_MODULES) || isPageType(rootState, PageTypeIDValue.CALENDAR_EVENT))) return;
      const form = rootState.adminCMSSite.pages.modals.newPage.ActiveForm;
      if (!form.LocationID) {
        return M.INVALID_SELECTED_LOCATION;
      } else {
        const location = rootState.cacheZero.options && rootState.cacheZero.options.Locations ? rootState.cacheZero.options.Locations.find((l) => l.ID === form.LocationID) : null;

        if (location && (location as any).Inactive) {
          return M.INVALID_SELECTED_LOCATION;
        }
      }
    },
    validatejs: {
      LocationID: {
        presence: {message: '^' + M.INVALID_SELECTED_LOCATION},
      },
    },
  },
  EventCategoryID: {
    key: 'EventCategoryID',
    isRequired: (rootState) => isPageType(rootState, PageTypeIDValue.CALENDAR_EVENT),
    customValidate: (rootState) => {
      const EventCategoryID = rootState.adminCMSSite.pages.modals.newPage.ActiveForm.EventCategoryID;

      if (EventCategoryID) {
        const siteEventCategory = rootState.adminCMSSite.cacheOne.SiteEventCategories ? rootState.adminCMSSite.cacheOne.SiteEventCategories.find((c) => c.ID === EventCategoryID) : null;

        if (siteEventCategory && siteEventCategory.Inactive) {
          return M.SELECTED_DROPDOWN_ITEM_DELETED;
        }
      }
    },
    options: {
      values: (rootState) => {
        const values = getEventCategoryIDValues(rootState);
        
        return [
          {ID: 0, NameAndIsHidden: ''},
          ...values
          .filter((s) => {
            return !s.Inactive || (s.Inactive && s.ID === rootState.adminCMSSite.pages.modals.newPage.ActiveForm.EventCategoryID);
          })
          .map(makeDeletedPrefixItemMapper('Name'))
        ];
      },
      valueKey: (v) => v.ID,
      labelKey: 'NameAndIsHidden',
      emptyValue: 0,
    },
    defaultValue: (rootState) => {
      if (!rootState.adminCMSSite.cacheOne.CMSSite) return 0;
      if (rootState.adminCMSSite.cacheOne.CMSSite.hasJustDistrictSites) return 3;

      return 0;
    },
    validatejs: {
      EventCategoryID: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        }
      },
    },
  },
  IsAllDay: {
    key: 'IsAllDay',
    defaultValue: () => false,
  },
  StartHour: getTimePickerHourValidationRule('StartHour'),
  StartMin: getTimePickerMinValidationRule('StartMin'),
  StartPeriod: getTimePickerPeriodValidationRule('StartPeriod'),
  EndHour: getTimePickerHourValidationRule('EndHour'),
  EndMin: getTimePickerMinValidationRule('EndMin'),
  EndPeriod: getTimePickerPeriodValidationRule('EndPeriod'),
};