import { GetAdminEventClassTypeCacheBodyParams } from "../../../constants/adminEventsUrls";
import { CLEAR_CACHE_PREFIX, createRequestActions } from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { HideDrawer, SilentCancelAllAction } from '../../../store/App/actions';
import { ADMIN_EVENT_TYPES_SUFFIX, ADMIN_EVENTS_MODULE_SUFFIX } from "../CacheOne/actions";
import { UPDATE_EVENT_CLASS_TYPE_FORM_SUFFIX } from "../ClassTypes/Form/actions";
import { ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX, UPDATE_EVENT_FORM_SUFFIX } from "../CacheTwoEvent/actions";
import { typeName, Action } from "../../../utils/StrongActions";


export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX + UPDATE_EVENT_CLASS_TYPE_FORM_SUFFIX + UPDATE_EVENT_FORM_SUFFIX + '__CACHE_THREE_CLASS_TYPE';
export const SUFFIX_SAVE = ADMIN_EVENT_TYPES_SUFFIX + '__CACHE_THREE_CLASS_TYPE_SAVING';

export const GetAdminEventClassTypeAllRequirements = createRequestActions(SUFFIX + "__ALL_REQUIREMENTS");

export const GetAdminEventClassType = createRequestActions(SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminEventsCacheThreeClassType extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  getClassType: (params: GetAdminEventClassTypeCacheBodyParams): ActionCreator => dispatch => {
    dispatch(GetAdminEventClassType.request(params));
  },
  refreshClassType: (params: GetAdminEventClassTypeCacheBodyParams): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    dispatch(GetAdminEventClassType.request(params));
  },
  clearAdminEventsCacheThreeClassType: (): ActionCreator => dispatch => {
    dispatch(new ClearAdminEventsCacheThreeClassType());
  },
};