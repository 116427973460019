import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';
import '../../../styles/elements/tickets/index.scss';
import { EventsIcon } from '../../Icons';

export const namespace = (): string => 'elements--tickets';

export interface TicketsProps {
  className?: string;
  text?: string;
  quantity?: number;
  total?: number;
  strong?: boolean;
  marginBottom?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  red?: boolean;
  full?: boolean;
}

const Tickets: React.SFC<TicketsProps> = (props: TicketsProps): React.ReactElement<TicketsProps> => {
  const { strong, marginBottom, red, full } = props;

  let quantity;
  if (typeof props.quantity !== 'undefined' && props.quantity !== null) {
    if (!props.total || props.quantity === props.total) {
      quantity = <span className={`${namespace()}--quantity`}>{props.quantity}</span>;
    } else {
      quantity = (
        <span className={`${namespace()}--quantity`}>
          <span className={`${namespace()}--quantity--quantity`}>{props.quantity}</span>
          <span className={`${namespace()}--quantity--slash`}>/</span>
          <span className={`${namespace()}--quantity--total`}>{props.total}</span>
        </span>
      );
    }
  }

  let className: string = mergeClassNames(namespace(), props);
  if (strong) className += ' strong';
  if (red || full) className += ' red';

  let style: React.CSSProperties = {};
  if (marginBottom) style.marginBottom = `${marginBottom}px`;

  return (
    <div className={className} style={style}>
      <EventsIcon width={18} height={18} className={`${namespace()}--icon`}/>
      {props.text && !full && <span className={`${namespace()}--text`}>{props.text}</span>}
      {!full && quantity}
      {full && <span className={`${namespace()}--text`}>FULL</span>}
    </div>
  );
};

export default Tickets;
