export const getFullAddress = (location: {
  Name?: string;
  Address: string;
  City: string;
  State: string;
  Zip?: string;
}) => {
  return `${location.Address}, ${location.City}, ${location.State} ${location.Zip}`;
};

export const getCityState = (location: {
  Name?: string;
  Address?: string;
  City: string;
  State: string;
  Zip?: string;
}) => {
  return `${location.City}, ${location.State}`;
};
