import {ActionCreator} from '../';
import { typeName, Action } from '../../utils/StrongActions';
import {CLEAR_CACHE_PREFIX, createRequestActions} from '../Validation/actionCreator';

export const SUFFIX = '__CACHE_TWO_EVENTS';
export const GetEventTypeData = createRequestActions(SUFFIX);

@typeName(`${CLEAR_CACHE_PREFIX}_CACHE_BELOW_TWO_EVENTS`) export class ClearCacheBelowTwoEvents extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  getEventTypeData: (
    EventTypeID: number,
    GroupIDi: number,
    GroupTS: number,
    GetOptions?: boolean,
    GetGroupData?: boolean
  ): ActionCreator => dispatch => {
    dispatch(GetEventTypeData.request(
      {EventTypeID, GroupIDi, GroupTS, GetOptions, GetGroupData}
    ));
  },
  clearCacheBelowTwoEvents: (): ActionCreator => dispatch => dispatch(new ClearCacheBelowTwoEvents())
};
