import * as React from 'react';
import * as M from '../../../../../constants/messages/events';
import { Main, MainContent } from '../../../../Layouts';
import { Class, LoadingAll, Title, EmptyMessage, Alert } from '../../../../Elements';
import { actionCreators } from "../../../../../store/Events/Event/Classes/actions";
import { EventsIcon } from '../../../../Icons';
import { bindActionCreators } from 'redux';
import { actionCreators as eventActionsCreators } from "../../../../../store/Events/Event/Main/actions";
import { actionCreators as eventTypeActionCreators } from "../../../../../store/Events/EventType/actions";
import { actionCreators as cacheThreeActionCreators } from "../../../../../store/CacheThreeEvents/actions";
import { actionCreators as cacheTwoActionCreators, ClearCacheBelowTwoEvents } from "../../../../../store/CacheTwoEvents/actions";
import { actionCreators as cacheOneActionCreators } from "../../../../../store/CacheOne/actions";
import { RouteComponentProps } from "react-router";
import { makeSelectedEventTypeSelector } from "../../../../../store/CacheTwoEvents/index";
import { makeSelectedEventSelector } from "../../../../../store/CacheThreeEvents/index";
import { withRouter } from "react-router";
import { defaultFilterSelector } from "../../../../../store/App/index";
import { makeSimpleSelector } from "../../../../../utils/reselectHelper";
import { ApplicationState } from '../../../../../store';
import { IEventRouterParams, isPathUnderEndUserEventCacheThree } from '../../../../../utils/helpers/endUserPageHelper';
import EndUserCacheManager from '../../../../../utils/cacheManagers/endUserCacheManager';
import { shouldReconfigRouter } from '../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isCacheThreeEventsPopulated, isEndUserCacheOnePopulated} from '../../../../../utils/cachePopulatedCheckers/endUser';
import {BaseEndUserClass, RegisteredClass} from '../../../../../models/class';
import { WithInertAttribute } from '../../../../Elements/WithInert';

const filterFnc = (c: BaseEndUserClass, f: string) => c.Name.toLowerCase().includes(f);
const classesIndividualSelector = (s) => {
  if (s.cacheThreeEvents.EventClassesIndividualAvailable) return s.cacheThreeEvents.EventClassesIndividualAvailable;
  return [];
};
const classesGroupSelector = (s) => {
  if (s.cacheThreeEvents.EventClassesGroupAvailable) return s.cacheThreeEvents.EventClassesGroupAvailable;
  return [];
};
const makeClassesIndividualSelector = makeSimpleSelector(() => true, filterFnc, classesIndividualSelector, defaultFilterSelector);
const makeClassesGroupSelector = makeSimpleSelector(() => true, filterFnc, classesGroupSelector, defaultFilterSelector);

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IEventRouterParams, {}>
>;

@(withRouter as any)
class ClassesComponent extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
  private nextLocation;

  public componentDidMount() {
    this.configRouteLeaveHook();
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeEvent({
          props: this.props,
          isStateNavigated,
        });
      }
    );
  }

  public componentWillReceiveProps(nextProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeEvent({
          props: nextProps,
          isStateNavigated,
        });
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouteLeaveHook();
  }

  componentWillUnmount() {
    const {cacheThreeEvents} = this.props;

    // if we are not navigating to another page under cache three events, clear cache
    if (
      this.nextLocation &&
      !isPathUnderEndUserEventCacheThree(this.nextLocation.pathname) &&
      isCacheThreeEventsPopulated(cacheThreeEvents)
    ) {
      reduxStoreService().dispatch(new ClearCacheBelowTwoEvents());
    }
    this.resetRouteLeaveHook();
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  renderClass = (c: BaseEndUserClass, index: number, individual: boolean) => {
    const { user: { user: { str_permissions } }, cacheTwoEvents: { EventTypeRegistrationSettings },
      cacheThreeEvents: { EventInfo }
    } = this.props;
    
    return (
      <Class key={index}
        classModel={c}
        isAdmin={str_permissions.hasAdminAccess}
        enableWaitlist={EventTypeRegistrationSettings ? EventTypeRegistrationSettings.NamesRegistrationSettings.EnableClassWaitingList : false}
        individual={individual}
        numYouth={EventInfo ? EventInfo.TotalNumbersYouth : 0}
        numAdults={EventInfo ? EventInfo.TotalNumbersAdults : 0}
        isPreview
      />
    );
  };

  renderGroupClass = (c: BaseEndUserClass, index: number) => this.renderClass(c, index, false);
  renderIndividualClass = (c: BaseEndUserClass, index: number) => this.renderClass(c, index, true);

  getEmptyMessage() {
    const { cacheTwoEvents: { eventType }, filteredIndividualClasses, filteredGroupClasses,
      cacheThreeEvents: { EventClassesGroupAvailable, EventClassesIndividualAvailable }
    } = this.props;
    // @todo: is there a better icon?
    if (!EventClassesIndividualAvailable && (!EventClassesGroupAvailable || eventType === 'participant')) {
      return <EmptyMessage icon={EventsIcon} description="There are no available classes" />;
    } else if (filteredIndividualClasses.length === 0 && (filteredGroupClasses.length === 0 || eventType === 'participant')) {
      return <EmptyMessage icon={EventsIcon} description="No classes found" />;
    }
    return null;
  }

  public render() {
    const { inert, cacheTwoEvents: { eventType }, selectedEventType, selectedEvent,
      filteredIndividualClasses, filteredGroupClasses, apiLoading, cacheOne,
      cacheThreeEvents: { EventClassesGroupAvailable, EventClassesIndividualAvailable, EventClassesGroupRegistered }
    } = this.props;
    if (!isEndUserCacheOnePopulated(cacheOne) || apiLoading > 0 || !selectedEventType || !selectedEvent) return <LoadingAll />;
    const emptyMessage = this.getEmptyMessage();
    return (
      <Main inert={inert} mobileBackground="white">
        <MainContent>
          {!emptyMessage && EventClassesIndividualAvailable && EventClassesGroupAvailable && eventType !== 'participant' &&
            <Title size={28} mobileSize={22} num={EventClassesIndividualAvailable.length}>Individual Participant Classes</Title>}
          {!emptyMessage && EventClassesIndividualAvailable &&
            filteredIndividualClasses.map(this.renderIndividualClass)}
          {!emptyMessage && EventClassesIndividualAvailable && EventClassesGroupAvailable && eventType !== 'participant' &&
            <Title size={28} mobileSize={22} marginTop={16} num={EventClassesGroupAvailable.length}>Registration Options</Title>}
          {!emptyMessage && EventClassesGroupAvailable && eventType !== 'participant' && EventClassesGroupRegistered && EventClassesGroupRegistered.length > 0 &&
            <Alert noFlex>{M.CLASSES_WARNING}</Alert>
          }
          {!emptyMessage && EventClassesGroupAvailable && eventType !== 'participant' &&
            filteredGroupClasses.map(this.renderGroupClass)
          }
          {emptyMessage}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredGroupClasses = makeClassesGroupSelector();
  const filterdIndividualClasses = makeClassesIndividualSelector();
  const selectedEventType = makeSelectedEventTypeSelector();
  const selectedEvent = makeSelectedEventSelector();
  return {
    user: state.user,
    eventType: state.events.eventType,
    event: state.events.event.main,
    systemSettings: state.session.SystemSettings,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    apiLoading: state.app.apiLoading,
    cacheThreeEvents: state.cacheThreeEvents,
    cacheTwoEvents: state.cacheTwoEvents,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    filteredIndividualClasses: filterdIndividualClasses(state),
    filteredGroupClasses: filteredGroupClasses(state),
    selectedEventType: selectedEventType(state),
    selectedEvent: selectedEvent(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...eventActionsCreators,
    ...cacheThreeActionCreators,
    ...cacheTwoActionCreators,
    ...cacheOneActionCreators,
    ...eventTypeActionCreators
  }, dispatch)
});

const ConnectedClassesComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(ClassesComponent);

export default ConnectedClassesComponent;
