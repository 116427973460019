
import { CLEAR_CACHE_PREFIX, createRequestActions } from '../../Validation/actionCreator';
import { ActionCreator } from '../..';
import { GetSiteCacheBodyParams } from '../../../constants/urls';
import { HideDrawer, SilentCancelAllAction, StartProgress } from '../../../store/App/actions';
import { SaveState } from '../../Rollback/actions';
import { typeName, Action } from '../../../utils/StrongActions';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../../utils/suffix';

export const UPDATE_ADMIN_CMS_SETTINGS_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__CMS_SETTINGS_FORM';
export const ADMIN_CMS_MODULE_SUFFIX = '__ADMIN_CMS_SITE';
export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__CACHE_ONE';
export const SUFFIX_SAVE = ADMIN_CMS_MODULE_SUFFIX + '__CACHE_ONE_SAVING';

// TODO: make this suffix setup consistent with other cache one such as AdminFacilities: with `CACHE_ONE_SUCCESS_HANDLE_SUFFIX`
export const GetSiteCache = createRequestActions(SUFFIX + UPDATE_ADMIN_CMS_SETTINGS_FORM_SUFFIX);

@typeName(`${CLEAR_CACHE_PREFIX}_ADMIN_CMS_SITE_CACHE_BELOW_ONE`) export class ClearAdminCMSSiteCacheBelowOne extends Action {}

@typeName(`${CLEAR_CACHE_PREFIX}_ALL_ADMIN_CMS_SITE_CACHE`) export class ClearAllAdminCMSSiteCache extends Action {
  constructor() {
    super();
  }
}

@typeName(`${CLEAR_CACHE_PREFIX}_ALL_ADMIN_CMS_SITE_CACHE_BUT_OPTIONS`) export class ClearAllAdminCMSSiteCacheButOptions extends Action {}

export type Actions = typeof actionCreators;
  
export const actionCreators = {
  getSiteCache: (params: GetSiteCacheBodyParams, isRefreshingCacheOneForm: boolean, startNProgress?: boolean, saveBefore?: boolean): ActionCreator => dispatch => {
    if (saveBefore) dispatch(new SaveState());
    if (startNProgress) dispatch(new StartProgress());

    dispatch(GetSiteCache.request(params, {skipUpdateForm: !isRefreshingCacheOneForm}));
  },
  clearAdminCMSSiteCacheBelowOne: (): ActionCreator => dispatch => dispatch(new ClearAdminCMSSiteCacheBelowOne()),
  clearAllAdminCMSSiteCache: (): ActionCreator => dispatch => dispatch(new ClearAllAdminCMSSiteCache()),
  refresh: (params: GetSiteCacheBodyParams, isRefreshingCacheOneForm?: boolean): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());

    // When `!isRefreshingCacheOneForm`, we should skip update cache one form, by setting `skipUpdateForm` to true
    dispatch(GetSiteCache.request(params, {skipUpdateForm: !isRefreshingCacheOneForm}));
  },
};