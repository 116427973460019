
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, Row, SimpleList, PageLoader, EmptyMessage, Button,
} from '../../../../Elements';
import '../../../../../styles/pages/shared/modals/location/index.scss';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as cacheZeroActionCreators, GetLocations } from '../../../../../store/CacheZero/actions';
import {
    actionCreators as adminCMSCacheOneActionCreators,
} from '../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as facilityLocationCacheOneActionCreators, GetFacilityLocationCache } from '../../../../../store/AdminFacilityLocation/CacheOne/actions';
import { actionCreators } from '../../../../../store/AdminSettings/Modals/Location/actions';
import { actionCreators as newPageActionCreators } from '../../../../../store/AdminCMSSite/Pages/Modals/NewPage/actions';
import { actionCreators as pageFormActionCreators } from '../../../../../store/AdminCMSSite/Pages/Page/Form/actions';
import { actionCreators as eventTypeFormActionCreators } from '../../../../../store/AdminEvents/EventTypes/EventType/Form/actions';
import { actionCreators as newEventTypeFormActionCreators } from '../../../../../store/AdminEvents/EventTypes/Modals/NewEventType/actions';
import { FormDefinition as NewPageFormDefinition } from '../../../../../store/AdminCMSSite/Pages/Modals/NewPage/validation';
import { FormDefinition as EditPageFormDefinition } from '../../../../../store/AdminCMSSite/Pages/Page/Form/validation';
import { EventTypeGeneralFormDefinition as EventTypeFormDefinition } from '../../../../../store/AdminEvents/EventTypes/EventType/Form/validation';
import { NewEventTypeFormDefinition } from '../../../../../store/AdminEvents/EventTypes/Modals/NewEventType/validation';
import { ApplicationState } from '../../../../../store';
import { Location } from '../../../../../models/api/adminCMSCacheOne';
import { makeFilteredLocationsSelector } from '../../../../../store/AdminSettings/Modals/Location';
import LocationCard, { LocationCardType } from '../../../../Elements/CMSLocation/Card';
import { ADMIN_FACILITY_LOCATION_SETTINGS_PATH } from '../../../../../routes';
import { LocationsIcon } from '../../../../Icons';
import { checkLocationPermission } from '../../../../../utils/helpers/adminCMSPageHelper';
import { navPush } from '../../../../../utils';
import { getFacilitiesHomeRootUrl } from '../../../../../constants/urls';
import { EmptyMessageType } from '../../../../../components/Elements/EmptyMessage';
import { ModalHeight } from '../../../../../components/Elements/Modal';
import { CardCategory } from '../../../../../components/Elements/Card';
import { makeFormModalPropSelector } from '../../../../../store/App';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { getFacilityLocationId, IAdminFacilitiesLocationRouterParams } from '../../../../../utils/helpers/adminFacilityLocationPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { extractRouteParams } from '../../../../../utils/urlHelper';
import { SaveState } from '../../../../../store/Rollback/actions';
import { reduxStoreService } from '../../../../../store/service';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--modals--location';

export type SelectLocationModalContext = 'admin-cms-edit-page' | 'admin-cms-add-page' | 'admin-facility-location' | 'admin-events-event-type' | 'admin-events-new-event-type';
type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}>;
type Props = WithInertAttribute<{}>

@(withRouter as any)
class LocationModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;
  private modalContentRef;

  componentWillUnmount() {
    this.onClose();
  }

  onClose = () => {
    this.onSearchBack();
  };
  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    if (this.props.locationModal.filterString) {
      this.scrollToTop();
    }
    this.props.actions.toggleFilter(false);
    this.props.actions.filterLocations('');
  };
  onAddClick = () => {
    checkLocationPermission(
      () => {
        this.props.actions.pushFormModal(ModalTypes.LOCATION_FORM, false);
      },
      this.props.cacheZero,
      undefined,
      true,
    );
  };
  onRefreshClick = () => {
    const {actions, routes} = this.props;
    actions.getLocations(routes);
  };
  onSelectLocation = (location: Location) => {
    const {context, adminFacilityLocationCacheOne, actions, pageFormActions, eventTypeFormActions, newEventTypeFormActions} = this.props;

    if (!location.Inactive) {
      let saveAfterPopModal = true;
      if (context === 'admin-facility-location') {
        // We dont need to pop modal here because route change will always close modals
        reduxStoreService().dispatch(new SaveState());
        this.onClose();
        navPush(
          this.props.router,
          getFacilitiesHomeRootUrl({
            locationId: location.ID,
            locationName: location.Name,
          }),
        );
        if (adminFacilityLocationCacheOne.FacilitiesLocation && adminFacilityLocationCacheOne.FacilitiesLocation.ID === location.ID) {
          reduxStoreService().dispatch(GetFacilityLocationCache.request({
            ...extractRouteParams(this.props),
          }));
        }
      } else {
        if (context === 'admin-cms-add-page') {
          actions.updateValue(location.ID, NewPageFormDefinition.LocationID);
        } else if (context === 'admin-cms-edit-page') {
          pageFormActions.updateValue(location.ID, EditPageFormDefinition.LocationID);
        } else if (context === 'admin-events-event-type') {
          eventTypeFormActions.updateEventTypeGeneralValue(location.ID, EventTypeFormDefinition.LocationID);
        } else if (context === 'admin-events-new-event-type') {
          newEventTypeFormActions.updateEventTypeGeneralValue(location.ID, NewEventTypeFormDefinition.LocationID);
        }
        this.onClose();
        this.props.actions.popModal(false, saveAfterPopModal, ModalTypes.SELECT_LOCATION);
      }
    }
  };
  onEditLocation = (location: Location) => {
    const {routes} = this.props;
    if (!location.Inactive) {
      const route = routes[routes.length - 1];
      if (route.path === ADMIN_FACILITY_LOCATION_SETTINGS_PATH) {
        const locationId = getFacilityLocationId(this.props);
        if (locationId === location.ID) {
          this.props.actions.showTopFloatingAlert('Please leave the Settings tab for facilities at this location before editing here.', true, 'orange');
          return;
        }
      }
      checkLocationPermission(
        () => {
          this.props.actions.pushFormModal(ModalTypes.LOCATION_FORM, true, {id: location.ID});
        },
        this.props.cacheZero,
        location,
        true,
      );
    }
  };

  itemRenderer = (index) => {
    const {adminCMSCacheOne, filteredLocations, sortedFilteredLocations, isFacilities} = this.props;
    const location: Location = isFacilities ? sortedFilteredLocations[index] : filteredLocations[index];

    return (
      <LocationCard
        key={`location_${location.ID}`}
        type={LocationCardType.IN_MODAL}
        location={location}
        adminCMSCacheOne={adminCMSCacheOne}
        onCardClick={this.onSelectLocation}
        onEdit={this.onEditLocation}
        isFacilities={isFacilities}
        cardCategory={CardCategory.LIST_MOBILE}
      />
    );
  };

  renderEmptyMessage = () => {
    return (
      <EmptyMessage
        icon={LocationsIcon}
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description='No locations found'
        actions={<Button className={`${namespace()}--empty-message-btn`} color='white' textColor='black' onClick={this.onAddClick}>NEW LOCATION</Button>}
      /> 
    );
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };
  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filterLocations(val);
  };

  public render() {
    const { apiLoading, apiLoadingMap, actions: {filterLocations}, adminCMSCacheTwoPage, locationModal: {isTextSearching}, adminCMSCacheOne, newPageFormState, pageFormState, context, eventTypeFormState, newEventTypeFormState, filteredLocations} = this.props;

    // Note that this one may be modified in the future, because it would be shared across different pages
    let subtitle;

    if (context === 'admin-cms-add-page') {
      subtitle = newPageFormState.ActiveForm.Name ? newPageFormState.ActiveForm.Name : 'New Page';
    } else if (context === 'admin-cms-edit-page') {
      subtitle = pageFormState.ActiveForm.Name ? pageFormState.ActiveForm.Name : 'Edit Page';
    } else if (context === 'admin-events-event-type') {
      subtitle = eventTypeFormState.General.ActiveForm.Name || 'Edit Event Type';
    } else if (context === 'admin-events-new-event-type') {
      subtitle = newEventTypeFormState.General.ActiveForm.Name || 'New Event Type';
    } else {
      subtitle = '';
    }
    
    return (
      <Modal
        height={ModalHeight.HEIGHT_425}
        onClose={this.onClose} big mobileFullScreen
        className={namespace()}
        inert={this.props.inert}
      >
        <ModalHeader
          isSearching={isTextSearching}
          onAdd={this.onAddClick}
          onRefresh={this.onRefreshClick}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={this.onSearchChange}
          onSearchBack={this.onSearchBack}
          subtitle={subtitle}
          className={`${namespace()}--header`}>
          {context === 'admin-facility-location' ? "Locations" : "Select Location"}
        </ModalHeader>
        <ModalContent
          refCallback={(ref) => this.modalContentRef = ref}
          neverLoading
          paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0} >
          <Row className={`${namespace()}--row-1`}>
            {apiLoading > 0 && <PageLoader className={`${namespace()}--page-loader`}/>}
            {!apiLoadingMap[GetLocations.requestType] && filteredLocations.length > 0 && <SimpleList
              itemRenderer={this.itemRenderer}
              totalLength={filteredLocations ? filteredLocations.length : 0}
            />}
            {filteredLocations.length === 0 && !apiLoadingMap[GetLocations.requestType] && this.renderEmptyMessage()}
          </Row>
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredLocationsSelector = makeFilteredLocationsSelector();
  const sortedFilteredLocationsSelector = makeFilteredLocationsSelector(true);
  const contextSelector = makeFormModalPropSelector(ModalTypes.SELECT_LOCATION, 'context');
  const isFacilitiesSelector = makeFormModalPropSelector(ModalTypes.SELECT_LOCATION, 'isFacilities');
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    adminCMSCacheTwoPage: state.adminCMSSite.cacheTwoPage,
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
    newPageFormState: state.adminCMSSite.pages.modals.newPage,
    pageFormState: state.adminCMSSite.pages.page.form,
    locationModal: state.adminSettings.modals.location,
    filteredLocations: filteredLocationsSelector(state),
    sortedFilteredLocations: sortedFilteredLocationsSelector(state),
    context: contextSelector(state),
    isFacilities: isFacilitiesSelector(state),
    eventTypeFormState: state.adminEvents.eventTypes.eventType.form,
    newEventTypeFormState: state.adminEvents.eventTypes.modals.newEventType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...adminCMSCacheOneActionCreators,
    ...newPageActionCreators,
    ...cacheZeroActionCreators,
  }, dispatch),
  pageFormActions: bindActionCreators({
    ...pageFormActionCreators,
  }, dispatch),
  facilityLocationActions: bindActionCreators({
    ...facilityLocationCacheOneActionCreators,
  }, dispatch),
  eventTypeFormActions: bindActionCreators({
    ...eventTypeFormActionCreators,
  }, dispatch),
  newEventTypeFormActions: bindActionCreators({
    ...newEventTypeFormActionCreators,
  }, dispatch),
});

const ConnectedLocationModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(LocationModal);

export default ConnectedLocationModal;