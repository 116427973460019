import * as Actions from './actions';
import { IValidator, FormDefinition } from "./validation";
import { SUFFIX } from './actions';
import { apiValidationReducerCreator } from "../../../../store/Validation/reducerCreator";
import { createSelector } from "reselect";
import { CMSResource, CMSResourceCategory } from "../../../../models/api/adminCMSCacheOne";
import { resourceCategoriesSelector } from "../Modals/ResourceCategory";
import { ClearAllAdminCMSSiteCache } from "../../CacheOne/actions";
import { getResourceCategoriesMap } from "../../../../utils/helpers/resourceCategoriesHelper";
import { ApplicationState } from "../../../../store";
import { compareDataByRowNum } from "../../../../utils/dateHelper";
import { listSelector } from "../../../../utils/reselectHelper";
import { isActionType } from '../../../../utils/StrongActions';
import { AnyAction, Reducer } from 'redux';
import {setDefaults} from '../../../../utils/validator';
import { WithRootState } from '../../../Validation/actionCreator';
import {ClearAllCache} from '../../../App/actions';

export interface ResourcesHomeState {
  ActiveForm: ResourceHomeActiveForm;
  ValidationRules: IValidator;
  expandFilter: boolean;
  unmountPreview?: boolean;
  currentSiteID?: number;
}

export interface ResourceHomeActiveForm {
  ShowDeleted?: boolean;
  FilterText?: string;
  SelectedCategoryID?: number;
  ResourceCategoryShowDeleted?: number;
  ResourceCategoryFilterText?: string;
}

const getInitialState: () => ResourcesHomeState = () => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition},
  expandFilter: false,
  unmountPreview: false,
});

const _resourcesSelector = (state: ApplicationState) => {
  if (state.adminCMSSite.cacheOne) return state.adminCMSSite.cacheOne.CMSResources;
  return [];
};
export const resourcesSelector = listSelector(_resourcesSelector);
const selectedResourceCategoryIDSelector = state => {
  return Math.abs(state.adminCMSSite.resources.home.ActiveForm.SelectedCategoryID);
};
const resourceShowDeletedSelector = state => {
  return state.adminCMSSite.resources.home.ActiveForm.ShowDeleted;
};
const resourceFilterTextSelector = state => {
  return state.adminCMSSite.resources.home.ActiveForm.FilterText;
};
const resourceCategoryShowDeletedSelector = state => {
  return state.adminCMSSite.resources.home.ActiveForm.ResourceCategoryShowDeleted;
};
const resourceCategoryFilterTextSelector = state => {
  return state.adminCMSSite.resources.home.ActiveForm.ResourceCategoryFilterText;
};

export const makeGroupedResourcesSelector = () => {
  return createSelector(
    [resourcesSelector, resourceCategoriesSelector, resourceShowDeletedSelector, resourceFilterTextSelector],
    (resources: CMSResource[], resourceCategories: CMSResourceCategory[], showDeleted: boolean, filterText: string) => {
      const groupedResources: {[key: string]: any[]} = {};
      const resourceCategoryMap = getResourceCategoriesMap(resourceCategories);

      if (resources) {
        resources.forEach((resource) => {
          if (resource.CategoryID && resourceCategoryMap[resource.CategoryID] && !resourceCategoryMap[resource.CategoryID].Inactive) {
            if (!groupedResources[resource.CategoryID.toString()]) {
              groupedResources[resource.CategoryID.toString()] = [];
            }
            // filter deleted
            if ((showDeleted || !resource.Inactive)) {
              // filter by filter text if set
              if (!filterText) {
                groupedResources[resource.CategoryID.toString()].push(resource);
              } else {
                const f = filterText.toLowerCase();
                const nameMatches = resource.Name && resource.Name.toLowerCase().includes(f);
                const descMatches = resource.Description && resource.Description.toLowerCase().includes(f);
                const filenameMatches = resource.File && resource.File.Filename.toLowerCase().includes(f);
                if (nameMatches || descMatches || filenameMatches) {
                  groupedResources[resource.CategoryID.toString()].push(resource);
                }
              } 
            }
          }
        });
      }
      for (const key of Object.keys(groupedResources)) {
        groupedResources[key].sort(compareDataByRowNum);
        const resourceCategory = resourceCategories && resourceCategories.find((c) => c.ID === Number(key));
        if (resourceCategory && groupedResources[key].length > 0) {
          groupedResources[key].unshift(resourceCategory);
        }
      }
      let result: any[] = [];
      for (const key of Object.keys(groupedResources)) {
        result = result.concat(groupedResources[key]);
      }
      // push an item to render empty paragraph if there are any resources
      if (result.length > 0) result.push(null);
      return result;
    }
  );
};

export const activeResourcesSelector = listSelector(_resourcesSelector, true);
// @todo - utilize the "makeFilteredSelector" helper method
export const makeFilteredResourcesSelector = () => {
  return createSelector(
    [resourcesSelector, selectedResourceCategoryIDSelector, resourceShowDeletedSelector, resourceFilterTextSelector],
    (resources: CMSResource[], selectedCategoryId: number, showDeleted: boolean, filterText: string) => {
      if (selectedCategoryId !== 0 && resources) {
        const result: any[] = resources.filter(
          (resource) => {
            let result = false;
            // filter deleted
            if (resource.CategoryID === selectedCategoryId && (showDeleted || !resource.Inactive)) {
              // filter by filter text if set
              if (!filterText) {
                result = true;
              } else {
                const f = filterText.toLowerCase();
                const nameMatches = resource.Name && resource.Name.toLowerCase().includes(f);
                const descMatches = resource.Description && resource.Description.toLowerCase().includes(f);
                const filenameMatches = resource.File && resource.File.Filename.toLowerCase().includes(f);
                if (nameMatches || descMatches || filenameMatches) {
                  result = true;
                }
              }
            }
            return result;
          }
        ).sort(compareDataByRowNum);

        // push an item to render empty paragraph if there are any filtered resources
        if (result.length > 0) result.push(null);
        return result;
      }
      return [];
    }
  );
};


export const activeResourceCategoriesSelector = listSelector(resourceCategoriesSelector, true);
// @todo - utilize the "makeFilteredSelector" helper method
export const makeFilteredResourceCategoriesSelector =  () => {
  return createSelector(
    [resourceCategoriesSelector, resourceCategoryShowDeletedSelector, resourceCategoryFilterTextSelector],
    (resourceCategories: CMSResourceCategory[], showDeleted: boolean, filterText: string) => {
      if (resourceCategories) {
        const result: any[] = resourceCategories.filter((c) => {
          let result = false;
          // filter based on deleted
          if (showDeleted || !c.Inactive) {
            if (!filterText) {
              result = true;
            } else {
              const f = filterText.toLowerCase();
              if (c.Name.toLowerCase().includes(f)) {
                result = true;
              }
            }
          }
          return result;
        });
        const sorted = result.sort((a, b) => a.Name.localeCompare(b.Name));

        if (sorted.length > 0) {
          // push an empty paragraph to the end
          sorted.push(null);
        }

        return sorted;
      }
      return [];
    });
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const ResourcesHomeReducer: Reducer<ResourcesHomeState> = (s, action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, action);
  if (isActionType(action, Actions.ToggleExpandFilterAction)) {
    return {...state, expandFilter: action.expand};
  } else if (isActionType(action, ClearAllAdminCMSSiteCache) || isActionType(action, ClearAllCache)) {
    return setDefaults(action.rootState, getInitialState());
  } else if (isActionType(action, Actions.ResourcesHomeReset)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        FilterText: '',
        ResourceCategoryFilterText: '',
      },
    };
  }
  return state || setDefaults(action.rootState, getInitialState());
};

export default ResourcesHomeReducer;