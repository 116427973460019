
import {makeRequestEpic} from "../../Validation/epicCreator";
import { getContact, getContactBody } from "../../../constants/urls";
import { GetContact } from "./actions";

export const adminCMSSiteCacheTwoContactsEpic = makeRequestEpic(
  GetContact,
  getContact,
  getContactBody,
  true,
);