import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import Text from '../Text';
import '../../../styles/elements/snackbar/index.scss';
import { isMobile } from '../../../utils/isMobile';
import { generateDOMId } from '../../../utils/cypressHelper';

export const namespace = (): string => 'elements--snackbar';

export interface Props {
  message: string;
  actionLabel?: string;
  action?: () => any;
  className?: string;
}

// @todo: action and actionLabel
// @todo: what should it look like in mobile?
const Snackbar: React.SFC<Props> = ({ message, actionLabel, action, className, ...props }: Props): React.ReactElement<Props> => {
  let cn = mergeClassNames(namespace(), props);
  if (isMobile) {
    cn += ' mobile';
  }
  return (
    <div id={generateDOMId("tentaroo-snackbar")} className={cn}>
      <div className={`${namespace()}--inner`}>
        <Text className={`${namespace()}--message`}
              color="white">
          {message}
        </Text>
      </div>
    </div>
  );
};

export default Snackbar;
