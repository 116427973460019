import React from "react";
import { RouteComponentProps } from "react-router";
import {push} from "react-router-redux";

import { ComponentUpdateTemplateState } from "../../utils/cacheLoaders/helpers/models";
import { shouldBlockActions } from "../../utils/cacheLoaders/helpers/blockers";
import { reduxStoreService } from "../../store/service";
import { hasLocationChanged } from "../../utils/navHelper";
import {isEndUserPage} from "../../constants/urls";
import { isAdminCMSSitePage, isAdminEventsPage, isAdminFacilityLocationPage } from "../../constants/urls";
import { ClearAllAdminEventsCache } from "../../store/AdminEvents/CacheOne/actions";
import { ClearAllAdminFacilityLocationCache } from "../../store/AdminFacilityLocation/CacheOne/actions";
import { ClearAllAdminCMSSiteCache } from "../../store/AdminCMSSite/CacheOne/actions";
import { isPathUnderCacheTwoPreviousOrders, isPathUnderCacheTwoRoster, isPathUnderEndUserEventCacheTwo, isPathUnderEndUserFacilityCacheTwo } from "../../utils/helpers/endUserPageHelper";
import { ClearCacheBelowOne } from "../../store/CacheOne/actions";
import { toggleLoadingAllIfNeeded, handleGeneralRedirection, handleOptimisticSaveRedirection } from "./helpers";
import {AppState} from "../../store";
import { RollbackState } from "../../store/Rollback";
import {isCacheTwoPrevOrdersPopulated, isCacheTwoRosterPopulated, isEndUserCacheOnePopulated, isEndUserCacheTwoEventsPopulated, isEndUserCacheTwoFacilitiesPopulated} from "../../utils/cachePopulatedCheckers/endUser";
import {isAdminEventsCacheOnePopulated} from "../../utils/cachePopulatedCheckers/adminEvents";
import {isAdminCMSCacheOnePopulated} from "../../utils/cachePopulatedCheckers/adminCMS";
import {isAdminFacilitiesLocationCacheOnePopulated} from "../../utils/cachePopulatedCheckers/adminFacilities";

const INITIAL_COMPONENT_STATE: ComponentUpdateTemplateState = {
  previousPathname: "",
  previousQuery: {},
};

type LoadAndSetData = (isStateNavigated: boolean) => void;
type ClearData = () => void;

// We require these flags appear in props so that loader get triggered after certain action is done
// apiSavingMap | apiSaving - after logout success
// isRollbackJustFinished - after rollback
// apiLoadingMap - probably not needed, just ensuring that loader is triggered after data is loaded.
type LoaderTriggerProps = Pick<AppState, "apiLoadingMap"> & (Pick<AppState, "apiSaving"> | Pick<AppState, "apiSavingMap">) & Pick<RollbackState, "isRollbackJustFinished">;

export class ComponentUpdateTemplate<P extends LoaderTriggerProps, S = {}> extends React.Component<P & RouteComponentProps<{}, {}>, S & ComponentUpdateTemplateState> {
  constructor(props: P & RouteComponentProps<{}, {}>, state: S) {
    super(props);

    this.state = {
      ...state,
      ...INITIAL_COMPONENT_STATE,
    };
  }

  protected loadAndSetData(
    routeProps: RouteComponentProps<{}, {}>,
    loadAndSetDataFn: LoadAndSetData,
  ): void {
    // Always handle navigation during save case
    // We dont check sequential save here because any sequential save should
    // already be canceled when leaving the page, which happens before this code
    if (this.hasRouterLocationChangedDuringSave(routeProps)) {
      const rootState = reduxStoreService().getState();
      
      // Compare current URL to the URL we should be at during this save request. 
      // If not at the correct URL, redirect and rollback to savePointDuringSave.
      const {oldQuery, oldUrl} = rootState.rollback.savePointDuringSave!;
      reduxStoreService().dispatch(push({
        pathname: oldUrl,
        query: oldQuery,
        state: {
          tentarooPerformRollback: true
        }
      }));

      return;
    }

    // Always clear data that doesn't belong to this page
    this.clearDataOnLoad(routeProps);

    if (shouldBlockActions()) return;

    const rootState = reduxStoreService().getState();

    /**
     * We skip redirection handling if we dont know if the user is logged in yet
     * 
     * So the order here would be:
     * 1. Always run `loadLoginForm` when the App is first loaded, no redirection logic for this request
     * 2. When user & session data comes back from `loadLoginForm`, another load will be triggered
     *    depending on what page we are at. This will always happen, because `componentDidMount` in any component page will only run after loadingAll is cleared, and we have loaders in `componentDidMount` in every page.
     *    - For authenticated pages - running all the cache loaders
     *    - For unauthenticated pages, it will then run `loadLoginForm` or `loadRegisterForm`,
     *      depending on we are at LOGIN page OR NEW_ACCOUNT page, and that is the time to redirect
     *      to default admin/group user page if the user is already logged in
     */ 
    const noGeneralRedirection = !rootState.session.SystemSettings ? true : handleGeneralRedirection(rootState, routeProps);
    const noOptimisticSaveRedirection = !rootState.session.SystemSettings ? true : handleOptimisticSaveRedirection(rootState, routeProps);

    if (noGeneralRedirection && noOptimisticSaveRedirection) {
      if (
        this.hasRouterLocationChanged(routeProps) ||
        // Run the loader on user side when cache is missing, to handle
        // how we implement refresh in end user side.
        // See monday item: https://tentaroo-camp-management.monday.com/boards/4118418283/pulses/6569538662
        (
          isEndUserPage(routeProps.location.pathname) && !isEndUserCacheOnePopulated(
            rootState.cacheOne
          )
        )
      ) {
        const isStateNavigated = this.checkIsStateNavigated(routeProps);
        loadAndSetDataFn(isStateNavigated);
      }

      /**
       * Always toggle loadingAll here even loader might not get run. 
       * 
       * It is safe and actually better to de-couple this logic from loader (before, this is part of running the loader) because
       * - This is a toggle logic, it wont trigger itself infinitely, and so it's safe
       * - With this new template for "load and set data", it's better to move logic from the template
       *   we defined in `cacheLoaderHelpers.ts`, where we sort of building template around the loader call. This new template is more explicit.
       */
      toggleLoadingAllIfNeeded(reduxStoreService().getState(), routeProps);
    }

    /**
     * Check the loading flag again after loader is run above, and block state update if
     * loading flag is set - it can be set by the loader code above, and in that case, we
     * want to wait till that load is done and then update the state
     */
    if (!shouldBlockActions()) {
      this.updateState(routeProps);
    }
  }

  protected checkIsStateNavigated(
    routeProps: RouteComponentProps<{}, {}>,
  ): boolean {
    const rootState = reduxStoreService().getState();
  
    /**
     * We say the state "has navigated" if location from router has changed since last save point
     */
    const isStateNavigated = hasLocationChanged({
      previousUrl: rootState.rollback.normalSavePoint?.oldUrl,
      previousQuery: rootState.rollback.normalSavePoint?.oldQuery,
      nextUrl: routeProps.location.pathname,
      nextQuery: routeProps.location.query,
    });
  
    return isStateNavigated;
  }

  private hasRouterLocationChangedDuringSave(
    routeProps: RouteComponentProps<{}, {}>,
  ): boolean {
    const rootState = reduxStoreService().getState();

    if (!rootState.rollback.savePointDuringSave || rootState.app.apiSaving === 0) return false;
  
    // Compare current URL to the URL we should be at during this save request. 
    // If not at the correct URL, redirect and rollback to savePointDuringSave.
    return hasLocationChanged({
      previousUrl: rootState.rollback.savePointDuringSave.oldUrl,
      previousQuery: rootState.rollback.savePointDuringSave.oldQuery,
      nextUrl: routeProps.location.pathname,
      nextQuery: routeProps.location.query,
    });
  }

  /**
   * Check if router location has changed by comparing location information we stored in component state
   * and location information from router
   */
  private hasRouterLocationChanged(
    routeProps: RouteComponentProps<{}, {}>,
  ) {
    return hasLocationChanged({
      previousUrl: this.state.previousPathname,
      previousQuery: this.state.previousQuery,
      nextUrl: routeProps.location.pathname,
      nextQuery: routeProps.location.query,
    });
  }
  
  private updateState(
    routeProps: RouteComponentProps<{}, {}>,
  ) {
    // Update state if browser url has changed
    if (this.hasRouterLocationChanged(routeProps)) {
      this.setState({
        ...this.state,
        previousPathname: routeProps.location.pathname,
        previousQuery: routeProps.location.query,
      });
    }
  }

  /**
   * Logics here will be invoked BEFORE any child loaders are run
   * 
   * We need this here to ensure that clearAllCache for admin modules happen BEFORE save state in child component
   */
  private clearDataOnLoad(routeProps: RouteComponentProps<{}, {}>) {
    const rootState = reduxStoreService().getState();

    // Block cache clearing on saving, because saving thunk is responsible to do it
    if (rootState.app.apiSaving > 0) return;
    // Block if user is not logged in
    if (!rootState.user.user.IDi || rootState.user.user.IDi <= 0) return;

    if (
      !isAdminEventsPage(routeProps.location.pathname) &&
      isAdminEventsCacheOnePopulated()
    ) {
      reduxStoreService().dispatch(new ClearAllAdminEventsCache());
    }

    if (
      !isAdminFacilityLocationPage(routeProps.location.pathname) &&
      isAdminFacilitiesLocationCacheOnePopulated()
    ) {
      // If we are navigating to a non FacilityLocation page, and there are any keys under FacilityLocationCacheOne (dont need to check CacheTwo)
      // clear all FacilityLocation Cache
      reduxStoreService().dispatch(new ClearAllAdminFacilityLocationCache());
    }
    if (
      !isAdminCMSSitePage(routeProps.location.pathname) &&
      isAdminCMSCacheOnePopulated()
    ) {
      // If we are navigating to a non CMSSite page, and there are any keys under CMSSiteCacheOne (dont need to check CacheTwo)
      // clear all CMSSite Cache
      reduxStoreService().dispatch(new ClearAllAdminCMSSiteCache());
    }

    // When switching to a different cache "branch" in end user side, should dispatch ClearCacheBelowOne
    // Latest status is we moved this logic from App.tsx to this template
    // See monday item: https://tentaroo-camp-management.monday.com/boards/4118418283/pulses/6504649976
    if (
      (
        !isPathUnderEndUserEventCacheTwo(routeProps.location.pathname) &&
        isEndUserCacheTwoEventsPopulated(reduxStoreService().getState().cacheTwoEvents)
      ) || (
        !isPathUnderCacheTwoPreviousOrders(routeProps.location.pathname) &&
        isCacheTwoPrevOrdersPopulated(reduxStoreService().getState().cacheTwoPrevOrders)
      ) || (
        !isPathUnderCacheTwoRoster(routeProps.location.pathname) &&
        isCacheTwoRosterPopulated(reduxStoreService().getState().cacheTwoRoster)
      ) || (
        !isPathUnderEndUserFacilityCacheTwo(routeProps.location.pathname) &&
        isEndUserCacheTwoFacilitiesPopulated(reduxStoreService().getState().cacheTwoFacilities)
      )
    ) {
      reduxStoreService().dispatch(new ClearCacheBelowOne());
    }
  }
}