import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/social-media-field/index.scss';
import Select from '../Select';
import { SSNFacebookColorIcon, SSNFacebookSolidIcon, SSNTwitterColorIcon, SSNPinterestColorIcon, SSNPinterestSolidIcon, SSNTwitterSolidIcon, SSNLinkedInColorIcon, SSNLinkedInSolidIcon, SSNYoutubeColorIcon, SSNYoutubeSolidIcon, SSNGoogleColorIcon, SSNGoogleSolidIcon, SSNVimeoColorIcon, SSNVimeoSolidIcon, SSNFlickrColorIcon, SSNFlickrSolidIcon, SSNInstColorIcon, SSNInstSolidIcon, SSNBlogIcon, SSNThreadColorIcon, SSNThreadSolidIcon, CloseIcon } from '../../../components/Icons';
import { TextField } from '..';
import { Validator } from '../../../utils/validator/models';

export const namespace = (): string => 'elements--social-media-field';

interface SocialMediaFieldProps {
  socialTypeValue: number;
  socialUserValue: string;
  disabled?: boolean;
  socialTypeValidationRule: any;
  socialUserValidationRule: any;
  onSocialUserChange: (val: string, vObj: Validator) => void;
  onSocialUserBlur: (val: string, vObj: Validator) => void;
  onSocialTypeChange: (val: string, vObj: Validator) => void;
  onCancelClick?: () => void;
  iconType: 'color' | 'solid';
  className?: string;
}

export const mapSocialTypeIDToIcon = (id: number, iconType: 'color' | 'solid') => {
  switch (id) {
    case 1:
      return iconType === 'color' ? <SSNFacebookColorIcon /> : <SSNFacebookSolidIcon />;
    case 2:
      return iconType === 'color' ? <SSNTwitterColorIcon /> : <SSNTwitterSolidIcon />;
    case 3:
      return iconType === 'color' ? <SSNPinterestColorIcon /> : <SSNPinterestSolidIcon />;
    case 4:
      return iconType === 'color' ? <SSNLinkedInColorIcon /> : <SSNLinkedInSolidIcon />;
    case 5:
      return iconType === 'color' ? <SSNYoutubeColorIcon /> : <SSNYoutubeSolidIcon />;
    case 6:
      return iconType === 'color' ? <SSNGoogleColorIcon /> : <SSNGoogleSolidIcon />;
    case 8:
      return iconType === 'color' ? <SSNVimeoColorIcon /> : <SSNVimeoSolidIcon />;
    case 9:
      return iconType === 'color' ? <SSNFlickrColorIcon /> : <SSNFlickrSolidIcon />;
    case 10:
      return iconType === 'color' ? <SSNInstColorIcon /> : <SSNInstSolidIcon />;
    case 15:
      return <SSNBlogIcon />;
    case 17:
      return iconType === 'color' ? <SSNThreadColorIcon /> : <SSNThreadSolidIcon />;
  }
  return null;
};

interface State {
  error: string;
}

class SocialMediaField extends React.Component<SocialMediaFieldProps, State> {
  public props: SocialMediaFieldProps;
  state: State = {
    error: '',
  };

  render() {
    const { disabled, socialTypeValue, socialTypeValidationRule, onCancelClick, iconType, socialUserValidationRule, onSocialUserChange, onSocialUserBlur, onSocialTypeChange, socialUserValue} = this.props;
    let className = mergeClassNames(namespace(), this.props);
    if (disabled) className += ' disabled';
  
    return (
      <div className={`${className}`}>
        <Select value={socialTypeValue}
          className={`${namespace()}--select`}
          validationRules={socialTypeValidationRule}
          hideOptional
          disabled={disabled}
          mapValueToIcon={(id: number) => mapSocialTypeIDToIcon(id, iconType)}
          onChangeValue={onSocialTypeChange}
          icon={mapSocialTypeIDToIcon(socialTypeValue, iconType)} />
        <TextField
          hideOptional
          className={`${namespace()}--url`}
          validationRules={socialUserValidationRule}
          disabled={disabled}
          value={socialUserValue}
          onChange={onSocialUserChange}
          onBlur={onSocialUserBlur}/>
        {!disabled ? <div onClick={onCancelClick} className={`${namespace()}--close`}>
          <CloseIcon width='14px' height='14px' />
        </div> : null}
      </div>
    );
  }
} 

export default SocialMediaField;
