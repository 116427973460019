import { CLEAR_CACHE_PREFIX, createApiSubmitActions, createRequestActions } from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { ADMIN_EVENT_TYPES_SUFFIX, ADMIN_EVENTS_MODULE_SUFFIX } from "../CacheOne/actions";
import { UPDATE_FORM_ACTION_SUFFIX } from "../../../utils/suffix";
import { typeName, Action } from "../../../utils/StrongActions";

export const UPDATE_ADMIN_CLASS_REQUIREMENT_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__ADMIN_CLASS_REUIQUIREMENT_FORM';
export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + UPDATE_ADMIN_CLASS_REQUIREMENT_SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + '__CACHE_THREE_CLASS_REQUIREMENTS';
export const SUFFIX_SAVE = ADMIN_EVENT_TYPES_SUFFIX + '__CACHE_THREE_CLASS_REQUIREMENTS_SAVING';

export const GetAdminClassRequirements = createRequestActions(SUFFIX);
export const UpdateAdminClassRequirements = createApiSubmitActions(SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminEventsCacheThreeClassRequirements extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  getClassRequirements: (classId: number): ActionCreator => dispatch => dispatch(GetAdminClassRequirements.request({IDi: classId})),
  clearAdminEventsCacheThreeClassRequirements: (): ActionCreator => dispatch => {
    dispatch(new ClearAdminEventsCacheThreeClassRequirements());
  },
};