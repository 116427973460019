import { ActionCreator } from '../../..';
import { Action, typeName } from '../../../../utils/StrongActions';
import { ADMIN_CMS_MODULE_SUFFIX } from '../../CacheOne/actions';

export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__SETTINGS_MAIN';


@typeName('RESET_FORM' + SUFFIX)
export class AdminCMSSettingsResetForm extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
    reset: (): ActionCreator => dispatch => dispatch(new AdminCMSSettingsResetForm()),
};