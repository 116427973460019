import type { ApplicationState } from "../store";
import {ShowTopFloatingAlert} from "../store/App/actions";
import { reduxStoreService } from "../store/service";

export const checkPermission = (
  onSuccess: () => void,
  permission: boolean,
  reason: string | null | undefined,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (permission) {
    onSuccess();
  } else {
    if (reason && !noAlertOnFail) {
      reduxStoreService().dispatch(
        new ShowTopFloatingAlert(reason, inModal !== undefined ? inModal : false, 'orange')
      );
    }
  }
};

export const isAdmin = (rootState = reduxStoreService().getState()) => {
  return isAdminSelector(rootState);
};

export const isAdminSelector = (state: ApplicationState) => {
  return state.user.user ? Boolean(state.user.user.str_permissions.hasAdminAccess) : false;
};