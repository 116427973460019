import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import * as Actions from './actions';
import {ApiRosterSubmitActions, InitializeDefaultValues, Reset, SUFFIX, ValidateActions} from './actions';
import {EventRegisterParticipantReset} from "../Main/actions";
import * as M from "../../../../../../constants/messages/generic";
import {EventPersonFormValidator, FormDefinition, isYouth} from './validation';
import moment from 'moment';
import {PersonFormActiveForm, PersonFormValidatior} from '../../../../../../utils/personFormValidationRules';
import {apiValidationReducerCreator} from '../../../../../Validation/reducerCreator';
import {FullGroupRosterPerson} from '../../../../../../models/api/cacheTwoRoster';
import {APISuccess, WithRootState} from '../../../../../Validation/actionCreator';
import {
  EventParticipantCalc,
  EventParticipantsGetPerson,
  UnselectParticipant
} from '../../../../../CacheFourEventsParticipants/actions';
import {SetCacheAction} from '../../../../../App/actions';
import {
  EventRegistrationPerson,
  EventRegistrationPersonCustomField
} from '../../../../../../models/api/cacheFourEvents';
import {YesNoBlankOptions, setDefaults} from "../../../../../../utils/validator";
import {NameAndID} from "../../../../../../models/api/options";
import {EventTypeCustomFieldOption} from "../../../../../../models/api/cacheTwoEvents";
import { captureTentarooError } from '../../../../../../utils/dataHelper';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../../utils/StrongActions';

export interface EventPersonActiveForm {
  IsStaff?: -1 | 0 | 1;
  SummerCampYear?: number;
  InCampAllWeek?: boolean;
  InCampMon?: boolean;
  InCampTue?: boolean;
  InCampWed?: boolean;
  InCampThu?: boolean;
  InCampFri?: boolean;
  InCampSat?: boolean;
  InCampSun?: boolean;
  RegistrationNotes?: string;
}

export interface SimplePerson {
  PersonIDi: number;
  IsYouth: boolean;
  Name: string;
}

export interface EventRegisterParticipantRosterState {
  selectedTab: 'roster' | 'new_youth' | 'new_adult';
  ActiveForm: PersonFormActiveForm & EventPersonActiveForm;
  ValidationRules: PersonFormValidatior & EventPersonFormValidator;
  SubmitErrorMessage?: string;
  isDirty?: true;
}
const checkApiValidation = apiValidationReducerCreator(SUFFIX);
const getInitialState = (): EventRegisterParticipantRosterState => ({
  selectedTab: 'roster',
  ActiveForm: {},
  ValidationRules: { ...FormDefinition }
});

const EventRegisterParticipantRoster: Reducer<EventRegisterParticipantRosterState> = (oldState, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, act);
  if (act.type === ValidateActions.updateCurrType) {
    return {...state, isDirty: true};
  } else if (act.type === ApiRosterSubmitActions.successType) {
    return {...state, isDirty: false};
  } else if (
    act.type === EventParticipantCalc.successType || act.type === EventParticipantsGetPerson.successType ||
    isActionType(act, SetCacheAction)
  ) {
    let p, ep;
    let customRules: Array<EventRegistrationPersonCustomField> = [];
    if (isActionType(act, SetCacheAction)) {
      p = act.response.xhr.response.GroupRosterPerson;
      ep = act.response.xhr.response.EventRegistrationPerson;
      customRules = act.response.xhr.response.EventRegistrationPersonCustomFields;
      if (!p) return state;
    } else {
      const action = <WithRootState<APISuccess>> act;
      p = action.response.response.GroupRosterPerson;
      ep = action.response.response.EventRegistrationPerson;
      customRules = action.response.response.EventRegistrationPersonCustomFields;
    }
    const eventRegistrationPerson = <EventRegistrationPerson> ep;
    const newState = {...state};
    if (p) {
      const person = <FullGroupRosterPerson> p;

      const {DOB, BSAID, DateBalooTraining, DateBasicTrainingForPosition, DateYouthProtectionTraining} = person;
      newState.ActiveForm = {
        ...person,
        DOB: DOB ? moment(DOB) : undefined,
        BSAID: BSAID !== null ? BSAID + '' : undefined
      };

      if (DateBalooTraining) {
        newState.ActiveForm.DateBalooTraining = DateBalooTraining ? moment(DateBalooTraining) : undefined;
      }
      if (DateBasicTrainingForPosition) {
        newState.ActiveForm.DateBasicTrainingForPosition = DateBasicTrainingForPosition ? moment(DateBasicTrainingForPosition) : undefined;
      }
      if (DateYouthProtectionTraining) {
        newState.ActiveForm.DateYouthProtectionTraining = DateYouthProtectionTraining ? moment(DateYouthProtectionTraining) : undefined;
      }
    }

    if (ep && eventRegistrationPerson.IsStaff) {
      newState.ActiveForm.IsStaff = 1;
    } else if (ep && eventRegistrationPerson.IsStaff === false) {
      newState.ActiveForm.IsStaff = 0;
    } else {
      newState.ActiveForm.IsStaff = -1;
    }

    if (ep) {
      newState.ActiveForm.RegistrationNotes = ep.Notes;
      if (eventRegistrationPerson.SummerCampYear) {
        newState.ActiveForm.SummerCampYear = eventRegistrationPerson.SummerCampYear + '';
      }
      if (eventRegistrationPerson.InCampAllWeek) {
        newState.ActiveForm.InCampAllWeek = eventRegistrationPerson.InCampAllWeek;
      }
      if (eventRegistrationPerson.InCampMon) {
        newState.ActiveForm.InCampMon = eventRegistrationPerson.InCampMon;
      }
      if (eventRegistrationPerson.InCampTue) {
        newState.ActiveForm.InCampTue = eventRegistrationPerson.InCampTue;
      }
      if (eventRegistrationPerson.InCampWed) {
        newState.ActiveForm.InCampWed = eventRegistrationPerson.InCampWed;
      }
      if (eventRegistrationPerson.InCampThu) {
        newState.ActiveForm.InCampThu = eventRegistrationPerson.InCampThu;
      }
      if (eventRegistrationPerson.InCampFri) {
        newState.ActiveForm.InCampFri = eventRegistrationPerson.InCampFri;
      }
      if (eventRegistrationPerson.InCampSat) {
        newState.ActiveForm.InCampSat = eventRegistrationPerson.InCampSat;
      }
      if (eventRegistrationPerson.InCampSun) {
        newState.ActiveForm.InCampSun = eventRegistrationPerson.InCampSun;
      }
    }
    const newValidation = {...state.ValidationRules};
    if (customRules) {
      customRules.forEach((c) => {
        const k = `${c.ElementID}`;
        let newRule: any = {
          key: k
        };
        newRule.validatejs = {};
        newRule.validatejs[k] = {};
        if (c.Type === 'combobox') {
          const EventTypeCustomFieldOptions = act.rootState.cacheTwoEvents.EventTypeCustomFieldOptions as Array<EventTypeCustomFieldOption>;
          let options: Array<NameAndID> = [{ID: 0, Name: ''}];
          if (EventTypeCustomFieldOptions && EventTypeCustomFieldOptions.length > 0) {
            const customOptions = EventTypeCustomFieldOptions.find((cfo) => cfo.ElementID === c.ElementID);
            if (customOptions) {
              options = customOptions.ElementOptions;
            } else {
              captureTentarooError(new Error(`Could not have custom field options for ElementID ${c.ElementID}`));
            }
          }
          if (c.IsRequired) {
            if (c.IsYouth) newRule.isRequired = (rootState) => isYouth(rootState);
            else newRule.isRequired = (rootState) => !isYouth(rootState);
          }
          newRule.defaultValue = () => 0;
          newRule.options = {
            values: () => options,
            valueKey: (v) => v.ID,
            labelKey: 'Name',
            emptyValue: 0
          };
          if (c.IsRequired) {
            newRule.validatejs[k].numericality = {
              notGreaterThan: SELECT_NOT_GREATER_THAN,
              greaterThan: 0
            };
            newRule.validatejs[k].presence = {message: `^${M.REQUIRED}`};
          }
          if (c.NumberMax > 0 && c.IsRequired) {
            newRule.validatejs[k].numericality.notLessThan = `^The selected option is invalid.`;
            newRule.validatejs[k].numericality.lessThan = c.NumberMax + 1;
          }
        } else if (c.Type === 'textinput' && c.IsRequired) {
          if (c.IsYouth) newRule.isRequired = (rootState) => isYouth(rootState);
          else newRule.isRequired = (rootState) => !isYouth(rootState);

          newRule.validatejs[k].presence = {message: `^${M.REQUIRED}`};
          newRule.validatejs[k].length = {
              maximum: 1024,
              tooLong: M.MAX_LENGTH(1024)
          };
        } else if (c.Type === 'yesno') {
          if (c.IsRequired) {
            if (c.IsYouth) newRule.isRequired = (rootState) => isYouth(rootState);
            else newRule.isRequired = (rootState) => !isYouth(rootState);
          }
          newRule.defaultValue = () => -1;
          newRule.options = {
            values: () => YesNoBlankOptions,
            valueKey: (v) => v.ID,
            labelKey: 'Name',
            emptyValue: -1
          };
          if (c.IsRequired) {
            newRule.validatejs[k].presence = {message: `^${M.REQUIRED}`};
            newRule.validatejs[k].numericality = {
              notGreaterThan: SELECT_NOT_GREATER_THAN,
              greaterThan: -1
            };
          }
        }
        newValidation[k] = newRule;
        if (c.Type === 'combobox' || c.Type === 'yesno' || c.Type === 'textinput') {
          if (c.Value !== null && c.Value !== '' && c.Value !== undefined) {
            newState.ActiveForm[c.ElementID] = c.Value;
          }
        }
      });
    }

    newState.ValidationRules = newValidation;
    window.scrollTo(0,0);
    return newState;
  } else if (isActionType(act, InitializeDefaultValues)) {
    const newState = {
      ...state,
      ActiveForm: {},
      // `ValidationRules` might contain rules for custom fields, so we need to keep those, and reset
      // the rest that are defined in `FormDefinition`
      ValidationRules: { ...state.ValidationRules, ...FormDefinition },
    };
    return setDefaults(act.rootState, newState, state);
  } else if (isActionType(act, Actions.EventRegisterParticipantRosterSelectTab) || act.type === EventParticipantsGetPerson.requestType || isActionType(act, UnselectParticipant)) {
    // `state.ValidationRules` might contain rules for custom fields. Here we generate newValidationRules that
    // - Contains the original version of non-custom fields (i.e. clears error)
    // - Contains existing custom fields and mannually clear their error
    const newValidationRules = {...FormDefinition};
    Object.keys(state.ValidationRules).forEach((key) => {
      if (!FormDefinition[key]) {
        newValidationRules[key] = {...state.ValidationRules[key]};
        newValidationRules[key].errors = false;
      }
    });
    const newState = {
      ...state,
      ActiveForm: {},
      selectedTab: act.selectedTab || 'roster',
      SubmitErrorMessage: undefined,
      isDirty: true,
      ValidationRules: newValidationRules,
    };
    return newState;
  } else if (isActionType(act, EventRegisterParticipantReset) || isActionType(act, Reset)) {
    return {...getInitialState()};
  }
  return state || {...getInitialState()};
};

export default EventRegisterParticipantRoster;

