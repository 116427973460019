import * as React from 'react';
import PropTypes from 'prop-types';
import { ModalOrchestratorInterface } from './ModalOrchestrator';
import { noop } from '../../utils';

interface Props {
  children: React.ReactElement<any>;
  index: number;
  leave?: boolean;
  hide?: boolean;
  replace?: boolean;
  hidden?: boolean;
}

interface Context {
  orchestrator: ModalOrchestratorInterface;
}

export interface ModalProviderInterface extends React.PureComponent<Props, {}> {
  onClose(callback: Function);
  onHide(callback: Function);
  onReplace(callback: Function);
  close(callback?: Function);
  closeModal();
  back(callback: Function);
  setHistory(history: Array<React.ReactType>);
  isFromHidden(): boolean;
}

class ModalProvider extends React.Component<Props, {}> implements ModalProviderInterface {
  static childContextTypes = {
    provider: PropTypes.object,
    modal: PropTypes.object
  };

  static contextTypes = {
    orchestrator: PropTypes.object,
  };

  public context: Context;
  public props: Props;
  private onCloseCallback: Function;
  private onHideCallback: Function;
  private onReplaceCallback: Function;
  private history: Array<React.ReactType>;

  private closeStarted: boolean = false;

  public getChildContext = () => ({
    provider: this,
    modal: {
      close: this.close
    }
  });

  public onClose = (callback: Function) => this.onCloseCallback = callback;
  public onHide = (callback: Function) => this.onHideCallback = callback;
  public onReplace = (callback: Function) => this.onReplaceCallback = callback;
  public setHistory = (history: Array<React.ReactType>) => this.history = history;

  public isFromHidden = (): boolean => !!this.props.hidden;

  public hide = () => {
    if (!this.closeStarted) {
      this.closeStarted = true;
      if (this.onHideCallback) {
        this.onHideCallback(() => this.handleCloseFinish());
      } else {
        this.handleCloseFinish();
      }
    }
  };

  public replace = () => {
    // Replace is intended to close the modal in a different manner if the modal is being replaced by a modal of the
    // same type. This would allow the animation to be different in this case. This is not fully implemented because
    // we need to know if the new modal is the same type as the current modal and we currently don't know.
    // It would be cool if it could also detect the direction of the history to play the animation in the opposite
    // direction when going back. Leaving the code here because this is a feature that can be implemented later.
    this.close();
  };

  public closeModal = () => {
    this.context.orchestrator.closeFromProvider(this.props.index);
  };

  public close = (callback: Function = noop) => {
    if (!this.closeStarted) {
      this.closeStarted = true;
      if (this.onCloseCallback) {
        this.onCloseCallback(() => this.handleCloseFinish(callback));
      } else {
        this.handleCloseFinish(callback);
      }
    }
  };

  public handleCloseFinish = (callback: Function = noop) => {
    this.context.orchestrator.remove(this.props.index);
    if (callback) callback();
  };

  public back = (callback: Function) => {
    if (this.history) {
      this.context.orchestrator.back();
    }
    callback();
  };

  public componentDidUpdate(prevProps) {
    if (!prevProps.leave && this.props.leave) this.close();
    else if (!prevProps.hide && this.props.hide) this.hide();
    else if (!prevProps.replace && this.props.replace) this.replace();
  }

  public shouldComponentUpdate(nextProps) {
    return !(
      nextProps.leave === this.props.leave &&
      nextProps.hide === this.props.hide &&
      nextProps.replace === this.props.replace
    );
  }

  public render() {
    return this.props.children;
  }
}

export default ModalProvider;
