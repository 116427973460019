import { ActionCreator } from "../../..";
import { typeName, Action } from "../../../../utils/StrongActions";

export const SUFFIX = '__CMS_LOCATION_MODAL';

@typeName("FILTER" + SUFFIX)
export class LocationModalFilter extends Action {
  constructor(public filterString: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class LocationToggleFilterTabs extends Action {
  constructor(public searching: boolean) { super(); }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  filterLocations: (filterString: string): ActionCreator => dispatch => dispatch(new LocationModalFilter(filterString)),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new LocationToggleFilterTabs(searching)),
};