import * as React from 'react';
import { Card, MonthRange, MonthRangeGroup, CompactMonthRangeSkeleton, EmptyMessage } from '../../../Elements';
import * as moment from 'moment';
import {FacilityLocation} from "../../../../models/api/cacheOne";
import {CacheTwoFacilitiesState} from "../../../../store/CacheTwoFacilities";
import {FacilitiesAvailability} from "../../../../models/api/cacheTwoFacilties";
import { AdminFacilitiesAvailability } from '../../../../models/api/adminFacilitiesAvailability';
import { EmptyMessageType } from '../../../../components/Elements/EmptyMessage';
import { FacilitiesIcon } from '../../../../components/Icons';
import { ENTITY_NOT_FOUND } from '../../../../constants/messages/adminCMS';
import { FACILITIES } from '../../../../constants/messages/adminFacilityLocation';

interface Props {
  cacheTwoFacilities?: CacheTwoFacilitiesState;
  FacilitiesAvailabilities?: FacilitiesAvailability[] | AdminFacilitiesAvailability[];
  facilityLocation?: FacilityLocation;
  startTime: moment.Moment;
  endTime: moment.Moment;
  onClickAvail: (avail: FacilitiesAvailability) => void
  loading?: boolean;
  showAdminBadge?: boolean;
  hasArrowIcon?: boolean;
  hasEditButton?: boolean;
}

const CompactView: React.SFC<Props> = (props: Props): React.ReactElement<Props> => {
  const {loading, facilityLocation, showAdminBadge, cacheTwoFacilities, FacilitiesAvailabilities, startTime, endTime, hasEditButton, hasArrowIcon} = props;

  const availabilities = FacilitiesAvailabilities !== undefined ? FacilitiesAvailabilities : (cacheTwoFacilities ? cacheTwoFacilities.FacilitiesAvailabilities : undefined) as any[];

  if (availabilities === undefined) {
    return <Card template="mobile-no-margin" padding="none">
      <MonthRangeGroup showLoaders hideTitles>
        <CompactMonthRangeSkeleton/>
      </MonthRangeGroup>
    </Card>;
  }
  if (availabilities === null || availabilities.length === 0) {
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        icon={FacilitiesIcon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={ENTITY_NOT_FOUND(FACILITIES)}
      />
    );
  }

  return (
    <Card template="mobile-no-margin" padding="none">
      <MonthRangeGroup hasArrowIcon={hasArrowIcon} hasEditButton={hasEditButton} showLoaders={loading} showAdminBadge={showAdminBadge}>
        {availabilities.map((avail) => (
          <MonthRange
            facilityLocation={facilityLocation}
            title={avail.Name}
            template="compact"
            start={startTime}
            end={endTime}
            availabilities={!loading ? avail.Availabilities : undefined}
            facilitiesAvailability={avail}
            onClickTitle={props.onClickAvail}
            showAdminBadge={avail.AllowOnlineBookingID !== 1}
          />
        ))}
      </MonthRangeGroup>
    </Card>
  );
};

export default CompactView;
