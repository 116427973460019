import {ViewReservationReset, ViewReservationShowMore} from "./actions";
import {FAC_TRIP_RESERVATION} from "../../../../constants/urls";
import {makeSingleSelector} from "../../../../utils/reselectHelper";
import {TripReservation} from "../../../../models/api/cacheThreeFacilities";
import {reservationSelector} from "../../../CacheThreeFacilities";
import { Reducer } from "redux";
import { isActionType } from "../../../../utils/StrongActions";

const selectedReservationId = (state) => {
  try {
    const path = state.routing.locationBeforeTransitions.pathname;
    const sp = path.split(`/${FAC_TRIP_RESERVATION}/`);
    const resId = sp[1].split('/')[0];
    return Number(resId);
  } catch(e) {
    return 0;
  }
};

export const makeSelectedReservationSelector = makeSingleSelector(
  (item: TripReservation, id: number) => item.ID === id,
  reservationSelector,
  selectedReservationId
);


export interface ViewReservationState {
  showMore: boolean;
}
const getInitialState: () => ViewReservationState = () => ({showMore: false});

const ViewReservation: Reducer<ViewReservationState> = (state, action) => {
  if (isActionType(action, ViewReservationShowMore)) {
    return {...state, showMore: action.show};
  } else if (isActionType(action, ViewReservationReset)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default ViewReservation;
