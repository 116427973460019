import { GetResourceParams } from "../../../constants/urls";
import { CLEAR_CACHE_PREFIX, createRequestActions } from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { ADMIN_CMS_MODULE_SUFFIX } from "../CacheOne/actions";
import { HideDrawer, SilentCancelAllAction } from '../../../store/App/actions';
import { UPDATE_FORM_ACTION_SUFFIX } from "../../../utils/suffix";
import { typeName, Action } from "../../../utils/StrongActions";


export const UPDATE_CMS_RESOURCE_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__CMS_RESOURCE';
export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__CACHE_TWO_RESOURCES';
export const SUFFIX_SAVE = ADMIN_CMS_MODULE_SUFFIX + '__CACHE_TWO_RESOURCES_SAVING';

export const GetResource = createRequestActions(SUFFIX + UPDATE_CMS_RESOURCE_FORM_SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminCMSCacheTwoResource extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  getResource: (params: GetResourceParams): ActionCreator => dispatch => {
    dispatch(GetResource.request(params));
  },
  refreshResource: (params: GetResourceParams, isRefreshingResourceForm: boolean): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    dispatch(GetResource.request(params, {skipUpdateForm: !isRefreshingResourceForm}));
  },
  clearAdminCMSCacheTwoResource: (): ActionCreator => dispatch => {
    dispatch(new ClearAdminCMSCacheTwoResource());
  },
};