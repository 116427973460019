

import moment from 'moment';
import validatejs from 'validate.js';
import { DATE_PICKER_FORMAT } from '@tentaroo/shared';
import {ValidationResults} from "@tentaroo/core";

import {ApplicationState} from "../../store/index";
import {validateDate} from "../dateHelper";
import cloneDeep from 'lodash.cloneDeep';
import { reduxStoreService } from '../../store/service';
import { SelectValidator, Validator } from './models';

validatejs.extend(validatejs.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: function(value, options) {
    if (typeof value === 'string') {
      const date = validateDate(value);
      if (!date) return NaN;

      const ret = +moment.utc(value);
      return ret;
    }
    const ret = +moment.utc(value);
    return ret;
  },
  // Input is a unix timestamp
  format: function(value, options) {
    const ret = moment.utc(value).format(DATE_PICKER_FORMAT);
    return ret;
  },
  // validatejs doesn't treat date like normal validation for some reason...
  notValid: (value, attribute, validatorOptions) => {
    return validatorOptions.notValid;
  },

  tooEarly: (value, attribute, validatorOptions) => {
    return validatorOptions.tooEarly;
  },

  tooLate: (value, attribute, validatorOptions) => {
    return validatorOptions.tooLate;
  },
});



export const chainMerge = (ActiveForm, values) => ({...ActiveForm, ...values});

const chainSetDefault = (values: any, v: Validator, rootState: ApplicationState = reduxStoreService().getState(), stateSelector?: (state) => any) => {
  if (v.chainDependants && stateSelector) v.chainDependants.forEach((dep: string) => {
    const vDep = stateSelector(rootState).ValidationRules[dep] as Validator;
    if (vDep.chainValue) values[vDep.key] = vDep.chainValue(rootState, values);
    else if (vDep.defaultValue) values[vDep.key] = vDep.defaultValue(rootState);
    chainSetDefault(values, vDep, rootState, stateSelector);
  });
};

export const setDefaults = (rootState: ApplicationState, initState: any, oldState?: any) => {
  const ValidationRules = oldState ? oldState.ValidationRules : initState.ValidationRules;

  const newState = {
    ...initState,
    ActiveForm: { ...initState.ActiveForm },
  };
  Object.keys(ValidationRules).forEach((k) => {
    if (ValidationRules[k].defaultValue) {
      /**
       * Same condition to `if (action.vObj.getValue)` in reducerCreator.ts, where
       * we set a customized value to the form, derived from the passed in value. This is only useful for RegistrationType under CreateAccount for now
       */
      if (ValidationRules[k].getValue) {
        newState.ActiveForm[k] = ValidationRules[k].getValue(rootState, ValidationRules[k].defaultValue(rootState));
      } else {
        newState.ActiveForm[k] = ValidationRules[k].defaultValue(rootState);
      }
      
    }
  });

  return {...newState};
};

// @todo-minor-performance: creating forEach function everytime
export const validate = (v: Validator, value, stateSelector?: (state) => any, vDepStateSelector?: (state) => any) : ValidationResults => {
  /**
   * By defining a new empty errors object at the beginning here, we will clear
   * errors for local dependants by default - unless `validateLocalDependants` is set, then
   * we run `validate()` for each local dependant and set its error (if any) to this object
   */
  let errors = {};
  let values = {};
  
  const state = reduxStoreService().getState();
  if (v.localDependants && stateSelector) {
    v.localDependants.forEach((dep: string) => {
      const dependantValidationRule = vDepStateSelector ? vDepStateSelector(state).ValidationRules[dep] : stateSelector(state).ValidationRules[dep];
      const dependantValue = vDepStateSelector ? vDepStateSelector(state).ActiveForm[dep] : stateSelector(state).ActiveForm[dep];
      /**
       * Differenct between the two errors:
       * - `dependantValidationRule.errors` is error for that dependant field before current update
       * - `dependantError` is error for that dependant field after current update
       */
      if (v.validateLocalDependants) {
        // Validate local dependants, only do so when `validateLocalDependants` is set
        const dependantError = validate(dependantValidationRule, dependantValue, stateSelector, vDepStateSelector).Errors;

        if (dependantError[dependantValidationRule.key]) errors[dependantValidationRule.key] = dependantError[dependantValidationRule.key];
        else errors[dependantValidationRule.key] = false;
      }
      if (
        !v.validateLocalDependants &&
        !v.skipResetLocalDependantsToDefault &&
        dependantValidationRule.defaultValue
      ) {
        values[dependantValidationRule.key] = dependantValidationRule.defaultValue(state);
      }
    });
  }

  chainSetDefault(values, v, state, vDepStateSelector ? vDepStateSelector : stateSelector);

  let val = {};
  val[v.key] = value;
  // && (!v.isRequired || (v.isRequired && v.isRequired()))
  if (v.validatejs) {
    // let validator = {...v.validatejs};
    let validator = cloneDeep(v.validatejs);
    if (v.isRequired && !v.isRequired(state)) {
      if ((v as SelectValidator).options && validator[v.key].numericality) {
        validator[v.key].numericality = undefined;
      }
      validator[v.key].presence = undefined;
    }
    if (v.validatejs[v.key].equality && stateSelector) {
      const matchKey = v.validatejs[v.key].equality.attribute;
      val[matchKey] = stateSelector(state).ActiveForm[matchKey];
    }
    if (v.equalityDependant && stateSelector) {
      val[v.equalityDependant] = stateSelector(state).ActiveForm[v.equalityDependant];
      validator[v.equalityDependant] = stateSelector(state).ValidationRules[v.equalityDependant].validatejs[v.equalityDependant];

      validator[v.equalityDependant] = {
        equality: {
          ...validator[v.equalityDependant].equality,
        },
      };
      const invalid = validatejs(val, validator);
      if (invalid) {
        errors = {...errors, ...invalid};
        if (!invalid[v.key]) errors[v.key] = false;
        if (!invalid[v.equalityDependant]) errors[v.equalityDependant] = false;
      } else {
        errors[v.key] = false;
        errors[v.equalityDependant] = false;
      }
    } else {
      const invalid = validatejs(val, validator);
      if (invalid) errors = {...errors, ...invalid};
      else errors[v.key] = false;
    }
  } else {
    values[v.key] = value;
  }

  // Skip custom validate if there is already error found above for this field
  if (v.customValidate && !errors[v.key]) {
    const customError = v.customValidate(state);

    if (customError) {
      errors[v.key] = [customError];
    } else {
      // Need to set this to clear custom error shown under input
      errors[v.key] = false;
    }
  }

  return {Errors: errors, Values: values};
};

export const validateAll = (stateSelector: (s: ApplicationState) => any) : ValidationResults | undefined => {
  const rootState = reduxStoreService().getState();
  const formValues = stateSelector(rootState).ActiveForm;
  const formValidation = stateSelector(rootState).ValidationRules;
  let fullValidationObject = {};
  let errors, apiErrors, customErrors, customValidation = {};

  for (let key in formValidation) {
    const v = formValidation[key];
    if (v.isChainRoot) {
      chainSetDefault(formValues, v, rootState, stateSelector);
    }
  }
  for (let key in formValidation) {
    const v = formValidation[key];
    if (v && v.validatejs && (!shouldSkip(rootState, v))) {
      // fullValidationObject[key] = {...v.validatejs[key]};
      fullValidationObject[key] = cloneDeep(v.validatejs[key]);
      if (v.isRequired && !v.isRequired(rootState)) {
        if (v.options && fullValidationObject[v.key].numericality) {
          fullValidationObject[v.key].numericality = undefined;
        }
        fullValidationObject[v.key].presence = undefined;
      }
    }
    if (v && v.apiCheck && (v.apiCheck.state === 'failure' || v.apiCheck.state === 'loading')) {
      if (!apiErrors) apiErrors = {};
      apiErrors[key] = v.errors;
    }
    if (v.customValidate) customValidation[key] = v.customValidate;
  }

  // We are creating a new object to perform validation here because
  // `formValues` is selected directly from redux state, and we dont
  // want to mutate it which could result in unexpected behavior
  const validateFormValues = {...formValues};

  Object.keys(formValues).forEach((key) => {
    if (moment.isMoment(formValues[key])) {
      validateFormValues[key] = moment(formValues[key]).format(DATE_PICKER_FORMAT);
    }
  });
  const invalid = validatejs(validateFormValues, fullValidationObject);
  Object.keys(customValidation).forEach((k) => {
    const customError = customValidation[k](rootState);
    if (customError) {
      if (!customErrors) customErrors = {};
      customErrors[k] = [customError];
    }
  });
  if (!invalid && !apiErrors && !customErrors) return;
  errors = {...apiErrors, ...invalid, ...customErrors};


  return {
    Errors: errors
  };
};

const shouldSkip = (rootState: ApplicationState, v?: Validator) => {
  if (!v) return false;
  if (v.skip) {
    return v.skip(rootState);
  } else if (v.skipUsingRequired) {
    if (!v.isRequired) return false;
    return !v.isRequired(rootState);
  }
  return false;
};

export const CouncilIDiDefaultValue = (rootState: ApplicationState) => {
  if (!rootState.cacheZero.options || !rootState.cacheZero.options.Councils || !rootState.session.SystemSettings) return 0;

  const councilExisted = rootState.cacheZero.options.Councils.find((c) => !!rootState.session.SystemSettings && c.IDi === rootState.session.SystemSettings.CouncilIDi);

  return councilExisted ? rootState.session.SystemSettings.CouncilIDi : 0;
};

export function isOptional(vObj?: Validator) {
  if (vObj) {
    if (vObj.isRequired) {
      const rootState = reduxStoreService().getState();
      // isRequired exists and it tells you it is not required
      return !vObj.isRequired(rootState);
    } else if (!vObj.isRequired) {
      // isRequired does not exist... then we'll see if presense exists on validatejs
      if (!vObj.validatejs) {
        return true;
      } else if (!vObj.validatejs[vObj.key].presence) {
        return true;
      }
    }
    return false;
  }
  // if null, it is optional because there's no rules requiring it
  return true;
}