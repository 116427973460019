import * as React from 'react';
import {
  Align,
  Button,
  Card,
  CardPadding,
  CardShowMore,
  CardSubTitle,
  CardTitle,
  Column,
  Ellipsis,
  Row,
  Separator,
  Text
} from '../../../../../Elements';
import {LocationsIcon} from '../../../../../Icons';
import FacilityStatuses from '../../../Facility/FacilityStatuses';
import FacilityFeatures from '../../../Facility/FacilityFeatures';
import {
  FacilitiesAvailability,
  FacilityLocation_FacilityType,
} from "../../../../../../models/api/cacheTwoFacilties";
import {createImageGallery} from "../../../../../../utils/imageHelper";
import * as moment from "moment";
import ImageGallery from 'react-image-gallery';
import { TripReservation, isTripReservation } from '../../../../../../models/api/cacheThreeFacilities';
import { WizardReservation } from '../../../../../../models/api/cacheFourFacilities';
import { getLocationPricingInfo } from '../../../../../../utils/helpers/facilityHelper';

interface Props {
  facilityOrReservation: FacilitiesAvailability | TripReservation;
  Reservation?: WizardReservation | null;
  selectedFacilityType: FacilityLocation_FacilityType;
  showMore: boolean;
  onShowMore: (show: boolean) => void;
}

class ReservationCard extends React.Component<{}, {}> {
  public props: Props;

  public componentDidMount() {
  }

  onViewMap = () => window.open(this.props.selectedFacilityType.FacilityMapURL);

  hasInfo = (info) => info.perPersonRatePerDay || info.flatRatePerDay || info.minPeople || info.maxPeople;

  public render() {
    const {showMore, onShowMore, facilityOrReservation, selectedFacilityType, Reservation} = this.props;
    const info = getLocationPricingInfo(facilityOrReservation, selectedFacilityType.NameSingular, Reservation);
    let startTime: string | moment.Moment = '';
    let endTime: string | moment.Moment = '';
    if (isTripReservation(facilityOrReservation)) {
      startTime = facilityOrReservation.StartDateTime;
    } else if (facilityOrReservation.CheckinTime) {
      startTime = facilityOrReservation.CheckinTime;
    }
    if (isTripReservation(facilityOrReservation)) {
      endTime = facilityOrReservation.EndDateTime;
    } else if (facilityOrReservation.CheckoutTime) {
      endTime = facilityOrReservation.CheckoutTime;
    }


    const images: Array<any> = createImageGallery(facilityOrReservation.Images);
    const showShowMore = facilityOrReservation.Description || selectedFacilityType.Description || selectedFacilityType.FacilityMapURL || facilityOrReservation.Features;
    return (
      <Card
        padding="none"
        footer={showShowMore ? <CardShowMore showLess={showMore} onClick={() => onShowMore(!showMore)}/> : undefined}
        template="mobile-stretch"
      >
        {images.length > 0 && <ImageGallery items={images} showPlayButton={false} showIndex showThumbnails={false} showBullets showFullscreenButton/>}

        <CardPadding layout="vertical" mobilePaddingVertical={16}>
          <CardTitle size={26} mobileSize={24} marginBottom={4}>{facilityOrReservation.Name}</CardTitle>
          <CardSubTitle>{selectedFacilityType.NameSingular}</CardSubTitle>
          {this.hasInfo(info) && <Row marginBottom={16}>
            {(!!info.flatRatePerDay || !!info.maxPeople) && <Column span={6} mobileSpan={12} layout="vertical">
              <Text weight="bold" color="green">{info.flatRatePerDay}</Text>
              <Text size={13} color="gray" marginBottom={0}>{info.maxPeople}</Text>
            </Column>}
            <Column span={6} mobileSpan={12} layout="vertical" mobileMarginBottom={8}>
              <Text weight="bold" color="green">{info.perPersonRatePerDay}</Text>
              <Text size={13} color="gray" marginBottom={0}>{info.minPeople}</Text>
            </Column>
          </Row>}
          <FacilityStatuses
            facilityInfo={facilityOrReservation}
            startTime={startTime}
            endTime={endTime}
            IsFacilityType={selectedFacilityType.IsFacilityType}
          />
        </CardPadding>
        {showMore && showShowMore ? <Separator marginVertical={0}/> : null}
        {showMore && showShowMore ? (
          <CardPadding layout="vertical" mobilePaddingVertical={16}>
            {facilityOrReservation.Description ? (
              <Ellipsis
                configs={{
                  lines: 5,
                  text: facilityOrReservation.Description,
                }}
                marginBottom={20} fontSize={15} />
            ) : null}
            {selectedFacilityType.Description ? (
              <Ellipsis
                configs={{
                  lines: 5,
                  text: selectedFacilityType.Description,
                }}
                marginBottom={20} fontSize={15} />
            ) : null}
            {selectedFacilityType.FacilityMapURL && <Row marginBottom={30}>
              <Align start><Button color="white" icon={<LocationsIcon color="gray"/>} onClick={this.onViewMap}>VIEW MAP</Button></Align>
            </Row>}
            <FacilityFeatures features={facilityOrReservation.Features}/>
          </CardPadding>
        ) : null}
      </Card>
    );
  }
}

export default ReservationCard;
