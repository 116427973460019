import {APISuccess} from "../Validation/actionCreator";
import {ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction} from "../App/actions";
import {ClearCacheBelowOne} from "../CacheOne/actions";
import {convertToMoment} from "../../utils/dateHelper";
import {PreviousOrder} from "../../models/api/cacheTwoPrevOrders";
import {GetPrevOrder} from "../CacheThreePrevOrders/actions";
import {GetPrevOrders} from "./actions";
import {SubmitManageOrderActions} from "../Settings/PrevOrders/ManageOrder/actions";
import {RefundOrFeeActions} from "../Settings/PrevOrders/RefundOrFee/actions";
import { Reducer } from "redux";
import { Action, isActionType } from "../../utils/StrongActions";

interface CacheTwoPrevOrdersCore {
  PreviousOrders?: Array<PreviousOrder> | null;
}

export interface GroupedOrder {
  year: number;
  orders: Array<PreviousOrder>;
}

export interface CacheTwoPrevOrderState extends CacheTwoPrevOrdersCore {
  groupedOrders?: Array<GroupedOrder>;
}

const setCacheTwoData = (data: CacheTwoPrevOrdersCore) => {
  const groupedOrders: Array<GroupedOrder> = [];
  const momentOrders = convertToMoment(data.PreviousOrders, ['DatePayment']);
  if (momentOrders) {
    momentOrders.forEach((order: PreviousOrder) => {
      const year = order.DatePayment.get('year');
      if (groupedOrders.length > 0) {
        const lastGroup = groupedOrders[groupedOrders.length - 1];
        if (lastGroup.year === year) {
          lastGroup.orders.push(order);
        } else {
          groupedOrders.push({
            year,
            orders: [order]
          });
        }
      } else {
        groupedOrders.push({
          year,
          orders: [order]
        });
      }
    });
  }

  return {
    PreviousOrders: momentOrders,
    groupedOrders
  };
};

const handleResponse = (state, responseData, request) => {
  if (responseData.PreviousOrders !== undefined){
    return setCacheTwoData(responseData);
  }
  return state;
};

const CacheTwoPrevOrder: Reducer<CacheTwoPrevOrderState> = (state, act: Action) => {
  if (act.type === GetPrevOrder.successType || act.type === GetPrevOrders.successType || act.type === SubmitManageOrderActions.successType || act.type === RefundOrFeeActions.successType) {
    const action = <APISuccess> act;
    return handleResponse(state, action.response.response, action.response.request);
  } else if (isActionType(act, SetCacheAction)) {
    return handleResponse(state, act.response.xhr.response, act.response.request);
  } else if (
    isActionType(act, ClearAllCache) || isActionType(act, ClearAllEndUserCacheButOptions) || isActionType(act, ClearCacheBelowOne)
  ) {
    return {};
  }
  return state || {};
};

export default CacheTwoPrevOrder;
