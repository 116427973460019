
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetAdminEventMessage} from "./actions";
import { getAdminEventMessageCache, getAdminEventMessageBody } from "../../../constants/adminEventsUrls";

export const adminEventsCacheTwoMessageEpic = makeRequestEpic(
  GetAdminEventMessage,
  getAdminEventMessageCache,
  getAdminEventMessageBody,
  true,
);