import * as React from 'react';
import { DATE_PICKER_FORMAT } from '@tentaroo/shared';
import '../../../../styles/elements/cms-page/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton, Vignette} from '../../index';
import { CMSPage, PageTypeIDValue } from '../../../../models/api/adminCMSCacheOne';
import { AdminCMSSiteCacheOneState } from '../../../../store/AdminCMSSite/CacheOne';
import { CloseIcon, OptionsIcon } from '../../../../components/Icons';
import * as moment from 'moment';
import ContextMenuComponent from './ContextMenu';
import { SELECTED_ITEM_DELETED } from '../../../../constants/messages/generic';
import { ADMIN_CMS_PAGE_PATH } from '../../../../routes';
import { getPageIcon } from '../../../../utils/helpers/adminCMSPageHelper';
import { CardCategory } from '../../Card';
import { namespace as cardNS } from "../../Card/constants";
import { Row } from '../../Grid';
import { isPathnameMatchingRoute } from '../../../../utils/urlHelper';
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--cms-page--card';

export enum PageCardType {
  IN_MODAL='in-modal',
  IN_MENU='in-menu',
  LIST='list'
}

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin';
  type: PageCardType;
  page: CMSPage;
  pageTypeName?: string;
  onEdit?: (page: CMSPage) => void;
  onPreview?: (page: CMSPage) => void;
  onCopyLink?: (page: CMSPage) => void;
  onRestore?: (page: CMSPage) => void;
  onRemove?: () => void;
  onDelete?: (page: CMSPage) => void;
  onClick?: (page: CMSPage) => void;
  adminCMSCacheOne?: AdminCMSSiteCacheOneState;
  disabled?: boolean;
  routes?: any;
  showDeletedPrompt?: boolean;
  compact?: boolean;
  params?: any;
  context?: string;
  cardCategory?: CardCategory;
}

const CMSPageCard: React.SFC<Props> = ({ children, type, template, onEdit, onDelete, onRemove, onCopyLink, page, pageTypeName, onPreview, onRestore, adminCMSCacheOne, routes, onClick, compact, showDeletedPrompt, cardCategory, params, disabled, context, ...props }: Props): React.ReactElement<Props> => {
  let className = attribute(namespace(), undefined, props);

  className += ` ${type}`;
  className += ` ${context}`;
  const deleted = page.Inactive;
  if (deleted) className += ' deleted';
  if (showDeletedPrompt) className += ' show-deleted-prompt';

  const onCardClick = (e) => {
    if (disabled) return;
    switch (type) {
      case PageCardType.LIST:
      case PageCardType.IN_MODAL:
        onClick && onClick(page);
        break;
      default:
        break;
    }
  };

  const renderInModal = () => {
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--icon ${page.PageTypeID === PageTypeIDValue.HOMEPAGE ? 'home' : ''}`}>{getPageIcon(page)}</div>
        <Row className={`${namespace()}--main--text`}>
          <div className={`${namespace()}--main--text--title`}>{page.Name}</div>
          <div className={`${namespace()}--main--text--subtitle ${page.PageTypeID === PageTypeIDValue.HOMEPAGE ? 'home' : ''}`}>{getSubtitle()}</div>
        </Row>
      </Row>
    );
  };

  const getSubtitle = (isMobile?: boolean) => {
    let subtitle = pageTypeName;

    if (isMobile) return subtitle;
    if (page.PageTypeID === PageTypeIDValue.CALENDAR_EVENT && page.StartDate) {
      subtitle += ` on ${moment(page.StartDate).format(DATE_PICKER_FORMAT)}`;
    } else if (page.PageTypeID === PageTypeIDValue.DISTRICT && page.District) {
      subtitle += `: ${page.District}`;
    } else if (page.PageTypeID === PageTypeIDValue.EVENT_MODULES && page.EventType) {
      subtitle += `: ${page.EventType}`;
    } else if (page.PageTypeID === PageTypeIDValue.FACILITY_MODULES && page.FacilityLocation) {
      subtitle += `: ${page.FacilityLocation}`;
    }

    return subtitle;
  };

  const onRemoveClick = () => {
    onRemove && onRemove();
  };

  const renderInMenu = () => {
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--icon ${page.PageTypeID === PageTypeIDValue.HOMEPAGE ? 'home' : ''}`}>{getPageIcon(page)}</div>
        <Row className={`${namespace()}--main--text`}>
          <div className={`${namespace()}--main--text--title`}>{page.Name}</div>
          <div className={`${namespace()}--main--text--subtitle ${page.PageTypeID === PageTypeIDValue.HOMEPAGE ? 'home' : ''}`}>{getSubtitle()}</div>
        </Row>
        <ActionButton
          disabled={disabled}
          onClick={onRemoveClick}
          icon={CloseIcon}
          className={`${namespace()}--main--close ${cardNS()}--close ${cardCategory} ${disabled ? 'disabled' : ''}`}
        />
      </Row>
    );
  };

  const onPreviewClick = (e) => {
    e.stopPropagation();
    onPreview && onPreview(page);
  };

  const onCopyLinkClick = (e) => {
    e.stopPropagation();
    onCopyLink && onCopyLink(page);
  };

  const onEditClick = (e) => {
    e.stopPropagation();
    !disabled && onEdit && onEdit(page);
  };

  const onRestoreClick = (e) => {
    e.stopPropagation();
    !disabled && onRestore && onRestore(page);
  };

  const onDeleteClick = (e) => {
    e.stopPropagation();
    !disabled && onDelete && onDelete(page);
  };

  const renderInList = () => {
    return (
      <Row className={`${namespace()}--main ${context}`}>
        <div className={`${namespace()}--main--icon ${page.PageTypeID === PageTypeIDValue.HOMEPAGE ? 'home' : ''}`}>{getPageIcon(page)}</div>
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>{page.Name}</Row>
          <Row className={`${namespace()}--main--text--second-row`}>
            <div className={`${namespace()}--main--text--subtitle ${page.PageTypeID === PageTypeIDValue.HOMEPAGE ? 'home' : ''}`}>{getSubtitle(compact)}</div>
            <div className={`${namespace()}--main--text--subtitle mobile ${page.PageTypeID === PageTypeIDValue.HOMEPAGE ? 'home' : ''}`}>{getSubtitle(true)}</div>
            {page.PageTypeID === PageTypeIDValue.BLOG_ARTICLE && page.IsExpired ? <div className={`${namespace()}--main--expired`}>Expired</div> : null}
            {page.ShortURL && !compact ? <div className={`${namespace()}--main--text--short-url`}>
              {`/${page.ShortURL}`}
            </div> : null}
          </Row>
        </div>
        {!page.Inactive && <ActionButton
          icon={OptionsIcon}
          className={`${namespace()}--options ${cardNS()}--options ${cardCategory}`}
          contextMenu={<ContextMenuComponent
            onPreview={onPreviewClick}
            onCopyLink={onCopyLinkClick}
            onEdit={onEditClick}
            onDelete={onDeleteClick} />}
        />}
        {page.Inactive && onRestore && <Button  className={`${namespace()}--main--restore ${cardNS()}--restore`} flat textColor="red" onClick={onRestoreClick}>RESTORE</Button>}
      </Row>
    );
  };

  let selected;

  if (routes && routes.length > 0) {
    const route = routes[routes.length - 1];
    selected = type === PageCardType.LIST &&isPathnameMatchingRoute(location.pathname, ADMIN_CMS_PAGE_PATH, 'pageId', page.ID);
  }


  return (
    <Card
      className={className}
      component={<section/>}
      template={template}
      selected={!!selected}
      padding="none"
      category={cardCategory || CardCategory.LIST}
    >
      <div id={generateDOMId(`admin-page-card-${page.ID}`)} className={`${namespace()}--wrapper`}
          onClick={onCardClick}>
        {type === PageCardType.IN_MODAL && renderInModal()}
        {type === PageCardType.IN_MENU && renderInMenu()}
        {type === PageCardType.LIST && renderInList()}
      </div>
      {deleted && showDeletedPrompt && <section className={`${namespace()}--deleted-prompt`}>{SELECTED_ITEM_DELETED}</section>}
    </Card>
  );
};

CMSPageCard.defaultProps = {
  template: 'mobile-no-margin',
};

export default CMSPageCard;