
import * as React from 'react';

import { Row, SimpleListFilter } from '../../../../../../components/Elements';
import { namespace } from '../';
import { ApplicationState } from '../../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../../../../../store/Admin/Modals/Accounts/actions";
import {actionCreators as appActionCreators} from '../../../../../../store/App/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../store/Rollback/actions';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { SelectValidator } from '../../../../../../utils/validator/models';

interface Props {

}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class ParticipantsFilters extends React.PureComponent<Props, {}> {
  public props: ConnectedProps & Props;

  onChangeEventFilter = (value: number, vObj: SelectValidator) => {
    this.props.actions.updateValue(value, vObj);
    if (this.props.adminAccounts.ActiveForm.EventTypeID !== undefined) this.props.actions.getParticipants(value, undefined, true);
  };

  onChangeYearFilter = (value: number, vObj: SelectValidator) => {
    const {actions: { updateValue, getParticipants }} = this.props;
    updateValue(value, vObj);
    if (this.props.adminAccounts.ActiveForm.Year !== undefined) getParticipants(undefined, value, true);
  };

  render() {
    const { adminAccounts: { ActiveForm, ValidationRules } } = this.props;
    return (
      <Row className={`${namespace()}--filter-row shadow`}>
        <SimpleListFilter
          label="Event Type"
          className={`${namespace()}--event-filter`}
          onChange={this.onChangeEventFilter}
          value={ActiveForm.EventTypeID}
          validationRules={ValidationRules.EventTypeID}
        />
        <SimpleListFilter
          className={`${namespace()}--year-filter`}
          label="Year"
          onChange={this.onChangeYearFilter}
          value={ActiveForm.Year}
          validationRules={ValidationRules.Year}
        />
      </Row>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    adminAccounts: state.adminAccounts,
  };
};

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
  ...rollbackActionCreators}, dispatch) });

const ConnectedParticipantsFilters = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ParticipantsFilters);

export default ConnectedParticipantsFilters;