import * as React from 'react';
import { MainRightSideBar, SideBarContent } from '../../Layouts';

export interface SideBarProps {
  content?: React.ReactElement<any>;
  open?: boolean;
  hideSidebar?: boolean;
  addedCount: number;
  onClose: () => void;
}

const SideBar: React.SFC<SideBarProps> = (
  { open, onClose, content, addedCount, hideSidebar }: SideBarProps
): React.ReactElement<SideBarProps> => (
  <MainRightSideBar tablet desktop open={open} onClose={onClose} hide={hideSidebar || addedCount <= 0}>
    <SideBarContent>
      {content}
    </SideBarContent>
  </MainRightSideBar>
);

export default SideBar;
