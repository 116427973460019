import * as React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/elements/product/product-group.scss';

const namespace = (): string => 'elements--product-group';

interface Props {
  children?: React.ReactNode;
  loading?: boolean;
}

interface State {
  selected?: any
};

// @deprecated: use card group instead
class ProductGroup extends React.Component<Props, State> {
  public static childContextTypes = {
    select: PropTypes.func,
    isSelected: PropTypes.func,
    unSelect: PropTypes.func
  };

  public props: Props;
  public state: State = {
    selected: null
  };

  public getChildContext = () => ({
    select: (instance) => this.setState({ selected: instance }),
    isSelected: (instance) => instance === this.state.selected,
    unSelect: (instance) => { if (instance === this.state.selected) this.setState({ selected: null }); }
  });

  public render() {
    return (
      <div className={`${namespace()} ${this.props.loading ? 'loading' : ''}`}>
        {this.props.children}
      </div>
    );
  }
}

export default ProductGroup;
