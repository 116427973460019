import * as React from 'react';
import {
  CampInfo, CampInfoRight, CampInfoTitle,
  CampInfoSubTitle, Button, CampInfoWarning, Media, ContactCard, Map,
  HeaderBlock, HeaderBlockPadding, Row, Column
} from '../../../../Elements';
import {withRouter, RouteComponentProps} from "react-router";
import {navPush} from "../../../../../utils/navHelper";
import {FAC_TRIP_ADD, FAC_TRIP_EDIT, FAC_TRIP_RESERVATION, URLS} from "../../../../../constants/urls";
import {displayTimeFrame, getFullAddress, spaceTo_} from "../../../../../utils";
import {SystemSettings} from "../../../../../models/api/registerForm";
import {ContactInfo, FacilityLocation, Image, Location} from "../../../../../models/api/cacheOne";
import ContactInfos from '../../ContactInfos';
import {FacilityTripPermissions} from "../../../../../models/api/cacheThreeFacilities";
import {getSubTitle} from "./Reservations";
import {FacilityLocation_Trip} from "../../../../../models/api/cacheTwoFacilties";
import {checkPermission} from "../../../../../utils/permissionHelper";
import {setSentryContext} from "../../../../../config/sentry";
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { captureTentarooError } from '../../../../../utils/dataHelper';

type ConnectedProps = RouteComponentProps<{},{locId: string; tripId: string; name: string;}>;

interface Props {
  facilityLocation: FacilityLocation;
  tentarooLocation: Location;
  contactInfo: ContactInfo;
  FacilityTripPermissions: FacilityTripPermissions;
  image?: Image | null;
  systemSettings: SystemSettings;
  showMapMobile?: boolean;
  onShowMapMobile: () => void;
  facilityTrip: FacilityLocation_Trip;
  locationId: number;
}

class CampInfoComponent extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  componentDidCatch(error: Error, errorInfo) {
    setSentryContext();
    error.message += " Error occurred in component containing map - Facilities/Trip/Summary/CampInfoComponent.tsx";
    captureTentarooError(error);
    throw error;
  };

  onEdit = () => {
    const {facilityTrip, router, locationId} = this.props;
    const url = `${URLS.FACILITY_TRIP}/${locationId}/${facilityTrip.GroupTripID}/${spaceTo_(facilityTrip.Name)}/${FAC_TRIP_EDIT}`;
    navPush(router, url);
  };

  onAddReservation = () => {
    const {router, params, FacilityTripPermissions} = this.props;
    checkPermission(
      () => {
        navPush(router, `${URLS.FACILITY_TRIP}/${params.locId}/${params.tripId}/${params.name}/${FAC_TRIP_RESERVATION}/${FAC_TRIP_ADD}`);

      },
      FacilityTripPermissions!.hasAllowAdd,
      FacilityTripPermissions!.hasAllowAddReason
    );
  };

  public render() {
    const {
      tentarooLocation, contactInfo, image, systemSettings, showMapMobile, onShowMapMobile,
      facilityTrip: {StartDateTime, EndDateTime, Name}
    } = this.props;

    return (
      <HeaderBlock hasLeftSideBar>
        <HeaderBlockPadding mobilePadding={false}>
          <CampInfo>
            <CampInfoRight
              alignWarning="right"
              warning={<CampInfoWarning size={14}>{displayTimeFrame(StartDateTime, EndDateTime)}</CampInfoWarning>}
              title={<CampInfoTitle size={22}>{Name}</CampInfoTitle>}
              subTitle={<CampInfoSubTitle size={14}>{getSubTitle(this.props.facilityTrip)}</CampInfoSubTitle>}
              zeroMobileBottomPadding
            >
              <Row mobileMarginBottom={0}>
                <Column marginRight={16}>
                  <Button color="green" onClick={this.onAddReservation} mobileMarginBottom={16}>ADD RESERVATION</Button>
                </Column>
                <Column>
                  <Button
                    id={generateDOMId("fac-manage-trip")}
                    color="white"
                    onClick={this.onEdit}
                    mobileMarginBottom={16}
                  >
                    MANAGE TRIP
                  </Button>
                </Column>
              </Row>
            </CampInfoRight>
          </CampInfo>
        </HeaderBlockPadding>
        <Media mobile>
          <ContactCard
            contacts={false}
            mapIsSelected={showMapMobile}
            onMapClick={onShowMapMobile}
            title={tentarooLocation.Name}
            fullAddress={getFullAddress(tentarooLocation)}
          />
          {showMapMobile ? <Map width="100%" height="240px" marginBottom={10} address={getFullAddress(tentarooLocation)}/> : null}
          <HeaderBlockPadding padding="6px 0">
            <ContactInfos
              contactInfoLabel="Facilities Contact"
              location={tentarooLocation}
              contactInfo={contactInfo}
              systemSettings={systemSettings}
            />
          </HeaderBlockPadding>
        </Media>
      </HeaderBlock>
    );
  }
}

export default withRouter(CampInfoComponent);
