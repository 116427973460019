import * as React from 'react';
import ReactList from 'react-list';
import '../../../styles/elements/simple-list/index.scss';

export const namespace = (): string => 'elements--simple-list';

export interface SimpleListProps {
  itemRenderer: (index: number) => any; // @todo: should be react component not any
  totalLength: number;
  type?: 'simple' | 'variable' | 'uniform',
  className?: string;
  itemSizeGetter?: (index: number) => any;
  threshold?: number;
  useStaticSize?: boolean;
  useTranslate3d?: boolean;
  minSize?: number;
}

class SimpleList extends React.Component<SimpleListProps> {
  public props: SimpleListProps;

  render() {
    const {itemRenderer, totalLength, className, type, itemSizeGetter, threshold, useStaticSize, useTranslate3d, minSize} = this.props;
    return (
      <div className={`${namespace()} ${className}`}>
        <ReactList
          ref='list'
          itemRenderer={itemRenderer}
          useStaticSize={useStaticSize}
          useTranslate3d={useTranslate3d}
          length={totalLength}
          minSize={minSize || 20}
          type={type || 'uniform'}
          itemSizeGetter={itemSizeGetter || null}
          threshold={threshold || 100}
        />
      </div>
    );
  }
}

export default SimpleList;
