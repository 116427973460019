import * as React from 'react';

import { Filter, FilterRow, FilterSearch, FilterColumn } from '../../../../../components/Layouts';
import { Switch } from '../../../../../components/Elements';
import { Validator } from '../../../../../utils/validator/models';

export const namespace = (): string => 'filters--facility-types';

interface Props {
  className?: string;
  disabled?: boolean;
  ActiveForm: any;
  ValidationRules: any;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
    toggleExpandFilter: (expand: boolean) => any,
  };
  newButton: React.ReactNode;
}

class ProductsFilter extends React.Component<Props, {}> {

  public render() {
    const {disabled, ActiveForm, ValidationRules, reduxActions, newButton} = this.props;
    return (
      <Filter className={namespace()}>
        <FilterRow>
          <FilterSearch
            disabled={disabled}
            ActiveForm={ActiveForm}
            searchKey='FilterText'
            ValidationRules={ValidationRules}
            reduxActions={reduxActions}
            mobileMarginRight={0}
            mobileMarginBottom={0}
            align='center'
            width='228'
          />
          <FilterColumn
            lowerOrderInMobile
            width='half'
            marginRight={24}
            mobileMarginRight={24}
          >
            <Switch
              label="Show Deleted"
              multilineLabel
              newDesign
              onChange={reduxActions.updateValue}
              value={!!ActiveForm.ShowDeleted}
              validationRules={ValidationRules.ShowDeleted}
            />
          </FilterColumn>
          <FilterColumn alignSelf='flex-end' marginBottom={21} hideInMobile width='none'>
            {newButton}
          </FilterColumn>
        </FilterRow>
      </Filter>
    );
  }
}

export default ProductsFilter;