import { makeRequestEpic } from "../../../../../store/Validation/epicCreator";
import { AddSiteMenuItemSubmitActions, DeleteSiteMenuItemSubmitActions, AddPageMenuItemSubmitActions, DeletePageMenuItemSubmitActions } from "./actions";
import { getUpdateSiteMenuItemUrl, getUpdatePageMenuItemUrl } from "../../../../../constants/urls";
import { AjaxResponse } from "rxjs/Rx";
import { ENTITY_EDITED, ENTITY_CREATED, ENTITY_PARSE_ERROR, ENTITY_DELETED, ENTITY_RESTORED, PAGE_MENU_ITEM, SITE_MENU_ITEM } from "../../../../../constants/messages/adminCMS";
import { ModalAddPageMenuItemSubmitActions } from "../../Modals/PageMenuItem/actions";
import { captureTentarooError } from "../../../../../utils/dataHelper";

export const adminCMSSiteAddSiteMenuEpic = makeRequestEpic(
  AddSiteMenuItemSubmitActions,
  getUpdateSiteMenuItemUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      if (body.row.ID) {
        return ENTITY_EDITED(SITE_MENU_ITEM);
      }
      return ENTITY_CREATED(SITE_MENU_ITEM);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(SITE_MENU_ITEM)));
      return '';
    }
  }
);

export const adminCMSSiteDeleteSiteMenuEpic = makeRequestEpic(
  DeleteSiteMenuItemSubmitActions,
  getUpdateSiteMenuItemUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const item = response.response.CMSSiteMenuItem;

    if (item.Inactive) {
      return ENTITY_DELETED(SITE_MENU_ITEM);
    }
    return ENTITY_RESTORED(SITE_MENU_ITEM);
  },
);

export const adminCMSSiteAddPageMenuEpic = makeRequestEpic(
  AddPageMenuItemSubmitActions,
  getUpdatePageMenuItemUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      if (body.row.ID) {
        return ENTITY_EDITED(PAGE_MENU_ITEM);
      }
      return ENTITY_CREATED(PAGE_MENU_ITEM);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(SITE_MENU_ITEM)));
      return '';
    }
  }
);

export const adminCMSSiteAddPageMenuModalEpic = makeRequestEpic(
  ModalAddPageMenuItemSubmitActions,
  getUpdatePageMenuItemUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      if (body.row.ID) {
        return ENTITY_EDITED(PAGE_MENU_ITEM);
      }
      return ENTITY_CREATED(PAGE_MENU_ITEM);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(SITE_MENU_ITEM)));
      return '';
    }
  }
);

export const adminCMSSiteDeletePageMenuEpic = makeRequestEpic(
  DeletePageMenuItemSubmitActions,
  getUpdatePageMenuItemUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const item = response.response.CMSPageMenuItem;

    if (item.Inactive) {
      return ENTITY_DELETED(PAGE_MENU_ITEM);
    }
    return ENTITY_RESTORED(PAGE_MENU_ITEM);
  },
);