import {GetEventTypeData} from './actions';
import { getEventTypeData, getEventTypeDataBody } from '../../constants/urls';
import {makeRequestEpic} from "../Validation/epicCreator";

export const cacheTwoEventsEpic = makeRequestEpic(
  GetEventTypeData,
  getEventTypeData,
  getEventTypeDataBody,
  true
);
