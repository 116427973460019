import * as React from 'react';
import {Title, Button, SubTitle, ContentBlock, Media, ContextMenu, ContextMenuItem, ContextMenuInfo} from '../../../../Elements';
import Facility from './Facility';
import FacilityGroup from './FacilityGroup';
import {withRouter, RouteComponentProps} from "react-router";
import {navPush} from "../../../../../utils/navHelper";
import {FAC_TRIP_ADD, FAC_TRIP_RESERVATION, URLS} from "../../../../../constants/urls";
import {CacheThreeFacilitiesState} from "../../../../../store/CacheThreeFacilities";
import {CacheTwoFacilitiesState} from "../../../../../store/CacheTwoFacilities";
import {TripReservation} from "../../../../../models/api/cacheThreeFacilities";
import {standardCurrencyFormat} from "../../../../../utils/classesHelper";
import {spaceTo_} from "../../../../../utils";
import {checkPermission} from "../../../../../utils/permissionHelper";
import { generateDOMId } from '../../../../../utils/cypressHelper';

interface Props {
  cacheTwoFacilities: CacheTwoFacilitiesState;
  cacheThreeFacilities: CacheThreeFacilitiesState;
  activeReservations: Array<TripReservation>;
  onRestore: (reservation: TripReservation) => void;
  onSchedule: () => void;
}

type ConnectedProps = RouteComponentProps<{},{locId: string; tripId: string; name: string;}>;

const TIME_FORMAT = 'MMM D, YYYY, h:mm A';
const DATE_FORMAT = 'MMM D, YYYY';

export const getSubTitle = (trip: {NumYouth: number; NumAdults: number;}) => {
  if (trip.NumYouth && trip.NumAdults) {
    return `${trip.NumYouth} YOUTH, ${trip.NumAdults} ADULT${trip.NumAdults === 1 ? '' : 'S'}`;
  } else if (trip.NumYouth) {
    return `${trip.NumYouth} YOUTH`;
  } else {
    return `${trip.NumAdults} ADULT${trip.NumAdults === 1 ? '' : 'S'}`;
  }
};

export const getSubTitleLower = (trip: {NumYouth: number; NumAdults: number;}) => {
  if (trip.NumYouth && trip.NumAdults) {
    return `${trip.NumYouth} Youth, ${trip.NumAdults} Adult${trip.NumAdults === 1 ? '' : 's'}`;
  } else if (trip.NumYouth) {
    return `${trip.NumYouth} Youth`;
  } else {
    return `${trip.NumAdults} Adult${trip.NumAdults === 1 ? '' : 's'}`;
  }
};

class Reservations extends React.Component<Props, {}> {
  props: Props & ConnectedProps;

  onClickSchedule = () => {
    const {onSchedule, cacheThreeFacilities: {FacilityTripPermissions}} = this.props;
    checkPermission(
      () => {
        onSchedule();
      },
      FacilityTripPermissions!.hasViewReports,
      FacilityTripPermissions!.hasViewReportsReason
    );
  };

  onAddReservation = () => {
    const {
      cacheTwoFacilities: {locationID},
      cacheThreeFacilities: {FacilityTripPermissions, FacilityTrip, tripID}
    } = this.props;
    const onAdd = () => navPush(this.props.router, `${URLS.FACILITY_TRIP}/${locationID}/${tripID}/${spaceTo_(FacilityTrip!.Name)}/${FAC_TRIP_RESERVATION}/${FAC_TRIP_ADD}`);
    checkPermission(
      onAdd,
      !!FacilityTripPermissions && FacilityTripPermissions!.hasAllowAdd,
      !!FacilityTripPermissions ? FacilityTripPermissions!.hasAllowAddReason : null,
    );
  };

  renderControls = () => {

    return [
      <Media mobileAndSmaller>
        <Button key="add" color="green" onClick={this.onAddReservation} marginRight={16}>ADD RESERVATION</Button>
        <Button key="generate" color="white" contextMenu={(
          <ContextMenu title="Generate Reports" info={<ContextMenuInfo>Reports will not include any changes that are still in the cart</ContextMenuInfo>}>
            <ContextMenuItem onClick={this.onClickSchedule}>Trip Itinerary, PDF</ContextMenuItem>
          </ContextMenu>
        )}>
          REPORTS
        </Button>
      </Media>,
      <Media tabletAndGreater>
        <Button key="generate" color="white" marginRight={16} contextMenu={(
          <ContextMenu title="Generate Reports" info={<ContextMenuInfo>Reports will not include any changes that are still in the cart</ContextMenuInfo>}>
            <ContextMenuItem onClick={this.onClickSchedule}>Trip Itinerary, PDF</ContextMenuItem>
          </ContextMenu>
        )}>
          REPORTS
        </Button>
        <Button key="add" color="green" onClick={this.onAddReservation}>ADD RESERVATION</Button>
      </Media>
    ];
  };

  render() {
    const {
      cacheTwoFacilities: {FacilityLocation_FacilityTypes, locationID},
      cacheThreeFacilities: {tripMap, FacilityTrip, tripID, includedFacilityTypes}, activeReservations, onRestore
    } = this.props;

    return <ContentBlock>
      <Title
        num={activeReservations ? activeReservations.length : 0}
        controlsWrap={false}
        controls={this.renderControls()}
      >
        Reservations
      </Title>
      {includedFacilityTypes!.map((facType) => {
        if (!tripMap[facType.ID]) return null;
        return [
          <SubTitle>{facType.Name}</SubTitle>,
          <FacilityGroup>
            {tripMap[facType.ID].map((trip) => {
              return <Facility
                id={generateDOMId(`fac-reservation-${trip.ID}`)}
                image={(trip.Images && trip.Images.length > 0) ? trip.Images[0] : undefined}
                color={`#${trip.Color}`}
                name={trip.Name}
                subTitle={getSubTitle(trip)}
                checkIn={trip.StartDateTime.format(trip.ShowTimes ? TIME_FORMAT : DATE_FORMAT)}
                checkOut={trip.EndDateTime.format(trip.ShowTimes ? TIME_FORMAT : DATE_FORMAT)}
                price={trip.Amount !== 0 ? standardCurrencyFormat(trip.Amount) : undefined}
                InCart={trip.InCart}
                onClick={() => navPush(this.props.router, `${URLS.FACILITY_TRIP}/${locationID}/${tripID}/${spaceTo_(FacilityTrip!.Name)}/${FAC_TRIP_RESERVATION}/${trip.ID}/${spaceTo_(trip.Name)}`)}
                IsFacilityType={facType.IsFacilityType}
                onRestore={() => onRestore(trip)}
              />;
            })}
            {(!tripMap[facType.ID] || tripMap[facType.ID].length === 0) && null}
          </FacilityGroup>
        ];
      })}
    </ContentBlock>;
  }
}

export default withRouter(Reservations);
