
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, ModalActions, Button, Loader
} from '../../../../../Elements';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as cacheOneActionCreators } from "../../../../../../store/AdminFacilityLocation/CacheOne/actions";
import { actionCreators as cacheTwoFacilityTypesActionCreators } from "../../../../../../store/AdminFacilityLocation/CacheTwoFacilityTypes/actions";
import { actionCreators, FacilityTypeFormModalNamespace as namespace } from "../../../../../../store/AdminFacilityLocation/Facilities/FacilityType/Form/actions";
import { ApplicationState } from '../../../../../../store';
import FacilityTypeForm from '../../../Facilities/FacilityType/Form';
import '../../../../../../styles/pages/admin-facility-location/facilities/modals/facility-type-form/index.scss';
import { INVALID_FACILITY_TYPE } from '../../../../../../constants/messages/adminFacilityLocation';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import AdminFacilitiesLocationCacheManager from '../../../../../../utils/cacheManagers/adminFacilitiesLocationCacheManager';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { IAdminFacilityTypeRouterParams } from '../../../../../../utils/helpers/adminFacilityLocationPageHelper';
import { connect } from 'react-redux';
import { reduxStoreService } from '../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isAdminFacilityLocationCacheTwoFacilityTypePopulated} from '../../../../../../utils/cachePopulatedCheckers/adminFacilities';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminFacilityTypeRouterParams, {}>
>;

@(withRouter as any)
class FacilityTypeFormModal extends ComponentUpdateTemplate<ConnectedProps, {}> {

  public props: ConnectedProps;

  componentDidMount() {
    super.loadAndSetData(
      this.props,
      () => {
        AdminFacilitiesLocationCacheManager.getInstance().loadCacheTwoFacilityType({
          props: this.props,
          isStateNavigated: true,
          isEdit: !!this.props.facilityTypeId,
          facilityTypeIdFromModalProps: this.props.facilityTypeId
        });
      }
    );
  }

  componentWillUnmount() {
    this.onClose();
  }

  onFacilityTypeInactive() {
    const {actions} = this.props;

    actions.showTopFloatingAlert(INVALID_FACILITY_TYPE, true, 'orange');
  }
  onSave = () => {
    const {actions, routes, router} = this.props;

    actions.apiSubmitForm(routes, router);
  };

  onClose = () => {
    const {actions} = this.props;
    if (isAdminFacilityLocationCacheTwoFacilityTypePopulated(reduxStoreService().getState().adminFacilityLocation.cacheTwoFacilityTypes)) {
      actions.clearAdminFacilityLocationCacheTwoFacilityType();
    }
  };

  public render() {
    const { cacheZero, apiSaving, actions, adminFacilityLocationCacheOne, facilityTypeId, adminFacilityLocationCacheTwoFacilityTypes, formState, inert } = this.props;

    const title = facilityTypeId ? 'Edit Facility Type' : 'New Facility Type';
    const subtitle = adminFacilityLocationCacheOne.FacilitiesLocation ? adminFacilityLocationCacheOne.FacilitiesLocation.Name : '';
    const loading = !!facilityTypeId && !isAdminFacilityLocationCacheTwoFacilityTypePopulated(adminFacilityLocationCacheTwoFacilityTypes, formState.FacilityTypeRow.ActiveForm);
    const inactive = !!facilityTypeId && (adminFacilityLocationCacheTwoFacilityTypes.FacilitiesType && adminFacilityLocationCacheTwoFacilityTypes.FacilitiesType.FacilityTypeRow.Inactive);

    const noSavePermission = !cacheZero.options || !cacheZero.options.GeneralPermissions || (!!this.props.facilityTypeId ? !cacheZero.options.GeneralPermissions.hasFacilityTypeEdit : !cacheZero.options.GeneralPermissions.hasFacilityTypeAdd);

    return (
      <Modal
        inert={inert}
        onClose={this.onClose}
        inactive={inactive}
        onInactive={() => this.onFacilityTypeInactive()}
        wideModal
        big
        mobileFullScreen className={namespace()}
      >
        <ModalHeader subtitle={subtitle} className={`${namespace()}--header`}>{title}</ModalHeader>
        <ModalContent hasPadding>
          {!loading && 
            <FacilityTypeForm
              inert={inert}
              handleCompact
              hideFormAction
              cacheZero={cacheZero}
              adminFacilityLocationCacheOne={adminFacilityLocationCacheOne}
              formState={formState}
              reduxActions={actions}
              disabled={apiSaving > 0}
              loading={apiSaving > 0}
              action={!facilityTypeId ? 'add' : 'edit'}
              onSave={this.onSave}
          />}
          {loading && <Loader className={`${namespace()}--loader`} center />}
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button id={generateDOMId("admin-fac-type-modal-save-btn")} textColor='black' disabled={loading || apiSaving > 0 || noSavePermission} flat onClick={this.onSave}>{this.props.facilityTypeId ? 'SAVE' : 'CREATE'}</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const facilityTypeFormIdSelector = makeFormModalPropSelector(ModalTypes.FACILITY_TYPE_FORM, 'id');
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheZero: state.cacheZero,
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
    adminFacilityLocationCacheTwoFacilityTypes: state.adminFacilityLocation.cacheTwoFacilityTypes,
    formState: state.adminFacilityLocation.facilities.facilityType.form,
    facilityTypeId: facilityTypeFormIdSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...cacheOneActionCreators,
  ...cacheTwoFacilityTypesActionCreators,
  ...appActionCreators,
}, dispatch) });

const ConnectedFacilityTypeFormModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FacilityTypeFormModal);

export default ConnectedFacilityTypeFormModal;