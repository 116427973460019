import * as React from 'react';
import { Modal, ModalActions, Button, ModalContent, ModalHeader } from '../../../../Elements';
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";
import {actionCreators as appActionCreators} from "../../../../../store/App/actions";
import {ApplicationState} from "../../../../../store";
import '../../../../../styles/pages/admin/modals/air-app/index.scss';
import {getFlashFilePath} from "../../../../../constants/urls";
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import {WithInertAttribute} from '../../../../Elements/WithInert';

const namespace = () => 'pages--admin--modals--air-app';
type Props = WithInertAttribute<{
}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

@(withRouter as any)
class TentarooAirAppModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  onGetAdobeAir = () => {
    window.open('https://admin.tentaroo.com/airinstall');
  };

  onAirApp = () => {
    window.open(getFlashFilePath());
  };

  public render() {
    const {apiSaving, apiLoading, inert} = this.props;

    return (
      <Modal inert={inert} className={`${namespace()}`} mobileFullScreen bodyScrollLock big higherZIndex>
        <ModalHeader className={`${namespace()}--header`}>Tentaroo Legacy AIR App</ModalHeader>
        <ModalContent hasPadding>
          <div className={`${namespace()}--text`}>The remaining features from the old system that haven’t yet been implemented are now available as an AIR App that will run as a desktop app on both Mac/Windows. This AIR App does not require Flash. Instead use the buttons below to first install Adobe AIR, then download the Tentaroo AIR App.</div>
          <br />
          <div className={`${namespace()}--text`}>The Tentaroo AIR App will install as a program on your computer. This program can be used to generate general system and payment reports, manage admin users, manage campsites for events, and access the Registration tab under events for manual pricing overrides and campsite assignment.</div>
          <br />
          <div className={`${namespace()}--text`}>If you get an error during the Adobe AIR install, please let us know at support@tentaroo.com and tell us if you’re on a Mac or PC. We’ll then send you instructions for how to fix it.</div>
        </ModalContent>
        <ModalActions
          noPadding
          loading={apiSaving > 0 || apiLoading > 0}
          stacked
          sticky
          left={<Button flat textColor="black" onClick={this.onGetAdobeAir}>Get Adobe AIR</Button>}
          right={<Button textColor="green" flat onClick={this.onAirApp}>Tentaroo AIR App</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...appActionCreators,
}, dispatch)});

const ConnectedTentarooAirAppModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(TentarooAirAppModal);

export default ConnectedTentarooAirAppModal;
