import * as React from 'react';
import {default as AppHeader} from '../../../App/Header';
import {bindActionCreators} from 'redux';
import {actionCreators} from '../../../../store/CacheOne/actions';
import {actionCreators as facActionCreators } from '../../../../store/Events/EventTypes/actions';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapDispatchToProps>;

const Header: React.SFC<ConnectedProps> = ({actions}): React.ReactElement<ConnectedProps> => (
  <AppHeader
    showDrawer
    title="Events"
    cart
    refresh
    search
  />
);

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({...actionCreators, ...facActionCreators} as any, dispatch) });

const ConnectedHeader = connect(
  null,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(Header);

export default ConnectedHeader;
