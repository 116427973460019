
import { ToggleNotFound } from "../../store/App/actions";
import { SaveState } from "../../store/Rollback/actions";
import { extractRouteParams, isIdFromUrlValid } from "../urlHelper";
import { GetAdminEventClass } from "../../store/AdminEvents/CacheThreeClass/actions";
import { UpdateAdminEventClassSubmitActions } from "../../store/AdminEvents/Classes/Form/actions";
import { getAdminEventClassIdFromPath, getEventIdFromPath } from "../helpers/adminEventsPageHelper";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import { reduxStoreService } from "../../store/service";
import type AdminEventsCacheManager from "../cacheManagers/adminEventsCacheManager";
import { LoadAdminEventCacheThreeClassParams } from "./helpers/models";
import {isAdminEventsCacheThreeClassPopulated} from "../cachePopulatedCheckers/adminEvents";

export const ensureAdminEventsCacheThreeClass = (
  params: LoadAdminEventCacheThreeClassParams & {cacheManager: AdminEventsCacheManager},
): boolean => {
  const { props, isEdit, cacheManager, isStateNavigated } = params;

  if (!isIdFromUrlValid(props.params.eventTypeId) || !isIdFromUrlValid(props.params.eventId)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const rootState = reduxStoreService().getState();
  const adminEventsCacheThreeClass = rootState.adminEvents.cacheThreeClass;

  let requestedData = false;
  if (isEdit) {
    if (!isIdFromUrlValid(props.params.classId)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }

    if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminEventsCacheThreeClassPopulated(adminEventsCacheThreeClass)) {
      if (isStateNavigated) {
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      const classId = getAdminEventClassIdFromPath(props.params);

      reduxStoreService().dispatch(GetAdminEventClass.request({
        ClassIDi: classId,
        EventIDi: getEventIdFromPath(props.params),
        ...extractRouteParams(props),
      }));

      requestedData = true;
    }
  } else if (isStateNavigated) {
    requestedData = cacheManager.loadCacheTwoEvent({
      props,
      isEdit: true,
      isStateNavigated,
    });

    if (!requestedData) {
      reduxStoreService().dispatch(UpdateAdminEventClassSubmitActions.updateForm());
      reduxStoreService().dispatch(new SaveState());
    }
  }

  return requestedData;
};