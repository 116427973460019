import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../../store';
import { bindActionCreators } from 'redux';
import {
    actionCreators as adminCMSCacheOneActionCreators,
} from '../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as adminCMSCacheTwoPageMenusActions, GetPageMenuItem} from "../../../../../../store/AdminCMSSite/CacheTwoPageMenus/actions";
import { 
  actionCreators as rollbackActionCreators,
} from '../../../../../../store/Rollback/actions';
import { actionCreators, MenuItemSideModalNamespace as namespace } from "../../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/actions";
import { SideModal, SideModalContent, SideModalHeader, Loader, SideModalActions, Button } from '../../../../../../components/Elements';
import { makeSelectedResourceSelector, makeSelectedEventCategorySelector } from '../../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form';
import '../../../../../../styles/pages/menus/site-menu-item/index.scss';
import { getPageMenusRootUrl, constructCMSSiteUrlParams } from '../../../../../../constants/urls';
import { navPush } from '../../../../../../utils';
import '../../../../../../styles/pages/menus/site-menu-item/form.scss';
import { CMSPageMenuItem } from '../../../../../../models/api/adminCMSCacheOne';
import Form from './Form';
import { NotFound } from '../../../../../../components/Pages';
import { INVALID_MENU_ITEM } from '../../../../../../constants/messages/adminCMS';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { shouldReconfigRouter } from '../../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { noOpenedModals } from '../../../../../../utils/modalHelper';
import { reduxStoreService } from '../../../../../../store/service';
import {isAdminCMSCacheTwoPageMenuItemPopulated} from '../../../../../../utils/cachePopulatedCheckers/adminCMS';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{menuItemId: string, parentItemId: string}, {}>;

interface Props {
  type: 'add' | 'edit'
  onDelete?: (item: CMSPageMenuItem) => void;
}

class PageMenuItem extends React.Component<Props, {}> {
  public props: ConnectedProps & Props;

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);
    this.configRouter();
  }

  componentDidUpdate(prevProps: ConnectedProps & Props) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
  }

  routerWillLeave = (nextLocation) => {
    const {type, adminCMSCacheTwoPageMenuItems, actions} = this.props;

    if (reduxStoreService().getState().app.apiLoading === 0) {
      if (type !== "edit" || isAdminCMSCacheTwoPageMenuItemPopulated(adminCMSCacheTwoPageMenuItems)) {
        actions.saveState();
      }
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentWillUnmount() {
    const {type, adminCMSCacheTwoPageMenuItems, actions} = this.props;

    if (type !== "edit" || isAdminCMSCacheTwoPageMenuItemPopulated(adminCMSCacheTwoPageMenuItems)) {
      actions.clearAdminCMSCacheTwoPageMenuItem();
    }

    this.resetRouteLeaveHook();
  }

  configRouter () {
    const {router, routes} = this.props;
    router.setRouteLeaveHook(routes[routes.length - 1], this.routerWillLeave);
  }

  onSave = () => {
    this.props.actions.apiSubmitMenuItemForm(this.props.router, true);
  };
  onClickModal = (e) => {
    e && e.stopPropagation();
  };
  onBack = () => {
    if (!this.props.adminCMSCacheOne.CMSSite) return;
    this.props.actions.clearAdminCMSSiteCacheBelowOne();
    const backUrl = getPageMenusRootUrl(constructCMSSiteUrlParams(this.props, this.props.adminCMSCacheOne));
    navPush(this.props.router, backUrl);
  };
  onDelete = () => {
    if (!this.props.adminCMSCacheTwoPageMenuItems.CMSPageMenuItem) return;
    this.props.onDelete && this.props.onDelete(this.props.adminCMSCacheTwoPageMenuItems.CMSPageMenuItem);
  };

  renderNotFound = () => {
    return (
      <NotFound showCMSSiteHeader inModal message={INVALID_MENU_ITEM}/>
    );
  };

  getPermission = () => {
    const {type, adminCMSCacheOne} = this.props;
    let enabled = false, reason;

    if (type === 'add') {
      if (adminCMSCacheOne.CMSSite) {
        enabled = adminCMSCacheOne.CMSSite.hasPageMenuAdd;
        reason = adminCMSCacheOne.CMSSite.hasPageMenuAddReason;
      }
    } else {
      if (adminCMSCacheOne.CMSSite) {
        enabled = adminCMSCacheOne.CMSSite.hasPageMenuEdit;
        reason = adminCMSCacheOne.CMSSite.hasPageMenuEditReason;
      }
    }
    return {enabled, reason};
  };

  renderContent = () => {
    const {selectedResource, siteMenuItemForm: {ActiveForm, ValidationRules, SubmitErrorMessage}, type, actions, apiSaving, adminCMSCacheOne, cacheZero, selectedEventCategory} = this.props;

    const {enabled, reason} = this.getPermission();

    return [
        <SideModalContent lockBodyScroll key='page-menu-content'>
          <Form
            ActiveForm={ActiveForm}
            ValidationRules={ValidationRules}
            reduxActions={actions}
            disabled={apiSaving > 0 || !enabled}
            SubmitErrorMessage={SubmitErrorMessage}
            disabledReason={reason}
            action={type}
            cacheZero={cacheZero}
            adminCMSCacheOne={adminCMSCacheOne}
            selectedResource={selectedResource}
            selectedEventCategory={selectedEventCategory}
            selectedPage={ActiveForm.SelectedPage}
            loading={apiSaving > 0}
          />
        </SideModalContent>,
        <SideModalActions key='page-menu-actions'>
          {type === 'add' && <Button disabled={!enabled} flat textColor="black" onClick={this.onSave}>CREATE</Button>}
          {type === 'edit' && <Button id={generateDOMId("admin-page-menu-save-btn")} disabled={!enabled} flat textColor="black" onClick={this.onSave}>SAVE CHANGES</Button>}
          {type === 'edit' && <Button disabled={!enabled} flat textColor="red" onClick={this.onDelete}>DELETE</Button>}
        </SideModalActions>
    ];
  };

  public render() {
    const {type, adminCMSCacheTwoPageMenuItems, apiLoadingMap, siteMenuItemForm: {ActiveForm}, adminCMSCacheOne} = this.props;
    const loading = (
      (type === 'edit' && !isAdminCMSCacheTwoPageMenuItemPopulated(adminCMSCacheTwoPageMenuItems, ActiveForm)) ||
      apiLoadingMap[GetPageMenuItem.requestType]
    ) && noOpenedModals();

    const itemInactive = !adminCMSCacheTwoPageMenuItems.CMSPageMenuItem || adminCMSCacheTwoPageMenuItems.CMSPageMenuItem.Inactive;
    const parent = adminCMSCacheOne.CMSPageMenuItems ? adminCMSCacheOne.CMSPageMenuItems.find((p) => !adminCMSCacheTwoPageMenuItems.CMSPageMenuItem || !adminCMSCacheTwoPageMenuItems.CMSPageMenuItem.ParentID ? false : p.ID === adminCMSCacheTwoPageMenuItems.CMSPageMenuItem.ParentID) : null;
    const parentInactive = parent && parent.Inactive;

    let content;
    if (type === 'add') {
      content = this.renderContent();
    } else {
      if (!isAdminCMSCacheTwoPageMenuItemPopulated(adminCMSCacheTwoPageMenuItems, ActiveForm)) {
        content = null;
      } else if (itemInactive || parentInactive) {
        content = this.renderNotFound();
      } else {
        content = this.renderContent();
      }
    }
    return (
      <SideModal
        allowShadowClickDefault
        className={`${namespace()}`}
        onClick={this.onClickModal}
        stickyHeader
        header={<SideModalHeader
                  onBack={this.onBack}
                  subtitle={adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.SiteDomain : ''}
                  onlyShowSubTitleInMobile
                  title={type === 'add' ? "New Menu Item" : "Edit Menu Item"} />}
        >
        {content}
        {loading && <Loader className={`${namespace()}--loader`} center />}
      </SideModal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedResourceSelector = makeSelectedResourceSelector();
  const selectedEventCategorySelector = makeSelectedEventCategorySelector();
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    adminCMSCacheTwoPageMenuItems: state.adminCMSSite.cacheTwoPageMenuItems,
    cacheZero: state.cacheZero,
    siteMenuItemForm: state.adminCMSSite.menus.siteMenuItem.form,
    selectedResource: selectedResourceSelector(state),
    selectedEventCategory: selectedEventCategorySelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...rollbackActionCreators,
    ...appActionCreators,
    ...adminCMSCacheOneActionCreators,
    ...adminCMSCacheTwoPageMenusActions
  }, dispatch)
});
const ConnectedPageMenuItem = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps & Props>(),
)(PageMenuItem);

export default withRouter<Props>(ConnectedPageMenuItem);
