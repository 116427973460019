
/**
 * This file contains all constants related to pass word reset requests.
 */

import { APIURL, getQP } from "./urls";

export interface GetResetPasswordSearchParams {
  EmailOrUsername: string;
  DesiredURL?: string;
}
const RESET_PASSWORD_SERACH = "/User/ResetPassword_AccountSearch/?ParseJSON=0";
export const getResetPasswordSearch = () => `${APIURL}${RESET_PASSWORD_SERACH}${getQP(true)}`;

export interface ResetPasswordUpdateParams {
  ResetCode: string;
  AccountID: number;
  IsAdmin: boolean;
  NewPassword: string;
}
const RESET_PASSWORD_UPDATE = "/User/ResetPassword_UpdatePassword/";
export const getResetPasswordUpdate = () => `${APIURL}${RESET_PASSWORD_UPDATE}${getQP(true)}`;

export interface ResetPasswordUpdateGroupSendResetLinkParams {
  GroupIDi: number;
}
const RESET_PASSWORD_UPDATE_GROUP_SEND_RESET_LINK = "/campsGroups/UpdateGroup_SendResetLink/?ParseJSON=0";
export const getResetPasswordUpdateGroupSendResetLink = () => `${APIURL}${RESET_PASSWORD_UPDATE_GROUP_SEND_RESET_LINK}${getQP(true)}`;
export const getSendResetLinkBody = (GroupIDi: number): ResetPasswordUpdateGroupSendResetLinkParams => {
  return {
    GroupIDi,
  };
};