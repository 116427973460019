import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import * as M from '../../../../../constants/messages/generic';
import { makeDeletedPrefixItemMapper } from "../../../../../utils/dataHelper";
import { makeSelectedEventCategory } from "./selectors";
import { getDistrictsValues } from "../../../../AdminCMSSite/Menus/SiteMenuItem/Form/validation";
import { SelectValidator, Validator } from "../../../../../utils/validator/models";
import type { ApplicationState } from "../../../..";
import { isJustNames, isUsingNames, isUsingNumbers } from "./uiHelpers";

export const getEventCategoriesValue = (rootState: ApplicationState) => {
  return rootState.cacheZero.options ? rootState.cacheZero.options.EventCategories || [] : [];
};

export interface IEventTypeGeneralValidator {
  Name: Validator;
  Description: Validator;
  LeadersGuideURL: Validator;
  WebsiteURL: Validator;
  PrimaryContactName: Validator;
  PrimaryContactPhone: Validator;
  PrimaryContactEmail: Validator;
  AdditionalNotify1_Name: Validator;
  AdditionalNotify1_Email: Validator;
  AdditionalNotify2_Name: Validator;
  AdditionalNotify2_Email: Validator;
  RegistrationMethodID: Validator;
  LocationID: Validator;
  EventCategoryID: SelectValidator;
  DistrictIDi: SelectValidator;
  NewRegistrationMessageID: Validator;
}

export const EventTypeGeneralFormDefinition: IEventTypeGeneralValidator = {
  RegistrationMethodID: {
    key: 'RegistrationMethodID',
  },
  Name: {
    key: 'Name',
    isRequired: () => true,
    validatejs: {
      Name: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 200,
          tooLong: M.MAX_LENGTH(200)
        },
      },
    },
  },
  Description: {
    key: 'Description',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      Description: {
        length: {
          maximum: 2048,
          tooLong: M.MAX_LENGTH(2048),
        },
      },
    },
  },
  LeadersGuideURL: {
    key: 'LeadersGuideURL',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      LeadersGuideURL: {
        length: {
          maximum: 1024,
          tooLong: M.MAX_LENGTH(1024),
        },
        url: {
          message: '^' + M.INVALID_FULL_URL,
        },
      },
    },
  },
  WebsiteURL: {
    key: 'WebsiteURL',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      WebsiteURL: {
        length: {
          maximum: 1024,
          tooLong: M.MAX_LENGTH(1024),
        },
        url: {
          message: '^' + M.INVALID_FULL_URL,
        },
      },
    },
  },
  PrimaryContactName: {
    key: 'PrimaryContactName',
    isRequired: () => true,
    validatejs: {
      PrimaryContactName: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 200,
          tooLong: M.MAX_LENGTH(200),
        },
      },
    },
  },
  PrimaryContactPhone: {
    key: 'PrimaryContactPhone',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      PrimaryContactPhone: {
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50),
        },
      },
    },
  },
  PrimaryContactEmail: {
    key: 'PrimaryContactEmail',
    isRequired: () => true,
    validatejs: {
      PrimaryContactEmail: {
        presence: {message: '^' + M.REQUIRED},
        email: {message: '^' + M.INVALID},
        length: {
          maximum: 200,
          tooLong: M.MAX_LENGTH(200),
        },
      },
    },
  },
  AdditionalNotify1_Name: {
    key: 'AdditionalNotify1_Name',
    defaultValue: () => '',
    validatejs: {
      AdditionalNotify1_Name: {
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50)
        },
      },
    },
  },
  AdditionalNotify1_Email: {
    key: 'AdditionalNotify1_Email',
    defaultValue: () => '',
    validatejs: {
      AdditionalNotify1_Email: {
        email: {message: '^' + M.INVALID},
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50)
        },
      },
    },
  },
  AdditionalNotify2_Name: {
    key: 'AdditionalNotify2_Name',
    defaultValue: () => '',
    validatejs: {
      AdditionalNotify2_Name: {
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50)
        },
      },
    },
  },
  AdditionalNotify2_Email: {
    key: 'AdditionalNotify2_Email',
    defaultValue: () => '',
    validatejs: {
      AdditionalNotify2_Email: {
        email: {message: '^' + M.INVALID},
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50)
        },
      },
    },
  },
  LocationID: {
    key: 'LocationID',
    isRequired: () => true,
    customValidate: (rootState) => {
      const form = rootState.adminEvents.eventTypes.eventType.form.General.ActiveForm;
      const cache = rootState.adminEvents.cacheOne.EventsEventType;
      if (!form.LocationID) {
        return M.INVALID_SELECTED_LOCATION;
      } else {
        const location = rootState.cacheZero.options && rootState.cacheZero.options.Locations ? rootState.cacheZero.options.Locations.find((l) => l.ID === form.LocationID) : null;

        if (location && (location as any).Inactive && (cache && form.LocationID !== cache.General.LocationID)) {
          return M.INVALID_SELECTED_LOCATION;
        }
      }
    },
    validatejs: {
      LocationID: {
        presence: {message: '^' + M.INVALID_SELECTED_LOCATION},
      },
    },
  },
  EventCategoryID: {
    key: 'EventCategoryID',
    isRequired: () => true,
    customValidate: (rootState) => {
      const EventCategoryID = rootState.adminEvents.eventTypes.eventType.form.General.ActiveForm.EventCategoryID;

      if (EventCategoryID) {
        const eventCategory = rootState.cacheZero.options && rootState.cacheZero.options.EventCategories ? rootState.cacheZero.options.EventCategories.find((c) => c.ID === EventCategoryID) : null;

        if (eventCategory && eventCategory.Inactive) {
          return M.SELECTED_DROPDOWN_ITEM_DELETED;
        }
      }
    },
    options: {
      values: (rootState) => {
        const values = getEventCategoriesValue(rootState);
        
        return [
          {ID: 0, Name: ''},
          ...values
          .filter((s) => {
            return !s.Inactive || (s.Inactive && s.ID === rootState.adminEvents.eventTypes.eventType.form.General.ActiveForm.EventCategoryID);
          })
          .map(makeDeletedPrefixItemMapper('Name'))
        ];
      },
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: 0,
    },
    validatejs: {
      EventCategoryID: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        }
      },
    },
  },
  DistrictIDi: {
    key: 'DistrictIDi',
    isRequired: (rootState) => {
      const selectedCategorySelector = makeSelectedEventCategory();
      const selectedCategory = selectedCategorySelector(rootState);

      return Boolean(selectedCategory?.IsDistricts);
    },
    options: {
      values: (rootState) => [{IDi: 0, Name: ''}, ...getDistrictsValues(rootState)],
      valueKey: (v) => v.IDi,
      labelKey: 'Name',
      emptyValue: 0,
    },
    defaultValue: () => 0,
    validatejs: {
      DistrictIDi: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        },
      },
    },
  },
  NewRegistrationMessageID: {
    key: 'NewRegistrationMessageID',
    customValidate: (rootState) => {
      const form = rootState.adminEvents.eventTypes.eventType.form.General.ActiveForm;
      if (form.NewRegistrationMessageID) {
        const message = rootState.adminEvents.cacheOne.EventsEmailMessages ? rootState.adminEvents.cacheOne.EventsEmailMessages.find((m) => m.ID === form.NewRegistrationMessageID) : null;

        if (!message || message.Inactive) {
          return M.INVALID_SELECTED_MESSAGE;
        }
      }
    },
    validatejs: {
      NewRegistrationMessageID: {
      },
    },
  }
};


export interface IEventTypePricing1Validator {
  AllowSelfRegistration: Validator;
  MinNumberOfParticipants: Validator;
  AllowIncreasesToRegistration: Validator;
}

export const EventTypePricing1FormDefinition: IEventTypePricing1Validator = {
  AllowSelfRegistration: {
    key: 'AllowSelfRegistration',
  },
  MinNumberOfParticipants: {
    key: 'MinNumberOfParticipants',
    integerOnly: true,
    validatejs: {
      MinNumberOfParticipants: {
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
          lessThanOrEqualTo: 50,
          notLessThanOrEqualTo: M.MAX_VALUE(50),
        },
      },
    },
  },
  AllowIncreasesToRegistration: {
    key: 'AllowIncreasesToRegistration',
  }
};

export interface IEventTypePricing2Validator {
  UpfrontPaymentID: Validator;
  UpfrontPaymentSpecific_Youth: Validator;
  UpfrontPaymentSpecific_Adults: Validator;
  MultipleEventDiscountForYouth: Validator;
  MultipleEventDiscountID: Validator;
  MultipleEventDiscountAmount: Validator;
}

export const EventTypePricing2FormDefinition: IEventTypePricing2Validator = {
  UpfrontPaymentID: {
    key: 'UpfrontPaymentID',
  },
  UpfrontPaymentSpecific_Youth: {
    key: 'UpfrontPaymentSpecific_Youth',
    decimalOnly: true,
    skip: (rootState) => {
      return rootState.adminEvents.eventTypes.eventType.form.Pricing2.ActiveForm.UpfrontPaymentID !== 2;
    },
    isRequired: (rootState) => {
      return rootState.adminEvents.eventTypes.eventType.form.Pricing2.ActiveForm.UpfrontPaymentID === 2;
    },
    validatejs: {
      UpfrontPaymentSpecific_Youth: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
        },
      },
    },
  },
  UpfrontPaymentSpecific_Adults: {
    key: 'UpfrontPaymentSpecific_Adults',
    skip: (rootState) => {
      return rootState.adminEvents.eventTypes.eventType.form.Pricing2.ActiveForm.UpfrontPaymentID !== 2;
    },
    decimalOnly: true,
    isRequired: (rootState) => {
      return rootState.adminEvents.eventTypes.eventType.form.Pricing2.ActiveForm.UpfrontPaymentID === 2;
    },
    validatejs: {
      UpfrontPaymentSpecific_Adults: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
        },
      },
    },
  },
  MultipleEventDiscountForYouth: {
    key: 'MultipleEventDiscountForYouth',
  },
  MultipleEventDiscountID: {
    key: 'MultipleEventDiscountID',
  },
  MultipleEventDiscountAmount: {
    key: 'MultipleEventDiscountAmount',
    skip: (rootState) => {
      return !(isJustNames(rootState) && !!rootState.adminEvents.eventTypes.eventType.form.Pricing2.ActiveForm.MultipleEventDiscountForYouth);
    },
    decimalOnly: true,
    isRequired: (rootState) => {
      return isJustNames(rootState) && !!rootState.adminEvents.eventTypes.eventType.form.Pricing2.ActiveForm.MultipleEventDiscountForYouth;
    },
    validatejs: {
      MultipleEventDiscountAmount: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
        },
      },
    },
  },
};

export interface IEventTypePricing3Validator {
  PricingLevelsByDate_Early: Validator;
  PricingLevelsByDate_Base: Validator;
  PricingLevelsByDate_Late: Validator;
  AllowLateRegistrations: Validator;
  NumberOfDaysBeforeEventToCloseRegistration: Validator;
}

export const EventTypePricing3FormDefinition: IEventTypePricing3Validator = {
  PricingLevelsByDate_Early: {
    key: 'PricingLevelsByDate_Early',
    isRequired: () => true,
    validatejs: {
      PricingLevelsByDate_Early: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50)
        },
      }
    },
  },
  PricingLevelsByDate_Base: {
    key: 'PricingLevelsByDate_Base',
    isRequired: () => true,
    validatejs: {
      PricingLevelsByDate_Base: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50)
        },
      }
    },
  },
  PricingLevelsByDate_Late: {
    key: 'PricingLevelsByDate_Late',
    isRequired: () => true,
    validatejs: {
      PricingLevelsByDate_Late: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50)
        },
      }
    },
  },
  AllowLateRegistrations: {
    key: 'AllowLateRegistrations',
    localDependants: ['NumberOfDaysBeforeEventToCloseRegistration'],
  },
  NumberOfDaysBeforeEventToCloseRegistration: {
    key: 'NumberOfDaysBeforeEventToCloseRegistration',
    skip: (rootState) => {
      return !rootState.adminEvents.eventTypes.eventType.form.Pricing3.ActiveForm.AllowLateRegistrations;
    },
    defaultValue: () => 7,
    isRequired: (rootState) => {
      return !!rootState.adminEvents.eventTypes.eventType.form.Pricing3.ActiveForm.AllowLateRegistrations;
    },
    integerOnly: true,
    validatejs: {
      NumberOfDaysBeforeEventToCloseRegistration: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
          lessThanOrEqualTo: 60,
          notLessThanOrEqualTo: M.MAX_VALUE(60),
        },
      },
    },
  },
};
export const getAdminEventTypePricingTierValidationRule = (key: string): Validator => {
  return {
    key,
  };
};

export const getAdminEventTypeParticipantTypeIsYouthValidationRule = (key: string, id?: number): SelectValidator => {
  return {
    key,
    isRequired: () => true,
    defaultValue: () => 0,
    extraInfo: {
      id,
    },
    options: {
      values: () => {
        return [
          {ID: 0, Name: 'Youth'},
          {ID: 1, Name: 'Adults'},
        ];
      },
      valueKey: (v) => v.ID,
      labelKey: 'Name',
    },
    validatejs: {
      [key]: {
        presence: {message: '^' + M.REQUIRED},
        // NOTE: There's no blank option, so setting numericality to undefined
        numericality: undefined,
      },
    },
  };
};

export const getAdminEventTypeParticipantTypeNameValidationRule = (key: string, id?: number): Validator => {
  return {
    key,
    isRequired: () => true,
    extraInfo: {
      id,
    },
    validatejs: {
      [key]: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 100,
          tooLong: M.MAX_LENGTH(100)
        },
      },
    },
  };
};

export interface IEventTypePricing4Validator {
  LockInPricingBasedOnID: Validator;
}

export const EventTypePricing4FormDefinition: IEventTypePricing4Validator = {
  LockInPricingBasedOnID: {
    key: 'LockInPricingBasedOnID',
  },
};
export interface IEventTypePricing5Validator {
  AllowCCPayment: Validator;
  CCFeePercent: Validator;
  AllowECheckPayment: Validator;
  eCheckFeePercent: Validator;
}

export const EventTypePricing5FormDefinition: IEventTypePricing5Validator = {
  AllowCCPayment: {
    key: 'AllowCCPayment',
  },
  CCFeePercent: {
    key: 'CCFeePercent',
    decimalOnly: true,
    skip: (rootState) => {
      if (!rootState.session.SystemSettings) return false;

      return !(rootState.session.SystemSettings.AllowCCPayment && rootState.adminEvents.eventTypes.eventType.form.Pricing5.ActiveForm.AllowCCPayment);
    },
    validatejs: {
      CCFeePercent: {
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
          lessThanOrEqualTo: 100,
          notLessThanOrEqualTo: M.MAX_VALUE(100),
        },
      },
    },
  },
  AllowECheckPayment: {
    key: 'AllowECheckPayment',
  },
  eCheckFeePercent: {
    key: 'eCheckFeePercent',
    decimalOnly: true,
    skip: (rootState) => {
      if (!rootState.session.SystemSettings) return false;

      return !(rootState.session.SystemSettings.AllowECheckPayment && rootState.adminEvents.eventTypes.eventType.form.Pricing5.ActiveForm.AllowECheckPayment);
    },
    validatejs: {
      eCheckFeePercent: {
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
          lessThanOrEqualTo: 100,
          notLessThanOrEqualTo: M.MAX_VALUE(100),
        },
      },
    },
  },
};

const constructRequireFieldValidationRule = (key: string): SelectValidator => {
  return {
    key,
    isRequired: () => true,
    options: {
      values: () => {
        return [
          {required: true, Name: 'required'},
          {required: false, Name: 'optional'},
        ];
      },
      valueKey: (v) => v.required,
      labelKey: 'Name',
    },
    validatejs: {
      [key]: {
        presence: {message: '^' + M.REQUIRED},
        // NOTE: There's no blank option, so setting numericality to undefined
        numericality: undefined,
      }
    }
  };
};

export interface IEventTypeNamedParticipants_YouthValidator {
  ShowYouth: Validator;
  ShowGenderYouth: Validator;
  RequireGenderYouth: SelectValidator;
  ShowDOBYouth: Validator;
  RequireDOBYouth: SelectValidator;
  ShowAllergiesAndDietaryYouth: Validator;
  ShowSpecialNeedsNotesYouth: Validator;
  ShowRegistrationNotesYouth: Validator;
  RequireEmailYouth: SelectValidator;
  ShowPhoneYouth: Validator;
  RequirePhoneYouth: SelectValidator;
  ShowAddressYouth: Validator;
  RequireAddressYouth: SelectValidator;
  ShowEmergencyContact: Validator;
  RequireEmergencyContact: SelectValidator;
  ShowAllowPhotographyYouth: Validator;
  ShowShirtSizeYouth: Validator;
  RequireShirtSizeYouth: SelectValidator;
  ShowGradeYouth: Validator;
  RequireGradeYouth: SelectValidator;
  RequireRankYouth: SelectValidator;
  RequireShowSummerCampYear: SelectValidator;
  ShowSummerCampYearYouth: Validator;
  ShowUnitLeaderYouth: Validator;
  RequireUnitLeaderYouth: SelectValidator;
  ShowUnitPositionYouth: Validator;
  RequireUnitPositionYouth: SelectValidator;
  ShowBSAIDYouth: Validator;
  RequireBSAIDYouth: SelectValidator;
  ShowIsStaffYouth: Validator;
}

export const EventTypeNamedParticipants_YouthFormDefinition: IEventTypeNamedParticipants_YouthValidator = {
  ShowYouth: {
    key: 'ShowYouth',
  },
  ShowGenderYouth: {
    key: 'ShowGenderYouth',
  },
  RequireGenderYouth: constructRequireFieldValidationRule('RequireGenderYouth'),
  ShowDOBYouth: {
    key: 'ShowDOBYouth',
  },
  RequireDOBYouth: constructRequireFieldValidationRule('RequireDOBYouth'),
  ShowAllergiesAndDietaryYouth: {
    key: 'ShowAllergiesAndDietaryYouth',
  },
  ShowSpecialNeedsNotesYouth: {
    key: 'ShowSpecialNeedsNotesYouth',
  },
  ShowRegistrationNotesYouth: {
    key: 'ShowRegistrationNotesYouth',
  },
  RequireEmailYouth: constructRequireFieldValidationRule('RequireEmailYouth'),
  ShowPhoneYouth: {
    key: 'ShowPhoneYouth',
  },
  RequirePhoneYouth: constructRequireFieldValidationRule('RequirePhoneYouth'),
  ShowAddressYouth: {
    key: 'ShowAddressYouth',
  },
  RequireAddressYouth: constructRequireFieldValidationRule('RequireAddressYouth'),
  ShowEmergencyContact: {
    key: 'ShowEmergencyContact',
  },
  RequireEmergencyContact: constructRequireFieldValidationRule('RequireEmergencyContact'),
  ShowAllowPhotographyYouth: {
    key: 'ShowAllowPhotographyYouth',
  },
  ShowShirtSizeYouth: {
    key: 'ShowShirtSizeYouth',
  },
  RequireShirtSizeYouth: constructRequireFieldValidationRule('RequireShirtSizeYouth'),
  ShowGradeYouth: {
    key: 'ShowGradeYouth',
  },
  RequireRankYouth: constructRequireFieldValidationRule('RequireRankYouth'),
  RequireShowSummerCampYear: constructRequireFieldValidationRule('RequireShowSummerCampYear'),
  RequireGradeYouth: constructRequireFieldValidationRule('RequireGradeYouth'),
  ShowSummerCampYearYouth: {
    key: 'ShowSummerCampYearYouth',
  },
  ShowUnitLeaderYouth: {
    key: 'ShowUnitLeaderYouth',
  },
  RequireUnitLeaderYouth: constructRequireFieldValidationRule('RequireUnitLeaderYouth'),
  ShowUnitPositionYouth: {
    key: 'ShowUnitPositionYouth',
  },
  RequireUnitPositionYouth: constructRequireFieldValidationRule('RequireUnitPositionYouth'),
  ShowBSAIDYouth: {
    key: 'ShowBSAIDYouth',
  },
  RequireBSAIDYouth: constructRequireFieldValidationRule('RequireBSAIDYouth'),
  ShowIsStaffYouth: {
    key: 'ShowIsStaffYouth',
  },
};

export interface IEventTypeNamedParticipants_AdultsValidator {
  ShowAdults: Validator;
  RequireGenderAdult: SelectValidator;
  ShowDOBAdult: Validator;
  RequireDOBAdult: SelectValidator;
  ShowAllergiesAndDietaryAdult: Validator;
  ShowSpecialNeedsNotesAdult: Validator;
  ShowRegistrationNotesAdult: Validator;
  RequireEmailAdult: SelectValidator;
  ShowPhoneAdult: Validator;
  RequirePhoneAdult: SelectValidator;
  ShowAddressAdult: Validator;
  RequireAddressAdult: SelectValidator;
  ShowShirtSizeAdult: Validator;
  RequireShirtSizeAdult: SelectValidator;
  ShowUnitPositionAdult: Validator;
  RequireUnitPositionAdult: SelectValidator;
  ShowDateYouthProtectionTrainingAdult: Validator;
  RequireDateYouthProtectionTrainingAdult: SelectValidator;
  ShowDateBalooTrainingAdult: Validator;
  RequireDateBalooTrainingAdult: SelectValidator;
  ShowDateBasicTrainingForPositionAdult: Validator;
  RequireDateBasicTrainingForPositionAdult: SelectValidator;
  ShowDaysInCampAdult: Validator;
  ShowBSAIDAdult: Validator;
  RequireBSAIDAdult: SelectValidator;
}

export const EventTypeNamedParticipants_AdultsFormDefinition: IEventTypeNamedParticipants_AdultsValidator = {
  ShowAdults: {
    key: 'ShowAdults',
  },
  RequireGenderAdult: constructRequireFieldValidationRule('RequireGenderAdult'),
  ShowDOBAdult: {
    key: 'ShowDOBAdult',
  },
  RequireDOBAdult: constructRequireFieldValidationRule('RequireDOBAdult'),
  ShowAllergiesAndDietaryAdult: {
    key: 'ShowAllergiesAndDietaryAdult',
  },
  ShowSpecialNeedsNotesAdult: {
    key: 'ShowSpecialNeedsNotesAdult',
  },
  ShowRegistrationNotesAdult: {
    key: 'ShowRegistrationNotesAdult',
  },
  RequireEmailAdult: constructRequireFieldValidationRule('RequireEmailAdult'),
  ShowPhoneAdult: {
    key: 'ShowPhoneAdult',
  },
  RequirePhoneAdult: constructRequireFieldValidationRule('RequirePhoneAdult'),
  ShowAddressAdult: {
    key: 'ShowAddressAdult',
  },
  RequireAddressAdult: constructRequireFieldValidationRule('RequireAddressAdult'),
  ShowShirtSizeAdult: {
    key: 'ShowShirtSizeAdult',
  },
  RequireShirtSizeAdult: constructRequireFieldValidationRule('RequireShirtSizeAdult'),
  ShowUnitPositionAdult: {
    key: 'ShowUnitPositionAdult',
  },
  RequireUnitPositionAdult: constructRequireFieldValidationRule('RequireUnitPositionAdult'),
  ShowDateYouthProtectionTrainingAdult: {
    key: 'ShowDateYouthProtectionTrainingAdult',
  },
  RequireDateYouthProtectionTrainingAdult: constructRequireFieldValidationRule('RequireDateYouthProtectionTrainingAdult'),
  ShowDateBalooTrainingAdult: {
    key: 'ShowDateBalooTrainingAdult',
  },
  RequireDateBalooTrainingAdult: constructRequireFieldValidationRule('RequireDateBalooTrainingAdult'),
  ShowDateBasicTrainingForPositionAdult: {
    key: 'ShowDateBasicTrainingForPositionAdult',
  },
  RequireDateBasicTrainingForPositionAdult: constructRequireFieldValidationRule('RequireDateBasicTrainingForPositionAdult'),
  ShowDaysInCampAdult: {
    key: 'ShowDaysInCampAdult',
  },
  ShowBSAIDAdult: {
    key: 'ShowBSAIDAdult',
  },
  RequireBSAIDAdult: constructRequireFieldValidationRule('RequireBSAIDAdult'),
};

export interface IEventTypeNamedParticipants_OAFieldsValidator {
  ShowOAFields: Validator;
}

export const EventTypeNamedParticipants_OAFieldsFormDefinition: IEventTypeNamedParticipants_OAFieldsValidator = {
  ShowOAFields: {
    key: 'ShowOAFields',
  },
};


export interface IEventTypeGroupRegistrationValidator {
  DefaultGroupTypeID: SelectValidator;
  DefaultUnitTypeID: SelectValidator;
  ShowGroupTypeDuringAccountCreation: Validator;
  ShowYouth: Validator;
  ShowAdults: Validator;
  AllowNonScouterParticipants: Validator;
  ShowCampsiteRanking: Validator;
  RequireCampsiteRanking: Validator;
  ShowEndUserRegistrationNotes: Validator;
  RequireEndUserRegistrationNotes: Validator;
  PromptEndUserRegistrationNotes: Validator;
  PaymentRequirementsToAddParticipantsID: Validator;
  PaymentRequirementsMinimumPercentage: Validator;
  ShowEventContact: Validator;
  RequireEventContact: Validator;
}


const getValues = (rootState: ApplicationState, key) => {
  const opts = rootState.cacheZero.options;
  if (opts) return opts[key];
  return [];
};

export const EventTypeGroupRegistrationFormDefinition: IEventTypeGroupRegistrationValidator = {
  DefaultGroupTypeID: {
    key: 'DefaultGroupTypeID',
    isRequired: () => true,
    options: {
      values: (rootState) => getValues(rootState, 'GroupTypes'),
      valueKey: (v) => v.ID,
      labelKey: 'Name'
    },
    validatejs: {
      DefaultGroupTypeID: {
        presence: {message: '^' + M.REQUIRED},
        // NOTE: There's no blank option, so setting numericality to undefined
        numericality: undefined,
      },
    },
  },
  DefaultUnitTypeID: {
    key: 'DefaultUnitTypeID',
    isRequired: () => true,
    options: {
      values: (rootState) => getValues(rootState, 'UnitTypes'),
      valueKey: (v) => v.ID,
      labelKey: 'Name'
    },
    validatejs: {
      DefaultUnitTypeID: {
        presence: {message: '^' + M.REQUIRED},
        // NOTE: There's no blank option, so setting numericality to undefined
        numericality: undefined,
      },
    },
  },
  ShowGroupTypeDuringAccountCreation: {
    key: 'ShowGroupTypeDuringAccountCreation',
  },
  ShowYouth: {
    key: 'ShowYouth',
  },
  ShowAdults: {
    key: 'ShowAdults',
  },
  AllowNonScouterParticipants: {
    key: 'AllowNonScouterParticipants',
  },
  ShowCampsiteRanking: {
    key: 'ShowCampsiteRanking',
  },
  RequireCampsiteRanking: {
    key: 'RequireCampsiteRanking',
  },
  ShowEventContact: {
    key: 'ShowEventContact',
  },
  RequireEventContact: {
    key: 'RequireEventContact',
  },
  ShowEndUserRegistrationNotes: {
    key: 'ShowEndUserRegistrationNotes',
  },
  RequireEndUserRegistrationNotes: {
    key: 'RequireEndUserRegistrationNotes',
  },
  PromptEndUserRegistrationNotes: {
    key: 'PromptEndUserRegistrationNotes',
    defaultValue: () => '',
    skip: (rootState) => {
      return !(isUsingNumbers(rootState) && !!rootState.adminEvents.eventTypes.eventType.form.GroupAndRegistration.ActiveForm.ShowEndUserRegistrationNotes);
    },
    isRequired: () => false,
    validatejs: {
      PromptEndUserRegistrationNotes: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 1024,
          tooLong: M.MAX_LENGTH(1024)
        },
      }
    }
  },
  PaymentRequirementsToAddParticipantsID: {
    key: 'PaymentRequirementsToAddParticipantsID',
  },
  PaymentRequirementsMinimumPercentage: {
    key: 'PaymentRequirementsMinimumPercentage',
    skip: (rootState) => {
      return !(isUsingNames(rootState) && isUsingNumbers(rootState) && rootState.adminEvents.eventTypes.eventType.form.GroupAndRegistration.ActiveForm.PaymentRequirementsToAddParticipantsID === 2);
    },
    isRequired: (rootState) => {
      return isUsingNames(rootState) && isUsingNumbers(rootState) && rootState.adminEvents.eventTypes.eventType.form.GroupAndRegistration.ActiveForm.PaymentRequirementsToAddParticipantsID === 2;
    },
    integerOnly: true,
    validatejs: {
      PaymentRequirementsMinimumPercentage: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
          lessThanOrEqualTo: 100,
          notLessThanOrEqualTo: M.MAX_VALUE(100),
        },
      },
    },
  },
};
export interface IEventTypeClassSchedulingValidator {
  EnableClassWaitingListForIndividuals: Validator;
  ShowFullClassesForIndividuals: Validator;
  ShowFullClassesGroup: Validator;
  RequireUniqueClassTypes: Validator;
  ShowClassLocation: Validator;
  CustomLabelForParticipantClasses: Validator;
  MinimumScheduledTimeBlocks_Youth: Validator;
  MinimumScheduledTimeBlocks_Adults: Validator;
  MinimumScheduledTimeBlocks_Group: Validator;
}

export const EventTypeClassSchedulingFormDefinition: IEventTypeClassSchedulingValidator = {
  EnableClassWaitingListForIndividuals: {
    key: 'EnableClassWaitingListForIndividuals',
  },
  ShowFullClassesForIndividuals: {
    key: 'ShowFullClassesForIndividuals',
  },
  ShowFullClassesGroup: {
    key: 'ShowFullClassesGroup',
  },
  RequireUniqueClassTypes: {
    key: 'RequireUniqueClassTypes',
  },
  ShowClassLocation: {
    key: 'ShowClassLocation',
  },
  CustomLabelForParticipantClasses: {
    key: 'CustomLabelForParticipantClasses',
    skip: (rootState) => !isUsingNames(rootState),
    defaultValue: () => '',
    validatejs: {
      CustomLabelForParticipantClasses: {
        length: {
          maximum: 100,
          tooLong: M.MAX_LENGTH(100)
        },
      },
    },

  },
  MinimumScheduledTimeBlocks_Youth: {
    key: 'MinimumScheduledTimeBlocks_Youth',
    skip: (rootState) => !isUsingNames(rootState),
    integerOnly: true,
    validatejs: {
      MinimumScheduledTimeBlocks_Youth: {
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
          lessThanOrEqualTo: 50,
          notLessThanOrEqualTo: M.MAX_VALUE(50),
        },
      },
    },
  },
  MinimumScheduledTimeBlocks_Adults: {
    key: 'MinimumScheduledTimeBlocks_Adults',
    skip: (rootState) => !isUsingNames(rootState),
    integerOnly: true,
    validatejs: {
      MinimumScheduledTimeBlocks_Adults: {
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
          lessThanOrEqualTo: 50,
          notLessThanOrEqualTo: M.MAX_VALUE(50),
        },
      },
    },
  },
  MinimumScheduledTimeBlocks_Group: {
    key: 'MinimumScheduledTimeBlocks_Group',
    skip: (rootState) => !isUsingNumbers(rootState),
    integerOnly: true,
    validatejs: {
      MinimumScheduledTimeBlocks_Group: {
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
          lessThanOrEqualTo: 50,
          notLessThanOrEqualTo: M.MAX_VALUE(50),
        },
      },
    },
  }
};