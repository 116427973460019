import * as React from 'react';
import '../../../../styles/pages/settings/orders/info.scss';
import {standardCurrencyFormat} from "../../../../utils/classesHelper";

interface Props {
  AvailableCredit: number;
}

const Info: React.SFC<Props> = (props: Props): React.ReactElement<Props> => (
  <div className="pages--settings--orders--header--info">
    <span className="pages--settings--orders--header--info--label">Available credit:</span>
    <span className="pages--settings--orders--header--info--amount">{standardCurrencyFormat(props.AvailableCredit)}</span>
  </div>
);

export default Info;
