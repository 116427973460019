
import * as React from 'react';
import {withRouter} from 'react-router';
import { EventTypeFormModalNamespace as namespace } from '../../../../../../store/AdminEvents/EventTypes/EventType/Form/actions';
import '../../../../../../styles/pages/admin-events/event-types/modal/new-event-type/index.scss';
import { CacheZeroState } from '../../../../../../store/CacheZero';
import { disableBodyScroll, enableBodyScroll } from '../../../../../../utils/navHelper';
import { isMobile } from '../../../../../../utils/isMobile';

interface Props {
  onSelectRegistrationMethod: (id: number) => any;
  cacheZero: CacheZeroState;
}

@(withRouter as any)
class NewEventTypeModalStepOne extends React.PureComponent<Props, {}> {

  public props: Props;

  private ref;

  componentDidMount() {
    disableBodyScroll(this.ref);
  }

  componentWillUnmount() {
    enableBodyScroll(this.ref);
  }

  public render() {
    return (
      <div className={`${namespace()}--select-registration-method`}>
        <section className={`${namespace()}--select-registration-method--prompt`}>Select Registration Method</section>
        <section ref={(ref) => this.ref = ref} className={`${namespace()}--select-registration-method--list`}>
          {this.props.cacheZero.options && this.props.cacheZero.options.EventTypeRegistrationMethodOptions && this.props.cacheZero.options.EventTypeRegistrationMethodOptions.map((o) => {
            return (
              <div
                key={`registration_method${o.ID}`}
                className={`${namespace()}--select-registration-method--list--item${isMobile ? ' mobile' : ''}`}
                onClick={() => this.props.onSelectRegistrationMethod(o.ID)}
              >
                <div className={`${namespace()}--select-registration-method--list--item--name`}>{o.Name}</div>
                <div className={`${namespace()}--select-registration-method--list--item--desc`}>{o.Description}</div>
              </div>
            );
          })}
        </section>
      </div>
    );
  }
}

export default NewEventTypeModalStepOne;