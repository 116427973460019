import * as React from 'react';
import { findDOMNode } from 'react-dom';
import '../../../styles/app/top-floating-alert.scss';
import { whichAnimationEvent } from '../../../utils';
import {actionCreators, Actions} from "../../../store/App/actions";
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../../store';
import { generateDOMId } from '../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

export const namespace = (): string => 'app--top-floating-alert';

export interface TopFloatingAlertProps {
  children?: React.ReactNode;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export interface TopFloatingAlertState {
  showLeaveAnimation?: boolean;
}

class TopFloatingAlert extends React.Component<TopFloatingAlertProps, TopFloatingAlertState> {
  public DISPLAY_TIME: number = 10000;
  timer: any;

  public props: TopFloatingAlertProps & ConnectedProps;
  public state: TopFloatingAlertState = {
    showLeaveAnimation: false
  };

  public componentDidMount() {
    this.timer = setTimeout(() => this.hide(), this.DISPLAY_TIME);
  }

  public componentWillReceiveProps(nextProps: TopFloatingAlertProps) {
    if (this.props.children !== nextProps.children) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.hide(), this.DISPLAY_TIME);
    }
  }

  public componentWillUnmount() {
    this.timer = undefined;
  }

  public hide = () => {
    if (this.props.app.alertInModal) {
      this.props.actions.hideTopFloatingAlert();
    } else {
      const whichAni = whichAnimationEvent();
      if (whichAni) findDOMNode(this)?.addEventListener(whichAni, this.handleLeaveAnimationEnd);
      this.setState({ showLeaveAnimation: true });
    }
  };

  public handleLeaveAnimationEnd = () => {
    const whichAni = whichAnimationEvent();
    if (whichAni) findDOMNode(this)?.removeEventListener(whichAni, this.handleLeaveAnimationEnd);
    this.props.actions.hideTopFloatingAlert();
  };

  public render() {
    const { children, app, rollback } = this.props;
    const { showLeaveAnimation } = this.state;

    let className = namespace();
    if (showLeaveAnimation) {
      className += ' leave-animation';
    } else {
      className += ' enter-animation';
    }
    if (app.alertInModal) className += ' is-modal';
    else className += ' not-modal';
    if (app.topFloatingAlertColor) className += ` ${app.topFloatingAlertColor}`;
    else className += ' green';
    if (app.apiSaving > 0 && !!rollback.savePointDuringSave) {
      className += ' saving';
    }

    return (
      <div id={generateDOMId("tentaroo-top-floating-alert")} className={className}>
        <span className={`${namespace()}--text`}>
          {children}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    app: state.app,
    rollback: state.rollback,
  };
};

const mapDispatchToProps = (dispatch) => (
  { actions: bindActionCreators(actionCreators, dispatch) }
);

const ConnectedTopFloatingAlert = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<TopFloatingAlertProps>(),
)(TopFloatingAlert);

export default ConnectedTopFloatingAlert;
