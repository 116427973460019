import * as React from 'react';
import {default as AppHeader} from '../../../App/Header';
import {URLS} from "../../../../constants/urls";
import {EventType} from "../../../../models/api/cacheOne";
import {makeSelectedEventTypeSelector} from "../../../../store/CacheTwoEvents/index";
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps>;

const Header: React.SFC<ConnectedProps> = ({eventType}): React.ReactElement<ConnectedProps> => (
  <AppHeader
    showDrawer
    title={eventType ? eventType.Name : ''}
    cart
    mobileCart
    refresh
    back={URLS.EVENT_TYPES}
  />
);

const mapStateToProps = (state: ApplicationState) => {
  const selectedEventType = makeSelectedEventTypeSelector();
  return {eventType: selectedEventType(state)};
};

const ConnectedHeader = connect(
  mapStateToProps,
  null,
  getMergeProps<{}>(),
)(Header);

export default ConnectedHeader;

