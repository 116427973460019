import {FacilityLocation} from "../../models/api/cacheOne";
import {listSelector, makeSingleSelector} from "../../utils/reselectHelper";
import {facilityLocationsSelector} from "../CacheOne/index";
import {GetFacilityAvailabilities, GetFacilityLocation, SelectLocation} from "./actions";
import {
  FacilitiesAvailabilitiesDate,
  FacilitiesAvailability,
  FacilityLocation_FacilityType, FacilityLocation_Trip,
  FacilityLocationPermissions
} from "../../models/api/cacheTwoFacilties";
import {convertToMoment} from "../../utils";
import {APIRequest, APISuccess} from "../Validation/actionCreator";
import {ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction} from "../App/actions";
import {ClearCacheBelowOne} from "../CacheOne/actions";
import {SelectFacilityTypeTab} from "../Facilities/Location/actions";
import {GetFacilityAvailabilitiesB} from "../CacheTwoBFacilities/actions";
import {EmptyCartActions, RemoveItemFromCartActions} from "../Cart/actions";
import {GetFacilityTripData} from "../CacheThreeFacilities/actions";
import {FacFSSubmitActions} from "../Facilities/Trip/Summary/actions";
import {AddTripSubmitActions, DeleteTripSubmitActions} from "../Facilities/Trip/Form/actions";
import {FacilitiesSave} from "../CacheFourFacilities/actions";
import { captureTentarooError } from '../../utils/dataHelper';
import { Action, isActionType } from "../../utils/StrongActions";
import { Reducer } from "redux";

export const locationIDSelector = (state) => state.cacheTwoFacilities.locationID;
export const facilityTypeIDSelector = (state) => state.facilities.location.selectedFacilityTypeTab || state.cacheTwoFacilities.FacilityLocation_SelectedFacilityTypeID;
export const facilityTypesSelector = listSelector(state => state.cacheTwoFacilities.FacilityLocation_FacilityTypes);

export const makeSelectedLocationSelector = makeSingleSelector((fl: FacilityLocation, id: number) => fl.ID === id, facilityLocationsSelector, locationIDSelector);
export const makeSelectedFacilityTypeSelector = makeSingleSelector((ft: FacilityLocation_FacilityType, id: number) => ft.ID === id, facilityTypesSelector, facilityTypeIDSelector);


export interface CacheTwoFacilitiesState {
  locationID: number;
  FacilityLocation_SelectedFacilityTypeID?: number;
  FacilityLocation_FacilityTypes?: Array<FacilityLocation_FacilityType>;
  FacilityLocation_Trips?: Array<FacilityLocation_Trip>;
  FacilityLocationPermissions?: FacilityLocationPermissions;
  FacilitiesAvailabilities?: Array<FacilitiesAvailability>;
  FacilitiesAvailabilitiesDates?: Array<FacilitiesAvailabilitiesDate>;
}

const setCacheTwoData = (data: {
  FacilityLocation_SelectedFacilityTypeID?: number;
  FacilityLocation_FacilityTypes?: Array<FacilityLocation_FacilityType>;
  FacilityLocation_Trips?: Array<FacilityLocation_Trip>;
  FacilityLocationPermissions?: FacilityLocationPermissions;
  FacilitiesAvailabilities?: Array<FacilitiesAvailability>;
  FacilitiesAvailabilitiesDates?: Array<FacilitiesAvailabilitiesDate>;
}, locationID) => {

  return {
    locationID,
    FacilityLocation_SelectedFacilityTypeID: data.FacilityLocation_SelectedFacilityTypeID,
    FacilityLocation_FacilityTypes: data.FacilityLocation_FacilityTypes,
    FacilityLocation_Trips: convertToMoment(data.FacilityLocation_Trips, ['StartDateTime', 'EndDateTime']),
    // FacilityLocationPermissions: {
    //   hasAllowNewTrip: false,
    //   hasAllowNewTripReason: 'hasAllowNewTrip'
    // },
    FacilityLocationPermissions: data.FacilityLocationPermissions,
    FacilitiesAvailabilities: data.FacilitiesAvailabilities,
    FacilitiesAvailabilitiesDates: data.FacilitiesAvailabilitiesDates
  };
};

const handleResponse = (state, responseData, request) => {
  if (responseData.FacilityLocation_SelectedFacilityTypeID !== undefined) {
    const bodyStr = request.body;
    try {
      const body = JSON.parse(bodyStr);
      return setCacheTwoData(responseData, body.AppState.FacilityLocationID);
    } catch (e) {
      captureTentarooError(new Error('Unable to parse request body for cache 2 facilities!'));
      return state;
    }
  }
  return state;
};

const CacheTwoFacilities: Reducer<CacheTwoFacilitiesState> = (state, act: Action) => {
  if (
    act.type === GetFacilityLocation.successType || act.type === GetFacilityAvailabilitiesB.successType ||
    act.type === GetFacilityTripData.successType || act.type === FacFSSubmitActions.successType ||
    act.type === EmptyCartActions.successType || act.type === RemoveItemFromCartActions.successType ||
    act.type === AddTripSubmitActions.successType || act.type === DeleteTripSubmitActions.successType ||
    act.type === FacilitiesSave.successType
  ) {
    const action = <APISuccess> act;
    return handleResponse(state, action.response.response, action.response.request);
  } else if (act.type === GetFacilityAvailabilities.successType) {
    const action = <APISuccess> act;
    if (action.response.response.FacilityLocation_SelectedFacilityTypeID !== undefined) {
      return handleResponse(state, action.response.response, action.response.request);
    }
    return {
      ...state,
      FacilitiesAvailabilities: action.response.response.FacilitiesAvailabilities,
      FacilitiesAvailabilitiesDates: action.response.response.FacilitiesAvailabilitiesDates
    };
  } else if (isActionType(act, SetCacheAction)) {
    return handleResponse(state, act.response.xhr.response, act.response.request);
  } else if (act.type === GetFacilityLocation.successType) {
    const action = <APIRequest> act;
    return {...state, locationID: action.value.FacilityLocationID};
  } else if (isActionType(act, SelectLocation)) {
    return {
      ...state,
      locationID: act.locationID
    };
  } else if (isActionType(act, SelectFacilityTypeTab)) {
    return {...state, FacilitiesAvailabilities: undefined, FacilitiesAvailabilitiesDate: undefined};
  } else if (
    isActionType(act, ClearAllCache) || isActionType(act, ClearAllEndUserCacheButOptions) || isActionType(act, ClearCacheBelowOne)
  ) {
    return {locationID: 0};
  }
  return state || {locationID: 0};
};

export default CacheTwoFacilities;
