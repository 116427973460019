import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/camp-info/index.scss';
import {namespace} from "./constants";



export interface CampInfoProps {
  children?: React.ReactNode;
  className?: string;
}

const CampInfo: React.SFC<CampInfoProps> = (props: CampInfoProps): React.ReactElement<CampInfoProps> => (
  <div className={mergeClassNames(namespace(), props)}>
    {props.children}
  </div>
);

export default CampInfo;
export { default as CampInfoRight } from './Right';
export { default as CampInfoLeft } from './Left';
export { default as CampInfoImage } from './Image';
export { default as CampInfoSynopsis } from './Synopsis';
export { default as CampInfoTitle } from './Title';
export { default as CampInfoSubTitle } from './SubTitle';
export { default as CampInfoAction } from './Action';
export { default as CampInfoWarning } from './Warning';
