import { IValidator, FormDefinition } from "./validation";
import { SUFFIX } from './actions';
import { apiValidationReducerCreator } from "../../../../store/Validation/reducerCreator";
import { createSelector } from "reselect";
import * as Actions from './actions';
import { ClearAllAdminEventsCache } from "../../CacheOne/actions";
import { ApplicationState } from "../../../../store";
import { AdminEventsProduct } from "../../../../models/api/adminEventsCacheOne";
import { sortByName } from "../../../CacheZero";
import { listSelector } from "../../../../utils/reselectHelper";
import { Reducer } from "redux";
import { isActionType } from "../../../../utils/StrongActions";
import { setDefaults } from "../../../../utils/validator";
import {ClearAllCache} from "../../../App/actions";

export interface ProductsHomeState {
  ActiveForm: ProductsHomeActiveForm;
  ValidationRules: IValidator;
  expandFilter: boolean;
}

export interface ProductsHomeActiveForm {
  ShowDeleted?: boolean;
  FilterText?: string;
}

const productsShowDeletedSelector = (state: ApplicationState) => !!state.adminEvents.products.home.ActiveForm.ShowDeleted;
const productsFilterTextSelector = (state: ApplicationState) => state.adminEvents.products.home.ActiveForm.FilterText;

const _productsSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsProducts || [];
export const productsSelector = listSelector(_productsSelector);

export const makeActiveProductsSelector = () => {
  return createSelector(
    [productsSelector],
    (products: AdminEventsProduct[]) => {
      if (products) {
        return products.filter((p) => !p.Inactive);
      }
      return [];
    }
  );
};

export const makeFilteredProductsSelector = () => {
  return createSelector(
    [productsSelector, productsShowDeletedSelector, productsFilterTextSelector],
    (products: AdminEventsProduct[], showDeleted: boolean, filterText: string) => {
      if (!products) return [];
      const result = products
            .filter((p) => {
              let passed = false;

              // filter based on deleted
              if (showDeleted || !p.Inactive) {
                if (!filterText) {
                  passed = true;
                } else {
                  const f = filterText.toLowerCase();
                  if (p.Name.toLowerCase().includes(f)) {
                    passed = true;
                  }
                }
              }
              return passed;
            }).sort(sortByName);
      return result;
    }
  );
};

const getInitialState: () => ProductsHomeState = () => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition},
  expandFilter: false,
});

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const ProductsHomeReducer: Reducer<ProductsHomeState> = (s, action: any) => {
  const state = checkApiValidation(s, action);
  if (isActionType(action, Actions.ToggleExpandFilterAction)) {
    return {...state, expandFilter: action.expand};
  } else if (isActionType(action, ClearAllAdminEventsCache) || isActionType(action, ClearAllCache)) {
    return setDefaults(action.rootState, getInitialState());
  } else if (isActionType(action, Actions.ProductsHomeReset)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        FilterText: '',
      },
    };
  }
  return state || setDefaults(action.rootState, getInitialState());
};

export default ProductsHomeReducer;