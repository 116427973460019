import {SUFFIX, ValidateActions} from './actions';
import * as NM from '../../../../../../constants/messages/numbersWizard';
import {apiValidationReducerCreator} from '../../../../../Validation/reducerCreator';
import {APISuccess, UpdateCurrValue, WithRootState} from "../../../../../Validation/actionCreator";
import {FormDefinition} from "./validation";
import {FinancialSummaryInit, initFinancialSummary} from "../../../../../../utils/classesHelper";
import {ApiSubmitActionsParticipantsClasses} from "../Classes/actions";
import {EventParticipantCalc, SUFFIX_SAVE} from "../../../../../CacheFourEventsParticipants/actions";
import {EventRegisterParticipantReset} from "../Main/actions";
import {ApiTypeSubmitActions} from "../Type/actions";
import {ApiSubmitActions as OverrideApiSubmitActions} from "../../../Modals/RegisterOverrideFee/actions";
import {ApiRosterSubmitActions} from "../Roster/actions";
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../../utils/StrongActions';

export interface ParticipantWizardConfirmationState {
  ActiveForm: {
    selectedFinance?: 'min' | 'full' | 'other' | 'credit';
    otherValue?: number;
  },
  ValidationRules: any;
  SubmitErrorMessage?: string;
  minValue?: number;
  maxValue?: number;
}

const determineInlineError = (errors: any) => {
  let message = '';
  if (errors) {
    if (errors.otherValue) message = NM.INVALID_PAYMENT;
  }

  return message;
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, determineInlineError);
const checkCache4EventParticipantsSaveValidation = apiValidationReducerCreator(SUFFIX_SAVE, determineInlineError);

const getInitialState = (): ParticipantWizardConfirmationState => {
  return {
    ActiveForm: {
      selectedFinance: 'min',
      otherValue: 0
    }, ValidationRules: {...FormDefinition}};
};

const ParticipantWizardConfirmation: Reducer<ParticipantWizardConfirmationState> = (oldState = {...getInitialState()}, act: WithRootState<AnyAction>) => {
  const s = checkApiValidation(oldState, act);
  const state = checkCache4EventParticipantsSaveValidation(s, act);
  if (
    act.type === EventParticipantCalc.successType || act.type === ApiSubmitActionsParticipantsClasses.successType ||
    act.type === ApiTypeSubmitActions.successType || act.type === ApiRosterSubmitActions.successType ||
    act.type === OverrideApiSubmitActions.successType
  ) {
    const action = <WithRootState<APISuccess>> act;
    const vals: FinancialSummaryInit = initFinancialSummary(
      action.rootState,
      action.response.response.EventRegistrationPaymentStatus,
      (rootState) => rootState.events.event.register.participant.confirmation.ActiveForm.selectedFinance === 'other',
      true,
    );

    return {
      ...state,
      ActiveForm: {selectedFinance: vals.paymentType, otherValue: vals.otherDefaultValue},
      ValidationRules: {...state.ValidationRules, otherValue: vals.otherValueRules},
      minValue: action.response.response.EventRegistrationPaymentStatus.AmountMin,
      maxValue: action.response.response.EventRegistrationPaymentStatus.AmountMax
    };
  } else if (act.type === ValidateActions.updateCurrType) {
    const action = <WithRootState<UpdateCurrValue>> act;
    const newState = {...state};
    newState.ActiveForm = {...state.ActiveForm};
    if (action.value === 'full') {
      newState.ActiveForm.otherValue = state.maxValue;
    } else if (action.value === 'min') {
      newState.ActiveForm.otherValue = state.minValue;
    } else if (action.value === 'credit') {
      newState.ActiveForm.otherValue = 0;
    } else {
      return state;
    }
    return newState;
  } else if (isActionType(act, EventRegisterParticipantReset)) {
    return getInitialState();
  }

  return state;
};

export default ParticipantWizardConfirmation;
