
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, ModalActions, Button, Loader
} from '../../../../../Elements';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as cacheOneActionCreators } from "../../../../../../store/AdminEvents/CacheOne/actions";
import { actionCreators as cacheTwoMessageActionCreators } from "../../../../../../store/AdminEvents/CacheTwoMessage/actions";
import { actionCreators, AdminEventMessageFormNamespace as namespace } from "../../../../../../store/AdminEvents/MessageCenter/Form/actions";
import { ApplicationState } from '../../../../../../store';
import MessageForm from '../../Form/Form';
import '../../../../../../styles/pages/admin-events/message-center/modals/message-form/index.scss';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { INVALID_MESSAGE } from '../../../../../../constants/messages/adminEvents';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import AdminEventsCacheManager from '../../../../../../utils/cacheManagers/adminEventsCacheManager';
import { IAdminMessageRouterParams } from '../../../../../../utils/helpers/adminEventsPageHelper';
import { connect } from 'react-redux';
import { reduxStoreService } from '../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isAdminEventsCacheTwoMessagePopulated} from '../../../../../../utils/cachePopulatedCheckers/adminEvents';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> &RouteComponentProps<IAdminMessageRouterParams, {}>
>;

@(withRouter as any)
class MessageFormModal extends ComponentUpdateTemplate<ConnectedProps, {}> {
  public props: ConnectedProps;

  componentDidMount() {
    super.loadAndSetData(
      this.props,
      () => {
        AdminEventsCacheManager.getInstance().loadCacheTwoMessage({
          props: this.props,
          isStateNavigated: true,
          isEdit: !!this.props.messageId,
          messageIdFromModalProps: this.props.messageId,
        });
      }
    );
  }

  componentWillUnmount() {
    this.onClose();
  }

  onMessageInactive() {
    const {actions} = this.props;

    actions.showTopFloatingAlert(INVALID_MESSAGE, true, 'orange');
  }
  onSave = () => {
    const {actions, routes, router} = this.props;

    actions.apiSubmitForm(router, routes);
  };

  onClose = () => {
    const {actions} = this.props;
    if (isAdminEventsCacheTwoMessagePopulated(reduxStoreService().getState().adminEvents.cacheTwoMessage)) {
      actions.clearAdminEventsCacheTwoMessage();
    }
  };

  public render() {
    const { apiSaving, actions, adminEventsCacheOne, messageId, adminEventsCacheTwoMessage, formState, inert } = this.props;

    const title = messageId ? 'Edit Message' : 'New Message';
    const subtitle = adminEventsCacheOne.EventsEventType ? adminEventsCacheOne.EventsEventType.General.Name : '';
    const loading = !!messageId && !isAdminEventsCacheTwoMessagePopulated(adminEventsCacheTwoMessage, formState.ActiveForm);
    const inactive = !!messageId && (adminEventsCacheTwoMessage.Message && adminEventsCacheTwoMessage.Message.Inactive);

    const noSavePermission = !adminEventsCacheOne.EventsEventType || (!!this.props.messageId ? !adminEventsCacheOne.EventsEventType.Permissions.hasEditMessage : !adminEventsCacheOne.EventsEventType.Permissions.hasAddMessage);

    return (
      <Modal
        inert={inert}
        onClose={this.onClose}
        inactive={inactive}
        onInactive={() => this.onMessageInactive()}
        wideModal
        big
        mobileFullScreen className={namespace()}
      >
        <ModalHeader subtitle={subtitle} className={`${namespace()}--header`}>{title}</ModalHeader>
        <ModalContent hasPadding>
          {!loading && 
            <MessageForm
              inert={inert}
              handleCompact
              hideFormAction
              adminEventsCacheOne={adminEventsCacheOne}
              messageForm={formState}
              reduxActions={actions}
              disabled={apiSaving > 0}
              loading={apiSaving > 0}
              action={!messageId ? 'add' : 'edit'}
              onSave={this.onSave}
          />}
          {loading && <Loader className={`${namespace()}--loader`} center />}
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button id={generateDOMId("tentaroo-admin-message-save-btn")} textColor='black' disabled={loading || apiSaving > 0 || noSavePermission} flat onClick={this.onSave}>{this.props.messageId ? 'SAVE' : 'CREATE'}</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const messageFormIdSelector = makeFormModalPropSelector(ModalTypes.MESSAGE_FORM, 'id');

  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheZero: state.cacheZero,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsCacheTwoMessage: state.adminEvents.cacheTwoMessage,
    formState: state.adminEvents.messageCenter.form,
    messageId: messageFormIdSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...cacheOneActionCreators,
  ...cacheTwoMessageActionCreators,
  ...appActionCreators,
}, dispatch) });

const ConnectedMessageFormModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessageFormModal);

export default ConnectedMessageFormModal;