import { ActionCreator, appActionCreators, ApplicationState } from "../../../..";
import { createValidateActions, createApiValidateActions, createApiSubmitActions, createUpdateValueMethod, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra } from "../../../../Validation/actionCreator";
import { formCreator, UPDATE_CMS_PAGE_MENU_FORM_SUFFIX } from "../../SiteMenuItem/Form/actions";
import { ADMIN_CMS_MODULE_SUFFIX } from "../../../../../store/AdminCMSSite/CacheOne/actions";
import { SaveState } from "../../../../../store/Rollback/actions";
import { ClearAdminCMSCacheTwoPageMenuItem } from "../../../../../store/AdminCMSSite/CacheTwoPageMenus/actions";
import { ModalTypes } from "../../../../../utils/modalHelper";

export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__PAGE_MENU_MODAL' + UPDATE_CMS_PAGE_MENU_FORM_SUFFIX;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ModalAddPageMenuItemSubmitActions = createApiSubmitActions(SUFFIX);

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.menus.modals.pageMenuItem),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  resetForm: (save?: boolean): ActionCreator => dispatch => {
    if (save) dispatch(new SaveState());
  },
  apiSubmitForm: (): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const ActiveForm = state.adminCMSSite.menus.modals.pageMenuItem.ActiveForm;
    // form validation
    const valid = innerApiSubmitFormMethod(
      dispatch,
      ModalAddPageMenuItemSubmitActions,
      (s: ApplicationState) => s.adminCMSSite.menus.modals.pageMenuItem,
      undefined,
      undefined,
      true,
      true,
      undefined,
      undefined,
    );

    if (valid) {
      const body = formCreator(ActiveForm);
      dispatch(appActionCreators.popModal(true, false, ModalTypes.PAGE_MENU_ITEM_FORM) as any);
      dispatch(ModalAddPageMenuItemSubmitActions.request(
          body,
          getCacheLevelExtra(
            true,
            !!state.adminCMSSite.cacheTwoPageMenuItems.CMSPageMenuItem,
          ),
        )
      );

      if (!!state.adminCMSSite.cacheTwoPageMenuItems.CMSPageMenuItem) {
        dispatch(new ClearAdminCMSCacheTwoPageMenuItem());
      }
    }
  }
};