import { typeName, Action } from '../../../utils/StrongActions';
import { ActionCreator } from '../../index';
import {SaveState} from "../../Rollback/actions";

@typeName("EVENT_EVENT_TYPES_SELECT_TAB") export class EventEventTypesSelectTab extends Action {
  constructor(public selectedTab: 'available' | 'upcoming' | 'current' | 'past') { super(); }
}
export const FILTER_TYPE = 'EVENT_EVENT_TYPES_FILTER';

export type Actions = typeof actionCreators;

export const actionCreators = {
  selectTab: (selectedTab: 'available' | 'upcoming' | 'current' | 'past'): ActionCreator => dispatch => {
    dispatch(new EventEventTypesSelectTab(selectedTab));
    dispatch(new SaveState());
  },
};
