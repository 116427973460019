
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, Row, PageLoader, EmptyMessage, Button, ModalActions, Switch, AdminEventCard, Column, RadioGroupOption, Text, Alert,
} from '../../../../../Elements';
import { actionCreators } from "../../../../../../store/AdminEvents/MessageCenter/Modals/SendMessage/actions";
import '../../../../../../styles/pages/admin-events/message-center/modals/send-message/index.scss';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../store/Rollback/actions';
import { actionCreators as eventTypeFormActionCreators } from '../../../../../../store/AdminEvents/EventTypes/EventType/Form/actions';
import { actionCreators as cacheOneActionCreators, GetEventTypeCacheAction } from '../../../../../../store/AdminEvents/CacheOne/actions';
import { actionCreators as adminEventsSharedActionCreators, GetRegisteredGroupsAction } from '../../../../../../store/AdminEvents/Shared/actions';
import { ApplicationState } from '../../../../../../store';
import { ModalHeight } from '../../../../../Elements/Modal';
import { CardCategory } from '../../../../../Elements/Card';
import { extractRouteParams } from '../../../../../../utils/urlHelper';
import { EmptyMessageType } from '../../../../../../components/Elements/EmptyMessage';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { makeFilteredEventsSelector, makeSelectedEventSelector, makeMultipleSelectedGroupsSelector } from '../../../../../../store/AdminEvents/MessageCenter/Modals/SendMessage';
import { AdminEventsEvent } from '../../../../../../models/api/adminEventsCacheOne';
import { Facilities2Icon, CloseCircleIcon } from '../../../../../Icons';
import { ADMIN_EVENTS } from '../../../../../../constants/messages/adminEvents';
import { AdminEventCardType } from '../../../../../Elements/AdminEvent/Card';
import Tag from '../../../../../Elements/Tag';
import StepOne from './StepOne';
import { checkAdminEventMessagePermission } from '../../../../../../utils/helpers/adminEventsPageHelper';
import { disableBodyScroll, enableBodyScroll } from '../../../../../../utils/navHelper';
import { ModalTypes, isModalOpened } from '../../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../store/service';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--modal--send-message';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> &RouteComponentProps<{}, {}>;
type Props = WithInertAttribute<{}>;

@(withRouter as any)
class SelectMessageModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;
  private modalContentRef;

  componentDidUpdate(prevProps) {
    const {sendMessageModal: {ActiveForm}} = this.props;
    if (ActiveForm.SelectedEventID) {
      disableBodyScroll(this.modalContentRef);
    }
  }

  componentWillUnmount() {
    const {sendMessageModal: {ActiveForm}} = this.props;
    if (ActiveForm.SelectedEventID) {
      enableBodyScroll(this.modalContentRef);
    }
    this.onClose();
  }

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    if (this.props.sendMessageModal.filterString) {
      this.scrollToTop();
    }
    this.props.actions.toggleFilter(false);
    this.props.actions.filterMessages('');
  };
  onRefreshClick = () => {
    this.props.actions.saveState();
    this.props.actions.refresh({
      GetEvents: true,
      MaxEventIDi: 0,
      ...extractRouteParams(this.props),
    }, false);
  };

  itemRenderer = (index) => {
    return (
      null
    );
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };

  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filterMessages(val);
  };

  onClose = () => {
    this.props.actions.resetRegisteredGroups();
    this.props.actions.reset();
  };

  onSend = () => {
    const {actions, cacheOne, routes, router} = this.props;

    checkAdminEventMessagePermission(
      () => {
        actions.submit(router, routes);
      },
      cacheOne,
      false,
      true,
    );
  };

  renderActions = (loadingGroups: boolean) => {
    const {sendMessageModal: {ActiveForm, ValidationRules}, actions, apiSaving} = this.props;

    if (!ActiveForm.SelectedEventID) {
      return (
        <ModalActions
          sticky
          notFixed
          className={`${namespace()}--show-events-from-past-years`}
          left={<Switch
            margin={false}
            newDesign
            label="Show Events From Past Years"
            value={!!ActiveForm.ShowEventsFromPastYear}
            validationRules={ValidationRules.ShowEventsFromPastYear}
            onChange={actions.updateValueWithoutSave}
          />}
        />
      );
    }

    return (
      <ModalActions
        sticky
        notFixed
        left={<Button id={generateDOMId("admin-message-send-btn-modal")} textColor='black' disabled={apiSaving > 0 || loadingGroups} flat onClick={this.onSend}>SEND</Button>}
      />
    );
  };

  onEventClick = (event: AdminEventsEvent) => {
    const { actions, cacheOne, sendMessageModal: {ActiveForm, ValidationRules} } = this.props;

    if (!cacheOne.EventsEventType) return;
    actions.getRegisteredGroups(cacheOne.EventsEventType.EventTypeRow.ID as number, event.IDi);
    actions.updateValueWithoutSave(event.IDi, ValidationRules.SelectedEventID);
    actions.initStep2();
  };

  renderEmptyMessage = () => {
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        admin
        icon={Facilities2Icon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={ENTITY_NOT_FOUND(ADMIN_EVENTS)}
      />
    );
  };

  renderStep1 = () => {
    const { sendMessageModal: { ActiveForm }, filteredEvents, apiSaving } = this.props;

    return !ActiveForm.SelectedEventID ? <StepOne /> : null;
  };

  onSelectGroups = () => {
    const {actions, selectedEvent} = this.props;

    if (!selectedEvent) return;
  
    actions.pushMultipleGroupsModal({
      multipleGroupsContext: 'send-message',
      selectedEventId: selectedEvent.IDi,
    });
  };

  renderStep2 = () => {
    const { apiLoading, apiLoadingMap, sendMessageModal: { ActiveForm, ValidationRules, SubmitErrorMessage }, cacheOne, selectedGroups, actions, selectedEvent, apiSaving} = this.props;
    
    const loading = apiLoadingMap[GetRegisteredGroupsAction.requestType] && !isModalOpened(ModalTypes.MULTIPLE_GROUPS);
    if (!ActiveForm.SelectedEventID || !selectedEvent) return null;

    return (
      <div className={`${namespace()}--step2`}>
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        <Row>
          <Column span={12} style={{display: 'flex', flexDirection: 'column'}}>
            <div className={`${namespace()}--label`}>Event</div>
            <AdminEventCard
              event={selectedEvent}
              type={AdminEventCardType.SEND_MESSAGE_MODAL_SELECTED}
              category={CardCategory.LIST_MOBILE}
              onRemove={this.onBack}
            />
          </Column>
        </Row>
        {loading && <PageLoader className={`${namespace()}--page-loader`}/>}
        {!loading && <div className={`${namespace()}--label`}>Recipient</div>}
        {!loading && <Row>
          <Column span={12}>
            <RadioGroupOption
              value={!!ActiveForm.IsAllRegisteredGroups}
              selected={!!ActiveForm.IsAllRegisteredGroups}
              ValidationRules={ValidationRules.IsAllRegisteredGroups}
              rawValue={true}
              onChange={actions.updateValueWithoutSave}
              newDesign
              label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>All Registered Groups</Text>}
            />
          </Column>
        </Row>}
        {!loading && <Row>
          <Column span={12}>
            <RadioGroupOption
              value={!!!ActiveForm.IsAllRegisteredGroups}
              selected={!!!ActiveForm.IsAllRegisteredGroups}
              ValidationRules={ValidationRules.IsAllRegisteredGroups}
              rawValue={false}
              onChange={actions.updateValueWithoutSave}
              newDesign
              label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>Specific Groups</Text>}
            />
          </Column>
        </Row>}
        {!loading && !ActiveForm.IsAllRegisteredGroups && selectedGroups && selectedGroups.length > 0 ? (
          <Row marginTop={8} marginLeft={36} className={`${namespace()}--tag-row`}>
            {selectedGroups.map((group) => {
              if (!group) return null;
              return (
                <div className={`${namespace()}--tag-wrapper`}>
                  <Tag
                    className={`${namespace()}--tag`}
                    onRemove={() => this.props.actions.removeGroup(group.GroupIDi)}
                    removeIcon={<CloseCircleIcon />}
                    text={group.GroupName}
                    backgroundColor='orange'
                  />
                </div>
              );
            })}
          </Row>
        ) : null}
        {!loading && !ActiveForm.IsAllRegisteredGroups && <Row marginLeft={36} marginTop={8} className={`${namespace()}--btn-row`}>
          <Column style={{display: 'flex', flexDirection: 'column'}}>
            <Button expand color="white" textColor="black" onClick={this.onSelectGroups}>SELECT</Button>
            {ValidationRules.SelectedGroups.errors && ValidationRules.SelectedGroups.errors.length > 0 ? <div className={`${namespace()}--select-groups--error`}>{ValidationRules.SelectedGroups.errors[0]}</div> : null}
          </Column>
        </Row>}
      </div>
    );
  };

  onBack = () => {
    const { actions, sendMessageModal: {ValidationRules} } = this.props;
    actions.updateValue(undefined, ValidationRules.SelectedEventID);
    actions.resetRegisteredGroups();
    if (reduxStoreService().getState().app.apiLoading > 0) {
      actions.silentCancelAll();
    }
  };

  public render() {
    const { apiLoading, messageSubject, apiLoadingMap, sendMessageModal: { ActiveForm, isTextSearching }, inert, cacheOne, actions, apiSaving} = this.props;
    const loadingCache = apiLoadingMap[GetEventTypeCacheAction.requestType];
    const loadingGroups = apiLoadingMap[GetRegisteredGroupsAction.requestType] && !isModalOpened(ModalTypes.MULTIPLE_GROUPS);
    return (
      <Modal inert={inert} height={ActiveForm.SelectedEventID ? ModalHeight.AUTO : undefined} onClose={this.onClose} big mobileFullScreen className={namespace()}>
        <ModalHeader
          isSearching={ActiveForm.SelectedEventID ? false : isTextSearching}
          onRefresh={ActiveForm.SelectedEventID ? undefined : this.onRefreshClick}
          onSearchOpen={ActiveForm.SelectedEventID ? undefined :this.onSearchOpen}
          onSearchChange={ActiveForm.SelectedEventID ? undefined :this.onSearchChange}
          onSearchBack={ActiveForm.SelectedEventID ? undefined :this.onSearchBack}
          subtitle={messageSubject || 'Edit Message'}
          className={`${namespace()}--header`}>
          Send Message
        </ModalHeader>

        <ModalContent refCallback={(ref) => this.modalContentRef = ref} paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0} >
          {!ActiveForm.SelectedEventID && <div className={`${namespace()}--step-prompt`}>
            Select Event
          </div>}
          {this.renderStep1()}
          {this.renderStep2()}
          {loadingCache && <PageLoader className={`${namespace()}--page-loader`}/>}
        </ModalContent>
        {this.renderActions(loadingGroups)}
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredEventsSelector = makeFilteredEventsSelector();
  const selectedEventSelector = makeSelectedEventSelector();
  const selectedGroupsSelector = makeMultipleSelectedGroupsSelector();
  const messageSubjectSelector = makeFormModalPropSelector(ModalTypes.SEND_MESSAGE, 'messageSubject');

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheOne: state.adminEvents.cacheOne,
    sendMessageModal: state.adminEvents.messageCenter.modals.sendMessage,
    filteredEvents: filteredEventsSelector(state),
    selectedEvent: selectedEventSelector(state),
    selectedGroups: selectedGroupsSelector(state),
    messageSubject: messageSubjectSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...cacheOneActionCreators,
    ...adminEventsSharedActionCreators,
    ...rollbackActionCreators,
  }, dispatch),
  eventTypeFormActions: bindActionCreators({
    ...eventTypeFormActionCreators,
  }, dispatch),
});

const ConnectedSelectMessageModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(SelectMessageModal);

export default ConnectedSelectMessageModal;