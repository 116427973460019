import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import {AdminEventsCacheTwoEventContext} from "@tentaroo/shared";

import { ApplicationState } from '../../../../../../store';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { LoadingAll, Loader } from '../../../../../Elements';
import { Main, MainContent } from '../../../../../Layouts';
import '../../../../../../styles/pages/admin-events/events/settings/index.scss';
import { actionCreators as cacheTwoEventActionCreators } from '../../../../../../store/AdminEvents/CacheTwoEvent/actions';
import EventForm from '../Form';
import { actionCreators } from '../../../../../../store/AdminEvents/Events/Event/Form/actions';
import { GetAdminEvent } from '../../../../../../store/AdminEvents/CacheTwoEvent/actions';
import NotFound from '../../../../NotFound';
import { INVALID_EVENT } from '../../../../../../constants/messages/adminEvents';
import { isPathUnderAdminEvent } from '../../../../../../utils/eventsHelper';
import AdminEventsCacheManager from '../../../../../../utils/cacheManagers/adminEventsCacheManager';
import { SaveState } from '../../../../../../store/Rollback/actions';
import { IAdminEventRouterParams } from '../../../../../../utils/helpers/adminEventsPageHelper';
import { shouldReconfigRouter } from '../../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { noOpenedModals } from '../../../../../../utils/modalHelper';
import { reduxStoreService } from '../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isAdminEventsCacheOnePopulated, isAdminEventsCacheTwoEventPopulated} from '../../../../../../utils/cachePopulatedCheckers/adminEvents';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--event-settings';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminEventRouterParams, {}>
>;

class AdminEventSettings extends ComponentUpdateTemplate<ConnectedProps, {}> {
  public props: ConnectedProps;
  private nextLocation;

  componentDidMount() {
    const {actions} = this.props;
    actions.showAdminPageHeader(true);
    this.configRouter();

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        AdminEventsCacheManager.getInstance().loadCacheTwoEvent({
          props: this.props,
          isEdit: true,
          context: AdminEventsCacheTwoEventContext.SETTINGS,
          isStateNavigated,
        });
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
  }

  configRouter() {
    const {routes, router} = this.props;

    const route = routes[routes.length - 1];
    router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  componentWillReceiveProps(nextProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        AdminEventsCacheManager.getInstance().loadCacheTwoEvent({
          props: nextProps,
          isEdit: true,
          context: AdminEventsCacheTwoEventContext.SETTINGS,
          isStateNavigated,
        });
      }
    );
  }

  routerWillLeave = (nextLocation) => {
    const {adminEventsCacheTwoEvent} = this.props;

    this.nextLocation = nextLocation;
    if (reduxStoreService().getState().app.apiLoading === 0) {
      if (
        isAdminEventsCacheTwoEventPopulated(adminEventsCacheTwoEvent)
      ) {
        reduxStoreService().dispatch(new SaveState());
      }
    }
  };

  componentWillUnmount() {
    const {adminEventsCacheTwoEvent, actions} = this.props;

    // if we are not navigating to any pages under cache two event, clear cache
    if (
      this.nextLocation &&
      !isPathUnderAdminEvent(this.nextLocation.pathname) &&
      isAdminEventsCacheTwoEventPopulated(adminEventsCacheTwoEvent)
    ) {
      actions.clearAdminEventsCacheTwoEvent();
    }

    this.resetRouteLeaveHook();
  }

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  onSave = () => {
    const {actions, routes, router} = this.props;

    actions.apiSubmitForm(routes, 'edit', router);
  };

  onDelete = () => {
    const {adminEventsCacheTwoEvent, actions, routes, router} = this.props;

    if (!adminEventsCacheTwoEvent.EventsEvent) return;

    actions.deleteEvent(adminEventsCacheTwoEvent.EventsEvent.IDi, true, routes, router);
  };

  public render() {
    const {adminEventsCacheTwoEvent, inert, eventForm, apiLoadingMap, apiSaving } = this.props;
    if (
      !isAdminEventsCacheOnePopulated() ||
      !isAdminEventsCacheTwoEventPopulated(adminEventsCacheTwoEvent, eventForm.ActiveForm)
    ) {
      return <LoadingAll />;
    }
    const pageLoading = isAdminEventsCacheTwoEventPopulated(adminEventsCacheTwoEvent, eventForm.ActiveForm) && apiLoadingMap[GetAdminEvent.requestType] && noOpenedModals();
    const inactive = adminEventsCacheTwoEvent.EventsEvent && !pageLoading && adminEventsCacheTwoEvent.EventsEvent.Inactive;
    const saving = apiSaving > 0 && noOpenedModals();

    return (
      <Main inert={inert} isLoading={pageLoading} isAdminEvents>
        <MainContent wide className={namespace()}>
          <div className={`${namespace()}--wrapper`}>
            {!inactive && <EventForm
              inert={inert}
              action='edit'
              disabled={apiSaving > 0}
              onSave={this.onSave}
              onDelete={this.onDelete}
            />}
            {inactive && <NotFound showCMSSiteHeader message={INVALID_EVENT} />}
          </div>
          {saving && <Loader className={`${namespace()}--loader saving`} center />}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSavingMap: state.app.apiSavingMap,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsCacheTwoEvent: state.adminEvents.cacheTwoEvent,
    eventForm: state.adminEvents.events.event.form,
    cacheZero: state.cacheZero,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...cacheTwoEventActionCreators,
    ...actionCreators,
  }, dispatch),
});

const ConnectedAdminEventSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(AdminEventSettings);

export default withRouter<{}>(ConnectedAdminEventSettings);
