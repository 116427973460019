import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { ApplicationState } from '../../../store';
import { getMergeProps } from '../../../utils/reduxHelper';

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
  };
};

type ConnectedProps = ReturnType<typeof mapStateToProps> & RouteComponentProps<{}, {}>;

export function WithDisabled<Props extends {disabled?: boolean}>(
  WrappedComponent: React.ClassType<Props, any, any>
): React.ClassType<ConnectedProps & Props, any, any> {
  class EnhancedComponent extends React.PureComponent<ConnectedProps & Props, {}> {
    public props: ConnectedProps & Props;

    constructor(props) {
      super(props);
    }

    render() {

      return <WrappedComponent disabled={this.props.disabled || this.props.apiSaving > 0} {...this.props} />;
    }
  }


  return connect(
    mapStateToProps,
    null,
    getMergeProps<{}>(),
  )(EnhancedComponent);
}