import * as React from 'react';

import SlideIn from "../../../Elements/SlideIn";
import Shadow from "../../../Elements/Shadow";

import '../../../../styles/layouts/main/side-bar/index.scss';
import { isFixed } from '../../../../utils';

import { namespace, sideNamespace } from "./constants";

export interface SideBarProps {
  children?: React.ReactNode;
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
  open?: boolean;
  hide?: boolean;
  loading?: boolean;
  onClose?: () => void;
}

interface Props extends SideBarProps {
  side: 'left' | 'right';
  hideEarly?: boolean;
}

interface State {
  footerHeight?: number | null;
};

class SideBar extends React.Component<Props, State> {
  public props: Props;
  public state: State = {
    footerHeight: null
  };

  private footer: HTMLDivElement;
  private hasFooter: boolean | null = null;

  public componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  public handleResize = () => {
    if (this.hasFooter !== false) {
      if (!this.footer) {
        const footerElements = document.body.getElementsByClassName('layouts--main--footer');
        this.hasFooter = false;
        if (footerElements && footerElements[0]) {
          const footerWrapper = footerElements[0].getElementsByClassName('elements--footer--wrapper');
          if (footerWrapper && footerWrapper[0]) {
            this.hasFooter = true;
            this.footer = footerWrapper[0] as any;
          }
        }
      }
      if (this.footer) {
        if (isFixed(this.footer)) {
          const isTablet = window.matchMedia('screen and (max-width: 959px)');
          if (isTablet.matches) {
            const boundingRect: ClientRect = this.footer.getBoundingClientRect();
            this.setState({ footerHeight: boundingRect.height });
          } else {
            this.setState({ footerHeight: 0 });
          }
        }
      }
    }
  };

  public render() {
    const { children, open, onClose, mobile, tablet, desktop, side, hide, hideEarly, loading } = this.props;
    const { footerHeight } = this.state;
    let className = `${namespace()} ${sideNamespace(side)}`;

    const style: React.CSSProperties = {
      marginBottom: footerHeight ? `${footerHeight}px` : '0px'
    };

    let backdropClassName = `${namespace()}--backdrop ${sideNamespace(side)}--backdrop`;
    if (open) backdropClassName += ' show';

    if (mobile || tablet || desktop) {
      className += ' hide-all-except';
      if (mobile) className += ' show-mobile';
      if (tablet) className += ' show-tablet';
      if (desktop) className += ' show-desktop';
    }
    if (hide) className += ' hide';
    if (hideEarly) className += ' hide-early';
    else if (!hideEarly) className += ' hide-normal';
    if (loading) className += ' loading';

    return (
      <div className={className}>
        <div
          onClick={onClose}
          className={backdropClassName}
        />
        <SlideIn
          right={side === 'right'}
          open={open ? true : false}
          tablet
          mobile
          forceShowDesktop={!hideEarly}
          forceShowLargeDesktop={hideEarly}
          className={`${namespace()}--slider ${sideNamespace(side)}--slider`}
        >
          <Shadow shadow={2} component={<section/>} className={`${namespace()}--container ${sideNamespace(side)}--container`}>
            <div style={style}>
              {children}
            </div>
          </Shadow>
        </SlideIn>
      </div>
    );
  }
}

export default SideBar;
export { default as SideBarContent } from './Content';
