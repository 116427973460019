import { captureTentarooError } from "../../utils/dataHelper";

// When saving a form, formState could be different than the one in SavePointDuringSave, but it
// doesn't matter because we always re-init the form on open. So here we skip any form state by check if an object has ActiveForm/ValidationRules/SubmitErrorMessage
const formKeysToSkip = ["ActiveForm", "ValidationRules", "SubmitErrorMessage"];

export function deepCompare(base: any, other: any, actionType: string, path="", keysToSkip: string[] = []) {
  /*
  if (isMoment(object) && isMoment(base)) {
    if (object.format(API_DATE_FORMAT) !== base.format(API_DATE_FORMAT)) {
      captureTentarooError(new Error(`SavePointDuringSave state doesn't match with current state. Path: ${parentPath}. Action: ${actionType}`));
    }

    return;
  }
  */

  // This is the base case for the recursion. We stop when either object OR base is not an object,
  // and start comparing them
  if (
    typeof base !== "object" || typeof other !== "object" ||
    other === null || base === null ||
    other === undefined || base === undefined
  ) {
    // If base & other are both NaN, its valid
    if (isNaN(base) && isNaN(other)) return;

    // Otherwise, report invalid with strict equality check - this should cover null/undefined as well
    if (base !== other) {
      captureTentarooError(new Error(`SavePointDuringSave state doesn't match with current state. Path: ${path}`), `Action: ${actionType}, base: ${base}, other: ${other}`);

      if (process.env.NODE_ENV === "development") {
        console.error(`${path} is different, base: ${base}, other: ${other}`);
      }
    }
    return;
  }

  const allKeysToSkip = [...formKeysToSkip, ...keysToSkip];
  const baseKeysToValidate = Object.keys(base).filter((key) => !allKeysToSkip.includes(key));
  const otherKeysToValidate = Object.keys(other).filter((key) => !allKeysToSkip.includes(key));

  if (baseKeysToValidate.length !== otherKeysToValidate.length) {
    captureTentarooError(
      new Error(`SavePointDuringSave state doesn't match with current state. Path: ${path}`),
      `Action: ${actionType}. base.keys: ${baseKeysToValidate.join(",")}, other.keys: ${otherKeysToValidate.join(",")}`,
    );
  }

  // Start the recursion
  baseKeysToValidate.forEach((key) => {
    deepCompare(base[key], other[key], actionType, `${path ? path + "." : ""}${key}`);
  });
}