import {
  ApiSubmitActionsParticipantsClasses,
  EventRegisterParticipantsShowError, EventRegisterParticipantsClassesAdd, EventRegisterParticipantsClassesRemove,
  EventRegisterParticipantsClassesWaitlist, EventRegisterParticipantsClearError
} from "./actions";
import {ClassOverrideFeeSave} from "../../../Modals/ClassOverrideFee/actions";
import {BaseEndUserClass} from "../../../../../../models/class";
import {AddUnscheduledAdd} from "../../../Modals/ClassAddUnscheduled/actions";
import {EventRegisterParticipantReset} from "../Main/actions";
import { Reducer } from "redux";
import { isActionType } from "../../../../../../utils/StrongActions";

export interface ParticipantsWizardClassesState {
  isDirty?: boolean;
  SubmitErrorMessage?: string;
  isError?: boolean;
  waitlistClass?: BaseEndUserClass;
}

const getInitialState = () => ({});

const ParticipantsWizardClasses: Reducer<ParticipantsWizardClassesState> = (state = getInitialState(), act) => {
  if (act.type === ApiSubmitActionsParticipantsClasses.successType) {
    return getInitialState();
  } else if (isActionType(act, EventRegisterParticipantsShowError)) {
    window.scrollTo(0, 0);
    return {...state, SubmitErrorMessage: act.error, isError: act.isError};
  } else if (isActionType(act, EventRegisterParticipantsClassesWaitlist)) {
    return {...state, waitlistClass: act.clazz};
  } else if (isActionType(act, EventRegisterParticipantsClearError)) {
    return {...state, SubmitErrorMessage: undefined, isError: false};
  } else if (
    isActionType(act, EventRegisterParticipantsClassesAdd) || isActionType(act, EventRegisterParticipantsClassesRemove) ||
    isActionType(act, ClassOverrideFeeSave) || isActionType(act, AddUnscheduledAdd)
  ) {
    return {...state, isDirty: true};
  } else if (isActionType(act, EventRegisterParticipantReset)) {
    return getInitialState();
  }
  return state;
};

export default ParticipantsWizardClasses;
