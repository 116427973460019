import * as React from 'react';

import '../../../../../styles/pages/admin-events/message-center/message/index.scss';
import { Main, MainContent } from '../../../../Layouts';
import { Form, FormActions, Button, Row, Column, TextField, Switch, RadioGroupLabel, RadioGroupOption, FieldSet, DatePicker, Alert } from '../../../../Elements';
import { AdminEventsCacheOneState } from '../../../../../store/AdminEvents/CacheOne';
import { Actions } from '../../../../../store/AdminEvents/MessageCenter/Form/actions';
import { Actions as AppActions } from '../../../../../store/App/actions';
import { AdminEventMessageFormState } from '../../../../../store/AdminEvents/MessageCenter/Form';
import CKEditor from '../../../../Elements/CKEditor';
import { DEFAULT_CKEDITOR_CONFIG, CKEDITOR_MODE, registerSourceModeInputEvent } from '../../../../../utils/ckeditorHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--message';

export type Props = WithInertAttribute<{
  action: 'add' | 'edit';
  disabled: boolean;
  loading?: boolean;
  adminEventsCacheOne: AdminEventsCacheOneState;
  handleCompact?: boolean;
  hideFormAction?: boolean;
  messageForm: AdminEventMessageFormState;
  onSave?: () => void;
  onDelete?: () => void;
  reduxActions: Actions & AppActions
}>

interface State {
  ckeditorMode: CKEDITOR_MODE;
}

class AdminEventClassTypeForm extends React.Component<Props, {}> {
  public props: Props;
  private editor: any;

  state = {
    ckeditorMode: CKEDITOR_MODE.WYSIWYG,
  };

  isFormDisabled = () => {
    const { adminEventsCacheOne, action } = this.props;
    
    if (adminEventsCacheOne.EventsEventType) {
      if (action === 'add') {
        return !adminEventsCacheOne.EventsEventType.Permissions.hasAddMessage;
      } else {
        return !adminEventsCacheOne.EventsEventType.Permissions.hasEditMessage;
      }
    }
  };

  componentWillUnmount() {
    if (this.editor && this.editor.getCommand("maximize").state === 1) {
      // if ckeditor is maximized, minimize it before unloading this page
      this.editor.execCommand('maximize');
    }
  }

  onCKEditorModeChange = (eventInfo) => {
    if (eventInfo.editor && eventInfo.editor.mode) {
      this.setState({
        ckeditorMode: eventInfo.editor.mode,
      });
      
      registerSourceModeInputEvent(eventInfo, this.onCKEditorSourceModeInput);
    }
  };

  onCKEditorSourceModeInput = (inputEventInfo) => {
    const {messageForm: {ValidationRules}, reduxActions} = this.props;
    reduxActions.simpleUpdate(inputEventInfo.data.$.target.value, ValidationRules.Message);
  };

  onCKEditorReady = (eventInfo) => {
    if (this.editor !== eventInfo.editor) this.editor = eventInfo.editor;
    this.editor.setReadOnly(this.isFormDisabled());
  };

  public render() {
    const {onSave, onDelete, adminEventsCacheOne, action, disabled, reduxActions, messageForm: {ActiveForm, ValidationRules, SubmitErrorMessage}, inert, loading, handleCompact, hideFormAction} = this.props;

    const permissions = adminEventsCacheOne.EventsEventType ? adminEventsCacheOne.EventsEventType.Permissions : null;
    return (
      <Main inert={inert}>
        <MainContent loading={loading} className={`${namespace()}--main-content`} handleCompact={handleCompact}>
            <Form
              onSubmit={e => e.preventDefault()}
            >
            {action === 'add' && permissions && permissions.hasAddMessageReason ? <Alert className={`${namespace()}--alert`}>{permissions.hasAddMessageReason}</Alert> : null}
            {action === 'edit' && permissions && permissions.hasEditMessageReason ? <Alert className={`${namespace()}--alert`}>{permissions.hasEditMessageReason}</Alert> : null}
            {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
              <Row>
                <Column span={12}>
                  <TextField
                    label="Subject"
                    id={generateDOMId("tentaroo-admin-message-subject-field")}
                    disabled={this.isFormDisabled()}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Name}
                    value={ActiveForm.Name}
                  />
                </Column>
              </Row>
              <Row className={`${namespace()}--ckeditor-row`} marginTop={16}>
                <Column span={12}>
                  <CKEditor
                    config={{...DEFAULT_CKEDITOR_CONFIG, startupMode: this.state.ckeditorMode, height: 346}}
                    data={ActiveForm.Message}
                    onChange={(e) => reduxActions.simpleUpdate(e.editor.getData(), ValidationRules.Message)}
                    onMode={this.onCKEditorModeChange}
                    onReady={this.onCKEditorReady}
                  />
                </Column>
              </Row>
            </Form>
        </MainContent>
      </Main>
    );
  }
}

export default AdminEventClassTypeForm;