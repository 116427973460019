import {
  createApiValidateActions,
  createValidateActions,
  createUpdateValueMethod,
  createApiSubmitActions,
  createSimpleUpdateValueMethod,
  innerApiSubmitFormMethod,
  getCacheLevelExtra,
} from "../../../Validation/actionCreator";
import { ActionCreator, ApplicationState } from "../../..";
import { SaveState } from "../../../Rollback/actions";
import {
  actionCreators as appActionCreators,
  ToggleModalSaving,
} from "../../../App/actions";
import { scrollSideModalToTop } from "../../../../utils/helpers/adminCMSPageHelper";
import { navPush } from "../../../../utils";
import {
  ADMIN_EVENTS_MODULE_SUFFIX,
  ADMIN_EVENT_TYPES_SUFFIX,
} from "../../CacheOne/actions";
import { AdminEventClassTypeActiveForm } from ".";
import {
  getAdminEventsEventClassTypesUrl,
  getDeleteAdminEventClassTypeBody,
  getUpdateAdminEventClassTypeBody,
} from "../../../../constants/adminEventsUrls";
import {
  ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX,
  UPDATE_EVENT_FORM_SUFFIX,
} from "../../CacheTwoEvent/actions";
import { ModalTypes, isModalOpened } from "../../../../utils/modalHelper";
import { UPDATE_FORM_ACTION_SUFFIX } from "../../../../utils/suffix";
import { PlainRoute } from "react-router";
import { ClearAdminEventsCacheThreeClassType } from "../../CacheThreeClassType/actions";
import { isClassType_NewTracking, isClassType_OldTracking } from "../../../../models/api/adminEventsCacheThreeClassType";
import {Action, typeName} from "../../../../utils/StrongActions";
import { MBRequirement } from "../../../../models/api/adminEventsCacheTwoEvent";

export const UPDATE_EVENT_CLASS_TYPE_FORM_SUFFIX =
  UPDATE_FORM_ACTION_SUFFIX + "__ADMIN_EVENT_CLASS_TYPE";
export const SUFFIX =
  ADMIN_EVENT_TYPES_SUFFIX +
  ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX +
  UPDATE_EVENT_FORM_SUFFIX +
  ADMIN_EVENTS_MODULE_SUFFIX +
  "__ADMIN_EVENT_CLASS_TYPE_FORM" +
  UPDATE_EVENT_CLASS_TYPE_FORM_SUFFIX;
export const DEL_SUFFIX =
  ADMIN_EVENT_TYPES_SUFFIX +
  ADMIN_EVENTS_MODULE_SUFFIX +
  ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX +
  UPDATE_EVENT_FORM_SUFFIX +
  "__DELETE_ADMIN_CLASS_TYPE" +
  UPDATE_EVENT_CLASS_TYPE_FORM_SUFFIX;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const UpdateAdminEventClassTypeSubmitActions =
  createApiSubmitActions(SUFFIX);
export const DeleteAdminEventClassTypeSubmitActions =
  createApiSubmitActions(DEL_SUFFIX);

export const AdminEventClassTypeFormNamespace = (): string =>
  "pages--admin-events--class-type";

@typeName("REMOVE_REQ") export class RemoveRequirement extends Action {
  constructor(public reqID: number) { super(); }
}

const formCreator = (
  ActiveForm: AdminEventClassTypeActiveForm,
  ClassType_AllMBRequirements: MBRequirement[] | null | undefined,
) => {
  const IDi = ActiveForm.IDi;
  const formData = new FormData();

  let RequirementsArray: undefined | number[] = undefined;

  if (isClassType_NewTracking(ActiveForm)) {
    if (!ClassType_AllMBRequirements || ClassType_AllMBRequirements.length === 0) {
      RequirementsArray = [];
    } else {
      RequirementsArray = ActiveForm.RequirementsArray.filter((reqId) => {
        return Boolean(ClassType_AllMBRequirements.find((req) => req.ID === reqId));
      });
    }
  }

  formData.append(
    "JSONData",
    JSON.stringify(
      getUpdateAdminEventClassTypeBody({
        IDi: IDi,
        Name: ActiveForm.Name,
        mbID: ActiveForm.mbID,
        Description: ActiveForm.Description,
        Requirements: isClassType_OldTracking(ActiveForm)
          ? ActiveForm.Requirements
          : undefined,
        RequirementsArray,
        TS: ActiveForm.TS || 0,
        RemoveImage: !!IDi && !ActiveForm.FeaturedImage,
      })
    )
  );

  if (ActiveForm.FeaturedImage && ActiveForm.FeaturedImage.inputObj)
    formData.append("File", ActiveForm.FeaturedImage.inputObj);

  return formData;
};

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(
    ValidateActions,
    ApiValidateActions,
    (s: ApplicationState) => s.adminEvents.classTypes.form
  ),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  apiSubmitForm:
    (router: any, routes: any): ActionCreator =>
    (dispatch, getState) => {
      const state = getState() as ApplicationState;
      // form validation (not including file)
      const valid = innerApiSubmitFormMethod(
        dispatch,
        UpdateAdminEventClassTypeSubmitActions,
        (s: ApplicationState) => s.adminEvents.classTypes.form,
        undefined,
        undefined,
        true,
        true,
        undefined,
        undefined
      );

      if (valid) {
        const body = formCreator(
          state.adminEvents.classTypes.form.ActiveForm,
          state.adminEvents.cacheThreeClassType.ClassType_AllMBRequirements,
        );
        const isClassTypeFormModalOpened = isModalOpened(
          ModalTypes.CLASS_TYPE_FORM
        );
        if (!isClassTypeFormModalOpened) {
          if (
            state.adminEvents.cacheOne.EventsEventType &&
            state.adminEvents.cacheTwoEvent.EventsEvent
          ) {
            navPush(
              router,
              getAdminEventsEventClassTypesUrl({
                eventTypeId:
                  state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID,
                eventId: state.adminEvents.cacheTwoEvent.EventsEvent.IDi,
                eventName: state.adminEvents.cacheTwoEvent.EventsEvent.Name,
              })
            );
          }
        } else {
          dispatch(
            appActionCreators.popModal(
              false,
              false,
              ModalTypes.CLASS_TYPE_FORM
            ) as any
          );
          dispatch(new ToggleModalSaving(true));
        }
        dispatch(
          UpdateAdminEventClassTypeSubmitActions.request(
            body,
            getCacheLevelExtra(
              true,
              !!state.adminEvents.cacheThreeClassType.ClassType
            )
          )
        );

        // For ClassTypeFormModal, we need this clear action to trigger SavePoint during
        // save. For ClassTypeForm page, the clear happens in `componentWillUnmount`
        if (
          isClassTypeFormModalOpened &&
          !!getState().adminEvents.cacheThreeClassType.ClassType
        ) {
          dispatch(new ClearAdminEventsCacheThreeClassType());
        }
      } else {
        scrollSideModalToTop(AdminEventClassTypeFormNamespace());
      }
    },
  deleteAdminEventClassType:
    (
      classTypeId: number,
      routes: PlainRoute<any>[],
      router: any,
      inactive: boolean
    ): ActionCreator =>
    (dispatch, getState) => {
      const state = getState();
      const route = routes[routes.length - 1];
      if (
        !state.adminEvents.cacheOne.EventsEventType ||
        !state.adminEvents.cacheTwoEvent.EventsEvent
      )
        return;

      dispatch(new SaveState());

      const body = getDeleteAdminEventClassTypeBody({
        IDi: classTypeId,
        Inactive: inactive,
      });
      navPush(
        router,
        getAdminEventsEventClassTypesUrl({
          eventTypeId:
            state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID,
          eventId: state.adminEvents.cacheTwoEvent.EventsEvent.IDi,
          eventName: state.adminEvents.cacheTwoEvent.EventsEvent.Name,
        })
      );
      dispatch(
        DeleteAdminEventClassTypeSubmitActions.request(
          body,
          getCacheLevelExtra(
            true,
            !!state.adminEvents.cacheThreeClassType.ClassType
          ),
          route
        )
      );
    },
  initAdminEventClassTypeForm: (): ActionCreator => (dispatch) => {
    dispatch(UpdateAdminEventClassTypeSubmitActions.updateForm());
    dispatch(new SaveState());
  },
  removeRequirement: (reqID: number): ActionCreator => (dispatch) => {
    dispatch(new RemoveRequirement(reqID));
  }
};
