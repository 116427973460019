import * as Actions from './actions';
import {FAC_TRIP_ADD, FAC_TRIP_RESERVATION} from "../../../../constants/urls";
import { FacilitiesCalc } from '../../../CacheFourFacilities/actions';
import { Reducer } from 'redux';
import { isActionType } from '../../../../utils/StrongActions';

export type ReservationPage = 'type' | 'reservation' | 'confirmation';

export interface FacilitiesReservationState {
  selectedPage: ReservationPage;
  pendingPage?: ReservationPage;
}

export const isUrlAddReservation = (path: string) => {
  const isAdd = path.includes(`/${FAC_TRIP_RESERVATION}/${FAC_TRIP_ADD}`);
  return isAdd;
};

const getInitialState = (selectedPage?: ReservationPage): FacilitiesReservationState => {
  return {
    selectedPage: selectedPage ?? "reservation",
  };
};

const FacilitiesReservation: Reducer<FacilitiesReservationState> = (state: FacilitiesReservationState, action: any) => {
  if (isActionType(action, Actions.FacilitiesReservationSelectPage)) {
    window.scrollTo(0,0);
    return {...state, selectedPage: action.selectedPage};
  } else if (isActionType(action, Actions.FacilitiesReservationSetPendingPage)) {
    return {...state, pendingPage: action.pendingPage};
  } else if (isActionType(action, Actions.FacilitiesReservationTransitionToPending)) {
    if (state.pendingPage) {
      window.scrollTo(0,0);
      return {...state, selectedPage: state.pendingPage, pendingPage: undefined};
    }
    return state;
  } else if (isActionType(action, Actions.FacilitiesReservationReset) || action.type === FacilitiesCalc.requestType) {
    return {...getInitialState(isUrlAddReservation(action.rootState.routing.locationBeforeTransitions.pathname) ? "type" : "reservation")};
  }
  return state || {...getInitialState()};
};

export default FacilitiesReservation;
