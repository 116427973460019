import * as EM from "../../../../../constants/messages/events";
import { Validator } from '../../../../../utils/validator/models';

export interface IValidator {
  Amount: Validator;
}

export const FormDefinition: IValidator = {
  Amount: {
    key: 'Amount',
    decimalOnly: true,
    allowNegative: () => false,
    validatejs: {
      Amount: {
        presence: {message: '^' + EM.FEE_REQUIRED},
        numericality: {
          notValid: `^${EM.FEE_INVALID}`
        }
      }
    }
  }
};
