import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { namespace as ns } from './constants';

export const namespace = (): string => `${ns()}--item`;

export interface ItemProps {
  children?: React.ReactNode;
  className?: string;
  size?: number;
}

const Item: React.SFC<ItemProps> = (props: ItemProps): React.ReactElement<ItemProps> => {
  const { size, children } = props;
  const style: React.CSSProperties = {};
  if (size !== null && size !== undefined) style.fontSize = `${size}px`;

  return (
    <li className={mergeClassNames(namespace(), props)} style={style}>
      <span className={`${namespace()}--text`} style={style}>{children}</span>
    </li>
  );
};

export default Item;
