import * as React from 'react';

import Footer from "../../Footer";
import FooterProgress from "../../Footer/Progress";

export interface Step {
  selected: boolean;
  onClick?: () => void; // @todo: remove onClick and use back/next explicitly
}

export interface FooterProgressProps {
  // @todo: hm having both these is confusing
  onComplete?: () => void;
  onBackClick?: (backParam?: string, isNext?: boolean) => void;
  onNextClick?: (nextParam?: string, isNext?: boolean) => void;
  steps: Array<Step>;
  backParam?: string;
  nextParam?: string;
  loading?: boolean;
  isDeleting?: boolean;
}

const RegisterFooterProgress: React.SFC<FooterProgressProps> = ({
  steps, onComplete, onBackClick, onNextClick, nextParam, backParam, loading, isDeleting
}: FooterProgressProps): React.ReactElement<FooterProgressProps> => {
  let numSteps: number = 0;
  let currentStep: number = 0;
  let onNextClickAction: any = undefined;
  let onBackClickAction: any = undefined;

  if (onNextClick) onNextClickAction = onNextClick;
  if (onBackClick) onBackClickAction = onBackClick;

  // @todo: this is legacy and should eventually be removed
  steps.forEach((step: Step) => {
    numSteps++;
    if (currentStep && !onNextClickAction) {
      onNextClickAction = step.onClick;
    }
    if (step.selected) {
      currentStep = numSteps;
    }
    if (!currentStep && !onBackClickAction) {
      onBackClickAction = step.onClick;
    }
  });

  return (
    <Footer>
      <FooterProgress
        steps={numSteps}
        currentStep={currentStep}
        onNextClick={onNextClickAction}
        onBackClick={onBackClickAction}
        backParam={backParam}
        nextParam={nextParam}
        onComplete={onComplete}
        loading={loading}
      />
    </Footer>
  );
};

export default RegisterFooterProgress;
