import * as React from 'react';
import { Main, MainContent } from '../../../../Layouts';
import { Alert, Button, EmptyMessage, LoadingAll, PageLoader, Product, ShowContacts, Row } from '../../../../Elements';
import { EventsIcon } from '../../../../Icons';
import CampInfo from '../../CampInfo';
import EventSidebar from '../../Sidebar';
import EventContactCard from '../../ContactCard';
import FinancialSummary from './FinancialSummary';
import Registration from './Registration';
import Spots from './Spots';
import Participants from './Participants';
import GroupClasses from './GroupClasses';
import GroupProducts from '../GroupProducts';
import ContactInfos from '../../../Facilities/ContactInfos';
import { bindActionCreators } from 'redux';
import {
  actionCreators,
  FSSubmitActions, ReportBlueCardsActions,
  ReportClassScheduleActions, ReportInvoiceActions, ReportRequirementsCompletedActions, ReportRequirementsScoutbookActions, ReportRosterActions
} from "../../../../../store/Events/Event/Registration/actions";
import {
  actionCreators as rollbackActionCreators,
} from "../../../../../store/Rollback/actions";
import {
  actionCreators as eventActionsCreators,
} from "../../../../../store/Events/Event/Main/actions";
import {
  actionCreators as eventTypeActionCreators,
} from "../../../../../store/Events/EventType/actions";
import {
  actionCreators as cacheThreeActionCreators,
} from "../../../../../store/CacheThreeEvents/actions";
import {
  actionCreators as cacheTwoActionCreators,
  ClearCacheBelowTwoEvents
} from "../../../../../store/CacheTwoEvents/actions";
import {
  actionCreators as cacheOneActionCreators,
} from "../../../../../store/CacheOne/actions";
import {
  actionCreators as appActionCreators,
} from "../../../../../store/App/actions";
import { EventType } from "../../../../../models/api/cacheOne";
import { makeSelectedEventTypeSelector } from "../../../../../store/CacheTwoEvents/index";
import { RouteComponentProps, withRouter } from "react-router";
import {
  getClassScheduleUrl,
  getEventRegisterNumbersUrl,
  getEventRegisterParticipantUrl, getEventRegisterProductsUrl,
  getEventRegistrationClassesUrl,
  getRequirementsCompletedPDFUrl,
  URLS
} from "../../../../../constants/urls";
import { navPush } from "../../../../../utils/navHelper";
import {
  makeAdultParticipantTypeSelector,
  makeSelectedEventSelector,
  makeYouthParticipantTypeSelector
} from "../../../../../store/CacheThreeEvents/index";
import { getWarningAndTitle } from "../index";
import { EventInfo } from "../../../../../models/api/cacheThreeEvents";
import '../../../../../styles/pages/events/event/registration/index.scss';
import { EventParticipantCalc } from "../../../../../store/CacheFourEventsParticipants/actions";
import { checkPermission } from "../../../../../utils/permissionHelper";
import { getFullAddress } from "../../../../../utils/addressHelper";
import { makeC3OrderedProductsFilter } from "../../../../../store/CacheThreeEvents";
import { ApplicationState } from '../../../../../store';
import EndUserCacheManager from '../../../../../utils/cacheManagers/endUserCacheManager';
import { shouldBlockActions } from '../../../../../utils/cacheLoaders/helpers/blockers';
import { IEventRouterParams, isPathUnderEndUserEventCacheThree } from '../../../../../utils/helpers/endUserPageHelper';
import { shouldReconfigRouter } from '../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { reduxStoreService } from '../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isCacheThreeEventsPopulated, isEndUserCacheOnePopulated} from '../../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--event--registration';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IEventRouterParams, {}>
>;

@(withRouter as any)
class RegistrationComponent extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
  private nextLocation;

  public componentDidMount() {
    this.configRouteLeaveHook();
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeEvent({
          props: this.props,
          isStateNavigated,
        });
      }
    );
  }

  public componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeEvent({
          props: nextProps,
          isStateNavigated,
        });
      }
    );
    if (shouldBlockActions()) return;
    if (nextProps.eventRegistration.isOtherRecentlySelected) {
      nextProps.actions.otherNotRecentlySelected();
      nextProps.actions.financeDirty();
    }
  }

  componentWillUnmount() {
    const {cacheThreeEvents} = this.props;

    // if we are not navigating to another page under cache three events, clear cache
    if (
      this.nextLocation &&
      !isPathUnderEndUserEventCacheThree(this.nextLocation.pathname) &&
      isCacheThreeEventsPopulated(cacheThreeEvents)
    ) {
      reduxStoreService().dispatch(new ClearCacheBelowTwoEvents());
    }
    this.resetRouteLeaveHook();
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouteLeaveHook();
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  onClickGroupClass = () => navPush(this.props.router, getEventRegistrationClassesUrl(this.props));

  onShowMoreProducts = () => {
    const { actions, eventRegistration: { showMoreProductsRegistered } } = this.props;
    actions.eventRegistrationShowMoreProducts(!showMoreProductsRegistered);
  };

  onManage = () => {
    const { cacheThreeEvents: { EventInfoPermissions }, router } = this.props;
    if (!EventInfoPermissions) return;

    checkPermission(
      () => navPush(router, getEventRegisterNumbersUrl(this.props)),
      EventInfoPermissions.hasRegistrationNumbersChange,
      EventInfoPermissions.hasRegistrationNumbersChangeReason
    );
  };

  onProductManage = () => {
    const { cacheThreeEvents: { EventInfoPermissions }, router } = this.props;
    if (!EventInfoPermissions) return;

    checkPermission(
      () => navPush(router, getEventRegisterProductsUrl(this.props)),
      EventInfoPermissions.hasRegistrationProducts,
      EventInfoPermissions.hasRegistrationProductsReason
    );
  };

  onCheckout = () => navPush(this.props.router, URLS.CHECKOUT);

  onCancelRollback = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.props.actions.restoreState();
  };

  onRestoreParticipant = (ParticipantIDi: number, IsYouth: boolean, Name: string) => {
    const { actions, selectedEventType, selectedEvent, cacheThreeEvents: { EventRegistrationPaymentStatus, EventInfoPermissions } } = this.props;

    if (!EventInfoPermissions) return;

    if (selectedEventType && selectedEvent && EventRegistrationPaymentStatus) {
      checkPermission(
        () => {
          actions.selectUserForRestore(IsYouth, ParticipantIDi, Name);
          actions.pushCancelRegistrationModal(
            IsYouth,
            Name,
            ParticipantIDi,
            -1,
            selectedEventType.ID,
            selectedEvent.IDi,
            EventRegistrationPaymentStatus.IDi,
            true,
            false
          );
        },
        EventInfoPermissions.hasRegistrationNames,
        EventInfoPermissions.hasRegistrationNamesReason
      );
    }
  };

  onRegister = () => {
    const { cacheTwoEvents: { eventType }, cacheThreeEvents: { EventInfoPermissions, EventInfo } } = this.props;

    if (!EventInfoPermissions) return;

    let registerUrl = getEventRegisterNumbersUrl(this.props);
    const onRegist = () => navPush(this.props.router, registerUrl);
    if (eventType === 'participant') registerUrl = getEventRegisterParticipantUrl(this.props);
    if (EventInfo && !EventInfo.HasGroupRegistration) {
      checkPermission(
        onRegist,
        EventInfoPermissions.hasRegistrationNumbersAdd,
        EventInfoPermissions.hasRegistrationNumbersAddReason
      );
    } else {
      checkPermission(
        onRegist,
        EventInfoPermissions.hasRegistrationNumbersChange,
        EventInfoPermissions.hasRegistrationNumbersChangeReason
      );
    }
  };

  verifyReport = (onReport: () => void) => {
    const { cacheThreeEvents: { EventInfoPermissions } } = this.props;

    if (!EventInfoPermissions) return;

    checkPermission(
      onReport,
      EventInfoPermissions.hasViewReports,
      EventInfoPermissions.hasViewReportsReason
    );
  };

  onReportInvoice = () => {
    const { cacheThreeEvents: { EventInfo }, cacheZero: { options }, actions } = this.props;
    if (!EventInfo || !options?.Group) return;

    const EventIDi = EventInfo.IDi;
    const EventTypeID = EventInfo.EventTypeID;
    const GroupIDi = options.Group.IDi;
    const onReport = () => {
      actions.reportInvoice(EventIDi, EventTypeID, GroupIDi);
    };
    this.verifyReport(onReport);
  };

  onReportParticipantRoster = () => {
    const { cacheThreeEvents: { EventInfo, EventRegistrationPaymentStatus }, actions } = this.props;

    if (!EventInfo || !EventRegistrationPaymentStatus) return;
    const EventIDi = EventInfo.IDi;
    const EventTypeID = EventInfo.EventTypeID;
    const GroupWeekIDi = EventRegistrationPaymentStatus.IDi;
    const onReport = () => {
      actions.reportRoster(EventIDi, EventTypeID, GroupWeekIDi);
    };
    this.verifyReport(onReport);
  };
  onReportClassSchedule = () => {
    const { cacheThreeEvents: { EventInfo, EventRegistrationPaymentStatus }, actions } = this.props;
    if (!EventInfo || !EventRegistrationPaymentStatus) return;

    const EventIDi = EventInfo.IDi;
    const EventTypeID = EventInfo.EventTypeID;
    const GroupWeekIDi = EventRegistrationPaymentStatus.IDi;
    const onReport = () => {
      let url = getClassScheduleUrl({ EventIDi, EventTypeID, GroupWeekIDi, reportDoc: 'troopschedule.cfr' });
      window.open(url);
    };
    this.verifyReport(onReport);
  };

  onReportClassSchedulePerson = () => {
    const { cacheThreeEvents: { EventInfo, EventRegistrationPaymentStatus }, actions } = this.props;
    if (!EventInfo || !EventRegistrationPaymentStatus) return;

    const EventIDi = EventInfo.IDi;
    const EventTypeID = EventInfo.EventTypeID;
    const GroupWeekIDi = EventRegistrationPaymentStatus.IDi;
    const onReport = () => {
      let url = getClassScheduleUrl({ EventIDi, EventTypeID, GroupWeekIDi, reportDoc: 'scoutschedule.cfr' });
      window.open(url);
    };
    this.verifyReport(onReport);
  };

  onReportRequirementsCompleted = () => {
    const { cacheThreeEvents: { EventInfo }, cacheZero: { options }, actions } = this.props;
    if (!EventInfo || !options?.Group) return;

    const EventIDi = EventInfo.IDi;
    const EventTypeID = EventInfo.EventTypeID;
    const GroupIDi = options.Group.IDi;
    const onReport = () => {
      actions.reportRequirementsCompletedActions(EventIDi, EventTypeID, GroupIDi);
    };
    this.verifyReport(onReport);
  };

  onReportRequirementsCompletedPDF = () => {
    const { cacheThreeEvents: { EventInfo, EventRegistrationPaymentStatus }, actions } = this.props;
    if (!EventInfo || !EventRegistrationPaymentStatus) return;

    const EventIDi = EventInfo.IDi;
    const EventTypeID = EventInfo.EventTypeID;
    const GroupWeekIDi = EventRegistrationPaymentStatus.IDi;
    const onReport = () => {
      let url = getRequirementsCompletedPDFUrl({ EventIDi, EventTypeID, GroupWeekIDi, reportDoc: 'reqs.cfr' });
      window.open(url);
    };
    this.verifyReport(onReport);
  };

  onReportBlueCardsFront = () => {
    const { cacheThreeEvents: { EventInfo }, cacheZero: { options }, actions } = this.props;
    if (!EventInfo || !options?.Group) return;

    const EventIDi = EventInfo.IDi;
    const GroupIDi = options.Group.IDi;
    const onReport = () => {
      actions.reportBlueCards(EventIDi, GroupIDi, true);
    };
    this.verifyReport(onReport);
  };

  onReportRequirementsScoutbook = () => {
    const { cacheThreeEvents: { EventInfo }, cacheZero: { options }, actions } = this.props;
    if (!EventInfo || !options?.Group) return;

    const EventIDi = EventInfo.IDi;
    const EventTypeID = EventInfo.EventTypeID;
    const GroupIDi = options.Group.IDi;
    const onReport = () => {
      actions.reportRequirementsScoutbook(EventTypeID, EventIDi, GroupIDi);
    };
    this.verifyReport(onReport);
  };

  onReportBlueCardsBack = () => {
    const { cacheThreeEvents: { EventInfo }, cacheZero: { options }, actions } = this.props;
    if (!EventInfo || !options?.Group) return;

    const EventIDi = EventInfo.IDi;
    const GroupIDi = options.Group.IDi;
    const onReport = () => {
      actions.reportBlueCards(EventIDi, GroupIDi, false);
    };
    this.verifyReport(onReport);
  };

  private renderRegistration = (EventInfo: EventInfo, selectedEventType: EventType): any => {
    const { systemSettings, actions, cacheTwoEvents: { eventType }, eventRegistration, cacheThreeEvents: { EventInfoPermissions }, eventType: { showMapMobile } } = this.props;

    if (!EventInfoPermissions) return null;

    let registerUrl = getEventRegisterNumbersUrl(this.props);
    if (eventType === 'participant') registerUrl = getEventRegisterParticipantUrl(this.props);

    // EventContactCard only shows on tablet and below
    // ShowContacts only appears on mobile
    let showNumberAlert = false;
    let showNamesAlert = false;
    if (!EventInfo.HasGroupRegistration) {
      showNumberAlert = !EventInfoPermissions.hasRegistrationNumbersAdd && (eventType === 'both' || eventType === 'numbers');
      showNamesAlert = !EventInfoPermissions.hasRegistrationNames && eventType === 'participant';
    }

    const ret: Array<any> = [
      <EventContactCard key="contact-card" marginBottom={(showNumberAlert || showNamesAlert) ? 16 : undefined} mobileMarginBottom={0} />
    ];
    if (EventInfo && !EventInfo.HasGroupRegistration) {
      if (showNumberAlert) {
        ret.push(<Row mobileMarginBottom={16}><Alert>{EventInfoPermissions.hasRegistrationNumbersAddReason}</Alert></Row>);
      } else if (showNamesAlert) {
        ret.push(<Row mobileMarginBottom={16}><Alert>{EventInfoPermissions.hasRegistrationNamesReason}</Alert></Row>);
      }

      ret.push(<EmptyMessage
        key="empty-message"
        icon={EventsIcon}
        description="You haven't registered for this event"
        actions={<Button id={generateDOMId("register-for-event-btn")} color="green" big onClick={this.onRegister} disabled={!EventInfoPermissions}>REGISTER FOR EVENT</Button>}
      />);
    } else {
      ret.push(this.renderContent(EventInfo));
    }
    return ret;
  };

  onSaveOthers = () => {
    const {actions, cacheThreeEvents: {EventInfoPermissions}} = this.props;

    if (!EventInfoPermissions) return;

    checkPermission(
      () => {
        actions.saveFinance();
      },
      EventInfoPermissions.hasEventRegistrationsGroup,
      EventInfoPermissions.hasEventRegistrationsGroupReason,
    );
  };

  private renderContent(EventInfo: EventInfo): any {
    const { cacheTwoEvents: { eventType, EventTypeRegistrationSettings }, actions, apiSavingMap, youthTypes, adultTypes,
      eventRegistration: { isFinanceDirty, ActiveForm: { selectedFinance, otherValue }, ValidationRules }, orderedProducts,
      cacheThreeEvents: {
        EventClassesGroupRegistered,
        EventProductsOrdered,
        EventProductsAvailable,
        EventRegistrationNumbers,
        EventInfoParticipantTypes,
        EventRegistrationPaymentStatus,
        sortedParticipants,
        participantTypeMap,
        EventInfoPermissions,
        eventID
      },
      user,
      cacheZero: {
        options
      }
    } = this.props;
    const ret: Array<any> = [];
    const isAdmin = user.user && user.user.str_permissions.hasAdminAccess;
    
    if (!EventInfoPermissions) return null;
    if (EventInfo.InCart !== 0) {
      ret.push(
        <Row mobileMarginBottom={0}>
          <Alert alertIcon color="red" key="alert">
            There are changes or payments for this event in your cart.
            Please remember to checkout soon to finalize your changes.
          </Alert>
        </Row>
      );
    }

    if (selectedFinance && EventRegistrationPaymentStatus) {
      const fees: Array<{ label: string; value: number; }> = [];
      fees.push({
        label: 'Registration Fees',
        value: EventRegistrationPaymentStatus.RegistrationTotal
      });
      fees.push({
        label: 'Options',
        value: EventRegistrationPaymentStatus.ClassAmount
      });
      if (orderedProducts && orderedProducts.length > 0) {
        fees.push({
          label: 'Products',
          value: EventRegistrationPaymentStatus.ProductAmount
        });
      }

      ret.push(<FinancialSummary
        key="fs"
        fees={fees}
        paymentStatus={EventRegistrationPaymentStatus}
        showLoaders={false}
        onSelect={actions.updateValue}
        onSaveOther={this.onSaveOthers}
        onCancelOther={this.onCancelRollback}
        updateValue={actions.updateValue}
        simpleUpdate={actions.simpleUpdate}
        selected={selectedFinance}
        isFinanceDirty={isFinanceDirty}
        validationRules={ValidationRules}
        isSaving={apiSavingMap[FSSubmitActions.requestType]}
        otherValue={otherValue}
        IsAdmin={isAdmin}
        onCheckout={this.onCheckout}
        hasAccess={EventInfoPermissions.hasEventRegistrationsGroup}
        hasAccessMessage={EventInfoPermissions.hasEventRegistrationsGroupReason}
        CartOrderItems={this.props.cacheOne.CartOrderItems}
      />);
    }
    if (eventType !== 'participant' && EventRegistrationNumbers) {
      ret.push(<Registration
        key="registration"
        eventType={eventType}
        registration={EventRegistrationNumbers}
        onManage={this.onManage}
        showCart={EventInfo.InCart !== 0}
        actions={actions}
        onReportInvoice={this.onReportInvoice}
        onReportParticipantRoster={this.onReportParticipantRoster}
        onReportClassSchedule={this.onReportClassSchedule}
        onReportClassSchedulePerson={this.onReportClassSchedulePerson}
        onReportRequirementsCompleted={this.onReportRequirementsCompleted}
        onReportRequirementsScoutbook={this.onReportRequirementsScoutbook}
        onReportRequirementsCompletedPDF={this.onReportRequirementsCompletedPDF}
        onReportBlueCardsFront={this.onReportBlueCardsFront}
        onReportBlueCardsBack={this.onReportBlueCardsBack}
      />);
    }
    if (eventType !== 'participant' && EventInfoParticipantTypes && EventTypeRegistrationSettings) {
      ret.push(<Spots
        key="spots"
        youthTypes={
          EventTypeRegistrationSettings.RegistrationSettings.ShowYouth ?
            youthTypes : []
        }
        adultTypes={
          EventTypeRegistrationSettings.RegistrationSettings.ShowAdults ?
            adultTypes : []
        }
        EventRegistrationNumbers={EventRegistrationNumbers}
        NumbersRegistrationSettings={EventTypeRegistrationSettings.NumbersRegistrationSettings}
        totalSpots={EventInfo.TotalNumbersYouth + EventInfo.TotalNumbersAdults}
        onClickSpots={this.onManage}
        eventType={eventType}
        eventSpotsAvailable={EventInfo.NumEventSpotsAvailablePending}
        MaxParticipants={EventInfo.MaxParticipants}
      />);
    }
    if (eventType !== 'numbers' && EventRegistrationPaymentStatus && EventTypeRegistrationSettings) {
      ret.push(<Participants
        key="participants"
        EventInfoParticipantTypes={EventInfoParticipantTypes}
        sortedParticipants={sortedParticipants}
        participantTypeMap={participantTypeMap}
        total={EventInfo.TotalNamesYouth + EventInfo.TotalNamesAdults}
        ParticipantAmountMinBalance={EventRegistrationPaymentStatus.ParticipantAmountMinBalance}
        eventType={eventType}
        eventSpotsAvailable={EventInfo.NumEventSpotsAvailablePending}
        MaxParticipants={EventInfo.MaxParticipants}
        showYouth={EventTypeRegistrationSettings.RegistrationSettings.ShowYouth}
        showAdult={EventTypeRegistrationSettings.RegistrationSettings.ShowAdults}
        EventInfoPermissions={EventInfoPermissions}
        onRestoreClick={this.onRestoreParticipant}
        onReportInvoice={this.onReportInvoice}
        onReportParticipantRoster={this.onReportParticipantRoster}
        onReportClassSchedule={this.onReportClassSchedule}
        onReportClassSchedulePerson={this.onReportClassSchedulePerson}
        onReportRequirementsCompleted={this.onReportRequirementsCompleted}
        onReportRequirementsCompletedPDF={this.onReportRequirementsCompletedPDF}
        onReportRequirementsScoutbook={this.onReportRequirementsScoutbook}
        onReportBlueCardsFront={this.onReportBlueCardsFront}
        onReportBlueCardsBack={this.onReportBlueCardsBack}
      />);
    }
    if (eventType !== 'participant' && EventClassesGroupRegistered) {
      ret.push(<GroupClasses
        key="group-classes"
        classes={EventClassesGroupRegistered}
        onClickItem={this.onClickGroupClass}
        onManage={this.onManage}
        EventInfoPermissions={EventInfoPermissions}
      />);
    }

    if (
      (EventProductsAvailable && EventProductsAvailable.length > 0) ||
      (EventProductsOrdered && EventProductsOrdered.length > 0)
    ) {
      // @todo: need permissions here
      const renderProducts: any[] = [];
      let num = EventProductsOrdered ? EventProductsOrdered.length : 0;
      if (EventProductsOrdered) {
        if (!this.props.eventRegistration.showMoreProductsRegistered && num > 3) {
          num = 3;
        }
        for (let i = 0; i < num; i++) {
          const product = EventProductsOrdered[i];
          renderProducts.push(product);
        }
      }

      ret.push(
        <GroupProducts
          key="products"
          onManage={this.onProductManage}
          title="Products"
          emptyMessage="You haven't ordered any products for this event"
          emptyButtonText="ORDER PRODUCTS"
          eventType={eventType}
          numProducts={EventProductsOrdered ? EventProductsOrdered.length : 0}
          showMoreProducts={!!this.props.eventRegistration.showMoreProductsRegistered}
          onShowMore={this.onShowMoreProducts}
        >
          {renderProducts.map((product) => (
            <Product
              productModel={product}
              isSummary
              isRegistrationSummary
              ordered
            />
          ))}
        </GroupProducts>
      );
    }
    return ret;
  }

  public render() {
    const {
      eventRegistration: { mobileShowContact }, cacheOne,
      apiSaving, apiLoadingMap, systemSettings, actions, selectedEventType, selectedEvent, eventType: { showMapMobile }, cacheThreeEvents,
      user, cacheTwoEvents: { eventType }, inert
    } = this.props;

    if (
      !isEndUserCacheOnePopulated(cacheOne) ||
      !selectedEventType || 
      !(selectedEvent || cacheThreeEvents.EventInfo) ||
      !systemSettings || !cacheThreeEvents.EventInfoPermissions
    ) return <LoadingAll />;
    const warningAndTitle = getWarningAndTitle(selectedEvent, cacheThreeEvents.EventInfo);

    const loading = (
      apiSaving > 0 ||
      apiLoadingMap[EventParticipantCalc.requestType] || apiLoadingMap[ReportClassScheduleActions.failureType] ||
      apiLoadingMap[ReportInvoiceActions.requestType] || apiLoadingMap[ReportRequirementsCompletedActions.requestType] ||
      apiLoadingMap[ReportBlueCardsActions.requestType] || apiLoadingMap[ReportRosterActions.requestType] ||
      apiLoadingMap[ReportRequirementsScoutbookActions.requestType]
    );

    const showContactsComp = <ShowContacts key="show-contact"
      show={mobileShowContact}
      onClick={actions.mobileShowContact}
      showMapMobile={!!showMapMobile}
      onShowMapMobile={actions.toggleMapMobile}
      title={selectedEventType.DefaultLocation.Name}
      fullAddress={getFullAddress(selectedEventType.DefaultLocation)}
    >
      <ContactInfos
        contactInfoLabel="Event Contact"
        location={selectedEventType.DefaultLocation}
        contactInfo={selectedEventType.EventContactInfo}
        systemSettings={systemSettings}
        multiple={selectedEventType.DefaultLocation.UpcomingEventsAtOtherLocations}
      />
    </ShowContacts>;

    return (
      <Main inert={inert} isLoading={loading} leftSidebar={<EventSidebar />} rightGutter={false}>
        <MainContent
          header={
            <CampInfo
              eventType={eventType}
              isAdmin={!!user.user.str_permissions.hasAdminAccess}
              campLocation={selectedEventType.DefaultLocation}
              contactInfo={selectedEventType.EventContactInfo}
              image={null}
              systemSettings={systemSettings}
              showMapMobile={showMapMobile}
              onShowMapMobile={actions.toggleMapMobile}
              EventInfoPermissions={cacheThreeEvents.EventInfoPermissions}
              HasGroupRegistration={warningAndTitle.HasGroupRegistration}
              registration
              warning={warningAndTitle.warning}
              title={warningAndTitle.title}
              subTitle={`${selectedEventType.Name} @ ${selectedEventType.DefaultLocation.Name}`}
              hideRegister
              showContactsComp={showContactsComp}
            />
          }
          rightGutter
          className={`${namespace()}--main`}
        >
          {isCacheThreeEventsPopulated(cacheThreeEvents) && cacheThreeEvents.EventInfo ? this.renderRegistration(cacheThreeEvents.EventInfo, selectedEventType) : <PageLoader />}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedEventType = makeSelectedEventTypeSelector();
  const selectedEvent = makeSelectedEventSelector();
  const youthTypes = makeYouthParticipantTypeSelector();
  const adultTypes = makeAdultParticipantTypeSelector();
  const orderedProducts = makeC3OrderedProductsFilter();

  return {
    user: state.user,
    eventType: state.events.eventType,
    event: state.events.event.main,
    eventRegistration: state.events.event.registration,
    systemSettings: state.session.SystemSettings,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheThreeEvents: state.cacheThreeEvents,
    cacheTwoEvents: state.cacheTwoEvents,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    selectedEvent: selectedEvent(state),
    selectedEventType: selectedEventType(state),
    youthTypes: youthTypes(state),
    adultTypes: adultTypes(state),
    orderedProducts: orderedProducts(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheThreeActionCreators,
    ...cacheTwoActionCreators,
    ...cacheOneActionCreators,
    ...eventTypeActionCreators,
    ...eventActionsCreators,
    ...rollbackActionCreators,
    ...appActionCreators
  }, dispatch)
});

const ConnectedRegistrationComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(RegistrationComponent);
export default ConnectedRegistrationComponent;
