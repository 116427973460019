import { Action } from "redux";
import { Observable } from "rxjs";
import { SaveState } from "../store/Rollback/actions";
import { PushModal, PopModal } from "../store/App/actions";
import { SelectLocationModalContext } from "../components/Pages/Shared/Modals/Location";
import { FacilityTypeFormSection } from "../store/AdminFacilityLocation/Facilities/FacilityType/Form/actions";
import { ImportType } from "../components/Pages/FacilityLocation/Facilities/Modals/ImportFromFacility";
import { SelectClassTypeModalType } from "../store/AdminEvents/Events/Modals/SelectClassType";
import { EventToolsModalContext } from "../store/AdminEvents/Events/Event/Dashboard";
import { ParticipantType } from "../models/api/adminEventsCacheOne";
import { EventTypeFormSection } from "../store/AdminEvents/EventTypes/EventType/Form/actions";
import { SelectGLAccountModalContext } from "../components/Pages/AdminSettings/GLAccounts/Modals/GLAccount";
import { ImportFromEventType } from "../components/Pages/AdminEvents/Events/Modals/ImportFromEvent";
import { MultipleGroupsContext } from "../store/AdminEvents/Events/Modals/MultipleGroups";
import { ResetPasswordContext } from "../store/ResetPassword";
import { IResetPasswordAccount } from "../models/api/login";
import {
  WizardReservation,
  TripPaymentStatus,
} from "../models/api/cacheFourFacilities";
import { SimplePerson } from "../store/Events/Event/Register/Participant/Roster";
import {MBRequirement} from "../models/api/adminEventsCacheTwoEvent";
import { ApplicationState } from "../store";
import { reduxStoreService } from "../store/service";
import {RegisteredClass} from "../models/class";

export const ResourceFormModalNamespace = (): string =>
  "pages--cms--modals--resource-form";

export const mapModalNamespace = (modal: ModalTypes) => {
  switch (modal) {
    case ModalTypes.RESOURCE_FORM:
      return ResourceFormModalNamespace();
  }

  return undefined;
};

// don't change the order of these
export enum ModalTypes {
  NOTHING, // 0
  ACCOUNTS_MODAL,
  CART,
  CANCEL_RESERVATION,
  CANCEL_REGISTRATION,

  CHECKOUT_MESSAGES,
  FULL_CLASSES,
  SUBMITTING_PAYMENT,
  ORDER_SUBMITTED,
  PAYMENT_FAILED,

  PROFILE_DELETE_PERSON, // 10

  PAYMENT_TOOLS,

  DELETE_PERSON,

  OVERRIDE_FEES,
  ADD_UNSCHEDULED,
  JOIN_WAITLIST,
  REGISTER_OVERRIDE_FEE,

  FACILITY_TYPES, // TODO: not used, remove it, and then update tests
  CMS_SITES,
  FACILITITY_BLACKOUTS, // TODO: not used, remove it, and then update tests
  EVENTS, // 20, TODO: not used, remove it, and then update tests

  REPORT_FINISHED,

  // CMS
  // TODO: not used, remove it, and then update tests, covered by RESOURCE_CATEGORY_FORM
  SELECT_RESOURCE_CATEGORIES,
  // TODO: not used, remove it, and then update tests, covered by RESOURCE_CATEGORY_FORM
  ADD_RESOURCE_CATEGORY,
  EDIT_RESOURCE_CATEGORY, // 25
  RESOURCES,
  SELECT_PAGE,
  NEW_PAGE,
  MULTIPLE_CONTACTS,
  MULTIPLE_RESOURCES,
  DUPLICATE_PAGE, // 30

  // AdminSettings (Previously Shared)
  SELECT_LOCATION,
  LOCATION_FORM,

  // Admin Facility Location
  NEW_FACILITY,
  MULTIPLE_FACILITIES,
  SELECT_GL_ACCOUNTS, // 35
  GL_ACCOUNT_FORM,
  FACILITY_TYPE_SHARED_SETTINGS,
  FACILITY_TYPE_FORM,
  IMPORT_FROM_FACILITY,

  // Admin Events
  ADMIN_EVENT_TYPES, // 40
  ADMIN_EVENT_TYPE_FORM,
  NEW_EVENT_TYPE,
  EVENT_FORM,
  SELECT_MESSAGE,
  REPLACE_PARTICIPANT_TYPE, // 45
  REMOVE_PARTICIPANT_TYPE,
  EVENT_TYPE_SHARED_SETTINGS,
  IMPORT_FROM_EVENT,
  RECALCULATE_PRICING,
  EXPORT_INSTRUCTOR_ROSTER, // 50
  GENERATE_INVOICES,
  ENTER_CLASS_REQUIREMENT_COMPLETED,
  MULTIPLE_GROUPS,
  MESSAGE_FORM,
  SEND_MESSAGE, // 55
  SELECT_CLASS_TYPE,
  CLASS_TYPE_FORM,
  DELETE_ADMIN_EVENTS_CLASS,
  EXPORT_PARTICIPANT_CLASS_SCHEDULE,
  TENTAROO_AIR_APP, // 60

  // More in CMS
  RESOURCE_FORM,
  CONTACT_FORM,
  PAGE_MENU_ITEM_FORM,
  DELETE_MENU_ITEM,
  RESOURCE_CATEGORY_FORM, // 65
  GALLERY_IMAGE_FORM,

  // More in BlackoutDates
  DELETE_BLACKOUT_DATE,
  DELETE_FACILITY_TYPE,

  // Checkout
  DELETE_ITEM_OR_CART,
  RESET_PASSWORD,
  CONFIRM_SEND_RESET_LINK,
  CHANGE_PASSWORD,
  RESERVATION_OVERRIDE_FEE,

  DELETE_ORDER,
  EDIT_EMAIL,
  ACTIVATE_ACCOUNT,
  FACILITY_TYPE_CONFIRMATION,

  SELECT_MERIT_BADGE,
  MANAGE_REQUIREMENTS,
}

export type ManageRequirementsModalContext = "class-type" | "requirements-completed";

// TODO: We can replace this interface with types to really bind the props with
// ModalTypes
export interface ModalProps {
  // for all form modals in general
  id?: number;
  // for SelectGLaccount modal
  glAccountContext?: SelectGLAccountModalContext;
  // for FacilityTypeSharedSettings form
  facilityTypeFormSection?: FacilityTypeFormSection;
  // for ImportFromFacility modal
  importFromFacilityType?: ImportType;
  // for SelectLocation Modal
  context?: SelectLocationModalContext;
  isFacilities?: boolean;
  // for ReplaceParticipantType Modal
  participantType?: ParticipantType;
  participantTypeIndex?: number;
  // for EventTypeSharedSettings form
  eventTypeFormSection?: EventTypeFormSection;
  // for ImportFrom Event modal
  importFromEventType?: ImportFromEventType;
  // for MultipleGroups modal
  multipleGroupsContext?: MultipleGroupsContext;
  selectedEventId?: number;
  // for SelectClassType modal
  selectClassTypeModalType?: SelectClassTypeModalType;
  // for SendMessage modal
  messageSubject?: string;
  // for Newvente modal
  duplicateEventTypeId?: number;
  // for ExportInstructorRoster & EnterRequirement modals
  selectedClassId?: number;
  childClassId?: number;
  eventToolsModalContext?: EventToolsModalContext;
  // for ExportInstructorRoster modal AND ReportFinished modal
  reportURL?: string;
  // for GalleryImageForm
  target?: "top-gallery" | "bottom-gallery";
  // for DeleteItem
  to?: string;
  // CancelRegistration Related
  isYouth?: boolean;
  name?: string;
  participantID?: number;
  inCart?: -1 | 0 | 1; // Also for CancelReservation
  eventTypeID?: number;
  eventID?: number;
  groupWeekID?: number;
  goToRegistration?: boolean;
  IsNewlyAdding?: boolean;
  // for ResetPassword modal
  resetPasswordContext?: ResetPasswordContext;
  resetPasswordAccounts?: IResetPasswordAccount[];
  resetPasswordCode?: string;
  // for CancelReservation modal
  reservationID?: number;
  reservationName?: string;
  wasFinalized?: boolean;
  // for OverrideFeeModal
  numYouth?: number;
  numAdults?: number;
  individual?: boolean;
  // for DELETE_ITEM modal
  deletingItemID?: number;
  deletingTo?: string;
  // for ReservationOverrideFee modal
  reservation?: WizardReservation;
  facilityAvailabilitiesName?: string;
  accommodation?: string;
  tripPaymentStatus?: TripPaymentStatus | null;
  // for RegisterOverrideFee modal
  pTypeAmount?: number;
  pTypeAmountMin?: number;
  pTypeName?: string;
  spotsAvailableText?: string;
  maxParticipants?: number;
  quantity?: number;
  // for OverrideFee modal
  class?: RegisteredClass;
  // for RemoveItem modal
  title?: string;
  text?: string;
  buttonText?: string;
  removeItemContext?: "confirm-empty-cart" | "remove-item";
  // for OrderSubmitted modal
  receiptUrl?: string;
  // for DeletePerson modal (wizard)
  deletingPerson?: SimplePerson;
  // for PaymentFailed modal
  paymentErrorMessage?: string;
  // for ManageRequirements modal
  manageRequirementsContext?: ManageRequirementsModalContext;
  allRequirements?: MBRequirement[] | null;
  selectedRequirements?: number[] | null;
  participantId?: number;
}

export interface IModal {
  type: ModalTypes;
  saveBefore: boolean;
  saveAfter: boolean;
  props?: ModalProps;
  namespace?: string;
}

type CommonModalPrams<T extends boolean> = {
  modal: ModalTypes;
  saveBefore: boolean;
  saveAfter: boolean;
  transformToObservable: T;
};

type PushModalParams<T extends boolean> = CommonModalPrams<T> & {
  modalProps?: ModalProps;
};

type PopModalParams<T extends boolean> = CommonModalPrams<T>;

export function pushModalObservables<T extends boolean>(
  params: PushModalParams<T>
): T extends true ? Observable<Action>[] : Action[];
export function pushModalObservables<T extends boolean>(
  params: PushModalParams<T>
): Observable<Action>[] | Action[] {
  const { modal, saveBefore, saveAfter, modalProps } = params;
  const actions: Action[] = [];

  if (saveBefore) {
    actions.push(new SaveState());
  }

  actions.push(
    new PushModal(modal, saveBefore, saveAfter, undefined, modalProps)
  );

  // NOTE: same as in App/actions.ts, `saveAfter` is then handled in `<Modal />` component, dispatch in componentDidMount if set

  return params.transformToObservable
    ? actions.map((action) => Observable.of(action))
    : actions;
}

export function popModalObservables<T extends boolean>(
  params: PopModalParams<T>
): T extends true ? Observable<Action>[] : Action[];
export function popModalObservables<T extends boolean>(
  params: PushModalParams<T>
): Observable<Action>[] | Action[] {
  const { modal, saveBefore, saveAfter } = params;
  const actions: Action[] = [];

  if (saveBefore) {
    actions.push(new SaveState());
  }

  actions.push(new PopModal(modal, saveBefore, saveAfter));

  if (saveAfter) {
    actions.push(new SaveState());
  }

  return params.transformToObservable
    ? actions.map((action) => Observable.of(action))
    : actions;
}

export const noOpenedModals = (rootState = reduxStoreService().getState()) => {
  return rootState.app.openedModals.length === 0;
};

export const isModalOpened = (modalType: ModalTypes, rootState = reduxStoreService().getState()) => {
  if (rootState.app.openedModals) {
    const found = rootState.app.openedModals.find((m) => m.type === modalType);
    return !!found;
  } else {
    return false;
  }
};