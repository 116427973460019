import * as React from 'react';

import { Switch, ActionButton } from '../../../../Elements';
import { Filter, FilterRow, FilterColumn, FilterSearch } from '../../../../../components/Layouts';
import { FilterIcon } from '../../../../Icons';
import { isMobileAndSmallerScreenSize } from '../../../../../utils/isMobile';
import { resize } from '../../../../../utils';
import '../../../../../styles/pages/admin-events/events/home/index.scss';
import { Validator } from '../../../../../utils/validator/models';


export const namespace = (): string => 'elements--event-filters';

export interface EventFiltersProps {
  className?: string;
  disabled?: boolean;
  expand?: boolean;
  ActiveForm: any;
  ValidationRules: any;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
    toggleExpandFilter: (expand: boolean) => any,
  };
  newButton: React.ReactNode;
  forceMobile?: boolean;
  alwaysShowSearchFieldInMobile?: boolean;
}

interface State {
}

@resize()
class EventFilters extends React.Component<EventFiltersProps, State> {

  public props: EventFiltersProps;

  onExpandClick = () => {
    this.props.reduxActions.toggleExpandFilter(!this.props.expand);
  };

  public render() {
    const props = this.props;
    const {ActiveForm, alwaysShowSearchFieldInMobile, forceMobile, ValidationRules, reduxActions, disabled, expand, newButton} = props;

    return (
      <Filter className={namespace()}>
        <FilterRow
          className={`${namespace()}--row1`}
          style={{height: '68px'}}
          mobileMarginBottom={0}
          forceMobile={forceMobile}
        >
          <FilterSearch
            marginRight={0}
            mobileMarginRight={8}
            disabled={disabled}
            ActiveForm={ActiveForm}
            searchKey='FilterText'
            ValidationRules={ValidationRules}
            alwaysShowSearchFieldInMobile={!!forceMobile}
            reduxActions={reduxActions}
            forceMobile={forceMobile}
            width='half'
          />
          <FilterColumn
            hideInMobile
            className={`${namespace()}--placeholder-column`}
            forceMobile={forceMobile}
            width='half'
          />
          <FilterColumn
            className={`${namespace()}--filter-btn`}
            marginRight={24}
            mobileMarginRight={0}
            marginBottom={21}
            forceMobile={forceMobile}
            width='none'
            alignSelf='flex-end'
            keep
          >
            <ActionButton
              lightSelected
              square
              onClick={() => this.onExpandClick()}
              icon={FilterIcon}
              selected={expand} />
          </FilterColumn>
          <FilterColumn alignSelf='flex-end' marginBottom={21} forceMobile={forceMobile} hideInMobile width='none'>
            {newButton}
          </FilterColumn>
        </FilterRow>
        {(forceMobile ? expand : (expand || !isMobileAndSmallerScreenSize())) ? <FilterRow wrapInMobile isExpanded forceMobile={forceMobile}>
          <FilterColumn
            mobileMarginRight={0}
            forceMobile={forceMobile}
            marginRight={24}
            width='half'
          >
            <Switch
              label="Show Events From Past Years"
              multilineLabel
              newDesign
              onChange={reduxActions.updateValue}
              value={!!ActiveForm.ShowEventsFromPastYears}
              validationRules={ValidationRules.ShowEventsFromPastYears}
            />
          </FilterColumn>
          <FilterColumn
            mobileMarginRight={0}
            forceMobile={forceMobile}
            marginRight={0}
            width='half'
          >
            <Switch
              label="Show Deleted"
              multilineLabel
              newDesign
              onChange={reduxActions.updateValue}
              value={!!ActiveForm.ShowDeleted}
              validationRules={ValidationRules.ShowDeleted}
            />
          </FilterColumn>
        </FilterRow> : null}
      </Filter>
    );
  }
}

export default EventFilters;