
import { CLEAR_CACHE_PREFIX, createRequestActions } from '../../Validation/actionCreator';
import { ActionCreator } from '../..';
import { GetFacilityLocationCacheBodyParams } from '../../../constants/urls';
import { HideDrawer, SilentCancelAllAction, StartProgress } from '../../../store/App/actions';
import { SaveState } from '../../Rollback/actions';
import { typeName, Action } from '../../../utils/StrongActions';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../../utils/suffix';

export const UPDATE_ADMIN_FACILITIES_SETTINGS_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__ADMIN_FACILITIES_SETTINGS_FORM';
export const SUFFIX = '__CACHE_ONE';
export const GET_BLACKOUT_DATES_SUFFIX = 'GET_BLACKOUT_DATES__' + SUFFIX;
export const SUFFIX_SAVE = '__CACHE_ONE_SAVING';
// Any successful request action wanted to be handled in cacheOne should include this suffix
export const ADMIN_FACILITY_MODULE_SUFFIX = '__$ADMIN_FACILITY_CACHE_ONE__HANDLE$';

export const GetFacilityLocationCache = createRequestActions(SUFFIX + ADMIN_FACILITY_MODULE_SUFFIX + UPDATE_ADMIN_FACILITIES_SETTINGS_FORM_SUFFIX);
export const GetAvailabilitiesCache = createRequestActions(SUFFIX + ADMIN_FACILITY_MODULE_SUFFIX + '__GET_AVAILABILITIES');

@typeName(`${CLEAR_CACHE_PREFIX}_ADMIN_FACILITY_LOCATION_CACHE_BELOW_ONE`) export class ClearAdminFacilityLocationCacheBelowOne extends Action {}

@typeName(`${CLEAR_CACHE_PREFIX}_ALL_ADMIN_FACILITY_LOCATION_CACHE`) export class ClearAllAdminFacilityLocationCache extends Action {
  constructor() {
    super();
  }
}

export type Actions = typeof actionCreators;
  
export const actionCreators = {
  getFacilityLocationCache: (params: GetFacilityLocationCacheBodyParams, isRefreshingCacheOneForm: boolean, useProgress?: boolean, saveBefore?: boolean): ActionCreator => dispatch => {
    if (saveBefore) dispatch(new SaveState());
    if (useProgress) dispatch(new StartProgress());
    dispatch(GetFacilityLocationCache.request(params,{skipUpdateForm: !isRefreshingCacheOneForm}));
  },
  getAvailabilitiesCache: (params: GetFacilityLocationCacheBodyParams, extra: any): ActionCreator => dispatch => {
    dispatch(GetAvailabilitiesCache.request(params, extra));
  },
  clearAdminFacilityLocationCacheBelowOne: (): ActionCreator => dispatch => dispatch(new ClearAdminFacilityLocationCacheBelowOne()),
  clearAllAdminFacilityLocationCache: (): ActionCreator => dispatch => dispatch(new ClearAllAdminFacilityLocationCache()),
  refresh: (params: GetFacilityLocationCacheBodyParams, isRefreshingCacheOneForm: boolean): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    // When `!isRefreshingCacheOneForm`, we should skip update cache one form, by setting `skipUpdateForm` to true
    dispatch(GetFacilityLocationCache.request(params, {skipUpdateForm: !isRefreshingCacheOneForm}));
  },
};