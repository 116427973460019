import {getPrevOrderBody, getPrevOrderUrl} from '../../constants/urls';
import {makeRequestEpic} from "../Validation/epicCreator";
import {GetPrevOrder} from "./actions";

export const cacheThreePrevOrdersEpic = makeRequestEpic(
  GetPrevOrder,
  getPrevOrderUrl,
  getPrevOrderBody,
  true
);
