import {DEL_SUFFIX, SUFFIX, AddTripSubmitActions, TripResetForm, TripInitialize} from './actions';
import {FormDefinition, IValidator} from './validation';
import {apiValidationReducerCreator} from "../../../Validation/reducerCreator";
import {GetFacilityTripData} from "../../../CacheThreeFacilities/actions";
import {APISuccess, WithRootState} from "../../../Validation/actionCreator";
import {convertObjToMoment} from "../../../../utils";
import { ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction } from '../../../App/actions';
import { ClearCacheBelowTwoFacilities } from '../../../CacheTwoFacilities/actions';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../utils/StrongActions';
import { ClearCacheBelowOne } from '../../../CacheOne/actions';

export interface TripActiveForm {
  Name?: string;
  ContactName?: string;
  ContactPhone?: string;
  ContactEmail?: string;
  ContactName2?: string;
  ContactPhone2?: string;
  ContactEmail2?: string;
  NumAdults?: number;
  NumYouth?: number;
  GroupNotes?: string;
  AdminNotes?: string;
  StartDateTime?: any;
  EndDateTime?: any;
  SumPeople?: number;
}

export interface TripFormState {
  ActiveForm: TripActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
}

const getInitialState = () => {
  return {
    ActiveForm: {
      NumAdults: 0,
      NumYouth: 0
    },
    ValidationRules: { ...FormDefinition }
  };
};

const determineInlineError = (errors: any) => {
  let message = '';
  if (errors) {
    if (errors.DateRange) message = errors.DateRange[0];
    else if (errors.SumPeople) message = errors.SumPeople[0];
  }
  return message;
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, determineInlineError);
const deleteCheckApiValidation = apiValidationReducerCreator(DEL_SUFFIX, determineInlineError);

const TripForm: Reducer<TripFormState> = (oldState, act: WithRootState<AnyAction>) => {
  const s = deleteCheckApiValidation(oldState, act);
  const state = checkApiValidation(s, act);

  if (act.type === GetFacilityTripData.successType || (isActionType(act, SetCacheAction) && act.actions.requestType === AddTripSubmitActions.requestType)) {
    const successAction = act as WithRootState<APISuccess>;
    const newState = getInitialState();
    const tripData = convertObjToMoment(successAction.response.response.FacilityTrip, ['StartDateTime', 'EndDateTime']);
    newState.ActiveForm = {
      ...newState.ActiveForm,
      ...tripData
    };

    return newState;
  } else if (isActionType(act, TripResetForm) || isActionType(act, TripInitialize)) {
    const newState = getInitialState();
    const FacilityTrip = act.rootState.cacheThreeFacilities.FacilityTrip;
    if (FacilityTrip) {
      const tripData = convertObjToMoment(FacilityTrip, ['StartDateTime', 'EndDateTime']);
      newState.ActiveForm = {
        ...newState.ActiveForm,
        ...tripData
      };
    }
    return newState;
  } else if (
    isActionType(act, ClearCacheBelowTwoFacilities) ||
    isActionType(act, ClearAllCache) ||
    isActionType(act, ClearAllEndUserCacheButOptions) ||
    isActionType(act, ClearCacheBelowOne)
  ) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default TripForm;
