import type {ActionCreator, ApplicationState} from '../';
import {createApiSubmitActions, createRequestActions} from '../Validation/actionCreator';
import {
  E_REGISTER,
  EVENT,
  eventNumbersInitBody,
  eventNumbersRecalcBody,
  eventNumbersSaveBody,
  NumbersLoadParams,
  NumbersRecalcParams,
  NumbersSaveParams
} from "../../constants/urls";
import {
  EventRegistrationCampsiteAssignment,
  NumberProposed,
  PostEventRegistrationClass,
  RegistrationFields
} from "../../models/api/cacheFourEvents";
import {RegisteredClass} from "../../models/class";
import {SaveState} from "../Rollback/actions";
import {push} from "react-router-redux";
import {validateAll} from "../../utils/validator";
import {ApiSubmitActionsNumbersSpots} from "../Events/Event/Register/Numbers/Spots/actions";
import {EventRegisterNumbersClassesShowError} from "../Events/Event/Register/Numbers/Classes/actions";
import {ApiSubmitNumbersConfirmtationActions} from "../Events/Event/Register/Numbers/Confirmation/actions";
import {ApiSubmitActionsNumbersCampsite} from "../Events/Event/Register/Numbers/Campsite/actions";
import {NumbersErrors, validateAllNumbersPages} from "./validationMaster";
import {getTotalAmount} from '../../utils/helpers/financialSummaryHelper';
import {OrderedProduct, ProductUpdate, RecalcProduct} from "../../models/product";
import { getParticipantTypeKey } from '../Events/Event/Register/Numbers/Spots/validation';
import { spotsOnlySelector } from '../../utils/eventsHelper';
import { captureTentarooError, FormCreater } from '../../utils/dataHelper';
import { EventRegistrationNumbersKeys } from '../Events/Event/Register/Numbers/Spots';
import { formatDate } from '../../utils/dateHelper';

export const SUFFIX = '__CACHE_FOUR_EVENTS_NUMBERS';
export const EventNumbersCalc = createRequestActions(SUFFIX);
export const SUFFIX_SAVE = '__CACHE_FOUR_EVENTS_NUMBERS_SAVE';
export const EventNumbersSave = createApiSubmitActions(SUFFIX_SAVE);


export type Actions = typeof actionCreators;

export const composeClassRegistrations = (EventClassesGroupRegistered: Array<RegisteredClass>) => {
  const classRegistrations: Array<PostEventRegistrationClass> = [];

  if (EventClassesGroupRegistered) {
    EventClassesGroupRegistered.forEach((c: RegisteredClass) => {
      classRegistrations.push({
        IDi: c.IDi,
        OldIDi: c.OldIDi,
        ClassIDi: c.ClassIDi,
        ClassTypeIDi: c.ClassTypeIDi,
        Inactive: !!c.Inactive,
        Amount: c.Amount,
        AmountMin: c.AmountMin,
        AmountChangeInCart: c.AmountChangeInCart ? c.AmountChangeInCart : 0,
        NumOverride: c.NumOverride,
        RegistrationRank: c.RegistrationRank,
        IsUnscheduled: c.IsUnscheduled
      });
    });
  }
  return classRegistrations;
};

export const composeCampsiteAssignments = (rootState: ApplicationState) => {
  const campsiteState = rootState.events.event.register.numbers.campsite;

  const campsiteAssignments: Array<EventRegistrationCampsiteAssignment> = [];

  campsiteState.EventRegistrationCampsiteAssignments
  .forEach((assignmentForm) => {
    campsiteAssignments.push({
      ID: assignmentForm.ActiveForm.ID,
      CampsiteIDi: assignmentForm.ActiveForm.CampsiteIDi,
      NumAdults: !!assignmentForm.ActiveForm.Inactive || !assignmentForm.ActiveForm.CampsiteIDi ? null as any : assignmentForm.ActiveForm.NumAdults,
      NumYouth: !!assignmentForm.ActiveForm.Inactive || !assignmentForm.ActiveForm.CampsiteIDi ? null as any : assignmentForm.ActiveForm.NumYouth,
      Inactive: !!assignmentForm.ActiveForm.Inactive,
    });
  });

  return campsiteAssignments;
};

export const composeNumbersProposed = (rootState: ApplicationState) => {
  const SpotsOnly = spotsOnlySelector(rootState);
  const spotState = rootState.events.event.register.numbers.spots;
  const c4 = rootState.cacheFourEventsNumbers;
  const numbersProposed: Array<NumberProposed> = [];

  if (SpotsOnly) {
    spotState.EventRegistrationParticipantTypes.forEach((pTypeForm) => {
      const pType = c4.EventRegistrationParticipantTypes ? c4.EventRegistrationParticipantTypes.find(pt => pt.ID === pTypeForm.ActiveForm.ID) : null;

      if (pType) {
        const numProposed = {
          ID: pType.ID,
          EstimateTotalChange: pTypeForm.ActiveForm[getParticipantTypeKey(pType)] - pType.EstimateTotalSaved
        };
        numbersProposed.push(numProposed);
      }
    });
  } else {
    spotState.EventRegistrationSpots
      .filter((spotForm) => !spotForm.ActiveForm.Inactive && !!spotForm.ActiveForm.NumSpots)
      .forEach((spotForm) => {
      const pType = c4.EventRegistrationParticipantTypes ? c4.EventRegistrationParticipantTypes.find(pt => pt.ID === spotForm.ActiveForm.PTID) : null;

      if (pType) {
        const numProposed = {
          PTID: pType.ID,
          NumSpots: spotForm.ActiveForm.NumSpots || null,
          Amount: spotForm.ActiveForm.Amount,
        };
        numbersProposed.push(numProposed);
      }
    });
  }

  return numbersProposed;
};

export const composeOrderedProducts = (
  orderedProducts: Array<OrderedProduct> | null | undefined,
  updateProducts: Array<ProductUpdate> | null | undefined
): Array<RecalcProduct> => {
  const products: Array<any> = [];
  if (orderedProducts) {
    orderedProducts.forEach((orderedProduct) => {

      let updatedProduct;
      if (updateProducts) {
        updatedProduct = updateProducts.find(pro => pro.ID === orderedProduct.ID);
      }

      products.push({
        ID: orderedProduct.ID,
        ProductIDi: orderedProduct.ProductIDi,
        // InCart: orderedProduct.InCart,
        Amount: orderedProduct.Amount,
        Quantity: orderedProduct.Quantity,
        Inactive: orderedProduct.Inactive,
        AmountChangeInCart: updatedProduct ? updatedProduct.AmountChangeInCart : 0
      });
    });
  }
  return products;
};

export const actionCreators = {
  numbersLoad: (params: NumbersLoadParams): ActionCreator => dispatch => {
    dispatch(EventNumbersCalc.request(eventNumbersInitBody(params)));
  },
  numbersRecalc: (params: NumbersRecalcParams): ActionCreator => dispatch => {
    dispatch(EventNumbersCalc.request(eventNumbersRecalcBody(params)));
  },
  numbersSave: (): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const c3 = getState().cacheThreeEvents;
    const c4 = getState().cacheFourEventsNumbers;
    const confirmationValidation = validateAll(s => s.events.event.register.numbers.confirmation);

    if (confirmationValidation) {
      dispatch(ApiSubmitNumbersConfirmtationActions.clientFailure(confirmationValidation, false));
      return;
    }
    const err = validateAllNumbersPages();

    if (err.spots) {
      dispatch(ApiSubmitActionsNumbersSpots.clientFailure(err.spots, false));
      return;
    }

    if (err.classes?.error) {
      dispatch(new EventRegisterNumbersClassesShowError(err.classes.warnings[0].message, err.classes.error));
      return;
    }

    if (err.campsite) {
      dispatch(ApiSubmitActionsNumbersCampsite.clientFailure(err.campsite, false));
      return;
    }

    // @todo: I should validate once more before save? I shouldn't need to really
    // But I at least need to check the old validations to see if there's any "warnings" that should be blocking saves
    const rootState = getState();
    const options = rootState.cacheZero.options;
    const eventTypeID = rootState.cacheTwoEvents.eventTypeID;
    const eventID = rootState.cacheThreeEvents.eventID;
    const numbersProposed: Array<NumberProposed> = composeNumbersProposed(getState());
    const classRegistrations: Array<PostEventRegistrationClass> = composeClassRegistrations(c4.EventClassesGroupRegistered);
    const CampsiteAssignments = composeCampsiteAssignments(getState());

    if (!options) {
      captureTentarooError(new Error("options not available when performing numbersSave"));
      return;
    }
    if (!c4.EventRegistrationPaymentStatus) {
      captureTentarooError(new Error("c4.EventRegistrationPaymentStatus not available when performing numbersSave"));
      return;
    }
    if (!c4.EventRegistrationNumbers) {
      captureTentarooError(new Error("c4.EventRegistrationNumbers not available when performing numbersSave"));
      return;
    }

    if (!options.Group) {
      captureTentarooError(new Error("Group is not available when performing numbersSave"));
      return;
    }
    const products = composeOrderedProducts(c4.EventProductsOrdered, c4.EventProductsOrdered_Updates);

    const spotsActiveForm = rootState.events.event.register.numbers.spots.ActiveForm;
    const spotsValidationRules = rootState.events.event.register.numbers.spots.ValidationRules;
    const campsiteActiveForm = rootState.events.event.register.numbers.campsite.ActiveForm;
    const registrationFields: RegistrationFields = {
      ...FormCreater(spotsActiveForm, EventRegistrationNumbersKeys, spotsValidationRules),
      IsCampingOvernight: spotsActiveForm.CampingOvernight !== undefined ? spotsActiveForm.CampingOvernight : -1,
      CampsiteRank1IDi: campsiteActiveForm.CampsiteRank1,
      CampsiteRank2IDi: campsiteActiveForm.CampsiteRank2,
      CampsiteRank3IDi: campsiteActiveForm.CampsiteRank3,
      DateRegistration: spotsActiveForm.DateRegistration ? formatDate(spotsActiveForm.DateRegistration) : undefined,
    };

    let newAmount: undefined | number = getTotalAmount(
      rootState.events.event.register.numbers.confirmation.ActiveForm,
      c4.EventRegistrationPaymentStatus
    );

    const params: NumbersSaveParams = {
      GroupIDi: options.Group.IDi,
      GroupTS: options.Group.TS,
      EventTypeID: eventTypeID,
      EventIDi: eventID,
      GroupWeekIDi: c4.EventRegistrationNumbers.IDi,
      NumbersProposed: numbersProposed,
      CampsiteAssignments,
      ClassRegistrations: classRegistrations,
      GroupWeekTS: c4.EventRegistrationNumbers.GroupWeekTS,
      AmountChangeInCart: c4.EventRegistrationPaymentStatus.AmountChangeInCart,
      DesiredPaymentInCart: newAmount,
      RegistrationFields: registrationFields,
      ProductOrders: products,
      ParticipantTypes: !c3.EventInfoParticipantTypes ? [] : c3.EventInfoParticipantTypes.map((pType) => pType.ID),
    };
    if (newAmount !== undefined) {
      params.TotalAmountChange = newAmount - c4.EventRegistrationPaymentStatus.AmountInCart;
    }

    const route = getState().routing.locationBeforeTransitions;
    const body = eventNumbersSaveBody(params);
    const newPath = route.pathname.replace(`${EVENT.REGISTER}/${E_REGISTER.NUMBERS}`, EVENT.REGISTRATION);
    // This navigation will trigger `clearCacheBelowThreeEvents` in Register/Numbers/index.tsx -> componentWillUnmount,
    // which will then trigger SavePoint during save
    dispatch(push(`${newPath}${route.search}`));
    dispatch(EventNumbersSave.request(body, null));
  }
};
