import {FormDefinition} from './validation';
import {DELETE_SUFFIX, InitializeAddDefaultValues, Reset, SUFFIX} from './actions';
import {apiValidationReducerCreator} from '../../../Validation/reducerCreator';
import {APISuccess, WithRootState} from "../../../Validation/actionCreator";
import moment from 'moment';
import {GetGroupRosterPerson} from "../../../CacheThreeRoster/actions";
import {ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction} from '../../../App/actions';
import {FullGroupRosterPerson} from '../../../../models/api/cacheTwoRoster';
import {PersonFormActiveForm, PersonFormValidatior} from '../../../../utils/personFormValidationRules';
import { setDefaults } from '../../../../utils/validator';
import { ClearCacheBelowTwoRoster } from '../../../CacheTwoRoster/actions';
import { isActionType } from '../../../../utils/StrongActions';
import { AnyAction, Reducer } from 'redux';
import { ClearCacheBelowOne } from '../../../CacheOne/actions';

export interface RosterAddState {
  ActiveForm: PersonFormActiveForm;
  ValidationRules: PersonFormValidatior;
  SubmitErrorMessage?: string;
}

const getInitialState: () => RosterAddState = () => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition},
});


const checkApiValidation = apiValidationReducerCreator(SUFFIX);
const checkApiValidationDelete = apiValidationReducerCreator(DELETE_SUFFIX);

const RoasterAdd: Reducer<RosterAddState> = (oldState: RosterAddState = getInitialState(), act: WithRootState<AnyAction>) => {
  const s = checkApiValidation(oldState, act);
  const state = checkApiValidationDelete(s, act);
  if (act.type === GetGroupRosterPerson.successType || isActionType(act, SetCacheAction)) {
    let p;
    if (isActionType(act, SetCacheAction)) {
      p = act.response.xhr.response.GroupRosterPerson;
      if (!p) return state;
    } else {
      const action = <WithRootState<APISuccess>> act;
      p = action.response.response.GroupRosterPerson;
    }
    const person = <FullGroupRosterPerson> p;
    const {DOB, BSAID, DateBalooTraining, DateBasicTrainingForPosition, DateYouthProtectionTraining} = person;
    const newState = {
      ...state,
      ActiveForm: {
        ...person,
        DOB: DOB ? moment(DOB) : undefined,
        BSAID: BSAID !== null ? BSAID + '' : undefined
      }
    };
    if (DateBalooTraining) {
      newState.ActiveForm.DateBalooTraining = DateBalooTraining ? moment(DateBalooTraining) : undefined;
    }
    if (DateBasicTrainingForPosition) {
      newState.ActiveForm.DateBasicTrainingForPosition = DateBasicTrainingForPosition ? moment(DateBasicTrainingForPosition) : undefined;
    }
    if (DateYouthProtectionTraining) {
      newState.ActiveForm.DateYouthProtectionTraining = DateYouthProtectionTraining ? moment(DateYouthProtectionTraining) : undefined;
    }
    return newState;
  } else if (isActionType(act, InitializeAddDefaultValues)) {
    const newState = {...state, ValidationRules: {...state.ValidationRules}, ActiveForm: {...state.ActiveForm} };
    return setDefaults(act.rootState, newState, state);
  } else if (
    isActionType(act, Reset) ||
    isActionType(act, ClearCacheBelowTwoRoster) ||
    isActionType(act, ClearAllCache) ||
    isActionType(act, ClearAllEndUserCacheButOptions) ||
    isActionType(act, ClearCacheBelowOne)
  ) {
    return {
      ActiveForm: {},
      ValidationRules: { ...FormDefinition }
    };
  }
  return state;
};

export default RoasterAdd;
