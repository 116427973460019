import * as React from 'react';
import '../../../../styles/elements/year-overview/card.scss';
import {mergeClassNames} from '@tentaroo/shared';
import { AdminEventsYearOverview, YearOverviewByParticipantType, YearOverviewByCouncil, YearOverviewByDistrict, YearOverviewByEvent } from '../../../../models/api/adminEventsCacheOne';
import Card, { CardCategory } from '../../Card';
import { Row, Column } from '../../Grid';
import { YearOverViewType } from '../../../../store/AdminEvents/Reports/YearOverview/validation';
import { RegistrationMethodType } from '../../../../store/AdminEvents/CacheOne';
import {isJustNames} from '../../../../store/AdminEvents/EventTypes/EventType/Form/uiHelpers';

export const namespace = (): string => 'elements--year-overview-card';

export interface YearOverviewProps {
  className?: string;
  overview: AdminEventsYearOverview | YearOverviewByParticipantType;
  template?: 'mobile-stretch' | 'mobile-no-margin' | 'info' | 'mobile-full';
  type: YearOverViewType;
  RegistrationMethodID: RegistrationMethodType;
}

class YearOverviewCard extends React.PureComponent<YearOverviewProps, {}> {
  public props: YearOverviewProps;

  getTitle = () => {
    const {type, overview} = this.props;
    switch (type) {
      case YearOverViewType.BY_COUNCIL:
        return (overview as YearOverviewByCouncil).CouncilType;
      case YearOverViewType.BY_DISTRICT:
        return (overview as YearOverviewByDistrict).District;
      case YearOverViewType.BY_EVENT:
        return (overview as YearOverviewByEvent).EventName;
      case YearOverViewType.BY_PARTICIPANT:
        return (overview as YearOverviewByParticipantType).ParticipantType;
    }
  };

  renderContent = () => {
    const {overview} = this.props;

    const o = overview as AdminEventsYearOverview;

    const NamesFinalized = o.YouthNamesFinalized + o.AdultsNamesFinalized;
    const NamesInCart = o.AdultsNamesInCart + o.YouthNamesInCart;
    const NumbersFinalized = o.YouthNumbersFinalized + o.AdultsNumbersFinalized;

    return (
      <Row className={`${namespace()}--row`}>
        <Column span={3} mobileSpan={6} className={`${namespace()}--column mobile-order-1`}>
          <div className={`${namespace()}--detail--title`}>Groups</div>
          <div className={`${namespace()}--detail--content`}>{o.NumGroups}</div>
        </Column>
        <Column span={3} mobileSpan={6} className={`${namespace()}--column mobile-order-3`}>
          <div className={`${namespace()}--detail--title`}>{isJustNames() ? 'Finalized' : 'Numbers'}</div>
          <div className={`${namespace()}--detail--content`}>{isJustNames() ? NamesFinalized : NumbersFinalized}</div>
          <div className={`${namespace()}--detail--extra`}>{isJustNames() ? `${o.YouthNamesFinalized} Youth` : `${o.YouthNumbersFinalized} Youth`}</div>
          <div className={`${namespace()}--detail--extra`}>{isJustNames() ? `${o.AdultsNamesFinalized} Adult${o.AdultsNamesFinalized !== 1 ? 's' : ''}` : `${o.AdultsNumbersFinalized} Adult${o.AdultsNumbersFinalized !== 1 ? 's' : ''}`}</div>
        </Column>
        <Column span={3} mobileSpan={6} className={`${namespace()}--column mobile-order-4`}>
          <div className={`${namespace()}--detail--title`}>{isJustNames() ? 'In-Cart' : 'Names'}</div>
          <div className={`${namespace()}--detail--content`}>{isJustNames() ? NamesInCart : NamesFinalized}</div>
          <div className={`${namespace()}--detail--extra`}>{`${isJustNames() ? o.YouthNamesInCart : o.YouthNamesFinalized} Youth`}</div>
          <div className={`${namespace()}--detail--extra`}>{`${isJustNames() ? o.AdultsNamesInCart : o.AdultsNamesFinalized} Adult${(isJustNames() ? o.AdultsNamesInCart !== 1 : o.AdultsNamesFinalized !== 1) ? 's' : ''}`}</div>
        </Column>
        <Column span={3} mobileSpan={6} className={`${namespace()}--column mobile-order-2`}>
          <div className={`${namespace()}--detail--title`}>Total</div>
          <div className={`${namespace()}--detail--content`}>{`$${o.TotalFees.toFixed(2)}`}</div>
          <div className={`${namespace()}--detail--extra`}>{`$${o.TotalAmountPaid.toFixed(2)} Paid`}</div>
        </Column>
      </Row>
    );
  };

  renderContentByParticipant = () => {
    const {overview} = this.props;

    const o = overview as YearOverviewByParticipantType;
    return (
      <Row className={`${namespace()}--row`}>
        <Column span={3} mobileSpan={6} className={`${namespace()}--column mobile-order-3`}>
          <div className={`${namespace()}--detail--title`}>Overall Total</div>
          <div className={`${namespace()}--detail--content`}>{(o.NumParticipantsInCouncil + o.NumParticipantsNonUnitGroups + o.NumParticipantsOutOfCouncil)}</div>
        </Column>
        <Column span={3} mobileSpan={6} className={`${namespace()}--column mobile-order-1`}>
          <div className={`${namespace()}--detail--title`}>In-Council</div>
          <div className={`${namespace()}--detail--content`}>{o.NumParticipantsInCouncil}</div>
        </Column>
        <Column span={3} mobileSpan={6} className={`${namespace()}--column mobile-order-2`}>
          <div className={`${namespace()}--detail--title`}>Out of Council</div>
          <div className={`${namespace()}--detail--content`}>{o.NumParticipantsOutOfCouncil}</div>
        </Column>
        <Column span={3} mobileSpan={6} className={`${namespace()}--column mobile-order-4`}>
          <div className={`${namespace()}--detail--title`}>Non-Unit Groups</div>
          <div className={`${namespace()}--detail--content`}>{o.NumParticipantsNonUnitGroups}</div>
        </Column>
      </Row>
    );
  };

  public render() {
    const { template, type, ...props } = this.props;

    let className = mergeClassNames(namespace(), props);


    return (
      <Card
        className={className}
        template={template || 'mobile-no-margin'}
        component={<section/>}
        padding='none'
      >
        <Row marginBottom={16}>
          <Column span={12}>
            <div className={`${namespace()}--title`}>{this.getTitle()}</div>
          </Column>
        </Row>
        {type === YearOverViewType.BY_COUNCIL ? this.renderContent() : null }
        {type === YearOverViewType.BY_DISTRICT ? this.renderContent() : null }
        {type === YearOverViewType.BY_EVENT ? this.renderContent() : null }
        {type === YearOverViewType.BY_PARTICIPANT ? this.renderContentByParticipant() : null }
      </Card>
    );
  }
}

export default YearOverviewCard;
