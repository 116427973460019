import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';
import { namespace as ns } from './constants';
import '../../../styles/elements/camp-info/image.scss';

export const namespace = (): string => `${ns()}--image`;

export interface ImageProps {
  className?: string;
  imageUrl: string;
}

class Image extends React.Component<ImageProps, {}> {

  public refs: {
    imgNode: any;
  };

  onLoad = () => {
    if (this.refs.imgNode) {
      if (this.refs.imgNode.parentElement && this.refs.imgNode.clientHeight > this.refs.imgNode.parentElement.clientHeight) {
        
        // In CampInfo section, we locked the parent of this image component to a certain ratio (set it to be 
        // the maximum height). And from issue#691, if the actual image size is taller than this maximum height,
        // we want it to be centered vertically. The following block achieve this.
        this.refs.imgNode.style.transform = "translateY(-25%)";
      } else {
        this.refs.imgNode.style.transform = "";
      }
    }
  };

  render() {
    return (
      <div className="imageWrapper">
        <img onLoad={this.onLoad} ref="imgNode" src={this.props.imageUrl} className={mergeClassNames(namespace(), this.props)}/>
      </div>
    );
  }
}

export default Image;
