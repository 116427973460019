import {makeRequestEpic} from "../../../Validation/epicCreator";
import {SubmitManageOrderActions} from "./actions";
import {getUpdateOrderUrl, URLS} from "../../../../constants/urls";
import { AjaxResponse } from "rxjs/Rx";
import { reduxStoreService } from "../../../service";

export const manageOrderSaveEpic = makeRequestEpic(
  SubmitManageOrderActions,
  getUpdateOrderUrl,
  undefined,
  true,
  () => `Order has been saved.`,
  (res?: AjaxResponse) => {
    let result;
    const qp = reduxStoreService().getState().routing.locationBeforeTransitions.search;
    // When the request is successful, navigate to the corresponding order's page
    // If anything wrong in the ajax response, fallback to order history page, in this case the successMessage
    // `Order has been saved` would still be shown.
    if (res && res.response && res.response.PreviousOrder && res.response.PreviousOrder.OrderID) {
      result = `${URLS.VIEW_ORDER_ROOT}/${res.response.PreviousOrder.OrderID}${qp}`;
    } else {
      result = `${URLS.ORDER_HISTORY}${qp}`;
    }
    return result;
  },
);
