import * as React from 'react';
import { findDOMNode } from 'react-dom';
import moment from 'moment';
import Month from './index';
import '../../../../styles/elements/month/standard/range.scss';
import ActionButton from "../../ActionButton/index";
import { BackArrowIcon, NextArrowIcon } from '../../../Icons';
import { easing } from '../../../../utils';
import {Availability, FacilitiesAvailabilitiesDate} from "../../../../models/api/cacheTwoFacilties";
import {run} from "domain-task";
import {Nullable} from '../../../../utils/dataHelper';

const namespace = (): string => 'elements--month-range';

export interface Props {
  start: string | moment.Moment;
  end: string | moment.Moment;
  availabilities?: Array<Availability>;
  availabilityDates?: Array<FacilitiesAvailabilitiesDate>;
  showAdminBadge?: boolean;
}

class Range extends React.PureComponent<Props, {}> {
  private get monthWidth() {
    const element = findDOMNode(this.refs.month) as Nullable<HTMLDivElement>;
    return element?.getBoundingClientRect().width ?? 0;
  }

  public handlePrevClick = () => {
    const element = findDOMNode(this.refs.scrollable) as Nullable<HTMLDivElement>;

    if (!element) return;

    const current = Math.ceil(element.scrollLeft / this.monthWidth);
    let next;
    if (current <= 0) return;
    else if (current === 1) next = 0;
    else next = current - 2;
    this.scrollTo(element, next * this.monthWidth);
  };

  public handleNextClick = () => {
    const element = findDOMNode(this.refs.scrollable) as Nullable<HTMLDivElement>;

    if (!element) return;
    
    const current = Math.ceil(element.scrollLeft / this.monthWidth);
    let next;
    if (current >= 24) return;
    else if (current === 23) next = 24;
    else next = current + 2;
    this.scrollTo(element, next * this.monthWidth);
  };

  public scrollTo = (target: HTMLDivElement, position: number) => {
    window.requestAnimationFrame(
      (time) => this.animateScrollFrame(
        target,
        target.scrollLeft,
        position,
        time
      )
    );
  };

  public animateScrollFrame = (
    target: HTMLDivElement,
    startPosition: number,
    endPosition: number,
    currentTime: number,
    startTime: number | null = null,
    duration: number = 400
  ) => {
    if (!startTime) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const nextValue = easing.easeInOutQuad(timeElapsed, startPosition, endPosition - startPosition, duration);
    target.scrollLeft = nextValue;
    if (timeElapsed < duration) {
      window.requestAnimationFrame(
        (time) => this.animateScrollFrame(
          target,
          startPosition,
          endPosition,
          time,
          startTime,
          duration
        )
      );
      return;
    }
    target.scrollLeft = endPosition;
  };

  public render() {
    const { start, end, availabilities, availabilityDates } = this.props;
    let startMoment: moment.Moment, endMoment: moment.Moment;
    if (typeof start === 'string') startMoment = moment(start);
    else startMoment = start;
    if (typeof end === 'string') endMoment = moment(end);
    else endMoment = end;
    const numMonths = endMoment.diff(startMoment, 'months');
    let offsets: Array<number> = [0];
    let runningOffset = 0;
    const countMoment = moment(startMoment);
    for (let i = 0; i < numMonths; i++) {
      runningOffset = runningOffset + countMoment.daysInMonth();
      offsets.push(runningOffset);
      countMoment.add(1, 'months');
    }

    return (
      <div className={namespace()}>
        <ActionButton
          icon={BackArrowIcon}
          shadow={true}
          background="white"
          className={`${namespace()}--action prev`}
          onClick={this.handlePrevClick}
        />
        <div className={`${namespace()}--scrollable`} ref="scrollable">
          {Array.apply(null, Array(numMonths)).map((value, index) => (
            <div key={index} className={`${namespace()}--scrollable--month`} ref={index === 0 ? 'month' : undefined}>
              <Month
                month={moment(startMoment).startOf('month').add(index, 'months')}
                availabilities={availabilities}
                offset={offsets[index]}
                availabilityDates={availabilityDates!}
              />
            </div>
          ))}
        </div>
        <ActionButton
          icon={NextArrowIcon}
          shadow={true}
          background="white"
          className={`${namespace()}--action next`}
          onClick={this.handleNextClick}
        />
      </div>
    );
  }
}

export default Range;
