import * as React from 'react';
import { CMSPageGalleryImage } from '../../../models/api/adminCMSCacheTwoPage';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { Image, Loader } from '..';
import GalleryImageContextMenuComponent from './GalleryImageContextMenu';
import { getImagePath, shallowEqual } from '../../../utils';
import { isMobile } from '../../../utils/isMobile';
import '../../../styles/elements/gallery/index.scss';

const namespace = () => 'elements--gallery';

interface Props {
  id: string;
  helperClass: string;
  list: CMSPageGalleryImage[];
  onGalleryItemSortEnd: (oldIndex: number, newIndex: number, list: CMSPageGalleryImage[]) => void;
  onEditGalleryImage?: (item: CMSPageGalleryImage) => void;
  onDeleteGalleryImage: (item: CMSPageGalleryImage) => void;
  onRestoreGalleryImage: (item: CMSPageGalleryImage) => void;
  disabled?: boolean;
}

class Gallery extends React.Component<Props, {}> {

  props: Props;

  shouldComponentUpdate(nextProps, nextState) {
    return !shallowEqual(this.props.list, nextProps.list);
  }

  render() {
    const {list, id, helperClass, onGalleryItemSortEnd, onEditGalleryImage, onDeleteGalleryImage, onRestoreGalleryImage} = this.props;
    const SortableItem = SortableElement(({value, sortIndex}) => {
      const source = value.dataUrl ? value.dataUrl : getImagePath(value, "th150px_auto");
      return (
        <Image
          id={value.ID}
          source={source}
          width={92}
          height={92}
          loading={value.loading}
          uploading={value.uploading}
          deleted={value.Inactive}
          placeholder={<Loader />}
          contextMenu={<GalleryImageContextMenuComponent
            onMoveUp={(value.Inactive || value.uploading) ? undefined : () => onGalleryItemSortEnd(sortIndex, sortIndex - 1 < 0 ? 0 : sortIndex - 1, list)}
            onMoveDown={(value.Inactive || value.uploading) ? undefined : () => onGalleryItemSortEnd(sortIndex, sortIndex + 1 > list.length - 1 ? list.length - 1 : sortIndex + 1, list)}
            onRestore={value.Inactive ? () => onRestoreGalleryImage(value) : undefined}
            onEdit={(value.Inactive || value.uploading || !onEditGalleryImage) ? undefined : () =>  onEditGalleryImage(value)}
            onDelete={(value.Inactive || value.uploading) ? undefined : () => onDeleteGalleryImage(value)} />}
          />
      );
    });
    const SortableList = SortableContainer(({items}) => {
      return (
        <ul id={id}>
          {items.map((value, index) => (
            <SortableItem disabled={isMobile} key={`gallery-item-${value.tempId || value.ID}`} sortIndex={index} index={index} value={value} />
          ))}
        </ul>
      );
    });

    return (
      <div className={`${namespace()}--gallery--wrapper`}>
        <SortableList
          helperClass={helperClass}
          helperContainer={() => document.getElementById(id) as any}
          distance={10}
          axis='xy'
          items={list}
          onSortEnd={({oldIndex, newIndex}) => onGalleryItemSortEnd(oldIndex, newIndex, list)}
        />
      </div>
    );
  }
}
export default Gallery;
