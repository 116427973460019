import {createSelector} from "reselect";

// This is poorly named, there is a filter, but just one...
export const makeNoFilterSelector = (filter: (item: any) => boolean, itemSelector, sort?: (itemA: any ,itemB: any) => number) => {
  return () => createSelector(
    [itemSelector],
    (items: Array<any>) => {
      const filteredItems = items.filter((item: any) => filter(item));
      if (sort) {
        filteredItems.sort(sort);
      }
      return filteredItems;
    }
  );
};

export const makeSimpleSelector = (
  filter: (item: any) => boolean,
  stringFilter: (item: any, f: string) => boolean,
  itemSelector,
  filterSelector,
  sort?: (itemA: any ,itemB: any) => number
) => {
  return () => createSelector(
    [itemSelector, filterSelector],
    (items: Array<any>, textFilter: string) => {
      let f;
      if (textFilter && textFilter !== '') f = textFilter.toLowerCase();
      const filteredItems = items.filter((item: any) => {
        if (f) {
          return filter(item) && stringFilter(item, f);
        }
        return filter(item);
      });
      if (sort) {
        filteredItems.sort(sort);
      }
      return filteredItems;
    }
  );
};

export const makeSingleSelector = (filter: (item: any, id: number) => boolean, itemSelector, idSelector) => {
  return () => createSelector(
    [itemSelector, idSelector],
    (items: Array<any>, id: number) => {
      if (!id) return undefined;
      if (!items || items.length === 0) return undefined;
      const fItems = items.filter((item: any) => filter(item, id));
      if (fItems.length === 0) return undefined;
      return fItems[0];
    }
  );
};

export const listSelector = (selector: (state) => any, activeOnly?: boolean) => {
  return (state) => {
    const v = selector(state);
    // @todo - consider active children but inactive parents for the list
    if (v) {
      if (activeOnly) {
        return v.filter((item) => !item.Inactive);
      }
      return v;
    }
    return [];
  };
};