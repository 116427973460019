import * as React from 'react';
import { connect } from 'react-redux';

import Nav from '../Nav';
import { default as AppHeader, Props as AppHeaderProps } from '../../../App/Header';
import { ApplicationState } from '../../../../store';
import { getMergeProps } from '../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps>;

const Header: React.SFC<AppHeaderProps> = (props: AppHeaderProps & ConnectedProps): React.ReactElement<AppHeaderProps> => {
  if (!props || props.session.getLoginFormFailed) {
    return <AppHeader
      showDrawer
      title=""
    />;
  }
  return (
    <AppHeader
      centerTabs
      nav={<Nav selected="login" showNewAccount={!!props.session.AllowSelfSignup}/>}
      {...props}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({ app: state.app, session: state.session });
const ConnectedHeader = connect(
  mapStateToProps,
  null,
  getMergeProps<{}>(),
)(Header);

export default ConnectedHeader;
