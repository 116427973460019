import { Observable } from "rxjs";
import {AjaxResponse} from "rxjs/ajax";

import {makeRequestEpic} from "../../../Validation/epicCreator";
import {UpdateAdminEventClassTypeSubmitActions, DeleteAdminEventClassTypeSubmitActions} from "./actions";
import { ENTITY_CREATED, ENTITY_EDITED, ENTITY_PARSE_ERROR, ENTITY_DELETED, ENTITY_RESTORED } from "../../../../constants/messages/adminCMS";
import { getUpdateAdminEventClassType } from "../../../../constants/adminEventsUrls";
import { ADMIN_EVENT_CLASS_TYPE } from "../../../../constants/messages/adminEvents";
import { captureTentarooError } from "../../../../utils/dataHelper";
import { UpdateSelectedClassType } from "../../Classes/Form/actions";
import { ModalTypes, isModalOpened, popModalObservables } from "../../../../utils/modalHelper";

export const adminEventsUpdateClassTypeEpic = makeRequestEpic(
  UpdateAdminEventClassTypeSubmitActions,
  getUpdateAdminEventClassType,
  undefined,
  true,
  (response: AjaxResponse) => {
    const formData = response.request.body;
    try {
      const JSONDataStr = formData.get('JSONData');
      const JSONData = JSON.parse(JSONDataStr);
      if (JSONData.row.IDi) {
        return ENTITY_EDITED(ADMIN_EVENT_CLASS_TYPE);
      }
      return ENTITY_CREATED(ADMIN_EVENT_CLASS_TYPE);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(ADMIN_EVENT_CLASS_TYPE)));
      return '';
    }
  },
  undefined,
  undefined,
  undefined,
  undefined,
  true,
  (payload) => {
    const classType = payload.response.ClassType;


    const formData = payload.request.body;
    try {
      const JSONDataStr = formData.get('JSONData');
      const JSONData = JSON.parse(JSONDataStr);

      if (isModalOpened(ModalTypes.SELECT_CLASS_TYPE) && !JSONData.row.ID) {
        return Observable.concat(
          Observable.of(new UpdateSelectedClassType([classType.IDi])),
          ...popModalObservables({
            modal: ModalTypes.SELECT_CLASS_TYPE,
            saveBefore: false,
            saveAfter: false,
            transformToObservable: true,
          }),
        );
      }
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(ADMIN_EVENT_CLASS_TYPE) + formData));
      return '';
    }
  }
);
export const adminEventsDeleteClassTypeEpic = makeRequestEpic(
  DeleteAdminEventClassTypeSubmitActions,
  getUpdateAdminEventClassType,
  undefined,
  true,
  (response: AjaxResponse) => {
    const item = response.response.ClassType;

    if (item.Inactive) {
      return ENTITY_DELETED(ADMIN_EVENT_CLASS_TYPE);
    }
    return ENTITY_RESTORED(ADMIN_EVENT_CLASS_TYPE);
  },
);