import {makeRequestEpic} from "../../../../Validation/epicCreator";
import {AddResourceSubmitActions, DeleteResourceSubmitActions} from "./actions";
import {getUpdateResourceUrl, URLS, CMS_RESOURCES, CMS_PATHS} from "../../../../../constants/urls";
import {AjaxResponse} from "rxjs/ajax";
import {spaceTo_, validate} from "../../../../../utils";
import { CMSResource } from "../../../../../models/api/adminCMSCacheOne";
import { ToggleResourceSelect } from "../../Modals/MultipleResources/actions";
import { ValidateActions } from '../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/actions';
import { FormDefinition } from '../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/validation';
import { Observable } from "rxjs";
import { ADMIN_CMS_RESOURCES_EDIT_PATH, ADMIN_CMS_RESOURCES_NEW_PATH } from "../../../../../routes";
import { ENTITY_DELETED, ENTITY_RESTORED, ENTITY_PARSE_ERROR, ENTITY_EDITED, ENTITY_CREATED, RESOURCE } from "../../../../../constants/messages/adminCMS";
import { ModalTypes, isModalOpened } from "../../../../../utils/modalHelper";
import { captureTentarooError } from "../../../../../utils/dataHelper";
import { popModalObservables } from "../../../../../utils/modalHelper";
import { Action } from "redux";
import { NoOp } from "../../../../App/actions";
import { reduxStoreService } from "../../../../service";

export const adminCMSSiteAddResourceEpic = makeRequestEpic(
  AddResourceSubmitActions,
  getUpdateResourceUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const formData = response.request.body;
    try {
      const JSONDataStr = formData.get('JSONData');
      const JSONData = JSON.parse(JSONDataStr);
      if (JSONData.row.ID) {
        return ENTITY_EDITED(RESOURCE);
      }
      return ENTITY_CREATED(RESOURCE);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(RESOURCE)));
      return '';
    }
  },
  (response: AjaxResponse, action: any) => {
    const resource = response.response.CMSResource as CMSResource;
    const state = reduxStoreService().getState();
    const site = state.adminCMSSite.cacheOne.CMSSite;
    const qp = reduxStoreService().getState().routing.locationBeforeTransitions.search;
    const formData = response.request.body;
    try {
      const JSONDataStr = formData.get('JSONData');
      const JSONData = JSON.parse(JSONDataStr);
      if (action.currentRoute && action.currentRoute.path !== ADMIN_CMS_RESOURCES_NEW_PATH && action.currentRoute.path !== ADMIN_CMS_RESOURCES_EDIT_PATH) {
        // We should stay after the request completes in the following scenarios:
        // - Not in NEW RESOURCE endpoint
        // - Not in EDIT RESOURCE endpoint
        return '';
      } else if (resource && site) {
        const ResourceName = resource.Name;
        return `${URLS.ADMIN_RESOURCES}/${site.ID}/${resource.ID}${ResourceName ? `/${spaceTo_(ResourceName)}` : ''}${qp}`;
      }
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(RESOURCE)));
      return '';
    }
    // otherwise, if `resource` is returned AND CMSSite is available in cacheOne, navigate back to the resource list
    if (resource && site) {
      return `${URLS.ADMIN_SITES}/${site.ID}/${site.SiteDomain}/${CMS_PATHS.RESOURCES}${qp}`;
    }

    return '';
  },
  undefined,
  undefined,
  undefined,
  true,
  (payload): Observable<Action> | "" => {
    const resource = payload.response.CMSResource;
    const state = reduxStoreService().getState();
    const formData = payload.request.body;
    try {
      const JSONDataStr = formData.get('JSONData');
      const JSONData = JSON.parse(JSONDataStr);
      // will always dispatch this, because we do not optimistically close the modal
      const hideResourceFormModal = popModalObservables({
        modal: ModalTypes.RESOURCE_FORM,
        saveBefore: false,
        saveAfter: false,
        transformToObservable: true,
      });

      if (isModalOpened(ModalTypes.MULTIPLE_RESOURCES) && !JSONData.row.ID) {
        // When MultipleResources modal is opened and we are adding a new resource, select that resource
        return Observable.concat(
          ...hideResourceFormModal,
          Observable.of(new ToggleResourceSelect(true, resource.ID)),
        );
      } else if (isModalOpened(ModalTypes.RESOURCES) && !JSONData.row.ID && FormDefinition.ResourceID) {
        // When Resources modal is opened and we are adding a new resource, select that resource
        // PageMenu and SiteMenu share the same form so we dont need to distinguish them here
        const validationResults = validate(FormDefinition.ResourceID, resource.ID, undefined, () => state.adminCMSSite.menus.siteMenuItem.form);
        return Observable.concat(
          ...hideResourceFormModal,
          Observable.of(ValidateActions.updateCurr(resource.ID, 'ResourceID', FormDefinition.ResourceID)),
          Observable.of(ValidateActions.update(validationResults, 'ResourceID')),
          ...popModalObservables({
            modal: ModalTypes.RESOURCES,
            saveBefore: false,
            saveAfter: false,
            transformToObservable: true,
          }),
        );
      }

      return Observable.concat(...hideResourceFormModal);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(RESOURCE)));
      return Observable.of(new NoOp());
    }
  },
);


export const adminCMSSiteDeleteResourceEpic = makeRequestEpic(
  DeleteResourceSubmitActions,
  getUpdateResourceUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const resource = response.response.CMSResource;

    if (resource.Inactive) {
      return ENTITY_DELETED(RESOURCE);
    }
    return ENTITY_RESTORED(RESOURCE);
  },
);