import { ActionCreator, ApplicationState } from "../../../..";
import {createApiValidateActions, createValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod} from '../../../../Validation/actionCreator';
import { typeName, Action } from "../../../../../utils/StrongActions";
import { SaveState } from "../../../../Rollback/actions";

export const SUFFIX = '__SELECT_CLASS_TYPE_MODAL';

@typeName("FILTER" + SUFFIX)
export class SelectClassTypeModalFilter extends Action {
  constructor(public filterString: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class SelectClassTypeToggleFilterTabs extends Action {
  constructor(public searching: boolean) { super(); }
}

@typeName("RESET" + SUFFIX) export class ResetSelectClassTypeModal extends Action {}
@typeName("INIT" + SUFFIX)
export class InitSelectClassTypeModal extends Action {
  constructor(public classTypeIds: number[]) { super(); }
}

@typeName("TOGGLE_CLASS_TYPE_SELECT" + SUFFIX)
export class ToggleClassTypeSelect extends Action {
  constructor(public show: boolean, public classTypeId: number) { super(); }
}
@typeName("SELECT_ALL" + SUFFIX)
export class SelectAll extends Action { constructor(public classTypeIds: number[]) { super(); }}
@typeName("DESELECT" + SUFFIX)
export class Deselect extends Action { constructor(public classTypeIds: number[]) { super(); }}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export type Actions = typeof actionCreators;

export const actionCreators = {
  selectClassTypeModalUpdateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.events.modals.selectClassType),
  selectClassTypeModalSimpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  filterClassTypes: (filterString: string): ActionCreator => dispatch => dispatch(new SelectClassTypeModalFilter(filterString)),
  init: (classTypeIds: number[]): ActionCreator => dispatch => {
    dispatch(new InitSelectClassTypeModal(classTypeIds));
    dispatch(new SaveState());
  },
  reset: (): ActionCreator => dispatch => dispatch(new ResetSelectClassTypeModal()),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new SelectClassTypeToggleFilterTabs(searching)),
  toggleClassTypeSelect: (show: boolean, classTypeId: number): ActionCreator => dispatch => dispatch(new ToggleClassTypeSelect(show, classTypeId)),
  selectAll: (classTypeIds: number[]): ActionCreator => dispatch => dispatch(new SelectAll(classTypeIds)),
  deselect: (classTypeIds: number[]): ActionCreator => dispatch => dispatch(new Deselect(classTypeIds)),
};