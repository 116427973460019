
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetFacility} from "./actions";
import { getFacilityBody, getFacility } from "../../../constants/urls";

export const adminFacilityLocationCacheTwoFacilityEpic = makeRequestEpic(
  GetFacility,
  getFacility,
  getFacilityBody,
  true,
);