import { typeName, Action } from '../../../../utils/StrongActions';
import { ActionCreator } from '../../../index';

@typeName("EVENTS_EVENT_RESET") export class EventReset extends Action {}

@typeName("EVENTS_EVENT_SHOW_MORE_PRODUCTS")
export class EventShowMoreProducts extends Action {
  constructor(public showMoreProducts: boolean) {
    super();
  }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  resetEvent: (): ActionCreator => dispatch => dispatch(new EventReset()),
  eventShowMoreProducts: (showMoreProducts: boolean): ActionCreator => dispatch => dispatch(new EventShowMoreProducts(showMoreProducts))
};
