import * as React from 'react';
import { Button } from 'react-mdl';
import {mergeClassNames} from '@tentaroo/shared';

import { namespace as ns } from './constants';
import { isMobile } from '../../../utils/isMobile';

export const namespace = (): string => `${ns()}--list--item`;

export interface ItemProps {
  children?: React.ReactNode;
  className?: string;
  icon: React.ReactElement<any>;
  forceTablet?: boolean;
  onClick?: () => void;
}

const MdlButton: any = Button;

const Item: React.SFC<ItemProps> = ({ onClick, ...props }: ItemProps): React.ReactElement<ItemProps> => {
  const className = mergeClassNames(namespace(), props);
  return (
    <li className={`${className} ${props.forceTablet ? 'force-tablet' : ''}`}>
      <MdlButton
        ripple={!isMobile}
        className={`${namespace()}--link`}
        onClick={onClick}
      >
        <div className={`${namespace()}--link--icon`}>
          {React.cloneElement(props.icon, {
            ...props.icon.props,
            className: `${namespace()}--link--icon--image` + (props.icon.props.className ? ` ${props.icon.props.className}` : '')
          })}
        </div>
        <span className={`${namespace()}--link--text`}>
          {props.children}
        </span>
      </MdlButton>
    </li>
  );
};

export default Item;
