import { ApplicationState } from "../../../..";
import { SelectValidator, Validator } from "../../../../../utils/validator/models";

export interface IValidator {
  SelectedFacilityType: SelectValidator;
  SelectedFacility: Validator;
}

const getFacilityTypeValues = (rootState: ApplicationState) => {
  const types = rootState.adminFacilityLocation.cacheOne.FacilitiesTypes;
  return !types ? [] : types.filter((t) => !t.Inactive);
};

export const FormDefinition: IValidator = {
  SelectedFacilityType: {
    key: 'SelectedFacilityType',
    defaultValue: () => 0,
    options: {
      values: (rootState) => [{ NamePlural: 'All Facilities', ID: 0 }, ...getFacilityTypeValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'NamePlural',
    }
  },
  SelectedFacility: {
    key: 'SelectedFacility',
    defaultValue: () => 0,
  }
};