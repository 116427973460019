import {GetGroupRosterPerson} from './actions';
import { getGroupRosterPerson, getGroupRosterPersonBody } from '../../constants/urls';
import {makeRequestEpic} from "../Validation/epicCreator";

export const cacheThreeRosterEpic = makeRequestEpic(
  GetGroupRosterPerson,
  getGroupRosterPerson,
  getGroupRosterPersonBody,
  true
);
