import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';
import { namespace as ns } from './constants';
import '../../../styles/elements/camp-info/sub-title.scss';

export const namespace = (): string => `${ns()}--sub-title`;

export interface SubTitleProps {
  className?: string;
  children?: React.ReactNode;
  size?: number;
}

const SubTitle: React.SFC<SubTitleProps> = ({ children, size, ...props }: SubTitleProps): React.ReactElement<SubTitleProps> => (
  <h2 className={mergeClassNames(namespace(), props) + (size ? ` size-${size}` : '')}>
    {children}
  </h2>
);

export default SubTitle;
