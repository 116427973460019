import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import {
    actionCreators as cacheZeroActionCreators
} from '../../../../../store/CacheZero/actions';
import {
    actionCreators as adminEventsCacheOneActionCreators,
} from '../../../../../store/AdminEvents/CacheOne/actions';
import {
    actionCreators as adminEventsCacheTwoMessageActionCreators, GetAdminEventMessage,
} from '../../../../../store/AdminEvents/CacheTwoMessage/actions';
import { actionCreators as appActionCreators, Actions as AppActions } from '../../../../../store/App/actions';
import { 
  actionCreators as rollbackActionCreators,
} from '../../../../../store/Rollback/actions';
import { actionCreators, AdminEventMessageFormNamespace as namespace } from "../../../../../store/AdminEvents/MessageCenter/Form/actions";
import { SideModal, SideModalContent, SideModalHeader, Loader, SideModalActions, Button, ActionButton } from '../../../../../components/Elements';
import Form from './Form';
import '../../../../../styles/pages/admin-events/message-center/message/index.scss';
import { navPush } from '../../../../../utils';
import { NotFound } from '../../../../../components/Pages';
import { INVALID_MESSAGE } from '../../../../../constants/messages/adminEvents';
import { getAdminEventsMessageCenterHomeRootUrl, constructAdminEventsUrlParams } from '../../../../../constants/adminEventsUrls';
import { EmailIcon } from '../../../../Icons';
import { checkAdminEventMessagePermission } from '../../../../../utils/helpers/adminEventsPageHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { shouldReconfigRouter } from '../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { noOpenedModals } from '../../../../../utils/modalHelper';
import { reduxStoreService } from '../../../../../store/service';
import {isAdminEventsCacheTwoMessagePopulated} from '../../../../../utils/cachePopulatedCheckers/adminEvents';
import { WithInertAttribute } from '../../../../Elements/WithInert';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> &RouteComponentProps<{}, {messageId: string}>;

type Props = WithInertAttribute<{
  type: 'add' | 'edit';
  productId?: number;
  onDelete?: (item: any) => void;
  onBack?: () => void;
}>;
class MessageSideModal extends React.Component<Props, {}> {
  public props: ConnectedProps & Props;

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);
    this.configRouter();
  }

  configRouter() {
    const {router, routes} = this.props;
    router.setRouteLeaveHook(routes[routes.length - 1], this.routerWillLeave);
  }

  componentDidUpdate(prevProps: ConnectedProps & Props) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
  }

  routerWillLeave = (nextLocation) => {
    const {actions, type, adminEventsCacheTwoMessage} = this.props;

    if (reduxStoreService().getState().app.apiLoading === 0) {
      if (type === "add" || isAdminEventsCacheTwoMessagePopulated(adminEventsCacheTwoMessage)) {
        actions.saveState();
      }
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentWillUnmount() {
    const {actions, type, adminEventsCacheTwoMessage} = this.props;

    if (type === "add" || isAdminEventsCacheTwoMessagePopulated(adminEventsCacheTwoMessage)) {
      actions.clearAdminEventsCacheTwoMessage();
    }

    this.resetRouteLeaveHook();
  }

  onSave = () => {
    const {type, actions, adminEventsCacheOne, routes, router} = this.props;

    checkAdminEventMessagePermission(
      () => {
        actions.apiSubmitForm(router, routes);
      },
      adminEventsCacheOne,
      type === 'edit',
    );
  };
  onDelete = () => {
    const {actions, router, adminEventsCacheOne, routes, formState: {ActiveForm}} = this.props;

    checkAdminEventMessagePermission(
      () => {
        if (ActiveForm.ID !== undefined) {
          actions.deleteAdminEventMessage(ActiveForm.ID, router, routes, true);
        }
      },
      adminEventsCacheOne,
      true,
    );
  };
  onClickModal = (e) => {
    e && e.stopPropagation();
  };
  onBack = () => {
    if (this.props.onBack) {
      this.props.onBack();
      return;
    }
    const backUrl = getAdminEventsMessageCenterHomeRootUrl(constructAdminEventsUrlParams(this.props, this.props.adminEventsCacheOne));
    this.props.actions.clearAdminEventsCacheTwoMessage();
    navPush(this.props.router, backUrl);
  };

  renderNotFound = () => {
    return (
      <NotFound showCMSSiteHeader inModal message={INVALID_MESSAGE}/>
    );
  };

  isDisabled = () => {
    const {type, adminEventsCacheOne} = this.props;

    if (adminEventsCacheOne.EventsEventType) {
      const permissions = adminEventsCacheOne.EventsEventType.Permissions;

      if (type === 'add') {
        return !permissions.hasAddMessage;
      } else {
        return !permissions.hasEditMessage;
      }
    }
  };

  renderContent = (empty?: boolean) => {
    const { type, apiSaving, inert, adminEventsCacheOne, actions, cacheZero, formState} = this.props;

    return [
      <SideModalContent lockBodyScroll key='admin-event-class-content'>
        {!empty && <Form
          inert={inert}
          messageForm={formState}
          disabled={apiSaving > 0}
          reduxActions={actions}
          adminEventsCacheOne={adminEventsCacheOne}
          action={type}
        />}
      </SideModalContent>,
      empty ? null : <SideModalActions key='admin-event-class-actions'>
        {type === 'add' && <Button disabled={this.isDisabled()} flat textColor="black" onClick={this.onSave}>CREATE</Button>}
        {type === 'edit' && <Button id={generateDOMId("tentaroo-admin-message-save-btn")} disabled={this.isDisabled()} flat textColor="black" onClick={this.onSave}>SAVE</Button>}
        {type === 'edit' && <Button disabled={this.isDisabled()} flat textColor="red" onClick={this.onDelete}>DELETE</Button>}
      </SideModalActions>,
    ];
  };

  onSendMessage = (inModal?: boolean) => {
    const {actions, type, adminEventsCacheOne, routes, router, formState: {ActiveForm}} = this.props;
    
    checkAdminEventMessagePermission(
      () => {
        actions.apiSubmitForm(router, routes, true);
      },
      adminEventsCacheOne,
      type === 'edit',
      undefined,
      inModal,
    );
  };

  public render() {
    const {type, formState: {ActiveForm}, adminEventsCacheTwoMessage, adminEventsCacheOne, apiLoadingMap } = this.props;
    const loading = type === 'edit' && (
      !isAdminEventsCacheTwoMessagePopulated(adminEventsCacheTwoMessage, ActiveForm) ||
      (isAdminEventsCacheTwoMessagePopulated(adminEventsCacheTwoMessage, ActiveForm) && apiLoadingMap[GetAdminEventMessage.requestType])
    ) && noOpenedModals();
    const subtitle = adminEventsCacheOne.EventsEventType ? adminEventsCacheOne.EventsEventType.General.Name : '';

    let content;

    if (type === 'add') {
      content = this.renderContent();
    } else {
      if (!isAdminEventsCacheTwoMessagePopulated(adminEventsCacheTwoMessage, ActiveForm) && apiLoadingMap[GetAdminEventMessage.requestType]) {
        content = this.renderContent(true);
      } else if (!adminEventsCacheTwoMessage.Message || adminEventsCacheTwoMessage.Message.Inactive) {
        content = this.renderNotFound();
      } else if (adminEventsCacheTwoMessage.Message) {
        content = this.renderContent();
      }
    }

    return (
      <SideModal
        allowShadowClickDefault
        className={`${namespace()}`}
        onClick={this.onClickModal}
        stickyHeader
        header={<SideModalHeader
                  className={`${namespace()}--header`}
                  onBack={this.onBack}
                  subtitle={subtitle}
                  onlyShowSubTitleInMobile
                  title={type === 'add' ? 'New Message' : "Edit Message"}
                  mobileControls={<ActionButton className={`${namespace()}--send-btn-mobile`} disabled={loading} icon={EmailIcon} onClick={() => this.onSendMessage(true)} />}
                  controls={(type === 'add' || (adminEventsCacheTwoMessage.Message && !adminEventsCacheTwoMessage.Message.Inactive)) && <Button className={`${namespace()}--send-btn`} id={generateDOMId("admin-message-send-btn")} onClick={this.onSendMessage} color='white' textColor='black' icon={<EmailIcon />}>SEND MESSAGE</Button>}
                />}
        >
          {content}
        {loading ? <Loader className={`${namespace()}--loader`} center /> : null}
      </SideModal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    rollback: state.rollback,
    cacheZero: state.cacheZero,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    formState: state.adminEvents.messageCenter.form,
    adminEventsCacheTwoMessage: state.adminEvents.cacheTwoMessage,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...rollbackActionCreators,
    ...cacheZeroActionCreators,
    ...adminEventsCacheTwoMessageActionCreators,
    ...appActionCreators,
    ...adminEventsCacheOneActionCreators,
  }, dispatch),
});

const ConnectedMessageSideModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>()
)(MessageSideModal);

export default withRouter<Props>(ConnectedMessageSideModal);
