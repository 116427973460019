import { ApplicationState } from "../../../..";
import * as M from "../../../../../constants/messages/generic";
import { PageTypeIDValue } from "../../../../../models/api/adminCMSCacheOne";
import { IValidator } from "./index.type";

export const getPageTypeValues = (state: ApplicationState) => {
  if (!state.adminCMSSite.cacheOne.PageTypesFilter) return [];

  return state.adminCMSSite.cacheOne.PageTypesFilter;
};

export const getEventCategoryValues = (state: ApplicationState) => {
  if (!state.adminCMSSite.cacheOne.SiteEventCategories) return [];

  return state.adminCMSSite.cacheOne.SiteEventCategories;
};

export const FormDefinition: IValidator = {
  PageTypeID: {
    key: 'PageTypeID',
    options: {
      values: (rootState) => [...getPageTypeValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'Name'
    },
    defaultValue: () => {
      return 0;
    },
  },
  EventCategoryID: {
    key: 'EventCategoryID',
    options: {
      values: (rootState) => [{ID: 0, NameAndIsHidden: 'All Categories'}, ...getEventCategoryValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'NameAndIsHidden'
    },
    defaultValue: () => {
      return 0;
    },
  },
  IncludePastEvents: {
    key: 'IncludePastEvents',
  },
  IncludeExpiredBlogArticles: {
    key: 'IncludeExpiredBlogArticles',
  },
  EventStartDate: {
    key: 'EventStartDate',
    skip: (rootState) => {
      return rootState.adminCMSSite.pages.modals.pageType.ActiveForm.PageTypeID !== PageTypeIDValue.CALENDAR_EVENT;
    },
    isRequired: (state) => {
      return state.adminCMSSite.pages.modals.pageType.ActiveForm.PageTypeID === PageTypeIDValue.CALENDAR_EVENT;
    },
    validatejs: {
      EventStartDate: {
        presence: {message: M.S_REQUIRED("Start Date From")},
        datetime: {
          notValid: M.S_INVALID_DATE("Start Date From"),
        },
      }
    }
  },
};