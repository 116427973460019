import * as M from "../../../constants/messages/generic";
import { Validator } from "../../../utils/validator/models";


export enum FacilitiesReportType {
  TRIP_ITINERARY=1,
  GROUP_TRIPS,
  GROUP_RESERVATIONS,
}
export interface IValidator {
  StartDate: Validator;
  EndDate: Validator;
  IncludeBlackoutDates1: Validator;
  IncludeBlackoutDates2: Validator;
  SelectedReportType: Validator;
}
export const FormDefinition: IValidator = {
  StartDate: {
    key: 'StartDate',
    isRequired: () => true,
    validatejs: {
      StartDate: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          notValid: `^${M.INVALID_DATE}`,
        }
      },
    },
  },
  EndDate: {
    key: 'EndDate',
    isRequired: () => true,
    validatejs: {
      EndDate: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          notValid: `^${M.INVALID_DATE}`,
        }
      },
    },
  },
  IncludeBlackoutDates1: {
    key: 'IncludeBlackoutDates1',
  },
  IncludeBlackoutDates2: {
    key: 'IncludeBlackoutDates2',
  },
  SelectedReportType: {
    key: 'SelectedReportType',
    defaultValue: () => FacilitiesReportType.TRIP_ITINERARY,
  },
};