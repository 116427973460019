import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/elements/financial-summary/details.scss';

export const namespace = (): string => `${ns()}--details`;

interface Props {
  children?: React.ReactNode;
  total?: React.ReactElement<any>;
  toggle?: boolean;
};

interface State {
  open: boolean;
}

class Details extends React.Component<Props, State> {
  static defaultProps = {
    toggle: true
  };

  public state: State = {
    open: false
  };

  public handleToggle = (open: boolean) => this.setState({ open });

  public render() {
    const { children, toggle, total } = this.props;
    const { open } = this.state;
    return (
      <div className={namespace()}>
        {children ? (
          <div className={`${namespace()}--content`} style={{ display: (!toggle || open) ? 'block' : 'none' }}>
            {children}
          </div>
        ) : null}
        {toggle && total ?
          React.cloneElement(total, { ...total.props, toggle, open, onToggle: this.handleToggle }) :
          total}
      </div>
    );
  }
}

export default Details;
