import * as React from 'react';
import '../../../styles/elements/step-progress-bar/index.scss';

import {namespace} from "./constants";

export interface StepProgressBarProps {
  children?: React.ReactNode;
  vertical?: boolean;
}

class StepProgressBar extends React.PureComponent<StepProgressBarProps, {}> {
  public props: StepProgressBarProps;

  public render() {
    const { children, vertical } = this.props;
    const finalChildren: Array<any> = [];
    let index = 0;
    let count = 1;
    let done = true;

    if (!vertical) {
      React.Children.toArray(children).forEach((child: React.ReactElement<any>) => {
        if (finalChildren.length) {
          finalChildren.push(<li className={`${namespace()}--list--separator ${vertical ? 'vertical' : ''}`} key={index}/>);
          index++;
        }
        if (child.props.selected) done = false;
        finalChildren.push(React.cloneElement(child, { ...child.props, num: count, done }));
        index++;
        count++;
      });
    } else {
      React.Children.toArray(children).forEach((child: React.ReactElement<any>, i: number) => {
        if (child.props.selected) done = false;
        if (i !== React.Children.toArray(children).length - 1) {
          finalChildren.push(
            <div className={`${namespace()}--wrapper`}>
              {React.cloneElement(child, { ...child.props, num: count, done })}
              <li className={`${namespace()}--list--separator ${vertical ? 'vertical' : ''}`} key={index}/>
            </div>
          );
        } else {
          finalChildren.push(React.cloneElement(child, { ...child.props, num: count, done }));
        }
        index++;
        count++;
      });
    }

    return (
      <div className={namespace()}>
        <ul className={`${namespace()}--list ${count - 1 === 2 ? 'two-steps' : ''} ${vertical ? `${namespace()}--list-vertical` : ''}`}>
          {finalChildren}
        </ul>
      </div>
    );
  }
}

export default StepProgressBar;
export { default as StepProgressBarStep } from './Step';
