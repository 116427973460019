import * as React from 'react';
import {
  Modal, ModalActions, Button, ModalContent, ModalHeader, Row, Column, Select, EmptyMessage, AdminClassCard, PageLoader, TextField, FormSection, Alert, FieldSet, Checkbox, Text, ParticipantCard, VirtualList } from '../../../../../Elements';
import {withRouter, RouteComponentProps} from "react-router";
import {bindActionCreators} from "redux";
import {actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import {actionCreators as rollbackActionCreators} from "../../../../../../store/Rollback/actions";
import {actionCreators as enterClassRequirementActionCreators, getClassParticipantStatusKey} from "../../../../../../store/AdminEvents/Events/Modals/EnterClassRequirement/actions";
import {actionCreators as cacheThreeClassRequirementsActionCreators,GetAdminClassRequirements} from "../../../../../../store/AdminEvents/CacheThreeClassRequirements/actions";
import {actionCreators as cacheTwoEventActionCreators, GetAdminEvent} from "../../../../../../store/AdminEvents/CacheTwoEvent/actions";
import {actionCreators as cacheOneActionCreators} from "../../../../../../store/AdminEvents/CacheOne/actions";
import {ApplicationState} from "../../../../../../store";
import '../../../../../../styles/pages/admin-events/events/modals/enter-class-requirement/index.scss';
import { makeFilteredClassesSelector, makeFilteredParticipantsSelector, getClassParticipantNewRequirementKey } from '../../../../../../store/AdminEvents/Events/Modals/EnterClassRequirement';
import { AdminEventClass, AdminEventClassParticipant, AdminClassParticipantStatus, AdminEventClassParticipantAttendance, AdminEventChildClass, isOldClassRequirements, isNewClassRequirements, MBRequirement } from '../../../../../../models/api/adminEventsCacheTwoEvent';
import { ClassTypesIcon, PersonIcon } from '../../../../../Icons';
import { EmptyMessageType } from '../../../../../Elements/EmptyMessage';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { ADMIN_EVENT_CLASSES } from '../../../../../../constants/messages/adminEvents';
import { AdminClassCardType } from '../../../../../Elements/AdminClass/Card';
import Card, { CardCategory } from '../../../../../Elements/Card';
import { sortRequirements } from '../../../../../../store/AdminEvents/Events/Modals/EnterClassRequirement';
import { ModalHeight } from '../../../../../Elements/Modal';
import { FilterSearch } from '../../../../../Layouts';
import { extractRouteParams } from '../../../../../../utils/urlHelper';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { isMobileAndSmallerScreenSize } from '../../../../../../utils/isMobile';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { getEventIdFromPath, IAdminEventRouterParams } from '../../../../../../utils/helpers/adminEventsPageHelper';
import AdminEventsCacheManager from '../../../../../../utils/cacheManagers/adminEventsCacheManager';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { classTypeSelector } from '../../../../../../store/CacheZero/selectors';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { reduxStoreService } from '../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import {isAdminEventsCacheThreeClassRequirementsPopulated} from '../../../../../../utils/cachePopulatedCheckers/adminEvents';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

const namespace = () => 'pages--events--modal--enter-class-requirement';

interface State {
  searchingParticipant: boolean;
}

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminEventRouterParams, State>
>;

@(withRouter as any)
class EnterClassRequirementModal extends ComponentUpdateTemplate<ConnectedProps, State> {
  public props: ConnectedProps;
  private listRef;
  private modalContentRef;

  constructor(props) {
    super(props, {
      searchingParticipant: false,
    });
  }
  componentDidMount() {
    if (shouldBlockActions()) return;
    if (this.props.selectedClassId) {
      // This `selectClass` is to select a class so that we enter the classRequirement form. A load will be triggered
      // after selecting a class. Same in `onSelectClass` method
      this.props.actions.selectClass(this.props.selectedClassId, this.props.childClassId);

      super.loadAndSetData(
        this.props,
        () => {
          // The `UPDATE_FORM` action after load finishes will init fields such as dropdowns in this case
          AdminEventsCacheManager.getInstance().loadCacheThreeClassRequirements({
            props: this.props,
            isEdit: true,
            classId: this.props.childClassId || this.props.selectedClassId,
            isStateNavigated: true,
          });
        }
      );
    } else {
      // When opening the modal without `selectedClassId`, we need to fields such as those dropdowns
      this.props.actions.init();
    }
  }

  componentWillUnmount() {
    this.onClose();
  }
  
  onSave = (e?: any) => {
    if (e) e.stopPropagation();
    this.props.actions.submit();
  };

  onBack = () => {
    const { actions, modalState: {ValidationRules, ActiveForm} } = this.props;

    if (reduxStoreService().getState().app.apiLoading > 0 ) { 
      actions.cancelGetClassRequirements();
    }
    
    actions.updateValue(undefined, ValidationRules.SelectedClass);
    this.onSearchBack();
    actions.clearAdminEventsCacheThreeClassRequirements();
  };

  scrollToTop = () => {
    if (this.listRef) this.listRef.scrollTop = 0;
  };

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    this.props.actions.toggleFilter(false);
    this.props.actions.filterClass('');
  };
  onRefreshClick = () => {
    const {actions, modalState: {ActiveForm}} = this.props;
    const eventId = getEventIdFromPath(this.props.params);

    actions.saveState();
    actions.refreshEvent({
      EventIDi: eventId,
      GetClasses: true,
      ...extractRouteParams(this.props),
    }, false);
  };

  renderEmptyClasses = () => {
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        admin
        icon={ClassTypesIcon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={ENTITY_NOT_FOUND(ADMIN_EVENT_CLASSES)}
      />
    );
  };

  onSelectClass = (adminEventParentClass: AdminEventClass, adminEventChildClass?: AdminEventChildClass) => {
    const {actions} = this.props;
    actions.saveState();

    actions.selectClass(adminEventParentClass.IDi, adminEventChildClass ? adminEventChildClass.IDi : undefined);
    const IDi = adminEventChildClass ? adminEventChildClass.IDi : adminEventParentClass.IDi;
    AdminEventsCacheManager.getInstance().loadCacheThreeClassRequirements({
      props: this.props,
      isEdit: true,
      classId: IDi,
      isStateNavigated: false,
    });
  };

  renderFilters = () => {
    const {modalState: {ActiveForm, ValidationRules}} = this.props;
    
    return [
      !ActiveForm.SelectedClass ? 
        <Row key='filter_1' className={`${namespace()}--filter`}>
          <div className={`${namespace()}--filter--col`}>
            <Select
              label='Kind of Class'
              disabled={false}
              hideOptional
              onChangeValue={this.props.actions.updateValue}
              value={ActiveForm.KindOfClass}
              validationRules={ValidationRules.KindOfClass}
              isNumber />
          </div>
          <div className={`${namespace()}--filter--col`}>
            <Select
              label='Register Under'
              disabled={true}
              hideOptional
              onChangeValue={this.props.actions.updateValue}
              value={ActiveForm.RegisterUnder}
              validationRules={ValidationRules.RegisterUnder}
              isNumber />
            </div>
        </Row> : <div key='filter_1'/>,
    ];
  };

  onClickRequirement = (requirement: string | MBRequirement) => {
    this.props.actions.massUpdateRequirement(requirement);
  };
  onClassOptionClick = (id: number | string) => {
    this.props.actions.massUpdateStatus(id as any);
  };

  updateParticipant = (v, vObj, index: number) => {
    this.props.actions.updateClassParticipantValue(v, vObj, (s: ApplicationState) => s.adminEvents.events.modals.enterClassRequirement.ClassParticipants[index]);
    this.props.actions.refreshMassUpdateAttendance();
  };

  onCheckParticipantAttendance = (participantId: number, attend: AdminEventClassParticipantAttendance) => {
    this.props.actions.toggleParticipantAttendance(participantId, attend);
    this.props.actions.refreshMassUpdateAttendance();
  };

  onAddAllRequirementsToParticipant = (participantId: number) => {
    this.props.actions.addAllRequirementsToParticipant_OldTracking(participantId);
  };

  onRemoveAllRequirementsFromParticipant = (participantId: number) => {
    this.props.actions.removeAllRequirementsFromParticipant_OldTracking(participantId);
  };
  onRemoveRequirementFromParticipant = (participantId: number, r: string) => {
    this.props.actions.removeRequirementFromParticipant(participantId, r);
  };

  onRequirementKeyDown = (e, participant: AdminEventClassParticipant, requirements: string, index: number) => {
    if (e.keyCode === 13 && !!requirements) {
      this.props.actions.addNewRequirementsToParticipant_OldTracking(participant.IDi, requirements);
      this.updateParticipant("", participant.ValidationRules[getClassParticipantNewRequirementKey(participant)], index);
    }
  };

  renderParticipantCard = ({index}, measure) => {
    const {apiSaving, actions, filteredParticipants, adminEventsCacheThreeClassRequirements} = this.props;
    const participant = filteredParticipants[index];

    return (
      <ParticipantCard
        adminEventsCacheThreeClassRequirements={adminEventsCacheThreeClassRequirements}
        participant={participant}
        apiSaving={apiSaving}
        actions={actions}
        onUpdateParticipant={this.updateParticipant}
        onRequirementKeyDown={this.onRequirementKeyDown}
        onCheckParticipantAttendance={this.onCheckParticipantAttendance}
        onRemoveAllRequirementsFromParticipant={this.onRemoveAllRequirementsFromParticipant}
        onRemoveRequirementFromParticipant={this.onRemoveRequirementFromParticipant}
        onAddAllRequirementsToParticipant={this.onAddAllRequirementsToParticipant}
        index={index}
      />
    );
  };

  forwardListRef = (ref) => {
    this.listRef = ref;
  };

  keyMapper = (rowIndex: number, colIndex: number) => {
    const {filteredParticipants} = this.props;

    const participant = filteredParticipants[rowIndex];

    return participant.IDi;
  };

  renderRequirementsForMassUpdate_NewTracking = (
    allRequirements: MBRequirement[] | undefined | null,
    classRequirements: (string | number)[] | undefined,
  ) => {
    if (!classRequirements) return null;

    return (
      allRequirements?.filter((req) => {
        return Boolean(classRequirements.find((reqId) => reqId === req.ID));
      }).map((req) => {
        return (
          <Column marginRight={12} marginBottom={12}>
            <Button className={`${namespace()}--requirement-btn normal-case`} onClick={() => this.onClickRequirement(req)} textColor='black' color='white'>{req.req}</Button>
          </Column>
        );
      })
    );
  };

  hasRequirementsForMassUpdate = () => {
    const {modalState: {ClassRequirements: {ActiveForm}}, adminEventsCacheThreeClassRequirements} = this.props;

    const allRequirements = adminEventsCacheThreeClassRequirements.EventsEventClass_AllMBRequirements;

    if (isOldClassRequirements(ActiveForm)) {
      return ActiveForm.Requirements && ActiveForm.Requirements.length > 0;
    }

    if (isNewClassRequirements(ActiveForm)) {
      /**
       * In new tracking, we say we have requirements for mass update when requirements
       * can be found in `EventsEventClass_AllMBRequirements` and after this filtering
       * the # of requirements is still greater than 0
       */
      return (
        ActiveForm.Requirements &&
        ActiveForm.Requirements
          .filter((reqId) => {
            return Boolean(allRequirements?.find((req) => req.ID === reqId));
          })
          .length > 0
      );
    }
  };

  renderRequirementsForMassUpdate = () => {
    const {modalState: {ClassRequirements: {ActiveForm}}, adminEventsCacheThreeClassRequirements} = this.props;

    if (!this.hasRequirementsForMassUpdate()) return null;
    // NOTE: `Requirements` under Cache and ActiveForm should be the same
    const ClassRequirementsActiveForm = ActiveForm;
    return [
        <Row key="mass-requirements-title" marginTop={16}>
          <div className={`${namespace()}--label`}>Requirements</div>
        </Row>,
        <Row key="mass-requirements-chips" styles={{marginRight: '-12px', marginBottom: '-12px'}}>
          {isOldClassRequirements(ClassRequirementsActiveForm) && sortRequirements(ClassRequirementsActiveForm.Requirements).map((r) => {
            return (
              <Column marginRight={12} marginBottom={12}>
                <Button className={`${namespace()}--requirement-btn`} onClick={() => this.onClickRequirement(r)} textColor='black' color='white'>{r}</Button>
              </Column>
            );
          })}
          {isNewClassRequirements(ActiveForm) && this.renderRequirementsForMassUpdate_NewTracking(
            adminEventsCacheThreeClassRequirements.EventsEventClass_AllMBRequirements,
            ActiveForm?.Requirements,
          )}
        </Row>,
    ];
  };

  renderStep2 = () => {
    const { apiLoadingMap, apiSaving, cacheZero, modalState: {ActiveForm, AttendArr, ValidationRules, ClassParticipants, ClassRequirements}, modalState, adminEventsCacheThreeClassRequirements, filteredParticipants, actions} = this.props;
    if (!ActiveForm.SelectedClass) return null;
    const loading = apiLoadingMap[GetAdminClassRequirements.requestType] || !isAdminEventsCacheThreeClassRequirementsPopulated(adminEventsCacheThreeClassRequirements, modalState) || !ClassRequirements;

    return (
      <div className={`${namespace()}--step2`}>
        <Row styles={{flex: '0 0 auto'}}>
          <Column span={12}>
            <AdminClassCard
              adminEventClass={ActiveForm.SelectedClass as AdminEventClass}
              type={AdminClassCardType.SINGLE_SELECTED}
              category={CardCategory.LIST}
              onRemove={this.onBack}
            />
          </Column>
        </Row>
        {loading && <PageLoader className={`${namespace()}--requirement--loader`} />}
        {!loading && (!ClassParticipants || ClassParticipants.length === 0) && (
          <EmptyMessage
            type={EmptyMessageType.PAGE_MARGIN}
            icon={PersonIcon}
            description='No participants'
          />
        )}
        {!loading && !!ClassParticipants && ClassParticipants.length > 0 && <section className={`${namespace()}--requirement-section`}>
          <Row styles={{position: 'relative'}} marginTop={8}>
            <Column span={6} mobileSpan={12}>
              <TextField
                label="Instructor Full Name"
                disabled={apiSaving > 0}
                onChange={actions.simpleUpdateClassRequirement}
                onBlur={actions.updateClassRequirementValue}
                validationRules={ClassRequirements.ValidationRules.InstructorName}
                value={ClassRequirements.ActiveForm.InstructorName}
                info={isMobileAndSmallerScreenSize() ? undefined : 'Info will be used for Blue Cards, Classes Export, Instructor Class Roster and Participant Class Schedule export.'}
                infoOverflow
              />
            </Column>
            <Column style={{position: 'relative'}} span={3} mobileSpan={12}>
              <div className={`${namespace()}--initials-wrapper`}>
                <TextField
                  label="Initials"
                  disabled={apiSaving > 0}
                  onChange={actions.simpleUpdateClassRequirement}
                  onBlur={actions.updateClassRequirementValue}
                  validationRules={ClassRequirements.ValidationRules.InstructorInitials}
                  value={ClassRequirements.ActiveForm.InstructorInitials}
                  info={isMobileAndSmallerScreenSize() ? 'Info will be used for Blue Cards, Classes Export, Instructor Class Roster and Participant Class Schedule export.' : ' '}
                  infoOverflow
                />
              </div>
            </Column>
          </Row>
          <Row marginTop={24}>
            <Column span={12}>
              <FormSection
                title='Mass Update'
                hideEdit
              >
                <Row>
                  <Column span={12}>
                    <Alert>Mass updates will only affect participants set to Partial or Completed, won’t apply to participants set to No Show.</Alert>
                  </Column>
                </Row>
                <Row marginTop={8}>
                  <div className={`${namespace()}--label`}>Status</div>
                </Row>
                <Row>
                  {!!cacheZero.options && !!cacheZero.options.ClassCompletionOptions && cacheZero.options.ClassCompletionOptions.map((option, index) => {
                    return (
                      <Column marginRight={12} marginBottom={12}>
                        <Button onClick={() => this.onClassOptionClick(option.ID)} textColor='black' color='white'>{option.Name}</Button>
                      </Column>
                    );
                  })}
                </Row>
                <Row marginTop={12}>
                  <div className={`${namespace()}--label`}>Attendance</div>
                </Row>
                  <Row>
                    {AttendArr && AttendArr.map((attend) => {
                      return (
                        <Column key={`mass_update_attendance_${attend.label}`} className={`${namespace()}--mass-update--attend-col`} marginRight={12}>
                          <Checkbox
                            value={attend.label}
                            disabled={false}
                            selected={!!attend.checked}
                            indeterminate={attend.status === -1}
                            newDesign
                            label={<Text color='dark-gray' weight="regular"  size={16} marginBottom={0}>{attend.label}</Text>}
                            onChange={() => this.props.actions.massUpdateAttendance(attend.label, !attend.checked)}
                          />
                          
                        </Column>
                      );
                    })}
                  </Row>
                  {this.renderRequirementsForMassUpdate()}
              </FormSection>
            </Column>
          </Row>
          <FieldSet
            marginBottom={0} marginTop={8} legendMarginBottom={16}
            fixedLegendFontSize
            newDesign
            className={`${namespace()}--participants${this.state.searchingParticipant ? ' searching' : ''}`}
            fontSize={22}
            name='Enter by Participant'
            keepControlsPositionInMobile
            controls={
              <FilterSearch
                mobileMarginRight={8}
                disabled={apiSaving > 0}
                ActiveForm={ActiveForm}
                searchKey='ParticipantFilterText'
                marginBottom={0}
                ValidationRules={ValidationRules}
                reduxActions={actions}
                forceMobile={false}
                onToggleSearch={(searching) => this.setState({searchingParticipant: searching})}
                width='228'
              />
            }
          >
            {filteredParticipants && 
              <VirtualList
                rowCount={filteredParticipants.length}
                scrollElement={this.modalContentRef}
                dynamicHeight
                rowRenderer={this.renderParticipantCard}
                keyMapper={this.keyMapper}
                forwardListRef={this.forwardListRef}
                list={filteredParticipants}
              />}
          </FieldSet>
        </section>}
      </div>
    );
  };

  onClose = () => {
    this.props.actions.resetEnterClassRequirementModal();
    this.props.actions.clearAdminEventsCacheThreeClassRequirements();
  };

  public render() {
    const {apiSaving, modalState: {ActiveForm, isTextSearching, FilterText, ValidationRules}, filteredClasses, filteredParticipants, cacheTwoEvent, actions, apiLoadingMap, apiLoading, inert} = this.props;

    let selectedClassName = '';

    if (ActiveForm.SelectedClass && 'ClassCode' in ActiveForm.SelectedClass) {
      if (ActiveForm.SelectedClass && ActiveForm.SelectedClass.ClassCode) selectedClassName += `${ActiveForm.SelectedClass.ClassCode} - `;
      if (ActiveForm.SelectedClass && ActiveForm.SelectedClass.IsCombo) selectedClassName += ActiveForm.SelectedClass.ComboName;
      else {
        if (ActiveForm.SelectedClass.ClassTypeIDi) {
          const classType = classTypeSelector(reduxStoreService().getState(), ActiveForm.SelectedClass.ClassTypeIDi);
          if (classType) selectedClassName += classType.Name;
        }
      }
    } else if (ActiveForm.SelectedClass) {
      selectedClassName = ActiveForm.SelectedClass.Name;
    }
    let subtitle = ActiveForm.SelectedClass ? selectedClassName : (cacheTwoEvent.EventsEvent ? cacheTwoEvent.EventsEvent.Name : '');

    const loading = apiLoadingMap[GetAdminEvent.requestType];

    const title = ActiveForm.SelectedClass ? "Update Requirements Completed" : "Select Class";
    return (
      <Modal
        inert={inert}
        className={`${namespace()}`}
        onClose={this.onClose}
        mobileFullScreen
        bodyScrollLock
        big higherZIndex
        height={!!ActiveForm.SelectedClass ? ModalHeight.AUTO : ModalHeight.HEIGHT_560}
        superWideModal={!!ActiveForm.SelectedClass}
      >
        <ModalHeader
          isSearching={!ActiveForm.SelectedClass ? isTextSearching : false}
          className={`${namespace()}--header`}
          onRefresh={!ActiveForm.SelectedClass ? this.onRefreshClick : undefined}
          onSearchOpen={!ActiveForm.SelectedClass ?  this.onSearchOpen : undefined}
          onSearchChange={!ActiveForm.SelectedClass ?  actions.filterClass : undefined}
          onSearchBack={!ActiveForm.SelectedClass ? this.onSearchBack : undefined}
          subtitle={subtitle}
        >{title}</ModalHeader>
        {this.renderFilters()}
        <ModalContent
          hasPadding={!!ActiveForm.SelectedClass}
          refCallback={(ref) => this.modalContentRef = ref}
          paddingLeft={!ActiveForm.SelectedClass ? 0 : undefined}
          paddingRight={!ActiveForm.SelectedClass ? 0 : undefined}
          paddingTop={!ActiveForm.SelectedClass ? 0 : undefined}
          paddingBottom={!ActiveForm.SelectedClass ? 0 : undefined}
        >
          {!ActiveForm.SelectedClass && !!filteredClasses && <div>
            {filteredClasses.map((adminClass) => {
              return (
                <AdminClassCard
                  key={`selected_class__card${adminClass.IDi}`}
                  type={AdminClassCardType.SINGLE_SELECT}
                  category={CardCategory.LIST}
                  adminEventClass={adminClass}
                  onClick={this.onSelectClass}
                />
              );
            })}</div>}
          {!ActiveForm.SelectedClass && !loading && (!filteredClasses || filteredClasses.length === 0) ? this.renderEmptyClasses() : null}
          {this.renderStep2()}
          {loading && <PageLoader className={`${namespace()}--page-loader`}/>}
        </ModalContent>
        {ActiveForm.SelectedClass ? <ModalActions
          noPadding
          sticky
          loading={apiSaving > 0 || apiLoading > 0}
          stacked
          left={<Button id={generateDOMId("save-class-requirements-btn")} disabled={filteredParticipants.length === 0} expand flat textColor="black" onClick={this.onSave}>SAVE</Button>}
        /> : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredClassesSelector = makeFilteredClassesSelector();
  const filteredParticipantsSelector = makeFilteredParticipantsSelector();
  const selectedClassIdSelector = makeFormModalPropSelector(ModalTypes.ENTER_CLASS_REQUIREMENT_COMPLETED, 'selectedClassId');
  const childClassIdSelector = makeFormModalPropSelector(ModalTypes.ENTER_CLASS_REQUIREMENT_COMPLETED, 'childClassId');

  return {
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheZero: state.cacheZero,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    cacheTwoEvent: state.adminEvents.cacheTwoEvent,
    adminEventsCacheThreeClassRequirements: state.adminEvents.cacheThreeClassRequirements,
    modalState: state.adminEvents.events.modals.enterClassRequirement,
    filteredClasses: filteredClassesSelector(state),
    // TODO: Fix this typing from selector
    filteredParticipants: filteredParticipantsSelector(state) as AdminEventClassParticipant[],
    selectedClassId: selectedClassIdSelector(state),
    childClassId: childClassIdSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...appActionCreators,
  ...enterClassRequirementActionCreators,
  ...cacheOneActionCreators,
  ...cacheTwoEventActionCreators,
  ...cacheThreeClassRequirementsActionCreators,
  ...rollbackActionCreators,
}, dispatch)});

const ConnectedEnterClassRequirementModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnterClassRequirementModal);

export default ConnectedEnterClassRequirementModal;
