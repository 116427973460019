import { ClassType } from "./options";

export interface AdminEventsEventRow {
  IDi: number;
  EventName: string;
  StartDate: string;
  Inactive: boolean;
  NumGroupsRegistered: number;
  TS: number;
}

export interface AdminEventCapacity {
  ParticipantTypeID: number;
  MaxParticipants: number;
}

export interface AdminEventPricing {
  PricingTierID: number;
  ParticipantTypeID: number;
  AmountEarly: number;
  AmountBase: number;
  AmountLate: number;
}

export interface AdminEventTimeBlock {
  ID: number;
  StartTime: string;
  EndTime: string;
  ShowTimeBlock: boolean;
  ShowStartTime: boolean;
}

export interface AdminEventOverview {
  NumGroups: number;
  YouthNumbersFinalized: number;
  YouthNamesFinalized: number;
  YouthNamesInCart: number;
  AdultsNumbersFinalized: number;
  AdultsNamesFinalized: number;
  AdultsNamesInCart: number;
  TotalFees: number;
  TotalAmountPaid: number;
}

export interface AdminEventRegistrationStatus {
  ID: number;
  Status: string;
  Description: string;
}

export interface AdminEventPermissions {
  hasAddClass: boolean;
  hasAddClassReason: string | null;
  hasEditClass: boolean;
  hasEditClassReason: string | null;
  hasEnterRequirementsCompleted: boolean;
  hasEnterRequirementsCompletedReason: string | null;
}

export interface AdminEvent {
  IDi: number;
  IDString: string;
  Name: string;
  IsAllDay: boolean;
  StartDate: string;
  EndDate: string;
  RegistrationStartDateNumbers: string;
  RegistrationStartDateNames: string;
  GLAccountID: number;
  GLAccountID_ClassDefault?: number;
  AllowReductionsUntilDate: string;
  YouthDepositDueDate: string;
  YouthDepositAmount: number;
  CampsiteDepositDefaultAmount: number;
  BasePricingStartDate: string;
  LatePricingStartDate: string;
  Inactive: boolean;
  Capacities: AdminEventCapacity[];
  Pricing: AdminEventPricing[];
  TimeBlocks_Settings: {
    NumberOfTimeBlocks: number;
    TimeBlocks: AdminEventTimeBlock[];
  };
  Overview: AdminEventOverview;
  RegistrationStatuses: AdminEventRegistrationStatus[];
  TS: number;
  Permissions: AdminEventPermissions;
}

export type AdminEventChildClass = ClassType & {
  ClassTypeIDi: number;
};

export interface AdminEventClass {
  IDi: number;
  mbID: number | null;
  IsRegisterUnderIndividuals: boolean;
  IsOption: boolean;
  IsUnscheduled: boolean;
  ClassTypeIDi: number;
  IsCombo: boolean;
  ComboName: string;
  ComboClasses: AdminEventChildClass[] | null;
  ClassCode: string;
  TimeBlocksDisplay: string;
  DaysDisplay: string;
  IsYouth: number;
  Inactive: boolean;
  Ord: number;
  IsDuplicate?: boolean;
  selected?: boolean;
}

export interface AdminEventsGroupEventRegistration {
  EventIDi: number;
  GroupIDi: number;
  GroupEventIDi: number;
  GroupName: string;
  RowNum: number;
  selected?: boolean;
}

export interface AdminEventsEventCampsite {
  Capacity: number;
  IDi: number;
  IsOverbooked: boolean;
  Name: string;
  NumAdults: number;
  NumGroups: number;
  NumYouth: number;
}

export interface AdminEventReportsOption {
  ID: number;
  Ord: number;
  Name: string;
  Description: string;
  IsJSONResponse: boolean;
  ReportURL: string;
}

export interface EventsEventDay {
  ID: number;
  Abbrev: string;
  Name: string;
  Ord: number;
}

type AdminEventClassRequirementsBase = {
  ClassIDi: number;
  ClassTypeIDi: number;
  InstructorInitials: string;
  InstructorName: string;
  ParentIDi: number;
};
/**
 * This is the old `AdminEventClassRequirements` type where mbID
 * is not set and `Requirements` is an array of string
 */
type AdminEventClassRequirements_Old = AdminEventClassRequirementsBase & {
  Requirements: string[];
  mbID: null;
};

/**
 * This is the new `AdminEventClassRequirements` where mbID is set
 * AND `Requirements` is an array of number
 */
type AdminEventClassRequirements_MeritBadge =
  AdminEventClassRequirementsBase & {
    mbID: number;
    Requirements: number[];
    VersionYear: number | null;
  };

export const isOldClassRequirements = (
  classRequirements:
    | AdminEventClassRequirements
    | Partial<AdminEventClassRequirements>
): classRequirements is AdminEventClassRequirements_Old => {
  return !classRequirements.mbID;
};

export const isNewClassRequirements = (
  classRequirements:
    | AdminEventClassRequirements
    | Partial<AdminEventClassRequirements>
): classRequirements is AdminEventClassRequirements_MeritBadge => {
  return !!classRequirements.mbID;
};

export type AdminEventClassRequirements =
  | AdminEventClassRequirements_Old
  | AdminEventClassRequirements_MeritBadge;

export type MBRequirement = {
  ID: number;
  Parents: number[];
  Ord: number;
  IsCheckable: boolean;
  req: string;
  reqLevel: number;
  reqTag: string;
  reqText: string;
};

export enum AdminClassParticipantStatus {
  PARTIAL = 1,
  COMPLETED = 2,
  NO_SHOW = 3,
}

export interface AdminEventClassParticipantAttendance {
  label: string;
  checked: boolean;
  status?: 1 | 0 | -1;
}

export type AdminEventClassParticipant = {
  Attend: { Mo: boolean; Tu: boolean; We: boolean; Th: boolean; Fr: boolean };
  AttendArr: AdminEventClassParticipantAttendance[];
  CompletionID: number;
  GroupName: string;
  IDi: number;
  ParticipantName: string;
  Status: AdminClassParticipantStatus;
  ReqsCompletedCSV?: string;
  ActiveForm: any;
  ValidationRules: any;
  Requirements: (string | number)[];
};