import * as React from 'react';
import {Location, ContactInfo as ContactInfoClass} from "../../../models/api/cacheOne";
import {SystemSettings} from "../../../models/api/registerForm";
import {ContactInfo} from '../../Elements';
import {getFullAddress} from '../../../utils';

interface Props {
  contactInfoLabel: string;
  location: Location;
  systemSettings: SystemSettings | undefined;
  defaultFirstOpen?: boolean;
  contactInfo: ContactInfoClass;
  multiple?: boolean;
}

// @todo: move to Elements? we will need to put multiple back in that case
const ContactInfos: React.SFC<Props> = ({systemSettings, contactInfoLabel, contactInfo, location, defaultFirstOpen, multiple}): React.ReactElement<Props> | null => {
  if (!systemSettings) return null;

  return (
    <div>
      {contactInfo.Name || contactInfo.WebsiteURL || contactInfo.Phone || contactInfo.Email ?
        (<ContactInfo
          title={contactInfoLabel}
          contactName={contactInfo.Name}
          website={contactInfo.WebsiteURL}
          phone={contactInfo.Phone}
          email={contactInfo.Email}
          open={defaultFirstOpen}
        />): null}
      {multiple ?
        <ContactInfo
          title="Location Info"
          multipleLocations
        /> : <ContactInfo
        title="Location Info"
        contactName={location.Name}
        address={getFullAddress(location)}
        phone={location.PhoneNumber}
        fax={location.FaxNumber}
      />}
      <ContactInfo
        title={systemSettings.CampName}
        address={getFullAddress(systemSettings)}
        phone={systemSettings.Phone}
        email={systemSettings.FromEmail}
        fax={systemSettings.Fax}
      />
    </div>
  );
};

export default ContactInfos;
