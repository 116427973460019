import * as React from 'react';
import { Text, Icon } from '../../../../Elements';
import { CartIcon } from '../../../../Icons';
import { namespace as ns } from './index';
import '../../../../../styles/pages/events/event/registration/spot.scss';
import {RED_THRESHOLD} from "../constants";
import {EventInfoParticipantType} from "../../../../../models/api/cacheThreeEvents";

export const namespace = (): string => `${ns()}--spot`;

export interface SpotProps {
  pType: EventInfoParticipantType;
  available?: number; // pass undefined if unlimited
  eventType: 'numbers' | 'both';
  cart?: boolean; // @todo: how to determine this?
  eventMaxParticipants: number;
  isLast?: boolean;
}

const Spot: React.SFC<SpotProps> = ({ pType:{Name, NumSpotsPending, NumNamesPending, MaxParticipants}, available, eventType, cart, isLast }: SpotProps): React.ReactElement<SpotProps> => {
  return <div className={`${namespace()} ${isLast ? 'last' : ''}`}>
    <div className={`${namespace()}--info`}>
      <Text size={16} weight="medium" className={`${namespace()}--info--name`}>{Name}</Text>
      {available !== undefined && <Text size={14} color={available < RED_THRESHOLD ? 'red' : 'gray'} className={`${namespace()}--info--spots`}>
        {available !== 0 ? `${available} spot${available === 1 ? '' : 's'} available` : 'FULL'}
      </Text>}
    </div>
    <div className={`${namespace()}--spots`}>
      <Text size={16} weight="medium" className={`${namespace()}--spots--quantity`}>
        {`${NumSpotsPending >= 0 ? NumSpotsPending : 0} spot${NumSpotsPending === 1 ? '' : 's'}`}
      </Text>
      {eventType !== 'numbers' && (
        <Text size={14} weight="medium" color="green" className={`${namespace()}--spots--registered`}>
          {`${NumNamesPending} of ${NumSpotsPending} registered`}
        </Text>
      )}
    </div>
    <div className={`${namespace()}--cart`}>
      {cart ? <Icon size={28} green icon={CartIcon}/> : null}
    </div>
  </div>;
};

export default Spot;
