import { AdminEventsImage } from "./adminEventsCacheOne";

type AdminEventsCacheThreeClassTypeBase = {
  IDi: number;
  Name: string;
  Description: string;
  Inactive: boolean;
  TS: number;
  Color: string;
  FeaturedImage: AdminEventsImage | null;
};

/**
 * This is the old `AdminEventsCacheThreeClassType` type where mbID
 * is not set and `Requirements` is a plain string
 */
export type AdminEventsCacheThreeClassType_Old = AdminEventsCacheThreeClassTypeBase & {
  Requirements: string;
  RequirementsArray: null;
  mbID: null;
};

/**
 * This is the new `AdminEventsCacheThreeClassType` where mbID is set
 * AND `Requirements` is an array of number
 */
export type AdminEventsCacheThreeClassType_MeritBadge =
  AdminEventsCacheThreeClassTypeBase & {
    mbID: number;
    Requirements: null;
    RequirementsArray: number[];
  };
export type AdminEventsCacheThreeClassType =
  | AdminEventsCacheThreeClassType_Old
  | AdminEventsCacheThreeClassType_MeritBadge;

export const isClassType_OldTracking = (
  classType:
    | AdminEventsCacheThreeClassType
    | Partial<AdminEventsCacheThreeClassType>
): classType is AdminEventsCacheThreeClassType_Old => {
  return !Boolean(classType.mbID);
};

export const isClassType_NewTracking = (
  classType:
    | AdminEventsCacheThreeClassType
    | Partial<AdminEventsCacheThreeClassType>
): classType is AdminEventsCacheThreeClassType_MeritBadge => {
  return Boolean(classType.mbID);
};
