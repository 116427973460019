import {
  ApiSubmitActionsProductsProducts,
  ClearError, CommitProductProducts,
  EventRegisterProductsProductAdd, EventRegisterProductsProductRemove,
  EventRegisterProductsProductShowError, ManageProductProducts
} from "./actions";
import {EventRegisterProductsReset} from "../Main/actions";
import {EventProductsCalc} from "../../../../../CacheFourEventsProducts/actions";
import { Reducer } from "redux";
import { isActionType } from "../../../../../../utils/StrongActions";

export interface ProductsWizardProductsState {
  isDirty?: boolean;
  SubmitErrorMessage?: string;
  isError?: boolean;
  managingId?: number;
}

const getInitialState = () => ({});

const ProductsWizardProducts: Reducer<ProductsWizardProductsState> = (state = getInitialState(), act) => {
  if (isActionType(act, ManageProductProducts)) {
    return {
      ...state,
      managingId: (act.product as any).ID
    };
  } else if (act.type === EventProductsCalc.successType || act.type === ApiSubmitActionsProductsProducts.successType) {
    return getInitialState();
  } else if (isActionType(act, EventRegisterProductsProductShowError)) {
    window.scrollTo(0, 0);
    return {...state, SubmitErrorMessage: act.error, isError: act.isError};
  } else if (isActionType(act, EventRegisterProductsReset)) {
    return getInitialState();
  } else if (
    isActionType(act, EventRegisterProductsProductAdd) || isActionType(act, EventRegisterProductsProductRemove) ||
    isActionType(act, CommitProductProducts)
  ) {
    return {...state, isDirty: true};
  } else if (isActionType(act, ClearError)) {
    return {...state, SubmitErrorMessage: undefined};
  }
  return state;
};

export default ProductsWizardProducts;
