import { ActionCreator, ApplicationState } from "../../../..";
import {createApiValidateActions, createValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod} from '../../../../Validation/actionCreator';
import { entityMapper } from "../../../../../utils/dataHelper";
import { AdminFacility, AdminFacilityType } from "../../../../../models/api/adminFacilitiesCacheOne";
import { typeName, Action } from "../../../../../utils/StrongActions";
import { SaveState } from "../../../../Rollback/actions";

export const SUFFIX = '__ADMIN_FACILITIES__MULTIPLE_FACILITIES__MODAL';

@typeName("FILTER" + SUFFIX)
export class MultipleFacilitiesModalFilter extends Action {
  constructor(public filterString: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class MultipleFacilitiesToggleFilterTabs extends Action {
  constructor(public searching: boolean) { super(); }
}

@typeName("RESET" + SUFFIX) export class ResetMultipleFacilitiesModal extends Action {}
@typeName("INIT" + SUFFIX)
export class InitMultipleFacilitiesModal extends Action {
  constructor(public facilityIds: number[], public facilityTypeId?: number) { super(); }
}

@typeName("TOGGLE_FACILITY_TYPE_SELECT" + SUFFIX)
export class ToggleFacilitySelect extends Action {
  constructor(public show: boolean, public facilityId: number) { super(); }
}
@typeName("SELECT_ALL" + SUFFIX)
export class SelectAll extends Action { constructor(public facilityIds: number[]) { super(); }}
@typeName("DESELECT" + SUFFIX)
export class Deselect extends Action { constructor(public facilityIds: number[]) { super(); }}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export type Actions = typeof actionCreators;

export const actionCreators = {
  facilityModalUpdateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminFacilityLocation.facilities.modals.multipleFacilities, true, undefined, true),
  facilityModalSimpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  filterMultipleFacilities: (filterString: string): ActionCreator => dispatch => dispatch(new MultipleFacilitiesModalFilter(filterString)),
  init: (facilityIds: number[]): ActionCreator => (dispatch, getState) => {
    const state = getState();

    const Facilities = state.adminFacilityLocation.cacheOne.Facilities;
    const FacilitiesTypes = state.adminFacilityLocation.cacheOne.FacilitiesTypes;
    let preselectFacilityTypeId;

    if (Facilities) {
      const facilities = entityMapper<AdminFacility>(Facilities, facilityIds, (f) => f.ID);
      const facilityTypeIds: number[] = [];
      facilities
        .filter((f) => !f.Inactive)
        .forEach((f) => {
        if (f && facilityTypeIds.indexOf(f.FacilityTypeID) === -1) facilityTypeIds.push(f.FacilityTypeID);
      });

      if (FacilitiesTypes) {
        const facilityTypes = entityMapper<AdminFacilityType>(FacilitiesTypes, facilityTypeIds, (ft) => ft.ID).filter((ft) => !ft.Inactive);
        if (facilityTypes.length === 1) preselectFacilityTypeId = facilityTypes[0].ID;
      }
    }
    dispatch(new InitMultipleFacilitiesModal(facilityIds, preselectFacilityTypeId));
    dispatch(new SaveState());
  },
  reset: (): ActionCreator => dispatch => dispatch(new ResetMultipleFacilitiesModal()),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new MultipleFacilitiesToggleFilterTabs(searching)),
  toggleFacilitySelect: (show: boolean, facilityId: number): ActionCreator => dispatch => dispatch(new ToggleFacilitySelect(show, facilityId)),
  selectAll: (facilityIds: number[]): ActionCreator => dispatch => dispatch(new SelectAll(facilityIds)),
  deselect: (facilityIds: number[]): ActionCreator => dispatch => dispatch(new Deselect(facilityIds)),
};