// Event Types
export const INVALID_EVENT = 'Event has been deleted. Please restore from list and try again.';
export const INVALID_CLASS = 'Class has been deleted. Please restore from list and try again.';
export const INVALID_CLASS_TYPE = 'Class type has been deleted. Please restore from list and try again.';
export const INVALID_PRODUCT = 'Product has been deleted. Please restore from list and try again.';
export const INVALID_MESSAGE = 'Message has been deleted. Please restore from list and try again.';

export const ADMIN_EVENT = 'event';
export const ADMIN_EVENTS = 'events';

export const ADMIN_EVENT_TYPE = 'event type';
export const ADMIN_PRODUCT = 'product';
export const ADMIN_PRODUCTS = 'products';
export const ADMIN_EMAIL_MESSAGE = 'message';
export const ADMIN_EMAIL_MESSAGES = 'messages';
export const ADMIN_EVENT_CLASS_TYPE = 'class type';
export const ADMIN_EVENT_CLASS_TYPES = 'class types';
export const ADMIN_EVENT_CLASS = 'class';
export const ADMIN_EVENT_CLASSES = 'classes';
export const MERIT_BADGES = "merit badges";
export const CLASS_REQUIREMENTS = 'requirements';

export const DELETED_ADMIN_EVENT_TYPE = 'This Event Type has been deleted. Please Restore to continue working with this Event Type.';

export const NEW_PARTICIPANT_TYPE = 'New Participant Type';