import * as React from 'react';
import {bindActionCreators} from 'redux';
import {Media, ContentBlock, ContactCard} from '../../Elements';
import {actionCreators as eventTypeActionCreators} from "../../../store/Events/EventType/actions";
import {getFullAddress} from "../../../utils/addressHelper";
import {makeSelectedEventTypeSelector} from "../../../store/CacheTwoEvents/index";
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface Props {
  mobile?: boolean
  marginBottom?: number;
  mobileMarginBottom?: number;
}

const EventContactCard: React.SFC<Props> = (props: ConnectedProps & Props): React.ReactElement<Props> => {
  if (!props.selectedEventType) return <div></div>; // @todo: what should I return here? should be loadingAll whenever this is true, so I don't think I care
  const openSidebar = () => props.actions.showSidebar(true);
  return <Media tablet newApi mobile={props.mobile}>
    <ContentBlock marginBottom={props.marginBottom} mobileMarginBottom={props.mobileMarginBottom}>
      <ContactCard
        map={!props.selectedEventType.DefaultLocation.UpcomingEventsAtOtherLocations}
        multiple={props.selectedEventType.DefaultLocation.UpcomingEventsAtOtherLocations}
        onMapClick={openSidebar}
        onContactsClick={openSidebar}
        title={props.selectedEventType.Name}
        fullAddress={getFullAddress(props.selectedEventType.DefaultLocation)}
      />
    </ContentBlock>
  </Media>;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedEventType = makeSelectedEventTypeSelector();
  return {
    eventType: state.events.eventType,
    systemSettings: state.session.SystemSettings,
    selectedEventType: selectedEventType(state)
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({...eventTypeActionCreators}, dispatch)});

const ConnectedEventContactCard = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(EventContactCard);

export default ConnectedEventContactCard;
