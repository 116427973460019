import { HideSnackbarItem, showSnackbarItem, HEADER_FILTER, ShowTopFloatingAlertInQueue } from './actions';
import { API_FAI } from '../../constants/request';
import { Observable } from "rxjs";
import { makeDelayQueueEpic } from '../Validation/epicCreator';

export const hideSnackbar = action$ => (
  action$.ofType(showSnackbarItem)
    .debounceTime(5000)
    .switchMap((action) => (
        Observable.of(new HideSnackbarItem())
    ))
);

export const hideSnackbarApiFail = action$ => (
  action$.filter(action => action.type && action.type.startsWith(API_FAI))
    .debounceTime(5000)
    .switchMap((action) => (
      Observable.of(new HideSnackbarItem())
    ))
);

export const addHeaderFilter = action$ => (
  action$.ofType(HEADER_FILTER)
    .debounceTime(250)
    .switchMap((action) => (
      Observable.of(action.actionFnc(action.filter) )
    ))
);


export const showTopFloatingAlertInQueueEpic = makeDelayQueueEpic(
  ShowTopFloatingAlertInQueue,
  750,
);