import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminCMSCacheOneContext } from "@tentaroo/shared";

import { ApplicationState } from '../../../../../../store';
import {
  actionCreators as cacheZeroActionCreators
} from '../../../../../../store/CacheZero/actions';
import {
  actionCreators as adminCMSCacheOneActionCreators, GetSiteCache,
} from '../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as cacheOneActions} from "../../../../../../store/CacheOne/actions";
import { actionCreators as rollbackActions} from "../../../../../../store/Rollback/actions";
import {actionCreators as pageFormActionCreators} from "../../../../../../store/AdminCMSSite/Pages/Page/Form/actions";
import { actionCreators } from "../../../../../../store/AdminCMSSite/Pages/Home/actions";
import { LoadingAll, Loader } from '../../../../../Elements';
import { Main, MainContent } from '../../../../../Layouts';
import '../../../../../../styles/pages/contacts/home/index.scss';
import PageList from './PageList';
import { makeFilteredPagesSelector, makeActivePagesSelector } from '../../../../../../store/AdminCMSSite/Pages/Home';
import AdminCMSCacheManager from '../../../../../../utils/cacheManagers/adminCMSCacheManager';
import { IAdminCMSRouterParams } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { noOpenedModals } from '../../../../../../utils/modalHelper';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isAdminCMSCacheOnePopulated} from '../../../../../../utils/cachePopulatedCheckers/adminCMS';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--contacts';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminCMSRouterParams, {}>
>;

class Pages extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;

  public componentDidMount() {
    this.props.actions.showAdminPageHeader(true);
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        AdminCMSCacheManager.getInstance().loadAdminCMSCacheOne({
          props: this.props,
          isStateNavigated,
          context: AdminCMSCacheOneContext.PAGES_LIST,
        });
      }
    );
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        AdminCMSCacheManager.getInstance().loadAdminCMSCacheOne({
          props: nextProps,
          isStateNavigated,
          context: AdminCMSCacheOneContext.PAGES_LIST,
        });
      }
    );
  }

  componentWillUnmount() {
  }

  public render() {
    const {actions, adminCMSCacheOne, filterInvalid, apiLoadingMap, apiSaving, apiLoading, pagesHome, filteredPages, activePages, pageFormActions, inert} = this.props;
    if (!isAdminCMSCacheOnePopulated()) {
        return <LoadingAll />;
    }

    const refreshing = apiLoadingMap[GetSiteCache.requestType] && noOpenedModals();
    const saving = apiSaving > 0 && noOpenedModals();

    return (
      <Main inert={inert} isLoading={refreshing} isCMSSite>
        <MainContent className={namespace()} handleCompact>
          <PageList
            adminCMSCacheOne={adminCMSCacheOne}
            actions={actions}
            pageFormActions={pageFormActions}
            apiSaving={apiSaving}
            apiLoading={apiLoading}
            apiLoadingMap={apiLoadingMap}
            pagesHome={pagesHome}
            filteredPages={filteredPages}
            activePages={activePages}
            filterInvalid={filterInvalid}
            context='page-list'
          />
          {saving && <Loader className={`${namespace()}--loader`} center />}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredPagesSelector = makeFilteredPagesSelector();
  const activePagesSelector = makeActivePagesSelector();
  return {
    app: state.app,
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    cacheZero: state.cacheZero,
    cacheOne: state.cacheOne,
    pagesHome: state.adminCMSSite.pages.home,
    filteredPages: filteredPagesSelector(state),
    activePages: activePagesSelector(state),
    filterInvalid: state.adminCMSSite.pages.home.filterInvalid,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheZeroActionCreators,
    ...appActionCreators,
    ...cacheOneActions,
    ...adminCMSCacheOneActionCreators,
    ...rollbackActions,
  }, dispatch),
  pageFormActions: bindActionCreators({
    ...pageFormActionCreators,
  }, dispatch),
});

const ConnectedPages = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(Pages);

export default withRouter<{}>(ConnectedPages);
