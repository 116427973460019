
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetGLAccount} from "./actions";
import { getGLAccountBody, getGLAccount } from "../../../constants/urls";

export const adminSettingsCacheTwoGLAccountEpic = makeRequestEpic(
  GetGLAccount,
  getGLAccount,
  getGLAccountBody,
  true,
);