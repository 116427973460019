import * as FM from "../../../../../constants/messages/facilitiesOverrideFee";
import { Validator } from '../../../../../utils/validator/models';

export interface IValidator {
  FlatRate: Validator;
  PerPersonRate: Validator;
}

export const FormDefinition: IValidator = {
  FlatRate: {
    key: 'FlatRate',
    allowNegative: () => false,
    validatejs: {
      FlatRate: {
        presence: {message: '^' + FM.PER_DAY_REQUIRED},
        numericality: {
          notValid: `^${FM.PER_DAY_INVALID}`
        }
      }
    }
  },
  PerPersonRate: {
    key: 'PerPersonRate',
    allowNegative: () => false,
    validatejs: {
      PerPersonRate: {
        presence: {message: '^' + FM.PER_PERSON_REQUIRED},
        numericality: {
          notValid: `^${FM.PER_PERSON_INVALID}`
        }
      }
    }
  }
};
