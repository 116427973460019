import * as React from 'react';
import { Modal, ModalTitle, ModalContent, ModalActions, Button, Paragraph } from '../../../../../../../Elements';
import {SimplePerson} from "../../../../../../../../store/Events/Event/Register/Participant/Roster";
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../../../../../../../store';
import {
  actionCreators,
} from '../../../../../../../../store/Events/Event/Register/Participant/Roster/actions';
import {actionCreators as appActionCreators} from "../../../../../../../../store/App/actions";
import { ModalTypes } from '../../../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../../../../utils/cypressHelper';
import { makeFormModalPropSelector } from '../../../../../../../../store/App';
import {WithInertAttribute} from '../../../../../../../Elements/WithInert';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type Props = WithInertAttribute<{
  onDelete?: (person: SimplePerson) => void;
}>

class DeletePerson extends React.Component<Props & ConnectedProps,{}> {
  onConfirmDelete = (e) => {
    e.preventDefault();
    const {deletingPerson, actions} = this.props;
    if (deletingPerson) {
      actions.deleteFromRoster(deletingPerson);
    }
  };

  onCancelDelete = (e) => {
    e.preventDefault();
    const {actions} = this.props;
    actions.popModal(false, true, ModalTypes.DELETE_PERSON);
  };

  render() {
    if (!this.props.deletingPerson) return null;

    const {Name} = this.props.deletingPerson;
    return (
      <Modal inert={this.props.inert} disableDynamicHeight big forceMargin>
        <ModalTitle>Remove From Group?</ModalTitle>
        <ModalContent paddingBottom={0} paddingTop={0}>
          <Paragraph color="gray" marginBottom={16}>
            This won't affect any event registrations, this just removes this person from this list of people that are options for new event registrations.
          </Paragraph>
        </ModalContent>
        <ModalActions noPadding stacked>
          <Button id={generateDOMId("close-remove-from-group-modal")} style={{flex: "1 1 auto"}} flat onClick={this.onCancelDelete}>KEEP {Name.toUpperCase()}</Button>
          <Button style={{flex: "1 0 auto"}} flat onClick={this.onConfirmDelete} textColor="red">REMOVE FROM GROUP</Button>
        </ModalActions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectDeletingPerson = makeFormModalPropSelector(ModalTypes.DELETE_PERSON, "deletingPerson");
  return {
    deletingPerson: selectDeletingPerson(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actionCreators, ...appActionCreators }, dispatch)
});

const ConnectedDeletePerson = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(DeletePerson);

export default ConnectedDeletePerson;
