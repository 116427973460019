import type {ActionCreator, ApplicationState} from '../../../';
import {
  createApiSubmitActions, createApiSubmitFormMethod,
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createValidateActions
} from "../../../Validation/actionCreator";
import {validate} from "../../../../utils/validator";
import {SaveState} from "../../../Rollback/actions";
import {FinanceForm} from "./index";
import {getUpdateTripPaymentBody} from "../../../../constants/urls";
import {CacheThreeFacilitiesState} from "../../../CacheThreeFacilities";
import {FullGroup} from "../../../../models/api/options";
import {CacheTwoFacilitiesState} from "../../../CacheTwoFacilities";
import {getTotalAmount, rollbackIfClickOut} from "../../../../utils/helpers/financialSummaryHelper";
import { typeName, Action } from '../../../../utils/StrongActions';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../utils/dataHelper';
import { Validator } from '../../../../utils/validator/models';

export const SUFFIX = '__TRIP_SUMMARY';

// todo: this should probably be a fragment
@typeName("TRIP_SUMMARY_SELECT_FINANCE")
export class TripSummarySelectFinance extends Action {
  constructor(public option: 'min' | 'full' | 'other' | 'credit') { super(); }
}
@typeName("TRIP_SUMMARY_FINANCE_DIRTY")
export class TripSummaryFinanceDirty extends Action {}
@typeName("TRIP_SUMMARY_OTHER_NOT_RECENTLY_SELCETED")
export class TripSummaryOtherNotRecentlySelected extends Action {}
@typeName("TRIP_SUMMARY_SAVE_FINANCE")
export class TripSummarySaveFinance extends Action {
  constructor(public value: number) { super(); }
}
@typeName("TRIP_SUMMARY_RESET")
export class TripSummaryReset extends Action {}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const FacFSSubmitActions = createApiSubmitActions(SUFFIX);

export type Actions = typeof actionCreators;

export const formCreator = (rootState: ApplicationState, activeForm: FinanceForm) => {
  let debugPayload: TentarooDebugPayload | undefined;
  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when submting TripSummary form");
  }
  const c2 = rootState.cacheTwoFacilities as CacheTwoFacilitiesState;
  const c3 = rootState.cacheThreeFacilities as CacheThreeFacilitiesState;
  const Group = rootState.cacheZero.options?.Group as FullGroup;

  let newAmount: undefined | number = getTotalAmount(
    activeForm,
    c3.FacilityTrip!
  );
  if (newAmount === undefined) {
    debugPayload = captureTentarooErrorAndGetRequestPayload(`Unable to get total amount. - TripSummary`);
  }
  let TotalAmountChange = newAmount === undefined ? undefined : (newAmount - c3.FacilityTrip!.AmountInCart);

  const payload = getUpdateTripPaymentBody({
    GroupIDi: Group.IDi,
    GroupTS: Group.TS,
    FacilityLocationID: c2.locationID,
    FacilityTripID: c3.tripID,
    TotalAmountChange: TotalAmountChange as any
  });

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

const shouldSave = (value: any, vObj: Validator) => {
  // if (vObj.key === 'selectedFinance') {
  //   if (value === 'min' || value === 'full' || value === 'credit') return true;
  //   return !!reduxStoreService().getState().facilities.trip.summary.ActiveForm.otherValue;
  // } else if (vObj.key === 'otherValue') {
  //   return false;
  // }
  return false;
};

export const actionCreators = {
  resetTripSummary: (): ActionCreator => dispatch => dispatch(new TripSummaryReset()),
  financeDirty: (): ActionCreator => dispatch => dispatch(new TripSummaryFinanceDirty()),
  otherNotRecentlySelected: (): ActionCreator => dispatch => dispatch(new TripSummaryOtherNotRecentlySelected()),
  updateValue: (value: any, vObj: Validator): ActionCreator => (dispatch, getState) => {
    const shouldSaveState = shouldSave(value, vObj);
    if (vObj.key === 'selectedFinance') {
      if (value === 'other') {
        document.addEventListener('mousedown', rollbackIfClickOut);
      } else {
        document.removeEventListener('mousedown', rollbackIfClickOut);
      }
    } else if (vObj.key === 'otherValue') {
      document.addEventListener('mousedown', rollbackIfClickOut);
    }

    if (shouldSaveState) dispatch(new SaveState());
    dispatch(ValidateActions.updateCurr(value, vObj.key, vObj));
    let validationResults = validate(vObj, value, (s) => s.facilities.trip.summary);
    dispatch(ValidateActions.update(validationResults, vObj.key));
    if (value !== 'other' && Object.keys(validationResults.Errors).length === 0) {
      dispatch(FacFSSubmitActions.request(formCreator(getState(), getState().facilities.trip.summary.ActiveForm), null));
    }

  },
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  saveFinance: createApiSubmitFormMethod(FacFSSubmitActions, (s) => s.facilities.trip.summary, formCreator, true, true, undefined, true, () => document.removeEventListener('mousedown', rollbackIfClickOut)),
};

