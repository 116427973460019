import {
  getFacilityAvailabilitiesUrl,
  getFacilityAvailabilityBody,
  getFacilityLocationBody,
  getFacilityLocationUrl
} from '../../constants/urls';
import {makeDebounceEpic, makeRequestEpic} from "../Validation/epicCreator";
import {GetFacilityAvailabilities, GetFacilityLocation} from "./actions";

export const cacheTwoFacilitiesEpic = makeRequestEpic(
  GetFacilityLocation,
  getFacilityLocationUrl,
  getFacilityLocationBody,
  true
);

export const cacheTwoFacilitiesAvailabilitiesEpic = makeDebounceEpic(
  GetFacilityAvailabilities,
  getFacilityAvailabilitiesUrl,
  getFacilityAvailabilityBody
);
