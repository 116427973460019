
import { DATE_PICKER_FORMAT } from "@tentaroo/shared";

import * as M from '../../../../../constants/messages/generic';
import { Validator } from "../../../../../utils/validator/models";

export interface IValidator {
  IsAllRegisteredGroups: Validator;
  BillEntireBalance: Validator;
  IncludeNoBalanceDue: Validator;
  DueDate: Validator;
  InvoiceNotes: Validator;
  BillAmountPerYouth: Validator;
  SelectedGroups: Validator;
}

export const FormDefinition: IValidator = {
  IsAllRegisteredGroups: {
    key: 'IsAllRegisteredGroups',
    defaultValue: () => true,
  },
  BillEntireBalance: {
    key: "BillEntireBalance",
    defaultValue: () => true,
  },
  IncludeNoBalanceDue: {
    key: 'IncludeNoBalanceDue',
    defaultValue: () => false,
  },
  DueDate: {
    key: 'DueDate',
    isRequired: () => false,
    validatejs: {
      DueDate: {
        datetime: {
          notValid: `^Must be ${DATE_PICKER_FORMAT}`,
        }
      },
    },
  },
  InvoiceNotes: {
    key: 'InvoiceNotes',
    isRequired: () => false,
    defaultValue: () => '',
  },
  BillAmountPerYouth: {
    key: 'BillAmountPerYouth',
    defaultValue: () => 0,
    decimalOnly: true,
    skip: (rootState) => {
      return !!rootState.adminEvents.events.modals.generateInvoices.ActiveForm.BillEntireBalance;
    },
    isRequired: () => true,
    validatejs: {
      BillAmountPerYouth: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
          notValid: '^' + M.INVALID,
        }
      },
    },
  },
  SelectedGroups: {
    key: 'SelectedGroups',
    skip: (rootState) => {
      return !!rootState.adminEvents.events.modals.generateInvoices.ActiveForm.IsAllRegisteredGroups;
    },
    isRequired: (rootState) => {
      return !rootState.adminEvents.events.modals.generateInvoices.ActiveForm.IsAllRegisteredGroups;
    },
    customValidate: (rootState) => {
      const modal = rootState.adminEvents.events.modals.generateInvoices;
      
      if (!modal.ActiveForm.IsAllRegisteredGroups) {
        if (!modal.ActiveForm.SelectedGroups || modal.ActiveForm.SelectedGroups.length === 0) {
          return M.REQUIRED;
        }
      }
    },
    validatejs: {
      SelectedGroups: {
        presence: {message: '^' + M.REQUIRED},
      },
    },
  },
};