import { FacFSSubmitActions } from './actions';
import { getUpdateTripPayment} from '../../../../constants/urls';
import { makeRequestEpic } from '../../../Validation/epicCreator';

export const tripSummarySaveFinanceEpic = makeRequestEpic(
  FacFSSubmitActions,
  getUpdateTripPayment,
  undefined,
  true
);
