import * as React from 'react';
import '../../../../styles/elements/group/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton, Vignette, Checkbox} from '../../index';
import { CardCategory } from '../../Card';
import { AdminEventsGroupEventRegistration } from '../../../../models/api/adminEventsCacheTwoEvent';
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--group--card';

export enum GroupCardType {
  MULTIPLE_SELECT='multiple-select',
} 

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin' | 'info' | 'mobile-full';
  type: GroupCardType;
  category: CardCategory;
  noBoxShadow?: boolean;
  selected?: boolean;
  group: AdminEventsGroupEventRegistration;
  onMultipleSelect?: (group: AdminEventsGroupEventRegistration) => void;
  disabled?: boolean;
  routes?: any;
  checked?: boolean;
}


class GroupCard extends React.Component<Props> {
  onCardClick = (e) => {
    const {type, group, disabled} = this.props;

    if (disabled) return;

    switch (type) {
      case GroupCardType.MULTIPLE_SELECT:
        this.props.onMultipleSelect && this.props.onMultipleSelect(group);
        break;
    }
  };

  onMultipleSelectClick = (e) => {
    const {onMultipleSelect, group} = this.props;
    !this.props.disabled && onMultipleSelect && onMultipleSelect(group);
  };

  renderContent = () => {
    const { group, category,  disabled, type } = this.props;
    return (
      <div className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <div className={`${namespace()}--main--text--title`}>{group.GroupName}</div>
        </div>
      </div>
    );
  };

  render() {
    const {category, noBoxShadow, template, checked, group, type, routes} = this.props;
    let className = attribute(namespace(), undefined, this.props);

    className += ` ${type}`;

    return (
      <Card
        noBoxShadow={noBoxShadow}
        className={className}
        component={<section/>}
        template={template || 'mobile-no-margin'}
        category={category}
        padding="none"
      >
        <div id={generateDOMId(`group-${group.GroupIDi}`)} className={`${namespace()}--wrapper`} onClick={this.onCardClick}>
          {type === GroupCardType.MULTIPLE_SELECT ? 
            <Checkbox
              selected={!!checked}
              value={!!checked}
              onChange={this.onMultipleSelectClick}
            /> : null}
          {this.renderContent()}
        </div>
      </Card>
    );
  }
}

export default GroupCard;