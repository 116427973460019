import { AnyAction, Reducer } from "redux";
import { createSelector } from "reselect";
import { ApplicationState } from "../../../..";
import { isActionType } from "../../../../../utils/StrongActions";
import { makeFormModalPropSelector } from "../../../../App";
import { apiValidationReducerCreator } from "../../../../Validation/reducerCreator";
import {
  Deselect,
  SUFFIX,
  ToggleRequirement,
} from "./actions";
import { FormDefinition, IValidator } from "./validation";
import { ModalTypes } from "../../../../../utils/modalHelper";
import { MBRequirement } from "../../../../../models/api/adminEventsCacheTwoEvent";
import { PushModal } from "../../../../App/actions";
import { WithSelected } from "../../../../../models/helpers";
import { classRequirementsSelector } from "../../../CacheThreeClassRequirements";
import {setDefaults} from "../../../../../utils/validator";
import { WithRootState } from "../../../../Validation/actionCreator";

export type ManageRequirementsModalActiveForm = {
  RequirementTypeID?: number;
  selectedRequirements?: number[] | null;
};
export type ManageRequirementsModalState = {
  isRefreshing: boolean;
  ActiveForm: ManageRequirementsModalActiveForm;
  ValidationRules: IValidator;
};

const getInitialState = (): ManageRequirementsModalState => ({
  isRefreshing: false,
  ValidationRules: { ...FormDefinition },
  ActiveForm: {
    RequirementTypeID: 0,
    selectedRequirements: [],
  },
});

const selectedRequirementsSelector = (state: ApplicationState) =>
  state.adminEvents.events.modals.manageRequirements.ActiveForm
    .selectedRequirements;
const requirementsFilterSelector = (state: ApplicationState) =>
  state.adminEvents.events.modals.manageRequirements.ActiveForm
    .RequirementTypeID;

const withSelected = (
  req: MBRequirement,
  selectedRequirements: number[] | null | undefined
): WithSelected<MBRequirement> => {
  const selected = selectedRequirements?.find((reqId) => reqId === req.ID);
  return {
    ...req,
    selected: Boolean(selected),
  };
};
export const makeFilteredMBRequirementsSelector = () => {
  return createSelector(
    [
      makeFormModalPropSelector(
        ModalTypes.MANAGE_REQUIREMENTS,
        "allRequirements"
      ),
      selectedRequirementsSelector,
      requirementsFilterSelector,
      classRequirementsSelector,
    ],
    (
      allRequirements,
      selectedRequirements,
      RequirementTypeID,
      // We exclude `string[]` type here because we know only the `number[]` case
      // is used within this selector
      classRequirements: number[] | undefined,
    ): WithSelected<MBRequirement>[] => {
      if (RequirementTypeID === 1) {
        if (!allRequirements) return [];
        // no filter
        return allRequirements.map((req) =>
          withSelected(req, selectedRequirements)
        );
      } else {
        // Hierarchical filtering - when a requirement is selected,
        // need to include all its parent up to the first level as well

        const finalFiltered: WithSelected<MBRequirement>[] = [];

        // First find all selected requirements
        const filteredRequirements = !allRequirements ? undefined : allRequirements
          .map((req) => withSelected(req, selectedRequirements))
          .filter((req) => {
            return !!req.selected || classRequirements?.find((classRequirementID) => classRequirementID === req.ID);
          });

        // For each selected requirement, find all its parents and put
        // them in `finalFiltered` with `selected` field.
        // NOTE: Only include the same parent once
        filteredRequirements?.forEach((childReq) => {
          const Parents = childReq.Parents;

          Parents && Parents.filter((parentID) => {
            // filter out any included parents
            const existed = finalFiltered.find(
              (final) => final.ID === parentID
            );

            return !Boolean(existed);
          }).forEach((parentID) => {
            const parentRequirement = allRequirements?.find(
              (req) => req.ID === parentID
            );

            if (parentRequirement)
              finalFiltered.push({...parentRequirement, selected: false});
          });

          finalFiltered.push(childReq);
        });

        return finalFiltered;
      }
    }
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);
const SelectMeritBadgeModalReducer: Reducer<ManageRequirementsModalState> = (
  s,
  act: WithRootState<AnyAction>,
) => {
  const state = checkApiValidation(s, act);
  if (
    isActionType(act, PushModal) &&
    act.modal === ModalTypes.MANAGE_REQUIREMENTS
  ) {
    const {manageRequirementsContext} = act.props!;
    return {
      ...setDefaults(act.rootState, getInitialState()),
      ActiveForm: {
        // NOTE: When open from ClassType form, default to no-filter, otherwise, default to filter
        RequirementTypeID: manageRequirementsContext === "class-type" ? 1 : 0,
        selectedRequirements: act.props?.selectedRequirements,
      },
    };
  } else if (isActionType(act, ToggleRequirement)) {
    const selectedRequirements = [...state.ActiveForm.selectedRequirements];
    const index = selectedRequirements.findIndex(
      (reqID) => reqID === act.reqID
    );

    if (index !== -1) {
      selectedRequirements.splice(index, 1);
      return {
        ...state,
        ActiveForm: {
          ...state.ActiveForm,
          selectedRequirements: [...selectedRequirements],
        },
      };
    } else {
      return {
        ...state,
        ActiveForm: {
          ...state.ActiveForm,
          selectedRequirements: [...selectedRequirements, act.reqID],
        },
      };
    }
  } else if (isActionType(act, Deselect)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        selectedRequirements: [],
      },
    };
  }
  return state || getInitialState();
};

export default SelectMeritBadgeModalReducer;
