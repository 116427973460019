import * as React from 'react';
import { namespace as ns } from '../constants';
import Header from '../Header';
import '../../../../styles/elements/header/amount/index.scss';

export const namespace = (): string => `${ns()}--amount`;

export interface AmountProps {
  children?: React.ReactNode;
  className?: string;
  label: string;
  amount: string;
  mobile?: boolean;
  tablet?: boolean;
}

const Amount: React.SFC<AmountProps> = ({className, label, amount, children, mobile, tablet}: AmountProps): React.ReactElement<AmountProps> => {
  let desktop = false;
  if (!mobile && !tablet) {
    mobile = true;
    tablet = true;
  }
  return (
    <Header mobile={mobile} tablet={tablet} desktop={desktop} className={namespace() + (className ? ` ${className}` : '')}>
      <div className={`${namespace()}--amount`}>
        <span className={`${namespace()}--amount--label`}>{label}</span>
        <span className={`${namespace()}--amount--amount` + (children ? '' : ' expand')}>{amount}</span>
      </div>
      {children ? <div className={`${namespace()}--wrapper`}>{children}</div> : null}
    </Header>
  );
};

export default Amount;
