import { ActionCreator, ApplicationState } from "../../../..";
import { createValidateActions, createApiValidateActions, createUpdateValueMethod } from "../../../../Validation/actionCreator";
import { typeName, Action } from "../../../../../utils/StrongActions";

export const SUFFIX = '__EVENT_TYPES_MODAL';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

@typeName("FILTER" + SUFFIX)
export class EventTypesModalFilter extends Action {
  constructor(public filterString: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class EventTypesToggleFilterTabs extends Action {
  constructor(public searching: boolean) { super(); }
}

@typeName("RESET" + SUFFIX) export class ResetSelectEventTypesModal extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.modals.selectEventTypes),
  filterEventTypes: (filterString: string): ActionCreator => dispatch => dispatch(new EventTypesModalFilter(filterString)),
  resetEventTypesModal: (): ActionCreator => dispatch => dispatch(new ResetSelectEventTypesModal()),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new EventTypesToggleFilterTabs(searching)),
};