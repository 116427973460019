import * as React from 'react';
import { generateDOMId } from '../../../../../../../utils/cypressHelper';
import { ContextMenu, ContextMenuItem } from '../../../../../../Elements';
import { Props as ContextMenuProps } from '../../../../../../Elements/ContextMenu/index';

interface Props {
  onDelete: () => void;
  personIDi: number;
}

// @todo: this to should be onClick to open modal instead
const ContextMenuComponent: React.SFC<ContextMenuProps & Props> = (props: ContextMenuProps & Props): React.ReactElement<ContextMenuProps & Props> => (
  <ContextMenu
    {...props}
    title="Participant options"
    children={(
      <ContextMenuItem id={generateDOMId(`person-${props.personIDi}-remove-from-group`)} onClick={props.onDelete}>Remove from my group</ContextMenuItem>
    )}
  />
);

export default ContextMenuComponent;
