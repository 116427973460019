import { ApplicationState } from "../../../../../store";
import { apiValidationReducerCreator } from "../../../../../store/Validation/reducerCreator";
import { isActionType } from "../../../../../utils/StrongActions";
import { AnyAction, Reducer } from "redux";
import { createSelector } from "reselect";
import {
  SelectMeritBadgeModalFilter,
  SelectMeritBadgeToggleFilterTabs,
  SUFFIX,
} from "./actions";
import { FormDefinition, IValidator } from "./validation";
import { allMeritBadgesSelector } from "../../../../CacheZero";
import { PushModal } from "../../../../App/actions";
import { ModalTypes } from "../../../../../utils/modalHelper";
import { MeritBadge } from "../../../../../models/api/options";
import { WithRootState } from "../../../../Validation/actionCreator";

export type SelectMeritBadgeModalActiveForm = {};
export type SelectMeritBadgeModalState = {
  isRefreshing: boolean;
  filterString: string;
  isTextSearching: boolean;
  ActiveForm: SelectMeritBadgeModalActiveForm;
  ValidationRules: IValidator;
};

const getInitialState = (): SelectMeritBadgeModalState => ({
  isRefreshing: false,
  filterString: "",
  isTextSearching: false,
  ValidationRules: { ...FormDefinition },
  ActiveForm: {},
});

const filterSelector = (state: ApplicationState) =>
  state.adminEvents.events.modals.selectMeritBadge.filterString;

const meritBadgeFilterMatch = (meritBadge: MeritBadge, filter: string) => {
  const f = filter.toLowerCase();

  if (!f || f === '') return true;

  const nameMatches = meritBadge.Name && meritBadge.Name.toLowerCase().includes(f);

  return nameMatches;
};
export const makeFilteredMeritBadgesSelector = () => {
  return createSelector(
    [allMeritBadgesSelector, filterSelector],
    (meritBadges, filter) => {
      return meritBadges.filter(
        (meritBadge) => !!meritBadge && !meritBadge.Inactive && meritBadgeFilterMatch(meritBadge, filter)
      );
    }
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);
const SelectMeritBadgeModalReducer: Reducer<SelectMeritBadgeModalState> = (
  s,
  act: WithRootState<AnyAction>,
) => {
  const state = checkApiValidation(s, act);
  if (isActionType(act, PushModal) && act.modal === ModalTypes.SELECT_MERIT_BADGE) {
    return getInitialState();
  } if (isActionType(act, SelectMeritBadgeToggleFilterTabs)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, SelectMeritBadgeModalFilter)) {
    return {
      ...state,
      filterString: act.filterString,
    };
  }
  return state || getInitialState();
};

export default SelectMeritBadgeModalReducer;
