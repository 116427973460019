import * as React from 'react';
import {Button, Modal, ModalActions, ModalContent, Text, ModalHeader} from '../../../Elements';
import {actionCreators} from "../../../../store/Checkout/actions";
import {actionCreators as appActionCreators} from "../../../../store/App/actions";
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../../../store';
import {captureTentarooError} from "../../../../utils/dataHelper";
import { ModalTypes } from '../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';
import {WithInertAttribute} from '../../../Elements/WithInert';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
export type Props = WithInertAttribute<{}>

class FullClassesModal extends React.Component<Props & ConnectedProps, {}> {
  componentWillUnmount() {
  }

  onClose = () => {
    this.props.actions.popModal(false, true, ModalTypes.FULL_CLASSES);
  };

  render() {
    const {inert, checkout: {fullClassesErrorMessage, fullClassesUrl}} = this.props;

    if (!fullClassesUrl) {
      captureTentarooError(new Error("fullClassesUrl is not available when opening FullClassesModal"));
      return null;
    }

    if (!fullClassesErrorMessage) {
      captureTentarooError(new Error("fullClassesErrorMessage is not available when opening FullClassesModal"));
      return null;
    }
    return (
      <Modal inert={inert} onClose={this.onClose} big mobileFullScreen>
        <ModalHeader>Checkout Errors</ModalHeader>
        <ModalContent scrollable>
          <Text>{fullClassesErrorMessage}</Text>
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          right={<Button flat onClick={() => {
            window.open(fullClassesUrl);
            this.onClose();
          }}>Open Error Report</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    checkout: state.checkout,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actionCreators, ...appActionCreators }, dispatch)
});

const ConnectedFullClassesModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(FullClassesModal);

export default ConnectedFullClassesModal;
