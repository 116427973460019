import {SaveState} from "../../store/Rollback/actions";
import {
  FAC_TRIP_ADD,
  FAC_TRIP_RESERVATION,
  FAC_TRIP_RESERVATION_EDIT,
  getFacilityReservationInitBody,
} from "../../constants/urls";
import {replace} from "react-router-redux";
import { shouldCloseWizardOnLoad } from "./helpers/blockers";
import { getGroupID } from "../helpers/endUserPageHelper";
import { ApplicationState } from '../../store';
import { FacilitiesCalc } from '../../store/CacheFourFacilities/actions';
import { captureTentarooError } from '../dataHelper';
import { reduxStoreService } from "../../store/service";
import { LoadEndUserCacheFourFacilityParams } from "./helpers/models";
import {isCacheFourFacilitiesPopulated, isCacheThreeFacilitiesPopulated, isEndUserCacheOnePopulated, isEndUserCacheTwoFacilitiesPopulated} from "../cachePopulatedCheckers/endUser";

function redirectToCacheThree(currentPath: string, currentQuery: string) {
  if (currentPath.includes(`/${FAC_TRIP_RESERVATION}/${FAC_TRIP_ADD}`)) {
    // Derive `END_USER_TRIP_SUMMARY_PATH` from `FACILITY_WIZARD_PATH` (add case)
    reduxStoreService().dispatch(replace({
      pathname: currentPath.split(`/${FAC_TRIP_RESERVATION}/${FAC_TRIP_ADD}`)[0],
      query: currentQuery,
    }));
  } else if (currentPath.includes(`/${FAC_TRIP_RESERVATION}`) && currentPath.includes(`/${FAC_TRIP_RESERVATION_EDIT}`)) {
    // Derive `END_USER_VIEW_RESERVATION_PATH` from `FACILITY_WIZARD_EDIT_PATH` (edit case)
    reduxStoreService().dispatch(replace({
      pathname: currentPath.split(`/${FAC_TRIP_RESERVATION_EDIT}`)[0],
      query: currentQuery
    }));
  }
}

export const ensureCacheFourFacilities = (
  params: LoadEndUserCacheFourFacilityParams,
): boolean => {
  const {props, isStateNavigated} = params;

  if (shouldCloseWizardOnLoad(props.location)) {
    redirectToCacheThree(props.location.pathname, props.location.query);
    return false;
  }

  /**
   * NOTE: cache four loaders will never get triggered on direct visit,
   * and can only get triggered by in-app navigation. That's said, we
   * can assume these ids from urls are already validated from parent caches
   */
  const locId = Number(props.params.locId);
  const tripId = Number(props.params.tripId);
  const resId = props.params.resId ? Number(props.params.resId) : undefined;

  const rootState = reduxStoreService().getState() as ApplicationState;
  const {cacheZero, cacheOne, cacheTwoFacilities, cacheThreeFacilities, cacheFourFacilities} = rootState;

  // @todo: if any of the errors below happen I should probably redirect like above
  if (cacheTwoFacilities.locationID !== 0 && locId !== cacheTwoFacilities.locationID) {
    captureTentarooError(new Error('facilities wizard accessed without correct location ID'));
    return false;
  } else if (cacheThreeFacilities.tripID !== 0 && tripId !== cacheThreeFacilities.tripID) {
    captureTentarooError(new Error('facilities wizard accessed without correct trip ID'));
    return false;
  }

  let requestedData = false;
  if (isCacheFourFacilitiesPopulated(cacheFourFacilities)) {
    if (isStateNavigated) {
      reduxStoreService().dispatch(new SaveState());
    }
  } else {
    const groupID = getGroupID(props);
    if (
      !groupID ||
      !cacheZero.options ||
      !cacheZero.options.Group ||
      !isEndUserCacheOnePopulated(cacheOne) ||
      !isEndUserCacheTwoFacilitiesPopulated(cacheTwoFacilities) ||
      !isCacheThreeFacilitiesPopulated(cacheThreeFacilities)
    ) {
      const route = reduxStoreService().getState().routing.locationBeforeTransitions;

      redirectToCacheThree(route.pathname, route.query);
    } else {
      let typeId: number | undefined = 0;
      if (resId) {
        const found = cacheThreeFacilities.TripReservations?.find((res) => res.ID === resId);
        if (!found) {
          captureTentarooError(new Error('Could not find reservation when loading cache 4 edit, should be impossible'));
          return false;
        }
        typeId = found.Type.ID;
      } else {
        if (!cacheTwoFacilities.FacilityLocation_FacilityTypes || cacheTwoFacilities.FacilityLocation_FacilityTypes.length === 0) {
          captureTentarooError(new Error('cacheTwoFacilities missing FacilityLocation_FacilityTypes when loading cache 4 edit, should be impossible'));
          return false;
        }
        typeId = cacheTwoFacilities.FacilityLocation_FacilityTypes[0].ID;
      }
      reduxStoreService().dispatch(
        FacilitiesCalc.request(
          getFacilityReservationInitBody({
            GroupIDi: groupID,
            GroupTS: cacheZero.options.Group.TS,
            FacilityLocationID: locId,
            FacilityTripID: tripId,
            FacilityTypeID: typeId,
            ReservationID: resId
          }),
        ),
      );

      requestedData = true;
    }
  }

  return requestedData;
};