
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import { AdminCMSCacheTwoResourceContext } from "@tentaroo/shared";

import {
  Modal, ModalHeader, ModalContent, ModalActions, Button, Loader
} from '../../../../../../Elements';
import '../../../../../../../styles/pages/resources/modals/resource/index.scss';
import { actionCreators } from '../../../../../../../store/AdminCMSSite/Resources/Resource/Form/actions';
import { ResourceFormModalNamespace as namespace } from '../../../../../../../utils/modalHelper';
import { actionCreators as adminCMSCacheTwoResourcesActionCreators } from '../../../../../../../store/AdminCMSSite/CacheTwoResources/actions';
import { actionCreators as adminCMSCacheOneActionCreators } from '../../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../../../store/App/actions';
import { ApplicationState } from '../../../../../../../store';
import { makeSelectedResourceCategorySelector } from '../../../../../../../store/AdminCMSSite/Resources/Resource/Form';
import Resource from '../../Resource/Form';
import '../../../../../../../styles/pages/resources/modals/resource-form/index.scss';
import { INVALID_RESOURCE } from '../../../../../../../constants/messages/adminCMS';
import { checkResourcePermission, IAdminCMSResourceRouterParams } from '../../../../../../../utils/helpers/adminCMSPageHelper';
import { makeFormModalPropSelector } from '../../../../../../../store/App';
import { ModalTypes, isModalOpened } from '../../../../../../../utils/modalHelper';
import AdminCMSCacheManager from '../../../../../../../utils/cacheManagers/adminCMSCacheManager';
import { generateDOMId } from '../../../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { reduxStoreService } from '../../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../../Templates/ComponentUpdateTemplate';
import {isAdminCMSCacheTwoResourcePopulated} from '../../../../../../../utils/cachePopulatedCheckers/adminCMS';
import {WithInertAttribute} from '../../../../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminCMSResourceRouterParams, {}>
>;

@(withRouter as any)
class ResourceFormModal extends ComponentUpdateTemplate<ConnectedProps, {}> {

  public props: ConnectedProps;

  componentDidMount() {
    super.loadAndSetData(
      this.props,
      () => {
        AdminCMSCacheManager.getInstance().loadCacheTwoResource({
          props: this.props,
          isStateNavigated: true,
          isEdit: !!this.props.resourceId,
          context: AdminCMSCacheTwoResourceContext.RESOURCE_FORM,
          resourceIdFromModalProps: this.props.resourceId,
        });
      }
    );
  }

  componentWillUnmount() {
    this.onClose();
  }

  onResourceInactive() {
    const {actions} = this.props;

    actions.showTopFloatingAlert(INVALID_RESOURCE, true, 'orange');
  }
  onSave = () => {
    checkResourcePermission(
      () => {
        this.props.actions.apiSubmitForm(this.props.routes);
      },
      this.props.cacheZero,
      this.props.adminCMSCacheOne,
      !!this.props.resourceId && this.props.adminCMSCacheTwoResources.CMSResource ? this.props.adminCMSCacheTwoResources.CMSResource.SiteID : undefined,
      true,
    );
  };

  onClose = () => {
    if (isAdminCMSCacheTwoResourcePopulated(reduxStoreService().getState().adminCMSSite.cacheTwoResources)) {
      this.props.actions.clearAdminCMSCacheTwoResource();
    }
  };

  public render() {
    const { inert, resourceId, selectedResourceCategory, adminCMSCacheOne, isResourceFormModalOpened, apiSaving, adminCMSCacheTwoResources, actions, resourceForm} = this.props;

    const title = this.props.resourceId ? 'Edit Resource' : 'New Resource';
    const loading = !!resourceId && !isAdminCMSCacheTwoResourcePopulated(adminCMSCacheTwoResources, resourceForm.ActiveForm);
    const inactive = !!resourceId && (adminCMSCacheTwoResources.CMSResource && adminCMSCacheTwoResources.CMSResource.Inactive);

    return (
      <Modal
        inert={inert}
        inactive={inactive}
        onInactive={() => this.onResourceInactive()}
        wideModal
        big mobileFullScreen
        className={namespace()}
        onClose={this.onClose}
      >
        <ModalHeader className={`${namespace()}--header`}>{title}</ModalHeader>
        <ModalContent hasPadding>
          {!loading && <Resource
            inert={inert}
            ActiveForm={resourceForm.ActiveForm}
            ValidationRules={resourceForm.ValidationRules}
            adminCMSCacheOne={adminCMSCacheOne}
            resource={adminCMSCacheTwoResources.CMSResource}
            isResourceFormModalOpened={isResourceFormModalOpened}
            reduxActions={actions}
            SubmitErrorMessage={resourceForm.SubmitErrorMessage}
            disabled={apiSaving > 0 }
            action={!!resourceId ? 'edit' : 'add'}
            hideFormAction
            selectedResourceCategory={selectedResourceCategory}
          />}
          {loading && <Loader className={`${namespace()}--loader`} center />}
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button id={generateDOMId("resource-form-modal-save-btn")} textColor='black' disabled={loading || apiSaving > 0} flat onClick={this.onSave}>{this.props.resourceId ? 'SAVE' : 'CREATE'}</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedResourceCategorySelector = makeSelectedResourceCategorySelector();
  const resourceIdSelector = makeFormModalPropSelector(ModalTypes.RESOURCE_FORM, 'id');
  return {
    adminCMSCacheTwoResources: state.adminCMSSite.cacheTwoResources,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    isResourceFormModalOpened: isModalOpened(ModalTypes.RESOURCE_FORM),
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    resourceForm: state.adminCMSSite.resources.resource.form,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    selectedResourceCategory: selectedResourceCategorySelector(state),
    resourceId: resourceIdSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...appActionCreators,
  ...actionCreators,
  ...adminCMSCacheOneActionCreators,
  ...adminCMSCacheTwoResourcesActionCreators,
}, dispatch) });

const ConnectedResourceFormModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResourceFormModal);

export default ConnectedResourceFormModal;