import * as React from 'react';
import { RegisterHeaderProgress, RegisterHeaderProgressStep } from '../../../../../Elements';
import {CacheTwoEventsState} from "../../../../../../store/CacheTwoEvents/index";
import {CacheFourEventsNumbersState} from "../../../../../../store/CacheFourEventsNumbers/index";
import {
  showClasses,
  showCampsite,
  showProducts
} from "../../../../../../store/Events/Event/Register/Numbers/Main/uiHelpers";
import { isAdmin } from '../../../../../../utils/permissionHelper';

export interface HeaderProgressProps {
  c2: CacheTwoEventsState;
  c4: CacheFourEventsNumbersState;
  selected: 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'campsite_assignment' | 'confirmation';
  loading: boolean;
  tabs?: React.ReactNode;
}

const HeaderProgress: React.SFC<HeaderProgressProps> = ({ c2, c4, selected, tabs, loading }: HeaderProgressProps): React.ReactElement<HeaderProgressProps> => (
  <RegisterHeaderProgress tabs={tabs} loading={loading}>
    <RegisterHeaderProgressStep selected={selected === 'spots'}>Spots</RegisterHeaderProgressStep>
    {showClasses(c4) && <RegisterHeaderProgressStep selected={selected === 'classes'}>Options</RegisterHeaderProgressStep>}
    {showProducts(c4) && <RegisterHeaderProgressStep selected={selected === 'products'}>Products</RegisterHeaderProgressStep>}
    {showCampsite(c2) && <RegisterHeaderProgressStep selected={selected === 'campsite_ranking'}>Campsites</RegisterHeaderProgressStep>}
    {isAdmin() && <RegisterHeaderProgressStep selected={selected === 'campsite_assignment'}>Campsites</RegisterHeaderProgressStep>}
    <RegisterHeaderProgressStep selected={selected === 'confirmation'}>Confirmation</RegisterHeaderProgressStep>
  </RegisterHeaderProgress>
);

export default HeaderProgress;
