import * as React from 'react';
import '../../../styles/elements/cart/item/index.scss';
import {mergeClassNames} from '@tentaroo/shared';
import Icon from "../Icon";
import { CartIcon as CartIconIcon, NotCartIcon } from '../../Icons';

export const namespace = (): string => 'elements--cart-icon';

export interface Props {
  className?: string;
  isDeleted?: boolean;
  size?: number;
}

const CartIcon: React.SFC<Props> = (props: Props):React.ReactElement<Props> => {
  const { size, isDeleted } = props;

  let className = mergeClassNames(namespace(), props);

  if (isDeleted) {
    return <NotCartIcon className="not-cart-icon"/>;
  }

  return <Icon size={size} green icon={CartIconIcon} className={className}/>;
};

CartIcon.defaultProps = {
  isDeleted: false,
  size: 28
};

export default CartIcon;
