import * as React from 'react';
import Card from "../Card";
import CardPadding from "../Card/Padding";
import CircleIcon from "../CircleIcon";
import Row from "../Grid/Row";
import Column from "../Grid/Column";
import Text from "../Text";
import Button from "../Button";
import '../../../styles/elements/option-item/index.scss';

const namespace = (): string => 'elements--option-item';

export interface OptionItemProps {
  onAdd?: () => void;
}

const OptionItem: React.SFC<OptionItemProps> = ({ onAdd }: OptionItemProps): React.ReactElement<OptionItemProps> => (
  <Card template="mobile-stretch" padding="none" className={namespace()}>
    <CardPadding layout="vertical">
      <Row>
        <Column gutter={false} marginRight={16}>
          <CircleIcon color="#3B99D8" initials="PD"/>
        </Column>
        <Column expand gutter={false} overflowHidden marginRight={16} layout="vertical">
          <Text weight="medium" size={18} line={26} wrap={false}>Popcorn Discount</Text>
          <Text size={14} line={18} color="gray">For everyone</Text>
        </Column>
        <Column>
          <Button color="green" onClick={onAdd}>APPLY</Button>
        </Column>
      </Row>
      <Row>
        <Text size={15} marginTop={15}>This option gives you 25% discount for purchasing classes for every youth</Text>
      </Row>
    </CardPadding>
  </Card>
);

export default OptionItem;
