import {AuditLogReset, SUFFIX} from './actions';
import {FormDefinition, IValidator} from "./validation";
import {apiValidationReducerCreator} from "../../../Validation/reducerCreator";
import * as moment from 'moment';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../utils/StrongActions';
import { ClearAllCache, ClearAllEndUserCacheButOptions } from '../../../App/actions';
import { ClearCacheBelowOne } from '../../../CacheOne/actions';
import { ClearCacheBelowTwoPrevOrders } from '../../../CacheTwoPrevOrders/actions';
import { setDefaults } from '../../../../utils/validator';
import { WithRootState } from '../../../Validation/actionCreator';

export interface AuditLogForm {
  StartDate?: moment.Moment;
}

export interface AuditLogState {
  ActiveForm: AuditLogForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
}
const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const getInitialState = (): AuditLogState => {
  return {
    ActiveForm: { },
    ValidationRules: {...FormDefinition}};
};

const AuditLog: Reducer<AuditLogState> = (oldState, action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, action);

  if (
    isActionType(action, AuditLogReset) ||
    isActionType(action, ClearCacheBelowTwoPrevOrders) ||
    isActionType(action, ClearAllCache) ||
    isActionType(action, ClearAllEndUserCacheButOptions) ||
    isActionType(action, ClearCacheBelowOne)
  ) {
    return setDefaults(action.rootState, getInitialState());
  }

  return state || { ...getInitialState()};
};

export default AuditLog;
