import * as React from 'react';

import Logo from '.';
import { ApplicationState } from '../../../store';
import '../../../styles/elements/logo/index.scss';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';
import { getDomain } from '../../../utils/urlHelper';

const logoNamespace = () => "elements--logo";

interface Props {
  className?: string;
  position: "top-blue-bar" | "side-bar" | "login";
}

type ConnectedProps = ReturnType<typeof mapStateToProps>;

class LogoComponent extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  private renderLogoInTopBlurBar() {
    const {className, campName, appLogo} = this.props;
    if (appLogo && appLogo.TopBarURL) {
      return (
        <div className={`${className} ${logoNamespace()}`}>
          <div className={`${className}--image-full ${logoNamespace()}--image-full`}>
            <img src={`${getDomain(true)}${appLogo.TopBarURL}`} />
          </div>
          <div className={`${className}--image-thumbnail ${logoNamespace()}--image-thumbnail`}>
            <img src={`${getDomain(true)}${appLogo.ThumbnailURL}`} />
          </div>
        </div>
      );
    }
    return (
      <div className={className}>
        <Logo className={`${className}--image`} width="43px" height="38px"/>
        <div className={`${className}--text`}>
          <h1><span>BOY SCOUTS OF AMERICA</span><sup className={`${className}--text--registered`}>®</sup></h1>
          <h2>{campName}</h2>
        </div>
      </div>
    );
  }
  

  private renderRegularLogo() {
    const {campName, appLogo, position} = this.props;
    if (appLogo && appLogo.RegularURL) {
      return (
        <div className={`${logoNamespace()}`}>
          <div className={`${logoNamespace()}--image ${position}`}>
            <img src={`${getDomain(true)}${appLogo.RegularURL}`} />
          </div>
        </div>
      );
    }
    return (
      <div className={`${logoNamespace()}--logo`}>
        <Logo width="30px" height="34px" className={`${logoNamespace()}--logo--image`}/>
        <div className={`${logoNamespace()}--logo--text`}>
          <h1><span>BOY SCOUTS OF AMERICA</span><sup
            className={`${logoNamespace()}--logo--text--registered`}>®</sup></h1>
          <h2>{campName}</h2>
        </div>
      </div>
    );
  }

  public render() {
    const {className, campName, appLogo, position} = this.props;

    if (position === "top-blue-bar") {
      return this.renderLogoInTopBlurBar();
    } else {
      return this.renderRegularLogo();
    }
    
  }
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    appLogo: state.session.SystemSettings ? state.session.SystemSettings.AppLogo : undefined,
    campName: state.session.SystemSettings ? state.session.SystemSettings.CampName : "",
  };
};

const ConnectedLogoComponent = connect(
  mapStateToProps,
  null,
  getMergeProps<Props>(),
)(LogoComponent);

export default ConnectedLogoComponent;
