import { ActionCreator } from '../';
import { SAVE, SAVE_FINISH } from '../Validation/actionCreator';
import { API_REQ, API_SUC, API_FAI } from '../../constants/request';
import { LoginForm } from '../../models/api/login';
import {AjaxResponse} from "rxjs/ajax";
import { typeName, Action } from '../../utils/StrongActions';
import {cancelLoadAndRollback} from '../../rollback/helpers';

export const SUFFIX = '__SESSION_ACTIONS';

const GET_LOGIN_FORM = 'GET_LOGIN_FORM';
const LOGOUT = 'LOGOUT';

export const getLoginFormRequestAction = API_REQ + SAVE + GET_LOGIN_FORM;
export const getLoginFormSuccessAction = API_SUC + SAVE_FINISH + GET_LOGIN_FORM;
export const getLoginFormFailureAction = API_FAI + SAVE_FINISH + GET_LOGIN_FORM;
export const LOGOUT_REQUEST = API_REQ + SAVE + LOGOUT;

@typeName(getLoginFormRequestAction)
export class APIRequestGetLoginForm extends Action {
  constructor(public resetCode?: string) {
    super();
  }
}

@typeName(getLoginFormSuccessAction)
export class APISuccessGetLoginForm extends Action {
  constructor(public form: LoginForm) {
    super();
  }
}

@typeName(getLoginFormFailureAction)
export class APIFailureGetLoginForm extends Action {
  constructor(public response: AjaxResponse) {
    super();
  }
}

@typeName(LOGOUT_REQUEST)
export class LogoutRequest extends Action {}

@typeName(API_SUC + SAVE_FINISH + LOGOUT)
export class LogoutSuccess extends Action {
  constructor(public response: AjaxResponse) {
    super();
  }
}

@typeName(API_FAI + SAVE_FINISH + LOGOUT)
export class LogoutFailure extends Action {
  constructor(public response: AjaxResponse) {
    super();
  }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  apiRequestLoginForm: (): ActionCreator => dispatch => dispatch(new APIRequestGetLoginForm()),
  apiRequestLogout: (): ActionCreator => (dispatch, getState) => {
    // We want to cancel any ongoing load and rollback BEFORE the logout request,
    // otherwise the rollback in onBrowserUrlChange afterwards will undo the logout.
    // We call `cancelLoadAndRollback` here because the load can come from a navigation
    // and if that is the case we want to rollback both the state and url.
    cancelLoadAndRollback();
    dispatch(new LogoutRequest());
  },
};
