import * as React from 'react';
import {bindActionCreators} from 'redux';
import {RouteComponentProps, withRouter} from "react-router";
import {Main, MainContent} from '../../../../../../Layouts';
import {Media} from '../../../../../../Elements';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import Tabs from './Tabs';
import MyGroup from './MyGroup';
import Person from './Person';
import {
  actionCreators,
  ApiRosterSubmitActions
} from '../../../../../../../store/Events/Event/Register/Participant/Roster/actions';
import {
  actionCreators as appActionCreators,
  ShowTopFloatingAlert
} from '../../../../../../../store/App/actions';
import {CacheThreeEventsState} from '../../../../../../../store/CacheThreeEvents';
import {CacheTwoEventsState} from '../../../../../../../store/CacheTwoEvents';
import {FullGroup} from '../../../../../../../models/api/options';
import {EventTypeRegistrationSettings} from '../../../../../../../models/api/cacheTwoEvents';
import {
  CacheFourEventsParticipantsState,
  makeAdultsCustomFieldsSelector,
  makeAvailableClassesNoTextFilter,
  makeConflictingClassesNoTextFilter,
  makeRegisteredClassesFilter,
  makeYouthCustomFieldsSelector
} from '../../../../../../../store/CacheFourEventsParticipants';
import {
  EventRegistrationPersonCustomField
} from "../../../../../../../models/api/cacheFourEvents";
import {Actions as ParticipantActions} from '../../../../../../../store/Events/Event/Register/Participant/Main/actions';
import {
  Actions as CacheFourActions,
  EventParticipantsGetPerson
} from '../../../../../../../store/CacheFourEventsParticipants/actions';
import {
  SimplePerson,
} from "../../../../../../../store/Events/Event/Register/Participant/Roster";
import {showClasses} from "../../../../../../../store/Events/Event/Register/Participant/Main/uiHelpers";
import {EventInfo} from "../../../../../../../models/api/cacheThreeEvents";
import "../../../../../../../styles/pages/events/event/register/participant/roster.scss";
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { ApplicationState } from '../../../../../../../store';
import { shouldBlockActions } from '../../../../../../../utils/cacheLoaders/helpers/blockers';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../../store/service';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--event--register--participant--roster';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string, ya?: string, pId?: string, pName?: string}>;

type RosterProps = WithInertAttribute<{
  cacheTwoEvents: CacheTwoEventsState;
  cacheThreeEvents: CacheThreeEventsState;
  cacheFourEventsParticipants: CacheFourEventsParticipantsState;
  cacheFourActions: ParticipantActions & CacheFourActions;
  Group: FullGroup;
}>;

@(withRouter as any)
class RosterComponent extends React.Component<RosterProps, {}> {
  public props: RosterProps & ConnectedProps;
  onChangeTab = (selectTab: 'roster' | 'new_youth' | 'new_adult') => {
    this.props.actions.selectTab(selectTab);
    if (selectTab === "new_youth" || selectTab === "new_adult") this.props.actions.initializeAddDefaultValues();
  };

  getAlertErrorMessage = () => {
    const {roster, classes, noFilterAvailableClasses, noFilterRegisteredClasses, noFilterConflictingClasses, cacheFourEventsParticipants} = this.props;

    if (cacheFourEventsParticipants.EventRegistrationPerson && !cacheFourEventsParticipants.EventRegistrationPerson.IDi) { // is adding
      if (roster.SubmitErrorMessage) return roster.SubmitErrorMessage;
    } else {
      if (roster.SubmitErrorMessage) return roster.SubmitErrorMessage;
      else if (classes.SubmitErrorMessage && showClasses(noFilterAvailableClasses, noFilterConflictingClasses, noFilterRegisteredClasses)) return classes.SubmitErrorMessage;
      return undefined;
    }

  };

  componentWillReceiveProps(nextProps: ConnectedProps & RosterProps) {
    if (shouldBlockActions()) return;
    if (nextProps.dispatchWarning)  {
      reduxStoreService().dispatch(new ShowTopFloatingAlert(nextProps.dispatchWarning, false, 'orange'));
    }
  }

  onDeletePerson = (person: SimplePerson) => {
    this.props.actions.pushModal(ModalTypes.DELETE_PERSON, false, true, {deletingPerson: person});
  };

  public renderEditParticipantMainContent() {
    const {
      params: {ya}, Group, actions,
      cacheTwoEvents: {EventTypeRegistrationSettings},
      cacheThreeEvents,
      cacheFourEventsParticipants: {EventRegistrationPersonCustomFields, GroupRosterPerson},
      roster: {ActiveForm, ValidationRules}
    } = this.props;

    // @todo: shouldn't need to pass in unselect
    // @todo: maybe OldScoutTypeID should be from c4.GroupRosterPerson and not ActiveForm?
    return (
      <Person
        preventUnselect
        onUnselect={() => false}
        EventTypeRegistrationSettings={EventTypeRegistrationSettings as EventTypeRegistrationSettings}
        isYouth={ya === 'y'}
        groupUnitType={Group.UnitType}
        GroupTypeID={Group.GroupTypeID}
        OldScoutTypeID={GroupRosterPerson ? GroupRosterPerson.ScoutTypeID : 0}
        reduxActions={actions}
        ValidationRules={ValidationRules}
        ActiveForm={ActiveForm}
        EventRegistrationPersonCustomFields={EventRegistrationPersonCustomFields}
        SubmitErrorMessage={this.getAlertErrorMessage()}
        EventInfo={cacheThreeEvents.EventInfo as EventInfo}
      />
    );
  }

  public renderMainContent() {
    const {
      youthCustomFields,
      adultsCustomFields,
      roster: {selectedTab, ActiveForm, ValidationRules, SubmitErrorMessage},
      actions, cacheFourActions, Group,
      cacheTwoEvents: {EventTypeRegistrationSettings, eventType},
      cacheThreeEvents,
      cacheFourEventsParticipants: {
        EventRegistrationPeopleNotRegisteredAdults, EventRegistrationPeopleNotRegisteredYouth, GroupRosterPerson
      }
    } = this.props;
    const ShowYouth = (EventTypeRegistrationSettings as EventTypeRegistrationSettings).RegistrationSettings.ShowYouth;
    const ShowAdults = (EventTypeRegistrationSettings as EventTypeRegistrationSettings).RegistrationSettings.ShowAdults;
    const ret = [
      <Media desktop tablet expand={true}>
        <Tabs
          ShowYouth={ShowYouth}
          ShowAdults={ShowAdults}
          selected={selectedTab}
          onChange={this.onChangeTab}
        />
      </Media>
    ];
    let customFields: Array<EventRegistrationPersonCustomField> = [];
    let isYouth = true;
    if (selectedTab === 'roster' && GroupRosterPerson) {
      isYouth = GroupRosterPerson.IsYouth;
      customFields = GroupRosterPerson.IsYouth ? youthCustomFields : adultsCustomFields;
    } else if (selectedTab === 'new_youth') {
      isYouth = true;
      customFields = youthCustomFields;
    } else if (selectedTab === 'new_adult') {
      isYouth = false;
      customFields = adultsCustomFields;
    }

    if (selectedTab === 'roster' && !GroupRosterPerson) {
      ret.push(<MyGroup
        EventRegistrationPeopleNotRegisteredAdults={EventRegistrationPeopleNotRegisteredAdults}
        EventRegistrationPeopleNotRegisteredYouth={EventRegistrationPeopleNotRegisteredYouth}
        ShowYouth={ShowYouth}
        ShowAdults={ShowAdults}
        eventType={eventType}
        onSelect={cacheFourActions.getPerson}
        GroupIDi={Group.IDi}
        GroupTS={Group.TS}
        isError={!!SubmitErrorMessage}
        onDelete={this.onDeletePerson}
      />);
    } else {
      ret.push(<Person
        onUnselect={cacheFourActions.unselectPerson}
        preventUnselect={selectedTab === 'new_youth' || selectedTab === 'new_adult'}
        EventTypeRegistrationSettings={EventTypeRegistrationSettings as EventTypeRegistrationSettings}
        isYouth={isYouth}
        groupUnitType={Group.UnitType}
        GroupTypeID={Group.GroupTypeID}
        OldScoutTypeID={GroupRosterPerson ? GroupRosterPerson.ScoutTypeID : 0}
        reduxActions={actions}
        ValidationRules={ValidationRules}
        ActiveForm={ActiveForm}
        EventRegistrationPersonCustomFields={customFields}
        SubmitErrorMessage={this.getAlertErrorMessage()}
        EventInfo={cacheThreeEvents.EventInfo as EventInfo}
      />);
    }
    return ret;
  }

  public render() {
    // Put this back in Main to get the participant right sidebar back
    // rightSidebar={selectedTab === 'roster' ? <SideBar open={this.state.showSidebar} onClose={() => this.setState({ showSidebar: false })}/> : undefined}
    const {
      roster: {selectedTab}, cacheTwoEvents, apiSaving, inert,
      params: {pId}, apiLoadingMap, cacheFourEventsParticipants, noFilterAvailableClasses, noFilterRegisteredClasses, noFilterConflictingClasses
    } = this.props;
    const ShowYouth = (cacheTwoEvents.EventTypeRegistrationSettings as EventTypeRegistrationSettings).RegistrationSettings.ShowYouth;
    const ShowAdults = (cacheTwoEvents.EventTypeRegistrationSettings as EventTypeRegistrationSettings).RegistrationSettings.ShowAdults;

    const loading = apiLoadingMap[EventParticipantsGetPerson.requestType] || apiLoadingMap[ApiRosterSubmitActions.requestType] || apiSaving > 0;
    const isMyGroup = selectedTab === 'roster' && !cacheFourEventsParticipants.GroupRosterPerson;
    return (
      <Main inert={inert} key="participants" mobileBackground="white" footer={<FooterProgress
        selected="roster"
        availableClasses={noFilterAvailableClasses}
        conflictingClasses={noFilterConflictingClasses}
        registeredClasses={noFilterRegisteredClasses}
      />}>
        <MainContent
          header={
            <HeaderProgress
              selected="roster"
              loading={loading}
              cacheFourEventsParticipants={cacheFourEventsParticipants}
              cacheTwoEvents={cacheTwoEvents}
              tabs={
              !pId && <Tabs
                mobile
                ShowYouth={ShowYouth}
                ShowAdults={ShowAdults}
                selected={selectedTab}
                onChange={this.onChangeTab}/>
              }
              availableClasses={noFilterAvailableClasses}
              conflictingClasses={noFilterConflictingClasses}
              registeredClasses={noFilterRegisteredClasses}
            />
          }
          loading={loading}
          className={`${namespace()}--main-content ${isMyGroup ? 'my-group' : ''}`}
        >
          {!pId && this.renderMainContent()}
          {pId && this.renderEditParticipantMainContent()}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const youthCustomFields = makeYouthCustomFieldsSelector();
  const adultsCustomFields = makeAdultsCustomFieldsSelector();
  
  const noFilterAvailableClasses = makeAvailableClassesNoTextFilter();
  const noFilterRegisteredClasses = makeRegisteredClassesFilter();
  const noFilterConflictingClasses = makeConflictingClassesNoTextFilter();
  return {
    roster: state.events.event.register.participant.roster,
    classes: state.events.event.register.participant.classes,
    youthCustomFields: youthCustomFields(state),
    adultsCustomFields: adultsCustomFields(state),
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    noFilterAvailableClasses: noFilterAvailableClasses(state),
    noFilterRegisteredClasses: noFilterRegisteredClasses(state),
    noFilterConflictingClasses: noFilterConflictingClasses(state),
    dispatchWarning: state.cacheFourEventsParticipants.dispatchWarning,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators
}, dispatch)});

const ConnectedRosterComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<RosterProps>(),
)(RosterComponent);

export default ConnectedRosterComponent;
