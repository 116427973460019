import {ActionCreator} from '..';
import { typeName, Action } from '../../utils/StrongActions';
import {createRequestActions} from '../Validation/actionCreator';

export const SUFFIX = '__CACHE_FOUR_EVENTS__PARTICIPANT';
export const GetEventParticipantData = createRequestActions(SUFFIX);

@typeName(`NO_REQUEST_NEEDED${SUFFIX}`)
export class CacheFourEventsParticipantNoRequestNeeded extends Action {
  constructor(public pId: number, public isYouth: boolean, public typeId: number) {
    super();
  }
}


export type Actions = typeof actionCreators;

export const actionCreators = {
  getEventParticipantData: (
    EventTypeID: number,
    GroupIDi: number,
    GroupTS: number,
    GetOptions: boolean,
    GetGroupData: boolean,
    EventIDi: number,
    GetEventTypeData: boolean,
    GetEventData: boolean,
    ParticipantIDi: number,
    IsYouth: boolean
  ): ActionCreator => dispatch => {
    dispatch(GetEventParticipantData.request(
      {EventTypeID, GroupIDi, GroupTS, GetOptions, GetGroupData, EventIDi, GetEventTypeData, GetEventData, ParticipantIDi, IsYouth}
    ));
  },
  noCacheFourRequest: (pId: number, isYouth: boolean, typeId: number): ActionCreator => dispatch => dispatch(new CacheFourEventsParticipantNoRequestNeeded(pId, isYouth, typeId)),
};
