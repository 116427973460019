import * as React from 'react';
import Cart from './Cart';
import Search from './Search';
import Refresh from './Refresh';
import Added from './Added';
import { namespace } from './constants';
import '../../../../styles/app/header/actions/index.scss';
import { ActionButton, Media } from '../../../../components/Elements';
import { AddIcon } from '../../../../components/Icons';
import { ADMIN_EVENTS_EVENTS_PATH } from '../../../../routes';
import { ModalTypes, isModalOpened } from '../../../../utils/modalHelper';

interface Props {
  search?: boolean;
  refresh?: boolean;
  cart?: boolean;
  add?: boolean;
  added?: boolean;
  addedLength?: number;
  marginLeftAuto?: boolean;
  mobileCart?: boolean;
  mobileSearch?: boolean;
  mobileAdd?: boolean;
  customRefresh?: () => void;
  onClickAdd?: () => void;
  onSearchOpen: () => any;
  onClickCart: () => void;
  routes?: any;
}

// @todo: there's a bug here where you cannot render added without search, though case does not yet exist
class Actions extends React.PureComponent<Props, {}> {
  public props: Props;

  static defaultProps = {
    marginLeftAuto: true
  };

  public handleSearchClick = () => {
    this.props.onSearchOpen();
  };

  private shouldDisableAdd = () => {
    const {routes} = this.props;
    const route = routes[routes.length - 1];

    if (route.path === ADMIN_EVENTS_EVENTS_PATH && isModalOpened(ModalTypes.EVENT_FORM)) return true;

    return false;
  };

  public render() {
    const { cart, search, refresh, marginLeftAuto, mobileCart, mobileSearch, added, addedLength, mobileAdd, customRefresh, onClickCart, add, onClickAdd, routes } = this.props;

    // @todo: add || added to the searches here to allow added without search
    let className = `${namespace()}` + (search ? ' has-search' : '');
    if (!marginLeftAuto) className += ' no-margin-left-auto';
    if (mobileCart) className += ' has-mobile-cart';
    if (mobileAdd) className += ' has-mobile-add';
    if (mobileSearch) className += ' has-mobile-search';

    if (cart || search || refresh || added || add) {
      return (
        <div className={className}>
          {search && <Search onClick={this.handleSearchClick}/>}
          {(refresh) && <Refresh customRefresh={customRefresh}/>}
          {cart && <Cart onClickCart={onClickCart}/>}
          {added ? <Added addedLength={addedLength ? addedLength : 0}/> : null}
          {add && <Media mobile><ActionButton disabled={this.shouldDisableAdd()} className={`${namespace()}--add-btn`} onClick={onClickAdd} icon={<AddIcon />} /></Media>}
        </div>
      );
    }
    return null;
  }
}

export default Actions;
