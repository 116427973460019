import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import ScrollTop from "../../../Elements/ScrollTop";
import Loader from "../../../Elements/Loader";

import { namespace as ns } from '../constants';
import '../../../../styles/layouts/main/content/index.scss';

export const namespace = (): string => `${ns()}--content`;

export interface ContentProps {
  children?: React.ReactNode;
  className?: string;
  verticalAlign?: 'top' | 'bottom' | 'center';
  header?: React.ReactNode;
  rightGutter?: boolean;
  mobileCardsFull?: boolean;
  handleCompact?: boolean;
  restrictHeight?: boolean;
  showScrollToTop?: boolean;
  loading?: boolean;
  noMobileMarginBottom?: boolean;
  enableClickThroughLoader?: boolean;
  leftContent?: React.ReactNode;
  wide?: boolean;
}

const Content: React.SFC<ContentProps> = ({ children, header, noMobileMarginBottom, loading, verticalAlign, rightGutter, mobileCardsFull, handleCompact, restrictHeight, showScrollToTop, enableClickThroughLoader, leftContent, wide, ...props }: ContentProps): React.ReactElement<ContentProps> => {
  let className = mergeClassNames(namespace(), props);
  if (verticalAlign) {
    switch(verticalAlign) {
      case 'top': className += ' vertical-align-top'; break;
      case 'center': className += ' vertical-align-center'; break;
      case 'bottom': className += ' vertical-align-bottom'; break;
    }
  }

  if (mobileCardsFull) className += ' mobile-cards-full';
  if (handleCompact) className += ' handle-compact';
  if (restrictHeight) className += ' restrict-height';
  if (loading) className += ' loading';
  if (noMobileMarginBottom) className += ' no-mobile-margin-bottom';
  if (wide) className += ' wide';

  const content = (
    <div className={className}>
      {children}
      {loading ? (
          <div className={`${namespace()}--loader ${enableClickThroughLoader ? 'disabled-click' : ''}`}>
            <Loader cover pop disablePointerEvents hideLoader={!loading}/>
          </div>
        ) : null}
    </div>
  );

  if (header) {
    return (
      <div className={`${namespace()}--wrapper`}>
        <div className={`${namespace()}--header ${loading ? 'loading' : ''}`}>
          {header}
        </div>
        {leftContent ? <div className={`${namespace()}--with-left-content`}>
          <div className={`${namespace()}--left-content ${wide ? 'wide' : ''}`}>{leftContent}</div>
          {rightGutter ? (
            <div className={`${namespace()}--content-gutter-wrapper`}>
              <div className={`${namespace()}--content-gutter-wrapper--content`}>{content}</div>
              <div className={`${namespace()}--content-gutter-wrapper--gutter right ${wide ? 'wide' : ''} ${!!leftContent ? 'has-left-content' : ''}`}/>
            </div>
          ) : content}
        </div> : null}
        {!leftContent && rightGutter ? (
          <div className={`${namespace()}--content-gutter-wrapper`}>
            <div className={`${namespace()}--content-gutter-wrapper--content`}>{content}</div>
            <div className={`${namespace()}--content-gutter-wrapper--gutter right ${wide ? 'wide' : ''}`}/>
          </div>
        ) : (leftContent ? null : content)}
        {showScrollToTop && <div className={`${namespace()}--scroll-to-top`}>
          <ScrollTop/>
        </div>}
      </div>
    );
  }

  return content;
};

export default Content;
