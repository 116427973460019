import { IValidator, FormDefinition } from "./validation";
import { SUFFIX } from './actions';
import { apiValidationReducerCreator } from "../../../../store/Validation/reducerCreator";
import * as Actions from './actions';
import { ClearAllAdminFacilityLocationCache } from "../../CacheOne/actions";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../utils/StrongActions";
import {setDefaults} from "../../../../utils/validator";
import { WithRootState } from "../../../Validation/actionCreator";
import {ClearAllCache} from "../../../App/actions";

export interface FacilitiesHomeState {
  ActiveForm: FacilitiesHomeActiveForm;
  ValidationRules: IValidator;
  expandFilter: boolean;
  unmountPreview?: boolean;
  currentSiteID?: number;
}

export interface FacilitiesHomeActiveForm {
  ShowDeleted?: boolean;
  FilterText?: string;
  SelectedFacilityTypeID?: number;
  FacilityTypeShowDeleted?: number;
  FacilityTypeFilterText?: string;
}

const getInitialState: () => FacilitiesHomeState = () => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition},
  expandFilter: false,
});

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const FacilitiesHomeReducer: Reducer<FacilitiesHomeState> = (s, action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, action);
  if (isActionType(action, Actions.ToggleExpandFilterAction)) {
    return {...state, expandFilter: action.expand};
  } else if (isActionType(action, ClearAllAdminFacilityLocationCache) || isActionType(action, ClearAllCache)) {
    return setDefaults(action.rootState, getInitialState());
  } else if (isActionType(action, Actions.FacilitiesHomeReset)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        FilterText: '',
        FacilityTypeFilterText: '',
      },
    };
  }
  return state || setDefaults(action.rootState, getInitialState());
};

export default FacilitiesHomeReducer;