import { AnyAction, combineReducers } from "redux";
import { routerReducer, routerMiddleware } from "react-router-redux";
import { configureStore } from "@reduxjs/toolkit";
import { setAutoFreeze } from "immer";
import * as Store from "../store";
import { createEpicMiddleware } from "redux-observable";
import { browserHistory } from "react-router";
import NProgress from "nprogress";
import * as cloneDeep from "lodash.cloneDeep";
import { TENTAROO_INIT } from "@tentaroo/shared";

import { rollbackIfClickOut } from "../utils/helpers/financialSummaryHelper";
import { captureTentarooError } from "../utils/dataHelper";
import { isActionType, typedToPlain } from "../utils/StrongActions";
import { ClearAllCache } from "../store/App/actions";
import { RestoreState } from "../store/Rollback/actions";
import { injectRootStateMiddleware } from "../redux/middlewares";
import { WithRootState } from "../store/Validation/actionCreator";

declare var module: any;

export const injectReducers = (reducers) => {
  const promises: any[] = [
    // CMS - Caches
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/CacheOne"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/CacheTwoContacts"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/CacheTwoResources"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/CacheTwoSiteMenus"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/CacheTwoPageMenus"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/CacheTwoPages"
    ),
    // CMS - Resources
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Resources/Home"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Resources/Modals/Resource"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Resources/Modals/ResourceCategory"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Resources/Modals/ResourceCategoryForm"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Resources/Modals/MultipleResources"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Resources/Resource/Form"
    ),
    // CMS - Contacts
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Contacts/Home"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Contacts/Contact/Form"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Contacts/Modals/MultipleContacts"
    ),
    // CMS - Settings
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Settings/Main"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Settings/General"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Settings/Messages"
    ),
    // CMS - Menus
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Menus/SiteMenuItem/Form/reducer"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Menus/Modals/PageMenuItem"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Menus/Home"
    ),
    // CMS - Pages
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Pages/Modals/SelectPage"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Pages/Modals/NewPage"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Pages/Modals/ImageForm"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Pages/Modals/DuplicatePageForm"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Pages/Home/reducer"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminCMSSite/Pages/Page/Form"
    ),
    // FacilityLocation - Caches
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/CacheOne"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/CacheTwoFacilityTypes"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/CacheTwoFacility"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/CacheTwoBlackoutDates"
    ),

    // FacilityLocation - Facilities
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/Facilities/Home/reducer"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/Facilities/FacilityType/Form"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/Facilities/Modals/NewFacility"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/Facilities/Facility/Form"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/Facilities/Modals/ImportFromFacility/reducer"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/Facilities/Modals/MultipleFacilities/reducer"
    ),
    // FacilityLocation - Settings
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/Settings"
    ),
    // FacilityLocation - Reports
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/Reports"
    ),
    // FacilityLocation - Blackout Dates
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/BlackoutDates/Home"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/BlackoutDates/Form/reducer"
    ),
    // FacilityLocation - Availabilities
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminFacilityLocation/Availabilities/Home/reducer"
    ),

    // Admin Events - Caches
    import(/* webpackChunkName: "main-admin" */ "../store/AdminEvents/CacheOne"),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/CacheTwoEventType"
    ),
    import(/* webpackChunkName: "main-admin" */ "../store/AdminEvents/Shared"),

    // Admin Events - EventTypes
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/EventTypes/EventType/Form/reducer"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/EventTypes/Modals/SelectEventTypes"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/EventTypes/Modals/ReplaceParticipantType"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/EventTypes/Modals/NewEventType"
    ),

    // Admin Events - Event
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/CacheTwoEvent"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Home"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Event/Classes/reducer"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Event/TimeBlocks"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Event/ClassTypes"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Event/Form"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Modals/ImportFromEvent"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Event/Dashboard"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Modals/ExportInstructorRoster"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Modals/ExportParticipantClassSchedule"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Modals/GenerateInvoices"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Modals/MultipleGroups"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Modals/EnterClassRequirement"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Modals/SelectClassType"
    ),

    // Admin Events - Class Type
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/CacheThreeClassType"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/ClassTypes/Form"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Modals/SelectMeritBadge"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Events/Modals/ManageRequirements"
    ),

    // Admin Events - Class
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/CacheThreeClass"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Classes/Form"
    ),

    // Admin Events - Class Requirements
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/CacheThreeClassRequirements"
    ),

    // Admin Events - Products
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Products/Home"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/CacheTwoProduct"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Products/Form"
    ),

    // Admin Events - Reports
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Reports/GenerateReports"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/Reports/YearOverview"
    ),

    // Admin Events - MessageCenter
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/MessageCenter/Home"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/MessageCenter/Modals/SelectMessage"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/CacheTwoMessage"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/MessageCenter/Form"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminEvents/MessageCenter/Modals/SendMessage"
    ),

    // Admin Settings - Caches
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminSettings/CacheTwoGLAccount"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminSettings/CacheTwoLocation"
    ),
    // Admin Settings - GLAccounts
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminSettings/GLAccounts/Modals/GLAccount"
    ),
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminSettings/GLAccounts/GLAccount/Form"
    ),
    // Admin Settings - Modal SelectLocation
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminSettings/Modals/Location"
    ),
    // Admin Settings - Modal LocationForm
    import(
      /* webpackChunkName: "main-admin" */ "../store/AdminSettings/Modals/LocationForm"
    ),
  ];

  return Promise.all(promises).then(
    ([
      // CMS - Caches
      AdminCMSSiteCacheOne,
      AdminCMSSiteCacheTwoContacts,
      AdminCMSSiteCacheTwoResources,
      AdminCMSSiteCacheTwoSiteMenuItems,
      AdminCMSSiteCacheTwoPageMenuItems,
      AdminCMSSiteCacheTwoPage,
      // CMS - Caches
      ResourcesHomeReducer,
      ResourceModalReducer,
      ResourceCategoryModalReducer,
      ResourceCategoryFormReducer,
      MultipleResourcesModalReducer,
      ResourceFormReducer,
      // CMS - Contacts
      ContactsHomeReducer,
      ContactFormReducer,
      MultipleContactsModalReducer,
      // CMS - Settings
      SettingsMainReducer,
      GeneralSettingsFormReducer,
      MessagesSettingsFormReducer,
      // CMS - Menus
      SiteMenuItemFormReducer,
      PageMenuItemFormReducer,
      MenusHomeReducer,
      // CMS - Pages
      PageTypeModalReducer,
      NewPageModalReducer,
      ImageFormReducer,
      DuplicatePageFormReducer,
      PagesHomeReducer,
      PageFormReducer,
      // FacilityLocation - Caches
      AdminFacilityLocationCacheOne,
      AdminFacilityLocationCacheTwoFacilityTypes,
      AdminCMSSiteCacheTwoFacility,
      AdminFacilityLocationCacheTwoBlackoutDates,

      // FacilityLocation - Facilities
      FacilitiesHomeReducer,
      FacilityTypeFormReducer,
      NewFacilityModalReducer,
      FacilityFormReducer,
      ImportFromFacilityModalReducer,
      MultipleFacilitiesModalReducer,

      // FacilityLocation - Settings
      FacilitiesSettingsFormReducer,

      // FacilityLocation - Reports
      FacilitiesReportsFormReducer,

      // FacilityLocation - Blackout Dates
      BlackoutDatesHomeReducer,
      BlackoutDateFormReducer,

      // FacilityLocation - Availabilities
      AvailabilitiesHomeReducer,

      // Admin Events - Caches
      AdminEventsCacheOneReducer,
      AdminEventsCacheTwoEventTypeReducer,
      AdminEventsSharedReducer,

      // Admin Events - EventTypes
      EventTypeFormReducer,
      EventTypesModalReducer,
      ReplaceParticipantTypeModalReducer,
      NewEventTypeFormReducer,

      // Admin Events - Events
      AdminEventsCacheTwoEventReducer,
      EventsHomeReducer,
      AdminEventsEventClassesTabReducer,
      AdminEventsEventTimeBlocksTabReducer,
      AdminEventsEventClassTypesTabReducer,
      EventFormReducer,
      ImportFromEventModalReducer,
      AdminEventsEventDashboardReducer,
      ExportInstructorRosterModalReducer,
      ExportParticipantClassScheduleModalReducer,
      GenerateInvoicesModalReducer,
      MultipleGroupsModalReducer,
      EnterClassRequirementModalReducer,
      SelectClassTypeModalReducer,

      // Admin Events - Class Type
      AdminEventsCacheThreeClassTypeReducer,
      AdminEventClassTypeFormReducer,
      SelectMeritBadgeModalReducer,
      ManageRequirementsModalReducer,

      // Admin Events - Class
      AdminEventsCacheThreeClassReducer,
      AdminEventClassFormReducer,

      // Admin Events - Class Requirements
      AdminEventsCacheThreeClassRequirementsReducer,

      // Admin Events - Products
      ProductsHomeReducer,
      AdminEventsCacheTwoProductReducer,
      AdminEventProductFormReducer,

      // Admin Events - Reports
      AdminEventsReportsFormReducer,
      AdminEventsYearOverviewFormReducer,

      // Admin Events - MessageCenter
      MessageCenterHomeReducer,
      SelectMessageModalReducer,
      AdminEventsCacheTwoMessageReducer,
      AdminEventMessageFormReducer,
      SendMessageModalReducer,

      // Admin Settings - Caches
      AdminSettingsCacheTwoGLAccount,
      AdminSettingsCacheTwoLocation,

      // Admin Settings - GLAccounts
      GLAccountModalReducer,
      GLAccountFormReducer,

      // Admin Settings - Modal
      LocationModalReducer,
      LocationFormReducer,
    ]) => {
      reducers.adminCMSSite = combineReducers({
        cacheOne: AdminCMSSiteCacheOne.default,
        cacheTwoContacts: AdminCMSSiteCacheTwoContacts.default,
        cacheTwoResources: AdminCMSSiteCacheTwoResources.default,
        cacheTwoSiteMenuItems: AdminCMSSiteCacheTwoSiteMenuItems.default,
        cacheTwoPageMenuItems: AdminCMSSiteCacheTwoPageMenuItems.default,
        cacheTwoPage: AdminCMSSiteCacheTwoPage.default,
        resources: combineReducers({
          home: ResourcesHomeReducer.default,
          modals: combineReducers({
            resource: ResourceModalReducer.default,
            resourceCategory: ResourceCategoryModalReducer.default,
            resourceCategoryForm: ResourceCategoryFormReducer.default,
            multipleResources: MultipleResourcesModalReducer.default,
          }),
          resource: combineReducers({
            form: ResourceFormReducer.default,
          }),
        }),
        contacts: combineReducers({
          home: ContactsHomeReducer.default,
          contact: combineReducers({
            form: ContactFormReducer.default,
          }),
          modals: combineReducers({
            multipleContacts: MultipleContactsModalReducer.default,
          }),
        }),
        settings: combineReducers({
          main: SettingsMainReducer.default,
          general: GeneralSettingsFormReducer.default,
          messages: MessagesSettingsFormReducer.default,
        }),
        menus: combineReducers({
          siteMenuItem: combineReducers({
            form: SiteMenuItemFormReducer.default,
          }),
          modals: combineReducers({
            pageMenuItem: PageMenuItemFormReducer.default,
          }),
          home: MenusHomeReducer.default,
        }),
        pages: combineReducers({
          modals: combineReducers({
            pageType: PageTypeModalReducer.default,
            newPage: NewPageModalReducer.default,
            imageForm: ImageFormReducer.default,
            duplicatePageForm: DuplicatePageFormReducer.default,
          }),
          home: PagesHomeReducer.default,
          page: combineReducers({
            form: PageFormReducer.default,
          }),
        }),
      });

      reducers.adminFacilityLocation = combineReducers({
        cacheOne: AdminFacilityLocationCacheOne.default,
        cacheTwoFacilityTypes:
          AdminFacilityLocationCacheTwoFacilityTypes.default,
        cacheTwoFacility: AdminCMSSiteCacheTwoFacility.default,
        cacheTwoBlackoutDate:
          AdminFacilityLocationCacheTwoBlackoutDates.default,
        facilities: combineReducers({
          home: FacilitiesHomeReducer.default,
          facilityType: combineReducers({
            form: FacilityTypeFormReducer.default,
          }),
          facility: combineReducers({
            form: FacilityFormReducer.default,
          }),
          modals: combineReducers({
            newFacility: NewFacilityModalReducer.default,
            importFromFacility: ImportFromFacilityModalReducer.default,
            multipleFacilities: MultipleFacilitiesModalReducer.default,
          }),
        }),
        blackoutDates: combineReducers({
          home: BlackoutDatesHomeReducer.default,
          form: BlackoutDateFormReducer.default,
        }),
        availabilities: combineReducers({
          home: AvailabilitiesHomeReducer.default,
        }),
        reports: FacilitiesReportsFormReducer.default,
        settings: FacilitiesSettingsFormReducer.default,
      });

      reducers.adminEvents = combineReducers({
        cacheOne: AdminEventsCacheOneReducer.default,
        cacheTwoEvent: AdminEventsCacheTwoEventReducer.default,
        cacheTwoEventType: AdminEventsCacheTwoEventTypeReducer.default,
        cacheThreeClass: AdminEventsCacheThreeClassReducer.default,
        cacheThreeClassRequirements:
          AdminEventsCacheThreeClassRequirementsReducer.default,
        cacheThreeClassType: AdminEventsCacheThreeClassTypeReducer.default,
        cacheTwoProduct: AdminEventsCacheTwoProductReducer.default,
        cacheTwoMessage: AdminEventsCacheTwoMessageReducer.default,
        shared: AdminEventsSharedReducer.default,
        events: combineReducers({
          home: EventsHomeReducer.default,
          event: combineReducers({
            form: EventFormReducer.default,
            classes: combineReducers({
              classesTab: AdminEventsEventClassesTabReducer.default,
              timeBlocksTab: AdminEventsEventTimeBlocksTabReducer.default,
              classTypesTab: AdminEventsEventClassTypesTabReducer.default,
            }),
            dashboard: AdminEventsEventDashboardReducer.default,
          }),
          modals: combineReducers({
            generateInvoices: GenerateInvoicesModalReducer.default,
            importFromEvent: ImportFromEventModalReducer.default,
            exportInstructorRoster: ExportInstructorRosterModalReducer.default,
            exportParticipantClassSchedule:
              ExportParticipantClassScheduleModalReducer.default,
            multipleGroups: MultipleGroupsModalReducer.default,
            enterClassRequirement: EnterClassRequirementModalReducer.default,
            selectClassType: SelectClassTypeModalReducer.default,
            selectMeritBadge: SelectMeritBadgeModalReducer.default,
            manageRequirements: ManageRequirementsModalReducer.default,
          }),
        }),
        classTypes: combineReducers({
          form: AdminEventClassTypeFormReducer.default,
        }),
        classes: combineReducers({
          form: AdminEventClassFormReducer.default,
        }),
        products: combineReducers({
          home: ProductsHomeReducer.default,
          form: AdminEventProductFormReducer.default,
        }),
        eventTypes: combineReducers({
          eventType: combineReducers({
            form: EventTypeFormReducer.default,
          }),
          modals: combineReducers({
            selectEventTypes: EventTypesModalReducer.default,
            replaceParticipantType: ReplaceParticipantTypeModalReducer.default,
            newEventType: NewEventTypeFormReducer.default,
          }),
        }),
        reports: combineReducers({
          generateReports: AdminEventsReportsFormReducer.default,
          yearOverview: AdminEventsYearOverviewFormReducer.default,
        }),
        messageCenter: combineReducers({
          home: MessageCenterHomeReducer.default,
          form: AdminEventMessageFormReducer.default,
          modals: combineReducers({
            selectMessage: SelectMessageModalReducer.default,
            sendMessage: SendMessageModalReducer.default,
          }),
        }),
      });

      reducers.adminSettings = combineReducers({
        cacheTwoGLAccount: AdminSettingsCacheTwoGLAccount.default,
        cacheTwoLocation: AdminSettingsCacheTwoLocation.default,
        glAccounts: combineReducers({
          modals: combineReducers({
            glAccount: GLAccountModalReducer.default,
          }),
          glAccount: combineReducers({
            form: GLAccountFormReducer.default,
          }),
        }),
        modals: combineReducers({
          location: LocationModalReducer.default,
          locationForm: LocationFormReducer.default,
        }),
      });

      return reducers;
    }
  );
};

export default async function createStore(
  initialState?: Store.InitialApplicationState
) {
  const epicMiddleware = createEpicMiddleware(Store.rootEpic);
  // Build middleware. These are functions that can process the actions before they reach the store.
  const windowIfDefined =
    typeof window === "undefined" ? null : (window as any);
  const devToolsExtension: () => () => void =
    windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__; // If devTools is installed, connect to it
  // Combine all reducers and instantiate the app-wide store instance
  const reducers = await Store.getReducers();
  const rootReducer = buildRootReducer(reducers);

  // TODO: Remove this once refactored all reducers to Immer
  setAutoFreeze(false);
  const store = configureStore({
    reducer: makeRollbackable(rootReducer),
    middleware: (getDefaultMiddleware) =>  getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([
      injectRootStateMiddleware,
      epicMiddleware,
      routerMiddleware(browserHistory),
      typedToPlain as any,
    ]),
    devTools: process.env.NODE_ENV === "development" && Boolean(devToolsExtension),
    preloadedState: { ...initialState },
  });

  // Enable Webpack hot module replacement for reducers
  if (module.hot) {
    /*
    module.hot.accept('./store', () => {
      const nextRootReducer = require('./store') as (typeof Store);
      store.replaceReducer(buildRootReducer(nextRootReducer.reducers));
    });
    */
  }
  
  return store;
}

let initState: any;
(window as any).getInitialState = () => initState;

function makeRollbackable(reducer) {
  return function (state: Store.ApplicationState, action: WithRootState<AnyAction>) {
    if (action.type === TENTAROO_INIT) {
      initState = cloneDeep(state);
      delete initState.routing; // don't save react-router state
    }

    if (isActionType(action, RestoreState)) {
      if (state.app.apiSaving > 0 && state.rollback.savePointDuringSave) {
        const newState = {
          ...state.rollback.savePointDuringSave.oldState,
          rollback: { ...state.rollback },
        };
        newState.app = {
          ...newState.app,
          topFloatingAlertColor: "orange",
          topFloatingAlert:
            "Currently saving, please wait for save to complete before going elsewhere.",
          alertInModal: true,
          snackbar: undefined,
          searchFilter: "",
        };

        // create a new save state
        const oldState = {} as any;
        for (let key in state.rollback.savePointDuringSave.oldState) {
          oldState[key] = {
            ...state.rollback.savePointDuringSave.oldState[key],
          };
        }
        oldState.rollback = {};
        newState.rollback.savePointDuringSave.oldState = oldState;

        newState.rollback.isRollbackJustFinished = true;
        newState.routing = { ...state.routing }; // never restore router

        return reducer(newState, action);
      } else if (state.app.apiSaving > 0 && !action.restoreDuringSave) {
        captureTentarooError(
          new Error("Restore during save with restoreDuringSave === false")
        );
        return reducer(state, action);
      }

      if (!state.rollback.normalSavePoint) {
        captureTentarooError(
          new Error("Trying to rollback when there no normalSavePoint")
        );
        return reducer(state, action);
      }
      const newState = {
        ...state.rollback.normalSavePoint.oldState,
        rollback: { ...state.rollback },
      };
      if (newState) {
        console.log("!!!!!Restore state occurred!!!!!");
        document.removeEventListener("mousedown", rollbackIfClickOut);
        NProgress.done();

        // create a new save state
        const oldState = {} as any;
        for (let key in state.rollback.normalSavePoint.oldState) {
          oldState[key] = { ...state.rollback.normalSavePoint.oldState[key] };
        }
        oldState.rollback = {};
        newState.rollback.normalSavePoint.oldState = oldState;
        // mark `isRollbackJustFinished` so that following actions (i.e. SetPreviousLocation) could
        // differentiate normal load and rollback load
        newState.rollback.isRollbackJustFinished = true;
        newState.app.snackbar = undefined;
        newState.app.topFloatingAlert = undefined;
        // clean any saving state when rollback, since all save should be discarded/canceled
        newState.app.apiSaving = 0;
        if (newState.app.apiSavingMap) {
          for (let key of Object.keys(newState.app.apiSavingMap)) {
            if (
              newState.app.apiSavingMap[key] !== true &&
              newState.app.apiSavingMap[key] > 0
            ) {
              newState.app.apiSavingMap[key] = 0;
            } else if (newState.app.apiSavingMap[key]) {
              newState.app.apiSavingMap[key] = false;
            }
          }
        }

        // never restore router
        newState.routing = { ...state.routing };

        // retain specified nodes
        if (action.type === "RESTORE_STATE" && action.retainNodeNames) {
          const retainNodeNames = action.retainNodeNames;
          retainNodeNames.forEach((retainNodeName) => {
            const nodes = retainNodeName.split(".");

            let newNode = { ...newState };
            let oldNode = { ...state };
            for (let i = 0; i < nodes.length - 1; i++) {
              newNode = newNode[nodes[i]];
              oldNode = oldNode[nodes[i]];
            }
            newNode[nodes[nodes.length - 1]] = oldNode[nodes[nodes.length - 1]];
          });
        }
        return reducer(newState, action);
      }
      return reducer(state, action);
    } else if (isActionType(action, ClearAllCache) && action.clearSession) {
      console.log("CLEARING SESSION!");
      const s = {
        ...state,
        ...initState,
        session: {
          ...initState.session,
          SystemSettings: {
            ...state.session.SystemSettings,
          },
          AllowSelfSignup: state.session.AllowSelfSignup,
        },
        app: {
          ...initState.app,
          apiSavingMap: {
            // TODO: remove this??
            something: false,
          },
          // DONOT clear `afterLoginPath` & `afterLoginAccount` when clearing session,
          // because these two fields are information we need when in unauthenticated pages
          afterLoginPath: state.app.afterLoginPath,
          afterLoginAccount: state.app.afterLoginAccount,
        },
      };
      return reducer(s, action);
    }
    return reducer(state, action);
  };
}

function buildRootReducer(allReducers) {
  return combineReducers({
    ...allReducers,
    routing: routerReducer,
  });
}
