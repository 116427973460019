
import { SUFFIX, DeleteAttribute, NewAttribute } from "./actions";
import { apiValidationReducerCreator, shouldSkipUpdateForm } from "../../../../../store/Validation/reducerCreator";
import { createSelector } from "reselect";
import { IFacilityTypeRowValidator, IFacilityTypeGeneralValidator, IFacilityTypeBookingTimesValidator, FacilityTypeRowFormDefinition, FacilityTypeGeneralFormDefinition, FacilityTypeBookingTimesFormDefinition, IFacilityTypeAttributesValidator, FacilityTypeAttributesFormDefinition, getAttributeDefinition, getAttributeNameValidationRule, getAttributeInactiveValidationRule } from "./validation";
import { AdminFacilityTypeDetail, AdminFacilityTypeAttribute } from "../../../../../models/api/adminFacilitiesCacheTwoFacilityType";
import { setDefaults } from "../../../../../utils/validator";
import { ClearAdminFacilityLocationCacheBelowOne } from "../../../../../store/AdminFacilityLocation/CacheOne/actions";
import { processHour } from "../../../../../utils/dateHelper";
import { API_UPD } from "../../../../../constants/request";
import { ClearAdminFacilityLocationCacheTwoFacilityType } from "../../../../../store/AdminFacilityLocation/CacheTwoFacilityTypes/actions";
import { openedModalsSelector } from "../../../../../store/App";
import { IModal, ModalTypes } from "../../../../../utils/modalHelper";
import { UPDATE_FACILITY_FACILITY_TYPE_FORM_SUFFIX } from "../../../../../utils/suffix";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../../utils/StrongActions";
import { WithRootState } from "../../../../Validation/actionCreator";

export interface FacilityTypeRowActiveForm {
  ID: number,
  Name: string;
  NamePlural: string;
  Description: string;
  ShowAsID: number;
  Inactive: boolean;
  TS: number;
}

export interface FacilityTypeGeneralActiveForm {
  Name: string;
  NamePlural: string;
  ShowAsID: number;
  AllowOverlapping: boolean;
  PropertyMapURL: string;
  AdditionalNotify1_Name: string;
  AdditionalNotify1_Email: string;
  AdditionalNotify2_Name: string;
  AdditionalNotify2_Email: string;
  GLAccountID_Default: number;
  Description?: string;
}

export interface AttributesActiveForm {

}

export interface BookingTimesActiveForm {
  StartHour?: number;
  StartMin?: number;
  StartPeriod?: number;
  EndHour?: number;
  EndMin?: number;
  EndPeriod?: number;
  IsCheckoutNextDay: boolean;
}

export interface FacilityTypeFormState {
  FacilityTypeRow: {
    ActiveForm: FacilityTypeRowActiveForm;
    ValidationRules: IFacilityTypeRowValidator;
    SubmitErrorMessage?: string;
  },
  General: {
    ActiveForm: FacilityTypeGeneralActiveForm;
    ValidationRules: IFacilityTypeGeneralValidator;
    SubmitErrorMessage?: string;
  },
  BookingTimes: {
    ActiveForm: BookingTimesActiveForm;
    ValidationRules: IFacilityTypeBookingTimesValidator;
    SubmitErrorMessage?: string;
  },
  Attributes: {
    Values: AdminFacilityTypeAttribute[];
    ActiveForm: AttributesActiveForm;
    ValidationRules: IFacilityTypeAttributesValidator;
    SubmitErrorMessage?: string;
  },
}

const getInitialState = () => {
  return {
    FacilityTypeRow: {
      ActiveForm: { },
      ValidationRules: { ...FacilityTypeRowFormDefinition }
    },
    General: {
      ActiveForm: { },
      ValidationRules: { ...FacilityTypeGeneralFormDefinition }
    },
    BookingTimes: {
      ActiveForm: { },
      ValidationRules: { ...FacilityTypeBookingTimesFormDefinition }
    },
    Attributes: {
      Values: [{ID: -1, Name: '', Inactive: false}],
      ValidationRules: {
        ...getAttributeDefinition([{ID: -1, Name: '', Inactive: false, TS: 0}]),
      },
      ActiveForm: {
        ...initAttributesForm([{ID: -1, Name: '', Inactive: false, TS: 0}]),
      }
    },
  };
};

export const initAttributesForm = (attributes: AdminFacilityTypeAttribute[]) => {

  const newActiveForm = {};

  if (attributes && attributes.length > 0) {
    attributes.forEach((attr, index) => {
      newActiveForm[`AttributeName${index}`] = attr.Name;
      newActiveForm[`AttributeInactive${index}`] = attr.Inactive;
    });
  } else {
    newActiveForm['AttributeName0'] = '';
    newActiveForm['AttributeInactive0'] = false;
  }

  return newActiveForm;
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX);
const checkGeneralApiValidation = apiValidationReducerCreator(SUFFIX + '__GENERAL');
const checkBookingTimesApiValidation = apiValidationReducerCreator(SUFFIX + '__BOOKING_TIMES');
const checkAttributesApiValidation = apiValidationReducerCreator(SUFFIX + '__ATTRIBUTES');

export const makeFacilityTypeSharedSettingsSectionSelector = () => {
  return createSelector(
    [openedModalsSelector],
    (openedModals: IModal[]) => {
      const modal = openedModals ? openedModals.find((m) => m.type === ModalTypes.FACILITY_TYPE_SHARED_SETTINGS) : null;

      if (modal) {
        return modal.props ? modal.props.facilityTypeFormSection : undefined;
      }

      return undefined;
    }
  );
};

const BookingTimesFormKeys = {
  StartHour: true,
  StartMin: true,
  StartPeriod: true,
  EndHour: true,
  EndMin: true,
  EndPeriod: true,
  IsCheckoutNextDay: true,
};

const setFacilityType = (state: FacilityTypeFormState, facilityType?: AdminFacilityTypeDetail) => {
  if (facilityType) {
    const checkinTokens = facilityType.BookingTimes.CheckinTime.split(':');
    const checkoutTokens = facilityType.BookingTimes.CheckoutTime.split(':');

    const newState: FacilityTypeFormState = {
      ...state,
      FacilityTypeRow: {
        ActiveForm: {
          ...state.FacilityTypeRow.ActiveForm,
          ID: facilityType.FacilityTypeRow.ID,
          Name: facilityType.FacilityTypeRow.Name,
          NamePlural: facilityType.FacilityTypeRow.NamePlural,
          Description: facilityType.FacilityTypeRow.Description,
          ShowAsID: facilityType.FacilityTypeRow.ShowAsID,
          Inactive: facilityType.FacilityTypeRow.Inactive,
          TS: facilityType.FacilityTypeRow.TS,
          
        },
        ValidationRules: {...FacilityTypeRowFormDefinition},
      },
      General: {
        ActiveForm: {
          ...state.General.ActiveForm,
          Name: facilityType.General.Name,
          NamePlural: facilityType.General.NamePlural,
          ShowAsID: facilityType.General.ShowAsID,
          AllowOverlapping: facilityType.General.AllowOverlapping,
          PropertyMapURL: facilityType.General.PropertyMapURL,
          AdditionalNotify1_Name: facilityType.General.AdditionalNotify1_Name,
          AdditionalNotify1_Email: facilityType.General.AdditionalNotify1_Email,
          AdditionalNotify2_Name: facilityType.General.AdditionalNotify2_Name,
          AdditionalNotify2_Email: facilityType.General.AdditionalNotify2_Email,
          GLAccountID_Default: facilityType.General.GLAccountID_Default,
        },
        ValidationRules: {...FacilityTypeGeneralFormDefinition},
      },
      BookingTimes: {
        ActiveForm: {
          ...state.BookingTimes.ActiveForm,
          StartHour: Number(checkinTokens[0]) >= 0 ? processHour(Number(checkinTokens[0])) : 0,
          StartMin: Number(checkinTokens[1]),
          StartPeriod: Number(checkinTokens[0]) > 11 ? 1 : 0,
          EndHour: Number(checkoutTokens[0]) >= 0 ? processHour(Number(checkoutTokens[0])): 0,
          EndMin: Number(checkoutTokens[1]),
          EndPeriod: Number(checkoutTokens[0]) > 11 ? 1 : 0,
          IsCheckoutNextDay: facilityType.BookingTimes.IsCheckoutNextDay,
        },
        ValidationRules: {...FacilityTypeBookingTimesFormDefinition},
      },
      Attributes: {
        ...state.Attributes.ActiveForm,
        Values: facilityType.Attributes ? [...facilityType.Attributes] : [{ID: -1, Name: '', Inactive: false, TS: 0}],
        ValidationRules: {
          ...getAttributeDefinition(facilityType.Attributes || [{ID: -1, Name: '', Inactive: false}]),
        },
        ActiveForm: {
          ...state.Attributes.ActiveForm,
          ...initAttributesForm(facilityType.Attributes || [{ID: -1, Name: '', Inactive: false}]),
        }
      },
    };
    return newState;
  }

  return state;
};

const FacilityTypeFormReducer: Reducer<FacilityTypeFormState> = (s, act: WithRootState<AnyAction>) => {
  const state = { ...getInitialState(), ...s } as any;

  if (s && s.FacilityTypeRow) state.FacilityTypeRow = checkApiValidation(s.FacilityTypeRow, act);
  if (s && s.General) state.General = checkGeneralApiValidation(s.General, act);
  if (s && s.BookingTimes) state.BookingTimes = checkBookingTimesApiValidation(s.BookingTimes, act);
  if (s && s.Attributes) state.Attributes = checkAttributesApiValidation(s.Attributes, act);

  if (act.type.includes(API_UPD) && act.type.includes(UPDATE_FACILITY_FACILITY_TYPE_FORM_SUFFIX)) {
    if (shouldSkipUpdateForm(act)) return state;
    let newState = getInitialState() as FacilityTypeFormState;
    const rootState = act.rootState;
    const facilityType = rootState.adminFacilityLocation.cacheTwoFacilityTypes.FacilitiesType;

    newState.General = setDefaults(act.rootState, newState.General, state.General);
    newState.BookingTimes = setDefaults(act.rootState, newState.BookingTimes, state.BookingTimes);
    newState.FacilityTypeRow = setDefaults(act.rootState, newState.FacilityTypeRow, state.FacilityTypeRow);
    newState.Attributes = setDefaults(act.rootState, newState.Attributes, state.Attributes);

    newState = setFacilityType(newState, facilityType);

    return newState;
  } else if (isActionType(act, DeleteAttribute)) {
    const newState = {
      ...state,
      Attributes: {
        ...state.Attributes,
        Values: state.Attributes.Values.map((attr, index) => {
          if (index === act.index) {
            return {
              ...attr,
              Inactive: act.restore,
            };
          }
          return attr;
        }).filter((attr, index) => {
          // filter out newly added but inactive attr
          return !(attr.ID === -1 && attr.Inactive);
        }),
      }
    };

    const attr = state.Attributes.Values.find((attr, index) => index === act.index);

    // if newly added, remove it from active form and validation rules
    if (attr && attr.ID === -1) {
      delete newState.Attributes.ActiveForm[`AttributeName${act.index}`];
      delete newState.Attributes.ActiveForm[`AttributeInactive${act.index}`];
      delete newState.Attributes.ValidationRules[`AttributeName${act.index}`];
      delete newState.Attributes.ValidationRules[`AttributeInactive${act.index}`];
    }

    return newState;
  } else if (isActionType(act, NewAttribute)) {
    let count = 0;
    for (let key of Object.keys(state.Attributes.ActiveForm)) {
      if (key.includes('AttributeName')) {
        count += 1;
      }
    }
    const newState = {...state};

    newState.Attributes.ActiveForm[`AttributeName${count}`] = '';
    newState.Attributes.ActiveForm[`AttributeInactive${count}`] = false;
    newState.Attributes.ValidationRules[`AttributeName${count}`] = getAttributeNameValidationRule(`AttributeName${count}`);
    newState.Attributes.ValidationRules[`AttributeInactive${count}`] = getAttributeInactiveValidationRule(`AttributeInactive${count}`);
    newState.Attributes.Values = [...newState.Attributes.Values, {ID: -1, Name: '', Inactive: false}];

    return newState;
  } else if (isActionType(act, ClearAdminFacilityLocationCacheTwoFacilityType) || isActionType(act, ClearAdminFacilityLocationCacheBelowOne)) {
    return getInitialState();
  }

  return state || getInitialState();
};

export default FacilityTypeFormReducer;