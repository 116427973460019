
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetFacilityType} from "./actions";
import { getFacilityTypeBody, getFacilityType } from "../../../constants/urls";

export const adminFacilityLocationCacheTwoFacilityTypesEpic = makeRequestEpic(
  GetFacilityType,
  getFacilityType,
  getFacilityTypeBody,
  true,
);