import {ApiSubmitActions, ApiSubmitDeleteActions, InitializeAddDefaultValues} from './actions';
import {updateGroupRosterPerson} from '../../../../constants/urls';
import {makeRequestEpic} from '../../../Validation/epicCreator';
import {
  ADD_ROSTER_SUCCESS,
  DELETE_ROSTER_SUCCESS,
  EDIT_ROSTER_SUCCESS
} from '../../../../constants/messages/participants';
import { reduxStoreService } from '../../../service';

export const updateRosterPersonEpic = makeRequestEpic(
  ApiSubmitActions,
  updateGroupRosterPerson,
  undefined,
  true,
  () => {
    const lastLocation = reduxStoreService().getState().app.previousLocation;
    if (lastLocation.pathname.includes('add')) {
      return ADD_ROSTER_SUCCESS;
    }
    return EDIT_ROSTER_SUCCESS;
  }
);

export const deleteRosterPersonEpic = makeRequestEpic(
  ApiSubmitDeleteActions,
  updateGroupRosterPerson,
  undefined,
  true,
  () => DELETE_ROSTER_SUCCESS
);