import {AjaxResponse} from "rxjs/ajax";
import {NameAndID} from "../models/api/options";
import device from 'current-device';
import { captureTentarooError } from "./dataHelper";

// actually turns spaces into - now and removes all non-alphanumeric
export const spaceTo_ = (str: string) => {
  if (!str) return '';
  const dashString = str.replace(/ /g, '-');
  const alphaString = dashString.replace(/[^0-9a-zA-Z\-]/g, '');
  return alphaString;
};

export const getMessageFromErrorResponse = (response: AjaxResponse) => {
  if (response && response.xhr && response.xhr.response && response.xhr.response.error) {
    const error = response.xhr.response.error;
    if (error.Type === 'Database' && error.Message.includes('Error Executing') && error.Detail) {
      // remove `[SQLServer]` string and anything before it
      const tokens = response.xhr.response.error.Detail.split('[SQLServer]');
      if (tokens.length > 1) {
        return tokens[1];
      }
      return response.xhr.response.error.Detail;
      // return response.xhr.response.error.Detail.replace(/ *\[[^)\[\]]+\] */g, "");
    } else {
      return response.xhr.response.error.Message;
    }
  }
  return;
};

export const copyStringToClipboard = str =>
{
  const chromeAgent = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  const safariAgent = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
  // reference: https://josephkhan.me/javascript-copy-clipboard-safari/
  const textArea = document.createElement('textarea');
  textArea.readOnly = true;
  textArea.contentEditable = true as any;
  textArea.value = str;
  document.body.appendChild(textArea);

  if (device.ios() || (safariAgent && !chromeAgent)) {
    // do this in iOS mobile and also Safari browser
    const range = document.createRange();
    range.selectNodeContents(textArea);
    const selection = window.getSelection();

    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    }
  } else {
    textArea.select();
  }
  document.execCommand('copy');
  document.body.removeChild(textArea);
};

// blankIfEmpty
export const bie = (o?: any) => {
  if (o !== undefined && o !== null) return o;
  return "";
};

// nullIfEmpty
export const nie = (o?: any) => {
  if (o !== undefined && o !== null && o !== "") return o;
  return null;
};

// zeroIfEmpty
export const zie = (o?: any) => {
  if (o !== undefined && o !== null && o !== "") return o;
  return 0;
};

export const getName = (options: Array<NameAndID>, id) => {
  const found = options.find((opt) => opt.ID === id);
  if (!found) {
    captureTentarooError(new Error('Could not find element with id ' + id + '. returning blank string.'));
    return "";
  }
  return found.Name;
};

export const makeFirstLetterUppercase = (str: string) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const USERNAME_REGEX = /^[^\^'#%()?¡¿',"&|°!<>;: ^¨*{}\[\]``~]*$/;