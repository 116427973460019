import {OrderedProduct, Product} from "../../models/product";
import {CancelProductProducts} from "../Events/Event/Register/Products/Products/actions";
import {CancelProductNumbers} from "../Events/Event/Register/Numbers/Products/actions";
import { captureTentarooError } from "../../utils/dataHelper";
import { reduxStoreService } from "../service";

export const addProductToState = (state: any, notAddedKey: string, addedKey: string, act: any) => {
  const newState = {...state};
  if (state[notAddedKey]) newState[notAddedKey] = [...state[notAddedKey]];
  else newState[notAddedKey] = [];

  if (state[addedKey]) newState[addedKey] = [...state[addedKey]];
  else newState[addedKey] = [];

  const foundProductIndex = newState[notAddedKey].findIndex(p => p.ProductIDi === act.product.ProductIDi);
  if (foundProductIndex === -1) {
    captureTentarooError(new Error(`Cannot add product with id ${act.product.ProductIDi}, it wasn't in the adding list? should never happen`));
    return state;
  }
  // newState[notAddedKey][foundProductIndex] = {...state[notAddedKey][foundProductIndex], isAdded: true};
  const foundProductAddedIndex = newState[addedKey].findIndex(p => {
    return p.Amount === act.product.Amount && p.ProductIDi === act.product.ProductIDi && p.IsNewlyAdding && p.Quantity + act.product.Quantity <= 100 && !p.Inactive;
  });
  const newItems = newState[addedKey].filter(item => item.IsNewlyAdding);
  if (foundProductAddedIndex === -1) {
    // if no valid product was found, push a new one with the given quantity
    newState[addedKey].push({
      ...act.product,
      IsNewlyAdding: true,
      Inactive: false,
      InCart: 1,
      DateOrdered: null,
      ID: (newItems.length + 1) * -1,
      editQuantity: act.product.Quantity,
      editAmount: act.product.Amount
    });
  } else {
    const quant = state[addedKey][foundProductAddedIndex].Quantity + act.product.Quantity;

    newState[addedKey][foundProductAddedIndex] = {
      ...state[addedKey][foundProductAddedIndex],
      IsNewlyAdding: true,
      Inactive: false,
      Quantity: quant
    };
  }
  return newState;
};

export const removeProductToState = (state: any, notAddedKey: string, addedKey: string, act: any) => {
  const newState = {...state};
  if (state[notAddedKey]) newState[notAddedKey] = [...state[notAddedKey]];
  else newState[notAddedKey] = [];

  if (state[addedKey]) newState[addedKey] = [...state[addedKey]];
  else newState[addedKey] = [];

  const foundProductAddedIndex = newState[addedKey].findIndex(p => {
    return p.ID === act.product.ID;
  });
  if (foundProductAddedIndex === -1) {
    captureTentarooError(new Error(`Cannot remove product with id ${act.product.ProductIDi}, it wasn't in the adding list? should never happen`));
    return state;
  }
  newState[addedKey][foundProductAddedIndex] = {
    ...newState[addedKey][foundProductAddedIndex],
    Inactive: true
  };

  return newState;
};

export const updateAvailableProductToState = (state: any, notAddedKey: string, act: any) => {
  const newState = {...state};
  ;if (state[notAddedKey]) newState[notAddedKey] = [...state[notAddedKey]];
  else newState[notAddedKey] = [];

  const foundProductIndex = newState[notAddedKey].findIndex(p => p.ProductIDi === act.product.ProductIDi);
  if (foundProductIndex === -1) {
    captureTentarooError(new Error(`Cannot add product with id ${act.product.IDi}, it wasn't in the adding list? should never happen`));
    return state;
  }
  // only ever update quantity on the available product list
  if (act.attrs.Quantity) {
    newState[notAddedKey][foundProductIndex] = {...newState[notAddedKey][foundProductIndex], Quantity: act.attrs.Quantity};
  }
  return newState;

};

export const updateAddedProductToState = (state: any, addedKey: string, act: any) => {
  const newState = {...state};

  if (state[addedKey]) newState[addedKey] = [...state[addedKey]];
  else newState[addedKey] = [];
  const foundProductIndex = newState[addedKey].findIndex(p => p.ID === act.product.ID);
  if (foundProductIndex === -1) {
    captureTentarooError(new Error(`Cannot add product with id ${act.product.ID}, it wasn't in the adding list? should never happen`));
    return state;
  }
  if (act.attrs.Quantity) {
    newState[addedKey][foundProductIndex] = {
      ...newState[addedKey][foundProductIndex],
      editQuantity: act.attrs.Quantity
    };
  }
  if (act.attrs.Amount !== undefined) {
    const newAmount: string = act.attrs.Amount;

    newState[addedKey][foundProductIndex] = {
      ...newState[addedKey][foundProductIndex],
      editAmount: newAmount
    };
  }
  return newState;
};

let managingId;

const isClickInProduct = (eTarget: any): boolean => {
  if (eTarget.classList && eTarget.classList.value.includes(`product--id--${managingId}`)) {
    return true;
  }
  if (eTarget.parentElement) {
    return isClickInProduct(eTarget.parentElement);
  }
  return false;
};

const isClickNextButton = (eTarget: any): boolean => {
  if (eTarget.classList && eTarget.classList.value.includes(`elements--footer--progress--next`)) {
    return true;
  }
  if (eTarget.parentElement) {
    return isClickNextButton(eTarget.parentElement);
  }
  return false;
};

const isClickManageButton = (eTarget: any): boolean => {
  if (eTarget.classList && eTarget.classList.value.includes(`product--manage-button`)) {
    return true;
  }
  if (eTarget.parentElement) {
    return isClickManageButton(eTarget.parentElement);
  }
  return false;
};

const manageListener = (e) => {
  let inNextButton = isClickNextButton(e.target);
  if (inNextButton) return;
  let inProduct = isClickInProduct(e.target);
  let inManageButton = isClickManageButton(e.target);
  if (!inProduct && !inManageButton) {
    // todo: is there a better way to do this?
    // NOTE: There is a bug here... we are canceling product in both wizard but there is
    // only one wizard being opened... and hence whenever a product is canceld by clicking on the page,
    // the error message in `cancelAddedProductToState` will be captured because one of the wizard cache doesn't have
    // any product loaded yet
    // TODO: In general, this `managingId` is a bad pattern, we should move it into Redux, and have one such field
    // per cache (i.e. CacheFourEventsProducts and CacheFourEventsNumbers), then use common
    // logic to handle them.
    // TODO: We should move all these Add/Manage/Commit/Cancel out of cache reducers??
    const productIDToCancel = managingId;
    // eslint-disable-next-line no-restricted-syntax
    reduxStoreService().dispatch(new CancelProductProducts({ID: productIDToCancel} as any));
    // eslint-disable-next-line no-restricted-syntax
    reduxStoreService().dispatch(new CancelProductNumbers({ID: productIDToCancel} as any));
    document.removeEventListener('click', manageListener);
  }
};

export const manageAddedProductToState = (state: any, addedKey: string, act: any) => {
  const newState = {...state};

  if (state[addedKey]) newState[addedKey] = [...state[addedKey]];
  else newState[addedKey] = [];
  const foundProductIndex = newState[addedKey].findIndex(p => p.ID === act.product.ID);
  for (let i = 0; i < newState[addedKey].length; i++) {
    const prod = newState[addedKey][i];
    if (prod.ID === act.product.ID) {
      newState[addedKey][i] = {
        ...newState[addedKey][i],
        isManaging: true,
        editQuantity: act.product.Quantity,
        editAmount: act.product.Amount
      };
    } else {
      newState[addedKey][i] = {
        ...newState[addedKey][i],
        isManaging: false
      };
    }
  }
  document.addEventListener('click', manageListener);
  managingId = act.product.ID;
  return newState;
};

export const cancelAddedProductToState = (state: any, addedKey: string, act: any) => {
  const newState = {...state};
  if (state[addedKey]) newState[addedKey] = [...state[addedKey]];
  else newState[addedKey] = [];
  const foundProductIndex = newState[addedKey].findIndex(p => p.ID === act.product.ID);
  if (foundProductIndex === -1) {
    // TODO: comment this back when TODOs in `manageListener` are resolved. Commenting this out for now
    // because it is creating noises in Sentry!
    // captureTentarooError(new Error(`Cannot add product with id ${act.product.ID}, it wasn't in the adding list? should never happen`));
    return state;
  }
  document.removeEventListener('click', manageListener);
  managingId = undefined;
  newState[addedKey][foundProductIndex] = {
    ...newState[addedKey][foundProductIndex],
    isManaging: false
  };
  return newState;
};

export const commitAddedProductToState = (state: any, addedKey: string, act: any) => {
  const newState = {...state};

  if (state[addedKey]) newState[addedKey] = [...state[addedKey]];
  else newState[addedKey] = [];
  const foundProductIndex = newState[addedKey].findIndex(p => p.ID === act.product.ID);
  if (foundProductIndex === -1) {
    captureTentarooError(new Error(`Cannot add product with id ${act.product.ID}, it wasn't in the adding list? should never happen`));
    return state;
  }
  document.removeEventListener('click', manageListener);
  managingId = undefined;
    newState[addedKey][foundProductIndex] = {
    ...newState[addedKey][foundProductIndex],
    isManaging: false,
    Amount: parseFloat(parseFloat(act.product.editAmount).toFixed(2)),
    Quantity: act.product.editQuantity
  };
  return newState;
};

export const resetManagingProductsToState = (state: any, addedKey: string) => {
  const newState = {...state};

  if (state[addedKey]) newState[addedKey] = [...state[addedKey]];
  else newState[addedKey] = [];
  for (let i = 0; i < newState[addedKey].length; i++) {
    const prod = newState[addedKey][i];
    if (prod.isManaging) {
      newState[addedKey][i] = {
        ...newState[addedKey][i],
        isManaging: false
      };
    }
  }

  return newState;
};

export const productAvailableSort = (a: Product, b: Product) => {
  if (a.Name.toLowerCase() === b.Name.toLowerCase()) {
    return 0;
  } else if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
    return -1;
  } else {
    return 1;
  }
};

export const productOrderedSort = (a: OrderedProduct, b: OrderedProduct) => {
  if (a.DateOrdered && a.DateOrdered.isBefore(b.DateOrdered)) {
    return 1;
  } else if (b.DateOrdered && b.DateOrdered.isBefore(a.DateOrdered)) {
    return -1;
  }

  if (a.Name.toLowerCase() === b.Name.toLowerCase()) {
    return 0;
  } else if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
    return -1;
  } else {
    return 1;
  }
};
