import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/empty-message/index.scss';

export const namespace = 'elements--empty-message';

export enum EmptyMessageType {
  PAGE_CENTER='page-center',
  PAGE_MARGIN='page-margin',
  CARD='card',
}

export interface EmptyMessageProps {
  className?: string;
  icon: React.ReactType;
  description: string;
  detail?: string;
  actions?: React.ReactNode;
  small?: boolean;
  fixedMargin?: boolean;
  iconWidth?: string;
  iconHeight?: string;
  fixedFontSize?: boolean;
  type?: EmptyMessageType;
  admin?: boolean;
  noMobilePadding?: boolean;
}

const EmptyMessage: React.SFC<EmptyMessageProps> = (props: EmptyMessageProps): React.ReactElement<EmptyMessageProps> => {
  const { small, fixedMargin, iconWidth, iconHeight, fixedFontSize, detail, type, admin, noMobilePadding } = props;
  let className = mergeClassNames(namespace, props);
  const style: React.CSSProperties = {};
  if (small) className += ' small';
  if (fixedMargin) className += ' fixed-margin';
  if (fixedFontSize) className += ' fixed-fontsize';
  if (iconHeight) style.flex = `0 0 ${iconHeight}`;
  if (detail) className += ' has-detail';
  if (type) className += ` ${type}`;
  if (admin) className += ' admin';
  if (noMobilePadding) className += ' no-mobile-padding';

  return (
    <section className={className}>
      <div className={`${namespace}--content`}>
        <props.icon style={style} width={iconWidth || '72px'} height={iconHeight || '72px'} className={`${namespace}--content--icon`}/>
        <span className={`${namespace}--content--text`}>
          {props.description}
        </span>
        {detail ? <span className={`${namespace}--content--detail`}>{detail}</span> : null}
        <div className={`${namespace}--content--actions`}>
          {props.actions}
        </div>
      </div>
    </section>
  );
};

export default EmptyMessage;
export { default as Action } from './Action';
