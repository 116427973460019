import {ActionCreator} from '../';
import {CLEAR_CACHE_PREFIX, createApiSubmitActions, createRequestActions} from '../Validation/actionCreator';
import {
  FAC_TRIP_ADD,
  FAC_TRIP_RESERVATION,
  FAC_TRIP_RESERVATION_EDIT,
  getFacilitiesAvailitiesC4Body,
  getFacilitiesReservationsSaveBody,
  getFacilityReservationInitBody,
  GetFacilityReservationInitParams
} from "../../constants/urls";
import {validateAll} from "../../utils/validator";
import {FacilitiesConfirmationActions} from "../Facilities/Trip/Reservation/Confirmation/actions";
import {push} from "react-router-redux";
import {SaveState} from "../Rollback/actions";
import {getTotalAmount} from "../../utils/helpers/financialSummaryHelper";
import { captureTentarooError } from '../../utils/dataHelper';
import { typeName, Action } from '../../utils/StrongActions';
import { TripPaymentStatus } from '../../models/api/cacheFourFacilities';

export const SUFFIX = '__CACHE_FOUR_FACILITIES';
export const FacilitiesCalc = createRequestActions(SUFFIX);
export const SUFFIX_RECALC = '__CACHE_FOUR_FACILITIES_RECALC';
export const FacilitiesRecalc = createRequestActions(SUFFIX_RECALC);
export const SUFFIX_SAVE = '__CACHE_FOUR_FACILITIES_SAVE';
export const FacilitiesSave = createApiSubmitActions(SUFFIX_SAVE);

export const SUFFIX_TYPE = '__CACHE_FOUR_TYPE';
export const FacilitiesTypeC4 = createRequestActions(SUFFIX_TYPE);

@typeName(`${CLEAR_CACHE_PREFIX}_CACHE${SUFFIX}`)
export class ClearCacheFourFacilities extends Action {}

@typeName(`SAVE_OVERRIDE_FEES_${SUFFIX}`)
export class ReservationSaveOverrideFees extends Action{
  constructor(public FlatRate: number, public PerPersonRate: number, public Amount: number, public AmountChangeInCart: number, public TripPaymentStatus?: TripPaymentStatus | null) {
    super();
  }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  facilitiesLoad: (params: GetFacilityReservationInitParams): ActionCreator => dispatch => {
    dispatch(FacilitiesCalc.request(getFacilityReservationInitBody(params)));
  },
  facilitiesSave: (): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const confirmationValidation = validateAll(s => s.facilities.trip.reservation.confirmation);

    if (confirmationValidation) {
      dispatch(FacilitiesConfirmationActions.clientFailure(confirmationValidation, false));
      return;
    }

    const rootState = getState();
    const c4 = rootState.cacheFourFacilities;
    const options = rootState.cacheZero.options;
    const Reservation = c4.Reservation!;

    if (!options) {
      captureTentarooError(new Error("options not available when performing facilitiesSave"));
      return;
    }
    if (!options.Group) {
      captureTentarooError(new Error("Group not available when performing facilitiesSave"));
      return;
    }
    if (!c4.TripPaymentStatus) {
      captureTentarooError(new Error("c4.TripPaymentStatus not available when performing facilitiesSave"));
      return;
    }
    if (!c4.FacilityAvailabilities) {
      captureTentarooError(new Error("c4.FacilityAvailabilities not available when performing facilitiesSave"));
      return;
    }
    // let newAmount = 0;
    let newAmount: undefined | number = getTotalAmount(
      rootState.facilities.trip.reservation.confirmation.ActiveForm,
      c4.TripPaymentStatus
    );
    if (newAmount !== undefined) {
      newAmount = newAmount - c4.TripPaymentStatus.AmountInCart;
    }

    if (c4.FacilityLocation_SelectedFacilityTypeID === null) {
      captureTentarooError(new Error("CacheFourFacilities FacilityLocation_SelectedFacilityTypeID is null when performing facilitiesSave"));
      return;
    }
    const params: GetFacilityReservationInitParams = {
      GroupIDi: options.Group.IDi,
      GroupTS: options.Group.TS,
      FacilityLocationID: rootState.cacheTwoFacilities.locationID,
      FacilityTripID: rootState.cacheThreeFacilities.tripID,
      FacilityTypeID: c4.FacilityLocation_SelectedFacilityTypeID
    };
    if (Reservation.ReservationID) {
      params.ReservationID = Reservation.ReservationID;
    }

    if (Reservation.FacilityID === null) {
      captureTentarooError(new Error("Reservation.FacilityID is null when performing facilitiesSave"));
      return;
    }
    if (Reservation.FlatRate === null) {
      captureTentarooError(new Error("Reservation.FlatRate is null when performing facilitiesSave"));
      return;
    }
    if (Reservation.PerPersonRate === null) {
      captureTentarooError(new Error("Reservation.PerPersonRate is null when performing facilitiesSave"));
      return;
    }
    if (Reservation.MinimumPeopleBilledAtPerPersonRates === null) {
      captureTentarooError(new Error("Reservation.MinimumPeopleBilledAtPerPersonRates is null when performing facilitiesSave"));
      return;
    }
    const body = getFacilitiesReservationsSaveBody(
      params,
      Reservation.FacilityID,
      Reservation.NumYouth!,
      Reservation.NumAdults!,
      Reservation.FlatRate,
      Reservation.PerPersonRate,
      Reservation.MinimumPeopleBilledAtPerPersonRates,
      c4.FacilityAvailabilities.ftbID,
      Reservation.StartDateTime!,
      Reservation.EndDateTime!,
      Reservation.AmountChangeInCart!,
      newAmount!
    );

    const route = getState().routing.locationBeforeTransitions;
    let newPath = '';
    if (route.pathname.includes(`/${FAC_TRIP_RESERVATION}/${FAC_TRIP_ADD}`)) {
      newPath = route.pathname.split(`/${FAC_TRIP_RESERVATION}/${FAC_TRIP_ADD}`)[0];
    } else if (route.pathname.includes(`/${FAC_TRIP_RESERVATION_EDIT}`)) {
      newPath = route.pathname.split(`/${FAC_TRIP_RESERVATION}/`)[0];
    }

    dispatch(push(`${newPath}${route.search}`));
    // move before request so the LoadingAll spinner won't block you
    dispatch(FacilitiesSave.request(body, null));

  },
  getFacilitiesTypes: (params: GetFacilityReservationInitParams): ActionCreator => dispatch => {
    dispatch(FacilitiesTypeC4.request(getFacilitiesAvailitiesC4Body(params)));
  },
  clearCacheFourFacilities: (): ActionCreator => dispatch => {
    dispatch(new ClearCacheFourFacilities());
  },
};
