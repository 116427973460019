import { validateAll } from "../../../../utils/validator";
import { ShowTopFloatingAlert, SilentCancelAllAction } from "../../../App/actions";
import { SaveState } from "../../../Rollback/actions";
import { formatDate } from "../../../../utils/dateHelper";
import { extractRouteParams } from "../../../../utils/urlHelper";
import { RouteComponentProps } from "react-router";
import { GetSiteCacheBodyParams } from "../../../../constants/urls";
import { IValidator, PageHomeActiveForm, PagesHomeState } from "./index.types";
import { PageTypeModalActiveFormState, PageTypeModalState, IValidator as ISelectPageModalValidator } from "../Modals/SelectPage/index.type";
import { ModalTypes, isModalOpened } from "../../../../utils/modalHelper";
import { reduxStoreService } from "../../../service";
import { Validator } from "../../../../utils/validator/models";


/**
 * NOTE: Never call this in reducer
 */
export const validateAndGetParamsOnPageFilterChange = (
  v,
  vObj,
  key: string,
  updateValue: (value: any, vObj: Validator) => void,
  ActiveForm: PageHomeActiveForm | PageTypeModalActiveFormState,
  ValidationRules: IValidator | ISelectPageModalValidator,
  stateSelector: () => PageTypeModalState | PagesHomeState,
  onCalendarEventInvalid: () => void,
  props: RouteComponentProps<{}, {}>,
): GetSiteCacheBodyParams | undefined => {
  // First, update field immediately
  updateValue(v, vObj);

  const validateResult = validateAll(stateSelector);
  if (validateResult && validateResult.Errors && validateResult.Errors.EventStartDate) {
    onCalendarEventInvalid();
    reduxStoreService().dispatch(new SilentCancelAllAction(false, true));
    reduxStoreService().dispatch(new SaveState());
    reduxStoreService().dispatch(new ShowTopFloatingAlert(validateResult.Errors.EventStartDate[0], isModalOpened(ModalTypes.SELECT_PAGE), 'orange'));
    return undefined;
  }

  // If valid, construct and return params
  const params = constructGetPageListBaseParams(ActiveForm, ValidationRules.EventStartDate, props);

  // If we ARE setting `EventStartDate`, and at this point it should be a valid value, then
  // format it and set it to request body
  params.CMSPagesFilters[key] = key === "EventStartDate" ? formatDate(v) : v;

  return params;
};


export const constructGetPageListBaseParams = (ActiveForm: PageHomeActiveForm | PageTypeModalActiveFormState, EventStartDateRule: Validator, props: RouteComponentProps<{}, {}>,) => {
  // If valid, construct and return params
  const params = {
    GetPages: true,
    CMSPagesFilters: {
      ...ActiveForm,
      // When NOT changing `EventStartDate`:
      // - skip this field if we are NOT selecting calender event;
      // - otherwise, grab `EventStartDate` in form
      // NOTE: It is safe to call `getState()` here because this is not called from reducer
      EventStartDate: (!!EventStartDateRule.skip && !!EventStartDateRule.skip(reduxStoreService().getState())) ? null : formatDate(ActiveForm.EventStartDate),
    },
    ...extractRouteParams(props),
  };

  return params;
};