import {FormDefinition} from "./validation";
import { apiValidationReducerCreator } from "../../../Validation/reducerCreator";
import {SUFFIX} from "./actions";
import * as Actions from './actions';
import { ClearAllAdminCMSSiteCache, ADMIN_CMS_MODULE_SUFFIX } from "../../CacheOne/actions";
import * as moment from 'moment';
import { APISuccess, SUBMIT_SUCCESS_ACTION, SUCCESS_ACTION, WithRootState } from "../../../../store/Validation/actionCreator";
import { convertObjToMoment } from "../../../../utils";
import { Reducer } from "redux";
import { Action, isActionType } from "../../../../utils/StrongActions";
import { PagesHomeState } from "./index.types";
import { ModalTypes, isModalOpened } from "../../../../utils/modalHelper";
import { setDefaults } from "../../../../utils/validator";
import {ClearAllCache} from "../../../App/actions";

const getInitialState: () => PagesHomeState = () => ({
  ActiveForm: {
    PageTypeID: 0,
    FilterText: "",
  },
  ValidationRules: {...FormDefinition},
  expandFilter: false,
  filterInvalid: false,
});

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const PagesHomeReducer: Reducer<PagesHomeState> = (s, act: WithRootState<Action>) => {
  const state = checkApiValidation(s, act);
  const rootState = act.rootState;

  // TODO: - use init action - another form that need to be init together with Settings
  if (act.type.includes(ADMIN_CMS_MODULE_SUFFIX) && (act.type.includes(SUBMIT_SUCCESS_ACTION) || act.type.includes(SUCCESS_ACTION))) {
    // Will update the filter in this node whenever server returns one
    // Here we wont need to handle special case for PageList scenario, because every time 
    // the page modal is opened, it will call the `Init` action to retreive the default value
    // from cache one. See handling of `InitPageTypesModal` below
    const action = <WithRootState<APISuccess>>act;
    const newState = {...state};
    const res = action.response.xhr.response;

    // Set CMSPagesFilters if we are NOT in page modal. We should handle all other special cases here
    if (!isModalOpened(ModalTypes.SELECT_PAGE, act.rootState) && res.CMSPagesFilters) {
      // get CMSPageFilters
      const CMSPagesFilters = res.CMSPagesFilters;
      if (CMSPagesFilters) {
        const filters = convertObjToMoment(CMSPagesFilters, ['EventStartDate']);
        if (!filters.EventStartDate) filters.EventStartDate = moment();
        newState.ActiveForm = {
          ...newState.ActiveForm,
          ...filters,
          EventCategoryID: filters.EventCategoryID ? filters.EventCategoryID : 0,
        };
      }
    }

    return newState;
  } else if (isActionType(act, Actions.ToggleFilterInvalid)) {
    return {...state, filterInvalid: act.invalid};
  } else if (isActionType(act, Actions.ToggleExpandFilterAction)) {
    return {...state, expandFilter: act.expand};
  } else if (isActionType(act, ClearAllAdminCMSSiteCache) || isActionType(act, ClearAllCache)) {
    return setDefaults(rootState, getInitialState());
  } else if (isActionType(act, Actions.PagesHomeReset)) {
    // NOTE: Here we are not using `setDefaults` because we want to preserve backend filter while resetting
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        FilterText: '',
      }
    };
  }
  return state || setDefaults(rootState, getInitialState());
};

export default PagesHomeReducer;