import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../../../store';
import { appActionCreators, makeFormModalPropSelector } from '../../../../store/App';
import { ModalTypes } from '../../../../utils/modalHelper';
import { getMergeProps } from '../../../../utils/reduxHelper';
import {
  Modal, ModalTitle, Icon, ModalContent, ModalActions, Text, Button
} from '../../../Elements';
import { CheckMarkIcon } from '../../../Icons';
import {WithInertAttribute} from '../../../Elements/WithInert';

type Props = WithInertAttribute<{}>;
type ConnectedProps = 
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class OrderSubmitted extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  onClose = () => {
    this.props.actions.popModal(false, true, ModalTypes.ORDER_SUBMITTED);
  };

  render() {
    return (
      <Modal inert={this.props.inert} disableDynamicHeight big>
        <ModalTitle center>
          <Icon green icon={CheckMarkIcon}/>
          <span>Order Submitted</span>
        </ModalTitle>
        <ModalContent center paddingTop={0}>
          <Text color="gray">
            Your order has successfully been submitted.
          </Text>
        </ModalContent>
        <ModalActions
          noPadding
          left={<Button flat onClick={() => window.open(this.props.receiptUrl)}>VIEW RECEIPT</Button>}
          right={<Button onClick={this.onClose} flat>CLOSE</Button>}
        />
      </Modal>
    );
  }
};

const mapStateToProps = (state: ApplicationState) => {
  const receiptUrlSelector = makeFormModalPropSelector(ModalTypes.ORDER_SUBMITTED, 'receiptUrl');
  return {
    receiptUrl: receiptUrlSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...appActionCreators }, dispatch)
});

const ConnectedOrderSubmittedModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(OrderSubmitted);

export default ConnectedOrderSubmittedModal;
