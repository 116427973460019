
import { APISuccess } from "../../../store/Validation/actionCreator";
import { ClearAdminEventsCacheThreeClassRequirements, GetAdminClassRequirements } from './actions';
import { ClearAllCache, SetCacheAction } from "../../../store/App/actions";
import { ADMIN_EVENT_TYPES_SUFFIX, ClearAdminEventsCacheBelowOne, ClearAllAdminEventsCache } from "../CacheOne/actions";
import { shouldSkipResponseForCache } from "../../Validation/reducerCreator";
import { ClearAdminEventsCacheTwoEvent, ClearAdminEventsCacheBelowTwoEvent } from "../CacheTwoEvent/actions";
import { AdminEventClassParticipant, AdminEventClassRequirements, MBRequirement } from "../../../models/api/adminEventsCacheTwoEvent";
import { Reducer } from "redux";
import { Action, isActionType } from "../../../utils/StrongActions";
import {ApplicationState} from "../..";

const adminEventsCacheThreeClassRequirementsKeys = {
  EventsEventClassRequirements: true,
  EventsEventClassParticipants: true,
  EventsEventClass_AllMBRequirements: true,
};

export interface AdminEventsCacheThreeClassRequirementsState {
  EventsEventClassRequirements?: AdminEventClassRequirements;
  EventsEventClassParticipants?: AdminEventClassParticipant[];
  /**
   * All requirements for the MB if mbID is set, so new entry method
   * 
   * TODO: Can we relate its typing to `EventsEventClassRequirements` where mbID is set?
   */
  EventsEventClass_AllMBRequirements?: MBRequirement[] | null;
}

export const classRequirementsSelector = (state: ApplicationState) => state.adminEvents.cacheThreeClassRequirements.EventsEventClassRequirements?.Requirements;

const setAdminEventsCacheThreeClassRequirementsData = (state, data): AdminEventsCacheThreeClassRequirementsState => {
  const result: any = {...state};

  for (let key of Object.keys(data)) {
    // will skip if a field is undefined
    if (adminEventsCacheThreeClassRequirementsKeys[key] && data[key] !== undefined) {
      result[key] = data[key];
    }
  }

  return result;
};

const handleResponse = (state, innerResponse,) => {
  if (innerResponse.EventsEventClassRequirements) {
    const newState: AdminEventsCacheThreeClassRequirementsState = setAdminEventsCacheThreeClassRequirementsData(state, innerResponse);
    return newState;
  }
};

const AdminEventsCacheThreeClassRequirementsReducer: Reducer<AdminEventsCacheThreeClassRequirementsState> = (state: AdminEventsCacheThreeClassRequirementsState, act: Action) => {
  if (act.type === GetAdminClassRequirements.successType || (isActionType(act, SetCacheAction) && act.actions.requestType.includes(ADMIN_EVENT_TYPES_SUFFIX))) {
    const action = <APISuccess> act;
    if (shouldSkipResponseForCache(act)) return state;

    const newState = handleResponse(state, action.response.response) || {...state};
    return newState;
  } else if (isActionType(act, ClearAdminEventsCacheTwoEvent) || isActionType(act, ClearAdminEventsCacheBelowTwoEvent) || isActionType(act, ClearAdminEventsCacheThreeClassRequirements) || isActionType(act, ClearAdminEventsCacheBelowOne) || isActionType(act, ClearAllAdminEventsCache) || isActionType(act, ClearAllCache)) {
    return {};
  }
  return state || {};
};
  
export default AdminEventsCacheThreeClassRequirementsReducer;