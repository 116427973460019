import * as NM from "../../../../../../constants/messages/numbersWizard";
import * as GM from "../../../../../../constants/messages/generic";
import {EventRegistrationParticipantType} from "../../../../../../models/api/cacheFourEvents";
import {SelectValidator, chainMerge} from "../../../../../../utils/validator";
import {makeSelectedEventTypeSelector} from "../../../../../CacheTwoEvents/selectors";
import {determineAvailability, spotsOnlySelector} from "../../../../../../utils/eventsHelper";
import {EventInfo, EventInfoParticipantType, EventRegistrationPaymentStatus} from "../../../../../../models/api/cacheThreeEvents";
import type { ApplicationState } from '../../../../..';
import { CacheFourEventsNumbersCore } from '../../../../../CacheFourEventsNumbers';
import { isAdmin } from '../../../../../../utils/permissionHelper';
import { createSelector } from 'reselect';
import { Validator } from '../../../../../../utils/validator/models';
import {toNumber} from "../../../../../../utils/dataHelper";

export const PT_PREFIX = 'ParticipantType';

const selectedEventTypeSelector = makeSelectedEventTypeSelector();

export const getParticipantTypeKey = (pType: Pick<EventRegistrationParticipantType, "ID">) => `${PT_PREFIX}${pType.ID}`;

export const makeSpotFilter = (activeOnly?: boolean) => {
  return (spot: any) => activeOnly ? !spot.ActiveForm.Inactive : !spot.ActiveForm.Inactive && !!spot.ActiveForm.NumSpots;
};

export const checkSpotsAndAmountValidation = (rootState: ApplicationState, pType: EventRegistrationParticipantType, SpotSum: number) => {
  const EventRegistrationPaymentStatus = rootState.cacheFourEventsNumbers.EventRegistrationPaymentStatus;
  const {EventInfo, participantTypeMap} = rootState.cacheThreeEvents;

  const eventNumSpotsAvailable = (EventRegistrationPaymentStatus as EventRegistrationPaymentStatus).NumEventSpotsAvailable;
  const eventsMax = (EventInfo as EventInfo).MaxParticipants;
  const typeMax = participantTypeMap[pType.ID].MaxParticipants;
  const maxAvailable = determineAvailability(
    eventNumSpotsAvailable,
    pType.NumSpotsAvailable,
    eventsMax,
    typeMax
  );

  let spotError;
  if (SpotSum < pType.NumAdded) {
    spotError = NM.SPOTS_LESS_THAN_REGISTERED_PARTICIPANTS(pType.NumAdded, true);
  } else if (maxAvailable !== undefined && SpotSum > maxAvailable) {
    spotError = NM.SPOTS_FULL_ERROR_MESSAGE(SpotSum - maxAvailable);
  } else if (SpotSum > 800) {
    spotError = GM.MAX_VALUE(800, true);
  }

  return spotError;
};

export const makeIsNewlyAddingRegistrationSelector = () => {
  return createSelector(
    [
      (state: ApplicationState) => state.cacheThreeEvents.EventInfoParticipantTypes
    ],
    (EventInfoParticipantTypes?: Array<EventInfoParticipantType>) => {
      let isNew = true;
      
      EventInfoParticipantTypes && EventInfoParticipantTypes.forEach((pt) => {
        if (pt.NumSpotsFinalized > 0) {
          isNew = false;
        }
      });

      return isNew;
    }
  );
};

export const displayOvernight = (val) => {
  const found = CAMPING_OVERNIGHT_VALUES.find(c => c.ID === val);
  return found ? found.Name : '';
};

export const CAMPING_OVERNIGHT_VALUES = [
  {ID: -1, Name: ""},
  {ID: 1, Name: "Camping Overnight"},
  {ID: 0, Name: "Attending Saturday Only"}
];

export const getSpotTempId = (rootState: ApplicationState, data?: CacheFourEventsNumbersCore, previousMinID?: number) => {
  let minID = 0;

  if (data) {
    data.EventRegistrationSpots && data.EventRegistrationSpots.forEach((spot) => {
      if (!spot.SpotID && previousMinID !== undefined) minID = previousMinID;
      if (spot.SpotID && spot.SpotID < minID) minID = spot.SpotID;
    });

    return minID - 1;
  } else {
    rootState.events.event.register.numbers.spots.EventRegistrationSpots.forEach((spot) => {
      if (spot.ActiveForm.SpotID && spot.ActiveForm.SpotID < minID) minID = spot.ActiveForm.SpotID;
    });

    return minID - 1;
  }
};

export const createSpotNumSpotsValidation = (pType: EventRegistrationParticipantType, SpotID: number, EventRegistrationPaymentStatus?: EventRegistrationPaymentStatus | null): Validator => {
  return {
    key: "NumSpots",
    integerOnly: true,
    chainDependants: pType.IsYouth ? ['NumCampersYouth'] : ['NumCampersAdult'],
    skip: (rootState) => {
      const Form = rootState.events.event.register.numbers.spots.EventRegistrationSpots.find((form) => form.ActiveForm.SpotID === SpotID);

      return !!Form && !!Form.ActiveForm.Inactive;
    },
    validatejs: {
      NumSpots: {
        numericality: {
          notValid: `^${GM.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: GM.MIN_VALUE(0),
        },
      },
    },
    extraInfo: {
      PTID: pType.ID,
      SpotID,
    },
  };
};


export const createSpotInactiveValidationRule = (pType: EventRegistrationParticipantType, SpotID: number, ): Validator => {
  return {
    key: 'Inactive',
    chainDependants: pType.IsYouth ? ['NumCampersYouth'] : ['NumCampersAdult'],
    extraInfo: {
      PTID: pType.ID,
      SpotID,
    },
  };
};


export const createSpotAmountValidation = (pType: EventRegistrationParticipantType, SpotID: number, EventRegistrationPaymentStatus?: EventRegistrationPaymentStatus | null): Validator => {
  const shouldSkipAmount = (rootState: ApplicationState) => {
    const Form = rootState.events.event.register.numbers.spots.EventRegistrationSpots.find((form) => form.ActiveForm.SpotID === SpotID);

    return !!Form && (!Form.ActiveForm.NumSpots || !!Form.ActiveForm.Inactive);
  };
  return {
    key: "Amount",
    decimalOnly: true,
    isRequired: (rootState) => !shouldSkipAmount(rootState),
    skip: (rootState) => shouldSkipAmount(rootState),
    validatejs: {
      Amount: {
        presence: {message: '^' + GM.REQUIRED},
        numericality: {
          notValid: `^${GM.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: GM.MIN_VALUE(0),
        },
      },
    },
    extraInfo: {
      PTID: pType.ID,
      SpotID,
    },
  };
};

export const createParticipantTypeValidation = (rootState: ApplicationState, pType: EventRegistrationParticipantType, EventRegistrationPaymentStatus?: EventRegistrationPaymentStatus | null) => {
  const k = getParticipantTypeKey(pType);
  const vRules = {
    key: k,
    chainDependants: pType.IsYouth ? ['NumCampersYouth'] : ['NumCampersAdult'],
    validatejs: {},
    extraInfo: {
      id: pType.ID,
    },
  };

  let eventNumSpotsAvailable;
  if (EventRegistrationPaymentStatus) {
    eventNumSpotsAvailable = EventRegistrationPaymentStatus.NumEventSpotsAvailable;
  } else {
    eventNumSpotsAvailable = !rootState.cacheFourEventsNumbers.EventRegistrationPaymentStatus ? undefined : rootState.cacheFourEventsNumbers.EventRegistrationPaymentStatus.NumEventSpotsAvailable;
  }

  const eventsMax = !rootState.cacheThreeEvents.EventInfo ? 0 : rootState.cacheThreeEvents.EventInfo.MaxParticipants;
  const typeMax = rootState.cacheThreeEvents.participantTypeMap[pType.ID].MaxParticipants;

  const maxAvailable = determineAvailability(eventNumSpotsAvailable, pType.NumSpotsAvailable, eventsMax, typeMax);
  vRules.validatejs[k] = {
    presence: {message: '^' + GM.REQUIRED},
    numericality : {
      notValid: `^${GM.INVALID}`,
      notGreaterThanOrEqualTo: (value, attribute, validatorOptions, attributes, globalOptions) => {
        const min = validatorOptions.greaterThanOrEqualTo;
        return NM.SPOTS_LESS_THAN_REGISTERED_PARTICIPANTS(min);
      },
      greaterThanOrEqualTo: pType.NumAdded
    }
  };
  if (maxAvailable !== undefined) {
    vRules.validatejs[k].numericality.notLessThanOrEqualTo = (value, attribute, validatorOptions, attributes, globalOptions) => {
      return `^${NM.SPOTS_FULL_ERROR_MESSAGE(value - validatorOptions.lessThanOrEqualTo)}`;
    };
    vRules.validatejs[k].numericality.lessThanOrEqualTo = maxAvailable;
  }
  /*
   * Commenting out for now, should do both validation in a row, and see if could implement with `customValidate`
  else {
    vRules.validatejs[k].numericality.notLessThanOrEqualTo = (value, attribute, validatorOptions, attributes, globalOptions) => {
      return GM.MAX_VALUE(800);
    };
    vRules.validatejs[k].numericality.lessThanOrEqualTo = 800;
  }
  */
  return {rule: vRules, key: k};
};

const isGroupRegistrationNotesRequired = (rootState: ApplicationState) => {
  if (isAdmin(rootState)) return false;

  return !rootState.cacheTwoEvents.EventTypeRegistrationSettings ? false : rootState.cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.RequireGroupRegistrationNotes;
};

const isGroupRegistrationNotesShown = (rootState: ApplicationState) => {
  return !rootState.cacheTwoEvents.EventTypeRegistrationSettings ? false : rootState.cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.ShowGroupRegistrationNotes;
};

const isEventContactRequired = (rootState: ApplicationState) => {
  return Boolean(rootState.cacheTwoEvents.EventTypeRegistrationSettings && rootState.cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.RequireEventContact);
};
const isEventContactShown = (rootState: ApplicationState) => {
  return rootState.cacheTwoEvents.EventTypeRegistrationSettings && rootState.cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.ShowEventContact;
};

type IValidator = {
  GroupRegistrationNotes: Validator;
  CampingOvernight: SelectValidator;
  NotesForGroup: Validator;
  AdminNotes: Validator;
  ContactName: Validator;
  ContactPhone: Validator;
  ContactEmail: Validator;
  ContactName2: Validator;
  ContactPhone2: Validator;
  ContactEmail2: Validator;
  DateRegistration: Validator;
  CampsiteDepositAmount: Validator;
  NumCampersYouth: Validator;
  NumCampersAdult: Validator;
  SumParticipants: Validator;
  OverbookedParticipantTypeTotal: Validator;
  NewEventOverbooked: Validator;
  AmountTotalChange: Validator;
}

export const FormDefinition: IValidator = {
  GroupRegistrationNotes: {
    key: "GroupRegistrationNotes",
    isRequired: (rootState) => isGroupRegistrationNotesRequired(rootState),
    skip: (rootState) => !isGroupRegistrationNotesShown(rootState),
    validatejs: {
      GroupRegistrationNotes: {
        presence: {
          message: '^' + GM.REQUIRED,
          allowEmpty: false
        },
        length: {
          maximum: 1024,
          tooLong: GM.MAX_LENGTH(1024)
        }
      }
    }
  },
  CampingOvernight: {
    key: 'CampingOvernight',
    options: {
      values: () => CAMPING_OVERNIGHT_VALUES,
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: -1
    },
    defaultValue: (rootState) => {
      return !rootState.cacheFourEventsNumbers.EventRegistrationNumbers ? undefined : rootState.cacheFourEventsNumbers.EventRegistrationNumbers.IsCampingOvernight;
    },
    isRequired: (rootState) => {
      return !rootState.cacheTwoEvents.EventTypeRegistrationSettings ? false : rootState.cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.ShowIsCampingOvernight;
    },
    validatejs: {
      CampingOvernight: {
        presence: {message: `^${GM.REQUIRED}`},
        numericality : {
          notGreaterThan: `^${GM.REQUIRED}`,
          greaterThan: -1
        }
      }
    }
  },
  NotesForGroup: {
    key: 'NotesForGroup',
    skip: (rootState) => !isAdmin(rootState),
    validatejs: {
      NotesForGroup: {
        length: {
          maximum: 2048,
          tooLong: GM.MAX_LENGTH(2048),
        },
      },
    },
  },
  AdminNotes: {
    key: 'AdminNotes',
    skip: (rootState) => !isAdmin(rootState),
    validatejs: {
      AdminNotes: {
        length: {
          maximum: 2048,
          tooLong: GM.MAX_LENGTH(2048),
        },
      },
    },
  },
  ContactName: {
    key: 'ContactName',
    defaultValue: () => '',
    isRequired: (rootState) => isEventContactRequired(rootState),
    skip: (rootState) => !isEventContactShown(rootState),
    validatejs: {
      ContactName: {
        presence: {message: '^' + GM.REQUIRED},
        length: {
          maximum: 150,
          tooLong: GM.MAX_LENGTH(150),
        },
      },
    },
  },
  ContactPhone: {
    key: 'ContactPhone',
    skip: (rootState) => !isEventContactShown(rootState),
    validatejs: {
      ContactPhone: {
        length: {
          maximum: 50,
          tooLong: GM.MAX_LENGTH(50),
        },
      },
    },
  },
  ContactEmail: {
    key: 'ContactEmail',
    isRequired: (rootState) => isEventContactRequired(rootState),
    skip: (rootState) => !isEventContactShown(rootState),
    validatejs: {
      ContactEmail: {
        presence: {message: '^' + GM.REQUIRED},
        email: {message: '^' + GM.INVALID},
        length: {
          maximum: 50,
          tooLong: GM.MAX_LENGTH(50),
        },
      },
    },
  },
  ContactName2: {
    key: 'ContactName2',
    skip: (rootState) => !isEventContactShown(rootState),
    validatejs: {
      ContactName2: {
        length: {
          maximum: 150,
          tooLong: GM.MAX_LENGTH(150),
        },
      },
    },
  },
  ContactPhone2: {
    key: 'ContactPhone2',
    skip: (rootState) => !isEventContactShown(rootState),
    validatejs: {
      ContactPhone2: {
        length: {
          maximum: 50,
          tooLong: GM.MAX_LENGTH(50),
        },
      },
    },
  },
  ContactEmail2: {
    key: 'ContactEmail2',
    skip: (rootState) => !isEventContactShown(rootState),
    validatejs: {
      ContactEmail2: {
        email: {message: '^' + GM.INVALID},
        length: {
          maximum: 50,
          tooLong: GM.MAX_LENGTH(50),
        },
      },
    },
  },
  DateRegistration: {
    key: 'DateRegistration',
    isRequired: () => false,
    skip: (rootState) => !isAdmin(rootState),
    validatejs: {
      DateRegistration: {
        datetime: {
          notValid: `^${GM.INVALID_DATE}`,
        }
      },
    },
  },
  CampsiteDepositAmount: {
    key: "CampsiteDepositAmount",
    decimalOnly: true,
    isRequired: (rootState) => isAdmin(rootState),
    skip: (rootState) => !isAdmin(rootState),
    validatejs: {
      CampsiteDepositAmount: {
        presence: {message: '^' + GM.REQUIRED},
        numericality: {
          notValid: `^${GM.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: GM.MIN_VALUE(0),
        },
      },
    },
  },
  NumCampersYouth: {
    key: 'NumCampersYouth',
    isChainRoot: true,
    chainValue: (rootState, values) => {
      const SpotsOnly = spotsOnlySelector(rootState);
      let sum: number = 0;

      if (SpotsOnly) {
        const types = rootState.events.event.register.numbers.spots.EventRegistrationParticipantTypes;
        types && types.forEach((pType) => {
          const EstimateTotal = pType.ActiveForm[getParticipantTypeKey({ID: pType.ActiveForm.ID})];
          const parsed = toNumber(EstimateTotal);
          if (pType.ActiveForm.IsYouth) sum += parsed;
        });
      } else {
        const spots = rootState.events.event.register.numbers.spots.EventRegistrationSpots;
        spots && spots.filter(makeSpotFilter(true)).forEach((spot) => {
          const NumSpots = spot.ActiveForm.NumSpots;
          const parsed = toNumber(NumSpots);
          if (!!spot.ActiveForm.PTIsYouth) sum += parsed;
        });
      }
      return sum;
    },
    chainDependants: ['SumParticipants', 'OverbookedParticipantTypeTotal']
  },
  NumCampersAdult: {
    key: 'NumCampersAdult',
    isChainRoot: true,
    chainValue: (rootState, values) => {
      const SpotsOnly = spotsOnlySelector(rootState);
      let sum: number = 0;

      if (SpotsOnly) {
        const types = rootState.events.event.register.numbers.spots.EventRegistrationParticipantTypes;
        types && types.forEach((pType) => {
          const EstimateTotal = pType.ActiveForm[getParticipantTypeKey({ID: pType.ActiveForm.ID})];
          const parsed = toNumber(EstimateTotal);
          if (!pType.ActiveForm.IsYouth) sum += parsed;
        });
      } else {
        const spots = rootState.events.event.register.numbers.spots.EventRegistrationSpots;
        spots && spots.filter(makeSpotFilter(true)).forEach((spot) => {
          const NumSpots = spot.ActiveForm.NumSpots;
          const parsed = toNumber(NumSpots);
          if (!spot.ActiveForm.PTIsYouth) sum += parsed;
        });
      }
      return sum;
    },
    chainDependants: ['SumParticipants', 'OverbookedParticipantTypeTotal']
  },
  SumParticipants: {
    key: 'SumParticipants',
    chainValue: (rootState, values) => {
      const ActiveForm = rootState.events.event.register.numbers.spots.ActiveForm;
      const v = chainMerge(ActiveForm, values);
      return toNumber(v.NumCampersAdult) + toNumber(v.NumCampersYouth);
    },
    customValidate: (rootState) => {
      const {EventInfo} = rootState.cacheThreeEvents;
      if (!rootState.cacheTwoEvents.EventTypeRegistrationSettings || !rootState.cacheFourEventsNumbers.EventRegistrationPaymentStatus || !EventInfo) {
        return undefined;
      }
      const MinNumParticipants = rootState.cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.MinNumberOfParticipants;
      const isAdmin = rootState.user.user.str_permissions.hasAdminAccess;
      const {ActiveForm} = rootState.events.event.register.numbers.spots;
      const {NumEventSpotsAvailable} = rootState.cacheFourEventsNumbers.EventRegistrationPaymentStatus;
      const selectedEventType = selectedEventTypeSelector(rootState);
      const min = Math.min(MinNumParticipants, NumEventSpotsAvailable);
      if (!EventInfo.HasGroupRegistration) {
        if (!isAdmin && MinNumParticipants > 1 &&
          (ActiveForm.SumParticipants) < min && min > 1) {
          return NM.AT_LEAST_X_PARTICIPANT(min);
        } else if (ActiveForm.SumParticipants === 0) {
          return NM.AT_LEAST_ONE_PARTICIPANT;
        }
      }
    }
  },
  OverbookedParticipantTypeTotal: {
    key: 'OverbookedParticipantTypeTotal',
    chainValue: (rootState, values) => {
      const SpotsOnly = spotsOnlySelector(rootState);
      const cachedTypes = rootState.cacheFourEventsNumbers.EventRegistrationParticipantTypes;

      let overbookedTotal = 0;
      if (SpotsOnly) {
        const types = rootState.events.event.register.numbers.spots.EventRegistrationParticipantTypes;
        types && types.forEach((pType) => {
          const EstimateTotal = toNumber(pType.ActiveForm[getParticipantTypeKey({ID: pType.ActiveForm.ID})]);

          const cachedPType = cachedTypes ? cachedTypes.find((_pt) => _pt.ID === pType.ActiveForm.ID) : null;
          if (cachedPType && EstimateTotal > cachedPType.NumSpotsAvailable) overbookedTotal += (EstimateTotal - cachedPType.NumSpotsAvailable);
        });
      } else {
        const spots = rootState.events.event.register.numbers.spots.EventRegistrationSpots;
        spots && spots.filter(makeSpotFilter(true)).forEach((spot) => {
          const NumSpots = toNumber(spot.ActiveForm.NumSpots);

          const cachedPType = cachedTypes ? cachedTypes.find((_pt) => _pt.ID === spot.ActiveForm.PTID) : null;
          if (cachedPType && NumSpots > cachedPType.NumSpotsAvailable) overbookedTotal += (NumSpots - cachedPType.NumSpotsAvailable);
        });
      }
      return overbookedTotal;
    },
    chainDependants: ['NewEventOverbooked'],
    validatejs: {
      OverbookedParticipantTypeTotal: {
        numericality : {
          notLessThan: (value, attribute, validatorOptions, attributes, globalOptions) => `^${NM.OVERBOOKED_PARTICIPANT_TOTAL}`,
          lessThan: 1
        }
      }
    }
  },
  NewEventOverbooked: {
    key: 'NewEventOverbooked',
    chainValue: (rootState, values) => {
      const NumEventSpotsAvailable = rootState.cacheFourEventsNumbers.EventRegistrationPaymentStatus ? rootState.cacheFourEventsNumbers.EventRegistrationPaymentStatus.NumEventSpotsAvailable : 0;
      const ActiveForm = rootState.events.event.register.numbers.spots.ActiveForm;
      const v = chainMerge(ActiveForm, values);
      let {NumCampersYouth, NumCampersAdult, OverbookedParticipantTypeTotal} = v;
      return toNumber(NumCampersYouth) + toNumber(NumCampersAdult) - toNumber(OverbookedParticipantTypeTotal) - NumEventSpotsAvailable;
    },
    validatejs: {
      NewEventOverbooked: {
        numericality : {
          notLessThan: (value, attribute, validatorOptions, attributes, globalOptions) => {
            let error = '';
            if (attributes.OverbookedParticipantTypeTotal > 0) {
              return `^${NM.NEW_EVENT_OVERBOOKED_AND_PTYPE(value)}`;
            }
            return `^${NM.NEW_EVENT_OVERBOOKED(value)}`;
          },
          lessThan: 1
        }
      }
    }
  },
  AmountTotalChange: {
    key: 'AmountTotalChange',
    customValidate: (rootState) => {
      if (!rootState.cacheFourEventsNumbers.EventRegistrationPaymentStatus) {
        // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
        return undefined;
      }
      if (!rootState.cacheThreeEvents.EventInfoPermissions) {
        // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
        return undefined;
      }
      const {AmountTotalChange} = rootState.cacheFourEventsNumbers.EventRegistrationPaymentStatus;
      const {IsRegistrationReductionAllowed} = rootState.cacheThreeEvents.EventInfoPermissions;
      const isAdmin = rootState.user.user.str_permissions.hasAdminAccess;
      const validAmountChange: boolean = AmountTotalChange >= 0 || isAdmin || IsRegistrationReductionAllowed;

      if (!validAmountChange) return NM.CHANGE_TOO_LOW;
    }
  }
};
