import * as React from 'react';
import '../../../../styles/elements/admin-class-type/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton, Vignette, Checkbox} from '../../index';
import ContextMenuComponent from './ContextMenu';
import { OptionsIcon, CloseIcon, EditIcon } from '../../../../components/Icons';
import { CardCategory } from '../../Card';
import { namespace as cardNS } from "../../Card/constants";
import { getImagePath } from '../../../../utils';
import { ClassType } from '../../../../models/api/options';
import { withRouter } from 'react-router';
import { isCardClicked } from '../../../../utils/navHelper';
import { ADMIN_EVENT_NEW_CLASS_TYPE_PATH, ADMIN_EVENT_EDIT_CLASS_TYPE_PATH } from '../../../../routes';
import { SELECTED_ITEM_DELETED } from '../../../../constants/messages/generic';
import { generateDOMId } from '../../../../utils/cypressHelper';
import { getClassTypeImage, isImageValid } from '../../../../utils/imageHelper';

export const namespace = (): string => 'elements--admin-class-type--card';

export enum AdminClassTypeCardType {
  CLASS_TYPE_LIST='class-type-list',
  SELECT_SINGLE='select-single',
  SELECT_MULTIPLE='select-multiple',
  SELECTED_NEW_CLASS='selected-new-class',
  SELECTED_EDIT_CLASS='selected-edit-class',
}

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin' | 'info' | 'mobile-full';
  type: AdminClassTypeCardType;
  category: CardCategory;
  noBoxShadow?: boolean;
  selected?: boolean;
  option: ClassType;
  onEdit?: (option: ClassType) => void;
  onDelete?: (option: ClassType) => void;
  onRestore?: (option: ClassType) => void;
  onClick?: (option: ClassType) => void;
  onRemove?: (option: ClassType) => void;
  onMultipleSelectClick?: (option: ClassType) => void;
  disabled?: boolean;
  routes?: any;
  checked?: boolean;
  showDeletedPrompt?: boolean;
}


class AdminClassTypeCard extends React.Component<Props> {
  shouldAllowPropagation = (e) => {
    const { routes } = this.props;
    const route = routes[routes.length - 1];
    const clickedOnCard = isCardClicked(e, namespace);
    return ((route.path === ADMIN_EVENT_EDIT_CLASS_TYPE_PATH || route.path === ADMIN_EVENT_NEW_CLASS_TYPE_PATH) && !clickedOnCard);
  };
  onEditClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onEdit, disabled, option} = this.props;
    
    e.stopPropagation();
    !disabled && onEdit && onEdit(option);
  };
  onDeleteClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onDelete, option} = this.props;
    e.stopPropagation();
    !this.props.disabled && onDelete && onDelete(option);
  };
  onRestoreClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onRestore, option} = this.props;
    !this.props.disabled && e.stopPropagation();
    onRestore && onRestore(option);
  };
  onClick = (e) => {
    const {onClick, option} = this.props;
    !this.props.disabled && e.stopPropagation();
    onClick && onClick(option);
  };
  onMultipleSelectClick = (e) => {
    const {onMultipleSelectClick, option} = this.props;

    !this.props.disabled && e.stopPropagation && e.stopPropagation();
    
    onMultipleSelectClick && onMultipleSelectClick(option);
  };
  onRemoveClick = (e) => {
    const {onRemove, option} = this.props;

    !this.props.disabled && e.stopPropagation();

    onRemove && onRemove(option);
  };
  onCardClick = (e) => {
    const {type, option, disabled} = this.props;

    if (option.Inactive || disabled) return;

    switch (type) {
      case AdminClassTypeCardType.CLASS_TYPE_LIST:
        this.onEditClick(e);
        break;
      case AdminClassTypeCardType.SELECT_SINGLE:
        this.onClick(e);
        break;
      case AdminClassTypeCardType.SELECT_MULTIPLE:
        this.onMultipleSelectClick(e);
        break;
    }
  };

  renderContent = () => {
    const { option, disabled, type, routes, category, onRestore } = this.props;

    const route = routes[routes.length - 1];
    return (
      <div className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <div className={`${namespace()}--main--text--title`}>{option.Name}</div>
        </div>
        {type === AdminClassTypeCardType.CLASS_TYPE_LIST && !option.Inactive && <ActionButton
          icon={OptionsIcon}
          allowPropagation={route.path === ADMIN_EVENT_EDIT_CLASS_TYPE_PATH || route.path === ADMIN_EVENT_NEW_CLASS_TYPE_PATH}
          className={`${namespace()}--options ${cardNS()}--options ${category}`}
          contextMenu={<ContextMenuComponent
            onEdit={this.onEditClick}
            onDelete={this.onDeleteClick}
            />}
        />}
        {(type === AdminClassTypeCardType.SELECT_MULTIPLE || type === AdminClassTypeCardType.SELECT_SINGLE || type === AdminClassTypeCardType.SELECTED_EDIT_CLASS || type === AdminClassTypeCardType.SELECTED_NEW_CLASS) && <ActionButton
          disabled={disabled}
          id={generateDOMId(type === AdminClassTypeCardType.SELECT_SINGLE ? `admin-classtype-${option.IDi}-card-edit-btn` : "admin-classtype-card-edit-btn")}
          icon={EditIcon}
          className={`${namespace()}--edit ${cardNS()}--edit`}
          onClick={this.onEditClick}
        />}
        {type === AdminClassTypeCardType.SELECTED_NEW_CLASS && <ActionButton
          icon={CloseIcon}
          disabled={disabled}
          className={`${namespace()}--close ${cardNS()}--close`}
          onClick={this.onRemoveClick}
        />}
        {type === AdminClassTypeCardType.CLASS_TYPE_LIST && option.Inactive && onRestore && <Button className={`${cardNS()}--restore ${namespace()}--restore`} flat textColor="red" onClick={this.onRestoreClick}>RESTORE</Button>}
      </div>
    );
  };

  render() {
    const {category, noBoxShadow, checked, showDeletedPrompt, selected, template, option, type, routes} = this.props;
    let className = attribute(namespace(), undefined, this.props);

    if (option.Inactive && type !== AdminClassTypeCardType.SELECTED_EDIT_CLASS) className += ' deleted';
    className += ` ${type}`;
    if (showDeletedPrompt) className += ' show-deleted-prompt';

    const image = getClassTypeImage(option);

    return (
      <Card
        noBoxShadow={noBoxShadow}
        className={className}
        component={<section/>}
        template={template || 'mobile-no-margin'}
        category={category}
        padding="none"
        selected={selected}
      >
        <div id={generateDOMId(`tentaroo-admin-classtype-${option.IDi}`)} className={`${namespace()}--wrapper`} onClick={this.onCardClick}>
          {type === AdminClassTypeCardType.SELECT_MULTIPLE ? 
            <Checkbox
              selected={!!checked}
              value={!!checked}
              onChange={this.onMultipleSelectClick}
            /> : null}
          {image && <Vignette
            className={`${namespace()}--${type}-avatar`}
            width={32}
            height={32}
            borderRadius='32px'
            name={image.initial}
            color={image.color}
            image={isImageValid(image) ? getImagePath(image, "th150px_auto") : undefined}
          />}
          {this.renderContent()}
        </div>
        {option.Inactive && showDeletedPrompt && <section className={`${namespace()}--deleted-prompt`}>{SELECTED_ITEM_DELETED}</section>}
      </Card>
    );
  }
}

export default withRouter(AdminClassTypeCard as any) as any;