import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/radio/group-label.scss';
import attributes, { AlignmentProps, MarginProps, LayoutProps } from '../../../styles';

export const namespace = (): string => 'elements--radio-group-label';

interface Props extends AlignmentProps, MarginProps, LayoutProps {
  className?: string;
}

class Group extends React.Component<Props, {}> {

  public render() {
    const { ...props } = this.props;
    let className = attributes(namespace(), this.props);
    return (
      <div className={`${mergeClassNames(namespace(), props)} ${className}`}>{this.props.children}</div>
    );
  }
}

export default Group;
