import * as Actions from './actions';
import {FSSubmitActions, SUFFIX, ValidateActions} from './actions';
import {FormDefinition} from "./validation";
import {apiValidationReducerCreator} from "../../../Validation/reducerCreator";
import {APISuccess, APISuccessSubmit, UpdateCurrValue, WithRootState} from "../../../Validation/actionCreator";
import {GetEventData} from "../../../CacheThreeEvents/actions";
import {GetEventParticipantData} from "../../../CacheFourEventsViewParticipant/actions";
import {EventNumbersSave} from "../../../CacheFourEventsNumbers/actions";
import {FinancialSummaryInit, initFinancialSummary} from "../../../../utils/classesHelper";
import {SetCacheAction} from '../../../App/actions';
import {EventRegistrationPaymentStatus} from "../../../../models/api/cacheThreeEvents";
import {EventParticipantsSave} from "../../../CacheFourEventsParticipants/actions";
import {EventProductsSave} from "../../../CacheFourEventsProducts/actions";
import {EmptyCartActions, RemoveItemFromCartActions} from "../../../Cart/actions";
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../utils/StrongActions';

export interface FinanceForm {
  selectedFinance?: 'min' | 'full' | 'other' | 'credit';
  otherValue?: number;
}

export interface EventRegistrationState {
  mobileShowContact: boolean;
  isFinanceDirty?: boolean;
  isOtherRecentlySelected?: boolean;
  ActiveForm: FinanceForm,
  ValidationRules: any;
  SubmitErrorMessage?: string;
  EventRegistrationPaymentStatus?: EventRegistrationPaymentStatus; // as is from API
  restoreIsYouth?: boolean;
  restoreParticipantIDi?: number;
  restoreName?: string;
  showMoreProductsRegistered?: boolean;
}
const checkApiValidation = apiValidationReducerCreator(SUFFIX);
// @todo: api success here needs to modify ValidationRules

const getInitialState = (): EventRegistrationState => {
  return {
    mobileShowContact: false,
    ActiveForm: {
      selectedFinance: 'min',
      otherValue: 0
    }, ValidationRules: {...FormDefinition}};
};

const EventRegistration: Reducer<EventRegistrationState> = (oldState, action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, action);

  if (
    action.type === GetEventData.successType || action.type === GetEventParticipantData.successType ||
    action.type === EventNumbersSave.successType || action.type === FSSubmitActions.successType ||
    action.type === EventProductsSave.successType || action.type === RemoveItemFromCartActions.successType ||
    action.type === EmptyCartActions.successType ||
    (action.type === EventParticipantsSave.successType && (action as WithRootState<APISuccessSubmit>).extra?.isCacheThreeEventLoaded) ||
    isActionType(action, SetCacheAction)
  ) {
    let EventRegistrationPaymentStatus;
    if (isActionType(action, SetCacheAction)) {
      EventRegistrationPaymentStatus = action.response.xhr.response.EventRegistrationPaymentStatus;
    } else {
      const castedAction = <WithRootState<APISuccess>> action;
      EventRegistrationPaymentStatus = castedAction.response.response.EventRegistrationPaymentStatus;
    }
    const castedAction = <WithRootState<APISuccess>> action;
    if (!EventRegistrationPaymentStatus) return state;
    const vals: FinancialSummaryInit = initFinancialSummary(
      action.rootState,
      EventRegistrationPaymentStatus,
      (rootState) => rootState.events.event.registration.ActiveForm.selectedFinance === 'other',
      true,
    );

    return {
      ...state,
      ActiveForm: {selectedFinance: vals.paymentType, otherValue: vals.otherDefaultValue},
      ValidationRules: {...state.ValidationRules, otherValue: vals.otherValueRules},
      EventRegistrationPaymentStatus: EventRegistrationPaymentStatus,
      isFinanceDirty: false
    };
  } else if (isActionType(action, Actions.EventsEventRegistrationSelectFinance)) {
    if (action.option !== 'other') return {...state, ActiveForm: {...state.ActiveForm, selectedFinance: action.option}};
    return {...state, ActiveForm: {...state.ActiveForm, selectedFinance: action.option}, isFinanceDirty: true};
  } else if (isActionType(action, Actions.SelectUserForRestore)) {
    return {
      ...state,
      restoreIsYouth: action.IsYouth,
      restoreParticipantIDi: action.ParticipantIDi,
      restoreName: action.Name
    };
  } else if (isActionType(action, Actions.EventsEventRegistrationSaveFinance)) {
    // @todo: should be a rest call
    // @todo: check if valid?
    return {...state, ActiveForm: {...state.ActiveForm, otherValue: action.value}, isFinanceDirty: false};
  } else if (isActionType(action, Actions.EventsEventRegistrationMobileShowContact)) {
    return {...state, mobileShowContact: action.show};
  } else if (isActionType(action, Actions.EventRegistrationShowMoreProducts)) {
    return {
      ...state,
      showMoreProductsRegistered: action.showMoreProducts
    };
  } else if (action.type === ValidateActions.updateCurrType) {
    const castedAction = <WithRootState<UpdateCurrValue>> action;
    if (castedAction.vObj.key === 'selectedFinance') {
      if (castedAction.value === 'other') {
        return {...state, isFinanceDirty: false, isOtherRecentlySelected: true};
      }
    } else if (castedAction.vObj.key === 'otherValue') {
      return {...state, isFinanceDirty: true};
    }
    return state;
  } else if (isActionType(action, Actions.EventsEventRegistrationOtherNotRecentlySelected)) {
    return {...state, isOtherRecentlySelected: false};
  } else if (isActionType(action, Actions.EventsEventRegistrationFinanceDirty)) {
    return {...state, isFinanceDirty: true};
  } else if (isActionType(action, Actions.EventsEventRegistrationReset)) {
    if (!state.EventRegistrationPaymentStatus) {
      return {
        ...state,
        mobileShowContact: false,
        isFinanceDirty: undefined,
        isOtherRecentlySelected: undefined
      };
    }
    const vals: FinancialSummaryInit = initFinancialSummary(
      action.rootState,
      state.EventRegistrationPaymentStatus,
      (rootState) => rootState.events.event.registration.ActiveForm.selectedFinance === 'other',
      true,
    );

    return {
      ...state,
      ActiveForm: {selectedFinance: vals.paymentType, otherValue: vals.otherDefaultValue},
      ValidationRules: {...state.ValidationRules, otherValue: vals.otherValueRules},
      mobileShowContact: false,
      isFinanceDirty: undefined,
      isOtherRecentlySelected: undefined
    };
  }
  // @todo: the min value in the FormDefinition needs to be based off the api
  return state || { ...getInitialState()};
};

export default EventRegistration;
