import { createSelector } from 'reselect';
import { GLAccountModalFilter, GLAccountToggleFilterTabs, ResetGLAccountModal } from './actions';
import { ApplicationState } from '../../../..';
import { IValidator, FormDefinition } from './validation';
import { apiValidationReducerCreator } from '../../../../Validation/reducerCreator';
import { SUFFIX } from './actions';
import { GLAccountsSelector } from '../../../../CacheZero';
import { GLAccount } from '../../../../../models/api/options';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface GLAccountModalActiveFormState {
  SelectedResourceCaetgoryID?: number;
}

export interface GLAccountModalState {
  isRefreshing: boolean;
  filterString: string;
  isTextSearching: boolean;
  ValidationRules: IValidator;
  ActiveForm: GLAccountModalActiveFormState;
}
const getInitialState = () => ({
  isRefreshing: false,
  filterString: '',
  isTextSearching: false,
  ValidationRules: {...FormDefinition},
  ActiveForm: {},
});

const filterSelector = (state: ApplicationState) => state.adminSettings.glAccounts.modals.glAccount.filterString;

export const makeFilteredGLAccountsSelector = () =>{
  return createSelector(
    [GLAccountsSelector, filterSelector],
    (accounts: GLAccount[], filterStr: string) => {
      if (!accounts) return [];
      const f = filterStr.toLowerCase();
      const result = accounts.filter((a) => {
        const nameMatch = f ? a.Name.toLowerCase().includes(f) : true;
        return !a.Inactive && nameMatch;
      });
      
      result.sort((a, b) => a.AccountNum < b.AccountNum ? -1 : (a.AccountNum > b.AccountNum ? 1 : 0));

      return result;
    }
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const GLAccountModalReducer: Reducer<GLAccountModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);
  if (isActionType(act, GLAccountToggleFilterTabs)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, GLAccountModalFilter)) {
    return {
      ...state,
      filterString: act.filterString,
    };
  } else if (isActionType(act, ResetGLAccountModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default GLAccountModalReducer;