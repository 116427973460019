import { typeName, Action } from '../../../../../../utils/StrongActions';
import { ActionCreator } from '../../../../../index';

@typeName("EVENT_REGISTER_NUMBERS_SELECT_PAGE") export class EventRegisterNumbersSelectPage extends Action {
  constructor(public selectedPage: 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'campsite_assignment' | 'confirmation') { super(); }
}
@typeName("EVENT_REGISTER_NUMBERS_SET_PENDING_PAGE") export class EventRegisterNumbersSetPendingPage extends Action {
  constructor(public pendingPage?: 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'campsite_assignment' | 'confirmation') { super(); }
}
@typeName("EVENT_REGISTER_NUMBERS_TRANSITION_TO_PENDING") export class EventRegisterNumbersTransitionToPending extends Action {}
@typeName("EVENT_REGISTER_NUMBERS_RESET") export class EventRegisterNumbersReset extends Action {}


export type Actions = typeof actionCreators;

export const actionCreators = {
  selectPage: (selectedPage: 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'campsite_assignment' | 'confirmation'): ActionCreator => dispatch => dispatch(new EventRegisterNumbersSelectPage(selectedPage)),
  setPendingPage: (pendingPage?: 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'campsite_assignment' | 'confirmation'): ActionCreator => dispatch => dispatch(new EventRegisterNumbersSetPendingPage(pendingPage)),
  transitionToPending: (): ActionCreator => dispatch => dispatch(new EventRegisterNumbersTransitionToPending()),
  reset: (): ActionCreator => dispatch => dispatch(new EventRegisterNumbersReset()),
};
