import {GetFacilityAvailabilitiesB, SetCacheTwoBFacilities} from "./actions";
import {
  FacilitiesAvailabilitiesDate,
  FacilitiesAvailability,
  FacilityLocation_FacilityType
} from "../../models/api/cacheTwoFacilties";
import {APISuccess} from "../Validation/actionCreator";
import {ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction} from "../App/actions";
import {ClearCacheBelowOne} from "../CacheOne/actions";
import {ClearCacheBelowTwoFacilities} from "../CacheTwoFacilities/actions";
import {makeSingleSelector} from "../../utils/reselectHelper";
import {facilityTypesSelector} from "../CacheTwoFacilities";
import {ApplicationState} from "../index";
import { captureTentarooError } from '../../utils/dataHelper';
import { Reducer } from "redux";
import { Action, isActionType } from "../../utils/StrongActions";


const facTypeBIDSelector = (state: ApplicationState) => {
  if (state.cacheTwoBFacilities.FacilityAvailabilities) {
    return state.cacheTwoBFacilities.FacilityAvailabilities.FacilityTypeID;
  }
  return 0;
};

export const makeSelectedFacilityTypeSelectorB = makeSingleSelector((ft: FacilityLocation_FacilityType, id: number) => ft.ID === id, facilityTypesSelector, facTypeBIDSelector);


export interface CacheTwoBFacilitiesState {
  facilityID: number;
  ftbID: number | null;
  FacilityAvailabilities?: FacilitiesAvailability;
  FacilityAvailabilitiesDates?: Array<FacilitiesAvailabilitiesDate>;
}

const setCacheTwoBData = (data: {
  FacilityAvailabilities?: FacilitiesAvailability;
  FacilityAvailabilitiesDates?: Array<FacilitiesAvailabilitiesDate>;
}, facilityID, ftbID) => {

  return {
    facilityID,
    ftbID,
    FacilityAvailabilities: data.FacilityAvailabilities,
    FacilityAvailabilitiesDates: data.FacilityAvailabilitiesDates
  };
};

const handleResponse = (state, responseData, request) => {
  if (responseData.FacilityAvailabilities !== undefined) {
    const bodyStr = request.body;
    try {
      const body = JSON.parse(bodyStr);
      return setCacheTwoBData(responseData, body.FacilityID, body.ftbID);
    } catch (e) {
      captureTentarooError(new Error('Unable to parse request body for cache 2 facilities!'));
      return state;
    }
  }
  return state;
};

const CacheTwoBFacilities: Reducer<CacheTwoBFacilitiesState> = (state, act: Action) => {
  if (act.type === GetFacilityAvailabilitiesB.successType) {
    const action = <APISuccess> act;
    return handleResponse(state, action.response.response, action.response.request);
  } else if (isActionType(act, SetCacheAction)) {
    return handleResponse(state, act.response.xhr.response, act.response.request);
  } else if (isActionType(act, SetCacheTwoBFacilities)) {
    return {
      facilityID: act.facilityID,
      ftbID: act.ftbID,
      FacilityAvailabilities: act.FacilityAvailabilities,
      FacilityAvailabilitiesDates: act.FacilityAvailabilitiesDates
    };
  } else if (
    isActionType(act, ClearAllCache) || isActionType(act, ClearAllEndUserCacheButOptions) || isActionType(act, ClearCacheBelowOne) ||
    isActionType(act, ClearCacheBelowTwoFacilities)
  ) {
    return {facilityID: 0, ftbID: null};
  }
  return state || {facilityID: 0, ftbID: null};
};

export default CacheTwoBFacilities;
