import * as React from 'react';
import { ContextMenu, ContextMenuItem } from '../../../../Elements';
import { Props as ContextMenuProps } from '../../../../Elements/ContextMenu/index';
import { PublicWebsiteIcon, CopyIcon, CloseIcon } from '../../../../../components/Icons';
import { generateDOMId } from '../../../../../utils/cypressHelper';

interface Props {
  onSave: (e) => void
  onDuplicate: (e) => void;
  onDelete: (e) => void;
}

const ContextMenuComponent: React.SFC<ContextMenuProps & Props> = (props: ContextMenuProps & Props): React.ReactElement<ContextMenuProps & Props> => (
  <ContextMenu
    {...props}
    consistentInMobile
    title=""
  >
    <ContextMenuItem admin onClick={props.onSave}>Save</ContextMenuItem>
    <ContextMenuItem admin onClick={props.onDuplicate}>Duplicate</ContextMenuItem>
    <ContextMenuItem id={generateDOMId("eventtype-delete")} admin onClick={props.onDelete}>Delete</ContextMenuItem>
  </ContextMenu>
);

export default ContextMenuComponent;
