import * as React from 'react';
import { Main, MainContent } from '../../../Layouts';
import {
  Align,
  Button,
  Card,
  CardPadding,
  CardSubTitle,
  CardTitle,
  Column,
  Ellipsis,
  LoadingAll,
  Media,
  MonthPicker,
  MonthRange,
  Row,
  Separator,
  Text,
} from '../../../Elements';
import { LocationsIcon } from '../../../Icons';
import '../../../../styles/pages/facilities/facility/index.scss';
import { RouteComponentProps, withRouter } from "react-router";
import { navPush } from "../../../../utils/navHelper";
import {
  actionCreators as cacheZeroActionCreators,
} from "../../../../store/CacheZero/actions";
import {
  actionCreators as cacheTwoFacilitiesActionCreators,
  ClearCacheBelowTwoFacilities
} from "../../../../store/CacheTwoFacilities/actions";
import {
  actionCreators as rollbackActionCreators,
} from "../../../../store/Rollback/actions";
import {
  actionCreators as cacheTwoBFacilitiesActionCreators,
} from "../../../../store/CacheTwoBFacilities/actions";
import { actionCreators as cacheOneActionCreators } from "../../../../store/CacheOne/actions";
import {
  makeSelectedLocationSelector
} from "../../../../store/CacheTwoFacilities";
import { bindActionCreators } from "redux";
import { getAddTripURL } from "../../../../constants/urls";
import { actionCreators } from "../../../../store/Facilities/Facility/actions";
import { formatDate } from "../../../../utils/dateHelper";
import { makeSelectedFacilityTypeSelectorB } from "../../../../store/CacheTwoBFacilities";
import { FacilitiesAvailability } from "../../../../models/api/cacheTwoFacilties";
import * as moment from 'moment';
import { createImageGallery } from "../../../../utils/imageHelper";
import { getLocationPricingInfo } from "../../../../utils/helpers/facilityHelper";
import FacilityStatuses from './FacilityStatuses';
import FacilityFeatures from './FacilityFeatures';
import { checkPermission } from "../../../../utils/permissionHelper";
import Alert from "../../../Elements/Alert";
import { NO_BOOKING } from "../../../../constants/messages/generic";
import ImageGallery from 'react-image-gallery';
import '../../../../styles/image-gallery.scss';
import { ApplicationState } from '../../../../store';
import EndUserCacheManager from '../../../../utils/cacheManagers/endUserCacheManager';
import { connect } from 'react-redux';
import { reduxStoreService } from '../../../../store/service';
import { ComponentUpdateTemplate } from '../../../Templates/ComponentUpdateTemplate';
import {isCacheTwoBFacilitiesPopulated} from '../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, { id: string; name: string; facId: string; ftbId: string | undefined | null; facName: string }>
>;

const namespace = (): string => 'pages--facilities--facility';

@(withRouter as any)
class Facility extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;

  public componentDidMount() {
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheTwoBFacilities({
          props: this.props,
          isStateNavigated,
        });
      }
    );
  }
  public componentWillReceiveProps(nextProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheTwoBFacilities({
          props: nextProps,
          isStateNavigated,
        });
      }
    );
  }

  componentWillUnmount() {
    reduxStoreService().dispatch(new ClearCacheBelowTwoFacilities());
  }

  onNewTrip = () => {
    const { selectedLocation, cacheTwoFacilities: { FacilityLocationPermissions } } = this.props;

    if (!FacilityLocationPermissions) return;

    checkPermission(
      () => navPush(this.props.router, getAddTripURL(selectedLocation)),
      FacilityLocationPermissions.hasAllowNewTrip,
      FacilityLocationPermissions.hasAllowNewTripReason
    );
  };

  onViewMap = () => window.open(this.props.selectedFacilityType.FacilityMapURL);

  onChangeMonth = (startDate: moment.Moment) => {
    if (!this.props.cacheZero.options?.Group) return;

    const FacilityAvailabilities = this.props.cacheTwoBFacilities.FacilityAvailabilities as FacilitiesAvailability;
    this.props.actions.changeDate(startDate);
    this.props.actions.getFacilityAvailabilitiesB(
      this.props.cacheZero.options.Group.IDi,
      this.props.cacheZero.options.Group.TS,
      this.props.cacheTwoFacilities.locationID,
      false,
      false,
      false,
      FacilityAvailabilities.FacilityID, // todo: make sure this is properly set
      FacilityAvailabilities.ftbID,
      formatDate(startDate),
    );
  };

  public render() {
    const {
      selectedFacilityType, user, cacheTwoBFacilities,
      selectedLocation, facility: { startDate, showContextMenu, disableNext, disablePrev }, actions, apiLoadingMap, inert
    } = this.props;

    if (!isCacheTwoBFacilitiesPopulated(cacheTwoBFacilities) || !selectedFacilityType || !this.props.cacheTwoBFacilities.FacilityAvailabilities) {
      return <LoadingAll />;
    }
    const FacilityAvailabilities = this.props.cacheTwoBFacilities.FacilityAvailabilities as FacilitiesAvailability;

    const nameSingular = selectedFacilityType.NameSingular;

    const info = getLocationPricingInfo(FacilityAvailabilities, nameSingular);
    const { perPersonRatePerDay, flatRatePerDay, minPeople, maxPeople } = info;

    const showAlert = !user.user.str_permissions.hasAdminAccess && !FacilityAvailabilities.AllowOnlineFacilityBooking;
    const images: Array<any> = createImageGallery(FacilityAvailabilities.Images);

    return (
      <Main inert={inert} mobileBackground="white" className={namespace()}>
        <MainContent>
          <Card template="mobile-stretch" className={`${namespace()}--facility`} padding="none" mobileMarginTop={showAlert ? 16 : undefined}>
            {images.length > 0 && <ImageGallery items={images} showPlayButton={false} showIndex showThumbnails={false} showBullets showFullscreenButton />}

            <CardPadding layout="vertical">
              {showAlert && <Row className={`${namespace()}--alert`}><Alert>{NO_BOOKING}</Alert></Row>}
              <CardTitle
                size={26}
                mobileSize={24}
                marginBottom={4}
                controls={<Media tablet desktop><Button color="green" big onClick={this.onNewTrip}>NEW TRIP</Button></Media>}
                className={`${namespace()}--facility--title`}
              >
                {FacilityAvailabilities.Name}
              </CardTitle>
              <CardSubTitle>{nameSingular}</CardSubTitle>
              {(perPersonRatePerDay || minPeople || flatRatePerDay || maxPeople) && <Row marginBottom={20}>
                {(!!flatRatePerDay || !!maxPeople) && <Column span={6} mobileSpan={12} layout="vertical" className={`${namespace()}--facility--price-info`}>
                  {flatRatePerDay && <Text weight="bold" color="green">{flatRatePerDay}</Text>}
                  {maxPeople && <Text size={13} color="gray">{maxPeople}</Text>}
                </Column>}
                <Column span={6} mobileSpan={12} layout="vertical" className={`${namespace()}--facility--price-info`}>
                  {perPersonRatePerDay && <Text weight="bold" color="green">{perPersonRatePerDay}</Text>}
                  {minPeople && <Text size={13} color="gray">{minPeople}</Text>}
                </Column>
              </Row>}
              <FacilityStatuses
                facilityInfo={FacilityAvailabilities}
                startTime={FacilityAvailabilities.CheckinTime}
                endTime={FacilityAvailabilities.CheckoutTime}
                IsFacilityType={this.props.selectedFacilityType.IsFacilityType}
              />
              <Media mobile><Button color="green" big expand onClick={this.onNewTrip}>NEW TRIP</Button></Media>
            </CardPadding>
            <Separator className={`${namespace()}--facility--separator`} />
            <CardPadding layout="vertical">
              {FacilityAvailabilities.Description ? (
                <Ellipsis
                  configs={{
                    lines: 5,
                    text: FacilityAvailabilities.Description,
                  }}
                  marginBottom={20} fontSize={15}
                />
              ) : null}
              {selectedFacilityType.Description ? (
                <Ellipsis
                  configs={{
                    lines: 5,
                    text: selectedFacilityType.Description,
                  }}
                  marginBottom={20} fontSize={15}
                />
              ) : null}
              {selectedFacilityType.FacilityMapURL && <Row marginBottom={30}>
                <Align start><Button color="white" icon={<LocationsIcon color="gray" />} onClick={this.onViewMap}>VIEW MAP</Button></Align>
              </Row>}
              <FacilityFeatures features={this.props.cacheTwoBFacilities.FacilityAvailabilities.Features} />
            </CardPadding>
          </Card>
          <Card padding="none" template="mobile-stretch">
            <CardTitle
              controls={<MonthPicker
                date={startDate}
                showContextMenu={showContextMenu}
                disableNext={disableNext}
                disablePrev={disablePrev}
                onChange={this.onChangeMonth}
                onShowMonthMenu={actions.showMonthMenu}
              />}
              marginTop={24} marginLeft={24} marginRight={24}
              mobileMarginTop={0} mobileMarginLeft={16} mobileMarginRight={16}
            >
              Available Dates
            </CardTitle>
            <MonthRange
              facilityLocation={selectedLocation}
              start={moment(startDate).startOf('month')}
              end={moment(startDate).startOf('month').add(3, 'months')}
              availabilities={FacilityAvailabilities.Availabilities}
              availabilityDates={this.props.cacheTwoBFacilities.FacilityAvailabilitiesDates}
            />
          </Card>
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedLocation = makeSelectedLocationSelector();
  const selectedFacilityType = makeSelectedFacilityTypeSelectorB();
  return {
    user: state.user,
    facilityLocation: state.facilities.location,
    SystemSettings: state.session.SystemSettings,
    facilitiesLocations: state.facilities.locations,
    facility: state.facilities.facility,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheTwoBFacilities: state.cacheTwoBFacilities,
    cacheTwoFacilities: state.cacheTwoFacilities,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    selectedLocation: selectedLocation(state),
    selectedFacilityType: selectedFacilityType(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheTwoBFacilitiesActionCreators,
    ...cacheTwoFacilitiesActionCreators,
    ...cacheOneActionCreators,
    ...cacheZeroActionCreators,
    ...rollbackActionCreators,
  }, dispatch)
});
const ConnectedFacility = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Facility);

export default ConnectedFacility;
export { default as FacilitiesFacilityHeader } from './Header';
