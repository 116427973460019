import * as React from 'react';

import { Form, Row, Column, TextField, Select, Alert, Media } from '../../../../Elements';
import { Main, MainContent } from '../../../../Layouts';
import { IValidator } from '../../../../../store/AdminSettings/Modals/LocationForm/validation';
import { Location } from '../../../../../models/api/adminCMSCacheOne';
import { LocationActiveForm } from '../../../../../store/AdminSettings/Modals/LocationForm';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { Validator } from '../../../../../utils/validator/models';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--location-form';

export type Props = WithInertAttribute<{
  action: 'add' | 'edit';
  disabled: boolean;
  ActiveForm: LocationActiveForm;
  ValidationRules: IValidator;
  loading?: boolean;
  location?: Location;
  hideFormAction?: boolean;
  SubmitErrorMessage?: string;
  onSave?: () => void;
  onDelete?: () => void;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
  };
}>;

class LocationForm extends React.Component<Props, {}> {
  public props: Props;

  public render() {
    const {onSave, onDelete, action, hideFormAction, SubmitErrorMessage, disabled, reduxActions, ValidationRules, ActiveForm, loading, inert} = this.props;

    // @todo - dont use <Media> tag to group form fields even to achieve responsive effect. Similar work should be done in `Checkout.tsx` as well
    return (
        <Main inert={inert}>
          <MainContent className={`${namespace()}--main-content`}>
            <Form
              className={`${namespace()}--form`}
              onSubmit={e => e.preventDefault()}
            >
              {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
              <Row marginBottom={8} mobileMarginBottom={8}>
                <Column span={12} mobileSpan={12}>
                  <TextField
                    label="Name"
                    id={generateDOMId("location-form-name-field")}
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Name}
                    value={ActiveForm.Name}/>
                </Column>
              </Row>
              <Row marginBottom={8} mobileMarginBottom={8}>
                <Column span={12} mobileSpan={12}>
                  <TextField
                    label="Address"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Address}
                    value={ActiveForm.Address}/>
                </Column>
              </Row>
              <Media tabletAndGreater>
                <Row marginBottom={8} mobileMarginBottom={0}>
                  <Column span={6}>
                    <TextField
                      label="City"
                      disabled={disabled}
                      onChange={reduxActions.simpleUpdate}
                      onBlur={reduxActions.updateValue}
                      validationRules={ValidationRules.City}
                      value={ActiveForm.City}/>
                  </Column>
                  <Column span={3}>
                    <Select
                        label="State"
                        disabled={disabled}
                        onChangeValue={reduxActions.updateValue}
                        value={ActiveForm.StateID}
                        validationRules={ValidationRules.StateID}
                        isNumber />
                  </Column>
                  <Column span={3}>
                    <TextField
                      label="Zip"
                      disabled={disabled}
                      onChange={reduxActions.simpleUpdate}
                      onBlur={reduxActions.updateValue}
                      validationRules={ValidationRules.Zip}
                      value={ActiveForm.Zip}/>
                  </Column>
                </Row>
              </Media>
              <Media style={{flexDirection: 'column'}} mobile>
                <Row marginBottom={8} mobileMarginBottom={8}>
                  <Column mobileSpan={12}>
                    <TextField
                      label="City"
                      disabled={disabled}
                      onChange={reduxActions.simpleUpdate}
                      onBlur={reduxActions.updateValue}
                      validationRules={ValidationRules.City}
                      value={ActiveForm.City}/>
                  </Column>
                </Row>
                <Row marginBottom={8} mobileMarginBottom={8}>
                  <Column marginBottom={0} mobileMarginBottom={8} mobileSpan={6}>
                    <Select
                        label="State"
                        customizedId={'mobile-elements--select-state'}
                        disabled={disabled}
                        onChangeValue={reduxActions.updateValue}
                        value={ActiveForm.StateID}
                        validationRules={ValidationRules.StateID}
                        isNumber />
                  </Column>
                  <Column mobileSpan={6}>
                    <TextField
                      label="Zip"
                      disabled={disabled}
                      onChange={reduxActions.simpleUpdate}
                      onBlur={reduxActions.updateValue}
                      validationRules={ValidationRules.Zip}
                      value={ActiveForm.Zip}/>
                  </Column>
                </Row>
              </Media>
              <Row>
                <Column marginBottom={0} mobileMarginBottom={8} span={6} mobileSpan={12}>
                  <TextField
                    label="Phone"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.PhoneNumber}
                    value={ActiveForm.PhoneNumber}/>
                </Column>
                <Column span={6} mobileSpan={12}>
                  <TextField
                    label="Fax"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.FaxNumber}
                    value={ActiveForm.FaxNumber}/>
                </Column>
              </Row>
            </Form>
          </MainContent>
        </Main>
    );
  }
}

export default LocationForm;
