import * as M from '../../../../constants/messages/generic';
import { Validator } from '../../../../utils/validator/models';

export interface IAdminEventProductValidator {
  Name: Validator;
  IsAdminOnly: Validator;
  FeaturedImage: Validator;
  Amount: Validator;
  GLAccountID: Validator;
  Description: Validator;
}

export const AdminEventProductFormDefinition: IAdminEventProductValidator = {
  Name: {
    key: 'Name',
    isRequired: () => true,
    defaultValue: () => '',
    validatejs: {
      Name: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 100,
          tooLong: M.MAX_LENGTH(100)
        },
      },
    },
  },
  IsAdminOnly: {
    key: 'IsAdminOnly',
    defaultValue: () => false,
  },
  FeaturedImage: {
    key: 'FeaturedImage',
    isRequired: () => false,
  },
  Amount: {
    key: 'Amount',
    isRequired: () => true,
    defaultValue: () => 0,
    decimalOnly: true,
    validatejs: {
      Amount: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
        },
      }
    }
  },
  GLAccountID: {
    key: 'GLAccountID',
    customValidate: (rootState) => {
      const form = rootState.adminEvents.products.form.ActiveForm;
      if (!form.GLAccountID) {
        return '';
      } else {
        const GLAccounts = rootState.cacheZero.options ? rootState.cacheZero.options.GLAccounts : [];
        const GLAccount = GLAccounts.find((acc) => acc.ID === form.GLAccountID);
        if (!GLAccount || GLAccount.Inactive) {
          return M.INVALID_SELECTED_GL_ACCOUNT;
        }
      }
      return;
    },
    validatejs: {
      GLAccountID: {
      },
    },
  },
  Description: {
    key: 'Description',
    defaultValue: () => '',
    validatejs: {
      Description: {
        length: {
          maximum: 2048,
          tooLong: M.MAX_LENGTH(2048),
        },
      },
    },
  },
};