import {
  ApiSubmitActionsNumbersProducts
} from './actions';
import {eventNumbersCalc} from '../../../../../../constants/urls';
import {makeRequestEpic} from '../../../../../Validation/epicCreator';

export const numbersProductsNext = makeRequestEpic(
  ApiSubmitActionsNumbersProducts,
  eventNumbersCalc,
  undefined,
  true
);
