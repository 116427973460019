import {makeRequestEpic} from "../../../../Validation/epicCreator";
import {AddResourceCategorySubmitActions, DeleteResourceCategorySubmitActions} from "./actions";
import {getUpdateResourceCategoryUrl} from "../../../../../constants/urls";
import {AjaxResponse} from "rxjs/ajax";
import {validate} from "../../../../../utils";
import { NoOp, PopModal } from "../../../../../store/App/actions";
import { ValidateActions } from "../../Resource/Form/actions";
import { FormDefinition } from "../../Resource/Form/validation";
import { Observable } from "rxjs";
import { ENTITY_EDITED, ENTITY_CREATED, ENTITY_PARSE_ERROR, ENTITY_DELETED, ENTITY_RESTORED, RESOURCE_CATEGORY } from "../../../../../constants/messages/adminCMS";
import { captureTentarooError } from "../../../../../utils/dataHelper";
import { ModalTypes, isModalOpened } from "../../../../../utils/modalHelper";
import { popModalObservables } from "../../../../../utils/modalHelper";
import { Action } from "redux";
import { reduxStoreService } from "../../../../service";

export const adminCMSSiteAddResourceCategoryEpic = makeRequestEpic(
  AddResourceCategorySubmitActions,
  getUpdateResourceCategoryUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      if (body.row.ID) {
        return ENTITY_EDITED(RESOURCE_CATEGORY);
      }
      return ENTITY_CREATED(RESOURCE_CATEGORY);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(RESOURCE_CATEGORY)));
      return '';
    }
  },
  undefined,
  undefined,
  undefined,
  undefined,
  false,
  (payload): Observable<Action> => {
    const bodyStr = payload.request.body;
    const CMSResourceCategories = payload.response.CMSResourceCategories;
    const state = reduxStoreService().getState();
    try {
      const body = JSON.parse(bodyStr);

      const rc = CMSResourceCategories.find((rc) => rc.Name === body.row.Name);
      if (isModalOpened(ModalTypes.SELECT_RESOURCE_CATEGORIES) && !body.row.ID) {
        // When ResourceCategories modal is opened and we are adding a new resource category, select that resource category
        const validationResults = validate(FormDefinition.CategoryID, rc.ID, undefined, () => state.adminCMSSite.resources.resource.form);
        return Observable.concat(
          Observable.of(ValidateActions.updateCurr(rc.ID, 'CategoryID', FormDefinition.CategoryID)),
          Observable.of(ValidateActions.update(validationResults, 'CategoryID')),
          ...popModalObservables({
            modal: ModalTypes.SELECT_RESOURCE_CATEGORIES,
            saveBefore: false,
            saveAfter: false,
            transformToObservable: true,
          }),
        );
      }

      return Observable.of(new NoOp());
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(RESOURCE_CATEGORY)));
      return Observable.of(new NoOp());
    }
  },
);

export const adminCMSSiteDeleteResourceCategoryEpic = makeRequestEpic(
  DeleteResourceCategorySubmitActions,
  getUpdateResourceCategoryUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const resourceCategories = response.response.CMSResourceCategories;
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      if (body.row.ID) {
        const resourceCategory = resourceCategories.find((rc) => rc.ID === body.row.ID);
        if (resourceCategory && resourceCategory.Inactive) {
          return ENTITY_DELETED(RESOURCE_CATEGORY);
        } else if (resourceCategory && !resourceCategory.Inactive){
          return ENTITY_RESTORED(RESOURCE_CATEGORY);
        } else {
          return '';
        }
      }
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(RESOURCE_CATEGORY)));
      return '';
    }
    return '';
  },
  undefined,
);