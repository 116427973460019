import {
  EventRegisterNumbersClassesShowError, ApiSubmitActionsNumbersClasses,
  EventRegisterNumbersClassesAdd, EventRegisterNumbersClassesRemove, ClearError
} from "./actions";
import {EventRegisterNumbersReset} from "../Main/actions";
import {ApiSubmitActionsNumbersSpots} from "../Spots/actions";
import {ClassOverrideFeeSave} from "../../../Modals/ClassOverrideFee/actions";
import {ApiSubmitActionsNumbersProducts} from "../Products/actions";
import { ApiSubmitActionsNumbersCampsite } from '../Campsite/actions';
import { Reducer } from "redux";
import { isActionType } from "../../../../../../utils/StrongActions";

export interface NumbersWizardClassesState {
  isDirty?: boolean;
  SubmitErrorMessage?: string;
  isError?: boolean;
}

const getInitialState = () => ({
});

const NumbersWizardClasses: Reducer<NumbersWizardClassesState> = (state = getInitialState(), act) => {
  if (
    act.type === ApiSubmitActionsNumbersSpots.successType ||
    act.type === ApiSubmitActionsNumbersClasses.successType ||
    act.type === ApiSubmitActionsNumbersProducts.successType ||
    act.type === ApiSubmitActionsNumbersCampsite.successType
  ) {
    return getInitialState();
  } else if (isActionType(act, EventRegisterNumbersClassesShowError)) {
    window.scrollTo(0, 0);
    return {...state, SubmitErrorMessage: act.error, isError: act.isError};
  } else if (isActionType(act, EventRegisterNumbersReset)) {
    return getInitialState();
  } else if (
    isActionType(act, EventRegisterNumbersClassesAdd) || isActionType(act, EventRegisterNumbersClassesRemove) ||
    isActionType(act, ClassOverrideFeeSave)
  ) {
    return {...state, isDirty: true};
  } else if (isActionType(act, ClearError)) {
    return {...state, SubmitErrorMessage: undefined};
  }
  return state;
};

export default NumbersWizardClasses;
