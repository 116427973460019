import { SUFFIX, ResetAvailabilities, GetAvailabilitiesAction, ChangeDate, SetView, ShowMonthMenu, SelectTab } from "./actions";
import { apiValidationReducerCreator } from "../../../Validation/reducerCreator";
import { AdminFacilitiesAvailability } from "../../../../models/api/adminFacilitiesAvailability";
import { FacilitiesAvailabilitiesDate } from "../../../../models/api/cacheTwoFacilties";
import { APISuccess, WithRootState } from "../../../../store/Validation/actionCreator";
import { ViewControlsOptions } from "../../../../components/Elements";
import moment from "moment";
import { getPrevAndNext } from "../../../../utils/dateHelper";
import { ClearAllAdminFacilityLocationCache } from "../../CacheOne/actions";
import { Reducer } from "redux";
import { Action, isActionType } from "../../../../utils/StrongActions";

export interface AvailabilitiesHomeState {
  FacilitiesAvailabilities: AdminFacilitiesAvailability[];
  FacilitiesAvailabilitiesDates: FacilitiesAvailabilitiesDate[];
  view: ViewControlsOptions;
  startDate: moment.Moment;
  showContextMenu: boolean;
  disableNext: boolean;
  disablePrev: boolean;
  selectedTabId?: number;
}

const getInitialState = () => {
  return {
    startDate: moment().startOf('month'),
    disablePrev: true,
    disableNext: false,
    showContextMenu: false,
    selectedTabId: undefined,
    view: 'grid'
  };
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const AvailabilitiesHomeReducer: Reducer<AvailabilitiesHomeState> = (s, act: WithRootState<Action>) => {
  const state = checkApiValidation(s, act);
  if (act.type === GetAvailabilitiesAction.successType) {
    const action = <WithRootState<APISuccess>>act;
    const res = action.response.xhr.response;

    return {
      ...state,
      FacilitiesAvailabilities: res.FacilitiesAvailabilities,
      FacilitiesAvailabilitiesDates: res.FacilitiesAvailabilitiesDates,
    };
  } else if (isActionType(act, ChangeDate)) {
    const newState = {...state, startDate: act.date};
    let prevAndNext = getPrevAndNext(act.date);
    newState.disablePrev = prevAndNext.disablePrev;
    newState.disableNext = prevAndNext.disableNext;
    return newState;
  } else if (isActionType(act, ShowMonthMenu)) {
    return { ...state, showContextMenu: act.show};
  } else if (isActionType(act, SelectTab)) {
    return { ...state, selectedTabId: act.tabId };
  } else if (isActionType(act, SetView)) {
    return {
      ...state,
      view: act.view,
    };
  } else if (isActionType(act, ResetAvailabilities)) {
    return {
      ...state,
      FacilitiesAvailabilities: undefined,
      FacilitiesAvailabilitiesDates: undefined,
    };
  } else if (isActionType(act, ClearAllAdminFacilityLocationCache) ) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default AvailabilitiesHomeReducer;