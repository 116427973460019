import * as React from 'react';
import '../../../styles/elements/bullet-list/index.scss';
import {default as attributes, MarginProps, PaddingProps} from "../../../styles/index";
import {namespace} from "./constants";

export interface BulletListProps extends MarginProps, PaddingProps {
  children?: React.ReactNode;
  className?: string;
}

const BulletList: React.SFC<BulletListProps> = ({ className, ...props }: BulletListProps): React.ReactElement<BulletListProps> => {
  className = attributes(namespace(), className, props);
  return (
    <ul className={className}>
      {props.children}
    </ul>
  );
};

export default BulletList;
export { default as BulletListItem } from './Item';
