import { ActionCreator } from '../';
import { typeName, Action } from '../../utils/StrongActions';

@typeName("SAVE_STATE")
export class SaveState extends Action {
  constructor(public savePointDuringSave?: boolean) { 
    super();
  }
}
@typeName("CLEAR_ROLLBACK")
export class ClearRollback extends Action {}

/**
 * We need `restoreDuringSave` here, instead of clear apiSaving when ClearRollbackLocationForSaving is dispatched
 * because we need to ClearRollbackLocationForSaving before router change to avoid infinite loop; and we also
 * need apiSaving > 0 before router change because we use that to prevent load request in loaders.
 */
@typeName("RESTORE_STATE")
export class RestoreState extends Action {
  constructor(public retainNodeNames?: string[], public restoreDuringSave?: boolean, public cancelRequests?: boolean) { super(); }
}

@typeName('CLEAR_SAVE_POINT_DURING_SAVE') export class ClearSavePointDuringSave extends Action {
  constructor() { super(); }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  saveState: (isBeforeSaving?: boolean): ActionCreator => dispatch => dispatch(new SaveState(isBeforeSaving)),
  restoreState: (retainNodeNames?: string[]): ActionCreator => dispatch => dispatch(new RestoreState(retainNodeNames)),
  clearRollback: (): ActionCreator => dispatch => {
    dispatch(new ClearRollback());
  },
};
