import * as React from 'react';
import '../../../../styles/pages/events/event/price.scss';
import {namespace as ns, RED_THRESHOLD} from './constants';
import { Card } from '../../../Elements';
import {EventInfoParticipantType} from "../../../../models/api/cacheThreeEvents";
import {standardCurrencyFormat} from "../../../../utils/classesHelper";
import {determineAvailability} from "../../../../utils/eventsHelper";

export const namespace = (): string => `${ns()}--price`;

export interface PriceProps {
  type: EventInfoParticipantType;
  numEventSpotsAvailable: number;
  MaxParticipants: number;
}

const Price: React.SFC<PriceProps> = (props: PriceProps): React.ReactElement<PriceProps> => {
  const {MaxParticipants, type, numEventSpotsAvailable} = props;
  const quantity = determineAvailability(numEventSpotsAvailable, type.NumEventSpotsAvailablePending, MaxParticipants, type.MaxParticipants);
  const quantityText = `${quantity} spot${quantity !== 1 ? 's' : ''} available`;
  return (
    <li className={namespace()}>
      <Card className={`${namespace()}--card`} padding="compact">
        <div className={`${namespace()}--card--label-price-group`}>
          <div className={`${namespace()}--card--label-price-group--label`}>{props.type.NameWithYA}</div>
          <div className={`${namespace()}--card--label-price-group--price`}>{standardCurrencyFormat(props.type.AmountCurrent)}</div>
        </div>
        {type.MaxParticipants !== 0 && quantity !== undefined && quantity > 0 && <div className={`${namespace()}--card--quantity` + (quantity < RED_THRESHOLD ? ' red' : '')}>{quantityText}</div>}
        {type.MaxParticipants !== 0 && quantity !== undefined && quantity === 0 && <div className={`${namespace()}--card--quantity red`}>FULL</div>}
      </Card>
    </li>
  );
};


export default Price;
