
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetFacilityLocationCache, GetAvailabilitiesCache} from "./actions";
import { getFacilityLocationCacheBody, getFacilityLocationCache, URLS } from "../../../constants/urls";
import { AjaxResponse, Observable } from "rxjs/Rx";
import { SaveState } from "../../../store/Rollback/actions";
import { SelectTab, GetAvailabilitiesAction } from "../Availabilities/Home/actions";
import moment from "moment";
import { extractRouteParams } from "../../../utils/urlHelper";

export const adminFacilityLocationCacheOneEpic = makeRequestEpic(
  GetFacilityLocationCache,
  getFacilityLocationCache,
  getFacilityLocationCacheBody,
  true,
);

export const adminFacilityLocationGetAvailabilitiesEpic = makeRequestEpic(
  GetAvailabilitiesCache,
  getFacilityLocationCache,
  getFacilityLocationCacheBody,
  true,
  undefined,
  undefined,
  undefined,
  undefined,
  true,
  undefined,
  (response: AjaxResponse, extra: any) => {
    const ActiveFacilitiesTypes = response.response.FacilitiesTypes ? response.response.FacilitiesTypes.filter((ft) => !ft.Inactive) : [];

    if (ActiveFacilitiesTypes.length > 0) {
      const actions: any[] = [];
      actions.push(new SaveState());
      actions.push(new SelectTab(ActiveFacilitiesTypes[0].ID));
      actions.push(GetAvailabilitiesAction.request({
        FacilityTypeID: ActiveFacilitiesTypes[0].ID,
        StartDate: moment().startOf('month'),
        ...extractRouteParams(extra),
      }));

      return Observable.concat(
        ...actions.map((a) => Observable.of(a)),
      );
    }

    return Observable.of(new SaveState());
  }
);