import * as React from 'react';
import { MainLeftSideBar } from '../../Layouts';
import { Map, ContactCard, ContactInfo } from '../../Elements';
import {FacilityLocation, Location, ContactInfo as ContactInfoClass} from "../../../models/api/cacheOne";
import {SystemSettings} from "../../../models/api/registerForm";
import ContactInfos from './ContactInfos';
import {getFullAddress} from '../../../utils';
import {setSentryContext} from "../../../config/sentry";
import { captureTentarooError } from '../../../utils/dataHelper';

export interface SidebarProps {
  className?: string;
  location: Location;
  contactInfo: ContactInfoClass;
  contactInfoLabel: string;
  systemSettings: SystemSettings;
  open: boolean;
  onClose: () => void;

  multiple?: boolean;
}



class Sidebar extends React.Component<SidebarProps, {}> {

  componentDidCatch(error: Error, errorInfo) {
    setSentryContext();
    error.message += " Error occurred in component containing map - Facilities/Sidebar.tsx";
    captureTentarooError(error);
    throw error;
  };

  render() {
    const { open, onClose, location, contactInfo, systemSettings, contactInfoLabel, multiple } = this.props;

    return (
    <MainLeftSideBar open={open} onClose={onClose}>
      {/* @todo: show multiple location marker*/}
      {!multiple && <Map width="100%" height="240px" address={getFullAddress(location)}/>}
      {!multiple && <ContactCard
        title={location.Name}
        fullAddress={getFullAddress(location)}
        map={false}
        contacts={false}
        borderRadius={0}
        marginBottom={10}
      />}
      <ContactInfos
        contactInfoLabel={contactInfoLabel}
        location={location}
        contactInfo={contactInfo}
        systemSettings={systemSettings}
        defaultFirstOpen
      />
    </MainLeftSideBar>

    );
  }

}

export default Sidebar;
