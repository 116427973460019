
import { SUFFIX, UPDATE_EVENT_PRODUCT_FORM_SUFFIX } from "./actions";
import { apiValidationReducerCreator, shouldSkipUpdateForm } from "../../../../store/Validation/reducerCreator";
import { createSelector } from "reselect";
import { IAdminEventProductValidator, AdminEventProductFormDefinition } from "./validation";
import { ApplicationState } from "../../../../store";
import { setDefaults } from "../../../../utils/validator";
import { API_UPD } from "../../../../constants/request";
import { ClearAdminEventsCacheTwoProduct } from "../../CacheTwoProduct/actions";
import { ClearAdminEventsCacheBelowOne } from "../../CacheOne/actions";
import { AdminEventsProduct, AdminEventsImage } from "../../../../models/api/adminEventsCacheOne";
import { GLAccountsSelector } from "../../../CacheZero";
import { GLAccount } from "../../../../models/api/options";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../utils/StrongActions";
import { WithRootState } from "../../../Validation/actionCreator";

export interface AdminEventProductActiveForm {
  IDi?: number;
  Name?: string;
  IsAdminOnly?: boolean;
  FeaturedImage?: AdminEventsImage;
  Amount?: number;
  GLAccountID?: number;
  Description?: string;
  TS?: number;
}

export interface AdminEventProductFormState {
  ActiveForm: AdminEventProductActiveForm;
  ValidationRules: IAdminEventProductValidator;
  SubmitErrorMessage?: string;
}

const GLAccountIDSelector = (state: ApplicationState) => state.adminEvents.products.form.ActiveForm.GLAccountID;
export const makeSelectedGLAccountSelector = () => {
  return createSelector(
    [GLAccountsSelector, GLAccountIDSelector],
    (accounts: GLAccount[], GLAccountID: number) => {
      return accounts.find((a) => a.ID === GLAccountID);
    }
  );
};

const getInitialState = () => {
  return {
    ActiveForm: {},
    ValidationRules: {...AdminEventProductFormDefinition},
  };
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX);


const setProduct = (state: AdminEventProductFormState, product?: AdminEventsProduct) => {
  if (!product) return state;
  
  const newState: AdminEventProductFormState = {
    ...state,
    ActiveForm: {
      IDi: product.IDi,
      Name: product.Name,
      IsAdminOnly: product.IsAdminOnly,
      FeaturedImage: product.FeaturedImage,
      Amount: product.Amount,
      GLAccountID: product.GLAccountID,
      Description: product.Description,
      TS: product.TS,
    },
    ValidationRules: {...AdminEventProductFormDefinition},
  };

  return newState;
};

const AdminEventProductFormReducer: Reducer<AdminEventProductFormState> = (s, act: WithRootState<AnyAction>) => {

  const state = {
    ...getInitialState(),
    ...checkApiValidation(s, act),
  };

  if (act.type.includes(API_UPD) && act.type.includes(UPDATE_EVENT_PRODUCT_FORM_SUFFIX)) {
    if (shouldSkipUpdateForm(act)) return state;

    // get initial state
    let newState = getInitialState();

    // set default values
    newState = setDefaults(act.rootState, newState, state);

    // load from cache two if set
    const rootState = act.rootState;
    if (rootState.adminEvents.cacheTwoProduct && rootState.adminEvents.cacheTwoProduct.Product) {
      const product = rootState.adminEvents.cacheTwoProduct.Product;
      return setProduct(newState as any, product);
    }
    
    return newState;
  } else if (isActionType(act, ClearAdminEventsCacheTwoProduct) || isActionType(act, ClearAdminEventsCacheBelowOne)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default AdminEventProductFormReducer;