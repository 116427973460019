import { ToggleNotFound } from "../../store/App/actions";
import { SaveState } from "../../store/Rollback/actions";
import { getPageIdFromPath } from "../helpers/adminCMSPageHelper";
import { extractRouteParams, isIdFromUrlValid } from "../urlHelper";
import { GetPage } from "../../store/AdminCMSSite/CacheTwoPages/actions";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import { reduxStoreService } from "../../store/service";
import type AdminCMSCacheManager from "../cacheManagers/adminCMSCacheManager";
import { LoadAdminCMSCacheTwoPageParams } from "./helpers/models";
import {isAdminCMSCacheTwoPagePopulated} from "../cachePopulatedCheckers/adminCMS";


export const ensureCacheTwoPage = (
  params: LoadAdminCMSCacheTwoPageParams & {cacheManager: AdminCMSCacheManager}
): boolean => {
  const {props, isEdit, cacheManager, isStateNavigated} = params;

  if (!isIdFromUrlValid(props.params.siteId)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const rootState = reduxStoreService().getState();
  const adminCMSCacheTwoPage = rootState.adminCMSSite.cacheTwoPage;

  let requestedData = false;
  if (isEdit) {
    // When we do need page data, pageId must be valid (i.e. > 0)
    if (!isIdFromUrlValid(props.params.pageId)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }

    const pageId = getPageIdFromPath(props.params);

    if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminCMSCacheTwoPagePopulated(adminCMSCacheTwoPage)) {
      if (isStateNavigated) {
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      const params = {
        ID: pageId,
        GetContacts: true,
        GetResources: true,
        GetResourceCategories: true,
        GetSiteMenus: true,
        ...extractRouteParams(props),
      };
      reduxStoreService().dispatch(GetPage.request(params));

      requestedData = true;
    }
  }

  return requestedData;
};