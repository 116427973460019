import {Nullable} from "./dataHelper";

export default function (parent: Nullable<Element>, child: Nullable<Element>): boolean {
  var node = child?.parentNode ?? null;
  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}
