import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/elements/context-menu/info.scss';

export const namespace = (): string => `${ns()}--info`;

export interface InfoProps {
  children?: React.ReactNode;
}

const Info: React.SFC<InfoProps> = ({ children }: InfoProps): React.ReactElement<InfoProps> => (
  <div className={namespace()}>
    {children}
  </div>
);

export default Info;
