import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { findDOMNode } from 'react-dom';
import '../../../styles/elements/info/index.scss';
import InfoIcon from '../../../images/elements/info/info.svg';
import { noop } from '../../../utils';
import { Tooltip } from '../';

export const namespace = (): string => 'elements--info';

export interface InfoProps {
  className?: string;
  red?: boolean;
  tooltip?: React.ReactElement<any>;
  showTooltipOnClick?: boolean;
  tooltipText?: string;
}

type InfoState = {
  showTooltip: boolean;
};

class Info extends React.Component<InfoProps, InfoState> {
  public props: InfoProps;
  public state: InfoState = {
    showTooltip: false
  };

  showTooltip = () => {
    this.setState({ showTooltip: true });
  };

  hideTooltip = () => {
    this.setState({ showTooltip: false });
  };

  toggleTooltip = () => {
    this.setState({ showTooltip: !this.state.showTooltip });
  };

  getNode = () => findDOMNode(this) as any;

  closeTooltip = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showTooltip: false });
  };

  public render() {
    const { tooltipText, red, showTooltipOnClick, ...props } = this.props;
    const { showTooltip } = this.state;
    let className = mergeClassNames(namespace(), props);
    let iconClassName: string = `${namespace()}--icon`;
    if (red) {
      className += ' red';
      iconClassName += ' red';
    } else {
      className += ' gray';
      iconClassName += ' gray';
    }

    return (
      <a className={className}
         onMouseOver={showTooltipOnClick ? noop : this.showTooltip}
         onMouseOut={showTooltipOnClick ? noop : this.hideTooltip}
         onClick={showTooltipOnClick ? this.toggleTooltip : noop}
      >
        {showTooltip ?
          <Tooltip target={this.getNode} onBackdropClick={showTooltipOnClick ? this.closeTooltip : undefined}>{tooltipText}</Tooltip> :
          null}
        <InfoIcon className={iconClassName}/>
      </a>
    );
  }
}

export default Info;
