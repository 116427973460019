import * as React from 'react';
import {Main, MainContent} from '../../../../../../Layouts';
import {Alert, RadioGroup} from '../../../../../../Elements';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import {CacheFourEventsParticipantsState} from "../../../../../../../store/CacheFourEventsParticipants";
import {EventRegistrationParticipantType} from "../../../../../../../models/api/cacheFourEvents";
import {CacheThreeEventsState} from "../../../../../../../store/CacheThreeEvents";
import {ParticipantType} from "./ParticipantType";
import {EventInfoParticipantType} from "../../../../../../../models/api/cacheThreeEvents";
import {EventRegisterParticipantTypeState} from "../../../../../../../store/Events/Event/Register/Participant/Type";
import {CacheTwoEventsState} from "../../../../../../../store/CacheTwoEvents";
import {EventClassIndividualAvailable, RegisteredClass} from "../../../../../../../models/class";
import { captureTentarooError } from '../../../../../../../utils/dataHelper';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

type Props = WithInertAttribute<{
  participantId?: string;
  type: EventRegisterParticipantTypeState;
  cacheFourEventsParticipants: CacheFourEventsParticipantsState;
  cacheThreeEvents: CacheThreeEventsState;
  cacheTwoEvents: CacheTwoEventsState;
  pTypes: Array<EventRegistrationParticipantType>;
  cacheThreePTypes: Array<EventInfoParticipantType>;
  apiLoading: number;
  onSelect: (pTypeID: number) => void;
  availableClasses: Array<EventClassIndividualAvailable>;
  conflictingClasses: Array<EventClassIndividualAvailable>;
  registeredClasses: Array<RegisteredClass>;
}>;

class Type extends React.Component<Props, {}> {
  props: Props;

  componentWillMount() {
    const {pTypes, onSelect} = this.props;
    if (pTypes.length === 1) {
      const cacheThreePType = this.props.cacheThreePTypes.find((t) => t.ID === pTypes[0].ID);
      if (!cacheThreePType) {
        captureTentarooError(new Error(`Could not find Participant Type from cache three: ${pTypes[0].ID}`));
        return;
      }
      if (cacheThreePType.NumSpotsPending - cacheThreePType.NumNamesPending > 0) {
        onSelect(pTypes[0].ID);
      }
    }
  }

  mapPTypes = (pt: EventRegistrationParticipantType) => {
    const cacheThreePType = this.props.cacheThreePTypes.find((t) => t.ID === pt.ID);
    if (!cacheThreePType) {
      captureTentarooError(new Error(`Could not find Participant Type from cache three: ${pt.ID}`));
      return;
    }
    
    return <ParticipantType
      cacheFourEventsParticipants={this.props.cacheFourEventsParticipants}
      selected={this.props.type.selectedTypeID === pt.ID}
      pType={pt}
      NumNamesPending={cacheThreePType.NumNamesPending}
      NumSpotsPending={cacheThreePType.NumSpotsPending}
      isAdding
      onSelect={this.props.onSelect}
    />;
  };

  public render() {
    const {
      availableClasses, registeredClasses, pTypes, cacheTwoEvents, cacheThreePTypes, cacheFourEventsParticipants, inert,
      type: {SubmitErrorMessage}, apiLoading, participantId, type, conflictingClasses
    } = this.props;
    let c3SingleSelectedPType, singleSelectedPType; // only set this in edit mode
    if (participantId) {
      c3SingleSelectedPType = cacheThreePTypes.find((t) => t.ID === type.selectedTypeID);
      singleSelectedPType = pTypes.find((t) => t.ID === type.selectedTypeID);
    }

    const loading = apiLoading > 0;
    return (
      <Main
        inert={inert}
        key="participant"
        mobileBackground="white"
        header={<HeaderProgress
          selected="type"
          cacheFourEventsParticipants={cacheFourEventsParticipants}
          loading={loading}
          cacheTwoEvents={cacheTwoEvents}
          availableClasses={availableClasses}
          conflictingClasses={conflictingClasses}
          registeredClasses={registeredClasses}
        />}
        footer={<FooterProgress
          selected="type"
          availableClasses={availableClasses}
          conflictingClasses={conflictingClasses}
          registeredClasses={registeredClasses}
        />}
      >
        <MainContent loading={loading}>
          {SubmitErrorMessage && <Alert noFlex>{SubmitErrorMessage}</Alert>}
          <RadioGroup>
            {!singleSelectedPType && pTypes.map(this.mapPTypes)}
            {singleSelectedPType && <ParticipantType
              cacheFourEventsParticipants={cacheFourEventsParticipants}
              selected
              editMode
              pType={singleSelectedPType}
              NumNamesPending={c3SingleSelectedPType.NumNamesPending}
              NumSpotsPending={c3SingleSelectedPType.NumSpotsPending}
              disableRadio
              onSelect={this.props.onSelect}
            />}
          </RadioGroup>
        </MainContent>
      </Main>
    );
  }
}

export default Type;
