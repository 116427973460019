import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import moment from 'moment';
import { DATE_PICKER_FORMAT } from '@tentaroo/shared';
import { makeFilteredTripsSelector } from '../../../../../../store/Admin/Modals/Accounts';
import { ApplicationState } from '../../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../../../../../store/Admin/Modals/Accounts/actions";
import {actionCreators as appActionCreators} from '../../../../../../store/App/actions';
import {AccountsTrip} from '../../../../../../models/api/options';
import { actionCreators as rollbackActionCreators, SaveState } from '../../../../../../store/Rollback/actions';
import { SimpleList, DetailedListElement, EmptyMessage } from '../../../../../Elements';
import { URLS } from '../../../../../../constants/urls';
import { navPush, spaceTo_ } from '../../../../../../utils';
import { LocationsIcon, Facilities2Icon } from '../../../../../../components/Icons';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { EmptyMessageType } from '../../../../../Elements/EmptyMessage';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { GetFacilityTripData } from '../../../../../../store/CacheThreeFacilities/actions';
import { reduxStoreService } from '../../../../../../store/service';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';

interface Props {
  scrollToTop: () => void;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;


@(withRouter as any)
class RegistrationsContent extends React.PureComponent<Props, {}> {
  public props: ConnectedProps & Props;

  componentDidMount() {
    if (shouldBlockActions()) return;

    this.props.actions.init();
  }

  onSearchBack = () => {
    // @todo-minor-performance: just use 1 action
    if (this.props.adminAccounts.filterString) {
      this.props.scrollToTop();
    }
    this.props.actions.showFilterTabs(true);
    this.props.actions.filterAccounts('');
  };

  onClickTripElement = (index: number, key: string | number, trip: AccountsTrip) => {
    const {actions, cacheZero, cacheTwoFacilities, cacheThreeFacilities, router, adminAccounts, location} = this.props;
    const tripName = trip.TripName;
    const pushUrl = `${URLS.FACILITY_TRIP}/${adminAccounts.ActiveForm.TripsLocationID}/${trip.GroupTripID}/` + `${spaceTo_(tripName)}`;

    reduxStoreService().dispatch(new SaveState());
    this.onSearchBack();
    navPush(
      router,
      pushUrl,
      trip.GroupIDi,
    );
    if (
      (cacheZero.options && cacheZero.options.Group && cacheZero.options.Group.IDi === trip.GroupIDi) &&
      cacheTwoFacilities.locationID === adminAccounts.ActiveForm.TripsLocationID &&
      cacheThreeFacilities.tripID === trip.GroupTripID
    ) {
      // if same page is selected, then refresh
      reduxStoreService().dispatch(GetFacilityTripData.request({
        GroupIDi: cacheZero.options.Group.IDi,
        GroupTS: cacheZero.options.Group.TS,
        FacilityLocationID: adminAccounts.ActiveForm.TripsLocationID,
        FacilityTripID: trip.GroupTripID,
        GetOptions: false,
        GetGroupData: false,
        GetFacilityLocationData: false,
        GetFacilityTripDataParam: true,
      }));
    }
  };

  tripItemRender = (index) => {
    const trip: AccountsTrip = this.props.filteredTrips[index];
    return (<DetailedListElement
      key={`${trip.GroupIDi}_${trip.GroupTripID}`}
      index={index}
      label={`${trip.TripName} - ${trip.GroupName}`}
      mobileLabels={[trip.TripName, trip.GroupName]}
      descriptions={[
        `Start: ${moment(trip.StartDate).format(DATE_PICKER_FORMAT)}`,
        `Added: ${moment(trip.DateAdded).format(DATE_PICKER_FORMAT)}`,
        `Amount: $${trip.TotalAmount.toFixed(2)}`,
        `Balance: $${trip.Balance.toFixed(2)}`,
      ]}
      onClick={this.onClickTripElement}
      deleted={false}
      itemValue={trip}
    />);
  };

  render() {
    const {filteredTrips, adminAccounts: {ActiveForm}} = this.props;

    if (!ActiveForm.TripsLocationID || ActiveForm.TripsLocationID === -1 || !ActiveForm.TripsLocationID_Final || ActiveForm.TripsLocationID_Final === -1) {
      return (
        <EmptyMessage
          icon={LocationsIcon}
          type={EmptyMessageType.PAGE_MARGIN}
          iconHeight='96px'
          iconWidth='96px'
          description='Select Location first'
          admin
        />
      );
    }

    if (!filteredTrips || filteredTrips.length === 0) {
      return (
        <EmptyMessage
          icon={Facilities2Icon}
          type={EmptyMessageType.PAGE_MARGIN}
          iconHeight='96px'
          iconWidth='96px'
          description={ENTITY_NOT_FOUND('trips')}
          admin
        />
      );
    }

    return <SimpleList itemRenderer={this.tripItemRender} totalLength={filteredTrips ? filteredTrips.length : 0}/>;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredTripsSelector = makeFilteredTripsSelector();
  return {
    adminAccounts: state.adminAccounts,
    filteredTrips: filteredTripsSelector(state),
    cacheZero: state.cacheZero,
    cacheTwoFacilities: state.cacheTwoFacilities,
    cacheThreeFacilities: state.cacheThreeFacilities,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
  ...rollbackActionCreators}, dispatch) });

const ConnectedRegistrationsContent = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(RegistrationsContent);

export default ConnectedRegistrationsContent;