import { EventsEventType, AdminEventsOption, EventTypeLockInPricingBasedOnOption, EventTypeReportsOption, AdminEventsEventCategory, AdminEventsEmailMessage, AdminEventsEvent, AdminEventsProduct, YearOverviewByCouncil, YearOverviewByEvent, YearOverviewByParticipantType, YearOverviewByDistrict } from "../../../models/api/adminEventsCacheOne";
import { ADMIN_EVENTS_MODULE_SUFFIX, ADMIN_EVENT_TYPES_SUFFIX, ClearAllAdminEventsCache } from "./actions";
import { SUCCESS_ACTION, SUBMIT_SUCCESS_ACTION, APISuccess } from "../../Validation/actionCreator";
import { ApplicationState } from "../..";
import { AdminEventReportsOption } from "../../../models/api/adminEventsCacheTwoEvent";
import { updateEntityInList } from "../../../utils/dataHelper";
import { ClearAllCache, SetCacheAction } from "../../App/actions";
import { ClientSubmitForm } from "../EventTypes/Modals/NewEventType/actions";
import { shouldSkipResponseForCache } from "../../Validation/reducerCreator";
import { Reducer } from "redux";
import { Action, isActionType } from "../../../utils/StrongActions";
import {DeleteAdminEventTypeSubmitActions} from "../EventTypes/EventType/Form/actions";

const adminEventsCacheOneKeys = {
  EventsEventType: true,
  EventTypeRegistrationMethodOptions: true,
  EventTypeUpfrontPaymentOptions: true,
  EventTypePaymentRequirementsToAddParticipantsOptions: true,
  EventTypeMultipleEventDiscountOptions: true,
  EventTypeLockInPricingBasedOnOptions: true,
  EventTypeReportsOptions: true,
  EventCategories: true,
  EventsEmailMessages: true,
  EventsEvents: true,
  EventsProducts: true,
  YearOverview_Year: true,
  YearOverviewByCouncil: true,
  YearOverviewByEvent: true,
  YearOverviewByDistrict: true,
  YearOverviewByParticipantType: true,
  EventReportsOptions: true,
};

export enum RegistrationMethodType {
  JUST_NAMES=1,
  JUST_NUMBERS,
  NUMBERS_AND_NAMES,
}

export interface AdminEventsCacheOneState {
  EventsEventType?: EventsEventType;
  EventTypeRegistrationMethodOptions?: AdminEventsOption[];
  EventTypePaymentRequirementsToAddParticipantsOptions?: AdminEventsOption[];
  EventTypeMultipleEventDiscountOptions?: AdminEventsOption[];
  EventTypeLockInPricingBasedOnOptions?: EventTypeLockInPricingBasedOnOption[];
  EventTypeUpfrontPaymentOptions?: AdminEventsOption[];
  EventTypeReportsOptions?: EventTypeReportsOption[];
  EventCategories?: AdminEventsEventCategory[];
  EventsEmailMessages?: AdminEventsEmailMessage[];
  EventsEvents?: AdminEventsEvent[];
  EventReportsOptions?: AdminEventReportsOption[];
  EventsProducts?: AdminEventsProduct[];
  YearOverview_Year?: number;
  YearOverviewByCouncil?: YearOverviewByCouncil[];
  YearOverviewByEvent?: YearOverviewByEvent[];
  YearOverviewByDistrict?: YearOverviewByDistrict[];
  YearOverviewByParticipantType?: YearOverviewByParticipantType[];
}

export const eventsEmailMessagesSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEmailMessages || [];

const setAdminEventsCacheOneData = (act, state): AdminEventsCacheOneState => {
  const action = <APISuccess>act;

  // first store all matched keys in cacheOne
  let newState = {...state};
  const res = action.response.xhr.response;
  for (let k in res) {
    if (adminEventsCacheOneKeys[k]) {
      newState[k] = res[k];
    }
  }

  if (res.EventsProduct) {
    updateEntityInList(newState, 'EventsProducts', res.EventsProduct, 'IDi');
  }

  if (res.EventsEmailMessage) {
    updateEntityInList(newState, 'EventsEmailMessages', res.EventsEmailMessage);
  }

  if (res.EventsEventRow) {
    updateEntityInList(newState, 'EventsEvents', res.EventsEventRow, 'IDi');
  }

  return newState;
};

const handleResponse = (act, state) => {
    const res = act.response.xhr.response;

    const newState: AdminEventsCacheOneState = setAdminEventsCacheOneData(act, state);
    return newState;
};

const AdminEventsCacheOne: Reducer<AdminEventsCacheOneState> = (s, act: Action) => {
  const state = {...s};

  if ((act.type.includes(ADMIN_EVENTS_MODULE_SUFFIX) && (act.type.includes(SUCCESS_ACTION) || act.type.includes(SUBMIT_SUCCESS_ACTION))) || (isActionType(act, SetCacheAction) && act.actions.requestType.includes(ADMIN_EVENT_TYPES_SUFFIX))) {
    if (shouldSkipResponseForCache(act, 1)) return state;

    const newState = handleResponse(act, state);
    return newState;
  } else if (isActionType(act, ClientSubmitForm)) {
    return {
      EventsEventType: {
        EventTypeRow: {
          Inactive: false,
          ID: -1,
          Name: act.data.Name,
          LocationID: act.data.LocationID,
        },
        General: {
          ...act.data,
        },
      }
    } as any;
  } else if (isActionType(act, ClearAllAdminEventsCache) || isActionType(act, ClearAllCache)) {
    return {};
  }

  return state || {};
};
  
export default AdminEventsCacheOne;