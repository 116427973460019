import {IValidator, FormDefinition} from './validation';
import { apiValidationReducerCreator } from '../../../../../store/Validation/reducerCreator';
import { SUFFIX, ResetImportFromFacilityModal, FilterFacility, ImportFromFacilityToggleFilter, InitImportFromFacilityModal } from './actions';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import {setDefaults} from '../../../../../utils/validator';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface ImportFromFacilityActiveForm {
  SelectedFacilityType?: number;
  SelectedFacility?: number;
}

export interface ImportFromFacilityModalState {
  ActiveForm: ImportFromFacilityActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
  FilterText?: string;
  isTextSearching: boolean;
}

const getInitialState = (): ImportFromFacilityModalState => {
  return {
    ActiveForm: { },
    ValidationRules: { ...FormDefinition },
    isTextSearching: false,
  };
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const ImportFromFacilityModalReducer: Reducer<ImportFromFacilityModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);
  
  if (isActionType(act, FilterFacility)) {
    return {
      ...state,
      FilterText: act.filterText,
    };
  } else if (isActionType(act, InitImportFromFacilityModal)) {
    // First init with default value
    const stateWithDefaultValue = setDefaults(act.rootState, state);

    // Then override with passed in data
    return {
      ...stateWithDefaultValue,
      ActiveForm: {
        ...stateWithDefaultValue.ActiveForm,
        SelectedFacilityType: act.currentFacilityTypeID,
      },
    };
  } else if (isActionType(act, ImportFromFacilityToggleFilter)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, ResetImportFromFacilityModal)) {
    return getInitialState();
  } 
  return state || getInitialState();
};

export default ImportFromFacilityModalReducer;