import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { namespace as ns } from './constants';
import '../../../styles/elements/grid/align.scss';

export const namespace = (): string => `${ns()}--align`;

export interface AlignProps {
  children?: React.ReactNode;
  className?: string;
  bottom?: boolean;
  top?: boolean;
  end?: boolean;
  start?: boolean;
  left?: boolean;
  right?: boolean;
}

/*
 * @todo: It would be great to refactor this component to pass classNames down to the child components instead of wrapping
 * the child components in a div. For instance:
 * <Align right><Text>Hello</Text></Align>
 *
 * Currently generates this HTML:
 * <div class="elements--align right"><p class="elements--text">Hello</p></div>
 *
 * After refactoring, it could generate something like this:
 * <p class="elements--text attributes--align right">Hello</p>
 *
 * This would solve so many issues with nested components.
 * */

const Align: React.SFC<AlignProps> = (props: AlignProps):React.ReactElement<AlignProps> => {
  const { bottom, top, end, start, children, left, right } = props;

  let className = mergeClassNames(namespace(), props);
  if (bottom) className += ' bottom';
  if (top) className += ' top';
  if (left || start) className += ' start';
  if (right || end) className += ' right';

  return (
    <div className={className}>
      {children}
    </div>
  );
};

export default Align;
