import { AdminCMSCacheTwoContactContext } from "@tentaroo/shared";

import { GetContact } from "../../store/AdminCMSSite/CacheTwoContacts/actions";
import { ToggleNotFound } from "../../store/App/actions";
import { SaveState } from "../../store/Rollback/actions";
import { extractRouteParams, isIdFromUrlValid, isIdValid } from "../urlHelper";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import type AdminCMSCacheManager from "../cacheManagers/adminCMSCacheManager";
import { reduxStoreService } from "../../store/service";
import { LoadAdminCMSCacheTwoContactParams } from "./helpers/models";
import {isAdminCMSCacheTwoContactPopulated} from "../cachePopulatedCheckers/adminCMS";

export const ensureCacheTwoContact = (
  params: LoadAdminCMSCacheTwoContactParams & {
    cacheManager: AdminCMSCacheManager,
    contactId: number,
  },
): boolean => {
  const {props, isStateNavigated, cacheManager, contactId, context, isEdit} = params;
  const { params: routeParams } = props;
  if (!isIdFromUrlValid(routeParams.siteId)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }
  
  const rootState = reduxStoreService().getState();
  const adminCMSCacheTwoContact = rootState.adminCMSSite.cacheTwoContacts;

  let requestedData = false;
  if (isEdit) {
    if (!isIdValid(contactId)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }

    if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminCMSCacheTwoContactPopulated(adminCMSCacheTwoContact)) {
      if (isStateNavigated) {
        if (context === AdminCMSCacheTwoContactContext.CONTACT_FORM) {
          reduxStoreService().dispatch(GetContact.updateForm());
        }
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      const params = {
        ID: contactId,
        ...extractRouteParams(props),
      };

      reduxStoreService().dispatch(GetContact.request(params, {skipUpdateForm: context !== AdminCMSCacheTwoContactContext.CONTACT_FORM}));

      requestedData = true;
    }
  } else if (isStateNavigated) {
    requestedData = cacheManager.loadAdminCMSCacheOne({
      props,
      isStateNavigated,
    });

    if (!requestedData) {
      reduxStoreService().dispatch(GetContact.updateForm());
      reduxStoreService().dispatch(new SaveState());
    }
  }

  return requestedData;
};