
import { IValidator, FormDefinition } from './validation';
import { apiValidationReducerCreator } from "../../../Validation/reducerCreator";
import { SUFFIX, ToggleExpandFilterAction, AdminEventsHomeReset } from './actions';
import { createSelector } from 'reselect';
import { AdminEventsEvent } from '../../../../models/api/adminEventsCacheOne';
import moment from 'moment';
import { ApplicationState } from "../../..";
import { ClearAllAdminEventsCache } from "../../CacheOne/actions";
import { listSelector } from "../../../../utils/reselectHelper";
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../utils/StrongActions';
import { WithRootState } from '../../../Validation/actionCreator';
import { setDefaults } from '../../../../utils/validator';
import {ClearAllCache} from '../../../App/actions';

export interface EventsHomeState {
  ActiveForm: EventsHomeActiveForm;
  ValidationRules: IValidator;
  expandFilter: boolean;
}

export interface EventsHomeActiveForm {
  ShowDeleted?: boolean;
  FilterText?: string;
  ShowEventsFromPastYears?: boolean;
}
const getInitialState: () => EventsHomeState = () => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition},
  expandFilter: false,
});

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const eventsShowDeletedSelector = (state: ApplicationState) => !!state.adminEvents.events.home.ActiveForm.ShowDeleted;

const eventsFilterTextSelector = (state: ApplicationState) => state.adminEvents.events.home.ActiveForm.FilterText || '';

const eventsShowEventsFromPastYears = (state: ApplicationState) => state.adminEvents.events.home.ActiveForm.ShowEventsFromPastYears;

export const eventsSelector = listSelector(
  (state: ApplicationState) => state.adminEvents.cacheOne.EventsEvents || [],
);

export const makeActiveEventsSelector = () => {
  return createSelector(
    [eventsSelector],
    (events: AdminEventsEvent[]) => {
      if (events) {
        return events.filter((e) => !e.Inactive);
      }
      return [];
    }
  );
};

export const makeFilteredEventsSelector = () => {

  return createSelector(
    [eventsSelector, eventsShowDeletedSelector, eventsFilterTextSelector, eventsShowEventsFromPastYears],
    (events: AdminEventsEvent[], showDeleted: boolean, filterText: string, showEventsFromPastYears: boolean) => {
      if (events) {
        let groupByYears = false;
        const filtered = events.filter((e) => {
          const f = filterText.toLowerCase();

          const nameMatched = e.EventName.toLowerCase().includes(f);
          const now = moment();
          const eventTime = moment(e.StartDate);
          
          return nameMatched && (showDeleted || !e.Inactive) && (showEventsFromPastYears || eventTime.year() >= now.year());
        }).sort((e1, e2) => {
          const e1Moment = moment(e1.StartDate);
          const e2Moment = moment(e2.StartDate);

          if (e1Moment.year() < e2Moment.year()) {
            groupByYears = true;
            return 1;
          } else if (e1Moment.year() > e2Moment.year()) {
            groupByYears = true;
            return -1;
          } else {
            if (e1Moment.isAfter(e2Moment)) {
              return -1;
            } else if (e2Moment.isAfter(e1Moment)) {
              return 1;
            } else {
              return e1.EventName.toLowerCase().localeCompare(e2.EventName.toLowerCase());
            }
          }
        });

        return {
          events: filtered,
          groupByYears,
        };
      }

      return {
        events: [],
        groupByYears: false,
      };
    }
  );
};

const EventsHomeReducer: Reducer<EventsHomeState> = (s, action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, action);

  if (isActionType(action, ToggleExpandFilterAction)) {
    return {
      ...state,
      expandFilter: action.expand,
    };
  } else if (isActionType(action, ClearAllAdminEventsCache) || isActionType(action, ClearAllCache)) {
    return setDefaults(action.rootState, getInitialState());
  } else if (isActionType(action, AdminEventsHomeReset)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        FilterText: "",
      }
    };
  }

  return state || setDefaults(action.rootState, getInitialState());
};

export default EventsHomeReducer;