import type { ActionCreator, ApplicationState } from '../../../';
import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, innerApiSubmitFormMethod
} from '../../../Validation/actionCreator';
import {deleteOrRestoreGroupRosterPersonBody, updateGroupRosterPersonBody, URLS} from '../../../../constants/urls';
import { push } from 'react-router-redux';
import { typeName, Action } from '../../../../utils/StrongActions';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../utils/dataHelper';
import { SaveState } from '../../../Rollback/actions';

export const SUFFIX = '__ROSTER_ADD';
export const DELETE_SUFFIX = '__ROSTER_ADD_DELETE';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ApiSubmitActions = createApiSubmitActions(SUFFIX);
export const ApiSubmitDeleteActions = createApiSubmitActions(DELETE_SUFFIX);

@typeName(`INIT_ADD_DEFAULT${SUFFIX}`)
export class InitializeAddDefaultValues extends Action {}
@typeName(`RESET${SUFFIX}`)
export class Reset extends Action {}

export type Actions = typeof actionCreators;

export const formCreator = (rootState: ApplicationState, activeForm, inactive?: boolean) => {
  const {cacheZero, cacheThreeRoster} = rootState;
  // /my_roster/youth/1089564/Jonathan2_Sweetman
  const path = rootState.routing.locationBeforeTransitions.pathname.split('/');
  const isYouth = path[3] === 'youth';
  let personIDi = 0, ts = 0;

  let debugPayload: TentarooDebugPayload | undefined;
  if (!cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when submitting RosterAdd form");
  }
  if (!cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when submitting RosterAdd form");
  }
  if (path[4] !== 'add') {
    if (!cacheThreeRoster.GroupRosterPerson) {
      debugPayload = captureTentarooErrorAndGetRequestPayload("cacheThreeRoster.GroupRosterPerson not available when submitting RosterAdd form");
    }
    personIDi = Number(path[4]);
    ts = cacheThreeRoster.GroupRosterPerson?.TS || 0;
  }

  const payload = updateGroupRosterPersonBody(
    cacheZero.options?.Group?.IDi as any,
    cacheZero.options?.Group?.TS as any,
    personIDi,
    isYouth,
    ts,
    !!inactive,
    activeForm
  );

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const deleteFormCreator = (rootState: ApplicationState) => {
  const {cacheZero} = rootState;
  // /admin2/my_roster/youth/1089564/Jonathan2_Sweetman
  const path = rootState.routing.locationBeforeTransitions.pathname.split('/');
  const isYouth = path[3] === 'youth';
  const personIDi = Number(path[4]);

  let debugPayload: TentarooDebugPayload | undefined;
  if (!cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when submitting RosterAdd delete form");
  }
  if (!cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when submitting RosterAdd delete form");
  }
  const payload = deleteOrRestoreGroupRosterPersonBody(
    cacheZero.options?.Group?.IDi as any,
    cacheZero.options?.Group?.TS as any,
    personIDi,
    isYouth,
    true
  );

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.settings.roster.add),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  apiSubmitForm: (): ActionCreator => (dispatch, getState) => {
    const valid = innerApiSubmitFormMethod(
      dispatch,
      ApiSubmitActions,
      (s) => s.settings.roster.add,
      undefined,
      false,
      false,
      true
    );
    if (valid) {
      const route = getState().routing.locationBeforeTransitions;
      const requestBody = formCreator(getState(), getState().settings.roster.add.ActiveForm);
      dispatch(push(`${URLS.MY_ROSTER}${route.search}`));
      dispatch(ApiSubmitActions.request(requestBody, null));
    }
  },
  initializeAddDefaultValues: (): ActionCreator => dispatch => dispatch(new InitializeAddDefaultValues()),
  reset: (): ActionCreator => dispatch => dispatch(new Reset()),
  apiDeletePerson: (): ActionCreator => (dispatch, getState) => {
    // NOTE: There will be a save state dispatched when navigating away to `URLS.MY_ROSTER`,
    // in `Roster/Person/index.tsx`.routerWillLeave, but no harm to have another one here
    dispatch(new SaveState());
    const route = getState().routing.locationBeforeTransitions;
    const requestBody = deleteFormCreator(getState());
    // TODO: Better to uncomment the next line and not relying on navigation to close the modal. We
    // are not doing this now because then the save state from `Roster/Person/index.tsx`.routerWillLeave
    // happens after PopModal, which is incorrect
    // dispatch(appActionCreators.popModal(false, false, ModalTypes.PROFILE_DELETE_PERSON) as any);
    dispatch(push(`${URLS.MY_ROSTER}${route.search}`));
    dispatch(ApiSubmitDeleteActions.request(requestBody, null));
  }
};
