import { GetFacilityParams } from "../../../constants/urls";
import { CLEAR_CACHE_PREFIX, createRequestActions } from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { ADMIN_FACILITY_MODULE_SUFFIX } from "../CacheOne/actions";
import { HideDrawer, SilentCancelAllAction } from '../../../store/App/actions';
import { UPDATE_FACILITY_FACILITY_FORM_SUFFIX } from "../Facilities/Facility/Form/actions";
import { typeName, Action } from "../../../utils/StrongActions";


export const SUFFIX = '__CACHE_TWO_FACILITY' + UPDATE_FACILITY_FACILITY_FORM_SUFFIX;

export const GetFacility = createRequestActions(SUFFIX + ADMIN_FACILITY_MODULE_SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminFacilityLocationCacheTwoFacility extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  getFacility: (params: GetFacilityParams): ActionCreator => dispatch => {
    dispatch(GetFacility.request(params));
  },
  refreshFacility: (params: GetFacilityParams): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    dispatch(GetFacility.request(params));
  },
  clearAdminFacilityLocationCacheTwoFacility: (): ActionCreator => dispatch => {
    dispatch(new ClearAdminFacilityLocationCacheTwoFacility());
  },
};