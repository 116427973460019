import { AnyAction, EnhancedStore } from "@reduxjs/toolkit";

import type { ApplicationState } from "../store";

export const injectRootStateMiddleware =
    (store: EnhancedStore<ApplicationState>) => (next: any) => (action: AnyAction) => {
      next({
          ...action,
          rootState: store.getState(),
      });
    };