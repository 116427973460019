import {GetGroupCache} from './actions';
import {getGroupCache, getGroupCacheBody} from '../../constants/urls';
import {makeRequestEpic} from "../Validation/epicCreator";
import { ApiSubmitActions } from '../Settings/Roster/Add/actions';
import { Observable } from "rxjs";
import {GetGroupRosterCache} from "../CacheTwoRoster/actions";
import { captureTentarooError } from '../../utils/dataHelper';
import { NoOp } from '../App/actions';
import { reduxStoreService } from '../service';

export const cacheOneEpic = makeRequestEpic(
  GetGroupCache,
  getGroupCache,
  getGroupCacheBody,
  true,
);

export const refreshRosterOnConflict = action$ => (
  action$.filter(action => action.type === ApiSubmitActions.failureType && action.response.status === 409)
    .switchMap((action) => {
      const rootState = reduxStoreService().getState();

      if (!rootState.cacheZero.options) {
        captureTentarooError(new Error("cacheZero.options not available when refreshRosterOnConflict"));
        return Observable.of(new NoOp());
      }
      if (!rootState.cacheZero.options.Group) {
        captureTentarooError(new Error("Group not available when refreshRosterOnConflict"));
        return Observable.of(new NoOp());
      }
      const Group = rootState.cacheZero.options.Group;
      const params: any = {
        GroupIDi: Group.IDi,
        GroupTS: Group.TS,
        GetOptions: false,
        GetGroupData: false,
        IncludeInactive: rootState.cacheTwoRoster.showDeletedGroupRoster
      };


      return Observable.of(GetGroupRosterCache.request(params));
    })
);
