import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/media/index.scss';
import { isFirefox, isTouch } from '../../../utils';
import { captureTentarooError } from '../../../utils/dataHelper';

export const namespace = (): string => 'elements--media';

export interface MediaProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  mobile?: boolean;
  mobileAndSmaller?: boolean;
  mobileAndGreater?: boolean;
  tablet?: boolean;
  tabletAndSmaller?: boolean;
  tabletAndGreater?: boolean;
  desktop?: boolean;
  desktopOnly?: boolean;
  desktopAndSmaller?: boolean;
  desktopAndGreater?: boolean;
  largeDesktopAndSmaller?: boolean;
  largeDesktopAndGreater?: boolean;
  inline?: boolean;
  expand?: boolean;
  touch?: boolean;
  notTouch?: boolean;
  layout?: 'horizontal' | 'vertical';
  newApi?: boolean; // todo: use new api for this component, to remove once this component has been completely refactored to use the new API (see comment below)
  alwaysRender?: boolean;
  flex?: boolean;
  noFlexGrow?: boolean;
}

/**
 * See use of newApi prop above
 * @todo: It would be great to refactor this component to pass classNames down to the child components instead of wrapping
 * the child components in a div. For instance:
 * <Media mobile><Text>Hello</Text></Media>
 *
 * Currently generates this HTML:
 * <div class="elements--media mobile"><p class="elements--text">Hello</p></div>
 *
 * After refactoring, it could generate something like this:
 * <p class="elements--text attributes--media--mobile">Hello</p>
 *
 * This would solve so many issues with nested components.
 */

const Media: React.SFC<MediaProps> = (props: MediaProps): React.ReactElement<MediaProps> => {
  const {
    mobile, mobileAndSmaller, mobileAndGreater,
    tablet, tabletAndSmaller, tabletAndGreater,
    desktop, desktopOnly, desktopAndSmaller, desktopAndGreater,
    largeDesktopAndSmaller, largeDesktopAndGreater, touch, notTouch,
    children, style, inline, expand, layout, newApi, alwaysRender,
    flex, noFlexGrow
  } = props;
  let className: string = mergeClassNames(namespace(), props);

  if (alwaysRender) {
    className += ' always-render';
  } else {
    if (layout) className += ` layout-${layout}`;
    if (inline) className += ' inline';
    if (flex) className += ' flex';
    if (!expand) className += ' not-expand';

    if (mobile) className += ' mobile';
    if (mobileAndSmaller) className += ' mobile-and-smaller';
    if (mobileAndGreater) className += ' mobile-and-greater';
    if (tablet) className += ' tablet';
    if (tabletAndSmaller) className += ' tablet-and-smaller';
    if (tabletAndGreater) className += ' tablet-and-greater';
    if (desktop) className += ' desktop';
    if (desktopOnly) className += ' desktop-only';
    if (desktopAndSmaller) className += ' desktop-and-smaller';
    if (desktopAndGreater) className += ' desktop-and-greater';
    if (largeDesktopAndSmaller) className += ' large-desktop-and-smaller';
    if (largeDesktopAndGreater) className += ' large-desktop-and-greater';
    if (touch) {
      if (isFirefox) {
        if (isTouch) {
          className += ' touch-firefox';
        }
      } else {
        className += ' touch';
      }
    }
    if (notTouch) {
      if (isFirefox) {
        if (!isTouch) {
          className += ' not-touch-firefox';
        }
      } else {
        className += ' not-touch';
      }
    }
  }
  if (noFlexGrow) className += ' no-flex-grow';

  if (newApi && children) {
    if (!React.isValidElement(children)) {
      captureTentarooError(new Error('Cannot use new API for Media component if children is not a valid React element'));
    } else {
      return React.cloneElement((children as any), {
        ...children.props,
        className: className + ((children.props as any).className ? ` ${(children.props as any).className}` : ''),
        style
      });
    }
  }

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

Media.defaultProps = {
  expand: true
};

export default Media;
