import * as React from 'react';
import {
  MyEvent, MyEventQuantity, Column, MyEventDateRange, MyEventName, MyEventAmounts, MyEventDetails, MyEventWarning
} from '../../../Elements';
import * as moment from 'moment';
import '../../../../styles/pages/home/my-trips/trips.scss';

export const namespace = (): string => 'pages--home--my-trips--trip';

interface Props {
  onClick: () => void;
  name: string;
  warning?: string;
  details?: string;
  total: number;
  balance: number;
  youth: number;
  adults: number;
  dateStart: moment.Moment;
  dateEnd: moment.Moment;
  cart: boolean;
  id?: string;
}

const renderQuantity = (youth, adults) => {
  // @todo: using regular html in pages
  if (youth && adults) {
    return (
      <p className={`${namespace()}--quantity`}>
        <span>{`${youth} youth`}</span>
        <span className={`${namespace()}--quantity--comma`}>,&nbsp;</span>
        <span>{`${adults} adult${adults === 1 ? '' : 's'}`}</span>
      </p>
    );
  } else if (youth && !adults) {
    return (
      <p>
        <span>{`${youth} youth`}</span>
      </p>
    );
  } else if (!youth && adults) {
    return (
      <p>
        <span>{`${adults} adult${adults === 1 ? '' : 's'}`}</span>
      </p>
    );
  }

  return <p></p>;
};

const Trip: React.SFC<Props> = ({
   onClick, details, dateStart, dateEnd, id, name, total, balance, youth, adults, cart, warning
 }: Props): React.ReactElement<Props> => (
  <MyEvent id={id} onClick={onClick} cart={cart} className={namespace()}>
    <Column layout="vertical" expand>
      <MyEventDateRange dateStart={dateStart} dateEnd={dateEnd}/>
      <MyEventName>{name}</MyEventName>
      {details && <MyEventDetails>{details}</MyEventDetails>}
      {warning ? <MyEventWarning>{warning}</MyEventWarning> : null}
    </Column>
    <Column horizontalAlignment="right" mobileSpan={12} mobileMarginTop={4}>
      <MyEventAmounts
        total={total}
        balance={balance}
        cart={cart}
        quantity={
          <MyEventQuantity text={renderQuantity(youth, adults)}/>
        }
      />
    </Column>
  </MyEvent>
);

export default Trip;
