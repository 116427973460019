import * as React from 'react';
import {
  CampInfo, Link, CampInfoLeft, CampInfoRight, CampInfoImage, CampInfoTitle,
  CampInfoSubTitle, Media, ContactCard, Map, Button, HeaderBlock, HeaderBlockPadding, Row, Column
} from '../../Elements';
import {FacilityLocation, ContactInfo, EventContactInfo, Image, Location} from "../../../models/api/cacheOne";
import {SystemSettings} from "../../../models/api/registerForm";
import ContactInfos from './ContactInfos';
import {getFullAddress, getCityState, getImagePath, isImageValid} from '../../../utils';
import {PublicWebsiteIcon} from '../../Icons';
import {withRouter, RouteComponentProps} from "react-router";
import {navPush} from "../../../utils/navHelper";
import {getAddTripURL} from "../../../constants/urls";
import {FacilityLocationPermissions} from "../../../models/api/cacheTwoFacilties";
import {checkPermission} from "../../../utils/permissionHelper";
import {setSentryContext} from "../../../config/sentry";
import { generateDOMId } from '../../../utils/cypressHelper';
import { captureTentarooError } from '../../../utils/dataHelper';

type ConnectedProps = RouteComponentProps<{}, {}>;

export interface CampInfoProps {
  facilityLocation: FacilityLocation;
  tentarooLocation: Location;
  contactInfo: ContactInfo | EventContactInfo; // @todo: what type is this? probably make base contact info and have both extend
  FacilityLocationPermissions?: FacilityLocationPermissions;
  image?: Image | null;
  systemSettings: SystemSettings;
  showMapMobile?: boolean;
  onShowMapMobile: () => void;
  apiSaving: number;
}

class CampInfoComponent extends React.Component<CampInfoProps, {}> {
  public props: CampInfoProps & ConnectedProps;

  componentDidCatch(error: Error, errorInfo) {
    setSentryContext();
    error.message += " Error occurred in component containing map - Facilities/CampInfoComponent";
    captureTentarooError(error);
    throw error;
  };

  onNewTrip = () => {
    const {facilityLocation, FacilityLocationPermissions} = this.props;
    if (FacilityLocationPermissions) {
      checkPermission(
        () => navPush(this.props.router, getAddTripURL(facilityLocation)),
        FacilityLocationPermissions.hasAllowNewTrip,
        FacilityLocationPermissions.hasAllowNewTripReason
      );
    }
  };

  onCheckDates = () => {
    if (document.getElementsByClassName('check-dates-top')) {
      window.scrollTo(0,(document.getElementsByClassName('check-dates-top')[0] as any).offsetTop - 70);
    }
  };

  public render() {
    const {tentarooLocation, apiSaving, contactInfo, image, systemSettings, showMapMobile, onShowMapMobile} = this.props;

    return (
      <HeaderBlock hasLeftSideBar>
        <HeaderBlockPadding mobilePadding={false}>
          <CampInfo>
            {isImageValid(image) && image && <CampInfoLeft>
              <CampInfoImage imageUrl={getImagePath(image, "720px")}/>
            </CampInfoLeft>}
            <CampInfoRight
              title={<CampInfoTitle>{tentarooLocation.Name}</CampInfoTitle>}
              subTitle={<CampInfoSubTitle>{getCityState(tentarooLocation)}</CampInfoSubTitle>}
            >
              {contactInfo.WebsiteURL && <Row marginBottom={8}>
                <Column span={6}>
                  <Link externalLink={contactInfo.WebsiteURL} size={14} icon={PublicWebsiteIcon}>
                    WEBSITE
                  </Link>
                </Column>
              </Row>}
              <Row >
                <Column span={6}>
                  <Button
                    disabled={apiSaving > 0}
                    big expand
                    color="white" textColor="black"
                    onClick={this.onCheckDates}
                  >
                    CHECK DATES
                  </Button>
                </Column>
                <Column span={6}>
                  <Button disabled={apiSaving > 0} id={generateDOMId("new-trip-btn")} big color="green" expand onClick={this.onNewTrip}>NEW TRIP</Button>
                </Column>
              </Row>
            </CampInfoRight>
          </CampInfo>
        </HeaderBlockPadding>
        <Media mobile>
          <ContactCard
            contacts={false}
            mapIsSelected={showMapMobile}
            onMapClick={onShowMapMobile}
            title={tentarooLocation.Name}
            fullAddress={getFullAddress(tentarooLocation)}
          />
          {showMapMobile ? <Map width="100%" height="240px" marginBottom={10} address={getFullAddress(tentarooLocation)}/> : null}
          <HeaderBlockPadding padding="6px 0">
            <ContactInfos
              contactInfoLabel="Facilities Contact"
              location={tentarooLocation}
              contactInfo={contactInfo}
              systemSettings={systemSettings}
            />
          </HeaderBlockPadding>
        </Media>
      </HeaderBlock>
    );
  }
}

export default withRouter(CampInfoComponent as any);
