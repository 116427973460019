
import { AdminFacilityTypeDetail, AdminFacilityTypeAttribute } from "../../../models/api/adminFacilitiesCacheTwoFacilityType";
import { ADMIN_FACILITY_MODULE_SUFFIX, ClearAdminFacilityLocationCacheBelowOne, ClearAllAdminFacilityLocationCache } from "../CacheOne/actions";
import { APISuccess } from "../../../store/Validation/actionCreator";
import { ClearAdminFacilityLocationCacheTwoFacilityType, GetFacilityType } from './actions';
import { ClearAllCache, PopModal, SetCacheAction } from "../../../store/App/actions";
import { GetFacility, ClearAdminFacilityLocationCacheTwoFacility } from "../CacheTwoFacility/actions";
import { ApplicationState } from "../../../store";
import { createSelector } from "reselect";
import { UpdateFacilityTypeSubmitActions } from "../Facilities/FacilityType/Form/actions";
import { AddFacilitySubmitActions } from "../Facilities/Modals/NewFacility/actions";
import { ImportBookingTimesSubmitActions, ImportPricingSubmitActions, ImportImagesSubmitActions } from "../Facilities/Facility/Form/actions";
import { processHour } from "../../../utils/dateHelper";
import { shouldSkipResponseForCache } from "../../Validation/reducerCreator";
import { Reducer } from "redux";
import { Action, isActionType } from "../../../utils/StrongActions";

const adminFacilityLocationCacheTwoFacilityTypeKeys = {
  Attributes: true,
  BookingTimes: true,
  FacilityTypeRow: true,
  General: true,
};

export interface AdminFacilityLocationCacheTwoFacilityTypesState {
  FacilitiesType?: AdminFacilityTypeDetail;
}

const setAdminFacilityLocationCacheTwoFacilityTypeData = (data): AdminFacilityLocationCacheTwoFacilityTypesState => {
    const result: any = {
      FacilitiesType: { },
    };

    for (let key of Object.keys(data.FacilitiesType)) {
      if (adminFacilityLocationCacheTwoFacilityTypeKeys[key]) {
        result.FacilitiesType[key] = data.FacilitiesType[key];
      }
    }
    const checkinTokens = data.FacilitiesType.BookingTimes.CheckinTime.split(':');
    const checkoutTokens = data.FacilitiesType.BookingTimes.CheckoutTime.split(':');
    result.FacilitiesType.BookingTimes.StartHour = Number(checkinTokens[0]) >= 0 ? processHour(Number(checkinTokens[0])) : 0;
    result.FacilitiesType.BookingTimes.StartMin = Number(checkinTokens[1]);
    result.FacilitiesType.BookingTimes.StartPeriod = Number(checkinTokens[0]) > 11 ? 1 : 0;
    result.FacilitiesType.BookingTimes.EndHour = Number(checkoutTokens[0]) >= 0 ? processHour(Number(checkoutTokens[0])) : 0;
    result.FacilitiesType.BookingTimes.EndMin = Number(checkoutTokens[1]);
    result.FacilitiesType.BookingTimes.EndPeriod = Number(checkoutTokens[0]) > 11 ? 1 : 0;

    return result;
};

const facilityTypeAttributesSelector = (state: ApplicationState) => state.adminFacilityLocation.cacheTwoFacilityTypes.FacilitiesType ? state.adminFacilityLocation.cacheTwoFacilityTypes.FacilitiesType.Attributes : [];
export const makeSortedFacilityTypeAttributesSelector = () => {
  return createSelector(
    [facilityTypeAttributesSelector],
    (attributes: AdminFacilityTypeAttribute[]) => {
      if (attributes) {
        return [...attributes].sort((a, b) => {
          return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase());
        });
      }

      return [];
    }
  );
};

const handleResponse = (innerResponse,) => {
  if (innerResponse.FacilitiesType) {
    const newState: AdminFacilityLocationCacheTwoFacilityTypesState = setAdminFacilityLocationCacheTwoFacilityTypeData(innerResponse);
    return newState;
  }
};

const AdminFacilityLocationCacheTwoFacilityTypes: Reducer<AdminFacilityLocationCacheTwoFacilityTypesState> = (state: AdminFacilityLocationCacheTwoFacilityTypesState, act: Action) => {
  if (act.type === GetFacilityType.successType || (isActionType(act, SetCacheAction) && act.actions.requestType.includes(ADMIN_FACILITY_MODULE_SUFFIX)) || act.type === GetFacility.successType || act.type === AddFacilitySubmitActions.successType || act.type === ImportBookingTimesSubmitActions.successType || act.type === ImportPricingSubmitActions.successType || act.type === ImportImagesSubmitActions.successType || act.type === UpdateFacilityTypeSubmitActions.successType) {
    if (shouldSkipResponseForCache(act)) return state;

    const action = <APISuccess> act;
    const newState = handleResponse(action.response.response) || {...state};

    return newState;
  } else if (isActionType(act, ClearAdminFacilityLocationCacheBelowOne) || isActionType(act, ClearAdminFacilityLocationCacheTwoFacilityType) || isActionType(act, ClearAllAdminFacilityLocationCache) || isActionType(act, ClearAdminFacilityLocationCacheTwoFacility) || isActionType(act, ClearAllCache)) {
    return { };
  }
  return state || {};
};
  
export default AdminFacilityLocationCacheTwoFacilityTypes;