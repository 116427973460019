import { createSelector } from 'reselect';
import cloneDeep from 'lodash.cloneDeep';
import { FormDefinition as AddGroupDefinition, IValidator, getDefaultTier } from '../../AddGroup/validation';
import { districtsSelector, filteredDistrictsCombiner } from '../../CacheZero/selectors';
import { ApplicationState } from '../..';
import { getUnitGenderCustomValidate, getUnitGenderOptionValues, getValues, isUnitGenderIDShown } from '../../AddGroup/validationHelpers';

const filterCouncilSelector = (state: ApplicationState) => state.settings.profile.ActiveForm.CouncilIDi;

export const makeFilteredDistrictSelector = () => {
  return createSelector(
    [districtsSelector, filterCouncilSelector],
    filteredDistrictsCombiner,
  );
};

const filteredDistrictSelector = makeFilteredDistrictSelector();

// Definition is almost the same from add groups, though the default values are different and we don't need Passwords
export let FormDefinition: Omit<IValidator, "Password" | "ConfirmPassword"> & Partial<Pick<IValidator, "Password" | "ConfirmPassword">> = cloneDeep(AddGroupDefinition);

if (FormDefinition.Username.apiCheck) {
  FormDefinition.Username.apiCheck.body = (rootState, val) => {
    return {
      CampID: rootState.session.SystemSettings ? rootState.session.SystemSettings.CampID : undefined,
      Username: val,
      IDi: (rootState.cacheZero.options && rootState.cacheZero.options.Group) ? rootState.cacheZero.options.Group.IDi : 0
    };
  };
}
delete FormDefinition.Password;
delete FormDefinition.ConfirmPassword;

FormDefinition.Unit.isRequired = (rootState) => {
  return rootState.settings.profile.ActiveForm.GroupTypeID === 1;
};
FormDefinition.UnitGenderID.isRequired = () => false;
FormDefinition.UnitGenderID.skip = (rootState) => !isUnitGenderIDShown(() => rootState.settings.profile.ActiveForm);
FormDefinition.UnitGenderID.options.values = (rootState) => getUnitGenderOptionValues(
  rootState,
  () => rootState.settings.profile.ActiveForm,
);
FormDefinition.UnitGenderID.customValidate = (rootState) => getUnitGenderCustomValidate(() => rootState.settings.profile.ActiveForm);

FormDefinition.CouncilIDi.skip = (rootState) => rootState.settings.profile.ActiveForm.GroupTypeID !== 1;


if (FormDefinition.DistrictIDi.options) {
  FormDefinition.DistrictIDi.options.values = (rootState) => {
    return filteredDistrictSelector(rootState);
  };
}
FormDefinition.DistrictIDi.isRequired = () => {
  // const cz = rootState.cacheZero.options;
  // const filteredDistricts = filteredDistrictSelector(rootState);
  // if (rootState.settings.profile.ActiveForm.GroupTypeID === 1 && cz && cz.Districts && filteredDistricts.length > 1) {
  //   return true;
  // }
  return false;
};

FormDefinition.Organization.isRequired = (rootState) => {
  return rootState.settings.profile.ActiveForm.GroupTypeID === 4;
};
FormDefinition.PricingTypeID.defaultValue = (rootState) => {
  const form = rootState.settings.profile.ActiveForm;
  const opts = rootState.cacheZero.options;
  const settings = rootState.session.SystemSettings;
  if (opts) return getDefaultTier(form, settings, opts.EventPricingTypes, FormDefinition.CouncilIDi.defaultValue);
  return 1;
};
FormDefinition.FacilityPricingTypeID.defaultValue = (rootState) => {
  const form = rootState.settings.profile.ActiveForm;
  const opts = rootState.cacheZero.options;
  const settings = rootState.session.SystemSettings;
  if (opts) return getDefaultTier(form, settings, opts.FacilityPricingTypes, FormDefinition.CouncilIDi.defaultValue);
  return 1;
};
FormDefinition.AllowOnlineBooking.defaultValue = (rootState) => rootState.settings.profile.ActiveForm.GroupTypeID === 1;
