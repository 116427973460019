import type {ActionCreator, ApplicationState} from '../../../../../';
import {eventProductsRecalcBody} from '../../../../../../constants/urls';
import {SaveState} from "../../../../../Rollback/actions";
import {Product} from "../../../../../../models/product";
import {composeOrderedProducts} from "../../../../../CacheFourEventsNumbers/actions";
import {ShowTopFloatingAlert} from "../../../../../App/actions";
import {EventRegisterProductsSelectPage, EventRegisterProductsSetPendingPage} from "../Main/actions";
import {
  createApiSubmitActions,
  createApiValidateActions,
  createValidateActions
} from "../../../../../Validation/actionCreator";
import { typeName, Action } from '../../../../../../utils/StrongActions';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../../../utils/dataHelper';

export const SUFFIX = '__PRODUCTS_PRODUCT_PAGE';
export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ApiSubmitActionsProductsProducts = createApiSubmitActions(SUFFIX, true);

@typeName(`ADD_PRODUCT${SUFFIX}`) export class EventRegisterProductsProductAdd extends Action {
  constructor(public product: Product) { super(); }
}

@typeName(`REMOVE_PRODUCT${SUFFIX}`) export class EventRegisterProductsProductRemove extends Action {
  constructor(public product: Product) { super(); }
}

@typeName(`SET_NOT_ADDED_QUANTITY${SUFFIX}`)
export class SetNotAdded extends Action {
  constructor(public product: Product, public attrs: any) {
    super();
  }
}

@typeName(`SET_ADDED_QUANTITY${SUFFIX}`)
export class SetAdded extends Action {
  constructor(public product: Product, public attrs: any) {
    super();
  }
}

@typeName(`SHOW_ERROR${SUFFIX}`) export class EventRegisterProductsProductShowError extends Action {
  constructor(public error: string, public isError: boolean) { super(); }
}

@typeName(`CLEAR_ERROR${SUFFIX}`) export class ClearError extends Action {}

@typeName(`MANAGE_PRODUCT_PRODUCTS${SUFFIX}`)
export class ManageProductProducts extends Action {
  constructor(public product: Product) {
    super();
  }
}

@typeName(`CANCEL_PRODUCT_PRODUCTS${SUFFIX}`)
export class CancelProductProducts extends Action {
  constructor(public product: Product) {
    super();
  }
}

@typeName(`COMMIT_PRODUCT_PRODUCTS${SUFFIX}`)
export class CommitProductProducts extends Action {
  constructor(public product: Product) {
    super();
  }
}

@typeName(`RESET_MANAGING_PRODUCTS_NUMBERS${SUFFIX}`)
export class ResetManagingProductsProducts extends Action {}

export type Actions = typeof actionCreators;

const formCreator = (rootState: ApplicationState) => {
  const options = rootState.cacheZero.options;
  const eventTypeID = rootState.cacheTwoEvents.eventTypeID;
  const eventID = rootState.cacheThreeEvents.eventID;
  const groupWeekIDi = rootState.cacheThreeEvents.EventRegistrationPaymentStatus!.IDi;
  const c4 = rootState.cacheFourEventsProducts;

  let debugPayload: TentarooDebugPayload | undefined;
  if (!options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("options not available when submitting EventProduct form");
  }
  if (!options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when submitting EventProduct form");
  }

  const products = composeOrderedProducts(c4.EventProductsOrdered, c4.EventProductsOrdered_Updates);

  const payload = eventProductsRecalcBody(
    {
      GroupIDi: options?.Group?.IDi as any,
      GroupTS: options?.Group?.TS as any,
      EventTypeID: eventTypeID,
      EventIDi: eventID,
      GroupWeekIDi: groupWeekIDi,
      ProductOrders: products
    }
  );

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  productsSubmit: (page: string, isNext: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());

    const orderedProducts = getState().cacheFourEventsProducts.EventProductsOrdered;
    let isValid = true;
    if (orderedProducts) {
      for (let i = 0; i < orderedProducts.length; i++) {
        const product = orderedProducts[i];
        if (product.isManaging) {
          isValid = false;
          break;
        }
      }
    }
    if (!isValid) {
      if (!isNext) {
        dispatch(new ResetManagingProductsProducts());
      } else {
        dispatch(new ShowTopFloatingAlert('Please update or cancel all product orders that are being edited.', false, 'orange'));
        return;
      }
    }

    if (!getState().events.event.register.products.products.isDirty) {
      dispatch(new EventRegisterProductsSelectPage(page as 'products' | 'confirmation'));
      return;
    }

    dispatch(new EventRegisterProductsSetPendingPage(page as 'products' | 'confirmation'));
    dispatch(ApiSubmitActionsProductsProducts.request(formCreator(getState()), null));
  },
  addProduct: (product: Product): ActionCreator => dispatch => dispatch(new EventRegisterProductsProductAdd(product)),
  removeProduct: (product: Product): ActionCreator => dispatch => dispatch(new EventRegisterProductsProductRemove(product)),
  showError: (error: string, isError: boolean): ActionCreator => dispatch => dispatch(new EventRegisterProductsProductShowError(error, isError)),
  setNotAdded: (product: Product, attrs: any): ActionCreator => dispatch => dispatch(new SetNotAdded(product, attrs)),
  setAdded: (product: Product, attrs: any): ActionCreator => dispatch => dispatch(new SetAdded(product, attrs)),
  manageProduct: (product: Product): ActionCreator => dispatch => dispatch(new ManageProductProducts(product)),
  cancelProduct: (product: Product): ActionCreator => dispatch => dispatch(new CancelProductProducts(product)),
  commitProduct: (product: Product): ActionCreator => dispatch => dispatch(new CommitProductProducts(product)),
  resetManagingProducts: (): ActionCreator => dispatch => dispatch(new ResetManagingProductsProducts())
};

