import * as React from 'react';
import CardTitle from '../../Card/Title';

import { namespace as ns } from './constants';

import '../../../../styles/elements/roster/card/title.scss';

export const namespace = (): string => `${ns()}--title`;

interface Props {
  children?: React.ReactNode;
  extra?: React.ReactNode;
  controls?: React.ReactNode;
  strong?: boolean;
  mobileTopMargin?: boolean;
  mobileMarginBottom?: number;
}

const TitleComponent: React.SFC<Props> = ({ children, extra, controls, strong, mobileTopMargin, mobileMarginBottom }: Props): React.ReactElement<Props> => (
  <CardTitle
    className={namespace() + (strong ? ' strong' : '')}
    controls={controls}
    extra={extra}
    marginTop={24} marginLeft={24} marginRight={24}
    mobileMarginTop={mobileTopMargin ? 16 : 0}
    mobileMarginBottom={mobileMarginBottom ? mobileMarginBottom : 4}
    mobileMarginLeft={16} mobileMarginRight={16}
  >
    {children}
  </CardTitle>
);

export default TitleComponent;
