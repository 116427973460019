import { ApplicationState } from "../../../../../store";
import { SelectValidator } from "../../../../../utils/validator/models";

export interface IValidator {
  SelectedContactCategoryID: SelectValidator;
}

export const getContactCategoryValues = (rootState: ApplicationState) => {
  return rootState.adminCMSSite.cacheOne.ContactCategories ? rootState.adminCMSSite.cacheOne.ContactCategories : [];
};

export const FormDefinition: IValidator = {
  SelectedContactCategoryID: {
    key: 'SelectedContactCategoryID',
    options: {
      values: (rootState) => [{ Name: 'All Categories', ID: 0 }, ...getContactCategoryValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'Name'
    },
    defaultValue: () => {
      return 0;
    },
  },
};