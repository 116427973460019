import * as React from 'react';
import { connect } from "react-redux";

import type { ApplicationState } from "../../../store";
import { getMergeProps } from "../../../utils/reduxHelper";
import {ModalTypes} from '../../../utils/modalHelper';
import {makeCurrentModalSelector} from '../../../store/App';
import { hasOngoingSequentialRequest } from '../../../store/App/reducerHelpers';

type ConnectedProps = {
  shouldInert: boolean;
}

type WithInertConfig<Props> = {
  WrappedComponent: React.ClassType<Props, React.Component<Props, any>, any>,
  
  modalType: ModalTypes | undefined;
}

/**
 * Use this HoC to wrap components at the root level (i.e. in App.tsx), so that they
 * can be marked as `inert` when certain condition is met.
 * 
 * We only care and wrap components that are interactable, such as page, header, nav, modals, etc.
 */
function WithInert<Props>(
  config: WithInertConfig<Props>,
): React.ComponentClass<Props, {}> {
  const {modalType, WrappedComponent} = config;

  const mapStateToProps = (state: ApplicationState): ConnectedProps => {
    // We'd like to always mark those components as inert during save, but NOT during
    // sequential save
    let shouldInert = state.app.apiSaving > 0 && !hasOngoingSequentialRequest(state);

    if (modalType === undefined) {
      shouldInert ||= state.app.openedModals.length > 0;
    } else {
      const currentModalSelector = makeCurrentModalSelector();

      shouldInert ||= currentModalSelector(state)?.type !== modalType;
    }

    return {
      shouldInert,
    };
  };

  class EnhancedComponent extends React.Component<ConnectedProps & Props, {}> {
    public props: ConnectedProps & Props;

    constructor(props) {
      super(props);
    }

    render() {
      return <WrappedComponent {...this.props} inert={this.props.shouldInert ? "true" : undefined} />;
    }
  }

  return connect(
    mapStateToProps,
    null,
    getMergeProps<Props>(),
  )(EnhancedComponent);
}

export function WithInertPage<Props>(
  WrappedComponent: React.ClassType<Props, React.Component<Props, any>, any>,
) {
  return WithInert<Props>({
    WrappedComponent,
    modalType: undefined,
  });
}

const cachedModalsWithInert: Record<string, ReturnType<typeof WithInert>> = {};

/**
 * Use this to create an `EnhancedModal` ComponentClass that configures `inert` to disable
 * the modal if
 * - There's any ongoing save
 * - The modal is not the topest modal
 * 
 * This also cache the `EnhancedModal` ComponentClass under the hood because we are doing
 * this enhancement dynamically (i.e. during each render in App.tsx)
 */
export function WithInertModal<Props>(
  WrappedComponent: React.ClassType<Props, React.Component<Props, any>, any>,
  modalType: ModalTypes,
) {
  if (cachedModalsWithInert[`${modalType}`] === undefined) {
    const EnhancedModal = WithInert<Props>({
      WrappedComponent,
      modalType,
    });
  
    cachedModalsWithInert[`${modalType}`] = EnhancedModal;
  }

  return cachedModalsWithInert[`${modalType}`];
}

export type WithInertAttribute<T> = T & {inert: string | undefined};