import * as React from 'react';

import { Form, Row, Column, FormActions, TextField, Alert } from '../../../../Elements';
import { Button } from '../../../../Elements';
import { Main, MainContent } from '../../../../Layouts';
import { IValidator } from '../../../../../store/AdminSettings/GLAccounts/GLAccount/Form/validation';
import '../../../../../styles/pages/contacts/form/index.scss';
import { GLAccountActiveForm } from '../../../../../store/AdminSettings/GLAccounts/GLAccount/Form';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { Validator } from '../../../../../utils/validator/models';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--gl-account--form';

export type Props = WithInertAttribute<{
  action: 'add' | 'edit';
  disabled: boolean;
  ActiveForm: GLAccountActiveForm;
  ValidationRules: IValidator;
  loading?: boolean;
  onSave?: () => void;
  onDelete?: () => void;
  hideFormAction?: boolean;
  SubmitErrorMessage?: string;
  handleCompact?: boolean;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
    showTopFloatingAlert(message: string, inModal?: boolean, color?: 'green' | 'orange'),
  };
}>

class GLAccountForm extends React.Component<Props, {}> {
  public props: Props;
  public render() {
    const {onSave, inert, onDelete, action, disabled, reduxActions, ValidationRules, ActiveForm, loading, hideFormAction, SubmitErrorMessage, handleCompact} = this.props;

    return (
        <Main inert={inert}>
          <MainContent loading={loading} handleCompact={handleCompact}>
            <Form
              className={`${namespace()}`}
              onSubmit={e => e.preventDefault()}
              actions={
                hideFormAction ? null : (action === 'add' ? (
                  <FormActions><Button flat textColor="black" big onClick={onSave}>CREATE</Button></FormActions>
                ) : (
                  <FormActions>
                    <Row>
                      <Column expand>
                        <Button
                          color="white"
                          flat textColor="black" big onClick={onSave}>SAVE</Button>
                      </Column>
                    </Row>
                  </FormActions>
                ))
              }
            >
              {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
              <Row marginBottom={8} mobileMarginBottom={8}>
                <Column span={12} mobileSpan={12}>
                  <TextField
                    label="Name"
                    id={generateDOMId("gl-account-name-field")}
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Name}
                    value={ActiveForm.Name}
                  />
                </Column>
              </Row>
              <Row>
                <Column span={12} mobileSpan={12}>
                  <TextField
                    label="Account Number"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.AccountNum}
                    value={ActiveForm.AccountNum}
                  />
                </Column>
              </Row>
            </Form>
          </MainContent>
        </Main>
    );
  }
}

export default GLAccountForm;
