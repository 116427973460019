import * as React from 'react';
import '../../../../styles/elements/page-menu/card/group.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton, Vignette, EmptyMessage} from '../../index';
import { CMSPageMenuItem } from '../../../../models/api/adminCMSCacheOne';
import { OptionsIcon, ArrowIcon, DrawerIcon, AddPlusOutlineCircleIcon } from '../../../Icons';
import { AdminCMSSiteCacheOneState } from '../../../../store/AdminCMSSite/CacheOne';
import ContextMenuComponent from './ContextMenu';
import { ADMIN_CMS_SITE_MENU_EDIT_PATH, ADMIN_CMS_PAGE_MENU_EDIT_PATH } from '../../../../routes';
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--page-menu--group-card';

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin';
  menuItem: CMSPageMenuItem;
  childrenNum?: number;
  activeChildrenNum?: number;
  onEditGroup?: (menuItem: CMSPageMenuItem) => void;
  onRestore?: (menuItem: CMSPageMenuItem) => void;
  onExpand?: (menuItem: CMSPageMenuItem) => void;
  onDeleteGroup?: (menuItem: CMSPageMenuItem) => void;
  onNewPageMenu?: () => void;
  adminCMSCacheOne?: AdminCMSSiteCacheOneState
  routes?: any;
  allowPropagation?: boolean;
}

const PageMenuGroup: React.SFC<Props> = ({ children, template, onEditGroup, onDeleteGroup, onExpand, menuItem, onRestore, adminCMSCacheOne, onNewPageMenu, routes, childrenNum, activeChildrenNum, ...props }: Props): React.ReactElement<Props> => {
  let className = attribute(namespace(), undefined, props);

  const onEditClick = (e) => {
    if (!props.allowPropagation) {
      e.stopPropagation();
      onEditGroup && onEditGroup(menuItem);
    }
  };
  const onRestoreClick = (e) => {
    if (!props.allowPropagation) {
      e.stopPropagation();
      onRestore && onRestore(menuItem);
    }
  };
  const onExpandClick = (e) => {
    e.stopPropagation();
    onExpand && onExpand(menuItem);
  };
  const onNewPageMenuClick = (e) => {
    if (!props.allowPropagation) {
      e.stopPropagation();
      onNewPageMenu && onNewPageMenu();
    }
  };
  const renderHeader = () => {
    const route = routes[routes.length - 1];
    return (
      <div className={`${namespace()}--wrapper--list-header ${menuItem.Expand ? 'expand' : ''}`} onClick={onEditClick}>
        {!menuItem.ParentID ? <div id={generateDOMId(`page-menu-card-expand-${menuItem.ID}`)} className={`${namespace()}--wrapper--list-header--arrow ${menuItem.Expand ? 'expand' : ''}`} onClick={onExpandClick}><ArrowIcon /></div> : null}
        <div className={`${namespace()}--wrapper--list-header--name`}>{menuItem.Name}</div>
        {!menuItem.Inactive && activeChildrenNum && activeChildrenNum > 0 ?
          <div className={`${namespace()}--wrapper--list-header--subitems`}>{`${activeChildrenNum}`}<span>{` item${activeChildrenNum > 1 ? 's' : ''}`}</span></div> : null}
        {!menuItem.Inactive && <div className={`${namespace()}--wrapper--list-header--right-section`}>
          {activeChildrenNum && activeChildrenNum > 0 ?
            <div className={`${namespace()}--wrapper--list-header--right-section--subitems`}>{`${activeChildrenNum}`}<span>{` item${activeChildrenNum > 1 ? 's' : ''}`}</span></div> : null}
          {menuItem.Expand && <ActionButton
            allowPropagation={route.path === ADMIN_CMS_SITE_MENU_EDIT_PATH || route.path === ADMIN_CMS_PAGE_MENU_EDIT_PATH}
            icon={OptionsIcon}
            className={`${namespace()}--wrapper--list-header--options`}
            contextMenu={<ContextMenuComponent
              onEdit={onEditClick}
              onDelete={onDeleteClick} />}
            />}
          {menuItem.Expand && <Button id={generateDOMId(`page-menu-item-${menuItem.ID}-new-btn`)} className={`${namespace()}--wrapper--list-header--new`} color='green' textColor='white' onClick={onNewPageMenuClick}>NEW ITEM</Button>}
          {menuItem.Expand && <ActionButton icon={AddPlusOutlineCircleIcon} onClick={onNewPageMenuClick} className={`${namespace()}--wrapper--list-header--new-icon`}></ActionButton>}
        </div>}
        {menuItem.Inactive && onRestore && <Button className={`${namespace()}--wrapper--list-header--restore`} flat textColor="red" onClick={onRestoreClick}>RESTORE</Button>}
      </div>
    );
  };
  const onDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteGroup && onDeleteGroup(menuItem);
  };
  if (menuItem.Inactive) className += ' deleted';

  const onCardClick = (e) => {
    if (!menuItem.Expand) {
      onExpandClick(e);
    }
  };

  const renderContent = () => {
    if (childrenNum && childrenNum > 0) {
      return children;
    } else {
      return (
        <div className={`${namespace()}--empty-message-wrapper`}>
          <EmptyMessage
            icon={DrawerIcon}
            iconHeight='96px'
            iconWidth='96px'
            fixedFontSize
            description='No menu items added' 
          />
        </div>
      );
    }
  };

  if (menuItem.Inactive) className += ' deleted';

  return (
    <Card
      className={className}
      component={<section/>}
      template={template}
      padding="none"
    >
      <div id={generateDOMId(`admin-page-menu-group-${menuItem.ID}`)} className={`${namespace()}--wrapper ${!menuItem.ParentID && childrenNum && childrenNum > 0 ? 'has-child' : ''} ${menuItem.Expand ? 'expand' : ''}`}
          onClick={onCardClick}>
        {renderHeader()}
        {menuItem.Expand && renderContent()}
      </div>
    </Card>
  );
};

PageMenuGroup.defaultProps = {
  template: 'mobile-no-margin',
};

export default PageMenuGroup;