import {EventNumbersCalc, EventNumbersSave} from './actions';
import {APIFailure, APISuccess, WithRootState} from "../Validation/actionCreator";
import {ClearAllCache, ClearAllEndUserCacheButOptions} from "../App/actions";
import {ClearCacheBelowOne} from "../CacheOne/actions";
import {EventClassGroupAvailable, RegisteredClass} from "../../models/class";
import {ClearCacheBelowTwoEvents} from "../CacheTwoEvents/actions";
import {ClearCacheBelowThreeEvents} from "../CacheThreeEvents/actions";
import {EventRegistrationPaymentStatus} from "../../models/api/cacheThreeEvents";
import {
  EventRegistrationCampsiteAssignment,
  EventRegistrationCampsiteRankingOption,
  EventRegistrationGroupClass,
  EventRegistrationNumbersC4,
  EventRegistrationParticipantType,
  EventRegistrationSpot
} from "../../models/api/cacheFourEvents";
import {convertObjToMoment, convertToMoment} from "../../utils/dateHelper";
import {ApiSubmitActionsNumbersSpots} from "../Events/Event/Register/Numbers/Spots/actions";
import {
  ApiSubmitActionsNumbersClasses,
  ClearWarning,
  EventRegisterNumbersClassesAdd,
  EventRegisterNumbersClassesRemove
} from "../Events/Event/Register/Numbers/Classes/actions";
import {classSort, determineConflict} from "../../utils/classesHelper";
import {ClassOverrideFeeSave} from "../Events/Event/Modals/ClassOverrideFee/actions";
import {OrderedProduct, Product, ProductUpdate} from "../../models/product";
import {
  ApiSubmitActionsNumbersProducts,
  CancelProductNumbers,
  CommitProductNumbers,
  EventRegisterNumbersProductAdd,
  EventRegisterNumbersProductRemove,
  ManageProductNumbers,
  ResetManagingProductsNumbers,
  SetNumbersAdded,
  SetNumbersNotAdded
} from "../Events/Event/Register/Numbers/Products/actions";
import {
  addProductToState,
  cancelAddedProductToState,
  commitAddedProductToState,
  manageAddedProductToState,
  removeProductToState, resetManagingProductsToState,
  updateAddedProductToState,
  updateAvailableProductToState
} from "../CacheFourEventsProducts/reducerHelpers";
import { ApiSubmitActionsNumbersCampsite } from '../Events/Event/Register/Numbers/Campsite/actions';
import { getCampsiteTempId } from '../Events/Event/Register/Numbers/Campsite/validation';
import { getSpotTempId } from '../Events/Event/Register/Numbers/Spots/validation';
import { captureTentarooError } from '../../utils/dataHelper';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../utils/StrongActions';
import type { ApplicationState } from '..';
const AVAILABLE_PRODUCT_KEY = 'EventProductsAvailable';
const ADDED_PRODUCT_KEY = 'EventProductsOrdered';

export interface CacheFourEventsNumbersCore {
  EventRegistrationParticipantTypes?: Array<EventRegistrationParticipantType> | null;
  EventRegistrationPaymentStatus?: EventRegistrationPaymentStatus | null;
  EventRegistrationGroupClasses?: Array<EventRegistrationGroupClass> | null;
  EventRegistrationCampsiteRankingOptions?: Array<EventRegistrationCampsiteRankingOption> | null;
  EventRegistrationNumbers?: EventRegistrationNumbersC4 | null;
  EventClassesGroupAvailable: Array<EventClassGroupAvailable>;
  EventClassesGroupRegistered: Array<RegisteredClass>;
  EventProductsAvailable?: Array<Product> | null;
  EventProductsOrdered?: Array<OrderedProduct> | null;
  EventProductsOrdered_Updates?: Array<ProductUpdate> | null;
  EventRegistrationSpots?: Array<EventRegistrationSpot> | null;
  EventRegistrationCampsiteAssignments: Array<EventRegistrationCampsiteAssignment> | null;
}

export interface CacheFourEventsNumbersState extends CacheFourEventsNumbersCore {
  dispatchWarning?: string;
}

const updateCacheFourData = (rootState: ApplicationState, newState: CacheFourEventsNumbersState, data: CacheFourEventsNumbersCore, isSaving?: boolean, setDataFirst?: boolean): CacheFourEventsNumbersState => {
  if (setDataFirst) {
    if (data.EventRegistrationGroupClasses !== undefined) {
      newState.EventRegistrationGroupClasses = data.EventRegistrationGroupClasses;
    }

    if (data.EventRegistrationCampsiteRankingOptions !== undefined) {
      newState.EventRegistrationCampsiteRankingOptions = data.EventRegistrationCampsiteRankingOptions;
    }

    if (data.EventClassesGroupAvailable !== undefined) {
      newState.EventClassesGroupAvailable = data.EventClassesGroupAvailable;
    }

    if (data.EventClassesGroupRegistered !== undefined) {
      newState.EventClassesGroupRegistered = data.EventClassesGroupRegistered;
    }
  }
  if (data.EventRegistrationPaymentStatus) {
    newState.EventRegistrationPaymentStatus = convertObjToMoment(data.EventRegistrationPaymentStatus, ['DepositDueDate']);
  }
  if (data.EventRegistrationNumbers) {
    newState.EventRegistrationNumbers = convertObjToMoment(data.EventRegistrationNumbers, ['DateRegistration']);
  }
  if (data.EventRegistrationParticipantTypes !== undefined) {
    newState.EventRegistrationParticipantTypes = data.EventRegistrationParticipantTypes;
  }
  if (data.EventRegistrationGroupClasses !== undefined) {
    newState.EventRegistrationGroupClasses = data.EventRegistrationGroupClasses;
  }
  if (data.EventProductsOrdered !== undefined) {
    newState.EventProductsOrdered = convertToMoment(data.EventProductsOrdered, ['DateOrdered']);
  }
  if (data.EventProductsOrdered_Updates !== undefined) {
    newState.EventProductsOrdered_Updates = data.EventProductsOrdered_Updates;
  }
  if (data.EventRegistrationSpots !== undefined) {
    const newSpots = data.EventRegistrationSpots ? [...data.EventRegistrationSpots] : [];
    let SpotID = 0;
    newState.EventRegistrationSpots = newSpots.map((spot) => {
      SpotID = getSpotTempId(rootState, data, SpotID);

      return {
        ...spot,
        SpotID,
      };
    }).sort((a, b) => {
      return a.Amount > b.Amount ? -1 : 1;
    });
  }
  if (data.EventProductsAvailable !== undefined) {
    let availableProducts = [];
    if (data.EventProductsAvailable) {
      data.EventProductsAvailable.forEach((avail) => {
        (availableProducts as any).push({
          ...avail,
          Quantity: 1
        });
      });
    }
    newState.EventProductsAvailable = availableProducts;
  }

  if (data.EventRegistrationCampsiteAssignments !== undefined) {
    let tempID = 0;
    newState.EventRegistrationCampsiteAssignments = !data.EventRegistrationCampsiteAssignments ? [] : data.EventRegistrationCampsiteAssignments.map((assignment) => {
      tempID = getCampsiteTempId(rootState, data, tempID);

      return {
        ...assignment,
        tempID,
      };
    });
  }

  if (data.EventRegistrationGroupClasses && newState.EventClassesGroupRegistered) {
    newState.EventClassesGroupRegistered = [...newState.EventClassesGroupRegistered];
    data.EventRegistrationGroupClasses.forEach((c: EventRegistrationGroupClass) => {
      const index = newState.EventClassesGroupRegistered.findIndex((cr: RegisteredClass) => {
        return cr.ClassIDi === c.ClassIDi && !!cr.Inactive === !!c.Inactive;
      });
      if (index > -1) {
        if (isSaving) {
          newState.EventClassesGroupRegistered[index] = {
            ...newState.EventClassesGroupRegistered[index],
            AmountChangeInCart: c.AmountChangeInCart,
            NumSpotsAvailable: c.NumSpotsAvailable
          };
        } else {
          newState.EventClassesGroupRegistered[index] = {
            ...newState.EventClassesGroupRegistered[index],
            AmountChangeInCart: c.AmountChangeInCart
          };
        }
      }
    });
  }
  return {...newState};
};

const getInitialState = () => ({
  EventClassesGroupAvailable: [],
  EventClassesGroupRegistered: [],
  EventProductsAvailable: [],
  EventProductsOrdered: []
});

const CacheFourEventsNumbers: Reducer<CacheFourEventsNumbersState> = (state: CacheFourEventsNumbersState, act: WithRootState<AnyAction>) => {
  if (act.type === EventNumbersCalc.successType) { // initial load
    const action = <WithRootState<APISuccess>> act;
    const data = action.response.response;
    const newState = updateCacheFourData(act.rootState, {...state}, data, undefined, true);
    if (!newState.EventClassesGroupAvailable) newState.EventClassesGroupAvailable = [];
    if (!newState.EventClassesGroupRegistered) newState.EventClassesGroupRegistered = [];
    // set conflictStatus
    const availableClasses: Array<EventClassGroupAvailable> = [];
    newState.EventClassesGroupAvailable.forEach((ca: EventClassGroupAvailable) => {
      const rootState = action.rootState;
      const {conflictStatus} = determineConflict(
        ca,
        newState.EventClassesGroupRegistered,
        !rootState.cacheTwoEvents.EventTypeRegistrationSettings ? false : rootState.cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.RequireUniqueClassType
      );
      availableClasses.push({...ca, conflictStatus});
    });
    newState.EventClassesGroupAvailable = availableClasses;
    return newState;
  } else if (
    act.type === ApiSubmitActionsNumbersSpots.successType || act.type === ApiSubmitActionsNumbersClasses.successType || act.type === ApiSubmitActionsNumbersCampsite.successType ||
    act.type === ApiSubmitActionsNumbersProducts.successType
  ) {
    const action = <WithRootState<APISuccess>> act;
    return updateCacheFourData(act.rootState, {...state}, action.response.response);
  } else if (act.type === EventNumbersSave.failureType) {
    const action = <WithRootState<APIFailure>> act;
    if (action.response.status === 400 && action.response.xhr.response.error.Detail === "Canceled") {
      return updateCacheFourData(act.rootState, {...state}, action.response.response, true);
    }
    return state;
  } else if (isActionType(act, EventRegisterNumbersClassesAdd)) {
    const newState = {...state};
    newState.EventClassesGroupAvailable = [...state.EventClassesGroupAvailable];
    newState.EventClassesGroupRegistered = [...state.EventClassesGroupRegistered];
    const addingIndex = state.EventClassesGroupAvailable.findIndex((c: EventClassGroupAvailable) => c.IDi === act.clazz.IDi);
    if (addingIndex === -1) {
      captureTentarooError(new Error(`Could not add class with IDi ${act.clazz.IDi}`));
      return state;
    }
    const addingClass = {...state.EventClassesGroupAvailable[addingIndex]};
    const addingClasses: Array<EventClassGroupAvailable> = [{...addingClass}];
    const addingIndexes: Array<number> = [addingIndex];
    if (addingClass.ParentIDi !== 0) {
      state.EventClassesGroupAvailable.forEach((avail: EventClassGroupAvailable, index: number) => {
        if (addingClass.IDi !== avail.IDi && addingClass.ParentIDi === avail.ParentIDi) {
          addingClasses.push(avail);
          addingIndexes.push(index);
        }
      });
    }

    const rootState = act.rootState;
    // Check pre added state to see if there's conflicts that need to be removed
    const {conflicts} = determineConflict(
      addingClass,
      state.EventClassesGroupRegistered,
      !rootState.cacheTwoEvents.EventTypeRegistrationSettings ? false : rootState.cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.RequireUniqueClassType,
      true,
    );
    let doesConflictAdmin = false;
    conflicts.forEach((index: number) => {
      if (!newState.EventClassesGroupRegistered[index].AllowSelfRegistration) doesConflictAdmin = true;
      newState.EventClassesGroupRegistered[index] = {...newState.EventClassesGroupRegistered[index], Inactive: true};
    });

    if (!rootState.user.user.str_permissions.hasAdminAccess && doesConflictAdmin) {
      // todo: this logic needs to be refactored into action to do this properly...
      // dispatchWarning should be removed completely
      return {
        ...state,
        dispatchWarning: 'This class cannot be added since it conflicts with an admin-only class that cannot be removed.'
      };
    }

    addingClasses.forEach((adding: EventClassGroupAvailable) => {
      const index = state.EventClassesGroupRegistered.findIndex((r: RegisteredClass) => r.ClassIDi === adding.IDi);
      let newClass: any;
      if (index > -1) {
        newClass = {...state.EventClassesGroupRegistered[index], Inactive: false};
        newState.EventClassesGroupRegistered[index] = newClass;
      } else {
        newClass = {
          ...adding,
          IsUnscheduled: false,
          IDi: 0,
          OldIDi: 0,
          ClassIDi: adding.IDi,
          AmountPaid: 0.00,
          NumOverride: 0, // was '0' in old app
          Inactive: false,
          AmountChangeInCart: 0.00, // will get from server
          AmountInCart: 0.00,
          InCart: 1
        };
        newState.EventClassesGroupRegistered.push(newClass);
      }
    });

    // now check each available class to see if it conflicts
    state.EventClassesGroupAvailable.forEach((avail: EventClassGroupAvailable, index: number) => {
      const {conflictStatus} = determineConflict(
        avail,
        newState.EventClassesGroupRegistered,
        !rootState.cacheTwoEvents.EventTypeRegistrationSettings ? false : rootState.cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.RequireUniqueClassType,
      );

      newState.EventClassesGroupAvailable[index] = {...state.EventClassesGroupAvailable[index], conflictStatus};
    });

    window.scrollTo(0, 0);
    newState.EventClassesGroupRegistered.sort(classSort);
    return newState;
  } else if (isActionType(act, EventRegisterNumbersClassesRemove)) {
    const newState = {...state};
    // @todo: might as well pass in whole class on remove
    newState.EventClassesGroupAvailable = [...state.EventClassesGroupAvailable];
    newState.EventClassesGroupRegistered = [...state.EventClassesGroupRegistered];
    const removingIndex = state.EventClassesGroupRegistered.findIndex((c: RegisteredClass) => c.ClassIDi === act.clazz.ClassIDi && !c.Inactive);
    if (removingIndex === -1) {
      // console.log(`Cannot remove class with idi ${act.clazz.ClassIDi}`);
      return state;
    }
    const removingIndexes: Array<number> = [removingIndex];
    const removingParentIDi = state.EventClassesGroupRegistered[removingIndex].ParentIDi;
    if (removingParentIDi !== 0) {
      state.EventClassesGroupRegistered.forEach((c: RegisteredClass, index: number) => {
        if (c.ParentIDi === removingParentIDi && index !== removingIndex) removingIndexes.push(index);
      });
    }

    removingIndexes.forEach((index: number) => {
      newState.EventClassesGroupRegistered[index] = {...newState.EventClassesGroupRegistered[index], Inactive: true};
    });

    const rootState = act.rootState;
    state.EventClassesGroupAvailable.forEach((avail: EventClassGroupAvailable, index: number) => {
      const {conflictStatus} = determineConflict(
        avail,
        newState.EventClassesGroupRegistered,
        !rootState.cacheTwoEvents.EventTypeRegistrationSettings ? false : rootState.cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.RequireUniqueClassType,
      );
      newState.EventClassesGroupAvailable[index] = {...state.EventClassesGroupAvailable[index], conflictStatus};
    });

    window.scrollTo(0, 0);
    return newState;
  } else if (isActionType(act, ClassOverrideFeeSave)) {
    if (state.EventClassesGroupRegistered.length === 0) return state;
    const newState = {...state};
    newState.EventClassesGroupRegistered = [...state.EventClassesGroupRegistered];
    const updatingIndex = state.EventClassesGroupRegistered.findIndex((c: RegisteredClass) => c.ClassIDi === act.c.ClassIDi && !c.Inactive);
    if (updatingIndex === -1) {
      // console.log(`Cannot override class with ClassIDi ${act.c.ClassIDi}`);
      return state;
    }
    newState.EventClassesGroupRegistered[updatingIndex] = {...state.EventClassesGroupRegistered[updatingIndex]};
    newState.EventClassesGroupRegistered[updatingIndex].NumOverride = act.NumOverride;
    newState.EventClassesGroupRegistered[updatingIndex].Amount = act.Amount;
    if (act.Amount < 0) {
      newState.EventClassesGroupRegistered[updatingIndex].AmountMin = act.Amount;
    } else {
      newState.EventClassesGroupRegistered[updatingIndex].AmountMin = act.AmountMin ?? 0;
    }
    return newState;
  } else if (isActionType(act, EventRegisterNumbersProductAdd)) {
    return addProductToState(state, AVAILABLE_PRODUCT_KEY, ADDED_PRODUCT_KEY, act);
  } else if (isActionType(act, EventRegisterNumbersProductRemove)) {
    return removeProductToState(state, AVAILABLE_PRODUCT_KEY, ADDED_PRODUCT_KEY, act);
  } else if (isActionType(act, SetNumbersNotAdded)) {
    return updateAvailableProductToState(state, AVAILABLE_PRODUCT_KEY, act);
  } else if (isActionType(act, SetNumbersAdded)) {
    return updateAddedProductToState(state, ADDED_PRODUCT_KEY, act);
  } else if (isActionType(act, ManageProductNumbers)) {
    return manageAddedProductToState(state, ADDED_PRODUCT_KEY, act);
  } else if (isActionType(act, CommitProductNumbers)) {
    return commitAddedProductToState(state, ADDED_PRODUCT_KEY, act);
  } else if (isActionType(act, CancelProductNumbers)) {
    return cancelAddedProductToState(state, ADDED_PRODUCT_KEY, act);
  } else if (isActionType(act, ResetManagingProductsNumbers)) {
    return resetManagingProductsToState(state, ADDED_PRODUCT_KEY);
  } else if (isActionType(act, ClearWarning)) {
    return {...state, dispatchWarning: undefined};
  } else if (isActionType(act, ClearAllCache) || isActionType(act, ClearAllEndUserCacheButOptions) ||
    isActionType(act, ClearCacheBelowOne) || isActionType(act, ClearCacheBelowTwoEvents) ||
    isActionType(act, ClearCacheBelowThreeEvents)
  ) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default CacheFourEventsNumbers;
