import { ActionCreator, ApplicationState } from "../../../..";
import {createApiValidateActions, createValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod} from '../../../../Validation/actionCreator';
import { typeName, Action } from "../../../../../utils/StrongActions";
import {SaveState} from "../../../../Rollback/actions";

export const SUFFIX = '__CMS_RESOURCE_MODAL';

@typeName("FILTER" + SUFFIX)
export class ResourcesModalFilter extends Action {
  constructor(public filterString: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class ResourcesToggleFilterTabs extends Action {
  constructor(public searching: boolean) { super(); }
}

@typeName("RESET" + SUFFIX) export class ResetResourcesModal extends Action {}

@typeName("INIT" + SUFFIX) export class InitResourcesModal extends Action {}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export type Actions = typeof actionCreators;

export const actionCreators = {
  resourceModalUpdateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.resources.modals.resource),
  resourceModalSimpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  filterResources: (filterString: string): ActionCreator => dispatch => dispatch(new ResourcesModalFilter(filterString)),
  init: (): ActionCreator => (dispatch, getState) => {
    dispatch(new InitResourcesModal());
    dispatch(new SaveState());
  },
  reset: (): ActionCreator => dispatch => dispatch(new ResetResourcesModal()),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new ResourcesToggleFilterTabs(searching)),
};