import { createSelector } from "reselect";

import type { ApplicationState } from "../../..";
import { AdminFacility, AdminFacilityType } from "../../../../models/api/adminFacilitiesCacheOne";
import { reduxStoreService } from "../../../service";
import { listSelector } from "../../../../utils/reselectHelper";
import { getFacilityTypesMap } from "../../../../utils/helpers/adminFacilityLocationPageHelper";


const facilityTypeShowDeletedSelector = (state: ApplicationState) => !!state.adminFacilityLocation.facilities.home.ActiveForm.FacilityTypeShowDeleted;

const facilityTypeFilterTextSelector = (state: ApplicationState) => state.adminFacilityLocation.facilities.home.ActiveForm.FacilityTypeFilterText || '';

export const facilityTypesSelector = (state: ApplicationState) => state.adminFacilityLocation.cacheOne.FacilitiesTypes;

export const makeActiveFacilityTypesSelector = () => {
  return createSelector(
    [facilityTypesSelector],
    (facilityTypes: AdminFacilityType[]) => {
      if (facilityTypes) {
        return facilityTypes.filter((ft) => !ft.Inactive);
      }
      return [];
    }
  );
};

export const sortByNamePlural = (a: { NamePlural: string}, b: { NamePlural: string} ) => {
  return a.NamePlural.toLowerCase().localeCompare(b.NamePlural.toLowerCase());
};
const sortByName = (a: { Name: string }, b: { Name: string }) => {
  return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase());
};

/**
 * NOTE: Dont use this function in reducers
 */
export const sortFacilities = (facilities: AdminFacility[], sortByFacilityType?: boolean) => {
  const state = reduxStoreService().getState();
  return [...facilities].sort((a, b) => {
    // if needed to sort by facility type, sort by facility type's name
    if (sortByFacilityType) {
      const facilityTypeA = state.adminFacilityLocation.cacheOne.FacilitiesTypes ? state.adminFacilityLocation.cacheOne.FacilitiesTypes.find((ft) => ft.ID === a.FacilityTypeID) : null;
      const facilityTypeB = state.adminFacilityLocation.cacheOne.FacilitiesTypes ? state.adminFacilityLocation.cacheOne.FacilitiesTypes.find((ft) => ft.ID === b.FacilityTypeID) : null;

      if (facilityTypeA && facilityTypeB) {
        const facilityTypeCompare = sortByNamePlural(facilityTypeA, facilityTypeB);
        if (facilityTypeCompare !== 0) return facilityTypeCompare;
      }
    }

    // if facility type sort results in a TIE, sort by facility's name
    const compare = sortByName(a, b);
    if (compare !== 0 ) {
      return compare;
    } else {
      // if all TIE, sort by facility's ID
      return a.ID < b.ID ? -1 : 1;
    }
  });
};

export const makeFilteredFacilityTypesSelector = () => {
  return createSelector(
    [facilityTypesSelector, facilityTypeShowDeletedSelector, facilityTypeFilterTextSelector],
    (facilityTypes: AdminFacilityType[], showDeleted: boolean, filterText: string) => {
      if (facilityTypes) {
        const result: any[] = facilityTypes.filter((ft) => {
          let passed = false;

          // filter based on deleted
          if (showDeleted || !ft.Inactive) {
            if (!filterText) {
              passed = true;
            } else {
              const f = filterText.toLowerCase();
              if (ft.Name.toLowerCase().includes(f) || ft.NamePlural.toLowerCase().includes(f)) {
                passed = true;
              }
            }
          }
          return passed;
        });
        return result.sort(sortByNamePlural);
      }

      return [];
    }
  );
};

const facilitiesShowDeletedSelector = (state: ApplicationState) => !!state.adminFacilityLocation.facilities.home.ActiveForm.ShowDeleted;
const facilitiesFilterTextSelector = (state: ApplicationState) => state.adminFacilityLocation.facilities.home.ActiveForm.FilterText;
const selectedFacilityTypeSelector = (state: ApplicationState) => state.adminFacilityLocation.facilities.home.ActiveForm.SelectedFacilityTypeID;
export const facilitiesSelector = listSelector(
  (state: ApplicationState) => state.adminFacilityLocation.cacheOne.Facilities,
);

export const makeFilteredFacilitiesSelector = () => {
  return createSelector(
    [facilitiesSelector, selectedFacilityTypeSelector, facilitiesShowDeletedSelector, facilitiesFilterTextSelector, facilityTypesSelector],
    (facilities: AdminFacility[], selectedFacilityTypeID: number, showDeleted: boolean, filterText: string, facilityTypes: AdminFacilityType[]) => {
      if (selectedFacilityTypeID !== 0 && facilities) {
        const result: AdminFacility[] = facilities.filter(
          (facility) => {
            let valid = false;
            // filter deleted
            if (facility.FacilityTypeID === selectedFacilityTypeID && (showDeleted || !facility.Inactive)) {
              // filter by filter text if true
              if (!filterText) {
                return valid = true;
              } else {
                const f = filterText.toLowerCase();

                if (facility.Name.toLowerCase().includes(f)) valid = true;
              }
            }

            return valid;
          }
        );

        return sortFacilities(result, true);
      }

      return [];
    }
  );
};

export const makeActiveFacilitiesSelector = () => {
  return createSelector(
    [facilitiesSelector, facilityTypesSelector],
    (facilities: AdminFacility[], facilityTypes: AdminFacilityType[]) => {
      if (facilities) {
        return facilities.filter((f) => {
          const facilityType = facilityTypes.find((ft) => ft.ID === f.FacilityTypeID);
          return !f.Inactive && facilityType && !facilityType.Inactive;
        });
      }
      return [];
    }
  );
};
export const makeGroupedFacilitiesSelector = () => {
  return createSelector(
    [facilitiesShowDeletedSelector, facilitiesFilterTextSelector, facilityTypesSelector, facilitiesSelector],
    (showDeleted: boolean, filterText: string, facilityTypes: AdminFacilityType[], facilities: AdminFacility[]) => {
      const groupedFacilities: {[key: string]: AdminFacility[]} = {};
      const facilityTypeMap = getFacilityTypesMap(facilityTypes);

      if (facilities) {
        // we sort the facility by facility type first, so that the groupe would be ordered by facility type's name
        const sortedByFacilityType = sortFacilities(facilities, true);
        sortedByFacilityType.forEach((facility) => {
          if (facility.FacilityTypeID && facilityTypeMap[facility.FacilityTypeID] && !facilityTypeMap[facility.FacilityTypeID].Inactive) {
            const key = `${facilityTypeMap[facility.FacilityTypeID].NamePlural}${facility.FacilityTypeID.toString()}`;
            if (!groupedFacilities[key]) {
              groupedFacilities[key] = [];
            }

            // filter deleted
            if (showDeleted || !facility.Inactive) {
              // filter by filterText if set
              if (!filterText) {
                groupedFacilities[key].push(facility);
              } else {
                const f = filterText.toLowerCase();

                if (facility.Name.toLowerCase().includes(f)) {
                  groupedFacilities[key].push(facility);
                }
              }
            }
          }
        });
      }

      for (const key of Object.keys(groupedFacilities)) {
        // we dont need to sort by facility type here.
        groupedFacilities[key] = sortFacilities(groupedFacilities[key]);
        const facilityType = facilityTypes && facilityTypes.find((ft) => groupedFacilities[key].length > 0 && ft.ID === groupedFacilities[key][0].FacilityTypeID);
        if (facilityType && groupedFacilities[key].length > 0) {
          groupedFacilities[key].unshift(facilityType as any);
        }
      }

      let result: any[] = [];
      for (const key of Object.keys(groupedFacilities)) {
        result = result.concat(groupedFacilities[key]);
      }
      return result;
    }
  );
};