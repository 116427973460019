import {replace} from "react-router-redux";
import { AdminFacilityLocationCacheOneContext } from "@tentaroo/shared";

import {getFacilitiesHomeRootUrl} from "../../constants/urls";
import {SaveState} from "../../store/Rollback/actions";
import { extractRouteParams, isIdFromUrlValid } from "../urlHelper";
import { GetFacilityLocationCache } from "../../store/AdminFacilityLocation/CacheOne/actions";
import { navPush } from "../navHelper";
import { getFacilityLocationId } from "../helpers/adminFacilityLocationPageHelper";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import type AdminFacilitiesLocationCacheManager from "../cacheManagers/adminFacilitiesLocationCacheManager";
import { reduxStoreService } from "../../store/service";
import {ToggleNotFound} from "../../store/App/actions";
import { LoadAdminFacilityCacheOneParams } from "./helpers/models";
import {isAdminFacilitiesLocationCacheOnePopulated} from "../cachePopulatedCheckers/adminFacilities";

export const ensureAdminFacilityLocationCacheOne = (
  params: LoadAdminFacilityCacheOneParams & {cacheManager: AdminFacilitiesLocationCacheManager},
): boolean => {
  const {props, context, cacheManager, isStateNavigated, isGetAvailabilities} = params;
  const {location, router} = props;
  if (!isIdFromUrlValid(props.params.locationId)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }
  
  const state = reduxStoreService().getState();
  const adminFacilityLocationCacheOne = state.adminFacilityLocation.cacheOne;
  const locationId = getFacilityLocationId(props);

  // redirect to Facilities when
  // - facility location cache one is not loaded AND we are in Availabilities page - meaning a direct visit to Availabilities OR navigated from a different module (i.e. CMS, EndUser, Events, etc.)
  // - facility location cache one is loaded, but id doesnt match the one in url - meaning we are navigating from a different facility location
  if (!adminFacilityLocationCacheOne.Facilities && isGetAvailabilities && props.params.locationName) {
    if (state.cacheZero.options) {
      navPush(router, getFacilitiesHomeRootUrl({locationId, locationName: props.params.locationName}));
    }
    else {
      reduxStoreService().dispatch(replace({
        pathname: getFacilitiesHomeRootUrl({locationId, locationName: props.params.locationName}),
        query: location.query
      }));
    }
    return false;
  }

  // Initializing Reports page OR list filters DONOT require cache one to be loaded, so we can put it here
  if (isStateNavigated && context) {
    cacheManager.initCacheOneListPage(context);
  }

  let requestedData = false;
  if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminFacilitiesLocationCacheOnePopulated()) {
    // Initializing Settings page DOES require cache one to be loaded, so we can put it here
    // These inits will happen when navigating to cache one form without any load,
    // and we shouldn't skip form update in this case
    if(isStateNavigated) {
      if (context === AdminFacilityLocationCacheOneContext.SETTINGS) {
        reduxStoreService().dispatch(GetFacilityLocationCache.updateForm());
      }
      reduxStoreService().dispatch(new SaveState());
    }
  } else {
    // @todo - comment the following out when implementing direct visit to Availabilities page
    /*
    if (isGetAvailabilities && !apiLoadingMap[GetAvailabilitiesCache.requestType]) {
      props.actions.getAvailabilitiesCache({
        ...extractRouteParams(props),
      }, {location: props.location, routes: props.routes, params: props.params});
    } else {
      props.actions.getFacilityLocationCache({
        ...extractRouteParams(props),
      });
    }
    */
    // After this load request is completed, a `updateForm` for cache one form will be dispatched,
    // we set `skipUpdateForm` to true when we are not in the form pages, to skip updating the form
    reduxStoreService().dispatch(GetFacilityLocationCache.request(
      {...extractRouteParams(props)},
      {
        skipUpdateForm: (
          context !== AdminFacilityLocationCacheOneContext.SETTINGS
        )
      },
    ));
    requestedData = true;
  }

  return requestedData;
};