import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../../styles/pages/events/event/prices.scss';

export const namespace = (): string => `${ns()}--prices`;

export interface PricesProps {
  children?: React.ReactNode;
}

const Prices: React.SFC<PricesProps> = (props: PricesProps): React.ReactElement<PricesProps> => (
  <ul className={namespace()}>
    {props.children}
  </ul>
);

export default Prices;
