import { ActionCreator, ApplicationState } from "../../../..";
import { createValidateActions, createApiValidateActions, createUpdateValueMethod, createApiSubmitActions, innerApiSubmitFormMethod } from "../../../../Validation/actionCreator";
import { ParticipantType } from "../../../../../models/api/adminEventsCacheOne";
import { actionCreators as appActionCreators, ShowTopFloatingAlert} from "../../../../App/actions";
import { actionCreators as eventTypeActionCreators} from "../../../../AdminEvents/EventTypes/EventType/Form/actions";
import { ModalTypes } from "../../../../../utils/modalHelper";
import { typeName, Action } from "../../../../../utils/StrongActions";
import {SaveState} from "../../../../Rollback/actions";

export const SUFFIX = '__REPLACE_PARTICIPANT_TYPE';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

@typeName("INIT" + SUFFIX) export class InitReplaceParticipantTypeModal extends Action {}
@typeName("RESET" + SUFFIX) export class ResetReplaceParticipantTypeModal extends Action {}

export const ReplaceParticipantAction = createApiSubmitActions(SUFFIX);

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.modals.selectEventTypes),
  initReplaceParticipantTypeModal: (): ActionCreator => (dispatch, getState) => {
    dispatch(new InitReplaceParticipantTypeModal());
    dispatch(new SaveState());
  },
  resetReplaceParticipantTypeModal: (): ActionCreator => dispatch => dispatch(new ResetReplaceParticipantTypeModal()),
  submit: (participantType: ParticipantType, index: number): ActionCreator => (dispatch, getState) => {
    const valid = innerApiSubmitFormMethod(
      dispatch,
      ReplaceParticipantAction,
      (s: ApplicationState) => s.adminEvents.eventTypes.modals.replaceParticipantType,
      undefined,
      undefined,
      true,
      true,
    );

    if (valid) {
      // We are not doing any request here, so we reset the modal, remove participantType,
      // and then save before closing
      const state = getState() as ApplicationState;
      dispatch(new ResetReplaceParticipantTypeModal());
      dispatch(eventTypeActionCreators.removeParticipantType(participantType, index, state.adminEvents.eventTypes.modals.replaceParticipantType.ActiveForm.ParticipantTypeID || 0) as any);
      dispatch(appActionCreators.popModal(false, true, ModalTypes.REPLACE_PARTICIPANT_TYPE) as any);
    } else {
      // Only one field in this form, so only that field could be invalid.
      dispatch(new ShowTopFloatingAlert('Replacement Type is required.', true, 'orange'));
    }
  },
};