import * as React from 'react';
import { Modal, ModalTitle, Icon } from '../../Elements';
import {WithInertAttribute} from '../../Elements/WithInert';

type Props = WithInertAttribute<{
  children?: React.ReactNode;
  icon?: React.ReactType;
  title?: string;
  onClose?: Function;
  titleAlignLeft?: boolean;
}>;

class ConfirmationModal extends React.Component<Props, {}> {
  componentWillUnmount() {
    this.props.onClose && this.props.onClose();
  }

  render() {
    const { onClose, icon, title, children, titleAlignLeft, inert } = this.props;
    return (
      <Modal inert={inert} disableDynamicHeight big onClose={onClose}>
        <ModalTitle marginBottom={8} center={!titleAlignLeft}>
          {icon && <Icon icon={icon}/>}
          <span>{title}</span>
        </ModalTitle>
        {children}
      </Modal>
    );
  }
}

export default ConfirmationModal;
export { default as ConfirmationModalContent } from './Content';
export { default as ConfirmationModalActions } from './Actions';
