import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators } from 'redux';
import { CacheThreeFacilitiesContext } from "@tentaroo/shared";

import { LoadingAll } from "../../../../../Elements";
import Trip from "../";
import {
  actionCreators as cacheZeroActionCreators,
} from "../../../../../../store/CacheZero/actions";
import {
  actionCreators as cacheTwoFacilitiesActionCreators,
  ClearCacheBelowTwoFacilities
} from "../../../../../../store/CacheTwoFacilities/actions";
import {
  actionCreators as cacheOneActionCreators,
} from "../../../../../../store/CacheOne/actions";
import {
  actionCreators as cacheThreeFacilitiesActionCreators,
} from "../../../../../../store/CacheThreeFacilities/actions";
import { makeSelectedLocationSelector } from "../../../../../../store/CacheTwoFacilities";
import { actionCreators } from "../../../../../../store/Facilities/Trip/Form/actions";
import {
  actionCreators as rollbackActionCreators,
  SaveState
} from "../../../../../../store/Rollback/actions";
import { shouldReconfigRouter } from '../../../../../../utils/cacheLoaders/reloaderHelper';
import { ApplicationState } from '../../../../../../store';
import EndUserCacheManager from '../../../../../../utils/cacheManagers/endUserCacheManager';
import { isPathUnderEndUserFacilityCacheThree } from '../../../../../../utils/helpers/endUserPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import { checkPermission } from '../../../../../../utils/permissionHelper';
import {isEndUserCacheOnePopulated} from '../../../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, { id: string; tripId: string; locId: string; name: string }>
>;

@(withRouter as any)
class AddTrip extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
  private nextLocation;

  public componentDidMount() {
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeFacilities({
          props: this.props,
          isEdit: false,
          isStateNavigated,
          context: CacheThreeFacilitiesContext.TRIP_FORM,
        });
      }
    );

    this.configRouteLeaveHook();
  }

  componentWillReceiveProps(nextProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeFacilities({
          props: nextProps,
          isEdit: false,
          isStateNavigated,
          context: CacheThreeFacilitiesContext.TRIP_FORM,
        });
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouteLeaveHook();
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;

    if (reduxStoreService().getState().app.apiLoading === 0) {
      reduxStoreService().dispatch(new SaveState());
    }
  };

  componentWillUnmount() {
    // if we are not navigating to cache below cache three facility, clear cache
    if (
      this.nextLocation &&
      !isPathUnderEndUserFacilityCacheThree(this.nextLocation.pathname)
    ) {
        reduxStoreService().dispatch(new ClearCacheBelowTwoFacilities());
    }
    this.resetRouteLeaveHook();
  }

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  onSave = () => {
    const {cacheTwoFacilities: { FacilityLocationPermissions }, actions} = this.props;

    if (!FacilityLocationPermissions) return;

    checkPermission(
      () => {
        actions.apiSubmitForm();
      },
      FacilityLocationPermissions.hasAllowNewTrip,
      FacilityLocationPermissions.hasAllowNewTripReason,
    );
  };

  public render() {
    const {
      inert,
      actions, tripForm: { ActiveForm, ValidationRules, SubmitErrorMessage }, apiSaving, cacheZero, user,
      cacheTwoFacilities: { FacilityLocationPermissions }, cacheOne
    } = this.props;

    if (!isEndUserCacheOnePopulated(cacheOne) || !FacilityLocationPermissions || !cacheZero.options?.Group) return <LoadingAll />;
    const { hasAllowNewTrip, hasAllowNewTripReason } = FacilityLocationPermissions;
    return (
      <Trip
        inert={inert}
        ActiveForm={ActiveForm}
        ValidationRules={ValidationRules}
        SubmitErrorMessage={SubmitErrorMessage}
        reduxActions={actions}
        isSaving={apiSaving > 0}
        action="add"
        onSave={this.onSave}
        loading={apiSaving > 0}
        group={cacheZero.options.Group}
        isAdmin={!!(user && user.user.str_permissions.hasAdminAccess)}
        hasAccess={hasAllowNewTrip}
        hasAccessMessage={hasAllowNewTripReason}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedLocation = makeSelectedLocationSelector();
  return {
    tripForm: state.facilities.trip.form,
    user: state.user,
    SystemSettings: state.session.SystemSettings,
    facilityLocation: state.facilities.location,
    facilitiesLocations: state.facilities.locations,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheThreeFacilities: state.cacheThreeFacilities,
    cacheTwoFacilities: state.cacheTwoFacilities,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    selectedLocation: selectedLocation(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,

  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...rollbackActionCreators,
    ...actionCreators,
    ...cacheThreeFacilitiesActionCreators,
    ...cacheTwoFacilitiesActionCreators,
    ...cacheOneActionCreators,
    ...cacheZeroActionCreators
  }, dispatch)
});
const ConnectedAddTrip = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(AddTrip);

export default ConnectedAddTrip;
