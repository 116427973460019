import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { namespace as ns, actionNamespace } from './constants';
import { ActionButton } from '../../../Elements';
import { CartIcon } from '../../../Icons';
import { whichAnimationEvent } from "../../../../utils";
import '../../../../styles/app/header/actions/cart.scss';
import { generateDOMId } from '../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { getMergeProps } from '../../../../utils/reduxHelper';
import {Nullable} from '../../../../utils/dataHelper';

export const namespace = (): string => `${ns()}--cart`;

type ConnectedProps = ReturnType<typeof mapStateToProps>;

interface Props {
  onClickCart: () => void;
}

interface State {
  animateEmpty: boolean;
  animateMore: boolean;
  animateNew: boolean;
  empty?: boolean | null;
};

class Cart extends React.Component<Props, State> {
  public props: Props & ConnectedProps;
  public state: State = {
    animateEmpty: false,
    animateMore: false,
    animateNew: false,
    empty: null
  };

  public constructor(props: Props & ConnectedProps) {
    super(props);
    if (!props.cacheOne.CartOrderItems || props.cacheOne.CartOrderItems.length === 0) {
      this.state.empty = true;
    }
  }

  public componentWillReceiveProps(nextProps: Props & ConnectedProps) {
    if (nextProps.cacheOne.CartOrderItems && this.props.cacheOne.CartOrderItems) {
      if (nextProps.cacheOne.CartOrderItems.length !== this.props.cacheOne.CartOrderItems.length) {
        if (nextProps.cacheOne.CartOrderItems.length === 0) {
          this.animateEmpty();
        } else if (this.props.cacheOne.CartOrderItems.length === 0) {
          this.animateEmpty();
        } else {
          this.animateMore();
        }
      }
    } else if (!this.props.cacheOne.CartOrderItems && nextProps.cacheOne.CartOrderItems) {
      // this case animation might be wrong, the cart should animate first and then the icon
      this.animateNew();
    } else if (this.props.cacheOne.CartOrderItems && !nextProps.cacheOne.CartOrderItems) {
      this.animateEmpty();
    }
  }

  public animateEmpty = () => {
    const node = findDOMNode(this) as Nullable<Element>;
    const element = node ? node.getElementsByTagName('span')[0] : null;
    const handleAnimationEnd = () => {
      const whichAni = whichAnimationEvent();
      if (whichAni) element && element.removeEventListener(whichAni, handleAnimationEnd);
      this.setState({ animateEmpty: false, empty: true });
    };
    const whichAni = whichAnimationEvent();
    if (whichAni) element && element.addEventListener(whichAni, handleAnimationEnd);
    this.setState({ animateEmpty: true });
  };

  public animateMore = () => {
    const node = findDOMNode(this) as Nullable<Element>;
    const element = node ? node.getElementsByTagName('span')[0] : null;
    const handleAnimationEnd = () => {
      const whichAni = whichAnimationEvent();
      if (whichAni) element && element.removeEventListener(whichAni, handleAnimationEnd);
      this.setState({ animateMore: false });
    };
    const whichAni = whichAnimationEvent();
    if (whichAni) element && element.addEventListener(whichAni, handleAnimationEnd);
    this.setState({ animateMore: true, empty: false });
  };

  public animateNew = () => {
    const node = findDOMNode(this) as Nullable<Element>;
    const element = node ? node.getElementsByTagName('span')[0] : null;
    const handleAnimationEnd = () => {
      const whichAni = whichAnimationEvent();
      if (whichAni) element && element.removeEventListener(whichAni, handleAnimationEnd);
      this.setState({ animateNew: false, empty: false  });
    };
    const whichAni = whichAnimationEvent();
    if (whichAni) element && element.addEventListener(whichAni, handleAnimationEnd);
    this.setState({ animateNew: true });
  };

  public render() {
    const { cacheOne, onClickCart, apiSaving, apiLoading } = this.props;
    const { empty, animateEmpty, animateMore, animateNew } = this.state;
    const numItems = cacheOne.CartOrderItems ? cacheOne.CartOrderItems.length : 0;

    let imageClassName: string = `${namespace()}--image`;
    let numClassName: string = `${namespace()}--num`;
    if (animateEmpty) {
      imageClassName += ' animate-empty';
      numClassName += ' animate-empty';
    } else if (animateMore) {
      imageClassName += ' animate-more';
      numClassName += ' animate-more';
    } else if (animateNew) {
      imageClassName += ' animate-new';
      numClassName += ' animate-new';
    } else if (empty) {
      numClassName += ' empty';
    }
    return (
      <ActionButton id={generateDOMId("cart-btn")} className={`${actionNamespace()} ${namespace()}`} onClick={onClickCart}>
        <CartIcon className={imageClassName} width="20px" height="20px"/>
        <span className={numClassName}>{numItems}</span>
      </ActionButton>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    cacheOne: state.cacheOne,
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving
  };
};

const ConnectedCart = connect(
  mapStateToProps,
  null,
  getMergeProps<Props>(),
)(Cart);

export default ConnectedCart;
