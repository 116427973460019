import * as React from 'react';

import ActionButton from '../../ActionButton';
import Button from "../../Button";
import Media from "../../Media";

import '../../../../styles/elements/simple-list-element/index.scss';
import { OptionsIcon } from '../../../../components/Icons';
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--simple-list-element';

export interface SimpleListElementProps {
  key: string | number;
  label?: string;
  index: number;
  deleted?: boolean;
  onClick?: (index: number, key: string | number, itemValue: any | undefined) => any;
  onRestore?: (index: number, key: string | number, itemValue: any | undefined) => any;
  itemValue?: any;
  canRestore?: boolean;
  hasMenu?: boolean;
  contextMenu?: React.ReactElement<any>;
  id?: number;
}

type State = {
  contextMenuOpen: boolean;
};

class SimpleListElement extends React.Component<SimpleListElementProps, State> {
  public props: SimpleListElementProps;

  public state: State = {
    contextMenuOpen: false
  };
  clickElement = (e) => {
    if (e) e.stopPropagation();
    if (this.props.onClick) this.props.onClick(this.props.index, this.props.key, this.props.itemValue);
  };

  restoreElement = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.props.onRestore) this.props.onRestore(this.props.index, this.props.key, this.props.itemValue);
  };

  public render() {
    const {label, id, index, deleted, onRestore, canRestore, contextMenu} = this.props;
    return (
      <div id={generateDOMId(`simple-list-item${id || index}`)} key={`${index}`} className={`${namespace()}--list-elem ${deleted ? 'deleted' : 'not-deleted'}`} onClick={this.clickElement}>
        <Media notTouch>
          {label && <div className={`${namespace()}--list-elem--name ${deleted ? 'deleted' : ''}`}>{label}</div>}
        </Media>
        <Media touch>
          <div className={`${namespace()}--list-elem--name ${deleted ? 'deleted' : ''}`}>{label}</div>
        </Media>
        {deleted && onRestore && <Button className={`${namespace()}--list-elem--restore`} flat textColor="red" disabled={!canRestore} onClick={this.restoreElement}>RESTORE</Button>}
        {!deleted &&  contextMenu ? (
          <ActionButton
            icon={OptionsIcon}
            className={`${namespace()}--options`}
            contextMenu={React.cloneElement(contextMenu, {
              ...contextMenu.props,
              onOpen: () => this.setState({ contextMenuOpen: true }),
              onClose: () => this.setState({ contextMenuOpen: false })
            })}
          />
        ) : null}
      </div>
    );
  }
}

export default SimpleListElement;