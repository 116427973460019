import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import * as M from '../../../../../constants/messages/generic';
import { LinkTypeValue, SiteEventCategory } from "../../../../../models/api/adminCMSCacheOne";
import { makeSelectedEventCategorySelector } from "./selectors";
import { makeDeletedPrefixItemMapper } from "../../../../../utils/dataHelper";
import { sortByName } from "../../../../CacheZero";
import { SelectValidator, Validator } from "../../../../../utils/validator/models";
import type { ApplicationState } from "../../../..";

const getMenuLevelValues = () => {
  return [{ID: 1, Name: 'Level 1'}, {ID: 2, Name: "Level 2"}];
};
const getParentIDValues = (state: ApplicationState) => {
  if (!state.adminCMSSite.cacheOne.CMSSiteMenuItems) return [{ID: 0, Name: ''}];

  const menuItem = state.adminCMSSite.cacheTwoSiteMenuItems.CMSSiteMenuItem || state.adminCMSSite.cacheTwoPageMenuItems.CMSPageMenuItem;

  return state.adminCMSSite.cacheOne.CMSSiteMenuItems.filter((item) => {
    // filter logic:
    // 1. has no parent id - level 1 item
    // 2. is active OR, is inactive, but is parent of the currently opened menu
    return !item.ParentID && (!item.Inactive || (menuItem && menuItem.ParentID === item.ID && item.Inactive));
  })
  .map(makeDeletedPrefixItemMapper('Name'))
  .sort((a, b) => a.ID < 0 ? -1 : (b.ID < 0 ? 1 : 0));
};
const getLinkTypeIDValues = (state: ApplicationState) => {
  if (!state.adminCMSSite.cacheOne.LinkTypes) return [];

  return state.adminCMSSite.cacheOne.LinkTypes.map((l) => {
    return l.Name === 'Specific Resource' ? {...l, Name: 'Resource'} : l;
  });
};

export const getEventCategoryIDValues = (state: ApplicationState) => {
  if (!state.adminCMSSite.cacheOne.SiteEventCategories) return [];

  return state.adminCMSSite.cacheOne.SiteEventCategories;
};

export const getDistrictsValues = (state: ApplicationState) => {
  if (!state.cacheZero.options || !state.cacheZero.options.Districts) return [];
  const councilIDi = state.session.SystemSettings ? state.session.SystemSettings.CouncilIDi : null;

  if (!councilIDi) return state.cacheZero.options.Districts.sort(sortByName);
  return state.cacheZero.options.Districts.filter((d) => d.CouncilIDi === councilIDi).sort(sortByName);
};

export interface IValidator {
  IsLinkNewWindow?: Validator;
  PageID?: Validator;
  EventCategoryID?: SelectValidator;
  DistrictIDi?: SelectValidator;
  ResourceID?: Validator;
  LinkTypeID?: SelectValidator;
  ParentID?: SelectValidator;
  MenuLevel?: SelectValidator;
  ExternalURL?: Validator;
  Name?: Validator;
  Title?: Validator;
  MenuStyle?: Validator;
  IsAlignRight?: Validator;
  IsPrimaryMenu?: Validator;
}

export const FormDefinition: IValidator = {
  Name: {
    key: 'Name',
    isRequired: () => true,
    validatejs: {
      Name: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 200,
          tooLong: M.MAX_LENGTH(200),
        },
      },
    },
  },
  Title: {
    key: 'Title',
    isRequired: () => false,
    defaultValue: () => '',
  },
  MenuStyle: {
    key: 'MenuStyle',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      MenuStyle: {
        length: {
          maximum: 30,
          tooLong: M.MAX_LENGTH(30),
        },
      }
    }
  },
  MenuLevel: {
    key: 'MenuLevel',
    isRequired: () => true,
    localDependants: ['ParentID', 'LinkTypeID'],
    options: {
      values: () => [...getMenuLevelValues()],
      valueKey: (v) => v.ID,
      labelKey: 'Name'
    },
    defaultValue: () => 1,
    validatejs: {
      MenuLevel: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          greaterThan: 0,
          notGreaterThan: SELECT_NOT_GREATER_THAN,
        }
      }
    }
  },
  ParentID: {
    key: 'ParentID',
    isRequired: (state) => {
      return !!state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.MenuLevel && state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.MenuLevel === 2;
    },
    options: {
      values: (rootState) => [{ID: 0, Name: ''}, ...getParentIDValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: 0,
    },
    customValidate: (state) => {
      const form = state.adminCMSSite.menus.siteMenuItem.form.ActiveForm;

      // Only cover the site menu case here, even when both SiteMenu and PageMenu are sharing the same form, because when a page menu's parent is deleted, it will be shown as NotFound.
      if (state.adminCMSSite.menus.siteMenuItem.form.isSiteMenu) {
        const menuItem = state.adminCMSSite.cacheOne.CMSSiteMenuItems ? state.adminCMSSite.cacheOne.CMSSiteMenuItems.find((menu) => menu.ID === form.ParentID) : null;

        if (menuItem && menuItem.Inactive) {
          return M.SELECTED_DROPDOWN_ITEM_DELETED;
        }
      }
      return undefined;
    },
    validatejs: {
      ParentID: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        },
      },
    },
    defaultValue: () => 0,
  },
  LinkTypeID: {
    key: 'LinkTypeID',
    isRequired: () => true,
    options: {
      values: (rootState) => [...getLinkTypeIDValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'Name'
    },
    defaultValue: (state) => {
      const activeForm = state.adminCMSSite.menus.siteMenuItem.form.ActiveForm;

      if ((activeForm.ParentID || activeForm.MenuLevel === 2) && (!activeForm.LinkTypeID || activeForm.LinkTypeID === LinkTypeValue.NO_LINK)) {
        return LinkTypeValue.PAGE;
      } else {
        return activeForm.LinkTypeID || LinkTypeValue.NO_LINK;
      }
    },
    validatejs: {
      LinkTypeID: {
        presence: {message: '^' + M.REQUIRED},
        // NOTE: There's no blank option, so setting numericality to undefined
        numericality: undefined,
      }
    }
  },
  DistrictIDi: {
    key: 'DistrictIDi',
    isRequired: (state) => {
      let required = !!state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID && state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID === LinkTypeValue.CALENDAR;
      const selectedCategorySelector = makeSelectedEventCategorySelector();
      const selectedCategory = selectedCategorySelector(state) as SiteEventCategory;

      return required && selectedCategory && selectedCategory.IsDistricts;
    },
    options: {
      values: (rootState) => [{IDi: 0, Name: ''}, ...getDistrictsValues(rootState)],
      valueKey: (v) => v.IDi,
      labelKey: 'Name',
      emptyValue: 0,
    },
    defaultValue: () => 0,
    validatejs: {
      DistrictIDi: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        },
      },
    },
  },
  EventCategoryID: {
    key: 'EventCategoryID',
    isRequired: (state) => {
      return !!state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID && state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID === LinkTypeValue.CALENDAR;
    },
    skip: (state) => {
      return !!state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID && state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID !== LinkTypeValue.CALENDAR;
    },
    options: {
      values: (rootState) => [{ID: -1, NameAndIsHidden: ''}, { NameAndIsHidden: 'Primary Calendar', ID: 0}, ...getEventCategoryIDValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'NameAndIsHidden',
      emptyValue: -1,
    },
    defaultValue: () => -1,
    localDependants: ['DistrictIDi'],
    validatejs: {
      EventCategoryID: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: -1,
        }
      },
    },
  },
  ExternalURL: {
    key: 'ExternalURL',
    isRequired: (state) => state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID === LinkTypeValue.URL,
    skip: (state) => {
      return !!state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID && state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID !== LinkTypeValue.URL;
    },
    validatejs: {
      ExternalURL: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 2048,
          tooLong: M.MAX_LENGTH(2048),
        },
      },
    },
  },
  ResourceID: {
    key: 'ResourceID',
    isRequired: (state) => {

      return !!state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID && state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID === LinkTypeValue.RESOURCE;
    },
    customValidate: (state) => {
      const form = state.adminCMSSite.menus.siteMenuItem.form.ActiveForm;
      if (form.LinkTypeID !== LinkTypeValue.RESOURCE) return;

      if (!form.ResourceID) {
        return M.INVALID_SELECTED_RESOURCE;
      } else {
        const CMSResources = state.adminCMSSite.cacheOne.CMSResources;
        const resource = CMSResources ? CMSResources.find((r) => r.ID === form.ResourceID) : null;

        if (resource && resource.Inactive) {
          return M.INVALID_SELECTED_RESOURCE;
        }
      }
    },
    skip: (state) => {
      return !!state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID && state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID !== LinkTypeValue.RESOURCE;
    },
    validatejs: {
      ResourceID: {
        presence: {message: '^' + M.INVALID_SELECTED_RESOURCE},
        numericality : {
          notGreaterThan: '^' + M.REQUIRED,
          greaterThan: 0,
        },
      },
    },
  },
  PageID:  {
    key: 'PageID',
    isRequired: (state) => {
      return !!state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID && state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID === LinkTypeValue.PAGE;
    },
    customValidate: (state) => {
      const form = state.adminCMSSite.menus.siteMenuItem.form.ActiveForm;
      if (form.LinkTypeID !== LinkTypeValue.PAGE) return;
      if (!form.PageID) {
        return M.INVALID_SELECTED_PAGE;
      } else if (state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.SelectedPage && state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.SelectedPage.Inactive) {
        return M.INVALID_SELECTED_PAGE;
      }
    },
    skip: (state) => {
      return !!state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID && state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.LinkTypeID !== LinkTypeValue.PAGE;
    },
    validatejs: {
      PageID: {
        presence: {message: '^' + M.INVALID_SELECTED_PAGE},
        numericality : {
          notGreaterThan: '^' + M.REQUIRED,
          greaterThan: 0,
        },
      },
    },
  },
  IsAlignRight: {
    key: 'IsAlignRight',
    defaultValue: () => false,
  },
  IsPrimaryMenu: {
    key: 'IsPrimaryMenu',
    defaultValue: () => false,
  },
  IsLinkNewWindow: {
    key: 'IsLinkNewWindow',
    defaultValue: () => false,
  },
};