import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/elements/header/steps.scss';

export const namespace = (): string => `${ns()}--steps`;

export interface StepsProps {
  steps: number;
  currentStep: number;
  name: string;
}

const Steps: React.SFC<StepsProps> = ({ steps, currentStep, name }: StepsProps): React.ReactElement<StepsProps> => (
  <div className={namespace()}>
    <span className={`${namespace()}--steps`}>Step {currentStep} of {steps}:</span>
    <span className={`${namespace()}--name`}>{name}</span>
  </div>
);

export default Steps;
