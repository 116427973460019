import * as Actions from './actions';
import {createSelector} from 'reselect';
import {listSelector} from "../../../../../utils/reselectHelper";
import {ClassType} from "../../../../../models/api/options";
import { Reducer } from 'redux';
import { Action, isActionType } from '../../../../../utils/StrongActions';
import { ApplicationState } from '../../../..';

const classesSelector = listSelector((state: ApplicationState) => state.cacheZero.options?.ClassTypes, true);
const filterSelector = state => state.events.event.modals.addUnscheduledClass.FilterString;

export const makeFilteredClassesSelector = () => {
  return createSelector(
    [classesSelector, filterSelector],
    (classes, filter) => {
      if (!filter || filter === '') return classes;
      return classes.filter(c => c.Name.toLowerCase().includes(filter.toLowerCase()));
    }
  );
};

export interface AddUnscheduledClassesState {
  selectedClass?: ClassType;
  FilterString?: string;
  showSearch?: boolean;
}

const AddUnscheduled: Reducer<AddUnscheduledClassesState> = (state, action: Action) => {
  if (isActionType(action, Actions.AddUnscheduledFilter)) {
    return {...state, FilterString: action.filterString};
  } else if (isActionType(action, Actions.AddUnscheduledSelect)) {
    return {...state, selectedClass: action.clazz};
  } else if (isActionType(action, Actions.AddUnscheduledIsSearching)) {
    return {...state, showSearch: action.show};
  }
  return state || {};
};

export default AddUnscheduled;
