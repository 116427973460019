import * as moment from "moment";

import {standardCurrencyFormat} from "../classesHelper";
import {FacilitiesAvailability} from "../../models/api/cacheTwoFacilties";
import {TIME_DISPLAY_FORMAT, TIME_FORMAT, getTimeMomentFromDateTime} from "../dateHelper";
import { TripReservation, isTripReservation } from "../../models/api/cacheThreeFacilities";
import { WizardReservation, isWizardReservation } from "../../models/api/cacheFourFacilities";

// Frequency shown in `perPersonRatePerDay` and `flatRatePerDay` string
type Frequency = "per block" | "per night" | "per day";

type LocationPricingInfo = {
  perPersonRatePerDay: string;
  flatRatePerDay: string;
  minPeople: string;
  maxPeople: string;
};

function getPerPersonRatePerDayText(
  amount: number | null,
  frequency: Frequency,
): string {
  if (!amount) return "";

  return `${standardCurrencyFormat(amount)} / person ${frequency}`;
}

function getFlatRatePerDayText(
  nameSingular: string,
  amount: number | null,
  frequency: Frequency,
): string {
  if (!amount) return "";

  return `${standardCurrencyFormat(amount)} / ${nameSingular} ${frequency}`;
}

function getMinPeopleText(
  MinimumPeopleBilledAtPerPersonRates: number | null,
): string {
  if (!MinimumPeopleBilledAtPerPersonRates) return "";
  return `Minimum of ${MinimumPeopleBilledAtPerPersonRates} people will be billed`;
}

function getMaxPeopleText(
  MaxAccommodation: number | null,
): string {
  if (!MaxAccommodation) return "";
  return `Maximum capacity of ${MaxAccommodation} people`;
}

/**
 * Function to get pricing frequency for facility outside trip or new reservation
 */
function getPricingFrequencyForFacilityOrNewReservation(
  facility: FacilitiesAvailability,
): Frequency;
function getPricingFrequencyForFacilityOrNewReservation(
  newReservation: WizardReservation,
  selectedAvailability: FacilitiesAvailability,
): Frequency;
function getPricingFrequencyForFacilityOrNewReservation(
  facilityOrNewReservation: FacilitiesAvailability | WizardReservation,
  selectedAvailability?: FacilitiesAvailability,
): Frequency {
  if (
    // Facility outside trip case
    !isWizardReservation(facilityOrNewReservation) &&
    facilityOrNewReservation.ftbID
  ) {
    return "per block";
  } else if (
    // Wizard case
    isWizardReservation(facilityOrNewReservation) &&
    selectedAvailability?.ftbID
  ) {
    return "per block";
  } else if (
    // Facility outside trip case
    !isWizardReservation(facilityOrNewReservation) &&
    facilityOrNewReservation.IsCheckoutNextDay
  ) {
    return "per night";
  } else if (
    // Wizard case
    isWizardReservation(facilityOrNewReservation) &&
    facilityOrNewReservation.Type.IsCheckoutNextDay
  ) {
    return "per night";
  } 

  return "per day";
}

/**
 * Gets PricingFrequency for a saved reservation. A saved reservation can be the
 * one under both ViewReservation OR ReservationWizard.
 * 
 * We don't persist `ftbID` nor the `IsCheckoutNextDay` flag in database.
 * Hence, we always fallback to "per night" or "per day" for saved reservations
 * 
 * When falling back to `per night` or `per day`, we perform a time-only comparison
 * between the start and end date, and say `per night` if checkout time is earlier in
 * the day that check-in time.
 */
function getPricingFrequencyForSavedReservation(
  savedReservation: WizardReservation | TripReservation, 
): Frequency {
  // Handle async state propagation
  if (!savedReservation.StartDateTime || !savedReservation.EndDateTime) {
    return "per night";
  }
  
  const checkinTimeMoment = getTimeMomentFromDateTime(savedReservation.StartDateTime);
  const checkoutTimeMoment = getTimeMomentFromDateTime(savedReservation.EndDateTime);

  return checkoutTimeMoment.isBefore(checkinTimeMoment) ? "per night" : "per day";
}

function getLocationPricingInfoForFacilityOutsideWizard(
  facilityOutsideWizard: FacilitiesAvailability | TripReservation,
  nameSingular: string,
): LocationPricingInfo {
  const pricingFrequency = isTripReservation(facilityOutsideWizard) ?
    // Saved reservation outside wizard
    getPricingFrequencyForSavedReservation(facilityOutsideWizard) :
    // Facility outside trip (CheckDates)
    getPricingFrequencyForFacilityOrNewReservation(facilityOutsideWizard);
    
  const perPersonRatePerDay = getPerPersonRatePerDayText(
    facilityOutsideWizard.Pricing.PerPersonRate,
    pricingFrequency,
  );
  const flatRatePerDay = getFlatRatePerDayText(
    nameSingular,
    facilityOutsideWizard.Pricing.FlatRate,
    pricingFrequency,
  );
  const minPeople = getMinPeopleText(facilityOutsideWizard.Pricing.MinimumPeopleBilledAtPerPersonRates);
  const maxPeople = getMaxPeopleText(facilityOutsideWizard.MaxAccommodation);

  return {
    perPersonRatePerDay,
    flatRatePerDay,
    minPeople,
    maxPeople,
  };
}

const getLocationPricingInfoForWizardReservation = (
  selectedAvailability: FacilitiesAvailability,
  nameSingular: string,
  reservation: WizardReservation,
): LocationPricingInfo => {
  const pricingFrequency = !reservation.ReservationID ?
    // Not a saved reservation case
    getPricingFrequencyForFacilityOrNewReservation(reservation, selectedAvailability) :
    // Saved reservation case
    getPricingFrequencyForSavedReservation(reservation);

  const perPersonRatePerDay = getPerPersonRatePerDayText(
      reservation.PerPersonRate,
      pricingFrequency,
    );
  const flatRatePerDay = getFlatRatePerDayText(
      nameSingular,
      reservation.FlatRate,
      pricingFrequency,
    );

  const minPeople = getMinPeopleText(reservation.MinimumPeopleBilledAtPerPersonRates);
  const maxPeople = getMaxPeopleText(selectedAvailability.MaxAccommodation);

  return {
    perPersonRatePerDay,
    flatRatePerDay,
    minPeople,
    maxPeople
  };
};


/**
 * Use this function to get pricing information for a location
 * 
 * We use this to get pricing text and min/max people text in the following pages
 * - Reservation wizard
 * - Outside wizard
 *   - CheckDates in Facility page (this is the location page)
 *   - CacheTwoBFacility - view specific facility under CheckDates
 *   - ViewReservation page
 */
export const getLocationPricingInfo = (
  selectedAvailabilityOrTripReservation: FacilitiesAvailability | TripReservation,
  nameSingular: string,
  reservation?: WizardReservation | null,
): LocationPricingInfo => {
  if (reservation && !isTripReservation(selectedAvailabilityOrTripReservation)) {
    // Reservation wizard
    return getLocationPricingInfoForWizardReservation(selectedAvailabilityOrTripReservation, nameSingular, reservation);
  } else {
    // Outside of wizard - ViewReservation OR Outside of trip
    return getLocationPricingInfoForFacilityOutsideWizard(selectedAvailabilityOrTripReservation, nameSingular);
  }
};

export const getCheckInTimes = (
  ShowTimes: boolean,
  CheckinTime: string | moment.Moment,
  CheckoutTime: string | moment.Moment,
  IsFacilityType: boolean,
): undefined | string => {
  if (!ShowTimes) return undefined;

  const inMoment = typeof CheckinTime === "string" ? moment(CheckinTime, TIME_FORMAT) : CheckinTime;
  const outMoment = typeof CheckoutTime === "string" ? moment(CheckoutTime, TIME_FORMAT) : CheckoutTime;
  

  return IsFacilityType ?
    `Check-in after ${inMoment.format(TIME_DISPLAY_FORMAT)}, Check-out before ${outMoment.format(TIME_DISPLAY_FORMAT)}.` :
    `Starts at ${inMoment.format(TIME_DISPLAY_FORMAT)}, Ends at ${outMoment.format(TIME_DISPLAY_FORMAT)}.`;
};