import { ActionCreator, ApplicationState } from "../../../..";
import {createApiValidateActions, createValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod} from '../../../../Validation/actionCreator';
import { entityMapper } from "../../../../../utils/dataHelper";
import { CMSResource, CMSResourceCategory } from "../../../../../models/api/adminCMSCacheOne";
import { typeName, Action } from "../../../../../utils/StrongActions";
import { SaveState } from "../../../../Rollback/actions";

export const SUFFIX = '__CMS_MULTIPLE_RESOURCES_MODAL';

@typeName("FILTER" + SUFFIX)
export class MultipleResourcesModalFilter extends Action {
  constructor(public filterString: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class MultipleResourcesToggleFilterTabs extends Action {
  constructor(public searching: boolean) { super(); }
}

@typeName("RESET" + SUFFIX) export class ResetMultipleResourcesModal extends Action {}
@typeName("INIT" + SUFFIX)
export class InitMultipleResourcesModal extends Action {
  constructor(public resourceIds: number[], public resourceCategoryId?: number) { super(); }
}

@typeName("TOGGLE_RESOURCE_SELECT" + SUFFIX)
export class ToggleResourceSelect extends Action {
  constructor(public show: boolean, public resourceId: number) { super(); }
}
@typeName("SELECT_ALL" + SUFFIX)
export class SelectAll extends Action { constructor(public resourceIds: number[]) { super(); }}
@typeName("DESELECT" + SUFFIX)
export class Deselect extends Action { constructor(public resourceIds: number[]) { super(); }}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);


export type Actions = typeof actionCreators;

export const actionCreators = {
  resourceModalUpdateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.resources.modals.multipleResources),
  resourceModalSimpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  filterMultipleResources: (filterString: string): ActionCreator => dispatch => dispatch(new MultipleResourcesModalFilter(filterString)),
  init: (resourceIds: number[]): ActionCreator => (dispatch, getState) => {
    const state = getState();

    const CMSResources = state.adminCMSSite.cacheOne.CMSResources;
    const CMSResourceCategories = state.adminCMSSite.cacheOne.CMSResourceCategories;
    let preselectResourceCategoryId;

    if (CMSResources) {
      const resources = entityMapper<CMSResource>(CMSResources, resourceIds, (r) => r.ID)
      ;
      const resourceCategoryIds: number[] = [];
      resources
        .filter((r) => !r.Inactive)
        .forEach((r) => {
          if (r && r.CategoryID && resourceCategoryIds.indexOf(r.CategoryID) === -1) resourceCategoryIds.push(r.CategoryID);
        });

      
      if (CMSResourceCategories) {
        const resourceCategories = entityMapper<CMSResourceCategory>(CMSResourceCategories, resourceCategoryIds, (rc) => rc.ID)
                                    .filter((rc) => !rc.Inactive);
        if (resourceCategories.length === 1) {
          preselectResourceCategoryId = resourceCategories[0].ID;
        }
      }
    }

    dispatch(new InitMultipleResourcesModal(resourceIds, preselectResourceCategoryId));
    dispatch(new SaveState());
  },
  reset: (): ActionCreator => dispatch => dispatch(new ResetMultipleResourcesModal()),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new MultipleResourcesToggleFilterTabs(searching)),
  toggleResourceSelect: (show: boolean, resourceId: number): ActionCreator => dispatch => dispatch(new ToggleResourceSelect(show, resourceId)),
  selectAll: (resourceIds: number[]): ActionCreator => dispatch => dispatch(new SelectAll(resourceIds)),
  deselect: (resourceIds: number[]): ActionCreator => dispatch => dispatch(new Deselect(resourceIds)),
};