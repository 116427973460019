import * as React from 'react';
import '../../../../styles/elements/site-menu/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton, Vignette} from '../../index';
import { CMSSiteMenuItem } from '../../../../models/api/adminCMSCacheOne';
import { OptionsIcon, ArrowIcon, EditIcon, CloseIcon } from '../../../../components/Icons';
import { AdminCMSSiteCacheOneState } from '../../../../store/AdminCMSSite/CacheOne';
import ContextMenuComponent from './ContextMenu';
import { ADMIN_CMS_SITE_MENU_EDIT_PATH, ADMIN_CMS_PAGE_MENU_EDIT_PATH } from '../../../../routes';
import { CardCategory } from '../../Card';
import { namespace as cardNS } from "../../Card/constants";
import { Row } from '../../Grid';
import { isCardClicked } from '../../../../utils/navHelper';
import { isPathnameMatchingRoute } from '../../../../utils/urlHelper';
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--site-menu--card';

export enum MenuCardType {
  LIST='list',
  PAGE_MENU_LIST='page-menu-list',
  EDIT_PAGE='edit-page',
  EDIT_PAGE_PAGE_MENU='edit-page-page-menu',
}
interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin';
  type: MenuCardType;
  menuItem: CMSSiteMenuItem;
  childrenNum?: number;
  activeChildrenNum?: number;
  onEdit?: (menuItem: CMSSiteMenuItem) => void;
  onRestore?: (menuItem: CMSSiteMenuItem) => void;
  onExpand?: (menuItem: CMSSiteMenuItem) => void;
  onDelete?: (menuItem: CMSSiteMenuItem) => void;
  onMoveDown?: (menuItem: CMSSiteMenuItem) => void;
  onMoveUp?: (menuItem: CMSSiteMenuItem) => void;
  onRemove?: (menuItem: CMSSiteMenuItem) => void;
  adminCMSCacheOne?: AdminCMSSiteCacheOneState
  routes?: any;
  disabled?: boolean;
  cardCategory?: CardCategory;
}

const SiteMenuCard: React.SFC<Props> = ({ children, type, template, onEdit, onDelete, onExpand, menuItem, onRestore, onMoveDown, onMoveUp, onRemove, adminCMSCacheOne, routes, childrenNum, activeChildrenNum, cardCategory, disabled, ...props }: Props): React.ReactElement<Props> => {
  let className = attribute(namespace(), undefined, props);

  const shouldAllowPropagation = (e) => {
    const route = routes[routes.length - 1];
    const clickedOnCard = isCardClicked(e, namespace, (className: string) => className !== `${namespace()}--order--actions`);
    return (route.path === ADMIN_CMS_SITE_MENU_EDIT_PATH || route.path === ADMIN_CMS_PAGE_MENU_EDIT_PATH) && !clickedOnCard;
  };

  const onEditClick = (e) => {
    if(shouldAllowPropagation(e)) return;
    e.stopPropagation();
    !disabled && onEdit && onEdit(menuItem);
  };
  const onMoveDownClick = (e) => {
    if(shouldAllowPropagation(e)) return;
    e.stopPropagation();
    !disabled && onMoveDown && onMoveDown(menuItem);
  };
  const onMoveUpClick = (e) => {
    if(shouldAllowPropagation(e)) return;
    e.stopPropagation();
    !disabled && onMoveUp && onMoveUp(menuItem);
  };
  const onRestoreClick = (e) => {
    if(shouldAllowPropagation(e)) return;
    e.stopPropagation();
    !disabled && onRestore && onRestore(menuItem);
  };
  const onExpandClick = (e) => {
    e.stopPropagation();
    onExpand && onExpand(menuItem);
  };
  const onRemoveClick = (e) => {
    if(shouldAllowPropagation(e)) return;
    e.stopPropagation();
    !disabled && onRemove && onRemove(menuItem);
  };
  const renderForList = () => {
    const route = routes[routes.length - 1];
    return (
      <Row className={`${namespace()}--main `}>
        {!menuItem.ParentID && childrenNum && childrenNum > 0 ? <div className={`${namespace()}--main--arrow ${menuItem.Expand ? 'expand' : ''}`} onClick={onExpandClick}><ArrowIcon /></div> : null}
        <div className={`${namespace()}--main--name`}>{menuItem.Name}</div>
        <div className={`${namespace()}--main--right-section ${cardCategory}`}>
          {!menuItem.Inactive && activeChildrenNum && activeChildrenNum > 0 ?
            <div className={`${namespace()}--main--subitems`}>{`${activeChildrenNum}`}<span className={`${namespace()}--main--subitems--text`}>{` sub-item${activeChildrenNum > 1 ? 's' : ''}`}</span></div> : null}
          {!menuItem.Inactive && <ActionButton
            id={generateDOMId(`site-menu-${menuItem.ID}-options`)}
            icon={OptionsIcon}
            allowPropagation={route.path === ADMIN_CMS_SITE_MENU_EDIT_PATH || route.path === ADMIN_CMS_PAGE_MENU_EDIT_PATH}
            className={`${namespace()}--options`}
            contextMenu={<ContextMenuComponent
              id={menuItem.ID}
              onMoveDown={onMoveDownClick}
              onMoveUp={onMoveUpClick}
              onEdit={onEditClick}
              onDelete={onDeleteClick} />}
            />}
          {!menuItem.Inactive && <div className={`${namespace()}--main--order-actions`}>
            <div className='up' onClick={onMoveUpClick}><ArrowIcon /></div>
            <div className='divider' />
            <div className='down' onClick={onMoveDownClick}><ArrowIcon /></div>
          </div>}
          {menuItem.Inactive && onRestore && <Button  className={`${namespace()}--restore ${cardNS()}--restore`} flat textColor="red" onClick={onRestoreClick}>RESTORE</Button>}
        </div>
      </Row>
    );
  };
  const onDeleteClick = (e) => {
    e.stopPropagation();
    onDelete && onDelete(menuItem);
  };

  const renderForEditPage = () => {
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--texts`}>
          <div className={`${namespace()}--main--name name1`}>{menuItem.Name1}</div>
          {menuItem.Name2 ? <div className={`${namespace()}--main--divider`}>/</div>: null}
          {menuItem.Name2 ? <div className={`${namespace()}--main--name name2`}>{menuItem.Name2}</div> : null}
        </div>
        <div className={`${namespace()}--main--actions`}>
          <ActionButton
            disabled={disabled}
            className={`${namespace()}--main--actions--btns edit ${cardNS()}--edit ${disabled ? 'disabled' : ''}`}
            onClick={onEditClick}
            icon={EditIcon} />
          <ActionButton
            disabled={disabled}
            onClick={onRemoveClick}
            className={`${namespace()}--main--actions--btns close ${cardNS()}--close ${disabled ? 'disabled' : ''}`}
            icon={CloseIcon} />
        </div>
      </Row>
    );
  };

  const renderPageMenuForEditPage = () => {
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--name`}>{menuItem.Name}</div>
      </Row>
    );
  };

  if (menuItem.Inactive && (type === MenuCardType.LIST || type === MenuCardType.PAGE_MENU_LIST)) className += ' deleted';
  if (menuItem.ParentID) className += ' subitem';
  if (menuItem.Name2) className += ' two-lines';
  if (disabled) className += ' disabled';
  className += ` ${type}`;

  const onCardClick = (e) => {
    switch (type) {
      case MenuCardType.LIST:
      case MenuCardType.PAGE_MENU_LIST:
        onEditClick && onEditClick(e);
        break;
      case MenuCardType.EDIT_PAGE:
        onEditClick && onEditClick(e);
        break;
      default:
        break;
    }
  };

  const siteMenuItemSelected = type === MenuCardType.LIST && isPathnameMatchingRoute(location.pathname, ADMIN_CMS_SITE_MENU_EDIT_PATH, 'menuItemId', menuItem.ID);
  const pageMenuItemSelected = type === MenuCardType.PAGE_MENU_LIST && isPathnameMatchingRoute(location.pathname, ADMIN_CMS_PAGE_MENU_EDIT_PATH, 'menuItemId', menuItem.ID);

  return (
    <Card
      className={className}
      component={<section/>}
      template={template}
      padding="none"
      category={cardCategory || CardCategory.LIST}
      selected={siteMenuItemSelected || pageMenuItemSelected}
    >
      <div id={generateDOMId(`admin-menu-card-${menuItem.ID}`)} className={`${namespace()}--wrapper ${!menuItem.ParentID && childrenNum && childrenNum > 0 ? 'has-child' : ''}`}
          onClick={onCardClick}>
        {(type === MenuCardType.LIST || type === MenuCardType.PAGE_MENU_LIST) && renderForList()}
        {type === MenuCardType.EDIT_PAGE && renderForEditPage()}
        {type === MenuCardType.EDIT_PAGE_PAGE_MENU && renderPageMenuForEditPage()}
      </div>
    </Card>
  );
};

SiteMenuCard.defaultProps = {
  template: 'mobile-no-margin',
};

export default SiteMenuCard;