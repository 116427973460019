import {OrderedProduct, Product} from '../models/product';

export const CentennialPants: OrderedProduct = {
  ID: 1,
  FeaturedImage: null,
  Name: 'Centennial Switchbacks Uniform Pants',
  Description: 'Modern automobiles are important to many aspects of American life. Those who service automobiles must ' +
    'understand each principle, and how these principles interact to provide smooth, efficient performance. ' +
    'Owners of cars also benefit by understanding how their vehicles operate. This enables them to understand ' +
    'why certain periodic maintenance is required to keep their vehicles in tip-top shape.',
  Amount: 19.99,
  Quantity: 1,
  AllowSelfRegistration: false,
  ProductIDi: 1
  // Sizes: ['XS', 'S', 'M', 'L']
};

export const BSOATShirt: OrderedProduct = {
  ID: 2,
  FeaturedImage: null,
  Name: 'BSOA T-Shirt 2016 Unisex',
  Description: 'Modern automobiles are important to many aspects of American life. Those who service automobiles must ' +
  'understand each principle, and how these principles interact to provide smooth, efficient performance. ' +
  'Owners of cars also benefit by understanding how their vehicles operate. This enables them to understand ' +
  'why certain periodic maintenance is required to keep their vehicles in tip-top shape.',
  Amount: 19.99,
  Quantity: 1,
  AllowSelfRegistration: false,
  ProductIDi: 1
  // Sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL']
};

export const OrderedCentennialPants: OrderedProduct = {
  ID: 3,
  FeaturedImage: null,
  Name: 'Centennial Switchbacks Uniform Pants Centennial Switchbacks Uniform Pants Centennial Switchbacks Uniform Pants',
  Description: 'Modern automobiles are important to many aspects of American life. Those who service automobiles must ' +
  'understand each principle, and how these principles interact to provide smooth, efficient performance. ' +
  'Owners of cars also benefit by understanding how their vehicles operate. This enables them to understand ' +
  'why certain periodic maintenance is required to keep their vehicles in tip-top shape.',
  Amount: 19.99,
  Quantity: 1,
  AllowSelfRegistration: false,
  ProductIDi: 1
  // Sizes: ['XS', 'S', 'M', 'L']
};
