import * as React from 'react';
import {
  Modal, ModalActions, Button, ModalContent, ModalHeader, Row, Column, Switch, } from '../../../../../Elements';
import {withRouter, RouteComponentProps} from "react-router";
import {bindActionCreators} from "redux";
import {actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import {actionCreators as exportParticipantClassScheduleActionCreators} from "../../../../../../store/AdminEvents/Events/Modals/ExportParticipantClassSchedule/actions";
import {actionCreators as cacheTwoEventActionCreators} from "../../../../../../store/AdminEvents/CacheTwoEvent/actions";
import {actionCreators as dashboardActionCreators} from "../../../../../../store/AdminEvents/Events/Event/Dashboard/actions";
import {ApplicationState} from "../../../../../../store";
import '../../../../../../styles/pages/admin-events/events/modals/export-participant-class-schedule/index.scss';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

const namespace = () => 'pages--events--modal--export-participant-class-schedule';

type Props = WithInertAttribute<{}>

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

@(withRouter as any)
class ExportParticipantClassScheduleModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  componentDidMount() {
    if (shouldBlockActions()) return;

    this.props.actions.init();
  }
  
  onContinue = (e?: any) => {
    if (e) e.stopPropagation();
    const {reportURL, actions, cacheTwoEvent, modalState: {ActiveForm}, cacheOne, dashboardActions} = this.props;
    if (!cacheOne.EventsEventType || !cacheTwoEvent.EventsEvent || !reportURL) return;
    
    actions.popModal(true, false, ModalTypes.EXPORT_PARTICIPANT_CLASS_SCHEDULE);

    dashboardActions.generateEventReports(
      reportURL,
      cacheOne.EventsEventType.EventTypeRow.ID as number,
      cacheTwoEvent.EventsEvent.IDi,
      undefined,
      !!ActiveForm.IncludeSecondaryTimeblocks,
      undefined,
      true,
    );
  };

  public render() {
    const {apiSaving, apiLoadingMap, reportURL, cacheTwoEvent, modalState: {ActiveForm, ValidationRules}, inert, actions, apiLoading} = this.props;

    let subtitle = cacheTwoEvent.EventsEvent ? cacheTwoEvent.EventsEvent.Name : '';
    
    return (
      <Modal inert={inert} className={`${namespace()}`} mobileFullScreen bodyScrollLock big higherZIndex>
        <ModalHeader
          subtitle={subtitle}
        >Export Participant Class Schedule</ModalHeader>
        <ModalContent>
          <div className={`${namespace()}--text`}>Please choose whether or not classes that span multiple time blocks should be listed multiple times (once for each time block). Otherwise, classes will just be shown under their starting time block.</div>
          <br />
          <Row>
            <Column span={12}>
                <Switch
                  id={generateDOMId("include-secondary-timeblocks")}
                  margin={false}
                  boldText
                  newDesign
                  label='Include Secondary Timeblocks'
                  multilineLabel
                  value={!!ActiveForm.IncludeSecondaryTimeblocks}
                  validationRules={ValidationRules.IncludeSecondaryTimeblocks}
                  onChange={actions.updateValue}
                />
            </Column>
          </Row>
        </ModalContent>
        {<ModalActions
          noPadding
          sticky
          loading={apiSaving > 0 || apiLoading > 0}
          stacked
          left={<Button expand flat textColor="black" onClick={this.onContinue}>CONTINUE</Button>}
        />}
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const reportURLSelector = makeFormModalPropSelector(ModalTypes.EXPORT_PARTICIPANT_CLASS_SCHEDULE, 'reportURL');
  
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    cacheOne: state.adminEvents.cacheOne,
    cacheTwoEvent: state.adminEvents.cacheTwoEvent,
    modalState: state.adminEvents.events.modals.exportParticipantClassSchedule,
    reportURL: reportURLSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...exportParticipantClassScheduleActionCreators,
    ...cacheTwoEventActionCreators,
  }, dispatch),
  dashboardActions: bindActionCreators({
    ...dashboardActionCreators,
  }, dispatch),
});

const ConnectedExportParticipantClassScheduleModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ExportParticipantClassScheduleModal);

export default ConnectedExportParticipantClassScheduleModal;
