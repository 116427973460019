import { GetAdminEventCacheBodyParams } from "../../../constants/adminEventsUrls";
import { CLEAR_CACHE_PREFIX, createRequestActions} from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { HideDrawer, SilentCancelAllAction } from '../../../store/App/actions';
import { ADMIN_EVENT_TYPES_SUFFIX, ADMIN_EVENTS_MODULE_SUFFIX, UPDATE_EVENT_TYPE_FORM_SUFFIX } from "../CacheOne/actions";
import { UPDATE_FORM_ACTION_SUFFIX } from "../../../utils/suffix";
import { typeName, Action } from "../../../utils/StrongActions";

export const UPDATE_EVENT_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__EVENT_FORM';
export const ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX = '__$ADMIN_EVENTS_CACHE_TWO_EVENT__HANDLE$';
export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + UPDATE_EVENT_FORM_SUFFIX + UPDATE_EVENT_TYPE_FORM_SUFFIX + ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + '__CACHE_TWO_EVENT';
export const SUFFIX_SAVE = ADMIN_EVENT_TYPES_SUFFIX + '__CACHE_TWO_EVENT_SAVING';

export const GetAdminEvent = createRequestActions(SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminEventsCacheTwoEvent extends Action { }

@typeName(`${CLEAR_CACHE_PREFIX}_BELOW_CACHE_TWO_EVENT` + SUFFIX) export class ClearAdminEventsCacheBelowTwoEvent extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  getEvent: (params: GetAdminEventCacheBodyParams, extra?: any): ActionCreator => dispatch => {
    dispatch(GetAdminEvent.request(params, extra));
  },
  refreshEvent: (params: GetAdminEventCacheBodyParams, isRefreshingCacheTwoForm: boolean): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    dispatch(GetAdminEvent.request(params, {skipUpdateForm: !isRefreshingCacheTwoForm}));
  },
  clearAdminEventsCacheTwoEvent: (): ActionCreator => dispatch => {
    dispatch(new ClearAdminEventsCacheTwoEvent());
  },
  clearAdminEventsCacheBelowTwoEvent: (): ActionCreator => dispatch => dispatch(new ClearAdminEventsCacheBelowTwoEvent()),
};