import * as React from 'react';
import {Alert, Button, Loader, Modal, ModalActions, ModalContent, ModalHeader, Row} from '../../../../../Elements';
import FinancialSummary from '../../Registration/FinancialSummary';
import {actionCreators} from "../../../../../../store/Events/Event/Modals/CancelRegistration/actions";
import {
  actionCreators as appActionCreators,
} from "../../../../../../store/App/actions";
import {
  actionCreators as cacheFourActionCreators,
} from '../../../../../../store/CacheFourEventsParticipants/actions';
import {bindActionCreators} from "redux";
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import * as PM from "../../../../../../constants/messages/participantsWizard";
import '../../../../../../styles/pages/events/event/modals/cancel-registration/index.scss';
import {makeC3OrderedProductsFilter} from "../../../../../../store/CacheThreeEvents";
import { ModalTypes } from '../../../../../../utils/modalHelper';
import EndUserCacheManager from '../../../../../../utils/cacheManagers/endUserCacheManager';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { CancelRegistrationRouterParams } from '../../../../../../utils/helpers/endUserPageHelper';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isCacheFourEventsParticipantsPopulated} from '../../../../../../utils/cachePopulatedCheckers/endUser';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--event--modals--cancel-registration';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<CancelRegistrationRouterParams, {}>
>;

class CancelRegistration extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps
  ;

  componentDidMount() {
    super.loadAndSetData(
      this.props,
      () => {
        if (
          this.props.IsYouth === undefined ||
          this.props.ParticipantIDi === undefined ||
          this.props.eventTypeID === undefined ||
          this.props.eventID === undefined
        ) return;
        EndUserCacheManager.getInstance().loadCancelRegistration({
          props: this.props,
          actions: this.props.actions,
          IsYouth: this.props.IsYouth,
          ParticipantIDi: this.props.ParticipantIDi,
          eventTypeId: this.props.eventTypeID,
          eventId: this.props.eventID,
          groupWeekIDi: this.props.groupWeekID,
          isRestore: this.props.InCart === -1,
          isStateNavigated: true,
        });
      }
    );
  }

  getDeleteWarning = () => {
    if (this.props.InCart === -1) { // aka IsAdding is false from cart
      return PM.RESTORE_WARNING;
    } else if (this.props.IsNewlyAdding) {
      return PM.DELETE_WARNING_IN_CART;
    }
    return PM.DELETE_WARNING;
  };

  getDeleteHeader = () => {
    if (this.props.InCart === -1) { // aka IsAdding is false from cart
      return `Restore Registration: ${this.props.participantName}`;
    } else if (this.props.IsNewlyAdding) {
      return `Delete Registration: ${this.props.participantName}`;
    }
    return `Cancel Registration: ${this.props.participantName}`;
  };

  getDeleteButtonText = () => {
    if (this.props.InCart === -1) { // aka IsAdding is false from cart
      return 'RESTORE';
    } else if (this.props.IsNewlyAdding) {
      return 'DELETE';
    }
    return 'CANCEL';
  };

  onCancel = () => {
    this.onClose();
    this.props.actions.popModal(false, true, ModalTypes.CANCEL_REGISTRATION);
  };
  onConfirmAndDelete = () => {
    if (
      this.props.IsYouth === undefined ||
      this.props.ParticipantIDi === undefined ||
      this.props.eventTypeID === undefined ||
      this.props.eventID === undefined ||
      this.props.groupWeekID === undefined ||
      this.props.participantName === undefined
    ) return;
    this.props.actions.updateRegistration(
      this.props.IsYouth,
      this.props.ParticipantIDi,
      this.props.eventTypeID,
      this.props.eventID,
      this.props.groupWeekID,
      this.props.participantName,
      this.props.InCart === -1,
      this.props.goToRegistration
    );
  };

  componentWillUnmount() {
    this.onClose();
  }

  onClose = () => {
    this.props.actions.clearCache();
  };

  renderLoading = () => {
    return (
      <ModalContent className={`${namespace()}--loading-content`} paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0}>
        <Loader className={`${namespace()}--loader`} center />
      </ModalContent>
    );
  };

  renderContent = () => {
    const {cacheTwoEvents, cacheFourEventsParticipants, cancelRegistration, actions, user, orderedProducts} = this.props;
    const {EventTypeRegistrationSettings} = cacheTwoEvents;
    const {EventRegistrationPaymentStatus} = cacheFourEventsParticipants;
    const { ActiveForm: { selectedFinance, otherValue }, ValidationRules } = cancelRegistration;
    let classString;
    if (EventTypeRegistrationSettings) {
      classString = EventTypeRegistrationSettings.NamesRegistrationSettings.ShowParticipantClassesAs;
    }
    // `EventRegistrationPaymentStatus` must be loaded if cacheFourEventsParticipants is populated
    if (!isCacheFourEventsParticipantsPopulated(cacheFourEventsParticipants) || !EventRegistrationPaymentStatus) return null;
    const fees: Array<{label: string; value: number;}> = [];
    fees.push({
      label: 'Registration Fees',
      value: EventRegistrationPaymentStatus.RegistrationTotal
    });
    fees.push({
      label: !!classString ? 'Options' : 'Classes',
      value: EventRegistrationPaymentStatus.ClassAmount
    });
    if (orderedProducts && orderedProducts.length > 0) {
      fees.push({
        label: 'Products',
        value: EventRegistrationPaymentStatus.ProductAmount
      });
    }
    return (
      <ModalContent paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0}>
        <Row className={`${namespace()}--row`}>
          <Alert noFlex>{this.getDeleteWarning()}</Alert>
        </Row>
        <Row className={`${namespace()}--row`}>
          <FinancialSummary
            title="Total Event Fees"
            fees={fees}
            paymentStatus={EventRegistrationPaymentStatus}
            showLoaders={false}
            selected={selectedFinance as 'min' | 'full' | 'other' | 'credit'}
            isFinanceDirty={false}
            onSelect={actions.updateValue}
            updateValue={actions.updateValue}
            simpleUpdate={actions.simpleUpdate}
            validationRules={ValidationRules}
            isSaving={false}
            otherValue={otherValue}
            IsAdmin={user.user ? user.user.str_permissions.hasAdminAccess : false}
            isWizard
            hasAccess
            hasAccessMessage={null}
          />
        </Row>
      </ModalContent>
    );
  };

  public render() {
    const {cacheFourEventsParticipants, inert} = this.props;

    const dataNotPopulated = !isCacheFourEventsParticipantsPopulated(cacheFourEventsParticipants);

    return (
      <Modal inert={inert} onClose={this.onClose} big mobileFullScreen expand className={namespace()}>
        <ModalHeader className={`${namespace()}--header`}>
          {this.getDeleteHeader()}
        </ModalHeader>
        {dataNotPopulated ? this.renderLoading() : this.renderContent()}
        <ModalActions
          sticky
          notFixed
          left={<Button id={generateDOMId("close-cancel-registration-modal")} flat textColor="black" onClick={this.onCancel}>CANCEL</Button>}
          right={<Button id={generateDOMId("submit-update-registration")} disabled={dataNotPopulated} flat textColor="red" onClick={this.onConfirmAndDelete}>{`CONFIRM AND ${this.getDeleteButtonText()}`}</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const orderedProducts = makeC3OrderedProductsFilter();
  const isYouthSelector = makeFormModalPropSelector(ModalTypes.CANCEL_REGISTRATION, 'isYouth');
  const nameSelector = makeFormModalPropSelector(ModalTypes.CANCEL_REGISTRATION, 'name');
  const participantIDSelector = makeFormModalPropSelector(ModalTypes.CANCEL_REGISTRATION, 'participantID');
  const inCartSelector = makeFormModalPropSelector(ModalTypes.CANCEL_REGISTRATION, 'inCart');
  const eventTypeIDSelector = makeFormModalPropSelector(ModalTypes.CANCEL_REGISTRATION, 'eventTypeID');
  const eventIDSelector = makeFormModalPropSelector(ModalTypes.CANCEL_REGISTRATION, 'eventID');
  const groupWeekIDSelector = makeFormModalPropSelector(ModalTypes.CANCEL_REGISTRATION, 'groupWeekID');
  const goToRegistrationSelector = makeFormModalPropSelector(ModalTypes.CANCEL_REGISTRATION, 'goToRegistration');
  const IsNewlyAddingSelector = makeFormModalPropSelector(ModalTypes.CANCEL_REGISTRATION, 'IsNewlyAdding');

  return {
    cancelRegistration: state.events.event.modals.cancelRegistration,
    cacheZero: state.cacheZero,
    cacheOne: state.cacheOne,
    cacheTwoEvents: state.cacheTwoEvents,
    cacheThreeEvents: state.cacheThreeEvents,
    cacheFourEventsParticipants: state.cacheFourEventsParticipants,
    user: state.user,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    orderedProducts: orderedProducts(state),
    IsYouth: isYouthSelector(state),
    participantName: nameSelector(state),
    ParticipantIDi: participantIDSelector(state),
    InCart: inCartSelector(state),
    eventTypeID: eventTypeIDSelector(state),
    eventID: eventIDSelector(state),
    groupWeekID: groupWeekIDSelector(state),
    goToRegistration: goToRegistrationSelector(state),
    IsNewlyAdding: IsNewlyAddingSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...cacheFourActionCreators
  }, dispatch)
});

const ConnectedCancelRegistration = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(CancelRegistration);

export default withRouter<{}>(ConnectedCancelRegistration);
