import { GetSiteMenuItem } from "../../store/AdminCMSSite/CacheTwoSiteMenus/actions";
import { ToggleNotFound } from "../../store/App/actions";
import { SaveState } from "../../store/Rollback/actions";
import { getMenuItemIdFromPath } from "../helpers/adminCMSPageHelper";
import { extractRouteParams, isIdFromUrlValid } from "../urlHelper";
import { AddSiteMenuItemSubmitActions, SiteMenuItemInitForEditPageForm } from "../../store/AdminCMSSite/Menus/SiteMenuItem/Form/actions";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import { reduxStoreService } from "../../store/service";
import type AdminCMSCacheManager from "../cacheManagers/adminCMSCacheManager";
import { LoadAdminCMSCacheTwoSiteMenuItemParams } from "./helpers/models";
import {isAdminCMSCacheTwoSiteMenuItemPopulated} from "../cachePopulatedCheckers/adminCMS";


export const ensureCacheTwoSiteMenuItem = (
  params: LoadAdminCMSCacheTwoSiteMenuItemParams & {
    cacheManager: AdminCMSCacheManager;
    menuItemIdFromEditPageSideBar?: number;
  },
): boolean => {
  const { props, parentContext,isEdit, cacheManager, isStateNavigated, menuItemIdFromEditPageSideBar } = params;
  if (!isIdFromUrlValid(props.params.siteId)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const rootState = reduxStoreService().getState();
  const adminCMSCacheTwoSiteMenuItems = rootState.adminCMSSite.cacheTwoSiteMenuItems;

  let requestedData = false;
  // The site menu item sidebar case
  if (!parentContext) {
    if (isEdit) {
      if (!isIdFromUrlValid(props.params.menuItemId)) {
        reduxStoreService().dispatch(new ToggleNotFound());
        return false;
      }
      if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminCMSCacheTwoSiteMenuItemPopulated(adminCMSCacheTwoSiteMenuItems)) {
        if (isStateNavigated) {
          reduxStoreService().dispatch(new SaveState());
        }
      } else {
        const siteMenuItemId = getMenuItemIdFromPath(props.params);

        const params = {
          ID: siteMenuItemId,
          GetResources: true,
          GetResourceCategories: true,
          GetSiteMenus: true,
          ...extractRouteParams(props),
        };

        reduxStoreService().dispatch(GetSiteMenuItem.request(params));

        requestedData = true;
      }
    } else if (isStateNavigated) {
      // Possible init for SiteMenuItem form in its own page. We don't need to re-init after a rollback
      requestedData = cacheManager.loadAdminCMSCacheOne({
        props,
        isStateNavigated,
      });

      if (!requestedData) {
        reduxStoreService().dispatch(AddSiteMenuItemSubmitActions.updateForm());
        reduxStoreService().dispatch(new SaveState());
      }
    }
  } else if (isStateNavigated) {
    // Possible init for SiteMenuItem form in EditPage. We don't need to re-init after a rollback
    reduxStoreService().dispatch(new SiteMenuItemInitForEditPageForm(isEdit && menuItemIdFromEditPageSideBar ? menuItemIdFromEditPageSideBar : undefined));
    reduxStoreService().dispatch(new SaveState());
  }

  return requestedData;
};