import * as React from 'react';
import {
  Modal, ModalTitle, ModalActions, Button, ModalContent, Text } from '../../../../../Elements';
import {withRouter, RouteComponentProps} from "react-router";
import {bindActionCreators} from "redux";
import {actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import {actionCreators} from "../../../../../../store/AdminFacilityLocation/BlackoutDates/Home/actions";
import {actionCreators as blackoutDateFormActionCreators} from "../../../../../../store/AdminFacilityLocation/BlackoutDates/Form/actions";
import {ApplicationState} from "../../../../../../store";
import '../../../../../../styles/pages/admin-facility-location/blackout-dates/modals/delete-blackout-date/index.scss';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

const namespace = () => 'pages--facility-location--modals--delete-balckout-date';
type Props = WithInertAttribute<{}>;

type ConnectedProps = 
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {}>;

@(withRouter as any)
class DeleteBlackouteDateModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  
  onCancel = (e?: any) => {
    if (e) e.stopPropagation();
    this.props.actions.popModal(false, true, ModalTypes.DELETE_BLACKOUT_DATE);
  };

  onDelete = () => {
    if (!this.props.blackoutDatesHome.deletingBlackoutDate) return;
    this.props.actions.deleteBlackoutDate(this.props.blackoutDatesHome.deletingBlackoutDate.ID, this.props.router, this.props.routes);
  };

  public render() {
    const {apiSaving, inert, apiLoading} = this.props;

    return (
      <Modal inert={inert} className={`${namespace()}`} disableDynamicHeight bodyScrollLock big higherZIndex>
        <ModalTitle marginBottom={8}>{`Delete Blackout Date?`}</ModalTitle>
        <ModalContent paddingTop={0}>
          <Text color="gray">This action cannot be undone</Text>
        </ModalContent>
        <ModalActions
          noPadding
          loading={apiSaving > 0 || apiLoading > 0}
          stacked
          alignRight
          left={<Button flat textColor="blue" id={generateDOMId("cancel-delete-blackout-date-btn")} onClick={this.onCancel}>CANCEL</Button>}
          right={<Button textColor="red" id={generateDOMId("delete-blackout-date-btn")} flat onClick={this.onDelete}>DELETE</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
    blackoutDatesHome: state.adminFacilityLocation.blackoutDates.home,
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
  ...blackoutDateFormActionCreators,
}, dispatch)});

const ConnectedDeleteModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(DeleteBlackouteDateModal);

export default ConnectedDeleteModal;
