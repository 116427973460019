import * as React from 'react';
import ClassImage from "./ClassImage";
import Button from "../Button";
import RemoveButton from "../Button/removeButton";
import Card from "../Card";
import CardPadding from "../Card/Padding";
import Column from "../Grid/Column";
import Ellipsis from "../Ellipsis";
import Row from "../Grid/Row";
import Text from "../Text";
import Info from "../Info";
import '../../../styles/elements/class/index.scss';
import {BaseEndUserClass, RegisteredClass} from '../../../models/class';
import {WaitlistIcon} from '../../Icons';
import {
  checkClassAge,
  ClassViewState,
  getAmountTooltip,
  getClassViewState,
  standardCurrencyFormat
} from "../../../utils/classesHelper";
import moment from "moment";
import * as PM from '../../../constants/messages/participantsWizard';
import { captureTentarooError } from '../../../utils/dataHelper';
import { generateDOMId } from '../../../utils/cypressHelper';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

export const namespace = (renderMobile?: boolean): string => `${renderMobile ? 'render-mobile ' : '' }elements--class`;

export const youthString = function(c: BaseEndUserClass) {
  if (c.JustYouth === 1) {
    return 'For youth';
  } else if (c.JustYouth === 0) {
    return 'For adults';
  }
  return 'For everyone';
};

export interface Props {
  classModel: BaseEndUserClass | RegisteredClass;
  className?: string;
  renderMobile?: boolean; // render this class mobile style even if not mobile

  numYouth: number;
  numAdults: number;
  enableWaitlist: boolean;
  individual?: boolean;
  individualDOB?: moment.Moment;
  isPreview: boolean; // non-wizards use preview
  isRegistration?: boolean; // @todo: rename to isRegistered?
  isAdmin?: boolean;
  hideForYouth?: boolean;
  labelOptions?: boolean;
  eventStateDate?: moment.Moment;
  onManageClass?: (c: BaseEndUserClass) => void; // only does something if you have onRemoveClass as well
  onAddClass?: (clazz: BaseEndUserClass) => void;
  onRemoveClass?: (clazz: BaseEndUserClass) => void;
  onJoinWaitlist?: (clazz: BaseEndUserClass) => void;
}

type ConnectedProps = ReturnType<typeof mapStateToProps>;

class Class extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  onJoin = () => this.props.onJoinWaitlist ? this.props.onJoinWaitlist(this.props.classModel) : null;

  private renderSpotsLeft(ns, classViewState: ClassViewState) {

    let color: 'red' | 'orange' | 'gray' = 'gray';
    let weight: 'regular' | 'bold' = 'regular';
    if (classViewState.state === 'waitlist') {
      color = 'orange';
      weight = 'bold';
    } else if (classViewState.state === 'full') {
      color = 'red';
      weight = 'bold';
    } else if (classViewState.state === 'warning') {
      color = 'orange';
      weight = 'bold';
    }
    let showTooltip: boolean = classViewState.tooltip !== '';

    return (
      <Text size={14} color={color} align="left" className={`${ns}--spots--spots`} weight={weight}
            icon={classViewState.state === 'waitlist' ? <WaitlistIcon width={16} height={18} color="orange"/> : undefined}
            info={showTooltip ? <Info showTooltipOnClick tooltipText={classViewState.tooltip}/> : undefined}
      >
        {classViewState.statusText}
      </Text>
    );
  }

  private renderPrice(ns, classViewState: ClassViewState) {
    const { labelOptions, renderMobile, classModel, individual, isRegistration, isAdmin} = this.props;
    const { Amount, JustYouth, AmountEstimated } = this.props.classModel;
    const AmountPaid = "AmountPaid" in this.props.classModel ? this.props.classModel.AmountPaid : undefined;

    let tooltip: string | undefined = getAmountTooltip(
      classModel,
      !!individual,
      !!isRegistration,
      !!isAdmin,
      labelOptions
    );
    if (tooltip === '') tooltip = undefined;

    if (!Amount && !AmountEstimated) {
      return null;
    } else if (!individual) {
      const numParticipants = classViewState.numParticipants;
      const totalAmount = Amount * numParticipants;
      let perString = 'person';
      if (JustYouth === 1) perString = 'youth';
      else if (JustYouth === 0) perString = 'adult';

      return (
        <div className={`${ns}--price-per-container`}>
          {!isRegistration && <Text weight="bold" size={18} mobileSize={16} renderMobile={renderMobile} >{`${standardCurrencyFormat(Amount)} / ${perString}`}</Text>}
          {isRegistration && <Text weight="bold" size={18} mobileSize={16} className={`${ns}--total-amount`} renderMobile={renderMobile} info={
            tooltip ? <Info showTooltipOnClick tooltipText={tooltip}/> : undefined
          }>{`${standardCurrencyFormat(totalAmount)}`}</Text>}
          {isRegistration && <Text color="gray" size={14} mobileSize={13} renderMobile={renderMobile}>
            {`${standardCurrencyFormat(Amount)} x ${numParticipants} ${numParticipants === 1 ? 'person': 'people'}`}
          </Text>}
        </div>
      );
    }

    let displayedAmount: string = '';
    if (Amount) {
      displayedAmount = standardCurrencyFormat(Amount);
    } else if (!Amount && !AmountPaid && AmountEstimated) { // "!" (not)   meaning if the Amount is not present or it equals 0
      displayedAmount = `~${standardCurrencyFormat(AmountEstimated)}`;
    } else {
      captureTentarooError(new Error(
        "In Class, `displayedAmount` is empty: Theres no Amount or Estimated amount! Or theres an AmountPaid and no Amount?"
      ));
    }
    return (
      <Text weight="bold" size={18} mobileSize={16} renderMobile={renderMobile} info={
        tooltip ? <Info showTooltipOnClick tooltipText={tooltip}/> : undefined
      }>{displayedAmount}</Text>
    );
  }

  private renderActionRow(ns, classViewState: ClassViewState) {
    const { labelOptions, onAddClass, onRemoveClass, onManageClass, isAdmin, enableWaitlist, classModel, individual, numYouth, numAdults, individualDOB } = this.props;
    const { Amount, conflictStatus, AllowSelfRegistration, Maximum, RegistrationRank, AmountEstimated } = classModel;

    let canAdd: boolean = false;
    let canRemove: boolean = false;
    if (isAdmin || AllowSelfRegistration) {
      canAdd = true;
      canRemove = true;
      if (!individual) {
        if (Maximum < 0) {
          canAdd = false;
        } else if (Maximum > 0) {
          if (classViewState.availNum < classViewState.numParticipants) {
            canAdd = false;
          }
        }
      } else {
        if (Maximum < 0 || (Maximum > 0 && !enableWaitlist && RegistrationRank > Maximum)) {
          canAdd = false;
        }
      }
    }
    let showAgeError = false;
    if (individual && numYouth === 1 && numAdults === 0) {
      showAgeError = !checkClassAge(classModel, false, !!numYouth, individualDOB);
    }

    if (!onAddClass && !onRemoveClass && Amount !== 0) {
      return (
        <div className={`${ns}--price`}>
          {this.renderPrice(ns, classViewState)}
        </div>
      );
    } else if (onAddClass && classViewState.availNum > 0) {
      return (
        <div className={`${ns}--action-row add`}>
          <Button id={generateDOMId(`add-class-${classModel.IDi}`)} color={conflictStatus === "conflict" ? 'red' : 'green'} onClick={onAddClass.bind(this, classModel)} disabled={!canAdd}>
            ADD {labelOptions ? 'OPTION' : 'CLASS'}
          </Button>
          {this.renderPrice(ns, classViewState)}
        </div>
      );
    } else if (onAddClass && classViewState.availNum <= 0) {
      if (enableWaitlist) {
        return (
          <div className={`${ns}--action-row wait`}>
            <Button color={conflictStatus === "conflict" ? 'red' : 'orange'} textColor="white" onClick={this.onJoin}>
              JOIN WAITLIST
            </Button>
            {this.renderPrice(ns, classViewState)}
          </div>
        );
      } else {
        return (
          <div className={`${ns}--action-row add`}>
            <Button id={generateDOMId(`add-class-${classModel.IDi}`)} color={conflictStatus === "conflict" ? 'red' : 'green'} onClick={onAddClass.bind(this, classModel)} disabled>
              ADD {labelOptions ? 'OPTION' : 'CLASS'}
            </Button>
            {this.renderPrice(ns, classViewState)}
          </div>
        );
      }
    } else if (onRemoveClass) { // note, this is only rendered in side panels or mobile
      let errorText;
      // @todo: waitlist should be here too? then we should use a Text similar to above where classViewState is used
      if (showAgeError) {
        errorText = <Text size={14} color="red" align="left" weight="bold" marginBottom={8}>
          {!individualDOB ? PM.DOB_REQUIRED : PM.MIN_AGE_FAILED}
        </Text>;
      } else if (classViewState.state === 'full') {
        errorText = <Text size={14} color="red" align="left" weight="bold" marginBottom={8}>
          {classViewState.tooltip}
        </Text>;
      }
      if (onManageClass) { // @todo: and user is admin? currently this check is done before using a class
        return [
          errorText,
          Amount !== 0 && (<div className={`${ns}--action-row price-only`} key="price-only">
            {this.renderPrice(ns, classViewState)}
          </div>),
          (<div className={`${ns}--action-row manage`} key="manage-row">
            <Button id={generateDOMId(`manage-class-${classModel.IDi}`)} color="gray" textColor="black" admin flat onClick={onManageClass.bind(this, classModel)}>MANAGE</Button>
            <RemoveButton onClick={onRemoveClass.bind(this, classModel)} disabled={!canRemove}>REMOVE</RemoveButton>
          </div>)
        ];
      }
      return [
        errorText,
        <div className={`${ns}--action-row remove ${(!Amount && !AmountEstimated) ? 'no-amount' : ''}`}>
          {Amount !== 0 ? this.renderPrice(ns, classViewState) : <div></div>}
          <RemoveButton onClick={onRemoveClass.bind(this, classModel)} disabled={!canRemove}>REMOVE</RemoveButton>
        </div>
      ];
    }
    return null;
  }

  public render() {
    const { isAdmin, renderMobile, individual, hideForYouth, classModel, isRegistration, enableWaitlist, numYouth, numAdults, isPreview, allClassTypes} = this.props;
    const {
      Name, StartTime, AllTimeBlocksDisplay,
      MinimumAge, DaysDisplay, ShowStartTime, ShowTimeBlock
    } = this.props.classModel;
    const errorMessage = "errorMessage" in this.props.classModel ? this.props.classModel.errorMessage : undefined;
    const NumOverride = "NumOverride" in this.props.classModel ? this.props.classModel.NumOverride : undefined;
    const classViewState = getClassViewState(
      classModel,
      !!individual,
      enableWaitlist,
      numYouth,
      numAdults,
      !!isRegistration,
      !isRegistration,
      isPreview,
      NumOverride,
      !!isAdmin
      // (isAdmin && !isRegistration) ? 1 : (NumOverride ? NumOverride : 10000)
    );

    const showMinAge = !!MinimumAge && MinimumAge !== '0';
    const dateString = 'Days: ' + DaysDisplay;
    const ns = namespace(renderMobile);

    let titleClass = '';
    if (individual && showMinAge) {
      titleClass = 'with-min-age';
    } else if (!hideForYouth) {
      titleClass = 'with-youth';
    }

    const showingStartTime = !!StartTime && ShowStartTime;
    const showingTimeBlock = !!AllTimeBlocksDisplay && ShowTimeBlock;

    const classType = allClassTypes.find((classType) => classType.IDi === classModel.ClassTypeIDi);

    const Description = classType?.Description;

    return (
      <Card className={ns} template="mobile-stretch" padding="none">
        <CardPadding layout="vertical" className={`${ns}--padding`}>
          <div className={`${ns}--title-block`}>
            {/* @todo: this should flex grow more is MinAge is not present */}
            <Row className={`${ns}--title-block--title-row ${titleClass}`} mobileMarginBottom={8} renderMobile={renderMobile}>
              <Column gutter={false} marginRight={16}>
                <ClassImage classModel={this.props.classModel}/>
              </Column>
              <Column className={`${ns}--name-column`} gutter={false} overflowHidden marginRight={16} layout="vertical">
                <div className={`${ns}--name-div`}>
                  <Ellipsis
                    className={`${ns}--name`}
                    isStatic
                    configs={{
                      lines: 2,
                      text: Name,
                    }}
                  />
                </div>
                <div>
                  <div className={`${ns}--spots`} >
                    {this.renderSpotsLeft(ns, classViewState)}
                  </div>
                </div>
              </Column>
            </Row>
            {((individual && showMinAge) || (!hideForYouth)) && <div className={`${ns}--availability`}>
              {!hideForYouth && <Text align="right" size={14} marginBottom={4} mobileMarginBottom={0} color="gray">{youthString(this.props.classModel)}</Text>}
              {individual && showMinAge && <Text size={14} color="red" weight="medium" mobileMarginLeft={hideForYouth ? undefined: 24} renderMobile={renderMobile}>{`${MinimumAge} years and older`}</Text>}
            </div>}
          </div>
          {(showingTimeBlock || showingStartTime || DaysDisplay) && <Row className={`${ns}--time-blocks`} mobileMarginBottom={Description ? 8 : 16} marginBottom={renderMobile && Description ? 8 : 16}>
            {(showingTimeBlock || showingStartTime) && <Column mobileMarginBottom={4} renderMobile={renderMobile} className={`${ns}--time-blocks--time-block`}>
              {showingTimeBlock && <Text weight="medium" color="dark-gray" size={14} marginBottom={4}>
                {`Time Blocks: ${(AllTimeBlocksDisplay === '-' || AllTimeBlocksDisplay === '500')? 'Unscheduled' : AllTimeBlocksDisplay}`}
              </Text>}
              {showingStartTime && <Text weight="medium" color="dark-gray" size={14}>{`Start Time: ${StartTime}`}</Text>}
            </Column>}
            {DaysDisplay && <Column className={`${ns}--time-blocks--dates`}>
              <Text weight="medium" color="dark-gray" size={14}>{dateString}</Text>
            </Column>}
          </Row>}
          {Description && (
            <div className={`${ns}--description`}>
              <Ellipsis
                configs={{
                  lines: 2,
                  text: Description,
                }}
              />
            </div>
          )}
          {errorMessage && <div className={`${ns}--error-message`}>
            <Text weight="medium" color="red" size={13}>{errorMessage}</Text>
          </div>}
          {this.renderActionRow(ns, classViewState)}
        </CardPadding>
      </Card>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  
  return {
    allClassTypes: state.cacheZero.options?.ClassTypes || [],
  };
};

const ConnectedClass = connect(
  mapStateToProps,
  null,
  getMergeProps<Props>(),
)(Class);
export default ConnectedClass;
export { default as ClassGroup } from './ClassGroup';
