import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators } from 'redux';
import { CacheThreeFacilitiesContext } from "@tentaroo/shared";

import { Main, MainContent } from '../../../../Layouts';
import { LoadingAll } from '../../../../Elements';
import { actionCreators } from "../../../../../store/Facilities/Trip/ViewReservation/actions";
import '../../../../../styles/pages/facilities/trip/view-reservation/index.scss';
import {
  actionCreators as cacheZeroActionCreators,
} from "../../../../../store/CacheZero/actions";
import {
  actionCreators as appActionCreators, ShowTopFloatingAlert
} from "../../../../../store/App/actions";
import {
  actionCreators as cacheTwoFacilitiesActionCreators,
  ClearCacheBelowTwoFacilities
} from "../../../../../store/CacheTwoFacilities/actions";
import {
  actionCreators as cacheThreeFacilitiesActionCreators,
} from "../../../../../store/CacheThreeFacilities/actions";
import {
  actionCreators as rollbackActionCreators,
} from "../../../../../store/Rollback/actions";
import {
  actionCreators as cacheOneActionCreators,
} from "../../../../../store/CacheOne/actions";
import { makeSelectedLocationSelector } from "../../../../../store/CacheTwoFacilities";
import { makeReservationSelector } from "../../../../../store/CacheThreeFacilities";
import ReservationCard from '../Reservation/Reservation/ReservationCard';
import {
  makeSelectedReservationSelector,
} from "../../../../../store/Facilities/Trip/ViewReservation";
import FacilityDetails from '../Reservation/Confirmation/Facility';
import { FULL_DATE_FORMAT } from "../../../../../utils/dateHelper";
import { FacilitiesRecalc } from "../../../../../store/CacheFourFacilities/actions";
import { NO_BOOKING } from "../../../../../constants/messages/generic";
import { NotFound } from '../../../';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { ApplicationState } from '../../../../../store';
import EndUserCacheManager from '../../../../../utils/cacheManagers/endUserCacheManager';
import { shouldReconfigRouter } from '../../../../../utils/cacheLoaders/reloaderHelper';
import { isPathUnderEndUserFacilityCacheThree } from '../../../../../utils/helpers/endUserPageHelper';
import { connect } from 'react-redux';
import { reduxStoreService } from '../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isCacheThreeFacilitiesPopulated} from '../../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--facilities--trip--view-reservation';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, { id: string; locId: string; tripId: string; name: string; }>
>;

@(withRouter as any)
class ViewReservation extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
  private nextLocation;

  public componentDidMount() {
    this.configRouteLeaveHook();
    
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeFacilities({
          props: this.props,
          isEdit: true,
          isStateNavigated,
          context: CacheThreeFacilitiesContext.VIEW_RESERVATION,
        });
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouteLeaveHook();
  }

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  componentWillUnmount() {
    const {cacheThreeFacilities} = this.props;

    // if we are not navigating to cache below cache three facility, clear cache
    if (
      this.nextLocation &&
      !isPathUnderEndUserFacilityCacheThree(this.nextLocation.pathname) &&
      isCacheThreeFacilitiesPopulated(cacheThreeFacilities)
    ) {
        reduxStoreService().dispatch(new ClearCacheBelowTwoFacilities());
    }
    this.resetRouteLeaveHook();
  }

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  public componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeFacilities({
          props: nextProps,
          isEdit: true,
          isStateNavigated,
          context: CacheThreeFacilitiesContext.VIEW_RESERVATION,
        });
      }
    );
  }

  onCancel = () => {
    const { actions, cacheThreeFacilities: { FacilityTripPermissions }, user, selectedReservation } = this.props;
    const isAdmin = user.user.str_permissions.hasAdminAccess;

    if (
      !FacilityTripPermissions
    ) return;

    if (!FacilityTripPermissions.hasAllowChange) {
      reduxStoreService().dispatch(new ShowTopFloatingAlert(FacilityTripPermissions.hasAllowChangeReason ?? "", false, 'orange'));
    } else if (!isAdmin && !selectedReservation.AllowOnlineFacilityBooking) {
      reduxStoreService().dispatch(new ShowTopFloatingAlert(NO_BOOKING, false, 'orange'));
    } else {
      actions.pushModal(ModalTypes.CANCEL_RESERVATION, true, false, {
        inCart: selectedReservation.InCart,
        reservationID: selectedReservation.ID,
        reservationName: selectedReservation.Name,
        wasFinalized: selectedReservation.WasFinalized,
      });
    }
  };

  private renderContent() {
    const {
      selectedReservation, viewReservation: { showMore }, actions, cacheTwoFacilities,
      cacheThreeFacilities: { FacilityTripPermissions }, user
    } = this.props;

    const selectedFacilityType = selectedReservation.Type;

    return [
      <ReservationCard
        facilityOrReservation={selectedReservation}
        showMore={showMore}
        onShowMore={actions.showMore}
        selectedFacilityType={selectedFacilityType}
      />,
      <FacilityDetails
        title="Reservation Details"
        FacilityTripPermissions={FacilityTripPermissions}
        selectedFacilityType={selectedFacilityType}
        Amount={selectedReservation.Amount}
        CheckinTime={selectedReservation.StartDateTime.format(FULL_DATE_FORMAT)}
        CheckoutTime={selectedReservation.EndDateTime.format(FULL_DATE_FORMAT)}
        showCart={selectedReservation.InCart === 1}
        NumYouth={selectedReservation.NumYouth}
        NumAdults={selectedReservation.NumAdults}
        onCancel={this.onCancel}
        isAdmin={user.user.str_permissions.hasAdminAccess}
        AllowOnlineFacilityBooking={selectedReservation.AllowOnlineFacilityBooking}
        clearCacheThreeAndBelow={this.props.actions.clearCacheBelowThreeFacilities}
      />
    ];
  }

  public render(): any {
    const { selectedLocation, inert, selectedReservation, cacheTwoFacilities, cacheThreeFacilities, apiLoadingMap } = this.props;
    if (!isCacheThreeFacilitiesPopulated(cacheThreeFacilities) || !selectedLocation || !cacheTwoFacilities.FacilityLocation_FacilityTypes || !cacheThreeFacilities.FacilityTrip) {
      return <LoadingAll />;
    }

    if (!selectedReservation) return <NotFound />;

    const ret: Array<any> = [];

    ret.push(<Main inert={inert} mobileBackground="white">
      <MainContent className={`${namespace()}--main`} loading={apiLoadingMap[FacilitiesRecalc.requestType]}>
        {this.renderContent()}
      </MainContent>
    </Main>);

    return ret;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedLocation = makeSelectedLocationSelector();
  const activeReservations = makeReservationSelector();
  const selectedReservation = makeSelectedReservationSelector();
  return {
    app: state.app,
    viewReservation: state.facilities.trip.viewReservation,
    user: state.user,
    summary: state.facilities.trip.summary,
    SystemSettings: state.session.SystemSettings,
    facilityLocation: state.facilities.location,
    facilitiesLocations: state.facilities.locations,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheThreeFacilities: state.cacheThreeFacilities,
    cacheTwoFacilities: state.cacheTwoFacilities,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    selectedLocation: selectedLocation(state),
    activeReservations: activeReservations(state),
    selectedReservation: selectedReservation(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...rollbackActionCreators,
    ...cacheThreeFacilitiesActionCreators,
    ...cacheTwoFacilitiesActionCreators,
    ...cacheOneActionCreators,
    ...cacheZeroActionCreators,
    ...appActionCreators,
    ...actionCreators
  }, dispatch)
});

const ConnectedViewReservation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewReservation);

export default ConnectedViewReservation;
export { default as FacilitiesTripSummaryHeader } from './Header';
