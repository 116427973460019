import { EventRegistrationParticipantType, EventRegistrationSpot } from "../models/api/cacheFourEvents";
import { ADMIN_EVENT_CLASSES_PATH, ADMIN_EVENT_CLASS_TYPES_PATH, ADMIN_EVENT_DASHBOARD_PATH, ADMIN_EVENT_EDIT_CLASS_PATH, ADMIN_EVENT_EDIT_CLASS_TYPE_PATH, ADMIN_EVENT_NEW_CLASS_PATH, ADMIN_EVENT_NEW_CLASS_TYPE_PATH, ADMIN_EVENT_SETTINGS_PATH, ADMIN_EVENT_TIME_BLOCKS_PATH } from "../routes";
import { ApplicationState } from "../store";
import { CampsiteAssigmentFormState } from "../store/Events/Event/Register/Numbers/Campsite";
import { isPathnameMatchingRoute } from "./urlHelper";

export const determineAvailability = (eventsAvailable: number, typeAvailable: number, eventsMax: number, typeMax: number, retNegative?: boolean): number | undefined => {
  if ((eventsMax === 0 || eventsMax === null) && (typeMax === 0 || typeMax === null)) {
    return undefined; // Unlimited
  } else if (eventsMax > 0 && (typeMax === 0 || typeMax === null)) {
    return doMax(eventsAvailable, retNegative);
  } else if ((eventsMax === 0 || eventsMax === null) && typeMax > 0) {
    return doMax(typeAvailable, retNegative);
  }
  return doMax(Math.min(eventsAvailable, typeAvailable), retNegative);
};

const doMax = (num: number, retNegative?: boolean) => {
  if (retNegative) return num;
  if (!num) return 0;
  return Math.max(0, num);
};

export const spotsOnlySelector = (state: ApplicationState) => {
  const user = state.user;
  const EventTypeRegistrationSettings = state.cacheTwoEvents.EventTypeRegistrationSettings;
  const isAdmin = user.user ? user.user.str_permissions.hasAdminAccess : false;

  const SpotsOnly = EventTypeRegistrationSettings && EventTypeRegistrationSettings.NumbersRegistrationSettings.LockInPricingBasedOnID === 2 || !isAdmin;

  return SpotsOnly;
};
export const createSpotsMap = (eventRegistrationSpots: EventRegistrationSpot[], eventRegistrationParticipantTypes: EventRegistrationParticipantType[]) => {
  const map = new Map<number, {pt: EventRegistrationParticipantType, spots: EventRegistrationSpot[]}>();
  eventRegistrationSpots.forEach((spot) => {
    const participantType = eventRegistrationParticipantTypes.find((pt) => spot.PTID === pt.ID);
    
    if (participantType) {
      let entry = map.get(participantType.ID);

      if (!entry) {
        entry = {
          pt: participantType,
          spots: [],
        };
      }
      if (!entry.spots) entry.spots = [];
      entry.spots.push(spot);

      map.set(participantType.ID, entry);
    }
  });

  return map;
};

export const spotsMapper = (map: Map<number, {pt: EventRegistrationParticipantType, spots: EventRegistrationSpot[]}>, isYouth: boolean) => {
  return Array.from(map, ([name, value]) => value).filter((val) => {
    return isYouth ? !!val.pt.IsYouth : !val.pt.IsYouth;
  }).map((val) => {
    return {
      ...val.pt,
      spots: val.spots,
    };
  }).sort((a, b) => {
    return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase());
  });
};

export const createCampsiteFormFilter = (filterByInactiveOnly?: boolean) => {
  return (campsiteForm: CampsiteAssigmentFormState) => {
    return filterByInactiveOnly ? !campsiteForm.ActiveForm.Inactive : !campsiteForm.ActiveForm.Inactive && !!campsiteForm.ActiveForm.CampsiteIDi;
  };
};

export const isPathUnderAdminEvent = (pathname: string) => {
  const result = isPathnameMatchingRoute(pathname, ADMIN_EVENT_DASHBOARD_PATH) || isPathnameMatchingRoute(pathname, ADMIN_EVENT_SETTINGS_PATH) || isPathnameMatchingRoute(pathname, ADMIN_EVENT_CLASS_TYPES_PATH) || isPathnameMatchingRoute(pathname, ADMIN_EVENT_NEW_CLASS_TYPE_PATH) || isPathnameMatchingRoute(pathname, ADMIN_EVENT_EDIT_CLASS_TYPE_PATH) || isPathnameMatchingRoute(pathname, ADMIN_EVENT_CLASSES_PATH) || isPathnameMatchingRoute(pathname, ADMIN_EVENT_NEW_CLASS_PATH) || isPathnameMatchingRoute(pathname, ADMIN_EVENT_EDIT_CLASS_PATH) || isPathnameMatchingRoute(pathname, ADMIN_EVENT_TIME_BLOCKS_PATH);

  return result;
};