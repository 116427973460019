import * as React from 'react';
import {Main, MainContent} from '../../../../../../Layouts';
import {Text, Card, Select, Alert, Row, Column, View, TextField, ActionButton, Button, EmptyMessage} from '../../../../../../Elements';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import {NumbersConnectedProps} from "../index";
import {actionCreators} from '../../../../../../../store/Events/Event/Register/Numbers/Campsite/actions';
import {bindActionCreators} from 'redux';
import {makeFilteredEventRegistrationCampsiteAssignmentsSelector, makePreferredCampsitesSelector} from "../../../../../../../store/Events/Event/Register/Numbers/Campsite/index";
import { ApplicationState } from '../../../../../../../store';
import { makeTotalNumParticipantsSelector } from '../../../../../../../store/CacheFourEventsNumbers';
import "../../../../../../../styles/pages/events/event/register/numbers/campsite-assignment.scss";
import { CloseIcon, ListIcon } from '../../../../../../Icons';
import { EmptyMessageType } from '../../../../../../Elements/EmptyMessage';
import { CAMPSITE_OVERBOOKED } from '../../../../../../../constants/messages/numbersWizard';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--event--register--campsite-assignment';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = WithInertAttribute<{
  numberProps: NumbersConnectedProps;
}>;

class CampsiteAssignment extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  renderPreferredCampsites = () => {
    const {cacheFourEventsNumbers: {EventRegistrationNumbers}} = this.props.numberProps;
    const {preferredCampsites} = this.props;

    if (!EventRegistrationNumbers || (!EventRegistrationNumbers.CampsiteRank1IDi && !EventRegistrationNumbers.CampsiteRank2IDi && !EventRegistrationNumbers.CampsiteRank3IDi)) return null;

    return (
      <Column mobileMarginBottom={16} span={6} mobileSpan={12}>
        <View layout="vertical">
          <Text size={12} color="gray" marginBottom={8}>Preferred Campsites</Text>
          {preferredCampsites.map((campsite, index) => {
            return <Text key={`campsite${index}`}>{campsite.Name}</Text>;
          })}
        </View>
      </Column>
    );
  };

  onCampsiteUpdate = (value, vObj, assignment) => {
    const {actions} = this.props;
    const overrideStateSelector = (s: ApplicationState) => s.events.event.register.numbers.campsite.EventRegistrationCampsiteAssignments.find((_assignment) => _assignment.ActiveForm.ID === assignment.ActiveForm.ID);

    actions.updateCampsiteValue(
      value,
      vObj,
      overrideStateSelector,
      true,
      (s: ApplicationState) => s.events.event.register.numbers.campsite,
    );
  };

  onNew = () => {
    const {actions} = this.props;

    actions.addCampsiteAssignment();
  };

  renderContent = () => {
    const {campsite: {ActiveForm, ValidationRules, SubmitErrorMessage}, filteredEventRegistrationCampsiteAssignments, YouthSum, AdultSum, apiLoading, actions} = this.props;
    const {cacheFourEventsNumbers: {EventRegistrationCampsiteRankingOptions},} = this.props.numberProps;

    const noCampsiteOptions = !EventRegistrationCampsiteRankingOptions || EventRegistrationCampsiteRankingOptions.length === 0;

    const overassigned = (ActiveForm.NumYouthTotal && ActiveForm.NumYouthTotal > YouthSum) || (ActiveForm.NumAdultsTotal && ActiveForm.NumAdultsTotal > AdultSum);

    return (
      <MainContent loading={apiLoading > 0}>
        {SubmitErrorMessage && <Alert noFlex>{SubmitErrorMessage}</Alert>}
        <Card padding="none" className={`${namespace()}--card`} template="mobile-stretch">
          <Row className={`${namespace()}--info-row`}>
            {this.renderPreferredCampsites()}
            <Column span={6} mobileSpan={12}>
              <View layout="vertical">
                <Text size={12} color="gray" marginBottom={8}>Total Number of Participants</Text>
                <Text>{`${YouthSum} Youth`}</Text>
                <Text>{`${AdultSum} Adult${AdultSum !== 1 ? 's' : ''}`}</Text>
              </View>
            </Column>
          </Row>
          {noCampsiteOptions && <Row className={`${namespace()}--empty-campsites`}>
            <EmptyMessage
              icon={ListIcon}
              type={EmptyMessageType.PAGE_MARGIN}
              iconHeight='96px'
              iconWidth='96px'
              description="No campsites available for assignment in this event’s location. Campsites can be added in Locations under system settings."
              admin
            />
            </Row>}
          {!noCampsiteOptions && <Row className={`${namespace()}--campsites-row`}>
            {filteredEventRegistrationCampsiteAssignments && filteredEventRegistrationCampsiteAssignments.map((assignment, index) => {
              return (
                <div key={`campsite_${index}`} className={`${namespace()}--campsite-wrapper`}>
                  <div className={`${namespace()}--campsite--select`}>
                    <Select
                      label="Campsite"
                      hideOptional
                      value={assignment.ActiveForm.CampsiteIDi}
                      validationRules={assignment.ValidationRules.CampsiteIDi}
                      onChangeValue={(value, vObj) => this.onCampsiteUpdate(value, vObj, assignment)}
                      isNumber />
                  </div>
                  <div className={`${namespace()}--campsite--textfield`}>
                    <TextField
                      label="# Youth"
                      validationRules={assignment.ValidationRules.NumYouth}
                      value={assignment.ActiveForm.NumYouth}
                      onChange={(value, vObj) => this.onCampsiteUpdate(value, vObj, assignment)}
                      hideOptional
                      onBlur={(value, vObj) => this.onCampsiteUpdate(value, vObj, assignment)}
                    />
                  </div>
                  <div className={`${namespace()}--campsite--textfield`}>
                    <TextField
                      label="# Adults"
                      validationRules={assignment.ValidationRules.NumAdults}
                      value={assignment.ActiveForm.NumAdults}
                      onChange={(value, vObj) => this.onCampsiteUpdate(value, vObj, assignment)}
                      hideOptional
                      onBlur={(value, vObj) => this.onCampsiteUpdate(value, vObj, assignment)}
                    />
                  </div>
                  <div className={`${namespace()}--campsite--remove`}>
                    <ActionButton
                      icon={CloseIcon}
                      onClick={() => this.onCampsiteUpdate(true, assignment.ValidationRules.Inactive, assignment)}
                    />
                  </div>
                </div>
              );
            })}
            <Column className={`${namespace()}--campsite--new`} span={12}>
              <Button textColor="black" color="white" onClick={this.onNew}>NEW</Button>
            </Column>
          </Row>}
          {!noCampsiteOptions && <Row className={`${namespace()}--summary-row`}>
            <Column marginRight={32} expand>
              <View layout="vertical">
                <Text>Total Number Assigned to Campsite(s):</Text>
                {!!overassigned ? <Text size={14} className={`${namespace()}--error`} marginTop={2} mobileMarginTop={8} color="red" weight="medium">{CAMPSITE_OVERBOOKED}</Text> : null}
              </View>
            </Column>
            <Column>
              <View layout="vertical">
                <Text weight="medium" color={ActiveForm.NumYouthTotal && ActiveForm.NumYouthTotal > YouthSum ? "red" : "black-87"}>{`${ActiveForm.NumYouthTotal} Youth`}</Text>
                <Text weight="medium" color={ActiveForm.NumAdultsTotal && ActiveForm.NumAdultsTotal > AdultSum ? "red" : "black-87"}>{`${ActiveForm.NumAdultsTotal} Adult${ActiveForm.NumAdultsTotal !== 1 ? 's' : ''}`}</Text>
              </View>
            </Column>
          </Row>}
        </Card>
      </MainContent>
    );
  };

  public render() {
    const {campsite: {ActiveForm, ValidationRules, SubmitErrorMessage}, actions, inert} = this.props;
    const {cacheFourEventsNumbers, apiSaving} = this.props.numberProps;
    return (
      <Main
        inert={inert}
        key="numbers"
        mobileBackground="white"
        header={<HeaderProgress loading={apiSaving > 0} c2={this.props.numberProps.cacheTwoEvents} c4={this.props.numberProps.cacheFourEventsNumbers} selected="campsite_assignment"/>}
        footer={<FooterProgress selected="campsite_assignment"/>}
      >
        {this.renderContent()}
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const preferredCampsitesSelector = makePreferredCampsitesSelector();
  const totalNumParticipantsSelector = makeTotalNumParticipantsSelector();
  const filteredEventRegistrationCampsiteAssignmentsSelector = makeFilteredEventRegistrationCampsiteAssignmentsSelector();
  const totalNumParticipants = totalNumParticipantsSelector(state);
  return {
    campsite: state.events.event.register.numbers.campsite,
    preferredCampsites: preferredCampsitesSelector(state),
    YouthSum: totalNumParticipants.YouthSum,
    AdultSum: totalNumParticipants.AdultSum,
    filteredEventRegistrationCampsiteAssignments: filteredEventRegistrationCampsiteAssignmentsSelector(state),
    apiLoading: state.app.apiLoading,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators
}, dispatch)});
const ConnectedCampsiteAssignment = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(CampsiteAssignment);

export default ConnectedCampsiteAssignment;
