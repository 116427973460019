import { CMSResource } from "../models/api/adminCMSCacheOne";
import slug from "./slug";
import Route from 'route-parser';

export const getResourceUrl = (domain: string, resource: CMSResource) => {
  //@todo - it would be an error case if `name` is empty, but we would probably like to capture it?
  const name = resource.Name ? slug(resource.Name) : slug(resource.File ? resource.File.Filename : '');
  return `https://${domain}/files/${resource.ID}/${name}`;
};

export const extractRouteParams = (props: {location: any, routes: any, params: any}) => {
  return {
    location: props.location,
    routes: props.routes,
    params: props.params,
  };
};

const checkParamType = (match: any): boolean => {
  let result = true;
  for (let key of Object.keys(match)) {
    const val = match[key];

    // for any param ends with `Id`, we expected it to be a number
    if (key.endsWith("Id")) {
      try {
        const parsedVal = !val ? NaN : Number(val);

        if (isNaN(parsedVal)) {
          result = false;
        }
      } catch(err) {
        result = false;
      }
    }

    // For other params our expectation is just strings. We could add more restrictions as needed in the future.
  }

  return result;
};

export const isPathnameMatchingRoute = (pathname: string, routePath: string, matchKey?: string, matchValue?: any) => {
  // @todo - currently we are using an external library `route-parser` to match/compare pathname with route.path
  // This is because `react-router v3` doesnt support the [matchPath](https://www.google.com/search?q=react-router+matchpath+api&oq=react-router+matchpath+api&aqs=chrome..69i57j69i60l3.7285j0j9&sourceid=chrome&ie=UTF-8) API yet, we could use that instead when upgrading
  // `react-router` to v4/v5, so that we dont need this external library anymore
  const route = new Route(routePath);
  const match = route.match(pathname);

  if (!match) return false;

  const typeMatch = checkParamType(match);

  if (!matchKey || !matchValue) {
    return !!typeMatch;
  } else if (typeMatch) {
    return match[matchKey] === `${matchValue}`;
  }

  return false;
};

export const getDomain = (relativeDomainWhenPossible:boolean) => {
  if (process.env.NODE_ENV === "development") {
    return "https://cmsdev.tentaroo.com";
  }
  return (relativeDomainWhenPossible) ? "" : `${window.location.protocol}//${window.location.hostname}`;
};

export const shouldTryToLoadDataWithId = (idString: string | undefined, minValueForId: number) => {
  const idNum = !idString ? NaN : Number(idString);

  // We will try to load (i.e. call ensureCache) data whenever its not an optimistic save
  // Any invalid id will be handled by the loader
  return isNaN(idNum) || idNum > 0 || idNum < minValueForId;
};

export const isIdFromUrlValid = (idString?: string) => {
  // Fallback to `NaN` so then `isNaN` can always validate it
  const idNum = !idString ? NaN : Number(idString);
  return !isNaN(idNum) && idNum > 0;
};

export const isIdValid = (id: string | number | undefined): boolean => {
  if (id === undefined) {
    return false;
  } else if (typeof id === "string") {
    return isIdFromUrlValid(id);
  } else {
    return id > 0;
  }
};