import { typeName, Action } from '../../../../../../utils/StrongActions';
import { ActionCreator } from '../../../../../index';

@typeName("EVENT_REGISTER_PARTICIPANT_SELECT_PAGE") export class EventRegisterParticipantSelectPage extends Action {
  constructor(public selectedPage: 'roster' | 'type' | 'classes' | 'confirmation') { super(); }
}
@typeName("EVENT_REGISTER_PARTICIPANT_SET_PENDING_PAGE") export class EventRegisterParticipantSetPendingPage extends Action {
  constructor(public pendingPage: 'roster' | 'type' | 'classes' | 'confirmation') { super(); }
}

@typeName("EVENT_REGISTER_PARTICIPANT_TRANSITION_TO_PENDING") export class EventRegisterParticipantTransitionToPending extends Action {}

@typeName("EVENT_REGISTER_PARTICIPANT_RESET") export class EventRegisterParticipantReset extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  reset: (): ActionCreator => dispatch => dispatch(new EventRegisterParticipantReset()),
  selectPage: (selectedPage: 'roster' | 'type' | 'classes' | 'confirmation'): ActionCreator => dispatch => dispatch(new EventRegisterParticipantSelectPage(selectedPage)),
};
