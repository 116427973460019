import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import * as M from '../../../../../constants/messages/generic';
import { TimeBlock, FacilityPricing } from "../../../../../models/api/adminFacilitiesCacheTwoFacility";
import { TimeAttributeKeys } from "../../../../../utils/dateHelper";
import { getTimePickerHourValidationRule, getTimePickerMinValidationRule, getTimePickerPeriodValidationRule } from "../../../../../store/AdminCMSSite/Pages/Modals/NewPage/validation";
import { FormDefinition as NewFacilityFormDefinition } from '../../../../../store/AdminFacilityLocation/Facilities/Modals/NewFacility/validation';
import { SelectValidator, Validator } from "../../../../../utils/validator/models";
import { ApplicationState } from "../../../..";
import { toNumber } from "../../../../../utils/dataHelper";

export interface IFacilityDetailValidator {
  Name: Validator;
  Description: Validator;
  AllowOnlineBookingID: SelectValidator;
  MaxCapacity: Validator;
  MinBookingLength: Validator;
  MaxBookingLength: Validator;
  RequireEntireWeekend: Validator;
  RequireStartOnSunday: Validator;
  ShowCheckInOutTimes: Validator;
  MinPerPersonFeesToBill: Validator;
  GLAccountID: Validator;
  IsAllDay: Validator;
}

export interface IFacilityValidator {
  ShowDeleted: Validator;
}

const getAllowOnlineBookingValues = (rootState: ApplicationState) => {
  if (!rootState.adminFacilityLocation.cacheOne.AllowOnlineBookingOptions) return [];
  return [...rootState.adminFacilityLocation.cacheOne.AllowOnlineBookingOptions];
};

export const getTimeBlockAttrKey = (index: number, key: TimeAttributeKeys) => {
  return `TimeBlock${index}${key}`;
};

export enum FacilityPricingAttributeKeys {
  PER_DAY_AMOUNT = 'PerDayAmount',
  PER_DAY_AMOUNT_UPFRONT = 'PerDayAmountUpfront',
  PER_PERSON_PER_DAY_AMOUNT = 'PerPersonPerDayAmount',
  PER_PERSON_PER_DAY_AMOUNT_UPFRONT = 'PerPersonPerDayAmountUpfront',
}

export const getFacilityPricingAttrKey = (id: number, key: FacilityPricingAttributeKeys) => {
  return `FacilityPricing${id}${key}`;
};

export const getPricingAttrValidationRule = (key: string): Validator => {
  return {
    key,
    isRequired: () => true,
    decimalOnly: true,
    allowNegative: () => false,
    validatejs: {
      [key]: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: '^' + M.INVALID,
        },
      },
    }
  };
};

export const getTimeBlocksValidationRules = (timeBlocks: TimeBlock[]) => {
  let definition = {};

  if (!timeBlocks) return definition;
  timeBlocks.forEach((tb, index) => {
    definition[getTimeBlockAttrKey(index, TimeAttributeKeys.START_HOUR)] = {
      ...getTimePickerHourValidationRule(getTimeBlockAttrKey(index, TimeAttributeKeys.START_HOUR)),
      defaultValue: () => 0,
    };
    definition[getTimeBlockAttrKey(index, TimeAttributeKeys.START_MIN)] = getTimePickerMinValidationRule(getTimeBlockAttrKey(index, TimeAttributeKeys.START_MIN));
    definition[getTimeBlockAttrKey(index, TimeAttributeKeys.START_PERIOD)] = {
      ...getTimePickerPeriodValidationRule(getTimeBlockAttrKey(index, TimeAttributeKeys.START_PERIOD)),
      defaultValue: () => 1,
    };

    definition[getTimeBlockAttrKey(index, TimeAttributeKeys.END_HOUR)] = getTimePickerHourValidationRule(getTimeBlockAttrKey(index, TimeAttributeKeys.END_HOUR));
    definition[getTimeBlockAttrKey(index, TimeAttributeKeys.END_MIN)] = getTimePickerMinValidationRule(getTimeBlockAttrKey(index, TimeAttributeKeys.END_MIN));
    definition[getTimeBlockAttrKey(index, TimeAttributeKeys.END_PERIOD)] = getTimePickerPeriodValidationRule(getTimeBlockAttrKey(index, TimeAttributeKeys.END_PERIOD));
  });

  return definition;
};

export const getPricingValidationRules = (pricing: FacilityPricing[]) => {
  let definition = {};

  if (!pricing) return definition;
  if (pricing && pricing.length > 0) {
    pricing.forEach((p) => {
      definition[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_DAY_AMOUNT)] = getPricingAttrValidationRule(getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_DAY_AMOUNT));
      definition[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_DAY_AMOUNT_UPFRONT)] = getPricingAttrValidationRule(getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_DAY_AMOUNT_UPFRONT));
      definition[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT)] = getPricingAttrValidationRule(getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT));
      definition[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT_UPFRONT)] = getPricingAttrValidationRule(getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT_UPFRONT));
    });
  }

  return definition;
};

export const FacilityDetailFormDefinition: IFacilityDetailValidator = {
  Name: NewFacilityFormDefinition.Name,
  Description: NewFacilityFormDefinition.Description,
  AllowOnlineBookingID: {
    key: 'AllowOnlineBookingID',
    isRequired: () => true,
    options: {
      values: (rootState) => [...getAllowOnlineBookingValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
    },
    validatejs: {
      AllowOnlineBookingID: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        },
      },
    },
  },
  MaxCapacity: {
    key: 'MaxCapacity',
    defaultValue: () => null,
    integerOnly: true,
    allowNegative: () => false,
    validatejs: {
      MaxCapacity: {
        numericality : {
          notGreaterThan: '^Must be at least 0.',
          greaterThan: -1,
          notValid: '^' + M.INVALID,
        }
      }
    },
  },
  MinBookingLength: {
    key: 'MinBookingLength',
    isRequired: () => true,
    defaultValue: () => 0,
    customValidate: (rootState) => {
      const ActiveForm = rootState.adminFacilityLocation.facilities.facility.form.Detail.ActiveForm;

      const MaxBookingLength = toNumber(ActiveForm.MaxBookingLength);
      const MinBookingLength = toNumber(ActiveForm.MinBookingLength);

      if (MaxBookingLength < MinBookingLength) {
        return 'Minimum booking length is too high.';
      }
      return undefined;
    },
    validatejs: {
      MinBookingLength: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: '^Must be at least 0.',
          greaterThan: -1,
          notLessThan: '^Must be no more than 32767.',
          lessThan: 32768,
          notValid: `^${M.INVALID}`,
        }
      }
    },
  },
  MaxBookingLength: {
    key: 'MaxBookingLength',
    defaultValue: () => 0,
    isRequired: () => true,
    customValidate: (rootState) => {
      const ActiveForm = rootState.adminFacilityLocation.facilities.facility.form.Detail.ActiveForm;

      const MaxBookingLength = toNumber(ActiveForm.MaxBookingLength);
      const MinBookingLength = toNumber(ActiveForm.MinBookingLength);
      
      if (MaxBookingLength < MinBookingLength) {
        return 'Minimum booking length is too high.';
      }
      return undefined;
    },
    validatejs: {
      MaxBookingLength: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: '^Must be at least 0.',
          greaterThan: -1,
          notLessThan: '^Must be no more than 32767.',
          lessThan: 32768,
          notValid: `^${M.INVALID}`,
        }
      }
    },
  },
  RequireEntireWeekend: {
    key: 'RequireEntireWeekend',
  },
  RequireStartOnSunday: {
    key: 'RequireStartOnSunday',
  },
  ShowCheckInOutTimes: {
    key: 'ShowCheckInOutTimes',
  },
  MinPerPersonFeesToBill: {
    key: 'MinPerPersonFeesToBill',
    defaultValue: () => 0,
    isRequired: () => true,
    validatejs: {
      MinPerPersonFeesToBill: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: '^Must be at least 0.',
          greaterThan: -1,
          notLessThan: '^Must be no more than 32767.',
          lessThan: 32768
        }
      }
    },
  },
  GLAccountID: {
    key: 'GLAccountID',
    customValidate: (rootState) => {
      const form = rootState.adminFacilityLocation.facilities.facility.form.Detail.ActiveForm;
      if (!form.GLAccountID) {
        return '';
      } else {
        const GLAccounts = rootState.cacheZero.options ? rootState.cacheZero.options.GLAccounts : [];
        const GLAccount = GLAccounts.find((acc) => acc.ID === form.GLAccountID);
        if (!GLAccount || GLAccount.Inactive) {
          return M.INVALID_SELECTED_GL_ACCOUNT;
        }
      }
      return;
    },
    validatejs: {
      GLAccountID: {
      },
    },
  },
  IsAllDay: {
    key: 'IsAllDay',
    defaultValue: () => false,
  },
};

export const FacilityFormDefinition: IFacilityValidator = {
  ShowDeleted: {
    key: 'ShowDeleted',
  },
};