import * as React from 'react';
import '../../../styles/elements/content-block/index.scss';
import {default as attributes, MarginProps, AlignmentProps, LayoutProps} from '../../../styles';

const namespace = (): string => 'elements--content-block';

interface Props extends MarginProps, AlignmentProps, LayoutProps {
  children?: React.ReactNode;
  className?: string;
}

const ContentBlock: React.SFC<Props> = ({children, className, ...props}: Props): React.ReactElement<Props> => (
  <div className={attributes(namespace(), className, props)}>
    {children}
  </div>
);

export default ContentBlock;
