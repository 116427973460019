import * as React from 'react';
import '../../../../styles/elements/detailed-list-element/index.scss';
import moment from 'moment';
import {displayTimeFrame} from "../../../../utils/dateHelper";
import { isMobile, isMobileAndSmallerScreenSize } from '../../../../utils/isMobile';
import Media from '../../Media';

export const namespace = (): string => 'elements--detailed-list-element';

export interface DetailedListElementProps {
  key: string | number;
  index: number;
  label?: string;
  description?: string;
  descriptions?: string[];
  // must have both dates or neither
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  deleted?: boolean;
  onClick?: (index: number, key: string | number, itemValue: any | undefined) => any;
  onRestore?: React.EventHandler<any>;
  itemValue: any;
  mobileLabels?: string[];
}

const dateString = (startDate?: moment.Moment, endDate?: moment.Moment) => {
  let str = '';
  if (startDate && endDate) str = displayTimeFrame(startDate, endDate);
  return str;
};

class DetailedListElement extends React.Component<DetailedListElementProps, {}> {
  public props: DetailedListElementProps;

  clickElement = () => {
    if (this.props.onClick) this.props.onClick(this.props.index, this.props.key, this.props.itemValue);
  };

  public render() {
    const {label, index, deleted, startDate, endDate, description, descriptions, mobileLabels} = this.props;
    return (
      <div key={index} className={`${namespace()}--list-elem ${deleted ? 'deleted' : ''} ${mobileLabels ? 'mobile-labels' : ''} ${descriptions ? 'mobile-descriptions' : ''}`} onClick={this.clickElement}>
        <div className={`${namespace()}--row-1 ${(startDate || endDate) ? 'has-date' : ''}`}>
          <Media style={{minWidth: 0}} tabletAndGreater>
            <div className={`${namespace()}--label ${(startDate || endDate) ? 'has-date' : ''}`}>{label}</div>
          </Media>
          {mobileLabels && <Media style={{minWidth: 0}} mobileAndSmaller>
            <div style={{display: 'flex', maxWidth: '100%', flexDirection: 'column'}}>{
              mobileLabels.map((l, index) => {
                return (
                  <div key={`mobile_label${index}`} className={`${namespace()}--label ${(startDate || endDate) ? 'has-date' : ''}`}>{l}</div>
                );
              })}
            </div>
          </Media>}
          {(startDate || endDate) && <span className={`${namespace()}--date`}>{dateString(startDate, endDate)}</span>}
        </div>
        <div className={`${namespace()}--row-2 ${descriptions ? 'multiple-columns' : ''}`}>
          {description && <div className={`${namespace()}--description`}>{description}</div>}
          {descriptions && descriptions.map((d, index) => {
            return (
              <div key={`detail_desc_${index}`} className={`${namespace()}--description-item`} style={{maxWidth: `${1.0 * 100 / descriptions.length}%`}}>
                <div>{d}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default DetailedListElement;
