import * as React from 'react';

import { namespace as ns } from './constants';

import '../../../styles/elements/tooltip/tip.scss';

export const namespace = (): string => `${ns()}--tip`;

export interface TipProps {
  attachment: 'top' | 'bottom' | 'right'; // @todo: right scss, currently was unneeded
}

class Tip extends React.PureComponent<TipProps, {}> {
  public props: TipProps;

  public render() {
    return (
      <div className={namespace() + (this.props.attachment === 'bottom' ? ' bottom' : '')}/>
    );
  }
}

export default Tip;
