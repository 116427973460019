import * as Actions from './actions';
import {SUFFIX, LogoutSuccess} from './actions';
import {LoginActions} from '../Login/actions';
import * as Cookies from 'js-cookie';
import {isNode} from '../../utils';
import {apiValidationReducerCreator} from '../Validation/reducerCreator';
import {APISuccessSubmit} from '../Validation/actionCreator';
import {SystemSettings} from '../../models/api/registerForm';
import { setSentryContext } from '../../config/sentry';
import { captureTentarooError } from '../../utils/dataHelper';
import { Action, isActionType } from '../../utils/StrongActions';
import { Reducer } from 'redux';
import { ClearAllCache } from '../App/actions';

export interface SessionState {
  id?: string;
  isValidated?: boolean;
  user_id?: number;
  expiry?: Date;
  SystemSettings?: SystemSettings;
  AllowSelfSignup?: boolean;
  CFID?: string;
  CFTOKEN?: string;
  getLoginFormFailed?: boolean;
}

const checkApiValidation = apiValidationReducerCreator(SUFFIX);
export const cookieName = 'session';

const sendErrorToSentry = () => {
  setSentryContext();
  captureTentarooError(new Error("SystemSettings not returned"));
};

const Session: Reducer<SessionState> = (oldState: SessionState = {}, action: Action) => {
  const state = oldState;
  if (action.type === LoginActions.successType) {
    const castedAction = <APISuccessSubmit> action;
    // @todo: is this a correct "prod" check?
    if (!isNode() && process.env.NODE_ENV === 'development') {
      // @todo: should we use localStorage instead?
      Cookies.set(cookieName, castedAction.response.response.session, {expires: 365});
    }
    return {
      ...state,
      CFID: castedAction.response.response.CFID,
      CFTOKEN: castedAction.response.response.CFTOKEN,
      id: castedAction.response.response.session,
      isValidated: true
    };
  } else if (isActionType(action, Actions.APISuccessGetLoginForm)) {
    if (action.form.SystemSettings) {
      return {
        ...state,
        CFID: action.form.CFID,
        CFTOKEN: action.form.CFTOKEN,
        SystemSettings: action.form.SystemSettings,
        isValidated: true,
        AllowSelfSignup: action.form.AllowSelfSignup,
      };
    } else {
      sendErrorToSentry();
      return state;
    }
  } else if (isActionType(action, Actions.APIFailureGetLoginForm) && !(action.response as any).parseError) {
    const newState = {...state};
    if (action.response && action.response.xhr && action.response.xhr.response && action.response.xhr.response.SystemSettings) {
      const response = action.response.xhr.response;
      newState.SystemSettings = response.SystemSettings;
      newState.AllowSelfSignup = response.AllowSelfSignup;
    } else {
      if (action.response && action.response.xhr && action.response.xhr.status === 401) {
        sendErrorToSentry();
      }
      newState.getLoginFormFailed = true;
      newState.SystemSettings = undefined;
    }
    newState.isValidated = false;
    return newState;
  } else if (
    (isActionType(action, ClearAllCache) && action.clearSession) ||
    isActionType(action, LogoutSuccess)
  ) {
    if (!isNode() && process.env.NODE_ENV === 'development') {
      Cookies.remove(cookieName);
    }
    return {
      ...state,
      id: undefined,
      CFID: undefined,
      CFTOKEN: undefined,
      isValidated: false
    };
  }

  return state || {};
};

export default Session;
