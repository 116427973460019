import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import ActionButton from '../ActionButton';
import { namespace as ns } from './constants';
import '../../../styles/elements/side-modal/header.scss';
import { BackIcon } from '../../Icons';
import { generateDOMId } from '../../../utils/cypressHelper';

export const namespace = (): string => `${ns()}--header`;

interface Props {
  className?: string;
  title?: string;
  subtitle?: string;
  controls?: React.ReactNode;
  mobileControls?: React.ReactNode;
  onlyShowTitleInMobile?: boolean;
  onlyShowSubTitleInMobile?: boolean;
  admin?: boolean;
  customizedBackIcon?: React.ReactNode;
  onBack?: () => any;
}

class Header extends React.PureComponent<Props, {}> {

  public props: Props;

  public onBackClick = () => {
    if (this.props.onBack) this.props.onBack();
  };

  public render() {
    const { title, controls, admin, mobileControls, onBack, subtitle, onlyShowTitleInMobile, onlyShowSubTitleInMobile, customizedBackIcon} = this.props;

    let className = mergeClassNames(namespace(), this.props);
    if (admin) className += ' admin';

    return (
      <header className={className}>
        {onBack && <ActionButton
          id={generateDOMId("side-modal-back-btn")}
          className={`${namespace()}--back ${onlyShowTitleInMobile ? 'only-mobile' : ''}`}
          onClick={this.onBackClick}
          icon={customizedBackIcon ? customizedBackIcon : BackIcon}
        />}
        <div className={`${namespace()}--text-section`}>
          {title && <h1 className={`${namespace()}--text ${onlyShowTitleInMobile ? 'only-mobile' : ''}`}>{title}</h1>}
          {subtitle && <h1 className={`${namespace()}--subtitle ${onlyShowTitleInMobile || onlyShowSubTitleInMobile ? 'only-mobile' : ''}`}>{subtitle}</h1>}
        </div>
        {controls && (<div className={`${namespace()}--controls`}>{controls}</div>)}
        {mobileControls && (<div className={`${namespace()}--mobile-controls`}>{mobileControls}</div>)}
      </header>
    );
  }
}

export default Header;
