import * as React from 'react';
import { Card as MdlCard } from 'react-mdl';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/card/index.scss';
import { default as attributes, MarginProps, LayoutProps, PaddingProps } from '../../../styles';
import Padding from './Padding';
import {namespace} from "./constants";

export enum CardCategory {
  LIST='list',
  LIST_MOBILE='list-mobile',
  CONTENT='content',
}
export interface CardProps extends MarginProps, LayoutProps, PaddingProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  footer?: React.ReactElement<any>;
  component?: React.ReactElement<any> | string;
  faded?: boolean;
  shadow?: number;
  padding?: 'none' | 'regular' | 'small' | 'compact';
  mobile?: boolean;
  selected?: boolean;
  template?: 'mobile-stretch' | 'mobile-no-margin' | 'info' | 'mobile-full'; // @deprecated mobile-full, use layoutContent prop instead
  onClick?: React.EventHandler<any>;
  mobileBottomBorder?: boolean;
  noBoxShadow?: boolean;
  id?: string;
  category?: CardCategory;
}

const Card: React.SFC<CardProps> = ({
  faded, component, shadow, selected, padding, layout, template, footer, children,
  mobileBottomBorder, style, onClick, className, id, noBoxShadow, category,  ...props
}: CardProps): React.ReactElement<CardProps> => {
  className = attributes(namespace(), className, props);
  if (noBoxShadow) className += ' no-box-shadow';
  if (faded) className += ' background-faded';
  if (selected) className += ' selected';
  if (padding) className += ` padding-${padding}`;
  if (template) className += ` ${template}-template`;
  if (layout) className += ` layout-${layout}`;
  if (selected === true || selected === false) className += ` active-effect`;
  if (mobileBottomBorder) className += ` mobile-bottom-border`;
  if (category) className += ` ${category}`;

  let nextChildren = children;
  if (footer) {
    nextChildren = [
      padding === 'none' ? children : <Padding key={0}>{children}</Padding>,
      React.cloneElement(footer, { ...footer.props, key: 1 })
    ];
    if (padding) className += ' no-padding';
  }
  if (onClick) className += ' clickable';

  if (component) {
    let dp: string = '0';
    switch (shadow) {
      case 0: dp = '2'; break;
      case 1: dp = '3'; break;
      case 2: dp = '4'; break;
      case 3: dp = '6'; break;
      case 4: dp = '8'; break;
      case 5: dp = '16'; break;
      case 6: dp = '24'; break;
    }

    if (typeof component === 'string') {
      return React.cloneElement(React.createElement(component) as any, {
        className: `mdl-card mdl-shadow--${dp}dp ${className}`,
        style
      }, nextChildren);
    } else if (component) {
      return React.cloneElement(component, {
        ...component.props,
        className: mergeClassNames(`mdl-card mdl-shadow--${dp}dp ${className}`, component.props),
        style
      }, nextChildren);
    }
  }

  return (
    <MdlCard
      children={nextChildren}
      className={className}
      style={style}
      id={id}
      onClick={onClick}
      shadow={shadow}
    />
  );
};

Card.defaultProps = {
  shadow: 0,
  padding: 'regular'
};

export default Card;
export { default as CardTitle } from './Title';
export { default as CardPadding } from './Padding';
export { default as CardShowMore } from './ShowMore';
export { default as CardActions } from './Actions';
export { default as CardSubTitle } from './SubTitle';
export { default as CardGroup } from './Group';
