import {makeRequestEpic} from "../Validation/epicCreator";
import {getChangePaymentBody, getChangePaymentUrl, getCheckoutCartUrl, URLS} from "../../constants/urls";
import {
  ChangePaymentTypeActions,
  CheckoutSubmitActions,
  SetFullClasses
} from "./actions";
import {Observable} from "rxjs/Rx";
import {push} from "react-router-redux";
import {getMessageFromErrorResponse} from "../../utils";
import { ModalTypes, isModalOpened, popModalObservables, pushModalObservables } from "../../utils/modalHelper";
import { Action } from "redux";
import { NoOp } from "../App/actions";
import { reduxStoreService } from "../service";
import { getDomain } from "../../utils/urlHelper";

export const changePaymentType = makeRequestEpic(
  ChangePaymentTypeActions,
  getChangePaymentUrl,
  getChangePaymentBody,
  true,

);

export const checkoutCart = makeRequestEpic(
  CheckoutSubmitActions,
  getCheckoutCartUrl,
  undefined,
  true,
  undefined,
  undefined,
  undefined,
  undefined,
  true
);

export const checkoutSuccessEpic = action$ => (
  action$.filter(action => action.type && action.type === CheckoutSubmitActions.successType)
    .switchMap((action): Observable<Action> => {
      if (action.response.response.CheckoutMessages) {
        const actions: Observable<Action>[] = [
          ...pushModalObservables({
            modal: ModalTypes.CHECKOUT_MESSAGES,
            saveBefore: false,
            saveAfter: false,
            transformToObservable: true,
          })
        ];
        if (isModalOpened(ModalTypes.SUBMITTING_PAYMENT)) {
          actions.unshift(
            ...popModalObservables({
              modal: ModalTypes.SUBMITTING_PAYMENT,
              saveBefore: false,
              saveAfter: false,
              transformToObservable: true,
            }),
          );
        }

        return Observable.concat(...actions);
      } else {
        const rootState = reduxStoreService().getState();
        const search = rootState.routing.locationBeforeTransitions.search;
        return Observable.concat(
          Observable.of(push(URLS.HOME + search)),
          ...pushModalObservables({
            modal: ModalTypes.ORDER_SUBMITTED,
            saveBefore: false,
            saveAfter: false,
            modalProps: {receiptUrl: rootState.app.viewReceiptUrl},
            transformToObservable: true,
          }),
        );
      }
    }
  )
);

export const checkoutFailureEpic = action$ => (
  action$.filter(action => {
    return (
      action.type === CheckoutSubmitActions.failureType &&
      (
        action.response.status === 400 ||
        action.response.status === 423
      )
    );
  })
    .switchMap((action): Observable<Action> => {
      const actions: Observable<Action>[] = [];
      // always close checkout messages modal if it's opened
      if (isModalOpened(ModalTypes.CHECKOUT_MESSAGES)) {
        actions.push(...popModalObservables({
          modal: ModalTypes.CHECKOUT_MESSAGES,
          saveBefore: false,
          saveAfter: false,
          transformToObservable: true,
        }));
      }
      if (action.response.status === 400) {
        if (action.response.xhr.response.error.Detail === 'Payment Error') {
          actions.push(
            ...pushModalObservables({
              modal: ModalTypes.PAYMENT_FAILED,
              saveBefore: false,
              saveAfter: false,
              modalProps: {
                paymentErrorMessage: action.response.xhr.response.error.Message,
              },
              transformToObservable: true,
            }),
          );
        }

        return Observable.concat(...actions);
      } else if (action.response.status === 423) {
        let url: string = '';
        const rootState = reduxStoreService().getState();

        if (rootState.cacheOne.CartOrder) {
          url = `${getDomain(true)}/FullClasses.cfm?GroupOrder=${rootState.cacheOne.CartOrder.ID}`;
        }

        actions.push(
          Observable.of(new SetFullClasses(getMessageFromErrorResponse(action.response), url)),
        );
        actions.push(
          ...pushModalObservables({
            modal: ModalTypes.FULL_CLASSES,
            saveBefore: false,
            saveAfter: true,
            transformToObservable: true,
          }),
        );
        return Observable.concat(...actions);
      } else {
        return Observable.of(new NoOp());
      }
    })
);
