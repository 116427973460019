
import {AdminEventsCacheTwoEventContext} from "@tentaroo/shared";

import { ToggleNotFound } from "../../store/App/actions";
import { SaveState } from "../../store/Rollback/actions";
import { extractRouteParams, isIdFromUrlValid } from "../urlHelper";
import { GetAdminEvent } from "../../store/AdminEvents/CacheTwoEvent/actions";
import { getEventIdFromPath } from "../helpers/adminEventsPageHelper";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import type AdminEventsCacheManager from "../cacheManagers/adminEventsCacheManager";
import { reduxStoreService } from "../../store/service";
import { LoadAdminEventCacheTwoEventParams } from "./helpers/models";
import {isAdminEventsCacheTwoEventPopulated} from "../cachePopulatedCheckers/adminEvents";


export const ensureAdminEventsCacheTwoEvent = (
  params: LoadAdminEventCacheTwoEventParams & {cacheManager: AdminEventsCacheManager}
): boolean => {
  const { props, isEdit, context, isStateNavigated, cacheManager } = params;

  if (!isIdFromUrlValid(props.params.eventTypeId)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const rootState = reduxStoreService().getState();
  const adminEventsCacheTwoEvent = rootState.adminEvents.cacheTwoEvent;

  if (isStateNavigated && context) {
    cacheManager.initCacheTwoEventNonFormPage(context);
  }

  let requestedData = false;
  if (isEdit) {
    // When we do need event data, eventId must be valid (i.e. > 0)
    if (!isIdFromUrlValid(props.params.eventId)) {
      reduxStoreService().dispatch(new ToggleNotFound());

      return false;
    }
    const eventId = getEventIdFromPath(props.params);

    if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminEventsCacheTwoEventPopulated(adminEventsCacheTwoEvent)) {
      if (isStateNavigated) {
        if (
          context === AdminEventsCacheTwoEventContext.TIMEBLOCKS_TAB ||
          context === AdminEventsCacheTwoEventContext.SETTINGS
        ) {
          reduxStoreService().dispatch(GetAdminEvent.updateForm());
        }
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      reduxStoreService().dispatch(GetAdminEvent.request(
        {
          EventIDi: eventId,
          ...extractRouteParams(props),
        }, {
          optimisticLoad: true,
          skipUpdateForm: (
            context !== AdminEventsCacheTwoEventContext.SETTINGS &&
            context !== AdminEventsCacheTwoEventContext.TIMEBLOCKS_TAB
          )
        }));

      requestedData = true;
    }
  }

  return requestedData;
};