import * as React from 'react';

import {
  Button, FinancialSummary, FinancialSummaryLine, FinancialSummaryTotal, FinancialSummaryOption,
  FinancialSummaryActions, FinancialSummaryDetails, FinancialSummaryOptions, ContentBlock
} from '../../../../Elements';
import { CartIcon } from '../../../../Icons';
import {standardCurrencyFormat} from "../../../../../utils/classesHelper";
import {checkPermission} from "../../../../../utils/permissionHelper";
import {ShowTopFloatingAlert} from "../../../../../store/App/actions";
import {CartOrderItem} from "../../../../../models/api/cacheOne";
import { reduxStoreService } from '../../../../../store/service';
import { Validator } from '../../../../../utils/validator/models';

export interface FinancialSummaryProps {
  // EventRegistrationPaymentStatus: EventRegistrationPaymentStatus;
  fees: Array<{label: string; value: number;}>;
  paymentStatus: {
    AmountMin: number;
    AmountMax: number;
    AmountInCart: number;
    AmountPaid: number;
  };

  title?: string;
  showLoaders?: boolean;
  selected: 'min' | 'credit' | 'full' | 'other';
  isFinanceDirty?: boolean;
  onSelect: (value: any, vObj: Validator) => void;
  onSaveOther?: (value: number) => void;
  updateValue: (value: any, vObj: Validator) => void;
  simpleUpdate: (value: any, vObj: Validator) => void;
  onCancelOther?: (e?:any) => void;
  validationRules: any;
  isSaving?: boolean;
  otherValue?: number;
  IsAdmin?: boolean;
  isWizard?: boolean;
  onCheckout?: () => void;
  hasAccess: boolean;
  hasAccessMessage: string | null | undefined;
  CartOrderItems?: Array<CartOrderItem> | null;
}

class FinancialSummaryComponent extends React.Component<FinancialSummaryProps, {}> {

  private setPaymentAmount = (value: any) => {
    const {selected, onSelect, validationRules, hasAccess, hasAccessMessage} = this.props;
    checkPermission(
      () => {
        if (value !== selected) onSelect(value, validationRules.selectedFinance);
      },
      hasAccess,
      hasAccessMessage
    );
  };

  onCheckout = () => {
    const {onCheckout, hasAccess, hasAccessMessage} = this.props;
    if (onCheckout) {
      checkPermission(
        () => {
          onCheckout();
        },
        hasAccess,
        hasAccessMessage
      );
    }
  };

  onClickSave = (value: number) => {
    const {selected, onSaveOther, onCancelOther, hasAccess, hasAccessMessage} = this.props;
    if (hasAccess && onSaveOther) {
      onSaveOther(value);
    } else {
      if (onCancelOther) onCancelOther();
      if (hasAccessMessage) {
        reduxStoreService().dispatch(new ShowTopFloatingAlert(hasAccessMessage, false, 'orange'));
      }
    }
  };

  public render() {
    const {
      IsAdmin, isFinanceDirty, onSaveOther, validationRules, updateValue, simpleUpdate, isSaving, otherValue, onCancelOther,
      isWizard, selected, title, onCheckout, CartOrderItems,
      // EventRegistrationPaymentStatus: {RegistrationTotal, ClassAmount, AmountMin, AmountMax, AmountInCart, AmountPaid}
      paymentStatus: {AmountMin, AmountMax, AmountInCart, AmountPaid}, fees
    } = this.props;
    const loading = this.props.showLoaders;
    let total = AmountMax - AmountInCart;
    if (isWizard) {
      if (selected === 'min') {
        total = AmountMax - AmountMin;
      } else if (selected === 'credit') {
        total = AmountMax;
      } else if (selected === 'full') {
        total = 0;
      } else if (selected === 'other' && otherValue !== undefined) {
        total = AmountMax - otherValue;
      }
    }

    const showMin = AmountMin !== AmountMax || AmountMin < 0;
    const showCredit = AmountMin < 0 && AmountMax > 0;
    const showMax = AmountMin !== AmountMax || AmountMax >= 0;
    const showOther = AmountMin !== AmountMax || AmountMin !== AmountInCart || selected === 'other' || IsAdmin;

    const maxPaymentLabel = AmountMax === 0 ? 'No balance' : (AmountMax >= 0 ? 'Pay full amount' : 'Apply credit to balance');
    /*
    <FinancialSummaryLine label="Registration Fees" value={standardCurrencyFormat(RegistrationTotal)}/>
    <FinancialSummaryLine label={optionsLabel ? 'Options' : 'Classes'} value={standardCurrencyFormat(ClassAmount)}/>
    */

    let disableCheckout = false;
    if (isFinanceDirty) {
      disableCheckout = true;
    } else if (CartOrderItems !== undefined && (CartOrderItems === null || CartOrderItems.length === 0)) {
      disableCheckout = true;
    }

    return (
      <ContentBlock>
        <FinancialSummary style={{ zIndex: 0 }} title={title}>
          <FinancialSummaryDetails
            total={<FinancialSummaryTotal value={standardCurrencyFormat(AmountMax)}/>}
            toggle={!isWizard}
          >
            {fees.map((fee) => <FinancialSummaryLine disabled={false} label={fee.label} value={standardCurrencyFormat(fee.value)}/>)}
            <FinancialSummaryLine disabled={false} label={'Applied Payments'} value={standardCurrencyFormat(AmountPaid*-1)}/>
          </FinancialSummaryDetails>
          <FinancialSummaryOptions>
            {showMin && <FinancialSummaryOption
              label={AmountMin < 0 ? 'Add credit to account' : 'Pay minimum required amount'}
              amount={standardCurrencyFormat(AmountMin)}
              value="min"
              selected={selected === 'min'}
              onChange={this.setPaymentAmount}
            />}
            {showCredit && <FinancialSummaryOption
              label="Apply credit to balance"
              amount={standardCurrencyFormat(0)}
              value="credit"
              selected={selected === 'credit'}
              onChange={this.setPaymentAmount}
            />}
            {showMax && <FinancialSummaryOption
              label={maxPaymentLabel}
              amount={standardCurrencyFormat(AmountMax)}
              value="full"
              selected={selected === 'full'}
              onChange={this.setPaymentAmount}
            />}
            {showOther && <FinancialSummaryOption
              wrapMoneyField
              marginBottom={selected === 'other' ? 8 : 16}
              label="Pay other amount"
              amount={`Not less than ${standardCurrencyFormat(AmountMin)}`}
              amountGray
              editable
              maxMoneyFieldWidth='120px'
              value="other"
              selected={selected === 'other'}
              requiresSave={selected === 'other' && isFinanceDirty}
              otherValidationRules={validationRules.otherValue}
              onSave={this.onClickSave}
              onCancel={onCancelOther}
              updateValue={updateValue}
              simpleUpdate={simpleUpdate}
              isSaving={isSaving}
              otherValue={otherValue}
              isWizard={isWizard}
              onChange={this.setPaymentAmount}
            />}
          </FinancialSummaryOptions>
          <FinancialSummaryTotal noMarginBottom={isWizard} title="New Balance" value={standardCurrencyFormat(total)}/>
          {onCheckout && <FinancialSummaryActions>
            <Button icon={CartIcon} color="green" big loading={loading} disabled={disableCheckout} onClick={this.onCheckout}>
              Checkout
            </Button>
          </FinancialSummaryActions>}
        </FinancialSummary>
      </ContentBlock>
    );
  }
}

export default FinancialSummaryComponent;
