import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/num/index.scss';

export const namespace = (): string => 'elements--num';

export interface NumProps {
  children?: React.ReactNode;
  className?: string;
}

const Num: React.SFC<any> = (props: NumProps): React.ReactElement<NumProps> => (
  <i className={mergeClassNames(namespace(), props)}>
    {props.children}
  </i>
);

export default Num;
