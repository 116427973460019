import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra
} from '../../../../Validation/actionCreator';
import { ActionCreator, ApplicationState } from '../../../../';
import { SiteMenuItemActiveForm } from '.';
import { getSubmitSiteMenuBody, getDeleteMenuItemBody, getSiteMenusRootUrl, getPageMenusRootUrl } from '../../../../../constants/urls';
import { SaveState } from '../../../../../store/Rollback/actions';
import { ShowTopFloatingAlert } from '../../../../../store/App/actions';
import { navPush } from '../../../../../utils';
import { INVALID_MENU_LEVEL } from '../../../../../constants/messages/adminCMS';
import { ADMIN_CMS_MODULE_SUFFIX } from '../../../../../store/AdminCMSSite/CacheOne/actions';
import { scrollSideModalToTop } from '../../../../../utils/helpers/adminCMSPageHelper';
import { isMobileAndSmallerScreenSize } from '../../../../../utils/isMobile';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../../../../utils/suffix';
import { PlainRoute } from 'react-router';
import { ModalTypes, isModalOpened } from '../../../../../utils/modalHelper';
import { appActionCreators } from '../../../../App';
import { typeName, Action } from '../../../../../utils/StrongActions';

export const UPDATE_CMS_SITE_MENU_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__CMS_SITE_MENU';
export const UPDATE_CMS_PAGE_MENU_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__CMS_PAGE_MENU';
export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__SITE_MENU_ITEM_FORM';
export const DEL_SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__DELETE_SITE_MENU_ITEM';
export const PAGE_MENU_SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__PAGE_MENU_ITEM_FORM';
export const PAGE_MENU_DEL_SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__DELETE_PAGE_MENU_ITEM_FORM';

@typeName('INIT_FORM_FOR_EDIT_PAGE' + SUFFIX)
export class SiteMenuItemInitForEditPageForm extends Action {
  constructor(public menuItemId?: number) {super();}
}

@typeName('UPDATE_SELECTED_PAGE' + SUFFIX)
export class UpdateSelectedPage extends Action {
  constructor(public pageId: number) { super(); }
}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const AddSiteMenuItemSubmitActions = createApiSubmitActions(SUFFIX + UPDATE_CMS_SITE_MENU_FORM_SUFFIX);
export const AddPageMenuItemSubmitActions = createApiSubmitActions(PAGE_MENU_SUFFIX + UPDATE_CMS_PAGE_MENU_FORM_SUFFIX);
export const DeleteSiteMenuItemSubmitActions = createApiSubmitActions(DEL_SUFFIX + UPDATE_CMS_SITE_MENU_FORM_SUFFIX);
export const DeletePageMenuItemSubmitActions = createApiSubmitActions(PAGE_MENU_DEL_SUFFIX + UPDATE_CMS_PAGE_MENU_FORM_SUFFIX);

export const MenuItemSideModalNamespace = (): string => 'pages--cms--site-menu-item';

export const formCreator = (activeForm: SiteMenuItemActiveForm) => {
  return getSubmitSiteMenuBody({}, activeForm);
};

export type Actions = typeof actionCreators;

export const scrollMenuItemSidebarToTop = () => {
  scrollSideModalToTop(MenuItemSideModalNamespace());
};

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.menus.siteMenuItem.form),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  updateSelectedPage: (pageId: number): ActionCreator => dispatch => dispatch(new UpdateSelectedPage(pageId)),
  apiSubmitMenuItemForm: (router: any, pageMenu?: boolean): ActionCreator => (dispatch, getState) => {
    const state = getState();
    // form validation
    let valid = innerApiSubmitFormMethod(
      dispatch,
      pageMenu ? AddPageMenuItemSubmitActions : AddSiteMenuItemSubmitActions,
      (s: ApplicationState) => s.adminCMSSite.menus.siteMenuItem.form,
      undefined,
      undefined,
      true,
      true,
      undefined,
      undefined,
    );

    if (!pageMenu && state.adminCMSSite.cacheOne.CMSSiteMenuItems && state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.ID && state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.MenuLevel === 2) {
      // if the site menu item is updated to level 2, but already has any active children under it
      state.adminCMSSite.cacheOne.CMSSiteMenuItems.forEach((item) => {
        if (item.ParentID === state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.ID) {
          valid = false;
          dispatch(new ShowTopFloatingAlert(INVALID_MENU_LEVEL, isMobileAndSmallerScreenSize(), 'orange'));
        }
      });
    }

    if (valid) {
      const body = formCreator(state.adminCMSSite.menus.siteMenuItem.form.ActiveForm);
      if (state.adminCMSSite.cacheOne.CMSSite) {
        if (pageMenu) {
          navPush(router, getPageMenusRootUrl({siteId: state.adminCMSSite.cacheOne.CMSSite.ID, domain: state.adminCMSSite.cacheOne.CMSSite.SiteDomain}));
        } else {
          navPush(router, getSiteMenusRootUrl({siteId: state.adminCMSSite.cacheOne.CMSSite.ID, domain: state.adminCMSSite.cacheOne.CMSSite.SiteDomain}));
        }
      }
      if (pageMenu) {
        dispatch(AddPageMenuItemSubmitActions.request(
            body,
            getCacheLevelExtra(
              true,
              !!state.adminCMSSite.cacheTwoPageMenuItems.CMSPageMenuItem
            ),
          )
        );
      } else {
        dispatch(AddSiteMenuItemSubmitActions.request(
            body,
            getCacheLevelExtra(
              true,
              !!state.adminCMSSite.cacheTwoSiteMenuItems.CMSSiteMenuItem
            ),
          )
        );
      }
    } else {
      scrollMenuItemSidebarToTop();
    }
  },
  deleteMenuItem: (menuItemId: number, restore: boolean, pageMenu: boolean, routes: PlainRoute<any>[], router: any): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    const route = routes[routes.length - 1];

    const pageMenuFormExtra = getCacheLevelExtra(
      true,
      !!state.adminCMSSite.cacheTwoPageMenuItems.CMSPageMenuItem,
    );
    const siteMenuFormExtra = getCacheLevelExtra(
      true,
      !!state.adminCMSSite.cacheTwoSiteMenuItems.CMSSiteMenuItem,
    );

    if (isModalOpened(ModalTypes.PAGE_MENU_ITEM_FORM)) {
      // Only do pop modal here, otherwise, navigation will close it
      dispatch(appActionCreators.popModal(true, false, ModalTypes.DELETE_MENU_ITEM) as any);
      dispatch(appActionCreators.popModal(false, false, ModalTypes.PAGE_MENU_ITEM_FORM) as any);
    } else if (state.adminCMSSite.cacheOne.CMSSite && router) {
      dispatch(new SaveState());
      if (pageMenu) {
        navPush(router, getPageMenusRootUrl({siteId: state.adminCMSSite.cacheOne.CMSSite.ID, domain: state.adminCMSSite.cacheOne.CMSSite.SiteDomain}));
      } else {
        navPush(router, getSiteMenusRootUrl({siteId: state.adminCMSSite.cacheOne.CMSSite.ID, domain: state.adminCMSSite.cacheOne.CMSSite.SiteDomain}));
      }
    }

    const body = getDeleteMenuItemBody({
      menuItemId,
    }, !restore);
    if (pageMenu) {
      dispatch(DeletePageMenuItemSubmitActions.request(body, pageMenuFormExtra, route));
    } else {
      dispatch(DeleteSiteMenuItemSubmitActions.request(body, siteMenuFormExtra, route));
    }
  },
};