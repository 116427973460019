import {E_REGISTER, EVENT, eventProductsBody, eventProductsSaveBody, ProductsLoadParams} from "../../constants/urls";
import {ActionCreator, ApplicationState} from "../index";
import {createApiSubmitActions, createRequestActions} from "../Validation/actionCreator";
import {composeOrderedProducts} from "../CacheFourEventsNumbers/actions";
import {validateAll} from "../../utils/validator";
import {getTotalAmount} from "../../utils/helpers/financialSummaryHelper";
import {push} from "react-router-redux";
import {SaveState} from "../Rollback/actions";
import {ApiSubmitProductsConfirmationActions} from "../Events/Event/Register/Products/Confirmation/actions";
import { captureTentarooError } from "../../utils/dataHelper";

export const SUFFIX = '__CACHE_FOUR_EVENTS_PRODUCTS';
export const EventProductsCalc = createRequestActions(SUFFIX);
export const SUFFIX_SAVE = '__CACHE_FOUR_EVENTS_PRODUCTS_SAVE';
export const EventProductsSave = createApiSubmitActions(SUFFIX_SAVE);


export type Actions = typeof actionCreators;

export const actionCreators = {
  productsLoad: (params: ProductsLoadParams): ActionCreator => dispatch => {
    dispatch(EventProductsCalc.request(eventProductsBody(params)));
  },
  // todo: recalc will likely have some extra params
  productsRecalc: (params: ProductsLoadParams): ActionCreator => dispatch => {
    dispatch(EventProductsCalc.request(eventProductsBody(params)));
  },
  productsSave: (): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const rootState = getState();
    const c4 = rootState.cacheFourEventsProducts;
    const confirmationValidation = validateAll((s: ApplicationState) => s.events.event.register.products.confirmation);

    if (confirmationValidation) {
      dispatch(ApiSubmitProductsConfirmationActions.clientFailure(confirmationValidation, false));
      return;
    }

    const options = rootState.cacheZero.options;
    const eventTypeID = rootState.cacheTwoEvents.eventTypeID;
    const eventID = rootState.cacheThreeEvents.eventID;
    // const GroupWeekTS = reduxStoreService().getState().cacheThreeEvents.EventRegistrationNumbers.GroupWeekTS;

    if (c4.EventProductsOrdered === undefined) {
      captureTentarooError(new Error("c4.EventProductsOrdered not available when performing product save."));
      return;
    }
    if (c4.EventProductsOrdered_Updates === undefined) {
      captureTentarooError(new Error("c4.EventProductsOrdered_Updates not available when performing product save."));
      return;
    }
    if (!c4.EventRegistrationPaymentStatus) {
      captureTentarooError(new Error("c4.EventRegistrationPaymentStatus not available when performing product save."));
      return;
    }
    if (!options) {
      captureTentarooError(new Error("options not available when performing product save."));
      return;
    }
    if (!options.Group) {
      captureTentarooError(new Error("Group not available when performing product save."));
      return;
    }
    const products = composeOrderedProducts(c4.EventProductsOrdered, c4.EventProductsOrdered_Updates);


    let newAmount: undefined | number = getTotalAmount(
      getState().events.event.register.products.confirmation.ActiveForm,
      c4.EventRegistrationPaymentStatus
    );

    const params: any = {
      GroupIDi: options.Group.IDi,
      GroupTS: options.Group.TS,
      EventTypeID: eventTypeID,
      EventIDi: eventID,
      GroupWeekIDi: c4.EventRegistrationPaymentStatus.IDi,
      ProductOrders: products,
      // GroupWeekTS: GroupWeekTS
    };
    if (newAmount !== undefined) {
      params.TotalAmountChange = newAmount - c4.EventRegistrationPaymentStatus.AmountInCart;
    }

    const route = getState().routing.locationBeforeTransitions;

    // /admin2/events/4/12404/2019-Week-2/registration
    // /admin2/events/4/12404/2019-Week-2/register/products

    const body = eventProductsSaveBody(params);
    const newPath = route.pathname.replace(`${EVENT.REGISTER}/${E_REGISTER.PRODUCTS}`, EVENT.REGISTRATION);
    dispatch(push(`${newPath}${route.search}`));
    dispatch(EventProductsSave.request(body, null));
  }
};
