import { ActionCreator, ApplicationState } from '../../';
import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, createApiSubmitFormMethod, createRequestActions
} from '../../Validation/actionCreator';
import { ActiveForm } from './index';
import {SaveState} from "../../Rollback/actions";
import {deleteGroupBody} from '../../../constants/urls';
import { typeName, Action } from '../../../utils/StrongActions';
import { captureTentarooError, captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../utils/dataHelper';
import { getSendResetLinkBody } from '../../../constants/resetPasswordUrls';

export const SUFFIX = '__EDIT_PROFILE';
export const DELETE_SUFFIX = '__DELETE_EDIT_PROFILE';

@typeName('RESET_FORM' + SUFFIX)
export class ProfileResetForm extends Action {
  constructor(public rootState: ApplicationState) { super(); }
}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ApiSubmitActions = createApiSubmitActions(SUFFIX);
export const ApiSubmitDeleteActions = createApiSubmitActions(DELETE_SUFFIX);
export const ResetPasswordUpdateGroupSendResetLinkSubmitActions = createRequestActions("UPDATE_GROUP_SEND_RESET_LINK", true);

@typeName('INITIALIZE' + SUFFIX)
export class ProfileInitialize extends Action {
  constructor(public rootState: ApplicationState) { super(); }
}

export type Actions = typeof actionCreators;

const valueOrEmpty = (value) => {
  return value === undefined ? '' : value;
};

const formCreator = (rootState: ApplicationState, activeForm: ActiveForm) => {
  let debugPayload: TentarooDebugPayload | undefined;
  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when creating ProfileForm body");
  }
  if (!rootState.cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when creating ProfileForm body");
  }
  const payload = {
    row: {
      IDi: rootState.cacheZero.options?.Group?.IDi,
      TS: rootState.cacheZero.options?.Group?.TS,

      Username: activeForm.Username,
      Password: rootState.cacheZero.options?.Group?.Password,
      Email: activeForm.Email,

      GroupTypeID: activeForm.GroupTypeID,
      UnitTypeID: activeForm.UnitTypeID,
      UnitGenderID: activeForm.UnitGenderID,
      Unit: valueOrEmpty(activeForm.Unit),
      CouncilIDi: activeForm.CouncilIDi,
      DistrictIDi: activeForm.DistrictIDi,

      Suffix: valueOrEmpty(activeForm.Suffix),
      FirstName: activeForm.FirstName,
      LastName: valueOrEmpty(activeForm.LastName),

      PhoneNumber: valueOrEmpty(activeForm.PhoneNumber),
      PhoneNumber2: valueOrEmpty(activeForm.PhoneNumber2),
      Address: valueOrEmpty(activeForm.Address),
      Address2: valueOrEmpty(activeForm.Address2),
      StateID: valueOrEmpty(activeForm.StateID),
      City: valueOrEmpty(activeForm.City),
      Zip: valueOrEmpty(activeForm.Zip),

      Contact2Name: valueOrEmpty(activeForm.Contact2Name),
      Contact2Phone: valueOrEmpty(activeForm.Contact2Phone),
      Contact2Email: valueOrEmpty(activeForm.Contact2Email),

      AllowOnlineBooking: !!activeForm.AllowOnlineBooking,
      UnitSponsor: valueOrEmpty(activeForm.UnitSponsor),
      Organization: valueOrEmpty(activeForm.Organization),
      PricingTypeID: valueOrEmpty(activeForm.PricingTypeID),
      FacilityPricingTypeID: valueOrEmpty(activeForm.FacilityPricingTypeID),
      MI: valueOrEmpty(activeForm.MI),
      MarketingSource: valueOrEmpty(activeForm.MarketingSource),
      Inactive: false
    }
  };

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  apiSubmitForm: createApiSubmitFormMethod(ApiSubmitActions, (s) => s.settings.profile, formCreator),
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.settings.profile),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  resetForm: (): ActionCreator => (dispatch, getState) => {
    dispatch(new ProfileResetForm(getState()));
  },
  apiDeleteGroup: (form: { IDi: number, restore?: boolean }): ActionCreator => dispatch => {
    dispatch(new SaveState());
    dispatch(ApiSubmitDeleteActions.request(deleteGroupBody(form.IDi, !!form.restore), null));
  },
  sendResetLink: (): ActionCreator => (dispatch, getState) => {
    const rootState = getState();
    if (!rootState.cacheZero.options || !rootState.cacheZero.options.Group) {
      captureTentarooError(new Error("cacheZero.options.Group not available when sendResetLink"));
      return;
    }
    const Group = rootState.cacheZero.options.Group;
    dispatch(ResetPasswordUpdateGroupSendResetLinkSubmitActions.request(getSendResetLinkBody(Group.IDi)));
  },
};
