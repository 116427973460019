import * as React from 'react';
import { bindActionCreators } from 'redux';
import {withRouter, RouteComponentProps, WithRouterProps} from "react-router";
import { actionCreators } from "../../../../../store/Facilities/Trip/Reservation/actions";
import { actionCreators as reservationActionCreators } from "../../../../../store/Facilities/Trip/Reservation/Reservation/actions";
import { actionCreators as cacheFourActionCreators } from "../../../../../store/CacheFourFacilities/actions";
import { RegisterFooterProgress } from '../../../../Elements';
import { Step } from '../../../../Elements/Register/FooterProgress';
import {isUrlAddReservation, ReservationPage} from "../../../../../store/Facilities/Trip/Reservation";
import {makeSelectedFacilityTypeC4Selector} from "../../../../../store/CacheFourFacilities";
import { ApplicationState } from '../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {locId: string; tripId: string; name: string; resId?: string; resName?: string;}>;

export interface FooterProgressProps {
  selected: ReservationPage;
}

const FooterProgress: React.SFC<FooterProgressProps> = ({
  cacheThreeFacilities,
  cacheFourFacilities,
  selectedFacilityType,
  apiSaving,
  apiLoading,
  selected,
  actions,
  router,
  params,
  routes,
  reservation
}: FooterProgressProps & ConnectedProps):React.ReactElement<FooterProgressProps> => {
  let steps: Array<Step> = [];
  const route = routes[routes.length - 1];
  if (isUrlAddReservation(route.path!)) {
    steps.push({ selected: selected === 'type', onClick: () => actions.selectPage('type')});
  }
  steps.push({ selected: selected === 'reservation', onClick: () => actions.selectPage('reservation')});
  steps.push({ selected: selected === 'confirmation', onClick: () => actions.selectPage('confirmation')});

  let onNextClick, onBackClick, backParam, nextParam;
  if (selected === 'type') {
    // should never happen, doesn't have a footer!
  } else if (selected === 'reservation') {
    if (isUrlAddReservation(route.path!)) {
      onBackClick = () => actions.selectPage('type');
    }
    let resId;
    if (params && params.resId) {
      resId = Number(params.resId);
    }
    onNextClick = () => actions.reservationSubmit(resId);

  } else if (selected === 'confirmation') {
    onBackClick = () => actions.selectPage('reservation');
  }


  return <RegisterFooterProgress
    loading={apiSaving > 0 || apiLoading > 0}
    onComplete={actions.facilitiesSave}
    onBackClick={onBackClick}
    backParam={backParam}
    onNextClick={onNextClick}
    nextParam={nextParam}
    steps={steps}
  />;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedFacilityType = makeSelectedFacilityTypeC4Selector();
  return {
    cacheThreeFacilities: state.cacheThreeFacilities,
    cacheFourFacilities: state.cacheFourFacilities,
    selectedFacilityType: selectedFacilityType(state),
    reservation: state.facilities.trip.reservation.reservation,
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...reservationActionCreators,
  ...cacheFourActionCreators
}, dispatch) });

const ConnectedFooterProgress = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<FooterProgressProps & WithRouterProps>(),
)(FooterProgress);

export default withRouter<FooterProgressProps>(ConnectedFooterProgress);
