import * as React from 'react';
import '../../../../styles/elements/month/compact/index.scss';

export const namespace = (): string => 'elements--compact-month';

export interface Props {
}

const fakeMonthArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];

const CompactMonthSkeleton: React.SFC<Props> = ({ }: Props): React.ReactElement<Props> => {
  return (
    <div className={`${namespace()} skeleton`}>
      <div className={`${namespace()}--days`}>
        <ul className={`${namespace()}--days--list`}>
          {fakeMonthArray.map((value, index) => {
            return (
              <li
                key={index}
                className={`${namespace()}--days--list--day`}
              >
                <span className={`${namespace()}--days--list--day--day-of-week`}>
                </span>
                <span className={`${namespace()}--days--list--day--day-of-month`}>
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CompactMonthSkeleton;