import { ApplicationState } from '../../..';
import * as M from '../../../../constants/messages/generic';
import { makeDeletedPrefixItemMapper } from "../../../../utils/dataHelper";
import { SelectValidator, Validator } from '../../../../utils/validator/models';

export const getFacilityTypeValues = (rootState: ApplicationState) => {
  const types = rootState.adminFacilityLocation.cacheOne.FacilitiesTypes;
  if (types) {
    return types
    .filter((type) => {
      // filter by inactive, and if inactive, but already selected, still return it
      return !type.Inactive ? true : (type.ID === rootState.adminFacilityLocation.facilities.home.ActiveForm.SelectedFacilityTypeID);
    })
    .map(makeDeletedPrefixItemMapper('NamePlural'))
    .sort((a, b) => a.Inactive ? -1 : (b.Inactive ? 1 : 0));
  }
  return [];
};

export interface IValidator {
  ShowDeleted: Validator;
  FilterText: Validator;
  SelectedFacilityTypeID: SelectValidator;
  FacilityTypeShowDeleted: Validator;
  FacilityTypeFilterText: Validator;
}

export const FormDefinition: IValidator = {
  ShowDeleted: {
    key: 'ShowDeleted',
    isRequired: () => false,
    defaultValue: () => false,
  },
  FilterText: {
    key: 'FilterText',
    isRequired: () => false,
    defaultValue: () => '',
  },
  SelectedFacilityTypeID: {
    key: 'SelectedFacilityTypeID',
    options: {
      values: (rootState) => [{ NamePlural: 'All Facilities', ID: 0 }, ...getFacilityTypeValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'NamePlural'
    },
    customValidate: (rootState) => {
      const form = rootState.adminFacilityLocation.facilities.home.ActiveForm;

      const facilityType = rootState.adminFacilityLocation.cacheOne.FacilitiesTypes ? rootState.adminFacilityLocation.cacheOne.FacilitiesTypes.find((ft) => ft.ID === form.SelectedFacilityTypeID) : null;

      if (facilityType && facilityType.Inactive) {
        return M.SELECTED_DROPDOWN_ITEM_DELETED;
      }
    },
    defaultValue: () => {
      return 0;
    },
  },
  FacilityTypeShowDeleted: {
    key: 'FacilityTypeShowDeleted',
    isRequired: () => false,
    defaultValue: () => false,
  },
  FacilityTypeFilterText: {
    key: 'FacilityTypeFilterText',
    isRequired: () => false,
    defaultValue: () => '',
  }
};