import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../../../styles/pages/facilities/trip/summary/empty-reservation.scss';
import { StepProgressBar, StepProgressBarStep } from '../../../../../components/Elements';

export const namespace = 'elements--empty-reservation';

export interface EmptyReservationProps {
  className?: string;
  actions?: React.ReactNode;
}

const EmptyReservation: React.SFC<EmptyReservationProps> = (props: EmptyReservationProps): React.ReactElement<EmptyReservationProps> => {
  let className = mergeClassNames(namespace, props);
  const {actions} = props;

  return (
    <section className={className}>
      <div className={`${namespace}--content`}>
        <StepProgressBar vertical>
          <StepProgressBarStep vertical largeNum numAlignment='start' selected={false} done num={1}>
            <div className={`${namespace}--step--content header crossed`}>Create a trip</div>
          </StepProgressBarStep>
          <StepProgressBarStep vertical largeNum numAlignment='start' selected={true} done={false} num={2}>
            <div className={`${namespace}--step--content header`}>Add a reservation</div>
            <div className={`${namespace}--step--content`}>Now that you’ve created your trip, please add reservations for specific facilities.</div>
            <div className={`${namespace}--actions`}>
              {props.actions}
            </div>
          </StepProgressBarStep>
          <StepProgressBarStep vertical largeNum numAlignment='start' selected={false} done={false} num={3}>
            <div className={`${namespace}--step--content header`}>Complete the checkout</div>
            <div className={`${namespace}--step--content`}>Your booking will not be complete until you add reservations to this trip and checkout.</div>
          </StepProgressBarStep>
        </StepProgressBar>
      </div>
    </section>
  );
};

export default EmptyReservation;
