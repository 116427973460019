import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../../../store';
import { bindActionCreators } from 'redux';
import {
  actionCreators as cacheZeroActionCreators
} from '../../../../../../../store/CacheZero/actions';
import { actionCreators as appActionCreators } from '../../../../../../../store/App/actions';
import { actionCreators as cacheThreeClassActionCreators } from '../../../../../../../store/AdminEvents/CacheThreeClass/actions';
import { actionCreators as classFormActionCreators } from '../../../../../../../store/AdminEvents/Classes/Form/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../../store/Rollback/actions';
import { actionCreators } from '../../../../../../../store/AdminEvents/Events/Event/Classes/actions';
import { Button, AdminClassCard, EmptyMessage, PageLoader } from '../../../../../../../components/Elements';
import '../../../../../../../styles/pages/admin-events/events/classes/classes-tab/index.scss';
import { navPush } from '../../../../../../../utils';
import { makeFilteredAdminEventClassesSelector, makeActiveAdminEventClassesSelector } from '../../../../../../../store/AdminEvents/Events/Event/Classes';
import { Main, MainContent } from '../../../../../../Layouts';
import ClassesFilters from './Filters';
import { AdminEventClass } from '../../../../../../../models/api/adminEventsCacheTwoEvent';
import { ENTITY_NOT_ADDED, ENTITY_NOT_FOUND } from '../../../../../../../constants/messages/adminCMS';
import { ADMIN_EVENT_CLASSES } from '../../../../../../../constants/messages/adminEvents';
import { AdminClassCardType } from '../../../../../../Elements/AdminClass/Card';
import { CardCategory } from '../../../../../../Elements/Card';
import { EmptyMessageType } from '../../../../../../Elements/EmptyMessage';
import { ClassTypesIcon } from '../../../../../../Icons';
import { ImportFromEventType } from '../../../Modals/ImportFromEvent';
import { ADMIN_EVENT_NEW_CLASS_PATH, ADMIN_EVENT_EDIT_CLASS_PATH } from '../../../../../../../routes';
import ClassForm from './Class';
import { getEditClassUrl, constructAdminEventsEventUrlParams, getNewClassUrl } from '../../../../../../../constants/adminEventsUrls';
import { isPathnameMatchingRoute } from '../../../../../../../utils/urlHelper';
import { checkAdminEventClassPermission, checkAdminEventReportsPermission, checkEnterClassRequirementsPermission } from '../../../../../../../utils/helpers/adminEventsPageHelper';
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { isPathUnderAdminEvent } from '../../../../../../../utils/eventsHelper';
import { actionCreators as cacheTwoEventActionCreators } from '../../../../../../../store/AdminEvents/CacheTwoEvent/actions';
import { generateDOMId } from '../../../../../../../utils/cypressHelper';
import { shouldReconfigRouter } from '../../../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { classTypeSelector } from '../../../../../../../store/CacheZero/selectors';
import { noOpenedModals } from '../../../../../../../utils/modalHelper';
import { reduxStoreService } from '../../../../../../../store/service';
import {isAdminEventsCacheTwoEventPopulated} from '../../../../../../../utils/cachePopulatedCheckers/adminEvents';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--event--classes-tab';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {classId: string, classTypeId: string}>;

type Props = WithInertAttribute<{
  disabled?: boolean;
}>;

class AdminEventsEventClassesTab extends React.Component<Props, {}> {
  public props: ConnectedProps & Props;
  private nextLocation;

  componentDidMount() {
    const route = this.props.routes[this.props.routes.length - 1];
    if (route.path !== ADMIN_EVENT_EDIT_CLASS_PATH && route.path !== ADMIN_EVENT_NEW_CLASS_PATH) this.configRouter();
  }

  private configRouter() {
    const {router, routes} = this.props;
    router.setRouteLeaveHook(routes[routes.length - 1], this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  componentDidUpdate(prevProps: Props & ConnectedProps) {
    const {routes} = this.props;

    const route = routes[routes.length - 1];
    if (shouldReconfigRouter(prevProps, this.props) && route.path !== ADMIN_EVENT_EDIT_CLASS_PATH && route.path !== ADMIN_EVENT_NEW_CLASS_PATH) this.configRouter();
  }

  componentWillUnmount() {
    const {actions, adminEventsCacheTwoEvent} = this.props;
    // if we are not navigating to any pages under cache two event, clear cache
    if (
      this.nextLocation &&
      !isPathUnderAdminEvent(this.nextLocation.pathname) && 
      isAdminEventsCacheTwoEventPopulated(adminEventsCacheTwoEvent)
    ) {
      actions.clearAdminEventsCacheTwoEvent();
    }

    this.resetRouteLeaveHook();
  }

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  onRestoreClass = (adminClass: AdminEventClass) => {
    const {classFormActions, adminEventsCacheTwoEvent, router, routes} = this.props;
    
    checkAdminEventClassPermission(
      () => {
        classFormActions.deleteAdminEventClass(adminClass.IDi, router, routes, false);
      },
      adminEventsCacheTwoEvent,
      true,
    );
  };

  onEditClass = (adminClass: AdminEventClass) => {
    const {routes, adminEventsCacheOne, adminEventsCacheTwoEvent} = this.props;
    const route = routes[routes.length - 1];
    if (!adminEventsCacheOne || !adminEventsCacheOne.EventsEventType) return;
    if (!adminEventsCacheTwoEvent || !adminEventsCacheTwoEvent.EventsEvent) return;
    
    const classType = classTypeSelector(reduxStoreService().getState(), adminClass.ClassTypeIDi);
    const editClassTypeUrl = getEditClassUrl(
      constructAdminEventsEventUrlParams(
        this.props,
        adminEventsCacheTwoEvent.EventsEvent.IDi,
        adminEventsCacheTwoEvent.EventsEvent.Name,
        undefined,
        undefined,
        adminClass.IDi,
        adminClass.IsCombo ? adminClass.ComboName : (classType ? classType.Name : ''),
      )
    );
    navPush(this.props.router, editClassTypeUrl);

  };
  onDeleteClass = (adminClass: AdminEventClass) => {
    const {actions, adminEventsCacheTwoEvent} = this.props;
    
    checkAdminEventClassPermission(
      () => {
        actions.pushDeleteAdminEventClassModal({id: adminClass.IDi});
      },
      adminEventsCacheTwoEvent,
      true,
    );
  };
  onEnterRequirementsCompleted = (adminClass: AdminEventClass, childClassId?: number) => {
    const {actions, adminEventsCacheTwoEvent} = this.props;

    checkEnterClassRequirementsPermission(
      () => {
        actions.pushModal(ModalTypes.ENTER_CLASS_REQUIREMENT_COMPLETED, true, false, {selectedClassId: adminClass.IDi, childClassId, eventToolsModalContext: 'class-list'});
      },
      adminEventsCacheTwoEvent,
    );
  };
  onExportInstructorClassRoster = (adminClass: AdminEventClass, childClassId?: number) => {
    const {actions, adminEventsCacheOne} = this.props;

    checkAdminEventReportsPermission(
      () => {
        actions.pushExportInstructorRosterModal({selectedClassId: adminClass.IDi, childClassId, eventToolsModalContext: 'class-list'});
      },
      adminEventsCacheOne,
    );
  };

  renderContent = () => {
    const {filteredClasses} = this.props;

    return (
      <div className={`${namespace()}--list-wrapper`}>
        {filteredClasses.map((adminClass) => {
          const selected = isPathnameMatchingRoute(location.pathname, ADMIN_EVENT_EDIT_CLASS_PATH, 'classId', adminClass.IDi);

          return (
            <AdminClassCard
              key={`class__card${adminClass.IDi}`}
              type={AdminClassCardType.CLASS_LIST}
              category={CardCategory.LIST}
              adminEventClass={adminClass}
              onRestore={this.onRestoreClass}
              onEdit={this.onEditClass}
              onDelete={this.onDeleteClass}
              onEnterRequirementsCompleted={this.onEnterRequirementsCompleted}
              onExportInstructorClassRoster={this.onExportInstructorClassRoster}
              selected={selected}
            />
          );
        })}
      </div>
    );
  };

  onNewClassClick = () => {
    const {routes, adminEventsCacheOne, adminEventsCacheTwoEvent} = this.props;

    checkAdminEventClassPermission(
      () => {
        const route = routes[routes.length - 1];
        if (route.path === ADMIN_EVENT_EDIT_CLASS_PATH) return;
        if (!adminEventsCacheOne || !adminEventsCacheOne.EventsEventType) return;
        if (!adminEventsCacheTwoEvent || !adminEventsCacheTwoEvent.EventsEvent) return;
            
        const newClassTypeUrl = getNewClassUrl(constructAdminEventsEventUrlParams(this.props, adminEventsCacheTwoEvent.EventsEvent.IDi, adminEventsCacheTwoEvent.EventsEvent.Name));
        navPush(this.props.router, newClassTypeUrl);
      },
      adminEventsCacheTwoEvent,
    );
  };

  onImportClassClick = () => {
    const {actions, adminEventsCacheTwoEvent} = this.props;

    checkAdminEventClassPermission(
      () => {
        actions.pushImportFromEventModal({importFromEventType: ImportFromEventType.CLASSES});
      },
      adminEventsCacheTwoEvent,
    );
  };

  renderEmptyMessage = (emptyMessage: string) => {
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        admin
        icon={ClassTypesIcon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        noMobilePadding
        description={emptyMessage}
        actions={<Button className={`${namespace()}--empty-message-btn`} color="green" onClick={this.onNewClassClick}>{'NEW CLASS'}</Button>}/>
    );
  };

  public render() {
    const {activeAdminEventClasses, inert, apiSaving, routes, filteredClasses, actions, classesTab} = this.props;
    
    let emptyMessage;
    const thisRoute = routes[routes.length - 1];

    if (activeAdminEventClasses.length === 0 && filteredClasses.length === 0) {
      emptyMessage = ENTITY_NOT_ADDED(ADMIN_EVENT_CLASSES);
    } else if (filteredClasses.length === 0) {
      emptyMessage = ENTITY_NOT_FOUND(ADMIN_EVENT_CLASSES);
    }

    const saving = apiSaving > 0 && noOpenedModals();

    return (
      <Main inert={inert}>
        <MainContent className={`${namespace()}`}>
          <div className={`${namespace()}--list-actions`}>
            <ClassesFilters
              ActiveForm={classesTab.ActiveForm}
              ValidationRules={classesTab.ValidationRules}
              expand={classesTab.expandFilter}
              reduxActions={actions}
              isEmptyList={filteredClasses.length === 0 || activeAdminEventClasses.length === 0}
              importButton={
                <Button
                  className='control import'
                  color='white' textColor='black'
                  onClick={this.onImportClassClick}
                >IMPORT</Button>
              }
              newButton={
                <Button id={generateDOMId("admin-class-add-btn")} className='control' color='green' textColor='white' onClick={this.onNewClassClick}>New</Button>
              }
            />
          </div>
          {emptyMessage && this.renderEmptyMessage(emptyMessage)}
          {!emptyMessage && this.renderContent()}
          {(thisRoute.path === ADMIN_EVENT_NEW_CLASS_PATH || thisRoute.path === ADMIN_EVENT_EDIT_CLASS_PATH) && (
            <ClassForm
              inert={inert}
              type={thisRoute.path === ADMIN_EVENT_EDIT_CLASS_PATH ? 'edit' : 'add'}
              onDelete={thisRoute.path === ADMIN_EVENT_EDIT_CLASS_PATH ? this.onDeleteClass : undefined}
              key='class-sidemodal'
            />
          )}
          {saving && <PageLoader className={`${namespace()}--page-loader`} />}
        </MainContent>
      </Main>
    );
  }
}

const filteredAdminEventClassesSelector = makeFilteredAdminEventClassesSelector();
const activeAdminEventClassesSelector = makeActiveAdminEventClassesSelector();
const mapStateToProps = (state: ApplicationState) => {
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSavingMap: state.app.apiSavingMap,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsCacheTwoEvent: state.adminEvents.cacheTwoEvent,
    adminEventsCacheThreeClass: state.adminEvents.cacheThreeClass,
    cacheZero: state.cacheZero,
    session: state.session,
    user: state.user,
    apiSaving: state.app.apiSaving,
    classesTab: state.adminEvents.events.event.classes.classesTab,
    filteredClasses: filteredAdminEventClassesSelector(state),
    activeAdminEventClasses: activeAdminEventClassesSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheZeroActionCreators,
    ...appActionCreators,
    ...rollbackActionCreators,
    ...cacheThreeClassActionCreators,
    ...cacheTwoEventActionCreators,
  }, dispatch),
  classFormActions: bindActionCreators({
    ...classFormActionCreators,
  }, dispatch),
});

const ConnectedAdminEventsEventClassesTab = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(AdminEventsEventClassesTab);

export default withRouter<Props>(ConnectedAdminEventsEventClassesTab);
