import {Image} from './cacheOne';
import moment from 'moment';
import {EventTypeRegistrationSettings} from "./cacheTwoEvents";
import { AdminEventsOption, AdminEventsEventCategory } from './adminEventsCacheOne';

// @todo: which options are optional?
export interface Options {
  Login?: Login;
  ABAAccountTypes?: Array<ABAAccountType>;
  ScoutTypes?: Array<ScoutType>;
  LeaderScoutTypes?: Array<LeaderScoutType>;
  OAStatusTypes?: Array<OAStatusType>;
  GradeOptions?: Array<GradeOption>;
  ShirtSizes?: Array<ShirtSize>;
  RankOptions?: Array<RankOption>;
  CubRankOptions?: Array<CubRankOption>;
  StateOptions?: Array<StateOption>;
  PaymentTypes?: Array<PaymentType>;
  EventPricingTypes?: Array<EventPricingType>;
  FacilityPricingTypes?: Array<FacilityPricingType>;
  ClassCompletionOptions?: Array<ClassCompletionOption>;
  Group?: FullGroup;

  GeneralPermissions?: GeneralPermissions;

  ClassTypes?: Array<ClassType>;
  GroupTypes?: Array<GroupType>;
  UnitTypes?: Array<UnitType>;
  UnitGenders?: Array<UnitGender>;
  Councils?: Array<Council>;
  Districts?: Array<District>;
  EventTypes?: Array<EventType>;
  Groups?: Array<Group> | null;
  CMSSites?: Array<CMSSite> | null;
  Locations?: Array<FacilityLocation> | null;
  FacilityTypes?: Array<FacilityType> | null; // Never use this directly (except facility types admin moda)! use the reselect makeFacilityTypesSelector from cacheZero
  BlackoutDates?: Array<BlackoutDate> | null;
  EventTypeRegistrationSettings_Roster: EventTypeRegistrationSettings; // @todo: this only contains Names atm
  GLAccounts: Array<GLAccount>;
  EventTypeRegistrationMethodOptions?: Array<AdminEventsOption>;
  EventCategories?: Array<AdminEventsEventCategory>;
  MeritBadges?: Array<MeritBadge>;
}

export interface Login {
  IDi: number;
  CampID: number;
  CurrentDate: string;
  /**
   * This will be returned for end user ONLY, and is set when the end user
   * is first logged in after an admin changed his password. Using this field
   * to require password change after first login.
   * 
   * Need to manually clear it when the end user updated password successfully.
   */
  IsTemporaryPassword?: boolean;
}

export interface NameAndID {
  ID: number | string;
  Name: string;
}

interface NameAndIDi {
  IDi: number;
  Name: string;
}

export interface ABAAccountType extends NameAndID {}
export interface ScoutType extends NameAndID {}
export interface LeaderScoutType extends NameAndID {}
export interface OAStatusType extends NameAndID {}
export interface GradeOption extends NameAndID {}
export interface ShirtSize  extends NameAndID {}
export interface RankOption extends NameAndID {}
export interface CubRankOption extends NameAndID {}
export interface StateOption extends NameAndID {}
export type MeritBadge = Pick<Image, "ID" | "ImageOriginal"> & {
  Name: string;
  VersionYear: number;
  Inactive: boolean;
  Color: string;
};
export interface PaymentType extends NameAndID {
  Refund: boolean;
  IsAdminOnly: boolean;
  Inactive: boolean;
}
export enum PaymentTypes {
  CREDIT_CARD=2,
  ECHECK=3,
  AT_COUNCIL_OTHER=4,
  CAMPERSHIP=5,
  OA_CAMPERSHIP=6,
  REFUND_ADD_CREDIT_TO_ACCOUNT=10,
  REFUND_AT_COUNCIL_OTHER=11,
  REFUND_CC_REFUND=12,
}
export interface EventPricingType extends NameAndID {
  IsDefaultForNonUnit: boolean;
}
export interface FacilityPricingType extends NameAndID {
  IsDefaultForNonUnit: boolean;
}
export interface ClassCompletionOption extends NameAndID {}
export interface GeneralPermissions {
  hasApplyRefundOrConvenienceFee: boolean;
  hasApplyRefundOrConvenienceFeeReason: string | null;
  hasAuditLog: boolean;
  hasAuditLogReason: string | null;
  hasCheckout: boolean;
  hasCheckoutReason: string | null;
  hasEditPreviousOrder: boolean;
  hasEditPreviousOrderReason: string | null;
  hasGroup: boolean;
  hasGroupReason: string | null;
  hasLocationAdd: boolean,
  hasLocationAddReason: string | null;
  hasTentaroo: boolean;
  // Admin Facilities
  hasFacilityTypeAdd: boolean;
  hasFacilityTypeAddReason: string | null;
  hasFacilityTypeEdit: boolean;
  hasFacilityTypeEditReason: string | null;
  // Admin Settings
  hasGLAccountAdd: boolean;
  hasGLAccountAddReason: string | null;
  hasGLAccountEdit: boolean;
  hasGLAccountEditReason: string | null;
  // Admin Events
  hasEventTypeAdd: boolean;
  hasEventTypeAddReason: string | null;
  hasClassTypeAdd: boolean;
  hasClassTypeAddReason: string | null;
  hasClassTypeEdit: boolean;
  hasClassTypeEditReason: string | null;
}
export interface ClassType extends NameAndIDi {
  /**
   * Both Description and FeaturedImage will be used by Classes
   */
  Description?: string;
  FeaturedImage: Image | null;
  mbID: number | null;
  Color: string;
  Inactive?: boolean;
  selected?: boolean;
}
export interface GroupType extends NameAndID {}
export enum UnitTypeID {
  TROOP=1,
  PACK,
  TEAM,
  CREW,
  SHIP,
  POST,
}
export interface UnitType extends NameAndID {
  SupportDeskUnitTypeID: number;
}
export interface UnitGender extends NameAndID {
  Abbrev: string;
}
export interface Council extends NameAndIDi {}
export interface District extends NameAndIDi {
  CouncilIDi: number;
}
export interface EventType extends NameAndID {
  ID: number;
  Inactive: boolean;
  NumCurrentOrFutureEvents: number;
  LocationID: number;
  TS: number;
  hasEditEventType: boolean;
  hasEditEventTypeReason: string | null;
}
export interface Group extends NameAndIDi {
  GroupTypeID: number;
  UnitSponsor: string;
  Organization?: string;
  Username: string;
  ContactName: string;
  Email: string;
  Contact2Name: string;
  Contact2Email: string;
  Inactive: boolean;
  RowNum: number;
  selected?: boolean;
}

export interface AccountsEventTypeEvents {
  EventName: string;
  IDi: number;
  StartDate: string;
  Inactive: boolean;
}

export interface AccountsEventRegistration {
  Amount: number;
  Balance: number;
  EventIDi: number;
  GroupEventIDi: number;
  GroupIDi: number;
  GroupName: string;
  NumAdults: number;
  NumYouth: number;
  RowNum: number;
}

export interface AccountsTrip {
  Balance: number;
  DateAdded: string;
  GroupIDi: number;
  GroupName: string;
  GroupTripID: number;
  RowNum: number;
  StartDate: string;
  TotalAmount: number;
  TripName: string;
}

export interface AccountsReservation {
  GroupTripID: number;
  GroupIDi: number;
  TripName: string;
  GroupName: string;
  ReservationID: number;
  FacilityTypeID: number;
  Facility: string;
  StartDate: string;
  EndDate: string;
  Amount: number;
  Balance: number;
  RowNum: number;
}

export interface Participant {
  EventIDi: number;
  GroupIDi: number;
  GroupWeekIDi: number;
  ParticipantEventIDi: number;
  IsYouth: boolean;
  ParticipantName: string;
  GroupName: string;
  Email: string;
  EventName: string;
}

export interface FullGroup {
  IDi: number;
  GroupTypeID: number;
  AllowOnlineBooking: boolean;
  Unit: string;
  UnitTypeID: number;
  UnitType: string;
  UnitSponsor: string;
  Organization: string;
  CouncilIDi: number;
  DistrictIDi: number;
  PricingTypeID: number;
  FacilityPricingTypeID: number;
  Username: string;
  Password?: string; // This shouldn't come back?
  FirstName: string;
  LastName: string;
  MI: string;
  Suffix: string;
  ContactName: string;
  PhoneNumber: number;
  PhoneNumber2: number;
  Contact2Name: string;
  Contact2Phone: number;
  Contact2Email: string;
  Address: string;
  Address2: string;
  City: string;
  StateID: number;
  Zip: string;
  Email: string;
  MarketingSource: string;
  Inactive: boolean;
  TS: number;
  Council: string;
  District: string;
  Name: string;
}

export interface FacilityType {
  Description: string;
  FacilityMapURL: string;
  ID: number;
  Inactive: boolean;
  IsCheckoutNextDay: boolean;
  IsFacilityType: boolean;
  Name: string;
  NameSingular: string;
  TS: number;
}

export interface BlackoutDate {
  ID: number;
  CampID: number;
  LocationID: number;
  Location: string;
  Reason: string;
  StartDateTime: moment.Moment;
  EndDateTime: moment.Moment;
}

export interface CMSSite {
  ID: number;
  SiteDomain: string;
  IsPrimaryCouncilSite: boolean;
  hasResourceAdd: boolean;
  hasResourceAddReason: string;
  hasResourceEdit: boolean;
  hasResourceEditReason: string;
  hasContactAdd: boolean;
  hasContactAddReason: string;
  hasContactEdit: boolean;
  hasContactEditReason: string;
  Inactive: boolean;
}

export interface FacilityLocation extends NameAndID {
  Inactive: boolean;
  NumFacilities: number;
}

export interface GLAccount {
  AccountNum: number;
  ID: number;
  Inactive: boolean;
  Name: string;
  TS: number;
}