import * as React from 'react';
import '../styles/icons/index.scss';

export interface IconProps {
  className?: string;
  style?: React.CSSProperties;
  width?: number | string;
  height?: number | string;
  color?: 'green' | 'orange' | 'gray' | 'red';
}

const Icon = (Icon: React.ReactType): React.StatelessComponent<IconProps> => (
  { className, width, height, color, style }: IconProps
): React.ReactElement<IconProps> => {
  const element = <Icon/>;
  const props: any = {
    className: 'icon' + (className ? ` ${className}` : '')
  };
  if (width) props.width = typeof width === 'string' ? width : `${width}px`;
  if (height) props.height = typeof height === 'string' ? height : `${height}px`;
  if (color) props.className += ` ${color}`;
  if (style) props.style = { ...props.style, ...style };
  return React.cloneElement(element, {
    ...element.props,
    ...props
  });
};

export const AddedIcon = Icon(require('../images/icons/added.svg'));
export const ArrowIcon = Icon(require('../images/icons/arrow.svg'));
// TODO: ArrowLeftIcon and ArrowRight below cannot be used somehow??
export const ArrowLeftIcon = Icon(require('../images/elements/date-picker/arrow-left.svg'));
export const ArrowRight = Icon(require('../images/elements/date-picker/arrow-right.svg'));
export const AttentionAlert = Icon(require('../images/icons/attention-alert.svg'));
export const AttentionAlert2 = Icon(require('../images/icons/attention-alert2.svg'));
export const AddIcon = Icon(require('../images/icons/add.svg'));
export const AddPlusOutlineCircleIcon = Icon(require('../images/icons/add-plus-outline-circle.svg'));
export const BackIcon = Icon(require('../images/icons/back.svg'));
export const BackArrowIcon = Icon(require('../images/icons/back-arrow.svg'));
export const BillingIcon = Icon(require('../images/icons/billing.svg'));
export const BlackoutDatesIcon = Icon(require('../images/icons/blackout-dates.svg'));
export const BlogArticleIcon = Icon(require('../images/icons/blog-article.svg'));
export const CalendarEventIcon = Icon(require('../images/icons/calendar-event.svg'));
export const CameraIcon = Icon(require('../images/icons/camera.svg'));
export const CartIcon = Icon(require('../images/icons/cart.svg'));
export const CheckMarkIcon = Icon(require('../images/icons/checkmark.svg'));
export const ClassTypesIcon = Icon(require('../images/icons/class-types.svg'));
export const CloseIcon = Icon(require('../images/icons/close.svg'));
export const CloseCircleIcon = Icon(require('../images/icons/close-circle.svg'));
export const CopyIcon = Icon(require('../images/icons/copy.svg'));
export const DrawerIcon = Icon(require('../images/app/header/drawer.svg'));
export const DeleteIcon = Icon(require('../images/icons/delete.svg'));
export const EditIcon = Icon(require('../images/icons/edit.svg'));
export const EmailIcon = Icon(require('../images/icons/email.svg'));
export const EmailOrangeIcon = Icon(require('../images/icons/email-orange.svg'));
export const EventsIcon = Icon(require('../images/icons/events.svg'));
export const EventsTypesIcon = Icon(require('../images/icons/events-types.svg'));
export const ExternalLinkIcon = Icon(require('../images/icons/external-link.svg'));
export const FacilitiesIcon = Icon(require('../images/icons/facilities.svg'));
export const Facilities2Icon = Icon(require('../images/icons/facilities2.svg'));
export const FacilitiesTypesIcon = Icon(require('../images/icons/facilities-types.svg'));
export const FacilitiesTypes2Icon = Icon(require('../images/icons/facilities-types2.svg'));
export const FilterIcon = Icon(require('../images/icons/filter.svg'));
export const FeedbackIcon = Icon(require('../images/icons/feedback.svg'));
export const GLAccountIcon = Icon(require('../images/icons/gl-account.svg'));
export const GlobeIcon = Icon(require('../images/icons/globe.svg'));
export const GroupIcon = Icon(require('../images/icons/group.svg'));
export const HomeIcon = Icon(require('../images/icons/home.svg'));
export const HelpIcon = Icon(require('../images/icons/help.svg'));
export const InfoIcon = Icon(require('../images/icons/info.svg'));
export const LaptopIcon = Icon(require('../images/icons/laptop.svg'));
export const LinkIcon = Icon(require('../images/icons/link.svg'));
export const ListIcon = Icon(require('../images/icons/list.svg'));
export const LocationsIcon = Icon(require('../images/icons/locations.svg'));
export const LogoutIcon = Icon(require('../images/icons/log-out.svg'));
export const MailIcon = Icon(require('../images/icons/mail.svg'));
export const MoreIcon = Icon(require('../images/icons/more.svg'));
export const MoreCircleIcon = Icon(require('../images/icons/more-circle.svg'));
export const NextArrowIcon = Icon(require('../images/icons/next-arrow.svg'));
export const NotCartIcon = Icon(require('../images/icons/not-cart.svg'));
export const OptionsIcon = Icon(require('../images/icons/options.svg'));
export const OrdersIcon = Icon(require('../images/icons/orders.svg'));
export const PageIcon = Icon(require('../images/icons/page.svg'));
export const PaymentTypeAmericanExpressIcon = Icon(require('../images/icons/PaymentType/american-express.svg'));
export const PaymentTypeCreditCardIcon = Icon(require('../images/icons/PaymentType/credit-card.svg'));
export const PaymentTypeDiscoverIcon = Icon(require('../images/icons/PaymentType/discover.svg'));
export const PaymentTypeECheckIcon = Icon(require('../images/icons/PaymentType/echeck.svg'));
export const PaymentTypeMasterCardIcon = Icon(require('../images/icons/PaymentType/mastercard.svg'));
export const PaymentTypeVisaIcon = Icon(require('../images/icons/PaymentType/visa.svg'));
export const PersonIcon = Icon(require('../images/icons/person.svg'));
export const PeopleContactIcon = Icon(require('../images/icons/people-contact.svg'));
export const PhoneIcon = Icon(require('../images/icons/phone.svg'));
export const PopcornIcon = Icon(require('../images/icons/popcorn.svg'));
export const ProductIcon = Icon(require('../images/icons/products.svg'));
export const ProfileIcon = Icon(require('../images/icons/profile.svg'));
export const PublicWebsiteIcon = Icon(require('../images/icons/public-website.svg'));
export const RefreshIcon = Icon(require('../images/icons/refresh.svg'));
export const RemoveIcon = Icon(require('../images/icons/remove.svg'));
export const ReportsIcon = Icon(require('../images/icons/reports.svg'));
export const SearchIcon = Icon(require('../images/icons/search.svg'));
export const SettingsIcon = Icon(require('../images/icons/settings.svg'));
export const SSNBlogIcon = Icon(require('../images/icons/ssn-blog.svg'));
export const SSNFacebookColorIcon = Icon(require('../images/icons/ssn-facebook-color.svg'));
export const SSNFacebookSolidIcon = Icon(require('../images/icons/ssn-facebook-solid.svg'));
export const SSNFlickrColorIcon = Icon(require('../images/icons/ssn-flickr-color.svg'));
export const SSNFlickrSolidIcon = Icon(require('../images/icons/ssn-flickr-solid.svg'));
export const SSNGoogleColorIcon = Icon(require('../images/icons/ssn-google-color.svg'));
export const SSNGoogleSolidIcon = Icon(require('../images/icons/ssn-google-solid.svg'));
export const SSNInstColorIcon = Icon(require('../images/icons/ssn-inst-color.svg'));
export const SSNInstSolidIcon = Icon(require('../images/icons/ssn-inst-solid.svg'));
export const SSNLinkedInColorIcon = Icon(require('../images/icons/ssn-linkedin-color.svg'));
export const SSNLinkedInSolidIcon = Icon(require('../images/icons/ssn-linkedin-solid.svg'));
export const SSNPinterestColorIcon = Icon(require('../images/icons/ssn-pinterest-color.svg'));
export const SSNPinterestSolidIcon = Icon(require('../images/icons/ssn-pinterest-solid.svg'));
export const SSNTwitterColorIcon = Icon(require('../images/icons/ssn-twitter-color.svg'));
export const SSNTwitterSolidIcon = Icon(require('../images/icons/ssn-twitter-solid.svg'));
export const SSNVimeoColorIcon = Icon(require('../images/icons/ssn-vimeo-color.svg'));
export const SSNVimeoSolidIcon = Icon(require('../images/icons/ssn-vimeo-solid.svg'));
export const SSNYoutubeColorIcon = Icon(require('../images/icons/ssn-youtube-color.svg'));
export const SSNYoutubeSolidIcon = Icon(require('../images/icons/ssn-youtube-solid.svg'));
export const SSNThreadColorIcon = Icon(require('../images/icons/ssn-thread-color.svg'));
export const SSNThreadSolidIcon = Icon(require('../images/icons/ssn-thread-solid.svg'));
export const WaitlistIcon = Icon(require('../images/icons/waitlist.svg'));
export const WebsitesIcon = Icon(require('../images/icons/websites.svg'));
