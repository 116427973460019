import { T_REQUIRED } from "@tentaroo/core";
import { DATE_PICKER_FORMAT, MIN_DATE, TODAY } from "@tentaroo/shared";

export const REQUIRED: T_REQUIRED = 'Required.';
export const INVALID = 'Invalid.';
export const INVALID_DATE = `Date must be in format ${DATE_PICKER_FORMAT}`;
export const MISSING_FILE = 'Please select a file before saving';
export const TOO_EARLY = 'You must pick a date after ' + MIN_DATE.format(DATE_PICKER_FORMAT);
export const TOO_LATE = 'You must pick a date before ' + TODAY.format(DATE_PICKER_FORMAT);
export const INVALID_FULL_URL = 'Invalid, please enter full URL starting with http/https.';
export const OUTSIDE_DATE = 'This date is outside the trip.';
export const UNAVAIL_DATE = 'This date is not available.';
export const GENERIC_API_ERROR = 'There was a problem communicating with the server, please try again.';
export const SUBMIT_ERROR = 'Please review the errors below.';
export const MUST_BE_NUMBER = "Must be a number";
export const SELECTED_DROPDOWN_ITEM_DELETED = 'The selected option has been deleted.';
export const SELECTED_ITEM_DELETED = "The selected option has been deleted. Please unselect this option then select a different one.";
export const INVALID_SELECTED_MERIT_BADGE = "Please select a merit badge before saving";
export const INVALID_SELECTED_CLASS_TYPE = "Please select a class type before saving";
export const INVALID_SELECTED_RESOURCE_CATEGORY = "Please select a resource category before saving";
export const INVALID_SELECTED_GL_ACCOUNT = "Please select a GL Account before saving";
export const INVALID_SELECTED_LOCATION = "Please select a location before saving";
export const INVALID_SELECTED_MESSAGE = "Please select a message before saving";
export const INVALID_SELECTED_RESOURCE = "Please select a resource before saving";
export const INVALID_SELECTED_PAGE = "Please select a page before saving";
export const CANCEL_ORDER_BEFORE_FILTERING = "Please save or cancel your changes to the menu item order before changing filters.";
export const UNSUPPORTED_FILE_TYPES = (filename: string, fileSize: string) => `${UPLOAD_FILE_ERROR_PREFIX(filename, fileSize)} - Unsupported file type. Please try selecting a different file.`;
export const DROPDOWN_ITEM_DELETED_PREFIX = 'DELETED - ';

export const MIN_VALUE = (min) => `^Minimum of ${min}`;
export const MAX_VALUE = (max, noPrefix?: boolean) => `${noPrefix ? "" : "^"}Maximum of ${max}`;
export const MIN_LENGTH = (min) => `^Min length of ${min} characters required.`;
export const MAX_LENGTH = (max) => `^Max length of ${max} characters exceeded.`;
export const S_REQUIRED = (label) => `^${label} is required.`;
export const S_INVALID = (label) => `^${label} is invalid.`;
export const S_TOO_LONG = (label, len) => `^${label} max length of ${len} characters exceeded.`;
export const S_TOO_SHORT = (label, len) => `^${label} min length of ${len} characters required.`;
export const S_INVALID_DATE = (label) =>  `^${label} must be in format ${DATE_PICKER_FORMAT}`;
export const SELECTED_CARD_DELETED = (label: string) => `This ${label} was deleted. Please remove this and select another ${label}.`;
const UPLOAD_FILE_ERROR_PREFIX = (filename: string, fileSize: string) => `Error uploading "${filename}" (${fileSize} MB)`;
export const MAX_FILE_SIZE = (filename: string, fileSize: string, maxSize) => `${UPLOAD_FILE_ERROR_PREFIX(filename, fileSize)} - File is too large, files are limited to ${maxSize}MB. Please try selecting a different file.`;

export const NO_INTERNET = 'You are offline. Please connect to the internet.';
export const CONNECTION_ISSUE = 'There was a problem communicating with the server.';
export const CONNECTION_TIMEOUT = 'Request timed out. Please check your internet connection and try again.';
export const SERVER_UNAVAILABLE = "The server cannot accept new requests at this time, please try again in a minute.";
export const PARSE_PAYLOAD_ERROR = 'Error while parsing response. Please try again.';

// Trip
export const AT_LEAST_ONE_PERSON = "Please specify at least one person. Please try again or cancel.";
export const MAX_EXCEEDED = "The maximum capacity for this facility has been exceeded. Please try again or cancel.";
export const BAD_DATE_RANGE = "Start Date must be before End Date. Please verify your dates and try again.";
export const TOO_MANY_PEOPLE = "Please reduce the number of attendees to below the trip numbers for youth and adults.";
export const BAD_START_DATE_RANGE = "Start Date is not available. Please verify your dates and try again.";
export const BAD_END_DATE_RANGE = "End Date is not available. Please verify your dates and try again.";

export const NO_BOOKING = "Online booking has not been enabled for this facility.";

// Others
export const LINK_COPIED= 'The link was copied to clipboard';
