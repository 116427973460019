import * as React from 'react';
import { default as AppHeader } from '../../App/Header';

const Header = (): React.SFC<undefined> => (): React.ReactElement<undefined> => (
  <AppHeader
    isBlank
  />
);

export default Header;
