import {GetGroupRosterCache} from './actions';
import {getGroupRosterCache, getGroupRosterCacheBody} from '../../constants/urls';
import {makeRequestEpic} from "../Validation/epicCreator";

export const cacheTwoRosterEpic = makeRequestEpic(
  GetGroupRosterCache,
  getGroupRosterCache,
  getGroupRosterCacheBody,
  true
);
