import {makeRequestEpic} from "../../Validation/epicCreator";
import {ChangePasswordActions} from "./actions";
import {getUpdatePasswordUrl} from "../../../constants/urls";
import { reduxStoreService } from "../../service";

export const changePasswordEpic = makeRequestEpic(
  ChangePasswordActions,
  getUpdatePasswordUrl,
  undefined,
  true,
  (payload, action) => {
    const rootState = reduxStoreService().getState();

    /* This should happen beofre `IsTemporaryPassword` is cleared on success */
    return rootState.cacheZero.options?.Login?.IsTemporaryPassword ? 
      'Your password has been changed. Your account is now active.' :
      'Password has been changed';
  },
);

