import { ActionCreator, ApplicationState } from '../';
import { CreateRegistration } from '../../models/api/registerForm';
import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod,
  createApiSubmitFormMethod, createRequestActions
} from '../Validation/actionCreator';
import { typeName, Action } from '../../utils/StrongActions';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../utils/suffix';
import { CreateAccountState } from '.';
import {toNumber} from '../../utils/dataHelper';

const GET_REGISTER_FORM_SUFFIX = "__GET_REGISTER_FORM";

export const UPDATE_REGISTER_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__REGSITER_FORM';
export const SUFFIX = '__CREATE_ACCOUNT';
export const SUFFIX_EMAIL = '__CREATE_ACCOUNT_EMAIL';

export const ApiEmailValidateActions = createApiValidateActions(SUFFIX_EMAIL);
export const EmailValidateActions = createValidateActions(SUFFIX_EMAIL);

export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ValidateActions = createValidateActions(SUFFIX);
export const GetRegisterFormActions = createRequestActions(GET_REGISTER_FORM_SUFFIX + UPDATE_REGISTER_FORM_SUFFIX);
export const ApiSubmitActions = createApiSubmitActions(SUFFIX); // Note: these actions are caught in several reducers! Including App and Session

/**
 * We use this action to trigger setDefaults for fields other than RegistrationType.
 * See more comments in reducer
 */
@typeName('INIT_OTHER_FIELDS')
export class InitRegisterFormOtherFields extends Action {}

export type Actions = typeof actionCreators;

const formCreator = (rootState: ApplicationState, activeForm: Required<CreateAccountState["ActiveForm"]>): CreateRegistration => {
  const result: CreateRegistration = {
    Group: {
      GroupTypeID: activeForm.GroupTypeID,
      UnitTypeID: activeForm.UnitTypeID,
      UnitGenderID: activeForm.UnitGenderID,
      UnitNum: toNumber(activeForm.Unit),
      CouncilIDi: activeForm.CouncilIDi,
      DistrictIDi: activeForm.DistrictIDi,
      FirstName: activeForm.FirstName,
      LastName: activeForm.LastName,
      PhoneNumber: activeForm.PhoneNumber,
      Username: activeForm.Username,
      Password: activeForm.Password,
      Email: activeForm.Email,
      ConfirmEmail: activeForm.Email,
      Organization: activeForm.Organization,
    }
  };
  if (rootState.app && rootState.app.afterLoginPath) {
    result.DesiredURL = rootState.app.afterLoginPath;
  } else if (activeForm && activeForm.RegistrationType) {
    // only set DesiredEventTypeID and DesiredFacilityLocationID when the afterLogin deep link is not presented
    if (activeForm.RegistrationType.EventTypeID > 0) result.DesiredEventTypeID = activeForm.RegistrationType.EventTypeID;
    if (activeForm.RegistrationType.FacilityLocationID > 0) result.DesiredFacilityLocationID = activeForm.RegistrationType.FacilityLocationID;
  }

  return result;
};

export const actionCreators = {
  apiRequestGetRegisterForm: (): ActionCreator => dispatch => dispatch(GetRegisterFormActions.request({})),
  apiSubmitForm: createApiSubmitFormMethod(ApiSubmitActions, (s) => s.createAccount, formCreator),
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.createAccount),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  updateValueEmail: createUpdateValueMethod(EmailValidateActions, ApiEmailValidateActions, (s) => s.createAccount),
  simpleUpdateEmail: createSimpleUpdateValueMethod(EmailValidateActions),
};
