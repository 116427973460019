import * as M from '../../constants/messages/generic';
import {
  APICancelValidate,
  APIFailureSubmit,
  APIFailureValidate,
  APIRequestValidate,
  APISuccessValidate,
  ClientFailureSubmit,
  LOAD,
  LOAD_FINISH,
  Populate,
  POPULATE_ACTION,
  SAVE,
  SAVE_FINISH,
  SUBMIT_FAILURE_ACTION,
  SUBMIT_REQUEST_ACTION,
  submitClientFailureAction,
  UpdateCurrValue,
  UpdateValue,
  validateCancelAction,
  validateFailureAction,
  validateRequestAction,
  validateSuccessAction,
  validateUpdateAction,
  validateUpdateCurrAction,
  REQUEST_ACTION,
  APIUpdateFormSubmit,
  APISuccessSubmit,
  APIUpdateForm,
  WithRootState,
  SUBMIT_SUCCESS_ACTION,
  SUCCESS_ACTION
} from './actionCreator';
import {getMessageFromErrorResponse} from "../../utils/stringHelper";
import { SetCacheAction } from '../App/actions';
import { captureTentarooError } from '../../utils/dataHelper';
import { isActionType } from '../../utils/StrongActions';
import { Validator } from '../../utils/validator/models';
import { noOpenedModals } from '../../utils/modalHelper';
import type { ApplicationState } from '..';
import { Action, AnyAction } from 'redux';

export const chainSetDefault = (rootState: ApplicationState, values: Record<string, any>, v: Validator, ValidationRules: any) => {
  if (v.chainDependants) {
    v.chainDependants.forEach((dep: string) => {
      const vDep = ValidationRules[dep];
      if (vDep.chainValue) values[vDep.key] = vDep.chainValue(rootState, values);
      else if (vDep.defaultValue) values[vDep.key] = vDep.defaultValue(rootState);
      chainSetDefault(rootState, values, vDep, ValidationRules);
    });
  }
};

const doClientFailure = (newState, errors: any, determineError?: (errors: any) => string, blockScroll?: boolean, noAlert?: boolean) => {
  newState.SubmitErrorMessage = '';
  if (errors) {
    for (let key in errors) {
      newState.ValidationRules[key] = {...newState.ValidationRules[key]};
      if (!errors[key]) newState.ValidationRules[key].errors = false;
      else {
        newState.ValidationRules[key].errors = [...errors[key]];
      }
    }
    if (!blockScroll) window.scrollTo(0,0);
  }
  if (determineError) newState.SubmitErrorMessage = determineError(errors);

  if (newState.SubmitErrorMessage === '') newState.SubmitErrorMessage = M.SUBMIT_ERROR;
  if (noAlert) newState.SubmitErrorMessage = undefined;
  
  return newState;
};

export const apiValidationReducerCreator = (suffix: string, determineError?: (errors: any) => string, blockScroll?: boolean) => {
// The passed in state is expected to have the following: ValidationRules, SubmitErrorMessage and ActiveForm
  return (state, act: WithRootState<AnyAction>) => {
    if (act.type === validateUpdateAction + suffix) {
      const action = <WithRootState<UpdateValue>> act;
      const newState = {...state, ValidationRules: {...state.ValidationRules}};
      if (action.errorsAndValues.Errors) {
        for (let key in action.errorsAndValues.Errors) {
          newState.ValidationRules[key] = {...newState.ValidationRules[key]};
          if (!action.errorsAndValues.Errors[key]) newState.ValidationRules[key].errors = false;
          else {
            newState.ValidationRules[key].errors = [...action.errorsAndValues.Errors[key]];
          }
        }
      }
      if (action.errorsAndValues.Values) {
        newState.ActiveForm = {...newState.ActiveForm, ...action.errorsAndValues.Values};
      }
      return newState;
    } else if (act.type === validateUpdateCurrAction + suffix) {
      const action = <WithRootState<UpdateCurrValue>> act;
      const newState = { ...state, ActiveForm: { ...state.ActiveForm } };
      if (action.changeKey) {
        // Only RegistrationType under CreateAccount sets this `getValue`
        if (action.vObj.getValue) {
          newState.ActiveForm[action.changeKey] = action.vObj.getValue(action.rootState, action.value);
        } else {
          newState.ActiveForm[action.changeKey] = action.value;
        }
      }
      return newState;
    } else if (act.type === POPULATE_ACTION + suffix) {
      const action =  <Populate> act;
      const newState = { ...state, ActiveForm: { ...state.ActiveForm }, ValidationRules: {...state.ValidationRules} };
      const {ActiveForm, ValidationRules} = newState;

      const values = {};
      Object.keys(ActiveForm).forEach((key) => {
        const v = ValidationRules[key];
        chainSetDefault(act.rootState, values, v, ValidationRules);
      });
      newState.ActiveForm = {...newState.ActiveForm, ...values};
      return newState;
    } else if (act.type === validateCancelAction + suffix) {
      const action = <WithRootState<APICancelValidate>> act;
      const key = action.vObj.key;
      const newState = {...state, ValidationRules: {...state.ValidationRules}};
      newState.ValidationRules[key] = {...newState.ValidationRules[key]};
      if (action.rollback) {
        // @todo: get back old errors
        newState.ActiveForm = {
          ...newState.ActiveForm
        };
        // @todo: what if object?
        newState.ActiveForm[key] = newState.ValidationRules[key].apiCheck.lastValue;
        newState.ValidationRules[key].apiCheck = {
          ...newState.ValidationRules[key].apiCheck,
          state: newState.ValidationRules[key].apiCheck.lastState
        };
      } else {
        newState.ValidationRules[key].apiCheck = {
          ...newState.ValidationRules[key].apiCheck,
          state: undefined,
          lastState: undefined,
          lastValue: state.ActiveForm[key]
        };
      }
      return newState;
    } else if (act.type === validateSuccessAction + suffix) {
      const action = <WithRootState<APISuccessValidate>> act;
      const newState = {...state, ValidationRules: {...state.ValidationRules}};
      const key = action.vObj.key;
      newState.ValidationRules[key] = {...newState.ValidationRules[key]};
      newState.ValidationRules[key].errors = false;
      newState.ValidationRules[key].apiCheck = {
        ...newState.ValidationRules[key].apiCheck,
        state: 'success',
        lastState: 'success',
        lastValue: state.ActiveForm[key] // @todo: will this work if object?
      };
      return newState;
    } else if (act.type === validateFailureAction + suffix) {
      const action = <WithRootState<APIFailureValidate>> act;
      // @todo: do I need to add more error types here?
      const newState = {...state, ValidationRules: {...state.ValidationRules}};
      const key = action.vObj.key;
      newState.ValidationRules[key] = {...newState.ValidationRules[key]};
      if (action.response.status !== 400 && action.response.status !== 409) {
        // @todo: rollback
        // @todo: get back old errors
        newState.ActiveForm = {
          ...newState.ActiveForm
        };
        // @todo: what if object?
        newState.ActiveForm[key] = newState.ValidationRules[key].apiCheck.lastValue;
        newState.ValidationRules[key].apiCheck = {
          ...newState.ValidationRules[key].apiCheck,
          state: newState.ValidationRules[key].apiCheck.lastState
        };
        return newState;
      } else if (action.vObj.apiCheck) {
        const errors = action.vObj.apiCheck.determineError(action.response);
        if (errors) {
          newState.ValidationRules[key].errors = [...errors];
          newState.ValidationRules[key].apiCheck = {
            ...newState.ValidationRules[key].apiCheck,
            state: 'failure',
            lastState: 'failure',
            lastValue: state.ActiveForm[key] // @todo: will this work if object?
          };
        } else {
          captureTentarooError(new Error('API error occurred, but determineError returned false for key ' + key));
        }
        return newState;
      }
    } else if (act.type === validateRequestAction + suffix) {
      const action = <WithRootState<APIRequestValidate>> act;
      if (action.vObj.apiCheck) {
        const key = action.vObj.key;
        const newState = {...state, ValidationRules: {...state.ValidationRules}};
        newState.ValidationRules[key] = {
          ...newState.ValidationRules[key],
          apiCheck: {...newState.ValidationRules[key].apiCheck, state: 'loading'}
        };
        return newState;
      }
    } else if (act.type === `${SUBMIT_REQUEST_ACTION}${SAVE}${suffix}` || act.type === `${SUBMIT_REQUEST_ACTION}${LOAD}${suffix}` || act.type === `${REQUEST_ACTION}${suffix}`) {
      return { ...state, SubmitErrorMessage: undefined};
    } else if (act.type === `${SUBMIT_FAILURE_ACTION}${SAVE_FINISH}${suffix}` || act.type === `${SUBMIT_FAILURE_ACTION}${LOAD_FINISH}${suffix}`) {
      const action = <WithRootState<APIFailureSubmit>> act;
      if (action.response.status !== 400 || (action.response.xhr.response && action.response.xhr.response.error.Detail === 'Payment Error')) return state;
      let message = getMessageFromErrorResponse(action.response);
      if (!message) message = M.SUBMIT_ERROR;
      const newState = { ...state, ValidationRules: { ...state.ValidationRules }, SubmitErrorMessage: message };
      if (action.noAlert) newState.SubmitErrorMessage = undefined;
      // TODO: Need to handle modal scroll to top on 400
      if (!action.blockScroll && !blockScroll && noOpenedModals(act.rootState)) {
        window.scrollTo(0, 0);
      }
      return newState;
    } else if (act.type === submitClientFailureAction + suffix) {
      const action = <WithRootState<ClientFailureSubmit>> act;
      const newState = {...state};
      const finalState = doClientFailure(newState, action.errors.Errors, determineError, action.blockScroll, action.noAlert);
      return finalState;
    }

    return state;
  };
};

/**
 * Whether or not we should skip updating cache when a SAVE response comes back.
 * In other words, response for load request will never be skipped unless response is invalid
 * 
 * We only want to skip when
 * 1. Response is invalid
 * 2. Save request - if NOT 409 AND is leaving cache level
 * 3. Save request - if 409 AND NOT in matching cache level
 * 
 * @param act APISuccessSubmit dispatched when a response comes back
 * @returns 
 */
export const shouldSkipResponseForCache = (act: any, underCacheLevel = 2) => {
  const submitAction = <APISuccessSubmit> act;

  /**
   * By default, always skip checking both flags for cache one, and always update cache
   * because `underCacheLevel === 1` for cache one and updatingCacheLevelAndBelow 
   * defaults to 2
   * 
   * By default, always check both flags for caches below one because
   * both `updatingCacheLevelAndBelow` and `underCacheLevel` are default to 2, so will
   * skip this condition.
   */
  const shouldSkipCheckingFlags = (
    submitAction.extra && submitAction.extra.updatingCacheLevelAndBelow &&
    // Meaning current level we under is above `updatingCacheLevelAndBelow`, so 
    // no need to check flags, and should just return false and always update cache
    underCacheLevel < submitAction.extra.updatingCacheLevelAndBelow
  );
  if (!submitAction.response || !submitAction.response.response) return true;
  else {
    if (shouldSkipCheckingFlags) return false;

    // if NOT 409 AND is leaving cache level, dont update cache
    if (!isActionType(act, SetCacheAction)) {
      return submitAction.extra && submitAction.extra.leavingCacheLevelOnSuccess;
    }
    // if 409 AND NOT in matching cache level, dont update cache
    else {
      return submitAction.extra && submitAction.extra.inMatchingCacheLevelOn409 === false;
    }
  }
};

/**
 * Whether or not we should skip updating a form when an action comes in.
 * This action could come from either load request OR save request
 * 
 * We want to skip updating form when
 * 1. Save request - if NOT 409 AND is leaving cache level. Note that this is different from the condition in `shouldSkipResponseForCache`: we skip update form regardless of what cache level we are at.
 * 2. Save request - if 409 AND NOT in matching cache level. We skip update form regardless what cache level we are at
 * 3. Load request - if `skipUpdateForm` is set, we usually set this when we are NOT in the form page, but
 *                   performs a load request that loads data related to that form
 * 
 * @param act APIUpdateForm action dispatched after load/save
 * @returns 
 */
export const shouldSkipUpdateForm = (act: any) => {
  const submitAction = <APIUpdateFormSubmit>act;
  const loadAction = <APIUpdateForm>act;

  if (submitAction.extra && !submitAction.extra.is409 && submitAction.extra.leavingCacheLevelOnSuccess) return true;
  else if (submitAction.extra && submitAction.extra.is409 && submitAction.extra.inMatchingCacheLevelOn409 === false) return true;
  else if (loadAction.extra && loadAction.extra && loadAction.extra.skipUpdateForm) {
    captureTentarooError(new Error(`skipUpdateForm fails to skip an updateForm action for action ${loadAction.type}`));
    return true;
  }

  return false;
};

export function createUpdateCacheConditions(
  suffix: string,
) {
  return (action: Action<string>) => {
    return (
      // Should try update cache if an action is a load/save success for certain cache level
      // under the passed in `suffix`
      action.type.includes(suffix) && (
        action.type.includes(SUBMIT_SUCCESS_ACTION) ||
        action.type.includes(SUCCESS_ACTION)
      ) ||
      // Should try update cache if a 409 action for certain cache level
      // under the passed in `suffix`
      isActionType(action, SetCacheAction) && action.actions.requestType.includes(suffix)
    );
  };
}