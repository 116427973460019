import * as React from 'react';
import {Main, MainContent} from '../../../../../Layouts';
import {
  Alert,
  AlertActions,
  Button,
  Card,
  CardSubTitle,
  CardTitle,
  Class,
  ContextMenu,
  ContextMenuInfo,
  ContextMenuItem,
  FinancialSummaryLine,
  FinancialSummaryTotal,
  Icon,
  LoadingAll,
  Media,
  PageLoader,
  Text,
  Title,
  View
} from '../../../../../Elements';
import {CartIcon as CartIconIcon} from '../../../../../Icons';
import {RouteComponentProps, withRouter} from "react-router";
import {navPush} from "../../../../../../utils/navHelper";
import {getEventRegisterParticipantEditUrl, getViewParticipantsReportUrl, URLS} from "../../../../../../constants/urls";
import {bindActionCreators} from 'redux';
import {actionCreators} from "../../../../../../store/Events/Event/Registration/actions";
import {
  actionCreators as appActionCreators,
  ShowTopFloatingAlert
} from "../../../../../../store/App/actions";
import {
  actionCreators as rollbackActionCreators,
} from "../../../../../../store/Rollback/actions";
import {
  actionCreators as eventActionsCreators,
} from "../../../../../../store/Events/Event/Main/actions";
import {
  actionCreators as eventTypeActionCreators,
} from "../../../../../../store/Events/EventType/actions";
import {
  actionCreators as cacheFourActionCreators,
} from "../../../../../../store/CacheFourEventsViewParticipant/actions";
import {
  actionCreators as cacheThreeActionCreators,
} from "../../../../../../store/CacheThreeEvents/actions";
import {
  actionCreators as cacheTwoActionCreators,
} from "../../../../../../store/CacheTwoEvents/actions";
import {
  actionCreators as cacheOneActionCreators,
} from "../../../../../../store/CacheOne/actions";
import {makeSelectedEventTypeSelector} from "../../../../../../store/CacheTwoEvents/index";
import {makeSelectedEventSelector} from "../../../../../../store/CacheThreeEvents/index";
import {noop} from "../../../../../../utils/noop";
import {
  makeSelectedParticipantSelector,
  makeSelectedParticipantTypeSelector
} from "../../../../../../store/CacheFourEventsViewParticipant/index";
import {RegisteredClass} from '../../../../../../models/class';
import {standardCurrencyFormat} from "../../../../../../utils/classesHelper";
import '../../../../../../styles/pages/events/event/registration/index.scss';
import '../../../../../../styles/pages/events/event/registration/participant.scss';
import {EventParticipantCalc} from "../../../../../../store/CacheFourEventsParticipants/actions";
import {checkPermission} from "../../../../../../utils/permissionHelper";
import { ApplicationState } from '../../../../../../store';
import EndUserCacheManager from '../../../../../../utils/cacheManagers/endUserCacheManager';
import { shouldReconfigRouter } from '../../../../../../utils/cacheLoaders/reloaderHelper';
import { isPathnameMatchingRoute } from '../../../../../../utils/urlHelper';
import { PARTICIPANT_WIZARD_EDIT_PATH, PARTICIPANT_WIZARD_PATH } from '../../../../../../routes';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import NotFound from '../../../../NotFound';
import { captureTentarooError } from '../../../../../../utils/dataHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isCacheFourEventsViewParticipantPopulated, isEndUserCacheOnePopulated} from '../../../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--event--registration--participant';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string, ya: string, pId: string, pName: string}>
>;

@(withRouter as any)
class Participant extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
  private nextLocation;

  reset = noop;

  public componentDidMount() {
    this.configRouter();
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheFourEventsViewParticipant({
          props: this.props,
          isStateNavigated,
        });
      }
    );
  }

  configRouter() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) {
      this.configRouter();
    }
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  componentWillUnmount() {
    const {cacheFourEventsViewParticipant} = this.props;

    // if we are not navigating to EventRegistrationParticipant page, clear cache
    if (
      this.nextLocation &&
      !isPathnameMatchingRoute(this.nextLocation.pathname, PARTICIPANT_WIZARD_PATH) &&
      !isPathnameMatchingRoute(this.nextLocation.pathname, PARTICIPANT_WIZARD_EDIT_PATH) &&
      isCacheFourEventsViewParticipantPopulated(cacheFourEventsViewParticipant)
    ) {
      this.props.actions.clearCacheBelowThreeEvents();
    }
    this.resetRouteLeaveHook();
  }

  public componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheFourEventsViewParticipant({
          props: nextProps,
          isStateNavigated,
        });
      }
    );
  }

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  onCancel = () => {
    const {selectedParticipant} = this.props;
    if (!selectedParticipant) {
      captureTentarooError(new Error('Cannot cancel without having a selectedParticipant'));
      return;
    }

    const {
      actions, selectedEventType, selectedEvent, cacheThreeEvents: {EventRegistrationPaymentStatus, EventInfoPermissions}, cacheTwoEvents:{eventType},
      user
    } = this.props;
    const isAdmin = user.user.str_permissions.hasAdminAccess;
    if (
      !EventInfoPermissions
    ) return;

    if (selectedEventType && selectedEvent && EventRegistrationPaymentStatus) {
      const showCancel = () => {
        actions.pushCancelRegistrationModal(
          selectedParticipant.IsYouth,
          selectedParticipant.Name,
          selectedParticipant.IDi,
          selectedParticipant.InCart,
          selectedEventType.ID,
          selectedEvent.IDi,
          EventRegistrationPaymentStatus.IDi,
          true,
          selectedParticipant.IsNewlyAdding,
        );
      };
      if (selectedParticipant.InCart === 0) {
        if (!EventInfoPermissions.hasRegistrationNames) {
          reduxStoreService().dispatch(new ShowTopFloatingAlert(EventInfoPermissions.hasRegistrationNamesReason ?? "", false, 'orange'));
        } else if (eventType !== 'participant' || isAdmin || selectedParticipant.IsNewlyAdding || EventInfoPermissions.IsRegistrationReductionAllowed) {
          showCancel();
        } else {
          reduxStoreService().dispatch(new ShowTopFloatingAlert(EventInfoPermissions.IsRegistrationReductionAllowedReason ?? "", false, 'orange'));
        }

      } else {
        checkPermission(
          showCancel,
          EventInfoPermissions.hasRegistrationNames,
          EventInfoPermissions.hasRegistrationNamesReason
        );
      }

    } else {
      // console.log('Cannot delete/cancel without selectedEventType, selectedEvent and EventRegistrationPaymentStatus');
    }
  };
  onEdit = () => {
    const {cacheThreeEvents: {EventInfoPermissions}} = this.props;

    if (!EventInfoPermissions) return;

    checkPermission(
      () => {
        navPush(this.props.router, getEventRegisterParticipantEditUrl(this.props));
      },
      EventInfoPermissions.hasRegistrationNames,
      EventInfoPermissions.hasRegistrationNamesReason
    );
  };
  onCheckout = () => navPush(this.props.router, URLS.CHECKOUT);

  onReportClassSchedules = () => {
    const {cacheThreeEvents: {EventInfo, EventInfoPermissions, EventRegistrationPaymentStatus}, selectedParticipant} = this.props;
    if (!EventInfo || !EventRegistrationPaymentStatus || !EventInfoPermissions) return;

    const EventTypeID = EventInfo.EventTypeID;
    const GroupWeekIDi = EventRegistrationPaymentStatus.IDi;

    if (!selectedParticipant) {
      captureTentarooError(new Error('Cannot report without having a selectedParticipant'));
      return;
    }
    const onReport = () => {
      const url = getViewParticipantsReportUrl({EventTypeID, GroupWeekIDi, ParticipantEventIDi: selectedParticipant.IDi, IsYouth: selectedParticipant.IsYouth});
      window.open(url);
    };
    checkPermission(
      onReport,
      EventInfoPermissions.hasViewReports,
      EventInfoPermissions.hasViewReportsReason
    );
  };

  renderScheduleButton = () => {
    return (
      <Button
        color="white"
        contextMenu={(
          <ContextMenu
            title="Generate Reports"
            info={(
              <ContextMenuInfo>
                Reports will not include any changes that are still in the cart
              </ContextMenuInfo>
            )}
          >
            <ContextMenuItem onClick={this.onReportClassSchedules}>Class Schedule, PDF</ContextMenuItem>
          </ContextMenu>
        )}
      >
        SCHEDULE
      </Button>
    );
  };

  renderCardControls = () => {
    const {cacheTwoEvents: {eventType}} = this.props;
    return <Media tablet desktop>
      <Button
        id={generateDOMId("manage-registration-btn")}
        color="white"
        disabled={this.props.apiLoading > 0 || this.props.apiSaving > 0}
        contextMenu={(
          <ContextMenu>
            <ContextMenuItem onClick={this.onEdit} id={generateDOMId("edit-registration-btn")}>Edit Registration</ContextMenuItem>
            <ContextMenuItem id={generateDOMId("cancel-registration-btn")} onClick={this.onCancel}>Cancel Registration</ContextMenuItem>
          </ContextMenu>
        )}
      >
        Manage Registration
      </Button>
    </Media>;
  };

  renderManageRegistration = () => (
    <Media mobile>
      <Button
        id={generateDOMId("manage-registration-btn")}
        color="white"
        disabled={this.props.apiLoading > 0 || this.props.apiSaving > 0}
        contextMenu={(
          <ContextMenu title="Manage Registration">
            <ContextMenuItem onClick={this.onEdit}>Edit Registration</ContextMenuItem>
            <ContextMenuItem id={generateDOMId("cancel-registration-btn")} onClick={this.onCancel}>Cancel Registration</ContextMenuItem>
          </ContextMenu>
        )}
        marginBottom={16}
      >
        Manage Registration
      </Button>
    </Media>
  );

  renderClass = (c: RegisteredClass, index: number) => {
    const {user: {user: {str_permissions}}, cacheTwoEvents: {EventTypeRegistrationSettings},
      cacheThreeEvents: {EventInfo}
    } = this.props;
    return (
      <Class key={index}
             classModel={c}
             isAdmin={str_permissions.hasAdminAccess}
             enableWaitlist={EventTypeRegistrationSettings ? EventTypeRegistrationSettings.NamesRegistrationSettings.EnableClassWaitingList : false}
             numYouth={EventInfo ? EventInfo.TotalNumbersYouth : 0}
             numAdults={EventInfo ? EventInfo.TotalNumbersAdults : 0}
             isRegistration
             isPreview={false}
             individual
             hideForYouth
      />
    );
  };

  renderClasses = () => {
    const {cacheFourEventsViewParticipant, selectedParticipant} = this.props;
    const {EventClassesIndividualRegistered} = cacheFourEventsViewParticipant;
    if (!selectedParticipant) return null; // should never happen
    const partialLoading = !isCacheFourEventsViewParticipantPopulated(cacheFourEventsViewParticipant);
    const ret: Array<any> = [
      <Title num={selectedParticipant.NumClasses} controls={this.renderScheduleButton()}>
        Classes
      </Title>
    ];

    if (partialLoading) ret.push(<PageLoader/>);
    else {
      ret.push(
        <View layout="vertical">
          {EventClassesIndividualRegistered && EventClassesIndividualRegistered.map(this.renderClass)}
        </View>
      );
    }

    return ret;
  };

  public render() {
    const {
      cacheTwoEvents: {eventType}, apiLoadingMap, selectedEventType, selectedEvent, selectedParticipant, cacheThreeEvents, cacheOne, selectedParticipantType, apiSaving, inert} = this.props;
    if (!isEndUserCacheOnePopulated(cacheOne) || !cacheThreeEvents || !selectedEventType || !(selectedEvent || cacheThreeEvents.EventInfo) ||
      !selectedParticipant || !selectedParticipantType) {
      return <LoadingAll/>;
    }

    if (selectedParticipant.InCart === -1) {
      return <NotFound message="This participant is being removed in the cart." />;
    }

    let summaryTotal: number = selectedParticipant.ClassAmount;
    if (eventType === 'participant') summaryTotal += selectedParticipant.RegistrationTotal;

    return (
      <Main inert={inert} mobileBackground="white">
        <MainContent className={`${namespace()}--main`} loading={!!apiLoadingMap[EventParticipantCalc.requestType] || apiSaving > 0}>
          {selectedParticipant.InCart !== 0 && <Alert
            noFlex
            actions={(
              <AlertActions>
                <Button flat textColor="white" icon={CartIconIcon} onClick={this.onCheckout}>Go to checkout</Button>
              </AlertActions>
            )}
          >
            There are changes or payments for this participant in your cart.
            Please remember to checkout soon to finalize your changes.
          </Alert>}

          <Card className={`${namespace()}--card`} template="mobile-stretch" marginBottom={32} mobileMarginBottom={16}>
            <CardTitle
              className={`${namespace()}--card-title`}
              marginBottom={2}
              extra={selectedParticipant.InCart === 1 ? <Icon marginLeft={14} mobileMarginLeft="auto" size={28} color="green" icon={CartIconIcon}/> : undefined}
              controls={this.renderCardControls()}
            >
              {selectedParticipant.Name}
            </CardTitle>
            <CardSubTitle>{selectedParticipantType.NameWithYA}</CardSubTitle>
            {this.renderManageRegistration()}

            <View layout="vertical">
              {
                /* Only show registration fees for individual if this is a Names-only registration. */
                eventType === 'participant' ?
                  ( <FinancialSummaryLine disabled={false} label="Registration Fees" value={standardCurrencyFormat(selectedParticipant.RegistrationTotal)}/> ) :
                  null
              }
              {selectedParticipant.NumClasses > 0 && <FinancialSummaryLine disabled={false} label="Classes" value={standardCurrencyFormat(selectedParticipant.ClassAmount)}/>}
              {(selectedParticipant.NumClasses > 0 || eventType === 'participant') && <FinancialSummaryTotal noMarginBottom title="Total" value={standardCurrencyFormat(summaryTotal)}/>}
            </View>

            {selectedParticipant.Notes && <View layout="vertical">
              <Text color="gray" size={13} marginBottom={4}>Registration Notes</Text>
              <Text>{selectedParticipant.Notes}</Text>
            </View>}
          </Card>
          {selectedParticipant.NumClasses > 0 && this.renderClasses()}
        </MainContent>
      </Main>
    );
  }
}

// @todo: remove some unused states

const mapStateToProps = (state: ApplicationState) => {
  const selectedEventType = makeSelectedEventTypeSelector();
  const selectedEvent = makeSelectedEventSelector();
  const selectedParticipant = makeSelectedParticipantSelector();
  const selectedParticipantType = makeSelectedParticipantTypeSelector();
  return {
    user: state.user,
    eventType: state.events.eventType,
    event: state.events.event.main,
    eventRegistration: state.events.event.registration,
    systemSettings: state.session.SystemSettings,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    apiLoading: state.app.apiLoading,
    cacheFourEventsViewParticipant: state.cacheFourEventsViewParticipant,
    cacheThreeEvents: state.cacheThreeEvents,
    cacheTwoEvents: state.cacheTwoEvents,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    selectedEvent: selectedEvent(state),
    selectedEventType: selectedEventType(state),
    selectedParticipant: selectedParticipant(state),
    selectedParticipantType: selectedParticipantType(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...cacheFourActionCreators,
  ...cacheThreeActionCreators,
  ...cacheTwoActionCreators,
  ...cacheOneActionCreators,
  ...eventTypeActionCreators,
  ...eventActionsCreators,
  ...rollbackActionCreators,
  ...appActionCreators
}, dispatch)});

const ConnectedParticipant = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(Participant);

export default ConnectedParticipant;
