import {
  createApiSubmitActions,
  createValidateActions,
  createApiValidateActions,
  createUpdateValueMethod,
  createSimpleUpdateValueMethod,
  innerApiSubmitFormMethod,
} from "../../../Validation/actionCreator";
import { ActionCreator, ApplicationState } from '../../..';

export const SUFFIX = '__ADMIN_EVENTS__REPORTS';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const GenerateAdminEventsEventTypeReportsActions = createApiSubmitActions('__GENERATE_REPORTS' + SUFFIX, true);

export type Actions = typeof actionCreators;
export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.reports.generateReports, true, undefined, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  generateEventTypeReports: (path: string, EventTypeID: number): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    const valid = innerApiSubmitFormMethod(
      dispatch,
      GenerateAdminEventsEventTypeReportsActions,
      (s: ApplicationState) => s.adminEvents.reports.generateReports,
      undefined,
      undefined,
      undefined,
      true,
    );

    if (valid) {
      dispatch(GenerateAdminEventsEventTypeReportsActions.request({
        path,
        EventTypeID,
        Year: state.adminEvents.reports.generateReports.ActiveForm.Year
      }, null));
    }
  },
};