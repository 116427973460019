import {
  createApiSubmitActions,
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions
} from "../../../../Validation/actionCreator";
import {validateAll} from "../../../../../utils/validator";
import {ActionCreator, appActionCreators} from "../../../../index";
import {CancelParticipantsParams, EVENT, eventParticipantsDeleteBody} from "../../../../../constants/urls";
import {push} from "react-router-redux";
import {SaveState} from "../../../../Rollback/actions";
import {getTotalAmount} from "../../../../../utils/helpers/financialSummaryHelper";
import {EventParticipantsSave} from "../../../../CacheFourEventsParticipants/actions";
import {ShowTopFloatingAlert} from "../../../../App/actions";
import { determineError } from ".";
import { captureTentarooError } from "../../../../../utils/dataHelper";
import { ModalTypes } from "../../../../../utils/modalHelper";
import { typeName, Action } from "../../../../../utils/StrongActions";
import { ClearCacheBelowThreeEvents } from "../../../../CacheThreeEvents/actions";
import { Validator } from "../../../../../utils/validator/models";
import {isCacheThreeEventsPopulated} from "../../../../../utils/cachePopulatedCheckers/endUser";

export const SUFFIX = '__CANCEL_REGISTRATION';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const CancelRegistrationActions = createApiSubmitActions(SUFFIX);

@typeName(`RESET${SUFFIX}`) export class CancelRegistrationReset extends Action {}

export type Actions = typeof actionCreators;

const shouldSave = (value: any, vObj: Validator) => {
  return false;
};

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.events.event.modals.cancelRegistration, true, shouldSave, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  reset: (): ActionCreator => dispatch => dispatch(new CancelRegistrationReset()),
  updateRegistration: (
    IsYouth: boolean,
    ParticipantRegistrationIDi: number,
    eventTypeID: number,
    eventID: number,
    groupWeekID: number,
    name: string,
    isRestore?: boolean,
    goToRegistration?: boolean
  ): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const rootState = getState();
    const options = rootState.cacheZero.options;
    const c3 = rootState.cacheThreeEvents;
    const c4 = rootState.cacheFourEventsParticipants;

    if (!c4.EventRegistrationPaymentStatus) {
      captureTentarooError(new Error("c4.EventRegistrationPaymentStatus not available when performing updateRegistration"));
      return;
    }
    if (!options) {
      captureTentarooError(new Error("options not available when performing updateRegistration"));
      return;
    }
    if (!options.Group) {
      captureTentarooError(new Error("Group not available when performing updateRegistration"));
      return;
    }
    if (!c4.EventRegistrationPerson) {
      captureTentarooError(new Error("c4.EventRegistrationPerson not available when performing updateRegistration"));
      return;
    }

    const validationResults = validateAll(s => s.events.event.modals.cancelRegistration);

    if (validationResults) {
      const errorMessage = determineError(validationResults.Errors);
      if (errorMessage) dispatch(new ShowTopFloatingAlert(errorMessage, true, 'orange'));
    } else {
      let newAmount: undefined | number = getTotalAmount(
        rootState.events.event.modals.cancelRegistration.ActiveForm,
        c4.EventRegistrationPaymentStatus
      );

      const route = rootState.routing.locationBeforeTransitions;
      const sp = route.pathname.split('/');

      if (goToRegistration) {
        const newPath = `/${sp[1]}/${sp[2]}/${sp[3]}/${sp[4]}/${sp[5]}/${EVENT.REGISTRATION}`;
        dispatch(push(`${newPath}${route.search}`));
      }

      // NOTE: There is a save state at the beginning, so here we set both saveBefore and saveAfter to false
      dispatch(appActionCreators.popModal(false, false, ModalTypes.CANCEL_REGISTRATION) as any);
      // move before request so the LoadingAll spinner won't block you

      const params: CancelParticipantsParams = {
        GroupIDi: options.Group.IDi,
        GroupTS: options.Group.TS,
        EventTypeID: eventTypeID,
        EventIDi: eventID,
        ParticipantRegistrationIDi: ParticipantRegistrationIDi,
        IsYouth: IsYouth,
        ClassRegistrations: [],
        ProposedInactive: !isRestore,
        ParticipantTypeID: undefined,
        PersonIDi: undefined,
        GroupWeekIDi: groupWeekID,
        EventRegistrationPerson: c4.EventRegistrationPerson,
        TotalAmountChange: 0,
        AmountChangeInCart: c4.EventRegistrationPaymentStatus.AmountChangeInCart,
        HasMultiWeekDiscount: c4.EventRegistrationPerson.HasMultiWeekDiscount,
      };
      if (newAmount !== undefined) {
        params.TotalAmountChange = newAmount - c4.EventRegistrationPaymentStatus.AmountInCart;
      }

      dispatch(EventParticipantsSave.request(
        eventParticipantsDeleteBody(params),
        {
          name,
          isCacheThreeEventLoaded: isCacheThreeEventsPopulated(getState().cacheThreeEvents) && getState().cacheThreeEvents.eventID === eventID,
          leavingCacheLevelOnSuccess: undefined,
          inMatchingCacheLevelOn409: undefined,
        })
      );
      // If cancel modal is opened on top of ViewParticipant, we need to rely on `ClearCacheBelowThreeEvents`
      // to clear cache for ViewParticipant page as well.
      dispatch(new ClearCacheBelowThreeEvents());
    }
  },
};
