import * as React from 'react';
import {bindActionCreators} from 'redux';

import Group from './Group';
import Item from './Item';
import * as Icons from '../../Icons';
import {RouteComponentProps, withRouter, WithRouterProps} from 'react-router';
import '../../../styles/app/nav/nav.scss';
import {actionCreators as sessionActionCreators} from '../../../store/Session/actions';
import {actionCreators as appActionCreators} from '../../../store/App/actions';
import {
  NAVS,
  openSupportForm,
  URLS
} from '../../../constants/urls';
import {ApplicationState} from "../../../store";
import {
  FACILITY_WIZARD_EDIT_PATH,
  FACILITY_WIZARD_PATH,
  NUMBERS_WIZARD_PATH,
  PARTICIPANT_WIZARD_EDIT_PATH,
  PARTICIPANT_WIZARD_PATH,
  PRODUCTS_WIZARD_PATH,
} from "../../../routes";
import { ModalTypes } from '../../../utils/modalHelper';
import { generateDOMId } from '../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

import {navNamespace as namespace} from "./constants";
import {isEndUserCacheOnePopulated} from '../../../utils/cachePopulatedCheckers/endUser';

type NavSize = 'compact' | 'normal';
interface Props {
  selected?: string;
  size?: NavSize;
  hideClient?: boolean;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

class Nav extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;

  public static defaultProps: Props = {
    size: 'normal'
  };

  public showFeedback = () => {
    openSupportForm();
  };

  public showCart = () => {
    this.props.actions.pushCartModal();
  };

  public logout = () => {
    this.props.actions.apiRequestLogout();
  };

  public showAdminFacilities = () => {
    this.props.actions.pushSelectLocationModal({context: 'admin-facility-location', isFacilities: true});
  };

  public showAirApp = () => {
    this.props.actions.pushModal(ModalTypes.TENTAROO_AIR_APP, false, true);
  };

  public showAdminEvents = () => {
    this.props.actions.pushModal(ModalTypes.ADMIN_EVENT_TYPES, false, true);
  };

  public showAdminCMSSites = () => {
    this.props.actions.pushModal(ModalTypes.CMS_SITES, false, true);
  };

  public showAccounts = () => {
    this.props.actions.pushAccountsModal();
  };

  public render() {
    const { selected, user, size, CartOrderItems, cacheOne, hideClient, cacheZeroOptions, session, apiSaving, routes } = this.props;
    let admin: boolean = false;
    if (user.user) admin = !!user.user.str_permissions.hasAdminAccess;
    let disabled: string = '';
    if (apiSaving > 0) disabled = 'disabled';
    const lastRoute = routes[routes.length - 1].path;

    const isWizard = lastRoute === PARTICIPANT_WIZARD_PATH || lastRoute === PARTICIPANT_WIZARD_EDIT_PATH || lastRoute === NUMBERS_WIZARD_PATH ||
      lastRoute === PRODUCTS_WIZARD_PATH || lastRoute === FACILITY_WIZARD_PATH || lastRoute === FACILITY_WIZARD_EDIT_PATH;

    const ShowEndUserFacilities = !isEndUserCacheOnePopulated(cacheOne) ? session.SystemSettings?.ShowEndUserFacilities : cacheOne.FacilityLocations;
    return (
      <div className={`${namespace()} ${size} ${disabled}`}>
        {cacheZeroOptions && cacheZeroOptions.Group && !hideClient && <Group name={admin ? 'Client' : undefined}>
          <Item name="Home" to={URLS.HOME} icon={Icons.HomeIcon} selected={selected === NAVS.HOME} marginTop={!admin} size={size} />
          {session.SystemSettings?.ShowEndUserEvents && <Item id={generateDOMId("app-nav-events")} name="Events" to={URLS.EVENT_TYPES} icon={Icons.EventsIcon} selected={selected === NAVS.EVENTS} size={size} />}
          {ShowEndUserFacilities && <Item id={generateDOMId("app-nav-fac")} name="Facilities" to={URLS.FACILITIES} icon={Icons.FacilitiesIcon} selected={selected === NAVS.FACILITIES} size={size} />}
          {!isWizard && <Item id={generateDOMId("app-nav-cart")} name="Cart"  num={CartOrderItems ? CartOrderItems.length : undefined} onClick={this.showCart} icon={Icons.CartIcon} size={size} />}
          {isWizard && <Item name="Checkout" to={URLS.CHECKOUT} num={CartOrderItems ? CartOrderItems.length : undefined} selected={selected === NAVS.CHECKOUT} icon={Icons.CartIcon} size={size} />}
          {admin && <Item id={generateDOMId("app-nav-user-settings")} name="Settings" to={URLS.PROFILE} icon={Icons.SettingsIcon} selected={selected === NAVS.SETTINGS} size={size} />}
          {admin && <Item onClick={() => window.open("https://users.tentaroo.com/", "_blank")} name="Help Center" icon={Icons.HelpIcon} size={size} />}
        </Group>}
        {!(cacheZeroOptions && cacheZeroOptions.Group && !hideClient) && size !== 'normal' && <Group>
          <Item name="Select client" onClick={this.showAccounts} icon={Icons.GroupIcon} size={size}/>
        </Group>}
        {!admin && (
          <Group>
            <Item name="Settings" to={URLS.PROFILE} icon={Icons.SettingsIcon} selected={selected === NAVS.SETTINGS} size={size}/>
            <Item onClick={() => window.open("https://users.tentaroo.com/", "_blank")} name="Help Center" icon={Icons.HelpIcon} size={size} />
            <Item name="Feedback" onClick={this.showFeedback} icon={Icons.FeedbackIcon} size={size} />
          </Group>
        )}
        {admin && (
          <Group isAdmin name="Admin" size={size}>
            <Item id={generateDOMId("app-nav-events")} name="Events" onClick={this.showAdminEvents} icon={Icons.EventsIcon} size={size} selected={selected === NAVS.ADMIN_EVENTS} />
            <Item id={generateDOMId("app-nav-admin-fac")}  name="Facilities" selected={selected === NAVS.FACILITY_LOCATION} onClick={this.showAdminFacilities} icon={Icons.FacilitiesIcon} size={size} />
            <Item id={generateDOMId("app-nav-admin-cms")}  name="Websites" selected={selected === NAVS.WEBSITES} onClick={this.showAdminCMSSites} icon={Icons.WebsitesIcon} size={size} />
            <Item id={generateDOMId("app-nav-admin-settings")} name="Settings" onClick={this.showAirApp} icon={Icons.SettingsIcon} size={size} />
            <Item onClick={() => window.open("https://admin.tentaroo.com/", "_blank")} name="Admin Manual" icon={Icons.HelpIcon} size={size} />
          </Group>
        )}
        <Group>
          <Item id={size === "normal" ? generateDOMId("app-nav-logout") : undefined} name="Log out" onClick={this.logout} icon={Icons.LogoutIcon} size={size}>
            <span>{user.user ? (user.user.Username ? user.user.Username : user.user.Email) : ''}</span>
          </Item>
        </Group>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    user: state.user,
    session: state.session,
    cacheZeroOptions: state.cacheZero.options,
    cacheOne: state.cacheOne,
    CartOrderItems: state.cacheOne.CartOrderItems,
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
    app: state.app
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...sessionActionCreators,
      ...appActionCreators
    }, dispatch
    )
});

const ConnectedNav = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(Nav);

export default withRouter<Props, {}>(ConnectedNav);
