import { typeName, Action } from '../../../utils/StrongActions';
import { ActionCreator } from '../../index';
import {SaveState} from "../../Rollback/actions";

@typeName("EVENT_TYPE_TOGGLE_MAP_MOBILE") export class EventTypeToggleMapMobile extends Action {}
@typeName("EVENT_TYPE_SHOW_SIDEBAR") export class EventTypeShowSidebar extends Action {
  constructor(public show: boolean) { super(); }
}
@typeName("EVENT_TYPE_SELECT_TAB") export class EventTypeSelectTab extends Action {
  constructor(public selectedTab: 'upcoming' | 'current' | 'past') {
    super();
  }
}
@typeName("EVENT_TYPE_RESET") export class EventTypeReset extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  resetEventType: (): ActionCreator => dispatch => dispatch(new EventTypeReset()),
  toggleMapMobile: (): ActionCreator => dispatch => dispatch(new EventTypeToggleMapMobile()),
  showSidebar: (show: boolean): ActionCreator => dispatch => dispatch(new EventTypeShowSidebar(show)),
  selectTab: (selectedTab: 'upcoming' | 'current' | 'past'): ActionCreator => dispatch => {
    dispatch(new EventTypeSelectTab(selectedTab));
    dispatch(new SaveState());
  }
};
