import React from "react";
import { DEFAULT_CKEDITOR_CONFIG } from "../../../utils/ckeditorHelper";

import CKEditor4 from 'ckeditor4-react';
import { getDomain } from "../../../utils/urlHelper";

export const _CKEditor = CKEditor4;
// using ckeditor 4.6.2 because this is the latest version supporting paste via context menu and paste toolbutton
// see issue: https://github.com/ckeditor/ckeditor4/issues/469
export const CKEDITOR_URL = `${getDomain(true)}/ckeditor_4.6.2/ckeditor.js`;
(_CKEditor as any).editorUrl = CKEDITOR_URL;

interface CKEditorProps {
  config?: any;
  data: any;
  readOnly?: boolean;
  onChange: Function;
  onMode?: Function;
  onReady?: Function;
}

interface CKEditorState {
  presetAvailable: boolean;
}

// onBeforeLoad callback - related to this issue `https://github.com/ckeditor/ckeditor4-react/issues/57`, to fix the ckeditor console error
class CKEditor extends React.PureComponent<CKEditorProps, CKEditorState> {
  public state: CKEditorState = {
    presetAvailable: false,
  };
  componentDidMount() {
    if (!(window as any).CKEDITOR) {
      const script = document.createElement("script");
      script.src = CKEDITOR_URL;
      script.async = true;
      script.type = "text/javascript";
      script.crossOrigin = "anonymous";
      script.onload = () => {
        // on loaded, ckeditor preset is available, render UI
        this.setState({
          presetAvailable: true,
        });
      };

      script.onerror = () => {
        // on error, remove the script from document, and because `presetAvailable` is not set, editor won't be loaded
        // todo: should we show something in UI in this case?
        document.head.removeChild(script);
      };
      document.head.appendChild(script);
    } else {
      // if `CKEDITOR` is found, it means we have successfully loaded the editor before, and therefore could render UI directly without doing any script loading
      this.setState({
        presetAvailable: true,
      });
    }
  }

  render() {
    const {data, onChange, config, onReady, readOnly, onMode} = this.props;

    if (!this.state.presetAvailable) {
      return null;
    }
    return (
      <_CKEditor
        config={config || DEFAULT_CKEDITOR_CONFIG}
        readOnly={readOnly}
        data={data}
        onBeforeLoad={ (CKEDITOR) => CKEDITOR.disableAutoInline = true }
        onChange={onChange}
        onInstanceReady={onReady}
        onMode={onMode ? onMode: undefined}
      />
    );
  }
}

export default CKEditor;
