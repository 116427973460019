import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import {
  actionCreators as cacheZeroActionCreators
} from '../../../../../store/CacheZero/actions';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { 
  actionCreators as rollbackActionCreators,
} from '../../../../../store/Rollback/actions';
import { actionCreators } from '../../../../../store/AdminEvents/Reports/YearOverview/actions';
import { actionCreators as cacheOneActionCreators } from '../../../../../store/AdminEvents/CacheOne/actions';
import { Row, Column, Select, Alert, YearOverviewCard, EmptyMessage } from '../../../../../components/Elements';
import '../../../../../styles/pages/admin-events/reports/home/year-overview.scss';
import { YearOverViewType } from '../../../../../store/AdminEvents/Reports/YearOverview/validation';
import { extractRouteParams } from '../../../../../utils/urlHelper';
import { EmptyMessageType } from '../../../../Elements/EmptyMessage';
import { EventsIcon } from '../../../../Icons';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';

export const namespace = (): string => 'pages--admin-events--year-overview';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> &RouteComponentProps<{}, {resourceId: string}>;

interface Props {
}

class YearOverview extends React.Component<Props, {}> {
  public props: ConnectedProps & Props;

  renderOverviews() {
    const {adminEventsCacheOne, adminEventsYearOverviewForm: {ActiveForm}} = this.props;

    switch (ActiveForm.View) {
      case YearOverViewType.BY_COUNCIL:
        if (!this.props.adminEventsCacheOne.YearOverviewByCouncil) return [];
        const overviewByCouncil = this.props.adminEventsCacheOne.YearOverviewByCouncil.map((o) => {
          return !adminEventsCacheOne.EventsEventType ? null : <YearOverviewCard RegistrationMethodID={adminEventsCacheOne.EventsEventType.General.RegistrationMethodID} type={YearOverViewType.BY_COUNCIL} key={o.Ord} overview={o} />;
        });

        overviewByCouncil.splice(1, 0, <div key='divider' className={`${namespace()}--divider`}></div>);

        return overviewByCouncil;
      case YearOverViewType.BY_DISTRICT:
        if (!this.props.adminEventsCacheOne.YearOverviewByDistrict) return [];
        return this.props.adminEventsCacheOne.YearOverviewByDistrict.map((o) => {
          return !adminEventsCacheOne.EventsEventType ? null : <YearOverviewCard RegistrationMethodID={adminEventsCacheOne.EventsEventType.General.RegistrationMethodID} type={YearOverViewType.BY_DISTRICT} key={o.Ord} overview={o} />;
        });
      case YearOverViewType.BY_EVENT:
        if (!this.props.adminEventsCacheOne.YearOverviewByEvent) return [];
        return this.props.adminEventsCacheOne.YearOverviewByEvent.map((o) => {
          return !adminEventsCacheOne.EventsEventType ? null : <YearOverviewCard RegistrationMethodID={adminEventsCacheOne.EventsEventType.General.RegistrationMethodID} type={YearOverViewType.BY_EVENT} key={o.Ord} overview={o} />;
        });
      case YearOverViewType.BY_PARTICIPANT:
        let firstAdultIndex = -1;
        let hasYouth;
        let hasAdults;
        if (!this.props.adminEventsCacheOne.YearOverviewByParticipantType) return [];
        const overviewByParticipant = this.props.adminEventsCacheOne.YearOverviewByParticipantType.map((o, index) => {
          if (o.IsYouth) hasYouth = true;
          if (!o.IsYouth) hasAdults = true;
          if (firstAdultIndex === -1 && !o.IsYouth) firstAdultIndex = index;
          return !adminEventsCacheOne.EventsEventType ? null : <YearOverviewCard RegistrationMethodID={adminEventsCacheOne.EventsEventType.General.RegistrationMethodID} type={YearOverViewType.BY_PARTICIPANT} key={o.Ord} overview={o} />;
        });

        if (hasYouth) overviewByParticipant.unshift(<div className={`${namespace()}--category`}>Youth</div>);
        if (hasAdults) overviewByParticipant.splice(firstAdultIndex + 1, 0, <div className={`${namespace()}--category`}>Adults</div>);

        return overviewByParticipant;
    }
  }

  updateYear = (v, vobj) => {
    this.props.actions.saveState();
    this.props.actions.updateValue(v, vobj);
    this.props.actions.refresh({
      GetYearOverview: true,
      YearOverview_Year: v,
      ...extractRouteParams(this.props),
    }, false);
  };

  public render() {
    const {adminEventsCacheOne, actions, adminEventsYearOverviewForm: {SubmitErrorMessage, ActiveForm, ValidationRules}} = this.props;

    const firstLoad = !ActiveForm.Year;

    return (
      <div className={`${namespace()}`}>
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        {!firstLoad && <Row marginTop={24} mobileMarginTop={16} marginBottom={8}>
          <Column marginRight={24} mobileMarginRight={0} span={4} mobileSpan={6}>
            <Select
              label="Year"
              onChangeValue={this.updateYear}
              validationRules={ValidationRules.Year}
              value={ActiveForm.Year}/>
          </Column>
          <Column mobileMarginTop={8} span={8} mobileSpan={12}>
            <Select
              label="View"
              onChangeValue={actions.updateValue}
              validationRules={ValidationRules.View}
              value={ActiveForm.View}
              isNumber
            />
          </Column>
        </Row>}
        {!firstLoad && <div className={`${namespace()}--overview-list`}>
          {!!adminEventsCacheOne.YearOverviewByEvent ? this.renderOverviews() : null}
          {!adminEventsCacheOne.YearOverviewByEvent && (
            <EmptyMessage
              type={EmptyMessageType.PAGE_MARGIN}
              icon={EventsIcon}
              description='No events found'
            />
          )}
        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsYearOverviewForm: state.adminEvents.reports.yearOverview, 
    cacheZero: state.cacheZero,
    session: state.session,
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheZeroActionCreators,
    ...appActionCreators,
    ...rollbackActionCreators,
    ...cacheOneActionCreators,
  }, dispatch)
});
const ConnectedYearOverview = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(YearOverview);

export default withRouter<Props>(ConnectedYearOverview);
