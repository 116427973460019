import * as React from 'react';
import PropTypes from 'prop-types';

import Card from "../Card";
import Text from "../Text";
import Button from "../Button";
import RemoveButton from "../Button/removeButton";
import Row from "../Grid/Row";
import Select from "../Select";
import Option from "../Select/Option";
import Vignette from "../Vignette";
import CartIcon from "../CartIcon";
import MoneyField from "../MoneyField";
import AdminBadge from "../AdminBadge";
import View from "../View";
import Ellipsis from "../Ellipsis";

import '../../../styles/elements/product/index.scss';
import { AppInterface } from '../../App';
import {OrderedProduct, Product as ProductModel} from '../../../models/product';
import {createInitials, getImagePath, noop} from '../../../utils';
import {ItemPriceVRules, QuantityVRules} from "../../../store/Events/Event/Register/Products/Products/validation";
import SlideShow from '../../Pages/Events/Event/Products/SlideShow';
import {ShowTopFloatingAlert} from "../../../store/App/actions";
import {validate} from "../../../utils/validator";
import { captureTentarooError } from '../../../utils/dataHelper';
import { reduxStoreService } from '../../../store/service';

const nameBase = 'elements--product';
export const namespace = (isCompact: boolean, isSummary: boolean): string =>
  `${isCompact ? 'compact' : 'not-compact'} ${isSummary ? 'is-summary' : 'not-is-compact'} ${nameBase}`;

interface Props {
  productModel: OrderedProduct;
  // @todo, we'll have to pass in some attribute(s) for the slideshow
  slideShow?: React.ReactElement<any>;
  isCompact?: boolean;
  isSummary?: boolean;
  isRegistrationSummary?: boolean;
  isAdmin?: boolean;
  ordered?: boolean;
  onClick?: () => void;
  onAddProduct?: (product: ProductModel) => void;
  onUpdateProduct?: (product: ProductModel, attributes: any) => void;
  onRemoveProduct?: (product: ProductModel) => void;
  onManage?: (product: ProductModel) => void;
  onCommit?: (product: ProductModel) => void;
  onCancel?: (product: ProductModel) => void;
}

interface Context {
  app: AppInterface;
}

class Product extends React.Component<Props, {}> {
  // @todo: get rid of context
  public static contextTypes = {
    app: PropTypes.object,
  };

  public props: Props;
  public context: Context;

  public handleImageClick = (event: React.SyntheticEvent<any>) => {
    event.stopPropagation();
    event.preventDefault();
    if (this.props.productModel.FeaturedImage && this.props.productModel.FeaturedImage.ImageOriginal) {
      this.context.app.addModal(<SlideShow image={this.props.productModel.FeaturedImage}/>);
    }
  };

  makeSizeOption = (size) => <Option>{size}</Option>;
  onChangeQuantity = (e) => this.props.onUpdateProduct ? this.props.onUpdateProduct(this.props.productModel, {Quantity: Number(e.target.value)}) : noop();
  onChangePrice = (val) => {
    this.props.onUpdateProduct ? this.props.onUpdateProduct(this.props.productModel, {Amount: val}) : noop();
  };
  onBlurPrice = (val, validationRule) => {
    this.props.onUpdateProduct ? this.props.onUpdateProduct(this.props.productModel, {Amount: val}) : noop();
  };
  onManage = () => {
    if (this.props.onManage) {
      if (this.props.productModel.AllowSelfRegistration || this.props.isAdmin) {
        this.props.onManage(this.props.productModel);
      } else {
        reduxStoreService().dispatch(new ShowTopFloatingAlert('Only an admin user can change this product order', false, 'orange'));
      }
    } else {
      captureTentarooError(new Error("Manage button in Product card should not be shown"));
    }
  };
  onRemove = () => {
    if (this.props.onRemoveProduct) {
      if (this.props.productModel.AllowSelfRegistration || this.props.isAdmin) {
        this.props.onRemoveProduct(this.props.productModel);
      } else {
        reduxStoreService().dispatch(new ShowTopFloatingAlert('Only an admin user can remove this product order', false, 'orange'));
      }
    } else {
      captureTentarooError(new Error("Remove button in Product card should not be shown"));
    }
  };
  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel(this.props.productModel);
    } else {
      captureTentarooError(new Error("Cancel button in Product card should not be shown"));
    }
  };
  onCloseManage = () => {
    if (this.props.productModel.editAmount === undefined) {
      reduxStoreService().dispatch(new ShowTopFloatingAlert('Please enter an amount before updating.', false, 'orange'));
    } else {
      const validationResults = validate(ItemPriceVRules, this.props.productModel.editAmount);
      const error = validationResults && validationResults.Errors && validationResults.Errors[ItemPriceVRules.key] ? validationResults.Errors[ItemPriceVRules.key][0] : '';
      if (this.props.onCommit && !error) {
        this.props.onCommit(this.props.productModel);
      } else if (error) {
        reduxStoreService().dispatch(new ShowTopFloatingAlert(error, false, 'orange'));
      } else {
        captureTentarooError(new Error("Update button in Product card should not be shown"));
      }
    }
  };
  getOrderedOnText = () => {
    const { productModel: { DateOrdered } } = this.props;
    if (DateOrdered) {
      return `Ordered on ${DateOrdered.format('MMM DD, YYYY')}`;
    }
    return null;
  };

  private renderPrice(ns, marginBottom: number, renderInRow: boolean) {
    const { isSummary } = this.props;
    const { Amount, Quantity } = this.props.productModel;
    let price = Amount;
    if (Quantity !== undefined) {
      const totalPrice = (price * Quantity).toFixed(2);
      return (
        <View marginBottom={marginBottom} className={`${ns}--price-container ${renderInRow ? 'render-row' : 'render-column'}`} key="price-multi">
          <Text weight="bold" size={16} mobileSize={16} marginRight={renderInRow ? 16 : 0} className={`${ns}--total-amount`} renderMobile={false} key="price-total">{`$${totalPrice}`}</Text>
          <Text color="gray" size={13} mobileSize={13} renderMobile={false} key="price-many">
            {`$${price.toFixed(2)} x ${Quantity} pc${Quantity !== 1 ? 's' : ''}.`}
          </Text>
        </View>
      );
    }
    return <Text weight="bold" size={isSummary ? 16 : 18} mobileSize={16} marginBottom={marginBottom} renderMobile={false} key="price-single">{`$${price.toFixed(2)}`}</Text>;
  }

  private renderDropdownsAndButton(ns: string, buttonLabel: string, buttonAction: any, showCancel?: boolean) {
    const { isCompact, isAdmin } = this.props;
    const { editQuantity, Quantity, editAmount } = this.props.productModel;
    const ret: any = [];
    const Sizes = undefined;


    /*
    {Sizes && <Select className={`${ns}--size-select`} label="Size" noBottomPadding>
      {Sizes.map(this.makeSizeOption)}
    </Select>}
    */

    ret.push(
      <Row marginBottom={16} key="select-row">
        {Sizes && <Select className={`${ns}--size-select`} label="Size" noBottomPadding>
          {(Sizes as any).map(this.makeSizeOption)}
        </Select>}
        <Select className={`${ns}--quantity-select`}
                label="Quantity"
                noBottomPadding
                onChange={this.onChangeQuantity}
                value={editQuantity ? editQuantity : Quantity}
                validationRules={QuantityVRules}
        />
      </Row>
    );
    if (isAdmin) {
      ret.push(
        <Row marginBottom={16} className={`${ns}--admin-price-row`} layout="horizontal" verticalAlignment="middle" key="admin-price-row">
          <div className={`${ns}--item-price-text`}>
            <Text size={16}>Item Price:</Text>
            <AdminBadge marginLeft={8} marginRight={2} />
          </div>
          <MoneyField className={`${ns}--item-price-value`} onBlur={this.onBlurPrice} placeHolder="$0.00" padding={false} onChange={this.onChangePrice} value={editAmount} validationRules={ItemPriceVRules}/>
        </Row>
      );
    }
    if (!showCancel) {
      ret.push(
        <Row className={`${ns}--price-row`} layout="horizontal" key="price-row">
          <Button marginTop={!isCompact ? 2 : 0} marginBottom={!isCompact ? 2 : 0} color="green" onClick={buttonAction.bind(this, this.props.productModel)} marginRight={24} mobileMarginRight={16}>
            {buttonLabel}
          </Button>
          {this.renderPrice(ns, 0, false)}
        </Row>
      );
    } else {
      ret.push(
        <Row className={`${ns}--price-row has-cancel`} layout="horizontal" key="price-row">
          <Button color="gray" textColor="black" flat onClick={this.onCancel}>CANCEL</Button>
          <Button
            className={`${ns}--product-cancel`}
            marginTop={!isCompact ? 2 : 0}
            marginBottom={!isCompact ? 2 : 0}
            color="green"
            onClick={buttonAction.bind(this, this.props.productModel)}
          >
            {buttonLabel}
          </Button>
        </Row>
      );

      ret.push(<Row layout="horizontal" key="price-extra-row" marginTop={16}>
        {this.renderPrice(ns, 0, true)}
      </Row>);
    }

    return ret;
  }

  private renderActionRow(ns, onAddProduct) {
    if (onAddProduct) return this.renderDropdownsAndButton(ns, 'ADD PRODUCT', onAddProduct);

    return (<Row className={`${ns}--action-row`}>
      {this.renderPrice(ns, 4, true)}
      {this.props.ordered && this.props.productModel.DateOrdered && <Text size={13} color="green" key="ordered-date">{this.getOrderedOnText()}</Text>}
    </Row>);
  }

  private renderSummaryActionRow(ns) {
    const ret: Array<any> = [];
    ret.push(this.renderPrice(ns, 4, true));
    if (this.props.ordered && this.props.productModel.DateOrdered) ret.push(<Text size={13} color="green" key="ordered-date">{this.getOrderedOnText()}</Text>);
    return ret;
  }

  private renderCompactActionRow(ns, onRemoveProduct) {
    const { ordered, isAdmin, productModel: {isManaging} } = this.props;
    if (ordered && this.props.productModel.DateOrdered) {
      return <div className={`${ns}--previous-order-price`}>
          <div>
            {this.renderPrice(ns, 4, true)}
            <Text size={13} color="green" key="compact-ordered-text">{this.getOrderedOnText()}</Text>
          </div>
          {isAdmin && <RemoveButton admin className={`${ns}--remove-previous`} onClick={onRemoveProduct ? onRemoveProduct.bind(this, this.props.productModel) : undefined}>REMOVE</RemoveButton>}
        </div>;
    }
    if (isManaging) return this.renderDropdownsAndButton(ns, 'UPDATE', this.onCloseManage, true);
    return [
      (this.renderPrice(ns, 16, true)),
      (<div className={`${ns}--manage-row`} key="manage-row">
        <Button className="product--manage-button" color="gray" textColor="black" admin={isAdmin} flat onClick={this.onManage}>MANAGE</Button>
        <RemoveButton onClick={onRemoveProduct ? onRemoveProduct.bind(this, this.props.productModel) : undefined}>REMOVE</RemoveButton>
      </div>)
    ];
  }

  renderDescription(ns, Description: string) {
    const { isCompact } = this.props;
    // @todo: There is one case in the design where this margin bottom isnt 16, I think it is a mistake
    return (
      <Row className={`${ns}--description-row`} marginBottom={16} verticalAlignment="top">
        <Ellipsis
          configs={{
            lines: isCompact ? 2 : 3,
            text: Description,
          }}
        />
      </Row>
    );
  }

  public render() {
    const { onAddProduct, ordered, isCompact, isSummary, isRegistrationSummary, onClick } = this.props;
    const { Name, FeaturedImage, Description, InCart, ID } = this.props.productModel;

    const Sizes = undefined as any;
    let sizesString = 'Available sizes: ';
    if (Sizes) {
      for (let i = 0; i < Sizes.length; i++) {
        sizesString = sizesString.concat(Sizes[i]);
        if (i < Sizes.length - 1) sizesString = sizesString.concat(', ');
      }
    }

    const ns = namespace(!!isCompact, !!isSummary);

    let vignetteWidth = 180;
    if (isCompact) vignetteWidth = 50;
    else if (isSummary) vignetteWidth = 100;

    let vignetteHeight = 180;
    if (isCompact) vignetteHeight = 50;
    else if (isSummary) vignetteHeight = 100;

    let vignetteMobileHeight = 240;
    if (isCompact) vignetteMobileHeight = 50;
    else if (isSummary) vignetteMobileHeight = 50;

    let vignetteClass = `${nameBase}--image-view--image`;
    if (isSummary) vignetteClass += ' summary';
    else if (isCompact) vignetteClass += ' compact';
    else vignetteClass += ' not-compact';
    if (!FeaturedImage || !FeaturedImage.ImageOriginal) vignetteClass += ' no-image';

    let contentClass = `${nameBase}--product-content`;
    if (isSummary) {
      contentClass += ' summary';
      if (ordered) contentClass += ' cart';
      else contentClass += ' not-cart';
    }
    else if (isCompact) contentClass += ' compact';
    else contentClass += ' not-compact';

    let initials: string | undefined = createInitials(Name);

    return (
      <Card
        className={ns + ` product--id--${ID}`}
        template="mobile-stretch"
        paddingTop={16}
        paddingBottom={16}
        paddingLeft={16}
        paddingRight={16}
        mobilePaddingVertical={isCompact || isSummary ? 16 : 0}
        mobilePaddingHorizontal={isCompact || isSummary ? 16 : 0}
        layout="horizontal"
        mobileLayout={isSummary ? undefined : "vertical"}
        marginBottom={isCompact ? 0 : undefined}
        mobileMarginBottom={0}
        onClick={onClick}
      >
        <Vignette
          width={vignetteWidth}
          height={vignetteHeight}
          mobileHeight={vignetteMobileHeight}
          mobileWidth={isSummary ? 50 : undefined}
          name={initials}
          color="#FC5830"
          image={FeaturedImage ? getImagePath(FeaturedImage as any) : undefined}
          mobileFullWidth={!isCompact && !isSummary}
          onClick={(FeaturedImage && FeaturedImage.ImageOriginal) ? this.handleImageClick : undefined}
          className={vignetteClass}
          topLeftBorderRadius
          bottomLeftBorderRadius
          topRightBorderRadius
          bottomRightBorderRadius
          mobileTopLeftBorderRadius={isSummary || isCompact}
          mobileBottomLeftBorderRadius={isSummary || isCompact}
          mobileTopRightBorderRadius={isSummary || isCompact}
          mobileBottomRightBorderRadius={isSummary || isCompact}
        />
        <View
          layout="vertical"
          paddingVertical={isSummary || isCompact ? undefined : 8}
          paddingRight={isSummary || isCompact ? undefined : 8}
          mobilePaddingVertical={isSummary || isCompact ? 0 : 16}
          mobilePaddingHorizontal={isSummary || isCompact ? 0 : 16}
          className={contentClass}
        >
          {/* #491 If there's no sizes in the regular product, make the margin bottom 8 instead of 16 unless there's a description */}
          <Row layout="vertical" className={`${ns}--name-row`} marginBottom={isSummary ? 4 : isCompact ? 16 : !Sizes && Description ? 8 : 16} mobileMarginBottom={isSummary ? 4 : 8} verticalAlignment="top">
            <Ellipsis
              className={`${ns}--name ${isSummary ? ' summary':''}`}
              configs={{
                lines: 2,
                text: Name,
              }}
              isStatic
            />
            {isSummary && Description && !isRegistrationSummary && (
              <Ellipsis
                className={`${ns}--description`}
                configs={{
                  lines: 2,
                  text: Description,
                }}
              />
            )}
            {Sizes && <Text weight="medium" size={14} wrap={false}>
              {sizesString}
            </Text>}
          </Row>
          {!isCompact && !isSummary && Description && this.renderDescription(ns, Description)}
          {!isCompact && !isSummary && this.renderActionRow(ns, onAddProduct)}
          {isSummary && this.renderSummaryActionRow(ns)}
        </View>
        {isCompact && Description && this.renderDescription(ns, Description)}
        {isCompact && this.renderCompactActionRow(ns, this.onRemove)}
        {isSummary && ordered && (InCart !== 0) && <CartIcon className={`${ns}--cart-icon`} isDeleted={InCart === -1}/>}
      </Card>
    );
  };
}

export default Product;
export { default as ProductGroup } from './ProductGroup';
