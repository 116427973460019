import { ActionCreator, ApplicationState } from '../../../../index';
import { SaveState } from '../../../../Rollback/actions';
import { createValidateActions, createApiValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod } from '../../../../../store/Validation/actionCreator';
import { ADMIN_EVENT_TYPES_SUFFIX } from '../../../CacheOne/actions';
import { typeName, Action } from '../../../../../utils/StrongActions';

export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + '__CLASSES_TAB';

@typeName('RESET' + SUFFIX) export class AdminEventsEventClassesTabReset extends Action {}

@typeName('TOGGLE_EXPAND_FILTER' + SUFFIX)
export class ToggleExpandFilterAction extends Action {
  constructor(public expand: boolean) { super(); }
}

export type Actions = typeof actionCreators;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.events.event.classes.classesTab, true, undefined, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  reset: (): ActionCreator => dispatch => dispatch(new AdminEventsEventClassesTabReset()),
  toggleExpandFilter: (expand: boolean): ActionCreator => dispatch => {
    dispatch(new ToggleExpandFilterAction(expand));
    dispatch(new SaveState());
  },
};