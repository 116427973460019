import {ActionCreator, ApplicationState} from '../..';
import {
  createApiSubmitActions,
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions,
  innerApiSubmitFormMethod
} from "../../Validation/actionCreator";
import {SaveState} from "../../Rollback/actions";
import {getUpdatePasswordBody} from "../../../constants/urls";
import {FullGroup} from "../../../models/api/options";
import {ToggleModalSaving} from "../../App/actions";
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../utils/dataHelper';
import { ModalTypes } from '../../../utils/modalHelper';
import {appActionCreators} from "../..";
import { typeName, Action } from '../../../utils/StrongActions';

export const SUFFIX = '__EDIT_PASSWORD';

@typeName(`INIT${SUFFIX}`)
export class ChangePasswordInit extends Action {}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ChangePasswordActions = createApiSubmitActions(SUFFIX);

export type Actions = typeof actionCreators;

export const formCreator = (rootState: ApplicationState, activeForm) => {
  let debugPayload: TentarooDebugPayload | undefined;
  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when submting ChangePassword form");
  }
  const Group = rootState.cacheZero.options?.Group as FullGroup;
  const payload = getUpdatePasswordBody({
    GroupIDi: Group.IDi,
    GroupTS: Group.TS,
    CurrentPassword: activeForm.CurrentPassword,
    NewPassword: activeForm.NewPassword,
  });

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  initChangePassword: (): ActionCreator => (dispatch, getState) => {
    dispatch(new ChangePasswordInit());
    dispatch(new SaveState());
  },
  changePasswordApiSubmitForm: (): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const valid = innerApiSubmitFormMethod(
      dispatch,
      ChangePasswordActions,
      (s: ApplicationState) => s.settings.changePassword,
      undefined,
      undefined,
      undefined,
      true,
    );
    if (valid) {
      const activeForm = getState().settings.changePassword.ActiveForm;
      dispatch(appActionCreators.popModal(false, false, ModalTypes.CHANGE_PASSWORD) as any);
      // Show the global loader when saving password, because this could happen in any page
      dispatch(new ToggleModalSaving(true));
      dispatch(ChangePasswordActions.request(formCreator(getState(), activeForm), null));
    }
  },
  changePasswordUpdateValue: createUpdateValueMethod(
    ValidateActions,
    undefined,
    (s: ApplicationState) => s.settings.changePassword,),
  changePasswordSimpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
};
