import * as React from 'react';
import moment from 'moment';

import '../../../styles/elements/month-picker/picker.scss';
import { namespace as ns } from './constants';

import Button from "../Button";
import BottomBar from "../BottomBar";
import BottomBarActions from "../BottomBar/Actions";
import Picker from "../Picker";
import PickerOption from "../Picker/Option";
import Row from "../Grid/Row";
import Column from "../Grid/Column";


export const namespace = (): string => `${ns()}--picker`;

interface Props {
  open: boolean;
  onChange: (value: moment.Moment) => void;
  onClose: () => any;
  value: moment.Moment;
}

interface State {
  close: boolean;
  month: number;
  year: number;
}

class PickerComponent extends React.Component<Props, State> {
  public props: Props;
  public state: State = {
    close: false,
    month: 1,
    year: moment().year()
  };

  constructor(props: Props) {
    super(props);
    this.state.month = props.value.month() + 1;
    this.state.year = props.value.year();
  }

  public close = () => {
    this.setState({ close: true });
  };

  public handleClose = () => {
    this.props.onClose();
    this.setState({ close: false });
  };

  public handleOkClick = () => {
    this.props.onChange(moment([this.state.year, this.state.month - 1, 1]));
    this.close();
  };

  componentWillReceiveProps(nextProps: Props) {
    const nextState: any = {};
    let hasChanges = false;
    if (this.state.month !== nextProps.value.month() + 1) {
      hasChanges = true;
      nextState.month = nextProps.value.month() + 1;
    }
    if (this.state.year !== nextProps.value.year()) {
      hasChanges = true;
      nextState.year = nextProps.value.year();
    }
    if (hasChanges) this.setState(nextState);
  }

  public render() {
    const { open } = this.props;
    const { close } = this.state;
    return (
      <BottomBar mobile open={close ? false : open} onClose={this.handleClose}>
        <Row paddingRight={20} paddingLeft={20} paddingTop={34} paddingBottom={18}>
          <Column span={7} gutter={false}>
            <Picker rows={7} value={this.state.month} onChange={(month: any) => this.setState({ month })}>
              {Array.apply(null, Array(12)).map((value, index) => {
                const month = moment().set('month', index);
                return (
                  <PickerOption key={index} value={index + 1}>{month.format('MMMM')}</PickerOption>
                );
              })}
            </Picker>
          </Column>
          <Column span={5}>
            {<Picker rows={7} value={this.state.year} onChange={(year: any) => this.setState({ year })}>
              {Array.apply(null, Array(10)).map((value, index) => {
                const year = moment().add(index, 'year');
                return (
                  <PickerOption key={index} value={year.year()}>{year.format('YYYY')}</PickerOption>
                );
              })}
            </Picker>}
          </Column>
        </Row>
        <BottomBarActions>
          <Button flat textColor="blue" onClick={this.close}>CANCEL</Button>
          <Button flat textColor="blue" onClick={this.handleOkClick}>OK</Button>
        </BottomBarActions>
      </BottomBar>
    );
  }
}

export default PickerComponent;
