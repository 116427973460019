import { makeRequestEpic } from "../../../Validation/epicCreator";
import { GenerateAdminEventsEventTypeReportsActions } from "./actions";
import { generateEventTypeReports, generateEventTypeReportsBody } from "../../../../constants/adminEventsUrls";
import { MINUTE } from "../../../../utils/ajaxHelper";

export const generateAdminEventsEventTypeReportsEpic = makeRequestEpic(
  GenerateAdminEventsEventTypeReportsActions,
  generateEventTypeReports,
  generateEventTypeReportsBody,
  true,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  5 * MINUTE,
);