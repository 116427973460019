import { AjaxResponse } from "rxjs/Rx";

export const SECOND = 1 * 1000;
export const MINUTE = 60 * SECOND;

export const ajaxPost = (url: string, body: any, formDataRequest?: boolean, timeout?: number) => {
  // @todo - if we have `form-data` request other than uploading, need to better distinguish them here
  return formDataRequest ? {
    url: url,
    body: body,
    method: 'POST',
    crossDomain: true,
    headers: {
    },
    responseType: 'text',
    timeout: 5 * MINUTE,        // 5 mins for file upload
  } : {
    url: url,
    body: body,
    method: 'POST',
    crossDomain: true,
    headers: {
      "Content-Type": 'application/json;charset=UTF-8'
    },
    responseType: 'text',
    timeout: timeout || 2 * MINUTE,   // support custom timeout
  };
};

const parseResponse = (responseText: string) => {
  try {
    return JSON.parse(responseText);
  } catch (e) {
    throw new Error(e);
  }
};

export const parsePayload = (payload: AjaxResponse): AjaxResponse & {parseError: boolean} => {
  // based on doc: http://reactivex.io/rxjs/file/es6/observable/dom/AjaxObservable.js.html#lineNumber340
  // `response` is "parsed" from either `xhr.response` or `xhr.responseText`, and since we use `text`
  // as responseType, it would be the string value of `xhr.response` or `xhr.responseText`.
  // This is true for both successful and error scenarios

  // Skip parsing payload when status is 0 (no network) or 503 (server unavailable)
  if (payload.status !== 0 && payload.status !== 503) {
    // When status is 0 or 503, parsing the `response` will throw an error,
    // instead of ending up with a thrown error,
    // if status === 0, show `CONNECTION_ISSUE` message, instead of throwing errors because parsing an empty response
    // if status === 503, show `SERVER_UNAVAILABLE` message, instead of throwing errors because parsing an invalid json response
    let parsedResponse;
    try {
      // For testing purpose, one could comment the following line to test parseError behavior
      // parsedResponse = parseResponse((window as any).debugParsePayload ? "test" : payload.response);
      parsedResponse = parseResponse(payload.response);
    } catch (e) {
      /**
       * Previously, we had code here to capture and send this parse error to Sentry,
       * but we decided to remove that code because the issues only seem to occur
       * - due to a proxy or something else that’s not related to us, which we cannot do anything about
       * - when the server goes down or is temporarily unavailable, in which case we don’t need the error reported to us from the app.
       *   - also we no longer try to parse the response if server is unavailable by skipping 503 code above, as of August 2024
       */
      return { ...payload, status: 500, parseError: true};
    }

    if (parsedResponse) {
      payload.response = parsedResponse;
      payload.xhr = { ...payload.xhr, response: parsedResponse };
    }
  }

  return {...payload, parseError: false};
};