import * as React from 'react';
import { Main, MainContent } from '../../../../../Layouts';
import { Class, LoadingAll, EmptyMessage } from '../../../../../Elements';
import { actionCreators } from "../../../../../../store/Events/Event/Classes/actions";
import { EventsIcon } from '../../../../../Icons';
import { bindActionCreators } from 'redux';
import { actionCreators as eventActionsCreators } from "../../../../../../store/Events/Event/Main/actions";
import { actionCreators as eventTypeActionCreators } from "../../../../../../store/Events/EventType/actions";
import { actionCreators as cacheThreeActionCreators } from "../../../../../../store/CacheThreeEvents/actions";
import { actionCreators as cacheTwoActionCreators, ClearCacheBelowTwoEvents } from "../../../../../../store/CacheTwoEvents/actions";
import { actionCreators as cacheOneActionCreators } from "../../../../../../store/CacheOne/actions";
import { RouteComponentProps } from "react-router";
import { makeSelectedEventTypeSelector } from "../../../../../../store/CacheTwoEvents/index";
import { makeSelectedEventSelector } from "../../../../../../store/CacheThreeEvents/index";
import { withRouter } from "react-router";
import { makeSimpleSelector } from "../../../../../../utils/reselectHelper";
import { defaultFilterSelector } from "../../../../../../store/App/index";
import { ApplicationState } from '../../../../../../store';
import EndUserCacheManager from '../../../../../../utils/cacheManagers/endUserCacheManager';
import { IEventRouterParams, isPathUnderEndUserEventCacheThree } from '../../../../../../utils/helpers/endUserPageHelper';
import { shouldReconfigRouter } from '../../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isCacheThreeEventsPopulated, isEndUserCacheOnePopulated} from '../../../../../../utils/cachePopulatedCheckers/endUser';
import {RegisteredClass} from '../../../../../../models/class';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

const filterFnc = (c: RegisteredClass, f: string) => c.Name.toLowerCase().includes(f);
const classesSelector = (s) => {
  if (s.cacheThreeEvents.EventClassesGroupRegistered) return s.cacheThreeEvents.EventClassesGroupRegistered;
  return [];
};
const makeClassesSelector = makeSimpleSelector(() => true, filterFnc, classesSelector, defaultFilterSelector);


type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IEventRouterParams, {}>
>;

@(withRouter as any)
class ClassesComponent extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
  private nextLocation;

  public componentDidMount() {
    this.configRouteLeaveHook();
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeEvent({
          props: this.props,
          isStateNavigated,
        });
      }
    );
  }

  public componentWillReceiveProps(nextProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeEvent({
          props: nextProps,
          isStateNavigated,
        });
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouteLeaveHook();
  }

  componentWillUnmount() {
    const {cacheThreeEvents} = this.props;

    // if we are not navigating to another page under cache three events, clear cache
    if (
      this.nextLocation &&
      !isPathUnderEndUserEventCacheThree(this.nextLocation.pathname) &&
      isCacheThreeEventsPopulated(cacheThreeEvents)
    ) {
      reduxStoreService().dispatch(new ClearCacheBelowTwoEvents());
    }
    this.resetRouteLeaveHook();
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  renderClass = (c: RegisteredClass, index: number) => {
    const { user: { user: { str_permissions } }, cacheTwoEvents: { EventTypeRegistrationSettings },
      cacheThreeEvents: { EventInfo }
    } = this.props;
    return (
      <Class key={index}
        classModel={c}
        isAdmin={str_permissions.hasAdminAccess}
        enableWaitlist={EventTypeRegistrationSettings ? EventTypeRegistrationSettings.NamesRegistrationSettings.EnableClassWaitingList : false}
        isRegistration
        numYouth={EventInfo ? EventInfo.TotalNumbersYouth : 0}
        numAdults={EventInfo ? EventInfo.TotalNumbersAdults : 0}
        isPreview
      />
    );
  };

  getEmptyMessage() {
    const { cacheTwoEvents: { eventType }, filteredClasses,
      cacheThreeEvents: { EventClassesGroupRegistered }
    } = this.props;
    // @todo: is there a better icon?
    if (!EventClassesGroupRegistered || eventType === 'participant') {
      return <EmptyMessage icon={EventsIcon} description="There are no available classes" />;
    } else if (filteredClasses.length === 0) {
      return <EmptyMessage icon={EventsIcon} description="No classes found" />;
    }
    return null;
  }

  public render() {
    const { selectedEventType, selectedEvent, cacheOne, filteredClasses, inert } = this.props;
    if (!isEndUserCacheOnePopulated(cacheOne) || !selectedEventType || !selectedEvent) {
      return <LoadingAll />;
    }
    const emptyMessage = this.getEmptyMessage();
    return (
      <Main inert={inert} mobileBackground="white">
        <MainContent>
          {!emptyMessage && filteredClasses.map(this.renderClass)}
          {emptyMessage}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredClasses = makeClassesSelector();
  const selectedEventType = makeSelectedEventTypeSelector();
  const selectedEvent = makeSelectedEventSelector();
  return {
    user: state.user,
    eventType: state.events.eventType,
    event: state.events.event.main,
    systemSettings: state.session.SystemSettings,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheThreeEvents: state.cacheThreeEvents,
    cacheTwoEvents: state.cacheTwoEvents,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    filteredClasses: filteredClasses(state),
    selectedEventType: selectedEventType(state),
    selectedEvent: selectedEvent(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...eventActionsCreators,
    ...cacheThreeActionCreators,
    ...cacheTwoActionCreators,
    ...cacheOneActionCreators,
    ...eventTypeActionCreators
  }, dispatch)
});

const ConnectedClassesComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(ClassesComponent);

export default ConnectedClassesComponent;
