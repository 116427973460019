import * as React from 'react';
import { makeGroupedFacilitiesSelector, makeActiveFacilitiesSelector, makeFilteredFacilitiesSelector } from '../../../../../store/AdminFacilityLocation/Facilities/Home';
import FacilitiesFilter from './FacilitiesFilter';
import { Button, EmptyMessage } from '../../../../../components/Elements';
import '../../../../../styles/pages/admin-facility-location/facilities/home/index.scss';
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators as facilitiesHomeActionCreators } from '../../../../../store/AdminFacilityLocation/Facilities/Home/actions';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../store/Rollback/actions';
import { actionCreators as facilityFormActionCreators } from '../../../../../store/AdminFacilityLocation/Facilities/Facility/Form/actions';
import { AdminFacility } from '../../../../../models/api/adminFacilitiesCacheOne';
import { FACILITIES, EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE } from '../../../../../constants/messages/adminFacilityLocation';
import { ENTITY_NOT_ADDED, ENTITY_NOT_FOUND } from '../../../../../constants/messages/adminCMS';
import AdminFacilityCard, {AdminFacilityCardType} from '../../../../../components/Elements/AdminFacility/Card';
import { CardCategory } from '../../../../../components/Elements/Card';
import { Facilities2Icon } from '../../../../../components/Icons';
import { getEditFacilityUrl, constructAdminFacilityLocationFacilityUrlParams } from '../../../../../constants/urls';
import { RouteComponentProps, withRouter, WithRouterProps } from 'react-router';
import { navPush } from '../../../../../utils';
import { EmptyMessageType } from '../../../../../components/Elements/EmptyMessage';
import { checkFacilityPermission, IAdminFacilitiesLocationRouterParams } from '../../../../../utils/helpers/adminFacilityLocationPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';

export const namespace = (): string => 'pages--facility-location--facilities';

interface Props {
  showFilters?: boolean;
  forceMobile?: boolean;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}>;

interface State {

}


class FacilityList extends React.Component<Props, State> {
  public props: Props & ConnectedProps;

  onNewFacilityClick = () => {
    checkFacilityPermission(
      () => {
        this.props.actions.pushNewFacilityModal();
      },
      this.props.adminFacilityLocationCacheOne,
    );
  };
  onDeleteClick = (facility: AdminFacility) => {
    if (this.props.facilityForm && this.props.facilityForm.processingImages) {
      this.props.actions.showTopFloatingAlert(EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    checkFacilityPermission(
      () => {
        this.props.facilityFormActions.deleteFacility(
          facility.ID,
          this.props.router,
          this.props.routes,
        );
      },
      this.props.adminFacilityLocationCacheOne,
      true,
    );
  };
  onEditClick = (facility: AdminFacility) => {
    if (this.props.facilityForm && this.props.facilityForm.processingImages) {
      this.props.actions.showTopFloatingAlert(EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    const editFacilityUrl = getEditFacilityUrl(constructAdminFacilityLocationFacilityUrlParams(this.props, this.props.adminFacilityLocationCacheOne, facility));
    this.props.actions.saveState();
    navPush(this.props.router, editFacilityUrl);
  };
  onRestoreClick = (facility: AdminFacility) => {
    if (this.props.facilityForm && this.props.facilityForm.processingImages) {
      this.props.actions.showTopFloatingAlert(EDIT_FACILITY_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    checkFacilityPermission(
      () => {
        this.props.facilityFormActions.deleteFacility(
          facility.ID,
          this.props.router,
          this.props.routes,
          true,
        );
      },
      this.props.adminFacilityLocationCacheOne,
      true,
    );
  };

  componentDidMount() {
  }

  renderGroupedFacilities = () => {
    const {groupedFacilities, routes, adminFacilityLocationCacheOne} = this.props;
    return (
      <div className={`${namespace()}--list`}>
        {groupedFacilities.map((f) => {
          if (!f.FacilityTypeID) {
            return <div key={`facility_type${f.ID}`} className={`${namespace()}--list--group--title`}>{(f as any).NamePlural}</div>;
          } else {
            return (
              <AdminFacilityCard
                facility={f}
                key={`facility_card${f.ID}`}
                type={AdminFacilityCardType.FACILITY_LIST}
                category={CardCategory.LIST}
                onDelete={this.onDeleteClick}
                onEdit={this.onEditClick}
                onRestore={this.onRestoreClick}
                routes={routes}
                adminFacilityLocationCacheOne={adminFacilityLocationCacheOne}
              />
            );
          }
        })}
      </div>
    );
  };

  renderFacilities = () => {
    const {filteredFacilities, routes, adminFacilityLocationCacheOne} = this.props;
    return (
      <div className={`${namespace()}--list`}>
        {filteredFacilities.map((f) => {
          return (
            <AdminFacilityCard
              facility={f}
              key={`facility_card${f.ID}`}
              type={AdminFacilityCardType.FACILITY_LIST}
              category={CardCategory.LIST}
              onDelete={this.onDeleteClick}
              onEdit={this.onEditClick}
              onRestore={this.onRestoreClick}
              routes={routes}
              adminFacilityLocationCacheOne={adminFacilityLocationCacheOne}
            />
          );
        })}
      </div>
    );
  };

  renderContent = () => {
    const {facilitiesHomeForm} = this.props;

    if (facilitiesHomeForm.ActiveForm.SelectedFacilityTypeID === 0) {
      return this.renderGroupedFacilities();
    } else {
      return this.renderFacilities();
    }
  };

  renderEmptyMessage = (emptyMessage: string) => {
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        admin
        noMobilePadding
        icon={Facilities2Icon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={emptyMessage}
        actions={<Button className={`${namespace()}--empty-message-btn`} color="green" onClick={this.onNewFacilityClick}>NEW FACILITY</Button>}/>
    );
  };

  render() {
    const {showFilters, facilitiesHomeForm, actions, adminFacilityLocationCacheOne, activeFacilities, groupedFacilities, filteredFacilities, forceMobile} = this.props;

    const showDeleted = facilitiesHomeForm.ActiveForm.ShowDeleted;

    let emptyMessage;
    if (activeFacilities.length === 0 && (facilitiesHomeForm.ActiveForm.SelectedFacilityTypeID === 0 ? groupedFacilities.length === 0 : filteredFacilities.length === 0)) {
      emptyMessage = ENTITY_NOT_ADDED(FACILITIES);
    } else if ((facilitiesHomeForm.ActiveForm.SelectedFacilityTypeID === 0 && groupedFacilities.length === 0) || (facilitiesHomeForm.ActiveForm.SelectedFacilityTypeID !== 0 && filteredFacilities.length === 0)) {
      emptyMessage = ENTITY_NOT_FOUND(FACILITIES);
    }

    return (
      <div>
        {showFilters ? <div className={`${namespace()}--list-actions`}>
          <FacilitiesFilter
            ActiveForm={facilitiesHomeForm.ActiveForm}
            ValidationRules={facilitiesHomeForm.ValidationRules}
            reduxActions={actions}
            expand={facilitiesHomeForm.expandFilter}
            forceMobile
            newButton={
              <Button className='control' color='green' textColor='white' onClick={this.onNewFacilityClick}>New</Button>}
          />
        </div> : null}
        {emptyMessage ? this.renderEmptyMessage(emptyMessage) : null}
        {!emptyMessage ? this.renderContent() : null}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const groupedFacilitiesSelector = makeGroupedFacilitiesSelector();
  const activeFacilitiesSelector = makeActiveFacilitiesSelector();
  const filteredFacilitiesSelector = makeFilteredFacilitiesSelector();
  return {
    groupedFacilities: groupedFacilitiesSelector(state),
    activeFacilities: activeFacilitiesSelector(state),
    filteredFacilities: filteredFacilitiesSelector(state),
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
    facilitiesHomeForm: state.adminFacilityLocation.facilities.home,
    facilityForm: state.adminFacilityLocation.facilities.facility.form,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...facilitiesHomeActionCreators,
    ...appActionCreators,
    ...rollbackActionCreators,
  }, dispatch),
  facilityFormActions: bindActionCreators({
    ...facilityFormActionCreators,
  }, dispatch),
});
const ConnectedFacilityList = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(FacilityList);

export default withRouter<Props>(ConnectedFacilityList);