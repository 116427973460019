import * as React from 'react';
import * as moment from 'moment';
import { namespace as ns } from './constants';
import {displayTimeFrame} from '../../../utils';
import '../../../styles/elements/my-event/date-range.scss';

const namespace = (): string => `${ns()}--date-range`;

interface Props {
  dateStart: moment.Moment;
  dateEnd: moment.Moment;
}

const MyEventDateRange: React.SFC<Props> = ({ dateStart, dateEnd }: Props): React.ReactElement<Props> => {
  return (
    <div className={namespace()}>
      {displayTimeFrame(dateStart, dateEnd)}
    </div>
  );
};

export default MyEventDateRange;
