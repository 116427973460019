import {GetFacilityTripData} from './actions';
import {APIRequest, APISuccess} from "../Validation/actionCreator";
import {ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction} from "../App/actions";
import {ClearCacheBelowOne} from "../CacheOne/actions";
import {convertObjToMoment} from "../../utils/dateHelper";
import {EmptyCartActions, RemoveItemFromCartActions} from "../Cart/actions";
import {ClearCacheBelowTwoFacilities} from "../CacheTwoFacilities/actions";
import {
  FacilityTrip,
  FacilityTripPermissions,
  TripReservation
} from "../../models/api/cacheThreeFacilities";
import {convertToMoment} from "../../utils";
import {listSelector, makeNoFilterSelector, makeSingleSelector} from "../../utils/reselectHelper";
import {FacilityLocation_FacilityType, FacilityLocation_Trip} from "../../models/api/cacheTwoFacilties";
import {FacFSSubmitActions} from "../Facilities/Trip/Summary/actions";
import {AddTripSubmitActions} from "../Facilities/Trip/Form/actions";
import {FacilitiesSave} from "../CacheFourFacilities/actions";
import { captureTentarooError } from '../../utils/dataHelper';
import { Reducer } from 'redux';
import { Action, isActionType } from '../../utils/StrongActions';

export const reservationSelector = listSelector((state) => state.cacheThreeFacilities.TripReservations);
const facilityLocationTripSelector = listSelector((state) => state.cacheTwoFacilities.FacilityLocation_Trips);
const tripIdSelector = (state) => state.cacheThreeFacilities.tripID;

export const makeReservationSelector = makeNoFilterSelector((r: TripReservation) => r.InCart !== -1, reservationSelector);
export const makeSelectedFacilityLocationTripSelector = makeSingleSelector(
  (flt: FacilityLocation_Trip, id: number) => flt.GroupTripID === id,
  facilityLocationTripSelector,
  tripIdSelector
);

export interface TripMap {
  [facId: number]: Array<TripReservation>;
}

export interface CacheThreeFacilitiesCore {
  tripID: number;
  tripMap: TripMap;
  includedFacilityTypes: Array<FacilityLocation_FacilityType>;
  TripReservations?: Array<TripReservation> | null;
  FacilityTrip?: FacilityTrip | null;
  FacilityTripPermissions?: FacilityTripPermissions | null;
}

export interface CacheThreeFacilitiesState extends CacheThreeFacilitiesCore {
}

const setCacheThreeData = (data: CacheThreeFacilitiesCore, tripID: number): CacheThreeFacilitiesState => {
  const tripMap = {};
  const includedFacilityTypes: Array<FacilityLocation_FacilityType> = [];
  const trips = convertToMoment(data.TripReservations, ['StartDateTime', 'EndDateTime']);
  if (trips) {
    trips.forEach((trip: TripReservation) => {
      if(!tripMap[trip.Type.ID]) {
        tripMap[trip.Type.ID] = [];
        includedFacilityTypes.push(trip.Type);
      }
      tripMap[trip.Type.ID].push(trip);
    });
  }
  Object.keys(tripMap).forEach((key) => {
    tripMap[key].sort((a: TripReservation, b: TripReservation) => {
      if (!a.StartDateTime.isSame(b.StartDateTime)) {
        if (a.StartDateTime.isBefore(b.StartDateTime)) return -1;
        return 1;
      }
      if (a.Name.toLowerCase() !== b.Name.toLowerCase()) {
        if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
        return 1;
      }
      return 0;
    });
  });

  includedFacilityTypes.sort((a: FacilityLocation_FacilityType, b: FacilityLocation_FacilityType) => {
    if (a.Name.toLowerCase() !== b.Name.toLowerCase()) {
      if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
      return 1;
    }
    return 0;
  });

  return {
    tripID: tripID ? tripID : (data.FacilityTrip ? data.FacilityTrip.FacilityTripID : 0),
    tripMap,
    includedFacilityTypes,
    TripReservations: trips,
    FacilityTrip: convertObjToMoment(data.FacilityTrip, ['StartDateTime', 'EndDateTime']),
    FacilityTripPermissions: data.FacilityTripPermissions
  };
};

const handleResponse = (state, responseData, request) => {
  if (responseData.TripReservations !== undefined){
    const bodyStr = request.body;
    try {
      const body = JSON.parse(bodyStr);
      return setCacheThreeData(responseData, body.AppState.FacilityTripID);
    } catch (e) {
      captureTentarooError(new Error('Unable to parse request body for cache 3 facilities!'));
      return state;
    }
  }
  return state;
};

const CacheThreeFacilities: Reducer<CacheThreeFacilitiesState> = (state, act: Action) => {
  if (
    act.type === GetFacilityTripData.successType || act.type === FacFSSubmitActions.successType ||
    act.type === EmptyCartActions.successType || act.type === RemoveItemFromCartActions.successType ||
    act.type === AddTripSubmitActions.successType || act.type === FacilitiesSave.successType
  ) {
    const action = <APISuccess> act;
    return handleResponse(state, action.response.response, action.response.request);
  } else if (isActionType(act, SetCacheAction)) {
    return handleResponse(state, act.response.xhr.response, act.response.request);
  } else if (act.type === GetFacilityTripData.requestType) {
    const action = <APIRequest> act;
    return {...state, tripID: action.value.FacilityTripID};
  } else if (
    isActionType(act, ClearAllCache) || isActionType(act, ClearAllEndUserCacheButOptions) || isActionType(act, ClearCacheBelowOne) ||
    isActionType(act, ClearCacheBelowTwoFacilities)
  ) {
    return {tripID: 0, tripMap: {}, includedFacilityTypes: []};
  }
  return state || {tripID: 0, tripMap: {}, includedFacilityTypes: []};
};

export default CacheThreeFacilities;
