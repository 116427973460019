import { Validator } from "../../../../utils/validator/models";

export interface IValidator {
  SiteMenusFilterText: Validator;
  SiteMenusShowDeleted: Validator;
  PageMenusFilterText: Validator;
  PageMenusShowDeleted: Validator;
}

export const FormDefinition: IValidator = {
  SiteMenusFilterText: {
    key: 'SiteMenusFilterText',
    isRequired: () => false,
    defaultValue: () => '',
  },
  SiteMenusShowDeleted: {
    key: 'SiteMenusShowDeleted',
    isRequired: () => false,
    defaultValue: () => false,
  },
  PageMenusFilterText: {
    key: 'PageMenusFilterText',
    isRequired: () => false,
    defaultValue: () => '',
  },
  PageMenusShowDeleted: {
    key: 'PageMenusShowDeleted',
    isRequired: () => false,
    defaultValue: () => false,
  },
};