import { makeRequestEpic } from "../../../../Validation/epicCreator";
import { GenerateAdminEventsEventReportsActions, RecalculatePricingActions, SendToClientsActions } from "./actions";
import { generateEventReports, generateEventReportsBody, getRecalculatePricing, getRecalculatePricingBody } from "../../../../../constants/adminEventsUrls";
import { MINUTE } from "../../../../../utils/ajaxHelper";
import { AjaxResponse } from "rxjs/Rx";

export const generateAdminEventsEventReportsEpic = makeRequestEpic(
  GenerateAdminEventsEventReportsActions,
  generateEventReports,
  generateEventReportsBody,
  true,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  5 * MINUTE,
);

export const sendToClientsEpic = makeRequestEpic(
  SendToClientsActions,
  generateEventReports,
  generateEventReportsBody,
  true,
  (response: AjaxResponse) => {
    if (!response.response) return '';

    const NumGroups = response.response.NumGroups;
    return `Sent to ${NumGroups} group${NumGroups !== 1 ? 's' : ''}`;
  },
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  5 * MINUTE,
);

export const recalculatePricingEpic = makeRequestEpic(
  RecalculatePricingActions,
  getRecalculatePricing,
  getRecalculatePricingBody,
  true,
  (response: AjaxResponse) => {
    return 'Registration fees have been recalculated.';
  },
);