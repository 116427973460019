import * as React from 'react';
import { generateDOMId } from '../../../../utils/cypressHelper';
import { ContextMenu, ContextMenuItem } from '../../../Elements';
import { Props as ContextMenuProps } from '../../../Elements/ContextMenu/index';

interface Props {
  onPreview: (e) => void;
  onCopyLink: (e) => void;
  onEdit: (e) => void;
  onDelete: (e) => void;
  resourceId: number;
}

const ContextMenuComponent: React.SFC<ContextMenuProps & Props> = (props: ContextMenuProps & Props): React.ReactElement<ContextMenuProps & Props> => (
  <ContextMenu
    {...props}
    consistentInMobile
    title=""
  >
    <ContextMenuItem admin onClick={props.onPreview}>Preview</ContextMenuItem>
    <ContextMenuItem admin onClick={props.onCopyLink}>Copy link</ContextMenuItem>
    <ContextMenuItem admin id={generateDOMId(`resource-${props.resourceId}-menu-edit`)} onClick={props.onEdit}>Edit</ContextMenuItem>
    <ContextMenuItem admin onClick={props.onDelete}>Delete</ContextMenuItem>
  </ContextMenu>
);

export default ContextMenuComponent;
