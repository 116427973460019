import {EditEmailReset, SUFFIX} from './actions';
import {FormDefinition, IValidator} from "./validation";
import {apiValidationReducerCreator} from "../../Validation/reducerCreator";
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../utils/StrongActions';
import { PopModal } from '../../App/actions';
import { ModalTypes } from '../../../utils/modalHelper';
import { WithRootState } from '../../Validation/actionCreator';

export interface EditEmailForm {
  Email?: string;
  ConfirmEmail?: string;
}

export interface EditEmailState {
  ActiveForm: EditEmailForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
}
const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const getInitialState = (): EditEmailState => {
  return {
    ActiveForm: {
    }, ValidationRules: {...FormDefinition}};
};

const EditEmail: Reducer<EditEmailState> = (oldState, action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, action);

  if (isActionType(action, PopModal) && action.modal === ModalTypes.EDIT_EMAIL) {
    return {
      ...getInitialState()
    };
  } else if (isActionType(action, EditEmailReset)) {
    return {
      ...getInitialState()
    };
  }

  return state || { ...getInitialState()};
};

export default EditEmail;
