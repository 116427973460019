import * as React from 'react';
import * as ReactDOM from 'react-dom';
import device from 'current-device';
import {bindActionCreators} from 'redux';
import {connect} from "react-redux";
import {mergeClassNames} from '@tentaroo/shared';

import Card from '../Card';
import '../../../styles/elements/modal/index.scss';
import {slug} from '../../../utils';
import {actionCreators as rollbackActionCreators} from '../../../store/Rollback/actions';
import {actionCreators as appActionCreators} from '../../../store/App/actions';
import { getWindowHeight, disableBodyScroll, enableBodyScroll } from '../../../utils/navHelper';
import { isMobile, isLandscape, isPortrait } from '../../../utils/isMobile';
import { ApplicationState } from '../../../store';
import { makeCurrentModalSelector } from '../../../store/App';
import { generateDOMId } from '../../../utils/cypressHelper';
import { getMergeProps } from '../../../utils/reduxHelper';

import { namespace, namespaceWindow } from "./constants";
import {Nullable} from '../../../utils/dataHelper';
import { ModalTypes } from '../../../utils/modalHelper';
import {WithInertAttribute} from '../WithInert';

const scrollKeys = [33,34,35,36,38,40]; // allow 37/39 they're for moving in text fields

export enum ModalHeight {
  /* List in modal need a fixed height */
  HEIGHT_425='max-height-425',
  HEIGHT_480='max-height-480',
  HEIGHT_560='max-height-560',
  /* Form modal should have height set to auto so that it will use up all the available space to show the form */
  AUTO='auto',
}

type Props = WithInertAttribute<{
  children?: React.ReactNode;
  className?: string;
  big?: boolean;
  height?: ModalHeight;
  stickyActions?: boolean;
  onClose?: Function;
  expand?: boolean;
  mobileFullScreen?: boolean;
  component?: string;
  disableClose?: boolean;
  higherZIndex?: boolean;
  wideModal?: boolean;
  superWideModal?: boolean;
  /**
   * Force margin (20px for both left and right) on modal
   */
  forceMargin?: boolean;
  inactive?: boolean;
  /**
   * Dont need to call any action to close the modal in this callback, <Modal />
   * component will do that for you
   */
  onInactive?: () => void;
  bodyScrollLock?: boolean;
  disableDynamicHeight?: boolean;
}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface State {
  renderMobile: boolean;
  desktopHeight?: number;
  cardHeight?: number;
  lastScrollY?: number;
  alignSelf?: string;
}

class Modal extends React.PureComponent<Props, State> {
  public props: Props & ConnectedProps;
  public state: State = {
    renderMobile: false
  };
  private ref;

  public id: number = Math.random();

  private _backdropMouseDownTargetClass = null;
  private _backdropMouseUpTargetClass = null;

  public componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    // use this handler to handle keyboard open up
    document.addEventListener('focusout', this.handleFocusOut);
    window.addEventListener('resize', this.handleResize);
    if (this.props.currentModal && this.props.currentModal.saveAfter) {
      this.props.actions.saveState();
    }

    this.handleInactive();
    this.handleResize();
    if (this.props.bodyScrollLock) disableBodyScroll(this.ref);
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('focusout', this.handleFocusOut);
    window.removeEventListener('resize', this.handleResize);
    if (this.props.bodyScrollLock) enableBodyScroll(this.ref);
    // if window scroll position is recorded, restore it during unmount
    if (this.state.lastScrollY) {
      window.scrollTo(0, this.state.lastScrollY);
    }
  }

  handleInactive() {
    const {inactive, onInactive} = this.props;

    if (inactive) {
      this.close();
      if (onInactive) onInactive();
    }
  }

  componentDidUpdate() {
    this.handleInactive();
  }

  handleFocusOut = () => {
    if (device.ios() && device.mobile() && isLandscape()) {
      if (!this.state.lastScrollY) {
        this.setState({
          lastScrollY: window.scrollY,
        });
      }
      window.scrollTo(0, -1);
    }
  };

  public handleResize = () => {
    if (this.refs.cardWrapper) {
      const {renderMobile, desktopHeight} = this.state;
      const node = ReactDOM.findDOMNode(this.refs.cardWrapper) as Nullable<Element>;
      // Here it is checking if the card element's height is greater than window.innerHeight
      // if it is then its `renderMobile`. This make sense because only mobile has the address bar feature.
      if (!renderMobile && node && node.children.length > 0 && node.children[0].clientHeight > window.innerHeight && node.children[0].clientHeight > window.innerHeight) {
        this.setState({renderMobile: true, desktopHeight: node.children[0].clientHeight});
      } else if (renderMobile && desktopHeight && desktopHeight <= window.innerHeight) {
        this.setState({renderMobile: false, desktopHeight: undefined});
      }
    }
    // in mobile (screen size), since we do not reset window's scroll position with `position:fixed` any more we need to craft the card's height manually
    if (isMobile && (document.documentElement.clientWidth < 687 || document.documentElement.clientHeight < 450) && !this.props.disableDynamicHeight) this.setState({cardHeight: getWindowHeight()});

    // if its in mobile landscape, scroll window to top and record the scroll position. This is the only edge case we need to handle this way
    if (device.mobile() && isLandscape()) {
      // handleResize will get called in two cases: `componentDidMount`, `onResize` (this could due to a scroll by our code, or a orientation change)

      // Record scroll position but only do this once. Scroll window to top (-1) to show browser bar.
      if (!this.state.lastScrollY) {
        this.setState({
          lastScrollY: window.scrollY,
        });
      }
      window.scrollTo(0, -1);

    } else if (device.mobile() && isPortrait()) {

      // if in portrait, restore window's scroll position and clear what we have recorded
      if (this.state.lastScrollY) {
        window.scrollTo(0, this.state.lastScrollY);
        this.setState({
          lastScrollY: undefined,
          alignSelf: undefined,
        });
      }
    }
  };

  public handleBackdropClick = (e) => {
    if (this.props.apiSaving === 0) this.close(e);
  };

  public stopAndPrevent = (e) => {
    // e.preventDefault();
    e.stopPropagation();
  };

  public handleKeyDown = (e) => {
    const key = e.which;
    if (scrollKeys.indexOf(key) > -1) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  public close = (e?: any) => {
    // NOTE: When using Cypress to click backdrop, it might not set `_backdropMouseUpTargetClass` nor `_backdropMouseDownTargetClass`
    // properly, and so we always allow
    if (!(window as any).Cypress && this._backdropMouseUpTargetClass !== this._backdropMouseDownTargetClass) return;
    if (this.props.disableClose) return;

    // If `currentModal` is falsy, it means modal is already closed
    if (!this.props.currentModal) return;
    if (this.props.onClose) this.props.onClose();

    this.props.actions.popModal(false, this.props.apiSaving === 0, this.props.currentModal.type);
  };

  onBackDropMouseDown = (e) => {
    this._backdropMouseDownTargetClass = e.target.classList && e.target.classList.length > 0 ? e.target.classList[0] : null;
  };

  onBackDropMouseUp = (e) => {
    this._backdropMouseUpTargetClass = e.target.classList && e.target.classList.length > 0 ? e.target.classList[0] : null;
  };

  setRef = (ref: any) => {
    this.ref = ref;
  };

  public render() {
    const { mobileFullScreen, children, big, stickyActions, expand, height, higherZIndex, superWideModal, forceMargin, wideModal } = this.props;
    const { renderMobile } = this.state;

    let className = mergeClassNames(namespaceWindow, this.props);
    const containerClassName = mergeClassNames(`${namespace}--container`, this.props);
    if (big) className += ' big';
    if (mobileFullScreen) className += ' mobile-full-screen';
    if (wideModal) className += ' wide-modal';
    if (superWideModal) className += ' super-wide-modal';
    if (expand) className += ' expand';
    if (renderMobile) className += ' render-mobile';
    if (forceMargin) className += ' force-margin';
    if (height) className += ` ${height}`;

    let headerComponent;
    const nextChildren: Array<React.ReactElement<any> | null> = React.Children.map(children, (child: any, index: number) => {
      if (!child) return null;
      // The following two flags are only set in <ModalHeader /> and <ModalActions />, respectively
      const isModalHeader = child.type && !!child.type.isModalHeader;
      const isModalActions = child.type && !!child.type.isModalActions;
      if (isModalHeader || isModalActions) {
        if (isModalHeader) {
          headerComponent = child;
          return React.cloneElement(child, {
            ...child.props,
            key: index,
            mobileFullScreen,
            // `this.props.onClose` is called when closing the modal, so we always override ModalHeader's
            // `onClose` prop with the Modal one so that they are consistent
            onClose: this.props.onClose || child.props.onClose,
            disableClose: !!this.props.disableClose,
          });
        } else {
          // For Modal Actions, only pass `mobileFullScreen` to make it consistent
          return React.cloneElement(child, { ...child.props, key: index, mobileFullScreen });
        }
      }
      return React.cloneElement(child , { ...child.props, key: index });
    });
    if (stickyActions) nextChildren.push(<div key="sticky_actions" className={`${namespaceWindow}--gutter ${renderMobile ? 'render-mobile' : ''}`}/>);

    const cardHeightStyle: React.CSSProperties = this.state.cardHeight ? {height: `${this.state.cardHeight}px`, maxHeight: `${this.state.cardHeight}px`, minHeight: 'unset !important'} : {};
    const alignSelfStyle: React.CSSProperties = this.state.alignSelf ? {alignSelf: this.state.alignSelf} : {};

    if (cardHeightStyle) className += ' dynamic-height';

    // Careful making any changes belows works on a real iPhone as well
    return (
      <div
        inert={this.props.inert}
        role="dialog"
        aria-modal="true"
        ref={this.setRef}
        className={`${namespace} ${containerClassName} ${higherZIndex ? 'high-z-index' : ''}`}
      >
        {/* @todo: this also has modal's class...*/}
        <div
          id={generateDOMId(`modal-backdrop${headerComponent && (typeof headerComponent.props.children === "string") ? "-" + slug(headerComponent.props.children) : ""}`)}
          onMouseDown={this.onBackDropMouseDown}
          onMouseUp={this.onBackDropMouseUp}
          className={`${namespace} ${namespace}--container--wrapper`}
          ref="cardWrapper"
          onClick={this.handleBackdropClick}
        >
            {/* @todo: putting a card in a modal doesn't make much sense to me...*/}
            <Card
              style={{...cardHeightStyle, ...alignSelfStyle}}
              component={<section onClick={this.stopAndPrevent}/>}
              shadow={6}
              children={nextChildren}
              className={className}
            />
          </div>
      </div>
    );
  }
}

export { default as Title } from './Title';
export { default as Header } from './Header';
export { default as Content } from './Content';
export { default as Actions } from './Actions';
export { Modal as ModalContext };

const mapStateToProps = (s: ApplicationState) => {
  const currentModalSelector = makeCurrentModalSelector();
  return {
    apiSaving: s.app.apiSaving,
    currentModal: currentModalSelector(s),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...rollbackActionCreators
  }, dispatch)
});

const ConnectedModal = connect(mapStateToProps, mapDispatchToProps, getMergeProps<Props>())(Modal);

export default ConnectedModal;
