// @todo: this shouldn't be in admin folder
import * as React from 'react';
import { default as AppHeader, Props as AppHeaderProps } from '../../../App/Header';

export interface AddGroupProps extends AppHeaderProps {
}

export default ():React.SFC<AddGroupProps> => {
  return (props: AddGroupProps): React.ReactElement<AddGroupProps> => {
    return (<AppHeader
      showDrawer
      title="New Client"
      showSelectClientLonger
      {...props}
    />);
  };
};
