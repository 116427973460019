// Login
export const INVALID_LOGIN = 'Invalid credentials.';
export const NO_USERNAME = 'Please enter a username greater than 2 characters.';
export const NO_PASSWORD = 'Please enter a password.';

// Logout
export const LOGOUT_FAILED = 'Logout failed.';

export const GET_LOGIN_FAIL_TITLE = 'Error occurred';
export const GET_LOGIN_FAIL_MESSAGE = 'A system error has occurred, please try again later.';
