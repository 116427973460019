import * as React from 'react';
import {default as AppHeader} from '../../../../../App/Header';
import {EVENT, getEventRootUrl} from "../../../../../../constants/urls";
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import {makeSelectedParticipantSelector} from "../../../../../../store/CacheFourEventsViewParticipant";
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string}>;

const mapStateToProps = (state: ApplicationState) => {
  const selectedParticipant = makeSelectedParticipantSelector();
  return {
    selectedParticipant: selectedParticipant(state)
  };
};
export default () => {
  const EventHeader = (props: ConnectedProps): React.ReactElement<ConnectedProps> => {
    const registrationLink =`${getEventRootUrl(props)}/${EVENT.REGISTRATION}`;
    return <AppHeader
      showDrawer
      cart
      mobileCart
      refresh
      title="Participant Registration"
      back={registrationLink}
    />;
  };

  const ConnectedEventHeader = connect(
    mapStateToProps,
    null,
    getMergeProps<WithRouterProps>(),
  )(EventHeader);

  return withRouter<{}>(ConnectedEventHeader);
};
