import { ActionCreator } from '../../../index';
import { SaveState } from '../../../Rollback/actions';
import { createValidateActions, createApiValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod, createApiSubmitActions, getCacheLevelExtra } from '../../../../store/Validation/actionCreator';
import { ADMIN_CMS_MODULE_SUFFIX } from '../../CacheOne/actions';
import { CMSSiteMenuItem, CMSPageMenuItem } from '../../../../models/api/adminCMSCacheOne';
import { getSubmitSiteMenuOrderBody, getSubmitPageMenuOrderBody } from '../../../../constants/urls';
import { typeName, Action } from '../../../../utils/StrongActions';

export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__MENUS_HOME';

@typeName('RESET' + SUFFIX) export class MenusHomeReset extends Action {}

export type Actions = typeof actionCreators;

@typeName('MOVE_SITE_MENU_ITEM' + SUFFIX)
export class MoveSiteMenuItemAction extends Action {
  constructor(public item: any, public offset: number, public isParent: boolean) { super(); }
}

@typeName('MOVE_PAGE_MENU_ITEM' + SUFFIX)
export class MovePageMenuItem extends Action {
  constructor(public item: CMSPageMenuItem, public offset: number) { super(); }
}
@typeName('CANCEL_REORDER_MENU_ITEM' + SUFFIX)
export class CancelReorderMenuItemAction extends Action {}

@typeName('TOGGLE_EXPAND_FILTER' + SUFFIX)
export class ToggleExpandFilterAction extends Action {
  constructor(public expand: boolean, public target: 'sitemenu' | 'pagemenu') { super(); }
}

@typeName('TOGGLE_DELETE_SITE_MENU_MODAL' + SUFFIX)
export class ToggleDeleteSiteMenuModal extends Action {
  constructor(public deletingSiteMenuItem: CMSSiteMenuItem) { super(); }
}

@typeName('EXPAND_SITE_MENU' + SUFFIX)
export class ExpandSiteMenuAction extends Action {
  constructor(public menuId: number) { super(); }
}

@typeName('EXPAND_PAGE_MENU' + SUFFIX)
export class ExpandPageMenuAction extends Action {
  constructor(public menuId: number) { super(); }
}

@typeName('CONFIG_DELETE_SITE_MENU_ITEM_MODAL' + SUFFIX)
export class ConfigDeleteSiteMenuItemModal extends Action {
  constructor(public item: CMSSiteMenuItem) { super(); }
}

@typeName('CONFIG_DELETE_PAGE_MENU_ITEM_MODAL' + SUFFIX)
export class ConfigDeletePageMenuItemModal extends Action {
  constructor(public item: CMSPageMenuItem) { super(); }
}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const SubmitSiteMenuOrderActions = createApiSubmitActions('UPDATE_SITE_MENU_ORDER' + SUFFIX);
export const SubmitPageMenuOrderActions = createApiSubmitActions('UPDATE_PAGE_MENU_ORDER' + SUFFIX);

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.adminCMSSite.menus.home, true, undefined, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  moveSiteMenuItem: (item: any, offset: number, isParent?: boolean): ActionCreator => dispatch => dispatch(new MoveSiteMenuItemAction(item, offset, !!isParent)),
  movePageMenuItem: (item: CMSPageMenuItem, offset: number): ActionCreator => dispatch => dispatch(new MovePageMenuItem(item, offset)),
  cancelReorder: (): ActionCreator => dispatch => dispatch(new CancelReorderMenuItemAction()),
  expandSiteMenu: (menuId: number): ActionCreator => dispatch => {
    dispatch(new ExpandSiteMenuAction(menuId));
    dispatch(new SaveState());
  },
  expandPageMenu: (menuId: number): ActionCreator => dispatch => {
    dispatch(new ExpandPageMenuAction(menuId));
    dispatch(new SaveState());
  },
  toggleExpandFilter: (expand: boolean, target: 'sitemenu' | 'pagemenu'): ActionCreator => dispatch => {
    dispatch(new ToggleExpandFilterAction(expand, target));
    dispatch(new SaveState());
  },
  configDeleteSiteMenuItemModal: (item: CMSSiteMenuItem): ActionCreator => dispatch => {
    dispatch(new ConfigDeleteSiteMenuItemModal(item));
  },
  configDeletePageMenuItemModal: (item: CMSPageMenuItem): ActionCreator => dispatch => {
    dispatch(new ConfigDeletePageMenuItemModal(item));
  },
  updateSiteMenuItemOrder: (items: CMSSiteMenuItem[]): ActionCreator => dispatch => {
    dispatch(new SaveState());
    dispatch(SubmitSiteMenuOrderActions.request(
      getSubmitSiteMenuOrderBody(items),
      getCacheLevelExtra(
        false,
        true,
      ),
    ));
  },
  updatePageMenuItemOrder: (items: CMSPageMenuItem[]): ActionCreator => dispatch => {
    dispatch(new SaveState());
    dispatch(SubmitPageMenuOrderActions.request(
      getSubmitPageMenuOrderBody(items),
      getCacheLevelExtra(
        false,
        true,
      ),
    ));
  },
  reset: (): ActionCreator => dispatch => dispatch(new MenusHomeReset()),
};