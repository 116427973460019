import { sortByName } from ".";
import { ApplicationState } from "..";
import { District } from "../../models/api/options";

export const districtsSelector = (state: ApplicationState) => {
  if (state.cacheZero.options && state.cacheZero.options.Districts) {
    return state.cacheZero.options.Districts;
  }
  return [];
};

export const filteredDistrictsCombiner = (districts: District[], filter: number | undefined | null) => {
  if (filter === undefined || filter === null) return districts;
  return districts
          .filter(district => district.CouncilIDi === filter || district.CouncilIDi === 0)
          .sort(sortByName);
};

export const classTypeSelector = (state: ApplicationState, classTypeIDi: number)=> {
  const cacheZeroOptions = state.cacheZero.options;
  if (cacheZeroOptions) {
    return cacheZeroOptions.ClassTypes ?
    cacheZeroOptions.ClassTypes.find((o) => o.IDi === classTypeIDi) : undefined;
  }
};