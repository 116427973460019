import * as React from 'react';
import * as moment from 'moment';

import { namespace as ns } from './constants';
import '../../../styles/elements/my-event/info.scss';
import {displayTimeFrame} from "../../../utils/dateHelper";

export const namespace = (): string => `${ns()}--info`;

export interface InfoProps {
  dateStart?: moment.Moment;
  dateEnd?: moment.Moment;
  name?: string;
  location?: string;
  warning?: string;
  error?: string;
}

// @deprecated
const Info: React.SFC<InfoProps> = (
  { dateStart, dateEnd, name, location, warning, error }
: InfoProps): React.ReactElement<InfoProps> => {
  return (
    <div className={namespace()}>
      {(dateStart && dateEnd) ? (
        <div className={`${namespace()}--dates`}>
          {displayTimeFrame(dateStart, dateEnd)}
        </div>
      ) : null}
      {name ? (
        <div className={`${namespace()}--name`}>
          {name}
        </div>
      ) : null}
      {location ? (
        <div className={`${namespace()}--location`}>
          {location}
        </div>
      ) : null}
      {warning ? (<div className={`${namespace()}--warning`}>{warning}</div>) : null}
      {error ? (<div className={`${namespace()}--error`}>{error}</div>) : null}
    </div>
  );
};

export default Info; // todo: remove this line and uncomment the next one when ready to deprecate this component
//export default deprecated('Info is deprecated')(Info);
