
import { ToggleNotFound } from "../../store/App/actions";
import { SaveState } from "../../store/Rollback/actions";
import { extractRouteParams, isIdFromUrlValid, isIdValid } from "../urlHelper";
import { GetAdminEventMessage } from "../../store/AdminEvents/CacheTwoMessage/actions";
import { AddMessageSubmitActions } from "../../store/AdminEvents/MessageCenter/Form/actions";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import { reduxStoreService } from "../../store/service";
import type AdminEventsCacheManager from "../cacheManagers/adminEventsCacheManager";
import { LoadAdminEventCacheTwoMessageParams } from "./helpers/models";
import {isAdminEventsCacheTwoMessagePopulated} from "../cachePopulatedCheckers/adminEvents";

export const ensureAdminEventsCacheTwoMessage = (
  params: LoadAdminEventCacheTwoMessageParams & {
    cacheManager: AdminEventsCacheManager;
    messageId: number;
  }
): boolean => {
  const {props, isEdit, messageId, cacheManager, isStateNavigated} = params;

  if (!isIdFromUrlValid(props.params.eventTypeId)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const rootState = reduxStoreService().getState();
  const adminEventsCacheTwoMessage = rootState.adminEvents.cacheTwoMessage;

  let requestedData = false;
  if (isEdit) {
    if (!isIdValid(messageId)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }

    if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminEventsCacheTwoMessagePopulated(adminEventsCacheTwoMessage)) {
      if (isStateNavigated) {
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      reduxStoreService().dispatch(GetAdminEventMessage.request({
        MessageID: messageId,
        ...extractRouteParams(props),
      }));

      requestedData = true;
    }
  } else if (isStateNavigated) {
    requestedData = cacheManager.loadAdminEventsCacheOne({
      props,
      isStateNavigated,
    });

    if (!requestedData) {
      reduxStoreService().dispatch(AddMessageSubmitActions.updateForm());
      reduxStoreService().dispatch(new SaveState());
    }
  }

  return requestedData;
};