import {SaveState} from "../../store/Rollback/actions";
import {EVENT, eventProductsBody, E_REGISTER} from "../../constants/urls";
import {replace} from "react-router-redux";
import { shouldCloseWizardOnLoad } from "./helpers/blockers";
import { getGroupID } from "../helpers/endUserPageHelper";
import { EventProductsCalc } from '../../store/CacheFourEventsProducts/actions';
import { reduxStoreService } from "../../store/service";
import { LoadEndUserCacheFourEventProductParams } from "./helpers/models";
import {isCacheFourEventsProductsPopulated, isCacheThreeEventsPopulated, isEndUserCacheOnePopulated, isEndUserCacheTwoEventsPopulated} from "../cachePopulatedCheckers/endUser";

function redirectToEventRegistration(currentPath: string, currentQuery: string) {
  // Derive `END_USER_EVENT_REGISTRATION_PATH` from `PRODUCTS_WIZARD_PATH`
  const newPath = currentPath.replace(`${EVENT.REGISTER}/${E_REGISTER.PRODUCTS}`, EVENT.REGISTRATION);
  reduxStoreService().dispatch(replace({
    pathname: newPath,
    query: currentQuery,
  }));
}
export const ensureCacheFourEventsProducts = (
  params: LoadEndUserCacheFourEventProductParams,
): boolean => {
  const {
    props, isStateNavigated,
  } = params;
  if (shouldCloseWizardOnLoad(props.location)) {
    redirectToEventRegistration(props.location.pathname, props.location.query);
    return false;
  }
  
  /**
   * NOTE: cache four loaders will never get triggered on direct visit,
   * and can only get triggered by in-app navigation. That's said, we
   * can assume these ids from urls are already validated from parent caches
   */
  const eventTypeId = Number(props.params.eventTypeId);
  const eventId = Number(props.params.eventId);
  const rootState = reduxStoreService().getState();

  const {cacheZero, cacheOne, cacheTwoEvents, cacheThreeEvents, cacheFourEventsProducts} = rootState;

  let requestedData = false;
  if (isCacheFourEventsProductsPopulated(cacheFourEventsProducts)) {
    if (isStateNavigated) {
      reduxStoreService().dispatch(new SaveState());
    }
  } else {
    const groupID = getGroupID(props);
    if (
      !groupID ||
      !cacheZero.options ||
      !cacheZero.options.Group ||
      !isEndUserCacheOnePopulated(cacheOne) ||
      !isEndUserCacheTwoEventsPopulated(cacheTwoEvents) ||
      !isCacheThreeEventsPopulated(cacheThreeEvents)
    ) {
      const route = reduxStoreService().getState().routing.locationBeforeTransitions;
      redirectToEventRegistration(route.pathname, route.query);
    } else {
      reduxStoreService().dispatch(
        EventProductsCalc.request(
          eventProductsBody({
            GroupIDi: groupID,
            GroupTS: cacheZero.options.Group.TS,
            EventTypeID: eventTypeId,
            EventIDi: eventId,
            GroupWeekIDi: cacheThreeEvents.EventRegistrationPaymentStatus!.IDi
          }),
        ),
      );

      requestedData = true;
    }
  }

  return requestedData;
};