import { createSelector } from 'reselect';
import { MultipleGroupsToggleFilterTabs, MultipleGroupsModalFilter, ResetMultipleGroupsModal, ToggleGroupSelect, SelectAll, Deselect, InitMultipleGroupsModal } from './actions';
import { ApplicationState } from '../../../..';
import { IValidator, FormDefinition } from './validation';
import { apiValidationReducerCreator } from '../../../../Validation/reducerCreator';
import { SUFFIX } from './actions';
import { compareDataByRowNum } from '../../../../../utils/dateHelper';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface MultipleGroupsModalActiveFormState {
  SelectedGroupIds: number[];
}

export type MultipleGroupsContext = 'send-message' | 'generate-invoices';

export interface MultipleGroupsModalState {
  isRefreshing: boolean;
  filterString: string;
  isTextSearching: boolean;
  ValidationRules: IValidator;
  ActiveForm: MultipleGroupsModalActiveFormState;
}
const getInitialState = () => ({
  isRefreshing: false,
  filterString: '',
  isTextSearching: false,
  ValidationRules: {...FormDefinition},
  ActiveForm: {
    SelectedGroupIds: [],
  },
});

const filterSelector = (state: ApplicationState) => state.adminEvents.events.modals.multipleGroups.filterString;

const selectedGroupIdsSelector = (state: ApplicationState) => state.adminEvents.events.modals.multipleGroups.ActiveForm.SelectedGroupIds;
export const groupsSelector = (state: ApplicationState) => {
  return state.adminEvents.shared.EventsGroupEventRegistrations || [];
};
export const makeFilteredGroupsSelector = () => {
  return createSelector(
    [groupsSelector, filterSelector, selectedGroupIdsSelector],
    (groups: Array<any>, filter: string, selectedGroupIds: number[]) => {
      let result;
      if (!filter || filter === '' || !groups) result = groups ? [...groups].sort(compareDataByRowNum) : null;
      else {
        const f = filter.toLowerCase();
        result = groups.filter(group => (group.GroupName.toLowerCase().includes(f))
        ).sort(compareDataByRowNum);
      }

      if (result) {
        const mapped = result.map((item) => {
          const existed = selectedGroupIds.findIndex((sId) => sId === item.GroupIDi);
          return {...item, selected: existed !== -1};
        });

        return mapped;
      }

      return null;
    }
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const MultipleGroupsModalReducer: Reducer<MultipleGroupsModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);
  if (isActionType(act, MultipleGroupsToggleFilterTabs)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, InitMultipleGroupsModal)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedGroupIds: act.groupIds,
      },
    };
  } else if (isActionType(act, MultipleGroupsModalFilter)) {
    return {
      ...state,
      filterString: act.filterString,
    };
  } else if (isActionType(act, ToggleGroupSelect)) {
    const groupIds = [...state.ActiveForm.SelectedGroupIds];
    if (act.show) {
      const existed = groupIds.find((id) => id === act.groupId);
      if (!existed && existed !== -1) {
        groupIds.push(act.groupId);
      }
    } else {
      const index = groupIds.findIndex((id) => id === act.groupId);
      if (index !== -1) {
        groupIds.splice(index, 1);
      }
    }
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedGroupIds: groupIds,
      },
    };
  } else if (isActionType(act, SelectAll)) {
    const groupIds = [...state.ActiveForm.SelectedGroupIds];

    act.groupIds.forEach((id) => {
      const existed = groupIds.find((rId) => rId === id);
      if (!existed && id !== -1) {
        groupIds.push(id);
      }
    });
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedGroupIds: groupIds,
      },
    };
  } else if (isActionType(act, Deselect)) {
    const groupIds = [...state.ActiveForm.SelectedGroupIds];
    const newGroupIds: number[] = [];

    groupIds.forEach((id, index) => {
      const existed = act.groupIds.find((rId) => rId === id);
      if (!existed && id !== -1) {
        newGroupIds.push(id);
      }
    });
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedGroupIds: newGroupIds,
      },
    };
  } else if (isActionType(act, ResetMultipleGroupsModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default MultipleGroupsModalReducer;