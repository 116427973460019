import * as React from 'react';
import {Align, Button, Column, MoneyField, Row, Text, View} from '../../../../Elements';
import {standardCurrencyFormat} from "../../../../../utils/classesHelper";
import {checkPermission} from "../../../../../utils/permissionHelper";
import {actionCreators} from "../../../../../store/Settings/PrevOrders/RefundOrFee/actions";
import {bindActionCreators} from 'redux';
import {ApplicationState} from "../../../../../store";
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';

// interface Props {
//   cacheZero: CacheZeroState;
//   AvailableCredit: number;
// }

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class AddCart extends React.Component<{},{}> {
  props: ConnectedProps;

  onAddToCart = () => {
    const {cacheZero: {options}, actions} = this.props;

    if (!options?.GeneralPermissions) return;

    checkPermission(
      actions.refundOrFeeApiSubmitForm,
      options.GeneralPermissions.hasApplyRefundOrConvenienceFee,
      options.GeneralPermissions.hasApplyRefundOrConvenienceFeeReason
    );

  };

  render() {
    const {cacheOne: {CartOrder}, actions, refundOrFee: {ValidationRules, ActiveForm}, apiSaving} = this.props;
    if (!CartOrder) return null;

    const {AvailableCredit} = CartOrder;

    return <View layout="vertical">
      <Row verticalAlignment="middle" marginBottom={16}>
        <Column expand><Text weight="medium">Available Credit:</Text></Column>
        <Column width={90}><Align end><Text weight="medium" align="right">{standardCurrencyFormat(AvailableCredit)}</Text></Align></Column>
      </Row>
      <Row verticalAlignment="middle" marginBottom={16}>
        <Column expand><Text>Refund Amount:</Text></Column>
        <Column width={90}>
          <MoneyField
            id={generateDOMId("refund-amount-textfield")}
            onChange={actions.refundOrFeeSimpleUpdate}
            onBlur={actions.refundOrFeeUpdateValue}
            validationRules={ValidationRules.Refund}
            value={ActiveForm.Refund}
            padding={false}
            disabled={apiSaving > 0}
            placeHolder="$0.00"
            hideError
            align="right"
          />
        </Column>
      </Row>
      <Row verticalAlignment="middle" marginBottom={16}>
        <Column expand><Text>Convenience Fee:</Text></Column>
        <Column width={90}>
          <MoneyField
            onChange={actions.refundOrFeeSimpleUpdate}
            onBlur={actions.refundOrFeeUpdateValue}
            validationRules={ValidationRules.ConvenienceFee}
            value={ActiveForm.ConvenienceFee}
            padding={false}
            disabled={apiSaving > 0}
            placeHolder="$0.00"
            hideError
            align="right"
          />
        </Column>
      </Row>
      <Row>
        <Button disabled={apiSaving > 0} color="white" textColor="green" onClick={this.onAddToCart}>ADD TO CART</Button>
      </Row>
    </View>;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheTwoPrevOrders: state.cacheTwoPrevOrders,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    user: state.user,
    refundOrFee: state.settings.prevOrders.refundOrFee
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators
}, dispatch)});

const ConnectedAddCart = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(AddCart);

export default ConnectedAddCart;
