import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra
} from '../../../Validation/actionCreator';
import { ActionCreator, ApplicationState } from '../../../';
import { SaveState } from '../../../../store/Rollback/actions';
import { scrollSideModalToTop } from '../../../../utils/helpers/adminCMSPageHelper';
import { ADMIN_FACILITY_MODULE_SUFFIX } from '../../CacheOne/actions';
import { getUpdateBlackoutDateBody, getBlackoutDatesHomeRootUrl, getDeleteBlackoutDateBody } from '../../../../constants/urls';
import { navPush } from '../../../../utils';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../../../utils/suffix';
import { PlainRoute } from 'react-router';
import { typeName, Action } from '../../../../utils/StrongActions';

export const UPDATE_FACILITY_BLACKOUT_DATE_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__FACILITY_BLACKOUT_DATE';
export const SUFFIX = ADMIN_FACILITY_MODULE_SUFFIX + '__BLACKOUT_DATE_FORM' + UPDATE_FACILITY_BLACKOUT_DATE_FORM_SUFFIX;
export const DEL_SUFFIX = ADMIN_FACILITY_MODULE_SUFFIX + '__DELETE_BLACKOUT_DATE' + UPDATE_FACILITY_BLACKOUT_DATE_FORM_SUFFIX;

@typeName('ADD_FACILITIES' + SUFFIX)
export class AddFacilities extends Action {
  constructor(public facilityIds: number[]) { super(); }
}
@typeName('REMOVE_FACILITY' + SUFFIX)
export class RemoveFacility extends Action {
  constructor(public facilityId: number) { super(); }
}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const UpdateBlackoutDateSubmitActions = createApiSubmitActions(SUFFIX);
export const DeleteBlackoutDateSubmitActions = createApiSubmitActions(DEL_SUFFIX);

export const BlackoutDateFormNamespace = (): string => 'pages--facility-location--blackout-date';

const getSubmitFormExtra = (routes, getState) => {
  const route = routes[routes.length - 1];
  const state = getState();

  return getCacheLevelExtra(
    true,
    !!state.adminFacilityLocation.cacheTwoBlackoutDate.FacilitiesBlackoutDate,
  );
};

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminFacilityLocation.blackoutDates.form),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  apiSubmitForm: (router: any, routes: any): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    // form validation (not including file)
    const valid = innerApiSubmitFormMethod(
      dispatch,
      UpdateBlackoutDateSubmitActions,
      (s: ApplicationState) => s.adminFacilityLocation.blackoutDates.form,
      undefined,
      undefined,
      true,
      true,
      undefined,
      undefined,
    );
    
    if (valid) {
      const body = getUpdateBlackoutDateBody(state.adminFacilityLocation.blackoutDates.form.ActiveForm);
      if (state.adminFacilityLocation.cacheOne.FacilitiesLocation) {
        navPush(router, getBlackoutDatesHomeRootUrl({
          locationId: state.adminFacilityLocation.cacheOne.FacilitiesLocation.ID,
          locationName: state.adminFacilityLocation.cacheOne.FacilitiesLocation.Name
        }));
      }
      dispatch(UpdateBlackoutDateSubmitActions.request(
          body,
          getSubmitFormExtra(routes, getState),
        )
      );
    } else {
      scrollSideModalToTop(BlackoutDateFormNamespace());
    }
  },
  deleteBlackoutDate: (blackoutDate: number, router: any, routes: PlainRoute<any>[]): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const route = routes[routes.length - 1];

    if (state.adminFacilityLocation.blackoutDates.home.deletingBlackoutDate) {
      const body = getDeleteBlackoutDateBody(state.adminFacilityLocation.blackoutDates.home.deletingBlackoutDate.ID, true);
      if (state.adminFacilityLocation.cacheOne.FacilitiesLocation) {
        dispatch(new SaveState());
        navPush(router, getBlackoutDatesHomeRootUrl({
          locationId: state.adminFacilityLocation.cacheOne.FacilitiesLocation.ID,
          locationName: state.adminFacilityLocation.cacheOne.FacilitiesLocation.Name
        }));
      }

      dispatch(
        DeleteBlackoutDateSubmitActions.request(
          body,
          getSubmitFormExtra(routes, getState),
          route,
        )
      );
    }
  },
  addFacilities: (facilityIds: number[]): ActionCreator => dispatch => dispatch(new AddFacilities(facilityIds)),
  removeFacility: (facilityId: number): ActionCreator => dispatch => dispatch(new RemoveFacility(facilityId)),
};