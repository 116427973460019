import * as React from 'react';
import StepProgressBarStep from "../../StepProgressBar/Step";

export interface StepProps {
  children?: React.ReactNode;
  selected?: boolean;
  num?: number;
  done?: boolean;
}

const Step: React.SFC<StepProps> = ({ children, selected, num, done }: StepProps): React.ReactElement<StepProps> => (
  <StepProgressBarStep selected={selected} num={num} done={done}>{children}</StepProgressBarStep>
);

export default Step;
