import * as React from 'react';
import '../../../styles/app/header/index.scss';
import '../../../styles/app/header/logo.scss';
import {mergeClassNames} from '@tentaroo/shared';
import { ActionButton } from '../../Elements';
import DrawerIcon from '../../../images/app/header/drawer.svg';
import { bindActionCreators } from 'redux';
import { actionCreators, ToggleGlobalSearch } from '../../../store/App/actions';
import { RouteComponentProps, WithRouterProps } from 'react-router';
import Logo from '../../Elements/Logo/LogoComponent';
import Back from './Back';
import Title from './Title';
import Actions from './Actions';
import Info from './Info';
import Search from './Search';
import Account from './Account';
import { withRouter } from 'react-router';
import {navPush} from "../../../utils/navHelper";
import CloseIcon from '../../../images/icons/close.svg';
import { ApplicationState } from '../../../store';
import { generateDOMId } from '../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';
import { reduxStoreService } from '../../../store/service';
import { namespace } from "./constants";
import { WithInertAttribute, WithInertPage } from '../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>
>;

export interface Props {
  className?: string;
  nav?: React.ReactElement<any>;
  centerTabs?: boolean;
  showDrawer?: boolean;
  added?: boolean;
  // cart?: {
  //   getEmptyCartUrl: () => string;
  //   getEmptyCartBody: (params: any) => void;
  //   postNavigate?: string;
  // };
  cart?: boolean;
  add?: boolean;
  mobileCart?: boolean;
  mobileAdd?: boolean;
  back?: string;
  disableBack?: boolean;
  onBack?: () => void;
  title?: string;
  subTitle?: string;
  search?: boolean; // @todo: remove search and just use onSearch
  mobileSearch?: boolean;
  refresh?: boolean;
  onSearch?: (searchString: string) => void;
  info?: React.ReactElement<any>;
  hideSelectClient?: boolean;
  hideIcon?: boolean;
  showSelectClientLonger?: boolean;
  neverLoad?: boolean;
  alwaysLoad?: boolean;
  loading?: boolean;
  addedLength?: number;
  onClose?: () => void;
  isBlank?: boolean;
  onClickAdd?: () => void;
  // allow customizing the refresh behavior, instead of always using global refresh
  customRefresh?: () => void;
  isAdminPage?: boolean;
  longTabs?: boolean;
  optionsControl?: React.ReactElement<any>;
}

class Header extends React.Component<Props, {}> {
  public props: Readonly<Props & ConnectedProps>;

  public static defaultProps = {
    cart: false
  };

  public handleSearchOpen = () => {
    reduxStoreService().dispatch(new ToggleGlobalSearch(true));
  };

  public handleSearchClose = () => {
    reduxStoreService().dispatch(new ToggleGlobalSearch(false));
    if (this.props.onSearch) this.props.onSearch('');
    else if (!this.props.onSearch) this.props.actions.updateGlobalSearchText('');
  };

  onSelectClient = () => {
    this.props.actions.pushAccountsModal();
  };

  onDrawerToggle = () => {
    if (this.props.app.isDrawerOpen) this.props.actions.hideDrawer();
    else this.props.actions.showDrawer();
  };

  onClickHeader = () => {
    if (this.props.app.isDrawerOpen) this.props.actions.hideDrawer();
  };

  onClickCart = () => {
    this.props.actions.pushCartModal();
  };

  public render() {
    const {
      cart, nav, centerTabs, onClose, showDrawer, back, title, search, refresh, info, user, mobileCart,
      app, subTitle, mobileSearch, added, loading, hideIcon, routes, actions, onBack, addedLength, disableBack,
      hideSelectClient, session: { getLoginFormFailed }, cacheZeroOptions, showSelectClientLonger, neverLoad, alwaysLoad, add, onClickAdd, mobileAdd, customRefresh, isAdminPage, longTabs,
      isBlank, optionsControl,
    } = this.props;

    const searchOpen = app.globalSearchOpen;

    let isAdmin = false;
    if (user.user) isAdmin = !!user.user.str_permissions.hasAdminAccess;
    const isLoading = loading === undefined ? alwaysLoad : loading;
    const isSaving = app.apiSaving > 0 && !neverLoad;

    let className = mergeClassNames(namespace(), this.props);

    if (centerTabs) className += ' center-tabs';
    else className += ' scrollable-tabs';

    if (search && searchOpen) className += ' search-open';
    if (onClose) className += ' has-close';
    if (showDrawer) className += ' has-drawer';
    if (back || onBack) className += ' has-back-button';
    if (isLoading || isSaving) className += ' faded';
    if (isAdminPage) className += ' admin-page';
    if (longTabs) className += ' long-tabs';
    let accountName;
    if (cacheZeroOptions && cacheZeroOptions.Group) {
      accountName = cacheZeroOptions.Group.Name;
    }

    if (getLoginFormFailed || isBlank) {
      return <div id="app--header" className={className}>
        <div className={`${namespace()}--wrapper`}>
        </div>
      </div>;
    }

    return (
      <div inert={this.props.inert} id="app--header" className={className} onClick={this.onClickHeader}>
        <div className={`${namespace()}--wrapper`}>
          {onClose && <a className={`${namespace()}--close`} onClick={onClose}>
            <CloseIcon width="14px" height="14px" className={`${namespace()}--image`}/>
          </a>}
          <div className={`${namespace()}--left-column`}>
            {showDrawer ? (
              <ActionButton
                id={generateDOMId("app-drawer")}
                className={`${namespace()}--drawer`}
                onClick={this.onDrawerToggle}
                selected={app.isDrawerOpen}
              >
                <DrawerIcon width="18px" height="12px"/>
              </ActionButton>
            ) : null}
            {!hideIcon && <div className={`${namespace()}--logo--wrapper`}>
              <Logo position="top-blue-bar" className={`${namespace()}--logo`}/>
            </div>}
          </div>
          {!(search && searchOpen) && back ? <Back id={generateDOMId(`header-back-btn`)} onClick={() => navPush(this.props.router, back)} disabled={disableBack}/> : null}
          {!(search && searchOpen) && onBack ? <Back onClick={onBack} disabled={disableBack}/> : null}
          {!(search && searchOpen) && title ? <Title subTitle={subTitle}>{title}</Title> : null}
          {!(search && searchOpen) && nav}
          {!(search && searchOpen) && nav && <div className={`${namespace()}--nav-fade`}/>}
          {!(search && searchOpen) && info && !isAdmin && <Info>{info}</Info>}
          {!(search && searchOpen) && isAdmin && showDrawer && !hideSelectClient && <Account
            selectedClient={accountName}
            onClick={this.onSelectClient}
            showSelectClientLonger={showSelectClientLonger}
          />}
          {(search && searchOpen) && <Search
            onClose={this.handleSearchClose}
            onSearch={actions.updateGlobalSearchText}
            value={app.searchFilter}
          />}
          {!(search && searchOpen) && <Actions
            routes={routes}
            marginLeftAuto={info || isAdmin ? false : true}
            cart={!!cart}
            add={add}
            mobileAdd={mobileAdd}
            onClickAdd={onClickAdd}
            onClickCart={this.onClickCart}
            added={added}
            addedLength={addedLength}
            search={search}
            refresh={refresh}
            mobileCart={mobileCart}
            mobileSearch={mobileSearch}
            customRefresh={customRefresh}
            onSearchOpen={this.handleSearchOpen}
          />}
          {optionsControl ? optionsControl : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    app: state.app,
    user: state.user,
    session: state.session,
    cacheZeroOptions: state.cacheZero.options
  };
};

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({...actionCreators}, dispatch) });

const ConnectedAppHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(Header);

export default withRouter<Props>(WithInertPage<Props & WithRouterProps>(ConnectedAppHeader));
export { default as Nav } from './Nav';
export { default as NavItem } from './Nav/Tab';
