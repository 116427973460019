
import { APISuccess, SUCCESS_ACTION, SUBMIT_SUCCESS_ACTION, APIRequest, WithRootState } from "../../../store/Validation/actionCreator";
import { ClearAdminEventsCacheTwoEvent, GetAdminEvent, ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX } from './actions';
import { ClearAllCache, SetCacheAction } from "../../../store/App/actions";
import { ADMIN_EVENT_TYPES_SUFFIX, ClearAdminEventsCacheBelowOne, ClearAllAdminEventsCache } from "../CacheOne/actions";
import { AdminEventsEventRow, AdminEvent, AdminEventClass, AdminEventsEventCampsite, EventsEventDay } from "../../../models/api/adminEventsCacheTwoEvent";
import { shouldSkipResponseForCache } from "../../Validation/reducerCreator";
import { ApplicationState } from "../..";
import { sortByOrd } from "../../CacheZero";
import { createSelector } from "reselect";
import { updateEntityInList } from "../../../utils/dataHelper";
import { ClientSubmitForm } from "../Events/Event/Form/actions";
import { EventsEventTypePricingTier, ParticipantType } from "../../../models/api/adminEventsCacheOne";
import { UpdateEventImportPricingSubmitActions } from "../Events/Modals/ImportFromEvent/actions";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../utils/StrongActions";

const adminEventsCacheTwoEventKeys = {
  EventsEventRow: true,
  EventsEvent: true,
  EventsEventCampsites: true,
  EventsEventClasses: true,
  EventsEventDays: true,
};

export interface AdminEventsCacheTwoEventState {
  EventsEventRow?: AdminEventsEventRow;
  EventsEvent?: AdminEvent;
  EventsEventClasses?: AdminEventClass[];
  EventsEventCampsites?: AdminEventsEventCampsite[];
  EventsEventDays?: EventsEventDay[];
}

export enum AdminEventReportType {
  EXPORT_GROUP_INVOICES=1,
  EXPORT_GROUPS=2,
  EXPORT_PARTICIPANT_ROSTER_PDF=3,
  EXPORT_PARTICIPANT_ROSTER_CSV=4,
  EXPORT_CLASS_SCHEDULE_CSV=5,
  EXPORT_CLASS_SCHEDULE_YOUTH=7,
  EXPORT_CLASS_SCHEDULE_ADULTS=8,
  EXPORT_CLASS_SCHEDULE_GROUP=9,
  INSTRUCTOR_CLASS_ROSTER_PDF=10,
  PARTICIPANT_CLASS_SCHEDULES_BY_GROUP=11,
  PARTICIPANT_CLASS_SCHEDULES_REQUIREMENTS_COMPLETED=12,
  PARTICIPANT_CLASS_SCHEDULES_BY_PARTICIPANT_PDF=13,
  PARTICIPANT_CLASS_SCHEDULES_BY_CLASS=14,
  PARTICIPANT_CLASS_SCHEDULES_BY_PARTICIPANT_CSV=15,
  PARTICIPANT_CLASS_SCHEDULES_EVENT_OPTIONS=16,
  EXPORT_BLUE_CARDS=17,
  EXPORT_GROUP_CLASS_OPTION_REGISTRATIONS=18,
  GENERATE_PARTICIPANT_LIST=19,
  EXPORT_PRODUCT_ORDERS=20,
  EXPORT_REGISTRATION_AMOUNTS=21,
  EXPORT_SHIRT_SIZES=22,
  EXPORT_SCOUTBOOK_REQUIREMENTS=23,
}

const setAdminEventsCacheTwoEventData = (state, data): AdminEventsCacheTwoEventState => {
    const newState: any = {...state};

    for (let key of Object.keys(data)) {
      // will skip if a field is undefined
      if (adminEventsCacheTwoEventKeys[key] && data[key] !== undefined) {
        newState[key] = data[key];
      }
    }

    if (data.EventsEventClassRow) {
      // update class, but preserve `Ord`
      updateEntityInList(
        newState,
        'EventsEventClasses',
        data.EventsEventClassRow,
        'IDi',
        (oldClass, newClass) => ({...newClass, Ord: oldClass.Ord}),
      );
    }

    if (data.EventsEvent_RegistrationStatuses) {
      newState.EventsEvent.RegistrationStatuses = data.EventsEvent_RegistrationStatuses.RegistrationStatuses;
    }

    if (data.EventsEventType && newState.EventsEvent) {
      const ParticipantTypes = data.EventsEventType.Pricing4.ParticipantTypes as ParticipantType[];
      const PricingTiers = data.EventsEventType.Pricing4.PricingTiers as EventsEventTypePricingTier[];

      if (ParticipantTypes) {
        ParticipantTypes.forEach((participantType) => {
          const existedPricing = newState.EventsEvent.Pricing ? newState.EventsEvent.Pricing.find((p) => p.ParticipantTypeID === participantType.ID) : null;
          const existedCapacity = newState.EventsEvent.Capacities ? newState.EventsEvent.Capacities.find((c) => c.ParticipantTypeID === participantType.ID) : null;

          if (!existedPricing) {
            if (!newState.EventsEvent.Pricing) newState.EventsEvent.Pricing = [];

            PricingTiers.forEach((pricingTier) => {
              newState.EventsEvent.Pricing.push({
                PricingTierID: pricingTier.ID,
                ParticipantTypeID: participantType.ID,
                AmountEarly: 0,
                AmountBase: 0,
                AmountLate: 0,
              });
            });
          }

          if (!existedCapacity) {
            if (!newState.EventsEvent.Capacities) newState.EventsEvent.Capacities = [];

            newState.EventsEvent.Capacities.push({
              ParticipantTypeID: participantType.ID,
              MaxParticipants: null,
            });
          }
        });
      }
    }

    return newState;
};

const handleResponse = (state, innerResponse,) => {
  const newState: AdminEventsCacheTwoEventState = setAdminEventsCacheTwoEventData(state, innerResponse);
  return newState;
};

export const adminEventClassesSelector = (state: ApplicationState) => state.adminEvents.cacheTwoEvent.EventsEventClasses || [];
export const makeActiveClassesSelector = () => {
  return createSelector(
    [adminEventClassesSelector],
    (classes: AdminEventClass[]) => {
      return classes.filter((c) => !c.Inactive);
    }
  );
};

export const makeEventDaysSelector = () => {
  return createSelector(
    [
      (state: ApplicationState) => state.adminEvents.cacheTwoEvent.EventsEventDays || [],
    ],
    (days: EventsEventDay[]) => {
      return [...days].sort(sortByOrd);
    }
  );
};

const AdminEventsCacheTwoEventReducer: Reducer<AdminEventsCacheTwoEventState> = (s, act: WithRootState<AnyAction>) => {
  const state = {...s};
  // @todo - add cache one clear actions and setcacheaction suffix check, when implementing admin settings cache one
  if (act.type.includes(ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX) && (act.type.includes(SUCCESS_ACTION) || act.type.includes(SUBMIT_SUCCESS_ACTION)) || (isActionType(act, SetCacheAction) && act.actions.requestType.includes(ADMIN_EVENT_TYPES_SUFFIX))) {
    const action = <WithRootState<APISuccess>> act;
    // Class Form update should always update this cache level
    if (shouldSkipResponseForCache(act)) return state;

    const newState = handleResponse(state, action.response.response) || {...state};
    return newState;
  } else if (act.type === UpdateEventImportPricingSubmitActions.successType) {
    const action = <WithRootState<APISuccess>>act;
    if (!action.response || !action.response.response) {
      return state;
    }

    const newState: AdminEventsCacheTwoEventState = { ...state };
    if (!newState.EventsEvent || !action.response.response.EventsEvent) return newState;

    const Event = action.response.response.EventsEvent;

    newState.EventsEvent.GLAccountID = Event.GLAccountID;
    newState.EventsEvent.AllowReductionsUntilDate = Event.AllowReductionsUntilDate;
    newState.EventsEvent.YouthDepositDueDate = Event.YouthDepositDueDate;
    newState.EventsEvent.YouthDepositAmount = Event.YouthDepositAmount;
    newState.EventsEvent.CampsiteDepositDefaultAmount = Event.CampsiteDepositDefaultAmount;
    newState.EventsEvent.BasePricingStartDate = Event.BasePricingStartDate;
    newState.EventsEvent.LatePricingStartDate = Event.LatePricingStartDate;
    newState.EventsEvent.Pricing = Event.Pricing;

    return newState;
  } else if (act.type === GetAdminEvent.requestType) {
    const action = <WithRootState<APIRequest>>act;

    if (!action.extra || !action.extra.optimisticLoad) return state;
    const rootState = act.rootState;
    const eventIDi = action.value.EventIDi;

    if (eventIDi && rootState.adminEvents.cacheOne.EventsEvents) {
      const event = rootState.adminEvents.cacheOne.EventsEvents.find((e) => e.IDi === eventIDi);

      if (event) {
        return {
          ...state,
          EventsEvent: { ...event, Name: event.EventName } as any,
        };
      }
    }

    return state;
  } else if (isActionType(act, ClientSubmitForm)) {
    return {
      ...state,
      EventsEvent: {
        ...act.data,
      },
    };
  } else if (
    isActionType(act, ClearAdminEventsCacheTwoEvent) ||
    isActionType(act, ClearAdminEventsCacheBelowOne) ||
    isActionType(act, ClearAllAdminEventsCache) ||
    isActionType(act, ClearAllCache)
  ) {
    return {};
  }
  return state || {};
};
  
export default AdminEventsCacheTwoEventReducer;