import {SUFFIX, ValidateActions} from './actions';
import * as NM from '../../../../../../constants/messages/numbersWizard';
import {apiValidationReducerCreator} from '../../../../../Validation/reducerCreator';
import {APIFailure, APISuccess, UpdateCurrValue, WithRootState} from "../../../../../Validation/actionCreator";
import {EventProductsCalc, EventProductsSave, SUFFIX_SAVE} from "../../../../../CacheFourEventsProducts/actions";
import {FormDefinition} from "./validation";
import {EventRegisterProductsReset} from "../Main/actions";
import {FinancialSummaryInit, initFinancialSummary} from "../../../../../../utils/classesHelper";
import {ApiSubmitActionsProductsProducts} from "../Products/actions";
import {CacheFourEventsProductsCore} from "../../../../../CacheFourEventsProducts";
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../../utils/StrongActions';

export interface ProductsWizardConfirmationState {
  ActiveForm: {
    selectedFinance?: 'min' | 'full' | 'other' | 'credit';
    otherValue?: number;
  },
  ValidationRules: any;
  SubmitErrorMessage?: string;
  minValue?: number;
  maxValue?: number;
}

const determineInlineError = (errors: any) => {
  let message = '';
  if (errors) {
    if (errors.otherValue) message = NM.INVALID_PAYMENT;
  }

  return message;
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, determineInlineError);
const checkCache4ProductsSaveValidation = apiValidationReducerCreator(SUFFIX_SAVE, determineInlineError);

const getInitialState = (): ProductsWizardConfirmationState => {
  return {
    ActiveForm: {
      selectedFinance: 'min',
      otherValue: 0
    }, ValidationRules: {...FormDefinition}};
};

const ProductsWizardConfirmation: Reducer<ProductsWizardConfirmationState> = (oldState = {...getInitialState()}, act: WithRootState<AnyAction>) => {
  const s = checkApiValidation(oldState, act);
  const state= checkCache4ProductsSaveValidation(s, act);
  if (
    act.type === EventProductsCalc.successType ||
    act.type === ApiSubmitActionsProductsProducts.successType ||
    (act.type === EventProductsSave.failureType && (act as WithRootState<APIFailure>).response.status === 400 && (act as WithRootState<APIFailure>).response.xhr.response.error.Detail === "Canceled")) {
    const action = <WithRootState<APISuccess>> act;
    let data: CacheFourEventsProductsCore;
    if (
      act.type === EventProductsCalc.successType ||
      act.type === ApiSubmitActionsProductsProducts.successType
    ) {
      data = action.response.response;
    } else {
      data = action.response.xhr.response;
    }
    if (data.EventRegistrationPaymentStatus) {
      const vals: FinancialSummaryInit = initFinancialSummary(
        action.rootState,
        data.EventRegistrationPaymentStatus,
        (rootState) => rootState.events.event.register.products.confirmation.ActiveForm.selectedFinance === 'other',
        true,
      );

      return {
        ...state,
        ActiveForm: {selectedFinance: vals.paymentType, otherValue: vals.otherDefaultValue},
        ValidationRules: {...state.ValidationRules, otherValue: vals.otherValueRules},
        minValue: data.EventRegistrationPaymentStatus.AmountMin,
        maxValue: data.EventRegistrationPaymentStatus.AmountMax
      };
    }
  } else if (act.type === ValidateActions.updateCurrType) {
    const action = <WithRootState<UpdateCurrValue>> act;
    const newState = {...state};
    newState.ActiveForm = {...state.ActiveForm};
    if (action.value === 'full') {
      newState.ActiveForm.otherValue = state.maxValue;
    } else if (action.value === 'min') {
      newState.ActiveForm.otherValue = state.minValue;
    } else if (action.value === 'credit') {
      newState.ActiveForm.otherValue = 0;
    } else {
      return state;
    }
    return newState;
  } else if (isActionType(act, EventRegisterProductsReset)) {
    return getInitialState();
  }

  return state;
};

export default ProductsWizardConfirmation;
