import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../../../store';
import { bindActionCreators } from 'redux';
import {
  actionCreators as cacheZeroActionCreators
} from '../../../../../../../store/CacheZero/actions';
import { actionCreators as appActionCreators } from '../../../../../../../store/App/actions';
import { actionCreators as rollbackActionCreators, SaveState } from '../../../../../../../store/Rollback/actions';
import { actionCreators, getTimeBlockAttrKey, UpdateTimeBlocksActions } from '../../../../../../../store/AdminEvents/Events/Event/TimeBlocks/actions';
import { Card, Row, Column, Select, Switch, Button, Alert, Form, FormActions, PageLoader } from '../../../../../../../components/Elements';
import '../../../../../../../styles/pages/admin-events/events/classes/time-blocks-tab/index.scss';
import { Main, MainContent } from '../../../../../../Layouts';
import TimePicker from '../../../../../../Elements/TimePicker';
import { ImportFromEventType } from '../../../Modals/ImportFromEvent';
import { UpdateEventImportTimeBlocksSubmitActions } from '../../../../../../../store/AdminEvents/Events/Modals/ImportFromEvent/actions';
import { isPathUnderAdminEvent } from '../../../../../../../utils/eventsHelper';
import { actionCreators as cacheTwoEventActionCreators } from '../../../../../../../store/AdminEvents/CacheTwoEvent/actions';
import { generateDOMId } from '../../../../../../../utils/cypressHelper';
import { shouldReconfigRouter } from '../../../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { noOpenedModals } from '../../../../../../../utils/modalHelper';
import { reduxStoreService } from '../../../../../../../store/service';
import {isAdminEventsCacheTwoEventPopulated} from '../../../../../../../utils/cachePopulatedCheckers/adminEvents';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--event--time-blocks-tab';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {resourceId: string}>;

type Props = WithInertAttribute<{
  disabled?: boolean;
}>

class AdminEventsEventTimeBlocksTab extends React.Component<Props, {}> {
  public props: ConnectedProps & Props;
  private nextLocation;

  componentDidMount() {
    this.configRouter();
  }

  private configRouter() {
    const {router, routes} = this.props;
    router.setRouteLeaveHook(routes[routes.length - 1], this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    const {adminEventsCacheTwoEvent} = this.props;

    this.nextLocation = nextLocation;
    if (reduxStoreService().getState().app.apiLoading === 0) {
      if (isAdminEventsCacheTwoEventPopulated(adminEventsCacheTwoEvent)) {
        reduxStoreService().dispatch(new SaveState());
      }
    }
  };

  componentDidUpdate(prevProps: Props & ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
  }

  componentWillUnmount() {
    const {actions, adminEventsCacheTwoEvent} = this.props;
    // if we are not navigating to any pages under cache two event, clear cache
    if (
      this.nextLocation &&
      !isPathUnderAdminEvent(this.nextLocation.pathname) &&
      isAdminEventsCacheTwoEventPopulated(adminEventsCacheTwoEvent)
    ) {
      actions.clearAdminEventsCacheTwoEvent();
    }

    this.resetRouteLeaveHook();
  }

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  onImportClick = () => {
    this.props.actions.pushImportFromEventModal({importFromEventType: ImportFromEventType.TIME_BLOCKS});
  };
  onSave = () => {
    this.props.actions.submit();
  };

  isFormDisabled = () => {
    const {adminEventsCacheOne, disabled} = this.props;

    return disabled || (!!adminEventsCacheOne.EventsEventType && !adminEventsCacheOne.EventsEventType.Permissions.hasEditEvent);
  };

  public render() {
    const {apiSavingMap, apiLoading, timeBlocksTab: {ActiveForm, ValidationRules, SubmitErrorMessage}, inert, adminEventsCacheOne, adminEventsCacheTwoEvent, actions,  apiSaving} = this.props;

    const saving = (
      apiSavingMap[UpdateTimeBlocksActions.requestType] ||
      apiSavingMap[UpdateEventImportTimeBlocksSubmitActions.requestType]
    ) && noOpenedModals();

    return (
      <Main inert={inert}>
        <MainContent
          className={`${namespace()}`}
        >
          <Form
            onSubmit={e => e.preventDefault()}
            actions={
              <FormActions>
                <Button
                  flat big
                  textColor="black"
                  disabled={this.isFormDisabled()}
                  onClick={this.onSave}>SAVE</Button>
              </FormActions>
            }
          >
            <div className={`${namespace()}--list-wrapper`}>
              {adminEventsCacheOne.EventsEventType && adminEventsCacheOne.EventsEventType.Permissions.hasEditEventReason && <Alert className={`${namespace()}--alert`}>{adminEventsCacheOne.EventsEventType.Permissions.hasEditEventReason}</Alert>}
              {SubmitErrorMessage && <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert>}
              <Row>
                <Column className={`${namespace()}--num-time-blocks-col`} span={3} mobileSpan={3}>
                  <Select
                    label='Number Of Time Blocks'
                    disabled={apiLoading > 0 || this.isFormDisabled()}
                    onChangeValue={actions.updateValue}
                    value={ActiveForm.NumberOfTimeBlocks}
                    validationRules={ValidationRules.NumberOfTimeBlocks}
                    overflowLabel
                    isNumber
                  />
                </Column>
                <Column marginTop={8} style={{justifyContent: 'flex-end'}} height={36} span={3} mobileSpan={6}>
                  <Button
                    id={generateDOMId("import-event-btn")}
                    disabled={this.isFormDisabled()}
                    textColor='black' color='white'
                    onClick={this.onImportClick}>IMPORT</Button>
                </Column>
              </Row>
              {!!adminEventsCacheTwoEvent.EventsEvent && adminEventsCacheTwoEvent.EventsEvent.TimeBlocks_Settings.TimeBlocks.map((tb, index) => {

                if (ActiveForm.NumberOfTimeBlocks && index >= ActiveForm.NumberOfTimeBlocks) return null;
                const ShowTimeBlockKey = getTimeBlockAttrKey(tb.ID, 'ShowTimeBlock');
                const ShowStartTimeKey = getTimeBlockAttrKey(tb.ID, 'ShowStartTime');
                const StartHourKey = getTimeBlockAttrKey(tb.ID, 'StartHour');
                const StartMinKey = getTimeBlockAttrKey(tb.ID, 'StartMin');
                const StartPeriodKey = getTimeBlockAttrKey(tb.ID, 'StartPeriod');
                const EndHourKey = getTimeBlockAttrKey(tb.ID, 'EndHour');
                const EndMinKey = getTimeBlockAttrKey(tb.ID, 'EndMin');
                const EndPeriodKey = getTimeBlockAttrKey(tb.ID, 'EndPeriod');
                return (
                  <Card
                    className={`${namespace()}--card`}
                    key={`time-block-card-${tb.ID}`}
                  >
                    <div className={`${namespace()}--card--title`}>{`Time Block ${tb.ID}`}</div>
                    <Row marginTop={16}>
                      <Column span={6} mobileSpan={12}>
                        <TimePicker
                          label="Start Time"
                          disabled={this.isFormDisabled() || apiSaving > 0}
                          customizedId={`timeBlock_${tb.ID}`}
                          onChangeValue={actions.updateValue}
                          hourValue={ActiveForm[StartHourKey]}
                          minValue={ActiveForm[StartMinKey]}
                          periodValue={ActiveForm[StartPeriodKey]}
                          hourValidationRules={ValidationRules[StartHourKey]}
                          minValidationRules={ValidationRules[StartMinKey]}
                          periodValidationRules={ValidationRules[StartPeriodKey]}
                        />
                      </Column>
                      <Column span={6} mobileSpan={12} mobileMarginTop={8}>
                        <TimePicker
                          label="End Time"
                          disabled={this.isFormDisabled() || apiSaving > 0}
                          customizedId={`timeBlock_${tb.ID}`}
                          onChangeValue={actions.updateValue}
                          hourValue={ActiveForm[EndHourKey]}
                          minValue={ActiveForm[EndMinKey]}
                          periodValue={ActiveForm[EndPeriodKey]}
                          hourValidationRules={ValidationRules[EndHourKey]}
                          minValidationRules={ValidationRules[EndMinKey]}
                          periodValidationRules={ValidationRules[EndPeriodKey]}
                        />
                      </Column>
                    </Row>
                    <Row marginTop={8}>
                      <Column span={6} mobileSpan={12}>
                        <Switch
                          margin={false}
                          newDesign
                          disabled={this.isFormDisabled() || apiSaving > 0}
                          label="Show Time Block Number"
                          multilineLabel
                          value={!!ActiveForm[ShowTimeBlockKey]}
                          validationRules={ValidationRules[ShowTimeBlockKey]}
                          onChange={actions.updateValue}
                        />
                      </Column>
                      <Column span={6} mobileSpan={12}>
                        <Switch
                          margin={false}
                          newDesign
                          disabled={this.isFormDisabled() || apiSaving > 0}
                          label="Show Time"
                          multilineLabel
                          value={!!ActiveForm[ShowStartTimeKey]}
                          validationRules={ValidationRules[ShowStartTimeKey]}
                          onChange={actions.updateValue}
                        />
                      </Column>
                    </Row>
                  </Card>
                );
              })}
            </div>
          </Form>
          {saving && <PageLoader className={`${namespace()}--page-loader`} />}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSavingMap: state.app.apiSavingMap,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsCacheTwoEvent: state.adminEvents.cacheTwoEvent,
    cacheZero: state.cacheZero,
    session: state.session,
    user: state.user,
    apiSaving: state.app.apiSaving,
    timeBlocksTab: state.adminEvents.events.event.classes.timeBlocksTab,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheZeroActionCreators,
    ...appActionCreators,
    ...rollbackActionCreators,
    ...cacheTwoEventActionCreators,
  }, dispatch),
});
  
const ConnectedAdminEventsEventTimeBlocksTab = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(AdminEventsEventTimeBlocksTab);

export default withRouter<Props>(ConnectedAdminEventsEventTimeBlocksTab);
