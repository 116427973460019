import * as React from 'react';
import {default as AppHeader} from '../../../App/Header';
import {makeSelectedLocationSelector} from "../../../../store/CacheTwoFacilities";
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import {makeSelectedFacilityTypeSelectorB} from "../../../../store/CacheTwoBFacilities";
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & RouteComponentProps<{}, {}>;

const Header: React.SFC<ConnectedProps> = (props): React.ReactElement<undefined> => {
  const sp = props.location.pathname.split('/');

  let title = '';
  if (props.selectedFacilityType) {
    title = `${props.selectedFacilityType.NameSingular} Info`;
  }

  return <AppHeader
    showDrawer
    back={`/${sp[1]}/${sp[2]}/${sp[3]}/${sp[4]}`}
    title={title}
    cart
    refresh
  />;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedLocation = makeSelectedLocationSelector();
  const selectedFacilityType = makeSelectedFacilityTypeSelectorB();
  return {
    selectedLocation: selectedLocation(state),
    selectedFacilityType: selectedFacilityType(state)
  };
};

const ConnectedHeader = connect(
  mapStateToProps,
  null,
  getMergeProps<WithRouterProps>(),
)(Header);

export default withRouter<{}>(ConnectedHeader);
