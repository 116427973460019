import { EnhancedStore } from "@reduxjs/toolkit";
import { Location } from "history";

import { SetIsRouterChangeRollbackFinished, SetPreviousLocation } from "../store/App/actions";
import { getIsFromHandleError, getIsRollingBackThroughBrowserState, isCancelingRequestBeforeCacheZeroIsLoaded, rollbackStateExists, shouldRollbackOnBrowserUrlChange } from "./helpers";
import type { ApplicationState } from "../store";
import {reduxStoreService} from "../store/service";
import {hasOngoingSequentialRequest} from "../store/App/reducerHelpers";

export const onBeforeBrowserUrlChange =(location: Location, store: EnhancedStore<ApplicationState>) => {
  const isFromHandleError = getIsFromHandleError(location);
  const isRollingBackThroughBrowserState = getIsRollingBackThroughBrowserState(location);

  let isRollingBack = false;
  // This condition matches conditions in `handleRollbackOnBrowserUrlChange` when `RestoreState` is dispatched
  if (
    (shouldRollbackOnBrowserUrlChange(store.getState(), false, isRollingBackThroughBrowserState) &&
      // Here we still want to set the flag to false for `isCancelingRequestBeforeCacheZeroIsLoaded` case,
      // even when theres no rollback state, because we want to make the cancellation atomic
      (rollbackStateExists(store.getState()) || isCancelingRequestBeforeCacheZeroIsLoaded(store.getState(), isFromHandleError))
    ) ||
    shouldRollbackOnBrowserUrlChange(store.getState(), true, isRollingBackThroughBrowserState)
  ) {
    // Rollback will roll this flag back to true
    store.dispatch(new SetIsRouterChangeRollbackFinished(false));
    isRollingBack = true;
  }

  const rootState = reduxStoreService().getState();

  // By checking !isRollingBack we block both rollback cases here, because
  // in both cases the rollback restores proper value of it, and we don't want to
  // count location that's not completely loaded as a location change
  // We also block on saving because we know any navigation during a save will
  // be canceled
  if (
    !isRollingBack &&
    (!rootState.app.apiSaving || hasOngoingSequentialRequest(rootState))
  ) {
    reduxStoreService().dispatch(new SetPreviousLocation(rootState.routing.locationBeforeTransitions));
  }
};