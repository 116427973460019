import * as React from 'react';
import '../../../styles/elements/side-bar-nav/index.scss';

import {namespace} from "./constants";

export interface SidebarNavProps {
  children?: React.ReactNode;
  className?: string;
  forceTablet?: boolean;
}

const SideBarNav: React.SFC<SidebarNavProps> = (props: SidebarNavProps): React.ReactElement<SidebarNavProps> => (
  <nav className={`${namespace()} ${props.forceTablet ? 'force-tablet' : ''}`}>
    <ul className={`${namespace()}--list ${props.forceTablet ? 'force-tablet' : ''}`}>
      {props.children}
    </ul>
  </nav>
);

export default SideBarNav;
export { default as SideBarNavItem } from './Item';
