import * as React from 'react';
import { contextMenuNamespace as ns } from './constants';
import '../../../styles/elements/month-picker/context-menu-item.scss';

export const namespace = (): string => `${ns()}--list--item`;

export interface ContextMenuItemProps {
  children?: React.ReactNode;
  onMouseOver: React.EventHandler<any>;
  onClick: React.EventHandler<any>;
  highlighted: boolean;
  selected: boolean;
}

const ContextMenuItem: React.SFC<ContextMenuItemProps> = ({ children, onMouseOver, highlighted, onClick, selected }: ContextMenuItemProps): React.ReactElement<ContextMenuItemProps> => (
  <li className={namespace()}>
    <a
      className={`${namespace()}--button` + (highlighted ? ' highlighted' : '') + (selected ? ' selected' : '')}
      onMouseOver={onMouseOver}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      {children}
    </a>
  </li>
);

export default ContextMenuItem;
