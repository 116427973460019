import * as React from 'react';
import { default as AppHeader } from '../../../../../App/Header';
import {getEventRootUrl, EVENT} from "../../../../../../constants/urls";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router";

type ConnectedProps = RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string}>;

export default () => {
  const EventHeader: React.SFC<ConnectedProps> = (props: ConnectedProps): React.ReactElement<ConnectedProps> => {
    return <AppHeader
      showDrawer
      cart
      mobileCart
      refresh
      search
      title="Registration Options"
      back={`${getEventRootUrl(props)}/${EVENT.REGISTRATION}`}
    />;
  };
  return withRouter(EventHeader as any);
};
