import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import * as M from '../../constants/messages/createAccount';
import * as GENERIC_M from '../../constants/messages/generic';
import { checkUsername, checkEmail } from '../../constants/urls';
import { createSelector } from 'reselect';
import { districtsSelector } from './selectors';
import { ApplicationState } from '..';
import { filteredDistrictsCombiner } from '../CacheZero/selectors';
import { getUnitGenderCustomValidate, getUnitGenderOptionValues, isUnitGenderIDShown } from '../AddGroup/validationHelpers';
import { ApiValidator, SelectValidator, Validator } from "../../utils/validator/models";
import { FormDefinition as AddGroupDefinition, IValidator as AddGroupValidator } from '../AddGroup/validation';

export type IValidator = Pick<AddGroupValidator, "GroupTypeID" | "UnitTypeID" | "UnitGenderID" | "Unit" | "FirstName" | "LastName" | "Username" | "Password" | "ConfirmPassword" | "PhoneNumber" | "Organization" | "CouncilIDi" | "DistrictIDi"> & {
  RegistrationType: SelectValidator;
  ShowGroupType: Validator;
  Email: ApiValidator;
}

const filterCouncilSelector = (state: ApplicationState) => state.createAccount.ActiveForm.CouncilIDi;

export const makeFilteredDistrictSelector = () => {
  return createSelector(
    [districtsSelector, filterCouncilSelector],
    filteredDistrictsCombiner,
  );
};


// @todo: Clear state when you leave page? otherwise rollback is bigger and there's potiential for old data flicker

const filteredDistrictSelector = makeFilteredDistrictSelector();

const registrationTypeValueKey = (v) => v && (v.EventTypeID + '-' + v.FacilityLocationID);

  /**
   * get default registration type if there are any event typeid or
   * facility id in `afterLoginPath` deep link
   */
const getDefaultRegistrationType = function(rootState: ApplicationState) {
  const {app, createAccount: { RegisterForm }} = rootState;

  const afterLoginPath = app.afterLoginPath;

  let defaultRegistrationType;
  let facilityId;
  let eventTypeId;

  const FACILITY_TEST = /facilities\/[0-9]*\//;
  const FACILITY_TRIP_TEST = /facilitytrip\/[0-9]*\//;
  const EVENT_TYPES_TEST = /eventtypes\/[0-9]*\//;
  const EVENTS_TEST = /events\/[0-9]*\//;

  if (afterLoginPath && (FACILITY_TEST.test(afterLoginPath) || FACILITY_TRIP_TEST.test(afterLoginPath))) {
    const facilityMatch = afterLoginPath.match(FACILITY_TEST) || afterLoginPath.match(FACILITY_TRIP_TEST);
    const facilityIdMatch = facilityMatch ? facilityMatch[0].match(/\/[0-9]*/) : null;
    facilityId = facilityIdMatch ? facilityIdMatch[0].replace("/", "") : null;
  }
  if (afterLoginPath && (EVENT_TYPES_TEST.test(afterLoginPath) || EVENTS_TEST.test(afterLoginPath))) {
    const eventTypesMatch = afterLoginPath.match(EVENT_TYPES_TEST) || afterLoginPath.match(EVENTS_TEST);
    const eventTypesIdMatch = eventTypesMatch ? eventTypesMatch[0].match(/\/[0-9]*/) : null;
    eventTypeId = eventTypesIdMatch ? eventTypesIdMatch[0].replace("/", "") : null;
  }

  if (eventTypeId || facilityId) {
    defaultRegistrationType = RegisterForm ? RegisterForm.RegistrationTypeOptions.find(o => o && (o.EventTypeID === Number(eventTypeId) || o.FacilityLocationID === Number(facilityId))) : null;
  }

  return defaultRegistrationType;
};

export const FormDefinition: IValidator = {
  RegistrationType: {
    key: 'RegistrationType',
    isRequired: () => true,
    defaultValue: (rootState) => {
      const c = rootState.createAccount;
      if (!c.RegisterForm) return undefined;
      const defaultRegistrationType = registrationTypeValueKey(getDefaultRegistrationType(rootState));
      if (defaultRegistrationType) return defaultRegistrationType;
      if (c.RegisterForm.RegistrationTypeOptions.length === 2) {
        return registrationTypeValueKey(c.RegisterForm.RegistrationTypeOptions[1]);
      }
      return registrationTypeValueKey(c.RegisterForm.RegistrationTypeOptions[0]);
    },
    getValue: (rootState, valueKey) => {
      const ids = valueKey.split('-');
      if (!rootState.createAccount.RegisterForm) {
        return undefined;
      }
      if (valueKey === '-1--1') return rootState.createAccount.RegisterForm.RegistrationTypeOptions[0];
      const value = rootState.createAccount.RegisterForm.RegistrationTypeOptions.find((r) => {
        return r.EventTypeID === Number(ids[0]) && r.FacilityLocationID === Number(ids[1]);
      });
      return value;
    },
    options: {
      values: (rootState) => {
        if (!rootState.createAccount.RegisterForm) {
          return [];
        }
        const values = [...rootState.createAccount.RegisterForm.RegistrationTypeOptions];

        return values;
      },
      valueKey: registrationTypeValueKey,
      labelKey: 'Name'
    },
    localDependants: ['ShowGroupType', 'GroupTypeID', 'UnitTypeID', 'UnitGenderID', 'CouncilIDi', 'DistrictIDi', 'Unit'],
    validatejs: {
      RegistrationType: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        // NOTE: value is string here, so relying on customValidate below to validate it
        numericality: undefined,
      }
    },
    customValidate: (rootState) => {
      const rt = rootState.createAccount.ActiveForm.RegistrationType;
      if (rt && rt.EventTypeID === -1 && rt.FacilityLocationID === -1) {
        return `${GENERIC_M.REQUIRED}`;
      }
      return undefined;
    },
  },
  ShowGroupType: {
    key: 'ShowGroupType',
    defaultValue: (rootState) => {
      return !rootState.createAccount.ActiveForm.RegistrationType ? undefined : rootState.createAccount.ActiveForm.RegistrationType.ShowGroupType;
    },
  },
  GroupTypeID: {
    ...AddGroupDefinition.GroupTypeID,
    localDependants: ['UnitTypeID', 'Unit', 'CouncilIDi', 'DistrictIDi', 'UnitGenderID', 'Organization'],
    defaultValue: (rootState) => {
      if (!rootState.createAccount.ActiveForm.RegistrationType) return undefined;
      return rootState.createAccount.ActiveForm.RegistrationType.DefaultGroupTypeID;
    },
  },
  Organization: {
    ...AddGroupDefinition.Organization,
    isRequired: (rootState) => {
      return rootState.createAccount.ActiveForm.GroupTypeID === 4;
    },
  },
  UnitTypeID: {
    ...AddGroupDefinition.UnitTypeID,
    defaultValue: (rootState) => {
      return !rootState.createAccount.ActiveForm.RegistrationType ? undefined : rootState.createAccount.ActiveForm.RegistrationType.DefaultUnitTypeID;
    },
  },
  UnitGenderID: {
    ...AddGroupDefinition.UnitGenderID,
    options: {
      ...AddGroupDefinition.UnitGenderID.options,
      values: (rootState) => getUnitGenderOptionValues(
        rootState,
        () => rootState.createAccount.ActiveForm,
      ),
    },
    customValidate: (rootState) => getUnitGenderCustomValidate(() => rootState.createAccount.ActiveForm),
    isRequired: (rootState) => isUnitGenderIDShown(() => rootState.createAccount.ActiveForm),
    skip: (rootState) => !isUnitGenderIDShown(() => rootState.createAccount.ActiveForm),
    validatejs: {
      UnitGenderID: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        },
      }
    },
  },
  Unit: {
    ...AddGroupDefinition.Unit,
    // @todo: do something on the UI for this
    isRequired: (rootState) => {
      return rootState.createAccount.ActiveForm.GroupTypeID === 1;
    },
    validatejs: {
      Unit: {
        ...AddGroupDefinition.Unit.validatejs.Unit,
        numericality: {
          onlyInteger: true,
          notValid: '^' + GENERIC_M.MUST_BE_NUMBER,
        },
      }
    },
  },
  CouncilIDi: {
    ...AddGroupDefinition.CouncilIDi,
    localDependants: ['DistrictIDi'],
    skip: (rootState) => rootState.createAccount.ActiveForm.GroupTypeID !== 1,
  },
  DistrictIDi: {
    ...AddGroupDefinition.DistrictIDi,
    options: {
      ...AddGroupDefinition.DistrictIDi.options,
      values: (rootState) => {
        return filteredDistrictSelector(rootState);
      },
    },
    isRequired: (rootState) => {
      const ca = rootState.createAccount;
      const filteredDistricts = filteredDistrictSelector(rootState);
      if (ca.ActiveForm.GroupTypeID === 1 && rootState.cacheZero.options?.Districts && filteredDistricts.length > 1) {
        return true;
      }
      return false;
    },
    validatejs: {
      DistrictIDi: {
        presence: {message: '^' + GENERIC_M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 1
        }
      }
    }
  },
  FirstName: {
    ...AddGroupDefinition.FirstName,
  },
  LastName: {
    ...AddGroupDefinition.LastName,
  },
  PhoneNumber: {
    ...AddGroupDefinition.PhoneNumber,
    isRequired: () => true,
  },
  Email: {
    key: 'Email',
    apiCheck: {
      url: checkEmail,
      body: (rootState, val) => {
        return {"CampID": !rootState.session.SystemSettings ? undefined : rootState.session.SystemSettings.CampID, "Email": val};
      },
      determineError: (response) => {
        if (response.status === 200) {
          return false; // there is no error
        }
        return [M.EMAIL_TAKEN];
      },
      lastValue: ''
    },
    validatejs: {
      ...AddGroupDefinition.Email.validatejs,
    }
  },
  Username: {
    ...AddGroupDefinition.Username,
    apiCheck: {
      ...AddGroupDefinition.Username.apiCheck,
      url: checkUsername,
      body: (rootState, val) => {
        return {"CampID": !rootState.session.SystemSettings ? undefined : rootState.session.SystemSettings.CampID, "Username": val};
      },
    },
  },
  Password: {
    ...AddGroupDefinition.Password,
  },
  ConfirmPassword: {
    ...AddGroupDefinition.ConfirmPassword,
  }
};
