import * as Actions from './actions';
import {eventsSelector} from "../../CacheTwoEvents/index";
import {EventTypeEvent} from "../../../models/api/cacheTwoEvents";
import {makeNoFilterSelector, makeSimpleSelector} from '../../../utils';
import {createSelector} from "reselect";
import {ClearAllCache, ClearAllEndUserCacheButOptions} from "../../App/actions";
import {ClearCacheBelowOne} from "../../CacheOne/actions";
import { Reducer } from 'redux';
import { isActionType } from '../../../utils/StrongActions';

const filterSelector = state => state.events.eventType.filterString;

const filterFnc = (e: EventTypeEvent, f: string) => e.Name.toLowerCase().includes(f);

export const makeUpcomingSelector = makeSimpleSelector((e: EventTypeEvent) => e.IsEventUpcoming, filterFnc, eventsSelector, filterSelector);
export const makeCurrentSelector = makeSimpleSelector((e: EventTypeEvent) => e.HasCurrentRegistration, filterFnc, eventsSelector, filterSelector);
export const makePastSelector = makeSimpleSelector((e: EventTypeEvent) => !e.IsEventUpcoming, filterFnc, eventsSelector, filterSelector);

export const makeNoFilterUpcomingSelector = makeNoFilterSelector((e: EventTypeEvent) => e.IsEventUpcoming, eventsSelector);
export const makeNoFilterCurrentSelector = makeNoFilterSelector((e: EventTypeEvent) => e.HasCurrentRegistration, eventsSelector);
// export const makeNoFilterPastSelector = makeNoFilterSelector((e: EventTypeEvent) => !e.IsEventUpcoming, eventsSelector);
export const makeNoFilterPastSelector = () => createSelector(
  [eventsSelector],
  (items: Array<EventTypeEvent>) => {
    const filteredItems = items.filter((item: EventTypeEvent) => {
      return !item.IsEventUpcoming;
    });
    filteredItems.sort((a: EventTypeEvent, b: EventTypeEvent) => {
      if (a.HasGroupRegistration && !b.HasGroupRegistration) {
        return -1;
      } else if (!a.HasGroupRegistration && b.HasGroupRegistration) {
        return 1;
      }

      if (a.StartDateTime.isBefore(b.EndDateTime)) {
        return 1;
      } else if (b.StartDateTime.isBefore(a.EndDateTime)) {
        return -1;
      }
      // then sort by name
      if (a.Name.toLowerCase() === b.Name.toLowerCase()) {
        return 0;
      } else if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
        return -1;
      } else {
        return 1;
      }
    });
    return filteredItems;
  }
);

export interface EventsEventTypeState {
  showMapMobile?: boolean;
  showSidebar?: boolean;
  selectedTab: 'upcoming' | 'current' | 'past';
  filterString?: string;
}

const getInitialState = (): EventsEventTypeState => ({selectedTab: 'upcoming'});

const EventsEventType: Reducer<EventsEventTypeState> = (state: EventsEventTypeState, action) => {
  if (isActionType(action, Actions.EventTypeSelectTab)) {
    return {...state, selectedTab: action.selectedTab};
  } else if (isActionType(action, Actions.EventTypeToggleMapMobile)) {
    return {...state, showMapMobile: !state.showMapMobile};
  } else if (isActionType(action, Actions.EventTypeShowSidebar)) {
    return {...state, showSidebar: action.show};
  } else if (isActionType(action, Actions.EventTypeReset)) {
    return {
      ...getInitialState(),
      selectedTab: state.selectedTab
    };
  } else if (
    // this should always match whatever is in cache 2 events
    isActionType(action, ClearAllCache) || isActionType(action, ClearAllEndUserCacheButOptions) || isActionType(action, ClearCacheBelowOne)
  ) {
    return {
      ...getInitialState()
    };
  }
  return state || {...getInitialState()};
};

export default EventsEventType;
