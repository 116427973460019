import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../../styles/app/header/search/back.scss';
import { ActionButton } from '../../../Elements';
import { BackIcon } from '../../../Icons';

export const namespace = () => `${ns()}--back`;

interface Props {
  className?: string;
  onClick: () => any;
}

const Back: React.SFC<Props> = ({ onClick }: Props): React.ReactElement<Props> => (
  <div className={namespace()}>
    <ActionButton
      onClick={onClick}
      className={`${namespace()}--button`}
    >
      <BackIcon className={`${namespace()}--button--image`}/>
    </ActionButton>
  </div>
);

export default Back;
