import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { namespace as ns } from './constants';
import '../../../styles/elements/header-block/padding.scss';

export const namespace = (): string => `${ns()}--padding`;

export interface PaddingProps {
  className?: string;
  children?: React.ReactNode;
  mobilePadding?: boolean;
  padding?: string;
}

const Padding: React.SFC<PaddingProps> = (props: PaddingProps): React.ReactElement<PaddingProps> => {
  const { children, mobilePadding, padding } = props;

  let className: string = mergeClassNames(namespace(), props);
  if (!mobilePadding) className += ' no-mobile-padding';

  const style: React.CSSProperties = {};
  if (padding) style.padding = padding;

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

Padding.defaultProps = {
  mobilePadding: true
};

export default Padding;
