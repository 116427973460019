import { createSelector } from "reselect";

import type { ApplicationState } from "../../../..";
import { AdminFacility, AdminFacilityType } from "../../../../../models/api/adminFacilitiesCacheOne";
import { facilitiesSelector, facilityTypesSelector, sortFacilities } from "../../Home";


const filterSelector = (state: ApplicationState) => state.adminFacilityLocation.facilities.modals.multipleFacilities.filterString;

const selectedFacilityTypeSelector = (state: ApplicationState) => state.adminFacilityLocation.facilities.modals.multipleFacilities.ActiveForm.SelectedFacilityTypeID;

const facilityFilterMatch = (facility: AdminFacility, filter: string) => {
  const f = filter.toLowerCase();
  if (!f || f === '') return true;
  const nameMatches = facility.Name && facility.Name.toLowerCase().includes(f);
  return nameMatches;
};

export const makeFilteredFacilitiesSelector = () => {
  return createSelector(
    [facilitiesSelector, selectedFacilityTypeSelector, filterSelector, facilityTypesSelector],
  (facilities: AdminFacility[], selectedFacilityTypeID: number, filter: string, facilityTypes: AdminFacilityType[]) => {
    let result;
    if (selectedFacilityTypeID === 0) {
      result = facilities.filter((f) => {
        const facilityType = facilityTypes.find((ft) => ft.ID === f.FacilityTypeID);
        return !f.Inactive && facilityFilterMatch(f, filter) && (facilityType && !facilityType.Inactive);
      });
    } else {
      // dont need to check Inactive of facilityType here, because we have filtered Inactive FacilityType from list
      result = facilities.filter((f) => f.FacilityTypeID === selectedFacilityTypeID && !f.Inactive && facilityFilterMatch(f, filter));
    }
    
    return sortFacilities(result, true);
  });
};