import * as React from 'react';
import '../../../../styles/elements/admin-product/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton, Vignette} from '../../index';
import ContextMenuComponent from './ContextMenu';
import { OptionsIcon } from '../../../../components/Icons';
import { CardCategory } from '../../Card';
import { namespace as cardNS } from "../../Card/constants";
import { AdminEventsProduct } from '../../../../models/api/adminEventsCacheOne';
import { createInitials, getImagePath } from '../../../../utils';
import { withRouter } from 'react-router';
import { isCardClicked } from '../../../../utils/navHelper';
import { ADMIN_EVENTS_EDIT_PRODUCT_PATH, ADMIN_EVENTS_NEW_PRODUCT_PATH } from '../../../../routes';
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--admin-product--card';

export enum AdminProductCardType {
  PRODUCT_LIST='product-list',
} 

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin' | 'info' | 'mobile-full';
  type: AdminProductCardType;
  category: CardCategory;
  noBoxShadow?: boolean;
  selected?: boolean;
  product: AdminEventsProduct;
  onEdit?: (product: AdminEventsProduct) => void;
  onDelete?: (product: AdminEventsProduct) => void;
  onRestore?: (product: AdminEventsProduct) => void;
  disabled?: boolean;
  routes?: any;
}


class AdminProductCard extends React.Component<Props> {
  shouldAllowPropagation = (e) => {
    const { routes } = this.props;
    const route = routes[routes.length - 1];
    const clickedOnCard = isCardClicked(e, namespace);
    return ((route.path === ADMIN_EVENTS_EDIT_PRODUCT_PATH || route.path === ADMIN_EVENTS_NEW_PRODUCT_PATH) && !clickedOnCard);
  };
  onEditClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onEdit, disabled, product} = this.props;
    
    e.stopPropagation();
    !disabled && onEdit && onEdit(product);
  };
  onDeleteClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onDelete, product} = this.props;
    e.stopPropagation();
    !this.props.disabled && onDelete && onDelete(product);
  };
  onRestoreClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onRestore, product} = this.props;
    !this.props.disabled && e.stopPropagation();
    onRestore && onRestore(product);
  };
  onCardClick = (e) => {
    const {type, product, disabled} = this.props;

    if (product.Inactive || disabled) return;

    switch (type) {
      case AdminProductCardType.PRODUCT_LIST:
        this.onEditClick(e);
        break;
    }
  };

  renderInList = () => {
    const { product, routes, category, onRestore } = this.props;
    const route = routes[routes.length - 1];
    return (
      <div className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <div className={`${namespace()}--main--text--title`}>{product.Name}</div>
          <div className={`${namespace()}--main--text--subtitle`}>{`$${product.Amount.toFixed(2)}`}</div>
        </div>
        {!product.Inactive && <ActionButton
          icon={OptionsIcon}
          allowPropagation={route.path === ADMIN_EVENTS_EDIT_PRODUCT_PATH || route.path === ADMIN_EVENTS_NEW_PRODUCT_PATH}
          className={`${namespace()}--options ${cardNS()}--options ${category}`}
          contextMenu={<ContextMenuComponent
            onEdit={this.onEditClick}
            onDelete={this.onDeleteClick}
            />}
        />}
        {product.Inactive && onRestore && <Button className={`${cardNS()}--restore ${namespace()}--restore`} flat textColor="red" onClick={this.onRestoreClick}>RESTORE</Button>}
      </div>
    );
  };

  render() {
    const {category, noBoxShadow, template, product, selected, type, routes} = this.props;
    let className = attribute(namespace(), undefined, this.props);

    if (product.Inactive) className += ' deleted';
    className += ` ${type}`;

    return (
      <Card
        noBoxShadow={noBoxShadow}
        className={className}
        component={<section/>}
        template={template || 'mobile-no-margin'}
        category={category}
        padding="none"
        selected={selected}
      >
        <div id={generateDOMId(`tentaroo-admin-product-${product.IDi}`)} className={`${namespace()}--wrapper`} onClick={this.onCardClick}>
          <Vignette
            className={`${namespace()}--${type}-avatar`}
            width={68}
            height={68}
            name={createInitials(product.Name)}
            color={`#${product.Color}`}
            image={product.FeaturedImage && product.FeaturedImage.ImageOriginal ? getImagePath(product.FeaturedImage) : undefined}
          />
          {type === AdminProductCardType.PRODUCT_LIST ? this.renderInList() : null}
        </div>
      </Card>
    );
  }
}

export default withRouter(AdminProductCard as any) as any;