import { typeName, Action } from "../../utils/StrongActions";
import {ActionCreator} from "../index";
import {CLEAR_CACHE_PREFIX, createRequestActions} from '../Validation/actionCreator';

export const SUFFIX = '__CACHE_TWO_FACILITIES';
export const GetFacilityLocation = createRequestActions(SUFFIX);
export const GetFacilityAvailabilities = createRequestActions('__CACHE_TWO_AVAILABILITIES_FACILITIES');

@typeName(`${CLEAR_CACHE_PREFIX}_CACHE_BELOW_TWO_FACILITIES`)
export class ClearCacheBelowTwoFacilities extends Action {}

@typeName(`SELECT_LOCATION${SUFFIX}`)
export class SelectLocation extends Action {
  constructor(public locationID: number) { super(); }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  getFacilityLocation: (
    GroupIDi: number,
    GroupTS: number,
    FacilityLocationID: number,
    GetOptions: boolean,
    GetGroupData: boolean,
    FacilityTypeID: number,
    StartDate: string
  ): ActionCreator => dispatch => {
    dispatch(GetFacilityLocation.request(
      {GroupIDi, GroupTS, FacilityLocationID, GetOptions, GetGroupData, FacilityTypeID, StartDate}
    ));
  },
  getFacilityAvailabilities: (
    GroupIDi: number,
    GroupTS: number,
    FacilityLocationID: number,
    GetOptions: boolean,
    GetGroupData: boolean,
    FacilityTypeID: number,
    StartDate: string
  ): ActionCreator => dispatch => {
    dispatch(GetFacilityAvailabilities.request(
      {GroupIDi, GroupTS, FacilityLocationID, GetOptions, GetGroupData, FacilityTypeID, StartDate}
    ));
  },
  clearCacheBelowTwoFacilities: (): ActionCreator => dispatch => dispatch(new ClearCacheBelowTwoFacilities()),
  selectLocation: (locationID: number): ActionCreator => dispatch => dispatch(new SelectLocation(locationID))
};

