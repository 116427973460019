import * as React from 'react';
import { ContextMenu, ContextMenuItem } from '..';
import { generateDOMId } from '../../../utils/cypressHelper';
import { Props as ContextMenuProps } from '../ContextMenu/index';

interface Props {
  onMoveUp?: (e) => void;
  onMoveDown?: (e) => void;
  onEdit?: (e) => void;
  onDelete?: (e) => void;
  onRestore?: (e) => void;
}

const ContextMenuComponent: React.SFC<ContextMenuProps & Props> = (props: ContextMenuProps & Props): React.ReactElement<ContextMenuProps & Props> => (
  <ContextMenu
    {...props}
    consistentInMobile
    title=""
  >
    {props.onMoveUp ? <ContextMenuItem admin onClick={props.onMoveUp}>Move Up</ContextMenuItem> : null}
    {props.onMoveDown ? <ContextMenuItem admin onClick={props.onMoveDown}>Move Down</ContextMenuItem> : null}
    {props.onEdit ? <ContextMenuItem id={generateDOMId("edit-image")} admin onClick={props.onEdit}>Edit</ContextMenuItem> : null}
    {props.onDelete ? <ContextMenuItem admin onClick={props.onDelete}>Delete</ContextMenuItem> : null}
    {props.onRestore ? <ContextMenuItem admin onClick={props.onRestore}>Restore</ContextMenuItem> : null}
  </ContextMenu>
);

export default ContextMenuComponent;
