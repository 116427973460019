import * as React from 'react';

import Footer from "../Footer";
import FooterActions from "../Footer/Actions";

import { namespace as ns } from './constants';
import '../../../styles/elements/form/actions.scss';

export const namespace = (): string => `${ns()}--actions`;

export interface ActionsProps {
  children?: React.ReactNode;
  loading?: boolean;
  floating?: boolean;
  leftGutter?: boolean;
  leftGutterSize?: number;
  handleCompact?: boolean;
  wide?: boolean;
  handleLeftContent?: boolean;  // set to true when use footer along with MainContant that has `leftContent`
}

const Actions: React.SFC<ActionsProps> = ({ children, loading, wide, floating, leftGutter, leftGutterSize, handleCompact, handleLeftContent }: ActionsProps): React.ReactElement<ActionsProps> => (
  <div className={namespace() + (loading ? ' loading' : '')}>
    {<Footer wide={wide} handleLeftContent={handleLeftContent} leftGutter={leftGutter} leftGutterSize={leftGutterSize} handleCompact={handleCompact} tablet desktop><FooterActions>{children}</FooterActions></Footer> }
    <Footer wide={wide} handleLeftContent={handleLeftContent} leftGutter={leftGutter} leftGutterSize={leftGutterSize} handleCompact={handleCompact} mobile><FooterActions>{children}</FooterActions></Footer>
  </div>
);

export default Actions;
