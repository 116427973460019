import {
    CartOrder,
    CartOrderItem,
    EventType,
    FacilityLocation,
    GroupBillingAddress,
    HomepageMyEvent,
    HomepageMyTrip
  } from "../../models/api/cacheOne";
  import {APISuccess, WithRootState} from "../Validation/actionCreator";
  import {convertToMoment} from '../../utils';
  import {ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction} from "../App/actions";
  import {API_SUC} from "../../constants/request";
  import { AnyAction, Reducer } from "redux";
  import { isActionType } from "../../utils/StrongActions";
  import { RestoreGroupActions } from '../Admin/Modals/Accounts/actions';
  
  export interface CacheOneState {
    HomepageMyEvents?: Array<HomepageMyEvent> | null;
    HomepageMyTrips?: Array<HomepageMyTrip> | null;
    FacilityLocations?: Array<FacilityLocation> | null;
    EventEventTypes?: Array<EventType> | null;
    CartOrder?: CartOrder | null;
    CartOrderItems?: Array<CartOrderItem> | null;
    GroupBillingAddress?: GroupBillingAddress | null;
  }
  
  const setCacheOneData = (data: {
    HomepageMyEvents: Array<HomepageMyEvent> | null;
    HomepageMyTrips: Array<HomepageMyTrip> | null;
    FacilityLocations: Array<FacilityLocation> | null;
    EventEventTypes?: Array<EventType> | null;
    CartOrder?: CartOrder | null;
    CartOrderItems?: Array<CartOrderItem> | null;
    GroupBillingAddress?: GroupBillingAddress | null;
  }): CacheOneState => {
    return {
      HomepageMyEvents: convertToMoment(data.HomepageMyEvents, ['StartDateTime', 'EndDateTime']),
      HomepageMyTrips: convertToMoment(data.HomepageMyTrips, ['StartDateTime', 'EndDateTime']),
      FacilityLocations: data.FacilityLocations,
      EventEventTypes: data.EventEventTypes,
      CartOrder: data.CartOrder,
      CartOrderItems: data.CartOrderItems,
      GroupBillingAddress: data.GroupBillingAddress
    };
  };
  
  const handleResponse = (innerResponse, state) => {
    if (innerResponse.HomepageMyTrips !== undefined) {
      const newState: CacheOneState = setCacheOneData(innerResponse);
      return newState;
    }
    return state;
  };
  
  const CacheOne: Reducer<CacheOneState> = (state, act: WithRootState<AnyAction>) => {
    if (act.type.startsWith(API_SUC)) {
      const action = <WithRootState<APISuccess>> act;
      // RestoreGroupActions can happen outside of cacheOne, if so, skip update cacheOne
      if (!action.response || !action.response.response) {
        return state;
      }
      const rootState = act.rootState;
  
      // If we are restoring a different group OR there is not group loaded yet, don't load it into cache
      if (act.type === RestoreGroupActions.successType &&
        rootState.cacheZero.options &&
        action.response && (
          !rootState.cacheZero.options.Group ||
          action.response.response.Group.IDi !== rootState.cacheZero.options.Group.IDi)
      ) {
          return state;
      }
      let newState = {...state};
      newState = handleResponse(action.response.response, newState);
      return newState;
    } else if (isActionType(act, SetCacheAction)) {
      return handleResponse(act.response.xhr.response, state);
    } else if (isActionType(act, ClearAllCache) || isActionType(act, ClearAllEndUserCacheButOptions)) {
      return {};
    }
    return state || {};
  };
  
  export default CacheOne;
  