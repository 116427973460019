import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Suspense } from 'react';
import {browserHistory, Router} from 'react-router';
import {Provider} from 'react-redux';
import {syncHistoryWithStore} from 'react-router-redux';
import createRoutes from './routes';
import createStore from './config/createStore';
import {ApplicationState, getApplicationInitialState}  from './store';
import {
  HideDrawer
} from './store/App/actions';
import {isAdminModulePage, URLS} from './constants/urls';
import "./wdyr"; 
import {AppContainer} from 'react-hot-loader'; // AppContainer is a necessary wrapper component for HMR
import {captureException, withScope} from '@sentry/browser';
import { TENTAROO_INIT } from '@tentaroo/shared';

// Configure global vars on loading app
const initialState = (window as any).initialReduxState as ApplicationState;

if ((window as any).Cypress) {
  (window as any).Cypress.on('uncaught:exception', (err, runnable) => {
    // returning false here prevents Cypress from
    // failing the test
    return false;
  });
}

/**
 * Setup `sendCustomSentryError` for debugging purpose, and this can only
 * be called from localhost
 * 
 * From localhost, you have to setup ngrok, otherwise CORS will block localhost from sending any event to Sentry
 * 
 * The implementation of `sendCustomSentryError` is the same as `captureTentarooError` just so
 * we can verify that both the Error and payload is correctly sent to Sentry.
 */
if (process.env.NODE_ENV === "development") {
  (window as any).sendCustomSentryError = (text?: string, payload?: string) => {
    const error = new Error(text || "Test Error from localhost");
    withScope((scope) => {
      if (payload) {
        scope.setExtra("payload", payload);
        captureException(error);
      } else {
        captureException(error);
      }
    });
  };
}

// NOTE: We also need to configure webpack's dynamic public path here.
// see https://webpack.js.org/guides/public-path/#on-the-fly
// `__webpack_public_path__` should be a free var - not under any scope like global
__webpack_public_path__ = process.env.PUBLIC_PATH!;

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual';
}
// @todo fallback indicator for Suspense component
const render = async () => {
  ReactDOM.render(
    <AppContainer>
      <Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
          <Router
            history={routerHistory}
            children={routes}
            onUpdate={function () {
              // QUESTION & TODO: What is this code? when upgrade react-router, re-visit this.
              // This code is saying that when visiting end user pages (except orders), always scroll
              // to top
              const pathname = this.state.location.pathname;
              if (
                !pathname.startsWith(URLS.ORDER_HISTORY) &&
                !isAdminModulePage(pathname)
              ){
                if (store.getState().app.apiSaving === 0) {
                  window.scrollTo(0, 0);
                }
              }
              store.dispatch(new HideDrawer());
            }}
          />
        </Provider>
      </Suspense>
    </AppContainer>,
    document.getElementById('react-app')
  );
};

export let store;
let routerHistory;
let routes;

const main = async () => {
  store = await createStore(getApplicationInitialState(initialState));
  store.dispatch({
    type: TENTAROO_INIT,
  });
  routerHistory = syncHistoryWithStore(browserHistory, store);
  
  window.store = store;

  routes = createRoutes();
  render();
};

main();
// Allow Hot Module Reloading
declare var module: any;
if (module.hot) {
  module.hot.accept('./routes', () => {
    render();
  });
}
