import * as M from '../../../../../constants/messages/generic';
import { Validator } from '../../../../../utils/validator/models';

export interface IValidator {
  ShowEventsFromPastYear: Validator;
  SelectedEventID: Validator;
  IsAllRegisteredGroups: Validator;
  SelectedGroups: Validator;
}

export const FormDefinition: IValidator = {
  ShowEventsFromPastYear: {
    key: 'ShowEventsFromPastYear',
  },
  SelectedEventID: {
    key: 'SelectedEventID',
  },
  IsAllRegisteredGroups: {
    key: 'IsAllRegisteredGroups',
    defaultValue: () => true,
  },
  SelectedGroups: {
    key: 'SelectedGroups',
    skip: (rootState) => {
      return !!rootState.adminEvents.messageCenter.modals.sendMessage.ActiveForm.IsAllRegisteredGroups;
    },
    isRequired: (rootState) => {
      return !rootState.adminEvents.messageCenter.modals.sendMessage.ActiveForm.IsAllRegisteredGroups;
    },
    customValidate: (rootState) => {
      const modal = rootState.adminEvents.messageCenter.modals.sendMessage;
      
      if (!modal.ActiveForm.IsAllRegisteredGroups) {
        if (!modal.ActiveForm.SelectedGroups || modal.ActiveForm.SelectedGroups.length === 0) {
          return M.REQUIRED;
        }
      }
    },
    validatejs: {
      SelectedGroups: {
        presence: {message: '^' + M.REQUIRED},
      },
    },
  }
};