import {PreviousOrder, PreviousOrderItem} from "../../../../models/api/cacheTwoPrevOrders";
import cloneDeep from 'lodash.cloneDeep';

import type {ApplicationState} from "../../..";
import {RefundProduct} from "../../../../components/Elements/Cart/Item/constants";
import {allowInactive, enableAmountChange, fpr, isRefund, getAmountKey, getAppliedCreditKey, getTotalAmountKey, getInactiveKey} from "./uiHelpers";
import {captureTentarooError, toNumber} from "../../../../utils/dataHelper";

export const mergeItems = (rootState: ApplicationState, ActiveForm) => {
  const PreviousOrderItems = cloneDeep(rootState.cacheThreePrevOrders.PreviousOrderItems) as Array<PreviousOrderItem> | null;
  if (PreviousOrderItems) {
    for (let i = 0; i < PreviousOrderItems.length; i++) {
      const item = PreviousOrderItems[i];
      PreviousOrderItems[i] = {
        ...PreviousOrderItems[i],
        Amount: ActiveForm[getAmountKey(item)],
        AppliedCredit: ActiveForm[getAppliedCreditKey(item)],
        TotalAmount: ActiveForm[getTotalAmountKey(item)],
        Inactive: ActiveForm[getInactiveKey(item)]
      };
    }
  }
  return PreviousOrderItems;
};

export const mergeOrder = (rootState: ApplicationState, ActiveForm): PreviousOrder => {
  return {
    ...cloneDeep(rootState.cacheThreePrevOrders.PreviousOrder),
    Amount: ActiveForm.Amount,
    AppliedCredit: ActiveForm.AppliedCredit,
    NotApplied: ActiveForm.NotApplied
  };
};

export const findC3OrderItem = (rootState: ApplicationState, OrderID: number, index?: number): PreviousOrderItem | undefined => {
  const c3Items = rootState.cacheThreePrevOrders.PreviousOrderItems;
  let c3Item: PreviousOrderItem | undefined;

  if (c3Items) {
    if (index && OrderID === c3Items[index].ID) {
      c3Item = c3Items[index];
    } else {
      c3Item = c3Items.find(c3 => c3.ID === OrderID);
    }
  }
  if (!c3Item) {
    captureTentarooError(new Error(`Unable to find previous order item with id ${OrderID} while updateOrderTotal.`));
    return c3Item;
  }
  return c3Item;
};

// From: https://github.com/mgroger/Camps/blob/master/org/echockotee/camps/model/ModelLocator.as#L1845
export const updateOrderTotal = (rootState: ApplicationState, Order: PreviousOrder, Items: Array<PreviousOrderItem> | null): void => {
  let TotalAmount: number = 0;
  let TotalAppliedCreditItems: number = 0;
  let itemAmount: number;
  let refundOrder: boolean = isRefund(rootState, Order.PaymentTypeID);

  const OrderAmount = toNumber(Order.Amount);
  if (Items) {
    for (let i = 0; i < Items.length; i++) {
      const item: PreviousOrderItem = Items[i];

      itemAmount = toNumber(item.TotalAmount);

      // Only run these updates if amount for item is valid.
      if (!item.Inactive && isNaN(itemAmount)) return;

      // Sum applied amounts for all active items except refund products (if this is a refund order). Refund products are counted with RefundAmount.
      if (!item.Inactive && (!refundOrder || item.Item_ProductIDi !== RefundProduct)) {
        //Add to total amount applied to items.
        TotalAmount += itemAmount;
      }

      // Adjust refund product item if necessary. Only adjusting if current PaymentAmount is valid for a refund (<= 0).
      // Refund products can't be deleted (in a refund order) so not checking item.Inactive here.
      if (item.Item_ProductIDi === RefundProduct && refundOrder) {
        const absAmount = Math.abs(OrderAmount);
        item.Amount = absAmount;
        item.TotalAmount = item.Amount;
        itemAmount = Math.abs(item.TotalAmount);
      }

      // Reduce/Increase AppliedCredit as necessary on each item.
      // Don't allow more credit to be applied on item than the amount that was previously saved. No longer letting credit move from one order to another, or one item to another within an order.
      if (!item.Inactive && itemAmount > 0) {
        const c3Item = findC3OrderItem(rootState, item.ID, i);
        // We have a null check here just for type checking, but if `c3Item` is undefined, we already capture it
        // as an error in `findC3OrderItem` function
        if (c3Item) {
          item.AppliedCredit = Math.min(itemAmount, c3Item.AppliedCredit);
          TotalAppliedCreditItems += item.AppliedCredit;
        }
      } else {
        item.AppliedCredit = 0;
      }
    }
  }


  // Change Order.RefundAmount if necessary
  if (refundOrder) {
    Order.RefundAmount = -1 * OrderAmount;
  }

  let RefundAmount = toNumber(Order.RefundAmount);
  RefundAmount = isNaN(RefundAmount) ? 0 : RefundAmount;

  // Find payment amount, not including NotApplied.
  // If this is a refund order, TotalAmount = $0 since credit will be used to pay for the refund.
  let removeChange: number;
  Order.AppliedCredit = TotalAppliedCreditItems;
  TotalAmount = fpr(TotalAmount + (Order.CCFee ? Order.CCFee : 0) + (Order.RefundAmount ? Order.RefundAmount : 0) - TotalAppliedCreditItems);

  // New payment amount = Item Total Amount + CCFee - AppliedCredit + NotApplied
  // Update amount not applied. This will be negative here if the payment amount and applied credit is not enough to cover the cost of the order items and any refund amount.
  Order.NotApplied = fpr(OrderAmount - TotalAmount + RefundAmount);

  // Remove Overlap - Reduce applied credit if appropriate, if both applied credit > 0 and NotApplied > 0.
  if (Order.NotApplied > 0) {
    removeChange = fpr(Math.min(Order.NotApplied, TotalAppliedCreditItems));
    TotalAppliedCreditItems -= removeChange;
    Order.AppliedCredit = TotalAppliedCreditItems;

    // Update total amount, NotApplied
    TotalAmount += removeChange;
    Order.NotApplied = fpr(OrderAmount - TotalAmount + RefundAmount);
  }

  // Calculate new payment total
  // Refund amount was temporarily added to TotalAmount to calculate credit. Subtracting now will make order negative by the amount of the refund.
  Order.Amount = fpr(TotalAmount - RefundAmount + Order.NotApplied);
};

export const deleteOrder = (rootState: ApplicationState, PreviousOrder: PreviousOrder, PreviousOrderItems: Array<PreviousOrderItem> | null): string => {
  let itemRow: PreviousOrderItem;
  let i: number;

  //Remove all items where item removal is allowed (all except refund product in a refund order).
  if (PreviousOrderItems) {
    for (i = 0; i < PreviousOrderItems.length; i++) {
      itemRow = PreviousOrderItems[i];
      if (allowInactive(itemRow.ItemType, itemRow.Item_ProductIDi, PreviousOrder.PaymentTypeID, rootState)) {
        itemRow.Inactive = true;
      }
    }

  }
  updateOrderTotal(rootState, PreviousOrder, PreviousOrderItems);
  // model.GroupOrderPrevious.IsDirty = true;

  /* Only enable payment amount change if not a credit card payment.
	If eCheck doesn't go through, user will need to remove payment so allow change. */
  if (enableAmountChange(PreviousOrder.PaymentTypeID)) {
    PreviousOrder.Amount = 0;
    updateOrderTotal(rootState, PreviousOrder, PreviousOrderItems);
  }

  let message: string = 'All order items have been removed. Any event registrations, facility reservations or product orders will be unaffected, this will just increase their balance.\n\n';

  if (enableAmountChange(PreviousOrder.PaymentTypeID)) {
    message = message +"The payment amount has been set to $0.00.\n\n";
  } else {
    message = message + (PreviousOrder.PaymentTypeID === 2 ?
      "Credit card payments cannot be deleted. Please use a refund or convenience fee instead in order to remove the credit from this payment." :
      "Credit card refunds cannot be deleted.") + "\n\n";
  }
  message = message +"Are you sure you want to continue saving this change?";

  return message;
};
