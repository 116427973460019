import * as React from 'react';
import '../../../styles/elements/text/index.scss';
import { default as attributes, MarginProps } from '../../../styles';

export const namespace = (): string => 'elements--text';

export interface TextProps extends MarginProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  size?: number;
  mobileSize?: number;
  line?: number;
  color?: 'red' | 'gray' | 'white' | 'green' | 'orange'| 'dark-gray' | 'black-87';
  weight?: 'thin' | 'light' | 'regular' | 'medium' | 'bold' | 'black';
  inline?: boolean;
  uppercase?: boolean;
  wrap?: boolean;
  preWrap?: boolean;
  additionalInfo?: boolean;
  component?: React.ReactElement<any> | string;
  align?: 'left' | 'center' | 'right';
  icon?: React.ReactNode;
  info?: React.ReactNode;
  renderMobile?: boolean; // render as if it was mobile even if it is not mobile
}

const Text: React.SFC<TextProps> = ({
  style: inheritedStyle, component, align, line, uppercase, mobileSize, size, weight, wrap, icon, info, color,
  className, inline, children, additionalInfo, renderMobile, preWrap, ...props
}: TextProps): React.ReactElement<TextProps> => {
  className = attributes(namespace(), renderMobile, className, props);

  if (renderMobile && mobileSize && size) className += ` size-${mobileSize}`;
  else if (size) className += ` size-${size}`;

  if (mobileSize) className += ` mobile-size-${mobileSize}`;
  if (color) className += ` ${color}`;
  if (additionalInfo) className += ' additional-info';
  if (weight) className += ` ${weight}`;
  if (uppercase) className += ' uppercase';
  if (align) className += ` align-${align}`;
  if (!wrap) className += ' no-wrap';
  if (preWrap) className += ' pre-wrap';
  if (info) className += ' has-info';

  const style: React.CSSProperties = { ...inheritedStyle };
  if (line) style.lineHeight = `${line}px`;

  if (icon && React.isValidElement(icon)) {
    children = [<div className={`${namespace()}--icon`} key={1}>{icon}</div>, <span key={2}>{children}</span>];
  } else if (icon) {
    const Icon = icon as React.ReactType;
    children = [<div className={`${namespace()}--icon`} key={1}><Icon/></div>, <span key={2}>{children}</span>];
  }

  if (info) {
    const infoElement: React.ReactElement<any> = info as any;
    children = [React.cloneElement(infoElement, { ...infoElement.props, key: 1 }), <span key={2}>{children}</span>];
  }

  if (component) {
    if (typeof component === 'string') {
      return React.createElement(component, {
        className: className,
        style: style,
      }, children);
    } else {
      return React.cloneElement(component, {
        ...component.props,
        className: className,
        style: style,
      }, children);
    }
  } else if (inline) {
    return (
      <span className={className} style={style}>{children}</span>
    );
  }
  return typeof children === 'string' ? (
    <p className={className} style={style}>{children}</p>
  ) : (
    <div className={className} style={style}>{children}</div>
  );
};

Text.defaultProps = {
  wrap: true,
  weight: 'regular'
};

export default Text;
