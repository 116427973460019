import * as React from 'react';
import '../../../../styles/elements/admin-event-type/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton} from '../../index';
import ContextMenuComponent from './ContextMenu';
import { OptionsIcon } from '../../../../components/Icons';
import { CardCategory } from '../../Card';
import { namespace as cardNS } from "../../Card/constants";
import { EventType } from '../../../../models/api/options';
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--admin-event-type--card';

export enum AdminEventTypeCardType {
  SELECT_EVENT_TYPE_MODAL='select-event-type-modal',
} 

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin' | 'info' | 'mobile-full';
  type: AdminEventTypeCardType;
  locationName?: string;
  category: CardCategory;
  noBoxShadow?: boolean;
  selected?: boolean;
  eventType: EventType;
  onEdit?: (eventType: EventType) => void;
  onDelete?: (eventType: EventType) => void;
  onRestore?: (eventType: EventType) => void;
  onOpen?: (eventType: EventType) => void;
  onDuplicate?: (eventType: EventType) => void;
  disabled?: boolean;
  routes?: any;
}


class AdminEventTypeCard extends React.Component<Props> {
  onEditClick = (e) => {
    const {onEdit, disabled, eventType} = this.props;
    
    e.stopPropagation();
    !disabled && onEdit && onEdit(eventType);
  };
  onDeleteClick = (e) => {
    const {onDelete, eventType} = this.props;
    e.stopPropagation();
    !this.props.disabled && onDelete && onDelete(eventType);
  };
  onRestoreClick = (e) => {
    const {onRestore, eventType} = this.props;
    !this.props.disabled && e.stopPropagation();
    onRestore && onRestore(eventType);
  };

  onDuplicateClick = (e) => {
    const {onDuplicate, disabled, eventType} = this.props;
    e.stopPropagation();
    !disabled && onDuplicate && onDuplicate(eventType);
  };

  onOpenClick = (e) => {
    const {disabled, eventType, onOpen} = this.props;
    !disabled && onOpen && onOpen(eventType);
  };
  onCardClick = (e) => {
    const {type, eventType, disabled} = this.props;

    if (eventType.Inactive || disabled) return;

    switch (type) {
      case AdminEventTypeCardType.SELECT_EVENT_TYPE_MODAL:
        this.onOpenClick(e);
        break;
    }
  };

  renderInSelectModal = () => {
    const { eventType, locationName, category, onRestore } = this.props;
    return (
      <div className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <div className={`${namespace()}--main--text--title`}>{eventType.Name}</div>
          <div className={`${namespace()}--main--text--subtitle`}>
            <div className={`${namespace()}--main--text--subtitle--text`}>{locationName}</div>
            {eventType.NumCurrentOrFutureEvents > 0 ? <div className={`${namespace()}--main--text--subtitle--text`}>{`${eventType.NumCurrentOrFutureEvents} Upcoming Event${eventType.NumCurrentOrFutureEvents > 1 ? 's' : ''}`}</div> : null}
          </div>
        </div>
        {!eventType.Inactive && <ActionButton
          id={generateDOMId(`admin-eventtype-${eventType.ID}-option-btn`)}
          icon={OptionsIcon}
          className={`${namespace()}--options ${cardNS()}--options ${category}`}
          contextMenu={<ContextMenuComponent
            eventTypeId={eventType.ID}
            onOpenEventType={this.onOpenClick}
            onEdit={this.onEditClick}
            onDuplicate={this.onDuplicateClick}
            onDelete={this.onDeleteClick}
            />}
        />}
        {eventType.Inactive && onRestore && <Button className={`${cardNS()}--restore ${namespace()}--restore`} flat textColor="red" onClick={this.onRestoreClick}>RESTORE</Button>}
      </div>
    );
  };

  render() {
    const {category, noBoxShadow, template, eventType, type, routes} = this.props;
    let className = attribute(namespace(), undefined, this.props);

    if (eventType.Inactive) className += ' deleted';
    className += ` ${type}`;

    return (
      <Card
        noBoxShadow={noBoxShadow}
        className={className}
        component={<section/>}
        template={template || 'mobile-no-margin'}
        category={category}
        padding="none"
      >
        <div id={generateDOMId(`tentaroo-admin-event-card-${eventType.ID}`)} className={`${namespace()}--wrapper`} onClick={this.onCardClick}>
          {type === AdminEventTypeCardType.SELECT_EVENT_TYPE_MODAL ? this.renderInSelectModal() : null}
        </div>
      </Card>
    );
  }
}

export default AdminEventTypeCard;