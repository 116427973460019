import * as React from 'react';
import { findDOMNode } from 'react-dom';
import tether from 'react-tether2';
import moment from 'moment';

import Shadow from "../Shadow";

import { contextMenuNamespace as namespace } from './constants';
import '../../../styles/elements/month-picker/context-menu.scss';
import { shallowEqual, isVisible } from '../../../utils';
import ContextMenuItem from './ContextMenuItem';
import {Nullable} from '../../../utils/dataHelper';



interface Props {
  value: moment.Moment;
  target: () => Element;
  onClose: () => void;
  onChange: (value: moment.Moment | null) => void;
}

interface State {
  highlighted: number | null;
  selected: number | null;
}

const KEY_MAP = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
  ESCAPE: 27
};

@tether(
  (ownProps) => ({
    target: ownProps.target(),
    attachment: 'top center',
    targetAttachment: 'bottom center',
    constraints: [
      {
        to: 'scrollParent',
        attachment: 'together'
      }
    ]
  }),
  state => state,
  {
    style: { zIndex: 999999 },
    className: 'elements--month-picker--context-menu' // doesnt work when using namespace
  }
)
class ContextMenu extends React.Component<Props, State> {
  public props: Props;
  public state: State = {
    highlighted: null,
    selected: null
  };

  private MAX = 24;

  private keys: Array<number> = [];

  private disableMouseOver: boolean = false;

  constructor(props) {
    super(props);
    for (let prop in KEY_MAP) {
      if (KEY_MAP.hasOwnProperty(prop)) {
        this.keys.push(KEY_MAP[prop]);
      }
    }
  }

  private _index: number | null = null;
  public get index(): number | null {
    if (this._index !== null) return this._index;
    let finalIndex: number | null = null;
    Array.apply(null, Array(this.MAX)).forEach((value, index: number) => {
      if (moment().startOf('month').add(index, 'month').isSame(this.props.value, 'month')) finalIndex = index;
    });
    return this._index = finalIndex;
  }

  public get date(): moment.Moment | null {
    if (this.index !== null) return moment().startOf('month').add(this.index, 'month');
    return null;
  }

  public componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
    if (this.state.highlighted === null && this.index) {
      this.scrollTo(this.index);
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return !shallowEqual(this.state, nextState);
  }

  public handleKeyPress = (event: KeyboardEvent) => {
    const element = findDOMNode(this) as Nullable<HTMLElement>;
    if (element && isVisible(element)) {
      if (this.keys.indexOf(event.keyCode) !== -1) {
        event.preventDefault();
        switch (event.keyCode) {
          case KEY_MAP.DOWN:
            this.increaseHighlight();
            break;
          case KEY_MAP.UP:
            this.decreaseHighlight();
            break;
          case KEY_MAP.ENTER:
            if (this.state.highlighted) this.select(this.state.highlighted);
            break;
          case KEY_MAP.ESCAPE:
            this.props.onClose();
            break;
        }
      }
    }
  };

  public increaseHighlight = () => {
    const currentIndex = this.state.highlighted === null ? this.index : this.state.highlighted;
    if (currentIndex !== null) {
      if ((currentIndex + 1) >= this.MAX) this.highlight(0, true);
      else this.highlight(currentIndex + 1, true);
    }
  };

  public decreaseHighlight = () => {
    const currentIndex = this.state.highlighted === null ? this.index : this.state.highlighted;
    if (currentIndex !== null) {
      if ((currentIndex - 1) < 0) this.highlight(this.MAX - 1, true);
      else this.highlight(currentIndex - 1, true);
    }
  };

  public highlight = (index: number, scrollTo: boolean = false) => {
    this.setState({ highlighted: index });
    if (scrollTo) this.scrollTo(index);
  };

  public scrollTo = (index: number) => {
    this.disableMouseOver = true;
    const ELEMENTS_VISIBLE = 3;
    const ELEMENT_HEIGHT = 40;
    const element = findDOMNode(this) as Nullable<Element>;
    if (!element) return;
  
    if ((index - ELEMENTS_VISIBLE + 1) * ELEMENT_HEIGHT > element.scrollTop) {
      element.scrollTop = (index - ELEMENTS_VISIBLE + 1) * ELEMENT_HEIGHT;
    } else if (index * ELEMENT_HEIGHT < element.scrollTop) {
      element.scrollTop = index * ELEMENT_HEIGHT;
    }
    setTimeout(() => this.disableMouseOver = false, 100);
  };

  public select = (index: number) => {
    let finalIndex: number | null = index !== null ? index : this.index;
    let finalDate: moment.Moment | null = index !== null ? moment().startOf('month').add(index, 'month') : this.date;
    this.setState({ selected: finalIndex });
    setTimeout(() => this.props.onChange(finalDate), 100);
    setTimeout(() => this.props.onClose(), 200);
  };

  public render() {
    const { selected, highlighted } = this.state;
    return (
      <Shadow shadow={3} className={`${namespace()}--container`}>
        <ul className={`${namespace()}--container--list`}>
          {Array.apply(null, Array(this.MAX)).map((value, index: number) => {
            const date = moment().startOf('month').add(index, 'month');
            return (
              <ContextMenuItem
                highlighted={highlighted !== null ? highlighted === index : date.isSame(this.props.value, 'month')}
                selected={selected === index}
                key={index}
                onMouseOver={() => {
                  if (!this.disableMouseOver) this.highlight(index);
                }}
                onClick={() => {
                  this.select(index);
                }}
              >
                {date.format('MMMM YYYY')}
              </ContextMenuItem>
            );
          })}
        </ul>
      </Shadow>
    );
  }
}

export default ContextMenu;
