
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';

import {
  Modal, ModalHeader, ModalContent, Row, SimpleList, PageLoader, Select, EmptyMessage, Button,
} from '../../../../../../Elements';
import ResourceCard, { ResourceCardType } from '../../../../../../Elements/Resource/Card';
import {
  actionCreators,
} from "../../../../../../../store/AdminCMSSite/Resources/Modals/Resource/actions";
import { makeFilteredResourcesSelector } from '../../../../../../../store/AdminCMSSite/Resources/Modals/Resource';
import { FormDefinition } from "../../../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/validation";
import { CMSResource } from '../../../../../../../models/api/adminCMSCacheOne';
import '../../../../../../../styles/pages/resources/modals/resource/index.scss';
import { actionCreators as appActionCreators } from '../../../../../../../store/App/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../../store/Rollback/actions';
import {
    actionCreators as adminCMSCacheOneActionCreators, GetSiteCache,
} from '../../../../../../../store/AdminCMSSite/CacheOne/actions';
import {
    actionCreators as siteMenuItemFormActionCreators,
} from '../../../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/actions';
import { ApplicationState } from '../../../../../../../store';
import { extractRouteParams } from '../../../../../../../utils/urlHelper';
import { makeResourceCategoriesMapSelector } from '../../../../../../../store/AdminCMSSite/Resources/Modals/ResourceCategory';
import { PageIcon } from '../../../../../../../components/Icons';
import { ENTITY_NOT_ADDED, RESOURCES, ENTITY_NOT_FOUND } from '../../../../../../../constants/messages/adminCMS';
import { checkResourcePermission } from '../../../../../../../utils/helpers/adminCMSPageHelper';
import { ModalHeight } from '../../../../../../../components/Elements/Modal';
import { CardCategory } from '../../../../../../../components/Elements/Card';
import { EmptyMessageType } from '../../../../../../Elements/EmptyMessage';
import { activeResourcesSelector } from '../../../../../../../store/AdminCMSSite/Resources/Home';
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { Validator } from '../../../../../../../utils/validator/models';
import { shouldBlockActions } from '../../../../../../../utils/cacheLoaders/helpers/blockers';
import {WithInertAttribute} from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--modals--resource';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>
type Props = WithInertAttribute<{}>

@(withRouter as any)
class ResourceModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;
  private modalContentRef;

  componentDidMount() {
    if (shouldBlockActions()) return;

    this.props.actions.init();
  }

  componentWillUnmount() {
    this.onClose();
  }

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    if (this.props.resourceModal.filterString) {
      this.scrollToTop();
    }
    this.props.actions.toggleFilter(false);
    this.props.actions.filterResources('');
  };

  checkPermission = (onSuccess: () => void, siteId?: number) => {
    const {cacheZero, adminCMSCacheOne} = this.props;
    
    checkResourcePermission(onSuccess, cacheZero, adminCMSCacheOne, siteId, true);
  };
  onAddClick = () => {
    const {actions} = this.props;
    this.checkPermission(
      () => actions.pushFormModal(ModalTypes.RESOURCE_FORM, false),
    );
  };

  onClose = () => {
    this.onSearchBack();
    this.props.actions.reset();
  };
  onRefreshClick = () => {
    const {actions} = this.props;
    actions.saveState();
    actions.refresh(
      {
        GetResources: true,
        MaxResourceID: 0,
        GetResourceCategories: true,
        MaxResourceCategoryID: 0,
        ...extractRouteParams(this.props),
      },
      false,
    );
  };
  onClickResourceElement = (r: CMSResource) => {
    if (!r.Inactive) {
      this.onClose();
      this.props.actions.updateValue(r.ID, FormDefinition.ResourceID as any);
      this.props.actions.popModal(false, true, ModalTypes.RESOURCES);
    }
  };
  onElementEditClick = (resource: CMSResource) => {
    const {actions} = this.props;
    this.checkPermission(
      () => actions.pushFormModal(ModalTypes.RESOURCE_FORM, true, {id: resource.ID}),
      resource.SiteID,
    );
  };

  itemRenderer = (index) => {
    const {resourceCategoriesMap, filteredResources} = this.props;
    const resource: CMSResource = filteredResources[index];
    return (
      <ResourceCard
        template='mobile-full'
        key={`resources_modal_list_item_${resource.ID}`}
        type={ResourceCardType.IN_MODAL}
        cardCategory={CardCategory.LIST_MOBILE}
        onCardClick={this.onClickResourceElement}
        onEdit={() => this.onElementEditClick(resource)}
        noBoxShadow
        resource={resource}
        categoryName={resource.CategoryID ? resourceCategoriesMap[resource.CategoryID.toString()].Name : undefined}
      />
    );
  };

  renderEmptyResources = () => {
    const {activeResources, filteredResources} = this.props;
    const message = activeResources.length === 0 && filteredResources.length === 0 ? ENTITY_NOT_ADDED(RESOURCES): ENTITY_NOT_FOUND(RESOURCES);
    return (
      <EmptyMessage
        icon={PageIcon}
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        actions={<Button className={`${namespace()}--empty-message-btn`} color='white' textColor='black' onClick={this.onAddClick}>NEW RESOURCE</Button>}
        description={message}
      /> 
    );
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };

  onResourceCategoryChange = (val: any, vObj: Validator) => {
    this.scrollToTop();
    this.props.actions.resourceModalUpdateValue(val, vObj);
  };

  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filterResources(val);
  };

  public render() {
    const { apiLoading, apiLoadingMap, resourceModal: { isTextSearching, ActiveForm, ValidationRules }, menuItemForm, cacheTwoPageMenus, cacheTwoSiteMenus, actions, filteredResources, inert, activeResources} = this.props;
    // subtitle should be
    // - if Menu Item name is blank, show `New Menu Item` or `Edit Menu Item`
    // - otherwise, show name of the Menu Item (the one stored in ActiveForm)
    const subtitle = menuItemForm.ActiveForm && menuItemForm.ActiveForm.Name ? menuItemForm.ActiveForm.Name : ((cacheTwoPageMenus.CMSPageMenuItem || cacheTwoSiteMenus.CMSSiteMenuItem) ? 'Edit Menu Item' : 'New Menu Item');
    return (
      <Modal inert={inert} height={ModalHeight.HEIGHT_425} onClose={this.onClose} big mobileFullScreen className={namespace()}>
        <ModalHeader
          isSearching={isTextSearching}
          onAdd={this.onAddClick}
          onRefresh={this.onRefreshClick}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={this.onSearchChange}
          onSearchBack={this.onSearchBack}
          subtitle={subtitle}
          className={`${namespace()}--header`}>
          Select Resource
        </ModalHeader>

        <ModalContent refCallback={(ref) => this.modalContentRef = ref} paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0} >
          <Row className={`${namespace()}--row-2`}>
            {!apiLoadingMap[GetSiteCache.requestType] && filteredResources.length > 0 && <SimpleList
              itemRenderer={this.itemRenderer}
              totalLength={filteredResources ? filteredResources.length : 0}
            />}
            {!apiLoadingMap[GetSiteCache.requestType] && (activeResources.length === 0 || filteredResources.length === 0) ? this.renderEmptyResources() : null}
          </Row>
          {apiLoading > 0 && <PageLoader className={`${namespace()}--page-loader`}/>}
        </ModalContent>
        <Row className={`${namespace()}--filter`}>
          <Select
            disabled={false}
            hideOptional
            onChangeValue={this.onResourceCategoryChange}
            value={ActiveForm.SelectedResourceCaetgoryID}
            validationRules={ValidationRules.SelectedResourceCaetgoryID}
            isNumber />
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredResourcesSelector = makeFilteredResourcesSelector();
  const resourceCategoriesMapSelector = makeResourceCategoriesMapSelector();
  return {
    resourceModal: state.adminCMSSite.resources.modals.resource,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    adminCMSCacheTwoResources: state.adminCMSSite.cacheTwoResources,
    cacheTwoSiteMenus: state.adminCMSSite.cacheTwoSiteMenuItems,
    cacheTwoPageMenus: state.adminCMSSite.cacheTwoPageMenuItems,
    menuItemForm: state.adminCMSSite.menus.siteMenuItem.form,
    filteredResources: filteredResourcesSelector(state),
    resourceCategoriesMap: resourceCategoriesMapSelector(state),
    activeResources: activeResourcesSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
  ...adminCMSCacheOneActionCreators,
  ...siteMenuItemFormActionCreators,
  ...rollbackActionCreators,
}, dispatch) });

const ConnectedResourceModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ResourceModal);

export default ConnectedResourceModal;