import * as React from 'react';
import { Tabs as MdlTabs } from 'react-mdl';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/tabs/index.scss';

import {namespace} from "./constants";

export interface TabsProps {
  className?: string;
  children?: React.ReactNode;
  controls?: React.ReactNode;
  /**
   * Indicates whether or not to disable overflow for tabs
   */
  noOverflow?: boolean;
}

class Tabs extends React.PureComponent<TabsProps, {}> {
  public props: TabsProps;

  public render() {
    const { children, controls, noOverflow, ...props } = this.props;

    // remove null children because that breaks MdlTabs
    let finalChildren: any = [];
    React.Children.forEach(children, (child: React.ReactElement<any>, index) => {
      if (child) {
        finalChildren.push(React.cloneElement(child, { ...child.props, key: index }));
      }
    });

    return (
      <div className={mergeClassNames(namespace(), props)}>
        <MdlTabs className={`${namespace()}--tabs-container ${noOverflow ? 'no-overflow' : ''}`}>
          {finalChildren}
        </MdlTabs>
        {controls}
      </div>
    );
  }
}

export default Tabs;
export { default as Tab } from './Tab';
