import * as React from 'react';
import {Main, MainContent} from '../../../Layouts';
import {EmptyMessage, LoadingAll, Location, Tab, Tabs} from '../../../Elements';
import {RouteComponentProps, withRouter} from 'react-router';
import '../../../../styles/pages/home/my-trips/index.scss';
import {
  makeFacilityLocationsSelector,
  makeMyTripsSelector
} from "../../../../store/Facilities/Locations/index";
import {actionCreators} from "../../../../store/Facilities/Locations/actions";
import {FacilityLocation, HomepageMyTrip} from "../../../../models/api/cacheOne";
import {bindActionCreators} from 'redux';
import {createInitials, getImagePath, isImageValid, navPush, spaceTo_} from '../../../../utils';
import {FacilitiesIcon} from '../../../Icons';
import Trip from '../../Home/MyTrips/Trip';
import {URLS} from '../../../../constants/urls';
import {
  actionCreators as cacheOneActionCreators,
} from "../../../../store/CacheOne/actions";
import {
  actionCreators as cacheZeroActionCreators,
} from "../../../../store/CacheZero/actions";
import {actionCreators as appActionCreators} from "../../../../store/App/actions";
import { ApplicationState } from '../../../../store';
import { generateDOMId } from '../../../../utils/cypressHelper';
import EndUserCacheManager from '../../../../utils/cacheManagers/endUserCacheManager';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';
import { SaveState } from '../../../../store/Rollback/actions';
import { reduxStoreService } from '../../../../store/service';
import { ComponentUpdateTemplate } from '../../../Templates/ComponentUpdateTemplate';
import { CacheOneContext } from '@tentaroo/shared';
import {isEndUserCacheOnePopulated} from '../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {}>
>;

@(withRouter as any)
class FacilitiesLocations extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;

  public componentDidMount() {
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        return  EndUserCacheManager.getInstance().loadCacheOne({
          props: this.props,
          isStateNavigated,
          context: CacheOneContext.FACILITY_LOCATIONS,
        });
      }
    );
  }

  public componentWillReceiveProps(nextProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheOne({
          props: nextProps,
          isStateNavigated,
          context: CacheOneContext.FACILITY_LOCATIONS,
        });

      }
    );
  }

  public componentWillUnmount() {
  }

  onClickAvailable = () => this.props.actions.facilitiesSelectTab('available');
  onClickMyTrips = () => this.props.actions.facilitiesSelectTab('myTrips');

  onClickLocation = (loc: FacilityLocation) => {
    const {router} = this.props;

    if (reduxStoreService().getState().app.apiLoading === 0) reduxStoreService().dispatch(new SaveState());
    navPush(router, `${URLS.FACILITIES}/${loc.ID}/${spaceTo_(loc.Name)}`);
  };

  onClickTrip = (trip: HomepageMyTrip) => {
    const {router} = this.props;

    if (reduxStoreService().getState().app.apiLoading === 0) reduxStoreService().dispatch(new SaveState());
    navPush(router, `${URLS.FACILITY_TRIP}/${trip.LocationID}/${trip.GroupTripID}/${spaceTo_(trip.Name)}`);
  };

  renderLocations() {
    const { facilitiesLocations: {selectedTab}, router, filteredLocs, filteredTrips, cacheOne: {FacilityLocations, HomepageMyTrips} } = this.props;
    if (selectedTab === 'available') {
      if (filteredLocs && filteredLocs.length > 0) {
        const facs: Array<any> = [];
        filteredLocs.forEach((loc: FacilityLocation) => {
          facs.push(
            <Location
              id={generateDOMId(`facility-${loc.ID}`)}
              color={`#${loc.Color}`}
              onClick={() => this.onClickLocation(loc)}
              name={loc.Name}
              info={`${loc.Location.City}, ${loc.Location.State}`}
              initials={createInitials(loc.Name)}
              numTrips={loc.NumCurrentTrips}
              cart={loc.InCart !== 0}
              image={isImageValid(loc.FeaturedImage) && loc.FeaturedImage ? getImagePath(loc.FeaturedImage) : undefined}
            />
          );
        });
        return facs;
      } else {
        let message = 'No Facilities Found';
        if (!FacilityLocations || FacilityLocations.length === 0) message = 'There are no facilities available';
        return <EmptyMessage
          icon={FacilitiesIcon}
          description={message}
        />;
      }
    } else {
      if (filteredTrips && filteredTrips.length > 0) {
        const myTripLocations: Array<any> = [];
        filteredTrips.forEach((trip: HomepageMyTrip, index) => {
          // @todo: hardcoded link
          myTripLocations.push(
            <Trip
              key={index}
              onClick={() => this.onClickTrip(trip)}
              dateStart={trip.StartDateTime}
              dateEnd={trip.EndDateTime}
              name={trip.Name}
              details={`${trip.Location.Name} (${trip.Location.City}, ${trip.Location.State})`}
              total={trip.TotalAmount}
              balance={trip.Balance}
              cart={trip.InCart !== 0}
              youth={trip.NumYouth}
              adults={trip.NumAdults}
            />
          );
        });
        return myTripLocations;
      } else {
        let message = 'No trips found.';
        if (!HomepageMyTrips || HomepageMyTrips.length === 0) message = 'You have no upcoming trips.';

        return <EmptyMessage
          icon={FacilitiesIcon}
          description={message}
        />;
      }
    }
  }

  render() {
    const { facilitiesLocations: {selectedTab}, cacheOne, inert} = this.props;
    if (!isEndUserCacheOnePopulated(cacheOne)) return <LoadingAll/>;
    return (
      <Main inert={inert}>
        <MainContent handleCompact>
          <Tabs>
            <Tab selected={selectedTab === 'available'} onClick={this.onClickAvailable}>AVAILABLE Locations</Tab>
            <Tab selected={selectedTab === 'myTrips'} onClick={this.onClickMyTrips}>My Trips</Tab>
          </Tabs>
          {this.renderLocations()}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredTrips = makeMyTripsSelector();
  const filteredLocs = makeFacilityLocationsSelector();
  return {
    facilitiesLocations: state.facilities.locations,
    filteredTrips: filteredTrips(state),
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    apiLoading: state.app.apiLoading,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    filteredLocs: filteredLocs(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...cacheOneActionCreators,
  ...cacheZeroActionCreators,
  ...appActionCreators,
}, dispatch) });

const ConnectedFacilitiesLocations = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(FacilitiesLocations);

export default ConnectedFacilitiesLocations;
export {default as FacilitiesLocationsHeader} from './Header';
