import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/app/header/info.scss';

export const namespace = (): string => `${ns()}--info`;

interface Props {
  children?: React.ReactNode;
}

const Info: React.SFC<Props> = ({ children }: Props): React.ReactElement<Props> => (
  <div className={namespace()}>
    {children}
  </div>
);

export default Info;
