
import {CacheFourEventsNumbersState} from "../../../../../CacheFourEventsNumbers/index";
import {CacheTwoEventsState} from "../../../../../CacheTwoEvents/index";
import { isAdmin } from '../../../../../../utils/permissionHelper';

// determine which page spots should go to
export const showClasses = (cacheFourEventsNumbers: CacheFourEventsNumbersState) => {
  const {EventClassesGroupAvailable, EventClassesGroupRegistered} = cacheFourEventsNumbers;
  return (EventClassesGroupAvailable && EventClassesGroupAvailable.length > 0) ||
    (EventClassesGroupRegistered && EventClassesGroupRegistered.length > 0);
};
export const showCampsite = (cacheTwoEvents: CacheTwoEventsState) => {
  return cacheTwoEvents.EventTypeRegistrationSettings &&
    cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.ShowCampsiteRanking && !isAdmin();
};

export const showProducts = (cacheFourEventsNumbers: CacheFourEventsNumbersState) => {
  if (cacheFourEventsNumbers.EventProductsAvailable && cacheFourEventsNumbers.EventProductsAvailable.length > 0) return true;
  if (cacheFourEventsNumbers.EventProductsOrdered && cacheFourEventsNumbers.EventProductsOrdered.length > 0) {
    // make sure at least one is not inactive
    for (let i = 0; i < cacheFourEventsNumbers.EventProductsOrdered.length; i++) {
      const product = cacheFourEventsNumbers.EventProductsOrdered[i];
      if (!product.Inactive) return true;
    }
  }

  return false;
};
export const productsNext = (cacheTwoEvents: CacheTwoEventsState) => {
  if (showCampsite(cacheTwoEvents) && !isAdmin()) return 'campsite_ranking';
  else if (isAdmin()) return 'campsite_assignment';
  return 'confirmation';
};
export const spotsNext = (cacheTwoEvents: CacheTwoEventsState, cacheFourEventsNumbers: CacheFourEventsNumbersState): 'classes' | 'products' | 'campsite_ranking' | 'confirmation' | 'campsite_assignment'=> {
  if (showClasses(cacheFourEventsNumbers)) return 'classes';
  else if (showProducts(cacheFourEventsNumbers)) return 'products';
  else if (showCampsite(cacheTwoEvents) && !isAdmin()) return 'campsite_ranking';
  else if (isAdmin()) return 'campsite_assignment';
  return 'confirmation';
};
export const classesNext = (cacheTwoEvents: CacheTwoEventsState, cacheFourEventsNumbers: CacheFourEventsNumbersState) => {
  if (showProducts(cacheFourEventsNumbers)) return 'products';
  else if (showCampsite(cacheTwoEvents) && !isAdmin()) return 'campsite_ranking';
  else if (isAdmin()) return 'campsite_assignment';
  return 'confirmation';
};
export const campsiteBack = (cacheFourEventsNumbers: CacheFourEventsNumbersState) => {
  if (showProducts(cacheFourEventsNumbers)) return 'products';
  else if (showClasses(cacheFourEventsNumbers)) return 'classes';
  return 'spots';
};
export const productsBack = (cacheTwoEvents: CacheTwoEventsState, cacheFourEventsNumbers: CacheFourEventsNumbersState) => {
  if (showClasses(cacheFourEventsNumbers)) return 'classes';
  return 'spots';
};