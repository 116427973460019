import { createSelector } from "reselect";
import { apiValidationReducerCreator } from "../../../../../store/Validation/reducerCreator";
import { SUFFIX, InitAction, GetEventTypeAction, ResetAction } from './actions';
import { setDefaults } from "../../../../../utils/validator";
import { ApplicationState } from "../../../../../store";
import { ClearAdminEventsCacheTwoEventType } from "../../../../../store/AdminEvents/CacheTwoEventType/actions";
import { APISuccess, WithRootState } from "../../../../../store/Validation/actionCreator";
import { locationsSelector } from "../../../../AdminSettings/Modals/Location";
import { EventTypeGeneralActiveForm } from "../../EventType/Form";
import { NewEventTypeFormDefinition, INewEventTypeValidator } from "./validation";
import { AdminEventsEventCategory, AdminEventsOption } from "../../../../../models/api/adminEventsCacheOne";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../../utils/StrongActions";

const getInitialState = () => {
  return {
    General: {
      ActiveForm: { },
      ValidationRules: { ...NewEventTypeFormDefinition },
    }
  };
};

export interface NewEventTypeFormState {
  General: {
    ActiveForm: EventTypeGeneralActiveForm;
    ValidationRules: INewEventTypeValidator;
    SubmitErrorMessage?: string;
  };
}

const selectedLocationIDSelector = (state: ApplicationState) => state.adminEvents.eventTypes.modals.newEventType.General.ActiveForm.LocationID;
export const makeSelectedLocationSelector = () => {
  return createSelector(
    [locationsSelector, selectedLocationIDSelector],
    (locations: any[], locationId: number) => {
      if (locations && locationId) return locations.find((l) => l.ID === locationId);

      return null;
    }
  );
};

const selectedEventCategoryIDSelector = (state: ApplicationState) => state.adminEvents.eventTypes.modals.newEventType.General.ActiveForm.EventCategoryID || 0;
const eventCategoriesSelector = (state: ApplicationState) => state.cacheZero.options ? state.cacheZero.options.EventCategories : [];
export const makeSelectedEventCategorySelector = () => {
  return createSelector(
    [eventCategoriesSelector, selectedEventCategoryIDSelector],
    (eventCategories: AdminEventsEventCategory[], id: number) => {
      return eventCategories.find((category) => category.ID === id);
    }
  );
};
const eventTypeRegistrationMethodOptionsSelector = (state: ApplicationState) => state.cacheZero.options ? state.cacheZero.options.EventTypeRegistrationMethodOptions : [];
const selectedRegistrationMethodIDSelector = (state: ApplicationState) => state.adminEvents.eventTypes.modals.newEventType.General.ActiveForm.RegistrationMethodID;
export const makeSelectedRegistrationMethodSelector = () => {
  return createSelector(
    [eventTypeRegistrationMethodOptionsSelector, selectedRegistrationMethodIDSelector],
    (options: AdminEventsOption[], id: number) => options ? options.find((o) => o.ID === id) : null
  );
};
const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const getDefaultNameWhenDuplicating = (rootState: ApplicationState, name: string) => {
  const newName = `Copy of ${name}`;
  const NameValidationRules = rootState.adminEvents.eventTypes.modals.newEventType.General.ValidationRules.Name;

  return newName.length <= NameValidationRules.validatejs.Name.length.maximum ? newName : name;
};

const NewEventTypeFormReducer: Reducer<NewEventTypeFormState> = (s, act: WithRootState<AnyAction>) => {
  const state = { ...getInitialState(), ...s } as any;
  if (s && s.General) state.General = checkApiValidation(s.General, act);

  if (isActionType(act, InitAction)) {
    // if (shouldSkipUpdateForm(action)) return state;

    let newState = getInitialState() as NewEventTypeFormState;
    // set initial and default value
    newState.General = setDefaults(act.rootState, newState.General, state.General);

    if (act.eventType) {
      newState.General.ActiveForm = {
        ...act.eventType,
        Name: getDefaultNameWhenDuplicating(act.rootState, act.eventType.Name || ''),
      };
    }
    return newState;
  } else if (act.type === GetEventTypeAction.successType) {
    // TODO: change this to init action, and then run `shouldSkipUpdateForm` check
    const action = <WithRootState<APISuccess>> act;

    if (!action.response || !action.response.response) return state;

    return {
      ...state,
      General: {
        ActiveForm: {
          ...action.response.response.EventsEventType.General,
          Name: getDefaultNameWhenDuplicating(action.rootState, action.response.response.EventsEventType.General.Name)
        },
        ValidationRules: { ...NewEventTypeFormDefinition },
      }
    };
  } else if (isActionType(act, ClearAdminEventsCacheTwoEventType) || isActionType(act, ResetAction)) {
    return getInitialState();
  }

  return state || getInitialState();
};

export default NewEventTypeFormReducer;