import * as React from 'react';
import {
  Modal, ModalHeader, ModalContent, ModalActions, Button, CartItem, CartList, HeaderAmount, EmptyMessage
} from '../../Elements';
import '../../../styles/pages/cart/cart.scss';
import { CartIcon } from '../../Icons';
import {bindActionCreators} from 'redux';
import {actionCreators} from "../../../store/App/actions";
import {cartActionCreators} from "../../../store/Cart/actions";
import {withRouter, RouteComponentProps} from "react-router";
import {navPush} from "../../../utils/navHelper";
import {URLS} from "../../../constants/urls";
import {standardCurrencyFormat} from "../../../utils/classesHelper";
import {CartOrder, CartOrderItem} from "../../../models/api/cacheOne";
import {checkPermission} from "../../../utils/permissionHelper";
import { generateDOMId } from '../../../utils/cypressHelper';
import { ModalTypes } from '../../../utils/modalHelper';
import { appActionCreators, ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';
import { reduxStoreService } from '../../../store/service';
import {WithInertAttribute} from '../../Elements/WithInert';

export const namespace = (): string => 'pages--cart';

type Props = WithInertAttribute<{}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

@(withRouter as any)
class Cart extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  onCheckout = () => {
    reduxStoreService().dispatch(appActionCreators.popModal(false, false, ModalTypes.CART) as any);
    navPush(this.props.router, URLS.CHECKOUT);
  };
  onRemoveItem = (id: number, to?: string) => this.props.actions.pushRemoveItemModal({id, to});

  onCancelRegistration = (
    isYouth?: boolean,
    name?: string,
    participantID?: number,
    inCart?: -1 | 0 | 1,
    eventTypeID?: number,
    eventID?: number,
    groupWeekID?: number,
    goToRegistration?: boolean,
    IsNewlyAdding?: boolean
  ) => {
    this.props.actions.pushCancelRegistrationModal(
      isYouth,
      name,
      participantID,
      inCart,
      eventTypeID,
      eventID,
      groupWeekID,
      goToRegistration,
      IsNewlyAdding,
    );
  };
  renderCartOrderItem(cartItem: CartOrderItem) {
    return <CartItem
      cacheZero={this.props.cacheZero}
      isAdmin={!!this.props.user.user.str_permissions.hasAdminAccess}
      CartOrderItem={cartItem}
      sidePadding
      router={this.props.router}
      onCancelRegistration={this.onCancelRegistration}
      onRemove={this.onRemoveItem}
      popModal={this.props.actions.popModal}
      fromModal
    />;
  }

  onEmptyCart = () => {
    const {cacheZero: {options}} = this.props;

    if (!options?.GeneralPermissions) return;

    checkPermission(
      () => this.props.actions.pushConfirmEmptyCartModal(),
      options.GeneralPermissions.hasGroup,
      options.GeneralPermissions.hasGroupReason,
      true
    );
  };

  public render() {
    const {apiSaving, apiLoading, inert} = this.props;
    const CartOrderItems = this.props.cacheOne.CartOrderItems as Array<CartOrderItem>;
    const CartOrder = this.props.cacheOne.CartOrder as CartOrder;


    if (!CartOrderItems || CartOrderItems.length === 0) return (
      <Modal inert={inert} big mobileFullScreen>
        <ModalHeader>Your Cart</ModalHeader>
        <ModalContent paddingBottom={0} paddingTop={0} verticalAlign="center">
          <EmptyMessage
            icon={CartIcon}
            description="Your cart is empty"
            className={`${namespace()}--inner`}
          />
        </ModalContent>
      </Modal>
    );

    return (
      <Modal inert={inert} big mobileFullScreen>
        <ModalHeader
          controls={(
            <div className={`${namespace()}--total`}>
              <span className={`${namespace()}--total--label`}>Total:</span>
              <span className={`${namespace()}--total--amount`}>{standardCurrencyFormat(CartOrder.Amount)}</span>
            </div>
          )}
        >
          Your Cart
        </ModalHeader>
        <ModalContent loading={apiSaving > 0 || apiLoading > 0} scrollable paddingLeft={0} paddingRight={0}>
          <HeaderAmount
            mobile
            label="Total:"
            amount={standardCurrencyFormat(CartOrder.Amount)}
            className={`${namespace()}--total-page-header`}
          />
          <CartList className={`${namespace()}--cart-list`}>
            {CartOrderItems.map((c) => this.renderCartOrderItem(c))}
          </CartList>
        </ModalContent>
        <ModalActions
          loading={apiSaving > 0 || apiLoading > 0}
          sticky
          notFixed
          left={<Button id={generateDOMId("empty-cart-btn")} flat textColor="black" onClick={this.onEmptyCart}>EMPTY CART</Button>}
          right={<Button id={generateDOMId("cart-checkout")} textColor="green" flat onClick={this.onCheckout}>CHECK OUT</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    cacheZero: state.cacheZero,
    cacheOne: state.cacheOne,
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
    user: state.user,
    getEmptyCartBody: state.app.getEmptyCartBody,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...cartActionCreators
}, dispatch)});

const ConnectedCart = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(Cart);

export default ConnectedCart;
