import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { Actions as AdminCMSCacheOneActions } from '../../../../../../store/AdminCMSSite/CacheOne/actions';
import { Actions as AppActions } from '../../../../../../store/App/actions';
import { Actions as RollbackActions } from "../../../../../../store/Rollback/actions";
import { Actions } from "../../../../../../store/AdminCMSSite/Pages/Home/actions";
import '../../../../../../styles/pages/cms-pages/home/page-filters/index.scss';
import { TextField, Select, Switch, ActionButton, DatePicker } from '../../../../../Elements';
import { SearchIcon, FilterIcon } from '../../../../../Icons';
import { PageTypeIDValue } from '../../../../../../models/api/adminCMSCacheOne';
import { AdminCMSSiteCacheOneState } from '../../../../../../store/AdminCMSSite/CacheOne';
import { PageFormState } from '../../../../../../store/AdminCMSSite/Pages/Page/Form';
import { EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE } from '../../../../../../constants/messages/adminCMS';
import { validateAndGetParamsOnPageFilterChange } from '../../../../../../store/AdminCMSSite/Pages/Home/uiHelpers';
import { RouteComponentProps, withRouter } from 'react-router';
import { PagesHomeState } from '../../../../../../store/AdminCMSSite/Pages/Home/index.types';
import { reduxStoreService } from '../../../../../../store/service';
import { Validator } from '../../../../../../utils/validator/models';

export const namespace = (): string => 'elements--page-filters';

type ConnectedProps = RouteComponentProps<{}, {}>;
export interface PagesFiltersProps {
  className?: string;
  disabled?: boolean;
  expand?: boolean;
  pagesHome: PagesHomeState;
  compact?: boolean;
  reduxActions: Actions & AppActions & AdminCMSCacheOneActions & RollbackActions;
  adminCMSCacheOne: AdminCMSSiteCacheOneState;
  selectedPageID: number;
  control: React.ReactNode;
  context: 'edit-page' | 'page-list';
  pageForm?: PageFormState;
  onValidate: (invalid: boolean) => any;
}

interface State {
  showPageSearch?: boolean;
}

class PagesFilters extends React.Component<PagesFiltersProps, State> {
  public props: PagesFiltersProps & ConnectedProps;

  state = {
    showPageSearch: false,
  };
  public static defaultProps = {

  };

  onFilterClick = () => {
    this.props.reduxActions.toggleExpandFilter(!this.props.expand);
  };

  onSearchCancel = () => {
    const {pagesHome: {ValidationRules}} = this.props;
    this.setState({showPageSearch: false});
    this.props.reduxActions.simpleUpdate(undefined, ValidationRules.FilterText);
    this.props.reduxActions.updateValue(undefined, ValidationRules.FilterText);
  };
  onSearchChange = (val: string, vObj: Validator) => {
    if (val) {
      this.setState({
        showPageSearch: true,
      });
    }
    this.props.reduxActions.simpleUpdate(val, vObj);
  };

  onPageFilterChange = (v, vObj, key: string) => {
    const {reduxActions, onValidate, pagesHome: {ActiveForm, ValidationRules}, pageForm, context} = this.props;

    // prevent any action if we are processing image in EditPage
    if (pageForm && pageForm.processingImages) {
      reduxActions.showTopFloatingAlert(EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('changing filters'), undefined, 'orange');
      return;
    }

    // Generate params, will be `undefined` if invalid
    const params = validateAndGetParamsOnPageFilterChange(
      v,
      vObj,
      key,
      context === 'edit-page' ? reduxActions.updateValue : reduxActions.updateValueWithoutSave,
      ActiveForm,
      ValidationRules,
      () => reduxStoreService().getState().adminCMSSite.pages.home,
      () => onValidate(true),
      this.props,
    );
    
    // Send request if params is returned
    if (params) reduxActions.getSiteCache(params, false, context === 'page-list');
  };

  onIncludePastEventsChange = (v, vObj) => {
    this.onPageFilterChange(v, vObj, 'IncludePastEvents');
  };
  onIncludeExpiredBlogArticlesChange = (v, vObj) => {
    this.onPageFilterChange(v, vObj, 'IncludeExpiredBlogArticles');
  };
  onShowDeletedChange = (v, vObj) => {
    this.onPageFilterChange(v, vObj, 'ShowInactive');
  };
  onEventCategoryChange = (v, vObj) => {
    this.onPageFilterChange(v, vObj, 'EventCategoryID');
  };
  onEventStartDateChange = (v, vObj) => {
    this.onPageFilterChange(v, vObj, 'EventStartDate');
  };

  onPageTypeChange = (v, vObj) => {
    this.onPageFilterChange(v, vObj, 'PageTypeID');
  };

  renderShowDeletedSwitch = () => {
    const {pagesHome: {ActiveForm, ValidationRules}, disabled} = this.props;
    return (
      <Switch
        className={`${namespace()}--switch`}
        label="Show Deleted"
        newDesign
        disabled={disabled}
        onChange={this.onShowDeletedChange}
        value={!!ActiveForm.ShowDeleted}
        validationRules={ValidationRules.ShowDeleted}
      />
    );
  };

  renderIncludeExpiredBlogArticlesSwitch = () => {
    const {pagesHome: {ActiveForm, ValidationRules}, disabled} = this.props;
    return (
      <Switch
        className={`${namespace()}--switch`}
        label="Include Expired Blog Articles"
        newDesign
        disabled={disabled}
        onChange={this.onIncludeExpiredBlogArticlesChange}
        value={!!ActiveForm.IncludeExpiredBlogArticles}
        validationRules={ValidationRules.IncludeExpiredBlogArticles}
      />
    );
  };

  renderExpandForAllPage = () => {
    const {expand, pagesHome: {ActiveForm, ValidationRules}, disabled} = this.props;

    if (!expand) return null;
    const result: any[] = [];

    result.push((<div key='all_page_row2' className={`${namespace()}--row2`}>
        <Switch
          className={`${namespace()}--switch`}
          label="Include Past Calendar Events"
          newDesign
          disabled={disabled}
          onChange={this.onIncludePastEventsChange}
          value={!!ActiveForm.IncludePastEvents}
          validationRules={ValidationRules.IncludePastEvents}
        />
        {this.renderIncludeExpiredBlogArticlesSwitch()}
      </div>
    ));

    result.push((<div key='all_page_row3' className={`${namespace()}--row3`}>
        {this.renderShowDeletedSwitch()}
      </div>
    ));

    return result;
  };

  renderExpandForCalendarEvents = () => {
    const {expand, pagesHome: {ActiveForm, ValidationRules}, reduxActions, disabled} = this.props;

    if (!expand) return null;
    const result: any[] = [];

    result.push((<div key='calendar_row2' className={`${namespace()}--row2`}>
        <Select
          className={`${namespace()}--advanced-select`}
          label="Event Category"
          hideOptional
          disabled={disabled}
          onChangeValue={this.onEventCategoryChange}
          value={ActiveForm.EventCategoryID}
          validationRules={ValidationRules.EventCategoryID}
          isNumber />
        <DatePicker
          className={`${namespace()}--advanced-select`}
          label="Start Date From"
          hideError
          disabled={disabled}
          value={ActiveForm.EventStartDate}
          validationRules={ValidationRules.EventStartDate}
          onSelect={this.onEventStartDateChange}
          onChangeRaw={reduxActions.simpleUpdate}
          removeMax
        />
      </div>
    ));

    result.push((<div key='calendar_row3' className={`${namespace()}--row3`}>
        {this.renderShowDeletedSwitch()}
      </div>
    ));

    return result;
  };

  renderExpandForBlogArticle = () => {
    const {expand} = this.props;

    if (!expand) return null;

    return (
      <div className={`${namespace()}--row2`}>
        {this.renderIncludeExpiredBlogArticlesSwitch()}
        {this.renderShowDeletedSwitch()}
      </div>
    );
  };

  public render() {
    const props = this.props;
    const {pagesHome: {ActiveForm, ValidationRules}, reduxActions, disabled, expand, control, selectedPageID, compact} = props;

    return (
      <div className={`${mergeClassNames(namespace(), props)} ${compact ? 'compact' : ''}`}>
        <div className={`${namespace()}--row1`}>
          <Select
              className={`${namespace()}--select`}
            label="Page Type"
            disabled={disabled}
            hideOptional
            onChangeValue={this.onPageTypeChange}
            value={ActiveForm.PageTypeID}
            validationRules={ValidationRules.PageTypeID}
              isNumber />
          <TextField
            className={`${namespace()}--search`}
            disabled={disabled}
            hideOptional
            icon={<SearchIcon />}
            onCancel={!!ActiveForm.FilterText ? this.onSearchCancel : undefined}
            onChange={this.onSearchChange}
            onBlur={reduxActions.updateValue}
            value={ActiveForm.FilterText}
            validationRules={ValidationRules.FilterText}
            label='Search' />
          {!this.state.showPageSearch && <Select
            className={`${namespace()}--select mobile`}
            label="Page Type"
            disabled={disabled}
            hideOptional
            onChangeValue={this.onPageTypeChange}
            value={ActiveForm.PageTypeID}
            validationRules={ValidationRules.PageTypeID}
            isNumber />}
          {!this.state.showPageSearch && <ActionButton lightSelected className={`${namespace()}--buttons--search`} square onClick={() => this.setState({showPageSearch: true})} icon={SearchIcon} />}
          {this.state.showPageSearch && <TextField
            className={`${namespace()}--buttons--search-field`}
            autoFocus
            onCancel={this.onSearchCancel}
            disabled={disabled}
            hideOptional
            icon={<SearchIcon />}
            onChange={this.onSearchChange}
            onBlur={reduxActions.updateValue}
            value={ActiveForm.FilterText}
            validationRules={ValidationRules.FilterText}
            label='Search' />}
          <ActionButton
            className={`${namespace()}--expand`}
            lightSelected
            square
            onClick={() => this.onFilterClick()}
            icon={FilterIcon} selected={expand} />
          {control}
        </div>
        {selectedPageID === PageTypeIDValue.ALL && this.renderExpandForAllPage()}
        {selectedPageID === PageTypeIDValue.BLOG_ARTICLE && this.renderExpandForBlogArticle()}
        {selectedPageID === PageTypeIDValue.CALENDAR_EVENT && this.renderExpandForCalendarEvents()}
        {selectedPageID !== PageTypeIDValue.BLOG_ARTICLE && selectedPageID !== PageTypeIDValue.CALENDAR_EVENT && selectedPageID !== PageTypeIDValue.ALL && expand ? <div className={`${namespace()}--row2`}>
        {this.renderShowDeletedSwitch()}</div> : null}
      </div>
    );
  }
}

export default withRouter(PagesFilters);