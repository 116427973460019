import {SaveState} from "../../store/Rollback/actions";
import {ToggleNotFound} from "../../store/App/actions";
import {getGroupRosterCacheBody} from "../../constants/urls";
import { ApplicationState } from "../../store";
import { getGroupID } from "../helpers/endUserPageHelper";
import { GetGroupRosterCache } from "../../store/CacheTwoRoster/actions";
import { captureTentarooError, captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from "../dataHelper";
import { reduxStoreService } from "../../store/service";
import {isIdValid} from "../urlHelper";
import { LoadEndUserCacheTwoRosterParams } from "./helpers/models";
import {isCacheTwoRosterPopulated, isCacheZeroPopulated, isEndUserCacheOnePopulated} from "../cachePopulatedCheckers/endUser";


export const ensureCacheTwoRoster = (
  params: LoadEndUserCacheTwoRosterParams
): boolean => {
  const {props, isStateNavigated} = params;
  const groupID = getGroupID(props);
  if (!isIdValid(groupID)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const rootState = reduxStoreService().getState() as ApplicationState;
  const {cacheTwoRoster, cacheZero, cacheOne} = rootState;

  let requestedData = false;
  if (isCacheTwoRosterPopulated(cacheTwoRoster)) {
    if (isStateNavigated) {
      reduxStoreService().dispatch(new SaveState());
    }
  } else {
    if (!isCacheZeroPopulated(cacheZero)) {
      // Came from page load, need everything
      reduxStoreService().dispatch(GetGroupRosterCache.request({
        GroupIDi: groupID,
        GroupTS: 0,
        GetOptions: true,
        GetGroupData: true,
        IncludeInactive: cacheTwoRoster.showDeletedGroupRoster
      }));
    } else if (
      isCacheZeroPopulated(cacheZero) &&
      (!cacheZero.options!.Group || !isEndUserCacheOnePopulated(cacheOne))
    ) {
      reduxStoreService().dispatch(GetGroupRosterCache.request({
        GroupIDi: groupID,
        GroupTS: 0,
        GetOptions: false,
        GetGroupData: true,
        IncludeInactive: cacheTwoRoster.showDeletedGroupRoster
      }));
    } else {
      if (!cacheZero.options!.Group) {
        captureTentarooError(new Error("Group is not available when dispatching GetGroupRosterCache in cacheTwoRosterLoader"));
        return false;
      }
      reduxStoreService().dispatch(GetGroupRosterCache.request({
        GroupIDi: groupID,
        GroupTS: cacheZero.options!.Group.TS,
        GetOptions: false,
        GetGroupData: false,
        IncludeInactive: cacheTwoRoster.showDeletedGroupRoster
      }));
    }
    requestedData = true;
  }

  return requestedData;
};

export const getCacheTwoRosterEmptyCartBody = (params: {}) => {
  const rootState = reduxStoreService().getState();

  let debugPayload: TentarooDebugPayload | undefined;
  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when getCacheTwoRosterEmptyCartBody");
  }
  if (!rootState.cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when getCacheTwoRosterEmptyCartBody");
  }
  const body: any = getGroupRosterCacheBody({
    GroupIDi: rootState.cacheZero.options?.Group?.IDi as any,
    GroupTS: rootState.cacheZero.options?.Group?.TS as any,
    GetOptions: false,
    GetGroupData: true,
    IncludeInactive: rootState.cacheTwoRoster.showDeletedGroupRoster
  });
  body.AppState.EmptyCart = true;
  if (debugPayload) {
    return withTentarooDebugPayload(body, debugPayload);
  } else {
    return body;
  }
};
