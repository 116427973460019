import {GetEventParticipantData} from './actions';
import { getEventParticipantData, getEventParticipantDataBody } from '../../constants/urls';
import {makeRequestEpic} from "../Validation/epicCreator";

export const cacheFourEventsViewParticipantEpic = makeRequestEpic(
  GetEventParticipantData,
  getEventParticipantData,
  getEventParticipantDataBody,
  true
);
