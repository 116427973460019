
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetAdminEventProduct} from "./actions";
import { getAdminEventProductCache, getAdminEventProductBody } from "../../../constants/adminEventsUrls";

export const adminEventsCacheTwoProductEpic = makeRequestEpic(
  GetAdminEventProduct,
  getAdminEventProductCache,
  getAdminEventProductBody,
  true,
);