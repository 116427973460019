import { IValidator, FormDefinition } from "./validation";
import { SUFFIX, ToggleAddSiteMenuSidebar, ToggleEditSiteMenuSidebar, RemoveResourceFromPage, RemoveContactFromPage, AddContactsToPage, AddResourcesToPage, RemoveSocial, AddSocial, UpdateSocialType, MoveTopGalleryItem, MoveBottomGalleryItem, TopGalleryAddImage, BottomGalleryAddImage, ToggleSectionExpand, GetDefaultForPrimaryEventPageSubmitActions, EditPageUpdateSiteMenuItemSubmitActions, EditPageEditGalleryImageSubmitActions, EditPageDeleteGalleryImageSubmitActions, EditPageAddBottomGalleryImageSubmitActions, EditPageAddTopGalleryImageSubmitActions, ClearTempThumbnails, UPDATE_CMS_PAGE_FORM_SUFFIX, UPDATE_SITE_MENU_ITEM_SUFFIX, EDIT_GALLERY_IMAGE_SUFFIX, DELETE_GALLERY_IMAGE_SUFFIX } from "./actions";
import { apiValidationReducerCreator, shouldSkipUpdateForm } from "../../../../../store/Validation/reducerCreator";
import { createSelector } from "reselect";
import { CMSSiteMenuItem, CMSResource, CMSResourceCategory, CMSContact, SiteEventCategory } from "../../../../../models/api/adminCMSCacheOne";
import { APISuccess, APIFailure, WithRootState, APISuccessSubmit } from "../../../../../store/Validation/actionCreator";
import * as moment from 'moment';
import { convertObjToMoment } from "../../../../../utils";
import { ApplicationState } from "../../../../../store";
import { locationsSelector } from "../../../../AdminSettings/Modals/Location";
import { resourcesSelector } from "../../../../../store/AdminCMSSite/Resources/Home";
import { resourceCategoriesSelector } from "../../../../../store/AdminCMSSite/Resources/Modals/ResourceCategory";
import { contactsSelector } from "../../../../../store/AdminCMSSite/Contacts/Home";
import { CMSSocial } from "../../../../../models/api/adminCMSCacheOne";
import { getSocialMediasDefinition, getSocialUserValidationRule } from "../../../../../store/AdminCMSSite/Contacts/Contact/Form/validation";
import { removeSocialTypeFromState } from "../../../../../store/AdminCMSSite/Contacts/Contact/Form";
import { getSocialTypeValidationRule, getSocialTypesValue } from "../../../Settings/General/validation";
import { CMSPageGalleryImage } from "../../../../../models/api/adminCMSCacheTwoPage";
import { setDefaults } from "../../../../../utils/validator";
import { siteEventCategoriesSelector } from "../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form";
import { processHour, compareDataByRowNum } from "../../../../../utils/dateHelper";
import { ClearAdminCMSSiteCacheBelowOne } from "../../../../../store/AdminCMSSite/CacheOne/actions";
import { AdminCMSSiteCacheTwoPageState, updateImageInGallery } from "../../../../../store/AdminCMSSite/CacheTwoPages";
import { ClearAdminCMSCacheTwoPage } from "../../../../../store/AdminCMSSite/CacheTwoPages/actions";
import { API_UPD } from "../../../../../constants/request";
import { SetCacheAction, SilentCancelAllAction } from "../../../../../store/App/actions";
import { moveItemInArrayWithOffset, filteredPhotoGalleryCombiner, handleDeleteImageFailure, handleAddImageFailure } from "../../../../../utils/dataHelper";
import { Reducer } from "redux";
import { Action, isActionType } from "../../../../../utils/StrongActions";
import { hasOngoingSequentialRequest } from "../../../../App/reducerHelpers";
import { initSocialTypesActiveForm } from "../../../Settings/General/reducerHelpers";

export interface PageActiveForm {
  ID: number;
  PageTypeID?: number;
  // Page Settings
  Name?: string;
  ShortURL?: string;
  IsFeatured?: boolean;
  StartDate?: moment.Moment;
  EndDate?: moment.Moment;
  DistrictIDi?: number;
  EventTypeID?: number;
  IsPrimaryEventPage?: boolean;
  ShowRegistrationLinks?: boolean;
  IncludeModuleContacts?: boolean;
  LocationID?: number;
  // Calendar Event
  EventCategoryID?: number;
  IsAllDay?: boolean;
  StartHour?: number;
  StartMin?: number;
  StartPeriod?: number;
  EndHour?: number;
  EndMin?: number;
  EndPeriod?: number;

  // Contact Section
  ContactsLayoutID?: number;

  // Map Section
  MapHTML?: string;

  // Page Content Section
  PageContent?: string
  
  // Embed Form Section
  FormBuilderHTML?: string;

  // Sidebar Content Section
  Sidebar1Title?: string;
  Sidebar1Content?: string;

  // SEO Section
  SEOTitle?: string;
  SEODescription?: string;
  SEOKeywords?: string;

  CMSPageSiteMenuItems?: CMSSiteMenuItem[];
  MenuOnPage?: string;
  CMSPageResources?: number[];
  CMSPageContacts?: number[];
  CMSPageSocials?: CMSSocial[];

  // Top Gallery
  Photos1LayoutID: number;
  TopGalleryShowDeleted: boolean;
  CMSPageGallery1: CMSPageGalleryImage[];

  // Bottom Gallery
  Photos2LayoutID: number;
  BottomGalleryShowDeleted: boolean;
  CMSPageGallery2: CMSPageGalleryImage[];

  SiteID: number;

  TS: number;
}

export interface PageFormState {
  ActiveForm: PageActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
  showAddSiteMenuSidebar?: boolean;
  editSiteMenuId?: number;
  mobileExpandSectionIndex: number;
  sectionExpand: boolean[];
  processingImages?: boolean;
}

const getInitialState = () => {
  return {
    ActiveForm: {
      EventTypeID: 0,
    },
    ValidationRules: { ...FormDefinition },
    mobileExpandSectionIndex: 0,
    sectionExpand: [true, true, true, false, true, false, false, true, false, false, false],
  };
};

const selectedLocationIDSelector = (state: ApplicationState) => state.adminCMSSite.pages.page.form.ActiveForm.LocationID;

export const makeSelectedLocationSelector = () => {
  return createSelector(
    [locationsSelector, selectedLocationIDSelector],
    (locations: any[], locationId: number) => {
      if (locations && locationId) return locations.find((l) => l.ID === locationId);

      return null;
    }
  );
};

const selectedEventCategoryIDSelector = (state: ApplicationState) => {
  if (state.adminCMSSite.pages && state.adminCMSSite.pages.page && state.adminCMSSite.pages.page.form) return state.adminCMSSite.pages.page.form.ActiveForm.EventCategoryID;
  return -1;
};

export const makeSelectedEventCategorySelector = () => {
  return createSelector(
    [siteEventCategoriesSelector, selectedEventCategoryIDSelector],
    (eventCategories: Array<SiteEventCategory>, categorId: number) => {
      if (categorId === -1 || !eventCategories || eventCategories.length === 0) return null;

      return eventCategories.find((c) => c.ID === categorId);
    },
  );
};

const photoGallery1ShowDeletedSelector = (state: ApplicationState) => state.adminCMSSite.pages.page.form.ActiveForm.TopGalleryShowDeleted;
const photoGallery1Selector = (state: ApplicationState) => state.adminCMSSite.pages.page.form.ActiveForm.CMSPageGallery1 || [];
export const makeFilteredPhotoGallery1Selector = () => {
  return createSelector(
    [photoGallery1Selector, photoGallery1ShowDeletedSelector],
    filteredPhotoGalleryCombiner,
  );
};

const photoGallery2ShowDeletedSelector = (state: ApplicationState) => state.adminCMSSite.pages.page.form.ActiveForm.BottomGalleryShowDeleted;

const photoGallery2Selector = (state: ApplicationState) => state.adminCMSSite.pages.page.form.ActiveForm.CMSPageGallery2 || [];

export const makeFilteredPhotoGallery2Selector = () => {
  return createSelector(
    [photoGallery2Selector, photoGallery2ShowDeletedSelector],
    filteredPhotoGalleryCombiner,
  );
};

export const hasTempThumbnails = (rootState: ApplicationState) => {
  const form = rootState.adminCMSSite.pages.page.form.ActiveForm;

  const hasTemp1 = form.CMSPageGallery1 ? form.CMSPageGallery1.find((image) => !!image.tempId && image.ID === -1) : false;
  const hasTemp2 = form.CMSPageGallery2 ? form.CMSPageGallery2.find((image) => !!image.tempId && image.ID === -1) : false;

  return !!hasTemp1 || !!hasTemp2;
};

const pageResourcesIdSelector = (state: ApplicationState) => state.adminCMSSite.pages.page.form.ActiveForm.CMSPageResources;

export const makePageResourcesSelector = () => {
  return createSelector(
    [resourcesSelector, pageResourcesIdSelector, resourceCategoriesSelector],
    (resources: CMSResource[], resourceIds: number[], resourceCategories: CMSResourceCategory[]) => {
      if (!resources || !resourceIds) return [];
      const foundResources: CMSResource[] = [];
      resourceIds.forEach((rId) => {
        const resource = resources.find((r) => r.ID === rId);
        const resourceCategory = resource ? resourceCategories.find((rc) => rc.ID === resource.CategoryID) : null;

        if (!resourceCategory || resourceCategory.Inactive || !resource || resource.Inactive) return;
        
        foundResources.push(resource);
      });

      return foundResources.sort(compareDataByRowNum);
    }
  );
};

const pageContactsIdSelector = (state: ApplicationState) => state.adminCMSSite.pages.page.form.ActiveForm.CMSPageContacts;

export const makePageContactsSelector = () => {
  return createSelector(
    [contactsSelector, pageContactsIdSelector],
    (contacts: CMSContact[], contactIds: number[]) => {
      if (!contacts || !contactIds) return [];
      const foundContacts: CMSContact[] = [];
      contactIds.forEach((rId) => {
        const contact = contacts.find((r) => r.ID === rId);
        if (contact && !contact.Inactive) foundContacts.push(contact);
      });

      return foundContacts.sort(compareDataByRowNum);
    }
  );
};

const constructEmptyPageGalleryImage = (dataUrl: string | ArrayBuffer | null): CMSPageGalleryImage => {
  return {
    dataUrl,
    ID: -1,
    Ord: -1,
    Name: '',
    Description: '',
    attachPath: '',
    filePath: '',
    ImageOriginal: '',
    PublishDate: '',
    ExpireDate: '',
    LinkURL: '',
    IsLinkNewWindow: false,
    Inactive: false,
  };
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX);
const checkSubmitApiValidation = apiValidationReducerCreator(SUFFIX + UPDATE_CMS_PAGE_FORM_SUFFIX);

export const makeImageLoading = (gallery: CMSPageGalleryImage[] | undefined, id: number) => {
  if (!gallery) return [];
  return gallery.map((item) => {
    if (item.ID === id) {
      return {
        ...item,
        loading: true,
      };
    }
    return item;
  });
};

const processGallery = (gallery?: CMSPageGalleryImage[]) => {
  if (!gallery) return [];
  return [...gallery].map((item) => {
    const itemWithMoment = convertObjToMoment(item, ['PublishDate', 'ExpireDate']);
    return {
      ...item,
      PublishDate: itemWithMoment.PublishDate,
      ExpireDate: itemWithMoment.ExpireDate,
    };
  }).filter((item) => !item.tempId);
};

const setPage = (rootState: ApplicationState, state: PageFormState, cacheTwoPage: AdminCMSSiteCacheTwoPageState) => {
  const page = cacheTwoPage.CMSPage;
  const pageWithMoment = convertObjToMoment(page, ['StartDate', 'EndDate']);
  const socialMediasActiveForm = {...initSocialTypesActiveForm(rootState, cacheTwoPage.CMSPageSocials || [], rootState.adminCMSSite.cacheOne.SocialTypes)};

  const StartHour = pageWithMoment.StartDate ? pageWithMoment.StartDate.hours() : null;
  const StartMin = pageWithMoment.StartDate ? pageWithMoment.StartDate.minutes() : null;
  const EndHour = pageWithMoment.EndDate ? pageWithMoment.EndDate.hours() : null;
  const EndMin = pageWithMoment.EndDate ? pageWithMoment.EndDate.minute() : null;

  return {
    ...state,
    ActiveForm: {
      ...state.ActiveForm,
      ...page,
      StartDate: pageWithMoment.StartDate,
      StartHour: StartHour >= 0 ? processHour(StartHour) : 0,
      StartMin,
      StartPeriod: StartHour === null ? null : (StartHour > 11 ? 1 : 0),
      EndDate: pageWithMoment.EndDate,
      EndHour: EndHour >= 0 ? processHour(EndHour) : 0,
      EndMin,
      EndPeriod: EndHour === null ? null : (EndHour > 11 ? 1 : 0),
      CMSPageSiteMenuItems: cacheTwoPage.CMSPageSiteMenuItems,
      CMSPageResources: cacheTwoPage.CMSPageResources,
      CMSPageContacts: cacheTwoPage.CMSPageContacts,
      CMSPageGallery1: processGallery(cacheTwoPage.CMSPageGallery1),
      CMSPageGallery2: processGallery(cacheTwoPage.CMSPageGallery2),
      ...socialMediasActiveForm,
    },
    ValidationRules: {
      ...state.ValidationRules,
      ...getSocialMediasDefinition(rootState, cacheTwoPage.CMSPageSocials)
    },
  };
};

const PageFormReducer: Reducer<PageFormState> = (s, act: WithRootState<Action>) => {
  const state = checkSubmitApiValidation(checkApiValidation(s, act), act);
  if (act.type.includes(API_UPD) && act.type.includes(UPDATE_CMS_PAGE_FORM_SUFFIX)) {
    if (shouldSkipUpdateForm(act)) return state;

    let newState = {
      ...setDefaults(act.rootState, getInitialState(), state),
    };
    if (act.rootState.adminCMSSite.cacheTwoPage.CMSPage) {
      newState = setPage(act.rootState, newState, act.rootState.adminCMSSite.cacheTwoPage);
    }
    return newState;
  } else if (act.type === EditPageDeleteGalleryImageSubmitActions.requestType) {
    const imageId = (act as any).form.row.ID;
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        CMSPageGallery1: makeImageLoading(state.ActiveForm.CMSPageGallery1, imageId),
        CMSPageGallery2: makeImageLoading(state.ActiveForm.CMSPageGallery2, imageId),
      },
      processingImages: true,
    };
  } else if (act.type === EditPageAddTopGalleryImageSubmitActions.requestType || act.type === EditPageAddBottomGalleryImageSubmitActions.requestType) {
    return {
      ...state,
      processingImages: true,
    };
  } else if (act.type === EditPageDeleteGalleryImageSubmitActions.successType || act.type === EditPageAddTopGalleryImageSubmitActions.successType || act.type === EditPageAddBottomGalleryImageSubmitActions.successType || act.type === EditPageEditGalleryImageSubmitActions.successType || (isActionType(act, SetCacheAction) && (act.actions.requestType === EditPageEditGalleryImageSubmitActions.requestType))) {
    const action = <WithRootState<APISuccessSubmit>>act;
    const res = action.response.xhr.response;
    const hasMoreSequentialRequests = hasOngoingSequentialRequest(action.rootState);

    if (res.CMSPageImage) {
      // - EditPageEditGalleryImageSubmitActions,
      // - EditPageAddTopGalleryImageSubmitActions
      // - EditPageAddBottomGalleryImageSubmitActions
      // - EditPageDeleteGalleryImageSubmitActions
      const image = res.CMSPageImage as CMSPageGalleryImage;
      return {
        ...state,
        ActiveForm: {
          ...state.ActiveForm,
          CMSPageGallery1: updateImageInGallery(state.ActiveForm.CMSPageGallery1, image, action.extra ? action.extra.tempId : undefined, undefined, act.type === EditPageEditGalleryImageSubmitActions.successType),
          CMSPageGallery2: updateImageInGallery(state.ActiveForm.CMSPageGallery2, image, action.extra ? action.extra.tempId : undefined, undefined, act.type === EditPageEditGalleryImageSubmitActions.successType),
        },
        processingImages: hasMoreSequentialRequests > 1,
      };
    }
  } else if (act.type === EditPageUpdateSiteMenuItemSubmitActions.successType || (isActionType(act, SetCacheAction) && act.actions.requestType === EditPageUpdateSiteMenuItemSubmitActions.requestType)) {
    // Handle site menu update under edit page
    const action = <WithRootState<APISuccess>> act;
    if (!action.response || !action.response.response) {
      return state;
    }
    const res = action.response.xhr.response;
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        CMSPageSiteMenuItems: res.CMSPageSiteMenuItems,
      },
    };
  } else if (act.type === EditPageDeleteGalleryImageSubmitActions.failureType) {
    const action = <WithRootState<APIFailure>>act;
    const res = action.response.xhr.response;

    const body = JSON.parse(action.response.request.body);
    const imageId = body.row.ID;
    // `returnedImage` will only be set if 409
    const returnedImage = res.CMSPageImage as CMSPageGalleryImage;
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        CMSPageGallery1: handleDeleteImageFailure(state.ActiveForm.CMSPageGallery1, imageId, returnedImage),
        CMSPageGallery2: handleDeleteImageFailure(state.ActiveForm.CMSPageGallery2, imageId, returnedImage),
      },
      processingImages: hasOngoingSequentialRequest(action.rootState) > 1,
    };
  } else if (act.type === EditPageAddTopGalleryImageSubmitActions.failureType) {
    // remove thumbnail from top gallery if failed - not handled in cache node, because cache node will only get updated when EditPageAddTopGalleryImageSubmitActions succeeds
    const newState = {
      ...state,
      processingImages: hasOngoingSequentialRequest(act.rootState) > 1
    };
    newState.ActiveForm.CMSPageGallery1 = newState.ActiveForm.CMSPageGallery1 ? [...newState.ActiveForm.CMSPageGallery1] : [];

    handleAddImageFailure(newState.ActiveForm.CMSPageGallery1, (act as any).extra.tempId);
    
    return newState;
  } else if (act.type === EditPageAddBottomGalleryImageSubmitActions.failureType) {
    // remove thumbnail from bottom gallery if failed - not handled in cache node, because cache node will only get updated when EditPageAddBottomGalleryImageSubmitActions succeeds
    const newState = {
      ...state,
      processingImages: hasOngoingSequentialRequest(act.rootState) > 1
    };
    newState.ActiveForm.CMSPageGallery2 = newState.ActiveForm.CMSPageGallery2 ? [...newState.ActiveForm.CMSPageGallery2] : [];

    handleAddImageFailure(newState.ActiveForm.CMSPageGallery2, (act as any).extra.tempId);
    
    return newState;
  } else if (act.type === GetDefaultForPrimaryEventPageSubmitActions.successType) {
    const action = <WithRootState<APISuccess>>act;
    const res = action.response.xhr.response;
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        IsPrimaryEventPage: res.IsPrimaryEventPage,
      },
    };
  } else if (isActionType(act, UpdateSocialType)) {
    const newState = { ...state };
    const socialUserKey = act.vObj.key.replace('SocialTypeID', 'SocialUser');
    newState.ValidationRules[socialUserKey] = getSocialUserValidationRule(socialUserKey, {SocialTypeID: act.key, SocialUser: ''} as any);

    return newState;
  } else if (isActionType(act, MoveTopGalleryItem)) {
    let CMSPageGallery1 = state.ActiveForm.CMSPageGallery1;
    const index = CMSPageGallery1.findIndex((item) => item.ID === act.item.ID);
    
    if (act.offset !== 0 && index !== -1) {
      moveItemInArrayWithOffset(CMSPageGallery1, index, act.offset);
    }

    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        CMSPageGallery1: [...CMSPageGallery1],
      },
    };
  } else if (isActionType(act, MoveBottomGalleryItem)) {
    let CMSPageGallery2 = state.ActiveForm.CMSPageGallery2;
    const index = CMSPageGallery2.findIndex((item) => item.ID === act.item.ID);
    
    if (act.offset !== 0 && index !== -1) {
      moveItemInArrayWithOffset(CMSPageGallery2, index, act.offset);
    }

    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        CMSPageGallery2: [...CMSPageGallery2],
      },
    };
  } else if (isActionType(act, ToggleSectionExpand)) {
    if (act.inMobile) {
      return {
        ...state,
        mobileExpandSectionIndex: act.expand ? act.index : -1,
      };
    } else {
      return {
        ...state,
        sectionExpand: state.sectionExpand.map((item, index) => {
          if (index === act.index) return act.expand;
          return item;
        }),
      };
    }
  } else if (isActionType(act, ToggleAddSiteMenuSidebar)) {
    return {
      ...state,
      editSiteMenuId: undefined,
      showAddSiteMenuSidebar: act.show,
    };
  } else if (isActionType(act, ToggleEditSiteMenuSidebar)) {
    return {
      ...state,
      editSiteMenuId: act.editSiteMenuId,
      showAddSiteMenuSidebar: false,
    };
  } else if (isActionType(act, RemoveResourceFromPage)) {
    if (!state.ActiveForm.CMSPageResources) return state;
    const resources = [...state.ActiveForm.CMSPageResources];

    const index = resources.findIndex((r) => r === act.resourceId);
    if (index !== -1) resources.splice(index, 1);

    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        CMSPageResources: resources,
      },
    };
  } else if (isActionType(act, RemoveContactFromPage)) {
    if (!state.ActiveForm.CMSPageContacts) return state;
    const contacts = [...state.ActiveForm.CMSPageContacts];

    const index = contacts.findIndex((r) => r === act.contactId);
    if (index !== -1) contacts.splice(index, 1);

    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        CMSPageContacts: contacts,
      },
    };
  } else if (isActionType(act, AddContactsToPage)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        CMSPageContacts: [...act.contactIds],
      },
    };
  } else if (isActionType(act, AddResourcesToPage)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        CMSPageResources: [...act.resourceIds],
      },
    };
  } else if (isActionType(act, TopGalleryAddImage)) {
    const newState = {...state};

    newState.ActiveForm.CMSPageGallery1 = [...newState.ActiveForm.CMSPageGallery1];
    if (!act.options) {
      newState.ActiveForm.CMSPageGallery1.push({
        ...constructEmptyPageGalleryImage(act.dataUrl),
        uploading: true,
        tempId: act.tempId,
      });
    } else {
      newState.ActiveForm.CMSPageGallery1.push({
        ...constructEmptyPageGalleryImage(act.dataUrl),
        ...act.options,
        uploading: true,
        tempId: act.tempId,
      });
    }

    return newState;
  } else if (isActionType(act, BottomGalleryAddImage)) {
    const newState = {...state};

    newState.ActiveForm.CMSPageGallery2 = [...newState.ActiveForm.CMSPageGallery2];

    if (!act.options) {
      newState.ActiveForm.CMSPageGallery2.push({
        ...constructEmptyPageGalleryImage(act.dataUrl),
        uploading: true,
        tempId: act.tempId,
      });
    } else {
      newState.ActiveForm.CMSPageGallery2.push({
        ...constructEmptyPageGalleryImage(act.dataUrl),
        ...act.options,
        uploading: true,
        tempId: act.tempId,
      });
    }

    return newState;
  } else if (isActionType(act, SilentCancelAllAction) && !act.isDuringNavigation) {
    return {
      ...state,
      processingImages: false,
    };
  } else if (isActionType(act, RemoveSocial)) {
    const newState = {...state};
    
    return removeSocialTypeFromState(state, newState, act.pageSocialIndex);
  } else if (isActionType(act, AddSocial)) {
    let count = 0;
    for (let key of Object.keys(state.ActiveForm)) {
      if (key.includes('SocialTypeID')) {
        count += 1;
      }
    }
    const newState = { ...state };

    const socialTypes = getSocialTypesValue(act.rootState);
    newState.ActiveForm[`SocialTypeID${count}`] = socialTypes && socialTypes.length > 0 ? socialTypes[0].ID : 1;
    newState.ActiveForm[`SocialUser${count}`] = '';
    newState.ValidationRules[`SocialTypeID${count}`] = getSocialTypeValidationRule(`SocialTypeID${count}`);
    newState.ValidationRules[`SocialUser${count}`] = getSocialUserValidationRule(`SocialUser${count}`, {SocialTypeID: socialTypes && socialTypes.length > 0 ? socialTypes[0].ID : 1, SocialUser: ''} as any);

    return newState;
  } else if (isActionType(act, ClearAdminCMSCacheTwoPage) || isActionType(act, ClearAdminCMSSiteCacheBelowOne)) {
    return getInitialState();
  } else if (isActionType(act, ClearTempThumbnails)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        CMSPageGallery1: processGallery(state.ActiveForm.CMSPageGallery1),
        CMSPageGallery2: processGallery(state.ActiveForm.CMSPageGallery2),
      },
    };
  }
  return state || getInitialState();
};

export default PageFormReducer;