import * as React from 'react';
import '../../../../styles/elements/admin-class/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton, Vignette, Checkbox} from '../../index';
import ContextMenuComponent from './ContextMenu';
import { OptionsIcon, CloseIcon, AddedIcon } from '../../../Icons';
import { CardCategory } from '../../Card';
import { namespace as cardNS } from "../../Card/constants";
import { getImagePath } from '../../../../utils';
import { AdminEventChildClass, AdminEventClass } from '../../../../models/api/adminEventsCacheTwoEvent';
import { withRouter } from 'react-router';
import { isCardClicked } from '../../../../utils/navHelper';
import { ADMIN_EVENT_EDIT_CLASS_PATH, ADMIN_EVENT_NEW_CLASS_PATH } from '../../../../routes';
import { generateDOMId } from '../../../../utils/cypressHelper';
import { ModalTypes, isModalOpened } from '../../../../utils/modalHelper';
import { classTypeSelector } from '../../../../store/CacheZero/selectors';
import { getClassImage, isImageValid } from '../../../../utils/imageHelper';
import { reduxStoreService } from '../../../../store/service';

export const namespace = (): string => 'elements--admin-class--card';

export enum AdminClassCardType {
  CLASS_LIST='class-list',
  IMPORT_CLASS_SELECT='import-class-select',
  IMPORT_CLASS_SELECTED='import-class-selected',
  SINGLE_SELECT='single-select',
  SINGLE_SELECTED='single-selected',
} 

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin' | 'info' | 'mobile-full';
  type: AdminClassCardType;
  category: CardCategory;
  noBoxShadow?: boolean;
  selected?: boolean;
  adminEventClass: AdminEventClass;
  onEdit?: (adminEventClass: AdminEventClass) => void;
  onDelete?: (adminEventClass: AdminEventClass) => void;
  onRestore?: (adminEventClass: AdminEventClass) => void;
  onEnterRequirementsCompleted?: (adminEventClass: AdminEventClass, childClassId?: number) => void;
  onRemove?: (adminEventClass: AdminEventClass) => void;
  onExportInstructorClassRoster?: (adminEventClass: AdminEventClass, childClassId?: number) => void;
  onClick?: (adminEventParentClass: AdminEventClass, adminEventChildClass?: AdminEventChildClass) => void;
  onMultipleSelectClick?: (adminEventClass: AdminEventClass) => void;
  disabled?: boolean;
  routes?: any;
}


class AdminClassCard extends React.Component<Props> {
  shouldAllowPropagation = (e) => {
    const { routes } = this.props;
    const route = routes[routes.length - 1];
    const clickedOnCard = isCardClicked(e, namespace);
    return ((route.path === ADMIN_EVENT_EDIT_CLASS_PATH || route.path === ADMIN_EVENT_NEW_CLASS_PATH) && !clickedOnCard);
  };
  onEditClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onEdit, disabled, adminEventClass} = this.props;
    
    e.stopPropagation();
    !disabled && onEdit && onEdit(adminEventClass);
  };
  onRemoveClick = (e) => {
    const {disabled, onRemove, adminEventClass} = this.props;
    e.stopPropagation();
    !disabled && onRemove && onRemove(adminEventClass);
  };
  onDeleteClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onDelete, adminEventClass} = this.props;
    e.stopPropagation();
    !this.props.disabled && onDelete && onDelete(adminEventClass);
  };
  onRestoreClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onRestore, adminEventClass} = this.props;
    !this.props.disabled && e.stopPropagation();
    onRestore && onRestore(adminEventClass);
  };
  onEnterRequirementsCompleted = (e: any, childClassId?: number) => {
    if(this.shouldAllowPropagation(e)) return;
    !this.props.disabled && e.stopPropagation();
    const {onEnterRequirementsCompleted, adminEventClass} = this.props;
    onEnterRequirementsCompleted && onEnterRequirementsCompleted(adminEventClass, childClassId);
  };
  onExportInstructorClassRoster = (e, childClassId?: number) => {
    !this.props.disabled && e.stopPropagation();
    const {onExportInstructorClassRoster, adminEventClass} = this.props;
    onExportInstructorClassRoster && onExportInstructorClassRoster(adminEventClass, childClassId);
  };
  onMultipleSelectClick = (e) => {
    const {onMultipleSelectClick, adminEventClass} = this.props;

    if (adminEventClass.IsDuplicate) return;
    onMultipleSelectClick && onMultipleSelectClick(adminEventClass);
  };
  onClick = (e) => {
    const {onClick, type, adminEventClass} = this.props;

    if (type === AdminClassCardType.SINGLE_SELECT && adminEventClass.IsCombo) return;
    onClick && onClick(adminEventClass);
  };

  onChildClick = (e, adminChildClass) => {
    const {onClick, type, adminEventClass} = this.props;

    if (type === AdminClassCardType.SINGLE_SELECT && adminEventClass.IsCombo) {;
      onClick && onClick(adminEventClass, adminChildClass);
    }
  };
  onCardClick = (e) => {
    const {type, adminEventClass, disabled} = this.props;

    if (adminEventClass.Inactive || disabled || (!isModalOpened(ModalTypes.ENTER_CLASS_REQUIREMENT_COMPLETED) && !isModalOpened(ModalTypes.EXPORT_INSTRUCTOR_ROSTER) && adminEventClass.IsUnscheduled)) return;

    switch (type) {
      case AdminClassCardType.CLASS_LIST:
        this.onEditClick(e);
        break;
      case AdminClassCardType.IMPORT_CLASS_SELECT:
        this.onMultipleSelectClick(e);
        break;
      case AdminClassCardType.SINGLE_SELECT:
        this.onClick(e);
        break;
    }
  };

  getTitle = () => {
    const {adminEventClass} = this.props;
    const classType = classTypeSelector(reduxStoreService().getState(), adminEventClass.ClassTypeIDi);

    let title = '';

    if (adminEventClass.ClassCode) title += `${adminEventClass.ClassCode} - `;
    if (adminEventClass.IsCombo) title += adminEventClass.ComboName;
    else {
      if (classType) title += classType.Name;
    }
    
    return title;
  };

  getDetail = () => {
    const {adminEventClass} = this.props;

    return (
      <div className={`${namespace()}--main--content--detail`}>
          <div className={`${namespace()}--main--content--detail--col`}>{`Time Blocks: ${adminEventClass.TimeBlocksDisplay}`}</div>
          {adminEventClass.DaysDisplay && <div className={`${namespace()}--main--content--detail--col`}>{`Days: ${adminEventClass.DaysDisplay}`}</div>}
          <div className={`${namespace()}--main--content--detail--col`}>{`For: ${adminEventClass.IsYouth === -1 ? 'Everyone' : (adminEventClass.IsYouth === 0 ? 'Adults' : 'Youth')}`}</div>
      </div>
    );
  };

  renderContent = () => {
    const { adminEventClass, routes, disabled, selected, category, type, onRestore } = this.props;
    const route = routes[routes.length - 1];

    // NOTE: For Combo class, we dont need an image, and we ClassTypeIDi is null, so
    // we should skip calling `getClassImage` here
    const image = !adminEventClass.IsCombo ? getClassImage(adminEventClass) : null;
    return (
      <div className={`${namespace()}--main ${adminEventClass.IsCombo ? 'is-combo' : ''}`}>

        {type === AdminClassCardType.IMPORT_CLASS_SELECT ? 
          <Checkbox
            className={`${namespace()}--checkbox`}
            selected={!!selected}
            value={!!selected}
            disabled={adminEventClass.IsDuplicate}
            onChange={this.onMultipleSelectClick}
          /> : null}
        {image && <Vignette
            className={`${namespace()}--${type}-avatar`}
            borderRadius='48px'
            width={48}
            height={48}
            name={image.initial}
            color={image.color}
            image={isImageValid(image) ? getImagePath(image, "th150px_auto") : undefined}
          />}
        <div className={`${namespace()}--main--content`}>
          <div className={`${namespace()}--main--content--title`}>{this.getTitle()}</div>
          {!adminEventClass.IsUnscheduled && this.getDetail()}
          {adminEventClass.IsUnscheduled  && <div className={`${namespace()}--main--content--unscheduled`}>Unscheduled</div>}
        </div>
        {!adminEventClass.Inactive && type === AdminClassCardType.CLASS_LIST && !adminEventClass.IsCombo && adminEventClass.IsRegisterUnderIndividuals && <ActionButton
          icon={AddedIcon}
          className={`${namespace()}--added-icon`}
          onClick={(e) => this.onEnterRequirementsCompleted(e)}
        />}
        {!adminEventClass.Inactive && type === AdminClassCardType.CLASS_LIST && <ActionButton
          id={generateDOMId(`admin-class-${adminEventClass.IDi}-options`)}
          icon={OptionsIcon}
          allowPropagation={route.path === ADMIN_EVENT_EDIT_CLASS_PATH || route.path === ADMIN_EVENT_NEW_CLASS_PATH}
          className={`${namespace()}--options ${cardNS()}--options ${category}`}
          contextMenu={<ContextMenuComponent
            classIDi={adminEventClass.IDi}
            IsUnscheduled={adminEventClass.IsUnscheduled}
            IsRegisterUnderIndividuals={adminEventClass.IsRegisterUnderIndividuals}
            isParent={!!adminEventClass.IsCombo}
            onEdit={this.onEditClick}
            onDelete={this.onDeleteClick}
            onEnterRequirementsCompleted={(e) => this.onEnterRequirementsCompleted(e)}
            onExportInstructorClassRoster={(e) => this.onExportInstructorClassRoster(e)}
            />}
        />}
        {type === AdminClassCardType.SINGLE_SELECTED && <ActionButton
          disabled={disabled}
          className={`${namespace()}--close ${cardNS()}--close ${category}`}
          icon={CloseIcon}
          onClick={this.onRemoveClick}
        />}
        {adminEventClass.Inactive && type === AdminClassCardType.CLASS_LIST && onRestore && <Button className={`${cardNS()}--restore ${namespace()}--restore`} flat textColor="red" onClick={this.onRestoreClick}>RESTORE</Button>}
      </div>
    );
  };
  renderChildClasses = () => {
    const { adminEventClass, type, category } = this.props;

    if (!adminEventClass.ComboClasses) return null;
    return (
      <div className={`${namespace()}--child-classes`}>
        {adminEventClass.ComboClasses.map((comboClass) => {
          const image = getClassImage(comboClass);
          return (
            <div onClick={(e) => this.onChildClick(e, comboClass)} key={`child_class${comboClass.IDi}`} className={`${namespace()}--child-classes--card`}>
              {image && <Vignette
                className={`${namespace()}--${type}-avatar child-class`}
                width={32}
                height={32}
                borderRadius='32px'
                name={image.initial}
                color={image.color}
                image={isImageValid(image) ? getImagePath(image, "th150px_auto") : undefined}
              />}
              <div className={`${namespace()}--child-classes--card--title`}>{comboClass.Name}</div>
              {!adminEventClass.Inactive && type === AdminClassCardType.CLASS_LIST && <ActionButton
                icon={AddedIcon}
                className={`${namespace()}--added-icon`}
                onClick={(e) => this.onEnterRequirementsCompleted(e, comboClass.IDi)}
              />}
              {!adminEventClass.Inactive && type === AdminClassCardType.CLASS_LIST && <ActionButton
                icon={OptionsIcon}
                className={`${namespace()}--options ${cardNS()}--options ${category}`}
                contextMenu={<ContextMenuComponent
                  classIDi={adminEventClass.IDi}
                  IsUnscheduled={adminEventClass.IsUnscheduled}
                  isChild={true}
                  IsRegisterUnderIndividuals={adminEventClass.IsRegisterUnderIndividuals}
                  onEdit={this.onEditClick}
                  onDelete={this.onDeleteClick}
                  onEnterRequirementsCompleted={(e) => this.onEnterRequirementsCompleted(e, comboClass.IDi)}
                  onExportInstructorClassRoster={(e) => this.onExportInstructorClassRoster(e, comboClass.IDi)}
                  />}
              />}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const {category, noBoxShadow, selected, template, adminEventClass, type, routes} = this.props;
    let className = attribute(namespace(), undefined, this.props);

    if (adminEventClass.Inactive || adminEventClass.IsDuplicate) className += ' deleted';
    if (adminEventClass.IsUnscheduled && !isModalOpened(ModalTypes.ENTER_CLASS_REQUIREMENT_COMPLETED)) className += ' unscheduled';
    className += ` ${type}`;

    return (
      <Card
        noBoxShadow={noBoxShadow}
        className={className}
        component={<section/>}
        template={template || 'mobile-no-margin'}
        category={category}
        padding="none"
        selected={selected}
      >
        <div id={generateDOMId(`tentaroo-admin-class-${adminEventClass.IDi}`)} className={`${namespace()}--wrapper`} onClick={this.onCardClick}>
          {this.renderContent()}
          {adminEventClass.IsCombo && !adminEventClass.IsUnscheduled && this.renderChildClasses()}
        </div>
      </Card>
    );
  }
}

export default withRouter(AdminClassCard as any) as any;

/*

        {!option.Inactive && <ActionButton
          icon={OptionsIcon}
          className={`${namespace()}--options ${cardNS()}--options ${category}`}
          contextMenu={<ContextMenuComponent
            onEdit={this.onEditClick}
            onDelete={this.onDeleteClick}
            />}
        />}
        {option.Inactive && onRestore && <Button className={`${cardNS()}--restore ${namespace()}--restore`} flat textColor="red" onClick={this.onRestoreClick}>RESTORE</Button>}
*/