import {makeRequestEpic} from "../../../../Validation/epicCreator";
import {AddPageSubmitActions} from "./actions";
import {getUpdateCMSPageUrl, getEditPageRootUrl} from "../../../../../constants/urls";
import {AjaxResponse} from "rxjs/ajax";
import { ENTITY_CREATED, CMS_PAGE } from "../../../../../constants/messages/adminCMS";
import { CMSPage } from "../../../../../models/api/adminCMSCacheOne";
import { reduxStoreService } from "../../../../service";

export const adminCMSSiteAddPageEpic = makeRequestEpic(
  AddPageSubmitActions,
  getUpdateCMSPageUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    return ENTITY_CREATED(CMS_PAGE);
  },
  (response: AjaxResponse) => {
    const page = response.response.CMSPage as CMSPage;
    const state = reduxStoreService().getState();
    const site = state.adminCMSSite.cacheOne.CMSSite;

    if (page && site) {
      return getEditPageRootUrl({
        siteId: site.ID,
        domain: site.SiteDomain,
        pageId: page.ID,
        pageName: page.Name,
      });
    }

    return '';
  },
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  true,
);