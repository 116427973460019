import * as React from 'react';
import '../../../styles/elements/field-set/index.scss';
import { default as attribute, MarginProps } from '../../../styles';

export const namespace = (): string => 'elements--field-set';

export interface FieldSetProps extends MarginProps {
  children?: React.ReactNode;
  className?: string;
  name?: string;
  controls?: React.ReactNode;
  fontSize?: number;
  keepControlsPositionInMobile?: boolean;
  hideControlsInDesktop?: boolean;
  controlsNoMarginTop?: boolean;
  newDesign?: boolean;
  emptyFieldSet?: boolean;
  legendMarginBottom?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  fixedLegendFontSize?: boolean;
}

const FieldSet:React.SFC<FieldSetProps> = (props:FieldSetProps):React.ReactElement<FieldSetProps> => {
  const { fontSize, legendMarginBottom, className: classNameOverride, controlsNoMarginTop, fixedLegendFontSize, newDesign, emptyFieldSet, keepControlsPositionInMobile, hideControlsInDesktop } = props;

  let className = attribute(namespace(), classNameOverride, props);
  let legendClassName = `${namespace()}--legend`;
  if (fontSize) legendClassName += ` font-size-${fontSize}`;
  if (fixedLegendFontSize) legendClassName += ' fixed-font-size';

  const legendStyle: React.CSSProperties = {};
  const fieldsetStyle: React.CSSProperties = {};
  if (legendMarginBottom !== null) legendStyle.marginBottom = `${legendMarginBottom}px`;

  return (
    <fieldset style={fieldsetStyle} className={`${namespace()} ${className} ${newDesign ? 'new-design' : ''} ${emptyFieldSet ? 'empty' : ''}`}>
      {props.name && <div className={legendClassName} style={legendStyle}>{props.name}</div>}
      {props.controls && (
        <div className={`${namespace()}--controls ${keepControlsPositionInMobile ? 'keep-mobile' : ''} ${hideControlsInDesktop ? 'hide-desktop' : ''} ${controlsNoMarginTop ? 'no-margin-top' : ''}`}>
          {props.controls}
        </div>
      )}
      {props.children}
    </fieldset>
  );
};

export default FieldSet;
