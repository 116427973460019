import * as React from 'react';
import '../../../../styles/elements/cart/list/index.scss';

export const namespace = (): string => 'elements--cart--list';

export interface ListProps {
  children?: React.ReactNode;
  className?: string;
}

const List: React.SFC<ListProps> = ({className, children}: ListProps): React.ReactElement<ListProps> => (
  <ul className={namespace() + (className ? ` ${className}` : '')}>
    {React.Children.map(children, (child) => <li className={`${namespace()}--item`}>{child}</li>)}
  </ul>
);

export default List;
