import { getYearDefinition } from "../../../Admin/Modals/Accounts/validation";
import { AdminEventsReportType } from ".";
import { SelectValidator, Validator } from "../../../../utils/validator/models";

export interface IValidator {
  Year: SelectValidator;
  SelectedReportType: Validator;
}
export const FormDefinition: IValidator = {
  Year: {
    ...getYearDefinition('Year'),
    isRequired: () => true,
  },
  SelectedReportType: {
    key: 'SelectedReportType',
    isRequired: () => true,
    defaultValue: () => AdminEventsReportType.GROUPS,
  }
};