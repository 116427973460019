import * as React from 'react';
import {CardTitle, FeatureList, FeatureListItem} from '../../../Elements';
import '../../../../styles/pages/facilities/facility/index.scss';
import {FacilitiesAvailability, Feature} from "../../../../models/api/cacheTwoFacilties";

export interface Props {
  features?: Array<Feature> | null;
}


class FacilityFeatures extends React.Component<Props, {}> {
  public props: Props;

  render(): any {
    const features = this.props.features;
    if (!features || features.length === 0) return null;

    return [
      <CardTitle>Features</CardTitle>,
      <FeatureList>
        {features.map(f => <FeatureListItem available={f.IsAvailable}>{f.Name}</FeatureListItem>)}
      </FeatureList>
    ];
  };
}

export default FacilityFeatures;
