
import * as React from 'react';
import {useDropzone} from 'react-dropzone';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/file-uploader/index.scss';
import Text from "../Text";
import Button from '../Button';
import { CameraIcon, PageIcon } from '../../../components/Icons';
import { isMobile } from '../../../utils/isMobile';
import { BaseValidator } from '../../../utils/validator/models';

export const namespace = (): string => 'elements--file-uploader';

export interface FileUploaderProps {
  className?: string;
  value?: any;
  validationRules?: BaseValidator;
  hideError?: boolean;
  disabled?: boolean;
  onChange?: React.EventHandler<any>;
  maxFileSize?: number;
  supportedFormatsPrompt?: string;
  supportedFormat?: string;
  component?: React.ReactNode;
  title?: string;
  isImage?: boolean;
}

const FileUploader: React.SFC<FileUploaderProps> = ({...props}: FileUploaderProps): React.ReactElement<FileUploaderProps> => {
  const onFileChange = (files: any) => {
    props.onChange && props.onChange(files);
  };

  const onDragOver = (e) => {
    if (isMobile) return;
    const elem = document.getElementsByClassName('elements--file-uploader--upload-zone-wrapper')[0];
    const hasDragEnter = elem.classList.contains('dragover');

    if (!!!hasDragEnter) {
      elem.classList.add('dragover');
    }
  };
  const onDragLeave = (e) => {
    if (isMobile) return;
    const elem = document.getElementsByClassName('elements--file-uploader--upload-zone-wrapper')[0];
    const hasDragEnter = elem.classList.contains('dragover');
  
    if (!!hasDragEnter) {
      elem.classList.remove('dragover');
    }
  };

  const {validationRules, disabled, hideError, value, maxFileSize, supportedFormatsPrompt, supportedFormat, component, title, isImage} = props;

  const error = validationRules && validationRules.errors && !hideError ? validationRules.errors[0] : undefined;
  const maxFileSizeText = `Files up to ${maxFileSize}MB`;
  const onDrop = React.useCallback((files) => {
    onFileChange(files);
  }, []);
  const {getRootProps, getInputProps} = useDropzone({onDrop});

  const validValue = !!value && (value.ImageOriginal || value.dataUrl || value.Filename);

  return (
    <div className={`${mergeClassNames(namespace(), props)} ${error ? 'has-error' : ''}`}>
      {title && <div className={`${namespace()}--title`}>{title}</div>}
      <div className={`${namespace()}--container`}>
        {!validValue && isMobile &&
          <Button disabled={disabled} className={`${namespace()}--upload-btn`} color='white' textColor='black'>
            {isImage ? 'UPLOAD IMAGE' : 'UPLOAD FILE'}
            <input disabled={disabled} accept={supportedFormat} onChange={(e) => onFileChange(e.target.files)} type='file' />
          </Button>}
        {!validValue && !!maxFileSize && <Text additionalInfo className={`${namespace()}--max-filesize`}>{maxFileSizeText}</Text>}
        {!validValue && !!maxFileSize && <br />}
        {!validValue && <Text marginBottom={24} additionalInfo className={`${namespace()}--supported-format`}>{supportedFormatsPrompt}</Text>}
        {!validValue && !isMobile &&
          <div {...getRootProps()} onDragEnter={onDragOver} onDragOver={onDragOver} onDragLeave={onDragLeave} className={`${namespace()}--upload-zone-wrapper${disabled ? ' disabled' : ''}`}>
            {isImage ? <CameraIcon width="48px" height="48px" className={`${namespace()}--upload-file-icon`} /> : <PageIcon  width="48px" height="48px" className={`${namespace()}--upload-file-icon`} />}
            <Text additionalInfo className={`${namespace()}--uploader-text`}>Drag here or click to upload</Text>
            <input disabled={disabled} {...getInputProps()} accept={supportedFormat} multiple={false} onChange={(e) => onFileChange(e.target.files)} type='file' />
          </div>}
        {validValue && component}
        {error ? <div className={`${namespace()}--error`}>{error}</div> : null}
      </div>
    </div>
  );
};

export default FileUploader;