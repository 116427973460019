import * as React from 'react';
import { default as AppHeader } from '../../../App/Header';
import Nav from '../Nav';
import {withRouter, RouteComponentProps, WithRouterProps} from "react-router";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { getMergeProps } from '../../../../utils/reduxHelper';

interface Props {

}

type ConnectedProps = ReturnType<typeof mapStateToProps> &RouteComponentProps<{}, {}>;

const NewAccountHeader: React.SFC<Props> = ({session, router}: Props & ConnectedProps): React.ReactElement<Props> => {
  if (session.getLoginFormFailed) {
    return <AppHeader
      showDrawer
      title=""
    />;
  }
  if (!session.AllowSelfSignup) return <AppHeader showDrawer={false} title="" neverLoad hideIcon/>;
  return (
    <AppHeader
      centerTabs
      nav={<Nav selected={'new_account'} showNewAccount/>}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({ session: state.session });
const ConnectedNewAccountHeader = connect(
  mapStateToProps,
  null,
  getMergeProps<Props & WithRouterProps>(),
)(NewAccountHeader);

export default withRouter<Props>(ConnectedNewAccountHeader);
