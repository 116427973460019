import { ApplicationState } from '..';
import * as M from '../../constants/messages/generic';

/**
 * This file contains helpers for fields in AddGroup form.
 * 
 * Because those fields and the profile form are shared in multiple pages,
 * we use these helpers to help configure these fields' validationRules based
 * on the form they belong to
 */

import { UnitTypeID } from "../../models/api/options";

interface IUnitGenderRelatedFields {
  GroupTypeID?: number;
  UnitTypeID?: number;
  UnitGenderID?: number | null;
}

/**
 * Checks whether or not UnitGenderID field is shown in UI
 * 
 * We only show that field when `GroupTypeID === 1` AND UnitType is either Pack or
 * Troop
 * 
 * @param getActiveForm - callback to get the corresponding active form
 */
export const isUnitGenderIDShown = <T extends IUnitGenderRelatedFields>(getActiveForm: () => T) => {
  const ActiveForm = getActiveForm();

  return ActiveForm.GroupTypeID === 1 && (ActiveForm.UnitTypeID === UnitTypeID.PACK || ActiveForm.UnitTypeID === UnitTypeID.TROOP);
};

/**
 * Helper function to figure out correct values for UnitGender field.
 * 
 * We only show "Family Pack" (i.e. ID === 3) option when UnitType is "Packs"
 * 
 * @param getActiveForm - callback to get the corresponding active form
 * @param getUnitGenderValues - callback to get the unit gender values as list options
 */
export const getUnitGenderOptionValues = <T extends IUnitGenderRelatedFields>(rootState: ApplicationState, getActiveForm: () => T) => {
  const finalValues = [{ID: 0, Name: ""}];
  const allUnitGenders = getValues(rootState, "UnitGenders");
  const ActiveForm = getActiveForm();

  if (ActiveForm.UnitTypeID === UnitTypeID.PACK) {
    finalValues.push(...allUnitGenders);
  } else if (ActiveForm.UnitTypeID === UnitTypeID.TROOP) {
    finalValues.push(...allUnitGenders.filter((unitGender) => {
      return ActiveForm.UnitGenderID === 3 || unitGender.ID !== 3;
    }));
  }

  return finalValues;
};

/**
 * Returns customValidate method for UnitGender field in different forms
 * 
 * @param getActiveForm - callback to get the corresponding active form
 * @returns 
 */
export const getUnitGenderCustomValidate = <T extends IUnitGenderRelatedFields>(getActiveForm: () => T) => {
  const ActiveForm = getActiveForm();

  if (ActiveForm.UnitTypeID === UnitTypeID.TROOP && ActiveForm.UnitGenderID === 3) {
    return M.INVALID;
  }
};

/**
 * Return a list options from cacheZero.options for <Select /> field
 * 
 * TODO: Make `key` type of the specific keys under cacheZero.options, so that typing
 * can be automatically inferred
 * 
 * @param {string} key - key under cacheZero.options that are list options for some Select fields
 */
export const getValues = (rootState: ApplicationState, key) => {
  const opts = rootState.cacheZero.options;
  if (opts) return opts[key];
  return [];
};