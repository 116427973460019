import { CacheOneContext } from "@tentaroo/shared";

import { SaveState } from "../../store/Rollback/actions";
import { GetGroupCache } from "../../store/CacheOne/actions";
import { ApplicationState } from "../../store";
import { getGroupID } from "../helpers/endUserPageHelper";
import { CheckoutInit } from "../../store/Checkout/actions";
import { ProfileInitialize } from "../../store/Settings/Profile/actions";
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from "../dataHelper";
import { reduxStoreService } from '../../store/service';
import {isIdValid} from '../urlHelper';
import {ToggleNotFound} from '../../store/App/actions';
import { LoadEndUserCacheOneParams } from "./helpers/models";
import {isCacheZeroPopulated, isEndUserCacheOnePopulated} from "../cachePopulatedCheckers/endUser";


export const ensureCacheOne = (
  params: LoadEndUserCacheOneParams,
): boolean => {
  const {props, isStateNavigated, context} = params;
  const rootState = reduxStoreService().getState() as ApplicationState;
  const { cacheZero } = rootState;
  const { cacheOne } = rootState;

  // check for other missing data, this page
  if (!isEndUserCacheOnePopulated(cacheOne)) {
    const groupID = getGroupID(props);
    if (!isIdValid(groupID)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }

    reduxStoreService().dispatch(GetGroupCache.request({
      GroupIDi: groupID,
      GroupTS: 0,
      GetOptions: !isCacheZeroPopulated(cacheZero),
      GetGroupData: true,
    }));

    return true;
  } else {
    if (isStateNavigated) {
      if (context) {
        switch (context) {
          case CacheOneContext.EVENT_TYPES:
            if (!cacheOne.EventEventTypes) {
              reduxStoreService().dispatch(new ToggleNotFound());
            }
            break;
          case CacheOneContext.FACILITY_LOCATIONS:
            if (!cacheOne.FacilityLocations) {
              reduxStoreService().dispatch(new ToggleNotFound());
            }
            break;
          case CacheOneContext.CHECKOUT:
            reduxStoreService().dispatch(new CheckoutInit(reduxStoreService().getState()));
            reduxStoreService().dispatch(new ProfileInitialize(reduxStoreService().getState()));
            break;
          case CacheOneContext.PROFILE:
            reduxStoreService().dispatch(new ProfileInitialize(reduxStoreService().getState()));
            break;
        }
      }
      reduxStoreService().dispatch(new SaveState());
    }
  }
  return false;
};

export const getCacheOneEmptyCartBody = (params: any) => {
  const rootState = reduxStoreService().getState();

  let debugPayload: TentarooDebugPayload | undefined;
  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when getCacheOneEmptyCartBody");
  }

  if (!rootState.cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when getCacheOneEmptyCartBody");
  }
  const payload = {
    AppState: {
      EmptyCart: true,
      GroupIDi: rootState.cacheZero.options?.Group?.IDi,
      GroupTS: rootState.cacheZero.options?.Group?.TS,
      GetOptions: false,
      GetGroupData: true
    }
  };

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};