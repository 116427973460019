import * as React from 'react';
import { ContextMenu, ContextMenuItem } from '../..';
import { Props as ContextMenuProps } from '../../ContextMenu/index';

interface Props {
  onDashboard: (e) => void;
  onEdit: (e) => void;
  onReports: (e) => void;
  onDelete: (e) => void;
}

const ContextMenuComponent: React.SFC<ContextMenuProps & Props> = (props: ContextMenuProps & Props): React.ReactElement<ContextMenuProps & Props> => (
  <ContextMenu
    {...props}
    consistentInMobile
    title=""
  >
    <ContextMenuItem admin onClick={props.onDashboard}>Dashboard</ContextMenuItem>
    <ContextMenuItem admin onClick={props.onEdit}>Edit</ContextMenuItem>
    <ContextMenuItem admin onClick={props.onReports}>Reports</ContextMenuItem>
    <ContextMenuItem admin onClick={props.onDelete}>Delete</ContextMenuItem>
  </ContextMenu>
);

export default ContextMenuComponent;
