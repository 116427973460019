import * as React from 'react';
import {bindActionCreators} from 'redux';
import {RouteComponentProps, withRouter} from "react-router";
import {actionCreators} from '../../../../../store/Facilities/Trip/Reservation/actions';
import Type from './Type';
import Reservation from './Reservation';
import Confirmation from './Confirmation';
import {
  actionCreators as cacheFourActionsCreators,
} from "../../../../../store/CacheFourFacilities/actions";
import {makeSelectedFacilityTypeC4Selector} from "../../../../../store/CacheFourFacilities";
import {LoadingAll} from '../../../../Elements';
import { ApplicationState } from '../../../../../store';
import EndUserCacheManager from '../../../../../utils/cacheManagers/endUserCacheManager';
import { ClearCacheBelowThreeFacilities } from '../../../../../store/CacheThreeFacilities/actions';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isCacheFourFacilitiesPopulated} from '../../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {locId: string; tripId: string; name: string; resId?: string; resName?: string;}>
>;

@(withRouter as any)
class ReservationWizard extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;

  componentDidMount() {
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheFourFacilities({
          props: this.props,
          isStateNavigated,
        });
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    // We need to call the loaders on props change, so that redirection can happen
    // on logout.
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheFourFacilities({
          props: nextProps,
          isStateNavigated,
        });
      }
    );
  }

  componentWillUnmount() {
    const {actions, cacheFourFacilities} = this.props;
    // TODO: Later, make it consistent with admin module:
    // use `ClearCacheBelowThreeFacilities` to clear both form and caches, and always init on mount
    actions.reset();

    if (isCacheFourFacilitiesPopulated(cacheFourFacilities)) {
      reduxStoreService().dispatch(new ClearCacheBelowThreeFacilities());
    }
  }

  public render() {
    const {
      facilitiesReservation: {selectedPage}, cacheZero, cacheTwoFacilities, cacheThreeFacilities, cacheFourFacilities, apiLoading, inert,
      actions, apiLoadingMap, selectedFacilityType, params, user
    } = this.props;

    if (!isCacheFourFacilitiesPopulated(cacheFourFacilities)) return <LoadingAll/>;
    if (selectedPage === 'type') {
      return <Type
        inert={inert}
        apiLoading={apiLoading}
        user={user}
        selectedFacilityType={selectedFacilityType}
        apiLoadingMap={apiLoadingMap}
        cacheZero={cacheZero}
        cacheTwoFacilities={cacheTwoFacilities}
        cacheThreeFacilities={cacheThreeFacilities}
        cacheFourFacilities={cacheFourFacilities}
        parentActions={actions}
      />;
    } else if (selectedPage === 'reservation') {
      return <Reservation
        inert={inert}
        apiLoadingMap={apiLoadingMap}
        cacheZero={cacheZero}
        cacheTwoFacilities={cacheTwoFacilities}
        cacheThreeFacilities={cacheThreeFacilities}
        cacheFourFacilities={cacheFourFacilities}
        parentActions={actions}
        params={params}
      />;
    } else if (selectedPage === 'confirmation') {
      return <Confirmation
        inert={inert}
        apiLoadingMap={apiLoadingMap}
        cacheZero={cacheZero}
        cacheTwoFacilities={cacheTwoFacilities}
        cacheThreeFacilities={cacheThreeFacilities}
        cacheFourFacilities={cacheFourFacilities}
        parentActions={actions}
      />;
    }
    return null;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedFacilityType = makeSelectedFacilityTypeC4Selector();
  return {
    facilitiesReservation: state.facilities.trip.reservation.main,
    user: state.user,
    SystemSettings: state.session.SystemSettings,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    apiLoading: state.app.apiLoading,
    cacheFourFacilities: state.cacheFourFacilities,
    cacheThreeFacilities: state.cacheThreeFacilities,
    cacheTwoFacilities: state.cacheTwoFacilities,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    selectedFacilityType: selectedFacilityType(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...cacheFourActionsCreators
}, dispatch)});

const ConnectedReservationWizard = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(ReservationWizard);

export default ConnectedReservationWizard;

