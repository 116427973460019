import {Location} from "history";
import { createValidateActions, createApiValidateActions, createApiSubmitActions, createUpdateValueMethod, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, createRequestActions, getCacheLevelExtra } from '../../../../../store/Validation/actionCreator';
import { ApplicationState, ActionCreator } from '../../../../../store';
import { scrollModalToTop } from '../../../../../utils/helpers/adminCMSPageHelper';
import { navPush } from '../../../../../utils/navHelper';
import { ADMIN_EVENT_TYPES_SUFFIX, ADMIN_EVENTS_MODULE_SUFFIX, UPDATE_EVENT_TYPE_FORM_SUFFIX, ClearAllAdminEventsCache } from '../../../CacheOne/actions';
import { EventTypeFormModalNamespace } from '../../EventType/Form/actions';
import { getAddAdminEventTypeBody, GetAdminEventTypesCacheBodyParams, getEventsHomeRootUrl } from '../../../../../constants/adminEventsUrls';
import { SaveState } from '../../../../Rollback/actions';
import { typeName, Action } from '../../../../../utils/StrongActions';
import { InjectedRouter } from 'react-router';
import { isAdminCMSSitePage, isAdminEventsPage, isAdminFacilityLocationPage, isEndUserPage } from '../../../../../constants/urls';
import { ClearAllAdminCMSSiteCache } from "../../../../AdminCMSSite/CacheOne/actions";
import { ClearAllAdminFacilityLocationCache } from "../../../../AdminFacilityLocation/CacheOne/actions";
import { ClearCacheBelowOne } from "../../../../CacheOne/actions";
import {EventsEventTypeGeneral} from "../../../../../models/api/adminEventsCacheOne";
import {isEndUserCacheOnePopulated, isEndUserCacheTwoPopulated} from "../../../../../utils/cachePopulatedCheckers/endUser";

export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + UPDATE_EVENT_TYPE_FORM_SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + '__NEW_EVENT_TYPE_FORM';
export const GET_SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + UPDATE_EVENT_TYPE_FORM_SUFFIX + '__NEW_EVENT_TYPE_FORM';

export const AddEventTypeSubmitActions = createApiSubmitActions(SUFFIX);
export const GetEventTypeAction = createRequestActions(GET_SUFFIX + '__GET_EVENT_TYPE__DUPLICATE__');

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

@typeName('INIT' + SUFFIX)
export class InitAction extends Action { constructor(public eventType?: EventsEventTypeGeneral) { super(); } }
@typeName('RESET' + SUFFIX)
export class ResetAction extends Action { constructor() { super(); } }

// will submit data to cache one event type
@typeName("CLIENT_SUBMIT_FORM" + SUFFIX) export class ClientSubmitForm extends Action { constructor(public data: any) { super(); }}

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateEventTypeGeneralValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.eventTypes.modals.newEventType.General),
  simpleUpdateEventTypeGeneral: createSimpleUpdateValueMethod(ValidateActions),
  init: (initFromCurrentEventType?: boolean): ActionCreator => (dispatch, getState) => {
    const state = getState();
    
    if (initFromCurrentEventType) {
      dispatch(new InitAction(state.adminEvents.cacheOne.EventsEventType?.General));
    } else {
      dispatch(new InitAction());
    }
    
    dispatch(new SaveState());
  },
  reset: (): ActionCreator => dispatch => dispatch(new ResetAction()),
  apiSubmitForm: (router: InjectedRouter, location: Location, SourceEventTypeID?: number): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const valid = innerApiSubmitFormMethod(
      dispatch,
      AddEventTypeSubmitActions,
      (s: ApplicationState) => s.adminEvents.eventTypes.modals.newEventType.General,
      undefined,
      true,
      true,
      true,
      undefined,
      undefined,
    );

    if (valid) {

      const body = getAddAdminEventTypeBody(state.adminEvents.eventTypes.modals.newEventType.General.ActiveForm, SourceEventTypeID);

      /**
       * We need to do this cache clear here because `NewEventType` modal can be opened in any page!
       * The cache clear logic in ComponentUpdateTemplate will not be run during save
       * 
       * If it's being opened under EndUser module, do ClearCacheBelowOne if cache two is populated
       */
      if (isAdminCMSSitePage(location.pathname)) {
        dispatch(new ClearAllAdminCMSSiteCache());
      } else if (isAdminFacilityLocationPage(location.pathname)) {
        dispatch(new ClearAllAdminFacilityLocationCache());
      } else if (isAdminEventsPage(location.pathname)) {
        dispatch(new ClearAllAdminEventsCache());
      } else if (
        isEndUserCacheOnePopulated(state.cacheOne) &&
        isEndUserCacheTwoPopulated()
      ) {
        dispatch(new ClearCacheBelowOne());
      }

      navPush(router, getEventsHomeRootUrl({
        eventTypeId: -1,
        eventTypeName: state.adminEvents.eventTypes.modals.newEventType.General.ActiveForm.Name as string,
      }));

      // Unlike NewPage/DuplicatePage, NewEventType and DuplicateEventType could happen in any
      // page... so we have to make sure all these local changes for optimistic save below happen
      // _AFTER_ the SaveState in `routerWillLeave` (if there is one).
      // A SaveState is made by `innerApiSubmitFormMethod` above, and another SaveState will be done by
      // `routerWillLeave` if we are leaving a form. so the worst case is that we will have two
      // Save States at this point.
      dispatch(new ResetAction());
      dispatch(new ClientSubmitForm(state.adminEvents.eventTypes.modals.newEventType.General.ActiveForm));
      dispatch(AddEventTypeSubmitActions.request(
        body,
        getCacheLevelExtra(false, true),
      ));

      return true;
    } else {
      scrollModalToTop(EventTypeFormModalNamespace());
      return false;
    }
  },
  getEventType: (params: GetAdminEventTypesCacheBodyParams): ActionCreator => dispatch => dispatch(GetEventTypeAction.request(params)),
};