import {ReportAuditLogActions,} from './actions';
import {getAuditLogUrl} from '../../../../constants/urls';
import {makeRequestEpic} from '../../../Validation/epicCreator';

export const reportAuditLogEpic = makeRequestEpic(
  ReportAuditLogActions,
  getAuditLogUrl,
  undefined,
  true
);
