import {makeRequestEpic} from "../../../Validation/epicCreator";
import {AddTripSubmitActions, DeleteTripSubmitActions} from "./actions";
import {getUpdateTripUrl, URLS} from "../../../../constants/urls";
import {AjaxResponse} from "rxjs/ajax";
import {FacilityTrip} from "../../../../models/api/cacheThreeFacilities";
import {spaceTo_} from "../../../../utils";
import { captureTentarooError } from "../../../../utils/dataHelper";
import { reduxStoreService } from "../../../service";

export const addTripEpic = makeRequestEpic(
  AddTripSubmitActions,
  getUpdateTripUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      if (body.AppState.FacilityTripID) {
        return 'The trip was successfully edited';
      }
      return 'Trip has been added. Please add reservations to complete your booking.';
    } catch (e) {
      captureTentarooError(new Error('Unable to parse request body for add/edit trip!'));
      return '';
    }
  },
  (response: AjaxResponse) => {
    const FacilityTrip = response.response.FacilityTrip as FacilityTrip;
    const locId = reduxStoreService().getState().cacheTwoFacilities.locationID;
    const qp = reduxStoreService().getState().routing.locationBeforeTransitions.search;

    return `${URLS.FACILITY_TRIP}/${locId}/${FacilityTrip.FacilityTripID}/${spaceTo_(FacilityTrip.Name)}${qp}`;
  }
);

export const deleteTripEpic = makeRequestEpic(
  DeleteTripSubmitActions,
  getUpdateTripUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    return 'The trip was successfully deleted';
  },
);
