import * as React from 'react';
import '../../../styles/elements/map/index.scss';
import Marker from '../../../images/elements/map/marker.svg';
import {compose, withProps} from "recompose";
import {GoogleMapsDefaultOptions} from "../../../utils/googleMaps";
import { getDomain } from '../../../utils/urlHelper';

export const namespace = (): string => 'elements--map';


interface Props {
  location?: any;
  ready?: boolean;
  width?: number | string;
  height?: number | string;
  marginBottom?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  address: string;
}

class Map extends React.PureComponent<Props, {}> {

  props: Props;

  componentDidMount() {
    window.addEventListener('resize', () => this.forceUpdate());
  }

  componentWillUnmount() {
    (window as any).removeEventListener('resize');
  }

  onClickImage = () => {
    let plusAddress = this.props.address.replace(/ /g, '+');
    window.open(`https://www.google.com/maps/place/${plusAddress}`, '_blank');
  };

  render() {
    const {ready, width, height, marginBottom} = this.props;
    const containerStyle: React.CSSProperties = {
      cursor: 'pointer'
    };
    if (width) containerStyle.width = typeof width === 'string' ? width : `${width}px`;
    if (height) containerStyle.height = typeof height === 'string' ? height : `${height}px`;
    if (marginBottom) containerStyle.marginBottom = `${marginBottom}px`;
    let plusAddress = this.props.address.replace(/ /g, '+');
    let mapWidth = 320;
    if (window.innerWidth < 640) {
      mapWidth = window.innerWidth;
    }

    const domain = getDomain(false);

    return (
      <div className={`${namespace()}--map-container`} style={containerStyle}>
        <img src={`https://maps.googleapis.com/maps/api/staticmap?zoom=15&size=${mapWidth}x240&maptype=roadmap
&markers=icon:${domain}/admin2/marker.png%7C${plusAddress}&scale=2
&key=${GoogleMapsDefaultOptions.key}`} onClick={this.onClickImage} style={{width: mapWidth}}/>
      </div>
    );
  }
}

export default Map;
