import {ActionCreator} from '../';
import {CLEAR_CACHE_PREFIX, createApiSubmitActions, createRequestActions} from '../Validation/actionCreator';
import {
  CustomFieldPost,
  EVENT,
  eventParticipantsInitBody,
  eventParticipantsRecalcBody,
  eventParticipantsSaveBody,
  getGroupRosterPersonBody,
  GetGroupRosterPersonParams,
  ParticipantsLoadParams,
  ParticipantsRecalcParams,
  ParticipantsSaveParams
} from "../../constants/urls";
import {checkClasses} from "../../utils/classesHelper";
import {SaveState} from "../Rollback/actions";
import {EventRegisterParticipantsShowError} from "../Events/Event/Register/Participant/Classes/actions";
import {validateAll} from "../../utils/validator";
import {ApiSubmitParticipantsConfirmtationActions} from "../Events/Event/Register/Participant/Confirmation/actions";
import {push} from "react-router-redux";
import {getTotalAmount} from "../../utils/helpers/financialSummaryHelper";
import {composeClassRegistrations} from "../CacheFourEventsNumbers/actions";
import {EventRegistrationPersonCustomField, PostEventRegistrationClass} from "../../models/api/cacheFourEvents";
import {makeAdultsCustomFieldsSelector, makeAvailableClassesFilter, makeYouthCustomFieldsSelector} from "./index";
import { captureTentarooError } from '../../utils/dataHelper';
import { typeName, Action } from '../../utils/StrongActions';

export const SUFFIX = '__CACHE_FOUR_EVENTS_PARTICIPANTS';
export const EventParticipantCalc = createRequestActions(SUFFIX);

export const SUFFIX_SELECT = '__CACHE_FOUR_EVENTS_PARTICIPANTS_SELECT';
export const EventParticipantsGetPerson = createRequestActions(SUFFIX_SELECT);

export const SUFFIX_SAVE = '__CACHE_FOUR_EVENTS_PARTICIPANTS_SAVE';
export const EventParticipantsSave = createApiSubmitActions(SUFFIX_SAVE);

@typeName(`UNSELECT${SUFFIX_SELECT}`)
export class UnselectParticipant extends Action {}

@typeName(`${CLEAR_CACHE_PREFIX}_CACHE${SUFFIX_SELECT}`)
export class ClearCacheFourEventParticipants extends Action {}

@typeName(`POST_CALC_VALIDATE${SUFFIX_SELECT}`)
export class PostCalcValidate extends Action {}


export type Actions = typeof actionCreators;

export const actionCreators = {
  participantsLoad: (params: ParticipantsLoadParams): ActionCreator => dispatch => {
    dispatch(EventParticipantCalc.request(eventParticipantsInitBody(params)));
  },
  participantsRecalc: (params: ParticipantsRecalcParams): ActionCreator => dispatch => {
    dispatch(EventParticipantCalc.request(eventParticipantsRecalcBody(params)));
  },
  participantsSave: (): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const confirmationValidation = validateAll(s => s.events.event.register.participant.confirmation);

    if (confirmationValidation) {
      dispatch(ApiSubmitParticipantsConfirmtationActions.clientFailure(confirmationValidation, false));
      return;
    }

    const c2 = getState().cacheTwoEvents;
    const c4 = getState().cacheFourEventsParticipants;
    const {ActiveForm} = getState().events.event.register.participant.roster;
    const isAdmin = !!getState().user.user.str_permissions.hasAdminAccess;
    const availableClasses = makeAvailableClassesFilter();

    if (c4.isYouth === undefined) {
      captureTentarooError(new Error("c4.isYouth is undefined when performing perticipantsSave"));
      return;
    }
    if (!c2.EventTypeRegistrationSettings) {
      captureTentarooError(new Error("c2.EventTypeRegistrationSettings not available when performing perticipantsSave"));
      return;
    }
    if (!c4.EventRegistrationPaymentStatus) {
      captureTentarooError(new Error("c4.EventRegistrationPaymentStatus not available when performing perticipantsSave"));
      return;
    }
    if (!c4.EventRegistrationPerson) {
      captureTentarooError(new Error("c4.EventRegistrationPerson not available when performing perticipantsSave"));
      return;
    }
    const classValidation = checkClasses(
      c4.EventClassesIndividualRegistered,
      c4.EventClassesIndividualAvailable,
      availableClasses(getState()),
      c4.isYouth,
      false,
      c2.EventTypeRegistrationSettings.NamesRegistrationSettings.EnableClassWaitingList,
      c4.isYouth ? 1 : 0,
      c4.isYouth ? 0 : 1,
      isAdmin,
      ActiveForm.DOB
    );
    if (classValidation.warnings.length > 0 && !isAdmin) {
      dispatch(new EventRegisterParticipantsShowError(classValidation.warnings[0].message, classValidation.error));
    }

    const options = getState().cacheZero.options;
    const eventTypeID = getState().cacheTwoEvents.eventTypeID;
    const eventID = getState().cacheThreeEvents.eventID;
    const selectedTypeID = getState().events.event.register.participant.type.selectedTypeID;
    const classRegistrations: Array<PostEventRegistrationClass> = composeClassRegistrations(c4.EventClassesIndividualRegistered);

    if (!options) {
      captureTentarooError(new Error("cacheZero.options not available when performing perticipantsSave"));
      return;
    }

    if (!options.Group) {
      captureTentarooError(new Error("Group not available when performing perticipantsSave"));
      return;
    }

    let newAmount: undefined | number = getTotalAmount(
      getState().events.event.register.participant.confirmation.ActiveForm,
      c4.EventRegistrationPaymentStatus
    );

    const personForm = {...getState().events.event.register.participant.roster.ActiveForm};
    const savePerson: any = {};
    Object.keys(personForm).forEach((k) => {
      // `isNaN(Number(k))` here is to skip pulling custom fields' values to savePerson
      if (isNaN(Number(k)) && k !== 'IDi' && k !== 'IsYouth') {
        savePerson[k] = personForm[k];
      }
    });

    const CustomFormFieldEntries: Array<CustomFieldPost> = [];
    if (c4.GroupRosterPerson) {
      if (c4.EventRegistrationPersonCustomFields) {
        c4.EventRegistrationPersonCustomFields.forEach((cf: EventRegistrationPersonCustomField) => {
          if (cf.Type === 'combobox' || cf.Type === 'textinput' || cf.Type === 'yesno') {
            const entry = {
              ElementID: cf.ElementID,
              Value: personForm[cf.ElementID] !== undefined ? personForm[cf.ElementID] : ''
            };
            CustomFormFieldEntries.push(entry);
          }
        });
      }
    } else {
      if (c4.isYouth) {
        const selector = makeYouthCustomFieldsSelector();
        const youthCustomFields = selector(getState());
        if (youthCustomFields) {
          youthCustomFields.forEach((cf: EventRegistrationPersonCustomField) => {
            if (cf.Type === 'combobox' || cf.Type === 'textinput' || cf.Type === 'yesno') {
              const entry = {
                ElementID: cf.ElementID,
                Value: personForm[cf.ElementID] !== undefined ? personForm[cf.ElementID] : ''
              };
              CustomFormFieldEntries.push(entry);
            }
          });
        }
      } else {
        const selector = makeAdultsCustomFieldsSelector();
        const adultCustomFields = selector(getState());
        if (adultCustomFields) {
          adultCustomFields.forEach((cf: EventRegistrationPersonCustomField) => {
            if (cf.Type === 'combobox' || cf.Type === 'textinput' || cf.Type === 'yesno') {
              const entry = {
                ElementID: cf.ElementID,
                Value: personForm[cf.ElementID] !== undefined ? personForm[cf.ElementID] : ''
              };
              CustomFormFieldEntries.push(entry);
            }
          });
        }
      }
    }

    const route = getState().routing.locationBeforeTransitions;
    const sp = route.pathname.split('/');
    // /events/4/5288/Week_1_2018/register/participant
    let personIDi: undefined | number = undefined;

    let HasMultiWeekDiscount = c4.EventRegistrationPerson.HasMultiWeekDiscount;
    const rootState = getState();
    if (sp.length === 8) { // then we're adding
      if (c4.GroupRosterPerson) {
        personIDi = c4.GroupRosterPerson.IDi;
      }
      const pType = !rootState.cacheFourEventsParticipants.EventRegistrationParticipantTypes ? null : rootState.cacheFourEventsParticipants.EventRegistrationParticipantTypes.find(pt => pt.ID === selectedTypeID);
      if (!pType) {
        captureTentarooError(new Error('pType not found when saving participant'));
      } else {
        if (pType.HasMultiWeekDiscount !== undefined) {
          HasMultiWeekDiscount = pType.HasMultiWeekDiscount;
        }
      }
    }

    const params: ParticipantsSaveParams = {
      GroupIDi: options.Group.IDi,
      GroupTS: options.Group.TS,
      EventTypeID: eventTypeID,
      EventIDi: eventID,
      GroupWeekIDi: c4.EventRegistrationPaymentStatus.IDi,
      ClassRegistrations: classRegistrations,
      PersonIDi: personIDi,
      IsYouth: c4.isYouth,
      ParticipantTypeID: selectedTypeID,
      EventRegistrationPerson: c4.EventRegistrationPerson,
      AmountChangeInCart: c4.EventRegistrationPaymentStatus.AmountChangeInCart,
      TotalAmountChange: 0,
      GroupRosterPerson: savePerson,
      CustomFormFieldEntries: CustomFormFieldEntries,
      ParticipantRegistrationIDi: c4.EventRegistrationPerson.IDi ? c4.EventRegistrationPerson.IDi : null,
      HasMultiWeekDiscount: HasMultiWeekDiscount,
      ParticipantTypes: !rootState.cacheThreeEvents.EventInfoParticipantTypes ? [] : rootState.cacheThreeEvents.EventInfoParticipantTypes.map((pType) => pType.ID),
    };

    if (newAmount !== undefined) {
      params.TotalAmountChange = newAmount - c4.EventRegistrationPaymentStatus.AmountInCart;
    }

    const body = eventParticipantsSaveBody(params);
    const newPath = `/${sp[1]}/${sp[2]}/${sp[3]}/${sp[4]}/${sp[5]}/${EVENT.REGISTRATION}`;
    // This navigation will trigger `clearCacheBelowThreeEvents` in Register/Participant/index.tsx -> componentWillUnmount,
    // which will then trigger SavePoint during save
    dispatch(push(`${newPath}${route.search}`));
    dispatch(EventParticipantsSave.request(
      body,
      {
        isCacheThreeEventLoaded: true,
        leavingCacheLevelOnSuccess: undefined,
        inMatchingCacheLevelOn409: undefined,
      }),
    );
  },
  getPerson: (params: GetGroupRosterPersonParams): ActionCreator => dispatch => {
    dispatch(EventParticipantsGetPerson.request(getGroupRosterPersonBody(params)));
  },
  unselectPerson: (): ActionCreator => dispatch => dispatch(new UnselectParticipant()),
  clearCache: (): ActionCreator => dispatch => dispatch(new ClearCacheFourEventParticipants()),
};
