import { Reducer } from 'redux';
import { Product } from '../../../../models/product';
import { BSOATShirt, CentennialPants } from '../../../../samples/products';

export interface EventProductsState {
  Products: Array<Product>; // @todo: reselect
  UnavailableProducts: Array<Product>;
}

const sampleProducts: Array<Product> = [];
const sampleUnvailableProducts: Array<Product> = [];

sampleProducts.push(CentennialPants);
sampleProducts.push(BSOATShirt);

const unavailableBSOA = {...BSOATShirt};
(unavailableBSOA as any).Sizes = [];
sampleUnvailableProducts.push(unavailableBSOA);

const EventProducts: Reducer<EventProductsState> = (state) => {
  return state || { Products: sampleProducts, UnavailableProducts: sampleUnvailableProducts };
};

export default EventProducts;
