import * as React from 'react';
import {
  Modal, ModalActions, Button, ModalContent, ModalHeader, Row, Column, Switch, Select, EmptyMessage, AdminClassCard, PageLoader } from '../../../../../Elements';
import {withRouter, RouteComponentProps} from "react-router";
import {bindActionCreators} from "redux";
import {actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import {actionCreators as rollbackActionCreators} from "../../../../../../store/Rollback/actions";
import {actionCreators as exportInstructorRosterActionCreators} from "../../../../../../store/AdminEvents/Events/Modals/ExportInstructorRoster/actions";
import {actionCreators as cacheTwoEventActionCreators, GetAdminEvent} from "../../../../../../store/AdminEvents/CacheTwoEvent/actions";
import {actionCreators as dashboardActionCreators} from "../../../../../../store/AdminEvents/Events/Event/Dashboard/actions";
import {ApplicationState} from "../../../../../../store";
import '../../../../../../styles/pages/admin-events/events/modals/export-instructor-roster/index.scss';
import { makeFilteredClassesSelector } from '../../../../../../store/AdminEvents/Events/Modals/ExportInstructorRoster';
import { AdminEventChildClass, AdminEventClass } from '../../../../../../models/api/adminEventsCacheTwoEvent';
import { ClassTypesIcon } from '../../../../../Icons';
import { EmptyMessageType } from '../../../../../Elements/EmptyMessage';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { ADMIN_EVENT_CLASSES } from '../../../../../../constants/messages/adminEvents';
import { AdminClassCardType } from '../../../../../Elements/AdminClass/Card';
import { CardCategory } from '../../../../../Elements/Card';
import { extractRouteParams } from '../../../../../../utils/urlHelper';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { ModalHeight } from '../../../../../Elements/Modal';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { getEventIdFromPath, IAdminEventRouterParams } from '../../../../../../utils/helpers/adminEventsPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { classTypeSelector } from '../../../../../../store/CacheZero/selectors';
import { reduxStoreService } from '../../../../../../store/service';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

const namespace = () => 'pages--events--modal--export-instructor-roster';

type Props = WithInertAttribute<{}>

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminEventRouterParams,{}>;

@(withRouter as any)
class ExportInstructorRosterModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  private listRef;

  componentDidMount() {
    if (shouldBlockActions()) return;

    this.props.actions.init(this.props.selectedClassId, this.props.childClassId);
  }

  componentWillUnmount() {
    this.onClose();
  }

  onClose = () => {
    this.props.actions.resetExportInstructorRosterModal();
  };
  
  onContinue = (e?: any) => {
    if (e) e.stopPropagation();
    const {reportURL, actions, cacheTwoEvent, modalState: {ActiveForm}, cacheOne, dashboardActions} = this.props;
    const generateEventReportsPath = !ActiveForm.SelectedClass ? reportURL : "ExportInstructorClassRoster";
    if (!cacheOne.EventsEventType || !cacheTwoEvent.EventsEvent || !generateEventReportsPath) return;


    actions.popModal(true, false, ModalTypes.EXPORT_INSTRUCTOR_ROSTER);
    this.props.actions.resetExportInstructorRosterModal();
    if (!ActiveForm.SelectedClass) {
      dashboardActions.generateEventReports(
        generateEventReportsPath,
        cacheOne.EventsEventType.EventTypeRow.ID as number,
        cacheTwoEvent.EventsEvent.IDi,
        !!ActiveForm.IncludeBlankRows,
        undefined,
        undefined,
        true,
      );
    } else {
      dashboardActions.generateEventReports(
        generateEventReportsPath,
        cacheOne.EventsEventType.EventTypeRow.ID as number,
        cacheTwoEvent.EventsEvent.IDi,
        !!ActiveForm.IncludeBlankRows,
        undefined,
        ActiveForm.SelectedClass.IDi,
        true,
      );
    }
  };

  onBack = () => {
    const { actions } = this.props;

    this.onSearchBack();
    actions.resetStep2();
  };

  scrollToTop = () => {
    if (this.listRef) this.listRef.scrollTop = 0;
  };

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    this.props.actions.toggleFilter(false);
    this.props.actions.filterClass('');
  };
  onRefreshClick = () => {
    const {actions, modalState: {ActiveForm}} = this.props;

    const eventId = getEventIdFromPath(this.props.params);
    actions.saveState();
    actions.refreshEvent({
      EventIDi: eventId,
      GetClasses: true,
      ...extractRouteParams(this.props),
    }, false);
  };

  renderEmptyClasses = () => {
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        admin
        icon={ClassTypesIcon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={ENTITY_NOT_FOUND(ADMIN_EVENT_CLASSES)}
      />
    );
  };

  onSelectClass = (adminEventParentClass: AdminEventClass, adminEventChildClass?: AdminEventChildClass) => {
    if (adminEventChildClass) {
      this.props.actions.updateValue(adminEventChildClass, this.props.modalState.ValidationRules.SelectedClass);
    } else {
      this.props.actions.updateValue(adminEventParentClass, this.props.modalState.ValidationRules.SelectedClass);
    }
  };

  renderFilters = () => {
    const {modalState: {ActiveForm, ValidationRules}, reportURL} = this.props;
    
    return [
      !ActiveForm.SelectedClass && !reportURL ? 
        <Row key='filter_1' className={`${namespace()}--filter`}>
          <div className={`${namespace()}--filter--col`}>
            <Select
              label='Kind of Class'
              disabled={false}
              hideOptional
              onChangeValue={this.props.actions.updateValue}
              value={ActiveForm.KindOfClass}
              validationRules={ValidationRules.KindOfClass}
              isNumber />
          </div>
          <div className={`${namespace()}--filter--col`}>
            <Select
              label='Register Under'
              disabled={true}
              hideOptional
              onChangeValue={this.props.actions.updateValue}
              value={ActiveForm.RegisterUnder}
              validationRules={ValidationRules.RegisterUnder}
              isNumber />
            </div>
        </Row> : <div key='filter_1'/>,
    ];
  };

  public render() {
    const {apiSaving, apiLoadingMap, context, modalState: {ActiveForm, isTextSearching, FilterText, ValidationRules}, inert, reportURL, filteredClasses, cacheTwoEvent, actions, apiLoading} = this.props;
    const loading = apiLoadingMap[GetAdminEvent.requestType];
    
    const renderStep1 = !ActiveForm.SelectedClass && !reportURL;
    const renderStep2 = !!ActiveForm.SelectedClass || reportURL;

    let subtitle;
    const title = ActiveForm.SelectedClass || reportURL ? "Export Instructor Class Roster" : "Select Class";
    
    if (ActiveForm.SelectedClass) {
      if ('ClassCode' in ActiveForm.SelectedClass) {
        let className = '';

        if (ActiveForm.SelectedClass.ClassCode) className += `${ActiveForm.SelectedClass.ClassCode} - `;
        if (ActiveForm.SelectedClass.IsCombo) className += ActiveForm.SelectedClass.ComboName;
        else {
          if (ActiveForm.SelectedClass.ClassTypeIDi) {
            const classType = classTypeSelector(reduxStoreService().getState(), ActiveForm.SelectedClass.ClassTypeIDi);
            if (classType) className += classType.Name;
          }
        }
        subtitle = className;
      } else {
        subtitle = ActiveForm.SelectedClass.Name;
      }
    } else {
      subtitle = cacheTwoEvent.EventsEvent ? cacheTwoEvent.EventsEvent.Name : '';
    }

    return (
      <Modal
        inert={inert}
        height={!!ActiveForm.SelectedClass || context === 'reports' || context === 'class-list' ? undefined : ModalHeight.HEIGHT_560} className={`${namespace()}`}
        mobileFullScreen bodyScrollLock big higherZIndex
        onClose={this.onClose}
      >
        <ModalHeader
          onBack={!!ActiveForm.SelectedClass && context !== 'class-list' ? this.onBack : undefined}
          isSearching={renderStep1 ? isTextSearching : false}
          className={`${namespace()}--header`}
          onRefresh={renderStep1 ? this.onRefreshClick : undefined}
          onSearchOpen={renderStep1 ?  this.onSearchOpen : undefined}
          onSearchChange={renderStep1 ?  actions.filterClass : undefined}
          onSearchBack={renderStep1 ?  this.onSearchBack : undefined}
          subtitle={subtitle}
        >{title}</ModalHeader>
        {this.renderFilters()}
        <ModalContent
          paddingLeft={renderStep1 ? 0 : undefined}
          paddingRight={renderStep1 ? 0 : undefined}
          paddingTop={renderStep1 ? 0 : undefined}
          paddingBottom={renderStep1 ? 0 : undefined}
          hasPadding={!renderStep1}
        >
          {renderStep1 && !!filteredClasses && filteredClasses.length > 0 && <div>{filteredClasses.map((adminClass) => {
              return (
                <AdminClassCard
                  key={`selected_class__card${adminClass.IDi}`}
                  type={AdminClassCardType.SINGLE_SELECT}
                  category={CardCategory.LIST}
                  adminEventClass={adminClass}
                  onClick={this.onSelectClass}
                />
              );
            })}</div>}
          {renderStep1 && (!filteredClasses || filteredClasses.length === 0) && this.renderEmptyClasses()}
          {renderStep2 && <div className={`${namespace()}--text`}>Please choose whether or not extra blank rows should be added to the class rosters for classes that aren't full. Blank rows will be added up to the maximum capacity of each class, up to 10 extra rows. For classes with no capacity, 5 extra rows will be added.</div>}
          {renderStep2 && <br />}
          {renderStep2 && <Row>
            <Column span={12}>
                <Switch
                  boldText
                  newDesign
                  label='Include Blank Rows'
                  multilineLabel
                  value={!!ActiveForm.IncludeBlankRows}
                  validationRules={ValidationRules.IncludeBlankRows}
                  onChange={actions.updateValue}
                />
            </Column>
          </Row>}
          {loading && <PageLoader className={`${namespace()}--page-loader`}/>}
        </ModalContent>
        {renderStep2 ? <ModalActions
          noPadding
          sticky
          loading={apiSaving > 0 || apiLoading > 0}
          stacked
          left={<Button expand flat textColor="black" onClick={this.onContinue}>CONTINUE</Button>}
        /> : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredClassesSelector = makeFilteredClassesSelector();
  const selectedClassIdSelector = makeFormModalPropSelector(ModalTypes.EXPORT_INSTRUCTOR_ROSTER, 'selectedClassId');
  const childClassIdSelector = makeFormModalPropSelector(ModalTypes.EXPORT_INSTRUCTOR_ROSTER, 'childClassId');
  const eventToolsModalContextSelector = makeFormModalPropSelector(ModalTypes.EXPORT_INSTRUCTOR_ROSTER, 'eventToolsModalContext');
  const reportURLSelector = makeFormModalPropSelector(ModalTypes.EXPORT_INSTRUCTOR_ROSTER, 'reportURL');
  
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    cacheOne: state.adminEvents.cacheOne,
    cacheTwoEvent: state.adminEvents.cacheTwoEvent,
    modalState: state.adminEvents.events.modals.exportInstructorRoster,
    filteredClasses: filteredClassesSelector(state),
    selectedClassId: selectedClassIdSelector(state),
    childClassId: childClassIdSelector(state),
    reportURL: reportURLSelector(state),
    context: eventToolsModalContextSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...exportInstructorRosterActionCreators,
    ...cacheTwoEventActionCreators,
    ...rollbackActionCreators,
  }, dispatch),
  dashboardActions: bindActionCreators({
    ...dashboardActionCreators,
  }, dispatch),
});

const ConnectedExportInstructorRosterModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ExportInstructorRosterModal);

export default ConnectedExportInstructorRosterModal;
