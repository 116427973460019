import { EnhancedStore } from '@reduxjs/toolkit';
import { Location } from 'history';
import { AnyAction, Dispatch } from "redux";

import { SilentCancelAllAction } from "../store/App/actions";
import { ClearRollback, RestoreState } from "../store/Rollback/actions";
import { captureTentarooError } from "../utils/dataHelper";
import { isCancelingRequestBeforeCacheZeroIsLoaded, rollbackStateExists, shouldRedirectToLogin, shouldRollbackOnBrowserUrlChange } from "./helpers";
import { QP_ACCOUNT } from "../constants/urls";
import { ApplicationState } from '../store';
import { CloseAllModals, ToggleNotFound } from "../store/App/actions";
import { noOpenedModals } from "../utils/modalHelper";
import {getIsRollingBackThroughBrowserState, getIsFromHandleError} from "./helpers";

const handleRollbackOnBrowserUrlChange = (rootState: ApplicationState, dispatch: Dispatch<AnyAction>, isRollingBackThroughBrowserState?: boolean, isFromHandleError?: boolean): boolean => {
  const app = rootState.app;
  let restored = false;
  // redirect out of wizards if user backs/forwards into them
  if (shouldRollbackOnBrowserUrlChange(rootState, false, isRollingBackThroughBrowserState)) {
    const skipRestoreStateAndRedirect = isCancelingRequestBeforeCacheZeroIsLoaded(rootState, isFromHandleError);

    if (!skipRestoreStateAndRedirect) {
      const redirectActions = shouldRedirectToLogin(true);

      if (redirectActions && redirectActions.length > 0) {
        redirectActions.forEach((action) => {
          dispatch(action);
        });

        return restored;
      }
    }

    // No need to rollback under epics anymore on SilentCancelAllAction since we'll rollback here. 
    // The other spots that call SilentCancelAllAction will also call rollback on error.
    // - Navigation OR ModalOpen will do a rollback (this case)
    // - Refresh will do a rollback if it has an error

    // `isFromHandleError` is set when we are rolling back due to a request failure, and
    // in that case the request is already completed, and hence we shouldn't need to dispatch
    // a SilentCancelAll. Otherwise, it will cancel the whole epic and stop the remaining of
    // failure handling. For example, the failure action that shows error in snackbar wont get executed.
    if (!isFromHandleError) {
      if (skipRestoreStateAndRedirect) {
        dispatch(new SilentCancelAllAction(true, true));
      } else if (rollbackStateExists(rootState)) {
        dispatch(new RestoreState(undefined, undefined, true));
        // If this is being triggered by `cancelLoadAndRollback`, clear rollback
        // immediately instead of waiting for App.tsx to do it, so that modal load doesn't get blocked
        if (isRollingBackThroughBrowserState) {
          dispatch(new ClearRollback());
        }
        restored = true;
      } else {
        captureTentarooError(new Error("NO ROLLBACK STATE"));
      }
    } else {
      if (rollbackStateExists(rootState)) {
        dispatch(new RestoreState());
        restored = true;
      } else {
        captureTentarooError(new Error("NO ROLLBACK STATE"));
      }
    }
  } else if (shouldRollbackOnBrowserUrlChange(rootState, true, isRollingBackThroughBrowserState)) {
    // Both cases will trigger restore here
    // 1. Normal save failure - 409 and other error
    // 2. Navigation During Save
    dispatch(new RestoreState(undefined, true));
    restored = true;
  }

  return restored;
};

/**
 * NOTE: This callback will be triggered as long as there is a push/replace/pop 
 * to the router, even when the endpoint is not changed
 */
export const onBrowserUrlChange = (location: Location, store: EnhancedStore<ApplicationState>) => {
  // Handle Rollback if it is the case
  const isRollingBackThroughBrowserState = getIsRollingBackThroughBrowserState(location);
  const isFromHandleError = getIsFromHandleError(location);
  handleRollbackOnBrowserUrlChange(store.getState(), store.dispatch, isRollingBackThroughBrowserState, isFromHandleError);

  // Should always close any opened modals when router changes
  if (!isRollingBackThroughBrowserState && !noOpenedModals()) {
    store.dispatch(new CloseAllModals());
  }
  
  const state = store.getState() as ApplicationState;

  // Handle NotFound case
  if (location.query) {
    const accountIDInUrl = location.query[QP_ACCOUNT];

    if (accountIDInUrl !== undefined) {
      // If `QP_ACCOUNT` is found in url query, toggle not found when it is an empty
      // string or when it contains charaters other than digits
      if (accountIDInUrl === '' || isNaN(Number(accountIDInUrl))) {
        store.dispatch(new ToggleNotFound());
      }
    }
  }

  if (state.app.isNotFound) {
    store.dispatch(new ToggleNotFound());
  }
};