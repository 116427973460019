import {ActionCreator} from '../';
import { typeName, Action } from '../../utils/StrongActions';
import {CLEAR_CACHE_PREFIX, createRequestActions} from '../Validation/actionCreator';

export const SUFFIX = '__CACHE_THREE_ROSTER';
export const GetGroupRosterPerson = createRequestActions(SUFFIX);

@typeName(`${CLEAR_CACHE_PREFIX}_CACHE_BELOW_THREE_ROSTER`)
export class ClearCacheBelowThreeRoster extends Action {}


export type Actions = typeof actionCreators;

export const actionCreators = {
  getGroupRosterPerson: (
    GroupIDi: number,
    GroupTS: number,
    PersonIDi: number,
    IsYouth: boolean,
    GetOptions: boolean,
    GetGroupData: boolean,
    GetGroupRosterData: boolean
  ): ActionCreator => dispatch => {
    dispatch(GetGroupRosterPerson.request({PersonIDi, GroupIDi, GroupTS, IsYouth, GetOptions, GetGroupData, GetGroupRosterData}));
  },
  clearCacheBelowThreeRoster: (): ActionCreator => dispatch => {
    dispatch(new ClearCacheBelowThreeRoster());
  },
};
