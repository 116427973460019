import * as React from 'react';
import { connect } from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';
import {bindActionCreators} from 'redux';

import { ApplicationState } from '../../../../../store';
import { makeFormModalPropSelector } from '../../../../../store/App';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import {
  Modal, ModalTitle, ModalContent, ModalActions, Text, Button
} from '../../../../Elements';
import {WithInertAttribute} from '../../../WithInert';
import {actionCreators} from "../../../../../store/App/actions";
import {cartActionCreators} from "../../../../../store/Cart/actions";
import {captureTentarooError} from '../../../../../utils/dataHelper';

// todo: move/rename this.
type Props = WithInertAttribute<{}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

@(withRouter as any)
class RemoveItemModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  onCancel = () => {
    this.props.actions.popModal(false, true, ModalTypes.DELETE_ITEM_OR_CART);
  };

  onConfirmRemoveItem = () => {
    const {getEmptyCartUrl, getEmptyCartBody, emptyCartPostNavigate, deletingItemID, deletingTo, actions, params} = this.props;
    if (getEmptyCartBody && getEmptyCartUrl) {
      const body: any = getEmptyCartBody(params);
      body.AppState.EmptyCart = false;
      body.AppState.RemoveCartItemID = deletingItemID;
      const to = emptyCartPostNavigate ? emptyCartPostNavigate : (deletingTo ? deletingTo: undefined);
      actions.removeItemFromCart(getEmptyCartUrl(), body, to);
    } else {
      captureTentarooError(new Error(
        'Cannot remove item from cart without getEmptyCartBody and getEmptyCartUrl! Something went wrong with a SetEmptyCart action'
      ));
    }
  };
  onConfirmEmptyCart = () => {
    const {getEmptyCartUrl, getEmptyCartBody, emptyCartPostNavigate, actions, params} = this.props;
    if (getEmptyCartBody && getEmptyCartUrl) {
      actions.emptyCart(getEmptyCartUrl(), getEmptyCartBody(params as any), emptyCartPostNavigate);
    } else {
      captureTentarooError(new Error(
        'Cannot empty cart without getEmptyCartBody and getEmptyCartUrl! Something went wrong with a SetEmptyCart action'
      ));
    }
  };

  onRemove = () => {
    if (this.props.context === "confirm-empty-cart") {
      this.onConfirmEmptyCart();
    } else {
      this.onConfirmRemoveItem();
    }
  };

  render() {
    const {apiLoading, apiSaving, title, text, buttonText} = this.props;

    const loading = apiSaving > 0 || apiLoading > 0;
    return (
      <Modal inert={this.props.inert} disableDynamicHeight big>
        <ModalTitle marginBottom={0} loading={loading}>{title}</ModalTitle>
        <ModalContent loading={loading}>
          <Text color="gray">{text}</Text>
        </ModalContent>
        <ModalActions
          noPadding
          stacked
          loading={loading}
          left={<Button flat onClick={this.onCancel}>Cancel</Button>}
          right={<Button flat onClick={this.onRemove} textColor="red">{buttonText}</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const titleSelector = makeFormModalPropSelector(ModalTypes.DELETE_ITEM_OR_CART, 'title');
  const textSelector = makeFormModalPropSelector(ModalTypes.DELETE_ITEM_OR_CART, 'text');
  const buttonTextSelector = makeFormModalPropSelector(ModalTypes.DELETE_ITEM_OR_CART, 'buttonText');
  const contextSelector = makeFormModalPropSelector(ModalTypes.DELETE_ITEM_OR_CART, 'removeItemContext');
  const deletingItemIDSelector = makeFormModalPropSelector(ModalTypes.DELETE_ITEM_OR_CART, "id");
  const deletingToSelector = makeFormModalPropSelector(ModalTypes.DELETE_ITEM_OR_CART, "to");
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    title: titleSelector(state),
    text: textSelector(state),
    buttonText: buttonTextSelector(state),
    context: contextSelector(state),
    getEmptyCartBody: state.app.getEmptyCartBody,
    getEmptyCartUrl: state.app.getEmptyCartUrl,
    emptyCartPostNavigate: state.app.emptyCartPostNavigate,
    deletingItemID: deletingItemIDSelector(state),
    deletingTo: deletingToSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cartActionCreators,
  }, dispatch)
});

const ConnectedRemoveItemModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(RemoveItemModal);

export default ConnectedRemoveItemModal;
