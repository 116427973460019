import {makeDebounceEpic} from "../../../../Validation/epicCreator";
import {getFacilityReservation} from "../../../../../constants/urls";
import {OverrideReservationChange} from "./actions";
import {Observable} from "rxjs";
import {NoOp} from "../../../../App/actions";
import {ReservationChange} from "../../Reservation/Reservation/actions";
import { ModalTypes, isModalOpened } from "../../../../../utils/modalHelper";
import { popModalObservables } from "../../../../../utils/modalHelper";
import { Action } from "redux";

export const overrideReservationChangeEpic = makeDebounceEpic(
  OverrideReservationChange,
  getFacilityReservation,
  undefined,
);

export const closeOverrideEpic = action$ => (
  action$.filter(action => action.type && action.type === ReservationChange.successType)
    .switchMap((action): Observable<Action> => {
      if (isModalOpened(ModalTypes.RESERVATION_OVERRIDE_FEE)) {
        return Observable.concat(
          ...popModalObservables({
            modal: ModalTypes.RESERVATION_OVERRIDE_FEE,
            saveBefore: false,
            saveAfter: false,
            transformToObservable: true,
          })
        );
      }
      return Observable.of(new NoOp());
    })
);
