
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, Row, TextField, Column, ModalActions, Button, Alert,
} from '../../../../../../Elements';
import {
  actionCreators,
} from "../../../../../../../store/AdminCMSSite/Resources/Modals/ResourceCategoryForm/actions";
import {
  actionCreators as adminCMSCacheOneActionCreators,
} from "../../../../../../../store/AdminCMSSite/CacheOne/actions";
import {
  actionCreators as appActionCreators,
} from "../../../../../../../store/App/actions";
import '../../../../../../../styles/pages/resources/modals/resource-category-form/index.scss';
import { INVALID_RESOURCE_CATEGORY } from '../../../../../../../constants/messages/adminCMS';
import { ApplicationState } from '../../../../../../../store';
import { checkResourceCategoryPermission } from '../../../../../../../utils/helpers/adminCMSPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import {WithInertAttribute} from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--modals--resource-category-form';

type ConnectedProps = 
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {}>;
type Props = WithInertAttribute<{
  onClose?: () => void;
}>

@(withRouter as any)
class ResourceCategoryFormModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;

  private saving;

  onCreate = () => {
    const {resourceCategoryFormModal: {type}} = this.props;

    checkResourceCategoryPermission(
      () => {
        this.saving = true;
        const valid = this.props.actions.apiSubmitForm();
        if (!valid) this.saving = false;
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };
  onClose = () => {
    this.props.actions.resetForm(!this.saving);
  };
  onDelete = () => {
    const {actions, resourceCategoryFormModal: {ActiveForm}} = this.props;

    checkResourceCategoryPermission(
      () => {
        if (ActiveForm.ID) {
          this.props.actions.deleteResourceCategory(ActiveForm.ID);
        }
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };

  onResourceCategoryInactive() {
    const {actions} = this.props;

    actions.showTopFloatingAlert(INVALID_RESOURCE_CATEGORY, true, 'orange');
  }

  componentWillUnmount() {
    this.onClose();
  }

  public render() {
    const {apiSaving, actions, inert, resourceCategoryFormModal: {ActiveForm, ValidationRules, type, SubmitErrorMessage}, adminCMSCacheOne} = this.props;

    return (
      <Modal
        inert={inert}
        inactive={ActiveForm.Inactive}
        onClose={this.onClose}
        onInactive={() => this.onResourceCategoryInactive()}
        mobileFullScreen
        big
        className={`${namespace()} ${!!SubmitErrorMessage ? 'has-error' : ''}`}
      >
        <ModalHeader
          subtitle={adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.SiteDomain : ''}
          className={`${namespace()}--header`}
        >
          {type === 'new' ? 'New Resource Category' : 'Edit Resource Category'}
        </ModalHeader>

        <ModalContent hasPadding>
          {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
          <Row>
            <Column span={12} mobileSpan={12}>
              <TextField
                label="Category Name"
                disabled={apiSaving > 0}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules.Name}
                value={ActiveForm.Name}/>
            </Column>
          </Row>
        </ModalContent>
        <ModalActions
          className={`${namespace()}--actions`}
          loading={apiSaving > 0}
          sticky
          notFixed
          left={<Button flat textColor="black" onClick={this.onCreate}>{type === 'new' ? 'CREATE' : 'SAVE CHANGES'}</Button>}
          right={type === 'edit' ? <Button flat textColor="red" onClick={this.onDelete}>DELETE</Button> : undefined}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    resourceCategoryFormModal: state.adminCMSSite.resources.modals.resourceCategoryForm,
    apiSaving: state.app.apiSaving,
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...adminCMSCacheOneActionCreators,
  ...appActionCreators,
}, dispatch) });

const ConnectedResourceCategoryFormModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ResourceCategoryFormModal);

export default ConnectedResourceCategoryFormModal;