import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createSimpleUpdateValueMethod
} from '../../../../Validation/actionCreator';
import { ActionCreator, ApplicationState } from '../../../../';
import { CMSPageGalleryImage } from '../../../../../models/api/adminCMSCacheTwoPage';
import { SaveState } from '../../../../../store/Rollback/actions';
import { typeName, Action } from '../../../../../utils/StrongActions';

export const SUFFIX = '__IMAGE_FORM';
export const DEL_SUFFIX = '__DELETE_IMAGE';

@typeName('RESET_FORM' + SUFFIX)
export class ImageResetForm extends Action {}
@typeName('INIT_FORM' + SUFFIX)
export class ImageInitForm extends Action {
  constructor(public image: CMSPageGalleryImage) { super(); }
}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export const ImageFormModalNamespace = (): string => 'pages--cms--modals--image-form';

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.pages.modals.imageForm),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  initForm: (image: CMSPageGalleryImage): ActionCreator => dispatch => {
    dispatch(new ImageInitForm(image));
    dispatch(new SaveState());
  },
  resetForm: (): ActionCreator => dispatch => {
    dispatch(new ImageResetForm());
    dispatch(new SaveState());
  },
};