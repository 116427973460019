import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { easing } from '../../../utils';
import '../../../styles/elements/picker/index.scss';
import { OptionProps } from './Option';
import {Nullable} from '../../../utils/dataHelper';

export const namespace = (): string => 'elements--picker';

export interface PickerProps {
  children?: React.ReactNode;
  rows: number;
  value: number | string;
  onChange: (value: number | string | null) => void;
}

interface State {
  width: number | null;
}

class Picker extends React.Component<PickerProps, State> {
  public props: PickerProps;
  public state: State = {
    width: null
  };

  private ITEM_HEIGHT = 34;
  private ITEM_HIGHLIGHTED_HEIGHT = 40;

  private _scrollable: HTMLDivElement | undefined;
  public get scrollable(): HTMLDivElement | undefined {
    if (this._scrollable) return this._scrollable;
    return this._scrollable = (findDOMNode(this) as Nullable<Element>)?.getElementsByClassName(`${namespace()}--container--scrollable`)[0] as HTMLDivElement;
  }

  private _highlightedElement: HTMLAnchorElement | undefined;
  public get highlightedElement(): HTMLAnchorElement | undefined {
    if (this._highlightedElement) return this._highlightedElement;
    return this._highlightedElement = (findDOMNode(this) as Nullable<Element>)?.getElementsByClassName(`${namespace()}--container--highlighted-item`)[0] as HTMLAnchorElement;
  }

  public highlight = () => {
    const scrollTop = this.scrollable?.scrollTop ?? 0;
    let position = Math.round(scrollTop / this.ITEM_HEIGHT);
    if (position < 0) position = 0;
    else if (position >= React.Children.count(this.props.children)) position = React.Children.count(this.props.children) - 1;
    const nextValue = this.valueAtIndex(position);
    if (this.props.value !== nextValue) {
      this.props.onChange(nextValue);
    }
  };

  private isDragging: boolean = false;
  private lastDragPosition: number | null = null;
  public handleMouseDown = (event: React.MouseEvent<any>) => {
    this.isDragging = false;
    this.lastDragPosition = event.screenY;
    const onDragStop = (event) => {
      event.stopPropagation();
      event.preventDefault();
      document.removeEventListener('mouseup', onDragStop);
      setTimeout(() => this.lastDragPosition = null, 10);
      if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(this.handleScrollEnd, 35);
    };
    document.addEventListener('mouseup', onDragStop);
  };

  public handleMouseMove = (event: React.MouseEvent<any>) => {
    if (this.lastDragPosition !== null) {
      this.isDragging = true;
      if (this.scrollable) {
        this.scrollable.scrollTop = this.scrollable.scrollTop + ((event.screenY - this.lastDragPosition) * -1);
      }
      this.lastDragPosition = event.screenY;
    }
  };

  private isTouching: boolean = false;
  private isAnimating: boolean = false;
  private scrollTimeout;
  public handleScroll = (event: React.UIEvent<any>) => {
    if (this.isAnimating) {
      event.preventDefault();
      return false;
    }
    this.highlight();
    if (!this.isTouching && this.lastDragPosition === null) {
      if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(this.handleScrollEnd, 35);
    }
  };

  private handleTouchEnd = () => {
    if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(this.handleScrollEnd, 35);
  };

  private lastScrollPosition: number | null = null;
  public handleScrollEnd = () => {
    if (!this.scrollable) return;
    if (this.lastScrollPosition === this.scrollable.scrollTop) {
      if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
      this.lastScrollPosition = null;
      this.scrollTimeout = setTimeout(this.resetScroll, 15);
    } else {
      this.lastScrollPosition = this.scrollable.scrollTop;
      if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(this.handleScrollEnd, 35);
    }
  };

  public resetScroll = () => {
    this.isDragging = false;
    this.isAnimating = true;
    window.requestAnimationFrame(
      (time) => this.animateScrollFrame(
        this.scrollable?.scrollTop ?? 0,
        (this.indexOfValue ? this.indexOfValue : 0) * this.ITEM_HEIGHT,
        time
      )
    );
  };

  public handleClick = (index: number) => {
    if (!this.isDragging) {
      this.isAnimating = true;
      this.props.onChange(this.valueAtIndex(index));
      window.requestAnimationFrame(
        (time) => this.animateScrollFrame(
          this.scrollable?.scrollTop ?? 0,
          index * this.ITEM_HEIGHT,
          time
        )
      );
    }
  };

  public animateScrollFrame = (
    startPosition: number,
    endPosition: number,
    currentTime: number,
    startTime: number | null = null,
    duration: number | null = null
  ) => {
    if (duration === null) {
      let diff = endPosition - startPosition;
      if (diff < 0) diff = diff * -1;
      if (diff > this.ITEM_HEIGHT / 4) {
        duration = 200;
      } else {
        duration = 100;
      }
    }

    if (!startTime) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const nextValue = easing.easeInOutQuad(timeElapsed, startPosition, endPosition - startPosition, duration);
    if (this.scrollable) this.scrollable.scrollTop = nextValue;
    if (timeElapsed < duration) {
      window.requestAnimationFrame(
        (time) => this.animateScrollFrame(
          startPosition,
          endPosition,
          time,
          startTime,
          duration
        )
      );
      return;
    }
    this.scrollEnd(endPosition);
  };

  public scrollEnd = (endPosition: number) => {
    if (this.scrollable) this.scrollable.scrollTop = endPosition;
    setTimeout(() => this.isAnimating = false, 10);
  };

  public componentDidMount() {
    this.handleResize(null, false);
    [10, 100, 1000].forEach(timeout => setTimeout(() => this.handleResize(null, false), timeout));
    window.addEventListener('resize', this.handleResize);
    if (this.scrollable) this.scrollable.scrollTop = (this.indexOfValue ? this.indexOfValue : 0) * this.ITEM_HEIGHT;
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  public componentWillUpdate() {
    this.handleResize(null, false);
  }

  public componentDidUpdate() {
    this.handleResize(null, false);
    if (!this.isDragging && !this.isAnimating && !this.isTouching && this.scrollable) {
      this.scrollable.scrollTop = (this.indexOfValue ? this.indexOfValue : 0) * this.ITEM_HEIGHT;
    }
  }

  public handleResize = (event, forceUpdate: boolean = true) => {
    const reference: HTMLAnchorElement | undefined = this.scrollable?.getElementsByTagName('ul')[0].getElementsByTagName('a')[0] as HTMLAnchorElement;
    if (this.highlightedElement && reference) this.highlightedElement.style.width = `${reference.getBoundingClientRect().width}px`;
    this.state.width = reference?.getBoundingClientRect().width ?? 0;
    if (forceUpdate) this.forceUpdate();
  };

  public get indexOfValue(): number | null {
    const { value, children } = this.props;
    let finalIndex: number | null = null;
    React.Children.forEach(children, (child: React.ReactElement<OptionProps>, index: number) => {
      if (value === child.props.value) finalIndex = index;
    });
    return finalIndex;
  }

  public valueAtIndex(index: number) {
    const { children } = this.props;
    let finalValue: number | string | null = null;
    React.Children.forEach(children, (child: React.ReactElement<OptionProps>, itemIndex: number) => {
      if (index === itemIndex) finalValue = child.props.value;
    });
    return finalValue;
  }

  public render() {
    const { rows, children, value } = this.props;
    const ghosts = Array.apply(null, Array((rows - 1) / 2));

    const height = (rows - 1) * this.ITEM_HEIGHT + this.ITEM_HIGHLIGHTED_HEIGHT;
    const style: React.CSSProperties = {
      height: `${height}px`
    };

    const highlightedStyle: React.CSSProperties = {
      top: `${height / 2 - this.ITEM_HIGHLIGHTED_HEIGHT / 2}px`,
      width: this.state.width !== null ? `${this.state.width}px` : undefined
    };

    let finalChildren: Array<React.ReactNode> = [];
    let highlightedComponent: React.ReactNode = null;
    React.Children.forEach(children, (child: React.ReactElement<OptionProps>, index: number) => {
      let highlighted: boolean = value === child.props.value;
      if (highlighted) {
        highlightedComponent = (
          <a className={`${namespace()}--container--highlighted-item`} style={highlightedStyle}>
            <span>{child.props.children}</span>
          </a>
        );
      }
      finalChildren.push(
        <li key={index} className={`${namespace()}--container--scrollable--list--item` + (highlighted ? ' highlighted' : '')}>
          <a className={`${namespace()}--container--scrollable--list--item--button` + (highlighted ? ' highlighted' : '')} onClick={() => this.handleClick(index)}>
            <span>{child.props.children}</span>
          </a>
        </li>
      );
    });

    return (
      <div
        className={namespace()}
        style={style}
        onTouchMove={e => e.preventDefault()}
        onTouchStart={e => e.preventDefault()}
      >
        <div
          className={`${namespace()}--container span-${rows}`}
          style={style}
        >
          {highlightedComponent}
          <div
            className={`${namespace()}--container--scrollable span-${rows}`}
            onTouchMove={e => e.stopPropagation()}
            onTouchStart={e => {
              e.stopPropagation();
              this.isTouching = true;
              if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
            }}
            onTouchEnd={e => {
              e.stopPropagation();
              this.isTouching = false;
              this.handleTouchEnd();
            }}
            onMouseDown={this.handleMouseDown}
            onMouseMove={this.handleMouseMove}
            onScroll={this.handleScroll}
          >
            <ul className={`${namespace()}--container--scrollable--list`}>
              {ghosts.map((value, index) => <li key={index} className={`${namespace()}--container--scrollable--list--item ghost`}/>)}
              {finalChildren}
              {ghosts.map((value, index) => <li key={index} className={`${namespace()}--container--scrollable--list--item ghost`}/>)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Picker;
export { default as PickerOption } from './Option';
