import * as React from 'react';
import { Product } from '../../../../../../Elements';
import { ProductsFormTemplate } from '../../../../../../Templates';
import ProductsAdded from '../../ProductsAdded';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import { actionCreators, Actions } from "../../../../../../../store/Events/Event/Register/Numbers/Products/actions";
import { appActionCreators } from "../../../../../../../store/App";
import { bindActionCreators } from 'redux';
import {Product as ProductModel} from '../../../../../../../models/product';
import '../../../../../../../styles/pages/events/event/register/products.scss';
import {NumbersConnectedProps} from "../index";
import {
  makeAddedNumbersProductsFilter,
  makeAvailableNumbersProductsFilter, makeAvailableNumbersProductsSearchFilter, makePreviousNumbersProductsFilter
} from "../../../../../../../store/CacheFourEventsNumbers";
import { ApplicationState } from '../../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

// @todo: this class is the same as Product/Products/index?
export const namespace = (): string => `pages--events--event--products--wizard`;


type Props = WithInertAttribute<{
  numberProps: NumbersConnectedProps;
}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class Products extends React.Component<{}, {}> {
  public props: ConnectedProps & Props;

  renderProduct = (actions: Actions, p: ProductModel, index: number) => {
    return (<Product
      key={index}
      onAddProduct={actions.addProduct}
      onUpdateProduct={actions.setNotAdded}
      productModel={p}
    />);
  };

  public render() {
    const { addedProducts, previousProducts, availableProducts, actions, user, filteredAvailableProducts, inert } = this.props;
    const {cacheTwoEvents, cacheFourEventsNumbers, apiLoading} = this.props.numberProps;

    let emptyMessage: string | undefined;

    if (availableProducts === null || (availableProducts && availableProducts.length === 0)) {
      emptyMessage = 'There are no available products';
    } else if (filteredAvailableProducts && filteredAvailableProducts.length === 0) {
      emptyMessage = 'No available products found';
    }

    const props = {
      key: 'numbers',
      inert,
      footer: <FooterProgress selected="products"/>,
      header: <HeaderProgress loading={apiLoading > 0} c2={cacheTwoEvents} c4={cacheFourEventsNumbers} selected="products"/>,
      sideBar: <ProductsAdded
        isAdmin={user.user && user.user.str_permissions.hasAdminAccess}
        products={addedProducts}
        orderedProducts={previousProducts}
        loading={apiLoading > 0}
        onRemove={actions.removeProduct}
        onUpdate={actions.setAdded}
        onCancel={actions.cancelProduct}
        onCommit={actions.commitProduct}
        onManage={actions.manageProduct}
      />,
      loading: apiLoading > 0,
      emptyMessage: emptyMessage,
      showEmpty: availableProducts.length === 0,
      addedItems: [...addedProducts, ...previousProducts],
      className: namespace()
    };
    /*
    {availableProducts.length !== 0 && <Alert className={`${namespace()}--alert`} hidable>
      Products are only applied to the whole group for this event.
    </Alert>}
    */
    return (
      <ProductsFormTemplate {...props}>
        {filteredAvailableProducts.map(this.renderProduct.bind(this, actions))}
      </ProductsFormTemplate>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const availableProducts = makeAvailableNumbersProductsFilter();
  const addedProducts = makeAddedNumbersProductsFilter();
  const previousProducts = makePreviousNumbersProductsFilter();
  const filteredAvailableProducts = makeAvailableNumbersProductsSearchFilter();
  return {
    availableProducts: availableProducts(state),
    addedProducts: addedProducts(state),
    previousProducts: previousProducts(state),
    filteredAvailableProducts: filteredAvailableProducts(state),
    user: state.user,
    showAddedSideBar: state.app.showAddedSideBar
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actionCreators, ...appActionCreators }, dispatch)
});

const ConnectedProducts = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(Products);

export default ConnectedProducts;

