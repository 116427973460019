import * as React from 'react';

import Card from "../../Card";
import { CardCategory } from '../../Card';
import AdminBadge from "../../AdminBadge";
import { default as attribute, MarginProps } from "../../../../styles";
import { IResetPasswordAccount } from '../../../../models/api/login';
import "../../../../styles/elements/reset-password-account/card/index.scss";

export const namespace = (): string => 'elements--reset-password-account--card';

interface Props extends MarginProps {
  category: CardCategory;
  account: IResetPasswordAccount;
  type: "list" | "update-password-form";
  onClick?: (account: IResetPasswordAccount) => void;
}

class ResetPasswordAccountCard extends React.Component<Props> {

  renderContent = () => {
    const {account, onClick} = this.props;

    return (
      <div className={`${namespace()}--main`} onClick={!onClick ? undefined : () => onClick(account)}>
        <div className={`${namespace()}--main--name`}>
          <div className={`${namespace()}--main--name--title`}>{account.Name}</div>
          {account.IsAdmin ? <AdminBadge className="admin-badge" marginLeft={2} /> : null}
        </div>
        <div className={`${namespace()}--main--username`}>
          {`Username: ${account.Username}`}
        </div>
      </div>
    );
  };

  render() {
    const {category, type} = this.props;
    let className = attribute(namespace(), undefined, this.props);

    return (
      <Card
        className={`${className} ${type}`}
        component={<section/>}
        template={'mobile-no-margin'}
        category={category}
        padding="none"
      >
        {this.renderContent()}
      </Card>
    );
  }
}

export default ResetPasswordAccountCard;