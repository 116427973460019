import { ToggleNotFound } from "../../store/App/actions";
import { ApplicationState } from '../../store';
import { SaveState } from "../../store/Rollback/actions";
import { getGroupID } from '../helpers/endUserPageHelper';
import { GetPrevOrders } from '../../store/CacheTwoPrevOrders/actions';
import { captureTentarooError } from '../dataHelper';
import { reduxStoreService } from "../../store/service";
import {isIdValid} from '../urlHelper';
import { LoadEndUserCacheTwoPrevOrdersParams } from "./helpers/models";
import {isCacheTwoPrevOrdersPopulated, isCacheZeroPopulated, isEndUserCacheOnePopulated} from "../cachePopulatedCheckers/endUser";

export const ensureCacheTwoPrevOrders = (
  params: LoadEndUserCacheTwoPrevOrdersParams
): boolean => {
  const {props, isStateNavigated} = params;
  const rootState = reduxStoreService().getState() as ApplicationState;
  const {cacheZero, cacheOne} = rootState;

  const groupID = getGroupID(props);
  if (!isIdValid(groupID)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const cacheTwoPrevOrders = rootState.cacheTwoPrevOrders;

  let requestedData = false;
  if (!isCacheTwoPrevOrdersPopulated(cacheTwoPrevOrders)) {
    const appStateProps: any = {};
    if (!isCacheZeroPopulated(cacheZero)) {
      appStateProps.GroupIDi = groupID;
      appStateProps.GroupTS = 0;
      appStateProps.GetOptions = true;
      appStateProps.GetGroupData = true;
      appStateProps.GetGroupPreviousOrdersData = true;
    } else if (
      isCacheZeroPopulated(cacheZero) &&
      (!cacheZero.options!.Group || !isEndUserCacheOnePopulated(cacheOne))
    ) {
      appStateProps.GroupIDi = groupID;
      appStateProps.GroupTS = 0;
      appStateProps.GetOptions = false;
      appStateProps.GetGroupData = true;
      appStateProps.GetGroupPreviousOrdersData = true;
    } else {
      if (!cacheZero.options!.Group) {
        captureTentarooError(new Error("Group not available when dispatching GetPrevOrders in ensureCacheTwoPrevOrders"));
        return false;
      }
      appStateProps.GroupIDi = cacheZero.options!.Group.IDi;
      appStateProps.GroupTS = cacheZero.options!.Group.TS;
      appStateProps.GetOptions = false;
      appStateProps.GetGroupData = false;
      appStateProps.GetGroupPreviousOrdersData = true;
    }

    reduxStoreService().dispatch(GetPrevOrders.request({
      GroupIDi: appStateProps.GroupIDi,
      GroupTS: appStateProps.GroupTS,
      GetOptions: appStateProps.GetOptions,
      GetGroupData: appStateProps.GetGroupData,
    }));

    requestedData = true;
  } else if (isStateNavigated) {
    reduxStoreService().dispatch(new SaveState());
  }

  return requestedData;
};