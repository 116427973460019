
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetSiteMenuItem} from "./actions";
import { getSiteMenuItem, getSiteMenuItemBody } from "../../../constants/urls";

export const adminCMSSiteCacheTwoSiteMenuItemsEpic = makeRequestEpic(
  GetSiteMenuItem,
  getSiteMenuItem,
  getSiteMenuItemBody,
  true,
);