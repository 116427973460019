import { SelectComponentRequiredValidator } from "@tentaroo/core";

import * as moment from 'moment';

import * as GM from '../../../../constants/messages/generic';
import * as AM from '../../../../constants/messages/accountsModal';
import { EventType } from '../../../../models/api/options';
import { sortLocations } from '../../../AdminSettings/Modals/Location';
import { compareDate } from '../../../../utils/dateHelper';
import { makeDeletedPrefixItemMapper } from '../../../../utils/dataHelper';
import { SelectValidator, Validator } from "../../../../utils/validator/models";
import type { ApplicationState } from "../../..";

export interface IValidator {
  Year: SelectValidator;
  EventTypeID: SelectValidator;
  RegistrationsEventTypeID: SelectValidator;
  RegistrationsYear: SelectValidator;
  RegistrationsEventID: SelectValidator;
  TripsLocationID: SelectValidator;
  TripsYear: SelectValidator;
  ReservationsLocationID: SelectValidator;
  ReservationsFacilityTypeID: SelectValidator;
  ReservationsYear: SelectValidator;
  ShowInactive: Validator;
}

const getYearsValues = () => {
  const now = moment();

  const currentYear = now.year();

  const prevYearsLimit = currentYear - 10;
  const nextYearsLimit = currentYear + 2;

  const result: any[] = [];

  for (let i = nextYearsLimit; i >= prevYearsLimit; i --) {
    result.push({key: i, year: i});
  }

  return result;
};

const getLocationsValues = (rootState: ApplicationState, key: string) => {
  if (!rootState.cacheZero.options || !rootState.cacheZero.options.Locations) return [];

  const selectedLocationID = rootState.adminAccounts.ActiveForm[key];
  const result = rootState.cacheZero.options.Locations.filter((l) => {
    return !l.Inactive || (l.ID === selectedLocationID);
  }).map(makeDeletedPrefixItemMapper('Name', (l) => {
    return {
      ...l,
      Name: l.Name + ` (${l.NumFacilities} facilities)`,
    };
  }));

  return [{ Name: '', ID: -1 }, ...sortLocations(result)];
};

const sortEventTypes = (eventTypes: Array<any>) => {
  const hasEvents = eventTypes.filter((l) => l.NumCurrentOrFutureEvents > 0);
  const noEvents = eventTypes.filter((l) => l.NumCurrentOrFutureEvents <= 0);

  return [...hasEvents.sort((a, b) => a.Name.localeCompare(b.Name)), ...noEvents.sort((a, b) => a.Name.localeCompare(b.Name))];
};

const getEventTypeIDDefinition = (key: string): Pick<SelectValidator, "key" | "options" | "defaultValue"> & SelectComponentRequiredValidator => {
  return {
    key,
    options: {
      values: (rootState) => {
        const selectedEventTypeID = rootState.adminAccounts.ActiveForm.EventTypeID;
        let eventTypes: EventType[] = [];
        if (rootState.cacheZero.options && rootState.cacheZero.options.EventTypes) eventTypes = rootState.cacheZero.options.EventTypes;
        
        eventTypes = eventTypes.filter((et) => {
          return !et.Inactive || (et.ID === selectedEventTypeID);
        }).map(makeDeletedPrefixItemMapper('Name', (et) => {
          return {
            ...et,
            Name: et.Name + ` (${et.NumCurrentOrFutureEvents} upcoming events)`,
          };
        }));
        return [{ Name: '', ID: -1 }, ...sortEventTypes(eventTypes)];
      },
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: -1,
    },
    defaultValue: () => -1,
    validatejs: {
      [key]: {
        presence: {message: '^' + AM.INVALID_EVENT_TYPE},
        numericality : {
          notGreaterThanOrEqualTo: '^' + AM.INVALID_EVENT_TYPE,
          greaterThanOrEqualTo: 0
        }
      }
    }
  };
};

export const getYearDefinition = (
  key: string,
): Pick<SelectValidator, "key" | "options" | "defaultValue"> & SelectComponentRequiredValidator => {
  return {
    key,
    options: {
      values: () => getYearsValues(),
      valueKey: (v) => v.key,
      labelKey: 'year',
    },
    defaultValue: () => moment().year(),
    validatejs: {
      [key]: {
        presence: {message: '^' + GM.REQUIRED},
        // NOTE: There's no blank options for this select field, so we dont need numericality validator
        numericality: undefined,
      }
    },
  };
};

const getLocationIDDefinition = (key: string): Pick<SelectValidator, "key" | "options" | "defaultValue"> & SelectComponentRequiredValidator => {
  return {
    key,
    options: {
      values: (rootState) => getLocationsValues(rootState, key),
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: -1,
    },
    defaultValue: () => -1,
    validatejs: {
      [key]: {
        presence: {message: '^' + GM.REQUIRED},
        numericality : {
          notGreaterThanOrEqualTo: '^' + AM.INVALID_LOCATION,
          greaterThanOrEqualTo: 0,
        }
      }
    },
  };
};

const getEventValues = (rootState: ApplicationState) => {
  let result: any[] = [];

  if (rootState.adminAccounts.AccountsEventTypeEvents) {
    const selectedEventID = rootState.adminAccounts.ActiveForm.RegistrationsEventID;
    result = [
      ...rootState.adminAccounts.AccountsEventTypeEvents.filter((event) => {
        return (!event.Inactive || event.IDi === selectedEventID) && moment(event.StartDate).year() === rootState.adminAccounts.ActiveForm.RegistrationsYear;
      })
    ]
    .map(makeDeletedPrefixItemMapper('EventName'))
    .sort((a, b) => {
      const result = compareDate(moment(a.StartDate), moment(b.StartDate));

      if (result === 0) {
        return a.EventName.localeCompare(b.EventName);
      }
      return result;
    });
  }

  result.unshift({IDi: 0, EventName: 'All Events', Inactive: false});

  return result;
};

const getFacilityTypeValues = (rootState: ApplicationState) => {
  let result: any[] = [{ID: 0, NamePlural: 'All Facilities'}];
  const selectedFacilityTypeTypeID = rootState.adminAccounts.ActiveForm.ReservationsFacilityTypeID;

  if (rootState.adminAccounts.AccountsLocationFacilityTypes) {
    result = [
      ...rootState.adminAccounts.AccountsLocationFacilityTypes.filter((f) => {
        return f.HasFacilities && (!f.Inactive || f.ID === selectedFacilityTypeTypeID);
      })
      .map(makeDeletedPrefixItemMapper('NamePlural'))
    ]
    .sort((a, b) => a.NamePlural.localeCompare(b.NamePlural));
    result.unshift({ID: 0, NamePlural: 'All Facilities'} as any);
  }

  return result;
};

export const FormDefinition: IValidator = {
  EventTypeID: {
    ...getEventTypeIDDefinition('EventTypeID'),
    isRequired: (rootState) => {
      return rootState.adminAccounts.selectedTab === 'participants';
    },
  },
  Year: {
    ...getYearDefinition('Year'),
    isRequired: (rootState) => {
      return rootState.adminAccounts.selectedTab === 'participants';
    },
  },
  RegistrationsEventTypeID: {
    ...getEventTypeIDDefinition('RegistrationsEventTypeID'),
    chainDependants: ['RegistrationsEventID'],
    isRequired: (rootState) => {
      return rootState.adminAccounts.selectedTab === 'registrations';
    },
  },
  RegistrationsYear: {
    ...getYearDefinition('RegistrationsYear'),
    chainDependants: ['RegistrationsEventID'],
    isRequired: (rootState) => {
      return rootState.adminAccounts.selectedTab === 'registrations';
    },
  },
  RegistrationsEventID: {
    key: 'RegistrationsEventID',
    isRequired: (rootState) => {
      return rootState.adminAccounts.selectedTab === 'registrations';
    },
    options: {
      values: (rootState) => getEventValues(rootState),
      valueKey: (v) => v.IDi,
      labelKey: 'EventName',
    },
    defaultValue: () => 0,
    validatejs: {
      RegistrationsEventID: {
        presence: {message: '^' + GM.REQUIRED},
        numericality: {
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: '^' + GM.REQUIRED,
        },
      }
    },
  },
  TripsLocationID: {
    ...getLocationIDDefinition('TripsLocationID'),
    isRequired: (rootState) => {
      return rootState.adminAccounts.selectedTab === 'trips';
    },
  },
  TripsYear: {
    ...getYearDefinition('TripsYear'),
    isRequired: (rootState) => {
      return rootState.adminAccounts.selectedTab === 'trips';
    },
  },
  ReservationsLocationID: {
    ...getLocationIDDefinition('ReservationsLocationID'),
    chainDependants: ['ReservationsFacilityTypeID'],
    isRequired: (rootState) => {
      return rootState.adminAccounts.selectedTab === 'reservations';
    },
  },
  ReservationsFacilityTypeID: {
    key: 'ReservationsFacilityTypeID',
    isRequired: (rootState) => {
      return rootState.adminAccounts.selectedTab === 'reservations';
    },
    options: {
      values: (rootState) => getFacilityTypeValues(rootState),
      valueKey: (v) => v.ID,
      labelKey: 'NamePlural',
    },
    defaultValue: () => 0,
    validatejs: {
      ReservationsFacilityTypeID: {
        presence: {message: '^' + GM.REQUIRED},
        numericality: {
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: '^' + GM.REQUIRED,
        },
      }
    },
  },
  ReservationsYear: {
    ...getYearDefinition('ReservationsYear'),
    chainDependants: ['ReservationsFacilityTypeID'],
    isRequired: (rootState) => {
      return rootState.adminAccounts.selectedTab === 'reservations';
    },
  },
  ShowInactive: {
    key: 'ShowInactive',
    defaultValue: () => false,
  }
};
