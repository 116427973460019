import * as M from "../../../constants/messages/generic";
import {PASSWORD_MATCH} from "../../../constants/messages/createAccount";
import { Validator } from "../../../utils/validator/models";

export interface IValidator {
  CurrentPassword: Validator;
  NewPassword: Validator;
  ConfirmNewPassword: Validator;
}

export const FormDefinition: IValidator = {
  CurrentPassword: {
    key: 'CurrentPassword',
    isRequired: (rootState) => {
      return (
        !rootState.user.user.str_permissions.hasAdminAccess &&
        !rootState.cacheZero.options?.Login?.IsTemporaryPassword
      );
    },
    validatejs: {
      CurrentPassword: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          minimum: 3,
          maximum: 50,
          tooLong: M.MAX_LENGTH(50),
          tooShort: M.MIN_LENGTH(3),
        }
      }
    }
  },
  NewPassword: {
    key: 'NewPassword',
    equalityDependant: 'ConfirmNewPassword',
    validatejs: {
      NewPassword: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          minimum: 8,
          maximum: 50,
          tooLong: M.MAX_LENGTH(50),
          tooShort: M.MIN_LENGTH(8),
        }
      }
    }
  },
  ConfirmNewPassword: {
    key: 'ConfirmNewPassword',
    validatejs: {
      ConfirmNewPassword: {
        presence: {message: '^' + M.REQUIRED},
        equality: {
          attribute: "NewPassword",
          message: '^' + PASSWORD_MATCH
        },
        length: {
          minimum: 8,
          maximum: 50,
          tooLong: M.MAX_LENGTH(50),
          tooShort: M.MIN_LENGTH(8)
        }
      }
    }
  }
};
