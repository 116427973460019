import * as React from 'react';
import {default as AppHeader} from '../../../../App/Header';
import {FAC_TRIP_ADD} from "../../../../../constants/urls";
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { makeSelectedLocationSelector } from '../../../../../store/CacheTwoFacilities';
import { ApplicationState } from '../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & RouteComponentProps<{}, {}>;

const Header: React.SFC<ConnectedProps> = (props: ConnectedProps): React.ReactElement<undefined> => {
  const sp = props.location.pathname.split('/');
  const isAdd = sp[sp.length - 1] === FAC_TRIP_ADD;
  const subtitle = props.selectedLocation ? props.selectedLocation.Name : '';
  return <AppHeader
    showDrawer
    back={isAdd ? `/${sp[1]}/${sp[2]}/${sp[3]}/${sp[4]}` : `/${sp[1]}/${sp[2]}/${sp[3]}/${sp[4]}/${sp[5]}`}
    title={isAdd ? 'New Trip' : 'Manage Trip'}
    subTitle={subtitle}
    cart
  />;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedLocation = makeSelectedLocationSelector();
  return {
    selectedLocation: selectedLocation(state),
  };
};
const ConnectedHeader = connect(
  mapStateToProps,
  null,
  getMergeProps<WithRouterProps>(),
)(Header);

export default withRouter<{}>(ConnectedHeader);
