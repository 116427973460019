import type {AdminEventsEmailMessage, AdminEventsProduct, EventsEventType} from "../../models/api/adminEventsCacheOne";
import type {AdminEventsEventClass} from "../../models/api/adminEventsCacheThreeClass";
import type {AdminEventsCacheThreeClassType} from "../../models/api/adminEventsCacheThreeClassType";
import type {AdminEvent, AdminEventClassRequirements} from "../../models/api/adminEventsCacheTwoEvent";
import type {AdminEventsCacheOneState} from "../../store/AdminEvents/CacheOne";
import type {AdminEventsCacheThreeClassState} from "../../store/AdminEvents/CacheThreeClass";
import type {AdminEventsCacheThreeClassRequirementsState} from "../../store/AdminEvents/CacheThreeClassRequirements";
import type {AdminEventsCacheThreeClassTypeState} from "../../store/AdminEvents/CacheThreeClassType";
import type {AdminEventsCacheTwoEventState} from "../../store/AdminEvents/CacheTwoEvent";
import type {AdminEventsCacheTwoMessageState} from "../../store/AdminEvents/CacheTwoMessage";
import type {AdminEventsCacheTwoProductState} from "../../store/AdminEvents/CacheTwoProduct";
import type {AdminEventClassTypeActiveForm} from "../../store/AdminEvents/ClassTypes/Form";
import type {AdminEventClassActiveForm} from "../../store/AdminEvents/Classes/Form";
import type {EventTypeRowActiveForm} from "../../store/AdminEvents/EventTypes/EventType/Form";
import type {EventActiveForm} from "../../store/AdminEvents/Events/Event/Form";
import type {EnterClassRequirementModalState} from "../../store/AdminEvents/Events/Modals/EnterClassRequirement";
import type {AdminEventMessageActiveForm} from "../../store/AdminEvents/MessageCenter/Form";
import type {AdminEventProductActiveForm} from "../../store/AdminEvents/Products/Form";
import {reduxStoreService} from "../../store/service";
import {isCacheLevelPopulated} from "./common";

const MIN_REQUIRED_FIELDS_ADMIN_EVENTS_CACHE_ONE = 0;

const MIN_REQUIRED_FIELDS_ADMIN_EVENTS_PRODUCT = 0;
const MIN_REQUIRED_FIELDS_ADMIN_EVENTS_MESSAGE = 0;
const MIN_REQUIRED_FIELDS_ADMIN_EVENTS_EVENT = 0;
const MIN_REQUIRED_FIELDS_ADMIN_EVENTS_CLASS = 0;
const MIN_REQUIRED_FIELDS_ADMIN_EVENTS_CLASS_REQUIREMENTS = 0;
const MIN_REQUIRED_FIELDS_ADMIN_EVENTS_CLASS_TYPE = 0;

// Cache One
export function isAdminEventsCacheOnePopulated(
  adminEventsCacheOne?: AdminEventsCacheOneState,
  eventTypeActiveForm?: EventTypeRowActiveForm,
) {
  const rootState = reduxStoreService().getState();
  const cacheOne = adminEventsCacheOne ? adminEventsCacheOne : rootState.adminEvents.cacheOne;
  return isCacheLevelPopulated<EventsEventType>(
    cacheOne.EventsEventType,
    MIN_REQUIRED_FIELDS_ADMIN_EVENTS_CACHE_ONE,
    () => !eventTypeActiveForm || (!!cacheOne.EventsEventType && cacheOne.EventsEventType.EventTypeRow && eventTypeActiveForm.ID === cacheOne.EventsEventType.EventTypeRow.ID),
  );
}

// Cache Two
export function isAdminEventsCacheTwoMessagePopulated(cacheTwoMessage: AdminEventsCacheTwoMessageState, messageForm?: AdminEventMessageActiveForm) {
  return isCacheLevelPopulated<AdminEventsEmailMessage>(
    cacheTwoMessage.Message,
    MIN_REQUIRED_FIELDS_ADMIN_EVENTS_MESSAGE,
    () => !messageForm || (!!cacheTwoMessage.Message && cacheTwoMessage.Message.ID === messageForm.ID),
  );
};

export function isAdminEventsCacheTwoProductPopulated(cacheTwoProduct: AdminEventsCacheTwoProductState, productForm?: AdminEventProductActiveForm) {
  return isCacheLevelPopulated<AdminEventsProduct>(
    cacheTwoProduct.Product,
    MIN_REQUIRED_FIELDS_ADMIN_EVENTS_PRODUCT,
    () => !productForm || (!!cacheTwoProduct.Product && cacheTwoProduct.Product.IDi === productForm.IDi),
  );
};

export function isAdminEventsCacheTwoEventPopulated(cacheTwoEvent: AdminEventsCacheTwoEventState, adminEventForm?: EventActiveForm) {
  return isCacheLevelPopulated<AdminEvent>(
    cacheTwoEvent.EventsEvent,
    MIN_REQUIRED_FIELDS_ADMIN_EVENTS_EVENT,
    () => !adminEventForm || (!!cacheTwoEvent.EventsEvent && cacheTwoEvent.EventsEvent.IDi === adminEventForm.ID),
  );
}

// Cache Three
export function isAdminEventsCacheThreeClassPopulated(cacheThreeClass: AdminEventsCacheThreeClassState, classForm?: AdminEventClassActiveForm) {
  return isCacheLevelPopulated<AdminEventsEventClass>(
    cacheThreeClass.EventsEventClass,
    MIN_REQUIRED_FIELDS_ADMIN_EVENTS_CLASS,
    () => !classForm || (!!cacheThreeClass.EventsEventClass && cacheThreeClass.EventsEventClass.IDi === classForm.IDi),
  );
};

export function isAdminEventsCacheThreeClassRequirementsPopulated(
  cacheThreeClassRequirements: AdminEventsCacheThreeClassRequirementsState,
  enterClassRequirementsModalState?: EnterClassRequirementModalState,
) {
  return isCacheLevelPopulated<AdminEventClassRequirements>(
    cacheThreeClassRequirements.EventsEventClassRequirements,
    MIN_REQUIRED_FIELDS_ADMIN_EVENTS_CLASS_REQUIREMENTS,
    () => !enterClassRequirementsModalState || enterClassRequirementsModalState.ClassRequirements?.ActiveForm.ClassIDi === cacheThreeClassRequirements.EventsEventClassRequirements?.ClassIDi
  );
}

/**
 * This populated check is checking the # of fields under
 * `cacheThreeClassType.ClassType`, and it does NOT care about
 * `cacheThreeClassType.ClassType_AllMBRequirements` at all
 */
export function isAdminEventsCacheThreeClassTypePopulated(cacheThreeClassType: AdminEventsCacheThreeClassTypeState, classTypeForm?: AdminEventClassTypeActiveForm) {
  return isCacheLevelPopulated<AdminEventsCacheThreeClassType>(
    cacheThreeClassType.ClassType,
    MIN_REQUIRED_FIELDS_ADMIN_EVENTS_CLASS_TYPE,
    () => !classTypeForm || (!!cacheThreeClassType.ClassType && cacheThreeClassType.ClassType.IDi === classTypeForm.IDi),
  );
}