import { GetBlackoutDateParams } from "../../../constants/urls";
import { CLEAR_CACHE_PREFIX, createRequestActions } from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { HideDrawer, SilentCancelAllAction } from '../../../store/App/actions';
import { ADMIN_SETTINGS_SUFFIX } from "../CacheOne/actions";
import { UPDATE_GL_ACCOUNT_FORM_SUFFIX } from "../GLAccounts/GLAccount/Form/actions";
import { typeName, Action } from "../../../utils/StrongActions";


export const SUFFIX = ADMIN_SETTINGS_SUFFIX + '__CACHE_TWO_GL_ACCOUNT' + UPDATE_GL_ACCOUNT_FORM_SUFFIX;
export const SUFFIX_SAVE = ADMIN_SETTINGS_SUFFIX + '__CACHE_TWO_GL_ACCOUNT_SAVING';

export const GetGLAccount = createRequestActions(SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminSettingsCacheTwoGLAccount extends Action { }

export type Actions = typeof actionCreators;
export const actionCreators = {
  getGLAccount: (params: GetBlackoutDateParams): ActionCreator => dispatch => {
    dispatch(GetGLAccount.request(params));
  },
  refreshGLAccount: (params: GetBlackoutDateParams): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    dispatch(GetGLAccount.request(params));
  },
  clearAdminSettingsCacheTwoGLAccount: (): ActionCreator => dispatch => {
    dispatch(new ClearAdminSettingsCacheTwoGLAccount());
  },
};