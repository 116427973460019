import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminFacilityLocationCacheOneContext } from "@tentaroo/shared";

import { ApplicationState } from '../../../../../store';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as reportsActionCreators, ReportGroupTripsActions, ReportGroupReservationsActions } from '../../../../../store/AdminFacilityLocation/Reports/actions';
import { actionCreators, GetFacilityLocationCache } from "../../../../../store/AdminFacilityLocation/CacheOne/actions";
import { LoadingAll, Button, Form, Row, Column, DatePicker, RadioGroupOption, Text, Checkbox, Alert, PageLoader } from '../../../../Elements';
import { Main, MainContent } from '../../../../Layouts';
import '../../../../../styles/pages/admin-facility-location/reports/home/index.scss';
import { FacilitiesReportType } from '../../../../../store/AdminFacilityLocation/Reports/validation';
import { formatDate } from '../../../../../utils/dateHelper';
import AdminFacilitiesLocationCacheManager from '../../../../../utils/cacheManagers/adminFacilitiesLocationCacheManager';
import { IAdminFacilitiesLocationRouterParams } from '../../../../../utils/helpers/adminFacilityLocationPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { noOpenedModals } from '../../../../../utils/modalHelper';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isAdminFacilitiesLocationCacheOnePopulated} from '../../../../../utils/cachePopulatedCheckers/adminFacilities';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--facility-location--reports';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}>
>;

class ReportsHome extends ComponentUpdateTemplate<ConnectedProps, {}> {
  public props: ConnectedProps;

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        AdminFacilitiesLocationCacheManager.getInstance().loadAdminFacilitiesLocationCacheOne({
          props: this.props,
          isStateNavigated,
          context: AdminFacilityLocationCacheOneContext.REPORTS,
        });
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        AdminFacilitiesLocationCacheManager.getInstance().loadAdminFacilitiesLocationCacheOne({
          props: nextProps,
          isStateNavigated,
          context: AdminFacilityLocationCacheOneContext.REPORTS,
        });
      }
    );
  }

  onChangeIncludeBlackoutDates = (context: FacilitiesReportType) => {
    this.props.actions.toggleIncludeBlackoutDates(context);
  };
  onGenerateReport = () => {
    const {adminFacilityLocationCacheOne, reportsForm: {ActiveForm}, actions} = this.props;

    if (!adminFacilityLocationCacheOne.FacilitiesLocation || !adminFacilityLocationCacheOne.FacilitiesLocation.ID) return;

    actions.apiSubmitForm(() => {
      if (!adminFacilityLocationCacheOne.FacilitiesLocation || !adminFacilityLocationCacheOne.FacilitiesLocation.ID) return;
      switch (ActiveForm.SelectedReportType) {
        case FacilitiesReportType.TRIP_ITINERARY:
          this.props.actions.reportTripItinerary(adminFacilityLocationCacheOne.FacilitiesLocation.ID, ActiveForm.StartDate, ActiveForm.EndDate);
          break;
        case FacilitiesReportType.GROUP_TRIPS:
          this.props.actions.reportGroupTrips(adminFacilityLocationCacheOne.FacilitiesLocation.ID, formatDate(ActiveForm.StartDate), formatDate(ActiveForm.EndDate), ActiveForm.IncludeBlackoutDates1);
          break;
        case FacilitiesReportType.GROUP_RESERVATIONS:
          this.props.actions.reportGroupReservations(adminFacilityLocationCacheOne.FacilitiesLocation.ID, formatDate(ActiveForm.StartDate), formatDate(ActiveForm.EndDate), ActiveForm.IncludeBlackoutDates2);
          break;
      }
    });
  };

  isFormDisabled = () => {
    const {adminFacilityLocationCacheOne } = this.props;

    if (!adminFacilityLocationCacheOne.FacilitiesLocation) return true;

    return !!!adminFacilityLocationCacheOne.FacilitiesLocation.hasFacilityReports;
  };

  public render() {
    const { inert, reportsForm: {ActiveForm, ValidationRules, SubmitErrorMessage}, actions, apiLoadingMap, apiSaving, adminFacilityLocationCacheOne } = this.props;
    if (!isAdminFacilitiesLocationCacheOnePopulated()) {
      return <LoadingAll />;
    }

    const loading = (
      apiLoadingMap[GetFacilityLocationCache.requestType] || apiLoadingMap[ReportGroupTripsActions.requestType] || apiLoadingMap[ReportGroupReservationsActions.requestType]
    ) && noOpenedModals();

    const hasError = (ValidationRules.StartDate.errors && ValidationRules.StartDate.errors.length > 0) || (ValidationRules.EndDate.errors && ValidationRules.EndDate.errors.length > 0);
    return (
      <Main inert={inert} isLoading={loading} isAdminFacilityLocationPage>
        <MainContent className={namespace()} handleCompact>
          <Form
            onSubmit={e => e.preventDefault()}
          >
            {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
            {adminFacilityLocationCacheOne.FacilitiesLocation && adminFacilityLocationCacheOne.FacilitiesLocation.hasFacilityReportsReason ? <Alert className={`${namespace()}--alert`}>{adminFacilityLocationCacheOne.FacilitiesLocation.hasFacilityReportsReason}</Alert> : null}
            <Row wrap={false} marginBottom={16} mobileMarginBottom={16}>
              <Column marginRight={24} mobileMarginRight={24} span={6} mobileSpan={6}>
                <DatePicker
                  label="Start Date"
                  disabled={this.isFormDisabled()}
                  onChangeRaw={actions.simpleUpdate}
                  onSelect={actions.updateValue}
                  value={ActiveForm.StartDate}
                  validationRules={ValidationRules.StartDate}
                  popperPlacement='bottom-end'
                  popperModifiers={{
                    flip: {
                        behavior: ["bottom"] // don't allow it to flip to be above
                    },
                    preventOverflow: {
                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                        enabled: false // turn off since needs preventOverflow to be enabled
                    }
                  }}
                  removeMax />
              </Column>
              <Column span={6} mobileSpan={6}>
                <DatePicker
                  label="End Date"
                  disabled={this.isFormDisabled()}
                  onChangeRaw={actions.simpleUpdate}
                  onSelect={actions.updateValue}
                  value={ActiveForm.EndDate}
                  validationRules={ValidationRules.EndDate}
                  popperPlacement='bottom-end'
                  popperModifiers={{
                    flip: {
                        behavior: ["bottom"] // don't allow it to flip to be above
                    },
                    preventOverflow: {
                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                        enabled: false // turn off since needs preventOverflow to be enabled
                    }
                  }}
                  removeMax />
              </Column>
            </Row>
            {!hasError && <div className={`${namespace()}--helper-text`}>Reports will include any trips or reservations that overlap with this date range.</div>}
            <Row>
              <Column layout='horizontal' span={12} mobileSpan={12}>
                <RadioGroupOption
                  newDesign
                  value={ActiveForm.SelectedReportType === FacilitiesReportType.TRIP_ITINERARY}
                  rawValue={FacilitiesReportType.TRIP_ITINERARY}
                  ValidationRules={ValidationRules.SelectedReportType}
                  onChange={actions.updateValue}
                  selected={ActiveForm.SelectedReportType === FacilitiesReportType.TRIP_ITINERARY}
                  disabled={this.isFormDisabled()}
                  label='Trip Itinerary (PDF)'
                />
              </Column>
            </Row>
            <Row>
              <Column layout='horizontal' span={12} mobileSpan={12}>
                <RadioGroupOption
                  newDesign
                  value={ActiveForm.SelectedReportType === FacilitiesReportType.GROUP_TRIPS}
                  selected={ActiveForm.SelectedReportType === FacilitiesReportType.GROUP_TRIPS}
                  disabled={this.isFormDisabled()}
                  rawValue={FacilitiesReportType.GROUP_TRIPS}
                  ValidationRules={ValidationRules.SelectedReportType}
                  onChange={actions.updateValue}
                  label='Group Trips (Excel/CSV)'
                />
              </Column>
            </Row>
            {ActiveForm.SelectedReportType === FacilitiesReportType.GROUP_TRIPS ? (
              <div className={`${namespace()}--checkbox`}>
                <Checkbox
                  value={ActiveForm.IncludeBlackoutDates1 ? '1' : '0'}
                  disabled={this.isFormDisabled()}
                  selected={ActiveForm.IncludeBlackoutDates1}
                  onChange={() => this.onChangeIncludeBlackoutDates(FacilitiesReportType.GROUP_TRIPS)}
                  newDesign
                  label={<Text color='dark-gray' weight="regular" size={16} marginBottom={0}>Include Blackout Dates</Text>}
                />
              </div>
            ) : null}
            <Row>
              <Column layout='horizontal' span={12} mobileSpan={12}>
                <RadioGroupOption
                  newDesign
                  value={ActiveForm.SelectedReportType === FacilitiesReportType.GROUP_RESERVATIONS}
                  selected={ActiveForm.SelectedReportType === FacilitiesReportType.GROUP_RESERVATIONS}
                  disabled={this.isFormDisabled()}
                  rawValue={FacilitiesReportType.GROUP_RESERVATIONS}
                  ValidationRules={ValidationRules.SelectedReportType}
                  onChange={actions.updateValue}
                  label='Group Reservations (Excel/CSV)'
                />
              </Column>
            </Row>
            {ActiveForm.SelectedReportType === FacilitiesReportType.GROUP_RESERVATIONS ? (
              <div className={`${namespace()}--checkbox`}>
              <Checkbox
                value={ActiveForm.IncludeBlackoutDates2 ? '1' : '0'}
                disabled={this.isFormDisabled()}
                selected={ActiveForm.IncludeBlackoutDates2}
                onChange={() => this.onChangeIncludeBlackoutDates(FacilitiesReportType.GROUP_RESERVATIONS)}
                newDesign
                label={<Text color='dark-gray' weight="regular"  size={16} marginBottom={0}>Include Blackout Dates</Text>}
              />
              
              </div>
            ) : null}
            <Row marginTop={24} mobileMarginTop={16}>
              <Button disabled={this.isFormDisabled()} onClick={this.onGenerateReport} textColor='white' color='green'>GENERATE REPORT</Button>
            </Row>
          </Form>
        </MainContent>
        {apiSaving > 0 && <PageLoader fullScreen />}
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
    reportsForm: state.adminFacilityLocation.reports,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...reportsActionCreators,
  }, dispatch),
});

const ConnectedReportsHome = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(ReportsHome);

export default withRouter<{}>(ConnectedReportsHome);
