import * as Actions from './actions';
import * as moment from 'moment';
import {API_DATE_FORMAT, getPrevAndNext} from "../../../utils/dateHelper";
import {SetCacheAction} from "../../App/actions";
import {GetFacilityAvailabilitiesB, SetCacheTwoBFacilities} from "../../CacheTwoBFacilities/actions";
import {APIFailure, APISuccess} from "../../Validation/actionCreator";
import { Reducer } from 'redux';
import { Action, isActionType } from '../../../utils/StrongActions';

export interface FacilityFacilityState {
  startDate: moment.Moment;
  showContextMenu: boolean;
  disableNext: boolean;
  disablePrev: boolean;
}

const FacilityFacilityReducer: Reducer<FacilityFacilityState> = (state: FacilityFacilityState, action: Action) => {
  if (action.type === GetFacilityAvailabilitiesB.successType) {
    const successAction = <APISuccess> action;
    if (successAction.response.response.FacilityAvailabilitiesDates) {
      const FacilityAvailabilitiesDates = successAction.response.response.FacilityAvailabilitiesDates;
      const startDate = moment(FacilityAvailabilitiesDates[0].Date, API_DATE_FORMAT).startOf('month');
      const prevAndNext = getPrevAndNext(startDate);
      return {
        ...state,
        startDate: startDate,
        disablePrev: prevAndNext.disablePrev,
        disableNext: prevAndNext.disableNext,
      };
    }
    return state;
  } else if (isActionType(action, SetCacheAction)) {
    const successAction = <APIFailure> action;
    if (successAction.response.xhr.response.FacilityAvailabilitiesDates) {
      const FacilityAvailabilitiesDates = successAction.response.response.FacilityAvailabilitiesDates;
      const startDate = moment(FacilityAvailabilitiesDates[0].Date, API_DATE_FORMAT).startOf('month');
      const prevAndNext = getPrevAndNext(startDate);
      return {
        ...state,
        startDate: startDate,
        disablePrev: prevAndNext.disablePrev,
        disableNext: prevAndNext.disableNext,
      };
    }
    return state;
  } else if (isActionType(action, Actions.ChangeDate)) {
    const newState = {...state, startDate: action.date};
    const prevAndNext = getPrevAndNext(action.date);
    newState.disablePrev = prevAndNext.disablePrev;
    newState.disableNext = prevAndNext.disableNext;
    return newState;
  } else if (isActionType(action, Actions.ShowMonthMenu)) {
    return {...state, showContextMenu: action.show};
  } else if (isActionType(action, SetCacheTwoBFacilities)) {
    return {
      ...state,
      startDate: action.startDate,
    };
  } else if (isActionType(action, Actions.FacilitiesFacilityReset)) {
    const FacilityAvailabilitiesDates = action.rootState.cacheTwoBFacilities.FacilityAvailabilitiesDates;
    let startDate = moment().startOf('month');
    if (FacilityAvailabilitiesDates && FacilityAvailabilitiesDates.length > 0) {
      startDate = moment(FacilityAvailabilitiesDates[0].Date, API_DATE_FORMAT).startOf('month');
    }
    const prevAndNext = getPrevAndNext(startDate);
    return {
      startDate: startDate,
      disablePrev: prevAndNext.disablePrev,
      disableNext: prevAndNext.disableNext,
      showContextMenu: false
    };
  }
  return state || {
    startDate: moment().startOf('month'),
    disablePrev: true,
    disableNext: false,
    showContextMenu: false
  };
};

export default FacilityFacilityReducer;
