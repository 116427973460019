import * as React from 'react';
import {
  Button,
  Card,
  CardPadding,
  CardSubTitle,
  CardTitle,
  Column,
  ContextMenu,
  ContextMenuItem,
  FinancialSummaryTotal,
  Icon,
  Media,
  Row,
  Separator,
  Text,
  View
} from '../../../../../Elements';
import {FacilityLocation_FacilityType} from "../../../../../../models/api/cacheTwoFacilties";
import {standardCurrencyFormat} from "../../../../../../utils/classesHelper";
import {CartIcon as CartIconIcon} from "../../../../../Icons";
import {getSubTitleLower} from "../../Summary/Reservations";
import {RouteComponentProps, withRouter} from "react-router";
import {navPush} from "../../../../../../utils";
import {FAC_TRIP_RESERVATION_EDIT} from "../../../../../../constants/urls";
import {FacilityTripPermissions} from "../../../../../../models/api/cacheThreeFacilities";
import {ShowTopFloatingAlert} from "../../../../../../store/App/actions";
import {NO_BOOKING} from "../../../../../../constants/messages/generic";
import {SaveState} from "../../../../../../store/Rollback/actions";
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { reduxStoreService } from '../../../../../../store/service';

interface Props {
  title: string;
  subtitle?: string;
  Amount: number;
  CheckinTime: string;
  CheckoutTime: string;
  NumYouth: number;
  NumAdults: number;
  selectedFacilityType: FacilityLocation_FacilityType;
  showCart?: boolean;
  hideManage?: boolean; // todo remove this and use onCancel existence
  onCancel?: () => void;
  FacilityTripPermissions?: FacilityTripPermissions;
  isAdmin?: boolean;
  AllowOnlineFacilityBooking?: boolean;
  clearCacheThreeAndBelow?: () => void;
}

type ConnectedProps = RouteComponentProps<{}, {locId: string; tripId: string; name: string; resId?: string; resName?: string;}>;

class FacilityDetails extends React.Component<Props,{}> {
  props: Props & ConnectedProps;

  onEdit = () => {
    const {isAdmin, FacilityTripPermissions, AllowOnlineFacilityBooking} = this.props;
    if (FacilityTripPermissions) {
      if (!FacilityTripPermissions!.hasAllowChange) {
        reduxStoreService().dispatch(new ShowTopFloatingAlert(FacilityTripPermissions!.hasAllowChangeReason!, false, 'orange'));
      } else if (!isAdmin && !AllowOnlineFacilityBooking) {
        reduxStoreService().dispatch(new ShowTopFloatingAlert(NO_BOOKING, false, 'orange'));
      } else {
        if (this.props.clearCacheThreeAndBelow) this.props.clearCacheThreeAndBelow();
        reduxStoreService().dispatch(new SaveState());
        navPush(this.props.router, `${this.props.location.pathname}/${FAC_TRIP_RESERVATION_EDIT}`);
      }
    }
  };

  onCancel = () => {
    if (this.props.onCancel) {
      // NOTE: No save state here, because in `onCancel` we gonna do SaveState
      // when pushing modal
      this.props.onCancel();
    }
  };

  renderCardControls = () => {
    return <Media tablet desktop>
      <Button
        id={generateDOMId("manage-reservation-btn")}
        color="white"
        contextMenu={(
          <ContextMenu>
            <ContextMenuItem id={generateDOMId("edit-reservation-btn")} onClick={this.onEdit}>Edit Reservation</ContextMenuItem>
            <ContextMenuItem id={generateDOMId("cancel-reservation-btn")} onClick={this.onCancel}>Cancel Reservation</ContextMenuItem>
          </ContextMenu>
        )}
      >
        Manage
      </Button>
    </Media>;
  };

  renderManageRegistration = () => (
    <Media mobile>
      <Button
        color="white"
        contextMenu={(
          <ContextMenu title="Manage Reservation">
            <ContextMenuItem onClick={this.onEdit}>Edit Reservation</ContextMenuItem>
            <ContextMenuItem onClick={this.onCancel}>Cancel Reservation</ContextMenuItem>
          </ContextMenu>
        )}
        marginTop={8}
        marginBottom={16}
      >
        Manage
      </Button>
    </Media>
  );

  render() {
    const {title, subtitle, Amount, selectedFacilityType, showCart, NumYouth, NumAdults, CheckinTime, CheckoutTime, hideManage} = this.props;
    return <Card template="mobile-stretch" marginBottom={32} mobileMarginBottom={0} padding="none">
      <CardPadding layout="vertical" mobilePaddingTop={0}>
        <CardTitle
          marginBottom={4}
          extra={showCart ? <Icon marginLeft={14} mobileMarginLeft="auto" size={28} color="green" icon={CartIconIcon}/> : undefined}
          controls={!hideManage ? this.renderCardControls() : undefined}
        >
          {title}
        </CardTitle>

        {subtitle && <CardSubTitle>{subtitle}</CardSubTitle>}
        {!hideManage && this.renderManageRegistration()}
        <Row>
          <Column span={6} mobileSpan={12} layout="vertical">
            <View layout="vertical" marginBottom={8}>
              <Text color="gray" size={14}>{selectedFacilityType.IsFacilityType ? 'Check-in' : 'Starts'}</Text>
              <Text weight="medium" marginBottom={0}>{CheckinTime}</Text>
            </View>
            <View layout="vertical" marginBottom={0} mobileMarginBottom={8}>
              <Text color="gray" size={14}>{selectedFacilityType.IsFacilityType ? 'Check-out' : 'End'}</Text>
              <Text weight="medium" marginBottom={0}>{CheckoutTime}</Text>
            </View>
          </Column>
          <Column span={6} mobileSpan={12} layout="vertical">
            <View layout="vertical" marginBottom={8}>
              <Text color="gray" size={14}>Participants</Text>
              <Text weight="medium" marginBottom={0}>{getSubTitleLower({NumYouth, NumAdults})}</Text>
            </View>
          </Column>
        </Row>
      </CardPadding>
      <Separator mobileMarginVertical={16}/>
      <CardPadding layout="vertical" paddingTop={0}>
        {Amount !== undefined && <FinancialSummaryTotal
          title={selectedFacilityType.NameSingular}
          value={standardCurrencyFormat(Amount)}
          noMarginBottom
        />}
      </CardPadding>
    </Card>;
  }
}

export default withRouter(FacilityDetails as any);
