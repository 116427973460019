import { createSelector } from 'reselect';
import { SelectMessageModalFilter, SelectMessageToggleFilterTabs, ResetSelectMessageModal } from './actions';
import { ApplicationState } from '../../../..';
import { IValidator, FormDefinition } from './validation';
import { apiValidationReducerCreator } from '../../../../Validation/reducerCreator';
import { SUFFIX } from './actions';
import { AdminEventsEmailMessage } from '../../../../../models/api/adminEventsCacheOne';
import { messagesSelector } from '../../Home';
import { sortByName } from '../../../../CacheZero';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface SelectMessageModalActiveFormState {
}

export interface SelectMessageModalState {
  isRefreshing: boolean;
  filterString: string;
  isTextSearching: boolean;
  ValidationRules: IValidator;
  ActiveForm: SelectMessageModalActiveFormState;
}
const getInitialState = () => ({
  isRefreshing: false,
  filterString: '',
  isTextSearching: false,
  ValidationRules: {...FormDefinition},
  ActiveForm: {},
});

const filterSelector = (state: ApplicationState) => state.adminEvents.messageCenter.modals.selectMessage.filterString;

export const makeFilteredMessagesSelector = () => {
  return createSelector(
    [messagesSelector, filterSelector],
    (messages: AdminEventsEmailMessage[], filterStr: string) => {
      if (!messages) return [];
      const f = filterStr.toLowerCase();
      const result = messages.filter((a) => {
        const nameMatch = f ? a.Name.toLowerCase().includes(f) : true;
        return !a.Inactive && nameMatch;
      });
      
      result.sort(sortByName);

      return result;
    }
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const SelectMessageModalReducer: Reducer<SelectMessageModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);
  if (isActionType(act, SelectMessageToggleFilterTabs)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, SelectMessageModalFilter)) {
    return {
      ...state,
      filterString: act.filterString,
    };
  } else if (isActionType(act, ResetSelectMessageModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default SelectMessageModalReducer;