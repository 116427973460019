import * as React from 'react';
import {withRouter, RouteComponentProps} from "react-router";

import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import moment from 'moment';
import Month from './Month';
import '../../../../styles/elements/month/compact/range.scss';
import {FacilityLocation} from "../../../../models/api/cacheOne";
import {
  Availability, FacilitiesAvailabilitiesDate,
  FacilitiesAvailability
} from "../../../../models/api/cacheTwoFacilties";

import {rangeNamespace as namespace} from "./constants";
import {Nullable} from '../../../../utils/dataHelper';

export interface Props {
  title?: React.ReactNode | string;
  start: moment.Moment;
  end: moment.Moment;
  availabilities?: Array<Availability>;
  availabilityDates?: Array<FacilitiesAvailabilitiesDate>;
  facilityLocation: FacilityLocation;
  facilitiesAvailability: FacilitiesAvailability;
  onClickTitle: (avail: FacilitiesAvailability) => void;
  showAdminBadge?: boolean;
}

type ConnectedProps = RouteComponentProps<{}, {}>

interface State {
  currentMonth: number;
}

class Range extends React.Component<Props, State> {
  public props: Props & ConnectedProps;
  public static contextTypes = {
    addTitle: PropTypes.func,
    addOnClickTitle: PropTypes.func,
    onScroll: PropTypes.func,
    addSubTitle: PropTypes.func,
    removeSubTitle: PropTypes.func,
    setShowAdminBadge: PropTypes.func,
  };

  public state: State = {
    currentMonth: 0
  };

  public componentWillMount() {
    if (this.props.title) {
      this.context.addTitle(this.props.title);
      this.context.addOnClickTitle(() => this.props.onClickTitle(this.props.facilitiesAvailability));
    }
    this.context.setShowAdminBadge(this.props.showAdminBadge);
    this.context.onScroll(this.handleScroll);
  }

  public componentDidMount() {
    this.takeMeasurements();
    window.addEventListener('resize', this.takeMeasurements);
    const month = moment(this.props.start).startOf('month').add(this.state.currentMonth, 'months');
    this.context.addSubTitle(month.format('MMM YYYY'));
  }

  public componentWillReceiveProps(nextProps) {
    if (!nextProps.start.isSame(this.props.start, 'year') || !nextProps.start.isSame(this.props.start, 'month')) {
      const month = moment(nextProps.start).startOf('month');
      // const month = moment(nextProps.start).startOf('month').add(this.state.currentMonth, 'months');
      this.context.addSubTitle(month.format('MMM YYYY'));
      this.state.currentMonth = 0;
      document.getElementsByClassName('elements--compact-month-range-group--scrollable')[0].scrollLeft = 0;
    }
  }

  public componentDidUpdate() {
    this.takeMeasurements();
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.takeMeasurements);
  }

  private measurements: Array<number> = [];
  public takeMeasurements = () => {
    const months = (findDOMNode(this) as Nullable<Element>)?.getElementsByClassName(`${namespace()}--months--month`) as Nullable<HTMLCollection>;
    if (!months) return;

    this.measurements = [];
    for (let i = 0, len = months.length; i < len; ++i) {
      this.measurements.push(months[i].getBoundingClientRect().width);
    }
  };

  public handleScroll = (position: number) => {
    let width = 0;
    let currentMonth;
    for (let i = 0, len = this.measurements.length; i < len; ++i) {
      width += this.measurements[i];
      if (position <= width) {
        currentMonth = i;
        break;
      }
    }
    if (this.state.currentMonth !== currentMonth) {
      const month = moment(this.props.start).startOf('month').add(currentMonth, 'months');
      this.context.addSubTitle(month.format('MMM YYYY'));
      this.setState({ currentMonth });
    }
  };

  public render() {
    const { availabilities, start, end, showAdminBadge } = this.props;
    const { currentMonth } = this.state;
    let startMoment: moment.Moment, endMoment: moment.Moment;
    startMoment = start;
    endMoment = end;
    const numMonths = endMoment.diff(startMoment, 'months');
    let offsets: Array<number> = [0];
    let runningOffset = 0;
    const countMoment = moment(startMoment);
    for (let i = 0; i < numMonths; i++) {
      runningOffset = runningOffset + countMoment.daysInMonth();
      offsets.push(runningOffset);
      countMoment.add(1, 'months');
    }

    return (
      <div className={namespace()}>
        {this.props.title ? <div className={`${namespace()}--title`} />: null}
        <div className={`${namespace()}--months`}>
          {Array.apply(null, Array(numMonths)).map((value, index) => {
            const month = moment(startMoment).startOf('month').add(index, 'months');
            return (
              <div className={`${namespace()}--months--month`} key={index}>
                <div className={`${namespace()}--months--month--header`}>
                  <div
                    className={`${namespace()}--months--month--header--text ${index === currentMonth ? ' sticky' : ''}`}
                  >
                    {month.format('MMM YYYY')}
                  </div>
                </div>
                <Month
                  month={month}
                  availabilities={availabilities}
                  offset={offsets[index]}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(Range as any);
