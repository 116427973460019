import * as M from '../../../../../constants/messages/generic';
import { FormDefinition as ClassesFormDefinition } from '../../Event/Classes/validation';
import { SelectValidator, Validator } from "../../../../../utils/validator/models";

export interface IValidator {
  IncludeBlankRows: Validator;
  SelectedClass: Validator;
  KindOfClass: SelectValidator;
  RegisterUnder: SelectValidator;
  ParticipantFilterText: Validator;
}

export const FormDefinition: IValidator = {
  IncludeBlankRows: {
    key: 'IncludeBlankRows',
  },
  SelectedClass: {
    key: 'SelectedClass',
  },
  KindOfClass: ClassesFormDefinition.KindOfClass,
  RegisterUnder: {
    ...ClassesFormDefinition.RegisterUnder,
    defaultValue: () => 2,
  },
  ParticipantFilterText: {
    key: 'ParticipantFilterText',
  },
};

export interface IClassRequirementValidator {
  InstructorName: Validator;
  InstructorInitials: Validator;
}
export const ClassRequirementFormDefinition: IClassRequirementValidator = {
  InstructorName: {
    key: 'InstructorName',
    validatejs: {
      InstructorName: {
        length: {
          maximum: 200,
          tooLong: M.MAX_LENGTH(200)
        },
      },
    },
  },
  InstructorInitials: {
    key: 'InstructorInitials',
    validatejs: {
      InstructorInitials: {
        length: {
          maximum: 5,
          tooLong: M.MAX_LENGTH(5)
        },
      },
    },
  },
};