import * as React from 'react';
import Button from './index';
import '../../../styles/elements/button/show-more-button.scss';

export const namespace = (): string => 'elements--show-more-button';

interface Props {
  showLess?: boolean;
  onClick?: React.ReactEventHandler<any>;
}

const ShowMoreButton: React.SFC<Props> = ({ showLess, onClick, ...props }: Props): React.ReactElement<Props> => {
  return (
    <Button
      marginLeft={36}
      mobileMarginLeft={0}
      ripple
      className={namespace()}
      onClick={onClick}
      flat
    >
      {showLess ? 'SHOW LESS' : 'SHOW MORE'}
    </Button>
  );
};

export default ShowMoreButton;
