import * as React from 'react';
import { Tab as MdlTab } from 'react-mdl';
import { withRouter, InjectedRouter } from 'react-router';
import { namespace } from './constants';
import { navPush } from '../../../../utils';
import {noop} from "../../../../utils/noop";

interface Props {
  children?: React.ReactNode;
  to?: string; // This to is properly handled with navPush
  onClick?: () => void;
  selected?: boolean;
  counter?: number;
  id?: string;
}

const Tab: React.SFC<Props> = ({ children, id, to, onClick, selected, counter, router }: Props & { router: InjectedRouter }): React.ReactElement<Props> => (
  <MdlTab
    id={id}
    cssPrefix="mdl-tabs"
    active={selected}
    onTabClick={to ? () => navPush(router, to) : (onClick ? onClick : noop)}
    className={`${namespace()}--tabs--tab` + (selected ? ' selected' : '')}
  >
    <span>{children}</span>
    {!!counter && <span className={`${namespace()}--tabs--tab--counter`}>{counter}</span>}
  </MdlTab>
);

export default withRouter(Tab);
