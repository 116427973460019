import * as React from 'react';
import { Button, Text } from '../../../../Elements';
import {actionCreators as profileActionCreators} from "../../../../../store/Settings/Profile/actions";
import {actionCreators} from "../../../../../store/Settings/ChangePassword/actions";
import {appActionCreators, ApplicationState} from "../../../../../store";
import {bindActionCreators} from 'redux';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { ConfirmationModal, ConfirmationModalActions, ConfirmationModalContent } from '../../../../Templates';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { connect } from 'react-redux';
import { reduxStoreService } from '../../../../../store/service';
import { WithInertAttribute } from '../../../../Elements/WithInert';

type Props = WithInertAttribute<{}>

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class ConfirmSendResetLink extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  componentWillUnmount() {
  }

  onCloseClick = () => {
    reduxStoreService().dispatch(appActionCreators.popModal(false, true, ModalTypes.CONFIRM_SEND_RESET_LINK) as any);
  };

  onSave = () => {
    reduxStoreService().dispatch(appActionCreators.popModal(true, false, ModalTypes.CONFIRM_SEND_RESET_LINK) as any);
    this.props.actions.sendResetLink();
  };

  public render() {
    const {apiSaving, inert} = this.props;
    return (
      <ConfirmationModal
        inert={inert}
        titleAlignLeft={true}
        onClose={this.onCloseClick}
        title="Send Reset Link?">
        <ConfirmationModalContent contentAlignLeft={true}>
          <Text color="gray" marginBottom={16}>This will send a password reset link to the email(s) in the account profile.</Text>
        </ConfirmationModalContent>
        <ConfirmationModalActions
          noPadding
          stacked
          alignRight
          loading={apiSaving > 0}
          left={<Button marginRight={8} id={generateDOMId("close-send-reset-link")} flat onClick={this.onCloseClick}>Cancel</Button>}
          right={<Button textColor='red' flat onClick={this.onSave}>Send</Button>}
        />
      </ConfirmationModal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...profileActionCreators, ...actionCreators }, dispatch)
});
const ConnectedConfirmSendResetLink = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ConfirmSendResetLink);
export default ConnectedConfirmSendResetLink;
