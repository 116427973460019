import {FacilityLocation} from "../models/api/cacheOne";
import {
  EventRegistrationCampsiteAssignment,
  EventRegistrationPerson,
  NumberProposed,
  PostEventRegistrationClass,
  RegistrationFields
} from "../models/api/cacheFourEvents";
import {bie, nie, spaceTo_, zie} from "../utils/stringHelper";
import {API_DATE_FORMAT, convertTimeDigit, constructDateTime, formatDate} from "../utils/dateHelper";
import {PersonFormActiveForm} from "../utils/personFormValidationRules";
import {EventPersonActiveForm} from "../store/Events/Event/Register/Participant/Roster";
import {TripActiveForm} from "../store/Facilities/Trip/Form";
import * as moment from 'moment';
import {FullGroupRosterPerson, GroupRosterPerson} from "../models/api/cacheTwoRoster";
import {PreviousOrderItem} from "../models/api/cacheTwoPrevOrders";
import {
  getAppliedCreditKey,
  getInactiveKey,
  getTotalAmountKey
} from "../store/Settings/PrevOrders/ManageOrder/uiHelpers";
import {RecalcProduct} from "../models/product";
import { CMSPagesFilters, CMSResource, CMSContact, CMSSiteMenuItem, CMSPageMenuItem, CMSPage, PageTypeIDValue } from '../models/api/adminCMSCacheOne';
import { ApplicationState } from '../store';
import { AdminCMSSiteCacheOneState } from '../store/AdminCMSSite/CacheOne';
import { ResourceActiveForm } from '../store/AdminCMSSite/Resources/Resource/Form';
import { ResourceCategoryActiveForm } from '../store/AdminCMSSite/Resources/Modals/ResourceCategoryForm';
import { ContactActiveForm } from '../store/AdminCMSSite/Contacts/Contact/Form';
import { CMSSocial } from '../models/api/adminCMSCacheOne';
import { SiteMenuItemActiveForm } from '../store/AdminCMSSite/Menus/SiteMenuItem/Form';
import { ImageActiveForm } from '../store/AdminCMSSite/Pages/Modals/ImageForm';
import { LocationActiveForm } from '../store/AdminSettings/Modals/LocationForm';
import { NewPageModalActiveForm } from '../store/AdminCMSSite/Pages/Modals/NewPage';
import { PageActiveForm } from '../store/AdminCMSSite/Pages/Page/Form';
import { GeneralSettingsActiveForm } from '../store/AdminCMSSite/Settings/General';
import { DuplicatePageActiveForm } from '../store/AdminCMSSite/Pages/Modals/DuplicatePageForm';
import { BlackoutDatesFilters, AdminFacilityType, AdminFacility, FacilitiesBlackoutDate } from '../models/api/adminFacilitiesCacheOne';
import { AdminFacilityLocationCacheOneState } from '../store/AdminFacilityLocation/CacheOne';
import { GLAccountActiveForm } from '../store/AdminSettings/GLAccounts/GLAccount/Form';
import { FacilitiesSettingsActiveForm } from '../store/AdminFacilityLocation/Settings';
import { BlackoutDateActiveForm } from '../store/AdminFacilityLocation/BlackoutDates/Form';
import { NewFacilityModalActiveForm } from '../store/AdminFacilityLocation/Facilities/Modals/NewFacility';
import { captureEmptyFieldInForm, captureTentarooError } from '../utils/dataHelper';
import { isAdmin } from '../utils/permissionHelper';
import { getSiteID } from '../utils/helpers/adminCMSPageHelper';
import { getFacilityLocationId, IAdminFacilitiesLocationRouterParams } from '../utils/helpers/adminFacilityLocationPageHelper';
import { RouteComponentProps } from 'react-router';
import { getGroupID } from '../utils/helpers/endUserPageHelper';
import { withMaxClassTypeIDiAndHasMeritBadges } from "../utils/helpers/requestHelpers";
import { reduxStoreService } from "../store/service";
import { getDomain } from "../utils/urlHelper";

export const QP_ACCOUNT = 'account';

const PROF = '/profile';
const MY_ROA = '/my_roster';
const ORD_HIS = '/order_history';

const ADMIN_SITES_ROOT = '/sites';
const ADMIN_FACILITY_LOCATION_ROOT = '/FacilityLocation';
const ADMIN_EVENTS_ROOT = '/EventTypeAdmin';

export const CMS_PATHS = {
  PAGES: 'pages',
  CONTACTS: 'contacts',
  SITE_MENU: 'site-menu',
  PAGE_MENUS: 'menus-on-pages',
  RESOURCES: 'resources',
  RESOURCES_CATEGORIES: 'resources/categories',
  SETTINGS_GENERAL: 'settings',
  SETTINGS_MESSAGES: 'settings/messages',
};

export const CMS_CONTACTS = {
  NEW: 'new',
  EDIT: 'edit',
};

export const CMS_SITE_MENUS = {
  NEW: 'new',
};

export const CMS_PAGE_MENUS = {
  NEW: 'new',
};

export const CMS_RESOURCES = {
  NEW: 'new',
  EDIT: 'edit',
};

export const FACILITY_LOCATION_PATH = {
  FACILITIES: 'Facilities',
  TYPES: 'Types',
  AVAILABILITIES: 'Availabilities',
  BLACKOUT_DATES: 'BlackoutDates',
  REPORTS: 'Reports',
  SETTINGS: 'Settings',
};

export const FACILITY_LOCATION_FACILITY = {
  NEW: 'New',
};

export const FACILITY_LOCATION_FACILITY_TYPE = {
  NEW: 'New',
};

export const FACILITY_LOCATION_BLACKOUT_DATE = {
  NEW: 'New',
};


export const EVENT = {
  CLASSES: 'classes',
  PRODUCTS: 'products',
  REGISTRATION: 'registration',
  REGISTER: 'register'
};
export const E_REGISTRATION = {
  CLASSES: 'classes',
  PRODUCTS: 'products'
};
export const E_REGISTER = {
  NUMBERS: 'numbers',
  PARTICIPANT: 'participant',
  PRODUCTS: 'products'
};
export const E_RER_NUMBERS = {
  CLASSES: 'classes',
  PRODUCTS: 'products',
  CAMPSITE_RANKING: 'campsite_ranking',
  CONFIRMATION: 'confirmation'
};
export const E_RER_PARTICIPANT = {
  NEW_YOUTH: 'new_youth',
  NEW_ADULT: 'new_adult',
  TYPE: 'type',
  CLASSES: 'classes',
  CONFIRMATION: 'confirmation',
  ROSTER: 'roster',
  EDIT: 'edit'
};
export const E_RER_PARTICIPANT_ROSTER = {
  PERSON: 'person'
};
export const E_RER_PRODUCTS = {
  CONFIRMATION: 'confirmation'
};

export const MY_ROSTER_YOUTH = 'youth';
export const MY_ROSTER_ADULT = 'adult';

export const ORDER_HIST_ORDER = 'order';
export const ORDER_HIST_ORDER_EDIT = 'edit';

export const FAC_TRIP = 'trip';
export const FAC_ADD = 'add';
export const FAC_FACILITY = 'facility';

export const FAC_TRIP_EDIT = 'edit';
export const FAC_TRIP_ADD = 'add';
export const FAC_TRIP_RESERVATION = 'reservation';
export const FAC_TRIP_RESERVATION_EDIT = 'edit';
export const FAC_TRIP_RES_ADD = {
  RESERVATION: 'reservation',
  CONFIRMATION: 'confirmation'
};

export let ROOT = '/admin2';

export const URLS = {
  HOME: ROOT + '',
  FACILITIES: ROOT + '/facilities',
  FACILITY_TRIP: ROOT + '/facilitytrip',
  EVENT_TYPES: ROOT + '/eventtypes',
  EVENTS: ROOT + '/events',
  PROFILE: ROOT + PROF,
  ORDER_HISTORY: ROOT + ORD_HIS,
  VIEW_ORDER_ROOT: ROOT + `${ORD_HIS}/${ORDER_HIST_ORDER}`,
  MY_ROSTER: ROOT + MY_ROA,
  ADD_GROUP: ROOT + '/groups/add',
  LOGIN: ROOT + '/login',
  NEW_ACCOUNT: ROOT + '/new_account',
  CHECKOUT: ROOT + '/checkout',
  // CMS
  ADMIN_SITES: ROOT + ADMIN_SITES_ROOT,
  ADMIN_PAGES: ROOT + '/pages',
  ADMIN_CONTACTS: ROOT + '/contacts',
  ADMIN_RESOURCES: ROOT + '/resources',
  ADMIN_SITE_MENU: ROOT + '/site-menu',
  ADMIN_PAGE_MENU: ROOT + '/menus-on-pages',
  // Facilities
  ADMIN_FACILITY_LOCATION: ROOT + ADMIN_FACILITY_LOCATION_ROOT,
  ADMIN_FACILITY_LOCATION_FACILITY: ROOT + '/Facility',
  ADMIN_FACILITY_LOCATION_TYPE: ROOT + '/FacilityType',
  ADMIN_FACILITY_LOCATION_BLACKOUT_DATE: ROOT + '/BlackoutDate',
  // Events
  ADMIN_EVENTS: ROOT + ADMIN_EVENTS_ROOT,
  ADMIN_EVENT_CLASS_TYPE: ROOT + '/ClassType',
  ADMIN_EVENT_CLASS: ROOT + '/Class',
  ADMIN_EVENT_PRODUCT: ROOT + '/Product',
  ADMIN_EVENT_MESSAGE: ROOT + '/Message',
};
export const MY_ROASTER_ADD = 'add';

export const NAVS = {
  HOME: 'home',
  SETTINGS: 'settings',
  EVENTS: 'events',
  FACILITIES: 'facilities',
  ADMIN: 'admin',
  CHECKOUT: 'checkout',
  WEBSITES: 'websites',
  FACILITY_LOCATION: 'facility_location',
  ADMIN_EVENTS: 'admin_events',
  NONE: undefined
};

export const isAdminCMSSitePage = (path: string) => {
  return path.includes(URLS.ADMIN_SITES) || path.includes(URLS.ADMIN_PAGES) || path.includes(URLS.ADMIN_CONTACTS)
        || path.includes(URLS.ADMIN_RESOURCES) || path.includes(URLS.ADMIN_SITE_MENU) || path.includes(URLS.ADMIN_PAGE_MENU);
};

export const isAdminFacilityLocationPage = (path: string) => {
  return path.includes(URLS.ADMIN_FACILITY_LOCATION) || path.includes(URLS.ADMIN_FACILITY_LOCATION_TYPE) || path.includes(URLS.ADMIN_FACILITY_LOCATION_FACILITY) || path.includes(URLS.ADMIN_FACILITY_LOCATION_BLACKOUT_DATE);
};

export const isAdminEventsPage = (path: string) => {
  return path.includes(URLS.ADMIN_EVENTS) || path.includes(URLS.ADMIN_EVENT_CLASS) || path.includes(URLS.ADMIN_EVENT_CLASS_TYPE) || path.includes(URLS.ADMIN_EVENT_PRODUCT) || path.includes(URLS.ADMIN_EVENT_MESSAGE);
};

export const isAdminPage = (path: string | undefined) => {
  if (!path) return false;
  return path === URLS.ADD_GROUP || isAdminModulePage(path);
};

export const isAuthenticatedPage = (path: string| undefined) => {
  if (!path) return false;
  return path !== URLS.LOGIN && path !== URLS.NEW_ACCOUNT;
};

export const isEndUserPage = (path: string | undefined) => {
  if (!path) return false;
  return isAuthenticatedPage(path) && !isAdminPage(path);
};

export const isAdminModulePage = (path: string | undefined) => {
  if (!path) return false;

  return isAdminCMSSitePage(path) || isAdminFacilityLocationPage(path) || isAdminEventsPage(path);
};

export let APIURL;
export let CMS_APIURL;
export let ADMIN_SETTINGS_API_URL;
export let ADMIN_EVENTS_API_URL;
export let FACILITY_LOCATION_APIURL;
export let CMS_SYSTEM_ADMIN_APIURL;

export let IMAGE_PATH = 'https://res.cloudinary.com/tentaroo/image/upload/';


export const API_VERSION: number = 9.102622;
export const ADMIN_AIR_VERSION: number = 9.1;

APIURL = `${getDomain(true)}/Services/v2/${API_VERSION}`;
CMS_APIURL = `${getDomain(true)}/Services/v2/campsCMS/${API_VERSION}`;
ADMIN_SETTINGS_API_URL = `${getDomain(true)}/Services/v2/campsAdmin/${API_VERSION}`;
ADMIN_EVENTS_API_URL = `${getDomain(true)}/Services/v2/campsEventsAdmin/${API_VERSION}`;
FACILITY_LOCATION_APIURL = `${getDomain(true)}/Services/v2/campsFacilitiesAdmin/${API_VERSION}`;
CMS_SYSTEM_ADMIN_APIURL = `${getDomain(true)}/Services/v2/${API_VERSION}/campsSystemAdmin`;
//IMAGE_PATH = 'https://tentaroouscentral.blob.core.windows.net/tentaroo';

export const getQP = (amp?: boolean) => {
  const store = reduxStoreService();
  if (process.env.NODE_ENV === 'development') {
    if (store.getState().session.id) {
      if (amp) {
        return '&' + store.getState().session.id;
      }
      return '?' + store.getState().session.id;
    }
  }
  return '';
};

export const getAccountQP = () => {
  const cacheZero = reduxStoreService().getState().cacheZero;
  if (cacheZero && cacheZero.options && cacheZero.options.Group) {
    return `?${QP_ACCOUNT}=${cacheZero.options.Group.IDi}`;
  }
  return '';
};

export const getFlashFilePath = () => {
  let AdminAIRFilenameVersion: string = `${ADMIN_AIR_VERSION.toFixed(1)}`.replace('.', '_');
  return `https://www.tentaroo.com/air/adminTentaroo_${AdminAIRFilenameVersion}.air`;
};

const LOGIN_URL = '/User/LoginUser';
const LOGOUT_URL = '/User/LogoutUser';
const GET_OPTIONS = '/Options/GetOptions';
const GET_LOCATIONS = '/GetLocations';
const CHECK_USERNAME = '/User/CheckUsername';
const CHECK_EMAIL = '/User/CheckEmail';
const GET_REGISTRATION_FORM = '/User/GetRegistrationForm';
const REGISTER = '/User/CreateAccount';
export const LOGIN_FORM = '/User/GetLoginForm';
const GET_GROUP = '/campsGroups/GetGroup';

export const getOptions = () => {
  return `${APIURL}${GET_OPTIONS}${getQP()}`;
};

export const getOptionsBody = ({IDi}) => {
  if (IDi) return { AppState: { GroupIDi: IDi } };
  return {};
};
export const getLocations = () => {
  return `${CMS_SYSTEM_ADMIN_APIURL}${GET_LOCATIONS}${getQP()}`;
};
export const getLocationsBody = () => {
  return {};
};
export const getGroup = (session?: string) => `${APIURL}${GET_GROUP}${getQP()}`;
// export const getGroup = (session?: string) => `${APIURL}${GET_GROUP}?${session ? session : reduxStoreService().getState().session.id}`;
export const getGroupNoSession = () => `${APIURL}${GET_GROUP}${getQP()}`;
export const getGroupBody = (IDi?: number) => {
  if (!IDi) return {};
  return { IDi };
};

const UPDATE_PASSWORD = '/campsGroups/UpdateGroupPassword';
export const getUpdatePasswordUrl = () => `${APIURL}${UPDATE_PASSWORD}${getQP()}`;
export const getUpdatePasswordBody = ({GroupIDi, GroupTS, CurrentPassword, NewPassword} : {GroupIDi: number; GroupTS: number; CurrentPassword: string; NewPassword: string;}) => {
  return {
    AppState: {
      GroupIDi,
      GroupTS,
      GetOptions: false,
      GetGroupData: false
    },
    CurrentPassword,
    NewPassword,
  };
};

const UPDATE_EMAIL = '/campsGroups/UpdateGroupEmail';
export const getUpdateEmailUrl = () => `${APIURL}${UPDATE_EMAIL}${getQP()}`;
export const getUpdateEmailBody = ({GroupIDi, GroupTS, Email} : {GroupIDi: number; GroupTS: number; Email: string;}) => {
  return {
    AppState: {
      GroupIDi,
      GroupTS,
      GetOptions: false,
      GetGroupData: false
    },
    Email
  };
};

export const getLoginForm = () => `${APIURL}${LOGIN_FORM}${getQP()}`;
export const loginUrl = () => APIURL + LOGIN_URL;
export const logoutUrl = () => APIURL + LOGOUT_URL + getQP();

// Cache One
const GET_GROUP_CACHE = '/campsGroups/GetGroupCache';
export const getGroupCache = () => `${APIURL}${GET_GROUP_CACHE}${getQP()}`;
export const getGroupCacheBody = (params: {GroupIDi: number, GroupTS: number, GetOptions?: boolean, GetGroupData?: boolean}) => {
  const {GroupIDi, GroupTS, GetOptions, GetGroupData} = params;
  return {
    AppState: {
      GroupIDi,
      GroupTS,
      GetOptions: !!GetOptions,
      GetGroupData: !!GetGroupData
    }
  };
};

// Create Account
export const getRegistrationForm = () => APIURL + GET_REGISTRATION_FORM;
export const checkUsername = () => APIURL + CHECK_USERNAME;
export const checkEmail = () => APIURL + CHECK_EMAIL;
export const registerNewAccount = () => APIURL + REGISTER;


// Add Group
const UPDATE_GROUP = '/campsGroups/UpdateGroup';
const ADD_GROUP_CHECK_USERNAME = '/campsGroups/CheckUsername';
export const updateGroup = () => APIURL + UPDATE_GROUP + getQP();
export const addGroupCheckUsername = () => APIURL + ADD_GROUP_CHECK_USERNAME + getQP();

// Admin Account Modal
const GET_PARTICIPANTS = '/campsGroups/GetGroupsParticipants';
const GET_REGISTRATIONS = '/Accounts/GetGroupsEventRegistrations';
const GET_GROUPS = '/campsGroups/GetGroups';
const GET_TRIPS = '/Accounts/GetGroupsTrips';
const GET_RESERVATIONS = '/Accounts/GetGroupsReservations';
const DELETE_GROUP = '/campsGroups/DeleteGroup';
export const getParticipants = () => APIURL + GET_PARTICIPANTS + getQP();
export const getParticipantsBody = (value: {EventTypeID: number, Year: number}) => (value);

export const getRegistrations = () => ADMIN_SETTINGS_API_URL + GET_REGISTRATIONS + getQP();
export const getRegistrationsBody = (value: {EventTypeID: number, EventYear: number, EventIDi?: number, IncludeFilterOptions?: boolean}) => (value);

export const getGroups = () => APIURL + GET_GROUPS + getQP();
export const getGroupsBody = (showInactive: boolean) => ({ showInactive: showInactive });

export const getTrips = () => ADMIN_SETTINGS_API_URL + GET_TRIPS + getQP();
export const getTripsBody = (value: {LocationID: number, TripYear: number}) => (value);

export const getReservations = () => ADMIN_SETTINGS_API_URL + GET_RESERVATIONS + getQP();
export const getReservationsBody = (value: {LocationID: number, TripYear: number, FacilityTypeID?: number, IncludeFilterOptions?: boolean}) => (value);

export const deleteGroup = () => APIURL + DELETE_GROUP + getQP();
export const activateGroupBody = (IDi: number) => ({ row: { IDi, Inactive: false }});
export const deleteGroupBody = (IDi: number, restore: boolean) => {
  return { row: { IDi, Inactive: !restore }};
};

// Events cache 2
const GET_EVENT_TYPE_DATA = '/campsEvents/GetEventTypeData';
export const getEventTypeData = () => `${APIURL}${GET_EVENT_TYPE_DATA}${getQP()}`;
export const getEventTypeDataBody = (params: {EventTypeID: number, GroupIDi: number, GroupTS: number, GetOptions?: boolean, GetGroupData?: boolean}) => {
  const {EventTypeID, GroupIDi, GroupTS, GetOptions, GetGroupData} = params;
  return {
    AppState: {
      GroupIDi,
      GroupTS,
      GetOptions: !!GetOptions,
      GetGroupData: !!GetGroupData,
      EventTypeID: EventTypeID,
    },
    GetEventTypeSettings: true // @todo: when will it be false?
  };
};

// Events cache 3
const GET_EVENT_DATA = '/campsEvents/GetEventData';
export const getEventData = () => `${APIURL}${GET_EVENT_DATA}${getQP()}`;
export const getEventDataBody = (params: {
  EventTypeID: number,
  GroupIDi: number,
  GroupTS: number,
  GetOptions?: boolean,
  GetGroupData?: boolean,
  EventIDi: number,
  GetEventTypeData?: boolean
}) => {
  const {EventTypeID, GroupIDi, GroupTS, GetOptions, GetGroupData, EventIDi, GetEventTypeData} = params;

  const AppStateBase = {
    GroupIDi,
    GroupTS,
    EventTypeID: EventTypeID,
    GetOptions: !!GetOptions,
    GetGroupData: !!GetGroupData,
    EventIDi: EventIDi,
    GetEventTypeData: !!GetEventTypeData,
  };
  return {
    AppState: withMaxClassTypeIDiAndHasMeritBadges(AppStateBase),
  };
};

const UPDATE_EVENT_PAYMENT = '/campsEventGroups/UpdateEventPaymentInCart/?ParseJSON=0';
export const getUpdateEventPayment = () => `${APIURL}${UPDATE_EVENT_PAYMENT}${getQP(true)}`;
export const getUpdateEventPaymentBody = (params: {
  GroupIDi: number,
  GroupTS: number,
  EventTypeID: number,
  EventIDi: number,
  GroupWeekIDi: number,
  TotalAmountChange: number
}) => {
  const {GroupIDi, GroupTS, EventTypeID, EventIDi, GroupWeekIDi, TotalAmountChange} = params;
  const AppStateBase = {
    GroupIDi: GroupIDi,
    GroupTS: GroupTS,
    EventTypeID: EventTypeID,
    EventIDi: EventIDi
  };
  return {
    AppState: withMaxClassTypeIDiAndHasMeritBadges(AppStateBase),
    GroupWeekIDi: GroupWeekIDi,
    TotalAmountChange: TotalAmountChange
  };
};


// Events cache 4 participant
const GET_EVENT_PARTICIPANT_DATA = '/campsEventParticipants/GetEventParticipantData';
export const getEventParticipantData = () => `${APIURL}${GET_EVENT_PARTICIPANT_DATA}${getQP()}`;
export const getEventParticipantDataBody = (params: {
  EventTypeID: number,
  GroupIDi: number,
  GroupTS: number,
  GetOptions: boolean,
  GetGroupData: boolean,
  EventIDi: number,
  GetEventTypeData: boolean,
  GetEventData: boolean,
  ParticipantIDi: number,
  IsYouth: boolean
}) => {
  const {
    EventTypeID, GroupIDi, GroupTS, GetOptions, GetGroupData, EventIDi, GetEventTypeData, GetEventData, ParticipantIDi, IsYouth
  } = params;
  const AppStateBase = {
    GroupIDi,
    GroupTS,
    EventTypeID: EventTypeID,
    GetOptions: GetOptions,
    GetGroupData: GetGroupData,
    EventIDi: EventIDi,
    GetEventTypeData: GetEventTypeData,
    GetEventData: GetEventData,
  };
  return {
    AppState: withMaxClassTypeIDiAndHasMeritBadges(AppStateBase),
    ParticipantIDi: ParticipantIDi,
    IsYouth: IsYouth
  };
};

// Events cache 4 numbers wizard

export interface NumbersLoadParams {
  GroupIDi: number;
  GroupTS: number;
  EventTypeID: number;
  EventIDi: number;
  /**
   * ID of currently loaded participant types, need this to check if there is
   * a 409 happened in participant types
   */
  ParticipantTypes: number[];
}

export interface NumbersRecalcParams extends NumbersLoadParams {
  GroupWeekIDi: number;
  NumbersProposed: Array<NumberProposed>;
  ClassRegistrations: Array<PostEventRegistrationClass>;
  ProductOrders: Array<RecalcProduct>;
  CampsiteAssignments?: Array<EventRegistrationCampsiteAssignment>;
  CampsiteDepositAmount?: number;
}

export interface NumbersSaveParams extends NumbersRecalcParams {
  GroupWeekTS: number;
  AmountChangeInCart: number;
  RegistrationFields: RegistrationFields;
  TotalAmountChange?: number;
  DesiredPaymentInCart: number | undefined;
}

const EVENT_NUMBERS_WIZARD = '/campsEventGroups/GetGroupEventRegistrationNumbersModify/?ParseJSON=0';
export const eventNumbersCalc = () => `${APIURL}${EVENT_NUMBERS_WIZARD}${getQP(true)}`;
export const eventNumbersInitBody = (params: NumbersLoadParams) => {
  const AppStateBase = {
    GroupIDi: params.GroupIDi,
    GroupTS: params.GroupTS,
    EventTypeID: params.EventTypeID,
    EventIDi: params.EventIDi
  };
  return {
    AppState: withMaxClassTypeIDiAndHasMeritBadges(AppStateBase),
    InitialLoadForModifying: true,
    IsSaving: false,
    NumbersProposed: null,
    ClassRegistrations: null,
    ParticipantTypes: params.ParticipantTypes,
  };
};
export const eventNumbersRecalcBody = (params: NumbersRecalcParams) => {
  const AppStateBase = {
    GroupIDi: params.GroupIDi,
    GroupTS: params.GroupTS,
    EventTypeID: params.EventTypeID,
    EventIDi: params.EventIDi
  };
  return {
    AppState: withMaxClassTypeIDiAndHasMeritBadges(AppStateBase),
    InitialLoadForModifying: false,
    IsSaving: false,
    GroupWeekIDi: params.GroupWeekIDi,
    NumbersProposed: params.NumbersProposed,
    ClassRegistrations: params.ClassRegistrations,
    ProductOrders: params.ProductOrders,
    CampsiteAssignments: params.CampsiteAssignments,
    RegistrationFields: {
      CampsiteDepositAmount: params.CampsiteDepositAmount
    },
    ParticipantTypes: params.ParticipantTypes,
  };
};
export const eventNumbersSaveBody = (params: NumbersSaveParams) => {
  const AppStateBase = {
    GroupIDi: params.GroupIDi,
    GroupTS: params.GroupTS,
    EventTypeID: params.EventTypeID,
    EventIDi: params.EventIDi
  };
  const ret: any = {
    AppState: withMaxClassTypeIDiAndHasMeritBadges(AppStateBase),
    InitialLoadForModifying: false,
    IsSaving: true,
    GroupWeekTS: params.GroupWeekTS,
    AmountChangeInCart: params.AmountChangeInCart,
    GroupWeekIDi: params.GroupWeekIDi,
    NumbersProposed: params.NumbersProposed,
    ClassRegistrations: params.ClassRegistrations,
    RegistrationFields: params.RegistrationFields,
    ProductOrders: params.ProductOrders,
    CampsiteAssignments: params.CampsiteAssignments,
    ParticipantTypes: params.ParticipantTypes,
  };

  if (params.TotalAmountChange !== undefined) {
    ret.TotalAmountChange = params.TotalAmountChange;
  }

  return ret;
};


// Events cache 4 participants wizard
export interface ParticipantsLoadParams {
  GroupIDi: number;
  GroupTS: number;
  EventTypeID: number;
  EventIDi: number;
  GroupWeekIDi?: number;
  ParticipantRegistrationIDi?: number | null;
  IsYouth: boolean;
  /**
   * ID of currently loaded participant types, need this to check if there is
   * a 409 happened in participant types
   */
  ParticipantTypes: number[];
}

export interface ParticipantsRecalcParams extends ParticipantsLoadParams {
  GroupWeekIDi: number;
  EventRegistrationPerson: EventRegistrationPerson;
  HasMultiWeekDiscount: boolean;
  ParticipantRegistrationIDi?: number | null;
  PersonIDi?: number | null;
  ParticipantTypeID?: number;
  ClassRegistrations: Array<PostEventRegistrationClass>;
  ProposedInactive?: boolean;
}

export interface CustomFieldPost {
  ElementID: number;
  Value: string;
}

export interface ParticipantsSaveParams extends ParticipantsRecalcParams {
  TotalAmountChange: number;
  AmountChangeInCart: number;
  GroupRosterPerson?: FullGroupRosterPerson;
  CustomFormFieldEntries?: Array<CustomFieldPost>;
}

export type CancelParticipantsParams = Omit<ParticipantsSaveParams, "ParticipantTypes">;
// export interface ParticipantsSaveParams extends NumbersRecalcParams {}

const EVENT_PARTICIPANTS_WIZARD = '/campsEventGroups/GetIndividualRegistrationNamesModify/?ParseJSON=0';
export const eventParticipantsInit = () => `${APIURL}${EVENT_PARTICIPANTS_WIZARD}${getQP(true)}`;
export const eventParticipantsInitBody = (params: ParticipantsLoadParams) => {
  const AppStateBase = {
    GroupIDi: params.GroupIDi,
    GroupTS: params.GroupTS,
    EventTypeID: params.EventTypeID,
    EventIDi: params.EventIDi
  };
  return {
    AppState: withMaxClassTypeIDiAndHasMeritBadges(AppStateBase),
    InitialLoadForModifying: true,
    IsSaving: false,
    GroupWeekIDi: params.GroupWeekIDi,
    ParticipantRegistrationIDi: params.ParticipantRegistrationIDi ? params.ParticipantRegistrationIDi : null,
    IsYouth: params.IsYouth,
    ParticipantTypes: params.ParticipantTypes,
  };
};

const createEventRegistrationPerson = (
  IsYouth: boolean | undefined,
  EventRegistrationPerson: EventRegistrationPerson,
  HasMultiWeekDiscount: boolean,
  pForm?: EventPersonActiveForm & { RegistrationNotes?: string }
) => {
  let personForm = pForm ? pForm : reduxStoreService().getState().events.event.register.participant.roster.ActiveForm;
  if (!personForm) return null;
  const eventType = reduxStoreService().getState().cacheTwoEvents.eventType;
  if (IsYouth) {
    return {
      IsStaff: personForm.IsStaff === 1,
      SummerCampYear: zie(personForm.SummerCampYear),
      Notes: bie(personForm.RegistrationNotes),
      HasMultiWeekDiscount: HasMultiWeekDiscount,
      Amount: EventRegistrationPerson.Amount,
      AmountMin: EventRegistrationPerson.AmountMin,
      AmountChangeInCart: EventRegistrationPerson.AmountChangeInCart,
      TS: EventRegistrationPerson.TS
    };
  } else {
    return {
      InCampAllWeek: !!personForm.InCampAllWeek,
      InCampFri: !!personForm.InCampAllWeek ? false : !!personForm.InCampFri,
      InCampMon: !!personForm.InCampAllWeek ? false : !!personForm.InCampMon,
      InCampSat: !!personForm.InCampAllWeek ? false : !!personForm.InCampSat,
      InCampSun: !!personForm.InCampAllWeek ? false : !!personForm.InCampSun,
      InCampThu: !!personForm.InCampAllWeek ? false : !!personForm.InCampThu,
      InCampTue: !!personForm.InCampAllWeek ? false : !!personForm.InCampTue,
      InCampWed: !!personForm.InCampAllWeek ? false : !!personForm.InCampWed,
      Notes: bie(personForm.RegistrationNotes),
      HasMultiWeekDiscount: HasMultiWeekDiscount,
      Amount: EventRegistrationPerson.Amount,
      AmountMin: EventRegistrationPerson.AmountMin,
      AmountChangeInCart: EventRegistrationPerson.AmountChangeInCart,
      TS: EventRegistrationPerson.TS
    };
  }
};

// cache four products

export interface ProductsLoadParams {
  GroupIDi: number;
  GroupTS: number;
  EventTypeID: number;
  EventIDi: number;
  GroupWeekIDi: number
}

export interface ProductsRecalcParams extends ProductsLoadParams {
  ProductOrders: Array<RecalcProduct>;
}

export interface ProductsSaveParams extends ProductsRecalcParams{
  TotalAmountChange: number;
  // GroupWeekTS: number;
}

const GET_PRODUCTS = '/campsEventGroups/GetGroupEventRegistrationProductsModify/?ParseJSON=0';
export const getEventProducts = () => `${APIURL}${GET_PRODUCTS}${getQP(true)}`;
export const eventProductsBody = (params: ProductsLoadParams) => {
  const AppStateBase = {
    GroupIDi: params.GroupIDi,
    GroupTS: params.GroupTS,
    EventTypeID: params.EventTypeID,
    EventIDi: params.EventIDi
  };
  return {
    AppState: withMaxClassTypeIDiAndHasMeritBadges(AppStateBase),
    GroupWeekIDi: params.GroupWeekIDi,
    InitialLoadForModifying: true,
    IsSaving: false,
    TotalAmountChange: null,
    ProductOrders: null
  };
};
export const eventProductsRecalcBody = (params: ProductsRecalcParams) => {
  const AppStateBase = {
    GroupIDi: params.GroupIDi,
    GroupTS: params.GroupTS,
    EventTypeID: params.EventTypeID,
    EventIDi: params.EventIDi
  };
  return {
    AppState: withMaxClassTypeIDiAndHasMeritBadges(AppStateBase),
    GroupWeekIDi: params.GroupWeekIDi,
    InitialLoadForModifying: false,
    IsSaving: false,
    TotalAmountChange: null,
    ProductOrders: params.ProductOrders
  };
};
export const eventProductsSaveBody = (params: ProductsSaveParams) => {
  const AppStateBase = {
    GroupIDi: params.GroupIDi,
    GroupTS: params.GroupTS,
    EventTypeID: params.EventTypeID,
    EventIDi: params.EventIDi
  };
  return {
    AppState: withMaxClassTypeIDiAndHasMeritBadges(AppStateBase),
    GroupWeekIDi: params.GroupWeekIDi,
    // GroupWeekTS: params.GroupWeekTS,
    InitialLoadForModifying: false,
    IsSaving: true,
    TotalAmountChange: params.TotalAmountChange,
    ProductOrders: params.ProductOrders
  };
};

// @todo: use on settings page too? is it a bit different?
const createRosterPerson = (
  person?: PersonFormActiveForm & {PersonIDi?: number | null; TS?: number | null; Inactive?: boolean | null},
  isYouth?: boolean
) => {
  if (!person) return null;

  // @todo: some of these bies don't make sense
  const base: any = {
    IDi: person.PersonIDi,
    IsYouth: !!isYouth,
    LastName: bie(person.LastName),
    FirstName: bie(person.FirstName),
    MI: bie(person.MI),
    Name: `${person.FirstName} ${person.LastName}`,
    ScoutTypeID: person.ScoutTypeID,
    Unit: person.Unit,
    UnitTypeID: person.UnitTypeID,
    CouncilIDi: person.CouncilIDi,
    DistrictIDi: person.DistrictIDi,
    Chapter: bie(person.Chapter),
    OALodge: bie(person.OALodge),
    OAStatusID: person.OAStatusID ? person.OAStatusID : null,
    BSAID: person.BSAID ? person.BSAID : null,
    Sex: bie(person.Sex),
    Email: bie(person.Email),
    ShirtSize: bie(person.ShirtSize),
    DOB: person.DOB ? formatDate(person.DOB) : null,
    OAPosition: bie(person.OAPosition),
    HasSeriousAllergies: !!person.HasSeriousAllergies,
    HasDietaryRestrictions: !!person.HasDietaryRestrictions,
    SpecialNeeds: bie(person.SpecialNeeds),
    Address: bie(person.Address),
    City: bie(person.City),
    StateID: person.StateID === -1 ? null : person.StateID,
    Zip: bie(person.Zip),
    PhoneNumber: bie(person.PhoneNumber),
    Phone2: bie(person.Phone2),
    TroopPosition: bie(person.TroopPosition),
    TS: person.TS,
    Inactive: person.Inactive
  };

  if (isYouth) {
    base.Rank = person.Rank;
    base.Grade = person.Grade;
    base.AllowPhoto = !!person.AllowPhoto;
    base.EmergencyName = bie(person.EmergencyName);
    base.EmergencyPhone = bie(person.EmergencyPhone);
    base.UnitLeaderName = bie(person.UnitLeaderName);
    base.UnitLeaderPhone = bie(person.UnitLeaderPhone);
    base.UnitLeaderEmail = bie(person.UnitLeaderEmail);
  } else {
    base.Suffix = bie(person.Suffix);
    base.DateYouthProtectionTraining = person.DateYouthProtectionTraining ? formatDate(person.DateYouthProtectionTraining) : null;
    base.DateBalooTraining = person.DateBalooTraining ? formatDate(person.DateBalooTraining) : null;
    base.DateBasicTrainingForPosition = person.DateBasicTrainingForPosition ? formatDate(person.DateBasicTrainingForPosition) : null;
  }
  return base;
};

export const eventParticipantsRecalcBody = (params: ParticipantsRecalcParams, pForm?: EventPersonActiveForm) => {
  const AppStateBase = {
    GroupIDi: params.GroupIDi,
    GroupTS: params.GroupTS,
    EventTypeID: params.EventTypeID,
    EventIDi: params.EventIDi
  };
  return {
    AppState: withMaxClassTypeIDiAndHasMeritBadges(AppStateBase),
    InitialLoadForModifying: false,
    IsSaving: false,
    GroupWeekIDi: params.GroupWeekIDi,
    ParticipantRegistrationIDi: nie(params.ParticipantRegistrationIDi),
    IsYouth: params.IsYouth,
    PersonIDi: nie(params.PersonIDi),
    ParticipantTypeID: nie(params.ParticipantTypeID),
    ProposedInactive: params.ProposedInactive !== undefined ? params.ProposedInactive : null,
    EventRegistrationPerson: createEventRegistrationPerson(params.IsYouth, params.EventRegistrationPerson, params.HasMultiWeekDiscount, pForm),
    ClassRegistrations: params.ClassRegistrations,
    ParticipantTypes: params.ParticipantTypes,
  };
};

export const eventParticipantsSaveBody = (params: ParticipantsSaveParams | CancelParticipantsParams, pForm?: EventPersonActiveForm) => {
  const AppStateBase = {
    GroupIDi: params.GroupIDi,
    GroupTS: params.GroupTS,
    EventTypeID: params.EventTypeID,
    EventIDi: params.EventIDi
  };
  return {
    AppState: withMaxClassTypeIDiAndHasMeritBadges(AppStateBase),
    InitialLoadForModifying: false,
    IsSaving: true,
    GroupWeekIDi: params.GroupWeekIDi,
    ParticipantRegistrationIDi: nie(params.ParticipantRegistrationIDi),
    IsYouth: params.IsYouth,
    PersonIDi: nie(params.PersonIDi),
    ParticipantTypeID: nie(params.ParticipantTypeID),
    ProposedInactive: params.ProposedInactive !== undefined ? params.ProposedInactive : null,
    EventRegistrationPerson: createEventRegistrationPerson(params.IsYouth, params.EventRegistrationPerson, params.HasMultiWeekDiscount, pForm),
    ClassRegistrations: params.ClassRegistrations,
    AmountChangeInCart: params.AmountChangeInCart,
    TotalAmountChange: params.TotalAmountChange,
    GroupRosterPerson: createRosterPerson(params.GroupRosterPerson, params.IsYouth),
    CustomFormFieldEntries: params.CustomFormFieldEntries,
    // `ParticipantTypes` is not needed when canceling registration
    ParticipantTypes: "ParticipantTypes" in params ? params.ParticipantTypes : null,
  };
};

export const eventParticipantsDeleteBody = (params: CancelParticipantsParams) => {
  const body = eventParticipantsSaveBody(params);
  return body;
};

interface EventRouteParams {
  eventTypeId: string;
  eventId: string;
  name: string;
}

export const getEventRootUrl = (props) => {
  const params: EventRouteParams = props.params;
  return `${URLS.EVENTS}/${params.eventTypeId}/${params.eventId}/${spaceTo_(params.name)}`;
};
// export const getEventProductsUrl = (props) => `${getEventRootUrl(props)}/${EVENT.PRODUCTS}`;
export const getEventRegistrationUrl = (props) => `${getEventRootUrl(props)}/${EVENT.REGISTRATION}`;
export const getEventRegistrationClassesUrl = (props) => `${getEventRegistrationUrl(props)}/${E_REGISTRATION.CLASSES}`;
// export const getEventRegistrationProductsUrl = (props) => `${getEventRegistrationUrl(props)}/${E_REGISTRATION.PRODUCTS}`;

export const getEventRegisterParticipantUrl = (props) => `${getEventRootUrl(props)}/${EVENT.REGISTER}/${E_REGISTER.PARTICIPANT}`;
export const getEventRegisterNumbersUrl = (props) => `${getEventRootUrl(props)}/${EVENT.REGISTER}/${E_REGISTER.NUMBERS}`;
export const getEventRegisterProductsUrl = (props) => `${getEventRootUrl(props)}/${EVENT.REGISTER}/${E_REGISTER.PRODUCTS}`;

export const getEventRegisterParticipantEditUrl =
  (props) => `${getEventRegisterParticipantUrl(props)}/${props.params.ya}/${props.params.pId}/${props.params.pName}/${E_RER_PARTICIPANT.EDIT}`;


// Setting Roster
export const getRosterPersonUrl = (person: GroupRosterPerson) =>
 `${URLS.MY_ROSTER}/${person.IsYouth ? 'youth' : 'adult'}/${person.IDi}/${spaceTo_(person.Name)}`;

// Settings Roster Cache 2
const GET_GROUP_ROSTER_CACHE = '/campsGroups/GetGroupRosterCache';
export const getGroupRosterCache = () =>
  `${APIURL}${GET_GROUP_ROSTER_CACHE}${getQP()}`;
export const getGroupRosterCacheBody = ({GroupIDi, GroupTS, GetOptions, GetGroupData, IncludeInactive}: {GroupIDi: number; GroupTS: number; GetOptions: boolean; GetGroupData: boolean; IncludeInactive: boolean;}) => {
  const body: any = {
    AppState: {
      GroupIDi,
      GroupTS,
      GetOptions,
      GetGroupData
    }
  };
  body.IncludeInactive = IncludeInactive;
  return body;
};

// Settings Roster Cache 3
export interface GetGroupRosterPersonParams {
  PersonIDi: number;
  GroupIDi: number;
  GroupTS: number;
  IsYouth: boolean;
  GetOptions?: boolean;
  GetGroupData?: boolean;
  GetGroupRosterData?: boolean;
}

const GET_GROUP_ROSTER_PERSON = '/campsEventParticipants/GetGroupRosterPerson';
export const getGroupRosterPerson = () => `${APIURL}${GET_GROUP_ROSTER_PERSON}${getQP()}`;
export const getGroupRosterPersonBody = (params: GetGroupRosterPersonParams) => {
  const {PersonIDi, GroupIDi, GroupTS, IsYouth, GetOptions, GetGroupData, GetGroupRosterData} = params;
  return {
    AppState: {
      GroupIDi,
      GroupTS,
      GetOptions: !!GetOptions,
      GetGroupData: !!GetGroupData,
      GetGroupRosterData: !!GetGroupRosterData
    },
    PersonIDi,
    IsYouth
  };
};

const UPDATE_GROUP_ROSTER_PERSON = '/campsEventParticipants/UpdateGroupRosterPerson?ParseJSON=0';
export const updateGroupRosterPerson = () => `${APIURL}${UPDATE_GROUP_ROSTER_PERSON}${getQP(true)}`;
export const updateGroupRosterPersonBody = (
  GroupIDi: number,
  GroupTS: number,
  PersonIDi: number,
  IsYouth: boolean,
  TS: number,
  Inactive: boolean,
  {
    LastName,
    FirstName,
    MI,
    ScoutTypeID,
    Unit,
    UnitTypeID,
    CouncilIDi,
    DistrictIDi,
    Chapter,
    OAStatusID,
    BSAID,
    Sex,
    Email,
    ShirtSize,
    DOB,
    OAPosition,
    HasSeriousAllergies,
    HasDietaryRestrictions,
    SpecialNeeds,
    Address,
    City,
    StateID,
    Zip,
    PhoneNumber,
    Phone2,
    TroopPosition,
    OALodge,
    // Youth only
    Rank,
    Grade,
    AllowPhoto,
    EmergencyName,
    EmergencyPhone,
    UnitLeaderName,
    UnitLeaderPhone,
    UnitLeaderEmail,
    // Adult only
    Suffix,
    DateYouthProtectionTraining,
    DateBalooTraining,
    DateBasicTrainingForPosition
}: PersonFormActiveForm) => {
  const base: any = {
    AppState: {
      GroupIDi: GroupIDi,
      GroupTS: GroupTS
    },
    Person: {
      IDi: PersonIDi,
      IsYouth: IsYouth,
      LastName: bie(LastName),
      FirstName: bie(FirstName),
      MI: bie(MI),
      Name: `${FirstName} ${LastName}`,
      ScoutTypeID: ScoutTypeID,
      Unit: bie(Unit),
      UnitTypeID: UnitTypeID,
      CouncilIDi: CouncilIDi,
      DistrictIDi: DistrictIDi,
      Chapter: bie(Chapter),
      OALodge: bie(OALodge),
      OAStatusID: OAStatusID ? OAStatusID : null,
      BSAID: BSAID ? BSAID : null,
      Sex: bie(Sex),
      Email: bie(Email),
      ShirtSize: bie(ShirtSize),
      DOB: DOB ? formatDate(DOB) : null,
      OAPosition: bie(OAPosition),
      HasSeriousAllergies: !!HasSeriousAllergies,
      HasDietaryRestrictions: !!HasDietaryRestrictions,
      SpecialNeeds: bie(SpecialNeeds),
      Address: bie(Address),
      City: bie(City),
      StateID: StateID === -1 ? null : StateID,
      Zip: bie(Zip),
      PhoneNumber: bie(PhoneNumber),
      Phone2: bie(Phone2),
      TroopPosition: bie(TroopPosition),
      TS: TS,
      Inactive: Inactive
    }
  };
  if (IsYouth) {
    base.Person.Rank = Rank;
    base.Person.Grade = Grade;
    base.Person.AllowPhoto = !!AllowPhoto;
    base.Person.EmergencyName = bie(EmergencyName);
    base.Person.EmergencyPhone = bie(EmergencyPhone);
    base.Person.UnitLeaderName = bie(UnitLeaderName);
    base.Person.UnitLeaderPhone = bie(UnitLeaderPhone);
    base.Person.UnitLeaderEmail = bie(UnitLeaderEmail);
  } else {
    base.Person.Suffix = bie(Suffix);
    base.Person.DateYouthProtectionTraining = DateYouthProtectionTraining ? formatDate(DateYouthProtectionTraining) : null;
    base.Person.DateBalooTraining = DateBalooTraining ? formatDate(DateBalooTraining) : null;
    base.Person.DateBasicTrainingForPosition = DateBasicTrainingForPosition ? formatDate(DateBasicTrainingForPosition) : null;
  }

  return base;
};

export const deleteOrRestoreGroupRosterPersonBody = (
  GroupIDi: number,
  GroupTS: number,
  IDi: number,
  IsYouth: boolean,
  Inactive: boolean
) => {
  const Person: any = {
    IDi,
    IsYouth,
    Inactive
  };
  return {
    AppState: {
      GroupIDi: GroupIDi,
      GroupTS: GroupTS
    },
    Person: Person
  };
};


// Facilities

// Facilties Cache Two
const FACILITY_LOCATION = `/campsFacilities/GetFacilityLocationData`;
export const getFacilityLocationUrl = () => `${APIURL}${FACILITY_LOCATION}${getQP()}`;
export interface FacilityLocationParams {
  GroupIDi: number;
  GroupTS: number;
  FacilityLocationID: number;
  GetOptions: boolean;
  GetGroupData: boolean;
  FacilityTypeID?: number;
  StartDate?: string | null;
}
export const getFacilityLocationBody = ({GroupIDi, GroupTS, FacilityLocationID, GetGroupData, GetOptions, FacilityTypeID, StartDate}: FacilityLocationParams) => {
  return {
    AppState: {
      GroupIDi,
      GroupTS,
      FacilityLocationID,
      GetGroupData,
      GetOptions
    },
    FacilityTypeID,
    StartDate
  };
};

const FACILITY_AVAILABILITIES = `/campsFacilities/GetFacilityAvailabilities`;
export const getFacilityAvailabilitiesUrl = () => `${APIURL}${FACILITY_AVAILABILITIES}${getQP()}`;
export interface FacilityAvailabilitiesParams {
  GroupIDi: number;
  GroupTS: number;
  FacilityLocationID: number;
  GetOptions: boolean;
  GetGroupData: boolean;
  GetFacilityLocationData: boolean;
  FacilityTypeID?: number;
  FacilityID?: number;
  StartDate?: string | null | undefined;
  ftbID?: number | null;
}
export const getFacilityAvailabilityBody = ({GroupIDi, GroupTS, FacilityLocationID, GetGroupData, GetOptions, GetFacilityLocationData, FacilityTypeID, StartDate, FacilityID, ftbID}: FacilityAvailabilitiesParams) => {
  const body: any = {
    AppState: {
      GroupIDi,
      GroupTS,
      FacilityLocationID,
      GetGroupData,
      GetOptions,
      GetFacilityLocationData
    },
    FacilityTypeID,
    StartDate,
    FacilityID
  };

  if (ftbID) {
    body.ftbID = ftbID;
  }
  return body;
};

export const getAddTripURL = (facilityLocation: FacilityLocation) => `${URLS.FACILITIES}/${facilityLocation.ID}/${spaceTo_(facilityLocation.Name)}/${FAC_TRIP}/${FAC_ADD}`;

const UPDATE_TRIP = '/campsFacilities/UpdateFacilityTrip/?ParseJSON=0';
export const getUpdateTripUrl = () => `${APIURL}${UPDATE_TRIP}${getQP(true)}`;
export interface UpdateTripParams {
  GroupIDi: number;
  GroupTS: number;
  FacilityLocationID: number;
  FacilityTripID?: number;
}
export interface DeleteTripParams {
  GroupIDi: number;
  GroupTS: number;
  FacilityLocationID: number;
  FacilityTripID: number;
}
export const getUpdateTripBody = (params: UpdateTripParams, form: TripActiveForm, tripTS?: number) => {
  const {GroupIDi, GroupTS, FacilityLocationID, FacilityTripID} = params;
  const body: any = {
    AppState: {
      GroupIDi,
      GroupTS,
      FacilityLocationID,
      FacilityTripID,
      GetGroupData: false,
      GetOptions: false
    },
    Trip: {
      Name: form.Name,
      ContactName: form.ContactName,
      ContactPhone: bie(form.ContactPhone),
      ContactEmail: form.ContactEmail,
      ContactName2: bie(form.ContactName2),
      ContactPhone2: bie(form.ContactPhone2),
      ContactEmail2: bie(form.ContactEmail2),
      NumAdults: form.NumAdults,
      NumYouth: form.NumYouth,
      GroupNotes: bie(form.GroupNotes),
      AdminNotes: form.AdminNotes,
      StartDateTime: form.StartDateTime ? formatDate(form.StartDateTime) : null,
      EndDateTime: form.EndDateTime ? formatDate(form.EndDateTime) : null,
      Inactive: false
    }
  };
  if (tripTS) body.Trip.TS = tripTS;
  return body;
};

export const getDeleteTripBody = (params: DeleteTripParams, tripTS: number) => {
  const {GroupIDi, GroupTS, FacilityLocationID, FacilityTripID} = params;
  const body: any = {
    AppState: {
      GroupIDi,
      GroupTS,
      FacilityLocationID,
      FacilityTripID,
      GetGroupData: false,
      GetOptions: false
    },
    Trip: {
      Inactive: true,
      TS: tripTS
    }
  };
  return body;
};

// Facilities cache 3
const FACILITY_TRIP_DATA = `/campsFacilities/GetFacilityTripData`;
export const getFacilityTripData = () => `${APIURL}${FACILITY_TRIP_DATA}${getQP()}`;
export interface FacilityTripDataParams {
  GroupIDi: number;
  GroupTS: number;
  FacilityLocationID: number;
  FacilityTripID: number;
  GetOptions: boolean;
  GetGroupData: boolean;
  GetFacilityLocationData: boolean;
  GetFacilityTripData: boolean;
}
export const getFacilityTripDataBody = ({GroupIDi, GroupTS, FacilityLocationID, FacilityTripID, GetOptions, GetGroupData, GetFacilityLocationData, GetFacilityTripData}: FacilityTripDataParams) => {

  // for #1402
  // const startDate = reduxStoreService().getState().facilities.location.startDate;
  // let stringDate = '';
  // if (startDate) {
  //   stringDate = startDate.format(AVAIL_FORMAT);
  // } else {
  //   stringDate = moment().format(AVAIL_FORMAT);
  // }
  return {
    AppState: {
      GroupIDi,
      GroupTS,
      FacilityLocationID,
      FacilityTripID,
      GetOptions,
      GetGroupData,
      GetFacilityLocationData,
      GetFacilityTripData
    },
    // StartDate: stringDate
  };
};

// Trip Summary FS
const UPDATE_TRIP_PAYMENT = '/campsFacilities/UpdateTripPaymentInCart/?ParseJSON=0';
export const getUpdateTripPayment = () => `${APIURL}${UPDATE_TRIP_PAYMENT}${getQP(true)}`;
export const getUpdateTripPaymentBody = (params: {
  GroupIDi: number,
  GroupTS: number,
  FacilityLocationID: number,
  FacilityTripID: number,
  TotalAmountChange: number
}) => {
  const {GroupIDi, GroupTS, FacilityLocationID, FacilityTripID, TotalAmountChange} = params;
  return {
    AppState: {
      GroupIDi: GroupIDi,
      GroupTS: GroupTS,
      FacilityLocationID: FacilityLocationID,
      FacilityTripID: FacilityTripID,
      GetOptions: false,
      GetGroupData: false
    },
    TotalAmountChange: TotalAmountChange
  };
};

// Facilities Cache 4
const GET_FACILITY_RESERVATION = '/campsFacilities/GetFacilityReservationModify/?ParseJSON=0';
export const getFacilityReservation = () => `${APIURL}${GET_FACILITY_RESERVATION}${getQP(true)}`;
export interface GetFacilityReservationInitParams {
  GroupIDi: number;
  GroupTS: number;
  FacilityLocationID: number;
  FacilityTripID: number;
  FacilityTypeID?: number;
  ReservationID?: number;
}
export const getFacilityReservationInitBody = (params: GetFacilityReservationInitParams) => {
  const {GroupIDi, GroupTS, FacilityLocationID, FacilityTripID, FacilityTypeID, ReservationID} = params;
  return {
    AppState: {
      GroupIDi,
      GroupTS,
      FacilityLocationID,
      FacilityTripID,
      GetOptions: false,
      GetGroupData: false
    },
    FacilityTypeID,
    InitialLoadForModifying: true,
    IsSaving: false,
    ReservationID: ReservationID ? ReservationID : null,
    ProposedInactive: null,
    IsUserStartDateChange: false,
    AmountChangeInCart: 0,
    TotalAmountChange: 0,
    Reservation: null
  };
};

const C4_GET_AVAILABILITIES = '/campsFacilities/GetFacilityAvailabilities_Wizard/';
export const getFacilitiesAvailitiesC4 = () => `${APIURL}${C4_GET_AVAILABILITIES}${getQP()}`;

export const getFacilitiesAvailitiesC4Body = (params: GetFacilityReservationInitParams) => {
  const {GroupIDi, GroupTS, FacilityLocationID, FacilityTripID, FacilityTypeID, ReservationID} = params;
  const body: any = {
    AppState: {
      GroupIDi,
      GroupTS,
      FacilityLocationID,
      FacilityTripID,
      GetOptions: false,
      GetGroupData: false
    },
    FacilityTypeID
  };
  if (ReservationID) body.ReservationID = ReservationID;
  return body;
};

export const getFacilitiesReservationsRecalcBody = (
  params: GetFacilityReservationInitParams,
  FacilityID: number,
  NumYouth: number,
  NumAdults: number,
  FlatRate: number | null,
  PerPersonRate: number | null,
  MinimumPeopleBilledAtPerPersonRates: number,
  ftbID: number | null | undefined,
  StartDateTime?: moment.Moment | string | null,
  EndDateTime?: moment.Moment | string | null,
  IsUserStartDateChange?: boolean
) => {
  const {GroupIDi, GroupTS, FacilityLocationID, FacilityTripID, FacilityTypeID, ReservationID} = params;

  let start = StartDateTime;
  if (StartDateTime && typeof StartDateTime !== 'string') {
    start = formatDate(StartDateTime, API_DATE_FORMAT);
  }

  let end = EndDateTime;
  if (EndDateTime && typeof EndDateTime !== 'string') {
    end = formatDate(EndDateTime, API_DATE_FORMAT);
  }

  const body: any = {
    AppState: {
      GroupIDi,
      GroupTS,
      FacilityLocationID,
      FacilityTripID,
      GetOptions: false,
      GetGroupData: false
    },
    FacilityTypeID,
    InitialLoadForModifying: false,
    IsSaving: false,
    ReservationID: ReservationID ? ReservationID : null,
    ProposedInactive: null,
    IsUserStartDateChange: !!IsUserStartDateChange,
    AmountChangeInCart: 0,
    TotalAmountChange: 0,
    Reservation: {
      NumAdults,
      NumYouth,
      FacilityID,
      ftbID: ftbID,
      StartDateTime: start ? start : null,
      EndDateTime: end ? end : null,
      FlatRate: FlatRate,
      PerPersonRate: PerPersonRate,
      MinimumPeopleBilledAtPerPersonRates: MinimumPeopleBilledAtPerPersonRates
    }
  };
  return body;
};

export const getFacilitiesReservationsSaveBody = (
  params: GetFacilityReservationInitParams,
  FacilityID: number,
  NumYouth: number,
  NumAdults: number,
  FlatRate: number,
  PerPersonRate: number,
  MinimumPeopleBilledAtPerPersonRates: number,
  ftbID: number | null,
  StartDateTime: moment.Moment | string,
  EndDateTime: moment.Moment | string,
  AmountChangeInCart: number,
  TotalAmountChange: number
) => {
  const body: any = getFacilitiesReservationsRecalcBody(
    params,
    FacilityID,
    NumYouth,
    NumAdults,
    FlatRate,
    PerPersonRate,
    MinimumPeopleBilledAtPerPersonRates,
    ftbID,
    StartDateTime,
    EndDateTime
  );
  body.IsSaving = true;
  body.AmountChangeInCart = AmountChangeInCart;
  body.TotalAmountChange = TotalAmountChange;
  return body;
};

export const getFacilitiesReservationsCancelLoadBody = (
  params: GetFacilityReservationInitParams
) => {
  const {GroupIDi, GroupTS, FacilityLocationID, FacilityTripID, FacilityTypeID, ReservationID} = params;
  return {
    AppState: {
      GroupIDi,
      GroupTS,
      FacilityLocationID,
      FacilityTripID,
      GetOptions: false,
      GetGroupData: false
    },
    FacilityTypeID: null,
    InitialLoadForModifying: false,
    IsSaving: false,
    ReservationID: ReservationID,
    ProposedInactive: true,
    IsUserStartDateChange: false,
    AmountChangeInCart: 0,
    TotalAmountChange: 0
  };
};

// Checkout
const EMPTY_CART = '/campsOrders/EmptyCart/?ParseJSON=0';
export const getEmptyCartUrl = () => `${APIURL}${EMPTY_CART}${getQP(true)}`;

const CHANGE_PAYMENT = '/campsOrders/UpdatePaymentTypeInCart/?ParseJSON=0';
export const getChangePaymentUrl = () => `${APIURL}${CHANGE_PAYMENT}${getQP(true)}`;
export const getChangePaymentBody = (value) => {
  return {
    AppState: {
      GroupIDi: value.GroupIDi,
      GroupTS: value.GroupTS
    },
    PaymentTypeID: value.PaymentTypeID,
    UseCredit: value.UseCredit,
  };
};

const CHECKOUT_CART = '/campsOrders/CheckoutCart';
export const getCheckoutCartUrl = () => `${APIURL}${CHECKOUT_CART}${getQP()}`;

const checkoutCartCC = (form: any, viewed: boolean) => {
  const options = reduxStoreService().getState().cacheZero.options;

  if (!options) {
    captureTentarooError(new Error("Trying to checkout cart with CreditCard when cacheZero is not available"));
    return {};
  }
  if (!options.Group) {
    captureTentarooError(new Error("Trying to checkout cart with CreditCard when cacheZero.Group is not available"));
    return {};
  }
  return {
    AppState:{
      GroupIDi: options.Group.IDi,
      GroupTS: options.Group.TS,
      GetOptions: false,
      GetGroupData: false
    },
    CartOrder:{
      CCNum: form.CCNum,
      CCExpMo: form.CCExpireMonth,
      CCExpYear: form.CCExpireYear,
      CCCode: form.CCCode,
      DatePayment: form.OrderDate ? formatDate(form.OrderDate) : null,
      SendReceipt: !!form.SendReceipt,
      NotApplied: form.NotApplied,
      ReceiptNum: bie(form.ReceiptNumber),
      Notes: bie(form.Notes)
    },
    GroupBillingAddress:{
      FirstName: form.FirstName,
      LastName: form.LastName,
      Address: form.Address,
      City: form.City,
      StateID: form.StateID,
      Zip: form.Zip
    },
    ViewedMessages: viewed
  };
};

const checkoutCartECheck = (form: any, viewed: boolean) => {
  const options = reduxStoreService().getState().cacheZero.options;
  if (!options) {
    captureTentarooError(new Error("Trying to checkout cart with ECheck when cacheZero is not available"));
    return {};
  }
  if (!options.Group) {
    captureTentarooError(new Error("Trying to checkout cart with CreditCard when cacheZero.Group is not available"));
    return {};
  }
  return {
    AppState:{
      GroupIDi: options.Group.IDi,
      GroupTS: options.Group.TS,
      GetOptions: false,
      GetGroupData: false
    },
    CartOrder:{
      ABARouting: form.RoutingNumber,
      ABAAcctNum: form.AccountNumber,
      ABAAcctTypeID: form.AccountType,
      ABABankName: form.BankName,
      ABAAcctName: form.NameOnAccount,
      DatePayment: form.OrderDate ? formatDate(form.OrderDate) : null,
      SendReceipt: !!form.SendReceipt,
      NotApplied: form.NotApplied,
      ReceiptNum: bie(form.ReceiptNumber),
      Notes: bie(form.Notes)
    },
    GroupBillingAddress:{
      FirstName: form.FirstName,
      LastName: form.LastName,
      Address: form.Address,
      City: form.City,
      StateID: form.StateID,
      Zip: form.Zip
    },
    ViewedMessages: viewed
  };
};

const checkoutCartOther = (form: any, viewed: boolean) => {
  const options = reduxStoreService().getState().cacheZero.options;
  if (!options) {
    captureTentarooError(new Error("Trying to checkout cart with OtherPayment when cacheZero is not available"));
    return {};
  }
  if (!options.Group) {
    captureTentarooError(new Error("Trying to checkout cart with OtherPayment when cacheZero.Group is not available"));
    return {};
  }
  return {
    AppState:{
      GroupIDi: options.Group.IDi,
      GroupTS: options.Group.TS,
      GetOptions: false,
      GetGroupData: false
    },
    CartOrder:{
      DatePayment: form.OrderDate ? formatDate(form.OrderDate) : null,
      SendReceipt: !!form.SendReceipt,
      NotApplied: form.NotApplied,
      ReceiptNum: bie(form.ReceiptNumber),
      Notes: bie(form.Notes)
    },
    GroupBillingAddress:{
      FirstName: form.FirstName,
      LastName: form.LastName,
      Address: form.Address,
      City: form.City,
      StateID: form.StateID,
      Zip: form.Zip
    },
    ViewedMessages: viewed
  };
};

export const getCheckoutCartBody = (form, viewedMessages: boolean) => {
  if (form.PaymentType === 2 || form.PaymentType === 12) {
    return checkoutCartCC(form, viewedMessages);
  } else if (form.PaymentType === 3) {
    return checkoutCartECheck(form, viewedMessages);
  } else {
    return checkoutCartOther(form, viewedMessages);
  }
};


// Previous Orders
const PREV_ORDERS = '/campsOrders/GetGroupPreviousOrders';
export const getPrevOrdersUrl = () => `${APIURL}${PREV_ORDERS}${getQP()}`;
export const getPrevOrderBody = ({GroupIDi, GroupTS, GetOptions, GetGroupData, OrderID, GetGroupPreviousOrdersData}: {GroupIDi: number; GroupTS: number; GetOptions: boolean; GetGroupData: boolean; OrderID?: number; GetGroupPreviousOrdersData?: boolean;}) => {
  const body: any = {
    AppState: {
      GroupIDi, GroupTS, GetOptions, GetGroupData
    }
  };
  if (GetGroupPreviousOrdersData !== undefined) {
    body.AppState.GetGroupPreviousOrdersData = GetGroupPreviousOrdersData;
  }
  if (OrderID) body.OrderID = OrderID;

  return body;
};

const PREV_ORDER = '/campsOrders/GetGroupPreviousOrder';
export const getPrevOrderUrl = () => `${APIURL}${PREV_ORDER}${getQP()}`;

const UPDATE_ORDER = '/campsOrders/UpdatePreviousOrder/?ParseJSON=0';
export const getUpdateOrderUrl = () => `${APIURL}${UPDATE_ORDER}${getQP(true)}`;
export const getUpdateOrderBody = (
  GroupIDi: number,
  GroupTS: number,
  OrderID: number,
  form: any,
  TS: number,
  C3PreviousOrderItems: Array<PreviousOrderItem> | null
) => {
  const body: any = {
    AppState: {
      GroupIDi,
      GroupTS
    },
    OrderID,
    Notes: form.Notes,
    DatePayment: form.DatePayment ? formatDate(form.DatePayment) : null,
    Amount: form.Amount,
    NotApplied: form.NotApplied,
    AppliedCredit: form.AppliedCredit,
    TS: TS
  };
  const items: Array<any> = [];
  if (C3PreviousOrderItems) {
    C3PreviousOrderItems.forEach((c3Item) => {
      items.push({
        ID: c3Item.ID,
        TotalAmount: form[getTotalAmountKey(c3Item)],
        AppliedCredit: form[getAppliedCreditKey(c3Item)],
        Inactive: form[getInactiveKey(c3Item)],
      });
    });
  }
  body.Items = items;
  return body;
};

const ADD_REFUND_OR_FEE = '/campsOrders/AddRefundOrConvenienceFeeToCart';
export const getAddRefundOrFeeUrl = () => `${APIURL}${ADD_REFUND_OR_FEE}${getQP()}`;
export const getAddRefundOrFeeBody = (
  GroupIDi: number,
  GroupTS: number,
  Refund: number,
  ConvenienceFee: number
) => {
  return {
    AppState: {
      GroupIDi,
      GroupTS,
      GetOptions: false,
      GetGroupData: false
    },
    Refund,
    ConvenienceFee
  };
};

// Reports
export const getReportUrl = (filename: string, ReportPath?: string) => {
  /**
   * For PDF reports that use the new PDF conversion server
   */
  if (ReportPath) {
    return `${ReportPath}${filename}`;
  }

  /**
   * For other reports
   */
  return `${getDomain(true)}/reports/${filename}`;
};


const INVOICE = '/campsGenerateReports/ExportInvoiceGroup';
export const getInvoiceUrl = () => {
  return `${APIURL}${INVOICE}${getQP()}`;
};
export const getInvoiceBody = ({EventIDi, EventTypeID, GroupIDi}: {EventIDi: number; EventTypeID: number; GroupIDi: number;}) => {
  return {EventIDi, EventTypeID, GroupIDi};
};

const REPORT_ROSTER = '/campsGenerateReports/GenerateParticipantRoster/participantRoster.pdf';
export const getReportRosterUrl = () => {
  return `${APIURL}${REPORT_ROSTER}${getQP()}`;
};
export const getReportRosterBody = ({EventIDi, EventTypeID, GroupWeekIDi}: {EventIDi: number; EventTypeID: number; GroupWeekIDi: number;}) => {
  return {
    EventIDi,
    EventTypeID,
    GroupWeekIDi,
  };
};

const CLASS_SCHEDULE = '/campsGenerateReports/GenerateClassSchedule/classSchedule.pdf';
export const getClassScheduleUrl = ({EventIDi, EventTypeID, GroupWeekIDi, reportDoc}: {EventIDi: number; EventTypeID: number; GroupWeekIDi: number; reportDoc: string;}) => {
  return `${APIURL}${CLASS_SCHEDULE}?EventIDi=${EventIDi}&EventTypeID=${EventTypeID}&GroupWeekIDi=${GroupWeekIDi}&reportDoc=${reportDoc}${getQP(true)}`;
};


const REQUIREMENTS_COMPLETED = '/campsGenerateReports/ExportClassScheduleGroup';
export const getRequirementsCompletedUrl = () => {
  return `${APIURL}${REQUIREMENTS_COMPLETED}${getQP()}`;
};
export const getRequirementsCompletedBody = ({EventIDi, EventTypeID, GroupIDi}: {EventIDi: number; EventTypeID: number; GroupIDi: number;}) => {
  return {EventIDi, EventTypeID, GroupIDi};
};

const REQUIREMENTS_COMPLETED_PDF = '/campsGenerateReports/GenerateClassSchedule/classScheduleRequirementsCompleted.pdf';
export const getRequirementsCompletedPDFUrl = ({EventIDi, EventTypeID, GroupWeekIDi, reportDoc}: {EventIDi: number; EventTypeID: number; GroupWeekIDi: number; reportDoc: string;}) => {
  return `${APIURL}${REQUIREMENTS_COMPLETED_PDF}?EventIDi=${EventIDi}&EventTypeID=${EventTypeID}&GroupWeekIDi=${GroupWeekIDi}&reportDoc=${reportDoc}${getQP(true)}`;
};

const BLUE_CARD = '/campsGenerateReports/ExportBlueCardsGroup';
export const getBlueCardUrl = () => {
  return `${APIURL}${BLUE_CARD}${getQP()}`;
};
export const getBlueCardBody = ({EventIDi, GroupIDi, IsFront}: {EventIDi: number; GroupIDi: number; IsFront: boolean;}) => {
  return {EventIDi, GroupIDi, IsFront};
};

const REQUIREMENTS_SCOUTBOOK = '/campsGenerateReports/ExportScoutbookRequirements/';
export const getRequirementsScoutbookUrl = () => {
  return `${APIURL}${REQUIREMENTS_SCOUTBOOK}${getQP()}`;
};
export const getRequirementsScoutbookBody = ({EventTypeID, EventIDi, GroupIDi}: {EventTypeID: number; EventIDi: number; GroupIDi: number;}) => {
  return {EventTypeID, EventIDi, GroupIDi};
};

export const getViewParticipantsReportUrl = ({EventTypeID, GroupWeekIDi, ParticipantEventIDi, IsYouth}: {EventTypeID: number; GroupWeekIDi: number; ParticipantEventIDi: number; IsYouth: boolean;}) => {
  return `${APIURL}${CLASS_SCHEDULE}?EventIDi=0&IsYouth=${IsYouth ? 1 : 0}&EventTypeID=${EventTypeID}&GroupWeekIDi=${GroupWeekIDi}&ParticipantEventIDi=${ParticipantEventIDi}&reportDoc=groupSchedule_SelfRegistration.cfr${getQP(true)}`;
};

const TRIP_ITINERARY = '/campsGenerateReports/GenerateTripItinerary/tripItinerary.pdf';
export const getTripItineraryReportUrl = ({LocationID, TripID}: {LocationID: number; TripID: number;}) => {
  return `${APIURL}${TRIP_ITINERARY}?LocationID=${LocationID}&TripID=${TripID}${getQP(true)}`;
};

const AUDIT_LOG = '/campsGenerateReports/ExportAuditLogGroup';
export const getAuditLogUrl = () => {
  return `${APIURL}${AUDIT_LOG}${getQP()}`;
};
export const getAuditLogBody = ({GroupIDi, StartDate}: {GroupIDi: number; StartDate: moment.Moment}) => {
  return {
    GroupIDi: GroupIDi,
    StartDate: formatDate(StartDate),
  };
};

/**
 * CMS Facility Location
 */
const AdminFacilityLocationCacheBodyParamsKeys = {
  LocationID: true,
  LocationTS: true,
  GetFacilitiesOptions: true,
  GetFacilities: true,
  MaxFacilityID: true,
  GetFacilityTypes: true,
  MaxFacilityTypeID: true,
  GetBlackoutDates: true,
  BlackoutDatesFilters: true,
  MaxGLAccountID: true,
  GetOptions: true,
  GroupIDi: true,
  GroupTS: true,
};

const constructAdminFacilityLocationCacheAppParams = (params: any) => {
  const result = {};

  for (let key of Object.keys(params)) {
    if (AdminFacilityLocationCacheBodyParamsKeys[key]) {
      result[key] = params[key];
    }
  }

  return result;
};

interface AdminFacilityLocationRouteParams {
  locationId: number;
  locationName: string;
  blackoutDateId?: number;
  blackoutDateReason?: string;
  facilityTypeId?: number;
  facilityTypeName?: string;
  facilityId?: number;
  facilityName?: string;
}

// param construction
export const constructAdminFacilityLocationUrlParams = (
  props: RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}>,
  cacheOne: AdminFacilityLocationCacheOneState,
): AdminFacilityLocationRouteParams => {
  return {
    locationId: getFacilityLocationId(props),
    locationName: cacheOne.FacilitiesLocation ? cacheOne.FacilitiesLocation.Name : '',
  };
};

export const constructAdminFacilityLocationFacilityTypeUrlParams = (props: RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}>, cacheOne: AdminFacilityLocationCacheOneState, facilityType: AdminFacilityType): AdminFacilityLocationRouteParams => {
  return {
    ...constructAdminFacilityLocationUrlParams(props, cacheOne),
    facilityTypeId: facilityType.ID,
    facilityTypeName: facilityType.Name,
  };
};

export const constructAdminFacilityLocationFacilityUrlParams = (props: RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}>, cacheOne: AdminFacilityLocationCacheOneState, facility: AdminFacility): AdminFacilityLocationRouteParams => {
  return {
    ...constructAdminFacilityLocationUrlParams(props, cacheOne),
    facilityId: facility.ID,
    facilityName: facility.Name,
  };
};

export const constructAdminFacilityLocationBlackoutDateUrlParams = (props: RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}>, cacheOne: AdminFacilityLocationCacheOneState, blackoutDate?: FacilitiesBlackoutDate): AdminFacilityLocationRouteParams => {
  return {
    ...constructAdminFacilityLocationUrlParams(props, cacheOne),
    blackoutDateId: !blackoutDate ? undefined : blackoutDate.ID,
    blackoutDateReason: !blackoutDate ? undefined : blackoutDate.Reason,
  };
};

const AdminFacilityLocationOptionsLoaded = () => {
  const cacheOne = (reduxStoreService().getState() as ApplicationState).adminFacilityLocation.cacheOne;

  return !!cacheOne.AllowOnlineBookingOptions;
};

// AdminFacilityLocation Cache One
const GET_FACILITY_LOCATION_CACHE = '/FacilitiesLocations/GetFacilityLocationCache';
export const getFacilityLocationCache = () => `${FACILITY_LOCATION_APIURL}${GET_FACILITY_LOCATION_CACHE}${getQP()}`;
export interface GetFacilityLocationCacheBodyParams {
  LocationID?: number;
  LocationTS?: number;
  GetFacilitiesOptions?: boolean;
  GetFacilities?: boolean;
  MaxFacilityID?: number;
  GetFacilityTypes?: boolean;
  MaxFacilityTypeID?: number;
  GetBlackoutDates?: boolean;
  BlackoutDatesFilters?: BlackoutDatesFilters;
  GetOptions?: boolean;
  MaxGLAccountID?: number;
  GroupIDi?: number;
  GroupTS?: number;
  routes?: any;
  location?: any;
  params?: any;
}

export const constructGetFacilityLocationCacheBodyBaseParams = (props: RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}>): GetFacilityLocationCacheBodyParams => {
  // not adding null check here, since these nodes should be available
  const state: ApplicationState = reduxStoreService().getState();

  const adminFacilityLocationCacheOne = state.adminFacilityLocation.cacheOne;

  const GroupIDi = getGroupID(props);
  const GroupTS = state.cacheZero.options && state.cacheZero.options.Group ? state.cacheZero.options.Group.TS : 0;
  const LocationID = getFacilityLocationId(props);
  const LocationTS = adminFacilityLocationCacheOne.FacilitiesLocation ? adminFacilityLocationCacheOne.FacilitiesLocation.TS : 0;
  const GetFacilitiesOptions = !AdminFacilityLocationOptionsLoaded();
  const MaxFacilityID = adminFacilityLocationCacheOne.Facilities ? getMaxId(adminFacilityLocationCacheOne.Facilities) : 0;
  const MaxFacilityTypeID = adminFacilityLocationCacheOne.FacilitiesTypes ? getMaxId(adminFacilityLocationCacheOne.FacilitiesTypes) : 0;
  const BlackoutDatesFilters = adminFacilityLocationCacheOne.BlackoutDatesFilters ? adminFacilityLocationCacheOne.BlackoutDatesFilters : undefined;
  const MaxGLAccountID = state.cacheZero.options && state.cacheZero.options.GLAccounts ? getMaxId(state.cacheZero.options.GLAccounts) : 0;
  const GetOptions = !state.cacheZero.options;

  return {
    LocationID,
    LocationTS,
    GetFacilitiesOptions,
    GetFacilities: false,
    MaxFacilityID,
    GetFacilityTypes: false,
    MaxFacilityTypeID,
    GetBlackoutDates: false,
    BlackoutDatesFilters,
    MaxGLAccountID,
    GetOptions,
    GroupIDi,
    GroupTS,
  };
};

export const constructUpdateFacilityLocationCacheBodyBaseParams = (): GetFacilityLocationCacheBodyParams => {
  // not adding null check here, since these nodes should be available
  const state: ApplicationState = reduxStoreService().getState();

  const adminFacilityLocationCacheOne = state.adminFacilityLocation.cacheOne;

  const GroupIDi = state.cacheZero.options && state.cacheZero.options.Group ? state.cacheZero.options.Group.IDi : 0;
  const GroupTS = state.cacheZero.options && state.cacheZero.options.Group ? state.cacheZero.options.Group.TS : 0;
  const LocationID = adminFacilityLocationCacheOne.FacilitiesLocation ? adminFacilityLocationCacheOne.FacilitiesLocation.ID : 0;
  const LocationTS = adminFacilityLocationCacheOne.FacilitiesLocation ? adminFacilityLocationCacheOne.FacilitiesLocation.TS : 0;
  const GetFacilitiesOptions = !AdminFacilityLocationOptionsLoaded();
  const MaxFacilityID = adminFacilityLocationCacheOne.Facilities ? getMaxId(adminFacilityLocationCacheOne.Facilities) : 0;
  const MaxFacilityTypeID = adminFacilityLocationCacheOne.FacilitiesTypes ? getMaxId(adminFacilityLocationCacheOne.FacilitiesTypes) : 0;
  const BlackoutDatesFilters = adminFacilityLocationCacheOne.BlackoutDatesFilters ? adminFacilityLocationCacheOne.BlackoutDatesFilters : undefined;
  const MaxGLAccountID = state.cacheZero.options && state.cacheZero.options.GLAccounts ? getMaxId(state.cacheZero.options.GLAccounts) : 0;
  const GetOptions = !state.cacheZero.options;

  return {
    LocationID,
    LocationTS,
    GetFacilitiesOptions,
    GetFacilities: false,
    MaxFacilityID,
    GetFacilityTypes: false,
    MaxFacilityTypeID,
    GetBlackoutDates: false,
    BlackoutDatesFilters,
    MaxGLAccountID,
    GetOptions,
    GroupIDi,
    GroupTS,
  };
};
export const getFacilityLocationCacheBody = (params: GetFacilityLocationCacheBodyParams) => {
  const baseParams = {...constructGetFacilityLocationCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params} as any)};
  const customParams = constructAdminFacilityLocationCacheAppParams(params);
  return {
    AppState: {
      ...baseParams,
      ...customParams,
    },
  };
};

// AdminFacilityLocation - Availabilities
const GetAvailabilities = '/Availabilities/GetAvailabilities/';
export const getAvailabilities = () => `${FACILITY_LOCATION_APIURL}${GetAvailabilities}${getQP()}`;

export interface GetAvailabilitiesParams {
  FacilityTypeID: number;
  StartDate: string | null;
  location?: any;
  routes?: any;
  params?: any;
}

export const getAvailabilitiesBody = (params: GetAvailabilitiesParams) => {
  const baseParams = constructGetFacilityLocationCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params} as any);
  return {
    AppState: {
      ...baseParams,
    },
    FacilityTypeID: params.FacilityTypeID,
    StartDate: params.StartDate,
  };
};

// AdminFacilityLocation Cache Two - Facility Type
const GET_FACILITY_TYPE = '/FacilityTypes/GetFacilityType/';
export const getFacilityType = () => `${FACILITY_LOCATION_APIURL}${GET_FACILITY_TYPE}${getQP()}`;

export interface GetFacilityTypeParams {
  ID: number;
  location?: any;
  routes?: any;
  params?: any;
}

export const getFacilityTypeBody = (params: GetFacilityTypeParams) => {
  const baseParams = constructGetFacilityLocationCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params} as any);
  return {
    AppState: {
      ...baseParams,
    },
    ID: params.ID,
  };
};

const UPDATE_FACILITY_TYPE = '/FacilityTypes/UpdateFacilityType/?ParseJSON=0';
export const getUpdateFacilityTypeUrl = () => `${FACILITY_LOCATION_APIURL}${UPDATE_FACILITY_TYPE}${getQP(true)}`;
export const getUpdateFacilityTypeBody = () => {
  const body: any = {
    AppState: {
      ...constructUpdateFacilityLocationCacheBodyBaseParams(),
    },
  };

  return body;
};

// AdminFacilityLocation Cache Two - Facility
const GET_FACILITY = '/Facilities/GetFacility/';
export const getFacility = () => `${FACILITY_LOCATION_APIURL}${GET_FACILITY}${getQP()}`;

export interface GetFacilityParams {
  ID: number;
  location?: any;
  routes?: any;  
  params?: any;
}

export const getFacilityBody = (params: GetFacilityParams) => {
  const baseParams = constructGetFacilityLocationCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params} as any);
  return {
    AppState: {
      ...baseParams,
      FacilityID: params.ID,
    },
  };
};

const ADD_FACILITY = '/Facilities/UpdateFacility/?ParseJSON=0';
export const getUpdateFacilityUrl = () => `${FACILITY_LOCATION_APIURL}${ADD_FACILITY}${getQP(true)}`;
export interface GetAddFacilityParams {
  location?: any;
  routes?: any;
  params?: any;
}
export const getAddFacilityBody = (form: NewFacilityModalActiveForm) => {
  const baseParams = constructUpdateFacilityLocationCacheBodyBaseParams();
  return {
    AppState: {
      ...baseParams,
    },
    General: [
      {
        IsPrevious: false,
        FacilityTypeID: form.SelectedFacilityTypeID,
        Name: form.Name,
        Description: form.Description,
      },
    ],
  };
};

export const getUpdateFacilityBody = (FacilityID: number) => {
  const baseParams = constructUpdateFacilityLocationCacheBodyBaseParams();
  return {
    AppState: {
      ...baseParams,
      FacilityID,
    },
  };
};

export const getDeleteRestoreFacilityBody = (ID: number, Inactive: boolean) => {
  const baseParams = constructUpdateFacilityLocationCacheBodyBaseParams();
  return {
    AppState: {
      ...baseParams,
      FacilityID: ID,
    },
    Inactive,
  };
};

const IMPORT_BOOKING_TIMES = '/Facilities/UpdateFacility_ImportBookingTimes/?ParseJSON=0';
export const getImportBookingTimesUrl = () => `${FACILITY_LOCATION_APIURL}${IMPORT_BOOKING_TIMES}${getQP(true)}`;
const IMPORT_PRICING = '/Facilities/UpdateFacility_ImportPricing/?ParseJSON=0';
export const getImportPricingUrl = () => `${FACILITY_LOCATION_APIURL}${IMPORT_PRICING}${getQP(true)}`;
const IMPORT_IMAGES = '/Facilities/UpdateFacility_ImportImages/?ParseJSON=0';
export const getImportImagesUrl = () => `${FACILITY_LOCATION_APIURL}${IMPORT_IMAGES}${getQP(true)}`;
export const getImportFromFacilityBody = (FacilityID: number, FromID: number) => {
  const baseParams = constructUpdateFacilityLocationCacheBodyBaseParams();
  return {
    AppState: {
      ...baseParams,
      FacilityID,
    },
    FromID,
  };
};

const EDIT_FACILITY_ADD_IMAGE = '/FacilityPhotos/UpdateFacilityImage_Add/?ParseJSON=0';
export const getEditFacilityAddImage = () => `${FACILITY_LOCATION_APIURL}${EDIT_FACILITY_ADD_IMAGE}${getQP(true)}`;
export const getEditFacilityAddImageBody = (FacilityID: number) => {
  const body: any = {
    AppState: {
      ...constructUpdateFacilityLocationCacheBodyBaseParams(),
      FacilityID,
    }
  };

  return body;
};

const EDIT_FACILITY_EDIT_IMAGE = '/FacilityPhotos/UpdateFacilityImage_Edit/?ParseJSON=0';
export const getEditFacilityEditImage = () => `${FACILITY_LOCATION_APIURL}${EDIT_FACILITY_EDIT_IMAGE}${getQP(true)}`;
export const getEditFacilityEditImageBody = (FacilityID: number, ImageID: number, Inactive: boolean) => {
  const body: any = {
    AppState: {
      ...constructUpdateFacilityLocationCacheBodyBaseParams(),
      FacilityID,
    },
    row: {
      ID: ImageID,
      Inactive,
    },
  };

  return body;
};

// AdminFacilityLocation Cache Two - Blackout Date
const GET_BLACKOUT_DATE = '/BlackoutDates/GetBlackoutDate/';
export const getBlackoutDate = () => `${FACILITY_LOCATION_APIURL}${GET_BLACKOUT_DATE}${getQP()}`;

export interface GetBlackoutDateParams extends GetFacilityLocationCacheBodyParams {
  ID: number;
  location?: any;
  routes?: any;
  params?: any;
}

export const getBlackoutDateBody = (params: GetBlackoutDateParams) => {
  const baseParams = constructGetFacilityLocationCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params} as any);
  const customParams = constructAdminFacilityLocationCacheAppParams(params);
  return {
    AppState: {
      ...baseParams,
      ...customParams,
    },
    ID: params.ID,
  };
};

const UPDATE_BLACKOUT_DATE = '/BlackoutDates/UpdateBlackoutDate/?ParseJSON=0';
export const getUpdateBlackoutDateUrl = () => `${FACILITY_LOCATION_APIURL}${UPDATE_BLACKOUT_DATE}${getQP(true)}`;
export const getUpdateBlackoutDateBody = (form: BlackoutDateActiveForm) => {
  const startDate = formatDate(form.StartDate);
  const endDate = formatDate(form.EndDate);
  const startDateTime = constructDateTime(startDate, form.StartHour, form.StartMin, form.StartPeriod);
  const endDateTime = constructDateTime(endDate, form.EndHour, form.EndMin, form.EndPeriod);
  const body: any = {
    AppState: {
      ...constructUpdateFacilityLocationCacheBodyBaseParams(),
    },
    row: {
      ID: form.ID,
      Reason: form.Reason,
      StartDate: startDateTime,
      EndDate: endDateTime,
      TS: form.TS,
    },
    Facilities: form.Facilities,
  };

  return body;
};

const DELETE_BLACKOUT_DATE = '/BlackoutDates/UpdateBlackoutDate/?ParseJSON=0';
export const getDeleteBlackoutDateUrl = () => `${FACILITY_LOCATION_APIURL}${DELETE_BLACKOUT_DATE}${getQP(true)}`;
export const getDeleteBlackoutDateBody = (id: number, Inactive: boolean) => {
  const body: any = {
    AppState: {
      ...constructUpdateFacilityLocationCacheBodyBaseParams(),
    },
    row: {
      ID: id,
      Inactive,
    },
  };

  return body;
};

// AdminFacilityLocation - Reports
const FACILITIES_TRIP_ITINERARY = '/campsGenerateReports/GenerateTripItinerary/tripItinerary.pdf';
export const getFacilitiesTripItineraryReportUrl = ({LocationID, TripID, StartDate, EndDate}: {LocationID: number; TripID: number; StartDate: moment.Moment; EndDate: moment.Moment;}) => {
  return `${APIURL}${FACILITIES_TRIP_ITINERARY}?LocationID=${LocationID}&TripID=${TripID}&StartDate=${StartDate.format(API_DATE_FORMAT)}&EndDate=${EndDate.format(API_DATE_FORMAT)}${getQP(true)}`;
};

const FACILITIES_GROUP_TRIPS = '/campsGenerateReports/ExportGroupTrips/';
export const getFacilitiesGroupTripsReportUrl = () => {
  return `${APIURL}${FACILITIES_GROUP_TRIPS}${getQP()}`;
};
export const getFacilitiesGroupTripsBody = ({LocationID, StartDate, EndDate, IncludeBlackoutDates}: {LocationID: number; StartDate: moment.Moment; EndDate: moment.Moment; IncludeBlackoutDates: boolean}) => {
  return {LocationID, StartDate, EndDate, IncludeBlackoutDates};
};

const FACILITIES_GROUP_RESERVATIONS = '/campsGenerateReports/ExportGroupReservations/';
export const getFacilitiesGroupReservationsReportUrl = () => {
  return `${APIURL}${FACILITIES_GROUP_RESERVATIONS}${getQP()}`;
};
export const getFacilitiesGroupReservationsBody = ({LocationID, StartDate, EndDate, IncludeBlackoutDates}: {LocationID: number; StartDate: moment.Moment; EndDate: moment.Moment; IncludeBlackoutDates: boolean;}) => {
  return {LocationID, StartDate, EndDate, IncludeBlackoutDates};
};

// AdminFacilityLocation - Settings
export const UPDATE_FACILITY_LOCATION_SETTINGS = '/FacilitiesLocations/UpdateFacilityLocation/?ParseJSON=0';
export const getUpdateFacilityLocationSettingsUrl = () => `${FACILITY_LOCATION_APIURL}${UPDATE_FACILITY_LOCATION_SETTINGS}${getQP(true)}`;
export const getUpdateAdminFacilityLocationSettingsBody = (form: FacilitiesSettingsActiveForm) => {
  const body: any = {
    AppState: {
      ...constructUpdateFacilityLocationCacheBodyBaseParams()
    },
    row: {
      Name: form.Name,
      Address: form.Address,
      City: form.City,
      StateID: form.StateID,
      Zip: form.Zip,
      PhoneNumber: form.PhoneNumber,
      FaxNumber: form.FaxNumber,
      FacilitiesWebsiteURL: form.FacilitiesWebsiteURL,
      AllowOnlineFacilityBooking: form.AllowOnlineFacilityBooking,
      RequireTripContactPhone: form.RequireTripContactPhone,
      AllowBookingMaxMonthsAhead: form.AllowBookingMaxMonthsAhead,
      AllowBookingMinDaysPrior: form.AllowBookingMinDaysPrior,
      AllowCreditNumDaysPrior: form.AllowCreditNumDaysPrior,
      AllowChangesNumDaysPrior: form.AllowChangesNumDaysPrior,
      PrimaryContactName: form.PrimaryContactName,
      PrimaryContactEmail: form.PrimaryContactEmail,
      PrimaryContactPhone: form.PrimaryContactPhone,
      TripNotificationHTML: form.TripNotificationHTML,
    },
  };

  return body;
};

export const getFacilitiesHomeRootUrl = (params: AdminFacilityLocationRouteParams) => {
  return `${URLS.ADMIN_FACILITY_LOCATION}/${params.locationId}/${spaceTo_(params.locationName)}/${FACILITY_LOCATION_PATH.FACILITIES}${getAccountQP()}`;
};
export const getFacilityTypesRootUrl = (params: AdminFacilityLocationRouteParams) => {
  return `${URLS.ADMIN_FACILITY_LOCATION}/${params.locationId}/${spaceTo_(params.locationName)}/${FACILITY_LOCATION_PATH.TYPES}${getAccountQP()}`;
};
export const getNewFacilityTypeUrl = (params: AdminFacilityLocationRouteParams) => {
  return `${URLS.ADMIN_FACILITY_LOCATION}/${params.locationId}/${spaceTo_(params.locationName)}/${FACILITY_LOCATION_PATH.TYPES}/${FACILITY_LOCATION_FACILITY_TYPE.NEW}${getAccountQP()}`;
};
export const getEditFacilityTypeUrl = (params: AdminFacilityLocationRouteParams) => {
  if (params.facilityTypeName) {
    return `${URLS.ADMIN_FACILITY_LOCATION_TYPE}/${params.locationId}/${params.facilityTypeId}/${spaceTo_(params.facilityTypeName)}${getAccountQP()}`;
  }
  return '';
};
export const getEditFacilityUrl = (params: AdminFacilityLocationRouteParams) => {
  if (params.facilityName) {
    return `${URLS.ADMIN_FACILITY_LOCATION_FACILITY}/${params.locationId}/${params.facilityId}/${spaceTo_(params.facilityName)}${getAccountQP()}`;
  }
  return '';
};

export const getAvailabilitiesHomeRootUrl = (params: AdminFacilityLocationRouteParams) => {
  return `${URLS.ADMIN_FACILITY_LOCATION}/${params.locationId}/${spaceTo_(params.locationName)}/${FACILITY_LOCATION_PATH.AVAILABILITIES}${getAccountQP()}`;
};
export const getBlackoutDatesHomeRootUrl = (params: AdminFacilityLocationRouteParams) => {
  return `${URLS.ADMIN_FACILITY_LOCATION}/${params.locationId}/${spaceTo_(params.locationName)}/${FACILITY_LOCATION_PATH.BLACKOUT_DATES}${getAccountQP()}`;
};
export const getEditBlackoutDateUrl = (params: AdminFacilityLocationRouteParams) => {
  if (params.blackoutDateReason) {
    return `${URLS.ADMIN_FACILITY_LOCATION_BLACKOUT_DATE}/${params.locationId}/${params.blackoutDateId}/${spaceTo_(params.blackoutDateReason)}${getAccountQP()}`;
  }
};
export const getNewBlackoutDateUrl = (params: AdminFacilityLocationRouteParams) => {
  return `${URLS.ADMIN_FACILITY_LOCATION}/${params.locationId}/${spaceTo_(params.locationName)}/${FACILITY_LOCATION_PATH.BLACKOUT_DATES}/${FACILITY_LOCATION_BLACKOUT_DATE.NEW}${getAccountQP()}`;
};
export const getReportsHomeRootUrl = (params: AdminFacilityLocationRouteParams) => {
  return `${URLS.ADMIN_FACILITY_LOCATION}/${params.locationId}/${spaceTo_(params.locationName)}/${FACILITY_LOCATION_PATH.REPORTS}${getAccountQP()}`;
};
export const getFacilitiesLocationSettingsHomeRootUrl = (params: AdminFacilityLocationRouteParams) => {
  return `${URLS.ADMIN_FACILITY_LOCATION}/${params.locationId}/${spaceTo_(params.locationName)}/${FACILITY_LOCATION_PATH.SETTINGS}${getAccountQP()}`;
};

/**
 * Admin Settings
 */

const AdminSettingsBodyParamsKeys = {
  MaxGLAccountID: true,
};
export interface UpdateAdminSettingsBodyParams {
  MaxGLAccountID?: number;
  routes?: any;
  location?: any;
  params?: any;
}
export const constructUpdateAdminSettingsBodyBaseParams = (): UpdateAdminSettingsBodyParams => {
  const state: ApplicationState = reduxStoreService().getState();

  const MaxGLAccountID = state.cacheZero.options && state.cacheZero.options.GLAccounts ? getMaxId(state.cacheZero.options.GLAccounts) : 0;
  return {
    MaxGLAccountID,
  };
};

// Get GL Accounts
const GET_GL_ACCOUNTS = '/GLAccounts/GetGLAccounts';
export const getGLAccounts = () => `${ADMIN_SETTINGS_API_URL}${GET_GL_ACCOUNTS}${getQP()}`;

// AdminSettings CacheTwo - GLAccounts
const GET_GL_ACCOUNT = '/GLAccounts/GetGLAccount';
export const getGLAccount = () => `${ADMIN_SETTINGS_API_URL}${GET_GL_ACCOUNT}${getQP()}`;
export interface GetGLAccountParams {
  ID: number;
  location?: any;
  routes?: any;
  params?: any;
}

export const getGLAccountBody = (params: GetGLAccountParams) => {
  return {
    ID: params.ID,
  };
};
const UPDATE_GL_ACCOUNT = '/GLAccounts/UpdateGLAccount?ParseJSON=0';
export const getUpdateGLAccountUrl = () => `${ADMIN_SETTINGS_API_URL}${UPDATE_GL_ACCOUNT}${getQP(true)}`;

export const getSubmitGLAccountBody = (form: GLAccountActiveForm) => {
  const body: any = {
    ...constructUpdateAdminSettingsBodyBaseParams(),
    row: {
      ID: form.ID,
      Name: form.Name,
      AccountNum: form.AccountNum,
      TS: form.TS,
    },
  };

  return body;
};

/**
 * CMS Sites
 */

export const MAXIMUM_MAX_ID = 10000;
export const getMaxId = (items: any[], isIDi?: boolean) => {
  if (items.length === 0) return 0;
  const sorted = [...items];
  sorted.sort((a, b) => {
    if (isIDi) return (a.IDi > b.IDi) ? -1 : 1;
    return (a.ID > b.ID) ? -1 : 1;
  });
  // if passed in array has no ID field, would cause errors, but we probably want to know that
  return isIDi ? sorted[0].IDi : sorted[0].ID;
};
const CMSStaticOptionsLoaded = () => {
  const cacheOne = (reduxStoreService().getState() as ApplicationState).adminCMSSite.cacheOne;

  return cacheOne && cacheOne.ContactLayouts;
};
const CMSSiteCacheBodyParamsKeys = {
  SiteID: true,
  SiteTS: true,
  HomePageTS: true,
  homepage1TS: true,
  GetCMSOptions: true,
  GetPages: true,
  GetContacts: true,
  MaxContactID: true,
  GetResources: true,
  MaxResourceID: true,
  GetResourceCategories: true,
  MaxResourceCategoryID: true,
  GetSiteMenus: true,
  MaxSiteMenuID: true,
  GetPageMenus: true,
  MaxPageMenuID: true,
  // CMSPagesFilters: true, // we manually override this one
  MaxEventTypeID: true,
  MaxLocationID: true,
  GetOptions: true,
  GroupIDi: true,
  GroupTS: true,
};

const constructCMSSiteCacheAppParams = (params: any) => {
  const result = {};

  for (let key of Object.keys(params)) {
    if (CMSSiteCacheBodyParamsKeys[key]) {
      result[key] = params[key];
    }
  }

  return result;
};

// Shared Cache Two Location

const GET_LOCATION = '/GetLocation';
export const getLocation = () => `${CMS_SYSTEM_ADMIN_APIURL}${GET_LOCATION}${getQP()}`;
export const getLocationBody = (params: {ID: number}) => {
  return {
    ID: params.ID,
  };
};

// Cache One
const GET_SITE_CACHE = '/CMSSites/GetCMSSiteCache';
export const getSiteCache = () => `${CMS_APIURL}${GET_SITE_CACHE}${getQP()}`;
export interface GetSiteCacheBodyParams {
  SiteID?: number;
  SiteTS?: number;
  HomepageTS?: number;
  homepage1TS?: number;
  GetCMSOptions?: boolean;
  GetPages?: boolean;
  GetContacts?: boolean;
  MaxContactID?: number;
  GetResources?: boolean;
  MaxResourceID?: number;
  GetResourceCategories?: boolean;
  MaxResourceCategoryID?: number;
  GetSiteMenus?: boolean;
  MaxSiteMenuID?: number;
  GetPageMenus?: boolean;
  MaxPageMenuID?: number;
  CMSPagesFilters?: CMSPagesFilters;
  MaxEventTypeID?: number;
  MaxLocationID?: number;
  GetOptions?: boolean;
  GroupIDi?: number | null;
  GroupTS?: number;
  routes?: any;
  location?: any;
  params?: any;
}

export const constructUpdateSiteCacheBodyBaseParams = (): GetSiteCacheBodyParams => {
  // not adding null check here, since these nodes should be available
  const state: ApplicationState = reduxStoreService().getState();
  const GroupIDi = state.cacheZero.options && state.cacheZero.options.Group ? state.cacheZero.options.Group.IDi : null;
  const adminCMSCacheOne = state.adminCMSSite.cacheOne;
  const GroupTS = state.cacheZero.options && state.cacheZero.options.Group ? state.cacheZero.options.Group.TS : 0;
  const HomepageTS = adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.HomepageTS : 0;
  const homepage1TS = adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.homepage1TS : 0;
  const SiteTS = adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.SiteTS : 0;
  const GetCMSOptions = !CMSStaticOptionsLoaded();
  const MaxEventTypeID = state.cacheZero.options && state.cacheZero.options.EventTypes ? getMaxId(state.cacheZero.options.EventTypes) : 0;
  const MaxLocationID = state.cacheZero.options && state.cacheZero.options.Locations ? getMaxId(state.cacheZero.options.Locations) : 0;
  const MaxResourceCategoryID = adminCMSCacheOne.CMSResourceCategories ? getMaxId(adminCMSCacheOne.CMSResourceCategories) : 0;
  const MaxResourceID = adminCMSCacheOne.CMSResources ? getMaxId(adminCMSCacheOne.CMSResources) : 0;
  const MaxSiteMenuID = adminCMSCacheOne.CMSSiteMenuItems ? getMaxId(adminCMSCacheOne.CMSSiteMenuItems) : 0;
  const MaxContactID = adminCMSCacheOne.CMSContacts ? getMaxId(adminCMSCacheOne.CMSContacts) : 0;
  const MaxPageMenuID = adminCMSCacheOne.CMSPageMenuItems ? getMaxId(adminCMSCacheOne.CMSPageMenuItems) : 0;
  const SiteID = adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.ID : 0;
  const GetOptions = !state.cacheZero.options;
  const CMSPagesFilters = adminCMSCacheOne.CMSPagesFilters && Object.keys(adminCMSCacheOne.CMSPagesFilters).length > 0 ? {
    ...adminCMSCacheOne.CMSPagesFilters,
    EventStartDate: adminCMSCacheOne.CMSPagesFilters.EventStartDate ? formatDate(adminCMSCacheOne.CMSPagesFilters.EventStartDate) : null,
  } : undefined;

  return {
    SiteID,
    GetPages: false,
    GetContacts: false,
    GetResources: false,
    GetResourceCategories: false,
    GetSiteMenus: false,
    GetPageMenus: false,
    GetOptions,
    GetCMSOptions,
    GroupTS,
    GroupIDi,
    HomepageTS,
    homepage1TS,
    SiteTS,
    MaxEventTypeID,
    MaxLocationID,
    MaxResourceCategoryID,
    MaxContactID,
    MaxResourceID,
    MaxSiteMenuID,
    MaxPageMenuID,
    CMSPagesFilters,
  };
};

export const constructGetSiteCacheBodyBaseParams = (props: {location: any, routes: any, params: any}): GetSiteCacheBodyParams => {
  // not adding null check here, since these nodes should be available
  const state: ApplicationState = reduxStoreService().getState();
  const adminCMSCacheOne = state.adminCMSSite.cacheOne;
  const GroupIDi = getGroupID(props);
  const GroupTS = state.cacheZero.options && state.cacheZero.options.Group ? state.cacheZero.options.Group.TS : 0;
  const HomepageTS = adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.HomepageTS : 0;
  const homepage1TS = adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.homepage1TS : 0;
  const SiteTS = adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.SiteTS : 0;
  const GetCMSOptions = !CMSStaticOptionsLoaded();
  const MaxEventTypeID = state.cacheZero.options && state.cacheZero.options.EventTypes ? getMaxId(state.cacheZero.options.EventTypes) : 0;
  const MaxLocationID = state.cacheZero.options && state.cacheZero.options.Locations ? getMaxId(state.cacheZero.options.Locations) : 0;
  const MaxResourceCategoryID = adminCMSCacheOne.CMSResourceCategories ? getMaxId(adminCMSCacheOne.CMSResourceCategories) : 0;
  const MaxResourceID = adminCMSCacheOne.CMSResources ? getMaxId(adminCMSCacheOne.CMSResources) : 0;
  const MaxSiteMenuID = adminCMSCacheOne.CMSSiteMenuItems ? getMaxId(adminCMSCacheOne.CMSSiteMenuItems) : 0;
  const MaxContactID = adminCMSCacheOne.CMSContacts ? getMaxId(adminCMSCacheOne.CMSContacts) : 0;
  const MaxPageMenuID = adminCMSCacheOne.CMSPageMenuItems ? getMaxId(adminCMSCacheOne.CMSPageMenuItems) : 0;
  const SiteID = getSiteID(props);
  const GetOptions = !state.cacheZero.options;
  const CMSPagesFilters = adminCMSCacheOne.CMSPagesFilters && Object.keys(adminCMSCacheOne.CMSPagesFilters).length > 0 ? {
    ...adminCMSCacheOne.CMSPagesFilters,
    EventStartDate: adminCMSCacheOne.CMSPagesFilters.EventStartDate ? formatDate(adminCMSCacheOne.CMSPagesFilters.EventStartDate) : null,
  } : undefined;

  return {
    SiteID,
    GetPages: false,
    GetContacts: false,
    GetResources: false,
    GetResourceCategories: false,
    GetSiteMenus: false,
    GetPageMenus: false,
    GetOptions,
    GetCMSOptions,
    GroupIDi,
    GroupTS,
    HomepageTS,
    homepage1TS,
    SiteTS,
    MaxEventTypeID,
    MaxLocationID,
    MaxContactID,
    MaxResourceCategoryID,
    MaxResourceID,
    MaxSiteMenuID,
    MaxPageMenuID,
    CMSPagesFilters,
  };
};
export const getSiteCacheBody = (params: GetSiteCacheBodyParams) => {
  const baseParams = {...constructGetSiteCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params})};
  const customParams = constructCMSSiteCacheAppParams(params);
  return {
    AppState: {
      ...baseParams,
      ...customParams,
      CMSPagesFilters: baseParams.CMSPagesFilters && params.CMSPagesFilters ? {
        ...baseParams.CMSPagesFilters,
        ...params.CMSPagesFilters,
      } : (baseParams.CMSPagesFilters ? {...baseParams.CMSPagesFilters} : null),
    },
  };
};

// Cache Two
const GET_RESOURCE = '/CMSSites/GetCMSResource/';
export const getResource = () => `${CMS_APIURL}${GET_RESOURCE}${getQP()}`;
export interface GetResourceParams {
  ID: number;
  getSiteCache?: boolean;
  MaxResourceCategoryID?: number;
  MaxEventTypeID?: number;
  MaxLocationID?: number;
  GetResources?: boolean;
  MaxResourceID?: number;
  GroupIDi?: number;
  GroupTS?: number;
  location?: any;
  routes?: any;
  params?: any;
}
export const getResourceBody = (params: GetResourceParams) => {
  const baseParams = constructGetSiteCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params});
  const customParams = constructCMSSiteCacheAppParams(params);
  return {
    AppState: {
      ...baseParams,
      ...customParams,
      GetResourceCategories: true,
    },
    ID: params.ID,
  };
};

const GET_CONTACT = '/CMSBlogs/GetCMSContact/';
export const getContact = () => `${CMS_APIURL}${GET_CONTACT}${getQP()}`;
export interface GetContactParams {
  ID: number;
  GetContacts?: boolean;
  getSiteCache?: boolean;
  MaxContactID?: number;
  location?: any;
  routes?: any;
  params?: any;
}
export const getContactBody = (params: GetContactParams) => {
  const baseParams = constructGetSiteCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params});
  const customParams = constructCMSSiteCacheAppParams(params);
  return {
    AppState: {
      ...baseParams,
      ...customParams,
    },
    ID: params.ID,
  };
};

const GET_SITE_MENU_ITEM = '/CMSSites/GetCMSSiteMenuItem';
export const getSiteMenuItem = () => `${CMS_APIURL}${GET_SITE_MENU_ITEM}${getQP()}`;

export interface GetSiteMenuItemParams {
  ID: number;
  GetSiteMenus?: boolean;
  MaxSiteMenuID?: number;
  GetResources?: boolean;
  GetResourceCategories?: boolean;
  location?: any;
  routes?: any;
  params?: any;
}
export const getSiteMenuItemBody = (params: GetSiteMenuItemParams) => {
  const baseParams = constructGetSiteCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params});
  const customParams = constructCMSSiteCacheAppParams(params);

  return {
    AppState: {
      ...baseParams,
      ...customParams,
    },
    ID: params.ID,
  };
};

const GET_PAGE_MENU_ITEM = '/CMSSites/GetCMSPageMenuItem';
export const getPageMenuItem = () => `${CMS_APIURL}${GET_PAGE_MENU_ITEM}${getQP()}`;

export interface GetPageMenuItemParams {
  ID: number;
  GetPageMenus?: boolean;
  MaxPageMenuID?: number;
  GetResources?: boolean;
  GetResourceCategories?: boolean;
  location?: any;
  routes?: any;
  params?: any;
}
export const getPageMenuItemBody = (params: GetPageMenuItemParams) => {
  const baseParams = constructGetSiteCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params});
  const customParams = constructCMSSiteCacheAppParams(params);

  return {
    AppState: {
      ...baseParams,
      ...customParams,
    },
    ID: params.ID,
  };
};

export interface GetDefaultForPrimaryEventPageParams {
  SiteID?: number;
  EventTypeID: number;
  params: any;
  ID?: number;
}

export const CHECK_CMS_PRIMARY_EVENT_PAGE = '/CMSBlogs/CheckCMSPrimaryEventPage';
export const getDefaultForPrimaryEventPage = () => `${CMS_APIURL}${CHECK_CMS_PRIMARY_EVENT_PAGE}${getQP()}`;
export const getDefaultForPrimaryEventPageBody = (params: GetDefaultForPrimaryEventPageParams) => {
  const state = reduxStoreService().getState() as ApplicationState;
  return {
    SiteID: getSiteID(params),
    ...params,
  };
};

const GET_PAGE = '/CMSBlogs/GetCMSPage';
export const getPage = () => `${CMS_APIURL}${GET_PAGE}${getQP()}`;
export interface GetPageParams {
  ID: number;
  GetContacts?: boolean;
  MaxContactID?: number;
  GetResources?: boolean;
  MaxResourceID?: number;
  GetResourceCategories?: boolean;
  MaxResourceCategoryID?: number;
  GetSiteMenus?: boolean;
  MaxSiteMenuID?: number;
  GetPageMenus?: boolean;
  MaxPageMenuID?: number;
  GetCMSOptions?: boolean;
  location?: any;
  routes?: any;
  params?: any;
}
export const getPageBody = (params: GetPageParams) => {
  const baseParams = constructGetSiteCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params});
  const customParams = constructCMSSiteCacheAppParams(params);

  return {
    AppState: {
      ...baseParams,
      ...customParams,
      PageID: params.ID,
    },
  };
};

interface AdminCMSRouteParams {
  siteId: number;
  domain: string;
  resourceId?: number;
  resourceName?: string;
  contactId?: number;
  contactTitle?: string;
  contactName?: string;
  siteMenuItemId?: number;
  siteMenuItemName?: string;
  pageMenuItemId?: number;
  pageMenuItemParentId?: number;
  pageMenuItemName?: string;
  pageId?: number;
  pageName?: string;
}

// param construction
export const constructCMSSiteUrlParams = (props: {params: any}, cacheOne: AdminCMSSiteCacheOneState): AdminCMSRouteParams => {
  return {
    siteId: getSiteID(props),
    domain: cacheOne.CMSSite ? cacheOne.CMSSite.SiteDomain : '',
  };
};

export const constructCMSSiteEditPageUrlParams = (props: {params: any}, cacheOne: AdminCMSSiteCacheOneState, page: CMSPage): AdminCMSRouteParams => {
  return {
    ...constructCMSSiteUrlParams(props, cacheOne),
    pageId: page ? page.ID as any : '',
    pageName: page ? page.Name : '',
  };
};

export const constructCMSSiteResourceUrlParams = (props: {params: any}, cacheOne: AdminCMSSiteCacheOneState, resource: CMSResource): AdminCMSRouteParams => {
  return {
    ...constructCMSSiteUrlParams(props, cacheOne),
    resourceId: resource ? resource.ID as any : '',
    resourceName: resource ? resource.Name : '',
  };
};

export const constructCMSSiteContactUrlParams = (props: {params: any}, cacheOne: AdminCMSSiteCacheOneState, contact: CMSContact): AdminCMSRouteParams => {
  return {
    ...constructCMSSiteUrlParams(props, cacheOne),
    contactId: contact ? contact.ID as any : '',
    contactName: contact ? contact.Name : '',
    contactTitle: contact ? contact.Title : '',
  };
};

export const constructCMSSiteSiteMenuItemUrlParams = (props: {params: any}, cacheOne: AdminCMSSiteCacheOneState, siteMenuItem?: CMSSiteMenuItem): AdminCMSRouteParams => {
  return {
    ...constructCMSSiteUrlParams(props, cacheOne),
    siteMenuItemId: siteMenuItem ? siteMenuItem.ID as any : '',
    siteMenuItemName: siteMenuItem ? siteMenuItem.Name : '',
  };
};

export const constructCMSSitePageMenuItemUrlParams = (props: {params: any}, cacheOne: AdminCMSSiteCacheOneState, pageMenuItem?: CMSPageMenuItem): AdminCMSRouteParams => {
  return {
    ...constructCMSSiteUrlParams(props, cacheOne),
    pageMenuItemParentId: pageMenuItem ? (pageMenuItem.ParentID || pageMenuItem.ID) : 0,
    pageMenuItemId: pageMenuItem ? pageMenuItem.ID as any : '',
    pageMenuItemName: pageMenuItem ? pageMenuItem.Name : '',
  };
};

// CMS Pages
export const getPagesRootUrl = (params: AdminCMSRouteParams) => {
  return `${URLS.ADMIN_SITES}/${params.siteId}/${params.domain}/${CMS_PATHS.PAGES}${getAccountQP()}`;
};
export const getEditPageRootUrl = (params: AdminCMSRouteParams) => {
  // NOTE: It is important that when `pageName` is empty, dont attach a tailing `/`,
  // because that will fail the route matching
  const nameParam = params.pageName ? `/${spaceTo_(params.pageName as string)}` : '';
  return `${URLS.ADMIN_PAGES}/${params.siteId}/${params.pageId}${nameParam}${getAccountQP()}`;
};

// CMS Contacts
export const getContactsRootUrl = (params: AdminCMSRouteParams) => {
  return `${URLS.ADMIN_SITES}/${params.siteId}/${params.domain}/${CMS_PATHS.CONTACTS}${getAccountQP()}`;
};

export const getAddContactUrl = (params: AdminCMSRouteParams) => {
  return `${URLS.ADMIN_SITES}/${params.siteId}/${params.domain}/${CMS_PATHS.CONTACTS}/${CMS_CONTACTS.NEW}${getAccountQP()}`;
};

const UPDATE_CONTACT = '/CMSContacts/UpdateCMSContact';
export const getUpdateContactUrl = () => `${CMS_APIURL}${UPDATE_CONTACT}${getQP()}`;

interface SubmitContactParams {
  contactId?: number;
  contactTS?: number;
  Socials?: CMSSocial[];
}
export const getSubmitContactBody = (params: SubmitContactParams, form: ContactActiveForm) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
    },
    row: {
      ID: params.contactId,
      SiteID: form.SiteID,
      FirstName: form.FirstName,
      LastName: form.LastName,
      MI: form.MI,
      Suffix: form.Suffix,
      Title: form.Title,
      Company: form.Company,
      CategoryID: form.CategoryID,
      Phone: form.Phone,
      PhoneExt: form.PhoneExt,
      MobilePhone: form.MobilePhone,
      Fax: form.Fax,
      Email: form.Email,
      // remove image when FeatureImage is not returned from server, and its not set by client
      RemoveImage: !form.FeaturedImage || (!form.FeaturedImage.inputObj && !form.FeaturedImage.ID),
    },
    Socials: params.Socials,
  };
  if (params.contactTS) body.row.TS = params.contactTS;
  return body;
};

interface DeleteContactParams {
  contactId: number;
}

export const getDeleteContactBody = (params: DeleteContactParams, inactive: boolean) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
    },
    row: {
      ID: params.contactId,
      Inactive: inactive,
    },
  };

  return body;
};

export const getEditContactUrl = (params: AdminCMSRouteParams) => {
  const nameParam = (params.contactName || params.contactTitle) ? `/${spaceTo_((params.contactName || params.contactTitle) as string)}/` : '/';
  return `${URLS.ADMIN_CONTACTS}/${params.siteId}/${params.contactId}${nameParam}${CMS_CONTACTS.EDIT}${getAccountQP()}`;
};

export const getContactPreviewUrl = (params: AdminCMSRouteParams) => {
  const nameParam = params.contactName || params.contactTitle ? `/${spaceTo_((params.contactName || params.contactTitle) as string)}` : '';
  return `${URLS.ADMIN_CONTACTS}/${params.siteId}/${params.contactId}${nameParam}${getAccountQP()}`;
};

// CMS Resources
export const getResourcesRootUrl = (params: AdminCMSRouteParams) => {
  return `${URLS.ADMIN_SITES}/${params.siteId}/${params.domain}/${CMS_PATHS.RESOURCES}${getAccountQP()}`;
};
export const getResourcesCategoriesRootUrl = (params: AdminCMSRouteParams) => {
  return `${URLS.ADMIN_SITES}/${params.siteId}/${params.domain}/${CMS_PATHS.RESOURCES_CATEGORIES}${getAccountQP()}`;
};

export const getAddResourceUrl = (params: AdminCMSRouteParams) => {
  return `${URLS.ADMIN_SITES}/${params.siteId}/${params.domain}/${CMS_PATHS.RESOURCES}/${CMS_RESOURCES.NEW}${getAccountQP()}`;
};

const UPDATE_RESOURCE_CATEGORY = '/CMSResourceCategories/UpdateCMSResourceCategory';
export const getUpdateResourceCategoryUrl = () => `${CMS_APIURL}${UPDATE_RESOURCE_CATEGORY}${getQP()}`;

interface SubmitResourceCategoryParams {
  resourceCategoryId?: number;
  resourceCategoryTS?: number;
}

export const getSubmitResourceCategoryBody = (params: SubmitResourceCategoryParams, form: ResourceCategoryActiveForm) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
    },
    row: {
      ID: params.resourceCategoryId,
      Name: form.Name,
    }
  };
  if (params.resourceCategoryTS) body.row.TS = params.resourceCategoryTS;

  return body;
};

interface DeleteResourceCategoryParams {
  resourceCategoryId: number;
}

export const getDeleteResourceCategoryBody = (params: DeleteResourceCategoryParams, inactive: boolean) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
    },
    row: {
      ID: params.resourceCategoryId,
      Inactive: inactive,
    },
  };

  return body;
};

const UPDATE_RESOURCE = '/CMSResources/UpdateCMSResource';
export const getUpdateResourceUrl = () => `${CMS_APIURL}${UPDATE_RESOURCE}${getQP()}`;

interface SubmitResourceParams {
  resourceId?: number;
  resourceTS?: number
}
export const getSubmitResourceBody = (params: SubmitResourceParams, form: ResourceActiveForm) => {
  captureEmptyFieldInForm(form, "Description", "SaveResource");
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
    },
    row: {
      ID: params.resourceId,
      CategoryID: form.CategoryID,
      Name: form.Name,
      Description: form.Description,
      SiteID: form.SiteID,
    },
  };
  if (params.resourceTS) body.row.TS = params.resourceTS;
  return body;
};

interface DeleteResourceParams {
  resourceId: number;
}

export const getDeleteResourceBody = (params: DeleteResourceParams, inactive: boolean) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
    },
    row: {
      ID: params.resourceId,
      Inactive: inactive,
    },
  };

  return body;
};

export const getEditResourceUrl = (params: AdminCMSRouteParams) => {
  const nameParam = params.resourceName ? `/${spaceTo_(params.resourceName as string)}/` : '/';
  return `${URLS.ADMIN_RESOURCES}/${params.siteId}/${params.resourceId}${nameParam}${CMS_RESOURCES.EDIT}${getAccountQP()}`;
};

export const getResourcePreviewUrl = (params: AdminCMSRouteParams) => {
  const nameParam = params.resourceName ? `/${spaceTo_(params.resourceName as string)}` : '';
  return `${URLS.ADMIN_RESOURCES}/${params.siteId}/${params.resourceId}${nameParam}${getAccountQP()}`;
};

// CMS Settings
export const getSettingsRootUrl = (params: AdminCMSRouteParams) => {
  return `${URLS.ADMIN_SITES}/${params.siteId}/${params.domain}/${CMS_PATHS.SETTINGS_GENERAL}${getAccountQP()}`;
};

export const getSettingsMessagesUrl = (params: AdminCMSRouteParams) => {
  return `${URLS.ADMIN_SITES}/${params.siteId}/${params.domain}/${CMS_PATHS.SETTINGS_MESSAGES}${getAccountQP()}`;
};

// CMS Menus
const UPDATE_SITE_MENU = '/CMSMenus/UpdateCMSSiteMenuItem/?ParseJSON=0';
export const getUpdateSiteMenuItemUrl = () => `${CMS_APIURL}${UPDATE_SITE_MENU}${getQP(true)}`;

interface SubmitSiteMenuItemParams {
}

export const getSubmitSiteMenuBody = (params: SubmitSiteMenuItemParams, form: SiteMenuItemActiveForm) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
    },
    row: {
      ID: form.ID,
      ParentID: form.ParentID,
      LinkTypeID: form.LinkTypeID,
      IsLinkNewWindow: form.IsLinkNewWindow,
      PageID: form.PageID,
      EventCategoryID: form.EventCategoryID,
      DistrictIDi: form.DistrictIDi,
      ResourceID: form.ResourceID,
      ExternalURL: form.ExternalURL,
      Name: form.Name,
      MenuStyle: form.MenuStyle,
      IsAlignRight: form.IsAlignRight,
      IsPrimaryMenu: form.IsPrimaryMenu,
      TS: form.TS,
    }
  };

  return body;
};

interface DeleteMenuItemParams {
  menuItemId: number;
}

export const getDeleteMenuItemBody = (params: DeleteMenuItemParams, inactive: boolean) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
    },
    row: {
      ID: params.menuItemId,
      Inactive: inactive,
    },
  };

  return body;
};

const UPDATE_SITE_MENU_ORDER = '/CMSMenus/UpdateCMSSiteMenuItems_Order/?ParseJSON=0';
export const getUpdateSiteMenuOrderUrl = () => `${CMS_APIURL}${UPDATE_SITE_MENU_ORDER}${getQP(true)}`;

export const getSubmitSiteMenuOrderBody = (items: CMSSiteMenuItem[]) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
    },
    Items: items.map((item, index) => {
      return {
        ID: item.ID,
        Ord: index,
      };
    }),
  };

  return body;
};

const UPDATE_PAGE_MENU_ORDER = '/CMSMenus/UpdateCMSPageMenuItems_Order/?ParseJSON=0';
export const getUpdatePageMenuOrderUrl = () => `${CMS_APIURL}${UPDATE_PAGE_MENU_ORDER}${getQP(true)}`;

export const getSubmitPageMenuOrderBody = (items: CMSPageMenuItem[]) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
    },
    Items: items.map((item, index) => {
      return {
        ID: item.ID,
        Ord: index,
      };
    }),
  };

  return body;
};

const UPDATE_PAGE_MENU = '/CMSMenus/UpdateCMSPageMenuItem/?ParseJSON=0';
export const getUpdatePageMenuItemUrl = () => `${CMS_APIURL}${UPDATE_PAGE_MENU}${getQP(true)}`;

interface SubmitPageMenuItemParams {
  pageMenuId?: number;
  pageMenuTS?: number;
}

export const getSubmitPageMenuBody = (params: SubmitPageMenuItemParams, form: SiteMenuItemActiveForm) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
    },
    row: {
      ID: params.pageMenuId,
      LinkTypeID: form.LinkTypeID,
      IsLinkNewWindow: form.IsLinkNewWindow,
      PageID: form.PageID,
      EventCategoryID: form.EventCategoryID,
      DistrictIDi: form.DistrictIDi,
      ResourceID: form.ResourceID,
      ParentID: form.ParentID,
      ExternalURL: form.ExternalURL,
      Name: form.Name,
      MenuStyle: form.MenuStyle,
      IsAlignRight: form.IsAlignRight,
      IsPrimaryMenu: form.IsPrimaryMenu,
      Ord: form.Ord,
      TS: params.pageMenuTS,
    }
  };

  return body;
};

// CMS Page
const UPDATE_SITE_MENU_ITEM_EDIT_PAGE = '/CMSMenus/UpdateCMSPage_SiteMenuItem/?ParseJSON=0';
export const getEditPageUpdateSiteMenuItemUrl = () => `${CMS_APIURL}${UPDATE_SITE_MENU_ITEM_EDIT_PAGE}${getQP(true)}`;

interface EditPageUpdateSiteMenuItemParams {
  pageId?: number;
  inactive?: boolean
}

export const getSubmitEditPageSiteMenuItemBody = (params: EditPageUpdateSiteMenuItemParams, item: CMSSiteMenuItem) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
      PageID: params.pageId,
    },
    row: {
      ID: item.ID,
      Name: item.Name,
      ParentID: item.ParentID,
      LinkTypeID: 1,
      IsPrimaryMenu: false,
      IsLinkNewWindow: item.IsLinkNewWindow,
      MenuStyle: item.MenuStyle,
      IsAlignRight: item.IsAlignRight,
      TS: item.TS,
    },
  };

  return body;
};

export const getDeleteEditPageSiteMenuItemBody = (params: EditPageUpdateSiteMenuItemParams, item: CMSSiteMenuItem) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
      PageID: params.pageId,
    },
    row: {
      ID: item.ID,
      Inactive: params.inactive,
    },
  };

  return body;
};

export const EDIT_GALLERY_IMAGE_EDIT_PAGE = '/CMSBlogPhotos/UpdateCMSPageImage_Edit/?ParseJSON=0';
export const getEditPageEditGalleryImageUrl = () => `${CMS_APIURL}${EDIT_GALLERY_IMAGE_EDIT_PAGE}${getQP(true)}`;

interface EditPageEditGalleryImageParams {
  PageID: number;
}
export const getEditPageEditGalleryImageBody = (params: EditPageEditGalleryImageParams, form: ImageActiveForm, ckeditorData?: string) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
      PageID: params.PageID,
    },
    row: {
      ID: form.ID,
      Name: form.Name,
      Description: ckeditorData ? ckeditorData : form.Description,
      PublishDate: formatDate(form.PublishDate),
      ExpireDate: formatDate(form.ExpireDate),
      LinkURL: form.LinkURL,
      IsLinkNewWindow: form.IsLinkNewWindow,
      TS: form.TS,
    },
  };

  return body;
};
interface EditPageDeleteGalleryImageParams {
  PageID: number;
  ID: number;
}
export const getEditPageDeleteGalleryImageBody = (params: EditPageDeleteGalleryImageParams, Inactive: boolean) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
      PageID: params.PageID,
    },
    row: {
      ID: params.ID,
      Inactive,
    },
  };

  return body;
};

export const ADD_BOTTOM_GALLERY_IMAGE_EDIT_PAGE = '/CMSBlogPhotos/UpdateCMSPageImage_Add_Bottom/?ParseJSON=0';
export const getEditPageAddBottomGalleryImageUrl = () => `${CMS_APIURL}${ADD_BOTTOM_GALLERY_IMAGE_EDIT_PAGE}${getQP(true)}`;

export const ADD_TOP_GALLERY_IMAGE_EDIT_PAGE = '/CMSBlogPhotos/UpdateCMSPageImage_Add_Top/?ParseJSON=0';
export const getEditPageAddTopGalleryImageUrl = () => `${CMS_APIURL}${ADD_TOP_GALLERY_IMAGE_EDIT_PAGE}${getQP(true)}`;

interface EditPageAddGalleryImageParams {
  PageID: number,
}
export const getEditPageAddGalleryImageBody = (params: EditPageAddGalleryImageParams) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
      PageID: params.PageID,
    }
  };

  return body;
};

export const DUPLICATE_CMS_PAGE = '/CMSBlogs/DuplicateCMSPage/?ParseJSON=0';
export const getDuplicateCMSPageUrl = () => `${CMS_APIURL}${DUPLICATE_CMS_PAGE}${getQP(true)}`;

export const getDuplicatePageBody = (PageID: number, form: DuplicatePageActiveForm) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
      PageID,
    },
    row: {
      Name: form.Name,
      StartDate: formatDate(form.StartDate),
    },
  };

  return body;
};

export const UPDATE_CMS_PAGE = '/CMSBlogs/UpdateCMSPage/?ParseJSON=0';
export const getUpdateCMSPageUrl = () => `${CMS_APIURL}${UPDATE_CMS_PAGE}${getQP(true)}`;

interface AddPageParams {
}
export const getAddPageBody = (params: AddPageParams, form: NewPageModalActiveForm) => {
  const startDate = formatDate(form.StartDate);
  const endDate = formatDate(form.EndDate);
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
    },
    row: {
      PageTypeID: form.PageTypeID,
      Name: form.Name,
      ShortURL: form.ShortURL,
      EventCategoryID: form.EventCategoryID,
      IsAllDay: form.IsAllDay,
      StartDate: startDate ? `${startDate}T${convertTimeDigit(form.StartHour, form.StartPeriod)}:${convertTimeDigit(form.StartMin)}:00` : null,
      EndDate: endDate ? `${endDate}T${convertTimeDigit(form.EndHour, form.EndPeriod)}:${convertTimeDigit(form.EndMin)}:00` : null,
      LocationID: form.LocationID,
      DistrictIDi: form.DistrictIDi,
      EventTypeID: form.EventTypeID,
      IsPrimaryEventPage: form.IsPrimaryEventPage,
      ShowRegistrationLinks: form.ShowRegistrationLinks,
      IncludeModuleContacts: form.IncludeModuleContacts,
    }
  };

  return body;
};

interface UpdatePageParams {
  PageID?: number,
}
export const getUpdatePageBody = (params: UpdatePageParams, form: PageActiveForm, ckeditorData?: string) => {
  const startDate = formatDate(form.StartDate);
  const endDate = formatDate(form.EndDate);
  const startDateTime = constructDateTime(startDate, form.StartHour, form.StartMin, form.StartPeriod);
  const endDateTime = constructDateTime(endDate, form.EndHour, form.EndMin, form.EndPeriod);
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
      PageID: params.PageID,
    },
    row: {
      PageTypeID: form.PageTypeID,
      Name: form.Name,
      ShortURL: form.ShortURL,
      EventCategoryID: form.EventCategoryID,
      IsAllDay: form.IsAllDay,
      StartDate: form.PageTypeID === PageTypeIDValue.CALENDAR_EVENT ? startDateTime : startDate,
      EndDate: form.PageTypeID === PageTypeIDValue.CALENDAR_EVENT ? endDateTime : endDate,
      LocationID: form.LocationID,
      DistrictIDi: form.DistrictIDi,
      EventTypeID: form.EventTypeID,
      IsPrimaryEventPage: form.IsPrimaryEventPage,
      ShowRegistrationLinks: form.ShowRegistrationLinks,
      IncludeModuleContacts: form.IncludeModuleContacts,
      
      SiteID: form.SiteID,
      IsFeatured: form.IsFeatured,
      PageContent: ckeditorData ? ckeditorData : form.PageContent,
      Photos1LayoutID: form.Photos1LayoutID,
      Photos2LayoutID: form.Photos2LayoutID,
      ContactsLayoutID: form.ContactsLayoutID,
      SEOTitle: form.SEOTitle,
      SEODescription: form.SEODescription,
      SEOKeywords: form.SEOKeywords,
      FormBuilderHTML: form.FormBuilderHTML,
      MapHTML: form.MapHTML,
      Sidebar1Title: form.Sidebar1Title,
      Sidebar1Content: form.Sidebar1Content,
      TS: form.TS,
    },
    Contacts: form.CMSPageContacts || [],
    Resources: form.CMSPageResources || [],
  };

  return body;
};

interface DeleteRestorePageParams {
  PageID: number;
}
export const getDeleteRestorePageBody = (params: DeleteRestorePageParams, Inactive: boolean) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
      PageID: params.PageID,
    },
    row: {
      Inactive,
    }
  };

  return body;
};

export const UPDATE_LOCATION = '/UpdateLocation/?ParseJSON=0';
export const getUpdateLocationUrl = () => `${CMS_SYSTEM_ADMIN_APIURL}${UPDATE_LOCATION}${getQP(true)}`;

interface UpdateLocationParams {

}
export const getUpdateLocationBody = (params: UpdateLocationParams, form: LocationActiveForm) => {
  return {
    row: {
      ID: form.ID,
      Name: form.Name,
      Address: form.Address,
      City: form.City,
      StateID: form.StateID,
      Zip: form.Zip,
      PhoneNumber: form.PhoneNumber,
      FaxNumber: form.FaxNumber,
      TS: form.TS,
    },
  };
};

// settings
export const UPDATE_SITE_SETTINGS_GENERAL = '/CMSSites/UpdateCMSSite_General/?ParseJSON=0';
export const getUpdateSiteSettingsGeneralUrl = () => `${CMS_APIURL}${UPDATE_SITE_SETTINGS_GENERAL}${getQP(true)}`;

export const getUpdateSiteSettingsGeneralBody = (form: GeneralSettingsActiveForm) => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
    },
    row: {
      ContactName: form.ContactName,
      Phone: form.Phone,
      Fax: form.Fax,
      Email: form.Email,
      Address: form.Address,
      Address2: form.Address2,
      City: form.City,
      StateID: form.StateID,
      Zip: form.Zip,
      DonateURL: form.DonateURL,
      DonateName: form.DonateName,
      IsDonateNewWindow: form.IsDonateNewWindow,
      JoinURL: form.JoinURL,
      JoinName: form.JoinName,
      IsJoinNewWindow: form.IsJoinNewWindow,
      IncludeAllCategoriesInCalendar: form.IncludeAllCategoriesInCalendar,
      CalendarPrimaryCategoryID: form.CalendarPrimaryCategoryID,
      IncludeDistrictsInPrimaryCalendar: form.IncludeDistrictsInPrimaryCalendar,
      ShowCalendarOnHomepage: form.ShowCalendarOnHomepage,
      FeaturedCalendarCodeHTML: form.FeaturedCalendarCodeHTML,
      SEOTitle: form.SEOTitle,
      SEODescription: form.SEODescription,
      SEOKeywords: form.SEOKeywords,
      GoogleAnalytics: form.GoogleAnalytics,
      MapInFooterHTML: form.MapInFooterHTML,
      SocialWidgetHTML: form.SocialWidgetHTML,

    }
  };

  return body;
};

export const UPDATE_SITE_SETTINGS_MESSAGES = '/CMSSites/UpdateCMSSite_Messages/?ParseJSON=0';
export const getUpdateSiteSettingsMessagesUrl = () => `${CMS_APIURL}${UPDATE_SITE_SETTINGS_MESSAGES}${getQP(true)}`;

export const getUpdateSiteSettingsMessagesBody = () => {
  const body: any = {
    AppState: {
      ...constructUpdateSiteCacheBodyBaseParams(),
    },
  };

  return body;
};

export const getSiteMenusRootUrl = (params: AdminCMSRouteParams) => {
  return `${URLS.ADMIN_SITES}/${params.siteId}/${params.domain}/${CMS_PATHS.SITE_MENU}${getAccountQP()}`;
};

export const getEditSiteMenuItemRootUrl = (params: AdminCMSRouteParams) => {
  const nameParam = params.siteMenuItemName ? `/${spaceTo_(params.siteMenuItemName as string)}` : '';
  return `${URLS.ADMIN_SITE_MENU}/${params.siteId}/${params.siteMenuItemId}${nameParam}${getAccountQP()}`;
};

export const getNewSiteMenuItemRootUrl = (params: AdminCMSRouteParams) => {
  return `${URLS.ADMIN_SITES}/${params.siteId}/${params.domain}/${CMS_PATHS.SITE_MENU}/${CMS_SITE_MENUS.NEW}${getAccountQP()}`;
};

export const getPageMenusRootUrl = (params: AdminCMSRouteParams) => {
  return `${URLS.ADMIN_SITES}/${params.siteId}/${params.domain}/${CMS_PATHS.PAGE_MENUS}${getAccountQP()}`;
};

export const getEditPageMenuItemRootUrl = (params: AdminCMSRouteParams) => {
  const nameParam = params.pageMenuItemName ? `/${spaceTo_(params.pageMenuItemName as string)}` : '';
  return `${URLS.ADMIN_PAGE_MENU}/${params.siteId}/${params.pageMenuItemParentId}/${params.pageMenuItemId}${nameParam}${getAccountQP()}`;
};

export const getNewPageMenuItemRootUrl = (params: AdminCMSRouteParams) => {
  return `${URLS.ADMIN_SITES}/${params.siteId}/${params.domain}/${CMS_PATHS.PAGE_MENUS}/${params.pageMenuItemParentId}/${CMS_PAGE_MENUS.NEW}${getAccountQP()}`;
};

const addFormParamToUrl = (url: string, paramKey: string, paramValue: string, isFirstParam?: boolean) => {
  let result = url;

  result += `${isFirstParam ? "?" : "&"}${paramKey}=${encodeURIComponent(paramValue)}`;

  return result;
};

export const openSupportForm = (returnLink?: boolean) => {
  const state = reduxStoreService().getState() as ApplicationState;
  const cacheZero = state.cacheZero;
  const user = state.user;
  
  let url = "/SupportDesk";

  url = addFormParamToUrl(url, "element_1", isAdmin() ? "1" : "2", true);

  // NOTE: If unauthenticated, skip the rest of fields
  if (user.user && user.user.IDi !== -1) {
    url = addFormParamToUrl(url, "element_2_1", user.user.FirstName);
    url = addFormParamToUrl(url, "element_2_2", user.user.LastName);
    url = addFormParamToUrl(url, "element_3", user.user.Email);
    if (!isAdmin() && cacheZero.options?.Group?.GroupTypeID === 1) {
      const unitTypeID = cacheZero.options.Group.UnitTypeID;
      const unitType = cacheZero.options.UnitTypes?.find(u => u.ID === unitTypeID);

      if (cacheZero.options.Group.Unit) {
        url = addFormParamToUrl(url, "element_14", cacheZero.options.Group.Unit);
      }
      if (unitType) {
        url = addFormParamToUrl(url, "element_15", `${unitType.SupportDeskUnitTypeID}`);
      }

      const CouncilIDi = cacheZero.options.Group.CouncilIDi;
      const Council = cacheZero.options.Councils ? cacheZero.options.Councils?.find((c) => c.IDi === CouncilIDi) : null;
      if (Council) {
        url = addFormParamToUrl(url, "element_19", Council.Name);
      }

      const DistrictIDi = cacheZero.options.Group.DistrictIDi;
      const District = cacheZero.options.Districts ? cacheZero.options.Districts?.find((d) => d.IDi === DistrictIDi && d.IDi !== 1) : null;
      if (District) {
        url = addFormParamToUrl(url, "element_20", District.Name);
      }
    }
  }

  const finalUrl = process.env.NODE_ENV === 'development' ? `https://www.greatskycountry.com${url}` : url;

  if (returnLink) return finalUrl;
  window.open(finalUrl);
};

export const openPricingTierForm = () => {
  const state = reduxStoreService().getState() as ApplicationState;
  const user = state.user;

  let url = "https://admin.tentaroo.com/pricing-tiers";
  
  url = addFormParamToUrl(url, "element_1_1", user.user.FirstName, true);
  url = addFormParamToUrl(url, "element_1_2", user.user.LastName);
  url = addFormParamToUrl(url, "element_2", user.user.Email);
  if (state.session.SystemSettings) {
    url = addFormParamToUrl(url, "element_3", state.session.SystemSettings.CampName);
  }

  return url;
};