
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetAdminEventClassType, GetAdminEventClassTypeAllRequirements} from "./actions";
import { getAdminEventClassTypeCache, getAdminEventClassTypeBody, getAdminEventClassTypeAllRequirements } from "../../../constants/adminEventsUrls";

export const adminEventsCacheThreeClassTypeEpic = makeRequestEpic(
  GetAdminEventClassType,
  getAdminEventClassTypeCache,
  getAdminEventClassTypeBody,
  true,
);

export const adminEventsClassTypeAllRequirementsEpic = makeRequestEpic(
  GetAdminEventClassTypeAllRequirements,
  getAdminEventClassTypeAllRequirements,
);