
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetBlackoutDate} from "./actions";
import { getBlackoutDateBody, getBlackoutDate } from "../../../constants/urls";

export const adminFacilityLocationCacheTwoBlackoutDatesEpic = makeRequestEpic(
  GetBlackoutDate,
  getBlackoutDate,
  getBlackoutDateBody,
  true,
);