import * as Actions from './actions';
import { Reducer } from 'redux';
import { isActionType } from '../../../../../../utils/StrongActions';

export interface EventRegisterNumbersState {
  selectedPage: 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'campsite_assignment' | 'confirmation';
  pendingPage?: 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'campsite_assignment' | 'confirmation';
}
const getInitialState: () => EventRegisterNumbersState = () => ({selectedPage: 'spots'});

const EventRegisterNumbers: Reducer<EventRegisterNumbersState> = (state: EventRegisterNumbersState, action: any) => {
  if (isActionType(action, Actions.EventRegisterNumbersSelectPage)) {
    window.scrollTo(0,0);
    return {...state, selectedPage: action.selectedPage};
  } else if (isActionType(action, Actions.EventRegisterNumbersSetPendingPage)) {
    return {...state, pendingPage: action.pendingPage};
  } else if (isActionType(action, Actions.EventRegisterNumbersTransitionToPending)) {
    if (state.pendingPage) {
      window.scrollTo(0,0);
      return {...state, selectedPage: state.pendingPage, pendingPage: undefined};
    }
    return state;
  } else if (isActionType(action, Actions.EventRegisterNumbersReset)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default EventRegisterNumbers;
