import * as React from 'react';
import { GroupIcon } from '../../../Icons';
import Arrow from '../../../../images/app/header/account/arrow.svg';
import { namespace as ns } from '../constants';
import '../../../../styles/app/header/account/index.scss';
import { Button } from 'react-mdl';
import { isMobile } from '../../../../utils/isMobile';
import { generateDOMId } from '../../../../utils/cypressHelper';
import { Ellipsis } from '../../../Elements';

export const namespace = (): string => `${ns()}--account`;

interface AccountProps {
  // This flag means the `Select Client` button in Header will stay visible "longer" when you reduce
  // the window width. And it is only set for `ADD_GROUP` page, because there is no `Tabs` in this page.
  // When this button is not visible in Header, it will be visible in the NavBar popped up by the hamburger button,
  // and that is done in `ClientApp/styles/app/nav/index.scss` - style under `&.add-group` is corresponding
  // to this `showSelectClientLonger` flag
  showSelectClientLonger?: boolean;
  selectedClient?: string;
  onClick?: React.EventHandler<any>;
}

// @todo-minor-performance: this should maybe have shouldComponentUpdate to prevent re-rendering Truncate, which is seemingly somewhat expensive

const Account: React.SFC<AccountProps> = ({ onClick, selectedClient, showSelectClientLonger, ...props }): React.ReactElement<AccountProps> => {
  return (
    <Button id={generateDOMId("select-account-btn")} ripple={!isMobile} className={`${namespace()} ${showSelectClientLonger ? 'longer' : 'normal'} ${isMobile ? 'mobile' : ''}`} onClick={onClick}>
      <GroupIcon className={`${namespace()}--icon`}/>
      <span className={`${namespace()}--text ${selectedClient ? 'selected' : ''}`}>
        <Ellipsis
          configs={{
            lines: 2,
            text: selectedClient ? selectedClient : 'Select client',
          }}
          isStatic
        />
      </span>
      <Arrow className={`${namespace()}--arrow`}/>
    </Button>
  );
};

export default Account;
