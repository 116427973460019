import { GetPageMenuItem } from "../../store/AdminCMSSite/CacheTwoPageMenus/actions";
import { ToggleNotFound } from "../../store/App/actions";
import { SaveState } from "../../store/Rollback/actions";
import { extractRouteParams, isIdFromUrlValid, isIdValid } from "../urlHelper";
import { ModalTypes, isModalOpened } from "../modalHelper";
import { AddPageMenuItemSubmitActions } from "../../store/AdminCMSSite/Menus/SiteMenuItem/Form/actions";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import { reduxStoreService } from "../../store/service";
import type AdminCMSCacheManager from "../cacheManagers/adminCMSCacheManager";
import { LoadAdminCMSCacheTwoPageMenuItemParams } from "./helpers/models";
import {isAdminCMSCacheTwoPageMenuItemPopulated} from "../cachePopulatedCheckers/adminCMS";

export const ensureCacheTwoPageMenuItem = (
  params: LoadAdminCMSCacheTwoPageMenuItemParams & {
    cacheManager: AdminCMSCacheManager,
    pageMenuId: number,
  }
): boolean => {
  const {props, isEdit, pageMenuId, cacheManager, isStateNavigated} = params;
  
  if (
    !isIdFromUrlValid(props.params.siteId) ||
    (
      !isModalOpened(ModalTypes.PAGE_MENU_ITEM_FORM) &&
      !isIdFromUrlValid(props.params.parentItemId)
    )
  ) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }
    
  const rootState = reduxStoreService().getState();
  const adminCMSCacheTwoPageMenuItems = rootState.adminCMSSite.cacheTwoPageMenuItems;

  let requestedData = false;
  if (isEdit) {
    if (!isIdValid(pageMenuId)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }

    if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminCMSCacheTwoPageMenuItemPopulated(adminCMSCacheTwoPageMenuItems)) {
      if (isStateNavigated) {
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      const params = {
        ID: pageMenuId,
        GetPageMenus: true,
        GetResourceCategories: true,
        GetResources: true,
        ...extractRouteParams(props),
      };
      reduxStoreService().dispatch(GetPageMenuItem.request(params));

      requestedData = true;
    }
  }
  else if (isStateNavigated) {
    if (!isModalOpened(ModalTypes.PAGE_MENU_ITEM_FORM)) {
      const ParentID = Number(props.params.parentItemId);

      requestedData = cacheManager.loadAdminCMSCacheOne({
        props,
        isStateNavigated,
      });

      if (!requestedData) {
        reduxStoreService().dispatch(AddPageMenuItemSubmitActions.updateForm({
          inMatchingCacheLevelOn409: undefined,
          leavingCacheLevelOnSuccess: undefined,
          ParentID,
        }));
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      reduxStoreService().dispatch(GetPageMenuItem.updateForm());
      reduxStoreService().dispatch(new SaveState());
    }
  }

  return requestedData;
};