import * as React from 'react';
import { Select, Switch, ActionButton, Row, Column } from '../../../../../../Elements';
import { Filter, FilterRow, FilterColumn, FilterSearch } from '../../../../../../Layouts';
import { FilterIcon } from '../../../../../../Icons';
import { Actions } from '../../../../../../../store/AdminEvents/Events/Event/Classes/actions';

export const namespace = (): string => 'elements--class-filters';

export interface ClassesFiltersProps {
  className?: string;
  disabled?: boolean;
  expand?: boolean;
  ActiveForm: any;
  ValidationRules: any;
  reduxActions: Actions;
  importButton: React.ReactNode;
  newButton: React.ReactNode;
  preventActions?: boolean;
  forceMobile?: boolean;
  isEmptyList?: boolean;
}

interface State {
  showSearch: boolean;
}

class ClassesFilters extends React.Component<ClassesFiltersProps, State> {
  state = {
    showSearch: false,
  };
  public static defaultProps = {

  };
  public props: ClassesFiltersProps;

  onExpandClick = () => {
    this.props.reduxActions.toggleExpandFilter(!this.props.expand);
  };

  onToggleSearch = (searching: boolean) => {
    this.setState({
      showSearch: searching,
    });
  };

  public render() {
    const props = this.props;
    const {ActiveForm, forceMobile, isEmptyList, importButton, ValidationRules, reduxActions, disabled, expand, newButton, preventActions} = props;

    return (
      <Filter className={namespace()}>
        <FilterRow style={{height: '68px'}} mobileMarginBottom={expand ? 8 : 0} forceMobile={forceMobile}>
          <Column span={6} mobileSpan={12} className={`${namespace()}--filter-search-col`}>
            <FilterSearch
              marginRight={0}
              mobileMarginRight={8}
              disabled={disabled}
              ActiveForm={ActiveForm}
              searchKey='FilterText'
              ValidationRules={ValidationRules}
              reduxActions={reduxActions}
              forceMobile={forceMobile}
              width='half'
              onToggleSearch={this.onToggleSearch}
            />
          </Column>
          <Column span={6} className={`${!this.state.showSearch ? 'has-import' : ''}`}>
            <Row styles={{justifyContent: 'flex-end', flexWrap: 'nowrap'}}>
              <FilterColumn
                marginRight={24}
                mobileMarginRight={0}
                marginBottom={21}
                forceMobile={forceMobile}
                width='none'
                alignSelf='flex-end'
                keep
              >
                <ActionButton
                  lightSelected
                  square
                  onClick={() => this.onExpandClick()}
                  icon={FilterIcon}
                  selected={expand} />
              </FilterColumn>
              <FilterColumn alignSelf='flex-end' marginBottom={21} forceMobile={forceMobile} hideInMobile={this.state.showSearch} width='none'>
                {importButton}
              </FilterColumn>
              <FilterColumn alignSelf='flex-end' marginBottom={21} forceMobile={forceMobile} hideInMobile width='none'>
                {newButton}
              </FilterColumn>
            </Row>
          </Column>
        </FilterRow>
        {expand ? <FilterRow className={`${namespace()}--filter-select-row`} marginBottom={8} isExpanded forceMobile={forceMobile}>
          <FilterColumn
            className={`${namespace()}--filter-select-col`}
            forceMobile={forceMobile}
            marginRight={24}
            width='half'
          >
            <Select
              label='Kind of Class'
              onChangeValue={reduxActions.updateValue}
              value={ActiveForm.KindOfClass}
              validationRules={ValidationRules.KindOfClass}
              isNumber
            />
          </FilterColumn>
          <FilterColumn
            className={`${namespace()}--filter-select-col`}
            mobileMarginRight={16}
            forceMobile={forceMobile}
            marginRight={24}
            width='half'
          >
            <Select
              label='Register Under'
              onChangeValue={reduxActions.updateValue}
              value={ActiveForm.RegisterUnder}
              validationRules={ValidationRules.RegisterUnder}
              isNumber
            />
          </FilterColumn>
        </FilterRow> : null}
        {expand ? <FilterRow marginBottom={isEmptyList ? 0 : undefined} isExpanded forceMobile={forceMobile}>
          <FilterColumn
            className={`${namespace()}--filter--show-deleted`}
            mobileMarginRight={16}
            forceMobile={forceMobile}
            marginRight={24}
            width='half'
          >
            <Switch
              label="Show Deleted"
              newDesign
              onChange={reduxActions.updateValue}
              value={!!ActiveForm.ShowDeleted}
              validationRules={ValidationRules.ShowDeleted}
            />
          </FilterColumn>
        </FilterRow> : null}
      </Filter>
    );
  }
}

export default ClassesFilters;