import { ActionCreator, ApplicationState } from '../../../';
import {
  createValidateActions,
  createSimpleUpdateValueMethod,
  createApiSubmitFormMethod,
  createApiValidateActions,
  createApiSubmitActions,
  createRequestActions
} from "../../../Validation/actionCreator";
import {validate} from "../../../../utils/validator";
import { FinanceForm } from '.';
import {getTotalAmount, rollbackIfClickOut} from '../../../../utils/helpers/financialSummaryHelper';
import { getUpdateEventPaymentBody } from '../../../../constants/urls';
import { FullGroup } from '../../../../models/api/options';
import { CacheThreeEventsState } from '../../../CacheThreeEvents';
import { EventRegistrationPaymentStatus, EventRegistrationNumbers } from '../../../../models/api/cacheThreeEvents';
import { CacheTwoEventsState } from '../../../CacheTwoEvents';
import { SaveState } from '../../../Rollback/actions';
import { typeName, Action } from '../../../../utils/StrongActions';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../utils/dataHelper';
import { Validator } from '../../../../utils/validator/models';

export const SUFFIX = '__EVENT_REGISTRATION_FINANCE';

@typeName("EVENTS_EVENT_REGISTRATION_MOBILE_SHOW_CONTACT")
export class EventsEventRegistrationMobileShowContact extends Action {
  constructor(public show: boolean) { super(); }
}
@typeName("EVENTS_EVENT_REGISTRATION_SELECT_FINANCE")
export class EventsEventRegistrationSelectFinance extends Action {
  constructor(public option: 'min' | 'full' | 'other' | 'credit') { super(); }
}
@typeName("EVENTS_EVENT_REGISTRATION_FINANCE_DIRTY")
export class EventsEventRegistrationFinanceDirty extends Action {}
@typeName("EVENTS_EVENT_REGISTRATION_OTHER_NOT_RECENTLY_SELCETED")
export class EventsEventRegistrationOtherNotRecentlySelected extends Action {}
@typeName("EVENTS_EVENT_REGISTRATION_SAVE_FINANCE")
export class EventsEventRegistrationSaveFinance extends Action {
  constructor(public value: number) { super(); }
}
@typeName("EVENTS_EVENT_REGISTRATION_RESET")
export class EventsEventRegistrationReset extends Action {}
@typeName("EVENTS_EVENT_SELECT_USER_FOR_RESTORE")
export class SelectUserForRestore extends Action {
  constructor(public IsYouth: boolean, public ParticipantIDi: number, public Name: string) { super();}
}
@typeName("EVENTS_EVENT_REGISTRATION_SHOW_MORE_PRODUCTS")
export class EventRegistrationShowMoreProducts extends Action {
  constructor(public showMoreProducts: boolean) {
    super();
  }
}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const FSSubmitActions = createApiSubmitActions(SUFFIX);

export const ReportClassScheduleActions = createRequestActions('__REPORT_CLASS_SCHEDULE');
export const ReportInvoiceActions = createRequestActions('__REPORT_INVOICE');
export const ReportRequirementsCompletedActions = createRequestActions('__REPORT_REQUIREMENTS_COMPLETED');
export const ReportRequirementsScoutbookActions = createRequestActions('__REPORT_REQUIREMENTS_SCOUTBOOK');
export const ReportBlueCardsActions = createRequestActions('__REPORT_BLUE_CARD');
export const ReportRosterActions = createRequestActions('__REPORT_ROSTER');

export type Actions = typeof actionCreators;

export const formCreator = (rootState: ApplicationState, activeForm: FinanceForm) => {
  const c2 = rootState.cacheTwoEvents as CacheTwoEventsState;
  const c3 = rootState.cacheThreeEvents as CacheThreeEventsState;
  const EventRegistrationPaymentStatus = c3.EventRegistrationPaymentStatus as EventRegistrationPaymentStatus;
  let newAmount: undefined | number = getTotalAmount(
    activeForm,
    EventRegistrationPaymentStatus as EventRegistrationPaymentStatus
  );
  let debugPayload: TentarooDebugPayload | undefined;
  if (newAmount === undefined) {
    debugPayload = captureTentarooErrorAndGetRequestPayload(`Unable to get total amount. - Event/Registration`);
  }
  let TotalAmountChange = newAmount === undefined ? undefined : (newAmount - EventRegistrationPaymentStatus.AmountInCart);

  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload(`cacheZero.options not available when submitting EventRegistration form`);
  }
  const Group = rootState.cacheZero.options?.Group as FullGroup;

  const payload = getUpdateEventPaymentBody({
    GroupIDi: Group.IDi,
    GroupTS: Group.TS,
    EventTypeID: c2.eventTypeID,
    EventIDi: c3.eventID,
    GroupWeekIDi: (c3.EventRegistrationNumbers as EventRegistrationNumbers).IDi,
    TotalAmountChange: TotalAmountChange as any
  });

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

const shouldSave = (value: any, vObj: Validator) => {
  // if (vObj.key === 'selectedFinance') {
  //   if (value === 'min' || value === 'full' || value === 'credit') return true;
  //   return !!reduxStoreService().getState().events.event.registration.ActiveForm.otherValue;
  // } else if (vObj.key === 'otherValue') {
  //   return false;
  // }
  return false;
};

export const actionCreators = {
  resetEventRegistration: (): ActionCreator => dispatch => dispatch(new EventsEventRegistrationReset()),
  mobileShowContact: (show: boolean): ActionCreator => dispatch => dispatch(new EventsEventRegistrationMobileShowContact(show)),
  financeDirty: (): ActionCreator => dispatch => dispatch(new EventsEventRegistrationFinanceDirty()),
  otherNotRecentlySelected: (): ActionCreator => dispatch => dispatch(new EventsEventRegistrationOtherNotRecentlySelected()),
  updateValue: (value: any, vObj: Validator): ActionCreator => (dispatch, getState) => {
    const shouldSaveState = shouldSave(value, vObj);
    if (vObj.key === 'selectedFinance') {
      if (value === 'other') {
        document.addEventListener('mousedown', rollbackIfClickOut);
      } else {
        document.removeEventListener('mousedown', rollbackIfClickOut);
      }
    } else if (vObj.key === 'otherValue') {
      document.addEventListener('mousedown', rollbackIfClickOut);
    }

    if (shouldSaveState) dispatch(new SaveState());
    dispatch(ValidateActions.updateCurr(value, vObj.key, vObj));
    let validationResults = validate(vObj, value, (s) => s.events.event.registration);
    dispatch(ValidateActions.update(validationResults, vObj.key));
    if (value !== 'other' && Object.keys(validationResults.Errors).length === 0) {
      dispatch(FSSubmitActions.request(formCreator(getState(), getState().events.event.registration.ActiveForm), null));
    }

  },
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  saveFinance: createApiSubmitFormMethod(FSSubmitActions, (s) => s.events.event.registration, formCreator, true, true, undefined, true, () => document.removeEventListener('mousedown', rollbackIfClickOut)),
  selectUserForRestore: (IsYouth: boolean, ParticipantIDi: number, Name: string): ActionCreator => dispatch => dispatch(new SelectUserForRestore(IsYouth, ParticipantIDi, Name)),
  reportClassSchedule: (EventIDi: number, EventTypeID: number, GroupWeekIDi: number): ActionCreator => dispatch => {
    dispatch(ReportClassScheduleActions.request({EventIDi, EventTypeID, GroupWeekIDi, reportDoc: 'troopschedule.cfr'}));
  },
  reportInvoice: (EventIDi: number, EventTypeID: number, GroupIDi: number): ActionCreator => dispatch => {
    dispatch(ReportInvoiceActions.request({EventIDi, EventTypeID, GroupIDi}));
  },
  reportRequirementsCompletedActions: (EventIDi: number, EventTypeID: number, GroupIDi: number): ActionCreator => dispatch => {
    dispatch(ReportRequirementsCompletedActions.request({EventIDi, EventTypeID, GroupIDi, reportDoc: 'reqs.cfr'}));
  },
  reportRequirementsScoutbook: (EventTypeID: number, EventIDi: number, GroupIDi: number): ActionCreator => dispatch => {
    dispatch(ReportRequirementsScoutbookActions.request({EventTypeID, EventIDi, GroupIDi}));
  },
  reportBlueCards: (EventIDi: number, GroupIDi: number, IsFront: boolean): ActionCreator => dispatch => {
    dispatch(ReportBlueCardsActions.request({EventIDi, GroupIDi, IsFront}));
  },
  reportRoster: (EventIDi: number, EventTypeID: number, GroupWeekIDi: number): ActionCreator => dispatch => {
    dispatch(ReportRosterActions.request({EventIDi, EventTypeID, GroupWeekIDi}));
  },
  eventRegistrationShowMoreProducts: (showMoreProducts: boolean): ActionCreator => dispatch => dispatch(new EventRegistrationShowMoreProducts(showMoreProducts))

};
