import * as React from 'react';
import '../../../../styles/elements/admin-message/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton, Vignette, Checkbox} from '../../index';
import ContextMenuComponent from './ContextMenu';
import { OptionsIcon, CloseIcon, EditIcon, EmailIcon } from '../../../../components/Icons';
import { CardCategory } from '../../Card';
import { namespace as cardNS } from "../../Card/constants";
import { AdminEventsEmailMessage } from '../../../../models/api/adminEventsCacheOne';
import moment from 'moment';
import { SELECTED_ITEM_DELETED } from '../../../../constants/messages/generic';
import { isCardClicked } from '../../../../utils/navHelper';
import { ADMIN_EVENTS_EDIT_MESSAGE_PATH, ADMIN_EVENTS_NEW_MESSAGE_PATH } from '../../../../routes';
import { withRouter } from 'react-router';
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--admin-message--card';

export enum AdminMessageCardType {
  MESSAGE_LIST='message-list',
  MODAL_SELECT='modal-select',
  EVENT_FORM='event-form',
  SELECT_LIST='select-list',
} 

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin' | 'info' | 'mobile-full';
  type: AdminMessageCardType;
  category: CardCategory;
  noBoxShadow?: boolean;
  selected?: boolean;
  message: AdminEventsEmailMessage;
  onEdit?: (message: AdminEventsEmailMessage) => void;
  onDelete?: (message: AdminEventsEmailMessage) => void;
  onRestore?: (message: AdminEventsEmailMessage) => void;
  onSend?: (message: AdminEventsEmailMessage) => void;
  onRemove?: (message: AdminEventsEmailMessage) => void;
  onClick?: (message: AdminEventsEmailMessage) => void;
  disabled?: boolean;
  routes?: any;
  showDeletedPrompt?: boolean;
}


class AdminMessageCard extends React.Component<Props> {
  shouldAllowPropagation = (e) => {
    const { routes } = this.props;
    const route = routes[routes.length - 1];
    const clickedOnCard = isCardClicked(e, namespace);
    return ((route.path === ADMIN_EVENTS_NEW_MESSAGE_PATH || route.path === ADMIN_EVENTS_EDIT_MESSAGE_PATH) && !clickedOnCard);
  };
  onEditClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onEdit, disabled, message} = this.props;
    
    e.stopPropagation();
    !disabled && onEdit && onEdit(message);
  };
  onDeleteClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onDelete, message} = this.props;
    e.stopPropagation();
    !this.props.disabled && onDelete && onDelete(message);
  };
  onRestoreClick = (e) => {
    if(this.shouldAllowPropagation(e)) return;
    const {onRestore, message} = this.props;
    !this.props.disabled && e.stopPropagation();
    onRestore && onRestore(message);
  };
  onRemoveClick = (e) => {
    const {onRemove, message} = this.props;
    e.stopPropagation();
    !this.props.disabled && onRemove && onRemove(message);
  };

  onSendClick = (e) => {
    const {disabled, message, onSend} = this.props;
    e.stopPropagation();
    !disabled && onSend && onSend(message);
  };
  onClick = (e) => {
    const {disabled, message, onClick} = this.props;

    !disabled && onClick && onClick(message);
  };
  onCardClick = (e) => {
    const {type, message, disabled} = this.props;

    if (message.Inactive || disabled) return;

    switch (type) {
      case AdminMessageCardType.MESSAGE_LIST:
        this.onEditClick(e);
      case AdminMessageCardType.SELECT_LIST:
        this.onClick(e);
        break;
    }
  };

  renderContent = () => {
    const { message, category, routes, disabled, type, onRestore } = this.props;
    const route = routes[routes.length - 1];

    return (
      <div className={`${namespace()}--main`}>
        {(type === AdminMessageCardType.MODAL_SELECT || type === AdminMessageCardType.EVENT_FORM) && <div  className={`${namespace()}--icon`}><EmailIcon /></div>}
        <div className={`${namespace()}--main--text`}>
          <div className={`${namespace()}--main--text--title`}>{message.Name}</div>
          <div className={`${namespace()}--main--text--subtitle`}>{message.MessagePreview}</div>
        </div>
        {(type === AdminMessageCardType.MODAL_SELECT || type === AdminMessageCardType.SELECT_LIST) && !message.Inactive && <ActionButton
          disabled={disabled}
          icon={EditIcon}
          id={generateDOMId(`admin-message-card-${message.ID}-edit-btn`)}
          onClick={this.onEditClick}
          className={`${namespace()}--edit ${cardNS()}--edit ${category}`}
        />}
        {type === AdminMessageCardType.MODAL_SELECT && <ActionButton
          disabled={disabled}
          id={generateDOMId(`admin-message-card-remove-btn`)}
          icon={CloseIcon}
          onClick={this.onRemoveClick}
          className={`${namespace()}--close ${cardNS()}--close ${category}`}
        />}
        {type === AdminMessageCardType.MESSAGE_LIST && !message.Inactive && <ActionButton
          id={generateDOMId(`admin-message-${message.ID}-options-btn`)}
          disabled={disabled}
          icon={OptionsIcon}
          allowPropagation={route.path === ADMIN_EVENTS_NEW_MESSAGE_PATH || route.path === ADMIN_EVENTS_EDIT_MESSAGE_PATH}
          className={`${namespace()}--options ${cardNS()}--options ${category}`}
          contextMenu={<ContextMenuComponent
            messageId={message.ID}
            onEdit={this.onEditClick}
            onSend={this.onSendClick}
            onDelete={this.onDeleteClick}
            />}
        />}
        {type === AdminMessageCardType.MESSAGE_LIST &&  message.Inactive && onRestore && <Button className={`${cardNS()}--restore ${namespace()}--restore`} flat textColor="red" onClick={this.onRestoreClick}>RESTORE</Button>}
      </div>
    );
  };

  render() {
    const {category, noBoxShadow, selected, template, showDeletedPrompt, message, type, routes} = this.props;
    let className = attribute(namespace(), undefined, this.props);

    if (message.Inactive) className += ' deleted';
    if (showDeletedPrompt) className += ' show-deleted-prompt';
    className += ` ${type}`;

    return (
      <Card
        noBoxShadow={noBoxShadow}
        className={className}
        component={<section/>}
        template={template || 'mobile-no-margin'}
        category={category}
        padding="none"
        selected={selected}
      >
        <div id={generateDOMId(`tentaroo-admin-message-${message.ID}`)} className={`${namespace()}--wrapper`} onClick={this.onCardClick}>
          {this.renderContent()}
          {message.Inactive && showDeletedPrompt && <section className={`${namespace()}--deleted-prompt`}>{SELECTED_ITEM_DELETED}</section>}
        </div>
      </Card>
    );
  }
}

export default withRouter(AdminMessageCard as any) as any;