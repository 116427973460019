import * as React from 'react';
import { Main, MainContent } from '../../../Layouts';
import { EmptyMessage, LoadingAll, MyEvent, Tab, Tabs, Title } from '../../../Elements';
import EventSidebar from '../Sidebar';
import EventContactCard from '../ContactCard';
import SkeletonEvent from "../../../Elements/MyEvent/SkeletonEvent/index";
import {
  makeNoFilterCurrentSelector,
  makeNoFilterPastSelector,
  makeNoFilterUpcomingSelector
} from "../../../../store/Events/EventType/index";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../../../store/Events/EventType/actions";
import {
  actionCreators as cacheTwoActionCreators,
} from "../../../../store/CacheTwoEvents/actions";
import { actionCreators as cacheOneActionCreators, ClearCacheBelowOne } from "../../../../store/CacheOne/actions";
import { RouteComponentProps } from "react-router";
import CampInfo from '../CampInfo';
import { makeSelectedEventTypeSelector } from "../../../../store/CacheTwoEvents/index";
import { EVENT, URLS } from "../../../../constants/urls";
import { EventTypeEvent } from "../../../../models/api/cacheTwoEvents";
import { navPush } from "../../../../utils/navHelper";
import { spaceTo_ } from "../../../../utils/stringHelper";
import { EventsIcon } from "../../../Icons";
import { generateDOMId } from '../../../../utils/cypressHelper';
import EndUserCacheManager from '../../../../utils/cacheManagers/endUserCacheManager';
import { IEventTypeRouterParams, isPathUnderEndUserEventCacheThree } from '../../../../utils/helpers/endUserPageHelper';
import { shouldReconfigRouter } from '../../../../utils/cacheLoaders/reloaderHelper';
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../store/service';
import { ComponentUpdateTemplate } from '../../../Templates/ComponentUpdateTemplate';
import {isEndUserCacheOnePopulated, isEndUserCacheTwoEventsPopulated} from '../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IEventTypeRouterParams, {}>
>;

class EventType extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
  private nextLocation;

  public componentDidMount() {
    this.configRouteLeaveHook();
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheTwoEvent({
          props: this.props,
          isStateNavigated,
        });
      }
    );
  }

  componentWillUnmount() {
    const {cacheTwoEvents} = this.props;

    // if we are not navigating to caches below it, clear cache
    if (
      this.nextLocation &&
      !isPathUnderEndUserEventCacheThree(this.nextLocation.pathname) &&
      isEndUserCacheTwoEventsPopulated(cacheTwoEvents)
    ) {
      reduxStoreService().dispatch(new ClearCacheBelowOne());
    }
    this.resetRouteLeaveHook();
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheTwoEvent({
          props: nextProps,
          isStateNavigated,
        });
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouteLeaveHook();
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  onClickCurrent = () => this.props.actions.selectTab('upcoming');
  onClickRegistered = () => this.props.actions.selectTab('current');
  onClickPast = () => this.props.actions.selectTab('past');

  private renderContent() {
    const {
      allUpcomingEvents, allCurrentEvents, allPastEvents, selectedEventType,
      eventType: { selectedTab }, cacheTwoEvents
    } = this.props;

    if (selectedEventType) {
      let ret: Array<any> = [];
      // EventContactCard only shows on tablet and below
      ret.push(<EventContactCard />);
      ret.push(<Title size={28}
        num={cacheTwoEvents && cacheTwoEvents.EventTypeEvents ? cacheTwoEvents.EventTypeEvents.length : undefined}
        mobileMarginTop={24 - 16}>
        Events
      </Title>);
      // yeah it looks wrong below, but it is right
      ret.push(
        <Tabs>
          <Tab selected={selectedTab === 'upcoming'} onClick={this.onClickCurrent}>CURRENT</Tab>
          <Tab selected={selectedTab === 'current'} onClick={this.onClickRegistered}>REGISTERED</Tab>
          <Tab id={generateDOMId("past-events")} selected={selectedTab === 'past'} onClick={this.onClickPast}>PAST</Tab>
        </Tabs>
      );
      if (cacheTwoEvents.EventTypeEvents === undefined) {
        ret.push(<SkeletonEvent />);
      } else {
        let events: Array<EventTypeEvent> = [];
        let emptyMessage = '';
        // yeah it looks wrong below, but it is right
        if (selectedTab === 'upcoming') {
          events = allUpcomingEvents;
          emptyMessage = 'No current or future events.';
        } else if (selectedTab === 'current') {
          events = allCurrentEvents;
          emptyMessage = 'No registrations for current or future events.';
        } else if (selectedTab === 'past') {
          events = allPastEvents;
          emptyMessage = 'No registrations for past events.';
        }
        if (events.length > 0) {
          ret = ret.concat(events.map((event: EventTypeEvent, index) => {
            let onClickEvent = () => navPush(this.props.router, `${URLS.EVENTS}/${selectedEventType.ID}/${event.IDi}/${spaceTo_(event.Name)}`);
            if (event.HasGroupRegistration) {
              onClickEvent = () => navPush(this.props.router, `${URLS.EVENTS}/${selectedEventType.ID}/${event.IDi}/${spaceTo_(event.Name)}/${EVENT.REGISTRATION}`);
            }
            return <MyEvent
              key={index}
              id={generateDOMId(`event-${event.IDi}`)}
              onClick={onClickEvent}
              dateStart={event.StartDateTime}
              dateEnd={event.EndDateTime}
              name={event.Name}
              warning={(!event.IsSoldOut && event.RegistrationStatus) ? event.RegistrationStatus : undefined}
              error={event.IsSoldOut ? 'FULL' : undefined}
              cart={event.InCart !== 0}
            />;
          }));
        } else {
          ret.push(<EmptyMessage icon={EventsIcon} description={emptyMessage} />);
        }
      }
      return ret;
    }
    return null;
  }

  public render() {
    if (!isEndUserCacheOnePopulated(this.props.cacheOne) || !this.props.selectedEventType) return <LoadingAll />;
    const { selectedEventType, inert, eventType: { showSidebar, showMapMobile }, SystemSettings, actions } = this.props;

    if (!SystemSettings) return null;

    return (
      <Main
        inert={inert}
        leftSidebar={<EventSidebar />}
        rightGutter={false}
      >
        <MainContent
          header={selectedEventType ? <CampInfo
            title={selectedEventType.Name}
            campLocation={selectedEventType.DefaultLocation}
            contactInfo={selectedEventType.EventContactInfo}
            image={selectedEventType.FeaturedImage}
            systemSettings={SystemSettings}
            showMapMobile={showMapMobile}
            onShowMapMobile={actions.toggleMapMobile}
            description={selectedEventType.EventContactInfo.Description}
          /> : null}
          rightGutter
        >
          {this.renderContent()}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const allUpcomingEvents = makeNoFilterUpcomingSelector();
  const allCurrentEvents = makeNoFilterCurrentSelector();
  const allPastEvents = makeNoFilterPastSelector();
  const selectedEventType = makeSelectedEventTypeSelector();
  return {
    eventType: state.events.eventType,
    SystemSettings: state.session.SystemSettings,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheTwoEvents: state.cacheTwoEvents,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    allUpcomingEvents: allUpcomingEvents(state),
    allCurrentEvents: allCurrentEvents(state),
    allPastEvents: allPastEvents(state),
    selectedEventType: selectedEventType(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actionCreators, ...cacheTwoActionCreators, ...cacheOneActionCreators }, dispatch)
});

const ConnectedEventType = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(EventType);

export default ConnectedEventType;

export { default as EventTypeHeader } from './Header';
