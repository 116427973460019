import * as React from 'react';

import {Column, Row} from '../../../Elements';
import {PersonFormValidatior} from '../../../../utils/personFormValidationRules';
import {EventRegistrationPersonCustomField} from '../../../../models/api/cacheFourEvents';
import AlertIcon from '../../../../images/elements/alert/alert.svg';
import {CustomField} from './customField';
import { Validator } from '../../../../utils/validator/models';

export interface Props {
  ActiveForm: any;
  ValidationRules: PersonFormValidatior;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
  };
  customFields: Array<EventRegistrationPersonCustomField>;
  disabled?: boolean;
}

export class CustomFields extends React.Component<Props, {}> {
  props: Props;

  render(): any {
    const {customFields, ActiveForm, ValidationRules, reduxActions, disabled} = this.props;

    return customFields.map((cf: EventRegistrationPersonCustomField) => {
        const key = `${cf.ElementID}`;
        if (cf.Type === 'notes') {
          return <Row>
            <Column span={12} mobileSpan={12}>
              <AlertIcon color="#E12341" style={{flexShrink: 0, alignSelf: 'center'}}/>
              <CustomField
                value={ActiveForm[key]}
                validationRules={ValidationRules[key]}
                customField={cf}
                reduxActions={reduxActions}
              />
            </Column>
          </Row>;
        }
        return <Row>
          <Column span={6} mobileSpan={12}>
            <CustomField
              value={ActiveForm[key]}
              validationRules={ValidationRules[key]}
              customField={cf}
              reduxActions={reduxActions}
              info={cf.Tooltip}
            />
          </Column>
        </Row>;
      });
  }
}
