import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';
import { namespace as ns } from './constants';
import '../../../styles/elements/camp-info/title.scss';

export const namespace = (): string => `${ns()}--title`;

export interface TitleProps {
  className?: string;
  children?: React.ReactNode;
  size?: number;
}

const Title: React.SFC<TitleProps> = ({ children, size, ...props }: TitleProps): React.ReactElement<TitleProps> => (
  <h1 className={mergeClassNames(namespace(), props) + (size ? ` size-${size}` : '')}>
    {children}
  </h1>
);

export default Title;
