import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import { DATE_PICKER_FORMAT, TODAY, MIN_DATE } from "@tentaroo/shared";

import * as M from "../constants/messages/generic";
import * as MP from "../constants/messages/participants";
import { RankOption, Options } from "../models/api/options";
import { FullGroupRosterPerson } from "../models/api/cacheTwoRoster";
import type { ApplicationState } from "../store";
import { CouncilIDiDefaultValue } from "./validator";
import {MAX_LENGTH} from "../constants/messages/generic";
import { isAdmin } from "./permissionHelper";
import { SelectValidator, Validator } from "./validator/models";

export const ScoutTypeID_NOTASCOUT_YOUTH = 4;
export const ScoutTypeID_NOTASCOUT_ADULT = 3;

export const ShowUnitFields = (GroupTypeID: number, AllowNonScouter: boolean, ScoutTypeID: number, IsYouth: boolean) => {
  return GroupTypeID !== 1 && (!AllowNonScouter || ScoutTypeID === 2 || (ScoutTypeID === 3 && IsYouth));
};
export interface PersonFormActiveForm {
  FirstName?: string;
  LastName?: string;
  DOB?: any;
  BSAID?: number;
  Email?: string;
  Address?: string;
  City?: string;
  StateID?: number;
  Zip?: string;
  PhoneNumber?: string;
  Phone2?: string;
  UnitLeaderName?: string;
  UnitLeaderPhone?: string;
  UnitLeaderEmail?: string;
  Rank?: string;
  Grade?: number
  TroopPosition?: string;
  ShirtSize?: string;
  EmergencyName?: string;
  EmergencyPhone?: string;
  Sex?: string;
  DateYouthProtectionTraining?: any;
  DateBalooTraining?: any;
  DateBasicTrainingForPosition?: any;
  AllowPhoto?: boolean;
  Chapter?: string;
  HasDietaryRestrictions?: boolean;
  HasSeriousAllergies?: boolean;
  MI?: string;
  Suffix?: string;
  OAPosition?: string;
  OAStatusID?: number;
  ScoutTypeID?: number;
  SpecialNeeds?: string;
  UnitTypeID?: number;
  Unit?: string;
  CouncilIDi?: number;
  DistrictIDi?: number;
  OALodge?: string;
}

export const ShowRankField = (AllowNonScouter: boolean, GroupTypeID: number, ScoutTypeID: number): boolean => {
  return (!AllowNonScouter || GroupTypeID === 1 || ScoutTypeID === 2 || ScoutTypeID === 3);
};

export const ShowIsScoutField = (
  GroupTypeID: number,
  AllowNonScouter: boolean,
  OldScoutTypeID: number,
  IsYouth: boolean
) => {
  //Show the IsScout field if the GroupType is not Unit and AllowNonScouter.
  //Override and show the field anyway if ScoutTypeID is Not a Scout and not allowing non-scouters.
  //  This way, field is visible in case ScoutTypeID needs to be changed.
  const notScoutID = IsYouth ? ScoutTypeID_NOTASCOUT_YOUTH : ScoutTypeID_NOTASCOUT_ADULT;
  return GroupTypeID !== 1 && (
    (AllowNonScouter) ||
    (!AllowNonScouter && OldScoutTypeID === notScoutID)
  );
};

export const GetRankOptions = (
  BSRanks: Array<any>,
  CSRanks: Array<any>,
  AllowNonScouter: boolean,
  ScoutTypeID: number,
  GroupTypeID: number,
  GroupUnitTypeID: number,
  CamperUnitTypeID: number
) : Array<any> => {

  // UnitTypes:  1	Troop, 2	Pack, 3	Team, 4	Crew, 5	Ship, 6	Post
  if (GroupTypeID === 1) {
    //UnitType is specified at Group level in this case.
    if (GroupUnitTypeID === 2) return CSRanks;
    else return BSRanks;
  } else if (AllowNonScouter && ScoutTypeID === 4) {
    //Not a Scouter, default to BSRanks.
    return BSRanks;
  } else {
    //Scouter, unit type set under individual.
    if (CamperUnitTypeID === 2) return CSRanks;
    else return BSRanks;
  }
};

const getValues = (rootState: ApplicationState, key) => {
  const opts = rootState.cacheZero.options;
  if (opts) return opts[key];
  return [];
};


export interface PersonFormValidatior {
  FirstName: Validator;
  LastName: Validator;
  DOB: Validator;
  BSAID: Validator;
  Email: Validator;
  Address: Validator;
  City: Validator;
  StateID: SelectValidator;
  Zip: Validator;
  PhoneNumber: Validator;
  Phone2: Validator;
  UnitLeaderName: Validator;
  UnitLeaderPhone: Validator;
  UnitLeaderEmail: Validator;
  Rank: SelectValidator;
  Grade: SelectValidator;
  TroopPosition: Validator;
  ShirtSize: SelectValidator;
  EmergencyName: Validator;
  EmergencyPhone: Validator;
  Sex: SelectValidator;
  DateYouthProtectionTraining: Validator;
  DateBalooTraining: Validator;
  DateBasicTrainingForPosition: Validator;
  AllowPhoto: Validator;
  Chapter: Validator;
  HasDietaryRestrictions: Validator;
  HasSeriousAllergies: Validator;
  MI: Validator;
  Suffix: Validator; // Adult only
  OALodge: Validator;
  OAPosition: Validator;
  OAStatusID: SelectValidator;
  ScoutTypeID: SelectValidator;
  SpecialNeeds: Validator;
  UnitTypeID: SelectValidator;
  Unit: Validator;
  CouncilIDi: SelectValidator;
  DistrictIDi: SelectValidator;
}


export const createFormDefinition = (
  getYAKey: (rootState: ApplicationState, keyRoot: string) => boolean,
  isYouth: (rootState: ApplicationState, ) => boolean,
  ShowUnit: (rootState: ApplicationState, ) => boolean,
  RetrieveRankOptions: (rootState: ApplicationState, ) => Array<RankOption>,
  getRequireEmergencyContact: (rootState: ApplicationState, ) => boolean,
  getAllowNonScouterParticipants: (rootState: ApplicationState, ) => boolean,
  getPerson: (rootState: ApplicationState, ) => FullGroupRosterPerson | null | undefined,
  getActiveForm: (rootState: ApplicationState, ) => any, // @todo: should not be any... should be all the form values
  ShowScoutField: (rootState: ApplicationState, ) => boolean,
  filteredDistrictSelector: (state: ApplicationState) => any, // @todo: what is the return?
  ShowRank: (rootState: ApplicationState, ) => boolean,
  scoutCustomValidate?: (rootState: ApplicationState, ) => string | undefined
) : PersonFormValidatior => {
  const isDistrictIDiRequired = (rootState: ApplicationState, ) => {
    if (isAdmin(rootState)) return false;
    if (!ShowUnit(rootState)) return false;
  
    if (!rootState.cacheZero.options || !rootState.cacheZero.options.Group) return false;
  
    const filteredDistricts = filteredDistrictSelector(rootState);
  
    return !!rootState.cacheZero.options.Districts && filteredDistricts.length > 1;
  };
  return {
    FirstName: {
      key: 'FirstName',
      validatejs: {
        FirstName: {
          presence: {message: '^' + M.REQUIRED},
          length: {
            maximum: 20,
            tooLong: MAX_LENGTH(20),
          }
        }
      }
    },
    LastName: {
      key: 'LastName',
      validatejs: {
        LastName: {
          presence: {message: '^' + M.REQUIRED},
          length: {
            maximum: 20,
            tooLong: MAX_LENGTH(20),
          }
        }
      }
    },
    DOB: {
      key: 'DOB',
      isRequired: (rootState) => getYAKey(rootState, 'RequireDOB'),
      skip: (rootState) => !getYAKey(rootState, 'ShowDOB'),
      validatejs: {
        DOB: {
          presence: {message: '^' + M.REQUIRED},
          datetime: {
            latest: TODAY.format(DATE_PICKER_FORMAT),
            earliest: MIN_DATE.format(DATE_PICKER_FORMAT),
            notValid: `^${M.INVALID_DATE}`,
            tooEarly: `^${M.TOO_EARLY}`,
            tooLate: `^${M.TOO_LATE}`
          }
        }
      }
    },
    BSAID: {
      key: 'BSAID',
      integerOnly: true,
      allowNegative: () => false,
      isRequired: (rootState) => getYAKey(rootState, 'RequireBSAID'),
      skip: (rootState) => !getYAKey(rootState, 'ShowBSAID'),
      validatejs: {
        BSAID: {
          presence: {message: '^' + M.REQUIRED},
          numericality: {
            notGreaterThan: M.MIN_VALUE(100),
            greaterThan: 99
          },
          length: {
            maximum: 11,
            tooLong: '^' + 'Max length of 11 characters exceeded.'
          }
        }
      }
    },
    Email: {
      key: 'Email',
      defaultValue: (rootState) => {
        if (!rootState.cacheZero.options) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("cacheZero.options not available when configuring PersonForm validation rules"));
          return undefined;
        }
        if (!rootState.cacheZero.options.Group) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("Group not available when configuring PersonForm validation rules"));
          return undefined;
        }
        const group = rootState.cacheZero.options.Group;
        if (group.GroupTypeID !== 1) {
          return group.Email;
        }
        return '';
      },
      isRequired: (rootState) => getYAKey(rootState, 'RequireEmail'),
      validatejs: {
        Email: {
          presence: {message: '^' + M.REQUIRED},
          email: {message: '^' + M.INVALID},
          length: {
            maximum: 50,
            tooLong: MAX_LENGTH(50)
          }
        }
      }
    },
    Address: {
      key: 'Address',
      defaultValue: (rootState) => {
        if (!rootState.cacheZero.options) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("cacheZero.options not available when configuring PersonForm validation rules"));
          return undefined;
        }
        if (!rootState.cacheZero.options.Group) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("Group not available when configuring PersonForm validation rules"));
          return undefined;
        }
        const group = rootState.cacheZero.options.Group;
        if (group.GroupTypeID !== 1 && getYAKey(rootState, 'ShowAddress')) {
          return group.Address;
        }
        return '';
      },
      isRequired: (rootState) => getYAKey(rootState, 'RequireAddress'),
      skip: (rootState) => !getYAKey(rootState, 'ShowAddress'),
      validatejs: {
        Address: {
          presence: {message: '^' + M.REQUIRED},
          length: {
            maximum: 50,
            tooLong: MAX_LENGTH(50)
          }
        }
      }
    },
    StateID: {
      key: 'StateID',
      defaultValue: (rootState) => {
        if (!rootState.cacheZero.options) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("cacheZero.options not available when configuring PersonForm validation rules"));
          return undefined;
        }
        if (!rootState.cacheZero.options.Group) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("Group not available when configuring PersonForm validation rules"));
          return undefined;
        }
        if (!rootState.session.SystemSettings) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("session.SystemSettings not available when configuring PersonForm validation rules"));
          return undefined;
        }
        const group = rootState.cacheZero.options.Group;
        if (group.GroupTypeID !== 1 && getYAKey(rootState, 'ShowAddress')) {
          return group.StateID;
        }
        return rootState.session.SystemSettings.StateID;
      },
      isRequired: (rootState) => getYAKey(rootState, 'RequireAddress'),
      skip: (rootState) => !getYAKey(rootState, 'ShowAddress'),
      options: {
        values: (rootState) => [{ Name: '', ID: -1 }, ...getValues(rootState, 'StateOptions')],
        valueKey: (v) => v.ID,
        labelKey: 'Name',
        emptyValue: -1
      },
      validatejs: {
        StateID: {
          presence: {message: '^' + M.REQUIRED},
          numericality : {
            notGreaterThan: SELECT_NOT_GREATER_THAN,
            greaterThan: 0
          }
        }
      }
    },
    City: {
      key: 'City',
      defaultValue: (rootState) => {
        if (!rootState.cacheZero.options) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("cacheZero.options not available when configuring PersonForm validation rules"));
          return undefined;
        }
        if (!rootState.cacheZero.options.Group) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("Group not available when configuring PersonForm validation rules"));
          return undefined;
        }
        const group = rootState.cacheZero.options.Group;
        if (group.GroupTypeID !== 1 && getYAKey(rootState, 'ShowAddress')) {
          return group.City;
        }
        return '';
      },
      isRequired: (rootState) => getYAKey(rootState, 'RequireAddress'),
      skip: (rootState) => !getYAKey(rootState, 'ShowAddress'),
      validatejs: {
        City: {
          presence: {message: '^' + M.REQUIRED},
          length: {
            maximum: 50,
            tooLong: MAX_LENGTH(50)
          }
        }
      }
    },
    Zip: {
      key: 'Zip',
      defaultValue: (rootState) => {
        if (!rootState.cacheZero.options) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("cacheZero.options not available when configuring PersonForm validation rules"));
          return undefined;
        }
        if (!rootState.cacheZero.options.Group) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("Group not available when configuring PersonForm validation rules"));
          return undefined;
        }
        const group = rootState.cacheZero.options.Group;
        if (group.GroupTypeID !== 1 && getYAKey(rootState, 'ShowAddress')) {
          return group.Zip;
        }
        return '';
      },
      zipOnly: true,
      isRequired: (rootState) => getYAKey(rootState, 'RequireAddress'),
      skip: (rootState) => !getYAKey(rootState, 'ShowAddress'),
      validatejs: {
        Zip: {
          presence: {message: '^' + M.REQUIRED},
          length: {
            maximum: 10,
            tooLong: MAX_LENGTH(10)
          }
        }
      }
    },
    PhoneNumber: {
      key: 'PhoneNumber',
      defaultValue: (rootState) => {
        if (!rootState.cacheZero.options) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("cacheZero.options not available when configuring PersonForm validation rules"));
          return undefined;
        }
        if (!rootState.cacheZero.options.Group) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("Group not available when configuring PersonForm validation rules"));
          return undefined;
        }
        const group = rootState.cacheZero.options.Group;
        if (group.GroupTypeID !== 1 && getYAKey(rootState, 'ShowPhone')) {
          return group.PhoneNumber;
        }
        return '';
      },
      isRequired: (rootState) => getYAKey(rootState, 'RequirePhone'),
      skip: (rootState) => !getYAKey(rootState, 'ShowPhone'),
      validatejs: {
        PhoneNumber: {
          presence: {message: '^' + M.REQUIRED},
          length: {
            maximum: 25,
            tooLong: MAX_LENGTH(25)
          }
        }
      }
    },
    Phone2: {
      key: 'Phone2',
      skip: (rootState) => !getYAKey(rootState, 'ShowPhone'),
      validatejs: {
        Phone2: {
          length: {
            maximum: 25,
            tooLong: MAX_LENGTH(25)
          }
        }
      }
    },
    UnitLeaderName: {
      key: 'UnitLeaderName',
      isRequired: (rootState) => {
        if (!isYouth(rootState)) return false;
        return getYAKey(rootState, 'RequireUnitLeader') && ShowUnit(rootState);
      },
      skip: (rootState) => {
        if (!isYouth(rootState)) return true;
        return !(getYAKey(rootState, 'ShowUnitLeader') && ShowUnit(rootState));
      },
      validatejs: {
        UnitLeaderName: {
          presence: {message: '^' + M.REQUIRED},
          length: {
            maximum: 50,
            tooLong: MAX_LENGTH(50)
          }
        }
      }
    },
    UnitLeaderPhone: {
      key: 'UnitLeaderPhone',
      isRequired: (rootState) => {
        if (!isYouth(rootState)) return false;
        return getYAKey(rootState, 'RequireUnitLeader') && ShowUnit(rootState);
      },
      skip: (rootState) => {
        if (!isYouth(rootState)) return true;
        return !(getYAKey(rootState, 'ShowUnitLeader') && ShowUnit(rootState));
      },
      validatejs: {
        UnitLeaderPhone: {
          presence: {message: '^' + M.REQUIRED},
          length: {
            maximum: 50,
            tooLong: MAX_LENGTH(50)
          }
        }
      }
    },
    UnitLeaderEmail: {
      key: 'UnitLeaderEmail',
      isRequired: (rootState) => {
        if (!isYouth(rootState)) return false;
        return getYAKey(rootState, 'RequireUnitLeader') && ShowUnit(rootState);
      },
      skip: (rootState) => {
        if (!isYouth(rootState)) return true;
        return !(getYAKey(rootState, 'ShowUnitLeader') && ShowUnit(rootState));
      },
      validatejs: {
        UnitLeaderEmail: {
          presence: {message: '^' + M.REQUIRED},
          email: {message: '^' + M.INVALID},
          length: {
            maximum: 50,
            tooLong: MAX_LENGTH(50)
          }
        }
      }
    },
    Rank: {
      key: 'Rank',
      defaultValue: (rootState) => {
        const {Rank} = getActiveForm(rootState);
        if (Rank !== undefined) return Rank;
        return '';
      },
      isRequired: (rootState) => {
        if (!isYouth(rootState)) return false;
        return getYAKey(rootState, 'RequireRank');
      },
      skip: (rootState) => {
        if (!isYouth(rootState)) return true;
        return !ShowRank(rootState);
      },
      options: {
        values: (rootState) => {
          return RetrieveRankOptions(rootState);
        },
        valueKey: (v) => v.ID,
        labelKey: 'Name',
        emptyValue: ''
      },
      validatejs: {
        Rank: {
          presence: {message: '^' + M.REQUIRED},
          // NOTE: Value of this select field is string, so setting numericality to undefined,
          numericality: undefined,
        }
      }
    },
    Grade: {
      key: 'Grade',
      defaultValue: () => {
        return -2;
      },
      isRequired: (rootState) => {
        if (!isYouth(rootState)) return false;
        return getYAKey(rootState, 'RequireGrade');
      },
      skip: (rootState) => {
        if (!isYouth(rootState)) return true;
        return !getYAKey(rootState, 'ShowGrade');
      },
      options: {
        values: (rootState) => [...getValues(rootState, 'GradeOptions')],
        valueKey: (v) => v.ID,
        labelKey: 'Name',
        emptyValue: -2
      },
      validatejs: {
        Grade: {
          presence: {message: '^' + M.REQUIRED},
          numericality : {
            notGreaterThan: SELECT_NOT_GREATER_THAN,
            greaterThan: -2
          }
        }
      }
    },
    TroopPosition: {
      key: 'TroopPosition',
      isRequired: (rootState) => getYAKey(rootState, 'RequireUnitPosition'),
      skip: (rootState) => !getYAKey(rootState, 'ShowUnitPosition'),
      validatejs: {
        TroopPosition: {
          presence: {message: '^' + M.REQUIRED},
          length: {
            maximum: 20,
            tooLong: MAX_LENGTH(20)
          }
        }
      }
    },
    ShirtSize: {
      key: 'ShirtSize',
      defaultValue: () => {
        return '';
      },
      isRequired: (rootState) => getYAKey(rootState, 'RequireShirtSize'),
      skip: (rootState) => !getYAKey(rootState, 'ShowShirtSize'),
      options: {
        values: (rootState) => [{ Name: '', ID: '' }, ...getValues(rootState, 'ShirtSizes')],
        valueKey: (v) => v.ID,
        labelKey: 'Name',
        emptyValue: ''
      },
      validatejs: {
        ShirtSize: {
          presence: {message: '^' + M.REQUIRED},
          // NOTE: Value of this select field is string, so setting numericality to undefined,
          numericality: undefined,
        }
      }
    },
    EmergencyName: {
      key: 'EmergencyName',
      defaultValue: (rootState) => {
        if (!rootState.cacheZero.options) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("cacheZero.options not available when configuring PersonForm validation rules"));
          return undefined;
        }
        if (!rootState.cacheZero.options.Group) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("Group not available when configuring PersonForm validation rules"));
          return undefined;
        }
        const group = rootState.cacheZero.options.Group;
        if (group.GroupTypeID !== 1) {
          return `${group.FirstName} ${group.LastName}`;
        }
        return '';
      },
      isRequired: (rootState) => isYouth(rootState) && getRequireEmergencyContact(rootState),
      validatejs: {
        EmergencyName: {
          presence: {message: '^' + M.REQUIRED},
          length: {
            maximum: 200,
            tooLong: MAX_LENGTH(200)
          }
        }
      }
    },
    EmergencyPhone: {
      key: 'EmergencyPhone',
      defaultValue: (rootState) => {
        if (!rootState.cacheZero.options) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("cacheZero.options not available when configuring PersonForm validation rules"));
          return undefined;
        }
        if (!rootState.cacheZero.options.Group) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("Group not available when configuring PersonForm validation rules"));
          return undefined;
        }
        const group = rootState.cacheZero.options.Group;
        if (group.GroupTypeID !== 1) {
          return group.PhoneNumber;
        }
        return '';
      },
      isRequired: (rootState) => isYouth(rootState) && getRequireEmergencyContact(rootState),
      validatejs: {
        EmergencyPhone: {
          presence: {message: '^' + M.REQUIRED},
          length: {
            maximum: 50,
            tooLong: MAX_LENGTH(50)
          }
        }
      }
    },
    Sex: {
      key: 'Sex',
      isRequired: (rootState) => getYAKey(rootState, 'RequireGender'),
      skip: (rootState) => !getYAKey(rootState, 'ShowGender'),
      defaultValue: () => {
        return '';
      },
      options: {
        values: () => [{Name:"", ID:""}, {Name:"Male", ID:"M"}, {Name:"Female", ID:"F"}],
        valueKey: (v) => v.ID,
        labelKey: 'Name',
        emptyValue: ''
      },
      validatejs: {
        Sex: {
          presence: {message: '^' + M.REQUIRED},
          // NOTE: Value of this select field is string, so setting numericality to undefined,
          numericality: undefined,
        }
      }
    },
    DateYouthProtectionTraining: {
      key: 'DateYouthProtectionTraining',
      isRequired: (rootState) => {
        if (isYouth(rootState)) return false;
        return getYAKey(rootState, 'RequireDateYouthProtectionTraining');
      },
      skip: (rootState) => {
        if (isYouth(rootState)) return true;
        return !getYAKey(rootState, 'ShowDateYouthProtectionTraining');
      },
      validatejs: {
        DateYouthProtectionTraining: {
          presence: {message: '^' + M.REQUIRED},
          datetime: {
            latest: TODAY.format(DATE_PICKER_FORMAT),
            earliest: MIN_DATE.format(DATE_PICKER_FORMAT),
            notValid: `^${M.INVALID_DATE}`,
            tooEarly: `^${M.TOO_EARLY}`,
            tooLate: `^${M.TOO_LATE}`
          }
        }
      }
    },
    DateBalooTraining: {
      key: 'DateBalooTraining',
      isRequired: (rootState) => {
        if (isYouth(rootState)) return false;
        return getYAKey(rootState, 'RequireDateBalooTraining');
      },
      skip: (rootState) => {
        if (isYouth(rootState)) return true;
        return !getYAKey(rootState, 'ShowDateBalooTraining');
      },
      validatejs: {
        DateBalooTraining: {
          presence: {message: '^' + M.REQUIRED},
          datetime: {
            latest: TODAY.format(DATE_PICKER_FORMAT),
            earliest: MIN_DATE.format(DATE_PICKER_FORMAT),
            notValid: `^${M.INVALID_DATE}`,
            tooEarly: `^${M.TOO_EARLY}`,
            tooLate: `^${M.TOO_LATE}`
          }
        }
      }
    },
    DateBasicTrainingForPosition: {
      key: 'DateBasicTrainingForPosition',
      isRequired: (rootState) => {
        if (isYouth(rootState)) return false;
        return getYAKey(rootState, 'RequireDateBasicTrainingForPosition');
      },
      skip: (rootState) => {
        if (isYouth(rootState)) return true;
        return !getYAKey(rootState, 'ShowDateBasicTrainingForPosition');
      },
      validatejs: {
        DateBasicTrainingForPosition: {
          presence: {message: '^' + M.REQUIRED},
          datetime: {
            latest: TODAY.format(DATE_PICKER_FORMAT),
            earliest: MIN_DATE.format(DATE_PICKER_FORMAT),
            notValid: `^${M.INVALID_DATE}`,
            tooEarly: `^${M.TOO_EARLY}`,
            tooLate: `^${M.TOO_LATE}`
          }
        }
      }
    },
    AllowPhoto: {
      key: 'AllowPhoto',
      skip: (rootState) => !getYAKey(rootState, 'ShowAllowPhotography')
    },
    Chapter: {
      key: 'Chapter',
      validatejs: {
        Chapter: {
          length: {
            maximum: 50,
            tooLong: MAX_LENGTH(50)
          }
        }
      }
    },
    HasDietaryRestrictions: {
      key: 'HasDietaryRestrictions',
      skip: (rootState) => !getYAKey(rootState, 'ShowAllergiesAndDietary')
    },
    HasSeriousAllergies: {
      key: 'HasSeriousAllergies',
      skip: (rootState) => !getYAKey(rootState, 'ShowAllergiesAndDietary')
    },
    MI: {
      key: 'MI',
      validatejs: {
        MI: {
          length: {
            maximum: 2,
            tooLong: MAX_LENGTH(2)
          }
        }
      }
    },
    Suffix: {
      key: 'Suffix',
      validatejs: {
        Suffix: {
          length: {
            maximum: 6,
            tooLong: MAX_LENGTH(6)
          }
        }
      }
    },
    OALodge: {
      key: 'OALodge',
      validatejs: {
        OALodge: {
          length: {
            maximum: 50,
            tooLong: MAX_LENGTH(50)
          }
        }
      }
    },
    OAPosition: {
      key: 'OAPosition',
      validatejs: {
        OAPosition: {
          length: {
            maximum: 50,
            tooLong: MAX_LENGTH(50)
          }
        }
      }
    },
    OAStatusID: {
      key: 'OAStatusID',
      defaultValue: () => {
        return 1;
      },
      options: {
        values: (rootState) => [...getValues(rootState, 'OAStatusTypes')],
        valueKey: (v) => v.ID,
        labelKey: 'Name',
        emptyValue: 1
      },
    },
    ScoutTypeID: {
      key: 'ScoutTypeID',
      defaultValue: (rootState) => {
        const {Group} = rootState.cacheZero.options as Options;
        if (!Group) {
          // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
          // captureTentarooError(new Error("Group not available when configuring PersonForm validation rules"));
          return undefined;
        }
        const AllowNonScouterParticipants = getAllowNonScouterParticipants(rootState);

        const GroupRosterPerson = getPerson(rootState);
        let oldScoutTypeID = 1;
        if (GroupRosterPerson) oldScoutTypeID = GroupRosterPerson.ScoutTypeID;

        const isScoutShown = ShowIsScoutField(
          Group.GroupTypeID,
          AllowNonScouterParticipants,
          oldScoutTypeID,
          isYouth(rootState)
        );

        if (!isScoutShown) {
          if (isYouth(rootState)) {
            //If ScoutTypeID field is hidden, set fields to correct values based on current Group settings.
            let newScoutTypeID: number = 2;
            if (AllowNonScouterParticipants) {
              //If hidden and AllowNonScouter, then GroupTypeID == 1.
              //Pull UnitTypeID from Group to set ScoutTypeID.
              if (Group.UnitTypeID === 2) {
                newScoutTypeID = 3;
              } else {
                newScoutTypeID = 2;
              }
            } else {
              //Pull UnitTypeID from the appropriate place.
              let UnitTypeID;
              if (Group.GroupTypeID === 1) {
                UnitTypeID = Group.UnitTypeID;
              } else {
                const ActiveForm = getActiveForm(rootState);
                UnitTypeID = unitDefaultValue(rootState, isYouth, getActiveForm);
                if (ActiveForm.UnitTypeID) UnitTypeID = ActiveForm.UnitTypeID;
              }
              //Get new type.
              if (UnitTypeID === 2) {
                newScoutTypeID = 3;
              } else {
                newScoutTypeID = 2;
              }
            }
            return newScoutTypeID;
          } else {
            return 2;
          }
        }
        return 1;
      },
      options: {
        values: (rootState) => {
          if (isYouth(rootState)) return [...getValues(rootState, 'ScoutTypes')];
          return [...getValues(rootState, 'LeaderScoutTypes')];
        },
        valueKey: (v) => v.ID,
        labelKey: 'Name',
        emptyValue: 1
      },
      localDependants: ['UnitTypeID', 'Rank', 'Unit'],
      customValidate: scoutCustomValidate,
      validatejs: {
        ScoutTypeID: {
          presence: {message: '^' + M.REQUIRED},
          numericality : {
            notGreaterThan: '^' + M.REQUIRED,
            greaterThan: 1
          }
        }
      }
    },
    SpecialNeeds: {
      key: 'SpecialNeeds',
      skip: (rootState) => !getYAKey(rootState, 'ShowSpecialNeedsNotes'),
      validatejs: {
        SpecialNeeds: {
          length: {
            maximum: 2048,
            tooLong: MAX_LENGTH(2048)
          }
        }
      }
    },
    UnitTypeID: {
      key: 'UnitTypeID',
      options: {
        values: (rootState) => getValues(rootState, 'UnitTypes'),
        valueKey: (v) => v.ID,
        labelKey: 'Name'
      },
      defaultValue: (rootState) => unitDefaultValue(rootState, isYouth, getActiveForm),
      customValidate: (rootState) => {
        const {ScoutTypeID, UnitTypeID} = getActiveForm(rootState);
        if (isYouth(rootState) && ScoutTypeID === 3 && UnitTypeID !== 2 && ShowScoutField(rootState) && ShowUnit(rootState)) {
          return MP.PACK_REQUIRED;
        }
        return undefined;
      },
      validatejs: {
        UnitTypeID: {
          presence: {message: '^' + M.REQUIRED}
        }
      }
    },
    Unit: {
      key: 'Unit',
      defaultValue: (rootState) => {
        const {Unit} = getActiveForm(rootState);
        if (Unit !== undefined) return Unit;
        return '';
      },
      isRequired: (rootState) => {
        return ShowUnit(rootState);
      },
      skip: (rootState) => {
        return !ShowUnit(rootState);
      },
      integerOnly: true,
      validatejs: {
        Unit: {
          presence: {message: '^' + M.REQUIRED},
          length: {
            maximum: 10,
            tooLong: MAX_LENGTH(10)
          }
        }
      }
    },
    CouncilIDi: {
      key: 'CouncilIDi',
      defaultValue: CouncilIDiDefaultValue,
      isRequired: () => true,
      skip: (rootState) => {
        return !ShowUnit(rootState);
      },
      options: {
        values: (rootState) => [{IDi: 0, Name: ""}, ...getValues(rootState, 'Councils')],
        valueKey: (v) => v.IDi,
        labelKey: 'Name',
        emptyValue: 0,
      },
      validatejs: {
        CouncilIDi: {
          presence: {message: '^' + M.REQUIRED},
          numericality : {
            notGreaterThan: SELECT_NOT_GREATER_THAN,
            greaterThan: 0,
          },
        },
      },
      localDependants: ['DistrictIDi']
    },
    DistrictIDi: {
      key: 'DistrictIDi',
      isRequired: (rootState) => isDistrictIDiRequired(rootState),
      options: {
        values: (rootState) => {
          const values = filteredDistrictSelector(rootState);

          return values || [];
        },
        valueKey: (v) => v.IDi,
        labelKey: 'Name',
        emptyValue: 1,
      },
      defaultValue: () => 1,
      validatejs: {
        DistrictIDi: {
          presence: {message: '^' + M.REQUIRED},
          numericality : {
            notGreaterThan: SELECT_NOT_GREATER_THAN,
            greaterThan: 1,
          },
        }
      }
    }
  };
};

const unitDefaultValue = (
  rootState: ApplicationState,
  isYouth: (rootState: ApplicationState) => boolean,
  getActiveForm: (rootState: ApplicationState) => any, // @todo: should not be any... should be all the form values
) => {
  if (isYouth(rootState)) {
    const {ScoutTypeID} = getActiveForm(rootState);
    if (ScoutTypeID !== undefined) {
      if (ScoutTypeID === 2) return 1;
      else if (ScoutTypeID === 3) return 2;
    }
  }
  return 1;
};
