import { IValidator, FormDefinition } from "./validation";
import { SUFFIX } from './actions';
import { apiValidationReducerCreator } from "../../../../store/Validation/reducerCreator";
import { createSelector } from "reselect";
import * as Actions from './actions';
import { ClearAllAdminEventsCache } from "../../CacheOne/actions";
import { ApplicationState } from "../../../../store";
import { AdminEventsEmailMessage } from "../../../../models/api/adminEventsCacheOne";
import { sortByName } from "../../../CacheZero";
import { listSelector } from "../../../../utils/reselectHelper";
import { Reducer } from "redux";
import { isActionType } from "../../../../utils/StrongActions";
import { setDefaults } from "../../../../utils/validator";
import {ClearAllCache} from "../../../App/actions";

export interface MessageCenterHomeState {
  ActiveForm: MessageCenterHomeActiveForm;
  ValidationRules: IValidator;
}

export interface MessageCenterHomeActiveForm {
  ShowDeleted?: boolean;
  FilterText?: string;
}

const messageCenterShowDeletedSelector = (state: ApplicationState) => !!state.adminEvents.messageCenter.home.ActiveForm.ShowDeleted;
const messageCenterFilterTextSelector = (state: ApplicationState) => state.adminEvents.messageCenter.home.ActiveForm.FilterText;

const _messagesSelector = (state: ApplicationState) => state.adminEvents.cacheOne.EventsEmailMessages || [];
export const messagesSelector = listSelector(_messagesSelector);

export const makeActiveMessagesSelector = () => {
  return createSelector(
    [messagesSelector],
    (messages: AdminEventsEmailMessage[]) => {
      if (messages) {
        return messages.filter((messages) => !messages.Inactive).sort(sortByName);
      }
      return [];
    }
  );
};

export const makeFilteredMessagesSelector = () => {
  return createSelector(
    [messagesSelector, messageCenterShowDeletedSelector, messageCenterFilterTextSelector],
    (messages: AdminEventsEmailMessage[], showDeleted: boolean, filterText: string) => {
      if (!messages) return [];
      const result = messages
            .filter((p) => {
              let passed = false;

              // filter based on deleted
              if (showDeleted || !p.Inactive) {
                if (!filterText) {
                  passed = true;
                } else {
                  const f = filterText.toLowerCase();
                  if (p.Name.toLowerCase().includes(f)) {
                    passed = true;
                  }
                }
              }
              return passed;
            });
      return result.sort(sortByName);
    }
  );
};

const getInitialState: () => MessageCenterHomeState = () => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition},
});

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const MessageCenterHomeReducer: Reducer<MessageCenterHomeState> = (s, action: any) => {
  const state = checkApiValidation(s, action);
  if (isActionType(action, ClearAllAdminEventsCache) || isActionType(action, ClearAllCache)) {
    return setDefaults(action.rootState, getInitialState());
  } else if (isActionType(action, Actions.MessageCenterHomeReset)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        FilterText: '',
      },
    };
  }
  return state || setDefaults(action.rootState, getInitialState());
};

export default MessageCenterHomeReducer;