import * as React from 'react';
import { MainRightSideBar, SideBarContent } from '../../Layouts';
import Contact from './Contact';

export interface RightSidebarProps {
  hideEarly?: boolean;
};

const RightSidebar: React.SFC<RightSidebarProps> = (): React.ReactElement<RightSidebarProps> => (
  <MainRightSideBar desktop hideEarly>
    <Contact component={<SideBarContent/>}/>
  </MainRightSideBar>
);

export default RightSidebar;
