import { CMSPage, CMSSiteMenuItem, CMSPageMenuItem } from "../../../models/api/adminCMSCacheOne";
import { GetPage, ClearAdminCMSCacheTwoPage } from "./actions";
import { APISuccess } from "../../Validation/actionCreator";
import { ClearAdminCMSSiteCacheBelowOne, ClearAllAdminCMSSiteCacheButOptions, ClearAllAdminCMSSiteCache, ADMIN_CMS_MODULE_SUFFIX } from "../CacheOne/actions";
import { CMSSocial } from "../../../models/api/adminCMSCacheOne";
import { CMSPageGalleryImage } from "../../../models/api/adminCMSCacheTwoPage";
import { ClearAllCache, SetCacheAction } from "../../../store/App/actions";
import { EditPageUpdateSiteMenuItemSubmitActions, EditPageDeleteGalleryImageSubmitActions, EditPageAddTopGalleryImageSubmitActions, EditPageAddBottomGalleryImageSubmitActions, EditPageEditGalleryImageSubmitActions, GetDefaultForPrimaryEventPageSubmitActions, UpdatePageSubmitActions } from "../Pages/Page/Form/actions";
import { DuplicatePageSubmitActions } from "../Pages/Modals/DuplicatePageForm/actions";
import { AddPageSubmitActions, ClientSubmitForm } from "../Pages/Modals/NewPage/actions";
import { convertObjToMoment } from "../../../utils";
import { shouldSkipResponseForCache } from "../../Validation/reducerCreator";
import { Reducer } from "redux";
import { Action, isActionType } from "../../../utils/StrongActions";

export interface AdminCMSSiteCacheTwoPageState {
  CMSPage?: CMSPage;
  CMSPageSiteMenuItems?: CMSSiteMenuItem[];
  CMSPageMenuItems?: CMSPageMenuItem[];
  CMSPageResources?: number[];
  CMSPageContacts?: number[];
  CMSPageSocials?: CMSSocial[];
  CMSPageGallery1?: CMSPageGalleryImage[];
  CMSPageGallery2?: CMSPageGalleryImage[];
  CMSPagesRow?: CMSPage;
};

const setAdminCMSSiteCacheTwoPageData = (data): AdminCMSSiteCacheTwoPageState => {
  return {
    CMSPage: data.CMSPage,
    CMSPageSiteMenuItems: data.CMSPageSiteMenuItems,
    CMSPageMenuItems: data.CMSPageMenuItems,
    CMSPageResources: data.CMSPageResources,
    CMSPageContacts: data.CMSPageContacts,
    CMSPageSocials: data.CMSPageSocials,
    CMSPageGallery1: data.CMSPageGallery1,
    CMSPageGallery2: data.CMSPageGallery2,
    CMSPagesRow: data.CMSPagesRow,
  };
};

const handleResponse = (innerResponse) => {
  const newState: AdminCMSSiteCacheTwoPageState = setAdminCMSSiteCacheTwoPageData(innerResponse);
  return newState;
};

export const updateImageInGallery = (gallery: CMSPageGalleryImage[] | undefined, image: CMSPageGalleryImage, tempId?: number, handleNewImage?: boolean, preserveOrd?: boolean) => {
  if (!gallery) return [];
  let found = false;
  const result = gallery.map((item) => {
    if (tempId ? (item.tempId === tempId) : (item.ID === image.ID)) {
      const itemWithMoment = convertObjToMoment(image, ['PublishDate', 'ExpireDate']);
      found = true;
      return {
        ...image,
        PublishDate: itemWithMoment.PublishDate,
        ExpireDate: itemWithMoment.ExpireDate,
        loading: false,
        uploading: false,
        Ord: preserveOrd ? item.Ord : image.Ord,
      };
    }
    return item;
  });

  if (handleNewImage && !found) {
    // clear dataUrl in image
    if (image.dataUrl) delete image.dataUrl;
    result.push(image);
  }
  return result;
};
const AdminCMSSiteCacheTwoPage: Reducer<AdminCMSSiteCacheTwoPageState> = (s, act: Action) => {
  const state = {...s};
  if (act.type === GetPage.successType || act.type === DuplicatePageSubmitActions.successType || (isActionType(act, SetCacheAction) && act.actions.requestType === UpdatePageSubmitActions.requestType) || act.type === AddPageSubmitActions.successType) {
    const action = <APISuccess> act;
    if (shouldSkipResponseForCache(act)) return state;

    const res = action.response.response;
    if (res.CMSPage) {
      return handleResponse(action.response.response);
    }

    return state;
  } else if (act.type === EditPageUpdateSiteMenuItemSubmitActions.successType || act.type === EditPageDeleteGalleryImageSubmitActions.successType || act.type === EditPageDeleteGalleryImageSubmitActions.failureType || act.type === EditPageAddTopGalleryImageSubmitActions.successType || act.type === EditPageAddBottomGalleryImageSubmitActions.successType || act.type === EditPageEditGalleryImageSubmitActions.successType || (isActionType(act, SetCacheAction) && (act.actions.requestType === EditPageEditGalleryImageSubmitActions.requestType || act.actions.requestType === EditPageUpdateSiteMenuItemSubmitActions.requestType))) {
    if (shouldSkipResponseForCache(act)) return state;
    const action = <APISuccess> act;
    const res = action.response.response;
    const image = res.CMSPageImage as CMSPageGalleryImage;    // handle gallery image conflict case
    if (image) {
      // - EditPageEditGalleryImageSubmitActions,
      // - EditPageAddTopGalleryImageSubmitActions
      // - EditPageAddBottomGalleryImageSubmitActions
      // - EditPageDeleteGalleryImageSubmitActions - No SetCacheAction for sequentials so watching for failure action to handle 409 case.
      return {
        ...state,
        CMSPageGallery1: updateImageInGallery(state.CMSPageGallery1, image, undefined, true, act.type === EditPageEditGalleryImageSubmitActions.successType),
        CMSPageGallery2: updateImageInGallery(state.CMSPageGallery2, image, undefined, true, act.type === EditPageEditGalleryImageSubmitActions.successType),
      };
    } else if (typeof res === "object" && 'CMSPageSiteMenuItems' in res) {
      // CMSPageSiteMenuItems could be null on SiteMenuItem 409 under EditPage, but we want to propagate that to state tree, too
      return {
        ...state,
        CMSPageSiteMenuItems: res.CMSPageSiteMenuItems,
      };
    }

    return state;
  } else if (act.type === GetDefaultForPrimaryEventPageSubmitActions.successType) {
    const action = <APISuccess>act;
    const res = action.response.xhr.response;

    return {
      ...state,
      CMSPage:  {
        ...state.CMSPage,
        IsPrimaryEventPage: res.IsPrimaryEventPage,
      },
    };
  } else if (isActionType(act, ClientSubmitForm)) {
    return {
      ...state,
      CMSPage: {...act.data},
    };
  } else if (isActionType(act, ClearAdminCMSSiteCacheBelowOne) || isActionType(act, ClearAdminCMSCacheTwoPage) || isActionType(act, ClearAllAdminCMSSiteCacheButOptions) || isActionType(act, ClearAllAdminCMSSiteCache) || isActionType(act, ClearAllCache)) {
    return {};
  }
  return state || {};
};
  
export default AdminCMSSiteCacheTwoPage;