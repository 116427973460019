import { CacheThreeFacilitiesContext } from "@tentaroo/shared";

import { SaveState } from "../../store/Rollback/actions";
import { ToggleNotFound } from "../../store/App/actions";
import { GetFacilityTripData } from "../../store/CacheThreeFacilities/actions";
import { TripInitialize } from "../../store/Facilities/Trip/Form/actions";
import { getGroupID } from "../helpers/endUserPageHelper";
import { isIdFromUrlValid, isIdValid } from '../urlHelper';
import { captureTentarooError } from '../dataHelper';
import { reduxStoreService } from "../../store/service";
import type EndUserCacheManager from '../cacheManagers/endUserCacheManager';
import { LoadEndUserCacheThreeFacilityParams } from "./helpers/models";
import {isCacheThreeFacilitiesPopulated, isCacheZeroPopulated, isEndUserCacheOnePopulated, isEndUserCacheTwoFacilitiesPopulated} from "../cachePopulatedCheckers/endUser";

export const ensureCacheThreeFacilities = (
  params: LoadEndUserCacheThreeFacilityParams & { cacheManager: EndUserCacheManager },
): boolean => {
  const { props, isEdit, isStateNavigated, cacheManager, context } = params;

  const groupID = getGroupID(props);
  if (
    !isIdValid(groupID) ||
    !isIdFromUrlValid(props.params.locId)
  ) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const locId = Number(props.params.locId);

  const rootState = reduxStoreService().getState();

  const { cacheTwoFacilities, cacheThreeFacilities, cacheOne, cacheZero } = rootState;

  let requestedData = false;
  if (isEdit) {
    if (!isIdFromUrlValid(props.params.tripId)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }
    const tripId = Number(props.params.tripId);

    if (isCacheThreeFacilitiesPopulated(cacheThreeFacilities)) {
      if (isStateNavigated) {
        // TODO: Refactor this to `updateForm` like admin modules
        if (context === CacheThreeFacilitiesContext.TRIP_FORM) {
          reduxStoreService().dispatch(new TripInitialize());
        }
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      if (!isCacheZeroPopulated(cacheZero)) {
        reduxStoreService().dispatch(GetFacilityTripData.request({
          GroupIDi: groupID,
          GroupTS: 0,
          FacilityLocationID: locId,
          FacilityTripID: tripId,
          GetOptions: true,
          GetGroupData: true,
          GetFacilityLocationData: true,
          GetFacilityTripDataParam: true,
        }));

        requestedData = true;
      } else if (
        isCacheZeroPopulated(cacheZero) &&
        (!cacheZero.options!.Group || !isEndUserCacheOnePopulated(cacheOne))
      ) {
        reduxStoreService().dispatch(GetFacilityTripData.request({
          GroupIDi: groupID,
          GroupTS: 0,
          FacilityLocationID: locId,
          FacilityTripID: tripId,
          GetOptions: false,
          GetGroupData: true,
          GetFacilityLocationData: true,
          GetFacilityTripDataParam: true,
        }));

        requestedData = true;
      } else {
        if (!cacheZero.options!.Group) {
          captureTentarooError(new Error("Group not available when dispatching GetFacilityTripData in cacheThreeFacilitiesLoader"));
          return false;
        }
        reduxStoreService().dispatch(GetFacilityTripData.request({
          GroupIDi: cacheZero.options!.Group.IDi,
          GroupTS: cacheZero.options!.Group.TS,
          FacilityLocationID: locId,
          FacilityTripID: tripId,
          GetOptions: false,
          GetGroupData: false,
          GetFacilityLocationData: !isEndUserCacheTwoFacilitiesPopulated(cacheTwoFacilities),
          GetFacilityTripDataParam: true,
        }));

        requestedData = true;
      }
    }
  } else if (isStateNavigated) {
    props.params.id = props.params.locId;
    requestedData = cacheManager.loadCacheTwoFacilities({
      props,
      isStateNavigated,
    });

    if (!requestedData) {
      reduxStoreService().dispatch(new TripInitialize());
      reduxStoreService().dispatch(new SaveState());
    }
  }

  return requestedData;
};