import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import * as NM from '../../../../../../constants/messages/numbersWizard';
import * as GM from '../../../../../../constants/messages/generic';
import {chainMerge} from "../../../../../../utils/validator";
import { CacheFourEventsNumbersCore, makeTotalNumParticipantsSelector } from '../../../../../CacheFourEventsNumbers';
import { createCampsiteFormFilter } from '../../../../../../utils/eventsHelper';
import { makeDeletedPrefixItemMapper, toNumber } from '../../../../../../utils/dataHelper';
import { SelectValidator, Validator } from "../../../../../../utils/validator/models";
import type { ApplicationState } from "../../../../..";

export interface IValidator {
  CampsiteRank1: SelectValidator;
  CampsiteRank2: SelectValidator;
  CampsiteRank3: SelectValidator;
  CampsiteOptions: Validator;
  NumAdultsTotal: Validator;
  NumYouthTotal: Validator;
  TotalNumberAssigned: Validator;
}

const getCampsiteRankingOptionValues = (rootState: ApplicationState, isCampsiteRanking: boolean, key: string, id?: number) => {
  if (!rootState.cacheFourEventsNumbers.EventRegistrationCampsiteRankingOptions) return [];
  
  return rootState.cacheFourEventsNumbers.EventRegistrationCampsiteRankingOptions
  .filter((option) => {
    let selected = false;
    if (isCampsiteRanking) {
      selected = (option.Inactive && option.IDi === rootState.events.event.register.numbers.campsite.ActiveForm[key]);
    } else {
      const campsite = rootState.events.event.register.numbers.campsite.EventRegistrationCampsiteAssignments.find((form) => !!form.ValidationRules.CampsiteIDi.extraInfo && form.ValidationRules.CampsiteIDi.extraInfo.id === id);

      selected = !!campsite && (option.Inactive && option.IDi === campsite.ActiveForm.CampsiteIDi);
    }
    return !option.Inactive || selected;
  })
  .map(makeDeletedPrefixItemMapper('Name'));
};

const campsiteRequired = (rootState: ApplicationState) => {
  const {cacheTwoEvents, user} = rootState;

  if (!cacheTwoEvents.EventTypeRegistrationSettings) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    return false;
  }
  return cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.RequireCampsiteRanking &&
    !user.user.str_permissions.hasAdminAccess;
};

const campsiteSkip = (rootState: ApplicationState) => {
  const {cacheFourEventsNumbers} = rootState;

  if (!cacheFourEventsNumbers.EventRegistrationNumbers) {
    // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
    return false;
  }
  const Campsites = cacheFourEventsNumbers.EventRegistrationNumbers.Campsites;
  return !!Campsites;
};

export const FormDefinition: IValidator = {
  CampsiteOptions: {
    key: 'CampsiteOptions',
    customValidate: (rootState) => {
      const {cacheTwoEvents, cacheFourEventsNumbers, user} = rootState;

      if (!cacheFourEventsNumbers.EventRegistrationCampsiteRankingOptions) {
        // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
        return undefined;
      }
      if (!cacheTwoEvents.EventTypeRegistrationSettings) {
        // TODO: add `captureTentarooError` back when form loading is refactored to follow admin side
        return undefined;
      }
      if (
        !campsiteSkip(rootState) &&
        cacheFourEventsNumbers.EventRegistrationCampsiteRankingOptions.length < 2 &&
        cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.RequireCampsiteRanking &&
        !user.user.str_permissions.hasAdminAccess
      ) {
        return NM.CAMPSITE_CRITICAL;
      }
      return undefined;
    }
  },
  CampsiteRank1: {
    key: 'CampsiteRank1',
    options: {
      values: (rootState) => getCampsiteRankingOptionValues(rootState, true, 'CampsiteRank1'),
      valueKey: (v) => v.IDi,
      labelKey: 'Name',
      emptyValue: 0
    },
    defaultValue: () => {
      return 0;
    },
    isRequired: campsiteRequired,
    skip: campsiteSkip,
    validatejs: {
      CampsiteRank1: {
        presence: {message: '^' + GM.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0
        }
      }
    }
  },
  CampsiteRank2: {
    key: 'CampsiteRank2',
    options: {
      values: (rootState) => getCampsiteRankingOptionValues(rootState, true, 'CampsiteRank2'),
      valueKey: (v) => v.IDi,
      labelKey: 'Name',
      emptyValue: 0
    },
    defaultValue: () => {
      return 0;
    },
    isRequired: campsiteRequired,
    skip: campsiteSkip,
    validatejs: {
      CampsiteRank2: {
        presence: {message: '^' + GM.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0
        }
      }
    }
  },
  CampsiteRank3: {
    key: 'CampsiteRank3',
    options: {
      values: (rootState) => getCampsiteRankingOptionValues(rootState, true, 'CampsiteRank3'),
      valueKey: (v) => v.IDi,
      labelKey: 'Name',
      emptyValue: 0
    },
    defaultValue: () => {
      return 0;
    },
    isRequired: campsiteRequired,
    skip: campsiteSkip,
    validatejs: {
      CampsiteRank3: {
        presence: {message: '^' + GM.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0
        }
      }
    }
  },
  NumAdultsTotal: {
    key: 'NumAdultsTotal',
    isChainRoot: true,
    chainValue: (rootState, values) => {
      let sum: number = 0;

      const assignments = rootState.events.event.register.numbers.campsite.EventRegistrationCampsiteAssignments;
      assignments.filter(createCampsiteFormFilter()).forEach((a) => {
        sum += toNumber(a.ActiveForm.NumAdults);
      });
      return sum;
    },
    chainDependants: ['TotalNumberAssigned'],
  },
  NumYouthTotal: {
    key: 'NumYouthTotal',
    isChainRoot: true,
    chainValue: (rootState, values) => {
      let sum: number = 0;

      const assignments = rootState.events.event.register.numbers.campsite.EventRegistrationCampsiteAssignments;
      assignments.filter(createCampsiteFormFilter()).forEach((a) => {
        sum += toNumber(a.ActiveForm.NumYouth);
      });
      return sum;
    },
    chainDependants: ['TotalNumberAssigned'],
  },
  TotalNumberAssigned: {
    key: 'TotalNumberAssigned',
    chainValue: (rootState, values) => {
      const ActiveForm = rootState.events.event.register.numbers.campsite.ActiveForm;
      const v = chainMerge(ActiveForm, values);
      return toNumber(v.NumYouthTotal) + toNumber(v.NumAdultsTotal);
    },
    customValidate: (rootState) => {
      const ActiveForm = rootState.events.event.register.numbers.campsite.ActiveForm;

      const totalNumParticipantsSelector = makeTotalNumParticipantsSelector();
      const totalNumParticipants = totalNumParticipantsSelector(rootState);

      if ((ActiveForm.NumYouthTotal && ActiveForm.NumYouthTotal > totalNumParticipants.YouthSum) || (ActiveForm.NumAdultsTotal && ActiveForm.NumAdultsTotal > totalNumParticipants.AdultSum)) {
        return NM.CAMPSITE_OVERBOOKED;
      }
    }
  },
};

export interface ICampsiteValidator {
  CampsiteIDi: SelectValidator;
  NumAdults: Validator;
  NumYouth: Validator;
  Inactive: Validator;
}

export const getCampsiteTempId = (rootState: ApplicationState, data?: CacheFourEventsNumbersCore, previousMinID?: number) => {
  let minID = 0;

  if (data) {
    data.EventRegistrationCampsiteAssignments && data.EventRegistrationCampsiteAssignments.forEach((assignment) => {
      if (!assignment.tempID && previousMinID !== undefined) minID = previousMinID;
      if (assignment.tempID && assignment.tempID < minID) minID = assignment.tempID;
    });

    return minID - 1;
  } else {
    rootState.events.event.register.numbers.campsite.EventRegistrationCampsiteAssignments.forEach((assignment) => {
      if (assignment.ActiveForm.tempID && assignment.ActiveForm.tempID < minID) minID = assignment.ActiveForm.tempID;
    });

    return minID - 1;
  }
};

export const createCampsiteIDiValidationRule = (id: number): SelectValidator => {
  return {
    ...CampsiteFormDefinition.CampsiteIDi,
    options: {
      values: (rootState) => getCampsiteRankingOptionValues(rootState, false, "", id),
      valueKey: (v) => v.IDi,
      labelKey: 'Name',
      emptyValue: 0
    },
    customValidate: (rootState) => {
      const campsiteForm = rootState.events.event.register.numbers.campsite.EventRegistrationCampsiteAssignments?.find((form) => form.ActiveForm.tempID === id);
      const campsiteOption = rootState.cacheFourEventsNumbers.EventRegistrationCampsiteRankingOptions?.find((o) => o.IDi === campsiteForm?.ActiveForm.CampsiteIDi);

      if (campsiteOption && campsiteOption.Inactive) {
        return GM.SELECTED_DROPDOWN_ITEM_DELETED;
      }

      return undefined;
    },
    extraInfo: {
      id,
    },
  };
};

export const createCampsiteInactiveValidationRule = (id: number): Validator => {
  return {
    ...CampsiteFormDefinition.Inactive,
    extraInfo: {
      id,
    },
  };
};

export const createCampsiteNumAdultsValidationRule = (id: number): Validator => {
  const shouldSkipNumAdults = (rootState: ApplicationState) => {
    const assignment = rootState.events.event.register.numbers.campsite.EventRegistrationCampsiteAssignments.find((a) => a.ActiveForm.tempID === id);

    return !!assignment && (!!assignment.ActiveForm.Inactive || !assignment.ActiveForm.CampsiteIDi);
  };
  return {
    ...CampsiteFormDefinition.NumAdults,
    isRequired: (rootState) => !shouldSkipNumAdults(rootState),
    skip: (rootState) => shouldSkipNumAdults(rootState),
    extraInfo: {
      id: id,
    },
  };
};

export const createCampsiteNumYouthValidationRule = (id: number): Validator => {
  const shouldSkipNumYouth = (rootState) => {
    const assignment = rootState.events.event.register.numbers.campsite.EventRegistrationCampsiteAssignments.find((a) => a.ActiveForm.tempID === id);

    return !!assignment && (!!assignment.ActiveForm.Inactive || !assignment.ActiveForm.CampsiteIDi);
  };
  return {
    ...CampsiteFormDefinition.NumYouth,
    isRequired: (rootState) => !shouldSkipNumYouth(rootState),
    skip: (rootState) => shouldSkipNumYouth(rootState),
    extraInfo: {
      id: id,
    },
  };
};

export const CampsiteFormDefinition: ICampsiteValidator = {
  CampsiteIDi: {
    key: 'CampsiteIDi',
    options: {
      values: () => [],
      valueKey: (v) => v.IDi,
      labelKey: 'Name',
      emptyValue: 0
    },
    chainDependants: ['NumAdultsTotal', 'NumYouthTotal'],
    defaultValue: () => {
      return 0;
    },
  },
  NumAdults: {
    key: 'NumAdults',
    integerOnly: true,
    validatejs: {
      NumAdults: {
        presence: {message: '^' + GM.REQUIRED},
        numericality: {
          notValid: `^${GM.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: GM.MIN_VALUE(0),
        },
      },
    },
    chainDependants: ['NumAdultsTotal'],
  },
  NumYouth: {
    key: 'NumYouth',
    integerOnly: true,
    validatejs: {
      NumYouth: {
        presence: {message: '^' + GM.REQUIRED},
        numericality: {
          notValid: `^${GM.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: GM.MIN_VALUE(0),
        },
      },
    },
    chainDependants: ['NumYouthTotal'],
  },
  Inactive: {
    key: 'Inactive',
    chainDependants: ['NumAdultsTotal', 'NumYouthTotal'],
  },
};