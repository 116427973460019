import isNode from './isNode';

let whichAnimationEvent : string | undefined = undefined;

export default () : string | undefined => {
  if (isNode()) return undefined;
  if (whichAnimationEvent) return whichAnimationEvent;

  var t;
  var el = document.createElement('fakeelement');
  var animations = {
    'animation':'animationend',
    'OAnimation':'oAnimationEnd',
    'MozAnimation':'animationend',
    'WebkitAnimation':'webkitAnimationEnd'
  };

  for (t in animations) {
    if (el.style[t] !== undefined) {
      return whichAnimationEvent = animations[t];
    }
  }
};
