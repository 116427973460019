import { createSelector } from 'reselect';

import { BaseEndUserClass, EventClassGroupAvailable } from "../../models/class";
import {OrderedProduct, Product} from "../../models/product";
import type { ApplicationState } from '..';
import { createSpotsMap, spotsMapper, spotsOnlySelector } from '../../utils/eventsHelper';
import { listSelector, makeSimpleSelector } from '../../utils/reselectHelper';
import type { EventRegistrationCampsiteAssignment, EventRegistrationCampsiteRankingOption, EventRegistrationParticipantType, EventRegistrationSpot } from '../../models/api/cacheFourEvents';
import { makeNoFilterSelector } from '../../utils';
import { availableFilter, classSort } from '../../utils/classesHelper';
import { productAvailableSort, productOrderedSort } from '../CacheFourEventsProducts/reducerHelpers';
import { activeRegisteredFilter } from './uiHelpers';
import { reduxStoreService } from '../service';

export const participantTypesSelector = listSelector(state => state.cacheFourEventsNumbers.EventRegistrationParticipantTypes);
export const eventRegistrationSpotsSelector = listSelector((state: ApplicationState) => state.cacheFourEventsNumbers.EventRegistrationSpots);
export const availableClassesSelector = listSelector(state => state.cacheFourEventsNumbers.EventClassesGroupAvailable);
export const registeredClassesSelector = listSelector(state => state.cacheFourEventsNumbers.EventClassesGroupRegistered);
export const campsiteAssignmentsSelector = listSelector((state: ApplicationState) => state.cacheFourEventsNumbers.EventRegistrationCampsiteAssignments);
export const campsiteRankingOptionsSelector = listSelector((state: ApplicationState) => state.cacheFourEventsNumbers.EventRegistrationCampsiteRankingOptions);

export const makeAssignedCampsiteSelector = (filterInactive?: boolean) => {
  return createSelector(
    [campsiteAssignmentsSelector, campsiteRankingOptionsSelector],
    (EventRegistrationCampsiteAssignments: Array<EventRegistrationCampsiteAssignment>, EventRegistrationCampsiteRankingOptions: Array<EventRegistrationCampsiteRankingOption>) => {
      if (!EventRegistrationCampsiteAssignments) return [];
      return EventRegistrationCampsiteAssignments
        .map((assignment) => {
          const option = EventRegistrationCampsiteRankingOptions.find((o) => assignment.CampsiteIDi === o.IDi);

          return {
            ...assignment,
            Name: option ? option.Name : "",
          };
        })
        .sort((a, b) => {
          if (!a.CampsiteIDi) return 1;
          if (!b.CampsiteIDi) return -1;
          return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase());
        })
        .filter((assignment) => {
          if (filterInactive) return !assignment.Inactive;
          return true;
        })
        .map((assignment) => assignment.Name)
        .filter((assignment) => !!assignment);
    }
  );
};

export const makeEventRegistrationYouthSpotsSelector = () => {
  return createSelector(
    [eventRegistrationSpotsSelector, participantTypesSelector],
    (eventRegistrationSpots: EventRegistrationSpot[], eventRegistrationParticipantTypes: EventRegistrationParticipantType[]) => {
      const map = createSpotsMap(eventRegistrationSpots, eventRegistrationParticipantTypes);

      const result = spotsMapper(map, true);

      return result;
    }
  );
};
export const makeEventRegistrationAdultSpotsSelector = () => {
  return createSelector(
    [eventRegistrationSpotsSelector, participantTypesSelector],
    (eventRegistrationSpots: EventRegistrationSpot[], eventRegistrationParticipantTypes: EventRegistrationParticipantType[]) => {
      const map = createSpotsMap(eventRegistrationSpots, eventRegistrationParticipantTypes);

      const result = spotsMapper(map, false);

      return result;
    }
  );
};

export const makeTotalNumParticipantsSelector = () => {
  return createSelector(
    [spotsOnlySelector, eventRegistrationSpotsSelector, participantTypesSelector],
    (spotsOnly: boolean, eventRegistrationSpots: EventRegistrationSpot[], eventRegistrationParticipantTypes: EventRegistrationParticipantType[]) => {
      let YouthSum = 0;
      let AdultSum = 0;

      if (spotsOnly) {
        eventRegistrationParticipantTypes.forEach((pt) => {
          if (pt.IsYouth) YouthSum += pt.EstimateTotal;
          else AdultSum += pt.EstimateTotal;
        });
      } else {
        eventRegistrationSpots.forEach((spot) => {
          const participant = eventRegistrationParticipantTypes.find((pt) => pt.ID === spot.PTID);
          if (participant) {
            if (participant.IsYouth) YouthSum += spot.NumSpots;
            else AdultSum += spot.NumSpots;
          }
        });
      }

      return {YouthSum, AdultSum};
    },
  );
};

export const makeRegisteredClassesFilter = makeNoFilterSelector(
  activeRegisteredFilter,
  registeredClassesSelector,
  classSort
);

export const noConflictAvailableFilter = (c: BaseEndUserClass) => {
  const rootState = reduxStoreService().getState();
  
  return (
    c.conflictStatus === "available" &&
    !!rootState.cacheTwoEvents.EventTypeRegistrationSettings &&
    rootState.events.event.register.numbers.spots.ActiveForm.NumCampersYouth !== undefined &&
    rootState.events.event.register.numbers.spots.ActiveForm.NumCampersAdult !== undefined &&
    availableFilter(
        c,
        rootState.cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.ShowFullClassesGroup,
        rootState.events.event.register.numbers.spots.ActiveForm.NumCampersYouth,
        rootState.events.event.register.numbers.spots.ActiveForm.NumCampersAdult,
        false
    )
  );
};
export const conflictAvailableFilter = (c: BaseEndUserClass) => {
  const rootState = reduxStoreService().getState();
  
  return (
    c.conflictStatus === "conflict" &&
    !!rootState.cacheTwoEvents.EventTypeRegistrationSettings &&
    rootState.events.event.register.numbers.spots.ActiveForm.NumCampersYouth !== undefined &&
    rootState.events.event.register.numbers.spots.ActiveForm.NumCampersAdult !== undefined &&
    availableFilter(
      c,
      rootState.cacheTwoEvents.EventTypeRegistrationSettings.NumbersRegistrationSettings.ShowFullClassesGroup,
      rootState.events.event.register.numbers.spots.ActiveForm.NumCampersYouth,
      rootState.events.event.register.numbers.spots.ActiveForm.NumCampersAdult,
      false
    )
  );
};

export const makeAvailableClassesNoFilter = makeNoFilterSelector(
  noConflictAvailableFilter,
  availableClassesSelector,
  classSort
);

export const makeConflictingClassesNoFilter = makeNoFilterSelector(
  conflictAvailableFilter,
  availableClassesSelector,
  classSort
);

export const makeAvailableClassesFilter = makeSimpleSelector(
  noConflictAvailableFilter,
  (c: EventClassGroupAvailable, f: string) => c.Name.toLowerCase().includes(f),
  availableClassesSelector,
  state => state.app.searchFilter, // @todo: this should be defaultFilterSelector, but I had a weird bug that makes it not work??
  classSort
);

export const makeConflictingClassesFilter = makeSimpleSelector(
  conflictAvailableFilter,
  (c: EventClassGroupAvailable, f: string) => c.Name.toLowerCase().includes(f),
  availableClassesSelector,
  state => state.app.searchFilter, // @todo: this should be defaultFilterSelector, but I had a weird bug that makes it not work??
  classSort
);

export const makeYouthParticipantTypeSelector = makeNoFilterSelector((t: EventRegistrationParticipantType) => t.IsYouth, participantTypesSelector);
export const makeAdultParticipantTypeSelector = makeNoFilterSelector((t: EventRegistrationParticipantType) => !t.IsYouth, participantTypesSelector);

const eventProductsSelector = listSelector(state => state.cacheFourEventsNumbers.EventProductsAvailable);
const eventProductsOrderedSelector = listSelector(state => state.cacheFourEventsNumbers.EventProductsOrdered);

const availableProductFilter = (p: Product) => !p.isAdded;
const addedProductFilter = (p: OrderedProduct) => !!p.IsNewlyAdding && !p.Inactive;
const previousProductFilter = (p: OrderedProduct) => !p.IsNewlyAdding && !p.Inactive;

export const makeAvailableNumbersProductsFilter = makeNoFilterSelector(
  availableProductFilter,
  eventProductsSelector,
  productAvailableSort
);

export const makeAvailableNumbersProductsSearchFilter = makeSimpleSelector(
  availableProductFilter,
  (p: Product, f: string) => p.Name.toLowerCase().includes(f),
  eventProductsSelector,
  state => state.app.searchFilter, // @todo: this should be defaultFilterSelector, but I had a weird bug that makes it not work??
  productAvailableSort
);

export const makeAddedNumbersProductsFilter = makeNoFilterSelector(
  addedProductFilter,
  eventProductsOrderedSelector,
  productOrderedSort
);

export const makePreviousNumbersProductsFilter = makeNoFilterSelector(
  previousProductFilter,
  eventProductsOrderedSelector,
  productOrderedSort
);