import {SaveState} from "../../store/Rollback/actions";
import { reduxStoreService } from "../../store/service";
import { LoadCancelReservationParams } from "./helpers/models";
import {isCacheFourFacilitiesPopulated} from "../cachePopulatedCheckers/endUser";

export const ensureCancelReservation = (
  params: LoadCancelReservationParams,
) => {
  const {actions, ReservationID, InCart, isStateNavigated} = params;

  const rootState = reduxStoreService().getState();
  const {
    cacheFourFacilities,
  } = rootState;
  if (isCacheFourFacilitiesPopulated(cacheFourFacilities)) {
    if (!cacheFourFacilities.didFail && isStateNavigated) {
      reduxStoreService().dispatch(new SaveState());
    }
  } else if (!isCacheFourFacilitiesPopulated(cacheFourFacilities)) {
    if (ReservationID) {
      // its cart, checkout or /registration
      actions.cancelReservationLoad(ReservationID, InCart !== -1);
    }
  }
};
