import { createSelector } from "reselect";

import type { ApplicationState } from "../../..";
import { CMSPage } from "../../../../models/api/adminCMSCacheOne";
import { compareDataByRowNum } from "../../../../utils/dateHelper";
import { pageFilterMatch } from "../Modals/SelectPage";

const filterSelector = (state: ApplicationState) => state.adminCMSSite.pages.home.ActiveForm.FilterText;

const pagesSelector = (state: ApplicationState) => state.adminCMSSite.cacheOne.CMSPages;

export const makeFilteredPagesSelector = () => {
  return createSelector(
    [pagesSelector, filterSelector],
    (pages: Array<CMSPage>, filter: string) => {
      let result: any[] = [];
      if (!pages || pages.length === 0) return [null];
      if (!filter || filter === '') {
        result = pages.sort(compareDataByRowNum).map((p) => p);
      } else {
        result = pages.filter(p => pageFilterMatch(p, filter)).sort(compareDataByRowNum);
      }
      result.push(null);
      return result;
    }
  );
};

export const makeActivePagesSelector = () => {
  return createSelector(
    [pagesSelector],
    (pages: Array<CMSPage>) => {
      if (!pages) return [];
      return pages;
    });
};