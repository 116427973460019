import {
  ApiSubmitActionsNumbersClasses,
  ClearError,
  EventRegisterNumbersClassesAdd, EventRegisterNumbersClassesRemove,
  EventRegisterNumbersClassesShowError
} from './actions';
import {eventNumbersCalc} from '../../../../../../constants/urls';
import {makeRequestEpic} from '../../../../../Validation/epicCreator';
import {Observable} from "rxjs/Rx";
import {validateAllNumbersPages} from "../../../../../CacheFourEventsNumbers/validationMaster";
import { isActionType } from '../../../../../../utils/StrongActions';

export const numbersClassesNext = makeRequestEpic(
  ApiSubmitActionsNumbersClasses,
  eventNumbersCalc,
  undefined,
  true
);

export const numbersAddClassValidateEpic = action$ => (
  action$.filter(action => isActionType(action, EventRegisterNumbersClassesAdd) || isActionType(action, EventRegisterNumbersClassesRemove))
    .mergeMap((action) => {
      const results = validateAllNumbersPages();
      if (results.classes && results.classes.warnings.length > 0) {
        return Observable.of(new EventRegisterNumbersClassesShowError(results.classes.warnings[0].message, results.classes.error));
      } else {
        return Observable.of(new ClearError());
      }
    })
);
