import { RouteComponentProps, withRouter } from "react-router";
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { ApplicationState } from "../../../../../../store";
import { Modal, ModalHeader, ModalContent, Button, ModalActions } from "../../../../../../components/Elements";
import { actionCreators, EventTypeFormModalNamespace as namespace } from "../../../../../../store/AdminEvents/EventTypes/EventType/Form/actions";
import { actionCreators as cacheOneActionCreators } from "../../../../../../store/AdminEvents/CacheOne/actions";
import { actionCreators as appActionCreators } from "../../../../../../store/App/actions";
import { actionCreators as rollbackActionCreators } from "../../../../../../store/Rollback/actions";
import '../../../../../../styles/pages/admin-events/event-types/modal/event-type-shared-settings/index.scss';
import { makeEventTypeSharedSettingsSectionSelector } from "../../../../../../store/AdminEvents/EventTypes/EventType/Form";
import EventTypeForm from "../../EventType/Form";
import { shouldBlockActions } from "../../../../../../utils/cacheLoaders/helpers/blockers";
import { generateDOMId } from "../../../../../../utils/cypressHelper";
import { connect } from "react-redux";
import { getMergeProps } from "../../../../../../utils/reduxHelper";
import {WithInertAttribute} from "../../../../../Elements/WithInert";

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

type Props = WithInertAttribute<{}>

@(withRouter as any)
class EventTypeSharedSettings extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;
  private modalContentRef;

  componentWillMount() {
    const {actions} = this.props;

    if (shouldBlockActions()) return;

    actions.initEventTypeForm();
  }

  onSave = () => {
    const {routes, router, actions, showSection} = this.props;

    actions.apiSubmitForm(routes, router, showSection);
  };

  public render() {
    const {adminEventsCacheOne, inert, cacheZero, formState, actions, apiSaving, showSection} = this.props;
    const subtitle = adminEventsCacheOne.EventsEventType ? adminEventsCacheOne.EventsEventType.General.Name : '';

    return (
      <Modal inert={inert} wideModal big mobileFullScreen className={namespace()}>
        <ModalHeader
          subtitle={subtitle}
          className={`${namespace()}--header`}
        >
          <div className={`${namespace()}--header--title`}>Edit Event Type Shared Settings</div>
          <div className={`${namespace()}--header--title mobile`}>Edit Shared Settings</div>
        </ModalHeader>
        <ModalContent refCallback={(ref) => this.modalContentRef = ref} hasPadding>
          <EventTypeForm
            inert={inert}
            showSection={showSection}
            formState={formState}
            reduxActions={actions}
            hideFormAction
            loading={apiSaving > 0}
            action='edit'
          />
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button id={generateDOMId("admin-event-type-shared-form-save-btn")} textColor='black' disabled={apiSaving > 0 || !adminEventsCacheOne.EventsEventType || !adminEventsCacheOne.EventsEventType.Permissions.hasEditEventType} flat onClick={this.onSave}>SAVE</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const sectionSelector = makeEventTypeSharedSettingsSectionSelector();

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    formState: state.adminEvents.eventTypes.eventType.form,
    showSection: sectionSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...cacheOneActionCreators,
  ...appActionCreators,
  ...rollbackActionCreators,
}, dispatch) });

const ConnectedEventTypeSharedSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(EventTypeSharedSettings);

export default ConnectedEventTypeSharedSettings;