import {makeRequestEpic} from "../../../Validation/epicCreator";
import {AjaxResponse} from "rxjs/ajax";
import { ENTITY_CREATED, ENTITY_EDITED, ENTITY_PARSE_ERROR, ENTITY_DELETED, ENTITY_RESTORED } from "../../../../constants/messages/adminCMS";
import { UpdateAdminEventProductSubmitActions, DeleteAdminEventProductSubmitActions } from './actions';
import { getUpdateAdminEventProduct } from "../../../../constants/adminEventsUrls";
import { ADMIN_PRODUCT } from "../../../../constants/messages/adminEvents";
import { captureTentarooError } from "../../../../utils/dataHelper";

export const adminEventsUpdateProductEpic = makeRequestEpic(
  UpdateAdminEventProductSubmitActions,
  getUpdateAdminEventProduct,
  undefined,
  true,
  (response: AjaxResponse) => {
    const formData = response.request.body;
    try {
      const JSONDataStr = formData.get('JSONData');
      const JSONData = JSON.parse(JSONDataStr);
      if (JSONData.row.IDi) {
        return ENTITY_EDITED(ADMIN_PRODUCT);
      }
      return ENTITY_CREATED(ADMIN_PRODUCT);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(ADMIN_PRODUCT)));
      return '';
    }
  },
  undefined,
  undefined,
  undefined,
  undefined,
  true,
);
export const adminEventsDeleteProductEpic = makeRequestEpic(
  DeleteAdminEventProductSubmitActions,
  getUpdateAdminEventProduct,
  undefined,
  true,
  (response: AjaxResponse) => {
    const item = response.response.EventsProduct;

    if (item.Inactive) {
      return ENTITY_DELETED(ADMIN_PRODUCT);
    }
    return ENTITY_RESTORED(ADMIN_PRODUCT);
  },
);