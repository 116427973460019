import { MarginProps } from "../../../styles";
import * as React from 'react';
import Card from "../Card";
import { AdminClassParticipantStatus, AdminEventClassParticipant, AdminEventClassParticipantAttendance } from "../../../models/api/adminEventsCacheTwoEvent";
import Button from "../Button";
import Checkbox from "../Checkbox";
import FieldSet from "../FieldSet";
import Select from "../Select";
import Text from "../Text";
import TextField from "../TextField";
import { getClassParticipantStatusKey, Actions as EnterClassRequirementActions } from "../../../store/AdminEvents/Events/Modals/EnterClassRequirement/actions";
import { Actions as AppActions } from "../../../store/App/actions";
import { Column, Row } from "../Grid";
import { getClassParticipantNewRequirementKey, sortRequirements } from "../../../store/AdminEvents/Events/Modals/EnterClassRequirement";
import Tag from "../Tag";
import { CloseCircleIcon } from "../../Icons";
import "../../../styles/elements/participant-card/index.scss";
import {ModalTypes} from "../../../utils/modalHelper";
import {AdminEventsCacheThreeClassRequirementsState} from "../../../store/AdminEvents/CacheThreeClassRequirements";

const namespace = () => 'elements--participant--card';

interface Props extends MarginProps {
  participant: AdminEventClassParticipant;
  onUpdateParticipant: (v, vObj, index: number) => void;
  onRequirementKeyDown: (e, participant: AdminEventClassParticipant, requirements: string, index: number) => void;
  onCheckParticipantAttendance: (participantId: number, attend: AdminEventClassParticipantAttendance) => void;
  onRemoveAllRequirementsFromParticipant: (participantId: number) => void;
  onRemoveRequirementFromParticipant: (participantId: number, r: string | number) => void;
  onAddAllRequirementsToParticipant: (participantId: number) => void;
  actions: EnterClassRequirementActions & AppActions;
  adminEventsCacheThreeClassRequirements: AdminEventsCacheThreeClassRequirementsState;
  index: number;
  apiSaving: number;
}

class ParticipantCard extends React.Component<Props> {
  onManageRequirements = () => {
    const {actions, participant, adminEventsCacheThreeClassRequirements} = this.props;

    actions.pushModal(ModalTypes.MANAGE_REQUIREMENTS, false, true, {
      manageRequirementsContext: "requirements-completed",
      allRequirements: adminEventsCacheThreeClassRequirements.EventsEventClass_AllMBRequirements,
      selectedRequirements: participant.Requirements as number[] || [],
      participantId: participant.IDi,
    });
  };

  renderRequirementsSection_OldTracking = (index: number) => {
    const {
      participant,
      actions,
      apiSaving,
      onRemoveAllRequirementsFromParticipant,
      onRemoveRequirementFromParticipant,
      onAddAllRequirementsToParticipant,
      onRequirementKeyDown,
      onUpdateParticipant,
    } = this.props;
    return [
      <Row key={`participant-${participant.IDi}-requirements-title`} marginTop={16}>
        <Column span={6} mobileSpan={12}>
          <TextField
            label="Requirement"
            disabled={apiSaving > 0}
            onKeyDown={(e) =>
              onRequirementKeyDown(
                e,
                participant,
                participant.ActiveForm[
                  getClassParticipantNewRequirementKey(participant)
                ],
                index
              )
            }
            onChange={actions.simpleUpdateClassParticipant}
            onBlur={(v, vObj) => onUpdateParticipant(v, vObj, index)}
            validationRules={
              participant.ValidationRules[
                getClassParticipantNewRequirementKey(participant)
              ]
            }
            value={
              participant.ActiveForm[
                getClassParticipantNewRequirementKey(participant)
              ]
            }
            hideOptional
            info="Enter requirement(s), then hit enter to add"
          />
        </Column>
        <Column
          mobileMarginTop={16}
          span={6}
          mobileSpan={12}
          className={`${namespace()}--requirement-btns`}
        >
          <Button
            onClick={() => onAddAllRequirementsToParticipant(participant.IDi)}
            textColor="black"
            color="white"
          >
            ADD ALL
          </Button>
          <Button
            onClick={() =>
              onRemoveAllRequirementsFromParticipant(participant.IDi)
            }
            textColor="black"
            color="white"
          >
            CLEAR ALL
          </Button>
        </Column>
      </Row>,
      participant.Requirements && participant.Requirements.length > 0 ? (
        <Row key={`participant-${participant.IDi}-requirements-chips`} marginTop={16}>
          {/* when mbID is null, we know participant.Requirements is an array of string so its safe to cast */}
          {sortRequirements(participant.Requirements as string[]).map((r) => {
            return (
              <Column
                key={`participant${participant.IDi}_requirement_${r}`}
                marginRight={8}
                marginBottom={8}
              >
                <Tag
                  className={`${namespace()}--tag`}
                  onRemove={() =>
                    onRemoveRequirementFromParticipant(participant.IDi, r)
                  }
                  removeIcon={<CloseCircleIcon />}
                  text={r}
                  backgroundColor="orange"
                />
              </Column>
            );
          })}
        </Row>
      ) : null,
    ];
  };

  renderRequirementsSection_NewTracking = (index: number) => {
    const {
      participant,
      apiSaving,
      onRemoveRequirementFromParticipant,
      adminEventsCacheThreeClassRequirements,
    } = this.props;
    return (
      <FieldSet
        fixedLegendFontSize
        keepControlsPositionInMobile
        newDesign
        fontSize={18}
        marginTop={16}
        marginBottom={0}
        legendMarginBottom={participant.Requirements && participant.Requirements.length > 0 ? 16 : 0}
        name="Requirements"
        className={`${namespace()}--updaterequirements ${participant.Requirements && participant.Requirements.length > 0 ? ' has-requirements' : ''}`}
        controls={
          <Button
            onClick={this.onManageRequirements}
            color="white"
            textColor="black"
            disabled={apiSaving > 0}
          >
            MANAGE
          </Button>
        }
      >
        <div className={`${namespace()}--req-chips`}>
          {!adminEventsCacheThreeClassRequirements.EventsEventClass_AllMBRequirements ? null : adminEventsCacheThreeClassRequirements.EventsEventClass_AllMBRequirements
          /* We dont need a sort here because the order is inherited from the order of `EventsEventClass_AllMBRequirements` */
          .filter((req) => {
            return Boolean(participant.Requirements?.find((reqID) => reqID === req.ID));
          })
          .map((req) => {
            return (
              <Tag
                className={`${namespace()}--req-tag`}
                onRemove={() => onRemoveRequirementFromParticipant(participant.IDi, req.ID)}
                removeIcon={<CloseCircleIcon />}
                text={req.req}
                backgroundColor="orange"
              />
            );
          })}
        </div>
      </FieldSet>
    );
  };

  render() {
    const {
      adminEventsCacheThreeClassRequirements,
      participant,
      index,
      onCheckParticipantAttendance,
      onUpdateParticipant,
    } = this.props;
    return (
      <Card key={participant.IDi} className={`${namespace()}`}>
        <header>
          <div className={`${namespace()}--name`}>
            {participant.ParticipantName}
          </div>
          <div className={`${namespace()}--group-name`}>
            {participant.GroupName}
          </div>
          <div className={`${namespace()}--status`}>
            <Select
              disabled={false}
              hideOptional
              onChangeValue={(v, vObj) => onUpdateParticipant(v, vObj, index)}
              value={
                participant.ActiveForm[
                  getClassParticipantStatusKey(participant)
                ]
              }
              validationRules={
                participant.ValidationRules[
                  getClassParticipantStatusKey(participant)
                ]
              }
              isNumber
            />
          </div>
        </header>
        {participant.ActiveForm[getClassParticipantStatusKey(participant)] !==
          AdminClassParticipantStatus.NO_SHOW && (
          <section className={`${namespace()}--status--detail`}>
            <Row marginTop={16}>
              <div className={`${namespace()}--label`}>Attendance</div>
            </Row>
            <Row>
              {participant.AttendArr.map((attend) => {
                return (
                  <Column
                    key={`participant${participant.IDi}_attendance_${attend.label}`}
                    className={`${namespace()}--attend-col`}
                    marginRight={12}
                  >
                    <Checkbox
                      value={attend.label}
                      disabled={false}
                      selected={!!attend.checked}
                      newDesign
                      label={
                        <Text
                          color="dark-gray"
                          weight="regular"
                          size={16}
                          marginBottom={0}
                        >
                          {attend.label}
                        </Text>
                      }
                      onChange={() =>
                        onCheckParticipantAttendance(participant.IDi, attend)
                      }
                    />
                  </Column>
                );
              })}
            </Row>
            {adminEventsCacheThreeClassRequirements.EventsEventClassRequirements?.mbID ? this.renderRequirementsSection_NewTracking(index) : this.renderRequirementsSection_OldTracking(index)}
          </section>
        )}
      </Card>
    );
  }
}

export default ParticipantCard;