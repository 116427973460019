import { CMSResourceCategory } from "../../models/api/adminCMSCacheOne";

export const getResourceCategoriesMap = (resourceCategories: CMSResourceCategory[]): {[key: string]: CMSResourceCategory} => {
  const categoryMap: {[key: string]: CMSResourceCategory} = {};

  resourceCategories && resourceCategories.forEach((category) => {
      categoryMap[category.ID] = category;
  });

  return categoryMap;
};