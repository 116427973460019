import {ActionCreator} from '../';
import {CLEAR_CACHE_PREFIX, createRequestActions} from '../Validation/actionCreator';
import {PreviousOrder} from "../../models/api/cacheTwoPrevOrders";
import { typeName, Action } from '../../utils/StrongActions';

export const SUFFIX = '__CACHE_THREE_PREV_ORDERS';
export const GetPrevOrder = createRequestActions(SUFFIX);

@typeName(`${CLEAR_CACHE_PREFIX}_CACHE_BELOW_THREE_PREV_ORDERS`) export class ClearCacheBelowThreePrevOrders extends Action {}
@typeName(`SET_PREV_ORDER${SUFFIX}`)
export class SetPrevOrder extends Action {
  constructor(public order: PreviousOrder) {
    super();
  }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  getPrevOrder: (
    GroupIDi: number,
    GroupTS: number,
    OrderID: number,
    GetOptions?: boolean,
    GetGroupData?: boolean,
    GetGroupPreviousOrdersData?: boolean
  ): ActionCreator => dispatch => {
    dispatch(GetPrevOrder.request(
      {GroupIDi, GroupTS, OrderID, GetOptions, GetGroupData, GetGroupPreviousOrdersData}
    ));
  },
  clearCacheBelowThreePrevOrders: (): ActionCreator => dispatch => dispatch(new ClearCacheBelowThreePrevOrders()),
  setPrevOrder: (order: PreviousOrder): ActionCreator => dispatch => dispatch(new SetPrevOrder(order)),
};
