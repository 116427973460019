import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import * as M from '../../../../../constants/messages/generic';
import { makeFormModalPropSelector } from "../../../../App";
import { AdminEventTypeParticipantTypeKeys, getAdminEventTypeParticipantTypeAttrKey } from "../../EventType/Form/actions";
import { NEW_PARTICIPANT_TYPE } from "../../../../../constants/messages/adminEvents";
import { ModalTypes } from "../../../../../utils/modalHelper";
import { SelectValidator } from "../../../../../utils/validator/models";

export interface IValidator {
  ParticipantTypeID: SelectValidator;
}

export const FormDefinition: IValidator = {
  ParticipantTypeID: {
    key: 'ParticipantTypeID',
    isRequired: () => true,
    defaultValue: () => 0,
    options: {
      values: (rootState) => {
        const participantTypeIndexSelector = makeFormModalPropSelector(ModalTypes.REPLACE_PARTICIPANT_TYPE, 'participantTypeIndex');
        const participantTypeSelector = makeFormModalPropSelector(ModalTypes.REPLACE_PARTICIPANT_TYPE, 'participantType');

        const index = participantTypeIndexSelector(rootState);
        const participantType = participantTypeSelector(rootState);
        
        if (index === undefined || !participantType) return [];

        let result = [...rootState.adminEvents.eventTypes.eventType.form.Pricing4.ActiveForm.ParticipantTypes];
        result.splice(index, 1);
        result = result.filter((pt) => !pt.Inactive).map((pt) => {
          const isYouthKey = getAdminEventTypeParticipantTypeAttrKey(pt.ID, AdminEventTypeParticipantTypeKeys.IS_YOUTH);
          const nameKey = getAdminEventTypeParticipantTypeAttrKey(pt.ID, AdminEventTypeParticipantTypeKeys.NAME);

          return {
            ID: pt.ID,
            Name: pt.ActiveForm[nameKey] || NEW_PARTICIPANT_TYPE,
            IsYouth: pt.ActiveForm[isYouthKey] === 0,
          };
        });

        result.unshift({ID: 0, Name: '', IsYouth: participantType.IsYouth});
        return result.filter((pt) => pt.IsYouth === participantType.IsYouth);
      },
      valueKey: (v) => v.ID,
      labelKey: 'Name',
      emptyValue: 0,
    },
    validatejs: {
      ParticipantTypeID: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0
        }
      },
    },
  }
};