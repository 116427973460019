import * as React from 'react';
import '../../../styles/elements/filter-text-field/index.scss';

export const namespace = (): string => 'elements--filter-text-field';

export interface FilterTextFieldProps {
  onChange: React.EventHandler<any>;
  delay?: number;
  defaultValue?: string;
  id?: string;
}

// @todo: can maybe get rid of this
export interface FilterTextFieldState {
  value?: string | number;
}

class FilterTextField extends React.Component<FilterTextFieldProps, FilterTextFieldState> {

  private lastFilter: string = '';

  public props: FilterTextFieldProps;

  public state: FilterTextFieldState = {
    value: ''
  };

  public shouldComponentUpdate(nextProps) {
    return false;
  }

  public componentWillMount() {
    if (this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue});
    }
  }

  public handleChange = () => {
    const input: any = this.refs.inputField;
    if (input && input.value !== this.lastFilter) {
      this.lastFilter = input;
      this.props.onChange(input.value);
    }
  };

  public render() {
    return <input id={this.props.id} onChange={this.handleChange} className={namespace()} ref="inputField" autoFocus value={this.props.defaultValue}></input>;
  }
}

export default FilterTextField;
