import * as M from "../../../../constants/messages/generic";
import { Validator } from "../../../../utils/validator/models";

export interface IAdminEventClassTypeValidator {
  Name: Validator;
  FeaturedImage: Validator;
  mbID: Validator;
  Description: Validator;
  Requirements: Validator;
  RequirementsArray: Validator;
}

export const AdminEventClassTypeFormDefinition: IAdminEventClassTypeValidator =
  {
    Name: {
      key: "Name",
      isRequired: () => true,
      defaultValue: () => "",
      validatejs: {
        Name: {
          presence: { message: "^" + M.REQUIRED },
          length: {
            maximum: 50,
            tooLong: M.MAX_LENGTH(50),
          },
        },
      },
    },
    FeaturedImage: {
      key: "FeaturedImage",
    },
    mbID: {
      key: "mbID",
      customValidate: (rootState) => {
        const form = rootState.adminEvents.classTypes.form.ActiveForm;

        if (!form.mbID) {
          return '';
        } else {
          const meritBadges = rootState.cacheZero.options?.MeritBadges || [];
          const selectedMeritBadge = meritBadges.find((mb) => mb.ID === form.mbID);

          if (selectedMeritBadge && selectedMeritBadge.Inactive) {
            return M.INVALID_SELECTED_MERIT_BADGE;
          }
        }
      },
      validatejs: {
        mbID: {},
      },
    },
    Description: {
      key: "Description",
      isRequired: () => false,
      defaultValue: () => "",
      validatejs: {
        Description: {
          length: {
            maximum: 2048,
            tooLong: M.MAX_LENGTH(2048),
          },
        },
      },
    },
    Requirements: {
      key: "Requirements",
      isRequired: () => false,
      defaultValue: () => "",
      validatejs: {
        Requirements: {
          length: {
            maximum: 2048,
            tooLong: M.MAX_LENGTH(2048),
          },
        },
      },
    },
    RequirementsArray: {
      key: "RequirementsArray",
      isRequired: () => false,
      validatejs: {
        RequirementsArray: {
        },
      },
    }
  };
