import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import {
  actionCreators as cacheZeroActionCreators
} from '../../../../../store/CacheZero/actions';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { 
  actionCreators as rollbackActionCreators,
} from '../../../../../store/Rollback/actions';
import { actionCreators } from '../../../../../store/AdminEvents/Reports/GenerateReports/actions';
import { Row, Column, Select, RadioGroupOption, Button, Alert } from '../../../../../components/Elements';
import '../../../../../styles/pages/admin-events/reports/home/generate-reports.scss';
import { makeSelectedEventTypeReportSelector } from '../../../../../store/AdminEvents/Reports/GenerateReports';
import { checkAdminEventReportsPermission } from '../../../../../utils/helpers/adminEventsPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';

export const namespace = (): string => 'pages--admin-events--generate-reports';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> &RouteComponentProps<{}, {resourceId: string}>;

interface Props {
}

class GenerateReports extends React.Component<Props, {}> {
  public props: ConnectedProps & Props;
  
  onGenerateReport = () => {
    const {selectedReportType, actions, adminEventsCacheOne} = this.props;

    checkAdminEventReportsPermission(
      () => {
        if (!adminEventsCacheOne.EventsEventType) return;
        actions.generateEventTypeReports(selectedReportType ? selectedReportType.ReportURL : '', adminEventsCacheOne.EventsEventType.EventTypeRow.ID as number);
      },
      adminEventsCacheOne,
    );
  };

  public render() {
    const {actions, adminEventsCacheOne, adminEventsReportsForm: {SubmitErrorMessage, ActiveForm, ValidationRules}} = this.props;

    return (
      <div className={`${namespace()}`}>
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        <Alert className={`${namespace()}--alert`}>For more reports, open a specific event on the Events tab</Alert>
        <Row marginTop={24} mobileMarginTop={16} marginBottom={8}>
          <Column span={4} mobileSpan={6}>
            <Select
              label="Year"
              onChangeValue={actions.updateValue}
              validationRules={ValidationRules.Year}
              value={ActiveForm.Year}
              isNumber
            />
          </Column>
        </Row>
        {adminEventsCacheOne.EventTypeReportsOptions ? adminEventsCacheOne.EventTypeReportsOptions.map((o) => {
          return (
            <Row key={o.ID}>
              <Column layout='horizontal' span={12} mobileSpan={12}>
                <RadioGroupOption
                  newDesign
                  value={ActiveForm.SelectedReportType === o.ID}
                  selected={ActiveForm.SelectedReportType === o.ID}
                  ValidationRules={ValidationRules.SelectedReportType}
                  rawValue={o.ID}
                  onChange={actions.updateValue}
                  label={o.Name}
                  helperText={o.Description ? o.Description : undefined}
                />
              </Column>
            </Row>
          );
        }) : null}
        <Row marginTop={24} mobileMarginTop={16}>
          <Button onClick={this.onGenerateReport} textColor='white' color='green'>GENERATE REPORT</Button>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedReportTypeSelector = makeSelectedEventTypeReportSelector();

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsReportsForm: state.adminEvents.reports.generateReports,
    cacheZero: state.cacheZero,
    session: state.session,
    user: state.user,
    apiSaving: state.app.apiSaving,
    selectedReportType: selectedReportTypeSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheZeroActionCreators,
    ...appActionCreators,
    ...rollbackActionCreators,
  }, dispatch)
});
const ConnectedGenerateReports = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(GenerateReports);

export default withRouter<Props>(ConnectedGenerateReports);
