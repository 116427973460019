import * as React from 'react';

import Row from "../Grid/Row";
import Column from "../Grid/Column";
import ActionButton from "../ActionButton";
import Text from "../Text";
import { namespace as ns } from './constants';
import Arrow from '../../../images/elements/financial-summary/arrow.svg';
import '../../../styles/elements/financial-summary/total.scss';
import { noop } from '../../../utils';

export const namespace = (): string => `${ns()}--total`;

interface Props {
  value: string;
  title?: string;
  toggle?: boolean;
  open?: boolean;
  onToggle?: (open: boolean) => void;
  noMarginBottom?: boolean;
  marginTop?: number;
  mobileMarginTop?: number;
}

const Total: React.SFC<Props> = ({ value, noMarginBottom, title, toggle, open, onToggle, marginTop, mobileMarginTop }: Props): React.ReactElement<Props> => (
  <Row
    mobileMarginTop={mobileMarginTop ? mobileMarginTop : 0}
    marginTop={marginTop ? marginTop : 0}
    marginBottom={(title && !noMarginBottom) ? 24 : 0}
    mobileMarginBottom={noMarginBottom ? 0 : 16}
    className={`${namespace()}--row`}
  >
    <Column expand>
      <Text size={18} weight="bold">
        {title ? title : 'Balance'}
      </Text>
      {toggle ? (
        <ActionButton
          icon={Arrow}
          className={`${namespace()}--arrow` + (open ? ' open' : '')}
          onClick={onToggle ? onToggle.bind(this, !open) : noop}
        />
      ) : null}
    </Column>
    <Column>
      <Text size={18} weight="bold">
        {value}
      </Text>
    </Column>
  </Row>
);

export default Total;
