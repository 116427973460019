import {E_REGISTER, EVENT, getEventProducts} from "../../constants/urls";
import {makeRequestEpic} from "../Validation/epicCreator";
import {EventProductsCalc, EventProductsSave} from "./actions";
import {Observable} from "../../../node_modules/rxjs";
import {ApiSubmitActionsProductsProducts} from "../Events/Event/Register/Products/Products/actions";
import {EventRegisterProductsTransitionToPending} from "../Events/Event/Register/Products/Main/actions";
import {NoOp, ShowSnackbarItem} from "../App/actions";
import {getMessageFromErrorResponse} from "../../utils";
import {replace} from "react-router-redux";
import { captureTentarooError } from "../../utils/dataHelper";
import { reduxStoreService } from "../service";

export const cacheFourEventsProductsEpic = makeRequestEpic(
  EventProductsCalc,
  getEventProducts,
  undefined, // body is passed in as is in action.value
  true
);

export const cacheFourEventsProductsRecalcSuccessEpic = action$ => {
  return action$.filter((a) =>
    a.type === ApiSubmitActionsProductsProducts.successType
  )
    .mergeMap((action) => {
      return Observable.of(new EventRegisterProductsTransitionToPending());
    });
};

export const cacheFourEventsProductsSaveFailureEpic = action$ => {
  return action$.filter((a) => (
    a.type === EventProductsSave.failureType || a.type === ApiSubmitActionsProductsProducts.failureType
  ))
    .mergeMap((action) => {
      const route = reduxStoreService().getState().routing.locationBeforeTransitions;
      const newPath = route.pathname.replace(`${EVENT.REGISTER}/${E_REGISTER.PRODUCTS}`, EVENT.REGISTRATION);
      if (action.response.status === 400) {
        if (action.response.xhr.response.error.Detail === 'Canceled') {
          return Observable.of(new ShowSnackbarItem(getMessageFromErrorResponse(action.response)));
        } else if (action.response.xhr.response.error.Detail === 'Incorrect Payment') {
          return Observable.merge(
            Observable.of(replace(`${newPath}${route.search}`)),
            Observable.of(new ShowSnackbarItem(getMessageFromErrorResponse(action.response)))
          );
        }
      } else if (action.response.status === 409) {
        return Observable.merge(
          Observable.of(replace(`${newPath}${route.search}`)),
        );
      }
      return Observable.of(new NoOp());
    });
};

export const cacheFourEventsProductsSaveEpic = makeRequestEpic(
  EventProductsSave,
  getEventProducts,
  undefined, // body is passed in as is in action.form
  true,
  () => {
    const rootState = reduxStoreService().getState();
    if (!rootState.cacheThreeEvents.EventInfo) {
      captureTentarooError(new Error("cacheThreeEvents.EventInfo not available after cacheFourEventsProduct save"));
      return "";
    }
    if (rootState.cacheThreeEvents.EventInfo.HasGroupRegistration) {
      return 'Any changes have been saved to your cart.';
    }
    return 'New products for this event has been added to your cart.';
  }
);
