
// @todo: I don't think I'll need this state anymore. As far as I know the class page just displays classes and allows filter

import { Reducer } from "redux";

// the page is using the global filter for it. only reselect binding are done
export interface EventClassesState {
}


const EventClasses: Reducer<EventClassesState> = (state, action) => {
  return state || {};
};

export default EventClasses;
