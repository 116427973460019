import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { namespace as ns } from './constants';
import '../../../styles/elements/slide-show/slide.scss';

export const namespace = (): string => `${ns()}--slides--slide`;

export interface SlideProps {
  className?: string;
  image?: string;
}

const Slide: React.SFC<SlideProps> = (props: SlideProps): React.ReactElement<SlideProps> => {
  const { image } = props;
  // const imageStyle: React.CSSProperties = {
  //   backgroundImage: `url("${image}")`
  // };
  const maxWidth = window.innerWidth - 20;
  const maxHeight = window.innerHeight * 0.7;
  let className: string = mergeClassNames(namespace(), props);
  return (
    <div className={className}>
      <img src={image} style={{maxWidth, maxHeight}}/>
    </div>
  );
};

export default Slide;
