import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators } from "redux";
import {
  Modal,
  ModalHeader,
  ModalContent,
  PageLoader,
  EmptyMessage,
  Button,
} from "../../../../../Elements";
import {
  actionCreators,
  GetGLAccountList,
} from "../../../../../../store/AdminSettings/GLAccounts/Modals/GLAccount/actions";
import "../../../../../../styles/pages/admin-settings/gl-accounts/modals/gl-account/index.scss";
import { actionCreators as appActionCreators } from "../../../../../../store/App/actions";
import { actionCreators as facilityFormActionCreators } from "../../../../../../store/AdminFacilityLocation/Facilities/Facility/Form/actions";
import { actionCreators as eventFormActionCreators } from "../../../../../../store/AdminEvents/Events/Event/Form/actions";
import { actionCreators as productFormActionCreators } from "../../../../../../store/AdminEvents/Products/Form/actions";
import { actionCreators as classFormActionCreators } from "../../../../../../store/AdminEvents/Classes/Form/actions";
import { FacilityDetailFormDefinition } from "../../../../../../store/AdminFacilityLocation/Facilities/Facility/Form/validation";
import { FormDefinition as EventFormDefinition } from "../../../../../../store/AdminEvents/Events/Event/Form/validation";
import { AdminEventProductFormDefinition as ProductFormDefinition } from "../../../../../../store/AdminEvents/Products/Form/validation";
import { AdminEventClassFormDefinition } from "../../../../../../store/AdminEvents/Classes/Form/validation";
import { actionCreators as cacheOneActionCreators } from "../../../../../../store/AdminFacilityLocation/CacheOne/actions";
import { ApplicationState } from "../../../../../../store";
import { ModalHeight } from "../../../../../Elements/Modal";
import { CardCategory } from "../../../../../Elements/Card";
import { makeFilteredGLAccountsSelector } from "../../../../../../store/AdminSettings/GLAccounts/Modals/GLAccount";
import { GLAccount } from "../../../../../../models/api/options";
import GLAccountCard, {
  GLAccountCardType,
} from "../../../../../Elements/GLAccount/Card";
import { checkGLAccountPermission } from "../../../../../../utils/helpers/adminSettingsPageHelper";
import { GLAccountIcon } from "../../../../../../components/Icons";
import { EmptyMessageType } from "../../../../../../components/Elements/EmptyMessage";
import { ENTITY_NOT_FOUND } from "../../../../../../constants/messages/adminCMS";
import { GL_ACCOUNT } from "../../../../../../constants/messages/adminSettings";
import { makeFormModalPropSelector } from "../../../../../../store/App";
import { ModalTypes } from "../../../../../../utils/modalHelper";
import { connect } from "react-redux";
import { getMergeProps } from "../../../../../../utils/reduxHelper";
import {WithInertAttribute} from "../../../../../Elements/WithInert";

export const namespace = (): string => "pages--settings--modal--gl-account";

export type SelectGLAccountModalContext =
  | "facility-form"
  | "event-form"
  | "product-form"
  | "class-form";
type ConnectedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {}>;
type Props = WithInertAttribute<{}>;

@(withRouter as any)
class GLAccountModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  private modalContentRef;

  componentWillUnmount() {
    this.onClose();
  }

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    if (this.props.glAccountModal.filterString) {
      this.scrollToTop();
    }
    this.props.actions.toggleFilter(false);
    this.props.actions.filterAccounts("");
  };
  onAddClick = () => {
    const { actions, cacheZero } = this.props;

    checkGLAccountPermission(
      () => {
        actions.pushFormModal(ModalTypes.GL_ACCOUNT_FORM, false);
      },
      cacheZero,
      false,
      true
    );
  };
  onRefreshClick = () => {
    const { actions, cacheZero } = this.props;
    actions.refreshGLAccounts();
  };

  itemRenderer = (index) => {
    return null;
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };

  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filterAccounts(val);
  };

  onEditGLAccount = (account: GLAccount) => {
    checkGLAccountPermission(
      () => {
        this.props.actions.pushFormModal(ModalTypes.GL_ACCOUNT_FORM, true, {
          id: account.ID,
        });
      },
      this.props.cacheZero,
      true,
      true
    );
  };
  onClickGLAccount = (account: GLAccount) => {
    if (this.props.context === "facility-form") {
      this.props.facilityFormActions.updateFacilityDetailValue(
        account.ID,
        FacilityDetailFormDefinition.GLAccountID
      );
    } else if (this.props.context === "event-form") {
      this.props.eventFormActions.updateValue(
        account.ID,
        EventFormDefinition.GLAccountID
      );
    } else if (this.props.context === "product-form") {
      this.props.productFormActions.updateValue(
        account.ID,
        ProductFormDefinition.GLAccountID
      );
    } else if (this.props.context === "class-form") {
      this.props.classFormActions.updateValue(
        account.ID,
        AdminEventClassFormDefinition.GLAccountID
      );
    }
    this.onClose();
    this.props.actions.popModal(false, true, ModalTypes.SELECT_GL_ACCOUNTS);
  };

  onClose = () => {
    this.props.actions.reset();
  };

  renderEmptyMessage = () => {
    return (
      <EmptyMessage
        icon={GLAccountIcon}
        admin
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight="96px"
        iconWidth="96px"
        fixedFontSize
        description={ENTITY_NOT_FOUND(GL_ACCOUNT)}
        actions={
          <Button
            className={`${namespace()}--empty-message-btn`}
            color="white"
            textColor="black"
            onClick={this.onAddClick}
          >
            NEW ACCOUNT
          </Button>
        }
      />
    );
  };

  public render() {
    const {
      apiLoading,
      apiLoadingMap,
      glAccountModal: { isTextSearching, ActiveForm, ValidationRules },
      context,
      facilityForm,
      productForm,
      classForm,
      eventForm,
      filteredGLAccounts,
      cacheOne,
      cacheTwoFacility,
      cacheTwoProduct,
      cacheTwoEvent,
      cacheZero,
      cacheThreeClass,
      actions,
      apiSaving,
      inert,
    } = this.props;
    let subtitle = "";

    if (context === "facility-form") {
      subtitle =
        facilityForm.Detail.ActiveForm.Name ||
        (!!cacheTwoFacility.FacilitiesFacility
          ? "Edit Facility"
          : "New Facility");
    } else if (context === "product-form") {
      subtitle =
        productForm.ActiveForm.Name ||
        (!!cacheTwoProduct.Product ? "Edit Product" : "New Product");
    } else if (context === "event-form") {
      subtitle =
        eventForm.ActiveForm.Name ||
        (!!cacheTwoEvent.EventsEvent ? "Edit Event" : "New Event");
    } else if (context === "class-form") {
      let className = "";

      if (classForm.ActiveForm.ClassCode)
        className += `${classForm.ActiveForm.ClassCode} - `;
      if (classForm.ActiveForm.IsCombo)
        className += classForm.ActiveForm.ComboName;
      else {
        if (
          cacheThreeClass.EventsEventClass &&
          cacheZero.options &&
          cacheZero.options.ClassTypes
        ) {
          const classType = cacheZero.options.ClassTypes.find(
            (o) =>
              !!cacheThreeClass.EventsEventClass &&
              o.IDi === cacheThreeClass.EventsEventClass.ClassTypeIDi
          );
          if (classType) className += classType.Name;
        }
      }
      subtitle =
        className ||
        (!!cacheThreeClass.EventsEventClass ? "Edit Class" : "New Class");
    }
    const loading = apiLoadingMap[GetGLAccountList.requestType];
    return (
      <Modal
        inert={inert}
        height={ModalHeight.HEIGHT_425}
        onClose={this.onClose}
        big
        mobileFullScreen
        className={namespace()}
      >
        <ModalHeader
          isSearching={isTextSearching}
          onAdd={this.onAddClick}
          onRefresh={this.onRefreshClick}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={this.onSearchChange}
          onSearchBack={this.onSearchBack}
          subtitle={subtitle}
          className={`${namespace()}--header`}
        >
          Select GL Account
        </ModalHeader>

        <ModalContent
          refCallback={(ref) => (this.modalContentRef = ref)}
          paddingLeft={0}
          paddingRight={0}
          paddingTop={0}
          paddingBottom={0}
        >
          {apiLoading === 0 &&
            !loading &&
            filteredGLAccounts.length > 0 &&
            filteredGLAccounts.map((account) => {
              return (
                <GLAccountCard
                  type={GLAccountCardType.LIST}
                  cardCategory={CardCategory.LIST_MOBILE}
                  showDeletedPrompt
                  disabled={apiSaving > 0}
                  marginTop={12}
                  onEdit={this.onEditGLAccount}
                  onClick={this.onClickGLAccount}
                  account={account}
                />
              );
            })}
          {apiLoading === 0 &&
            !loading &&
            filteredGLAccounts.length === 0 &&
            this.renderEmptyMessage()}
          {apiLoading > 0 && loading && (
            <PageLoader className={`${namespace()}--page-loader`} />
          )}
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredGLAccountsSelector = makeFilteredGLAccountsSelector();
  const contextSelector = makeFormModalPropSelector(
    ModalTypes.SELECT_GL_ACCOUNTS,
    "glAccountContext"
  );

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    glAccountModal: state.adminSettings.glAccounts.modals.glAccount,
    cacheOne: state.adminFacilityLocation.cacheOne,
    facilityForm: state.adminFacilityLocation.facilities.facility.form,
    productForm: state.adminEvents.products.form,
    eventForm: state.adminEvents.events.event.form,
    classForm: state.adminEvents.classes.form,
    cacheTwoFacility: state.adminFacilityLocation.cacheTwoFacility,
    cacheTwoProduct: state.adminEvents.cacheTwoProduct,
    cacheTwoEvent: state.adminEvents.cacheTwoEvent,
    cacheThreeClass: state.adminEvents.cacheThreeClass,
    filteredGLAccounts: filteredGLAccountsSelector(state),
    context: contextSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...actionCreators,
      ...appActionCreators,
      ...cacheOneActionCreators,
    },
    dispatch
  ),
  facilityFormActions: bindActionCreators(
    {
      ...facilityFormActionCreators,
    },
    dispatch
  ),
  eventFormActions: bindActionCreators(
    {
      ...eventFormActionCreators,
    },
    dispatch
  ),
  productFormActions: bindActionCreators(
    {
      ...productFormActionCreators,
    },
    dispatch
  ),
  classFormActions: bindActionCreators(
    {
      ...classFormActionCreators,
    },
    dispatch
  ),
});

const ConnectedGLAccountModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>()
)(GLAccountModal);

export default ConnectedGLAccountModal;
