
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import { AdminCMSCacheTwoContactContext } from "@tentaroo/shared";

import {
  Modal, ModalHeader, ModalContent, ModalActions, Button, Loader
} from '../../../../../../Elements';
import { actionCreators, ContactFormModalNamespace as namespace } from '../../../../../../../store/AdminCMSSite/Contacts/Contact/Form/actions';
import { actionCreators as adminCMSCacheTwoContactsActionCreators } from '../../../../../../../store/AdminCMSSite/CacheTwoContacts/actions';
import { actionCreators as adminCMSCacheOneActionCreators } from '../../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../../../store/App/actions';
import { ApplicationState } from '../../../../../../../store';
import Contact from '../../Contact/Form';
import '../../../../../../../styles/pages/contacts/modals/contact-form/index.scss';
import { INVALID_CONTACT } from '../../../../../../../constants/messages/adminCMS';
import { checkContactPermission, IAdminCMSContactRouterParams } from '../../../../../../../utils/helpers/adminCMSPageHelper';
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { makeFormModalPropSelector } from '../../../../../../../store/App';
import AdminCMSCacheManager from '../../../../../../../utils/cacheManagers/adminCMSCacheManager';
import { generateDOMId } from '../../../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../../Templates/ComponentUpdateTemplate';
import {isAdminCMSCacheOnePopulated, isAdminCMSCacheTwoContactPopulated} from '../../../../../../../utils/cachePopulatedCheckers/adminCMS';
import {WithInertAttribute} from '../../../../../../Elements/WithInert';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminCMSContactRouterParams, {}>;
type Props = WithInertAttribute<{}>

@(withRouter as any)
class ContactFormModal extends ComponentUpdateTemplate<Props & ConnectedProps> {

  public props: Props & ConnectedProps;

  componentDidMount() {
    super.loadAndSetData(
      this.props,
      () => {
        AdminCMSCacheManager.getInstance().loadCacheTwoContact({
          props: this.props,
          isStateNavigated: true,
          isEdit: !!this.props.contactId,
          context: AdminCMSCacheTwoContactContext.CONTACT_FORM,
          contactIdFromModalProps: this.props.contactId,
        });
      }
    );
  }

  componentWillUnmount() {
    this.onClose();
  }

  onContactInactive() {
    const {actions} = this.props;

    actions.showTopFloatingAlert(INVALID_CONTACT, true, 'orange');
  }
  onSave = () => {
    checkContactPermission(
      () => {
        this.props.actions.apiSubmitForm(this.props.routes);
      },
      this.props.cacheZero,
      this.props.adminCMSCacheOne,
      !!this.props.contactId && this.props.adminCMSCacheTwoContact.CMSContact ? this.props.adminCMSCacheTwoContact.CMSContact.SiteID : undefined,
      true,
    );
  };

  onClose = () => {
    if (isAdminCMSCacheTwoContactPopulated(reduxStoreService().getState().adminCMSSite.cacheTwoContacts)) {
      this.props.actions.clearAdminCMSCacheTwoContact();
    }
  };

  public render() {
    const { contactId, apiSaving, inert, adminCMSCacheTwoContact, actions, contactForm} = this.props;

    const title = contactId ? 'Edit Contact' : 'New Contact';
    const loading = !!contactId && !isAdminCMSCacheTwoContactPopulated(adminCMSCacheTwoContact, contactForm.ActiveForm);

    const inactive = !!contactId && (adminCMSCacheTwoContact.CMSContact && adminCMSCacheTwoContact.CMSContact.Inactive);

    return (
      <Modal
        inert={inert}
        inactive={inactive}
        onInactive={() => this.onContactInactive()}
        wideModal
        big mobileFullScreen
        className={namespace()}
        onClose={this.onClose}
      >
        <ModalHeader className={`${namespace()}--header`}>{title}</ModalHeader>
        <ModalContent hasPadding>
          {!loading &&
            <Contact
              inert={inert}
              hideFormAction
              ActiveForm={contactForm.ActiveForm}
              ValidationRules={contactForm.ValidationRules}
              reduxActions={actions}
              SubmitErrorMessage={contactForm.SubmitErrorMessage}
              disabled={apiSaving > 0 || !isAdminCMSCacheOnePopulated() || (!!contactId && !isAdminCMSCacheTwoContactPopulated(adminCMSCacheTwoContact))}
              action={!!contactId ? 'edit' : 'add'}
              onSave={this.onSave}
            />}
          {loading && <Loader className={`${namespace()}--loader`} center />}
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button id={generateDOMId("contact-modal-save-btn")} textColor='black' disabled={loading || apiSaving > 0} flat onClick={this.onSave}>{this.props.contactId ? 'SAVE' : 'CREATE'}</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const contactIdSelector = makeFormModalPropSelector(ModalTypes.CONTACT_FORM, 'id');
  return {
    adminCMSCacheTwoContact: state.adminCMSSite.cacheTwoContacts,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    contactForm: state.adminCMSSite.contacts.contact.form,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    contactId: contactIdSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...appActionCreators,
  ...actionCreators,
  ...adminCMSCacheOneActionCreators,
  ...adminCMSCacheTwoContactsActionCreators,
}, dispatch) });

const ConnectedContactFormModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ContactFormModal);

export default ConnectedContactFormModal;