import * as React from 'react';
import { connect } from 'react-redux';
import {RouteComponentProps, withRouter} from "react-router";
import { bindActionCreators } from 'redux';

import {Alert, LoadingAll, PageLoader, Row} from '../../../Elements';
import { Main, MainContent } from '../../../Layouts';
import ProfileForm from '../../../Pages/Settings/Profile/form';
import { actionCreators } from '../../../../store/AddGroup/actions';
import { actionCreators as appActionCreators } from '../../../../store/App/actions';
import {
  actionCreators as cacheZeroActionCreators
} from '../../../../store/CacheZero/actions';
import {ApplicationState} from "../../../../store/index";
import {SaveState} from "../../../../store/Rollback/actions";
import EndUserCacheManager from '../../../../utils/cacheManagers/endUserCacheManager';
import { shouldReconfigRouter } from '../../../../utils/cacheLoaders/reloaderHelper';
import { getMergeProps } from '../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../store/service';
import { ComponentUpdateTemplate } from '../../../Templates/ComponentUpdateTemplate';
import { WithInertAttribute } from '../../../Elements/WithInert';

const namespace = (): string => 'pages--admin--add-group';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>
>;


@(withRouter as any)
class AddGroup extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;

  public componentDidMount() {
    this.configRouteLeaveHook();
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheZero({
          props: this.props,
          isStateNavigated,
        });
      }
    );
  }

  public componentWillReceiveProps(nextProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheZero({
          props: this.props,
          isStateNavigated,
        });
      }
    );
  }

  componentWillUnmount() {
    this.resetRouteLeaveHook();
  }

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentDidUpdate(prevProps: ConnectedProps & RouteComponentProps<{}, {}>) {
    if (shouldReconfigRouter(prevProps, this.props)) {
      this.configRouteLeaveHook();
    }
  }

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    if (reduxStoreService().getState().app.apiLoading === 0) {
      reduxStoreService().dispatch(new SaveState());
    }
  };

  public render() {
    const { cacheZero, addGroup, user, actions, apiSaving, apiLoading, inert } = this.props;
    if (!cacheZero.options || !cacheZero.options.GeneralPermissions) return <LoadingAll/>;
    const hasAccess = cacheZero.options.GeneralPermissions.hasGroup;
    return (
      <Main inert={inert} mobileBackground="white" className={namespace()}>
        <MainContent handleCompact>
          {!hasAccess && <Row hardOpacity key="access">
            <Alert>{cacheZero.options.GeneralPermissions.hasGroupReason}</Alert>
          </Row>}
          <ProfileForm
            isCreateAccount
            isAdmin={user.user ? user.user.str_permissions.hasAdminAccess : false}
            ActiveForm={addGroup.ActiveForm}
            ValidationRules={addGroup.ValidationRules}
            SubmitErrorMessage={addGroup.SubmitErrorMessage}
            cacheZero={cacheZero}
            apiSaving={apiSaving}
            apiLoading={apiLoading}
            actions={actions}
          />
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    user: state.user,
    addGroup: state.addGroup,
    cacheZero: state.cacheZero,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({...actionCreators, ...cacheZeroActionCreators, ...appActionCreators}, dispatch)
});

const ConnectedAddGroup = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(AddGroup);

export { default as AddGroupHeader } from './Header';
export default ConnectedAddGroup;
