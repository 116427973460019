import * as React from 'react';
import { connect } from 'react-redux';

import Modal from "../Modal";
import ModalTitle from "../Modal/Title";
import ModalContent from "../Modal/Content";
import ModalActions from "../Modal/Actions";
import Text from "../Text";
import Button from "../Button";

import {actionCreators} from "../../../store/App/actions";
import { ApplicationState } from '../../../store';
import { makeFormModalPropSelector } from '../../../store/App';
import { ModalTypes } from '../../../utils/modalHelper';
import { getMergeProps } from '../../../utils/reduxHelper';
import {WithInertAttribute} from '../WithInert';
import {bindActionCreators} from 'redux';

type Props = WithInertAttribute<{
}>

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const ReportFinishedModal: React.SFC<ConnectedProps & Props> = (props): React.ReactElement<Props> => (
  <Modal inert={props.inert} disableDynamicHeight big>
    <ModalTitle marginBottom={0}>Report Ready</ModalTitle>
    <ModalContent>
      <Text color="gray">Your report is ready.</Text>
    </ModalContent>
    <ModalActions
      noPadding
      stacked
      right={<Button flat onClick={() => {
        window.open(props.url);
        props.actions.popModal(false, true, ModalTypes.REPORT_FINISHED);
      }}>Open</Button>}
    />
  </Modal>
);


const mapStateToProps = (state: ApplicationState) => {
  const reportUrlSelector = makeFormModalPropSelector(ModalTypes.REPORT_FINISHED, 'reportURL');
  return {
    url: reportUrlSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
  }, dispatch)
});

const ConnectedReportFinishedModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ReportFinishedModal);

export default ConnectedReportFinishedModal;
