import * as React from 'react';
import { ModalContent, Text, Paragraph } from '../../Elements';

interface Props {
  contentAlignLeft?: boolean;
  children?: React.ReactNode;
}

const Content: React.SFC<Props> = ({ children, contentAlignLeft }: Props): React.ReactElement<Props> => (
  <ModalContent center={!contentAlignLeft} paddingBottom={0} paddingTop={0}>
    {React.Children.map(children, (child:any) => {
      if (child.type === Text || child.type === Paragraph) {
        return React.cloneElement(child, {
          size: 16,
          gray: true,
          ...child.props
        });
      }
      return child;
    })}
  </ModalContent>
);

export default Content;
