import * as React from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { CacheOneContext } from "@tentaroo/shared";

import {Main, MainContent} from '../../Layouts';
import {
  Alert,
  Button,
  CartCard,
  CartItem,
  CartList,
  Column,
  EmptyMessage,
  FieldSet,
  FinancialSummary,
  FinancialSummaryLine,
  FinancialSummaryTotal,
  Form,
  FormActions,
  LoadingAll,
  Media,
  Row,
  Select,
  Switch,
  TextField,
  Text
} from '../../Elements';
import PaymentMethod from './PaymentMethod';
import {CartIcon} from '../../Icons';
import '../../../styles/pages/checkout/index.scss';
import {actionCreators, ChangePaymentTypeActions} from "../../../store/Checkout/actions";
import {actionCreators as appActionCreators} from "../../../store/App/actions";
import {actionCreators as profileActions} from '../../../store/Settings/Profile/actions';
import {actionCreators as cacheOneActions} from "../../../store/CacheOne/actions";
import {actionCreators as cacheZeroActions} from "../../../store/CacheZero/actions";
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import {standardCurrencyFormat} from "../../../utils/classesHelper";
import {makeAllowUserCheckoutSelector} from "../../../store/Checkout";
import {EventParticipantsSave} from "../../../store/CacheFourEventsParticipants/actions";
import * as M from "../../../constants/messages/createAccount";
import { SaveState } from '../../../store/Rollback/actions';
import { isRefund } from '../../../store/Settings/PrevOrders/ManageOrder/uiHelpers';
import { ApplicationState } from '../../../store';
import EndUserCacheManager from '../../../utils/cacheManagers/endUserCacheManager';
import { shouldReconfigRouter } from '../../../utils/cacheLoaders/reloaderHelper';
import { getMergeProps } from '../../../utils/reduxHelper';
import { reduxStoreService } from '../../../store/service';

import {namespace} from "./constants";
import { ComponentUpdateTemplate } from '../../Templates/ComponentUpdateTemplate';
import { checkPermission } from '../../../utils/permissionHelper';
import {isEndUserCacheOnePopulated} from '../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../Elements/WithInert';
import {toNumber} from '../../../utils/dataHelper';


type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {}>
>;


class Checkout extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;

  public componentDidMount() {
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheOne({
          props: this.props,
          isStateNavigated,
          context: CacheOneContext.CHECKOUT,
        });
      }
    );
    
    if (this.props.cacheOne.CartOrder && this.props.cacheZero.options && this.props.cacheZero.options.PaymentTypes) {
      this.props.actions.validatePaymentTypeUponLoading();
    }
    this.configRouteLeaveHook();
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) {
      this.configRouteLeaveHook();
    }
  }

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    if (reduxStoreService().getState().app.apiLoading === 0 && isEndUserCacheOnePopulated(this.props.cacheOne)) {
      reduxStoreService().dispatch(new SaveState());
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheOne({
          props: nextProps,
          isStateNavigated,
          context: CacheOneContext.CHECKOUT,
        });
      }
    );
  }

  componentWillUnmount() {
    this.props.profileActions.resetForm();
    this.props.actions.checkoutReset();
    this.resetRouteLeaveHook();
  }

  public submit = (event: React.SyntheticEvent<any>) => {
    const { allowUserCheckout, cacheZero, actions } = this.props;
    event.preventDefault();
    
    if (!cacheZero?.options?.GeneralPermissions) return;

    checkPermission(
      () => {
        if (allowUserCheckout) {
          actions.placeOrder();
        } else {
          actions.showTopFloatingAlert(M.NO_ONLINE_PAYMENT, false, 'orange');
        }
      },
      cacheZero.options.GeneralPermissions.hasCheckout,
      cacheZero.options.GeneralPermissions.hasCheckoutReason
    );
  };

  onCopyFromProfile = (e) => {
    const { actions, profile: {ActiveForm}, checkout: {ValidationRules} } = this.props;
    e.preventDefault();
    
    const addressValue = ActiveForm.Address && ActiveForm.Address2 && ActiveForm.Address2.trim() ? `${ActiveForm.Address.trim()}, ${ActiveForm.Address2.trim()}` : ActiveForm.Address;
    actions.updateValue(ActiveForm.FirstName, ValidationRules.FirstName);
    actions.updateValue(ActiveForm.LastName, ValidationRules.LastName);
    actions.updateValue(addressValue, ValidationRules.Address);
    actions.updateValue(ActiveForm.City, ValidationRules.City);
    actions.updateValue(ActiveForm.StateID, ValidationRules.StateID);
    actions.updateValue(ActiveForm.Zip, ValidationRules.Zip);
  };

  onEmptyCart = () => {
    const {cacheZero} = this.props;

    if (!cacheZero.options?.GeneralPermissions) return;

    checkPermission(
      () => {
        this.props.actions.pushConfirmEmptyCartModal();
      },
      cacheZero.options.GeneralPermissions.hasCheckout,
      cacheZero.options.GeneralPermissions.hasCheckoutReason,
    );
  };
  onRemoveItem = (id: number, to?: string) => {
    this.props.actions.pushRemoveItemModal({id, to});
  };
  showUseCredit = () => {
    const { user, cacheOne: { CartOrder } } = this.props;
    if (!CartOrder) return false;
    const {Amount, AppliedCredit} = CartOrder;
    return user.user.str_permissions.hasAdminAccess && Amount + AppliedCredit > 0 && !isRefund(reduxStoreService().getState(), CartOrder.PaymentTypeID as number);
  };
  onSwitchUseCredit = (val, vObj) => {
    const { actions, checkout: {ActiveForm, ValidationRules} } = this.props;

    reduxStoreService().dispatch(new SaveState());
    // update UseCredit switch's value
    actions.updateValue(val, vObj);
    // update NotApplied field's value to 0 if we are toggling on `UseCredit`
    if (val) actions.updateValue(0, ValidationRules.NotApplied);
    actions.changePaymentType(ActiveForm.PaymentType as number, val as boolean);
  };


  onCancelRegistration = (
    isYouth?: boolean,
    name?: string,
    participantID?: number,
    inCart?: -1 | 0 | 1,
    eventTypeID?: number,
    eventID?: number,
    groupWeekID?: number,
    goToRegistration?: boolean,
    IsNewlyAdding?: boolean
  ) => {
    this.props.actions.pushCancelRegistrationModal(
      isYouth,
      name,
      participantID,
      inCart,
      eventTypeID,
      eventID,
      groupWeekID,
      goToRegistration,
      IsNewlyAdding,
    );
  };

  public render() {
    const {
      inert,
      cacheOne: {CartOrderItems, CartOrder}, actions, router, user, SystemSettings,
      cacheZero,
      cacheOne: {GroupBillingAddress},
      profileActions,
      checkout: {ActiveForm, ValidationRules, SubmitErrorMessage, cartItemsExpanded, CheckoutMessages, agreed, currCheckoutIndex, fullClassesErrorMessage, fullClassesUrl},
      apiLoading, apiSaving, apiSavingMap, allowUserCheckout,
      profile,
    } = this.props;

    const options = cacheZero.options;
    if (CartOrderItems === undefined || !CartOrder || !options) return <LoadingAll/>;
    if (CartOrderItems === null || CartOrderItems.length === 0) return <EmptyMessage
      icon={CartIcon}
      description="Your cart is empty"
      className={`${namespace()}--empty`}
    />;

    const isLoading = apiSavingMap[ChangePaymentTypeActions.requestType] || apiSavingMap[EventParticipantsSave.requestType];

    const NotApplied = toNumber(ActiveForm.NotApplied);

    const {Amount, AppliedCredit} = CartOrder;
    const total = standardCurrencyFormat(Amount + NotApplied);
    const showAddressFields = allowUserCheckout && total.replace('$', '') !== 'NaN' && (Amount !== 0 || NotApplied !== 0);
    const hasAccess = cacheZero?.options?.GeneralPermissions?.hasCheckout;
    const isInactiveGroup = cacheZero?.options?.Group?.Inactive ?? true;
    const disabled = !hasAccess || isInactiveGroup || apiSaving > 0;
    const formErrorMessage = SubmitErrorMessage || profile.SubmitErrorMessage;

    let appliedCredit = standardCurrencyFormat(AppliedCredit);
    if (AppliedCredit !== 0) {
      appliedCredit = '-' + appliedCredit;
    }

    // Also blocking <MainContent /> when group is inactive because there's
    // no Restore button in this form, so we should block all buttons
    // when inactive
    return (
      <Main inert={inert}>
        <MainContent loading={isLoading}>
          {!hasAccess && options.GeneralPermissions && <Row hardOpacity key="access">
            <Alert>{options.GeneralPermissions.hasCheckoutReason}</Alert>
          </Row>}
          <CartCard open={cartItemsExpanded} onToggle={actions.toggleCartItems} numCartItems={CartOrderItems.length} onEmptyCart={this.onEmptyCart}>
            <CartList>
              {CartOrderItems.map((cartItem) => (
                <CartItem
                  isAdmin={!!user.user.str_permissions.hasAdminAccess}
                  router={router}
                  CartOrderItem={cartItem}
                  onCancelRegistration={this.onCancelRegistration}
                  onRemove={this.onRemoveItem}
                  popModal={this.props.actions.popModal}
                  cacheZero={this.props.cacheZero}
                />
              ))}
            </CartList>
          </CartCard>
          <FinancialSummary>
            <FinancialSummaryLine disabled={disabled} label="Total Items" value={standardCurrencyFormat(Amount + AppliedCredit)}/>
            <FinancialSummaryLine label="Applied Credit"
                                  disabled={disabled}
                                  lineSwitch={this.showUseCredit() ? 
                                    <Media tabletAndGreater>
                                      <Switch
                                        disabled={disabled}
                                        margin={false}
                                        label="Use Credit"
                                        value={ActiveForm.UseCredit as boolean}
                                        validationRules={ValidationRules.UseCredit}
                                        onChange={this.onSwitchUseCredit}
                                    /></Media> : undefined}
                                  value={appliedCredit}/>
            {this.showUseCredit() ? <Media mobile>
              <FinancialSummaryLine hideTextField admin
                                  centerItems
                                  disabled={disabled}
                                  label="Use Credit"
                                  lineSwitch={<Switch
                                    margin={false}
                                    disabled={disabled}
                                    label=""
                                    value={ActiveForm.UseCredit as boolean}
                                    validationRules={ValidationRules.UseCredit}
                                    onChange={this.onSwitchUseCredit}
                                  />}/>
            </Media> : null}
            {user.user.str_permissions.hasAdminAccess && (!ActiveForm.UseCredit || Amount + AppliedCredit === 0) && Amount >= 0 ?
              <FinancialSummaryLine admin
                                    centerItems
                                    disabled={disabled}
                                    limitPriceWidth
                                    hideError
                                    label="Unapplied"
                                    value={ActiveForm.NotApplied}
                                    editable
                                    onEditChange={actions.simpleUpdate}
                                    onEditBlur={actions.updateValue}
                                    editValidationRules={ValidationRules.NotApplied}
              /> : null}
            <FinancialSummaryTotal title="Total Payment" marginTop={8} noMarginBottom value={total}/>
          </FinancialSummary>
          {allowUserCheckout && formErrorMessage && <Alert marginTop={8} className={`${namespace()}--error`} noFlex>{formErrorMessage}</Alert>}
          {!allowUserCheckout && <Alert marginTop={8} className={`${namespace()}--error`} noFlex>{M.NO_ONLINE_PAYMENT}</Alert>}
          {allowUserCheckout && !formErrorMessage && 
            <Alert marginTop={8} noFlex>
              <Text color="white" size={14}>
                <strong>{M.PROFILE_CONTACT_ALERT1}</strong>
                <br />
                {M.PROFILE_CONTACT_ALERT2}
              </Text>
            </Alert>
          }
          <Form actions={<FormActions>
                  <Button color='white' disabled={disabled} submit big onClick={this.submit}>
                    PLACE ORDER
                  </Button>
                </FormActions>}
          >
          {allowUserCheckout && <FieldSet emptyFieldSet newDesign legendMarginBottom={0} fontSize={28} name="Profile Contact Information"></FieldSet>}
          {allowUserCheckout && <FieldSet newDesign className="compact-margin-bottom" fontSize={20} name="Primary Contact">
            <Row>
              <Column span={6} mobileSpan={8}>
                <TextField label="First Name"
                          disabled={disabled}
                          onChange={profileActions.simpleUpdate}
                          onBlur={profileActions.updateValue}
                          validationRules={profile.ValidationRules.FirstName}
                          value={profile.ActiveForm.FirstName}/>
              </Column>
              <Column span={3} mobileSpan={4}>
                <TextField label="MI"
                          disabled={disabled}
                          onChange={profileActions.simpleUpdate}
                          onBlur={profileActions.updateValue}
                          validationRules={profile.ValidationRules.MI}
                          value={profile.ActiveForm.MI}/>
              </Column>
            </Row>
            <Row>
              <Column span={6} mobileSpan={8}>
                <TextField label="Last Name"
                          disabled={disabled}
                          onChange={profileActions.simpleUpdate}
                          onBlur={profileActions.updateValue}
                          validationRules={profile.ValidationRules.LastName}
                          value={profile.ActiveForm.LastName}/>
              </Column>
              <Column span={3} mobileSpan={4}>
                <TextField label="Suffix"
                          disabled={disabled}
                          onChange={profileActions.simpleUpdate}
                          onBlur={profileActions.updateValue}
                          validationRules={profile.ValidationRules.Suffix}
                          value={profile.ActiveForm.Suffix}/>
              </Column>
            </Row>
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField label="Email"
                          disabled={disabled}
                          onChange={profileActions.simpleUpdate}
                          onBlur={profileActions.updateValue}
                          validationRules={profile.ValidationRules.Email}
                          value={profile.ActiveForm.Email}/>
              </Column>
            </Row>
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField label="Phone Number"
                          disabled={disabled}
                          onChange={profileActions.simpleUpdate}
                          onBlur={profileActions.updateValue}
                          validationRules={profile.ValidationRules.PhoneNumber}
                          value={profile.ActiveForm.PhoneNumber}/>
              </Column>
              <Column span={6} mobileSpan={12}>
                <TextField label="Alternate Phone Number"
                          disabled={disabled}
                          onChange={profileActions.simpleUpdate}
                          onBlur={profileActions.updateValue}
                          validationRules={profile.ValidationRules.PhoneNumber2}
                          value={profile.ActiveForm.PhoneNumber2}/>
              </Column>
            </Row>
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField label="Address Line 1"
                          disabled={disabled}
                          onChange={profileActions.simpleUpdate}
                          onBlur={profileActions.updateValue}
                          validationRules={profile.ValidationRules.Address}
                          value={profile.ActiveForm.Address}/>
              </Column>
              <Column span={6} mobileSpan={12}>
                <TextField label="Address Line 2"
                          disabled={disabled}
                          onChange={profileActions.simpleUpdate}
                          onBlur={profileActions.updateValue}
                          validationRules={profile.ValidationRules.Address2}
                          value={profile.ActiveForm.Address2}/>
              </Column>
            </Row>
            <Media tabletAndGreater>
              <Row>
                <Column span={6}>
                  <TextField label="City"
                            disabled={disabled}
                            onChange={profileActions.simpleUpdate}
                            onBlur={profileActions.updateValue}
                            validationRules={profile.ValidationRules.City}
                            value={profile.ActiveForm.City}/>
                </Column>
                <Column span={3}>
                  <Select label="State"
                          disabled={disabled}
                          onChangeValue={profileActions.updateValue}
                          value={profile.ActiveForm.StateID}
                          validationRules={profile.ValidationRules.StateID}
                          isNumber
                  />
                </Column>
                <Column span={3}>
                  <TextField label="ZIP Code"
                            disabled={disabled}
                            onChange={profileActions.simpleUpdate}
                            onBlur={profileActions.updateValue}
                            validationRules={profile.ValidationRules.Zip}
                            value={profile.ActiveForm.Zip}/>
                </Column>
              </Row>
            </Media>
            <Media mobile>
              <Row>
                <Column mobileSpan={12}>
                  <TextField label="City"
                            disabled={disabled}
                            onChange={profileActions.simpleUpdate}
                            onBlur={profileActions.updateValue}
                            validationRules={profile.ValidationRules.City}
                            value={profile.ActiveForm.City}/>
                </Column>
              </Row>
              <Row>
                <Column mobileSpan={6}>
                  <Select label="State"
                          disabled={disabled}
                          onChangeValue={profileActions.updateValue}
                          value={profile.ActiveForm.StateID}
                          validationRules={profile.ValidationRules.StateID}
                          isNumber
                  />
                </Column>
                <Column mobileSpan={6}>
                  <TextField label="ZIP Code"
                            disabled={disabled}
                            onChange={profileActions.simpleUpdate}
                            onBlur={profileActions.updateValue}
                            validationRules={profile.ValidationRules.Zip}
                            value={profile.ActiveForm.Zip}/>
                </Column>
              </Row>
            </Media>
          </FieldSet>}

          {allowUserCheckout && <FieldSet newDesign fontSize={20} name="Alternate Contact">
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField label="Full Name"
                          disabled={disabled}
                          onChange={profileActions.simpleUpdate}
                          onBlur={profileActions.updateValue}
                          validationRules={profile.ValidationRules.Contact2Name}
                          value={profile.ActiveForm.Contact2Name}/>
              </Column>
            </Row>
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField label="Email"
                          id="ContactEmail"
                          disabled={disabled}
                          onChange={profileActions.simpleUpdate}
                          onBlur={profileActions.updateValue}
                          validationRules={profile.ValidationRules.Contact2Email}
                          value={profile.ActiveForm.Contact2Email}/>
              </Column>
              <Column span={6} mobileSpan={12}>
                <TextField label="Phone Number"
                          id="ContactPhoneNumber"
                          disabled={disabled}
                          onChange={profileActions.simpleUpdate}
                          onBlur={profileActions.updateValue}
                          validationRules={profile.ValidationRules.Contact2Phone}
                          value={profile.ActiveForm.Contact2Phone}/>
              </Column>
            </Row>
          </FieldSet>}
          {allowUserCheckout && showAddressFields && GroupBillingAddress && <FieldSet
            newDesign
            fontSize={28}
            name="Billing Contact"
            controlsNoMarginTop
            controls={
              <Button disabled={disabled} color="white" key={1} onClick={this.onCopyFromProfile}>
                Copy From Profile
              </Button>
            }
          >
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField label="First Name"
                          disabled={disabled}
                          onChange={actions.simpleUpdate}
                          onBlur={actions.updateValue}
                          validationRules={ValidationRules.FirstName}
                          value={ActiveForm.FirstName}/>
              </Column>
              <Column span={6} mobileSpan={12}>
                <TextField label="Last Name"
                          disabled={disabled}
                          onChange={actions.simpleUpdate}
                          onBlur={actions.updateValue}
                          validationRules={ValidationRules.LastName}
                          value={ActiveForm.LastName}/>
              </Column>
            </Row>
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField value={ActiveForm.Address}
                          label="Address"
                          disabled={disabled}
                          onChange={actions.simpleUpdate}
                          onBlur={actions.updateValue}
                          validationRules={ValidationRules.Address}
                />
              </Column>
            </Row>
              <Media tabletAndGreater>
                <Row>
                  <Column span={6}>
                    <TextField value={ActiveForm.City}
                              label="City"
                              disabled={disabled}
                              onChange={actions.simpleUpdate}
                              onBlur={actions.updateValue}
                              validationRules={ValidationRules.City}
                    />
                  </Column>
                  <Column span={3}>
                    <Select label="State"
                          disabled={disabled}
                            onChangeValue={actions.updateValue}
                            value={ActiveForm.StateID}
                            validationRules={ValidationRules.StateID}
                            isNumber
                    />
                  </Column>
                  <Column span={3}>
                    <TextField value={ActiveForm.Zip}
                              label="Zip Code"
                              disabled={disabled}
                              onChange={actions.simpleUpdate}
                              onBlur={actions.updateValue}
                              validationRules={ValidationRules.Zip}
                    />
                  </Column>
                </Row>
              </Media>

              <Media mobile>
                <Row>
                  <Column mobileSpan={12}>
                    <TextField value={ActiveForm.City}
                              label="City"
                              disabled={disabled}
                              onChange={actions.simpleUpdate}
                              onBlur={actions.updateValue}
                              validationRules={ValidationRules.City}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column mobileSpan={6}>
                    <Select label="State"
                            disabled={disabled}
                            onChangeValue={actions.updateValue}
                            value={ActiveForm.StateID}
                            validationRules={ValidationRules.StateID}
                            isNumber
                    />
                  </Column>
                  <Column mobileSpan={6}>
                    <TextField value={ActiveForm.Zip}
                              label="Zip Code"
                              disabled={disabled}
                              onChange={actions.simpleUpdate}
                              onBlur={actions.updateValue}
                              validationRules={ValidationRules.Zip}
                    />
                  </Column>
                </Row>
              </Media>
            </FieldSet>}
            {allowUserCheckout && SystemSettings && <PaymentMethod
              isAdmin={!!user.user.str_permissions.hasAdminAccess}
              cacheOne={this.props.cacheOne}
              actions={actions}
              checkout={this.props.checkout}
              ShowAmEx={SystemSettings.ShowAmEx}
              ShowCCDiscover={SystemSettings.ShowCCDiscover}
              AllowCCPayment={SystemSettings.AllowCCPayment}
              AllowECheckPayment={SystemSettings.AllowECheckPayment}
              hidePaymentType={!showAddressFields}
              CheckoutAndPrivacyPolicies={SystemSettings.CheckoutAndPrivacyPolicies}
              disabled={disabled}
            />}
          </Form>
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const allowUserCheckoutSelector = makeAllowUserCheckoutSelector();
  return {
    cacheZero: state.cacheZero,
    cacheOne: state.cacheOne,
    profile: state.settings.profile,
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSavingMap: state.app.apiSavingMap,
    user: state.user,
    SystemSettings: state.session.SystemSettings,
    checkout: state.checkout,
    allowUserCheckout: allowUserCheckoutSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...cacheZeroActions,
    ...cacheOneActions,
  }, dispatch),
  profileActions: bindActionCreators({
    ...profileActions,
  }, dispatch),
});

const ConnectedCheckout = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(Checkout);

export default withRouter<{}>(ConnectedCheckout);
export {default as CheckoutHeader} from './Header';
