import * as React from 'react';
import { default as StandardRange, Props as StandardRangeProps } from './Standard/Range';
import { default as CompactRange, Props as CompactRangeProps } from './Compact/Range';
import {
  Availability, FacilitiesAvailabilitiesDate,
  FacilitiesAvailability
} from "../../../models/api/cacheTwoFacilties";
import moment from "moment";
import {FacilityLocation} from "../../../models/api/cacheOne";
import { FacilitiesLocation } from '../../../models/api/adminFacilitiesCacheOne';

interface Props {
  template?: 'standard' | 'compact';
  start: string | moment.Moment;
  end: string | moment.Moment;
  availabilities?: Array<Availability>;
  availabilityDates?: Array<FacilitiesAvailabilitiesDate>;
  title?: React.ReactNode | string;
  facilityLocation?: FacilityLocation | FacilitiesLocation;
  facilitiesAvailability?: FacilitiesAvailability;
  onClickTitle?: (avail: FacilitiesAvailability) => void;
  showAdminBadge?: boolean;
}

const Range: React.SFC<Props> = ({ template, availabilities, availabilityDates, ...props }: Props): React.ReactElement<Props> => {
  if (template === 'standard') {
    return <StandardRange {...props as any} availabilities={availabilities} availabilityDates={availabilityDates}/>;
  } else {
    return <CompactRange {...props as any} availabilities={availabilities} availabilityDates={availabilityDates}/>;
  }
};


Range.defaultProps = {
  template: 'standard'
};

export default Range;
