import * as React from 'react';
import { default as StandardMonth, Props as StandardProps } from './Standard';
import { Month as CompactMonth } from './Compact';

interface Props extends StandardProps {
  template?: 'standard' | 'compact';
}

const Month: React.SFC<Props> = ({ template, ...props }: Props): React.ReactElement<Props> => {
  if (template === 'standard') {
    return <StandardMonth {...props}/>;
  } else {
    return <CompactMonth {...props}/>;
  }
};

Month.defaultProps = {
  template: 'standard'
};

export default Month;