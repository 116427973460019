import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminCMSCacheTwoContactContext } from "@tentaroo/shared";

import { ApplicationState } from '../../../../../../store';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as adminCMSCacheTwoContactActionCreator, GetContact} from "../../../../../../store/AdminCMSSite/CacheTwoContacts/actions";
import Contact from './Form';
import { 
    actionCreators as rollbackActionCreators,
} from '../../../../../../store/Rollback/actions';
import { actionCreators } from "../../../../../../store/AdminCMSSite/Contacts/Contact/Form/actions";
import { LoadingAll } from '../../../../../../components/Elements';
import { INVALID_CONTACT } from '../../../../../../constants/messages/adminCMS';
import { NotFound } from '../../../../../../components/Pages';
import { ADMIN_CMS_CONTACT_EDIT_PATH, ADMIN_CMS_CONTACT_NEW_PATH, ADMIN_CMS_CONTACT_PREVIEW_PATH } from '../../../../../../routes';
import { checkContactPermission, IAdminCMSContactRouterParams } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { isPathnameMatchingRoute } from '../../../../../../utils/urlHelper';
import AdminCMSCacheManager from '../../../../../../utils/cacheManagers/adminCMSCacheManager';
import { shouldReconfigRouter } from '../../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import { reduxStoreService } from '../../../../../../store/service';
import {isAdminCMSCacheOnePopulated, isAdminCMSCacheTwoContactPopulated} from '../../../../../../utils/cachePopulatedCheckers/adminCMS';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--contact';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminCMSContactRouterParams, {}>
>;

class EditContact extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
  private nextLocation;

  public componentDidMount() {
    this.props.actions.showAdminPageHeader(false);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        const {routes} = this.props;
        const route = routes[routes.length - 1];

        AdminCMSCacheManager.getInstance().loadCacheTwoContact({
          props: this.props,
          isStateNavigated,
          isEdit: route.path === ADMIN_CMS_CONTACT_EDIT_PATH,
          context: AdminCMSCacheTwoContactContext.CONTACT_FORM,
        });
      }
    );
    this.configRouter();
  }

  configRouter() {
    const {router, routes} = this.props;
    router.setRouteLeaveHook(routes[routes.length - 1], this.routerWillLeave);
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {

    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        const {params} = nextProps;

        AdminCMSCacheManager.getInstance().loadCacheTwoContact({
          props: nextProps,
          isStateNavigated,
          isEdit: !!params.contactId,
          context: AdminCMSCacheTwoContactContext.CONTACT_FORM,
        });
      }
    );
  }

  routerWillLeave = (nextLocation) => {
    const {params, adminCMSCacheTwoContact, actions, apiLoading} = this.props;
    
    this.nextLocation = nextLocation;

    if (reduxStoreService().getState().app.apiLoading === 0) {
      if (!params.contactId || isAdminCMSCacheTwoContactPopulated(adminCMSCacheTwoContact)) {
        actions.saveState();
      }
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentWillUnmount() {
    const {params, adminCMSCacheTwoContact, actions} = this.props;

    // if we are NOT navigating away to ContactPreview, and cache two is loaded, then clear cache, which will also reset the form to empty
    // otherwise, reset the form to values stored in cache two
    if (
      this.nextLocation &&
      !isPathnameMatchingRoute(this.nextLocation.pathname, ADMIN_CMS_CONTACT_PREVIEW_PATH)
    ) {
      if (!params.contactId || isAdminCMSCacheTwoContactPopulated(adminCMSCacheTwoContact)) {
        actions.clearAdminCMSCacheTwoContact();
      }
    }

    this.resetRouteLeaveHook();
  }

  onSave = () => {
    this.props.actions.apiSubmitForm(this.props.routes);
  };
  onDelete = () => {
    const {adminCMSCacheTwoContact, actions, routes, router} = this.props;
    if (!adminCMSCacheTwoContact.CMSContact) return;
    actions.deleteContact(adminCMSCacheTwoContact.CMSContact.ID, router, routes);
  };

  public render() {
    const {adminCMSCacheOne, inert, apiLoadingMap, contactForm: {ActiveForm, ValidationRules, SubmitErrorMessage}, cacheZero, actions, apiSaving, adminCMSCacheTwoContact, routes} = this.props;
    const route = routes[routes.length - 1];

    if (
      !isAdminCMSCacheOnePopulated() ||
      (route.path === ADMIN_CMS_CONTACT_EDIT_PATH && (apiLoadingMap[GetContact.requestType] || !isAdminCMSCacheTwoContactPopulated(adminCMSCacheTwoContact, ActiveForm)))
    ) {
        return <LoadingAll />;
    }

    if (route.path === ADMIN_CMS_CONTACT_EDIT_PATH && adminCMSCacheTwoContact.CMSContact && adminCMSCacheTwoContact.CMSContact.Inactive) {
      return (
        <NotFound message={INVALID_CONTACT}/>
      );
    }

    let unauthorizedReason;
    if (route.path === ADMIN_CMS_CONTACT_NEW_PATH) {
      unauthorizedReason = checkContactPermission(
        () => true,
        cacheZero,
        adminCMSCacheOne,
        undefined,
        false,
        true,
      );
    } else if (route.path === ADMIN_CMS_CONTACT_EDIT_PATH && adminCMSCacheTwoContact.CMSContact) {
      unauthorizedReason = checkContactPermission(
        () => true,
        cacheZero,
        adminCMSCacheOne,
        adminCMSCacheTwoContact.CMSContact.SiteID,
        false,
        true,
      );
    }
    if (unauthorizedReason) {
      return (
        <NotFound message={unauthorizedReason}/>
      );
    }

    return (
      <Contact
        inert={inert}
        ActiveForm={ActiveForm}
        ValidationRules={ValidationRules}
        SubmitErrorMessage={SubmitErrorMessage}
        reduxActions={actions}
        disabled={apiSaving > 0 || !isAdminCMSCacheOnePopulated() || (route.path === ADMIN_CMS_CONTACT_EDIT_PATH && !isAdminCMSCacheTwoContactPopulated(adminCMSCacheTwoContact))}
        action={route.path === ADMIN_CMS_CONTACT_NEW_PATH ? 'add' : 'edit'}
        onSave={this.onSave}
        onDelete={this.onDelete}
        loading={apiSaving > 0}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    adminCMSCacheTwoContact: state.adminCMSSite.cacheTwoContacts,
    cacheZero: state.cacheZero,
    contactForm: state.adminCMSSite.contacts.contact.form,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...rollbackActionCreators,
    ...appActionCreators,
    ...adminCMSCacheTwoContactActionCreator,
  }, dispatch)
});

const ConnectedEditContact = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(EditContact);

export default withRouter<{}>(ConnectedEditContact);
