import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminFacilityLocationCacheOneContext } from "@tentaroo/shared";

import { ApplicationState } from '../../../../../store';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as facilityTypeFormActionCreators } from '../../../../../store/AdminFacilityLocation/Facilities/FacilityType/Form/actions';
import { actionCreators as facilitiesHomeActionCreators } from '../../../../../store/AdminFacilityLocation/Facilities/Home/actions';
import { FormDefinition } from '../../../../../store/AdminFacilityLocation/Facilities/Home/validation';
import { actionCreators, GetFacilityLocationCache } from "../../../../../store/AdminFacilityLocation/CacheOne/actions";
import { LoadingAll, EmptyMessage, Button, Loader, Tabs, Tab } from '../../../../Elements';
import { Main, MainContent } from '../../../../Layouts';
import '../../../../../styles/pages/admin-facility-location/facilities/home/index.scss';
import { ADMIN_FACILITY_LOCATION_FACILITIES_PATH } from '../../../../../routes';
import { getFacilityTypesRootUrl, constructAdminFacilityLocationUrlParams, getFacilitiesHomeRootUrl, getNewFacilityTypeUrl, getEditFacilityTypeUrl, constructAdminFacilityLocationFacilityTypeUrlParams } from '../../../../../constants/urls';
import { navPush } from '../../../../../utils';
import FacilitiesFilter from './FacilitiesFilter';
import FacilityTypesFilter from './FacilityTypesFilter';
import { makeFilteredFacilityTypesSelector, makeActiveFacilityTypesSelector } from '../../../../../store/AdminFacilityLocation/Facilities/Home';
import { AdminFacilityType } from '../../../../../models/api/adminFacilitiesCacheOne';
import { ENTITY_NOT_ADDED, ENTITY_NOT_FOUND } from '../../../../../constants/messages/adminCMS';
import { FACILITY_TYPES, FACILITY_TYPE_NOT_ADDED_DETAIL } from '../../../../../constants/messages/adminFacilityLocation';
import { FacilitiesTypes2Icon } from '../../../../../components/Icons';
import FacilityTypeCard, { FacilityTypeCardType } from '../../../../../components/Elements/FacilityType/Card';
import { CardCategory } from '../../../../../components/Elements/Card';
import FacilityList from './FacilityList';
import { EmptyMessageType } from '../../../../../components/Elements/EmptyMessage';
import { checkFacilityTypePermission, checkFacilityPermission, IAdminFacilitiesLocationRouterParams } from '../../../../../utils/helpers/adminFacilityLocationPageHelper';
import { ModalTypes, noOpenedModals } from '../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../store/service';
import AdminFacilitiesLocationCacheManager from '../../../../../utils/cacheManagers/adminFacilitiesLocationCacheManager';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isAdminFacilitiesLocationCacheOnePopulated} from '../../../../../utils/cachePopulatedCheckers/adminFacilities';
import { WithInertAttribute } from '../../../../Elements/WithInert';


export const namespace = (): string => 'pages--facility-location--facilities';

enum FacilitiesHomeTabs {
  FACILITIES,
  FACILITY_TYPES,
}

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminFacilitiesLocationRouterParams, {}>
>;

class FacilitiesHome extends ComponentUpdateTemplate<ConnectedProps, {}> {
  public props: ConnectedProps;

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        AdminFacilitiesLocationCacheManager.getInstance().loadAdminFacilitiesLocationCacheOne({
          props: this.props,
          isStateNavigated,
          context: AdminFacilityLocationCacheOneContext.FACILITIES_LIST,
        });
      }
    );
  }
  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        AdminFacilitiesLocationCacheManager.getInstance().loadAdminFacilitiesLocationCacheOne({
          props: nextProps,
          isStateNavigated,
          context: AdminFacilityLocationCacheOneContext.FACILITIES_LIST,
        });
      });
  }
  componentWillUnmount() {
  }

  onClickFacilities = () => {
    const {adminFacilityLocationCacheOne} = this.props;
    const url = getFacilitiesHomeRootUrl(constructAdminFacilityLocationUrlParams(this.props, adminFacilityLocationCacheOne));
    this.props.actions.updateValue(undefined, FormDefinition.FilterText);
    
    navPush(this.props.router, url);
  };
  onClickTypes = () => {
    const {adminFacilityLocationCacheOne} = this.props;
    const url = getFacilityTypesRootUrl(constructAdminFacilityLocationUrlParams(this.props, adminFacilityLocationCacheOne));
    this.props.actions.updateValue(undefined, FormDefinition.FacilityTypeFilterText);

    navPush(this.props.router, url);
  };

  getSelectedTab = () => {
    const {routes} = this.props;
    const thisRoute = routes[routes.length - 1];
    const selectedTab = thisRoute.path === ADMIN_FACILITY_LOCATION_FACILITIES_PATH ? FacilitiesHomeTabs.FACILITIES : FacilitiesHomeTabs.FACILITY_TYPES;

    return selectedTab;
  };

  renderEmptyMessage = (emptyMessage: string) => {
    const selectedTab = this.getSelectedTab();
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        admin
        icon={FacilitiesTypes2Icon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        noMobilePadding
        description={emptyMessage}
        detail={FACILITY_TYPE_NOT_ADDED_DETAIL}
        actions={<Button className={`${namespace()}--empty-message-btn`} color="green" onClick={selectedTab === FacilitiesHomeTabs.FACILITIES ? this.onNewFacilityClick : this.onNewFacilityTypeClick}>{selectedTab === FacilitiesHomeTabs.FACILITIES ? 'NEW FACILITY' : 'NEW FACILITY TYPE'}</Button>}/>
    );
  };

  renderFacilityTypesContent = () => {
    const {filteredFacilityTypes} = this.props;

    return (
      <div className={`${namespace()}--list-wrapper`}>
        {filteredFacilityTypes.map((ft, index) => {
          return (
            <FacilityTypeCard
              key={`facility_type_card${index}`}
              type={FacilityTypeCardType.FACILIT_TYPES_LIST}
              category={CardCategory.LIST}
              facilityType={ft}
              onEdit={this.onEditFacilityTypeClick}
              onRestore={this.onRestoreFacilityTypeClick}
              onDelete={this.onDeleteFacilityTypeClick}
            />
          );
        })}
      </div>
    );
  };

  onNewFacilityTypeClick = (e) => {
    e.stopPropagation();
    checkFacilityTypePermission(
      () => {
        const newFacilityTypeUrl = getNewFacilityTypeUrl(constructAdminFacilityLocationUrlParams(this.props, this.props.adminFacilityLocationCacheOne));
    
        navPush(this.props.router, newFacilityTypeUrl);
      },
      reduxStoreService().getState().cacheZero,
    );
  };
  onEditFacilityTypeClick = (facilityType: AdminFacilityType) => {
    const editFacilityTypeUrl = getEditFacilityTypeUrl(constructAdminFacilityLocationFacilityTypeUrlParams(this.props, this.props.adminFacilityLocationCacheOne, facilityType));
    navPush(this.props.router, editFacilityTypeUrl);
  };
  onDeleteFacilityTypeClick = (facilityType: AdminFacilityType) => {
    checkFacilityTypePermission(
      () => {
        this.props.actions.pushModal(ModalTypes.DELETE_FACILITY_TYPE, false, true, {id: facilityType.ID});
      },
      reduxStoreService().getState().cacheZero,
      true,
    );
  };
  onRestoreFacilityTypeClick = (facilityType: AdminFacilityType) => {
    checkFacilityTypePermission(
      () => {
        this.props.facilityTypeFormActions.deleteFacilityType(
          this.props.routes,
          this.props.router,
          facilityType.ID,
          true,
        );
      },
      reduxStoreService().getState().cacheZero,
      true,
    );
  };

  onNewFacilityClick = () => {
    checkFacilityPermission(
      () => {
        this.props.actions.pushNewFacilityModal();
      },
      this.props.adminFacilityLocationCacheOne,
    );
  };

  public render() {
    const {apiLoadingMap, apiSaving, actions, adminFacilityLocationCacheOne, facilitiesHomeForm, filteredFacilityTypes, inert, activeFacilityTypes} = this.props;
    if (!isAdminFacilitiesLocationCacheOnePopulated()) {
      return <LoadingAll />;
    }
    const selectedTab = this.getSelectedTab();

    let emptyFacilityTypesMessage;

    if (selectedTab === FacilitiesHomeTabs.FACILITY_TYPES) {
      const facilityTypeFilterText = facilitiesHomeForm.ActiveForm.FacilityTypeFilterText;
      if (activeFacilityTypes.length === 0 && filteredFacilityTypes.length === 0) {
        emptyFacilityTypesMessage = ENTITY_NOT_ADDED(FACILITY_TYPES);
      } else if (filteredFacilityTypes.length === 0 && facilityTypeFilterText) {
        emptyFacilityTypesMessage = ENTITY_NOT_FOUND(FACILITY_TYPES);
      }
    }

    const refreshingList = apiLoadingMap[GetFacilityLocationCache.requestType] && noOpenedModals();
    const saving = apiSaving > 0 && noOpenedModals();
    
    return (
      <Main inert={inert} isLoading={refreshingList} isAdminFacilityLocationPage>
        <MainContent className={namespace()} handleCompact>
          <Tabs className={`${namespace()}--tabs`}>
            <Tab id={generateDOMId("admin-fac-facilities-tab")} selected={selectedTab === FacilitiesHomeTabs.FACILITIES} onClick={this.onClickFacilities}>FACILITIES</Tab>
            <Tab id={generateDOMId("admin-fac-facility-types-tab")} selected={selectedTab === FacilitiesHomeTabs.FACILITY_TYPES} onClick={this.onClickTypes}>TYPES</Tab>
          </Tabs>
          <div className={`${namespace()}--list-actions`}>
            {selectedTab === FacilitiesHomeTabs.FACILITIES ? (
              <FacilitiesFilter
                ActiveForm={facilitiesHomeForm.ActiveForm}
                ValidationRules={facilitiesHomeForm.ValidationRules}
                reduxActions={actions}
                expand={facilitiesHomeForm.expandFilter}
                newButton={
                  <Button id={generateDOMId("new-facility-btn")} className='control' color='green' textColor='white' onClick={this.onNewFacilityClick}>New</Button>}
              />) : null
            }
            {selectedTab === FacilitiesHomeTabs.FACILITY_TYPES ? (
              <FacilityTypesFilter
                ActiveForm={facilitiesHomeForm.ActiveForm}
                ValidationRules={facilitiesHomeForm.ValidationRules}
                reduxActions={actions}
                newButton={
                  <Button id={generateDOMId("new-facility-type-btn")} className='control' color='green' textColor='white' onClick={this.onNewFacilityTypeClick}>New</Button>}
              />) : null
            }
          </div>
          {emptyFacilityTypesMessage && selectedTab === FacilitiesHomeTabs.FACILITY_TYPES && this.renderEmptyMessage(emptyFacilityTypesMessage)}
          {!emptyFacilityTypesMessage && selectedTab === FacilitiesHomeTabs.FACILITY_TYPES && this.renderFacilityTypesContent()}
          {selectedTab === FacilitiesHomeTabs.FACILITIES && (
            <div className={`${namespace()}--list-wrapper`}>
              <FacilityList />
            </div>
          )}
        </MainContent>
        {saving && <Loader className={`${namespace()}--loader`} center />}
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredFacilityTypesSelector = makeFilteredFacilityTypesSelector();
  const activeFacilityTypesSelector = makeActiveFacilityTypesSelector();
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    app: state.app,
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
    facilitiesHomeForm: state.adminFacilityLocation.facilities.home,
    filteredFacilityTypes: filteredFacilityTypesSelector(state),
    activeFacilityTypes: activeFacilityTypesSelector(state),
    cacheZero: state.cacheZero,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...facilitiesHomeActionCreators,
  }, dispatch),
  facilityTypeFormActions: bindActionCreators({
    ...facilityTypeFormActionCreators,
  }, dispatch),
});

const ConnectedFacilitiesHome = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(FacilitiesHome);

export default withRouter<{}>(ConnectedFacilitiesHome);
