import * as React from 'react';
import { findDOMNode } from 'react-dom';
import moment from 'moment';

import ActionButton from '../ActionButton';
import '../../../styles/elements/month-picker/index.scss';
import { isDescendant, isVisible } from '../../../utils';
import Arrow from '../../../images/elements/month-picker/arrow.svg';
import ContextMenu from './ContextMenu';
import Picker from './Picker';

import {namespace} from "./constants";
import {Nullable} from '../../../utils/dataHelper';

interface Props {
  date: moment.Moment;
  showContextMenu: boolean;
  disableNext: boolean;
  disablePrev: boolean;
  onChange: (date: moment.Moment) => void;
  onShowMonthMenu: (show: boolean) => void;
}

class MonthPicker extends React.Component<Props, {}> {
  public props: Props;

  public showContextMenu = () => {
    document.addEventListener('click', this.handleOutsideClick);
    document.addEventListener('contextmenu', this.handleOutsideClick);
    this.props.onShowMonthMenu(true);
  };

  public handleOutsideClick = (event: MouseEvent) => {
    const contextMenu = findDOMNode(this.refs.contextMenu) as Nullable<HTMLElement>;
    if (contextMenu && isVisible(contextMenu) && !isDescendant(contextMenu, event.target as Nullable<Element>)) {
      this.close();
    }
  };

  public close = () => {
    document.removeEventListener('click', this.handleOutsideClick);
    document.removeEventListener('contextmenu', this.handleOutsideClick);
    this.props.onShowMonthMenu(false);
  };

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
    document.removeEventListener('contextmenu', this.handleOutsideClick);
  }

  public handlePrevClick = (e) => {
    e.preventDefault();
    this.changeDate(this.props.date.clone().subtract(1, 'month'));
  };

  public handleNextClick = (e) => {
    e.preventDefault();
    this.changeDate(this.props.date.clone().add(1, 'month'));
  };

  public changeDate = (nextDate: moment.Moment) => {
    nextDate = nextDate.startOf('month');
    this.props.onChange(nextDate);
  };

  public render() {
    const { showContextMenu, date, disableNext, disablePrev } = this.props;
    return (
      <div className={namespace()}>
        {showContextMenu ? (
          <ContextMenu
            ref="contextMenu"
            value={date}
            onClose={this.close}
            onChange={this.changeDate}
            target={() => findDOMNode(this) as Element}
          />
        ) : null}
        <Picker
          open={showContextMenu}
          value={date}
          onChange={this.changeDate}
          onClose={this.close}
        />
        <ActionButton
          disabled={disablePrev}
          icon={<Arrow/>}
          title="Previous"
          onClick={this.handlePrevClick}
        />
        <a className={`${namespace()}--current-month`} onClick={this.showContextMenu}>
          {date.format('MMMM YYYY')}
        </a>
        <ActionButton
          disabled={disableNext}
          icon={<Arrow className={`${namespace()}--next rotate-180`}/>}
          title="Next"
          onClick={this.handleNextClick}
        />
      </div>
    );
  }
}

export default MonthPicker;
