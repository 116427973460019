import * as M from '../../../../constants/messages/generic';
import { isJustNumbers } from "../../EventTypes/EventType/Form/uiHelpers";
import { Validator } from '../../../../utils/validator/models';
import { toNumber } from '../../../../utils/dataHelper';

export interface IAdminEventClassValidator {
  IsRegisterUnderIndividuals: Validator;
  IsOption: Validator;
  IsAmountActual: Validator;
  UpfrontPaymentID: Validator;
  IsCombo: Validator;
  ComboName: Validator;
  ClassCode: Validator;
  Location: Validator;
  IsEntireEvent: Validator;
  Amount: Validator;
  UpfrontPaymentSpecific: Validator;
  MaxParticipants: Validator;
  MinimumAge: Validator;
  AllowSelfRegistration: Validator;
  InstructorName: Validator;
  InstructorInitials: Validator;
  GLAccountID: Validator;
  ShowYouth: Validator;
  ShowAdults: Validator;
  SelectedClassTypeIds: Validator;
}

export const AdminEventClassFormDefinition: IAdminEventClassValidator = {
  IsRegisterUnderIndividuals: {
    key: 'IsRegisterUnderIndividuals',
    defaultValue: (rootState) => isJustNumbers(rootState) ? false : true,
  },
  IsOption: {
    key: 'IsOption',
    defaultValue: () => false,
    localDependants: ['GLAccountID'],
  },
  IsAmountActual: {
    key: 'IsAmountActual',
    defaultValue: () => true,
  },
  UpfrontPaymentID: {
    key: 'UpfrontPaymentID',
    defaultValue: () => 1,
  },
  IsCombo: {
    key: 'IsCombo',
    defaultValue: () => false,
  },
  ComboName: {
    key: 'ComboName',
    defaultValue: () => '',
    skip: (rootState) => {
      return !rootState.adminEvents.classes.form.ActiveForm.IsCombo;
    },
    isRequired: (rootState) => {
      return !!rootState.adminEvents.classes.form.ActiveForm.IsCombo;
    },
    validatejs: {
      ComboName: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 1024,
          tooLong: M.MAX_LENGTH(1024)
        },
      }
    },
  },
  ClassCode: {
    key: 'ClassCode',
    defaultValue: () => '',
    isRequired: () => false,
    validatejs: {
      ClassCode: {
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50)
        },
      }
    },
  },
  Location: {
    key: 'Location',
    defaultValue: () => '',
    skip: (rootState) => {
      return !(!!rootState.adminEvents.cacheOne.EventsEventType && rootState.adminEvents.cacheOne.EventsEventType.ClassScheduling.ShowClassLocation);
    },
    validatejs: {
      Location: {
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50)
        },
      }
    },
  },
  IsEntireEvent: {
    key: 'IsEntireEvent',
    defaultValue: () => true,
  },
  Amount: {
    key: 'Amount',
    isRequired: () => true,
    decimalOnly: true,
    defaultValue: () => 0,
    customValidate: (rootState) => {
      const form = rootState.adminEvents.classes.form.ActiveForm;

      const Amount = toNumber(form.Amount);
      if (Amount < 0) {
        if (!!form.IsOption && !!form.IsAmountActual) return '';
        else return M.MIN_VALUE(0).substr(1);
      }
    },
    allowNegative: (rootState) => {
      const form = rootState.adminEvents.classes.form.ActiveForm;

      return !!form.IsOption && !!form.IsAmountActual;
    },
    validatejs: {
      Amount: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          notValid: `^${M.INVALID}`,
        },
      },
    },
  },
  UpfrontPaymentSpecific: {
    key: 'UpfrontPaymentSpecific',
    defaultValue: () => 0,
    decimalOnly: true,
    skip: (rootState) => {
      const form = rootState.adminEvents.classes.form.ActiveForm;
      return !(!!form.IsAmountActual && form.Amount !== undefined && form.Amount >= 0 && form.UpfrontPaymentID === 2);
    },
    customValidate: (rootState) => {
      const form = rootState.adminEvents.classes.form.ActiveForm;

      const UpfrontPaymentSpecific = toNumber(form.UpfrontPaymentSpecific);
      const Amount = toNumber(form.Amount);

      if (
        form.IsAmountActual && form.UpfrontPaymentID === 2 &&
        UpfrontPaymentSpecific && Amount
      ) {
        if (UpfrontPaymentSpecific > Amount) return M.MAX_VALUE(form.Amount).substr(1);
      }

      return '';
    },
    isRequired: () => true,
    validatejs: {
      UpfrontPaymentSpecific: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
        },
      },
    },
  },
  MaxParticipants: {
    key: 'MaxParticipants',
    integerOnly: true,
    validatejs: {
      MaxParticipants: {
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
        },
      },
    },
  },
  MinimumAge: {
    key: 'MinimumAge',
    skip: (rootState) => {
      return !rootState.adminEvents.classes.form.ActiveForm.IsRegisterUnderIndividuals;
    },
    integerOnly: true,
    validatejs: {
      MinimumAge: {
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
        },
      },
    },
  },
  AllowSelfRegistration: {
    key: 'AllowSelfRegistration',
    defaultValue: () => true,
  },
  InstructorName: {
    key: 'InstructorName',
    defaultValue: () => '',
    validatejs: {
      InstructorName: {
        length: {
          maximum: 200,
          tooLong: M.MAX_LENGTH(200)
        },
      },
    },
  },
  InstructorInitials: {
    key: 'InstructorInitials',
    defaultValue: () => '',
    validatejs: {
      InstructorInitials: {
        length: {
          maximum: 5,
          tooLong: M.MAX_LENGTH(5)
        },
      },
    },
  },
  GLAccountID: {
    key: 'GLAccountID',
    skip: (rootState) => {
      return !(rootState.adminEvents.classes.form.ActiveForm.IsRegisterUnderIndividuals || !rootState.adminEvents.classes.form.ActiveForm.IsOption);
    },
    defaultValue: (rootState) => {
      const form = rootState.adminEvents.classes.form.ActiveForm;

      if (form.IsOption) return null;
      else return rootState.adminEvents.cacheTwoEvent.EventsEvent ? rootState.adminEvents.cacheTwoEvent.EventsEvent.GLAccountID_ClassDefault : null;
    },
    customValidate: (rootState) => {
      const form = rootState.adminEvents.classes.form.ActiveForm;
      if (!form.GLAccountID) {
        return '';
      } else {
        const GLAccounts = rootState.cacheZero.options ? rootState.cacheZero.options.GLAccounts : [];
        const GLAccount = GLAccounts.find((acc) => acc.ID === form.GLAccountID);
        if (!GLAccount || GLAccount.Inactive) {
          return M.INVALID_SELECTED_GL_ACCOUNT;
        }
      }
      return;
    },
    validatejs: {
      GLAccountID: {
      },
    },
  },
  ShowYouth: {
    key: 'ShowYouth',
    defaultValue: () => true,
  },
  ShowAdults: {
    key: 'ShowAdults',
    defaultValue: () => true,
  },
  SelectedClassTypeIds: {
    key: 'SelectedClassTypeIds',
    customValidate: (rootState) => {
      const ActiveForm = rootState.adminEvents.classes.form.ActiveForm;

      if (!ActiveForm.SelectedClassTypeIds || ActiveForm.SelectedClassTypeIds.length === 0) {
        return M.INVALID_SELECTED_CLASS_TYPE;
      }

      return '';
    },
    validatejs: {
      SelectedClassTypeIds: {

      }
    }
  }
};