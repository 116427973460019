
import { makeRequestEpic, makeSequentialRequestEpic } from '../../../../Validation/epicCreator';
import {GetDefaultForPrimaryEventPageSubmitActions, EditPageUpdateSiteMenuItemSubmitActions, EditPageAddTopGalleryImageSubmitActions, EditPageAddBottomGalleryImageSubmitActions, EditPageEditGalleryImageSubmitActions, EditPageDeleteGalleryImageSubmitActions, UPDATE_GALLERY_IMAGE_PREFIX, DeletePageSubmitActions, UpdatePageSubmitActions} from './actions';
import { getDefaultForPrimaryEventPage, getEditPageUpdateSiteMenuItemUrl, getEditPageAddTopGalleryImageUrl, getEditPageAddBottomGalleryImageUrl, getEditPageEditGalleryImageUrl, getUpdateCMSPageUrl, getPagesRootUrl } from '../../../../../constants/urls';
import { AjaxResponse } from 'rxjs/Rx';
import { ENTITY_EDITED, ENTITY_DELETED, ENTITY_CREATED, GALLERY_IMAGE, MENU_ITEM, TOP_GALLERY_IMAGE, BOTTOM_GALLERY_IMAGE, ENTITY_RESTORED, CMS_PAGE, PAGES, ENTITY_PARSE_ERROR } from '../../../../../constants/messages/adminCMS';
import { CMSPageGalleryImage } from '../../../../../models/api/adminCMSCacheTwoPage';
import { captureTentarooError } from '../../../../../utils/dataHelper';

export const getDefaultForPrimaryEventPageEpic = makeRequestEpic(
  GetDefaultForPrimaryEventPageSubmitActions,
  getDefaultForPrimaryEventPage,
  undefined,
  true
);

// Edit Site Menu Item
export const editPageUpdateSiteMenuItemEpic = makeRequestEpic(
  EditPageUpdateSiteMenuItemSubmitActions,
  getEditPageUpdateSiteMenuItemUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      if (body.row.ID && !body.row.Inactive) {
        return ENTITY_EDITED(MENU_ITEM);
      } else if (body.row.ID && body.row.Inactive) {
        return ENTITY_DELETED(MENU_ITEM);
      }
      return 'New Menu Item was added to the site navigation';
    } catch (e) {
      captureTentarooError(new Error('Unable to parse request body for add/edit site menu item in edit page!'));
      return '';
    }
  },
);

// Edit Gallery Image
export const adminCMSSiteEditGalleryImageEpic = makeRequestEpic(
  EditPageEditGalleryImageSubmitActions,
  getEditPageEditGalleryImageUrl,
  undefined,
  true,
  (response: AjaxResponse, action) => {
    if (action.extra && action.extra.gallery === 'top') {
      return ENTITY_EDITED(TOP_GALLERY_IMAGE);
    } else {
      return ENTITY_EDITED(BOTTOM_GALLERY_IMAGE);
    }
  },
  undefined,
  undefined,
  undefined,
  false,
);

// Delete/Restore Gallery Image, and AddImage to Top/Bottom Gallery, they will be using one epic
export const adminCMSSiteUpdateGalleryImageEpic = makeSequentialRequestEpic(
  [EditPageDeleteGalleryImageSubmitActions, EditPageAddBottomGalleryImageSubmitActions, EditPageAddTopGalleryImageSubmitActions],
  (actionType: string) => {
    switch (actionType) {
      case (EditPageDeleteGalleryImageSubmitActions.requestType):
        return getEditPageEditGalleryImageUrl();
      case (EditPageAddTopGalleryImageSubmitActions.requestType):
        return getEditPageAddTopGalleryImageUrl();
      case (EditPageAddBottomGalleryImageSubmitActions.requestType):
        return getEditPageAddBottomGalleryImageUrl();
      default:
        return '';
    }
  },
  UPDATE_GALLERY_IMAGE_PREFIX,
  undefined,
  (response: AjaxResponse, action) => {
    const image = response.response.CMSPageImage as CMSPageGalleryImage;
    if (action.extra) {
      if (action.extra.gallery === 'top') {
        return action.extra.tempId ? ENTITY_CREATED(TOP_GALLERY_IMAGE) : (image.Inactive ? ENTITY_DELETED(TOP_GALLERY_IMAGE) : ENTITY_RESTORED(TOP_GALLERY_IMAGE));
      } else if (action.extra.gallery === 'bottom') {
        return action.extra.tempId ? ENTITY_CREATED(BOTTOM_GALLERY_IMAGE) : (image.Inactive ? ENTITY_EDITED(BOTTOM_GALLERY_IMAGE) : ENTITY_RESTORED(BOTTOM_GALLERY_IMAGE));
      }
    }

    return '';
  },
);

// Delete/Restore Page
export const adminCMSSiteDeleteRestorePageEpic = makeRequestEpic(
  DeletePageSubmitActions,
  getUpdateCMSPageUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const CMSPagesRow = response.response.CMSPagesRow;

    if (CMSPagesRow.Inactive) {
      return ENTITY_DELETED(CMS_PAGE);
    }
    return ENTITY_RESTORED(CMS_PAGE);
  },
);

// Update Page
export const adminCMSSiteUpdatePageEpic = makeRequestEpic(
  UpdatePageSubmitActions,
  getUpdateCMSPageUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    return ENTITY_EDITED(CMS_PAGE);
  },
  (response: AjaxResponse) => {
    return '';
  }
);