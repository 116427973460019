import { SaveState } from "../../store/Rollback/actions";
import { makeSelectedLocationSelector } from "../../store/CacheTwoFacilities";
import { getFacilityLocationBody } from "../../constants/urls";
import { ToggleNotFound } from "../../store/App/actions";
import { formatDate } from "../dateHelper";
import { getGroupID } from "../helpers/endUserPageHelper";
import { GetFacilityLocation } from "../../store/CacheTwoFacilities/actions";
import { isIdFromUrlValid, isIdValid } from "../urlHelper";
import { captureTentarooError, captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from "../dataHelper";
import { reduxStoreService } from "../../store/service";
import type EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import { LoadEndUserCacheTwoFacilityParams } from "./helpers/models";
import {isCacheZeroPopulated, isEndUserCacheOnePopulated, isEndUserCacheTwoFacilitiesPopulated} from "../cachePopulatedCheckers/endUser";

export const ensureCacheTwoFacilities = (
  params: LoadEndUserCacheTwoFacilityParams & {cacheManager: EndUserCacheManager},
): boolean => {
  const { props, cacheManager, isStateNavigated } = params;

  const groupID = getGroupID(props);
  if (!isIdValid(groupID) || !isIdFromUrlValid(props.params.id)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }
  const rootState = reduxStoreService().getState();

  const { cacheTwoFacilities, cacheOne, cacheZero } = rootState;
  const facilityLocation = rootState.facilities.location;

  const selectedLocationSelector = makeSelectedLocationSelector();
  const selectedLocation = selectedLocationSelector(rootState);

  const facTypeID = facilityLocation.selectedFacilityTypeTab;
  const startDate = formatDate(facilityLocation.startDate);

  let requestedData = false;

  const locationId = Number(props.params.id);

  // TODO: why do we have this first if block here??
  if (isEndUserCacheTwoFacilitiesPopulated(cacheTwoFacilities) && !selectedLocation) {
    if (isStateNavigated) {
      reduxStoreService().dispatch(new SaveState());
    }
  } else if (!isEndUserCacheTwoFacilitiesPopulated(cacheTwoFacilities)) {
    if (!isCacheZeroPopulated(cacheZero)) {
      // Came from page load, need everything
      reduxStoreService().dispatch(GetFacilityLocation.request({
        GroupIDi: groupID,
        GroupTS: 0,
        FacilityLocationID: locationId,
        GetOptions: true,
        GetGroupData: true,
        FacilityTypeID: facTypeID,
        StartDate: startDate
      }));

      requestedData = true;
    } else if (
      isCacheZeroPopulated(cacheZero) &&
      (!cacheZero.options!.Group || !isEndUserCacheOnePopulated(cacheOne))
    ) {
      reduxStoreService().dispatch(GetFacilityLocation.request({
        GroupIDi: groupID,
        GroupTS: 0,
        FacilityLocationID: locationId,
        GetOptions: false,
        GetGroupData: true,
        FacilityTypeID: facTypeID,
        StartDate: startDate
      }));
      
      requestedData = true;
    } else {
      if (!cacheZero.options!.Group) {
        captureTentarooError(new Error("Group not available when dispatching GetFacilityLocation in cacheTwoFacilitiesLoader"));
        return false;
      }
      reduxStoreService().dispatch(GetFacilityLocation.request({
        GroupIDi: cacheZero.options!.Group.IDi,
        GroupTS: cacheZero.options!.Group.TS,
        FacilityLocationID: locationId,
        GetOptions: false,
        GetGroupData: false,
        FacilityTypeID: facTypeID,
        StartDate: startDate
      }));
      requestedData = true;
    }
  } else if (isStateNavigated) {
    // QUESTION: When could this happen?
    requestedData = cacheManager.loadCacheOne({props, isStateNavigated});

    if (!requestedData) {
      reduxStoreService().dispatch(new SaveState());
    }
  }

  return requestedData;
};

export const getCacheTwoFacilitiesEmptyCartBody = (params: { id: string }) => {

  let debugPayload: TentarooDebugPayload | undefined;
  const rootState = reduxStoreService().getState();

  if (!isIdFromUrlValid(params.id)) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Invalid locationId in getCacheTwoFacilitiesEmptyCartBody");
  }

  const locationId = Number(params.id);

  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when getCacheTwoFacilitiesEmptyCartBody");
  }
  if (!rootState.cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when getCacheTwoFacilitiesEmptyCartBody");
  }
  const body: any = getFacilityLocationBody({
    FacilityLocationID: locationId,
    GroupIDi: rootState.cacheZero.options?.Group?.IDi as any,
    GroupTS: rootState.cacheZero.options?.Group?.TS as any,
    GetOptions: false,
    GetGroupData: true,
    FacilityTypeID: rootState.facilities.location.selectedFacilityTypeTab,
    StartDate: formatDate(rootState.facilities.location.startDate),
  });
  body.AppState.EmptyCart = true;

  if (debugPayload) {
    return withTentarooDebugPayload(body, debugPayload);
  } else {
    return body;
  }
};