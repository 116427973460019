import * as React from 'react';
import { namespace as ns } from './constants';

import '../../../styles/elements/my-event/name.scss';

const namespace = (): string => `${ns()}--name`;

interface Props {
  children?: React.ReactNode;
}

const MyEventName: React.SFC<Props> = ({ children }: Props): React.ReactElement<Props> => (
  <h2 className={namespace()}>{children}</h2>
);

export default MyEventName;
