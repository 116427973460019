import * as React from 'react';
import {
  Button,
  Column,
  Row,
  SideModal,
  SideModalContent,
  SideModalHeader,
  IconRowLink,
  Vignette,
  ActionButton,
  Loader,
} from '../../../../../Elements';
import '../../../../../../styles/pages/contacts/home/preview.scss';
import {bindActionCreators} from "redux";
import {ApplicationState} from "../../../../../../store";
import { getContactsRootUrl, getEditContactUrl, constructCMSSiteUrlParams, constructCMSSiteContactUrlParams } from '../../../../../../constants/urls';
import { navPush, createInitials, getImagePath } from '../../../../../../utils';
import { CMSContact } from '../../../../../../models/api/adminCMSCacheOne';
import { RouteComponentProps, withRouter, WithRouterProps } from 'react-router';
import { actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import { actionCreators as adminCMSCacheTwoContactActionCreator, GetContact} from "../../../../../../store/AdminCMSSite/CacheTwoContacts/actions";
import { actionCreators as contactFormActionCreator} from "../../../../../../store/AdminCMSSite/Contacts/Contact/Form/actions";
import {
    actionCreators as adminCMSCacheOneActionCreators,
} from '../../../../../../store/AdminCMSSite/CacheOne/actions';
import { PhoneIcon, MailIcon, EditIcon } from '../../../../../../components/Icons';
import { mapSocialTypeIDToIcon } from '../../../../../../components/Elements/SocialMediaField';
import { NotFound } from '../../../../../../components/Pages';
import { INVALID_CONTACT } from '../../../../../../constants/messages/adminCMS';
import { checkContactPermission, getContactIdFromPath, IAdminCMSContactRouterParams } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { isPathnameMatchingRoute } from '../../../../../../utils/urlHelper';
import { ADMIN_CMS_CONTACT_EDIT_PATH } from '../../../../../../routes';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { shouldReconfigRouter } from '../../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import {isAdminCMSCacheOnePopulated, isAdminCMSCacheTwoContactPopulated} from '../../../../../../utils/cachePopulatedCheckers/adminCMS';

export const namespace = (): string => 'pages--contact--preview';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminCMSContactRouterParams, {}>;

class ContactPreview extends React.Component<ConnectedProps, {}> {
  props: ConnectedProps;
  private nextLocation;

  componentDidMount() {
    this.configRouter();
  }

  configRouter() {
    const {routes, router} = this.props;
    const route = routes[routes.length - 1];
    router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
  }

  public componentWillUnmount() {
    const {actions, adminCMSCacheTwoContact} = this.props;

    // if we are not navigating to EditContact page, clear cache
    if (
      this.nextLocation &&
      !isPathnameMatchingRoute(this.nextLocation.pathname, ADMIN_CMS_CONTACT_EDIT_PATH) &&
      isAdminCMSCacheTwoContactPopulated(adminCMSCacheTwoContact)
    ) {
      actions.clearAdminCMSCacheTwoContact();
    }
    this.resetRouteLeaveHook();
  }
  onClickModal = (e) => {
    e && e.stopPropagation();
  };
  
  onBack = () => {
    this.props.actions && this.props.actions.clearAdminCMSSiteCacheBelowOne();
    const backUrl = getContactsRootUrl(constructCMSSiteUrlParams(this.props, this.props.adminCMSCacheOne));
    navPush(this.props.router, backUrl);
  };
  onEditClick = (contact: CMSContact) => {
    checkContactPermission(
      () => {
        const editContactUrl = getEditContactUrl(constructCMSSiteContactUrlParams(this.props, this.props.adminCMSCacheOne, contact));
        navPush(this.props.router, editContactUrl);
      },
      this.props.cacheZero,
      this.props.adminCMSCacheOne,
      contact.SiteID,
      true,
    );
  };

  renderContent = (contactFromCacheOne: CMSContact, loading:boolean, contact?: CMSContact, CMSContactSocials?: any[]) => {
    let key = 0;
    return (
      <SideModalContent lockBodyScroll>
        {((contact && contact.FeaturedImage) || contactFromCacheOne.FeaturedImage) && <Row marginTop={0} mobileMarginTop={24} marginBottom={0}>
          <Column style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} span={12} mobileSpan={12}>
            <Vignette width={96}
                      height={96}
                      className={`${namespace()}--avatar`}
                      name={createInitials(contact ? (contact.Name ? contact.Name : contact.Title) : (contactFromCacheOne.Name ? contactFromCacheOne.Name : contactFromCacheOne.Title))}
                      color={`#${(contact && contact.Color) || contactFromCacheOne.Color}`}
                      image={(contact && contact.FeaturedImage) ? getImagePath(contact.FeaturedImage, 'th300px_auto') : (contactFromCacheOne.FeaturedImage ? getImagePath(contactFromCacheOne.FeaturedImage, 'th300px_auto') : undefined)}
                      borderRadius='48px' />
          </Column>
        </Row>}
        <Row marginTop={16} marginBottom={0}>
          <Column span={12} mobileSpan={12}>
            {!contact && <div className={`${namespace()}--name`}> {contactFromCacheOne.Name ? contactFromCacheOne.Name : contactFromCacheOne.Title}</div>}
            {contact && <div className={`${namespace()}--name`}> {contact.Name ? contact.Name : contact.Title}</div>}
          </Column>
        </Row>
        <Row marginTop={8} marginBottom={0}>
          <Column span={12} mobileSpan={12}>
            {!contact && <div className={`${namespace()}--subtitle`}>{contactFromCacheOne.Name ? contactFromCacheOne.Title : contactFromCacheOne.Company}</div>}
            {contact && <div className={`${namespace()}--subtitle`}>{contact.Name ? contact.Title : contactFromCacheOne.Company}</div>}
          </Column>
        </Row>
        {contact && <Row marginTop={40} mobileMarginTop={32} marginBottom={0}>
          {contact.Phone &&
            <Column span={12} mobileSpan={12}>
              <IconRowLink noLinkDecoration target="_blank" href={`tel:${contact.Phone}`} icon={PhoneIcon} key={key++}>
                <Row className={`${namespace()}--phone-row`}>
                  <div className={`${namespace()}--phone`}>{contact.Phone}</div>
                  <div className={`${namespace()}--link-helper-text`}>Phone</div>
                </Row>
              </IconRowLink>
            </Column>}
          {contact.Fax &&
            <Column span={12} mobileSpan={12}>
              <IconRowLink noLinkDecoration target="_blank" href={`tel:${contact.Fax}`} icon={PhoneIcon} key={key++}>
                <Row className={`${namespace()}--phone-row`}>
                  <div className={`${namespace()}--phone`}>{contact.Fax}</div>
                  <div className={`${namespace()}--link-helper-text`}>Fax</div>
                </Row>
              </IconRowLink>
            </Column>}
          {contact.MobilePhone &&
            <Column span={12} mobileSpan={12}>
              <IconRowLink noLinkDecoration target="_blank" href={`tel:${contact.MobilePhone}`} icon={PhoneIcon} key={key++}>
                <Row className={`${namespace()}--phone-row`}>
                  <div className={`${namespace()}--phone`}>{contact.MobilePhone}</div>
                  <div className={`${namespace()}--link-helper-text`}>Mobile</div>
                </Row>
              </IconRowLink>
            </Column>}
          {contact.Email && 
            <Column span={12} mobileSpan={12}>
              <IconRowLink noLinkDecoration target="_blank" href={`mailto:${contact.Email}`} icon={MailIcon} truncate key={key++}>{contact.Email}</IconRowLink>
            </Column>}
        </Row>}
        {CMSContactSocials && <Row marginTop={24} mobileMarginTop={24}>
          {CMSContactSocials.map((s) => {
            return (
              <Column key={`contact-ssn-${s.ID}`} span={12} mobileSpan={12}>
                <IconRowLink noLinkDecoration target="_blank" href={`#`} iconNode={mapSocialTypeIDToIcon(s.SocialTypeID, 'solid')} truncate key={key++}>{s.SocialUser}</IconRowLink>
              </Column>);
          })}
      </Row>}
    </SideModalContent>
    );
  };

  renderNotFound = (loading: boolean) => {
    return (
      <NotFound inModal showCMSSiteHeader message={INVALID_CONTACT}/>
    );
  };

  render() {
    const {apiSaving, adminCMSCacheOne, apiLoadingMap, adminCMSCacheTwoContact, adminCMSCacheTwoContact: {CMSContact, CMSContactSocials}, router, params} = this.props;

    const loading = (
      !isAdminCMSCacheOnePopulated() ||
      // navigate from list to preview
      !isAdminCMSCacheTwoContactPopulated(adminCMSCacheTwoContact) ||
      // refresh
      apiLoadingMap[GetContact.requestType]
    );
    const contactFromCacheOne = (
      adminCMSCacheOne.CMSContacts &&
      adminCMSCacheOne.CMSContacts.find(
        (c) => c.ID === getContactIdFromPath(params)
      )
    );
    const contact = CMSContact;
    const contactInactive = contact ? contact.Inactive : (!contactFromCacheOne || contactFromCacheOne.Inactive);
    return contactFromCacheOne ? (
      <SideModal
        className={`${namespace()} ${contactInactive ? 'inactive' : ''}`}
        loading={apiSaving > 0}
        onClick={this.onClickModal}
        stickyHeader
        header={<SideModalHeader
                  className={`${namespace()}--header`}
                  controls={contactInactive ? null : <Button id={generateDOMId("contact-edit-btn")} disabled={loading} textColor='black' onClick={() => this.onEditClick(contactFromCacheOne)} color='white'>EDIT</Button>}
                  onlyShowTitleInMobile
                  onBack={this.onBack}
                  title="Contact Preview"
                  mobileControls={<ActionButton onClick={() => this.onEditClick(contactFromCacheOne)} icon={EditIcon} />}
                  subtitle={adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.SiteDomain : ''} />}
        >
        {contactInactive ? this.renderNotFound(loading) : this.renderContent(contactFromCacheOne, loading, contact, CMSContactSocials)}
        {loading && <Loader className={`${namespace()}--loader`} center />}
    </SideModal>
    ) : null;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    adminCMSCacheTwoContact: state.adminCMSSite.cacheTwoContacts,
    cacheZero: state.cacheZero,
    apiLoadingMap: state.app.apiLoadingMap,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...adminCMSCacheTwoContactActionCreator,
  ...adminCMSCacheOneActionCreators,
  ...appActionCreators,
  ...contactFormActionCreator,
  }, dispatch)});

const ConnectedContactPreview = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(ContactPreview);

export default withRouter<{}>(ConnectedContactPreview);
