import { ValidationResults } from "@tentaroo/core";

import type {ActionCreator, ApplicationState} from '../../../';
import {
  createApiSubmitActions,
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions
} from "../../../Validation/actionCreator";
import {RefundOrFeeForm} from "./index";
import {validateAll} from "../../../../utils/validator";
import {SaveState} from "../../../Rollback/actions";
import {ShowTopFloatingAlert} from "../../../App/actions";
import {getAddRefundOrFeeBody} from "../../../../constants/urls";
import { typeName, Action } from '../../../../utils/StrongActions';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../utils/dataHelper';
import { ModalTypes, isModalOpened } from '../../../../utils/modalHelper';

export const SUFFIX = '__REFUND_OR_FEE';

@typeName(`RESET${SUFFIX}`)
export class RefundOrFeeReset extends Action {}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const RefundOrFeeActions = createApiSubmitActions(SUFFIX);

export type Actions = typeof actionCreators;

export const formCreator = (rootState: ApplicationState, activeForm: RefundOrFeeForm) => {
  let debugPayload: TentarooDebugPayload | undefined;
  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when submitting RefundOrFee form");
  }
  const Group = rootState.cacheZero.options?.Group;

  const payload = getAddRefundOrFeeBody(
    Group?.IDi as any,
    Group?.TS as any,
    activeForm.Refund as number,
    activeForm.ConvenienceFee as number
  );

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  resetRefundOrFee: (): ActionCreator => dispatch => dispatch(new RefundOrFeeReset()),
  refundOrFeeApiSubmitForm: (): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const validationResults = validateAll(s => s.settings.prevOrders.refundOrFee);
    if (!validationResults) {
      const activeForm = getState().settings.prevOrders.refundOrFee.ActiveForm;
      dispatch(RefundOrFeeActions.request(formCreator(getState(), activeForm), null));
    } else {
      dispatch(RefundOrFeeActions.clientFailure(validationResults, isModalOpened(ModalTypes.PAYMENT_TOOLS)));
      dispatch(new ShowTopFloatingAlert(validationResults.Errors[Object.keys(validationResults.Errors)[0]][0], isModalOpened(ModalTypes.PAYMENT_TOOLS), 'orange'));
    }
  },
  refundOrFeeUpdateValue: createUpdateValueMethod(
    ValidateActions,
    ApiValidateActions,
    (s) => s.settings.prevOrders.refundOrFee,
    undefined,
    undefined,
    undefined,
    (validationResults: ValidationResults, key: string, dispatch) => {
      dispatch(new ShowTopFloatingAlert(validationResults.Errors[Object.keys(validationResults.Errors)[0]][0], isModalOpened(ModalTypes.PAYMENT_TOOLS), 'orange'));
    }
  ),
  refundOrFeeSimpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
};
