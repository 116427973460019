import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/radio/index.scss';
import { noop } from '../../../utils';
import MaterialRadio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const AnyMaterialRadio = MaterialRadio as any;

export const namespace = (): string => 'elements--radio';

// What's the difference beteen value and selected?
// label doesn't work...
interface Props {
  label?: string | React.ReactElement;
  value: boolean | number | string;
  rawValue?: any;
  selected: boolean;
  disabled?: boolean;
  onChange: Function;
  newDesign?: boolean;
  helperText?: string | string[];
  redHelperText?: boolean;
  ValidationRules?: any;
  className?: string;
  id?: string;
}

const StatelessOption: React.SFC<Props> = ({ disabled, id, label, value, selected, onChange, rawValue, ValidationRules, newDesign, helperText, redHelperText, ...props }: Props): React.ReactElement<Props> => {
  let finalValue: string | number;
  if (typeof value === 'boolean') finalValue = value ? '1' : '0';
  else finalValue = value;
  let className = mergeClassNames(namespace(), props);
  if (selected) className += ' checked';
  if (disabled) className += ' disabled';
  const radio = (
    <AnyMaterialRadio
      label={label}
      value={finalValue}
      className={className}
      checked={selected}
      disabled={disabled}
      id={id}
      onChange={onChange ? (event) => {
        const element: HTMLInputElement = event.target as any;
        if (ValidationRules) {
          onChange(rawValue, ValidationRules);
          return;
        }
        if (typeof value === 'boolean') onChange(element.value == '1' ? true : false);
        else onChange(element.value);
      } : noop}
    />
  );

  if (newDesign) {
    return (
      <div className={`${namespace()}--wrapper ${disabled ? 'disabled' : ''}`}>
        <div className={`${namespace()}--content`}>
          <FormControlLabel
            control={<AnyMaterialRadio
              id={id}
              value={finalValue}
              className={className}
              checked={selected}
              disabled={disabled}
              onChange={onChange ? (event) => {
                const element: HTMLInputElement = event.target as any;
                if (ValidationRules) {
                  onChange(rawValue, ValidationRules);
                  return;
                }
                if (typeof value === 'boolean') onChange(element.value == '1' ? true : false);
                else onChange(element.value);
              } : noop}
              />
            }
            label={<div className={`${namespace()}--content--label`}>{label}</div>}
          />
        </div>
        {helperText && typeof helperText === 'string' ? <div className={`${namespace()}--helper-text`}>{helperText}</div> : null}
        {helperText && typeof helperText !== 'string' ? helperText.map((t) => {
          return (
            <div className={`${namespace()}--helper-text multiple ${redHelperText ? 'red' : ''}`}>{t}</div>
          );
        }) : null}
      </div>
    );
  }

  return radio;
};

export default StatelessOption;
