import * as React from 'react';
import {
  Modal, ModalTitle, ModalActions, Button } from '../../../../../Elements';
import {withRouter, RouteComponentProps} from "react-router";
import {bindActionCreators} from "redux";
import {actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import {actionCreators} from "../../../../../../store/AdminFacilityLocation/Facilities/FacilityType/Form/actions";
import {ApplicationState} from "../../../../../../store";
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

type Props = WithInertAttribute<{}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{},{}>;

@(withRouter as any)
class DeleteFacilityTypeModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  
  onCancel = (e?: any) => {
    if (e) e.stopPropagation();
    this.props.actions.popModal(false, true, ModalTypes.DELETE_FACILITY_TYPE);
  };

  onDelete = () => {
    if (!this.props.id) return;
    this.props.actions.deleteFacilityType(this.props.routes, this.props.router, this.props.id);
  };

  public render() {
    const {apiSaving, apiLoading, cacheOne, inert, id} = this.props;

    if (!cacheOne.FacilitiesTypes || !id) return null;
    const deletingFacilityType = cacheOne.FacilitiesTypes.find((ft) => ft.ID === id);

    return (
      <Modal inert={inert} disableDynamicHeight bodyScrollLock big higherZIndex>
        <ModalTitle marginBottom={46}>{`Delete "${deletingFacilityType ? deletingFacilityType.NamePlural.trim() : ''}"?`}</ModalTitle>
        <ModalActions
          noPadding
          loading={apiSaving > 0 || apiLoading > 0}
          stacked
          alignRight
          left={<Button flat textColor="blue" id={generateDOMId("cancel-delete-facility-type-btn")} onClick={this.onCancel}>CANCEL</Button>}
          right={<Button textColor="red" id={generateDOMId("delete-facility-type-btn")} flat onClick={this.onDelete}>DELETE</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const facilityTypeIdSelector = makeFormModalPropSelector(ModalTypes.DELETE_FACILITY_TYPE, 'id');
  return {
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
    cacheOne: state.adminFacilityLocation.cacheOne,
    cacheZero: state.cacheZero,
    id: facilityTypeIdSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
}, dispatch)});

const ConnectedDeleteFacilityTypeModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(DeleteFacilityTypeModal);

export default ConnectedDeleteFacilityTypeModal;
