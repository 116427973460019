import {GetEventData} from './actions';
import { getEventData, getEventDataBody } from '../../constants/urls';
import {makeRequestEpic} from "../Validation/epicCreator";

export const cacheThreeEventsEpic = makeRequestEpic(
  GetEventData,
  getEventData,
  getEventDataBody,
  true
);
