import * as React from 'react';
import * as moment from "moment";

import {Column, DatePicker, FieldSet, Form, Row, Select, Switch, Text, TextField} from '../../../Elements';
import {EventTypeRegistrationSettings} from "../../../../models/api/cacheTwoEvents";
import {PersonFormValidatior, ShowIsScoutField, ShowRankField, ShowUnitFields} from '../../../../utils/personFormValidationRules';
import {EventRegistrationPersonCustomField} from '../../../../models/api/cacheFourEvents';
import {CustomFields} from './customFields';
import {convertNumberToOrdinal} from '../../../../utils/classesHelper';
import {EventInfo} from "../../../../models/api/cacheThreeEvents";
import {displaySimpleDate, isCurrentGrade} from "../../../../utils/dateHelper";
import { generateDOMId } from '../../../../utils/cypressHelper';
import { reduxStoreService } from '../../../../store/service';
import { SelectValidator, Validator } from '../../../../utils/validator/models';

export interface PersonFormTemplateProps {
  ActiveForm: any;
  ValidationRules: PersonFormValidatior & {
    IsStaff?: SelectValidator,
    SummerCampYear?: Validator,
    InCampAllWeek?: Validator,
    InCampMon?: Validator,
    InCampTue?: Validator,
    InCampWed?: Validator,
    InCampThu?: Validator,
    InCampFri?: Validator,
    InCampSat?: Validator,
    InCampSun?: Validator,
    RegistrationNotes?: Validator
  };
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
  };
  EventTypeRegistrationSettings: EventTypeRegistrationSettings;
  GroupTypeID: number;
  groupUnitType: string;
  OldScoutTypeID: number;
  actions?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  isYouth: boolean;
  customFields?: Array<EventRegistrationPersonCustomField> | null;
  EventInfo?: EventInfo;
}

const getGradeHelperText = (EventInfo: EventInfo) => {
  if (EventInfo.StartDateTime) {
    if (isCurrentGrade(EventInfo.StartDateTime)) {
      return `Grade as of ${displaySimpleDate(EventInfo.StartDateTime)}`;
    } else {
      return `Upcoming grade in fall ${moment(EventInfo.StartDateTime).year()}`;
    }
  }
};

const PersonFormTemplate: React.SFC<PersonFormTemplateProps> = ({
  EventTypeRegistrationSettings,
  GroupTypeID,
  OldScoutTypeID,
  groupUnitType,
  isYouth,
  actions,
  reduxActions,
  loading,
  ActiveForm,
  ValidationRules,
  disabled,
  customFields,
  EventInfo
}: PersonFormTemplateProps): React.ReactElement<PersonFormTemplateProps> => {
  const ShowScout = ShowIsScoutField(
    GroupTypeID,
    EventTypeRegistrationSettings.NamesRegistrationSettings.AllowNonScouterParticipants,
    OldScoutTypeID, // @todo: this isn't oldScoutTypeID in participants wizard, it is just the current one, which I can see the default in CamperWeekVO
    isYouth
  );
  const ShowUnit = ShowUnitFields(
    GroupTypeID,
    EventTypeRegistrationSettings.NamesRegistrationSettings.AllowNonScouterParticipants,
    ActiveForm.ScoutTypeID ? ActiveForm.ScoutTypeID : 1,
    isYouth
  );
  const ShowRank = ShowRankField(
    EventTypeRegistrationSettings.NamesRegistrationSettings.AllowNonScouterParticipants,
    GroupTypeID,
    ActiveForm.ScoutTypeID ? ActiveForm.ScoutTypeID : 1
  );
  const ShowGender = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowGender');
  const ShowDOB = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowDOB');
  const ShowAddress = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowAddress');
  const ShowPhone = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowPhone');
  const ShowShirtSize = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowShirtSize');
  const ShowAllowPhotography = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowAllowPhotography');
  const ShowDateBalooTraining = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowDateBalooTraining');
  const ShowDateBasicTrainingForPosition = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowDateBasicTrainingForPosition');
  const ShowDateYouthProtectionTraining = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowDateYouthProtectionTraining');
  const ShowGrade = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowGrade');
  const ShowBSAID = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowBSAID');
  const ShowUnitPosition = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowUnitPosition');
  const ShowUnitLeader = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowUnitLeader');
  const ShowAllergiesAndDietary = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowAllergiesAndDietary');
  const ShowSpecialNeedsNotes = getYAKey(EventTypeRegistrationSettings, isYouth, 'ShowSpecialNeedsNotes');
  const ShowIsStaff = isYouth && EventTypeRegistrationSettings.NamesRegistrationSettings.ShowIsStaffYouth;
  const ShowSummerCampYear = isYouth && EventTypeRegistrationSettings.NamesRegistrationSettings.ShowSummerCampYearYouth;
  const ShowDaysInCamp = !isYouth && EventTypeRegistrationSettings.NamesRegistrationSettings.ShowDaysInCampAdult;
  const showCustomFields = customFields && customFields.length > 0;
  const showEventRegistration = showCustomFields || ShowIsStaff || ShowSummerCampYear || ShowDaysInCamp;


  return (
    <Form actions={actions} loading={loading} disabled={disabled}>
      <FieldSet>
        <Row key="adultFirst">
          <Column span={6} mobileSpan={12}>
            <TextField label="First Name"
                       id={generateDOMId("person-first-name")}
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.FirstName}
                       value={ActiveForm.FirstName}/>
          </Column>
          <Column span={6} mobileSpan={12}>
            <TextField label="MI"
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.MI}
                       value={ActiveForm.MI}/>
          </Column>
        </Row>
        <Row key="adultLast">
          <Column span={6} mobileSpan={12}>
            <TextField label="Last Name"
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.LastName}
                       value={ActiveForm.LastName}/>
          </Column>
          {!isYouth && <Column span={6} mobileSpan={12}>
            <TextField label="Suffix"
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.Suffix}
                       value={ActiveForm.Suffix}/>
          </Column>}
        </Row>
        {(ShowDOB || ShowGender) && <Row>
          {ShowDOB && <Column span={6} mobileSpan={12}>
            <DatePicker
              label="Date Of Birth"
              disabled={disabled}
              value={ActiveForm.DOB}
              validationRules={ValidationRules.DOB}
              onSelect={reduxActions.updateValue}
              onChangeRaw={reduxActions.simpleUpdate}
            />
          </Column>}
          {ShowGender && <Column span={6} mobileSpan={12}>
            <Select label="Gender"
                    disabled={disabled}
                    onChangeValue={reduxActions.updateValue}
                    value={ActiveForm.Sex}
                    validationRules={ValidationRules.Sex}
            />
          </Column>}
        </Row>}
        {isYouth && ShowGrade && <Row>
          <Column span={6} mobileSpan={12} layout="vertical">
            <Select label="Grade"
                    disabled={disabled}
                    onChangeValue={reduxActions.updateValue}
                    value={ActiveForm.Grade}
                    validationRules={ValidationRules.Grade}
                    info={EventInfo ? getGradeHelperText(EventInfo) : ''}
                    isNumber
            />
          </Column>
        </Row>}
        {(ShowShirtSize || (ShowAllowPhotography && isYouth)) && <Row>
          {ShowShirtSize && <Column span={6} mobileSpan={12} verticalAlignment="top">
            <Select label="Shirt Size"
                    disabled={disabled}
                    onChangeValue={reduxActions.updateValue}
                    value={ActiveForm.ShirtSize}
                    validationRules={ValidationRules.ShirtSize}
            />
          </Column>}
          {ShowAllowPhotography && isYouth && <Column span={6} mobileSpan={12}>
            <Switch label="Allow Photography"
                    disabled={disabled}
                    onChange={reduxActions.updateValue}
                    value={!!ActiveForm.AllowPhoto}
                    validationRules={ValidationRules.AllowPhoto}
                    info="Child may be photographed and/or videotaped to be used for promotion, free of any claims."
                    expand
            />
          </Column>}
        </Row>}
      </FieldSet>


      <FieldSet name="Contact Info">
        <Row>
          <Column span={6} mobileSpan={12}>
            <TextField label="Email"
                       id={generateDOMId("person-email")}
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.Email}
                       value={ActiveForm.Email}/>
          </Column>
        </Row>
        {ShowPhone && <Row>
          <Column span={6} mobileSpan={12}>
            <TextField label="Phone Number"
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.PhoneNumber}
                       value={ActiveForm.PhoneNumber}/>
          </Column>
          <Column span={6} mobileSpan={12}>
            <TextField label="Alternate Phone Number"
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.Phone2}
                       value={ActiveForm.Phone2}/>
          </Column>
        </Row>}
        {ShowAddress && [<Row key="addr1">
          <Column span={6} mobileSpan={12}>
            <TextField label="Address"
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.Address}
                       value={ActiveForm.Address}/>
          </Column>
          <Column span={6} mobileSpan={12}>
            <TextField label="City"
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.City}
                       value={ActiveForm.City}/>
          </Column>
        </Row>,
          <Row key="addr2">
            <Column span={6} mobileSpan={12}>
              <Select label="State"
                      disabled={disabled}
                      onChangeValue={reduxActions.updateValue}
                      value={ActiveForm.StateID}
                      validationRules={ValidationRules.StateID}
                      isNumber/>
            </Column>
            <Column span={6} mobileSpan={12}>
              <TextField label="Zip"
                         disabled={disabled}
                         onChange={reduxActions.simpleUpdate}
                         onBlur={reduxActions.updateValue}
                         validationRules={ValidationRules.Zip}
                         value={ActiveForm.Zip}/>
            </Column>
          </Row>]}
      </FieldSet>

      {showEventRegistration && <FieldSet name="Event Registration">
        {(ShowIsStaff || ShowSummerCampYear) && <Row>
          {ShowIsStaff && <Column span={6} mobileSpan={12}>
            <Select label="Is Staff?"
                    disabled={disabled}
                    onChangeValue={reduxActions.updateValue}
                    value={ActiveForm.IsStaff}
                    validationRules={ValidationRules.IsStaff}
                    isNumber
            />
          </Column>}
          {ShowSummerCampYear && <Column span={6} mobileSpan={12}>
            <TextField label="Year of Summer Camp"
                       style={{ width: '100%' }}
                       info={ShowSummerCampYear && ActiveForm.SummerCampYear ? `This will be the ${convertNumberToOrdinal(ActiveForm.SummerCampYear)} year at a summer camp.` : ' '}
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.SummerCampYear}
                       value={ActiveForm.SummerCampYear}/>
          </Column>}
        </Row>}
        {ShowDaysInCamp && <Row>
          <Column span={6} mobileSpan={12}>
            <Switch label="Attending All Week?"
                    disabled={disabled}
                    onChange={reduxActions.updateValue}
                    value={!!ActiveForm.InCampAllWeek}
                    validationRules={ValidationRules.InCampAllWeek}
            />
          </Column>
        </Row>}
        {ShowDaysInCamp && !ActiveForm.InCampAllWeek && <Row>
          <Column span={6} mobileSpan={12}>
            <Switch label="Attending Monday?"
                    disabled={disabled}
                    onChange={reduxActions.updateValue}
                    value={!!ActiveForm.InCampMon}
                    validationRules={ValidationRules.InCampMon}
            />
          </Column>
          <Column span={6} mobileSpan={12}>
            <Switch label="Attending Tuesday?"
                    disabled={disabled}
                    onChange={reduxActions.updateValue}
                    value={!!ActiveForm.InCampTue}
                    validationRules={ValidationRules.InCampTue}
            />
          </Column>
        </Row>}
        {ShowDaysInCamp && !ActiveForm.InCampAllWeek && <Row>
          <Column span={6} mobileSpan={12}>
            <Switch label="Attending Wednesday?"
                    disabled={disabled}
                    onChange={reduxActions.updateValue}
                    value={!!ActiveForm.InCampWed}
                    validationRules={ValidationRules.InCampWed}
            />
          </Column>
          <Column span={6} mobileSpan={12}>
            <Switch label="Attending Thursday?"
                    disabled={disabled}
                    onChange={reduxActions.updateValue}
                    value={!!ActiveForm.InCampThu}
                    validationRules={ValidationRules.InCampThu}
            />
          </Column>
        </Row>}
        {ShowDaysInCamp && !ActiveForm.InCampAllWeek && <Row>
          <Column span={6} mobileSpan={12}>
            <Switch label="Attending Friday?"
                    disabled={disabled}
                    onChange={reduxActions.updateValue}
                    value={!!ActiveForm.InCampFri}
                    validationRules={ValidationRules.InCampFri}
            />
          </Column>
          <Column span={6} mobileSpan={12}>
            <Switch label="Attending Saturday?"
                    disabled={disabled}
                    onChange={reduxActions.updateValue}
                    value={!!ActiveForm.InCampSat}
                    validationRules={ValidationRules.InCampSat}
            />
          </Column>
        </Row>}
        {ShowDaysInCamp && !ActiveForm.InCampAllWeek && <Row>
          <Column span={6} mobileSpan={12}>
            <Switch label="Attending Sunday?"
                    disabled={disabled}
                    onChange={reduxActions.updateValue}
                    value={!!ActiveForm.InCampSun}
                    validationRules={ValidationRules.InCampSun}
            />
          </Column>
        </Row>}
        {customFields && showCustomFields && <CustomFields
          ActiveForm={ActiveForm}
          ValidationRules={ValidationRules}
          reduxActions={reduxActions}
          customFields={customFields}
          disabled={disabled}
        />}
      </FieldSet>}


      {(ShowScout || ShowUnit || ShowBSAID || (isYouth && ShowRank) || ShowUnitPosition) && <FieldSet name="Unit">
        {ShowScout && <Row>
          <Column span={6} mobileSpan={12}>
            <Select label="Scout Type"
                    disabled={disabled}
                    onChangeValue={reduxActions.updateValue}
                    value={ActiveForm.ScoutTypeID}
                    validationRules={ValidationRules.ScoutTypeID}
                    isNumber/>
          </Column>
        </Row>}
        {ShowUnit && [
          <Row key="unit1">
            <Column span={6} mobileSpan={12}>
              <Select label="Unit Type"
                      disabled={disabled}
                      onChangeValue={reduxActions.updateValue}
                      value={ActiveForm.UnitTypeID}
                      validationRules={ValidationRules.UnitTypeID}
                      isNumber
              />
            </Column>
            <Column span={6} mobileSpan={12}>
              <TextField label="Unit"
                        disabled={disabled}
                        onChange={reduxActions.simpleUpdate}
                        onBlur={reduxActions.updateValue}
                        validationRules={ValidationRules.Unit}
                        value={ActiveForm.Unit}/>
            </Column>
          </Row>,
          <Row key="unit2">
            <Column span={6} mobileSpan={12}>
              <Select label="Council"
                      disabled={disabled}
                      onChangeValue={reduxActions.updateValue}
                      value={ActiveForm.CouncilIDi}
                      validationRules={ValidationRules.CouncilIDi}
                      isNumber
              />
            </Column>
            <Column span={6} mobileSpan={12}>
              <Select label="District"
                      disabled={disabled}
                      onChangeValue={reduxActions.updateValue}
                      value={ActiveForm.DistrictIDi}
                      validationRules={ValidationRules.DistrictIDi}
                      isNumber
              />
            </Column>
          </Row>
        ]}
        {(ShowBSAID || (isYouth && ShowRank)) && <Row>
          {ShowBSAID && <Column span={6} mobileSpan={12}>
            <TextField label="BSA ID"
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.BSAID}
                       value={ActiveForm.BSAID}/>
          </Column>}
          {isYouth && ShowRank && <Column span={6} mobileSpan={12} layout="vertical">
            <Select label="Rank"
                    disabled={disabled}
                    onChangeValue={reduxActions.updateValue}
                    value={ActiveForm.Rank}
                    info={EventInfo ? `Rank as of ${displaySimpleDate(EventInfo.StartDateTime)}` : ''}
                    validationRules={ValidationRules.Rank}
            />
          </Column>}
        </Row>}
        {ShowUnitPosition && <Row>
          <Column span={6} mobileSpan={12}>
            <TextField label={GetPositionType(
                          EventTypeRegistrationSettings.NamesRegistrationSettings.ShowOAFields,
                          GroupTypeID,
                          isYouth,
                          groupUnitType,
                          ActiveForm.UnitTypeID
                        )}
                        disabled={disabled}
                        onChange={reduxActions.simpleUpdate}
                        onBlur={reduxActions.updateValue}
                        validationRules={ValidationRules.TroopPosition}
                        value={ActiveForm.TroopPosition}/>
          </Column>
        </Row>}
      </FieldSet>}

      {isYouth && ShowUnitLeader && ShowUnit &&
        <FieldSet name="Unit Leader">
          <Row>
            <Column span={6} mobileSpan={12}>
              <TextField label="Unit Leader Name"
                         disabled={disabled}
                         onChange={reduxActions.simpleUpdate}
                         onBlur={reduxActions.updateValue}
                         validationRules={ValidationRules.UnitLeaderName}
                         value={ActiveForm.UnitLeaderName}/>
            </Column>
            <Column span={6} mobileSpan={12}>
              <TextField label="Unit Leader Phone Number"
                         disabled={disabled}
                         onChange={reduxActions.simpleUpdate}
                         onBlur={reduxActions.updateValue}
                         validationRules={ValidationRules.UnitLeaderPhone}
                         value={ActiveForm.UnitLeaderPhone}/>
            </Column>
          </Row>
          <Row>
            <Column span={6} mobileSpan={12}>
              <TextField label="Unit Leader Email"
                         disabled={disabled}
                         onChange={reduxActions.simpleUpdate}
                         onBlur={reduxActions.updateValue}
                         validationRules={ValidationRules.UnitLeaderEmail}
                         value={ActiveForm.UnitLeaderEmail}/>
            </Column>
          </Row>
        </FieldSet>
      }

      {EventTypeRegistrationSettings.NamesRegistrationSettings.ShowOAFields && <FieldSet name="Order of the Arrow">
        <Row>
          <Column span={6} mobileSpan={12}>
            <TextField label="Chapter"
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.Chapter}
                       value={ActiveForm.Chapter}/>
          </Column>
          <Column span={6} mobileSpan={12}>
            <TextField label="Lodge"
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.OALodge}
                       value={ActiveForm.OALodge}/>
          </Column>
        </Row>
        <Row>
          <Column span={6} mobileSpan={12}>
            <Select label="Status"
                    disabled={disabled}
                    onChangeValue={reduxActions.updateValue}
                    value={ActiveForm.OAStatusID}
                    validationRules={ValidationRules.OAStatusID}
                    isNumber
            />
          </Column>
          <Column span={6} mobileSpan={12}>
            <TextField label="Position"
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.OAPosition}
                       value={ActiveForm.OAPosition}/>
          </Column>
        </Row>
      </FieldSet>}


      {!isYouth && (ShowDateBalooTraining || ShowDateBasicTrainingForPosition || ShowDateYouthProtectionTraining) && <FieldSet name="Training">
        {(ShowDateBalooTraining || ShowDateYouthProtectionTraining) &&<Row>
          {ShowDateYouthProtectionTraining && <Column span={6} mobileSpan={12}>
            <DatePicker
              label="Youth Protection Training"
              value={ActiveForm.DateYouthProtectionTraining}
              validationRules={ValidationRules.DateYouthProtectionTraining}
              onSelect={reduxActions.updateValue}
              onChangeRaw={reduxActions.simpleUpdate}
            />
          </Column>}
          {ShowDateBalooTraining && <Column span={6} mobileSpan={12}>
            <DatePicker
                label="Baloo Training"
                value={ActiveForm.DateBalooTraining}
                validationRules={ValidationRules.DateBalooTraining}
                onSelect={reduxActions.updateValue}
                onChangeRaw={reduxActions.simpleUpdate}
              />
          </Column>}
        </Row>}
        {ShowDateBasicTrainingForPosition && <Row>
           <Column span={6} mobileSpan={12}>
            <DatePicker
              label="Basic Training"
              value={ActiveForm.DateBasicTrainingForPosition}
              validationRules={ValidationRules.DateBasicTrainingForPosition}
              onSelect={reduxActions.updateValue}
              onChangeRaw={reduxActions.simpleUpdate}
            />
          </Column>
        </Row>}
      </FieldSet>}

      {(ShowAllergiesAndDietary || ShowSpecialNeedsNotes) && <FieldSet name="Special Needs">
        {ShowAllergiesAndDietary && <Row>
          <Column span={6} mobileSpan={12}>
            <Switch label="Serious Allergies"
                    disabled={disabled}
                    onChange={reduxActions.updateValue}
                    value={!!ActiveForm.HasSeriousAllergies}
                    validationRules={ValidationRules.HasSeriousAllergies}
            />
          </Column>
          <Column span={6} mobileSpan={12}>
            <Switch label="Dietary Restrictions"
                    disabled={disabled}
                    onChange={reduxActions.updateValue}
                    value={!!ActiveForm.HasDietaryRestrictions}
                    validationRules={ValidationRules.HasDietaryRestrictions}
            />
          </Column>
        </Row>}
        {ShowSpecialNeedsNotes && <Row>
          <TextField
            label="Additional Info"
            disabled={disabled}
            onChange={reduxActions.simpleUpdate}
            onBlur={reduxActions.updateValue}
            validationRules={ValidationRules.SpecialNeeds}
            value={ActiveForm.SpecialNeeds}
            rows={4}
            info="Please enter info about any allergies, dietary restrictions, or other needed accommodations."
          />
        </Row>}
      </FieldSet>}


      {isYouth && EventTypeRegistrationSettings.NamesRegistrationSettings.ShowEmergencyContact && <FieldSet name="Emergency Contact">
        <Row>
          <Column span={6} mobileSpan={12}>
            <TextField label="Emergency Contact Name"
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.EmergencyName}
                       value={ActiveForm.EmergencyName}/>
          </Column>
          <Column span={6} mobileSpan={12}>
            <TextField label="Emergency Contact Phone"
                       disabled={disabled}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.EmergencyPhone}
                       value={ActiveForm.EmergencyPhone}/>
          </Column>
        </Row>
      </FieldSet>}


    </Form>
  );
};

export default PersonFormTemplate;


const getYAKey = (EventTypeRegistrationSettings: EventTypeRegistrationSettings, isYouth: boolean, key: string) => {
  if (isYouth) {
    return !!EventTypeRegistrationSettings.NamesRegistrationSettings[`${key}Youth`];
  } else {
    return !!EventTypeRegistrationSettings.NamesRegistrationSettings[`${key}Adult`];
  }
};

const GetPositionType = (
  ShowLodge: boolean, GroupTypeID: number, IsYouth: boolean, groupUnitType: string, camperUnitTypeID?: any
) : string => {
  /* This code is based on RankOptions code below. Need to extend to support other types of units!! */
  let groupPositionType: string = "Troop";
  let unitTypeIndex: number = 0;

  //Get unit type from Group or individual
  if (GroupTypeID !== 1) {
    const rootState = reduxStoreService().getState();
    if (camperUnitTypeID && rootState.cacheZero.options && rootState.cacheZero.options.UnitTypes) {
      const UnitTypes = rootState.cacheZero.options.UnitTypes;
      const unitType = UnitTypes.find((id) => id === camperUnitTypeID);
      if (unitType) groupPositionType = unitType.Name;
    }
  } else {
    groupPositionType = groupUnitType;
  }

  //Add /OA if appropriate, only for adults that share the same field.
  // if (ShowLodge && !IsYouth) groupPositionType = groupPositionType + "/OA";

  // return groupPositionType + ' Position';
  return 'Unit Position';
};
