
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, ModalActions, Button, Loader
} from '../../../../../Elements';
import { EventTypeFormModalNamespace as namespace } from '../../../../../../store/AdminEvents/EventTypes/EventType/Form/actions';
import { actionCreators, GetEventTypeAction } from '../../../../../../store/AdminEvents/EventTypes/Modals/NewEventType/actions';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../store/Rollback/actions';
import { actionCreators as cacheOneActionCreators } from '../../../../../../store/AdminEvents/CacheOne/actions';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { ApplicationState } from '../../../../../../store';
import '../../../../../../styles/pages/admin-events/event-types/modal/new-event-type/index.scss';
import { ModalHeight } from '../../../../../Elements/Modal';
import { extractRouteParams } from '../../../../../../utils/urlHelper';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../store/service';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;
type Props = WithInertAttribute<{}>

/**
 * This Modal handles both new and duplicate event type
 */
@(withRouter as any)
class NewEventTypeModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;


  componentDidMount() {
    /**
     * There could be a load in this modal (duplicate a different event type), so we should check
     * `shouldBlockActions()`. Since modal cannot be opened via browser url change, we dont need to
     * run this logic on props change.
     * 
     * We also block load for duplicateEventType modal if it is rolling back, because here we are not relying
     * on any cacheNeedsReload check like we have in other pages. So when it's rolling back, we should block both
     * init and load, because all needed data should be there.
     */
    if (shouldBlockActions()) return;

    const { duplicateEventTypeId, adminEventsCacheOne, actions } = this.props;

    if (duplicateEventTypeId) {
      if (!adminEventsCacheOne.EventsEventType || duplicateEventTypeId !== adminEventsCacheOne.EventsEventType.EventTypeRow.ID) {
        actions.getEventType({
          ...extractRouteParams(this.props),
          EventTypeID: duplicateEventTypeId,
          EventTypeTS: 0,
          GetOptions: false,
          GetYearOverview: false,
          GetEventsOptions: false,
        });
      } else if (adminEventsCacheOne.EventsEventType &&  duplicateEventTypeId === adminEventsCacheOne.EventsEventType.EventTypeRow.ID) {
        actions.init(true);
      }
    } else if (!duplicateEventTypeId) {
      actions.init();
    }
  }

  componentWillUnmount() {
    this.onClose();
  }

  onSave = () => {
    this.props.actions.apiSubmitForm(
      this.props.router,
      this.props.location,
      this.props.duplicateEventTypeId,
    );
  };

  onClose = () => {
    this.props.actions.reset();
  };

  currentStep = () => {
    const { newEventTypeForm } = this.props;
    if (newEventTypeForm.General.ActiveForm.RegistrationMethodID) {
      return 2;
    } else {
      return 1;
    }
  };

  renderContent = () => {
    const {cacheZero, inert} = this.props;

    const currentStep = this.currentStep();

    switch(currentStep) {
      case 2:
        return <StepTwo inert={inert} />;
      case 1:
        return (
          <StepOne
            cacheZero={cacheZero}
            onSelectRegistrationMethod={this.onSelectRegistrationMethod}
          />
        );
      default:
        return null;
    }
  };

  onSelectRegistrationMethod = (id: number) => {
    this.props.actions.updateEventTypeGeneralValue(id, this.props.newEventTypeForm.General.ValidationRules.RegistrationMethodID);
  };

  public render() {
    const { apiLoadingMap, duplicateEventTypeId, newEventTypeForm, apiSaving, actions, inert} = this.props;

    const title = duplicateEventTypeId ? 'Duplicate Event Type' : 'New Event Type';
    const loading = apiLoadingMap[GetEventTypeAction.requestType];
    const registrationMethodSelected = !!newEventTypeForm.General.ActiveForm.RegistrationMethodID;
    const padding = newEventTypeForm.General.ActiveForm.RegistrationMethodID ? undefined: 0;
    const paddingTop = newEventTypeForm.General.ActiveForm.RegistrationMethodID ? 16: 0;

    return (
      <Modal
        inert={inert}
        wideModal
        big mobileFullScreen
        className={namespace()}
        onClose={this.onClose}
        height={registrationMethodSelected ? undefined: ModalHeight.HEIGHT_425}
      >
        <ModalHeader className={`${namespace()}--header`}>{title}</ModalHeader>
        <ModalContent paddingLeft={padding} paddingRight={padding} paddingTop={paddingTop} paddingBottom={padding}>
          {!loading && this.renderContent()}
          {loading && <Loader className={`${namespace()}--loader`} center />}
        </ModalContent>
        {registrationMethodSelected ? <ModalActions
          sticky
          notFixed
          left={<Button textColor='black' disabled={loading || apiSaving > 0} flat onClick={this.onSave}>{duplicateEventTypeId ? 'DUPLICATE' : 'CREATE'}</Button>}
        /> : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const duplicateEventTypeIdSelector = makeFormModalPropSelector(ModalTypes.NEW_EVENT_TYPE, 'duplicateEventTypeId');

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    newEventTypeForm: state.adminEvents.eventTypes.modals.newEventType,
    duplicateEventTypeId: duplicateEventTypeIdSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...appActionCreators,
  ...actionCreators,
  ...cacheOneActionCreators,
  ...rollbackActionCreators,
}, dispatch) });

const ConnectedNewEventTypeModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(NewEventTypeModal);

export default ConnectedNewEventTypeModal;