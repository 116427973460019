import * as React from 'react';
import { Product } from '../../../../../../Elements';
import { ProductsFormTemplate } from '../../../../../../Templates';
import ProductsAdded from '../../ProductsAdded';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import { bindActionCreators } from 'redux';
import { Product as ProductModel } from '../../../../../../../models/product';
import {Actions, actionCreators} from '../../../../../../../store/Events/Event/Register/Products/Products/actions';

import '../../../../../../../styles/pages/events/event/register/products.scss';
import {
  makeAddedProductsProductsFilter,
  makeAvailableProductsProductsFilter,
  makeAvailableProductsProductsSearchFilter,
  makePreviousProductsProductsFilter
} from "../../../../../../../store/CacheFourEventsProducts";
import { ApplicationState } from '../../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

// @todo: this class is the same as Product/Products/index?
export const namespace = (): string => `pages--events--event--products--wizard`;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = WithInertAttribute<{}>;

class Products extends React.Component<Props, {}> {
  public props: Props &ConnectedProps;


  renderProduct = (actions: Actions, p: ProductModel, index: number) => {
    return (<Product
      key={index}
      onAddProduct={actions.addProduct}
      onUpdateProduct={actions.setNotAdded}
      productModel={p}
    />);
  };

  public render() {

    const { inert, addedProducts, availableProducts, actions, user, filteredAvailableProducts, apiLoading, apiSaving, previousProducts } = this.props;

    let emptyMessage: string | undefined;

    if (availableProducts === null || (availableProducts && availableProducts.length === 0)) {
      emptyMessage = 'There are no available products';
    } else if (filteredAvailableProducts && filteredAvailableProducts.length === 0) {
      emptyMessage = 'No available products found';
    }

    const props = {
      key: 'numbers',
      inert,
      footer: <FooterProgress selected="products"/>,
      header: <HeaderProgress selected="products" loading={apiSaving > 0}/>,
      sideBar: <ProductsAdded
        isAdmin={user.user && user.user.str_permissions.hasAdminAccess}
        products={addedProducts}
        orderedProducts={previousProducts}
        loading={apiLoading > 0}
        onRemove={actions.removeProduct}
        onUpdate={actions.setAdded}
        onCancel={actions.cancelProduct}
        onCommit={actions.commitProduct}
        onManage={actions.manageProduct}
      />,
      loading: apiLoading > 0,
      addedItems: [...addedProducts, ...previousProducts],
      className: namespace(),
      emptyMessage: emptyMessage
    };
    /*
    {availableProducts.length !== 0 && <Alert className={`${namespace()}--alert`} hidable>
      Products are only applied to the whole group for this event.
    </Alert>}
    */
    return (
      <ProductsFormTemplate {...props}>
        {filteredAvailableProducts.map(this.renderProduct.bind(this, actions))}
      </ProductsFormTemplate>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const availableProducts = makeAvailableProductsProductsFilter();
  const addedProducts = makeAddedProductsProductsFilter();
  const previousProducts = makePreviousProductsProductsFilter();
  
  const filteredAvailableProducts = makeAvailableProductsProductsSearchFilter();
  return {
    cacheFourEventsProducts: state.cacheFourEventsProducts,
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    availableProducts: availableProducts(state),
    addedProducts: addedProducts(state),
    previousProducts: previousProducts(state),
    filteredAvailableProducts: filteredAvailableProducts(state),
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actionCreators }, dispatch)
});

const ConnectedProducts = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(Products);

export default ConnectedProducts;

