import * as React from 'react';
import {
  Modal, ModalContent, ModalActions, TextField, Button, Row, Column, ModalHeader, Text, Alert
} from '../../../../Elements';
import {actionCreators} from "../../../../../store/Settings/ChangePassword/actions";
import {ApplicationState} from "../../../../../store";
import {bindActionCreators} from 'redux';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { shouldBlockActions } from '../../../../../utils/cacheLoaders/helpers/blockers';
import { ModalHeight } from '../../../../Elements/Modal';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { connect } from 'react-redux';
import { reduxStoreService } from '../../../../../store/service';
import {WithInertAttribute} from '../../../../Elements/WithInert';

const namespace = () => "modals--change-password";

type Props = WithInertAttribute<{}>

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class ChangePassword extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  componentWillUnmount() {
  }

  componentDidMount(): void {
    if (shouldBlockActions()) return;
    
    const {actions} = this.props;
    actions.initChangePassword();
  }

  onSave = () => {
    this.props.actions.changePasswordApiSubmitForm();
  };

  public render() {
    const {changePassword: {ActiveForm, ValidationRules, SubmitErrorMessage}, IsTemporaryPassword, inert, actions, apiSaving, user} = this.props;
    const isAdmin = !!user.user.str_permissions.hasAdminAccess;
    return (
      <Modal
        inert={inert}
        mobileFullScreen disableDynamicHeight big
        /* When `IsTemporaryPassword`, disable close */
        disableClose={IsTemporaryPassword}
        height={ModalHeight.AUTO}
      >
        <ModalHeader>Change Password</ModalHeader>
        <ModalContent hasPadding>
          {SubmitErrorMessage && <Row>
            <Alert>{SubmitErrorMessage}</Alert>
            </Row>}
          {IsTemporaryPassword && <Row marginBottom={16}>
            <Text className={`${namespace()}--prompt`}>For security reasons, please change your password to continue:</Text>
          </Row>}
          {
          /**
           * Show text about force password change if password is being changed for someone else. 
           * 
           * Currently, password is always being changed for someone else if the current user is an admin - 
           * since this change password modal only supports changing password for end users so far. 
           * Password can't be changed for admin users through this modal yet - We'll first add support 
           * for admin users in this force password reset feature, then later maybe use the Change Password 
           * modal for other admin password change cases. Like we currently do on end user side.
           */
          }
          {isAdmin && !IsTemporaryPassword && <Row marginBottom={16}>
            <Text className={`${namespace()}--prompt`}>User will be required to change their password the next time they login.</Text>
          </Row>}
          {!isAdmin && !IsTemporaryPassword && <Row marginBottom={8}>
            <Column span={12}>
              <TextField value={ActiveForm.CurrentPassword}
                         label="Current Password"
                         type="password"
                         onBlur={actions.changePasswordUpdateValue}
                         onChange={actions.changePasswordSimpleUpdate}
                         validationRules={ValidationRules.CurrentPassword}
              />
            </Column>
          </Row>}
          <Row marginBottom={8}>
            <Column span={12}>
              <TextField value={ActiveForm.NewPassword}
                         label="New Password"
                         type="password"
                         onBlur={actions.changePasswordUpdateValue}
                         onChange={actions.changePasswordSimpleUpdate}
                         validationRules={ValidationRules.NewPassword}
              />
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <TextField value={ActiveForm.ConfirmNewPassword}
                         label="Repeat New Password"
                         type="password"
                         onBlur={actions.changePasswordUpdateValue}
                         onChange={actions.changePasswordSimpleUpdate}
                         validationRules={ValidationRules.ConfirmNewPassword}
              />
            </Column>
          </Row>
        </ModalContent>
        <ModalActions
          noPadding
          notFixed
          sticky
          loading={apiSaving > 0}
          left={<Button textColor='black' id={generateDOMId("save-edit-password-modal")} flat onClick={this.onSave}>Complete</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    changePassword: state.settings.changePassword,
    user: state.user,
    IsTemporaryPassword: state.cacheZero.options?.Login?.IsTemporaryPassword,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actionCreators }, dispatch)
});
const ConnectedChangePassword = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ChangePassword);

export default ConnectedChangePassword;
