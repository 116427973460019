import * as React from 'react';

import '../../../styles/elements/time-picker/index.scss';
import Select from '../Select';
import { SelectValidator, Validator } from '../../../utils/validator/models';

export const namespace = (): string => 'elements--time-picker';

// @todo: is there a reason we're just using straight up <select> here and not a lib?

export interface SelectProps {
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  label?: string;
  bold?: boolean;
  disabled?: boolean;
  onChangeValue?: (value: string | number | undefined, validationRules?: Validator) => any;
  hourValue?: any;
  hourValidationRules?: SelectValidator;
  minValue?: any;
  minValidationRules?: SelectValidator;
  periodValue?: any;
  periodValidationRules?: SelectValidator;
  hideOptional?: boolean;
  customizedId?: string;
  helperText?: string | React.ReactNode;
}

class TimePicker extends React.Component<SelectProps, {}> {
  private wrapperRef;

  componentDidMount() {
    this.updateInfoStyle();
    window.addEventListener('resize', this.updateInfoStyle);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateInfoStyle);
  }

  componentDidUpdate() {
    this.updateInfoStyle();
  }

  updateInfoStyle = () => {
    const {helperText} = this.props;
    if (this.wrapperRef) {
      if (helperText) {
        const error = this.getError();
        const select = this.wrapperRef.firstElementChild;
        const info = this.wrapperRef.lastElementChild;

        if (select) {
          if (!error) select.style.paddingBottom = `${(info.clientHeight || 8) + 8}px`;
          else select.style.paddingBottom = '24px';
        }
      }
    }
  };

  getError = () => {
    const {minValidationRules, hourValidationRules, periodValidationRules} = this.props;
    
    let error = '';
    if (hourValidationRules && minValidationRules && periodValidationRules) {
      if (hourValidationRules.errors && hourValidationRules.errors.length > 0) {
        error += hourValidationRules.errors[0];
      }
      if (minValidationRules.errors && minValidationRules.errors.length > 0) {
        error += ` ${minValidationRules.errors[0]}`;
      }
      if (periodValidationRules.errors && periodValidationRules.errors.length > 0) {
        error += ` ${periodValidationRules.errors[0]}`;
      }
    }

    return error;
  };

  public render() {
    const {disabled, onChangeValue, className: _className, hourValue, hourValidationRules, minValue, minValidationRules, periodValue, periodValidationRules, helperText, label, customizedId} = this.props;

    const error = this.getError();
    const timePicker = (
      <div ref={(ref) => this.wrapperRef = ref} className={`${namespace()} ${_className}`}>
        <Select
          customizedId={`${customizedId}-hour`}
          label={label}
          disabled={disabled}
          onChangeValue={onChangeValue}
          value={hourValue}
          validationRules={hourValidationRules}
          hideOptional
          hideError
          isNumber />
        <Select
          customizedId={`${customizedId}-min`}
          disabled={disabled}
          onChangeValue={onChangeValue}
          value={minValue}
          validationRules={minValidationRules}
          hideOptional
          hideError
          isNumber />
        <Select
          customizedId={`${customizedId}-sec`}
          disabled={disabled}
          onChangeValue={onChangeValue}
          value={periodValue}
          validationRules={periodValidationRules}
          hideOptional
          hideError
          isNumber />
        {error ? <div className={`${namespace()}--error`}>{error}</div> : null}
        {helperText ? <div className={`${namespace()}--helper-text`}>{helperText}</div> : null}
      </div>
    );

    return timePicker;
  }
}

export default TimePicker;