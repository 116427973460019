/**
 * Admin Event Types related urls
 */
import { AdminEventsCacheOneState } from "../store/AdminEvents/CacheOne";
import { spaceTo_ } from "../utils";
import { URLS, getAccountQP, getMaxId, getQP, ADMIN_EVENTS_API_URL } from "./urls";
import { ApplicationState } from "../store";
import { AdminEventsProduct, AdminEventsEmailMessage, AdminEventsImage } from "../models/api/adminEventsCacheOne";
import { EventTypeGeneralActiveForm } from "../store/AdminEvents/EventTypes/EventType/Form";
import { getGroupID } from "../utils/helpers/endUserPageHelper";
import { getEventTypeId } from "../utils/helpers/adminEventsPageHelper";
import { withMaxClassTypeIDiAndHasMeritBadges } from "../utils/helpers/requestHelpers";
import { reduxStoreService } from "../store/service";

export const ADMIN_EVENTS_PATH = {
  EVENTS: 'Events',
  PRODUCTS: 'Products',
  REPORTS: 'Reports',
  MESSAGE_CENTER: 'Messages',
  YEAR_OVERVIEW: 'YearOverview',
  SETTINGS: 'Settings',
};

export const ADMIN_EVENTS_EVENT_PATH = {
  DASHBOARD: 'Dashboard',
  SETTINGS: 'Settings',
  CLASS_TYPES: 'ClassTypes',
  CLASS_LIST: 'Classes',
  TIME_BLOCKS: 'TimeBlocks',
};

export const ADMIN_EVENT_CLASS_TYPE = {
  NEW: 'new',
};

export const ADMIN_EVENT_CLASS = {
  NEW: 'new',
};

export const ADMIN_EVENT_MESSAGE = {
  NEW: 'new',
};

export const ADMIN_EVENT_PRODUCT = {
  NEW: 'new',
};

export interface GetAdminEventTypesCacheBodyParams {
  EventTypeID?: number;
  EventTypeTS?: number;
  GetEventsOptions?: boolean;
  GetEvents?: boolean;
  GetProducts?: boolean;
  GetYearOverview?: boolean;
  MaxEventIDi?: number;

  MaxLocationID?: number;
  MaxGLAccountID?: number;
  MaxProductIDi?: number;
  MaxMessageID?: number;

  YearOverview_Year?: number;

  GetOptions?: boolean;
  GroupIDi?: number;
  GroupTS?: number;
  routes?: any;
  location?: any;
  params?: any;
}

export const constructUpdateEventTypesCacheBodyBaseParams = (isAddingEventType?: boolean): GetAdminEventTypesCacheBodyParams => {
  // not adding null check here, since these nodes should be available
  const state: ApplicationState = reduxStoreService().getState();
  const EventTypeTS = state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.EventTypeRow.TS : 0;
  
  // Unset max ID when `isAddingEventType` is true, because we are about to clear
  // those data. This is the only special case because
  // - One can add/duplicate an event type while in a different event type.
  // - Event type is cache one data, which determines the flag values in normal cases; hence, we need to manually unset the flags before clearing the data
  // We have a similar scenario which is adding a facility location, but because we dont
  // switch to the new facility location there is no issue there.
  const MaxEventIDi = isAddingEventType ? 0 :
    (state.adminEvents.cacheOne.EventsEvents ? 
      getMaxId(state.adminEvents.cacheOne.EventsEvents) : 0);
  const MaxProductIDi = isAddingEventType ? 0 :
    (state.adminEvents.cacheOne.EventsProducts ?
      getMaxId(state.adminEvents.cacheOne.EventsProducts, true) : 0);
  const MaxMessageID = isAddingEventType ? 0 :
    (state.adminEvents.cacheOne.EventsEmailMessages ?
      getMaxId(state.adminEvents.cacheOne.EventsEmailMessages) : 0);

  const MaxLocationID = state.cacheZero.options && state.cacheZero.options.Locations ? getMaxId(state.cacheZero.options.Locations) : 0;
  const MaxGLAccountID = state.cacheZero.options && state.cacheZero.options.GLAccounts ? getMaxId(state.cacheZero.options.GLAccounts) : 0;

  const GetOptions = !state.cacheZero.options;
  const GetEventsOptions = !state.adminEvents.cacheOne.EventTypeReportsOptions;
  const GroupIDi = state.cacheZero.options && state.cacheZero.options.Group ? state.cacheZero.options.Group.IDi : 0;
  const GroupTS = state.cacheZero.options && state.cacheZero.options.Group ? state.cacheZero.options.Group.TS : 0;
  const EventTypeID = state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID as number : 0;

  return {
    EventTypeID,
    EventTypeTS,

    GetEvents: false,
    GetProducts: false,
    GetYearOverview: false,

    MaxLocationID,
    MaxGLAccountID,
    MaxProductIDi,
    MaxMessageID,

    MaxEventIDi,

    GetEventsOptions,

    GetOptions,
    GroupIDi,
    GroupTS,
  };
};

export const constructGetEventTypesCacheBodyBaseParams = (props: {location: any, routes: any, params: any}): GetAdminEventTypesCacheBodyParams => {
  // not adding null check here, since these nodes should be available
  const state: ApplicationState = reduxStoreService().getState();
  const EventTypeTS = state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.EventTypeRow.TS : 0;
  
  const MaxEventIDi = state.adminEvents.cacheOne.EventsEvents ? getMaxId(state.adminEvents.cacheOne.EventsEvents) : 0;
  const MaxLocationID = state.cacheZero.options && state.cacheZero.options.Locations ? getMaxId(state.cacheZero.options.Locations) : 0;
  const MaxGLAccountID = state.cacheZero.options && state.cacheZero.options.GLAccounts ? getMaxId(state.cacheZero.options.GLAccounts) : 0;
  const MaxProductIDi = state.adminEvents.cacheOne.EventsProducts ? getMaxId(state.adminEvents.cacheOne.EventsProducts, true) : 0;
  const MaxMessageID = state.adminEvents.cacheOne.EventsEmailMessages ? getMaxId(state.adminEvents.cacheOne.EventsEmailMessages) : 0;

  const GetOptions = !state.cacheZero.options;
  const GetEventsOptions = !state.adminEvents.cacheOne.EventTypeReportsOptions;
  const GroupIDi = getGroupID(props);
  const GroupTS = state.cacheZero.options && state.cacheZero.options.Group ? state.cacheZero.options.Group.TS : 0;
  const EventTypeID = getEventTypeId(props);

  return {
    EventTypeID,
    EventTypeTS,

    GetEvents: false,
    GetProducts: false,
    GetYearOverview: false,

    MaxLocationID,
    MaxGLAccountID,
    MaxProductIDi,
    MaxMessageID,

    MaxEventIDi,

    GetEventsOptions,

    GetOptions,
    GroupIDi,
    GroupTS,
  };
};

const GET_EVENT_TYPES = '/EventTypes/GetEventTypes';
export const getEventTypes = () => `${ADMIN_EVENTS_API_URL}${GET_EVENT_TYPES}${getQP()}`;
export const getEventTypesBody = () => {
  const state: ApplicationState = reduxStoreService().getState();

  const MaxLocationID = state.cacheZero.options && state.cacheZero.options.Locations ? getMaxId(state.cacheZero.options.Locations) : 0;
  return {
    AppState: {
      MaxLocationID,
    },
  };
};

export interface GetEventTypeParams {
  ID: number;
  location?: any;
  routes?: any;
  params?: any;
}
const AdminEventsCacheBodyParamsKeys = {
  EventTypeID: true,
  EventTypeTS: true,
  GetEventsOptions: true,
  GetEvents: true,
  MaxEventIDi: true,
  GetProducts: true,
  GetYearOverview: true,
  
  MaxLocationID: true,
  MaxGLAccountID: true,
  MaxProductIDi: true,
  MaxMessageID: true,

  YearOverview_Year: true,
  GetOptions: true,
  GroupIDi: true,
  GroupTS: true,
};

const constructAdminEventsCacheAppParams = (params: any) => {
  const result = {};

  for (let key of Object.keys(params)) {
    if (AdminEventsCacheBodyParamsKeys[key]) {
      result[key] = params[key];
    }
  }

  return result;
};

const GET_EVENT_TYPE_CACHE = '/EventTypes/GetEventTypeCache';
export const getEventTypeCache = () => `${ADMIN_EVENTS_API_URL}${GET_EVENT_TYPE_CACHE}${getQP()}`;
export const getEventTypeBody = (params: GetAdminEventTypesCacheBodyParams) => {
  const baseParams = {...constructGetEventTypesCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params})};

  const customParams = constructAdminEventsCacheAppParams(params);

  return {
    AppState: {
      ...baseParams,
      ...customParams,
    },
  };
};

const UPDATE_EVENT_TYPE = '/EventTypes/UpdateEventType/?ParseJSON=0';
export const getUpdateAdminEventType = () => `${ADMIN_EVENTS_API_URL}${UPDATE_EVENT_TYPE}${getQP(true)}`;
export const getAddAdminEventTypeBody = (ActiveForm: EventTypeGeneralActiveForm, SourceEventTypeID?: number) => {
  return {
    AppState: {
      ...constructUpdateEventTypesCacheBodyBaseParams(true),
      EventTypeID: null,
    },
    SourceEventTypeID,
    General: [{
      IsPrevious: false, 
      RegistrationMethodID: ActiveForm.RegistrationMethodID,
      Name: ActiveForm.Name,
      Description: ActiveForm.Description,
      LeadersGuideURL: ActiveForm.LeadersGuideURL,
      WebsiteURL: ActiveForm.WebsiteURL,
      LocationID: ActiveForm.LocationID,
      EventCategoryID: ActiveForm.EventCategoryID,
      DistrictIDi: ActiveForm.DistrictIDi,
      PrimaryContactName: ActiveForm.PrimaryContactName,
      PrimaryContactPhone: ActiveForm.PrimaryContactPhone,
      PrimaryContactEmail: ActiveForm.PrimaryContactEmail
    }],
  };
};
export const getUpdateAdminEventTypeBody = () => {
  const body: any = {
    AppState: {
      ...constructUpdateEventTypesCacheBodyBaseParams(),
    },
  };

  return body;
};

export const getDeleteAdminEventTypeBody = (EventTypeID: number, Inactive: boolean) => {
  return {
    AppState: {
      ...constructUpdateEventTypesCacheBodyBaseParams(),
      EventTypeID,
    },
    Inactive,
  };
};

// EventType - Reports
export const GENERATE_REPORTS = '/GenerateReports/';
export const generateEventTypeReports = ({path}: {path: string}) => `${ADMIN_EVENTS_API_URL}${GENERATE_REPORTS}${path}${getQP()}`;
export const generateEventTypeReportsBody = ({EventTypeID, Year}: {EventTypeID: number, Year: number}) => {
  return {EventTypeID, Year};
};

// Event - Reports
export const GENERATE_EVENT_REPORT = '/GenerateReports_Event/';
export const generateEventReports = ({path}: {path: string}) => `${ADMIN_EVENTS_API_URL}${GENERATE_EVENT_REPORT}${path}${getQP()}`;
export interface GenerateEventReportParams {
  EventIDi: number;
  EventTypeID: number;
  IncludeBlankRows: boolean;
  IncludeSecondaryTimeblocks: boolean;
  GroupIDi: number;
  IsFront: boolean;
  BillEntireBalance?: boolean;
  BillAmountPerYouth?: number;
  IncludeNoBalanceDue?: boolean;
  DueDate?: string;
  InvoiceNotes?: string;
  JustExportPDF?: boolean;
  GroupsCSV?: string;
  ClassIDi?: number;
}
export const generateEventReportsBody = ({
  EventIDi,
  EventTypeID,
  IncludeBlankRows,
  IncludeSecondaryTimeblocks,
  GroupIDi,
  IsFront,
  BillEntireBalance,
  BillAmountPerYouth,
  IncludeNoBalanceDue,
  DueDate,
  InvoiceNotes,
  JustExportPDF,
  GroupsCSV,
  ClassIDi,
}: GenerateEventReportParams) => {
  return {
    EventTypeID,
    EventIDi,
    IncludeBlankRows,
    IncludeSecondaryTimeblocks,
    GroupIDi,
    IsFront,
    BillEntireBalance,
    BillAmountPerYouth,
    IncludeNoBalanceDue,
    DueDate,
    InvoiceNotes,
    JustExportPDF,
    GroupsCSV,
    ClassIDi,
  };
};

export const generateEventReport_Get = ({path, EventIDi, EventTypeID}: {path: string, EventIDi: number, EventTypeID: number}) => {
  return `${ADMIN_EVENTS_API_URL}${GENERATE_EVENT_REPORT}${path}?EventIDi=${EventIDi}&EventTypeID=${EventTypeID}${getQP(true)}`;
};

// Outside Cache - Registered Groups
export const GET_REGISTERED_GROUPS = '/Events/GetGroupsEventRegistrations';
export const getRegisteredGroups = () => `${ADMIN_EVENTS_API_URL}${GET_REGISTERED_GROUPS}${getQP()}`;
export const getRegisteredGroupsBody = (params: GetAdminEventCacheBodyParams) => {
  return {
    AppState: {
      EventTypeID: params.EventTypeID,
      EventIDi: params.EventIDi,
    },
  };
};


// Cache Two - Product
export interface GetAdminEventProductCacheBodyParams extends GetAdminEventTypesCacheBodyParams {
  ProductIDi?: number;
}

const GET_ADMIN_EVENT_PRODUCT_CACHE = '/Products/GetProduct/';
export const getAdminEventProductCache = () => `${ADMIN_EVENTS_API_URL}${GET_ADMIN_EVENT_PRODUCT_CACHE}${getQP()}`;
export const getAdminEventProductBody = (params: GetAdminEventProductCacheBodyParams) => {
  const baseParams = {...constructGetEventTypesCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params})};

  const customParams = constructAdminEventCacheAppParams(params);

  return {
    AppState: {
      ...baseParams,
      ...customParams,
    },
    IDi: params.ProductIDi,
  };
};


interface SubmitAdminEventProductParams {
  IDi?: number;
  Name?: string;
  IsAdminOnly?: boolean;
  FeaturedImage?: AdminEventsImage;
  Amount?: number;
  GLAccountID?: number;
  Description?: string;
  TS?: number;
  RemoveImage?: boolean;
  Inactive?: boolean;
}
const UPDATE_ADMIN_EVENT_PRODUCT = '/Products/UpdateProduct/?ParseJSON=0';
export const getUpdateAdminEventProduct = () => `${ADMIN_EVENTS_API_URL}${UPDATE_ADMIN_EVENT_PRODUCT}${getQP(true)}`;
export const getUpdateAdminEventProductBody = (params: SubmitAdminEventProductParams) => {
  return {
    AppState: {
      ...constructUpdateEventTypesCacheBodyBaseParams(),
    },
    row: {
      IDi: params.IDi,
      Name: params.Name,
      IsAdminOnly: params.IsAdminOnly,
      Amount: params.Amount,
      GLAccountID: params.GLAccountID,
      Description: params.Description,
      TS: params.TS,
      RemoveImage: params.RemoveImage,
    },
  };
};

export const getDeleteAdminEventProductBody = (params: SubmitAdminEventProductParams) => {
  return {
    AppState: {
      ...constructUpdateEventTypesCacheBodyBaseParams(),
    },
    row: {
      IDi: params.IDi,
      Inactive: params.Inactive,
    },
  };
};

// Cache Two - Message
export interface GetAdminEventMessageCacheBodyParams extends GetAdminEventTypesCacheBodyParams {
  MessageID?: number;
}

const GET_ADMIN_EVENT_MESSAGE_CACHE = '/Messages/GetMessage/';
export const getAdminEventMessageCache = () => `${ADMIN_EVENTS_API_URL}${GET_ADMIN_EVENT_MESSAGE_CACHE}${getQP()}`;
export const getAdminEventMessageBody = (params: GetAdminEventMessageCacheBodyParams) => {
  const baseParams = {...constructGetEventTypesCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params})};

  const customParams = constructAdminEventCacheAppParams(params);

  return {
    AppState: {
      ...baseParams,
      ...customParams,
    },
    ID: params.MessageID,
  };
};

interface SubmitAdminEventMessageParams {
  ID?: number;
  Name?: string;
  Message?: string;
  SendingMessage?: boolean;
  SendToEventIDi?: number;
  GroupsCSV?: string;
  TS?: number;
  Inactive?: boolean;
}
const SUBMIT_MESSAGE = '/Messages/UpdateMessage/?ParseJSON=0';
export const getUpdateAdminEventMessage = () => `${ADMIN_EVENTS_API_URL}${SUBMIT_MESSAGE}${getQP(true)}`;
export const getUpdateAdminEventMessageBody = (params: SubmitAdminEventMessageParams) => {
  return {
    AppState: {
      ...constructUpdateEventTypesCacheBodyBaseParams(),
    },
    row: {
      ID: params.ID,
      Name: params.Name,
      Message: params.Message,
      TS: params.TS,
    },
    SendingMessage: params.SendingMessage,
    SendToEventIDi: params.SendToEventIDi,
    GroupsCSV: params.GroupsCSV,
  };
};

export const getDeleteAdminEventMessageBody = (params: SubmitAdminEventMessageParams) => {
  return {
    AppState: {
      ...constructUpdateEventTypesCacheBodyBaseParams(),
    },
    row: {
      ID: params.ID,
      Inactive: params.Inactive,
    },
  };
};

// Cache Two - Event
export interface GetAdminEventCacheBodyParams extends GetAdminEventTypesCacheBodyParams {
  EventIDi?: number;
  EventTS?: number;
  MaxClassTypeIDi?: number;
  GetClasses?: boolean;
  GetGroupEventRegistrations?: boolean;
  HasMeritBadges?: boolean;
}

/**
 * This funciton is used to construct BaseParams for AppState for
 * AdminCacheTwoEvents and below
 */
export const constructGetEventCacheBodyBaseParams = (props: {location: any, routes: any, params: any}): GetAdminEventCacheBodyParams => {
  const state = reduxStoreService().getState();

  const base = constructGetEventTypesCacheBodyBaseParams(props);

  const EventTS = state.adminEvents.cacheTwoEvent.EventsEvent ? state.adminEvents.cacheTwoEvent.EventsEvent.TS : 0;

  const AppStateBase = {
    ...base,
    GetClasses: !state.adminEvents.cacheTwoEvent.EventsEventRow,
    GetGroupEventRegistrations: false,

    EventTS,
  };
  return withMaxClassTypeIDiAndHasMeritBadges(AppStateBase);
};
export const constructUpdateEventCacheBodyBaseParams = (): GetAdminEventCacheBodyParams => {
  const state = reduxStoreService().getState();

  const base = constructUpdateEventTypesCacheBodyBaseParams();

  const EventTS = state.adminEvents.cacheTwoEvent.EventsEvent ? state.adminEvents.cacheTwoEvent.EventsEvent.TS : 0;
  const EventIDi = state.adminEvents.cacheTwoEvent.EventsEvent ? state.adminEvents.cacheTwoEvent.EventsEvent.IDi : 0;

  const AppStateBase = {
    ...base,
    GetClasses: !state.adminEvents.cacheTwoEvent.EventsEventRow,
    GetGroupEventRegistrations: false,

    EventIDi,

    EventTS,
  };

  return withMaxClassTypeIDiAndHasMeritBadges(AppStateBase);
};

/**
 * Support these keys if we need to customize requests for
 * AdminEventCache and below
 */
const AdminEventCacheBodyParamsKeys = {
  EventIDi: true,
  EventTS: true,
  MaxClassTypeIDi: true,
  GetClasses: true,
  GetGroupEventRegistrations: true,
};

export const constructAdminEventCacheAppParams = (params: any) => {
  const result = constructAdminEventsCacheAppParams(params);

  for (let key of Object.keys(params)) {
    if (AdminEventCacheBodyParamsKeys[key]) {
      result[key] = params[key];
    }
  }

  return result;
};

const GET_ADMIN_EVENT_CACHE = '/Events/GetEventCache';
export const getAdminEventCache = () => `${ADMIN_EVENTS_API_URL}${GET_ADMIN_EVENT_CACHE}${getQP()}`;
export const getAdminEventBody = (params: GetAdminEventCacheBodyParams) => {
  const baseParams = {...constructGetEventCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params})};

  const customParams = constructAdminEventCacheAppParams(params);

  return {
    AppState: {
      ...baseParams,
      ...customParams,
    },
  };
};

const RECALCULATE_PRICINNG = '/Events/UpdateEventRecalculatePricing/?ParseJSON=0';
export const getRecalculatePricing = () => `${ADMIN_EVENTS_API_URL}${RECALCULATE_PRICINNG}${getQP(true)}`;
export const getRecalculatePricingBody = () => {
  return {
    AppState: {
      ...constructUpdateEventCacheBodyBaseParams(),
    },
  };
};

const UPDATE_ADMIN_EVENT = '/Events/UpdateEvent/?ParseJSON=0';
export const getUpdateAdminEvent = () => `${ADMIN_EVENTS_API_URL}${UPDATE_ADMIN_EVENT}${getQP(true)}`;
export const getUpdateAdminEventBody = () => {
  return {
    AppState: {
      ...constructUpdateEventCacheBodyBaseParams(),
    },
  };
};

export const getDeleteAdminEventBody = (EventIDi: number, Inactive: boolean) => {
  return {
    AppState: {
      ...constructUpdateEventCacheBodyBaseParams(),
      EventIDi,
    },
    Inactive,
  };
};

const UPDATE_ADMIN_EVENT_IMPORT_PRICING = '/Events/UpdateEvent_ImportPricing/?ParseJSON=0';
export const getUpdateAdminEventImportPricing = () => `${ADMIN_EVENTS_API_URL}${UPDATE_ADMIN_EVENT_IMPORT_PRICING}${getQP(true)}`;

const UPDATE_ADMIN_EVENT_IMPORT_TIME_BLOCKS = '/Events/UpdateTimeBlocks_Import/?ParseJSON=0';
export const getUpdateAdminEventImportTimeBlocks = () => `${ADMIN_EVENTS_API_URL}${UPDATE_ADMIN_EVENT_IMPORT_TIME_BLOCKS}${getQP(true)}`;

export const getUpdateAdminEventImportBody = (FromIDi: number) => {
  return {
    AppState: {
      ...constructUpdateEventCacheBodyBaseParams(),
    },
    FromIDi,
  };
};

const UPDATE_ADMIN_EVENT_TIME_BLOCKS = '/Events/UpdateTimeBlocks/?ParseJSON=0';
export const getUpdateAdminEventTimeBlocks = () => `${ADMIN_EVENTS_API_URL}${UPDATE_ADMIN_EVENT_TIME_BLOCKS}${getQP(true)}`;
export const getUpdateAdminEventTimeBlocksBody = () => {
  return {
    AppState: {
      ...constructUpdateEventCacheBodyBaseParams(),
    },
  };
};


const GET_EVENTS_LIST = '/Events/GetEventTypeEvents/';
export const getAdminEventsList = () => `${ADMIN_EVENTS_API_URL}${GET_EVENTS_LIST}${getQP()}`;
export const getAdminEventsListBody = (params: {EventTypeID: number}) => {
  return {
    AppState: {
      EventTypeID: params.EventTypeID,
    },
  };
};

const GET_SOURCE_CLASSES_LIST = '/Classes/GetSourceClasses/';
export const getSourceClassesList = () => `${ADMIN_EVENTS_API_URL}${GET_SOURCE_CLASSES_LIST}${getQP()}`;
export const getSourceClassesListBody = (params: {SourceEventTypeID: number, SourceEventIDi: number}) => {
  const rootState = reduxStoreService().getState();
  const AppStateBase = {
    EventTypeID: rootState.adminEvents.cacheOne.EventsEventType ? rootState.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID : 0,
    EventIDi: rootState.adminEvents.cacheTwoEvent.EventsEvent ? rootState.adminEvents.cacheTwoEvent.EventsEvent.IDi : 0,
  };
  return {
    AppState: withMaxClassTypeIDiAndHasMeritBadges(AppStateBase),
    SourceEventTypeID: params.SourceEventTypeID,
    SourceEventIDi: params.SourceEventIDi,
  };
};

// Cache Three - Class Requirements
export const GET_CLASS_REQUIREMENT = '/Classes/GetRequirementsCompleted/';
export const getClassRequirement = () => `${ADMIN_EVENTS_API_URL}${GET_CLASS_REQUIREMENT}${getQP()}`;
export const getClassRequirementBody = (params: {EventTypeID?: number, EventIDi?: number, IDi: number}) => {
  const rootState = reduxStoreService().getState();
  return {
    EventTypeID: rootState.adminEvents.cacheOne.EventsEventType ? rootState.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID : params.EventTypeID,
    EventIDi: rootState.adminEvents.cacheTwoEvent.EventsEvent ? rootState.adminEvents.cacheTwoEvent.EventsEvent.IDi : 0,
    IDi: params.IDi,
  };
};

export const UPDATE_CLASS_REQUIREMENTS = '/Classes/UpdateRequirementsCompleted/?ParseJSON=0';
export const getUpdateClassRequirements = () => `${ADMIN_EVENTS_API_URL}${UPDATE_CLASS_REQUIREMENTS}${getQP(true)}`;
export const getUpdateClassRequirementsBody = () => {
  const state = reduxStoreService().getState();
  const cacheThreeClassRequirements = state.adminEvents.cacheThreeClassRequirements;

  const EventTypeID = state.adminEvents.cacheOne.EventsEventType ? state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID as number : 0;
  const EventIDi = state.adminEvents.cacheTwoEvent.EventsEvent ? state.adminEvents.cacheTwoEvent.EventsEvent.IDi : 0;
  const ClassIDi = cacheThreeClassRequirements.EventsEventClassRequirements ? cacheThreeClassRequirements.EventsEventClassRequirements.ClassIDi : 0;

  return {
    EventTypeID,
    EventIDi,
    IDi: ClassIDi,
    VersionYear: cacheThreeClassRequirements?.EventsEventClassRequirements?.mbID ? cacheThreeClassRequirements?.EventsEventClassRequirements?.VersionYear : undefined,
  };
};


// Cache Three - Class Type
const AdminEventCacheThreeBodyParamsKeys = {
  GetGroupEventRegistrations: true,
};

export const constructAdminEventCacheThreeAppParams = (params: any) => {
  const result = constructAdminEventCacheAppParams(params);

  for (let key of Object.keys(params)) {
    if (AdminEventCacheThreeBodyParamsKeys[key]) {
      result[key] = params[key];
    }
  }

  return result;
};


interface SubmitAdminEventClassTypeParams {
  IDi?: number;
  Name?: string;
  mbID?: number | null;
  FeaturedImage?: AdminEventsImage;
  Description?: string;
  Requirements?: string;
  RequirementsArray?: number[];
  TS?: number;
  RemoveImage?: boolean;
  Inactive?: boolean;
}
const UPDATE_ADMIN_EVENT_CLASS_TYPE = '/ClassTypes/UpdateClassType/';
export const getUpdateAdminEventClassType = () => `${ADMIN_EVENTS_API_URL}${UPDATE_ADMIN_EVENT_CLASS_TYPE}${getQP()}`;
export const getUpdateAdminEventClassTypeBody = (params: SubmitAdminEventClassTypeParams) => {
  return {
    AppState: {
      ...constructUpdateEventCacheBodyBaseParams(),
    },
    row: {
      IDi: params.IDi,
      Name: params.Name,
      mbID: params.mbID,
      Description: params.Description,
      Requirements: params.Requirements,
      TS: params.TS,
      RemoveImage: params.RemoveImage,
      RequirementsArray: params.RequirementsArray,
    },
  };
};

export const getDeleteAdminEventClassTypeBody = (params: SubmitAdminEventClassTypeParams) => {
  return {
    AppState: {
      ...constructUpdateEventCacheBodyBaseParams(),
    },
    row: {
      IDi: params.IDi,
      Inactive: params.Inactive,
    },
  };
};

export const constructGetEventClassTypeCacheBodyBaseParams = (props: {location: any, routes: any, params: any}): GetAdminEventCacheBodyParams => {
  const base = constructGetEventCacheBodyBaseParams(props);

  return {
    ...base,
  };
};
export interface GetAdminEventClassTypeCacheBodyParams extends GetAdminEventCacheBodyParams {
  ClassTypeIDi: number;
}
const GET_ADMIN_EVENT_CLASS_TYPE_CACHE = '/ClassTypes/GetClassType/';
export const getAdminEventClassTypeCache = () => `${ADMIN_EVENTS_API_URL}${GET_ADMIN_EVENT_CLASS_TYPE_CACHE}${getQP()}`;
export const getAdminEventClassTypeBody = (params: GetAdminEventClassTypeCacheBodyParams) => {
  const baseParams = {...constructGetEventClassTypeCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params})};

  const customParams = constructAdminEventCacheThreeAppParams(params);

  return {
    AppState: {
      ...baseParams,
      ...customParams,
      EventIDi: params.EventIDi,
      
    },
    IDi: params.ClassTypeIDi,
  };
};

const GET_ADMIN_EVENT_CLASS_TYPE_ALL_REQUIREMENTS = '/ClassTypes/GetClassType_AllMBRequirements/';
export const getAdminEventClassTypeAllRequirements = () => `${ADMIN_EVENTS_API_URL}${GET_ADMIN_EVENT_CLASS_TYPE_ALL_REQUIREMENTS}${getQP()}`;


// Cache Three - Class
export interface GetAdminEventClasseCacheBodyParams extends GetAdminEventCacheBodyParams {
  ClassIDi: number;
}
const GET_ADMIN_EVENT_CLASS_CACHE = '/Classes/GetClass/';
export const getAdminEventClassCache = () => `${ADMIN_EVENTS_API_URL}${GET_ADMIN_EVENT_CLASS_CACHE}${getQP()}`;
export const getAdminEventClassBody = (params: GetAdminEventClasseCacheBodyParams) => {
  const baseParams = {...constructGetEventClassTypeCacheBodyBaseParams({location: params.location, routes: params.routes, params: params.params})};

  const customParams = constructAdminEventCacheThreeAppParams(params);

  return {
    AppState: {
      ...baseParams,
      ...customParams,
      EventIDi: params.EventIDi,
      
    },
    IDi: params.ClassIDi,
  };
};

const UPDATE_ADMIN_EVENT_CLASS_IMPORT = '/Classes/UpdateClasses_Import/';
export const getUpdateAdminEventImportClass = () => `${ADMIN_EVENTS_API_URL}${UPDATE_ADMIN_EVENT_CLASS_IMPORT}${getQP()}`;
export const getUpdateAdminEventImportClassBody = (params: {SourceEventTypeID: number, SourceEventIDi: number, ClassesCSV: string}) => {
  return {
    AppState: {
      ...constructUpdateEventCacheBodyBaseParams(),
    },
    SourceEventTypeID: params.SourceEventTypeID,
    SourceEventIDi: params.SourceEventIDi,
    ClassesCSV: params.ClassesCSV,
  };
};
interface SubmitAdminEventClassParams {
  IDi?: number;
  TS?: number;
  Inactive?: boolean;
}
const UPDATE_ADMIN_EVENT_CLASS = '/Classes/UpdateClass/?ParseJSON=0';
export const getUpdateAdminEventClass = () => `${ADMIN_EVENTS_API_URL}${UPDATE_ADMIN_EVENT_CLASS}${getQP(true)}`;
export const getUpdateAdminEventClassBody = (params: SubmitAdminEventClassParams) => {
  return {
    AppState: {
      ...constructUpdateEventCacheBodyBaseParams(),
    },
    row: {
      IDi: params.IDi,
      TS: params.TS,
    },
  };
};

export const getDeleteAdminEventClassBody = (params: SubmitAdminEventClassParams) => {
  return {
    AppState: {
      ...constructUpdateEventCacheBodyBaseParams(),
    },
    row: {
      IDi: params.IDi,
      Inactive: params.Inactive,
    },
  };
};

interface AdminEventsRouteParams {
  eventTypeId: number | string;
  eventTypeName: string;
  productId?: number;
  productName?: string;
  messageId?: number;
  messageName?: string;
}

interface AdminEventsEventRouteParams {
  eventTypeId: number | string;
  eventId: number;
  eventName: string;
  classTypeId?: number;
  classTypeName?: string;
  classId?: number;
  className?: string;
}

// param construction
export const constructAdminEventsUrlParams = (props: {params: any}, cacheOne: AdminEventsCacheOneState, eventTypeId?: number): AdminEventsRouteParams => {
  return {
    eventTypeId: eventTypeId ? eventTypeId : getEventTypeId(props),
    eventTypeName: cacheOne.EventsEventType && cacheOne.EventsEventType.EventTypeRow ? cacheOne.EventsEventType.EventTypeRow.Name : '',
  };
};

export const constructAdminEventsProductUrlParams = (props: {params: any}, cacheOne: AdminEventsCacheOneState, product?: AdminEventsProduct): AdminEventsRouteParams => {
  return {
    ...constructAdminEventsUrlParams(props, cacheOne),
    productId: product ? product.IDi : undefined,
    productName: product ? product.Name : undefined,
  };
};

export const constructAdminEventsMessageUrlParams = (props: {params: any}, cacheOne: AdminEventsCacheOneState, message?: AdminEventsEmailMessage): AdminEventsRouteParams => {
  return {
    ...constructAdminEventsUrlParams(props, cacheOne),
    messageId: !message ? undefined : message.ID,
    messageName: !message ? undefined : message.Name,
  };
};

export const getEventsHomeRootUrl = (params: AdminEventsRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${spaceTo_(params.eventTypeName)}/${ADMIN_EVENTS_PATH.EVENTS}${getAccountQP()}`;
};

export const getProductsHomeRootUrl = (params: AdminEventsRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${spaceTo_(params.eventTypeName)}/${ADMIN_EVENTS_PATH.PRODUCTS}${getAccountQP()}`;
};

export const getNewProductUrl = (params: AdminEventsRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${spaceTo_(params.eventTypeName)}/${ADMIN_EVENTS_PATH.PRODUCTS}/${ADMIN_EVENT_PRODUCT.NEW}${getAccountQP()}`;
};

export const getEditProductUrl = (params: AdminEventsRouteParams) => {
  if (params.productName) {
    return `${URLS.ADMIN_EVENT_PRODUCT}/${params.eventTypeId}/${params.productId}/${spaceTo_(params.productName)}${getAccountQP()}`;
  }

  return '';
};

export const getAdminEventsReportsHomeRootUrl = (params: AdminEventsRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${spaceTo_(params.eventTypeName)}/${ADMIN_EVENTS_PATH.REPORTS}${getAccountQP()}`;
};

export const getAdminEventsYearOverviewRootUrl = (params: AdminEventsRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${spaceTo_(params.eventTypeName)}/${ADMIN_EVENTS_PATH.REPORTS}/${ADMIN_EVENTS_PATH.YEAR_OVERVIEW}${getAccountQP()}`;
};

export const getAdminEventsMessageCenterHomeRootUrl = (params: AdminEventsRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${spaceTo_(params.eventTypeName)}/${ADMIN_EVENTS_PATH.MESSAGE_CENTER}${getAccountQP()}`;
};

export const getNewMessagetUrl = (params: AdminEventsRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${spaceTo_(params.eventTypeName)}/${ADMIN_EVENTS_PATH.MESSAGE_CENTER}/${ADMIN_EVENT_MESSAGE.NEW}${getAccountQP()}`;
};

export const getEditMessageUrl = (params: AdminEventsRouteParams) => {
  if (params.messageName) {
    return `${URLS.ADMIN_EVENT_MESSAGE}/${params.eventTypeId}/${params.messageId}/${spaceTo_(params.messageName)}${getAccountQP()}`;
  }

  return '';
};

export const getAdminEventsSettingsHomeRootUrl = (params: AdminEventsRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${spaceTo_(params.eventTypeName)}/${ADMIN_EVENTS_PATH.SETTINGS}${getAccountQP()}`;
};

export const constructAdminEventsEventUrlParams = (props: {params: any}, eventId: number, eventName: string, classTypeId?: number, classTypeName?: string, classId?: number, className?: string): AdminEventsEventRouteParams => {
  return {
    eventTypeId: getEventTypeId(props),
    eventId,
    eventName,
    classTypeId,
    classTypeName,
    classId,
    className,
  };
};

export const getAdminEventsEventDashboardUrl = (params: AdminEventsEventRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${params.eventId}/${spaceTo_(params.eventName)}/${ADMIN_EVENTS_EVENT_PATH.DASHBOARD}${getAccountQP()}`;
};

export const getAdminEventsEventClassesUrl = (params: AdminEventsEventRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${params.eventId}/${spaceTo_(params.eventName)}/${ADMIN_EVENTS_EVENT_PATH.CLASS_LIST}${getAccountQP()}`;
};

export const getNewClassUrl = (params: AdminEventsEventRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${params.eventId}/${spaceTo_(params.eventName)}/${ADMIN_EVENTS_EVENT_PATH.CLASS_LIST}/${ADMIN_EVENT_CLASS.NEW}${getAccountQP()}`;
};

export const getEditClassUrl = (params: AdminEventsEventRouteParams) => {
  if (!params.className) return '';
  return `${URLS.ADMIN_EVENT_CLASS}/${params.eventTypeId}/${params.eventId}/${params.classId}/${spaceTo_(params.className)}${getAccountQP()}`;
};

export const getAdminEventsEventClassTypesUrl = (params: AdminEventsEventRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${params.eventId}/${spaceTo_(params.eventName)}/${ADMIN_EVENTS_EVENT_PATH.CLASS_TYPES}${getAccountQP()}`;
};

export const getNewClassTypeUrl = (params: AdminEventsEventRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${params.eventId}/${spaceTo_(params.eventName)}/${ADMIN_EVENTS_EVENT_PATH.CLASS_TYPES}/${ADMIN_EVENT_CLASS_TYPE.NEW}${getAccountQP()}`;
};

export const getEditClassTypeUrl = (params: AdminEventsEventRouteParams) => {
  if (!params.classTypeName) return '';
  return `${URLS.ADMIN_EVENT_CLASS_TYPE}/${params.eventTypeId}/${params.eventId}/${params.classTypeId}/${spaceTo_(params.classTypeName)}${getAccountQP()}`;
};

export const getAdminEventsEventTimeBlocksUrl = (params: AdminEventsEventRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${params.eventId}/${spaceTo_(params.eventName)}/${ADMIN_EVENTS_EVENT_PATH.TIME_BLOCKS}${getAccountQP()}`;
};

export const getAdminEventsEventSettingsUrl = (params: AdminEventsEventRouteParams) => {
  return `${URLS.ADMIN_EVENTS}/${params.eventTypeId}/${params.eventId}/${spaceTo_(params.eventName)}/${ADMIN_EVENTS_EVENT_PATH.SETTINGS}${getAccountQP()}`;
};