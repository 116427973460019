import * as M from "../../../../../../constants/messages/createAccount";
import * as GenericM from "../../../../../../constants/messages/generic";

let quantityValues: any[] = [];
for (let i = 1; i < 101; i++) {
  quantityValues.push({
    val: i,
    label: i
  });
}

export const QuantityVRules = {
    key: 'Quantity',
    options: {
      values: () => quantityValues,
      valueKey: (v) => v.val,
      labelKey: 'label'
    },
    defaultValue: () => {
      return 1;
    },
    validatejs: {
    Quantity: {
      presence: {message: '^' + GenericM.REQUIRED}
    }
  }
};

export const AmountVRules = {
  key: 'Amount',
  decimalOnly: true,
  allowNegative: () => false,
  validatejs: {
    Amount: {
      presence: {message: '^' + GenericM.REQUIRED},
      numericality: {
        notValid: `^${GenericM.INVALID}`
      }
    }
  }
};

export const ItemPriceVRules = {
  key: 'ItemPrice',
  decimalOnly: true,
  allowNegative: () => false,
  validatejs: {
    ItemPrice: {
      presence: {message: GenericM.S_REQUIRED("Item price")},
      numericality: {
        notValid: GenericM.S_INVALID("Item price"),
      }
    }
  }
};
