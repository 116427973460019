import * as React from 'react';
import '../../../styles/elements/icon/index.scss';
import { default as attributes, MarginProps } from '../../../styles';

export const namespace = 'elements--icon';

export interface IconProps extends MarginProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  icon: React.ReactType;
  green?: boolean;
  orange?: boolean;
  red?: boolean;
  color?: 'green';
  size?: number;
  iconHeight?: number;
  iconWidth?: number;
}

const Icon: React.SFC<IconProps> = (
  { iconHeight, iconWidth, green, red, orange, size, color, icon: Icon, style, className: inheritedClassName, ...props }: IconProps
): React.ReactElement<IconProps> => {
  let className = attributes(namespace, inheritedClassName, props);
  if (green || color === 'green') className += ' green';
  if (red) className += ' red';
  if (orange) className += ' orange';

  const iconSize: string = `${size ? Math.floor(size / 5 * 3) : 24}px`;
  const styles: React.CSSProperties = { ...style };
  const iconStyle: React.CSSProperties = {};
  if (size) {
    styles.width = `${size}px`;
    styles.height = `${size}px`;
    iconStyle.width = iconSize;
    iconStyle.height = iconSize;
  }
  if (iconWidth || iconHeight) {
    iconStyle.width = `${iconWidth}px`;
    iconStyle.height = `${iconHeight}px`;
  }

  return (
    <i className={className} style={styles}>
      {<Icon className={`${namespace}--image`} width={iconStyle.width} height={iconStyle.height} style={iconStyle}/>}
    </i>
  );
};

export default Icon;
