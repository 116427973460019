
import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { makeFilteredGroupsSelector } from '../../../../../../store/Admin/Modals/Accounts';
import { ApplicationState } from '../../../../../../store';
import { bindActionCreators } from 'redux';

import { actionCreators } from "../../../../../../store/Admin/Modals/Accounts/actions";
import {actionCreators as appActionCreators} from '../../../../../../store/App/actions';
import {Group} from '../../../../../../models/api/options';
import { actionCreators as rollbackActionCreators, SaveState } from '../../../../../../store/Rollback/actions';
import { SimpleList, SimpleListElement, EmptyMessage } from '../../../../../../components/Elements';
import { URLS } from '../../../../../../constants/urls';
import { PeopleContactIcon } from '../../../../../../components/Icons';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { EmptyMessageType } from '../../../../../Elements/EmptyMessage';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { navPush } from '../../../../../../utils';
import { GetGroupCache } from '../../../../../../store/CacheOne/actions';
import { reduxStoreService } from '../../../../../../store/service';
import { getGroupIDFromUrl } from '../../../../../../utils/helpers/endUserPageHelper';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';

interface Props {
  scrollToTop: () => void;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;


@(withRouter as any)
class GroupsContent extends React.PureComponent<Props, {}> {
  public props: ConnectedProps & Props;
  
  public componentDidMount() {
    if (shouldBlockActions()) return;
    const { actions: { getGroups, init }, adminAccounts: { ActiveForm: { ShowInactive }}} = this.props;
    const groups = this.props.filteredGroups;
    
    init(!groups);

    if (!groups) {
      getGroups(ShowInactive);
    }
  }

  onSearchBack = () => {
    // @todo-minor-performance: just use 1 action
    if (this.props.adminAccounts.filterString) {
      this.props.scrollToTop();
    }
    this.props.actions.showFilterTabs(true);
    this.props.actions.filterAccounts('');
  };

  onRestoreElement = (index: number, key: string | number, account: Group) => {
    this.props.actions.restoreGroup(account.IDi);
  };

  onClickGroupElement = (index: number, key: string | number, account: Group) => {
    const {router, location} = this.props;
    reduxStoreService().dispatch(new SaveState());
    this.onSearchBack();
    navPush(
      router,
      `${URLS.HOME}`,
      account.IDi,
    );
    if (account.IDi === getGroupIDFromUrl(location)) {
      reduxStoreService().dispatch(GetGroupCache.request({
        GroupIDi: account.IDi,
        GroupTS: 0,
        GetOptions: false,
        GetGroupData: true,
      }));
    }
  };

  itemRenderer = (index) => {
    const {filteredGroups} = this.props;

    if (!filteredGroups) return null;
    const group: Group = filteredGroups[index];
    return (<SimpleListElement
      key={group.IDi}
      index={index}
      label={group.Name}
      onClick={this.onClickGroupElement}
      onRestore={this.onRestoreElement}
      deleted={group.Inactive}
      itemValue={group}
      canRestore={this.props.apiSaving === 0}
      id={group.IDi}
    />);
  };

  render() {
    const {filteredGroups, groups} = this.props;

    if (!groups) return null;

    if (!filteredGroups || filteredGroups.length === 0) {
      return (
        <EmptyMessage
          icon={PeopleContactIcon}
          type={EmptyMessageType.PAGE_MARGIN}
          iconHeight='96px'
          iconWidth='96px'
          description={`${ENTITY_NOT_FOUND('groups')}`}
          admin
        />
      );
    }
    return (
      <SimpleList itemRenderer={this.itemRenderer} totalLength={filteredGroups ? filteredGroups.length : 0}/>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredGroupsSelector = makeFilteredGroupsSelector();
  return {
    adminAccounts: state.adminAccounts,
    filteredGroups: filteredGroupsSelector(state),
    groups: state.cacheZero.options?.Groups,
    cacheZero: state.cacheZero,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
  };
};

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
  ...rollbackActionCreators}, dispatch) });

const ConnectedGroupsContent = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(GroupsContent);

export default ConnectedGroupsContent;
