import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/elements/class-list/item.scss';
import { CartIcon } from '../../Icons';
import Text from "../Text";
import Vignette from "../Vignette";
import Icon from "../Icon";
import { Button } from 'react-mdl';
import {BaseEndUserClass, RegisteredClass} from "../../../models/class";
import {getImagePath} from "../../../utils";
import {getClassImage, isImageValid} from "../../../utils/imageHelper";
import { isMobile } from '../../../utils/isMobile';

export const namespace = (): string => `${ns()}--item`;

export interface Props {
  className?: string;
  onClick: () => void;
  clazz: BaseEndUserClass | RegisteredClass;
}

class Item extends React.PureComponent<Props, {}> {
  public props: Props;

  public render() {
    const {onClick, clazz} = this.props;
    const {Name} = clazz;

    const InCart = "InCart" in clazz ? clazz.InCart : undefined;
    let label = Name;

    const image = getClassImage(clazz);

    if (!image) return null;
    return (
      <li className={namespace()}>
        <Button
          ripple={!isMobile}
          className={`${namespace()}--button`}
          onClick={onClick}
        >
          <Vignette
            className={`${namespace()}--image`}
            borderRadius='50%'
            width={28}
            height={28}
            name={image.initial}
            color={image.color}
            image={isImageValid(image) ? getImagePath(image, "th150px_auto") : undefined}
          />
          <Text className={`${namespace()}--text`} marginLeft={8} size={16} wrap={false}>{label}</Text>
          {InCart === 1 ? <div className={`${namespace()}--cart`}><Icon size={28} green icon={CartIcon} className={`${namespace()}--cart--icon`}/></div> : null}
        </Button>
      </li>
    );
  }
}

export default Item;
