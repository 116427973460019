import {
  createApiSubmitActions,
  createValidateActions,
  createApiValidateActions,
  createUpdateValueMethod,
  createSimpleUpdateValueMethod,
  innerApiSubmitFormMethod,
  getCacheLevelExtra,
} from "../../../Validation/actionCreator";
import { ActionCreator, ApplicationState } from '../../..';
import { MessagesSettingsActiveForm } from ".";
import { getUpdateSiteSettingsMessagesBody } from "../../../../constants/urls";
import { ADMIN_CMS_MODULE_SUFFIX, UPDATE_ADMIN_CMS_SETTINGS_FORM_SUFFIX } from "../../CacheOne/actions";

export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__MESSAGES_SETTINGS' + UPDATE_ADMIN_CMS_SETTINGS_FORM_SUFFIX;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const MessagesSettingsSubmitActions = createApiSubmitActions(SUFFIX);

const updateMessagesSettingsFormCreator = (rootState: ApplicationState, form: MessagesSettingsActiveForm) => {
  const body = getUpdateSiteSettingsMessagesBody();

  const Messages: any[] = [];


  if (rootState && rootState.adminCMSSite && rootState.adminCMSSite.cacheOne && rootState.adminCMSSite.cacheOne.CMSSiteMessages) {
    rootState.adminCMSSite.cacheOne.CMSSiteMessages.forEach((m) => {
      const EmbedName = m.EmbedName;

      Messages.push({
        EmbedName,
        Title: form[`${EmbedName}Title`],
        Description: form[`${EmbedName}Description`],
        ButtonLabel: form[`${EmbedName}ButtonLabel`],
        ButtonURL: form[`${EmbedName}ButtonURL`],
        IsNewWindow: form[`${EmbedName}IsNewWindow`],
      });

      if (m.EmbedName === 'homepage4') {
        Messages.push({
          EmbedName: 'homepage4_previous',
          Title: m.Title,
          Description: m.Description,
          ButtonLabel: m.ButtonLabel,
          ButtonURL: m.ButtonURL,
          IsNewWindow: m.IsNewWindow,
        });
      }
    });
  }

  body.Messages = Messages;
  
  return body;
};

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.adminCMSSite.settings.messages, true, undefined, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  apiSubmitMessagesSettings: (): ActionCreator => (dispatch, getState) => {
    const state = getState();
    // form validation (not including file)
    const valid = innerApiSubmitFormMethod(
      dispatch,
      MessagesSettingsSubmitActions,
      (s: ApplicationState) => s.adminCMSSite.settings.messages,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
    );

    if (valid) {
      dispatch(MessagesSettingsSubmitActions.request(
        updateMessagesSettingsFormCreator(getState(), state.adminCMSSite.settings.messages.ActiveForm),
        getCacheLevelExtra(
          false,
          true,
          // We are updating cache 1
          1,
        )
      ));
    }
  }
};