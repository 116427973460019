import { AdminEventsCacheOneContext, AdminEventsCacheTwoEventContext } from "@tentaroo/shared";

import { ClearAdminEventsCacheBelowOne, ClearAllAdminEventsCache } from "../../store/AdminEvents/CacheOne/actions";
import CacheManager from "./cacheManager";
import { AdminEventsEmailMessage, AdminEventsProduct } from "../../models/api/adminEventsCacheOne";
import { AdminEventsCacheTwoMessageState } from "../../store/AdminEvents/CacheTwoMessage";
import { safelySetEmptyCart } from "../cacheLoaders/cacheLoaderHelpers";
import { AdminEventsCacheTwoProductState } from "../../store/AdminEvents/CacheTwoProduct";
import { AdminEventsCacheThreeClassState } from "../../store/AdminEvents/CacheThreeClass";
import { AdminEventsEventClass } from "../../models/api/adminEventsCacheThreeClass";
import { AdminEventsCacheThreeClassTypeState } from "../../store/AdminEvents/CacheThreeClassType";
import { AdminEventsCacheThreeClassType } from "../../models/api/adminEventsCacheThreeClassType";
import { AdminEventsCacheTwoEventState } from "../../store/AdminEvents/CacheTwoEvent";
import { AdminEvent } from "../../models/api/adminEventsCacheTwoEvent";
import { AdminEventsCacheOneState } from "../../store/AdminEvents/CacheOne";
import { ClearAdminEventsCacheTwoMessage } from "../../store/AdminEvents/CacheTwoMessage/actions";
import { ModalTypes, isModalOpened } from "../modalHelper";
import { ClearAdminEventsCacheBelowTwoEvent } from "../../store/AdminEvents/CacheTwoEvent/actions";
import { ClearAdminEventsCacheThreeClassType } from "../../store/AdminEvents/CacheThreeClassType/actions";
import EndUserCacheManager from "./endUserCacheManager";
import { RouteComponentProps } from "react-router";
import { getAdminEventClassIdFromPath, getAdminEventClassTypeIdFromPath, getAdminEventMessageIdFromPath, getAdminEventProductIdFromPath, getEventTypeId, IAdminClassRouterParams, IAdminClassTypeRouterParams, IAdminEventRouterParams, IAdminEventsRouterParams, IAdminMessageRouterParams, IAdminProductRouterParams } from "../helpers/adminEventsPageHelper";
import { ensureAdminEventsCacheTwoMessage } from "../cacheLoaders/adminEventsCacheTwoMessageLoader";
import { ensureAdminEventsCacheTwoProduct } from "../cacheLoaders/adminEventsCacheTwoProductLoader";
import { ensureAdminEventsCacheTwoEvent } from "../cacheLoaders/adminEventsCacheTwoEventLoader";
import { ensureAdminEventsCacheOne } from "../cacheLoaders/adminEventsCacheOneLoader";
import { ensureAdminEventsCacheThreeClass } from "../cacheLoaders/adminEventsCacheThreeClassLoader";
import { ensureAdminEventsCacheThreeClassType } from "../cacheLoaders/adminEventsCacheThreeClassTypeLoader";
import { AdminEventsCacheThreeClassRequirementsState } from "../../store/AdminEvents/CacheThreeClassRequirements";
import { ensureAdminEventsCacheThreeClassRequirements } from "../cacheLoaders/adminEventsCacheThreeClassRequirementsLoader";
import { getDataIdFromUrlOrModalProp } from "../dataHelper";
import {getGroupCache} from "../../constants/urls";
import {getCacheOneEmptyCartBody} from "../cacheLoaders/cacheLoaders";
import { reduxStoreService } from "../../store/service";
import { AdminEventsHomeReset } from "../../store/AdminEvents/Events/Home/actions";
import { ProductsHomeReset } from "../../store/AdminEvents/Products/Home/actions";
import { MessageCenterHomeReset } from "../../store/AdminEvents/MessageCenter/Home/actions";
import { AdminEventsEventClassesTabReset } from "../../store/AdminEvents/Events/Event/Classes/actions";
import { AdminEventsEventClassTypesTabReset } from "../../store/AdminEvents/Events/Event/ClassTypes/actions";
import { LoadAdminEventCacheThreeClassParams, LoadAdminEventCacheThreeClassRequirementParams, LoadAdminEventCacheThreeClassTypeParams, LoadAdminEventCacheTwoEventParams, LoadAdminEventCacheTwoMessageParams, LoadAdminEventCacheTwoProductParams, LoadAdminEventsCacheOneParams } from "../cacheLoaders/helpers/models";
import {isAdminEventsCacheOnePopulated, isAdminEventsCacheThreeClassPopulated, isAdminEventsCacheThreeClassRequirementsPopulated, isAdminEventsCacheThreeClassTypePopulated, isAdminEventsCacheTwoEventPopulated, isAdminEventsCacheTwoMessagePopulated, isAdminEventsCacheTwoProductPopulated} from "../cachePopulatedCheckers/adminEvents";

export default class AdminEventsCacheManager extends CacheManager {
  private static _instance: AdminEventsCacheManager;

  public static getInstance(): AdminEventsCacheManager {
    if (!this._instance) {
      this._instance = new AdminEventsCacheManager();
    }

    return this._instance;
  }

  constructor() {
    super();
  }

  // Cache One
  public initCacheOneListPage(
    context: AdminEventsCacheOneContext,
  ) {
    if (context === AdminEventsCacheOneContext.EVENTS_LIST) {
      reduxStoreService().dispatch(new AdminEventsHomeReset());
    } else if (context === AdminEventsCacheOneContext.PRODUCTS_LIST) {
      reduxStoreService().dispatch(new ProductsHomeReset());
    } else if (context === AdminEventsCacheOneContext.MESSAGES_LIST) {
      reduxStoreService().dispatch(new MessageCenterHomeReset());
    }
  }

  private isPopulatedAdminEventsCacheOneInvalid(cacheOne: AdminEventsCacheOneState, eventTypeId: number) {
    if (this._isPopulatedCacheInvalid<AdminEventsCacheOneState>(
      cacheOne,
      (cacheOne) => cacheOne.EventsEventType ? cacheOne.EventsEventType.EventTypeRow.ID : 0,
      eventTypeId,
    )) {
      reduxStoreService().dispatch(new ClearAllAdminEventsCache());
      return true;
    }
  
    return false;
  };

  public loadAdminEventsCacheOne(
    params: LoadAdminEventsCacheOneParams,
  ) {
    const {props, isStateNavigated} = params;
    // Only SetEmptyCart when first navigating to the any of the cache one pages
    // but not due to a rollback
    if (isStateNavigated) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }

    if (this.cacheOneNeedsReload(
      props,
      getEventTypeId(props),
    ) || isStateNavigated) {
      return ensureAdminEventsCacheOne({
        ...params,
        cacheManager: this,
      });
    }

    return false;
  };

  private cacheOneNeedsReload(
    props: RouteComponentProps<IAdminEventsRouterParams, {}>,
    eventTypeId: number,
  ) {
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const cacheOne = reduxStoreService().getState().adminEvents.cacheOne;
    const isCacheInvalid = this.isPopulatedAdminEventsCacheOneInvalid(cacheOne, eventTypeId);
    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      !isAdminEventsCacheOnePopulated()
    );
  }

  // Message
  private isPopulatedMessageInvalid(
    cacheTwoMessage: AdminEventsCacheTwoMessageState,
    eventTypeId: number,
    messageId: number,
  ) {
    const cacheOne = reduxStoreService().getState().adminEvents.cacheOne;

    if (this.isPopulatedAdminEventsCacheOneInvalid(cacheOne, eventTypeId)) return true;

    const isInvalid = this._isPopulatedCacheInvalid<AdminEventsEmailMessage>(
      cacheTwoMessage.Message,
      (message) => message.ID,
      messageId,
    );

    if (isInvalid) {
      const isMessageModalOpened = isModalOpened(ModalTypes.MESSAGE_FORM);
      if (isMessageModalOpened) reduxStoreService().dispatch(new ClearAdminEventsCacheTwoMessage());
      else reduxStoreService().dispatch(new ClearAdminEventsCacheBelowOne());
    }

    return isInvalid;
  };

  public loadCacheTwoMessage(
    params: LoadAdminEventCacheTwoMessageParams,
  ) {
    const {props, isStateNavigated, messageIdFromModalProps, isEdit} = params;
    const rootState = reduxStoreService().getState();
    const messageId = getDataIdFromUrlOrModalProp<IAdminMessageRouterParams>(
      getAdminEventMessageIdFromPath,
      props.params,
      messageIdFromModalProps,
    );
    // Only SetEmptyCart when first navigating to message form page but not
    // due to a rollback
    if (isStateNavigated && !isModalOpened(ModalTypes.MESSAGE_FORM)) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }
    if (this.adminEventsCacheTwoMessageNeedsReload(
      rootState.adminEvents.cacheTwoMessage,
      getEventTypeId(props),
      messageId,
      props,
      isEdit,
    ) || isStateNavigated) {
      return ensureAdminEventsCacheTwoMessage({
        ...params,
        cacheManager: this,
        messageId,
      });
    }

    return false;
  };

  private adminEventsCacheTwoMessageNeedsReload(
    cacheTwoMessage: AdminEventsCacheTwoMessageState,
    eventTypeId: number,
    messageId: number,
    props: RouteComponentProps<IAdminMessageRouterParams, {}>,
    isEdit: boolean,
  ) {
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const isCacheInvalid = this.isPopulatedMessageInvalid(cacheTwoMessage, eventTypeId, messageId);

    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      (isEdit && !isAdminEventsCacheTwoMessagePopulated(cacheTwoMessage))
    );
  }

  // Product
  private isPopulatedProductInvalid(
    cacheTwoProduct: AdminEventsCacheTwoProductState,
    eventTypeId: number,
    productId: number,
  ) {
    const cacheOne = reduxStoreService().getState().adminEvents.cacheOne;

    if (this.isPopulatedAdminEventsCacheOneInvalid(cacheOne, eventTypeId)) return true;

    const isInvalid = this._isPopulatedCacheInvalid<AdminEventsProduct>(
      cacheTwoProduct.Product,
      (product) => product.IDi,
      productId,
    );

    if (isInvalid) {
      reduxStoreService().dispatch(new ClearAdminEventsCacheBelowOne());
    }

    return isInvalid;
  };

  public loadCacheTwoProduct(
    params: LoadAdminEventCacheTwoProductParams
  ) {
    const {props, isStateNavigated, isEdit} = params;
    const rootState = reduxStoreService().getState();
    const productId = getAdminEventProductIdFromPath(props.params);

    // Only SetEmptyCart when first navigating to product form page but not
    // due to a rollback
    if (isStateNavigated) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }
    if (this.adminEventsCacheTwoProductNeedsReload(
      rootState.adminEvents.cacheTwoProduct,
      getEventTypeId(props),
      productId,
      props,
      isEdit,
    ) || isStateNavigated) {
      return ensureAdminEventsCacheTwoProduct({
        ...params,
        cacheManager: this,
      });
    }

    return false;
  };

  private adminEventsCacheTwoProductNeedsReload(
    cacheTwoProduct: AdminEventsCacheTwoProductState,
    eventTypeId: number,
    productId: number,
    props: RouteComponentProps<IAdminProductRouterParams, {}>,
    isEdit: boolean,
  ) {
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const isCacheInvalid = this.isPopulatedProductInvalid(cacheTwoProduct, eventTypeId, productId);

    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      (isEdit && !isAdminEventsCacheTwoProductPopulated(cacheTwoProduct))
    );
  }

  // Class
  private isPopulatedClassInvalid(
    cacheTwoEvent: AdminEventsCacheTwoEventState,
    cacheThreeClass: AdminEventsCacheThreeClassState,
    eventTypeId: number,
    eventId: string,
    classId: number,
  ) {
    if (this.isPopulatedEventInvalid(cacheTwoEvent, eventTypeId, eventId)) return true;

    const isInvalid = (
      this._isPopulatedCacheInvalid<AdminEventsEventClass>(
        cacheThreeClass.EventsEventClass,
        (adminClass) => adminClass.IDi,
        classId,
      )
    );

    if (isInvalid) {
      reduxStoreService().dispatch(new ClearAdminEventsCacheBelowTwoEvent());
    }

    return isInvalid;
  };

  public loadCacheThreeClass(
    params: LoadAdminEventCacheThreeClassParams
  ) {
    const {props, isStateNavigated, isEdit} = params;
    const rootState = reduxStoreService().getState();

    const classId = getAdminEventClassIdFromPath(props.params);
    // Only SetEmptyCart when first navigating to class form page but not
    // due to a rollback
    if (isStateNavigated) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }
    
    if (this.adminEventsCacheThreeClassNeedsReload(
      rootState.adminEvents.cacheThreeClass,
      rootState.adminEvents.cacheTwoEvent,
      getEventTypeId(props),
      props.params.eventId,
      classId,
      props,
      isEdit,
    ) || isStateNavigated) {
      return ensureAdminEventsCacheThreeClass({
        ...params,
        cacheManager: this,
      });
    }

    return false;
  };

  private adminEventsCacheThreeClassNeedsReload(
    cacheThreeClass: AdminEventsCacheThreeClassState,
    cacheTwoEvent: AdminEventsCacheTwoEventState,
    eventTypeId: number,
    eventId: string,
    classId: number,
    props: RouteComponentProps<IAdminClassRouterParams, {}>,
    isEdit: boolean,
  ) {
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const isCacheInvalid = this.isPopulatedClassInvalid(
      cacheTwoEvent,
      cacheThreeClass,
      eventTypeId,
      eventId,
      classId,
    );

    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      (isEdit && !isAdminEventsCacheThreeClassPopulated(cacheThreeClass))
    );
  }

  // Class Requirements
  private isPopulatedClassThreeRequirementsInvalid(
    cacheThreeClassRequirements: AdminEventsCacheThreeClassRequirementsState,
  ) {
    const rootState = reduxStoreService().getState();
    const ActiveForm = !rootState.adminEvents.events.modals.enterClassRequirement.ClassRequirements ? undefined: rootState.adminEvents.events.modals.enterClassRequirement.ClassRequirements.ActiveForm;
    const isInvalid = !ActiveForm || (!!cacheThreeClassRequirements.EventsEventClassRequirements &&
        (cacheThreeClassRequirements.EventsEventClassRequirements.ClassIDi !== ActiveForm.ClassIDi ||
         cacheThreeClassRequirements.EventsEventClassRequirements.ClassTypeIDi !== ActiveForm.ClassTypeIDi ||
         cacheThreeClassRequirements.EventsEventClassRequirements.ParentIDi !== ActiveForm.ParentIDi));
    
    if (isInvalid) {
      reduxStoreService().dispatch(new ClearAdminEventsCacheBelowTwoEvent());
    }

    return isInvalid;
  };

  public loadCacheThreeClassRequirements(
    params: LoadAdminEventCacheThreeClassRequirementParams
  ) {
    const {props} = params;
    const rootState = reduxStoreService().getState();

    if (this.adminEventsCacheThreeClassRequirementsNeedsReload(
      props,
      rootState.adminEvents.cacheThreeClassRequirements,
    )) {
      return ensureAdminEventsCacheThreeClassRequirements(params);
    }

    return false;
  };

  private adminEventsCacheThreeClassRequirementsNeedsReload(
    props: RouteComponentProps<{}, {}>,
    cacheThreeClassRequirements: AdminEventsCacheThreeClassRequirementsState,
  ) {
    return (
      this.isPopulatedClassThreeRequirementsInvalid(cacheThreeClassRequirements) ||
      !isAdminEventsCacheThreeClassRequirementsPopulated(cacheThreeClassRequirements)
      
    );
  }

  // Class Type
  private isPopulatedClassTypeInvalid(
    cacheTwoEvent: AdminEventsCacheTwoEventState,
    cacheThreeClassType: AdminEventsCacheThreeClassTypeState,
    eventTypeId: number,
    eventId: string,
    classTypeId: number,
  ) {
    if (this.isPopulatedEventInvalid(cacheTwoEvent, eventTypeId, eventId)) return true;

    const isInvalid = (
      this._isPopulatedCacheInvalid<AdminEventsCacheThreeClassType>(
        cacheThreeClassType.ClassType,
        (classType) => classType.IDi,
        classTypeId,
      )
    );

    if (isInvalid) {
      const isClassTypeModalOpened = isModalOpened(ModalTypes.CLASS_TYPE_FORM);

      if (isClassTypeModalOpened) reduxStoreService().dispatch(new ClearAdminEventsCacheThreeClassType());
      else reduxStoreService().dispatch(new ClearAdminEventsCacheBelowTwoEvent());
    }

    return isInvalid;
  };

  public loadCacheThreeClassType(
    params: LoadAdminEventCacheThreeClassTypeParams
  ) {
    const {props, isEdit, isStateNavigated, classTypeIdFromModalProps} = params;
    const rootState = reduxStoreService().getState();

    const classTypeId = getDataIdFromUrlOrModalProp<IAdminClassTypeRouterParams>(
      getAdminEventClassTypeIdFromPath,
      props.params,
      classTypeIdFromModalProps,
    );

    // Only SetEmptyCart when first navigating to classType form page but not
    // due to a rollback
    if (isStateNavigated && !isModalOpened(ModalTypes.CLASS_TYPE_FORM)) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }
    if (this.adminEventsCacheThreeClassTypeNeedsReload(
      rootState.adminEvents.cacheThreeClassType,
      rootState.adminEvents.cacheTwoEvent,
      getEventTypeId(props),
      props.params.eventId,
      classTypeId,
      props,
      isEdit,
    ) || isStateNavigated) {
      return ensureAdminEventsCacheThreeClassType({
        ...params,
        cacheManager: this,
        classTypeId,
      });
    }

    return false;
  };

  private adminEventsCacheThreeClassTypeNeedsReload(
    cacheThreeClassType: AdminEventsCacheThreeClassTypeState,
    cacheTwoEvent: AdminEventsCacheTwoEventState,
    eventTypeId: number,
    eventId: string,
    classTypeId: number,
    props: RouteComponentProps<IAdminClassTypeRouterParams, {}>,
    isEdit: boolean,
  ) {
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const isCacheInvalid = this.isPopulatedClassTypeInvalid(
      cacheTwoEvent,
      cacheThreeClassType,
      eventTypeId,
      eventId,
      classTypeId,
    );

    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      (isEdit && !isAdminEventsCacheThreeClassTypePopulated(cacheThreeClassType))
    );
  }

  // Event
  public initCacheTwoEventNonFormPage(context: AdminEventsCacheTwoEventContext) {
    if (context === AdminEventsCacheTwoEventContext.CLASSES_TAB) {
      reduxStoreService().dispatch(new AdminEventsEventClassesTabReset());
    } else if (context === AdminEventsCacheTwoEventContext.CLASS_TYPES_TAB) {
      reduxStoreService().dispatch(new AdminEventsEventClassTypesTabReset());
    }
  }

  private isPopulatedEventInvalid(
    cacheTwoEvent: AdminEventsCacheTwoEventState,
    eventTypeId: number,
    eventId: string,
  ) {
    const cacheOne = reduxStoreService().getState().adminEvents.cacheOne;

    if (this.isPopulatedAdminEventsCacheOneInvalid(cacheOne, eventTypeId)) return true;
    
    const isInvalid = this._isPopulatedCacheInvalid<AdminEvent>(
      cacheTwoEvent.EventsEvent,
      (event) => event.IDi,
      eventId,
    );

    if (isInvalid) {
      reduxStoreService().dispatch(new ClearAdminEventsCacheBelowOne());
    }

    return isInvalid;
  };

  public loadCacheTwoEvent(
    params: LoadAdminEventCacheTwoEventParams,
  ) {
    const {isStateNavigated, isEdit, props} = params;
    const rootState = reduxStoreService().getState();

    // Only SetEmptyCart when first navigating to event form page but not
    // due to a rollback
    if (isStateNavigated && isEdit) {
      safelySetEmptyCart(getGroupCache, getCacheOneEmptyCartBody);
    }

    if (this.adminEventsCacheTwoEventNeedsReload(
      rootState.adminEvents.cacheTwoEvent,
      getEventTypeId(props),
      props.params.eventId,
      props,
      isEdit,
    ) || isStateNavigated) {
      return ensureAdminEventsCacheTwoEvent({
        ...params,
        cacheManager: this,
      });
    }

    return false;
  };

  public adminEventsCacheTwoEventNeedsReload(
    cacheTwoEvent: AdminEventsCacheTwoEventState,
    eventTypeId: number,
    eventId: string,
    props: RouteComponentProps<IAdminEventRouterParams, {}>,
    isEdit: boolean,
  ) {
    const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
    const isCacheInvalid = this.isPopulatedEventInvalid(
      cacheTwoEvent,
      eventTypeId,
      eventId,
    );
    return (
      endUserCacheOneInvalid ||
      isCacheInvalid ||
      (isEdit && !isAdminEventsCacheTwoEventPopulated(cacheTwoEvent))
    );
  }
}