export const DEFAULT_CKEDITOR_CONFIG = {
  height: 440,
  toolbar: null,
  toolbarGroups: [
		{ name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
		{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
		{ name: 'links', groups: [ 'links' ] },
		{ name: 'insert', groups: [ 'insert' ] },
		{ name: 'tools', groups: [ 'tools' ] },
		{ name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
		'/',
		{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
		{ name: 'forms', groups: [ 'forms' ] },
		{ name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
		{ name: 'styles', groups: [ 'styles' ] },
		{ name: 'colors', groups: [ 'colors' ] },
		{ name: 'others', groups: [ 'others' ] },
		{ name: 'about', groups: [ 'about' ] }
  ],
  removeButtons: 'PasteText,SelectAll,Scayt,Image,Flash,Smiley,PageBreak,Iframe,ShowBlocks,Save,NewPage,Preview,Print,Templates,Subscript,CopyFormatting,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,JustifyLeft,BidiLtr,BidiRtl,JustifyCenter,Language,JustifyRight,JustifyBlock,Font,FontSize,TextColor,About,BGColor',
  resize_enabled: false,

  //Allows anything
  //config.allowedContent = true;

  // {} Inline styles are disabled, better to use classes instead. Only enabled for table.
  // [] Attributes.
  // () Classes.
  extraAllowedContent: 'div[*](*); a[*](*); iframe[*](*); p[*](*); h1[*](*); h2[*](*); h3[*](*); h4[*](*); h5[*](*); table[*](*){*}; thead[*](*); tbody[*](*); tr[*](*); th[*](*); td[*](*); button[*](*); i[*](*); img[*](*); ul[*](*); ol[*](*); li[*](*); span[*](*)',
  
};

export enum CKEDITOR_MODE {
	WYSIWYG='wysiwyg',
	SOURCE='source',
}

export const registerSourceModeInputEvent = (eventInfo, callback) => {
	const editable = eventInfo.editor.editable();
	if (eventInfo.editor.mode === CKEDITOR_MODE.SOURCE) {
		editable.attachListener(editable, 'input', callback);
	}
};