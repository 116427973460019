import { ActionCreator } from '../../../../';
import {ClassType} from "../../../../../models/api/options";
import { typeName, Action } from '../../../../../utils/StrongActions';

@typeName("ADD_UNSCHEDULED_FILTER")
export class AddUnscheduledFilter extends Action {
  constructor(public filterString: string) { super(); }
}

@typeName("ADD_UNSCHEDULED_SELECT")
export class AddUnscheduledSelect extends Action {
  constructor(public clazz?: ClassType) { super(); }
}

@typeName("ADD_UNSCHEDULED_ADD")
export class AddUnscheduledAdd extends Action {
  constructor(public clazz: ClassType) { super(); }
}

@typeName("ADD_UNSCHEDULED_IS_SEARCHING")
export class AddUnscheduledIsSearching extends Action {
  constructor(public show: boolean) {super();}
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  filterClasses: (filterString: string): ActionCreator => dispatch => dispatch(new AddUnscheduledFilter(filterString)),
  selectClass: (clazz?: ClassType): ActionCreator => dispatch => dispatch(new AddUnscheduledSelect(clazz)),
  addClass: (clazz: ClassType): ActionCreator => dispatch => dispatch(new AddUnscheduledAdd(clazz)),
  showSearch: (show: boolean): ActionCreator => dispatch => dispatch(new AddUnscheduledIsSearching(show))
};
