import {SUFFIX, ValidateActions} from './actions';
import * as NM from '../../../../../constants/messages/numbersWizard';
import {apiValidationReducerCreator} from '../../../../Validation/reducerCreator';
import {APISuccess, UpdateCurrValue, WithRootState} from "../../../../Validation/actionCreator";
import {FormDefinition} from "./validation";
import {FinancialSummaryInit, initFinancialSummary} from "../../../../../utils/classesHelper";
import {ReservationChange} from "../../../../Facilities/Trip/Reservation/Reservation/actions";
import {FacilitiesCalc, FacilitiesRecalc, FacilitiesTypeC4, ReservationSaveOverrideFees} from "../../../../CacheFourFacilities/actions";
import {FacilitiesReservationReset} from "../actions";
import {SUFFIX_SAVE} from '../../../../CacheFourFacilities/actions';
import { AnyAction, Reducer } from 'redux';
import { Action, isActionType } from '../../../../../utils/StrongActions';

export interface FacilitiesWizardConfirmationState {
  ActiveForm: {
    selectedFinance?: 'min' | 'full' | 'other' | 'credit';
    otherValue?: number;
  },
  ValidationRules: any;
  SubmitErrorMessage?: string;
  minValue?: number;
  maxValue?: number;
}

const determineInlineError = (errors: any) => {
  let message = '';
  if (errors) {
    if (errors.otherValue) message = NM.INVALID_PAYMENT;
  }

  return message;
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, determineInlineError);
const checkC4SaveValidation = apiValidationReducerCreator(SUFFIX_SAVE);

const getInitialState = (): FacilitiesWizardConfirmationState => {
  return {
    ActiveForm: {
      selectedFinance: 'min',
      otherValue: 0
    }, ValidationRules: {...FormDefinition}};
};

const FacilitiesWizardConfirmation: Reducer<FacilitiesWizardConfirmationState> = (oldState = {...getInitialState()}, act: WithRootState<AnyAction>) => {
  const s = checkApiValidation(oldState, act);
  const state = checkC4SaveValidation(s, act);
  if (
    act.type === FacilitiesCalc.successType || act.type === FacilitiesTypeC4.successType || act.type === FacilitiesRecalc.successType ||
    act.type === ReservationChange.successType
  ) {
    const action = <WithRootState<APISuccess>> act;
    if (action.response.response.TripPaymentStatus) {
      const vals: FinancialSummaryInit = initFinancialSummary(
        action.rootState,
        action.response.response.TripPaymentStatus,
        (rootState) => rootState.facilities.trip.reservation.confirmation.ActiveForm.selectedFinance === 'other',
        false,
      );

      return {
        ...state,
        ActiveForm: {selectedFinance: vals.paymentType, otherValue: vals.otherDefaultValue},
        ValidationRules: {...state.ValidationRules, otherValue: vals.otherValueRules},
        minValue: action.response.response.TripPaymentStatus.AmountMin,
        maxValue: action.response.response.TripPaymentStatus.AmountMax,
        SubmitErrorMessage: undefined
      };
    }
    return state;
  } else if (act.type === ValidateActions.updateCurrType) {
    const action = <WithRootState<UpdateCurrValue>> act;
    const newState = {...state};
    newState.ActiveForm = {...state.ActiveForm};
    if (action.value === 'full') {
      newState.ActiveForm.otherValue = state.maxValue;
    } else if (action.value === 'min') {
      newState.ActiveForm.otherValue = state.minValue;
    } else if (action.value === 'credit') {
      newState.ActiveForm.otherValue = 0;
    } else {
      return state;
    }
    return newState;
  } else if (isActionType(act, ReservationSaveOverrideFees)) {
    if (act.TripPaymentStatus) {
      const vals: FinancialSummaryInit = initFinancialSummary(
        act.rootState,
        act.TripPaymentStatus,
        (rootState) => rootState.facilities.trip.reservation.confirmation.ActiveForm.selectedFinance === 'other',
        false,
      );

      return {
        ...state,
        ActiveForm: {selectedFinance: vals.paymentType, otherValue: vals.otherDefaultValue},
        ValidationRules: {...state.ValidationRules, otherValue: vals.otherValueRules},
        minValue: act.TripPaymentStatus.AmountMin,
        maxValue: act.TripPaymentStatus.AmountMax,
        SubmitErrorMessage: undefined
      };
    }

    return state;
  } else if (isActionType(act, FacilitiesReservationReset)) {
    return getInitialState();
  }

  return state;
};

export default FacilitiesWizardConfirmation;
