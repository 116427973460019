import * as React from 'react';
import * as M from '../../../../../../../../constants/messages/participantsWizard';
import RosterItem from '../RosterItem';
import RosterCard from "../../../../../../../Elements/Roster/Card";
import {RosterList, RosterListEmpty, Alert} from '../../../../../../../Elements';
import {EventNotRegisteredPerson} from "../../../../../../../../models/api/cacheFourEvents";
import {GetGroupRosterPersonParams} from "../../../../../../../../constants/urls";
import {SimplePerson} from "../../../../../../../../store/Events/Event/Register/Participant/Roster";

export const namespace = (): string => 'pages--events--event--register--participant--roster';

interface Props {
  EventRegistrationPeopleNotRegisteredAdults?: Array<EventNotRegisteredPerson> | null;
  EventRegistrationPeopleNotRegisteredYouth?: Array<EventNotRegisteredPerson> | null;
  eventType: 'numbers' | 'participant' | 'both';
  GroupIDi: number;
  GroupTS: number;
  onSelect: (params: GetGroupRosterPersonParams) => void;
  ShowYouth: boolean;
  ShowAdults: boolean;
  isError: boolean;
  onDelete: ((person: SimplePerson) => void)
}

class MyGroup extends React.Component<Props, {}> {
  public props: Props;

  public render(): any {
    const {
      EventRegistrationPeopleNotRegisteredAdults, EventRegistrationPeopleNotRegisteredYouth, eventType, onSelect,
      GroupIDi, GroupTS, ShowYouth, ShowAdults, isError, onDelete
    } = this.props;
    return [
      isError ? <Alert className={`${namespace}--group-alert`} noFlex>{M.NEED_PERSON}</Alert> : null,
      ShowYouth ? <RosterCard key="youth" title="Youth (Y)" template="mobile-no-margin" isWizard mobileBottomBorder>
        {(!EventRegistrationPeopleNotRegisteredYouth || EventRegistrationPeopleNotRegisteredYouth.length === 0) &&
          <RosterListEmpty message={M.NO_YOUTH_AVAILABLE}/>
        }
        {EventRegistrationPeopleNotRegisteredYouth && EventRegistrationPeopleNotRegisteredYouth.length > 0 &&
          <RosterList>
            {EventRegistrationPeopleNotRegisteredYouth.map((p: EventNotRegisteredPerson, i) => {
              return (
                <RosterItem person={p} onSelect={onSelect} GroupIDi={GroupIDi} GroupTS={GroupTS} key={i} onDelete={onDelete}>
                  {p.Name}
                </RosterItem>
              );
            })}
          </RosterList>
        }
      </RosterCard> : null,

      ShowAdults ? <RosterCard key="adult" title="Adults (A)" template="mobile-no-margin" isWizard>
        {(!EventRegistrationPeopleNotRegisteredAdults || EventRegistrationPeopleNotRegisteredAdults.length === 0) &&
          <RosterListEmpty message={M.NO_ADULTS_AVAILABLE}/>
        }
        {EventRegistrationPeopleNotRegisteredAdults && EventRegistrationPeopleNotRegisteredAdults.length > 0 &&
          <RosterList>
            {EventRegistrationPeopleNotRegisteredAdults.map((p: EventNotRegisteredPerson, i) => {
              return (
                <RosterItem person={p} onSelect={onSelect} GroupIDi={GroupIDi} GroupTS={GroupTS} key={i} onDelete={onDelete}>
                  {p.Name}
                </RosterItem>
              );
            })}
          </RosterList>
        }
      </RosterCard> : null
    ];
  }
}

export default MyGroup;
