
import { SaveState } from "../../store/Rollback/actions";
import { GetAdminClassRequirements } from "../../store/AdminEvents/CacheThreeClassRequirements/actions";
import { reduxStoreService } from "../../store/service";
import type AdminEventsCacheManager from "../cacheManagers/adminEventsCacheManager";
import { LoadAdminEventCacheThreeClassRequirementParams } from "./helpers/models";
import {isAdminEventsCacheThreeClassRequirementsPopulated} from "../cachePopulatedCheckers/adminEvents";

export const ensureAdminEventsCacheThreeClassRequirements = (
  params: LoadAdminEventCacheThreeClassRequirementParams,
): boolean => {
  const {isEdit, classId, isStateNavigated} = params;

  const rootState = reduxStoreService().getState();
  const adminEventsCacheThreeClassRequirements = rootState.adminEvents.cacheThreeClassRequirements;

  let requestedData = false;
  if (isEdit) {
    if (isAdminEventsCacheThreeClassRequirementsPopulated(adminEventsCacheThreeClassRequirements)) {
      if (isStateNavigated) {
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      reduxStoreService().dispatch(GetAdminClassRequirements.request({IDi: classId}));

      requestedData = true;
    }
  }

  return requestedData;
};