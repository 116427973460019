import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/elements/card/sub-title.scss';
import {default as attributes, MarginProps, PaddingProps} from "../../../styles/index";

export const namespace = (): string => `${ns()}--sub-title`;

export interface SubTitleProps extends MarginProps, PaddingProps {
  children?: React.ReactNode;
  className?: string;
}

const SubTitle: React.SFC<SubTitleProps> = ({ children, className: classNameOverride, ...props }: SubTitleProps): React.ReactElement<SubTitleProps> => {
  let className = attributes(namespace(), classNameOverride, props);
  return (
    <h2 className={className}>
      <span>
        {children}
      </span>
    </h2>
  );
};

export default SubTitle;
