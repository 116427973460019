import { GetContactParams } from "../../../constants/urls";
import { CLEAR_CACHE_PREFIX, createRequestActions } from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { ADMIN_CMS_MODULE_SUFFIX } from "../CacheOne/actions";
import { HideDrawer, SilentCancelAllAction } from '../../../store/App/actions';
import { UPDATE_CMS_CONTACT_FORM_SUFFIX } from "../Contacts/Contact/Form/actions";
import { Action, typeName } from "../../../utils/StrongActions";


export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__CACHE_TWO_CONTACTS';
export const SUFFIX_SAVE = ADMIN_CMS_MODULE_SUFFIX + '__CACHE_TWO_CONTACTS_SAVING';

export const GetContact = createRequestActions(SUFFIX + UPDATE_CMS_CONTACT_FORM_SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminCMSCacheTwoContact extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  getContact: (params: GetContactParams): ActionCreator => dispatch => {
    dispatch(GetContact.request(params));
  },
  clearAdminCMSCacheTwoContact: (): ActionCreator => dispatch => {
    dispatch(new ClearAdminCMSCacheTwoContact());
  },
  refreshContact: (params: GetContactParams, isRefreshingContactForm: boolean): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    dispatch(GetContact.request(params, {skipUpdateForm: !isRefreshingContactForm}));
  },
};