import { setDefaults } from './../../../utils/validator';
import * as Actions from './actions';
import { FormDefinition } from './validation';
import { IValidator } from '../../AddGroup/validation';
import { SUFFIX, DELETE_SUFFIX, ProfileInitialize, ApiSubmitActions } from './actions';
import { apiValidationReducerCreator } from '../../Validation/reducerCreator';
import {APIFailure, APISuccessSubmit, WithRootState} from "../../Validation/actionCreator";
import {GetOptions} from "../../CacheZero/actions";
import {ApiSubmitDeleteActions} from "./actions";
import {FullGroup} from "../../../models/api/options";
import {GetGroupCache} from "../../CacheOne/actions";
import cloneDeep from 'lodash.cloneDeep';
import { EditEmailActions } from '../EditEmail/actions';
import { ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction } from '../../../store/App/actions';
import { captureTentarooError } from '../../../utils/dataHelper';
import { isActionType } from '../../../utils/StrongActions';
import { AnyAction, Reducer } from 'redux';
import { Validator } from '../../../utils/validator/models';

export interface ActiveForm {
  Username?: string;
  Password?: string;
  ConfirmPassword?: string;
  Email?: string;

  GroupTypeID?: number;
  UnitTypeID?: number;
  UnitGenderID?: number;
  Unit?: string;
  CouncilIDi?: number;
  DistrictIDi?: number;

  Suffix?: string;
  FirstName?: string;
  LastName?: string;

  PhoneNumber?: string;
  PhoneNumber2?: string; // alternate phone number
  Address?: string;
  Address2?: string;
  /**
   * StateID can be null
   * 
   * Even though StateID has a default, users can manually set to blank on systems that aren't set
   * to require address (cmsdev doesn't require address but greatsky does). In cases like this, a null
   * StateID is expected and doesn't cause any issues.
   */
  StateID?: number;
  City?: string;
  Zip?: string;

  Contact2Name?: string;
  Contact2Phone?: string;
  Contact2Email?: string;

  AllowOnlineBooking?: boolean;
  UnitSponsor?: string; // text; show only for unit (1) max 100
  Organization?: string; // show only if Non-profit (4); max 100
  PricingTypeID?: number; // dropdown; admin visible only; use admin options
  FacilityPricingTypeID?: number; // dropdown; admin visible only; use admin options
  MI?: string; // always show; max 2
  MarketingSource?: string; // how did you hear about us? max 1024
  Inactive?: boolean;
}

export interface ProfileState {
  ActiveForm: ActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
}


const checkApiValidation = apiValidationReducerCreator(SUFFIX);
const checkApiValidationDelete = apiValidationReducerCreator(DELETE_SUFFIX);

const initStateWithGroup = (state: ProfileState, group: FullGroup) => {
  const newState = {
    ...state,
    ActiveForm: {...group},
  };

  newState.ValidationRules = cloneDeep(FormDefinition);
  newState.ValidationRules.Username = {
    ...newState.ValidationRules.Username,
    apiCheck: {
      ...newState.ValidationRules.Username.apiCheck,
      lastValue: group.Username,
      state: undefined,
      lastState: undefined
    }
  };

  return newState;
};

const getInitialState: () => ProfileState = () => ({
  ActiveForm: {},
  ValidationRules: cloneDeep(FormDefinition)
});
const Profile: Reducer<ProfileState> = (oldState = getInitialState(), act: WithRootState<AnyAction>) => {
  const s = checkApiValidation(oldState, act);
  const state = checkApiValidationDelete(s, act);
  if (act.type === GetGroupCache.successType ||
    act.type === GetOptions.successType ||
    act.type === ApiSubmitDeleteActions.successType ||
    ((act.type === ApiSubmitDeleteActions.failureType ||
    act.type === ApiSubmitActions.failureType) && !(act as any).response.parseError) ||
    act.type === Actions.ApiSubmitActions.successType ||
    isActionType(act, SetCacheAction)
  ) {
    const action = <WithRootState<APIFailure>> act;
    let group;
    try {
      group = action.response.xhr.response.Group;
    } catch (e) {
      // do nothing, some responses don't return group
    }
    if (!group) return state;
    return initStateWithGroup(state, group);
  } else if (act.type === EditEmailActions.successType) {
    const action = <WithRootState<APISuccessSubmit>> act;
    if (action.response.response.Group) {
      return {...state, ActiveForm: { ...state.ActiveForm, Email: action.response.response.Group.Email }};
    }
    return state;
  } else if (isActionType(act, ProfileInitialize)) {
    const cacheZero = act.rootState.cacheZero;
    if (cacheZero.options && cacheZero.options.Group) {
      const stateWithDefaultValue = setDefaults(act.rootState, state);
      const newState = initStateWithGroup(stateWithDefaultValue, cacheZero.options.Group);
      newState.SubmitErrorMessage = undefined;
      return newState;
    }

    /**
     * NOTE: Profile could be _reset_ when there is no Group in cacheZero:
     * When an unauthenticated user visits Checkout/Profile page, Checkout/Profile page
     * will be rendered for a really short moment: after `GetLoginForm` completes and before
     * redirection. Then, redirection will trigger `componentWillUnmount` and resets form
     * 
     * However, Profile should NOT be _init_ when there is no Group in cacheZero
     */
    captureTentarooError(new Error('/profile initialized, but there was no Group in cacheZero'));
    return state;
  } else if (
    isActionType(act, Actions.ProfileResetForm) ||
    isActionType(act, ClearAllCache) ||
    isActionType(act, ClearAllEndUserCacheButOptions)
  ) {
    return getInitialState();
  }
  return state;
};

export default Profile;
