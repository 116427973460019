import * as React from 'react';
import '../../../styles/elements/credit-card-types/index.scss';
import { PaymentTypeMasterCardIcon, PaymentTypeVisaIcon, PaymentTypeAmericanExpressIcon, PaymentTypeDiscoverIcon, PaymentTypeECheckIcon } from '../../../components/Icons';

const image = {
  '1x': require('../../../images/elements/credit-card-types/credit-card-type@1x.png') + '',
  '2x': require('../../../images/elements/credit-card-types/credit-card-type@2x.png') + '',
  '3x': require('../../../images/elements/credit-card-types/credit-card-type@3x.png') + '',
};

interface Props {
  className?: string;
  ShowCCDiscover?: boolean;
  ShowAmEx?: boolean;
  AllowECheckPayment: boolean;
}

const CreditCardTypes:React.SFC<Props> = (props: Props):React.ReactElement<Props> => (
  <div className="credit-card-types">
    <PaymentTypeMasterCardIcon />
    <PaymentTypeVisaIcon />
    {props.ShowAmEx && <PaymentTypeAmericanExpressIcon />}
    {props.ShowCCDiscover && <PaymentTypeDiscoverIcon />}
    {props.AllowECheckPayment && <PaymentTypeECheckIcon />}
  </div>
);
/*
  <img
    className="credit-card-types"
    width="160px"
    height="16px"
    src={image['1x']}
    srcSet={`${image['1x']} 1x, ${image['2x']} 2x, ${image['3x']} 3x`}
    {...props}
  />
*/
export default CreditCardTypes;
