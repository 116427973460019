import * as React from 'react';
import { Added, Tabs, Tab } from '../../Elements';

interface Props {
  children?: React.ReactNode;
  onHideSideBar?: () => void;
  onShowSideBar?: () => void;
  addedSelected?: boolean;
  addedCount?: number;
};

const TabsComponent: React.SFC<Props> = (
  { children, addedSelected, onShowSideBar, onHideSideBar, addedCount }: Props
): React.ReactElement<Props> => {
  return (
    <Tabs noOverflow={true} className="max-width-100">
      {React.Children.map(children, (node: React.ReactElement<any>) => React.cloneElement(node, {
        ...node.props,
        selected: !addedSelected ? node.props.selected : false,
        onClick: () => {
          if (onHideSideBar) onHideSideBar();
          if (node.props.onClick) node.props.onClick();
        },
      }))}
      {addedCount ? (
        <Tab className="no-overflow" tablet mobile horizontalAlign="end" selected={addedSelected} onClick={onShowSideBar}>
          <Added addedLength={addedCount ? addedCount : 0}/>
        </Tab>
      ) : null}
    </Tabs>
  );
};

export default TabsComponent;
