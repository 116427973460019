import * as React from 'react';
import '../../../styles/elements/side-modal/content-padding.scss';
import { namespace as ns } from './Content';

export const namespace = (): string => `${ns()}--padding`;

export interface ContentPaddingProps {
  children?: React.ReactNode;
  marginBottom?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  flex?: boolean;
  layout?: 'vertical' | 'horizontal';
  alignItems?: string;
}

const ContentPadding: React.SFC<ContentPaddingProps> = (props: ContentPaddingProps): React.ReactElement<ContentPaddingProps> => {
  const { marginBottom, marginTop, marginLeft, marginRight, flex, layout, alignItems } = props;

  const style: React.CSSProperties = {};
  if (marginBottom !== null) style.marginBottom = `${marginBottom}px`;
  if (marginTop !== null) style.marginTop = `${marginTop}px`;
  if (marginLeft !== null) style.marginLeft = `${marginLeft}px`;
  if (marginRight !== null) style.marginRight = `${marginRight}px`;
  if (flex) style.display = 'flex';
  if (layout) {
    style.flexDirection = layout === 'vertical' ? 'column' : 'row';
    style.alignItems = 'center';
  }
  if (alignItems) style.alignItems= alignItems;

  return (
    <div className={namespace()} style={style}>
      {props.children}
    </div>
  );
};

export default ContentPadding;
