import { GetFacilityTypeParams } from "../../../constants/urls";
import { CLEAR_CACHE_PREFIX, createRequestActions } from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { ADMIN_FACILITY_MODULE_SUFFIX } from "../CacheOne/actions";
import { HideDrawer, SilentCancelAllAction } from '../../../store/App/actions';
import { UPDATE_FACILITY_FACILITY_TYPE_FORM_SUFFIX } from "../../../utils/suffix";
import { typeName, Action } from "../../../utils/StrongActions";


export const SUFFIX = '__CACHE_TWO_FACILITY_TYPES';
export const SUFFIX_SAVE = '__CACHE_TWO_FACILITY_TYPES_SAVING';

export const GetFacilityType = createRequestActions(SUFFIX + ADMIN_FACILITY_MODULE_SUFFIX + UPDATE_FACILITY_FACILITY_TYPE_FORM_SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminFacilityLocationCacheTwoFacilityType extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  getFacilityType: (params: GetFacilityTypeParams): ActionCreator => dispatch => {
    dispatch(GetFacilityType.request(params));
  },
  refreshFacilityType: (params: GetFacilityTypeParams): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    dispatch(GetFacilityType.request(params));
  },
  clearAdminFacilityLocationCacheTwoFacilityType: (): ActionCreator => dispatch => {
    dispatch(new ClearAdminFacilityLocationCacheTwoFacilityType());
  },
};