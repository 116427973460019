import { SUBMIT_SUFFIX, CheckAttribute, RemoveTimeBlock, AddTimeBLock, CopyFromInCouncil, MoveGalleryItem, ImportBookingTimesSubmitActions, ImportPricingSubmitActions, ImportImagesSubmitActions, AddPhoto, EditFacilityAddImageSubmitActions, EditFacilityDeleteImageSubmitActions, UPDATE_FACILITY_FACILITY_FORM_SUFFIX, ClearTempThumbnails } from "./actions";
import { apiValidationReducerCreator, shouldSkipUpdateForm } from "../../../../../store/Validation/reducerCreator";
import { IFacilityDetailValidator, FacilityDetailFormDefinition, getTimeBlockAttrKey, getTimeBlocksValidationRules, getFacilityPricingAttrKey, FacilityPricingAttributeKeys, getPricingValidationRules, IFacilityValidator, FacilityFormDefinition } from './validation';
import { ClearAdminFacilityLocationCacheTwoFacility } from "../../../../../store/AdminFacilityLocation/CacheTwoFacility/actions";
import { updateImages } from "../../../../../store/AdminFacilityLocation/CacheTwoFacility";
import { APISuccess, APISuccessSubmit, WithRootState } from "../../../../../store/Validation/actionCreator";
import { ApplicationState } from "../../../../../store";
import { AdminFacilityDetail, TimeBlock, FacilityPricing } from "../../../../../models/api/adminFacilitiesCacheTwoFacility";
import { createSelector } from "reselect";
import { AdminFacilityType, ShowAsOption } from "../../../../../models/api/adminFacilitiesCacheOne";
import { facilityTypesSelector } from "../../Home";
import { getHourMinPeriodFromString, TimeAttributeKeys } from "../../../../../utils/dateHelper";
import { ClearAdminFacilityLocationCacheBelowOne } from "../../../../../store/AdminFacilityLocation/CacheOne/actions";
import { GLAccountsSelector } from "../../../../../store/CacheZero";
import { GLAccount } from "../../../../../models/api/options";
import { makeImageLoading } from "../../../../../store/AdminCMSSite/Pages/Page/Form";
import { setDefaults } from "../../../../../utils/validator";
import { API_UPD } from "../../../../../constants/request";
import { SetCacheAction, SilentCancelAllAction } from "../../../../../store/App/actions";
import { moveItemInArrayWithOffset, filteredPhotoGalleryCombiner, handleDeleteImageFailure, handleAddImageFailure } from "../../../../../utils/dataHelper";
import { Reducer } from "redux";
import { Action, isActionType } from "../../../../../utils/StrongActions";
import { hasOngoingSequentialRequest } from "../../../../App/reducerHelpers";
import { GalleryImage } from "../../../../../models/api/cacheOne";

export interface FacilityFormDetailActiveForm {
  ID: number;
  Name: string;
  Description: string;
  Attributes: number[];
  AllowOnlineBookingID: number;
  MaxCapacity: number;
  MinBookingLength: number;
  MaxBookingLength: number;
  RequireEntireWeekend: boolean;
  RequireStartOnSunday: boolean;
  ShowCheckInOutTimes: boolean;
  IsAllDay: boolean;
  TimeBlocks?: TimeBlock[];
  MinPerPersonFeesToBill: number;
  Pricing?: FacilityPricing[];
  GLAccountID?: number;
  Images: GalleryImage[];
}

export interface FacilityFormActiveForm {
  ShowDeleted?: boolean;
}

export interface FacilityFormState {
  Detail: {
    ActiveForm: FacilityFormDetailActiveForm;
    ValidationRules: IFacilityDetailValidator;
    SubmitErrorMessage?: string;
  },
  ActiveForm: FacilityFormActiveForm;
  ValidationRules: IFacilityValidator;
  SubmitErrorMessage?: string;
  processingImages?: boolean;
}


const getInitialState = () => {
  return {
    Detail: {
      ActiveForm: { },
      ValidationRules: { ...FacilityDetailFormDefinition }
    },
    ActiveForm: { },
    ValidationRules: { ...FacilityFormDefinition },
  };
};

const checkApiValidation = apiValidationReducerCreator(SUBMIT_SUFFIX);

const showAsOptionsSelector = (state: ApplicationState) => state.adminFacilityLocation.cacheOne.ShowAsOptions;
const facilityTypeShowAsIDSelector = (state: ApplicationState) => state.adminFacilityLocation.cacheTwoFacilityTypes.FacilitiesType ? state.adminFacilityLocation.cacheTwoFacilityTypes.FacilitiesType.General.ShowAsID : 0;
const GLAccountIDSelector = (state: ApplicationState) => state.adminFacilityLocation.facilities.facility.form.Detail.ActiveForm.GLAccountID;


export const hasTempThumbnails = (rootState: ApplicationState) => {
  const Images = rootState.adminFacilityLocation.facilities.facility.form.Detail.ActiveForm.Images;

  const hasTemp = Images ? Images.find((image) => !!image.tempId && image.ID === -1) : false;

  return !!hasTemp;
};

export const makeSelectedGLAccountSelector = () => {
  return createSelector(
    [GLAccountsSelector, GLAccountIDSelector],
    (accounts: GLAccount[], GLAccountID: number) => {
      return accounts ? accounts.find((a) => a.ID === GLAccountID) : null;
    }
  );
};

export const makeShowAsOptionSelector = () => {
  return createSelector(
    [showAsOptionsSelector, facilityTypeShowAsIDSelector],
    (showAsOptions: ShowAsOption[], showAsID: number) => {
      if (!showAsOptions || !showAsID) return null;
      return showAsOptions.find((s) => s.ID === showAsID);
    }
  );
};

const facilityTypeIDSelector = (state: ApplicationState) => state.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility ? state.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility.FacilityTypeID : 0;

export const makeFacilityTypeSelector = () => {
  return createSelector(
    [facilityTypesSelector, facilityTypeIDSelector],
    (facilityTypes: AdminFacilityType[], facilityTypeID: number) => {
      if (!facilityTypes || !facilityTypeID) return null;
      return facilityTypes.find((ft) => ft.ID === facilityTypeID);
    }
  );
};

export const initTimeBlocksActiveForm = (timeBlocks: TimeBlock[]) => {
  let activeForm = {};
  if (!timeBlocks) return activeForm;
  timeBlocks.forEach((tb, index) => {
    const StartTime = getHourMinPeriodFromString(tb.StartTime);
    const EndTime = getHourMinPeriodFromString(tb.EndTime);
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.ID)] = tb.ID;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.START_HOUR)] = StartTime.hour;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.START_MIN)] = StartTime.min;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.START_PERIOD)] = StartTime.period;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.END_HOUR)] = EndTime.hour;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.END_MIN)] = EndTime.min;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.END_PERIOD)] = EndTime.period;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.INACTIVE)] = !!tb.Inactive;
  });

  return activeForm;
};

const initPricingActiveForm = (pricing: FacilityPricing[]) => {
  let activeForm = {};

  if (!pricing) return activeForm;
  if (pricing) {
    pricing.forEach((p) => {
      activeForm[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_DAY_AMOUNT)] = p.PerDayAmount.toFixed(4);
      activeForm[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_DAY_AMOUNT_UPFRONT)] = p.PerDayAmountUpfront.toFixed(4);
      activeForm[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT)] = p.PerPersonPerDayAmount.toFixed(4);
      activeForm[getFacilityPricingAttrKey(p.PricingTierID, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT_UPFRONT)] = p.PerPersonPerDayAmountUpfront.toFixed(4);
    });
  }

  return activeForm;
};

const facilityPhotosSelector = (state: ApplicationState) => state.adminFacilityLocation.facilities.facility.form.Detail.ActiveForm.Images || [];
const facilityPhotosShowDeletedSelector = (state: ApplicationState) => state.adminFacilityLocation.facilities.facility.form.ActiveForm.ShowDeleted;
export const makeFilteredFacilityPhotosSelector = () => {
  return createSelector(
    [facilityPhotosSelector, facilityPhotosShowDeletedSelector],
    filteredPhotoGalleryCombiner,
  );
};

const constructEmptyPhoto = (dataUrl: string | ArrayBuffer | null): GalleryImage => {
  return {
    dataUrl,
    ID: -1,
    Ord: -1,
    attachPath: '',
    filePath: '',
    ImageOriginal: '',
    Inactive: false,
  };
};

export const setFacility = (state: FacilityFormState, facility: AdminFacilityDetail) => {
  if (!facility) return state;
  if (!facility.TimeBlocks || facility.TimeBlocks.length === 0) facility.TimeBlocks = [{ID: null, StartTime: '12:00:00', EndTime: '12:00:00'}] as any;
  const newState: FacilityFormState = {
    ...state,
    Detail: {
      ActiveForm: {
        ...state.Detail.ActiveForm,
        ID: facility.ID,
        Name: facility.Name,
        Description: facility.Description,
        Attributes: facility.Attributes,
        AllowOnlineBookingID: facility.AllowOnlineBookingID,
        MaxCapacity: facility.MaxCapacity,
        MinBookingLength: facility.MinBookingLength,
        MaxBookingLength: facility.MaxBookingLength,
        RequireEntireWeekend: facility.RequireEntireWeekend,
        RequireStartOnSunday: facility.RequireStartOnSunday,
        ShowCheckInOutTimes: facility.ShowCheckInOutTimes,
        IsAllDay: facility.IsAllDay,
        TimeBlocks: facility.TimeBlocks,
        MinPerPersonFeesToBill: facility.MinPerPersonFeesToBill,
        Pricing: facility.Pricing,
        GLAccountID: facility.GLAccountID,
        Images: facility.Images ? [...facility.Images] : [],
        ...initTimeBlocksActiveForm(facility.TimeBlocks),
        ...initPricingActiveForm(facility.Pricing),
      },
      ValidationRules: {
        ...state.Detail.ValidationRules,
        ...getTimeBlocksValidationRules(facility.TimeBlocks),
        ...getPricingValidationRules(facility.Pricing),
      },
    },
  };

  return newState;
};

const FacilityFormReducer: Reducer<FacilityFormState> = (s, act: WithRootState<Action>) => {
  const state = {
    ...getInitialState(),
    ...checkApiValidation(s, act),
  };

  if (s && s.Detail) state.Detail = checkApiValidation(s.Detail, act);

  if (act.type.includes(API_UPD) && act.type.includes(UPDATE_FACILITY_FACILITY_FORM_SUFFIX)) {
    if (shouldSkipUpdateForm(act)) return state;

    // get initial state
    let newState = getInitialState();

    // set default values
    newState = { ...newState, ...setDefaults(act.rootState, newState, state)};

    //load from cache two if set
    const rootState = act.rootState;
    if (rootState.adminFacilityLocation.cacheTwoFacility && rootState.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility && rootState.adminFacilityLocation.cacheTwoFacility.FacilitiesType) {
      const facility = rootState.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility;

      return setFacility(newState as any, facility);
    }

    return newState;
  } else if (act.type === EditFacilityAddImageSubmitActions.successType || act.type === EditFacilityDeleteImageSubmitActions.successType || (isActionType(act, SetCacheAction) && (act.actions.requestType === EditFacilityDeleteImageSubmitActions.requestType))) {
    const action = <WithRootState<APISuccessSubmit>>act;
    const res = action.response.xhr.response;
    const image = res.FacilityImage as GalleryImage;
    const hasMoreSequentialRequests = hasOngoingSequentialRequest(action.rootState);
    if (image) {
      return {
        ...state,
        Detail: {
          ...state.Detail,
          ActiveForm: {
            ...state.Detail.ActiveForm,
            Images: updateImages(state.Detail.ActiveForm.Images, image, action.extra ? action.extra.tempId : undefined, undefined, act.type === EditFacilityDeleteImageSubmitActions.successType),
          }
        },
        processingImages: hasMoreSequentialRequests > 1,
      };
    }
  } else if (act.type === EditFacilityDeleteImageSubmitActions.failureType) {
    const action = <WithRootState<APISuccess>>act;
    const res = action.response.xhr.response;
    const image = res.FacilityImage as GalleryImage;
    const body = JSON.parse(action.response.request.body);
    const imageId = body.row.ID;
    return {
      ...state,
      Detail: {
        ...state.Detail,
        ActiveForm: {
          ...state.Detail.ActiveForm,
          Images: handleDeleteImageFailure(state.Detail.ActiveForm.Images, imageId, image),
        }
      },
      processingImages: hasOngoingSequentialRequest(action.rootState) > 1,
    };
  } else if (act.type === EditFacilityAddImageSubmitActions.failureType) {
    // remove thumbnail from gallery if failed - not handled in cache node, because cache node will only get updated when EditFacilityAddImageSubmitActions succeeds
    const newState = {
      ...state,
      processingImages: hasOngoingSequentialRequest(act.rootState) > 1
    };
    newState.Detail.ActiveForm.Images = newState.Detail.ActiveForm.Images ? [...newState.Detail.ActiveForm.Images] : [];

    handleAddImageFailure(newState.Detail.ActiveForm.Images, (act as any).extra.tempId);

    return newState;
  } else if (act.type === ImportBookingTimesSubmitActions.successType || act.type === ImportPricingSubmitActions.successType || act.type === ImportImagesSubmitActions.successType || (isActionType(act, SetCacheAction) && (act.actions.requestType === ImportBookingTimesSubmitActions.requestType || act.actions.requestType === ImportPricingSubmitActions.requestType || act.actions.requestType === ImportImagesSubmitActions.requestType))) {
    const action = <WithRootState<APISuccess>>act;
    const res = action.response.xhr.response;
    const facility = res.FacilitiesFacility as AdminFacilityDetail;
    let initState = getInitialState();
    // set default values
    initState = { ...initState, ...setDefaults(act.rootState, initState, state)};

    if (!facility) return state;

    const newState: FacilityFormState = { ...state };
    if (act.type === ImportBookingTimesSubmitActions.successType) {
      const timeBlocks = (!facility.TimeBlocks || facility.TimeBlocks.length === 0) ? [{ID: null, StartTime: '12:00:00', EndTime: '12:00:00'}] as any : facility.TimeBlocks;
      newState.Detail.ActiveForm = {
        ...newState.Detail.ActiveForm,
        ...initTimeBlocksActiveForm(timeBlocks),
        TimeBlocks: timeBlocks,
        IsAllDay: facility.IsAllDay,
        ShowCheckInOutTimes: facility.ShowCheckInOutTimes,
      };
      newState.Detail.ValidationRules = {
        ...newState.Detail.ValidationRules,
        ...getTimeBlocksValidationRules(timeBlocks),
      };
    }
    if (act.type === ImportPricingSubmitActions.successType) {
      newState.Detail.ActiveForm = {
        ...newState.Detail.ActiveForm,
        GLAccountID: facility.GLAccountID,
        MinPerPersonFeesToBill: facility.MinPerPersonFeesToBill,
        ...initPricingActiveForm(facility.Pricing),
        Pricing: facility.Pricing,
      };
      newState.Detail.ValidationRules = {
        ...newState.Detail.ValidationRules,
        ...getPricingValidationRules(facility.Pricing),
      };
    }

    if (act.type === ImportImagesSubmitActions.successType) {
      newState.Detail.ActiveForm.Images = facility.Images;
    }

    newState.SubmitErrorMessage = undefined;

    return newState;
  } else if (isActionType(act, RemoveTimeBlock)) {
    const timeBlocks = state.Detail.ActiveForm.TimeBlocks || [];
    const newState = { ...state };
    const activeForm = newState.Detail.ActiveForm;
    const index = act.index;

    timeBlocks[index].Inactive = true;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.INACTIVE)] = true;

    return newState;
  } else if (isActionType(act, AddPhoto)) {
    const newState = {...state};

    newState.Detail.ActiveForm.Images = [...newState.Detail.ActiveForm.Images];

    newState.Detail.ActiveForm.Images.push({
      ...constructEmptyPhoto(act.dataUrl),
      uploading: true,
      tempId: act.tempId,
    });

    return newState;
  } else if (act.type === EditFacilityAddImageSubmitActions.requestType) {
    return {
      ...state,
      processingImages: true,
    };
  } else if (act.type === EditFacilityDeleteImageSubmitActions.requestType) {
    const imageId = (act as any).form.row.ID;
    return {
      ...state,
      Detail: {
        ...state.Detail,
        ActiveForm: {
          ...state.Detail.ActiveForm,
          Images: makeImageLoading(state.Detail.ActiveForm.Images, imageId),
        }
      },
      processingImages: true,
    };
  } else if (isActionType(act, CheckAttribute)) {
    let Attributes = state.Detail.ActiveForm.Attributes ? [...state.Detail.ActiveForm.Attributes] : [];
    
    if (Attributes.length === 0) {
      Attributes = [act.attrId];
    } else {
      const index = Attributes.findIndex((a) => a === act.attrId);
  
      if (index === -1) {
        Attributes.push(act.attrId);
      } else {
        Attributes.splice(index, 1);
      }
  
    }
    return {
      ...state,
      Detail: {
        ...state.Detail,
        ActiveForm: {
          ...state.Detail.ActiveForm,
          Attributes,
        },
      },
    };
  } else if (isActionType(act, SilentCancelAllAction) && !act.isDuringNavigation) {
    return {
      ...state,
      processingImages: false,
    };
  } else if (isActionType(act, MoveGalleryItem)) {
    let gallery = state.Detail.ActiveForm.Images;
    const index = gallery.findIndex((item) => item.ID === act.item.ID);
    
    if (act.offset !== 0 && index !== -1) {
      moveItemInArrayWithOffset(gallery, index, act.offset);
    }

    return {
      ...state,
      Detail: {
        ...state.Detail,
        ActiveForm: {
          ...state.Detail.ActiveForm,
          Images: [...gallery],
        },
      },
    };

  } else if (isActionType(act, CopyFromInCouncil)) {
    const newState = {...state};
    const activeForm = newState.Detail.ActiveForm;
    const PricingTierID = act.PricingTierID;
    activeForm[getFacilityPricingAttrKey(PricingTierID, FacilityPricingAttributeKeys.PER_DAY_AMOUNT)] = activeForm[getFacilityPricingAttrKey(1, FacilityPricingAttributeKeys.PER_DAY_AMOUNT)];
    activeForm[getFacilityPricingAttrKey(PricingTierID, FacilityPricingAttributeKeys.PER_DAY_AMOUNT_UPFRONT)] = activeForm[getFacilityPricingAttrKey(1, FacilityPricingAttributeKeys.PER_DAY_AMOUNT_UPFRONT)];
    activeForm[getFacilityPricingAttrKey(PricingTierID, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT)] = activeForm[getFacilityPricingAttrKey(1, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT)];
    activeForm[getFacilityPricingAttrKey(PricingTierID, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT_UPFRONT)] = activeForm[getFacilityPricingAttrKey(1, FacilityPricingAttributeKeys.PER_PERSON_PER_DAY_AMOUNT_UPFRONT)];
    return newState;
  } else if (isActionType(act, AddTimeBLock)) {
    const timeBlocks = [...state.Detail.ActiveForm.TimeBlocks];
    const tb = {ID: null, StartTime: '12:00:00', EndTime: '12:00:00'};
    timeBlocks.push(tb);

    const newState = {
      ...state,
      Detail: {
        ActiveForm: {
          ...state.Detail.ActiveForm,
          TimeBlocks: timeBlocks,
        },
        ValidationRules: {
          ...state.Detail.ValidationRules,
          ...getTimeBlocksValidationRules(timeBlocks),
        },
      },
    };

    const activeForm = newState.Detail.ActiveForm;
    const index = timeBlocks.length - 1;
    const StartTime = getHourMinPeriodFromString(tb.StartTime);
    const EndTime = getHourMinPeriodFromString(tb.EndTime);
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.ID)] = tb.ID;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.START_HOUR)] = StartTime.hour;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.START_MIN)] = StartTime.min;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.START_PERIOD)] = StartTime.period;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.END_HOUR)] = EndTime.hour;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.END_MIN)] = EndTime.min;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.END_PERIOD)] = EndTime.period;
    activeForm[getTimeBlockAttrKey(index, TimeAttributeKeys.INACTIVE)] = false;

    return newState;
  } else if (isActionType(act, ClearAdminFacilityLocationCacheTwoFacility) || isActionType(act, ClearAdminFacilityLocationCacheBelowOne)) {
    return getInitialState();
  } else if (isActionType(act, ClearTempThumbnails)) {
    return {
      ...state,
      Detail: {
        ...state.Detail,
        ActiveForm: {
          ...state.Detail.ActiveForm,
          Images: state.Detail.ActiveForm.Images ? state.Detail.ActiveForm.Images.filter((image) => !image.tempId) : [],
        },
      },
    };
  }

  return state || getInitialState();
};

export default FacilityFormReducer;