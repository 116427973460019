import * as M from "../../../../../constants/messages/generic";
import { SelectValidator, Validator } from "../../../../../utils/validator/models";

export interface IValidator {
  ShowDeleted: Validator;
  FilterText: Validator;
  KindOfClass: SelectValidator;
  RegisterUnder: SelectValidator;
}

export const FormDefinition: IValidator = {
  ShowDeleted: {
    key: 'ShowDeleted',
    isRequired: () => false,
    defaultValue: () => false,
  },
  FilterText: {
    key: 'FilterText',
    isRequired: () => false,
    defaultValue: () => '',
  },
  KindOfClass: {
    key: 'KindOfClass',
    defaultValue: () => 0,
    isRequired: () => true,
    options: {
      values: () => [
        {Name: 'Classes & Options', ID: 0},
        {Name: 'Classes', ID: 1},
        {Name: 'Options', ID: 2},
      ],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
    },
    validatejs: {
      KindOfClass: {
        presence: {message: '^' + M.REQUIRED},
        // NOTE: There's no blank option, so setting numericality to undefined
        numericality: undefined,
      },
    },
  },
  RegisterUnder: {
    key: 'RegisterUnder',
    defaultValue: () => 0,
    isRequired: () => true,
    options: {
      values: () => [
        { Name: 'Groups & Individuals', ID: 0 },
        { Name: 'Groups', ID: 1 },
        { Name: 'Individuals', ID: 2 },
      ],
      valueKey: (v) => v.ID,
      labelKey: 'Name',
    },
    validatejs: {
      RegisterUnder: {
        presence: {message: '^' + M.REQUIRED},
        // NOTE: There's no blank option, so setting numericality to undefined
        numericality: undefined,
      },
    },
  },
};