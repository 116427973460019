import React, { ComponentProps } from "react";

interface LogoProps {
  className?: string;
  width?: string;
  height?: string;
}

// converted from 'ClientApp/images/logo.svg' by https://react-svgr.com/playground/
const Logo: React.SFC<LogoProps> = ({...props}: LogoProps): React.ReactElement<LogoProps> => {
  return (
    <svg viewBox="0 0 284.8 322.3" {...props}>
      <path
        fill="#003F87"
        d="M239.5 267.4c-2-2-4.4-3-7.3-3s-5.3 1-7.3 3-3 4.5-3 7.3c0 2.9 1 5.3 3 7.3s4.4 3 7.3 3c2.9 0 5.3-1 7.3-3s3-4.5 3-7.3-1-5.2-3-7.3zm-.8 13.9c-1.8 1.8-4 2.7-6.5 2.7s-4.7-.9-6.5-2.7c-1.8-1.8-2.7-4-2.7-6.5 0-2.6.9-4.7 2.7-6.5 1.8-1.8 4-2.7 6.5-2.7s4.7.9 6.5 2.7c1.8 1.8 2.7 4 2.7 6.5s-.9 4.7-2.7 6.5z"
      />
      <path
        fill="#003F87"
        d="M238.1 277.9c-.1.8-.2 1.3-.3 1.6-.2.4-.4.6-.8.6-.3 0-.6-.8-1-2.3-.4-1.5-1.4-2.5-2.8-3v-.1c1.2-.1 2.2-.5 3-1 .7-.5 1.1-1.2 1.1-2 0-1-.5-1.7-1.4-2.3-.9-.5-2.1-.8-3.6-.8h-5.9v.7c.8 0 1.3.1 1.6.2.3.1.4.4.4.8v8.8c0 .5-.1.8-.4.9-.3.1-.8.2-1.6.2v.7h6.1v-.9c-.7 0-1.2 0-1.5-.1-.4-.1-.6-.4-.6-.9v-4.1c1.4 0 2.3.1 2.8.5s.7 1.1.8 2.3c.1 1.2.2 1.9.4 2.3.3.6 1 .9 1.9.9 1 0 1.7-.4 2.1-1.1.2-.4.4-1.1.4-2h-.7zm-7.7-3.7v-4c0-.4.1-.7.4-.8.3-.1.7-.2 1.2-.2 1 0 1.7.2 2.1.6.4.4.7 1 .7 1.8 0 1.2-.4 1.9-1.3 2.3-.4.2-1.5.3-3.1.3z"
      />
      <path
        fill="#CE1126"
        d="M134.7 112l11.4-.2-5.7-84.9c-2.2 29.2-3.7 56-5.7 85.1zM33.5 163.2L30 152.9c-1.5 3.5-2.4 7.3-4.4 10.8-3.7.7-7.5-.2-11 .9l8.8 6.2-3.3 11.2c3.5-1.8 6.6-4.6 10.1-6.2 3.1 2.4 6.4 4.8 9.5 6.4-1.3-3.7-2.9-7.5-3.1-11.4 2.9-2.4 6.8-4.2 8.8-6.8-4.2-.1-8.2.3-11.9-.8zM265.8 164.6c-2.2-.7-5.1-.2-7.5-.4-6.6 1.1-5.3-7.7-7.9-11l-4 10.8-11.7.7 9.5 7-3.5 11 9.9-6.6 9.7 6.2-3.3-10.6 8.8-7.1zM112.5 187.2c-.2 5.9 1.3 12.5 3.7 17.8v-15.4c1.3-3-2.2-2.4-3.7-2.4zM120.6 215.2l4.6 5.5v-33.2l-4-.2c-1.3 8.7-.2 17.5-.6 27.9zM129.8 187.2c-.9 11.9 0 24-.4 37.2 0 2.4 3.3 4.2 4.8 4.4.2-13.6.2-27.7 0-41.4l-4.4-.2zM138.6 187.2c-.9 14.5-.7 29.9-.2 44.7 1.5 1.3 3.1.7 4.4-.7l-.2-44h-4zM147.7 187.2c-1.3 11.7.2 28.6-.2 41.1 1.8-.2 3.3-2.4 4.4-3.7l-.2-37.2-4-.2zM155.8 187.4v32.1l.4.9c1.5-2 3.5-4 4-6.4l-.2-26.8c-1.3 0-2.9-.2-4.2.2zM165.5 187.2c-1.1 5.7-.4 11.4-.2 17.6 2.4-4.8 2.9-10.6 3.7-16.1.7-2.4-2.2-1.3-3.5-1.5z"
      />
      <path
        fill="#CE1126"
        d="M25.6 116.4l7.7-4.4c20.9-10.6 48.2-5.3 65.3 8.6 1.3-38.5 25.7-67.5 41.1-100.3l.7-.2c15.4 33.2 40.5 62.5 41.8 101.2l9.5-6.4c17.6-11.2 46-11 62.9 1.8l12.1-.2c-13.4-14.7-32.3-21.1-52.8-17.8-9 .7-17.4 4.6-25.3 7.3-1.3-10.1-5.3-19.1-8.8-28.4-11.7-26.8-29.7-51-39.8-77.6-12.8 36.5-41.1 66.7-48 105.6l-13.6-5.1c-22.7-7.3-49.1-2.2-64.7 15.6 3.9.5 7.9.3 11.9.3zM45.1 198.9c2-9.5 10.6-18.5 19.6-21.1l-8.8-5.9c-11.2 5.1-17.4 16.7-20 27.7-5.9-2.9-11.2-8.1-15.6-13.4-11.5-14.2-15.9-35.9-8.4-52.9l-5.5-6.4c-6.4 10.3-7.7 24-5.3 36.5 4 22.2 23.1 43.6 44.2 51.7-.8-5-1.7-11.2-.2-16.2zM274.4 127.6c-2.4 1.1-4.2 3.7-5.9 5.9 5.9 14.5 4.4 32.3-3.5 45.8-5.1 7.7-12.1 17.2-20.7 20.5-2.2-11-8.6-22.9-20-27.3-2.6 2.2-6.2 3.7-8.1 5.9 3.5.7 6.4 2.9 9.5 4.8 9.7 7.7 11.9 20.5 9.5 32.1 13.4-5.1 26.6-15.8 34.8-28.8 10.8-16.2 13.8-40.9 4.4-58.9zM206.4 237.4c-7 3.3-15.8 1.5-21.8-3.3-2.2 1.5-4.8 2.6-6.2 4.8 4 3.7 8.6 6.4 13.4 7.9 9.7 2.6 18.9-.4 25.7-7h1.3c-.4 7.9-2.4 15.4-6.6 22-5.5 9-14.5 14.7-24.4 15.8-9.2 1.5-16.7-2.6-23.3-8.1-5.7 15-12.8 29.5-24.2 41.8-11.4-12.8-18.3-27.7-24.6-42.2-4.2 4.6-9.2 6.4-14.3 8.1-12.8 2.2-24-3.5-31.7-13.4-4.8-7.3-8.1-15.8-7.5-24.9 4.8 5.1 10.6 7.7 16.9 8.4 9 .4 17.4-2.6 22.9-9.2-2-1.5-4-3.3-6.2-4.2-6.2 5.1-15 6.4-22.2 2.9-8.8-4.8-14.3-14.1-14.1-24.2-3.1 4.4-4.4 9.7-5.7 14.7-2.2 18.7 1.5 37 16.3 49.5 11 9.5 29 12.5 42.2 5.7 7 14.3 15.6 28.6 27.9 39.8 7-6.2 12.3-13.4 17.2-20.5 4-6.4 7.3-13.4 11.2-19.1 4 2 8.1 3.1 12.5 3.7 16.3 1.1 30.6-7.5 38.9-21.1 9.2-15 9.9-37.6.4-52.6.3 10.2-4.3 20.1-14 24.7z"
      />
      <path
        fill="#003F87"
        d="M207.3 120.1c-18.9.2-31.7 16.3-45.3 28.2-1.3.9-3.5 1.1-4.4-.4-2.9-7.9-4-16.7-4.8-25.5l3.1-5.3c-5.5.7-11.2-1.8-17.4-.9-3.7.7-6.6 3.3-8.1 6.6-3.5-.2-7-1.1-10.1.9-.9 1.8-1.5 3.3-1.1 5.5.9 1.3 1.3 4.4 3.5 3.7-.7-.9-.9-2-.9-3.3 3.7-2.4 8.4-.7 12.8-1.5 1.1-.7 3.3-1.5 2.9-3.1-1.5.7-2.9-.4-4-1.1l.4-.7c4.4-.7 10.6-2.4 13.9 1.8.9 7.9 1.8 16.9 3.7 24.6-6.8.7-12.8.2-19.4-.4 1.5-4.8 3.5-9.7 4.4-14.7-2.2-5.1-8.8-3.7-13.2-4.2 1.1 3.1 4.6 4 7.3 5.3-2.4 4.8-3.7 13.6-10.6 13.2-14.7-11.4-25.7-27.9-44.7-28.8H7.5l-.9.4c1.3 3.5 5.1 5.9 8.4 6.6l28.6 2.2.9.7-30.8.7c6.6 10.1 20.9 5.5 31.2 8.1-7.7 1.1-15.8-.2-22.9 1.3 2.2 2.2 4.6 5.3 7.9 6.4l24.6 2.4c-7 .9-14.1.2-21.6.4l-.9.4c7.3 11.4 21.6 5.7 32.3 8.6v.4l-19.6.2-.9.7c7.9 9.5 21.6 5.3 31.7 8.1-6.2.2-13.4.4-18.7.9 7.3 8.1 18.5 7 28.4 8.8-4.6 1.1-8.1.2-12.5.9 3.7 2.4 7.5 4.6 11.4 6.4 4.6 1.1 10.1.7 14.5 2.4-3.3.7-6.2-.4-9 .7l16.1 8.8.9 1.5c-5.3 6.6-13.2 13.9-19.6 20 3.3 6.8 10.6 12.1 16.7 16.1l10.1-24c-7.3-13.4-5.7-31.2-5.3-47.5l32.3-6.8 30.1 6.6 1.5 1.1c0 16.7.9 33.7-6.2 47.5l10.1 23.5c6.4-3.5 13.2-9.5 16.9-15.8l-19.4-19.4-.2-1.5c5.1-4 11.7-6.6 16.5-10.1l-9.2-.4c9.7-1.1 19.4-2.4 26.2-9l-13-.4c10.1-2.2 21.8-.4 28.8-9l-17.8-.9c10.1-2.9 23.5 1.1 30.8-8.4-7-.7-14.5.2-21.3-.9 11-2.9 26.4 2.9 33.2-9l-21.6-.2-.9-.4c10.8-3.1 26.4 2.9 32.6-9l-20.5-.4-.7-.7c9.7-2.6 23.8 2.2 29.3-8.1l-28.8-.2-.9-.9c10.6-1.1 21.6-.9 31.5-3.1 2.4-1.1 4.6-3.5 5.3-6.2l-65.8-.4z"
      />
      <path
        fill="#003F87"
        d="M121.3 245.7l6.6-16.9c-5.5-4.2-8.1-10.1-12.1-14.5l-10.3 24.9c0 2.4 3.1 1.8 3.3 3.7-3.5 6.8-10.3 7.9-17.8 7-2 .9-2.9 3.3-2.4 5.7.4 1.8 2.2 4.2 4 4.2-.2-1.5-1.8-3.7.2-4.8l23.1-.2 1.8.9c.7 1.1-.2 2.4-.2 3.5.9.4 1.1-.7 1.8-.7 1.3-1.8 2.2-4.2 1.3-6.4-1.3-4.4-7.3-.2-9.5-3.5l3.1-4.2c2.2.5 4.6 2.4 7.1 1.3zM171.9 242.7c1.1-1.1 3.3-1.3 3.5-3.3l-10.6-24.6c-3.5 5.1-7.5 10.3-11.9 14.7 2 5.9 4.2 11.9 7.3 17.2l6.6-1.5c.9 1.5 2.6 2.6 3.1 4.4-2 1.5-4.8.4-7.3.9-2.9.7-2.9 4-2.4 6.2.9 1.1 1.8 3.5 3.3 3.1-.2-1.3-1.5-3.5.4-4.4l23.3-.2c2.9.4 1.3 4 1.3 5.3l3.1-3.1c.9-2 .9-4.8-.7-6.4-7.4-.6-16.2-.4-19-8.3zM140.8 236.7c-3.7-.9-6.8-3.1-9.9-5.3-2.2 3.7-4 8.6-4.6 12.5 9 2 19.4 2.9 28.4.2l-4.6-12.3c-3.1 1.8-6 4-9.3 4.9zM169 165.4c-6.2-1.3-12.3-2.9-18.7-4.2-13.2-4.8-25.5 3.1-38.3 4.6-.7 6.2.2 12.3.2 17.6 18.9 0 38.9.2 57-.2l-.2-17.8zm-20.8-2.9l1.2 2.5 2.7.4-2 1.9.5 2.7-2.5-1.3-2.5 1.3.5-2.7-2-1.9 2.7-.4 1.4-2.5zm-32.2 5.8l1.2-2.5 1.2 2.5 2.7.4-2 1.9.5 2.7-2.5-1.3-2.5 1.3.5-2.7-2-1.9 2.9-.4zm3.8 13.8l-2.5-1.3-2.5 1.3.5-2.7-2-1.9 2.7-.4 1.2-2.5 1.2 2.5 2.8.4-2 1.9.6 2.7zm4.4-15.3l1.2-2.5 1.2 2.5 2.8.4-2 1.9.5 2.7-2.5-1.3-2.5 1.3.5-2.7-2-1.9 2.8-.4zm4.7 15.5l-2.5-1.3-2.5 1.3.5-2.7-2-1.9 2.7-.4 1.2-2.5 1.2 2.5 2.8.4-2 1.9.6 2.7zm.3-16.6l2.8-.4 1.2-2.5 1.2 2.5 2.7.4-2 1.9.5 2.7-2.5-1.3-2.5 1.3.5-2.7-1.9-1.9zm9.2 16.3l-2.5-1.3-2.5 1.3.5-2.7-2-1.9 2.7-.4 1.2-2.5 1.2 2.5 2.7.4-2 1.9.7 2.7zm-.4-13l.5-2.7-2-1.9 2.8-.4 1.2-2.5 1.2 2.5 2.7.4-2 1.9.5 2.7-2.5-1.3-2.4 1.3zm9.3 13.3l-2.5-1.3-2.5 1.3.5-2.7-2-1.9 2.7-.4 1.2-2.5 1.2 2.5 2.8.4-2 1.9.6 2.7zm9.1.1l-2.5-1.3-2.5 1.3.5-2.8-2-1.9 2.8-.4 1.2-2.5 1.2 2.5 2.7.4-2 1.9.6 2.8zm-.1-11.4l-2.5 1.3.5-2.7-2-1.9 2.8-.4 1.2-2.5 1.2 2.5 2.8.4-2 1.9.5 2.7-2.5-1.3zm9.8 11.6l-2.5-1.3-2.5 1.3.5-2.8-2-1.9 2.8-.4 1.2-2.5 1.2 2.5 2.8.4-2 1.9.5 2.8zm-.4-11.3l.5 2.7-2.5-1.3-2.5 1.3.5-2.7-2-1.9 2.8-.4 1.2-2.5 1.2 2.5 2.7.4-1.9 1.9z"
      />
    </svg>
  );
};

export default Logo;
