
import {createSelector} from "reselect";

import {EventClassIndividualAvailable, RegisteredClass} from "../../models/class";
import {EventInfoParticipantType} from "../../models/api/cacheThreeEvents";
import {listSelector, makeSingleSelector} from "../../utils/reselectHelper";
import {E_REGISTER, EVENT} from "../../constants/urls";
import {makeNoFilterSelector} from "../../utils";
import {availableFilter, classSort} from "../../utils/classesHelper";
import { EventRegistrationParticipantType, EventRegistrationPersonCustomField } from "../../models/api/cacheFourEvents";
import { reduxStoreService } from "../service";


const customFieldsSelector = listSelector(state => state.cacheFourEventsParticipants.EventRegistrationPersonCustomFields);
const participantTypesSelector = listSelector(state => state.cacheFourEventsParticipants.EventRegistrationParticipantTypes);
const availableClassesSelector = listSelector(state => state.cacheFourEventsParticipants.EventClassesIndividualAvailable);
const registeredClassesSelector = listSelector(state => state.cacheFourEventsParticipants.EventClassesIndividualRegistered);
const filterSelector = state => state.app.searchFilter;
const selectedPTypeSelector = state => state.cacheFourEventsParticipants.EventRegistrationPerson.ParticipantTypeID;

const activeRegisteredFilter = (c: RegisteredClass) => !c.Inactive;

export const makeYouthCustomFieldsSelector = makeNoFilterSelector((p: EventRegistrationPersonCustomField) => !!p.IsYouth, customFieldsSelector);
export const makeAdultsCustomFieldsSelector = makeNoFilterSelector((p: EventRegistrationPersonCustomField) => !p.IsYouth, customFieldsSelector);

export const makeYouthParticipantTypesSelector = makeNoFilterSelector((p: EventRegistrationParticipantType) => !!p.IsYouth, participantTypesSelector);
export const makeAdultParticipantTypesSelector = makeNoFilterSelector((p: EventRegistrationParticipantType) => !p.IsYouth, participantTypesSelector);
export const makeSelectedParticipantTypeSelector = makeSingleSelector(
  (item: EventInfoParticipantType, id: number) => item.ID === id,
  participantTypesSelector,
  selectedPTypeSelector
);

const youthSelector = (state) => state.cacheFourEventsParticipants.isYouth;
const pTypeSelector = (state) => state.events.event.register.participant.type.selectedTypeID;

const createClassesSelector = (selectConflicts: boolean) => {
  return () => createSelector(
    [availableClassesSelector, youthSelector, pTypeSelector],
    (classes: Array<EventClassIndividualAvailable>, isYouth: boolean, pTypeID: number) => {
      const filteredClasses = classes.filter((c: EventClassIndividualAvailable) => {
        if (c.JustYouth === 1 && isYouth !== undefined && !isYouth) return false;
        if (c.JustYouth === 0 && isYouth !== undefined && isYouth) return false;
        const isAddWizard = reduxStoreService().getState().routing.locationBeforeTransitions.pathname.endsWith(`/${EVENT.REGISTER}/${E_REGISTER.PARTICIPANT}`);

        if (isAddWizard && pTypeID && pTypeID !== -1 && c.ParticipantTypes && c.ParticipantTypes.length > 0) {
          const found = c.ParticipantTypes.find((p) => p.ID === pTypeID);
          if (!found) {
            return false;
          }
        } else if (c.ParticipantTypes && c.ParticipantTypes.length === 0) {
          return false;
        }
        // seems names doesn't need available filter because it always returns true
        const rootState = reduxStoreService().getState();
        const conflictCondition = !selectConflicts ? c.conflictStatus === "available" : c.conflictStatus === "conflict";

        return (
          conflictCondition &&
          !!rootState.cacheTwoEvents.EventTypeRegistrationSettings &&
          availableFilter(
            c,
            rootState.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.EnableClassWaitingList,
            isYouth ? 1 : 0,
            isYouth ? 0 : 1,
            true
          )
        );
      });
      filteredClasses.sort(classSort);
      return filteredClasses;
    }
  );
};

const createClassesTextFilterSelector = (selectConflicts: boolean) => {
  return () => createSelector(
    [availableClassesSelector, youthSelector, pTypeSelector, filterSelector],
    (classes: Array<EventClassIndividualAvailable>, isYouth: boolean, pTypeID: number, textFilter) => {
      let f;
      if (textFilter && textFilter !== '') f = textFilter.toLowerCase();
      const filteredClasses = classes.filter((c: EventClassIndividualAvailable) => {
        if (c.JustYouth === 1 && isYouth !== undefined && !isYouth) return false;
        if (c.JustYouth === 0 && isYouth !== undefined && isYouth) return false;
        if (f && !c.Name.toLowerCase().includes(f)) return false;
        const isAddWizard = reduxStoreService().getState().routing.locationBeforeTransitions.pathname.endsWith(`/${EVENT.REGISTER}/${E_REGISTER.PARTICIPANT}`);
        if (isAddWizard && c.ParticipantTypes && c.ParticipantTypes.length > 0) {
          const found = c.ParticipantTypes.find((p) => p.ID === pTypeID);
          if (!found) {
            return false;
          }
        } else if (c.ParticipantTypes && c.ParticipantTypes.length === 0) {
          return false;
        }

        const rootState = reduxStoreService().getState();

        const conflictCondition = !selectConflicts ? c.conflictStatus === "available" : c.conflictStatus === "conflict";
        return (
          conflictCondition &&
          !!rootState.cacheTwoEvents.EventTypeRegistrationSettings &&
          availableFilter(
            c,
            rootState.cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.EnableClassWaitingList,
            isYouth ? 1 : 0,
            isYouth ? 0 : 1,
            true
          )
        );
      });
      filteredClasses.sort(classSort);
      return filteredClasses;
    }
  );
};

export const makeAvailableClassesNoTextFilter = createClassesSelector(false);
export const makeConflictingClassesNoTextFilter = createClassesSelector(true);
export const makeAvailableClassesFilter = createClassesTextFilterSelector(false);
export const makeConflictingClassesFilter = createClassesTextFilterSelector(true);
export const makeRegisteredClassesFilter = makeNoFilterSelector(
  activeRegisteredFilter,
  registeredClassesSelector,
  classSort
);