import { AnyAction, Dispatch } from 'redux';

import { ActionCreator, ApplicationState } from '../../../';
import { AccountsState } from './index';
import {SaveState} from '../../../Rollback/actions';
import {
  createValidateActions, createUpdateValueMethod,
  createSimpleUpdateValueMethod,
  createRequestActions,
  APIRequestActions
} from '../../../Validation/actionCreator';
import { ShowTopFloatingAlert } from '../../../App/actions';
import { validateAll } from '../../../../utils';
import { typeName, Action } from '../../../../utils/StrongActions';
import {shouldBlockActions} from '../../../../utils/cacheLoaders/helpers/blockers';

export const SUFFIX = '__ADMIN_ACCOUNTS';

export const ValidateActions = createValidateActions(SUFFIX);
export const GetParticipantsActions = createRequestActions('__GET_PARTICIPANTS' + SUFFIX);
export const GetRegistrationsActions = createRequestActions('__GET_REGISTRATIONS' + SUFFIX);

export const GetGroupsActions = createRequestActions('__GET_GROUPS' + SUFFIX);
export const GetTripsActions = createRequestActions('__GET_TRIPS' + SUFFIX);
export const GetReservationsActions = createRequestActions('__GET_RESERVATIONS' + SUFFIX);
export const RestoreGroupActions = createRequestActions('__RESTORE_GROUP' + SUFFIX, true);

@typeName("INIT" + SUFFIX)
export class InitAccountsModal extends Action {}
@typeName("FILTER" + SUFFIX)
export class AdminAccountsFilter extends Action {
  constructor(public filterString: string) { super(); }
}

@typeName("SHOW_FILTER_TABS" + SUFFIX)
export class AdminAccountsShowFilterTabs extends Action {
  constructor(public show: boolean) { super(); }
}

@typeName("SELECT_TAB" + SUFFIX)
export class AdminAccountsSelectTab extends Action {
  constructor(public tab: 'groups' | 'registrations' | 'participants' | 'trips' | 'reservations') { super(); }
}

@typeName('RESET_PARTICIPANTS' + SUFFIX)
export class AdminAccountsResetParticipants extends Action {}

@typeName('RESET_REGISTRATIONS' + SUFFIX)
export class AdminAccountsResetRegistrationsTab extends Action {}

@typeName('RESET_TRIPS' + SUFFIX)
export class AdminAccountsResetTripsTab extends Action {}

@typeName('RESET_RESERVATIONS' + SUFFIX)
export class AdminAccountsReservationsTab extends Action {}

const cancelOngoingLoads = (
  rootState: ApplicationState,
  exception: APIRequestActions<any>,
  dispatch: Dispatch<AnyAction>,
) => {
  const actions = [GetGroupsActions, GetRegistrationsActions, GetParticipantsActions, GetTripsActions, GetReservationsActions];

  const retainNodeNames = ['app.openedModals', 'adminAccounts.selectedTab'];

  // If we dont have an initialized form to rollback to, skip rolling back the form state
  if (rootState.rollback.normalSavePoint?.oldState?.adminAccounts.ActiveForm.RegistrationsEventID === undefined) {
    retainNodeNames.push("adminAccounts.ActiveForm");
  }
  actions.forEach((a) => {
    // only perform cancel when the request is ongoing
    if (a.requestType !== exception.requestType && rootState.app.apiLoadingMap[a.requestType]) {
      dispatch(a.cancel({
        retainNodeNames: retainNodeNames,
      }));
    }
  });
};

export const actionCreators = {
  init: (skipSaveState?: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new InitAccountsModal());
    if (!skipSaveState) dispatch(new SaveState);
  },
  filterAccounts: (filterString: string): ActionCreator => dispatch => dispatch(new AdminAccountsFilter(filterString)),
  showFilterTabs: (show: boolean): ActionCreator => dispatch => dispatch(new AdminAccountsShowFilterTabs(show)),
  selectTab: (tab: 'groups' | 'registrations' | 'participants' | 'trips' | 'reservations'): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    if (state.app.apiLoading > 0) {
      if (tab === 'groups') {
        cancelOngoingLoads(getState(), GetGroupsActions, dispatch);
      } else if (tab === 'registrations') {
        cancelOngoingLoads(getState(), GetRegistrationsActions, dispatch);
      } else if (tab === 'participants') {
        cancelOngoingLoads(getState(), GetParticipantsActions, dispatch);
      } else if (tab === 'reservations') {
        cancelOngoingLoads(getState(), GetReservationsActions, dispatch);
      } else if (tab === 'trips') {
        cancelOngoingLoads(getState(), GetTripsActions, dispatch);
      }
    }
    dispatch(new AdminAccountsSelectTab(tab));

    if (tab !== 'groups' || (state.cacheZero.options && state.cacheZero.options.Groups)) {
      dispatch(new SaveState());
    }
  },
  updateValue: createUpdateValueMethod(ValidateActions, {}, (s) => s.adminAccounts, false),
  updateValueAndSaveAfter: createUpdateValueMethod(ValidateActions, {}, (s) => s.adminAccounts, true, undefined, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  getParticipants: (eventID?: number, year?: number, blockError?: boolean): ActionCreator => (dispatch, getState) => {
    const state: AccountsState = getState().adminAccounts;
    const validationResults = validateAll(s => s.adminAccounts);
    if (!validationResults) {
      dispatch(GetParticipantsActions.request({EventTypeID: state.ActiveForm.EventTypeID, Year: state.ActiveForm.Year}));
    } else {
      // If the validation result is invalid, we should cancel any ongoing getparticipants request
      // in case we accidentally display them upon returning from a slow internet
      dispatch(GetParticipantsActions.cancel({skipRestoreState: true}));
      let errorMessage;
      if (validationResults.Errors.EventTypeID) errorMessage = validationResults.Errors.EventTypeID[0];
      else if (validationResults.Errors.Year) errorMessage = validationResults.Errors.Year[0];
      if (!blockError) dispatch(new ShowTopFloatingAlert(errorMessage, true, 'orange'));
      if (validationResults.Errors.EventTypeID) dispatch(new AdminAccountsResetParticipants());
      dispatch(new SaveState());
    }
  },
  getRegistrations: (eventTypeID?: number, year?: number, eventID?: number, blockError?: boolean): ActionCreator => (dispatch, getState) => {
    const state: AccountsState = getState().adminAccounts;
    const validationResults = validateAll(s => s.adminAccounts);
    if (!validationResults) {
      dispatch(GetRegistrationsActions.request({
        EventTypeID: eventTypeID || state.ActiveForm.RegistrationsEventTypeID,
        EventYear: year || state.ActiveForm.RegistrationsYear,
        IncludeFilterOptions: true,
      }));
    } else {
      dispatch(GetRegistrationsActions.cancel({skipRestoreState: true}));
      let errorMessage;
      if (validationResults.Errors.RegistrationsEventTypeID) errorMessage = validationResults.Errors.RegistrationsEventTypeID[0];
      else if (validationResults.Errors.RegistrationsYear) errorMessage = validationResults.Errors.RegistrationsYear[0];
      else if (validationResults.Errors.RegistrationsEventID) errorMessage = validationResults.Errors.RegistrationsEventID[0];
      if (!blockError) dispatch(new ShowTopFloatingAlert(errorMessage, true, 'orange'));
      if (validationResults.Errors.RegistrationsEventTypeID) dispatch(new AdminAccountsResetRegistrationsTab());
      dispatch(new SaveState());
    }
  },
  getTrips: (locationID?: number, year?: number, blockError?: boolean): ActionCreator => (dispatch, getState) => {
    const state: AccountsState = getState().adminAccounts;
    const validationResults = validateAll(s => s.adminAccounts);
    if (!validationResults) {
      dispatch(GetTripsActions.request({
        LocationID: locationID || state.ActiveForm.TripsLocationID,
        TripYear: year || state.ActiveForm.TripsYear,
      }));
    } else {
      dispatch(GetTripsActions.cancel({skipRestoreState: true}));
      let errorMessage;
      if (validationResults.Errors.TripsLocationID) errorMessage = validationResults.Errors.TripsLocationID[0];
      else if (validationResults.Errors.TripsYear) errorMessage = validationResults.Errors.TripsYear[0];
      if (!blockError) dispatch(new ShowTopFloatingAlert(errorMessage, true, 'orange'));
      if (validationResults.Errors.TripsLocationID) dispatch(new AdminAccountsResetTripsTab());
      dispatch(new SaveState());
    }
  },
  getReservations: (locationID?: number, year?: number, facilityTypeID?: number, blockError?: boolean): ActionCreator => (dispatch, getState) => {
    const state: AccountsState = getState().adminAccounts;
    const validationResults = validateAll(s => s.adminAccounts);
    if (!validationResults) {
      dispatch(GetReservationsActions.request({
        LocationID: locationID || state.ActiveForm.ReservationsLocationID,
        TripYear: year || state.ActiveForm.ReservationsYear,
        IncludeFilterOptions: true,
      }));
    } else {
      dispatch(GetReservationsActions.cancel({skipRestoreState: true}));
      let errorMessage;
      if (validationResults.Errors.ReservationsLocationID) errorMessage = validationResults.Errors.ReservationsLocationID[0];
      else if (validationResults.Errors.ReservationsFacilityTypeID) errorMessage = validationResults.Errors.ReservationsFacilityTypeID[0];
      else if (validationResults.Errors.ReservationsYear) errorMessage = validationResults.Errors.ReservationsYear[0];
      
      if (!blockError) dispatch(new ShowTopFloatingAlert(errorMessage, true, 'orange'));
      if (validationResults.Errors.ReservationsLocationID) dispatch(new AdminAccountsReservationsTab());
      dispatch(new SaveState());
    }
  },
  getGroups: (showInactive: boolean | undefined, saveBefore?: boolean): ActionCreator => (dispatch, getState) => {
    if (shouldBlockActions()) return;

    if (saveBefore) dispatch(new SaveState());
    dispatch(GetGroupsActions.request(Boolean(showInactive)));
  },
  restoreGroup: (IDi: number): ActionCreator => dispatch => {
    dispatch(RestoreGroupActions.request(IDi));
  }
};
