import { PageTypeIDValue } from "../../../../models/api/adminCMSCacheOne";
import { IValidator } from "./index.types";
import { FormDefinition as SelectPageModalFormDefinition } from '../Modals/SelectPage/validation';


export const FormDefinition: IValidator = {
  ShowDeleted: {
    key: 'ShowDeleted',
    isRequired: () => false,
    defaultValue: () => false,
  },
  FilterText: {
    key: 'FilterText',
    isRequired: () => false,
    defaultValue: () => '',
  },
  IncludePastEvents: { ...SelectPageModalFormDefinition.IncludePastEvents },
  IncludeExpiredBlogArticles: { ...SelectPageModalFormDefinition.IncludeExpiredBlogArticles },
  PageTypeID: { ...SelectPageModalFormDefinition.PageTypeID},
  EventCategoryID: { ...SelectPageModalFormDefinition.EventCategoryID },
  EventStartDate: {
    ... SelectPageModalFormDefinition.EventStartDate,
    skip: (rootState) => {
      return rootState.adminCMSSite.pages.home.ActiveForm.PageTypeID !== PageTypeIDValue.CALENDAR_EVENT;
    },
    isRequired: (state) => {

      return state.adminCMSSite.pages.home.ActiveForm.PageTypeID === PageTypeIDValue.CALENDAR_EVENT;
    },
  },
};