import {createApiValidateActions, createValidateActions, createApiSubmitActions, createUpdateValueMethod, createSimpleUpdateValueMethod, createRequestActions, getCacheLevelExtra} from '../../../../Validation/actionCreator';
import { ActionCreator, ApplicationState } from "../../../../../store";
import { ImportFromEventType } from "../../../../../components/Pages/AdminEvents/Events/Modals/ImportFromEvent";
import { SaveState } from "../../../../Rollback/actions";
import { getUpdateAdminEventImportBody, getUpdateAdminEventImportClassBody } from "../../../../../constants/adminEventsUrls";
import { ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX, UPDATE_EVENT_FORM_SUFFIX } from "../../../CacheTwoEvent/actions";
import { typeName, Action } from '../../../../../utils/StrongActions';

export const SUFFIX = '__IMPORT_FROM_EVENT_FORM__MODAL';

export const GetEventsList = createRequestActions('GET_EVENTS_LIST' + SUFFIX);
export const GetSourceClassesList = createRequestActions('GET_SOURCE_CLASSES_LIST' + SUFFIX);
export const UpdateEventImportPricingSubmitActions = createApiSubmitActions(SUFFIX + '__IMPORT_PRICING');
export const UpdateEventImportTimeBlocksSubmitActions = createApiSubmitActions(SUFFIX + '__IMPORT_TIMEBLOCKS' + ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX + UPDATE_EVENT_FORM_SUFFIX);
export const UpdateEventImportClassesSubmitActions = createApiSubmitActions(SUFFIX + '__IMPORT_CLASSES' + ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX + UPDATE_EVENT_FORM_SUFFIX);

@typeName("INIT" + SUFFIX)
export class InitImportFromEventModal extends Action { constructor(public importType: ImportFromEventType) { super(); } }

@typeName("RESET" + SUFFIX) export class ResetImportFromEventModal extends Action {}

@typeName("RESET_STEP_TWO" + SUFFIX) export class ResetStepTwo extends Action {}

@typeName("RESET_STEP_THREE" + SUFFIX) export class ResetStepThree extends Action {}

@typeName("FILTER_EVENT" + SUFFIX) export class FilterEvent extends Action {
  constructor(public filterText: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class ImportFromEventToggleFilter extends Action {
  constructor(public searching: boolean) { super(); }
}

@typeName("UPDATE_FINAL_EVENT_TYPE" + SUFFIX)
export class UpdateFinalEventTypeID extends Action {
  constructor(public id: number) { super(); }
}

@typeName("TOGGLE_CLASS_SELECT" + SUFFIX)
export class ToggleClassSelect extends Action {
  constructor(public show: boolean, public classId: number) { super(); }
}
@typeName("SELECT_ALL" + SUFFIX)
export class SelectAll extends Action { constructor(public classIds: number[]) { super(); }}
@typeName("DESELECT" + SUFFIX)
export class Deselect extends Action { constructor(public classIds: number[]) { super(); }}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.events.modals.importFromEvent, true, undefined, true),
  updateValueWithoutSave: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.events.modals.importFromEvent, false),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  filterEvent: (filterText: string): ActionCreator => dispatch => dispatch(new FilterEvent(filterText)),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new ImportFromEventToggleFilter(searching)),
  reset: (): ActionCreator => dispatch => dispatch(new ResetImportFromEventModal()),
  init: (importType: ImportFromEventType): ActionCreator => (dispatch, getState) => {
    dispatch(new InitImportFromEventModal(importType));
    dispatch(new SaveState());
  },
  getEventsList: (EventTypeID: number): ActionCreator => dispatch => {
    dispatch(GetEventsList.request({EventTypeID}));
  },
  getClassesList: (SourceEventTypeID: number, SourceEventIDi): ActionCreator => dispatch => {
    dispatch(new SaveState());
    dispatch(GetSourceClassesList.request({SourceEventTypeID, SourceEventIDi}));
  },
  toggleClassSelect: (show: boolean, classId: number): ActionCreator => dispatch => dispatch(new ToggleClassSelect(show, classId)),
  selectAll: (classIds: number[]): ActionCreator => dispatch => dispatch(new SelectAll(classIds)),
  deselect: (classIds: number[]): ActionCreator => dispatch => dispatch(new Deselect(classIds)),
  resetStep2: (): ActionCreator => dispatch => dispatch(new ResetStepTwo()),
  resetStep3: (): ActionCreator => dispatch => dispatch(new ResetStepThree()),
  importPricing: (FromIDi: number): ActionCreator => dispatch => {
    const form = getUpdateAdminEventImportBody(FromIDi);
    dispatch(new ResetImportFromEventModal());
    // We close the modal in `ImportFromEvent/index.tsx` already
    dispatch(UpdateEventImportPricingSubmitActions.request(form, getCacheLevelExtra(false, true)));
  },
  importTimeBlocks: (FromIDi: number): ActionCreator => dispatch => {
    const form = getUpdateAdminEventImportBody(FromIDi);
    dispatch(new ResetImportFromEventModal());
    // We close the modal in `ImportFromEvent/index.tsx` already
    dispatch(UpdateEventImportTimeBlocksSubmitActions.request(form, getCacheLevelExtra(false, true)));
  },
  importClasses: (): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;

    const form = getUpdateAdminEventImportClassBody({
      SourceEventIDi: state.adminEvents.events.modals.importFromEvent.ActiveForm.SelectedEvent || 0,
      SourceEventTypeID: state.adminEvents.events.modals.importFromEvent.ActiveForm.SelectedEventType_Final || 0,
      ClassesCSV: (state.adminEvents.events.modals.importFromEvent.ActiveForm.SelectedClassIds || []).join(','),
    });
    dispatch(new ResetImportFromEventModal());
    // We close the modal in `ImportFromEvent/index.tsx` already
    dispatch(UpdateEventImportClassesSubmitActions.request(form, getCacheLevelExtra(false, true)));
  }
};