import * as React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/slide-show-modal/index.scss';
import { whichAnimationEvent } from '../../../utils';
import { ModalProviderInterface } from '../../Modal/ModalProvider';
import CloseIcon from '../../../images/elements/slide-show-modal/close.svg';
import {Nullable} from '../../../utils/dataHelper';

export const namespace = (): string => 'elements--slide-show-modal';

interface ModalProps {
  children?: React.ReactNode;
  className?: string;
  onClose?: Function;
}

type ModalContext = {
  provider: ModalProviderInterface;
}

type SlideShowModalState = {
  closing?: boolean;
  entering?: boolean;
}

class Modal extends React.Component<ModalProps, SlideShowModalState> {
  public static contextTypes = {
    provider: PropTypes.object
  };

  public context: ModalContext;
  public props: ModalProps;
  public state: SlideShowModalState = {
    closing: false,
    entering: false,
  };

  public componentWillMount() {
    this.context.provider.onClose(this.close);
  }

  public componentDidMount() {
    const element = (findDOMNode(this) as Nullable<Element>)?.getElementsByTagName('section')[0];
    const handleAnimationEnd = () => {
      const whichAni = whichAnimationEvent();
      if (whichAni) element?.removeEventListener(whichAni, handleAnimationEnd);
      this.setState({ entering: false });
    };
    const whichAni = whichAnimationEvent();
    if (whichAni) element?.addEventListener(whichAni, handleAnimationEnd);
    this.setState({ entering: true });
  }

  public shouldComponentUpdate() {
    if (this.state.closing) return false;
    return true;
  }

  public handleBackdropClick = () => {
    this.context.provider.closeModal();
  };

  public close = (callback: Function) => {
    const element = (findDOMNode(this) as Nullable<Element>)?.getElementsByTagName('section')[0];
    const handleAnimationEnd = () => {
      const whichAni = whichAnimationEvent();
      if (whichAni) element?.removeEventListener(whichAni, handleAnimationEnd);
      callback();
    };
    const whichAni = whichAnimationEvent();
    if (whichAni) element?.addEventListener(whichAni, handleAnimationEnd);
    this.setState({ closing: true });
  };

  public render() {
    const { children } = this.props;
    const { entering, closing } = this.state;

    let backdropClassName: string = `${namespace()}--backdrop`;
    let windowClassName: string = `${namespace()}--window`;
    if (entering) {
      backdropClassName += ' enter';
      windowClassName += ' enter';
    }
    if (closing) {
      backdropClassName += ' leave';
      windowClassName += ' leave';
    }

    return (
      <div className={mergeClassNames(namespace(), this.props)}>
        <div onClick={this.handleBackdropClick} className={backdropClassName}>
          <a onClick={this.handleBackdropClick} className={`${namespace()}--close`}>
            <CloseIcon className={`${namespace()}--close--icon`}/>
          </a>
        </div>
        <section className={windowClassName}>
          <div className={`${namespace()}--window--content`}>
            {children}
          </div>
        </section>
      </div>
    );
  }
}

export default Modal;
