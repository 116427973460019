import { MultipleFacilitiesToggleFilterTabs, MultipleFacilitiesModalFilter, ResetMultipleFacilitiesModal, ToggleFacilitySelect, SelectAll, Deselect, InitMultipleFacilitiesModal } from './actions';
import { IValidator, FormDefinition } from './validation';
import { apiValidationReducerCreator } from '../../../../../store/Validation/reducerCreator';
import { SUFFIX } from './actions';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import {setDefaults} from '../../../../../utils/validator';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface MultipleFacilitiesModalActiveFormState {
  SelectedFacilityTypeID?: number;
  SelectedFacilityIds: number[];
}

export interface MultipleFacilitiesModalState {
  isRefreshing: boolean;
  filterString: string;
  isTextSearching: boolean;
  ValidationRules: IValidator;
  ActiveForm: MultipleFacilitiesModalActiveFormState;
}
const getInitialState = () => ({
  isRefreshing: false,
  filterString: '',
  isTextSearching: false,
  ValidationRules: {...FormDefinition},
  ActiveForm: {
    SelectedFacilityIds: [],
  },
});

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const MultipleFacilitiesModalReducer: Reducer<MultipleFacilitiesModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);
  if (isActionType(act, MultipleFacilitiesToggleFilterTabs)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, InitMultipleFacilitiesModal)) {
    // First init with default values
    const stateWithDefaultValue = setDefaults(act.rootState, state);

    // Then override with passed in data
    return {
      ...stateWithDefaultValue,
      ActiveForm: {
        ...stateWithDefaultValue.ActiveForm,
        SelectedFacilityIds: act.facilityIds,
        SelectedFacilityTypeID: act.facilityTypeId || 0,
      }
    };
  } else if (isActionType(act, MultipleFacilitiesModalFilter)) {
    return {
      ...state,
      filterString: act.filterString,
    };
  } else if (isActionType(act, ToggleFacilitySelect)) {
    const facilityIds = [...state.ActiveForm.SelectedFacilityIds];
    if (act.show) {
      const existed = facilityIds.find((id) => id === act.facilityId);
      if (!existed) {
        facilityIds.push(act.facilityId);
      }
    } else {
      const index = facilityIds.findIndex((id) => id === act.facilityId);
      if (index !== -1) {
        facilityIds.splice(index, 1);
      }
    }
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedFacilityIds: facilityIds,
      },
    };
  } else if (isActionType(act, SelectAll)) {
    const facilityIds = [...state.ActiveForm.SelectedFacilityIds];

    act.facilityIds.forEach((id) => {
      const existed = facilityIds.find((fId) => fId === id);
      if (!existed) {
        facilityIds.push(id);
      }
    });
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedFacilityIds: facilityIds,
      },
    };
  } else if (isActionType(act, Deselect)) {
    const facilityIds = [...state.ActiveForm.SelectedFacilityIds];
    const newFacilityIds: number[] = [];

    facilityIds.forEach((id, index) => {
      const existed = act.facilityIds.find((fId) => fId === id);
      if (!existed && id !== -1) {
        newFacilityIds.push(id);
      }
    });

    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedFacilityIds: newFacilityIds,
      },
    };
  } else if (isActionType(act, ResetMultipleFacilitiesModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default MultipleFacilitiesModalReducer;