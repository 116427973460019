import { Validator } from '../../../../../utils/validator/models';

export interface IValidator {
  ShowDeleted: Validator;
}

export const FormDefinition: IValidator = {
  ShowDeleted: {
    key: 'ShowDeleted',
  }
};