import * as React from 'react';

export interface OptionProps {
  value?: string | number;
}

const Option:React.SFC<OptionProps> = (props:OptionProps):React.ReactElement<OptionProps> =>
  <option {...props}/>;

export default Option;
