import { AdminCMSCacheTwoResourceContext } from "@tentaroo/shared";

import { ToggleNotFound } from "../../store/App/actions";
import { SaveState } from "../../store/Rollback/actions";
import { extractRouteParams, isIdFromUrlValid, isIdValid } from "../urlHelper";
import { GetResource } from "../../store/AdminCMSSite/CacheTwoResources/actions";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import type AdminCMSCacheManager from "../cacheManagers/adminCMSCacheManager";
import { reduxStoreService } from "../../store/service";
import { LoadAdminCMSCacheTwoResourceParams } from "./helpers/models";
import {isAdminCMSCacheTwoResourcePopulated} from "../cachePopulatedCheckers/adminCMS";

export const ensureCacheTwoResource = (
  params: LoadAdminCMSCacheTwoResourceParams & {
    cacheManager: AdminCMSCacheManager;
    resourceId: number;
  },
): boolean => {
  const {props, isEdit, context, cacheManager, isStateNavigated, resourceId} = params;
  
  if (!isIdFromUrlValid(props.params.siteId)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const rootState = reduxStoreService().getState();
  const adminCMSCacheTwoResources = rootState.adminCMSSite.cacheTwoResources;

  let requestedData = false;
  if (isEdit) {
    if (!isIdValid(resourceId)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }

    if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminCMSCacheTwoResourcePopulated(adminCMSCacheTwoResources)) {
      if (isStateNavigated) {
        if (context === AdminCMSCacheTwoResourceContext.RESOURCE_FORM) {
          reduxStoreService().dispatch(GetResource.updateForm());
        }
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      const params = {
        ID: resourceId,
        ...extractRouteParams(props),
      };
      reduxStoreService().dispatch(GetResource.request(params, {skipUpdateForm: context !== AdminCMSCacheTwoResourceContext.RESOURCE_FORM}));

      requestedData = true;
    }
  } else if (isStateNavigated) {
    requestedData = cacheManager.loadAdminCMSCacheOne({
      props,
      isStateNavigated,
    });

    if (!requestedData) {
      reduxStoreService().dispatch(GetResource.updateForm());
      reduxStoreService().dispatch(new SaveState());
    }
  }

  return requestedData;
};