import * as React from 'react';
import { namespace as ns } from './constants';
import LoaderElement from '../Loader';

const namespace = (): string => `${ns()}--marker`;

const Loader:React.SFC<{}> = ():React.ReactElement<{}> =>
  <i className={`${namespace()} ${namespace()}--loader`}>
    <LoaderElement size="18px"/>
  </i>;

export default Loader;
