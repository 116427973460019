import * as React from 'react';
import '../../../styles/elements/shadow/index.scss';

interface ShadowProps extends React.ClassAttributes<any> {
  children?: React.ReactNode;
  shadow?: number;
  mobile?: number;
  tablet?: number;
  className?: string;
  component?: React.ReactElement<any> | Function;
  onClick?: (e) => void;
}

export const namespace = 'elements--shadow';

const shadowClassName = (prefix: string, depth: number): string => {
  let dp:string = '0';
  switch (depth) {
    case 0: dp = '2'; break;
    case 1: dp = '3'; break;
    case 2: dp = '4'; break;
    case 3: dp = '6'; break;
    case 4: dp = '8'; break;
    case 5: dp = '16'; break;
    case 6: dp = '24'; break;
  }
  return `${prefix}${dp}dp`;
};

const Shadow: React.SFC<ShadowProps> = (props: ShadowProps): React.ReactElement<ShadowProps> => {
  let { children, component, shadow, mobile, tablet, onClick } = props;

  let className:string = '';
  if (mobile !== undefined && mobile !== null) className += shadowClassName(' mobile-', mobile);
  if (tablet !== undefined && tablet !== null) className += shadowClassName(' tablet-', tablet);

  if ((mobile === undefined || mobile === null) && (tablet === undefined || tablet === null) && shadow) {
    className = shadowClassName(`mdl-shadow--`, shadow);
  }

  if (component) {
    if (component instanceof Function) {
      return component({
        ...props,
        className: (props.className ? props.className + ' ' : '') + className
      }, children);
    } else {
      return React.cloneElement(component, {
        ...component.props,
        onClick: props.onClick,
        className: (props.className ? props.className + ' ' : '') + className + (component.props.className ? ` ${component.props.className}` : '')
      }, children);
    }
  }

  return (
    <div onClick={onClick} className={(props.className ? props.className + ' ' : '') + className}>
      {children}
    </div>
  );
};

Shadow.defaultProps = {
  shadow: 0
};

export default Shadow;
