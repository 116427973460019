import * as React from 'react';
import {Button, Column, Modal, ModalActions, ModalContent, ModalTitle, Row, TextField} from '../../../../Elements';
import {
  actionCreators as profileActionCreators,
} from "../../../../../store/Settings/Profile/actions";
import {actionCreators} from "../../../../../store/Settings/EditEmail/actions";
import {appActionCreators, ApplicationState} from "../../../../../store";
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { ModalTypes } from '../../../../../utils/modalHelper';
import {WithInertAttribute} from '../../../../Elements/WithInert';

type Props = WithInertAttribute<{}>

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class EditEmail extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  onCloseClick = () => {
    this.props.actions.popModal(false, true, ModalTypes.EDIT_EMAIL);
  };

  onSave = () => {
    this.props.actions.editEmailApiSubmitForm();
  };

  componentWillUnmount() {
  }

  public render() {
    const {editEmail: {ActiveForm, ValidationRules}, inert, apiSaving, actions} = this.props;
    return (
      <Modal inert={inert} disableDynamicHeight big>
        <ModalTitle loading={apiSaving > 0} marginBottom={0}>Edit Email</ModalTitle>
        <ModalContent paddingBottom={0} paddingTop={0}>
          <Row>
            <Column span={6} mobileSpan={12}>
              <TextField value={ActiveForm.Email}
                         label="New Email"
                         onBlur={actions.editEmailUpdateValue}
                         onChange={actions.editEmailSimpleUpdate}
                         validationRules={ValidationRules.Email}
                         hideError
              />
            </Column>
            <Column span={6} mobileSpan={12}>
              <TextField value={ActiveForm.ConfirmEmail}
                         label="Confirm Email"
                         onBlur={actions.editEmailUpdateValue}
                         onChange={actions.editEmailSimpleUpdate}
                         validationRules={ValidationRules.ConfirmEmail}
                         hideError
              />
            </Column>
          </Row>
        </ModalContent>
        <ModalActions
          loading={apiSaving > 0}
          noPadding
          borderTop
          left={<Button id={generateDOMId("close-edit-email-modal")} flat onClick={this.onCloseClick}>Cancel</Button>}
          right={<Button flat onClick={this.onSave}>Save changes</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    editEmail: state.settings.editEmail,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...appActionCreators, ...profileActionCreators, ...actionCreators }, dispatch)
});

const ConnectedEditEmail = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(EditEmail);

export default ConnectedEditEmail;
