import * as React from 'react';
import { connect } from 'react-redux';
import {withRouter, RouteComponentProps} from "react-router";
import {bindActionCreators} from "redux";

import {
  Modal, ModalTitle, ModalActions, Button, ModalContent, Text } from '../../../../../Elements';
import {actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import {actionCreators as classFormActionCreators} from "../../../../../../store/AdminEvents/Classes/Form/actions";
import {ApplicationState} from "../../../../../../store";
import '../../../../../../styles/pages/admin-events/events/modals/delete-class/index.scss';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

const namespace = () => 'pages--admin-events--modals--delete-class';
type Props = WithInertAttribute<{}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{},{}>;

@(withRouter as any)
class DeleteAdminEventClassModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  
  onCancel = (e?: any) => {
    if (e) e.stopPropagation();
    this.props.actions.popModal(false, true, ModalTypes.DELETE_ADMIN_EVENTS_CLASS);
  };

  onDelete = () => {
    const {actions, router, classId, routes} = this.props;

    if (classId === undefined) return;

    actions.deleteAdminEventClass(classId, router, routes, true);
  };

  public render() {
    const {apiSaving, inert, apiLoading} = this.props;

    return (
      <Modal inert={inert} className={`${namespace()}`} disableDynamicHeight bodyScrollLock big higherZIndex>
        <ModalTitle marginBottom={8}>{`Delete Class?`}</ModalTitle>
        <ModalContent paddingTop={0}>
          <Text color="gray">Deleting this class will remove all registrations for this class and credit end users as appropriate for any class fees paid. Although the class can be restored, any registrations for this class cannot be restored.</Text>
        </ModalContent>
        <ModalActions
          noPadding
          loading={apiSaving > 0 || apiLoading > 0}
          stacked
          alignRight
          left={<Button flat textColor="blue" id={generateDOMId("cancel-delete-class")} onClick={this.onCancel}>CANCEL</Button>}
          right={<Button textColor="red" flat onClick={this.onDelete}>DELETE</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const classIdSelector = makeFormModalPropSelector(ModalTypes.DELETE_ADMIN_EVENTS_CLASS, 'id');
  return {
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
    classId: classIdSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...appActionCreators,
  ...classFormActionCreators,
}, dispatch)});

const ConnectedDeleteAdminEventClassModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(DeleteAdminEventClassModal);

export default ConnectedDeleteAdminEventClassModal;
