import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { namespace as ns } from '../constants';
import '../../../../styles/elements/side-bar/title/index.scss';

export const namespace = (): string => `${ns()}--title`;

export interface TitleProps {
  children?: React.ReactNode;
  className?: string;
  admin?: boolean;
}

const Title: React.SFC<TitleProps> = (props: TitleProps): React.ReactElement<TitleProps> => (
  <h1 className={mergeClassNames(namespace(), props) + (props.admin ? ' admin' : '')}>
    {props.children}
  </h1>
);

export default Title;
