import * as React from 'react';
import { ContextMenu, ContextMenuItem } from '../..';
import { generateDOMId } from '../../../../utils/cypressHelper';
import { Props as ContextMenuProps } from '../../ContextMenu/index';

interface Props {
  onOpenEventType: (e) => void;
  onEdit: (e) => void;
  onDuplicate: (e) => void;
  onDelete: (e) => void;
  eventTypeId?: string | number;
}

const ContextMenuComponent: React.SFC<ContextMenuProps & Props> = (props: ContextMenuProps & Props): React.ReactElement<ContextMenuProps & Props> => (
  <ContextMenu
    {...props}
    consistentInMobile
    title=""
  >
    <ContextMenuItem admin onClick={props.onOpenEventType}>Open</ContextMenuItem>
    <ContextMenuItem admin onClick={props.onEdit}>Edit</ContextMenuItem>
    <ContextMenuItem id={generateDOMId(`admin-eventtype-${props.eventTypeId}-duplicate-btn`)} admin onClick={props.onDuplicate}>Duplicate</ContextMenuItem>
    <ContextMenuItem admin onClick={props.onDelete}>Delete</ContextMenuItem>
  </ContextMenu>
);

export default ContextMenuComponent;
