import { createValidateActions, createUpdateValueMethod,
  createSimpleUpdateValueMethod, createApiSubmitActions, createApiSubmitFormMethod } from '../Validation/actionCreator';

export const SUFFIX = '__LOGIN_PAGE';
export const ValidateActions = createValidateActions(SUFFIX);
export const LoginActions = createApiSubmitActions(SUFFIX);

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, undefined, (s) => s.login),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  apiRequestLogin: createApiSubmitFormMethod(LoginActions, (s) => s.login),
};
