import {apiValidationReducerCreator} from "../../../Validation/reducerCreator";
import {
  FacFSSubmitActions,
  SUFFIX,
  TripSummaryFinanceDirty,
  TripSummaryOtherNotRecentlySelected,
  TripSummaryReset,
  TripSummarySaveFinance,
  TripSummarySelectFinance,
  ValidateActions
} from "./actions";
import {FormDefinition} from "./validation";
import {SetCacheAction} from "../../../App/actions";
import {GetFacilityTripData} from "../../../CacheThreeFacilities/actions";
import {FinancialSummaryInit, initFinancialSummary} from "../../../../utils/classesHelper";
import {APISuccess, UpdateCurrValue, WithRootState} from "../../../Validation/actionCreator";
import {FacilityTrip, TripReservation} from "../../../../models/api/cacheThreeFacilities";
import {FacilitiesSave} from "../../../CacheFourFacilities/actions";
import {EmptyCartActions, RemoveItemFromCartActions} from "../../../Cart/actions";
import { AnyAction, Reducer } from "redux";
import { Action, isActionType } from "../../../../utils/StrongActions";

export interface FinanceForm {
  selectedFinance?: 'min' | 'full' | 'other' | 'credit';
  otherValue?: number;
}

export interface TripSummaryState {
  isFinanceDirty?: boolean;
  isOtherRecentlySelected?: boolean;
  ActiveForm: FinanceForm,
  ValidationRules: any;
  SubmitErrorMessage?: string;
  FacilityTrip?: FacilityTrip; // as is from API
  restoringReservation?: TripReservation;
}
const checkApiValidation = apiValidationReducerCreator(SUFFIX);
// @todo: api success here needs to modify ValidationRules

const getInitialState = (): TripSummaryState => {
  return {
    ActiveForm: {
      selectedFinance: 'min',
      otherValue: 0
    }, ValidationRules: {...FormDefinition}};
};

const TripSummary: Reducer<TripSummaryState> = (oldState, action: WithRootState<Action>) => {
  const state = checkApiValidation(oldState, action);
  if (
    action.type === GetFacilityTripData.successType ||  action.type === FacFSSubmitActions.successType ||
    action.type === FacilitiesSave.successType || action.type === RemoveItemFromCartActions.successType ||
    action.type === EmptyCartActions.successType ||
    isActionType(action, SetCacheAction)
  ) {
    let FacilityTrip;
    if (isActionType(action, SetCacheAction)) {
      FacilityTrip = action.response.xhr.response.FacilityTrip;
    } else {
      const castedAction = <WithRootState<APISuccess>> action;
      FacilityTrip = castedAction.response.response.FacilityTrip;
    }
    const castedAction = <WithRootState<APISuccess>> action;
    if (!FacilityTrip) return state;
    const vals: FinancialSummaryInit = initFinancialSummary(
      action.rootState,
      FacilityTrip,
      (rootState) => rootState.facilities.trip.summary.ActiveForm.selectedFinance === 'other',
      false,
    );

    return {
      ...state,
      ActiveForm: {selectedFinance: vals.paymentType, otherValue: vals.otherDefaultValue},
      ValidationRules: {...state.ValidationRules, otherValue: vals.otherValueRules},
      FacilityTrip: FacilityTrip,
      isFinanceDirty: false
    };
  } else if (isActionType(action, TripSummarySelectFinance)) {
    if (action.option !== 'other') return {...state, ActiveForm: {...state.ActiveForm, selectedFinance: action.option}};
    return {...state, ActiveForm: {...state.ActiveForm, selectedFinance: action.option}, isFinanceDirty: true};
  } else if (isActionType(action, TripSummarySaveFinance)) {
    // @todo: should be a rest call
    // @todo: check if valid?
    return {...state, ActiveForm: {...state.ActiveForm, otherValue: action.value}, isFinanceDirty: false};
  } else if (action.type === ValidateActions.updateCurrType) {
    const castedAction = <WithRootState<UpdateCurrValue>> action;
    if (castedAction.vObj.key === 'selectedFinance') {
      if (castedAction.value === 'other') {
        return {...state, isFinanceDirty: false, isOtherRecentlySelected: true};
      }
    } else if (castedAction.vObj.key === 'otherValue') {
      return {...state, isFinanceDirty: true};
    }
    return state;
  } else if (isActionType(action, TripSummaryOtherNotRecentlySelected)) {
    return {...state, isOtherRecentlySelected: false};
  } else if (isActionType(action, TripSummaryFinanceDirty)) {
    return {...state, isFinanceDirty: true};
  } else if (isActionType(action, TripSummaryReset)) {
    if (!state.FacilityTrip) {
      return {
        ...state,
        isFinanceDirty: undefined,
        isOtherRecentlySelected: undefined
      };
    }
    const vals: FinancialSummaryInit = initFinancialSummary(
      (action as WithRootState<AnyAction>).rootState,
      state.FacilityTrip,
      (rootState) => rootState.facilities.trip.summary.ActiveForm.selectedFinance === 'other',
      false,
    );

    return {
      ...state,
      ActiveForm: {selectedFinance: vals.paymentType, otherValue: vals.otherDefaultValue},
      ValidationRules: {...state.ValidationRules, otherValue: vals.otherValueRules},
      isFinanceDirty: undefined,
      isOtherRecentlySelected: undefined
    };
  }
  // @todo: the min value in the FormDefinition needs to be based off the api
  return state || { ...getInitialState()};
};

export default TripSummary;
