import { createSelector } from 'reselect';
import { SelectClassTypeToggleFilterTabs, SelectClassTypeModalFilter, ResetSelectClassTypeModal, ToggleClassTypeSelect, SelectAll, Deselect, InitSelectClassTypeModal } from './actions';
import { ApplicationState } from '../../../..';
import { IValidator, FormDefinition } from './validation';
import { apiValidationReducerCreator } from '../../../../Validation/reducerCreator';
import { SUFFIX } from './actions';
import { filteredClassTypesCombiner } from '../../Event/ClassTypes';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import { allClassTypesSelector } from '../../Event/Classes';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface SelectClassTypeModalActiveFormState {
  SelectedClassTypeIds: number[];
}

export type SelectClassTypeModalType = 'single' | 'multiple';

export interface SelectClassTypeModalState {
  isRefreshing: boolean;
  filterString: string;
  isTextSearching: boolean;
  ValidationRules: IValidator;
  ActiveForm: SelectClassTypeModalActiveFormState;
}
const getInitialState = () => ({
  isRefreshing: false,
  filterString: '',
  isTextSearching: false,
  ValidationRules: {...FormDefinition},
  ActiveForm: {
    SelectedClassTypeIds: [],
  },
});

const filterSelector = (state: ApplicationState) => state.adminEvents.events.modals.selectClassType.filterString;
const showDeletedSelector = (state: ApplicationState) => false;

const selectedClassTypeIdsSelector = (state: ApplicationState) => state.adminEvents.events.modals.selectClassType.ActiveForm.SelectedClassTypeIds;

export const makeFilteredClassTypesSelector = () => {
  return createSelector(
    [allClassTypesSelector, filterSelector, showDeletedSelector, selectedClassTypeIdsSelector],
    filteredClassTypesCombiner,
  );
};

export const makeActiveClassTypesSelector = () => {
  return createSelector(
    [allClassTypesSelector, (state: ApplicationState) => "", showDeletedSelector, selectedClassTypeIdsSelector],
    filteredClassTypesCombiner,
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const SelectClassTypeModalReducer: Reducer<SelectClassTypeModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);
  if (isActionType(act, SelectClassTypeToggleFilterTabs)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, InitSelectClassTypeModal)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedClassTypeIds: act.classTypeIds,
      },
    };
  } else if (isActionType(act, SelectClassTypeModalFilter)) {
    return {
      ...state,
      filterString: act.filterString,
    };
  } else if (isActionType(act, ToggleClassTypeSelect)) {
    const classTypeIds = state.ActiveForm.SelectedClassTypeIds ? [...state.ActiveForm.SelectedClassTypeIds] : [];
    if (act.show) {
      const existed = classTypeIds.find((id) => id === act.classTypeId);
      if (!existed && existed !== -1) {
        classTypeIds.push(act.classTypeId);
      }
    } else {
      const index = classTypeIds.findIndex((id) => id === act.classTypeId);
      if (index !== -1) {
        classTypeIds.splice(index, 1);
      }
    }
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedClassTypeIds: classTypeIds,
      },
    };
  } else if (isActionType(act, SelectAll)) {
    const classTypeIds = [...state.ActiveForm.SelectedClassTypeIds];

    act.classTypeIds.forEach((id) => {
      const existed = classTypeIds.find((rId) => rId === id);
      if (!existed && id !== -1) {
        classTypeIds.push(id);
      }
    });
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedClassTypeIds: classTypeIds,
      },
    };
  } else if (isActionType(act, Deselect)) {
    const classTypeIds = state.ActiveForm.SelectedClassTypeIds ? [...state.ActiveForm.SelectedClassTypeIds] : [];
    const newClassTypeIds: number[] = [];

    classTypeIds.forEach((id, index) => {
      const existed = act.classTypeIds.find((rId) => rId === id);
      if (!existed && id !== -1) {
        newClassTypeIds.push(id);
      }
    });
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedClassTypeIds: newClassTypeIds,
      },
    };
  } else if (isActionType(act, ResetSelectClassTypeModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default SelectClassTypeModalReducer;