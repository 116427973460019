import { IValidator, FormDefinition } from "./validation";
import { SUFFIX } from "./actions";
import { apiValidationReducerCreator } from "../../../../../store/Validation/reducerCreator";
import { createSelector } from "reselect";
import { ApplicationState } from "../../../../../store";
import { sortByNamePlural } from "../../../../../store/AdminFacilityLocation/Facilities/Home";
import { AdminFacilityType } from "../../../../../models/api/adminFacilitiesCacheOne";
import * as Actions from './actions';
import { facilityTypesSelector } from "../../Home";
import { setDefaults } from "../../../../../utils/validator";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../../utils/StrongActions";
import { WithRootState } from "../../../../Validation/actionCreator";

export interface NewFacilityModalActiveForm {
  SelectedFacilityTypeID: number;
  Name: string;
  Description: string;
}

export interface NewFacilityModalState {
  ActiveForm: NewFacilityModalActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
  FilterText?: string;
  isTextSearching: boolean;
}

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const filterTextSelector = (state: ApplicationState) => state.adminFacilityLocation.facilities.modals.newFacility.FilterText || '';
export const makeFilteredFacilityTypesSelector = () => {
  return createSelector(
    [facilityTypesSelector, filterTextSelector],
    (facilityTypes: AdminFacilityType[], filterText: string) => {
      if (facilityTypes) {
        return facilityTypes.filter((ft) => {
          if (!filterText) {
            return !ft.Inactive;
          } else {
            const f = filterText.toLowerCase();

            return (ft.Name.toLowerCase().includes(f) || ft.NamePlural.toLowerCase().includes(f)) && !ft.Inactive;
          }
        }).sort(sortByNamePlural);
      }
      return [];
    }
  );
};

const getInitialState = () => {
  return {
    ActiveForm: {
      SelectedFacilityTypeID: 0,
    },
    ValidationRules: { ...FormDefinition }
  };
};

const NewFacilityModalReducer: Reducer<NewFacilityModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);

  if (isActionType(act, Actions.FilterFacilityType)) {
    return {
      ...state,
      FilterText: act.filterText,
    };
  } else if (isActionType(act, Actions.NewFacilityToggleFilter)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, Actions.ResetNewFacilityModal)) {
    return getInitialState();
  } else if (isActionType(act, Actions.InitNewFacilityModal)) {
    return setDefaults(act.rootState, getInitialState());
  }
  return state || getInitialState();
};

export default NewFacilityModalReducer;