import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';
import { namespace as ns } from './constants';
import '../../../styles/elements/camp-info/synopsis.scss';
import Ellipsis from '../Ellipsis';

export const namespace = (): string => `${ns()}--synopsis`;

export interface SynopsisProps {
  text: string;
  className?: string;
}

const Synopsis: React.SFC<SynopsisProps> = (props: SynopsisProps): React.ReactElement<SynopsisProps> => (
  <Ellipsis
    className={mergeClassNames(namespace(), props)}
    configs={{
      lines: 3,
      text: props.text,

    }}
  />
);

export default Synopsis;
