import { ActionCreator, ApplicationState } from '../../../index';
import { SaveState } from '../../../Rollback/actions';
import { createValidateActions, createApiValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod } from '../../../../store/Validation/actionCreator';
import { ADMIN_EVENT_TYPES_SUFFIX } from '../../CacheOne/actions';
import { typeName, Action } from '../../../../utils/StrongActions';

export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + '__PRODUCTS_HOME';

@typeName('RESET' + SUFFIX) export class ProductsHomeReset extends Action {}

export type Actions = typeof actionCreators;

@typeName('TOGGLE_EXPAND_FILTER' + SUFFIX)
export class ToggleExpandFilterAction extends Action {
  constructor(public expand: boolean) { super(); }
}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.products.home, true, undefined, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  toggleExpandFilter: (expand: boolean): ActionCreator => dispatch => {
    dispatch(new ToggleExpandFilterAction(expand));
    dispatch(new SaveState());
  },
  reset: (): ActionCreator => dispatch => dispatch(new ProductsHomeReset()),
};