import { ActionCreator, ApplicationState } from "../../../..";
import {createApiValidateActions, createValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod} from '../../../../Validation/actionCreator';
import { typeName, Action } from "../../../../../utils/StrongActions";
import { CMSPage, CMSPagesFilters } from "../../../../../models/api/adminCMSCacheOne";
import { SaveState } from "../../../../Rollback/actions";

export const SUFFIX = '__CMS_PAGE_TYPES_MODAL';

@typeName("FILTER" + SUFFIX)
export class PageTypesModalFilter extends Action {
  constructor(public filterString: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class PageTypesToggleFilterTabs extends Action {
  constructor(public searching: boolean) { super(); }
}

@typeName("SHOW_ADVANCED_FILTER" + SUFFIX)
export class PageTypesShowAdvancedFilter extends Action {
  constructor(public show: boolean) { super(); }
}
@typeName('TOGGLE_FILTER_INVALID' + SUFFIX)
export class ToggleFilterInvalidInSelectPageModal extends Action {
  constructor(public invalid: boolean) { super(); }
}
@typeName("INIT" + SUFFIX)
export class InitPageTypesModal extends Action {
  constructor(public CMSPagesFilters: CMSPagesFilters | undefined, public CMSPages: CMSPage[] | undefined) { super(); }
}

@typeName("RESET" + SUFFIX) export class ResetPageTypesModal extends Action {}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export type Actions = typeof actionCreators;

export const actionCreators = {
  pageTypeModalUpdateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.pages.modals.pageType),
  pageTypeModalSimpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  filterPageTypes: (filterString: string): ActionCreator => dispatch => dispatch(new PageTypesModalFilter(filterString)),
  showAdvancedFilter: (show: boolean): ActionCreator => dispatch => dispatch(new PageTypesShowAdvancedFilter(show)),
  reset: (): ActionCreator => dispatch => dispatch(new ResetPageTypesModal()),
  init: (): ActionCreator => (dispatch, getState) => {
    dispatch(new InitPageTypesModal(getState().adminCMSSite.cacheOne.CMSPagesFilters, getState().adminCMSSite.cacheOne.CMSPages));
    dispatch(new SaveState());
  },
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new PageTypesToggleFilterTabs(searching)),
};