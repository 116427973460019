import * as React from 'react';
import { namespace, actionNamespace } from './constants';
import { ActionButton } from '../../../Elements';
import { RefreshIcon } from '../../../Icons';
import '../../../../styles/app/header/actions/refresh.scss';
import {
  ClearAllEndUserCacheButOptions,
  HideDrawer,
  SilentCancelAllAction,
} from "../../../../store/App/actions";
import { SaveState } from '../../../../store/Rollback/actions';
import { reduxStoreService } from '../../../../store/service';

interface Props {
  customRefresh?: () => void;
}

export const clearAllCache = () => {
  const rootState = reduxStoreService().getState();
  // If refreshing while there is a load/save, no save state -> should rollback to
  // state before the load/save if failure
  if (rootState.app.apiLoading === 0 && rootState.app.apiSaving === 0) {
    reduxStoreService().dispatch(new SaveState());
  }
  if (rootState.app.isDrawerOpen) reduxStoreService().dispatch(new HideDrawer());
  if (rootState.app.apiLoading !== 0) reduxStoreService().dispatch(new SilentCancelAllAction());
  reduxStoreService().dispatch(new ClearAllEndUserCacheButOptions(true, rootState));
};

const Refresh: React.SFC<Props> = ({customRefresh}): React.ReactElement<Props> => {
  return (
    <ActionButton className={`${actionNamespace()} ${namespace()}--refresh`} onClick={customRefresh ? customRefresh : clearAllCache}>
      <RefreshIcon className={`${namespace()}--refresh--image`} width="16px" height="16px"/>
    </ActionButton>
  );
};

export default Refresh;
