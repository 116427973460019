
import { SUFFIX, AddFacilities, RemoveFacility, UPDATE_FACILITY_BLACKOUT_DATE_FORM_SUFFIX } from "./actions";
import { apiValidationReducerCreator, shouldSkipUpdateForm } from "../../../../store/Validation/reducerCreator";
import { IBlackoutDateValidator, FacilityTypeBlackoutDateFormDefinition } from "./validation";
import { setDefaults } from "../../../../utils/validator";
import { FacilitiesBlackoutDate } from "../../../../models/api/adminFacilitiesCacheOne";
import moment from "moment";
import { convertObjToMoment } from "../../../../utils";
import { processHour } from "../../../../utils/dateHelper";
import { API_UPD } from "../../../../constants/request";
import { ClearAdminFacilityLocationCacheTwoBlackoutDate } from "../../CacheTwoBlackoutDates/actions";
import { ClearAdminFacilityLocationCacheBelowOne } from "../../CacheOne/actions";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../utils/StrongActions";
import { WithRootState } from "../../../Validation/actionCreator";

export interface BlackoutDateActiveForm {
  ID?: number;
  StartDate: moment.Moment;
  EndDate: moment.Moment;
  StartHour?: number;
  StartMin?: number;
  StartPeriod?: number | null;
  EndHour?: number;
  EndMin?: number;
  EndPeriod?: number | null;
  Reason?: string;
  Facilities?: number[];
  TS: number;
}

export interface BlackoutDateFormState {
  ActiveForm: BlackoutDateActiveForm;
  ValidationRules: IBlackoutDateValidator;
  SubmitErrorMessage?: string;
}

const getInitialState = () => {
  return {
    ActiveForm: {},
    ValidationRules: {...FacilityTypeBlackoutDateFormDefinition},
  };
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX);


const setBlackoutDate = (state: BlackoutDateFormState, blackoutDate?: FacilitiesBlackoutDate) => {
  
  if (!blackoutDate) return state;
  const blackoutDateWithMoment = convertObjToMoment(blackoutDate, ['StartDate', 'EndDate']);
  
  const StartHour = blackoutDateWithMoment.StartDate ? blackoutDateWithMoment.StartDate.hours() : null;
  const StartMin = blackoutDateWithMoment.StartDate ? blackoutDateWithMoment.StartDate.minutes() : null;
  const EndHour = blackoutDateWithMoment.EndDate ? blackoutDateWithMoment.EndDate.hours() : null;
  const EndMin = blackoutDateWithMoment.EndDate ? blackoutDateWithMoment.EndDate.minute() : null;
  
  const newState: BlackoutDateFormState = {
    ...state,
    ActiveForm: {
      ...state.ActiveForm,
      ID: blackoutDate.ID,
      StartDate: blackoutDateWithMoment.StartDate,
      StartHour: StartHour >= 0 ? processHour(StartHour) : 0,
      StartMin,
      StartPeriod: StartHour === null ? null : (StartHour > 11 ? 1 : 0),
      EndDate: blackoutDateWithMoment.EndDate,
      EndHour: EndHour >= 0 ? processHour(EndHour) : 0,
      EndMin,
      EndPeriod: EndHour === null ? null : (EndHour > 11 ? 1 : 0),
      Reason: blackoutDate.Reason,
      Facilities: blackoutDate.Facilities,
      TS: blackoutDate.TS,
    },
    ValidationRules: {...FacilityTypeBlackoutDateFormDefinition},
  };

  return newState;
};

const BlackoutDateFormReducer: Reducer<BlackoutDateFormState> = (s, act: WithRootState<AnyAction>) => {

  const state = {
    ...getInitialState(),
    ...checkApiValidation(s, act),
  };

  if (isActionType(act, AddFacilities)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        Facilities: [...act.facilityIds],
      },
    };
  } else if (isActionType(act, RemoveFacility)) {
    if (!state.ActiveForm.Facilities) return state;
    const facilities = [...state.ActiveForm.Facilities];

    const index = facilities.findIndex((f) => f === act.facilityId);
    if (index !== -1) facilities.splice(index, 1);

    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        Facilities: facilities,
      },
    };
  } else if (act.type.includes(API_UPD) && act.type.includes(UPDATE_FACILITY_BLACKOUT_DATE_FORM_SUFFIX)) {
    if (shouldSkipUpdateForm(act)) return state;

    // get initial state
    let newState = getInitialState();

    // set default values
    newState = setDefaults(act.rootState, newState, state);

    // load from cache two if set
    const rootState = act.rootState;
    if (rootState.adminFacilityLocation.cacheTwoBlackoutDate && rootState.adminFacilityLocation.cacheTwoBlackoutDate.FacilitiesBlackoutDate) {
      const blackoutDate = rootState.adminFacilityLocation.cacheTwoBlackoutDate.FacilitiesBlackoutDate;
      return setBlackoutDate(newState as any, blackoutDate);
    }
    
    return newState;
  } else if (isActionType(act, ClearAdminFacilityLocationCacheTwoBlackoutDate) || isActionType(act, ClearAdminFacilityLocationCacheBelowOne)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default BlackoutDateFormReducer;