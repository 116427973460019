import * as React from 'react';

import { FieldSet, Row, Column, Select, AdminBadge, DatePicker, TextField, Switch, CreditCard as CreditCardNum, CreditCardTypes, Text } from '../../../Elements';
import Security from './Security';
import { namespace as ns } from '../constants';
import '../../../../styles/pages/checkout/payment-method.scss';
import CreditCard from './CreditCard';
import ECheck from './ECheck';
import {Actions as AppActions} from "../../../../store/App/actions";
import {Actions as CacheZeroActions} from "../../../../store/CacheZero/actions";
import {Actions as CacheOneActions} from "../../../../store/CacheOne/actions";
import {Actions} from "../../../../store/Checkout/actions";
import {CheckoutState} from "../../../../store/Checkout";
import {CacheOneState} from "../../../../store/CacheOne";
import {allowAtCouncilForm, allowCCForm, alloweCheckForm} from "../../../../store/Checkout/validation";
import {CartOrder} from "../../../../models/api/cacheOne";
import {SaveState} from "../../../../store/Rollback/actions";
import { reduxStoreService } from '../../../../store/service';
import { Validator } from '../../../../utils/validator/models';

export const namespace = (): string => `${ns()}--payment-method`;

export interface PaymentMethodProps {
  cacheOne: CacheOneState;
  checkout: CheckoutState;
  actions: Actions & AppActions & CacheOneActions & CacheZeroActions ;
  isAdmin: boolean;
  ShowAmEx: boolean;
  ShowCCDiscover: boolean;
  AllowCCPayment: boolean;
  AllowECheckPayment: boolean;
  hidePaymentType?: boolean
  CheckoutAndPrivacyPolicies: string | null;
  disabled: boolean;
}

class PaymentMethod extends React.Component<PaymentMethodProps, {}> {
  public props: PaymentMethodProps;

  onChangePaymentType = (value: string | number | undefined, validationRules: Validator) => {
    const oldPaymentType = this.props.checkout.ActiveForm.PaymentType;
    const UseCredit = this.props.checkout.ActiveForm.UseCredit;
    reduxStoreService().dispatch(new SaveState());
    this.props.actions.updateValue(value, validationRules);
    if (oldPaymentType) {
      this.props.actions.changePaymentType(value as number, UseCredit as boolean);
    }
  };

  public render() {
    const { CheckoutAndPrivacyPolicies, hidePaymentType, AllowCCPayment, AllowECheckPayment, actions, ShowAmEx, ShowCCDiscover, checkout: {ActiveForm, ValidationRules}, disabled, isAdmin} = this.props;
    const CartOrder = this.props.cacheOne.CartOrder as CartOrder;
    const showCouncilForm = allowAtCouncilForm(ActiveForm.PaymentType as number, CartOrder.Amount, parseFloat(ActiveForm.NotApplied as any));

    return (
      <FieldSet legendMarginBottom={16} fontSize={28} name={(hidePaymentType && !isAdmin) ? undefined : 'Payment Method'}>
        <Security/>
        {isAdmin && <Row>
          <Column className="new-design-switch-wrapper" span={6} mobileSpan={12}>
            <AdminBadge className={`${namespace()}--order-date-badge`}/>
            <Switch label="Send Receipt"
                    disabled={disabled}
                    newDesign
                    onChange={actions.updateValue}
                    value={!!ActiveForm.SendReceipt}
                    validationRules={ValidationRules.SendReceipt}
            />
          </Column>
          <Column span={6} mobileSpan={12}>
            <DatePicker
              label="Order Date"
              disabled={disabled}
              value={ActiveForm.OrderDate}
              validationRules={ValidationRules.OrderDate}
              onSelect={actions.updateValue}
              onChangeRaw={actions.simpleUpdate}
              removeMax
            />
          </Column>
        </Row>}
        {(!hidePaymentType || showCouncilForm) && <Row>
          {!hidePaymentType && <Column span={6} mobileSpan={12}>
            <Select label="Payment Type"
                    disabled={disabled}
                    onChangeValue={this.onChangePaymentType}
                    value={ActiveForm.PaymentType}
                    validationRules={ValidationRules.PaymentType}
                    isNumber
            />
          </Column>}
          {showCouncilForm && <Column span={6} mobileSpan={12}>
            <TextField
              disabled={disabled}
              label="Receipt Number"
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.ReceiptNumber}
              value={ActiveForm.ReceiptNumber}
            />
          </Column>}
          {!hidePaymentType && AllowCCPayment && allowCCForm(ActiveForm.PaymentType as number, CartOrder.Amount, parseFloat(ActiveForm.NotApplied as any)) && <Column span={6} mobileSpan={12}>
            <CreditCardNum label="Card Number"
                        disabled={disabled}
                        creditCardType={this.props.checkout.creditCardType}
                        value={this.props.checkout.ActiveForm.CCNum}
                        onCreditCardTypeChange={actions.changeCreditCardType}
                        onChange={actions.simpleUpdate}
                        onBlur={actions.updateValue}
                        validationRules={this.props.checkout.ValidationRules.CCNum}
            />
          </Column>}
        </Row>}
        {!hidePaymentType && AllowCCPayment && allowCCForm(ActiveForm.PaymentType as number, CartOrder.Amount, parseFloat(ActiveForm.NotApplied as any)) && <CreditCard
          cacheOne={this.props.cacheOne}
          actions={actions}
          checkout={this.props.checkout}
          ShowAmEx={ShowAmEx}
          ShowCCDiscover={ShowCCDiscover}
          disabled={disabled}
        />}
        {!hidePaymentType && AllowECheckPayment && alloweCheckForm(ActiveForm.PaymentType as number, CartOrder.Amount, parseFloat(ActiveForm.NotApplied as any)) && <ECheck
          cacheOne={this.props.cacheOne}
          actions={actions}
          checkout={this.props.checkout}
          disabled={disabled}
        />}
        {isAdmin && <Row>
          <TextField
            label="Notes"
            disabled={disabled}
            onChange={actions.simpleUpdate}
            onBlur={actions.updateValue}
            validationRules={ValidationRules.Notes}
            value={ActiveForm.Notes}
            rows={4}
          />
        </Row>}
        {!hidePaymentType && (AllowCCPayment || AllowECheckPayment) && <Row marginBottom={16} marginTop={12} verticalAlignment="middle">
          <Text color="gray" size={14} marginRight={8}>We accept:</Text>
          <CreditCardTypes
            className={`${namespace()}--credit-card-types`}
            ShowAmEx={ShowAmEx}
            ShowCCDiscover={ShowCCDiscover}
            AllowECheckPayment={AllowECheckPayment}
          />
        </Row>}
        {CheckoutAndPrivacyPolicies && <Row marginTop={8}>
          <div className={`${namespace()}--privacy-policies`} dangerouslySetInnerHTML={{__html: CheckoutAndPrivacyPolicies}}></div>
        </Row>}
      </FieldSet>
    );
  }
}

export default PaymentMethod;
