import * as React from 'react';
import { bindActionCreators } from 'redux';
import {withRouter, RouteComponentProps, WithRouterProps} from "react-router";
import { actionCreators } from "../../../../../../store/Events/Event/Register/Products/Main/actions";
import { actionCreators as productActions } from "../../../../../../store/Events/Event/Register/Products/Products/actions";
import { actionCreators as cacheFourProductsActions } from "../../../../../../store/CacheFourEventsProducts/actions";
import { RegisterFooterProgress } from '../../../../../Elements';
import { Step } from '../../../../../Elements/Register/FooterProgress';
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string}>;

export interface FooterProgressProps {
  selected: 'products' | 'confirmation';
}

const FooterProgress: React.SFC<FooterProgressProps> = ({ selected, actions, apiSaving, apiLoading }: FooterProgressProps & ConnectedProps):React.ReactElement<FooterProgressProps> => {
  const steps: Array<Step> = [
    { selected: selected === 'products'},
    { selected: selected === 'confirmation'},
  ];

  let onNextClick, onBackClick, backParam, nextParam;
  if (selected === 'products') {
    onNextClick = actions.productsSubmit;
    nextParam = 'confirmation';
  } else if (selected === 'confirmation') {
    onBackClick = () => actions.selectPage('products');
  }

  return <RegisterFooterProgress
    loading={apiSaving > 0 || apiLoading > 0}
    onComplete={actions.productsSave}
    onBackClick={onBackClick}
    backParam={backParam}
    onNextClick={onNextClick}
    nextParam={nextParam}
    steps={steps}
  />;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({...cacheFourProductsActions, ...actionCreators, ...productActions} as any, dispatch) });

const ConnectedFooterProgress = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<FooterProgressProps & WithRouterProps>(),
)(FooterProgress);

export default withRouter<FooterProgressProps>(ConnectedFooterProgress);

