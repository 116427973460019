import * as React from 'react';
import '../../../../styles/pages/auth/login/footer.scss';
import { namespace as ns } from './constants';

export const namespace = (): string => `${ns()}--footer`;

interface Props {
  faded?: boolean;
}

const Footer: React.SFC<Props> = ({ faded }: Props): React.ReactElement<Props> => {
  let className = namespace();
  if (faded) className += ' faded';
  return (
    <div className={className}>
    </div>
  );
};

export default Footer;
