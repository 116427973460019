import * as React from 'react';
import {default as AppHeader} from '../../../../../App/Header';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { CMS_CONTACTS, getContactsRootUrl } from '../../../../../../constants/urls';
import { bindActionCreators } from 'redux';
import {
    actionCreators as cacheTwoContactsActionCreators
} from '../../../../../../store/AdminCMSSite/CacheTwoContacts/actions';
import {
    actionCreators as rollbackActionCreators
} from '../../../../../../store/Rollback/actions';
import { extractRouteParams } from '../../../../../../utils/urlHelper';
import { getContactIdFromPath, IAdminCMSContactRouterParams } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminCMSContactRouterParams, {}>;

const Header = (props: ConnectedProps): React.ReactElement<ConnectedProps> => {
  const sp = props.location.pathname.split('/');
  const isAdd = sp[sp.length - 1] === CMS_CONTACTS.NEW;
  const isEdit = sp[sp.length - 1] === CMS_CONTACTS.EDIT;
  
  const site = props.CMSSite;

  const customRefresh = () => {
    // during editing, should always save state before a refresh
    props.actions.saveState();
    isEdit && site && props.actions.refreshContact({
      ID: getContactIdFromPath(props.params),
      ...extractRouteParams(props),
    }, true);
  };
  // fall back to root
  const backUrl = site ? getContactsRootUrl({
    siteId: site.ID,
    domain: site.SiteDomain,
  }) : '/admin2';

  return <AppHeader
    showDrawer
    back={backUrl}
    disableBack={!site}
    title={isAdd ? 'New Contact' : 'Edit Contact'}
    subTitle={site ? site.SiteDomain : ''}
    customRefresh={customRefresh}
    refresh={!isAdd}
  />;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    CMSSite: state.adminCMSSite.cacheOne.CMSSite,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    cacheZero: state.cacheZero,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...cacheTwoContactsActionCreators,
    ...rollbackActionCreators,
  }, dispatch),
});

const ConnectedHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<ConnectedProps & WithRouterProps>(),
)(Header);

export default withRouter<{}>(ConnectedHeader);
