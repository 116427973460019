import * as React from 'react';

import { Main, MainContent } from '../../../../Layouts';
import {
  Button, Row, Column, Form, FormActions, TextField, DatePicker, NumberField, FieldSet, Alert, Media
} from '../../../../Elements';
import {TripActiveForm} from "../../../../../store/Facilities/Trip/Form";
import {IValidator} from "../../../../../store/Facilities/Trip/Form/validation";
import {FullGroup} from "../../../../../models/api/options";
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { Validator } from '../../../../../utils/validator/models';
import { WithInertAttribute } from '../../../../Elements/WithInert';

type Props = WithInertAttribute<{
  action: 'add' | 'edit';
  ActiveForm: TripActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
  isSaving: boolean;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
  };
  onSave?: () => void;
  onDelete?: () => void;
  loading?: boolean;
  isAdmin: boolean;
  group: FullGroup;
  hasAccess: boolean;
  hasAccessMessage: string | null;
}>;

class Trip extends React.Component<Props, {}> {
  props: Props;

  onYouthChange = (value) => {
    const {reduxActions, ValidationRules} = this.props;
    let v;
    if (value || value === 0) v = parseInt(value);
    if (isNaN(v)) reduxActions.updateValue('', ValidationRules.NumYouth);
    else reduxActions.updateValue(v, ValidationRules.NumYouth);

  };

  onAdultChange = (value) => {
    const {reduxActions, ValidationRules} = this.props;
    let v;
    if (value || value === 0) v = parseInt(value);
    if (isNaN(v)) reduxActions.updateValue('', ValidationRules.NumAdults);
    else reduxActions.updateValue(v, ValidationRules.NumAdults);
  };

  onCopyAccountInfo = () => {
    const {reduxActions, ValidationRules, group} = this.props;
    reduxActions.updateValue(group.ContactName, ValidationRules.ContactName);
    reduxActions.updateValue(group.Email, ValidationRules.ContactEmail);
    reduxActions.updateValue(group.PhoneNumber, ValidationRules.ContactPhone);
  };

  isFormDisabled() {
    const {hasAccess, isSaving} = this.props;

    return !hasAccess || isSaving;
  }

  render() {
    const { hasAccess, hasAccessMessage, isAdmin, ValidationRules, ActiveForm, SubmitErrorMessage, inert, action, reduxActions, loading, onSave, onDelete } = this.props;

    // Also disable buttons when it's saving because the save is non-optimistic
    return <Main inert={inert} mobileBackground="white">
      <MainContent loading={loading}>
        {!hasAccess && <Row hardOpacity key="access">
          <Alert>{hasAccessMessage}</Alert>
        </Row>}
        {SubmitErrorMessage && <Row key="alert">
          <Alert>{SubmitErrorMessage}</Alert>
        </Row>}
        <Form
          onSubmit={e => e.preventDefault()}
          actions={
            action === 'add' ? (
              <FormActions><Button disabled={this.isFormDisabled()} flat textColor="black" big onClick={onSave}>CONTINUE</Button></FormActions>
            ) : (
              <FormActions>
                <Row>
                  <Column expand>
                    <Button disabled={this.isFormDisabled()} color="white" flat textColor="black" big onClick={onSave}>SAVE CHANGES</Button>
                  </Column>
                  <Column>
                    <Button disabled={this.isFormDisabled()} color="white" flat textColor="red" big onClick={onDelete}>DELETE TRIP</Button>
                  </Column>
                </Row>
              </FormActions>
            )
          }
        >
          <FieldSet newDesign>
            <TextField label="Trip Name"
                       id={generateDOMId("trip-name-field")}
                       disabled={this.isFormDisabled()}
                       onChange={reduxActions.simpleUpdate}
                       onBlur={reduxActions.updateValue}
                       validationRules={ValidationRules.Name}
                       value={ActiveForm.Name}
                       info="For example: Petersons Family Trip to Conewago"
                       big
            />
            <Row>
              <Column span={6} mobileSpan={12}>
                <DatePicker
                  label="Arrival"
                  value={ActiveForm.StartDateTime}
                  validationRules={ValidationRules.StartDateTime}
                  onSelect={reduxActions.updateValue}
                  onChangeRaw={reduxActions.simpleUpdate}
                  disabled={this.isFormDisabled()}
                  removeMax
                />
              </Column>
              <Column span={6} mobileSpan={12}>
                <DatePicker
                  label="Departure"
                  value={ActiveForm.EndDateTime}
                  validationRules={ValidationRules.EndDateTime}
                  onSelect={reduxActions.updateValue}
                  onChangeRaw={reduxActions.simpleUpdate}
                  disabled={this.isFormDisabled()}
                  removeMax
                />
              </Column>
            </Row>
            <Row>
              <Column span={6} mobileSpan={12}>
                <NumberField
                  label="Number of Youth"
                  value={ActiveForm.NumYouth}
                  validationRules={ValidationRules.NumYouth}
                  minimum={ValidationRules.NumYouth.validatejs.NumYouth.numericality.greaterThan + 1}
                  maximum={ValidationRules.NumYouth.validatejs.NumYouth.numericality.lessThan - 1}
                  onChange={this.onYouthChange}
                  disabled={this.isFormDisabled()}
                  noNegative
                />
              </Column>
              <Column span={6} mobileSpan={12}>
                <NumberField
                  label="Number of Adults"
                  value={ActiveForm.NumAdults}
                  validationRules={ValidationRules.NumAdults}
                  minimum={ValidationRules.NumAdults.validatejs.NumAdults.numericality.greaterThan + 1}
                  maximum={ValidationRules.NumAdults.validatejs.NumAdults.numericality.lessThan - 1}
                  onChange={this.onAdultChange}
                  disabled={this.isFormDisabled()}
                  noNegative
                />
              </Column>
            </Row>
          </FieldSet>
          <FieldSet
            newDesign
            name="Contact Info"
            controlsNoMarginTop
            controls={
              <Button disabled={this.isFormDisabled()} color="white" key={1} onClick={this.onCopyAccountInfo}>
                Copy From Profile
              </Button>
            }
          >
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField label="Name"
                           disabled={this.isFormDisabled()}
                           onChange={reduxActions.simpleUpdate}
                           onBlur={reduxActions.updateValue}
                           validationRules={ValidationRules.ContactName}
                           value={ActiveForm.ContactName}/>
              </Column>
            </Row>
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField label="Email"
                           disabled={this.isFormDisabled()}
                           onChange={reduxActions.simpleUpdate}
                           onBlur={reduxActions.updateValue}
                           validationRules={ValidationRules.ContactEmail}
                           value={ActiveForm.ContactEmail}/>
              </Column>
              <Column span={6} mobileSpan={12}>
                <TextField label="Phone"
                           disabled={this.isFormDisabled()}
                           onChange={reduxActions.simpleUpdate}
                           onBlur={reduxActions.updateValue}
                           validationRules={ValidationRules.ContactPhone}
                           value={ActiveForm.ContactPhone}/>
              </Column>
            </Row>
          </FieldSet>
          <FieldSet newDesign name="Alternate Contact">
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField label="Name"
                           disabled={this.isFormDisabled()}
                           onChange={reduxActions.simpleUpdate}
                           onBlur={reduxActions.updateValue}
                           validationRules={ValidationRules.ContactName2}
                           value={ActiveForm.ContactName2}/>
              </Column>
            </Row>
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField label="Email"
                           disabled={this.isFormDisabled()}
                           onChange={reduxActions.simpleUpdate}
                           onBlur={reduxActions.updateValue}
                           validationRules={ValidationRules.ContactEmail2}
                           value={ActiveForm.ContactEmail2}/>
              </Column>
              <Column span={6} mobileSpan={12}>
                <TextField label="Phone"
                           disabled={this.isFormDisabled()}
                           onChange={reduxActions.simpleUpdate}
                           onBlur={reduxActions.updateValue}
                           validationRules={ValidationRules.ContactPhone2}
                           value={ActiveForm.ContactPhone2}/>
              </Column>
            </Row>
          </FieldSet>
          <FieldSet newDesign>
            <Media mobile>
              <TextField
                label="Additional Info"
                value={ActiveForm.GroupNotes}
                onChange={reduxActions.simpleUpdate}
                onBlur={reduxActions.updateValue}
                validationRules={ValidationRules.GroupNotes}
                disabled={this.isFormDisabled()}
                rows={3}/>
            </Media>
            <Media tabletAndGreater>
              <TextField
                  label="Additional Info"
                  value={ActiveForm.GroupNotes}
                  onChange={reduxActions.simpleUpdate}
                  onBlur={reduxActions.updateValue}
                  validationRules={ValidationRules.GroupNotes}
                  disabled={this.isFormDisabled()}
                  rows={6}/>
            </Media>
          </FieldSet>
          {isAdmin && <FieldSet newDesign>
            <Row styles={{position: 'relative'}}>
              <Column span={12}>
                <Media mobile>
                  <TextField
                    isAdmin
                    label="Admin Notes"
                    value={ActiveForm.AdminNotes}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.AdminNotes}
                    disabled={this.isFormDisabled()}
                    rows={3}/>
                </Media>
                <Media tabletAndGreater>
                  <TextField
                    isAdmin
                    label="Admin Notes"
                    value={ActiveForm.AdminNotes}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.AdminNotes}
                    disabled={this.isFormDisabled()}
                    rows={6}/>
                </Media>
              </Column>
              
            </Row>
          </FieldSet>}
        </Form>
      </MainContent>
    </Main>;
  }
}

export default Trip;
export { default as FacilitiesTripTripHeader } from './Header';
