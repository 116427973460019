export const USERNAME_TAKEN = 'This username is already in use.';
export const EMAIL_TAKEN = 'This email is already in use.';

export const SUBMIT_ERROR = 'Please review the errors below.';

export const EMAIL_MATCH = 'Emails must match.';
export const PASSWORD_MATCH = 'Passwords must match.';

export const ROUTING_NUMBER_MATCH = 'Routing Numbers must match.';
export const ROUTING_NUMBER_LENGTH = 'Routing Number must be exactly 9 digits.';

export const AMOUNT_INVALID = 'Amount is invalid.';

export const NO_CC = 'Credit Card payments are no longer enabled.';
export const NO_ECHECK = 'eCheck payments are no longer enabled.';
export const NO_ONLINE_PAYMENT = 'No online payment methods are currently enabled. Please either mail in your payment or ask your council how to proceed.';
export const DIFF_PAYMENT_TYPE = 'Please select a different payment type.';

export const PROFILE_CONTACT_ALERT1 = 'Verify your profile contact information to make sure we can contact you.';
export const PROFILE_CONTACT_ALERT2 = 'Please make sure you\'re listed as either the primary or alternate contact in order to receive a receipt.';