import { FormDefinition as ClassesFormDefinition } from '../../Event/Classes/validation';
import { SelectValidator, Validator } from "../../../../../utils/validator/models";

export interface IValidator {
  IncludeBlankRows: Validator;
  SelectedClass: Validator;
  KindOfClass: SelectValidator;
  RegisterUnder: SelectValidator;
}

export const FormDefinition: IValidator = {
  IncludeBlankRows: {
    key: 'IncludeBlankRows',
  },
  SelectedClass: {
    key: 'SelectedClass',
  },
  KindOfClass: ClassesFormDefinition.KindOfClass,
  RegisterUnder: {
    ...ClassesFormDefinition.RegisterUnder,
    defaultValue: () => 2,
  },
};