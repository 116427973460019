import * as React from 'react';
import { Modal, ModalContent, ModalActions, TextField, Button, ModalHeader, Text, Row, Link, ResetPasswordAccountCard, Column, Alert } from '../../../Elements';
import {bindActionCreators} from 'redux';
import {actionCreators, ResetPasswordSelectAccount} from "../../../../store/ResetPassword/actions";
import {ResetPasswordContext} from "../../../../store/ResetPassword/index";
import { ApplicationState } from '../../../../store';
import { generateDOMId } from '../../../../utils/cypressHelper';
import { appActionCreators, makeFormModalPropSelector } from '../../../../store/App';
import { ModalTypes } from '../../../../utils/modalHelper';
import { EmailOrangeIcon } from '../../../Icons';
import '../../../../styles/pages/auth/reset-password/index.scss';
import { openSupportForm } from '../../../../constants/urls';
import { IResetPasswordAccount } from '../../../../models/api/login';
import { CardCategory } from '../../../Elements/Card';
import { shouldBlockActions } from '../../../../utils/cacheLoaders/helpers/blockers';
import { ModalHeight } from '../../../Elements/Modal';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../store/service';
import {WithInertAttribute} from '../../../Elements/WithInert';

const namespace = () => "modals--reset-password";

type Props = WithInertAttribute<{}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class ResetPassword extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  componentDidMount() {
    if (shouldBlockActions()) return;
    
    this.props.actions.init();
  }

  public componentWillUnmount() {
  }

  onClose = () => {
  };

  renderActions = () => {
    const {context} = this.props;

    if (context === ResetPasswordContext.SEND_RESET_LINK) {
      return this.renderActionsForSendResetLink();
    } else {
      return this.renderActionsForUpdatePassword();
    }
  };

  renderContent = () => {
    const {context} = this.props;

    if (context === ResetPasswordContext.SEND_RESET_LINK) {
      return this.renderContentForSendResetLink();
    } else {
      return this.renderContentForUpdatePassword();
    }
  };

  renderContentForUpdatePassword = () => {
    const {resetPassword, resetPasswordAccounts, context} = this.props;

    if (resetPassword.selectedAccount) {
      return (
        <>
          {resetPasswordAccounts && resetPasswordAccounts.length > 1 ? this.renderStepper() : null}
          <ModalContent
            paddingBottom={0}
            paddingLeft={0}
            paddingRight={0}
            paddingTop={0}
          >
            {this.renderUpdatePasswordForm(resetPassword.selectedAccount)}
          </ModalContent>
        </>
      );
    } else {
      return this.renderUpdatePasswordStep1();
    }
  };

  renderStepper = () => {
    const {resetPassword} = this.props;

    return (
      <div className={`${namespace()}--stepper`}>
        <div className={`${namespace()}--stepper--title`}>
          {!resetPassword.selectedAccount ? "Step 1 of 2:" : "Step 2 of 2:"}
        </div>
        <div className={`${namespace()}--stepper--content`}>
          {!resetPassword.selectedAccount ? "Select Account" : "Enter New Password"}
        </div>
      </div>
    );
  };

  onClickAccount = (account: IResetPasswordAccount) => {
    reduxStoreService().dispatch(new ResetPasswordSelectAccount(account));
  };

  renderUpdatePasswordStep1 = () => {
    const {resetPasswordAccounts} = this.props;
    return (
      <>
        {this.renderStepper()}
        <ModalContent
            paddingBottom={0}
            paddingLeft={0}
            paddingRight={0}
            paddingTop={0}
        >
          <div className={`${namespace()}--account-list`}>
            {resetPasswordAccounts?.map((account) => {
              return (
                <ResetPasswordAccountCard
                  key={account.AccountID}
                  type="list"
                  category={CardCategory.LIST_MOBILE}
                  account={account}
                  onClick={this.onClickAccount}
                />
              );
            })}
          </div>
        </ModalContent>
      </>
    );
  };


  renderUpdatePasswordForm = (selectedAccount: IResetPasswordAccount) => {
    const {actions, resetPassword: {UpdatePasswordForm: {ActiveForm, ValidationRules, SubmitErrorMessage}}} = this.props;
    return (
      <div className="update-password-form-wrapper">
        {SubmitErrorMessage && <Row><Alert>{SubmitErrorMessage}</Alert></Row>}
        <ResetPasswordAccountCard
          type="update-password-form"
          category={CardCategory.LIST_MOBILE}
          account={selectedAccount}
        />
        <Row marginBottom={8}>
          <Column span={12}>
            <TextField
              id={generateDOMId("new-password-field")}
              label="New Password"
              type="password"
              value={ActiveForm.NewPassword}
              validationRules={ValidationRules.NewPassword}
              onChange={actions.updatePasswordFormSimpleUpdate}
              onBlur={actions.updatePasswordFormUpdateValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <TextField
              id={generateDOMId("repeat-new-password-field")}
              label="Repeat New Password"
              type="password"
              value={ActiveForm.ConfirmNewPassword}
              validationRules={ValidationRules.ConfirmNewPassword}
              onChange={actions.updatePasswordFormSimpleUpdate}
              onBlur={actions.updatePasswordFormUpdateValue}
            />
          </Column>
        </Row>
      </div>
    );
  };

  submitUpdatePassword = () => {
    const {actions, resetPassword, resetPasswordCode} = this.props;

    if (!resetPasswordCode || !resetPassword.selectedAccount) return;
  
    actions.submitUpdatePassword(
      resetPassword.selectedAccount,
      resetPasswordCode,
    );
  };

  renderActionsForUpdatePassword = () => {
    const {apiSaving, resetPassword} = this.props;

    if (resetPassword.selectedAccount) {
      return (
        <ModalActions
          sticky
          notFixed
          noPadding
          loading={apiSaving > 0}
          left={<Button textColor="black" flat onClick={this.submitUpdatePassword}>Complete</Button>}
        />
      );
    } else {
      return null;
    }
    
  };

  renderContentForSendResetLink = () => {
    const {resetPassword} = this.props;

    if (resetPassword.sendResetLinkStep === 1) return this.renderContentForSendResetLinkStep1();
    else return this.renderContentForSendResetLinkStep2();
  };

  renderActionsForSendResetLink = () => {
    const {resetPassword} = this.props;

    if (resetPassword.sendResetLinkStep === 1) return this.renderActionsForSendResetLinkStep1();
    else return this.renderActionsForSendResetLinkStep2();
  };

  renderContentForSendResetLinkStep1 = () => {
    const {actions, resetPassword: {ResetPasswordSearchForm: {ActiveForm, ValidationRules, SubmitErrorMessage} }} = this.props;
    return (
      <ModalContent hasPadding>
        {SubmitErrorMessage && <Row><Alert>{SubmitErrorMessage}</Alert></Row>}
        <Row marginBottom={16}>
          <Text>Please enter Email or Username. Reset link will be sent to the associated email(s).</Text>
        </Row>
        <Row>
          <Column span={12}>
            <TextField
              id={generateDOMId("reset-password-email-field")}
              label="Email or Username"
              value={ActiveForm.EmailOrUsername}
              validationRules={ValidationRules.EmailOrUsername}
              onChange={actions.resetPasswordSearchSimpleUpdate}
              onBlur={actions.resetPasswordSearchFormUpdateValue}
            />
          </Column>
        </Row>
      </ModalContent>
    );
  };

  renderActionsForSendResetLinkStep1 = () => {
    const {apiSaving, actions} = this.props;
    return (
      <ModalActions
        sticky
        notFixed
        noPadding
        loading={apiSaving > 0}
        left={<Button textColor="black" flat onClick={actions.submitResetPasswordSearch}>Continue</Button>}
      />
    );
  };

  renderContentForSendResetLinkStep2 = () => {
    const {resetPassword: {ResetPasswordSearchForm: {ActiveForm}}} = this.props;
    return (
      <ModalContent hasPadding>
        <Row className={`${namespace()}--check-email-row`} marginBottom={8}>
          <EmailOrangeIcon />
          <Text marginTop={8} className={`${namespace()}--check-email-text`}>Check Your Email</Text>
        </Row>
        <Row className={`${namespace()}--check-email-message-row`}>
          <div>If we find <strong>{ActiveForm.EmailOrUsername}</strong> in our system associated with an active account, we will send you an email with a link to reset your password.</div>
          <div>If you don't receive the email, check your spam folder or <Link medium externalLink={openSupportForm(true)}>Contact Your Council</Link>.</div>
          <div>Or go back to try again using a different email address.</div>
        </Row>
      </ModalContent>
    );
  };

  onCloseSendResetLink = () => {
    this.onClose();
    reduxStoreService().dispatch(appActionCreators.popModal(false, true, ModalTypes.RESET_PASSWORD) as any);
  };

  renderActionsForSendResetLinkStep2 = () => {
    const {apiSaving, actions} = this.props;
    return (
      <ModalActions
        sticky
        notFixed
        noPadding
        loading={apiSaving > 0}
        left={<Button onClick={this.onCloseSendResetLink} textColor="black" flat>Close</Button>}
        right={<Button onClick={this.props.actions.init} textColor="black" flat>Try Different Address</Button>}
      />
    );
  };

  getOnBack = () => {
    const {context, resetPasswordAccounts, actions, resetPassword} = this.props;

    if (context === ResetPasswordContext.SEND_RESET_LINK) {
      if (resetPassword.sendResetLinkStep === 2) {
        return this.props.actions.init;
      }
    } else {
      if (resetPassword.selectedAccount && resetPasswordAccounts && resetPasswordAccounts.length > 1) {
        return this.props.actions.init;
      }
    }
  };

  public render() {
    const {context, resetPassword: {selectedAccount}, inert} = this.props;

    return (
      <Modal
        inert={inert}
        height={context === ResetPasswordContext.UPDATE_PASSWORD && !selectedAccount ? ModalHeight.HEIGHT_425 : ModalHeight.AUTO}
        mobileFullScreen
        className={`${namespace()}${context === ResetPasswordContext.SEND_RESET_LINK ? " send-reset-link" : " update-password"}`}
        big disableDynamicHeight onClose={this.onClose}>
        <ModalHeader onBack={this.getOnBack()} className={`${namespace()}--header`}>Reset Password</ModalHeader>
        {this.renderContent()}
        {this.renderActions()}
      </Modal>
    );
  }
}


const mapStateToProps = (state: ApplicationState) => {
  const resetPasswordContextSelector = makeFormModalPropSelector(ModalTypes.RESET_PASSWORD, "resetPasswordContext");
  const resetPasswordAccountsSelector = makeFormModalPropSelector(ModalTypes.RESET_PASSWORD, "resetPasswordAccounts");
  const resetPasswordCodeSelector = makeFormModalPropSelector(ModalTypes.RESET_PASSWORD, "resetPasswordCode");
  
  return {
    resetPassword: state.resetPassword,
    apiSaving: state.app.apiSaving,
    context: resetPasswordContextSelector(state),
    resetPasswordAccounts: resetPasswordAccountsSelector(state),
    resetPasswordCode: resetPasswordCodeSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({...actionCreators}, dispatch)});

const ConnectedResetPassword = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ResetPassword);
export default ConnectedResetPassword;
