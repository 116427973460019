import * as React from 'react';
import {default as AppHeader} from '../../../App/Header';
import {FacilityLocation} from "../../../../models/api/cacheOne";
import {URLS} from "../../../../constants/urls";
import {makeSelectedLocationSelector} from "../../../../store/CacheTwoFacilities";
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps>;

const Header: React.SFC<ConnectedProps> = ({selectedLocation}): React.ReactElement<ConnectedProps> => (
  <AppHeader
    showDrawer
    title={selectedLocation ? selectedLocation.Name : ''}
    cart
    mobileCart
    refresh
    back={URLS.FACILITIES}
  />
);

const mapStateToProps = (state: ApplicationState) => {
  const selectedLocation = makeSelectedLocationSelector();
  return {
    selectedLocation: selectedLocation(state)
  };
};

const ConnectedHeader = connect(
  mapStateToProps,
  null,
  getMergeProps<{}>(),
)(Header);

export default ConnectedHeader;
