import * as React from 'react';

import Shadow from "../Shadow";

import '../../../styles/elements/header-block/index.scss';

import {namespace} from "./constants";

export interface HeaderBlockProps {
  className?: string;
  children?: React.ReactNode;
  leftGutter?: boolean;
  leftGutterSize?: number;
  hasLeftSideBar?: boolean;
  hasRightSideBar?: boolean;
  wide?: boolean;
}

const HeaderBlock: React.SFC<HeaderBlockProps> = (props: HeaderBlockProps): React.ReactElement<HeaderBlockProps> => (
  <Shadow shadow={1} className={`${namespace()}${props.className ? ' ' + props.className : ''}${props.wide ? ' wide' : ''}`}>
    {props.leftGutter && <div className={`${namespace()}--left-gutter`} style={props.leftGutterSize ? {maxWidth: `${props.leftGutterSize}px`, flex: `1 0 ${props.leftGutterSize}px`} : {}} />}
    <div className={`${namespace()}--content`}>
      {props.children}
    </div>
    {props.hasLeftSideBar && !props.hasRightSideBar && <div className={`${namespace()}--empty-right-sidebar`}/>}
  </Shadow>
);

export default HeaderBlock;
export { default as HeaderBlockPadding } from './Padding';
