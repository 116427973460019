

import * as React from 'react';
import { ApplicationState } from '../../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../../../../../store/Admin/Modals/Accounts/actions";
import {actionCreators as appActionCreators} from '../../../../../../store/App/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../store/Rollback/actions';
import { Row, Switch } from '../../../../../../components/Elements';
import { namespace } from '..';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { connect } from 'react-redux';


interface Props {

}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class ParticipantsFilters extends React.PureComponent<Props, {}> {
  public props: ConnectedProps & Props;
  
  onSwitchShowDeleted = (value, vObj) => {
    this.props.actions.updateValue(value, vObj);
    this.props.actions.getGroups(value, true);
  };

  render() {
    const {
      adminAccounts: { ActiveForm, ValidationRules } } = this.props;
    return (
      <Row className={`${namespace()}--row-3`}>
        <Switch
          label="Show Deleted"
          onChange={this.onSwitchShowDeleted}
          value={Boolean(ActiveForm.ShowInactive)}
          validationRules={ValidationRules.ShowInactive}
        />
      </Row>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    adminAccounts: state.adminAccounts,
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
  ...rollbackActionCreators}, dispatch) });

const ConnectedParticipantsFilters = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ParticipantsFilters);

export default ConnectedParticipantsFilters;