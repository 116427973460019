import { ActionCreator, appActionCreators, ApplicationState } from "../../../..";
import { createValidateActions, createApiValidateActions, createApiSubmitActions, createUpdateValueMethod, createSimpleUpdateValueMethod, createApiSubmitFormMethod, innerApiSubmitFormMethod, getCacheLevelExtra } from "../../../../Validation/actionCreator";
import { ResourceCategoryActiveForm } from ".";
import { getSubmitResourceCategoryBody, getDeleteResourceCategoryBody } from "../../../../../constants/urls";
import { SaveState } from "../../../../../store/Rollback/actions";
import { ToggleModalSaving } from "../../../../../store/App/actions";
import { ADMIN_CMS_MODULE_SUFFIX } from "../../../../../store/AdminCMSSite/CacheOne/actions";
import { ModalTypes, isModalOpened } from "../../../../../utils/modalHelper";
import { typeName, Action } from "../../../../../utils/StrongActions";

export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__CMS_ADD_RESOURCE_CATEGORY_MODAL';
export const DEL_SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__DELETE_RESOURCE_CATEGORY';

@typeName('RESET_FORM' + SUFFIX)
export class ResourceCategoryResetForm extends Action {}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const AddResourceCategorySubmitActions = createApiSubmitActions(SUFFIX);
export const DeleteResourceCategorySubmitActions = createApiSubmitActions(DEL_SUFFIX);

const formCreator = (
  rootState: ApplicationState,
  activeForm: ResourceCategoryActiveForm,
) => {
  const resourceCategory = activeForm.ID && rootState.adminCMSSite.cacheOne.CMSResourceCategories ? rootState.adminCMSSite.cacheOne.CMSResourceCategories.find((rc) => rc.ID === activeForm.ID) : null;
  return getSubmitResourceCategoryBody({
    resourceCategoryId: activeForm.ID || 0,
    resourceCategoryTS: resourceCategory ? resourceCategory.TS : 0
  }, activeForm);
};

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.adminCMSSite.resources.modals.resourceCategoryForm),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  apiSubmitForm: (): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    // form validation (not including file)
    const valid = innerApiSubmitFormMethod(
      dispatch,
      AddResourceCategorySubmitActions,
      (s: ApplicationState) => s.adminCMSSite.resources.modals.resourceCategoryForm,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
    );
    if (valid) {
      dispatch(appActionCreators.popModal(true, false, ModalTypes.RESOURCE_CATEGORY_FORM) as any);
      dispatch(new ResourceCategoryResetForm());
      dispatch(new ToggleModalSaving(true));
      dispatch(AddResourceCategorySubmitActions.request(
        formCreator(getState(), state.adminCMSSite.resources.modals.resourceCategoryForm.ActiveForm),
        getCacheLevelExtra(
          false,
          true,
        )
      ));
      return true;
    }

    return false;
  },
  deleteResourceCategory: (resourceCategoryId: number, restore?: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const state = getState() as ApplicationState;

    if (isModalOpened(ModalTypes.RESOURCE_CATEGORY_FORM)) {
      dispatch(appActionCreators.popModal(false, false, ModalTypes.RESOURCE_CATEGORY_FORM) as any);
      dispatch(new ResourceCategoryResetForm());
    }
    dispatch(DeleteResourceCategorySubmitActions.request(
      getDeleteResourceCategoryBody({
        resourceCategoryId,
      }, !restore),
      getCacheLevelExtra(false, true),
    ));
  },
  resetForm: (save?: boolean): ActionCreator => dispatch => {
    dispatch(new ResourceCategoryResetForm());
    if (save) dispatch(new SaveState());
  },
};