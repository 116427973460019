import * as React from 'react';
import { Media, ProductGroup, Title, Product } from '../../../../Elements';
import {OrderedProduct, Product as ProductModel} from '../../../../../models/product';
import {ProductsFormTemplateSideBar} from '../../../../Templates';
import SlideShow from '../Products/SlideShow';

export interface AddedProps {
  onRemove?: (product: ProductModel) => any;
  onUpdate?: (product: ProductModel, attributes: any) => any;
  onCancel?: (product: ProductModel) => any;
  onCommit?: (product: ProductModel) => any;
  onManage?: (product: ProductModel) => any;
  products: Array<ProductModel>;
  orderedProducts?: Array<OrderedProduct> | null;
  isAdmin?: boolean;
  loading?: boolean;
}

const renderOrderedProduct = (onRemove, onUpdate, isAdmin: boolean, p: ProductModel, index: number, AllowSelfRegistration: boolean) => {
  return (<Product
    key={index}
    productModel={p}
    isCompact
    onRemoveProduct={onRemove}
    onUpdateProduct={onUpdate}
    isAdmin={isAdmin}
    ordered
  />);
};

class ProductsAdded extends React.PureComponent<AddedProps, {}> {
  public props: AddedProps;

  renderProduct = (p: ProductModel, index: number) => {
    const { onCancel, onCommit, onManage, onRemove, onUpdate, isAdmin } = this.props;
    return (<Product
      key={index}
      productModel={p}
      isCompact
      onRemoveProduct={onRemove}
      onUpdateProduct={onUpdate}
      isAdmin={isAdmin}
      onManage={onManage}
      onCommit={onCommit}
      onCancel={onCancel}
    />);
  };

  public render() {
    const { onRemove, onUpdate, products, orderedProducts, isAdmin, loading } = this.props;
    if (!products) return null;
    return (
      <ProductsFormTemplateSideBar>
        <ProductGroup loading={loading}>
          {products.length > 0 && <Media tabletAndGreater>
            <Title size={20} num={products.length} marginBottom={0}>Added Products</Title>
          </Media>}
          {products.map(this.renderProduct)}
          {orderedProducts && orderedProducts.length > 0 && <Media tabletAndGreater>
            <Title size={20} num={orderedProducts.length} marginTop={products.length > 0 ? 24 : 0} marginBottom={0}>Previous Orders</Title>
          </Media>}
          {orderedProducts && orderedProducts.length > 0 && <Media mobileAndSmaller>
            <Title size={20} num={orderedProducts.length} marginTop={24} marginBottom={0}>Previous Orders</Title>
          </Media>}
          {/* @todo-minor-performance: remove these binds */}
          {orderedProducts && orderedProducts.map(renderOrderedProduct.bind(this, onRemove, onUpdate, isAdmin))}
        </ProductGroup>
      </ProductsFormTemplateSideBar>
    );
  }
}

export default ProductsAdded;
