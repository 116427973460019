import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminCMSCacheOneContext } from "@tentaroo/shared";

import { ApplicationState } from '../../../../../../store';
import {
    actionCreators as cacheZeroActionCreators
} from '../../../../../../store/CacheZero/actions';
import {
    actionCreators as adminCMSCacheOneActionCreators, GetSiteCache,
} from '../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as rollbackActionCreators, SaveState } from '../../../../../../store/Rollback/actions';
import { actionCreators as cacheOneActionCreators} from "../../../../../../store/CacheOne/actions";
import { actionCreators as menuItemActionCreators} from "../../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/actions";
import { actionCreators } from "../../../../../../store/AdminCMSSite/Menus/Home/actions";
import { FormDefinition } from "../../../../../../store/AdminCMSSite/Menus/Home/validation";
import { LoadingAll, Tab, Tabs, EmptyMessage, Button, Loader, Footer } from '../../../../../Elements';
import { Main, MainContent } from '../../../../../Layouts';
import { DrawerIcon } from '../../../../../Icons';
import { navPush } from '../../../../../../utils';
import { constructCMSSiteUrlParams, getSiteMenusRootUrl, getPageMenusRootUrl, constructCMSSiteSiteMenuItemUrlParams, getEditSiteMenuItemRootUrl, getNewSiteMenuItemRootUrl, constructCMSSitePageMenuItemUrlParams, getEditPageMenuItemRootUrl, getNewPageMenuItemRootUrl } from '../../../../../../constants/urls';
import '../../../../../../styles/pages/menus/home/index.scss';
import { makeFilteredSiteMenusSelector, makeFilteredPageMenusMapSelector, activePageMenusSelector, activeSiteMenusSelector } from '../../../../../../store/AdminCMSSite/Menus/Home';
import SiteMenuFilters from './SiteMenuFilters';
import PageMenuFilters from './PageMenuFilters';
import { CMSSiteMenuItem, CMSPageMenuItem } from '../../../../../../models/api/adminCMSCacheOne';
import SiteMenuCard, { MenuCardType } from '../../../../../../components/Elements/CMSSiteMenu/Card';
import PageMenuGroup from '../../../../../../components/Elements/CMSPageMenu/Card/Group';
import { ADMIN_CMS_SITE_MENU_EDIT_PATH, ADMIN_CMS_SITE_MENU_NEW_PATH, ADMIN_CMS_SITE_MENUS_PATH, ADMIN_CMS_PAGE_MENUS_PATH, ADMIN_CMS_PAGE_MENU_EDIT_PATH, ADMIN_CMS_PAGE_MENU_NEW_PATH } from '../../../../../../routes';
import SiteMenuItem from '../SiteMenuItem';
import PageMenuItem from '../PageMenuItem';
import { actionCreators as adminCMSCacheTwoSiteMenusActionCreators } from '../../../../../../store/AdminCMSSite/CacheTwoSiteMenus/actions';
import { actionCreators as adminCMSCacheTwoPageMenusActionCreators } from '../../../../../../store/AdminCMSSite/CacheTwoPageMenus/actions';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { ENTITY_NOT_ADDED, ENTITY_NOT_FOUND, MENU_ITEMS, ORDERING_MENU_ITEMS_ALERT, TOGGLE_SHOW_DELETED_BEFORE_CHANGING_MENU_ORDER } from '../../../../../../constants/messages/adminCMS';
import { isMobile } from '../../../../../../utils/isMobile';
import { checkPageMenuPermission, checkSiteMenuPermission, IAdminCMSMenuRouterParams } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { EmptyMessageType } from '../../../../../../components/Elements/EmptyMessage';
import { CardCategory } from '../../../../../../components/Elements/Card';
import AdminCMSCacheManager from '../../../../../../utils/cacheManagers/adminCMSCacheManager';
import { ModalTypes, noOpenedModals } from '../../../../../../utils/modalHelper';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isAdminCMSCacheOnePopulated} from '../../../../../../utils/cachePopulatedCheckers/adminCMS';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--menus';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminCMSMenuRouterParams, {}>
>;

class Menus extends ComponentUpdateTemplate<ConnectedProps> {
  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        const {routes} = this.props;
        const route = routes[routes.length - 1];

        if (route.path === ADMIN_CMS_SITE_MENU_EDIT_PATH || route.path === ADMIN_CMS_SITE_MENU_NEW_PATH) {
          AdminCMSCacheManager.getInstance().loadCacheTwoSiteMenuItem({
            props: this.props,
            isStateNavigated,
            isEdit: route.path === ADMIN_CMS_SITE_MENU_EDIT_PATH,
          });
        } else if (route.path === ADMIN_CMS_PAGE_MENU_EDIT_PATH || route.path === ADMIN_CMS_PAGE_MENU_NEW_PATH) {
          AdminCMSCacheManager.getInstance().loadCacheTwoPageMenuItem({
            props: this.props,
            isStateNavigated,
            isEdit: route.path === ADMIN_CMS_PAGE_MENU_EDIT_PATH,
          });
        } else {
          AdminCMSCacheManager.getInstance().loadAdminCMSCacheOne({
            props: this.props,
            isStateNavigated,
            context: AdminCMSCacheOneContext.MENUS_LIST,
          });
        }
      }
    );
  }

  componentWillUnmount() {
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        const {routes} = this.props;
        const thisRoute = routes[routes.length - 1];
        const nextRoute = nextProps.routes[nextProps.routes.length - 1];
        const thisPath = thisRoute.path;
        const nextPath = nextRoute.path;

        const fromSidebarToNotSiteMenuList = (thisPath === ADMIN_CMS_SITE_MENU_NEW_PATH || thisPath === ADMIN_CMS_SITE_MENU_EDIT_PATH) && nextPath !== ADMIN_CMS_SITE_MENUS_PATH;
        const fromSidebarToNotPageMenuList = (thisPath === ADMIN_CMS_PAGE_MENU_NEW_PATH || thisPath === ADMIN_CMS_PAGE_MENU_EDIT_PATH) && nextPath !== ADMIN_CMS_PAGE_MENUS_PATH;

        if (nextPath === ADMIN_CMS_SITE_MENU_EDIT_PATH ||
            nextPath === ADMIN_CMS_SITE_MENU_NEW_PATH) {
          AdminCMSCacheManager.getInstance().loadCacheTwoSiteMenuItem({
            props: nextProps,
            isStateNavigated,
            isEdit: nextPath === ADMIN_CMS_SITE_MENU_EDIT_PATH,
          });
        } else if (nextPath === ADMIN_CMS_PAGE_MENU_EDIT_PATH ||
          nextPath === ADMIN_CMS_PAGE_MENU_NEW_PATH) {
          AdminCMSCacheManager.getInstance().loadCacheTwoPageMenuItem({
            props: nextProps,
            isStateNavigated,
            isEdit: nextPath === ADMIN_CMS_PAGE_MENU_EDIT_PATH,
          });
        } else {
          AdminCMSCacheManager.getInstance().loadAdminCMSCacheOne({
            props: nextProps,
            isStateNavigated,
            context: (fromSidebarToNotSiteMenuList || fromSidebarToNotPageMenuList) ? AdminCMSCacheOneContext.MENUS_LIST : undefined
          });
        }
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    const {routes} = this.props;
    const thisRoute = routes[routes.length - 1];
    const prevRoute = prevProps.routes[prevProps.routes.length - 1];
    const thisPath = thisRoute.path;
    const prevPath = prevRoute.path;

    if (shouldBlockActions()) return;

    if (
      thisPath !== prevPath &&
      (thisPath === ADMIN_CMS_SITE_MENUS_PATH || thisPath === ADMIN_CMS_PAGE_MENUS_PATH) &&
      prevPath !== ADMIN_CMS_SITE_MENUS_PATH && prevPath !== ADMIN_CMS_SITE_MENUS_PATH
    ) {
      reduxStoreService().dispatch(new SaveState());
    }
  }

  onClickSiteMenus = () => {
    const url = getSiteMenusRootUrl(constructCMSSiteUrlParams(this.props, this.props.adminCMSCacheOne));
    navPush(this.props.router, url);

  };
  onClickPageMenus = () => {
    const url = getPageMenusRootUrl(constructCMSSiteUrlParams(this.props, this.props.adminCMSCacheOne));
    navPush(this.props.router, url);
  };

  onNewSiteMenu = (e) => {
    const {menusHome, routes} = this.props;
    const route = routes[routes.length - 1];

    // dont respond when we are editing site menu
    if (route.path === ADMIN_CMS_SITE_MENU_EDIT_PATH) return;
    if (menusHome.orderingSiteMenu) {
      this.props.actions.showTopFloatingAlert(ORDERING_MENU_ITEMS_ALERT, undefined, 'orange');
      return;
    }
    checkSiteMenuPermission(
      () => {
        const newSiteMenuItemUrl = getNewSiteMenuItemRootUrl(constructCMSSiteSiteMenuItemUrlParams(this.props, this.props.adminCMSCacheOne));
        navPush(this.props.router, newSiteMenuItemUrl);
      },
      this.props.adminCMSCacheOne,
    );
  };

  renderFilters = () => {
    const {apiSaving, menusHome, actions} = this.props;
    const selectedTab = this.getSelectedTab();
    if (selectedTab === 'site') {
      return (
        <div className={`${namespace()}--list-actions`}>
          <SiteMenuFilters
            menusHome={menusHome}
            disabled={apiSaving > 0}
            ActiveForm={menusHome.ActiveForm}
            ValidationRules={menusHome.ValidationRules}
            reduxActions={actions}
            expand={menusHome.expandSiteMenuFilter}
            control={
              <Button id={generateDOMId("admin-site-menu-add-btn")} className='control' color='green' textColor='white' onClick={this.onNewSiteMenu}>New</Button>
            } 
          />
        </div>
      );
    } else if (selectedTab === 'page') {
      return (
        <div className={`${namespace()}--list-actions`}>
          <PageMenuFilters
            menusHome={menusHome}
            disabled={apiSaving > 0}
            ActiveForm={menusHome.ActiveForm}
            ValidationRules={menusHome.ValidationRules}
            reduxActions={actions}
            expand={menusHome.expandPageMenuFilter}
            control={
              <Button id={generateDOMId("admin-page-menu-add-btn")} className='control' color='green' textColor='white' onClick={this.onNewPageMenuGroup}>New Menu</Button>
            } 
          />
        </div>
      );
    }
  };

  onNewPageMenuGroup = (menu: CMSPageMenuItem) => {
    const {menusHome, routes} = this.props;
    const route = routes[routes.length - 1];

    // dont respond when we are editing page menu
    if (route.path === ADMIN_CMS_PAGE_MENU_EDIT_PATH || route.path === ADMIN_CMS_PAGE_MENU_NEW_PATH) return;
    if (menusHome.orderingPageMenu) {
      this.props.actions.showTopFloatingAlert(ORDERING_MENU_ITEMS_ALERT, undefined, 'orange');
      return;
    }
    checkPageMenuPermission(
      () => {
        this.props.actions.pushFormModal(ModalTypes.PAGE_MENU_ITEM_FORM, false);
      },
      this.props.adminCMSCacheOne,
    );
  };
  onNewPageMenu = (item: CMSPageMenuItem) => {
    const {menusHome, routes} = this.props;
    if (menusHome.orderingPageMenu) {
      this.props.actions.showTopFloatingAlert(ORDERING_MENU_ITEMS_ALERT, undefined, 'orange');
      return;
    }
    checkPageMenuPermission(
      () => {
        const newPageMenuItemUrl = getNewPageMenuItemRootUrl(constructCMSSitePageMenuItemUrlParams(this.props, this.props.adminCMSCacheOne, item));
        navPush(this.props.router, newPageMenuItemUrl);
      },
      this.props.adminCMSCacheOne,
    );
  };
  onEditGroup = (menu: CMSPageMenuItem) => {
    if (menu.Inactive) return;
    const {menusHome} = this.props;
    if (menusHome.orderingPageMenu) {
      this.props.actions.showTopFloatingAlert(ORDERING_MENU_ITEMS_ALERT, undefined, 'orange');
      return;
    }

    checkPageMenuPermission(
      () => {
        this.props.actions.pushFormModal(ModalTypes.PAGE_MENU_ITEM_FORM, true, {id: menu.ID});
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };

  clearPageMenuFilter = () => {
    const {menusHome: {ActiveForm}} = this.props;
    if (ActiveForm.PageMenusShowDeleted) this.props.actions.updateValue(false, FormDefinition.PageMenusShowDeleted);
    if (ActiveForm.PageMenusFilterText) this.props.actions.updateValue('', FormDefinition.PageMenusFilterText);
  };
  onMoveUpPageMenuItem = (item: CMSPageMenuItem, offset?: number) => {
    if (this.props.menusHome.ActiveForm.PageMenusShowDeleted) {
      this.props.actions.showTopFloatingAlert(TOGGLE_SHOW_DELETED_BEFORE_CHANGING_MENU_ORDER, undefined, 'orange');
      return;
    }
    checkPageMenuPermission(
      () => {
        this.clearPageMenuFilter();
        this.props.actions.movePageMenuItem(item, offset || -1);
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };
  onMoveDownPageMenuItem = (item: CMSPageMenuItem, offset?: number) => {
    if (this.props.menusHome.ActiveForm.PageMenusShowDeleted) {
      this.props.actions.showTopFloatingAlert(TOGGLE_SHOW_DELETED_BEFORE_CHANGING_MENU_ORDER, undefined, 'orange');
      return;
    }
    checkPageMenuPermission(
      () => {
        this.clearPageMenuFilter();
        this.props.actions.movePageMenuItem(item, offset || 1);
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };
  onEditPageMenuItem = (item: CMSPageMenuItem) => {
    if (item.Inactive) return;
    const {menusHome} = this.props;
    if (menusHome.orderingPageMenu) {
      this.props.actions.showTopFloatingAlert(ORDERING_MENU_ITEMS_ALERT, undefined, 'orange');
      return;
    }
    // @todo - validate if we are reordering list
    const editPageMenuItemUrl = getEditPageMenuItemRootUrl(constructCMSSitePageMenuItemUrlParams(this.props, this.props.adminCMSCacheOne, item));
    navPush(this.props.router, editPageMenuItemUrl);
  };
  onPageMenuGroupRestore = (item: CMSPageMenuItem) => {
    checkPageMenuPermission(
      () => {
        this.props.menuItemActions.deleteMenuItem(item.ID, true, true, this.props.routes, this.props.router);
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };
  onPageMenuRestore = (item: CMSPageMenuItem) => {
    checkPageMenuPermission(
      () => {
        this.props.menuItemActions.deleteMenuItem(item.ID, true, true, this.props.routes,  this.props.router);
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };
  onDeletePageMenuItem = (item: CMSPageMenuItem) => {
    checkPageMenuPermission(
      () => {
        this.props.actions.configDeletePageMenuItemModal(item);
        this.props.actions.pushDeleteMenuItemModal();
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };

  renderPageMenusTab = () => {
    const {pageMenusMap, menusHome, adminCMSCacheOne, routes, activePageMenus} = this.props;

    const source = menusHome.orderedPageMenu || pageMenusMap;
    const result: any[] = [];
    const route = routes[routes.length - 1];
    let filteredItemCount = 0;

    for (let key of Object.keys(source)) {
      const items = source[key];

      const group = items[0];
      const children = [...items];
      children.splice(0, 1);
      filteredItemCount += 1;
      if (menusHome.ActiveForm.PageMenusShowDeleted || !group.Inactive) {
        filteredItemCount += menusHome.ActiveForm.PageMenusShowDeleted ? children.length : children.filter((c) => !c.Inactive).length;
      }

      const SortableItem = SortableElement(({value}) => {
        const menuItem = {...value};
        // if parent is inactive but child is active, show it as disabled
        if (group.Inactive && !menuItem.Inactive) menuItem.Inactive = true;
        return (
          <SiteMenuCard
            onDelete={this.onDeletePageMenuItem}
            routes={routes}
            type={MenuCardType.PAGE_MENU_LIST}
            childrenNum={0}
            cardCategory={CardCategory.LIST}
            adminCMSCacheOne={adminCMSCacheOne}
            menuItem={menuItem}
            onEdit={this.onEditPageMenuItem}
            // when parent is inactive, no restore button in child
            onRestore={group.Inactive ? undefined : this.onPageMenuRestore}
            onMoveDown={this.onMoveDownPageMenuItem}
            onMoveUp={this.onMoveUpPageMenuItem}
        />
        );
      });
      const SortableList = SortableContainer(({items, sortKey}) => {
        return (
          <ul id={`page-menu-list-${sortKey}`}>
            {items.map((value, index) => {
              // if parent is inactive and child is also inactive, hide it by returning null
              if (value.Inactive && group.Inactive) return null;
              return (
                <SortableItem
                  disabled={isMobile || route.path === ADMIN_CMS_PAGE_MENU_EDIT_PATH || route.path === ADMIN_CMS_PAGE_MENU_NEW_PATH}
                  key={`page-menu-item-${index}`}
                  index={index}
                  value={value} />
              );
            })}
          </ul>
        );
      });

      result.push(
        <PageMenuGroup
          onExpand={this.onExpandPageMenu}
          onRestore={this.onPageMenuGroupRestore}
          key={`page_menu_group_${group.ID}`}
          menuItem={group}
          routes={routes}
          childrenNum={children.length}
          activeChildrenNum={children.filter((p) => !p.Inactive).length}
          onNewPageMenu={() => this.onNewPageMenu(group)}
          onEditGroup={this.onEditGroup}
          allowPropagation={route.path === ADMIN_CMS_PAGE_MENU_EDIT_PATH || route.path === ADMIN_CMS_PAGE_MENU_NEW_PATH}
          onDeleteGroup={this.onDeletePageMenuItem}
        >
          <SortableList
            helperClass={'page-menu-sort-helper'}
            helperContainer={() => document.getElementById(`page-menu-list-${key}`) as any} distance={10} key={key} items={children} sortKey={key}
            onSortEnd={({oldIndex, newIndex}) => this.onPageMenuItemSortEnd(oldIndex, newIndex, children)}
          />
        </PageMenuGroup>
      );
    }
    let emptyMessage;

    if (activePageMenus.length === 0 && filteredItemCount === 0) {
      // if we dont have any result (after filtered by show deleted), but user didnt type any filter text
      emptyMessage = ENTITY_NOT_ADDED(MENU_ITEMS);;
    } else if (filteredItemCount === 0) {
      emptyMessage = ENTITY_NOT_FOUND(MENU_ITEMS);
    }

    if (emptyMessage) {
      return this.renderEmptyMessage(emptyMessage);
    }

    return (
      <div className={`${namespace()}--list-wrapper page-menus`}>{result}</div>
    );
  };

  onPageMenuItemSortEnd = (oldIndex, newIndex, list) => {
    const offset = newIndex - oldIndex;
    const item = list[oldIndex];
    if (offset > 0) {
      this.onMoveDownPageMenuItem(item, offset);
    } else if (offset < 0){
      this.onMoveUpPageMenuItem(item, offset);
    }
  };

  onExpandPageMenu = (item: CMSPageMenuItem) => {
    this.props.actions.expandPageMenu(item.ID);
  };

  onExpandSiteMenu = (item: CMSSiteMenuItem) => {
    this.props.actions.expandSiteMenu(item.ID);
  };
  onEditSiteMenuClick = (menuItem: CMSSiteMenuItem) => {
    if (menuItem.Inactive) return;
    const {menusHome} = this.props;
    if (menusHome.orderingSiteMenu) {
      this.props.actions.showTopFloatingAlert(ORDERING_MENU_ITEMS_ALERT, undefined, 'orange');
      return;
    }
    const editSiteMenuItemUrl = getEditSiteMenuItemRootUrl(constructCMSSiteSiteMenuItemUrlParams(this.props, this.props.adminCMSCacheOne, menuItem));
    navPush(this.props.router, editSiteMenuItemUrl);
  };

  clearSiteMenuFilter = () => {
    const {menusHome: {ActiveForm}} = this.props;
    if (ActiveForm.SiteMenusShowDeleted) this.props.actions.updateValue(false, FormDefinition.SiteMenusShowDeleted);
    if (ActiveForm.SiteMenusFilterText) this.props.actions.updateValue('', FormDefinition.SiteMenusFilterText);
  };
  onMoveUp = (item: any, offset?: number, isParent?: boolean) => {
    if (this.props.menusHome.ActiveForm.SiteMenusShowDeleted) {
      this.props.actions.showTopFloatingAlert(TOGGLE_SHOW_DELETED_BEFORE_CHANGING_MENU_ORDER, undefined, 'orange');
      return;
    }
    checkSiteMenuPermission(
      () => {
        this.clearSiteMenuFilter();
        this.props.actions.moveSiteMenuItem(item, offset || -1, !!isParent);
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };
  onMoveDown = (item: any, offset?: number, isParent?: boolean) => {
    if (this.props.menusHome.ActiveForm.SiteMenusShowDeleted) {
      this.props.actions.showTopFloatingAlert(TOGGLE_SHOW_DELETED_BEFORE_CHANGING_MENU_ORDER, undefined, 'orange');
      return;
    }
    checkSiteMenuPermission(
      () => {
        this.clearSiteMenuFilter();
        this.props.actions.moveSiteMenuItem(item, offset || 1, !!isParent);
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };
  onSiteMenuItemRestore = (item: CMSSiteMenuItem) => {
    checkSiteMenuPermission(
      () => {
        this.props.menuItemActions.deleteMenuItem(item.ID, true, false, this.props.routes, this.props.router);
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };
  onClickMain = (e) => {
    // if we are clicking on the `tab`, dont do anything in this callback (i.e. manually stop propagation)
    if (e.target.classList.contains('mdl-tabs__tab')) return;
    e.stopPropagation();
    const { routes, router, apiSaving, adminCMSCacheOne} = this.props;
    const thisRoute = routes[routes.length - 1];

    if (apiSaving <= 0 && adminCMSCacheOne.CMSSite) {
      if (thisRoute.path === ADMIN_CMS_SITE_MENU_EDIT_PATH || thisRoute.path === ADMIN_CMS_SITE_MENU_NEW_PATH) {
        const backUrl = getSiteMenusRootUrl(constructCMSSiteUrlParams(this.props, adminCMSCacheOne));
        navPush(router, backUrl);
      } else if (thisRoute.path === ADMIN_CMS_PAGE_MENU_EDIT_PATH || thisRoute.path === ADMIN_CMS_PAGE_MENU_NEW_PATH) {
        const backUrl = getPageMenusRootUrl(constructCMSSiteUrlParams(this.props, adminCMSCacheOne));
        navPush(router, backUrl);
      }
    }
  };
  onDeleteSiteMenuItem = (item: CMSSiteMenuItem) => {
    checkSiteMenuPermission(
      () => {
        this.props.actions.configDeleteSiteMenuItemModal(item);
        this.props.actions.pushDeleteMenuItemModal();
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };
  renderSiteMenusTab = () => {
    const {siteMenus, menusHome, routes, adminCMSCacheOne, activeSiteMenus} = this.props;

    const source = menusHome.orderedSiteMenus || siteMenus;
    const route = routes[routes.length - 1];

    const SortableListItem = SortableElement(({value}) => {
      const {item} = value;
      return (
        <SiteMenuCard
          type={MenuCardType.LIST}
          routes={routes}
          onDelete={this.onDeleteSiteMenuItem}
          adminCMSCacheOne={adminCMSCacheOne}
          cardCategory={CardCategory.LIST}
          childrenNum={0}
          activeChildrenNum={0}
          menuItem={item}
          onExpand={this.onExpandSiteMenu}
          onEdit={this.onEditSiteMenuClick}
          onMoveDown={this.onMoveDown}
          onMoveUp={this.onMoveUp}
          onRestore={this.onSiteMenuItemRestore}
        />
      );
    });
    const makeSortableItemList = (groupIndex) => {
      return SortableContainer(({items}) => {
        return (
          <ul id={`site-menu-list${groupIndex}`}>
            {items.map((item, index) => (
              <SortableListItem
                disabled={isMobile || route.path === ADMIN_CMS_SITE_MENU_EDIT_PATH || route.path === ADMIN_CMS_SITE_MENU_NEW_PATH}
                key={`site-menu-item-${item.ID}`}
                index={index} value={{item}}
                />
            ))}
          </ul>
        );
      });
    };
    const SortableSectionItem = SortableElement(({groupIndex, group, item}) => {
      const SortableItemList = makeSortableItemList(groupIndex);
      const children = [...group];
      children.splice(0, 1);
      return (
        <div>
            <SiteMenuCard
              type={MenuCardType.LIST}
              routes={routes}
              onDelete={this.onDeleteSiteMenuItem}
              adminCMSCacheOne={adminCMSCacheOne}
              childrenNum={group.length - 1}
              cardCategory={group.length - 1 > 0 ? CardCategory.LIST_MOBILE : CardCategory.LIST}
              activeChildrenNum={group.filter((item) => !item.Inactive).length - 1}
              menuItem={item}
              onExpand={this.onExpandSiteMenu}
              onEdit={this.onEditSiteMenuClick}
              onMoveDown={(item) => this.onMoveDown(group, 1, true)}
              onMoveUp={(item) => this.onMoveUp(group, -1, true)}
              onRestore={this.onSiteMenuItemRestore}
            />
            <SortableItemList
              helperClass={'site-menu-sort-helper'}
              helperContainer={() => document.getElementById(`site-menu-list${groupIndex}`) as any} distance={10} items={item.Expand ? children : []}
              onSortEnd={({oldIndex, newIndex}) => this.onSiteMenuItemSortEnd(oldIndex, newIndex, item.Expand ? children : [] as any)}
            />
        </div>
      );
    });

    const SortableSectionList = SortableContainer(({source}) => {
      return (
        <div id={`site-menu-list-section`}>
          {source.map((group, groupIndex) => {
            return (
            <SortableSectionItem
              disabled={isMobile || route.path === ADMIN_CMS_SITE_MENU_EDIT_PATH || route.path === ADMIN_CMS_SITE_MENU_NEW_PATH}
              groupIndex={groupIndex}
              group={group}
              key={`site_menu_sortable_section_${group[0].ID}`}
              item={group[0]}
              index={groupIndex} />);
          })}
        </div>
      );
    });

    let filteredItemCount = 0;
    for (let group of source) {
      group.forEach((item, index) => {
        // only count active item if parent item is active
        if (menusHome.ActiveForm.SiteMenusShowDeleted || !group[0].Inactive) filteredItemCount += (menusHome.ActiveForm.SiteMenusShowDeleted || !item.Inactive) ? 1 : 0;
      });
    }
    let emptyMessage;
    let renderEmptyMessageAction;

    if (activeSiteMenus.length === 0 && filteredItemCount === 0) {
      // if we dont have any result (after filtered by show deleted), but user didnt type any filter text
      emptyMessage = ENTITY_NOT_ADDED(MENU_ITEMS);
      renderEmptyMessageAction = true;
    } else if (filteredItemCount === 0) {
      emptyMessage = ENTITY_NOT_FOUND(MENU_ITEMS);
      renderEmptyMessageAction = false;
    }

    if (emptyMessage) {
      return this.renderEmptyMessage(emptyMessage);
    }

    return (
      <div className={`${namespace()}--list-wrapper`}>
          <SortableSectionList
            helperClass={'site-menu-sort-helper'}
            helperContainer={() => document.getElementById(`site-menu-list-section`) as any} 
            onSortEnd={({oldIndex, newIndex}) => this.onSiteMenuItemSortEnd(oldIndex, newIndex, source, true)}
            source={source} distance={10} />
      </div>
    );
  };

  onSiteMenuItemSortEnd = (oldIndex, newIndex, items: any[], isParent?: boolean) => {
    const offset = newIndex - oldIndex;
    const item = items[oldIndex];
    if (offset > 0) {
      this.onMoveDown(item, offset, isParent);
    } else if (offset < 0){
      this.onMoveUp(item, offset, isParent);
    }
  };

  changeSiteMenuOrder = () => {
    const {menusHome: {orderedSiteMenus}} = this.props;
    if (!orderedSiteMenus) return;
    let result: CMSSiteMenuItem[] = [];
    for (let menus of orderedSiteMenus) {
      result = result.concat(menus);
    }
    this.props.actions.updateSiteMenuItemOrder(result);
  };
  changePageMenuOrder = () => {
    const {menusHome: {orderedPageMenu}} = this.props;
    if (!orderedPageMenu) return;

    let result: CMSPageMenuItem[] = [];

    for (let key of Object.keys(orderedPageMenu)) {
      const menus = orderedPageMenu[key];
      result = result.concat(menus);
    }
    this.props.actions.updatePageMenuItemOrder(result);
  };
  cancelOrderChange = () => {
    this.props.actions.cancelReorder();
  };

  getSelectedTab = () => {
    const {routes} = this.props;
    const thisRoute = routes[routes.length - 1];
    const selectedTab = (thisRoute.path === ADMIN_CMS_SITE_MENUS_PATH || thisRoute.path === ADMIN_CMS_SITE_MENU_EDIT_PATH || thisRoute.path === ADMIN_CMS_SITE_MENU_NEW_PATH) ? 'site' : ((thisRoute.path === ADMIN_CMS_PAGE_MENUS_PATH || thisRoute.path === ADMIN_CMS_PAGE_MENU_EDIT_PATH || thisRoute.path === ADMIN_CMS_PAGE_MENU_NEW_PATH) ? 'page' : '');

    return selectedTab;
  };

  renderEmptyMessage = (emptyMessage) => {
    const selectedTab = this.getSelectedTab();
    return (
      <EmptyMessage
        icon={DrawerIcon}
        iconHeight='96px'
        iconWidth='96px'
        type={EmptyMessageType.PAGE_MARGIN}
        fixedFontSize
        description={emptyMessage} 
        actions={<Button className={`${namespace()}--empty-message-btn`} color="green" onClick={selectedTab === 'site' ? this.onNewSiteMenu : this.onNewPageMenuGroup}>{selectedTab === 'site' ? 'NEW' : 'NEW MENU'}</Button>}/>
    );
  };

  render() {
    const {routes, menusHome, inert, apiLoadingMap, apiSaving, app} = this.props;
    const thisRoute = routes[routes.length - 1];
    if (!isAdminCMSCacheOnePopulated()) {
        return <LoadingAll />;
    }
    const selectedTab = this.getSelectedTab();
    const refreshingList = apiLoadingMap[GetSiteCache.requestType] && noOpenedModals();
    const saving = apiSaving > 0 && noOpenedModals();

    return (
      <Main inert={inert} isLoading={refreshingList} onClick={this.onClickMain} isCMSSite>
        <MainContent className={namespace()} handleCompact>
          <Tabs className={`${namespace()}--tabs`}>
            <Tab id={generateDOMId("site-menu-tab")} selected={selectedTab === 'site'} onClick={this.onClickSiteMenus}>SITE NAVIGATION</Tab>
            <Tab id={generateDOMId("page-menu-tab")} selected={selectedTab === 'page'} onClick={this.onClickPageMenus}>MENUS ON PAGES</Tab>
          </Tabs>
          {this.renderFilters()}
          {!isMobile && <div className={`${namespace()}--notes`}>You can drag and drop menu items to change their order</div>}
          {selectedTab === 'site' && this.renderSiteMenusTab()}
          {selectedTab === 'page' && this.renderPageMenusTab()}
        </MainContent>
        {selectedTab === 'site' && menusHome.orderingSiteMenu ? <Footer handleCompact>
          <Button color='white' flat textColor='green' onClick={this.changeSiteMenuOrder}>SAVE ORDER CHANGES</Button>
          <Button color='white' flat textColor='black' onClick={this.cancelOrderChange}>CANCEL</Button>
        </Footer> : null}
        {selectedTab === 'page' && menusHome.orderingPageMenu ? <Footer handleCompact>
          <Button color='white' flat textColor='green' onClick={this.changePageMenuOrder}>SAVE ORDER CHANGES</Button>
          <Button color='white' flat textColor='black' onClick={this.cancelOrderChange}>CANCEL</Button>
        </Footer> : null}
        {(thisRoute.path === ADMIN_CMS_SITE_MENU_EDIT_PATH || thisRoute.path === ADMIN_CMS_SITE_MENU_NEW_PATH) && (
          <SiteMenuItem
            onDelete={thisRoute.path === ADMIN_CMS_SITE_MENU_EDIT_PATH ? (item) => this.onDeleteSiteMenuItem(item) : undefined}
            type={thisRoute.path === ADMIN_CMS_SITE_MENU_EDIT_PATH ? 'edit' : 'add'}
            key='site-menu-item-modal'
            />
        )}
        {(thisRoute.path === ADMIN_CMS_PAGE_MENU_EDIT_PATH || thisRoute.path === ADMIN_CMS_PAGE_MENU_NEW_PATH) && (
          <PageMenuItem
            onDelete={ADMIN_CMS_PAGE_MENU_EDIT_PATH ? this.onDeletePageMenuItem : undefined}
            key='page-menu-item-modal'
            type={thisRoute.path === ADMIN_CMS_PAGE_MENU_EDIT_PATH ? 'edit' : 'add'}
          />
        )}
        {saving && <Loader className={`${namespace()}--loader`} center />}
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const siteMenusSelector = makeFilteredSiteMenusSelector();
  const pageMenusMapSelector = makeFilteredPageMenusMapSelector();
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    adminCMSCacheTwoPageMenuItems: state.adminCMSSite.cacheTwoPageMenuItems,
    adminCMSCacheTwoSiteMenuItems: state.adminCMSSite.cacheTwoSiteMenuItems,
    cacheZero: state.cacheZero,
    cacheOne: state.cacheOne,
    app: state.app,
    menusHome: state.adminCMSSite.menus.home,
    siteMenus: siteMenusSelector(state),
    pageMenusMap: pageMenusMapSelector(state),
    activeSiteMenus: activeSiteMenusSelector(state),
    activePageMenus: activePageMenusSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheZeroActionCreators,
    ...appActionCreators,
    ...cacheOneActionCreators,
    ...adminCMSCacheOneActionCreators,
    ...adminCMSCacheTwoSiteMenusActionCreators,
    ...adminCMSCacheTwoPageMenusActionCreators,
    ...rollbackActionCreators,
  }, dispatch),
  menuItemActions: bindActionCreators({
    ...menuItemActionCreators,
  }, dispatch),
});
const ConnectedMenus = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(Menus);

export default withRouter<{}>(ConnectedMenus);