import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/elements/alert/actions.scss';

export const namespace = (): string => `${ns()}--actions`;

export interface ActionsProps {
  children?: React.ReactNode;
}

const Actions: React.SFC<ActionsProps> = ({ children }: ActionsProps): React.ReactElement<ActionsProps> => (
  <div className={namespace()}>
    {children}
  </div>
);

export default Actions;
