import { Reducer } from 'redux';
import { createSelector } from 'reselect';
import { ApplicationState } from '../../..';
import { listSelector } from '../../../../utils/reselectHelper';
import { isActionType } from '../../../../utils/StrongActions';
import { LocationToggleFilterTabs, LocationModalFilter } from './actions';

export interface LocationModalState {
  filterString: string;
  isTextSearching: boolean;
}
const getInitialState = () => ({
  filterString: '',
  isTextSearching: false,
});

const _locationsSelector = (state: ApplicationState) => {
  if (state.cacheZero.options && state.cacheZero.options.Locations) return state.cacheZero.options.Locations;
  return [];
};
export const locationsSelector = listSelector(_locationsSelector);

const filterSelector = (state: ApplicationState) => state.adminSettings.modals.location.filterString;

export const sortLocations = (locations: Array<any>) => {
  const hasFacilities = locations.filter((l) => l.NumFacilities > 0);
  const noFacility = locations.filter((l) => l.NumFacilities <= 0);

  return [...hasFacilities.sort((a, b) => a.Name.localeCompare(b.Name)), ...noFacility.sort((a, b) => a.Name.localeCompare(b.Name))];
};

export const makeFilteredLocationsSelector = (sort?: boolean) => {
  return createSelector(
    [locationsSelector, filterSelector],
    (locations: Array<any>, filter: string) => {
      let result;
      // use `ClientInactive` to check if the resource category is just deleted within the modal
      if (!filter || filter === '') result = locations.filter(location => !location.Inactive);
      const f = filter.toLowerCase();
      result = locations.filter(location => ((location.Name.toLowerCase().includes(f) || location.City.toLowerCase().includes(f) || location.Address.toLowerCase().includes(f)) && !location.Inactive));

      if (!sort) return result;

      return sortLocations(result);
    }
  );
};

const LocationModalReducer: Reducer<LocationModalState> = (state: LocationModalState, act) => {
  if (isActionType(act, LocationToggleFilterTabs)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, LocationModalFilter)) {
    return {
      ...state,
      filterString: act.filterString,
    };
  }
  return state || getInitialState();
};

export default LocationModalReducer;