import { makeRequestEpic } from "../../../../../store/Validation/epicCreator";
import { GetEventTypeAction, AddEventTypeSubmitActions } from "./actions";
import { getEventTypeCache, getEventTypeBody, getUpdateAdminEventType, getEventsHomeRootUrl } from "../../../../../constants/adminEventsUrls";
import { AjaxResponse } from "rxjs/Rx";
import { ENTITY_CREATED } from "../../../../../constants/messages/adminCMS";
import { ADMIN_EVENT_TYPE } from "../../../../../constants/messages/adminEvents";

export const getAdminEventsTypeEpic = makeRequestEpic(
  GetEventTypeAction,
  getEventTypeCache,
  getEventTypeBody,
);

export const adminEventsAddEventTypeEpic = makeRequestEpic(
  AddEventTypeSubmitActions,
  getUpdateAdminEventType,
  undefined,
  true,
  (response: AjaxResponse) => {
    return ENTITY_CREATED(ADMIN_EVENT_TYPE);
  },
  (response: AjaxResponse) => {
    const eventEventType = response.response.EventsEventType;

    if (!eventEventType) return '';
    return getEventsHomeRootUrl({
      eventTypeId: eventEventType.EventTypeRow.ID,
      eventTypeName: eventEventType.General.Name,
    });
  },
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  true,
);