
import { Actions as CacheOneActions } from '../../store/AdminEvents/CacheOne/actions';
import { Actions as CacheTwoProductActions } from '../../store/AdminEvents/CacheTwoProduct/actions';
import { Actions as CacheTwoEventActions } from '../../store/AdminEvents/CacheTwoEvent/actions';
import { Actions as CacheThreeClassTypeActions } from '../../store/AdminEvents/CacheThreeClassType/actions';
import { Actions as CacheThreeClassActions } from '../../store/AdminEvents/CacheThreeClass/actions';
import { Actions as CacheTwoMessageAction } from '../../store/AdminEvents/CacheTwoMessage/actions';
import { Actions as RollbackActions } from '../../store/Rollback/actions';
import { ADMIN_EVENTS_EVENTS_PATH, ADMIN_EVENTS_PRODUCTS_PATH, ADMIN_EVENTS_REPORTS_PATH, ADMIN_EVENTS_YEAR_OVERVIEW_PATH, ADMIN_EVENTS_MESSAGE_CENTER_PATH, ADMIN_EVENT_SETTINGS_PATH, ADMIN_EVENT_TIME_BLOCKS_PATH, ADMIN_EVENT_DASHBOARD_PATH, ADMIN_EVENT_CLASS_TYPES_PATH, ADMIN_EVENT_CLASSES_PATH, ADMIN_EVENTS_SETTINGS_PATH, ADMIN_EVENTS_EDIT_PRODUCT_PATH, ADMIN_EVENTS_EDIT_MESSAGE_PATH, ADMIN_EVENT_EDIT_CLASS_TYPE_PATH, ADMIN_EVENT_EDIT_CLASS_PATH } from '../../routes';
import { extractRouteParams } from '../urlHelper';
import { AdminEventClassParticipant, AdminClassParticipantStatus } from '../../models/api/adminEventsCacheTwoEvent';
import { getClassParticipantStatusKey } from '../../store/AdminEvents/Events/Modals/EnterClassRequirement/actions';
import { CacheZeroState } from '../../store/CacheZero';
import { AdminEventsCacheOneState } from '../../store/AdminEvents/CacheOne';
import { checkPermission } from '../permissionHelper';
import { AdminEventsCacheTwoEventState } from '../../store/AdminEvents/CacheTwoEvent';
import { EventType } from '../../models/api/options';
import { RouteComponentProps } from 'react-router';
import { reduxStoreService } from '../../store/service';

type RefreshActions = CacheOneActions & CacheTwoProductActions & CacheTwoMessageAction & RollbackActions;

type CacheTwoEventRefreshActions = CacheOneActions & CacheTwoEventActions & CacheThreeClassTypeActions & CacheThreeClassActions & RollbackActions;

type RefreshProps = RouteComponentProps<IAdminEventsRouterParams, {}>;

export interface IAdminEventsRouterParams {
  [key: string]: any;
  eventTypeId: string;
  eventTypeName?: string;
}

export interface IAdminEventRouterParams extends IAdminEventsRouterParams {
  eventId: string;
  eventName?: string;
}

export interface IAdminMessageRouterParams extends IAdminEventsRouterParams {
  messageId: string | undefined;
}

export interface IAdminProductRouterParams extends IAdminEventsRouterParams {
  productId: string | undefined;
}

export interface IAdminClassRouterParams extends IAdminEventRouterParams {
  classId: string | undefined;
}

export interface IAdminClassTypeRouterParams extends IAdminEventRouterParams {
  classTypeId: string | undefined;
}

const refreshEvents = (props: RefreshProps, actions: RefreshActions) => {
  actions.refresh({
    GetEvents: true,
    MaxEventIDi: 0,
    ...extractRouteParams(props),
  }, false);
};
const refreshProducts = (props: RefreshProps, actions: RefreshActions) => {
  actions.refresh({
    GetProducts: true,
    MaxProductIDi: 0,
    ...extractRouteParams(props),
  }, false);
};
const refreshProduct = (props: RefreshProps, actions: RefreshActions) => {
  actions.saveState();
  actions.refreshProduct({
    GetProducts: true,
    MaxProductIDi: 0,
    // NOTE: It is safe to do a type cast here because this can only be called in Edit Product page
    ProductIDi: getAdminEventProductIdFromPath(props.params as IAdminProductRouterParams),
    ...extractRouteParams(props),
  });
};

const refreshReports = (props: RefreshProps, actions: RefreshActions) => {
  actions.refresh({
    EventTypeTS: 0,
    ...extractRouteParams(props),
  }, false);
};

const refreshYearOverview = (props: RefreshProps, actions: RefreshActions) => {
  const state = reduxStoreService().getState();
  if (!state.adminEvents.reports.yearOverview.ActiveForm.Year) return;
  actions.saveState();
  actions.refresh({
    GetYearOverview: true,
    YearOverview_Year: state.adminEvents.reports.yearOverview.ActiveForm.Year,
    ...extractRouteParams(props),
  }, false);
};

const refreshMessageCenter = (props: RefreshProps, actions: RefreshActions) => {
  actions.refresh({
    MaxMessageID: 0,
    ...extractRouteParams(props),
  }, false);
};

const refreshMessage = (props: RefreshProps, actions: RefreshActions) => {
  actions.saveState();
  actions.refreshMessage({
    MaxMessageID: 0,
    // NOTE: It is safe to do a type cast here because this can only be called in Edit Message page
    MessageID: getAdminEventMessageIdFromPath(props.params as IAdminMessageRouterParams),
    ...extractRouteParams(props),
  });
};

const refreshSettings = (props: RefreshProps, actions: RefreshActions) => {
  actions.saveState();
  actions.refresh({
    EventTypeTS: 0,
    ...extractRouteParams(props),
  }, true);
};

export const getAdminEventsCustomRefresh = (props: RefreshProps, actions: RefreshActions) => {
  const route = props.routes[props.routes.length - 1];

  switch (route.path) {
    // Events
    case ADMIN_EVENTS_EVENTS_PATH:
      return () => refreshEvents(props, actions);
    // Products
    case ADMIN_EVENTS_PRODUCTS_PATH:
      return () => refreshProducts(props, actions);
    case ADMIN_EVENTS_EDIT_PRODUCT_PATH:
      return () => refreshProduct(props, actions);
    // Reports
    case ADMIN_EVENTS_REPORTS_PATH:
      return () => refreshReports(props, actions);
    case ADMIN_EVENTS_YEAR_OVERVIEW_PATH:
      return () => refreshYearOverview(props, actions);
    // Message Center
    case ADMIN_EVENTS_MESSAGE_CENTER_PATH:
      return () => refreshMessageCenter(props, actions);
    case ADMIN_EVENTS_EDIT_MESSAGE_PATH:
      return () => refreshMessage(props, actions);
    // Settings
    case ADMIN_EVENTS_SETTINGS_PATH:
      return () => refreshSettings(props, actions);
    default:
      return undefined;
  }
};

const refreshEventSettings = (props: RefreshProps, actions: CacheTwoEventRefreshActions) => {
  const eventId = getEventIdFromPath(props.params as IAdminEventRouterParams);
  actions.saveState();
  actions.refreshEvent({
    EventIDi: eventId,
    EventTS: 0,
    ...extractRouteParams(props),
  }, true);
};

const refreshDashboard = (props: RefreshProps, actions: CacheTwoEventRefreshActions) => {
  const eventId = getEventIdFromPath(props.params as IAdminEventRouterParams);
  actions.saveState();
  actions.refreshEvent({
    EventIDi: eventId,
    EventTS: 0,
    ...extractRouteParams(props),
  }, false);
};

const refreshClasses = (props: RefreshProps, actions: CacheTwoEventRefreshActions) => {
  const eventId = getEventIdFromPath(props.params as IAdminEventRouterParams);
  actions.saveState();
  actions.refreshEvent({
    EventIDi: eventId,
    GetClasses: true,
    ...extractRouteParams(props),
  }, false);
};

const refreshClass = (props: RefreshProps, actions: CacheTwoEventRefreshActions) => {
  const eventId = getEventIdFromPath(props.params as IAdminEventRouterParams);
  actions.saveState();
  actions.refreshClass({
    EventIDi: eventId,
    GetClasses: true,
    ClassIDi: getAdminEventClassIdFromPath(props.params as IAdminClassRouterParams),
    ...extractRouteParams(props),
  });

};

const refreshTimeBlocks = (props: RefreshProps, actions: CacheTwoEventRefreshActions) => {
  const eventId = getEventIdFromPath(props.params as IAdminEventRouterParams);
  actions.saveState();
  actions.refreshEvent({
    EventIDi: eventId,
    EventTS: 0,
    ...extractRouteParams(props),
  }, true);
};

const refreshClassTypes = (props: RefreshProps, actions: CacheTwoEventRefreshActions) => {
  const eventId = getEventIdFromPath(props.params as IAdminEventRouterParams);
  actions.saveState();
  actions.refreshEvent({
    EventIDi: eventId,
    MaxClassTypeIDi: 0,
    ...extractRouteParams(props),
  }, false);
};

const refreshClassType = (props: RefreshProps, actions: CacheTwoEventRefreshActions) => {
  const eventId = getEventIdFromPath(props.params as IAdminEventRouterParams);
  actions.saveState();
  actions.refreshClassType({
    EventIDi: eventId,
    MaxClassTypeIDi: 0,
    ClassTypeIDi: getAdminEventClassTypeIdFromPath(props.params as IAdminClassTypeRouterParams),
    ...extractRouteParams(props),
  });
};

export const getAdminEventCustomRefresh = (props: RefreshProps, actions: CacheTwoEventRefreshActions) => {
  const route = props.routes[props.routes.length - 1];

  switch (route.path) {
    // Event Dashboard
    case ADMIN_EVENT_DASHBOARD_PATH:
      return () => refreshDashboard(props, actions);

    // Classes
    case ADMIN_EVENT_CLASSES_PATH:
      return () => refreshClasses(props, actions);
    case ADMIN_EVENT_EDIT_CLASS_PATH:
      return () => refreshClass(props, actions);

    // Time Blocks
    case ADMIN_EVENT_TIME_BLOCKS_PATH:
      return () => refreshTimeBlocks(props, actions);

    // Class Types
    case ADMIN_EVENT_CLASS_TYPES_PATH:
      return () => refreshClassTypes(props, actions);
    case ADMIN_EVENT_EDIT_CLASS_TYPE_PATH:
      return () => refreshClassType(props, actions);

    // Event Settings
    case ADMIN_EVENT_SETTINGS_PATH:
      return () => refreshEventSettings(props, actions);
    default:
      return undefined;
  }
};

export const getOverallAttendanceStatus = (participants: AdminEventClassParticipant[], attendKey: string) : number => {
  let allAttended = true;
  let atLeastOneAttended = false;

  participants.filter((p) => {
    const statusKey = getClassParticipantStatusKey(p);
    return p.ActiveForm[statusKey] !== AdminClassParticipantStatus.NO_SHOW;
  }).forEach((p) => {
    const attendance = !p.AttendArr ? null : p.AttendArr.find((a) => a.label === attendKey);
    if (!attendance) return p;
    allAttended = allAttended && !!attendance.checked;
    atLeastOneAttended = atLeastOneAttended || !!attendance.checked;
  });

  return allAttended && atLeastOneAttended ? 1 : (atLeastOneAttended ? -1 : 0);
};

export const checkEventTypePermission = (
  onSuccess: () => void,
  cacheZero: CacheZeroState,
  cacheOne?: AdminEventsCacheOneState,
  eventType?: EventType,
  isEdit?: boolean,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (isEdit) {
    if (eventType) {
      checkPermission(
        () => onSuccess(),
        eventType.hasEditEventType,
        eventType.hasEditEventTypeReason,
        inModal,
        noAlertOnFail,
      );
    }

    if (cacheOne && cacheOne.EventsEventType) {
      checkPermission(
        () => onSuccess(),
        cacheOne.EventsEventType.Permissions.hasEditEventType,
        cacheOne.EventsEventType.Permissions.hasEditEventTypeReason,
        inModal,
        noAlertOnFail,
      );
    }
  }
  if (!isEdit && cacheZero.options && cacheZero.options.GeneralPermissions) {
    checkPermission(
      () => onSuccess(),
      cacheZero.options.GeneralPermissions.hasEventTypeAdd,
      cacheZero.options.GeneralPermissions.hasEventTypeAddReason,
      inModal,
      noAlertOnFail,
    );
  }
};

export const checkAdminEventProductPermission = (
  onSuccess: () => void,
  cacheOne: AdminEventsCacheOneState,
  isEdit?: boolean,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (cacheOne.EventsEventType) {
    checkPermission(
      () => onSuccess(),
      isEdit ? cacheOne.EventsEventType.Permissions.hasEditProduct : cacheOne.EventsEventType.Permissions.hasAddProduct,
      isEdit ? cacheOne.EventsEventType.Permissions.hasEditProductReason : cacheOne.EventsEventType.Permissions.hasAddProductReason,
      inModal,
      noAlertOnFail,
    );
  }
};

export const checkAdminEventMessagePermission = (
  onSuccess: () => void,
  cacheOne: AdminEventsCacheOneState,
  isEdit?: boolean,
  isSend?: boolean,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (cacheOne.EventsEventType) {
    const permissions = cacheOne.EventsEventType.Permissions;
    checkPermission(
      () => onSuccess(),
      isEdit ? permissions.hasEditMessage : (isSend ? permissions.hasSendMessage : permissions.hasAddMessage),
      isEdit ? permissions.hasEditMessageReason : (isSend ? permissions.hasSendMessageReason : permissions.hasAddMessageReason),
      inModal,
      noAlertOnFail,
    );
  }
};

export const checkAdminEventReportsPermission = (
  onSuccess: () => void,
  cacheOne: AdminEventsCacheOneState,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (cacheOne.EventsEventType) {
    checkPermission(
      () => onSuccess(),
      cacheOne.EventsEventType.Permissions.hasEventReports,
      cacheOne.EventsEventType.Permissions.hasEventReportsReason,
      inModal,
      noAlertOnFail,
    );
  }
};

export const checkAdminEventGenerateInvoicesPermission = (
  onSuccess: () => void,
  cacheOne: AdminEventsCacheOneState,
  isEmail?: boolean,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (cacheOne.EventsEventType) {
    const permissions = cacheOne.EventsEventType.Permissions;

    checkPermission(
      () => onSuccess(),
      isEmail ? permissions.hasEmailInvoices : permissions.hasGenerateInvoices,
      isEmail ? permissions.hasEmailInvoicesReason :permissions.hasGenerateInvoicesReason,
      inModal,
      noAlertOnFail,
    );
  }
};

export const checkAdminEventRecalculatePricingPermission = (
  onSuccess: () => void,
  cacheOne: AdminEventsCacheOneState,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (cacheOne.EventsEventType) {
    checkPermission(
      () => onSuccess(),
      cacheOne.EventsEventType.Permissions.hasRecalculatePricing,
      cacheOne.EventsEventType.Permissions.hasRecalculatePricingReason,
      inModal,
      noAlertOnFail,
    );
  }
};

export const checkAdminEventPermission = (
  onSuccess: () => void,
  cacheOne: AdminEventsCacheOneState,
  isEdit?: boolean,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (cacheOne && cacheOne.EventsEventType) {
    const permissions = cacheOne.EventsEventType.Permissions;
    checkPermission(
      () => onSuccess(),
      isEdit ? permissions.hasEditEvent : permissions.hasAddEvent,
      isEdit ? permissions.hasEditEventReason : permissions.hasAddEventReason,
      inModal,
      noAlertOnFail,
    );
  }
};

export const checkEnterClassRequirementsPermission = (
  onSuccess: () => void,
  cacheTwoEvent: AdminEventsCacheTwoEventState,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (cacheTwoEvent && cacheTwoEvent.EventsEvent) {
    const permissions = cacheTwoEvent.EventsEvent.Permissions;
    checkPermission(
      () => onSuccess(),
      permissions.hasEnterRequirementsCompleted,
      permissions.hasEnterRequirementsCompletedReason,
      inModal,
      noAlertOnFail,
    );
  }
};

export const checkAdminEventClassTypePermission = (
  onSuccess: () => void,
  cacheZero: CacheZeroState,
  isEdit?: boolean,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (cacheZero.options && cacheZero.options.GeneralPermissions) {
    const permissions = cacheZero.options.GeneralPermissions;
    checkPermission(
      () => onSuccess(),
      isEdit ? permissions.hasClassTypeEdit : permissions.hasClassTypeAdd,
      isEdit ? permissions.hasClassTypeEditReason : permissions.hasClassTypeAddReason,
      inModal,
      noAlertOnFail,
    );
  }
};

export const checkAdminEventClassPermission = (
  onSuccess: () => void,
  cacheTwoEvent: AdminEventsCacheTwoEventState,
  isEdit?: boolean,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (cacheTwoEvent.EventsEvent) {
    const permissions = cacheTwoEvent.EventsEvent.Permissions;
    checkPermission(
      () => onSuccess(),
      isEdit ? permissions.hasEditClass : permissions.hasAddClass,
      isEdit ? permissions.hasEditClassReason : permissions.hasAddClassReason,
      inModal,
      noAlertOnFail,
    );
  }
};

export const getEventTypeId = (props: Partial<RouteComponentProps<IAdminEventsRouterParams, {}>>): number => {
  if (props.params && props.params.eventTypeId) {
    return Number(props.params.eventTypeId);
  }
  return 0;
};

export const getEventTypeName = (props: RouteComponentProps<IAdminEventsRouterParams, {}>): string => {
  if (props.params.eventTypeName) {
    return props.params.eventTypeName;
  }
  return '';
};

export const getAdminEventMessageIdFromPath = (params: IAdminMessageRouterParams): number => {
  if (params.messageId) {
    return Number(params.messageId);
  }
  return 0;
};

export const getAdminEventProductIdFromPath = (params: IAdminProductRouterParams): number => {
  if (params.productId) {
    return Number(params.productId);
  }
  return 0;
};

export const getEventIdFromPath = (params: IAdminEventRouterParams): number => {
  if (params.eventId) {
    return Number(params.eventId);
  }
  return 0;
};
export const getEventId = (params: IAdminEventRouterParams): number => {
  const state = reduxStoreService().getState();
  if (state.adminEvents.cacheTwoEvent.EventsEvent) return state.adminEvents.cacheTwoEvent.EventsEvent.IDi;
  return getEventIdFromPath(params);
};

export const getEventName = (params: IAdminEventRouterParams): string => {
  const state = reduxStoreService().getState();
  if (state.adminEvents.cacheTwoEvent.EventsEvent) return state.adminEvents.cacheTwoEvent.EventsEvent.Name;
  if (params.eventName) {
    return params.eventName;
  }
  return '';
};

export const getAdminEventClassIdFromPath = (params: IAdminClassRouterParams): number => {
  if (params.classId) {
    return Number(params.classId);
  }
  return 0;
};

export const getAdminEventClassTypeIdFromPath = (params: IAdminClassTypeRouterParams): number => {
  if (params.classTypeId) {
    return Number(params.classTypeId);
  }
  return 0;
};