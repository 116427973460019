import * as React from 'react';
import {Switch as MdlSwitch} from 'react-mdl';

import {mergeClassNames} from '@tentaroo/shared';

import Text from '../Text';

import {noop} from '../../../utils';
import '../../../styles/elements/switch/index.scss';
import { Validator } from '../../../utils/validator/models';

export const namespace = 'elements--switch';

export interface SwitchProps {
  className?: string;
  label?: string;
  labelAfter?: boolean;
  expand?: boolean;
  info?: string;
  margin?: boolean;
  noMarginTop?: boolean;
  disabled?: boolean;
  editable?: boolean;
  newDesign?: boolean;
  onChange: (value: boolean, validationRules?: Validator) => any;
  validationRules?: Validator;
  value: boolean;
  multilineLabel?: boolean;
  helperText?: string[];
  inlineDropdown?: React.ReactNode;
  keepLabelColorWhenDisabled?: boolean;
  boldText?: boolean;
  id?: string;
}

const onSwitchChange = (onChange: (value: boolean, validationRules?: Validator) => any, validationRules?: Validator): any  => {
  return (e: any) => {
    onChange(e.target.checked, validationRules);
  };
};

const Switch: React.SFC<SwitchProps> = (props: SwitchProps):React.ReactElement<SwitchProps> => {
  const { labelAfter, expand, info, margin, label, value, onChange, validationRules, disabled, editable, noMarginTop, newDesign, inlineDropdown, multilineLabel, boldText, keepLabelColorWhenDisabled, helperText } = props;
  let edit = editable === undefined ? true : editable;
  let className = mergeClassNames(namespace, props);
  className += ' is-upgraded';
  if (!labelAfter) className += ' label-before';
  if (expand) className += ' expand';
  if (disabled) className += ' disabled';
  if (!margin) className += ' no-margin';
  if (noMarginTop) className += ' no-top-margin';
  if (!edit) className += ' not-edit';
  if (newDesign) className += ' new-design';
  if (multilineLabel) className += ' multiline-label';
  if (!!value && inlineDropdown) className += ' has-inline-dropdown';
  if (keepLabelColorWhenDisabled) className += ' keep-label-color';
  if (boldText) className += ' bold-text';

  const switchComponent = (
    <MdlSwitch
      id={props.id}
      checked={value}
      ripple={false}
      onChange={edit ? onSwitchChange(onChange, validationRules) : noop}
      disabled={disabled}
      className={className}
    >
      {label}
    </MdlSwitch>
  );

  if (helperText) {
    return (
      <div className={`${namespace}--has-helper-text ${inlineDropdown ? 'has-inline-dropdown' : ''}`}>
        {switchComponent}
        {!!value && inlineDropdown ? <div className={`${namespace}--inline-toggle`}>{inlineDropdown}</div> : null}
        {helperText.map((t, index) => <div key={`${namespace}--helper-text${index}`} className={`${namespace}--helper-text`}>{t}</div>)}
      </div>
    );
  }

  if (info) {
    return (
      <div className={`${namespace}--text-info-container`}>
        {switchComponent}
        <Text additionalInfo className={`${namespace}--text-info`}>{info}</Text>
      </div>
    );
  }

  if (!!value && inlineDropdown) {
    return (
      <div className={`${namespace}--wrapper`}>
        {switchComponent}
        {inlineDropdown ? <div className={`${namespace}--inline-toggle`}>{inlineDropdown}</div> : null}
      </div>
    );
  }

  return switchComponent;
};

Switch.defaultProps = {
  expand: true,
  margin: true,
};

export default Switch;
