import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import * as M from '../../../../../constants/messages/generic';
import { AdminEventReportType } from "../../../CacheTwoEvent";
import { SelectValidator, Validator } from "../../../../../utils/validator/models";

export interface IValidator {
  SelectedReportID: Validator;
  IsFront: Validator;
  GroupIDi: SelectValidator;
  GroupIDi_ScoutbookRequirements: SelectValidator;
}

const makeGroupIDiValidationObject = (configs: Required<Pick<SelectValidator, "key" | "isRequired">>): SelectValidator => {
  return {
    key: configs.key,
    isRequired: configs.isRequired,
    defaultValue: () => 0,
    options: {
      values: (rootState) => {
        const result = [...(rootState.adminEvents.shared.EventsGroupEventRegistrations || [])];

        result.unshift({GroupName: '', GroupIDi: 0} as any);

        return result;
      },
      valueKey: (v) => v.GroupIDi,
      labelKey: 'GroupName',
      emptyValue: 0,
    },
    validatejs: {
      [configs.key]: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: SELECT_NOT_GREATER_THAN,
          greaterThan: 0,
        }
      },
    },
  };
};
export const FormDefinition: IValidator = {
  SelectedReportID: {
    key: 'SelectedReportID',
    defaultValue: () => undefined,
  },
  IsFront: {
    key: 'IsFront',
    defaultValue: () => true,
  },
  GroupIDi: makeGroupIDiValidationObject({
    key: 'GroupIDi',
    isRequired: (rootState) => {
      return (
        rootState.adminEvents.events.event.dashboard.ActiveForm.SelectedReportID === AdminEventReportType.EXPORT_BLUE_CARDS
      );
    },
  }),
  GroupIDi_ScoutbookRequirements: makeGroupIDiValidationObject({
    key: 'GroupIDi_ScoutbookRequirements',
    isRequired: (rootState) => {
      return (
        rootState.adminEvents.events.event.dashboard.ActiveForm.SelectedReportID === AdminEventReportType.EXPORT_SCOUTBOOK_REQUIREMENTS
      );
    },
  }),
};