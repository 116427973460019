import * as React from 'react';
import '../../../styles/elements/polarity/index.scss';
import Negative from '../../../images/elements/polarity/negative.svg';
import Positive from '../../../images/elements/polarity/positive.svg';

export const namespace = (): string => 'elements--polarity';

export interface PolarityProps {
  positive?: boolean;
  negative?: boolean;
}

const Polarity: React.SFC<PolarityProps> = (props: PolarityProps): React.ReactElement<PolarityProps> => (
  <i className={namespace() + ' ' + (props.negative ? 'negative' : 'positive')}>
    {props.negative && <Negative className={`${namespace()}--icon`}/>}
    {props.positive && <Positive className={`${namespace()}--icon`}/>}
  </i>
);

export default Polarity;
