import * as React from 'react';
import { MainRightSideBar, SideBarContent } from '../../../../Layouts';
import {Separator, SideBarTitle, Title} from '../../../../Elements';
import AddCart from './AddCart';
import ExportLogs from './ExportLogs';
import {bindActionCreators} from "redux";
import {
  actionCreators as cacheZeroActionCreators,
} from "../../../../../store/CacheZero/actions";
import {
  actionCreators as cacheOneActionCreators,
} from "../../../../../store/CacheOne/actions";
import {actionCreators} from "../../../../../store/App/actions";
import {
  actionCreators as cacheTwoPrevOrdersActionCreators,
} from "../../../../../store/CacheTwoPrevOrders/actions";
import {
  actionCreators as cacheThreePrevOrdersActionCreators,
} from "../../../../../store/CacheThreePrevOrders/actions";
import {RouteComponentProps} from "react-router";
import {ReportAuditLogActions} from "../../../../../store/Settings/PrevOrders/AuditLog/actions";
import { ApplicationState } from '../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {id: string}>;

class RightSidebar extends React.PureComponent<{}, {}> {
  props: ConnectedProps;

  public render() {
    const {apiLoadingMap, apiSaving} = this.props;
    return (
      <MainRightSideBar desktop loading={apiLoadingMap[ReportAuditLogActions.requestType] || apiSaving > 0}>
        <SideBarTitle admin>Order/Payment Tools</SideBarTitle>
        <SideBarContent>
          <Title>Manage Available Credit</Title>
          <AddCart/>
          <Separator/>
          <Title>Export Audit Log</Title>
          <ExportLogs/>
        </SideBarContent>
      </MainRightSideBar>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheTwoPrevOrders: state.cacheTwoPrevOrders,
    cacheThreePrevOrders: state.cacheThreePrevOrders,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...cacheZeroActionCreators,
  ...cacheOneActionCreators,
  ...actionCreators,
  ...cacheTwoPrevOrdersActionCreators,
  ...cacheThreePrevOrdersActionCreators
}, dispatch)});

const ConnectedRightSidebar = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(RightSidebar);

export default ConnectedRightSidebar;
