import * as React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import '../../../styles/elements/context-menu/index.scss';
import { isDescendant, whichAnimationEvent } from '../../../utils';
import Media from '../Media';
import { namespace } from "./constants";
import {Nullable} from '../../../utils/dataHelper';

export interface Props {
  open?: boolean;
  onOpen?: () => any;
  onClose?: () => any;
  title?: string;
  info?: React.ReactNode;
  children?: React.ReactNode;
  direction?: 'top' | 'bottom';

  // indicate whether to remain the consistent style (pop-up) in mobile
  consistentInMobile?: boolean;
}

interface State {
  closing?: boolean;
}

class ContextMenu extends React.Component<Props, State> {
  private _open: boolean;

  public static childContextTypes = {
    close: PropTypes.func
  };

  public state: State = {
    closing: false
  };

  public getChildContext() {
    return {
      close: this.close
    };
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (nextProps.open && !this.props.open && this.props.onOpen) {
      this.props.onOpen();
    }
    this._open = !!nextProps.open;
  }

  public componentDidMount() {
    if (this._open) {
      document.addEventListener('click', this.handleDocumentClick);
      document.addEventListener('touchstart', this.handleDocumentClick);
    } else {
      document.removeEventListener('click', this.handleDocumentClick);
      document.removeEventListener('touchstart', this.handleDocumentClick);
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
    document.removeEventListener('touchstart', this.handleDocumentClick);
  }

  public handleDocumentClick = (event: PointerEvent) => {
    if (event.target !== findDOMNode(this) && !isDescendant(findDOMNode(this) as Nullable<Element>, event.target as Nullable<Element>)) {
      this.close(event);
    }
  };

  private isMobile = () => {
    const {consistentInMobile} = this.props;
    if (consistentInMobile) return false;
    const title = (findDOMNode(this) as Nullable<Element>)?.getElementsByTagName('h1')[0] as Nullable<HTMLDivElement>;
    return title && title.offsetParent ? true : false;
  };

  public close = (e) => {
    if (e) e.stopPropagation();
    if (this.isMobile()) {
      const el = (findDOMNode(this) as Nullable<Element>)?.getElementsByClassName(`${namespace()}--container`)[0] as Nullable<HTMLDivElement>;
      const animationCallback = () => {
        const whichAni = whichAnimationEvent();
        if (whichAni) el?.removeEventListener(whichAni, animationCallback);
        this.setState({ closing: false });
        if (this.props.onClose) this.props.onClose();
      };
      const whichAni = whichAnimationEvent();
      if (whichAni) el?.addEventListener(whichAni, animationCallback);
      this.setState({ closing: true });
    } else {
      if (this.props.onClose) this.props.onClose();
    }
  };

  public render() {
    const { open, info, children, title, consistentInMobile, direction } = this.props;
    const { closing } = this.state;

    return (
      <div className={`${namespace() + (closing ? ' closing' : (open ? ' open' : '')) + (consistentInMobile ? ' consistent-mobile' : '')} ${direction ? direction : 'bottom'}`}>
        <div className={`${namespace()}--backdrop`} onClick={this.close}/>
        <div className={`${namespace()}--container`}>
          {title ? (
            <Media mobile>
              <h1 className={`${namespace()}--title`}>{title}</h1>
            </Media>
          ) : null}
          {info}
          <ul className={`${namespace()}--list`}>
            {children}
          </ul>
        </div>
      </div>
    );
  }
}

export default ContextMenu;
export { default as ContextMenuItem } from './Item';
export { default as ContextMenuInfo } from './Info';
