import * as React from 'react';

import Media from "../../Media";
import View from "../../View";
import {Header} from "../../Header";
import HeaderSteps from "../../Header/Steps";
import HeaderTabs from "../../Header/Tabs";
import StepProgressBar from "../../StepProgressBar";

import '../../../../styles/elements/registration/header-progress/index.scss';

export interface HeaderProgressProps {
  loading?: boolean;
  children?: React.ReactNode;
  tabs?: React.ReactNode;
}

const namespace = () => 'elements--registration--header-progress';

const RegisterHeaderProgress: React.SFC<HeaderProgressProps> = ({ children, tabs, loading }: HeaderProgressProps): React.ReactElement<HeaderProgressProps> => {
  let steps = 0;
  let name: string = '';
  let currentStep: number = 1;
  React.Children.forEach(children, (child: React.ReactElement<any>) => {
    if (child) steps++;
    if (child && child.props.selected) {
      currentStep = steps;
      name = child.props.children;
    }
  });

  return (
    <View className={`${namespace()} ${loading?'loading':''}`} style={{ width: '100%' }}>
      <Media mobile style={{ flex: 1 }}>
        <Header layout="vertical">
          <HeaderSteps currentStep={currentStep} steps={steps} name={name}/>
          {tabs ? <HeaderTabs>{tabs}</HeaderTabs> : null}
        </Header>
      </Media>
      <Media tablet desktop>
        <StepProgressBar>
          {children}
        </StepProgressBar>
      </Media>
    </View>
  );
};

export default RegisterHeaderProgress;
export { default as RegisterHeaderProgressStep } from './Step';
