import * as React from 'react';
import { Main, MainContent } from '../../../Layouts';
import {
  ContentBlock,
  Media,
  Title,
  Tickets,
  Text,
  BulletList,
  BulletListItem,
  Button,
  Footer,
  Product,
  LoadingAll,
  PageLoader,
  Alert,
  Row
} from '../../../Elements';
import SlideShow from './Products/SlideShow';
import CampInfo from '../CampInfo';
import Prices from './Prices';
import Price from './Price';
import PricingInfo from './PricingInfo';
import Classes from './Classes';
import EventSidebar from '../Sidebar';
import EventContactCard from '../ContactCard';
import GroupProducts from './GroupProducts';
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../../../store/Events/Event/Main/actions";
import { actionCreators as eventTypeActionCreators } from "../../../../store/Events/EventType/actions";
import { actionCreators as cacheThreeActionCreators } from "../../../../store/CacheThreeEvents/actions";
import { actionCreators as cacheTwoActionCreators, ClearCacheBelowTwoEvents } from "../../../../store/CacheTwoEvents/actions";
import { actionCreators as cacheOneActionCreators } from "../../../../store/CacheOne/actions";
import '../../../../styles/pages/events/event/index.scss';
import { makeSelectedEventTypeSelector } from "../../../../store/CacheTwoEvents/index";
import { withRouter, RouteComponentProps } from "react-router";
import { E_REGISTER, EVENT, getEventRegistrationUrl, getEventRootUrl } from "../../../../constants/urls";
import { navPush } from "../../../../utils/navHelper";
import {
  makeSelectedEventSelector,
  makeParticipantTypeSelector
} from "../../../../store/CacheThreeEvents/index";
import { displayTimeFrame } from "../../../../utils/dateHelper";
import { BaseEndUserClass } from "../../../../models/class";
import { EventTypeEvent } from "../../../../models/api/cacheTwoEvents";
import { EventInfo, EventInfoParticipantType, RegistrationStatus } from "../../../../models/api/cacheThreeEvents";
import { checkPermission } from "../../../../utils/permissionHelper";
import { ApplicationState } from '../../../../store';
import EndUserCacheManager from '../../../../utils/cacheManagers/endUserCacheManager';
import { IEventRouterParams, isPathUnderEndUserEventCacheThree } from '../../../../utils/helpers/endUserPageHelper';
import { shouldReconfigRouter } from '../../../../utils/cacheLoaders/reloaderHelper';
import { captureTentarooError } from '../../../../utils/dataHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../store/service';

import {namespace, RED_THRESHOLD} from "./constants";
import { ComponentUpdateTemplate } from '../../../Templates/ComponentUpdateTemplate';
import {isCacheThreeEventsPopulated, isEndUserCacheOnePopulated} from '../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IEventRouterParams, {}>
>;

@(withRouter as any)
class Event extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
  private nextLocation;

  public componentDidMount() {
    this.configRouteLeaveHook();
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeEvent({
          props: this.props,
          isStateNavigated,
        });
      }
    );
  }

  public componentWillReceiveProps(nextProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeEvent({
          props: nextProps,
          isStateNavigated,
        });
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouteLeaveHook();
  }

  componentWillUnmount() {
    const {cacheThreeEvents} = this.props;

    // if we are not navigating to another page under cache three events, clear cache
    if (
      this.nextLocation &&
      !isPathUnderEndUserEventCacheThree(this.nextLocation.pathname) &&
      isCacheThreeEventsPopulated(cacheThreeEvents)
    ) {
      reduxStoreService().dispatch(new ClearCacheBelowTwoEvents());
    }
    this.resetRouteLeaveHook();
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  onSelectClass = () => navPush(this.props.router, `${getEventRootUrl(this.props)}/${EVENT.CLASSES}`);

  onShowMoreProducts = () => {
    const { actions, event: { showMoreProducts } } = this.props;
    actions.eventShowMoreProducts(!showMoreProducts);
  };

  private renderParticipantCards(EventInfo: EventInfo): any {
    const { participantTypes } = this.props;
    if (participantTypes.length === 0) return null;
    return (
      <Prices>
        {participantTypes.map((p: EventInfoParticipantType, i) => (
          <Price
            key={i}
            type={p}
            numEventSpotsAvailable={EventInfo.NumEventSpotsAvailablePending}
            MaxParticipants={EventInfo.MaxParticipants}
          />
        ))}
      </Prices>
    );
  }

  renderRegistrationStatus(r: RegistrationStatus, i) {
    if (r.Description && r.Status) {
      return (
        <BulletListItem size={14} key={i}>
          <Text size={14} weight="medium" inline>{r.Status}</Text><br />
          <span>{r.Description}</span>
        </BulletListItem>
      );
    } else if (r.Status) {
      return <BulletListItem size={14} key={i}>{r.Status}</BulletListItem>;
    }
    return null;
  }

  private renderRegistrationStatuses(EventInfo: EventInfo): any {
    if (!EventInfo.RegistrationStatuses || EventInfo.RegistrationStatuses.length === 0) return null;
    return (
      <BulletList>
        {EventInfo.RegistrationStatuses.map(this.renderRegistrationStatus)}
      </BulletList>
    );
  }

  private renderContent() {
    const { selectedEvent, selectedEventType, cacheThreeEvents, cacheThreeEvents: { EventInfo, EventInfoPermissions } } = this.props;
    if (!isCacheThreeEventsPopulated(cacheThreeEvents) || !selectedEventType || !(selectedEvent || EventInfo) || !EventInfo || !EventInfoPermissions) return <LoadingAll />;
    const {
      cacheTwoEvents: { eventType },
      cacheThreeEvents: { EventProductsAvailable, EventClassesGroupAvailable, EventClassesIndividualAvailable, }
    } = this.props;
    const { MaxParticipants, NumEventSpotsAvailablePending } = EventInfo;
    const eventInformation = getWarningAndTitle(selectedEvent, EventInfo);

    let rootUrl = getEventRootUrl(this.props);
    const registerProductsUrl = `${rootUrl}/${EVENT.REGISTER}/${E_REGISTER.PRODUCTS}`;

    let displayClasses: Array<BaseEndUserClass> = [];
    if (EventClassesIndividualAvailable) displayClasses = EventClassesIndividualAvailable;
    if ((eventType === 'numbers' || eventType === 'both') && EventClassesGroupAvailable) {
      displayClasses = [...displayClasses, ...EventClassesGroupAvailable];
    }
    const numSpotsAvailable = Math.max(0, NumEventSpotsAvailablePending);
    let spotsAvailable: string | undefined;
    if (MaxParticipants > 0) {
      spotsAvailable = `${numSpotsAvailable} spot${numSpotsAvailable !== 1 ? 's' : ''} available`;
    }
    const ret: Array<any> = [];
    let showNumberAlert = false;
    let showNamesAlert = false;
    if (!eventInformation.HasGroupRegistration) {
      showNumberAlert = !EventInfoPermissions.hasRegistrationNumbersAdd && (eventType === 'both' || eventType === 'numbers');
      showNamesAlert = !EventInfoPermissions.hasRegistrationNames && eventType === 'participant';
    }

    if (EventClassesGroupAvailable !== undefined) {
      // EventContactCard only displays on tablet and below
      ret.push(<EventContactCard key="contact-card" marginBottom={(showNumberAlert || showNamesAlert) ? 16 : undefined} mobileMarginBottom={0} />);
    }
    if (!eventInformation.HasGroupRegistration) {
      if (showNumberAlert) {
        ret.push(<Row mobileMarginBottom={16} marginBottom={24}><Alert showMargins={false}>{EventInfoPermissions.hasRegistrationNumbersAddReason}</Alert></Row>);
      } else if (showNamesAlert) {
        ret.push(<Row mobileMarginBottom={16} marginBottom={24}><Alert showMargins={false}>{EventInfoPermissions.hasRegistrationNamesReason}</Alert></Row>);
      }
    }

    // hmm, selectedEvent (and maybe selectedEventType?) need to be selected earlier for partial loading...
    if (EventClassesGroupAvailable !== undefined) {
      ret.push(<ContentBlock key="pricing">
        <Title
          size={28}
          mobileSize={22}
          mobileMarginTop={24 - 16}
          controls={(
            spotsAvailable && EventInfo.MaxParticipants > 0 ? <Media tablet desktop>
              <Tickets text={spotsAvailable} strong red={numSpotsAvailable < RED_THRESHOLD} full={numSpotsAvailable === 0} />
            </Media> : undefined
          )}
        >
          {'Pricing & Conditions'}
        </Title>
        <Row mobileMarginBottom={16}><PricingInfo EventInfo={EventInfo} /></Row>
        {spotsAvailable && EventInfo.MaxParticipants > 0 && <Media mobile>
          <Tickets text={spotsAvailable} strong marginBottom={16} red={numSpotsAvailable < RED_THRESHOLD} full={numSpotsAvailable === 0} />
        </Media>}
        {this.renderParticipantCards(EventInfo)}
        {this.renderRegistrationStatuses(EventInfo)}
      </ContentBlock>);

      if (displayClasses.length > 0) {
        ret.push(<ContentBlock key="classes">
          <Title size={28} mobileSize={22} num={displayClasses.length}>Classes</Title>
          <Classes classes={displayClasses} onClickItem={this.onSelectClass} />
        </ContentBlock>);
      }

      if (EventProductsAvailable && EventProductsAvailable.length > 0) {
        let num = EventProductsAvailable.length;
        if (!this.props.event.showMoreProducts && num > 3) {
          num = 3;
        }
        const renderProducts: any[] = [];
        for (let i = 0; i < num; i++) {
          const product = EventProductsAvailable[i];
          renderProducts.push(product);
        }

        ret.push(
          <GroupProducts
            key="products"
            title="Products"
            hideManage
            eventType={eventType}
            numProducts={EventProductsAvailable.length}
            showMoreProducts={this.props.event.showMoreProducts}
            onShowMore={this.onShowMoreProducts}
          >
            {renderProducts.map(
              (product, index) => <Product
                productModel={product}
                isSummary
                key={index}
                slideShow={product.FeaturedImage ? <SlideShow image={product.FeaturedImage} /> : undefined}
              />
            )}
          </GroupProducts>
        );
      }
      return ret;
    }
    return <PageLoader />;
  }

  onRegisterForEvent = () => {
    const { cacheTwoEvents: { eventType }, cacheThreeEvents: { EventInfoPermissions, EventInfo } } = this.props;

    if (!EventInfoPermissions) return;

    let rootUrl = getEventRootUrl(this.props);
    let registerUrl = `${rootUrl}/${EVENT.REGISTER}/`;
    if (eventType === 'participant') {
      registerUrl = registerUrl + E_REGISTER.PARTICIPANT;
    } else {
      registerUrl = registerUrl + E_REGISTER.NUMBERS;
    }
    const onRegister = () => navPush(this.props.router, registerUrl);
    checkPermission(
      onRegister,
      EventInfoPermissions.hasRegistrationNumbersAdd,
      EventInfoPermissions.hasRegistrationNumbersAddReason
    );
  };

  onMyRegister = () => {
    const { router } = this.props;
    navPush(router, getEventRegistrationUrl(this.props));
  };

  public render() {
    const {
      inert,
      systemSettings, actions, cacheThreeEvents, cacheThreeEvents: { EventInfo, EventInfoPermissions }, cacheTwoEvents: { eventType }, router, selectedEventType,
      eventType: { showMapMobile }, selectedEvent, cacheOne,
    } = this.props;
    if (
      !isEndUserCacheOnePopulated(cacheOne) ||
      !selectedEventType ||
      !(selectedEvent || EventInfo) ||
      !systemSettings || !EventInfoPermissions
    ) return <LoadingAll />;
    const warningAndTitle = getWarningAndTitle(selectedEvent, EventInfo);

    const buttonText = warningAndTitle.HasGroupRegistration ? 'MY REGISTRATION' : 'REGISTER FOR EVENT';
    const onRegister = warningAndTitle.HasGroupRegistration ? this.onMyRegister : this.onRegisterForEvent;

    return (
      <Main
        inert={inert}
        footer={(
          <Footer mobile height={48}>
            <Button onClick={onRegister} expand big color="green" style={{ borderRadius: '0px' }} disabled={!EventInfoPermissions && !warningAndTitle.HasGroupRegistration} className={`${namespace()}--register-button--button`}>
              {buttonText}
            </Button>
          </Footer>
        )}
        leftSidebar={<EventSidebar />}
        rightGutter={false}
      >
        <MainContent
          header={
            <CampInfo
              campLocation={selectedEventType.DefaultLocation}
              contactInfo={selectedEventType.EventContactInfo}
              image={selectedEventType.FeaturedImage}
              systemSettings={systemSettings}
              showMapMobile={showMapMobile}
              onShowMapMobile={actions.toggleMapMobile}
              eventType={eventType}
              description={selectedEventType.EventContactInfo.Description}
              EventInfoPermissions={EventInfoPermissions}
              HasGroupRegistration={warningAndTitle.HasGroupRegistration}
              warning={warningAndTitle.warning}
              title={warningAndTitle.title}
              subTitle={`${selectedEventType.Name} @ ${selectedEventType.DefaultLocation.Name}`}
            />
          }
          rightGutter
        >
          {this.renderContent()}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedEventType = makeSelectedEventTypeSelector();
  const selectedEvent = makeSelectedEventSelector();
  const participantTypes = makeParticipantTypeSelector();
  return {
    eventType: state.events.eventType,
    event: state.events.event.main,
    systemSettings: state.session.SystemSettings,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheThreeEvents: state.cacheThreeEvents,
    cacheTwoEvents: state.cacheTwoEvents,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    selectedEventType: selectedEventType(state),
    selectedEvent: selectedEvent(state),
    participantTypes: participantTypes(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheThreeActionCreators,
    ...cacheTwoActionCreators,
    ...cacheOneActionCreators,
    ...eventTypeActionCreators
  }, dispatch)
});

const ConnectedEvent = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(Event);

export default ConnectedEvent;

export const getWarningAndTitle = (selectedEvent?: EventTypeEvent, EventInfo?: EventInfo): {
  warning: string,
  title: string,
  HasGroupRegistration: boolean
} => {
  if (selectedEvent) {
    return {
      warning: displayTimeFrame(selectedEvent.StartDateTime, selectedEvent.EndDateTime),
      title: selectedEvent.Name,
      HasGroupRegistration: selectedEvent.HasGroupRegistration
    };
  } else if (EventInfo) {
    return {
      warning: displayTimeFrame(EventInfo.StartDateTime, EventInfo.EndDateTime),
      title: EventInfo.Name,
      HasGroupRegistration: EventInfo.HasGroupRegistration
    };
  } else {
    captureTentarooError(new Error("Either selectedEvent or EventInfo should be presented in order to populate header"));
    return {
      warning: '',
      title: '',
      HasGroupRegistration: false
    };
  }
};

export { default as EventHeader } from './Header';
export { default as EventClasses } from './Classes/index';
export { default as EventClassesHeader } from './Classes/Header';
export { default as EventRegistration } from './Registration/index';
export { default as EventRegistrationParticipant } from './Registration/Participant';
export { default as EventRegistrationParticipantHeader } from './Registration/Participant/Header';
export { default as EventRegisterParticipantRoster } from './Register/Participant/Roster';
export { default as EventRegisterParticipantRosterPerson } from './Register/Participant/Roster/Person';
export { default as EventRegisterParticipantType } from './Register/Participant/Type';
export { default as EventRegisterParticipantClasses } from './Register/Participant/Classes';
export { default as EventRegisterParticipantConfirmation } from './Register/Participant/Confirmation';
export { default as EventRegisterNumbersSpots } from './Register/Numbers/Spots/index';
export { default as EventRegisterNumbersClasses } from './Register/Numbers/Classes/index';
export { default as EventRegisterNumbersProducts } from './Register/Numbers/Products';
export { default as EventRegisterNumbersCampsiteRanking } from './Register/Numbers/CampsiteRanking';
export { default as EventRegisterNumbersConfirmation } from './Register/Numbers/Confirmation';
export { default as EventRegisterProductsProducts } from './Register/Products/Products/index';
export { default as EventRegisterProductsConfirmation } from './Register/Products/Confirmation/index';
export { default as EventRegistrationClasses } from './Registration/Classes';
export { default as EventRegistrationClassesHeader } from './Registration/Classes/Header';

