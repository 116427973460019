import {EventNumbersCalc, EventNumbersSave} from './actions';
import {E_REGISTER, EVENT, eventNumbersCalc} from '../../constants/urls';
import {makeRequestEpic} from "../Validation/epicCreator";
import {Observable} from "rxjs";
import {ApiSubmitActionsNumbersSpots} from "../Events/Event/Register/Numbers/Spots/actions";
import {replace} from "react-router-redux";
import {
  ApiSubmitActionsNumbersClasses,
  EventRegisterNumbersClassesShowError
} from "../Events/Event/Register/Numbers/Classes/actions";
import {validateAllNumbersPages} from "./validationMaster";
import {EventRegisterNumbersTransitionToPending} from "../Events/Event/Register/Numbers/Main/actions";
import {ApiSubmitActionsNumbersCampsite} from "../Events/Event/Register/Numbers/Campsite/actions";
import {NoOp, ShowSnackbarItem} from "../App/actions";
import {getMessageFromErrorResponse} from "../../utils/stringHelper";
import {ApiSubmitActionsNumbersProducts} from "../Events/Event/Register/Numbers/Products/actions";
import { reduxStoreService } from '../service';

export const cacheFourEventsNumbersEpic = makeRequestEpic(
  EventNumbersCalc,
  eventNumbersCalc,
  undefined, // body is passed in as is in action.value
  true,
  undefined,
  undefined,
  () => {
    return ApiSubmitActionsNumbersSpots.populate();
  }
);

export const cacheFourEventsNumbersSaveFailureEpic = action$ => {
  return action$.filter((a) => (
    a.type === EventNumbersSave.failureType || a.type === ApiSubmitActionsNumbersSpots.failureType
    || a.type === ApiSubmitActionsNumbersClasses.failureType || a.type === ApiSubmitActionsNumbersProducts.failureType || a.type === ApiSubmitActionsNumbersCampsite.failureType
  ))
    .mergeMap((action) => {
      const route = reduxStoreService().getState().routing.locationBeforeTransitions;
      const newPath = route.pathname.replace(`${EVENT.REGISTER}/${E_REGISTER.NUMBERS}`, EVENT.REGISTRATION);
      if (action.response.status === 400) {
        if (action.response.xhr.response.error.Detail === 'Canceled') {
          const results = validateAllNumbersPages();
          if (results.spots && results.classes && results.classes.warnings.length > 0) {
            return Observable.merge(
              Observable.of(ApiSubmitActionsNumbersSpots.clientFailure(results.spots, false)),
              Observable.of(new ShowSnackbarItem(getMessageFromErrorResponse(action.response))),
              Observable.of(new EventRegisterNumbersClassesShowError(results.classes.warnings[0].message, results.classes.error))
            );
          } else if (results.spots && results.classes && results.classes.warnings.length === 0) {
            return Observable.merge(
              Observable.of(new ShowSnackbarItem(getMessageFromErrorResponse(action.response))),
              Observable.of(ApiSubmitActionsNumbersSpots.clientFailure(results.spots, false))
            );
          } else if (!results.spots && results.classes && results.classes.warnings.length > 0) {
            return Observable.merge(
              Observable.of(new ShowSnackbarItem(getMessageFromErrorResponse(action.response))),
              Observable.of(new EventRegisterNumbersClassesShowError(results.classes.warnings[0].message, results.classes.error))
            );
          }
          return Observable.of(new ShowSnackbarItem(getMessageFromErrorResponse(action.response)));
        } else if (action.response.xhr.response.error.Detail === 'Incorrect Payment') {
          return Observable.merge(
            Observable.of(replace(`${newPath}${route.search}`)),
            Observable.of(new ShowSnackbarItem(getMessageFromErrorResponse(action.response)))
          );
        }
      } else if (action.response.status === 409) {
        return Observable.merge(
          Observable.of(replace(`${newPath}${route.search}`)),
        );
      }

      return Observable.of(new NoOp());
    });
};

export const cacheFourEventsNumbersRecalcSuccessEpic = action$ => {
  return action$.filter((a) =>
    a.type === ApiSubmitActionsNumbersClasses.successType || a.type === ApiSubmitActionsNumbersSpots.successType || a.type === ApiSubmitActionsNumbersCampsite.successType || 
    a.type === ApiSubmitActionsNumbersProducts.successType
  )
    .mergeMap((action) => {
      const results = validateAllNumbersPages();
      // @todo: I disagree with doing this (EventRegisterNumbersTransitionToPending should not occur),
      // I think we should block these errors now rather than later
      if (results.spots && results.spots.Errors && results.classes && results.classes.warnings.length > 0) {
        if (results.spots.Errors.AmountTotalChange) {
          return Observable.merge(
            Observable.of(ApiSubmitActionsNumbersSpots.clientFailure(results.spots, false)),
            Observable.of(new EventRegisterNumbersClassesShowError(results.classes.warnings[0].message, results.classes.error))
          );
        }
        return Observable.merge(
          Observable.of(ApiSubmitActionsNumbersSpots.clientFailure(results.spots, false)),
          Observable.of(new EventRegisterNumbersClassesShowError(results.classes.warnings[0].message, results.classes.error)),
          Observable.of(new EventRegisterNumbersTransitionToPending()),
        );
      } else if (results.spots && results.spots.Errors && results.classes && results.classes.warnings.length === 0) {
        if (results.spots.Errors.AmountTotalChange) {
          return Observable.merge(
            Observable.of(ApiSubmitActionsNumbersSpots.clientFailure(results.spots, false))
          );
        }
        return Observable.merge(
          Observable.of(ApiSubmitActionsNumbersSpots.clientFailure(results.spots, false)),
          Observable.of(new EventRegisterNumbersTransitionToPending()),
        );
      } else if (!results.spots && results.classes && results.classes.warnings.length > 0) {
        return Observable.merge(
          Observable.of(new EventRegisterNumbersClassesShowError(results.classes.warnings[0].message, results.classes.error)),
          Observable.of(new EventRegisterNumbersTransitionToPending()),
        );
      }
      return Observable.of(new EventRegisterNumbersTransitionToPending());
    });
};

export const cacheFourEventsNumbersLoadPopulateEpic = action$ => {
  return action$.filter((a) => a.type === EventNumbersCalc.successType)
    .mergeMap((action) => {
      return Observable.of(ApiSubmitActionsNumbersSpots.populate());
    });
};

export const cacheFourEventsNumbersLoadSuccessEpic = action$ => {
  return action$.filter((a) => a.type === ApiSubmitActionsNumbersSpots.populateType)
    .mergeMap((action) => {
      const rootState = reduxStoreService().getState();
      if (rootState.cacheThreeEvents.EventInfo && rootState.cacheThreeEvents.EventInfo.HasGroupRegistration) {
        const results = validateAllNumbersPages();

        if (results.spots || (results.classes && results.classes.warnings.length > 0) || results.campsite) {
          return Observable.merge(
            Observable.if(
              () => !!results.spots,
              Observable.of(ApiSubmitActionsNumbersSpots.clientFailure(results.spots, false))
            ),
            Observable.if(
              () => !!results.classes && results.classes.warnings.length > 0,
              Observable.of(!!results.classes && results.classes.warnings.length > 0 ? new EventRegisterNumbersClassesShowError(results.classes.warnings[0].message, results.classes.error) : new NoOp())
            ),
            Observable.if(
              () => !!results.campsite,
              Observable.of(ApiSubmitActionsNumbersCampsite.clientFailure(results.campsite, false))
            ),
          );
        }
      }

      return Observable.of(new NoOp());
    });
};

export const cacheFourEventsNumbersSaveEpic = makeRequestEpic(
  EventNumbersSave,
  eventNumbersCalc,
  undefined, // body is passed in as is in action.form
  true,
  () => {
    const rootState = reduxStoreService().getState();
    if (rootState.cacheThreeEvents.EventInfo && rootState.cacheThreeEvents.EventInfo.HasGroupRegistration) {
      return 'Any changes have been saved to your cart.';
    }
    return 'New event registration for this event has been added to your cart.';
  }
);
