import * as React from 'react';

import { Filter, FilterRow, FilterColumn } from '../../../../../components/Layouts';
import { Switch } from '../../../../../components/Elements';
import { BlackoutDatesFilters } from '../../../../../models/api/adminFacilitiesCacheOne';
import { GetFacilityLocationCacheBodyParams } from '../../../../../constants/urls';
import { extractRouteParams } from '../../../../../utils/urlHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { Validator } from '../../../../../utils/validator/models';

export const namespace = (): string => 'filters--blackout-dates';

interface Props {
  className?: string;
  disabled?: boolean;
  BlackoutDatesFilters?: BlackoutDatesFilters;
  ActiveForm: any;
  ValidationRules: any;
  newButton?: React.ReactNode;
  location: any;
  routes: any;
  params: any;
  reduxActions: {
    getFacilityLocationCache: (params: GetFacilityLocationCacheBodyParams, isRefreshingCacheOneForm: boolean, useProgress?: boolean) => void;
    updateValue(value: any, vObj: Validator);
  }
}

interface State {
};
class BlackoutDatesFilter extends React.Component<Props, State> {

  onChangeShowExpired = (v, vObj) => {
    const {reduxActions} = this.props;
    reduxActions.updateValue(v, vObj);
    this.props.reduxActions.getFacilityLocationCache({
      GetBlackoutDates: true,
      BlackoutDatesFilters: {
        IncludeExpired: !!v,
      },
      ...extractRouteParams(this.props),
    }, false, true);
  };

  public render() {
    const {newButton, ActiveForm,  ValidationRules} = this.props;
    return (
      <Filter className={namespace()}>
        <FilterRow>
          <FilterColumn width='half'>
            <Switch
              id={generateDOMId("show-expired")}
              label="Show Expired"
              newDesign
              onChange={this.onChangeShowExpired}
              value={ActiveForm.ShowExpired}
              validationRules={ValidationRules.ShowExpired}
            />
          </FilterColumn>
          <FilterColumn hideInMobile width='half'>
            {newButton}
          </FilterColumn>
        </FilterRow>
      </Filter>
    );
  }
}

export default BlackoutDatesFilter;