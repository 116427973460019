
import {makeRequestEpic} from "../../../Validation/epicCreator";
import {GetAvailabilitiesAction} from "./actions";
import { getAvailabilities, getAvailabilitiesBody } from "../../../../constants/urls";

export const adminFacilityLocationAvailabilitiesEpic = makeRequestEpic(
  GetAvailabilitiesAction,
  getAvailabilities,
  getAvailabilitiesBody,
  true
);