import*  as React from 'react';
import PropTypes from 'prop-types';
import ModalProvider from './ModalProvider';
import { RouteComponentProps } from 'react-router';
import { shallowEqual } from '../../utils';

export interface ModalOrchestratorProps extends RouteComponentProps<{}, { id: string }> {
  modal?: React.ReactElement<any>;
}

export interface ModalOrchestratorState {
  leavingModals?: Array<React.ReactElement<any>>,
  replacedModals?: Array<React.ReactElement<any>>,
  hiddenModals?: Array<React.ReactElement<any>>,
  modals?: Array<React.ReactElement<any>>,
}

export interface ModalOrchestratorInterface extends React.Component<ModalOrchestratorProps, ModalOrchestratorState>{
  add(modal: React.ReactElement<any>);
  remove(index: number);
  back();
  closeFromProvider(index: number);
};

class ModalOrchestrator extends React.Component<ModalOrchestratorProps, ModalOrchestratorState> implements ModalOrchestratorInterface {
  public static childContextTypes = {
    orchestrator: PropTypes.object
  };

  public getChildContext = ()  => ({
    orchestrator: this
  });

  public props: ModalOrchestratorProps;
  public state: ModalOrchestratorState = {
    leavingModals: new Array(),
    replacedModals: new Array(),
    hiddenModals: new Array(),
    modals: new Array()
  };

  private modalIds: Map<any, any> = new Map();
  private lastModalId: number = -1;
  private backFlag: boolean = false;

  private getModalId(modal: React.ReactElement<any>) {
    let match: number | null = null;
    this.modalIds.forEach((value: React.ReactElement<any>, key: number) => {
      if (modal === value) match = key;
    });
    if (match !== null) return match;

    this.lastModalId++;
    this.modalIds.set(this.lastModalId, modal);
    return this.lastModalId;
  }

  public add = (modal: React.ReactElement<any>) => {
    if (this.state.modals) {
      const nextModals = this.state.modals.slice();
      nextModals.push(modal);
      this.setState({ modals: nextModals });
    }
  };

  public closeFromProvider = (index: number) => {
    const modal = this.modalIds.get(index);
    this.close(modal);
  };

  public close = (modal: React.ReactElement<any>, replace: boolean = false) => {
    if (this.state.modals) {
      const modalIndex: number = this.state.modals.findIndex(item => item === modal);
      let nextModals: any = this.state.modals.slice();
      if (modalIndex !== null && modalIndex >= 0) {
        nextModals = [].concat(nextModals.slice(0, modalIndex), nextModals.slice(modalIndex + 1));
      }
      if (!replace && this.state.leavingModals) {
        let nextLeavingModals = this.state.leavingModals.slice();
        nextLeavingModals.push(modal);
        this.setState({ leavingModals: nextLeavingModals, modals: nextModals });
      } else {
        //let nextReplacedModals = this.state.replacedModals.slice();
        //nextReplacedModals.push(modal);
        //this.setState({ replacedModals: nextReplacedModals, modals: nextModals });
      }
    }

  };

  public back = () => {
    this.backFlag = true;
  };

  public hide = (modal: React.ReactElement<any>) => {
    if (this.state.hiddenModals) {
      let nexHiddenModals = this.state.hiddenModals.slice();
      nexHiddenModals.push(modal);
      this.setState({ hiddenModals: nexHiddenModals });
    }
  };

  public remove = (index: number) => {
    const modal = this.modalIds.get(index);
    this.modalIds.delete(index);
    let modalGroups = ['leavingModals', 'replacedModals', 'hiddenModals', 'modals'];
    for (let i = 0, len = modalGroups.length; i < len; ++i) {
      const group = this.state[modalGroups[i]];
      const itemIndex = group.findIndex(value => modal === value);
      if (itemIndex >= 0) {
        let nextItems = group.slice();
        nextItems = [
          ...nextItems.slice(0, itemIndex),
          ...nextItems.slice(itemIndex + 1)
        ];
        const nextState: ModalOrchestratorState = {};
        nextState[modalGroups[i]] = nextItems;
        this.setState(nextState);
        break;
      }
    }
  };

  public componentWillReceiveProps(nextProps: ModalOrchestratorProps) {
    if (this.state.modals && this.state.modals.length && this.props.location.pathname !== nextProps.location.pathname) {
      this.state.modals = [];
    }

    if (this.props.modal) {
      const state = nextProps.location.state as any;
      const modalHistory = state && state.modalHistory === true;
      if (!nextProps.modal) {
        this.close(this.props.modal);
      } else {
        // Commented out is the version that could handle the replace method.
        //if (this.props.modal.type === nextProps.modal.type) {
          //if (!modalHistory) this.close(this.props.modal, true);
          //else this.hide(this.props.modal);
        //} else {
          //if (!modalHistory) this.close(this.props.modal);
          //else this.hide(this.props.modal);
        //}
        if (shallowEqual(nextProps.modal, this.props.modal)) {
          return null;
        } else if (this.backFlag) {
          this.close(this.props.modal);
        } else {
          if (!modalHistory) this.close(this.props.modal);
          else this.hide(this.props.modal);
        }
      }
    }
  }

  public componentDidUpdate() {
    this.backFlag = false;
  }

  public render() {
    const { modal } = this.props;
    let children: Array<any> = [].concat(this.renderLeavingModals())
      .concat(this.renderReplacedModals())
      .concat(this.renderHiddenModals());
    if (modal) {
      children = children.concat(<ModalProvider index={this.getModalId(modal)} key={this.getModalId(modal)} hidden={this.backFlag}>
        {modal}
      </ModalProvider>);
    }
    children = children.concat(this.renderNormalModals());
    children = children.sort((a, b) => {
      if (a && b) {
        if (a.props.index < b.props.index) return -1;
        if (a.props.index > b.props.index) return 1;
        return 0;
      }
      return 0;
    });

    return (
      <div>
        {children}
      </div>
    );
  }

  private renderModals(modals, render: (modal: React.ReactElement<any>, id: number) => React.ReactElement<any>) {
    return modals.map((modal: React.ReactElement<any>) => {
      const id = this.getModalId(modal);
      return render(modal, id);
    });
  }

  private renderLeavingModals = () => this.renderModals(
    this.state.leavingModals,
    (modal, id) => <ModalProvider index={id} key={id} leave>{modal}</ModalProvider>
  );

  private renderReplacedModals = () => this.renderModals(
    this.state.replacedModals,
    (modal, id) => <ModalProvider index={id} key={id} replace>{modal}</ModalProvider>
  );

  private renderHiddenModals = () => this.renderModals(
    this.state.hiddenModals,
    (modal, id) => <ModalProvider index={id} key={id} hide>{modal}</ModalProvider>
  );

  private renderNormalModals = () => this.renderModals(
    this.state.modals,
    (modal, id) => <ModalProvider index={id} key={id} hide>{modal}</ModalProvider>
  );
}

export default ModalOrchestrator;
