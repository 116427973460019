
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, Row, Select, Column, Switch, DatePicker, Form, TextField, ModalActions, Button, ModalSelect, Link, Alert, Loader,
} from '../../../../../../Elements';
import '../../../../../../../styles/pages/cms-pages/modals/new-page/index.scss';
import { actionCreators as appActionCreators } from '../../../../../../../store/App/actions';
import {
    actionCreators as adminCMSCacheOneActionCreators,
} from '../../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators, namespace } from '../../../../../../../store/AdminCMSSite/Pages/Modals/NewPage/actions';
import { FormDefinition } from '../../../../../../../store/AdminCMSSite/Pages/Modals/NewPage/validation';
import { ApplicationState } from '../../../../../../../store';
import { makeFilteredPageTypesSelector, makeSelectedLocationSelector, makeSelectedEventCategorySelector } from '../../../../../../../store/AdminCMSSite/Pages/Modals/NewPage';
import { PageType, PageTypeIDValue, Location } from '../../../../../../../models/api/adminCMSCacheOne';
import { BlogArticleIcon, CalendarEventIcon, LocationsIcon, EventsIcon, FacilitiesIcon, PageIcon, HomeIcon, CloseCircleIcon } from '../../../../../../../components/Icons';
import Tag from '../../../../../../../components/Elements/Tag';
import LocationCard, { LocationCardType } from '../../../../../../../components/Elements/CMSLocation/Card';
import { openSupportForm } from '../../../../../../../constants/urls';
import TimePicker from '../../../../../../../components/Elements/TimePicker';
import * as generic from '../../../../../../../constants/messages/adminCMS';
import { GetDefaultForPrimaryEventPageSubmitActions } from '../../../../../../../store/AdminCMSSite/Pages/Page/Form/actions';
import { CardCategory } from '../../../../../../../components/Elements/Card';
import { disableBodyScroll, enableBodyScroll } from '../../../../../../../utils/navHelper';
import { isMobile } from '../../../../../../../utils/isMobile';
import { checkLocationPermission } from '../../../../../../../utils/helpers/adminCMSPageHelper';
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import {WithInertAttribute} from '../../../../../../Elements/WithInert';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;
type Props = WithInertAttribute<{}>

@(withRouter as any)
class NewPageModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;

  private modalContentRef;
  private pageTypeSelectionRef;

  componentDidMount() {
    disableBodyScroll(this.pageTypeSelectionRef);
  }

  componentWillUnmount() {
    // we want to do a reset here to reset the PageTypeID, so that next time it wont show the form directly
    enableBodyScroll(this.pageTypeSelectionRef);
    this.onClose();
  }
  componentDidUpdate(prevProps: Props & ConnectedProps) {
    if (prevProps.newPageModal.ActiveForm.PageTypeID !== this.props.newPageModal.ActiveForm.PageTypeID) {
      if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
    }
    if (this.props.newPageModal.ActiveForm.PageTypeID !== prevProps.newPageModal.ActiveForm.PageTypeID) {
      enableBodyScroll(this.pageTypeSelectionRef);
      if (!this.props.newPageModal.ActiveForm.PageTypeID) {
        disableBodyScroll(this.pageTypeSelectionRef);
      } else {
        disableBodyScroll(this.modalContentRef);
      }
    }
  }
  onClose = () => {
    this.props.actions.reset();
  };

  getPageTypeIcon = (pageTypeID: number) => {
    switch (pageTypeID) {
      case PageTypeIDValue.BLOG_ARTICLE:
        return <BlogArticleIcon />;
      case PageTypeIDValue.CALENDAR_EVENT:
        return <CalendarEventIcon />;
      case PageTypeIDValue.DISTRICT:
        return <LocationsIcon />;
      case PageTypeIDValue.EVENT_MODULES:
        return <EventsIcon />;
      case PageTypeIDValue.FACILITY_MODULES:
        return <FacilitiesIcon />;
      case PageTypeIDValue.STANDARD_PAGE:
        return <PageIcon />;
      case PageTypeIDValue.HOMEPAGE:
        return <HomeIcon />;
      default:
        // default to standard page icon
        return <PageIcon />;
    }
  };

  onPageTypeCardClick = (pageType: PageType) => {
    this.props.actions.updateValue(pageType.ID, FormDefinition.PageTypeID);
    // we should init when `PageTypeID` is selected
    this.props.actions.init();
  };

  renderPageTypeSelection = () => {
    const {filteredPageTypes} = this.props;
    return (
      <div className={`${namespace()}--page-type-selection`}>
        <div className={`${namespace()}--page-type-selection--title`}>Select Page Type</div>
        <div
          ref={(ref) => this.pageTypeSelectionRef = ref}
          className={`${namespace()}--page-type-selection--wrapper`}>
          {filteredPageTypes.map((pt, index) => {
            return (
              <div className={`${namespace()}--page-type-selection--card ${isMobile ? 'mobile' : ''}`} key={`page-type-selection--card${index}`} onClick={() => this.onPageTypeCardClick(pt)}>
                <div className={`${namespace()}--page-type-selection--card--icon`}>{this.getPageTypeIcon(pt.ID)}</div>
                <div className={`${namespace()}--page-type-selection--card--text`}>
                  <div className={`${namespace()}--page-type-selection--card--text--title`}>
                    {pt.AddingName}
                  </div>
                  <div className={`${namespace()}--page-type-selection--card--text--desc`}>
                    {pt.AddingDescription}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  renderPageTitleField = () => {
    const {apiSaving, actions, newPageModal: { ActiveForm, ValidationRules }} = this.props;
    return (
      <Row>
        <Column span={12} mobileSpan={12}>
          <TextField
            label="Page Title"
            disabled={apiSaving > 0}
            onChange={actions.simpleUpdate}
            onBlur={actions.updateValue}
            validationRules={ValidationRules.Name}
            value={ActiveForm.Name}/>
        </Column>
      </Row>
    );
  };

  renderPageUrlField = () => {
    const {apiSaving, actions, newPageModal: { ActiveForm, ValidationRules }, adminCMSCacheOne} = this.props;

    if (!adminCMSCacheOne || !adminCMSCacheOne.CMSSite) return null;
    return (
      <Row>
        <Column span={12} mobileSpan={12}>
          <TextField
            label="Page URL"
            disabled={apiSaving > 0}
            onChange={actions.simpleUpdate}
            onBlur={actions.updateValue}
            validationRules={ValidationRules.ShortURL}
            value={ActiveForm.ShortURL}
            info={ActiveForm.ShortURL ? `${adminCMSCacheOne.CMSSite.SiteDomain}/` : 'Set this if you want the page at a specific URL.'}
            appendedBoldInfo={ActiveForm.ShortURL ? ActiveForm.ShortURL : undefined} />
            
        </Column>
      </Row>
    );
  };

  renderPageTypeStaticField = () => {
    const {newPageModal: { ActiveForm }, filteredPageTypes, adminCMSCacheOne} = this.props;
    const result: any[] = [];
    const currentPageType = filteredPageTypes.find((pt) => pt.ID === ActiveForm.PageTypeID);
    if (!currentPageType || !adminCMSCacheOne || !adminCMSCacheOne.CMSSite) return null;

    result.push(
      <Row key='pagetype-static-field1' className={`${namespace()}--form-wrapper--pagetype-row`}>
        <div className={`${namespace()}--form-wrapper--field--label ${namespace()} row`}>Page Type: </div>
        <Tag
          onRemove={() => this.props.actions.reset()}
          icon={this.getPageTypeIcon(ActiveForm.PageTypeID || 0)}
          removeIcon={<CloseCircleIcon />}
          text={currentPageType.AddingName}
          backgroundColor='orange' />
      </Row>
    );

    result.push(
      <Row key='pagetype-static-field2' marginTop={16} marginBottom={24}>
        <div className={`${namespace()}--form-wrapper--note`}>Page type can't be changed after a page is created. Please make sure you have selected the right type.</div>
      </Row>
    );

    return result;
  };

  renderStandardPageForm = () => {
    // no extra field
    const result = [
    ];

    return result;
  };

  renderBlogArticlePageForm = () => {
    const {newPageModal: { ActiveForm, ValidationRules }, actions} = this.props;
    const result = [
      (<Row marginTop={16}>
        <Column span={6} mobileSpan={12}>
          <DatePicker
            className={`${namespace()}--advanced-select`}
            label="Publish Date"
            value={ActiveForm.StartDate}
            validationRules={ValidationRules.StartDate}
            popperPlacement="bottom-start"
            popperModifiers={{
              flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
              },
              preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
              }
            }}
            onSelect={actions.updateValue}
            onChangeRaw={actions.simpleUpdate}
            removeMax
          />
        </Column>
        <Column span={6} mobileSpan={12}>
          <DatePicker
            className={`${namespace()}--advanced-select`}
            label="Expiration Date"
            value={ActiveForm.EndDate}
            validationRules={ValidationRules.EndDate}
            popperPlacement="bottom-start"
            popperModifiers={{
              flip: {
                  behavior: ["bottom"] // don't allow it to flip to be above
              },
              preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
              }
            }}
            onSelect={actions.updateValue}
            onChangeRaw={actions.simpleUpdate}
            removeMax
          />
        </Column>
      </Row>),
    ];

    return result;
  };

  renderDistrictPageForm = () => {
    const {apiSaving, newPageModal: { ActiveForm, ValidationRules }, actions} = this.props;
    const result = [
      <Row marginTop={16}>
        <Column span={12}>
          <Select
              label="District"
              disabled={apiSaving > 0}
              onChangeValue={actions.updateValue}
              value={ActiveForm.DistrictIDi}
              validationRules={ValidationRules.DistrictIDi}
              info={`District can't be changed after a page is created`}
              isNumber />
        </Column>
      </Row>
    ];

    return result;
  };

  onChangeEventType = (val: any, vObj: any) => {
    const {actions, params} = this.props;
    actions.updateValueWithSave(val, vObj);

    if (val) {
      actions.getDefaultForPrimaryEventPage({
        EventTypeID: Number(val),
        ID: 0,
        params,
      });
    }
  };

  renderEventModulePageForm = () => {
    const {apiSaving, newPageModal: { ActiveForm, ValidationRules }, actions} = this.props;
    const KEY_PREFIX = 'event-row';
    const result = [
      <Row key={`${KEY_PREFIX}1`} marginTop={16}>
        <Column span={12}>
          <Select
            label="Event Type"
            disabled={apiSaving > 0}
            onChangeValue={this.onChangeEventType}
            value={ActiveForm.EventTypeID}
            validationRules={ValidationRules.EventTypeID}
            isNumber />
        </Column>
      </Row>,
      <Row key={`${KEY_PREFIX}2`}>
        <Column span={12}>
          <Switch
            label="Primary Event Page"
            multilineLabel
            newDesign
            onChange={actions.updateValue}
            value={!!ActiveForm.IsPrimaryEventPage}
            validationRules={ValidationRules.IsPrimaryEventPage}
          />
        </Column>
      </Row>,
      <div key={`${KEY_PREFIX}3`} className={`${namespace()}--form-wrapper--note regular large`}>{generic.PRIMARY_EVENT_PAGE_NOTE}</div>,
      <Row key={`${KEY_PREFIX}4`} marginTop={8}>
        <Column span={12}>
          <Switch
            label="Registration Buttons"
            multilineLabel
            newDesign
            onChange={actions.updateValue}
            value={!!ActiveForm.ShowRegistrationLinks}
            validationRules={ValidationRules.ShowRegistrationLinks}
          />
        </Column>
      </Row>,
      <div key={`${KEY_PREFIX}5`} className={`${namespace()}--form-wrapper--note regular large`}>{generic.SHOW_REGISTRATION_LINKS_NOTE}</div>,
      <Row key={`${KEY_PREFIX}6`} marginTop={8}>
        <Column span={12}>
          <Switch
            label="Show Primary Contact From Event"
            multilineLabel
            newDesign
            onChange={actions.updateValue}
            value={!!ActiveForm.IncludeModuleContacts}
            validationRules={ValidationRules.IncludeModuleContacts}
          />
        </Column>
      </Row>,
      <div className={`${namespace()}--form-wrapper--note regular large`}>{generic.INCLUDE_EVENT_MODULE_CONTACTS_NOTE}</div>,
    ];

    return result;
  };

  onClickSelectLocationButton = (isFacilities?: boolean) => {
    this.props.actions.pushSelectLocationModal({context: 'admin-cms-add-page', isFacilities: !!isFacilities});
  };
  onRemoveSelectedLocation = () => {
    this.props.actions.updateValue(undefined, FormDefinition.LocationID);
  };
  onEditSelectedLocation = (location: Location) => {
    if (!location.Inactive) {
      checkLocationPermission(
        () => {
          this.props.actions.pushFormModal(ModalTypes.LOCATION_FORM, true, {id: location.ID});
        },
        this.props.cacheZero,
        location,
        true,
      );
    }
  };

  renderFacilityModulePageForm = () => {
    const {apiSaving, newPageModal: { ActiveForm, ValidationRules }, actions, selectedLocation} = this.props;
    const KEY_PREFIX = 'facility-row';
    const result = [
      <Row key={`${KEY_PREFIX}1`} marginTop={16}>
        <Column span={12} mobileSpan={12}>
          <ModalSelect
            label="Facility Location"
            selectButtonLabel="SELECT LOCATION"
            hideError={!!selectedLocation}
            disabled={apiSaving > 0}
            selectedCard={selectedLocation ? <LocationCard
              showDeletedPrompt
              location={selectedLocation}
              cardCategory={CardCategory.LIST}
              type={LocationCardType.MODAL_SELECT}
              onRemove={this.onRemoveSelectedLocation}
              onEdit={this.onEditSelectedLocation}
            /> : null}
            validationRules={ValidationRules.LocationID}
            onClickSelectButton={() => this.onClickSelectLocationButton(true)}
            value={ActiveForm.LocationID}/>
        </Column>
      </Row>,
      <Row key={`${KEY_PREFIX}2`}>
        <Column span={12}>
          <Switch
            label="Show Primary Contact From Facility Location"
            multilineLabel
            newDesign
            onChange={actions.updateValue}
            value={!!ActiveForm.IncludeModuleContacts}
            validationRules={ValidationRules.IncludeModuleContacts}
          />
        </Column>
      </Row>,
      <div key={`${KEY_PREFIX}3`} className={`${namespace()}--form-wrapper--note regular large`}>{generic.INCLUDE_FACILITY_MODULE_CONTACTS_NOTE}</div>,
    ];

    return result;
  };

  renderCalendarEventPageForm = () => {
    const {apiSaving, newPageModal: { ActiveForm, ValidationRules }, selectedEventCategory, actions, selectedLocation} = this.props;
    const KEY_PREFIX = 'calendar-event--row';
    const result = [
      <Row key={`${KEY_PREFIX}1`} marginTop={16}>
        <Column span={12} mobileSpan={12}>
          <ModalSelect
            label="Event Location"
            selectButtonLabel="SELECT LOCATION"
            hideError={!!selectedLocation}
            disabled={apiSaving > 0}
            paddingBottom={24}
            mobilePaddingBottom={16}
            selectedCard={selectedLocation ? <LocationCard
              showDeletedPrompt
              location={selectedLocation}
              cardCategory={CardCategory.LIST}
              type={LocationCardType.MODAL_SELECT}
              onRemove={this.onRemoveSelectedLocation}
              onEdit={this.onEditSelectedLocation}
            /> : null}
            validationRules={ValidationRules.LocationID}
            onClickSelectButton={this.onClickSelectLocationButton}
            value={ActiveForm.LocationID}/>
        </Column>
      </Row>,
      <Row key={`${KEY_PREFIX}2`}>
        <Column span={6} mobileSpan={12}>
          <Select
            label="Event Category"
            disabled={apiSaving > 0}
            onChangeValue={actions.updateValue}
            value={ActiveForm.EventCategoryID}
            validationRules={ValidationRules.EventCategoryID}
            info={<div className={`${namespace()}--card--help-text`}>To add a new category, please <Link black medium externalLink={openSupportForm(true)}>contact Tentaroo</Link></div>}
            isNumber />
        </Column>
        {selectedEventCategory && selectedEventCategory.IsDistricts && <Column span={6} mobileSpan={12}>
          <Select
            label="District"
            disabled={apiSaving > 0}
            onChangeValue={actions.updateValue}
            value={ActiveForm.DistrictIDi}
            validationRules={ValidationRules.DistrictIDi}
            isNumber />
        </Column>}
      </Row>,
      <Row key={`${KEY_PREFIX}3`} marginTop={16}>
        <Column span={6} mobileSpan={12}>
          <Switch
            className={`${namespace()}--center-switch`}
            multilineLabel
            label="All-Day Event"
            newDesign
            onChange={actions.updateValue}
            value={!!ActiveForm.IsAllDay}
            validationRules={ValidationRules.IsAllDay}
          />
        </Column>
      </Row>,
      <Row marginTop={16} key={`${KEY_PREFIX}4`}>
        <Column span={6} mobileSpan={12}>
          <DatePicker
            label="Start Date"
            value={ActiveForm.StartDate}
            validationRules={ValidationRules.StartDate}
            onSelect={actions.updateValue}
            onChangeRaw={actions.simpleUpdate}
            removeMax
          />
        </Column>
        {!ActiveForm.IsAllDay ? <Column span={6} mobileSpan={12}>
          <TimePicker
            label="Time"
            disabled={apiSaving > 0}
            onChangeValue={actions.updateValue}
            hourValue={ActiveForm.StartHour}
            minValue={ActiveForm.StartMin}
            periodValue={ActiveForm.StartPeriod}
            hourValidationRules={ValidationRules.StartHour}
            minValidationRules={ValidationRules.StartMin}
            periodValidationRules={ValidationRules.StartPeriod}
            customizedId='NewPage_StartTime'
          />
        </Column> : null}
      </Row>,
      <Row key={`${KEY_PREFIX}5`}>
        <Column span={6} mobileSpan={12}>
          <DatePicker
            label="End Date"
            value={ActiveForm.EndDate}
            validationRules={ValidationRules.EndDate}
            onSelect={actions.updateValue}
            onChangeRaw={actions.simpleUpdate}
            removeMax
          />
        </Column>
        {!ActiveForm.IsAllDay ? <Column span={6} mobileSpan={12}>
          <TimePicker
            label="Time"
            disabled={apiSaving > 0}
            onChangeValue={actions.updateValue}
            hourValue={ActiveForm.EndHour}
            minValue={ActiveForm.EndMin}
            periodValue={ActiveForm.EndPeriod}
            hourValidationRules={ValidationRules.EndHour}
            minValidationRules={ValidationRules.EndMin}
            periodValidationRules={ValidationRules.EndPeriod}
            customizedId='NewPage_EndTime'
          />
        </Column> : null}
      </Row>,
    ];

    return result;
  };

  renderNewPageForm = () => {
    const {newPageModal: { ActiveForm, SubmitErrorMessage }} = this.props;
    return (

      <div className={`${namespace()}--form-wrapper`}>
        <Form
          onSubmit={e => e.preventDefault()}>
          {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
          {this.renderPageTypeStaticField()}
          {this.renderPageTitleField()}
          {this.renderPageUrlField()}
          {ActiveForm.PageTypeID === PageTypeIDValue.STANDARD_PAGE ? this.renderStandardPageForm() : null}
          {ActiveForm.PageTypeID === PageTypeIDValue.BLOG_ARTICLE ? this.renderBlogArticlePageForm() : null}
          {ActiveForm.PageTypeID === PageTypeIDValue.DISTRICT ? this.renderDistrictPageForm() : null}
          {ActiveForm.PageTypeID === PageTypeIDValue.EVENT_MODULES ? this.renderEventModulePageForm() : null}
          {ActiveForm.PageTypeID === PageTypeIDValue.FACILITY_MODULES ? this.renderFacilityModulePageForm() : null}
          {ActiveForm.PageTypeID === PageTypeIDValue.CALENDAR_EVENT ? this.renderCalendarEventPageForm(): null}
        </Form>
      </div>
    );
  };

  onCreate = () => {
    this.props.actions.apiSubmitForm(this.props.router);
  };

  public render() {
    const { apiLoading, apiLoadingMap, apiSaving, adminCMSCacheOne, newPageModal: { ActiveForm, ValidationRules }, actions, inert} = this.props;

    const subtitle = adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.SiteDomain : '';
    const loading = apiLoadingMap[GetDefaultForPrimaryEventPageSubmitActions.requestType];
    return (
      <Modal inert={inert} onClose={this.onClose} wideModal big mobileFullScreen className={namespace()}>
        <ModalHeader
          subtitle={subtitle}
          className={`${namespace()}--header`}>
          New Page
        </ModalHeader>
        <ModalContent
          noBodyScrollLock={!ActiveForm.PageTypeID}
          refCallback={(ref) => this.modalContentRef = ref} paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0} >
          {!ActiveForm.PageTypeID ? this.renderPageTypeSelection() : null}
          {ActiveForm.PageTypeID ? this.renderNewPageForm() : null}
          {loading && <Loader className={`${namespace()}--loader`} center />}
        </ModalContent>
        {ActiveForm.PageTypeID ? <ModalActions
          sticky
          notFixed
          left={<Button textColor='black' disabled={apiSaving > 0} flat onClick={this.onCreate}>CREATE</Button>}
        />: null}
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filterPageTypesSelector = makeFilteredPageTypesSelector();
  const selectedLocationSelector = makeSelectedLocationSelector();
  const selectedEventCategorySelector = makeSelectedEventCategorySelector();
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    newPageModal: state.adminCMSSite.pages.modals.newPage,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    filteredPageTypes: filterPageTypesSelector(state),
    selectedLocation: selectedLocationSelector(state),
    selectedEventCategory: selectedEventCategorySelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
  ...adminCMSCacheOneActionCreators,
}, dispatch) });
const ConnectedNewPageModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(NewPageModal);

export default ConnectedNewPageModal;