import {MAX_LENGTH, REQUIRED, MIN_LENGTH} from "../../constants/messages/generic";
import { Validator } from '../../utils/validator/models';

export interface IValidator {
  Username: Validator;
  Password: Validator;
}

export const FormDefinition: IValidator = {
  Username: {
    key: 'Username',
    validatejs: {
      Username: {
        presence: {message: '^' + REQUIRED},
        length: {
          minimum: 3,
          maximum: 50,
          tooLong: MAX_LENGTH(50),
          tooShort: MIN_LENGTH(3)
        }
      }
    }
  },
  Password: {
    key: 'Password',
    validatejs: {
      Password: {
        presence: {message: '^' + REQUIRED},
        length: {
          maximum: 50,
          tooLong: MAX_LENGTH(50),
        }
      }
    }
  }
};
