import { WizardReservation } from "./cacheFourFacilities";
import {FacilityInfo, FacilitiesAvailability, FacilityLocation_FacilityType} from "./cacheTwoFacilties";
import * as moment from 'moment';

// Reservation under cacheThree facility, used in ViewReservation page
export type TripReservation = FacilityInfo & {
  ID: number;
  InCart: 1 | 0 | -1;
  WasFinalized: boolean;
  Amount: number;
  NumAdults: number;
  NumYouth: number;
  FacilityType: string;
  StartDateTime: moment.Moment;
  EndDateTime: moment.Moment;
  Type: FacilityLocation_FacilityType;
}

/**
 * Predicate to differentiate TripReservation, FacilitiesAvailability and WizardReservation, check if an
 * object is TripReservation
 * 
 * TripReservation has some overlap fields with both FacilitiesAvailability and WizardReservation, and that's why we need such predicate
 * 
 * An object is TripReservation if it has `WasFinalized`
 */
export function isTripReservation(facilityOrReservation: FacilitiesAvailability | TripReservation | WizardReservation): facilityOrReservation is TripReservation {
  return "WasFinalized" in facilityOrReservation;
}

export interface FacilityTrip {
  FacilityTripID: number;
  Name: string;
  ContactName: string;
  ContactPhone: string;
  ContactEmail: string;
  ContactName2: string;
  ContactPhone2: string;
  ContactEmail2: string;
  NumAdults: number;
  NumYouth: number;
  GroupNotes: string;
  AdminNotes: string;
  StartDateTime: moment.Moment;
  EndDateTime: moment.Moment;
  TS: number;
  InCart: 1 | 0 | -1;
  ReservationTotal: number;
  AmountPaid: number;
  AmountPaidPending: number;
  AmountInCart: number;
  AmountMin: number;
  AmountMax: number;
  TotalBalance: number;
  ReservationTotalsByType: Array<ReservationTotalByType>;
}

export interface ReservationTotalByType {
  Name: string;
  Amount: number
}

export interface FacilityTripPermissions {
  FacilityTripID: number;
  hasAllowAdd: boolean;
  hasAllowAddReason: string | null;
  hasAllowChange: boolean;
  hasAllowChangeReason: string | null;
  hasAllowCreditChange: boolean;
  hasAllowCreditChangeReason: string | null;
  hasAllowOverride: boolean;
  hasAllowOverrideReason: string | null;
  hasAllowTripChange: boolean;
  hasAllowTripChangeReason: string | null;
  hasFacilityReservations: boolean;
  hasFacilityReservationsReason: string | null;
  hasViewReports: boolean;
  hasViewReportsReason: string | null;
}
