import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminCMSCacheOneContext } from "@tentaroo/shared";

import { ApplicationState } from '../../../../../store';
import {
    actionCreators as cacheZeroActionCreators
} from '../../../../../store/CacheZero/actions';
import {
    actionCreators as adminCMSCacheOneActionCreators, GetSiteCache,
} from '../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as cacheOneActions} from "../../../../../store/CacheOne/actions";
import { actionCreators as settingsMainActions} from "../../../../../store/AdminCMSSite/Settings/Main/actions";
import { actionCreators as settingsGeneralActionCreators} from "../../../../../store/AdminCMSSite/Settings/General/actions";
import { actionCreators as settingsMessagesActionCreators} from "../../../../../store/AdminCMSSite/Settings/Messages/actions";
import { LoadingAll, Tabs, Tab, Form, FormActions, Button, PageLoader } from '../../../../../components/Elements';
import { Main, MainContent } from '../../../../../components/Layouts';
import '../../../../../styles/pages/cms-settings/index.scss';
import { getSettingsRootUrl, getSettingsMessagesUrl, constructCMSSiteUrlParams } from '../../../../../constants/urls';
import { navPush } from '../../../../../utils';
import GeneralSettings from './GeneralSettings';
import MessagesSettings from './MessagesSettings';
import { ADMIN_CMS_SETTINGS_GENERAL_PATH, ADMIN_CMS_SETTINGS_MESSAGES_PATH } from '../../../../../routes';
import AdminCMSCacheManager from '../../../../../utils/cacheManagers/adminCMSCacheManager';
import { IAdminCMSRouterParams } from '../../../../../utils/helpers/adminCMSPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { noOpenedModals } from '../../../../../utils/modalHelper';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isAdminCMSCacheOnePopulated} from '../../../../../utils/cachePopulatedCheckers/adminCMS';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--settings';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminCMSRouterParams, {}>
>;

class Settings extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;

  public componentDidMount() {
    this.props.actions.showAdminPageHeader(true);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        AdminCMSCacheManager.getInstance().loadAdminCMSCacheOne({
          props: this.props,
          isStateNavigated,
          context: this.getContext(),
        });
      }
    );
  }

  componentWillUnmount() {
    this.props.actions.reset();
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        AdminCMSCacheManager.getInstance().loadAdminCMSCacheOne({
          props: nextProps,
          isStateNavigated,
          context: this.getContext(nextProps),
        });
      },
    );
  }

  onClickGeneralTab = () => {
    const url = getSettingsRootUrl(constructCMSSiteUrlParams(this.props, this.props.adminCMSCacheOne));
    navPush(this.props.router, url);
  };

  onClickMessagesTab = () => {
    const url = getSettingsMessagesUrl(constructCMSSiteUrlParams(this.props, this.props.adminCMSCacheOne));
    navPush(this.props.router, url);
  };

  getSelectedTab = (props?: ConnectedProps) => {
    const routes = props ? props.routes : this.props.routes;
    const route = routes[routes.length - 1];
    const selectedTab = route.path === ADMIN_CMS_SETTINGS_GENERAL_PATH ? 'general' : (route.path === ADMIN_CMS_SETTINGS_MESSAGES_PATH ? 'messages' : '');

    return selectedTab;
  };

  getContext = (props?: ConnectedProps) => {
    const selectedTab = this.getSelectedTab(props);

    return selectedTab === "general" ? AdminCMSCacheOneContext.SETTINGS_GENERAL : AdminCMSCacheOneContext.SETTINGS_MESSAGES;
  };

  onSave = () => {
    const selectedTab = this.getSelectedTab();

    if (selectedTab === 'general') {
      this.props.settingsGeneralActions.apiSubmitGeneralSettings();
    } else if (selectedTab === 'messages') {
      this.props.settingsMessagesActions.apiSubmitMessagesSettings();
    }
  };
  public render() {
    const {adminCMSCacheOne, inert, generalSettingsFormState, apiLoadingMap, apiSaving} = this.props;

    if (!isAdminCMSCacheOnePopulated(undefined, generalSettingsFormState.ActiveForm)) {
        return <LoadingAll />;
    }

    const selectedTab = this.getSelectedTab();
    const disabled = (adminCMSCacheOne.CMSSite ? !adminCMSCacheOne.CMSSite.hasSettingsEdit : true) || apiSaving > 0;
    
    let tabValue;

    const refreshing = apiLoadingMap[GetSiteCache.requestType] && noOpenedModals();
    const saving = apiSaving > 0 && noOpenedModals();

    return (
      <Main inert={inert} isLoading={refreshing} isCMSSite>
        <MainContent className={`${namespace()}`} handleCompact>
          <Tabs className={`${namespace()}--tabs`}>
            <Tab selected={selectedTab === 'general'} onClick={this.onClickGeneralTab}>GENERAL</Tab>
            <Tab selected={selectedTab === 'messages'} onClick={this.onClickMessagesTab}>MESSAGES</Tab>
          </Tabs>
          <Form
              onSubmit={e => e.preventDefault()}
              actions={
                <FormActions handleCompact>
                  <Button disabled={disabled} flat textColor="black" big onClick={this.onSave}>SAVE CHANGES</Button>
                </FormActions>
              }
            >
            {selectedTab === 'general' && <GeneralSettings disabled={disabled} />}
            {selectedTab === 'messages' && <MessagesSettings disabled={disabled} />}
          </Form>
        </MainContent>
        {saving && <PageLoader fullScreen />}
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    generalSettingsFormState: state.adminCMSSite.settings.general,
    cacheZero: state.cacheZero,
    cacheOne: state.cacheOne,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...cacheZeroActionCreators,
    ...appActionCreators,
    ...cacheOneActions,
    ...adminCMSCacheOneActionCreators,
    ...settingsMainActions,
  }, dispatch),
  settingsGeneralActions: bindActionCreators({
    ...settingsGeneralActionCreators,
  }, dispatch),
  settingsMessagesActions: bindActionCreators({
    ...settingsMessagesActionCreators,
  }, dispatch),
});

const ConnectedSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(Settings);

export default withRouter<{}>(ConnectedSettings);
