import * as React from 'react';
import '../../../../../../styles/pages/cms-pages/edit-page/section.scss';
import { default as attribute, MarginProps } from '../../../../../../styles';
import {Card} from '../../../../../Elements';
import { ArrowIcon, AttentionAlert } from '../../../../../Icons';
import { generateDOMId } from '../../../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--page-section';

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin';
  title?: string;
  noPadding?: boolean;
  noPaddingBottomMobile?: boolean;
  showHeaderMobileOnly?: boolean;
  headerControl?: React.ReactNode;
  error?: boolean;
  expand?: boolean;
  onExpand?: (expand: boolean) => void;
}

class PageSection extends React.Component<Props, {}> {

  componentDidMount() {
  }

  onExpandClick = (e) => {
    e.stopPropagation();
    this.props.onExpand && this.props.onExpand(!this.props.expand);
  };

  renderHeader = () => {
    const {title, showHeaderMobileOnly, headerControl, onExpand, error, expand} = this.props;
    return (
      <div id={generateDOMId(`page-section-${title}`)} className={`${namespace()}--wrapper--section-header ${onExpand ? 'expandable' : ''} ${expand ? 'expand' : ''} ${showHeaderMobileOnly ? 'mobile-only' : ''}`} onClick={this.onExpandClick}>
        <div className={`${namespace()}--wrapper--section-header--title ${error ? 'error' : ''}`}>{title}{error ? <AttentionAlert /> : null}</div>
        {expand && headerControl ? headerControl : null}
        {onExpand && <div className={`${namespace()}--wrapper--section-header--arrow ${expand ? 'expand' : ''}`}><ArrowIcon /></div>}
      </div>
    );
  };


  render() {
    const {template, children, title, noPaddingBottomMobile, noPadding, expand} = this.props;
    let className = attribute(namespace(), undefined, this.props);

    if (noPadding) className += ' no-padding';
    if (noPaddingBottomMobile) className += ' no-padding-bottom-mobile';
    return (
      <Card
        className={className}
        component={<section/>}
        template={template || 'mobile-no-margin'}
        padding="none"
        marginBottom={24}
      >
        <div className={`${namespace()}--wrapper ${expand ? 'expand' : ''}`}>
          {title && this.renderHeader()}
          {expand ? <div className={`${namespace()}--wrapper--content${noPaddingBottomMobile ? ' no-padding-bottom-mobile' : ''} ${!title ? 'no-title' : ''} ${noPadding ? 'no-padding' : ''}`}>{children}</div> : null}
        </div>
      </Card>
    );
  }
}

export default PageSection;