import { IMAGE_PATH } from "../constants/urls";
import { Image, ImageWithInitial, WithInitial } from "../models/api/cacheOne";
import { FacilityImage } from "../models/api/cacheTwoFacilties";
import { AdminEventChildClass, AdminEventClass } from "../models/api/adminEventsCacheTwoEvent";
import {
  EventClassGroupAvailable,
  EventClassIndividualAvailable,
  RegisteredClass,
} from "../models/class";
import { ClassType, MeritBadge } from "../models/api/options";
import { captureTentarooError } from "./dataHelper";
import createInitials from "./createInitials";
import { reduxStoreService } from "../store/service";

export function encodeAll(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
}

/**
 * Gets image path from an image object. This will return empty string if `ImageOriginal` is not found.
 *
 * The returned value is then consumed by the `<Vignette />` where we always pass in
 * both image path and initials + color, so that it can fallback to use initials + color
 *
 */
export const getImagePath = (
  image: Image,
  size:
    | "2200px"
    | "1500px"
    | "720px"
    | "th300px"
    | "th300px_auto"
    | "th150px_auto" = "th300px_auto"
) => {
  if (!image.ImageOriginal) return "";
  const filePath = image.filePath ? image.filePath : "/resources/mb_images/";
  return `${IMAGE_PATH}f_auto,t_${size}${filePath}${encodeAll(
    image.ImageOriginal
  )}`;
};

export const getLargeImagePath = (image: FacilityImage) => {
  return getImagePath(image, "1500px");
};

/**
 * Type predicate to validate an image object
 * 
 * It is considered valid when it has a ImageOriginal field so
 * that we can construct path for the image
 */
export const isImageValid = (
  image: Image | MeritBadge | ImageWithInitial | null | undefined
): image is Image | MeritBadge => {
  return (
    !!image &&
    "ImageOriginal" in image &&
    !!image.ImageOriginal &&
    image.ImageOriginal !== ""
  );
};

export const createImageGallery = (Images: Array<FacilityImage>) => {
  const images: Array<any> = [];
  if (Images) {
    Images.forEach((im) => {
      images.push({
        original: getLargeImagePath(im),
        thumbnail: getImagePath(im),
      });
    });
  }
  return images;
};

const getClassTypeForClass = (
  c:
    | AdminEventClass
    | RegisteredClass
    | EventClassGroupAvailable
    | EventClassIndividualAvailable
    | AdminEventChildClass
): ClassType | undefined => {
  const state = reduxStoreService().getState();

  // NOTE: Not using selector here to avoid import issue
  const cacheZeroOptions = state.cacheZero.options;

  if (cacheZeroOptions) {
    const classType = cacheZeroOptions.ClassTypes?.find(
      (o) => o.IDi === c.ClassTypeIDi
    );

    if (!classType) {
      // Not throwing error here, relying on caller to silently fail just so
      // the app is not crashed
      captureTentarooError(
        new Error(`Cannot find ClassType for class: ${"ClassIDi" in c ? c.ClassIDi : c.IDi}`)
      );
    }

    return classType;
  }
};

const getMeritBadge = (mbID: number): MeritBadge | undefined => {
  const state = reduxStoreService().getState();

  // NOTE: Not using selector here to avoid import issue
  const cacheZeroOptions = state.cacheZero.options;

  if (cacheZeroOptions) {
    const meritBadge = cacheZeroOptions.MeritBadges?.find(
      (mb) => mb.ID === mbID
    );

    return meritBadge;
  }
};

export const getClassImage = (
  c:
    | AdminEventClass
    | RegisteredClass
    | EventClassGroupAvailable
    | EventClassIndividualAvailable
    | AdminEventChildClass
): WithInitial<Image> | WithInitial<MeritBadge> | ImageWithInitial | undefined => {
  const classType = getClassTypeForClass(c);

  if (!classType) {
    captureTentarooError(
      new Error(`Failed to get image for class ${c.IDi}`),
    );
    return undefined;
  }

  return getClassTypeImage(classType, c);
};

export const getClassTypeImage = (
  classType: ClassType,
  c?:
    | AdminEventClass
    | RegisteredClass
    | EventClassGroupAvailable
    | EventClassIndividualAvailable
    | AdminEventChildClass
): WithInitial<Image> | WithInitial<MeritBadge> | ImageWithInitial | undefined => {
  const initial: ImageWithInitial = {
    initial: createInitials(classType.Name),
    color: `#${classType.Color}`,
  };

  if (classType.FeaturedImage && classType.FeaturedImage.ImageOriginal) {
    return {...classType.FeaturedImage, ...initial};
  }

  const mbID = c ? c.mbID : classType.mbID;
  if (mbID) {
    const meritBadge = getMeritBadge(mbID);
    if (meritBadge && meritBadge.ImageOriginal) {
      return {...meritBadge, ...initial};
    } else if (!meritBadge) {
      captureTentarooError(
        c ? new Error(`Failed to find merit badge for class ${c.IDi}`)
        : new Error(`Failed to find merit badge for class type ${classType.IDi}`)
      );
    }
  }

  return initial;
};
