import * as React from 'react';
import EmptyMessage from '../../EmptyMessage';
import { PersonIcon } from '../../../Icons';
import { namespace as ns } from './constants';

import '../../../../styles/elements/roster/list/empty.scss';

export const namespace = (): string => `${ns()}--empty`;

interface Props {
  message: string;
  button?: React.ReactNode;
}

const Empty: React.SFC<Props> = ({ message, button }: Props): React.ReactElement<Props> => (
  <div className={namespace()}>
    <EmptyMessage
      small
      icon={PersonIcon}
      description={message}
    />
    <div className={`${namespace()}--add`}>
      {button}
    </div>
  </div>
);

export default Empty;
