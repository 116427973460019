import * as Actions from './actions';
import { isActionType } from '../../utils/StrongActions';
import { RegisterFormModel, RegistrationTypeOption } from '../../models/api/registerForm';
import { IValidator, FormDefinition } from './validation';
import {SUFFIX, GetRegisterFormActions} from './actions';
import { apiValidationReducerCreator } from '../Validation/reducerCreator';
import {setDefaults} from '../../utils/validator';
import {APISuccessSubmit, WithRootState} from "../Validation/actionCreator";
import {SUFFIX_EMAIL} from "./actions";
import { cacheZeroKeys } from '../CacheZero/constants';
import { API_UPD } from '../../constants/request';
import { ClearAllCache } from '../App/actions';
import { AnyAction, Reducer } from 'redux';

export interface CreateAccountState {
  Loaded: boolean;
  RegisterForm?: RegisterFormModel; // API data
  ActiveForm: { // Change state of the UI
    RegistrationType?: RegistrationTypeOption; // The selected index
    ShowGroupType?: boolean;
    GroupTypeID?: number; ///  1 | 2 | 4; // Currently we only support these 3 Group Types
    UnitTypeID?: number; // Only required with GroupType 1
    Unit?: string; // Only required with GroupType 1
    UnitGenderID?: number;
    CouncilIDi?: number; // Only required with GroupType 1
    DistrictIDi?: number; // Only required with GroupType 1
    FirstName?: string;
    LastName?: string;
    Email?: string;
    Username?: string;
    Password?: string;
    ConfirmPassword?: string;
    PhoneNumber?: string;
    DesiredURL?: string;
    Organization?: string; // show only if Non-profit (4); max 100
  },
  ValidationRules: IValidator;
  SubmitErrorMessage?: string; // @todo: this should be composable too
}

const checkApiValidation = apiValidationReducerCreator(SUFFIX);
const checkApiEmailValidation = apiValidationReducerCreator(SUFFIX_EMAIL);

const DEFAULT_NAME = '';

const DEFAULT_LOCATION_EVENT_OPTION: RegistrationTypeOption = {
  EventTypeID: -1,
  FacilityLocationID: -1,
  Name: DEFAULT_NAME,
  IsLocation: false,
  ShowGroupType: false,
  DefaultGroupTypeID: 0,
  DefaultUnitTypeID: 0
};

const getInitialState: () => CreateAccountState = () => ({
  Loaded: false,
  ActiveForm: {},
  ValidationRules: { ...FormDefinition },
});

const CreateAccount: Reducer<CreateAccountState> = (oldState = getInitialState(), action: WithRootState<AnyAction>) => {
  const s = checkApiValidation(oldState, action);
  const state = checkApiEmailValidation(s, action);
  if (action.type === GetRegisterFormActions.successType) {
    const castedAction = <WithRootState<APISuccessSubmit>> action;
    const res = castedAction.response.response;

    const RegisterForm = {} as any;

    /**
     * Skip fields that we already store in cacheZero
     */
    for (let k in res) {
      if (!cacheZeroKeys[k] && k !== "SystemSettings") {
        RegisterForm[k] = res[k];
      }
    }

    const newState = {
      ...state,
      RegisterForm,
      Loaded: true,
      ValidationRules: {...FormDefinition},
    };
    const defaultOption = {...DEFAULT_LOCATION_EVENT_OPTION};
    newState.RegisterForm.RegistrationTypeOptions = [defaultOption, ...newState.RegisterForm.RegistrationTypeOptions ];

    return newState;
  }
  /**
   * Initialization (i.e. setDefaults) in CreateAccount form is special here because some fields' default
   * values depends on RegistrationType's default value. And with current infrastructure (i.e. the way defaultValue works),
   * we can only finish this default value dependency by two actions:
   * 1. The normal updateForm action will ONLY set default values for `RegistrationType`
   * 2. Then `InitRegisterFormOtherFields` action is dispatched afterwards, so that we have access to the
   *    value of `RegistrationType` field, and use that to set default values for other fields
   * 
   * TODO: Later, when refactor the form init infra and mechanism in app, should take this into consideration
   */
  else if (action.type.includes(API_UPD) && action.type.includes(Actions.UPDATE_REGISTER_FORM_SUFFIX)) {
    const newState = {
      ...state,
      ActiveForm: {},
      ValidationRules: { ...FormDefinition},
      SubmitErrorMessage: undefined,
    };

    newState.ActiveForm.RegistrationType = FormDefinition.RegistrationType.getValue?.(
      action.rootState,
      FormDefinition.RegistrationType.defaultValue?.(action.rootState),
    );

    return newState;
  } else if (isActionType(action, ClearAllCache)) {
    return getInitialState();
  } else if (isActionType(action, Actions.InitRegisterFormOtherFields)) {
    return setDefaults((action as WithRootState<Actions.InitRegisterFormOtherFields>).rootState, state);
  }
  return state;
};

export default CreateAccount;
