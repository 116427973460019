import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra
} from '../../../Validation/actionCreator';
import type { ActionCreator, ApplicationState } from '../../..';
import { SaveState } from '../../../Rollback/actions';
import { actionCreators as appActionCreators, ShowTopFloatingAlert, ToggleModalSaving } from '../../../App/actions';
import { scrollSideModalToTop } from '../../../../utils/helpers/adminCMSPageHelper';
import { navPush } from '../../../../utils';
import { ADMIN_EVENTS_MODULE_SUFFIX, ADMIN_EVENT_TYPES_SUFFIX } from '../../CacheOne/actions';
import { makeFormModalPropSelector } from '../../../App';
import { getAdminEventsMessageCenterHomeRootUrl, getDeleteAdminEventMessageBody, getUpdateAdminEventMessageBody } from '../../../../constants/adminEventsUrls';
import { ResetAdminEventsRegisteredGroup } from '../../Shared/actions';
import { ResetSendMessageModal } from '../Modals/SendMessage/actions';
import { isMobileAndSmallerScreenSize } from '../../../../utils/isMobile';
import { ModalTypes, isModalOpened } from '../../../../utils/modalHelper';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../../../utils/suffix';
import { PlainRoute } from 'react-router';
import { typeName, Action } from '../../../../utils/StrongActions';
import { ClearAdminEventsCacheTwoMessage } from '../../CacheTwoMessage/actions';

export const UPDATE_EVENT_MESSAGE_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__ADMIN_EVENT_MESSAGE';
export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + '__ADMIN_EVENT_MESSAGE_FORM' + UPDATE_EVENT_MESSAGE_FORM_SUFFIX;
export const DEL_SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + '__DELETE_ADMIN_MESSAGE' + UPDATE_EVENT_MESSAGE_FORM_SUFFIX;

@typeName(`CLEAR_ERROR${SUFFIX}`) export class ClearError extends Action {}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export const AddMessageSubmitActions = createApiSubmitActions(SUFFIX);
export const DeleteMessageSubmitActions = createApiSubmitActions(DEL_SUFFIX);

export const AdminEventMessageFormNamespace = (): string => 'pages--admin-events--message';

export type Actions = typeof actionCreators;

const formCreator = (rootState: ApplicationState, afterSend: boolean) => {
  const ActiveForm = rootState.adminEvents.messageCenter.form.ActiveForm;
  const SendMessageActiveForm = rootState.adminEvents.messageCenter.modals.sendMessage.ActiveForm;
  const GroupsCSV = SendMessageActiveForm.IsAllRegisteredGroups ? '' :( SendMessageActiveForm.SelectedGroups && SendMessageActiveForm.SelectedGroups.length > 0 ? SendMessageActiveForm.SelectedGroups.join(', ') : '');
  const messageIdSelector = makeFormModalPropSelector(ModalTypes.SEND_MESSAGE, 'id');

  const messageInfo = {
    ID: afterSend ? messageIdSelector(rootState) : ActiveForm.ID,
    Name: ActiveForm.Name,
    Message: ActiveForm.Message,
    TS: ActiveForm.TS
  };
  return getUpdateAdminEventMessageBody({
    ...messageInfo,
    SendingMessage: !!afterSend,
    SendToEventIDi: SendMessageActiveForm.SelectedEventID,
    GroupsCSV,
  });
};

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.messageCenter.form),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  apiSubmitForm: (router: any, routes: PlainRoute<any>[], beforeSend?: boolean, afterSend?: boolean): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const route = routes[routes.length - 1];

    // if sending message from message list page
    if (!state.adminEvents.cacheTwoMessage.Message && afterSend) {
      const body = formCreator(getState(), true);
      dispatch(appActionCreators.popModal(true, false, ModalTypes.SEND_MESSAGE) as any);
      dispatch(new ResetAdminEventsRegisteredGroup());
      dispatch(new ResetSendMessageModal());
      dispatch(AddMessageSubmitActions.request(
        body,
        getCacheLevelExtra(true, !!state.adminEvents.cacheTwoMessage.Message),
        route,
      ));
      return;
    }

    // Otherwise, it's saving/sending message via MessageForm/MessageFormModal
    // form validation (not including file)
    const valid = innerApiSubmitFormMethod(
      dispatch,
      AddMessageSubmitActions,
      (s: ApplicationState) => s.adminEvents.messageCenter.form,
      undefined,
      undefined,
      true,
      true,
      undefined,
      undefined,
    );
    
    if (valid) {
      dispatch(new ClearError());

      if (!state.adminEvents.messageCenter.form.ActiveForm.Message) {
        dispatch(new ShowTopFloatingAlert('Message required', isModalOpened(ModalTypes.MESSAGE_FORM) || isMobileAndSmallerScreenSize(), 'orange'));
        return;
      }
      if (beforeSend) {
        // valid and push send message modal
        dispatch(appActionCreators.pushModal(ModalTypes.SEND_MESSAGE, false, true, {messageSubject: state.adminEvents.messageCenter.form.ActiveForm.Name, id: state.adminEvents.messageCenter.form.ActiveForm.ID}) as any);
        return;
      } else {
        // use afterSend to build request
        const body = formCreator(getState(), !!afterSend);

        if (afterSend) {
          dispatch(new ResetAdminEventsRegisteredGroup());
          dispatch(new ResetSendMessageModal());
        }

        const isMessageFormOpened = isModalOpened(ModalTypes.MESSAGE_FORM);
        if (!isMessageFormOpened) {
          if (state.adminEvents.cacheOne.EventsEventType) {
            navPush(router, getAdminEventsMessageCenterHomeRootUrl({
              eventTypeId: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID,
              eventTypeName: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.Name,
            }));
          }
        } else {
          dispatch(appActionCreators.popModal(false, false, ModalTypes.MESSAGE_FORM) as any);
          dispatch(new ToggleModalSaving(true));
        }
        dispatch(AddMessageSubmitActions.request(
          body,
          getCacheLevelExtra(true, !!getState().adminEvents.cacheTwoMessage.Message),
          route,
        ));

        // If in MessageForm modal, we clear cache for the edit case here, in order to
        // trigger save point during save.
        // Notice we dont do this for SendMessage form, because when submitting SendMessageForm,
        // it will close the message sidebar which will clear cache on its unmount
        if (isMessageFormOpened && !!getState().adminEvents.cacheTwoMessage.Message) {
          dispatch(new ClearAdminEventsCacheTwoMessage());
        }
      }
    } else {
      scrollSideModalToTop(AdminEventMessageFormNamespace());
    }
  },
  deleteAdminEventMessage: (messageId: number, router: any, routes: PlainRoute<any>[], inactive: boolean): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const route = routes[routes.length - 1];
    if (!state.adminEvents.cacheOne.EventsEventType) return;

    dispatch(new SaveState());

    const body = getDeleteAdminEventMessageBody({
      ID: messageId,
      Inactive: inactive,
    });
    navPush(router, getAdminEventsMessageCenterHomeRootUrl({
      eventTypeId: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID,
      eventTypeName: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.Name,
    }));
    dispatch(DeleteMessageSubmitActions.request(
      body,
      getCacheLevelExtra(true, !!state.adminEvents.cacheTwoMessage.Message),
      route,
    ));
  },
};