import { getResetPasswordSearch, getResetPasswordUpdate, getResetPasswordUpdateGroupSendResetLink } from "../../constants/resetPasswordUrls";
import { ResetPasswordUpdateGroupSendResetLinkSubmitActions } from "../Settings/Profile/actions";
import { makeRequestEpic } from "../Validation/epicCreator";
import { ResetPasswordSearchSubmitActions, ResetPasswordUpdateSubmitActions } from "./actions";

export const resetPasswordSearchEpic = makeRequestEpic(
  ResetPasswordSearchSubmitActions,
  getResetPasswordSearch,
);

export const resetPasswordUpdateEpic = makeRequestEpic(
  ResetPasswordUpdateSubmitActions,
  getResetPasswordUpdate,
  undefined,
  undefined,
  "Password has been changed",
);

export const resetPasswordUpdateGroupSendResetLinkEpic = makeRequestEpic(
  ResetPasswordUpdateGroupSendResetLinkSubmitActions,
  getResetPasswordUpdateGroupSendResetLink,
  undefined,
  undefined,
  "Password reset link sent",
);