
import * as React from 'react';

import { Row, SimpleListFilter } from '../../../../../Elements';
import { namespace } from '..';
import { ApplicationState } from '../../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../../../../../store/Admin/Modals/Accounts/actions";
import {actionCreators as appActionCreators} from '../../../../../../store/App/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../store/Rollback/actions';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { SelectValidator } from '../../../../../../utils/validator/models';

interface Props {

}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class TripsFilters extends React.PureComponent<Props, {}> {
  public props: ConnectedProps & Props;

  onChangeLocation = (value: number, vObj: SelectValidator) => {
    this.props.actions.updateValue(value, vObj);
    if (this.props.adminAccounts.ActiveForm.TripsLocationID !== undefined) this.props.actions.getTrips(value, undefined, true);
  };

  onChangeYearFilter = (value: number, vObj: SelectValidator) => {
    const {actions: { updateValue, getTrips }} = this.props;

    updateValue(value, vObj);
    if (this.props.adminAccounts.ActiveForm.TripsYear !== undefined) getTrips(undefined, value, true);
  };

  render() {
    const { adminAccounts: { ActiveForm, ValidationRules } } = this.props;
    return (
      <Row className={`${namespace()}--filter-row shadow`}>
        <SimpleListFilter
          label="Location"
          className={`${namespace()}--location-filter trips`}
          onChange={this.onChangeLocation}
          value={ActiveForm.TripsLocationID}
          validationRules={ValidationRules.TripsLocationID}
        />
        <SimpleListFilter
          className={`${namespace()}--year-filter trips`}
          label="Year"
          onChange={this.onChangeYearFilter}
          value={ActiveForm.TripsYear}
          validationRules={ValidationRules.TripsYear}
        />
      </Row>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    adminAccounts: state.adminAccounts,
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
  ...rollbackActionCreators}, dispatch) });

const ConnectedTripsFilters = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(TripsFilters);

export default ConnectedTripsFilters;