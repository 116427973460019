import { ActionCreator, ApplicationState } from '../../../../../';
import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod
} from '../../../../../Validation/actionCreator';
import {EventRegisterNumbersSelectPage, EventRegisterNumbersSetPendingPage} from "../Main/actions";
import { isAdmin } from '../../../../../../utils/permissionHelper';
import { PostEventRegistrationClass } from '../../../../../../models/api/cacheFourEvents';
import { composeCampsiteAssignments, composeClassRegistrations, composeNumbersProposed, composeOrderedProducts } from '../../../../../CacheFourEventsNumbers/actions';
import { eventNumbersRecalcBody } from '../../../../../../constants/urls';
import { captureTentarooErrorAndGetRequestPayload, isNumberFieldDirty, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../../../utils/dataHelper';
import { validateAllNumbersPages } from '../../../../../CacheFourEventsNumbers/validationMaster';
import { typeName, Action } from '../../../../../../utils/StrongActions';
import { SaveState } from '../../../../../Rollback/actions';

export const SUFFIX = '__NUMBERS_WIZARD_CAMPSITE';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ApiSubmitActionsNumbersCampsite = createApiSubmitActions(SUFFIX, true);

export const CampsiteValidateActions = createValidateActions(SUFFIX + '__CAMPSITE');
export const CampsiteApiValidateActions = createApiValidateActions(SUFFIX + '__CAMPSITE');

@typeName(`CLEAR_ERROR${SUFFIX}`) export class EventRegisterNumbersResetCampsiteError extends Action {}

@typeName(`ADD_CAMPSITE_ASSIGNMENT${SUFFIX}`) export class AddCampsiteAssignment extends Action {}

type CampsiteIdValidationError = {
  campsiteTempID: number;
  message: string;
};
@typeName(`CAMPSITE_ID_CLIENT_FAILURE`)
export class CampsiteIdClientFailure extends Action {
  constructor(public errors: CampsiteIdValidationError[]) { super(); }
}

export type Actions = typeof actionCreators;

const isCampsiteAssignmentsDirty = (state: ApplicationState) => {
  let isDirty = false;
  const c4 = state.cacheFourEventsNumbers;
  const EventRegistrationCampsiteAssignments = c4.EventRegistrationCampsiteAssignments;
  const campsiteState = state.events.event.register.numbers.campsite;

  campsiteState.EventRegistrationCampsiteAssignments.forEach((assignment) => {
    const matchingAssignment = EventRegistrationCampsiteAssignments ? EventRegistrationCampsiteAssignments.find((_assignment) => !!assignment.ActiveForm.ID && !!_assignment.ID && assignment.ActiveForm.ID === _assignment.ID) : null;

    // not saved previously
    if (!matchingAssignment) {
      // if active and not blank, then mark it as dirty
      if (!assignment.ActiveForm.Inactive && !!assignment.ActiveForm.CampsiteIDi) {
        isDirty = true;
      }
    } else {
      const InactiveChanged = (matchingAssignment.Inactive !== assignment.ActiveForm.Inactive) || !assignment.ActiveForm.CampsiteIDi;

      if (InactiveChanged || isNumberFieldDirty(matchingAssignment.NumAdults, assignment.ActiveForm.NumAdults) || isNumberFieldDirty(matchingAssignment.NumYouth, assignment.ActiveForm.NumYouth) || matchingAssignment.CampsiteIDi !== assignment.ActiveForm.CampsiteIDi) {
        isDirty = true;
      }
    }
  });

  return isDirty;
};

const formCreator = (rootState: ApplicationState) => {
  const options = rootState.cacheZero.options;
  const eventTypeID = rootState.cacheTwoEvents.eventTypeID;
  const eventID = rootState.cacheThreeEvents.eventID;
  const c3 = rootState.cacheThreeEvents;
  const c4 = rootState.cacheFourEventsNumbers;
  
  const classRegistrations: Array<PostEventRegistrationClass> = composeClassRegistrations(c4.EventClassesGroupRegistered);
  const products = composeOrderedProducts(c4.EventProductsOrdered, c4.EventProductsOrdered_Updates);
  const numbersProposed = composeNumbersProposed(rootState);
  const CampsiteAssignments = composeCampsiteAssignments(rootState);

  let debugPayload: TentarooDebugPayload | undefined;
  if (!options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when submitting Register Numbers Campsite form");
  }
  if (!options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when submitting Register Numbers Campsite form");
  }
  if (!c4.EventRegistrationNumbers) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheFourEventsNumbers.EventRegistrationNumbers not available when submitting Register Numbers Campsite form");
  }

  const payload = {
    ...eventNumbersRecalcBody(
      {
        GroupIDi: options?.Group?.IDi as any,
        GroupTS: options?.Group?.TS as any,
        EventTypeID: eventTypeID,
        EventIDi: eventID,
        GroupWeekIDi: c4.EventRegistrationNumbers?.IDi  as any,
        NumbersProposed: numbersProposed,
        ClassRegistrations: classRegistrations,
        ProductOrders: products,
        CampsiteAssignments,
        CampsiteDepositAmount: rootState.events.event.register.numbers.spots.ActiveForm.CampsiteDepositAmount,
        ParticipantTypes: !c3.EventInfoParticipantTypes ? [] : c3.EventInfoParticipantTypes.map((pType) => pType.ID),
      }
    ),
  };

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.events.event.register.numbers.campsite),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  campsiteSubmit: (page: string, isNext: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());

    const error = validateAllNumbersPages();
    
    // `error.campsite` contains validation error (strings) from main form and all sub-forms
    if (!error.campsite) {
      // Only submit request if next
      if (isNext && isAdmin()) {
        // Check if fields are dirty
        const isDirty = isCampsiteAssignmentsDirty(getState());
        
        if (!isDirty) {
          dispatch(new EventRegisterNumbersResetCampsiteError());
          dispatch(new EventRegisterNumbersSelectPage(page as 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'confirmation'));
        } else {
          const form = formCreator(getState());
          dispatch(ApiSubmitActionsNumbersCampsite.request(form, null));
          dispatch(new EventRegisterNumbersSetPendingPage(page as 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'confirmation'));
        }
      } else {
        dispatch(new EventRegisterNumbersResetCampsiteError());
        dispatch(new EventRegisterNumbersSelectPage(page as 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'confirmation'));
      }
    } else {
      // NOTE: If campsiteID has error, dispatch action to ensure inline error
      if (error.campsiteIDValidationResults) {
        dispatch(new CampsiteIdClientFailure(error.campsiteIDValidationResults));
      }

      // Dispatch action to show error & inline alert for the entire form
      dispatch(ApiSubmitActionsNumbersCampsite.clientFailure(error.campsite, false));
      if (!isNext) {
        // only allow navigation if back in invalid case
        dispatch(new EventRegisterNumbersSelectPage(page as 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'confirmation'));
      }
    }
  },
  updateCampsiteValue: createUpdateValueMethod(
    CampsiteValidateActions,
    CampsiteApiValidateActions,
    (s: ApplicationState) => s.events.event.register.numbers.campsite.EventRegistrationCampsiteAssignments,
    undefined,
    undefined,
    undefined,
    undefined,
    ValidateActions,
  ),
  simpleUpdateCampsite: createSimpleUpdateValueMethod(CampsiteValidateActions),
  addCampsiteAssignment: (): ActionCreator => dispatch => dispatch(new AddCampsiteAssignment()),
};
