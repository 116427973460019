import * as React from 'react';

import { Switch } from '../../../../../Elements';
import { Filter, FilterRow, FilterSearch, FilterColumn } from '../../../../../../components/Layouts';
import { Validator } from '../../../../../../utils/validator/models';

export const namespace = (): string => 'elements--resource-category-filters';

export interface ResourceCategoryFiltersProps {
  className?: string;
  disabled?: boolean;
  ActiveForm: any;
  ValidationRules: any;
  expand?: boolean;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
  };
  newButton: React.ReactNode;
}

class ResourceCategoryFilters extends React.Component<ResourceCategoryFiltersProps, {}> {
  public static defaultProps = {

  };
  public props: ResourceCategoryFiltersProps;

  public render() {
    const props = this.props;
    const {ActiveForm, ValidationRules, reduxActions, disabled, newButton} = props;

    return (
      <Filter className={namespace()}>
        <FilterRow mobileMarginBottom={2} style={{height: '68px'}}>
          <FilterSearch
            disabled={disabled}
            ActiveForm={ActiveForm}
            searchKey='ResourceCategoryFilterText'
            ValidationRules={ValidationRules}
            reduxActions={reduxActions}
            mobileMarginRight={0}
            mobileMarginBottom={0}
            align='center'
            width='228'
          />
          <FilterColumn
            lowerOrderInMobile
            width='half'
            marginRight={24}
            mobileMarginRight={24}
          >
            <Switch
              label="Show Deleted"
              newDesign
              onChange={reduxActions.updateValue}
              value={!!ActiveForm.ResourceCategoryShowDeleted}
              validationRules={ValidationRules.ResourceCategoryShowDeleted}
            />
          </FilterColumn>
          <FilterColumn alignSelf='flex-end' marginBottom={21} hideInMobile width='none'>
            {newButton}
          </FilterColumn>
        </FilterRow>
      </Filter>
    );
  }
}

export default ResourceCategoryFilters;