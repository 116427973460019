import type {CacheFourEventsNumbersState} from "../../store/CacheFourEventsNumbers";
import type {CacheFourEventsParticipantsState} from "../../store/CacheFourEventsParticipants";
import type {CacheFourEventsProductsState} from "../../store/CacheFourEventsProducts";
import type {CacheFourEventsViewParticipantState} from "../../store/CacheFourEventsViewParticipant";
import type {CacheFourFacilitiesState} from "../../store/CacheFourFacilities";
import type {CacheOneState} from "../../store/CacheOne";
import type {CacheThreeEventsState} from "../../store/CacheThreeEvents";
import type {CacheThreeFacilitiesState} from "../../store/CacheThreeFacilities";
import type {CacheThreePrevOrderState} from "../../store/CacheThreePrevOrders";
import type {CacheThreeRosterState} from "../../store/CacheThreeRoster";
import type {CacheTwoBFacilitiesState} from "../../store/CacheTwoBFacilities";
import type {CacheTwoEventsState} from "../../store/CacheTwoEvents";
import type {CacheTwoFacilitiesState} from "../../store/CacheTwoFacilities";
import type {CacheTwoPrevOrderState} from "../../store/CacheTwoPrevOrders";
import type {CacheTwoRosterState} from "../../store/CacheTwoRoster";
import type {CacheZeroState} from "../../store/CacheZero";
import {reduxStoreService} from "../../store/service";
import {isCacheLevelPopulated} from "./common";

const MIN_REQUIRED_FIELDS_CACHE_ZERO = 5;
const MIN_REQUIRED_FIELDS_CACHE_ONE = 0;

const MIN_REQUIRED_FIELDS_CACHE_TWO_ROSTER = 1;
const MIN_REQUIRED_FIELDS_CACHE_TWO_FACILITIES = 1;
const MIN_REQUIRED_FIELDS_CACHE_TWO_BFACILITIES = 2;
const MIN_REQUIRED_FIELDS_CACHE_TWO_EVENTS = 2;
const MIN_REQUIRED_FIELDS_CACHE_TWO_PREV_ORDERS = 1;

const MIN_REQUIRED_FIELDS_CACHE_THREE_PREV_ORDER = 1;
const MIN_REQUIRED_FIELDS_CACHE_THREE_ROSTER = 0;
const MIN_REQUIRED_FIELDS_CACHE_THREE_FACILITIES = 3;
const MIN_REQUIRED_FIELDS_CACHE_THREE_EVENTS = 1;

const MIN_REQUIRED_FIELDS_CACHE_FOUR_EVENTS_PRODUCTS = 3;
const MIN_REQUIRED_FIELDS_CACHE_FOUR_EVENTS_PARTICIPANTS = 2;
const MIN_REQUIRED_FIELDS_CACHE_FOUR_EVENTS_PARTICIPANT = 3;
const MIN_REQUIRED_FIELDS_CACHE_FOUR_EVENTS_NUMBERS = 5;
const MIN_REQUIRED_FIELDS_CACHE_FOUR_FACILITIES = 0;

// CacheZero
export function isCacheZeroPopulated(cacheZero: CacheZeroState) {
    // Following data could be loaded to `cacheZero.options` when just navigated to CreateAccount page:
    // - GroupTypes
    // - UnitTypes
    // - UnitGenders
    // - Councils
    // - Districts
    // Hence, to determine if cache zero is populated or not, we only say cache zero is loaded when
    // there are more than 5 fields under `cacheZero.options`. Otherwise, it should trigger a `GetOptions` request
    return !!cacheZero.options && Object.keys(cacheZero.options).length > MIN_REQUIRED_FIELDS_CACHE_ZERO;
}

// Cache One
export function isEndUserCacheOnePopulated(cacheOne: CacheOneState) {
    return Object.keys(cacheOne).length > MIN_REQUIRED_FIELDS_CACHE_ONE;
}

// Cache Two
export function isEndUserCacheTwoEventsPopulated(cacheTwoEvents: CacheTwoEventsState) {
  return isCacheLevelPopulated<CacheTwoEventsState>(
    cacheTwoEvents,
    MIN_REQUIRED_FIELDS_CACHE_TWO_EVENTS
  );
};

export function isEndUserCacheTwoFacilitiesPopulated(cacheTwoFacilities: CacheTwoFacilitiesState) {
  return isCacheLevelPopulated<CacheTwoFacilitiesState>(
    cacheTwoFacilities,
    MIN_REQUIRED_FIELDS_CACHE_TWO_FACILITIES,
  );
}

export function isCacheTwoBFacilitiesPopulated(cacheTwoBFacilities: CacheTwoBFacilitiesState){
  return isCacheLevelPopulated<CacheTwoBFacilitiesState>(cacheTwoBFacilities, MIN_REQUIRED_FIELDS_CACHE_TWO_BFACILITIES);
};

export function isCacheTwoRosterPopulated(cacheTwoRoster: CacheTwoRosterState) {
  return isCacheLevelPopulated<CacheTwoRosterState>(cacheTwoRoster, MIN_REQUIRED_FIELDS_CACHE_TWO_ROSTER);
};

export function isCacheTwoPrevOrdersPopulated(cacheTwoPrevOrders: CacheTwoPrevOrderState) {
  return isCacheLevelPopulated<CacheTwoPrevOrderState>(
    cacheTwoPrevOrders,
    MIN_REQUIRED_FIELDS_CACHE_TWO_PREV_ORDERS,
  );
};

export function isEndUserCacheTwoPopulated() {
  const rootState = reduxStoreService().getState();
  const { cacheTwoBFacilities, cacheTwoEvents, cacheTwoFacilities, cacheTwoRoster } = rootState;

  return (
    isCacheTwoBFacilitiesPopulated(cacheTwoBFacilities) ||
    isEndUserCacheTwoFacilitiesPopulated(cacheTwoFacilities) ||
    isEndUserCacheTwoEventsPopulated(cacheTwoEvents) ||
    isCacheTwoRosterPopulated(cacheTwoRoster)
  );
};

// Cache Three
export function isCacheThreeRosterPopulated(cacheThreeRoster: CacheThreeRosterState) {
  return isCacheLevelPopulated<CacheThreeRosterState>(cacheThreeRoster, MIN_REQUIRED_FIELDS_CACHE_THREE_ROSTER);
};

export function isCacheThreePrevOrdersPopulated(cacheThreePrevOrders: CacheThreePrevOrderState) {
  return isCacheLevelPopulated<CacheThreePrevOrderState>(
    cacheThreePrevOrders,
    MIN_REQUIRED_FIELDS_CACHE_THREE_PREV_ORDER,
    () => cacheThreePrevOrders.PreviousOrderItems !== undefined,
  );
};

export function isCacheThreeEventsPopulated(cacheThreeEvents: CacheThreeEventsState) {
  return isCacheLevelPopulated<CacheThreeEventsState>(
    cacheThreeEvents,
    MIN_REQUIRED_FIELDS_CACHE_THREE_EVENTS,
  );
};

export function isCacheThreeFacilitiesPopulated(cacheThreeFacilities: CacheThreeFacilitiesState) {
  return isCacheLevelPopulated<CacheThreeFacilitiesState>(cacheThreeFacilities, MIN_REQUIRED_FIELDS_CACHE_THREE_FACILITIES);
};

// Cache Four
export function isCacheFourEventsProductsPopulated(cacheFourEventsProducts: CacheFourEventsProductsState) {
  return isCacheLevelPopulated<CacheFourEventsProductsState>(
    cacheFourEventsProducts,
    MIN_REQUIRED_FIELDS_CACHE_FOUR_EVENTS_PRODUCTS,
  );
};

export function isCacheFourEventsParticipantsPopulated(cacheFourEventsParticipants: CacheFourEventsParticipantsState) {
  return isCacheLevelPopulated<CacheFourEventsParticipantsState>(
    cacheFourEventsParticipants,
    MIN_REQUIRED_FIELDS_CACHE_FOUR_EVENTS_PARTICIPANTS,
  );
};

export function isCacheFourFacilitiesPopulated(cacheFourFacilities: CacheFourFacilitiesState) {
  return isCacheLevelPopulated<CacheFourFacilitiesState>(
    cacheFourFacilities,
    MIN_REQUIRED_FIELDS_CACHE_FOUR_FACILITIES,
  );
};

export function isCacheFourEventsNumbersPopulated(cacheFourEventsNumbers: CacheFourEventsNumbersState) {
  return isCacheLevelPopulated<CacheFourEventsNumbersState>(
    cacheFourEventsNumbers,
    MIN_REQUIRED_FIELDS_CACHE_FOUR_EVENTS_NUMBERS,
  );
};

export function isCacheFourEventsViewParticipantPopulated(cacheFourEventsViewParticipant: CacheFourEventsViewParticipantState) {
  return isCacheLevelPopulated<CacheFourEventsViewParticipantState>(
    cacheFourEventsViewParticipant,
    MIN_REQUIRED_FIELDS_CACHE_FOUR_EVENTS_PARTICIPANT,
  );
};