import * as React from 'react';
import {default as AppHeader, Nav, NavItem} from '../../../App/Header';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import {getEventRegistrationUrl, getEventRootUrl, URLS} from "../../../../constants/urls";
import {spaceTo_} from "../../../../utils/stringHelper";
import {makeSelectedEventTypeSelector} from "../../../../store/CacheTwoEvents/index";
import {EventType} from "../../../../models/api/cacheOne";
import { generateDOMId } from '../../../../utils/cypressHelper';
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string}>;

export default (selected: 'info' | 'registration') => {
  const EventHeader = (props: ConnectedProps): React.ReactElement<ConnectedProps> => {
    const {params} = props;
    let name = 'event';
    if (props.selectedEventType) name = spaceTo_(props.selectedEventType.Name);
    else {
      if (props.cacheOne.EventEventTypes) {
        const eventType = props.cacheOne.EventEventTypes.find((e: EventType) => e.ID.toString() === params.eventTypeId);
        if (eventType) name = spaceTo_(eventType.Name);
      }
    }

    return <AppHeader
      showDrawer
      cart
      mobileCart
      refresh
      nav={(
      <Nav>
        <NavItem id={generateDOMId("event-info")} to={getEventRootUrl(props)} selected={selected === 'info'}>INFO</NavItem>
        <NavItem id={generateDOMId("event-registration")} to={getEventRegistrationUrl(props)} selected={selected === 'registration'}>REGISTRATION</NavItem>
      </Nav>
      )}
      back={`${URLS.EVENT_TYPES}/${params.eventTypeId}/${name}`}
    />;
  };
  const ConnectedEventHeader = connect(
    mapStateToProps,
    null,
    getMergeProps<WithRouterProps>(),
  )(EventHeader);

  return withRouter<{}>(ConnectedEventHeader);
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedEventTypeSelector = makeSelectedEventTypeSelector();

  return {
    selectedEventType: selectedEventTypeSelector(state),
    cacheOne: state.cacheOne
  };
};
