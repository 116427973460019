import * as React from 'react';
import {default as AppHeader} from '../../../../../App/Header';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { CMS_RESOURCES, getResourcesRootUrl } from '../../../../../../constants/urls';
import {
    actionCreators as cacheTwoResourcesActionCreators
} from '../../../../../../store/AdminCMSSite/CacheTwoResources/actions';
import { ApplicationState } from '../../../../../../store';
import { bindActionCreators } from 'redux';
import {
    actionCreators as rollbackActionCreators
} from '../../../../../../store/Rollback/actions';
import { extractRouteParams } from '../../../../../../utils/urlHelper';
import { getResourceIdFromPath, IAdminCMSResourceRouterParams } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminCMSResourceRouterParams, {}>;

const Header = (props: ConnectedProps): React.ReactElement<undefined> => {
  const sp = props.location.pathname.split('/');
  const isAdd = sp[sp.length - 1] === CMS_RESOURCES.NEW;
  const isEdit = sp[sp.length - 1] === CMS_RESOURCES.EDIT;
  
  const site = props.CMSSite;

  const customRefresh = () => {
    // always save state before refreshing during edit
    props.actions.saveState();
    isEdit && site && props.actions.refreshResource({
      ID: getResourceIdFromPath(props.params),
      ...extractRouteParams(props),
    }, true);
  };
  // fall back to root
  const backUrl = site ? getResourcesRootUrl({
    siteId: site.ID,
    domain: site.SiteDomain,
  }) : '/admin2';

  return <AppHeader
    showDrawer
    back={backUrl}
    disableBack={!site}
    title={isAdd ? 'New Resource' : 'Edit Resource'}
    subTitle={site ? site.SiteDomain : ''}
    refresh={!isAdd}
    customRefresh={customRefresh}
  />;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    CMSSite: state.adminCMSSite.cacheOne.CMSSite,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    cacheZero: state.cacheZero,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...cacheTwoResourcesActionCreators,
    ...rollbackActionCreators,
  }, dispatch),
});

const ConnectedHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(Header);

export default withRouter<{}>(ConnectedHeader);
