import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../../styles/layouts/main/side-bar/content.scss';

export const namespace = (): string => `${ns()}--content`;

export interface ContentProps {
  children?: React.ReactNode;
}

const Content: React.SFC<ContentProps> = ({ children }: ContentProps): React.ReactElement<ContentProps> => (
  <div className={namespace()}>
    {children}
  </div>
);

export default Content;
