import * as React from 'react';
import { DATE_PICKER_FORMAT } from '@tentaroo/shared';
import '../../../../styles/elements/admin-event/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton, Vignette, Checkbox} from '../../index';
import ContextMenuComponent from './ContextMenu';
import { OptionsIcon, CloseIcon } from '../../../../components/Icons';
import { CardCategory } from '../../Card';
import { namespace as cardNS } from "../../Card/constants";
import { isPathnameMatchingRoute } from '../../../../utils/urlHelper';
import { AdminEventsEvent } from '../../../../models/api/adminEventsCacheOne';
import moment from 'moment';
import { ADMIN_EVENT_DASHBOARD_PATH } from '../../../../routes';
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--admin-event--card';

export enum AdminEventCardType {
  EVENT_LIST='event-list',
  DASHBOARD='dashboard',
  IMPORT_FROM_MODAL_LIST='import-from-modal-list',
  IMPORT_FROM_MODAL_LIST_SELECTED='import-from-modal-list-selected',
  SEND_MESSAGE_MODAL_SELECTED='send-message-modal-selected',
} 

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin' | 'info' | 'mobile-full';
  type: AdminEventCardType;
  category: CardCategory;
  noBoxShadow?: boolean;
  selected?: boolean;
  event: AdminEventsEvent;
  onEdit?: (event: AdminEventsEvent) => void;
  onDelete?: (event: AdminEventsEvent) => void;
  onRestore?: (event: AdminEventsEvent) => void;
  onReports?: (event: AdminEventsEvent) => void;
  onDashboard?: (event: AdminEventsEvent) => void;
  onRemove?: (event: AdminEventsEvent) => void;
  onClick?: (event: AdminEventsEvent) => void;
  disabled?: boolean;
  routes?: any;
}


class AdminEventCard extends React.Component<Props> {
  onEditClick = (e) => {
    const {onEdit, disabled, event} = this.props;
    
    e.stopPropagation();
    !disabled && onEdit && onEdit(event);
  };
  onDeleteClick = (e) => {
    const {onDelete, event} = this.props;
    e.stopPropagation();
    !this.props.disabled && onDelete && onDelete(event);
  };
  onRestoreClick = (e) => {
    const {onRestore, event} = this.props;
    !this.props.disabled && e.stopPropagation();
    onRestore && onRestore(event);
  };

  onDashboardClick = (e) => {
    const {onDashboard, disabled, event} = this.props;
    e.stopPropagation();
    !disabled && onDashboard && onDashboard(event);
  };

  onReportsClick = (e) => {
    const {disabled, event, onReports} = this.props;
    e.stopPropagation();
    !disabled && onReports && onReports(event);
  };
  onRemoveClick= (e) => {
    const {disabled, event, onRemove} = this.props;
    e.stopPropagation();
    !disabled && onRemove && onRemove(event);
  };
  onCardClick = (e) => {
    const {type, event, onClick, disabled} = this.props;

    if (event.Inactive || disabled) return;

    switch (type) {
      case AdminEventCardType.EVENT_LIST:
      case AdminEventCardType.DASHBOARD:
        this.onDashboardClick(e);
        break;
      case AdminEventCardType.IMPORT_FROM_MODAL_LIST:
        onClick && onClick(event);
        break;
    }
  };

  renderContent = () => {
    const { event, category, disabled, type, onRestore } = this.props;
    return (
      <div className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <div className={`${namespace()}--main--text--title`}>{event.EventName}</div>
          <div className={`${namespace()}--main--text--subtitle`}>
            <div className={`${namespace()}--main--text--subtitle--text`}>{`Start: ${moment(event.StartDate).format(DATE_PICKER_FORMAT)}`}</div>
            {event.NumGroupsRegistered > 0 && type !== AdminEventCardType.DASHBOARD ? <div className={`${namespace()}--main--text--subtitle--text`}>{`${event.NumGroupsRegistered} Group${event.NumGroupsRegistered > 1 ? 's' : ''} Registered`}</div> : null}
          </div>
        </div>
        {(type === AdminEventCardType.EVENT_LIST || type === AdminEventCardType.DASHBOARD) && !event.Inactive && <ActionButton
          icon={OptionsIcon}
          className={`${namespace()}--options ${cardNS()}--options ${category}`}
          contextMenu={<ContextMenuComponent
            onDashboard={this.onDashboardClick}
            onEdit={this.onEditClick}
            onReports={this.onReportsClick}
            onDelete={this.onDeleteClick}
            />}
        />}
        {type === AdminEventCardType.SEND_MESSAGE_MODAL_SELECTED && !event.Inactive && 
        <ActionButton
          onClick={this.onRemoveClick}
          icon={CloseIcon}
          disabled={disabled}
          className={`${cardNS()}--close`}
        />}
        {(type === AdminEventCardType.EVENT_LIST || type === AdminEventCardType.DASHBOARD) && event.Inactive && onRestore && <Button className={`${cardNS()}--restore ${namespace()}--restore`} flat textColor="red" onClick={this.onRestoreClick}>RESTORE</Button>}
      </div>
    );
  };

  render() {
    const {category, noBoxShadow, template, event, type} = this.props;
    let className = attribute(namespace(), undefined, this.props);

    if (event.Inactive) className += ' deleted';
    className += ` ${type}`;

    const selected = type === AdminEventCardType.DASHBOARD && isPathnameMatchingRoute(location.pathname, ADMIN_EVENT_DASHBOARD_PATH, 'eventId', event.IDi);

    return (
      <Card
        noBoxShadow={noBoxShadow}
        className={className}
        selected={selected}
        component={<section/>}
        template={template || 'mobile-no-margin'}
        category={category}
        padding="none"
      >
        <div id={generateDOMId(`tentaroo-admin-event-${event.IDi}`)} className={`${namespace()}--wrapper`} onClick={this.onCardClick}>
          {this.renderContent()}
        </div>
      </Card>
    );
  }
}

export default AdminEventCard;