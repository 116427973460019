import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/sizes/index.scss';
// @todo this might be unused
export const namespace = (): string => 'elements--sizes';

export type SizesArray = Array<'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL'>;

export interface SizesProps {
  className?: string;
  sizes?: SizesArray;
}

const Sizes: React.SFC<SizesProps> = ({ sizes, ...props }: SizesProps): React.ReactElement<SizesProps> => {
  const defaultSizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];
  let allSizes = true;
  let sizesList: any = defaultSizes.map((size: string) => {
    if (sizes && sizes.indexOf(size as any) !== -1) return <li key={size} className={`${namespace()}--list--item green`}>{size}</li>;
    else {
      allSizes = false;
      return <li key={size} className={`${namespace()}--list--item`}>{size}</li>;
    }
  });

  if (allSizes) sizesList = <li className={`${namespace()}--list--item green`}>Universal</li>;

  return (
    <div className={mergeClassNames(namespace(), props)}>
      <h4 className={`${namespace()}--title`}>Sizes:</h4>
      <ul className={`${namespace()}--list`}>
        {sizesList}
      </ul>
    </div>
  );
};

export default Sizes;
