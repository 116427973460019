import * as React from 'react';
import EventFilters from './EventFilters';
import { Button, EmptyMessage, AdminEventCard } from '../../../../../components/Elements';
import '../../../../../styles/pages/admin-events/events/home/index.scss';
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators as eventsHomeActionCreators } from '../../../../../store/AdminEvents/Events/Home/actions';
import { actionCreators as eventFormActionCreators } from '../../../../../store/AdminEvents/Events/Event/Form/actions';
import { makeActiveEventsSelector, makeFilteredEventsSelector } from '../../../../../store/AdminEvents/Events/Home';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { ENTITY_NOT_ADDED, ENTITY_NOT_FOUND } from '../../../../../constants/messages/adminCMS';
import { CardCategory } from '../../../../../components/Elements/Card';
import { EventsIcon } from '../../../../../components/Icons';
import { RouteComponentProps, withRouter, WithRouterProps } from 'react-router';
import { navPush } from '../../../../../utils';
import { EmptyMessageType } from '../../../../../components/Elements/EmptyMessage';
import { AdminEventsEvent } from '../../../../../models/api/adminEventsCacheOne';
import { AdminEventCardType } from '../../../../Elements/AdminEvent/Card';
import { ADMIN_EVENTS } from '../../../../../constants/messages/adminEvents';
import moment from 'moment';
import { getAdminEventsEventDashboardUrl, constructAdminEventsEventUrlParams, getAdminEventsEventSettingsUrl } from '../../../../../constants/adminEventsUrls';
import { checkAdminEventPermission } from '../../../../../utils/helpers/adminEventsPageHelper';
import { ModalTypes, isModalOpened } from '../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';

export const namespace = (): string => 'pages--admin-events--events';

interface Props {
  showFilters?: boolean;
  forceMobile?: boolean;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

interface State {

}


class EventList extends React.Component<Props, State> {
  public props: Props & ConnectedProps;

  onNewEventClick = () => {
    const {actions, adminEventsCacheOne} = this.props;

    checkAdminEventPermission(
      () => {
        actions.pushEventFormModal();
      },
      adminEventsCacheOne,
    );
  };
  onDeleteClick = (event: AdminEventsEvent) => {
    const {eventFormActions, adminEventsCacheOne, routes, router} = this.props;

    checkAdminEventPermission(
      () => {
        eventFormActions.deleteEvent(event.IDi, true, routes, router);
      },
      adminEventsCacheOne,
      true,
    );
  };
  onEditClick = (event: AdminEventsEvent) => {
    const url = getAdminEventsEventSettingsUrl(constructAdminEventsEventUrlParams(this.props, event.IDi, event.EventName));

    navPush(this.props.router, url);
  };
  onRestoreClick = (event: AdminEventsEvent) => {
    const {adminEventsCacheOne, eventFormActions, routes, router} = this.props;

    checkAdminEventPermission(
      () => {
        eventFormActions.deleteEvent(event.IDi, false, routes, router);
      },
      adminEventsCacheOne,
      true,
    );
  };
  onDashboardClick = (event: AdminEventsEvent) => {
    const url = getAdminEventsEventDashboardUrl(constructAdminEventsEventUrlParams(this.props, event.IDi, event.EventName));

    navPush(this.props.router, url);
  };
  onReportsClick = (event: AdminEventsEvent) => {
    const url = getAdminEventsEventDashboardUrl(constructAdminEventsEventUrlParams(this.props, event.IDi, event.EventName));

    navPush(this.props.router, url);
  };

  componentDidMount() {
  }

  renderEvents = () => {
    const {routes, adminEventsCacheOne, forceMobile, groupByYears, filteredEvents} = this.props;
    const render: any[] = [];

    filteredEvents.reduce((acc: any, curr) => {
      const accYear = acc && moment(acc.StartDate).year();
      const currYear = moment(curr.StartDate).year();
      if (groupByYears && (!acc || accYear !== currYear)) {
        render.push(
          <div key={`event_year_${currYear}`} className={`${namespace()}--group-year`}>
            {currYear}
          </div>
        );
      }
      render.push(
        <AdminEventCard
          key={`event${curr.IDi}`}
          type={forceMobile ? AdminEventCardType.DASHBOARD : AdminEventCardType.EVENT_LIST}
          event={curr}
          category={CardCategory.LIST}
          onRestore={this.onRestoreClick}
          onEdit={this.onEditClick}
          onDelete={this.onDeleteClick}
          onDashboard={this.onDashboardClick}
          onReports={this.onReportsClick}
        />
      );

      return curr;
    }, null);

    return (
      <div className={`${namespace()}--list`}>
        {render}
      </div>
    );
  };

  renderEmptyMessage = (emptyMessage: string) => {
    const {forceMobile} = this.props;
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        admin
        noMobilePadding
        icon={EventsIcon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={emptyMessage}
        actions={forceMobile ? undefined : <Button disabled={isModalOpened(ModalTypes.EVENT_FORM)} className={`${namespace()}--empty-message-btn`} color="green" onClick={this.onNewEventClick}>NEW EVENT</Button>}/>
    );
  };

  render() {
    const {showFilters, eventsHome, actions, adminEventsCacheOne, filteredEvents, activeEvents, forceMobile} = this.props;

    let emptyMessage;
    if (activeEvents.length === 0 && filteredEvents.length === 0) {
      emptyMessage = ENTITY_NOT_ADDED(ADMIN_EVENTS);
    } else if (filteredEvents.length === 0) {
      emptyMessage = ENTITY_NOT_FOUND(ADMIN_EVENTS);
    }

    return (
      <div>
        {showFilters ? <div className={`${namespace()}--list-actions`}>
          <EventFilters
            ActiveForm={eventsHome.ActiveForm}
            ValidationRules={eventsHome.ValidationRules}
            reduxActions={actions}
            expand={eventsHome.expandFilter}
            forceMobile={forceMobile}
            newButton={
              <Button id={generateDOMId("admin-events-new-event-btn")} disabled={isModalOpened(ModalTypes.EVENT_FORM)} className='control' color='green' textColor='white' onClick={this.onNewEventClick}>New</Button>
            }
          />
        </div> : null}
        {emptyMessage ? this.renderEmptyMessage(emptyMessage) : null}
        {!emptyMessage ? this.renderEvents() : null}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const activeEventsSelector = makeActiveEventsSelector();
  const filteredEventsSelector = makeFilteredEventsSelector();
  const result = filteredEventsSelector(state);

  return {
    adminEventsCacheOne: state.adminEvents.cacheOne,
    eventsHome: state.adminEvents.events.home,
    groupByYears: result.groupByYears,
    activeEvents: activeEventsSelector(state),
    filteredEvents: result.events,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...eventsHomeActionCreators,
  }, dispatch),
  eventFormActions: bindActionCreators({
    ...eventFormActionCreators,
  }, dispatch),
});

const ConnectedEventList = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(EventList);

export default withRouter<Props>(ConnectedEventList);