import React from "react";
import '../../../styles/elements/virtual-list/wrapper.scss';

const namespace = () => "elements--virtual-list-wrapper";

type Props = {
    /**
     * Use this to set ref for the scroll element
     */
    forwardRef: (ref: HTMLElement | null) => void;
    grid?: boolean;
}

/**
 * In order to make our VirtualList (implemented by using `react-virtualized`) work as expected,
 * we need to guarantee the following:
 * 1. the scroll element (usually the list wrapper) does not overflow
 * 2. the scroll element should always present
 * 
 * We introduce this wrapper component to help guarantee the above two points:
 * 1. Styles defined in `wrapper.scss` makes sure this wrapper does not overflow and its flex-direction is column
 *     - Should use this in all modals because `<ModalContent>` component overflows
 *     - For `grid` we might have other styles, will finish that when using `VirtualList` for grids
 * 2. This element should always be presented even when the `VirtualList` itself is not, a typical example
 *    is a list with serach filter (e.x. SelectMeritBadges), when all results are filtered out, we should unmount
 *    the VirtualList component and keep this one
 */
function VirtualListWrapper(props: React.PropsWithChildren<Props>) {
    const {grid, forwardRef, children} = props;
    return (
        <div ref={forwardRef} className={`${namespace()}${grid ? ' grid' : ''}`}>
            {children}
        </div>
    );
}

export default VirtualListWrapper;