import * as React from 'react';

import Accordion from "../Accordion";
import ContactCard from "../ContactCard";
import Media from "../Media";
import Map from "../Map";

import '../../../styles/elements/show-contacts/index.scss';
import {setSentryContext} from "../../../config/sentry";
import { captureTentarooError } from '../../../utils/dataHelper';

interface Props extends React.ClassAttributes<any> {
  showMapMobile: boolean;
  onShowMapMobile: () => void;
  title: string;
  fullAddress: string;
  children?: React.ReactNode;
  show?: boolean;
  onClick?: (show: boolean) => void;
}

export const namespace = 'elements--show-contacts';

class ShowContacts extends React.Component<Props, {}> {
  componentDidCatch(error: Error, errorInfo) {
    setSentryContext();
    error.message += ' Error occurred in component containing map - ShowContacts';
    captureTentarooError(error);
    throw error;
  };

  render () {
    const {show, onClick, children, showMapMobile, onShowMapMobile, title, fullAddress} = this.props;
    return (
      <Media newApi mobile className={namespace}>
        <Accordion name={show ? 'HIDE CONTACTS' : 'SHOW CONTACTS'} open={show} onClick={onClick}>
          <ContactCard
            contacts={false}
            mapIsSelected={showMapMobile}
            onMapClick={onShowMapMobile}
            title={title}
            fullAddress={fullAddress}
          />
          {showMapMobile ? <Map width="100%" height="240px" marginBottom={10} address={fullAddress}/> : null}
          {children}
        </Accordion>
      </Media>
    );
  }
};

export default ShowContacts;
