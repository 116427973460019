import * as React from 'react';
import { Card, CardPadding, CardTitle, Column, Image, View, Text, Days, Vignette, Row } from '../../../../../Elements';
import '../../../../../../styles/pages/facilities/trip/reservation/type/facility.scss';
import {
  FacilitiesAvailabilitiesDate, FacilitiesAvailability,
  FacilityLocation_FacilityType
} from "../../../../../../models/api/cacheTwoFacilties";
import {getLocationPricingInfo} from "../../../../../../utils/helpers/facilityHelper";
import {getImagePath} from "../../../../../../utils";
import Moment from 'moment';
import * as Range from 'moment-range';
import {FacilityTrip} from "../../../../../../models/api/cacheThreeFacilities";
import { ArrowIcon } from '../../../../../Icons';
const moment = (Range as any).extendMoment(Moment);

const namespace = (): string => 'pages--facilities--trip--reservation--type--facility';

interface Props {
  trip: FacilityTrip;
  avail: FacilitiesAvailability;
  type: FacilityLocation_FacilityType;
  availabilities: Array<FacilitiesAvailabilitiesDate>;
  onClick: (avail: FacilitiesAvailability, trip: FacilityTrip, type: FacilityLocation_FacilityType) => void;
}

// TODO: Can we combine this with `ClientApp/components/Elements/Facility/Card/index.tsx`??
class Facility extends React.Component<Props,{}> {
  props: Props;

  onClick = () => {
    const {avail, trip, type, onClick} = this.props;
    onClick(avail, trip, type);
  };

  render() {
    const { trip, avail, type, availabilities } = this.props;
    const info = getLocationPricingInfo(avail, type.NameSingular);
    const range = moment.range(trip.StartDateTime, trip.EndDateTime);

    return (
      <Card onClick={this.onClick} padding="none" template="mobile-full" className={namespace()}>
        <Vignette
          className={`${namespace()}--image`}
          width={180}
          minHeight={180}
          color={`#${avail.Color}`}
          name={avail.Name}
          image={avail.Images ? getImagePath(avail.Images[0]) : undefined}
          skeleton={false}
          mobileFullWidth
          topLeftBorderRadius
          bottomLeftBorderRadius
        />

        <Column expand overflowHidden>
          <CardPadding layout="vertical" overflowHidden>
            <Row>
              <CardTitle className={`${namespace()}--title`} size={18} marginBottom={8}>{avail.Name}</CardTitle>
              <div className={`${namespace()}--arrow`}>
                <ArrowIcon />
              </div>
            </Row>
            {info.minPeople && info.perPersonRatePerDay && <View marginBottom={8} layout="vertical">
              {info.perPersonRatePerDay && <Text weight="bold" color="green">{info.perPersonRatePerDay}</Text>}
              {info.minPeople && <Text size={13} color="gray" marginBottom={0}>{info.minPeople}</Text>}
            </View>}
            {info.maxPeople && info.flatRatePerDay && <View marginBottom={8} layout="vertical">
              {info.flatRatePerDay && <Text weight="bold" color="green">{info.flatRatePerDay}</Text>}
              {info.maxPeople && <Text size={13} color="gray" marginBottom={0}>{info.maxPeople}</Text>}
            </View>}
            <Days avail={avail} range={range} availabilities={availabilities} small/>
          </CardPadding>
        </Column>
      </Card>
    );
  }
}

export default Facility;
