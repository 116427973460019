import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';
import { namespace as ns } from './constants';
import '../../../styles/elements/camp-info/left.scss';

export const namespace = (): string => `${ns()}--left`;

export interface LeftProps {
  children?: React.ReactNode;
  className?: string;
}

const Left: React.SFC<LeftProps> = (props: LeftProps): React.ReactElement<LeftProps> => (
  <div className={mergeClassNames(namespace(), props)}>
    {props.children}
  </div>
);

export default Left;
