import * as React from 'react';
import Button from '../Button';

export interface ActionProps {
  children?: React.ReactNode;
  onClick?: () => void;
}

const Action: React.SFC<ActionProps> = (props: ActionProps): React.ReactElement<ActionProps> => (
  <Button flat={true} onClick={props.onClick}>
    {props.children}
  </Button>
);

export default Action;
