import * as React from 'react';
import '../../../../styles/elements/resource-category/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, ActionButton} from '../../index';
import { EditIcon } from '../../../../components/Icons';
import Button from '../../Button';
import { SELECTED_ITEM_DELETED } from '../../../../constants/messages/generic';
import { CardCategory } from '../../Card';
import { namespace as cardNS } from "../../Card/constants";
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--resource-category--card';

export enum ResourceCategoryCardType {
  LIST='list',
  EDIT_RESOURCE='edit-resource',
}
interface Props extends MarginProps {
  id?: number,
  children?: React.ReactNode;
  name: string;
  template?: 'mobile-stretch' | 'mobile-no-margin';
  type: ResourceCategoryCardType;
  removeIcon: React.ReactNode;
  deleted?: boolean;
  showDeletedPrompt?: boolean;
  onEdit?: () => void;
  onRemove?: () => void;
  onRestore?: () => void;
  error?: string;
  disabled?: boolean;
  cardCategory?: CardCategory;
}

const ResourceCategoryCard: React.SFC<Props> = ({ children, id, name, template, onEdit, onRemove, removeIcon, deleted, onRestore, showDeletedPrompt, type, cardCategory, disabled, ...props }: Props): React.ReactElement<Props> => {
  let className = attribute(namespace(), undefined, props);

  const onEditClick = (e) => {
    e.stopPropagation();
    if (deleted) return;
    !disabled && onEdit && onEdit();
  };
  const onRemoveClick = (e) => {
    e.stopPropagation();
    !disabled && onRemove && onRemove();
  };
  const onRestoreClick = (e) => { 
    e.stopPropagation();
    !disabled && onRestore && onRestore();
  };

  if (deleted) className += ' deleted';
  if (showDeletedPrompt) className += ' show-deleted-prompt';
  className += ` ${type}`;

  return (
    <Card
      id={generateDOMId(`resource-category-card-${id}`)}
      className={className}
      component={<section/>}
      template={template}
      padding="none"
      category={cardCategory || CardCategory.LIST}
    >
      <div onClick={(e) => type === ResourceCategoryCardType.LIST ? onEditClick(e) : null} className={`${namespace()}--main`}>
        <label className={`${namespace()}--main--name`}>{name}</label>
        {!deleted && <ActionButton id={generateDOMId(`resource-category-${id}-edit-btn`)} disabled={disabled} onClick={onEditClick} icon={EditIcon} className={`${cardNS()}--edit ${disabled ? 'disabled' : ''}`} />}
        {(!deleted || (deleted && showDeletedPrompt)) && <ActionButton disabled={disabled} onClick={onRemoveClick} icon={removeIcon} className={`${cardNS()}--close ${cardCategory} ${disabled ? 'disabled' : ''}`} />}
        {deleted && onRestore && <Button disabled={disabled} className={`${cardNS()}--restore`} flat textColor="red" onClick={onRestoreClick}>RESTORE</Button>}
      </div>
      {deleted && showDeletedPrompt && <section className={`${namespace()}--deleted-prompt`}>{SELECTED_ITEM_DELETED}</section>}
    </Card>
  );
};

ResourceCategoryCard.defaultProps = {
  template: 'mobile-no-margin'
};

export default ResourceCategoryCard;