import * as React from 'react';

import { Form, Row, Column, FormActions, TextField, ModalSelect, FileUploader, Select, FieldSet, Loader, Alert } from '../../../../../Elements';
import { Button } from '../../../../../Elements';
import { Main, MainContent } from '../../../../../Layouts';
import { ResourceActiveForm } from '../../../../../../store/AdminCMSSite/Resources/Resource/Form';
import { IValidator } from '../../../../../../store/AdminCMSSite/Resources/Resource/Form/validation';
import ResourceCategoryCard, { ResourceCategoryCardType } from '../../../../../Elements/ResourceCategory/Card';
import { CMSResourceCategory, CMSResource } from '../../../../../../models/api/adminCMSCacheOne';
import { CloseIcon } from '../../../../../Icons';
import ResourceCard, { ResourceCardType } from '../../../../../Elements/Resource/Card';
import '../../../../../../styles/pages/resources/resource/index.scss';
import { getResourceUrl, copyStringToClipboard } from '../../../../../../utils';
import { AdminCMSSiteCacheOneState } from '../../../../../../store/AdminCMSSite/CacheOne';
import { isArray, isObject } from 'util';
import { stripFileExtension, validateFileType, VALID_RESOURCE_TYPES, MB, getValidResourceTypesString, checkFileSize } from '../../../../../../utils/fileHelper';
import * as M from '../../../../../../constants/messages/generic';
import { checkResourceCategoryPermission } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { CardCategory } from '../../../../../../components/Elements/Card';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { ModalProps, ModalTypes } from '../../../../../../utils/modalHelper';
import { Validator } from '../../../../../../utils/validator/models';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--resource-form';

export type Props = WithInertAttribute<{
  action: 'add' | 'edit';
  disabled: boolean;
  ActiveForm: ResourceActiveForm;
  ValidationRules: IValidator;
  adminCMSCacheOne: AdminCMSSiteCacheOneState;
  loading?: boolean;
  selectedResourceCategory?: CMSResourceCategory;
  resource?: CMSResource;
  isResourceFormModalOpened?: boolean;
  hideFormAction?: boolean;
  SubmitErrorMessage?: string;
  handleCompact?: boolean;
  onSave?: () => void;
  onDelete?: () => void;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
    pushModal: (modal: ModalTypes, saveBefore: boolean, saveAfter: boolean, props?: ModalProps) => any,
    showSnackbarItem(msg: string),
    showTopFloatingAlert(message: string, inModal?: boolean, color?: 'green' | 'orange'),
  };
}>;

class Resource extends React.Component<Props, {}> {
  public props: Props;

  onClickSelectButton = () => {
    this.props.reduxActions.pushModal(ModalTypes.SELECT_RESOURCE_CATEGORIES, false, true);
  };
  onClickEditSelectedResourceCategory = () => {
    const {selectedResourceCategory} = this.props;
    if (!selectedResourceCategory) return;

    checkResourceCategoryPermission(
      () => {
        this.props.reduxActions.pushModal(ModalTypes.RESOURCE_CATEGORY_FORM, false, true, {id: selectedResourceCategory.ID});
      },
      this.props.adminCMSCacheOne,
      true,
    );
  };
  onClickRemoveSelectedResourceCategory = () => {
    this.props.reduxActions.updateValue(undefined, this.props.ValidationRules.CategoryID);
  };
  onClickRemoveSelectedFile = () => {
    this.props.reduxActions.updateValue(undefined, this.props.ValidationRules.File);
  };
  onFileChange = (files: any) => {
    const file = isArray(files) && files.length > 0 ? files[0] : (isObject(files) ? files[0] : null);

    const isOversized = checkFileSize(
      file.name,
      file.size,
      18,
      (oversizeMessage) => {
        this.props.reduxActions.showTopFloatingAlert(oversizeMessage, this.props.isResourceFormModalOpened, 'orange');
      }
    );
    if (isOversized) {
      return;
    }

    const result = stripFileExtension(file.name);
    if (result) {
      const validType = validateFileType(result.extension, VALID_RESOURCE_TYPES);
      if (!validType) {
        this.props.reduxActions.showTopFloatingAlert(M.UNSUPPORTED_FILE_TYPES(file.name, (file.size / MB).toFixed(2)), this.props.isResourceFormModalOpened, 'orange');
        return;
      }
    }

    this.props.reduxActions.updateValue({
      Filename: file.name,
      inputObj: file,
    }, this.props.ValidationRules.File);
  };
  onFileClick = () => {
    if (!this.props.adminCMSCacheOne.CMSSite || !this.props.resource) return;
    const url = getResourceUrl(this.props.adminCMSCacheOne.CMSSite.SiteDomain as string, this.props.resource);
    window.open(url);
  };
  onCopyFileLink = () => {
    if (!this.props.adminCMSCacheOne || !this.props.adminCMSCacheOne.CMSSite || !this.props.resource) return;
    const url = getResourceUrl(this.props.adminCMSCacheOne.CMSSite.SiteDomain, this.props.resource);
    copyStringToClipboard(url);
    this.props.reduxActions.showSnackbarItem(M.LINK_COPIED);
  };

  public render() {
    const {onSave, onDelete, action, hideFormAction, disabled, reduxActions, ValidationRules, ActiveForm, loading, inert, selectedResourceCategory, SubmitErrorMessage, handleCompact} = this.props;
    return (
        <Main inert={inert}>
          <MainContent loading={loading} className={`${namespace()}--main-content`} handleCompact={handleCompact}>
            <Form
              onSubmit={e => e.preventDefault()}
              actions={
                hideFormAction ? null : (action === 'add' ? (
                  <FormActions><Button flat textColor="black" big onClick={onSave}>CREATE RESOURCE</Button></FormActions>
                ) : (
                  <FormActions>
                    <Row>
                      <Column expand>
                        <Button id={generateDOMId("admin-resource-save-btn")} color="white" flat textColor="black" big onClick={onSave}>SAVE CHANGES</Button>
                      </Column>
                      <Column>
                        <Button color="white" flat textColor="red" big onClick={onDelete}>DELETE</Button>
                      </Column>
                    </Row>
                  </FormActions>)
                )
              }
            >
              {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
              <Row>
                <Column span={12} mobileSpan={12}>
                  <TextField
                    id={generateDOMId("admin-resource-name-field")}
                    label="Name"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Name}
                    value={ActiveForm.Name}/>
                </Column>
              </Row>
              <Row>
                <Column span={12} mobileSpan={12}>
                  <ModalSelect
                    label="Category"
                    selectButtonLabel="SELECT CATEGORY"
                    paddingBottom={24}
                    mobilePaddingBottom={16}
                    disabled={disabled}
                    hideError={!!selectedResourceCategory}
                    selectedCard={selectedResourceCategory ?
                      <ResourceCategoryCard
                        type={ResourceCategoryCardType.EDIT_RESOURCE}
                        disabled={disabled}
                        showDeletedPrompt
                        deleted={selectedResourceCategory.Inactive}
                        removeIcon={CloseIcon}
                        onEdit={this.onClickEditSelectedResourceCategory}
                        onRemove={this.onClickRemoveSelectedResourceCategory}
                        marginTop={16}
                        cardCategory={CardCategory.LIST_MOBILE}
                        id={selectedResourceCategory.ID}
                        name={selectedResourceCategory.Name} /> : null}
                    validationRules={ValidationRules.CategoryID}
                    onClickSelectButton={this.onClickSelectButton}
                    value={ActiveForm.CategoryID}/>
                </Column>
              </Row>
              {action === 'edit' && <Row>
                <Column span={6} mobileSpan={12}>
                  <Select
                    label="Owner"
                    disabled={disabled}
                    hideOptional
                    onChangeValue={reduxActions.updateValue}
                    value={ActiveForm.SiteID}
                    validationRules={ValidationRules.SiteID}
                    isNumber />
                </Column>
              </Row>}
              <Row>
                <Column span={12} mobileSpan={12}>
                  <TextField
                    value={ActiveForm.Description}
                    label="Description"
                    rows={6}
                    onChange={reduxActions.updateValue}
                    onBlur={reduxActions.simpleUpdate}
                    validationRules={ValidationRules.Description}/>
                </Column>
              </Row>
              <Row>
                <Column span={12} mobileSpan={12}>
                  <FileUploader
                    title='File'
                    component={
                      <ResourceCard
                        cardCategory={CardCategory.LIST}
                        type={ActiveForm.File && ActiveForm.File.ID ? ResourceCardType.EDIT_RESOURCE : ResourceCardType.ADD_RESOURCE}
                        onCopyLink={() => this.onCopyFileLink()}
                        onDownload={() => ActiveForm.File && ActiveForm.File.filePath ? this.onFileClick() : null}
                        resource={{File: ActiveForm.File as any} as any}
                        onRemove={() => this.onClickRemoveSelectedFile()}
                      /> 
                    }
                    maxFileSize={18}
                    supportedFormatsPrompt='Supported formats: Archives (ZIP, 7ZIP), Documents (DOC, DOCX, RTF, PDF, TXT), Presentations (PPT, PPTX), Spreadsheets (XLS, XLSX, CSV), Web pages (HTM, HTML), Images (JPG, PNG, GIF, TIFF), Audio (MP3, M4A, WAV), Videos (AVI, MPG, MP4, MOV)'
                    supportedFormat={getValidResourceTypesString()}
                    value={ActiveForm.File}
                    onChange={this.onFileChange}
                    validationRules={ValidationRules.File}/>
                </Column>
              </Row>
            </Form>
          </MainContent>
        </Main>
    );
  }
}

export default Resource;
