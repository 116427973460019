import * as React from 'react';
import Card from "../Card";
import CardPadding from "../Card/Padding";
import Align from "../Grid/Align";
import Row from "../Grid/Row";
import Column from "../Grid/Column";
import Title from "../Title";
import Text from "../Text";
import CartIcon from "../CartIcon";
import Vignette from "../Vignette";
import MyEventWarning from "../MyEvent/Warning";
// @todo: MyEventWaring is kinda dumb, maybe remove and just use Text if it is good enough
import '../../../styles/elements/location/index.scss';

export const namespace = (): string => 'elements--location';

export interface LocationProps {
  image?: string;
  color?: string;
  initials?: string;
  onClick?: () => void;
  name: string;
  info: string;

  numTrips?: number;
  cart?: boolean;

  numEventsAvailable?: number;
  numEventsRegistered?: number;

  registrationStatus?: string;
  isPastTab?: boolean;
  id?: string;

  bottom?: any; // @todo: remove bottom
}

const getTripsText = (numTrips?: number) => {
  if (!numTrips) return null;
  let text = 'UPCOMING TRIP';
  if (numTrips === 1) text = `1 ${text}`;
  else text = `${numTrips} ${text}S`;
  return <Text size={14} color="green" weight="medium">{text}</Text>;
};

const getEventTypeText = (numEventsAvailable?: number, numEventsRegistered?: number, isPastTab?: boolean) => {
  // @todo: move this css (see the className is hardcoded to another class)
  if (numEventsAvailable && numEventsRegistered) {
    return (
      <Text className={`pages--events--event-types--location--bottom`}>
        <Text size={14} color="gray" inline className={`pages--events--event-types--location--bottom--available`}>
          {numEventsAvailable} EVENTS AVAILABLE
        </Text>
        <Text size={14} color="gray" inline className={`pages--events--event-types--location--bottom--comma`}>
          ,&nbsp;
        </Text>
        <Text size={14} color="green" inline className={`pages--events--event-types--location--bottom--registered`}>
          {numEventsRegistered} REGISTERED
        </Text>
      </Text>
    );
  } else if (numEventsAvailable) {
    return <Text className={`pages--events--event-types--location--bottom`} size={14} color="gray">{numEventsAvailable} EVENTS AVAILABLE</Text>;
  } else if (numEventsRegistered && !isPastTab) {
    return <Text className={`pages--events--event-types--location--bottom`} size={14} color="gray">{numEventsRegistered} EVENTS REGISTERED</Text>;
  } else if (numEventsRegistered && isPastTab) {
    return <Text className={`pages--events--event-types--location--bottom`} size={14} color="gray">{numEventsRegistered} PAST EVENTS REGISTERED</Text>;
  }
  return null;
};

const Location: React.SFC<LocationProps> = (
  { image, color, initials, name, info, numTrips, numEventsAvailable, numEventsRegistered, cart, id, bottom, registrationStatus, onClick, isPastTab }: LocationProps
): React.ReactElement<LocationProps> => {

  const eventTypeText = getEventTypeText(numEventsAvailable, numEventsRegistered, isPastTab);
  const tripsText = getTripsText(numTrips);

  return (
    <Card id={id} padding="none" onClick={onClick}>
      <Row wrap={false}>
        <Vignette
          width={100}
          mobileWidth={80}
          minHeight={100}
          color={color}
          name={initials}
          image={image}
          topLeftBorderRadius
          bottomLeftBorderRadius
          mobileTopLeftBorderRadius
          mobileBottomLeftBorderRadius
        />
        <CardPadding padding="small" className={`${namespace()}--content`} overflowHidden layout="vertical">
          <Row marginBottom={4}>
            <Column overflowHidden layout="vertical" marginRight={cart ? 28 : undefined}>
              <Title size={18} className={`${namespace()}--content--name`} marginBottom={4} importance={2}>
                {name}
              </Title>
              <Text size={14} color="gray" wrap={false}>
                {info}
              </Text>
            </Column>
          </Row>
          {registrationStatus && <Row><MyEventWarning marginTop={0} marginBottom={!!eventTypeText || !!tripsText || !!bottom ? 4 : 0}>{registrationStatus}</MyEventWarning></Row>}
          <Row>
            <Align end className={`${namespace()}--content--bottom`}>
              {tripsText}
              {eventTypeText}
              {bottom}
            </Align>
          </Row>
          {cart ? <CartIcon className={`${namespace()}--content--cart-icon`}/> : null}
        </CardPadding>
      </Row>
    </Card>
  );
};

export default Location;
