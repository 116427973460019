import * as React from 'react';
import { Form, Row, Column, FormActions, TextField, Alert, ActionButton, FieldSet, ModalSelect, Select, Link, Switch, RadioGroupOption, Text, MoneyField, FormSection } from '../../../../Elements';
import { Button } from '../../../../Elements';
import { Main, MainContent } from '../../../../Layouts';
import { isJustNames, isJustNumbers, isUsingNumbers, isUsingNames } from '../../../../../store/AdminEvents/EventTypes/EventType/Form/uiHelpers';
import { Actions as EventTypeFormActions, EventTypeFormSection, getAdminEventTypePricingTierKey, getAdminEventTypeParticipantTypeAttrKey, AdminEventTypeParticipantTypeKeys } from '../../../../../store/AdminEvents/EventTypes/EventType/Form/actions';
import { Actions as CacheOneActions } from '../../../../../store/AdminEvents/CacheOne/actions';
import { Actions as AppActions } from '../../../../../store/App/actions';
import '../../../../../styles/pages/admin-events/event-types/form/index.scss';
import * as M from '../../../../../constants/messages/generic';
import { EventTypeFormState, makeSelectedRegistrationMethodSelector, makeSelectedMessageSelector, makePricingTiersSelector, makeSelectedLocationSelector, makeSelectedEventCategory } from '../../../../../store/AdminEvents/EventTypes/EventType/Form';
import { AdminEventsOption, AdminEventsEmailMessage, ParticipantType, AdminEventsEventCategory } from '../../../../../models/api/adminEventsCacheOne';
import { CloseIcon } from '../../../../Icons';
import { CardCategory } from '../../../../Elements/Card';
import LocationCard, { LocationCardType } from '../../../../Elements/CMSLocation/Card';
import { Location } from '../../../../../models/api/adminCMSCacheOne';
import { openPricingTierForm, openSupportForm } from '../../../../../constants/urls';
import { DELETED_ADMIN_EVENT_TYPE } from '../../../../../constants/messages/adminEvents';
import AdminMessageCard, { AdminMessageCardType } from '../../../../Elements/AdminMessage/Card';
import { isMobileAndSmallerScreenSize } from '../../../../../utils/isMobile';
import { ApplicationState } from '../../../../../store';
import { checkAdminEventMessagePermission } from '../../../../../utils/helpers/adminEventsPageHelper';
import { checkLocationPermission } from '../../../../../utils/helpers/adminCMSPageHelper';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--event-type--form';

export type Props = WithInertAttribute<{
  action: 'add' | 'edit';
  formState: EventTypeFormState;
  loading?: boolean;
  onSave?: () => void;
  onDelete?: () => void;
  hideFormAction?: boolean;
  SubmitErrorMessage?: string;
  handleCompact?: boolean;
  formActions?: any;
  reduxActions: EventTypeFormActions & AppActions & CacheOneActions;
  showSection?: EventTypeFormSection;
  selectedEventCategoryInNew?: AdminEventsEventCategory;
  selectedLocationInNew?: Location;
  selectedRegistrationMethodInNew?: AdminEventsOption;
}>;

type ConnectedProps = ReturnType<typeof mapStateToProps>;

class EventTypeForm extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  isFormDisabled = () => {
    const {adminEventsCacheOne, action, apiSaving} = this.props;
    return apiSaving > 0 || (
      action === 'edit' &&
      (!adminEventsCacheOne.EventsEventType || adminEventsCacheOne.EventsEventType.EventTypeRow.Inactive || !adminEventsCacheOne.EventsEventType.Permissions.hasEditEventType)
    );
  };

  onClickSelectLocationButton = () => {
    const { reduxActions, action } = this.props;
    reduxActions.pushSelectLocationModal({context: action === 'add' ? 'admin-events-new-event-type' : 'admin-events-event-type', isFacilities: false});
  };

  onClickSelectMessageButton = () => {
    this.props.reduxActions.pushModal(ModalTypes.SELECT_MESSAGE, false, true);
  };

  onRemoveSelectedLocation = () => {
    this.props.reduxActions.updateEventTypeGeneralValue(undefined, this.props.formState.General.ValidationRules.LocationID);
  };

  onEditSelectedLocation = (location: Location) => {
    checkLocationPermission(
      () => {
        this.props.reduxActions.pushFormModal(ModalTypes.LOCATION_FORM, true, {id: location.ID, isFacilities: false});
      },
      this.props.cacheZero,
      location,
    );
  };

  getRegistrationMethodHelperText = () => {
    if (this.props.action === 'add') {
      return "Registration Method can't be changed after an Event Type is created. Please make sure you have selected the right method.";
    } else {
      return "To change this, you’ll need to create a new copy of this event type. This event type can be duplicated so that your other settings are preserved; however, events cannot be moved between event types.";
    }
  };

  onEditMessageClick = (message: AdminEventsEmailMessage) => {
    const {reduxActions, adminEventsCacheOne} = this.props;

    checkAdminEventMessagePermission(
      () => {
        reduxActions.pushFormModal(ModalTypes.MESSAGE_FORM, true, {id: message.ID});
      },
      adminEventsCacheOne,
      true,
    );
  };

  renderGeneralSection = () => {
    const {action, reduxActions, formState: { General: {ValidationRules: GeneralValidationRules, ActiveForm: GeneralActiveForm} }, adminEventsCacheOne, selectedEventCategoryInNew, selectedLocation, selectedLocationInNew, selectedEventCategory, selectedMessage} = this.props;

    let selectedLocationCard;

    if (action === 'add') {
      selectedLocationCard = selectedLocationInNew ? <LocationCard
        showDeletedPrompt
        disabled={this.isFormDisabled()}
        location={selectedLocationInNew}
        cardCategory={CardCategory.LIST}
        type={LocationCardType.MODAL_SELECT}
        onRemove={this.onRemoveSelectedLocation}
        onEdit={this.onEditSelectedLocation}
      /> : null;
    } else {
      selectedLocationCard = selectedLocation ? <LocationCard
        showDeletedPrompt={adminEventsCacheOne.EventsEventType && GeneralActiveForm.LocationID !== adminEventsCacheOne.EventsEventType.General.LocationID}
        disabled={this.isFormDisabled()}
        location={selectedLocation}
        cardCategory={CardCategory.LIST}
        type={LocationCardType.MODAL_SELECT}
        onRemove={this.onRemoveSelectedLocation}
        onEdit={this.onEditSelectedLocation}
        allowInactive={adminEventsCacheOne.EventsEventType && GeneralActiveForm.LocationID === adminEventsCacheOne.EventsEventType.General.LocationID}
      /> : null;
    }

    return (
      <section>
        <Row>
          <Column span={12}>
            <TextField
              label="Name"
              id={generateDOMId("admin-event-type-name-field")}
              disabled={this.isFormDisabled()}
              onChange={reduxActions.simpleUpdateEventTypeGeneral}
              onBlur={reduxActions.updateEventTypeGeneralValue}
              validationRules={GeneralValidationRules.Name}
              value={GeneralActiveForm.Name}
            />
          </Column>
        </Row>
        <Row marginTop={8} mobileMarginTop={8}>
          <Column span={12}>
            <TextField
              label="Description"
              disabled={this.isFormDisabled()}
              onChange={reduxActions.simpleUpdateEventTypeGeneral}
              onBlur={reduxActions.updateEventTypeGeneralValue}
              validationRules={GeneralValidationRules.Description}
              value={GeneralActiveForm.Description}
              rows={4}
            />
          </Column>
        </Row>
        <Row marginTop={8} mobileMarginTop={8}>
          <Column span={12}>
            <TextField
              label="Leader's Guide Link"
              disabled={this.isFormDisabled()}
              onChange={reduxActions.simpleUpdateEventTypeGeneral}
              onBlur={reduxActions.updateEventTypeGeneralValue}
              validationRules={GeneralValidationRules.LeadersGuideURL}
              value={GeneralActiveForm.LeadersGuideURL}
            />
          </Column>
        </Row>
        <Row marginTop={8} mobileMarginTop={8}>
          <Column span={12}>
            <TextField
              label="Website Link"
              disabled={this.isFormDisabled()}
              onChange={reduxActions.simpleUpdateEventTypeGeneral}
              onBlur={reduxActions.updateEventTypeGeneralValue}
              validationRules={GeneralValidationRules.WebsiteURL}
              value={GeneralActiveForm.WebsiteURL}
            />
          </Column>
        </Row>
        <Row marginTop={8} mobileMarginTop={8}>
          <Column span={12} mobileSpan={12}>
            <ModalSelect
              label="Location"
              disabled={this.isFormDisabled()}
              selectButtonLabel="SELECT LOCATION"
              hideError={action === 'add' ? !!selectedLocationInNew : !!selectedLocation}
              paddingBottom={12}
              selectedCard={selectedLocationCard}
              validationRules={GeneralValidationRules.LocationID}
              onClickSelectButton={() => this.onClickSelectLocationButton()}
              value={GeneralActiveForm.LocationID}/>
          </Column>
          {isUsingNumbers() && <Column span={12} mobileSpan={12}>
            <div className={`${namespace()}--helper-text`}>Campsites can be added/removed under General Settings → Locations</div>
          </Column>}
        </Row>
        <Row styles={{position: 'relative'}} marginTop={24} mobileMarginTop={16}>
          <Column span={6} mobileSpan={12}>
            <Select
              label="Event Category"
              infoOverflow={!GeneralValidationRules.DistrictIDi.errors}
              disabled={this.isFormDisabled()}
              onChangeValue={reduxActions.updateEventTypeGeneralValue}
              value={GeneralActiveForm.EventCategoryID}
              validationRules={GeneralValidationRules.EventCategoryID}
              info={GeneralValidationRules.DistrictIDi.errors ? undefined : <div className={`${namespace()}--card--help-text`}>This is used to set the calendar category, only applies if you have a Tentaroo website. To add a new category, please <Link black medium externalLink={openSupportForm(true)}>contact Tentaroo</Link></div>}
              isNumber />
          </Column>
          {(action === 'add' ? (selectedEventCategoryInNew && selectedEventCategoryInNew.IsDistricts) : (selectedEventCategory && selectedEventCategory.IsDistricts)) && <Column mobileMarginTop={8} style={{height: '67px'}} span={6} mobileSpan={12}>
            <Select
              label="District"
              disabled={this.isFormDisabled()}
              onChangeValue={reduxActions.updateEventTypeGeneralValue}
              value={GeneralActiveForm.DistrictIDi}
              validationRules={GeneralValidationRules.DistrictIDi}
              isNumber />
          </Column>}
        </Row>
        {action === 'add' ? null : <Row marginTop={16} mobileMarginTop={16}>
          <Column span={12} mobileSpan={12}>
            <ModalSelect
              label="New Registration Confirmation Message"
              selectButtonLabel="SELECT"
              disabled={this.isFormDisabled()}
              className={'message'}
              paddingBottom={!!selectedMessage ? 12: 0}
              hideError
              selectedCard={
                !selectedMessage ? null : <AdminMessageCard
                  type={AdminMessageCardType.MODAL_SELECT}
                  disabled={this.isFormDisabled()}
                  category={CardCategory.LIST}
                  message={selectedMessage}
                  onEdit={this.onEditMessageClick}
                  showDeletedPrompt
                  onRemove={() => reduxActions.updateEventTypeGeneralValue(undefined, GeneralValidationRules.NewRegistrationMessageID)}
                />}
              validationRules={GeneralValidationRules.NewRegistrationMessageID}
              onClickSelectButton={() => this.onClickSelectMessageButton()}
              value={GeneralActiveForm.NewRegistrationMessageID}/>
          </Column>
          <Column span={12} mobileSpan={12}>
            <div className={`${namespace()}--helper-text`}>Selected message will automatically be emailed to group when they newly register for this event type.</div>
          </Column>
        </Row>}
        <FieldSet marginTop={24} marginBottom={0} fixedLegendFontSize className={`${namespace()}--primary-contact`} newDesign legendMarginBottom={16} fontSize={18} name="Primary Contact">
          <Row>
            <Column span={6} mobileSpan={12}>
              <TextField
                label="Full Name"
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypeGeneral}
                onBlur={reduxActions.updateEventTypeGeneralValue}
                validationRules={GeneralValidationRules.PrimaryContactName}
                value={GeneralActiveForm.PrimaryContactName}
              />
            </Column>
          </Row>
          <Row marginTop={8} mobileMarginTop={8}>
            <Column marginRight={24} mobileMarginRight={0} span={6} mobileSpan={12}>
              <TextField
                label="Email"
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypeGeneral}
                onBlur={reduxActions.updateEventTypeGeneralValue}
                validationRules={GeneralValidationRules.PrimaryContactEmail}
                value={GeneralActiveForm.PrimaryContactEmail}
              />
            </Column>
            <Column mobileMarginTop={8} span={6} mobileSpan={12}>
              <TextField
                label="Phone"
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypeGeneral}
                onBlur={reduxActions.updateEventTypeGeneralValue}
                validationRules={GeneralValidationRules.PrimaryContactPhone}
                value={GeneralActiveForm.PrimaryContactPhone}
              />
              </Column>
          </Row>
        </FieldSet>
        {action === 'add' ? null : <FieldSet fixedLegendFontSize className={`${namespace()}--additional-contact`} newDesign marginTop={24} mobileMarginTop={24} marginBottom={0} legendMarginBottom={16} fontSize={18} name="Additional Notify Contact">
          <Row>
            <Column marginRight={24} mobileMarginRight={0} span={6} mobileSpan={12}>
              <TextField
                label="Full Name"
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypeGeneral}
                onBlur={reduxActions.updateEventTypeGeneralValue}
                validationRules={GeneralValidationRules.AdditionalNotify1_Name}
                value={GeneralActiveForm.AdditionalNotify1_Name}
              />
            </Column>
            <Column mobileMarginTop={8} span={6} mobileSpan={12}>
              <TextField
                label="Email"
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypeGeneral}
                onBlur={reduxActions.updateEventTypeGeneralValue}
                validationRules={GeneralValidationRules.AdditionalNotify1_Email}
                value={GeneralActiveForm.AdditionalNotify1_Email}
              />
              </Column>
          </Row>
        </FieldSet>}
        {action === 'add' ? null : <FieldSet fixedLegendFontSize className={`${namespace()}--additional-contact`} newDesign marginTop={24} mobileMarginTop={24} marginBottom={0} legendMarginBottom={16} fontSize={18} name="Additional Notify Contact">
          <Row marginTop={8} mobileMarginTop={8}>
            <Column marginRight={24} mobileMarginRight={0} span={6} mobileSpan={12}>
              <TextField
                label="Full Name"
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypeGeneral}
                onBlur={reduxActions.updateEventTypeGeneralValue}
                validationRules={GeneralValidationRules.AdditionalNotify2_Name}
                value={GeneralActiveForm.AdditionalNotify2_Name}
              />
            </Column>
            <Column mobileMarginTop={8} span={6} mobileSpan={12}>
              <TextField
                label="Email"
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypeGeneral}
                onBlur={reduxActions.updateEventTypeGeneralValue}
                validationRules={GeneralValidationRules.AdditionalNotify2_Email}
                value={GeneralActiveForm.AdditionalNotify2_Email}
              />
              </Column>
          </Row>
        </FieldSet>}
      </section>
    );
  };

  renderPricingSection = () => {
    const {showSection} = this.props;

    if (!this.showPricing1Section() && !this.showPricing2Section() && !this.showPricing3Section() && !this.showPricing4Section() && !this.showPricing5Section()) return null;
    return (
      <section>
        <FieldSet fixedLegendFontSize className={`${namespace()}--fieldset-pricing`} newDesign marginBottom={0} marginTop={showSection ? 0 : 32} mobileMarginTop={showSection ? 0 : 24} legendMarginBottom={24} fontSize={22} name="Pricing">
          {isUsingNumbers() && this.showPricing1Section() ? this.renderPricing1() : null}
          {!this.showPricing2Section() ? null : this.renderPricing2()}
          {!this.showPricing3Section() ? null : this.renderPricing3()}
          {!this.showPricing4Section() ? null : this.renderPricing4()}
          {!this.showPricing5Section() ? null : this.renderPricing5()}
        </FieldSet>
      </section>
    );
  };

  renderPricing1 = () => {
    const {reduxActions, formState: {Pricing1: {ActiveForm, ValidationRules}}} = this.props;
    return (
      <section className={`${namespace()}--pricing1`}>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Allow Self Registration"
              multilineLabel
              helperText={['Groups will be allowed to sign themselves up for events. Groups must pay the amount due upfront online to add participants.']}
              value={!!ActiveForm.AllowSelfRegistration}
              validationRules={ValidationRules.AllowSelfRegistration}
              onChange={reduxActions.updateEventTypePricing1Value}
            />
          </Column>
        </Row>
        <Row marginTop={16} styles={{position: 'relative'}}>
          <Column span={6} mobileSpan={12}>
            <TextField
              label="Minimum Number of Participants"
              disabled={this.isFormDisabled()}
              onChange={reduxActions.simpleUpdateEventTypePricing1}
              onBlur={reduxActions.updateEventTypePricing1Value}
              validationRules={ValidationRules.MinNumberOfParticipants}
              value={ActiveForm.MinNumberOfParticipants}
              infoOverflow
              info='If groups can self-register, what is the minimum number of participants the group must register when creating a new registration?'
            />
          </Column>
        </Row>
        <Row marginTop={16}>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={!!ActiveForm.AllowSelfRegistration || this.isFormDisabled()}
              label="Allow Increases to Event Registration"
              multilineLabel
              value={!!ActiveForm.AllowSelfRegistration || !!ActiveForm.AllowIncreasesToRegistration}
              validationRules={ValidationRules.AllowIncreasesToRegistration}
              onChange={reduxActions.updateEventTypePricing1Value}
            />
          </Column>
        </Row>
      </section>
    );
  };

  renderPricing2 = () => {
    const {adminEventsCacheOne: {EventTypeUpfrontPaymentOptions, EventTypeMultipleEventDiscountOptions}, reduxActions, formState: {Pricing2: {ActiveForm, ValidationRules}}} = this.props;

    return (
      <section className={`${namespace()}--pricing2`}>
        <div className={`${namespace()}--field-label`}>Upfront Payment</div>
        {EventTypeUpfrontPaymentOptions ? EventTypeUpfrontPaymentOptions.map((o, index) => {
          return (
            <Row marginTop={index === 0 ? 12 : 0}>
              <Column layout='horizontal' span={12}>
                <RadioGroupOption
                  value={ActiveForm.UpfrontPaymentID === o.ID}
                  rawValue={o.ID}
                  selected={ActiveForm.UpfrontPaymentID === o.ID}
                  disabled={this.isFormDisabled()}
                  onChange={reduxActions.updateEventTypePricing2Value}
                  ValidationRules={ValidationRules.UpfrontPaymentID}
                  newDesign
                  label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>{o.Name}</Text>}
                  helperText={o.Description}
                />
              </Column>
            </Row>
          );
        }) : null}
        {ActiveForm.UpfrontPaymentID === 2 && <Row className={`${namespace()}--specific-amount-row`} marginTop={8}>
            <Column marginRight={24} span={3} mobileSpan={5}>
              <MoneyField
                disabled={this.isFormDisabled()}
                dollarSignPrefix
                value={ActiveForm.UpfrontPaymentSpecific_Youth}
                validationRules={ValidationRules.UpfrontPaymentSpecific_Youth}
                onBlur={reduxActions.updateEventTypePricing2Value}
                onChange={reduxActions.simpleUpdateEventTypePricing2}
                label='Youth'
                placeHolder="$0.00"
              />
            </Column>
            <Column span={3} mobileSpan={5}>
              <MoneyField
                disabled={this.isFormDisabled()}
                dollarSignPrefix
                value={ActiveForm.UpfrontPaymentSpecific_Adults}
                validationRules={ValidationRules.UpfrontPaymentSpecific_Adults}
                onBlur={reduxActions.updateEventTypePricing2Value}
                onChange={reduxActions.simpleUpdateEventTypePricing2}
                label='Adults'
                placeHolder="$0.00"
              />
            </Column>
          </Row>}
          {isJustNames() && <Row marginTop={16}>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Multiple Event Discount for Youth Participants"
                multilineLabel
                helperText={['If a special price is desired for youth participants who are attending more than one event, enable this option, select whether the amount is actual price or discount, and enter an amount.']}
                value={!!ActiveForm.MultipleEventDiscountForYouth}
                validationRules={ValidationRules.MultipleEventDiscountForYouth}
                onChange={reduxActions.updateEventTypePricing2Value}
              />
            </Column>
          </Row>}
          {isJustNames() && !!ActiveForm.MultipleEventDiscountForYouth && <Row marginTop={16}>
            <div className={`${namespace()}--field-label`}>Amount</div>
            {EventTypeMultipleEventDiscountOptions && EventTypeMultipleEventDiscountOptions.map((o, index) => {
              return (
                <Row className={`${namespace()}--pricing2-amount-row`} marginTop={index === 0 ? 12 : 0}>
                  <Column span={3} mobileSpan={5}>
                    <RadioGroupOption
                      value={ActiveForm.MultipleEventDiscountID === o.ID}
                      selected={ActiveForm.MultipleEventDiscountID === o.ID}
                      ValidationRules={ValidationRules.MultipleEventDiscountID}
                      rawValue={o.ID}
                      disabled={this.isFormDisabled()}
                      onChange={reduxActions.updateEventTypePricing2Value}
                      newDesign
                      label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>{o.Name}</Text>}
                    />
                  </Column>
                  {ActiveForm.MultipleEventDiscountID === o.ID && <Column style={{alignItems: 'center'}} span={3} mobileSpan={5}>
                    <MoneyField
                      disabled={this.isFormDisabled()}
                      dollarSignPrefix
                      value={ActiveForm.MultipleEventDiscountAmount}
                      validationRules={ValidationRules.MultipleEventDiscountAmount}
                      onBlur={reduxActions.updateEventTypePricing2Value}
                      onChange={reduxActions.simpleUpdateEventTypePricing2}
                      pullDefaultValueFromRule
                      placeHolder="$0.00"
                    />
                  </Column>}
                </Row>
              );
            })}
          </Row>}
      </section>
    );
  };

  renderPricing3 = () => {
    const {reduxActions, formState: {Pricing3: {ActiveForm, ValidationRules}}} = this.props;
    return (
      <section className={`${namespace()}--pricing3`}>
        <FieldSet marginBottom={0} fixedLegendFontSize className={`${namespace()}--pricing-levels-by-date`} newDesign legendMarginBottom={8} fontSize={18} name="Pricing Levels by Date">
          <div className={`${namespace()}--field-label`}>These will be used to describe the pricing levels to the end user that are based on date.</div>
          <Row marginTop={16}>
            <Column span={6} mobileSpan={12}>
              <TextField
                label="Early"
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypePricing3}
                onBlur={reduxActions.updateEventTypePricing3Value}
                validationRules={ValidationRules.PricingLevelsByDate_Early}
                value={ActiveForm.PricingLevelsByDate_Early}
              />
            </Column>
            <Column marginTop={0} mobileMarginTop={8} span={6} mobileSpan={12}>
              <TextField
                label="Base"
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypePricing3}
                onBlur={reduxActions.updateEventTypePricing3Value}
                validationRules={ValidationRules.PricingLevelsByDate_Base}
                value={ActiveForm.PricingLevelsByDate_Base}
              />
            </Column>
          </Row>
          <Row marginTop={8}>
            <Column span={6} mobileSpan={12}>
                <TextField
                  label="Late"
                  disabled={this.isFormDisabled()}
                  onChange={reduxActions.simpleUpdateEventTypePricing3}
                  onBlur={reduxActions.updateEventTypePricing3Value}
                  validationRules={ValidationRules.PricingLevelsByDate_Late}
                  value={ActiveForm.PricingLevelsByDate_Late}
                />
              </Column>
          </Row>
          <Row marginTop={8}>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Allow Late Registrations"
                multilineLabel
                helperText={['Enable to allow three pricing levels by date. Disable to stop registration on a specific date for each event. If disabled, registrations will end on the Late Pricing Start Date, at the beginning of the day.']}
                value={!!ActiveForm.AllowLateRegistrations}
                validationRules={ValidationRules.AllowLateRegistrations}
                onChange={reduxActions.updateEventTypePricing3Value}
              />
            </Column>
          </Row>
          {!!ActiveForm.AllowLateRegistrations && <Row marginTop={16} styles={{position: 'relative'}}>
            <Column span={6} mobileSpan={12}>
              <TextField
                label="Number of Days Before Event to Close Registration"
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypePricing3}
                onBlur={reduxActions.updateEventTypePricing3Value}
                validationRules={ValidationRules.NumberOfDaysBeforeEventToCloseRegistration}
                value={ActiveForm.NumberOfDaysBeforeEventToCloseRegistration}
                infoOverflow
                overflowLabel
                info='Registration will close this number of days before the event at the beginning of the day. If set to 1, then registration will close as soon as the day before the event starts (2 days prior at midnight).'
              />
            </Column>
          </Row>}
        </FieldSet>
      </section>
    );
  };

  onRemoveParticipantType = (pt: ParticipantType, index: number) => {
    const isYouthKey = getAdminEventTypeParticipantTypeAttrKey(pt.ID, AdminEventTypeParticipantTypeKeys.IS_YOUTH);
    const hasYouth = this.props.formState.Pricing4.ActiveForm.ParticipantTypes.filter((_pt) => _pt.ID !== pt.ID).some((_pt) => {
      const _isYouthKey = getAdminEventTypeParticipantTypeAttrKey(_pt.ID, AdminEventTypeParticipantTypeKeys.IS_YOUTH);
      return _pt.ActiveForm[_isYouthKey] && !_pt.Inactive;
    });
    const hasAdults = this.props.formState.Pricing4.ActiveForm.ParticipantTypes.filter((_pt) => _pt.ID !== pt.ID).some((_pt) => {
      const _isYouthKey = getAdminEventTypeParticipantTypeAttrKey(_pt.ID, AdminEventTypeParticipantTypeKeys.IS_YOUTH);
      return !_pt.ActiveForm[_isYouthKey] && !_pt.Inactive;
    });

    if ((pt.ActiveForm[isYouthKey] && !hasYouth) || (!pt.ActiveForm[isYouthKey] && !hasAdults)) {
      this.props.reduxActions.pushModal(ModalTypes.REMOVE_PARTICIPANT_TYPE, false, true);
      return;
    }
    if (pt.ID < 0 && !pt.IsReplacement) {
      this.props.reduxActions.removeParticipantType(pt, index);
    } else {
      this.props.reduxActions.pushModal(ModalTypes.REPLACE_PARTICIPANT_TYPE, false, false, {participantType: pt, participantTypeIndex: index});
    }
  };
  onNewParticipantType = () => {
    this.props.reduxActions.addParticipantType();
  };
  updateParticipantType = (v, vObj, index: number) => {
    this.props.reduxActions.updateParticipantTypeValue(
      v,
      vObj,
      (s: ApplicationState) => s.adminEvents.eventTypes.eventType.form.Pricing4.ActiveForm.ParticipantTypes[index],
      true,
    );
  };
  renderPricing4 = () => {
    const {pricingTiers, adminEventsCacheOne: {EventTypeLockInPricingBasedOnOptions}, action, reduxActions, formState: {Pricing4: {ActiveForm, ValidationRules}}} = this.props;
    return (
      <section className={`${namespace()}--pricing4`}>
        <FieldSet marginBottom={0} fixedLegendFontSize className={`${namespace()}--pricing-tiers`} newDesign legendMarginBottom={8} fontSize={18} name="Pricing Tiers">
            {action !== 'add' && <div className={`${namespace()}--field-label`}>To add or remove pricing tiers, <Link black medium externalLink={openPricingTierForm()}>Contact Tentaroo</Link>.</div>}
            {action !== 'add' && <div className={`${namespace()}--field-label`}>If disabled, pricing tier will be kept in sync with Default / In-Council pricing.</div>}
            {pricingTiers && pricingTiers.map((p, index) => {
              const key = getAdminEventTypePricingTierKey(p.ID);
              return (
                <Row marginTop={index === 0 ? 16 : 0}>
                  <Column span={12}>
                    <Switch
                      margin={false}
                      newDesign
                      disabled={p.ID === 1 || this.isFormDisabled()}
                      label={p.Name}
                      multilineLabel
                      value={!!ActiveForm[key]}
                      validationRules={ValidationRules[key]}
                      onChange={reduxActions.updateEventTypePricing4Value}
                    />
                  </Column>
                </Row>
              );
            })}
          </FieldSet>
          <FieldSet
            marginBottom={0} marginTop={24} legendMarginBottom={16}
            fixedLegendFontSize
            newDesign
            className={`${namespace()}--participant-types`}
            fontSize={18} 
            name="Participant Types"
            keepControlsPositionInMobile
            hideControlsInDesktop
            controls={<Button disabled={this.isFormDisabled()} onClick={this.onNewParticipantType} color='white' textColor='black'>NEW</Button>}
          >
            {ActiveForm.ParticipantTypes && ActiveForm.ParticipantTypes.map((pt, index) => {
              const isYouthKey = getAdminEventTypeParticipantTypeAttrKey(pt.ID, AdminEventTypeParticipantTypeKeys.IS_YOUTH);
              const nameKey = getAdminEventTypeParticipantTypeAttrKey(pt.ID, AdminEventTypeParticipantTypeKeys.NAME);

              if (pt.Inactive) return null;

              return (
                <Row
                  className={`${namespace()}--participant-type--row`}
                  marginTop={index === 0 ? 0 : 8}
                  wrap={false}
                >
                  <Row wrap={false}>
                    <Column span={6} mobileSpan={12} mobileMarginBottom={8} marginRight={24} mobileMarginRight={0}>
                      <Select
                        label="Youth / Adults"
                        customizedId={`participant-type-${pt.ID}-youth-adults`}
                        disabled={pt.ID > 0 || pt.IsReplacement || this.isFormDisabled()}
                        onChangeValue={(v, vObj) => this.updateParticipantType(v, vObj, index)}
                        value={pt.ActiveForm[isYouthKey]}
                        validationRules={pt.ValidationRules[isYouthKey]}
                        isNumber />
                    </Column>
                    <Column span={6} mobileSpan={12} marginRight={0} mobileMarginRight={0}>
                      <TextField
                        label="Name"
                        disabled={this.isFormDisabled()}
                        onChange={reduxActions.simpleUpdateParticipantType}
                        onBlur={(v, vObj) => this.updateParticipantType(v, vObj, index)}
                        value={pt.ActiveForm[nameKey]}
                        validationRules={pt.ValidationRules[nameKey]}
                      />
                    </Column>
                  </Row>
                  <ActionButton
                    disabled={this.isFormDisabled()}
                    className={`${namespace()}--participant-type--close`}
                    icon={CloseIcon}
                    onClick={() => this.onRemoveParticipantType(pt, index)}
                    id={generateDOMId(`participant-type-${pt.ID}-remove-btn`)}
                  />
                  <div className={`${namespace()}--participant-type--divider`} />
                </Row>
              );
            })}
            <Button className={`${namespace()}--new-participant-type-btn`} disabled={this.isFormDisabled()} onClick={this.onNewParticipantType} color='white' textColor='black'>NEW</Button>
            <div className={`${namespace()}--field-label ${namespace()}--lockin-pricing`}>Lock-in Pricing Based on</div>
            {EventTypeLockInPricingBasedOnOptions && EventTypeLockInPricingBasedOnOptions.map((o, index) => {
              const helperText = o.Description ? o.Description.split("\\n\\n") : [];
              const optionDisabled =  !!o.DisabledErrorMessage && ((o.ID === 2 && isJustNames()) || (o.ID === 1 && isUsingNumbers()));
              if (optionDisabled) helperText.push(o.DisabledErrorMessage);
              return (
                <Row marginTop={index === 0 ? 12 : 0}>
                  <Column span={12}>
                    <RadioGroupOption
                      value={ActiveForm.LockInPricingBasedOnID === o.ID}
                      selected={ActiveForm.LockInPricingBasedOnID === o.ID}
                      ValidationRules={ValidationRules.LockInPricingBasedOnID}
                      rawValue={o.ID}
                      disabled={optionDisabled || this.isFormDisabled()}
                      onChange={reduxActions.updateEventTypePricing4Value}
                      newDesign
                      redHelperText={optionDisabled}
                      label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>{o.Name}</Text>}
                      helperText={helperText}

                    />
                  </Column>
                </Row>
              );
            })}
          </FieldSet>
      </section>
    );
  };

  renderPricing5 = () => {
    const {reduxActions, formState: {Pricing5: {ActiveForm, ValidationRules}}, systemSettings} = this.props;

    if (!systemSettings || (!systemSettings.AllowCCPayment && !systemSettings.AllowECheckPayment)) return null;
    return (
      <section className={`${namespace()}--pricing5`}>
        <FieldSet marginBottom={0} fixedLegendFontSize className={`${namespace()}--payment-gateways`} newDesign legendMarginBottom={8} fontSize={18} name="Payment Gateways">
          <div className={`${namespace()}--field-label bold`}>Warning: Disabling credit card or eCheck payments here is not recommended as users may not know until they try to checkout that the payment type is not allowed for this event type. We instead recommend charging a fee for online payments to cover processing costs.</div>
          {systemSettings.AllowCCPayment && <Row marginTop={16}>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Allow Credit Card Payments"
                multilineLabel
                value={!!ActiveForm.AllowCCPayment}
                validationRules={ValidationRules.AllowCCPayment}
                onChange={reduxActions.updateEventTypePricing5Value}
              />
            </Column>
          </Row>}
          {systemSettings.AllowCCPayment && ActiveForm.AllowCCPayment && <Row marginTop={8}>
            <Column span={3} mobileSpan={6}>
              <TextField
                label="Extra Fee"
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypePricing5}
                onBlur={reduxActions.updateEventTypePricing5Value}
                validationRules={ValidationRules.CCFeePercent}
                value={ActiveForm.CCFeePercent}
                percentSignSuffix
              />
            </Column>
          </Row>}
          {systemSettings.AllowECheckPayment && <Row marginTop={8}>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Allow eCheck Payments"
                multilineLabel
                value={!!ActiveForm.AllowECheckPayment}
                validationRules={ValidationRules.AllowECheckPayment}
                onChange={reduxActions.updateEventTypePricing5Value}
              />
            </Column>
          </Row>}
          {systemSettings.AllowECheckPayment && ActiveForm.AllowECheckPayment && <Row marginTop={8}>
            <Column span={3} mobileSpan={6}>
              <TextField
                label="Extra Fee"
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypePricing5}
                onBlur={reduxActions.updateEventTypePricing5Value}
                validationRules={ValidationRules.eCheckFeePercent}
                value={ActiveForm.eCheckFeePercent}
                percentSignSuffix
              />
            </Column>
          </Row>}
        </FieldSet>
      </section>
    );
  };

  renderGroupRegistration = () => {
    const {reduxActions, formState: {GroupAndRegistration: {ActiveForm, ValidationRules}}, adminEventsCacheOne: {EventTypePaymentRequirementsToAddParticipantsOptions}} = this.props;
    return (
      <section>
        <FieldSet fixedLegendFontSize className={`${namespace()}--fieldset-group-registration`} newDesign marginBottom={0} marginTop={this.props.showSection ? 0 : 32} mobileMarginTop={this.props.showSection ? 0 : 24} legendMarginBottom={0} fontSize={22} name={`Group & Registration`}>
          <Row styles={{position: 'relative'}} marginTop={24} mobileMarginTop={16}>
            <Column span={6} mobileSpan={12} marginRight={24} mobileMarginRight={0}>
              <Select
                label="Default Group Type"
                disabled={this.isFormDisabled()}
                onChangeValue={reduxActions.updateEventTypeGroupRegistrationValue}
                value={ActiveForm.DefaultGroupTypeID}
                validationRules={ValidationRules.DefaultGroupTypeID}
                info={isMobileAndSmallerScreenSize() ? undefined : 'Defaults when creating a new account for this event type.'}
                infoOverflow
                isNumber />
            </Column>
            <Column style={{height: '67px'}} mobileMarginTop={8} span={6} mobileSpan={12}>
              <Select
                label="Default Unit Type"
                disabled={this.isFormDisabled()}
                onChangeValue={reduxActions.updateEventTypeGroupRegistrationValue}
                value={ActiveForm.DefaultUnitTypeID}
                validationRules={ValidationRules.DefaultUnitTypeID}
                info={isMobileAndSmallerScreenSize() ? 'Defaults when creating a new account for this event type.' : undefined}
                isNumber />
            </Column>
          </Row>
          <Row marginTop={16}>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Show Group Type During Account Creation"
                multilineLabel
                value={!!ActiveForm.ShowGroupTypeDuringAccountCreation}
                validationRules={ValidationRules.ShowGroupTypeDuringAccountCreation}
                onChange={reduxActions.updateEventTypeGroupRegistrationValue}
              />
            </Column>
          </Row>
          {isJustNumbers() && <Row>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Show Youth"
                multilineLabel
                value={!!ActiveForm.ShowYouth}
                validationRules={ValidationRules.ShowYouth}
                onChange={reduxActions.updateEventTypeGroupRegistrationValue}
              />
            </Column>
          </Row>}
          {isJustNumbers() && <Row>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Show Adults"
                multilineLabel
                value={!!ActiveForm.ShowAdults}
                validationRules={ValidationRules.ShowAdults}
                onChange={reduxActions.updateEventTypeGroupRegistrationValue}
              />
            </Column>
          </Row>}
          {isUsingNames() && <Row>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Allow Non-Scouter Participants"
                multilineLabel
                value={!!ActiveForm.AllowNonScouterParticipants}
                helperText={["Participants will be asked whether or not they're Scouters"]}
                validationRules={ValidationRules.AllowNonScouterParticipants}
                onChange={reduxActions.updateEventTypeGroupRegistrationValue}
              />
            </Column>
          </Row>}
          {isUsingNames() && isUsingNumbers() && <Row marginBottom={8} marginTop={16}>
            <div className={`${namespace()}--field-label`}>Payment Requirements to Add Participants</div>
            {EventTypePaymentRequirementsToAddParticipantsOptions && EventTypePaymentRequirementsToAddParticipantsOptions.map((o, index) => {
              return (
                <Row className={`${namespace()}--payment-requirements-row`} marginTop={index === 0 ? 12 : 0}>
                  <Column span={12}>
                    <RadioGroupOption
                      value={ActiveForm.PaymentRequirementsToAddParticipantsID === o.ID}
                      selected={ActiveForm.PaymentRequirementsToAddParticipantsID === o.ID}
                      disabled={this.isFormDisabled()}
                      ValidationRules={ValidationRules.PaymentRequirementsToAddParticipantsID}
                      rawValue={o.ID}
                      onChange={reduxActions.updateEventTypeGroupRegistrationValue}
                      newDesign
                      label={<Text wrap color='dark-gray' weight="regular" size={16} marginBottom={0}>{o.Name}</Text>}
                      helperText={o.Description}
                    />
                  </Column>
                </Row>
              );
            })}
            {ActiveForm.PaymentRequirementsToAddParticipantsID === 2 && <Row className={`${namespace()}--minimum-percentage-row`} marginTop={8}>
                <Column span={4} mobileSpan={6}>
                  <MoneyField
                    isInteger
                    disabled={this.isFormDisabled()}
                    value={ActiveForm.PaymentRequirementsMinimumPercentage}
                    validationRules={ValidationRules.PaymentRequirementsMinimumPercentage}
                    onBlur={reduxActions.updateEventTypeGroupRegistrationValue}
                    onChange={reduxActions.simpleUpdateEventTypeGroupRegistration}
                    label='Min. Percentage'
                    percentSignSuffix
                  />
              </Column>
            </Row>}
          </Row>}
          {isUsingNumbers() && <Row>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Show Event Contact"
                multilineLabel
                value={!!ActiveForm.ShowEventContact}
                validationRules={ValidationRules.ShowEventContact}
                onChange={reduxActions.updateEventTypeGroupRegistrationValue}
              />
            </Column>
          </Row>}
          {isUsingNumbers() && !!ActiveForm.ShowEventContact && <Row>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Require Event Contact"
                multilineLabel
                value={!!ActiveForm.RequireEventContact}
                validationRules={ValidationRules.RequireEventContact}
                onChange={reduxActions.updateEventTypeGroupRegistrationValue}
              />
            </Column>
          </Row>}
          {isUsingNumbers() && <Row>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Show Campsite Ranking"
                multilineLabel
                value={!!ActiveForm.ShowCampsiteRanking}
                validationRules={ValidationRules.ShowCampsiteRanking}
                helperText={['Note: Notification will be emailed daily when campsites have been requested but haven’t been assigned.']}
                onChange={reduxActions.updateEventTypeGroupRegistrationValue}
              />
            </Column>
          </Row>}
          {isUsingNumbers() && !!ActiveForm.ShowCampsiteRanking && <Row>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Require Campsite Ranking"
                multilineLabel
                value={!!ActiveForm.RequireCampsiteRanking}
                validationRules={ValidationRules.RequireCampsiteRanking}
                onChange={reduxActions.updateEventTypeGroupRegistrationValue}
              />
            </Column>
          </Row>}
          {isUsingNumbers() && <Row>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Show Registration Notes"
                multilineLabel
                value={!!ActiveForm.ShowEndUserRegistrationNotes}
                validationRules={ValidationRules.ShowEndUserRegistrationNotes}
                helperText={['Select if you want to show an extra notes field for end users to use on their side.']}
                onChange={reduxActions.updateEventTypeGroupRegistrationValue}
              />
            </Column>
          </Row>}
          {isUsingNumbers() && !!ActiveForm.ShowEndUserRegistrationNotes && <Row>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Require Registration Notes"
                multilineLabel
                value={!!ActiveForm.RequireEndUserRegistrationNotes}
                validationRules={ValidationRules.RequireEndUserRegistrationNotes}
                onChange={reduxActions.updateEventTypeGroupRegistrationValue}
              />
            </Column>
          </Row>}
          {isUsingNumbers() && !!ActiveForm.ShowEndUserRegistrationNotes && <Row marginTop={16}>
            <Column span={12}>
              <TextField
                label="Custom Prompt For Registration Notes"
                disabled={this.isFormDisabled()}
                rows={5}
                onChange={reduxActions.simpleUpdateEventTypeGroupRegistration}
                onBlur={reduxActions.updateEventTypeGroupRegistrationValue}
                validationRules={ValidationRules.PromptEndUserRegistrationNotes}
                value={ActiveForm.PromptEndUserRegistrationNotes}
              />
            </Column>
          </Row>}
        </FieldSet>
      </section>
    );
  };

  renderShowYouthSection = () => {
    const {reduxActions, formState: {NamedParticipants: {Youth: {ActiveForm, ValidationRules}}}} = this.props;

    return (
      <FormSection
        title='Show Youth'
        toggle={<Switch
          margin={false}
          newDesign
          disabled={this.isFormDisabled()}
          value={!!ActiveForm.ShowYouth}
          validationRules={ValidationRules.ShowYouth}
          onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
        />}
        hideContent={!ActiveForm.ShowYouth}
      >
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Gender"
              multilineLabel
              value={!!ActiveForm.ShowGenderYouth}
              validationRules={ValidationRules.ShowGenderYouth}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_YouthValue}
                  value={ActiveForm.RequireGenderYouth}
                  validationRules={ValidationRules.RequireGenderYouth}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Date of Birth"
              multilineLabel
              value={!!ActiveForm.ShowDOBYouth}
              validationRules={ValidationRules.ShowDOBYouth}
              helperText={['Set this as required if planning to use minimum age requirements for any classes.']}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_YouthValue}
                  value={ActiveForm.RequireDOBYouth}
                  validationRules={ValidationRules.RequireDOBYouth}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Allergies, Dietary Restrictions"
              multilineLabel
              value={ActiveForm.ShowAllergiesAndDietaryYouth}
              validationRules={ValidationRules.ShowAllergiesAndDietaryYouth}
              onChange={this.onChangeShowAllergiesAndDietary}
              helperText={['This setting is shared between Youth/Adults']}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Notes for Special Needs"
              multilineLabel
              value={ActiveForm.ShowSpecialNeedsNotesYouth}
              validationRules={ValidationRules.ShowSpecialNeedsNotesYouth}
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
              helperText={['Use to collect more information about any allergies, dietary restrictions or other special needs.']}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Event Registration Notes"
              multilineLabel
              value={ActiveForm.ShowRegistrationNotesYouth}
              validationRules={ValidationRules.ShowRegistrationNotesYouth}
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={true}
              label="Email"
              keepLabelColorWhenDisabled
              multilineLabel
              value={true}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_YouthValue}
                  value={ActiveForm.RequireEmailYouth}
                  validationRules={ValidationRules.RequireEmailYouth}
                />
              }
              onChange={() => {}}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Phone Number"
              multilineLabel
              value={!!ActiveForm.ShowPhoneYouth}
              validationRules={ValidationRules.ShowPhoneYouth}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_YouthValue}
                  value={ActiveForm.RequirePhoneYouth}
                  validationRules={ValidationRules.RequirePhoneYouth}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Address"
              multilineLabel
              value={!!ActiveForm.ShowAddressYouth}
              validationRules={ValidationRules.ShowAddressYouth}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_YouthValue}
                  value={ActiveForm.RequireAddressYouth}
                  validationRules={ValidationRules.RequireAddressYouth}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Emergency Contact"
              multilineLabel
              value={!!ActiveForm.ShowEmergencyContact}
              validationRules={ValidationRules.ShowEmergencyContact}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_YouthValue}
                  value={ActiveForm.RequireEmergencyContact}
                  validationRules={ValidationRules.RequireEmergencyContact}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Allow Photography"
              multilineLabel
              value={ActiveForm.ShowAllowPhotographyYouth}
              validationRules={ValidationRules.ShowAllowPhotographyYouth}
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
              helperText={["This can be used to indicate whether or not the camp has permission to take photos of children at events."]}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Shirt Size"
              multilineLabel
              value={!!ActiveForm.ShowShirtSizeYouth}
              validationRules={ValidationRules.ShowShirtSizeYouth}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_YouthValue}
                  value={ActiveForm.RequireShirtSizeYouth}
                  validationRules={ValidationRules.RequireShirtSizeYouth}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Grade"
              multilineLabel
              value={!!ActiveForm.ShowGradeYouth}
              validationRules={ValidationRules.ShowGradeYouth}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_YouthValue}
                  value={ActiveForm.RequireGradeYouth}
                  validationRules={ValidationRules.RequireGradeYouth}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={true}
              label="Rank"
              keepLabelColorWhenDisabled
              multilineLabel
              value={true}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_YouthValue}
                  value={ActiveForm.RequireRankYouth}
                  validationRules={ValidationRules.RequireRankYouth}
                />
              }
              onChange={() => {}}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Summer Camp Year"
              keepLabelColorWhenDisabled
              multilineLabel
              inlineDropdown={
                <Select
                  disabled={true}
                  onChangeValue={() => undefined}
                  value={true}
                  validationRules={ValidationRules.RequireShowSummerCampYear}
                />
              }
              value={!!ActiveForm.ShowSummerCampYearYouth}
              validationRules={ValidationRules.ShowSummerCampYearYouth}
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Unit Position"
              multilineLabel
              value={!!ActiveForm.ShowUnitPositionYouth}
              validationRules={ValidationRules.ShowUnitPositionYouth}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_YouthValue}
                  value={ActiveForm.RequireUnitPositionYouth}
                  validationRules={ValidationRules.RequireUnitPositionYouth}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Unit Leader"
              multilineLabel
              value={!!ActiveForm.ShowUnitLeaderYouth}
              validationRules={ValidationRules.ShowUnitLeaderYouth}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_YouthValue}
                  value={ActiveForm.RequireUnitLeaderYouth}
                  validationRules={ValidationRules.RequireUnitLeaderYouth}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="BSA ID"
              multilineLabel
              value={!!ActiveForm.ShowBSAIDYouth}
              validationRules={ValidationRules.ShowBSAIDYouth}
              helperText={["Optional/required for this setting is shared between Youth/Adults."]}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={this.onChangeRequireBSAID}
                  value={ActiveForm.RequireBSAIDYouth}
                  validationRules={ValidationRules.RequireBSAIDYouth}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Is Staff?"
              multilineLabel
              value={ActiveForm.ShowIsStaffYouth}
              validationRules={ValidationRules.ShowIsStaffYouth}
              onChange={reduxActions.updateEventTypeNamedParticipants_YouthValue}
            />
          </Column>
        </Row>
      </FormSection>
    );
  };

  onChangeShowAllergiesAndDietary = (val, vObj) => {
    const {reduxActions, formState: {NamedParticipants: {Adults, Youth}}} = this.props;
    this.props.reduxActions.updateEventTypeNamedParticipants_YouthValue(val, Youth.ValidationRules.ShowAllergiesAndDietaryYouth);
    this.props.reduxActions.updateEventTypeNamedParticipants_AdultsValue(val, Adults.ValidationRules.ShowAllergiesAndDietaryAdult);
  };

  onChangeRequireBSAID = (val, vObj) => {
    const {reduxActions, formState: {NamedParticipants: {Adults, Youth}}} = this.props;
    this.props.reduxActions.updateEventTypeNamedParticipants_YouthValue(val, Youth.ValidationRules.RequireBSAIDYouth);
    this.props.reduxActions.updateEventTypeNamedParticipants_AdultsValue(val, Adults.ValidationRules.RequireBSAIDAdult);
  };

  renderShowAdultsSection = () => {
    const {reduxActions, formState: {NamedParticipants: {Adults: {ActiveForm, ValidationRules}}}} = this.props;

    return (
      <FormSection
        title='Show Adults'
        toggle={<Switch
          margin={false}
          newDesign
          disabled={this.isFormDisabled()}
          value={!!ActiveForm.ShowAdults}
          validationRules={ValidationRules.ShowAdults}
          onChange={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
        />}
        hideContent={!ActiveForm.ShowAdults}
      >
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={true}
              label="Gender"
              keepLabelColorWhenDisabled
              multilineLabel
              value={true}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
                  value={ActiveForm.RequireGenderAdult}
                  validationRules={ValidationRules.RequireGenderAdult}
                />
              }
              onChange={() => {}}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Date of Birth"
              multilineLabel
              value={!!ActiveForm.ShowDOBAdult}
              validationRules={ValidationRules.ShowDOBAdult}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
                  value={ActiveForm.RequireDOBAdult}
                  validationRules={ValidationRules.RequireDOBAdult}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Allergies, Dietary Restrictions"
              multilineLabel
              value={ActiveForm.ShowAllergiesAndDietaryAdult}
              validationRules={ValidationRules.ShowAllergiesAndDietaryAdult}
              onChange={this.onChangeShowAllergiesAndDietary}
              helperText={['This setting is shared between Youth/Adults']}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Notes for Special Needs"
              multilineLabel
              value={ActiveForm.ShowSpecialNeedsNotesAdult}
              validationRules={ValidationRules.ShowSpecialNeedsNotesAdult}
              onChange={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
              helperText={['Use to collect more information about any allergies, dietary restrictions or other special needs.']}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Event Registration Notes"
              multilineLabel
              value={ActiveForm.ShowRegistrationNotesAdult}
              validationRules={ValidationRules.ShowRegistrationNotesAdult}
              onChange={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={true}
              label="Email"
              keepLabelColorWhenDisabled
              multilineLabel
              value={true}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
                  value={ActiveForm.RequireEmailAdult}
                  validationRules={ValidationRules.RequireEmailAdult}
                />
              }
              onChange={() => {}}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Phone Number"
              multilineLabel
              value={!!ActiveForm.ShowPhoneAdult}
              validationRules={ValidationRules.ShowPhoneAdult}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
                  value={ActiveForm.RequirePhoneAdult}
                  validationRules={ValidationRules.RequirePhoneAdult}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Address"
              multilineLabel
              value={!!ActiveForm.ShowAddressAdult}
              validationRules={ValidationRules.ShowAddressAdult}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
                  value={ActiveForm.RequireAddressAdult}
                  validationRules={ValidationRules.RequireAddressAdult}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Shirt Size"
              multilineLabel
              value={!!ActiveForm.ShowShirtSizeAdult}
              validationRules={ValidationRules.ShowShirtSizeAdult}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
                  value={ActiveForm.RequireShirtSizeAdult}
                  validationRules={ValidationRules.RequireShirtSizeAdult}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Unit Position"
              multilineLabel
              value={!!ActiveForm.ShowUnitPositionAdult}
              validationRules={ValidationRules.ShowUnitPositionAdult}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
                  value={ActiveForm.RequireUnitPositionAdult}
                  validationRules={ValidationRules.RequireUnitPositionAdult}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Youth Protection Training Date"
              multilineLabel
              value={!!ActiveForm.ShowDateYouthProtectionTrainingAdult}
              validationRules={ValidationRules.ShowDateYouthProtectionTrainingAdult}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
                  value={ActiveForm.RequireDateYouthProtectionTrainingAdult}
                  validationRules={ValidationRules.RequireDateYouthProtectionTrainingAdult}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Baloo Training Date"
              multilineLabel
              value={!!ActiveForm.ShowDateBalooTrainingAdult}
              validationRules={ValidationRules.ShowDateBalooTrainingAdult}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
                  value={ActiveForm.RequireDateBalooTrainingAdult}
                  validationRules={ValidationRules.RequireDateBalooTrainingAdult}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Basic Training Date"
              multilineLabel
              value={!!ActiveForm.ShowDateBasicTrainingForPositionAdult}
              validationRules={ValidationRules.ShowDateBasicTrainingForPositionAdult}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
                  value={ActiveForm.RequireDateBasicTrainingForPositionAdult}
                  validationRules={ValidationRules.RequireDateBasicTrainingForPositionAdult}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="Days in Camp"
              multilineLabel
              value={ActiveForm.ShowDaysInCampAdult}
              validationRules={ValidationRules.ShowDaysInCampAdult}
              onChange={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
            />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="BSA ID"
              multilineLabel
              value={!!ActiveForm.ShowBSAIDAdult}
              validationRules={ValidationRules.ShowBSAIDAdult}
              helperText={["Optional/required for this setting is shared between Youth/Adults."]}
              inlineDropdown={
                <Select
                  disabled={this.isFormDisabled()}
                  onChangeValue={this.onChangeRequireBSAID}
                  value={ActiveForm.RequireBSAIDAdult}
                  validationRules={ValidationRules.RequireBSAIDAdult}
                />
              }
              onChange={reduxActions.updateEventTypeNamedParticipants_AdultsValue}
            />
          </Column>
        </Row>
      </FormSection>
    );
  };

  renderOAFieldsSection = () => {
    const {reduxActions, formState: {NamedParticipants: {OAFields: {ActiveForm, ValidationRules}}}} = this.props;

    return (
      <FormSection
        title='Other Fields'
        toggle={<div />}
        hideToggle
      >
        <Row>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={this.isFormDisabled()}
              label="OA Fields"
              multilineLabel
              helperText={['Show the Chapter, Lodge, OA Status and OA Position fields. ']}
              value={!!ActiveForm.ShowOAFields}
              validationRules={ValidationRules.ShowOAFields}
              onChange={reduxActions.updateEventTypeNamedParticipants_OAFieldsValue}
            />
          </Column>
        </Row>
      </FormSection>
    );
  };

  renderNamedParticipants = () => {
    if (isJustNumbers()) return null;

    return (
      <section>
        <FieldSet fixedLegendFontSize className={`${namespace()}--fieldset-class-scheduling`} newDesign marginBottom={0} marginTop={this.props.showSection ? 0 : 32} mobileMarginTop={this.props.showSection ? 0 : 24} legendMarginBottom={24} fontSize={22} name={`Named Participants`}>
          {this.renderShowYouthSection()}
          {this.renderShowAdultsSection()}
          {this.renderOAFieldsSection()}
        </FieldSet>
      </section>
    );
  };

  renderClassScheduling = () => {
    const {reduxActions, formState: {ClassScheduling: {ActiveForm, ValidationRules}}} = this.props;
    return (
      <section>
        <FieldSet fixedLegendFontSize className={`${namespace()}--fieldset-class-scheduling`} newDesign marginBottom={0} marginTop={this.props.showSection ? 0 : 32} mobileMarginTop={this.props.showSection ? 0 : 24} legendMarginBottom={24} fontSize={22} name={`Class Scheduling`}>
          {isUsingNames() && <Row>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Enable Waiting List Under Individual Participants"
                multilineLabel
                helperText={['Allows groups to select classes that are full to be added to the waiting list. This only applies  to classes that are for individuals, not group classes.']}
                value={!!ActiveForm.EnableClassWaitingListForIndividuals}
                validationRules={ValidationRules.EnableClassWaitingListForIndividuals}
                onChange={reduxActions.updateEventTypeClassSchedulingValue}
              />
            </Column>
          </Row>}
          {isUsingNames() && <Row>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={!!ActiveForm.EnableClassWaitingListForIndividuals || this.isFormDisabled()}
                label="Show Full Classes for Participants"
                multilineLabel
                helperText={["Shows all classes, even if full. Full classes still can't be selected unless waiting list is enabled."]}
                value={!!ActiveForm.EnableClassWaitingListForIndividuals || !!ActiveForm.ShowFullClassesForIndividuals}
                validationRules={ValidationRules.ShowFullClassesForIndividuals}
                onChange={reduxActions.updateEventTypeClassSchedulingValue}
              />
            </Column>
          </Row>}
          {isUsingNumbers() && <Row>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Show Full Group Classes"
                multilineLabel
                helperText={["Shows all group classes and options when scheduling, even if class is full. Full classes still can't be selected."]}
                value={!!ActiveForm.ShowFullClassesGroup}
                validationRules={ValidationRules.ShowFullClassesGroup}
                onChange={reduxActions.updateEventTypeClassSchedulingValue}
              />
            </Column>
          </Row>}
          <Row>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Require Unique Class Types"
                multilineLabel
                helperText={["Require that each selected class in a participant’s schedule be a different class type. This applies to all class schedules for youth/adults/groups."]}
                value={!!ActiveForm.RequireUniqueClassTypes}
                validationRules={ValidationRules.RequireUniqueClassTypes}
                onChange={reduxActions.updateEventTypeClassSchedulingValue}
              />
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <Switch
                margin={false}
                newDesign
                disabled={this.isFormDisabled()}
                label="Show Class Location Field"
                multilineLabel
                helperText={["Entered class locations will be displayed on the Participant Class Schedule reports prior to the event start."]}
                value={!!ActiveForm.ShowClassLocation}
                validationRules={ValidationRules.ShowClassLocation}
                onChange={reduxActions.updateEventTypeClassSchedulingValue}
              />
            </Column>
          </Row>
          {isUsingNames() && <Row marginTop={16}>
            <Column span={12}>
              <TextField
                label={isMobileAndSmallerScreenSize() ? 'Label for Partic. Classes' : 'Custom Label for Participant Classes'}
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypeClassScheduling}
                onBlur={reduxActions.updateEventTypeClassSchedulingValue}
                validationRules={ValidationRules.CustomLabelForParticipantClasses}
                value={ActiveForm.CustomLabelForParticipantClasses}
                info='Enter a name to use instead of "Classes" on the end user side. Use if only using classes for registration options.'
              />
            </Column>
          </Row>}
          <FieldSet fixedLegendFontSize className={`${namespace()}--fieldset-class-scheduling`} newDesign marginBottom={0} marginTop={24} mobileMarginTop={24} legendMarginBottom={8} fontSize={18} name={`Minimum Scheduled Time Blocks`}>
            <div className={`${namespace()}--field-label`}>A warning will be shown if there are any classes in timeblocks up to and including this timeblock that are available and don’t conflict with the currently selected classes.
            </div>
            {isUsingNames() && <div className={`${namespace()}--field-label`}><br />For individual classes (selected under specific participants), if this feature is used the system will also require that each new participant has been scheduled for at least one class at checkout. The minimum timeblock won’t be enforced, just whether or not the participant has registered for any classes.</div>}
            {isUsingNumbers() && <div className={`${namespace()}--field-label`}><br />For group classes, this setting is not enforced, and will just show the warning.</div>}
          </FieldSet>
          {isUsingNames() && <Row marginTop={16}>
            <Column marginRight={24} mobileMarginRight={0} span={6} mobileSpan={12}>
              <TextField
                label='Youth'
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypeClassScheduling}
                onBlur={reduxActions.updateEventTypeClassSchedulingValue}
                validationRules={ValidationRules.MinimumScheduledTimeBlocks_Youth}
                value={ActiveForm.MinimumScheduledTimeBlocks_Youth}
              />
            </Column>
            <Column marginRight={0} mobileMarginTop={8} span={6} mobileSpan={12}>
              <TextField
                label='Adults'
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypeClassScheduling}
                onBlur={reduxActions.updateEventTypeClassSchedulingValue}
                validationRules={ValidationRules.MinimumScheduledTimeBlocks_Adults}
                value={ActiveForm.MinimumScheduledTimeBlocks_Adults}
              />
            </Column>
          </Row>}
          {isUsingNumbers() && <Row marginTop={isUsingNames() ? 8 : 16}>
            <Column span={6} mobileSpan={12}>
              <TextField
                label='Group Classes'
                disabled={this.isFormDisabled()}
                onChange={reduxActions.simpleUpdateEventTypeClassScheduling}
                onBlur={reduxActions.updateEventTypeClassSchedulingValue}
                validationRules={ValidationRules.MinimumScheduledTimeBlocks_Group}
                value={ActiveForm.MinimumScheduledTimeBlocks_Group}
              />
            </Column>
          </Row>}
        </FieldSet>
      </section>
      );
  };

  showGeneralSection = () => {
    return !this.props.showSection || this.props.showSection === EventTypeFormSection.GENERAL;
  };

  showPricing1Section = () => {
    return !this.props.showSection || this.props.showSection === EventTypeFormSection.PRICING1;
  };

  showPricing2Section = () => {
    return !this.props.showSection || this.props.showSection === EventTypeFormSection.PRICING2;
  };

  showPricing3Section = () => {
    return !this.props.showSection || this.props.showSection === EventTypeFormSection.PRICING3;
  };

  showPricing4Section = () => {
    return !this.props.showSection || this.props.showSection === EventTypeFormSection.PRICING4;
  };

  showPricing5Section = () => {
    return !this.props.showSection || this.props.showSection === EventTypeFormSection.PRICING5;
  };

  showNamedParticipantsSection = () => {
    return !this.props.showSection || this.props.showSection === EventTypeFormSection.NAMED_PARTICIPANTS;
  };

  showGroupRegistrationSection = () => {
    return !this.props.showSection || this.props.showSection === EventTypeFormSection.GROUP_REGISTRATION;
  };

  showClassSchedulingSection = () => {
    return !this.props.showSection || this.props.showSection === EventTypeFormSection.CLASS_SCHEDULING;
  };

  getSubmitErrorMessage = () => {
    const { formState, action } = this.props;

    if (action === 'edit' && formState.EventTypeRow.SubmitErrorMessage && formState.EventTypeRow.SubmitErrorMessage !== M.SUBMIT_ERROR) return formState.EventTypeRow.SubmitErrorMessage;
    if (formState.General.SubmitErrorMessage && formState.General.SubmitErrorMessage !== M.SUBMIT_ERROR) return formState.General.SubmitErrorMessage;
    if (action === 'edit' && formState.Pricing1.SubmitErrorMessage && formState.Pricing1.SubmitErrorMessage !== M.SUBMIT_ERROR) return formState.Pricing1.SubmitErrorMessage;
    if (action === 'edit' && formState.Pricing2.SubmitErrorMessage && formState.Pricing2.SubmitErrorMessage !== M.SUBMIT_ERROR) return formState.Pricing2.SubmitErrorMessage;
    if (action === 'edit' && formState.Pricing3.SubmitErrorMessage && formState.Pricing3.SubmitErrorMessage !== M.SUBMIT_ERROR) return formState.Pricing3.SubmitErrorMessage;
    if (action === 'edit' && formState.Pricing4.SubmitErrorMessage && formState.Pricing4.SubmitErrorMessage !== M.SUBMIT_ERROR) return formState.Pricing4.SubmitErrorMessage;
    if (action === 'edit' && formState.Pricing5.SubmitErrorMessage && formState.Pricing5.SubmitErrorMessage !== M.SUBMIT_ERROR) return formState.Pricing5.SubmitErrorMessage;
    if (action === 'edit' && formState.ClassScheduling.SubmitErrorMessage && formState.ClassScheduling.SubmitErrorMessage !== M.SUBMIT_ERROR) return formState.ClassScheduling.SubmitErrorMessage;
    if (action === 'edit' && formState.GroupAndRegistration.SubmitErrorMessage && formState.GroupAndRegistration.SubmitErrorMessage !== M.SUBMIT_ERROR) return formState.GroupAndRegistration.SubmitErrorMessage;

    let participantTypeErrorMessage;

    if (action === 'edit' && formState.Pricing4.ActiveForm.ParticipantTypes) {
      formState.Pricing4.ActiveForm.ParticipantTypes.forEach((pt) => {
        participantTypeErrorMessage = participantTypeErrorMessage || pt.SubmitErrorMessage;
      });

      if (participantTypeErrorMessage && participantTypeErrorMessage !== M.SUBMIT_ERROR) return participantTypeErrorMessage;
    }

    return action === 'edit' ? (formState.General.SubmitErrorMessage || formState.Pricing1.SubmitErrorMessage || formState.Pricing2.SubmitErrorMessage || formState.Pricing3.SubmitErrorMessage || formState.Pricing4.SubmitErrorMessage || participantTypeErrorMessage || formState.Pricing5.SubmitErrorMessage || formState.ClassScheduling.SubmitErrorMessage || formState.GroupAndRegistration.SubmitErrorMessage) : formState.General.SubmitErrorMessage;
  };

  public render() {
    const {onSave, onDelete, inert, action, reduxActions, formState: { EventTypeRow, General: {ValidationRules: GeneralValidationRules, ActiveForm: GeneralActiveForm} }, loading, selectedRegistrationMethod, selectedRegistrationMethodInNew, hideFormAction, handleCompact, adminEventsCacheOne} = this.props;

    const registrationMethod = action === 'add' ? selectedRegistrationMethodInNew : selectedRegistrationMethod;

    const SubmitErrorMessage = this.getSubmitErrorMessage();
    return (
        <Main inert={inert}>
          <MainContent loading={loading} handleCompact={handleCompact}>
            <Form
              className={`${namespace()}`}
              onSubmit={e => e.preventDefault()}
              actions={
                hideFormAction ? null : (action === 'add' ? (
                  <FormActions><Button flat textColor="black" big onClick={onSave}>CREATE</Button></FormActions>
                ) : this.props.formActions)
              }
            >
              {action === 'edit' && EventTypeRow.ActiveForm.Inactive ? <Alert className={`${namespace()}--alert`}>{DELETED_ADMIN_EVENT_TYPE}</Alert> : null}
              {action === 'edit' && adminEventsCacheOne.EventsEventType && adminEventsCacheOne.EventsEventType.Permissions.hasEditEventTypeReason ? <Alert className={`${namespace()}--alert`}>{adminEventsCacheOne.EventsEventType.Permissions.hasEditEventTypeReason}</Alert> : null}
              {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
              {!SubmitErrorMessage && action === 'add' && <Alert className={`${namespace()}--prompt`}>Once saved, more settings for this Event Type will be available under the Settings tab</Alert>}
              {this.showGeneralSection() && <Row marginTop={action === 'add' ? 8 : 0} marginBottom={24} mobileMarginBottom={16}>
                <Column span={12} style={{display: 'flex', flexDirection: 'column'}}>
                  <div className={`${namespace()}--label`}>Registration Method</div>
                  <div className={`${namespace()}--selected-method-card`}>
                    <div className={`${namespace()}--selected-method-card--name`}>{registrationMethod ? registrationMethod.Name : ''}</div>
                    <div className={`${namespace()}--selected-method-card--desc`}>{registrationMethod ? registrationMethod.Description : ''}</div>
                    {action === 'edit' ? null : <ActionButton
                      onClick={() => reduxActions.updateEventTypeGeneralValue(undefined, GeneralValidationRules.RegistrationMethodID)}
                      className={`${namespace()}--selected-method-card--close`}
                      icon={CloseIcon}
                    />}
                  </div>
                  <div className={`${namespace()}--helper-text bold`}>{this.getRegistrationMethodHelperText()}</div>
                </Column>
              </Row>}
              {!this.showGeneralSection() ? null : this.renderGeneralSection()}
              {action === 'add' ? null : this.renderPricingSection()}
              {action === 'add' || !this.showGroupRegistrationSection() ? null : this.renderGroupRegistration()}
              {action === 'add' || !this.showNamedParticipantsSection() ? null : this.renderNamedParticipants()}
              {action === 'add' || !this.showClassSchedulingSection() ? null : this.renderClassScheduling()}
            </Form>
          </MainContent>
        </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedLocationSelector = makeSelectedLocationSelector();
  const selectedRegistrationMethodSelector = makeSelectedRegistrationMethodSelector();
  const selectedMessageSelector = makeSelectedMessageSelector();
  const pricingTiersSelector = makePricingTiersSelector();
  const selectedEventCategorySelector = makeSelectedEventCategory();

  return {
    adminEventsCacheOne: state.adminEvents.cacheOne,
    selectedLocation: selectedLocationSelector(state),
    selectedRegistrationMethod: selectedRegistrationMethodSelector(state),
    selectedMessage: selectedMessageSelector(state),
    pricingTiers: pricingTiersSelector(state),
    selectedEventCategory: selectedEventCategorySelector(state),
    systemSettings: state.session.SystemSettings,
    cacheZero: state.cacheZero,
    apiSaving: state.app.apiSaving,
  };
};

const ConnectedEventTypeForm = connect(
  mapStateToProps,
  null,
  getMergeProps<Props>(),
)(EventTypeForm);

export default ConnectedEventTypeForm;
