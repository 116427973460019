
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, PageLoader, EmptyMessage, Button,
} from '../../../../../Elements';
import { actionCreators } from "../../../../../../store/AdminEvents/MessageCenter/Modals/SelectMessage/actions";
import '../../../../../../styles/pages/admin-events/message-center/modals/select-message/index.scss';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../store/Rollback/actions';
import { actionCreators as eventTypeFormActionCreators } from '../../../../../../store/AdminEvents/EventTypes/EventType/Form/actions';
import { EventTypeGeneralFormDefinition } from '../../../../../../store/AdminEvents/EventTypes/EventType/Form/validation';
import { actionCreators as cacheOneActionCreators, GetEventTypeCacheAction } from '../../../../../../store/AdminEvents/CacheOne/actions';
import { ApplicationState } from '../../../../../../store';
import { ModalHeight } from '../../../../../Elements/Modal';
import { CardCategory } from '../../../../../Elements/Card';
import { extractRouteParams } from '../../../../../../utils/urlHelper';
import { EmptyMessageType } from '../../../../../../components/Elements/EmptyMessage';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { makeFilteredMessagesSelector } from '../../../../../../store/AdminEvents/MessageCenter/Modals/SelectMessage';
import { AdminEventsEmailMessage } from '../../../../../../models/api/adminEventsCacheOne';
import { EmailIcon } from '../../../../../Icons';
import { ADMIN_EMAIL_MESSAGES } from '../../../../../../constants/messages/adminEvents';
import AdminMessageCard, { AdminMessageCardType } from '../../../../../Elements/AdminMessage/Card';
import { checkAdminEventMessagePermission } from '../../../../../../utils/helpers/adminEventsPageHelper';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--modal--select-message';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> &RouteComponentProps<{}, {}>;
type Props = WithInertAttribute<{}>

@(withRouter as any)
class SelectMessageModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;
  private modalContentRef;
  
  componentWillUnmount() {
    this.onClose();
  }

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    if (this.props.selectMessageModal.filterString) {
      this.scrollToTop();
    }
    this.props.actions.toggleFilter(false);
    this.props.actions.filterMessages('');
  };
  onAddClick = () => {
    const {actions, cacheOne} = this.props;

    checkAdminEventMessagePermission(
      () => {
        actions.pushFormModal(ModalTypes.MESSAGE_FORM, false);
      },
      cacheOne,
      false,
      false,
      true,
    );
  };
  onRefreshClick = () => {
    const {actions} = this.props;
    actions.saveState();
    actions.refresh(
      {
        MaxMessageID: 0,
        ...extractRouteParams(this.props),
      },
      // Passing false here because its refreshing cache one in a modal, it will
      // set `skipUpdateForm` flag in extra so that it will skip
      // updating the event type form after load completes, in `epicCreator`
      false,
    );
  };

  itemRenderer = (index) => {
    return (
      null
    );
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };

  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filterMessages(val);
  };
  onClickMessage = (message: AdminEventsEmailMessage) => {
    this.props.eventTypeFormActions.updateEventTypeGeneralValue(message.ID, EventTypeGeneralFormDefinition.NewRegistrationMessageID);
    this.onClose();
    this.props.actions.popModal(false, true, ModalTypes.SELECT_MESSAGE);
  };

  onClose = () => {
    this.props.actions.reset();
  };

  renderEmptyMessage = () => {
    return (
      <EmptyMessage
        icon={EmailIcon}
        admin
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={ENTITY_NOT_FOUND(ADMIN_EMAIL_MESSAGES)}
        actions={<Button className={`${namespace()}--empty-message-btn`} color='white' textColor='black' onClick={this.onAddClick}>NEW MESSAGE</Button>}
      /> 
    );
  };

  onEditMessageClick = (message: AdminEventsEmailMessage) => {
    const {actions, cacheOne} = this.props;

    checkAdminEventMessagePermission(
      () => {
        actions.pushFormModal(ModalTypes.MESSAGE_FORM, true, {id: message.ID});
      },
      cacheOne,
      true,
      false,
      true,
    );
  };

  public render() {
    const { apiLoading, apiLoadingMap, selectMessageModal: { isTextSearching }, filteredMessages, inert, cacheOne, actions, apiSaving, eventTypeForm} = this.props;
    const subtitle = eventTypeForm.General.ActiveForm.Name || 'Edit Event Type';
    const loading = apiLoadingMap[GetEventTypeCacheAction.requestType];
    return (
      <Modal inert={inert} height={ModalHeight.HEIGHT_425} onClose={this.onClose} big mobileFullScreen className={namespace()}>
        <ModalHeader
          isSearching={isTextSearching}
          onAdd={this.onAddClick}
          onRefresh={this.onRefreshClick}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={this.onSearchChange}
          onSearchBack={this.onSearchBack}
          subtitle={subtitle}
          className={`${namespace()}--header`}>
          Select Message
        </ModalHeader>

        <ModalContent refCallback={(ref) => this.modalContentRef = ref} paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0} >
          {apiLoading === 0 && !loading && filteredMessages.length > 0 && filteredMessages.map((m) => {
            return (
              <AdminMessageCard
                key={`message_card${m.ID}`}
                type={AdminMessageCardType.SELECT_LIST}
                category={CardCategory.LIST_MOBILE}
                message={m}
                onEdit={this.onEditMessageClick}
                onClick={this.onClickMessage}
              />
            );
          })}
          {apiLoading === 0 && !loading && filteredMessages.length === 0 && this.renderEmptyMessage()}
          {apiLoading > 0 && loading && <PageLoader className={`${namespace()}--page-loader`}/>}
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredMessagesSelector = makeFilteredMessagesSelector();

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    cacheOne: state.adminEvents.cacheOne,
    selectMessageModal: state.adminEvents.messageCenter.modals.selectMessage,
    filteredMessages: filteredMessagesSelector(state),
    eventTypeForm: state.adminEvents.eventTypes.eventType.form,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...cacheOneActionCreators,
    ...rollbackActionCreators,
  }, dispatch),
  eventTypeFormActions: bindActionCreators({
    ...eventTypeFormActionCreators,
  }, dispatch),
});

const ConnectedSelectMessageModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(SelectMessageModal);

export default ConnectedSelectMessageModal;