import * as React from 'react';
import {
  SideModalHeader, SideModalContent, SideModalContentPadding, Button, Row, Column, Title, Separator, Text,
  Media, PageLoader, SideModal, Paragraph
} from '../../../Elements';
import {withRouter} from 'react-router';
import {navPush} from "../../../../utils/navHelper";
import {URLS} from "../../../../constants/urls";
import {ConnectedProps} from "../Orders";
import {GetPrevOrder} from "../../../../store/CacheThreePrevOrders/actions";
import {SIMPLE_FORMAT} from "../../../../utils/dateHelper";
import {standardCurrencyFormat} from "../../../../utils/classesHelper";
import {PreviousOrder, PreviousOrderItem} from "../../../../models/api/cacheTwoPrevOrders";
import {checkPermission} from "../../../../utils/permissionHelper";
import { SaveState } from '../../../../store/Rollback/actions';
import { ClearCacheBelowTwoPrevOrders } from '../../../../store/CacheTwoPrevOrders/actions';
import { isPathUnderCacheTwoPreviousOrders } from '../../../../utils/helpers/endUserPageHelper';
import { ClearCacheBelowOne } from '../../../../store/CacheOne/actions';
import { shouldReconfigRouter } from '../../../../utils/cacheLoaders/reloaderHelper';
import "../../../../styles/pages/settings/order/index.scss";
import { reduxStoreService } from '../../../../store/service';
import {isCacheThreePrevOrdersPopulated, isCacheTwoPrevOrdersPopulated} from '../../../../utils/cachePopulatedCheckers/endUser';
import { getDomain } from '../../../../utils/urlHelper';

export const namespace = (): string => 'pages--settings--order';

export interface OrderProps {
  id: string;
  /**
   * Whether or not we are showing a loader in order list
   */
  showLoaderInList?: boolean;
}

// based off https://github.com/mgroger/Camps/blob/master/org/echockotee/camps/model/ModelLocator.as#L1774
export const getItemName = (item: PreviousOrderItem) => {
  const {ItemType, Name, Event, EventIDi, TimeBlock, IsIndividualRegistration, ClassName, ParticipantType, Quantity} = item;
  switch (ItemType) {
    case "Product":
      let name = `Product: ${Name}`;
      if (EventIDi && EventIDi > 0) {
        name += ", "+ Event;
      }
      if (Quantity) {
        name = `${name}, Quantity: ${Quantity}`;
      }
      return name;
    case "ClassChange":
      let nameForClass = ClassName +((TimeBlock < 100)?" ("+TimeBlock.toString()+")":"") +
        ((IsIndividualRegistration) ? " - "+ Name : "");

      if (EventIDi && EventIDi > 0) {
        nameForClass += ", "+ Event;
      }
      return nameForClass;
    case "YouthChange":
      return ParticipantType +": "+ Name +", "+ Event;
    case "AdultChange":
      return ParticipantType +": "+ Name +", "+ Event;
    case "Base":
      return "Registration: "+ Event;
    default:
      //Trip
      return "Trip: "+ Name;
  }
};

@(withRouter as any)
class Order extends React.Component<OrderProps, {}> {
  public props: OrderProps & ConnectedProps;
  private nextLocation;

  private configRouter() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  componentDidMount() {
    this.configRouter();
  }

  componentDidUpdate(prevProps: ConnectedProps & OrderProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
  }

  routerWillLeave = (nextLocation) => {
    const rootState = reduxStoreService().getState();

    this.nextLocation = nextLocation;
    if (rootState.app.apiLoading === 0) {
      if (isCacheThreePrevOrdersPopulated(rootState.cacheThreePrevOrders)) {
        reduxStoreService().dispatch(new SaveState());
      }
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentWillUnmount() {
    const rootState = reduxStoreService().getState();

    if (
      this.nextLocation &&
      !isPathUnderCacheTwoPreviousOrders(this.nextLocation.pathname) &&
      isCacheTwoPrevOrdersPopulated(rootState.cacheTwoPrevOrders)
    ) {
      reduxStoreService().dispatch(new ClearCacheBelowOne());
    } else if (isCacheThreePrevOrdersPopulated(rootState.cacheThreePrevOrders)) {
      reduxStoreService().dispatch(new ClearCacheBelowTwoPrevOrders());
    }
    this.resetRouteLeaveHook();
  }

  onBack = () => navPush(this.props.router, URLS.ORDER_HISTORY);
  onManage = () => {
    const {cacheZero: {options}, actions} = this.props;
    checkPermission(
      () => actions.showManageOrder(true, true),
      options!.GeneralPermissions!.hasEditPreviousOrder,
      options!.GeneralPermissions!.hasEditPreviousOrderReason
    );
  };

  // based off https://github.com/mgroger/Camps/blob/master/org/echockotee/camps/views/ManageOrders.mxml#L352
  viewPaymentTypeDetails = (): string => {
    const {cacheThreePrevOrders: {PreviousOrder}} = this.props;
    const {PaymentType, PaymentTypeID, ReceiptNum} = PreviousOrder as PreviousOrder;
    let paymentTypeDetails: string = PaymentType;
    if ((PaymentTypeID == 4 || PaymentTypeID == 11) && ReceiptNum.length > 0) {
      paymentTypeDetails = paymentTypeDetails +", "+ ReceiptNum;
    }
    return paymentTypeDetails;
  };

  onGenerateReceipt = () => {
    const {cacheThreePrevOrders: {PreviousOrder}} = this.props;
    window.open(`${getDomain(true)}/receipt.cfm?GroupOrder=${PreviousOrder!.OrderID}`);
  };

  public render() {
    const {user, apiLoadingMap, id, showLoaderInList, cacheThreePrevOrders: {PreviousOrder, PreviousOrderItems}} = this.props;
    const isAdmin = user.user && user.user.str_permissions.hasAdminAccess;
    const loading = apiLoadingMap[GetPrevOrder.requestType];
    if (!PreviousOrder) return null;
    let appliedCredit = 0;
    if (PreviousOrder.AppliedCredit) {
      appliedCredit = PreviousOrder.AppliedCredit;
    }
    const showItems = PreviousOrderItems !== null && PreviousOrderItems && PreviousOrderItems.length > 0;
    return (
      <SideModal
        ensureVisibility={true}
        className={`${showLoaderInList ? 'loading' : ''}`}
        onClick={(e) => e.stopPropagation()}
        stickyHeader
        header={
        <SideModalHeader
          title={`Order # ${PreviousOrder.OrderID}`}
          controls={(isAdmin &&
            <Button disabled={loading || showLoaderInList} color="white" admin onClick={this.onManage}>
              MANAGE
            </Button>
          )}
          onBack={this.onBack}
        />
        }
      >
        <SideModalContent lockBodyScroll>
          <SideModalContentPadding>
            <Row marginBottom={8}>
              <Column expand><Text color="gray" size={15}>Date</Text></Column>
              <Column><Text size={15}>{PreviousOrder.DatePayment.format(SIMPLE_FORMAT)}</Text></Column>
            </Row>
            <Row marginBottom={8}>
              <Column expand><Text color="gray" size={15}>Payment Method</Text></Column>
              <Column><Text size={15}>{this.viewPaymentTypeDetails()}</Text></Column>
            </Row>

            {PreviousOrder.Notes && <Row>
              <Column><Text color="gray" size={15}>Notes</Text></Column>
            </Row>}
            {PreviousOrder.Notes && <Row>
              <Column><Paragraph size={15} preWrap>{PreviousOrder.Notes}</Paragraph></Column>
            </Row>}

            {(isAdmin &&
              <Media mobile>
                <Row marginTop={16}>
                  <Button disabled={loading || showLoaderInList} color="white" admin onClick={this.onManage}>
                    MANAGE
                  </Button>
                </Row>
              </Media>
            )}
          </SideModalContentPadding>
          <Separator/>

          <SideModalContentPadding>
            <Title>Financial Summary</Title>
            <Row marginBottom={8}>
              <Column expand><Text size={15}>Total Items</Text></Column>
              <Column><Text size={15} weight="medium">{standardCurrencyFormat(PreviousOrder.Amount + appliedCredit - PreviousOrder.NotApplied)}</Text></Column>
            </Row>
            {!!PreviousOrder.NotApplied && <Row marginBottom={8}>
              <Column expand><Text size={15}>Unapplied</Text></Column>
              <Column><Text size={15} weight="medium">{standardCurrencyFormat(PreviousOrder.NotApplied)}</Text></Column>
            </Row>}
            {!!appliedCredit && <Row marginBottom={8}>
              <Column expand><Text size={15}>Applied Credit</Text></Column>
              <Column><Text size={15} weight="medium">{standardCurrencyFormat(appliedCredit * -1)}</Text></Column>
            </Row>}
            <Row marginBottom={24}>
              <Column expand><Text size={18} weight="bold">Total Payment</Text></Column>
              <Column><Text size={18} weight="bold">{standardCurrencyFormat(PreviousOrder.Amount)}</Text></Column>
            </Row>
            <Row>
              <Button color="white" disabled={showLoaderInList || loading} onClick={this.onGenerateReceipt}>GENERATE RECEIPT</Button>
            </Row>
          </SideModalContentPadding>
          {showItems && <Separator/>}

          {showItems && <SideModalContentPadding>
            <Title num={PreviousOrder.NumItems}>Items</Title>
            {this.renderItems()}
          </SideModalContentPadding>}
        </SideModalContent>
        {loading && <PageLoader className={`${namespace()}--loader`} />}
      </SideModal>
    );
  }



  private renderItems = () => {
    const { cacheThreePrevOrders: {PreviousOrder, PreviousOrderItems} } = this.props;

    if (!PreviousOrderItems) return null;

    const ret: Array<any> = [];

    PreviousOrderItems.forEach((item: PreviousOrderItem, index) => {
      ret.push(<Row marginBottom={8} key={`item-row-${index}`}>
        <Column expand><Text size={15}>{getItemName(item)}</Text></Column>
        <Column><Text size={15} weight="medium">{standardCurrencyFormat(item.Amount)}</Text></Column>
      </Row>);
    });

    return ret;
  };
}

export default Order;
