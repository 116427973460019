import * as React from 'react';
import { namespace as ns } from './constants';
import '../../../styles/elements/step-progress-bar/step.scss';
import CheckMark from '../../../images/elements/step-progress-bar/checkmark.svg';

export const namespace = (): string => `${ns()}--step`;

export interface StepProps {
  children?: React.ReactNode;
  num?: number;
  selected?: boolean;
  done?: boolean;
  /**
   * Whether the num circle is large or not
   */
  largeNum?: boolean;
  /**
   * Alignment for the num/checkmark
   */
  numAlignment?: 'start' | 'center' | 'end';
  /**
   * Whether the step is rendered in vertical direction or not
   */
  vertical?: boolean;
}

const Step: React.SFC<StepProps> = ({ children, num, selected, done, largeNum, numAlignment, vertical }: StepProps): React.ReactElement<StepProps> => {
  if (typeof children === 'string') {
    const parts = children.split(' ');
    children = [
      <span className={`${namespace()}--text`} key={0}>{parts[0]}</span>,
      <span className={`${namespace()}--text`} key={1}>{parts.slice(1).join(' ')}</span>
    ];
  } else {
    children = <span className={`${namespace()}--text`}>{children}</span>;
  }

  let className: string = namespace();
  if (done) className += ' done';
  if (selected) className += ' selected';
  if (numAlignment) className += ` ${numAlignment}`;
  if (vertical) className += ' vertical';

  return (
    <li className={className}>
      <div className={`${namespace()}--num ${largeNum ? 'large' : ''}`}>{done ? <CheckMark className={`${namespace()}--num--check-mark`}/> : num}</div>
      <div className={`${namespace()}--name`}>
        {children}
      </div>
    </li>
  );
};

export default Step;
