import { ActionCreator, ApplicationState } from "../../../..";
import { createValidateActions, createApiValidateActions, createUpdateValueMethod } from "../../../../Validation/actionCreator";
import { typeName, Action } from "../../../../../utils/StrongActions";
import { SaveState } from "../../../../Rollback/actions";

export const SUFFIX = '__EXPORT_PARTICIPANT_CLASS_SCHEDULE';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

@typeName("INIT" + SUFFIX)
export class InitExportParticipantClassScheduleModal extends Action { }

@typeName("RESET" + SUFFIX) export class ResetExportParticipantClassScheduleModal extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  init: (): ActionCreator  => (dispatch) => {
    dispatch(new InitExportParticipantClassScheduleModal());
    dispatch(new SaveState());
  },
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.events.modals.exportParticipantClassSchedule),
  resetExportParticipantClassScheduleModal: (): ActionCreator => dispatch => dispatch(new ResetExportParticipantClassScheduleModal()),
};