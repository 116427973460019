import * as React from 'react';
import { Text, Card, CardTitle, Row, Column, Align, View, FinancialSummaryTotal } from '../../../../../../Elements';
import {OrderedProduct} from "../../../../../../../models/product";
import {standardCurrencyFormat} from "../../../../../../../utils/classesHelper";
import '../../../../../../../styles/pages/events/event/register/numbers/confirmation/productsFSCard.scss';

export const namespace = (): string => 'pages--events--event--register--numbers--confirmation--products';

interface Props {
  products: Array<OrderedProduct>;
}

class ProductsFSCard extends React.Component<Props, {}> {
  public props: Props;

  productPiecesText = (p: OrderedProduct) => `${p.Quantity} pc${p.Quantity === 1 ? '' : 's'}.`;
  productTotalAmount = (p: OrderedProduct) => {
    const quantity = p.Quantity === undefined ? 1 : p.Quantity;
    return p.Amount * quantity;
  };

  public render() {
    const { products } = this.props;

    let totalAmount = 0;
    products.forEach(prod => {
      totalAmount += this.productTotalAmount(prod);
    });

    return (
      <Card className={`${namespace()}`} template="mobile-stretch">
        <CardTitle>New Product Orders</CardTitle>
        <View layout="vertical" className={`${namespace()}--section`}>
          {products.map((product: OrderedProduct, index) => {
            return (
              <Row className={`${namespace()}--product`} marginBottom={index === products.length - 1 ? 16 : 8}>
                <Column className={`${namespace()}--column`} expand overflowHidden>
                  <Row>
                    <Text size={15}>{product.Name}</Text>
                  </Row>
                  <Row>
                    <Text size={14} color="gray">{this.productPiecesText(product)}</Text>
                  </Row>
                </Column>
                <Column>
                  <Align end>
                    <Text size={15} weight="medium">{standardCurrencyFormat(this.productTotalAmount(product))}</Text>
                  </Align>
                </Column>
              </Row>
            );
          })}
          <FinancialSummaryTotal noMarginBottom title="Total" value={standardCurrencyFormat(totalAmount)}/>
        </View>
      </Card>
    );
  }
}

export default ProductsFSCard;
