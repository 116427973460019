import {makeRequestEpic} from "../../../../Validation/epicCreator";
import {UpdateTimeBlocksActions} from "./actions";
import {AjaxResponse} from "rxjs/ajax";
import { getUpdateAdminEventTimeBlocks } from "../../../../../constants/adminEventsUrls";


export const adminEventsUpdateTimeBlocksEpic = makeRequestEpic(
  UpdateTimeBlocksActions,
  getUpdateAdminEventTimeBlocks,
  undefined,
  true,
  (response: AjaxResponse) => {
    return 'Time blocks were saved.';
  },
);