import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { namespace as ns } from './index';
import '../../../../styles/layouts/main/content/container.scss';

export const namespace = (): string => `${ns()}--container`;

interface Props {
  children?: React.ReactNode;
  className?: string;
  padding?: boolean;
}

class Container extends React.PureComponent<Props, {}> {
  public static defaultProps = {
    padding: true
  };

  public props: Props;

  public render() {
    const { children, ...props } = this.props;
    return (
      <div className={mergeClassNames(namespace(), props)}>
        {children}
      </div>
    );
  }
}

export default Container;
