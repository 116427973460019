import * as M from '../../../../../constants/messages/generic';
import {ActionCreator} from '../../../../';
import {RegisteredClass} from '../../../../../models/class';
import {validateAll} from "../../../../../utils/validator";
import {
  createApiSubmitActions,
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions
} from "../../../../Validation/actionCreator";
import {actionCreators as appActionCreators, ShowTopFloatingAlert} from "../../../../App/actions";
import { captureTentarooError } from '../../../../../utils/dataHelper';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { typeName, Action } from '../../../../../utils/StrongActions';

export const SUFFIX = '__CLASS_OVERRIDE_FEE';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ApiSubmitActions = createApiSubmitActions(SUFFIX);


@typeName("CLASS_OVERRIDE_FEE_SAVE")
export class ClassOverrideFeeSave extends Action {
  constructor(public c: RegisteredClass, public NumOverride: number | undefined, public Amount: number, public AmountMin: number | undefined) { super(); }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  saveOverride: (): ActionCreator => (dispatch, getState) => {
    const {ActiveForm, OverridingClass} = getState().events.event.modals.overrideFee;
    const validationResults = validateAll(s => s.events.event.modals.overrideFee);

    if (!OverridingClass) {
      captureTentarooError(new Error("OverridingClass not presented when trying to save ClassOverrideFee"));
      return;
    }

    // `ActiveForm.Amount` is always shown in the form, so if this is undefined, we want to capture it to Sentry
    if (ActiveForm.Amount === undefined) {
      captureTentarooError(new Error("ActiveForm.Amount is undefined when trying to save ClassOverrideFee"));
      return;
    }
    if (!validationResults) {
      dispatch(new ClassOverrideFeeSave(OverridingClass, ActiveForm.NumOverride, ActiveForm.Amount, ActiveForm.AmountMin));
      dispatch(appActionCreators.popModal(false, true, ModalTypes.OVERRIDE_FEES) as any);
    } else {
      dispatch(ApiSubmitActions.clientFailure(validationResults, true));
      let errorMessage = M.SUBMIT_ERROR;
      if (validationResults.Errors.NumOverride) errorMessage = validationResults.Errors.NumOverride[0];
      else if (validationResults.Errors.Amount) errorMessage = validationResults.Errors.Amount[0];
      else if (validationResults.Errors.AmountMin) errorMessage = validationResults.Errors.AmountMin[0];
      else if (validationResults.Errors.MinLessThanAmount) errorMessage = validationResults.Errors.MinLessThanAmount[0];
      else if (validationResults.Errors.AllowNegative) errorMessage = validationResults.Errors.AllowNegative[0];
      dispatch(new ShowTopFloatingAlert(errorMessage, true, 'orange'));
    }
  },
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.events.event.modals.overrideFee),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
};
