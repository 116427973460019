import {APISuccess} from "../Validation/actionCreator";
import {ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction} from "../App/actions";
import {ClearCacheBelowOne} from "../CacheOne/actions";
import {GetGroupRosterCache,} from "./actions";
import {RestoreGroupRoster} from "../Settings/Roster/Main/actions";
import {
  ApiSubmitActions as RosterAddApiSubmitActions,
  ApiSubmitDeleteActions as RosterApiSubmitDeleteActions
} from "../Settings/Roster/Add/actions";
import {ApiDeleteRosterActions} from "../Events/Event/Register/Participant/Roster/actions";
import {GroupRosterPerson} from "../../models/api/cacheTwoRoster";
import {listSelector} from "../../utils/reselectHelper";
import {GetGroupRosterPerson} from "../CacheThreeRoster/actions";
import { captureTentarooError } from '../../utils/dataHelper';
import { Reducer } from "redux";
import { Action, isActionType } from "../../utils/StrongActions";

export const youthRosterSelector = listSelector(state => state.cacheTwoRoster.GroupRosterYouth);
export const adultRosterSelector = listSelector(state => state.cacheTwoRoster.GroupRosterAdults);

interface CacheTwoRosterCore {
  GroupRosterYouth?: Array<GroupRosterPerson> | null;
  GroupRosterAdults?: Array<GroupRosterPerson> | null;
}

export interface CacheTwoRosterState extends CacheTwoRosterCore {
  showDeletedGroupRoster: boolean;
}

const setCacheTwoData = (data: CacheTwoRosterCore) => {
  return {
    GroupRosterYouth: data.GroupRosterYouth,
    GroupRosterAdults: data.GroupRosterAdults,
    showDeletedGroupRoster: false
  };
};

const handleResponse = (state, responseData) => {
  if (responseData.GroupRosterYouth !== undefined){
    return setCacheTwoData(responseData);
  }
  return state;
};

const getInitialState = () => ({selectedPerson: {IDi: 0, IsYouth: false}});

const CacheTwoRoster: Reducer<CacheTwoRosterState> = (state: CacheTwoRosterState, act: Action) => {


  if (act.type === GetGroupRosterPerson.successType) {
    const successAction = act as APISuccess;
    return handleResponse(state, successAction.response.response);
  } else if (act.type === GetGroupRosterCache.successType) {
    const successAction = act as APISuccess;
    const newState = handleResponse(state, successAction.response.response);
    try {
      const body = JSON.parse(successAction.response.request.body);
      newState.showDeletedGroupRoster = body.IncludeInactive;
    } catch (e) {
      captureTentarooError(new Error('Cannot parse request body from GetGroupRosterPerson.'));
    }
    return newState;
    // return handleResponse(state, successAction.response.response);
    // return {
    //   ...state,
    //   GroupRosterYouth: successAction.response.response.GroupRosterYouth,
    //   GroupRosterAdults: successAction.response.response.GroupRosterAdults
    // };
  } else if (act.type === RestoreGroupRoster.successType) {
    const successAction = act as APISuccess;
    const newState = {...state};
    const person = successAction.response.response.GroupRosterPerson;
    if (person.IsYouth && state.GroupRosterYouth) {
      newState.GroupRosterYouth = [...state.GroupRosterYouth];
      const index = state.GroupRosterYouth.findIndex((p) => p.IDi === person.IDi);
      if (index > -1) {
        newState.GroupRosterYouth[index] = {
          FirstName: person.FirstName,
          IDi: person.IDi,
          Inactive: person.Inactive,
          IsYouth: person.IsYouth,
          LastName: person.LastName,
          MI: person.MI,
          Name: person.Name
        };
      } else {
        newState.GroupRosterYouth.push({
          FirstName: person.FirstName,
          IDi: person.IDi,
          Inactive: person.Inactive,
          IsYouth: person.IsYouth,
          LastName: person.LastName,
          MI: person.MI,
          Name: person.Name
        });
      }
      return newState;
    } else if (!person.IsYouth && state.GroupRosterAdults) {
      newState.GroupRosterAdults = [...state.GroupRosterAdults];
      const index = state.GroupRosterAdults.findIndex((p) => p.IDi === person.IDi);
      if (index > -1) {
        newState.GroupRosterAdults[index] = {
          FirstName: person.FirstName,
          IDi: person.IDi,
          Inactive: person.Inactive,
          IsYouth: person.IsYouth,
          LastName: person.LastName,
          MI: person.MI,
          Name: person.Name
        };
      } else {
        newState.GroupRosterAdults.push({
          FirstName: person.FirstName,
          IDi: person.IDi,
          Inactive: person.Inactive,
          IsYouth: person.IsYouth,
          LastName: person.LastName,
          MI: person.MI,
          Name: person.Name
        });
      }
      return newState;
    } else {
      captureTentarooError(new Error('Did you restore someone successfully who wast on the list?'));
      return state;
    }
  } else if (act.type === RosterAddApiSubmitActions.successType || act.type === RosterApiSubmitDeleteActions.successType) {
    const successAction = act as APISuccess;
    const newState = {...state};
    const person = successAction.response.response.GroupRosterPerson;
    if (person.IsYouth) {
      if (state.GroupRosterYouth && state.GroupRosterYouth.length > 0) {
        const found = state.GroupRosterYouth.findIndex((p) => p.IDi === person.IDi);
        if (found > -1) {
          newState.GroupRosterYouth = [...state.GroupRosterYouth];
          newState.GroupRosterYouth[found] = {...person};
        } else {
          newState.GroupRosterYouth = [{...person}, ...state.GroupRosterYouth];
        }
      } else {
        newState.GroupRosterYouth = [{...person}];
      }
    } else {
      if (state.GroupRosterAdults && state.GroupRosterAdults.length > 0) {
        const found = state.GroupRosterAdults.findIndex((p) => p.IDi === person.IDi);
        if (found > -1) {
          newState.GroupRosterAdults = [...state.GroupRosterAdults];
          newState.GroupRosterAdults[found] = {...person};
        } else {
          newState.GroupRosterAdults = [{...person}, ...state.GroupRosterAdults];
        }
      } else {
        newState.GroupRosterAdults = [{...person}];
      }
    }
    return newState;
  } else if (isActionType(act, SetCacheAction)) {
    return handleResponse(state, act.response.xhr.response);
  } else if (
    isActionType(act, ClearAllCache) || isActionType(act, ClearAllEndUserCacheButOptions) || isActionType(act, ClearCacheBelowOne)
  ) {
    return {...getInitialState()};
  }
  return state || {...getInitialState()};
};

export default CacheTwoRoster;
