import {RestoreGroupRoster} from './actions';
import {makeRequestEpic} from '../../../Validation/epicCreator';
import {updateGroupRosterPerson} from "../../../../constants/urls";
import {RESTORE_ROSTER_SUCCESS} from "../../../../constants/messages/participants";

export const restoreRosterPersonEpic = makeRequestEpic(
  RestoreGroupRoster,
  updateGroupRosterPerson,
  undefined,
  true,
  () => RESTORE_ROSTER_SUCCESS
);
