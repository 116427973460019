import { ValidationResults } from "@tentaroo/core";

import type { ActionCreator, ApplicationState } from '../';
import { SaveState } from '../Rollback/actions';
import { API_REQ, API_SUC, API_FAI, API_CAN, API_PRE, API_UPD } from '../../constants/request';
import {validate, validateAll} from '../../utils/validator';
import {AjaxResponse} from "rxjs/ajax";
import moment from 'moment';
import { PlainRoute } from 'react-router';
import { DATE_PICKER_FORMAT } from '@tentaroo/shared';
import { LoadRequestActionExtra, SubmitActionsExtra } from './actions.types';
import { reduxStoreService } from '../service';
import { Validator } from "../../utils/validator/models";

const VALIDATE = "VALIDATE";
const SUBMIT = 'SUBMIT';
const LOAD_REQ = 'LOADING';
const SAVE_REQ = 'SAVING';

export const CLEAR_CACHE_PREFIX = '$_CLEAR_$';
export const LOAD = '$_APILOAD_$';
export const LOAD_FINISH = '$_APIFINISHLOAD_$';
export const SAVE = '$_APISAVE_$';
export const SAVE_FINISH = '$_APIFINISHSAVE_$';
export const validateUpdateAction = VALIDATE + '_UPDATE_VALUE';
export const validateUpdateCurrAction = VALIDATE + '_UPDATE_CURR_VALUE';
export const POPULATE_ACTION = VALIDATE + '_POPULATE_ACTION';

export const validateRequestAction = API_REQ + VALIDATE;
export const validateSuccessAction = API_SUC + VALIDATE;
export const validateFailureAction = API_FAI + VALIDATE;
export const validateCancelAction = API_CAN + VALIDATE;

export const SUBMIT_REQUEST_ACTION = API_REQ + SUBMIT;
export const SUBMIT_SUCCESS_ACTION = API_SUC + SUBMIT;
export const SUBMIT_FAILURE_ACTION = API_FAI + SUBMIT;
export const SUBMIT_CANCEL_ACTION = API_CAN + SUBMIT;
export const SUBMIT_UPDATE_FORM_ACTION = API_UPD + SUBMIT;
export const SEQUENTIAL_SUBMIT_REQUEST = '$_SEQUENTIAL_$';
export const submitClientFailureAction = 'CLIENT_FAILURE__' + SUBMIT;

export const PRE_REQUEST_ACTION = API_PRE + LOAD_REQ + LOAD;
export const REQUEST_ACTION = API_REQ + LOAD_REQ + LOAD;
export const SUCCESS_ACTION = API_SUC + LOAD_REQ + LOAD_FINISH;
export const FAILURE_ACTION = API_FAI + LOAD_REQ + LOAD_FINISH;
export const CANCEL_ACTION = API_CAN + LOAD_REQ + LOAD_FINISH;
export const UPDATE_FORM_ACTION = API_UPD + LOAD_REQ + LOAD_FINISH;

export const SAVE_PRE_REQUEST_ACTION = API_PRE + SAVE_REQ + SAVE;
export const SAVE_REQUEST_ACTION = API_REQ + SAVE_REQ + SAVE;
export const SAVE_SUCCESS_ACTION = API_SUC + SAVE_REQ + SAVE_FINISH;
export const SAVE_FAILURE_ACTION = API_FAI + SAVE_REQ + SAVE_FINISH;
export const SAVE_CANCEL_ACTION = API_CAN + SAVE_REQ + SAVE_FINISH;
export const SAVE_SET_FORM_ACTION = API_UPD + SAVE_REQ + SAVE_FINISH;

export const DELAY_QUEUE_ACTION = '$DELAY_QUEUE_ACTION$';
export const RX_QUEUE_ACTOIN = '$RX_QUEUE_ACTON$';

export interface DelayQueueActions {
  request: (payload: any) => DelayQueueRequest;
  success: (payload: any) => DelayQueueRequest;
  successType: string;
  requestType: string;
}

export interface DelayQueueRequest {
  type: string;
  payload: any;
}

export const createDelayQueueActions = (actionType: string): DelayQueueActions => {
  return {
    request: (payload): DelayQueueRequest => ({type: `${DELAY_QUEUE_ACTION}${actionType}`, payload}),
    success: (payload: any): DelayQueueRequest =>  ({type: `${DELAY_QUEUE_ACTION}_SUCCESS_${actionType}`, payload}),
    successType: `${DELAY_QUEUE_ACTION}_SUCCESS_${actionType}`,
    requestType: `${DELAY_QUEUE_ACTION}${actionType}`,
  };
};

export interface APIRequestActions<T> {
  request: (value: T, extra?: LoadRequestActionExtra, currentRoute?: PlainRoute<any>) => APIRequest;
  success: (response: AjaxResponse, extra?: LoadRequestActionExtra) => APISuccess;
  failure: (response: AjaxResponse) => APIFailure;
  cancel: (extra?: LoadRequestActionExtra) => APICancel;
  updateForm: (extra?: LoadRequestActionExtra) => APIUpdateForm;
  requestType: string;
  successType: string;
  failureType: string;
  cancelType: string;
  updateFormType: string;
}
export interface APIRequest {
  type: string;
  value: any;
  extra?: LoadRequestActionExtra;
  currentRoute?: PlainRoute<any>;
}
export interface APISuccess {
  type: string;
  response: AjaxResponse;
  extra?: LoadRequestActionExtra;
}
export interface APIFailure {
  type: string;
  response: AjaxResponse;
  extra?: LoadRequestActionExtra;
}
export interface APICancel {
  type: string;
  extra?: LoadRequestActionExtra;
}
export interface APIUpdateForm {
  type: string;
  extra?: LoadRequestActionExtra;
}

export const createRequestActions = <T>(suffix: string, save?: boolean): APIRequestActions<T> => {
  return {
    requestType: `${save ? SAVE_REQUEST_ACTION : REQUEST_ACTION}${suffix}`,
    successType: `${save ? SAVE_SUCCESS_ACTION : SUCCESS_ACTION}${suffix}`,
    failureType: `${save ? SAVE_FAILURE_ACTION : FAILURE_ACTION}${suffix}`,
    cancelType: `${save ? SAVE_CANCEL_ACTION : CANCEL_ACTION}${suffix}`,
    updateFormType: `${save ? SAVE_SET_FORM_ACTION : UPDATE_FORM_ACTION}${suffix}`,
    request: (value: T, extra?: LoadRequestActionExtra, currentRoute?: PlainRoute<any>):APIRequest => ({type: `${save ? SAVE_REQUEST_ACTION : REQUEST_ACTION}${suffix}`, value, extra, currentRoute }),
    success: (response: any, extra?: LoadRequestActionExtra):APISuccess => ({type: `${save ? SAVE_SUCCESS_ACTION : SUCCESS_ACTION}${suffix}`, response, extra }),
    failure: (response: any):APIFailure => ({type: `${save ? SAVE_FAILURE_ACTION : FAILURE_ACTION}${suffix}`, response }),
    cancel: (extra?: LoadRequestActionExtra): APICancel => ({type: `${save ? SAVE_CANCEL_ACTION : CANCEL_ACTION}${suffix}`, extra}),
    updateForm: (extra?: LoadRequestActionExtra): APIUpdateForm => ({ type: `${save ? SAVE_SET_FORM_ACTION : UPDATE_FORM_ACTION}${suffix}`, extra}),
  };
};



export interface ValidateActions {
  request: (vObj: Validator, value: any) => APIRequestValidate;
  success: (vObj?: Validator, response?: any) => APISuccessValidate;
  failure: (vObj: Validator, response: any) => APICancelValidate;
  cancel: (vObj?: Validator, rollback?: boolean) => APICancelSubmit;
  requestType: string;
  successType: string;
  failureType: string;
  cancelType: string;
}

export interface APIRequestValidate {
  type: string;
  vObj: Validator;
  value: any;
}

export interface APISuccessValidate {
  type: string;
  vObj: Validator;
  response: any;
}

export interface APIFailureValidate {
  type: string;
  vObj: Validator;
  response: any;
}

export interface APICancelValidate {
  type: string;
  vObj: Validator;
  rollback?: boolean;
}

export const createApiValidateActions = (suffix: string):ValidateActions => {
  return {
    request: (vObj: Validator, value: any):APIRequestValidate => {
      return {
        type: validateRequestAction + suffix,
        vObj,
        value
      };
    },
    success: (vObj: Validator, response: any):APISuccessValidate => {
      return {
        type: validateSuccessAction + suffix,
        vObj,
        response
      };
    },
    failure: (vObj: Validator, response: any):APIFailureValidate => {
      return {
        type: validateFailureAction + suffix,
        vObj,
        response
      };
    },
    cancel: (vObj: Validator, rollback?: boolean):APICancelValidate => {
      return {
        type: validateCancelAction + suffix,
        vObj,
        rollback
      };
    },
    requestType: validateRequestAction + suffix,
    successType: validateSuccessAction + suffix,
    failureType: validateFailureAction + suffix,
    cancelType: validateCancelAction + suffix
  };
};

export interface SubmitActions {
  request: (form: any, extra: SubmitActionsExtra | null, route?: PlainRoute<any>) => APIRequestSubmit;
  success: (response: AjaxResponse, extra?: SubmitActionsExtra) => APISuccessSubmit;
  failure: (response: AjaxResponse, message?: string, noAlert?: boolean, blockScroll?: boolean) => APIFailureSubmit; // @todo: errors could have a better type
  cancel: (vObj?: Validator) => APICancelSubmit;
  updateForm: (extra?: SubmitActionsExtra) => APIUpdateFormSubmit;
  clientFailure: (errors: any, blockScroll?: boolean, noAlert?: boolean) => ClientFailureSubmit;
  populate: () => Populate;
  requestType: string;
  successType: string;
  failureType: string;
  cancelType: string;
  updateFormType: string;
  clientFailureType: string;
  populateType: string;
}

export interface APIRequestSubmit {
  type: string;
  form: any;
  extra?: SubmitActionsExtra | null;
  currentRoute?: PlainRoute<any>;
}

export interface APISuccessSubmit {
  type: string;
  response: AjaxResponse;
  extra?: SubmitActionsExtra;
}

export interface APIFailureSubmit {
  type: string;
  response: AjaxResponse;
  message?: string;
  noAlert?: boolean;
  blockScroll?: boolean;
  extra?: SubmitActionsExtra;
}

export interface APICancelSubmit {
  type: string;
  vObj?: Validator;
}

export interface APIUpdateFormSubmit {
  type: string;
  extra?: SubmitActionsExtra;
}

export interface Populate {
  type: string;
}

export interface ClientFailureSubmit {
  type: string;
  errors: any;
  blockScroll?: boolean;
  noAlert?: boolean;
}

/**
 * Any request action that could result in 400 response should be created via this action,
 * because we will setup `SubmitErrorMessage` of a form whenever we see a `SUBMIT_FAILURE_ACTION`.
 * @param suffix 
 * @returns 
 */
export const createApiSubmitActions = (suffix, isLoad?: boolean): SubmitActions  => {
  return {
    request: (form: any, extra: SubmitActionsExtra | null, currentRoute?: PlainRoute<any>):APIRequestSubmit => {
      return {
        type: `${SUBMIT_REQUEST_ACTION}${isLoad ? LOAD : SAVE}${suffix}`,
        form,
        extra,
        currentRoute,
      };
    },
    success: (response: AjaxResponse, extra?: SubmitActionsExtra):APISuccessSubmit => {
      return {
        type: `${SUBMIT_SUCCESS_ACTION}${isLoad ? LOAD_FINISH : SAVE_FINISH}${suffix}`,
        response,
        extra
      };
    },
    failure: (response: AjaxResponse, message?: string, noAlert?: boolean, blockScroll?: boolean, extra?: SubmitActionsExtra):APIFailureSubmit => {
      return {
        type: `${SUBMIT_FAILURE_ACTION}${isLoad ? LOAD_FINISH : SAVE_FINISH}${suffix}`,
        response,
        message,
        noAlert,
        blockScroll,
        extra,
      };
    },
    cancel: (vObj?: Validator): APICancelSubmit => {
      return {
        type: `${SUBMIT_CANCEL_ACTION}${isLoad ? LOAD_FINISH : SAVE_FINISH}${suffix}`,
        vObj
      };
    },
    updateForm: (extra?: SubmitActionsExtra): APIUpdateFormSubmit => {
      return {
        type: `${SUBMIT_UPDATE_FORM_ACTION}${isLoad ? LOAD_FINISH : SAVE_FINISH}${suffix}`,
        extra,
      };
    },
    clientFailure: (errors: any, blockScroll?: boolean, noAlert?: boolean):ClientFailureSubmit => {
      return {
        type: submitClientFailureAction + suffix,
        errors,
        noAlert,
        blockScroll
      };
    },
    populate: ():Populate => ({
      type: POPULATE_ACTION + suffix
    }),
    requestType: `${SUBMIT_REQUEST_ACTION}${isLoad ? LOAD : SAVE}${suffix}`,
    successType: `${SUBMIT_SUCCESS_ACTION}${isLoad ? LOAD_FINISH : SAVE_FINISH}${suffix}`,
    failureType: `${SUBMIT_FAILURE_ACTION}${isLoad ? LOAD_FINISH : SAVE_FINISH}${suffix}`,
    cancelType: `${SUBMIT_CANCEL_ACTION}${isLoad ? LOAD_FINISH : SAVE_FINISH}${suffix}`,
    updateFormType: `${SUBMIT_UPDATE_FORM_ACTION}${isLoad ? LOAD_FINISH : SAVE_FINISH}${suffix}`,
    clientFailureType: submitClientFailureAction + suffix,
    populateType: POPULATE_ACTION + suffix
  };
};

export interface UpdateActions {
  update: (errorsAndValues: any, changeKey: string) => UpdateValue;
  updateCurr: (value: any, changeKey: string, vObj: Validator) => UpdateCurrValue;
  updateType: string;
  updateCurrType: string;
}

export interface UpdateValue {
  type: string;
  errorsAndValues: any;
  changeKey: string;
  vObj?: Validator;
}

export interface UpdateCurrValue {
  type: string;
  value: any;
  changeKey: string;
  vObj: Validator;
}

export type WithRootState<A> = A & {rootState: ApplicationState};

export const createValidateActions = (suffix: string):UpdateActions => {
  return {
    update: (errorsAndValues: any, changeKey: string, vObj?: Validator):UpdateValue => {
      return {
        type: validateUpdateAction + suffix,
        errorsAndValues,
        changeKey,
        vObj,
      };
    },
    updateCurr: (value: any, changeKey: string, vObj: Validator):UpdateCurrValue => {
      return {
        type: validateUpdateCurrAction + suffix,
        value,
        changeKey,
        vObj
      };
    },
    updateType: validateUpdateAction + suffix,
    updateCurrType: validateUpdateCurrAction + suffix
  };
};

// TODO: Polish up typing for this function, and also the entire file. using `any` here
// is very dangerous, if wrong value is passed, it could blow up the app
export const createUpdateValueMethod = (
  validateActions,
  apiValidateActions,
  stateSelector,
  save?: boolean,
  shouldSave?: (value: any, vObj: Validator) => boolean,
  saveAfter?: boolean,
  onFail?: (validationResults: ValidationResults, key: string, dispatch) => void,
  vDepValidateActions?: any,
) => {
  return (value: any, vObj: Validator, overrideStateSelector?: any, updateWithVObj?: boolean, vDepStateSelector?: any): ActionCreator => dispatch => {
    let validateVal = value;
    if (moment.isMoment(value)) {
      validateVal = moment(value).format(DATE_PICKER_FORMAT);
    }

    if (save && !saveAfter && (!shouldSave || shouldSave(value, vObj))) dispatch(new SaveState());
    dispatch(validateActions.updateCurr(value, vObj.key, vObj));
    let validationResults = validate(vObj, validateVal, overrideStateSelector ? overrideStateSelector : stateSelector, vDepStateSelector);
    if (moment.isMoment(value)) {
      validationResults.Values[vObj.key] = value;
    }
    // If there's api validation and the client validation passed, then do the api validation
    if (vObj.apiCheck && apiValidateActions) {
      if (!validationResults.Errors[vObj.key]) {
        dispatch(apiValidateActions.request(vObj, value));
      } else {
        // we're supposed to do an api check, but we won't because client failed, make sure spinner/check/X doesn't show
        if (vObj.apiCheck.state !== undefined) dispatch(apiValidateActions.cancel(vObj));
      }
    } else {
      if (vDepValidateActions) {
        dispatch(vDepValidateActions.update(validationResults, vObj.key, updateWithVObj ? vObj : undefined));
      }
    }
    // NOTE: This `validateActions.update` will only update validation results for dependants under the
    // same form. If we have "cross-form" dependants, those dependants' validationResults wont get updated
    dispatch(validateActions.update(validationResults, vObj.key, updateWithVObj ? vObj : undefined));
    
    if (validationResults.Errors[vObj.key]) {
      if (onFail) onFail(validationResults, vObj.key, dispatch);
    }
    if (save && saveAfter && (!shouldSave || shouldSave(value, vObj))) dispatch(new SaveState());
  };
};

export const createSimpleUpdateValueMethod = (validateActions) => {
  return (value: any, vObj: Validator): ActionCreator => dispatch => {
    dispatch(validateActions.updateCurr(value, vObj.key, vObj));
  };
};

export const innerApiSubmitFormMethod = (
  dispatch,
  submitActions: SubmitActions,
  stateSelector,
  createSubmission?: (rootState: ApplicationState, activeForm: any) => any,
  saveOnlyIfValid?: boolean,
  blockScroll?: boolean,
  skipApiCall?: boolean,
  noSaving?: boolean,
  onStart?: () => void,
): boolean => {
  if (onStart) onStart();
  const rootState = reduxStoreService().getState();
  const validationResults = validateAll(stateSelector);
  if (!saveOnlyIfValid && !noSaving) dispatch(new SaveState());
  if (!validationResults) {
    if (saveOnlyIfValid && !noSaving) dispatch(new SaveState());
    if (skipApiCall) return true;
    const activeForm = stateSelector(rootState).ActiveForm;
    let submissionForm = activeForm;
    if (createSubmission) submissionForm = createSubmission(rootState, activeForm);
    dispatch(submitActions.request(submissionForm, null));
    return true;
  } else {
    dispatch(submitActions.clientFailure(validationResults, blockScroll));
    return false;
  }
};

export const createApiSubmitFormMethod = (
  submitActions: SubmitActions,
  stateSelector,
  createSubmission?: (rootState: ApplicationState, activeForm: any) => any,
  saveOnlyIfValid?: boolean,
  blockScroll?: boolean,
  skipApiCall?: boolean,
  noSaving?: boolean,
  onStart?: () => void,
) => {
  return (): ActionCreator => dispatch => {
    innerApiSubmitFormMethod(
      dispatch,
      submitActions,
      stateSelector,
      createSubmission,
      saveOnlyIfValid,
      blockScroll,
      skipApiCall,
      noSaving,
      onStart,
    );
  };
};

export const getCacheLevelExtra = (
  leavingCacheLevelOnSuccess: boolean,
  inMatchingCacheLevelOn409: boolean,
  updatingCacheLevelAndBelow = 2,
): Pick<SubmitActionsExtra, "leavingCacheLevelOnSuccess" | "inMatchingCacheLevelOn409" | "updatingCacheLevelAndBelow"> => {
  return {
    leavingCacheLevelOnSuccess,
    inMatchingCacheLevelOn409,
    updatingCacheLevelAndBelow,
  };
};