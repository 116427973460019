import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../utils/StrongActions';
import { IValidator, FormDefinition } from './validation';
import { SUFFIX } from './actions';
import { apiValidationReducerCreator, shouldSkipUpdateForm } from '../Validation/reducerCreator';
import { ClearAllCache } from '../App/actions';
import {setDefaults} from '../../utils/validator';
import {API_UPD} from '../../constants/request';
import {UPDATE_ADD_GROUP_FORM_SUFFIX} from './constants';
import { WithRootState } from '../Validation/actionCreator';

export interface ActiveForm {
  Username?: string;
  Password?: string;
  ConfirmPassword?: string;
  Email?: string;

  GroupTypeID?: number;
  UnitTypeID?: number;
  UnitGenderID?: number | null;
  Unit?: string;
  CouncilIDi?: number;
  DistrictIDi?: number;

  Suffix?: string;
  FirstName?: string;
  LastName?: string;

  PhoneNumber?: string;
  PhoneNumber2?: string; // alternate phone number
  Address?: string;
  Address2?: string;
  StateID?: number;
  City?: string;
  Zip?: string;

  Contact2Name?: string;
  Contact2Phone?: string;
  Contact2Email?: string;

  AllowOnlineBooking?: boolean;
  UnitSponsor?: string; // text; show only for unit (1) max 100
  Organization?: string; // show only if Non-profit (4); max 100
  PricingTypeID?: number; // dropdown; admin visible only; use admin options
  FacilityPricingTypeID?: number; // dropdown; admin visible only; use admin options
  MI?: string; // always show; max 2
  MarketingSource?: string; // how did you hear about us? max 1024
  Inactive?: boolean;
}

export interface AddGroupState {
  ActiveForm: ActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
}

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const getInitialState: () => AddGroupState = () => ({
  ActiveForm: {},
  ValidationRules: { ...FormDefinition }
});

const AddGroup: Reducer<AddGroupState> = (oldState = getInitialState(), action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, action);
  if (action.type.includes(API_UPD) && action.type.includes(UPDATE_ADD_GROUP_FORM_SUFFIX)) {
    if (shouldSkipUpdateForm(action)) return state;

    return setDefaults(action.rootState, getInitialState());
  } else if (isActionType(action, ClearAllCache)) {
    return {
      ...state,
      ActiveForm: {},
      ValidationRules: { ...FormDefinition},
      SubmitErrorMessage: undefined
    };
  }
  return state;
};

export default AddGroup;
