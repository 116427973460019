import type {FacilitiesBlackoutDate, FacilitiesLocation} from "../../models/api/adminFacilitiesCacheOne";
import type {AdminFacilityDetail} from "../../models/api/adminFacilitiesCacheTwoFacility";
import type {AdminFacilityTypeDetail} from "../../models/api/adminFacilitiesCacheTwoFacilityType";
import type {BlackoutDateActiveForm} from "../../store/AdminFacilityLocation/BlackoutDates/Form";
import type {AdminFacilityLocationCacheOneState} from "../../store/AdminFacilityLocation/CacheOne";
import type {AdminFacilityLocationCacheTwoBlackoutDatesState} from "../../store/AdminFacilityLocation/CacheTwoBlackoutDates";
import type {AdminFacilityLocationCacheTwoFacilityState} from "../../store/AdminFacilityLocation/CacheTwoFacility";
import type {AdminFacilityLocationCacheTwoFacilityTypesState} from "../../store/AdminFacilityLocation/CacheTwoFacilityTypes";
import type {FacilityFormDetailActiveForm} from "../../store/AdminFacilityLocation/Facilities/Facility/Form";
import type {FacilityTypeRowActiveForm} from "../../store/AdminFacilityLocation/Facilities/FacilityType/Form";
import type {FacilitiesSettingsActiveForm} from "../../store/AdminFacilityLocation/Settings";
import {reduxStoreService} from "../../store/service";
import {isCacheLevelPopulated} from "./common";


const MIN_REQUIRED_FIELDS_FAC_LOC_BLACKOUT_DATE = 0;
const MIN_REQUIRED_FIELDS_FAC_LOC_FACILITY = 0;
const MIN_REQUIRED_FIELDS_FAC_LOC_FACILITY_TYPE = 0;
const MIN_REQUIRED_FIELDS_FAC_LOC_CACHE_ONE = 0;

// Cache One
export function isAdminFacilitiesLocationCacheOnePopulated(
  adminFacCacheOne?: AdminFacilityLocationCacheOneState,
  settingsActiveForm?: FacilitiesSettingsActiveForm,
) {
  const rootState = reduxStoreService().getState();
  const cacheOne = adminFacCacheOne ? adminFacCacheOne : rootState.adminFacilityLocation.cacheOne;
  return isCacheLevelPopulated<FacilitiesLocation>(
    cacheOne.FacilitiesLocation,
    MIN_REQUIRED_FIELDS_FAC_LOC_CACHE_ONE,
    () => !settingsActiveForm || (!!cacheOne.FacilitiesLocation && settingsActiveForm.ID === cacheOne.FacilitiesLocation.ID),
  );
};

// Cache Two
export function isAdminFacilityLocationCacheTwoBlackoutDatePopulated(cacheTwoBlackoutDates: AdminFacilityLocationCacheTwoBlackoutDatesState, blackoutDateForm?: BlackoutDateActiveForm) {
  return isCacheLevelPopulated<FacilitiesBlackoutDate>(
    cacheTwoBlackoutDates.FacilitiesBlackoutDate,
    MIN_REQUIRED_FIELDS_FAC_LOC_BLACKOUT_DATE,
    () => !blackoutDateForm || (!!cacheTwoBlackoutDates.FacilitiesBlackoutDate && cacheTwoBlackoutDates.FacilitiesBlackoutDate.ID === blackoutDateForm.ID)
  );
};

export function isAdminFacilityLocationCacheTwoFacilityPopulated(cacheTwoFacility: AdminFacilityLocationCacheTwoFacilityState, facilityForm?: FacilityFormDetailActiveForm) {
  return isCacheLevelPopulated<AdminFacilityDetail>(
    cacheTwoFacility.FacilitiesFacility,
    MIN_REQUIRED_FIELDS_FAC_LOC_FACILITY,
    () => !facilityForm || (!!cacheTwoFacility.FacilitiesFacility && cacheTwoFacility.FacilitiesFacility.ID === facilityForm.ID)
  );
};

export function isAdminFacilityLocationCacheTwoFacilityTypePopulated(cacheTwoFacilityTypes: AdminFacilityLocationCacheTwoFacilityTypesState, facilityTypeForm?: FacilityTypeRowActiveForm) {
  return isCacheLevelPopulated<AdminFacilityTypeDetail>(
    cacheTwoFacilityTypes.FacilitiesType,
    MIN_REQUIRED_FIELDS_FAC_LOC_FACILITY_TYPE,
    () => !facilityTypeForm || (!!cacheTwoFacilityTypes.FacilitiesType && facilityTypeForm.ID === cacheTwoFacilityTypes.FacilitiesType.FacilityTypeRow.ID),
  );
}