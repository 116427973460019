import * as React from 'react';
import { bindActionCreators } from 'redux';
import { CacheOneContext } from "@tentaroo/shared";

import { Main, MainContent } from '../../../Layouts';
import {Alert, LoadingAll, Row} from '../../../Elements';
import ProfileForm from './form';
import '../../../../styles/pages/settings/profile.scss';
import {ApplicationState} from "../../../../store/index";
import { actionCreators } from '../../../../store/Settings/Profile/actions';
import {withRouter, RouteComponentProps} from 'react-router';
import {actionCreators as appActionCreators} from "../../../../store/App/actions";
import {actionCreators as cacheOneActionCreators} from "../../../../store/CacheOne/actions";
import {actionCreators as cacheZeroActionCreators} from "../../../../store/CacheZero/actions";
import {actionCreators as editEmailActionCreators} from "../../../../store/Settings/EditEmail/actions";
import { shouldBlockActions } from '../../../../utils/cacheLoaders/helpers/blockers';
import EndUserCacheManager from '../../../../utils/cacheManagers/endUserCacheManager';
import { shouldReconfigRouter } from '../../../../utils/cacheLoaders/reloaderHelper';
import { SaveState } from '../../../../store/Rollback/actions';
import { getMergeProps } from '../../../../utils/reduxHelper';
import { connect } from 'react-redux';
import { reduxStoreService } from '../../../../store/service';
import { ComponentUpdateTemplate } from '../../../Templates/ComponentUpdateTemplate';
import {isEndUserCacheOnePopulated} from '../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../Elements/WithInert';

export const namespace = (): string => 'pages--settings--profile';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {}>
>;

@(withRouter as any)
class Profile extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;

  public componentDidMount() {
    this.configRouteLeaveHook();
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheOne({
          props: this.props,
          isStateNavigated,
          context: CacheOneContext.PROFILE,
        });
      }
    );

    if (shouldBlockActions()) return;
    this.props.actions.resetEditEmail();

  }

  componentWillReceiveProps(nextProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheOne({
          props: nextProps,
          isStateNavigated,
          context: CacheOneContext.PROFILE,
        });
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) {
      this.configRouteLeaveHook();
    }
  }

  componentWillUnmount() {
    this.resetRouteLeaveHook();
  }

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    if (reduxStoreService().getState().app.apiLoading === 0 && isEndUserCacheOnePopulated(this.props.cacheOne)) {
      reduxStoreService().dispatch(new SaveState());
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  public render() {
    const { user, inert, profile, cacheZero, apiSaving, apiLoading, actions, cacheOne } = this.props;
    if (!isEndUserCacheOnePopulated(cacheOne) || !cacheZero.options) return <LoadingAll/>;
    const isAdmin = user.user && user.user.str_permissions.hasAdminAccess;
    const inactive = (cacheZero.options && cacheZero.options.Group) ? cacheZero.options.Group.Inactive : true;
    const hasAccess = cacheZero.options?.GeneralPermissions?.hasGroup;
    
    return (
      <Main inert={inert} mobileBackground="white">
        <MainContent handleCompact>
          {!hasAccess && <Row hardOpacity key="access">
            <Alert>{cacheZero.options?.GeneralPermissions?.hasGroupReason}</Alert>
          </Row>}
          <ProfileForm
            isAdmin={isAdmin}
            ActiveForm={profile.ActiveForm}
            ValidationRules={profile.ValidationRules}
            SubmitErrorMessage={profile.SubmitErrorMessage}
            cacheZero={cacheZero}
            apiSaving={apiSaving}
            apiLoading={apiLoading}
            actions={actions}
            inactive={inactive}
          />
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    user: state.user,
    profile: state.settings.profile,
    cacheZero: state.cacheZero,
    cacheOne: state.cacheOne,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...editEmailActionCreators,
    ...actionCreators,
    ...cacheOneActionCreators,
    ...cacheZeroActionCreators,
    ...appActionCreators,
  }, dispatch)
});
const ConnectedProfile = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(Profile);

export { default as EditPassword } from './ChangePassword';
export { default as EditEmail } from './EditEmail';
export default ConnectedProfile;
