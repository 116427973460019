import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import {
  actionCreators as cacheZeroActionCreators
} from '../../../../../store/CacheZero/actions';
import {
    actionCreators as adminCMSCacheOneActionCreators,
} from '../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { 
  actionCreators as rollbackActionCreators,
} from '../../../../../store/Rollback/actions';
import { 
  actionCreators,
} from '../../../../../store/AdminCMSSite/Settings/General/actions';
import { Card, FieldSet, Row, Column, TextField, Select, RadioGroupOption, Text, Switch, Checkbox, Link, Button, Alert, Media } from '../../../../../components/Elements';
import '../../../../../styles/pages/cms-settings/general.scss';
import { SiteEventCategory } from '../../../../../models/api/adminCMSCacheOne';
import SocialMediaField from '../../../../../components/Elements/SocialMediaField';
import { openSupportForm } from '../../../../../constants/urls';
import { checkPermission } from '../../../../../utils/permissionHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';

export const namespace = (): string => 'pages--cms--settings-general';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {resourceId: string}>;

interface Props {
  disabled?: boolean;
}

class GeneralSettings extends React.Component<Props, {}> {
  public props: ConnectedProps & Props;

  renderContactInfoSection = () => {
    const {disabled, actions, generalSettingsForm: {ActiveForm, ValidationRules}} = this.props;
    return (<Card
  ​      className={`${namespace()}--card`}
  ​      component={<section/>}
  ​      template={'mobile-no-margin'}
        marginTop={24}
        mobileMarginTop={16}
  ​      mobileMarginBottom={0}
  ​      marginBottom={0}
      >
        <FieldSet className='consistent-margin-bottom' legendMarginBottom={0} fontSize={18} name="Contact Info"></FieldSet>
        <Row>
          <Column span={6} mobileSpan={12}>
            <TextField
              label="Contact Name"
              disabled={disabled}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.ContactName}
              value={ActiveForm.ContactName}/>
          </Column>
        </Row>
        <Row>
          <Column span={6} mobileSpan={12}>
            <TextField
              label="Phone"
              disabled={disabled}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.Phone}
              value={ActiveForm.Phone}/>
          </Column>
          <Column span={6} mobileSpan={12}>
            <TextField
              label="Fax"
              disabled={disabled}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.Fax}
              value={ActiveForm.Fax}/>
          </Column>
        </Row>
        <Row>
          <Column span={6} mobileSpan={12}>
            <TextField
              label="Email"
              disabled={disabled}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.Email}
              value={ActiveForm.Email}/>
          </Column>
        </Row>
        <Row>
          <Column span={6} mobileSpan={12}>
            <TextField
              label="Address Line1"
              disabled={disabled}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.Address}
              value={ActiveForm.Address}/>
          </Column>
          <Column span={6} mobileSpan={12}>
            <TextField
              label="Address Line 2"
              disabled={disabled}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.Address2}
              value={ActiveForm.Address2}/>
          </Column>
        </Row>
        <Media tabletAndGreater>
          <Row>
            <Column span={6}>
              <TextField
                label="City"
                disabled={disabled}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules.City}
                value={ActiveForm.City}/>
            </Column>
            <Column span={3}>
              <Select label="State"
                      disabled={disabled}
                      onChangeValue={actions.updateValue}
                      value={ActiveForm.StateID}
                      validationRules={ValidationRules.StateID}
                      isNumber
              />
            </Column>
            <Column span={3}>
              <TextField
                label="Zip Code"
                disabled={disabled}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules.Zip}
                value={ActiveForm.Zip}/>
            </Column>
          </Row>
        </Media>
        <Media mobile style={{flexDirection: 'column'}}>
            <Row>
              <Column mobileSpan={12}>
                <TextField
                  label="City"
                  disabled={disabled}
                  onChange={actions.simpleUpdate}
                  onBlur={actions.updateValue}
                  validationRules={ValidationRules.City}
                  value={ActiveForm.City}/>
              </Column>
            </Row>
            <Row>
              <Column mobileSpan={6}>
                <Select label="State"
                        customizedId={'mobile-elements--select-state'}
                        disabled={disabled}
                        onChangeValue={actions.updateValue}
                        value={ActiveForm.StateID}
                        validationRules={ValidationRules.StateID}
                        isNumber
                />
              </Column>
              <Column mobileSpan={6}>
                <TextField
                  label="Zip Code"
                  disabled={disabled}
                  onChange={actions.simpleUpdate}
                  onBlur={actions.updateValue}
                  validationRules={ValidationRules.Zip}
                  value={ActiveForm.Zip}/>
              </Column>
            </Row>
        </Media>
      </Card>
    );
  };

  renderDonateButtonSection = () => {
    const {disabled, actions, cacheZero, generalSettingsForm: {ActiveForm, ValidationRules}} = this.props;
    return (<Card
  ​      className={`${namespace()}--card`}
  ​      component={<section/>}
  ​      template={'mobile-no-margin'}
        marginTop={24}
        mobileMarginTop={16}
  ​      mobileMarginBottom={0}
  ​      marginBottom={0}
      >
        <FieldSet className='consistent-margin-bottom' legendMarginBottom={0} fontSize={18} name="Donate Button"></FieldSet>
        <Row>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Donate Button Label"
              disabled={disabled || (cacheZero.options && cacheZero.options.GeneralPermissions ? !cacheZero.options.GeneralPermissions.hasTentaroo : true)}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.DonateName}
              value={ActiveForm.DonateName}/>
          </Column>
        </Row>
        <Row>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Donate Button Link"
              disabled={disabled}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.DonateURL}
              value={ActiveForm.DonateURL}/>
          </Column>
        </Row>
        <div className={`${namespace()}--card--field-label`}>Open In</div>
        <Row>
          <Column mobileMarginBottom={0} layout='horizontal' span={6} mobileSpan={12}>
            <RadioGroupOption
              value={!!!ActiveForm.IsDonateNewWindow}
              selected={!!!ActiveForm.IsDonateNewWindow}
              ValidationRules={ValidationRules.IsDonateNewWindow}
              rawValue={false}
              onChange={actions.updateValue}
              disabled={disabled}
              newDesign
              label={<Text color='dark-gray' weight="regular" size={16} marginBottom={0}>Same Window</Text>}
            />
          </Column>
          <Column layout='horizontal' span={6} mobileSpan={12}>
            <RadioGroupOption
              value={!!ActiveForm.IsDonateNewWindow}
              selected={!!ActiveForm.IsDonateNewWindow}
              disabled={disabled}
              ValidationRules={ValidationRules.IsDonateNewWindow}
              rawValue={true}
              onChange={actions.updateValue}
              newDesign
              label={<Text color='dark-gray' weight="regular"  size={16} marginBottom={0}>New Window</Text>}
            />
            
          </Column>
        </Row>
      </Card>
    );
  };

  renderJoinButtonSection = () => {
    const {disabled, actions, cacheZero, generalSettingsForm: {ActiveForm, ValidationRules}} = this.props;
    return (<Card
  ​      className={`${namespace()}--card`}
  ​      component={<section/>}
  ​      template={'mobile-no-margin'}
        marginTop={24}
        mobileMarginTop={16}
  ​      mobileMarginBottom={0}
  ​      marginBottom={0}
      >
        <FieldSet className='consistent-margin-bottom' legendMarginBottom={0} fontSize={18} name="Join Button"></FieldSet>
        <Row>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Join Button Label"
              disabled={disabled || (cacheZero.options && cacheZero.options.GeneralPermissions ? !cacheZero.options.GeneralPermissions.hasTentaroo : true)}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.JoinName}
              value={ActiveForm.JoinName}/>
          </Column>
        </Row>
        <Row>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Join Button Link"
              disabled={disabled}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.JoinURL}
              value={ActiveForm.JoinURL}/>
          </Column>
        </Row>
        <div className={`${namespace()}--card--field-label`}>Open In</div>
        <Row>
          <Column mobileMarginBottom={0} layout='horizontal' span={6} mobileSpan={12}>
            <RadioGroupOption
              value={!!!ActiveForm.IsJoinNewWindow}
              selected={!!!ActiveForm.IsJoinNewWindow}
              ValidationRules={ValidationRules.IsJoinNewWindow}
              rawValue={false}
              onChange={actions.updateValue}
              disabled={disabled}
              newDesign
              label={<Text color='dark-gray' weight="regular" size={16} marginBottom={0}>Same Window</Text>}
            />
          </Column>
          <Column layout='horizontal' span={6} mobileSpan={12}>
            <RadioGroupOption
              value={!!ActiveForm.IsJoinNewWindow}
              selected={!!ActiveForm.IsJoinNewWindow}
              disabled={disabled}
              ValidationRules={ValidationRules.IsJoinNewWindow}
              rawValue={true}
              onChange={actions.updateValue}
              newDesign
              label={<Text color='dark-gray' weight="regular"  size={16} marginBottom={0}>New Window</Text>}
            />
            
          </Column>
        </Row>
      </Card>
    );
  };
  
  onEventCategoryShownOnSiteChange = (category: SiteEventCategory) => {
    this.props.actions.checkEventCategoryShownInSite(category.ID);
  };

  renderCalendarSection = () => {
    const {disabled, actions, adminCMSCacheOne, generalSettingsForm: {ActiveForm, ValidationRules}} = this.props;
    return (<Card
  ​      className={`${namespace()}--card`}
  ​      component={<section/>}
  ​      template={'mobile-no-margin'}
        marginTop={24}
        mobileMarginTop={16}
  ​      mobileMarginBottom={0}
  ​      marginBottom={0}
      >
        <FieldSet className='consistent-margin-bottom' legendMarginBottom={0} fontSize={18} name="Calendar"></FieldSet>
        <Row>
          <Column span={12} mobileSpan={12}>
            <Switch
                disabled={disabled}
                label="Include All Categories in Primary Calendar"
                newDesign
                onChange={actions.updateValue}
                value={!!ActiveForm.IncludeAllCategoriesInCalendar}
                validationRules={ValidationRules.IncludeAllCategoriesInCalendar}
            />
          </Column>
        </Row>
        {!ActiveForm.IncludeAllCategoriesInCalendar && <Row marginTop={16}>
          <Column span={12} mobileSpan={12}>
            <Select label="Primary Calendar Event Category"
                    disabled={disabled}
                    onChangeValue={actions.updateValue}
                    value={ActiveForm.CalendarPrimaryCategoryID}
                    validationRules={ValidationRules.CalendarPrimaryCategoryID}
                    isNumber
            />
          </Column>
        </Row>}
        {ActiveForm.IncludeAllCategoriesInCalendar && <Row>
          <Column span={12} mobileSpan={12}>
            <Switch
                disabled={disabled}
                label="Include Districts in Primary Calendar"
                newDesign
                onChange={actions.updateValue}
                value={!!ActiveForm.IncludeDistrictsInPrimaryCalendar}
                validationRules={ValidationRules.IncludeDistrictsInPrimaryCalendar}
            />
          </Column>
        </Row>}
        <Row>
          <Column span={12} mobileSpan={12}>
            <Switch
                disabled={disabled}
                label="Show Calendar on Homepage"
                newDesign
                onChange={actions.updateValue}
                value={!!ActiveForm.ShowCalendarOnHomepage}
                validationRules={ValidationRules.ShowCalendarOnHomepage}
            />
          </Column>
        </Row>
        <div className={`${namespace()}--card--divider`}></div>
        <FieldSet className='compact-consistent-margin-bottom' legendMarginBottom={0} fontSize={18} name="Event Categories Shown on Site"></FieldSet>
        <div className={`${namespace()}--card--help-text`}>To add additional calendar categories, <Link black medium externalLink={openSupportForm(true)}>contact Tentaroo</Link></div>
        {ActiveForm.SiteEventCategories && ActiveForm.SiteEventCategories.map((c) => {
          return !c.Inactive ? (
            <Row key={`${namespace()}--site-event-category-${c.ID}`}>
              <Column span={12} mobileSpan={12}>
                <Checkbox value={c.ID.toString()}
                          disabled={disabled}
                          selected={c.IsShownOnSite}
                          newDesign
                          label={<Text color='dark-gray' weight="regular"  size={16} marginBottom={0}>{c.Name}</Text>}
                          onChange={() => this.onEventCategoryShownOnSiteChange(c)} />
                
              </Column>
            </Row>
          ) : null;
        })}
        <Row marginTop={24} mobileMarginTop={16}>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Featured Calendar Code"
              rows={5}
              disabled={disabled || (adminCMSCacheOne.CMSSite ? !adminCMSCacheOne.CMSSite.canChangeHTML : true)}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.FeaturedCalendarCodeHTML}
              value={ActiveForm.FeaturedCalendarCodeHTML}/>
          </Column>
        </Row>
      </Card>
    );
  };

  onRemoveSiteSocialType = (siteSocialIndex: number) => {
    this.props.actions.removeSiteSocial(siteSocialIndex);
  };

  onAddSiteSocialType = () => {
    const {adminCMSCacheOne} = this.props;
    if (!adminCMSCacheOne.CMSSite) return;
    checkPermission(
      () => {
        this.props.actions.addSiteSocial();
      },
      adminCMSCacheOne.CMSSite.hasSettingsEdit,
      adminCMSCacheOne.CMSSite.hasSettingsEditReason,
    );
  };

  renderSocialMediaSection = () => {
    const {disabled, actions, generalSettingsForm: {ActiveForm, ValidationRules}} = this.props;
    if (!ActiveForm) return null;
    const socialMedias: any[] = [];
    for (let key of Object.keys(ActiveForm)) {
      if (key.includes("SocialTypeID")) {
        const index = Number(key.replace('SocialTypeID', ''));
        socialMedias.push(
          <Row key={`${namespace()}--ssn-${index}`} className={`${namespace()}--social-media`}>
            <Column span={12} mobileSpan={12}>
              <SocialMediaField
                disabled={disabled}
                onSocialUserChange={actions.simpleUpdate}
                onSocialUserBlur={actions.updateValue}
                onSocialTypeChange={actions.updateSocialType}
                onCancelClick={() => this.onRemoveSiteSocialType(index)}
                iconType='color'
                socialTypeValue={ActiveForm[`SocialTypeID${index}`]}
                socialUserValue={ActiveForm[`SocialUser${index}`]}
                socialUserValidationRule={(ValidationRules as any)[`SocialUser${index}`]}
                socialTypeValidationRule={(ValidationRules as any)[`SocialTypeID${index}`]} />
            </Column>
          </Row>
        );
      }
    }
    return (<Card
  ​      className={`${namespace()}--card`}
  ​      component={<section/>}
  ​      template={'mobile-no-margin'}
        marginTop={24}
        mobileMarginTop={16}
  ​      mobileMarginBottom={0}
  ​      marginBottom={0}
      >
        <FieldSet className='consistent-margin-bottom' legendMarginBottom={0} fontSize={18} name="Social Media"></FieldSet>
        {socialMedias}
        <Button style={{width: '132px', marginTop: '16px'}} onClick={() => this.onAddSiteSocialType()} textColor='black' color='white'>ADD ACCOUNT</Button>
      </Card>
    );
  };

  renderSearchEngineMetadataSection = () => {
    const {disabled, actions, generalSettingsForm: {ActiveForm, ValidationRules}} = this.props;
    return (<Card
  ​      className={`${namespace()}--card`}
  ​      component={<section/>}
  ​      template={'mobile-no-margin'}
        marginTop={24}
        mobileMarginTop={16}
  ​      mobileMarginBottom={0}
  ​      marginBottom={0}
      >
        <FieldSet className='consistent-margin-bottom' legendMarginBottom={0} fontSize={18} name="Search Engine Metadata"></FieldSet>
        <Row>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Title"
              disabled={disabled}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.SEOTitle}
              value={ActiveForm.SEOTitle}/>
          </Column>
        </Row>
        <Row>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Description"
              rows={5}
              disabled={disabled}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.SEODescription}
              value={ActiveForm.SEODescription}/>
          </Column>
        </Row>
        <Row>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Keywords"
              rows={5}
              disabled={disabled}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.SEOKeywords}
              value={ActiveForm.SEOKeywords}/>
          </Column>
        </Row>
      </Card>
    );
  };

  renderGoogleAnalyticsSection = () => {
    const {disabled, actions, generalSettingsForm: {ActiveForm, ValidationRules}} = this.props;
    return (<Card
  ​      className={`${namespace()}--card`}
  ​      component={<section/>}
  ​      template={'mobile-no-margin'}
        marginTop={24}
        mobileMarginTop={16}
  ​      mobileMarginBottom={0}
  ​      marginBottom={0}
      >
        <FieldSet className='consistent-margin-bottom' legendMarginBottom={0} fontSize={18} name="Analytics Tracking Script"></FieldSet>
        <Row>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Enter code"
              rows={5}
              disabled={disabled}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.GoogleAnalytics}
              value={ActiveForm.GoogleAnalytics}/>
          </Column>
        </Row>
      </Card>
    );
  };

  renderMapInFooterSection = () => {
    const {disabled, adminCMSCacheOne, actions, generalSettingsForm: {ActiveForm, ValidationRules}} = this.props;
    return (<Card
  ​      className={`${namespace()}--card`}
  ​      component={<section/>}
  ​      template={'mobile-no-margin'}
        marginTop={24}
        mobileMarginTop={16}
  ​      mobileMarginBottom={0}
  ​      marginBottom={0}
      >
        <FieldSet className='consistent-margin-bottom' legendMarginBottom={0} fontSize={18} name="Map in Footer"></FieldSet>
        <Row>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Enter code"
              rows={5}
              disabled={disabled || (adminCMSCacheOne.CMSSite ? !adminCMSCacheOne.CMSSite.canChangeHTML : true)}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.MapInFooterHTML}
              value={ActiveForm.MapInFooterHTML}/>
          </Column>
        </Row>
      </Card>
    );
  };

  renderSocialWidgetInFooterSection = () => {
    const {disabled, actions, adminCMSCacheOne, generalSettingsForm: {ActiveForm, ValidationRules}} = this.props;
    return (<Card
  ​      className={`${namespace()}--card`}
  ​      component={<section/>}
  ​      template={'mobile-no-margin'}
        marginTop={24}
        mobileMarginTop={16}
  ​      mobileMarginBottom={0}
  ​      marginBottom={0}
      >
        <FieldSet className='consistent-margin-bottom' legendMarginBottom={0} fontSize={18} name="Social Widget in Footer"></FieldSet>
        <Row>
          <Column span={12} mobileSpan={12}>
            <TextField
              label="Enter Code"
              rows={6}
              disabled={disabled || (adminCMSCacheOne.CMSSite ? !adminCMSCacheOne.CMSSite.canChangeHTML : true)}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.SocialWidgetHTML}
              value={ActiveForm.SocialWidgetHTML}/>
          </Column>
        </Row>
      </Card>
    );
  };

  public render() {
    const {adminCMSCacheOne, generalSettingsForm: {SubmitErrorMessage}} = this.props;

    return (
      <div className={`${namespace()}`}>
        {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
        {adminCMSCacheOne.CMSSite && adminCMSCacheOne.CMSSite.hasSettingsEditReason ? <Alert className={`${namespace()}--alert`}>{adminCMSCacheOne.CMSSite.hasSettingsEditReason}</Alert> : null}
        {this.renderContactInfoSection()}
        {this.renderJoinButtonSection()}
        {this.renderDonateButtonSection()}
        {this.renderCalendarSection()}
        {this.renderSocialMediaSection()}
        {this.renderSearchEngineMetadataSection()}
        {this.renderGoogleAnalyticsSection()}
        {this.renderMapInFooterSection()}
        {this.renderSocialWidgetInFooterSection()}
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    cacheZero: state.cacheZero,
    generalSettingsForm: state.adminCMSSite.settings.general,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheZeroActionCreators,
    ...adminCMSCacheOneActionCreators,
    ...appActionCreators,
    ...rollbackActionCreators,
  }, dispatch)
});

const ConnectedGeneralSettings = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(GeneralSettings);

export default withRouter<Props>(ConnectedGeneralSettings);
