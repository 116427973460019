
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';

import {
  Modal, ModalHeader, ModalContent, ModalActions, Button, Row, Select, PageLoader, SimpleList, EmptyMessage
} from '../../../../../../Elements';
import { actionCreators } from '../../../../../../../store/AdminCMSSite/Resources/Modals/MultipleResources/actions';
import { actionCreators as adminCMSCacheTwoResourcesActionCreators } from '../../../../../../../store/AdminCMSSite/CacheTwoResources/actions';
import { actionCreators as adminCMSCacheOneActionCreators, GetSiteCache } from '../../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../../store/Rollback/actions';
import { actionCreators as appActionCreators } from '../../../../../../../store/App/actions';
import { actionCreators as pageFormActionCreators } from '../../../../../../../store/AdminCMSSite/Pages/Page/Form/actions';
import { ApplicationState } from '../../../../../../../store';
import '../../../../../../../styles/pages/resources/modals/multiple-resources/index.scss';
import { makeFilteredResourcesSelector } from '../../../../../../../store/AdminCMSSite/Resources/Modals/MultipleResources';
import { CMSResource } from '../../../../../../../models/api/adminCMSCacheOne';
import { extractRouteParams } from '../../../../../../../utils/urlHelper';
import ResourceCard, { ResourceCardType } from '../../../../../../../components/Elements/Resource/Card';
import { PageIcon } from '../../../../../../../components/Icons';
import { ENTITY_NOT_ADDED, RESOURCES, ENTITY_NOT_FOUND } from '../../../../../../../constants/messages/adminCMS';
import { checkResourcePermission } from '../../../../../../../utils/helpers/adminCMSPageHelper';
import { EmptyMessageType } from '../../../../../../../components/Elements/EmptyMessage';
import { ModalHeight } from '../../../../../../../components/Elements/Modal';
import { CardCategory } from '../../../../../../../components/Elements/Card';
import { activeResourcesSelector } from '../../../../../../../store/AdminCMSSite/Resources/Home';
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { Validator } from '../../../../../../../utils/validator/models';
import { shouldBlockActions } from '../../../../../../../utils/cacheLoaders/helpers/blockers';
import {WithInertAttribute} from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--modals--multiple-resources';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{},{resourceId: string}>;
type Props = WithInertAttribute<{
}>

@(withRouter as any)
class MultipleResourcesModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;
  private modalContentRef;

  componentDidMount() {
    if (shouldBlockActions()) return;

    const {actions, pageForm} = this.props;

    actions.init(pageForm.ActiveForm.CMSPageResources || []);
  }

  componentWillUnmount() {
    this.onClose();
  }

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    if (this.props.multipleResourcesForm.filterString) {
      this.scrollToTop();
    }
    this.props.actions.toggleFilter(false);
    this.props.actions.filterMultipleResources('');
  };
  onAddClick = () => {
    checkResourcePermission(
      () => {
        this.props.actions.pushFormModal(ModalTypes.RESOURCE_FORM, false);
      },
      this.props.cacheZero,
      this.props.adminCMSCacheOne,
      undefined,
      true,
    );
  };

  onClose = () => {
    this.onSearchBack();
    this.props.actions.reset();
  };

  onSave = () => {
    this.onClose();
    this.props.pageFormActions.addResourcesToPage(this.props.multipleResourcesForm.ActiveForm.SelectedResourceIds);
    this.props.actions.popModal(false, true, ModalTypes.MULTIPLE_RESOURCES);
  };
  onRefreshClick = () => {
    const {actions} = this.props;
    actions.saveState();
    actions.refresh(
      {
        GetResources: true,
        GetResourceCategories: true,
        MaxResourceID: 0,
        MaxResourceCategoryID: 0,
        ...extractRouteParams(this.props),
      },
      false,
    );
  };

  onResourceEditClick = (resource: CMSResource) => {
    checkResourcePermission(
      () => {
        this.props.actions.pushFormModal(ModalTypes.RESOURCE_FORM, true, {id: resource.ID});
      },
      this.props.cacheZero,
      this.props.adminCMSCacheOne,
      resource.SiteID,
      true,
    );
  };
  onResourceSelect = (resource: CMSResource) => {
    this.props.actions.toggleResourceSelect(!resource.selected, resource.ID);
  };

  itemRenderer = (index) => {
    const {filteredResources} = this.props;
    const resource: CMSResource = filteredResources[index];
    return (
      <div className={`multiple_resource_${index}`} key={`multiple_resource_${resource.ID || index * -1}`}>
        <ResourceCard
          checked={resource.selected}
          onEdit={this.onResourceEditClick}
          onMultipleSelect={this.onResourceSelect}
          routes={this.props.routes}
          type={ResourceCardType.MULTIPLE_RESOURCES}
          key={`multiple_resources_list_item_${resource.ID}`}
          cardCategory={CardCategory.LIST_MOBILE}
          resource={resource} />
      </div>
    );
  };

  onSelectAll = () => {
    const {filteredResources} = this.props;
    this.props.actions.selectAll(filteredResources.map((r) => r.ID));
  };

  onDeselect = () => {
    const {filteredResources} = this.props;
    this.props.actions.deselect(filteredResources.map((r) => r.ID));
  };

  renderEmptyMessage = () => {
    const {activeResources, filteredResources} = this.props;
    const message = activeResources.length === 0 && filteredResources.length === 0 ? ENTITY_NOT_ADDED(RESOURCES): ENTITY_NOT_FOUND(RESOURCES);
    return (
      <EmptyMessage
        icon={PageIcon}
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={message}
        actions={<Button className={`${namespace()}--empty-message-btn`} color='white' textColor='black' onClick={this.onAddClick}>NEW RESOURCE</Button>}
      /> 
    );
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };

  onResourceCategoryChange = (val: any, vObj: Validator) => {
    this.scrollToTop();
    this.props.actions.resourceModalUpdateValue(val, vObj);
  };
  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filterMultipleResources(val);
  };

  public render() {
    const { apiLoadingMap, filteredResources, activeResources, actions, pageForm, multipleResourcesForm: { ActiveForm, ValidationRules, isTextSearching}, inert} = this.props;

    const title = 'Select Resources';
    const loading = apiLoadingMap[GetSiteCache.requestType];
    const subtitle = pageForm.ActiveForm && pageForm.ActiveForm.Name ? pageForm.ActiveForm.Name : 'Edit Page';

    return (
      <Modal inert={inert} height={ModalHeight.HEIGHT_425} big mobileFullScreen className={namespace()} onClose={this.onClose}>
        <ModalHeader
          isSearching={isTextSearching}
          onAdd={this.onAddClick}
          onRefresh={this.onRefreshClick}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={this.onSearchChange}
          onSearchBack={this.onSearchBack}
          subtitle={subtitle}
          className={`${namespace()}--header`}>
            {title}
        </ModalHeader>
        <Row className={`${namespace()}--filter`}>
          <Select
            disabled={false}
            hideOptional
            onChangeValue={this.onResourceCategoryChange}
            value={ActiveForm.SelectedResourceCategoryID}
            validationRules={ValidationRules.SelectedResourceCategoryID}
            isNumber />
        </Row>
        <ModalContent
          refCallback={(ref) => this.modalContentRef = ref}
          neverLoading
          paddingLeft={0}
          paddingRight={0}
          paddingTop={0}
          paddingBottom={0}
        >
          {!apiLoadingMap[GetSiteCache.requestType] && !(activeResources.length === 0 || filteredResources.length === 0) && <Row className={`${namespace()}--row-2`}>
            <SimpleList
              itemRenderer={this.itemRenderer}
              totalLength={filteredResources ? filteredResources.length : 0}
            />
          </Row>}
          {loading && <PageLoader className={`${namespace()}--page--loader`} />}
          {!apiLoadingMap[GetSiteCache.requestType] && (activeResources.length === 0 || filteredResources.length === 0) ? this.renderEmptyMessage() : null}
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button textColor='black' disabled={loading} flat onClick={this.onSave}>SAVE</Button>}
          right={<div className={`${namespace()}--right-actions`}>
            {ActiveForm.SelectedResourceIds.length > 0 ? <Button textColor='black' disabled={loading} flat onClick={this.onDeselect}>DESELECT</Button> : null}
            <Button textColor='black' disabled={loading} flat onClick={this.onSelectAll}>SELECT ALL</Button>
          </div>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredResourcesSelector = makeFilteredResourcesSelector();
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    multipleResourcesForm: state.adminCMSSite.resources.modals.multipleResources,
    pageForm: state.adminCMSSite.pages.page.form,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    filteredResources: filteredResourcesSelector(state),
    activeResources: activeResourcesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...actionCreators,
    ...adminCMSCacheOneActionCreators,
    ...adminCMSCacheTwoResourcesActionCreators,
    ...rollbackActionCreators,
  }, dispatch),
  pageFormActions: bindActionCreators({
    ...pageFormActionCreators,
  }, dispatch),
});

const ConnectedMultipleResourcesModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(MultipleResourcesModal);

export default ConnectedMultipleResourcesModal;