import * as React from 'react';
import { default as AppHeader, Props as AppHeaderProps } from '../../App/Header';
import { RouteComponentProps, WithRouterProps } from 'react-router';
import {withRouter} from "react-router";
import {navPush} from "../../../utils/navHelper";
import {
  E_REGISTER,
  EVENT,
  FAC_TRIP_ADD,
  FAC_TRIP_RESERVATION,
  FAC_TRIP_RESERVATION_EDIT,
  URLS,
  isAdminPage
} from "../../../constants/urls";
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

export interface CheckoutHeaderProps extends AppHeaderProps, RouteComponentProps<{}, { id: string }> {
}

type ConnectedProps = ReturnType<typeof mapStateToProps>;
class CheckoutHeader extends React.Component<CheckoutHeaderProps & ConnectedProps, {}> {
  public props: CheckoutHeaderProps & ConnectedProps;

  goBack = () => {
    const { previousLocation } = this.props;

    let prevLoc;

    if (
      previousLocation && previousLocation.pathname
    ) {
      // navigate to previousLocation when there is a previousLocation in `app` state.
      prevLoc = previousLocation.pathname;
    } else {
      // otherwise, always fallback to HOME page
      prevLoc = URLS.HOME;
    }

    // Although we have redirection setup in each cache four loader, we still need to manually
    // redirect here because the other redirections only handle the browser back/forward case
    if (prevLoc.includes(`${EVENT.REGISTER}/${E_REGISTER.NUMBERS}`)) {
      const sp = prevLoc.split(`${EVENT.REGISTER}/${E_REGISTER.NUMBERS}`);
      prevLoc = `${sp[0]}${EVENT.REGISTRATION}`;
    } else if (prevLoc.includes(`${EVENT.REGISTER}/${E_REGISTER.PARTICIPANT}`)) {
      const sp = prevLoc.split(`${EVENT.REGISTER}/${E_REGISTER.PARTICIPANT}`);
      prevLoc = `${sp[0]}${EVENT.REGISTRATION}`;
    } else if (prevLoc.endsWith(`/${EVENT.REGISTER}/${E_REGISTER.PRODUCTS}`)) {
      const sp = prevLoc.split(`${EVENT.REGISTER}/${E_REGISTER.PRODUCTS}`);
      prevLoc = `${sp[0]}${EVENT.REGISTRATION}`;
    } else if (prevLoc.includes(`/${FAC_TRIP_RESERVATION}/`) && prevLoc.endsWith(`/${FAC_TRIP_RESERVATION_EDIT}`)) {
      const sp = prevLoc.split(`/${FAC_TRIP_RESERVATION}/`);
      prevLoc = sp[0];
    } else if (prevLoc.endsWith(`/${FAC_TRIP_RESERVATION}/${FAC_TRIP_ADD}`)) {
      const sp = prevLoc.split(`/${FAC_TRIP_RESERVATION}/`);
      prevLoc = sp[0];
    }

    // If we are navigating to a page that doesn't require a groupID, only attach
    // groupID if the previous location also has groupID in url. Then, in `navPush`,
    // we will navigate to `prevLoc` directly.
    // For pages that do require a groupID in url, `navPush` will handle the process to
    // parse `groupId` from checkout page url and append it to the path
    if (previousLocation && previousLocation.search && prevLoc === URLS.LOGIN || prevLoc === URLS.NEW_ACCOUNT || isAdminPage(prevLoc)) {
      prevLoc += previousLocation.search;
    }
    navPush(this.props.router, prevLoc);
  };
  
  render() {
    return (
      <AppHeader
        refresh
        title="Checkout"
        onBack={this.goBack}
        {...this.props as any}
      />
    );
  }
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    previousLocation: state.app.previousLocation,
  };
};
const ConnectedCheckoutHeader = connect(
  mapStateToProps,
  null,
  getMergeProps<WithRouterProps>(),
)(CheckoutHeader);

export default withRouter<{}>(ConnectedCheckoutHeader);
