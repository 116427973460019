import {makeRequestEpic} from "../../../Validation/epicCreator";
import {UpdateLocationSubmitActions} from "./actions";
import {getUpdateLocationUrl} from "../../../../constants/urls";
import {AjaxResponse} from "rxjs/ajax";
import { ENTITY_PARSE_ERROR, ENTITY_EDITED, ENTITY_CREATED, LOCATION } from "../../../../constants/messages/adminCMS";
import { validate } from "../../../../utils";
import { ValidateActions as EditPageValidateActions } from '../../../AdminCMSSite/Pages/Page/Form/actions';
import { FormDefinition as EditPageFormDefinition } from '../../../AdminCMSSite/Pages/Page/Form/validation';
import { Observable } from "rxjs";
import { ValidateActions as NewPageValidateActions } from '../../../AdminCMSSite/Pages/Modals/NewPage/actions';
import { FormDefinition as NewPageFormDefinition } from '../../../AdminCMSSite/Pages/Modals/NewPage/validation';
import { GeneralValidateActions as EventTypeValidateActions } from '../../../AdminEvents/EventTypes/EventType/Form/actions';
import { ValidateActions as NewEventTypeValidateActions } from '../../../AdminEvents/EventTypes/Modals/NewEventType/actions';
import { ValidateActions as FacilityValidateActions } from '../../../AdminFacilityLocation/Facilities/Facility/Form/actions';
import { EventTypeGeneralFormDefinition as EventTypeFormDefinition } from '../../../AdminEvents/EventTypes/EventType/Form/validation';
import { NewEventTypeFormDefinition } from '../../../AdminEvents/EventTypes/Modals/NewEventType/validation';
import { makeFormModalPropSelector } from "../../../App";
import { ModalTypes, isModalOpened, popModalObservables } from "../../../../utils/modalHelper";
import { captureTentarooError } from "../../../../utils/dataHelper";
import { reduxStoreService } from "../../../service";

// @todo - this epic is more of a common/shared code, so better to clean up all the variable/namings here
export const adminCMSSiteAddLocationEpic = makeRequestEpic(
  UpdateLocationSubmitActions,
  getUpdateLocationUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      if (body.row.ID) {
        return ENTITY_EDITED(LOCATION);
      }
      return ENTITY_CREATED(LOCATION);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(LOCATION)));
      return '';
    }
  },
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  (payload) => {
    const location = payload.response.Location;
    const state = reduxStoreService().getState();
    const formData = payload.request.body;
    try {
      const JSONData = JSON.parse(formData);

      if (isModalOpened(ModalTypes.SELECT_LOCATION) && !JSONData.row.ID && EditPageFormDefinition.LocationID) {

        const locationModalContextSelector = makeFormModalPropSelector(ModalTypes.SELECT_LOCATION, 'context');
        const context = locationModalContextSelector(state);
        const validateObservables: Observable<any>[] = [];

        // Handle adding location in other forms. Don't need to handle `admin-facility-location`
        // case because that's not in any form
        if (context === 'admin-cms-edit-page') {
          const validationResults = validate(EditPageFormDefinition.LocationID, location.ID, () => state.adminCMSSite.pages.page.form);
          
          validateObservables.push(
            Observable.of(EditPageValidateActions.updateCurr(location.ID, 'LocationID', EditPageFormDefinition.LocationID)),
            Observable.of(EditPageValidateActions.update(validationResults, 'LocationID')),
          );
        } else if (context === 'admin-cms-add-page') {
          const validationResults = validate(NewPageFormDefinition.LocationID, location.ID, () => state.adminCMSSite.pages.modals.newPage);
          
          validateObservables.push(
            Observable.of(NewPageValidateActions.updateCurr(location.ID, 'LocationID', NewPageFormDefinition.LocationID)),
            Observable.of(NewPageValidateActions.update(validationResults, 'LocationID')),
          );
        } else if (context === 'admin-events-event-type') {
          const validationResults = validate(EventTypeFormDefinition.LocationID, location.ID, () => state.adminEvents.eventTypes.eventType.form);
          
          validateObservables.push(
            Observable.of(EventTypeValidateActions.updateCurr(location.ID, 'LocationID', EventTypeFormDefinition.LocationID)),
            Observable.of(EventTypeValidateActions.update(validationResults, 'LocationID')),
          );
        } else if (context === "admin-events-new-event-type") {
          const validationResults = validate(NewEventTypeFormDefinition.LocationID, location.ID, () => state.adminEvents.eventTypes.modals.newEventType.General);
          
          validateObservables.push(
            Observable.of(NewEventTypeValidateActions.updateCurr(location.ID, 'LocationID', NewEventTypeFormDefinition.LocationID)),
            Observable.of(NewEventTypeValidateActions.update(validationResults, 'LocationID')),
          );
        }
        return Observable.concat(
          ...validateObservables,
          ...popModalObservables({
            modal: ModalTypes.SELECT_LOCATION,
            saveBefore: false,
            saveAfter: false,
            transformToObservable: true,
          })
        );
      }
    } catch {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(LOCATION) + formData));
      return '';
    }
  },
);