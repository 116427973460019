import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra
} from '../../../../Validation/actionCreator';
import type { ActionCreator, ApplicationState } from '../../../..';
import { ADMIN_CMS_MODULE_SUFFIX } from '../../../../../store/AdminCMSSite/CacheOne/actions';
import { ContactActiveForm } from '.';
import { getSubmitContactBody, getDeleteContactBody, URLS, CMS_PATHS } from '../../../../../constants/urls';
import { SaveState } from '../../../../../store/Rollback/actions';
import { ToggleModalSaving, actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { ADMIN_CMS_CONTACT_EDIT_PATH, ADMIN_CMS_CONTACT_NEW_PATH } from '../../../../../routes';
import { scrollModalToTop } from '../../../../../utils/helpers/adminCMSPageHelper';
import { navPush } from '../../../../../utils/navHelper';
import { ModalTypes, isModalOpened } from '../../../../../utils/modalHelper';
import { AdminCMSSiteCacheTwoContactsState } from '../../../CacheTwoContacts';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../../../../utils/suffix';
import { PlainRoute } from 'react-router';
import { typeName, Action } from '../../../../../utils/StrongActions';
import { ClearAdminCMSCacheTwoContact } from '../../../CacheTwoContacts/actions';
import { Validator } from '../../../../../utils/validator/models';

export const UPDATE_CMS_CONTACT_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__CMS_CONTACT';
export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__CONTACT_FORM' + UPDATE_CMS_CONTACT_FORM_SUFFIX;
export const DEL_SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__DELETE_CONTACT' + UPDATE_CMS_CONTACT_FORM_SUFFIX;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const AddContactSubmitActions = createApiSubmitActions(SUFFIX);
export const DeleteContactSubmitActions = createApiSubmitActions(DEL_SUFFIX);

export const ContactFormModalNamespace = (): string => 'pages--cms--modals--contact-form';

@typeName('REMOVE_SOCIAL' + SUFFIX)
export class RemoveSocial extends Action {
  constructor(public contactSocialIndex: number) { super(); }
}
@typeName('ADD_SOCIAL' + SUFFIX)
export class AddSocial extends Action { constructor() { super(); } }

@typeName('UPDATE_SOCIAL_TYPE' + SUFFIX)
export class UpdateSocialType extends Action {constructor(public key: string, public vObj: Validator) { super(); }}

export type Actions = typeof actionCreators;

const formCreator = (activeForm: ContactActiveForm, Cache: AdminCMSSiteCacheTwoContactsState) => {
  const contactId = Cache.CMSContact ? Cache.CMSContact.ID : undefined;
  const formData = new FormData();
  
  let foundAllSocialMedias = false;
  let index = 0;
  const Socials: any[] = [];

  while (!foundAllSocialMedias) {
    if (!activeForm[`SocialTypeID${index}`] || !activeForm[`SocialUser${index}`]) {
      foundAllSocialMedias = true;
      break;
    }

    Socials.push({
      SocialTypeID: activeForm[`SocialTypeID${index}`],
      SocialUser: activeForm[`SocialUser${index}`],
    });
    index += 1;
  }

  formData.append('JSONData', JSON.stringify(getSubmitContactBody({
    contactId,
    contactTS: Cache.CMSContact ? Cache.CMSContact.TS : 0,
    Socials,
  }, activeForm)));

  if (activeForm.FeaturedImage && activeForm.FeaturedImage.inputObj) formData.append('File', activeForm.FeaturedImage.inputObj);

  return formData;
};

// We pass the `leavingCacheLevelOnSuccess` extra field to action when we dont want to update
// AdminCMSCacheTwoContacts state upon successfully saving. They are
//     - When we are not in EditContact/NewContact page AND
//     - When we are not in preview page
const getSubmitFormExtra = (routes, getState) => {
  const route = routes[routes.length - 1];
  const state = getState() as ApplicationState;
  
  return getCacheLevelExtra(
    !(route.path === ADMIN_CMS_CONTACT_EDIT_PATH || route.path === ADMIN_CMS_CONTACT_NEW_PATH),
    !!state.adminCMSSite.cacheTwoContacts.CMSContact,
  );
};

export const actionCreators = {
  updateSocialType: (value: any, vObj: Validator): ActionCreator => (dispatch, getState) => {
    dispatch(new UpdateSocialType(value, vObj));
    createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.adminCMSSite.contacts.contact.form)(value, vObj)(dispatch, () => getState());
  },
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.adminCMSSite.contacts.contact.form),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  removeSocial: (id: number): ActionCreator => dispatch => dispatch(new RemoveSocial(id)),
  addSocial: (): ActionCreator => dispatch => dispatch(new AddSocial()),
  apiSubmitForm: (routes: PlainRoute<any>[]): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    const isContactFormModalOpened = isModalOpened(ModalTypes.CONTACT_FORM);
    const ActiveForm = state.adminCMSSite.contacts.contact.form.ActiveForm;
    const Cache = state.adminCMSSite.cacheTwoContacts;
    const valid = innerApiSubmitFormMethod(
      dispatch,
      AddContactSubmitActions,
      (s: ApplicationState) => s.adminCMSSite.contacts.contact.form,
      undefined,
      undefined,
      isContactFormModalOpened,
      true,
      undefined,
      undefined,
    );

    if (valid) {
      if (isContactFormModalOpened) {
        dispatch(appActionCreators.popModal(false, false, ModalTypes.CONTACT_FORM) as any);
        dispatch(new ToggleModalSaving(true));
      }
      const route = routes[routes.length - 1];

      dispatch(AddContactSubmitActions.request(
          formCreator(ActiveForm, Cache),
          getSubmitFormExtra(routes, getState),
          route,
        )
      );

      if (isContactFormModalOpened && !!getState().adminCMSSite.cacheTwoContacts.CMSContact) {
        dispatch(new ClearAdminCMSCacheTwoContact());
      }
      return true;
    } else {
      if (isContactFormModalOpened) {
        scrollModalToTop(ContactFormModalNamespace());
      }
      return false;
    }
  },
  deleteContact: (contactId: number, router: any, routes: PlainRoute<any>[], restore?: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());

    const state = getState() as ApplicationState;
    const site = state.adminCMSSite.cacheOne.CMSSite;
    const qp = getState().routing.locationBeforeTransitions.search;

    const body = getDeleteContactBody({contactId}, !restore);
    const route = routes[routes.length - 1];
    
    if (site) {
      navPush(router, `${URLS.ADMIN_SITES}/${site.ID}/${site.SiteDomain}/${CMS_PATHS.CONTACTS}${qp}`);
    }
    
    dispatch(DeleteContactSubmitActions.request(
        body,
        getCacheLevelExtra(
          true, 
          !!state.adminCMSSite.cacheTwoContacts.CMSContact && state.adminCMSSite.cacheTwoContacts.CMSContact.ID === contactId,
        ),
        route,
      )
    );
  },
};