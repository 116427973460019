import * as React from 'react';
import Month from './index';
import '../../../../styles/elements/month/standard/range.scss';

const namespace = (): string => 'elements--month-range';

export interface Props {
}

class Range extends React.PureComponent<Props, {}> {

  public render() {
    return (
      <div className={namespace()}>
        <div className={`${namespace()}--scrollable`} ref="scrollable">
          {Array.apply(null, Array(3)).map((value, index) => (
            <div key={index} className={`${namespace()}--scrollable--month`} ref={index === 0 ? 'month' : undefined}>
              <Month/>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Range;
