import * as React from 'react';
import { Text, Card, CardTitle, Row, Column, Align, View, Ellipsis } from '../../../../../../Elements';
import {EventRegistrationCampsiteRankingOption, EventRegistrationParticipantType} from "../../../../../../../models/api/cacheFourEvents";
import '../../../../../../../styles/pages/events/event/register/numbers/confirmation/spots.scss';
import {displayOvernight} from "../../../../../../../store/Events/Event/Register/Numbers/Spots/validation";
import {SpotsActiveForm} from "../../../../../../../store/Events/Event/Register/Numbers/Spots";
import { EmailIcon, PhoneIcon } from '../../../../../../Icons';

export const namespace = (): string => 'pages--events--event--register--numbers--confirmation--spots';

interface Props {
  EventRegistrationParticipantTypes: Array<EventRegistrationParticipantType>;
  ShowIsCampingOvernight: boolean;
  ShowGroupRegistrationNotes: boolean;
  PromptGroupRegistrationNotes?: string | null;
  ShowCampsiteRanking: boolean;
  SpotsActiveForm: SpotsActiveForm;
  assignedCampsites: Array<string>;
  preferredCampsites: EventRegistrationCampsiteRankingOption[];
}

const Spots: React.SFC<Props> = ({
  EventRegistrationParticipantTypes,
  ShowIsCampingOvernight,
  ShowGroupRegistrationNotes,
  PromptGroupRegistrationNotes,
  ShowCampsiteRanking,
  SpotsActiveForm,
  assignedCampsites,
  preferredCampsites,
}): React.ReactElement<Props> => {

  const notes = SpotsActiveForm.GroupRegistrationNotes;

  return (<Card className={`${namespace()}`} template="mobile-stretch">
    <CardTitle>Spots</CardTitle>
    <View layout="vertical" className={`${namespace()}--section`}>
      {EventRegistrationParticipantTypes.map((pType: EventRegistrationParticipantType) => {
        if (pType.EstimateTotal === 0) return null;
        return (
          <Row key={`spots_${pType.ID}`} className={`${namespace()}--spot`}>
            <Column expand overflowHidden>
              <Text size={15} weight="medium" wrap={false}>{`${pType.Name} ${pType.IsYouth ? '(Y)' : '(A)'}`}</Text>
            </Column>
            <Column>
              <Align end>
                <Text size={15} weight="medium">{`${pType.EstimateTotal} spot${pType.EstimateTotal !== 1 ? 's':''}`}</Text>
              </Align>
            </Column>
          </Row>
        );
      })}
    </View>
    {ShowIsCampingOvernight && SpotsActiveForm.CampingOvernight !== undefined && SpotsActiveForm.CampingOvernight > -1 && <View layout="vertical" className={`${namespace()}--section`}>
      <Text size={12} color="gray" marginBottom={4}>Camping Overnight</Text>
      <Text>{displayOvernight(SpotsActiveForm.CampingOvernight)}</Text>
    </View>}
    {ShowGroupRegistrationNotes && notes && <View layout="vertical" className={`${namespace()}--section`}>
      <Text size={12} color="gray" marginBottom={4}>
        {PromptGroupRegistrationNotes ? PromptGroupRegistrationNotes : 'Registration Notes'}
        </Text>
        <Ellipsis
          configs={{
            lines: 5,
            text: notes,
          }}
        />
    </View>}
    {SpotsActiveForm.NotesForGroup && <View layout="vertical" className={`${namespace()}--section`}>
      <Text size={12} color="gray" marginBottom={4}>Notes for Group</Text>
        <Ellipsis
          configs={{
            lines: 5,
            text: SpotsActiveForm.NotesForGroup,
          }}
        />
    </View>}
    {SpotsActiveForm.AdminNotes && <View layout="vertical" className={`${namespace()}--section`}>
      <Text size={12} color="gray" marginBottom={4}>Admin Notes</Text>
        <Ellipsis
          configs={{
            lines: 5,
            text: SpotsActiveForm.AdminNotes,
          }}
        />
    </View>}
    {(SpotsActiveForm.ContactName || SpotsActiveForm.ContactEmail || SpotsActiveForm.ContactPhone) && <View layout="vertical" className={`${namespace()}--section`}>
      <Text size={12} color="gray" marginBottom={4}>Primary Event Contact</Text>
      {SpotsActiveForm.ContactName && <Text>{SpotsActiveForm.ContactName}</Text>}
      {(SpotsActiveForm.ContactEmail || SpotsActiveForm.ContactPhone) && <div className={`${namespace()}--contact--row`}>
        {SpotsActiveForm.ContactEmail && <div className={`${namespace()}--contact--col`}>
          <div className={`${namespace()}--contact--icon`}><EmailIcon /></div>
          <div className={`${namespace()}--contact--text`}>{SpotsActiveForm.ContactEmail}</div>
        </div>}
        {SpotsActiveForm.ContactPhone && <div className={`${namespace()}--contact--col`}>
          <div className={`${namespace()}--contact--icon`}><PhoneIcon /></div>
          <div className={`${namespace()}--contact--text`}>{SpotsActiveForm.ContactPhone}</div>
        </div>}
      </div>}
    </View>}
    {(SpotsActiveForm.ContactName2 || SpotsActiveForm.ContactEmail2 || SpotsActiveForm.ContactPhone2) && <View layout="vertical" className={`${namespace()}--section`}>
      <Text size={12} color="gray" marginBottom={4}>Alternate Contact</Text>
      {SpotsActiveForm.ContactName2 && <Text marginBottom={4}>{SpotsActiveForm.ContactName2}</Text>}
      {(SpotsActiveForm.ContactEmail2 || SpotsActiveForm.ContactPhone2) && <div className={`${namespace()}--contact--row`}>
        {SpotsActiveForm.ContactEmail2 && <div className={`${namespace()}--contact--col`}>
          <div className={`${namespace()}--contact--icon`}><EmailIcon /></div>
          <div className={`${namespace()}--contact--text`}>{SpotsActiveForm.ContactEmail2}</div>
        </div>}
        {SpotsActiveForm.ContactPhone2 && <div className={`${namespace()}--contact--col`}>
          <div className={`${namespace()}--contact--icon`}><PhoneIcon /></div>
          <div className={`${namespace()}--contact--text`}>{SpotsActiveForm.ContactPhone2}</div>
        </div>}
      </div>}
    </View>}
    {ShowCampsiteRanking && (!assignedCampsites || assignedCampsites.length === 0) && preferredCampsites && preferredCampsites.length > 0 && <View layout="vertical" className={`${namespace()}--section`}>
          <Text size={12} color="gray" marginBottom={8}>Preferred Campsites</Text>
          {preferredCampsites.map((campsite, index) => {
            return <Text key={`campsite${index}`}>{campsite.Name}</Text>;
          })}
        </View>}
    {assignedCampsites && assignedCampsites.length > 0 && <View layout="vertical" className={`${namespace()}--section`}>
      <Text size={12} color="gray" marginBottom={4}>Campsites</Text>
      <Text>{assignedCampsites.join(", ")}</Text>
      </View>}
  </Card>);
};

export default Spots;
