import {FacilitiesCalc, FacilitiesRecalc, FacilitiesSave, FacilitiesTypeC4, ReservationSaveOverrideFees} from './actions';
import {APIFailure, APISuccess} from "../Validation/actionCreator";
import {ClearAllCache, ClearAllEndUserCacheButOptions} from "../App/actions";
import {ClearCacheBelowOne} from "../CacheOne/actions";
import {convertObjToMoment} from "../../utils/dateHelper";
import {ClearCacheBelowTwoFacilities} from "../CacheTwoFacilities/actions";
import {ClearCacheBelowThreeFacilities} from "../CacheThreeFacilities/actions";
import {WizardReservation, TripPaymentStatus} from "../../models/api/cacheFourFacilities";
import {
  FacilitiesAvailabilitiesDate,
  FacilitiesAvailability,
  FacilityLocation_FacilityType
} from "../../models/api/cacheTwoFacilties";
import {makeSingleSelector} from "../../utils/reselectHelper";
import {facilityTypesSelector} from "../CacheTwoFacilities";
import {ReservationChange} from "../Facilities/Trip/Reservation/Reservation/actions";
import { Reducer } from 'redux';
import { Action, isActionType } from '../../utils/StrongActions';
import { OverrideReservationChange } from '../Facilities/Trip/Modals/ReservationOverrideFee/actions';

export const facilityTypeIDC4Selector = (state) => state.cacheFourFacilities.FacilityLocation_SelectedFacilityTypeID;

export const makeSelectedFacilityTypeC4Selector = makeSingleSelector((ft: FacilityLocation_FacilityType, id: number) => ft.ID === id, facilityTypesSelector, facilityTypeIDC4Selector);

export interface CacheFourFacilitiesCore {
  Reservation?: WizardReservation | null;
  TripPaymentStatus?: TripPaymentStatus | null;
  FacilityLocation_SelectedFacilityTypeID?: number | null;
  FacilitiesAvailabilities?: Array<FacilitiesAvailability> | null;
  FacilitiesAvailabilitiesDates?: Array<FacilitiesAvailabilitiesDate> | null;
  FacilityAvailabilities?: FacilitiesAvailability | null;
  FacilityAvailabilitiesDates?: Array<FacilitiesAvailabilitiesDate> | null;
}

export interface CacheFourFacilitiesState extends CacheFourFacilitiesCore {
  didFail?: boolean;
}

const setCacheFourData = (data: CacheFourFacilitiesCore): CacheFourFacilitiesState => {
  return {
    Reservation: convertObjToMoment(data.Reservation, ['StartDateTime', 'EndDateTime']),
    TripPaymentStatus: data.TripPaymentStatus,
    FacilityLocation_SelectedFacilityTypeID: data.FacilityLocation_SelectedFacilityTypeID,
    FacilitiesAvailabilities: data.FacilitiesAvailabilities,
    FacilitiesAvailabilitiesDates: data.FacilitiesAvailabilitiesDates,
    FacilityAvailabilities: data.FacilityAvailabilities,
    FacilityAvailabilitiesDates: data.FacilityAvailabilitiesDates
  };
};

const updateCacheFourData = (newState: CacheFourFacilitiesState, data: any, request: any, isOverride: boolean): CacheFourFacilitiesState => {
  if (data.FacilityAvailabilities) {
    newState.FacilityAvailabilities = data.FacilityAvailabilities;
  }
  if (data.FacilityAvailabilitiesDates) {
    newState.FacilityAvailabilitiesDates = data.FacilityAvailabilitiesDates;
  }

  if (!isOverride) {
    if (data.FacilitiesAvailabilities) {
      newState.FacilitiesAvailabilities = data.FacilitiesAvailabilities;
    }
    if (data.FacilitiesAvailabilitiesDates) {
      newState.FacilitiesAvailabilitiesDates = data.FacilitiesAvailabilitiesDates;
    }
    if (data.Reservation) {
      newState.Reservation = convertObjToMoment(data.Reservation, ['StartDateTime', 'EndDateTime']);
    }
    if (data.TripPaymentStatus) {
      newState.TripPaymentStatus = data.TripPaymentStatus;
    }

    if (request.body) {
      try {
        const body = JSON.parse(request.body);
        if (body.FacilityTypeID) {
          newState.FacilityLocation_SelectedFacilityTypeID = body.FacilityTypeID;
        }
      } catch (e) {
        console.log('could not parse request body in c4 facilities response');
      }
    }
  }

  return {...newState};
};

const getInitialState = () => ({});

const CacheFourFacilities: Reducer<CacheFourFacilitiesState> = (state: CacheFourFacilitiesState, act: Action) => {
  if (act.type === FacilitiesCalc.successType) { // initial load
    const action = <APISuccess> act;
    const data = action.response.response;
    let newState = {...state};
    newState = updateCacheFourData(newState, data, action.response.request, false);
    return newState;
  } else if (
    act.type === FacilitiesTypeC4.successType ||
    act.type === FacilitiesRecalc.successType ||
    act.type === ReservationChange.successType ||
    act.type === OverrideReservationChange.successType
  ) {
    const action = <APISuccess> act;
    const data = action.response.response;
    let newState = {...state};
    newState = updateCacheFourData(newState, data, action.response.request, act.type === OverrideReservationChange.successType);
    newState.didFail = false;
    return newState;
  } else if (
    act.type === FacilitiesSave.failureType ||
    act.type === FacilitiesRecalc.failureType ||
    act.type === ReservationChange.failureType ||
    act.type === FacilitiesTypeC4.failureType ||
    act.type === FacilitiesCalc.failureType ||
    act.type === OverrideReservationChange.failureType
  ) {
    const action = <APIFailure> act;
    if (action.response.status === 400 && action.response && action.response.xhr && action.response.xhr.response && action.response.xhr.response.error.Detail === "Unavailable Dates") {
      let newState = {...state};
      newState = updateCacheFourData(newState, action.response.xhr.response, action.response.request, act.type === OverrideReservationChange.failureType);
      newState.didFail = true;
      return newState;
    }
    return state;
  } else if (isActionType(act, ReservationSaveOverrideFees)) {
    return {
      ...state,
      Reservation: {
        ...state.Reservation,
        AmountChangeInCart: act.AmountChangeInCart,
        Amount: act.Amount,
        FlatRate: act.FlatRate,
        PerPersonRate: act.PerPersonRate,
      },
      TripPaymentStatus: act.TripPaymentStatus,
    };
  } else if (isActionType(act, ClearAllCache) || isActionType(act, ClearAllEndUserCacheButOptions) ||
    isActionType(act, ClearCacheBelowOne) || isActionType(act, ClearCacheBelowTwoFacilities) || isActionType(act, ClearCacheBelowThreeFacilities)
  ) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default CacheFourFacilities;
