import { IValidator, FormDefinition } from "./validation";
import { SUFFIX } from "./actions";
import * as Actions from "./actions";
import { apiValidationReducerCreator } from "../../../Validation/reducerCreator";
import { createSelector } from "reselect";
import { ApplicationState } from "../../..";
import { EventTypeReportsOption } from "../../../../models/api/adminEventsCacheOne";
import { ClearAllAdminEventsCache } from "../../CacheOne/actions";
import { setDefaults } from "../../../../utils/validator";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../utils/StrongActions";
import { WithRootState } from "../../../Validation/actionCreator";
import {ClearAllCache} from "../../../App/actions";

export enum AdminEventsReportType {
  GROUPS=1,
  PARTICIPANT_ROSTER_CSV,
  T_SHIRT_SIZES,
  EVENT_CLASS_SCHEDULE,
  PARTICIPANT_CLASS_SCHEDULES_BY_CLASSES,
  PARTICIPANT_CLASS_SCHEDULES_BY_PARTICIPANT,
  PARTICIPANT_CLASS_SCHEDULES_BY_EVENT_OPTIONS,
  GROUP_CLASS_OPTION_REGISTRATION,
  REGISTRATION_AMOUNTS,
}

export interface AdminEventsReportsActiveForm {
  SelectedReportType: AdminEventsReportType;
  Year: number;
}

export interface AdminEventsReportsFormState {
  ActiveForm: AdminEventsReportsActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage: string;
}

const getInitialState = () => {
  return {
    ActiveForm: { },
    ValidationRules: { ...FormDefinition }
  };
};

export const makeSelectedEventTypeReportSelector = () => {
  return createSelector(
    [
      (state: ApplicationState) => state.adminEvents.reports.generateReports.ActiveForm.SelectedReportType,
      (state: ApplicationState) => state.adminEvents.cacheOne.EventTypeReportsOptions || [],
    ],
    (reportType: AdminEventsReportType, reports: EventTypeReportsOption[]) => {
      return reports.find((r) => r.ID === reportType);
    }
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX);
const generateReportsCheckApiValidation = apiValidationReducerCreator('__GENERATE_REPORTS' + SUFFIX);

const AdminEventsReportsFormReducer: Reducer<AdminEventsReportsFormState> = (s, act: WithRootState<AnyAction>) => {
  const state = generateReportsCheckApiValidation(checkApiValidation(s, act), act);
  if (isActionType(act, ClearAllAdminEventsCache) || isActionType(act, ClearAllCache)) {
    return setDefaults(act.rootState, getInitialState());
  }
  return state || setDefaults(act.rootState, getInitialState());
};

export default AdminEventsReportsFormReducer;