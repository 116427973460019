import * as React from 'react';
import '../../../styles/elements/sub-title/index.scss';
import {default as attributes, MarginProps} from "../../../styles/index";

export const namespace = (): string => 'elements--sub-title';

export interface SubTitleProps extends MarginProps {
  className?: string;
  children?: React.ReactNode;
}

const SubTitle: React.SFC<any> = ({ children, className: classNameOverride, ...props }: SubTitleProps): React.ReactElement<SubTitleProps> => {
  const style: React.CSSProperties = {};
  let className = attributes(namespace(), classNameOverride, props);
  return (
    <h2 className={className} style={style}>
      {children}
    </h2>
  );
};

export default SubTitle;
