import { ValidationResults } from "@tentaroo/core";

import type {ActionCreator, ApplicationState} from '../../';

import { actionCreators as appActionCreators } from "../../App/actions";
import {
  createApiSubmitActions,
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions
} from "../../Validation/actionCreator";
import {validateAll} from "../../../utils/validator";
import {SaveState} from "../../Rollback/actions";
import {getUpdateEmailBody} from "../../../constants/urls";
import {FullGroup} from "../../../models/api/options";
import {ShowTopFloatingAlert} from "../../App/actions";
import { typeName, Action } from '../../../utils/StrongActions';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../utils/dataHelper';
import { ModalTypes } from '../../../utils/modalHelper';

export const SUFFIX = '__EDIT_EMAIL';

@typeName(`RESET${SUFFIX}`)
export class EditEmailReset extends Action {}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const EditEmailActions = createApiSubmitActions(SUFFIX);

export type Actions = typeof actionCreators;

export const formCreator = (rootState: ApplicationState, activeForm) => {
  let debugPayload: TentarooDebugPayload | undefined;
  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when submting EditEmail form");
  }
  const Group = rootState.cacheZero.options?.Group as FullGroup;
  const payload = getUpdateEmailBody({
    GroupIDi: Group.IDi,
    GroupTS: Group.TS,
    Email: activeForm.Email
  });

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  resetEditEmail: (): ActionCreator => dispatch => dispatch(new EditEmailReset()),
  editEmailApiSubmitForm: (): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const validationResults = validateAll(s => s.settings.editEmail);
    if (!validationResults) {
      const activeForm = getState().settings.editEmail.ActiveForm;
      dispatch(appActionCreators.popModal(false, false, ModalTypes.EDIT_EMAIL) as any);
      dispatch(EditEmailActions.request(formCreator(getState(), activeForm), null));
    } else {
      dispatch(EditEmailActions.clientFailure(validationResults, true));
      dispatch(new ShowTopFloatingAlert(validationResults.Errors[Object.keys(validationResults.Errors)[0]][0], true, 'orange'));
    }
  },
  editEmailUpdateValue: createUpdateValueMethod(
    ValidateActions,
    ApiValidateActions,
    (s) => s.settings.editEmail,
    undefined,
    undefined,
    undefined,
    (validationResults: ValidationResults, key: string, dispatch) => {
      dispatch(new ShowTopFloatingAlert(validationResults.Errors[Object.keys(validationResults.Errors)[0]][0], true, 'orange'));
    }),
  editEmailSimpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
};
