import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminEventsCacheOneContext } from '@tentaroo/shared';

import { ApplicationState } from '../../../../../store';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as cacheOneActionCreators } from '../../../../../store/AdminEvents/CacheOne/actions';
import { LoadingAll, Tabs, Tab } from '../../../../Elements';
import { Main, MainContent } from '../../../../Layouts';
import '../../../../../styles/pages/admin-events/reports/home/index.scss';
import { ADMIN_EVENTS_REPORTS_PATH, ADMIN_EVENTS_YEAR_OVERVIEW_PATH } from '../../../../../routes';
import GenerateReports from './GenerateReports';
import { getAdminEventsReportsHomeRootUrl, constructAdminEventsUrlParams, getAdminEventsYearOverviewRootUrl } from '../../../../../constants/adminEventsUrls';
import { navPush } from '../../../../../utils';
import YearOverview from './YearOverview';
import AdminEventsCacheManager from '../../../../../utils/cacheManagers/adminEventsCacheManager';
import { IAdminEventsRouterParams } from '../../../../../utils/helpers/adminEventsPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { noOpenedModals } from '../../../../../utils/modalHelper';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isAdminEventsCacheOnePopulated} from '../../../../../utils/cachePopulatedCheckers/adminEvents';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--reports';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminEventsRouterParams, {}>
>;

class ReportsHome extends ComponentUpdateTemplate<ConnectedProps, {}> {
  public props: ConnectedProps;

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        const {route} = this.props;
        AdminEventsCacheManager.getInstance().loadAdminEventsCacheOne({
          props: this.props,
          isGetYearOverview: !!route.path && route.path === ADMIN_EVENTS_YEAR_OVERVIEW_PATH,
          isStateNavigated,
          context: route.path === ADMIN_EVENTS_YEAR_OVERVIEW_PATH ? undefined : AdminEventsCacheOneContext.REPORTS,
        });
      }
    );
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    const {routes: thisRoutes} = nextProps;
    const thisPath = thisRoutes[thisRoutes.length - 1].path;

    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        AdminEventsCacheManager.getInstance().loadAdminEventsCacheOne({
          props: nextProps,
          isGetYearOverview: !!thisPath && thisPath === ADMIN_EVENTS_YEAR_OVERVIEW_PATH,
          isStateNavigated,
          context: thisPath === ADMIN_EVENTS_YEAR_OVERVIEW_PATH ? undefined : AdminEventsCacheOneContext.REPORTS,
        });
      }
    );
  }

  getSelectedTab = () => {
    const {routes} = this.props;
    const route = routes[routes.length - 1];
    const selectedTab = route.path === ADMIN_EVENTS_REPORTS_PATH ? 'reports' : (route.path === ADMIN_EVENTS_YEAR_OVERVIEW_PATH ? 'year_overview' : '');

    return selectedTab;
  };

  onClickReportsTab = () => {
    const url = getAdminEventsReportsHomeRootUrl(constructAdminEventsUrlParams(this.props, this.props.adminEventsCacheOne));
    navPush(this.props.router, url);
  };
  onClickYearOverviewTab = () => {
    const url = getAdminEventsYearOverviewRootUrl(constructAdminEventsUrlParams(this.props, this.props.adminEventsCacheOne));
    navPush(this.props.router, url);
  };

  public render() {
    const {adminEventsCacheOne, apiLoading, inert, apiSaving } = this.props;
    if (!isAdminEventsCacheOnePopulated(adminEventsCacheOne)) {
      return <LoadingAll />;
    }

    const selectedTab = this.getSelectedTab();
    const loading = apiLoading > 0 && noOpenedModals();

    return (
      <Main inert={inert} isLoading={loading} isAdminEvents>
        <MainContent className={namespace()} handleCompact>
          <Tabs className={`${namespace()}--tabs`}>
            <Tab selected={selectedTab === 'reports'} onClick={this.onClickReportsTab}>GENERATE REPORTS</Tab>
            <Tab selected={selectedTab === 'year_overview'} onClick={this.onClickYearOverviewTab}>YEAR OVERVIEW</Tab>
          </Tabs>
          {selectedTab === 'reports' && <GenerateReports />}
          {selectedTab === 'year_overview' && <YearOverview />}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    cacheZero: state.cacheZero,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...cacheOneActionCreators,
  }, dispatch),
});
const ConnectedReportsHome = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(ReportsHome);
export default withRouter<{}>(ConnectedReportsHome);
