import * as React from 'react';
import { ActionButton, AdminBadge } from '../../Elements';
import '../../../styles/app/nav/group.scss';
import { groupNamespace } from './constants';

export interface GroupProps {
  children?: React.ReactNode;
  name?: string;
  icon?: React.ReactType;
  size?: 'super' | 'compact' | 'normal';
  onBackClick?: () => void;
  isAdmin?: boolean;
}

const Group:React.SFC<GroupProps> = (props:GroupProps):React.ReactElement<GroupProps> => {
  const { icon, size, isAdmin, onBackClick } = props;

  return (
    <nav className={groupNamespace()}>
      {props.name ? (
        <div className={`${groupNamespace()}--header ${size}${isAdmin ? ' is-admin' : ''}`}>
          {props.name && <div className={`${groupNamespace()}--name`}>{props.name}</div>}
          {icon && onBackClick && (<ActionButton
            icon={icon}
            onClick={onBackClick}
            className={`${groupNamespace()}--button`}
          />)}
          {isAdmin && <AdminBadge marginLeft={11} />}
        </div>
      ) : null}
      <ul className={`${groupNamespace()}--items`}>
        {props.children}
      </ul>
    </nav>
  );
};

Group.defaultProps = {
  size: 'normal'
};

export default Group;
