import * as React from 'react';
import '../../../styles/elements/admin-badge/index.scss';
import styles from '../../../styles';

export const namespace = (): string => 'elements--admin-badge';

type AdminBadgeProps = {
  className?: string;
  marginLeft?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  marginRight?: number;
};

const AdminBadge: React.SFC<AdminBadgeProps> = ({ marginLeft, marginRight, className }: AdminBadgeProps): React.ReactElement<AdminBadgeProps> => (
  <i className={styles(namespace(), className)} style={{
    marginLeft: marginLeft ? `${marginLeft}px` : undefined,
    marginRight: marginRight ? `${marginRight}px` : undefined,
  }}>A</i>
);

export default AdminBadge;
