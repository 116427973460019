import * as React from 'react';
import PropTypes from 'prop-types';
import { namespace as ns } from './constants';
import '../../../styles/elements/context-menu/item.scss';
import { AppInterface } from '../../App';

export const namespace = (): string => `${ns()}--item`;

interface Props {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<any>) => any;
  admin?: boolean;    // whether this is a context menu item in admin pages or not
  url?: string;
  id?: string;
}

// @todo: remove this
interface Context {
  app: AppInterface;
  close: () => any;
}

class Item extends React.PureComponent<Props, {}> {
  public static contextTypes = {
    app: PropTypes.object,
    close: PropTypes.func
  };

  public context: Context;

  public handleClick = (event: React.MouseEvent<any>) => {
    this.context.close();
    if (this.props.onClick) this.props.onClick(event);
  };

  public render() {
    const { children, id, url, admin } = this.props;

    if (url) {
      return (
        <li id={id} className={namespace()}>
          <a className={`${namespace()}--button ${admin ? 'admin' : ''}`} href={url} target="_blank">{children}</a>
        </li>
      );
    }
    return (
      <li id={id} className={namespace()}>
        <a className={`${namespace()}--button ${admin ? 'admin' : ''}`} onClick={this.handleClick}>{children}</a>
      </li>
    );
  }
}

export default Item;
