import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import Accordion from "../Accordion";
import IconRow from "../IconRow";
import IconRowLink from "../IconRowLink";
import { PersonIcon, LocationsIcon, PhoneIcon, MailIcon, PublicWebsiteIcon } from '../../Icons';
import '../../../styles/elements/contact-info/index.scss';

export const namespace = (): string => 'elements--contact-info';

export interface ContactInfoProps {
  marginTop?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  className?: string;
  title: string;
  contactName?: string;
  address?: string;
  phone?: string;
  fax?: string;
  email?: string;
  website?: string;
  open?: boolean;
  multipleLocations?: boolean;
}

const ContactInfo: React.SFC<ContactInfoProps> = (props: ContactInfoProps): React.ReactElement<ContactInfoProps> => {
  let children: Array<any> = [];
  let key = 0;
  if (props.contactName && props.contactName !== '') {
    children.push(<IconRow icon={PersonIcon} key={key++}>{props.contactName}</IconRow>);
  }
  if (props.address && props.address !== '') {
    // remove comma after city in address
    let formattedAddress = props.address;
    const addressTokens = props.address.split(", ");

    if (addressTokens && addressTokens.length > 0) {
      const len = addressTokens.length;
      addressTokens[len - 2] = `${addressTokens[len - 2]} ${addressTokens[len - 1]}`;
      addressTokens.splice(len-1, 1);
      formattedAddress = addressTokens.join(", ");
    }

    children.push(<IconRowLink noLinkDecoration icon={LocationsIcon} target="_blank" href={`https://maps.google.com/?q=${formattedAddress}`} key={key++}>{formattedAddress}</IconRowLink>);
  }
  if (props.phone && props.phone !== '') {
    children.push(<IconRowLink noLinkDecoration target="_blank" href={`tel:${props.phone}`} icon={PhoneIcon} key={key++}>{props.phone}</IconRowLink>);
  }
  if (props.fax && props.fax !== '') {
    children.push(<IconRowLink icon={PhoneIcon} key={key++}>{props.fax + ' (fax)'}</IconRowLink>);
  }
  if (props.email && props.email !== '') {
    children.push(<IconRowLink noLinkDecoration target="_blank" href={`mailto:${props.email}`} icon={MailIcon} truncate key={key++}>{props.email}</IconRowLink>);
  }
  if (props.website && props.website !== '') {
    children.push(<IconRowLink noLinkDecoration target="_blank" href={`${props.website}`} icon={PublicWebsiteIcon} truncate key={key++}>{props.website}</IconRowLink>);
  }
  return (
    <Accordion marginTop={props.marginTop} name={props.title} className={mergeClassNames(namespace(), props)} defaultOpen={props.open}>
      {props.multipleLocations ? (
          <div className={`${namespace()}--multiple-locations`}>
            <LocationsIcon width="28px" height="40px" className={`${namespace()}--multiple-locations--icon`}/>
            <div className={`${namespace()}--multiple-locations--text`}>
              <span>Events have different locations.</span>
              <span>Select specific event to see its location.</span>
            </div>
          </div>
      ) : children}
    </Accordion>
  );
};

export default ContactInfo;
