import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../../../../store';
import { bindActionCreators } from 'redux';
import {
    actionCreators as adminEventsCacheThreeClassTypesActionCreators, GetAdminEventClassType, GetAdminEventClassTypeAllRequirements,
} from '../../../../../../../../store/AdminEvents/CacheThreeClassType/actions';
import { actionCreators as appActionCreators } from '../../../../../../../../store/App/actions';
import { 
    actionCreators as rollbackActionCreators, } from '../../../../../../../../store/Rollback/actions';
import { actionCreators, AdminEventClassTypeFormNamespace as namespace } from "../../../../../../../../store/AdminEvents/ClassTypes/Form/actions";
import { SideModal, SideModalContent, SideModalHeader, Loader, SideModalActions, Button } from '../../../../../../../../components/Elements';
import Form from './Form';
import '../../../../../../../../styles/pages/admin-events/class-types/class-type/index.scss';
import { navPush } from '../../../../../../../../utils';
import { NotFound } from '../../../../../../../../components/Pages';
import { getAdminEventsEventClassTypesUrl, constructAdminEventsEventUrlParams } from '../../../../../../../../constants/adminEventsUrls';
import { INVALID_CLASS } from '../../../../../../../../constants/messages/adminEvents';
import { checkAdminEventClassTypePermission } from '../../../../../../../../utils/helpers/adminEventsPageHelper';
import { generateDOMId } from '../../../../../../../../utils/cypressHelper';
import { shouldReconfigRouter } from '../../../../../../../../utils/cacheLoaders/reloaderHelper';
import { isPathUnderAdminEvent } from '../../../../../../../../utils/eventsHelper';
import { ClearAdminEventsCacheTwoEvent } from '../../../../../../../../store/AdminEvents/CacheTwoEvent/actions';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../../utils/reduxHelper';
import {makeSelectedMeritBadgeSelector} from '../../../../../../../../store/AdminEvents/ClassTypes/Form';
import { reduxStoreService } from '../../../../../../../../store/service';
import {isAdminEventsCacheThreeClassTypePopulated, isAdminEventsCacheTwoEventPopulated} from '../../../../../../../../utils/cachePopulatedCheckers/adminEvents';
import { WithInertAttribute } from '../../../../../../../Elements/WithInert';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{classTypeId: string, eventId: string}, {}>;

type Props = WithInertAttribute<{
  type: 'add' | 'edit';
  classTypeId?: number;
  onBack?: () => void;
}>;
class ClassTypeSideModal extends React.Component<Props, {}> {
  public props: ConnectedProps & Props;
  private nextLocation;

  private configRouter() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    const {type, actions, adminEventsCacheThreeClassTypes} = this.props;

    this.nextLocation = nextLocation;
    if (reduxStoreService().getState().app.apiLoading === 0) {
      if (type === "add" || isAdminEventsCacheThreeClassTypePopulated(adminEventsCacheThreeClassTypes)) {
        actions.saveState();
      }
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentWillUnmount() {
    const {type, actions, adminEventsCacheTwoEvent, adminEventsCacheThreeClassTypes} = this.props;
    if (
      this.nextLocation &&
      !isPathUnderAdminEvent(this.nextLocation.pathname) &&
      isAdminEventsCacheTwoEventPopulated(adminEventsCacheTwoEvent)
    ) {
      reduxStoreService().dispatch(new ClearAdminEventsCacheTwoEvent());
    } else if (type === "add" || isAdminEventsCacheThreeClassTypePopulated(adminEventsCacheThreeClassTypes)) {
      actions.clearAdminEventsCacheThreeClassType();
    }

    this.resetRouteLeaveHook();
  }

  componentDidUpdate(prevProps: ConnectedProps & Props) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
  }

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);
    this.configRouter();
  }

  onSave = () => {
    const {type, actions, cacheZero, router, routes, adminEventsCacheOne} = this.props;

    checkAdminEventClassTypePermission(
      () => {
        actions.apiSubmitForm(router, routes);
      },
      cacheZero,
      type === 'edit',
    );
  };
  onDelete = () => {
    const {actions, routes, cacheZero, router, formState: {ActiveForm}} = this.props;


    checkAdminEventClassTypePermission(
      () => {
        actions.deleteAdminEventClassType(ActiveForm.IDi as number, routes, router, true);
      },
      cacheZero,
      true,
    );
  };
  onClickModal = (e) => {
    e && e.stopPropagation();
  };
  onBack = () => {
    if (!this.props.adminEventsCacheTwoEvent.EventsEvent) return;
    if (this.props.onBack) {
      this.props.onBack();
      return;
    }
    const backUrl = getAdminEventsEventClassTypesUrl(constructAdminEventsEventUrlParams(this.props, this.props.adminEventsCacheTwoEvent.EventsEvent.IDi, this.props.adminEventsCacheTwoEvent.EventsEvent.Name));
    navPush(this.props.router, backUrl);
  };

  renderNotFound = () => {
    return (
      <NotFound showCMSSiteHeader inModal message={INVALID_CLASS}/>
    );
  };

  isDisabled = () => {
    const {type, cacheZero} = this.props;

    if (!cacheZero.options || !cacheZero.options.GeneralPermissions) return;

    if (type === 'add') {
      return !cacheZero.options.GeneralPermissions.hasClassTypeAdd;
    } else {
      return !cacheZero.options.GeneralPermissions.hasClassTypeEdit;
    }
  };

  renderContent = (empty?: boolean) => {
    const { type, inert, apiSaving, selectedMeritBadge, adminEventsCacheThreeClassTypes, adminEventsCacheOne, actions, cacheZero, formState} = this.props;

    return [
      <SideModalContent lockBodyScroll key='admin-event-class-type-content'>
        {!empty && <Form
          inert={inert}
          classTypeForm={formState}
          disabled={apiSaving > 0}
          reduxActions={actions}
          adminEventsCacheOne={adminEventsCacheOne}
          adminEventsCacheThreeClassTypes={adminEventsCacheThreeClassTypes}
          cacheZero={cacheZero}
          action={type}
          selectedMeritBadge={selectedMeritBadge}
        />}
      </SideModalContent>,
      empty ? null : <SideModalActions key='admin-event-class-type-actions'>
        {type === 'add' && <Button disabled={this.isDisabled()} flat textColor="black" onClick={this.onSave}>CREATE</Button>}
        {type === 'edit' && <Button id={generateDOMId("tentaroo-save-class-type-btn")} disabled={this.isDisabled()} flat textColor="black" onClick={this.onSave}>SAVE</Button>}
        {type === 'edit' && <Button disabled={this.isDisabled()} flat textColor="red" onClick={this.onDelete}>DELETE</Button>}
      </SideModalActions>,
    ];
  };

  public render() {
    const {formState: {ActiveForm}, type, adminEventsCacheThreeClassTypes, adminEventsCacheOne, apiLoadingMap } = this.props;
    const loading = type === "edit" && (
      !isAdminEventsCacheThreeClassTypePopulated(adminEventsCacheThreeClassTypes, ActiveForm) ||
      (isAdminEventsCacheThreeClassTypePopulated(adminEventsCacheThreeClassTypes, ActiveForm) && apiLoadingMap[GetAdminEventClassType.requestType])
    );
    const reloadingAllRequirements = apiLoadingMap[GetAdminEventClassTypeAllRequirements.requestType];
    const subtitle = '';

    let content;

    if (type === 'add') {
      content = this.renderContent();
    } else {
      if (!isAdminEventsCacheThreeClassTypePopulated(adminEventsCacheThreeClassTypes, ActiveForm) && apiLoadingMap[GetAdminEventClassType.requestType]) {
        content = this.renderContent(true);
      } else if (!adminEventsCacheThreeClassTypes.ClassType || adminEventsCacheThreeClassTypes.ClassType.Inactive) {
        content = this.renderNotFound();
      } else if (adminEventsCacheThreeClassTypes.ClassType) {
        content = this.renderContent();
      }
    }

    return (
      <SideModal
        allowShadowClickDefault
        className={`${namespace()}`}
        onClick={this.onClickModal}
        stickyHeader
        header={<SideModalHeader
                  className={`${namespace()}--header`}
                  onBack={this.onBack}
                  subtitle={subtitle}
                  onlyShowSubTitleInMobile
                  title={type === 'add' ? 'New Class Type' : "Edit Class Type"} />}
        >
          {content}
        {loading || reloadingAllRequirements ? <Loader className={`${namespace()}--loader`} center /> : null}
      </SideModal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedMeritBadgeSelector = makeSelectedMeritBadgeSelector();
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    rollback: state.rollback,
    cacheZero: state.cacheZero,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    formState: state.adminEvents.classTypes.form,
    adminEventsCacheThreeClassTypes: state.adminEvents.cacheThreeClassType,
    adminEventsCacheTwoEvent: state.adminEvents.cacheTwoEvent,
    selectedMeritBadge: selectedMeritBadgeSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...rollbackActionCreators,
    ...adminEventsCacheThreeClassTypesActionCreators,
    ...appActionCreators,
  }, dispatch),
});

const ConnectedClassTypeSideModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(ClassTypeSideModal);

export default withRouter<Props>(ConnectedClassTypeSideModal);
