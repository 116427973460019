import * as React from 'react';
import Button from "../../Button";
import Icon from "../../Icon";
import ActionButton from "../../ActionButton";
import { CartIcon, OptionsIcon } from '../../../Icons';
import { Button as OriginalMdlButton } from 'react-mdl';
import '../../../../styles/elements/roster/item/index.scss';
import {EventRegistrationParticipant} from "../../../../models/api/cacheThreeEvents";
import { isMobile } from '../../../../utils/isMobile';
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--roster--item';

interface Props {
  Name: string;
  isYouth: boolean;
  IDi: number;
  children?: React.ReactNode;
  deleted?: boolean;
  cart?: boolean;
  color?: 'gray';
  data?: any;
  onClick?: (data?: any) => void;
  onRestoreClick?: (IDi: number, isYouth: boolean, Name: string) => void;
  contextMenu?: React.ReactElement<any>;
  extra?: React.ReactNode;
  restoreParticipant?: EventRegistrationParticipant;
}

type ItemState = {
  mouseOver: boolean;
  contextMenuOpen: boolean;
};

const MdlButton: any = OriginalMdlButton;

class Item extends React.Component<Props, ItemState> {
  public props: Props;
  public state: ItemState = {
    mouseOver: false,
    contextMenuOpen: false
  };

  handleOnClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(this.props.data);
    }
  };

  handleRestore = () => {
    if (this.props.onRestoreClick) this.props.onRestoreClick(this.props.IDi, this.props.isYouth, this.props.Name);
  };

  public render() {
    const { children, deleted, cart, IDi, contextMenu, extra, color, onRestoreClick } = this.props;
    const { mouseOver, contextMenuOpen } = this.state;

    let textClassName = `${namespace()}--button--text`;
    if (deleted) {
      return (
        <div className={namespace()}>
          <div className={`${namespace()}--button deleted`}>
            <span className={textClassName}>{children}</span>
            <Button id={generateDOMId(`person-${this.props.IDi}-restore-btn`)} textColor="red" flat className={`${namespace()}--button--restore`} onClick={this.handleRestore}>
              Restore
            </Button>
          </div>
        </div>
      );
    }

    if (color) textClassName += ' gray';

    return (
      <div
        className={namespace()}
        onMouseOver={() => this.setState({ mouseOver: true })}
        onMouseOut={() => this.setState({ mouseOver: false })}
      >
        <MdlButton
          ripple={!isMobile}
          className={`${namespace()}--button`}
          onClick={this.handleOnClick}
          id={generateDOMId(`person-${IDi}`)}
        >
          <span className={textClassName}>{children}</span>
          {extra ? <div className={`${namespace()}--button--extra`}>{extra}</div> : null}
          {cart ? <Icon size={28} green icon={CartIcon} className={`${namespace()}--button--cart`}/> : null}
          {contextMenu ? <div className={`${namespace()}--button--options-placeholder`}/> : null}
        </MdlButton>
        {contextMenu ? (
          <ActionButton
            id={generateDOMId(`person-${IDi}-options`)}
            icon={OptionsIcon}
            className={`${namespace()}--options` + (mouseOver || contextMenuOpen ? ' show' : '')}
            contextMenu={React.cloneElement(contextMenu, {
              ...contextMenu.props,
               onOpen: () => this.setState({ contextMenuOpen: true }),
               onClose: () => this.setState({ contextMenuOpen: false })
            })}
          />
        ) : null}
      </div>
    );
  }
}

export default Item;
