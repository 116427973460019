import * as React from 'react';
import { Main, MainContent } from '../../../../../../Layouts';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import ProductsFSCard from './ProductsFSCard';
import FinancialSummary from '../../../Registration/FinancialSummary';
import {
  actionCreators as rollbackActionCreators,
} from "../../../../../../../store/Rollback/actions";
import {bindActionCreators} from "redux";
import {
  makeAddedProductsProductsFilter, makePreviousProductsProductsFilter
} from "../../../../../../../store/CacheFourEventsProducts";
import {actionCreators as confirmationActionCreators} from '../../../../../../../store/Events/Event/Register/Products/Confirmation/actions';
import { ApplicationState } from '../../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = WithInertAttribute<{}>;

class Confirmation extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  public render() {
    const {
      confirmation, actions, apiSaving, user, addedProducts, previousProducts,
      cacheFourEventsProducts: { EventRegistrationPaymentStatus }, inert,
    } = this.props;
    const { ActiveForm: { selectedFinance, otherValue }, ValidationRules } = confirmation;

    if (!EventRegistrationPaymentStatus) return null;
    const fees: Array<{label: string; value: number;}> = [];
    fees.push({
      label: 'Registration Fees',
      value: EventRegistrationPaymentStatus.RegistrationTotal
    });
    fees.push({
      label: 'Options',
      value: EventRegistrationPaymentStatus.ClassAmount
    });
    if (addedProducts.length > 0 || previousProducts.length > 0) {
      fees.push({
        label: 'Products',
        value: EventRegistrationPaymentStatus.ProductAmount
      });
    }

    return (
      <Main
        inert={inert}
        key="numbers"
        mobileBackground="white"
        header={<HeaderProgress selected="confirmation" loading={apiSaving > 0}/>}
        footer={<FooterProgress selected="confirmation" />}
      >
        <MainContent loading={apiSaving > 0} noMobileMarginBottom>
          {addedProducts && addedProducts.length > 0 && <ProductsFSCard products={addedProducts}/>}
          <FinancialSummary
            title="Total Event Fees"
            fees={fees}
            paymentStatus={EventRegistrationPaymentStatus}
            showLoaders={false}
            selected={selectedFinance as 'min' | 'full' | 'other' | 'credit'}
            isFinanceDirty={false}
            onSelect={actions.updateValue}
            updateValue={actions.updateValue}
            simpleUpdate={actions.simpleUpdate}
            validationRules={ValidationRules}
            isSaving={false}
            otherValue={otherValue}
            IsAdmin={user.user ? user.user.str_permissions.hasAdminAccess : false}
            isWizard
            hasAccess
            hasAccessMessage={null}
          />
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const addedProducts = makeAddedProductsProductsFilter();
  const previousProducts = makePreviousProductsProductsFilter();
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    user: state.user,
    cacheFourEventsProducts: state.cacheFourEventsProducts,
    confirmation: state.events.event.register.products.confirmation,
    addedProducts: addedProducts(state),
    previousProducts: previousProducts(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...confirmationActionCreators,
    ...rollbackActionCreators
  }, dispatch)
});

const ConnectedConfirmation = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(Confirmation);

export default ConnectedConfirmation;
