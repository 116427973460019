import { ActionCreator, ApplicationState } from '../../../../../';
import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod
} from '../../../../../Validation/actionCreator';
import {eventNumbersRecalcBody} from '../../../../../../constants/urls';
import {
  EventRegistrationParticipantType,
  PostEventRegistrationClass,
  EventRegistrationSpot
} from "../../../../../../models/api/cacheFourEvents";
import {getParticipantTypeKey} from "./validation";
import {EventRegisterNumbersSelectPage, EventRegisterNumbersSetPendingPage} from "../Main/actions";
import {composeClassRegistrations, composeNumbersProposed, composeOrderedProducts} from "../../../../../CacheFourEventsNumbers/actions";
import {SaveState} from "../../../../../Rollback/actions";
import {validateAllNumbersPages, NumbersErrors} from "../../../../../CacheFourEventsNumbers/validationMaster";
import {SUBMIT_ERROR} from "../../../../../../constants/messages/generic";
import { NumbersWizardSpotsState } from '.';
import { spotsOnlySelector } from '../../../../../../utils/eventsHelper';
import { isNumberFieldDirty } from '../../../../../../utils/dataHelper';
import { typeName, Action } from '../../../../../../utils/StrongActions';

export const SUFFIX = '__NUMBERS_WIZARD_SPOTS';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ApiSubmitActionsNumbersSpots = createApiSubmitActions(SUFFIX, true);

export const SpotValidateActions = createValidateActions(SUFFIX + '__SPOT');
export const SpotApiValidateActions = createApiValidateActions(SUFFIX + '__SPOT');

@typeName(`CLEAR_SUBMIT_ERROR_MESSAGE${SUFFIX}`)
export class ClearSpotsError extends Action {}
@typeName(`COPY_FROM_PROFILE${SUFFIX}`)
export class CopyFromProfile extends Action {}
@typeName(`ADD_SPOT${SUFFIX}`)
export class AddSpot extends Action {
  constructor(public PTID: number, public PTISYouth: boolean) { super(); }
}


export type Actions = typeof actionCreators;

const formCreator = (rootState: ApplicationState, SpotsOnly: boolean, LeaderRatiosRecalc?: boolean) => {
  const options = (rootState as any).cacheZero.options;
  const eventTypeID = rootState.cacheTwoEvents.eventTypeID;
  const eventID = rootState.cacheThreeEvents.eventID;
  const c3 = rootState.cacheThreeEvents;
  const c4 = (rootState as any).cacheFourEventsNumbers;
  
  const classRegistrations: Array<PostEventRegistrationClass> = composeClassRegistrations(c4.EventClassesGroupRegistered);
  const products = composeOrderedProducts(c4.EventProductsOrdered, c4.EventProductsOrdered_Updates);
  const numbersProposed = composeNumbersProposed(rootState);

  return {
    ...eventNumbersRecalcBody(
      {
        GroupIDi: options.Group.IDi,
        GroupTS: options.Group.TS,
        EventTypeID: eventTypeID,
        EventIDi: eventID,
        GroupWeekIDi: c4.EventRegistrationNumbers.IDi,
        NumbersProposed: numbersProposed,
        ClassRegistrations: classRegistrations,
        ProductOrders: products,
        CampsiteDepositAmount: rootState.events.event.register.numbers.spots.ActiveForm.CampsiteDepositAmount,
        ParticipantTypes: !c3.EventInfoParticipantTypes ? [] : c3.EventInfoParticipantTypes.map((pType) => pType.ID),
      }
    ),
    LeaderRatiosRecalc,
  };
};

const isParticipantTypeDirty = (EventRegistrationParticipantTypes: EventRegistrationParticipantType[], spotState: NumbersWizardSpotsState) => {
  let isDirty = false;
  EventRegistrationParticipantTypes.forEach((pt) => {
    const participantType = spotState.EventRegistrationParticipantTypes.find((_pt) => pt.ID === _pt.ActiveForm.ID);

    if (participantType && isNumberFieldDirty(participantType.ActiveForm[getParticipantTypeKey(pt)], pt.EstimateTotal)) {
      isDirty = true;
    }
  });

  return isDirty;
};

const isSpotDirty = (EventRegistrationSpots: EventRegistrationSpot[], spotState: NumbersWizardSpotsState) => {
  let isDirty = false;
  spotState.EventRegistrationSpots.forEach((_spot) => {
    const matchingSpot = EventRegistrationSpots.find((spot) => spot.PTID === _spot.ActiveForm.PTID && spot.SpotID === _spot.ActiveForm.SpotID && !!spot.NumSpots);

    if (!matchingSpot) {
      // if active and not blank, then mark it as dirty
      if (!_spot.ActiveForm.Inactive && !!_spot.ActiveForm.NumSpots) {
        isDirty = true;
      }
    } else {
      const InactiveChanged = !!_spot.ActiveForm.Inactive || !_spot.ActiveForm.NumSpots;

      if (InactiveChanged || isNumberFieldDirty(matchingSpot.NumSpots, _spot.ActiveForm.NumSpots) || isNumberFieldDirty(matchingSpot.Amount, _spot.ActiveForm.Amount)) {
        isDirty = true;
      }
    }
  });

  return isDirty;
};

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.events.event.register.numbers.spots),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  updateSpotValue: createUpdateValueMethod(
    SpotValidateActions,
    SpotApiValidateActions,
    (s: ApplicationState) => s.events.event.register.numbers.spots.EventRegistrationParticipantTypes,
    undefined,
    undefined,
    undefined,
    undefined,
    ValidateActions,
  ),
  simpleUpdateSpot: createSimpleUpdateValueMethod(SpotValidateActions),
  spotsSubmit: (page: string, isNext?: boolean, LeaderRatiosRecalc?: boolean): ActionCreator => (dispatch, getState) => {
    // it is always next
    dispatch(new SaveState());
    const rootState = getState() as ApplicationState;
    const SpotsOnly = spotsOnlySelector(rootState);
    const c4 = rootState.cacheFourEventsNumbers;
    const spotsState = rootState.events.event.register.numbers.spots;

    // Check if fields are dirty
    let isDirty = false;

    if (c4.EventRegistrationNumbers) {
      const registrationFieldsDirty = isNumberFieldDirty(c4.EventRegistrationNumbers.CampsiteDepositAmount, spotsState.ActiveForm.CampsiteDepositAmount);
      isDirty = isDirty || registrationFieldsDirty;
    }
    if (SpotsOnly && c4.EventRegistrationParticipantTypes) {
      isDirty = isDirty || isParticipantTypeDirty(c4.EventRegistrationParticipantTypes, spotsState);
    } else if (!SpotsOnly && c4.EventRegistrationSpots) {
      isDirty = isDirty || isSpotDirty(c4.EventRegistrationSpots, spotsState);
    }

    const err = validateAllNumbersPages();

    // block next if any error other than AmountTotalChange, AmountTotalChange needs to be checked post recalc
    let blockNext = false;
    if (err.spots && err.spots.Errors) {
      for (let i = 0; i < Object.keys(err.spots.Errors).length; i++) {
        const k = Object.keys(err.spots.Errors)[i];
        if (k !== 'AmountTotalChange') {
          blockNext = true;
          break;
        }
      }
    }

    if (blockNext) {
      if (err.spots) {
        dispatch(ApiSubmitActionsNumbersSpots.clientFailure(err.spots));
      } else {
        dispatch(ApiSubmitActionsNumbersSpots.clientFailure({Errors: {}}));
      }
    } else {
      if (err.spots && err.spots.Errors && err.spots.Errors.AmountTotalChange) {
        dispatch(ApiSubmitActionsNumbersSpots.request(formCreator(rootState, SpotsOnly, LeaderRatiosRecalc), null));
        dispatch(new EventRegisterNumbersSetPendingPage(page as 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'confirmation'));
      } else if (!isDirty && !LeaderRatiosRecalc) {
        if (rootState.events.event.register.numbers.spots.SubmitErrorMessage === SUBMIT_ERROR) {
          dispatch(new ClearSpotsError());
        }
        dispatch(new EventRegisterNumbersSelectPage(page as 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'confirmation'));
      } else {
        dispatch(ApiSubmitActionsNumbersSpots.request(formCreator(rootState, SpotsOnly, LeaderRatiosRecalc), null));
        dispatch(new EventRegisterNumbersSetPendingPage(page as 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'confirmation'));
      }
    }
  },
  addSpot: (PTID: number, PTISYouth: boolean): ActionCreator => dispatch => dispatch(new AddSpot(PTID, PTISYouth)),
  copyFromProfile: (): ActionCreator => dispatch => dispatch(new CopyFromProfile()),
};
