import { createSelector } from 'reselect';
import { MultipleContactsToggleFilterTabs, MultipleContactsModalFilter, ResetMultipleContactsModal, ToggleContactSelect, SelectAll, Deselect, InitMultipleContactsModal } from './actions';
import { ApplicationState } from '../../../../../store';
import { IValidator, FormDefinition } from './validation';
import { apiValidationReducerCreator } from '../../../../../store/Validation/reducerCreator';
import { SUFFIX } from './actions';
import { contactsSelector } from '../../Home';
import { CMSContact } from '../../../../../models/api/adminCMSCacheOne';
import { compareDataByRowNum } from '../../../../../utils/dateHelper';
import { isActionType } from '../../../../../utils/StrongActions';
import { AnyAction, Reducer } from 'redux';
import {setDefaults} from '../../../../../utils/validator';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface MultipleContactsModalActiveFormState {
  SelectedContactCategoryID?: number;
  SelectedContactIds: number[];
}

export interface MultipleContactsModalState {
  isRefreshing: boolean;
  filterString: string;
  isTextSearching: boolean;
  ValidationRules: IValidator;
  ActiveForm: MultipleContactsModalActiveFormState;
}
const getInitialState = () => ({
  isRefreshing: false,
  filterString: '',
  isTextSearching: false,
  ValidationRules: {...FormDefinition},
  ActiveForm: {
    SelectedContactIds: [],
  },
});

const filterSelector = (state: ApplicationState) => state.adminCMSSite.contacts.modals.multipleContacts.filterString;

const selectedContactCategorySelector = (state: ApplicationState) => state.adminCMSSite.contacts.modals.multipleContacts.ActiveForm.SelectedContactCategoryID;

const contactFilterMatch = (contact: CMSContact, filter: string) => {
  const f = filter.toLowerCase();
  if (!f || f === '') return true;
  const nameMatches = contact.Name && contact.Name.toLowerCase().includes(f);
  const titleMatches = contact.Title && contact.Title.toLowerCase().includes(f);
  const companyMatches = contact.Company && contact.Company.toLowerCase().includes(f);
  return nameMatches || titleMatches || companyMatches;
};

export const makeFilteredContactsSelector = () => {
  return createSelector(
    [contactsSelector, selectedContactCategorySelector, filterSelector],
  (contacts: CMSContact[], selectedContactCategoryID: number, filter: string) => {
    let result;
    if (selectedContactCategoryID === 0) {
      result = contacts.filter((c) => !c.Inactive && contactFilterMatch(c, filter));
    } else {
      result = contacts.filter((c) => c.CategoryID === selectedContactCategoryID && !c.Inactive && contactFilterMatch(c, filter));
    }
    result.sort(compareDataByRowNum);

    return result;
  });
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const MultipleContactsModalReducer: Reducer<MultipleContactsModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);
  if (isActionType(act, MultipleContactsToggleFilterTabs)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, InitMultipleContactsModal)) {
    const stateWithDefaultValue = setDefaults(act.rootState, state);
    return {
      ...stateWithDefaultValue,
      ActiveForm: {
        ...stateWithDefaultValue.ActiveForm,
        SelectedContactIds: act.contactIds,
        SelectedContactCategoryID: act.contactCategoryId || 0,
      }
    };
  } else if (isActionType(act, MultipleContactsModalFilter)) {
    return {
      ...state,
      filterString: act.filterString,
    };
  } else if (isActionType(act, ToggleContactSelect)) {
    const contactIds = [...state.ActiveForm.SelectedContactIds];
    if (act.show) {
      const existed = contactIds.find((id) => id === act.contactId);
      if (!existed) {
        contactIds.push(act.contactId);
      }
    } else {
      const index = contactIds.findIndex((id) => id === act.contactId);
      if (index !== -1) {
        contactIds.splice(index, 1);
      }
    }
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedContactIds: contactIds,
      },
    };
  } else if (isActionType(act, SelectAll)) {
    const contactIds = [...state.ActiveForm.SelectedContactIds];

    act.contactIds.forEach((id) => {
      const existed = contactIds.find((cId) => cId === id);
      if (!existed) {
        contactIds.push(id);
      }
    });
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedContactIds: contactIds,
      },
    };
  } else if (isActionType(act, Deselect)) {
    const contactIds = [...state.ActiveForm.SelectedContactIds];
    const newContactIds: number[] = [];

    contactIds.forEach((id, index) => {
      const existed = act.contactIds.find((cId) => cId === id);
      if (!existed && id !== -1) {
        newContactIds.push(id);
      }
    });

    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedContactIds: newContactIds,
      },
    };
  } else if (isActionType(act, ResetMultipleContactsModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default MultipleContactsModalReducer;