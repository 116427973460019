
import {
  init,
  getCurrentScope,
  addBreadcrumb,
  globalHandlersIntegration,
  thirdPartyErrorFilterIntegration,
} from "@sentry/browser";

import { API_VERSION } from "../constants/urls";
import { captureTentarooError } from "../utils/dataHelper";

declare var __USE_FS__: any;

// `SENTRY_THIRD_PARTY_ERROR_FILTER_KEY` is injected from `webpack.config.js`, doing a null check here for Typescript
if (!process.env.SENTRY_THIRD_PARTY_ERROR_FILTER_KEY) {
  throw new Error("filterKeys for thirdPartyErrorFilterIntegration is not configured");
}

if (process.env.NODE_ENV === "development") {
  console.log(`thirdPartyErrorFilterIntegration filterKeys: ${process.env.SENTRY_THIRD_PARTY_ERROR_FILTER_KEY}`);
}

init({
  dsn: "https://82ca4cbe922b4dddb304275efcc3b759@o172783.ingest.sentry.io/1252641",
  release: `${API_VERSION}`,
  environment: process.env.NODE_ENV,
  integrations: [
    globalHandlersIntegration({
      onerror: true,
      onunhandledrejection: false,
    }),
    thirdPartyErrorFilterIntegration({
      filterKeys: [process.env.SENTRY_THIRD_PARTY_ERROR_FILTER_KEY],
      behaviour: "drop-error-if-exclusively-contains-third-party-frames",
    })
  ],
  maxBreadcrumbs: 50,
  // NOTE: Common errors compiled from Sentry community
  // See https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    'Can\'t find variable: ZiteReader',
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.'
  ],
  // NOTE: Previously blacklistUrls
  denyUrls: [
    // Ignore errors from CKEditor
    /ckeditor/,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
  ],
  debug: process.env.NODE_ENV === "development",
});


window.onerror = (
  message,
  file,
  lineNumber,
  columnNumber,
  error: Error | undefined
) => {
  setSentryContext();
  if (error) {
    captureTentarooError(error);
  } else {
    const errObj = {
      message,
      file,
      lineNumber,
      columnNumber,
    };
    captureTentarooError(new Error(JSON.stringify(errObj)));
  }
};

export const setSentryContext = function () {
  const usefs = __USE_FS__ as any;
  if (usefs) {
    try {
      const sessionUrl = (window as any).FS.getCurrentSessionURL(true);
      const session = (window as any).FS.getCurrentSession();

      // set TagContext
      {
        const scope = getCurrentScope();
        scope.setTag("sessionUrl", sessionUrl);
        scope.setTag("session", session);
      };

      addBreadcrumb({
        message: "FSLink",
        category: "action",
        data: {
          sessionUrl,
          session,
        },
      });

      // set ExtraContext
      {
        const scope = getCurrentScope();
        scope.setExtra("sessionUrl", sessionUrl);
        scope.setExtra("session", session);
      };
    } catch (e) {
      console.log("Failed to setup FS links in Sentry");
    }
  }
};

(window as any)["_fs_ready"] = setSentryContext;