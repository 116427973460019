import {ActionCreator} from '../';
import { typeName, Action } from '../../utils/StrongActions';
import {CLEAR_CACHE_PREFIX, createRequestActions} from '../Validation/actionCreator';

export const SUFFIX = '__CACHE_THREE_EVENTS';
export const GetEventData = createRequestActions(SUFFIX);

@typeName(`${CLEAR_CACHE_PREFIX}_CACHE_BELOW_THREE_EVENTS`) export class ClearCacheBelowThreeEvents extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  getEventData: (
    EventTypeID: number,
    GroupIDi: number,
    GroupTS: number,
    GetOptions?: boolean,
    GetGroupData?: boolean,
    EventIDi?: number,
    GetEventTypeData?: boolean
  ): ActionCreator => dispatch => {
    dispatch(GetEventData.request(
      {EventTypeID, GroupIDi, GroupTS, GetOptions, GetGroupData, EventIDi, GetEventTypeData}
    ));
  },
  clearCacheBelowThreeEvents: (): ActionCreator => dispatch => dispatch(new ClearCacheBelowThreeEvents())
};
