import * as React from 'react';
import { bindActionCreators } from 'redux';
import { appActionCreators, AppActions } from '../../../store';
import { noop } from '../../../utils';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

interface Props {
  children: React.ReactElement<any>;
}
type ConnectedProps = ReturnType<typeof mapDispatchToProps>;

const ShowSideBar: React.SFC<Props> = ({ children, actions }: Props & ConnectedProps): React.ReactElement<Props> =>
  React.cloneElement(children, {
    ...children.props,
    onClick: actions ? actions.showAddedSideBar : noop
  });

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(appActionCreators as any, dispatch) });
const ConnectedShowSideBar = connect(
  null,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ShowSideBar);

export default ConnectedShowSideBar;
