import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import {
    actionCreators as adminFacilityLocationCacheOneActionCreators,
} from '../../../../../store/AdminFacilityLocation/CacheOne/actions';
import {
    actionCreators as adminFacilityLocationCacheTwoBlackoutDatesActionCreators, GetBlackoutDate,
} from '../../../../../store/AdminFacilityLocation/CacheTwoBlackoutDates/actions';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { 
    actionCreators as rollbackActionCreators,
} from '../../../../../store/Rollback/actions';
import { actionCreators, BlackoutDateFormNamespace as namespace } from "../../../../../store/AdminFacilityLocation/BlackoutDates/Form/actions";
import { SideModal, SideModalContent, SideModalHeader, Loader, SideModalActions, Button } from '../../../../../components/Elements';
import Form from './Form';
import '../../../../../styles/pages/admin-facility-location/blackout-dates/blackout-date/index.scss';
import { navPush } from '../../../../../utils';
import '../../../../../styles/pages/menus/site-menu-item/form.scss';
import { NotFound } from '../../../../../components/Pages';
import { getBlackoutDatesHomeRootUrl, constructAdminFacilityLocationUrlParams } from '../../../../../constants/urls';
import { makeSelectedFacilitiesSelector } from '../../../../../store/AdminFacilityLocation/BlackoutDates/Form';
import { INVALID_BLACKOUT_DATE } from '../../../../../constants/messages/adminFacilityLocation';
import { checkBlackoutDatePermission, IAdminBlackoutDateRouterParams } from '../../../../../utils/helpers/adminFacilityLocationPageHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { shouldReconfigRouter } from '../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { noOpenedModals } from '../../../../../utils/modalHelper';
import { reduxStoreService } from '../../../../../store/service';
import {isAdminFacilityLocationCacheTwoBlackoutDatePopulated} from '../../../../../utils/cachePopulatedCheckers/adminFacilities';
import { WithInertAttribute } from '../../../../Elements/WithInert';

type ConnectedProps = 
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminBlackoutDateRouterParams, {}>;

type Props = WithInertAttribute<{
  type: 'add' | 'edit';
  blackoutDateId?: number;
  onDelete?: (item: any) => void;
  onBack?: () => void;
}>;
class EditBlackoutDate extends React.Component<Props, {}> {
  public props: ConnectedProps & Props;

  private configRouteLeaveHook() {
    const {routes, router} = this.props;
    const route = routes[routes.length - 1];
    router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    const {actions, type, adminFacilityLocationCacheTwoBlackoutDates} = this.props;

    if (reduxStoreService().getState().app.apiLoading === 0) {
      if (type !== "edit" || isAdminFacilityLocationCacheTwoBlackoutDatePopulated(adminFacilityLocationCacheTwoBlackoutDates)) {
        actions.saveState();
      }
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentWillUnmount() {
    const {actions, type, adminFacilityLocationCacheTwoBlackoutDates} = this.props;

    if (type !== "edit" || isAdminFacilityLocationCacheTwoBlackoutDatePopulated(adminFacilityLocationCacheTwoBlackoutDates)) {
      actions.clearAdminFacilityLocationCacheTwoBlackoutDate();
    }

    this.resetRouteLeaveHook();
  }

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);
    this.configRouteLeaveHook();
  }

  componentDidUpdate(prevProps: ConnectedProps & Props) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouteLeaveHook();
  }

  onSave = () => {
    const {type, actions, adminFacilityLocationCacheOne} = this.props;

    checkBlackoutDatePermission(
      () => {
        actions.apiSubmitForm(this.props.router, this.props.routes);
      },
      adminFacilityLocationCacheOne,
      type === 'edit',
    );
  };
  onDelete = () => {
    checkBlackoutDatePermission(
      () => {
        if (!this.props.adminFacilityLocationCacheTwoBlackoutDates.FacilitiesBlackoutDate) return;
        this.props.onDelete && this.props.onDelete(this.props.adminFacilityLocationCacheTwoBlackoutDates.FacilitiesBlackoutDate);
      },
      this.props.adminFacilityLocationCacheOne,
      true,
    );
  };
  onClickModal = (e) => {
    e && e.stopPropagation();
  };
  onBack = () => {
    if (this.props.onBack) {
      this.props.onBack();
      return;
    }
    const backUrl = getBlackoutDatesHomeRootUrl(constructAdminFacilityLocationUrlParams(this.props, this.props.adminFacilityLocationCacheOne));
    this.props.actions.clearAdminFacilityLocationCacheBelowOne();
    navPush(this.props.router, backUrl);
  };

  renderNotFound = () => {
    return (
      <NotFound showCMSSiteHeader inModal message={INVALID_BLACKOUT_DATE}/>
    );
  };

  renderContent = (empty?: boolean) => {
    const { type, apiSaving, adminFacilityLocationCacheOne, selectedFacilities, actions, cacheZero, inert, formState} = this.props;

    return [
      <SideModalContent lockBodyScroll key='blackout-date-content'>
        {!empty && <Form
          inert={inert}
          blackoutDateForm={formState}
          disabled={apiSaving > 0}
          reduxActions={actions}
          adminFacilityLocationCacheOne={adminFacilityLocationCacheOne}
          selectedFacilities={selectedFacilities}
          action={type}
        />}
      </SideModalContent>,
      empty ? null : <SideModalActions key='blackout-date-actions'>
        {type === 'add' && <Button disabled={!adminFacilityLocationCacheOne.FacilitiesLocation || !adminFacilityLocationCacheOne.FacilitiesLocation.hasBlackoutAdd} flat textColor="black" onClick={this.onSave}>CREATE</Button>}
        {type === 'edit' && <Button id={generateDOMId("admin-blackout-save-btn")} disabled={!adminFacilityLocationCacheOne.FacilitiesLocation || !adminFacilityLocationCacheOne.FacilitiesLocation.hasBlackoutEdit} flat textColor="black" onClick={this.onSave}>SAVE CHANGES</Button>}
        {type === 'edit' && <Button id={generateDOMId("admin-blackout-delete-btn")} disabled={!adminFacilityLocationCacheOne.FacilitiesLocation || !adminFacilityLocationCacheOne.FacilitiesLocation.hasBlackoutEdit} flat textColor="red" onClick={this.onDelete}>DELETE</Button>}
      </SideModalActions>,
    ];
  };

  public render() {
    const {type, formState: {ActiveForm}, adminFacilityLocationCacheTwoBlackoutDates, adminFacilityLocationCacheOne, apiLoadingMap } = this.props;
    const loading = type === 'edit' && (
      !isAdminFacilityLocationCacheTwoBlackoutDatePopulated(adminFacilityLocationCacheTwoBlackoutDates, ActiveForm) ||
      (isAdminFacilityLocationCacheTwoBlackoutDatePopulated(adminFacilityLocationCacheTwoBlackoutDates, ActiveForm) && apiLoadingMap[GetBlackoutDate.requestType])
    ) && noOpenedModals();
    const subtitle = adminFacilityLocationCacheOne.FacilitiesLocation ? adminFacilityLocationCacheOne.FacilitiesLocation.Name : '';

    let content;

    if (type === 'add') {
      content = this.renderContent();
    } else {
      if (!isAdminFacilityLocationCacheTwoBlackoutDatePopulated(adminFacilityLocationCacheTwoBlackoutDates, ActiveForm) && apiLoadingMap[GetBlackoutDate.requestType]) {
        content = this.renderContent(true);
      } else if ((!isAdminFacilityLocationCacheTwoBlackoutDatePopulated(adminFacilityLocationCacheTwoBlackoutDates, ActiveForm) || (adminFacilityLocationCacheTwoBlackoutDates.FacilitiesBlackoutDate && adminFacilityLocationCacheTwoBlackoutDates.FacilitiesBlackoutDate.Inactive)) && !apiLoadingMap[GetBlackoutDate.requestType]) {
        content = this.renderNotFound();
      } else {
        content = this.renderContent();
      }
    }

    return (
      <SideModal
        allowShadowClickDefault
        className={`${namespace()}`}
        onClick={this.onClickModal}
        stickyHeader
        header={<SideModalHeader
                  className={`${namespace()}--header`}
                  onBack={this.onBack}
                  subtitle={subtitle}
                  onlyShowSubTitleInMobile
                  // customizedBackIcon={!!adminCMSCacheTwoPage.CMSPage ? CloseIcon : null}
                  title={type === 'add' ? 'New Blackout Date' : "Edit Blackout Date"} />}
        >
          {content}
        {loading ? <Loader className={`${namespace()}--loader`} center /> : null}
      </SideModal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedFacilitiesSelector = makeSelectedFacilitiesSelector();
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    rollback: state.rollback,
    cacheZero: state.cacheZero,
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
    adminFacilityLocationCacheTwoBlackoutDates: state.adminFacilityLocation.cacheTwoBlackoutDate,
    formState: state.adminFacilityLocation.blackoutDates.form,
    selectedFacilities: selectedFacilitiesSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...rollbackActionCreators,
    ...adminFacilityLocationCacheOneActionCreators,
    ...adminFacilityLocationCacheTwoBlackoutDatesActionCreators,
    ...appActionCreators,
  }, dispatch),
});

const ConnectedEditBlackoutDate = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps & Props>(),
)(EditBlackoutDate);

export default withRouter<Props>(ConnectedEditBlackoutDate);
