import { RouteComponentProps } from "react-router";
import { Location } from "history";
import { QP_ACCOUNT, URLS } from "../../constants/urls";
import { END_USER_ADD_TRIP_PATH, END_USER_EDIT_TRIP_PATH, END_USER_EVENT_CLASSES_PATH, END_USER_EVENT_PATH, END_USER_EVENT_REGISTRATION_CLASSES_PATH, END_USER_EVENT_REGISTRATION_PARTICIPANT_PATH, END_USER_EVENT_REGISTRATION_PATH, END_USER_EVENT_TYPE_PATH, END_USER_FACILITYB_PATH, END_USER_FACILITYB_PATH_WITH_FTBID, END_USER_FACILITY_PATH, END_USER_TRIP_SUMMARY_PATH, END_USER_VIEW_RESERVATION_PATH, FACILITY_WIZARD_EDIT_PATH, FACILITY_WIZARD_PATH, NUMBERS_WIZARD_PATH, PARTICIPANT_WIZARD_EDIT_PATH, PARTICIPANT_WIZARD_PATH, PRODUCTS_WIZARD_PATH, ROSTER_ADD_PATH, ROSTER_EDIT_PATH, VIEW_ORDER_PATH } from "../../routes";
import { isPathnameMatchingRoute } from "../urlHelper";
import { reduxStoreService } from "../../store/service";

/*
 * Returns the group ID from user for logged in "group" type
 * Returns the group ID from URL for logged in "admin" type
 * Returns 0 if the user is not logged in
 */
export const getGroupID = (props: Partial<RouteComponentProps<{}, {}>>): number => {
  const u = reduxStoreService().getState().user;
  if (!u.user || u.user.IDi === -1 || !props.location || !props.routes) {
    return 0;
  } else if (u.user.userType === 'group') {
    return u.user.IDi;
  } else { 
    // logged in admin, note getGroupID is not used on admin or groups/add page
    return getGroupIDFromUrl(props.location);
  }
};

export const getGroupIDFromUrl = (location: Location) => {
  const accountIDInUrl = location.query[QP_ACCOUNT];

  if (!accountIDInUrl || isNaN(Number(accountIDInUrl))) {
    return 0;
  } else {
    return Number(accountIDInUrl);
  }

};

export const isPathUnderEndUserEventCacheFour = (pathname: string) => {
  const result = (
    isPathnameMatchingRoute(pathname, END_USER_EVENT_REGISTRATION_PARTICIPANT_PATH) ||
    isPathnameMatchingRoute(pathname, PRODUCTS_WIZARD_PATH) ||
    isPathnameMatchingRoute(pathname, NUMBERS_WIZARD_PATH) ||
    isPathnameMatchingRoute(pathname, PARTICIPANT_WIZARD_PATH) ||
    isPathnameMatchingRoute(pathname, PARTICIPANT_WIZARD_EDIT_PATH)
  );

  return result;
};

export const isPathUnderEndUserEventCacheTwo = (pathname: string) => {
  const result = (
    isPathnameMatchingRoute(pathname, END_USER_EVENT_TYPE_PATH)
  );
  return result || isPathUnderEndUserEventCacheThree(pathname);
};

export const isPathUnderEndUserEventCacheThree = (pathname: string) => {
  const result = (
    isPathnameMatchingRoute(pathname, END_USER_EVENT_PATH) ||
    isPathnameMatchingRoute(pathname, END_USER_EVENT_REGISTRATION_PATH) ||
    isPathnameMatchingRoute(pathname, END_USER_EVENT_CLASSES_PATH) ||
    isPathnameMatchingRoute(pathname, END_USER_EVENT_REGISTRATION_CLASSES_PATH)
  );
  return result || isPathUnderEndUserEventCacheFour(pathname);
};

export const isPathUnderEndUserFacilityCacheTwo = (pathname: string) => {
  return (
    isPathUnderEndUserFacilityB(pathname) ||
    isPathUnderEndUserFacilityCacheThree(pathname) ||
    isPathnameMatchingRoute(pathname, END_USER_FACILITY_PATH) ||
    isPathnameMatchingRoute(pathname, END_USER_ADD_TRIP_PATH)
  );
};

export const isPathUnderEndUserFacilityCacheFour = (pathname: string) => {
  const result = (
    isPathnameMatchingRoute(pathname, FACILITY_WIZARD_PATH) ||
    isPathnameMatchingRoute(pathname, FACILITY_WIZARD_EDIT_PATH)
  );

  return result;
};

export const isPathUnderEndUserFacilityB = (pathname: string) => {
  return (
    isPathnameMatchingRoute(pathname, END_USER_FACILITYB_PATH) ||
    isPathnameMatchingRoute(pathname, END_USER_FACILITYB_PATH_WITH_FTBID)
  );
};

export const isPathUnderEndUserFacilityCacheThree = (pathname: string) => {
  const result = (
    isPathnameMatchingRoute(pathname, END_USER_EDIT_TRIP_PATH) ||
    isPathnameMatchingRoute(pathname, END_USER_VIEW_RESERVATION_PATH) ||
    isPathnameMatchingRoute(pathname, END_USER_TRIP_SUMMARY_PATH)
  );
  return result || isPathUnderEndUserFacilityCacheFour(pathname);
};

export const isPathUnderCacheTwoPreviousOrders = (pathname: string) => {
  const result = (
    isPathnameMatchingRoute(pathname, URLS.ORDER_HISTORY) ||
    isPathnameMatchingRoute(pathname, VIEW_ORDER_PATH)
  );

  return result;
};

export const isPathUnderCacheThreeRoster = (pathname: string) => {
  return (
    isPathnameMatchingRoute(pathname, ROSTER_EDIT_PATH)
  );
};

export const isPathUnderCacheTwoRoster = (pathname: string) => {
  return (
    isPathUnderCacheThreeRoster(pathname) ||
    isPathnameMatchingRoute(pathname, ROSTER_ADD_PATH) ||
    isPathnameMatchingRoute(pathname, URLS.MY_ROSTER)
  );
};

export interface IPrevOrdersRouterParams {
  id: string;
}
export interface IPrevOrderRouterParams {
  id: string;
}

export interface IRosterRouterParams {
  id: string;
  name: string;
  ya: string;
}

export interface IEventTypeRouterParams {
  id: string;
  name: string;
}

export interface IEventRouterParams {
  eventTypeId: string;
  eventId: string;
  name: string;
}

export interface IEventParticipantRouterParams {
  eventTypeId: string;
  eventId: string;
  name: string;
  ya: string;
  pId: string;
  pName: string;
}

export interface IFacilityTypeRouterParams {
  id: string;
  name: string;
}

export interface IFacilityRouterParams {
  id: string;
  locId: string;
  tripId: string;
  name: string;
}

export interface IBFacilityRouterParams {
  id: string;
  name: string;
  facId: string;
  ftbId: string | undefined | null;
  facName: string;
};

export interface IFacilityCacheFourRouterParams {
  locId: string;
  tripId: string;
  name: string;
  resId?: string;
  resName?: string;
}

export type CancelRegistrationRouterParams = {
  eventTypeId: string;
  eventId: string;
  name: string;
  ya: string;
  pId: string;
  pName: string;
}

export type CancelReservationRouterParams = {
  locId: string;
  tripId: string;
  name: string;
  resId?: string;
  resName?: string;
}