import * as React from 'react';
import { groupNamespace as ns } from './constants';

import { Button } from 'react-mdl';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../store/App/actions';
import { withRouter, RouteComponentProps } from 'react-router';
import { navPush } from '../../../utils/navHelper';
import '../../../styles/app/nav/item.scss';
import { isMobile } from '../../../utils/isMobile';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

export const namespace = (): string => `${ns()}--items--item`;

export interface ItemProps {
  id?: string;
  children?: React.ReactNode;
  marginTop?: boolean;
  name: string;
  selected?: boolean;
  to?: string;
  num?: number;
  onClick?: Function;
  icon: React.ReactType;
  size?: 'compact' | 'super' | 'normal';
  disabled?: boolean;
}

type ConnectedProps = ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

@(withRouter as any)
class Item extends React.Component<ItemProps, any> {
  public props: ItemProps & ConnectedProps;

  public handleClick = () => {
    if (!this.props.size || this.props.size === 'normal') {
      this.props.actions.hideDrawer();
    }
  };

  public handleActionClick = () => {
    const { size, to, onClick, actions, router } = this.props;
    if (!size || size === 'normal') {
      actions.hideDrawer();
    }
    if (onClick) {
      onClick();
    } else if (to) {
      navPush(router, to);
    }
  };

  private renderItem() {
    const { size, id } = this.props;
    if (size === 'super') {
      return (
        <div>
          <Button
            ripple={!isMobile}
            component={this.renderButton}
          />
        </div>
      );
    }
    return (
      <div>
        <Button
          id={id}
          ripple={!isMobile}
          component={this.renderButton}
        />
      </div>
    );
  }

  public render() {
    const { selected, size, children, disabled } = this.props;
    let className: string = `${namespace()} ${size}`;
    if (selected) className += ' selected';
    if (children) className += ' content';
    if (disabled) className += ' disabled';
    return (
      <li className={className}>
        {this.renderItem()}
      </li>
    );
  }

  private renderLinkText() {
    const { children, name } = this.props;
    return (
      <div className={`${namespace()}--link--text--container`}>
        <span className={`${namespace()}--link--text`}>
          {name}
        </span>
        {children ? <div className={`${namespace()}--link--content`}>{children}</div> : null}
      </div>
    );
  }
  private renderButton = (buttonProps:any): React.ReactNode => {
    const { selected, to, id, children, name, icon: Icon, onClick, num, marginTop, size } = this.props;
    let className = (
      `${namespace()}--link ` +
      (selected ? 'selected ' : '') +
      (children ? 'content ' : '') +
      buttonProps.className +
      (marginTop ? ' margin-top' : '')
    );
    return (
      <a id={buttonProps.id} className={className} onClick={this.handleActionClick}>
        <i className={`${namespace()}--link--icon`}>
          <Icon/>
          {!!num && num > 0 && <span className={`${namespace()}--link--icon--num`}>{num}</span>}
        </i>
        {(!size || size === 'normal') && this.renderLinkText()}
        {(!size || size === 'normal') && buttonProps.children}
        {size === 'compact' && !children && (<span className={`${namespace()}--link--text`}>
          {name}
        </span>)}
        {size === 'compact' && children && (<div className={`${namespace()}--compact-link-container`}>
          <span className={`${namespace()}--link--text`}>
            {name}
          </span>
          <span className={`${namespace()}--link--content`}>
            {children}
          </span>
        </div>)}
      </a>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actionCreators }, dispatch)
});

const ConnectedItem = connect(
  null,
  mapDispatchToProps,
  getMergeProps<ItemProps>(),
)(Item);

export default ConnectedItem;
