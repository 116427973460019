import { ToggleNotFound } from "../../store/App/actions";
import { GetPrevOrder, SetPrevOrder } from "../../store/CacheThreePrevOrders/actions";
import { SaveState } from "../../store/Rollback/actions";
import { captureTentarooError } from "../dataHelper";
import { getGroupID } from "../helpers/endUserPageHelper";
import { isIdFromUrlValid, isIdValid } from "../urlHelper";
import { reduxStoreService } from "../../store/service";
import { LoadEndUserCacheThreePrevOrderParams } from "./helpers/models";
import {isCacheThreePrevOrdersPopulated, isCacheTwoPrevOrdersPopulated, isCacheZeroPopulated, isEndUserCacheOnePopulated} from "../cachePopulatedCheckers/endUser";

export const ensureCacheThreePrevOrder = (
  params: LoadEndUserCacheThreePrevOrderParams
): boolean => {
  const {props, isStateNavigated} = params;
  const groupID = getGroupID(props);
  if (!isIdValid(groupID) || !isIdFromUrlValid(props.params.id)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const orderId = Number(props.params.id);
  const rootState = reduxStoreService().getState();
  const {cacheOne, cacheZero} = rootState;
  const cacheTwoPrevOrders = rootState.cacheTwoPrevOrders;
  const cacheThreePrevOrders = rootState.cacheThreePrevOrders;

  let requestedData = false;
  if (!isCacheThreePrevOrdersPopulated(cacheThreePrevOrders)) {
    const appStateProps: any = {};
    if (!isCacheZeroPopulated(cacheZero)) {
      appStateProps.GroupIDi = groupID;
      appStateProps.GroupTS = 0;
      appStateProps.GetOptions = true;
      appStateProps.GetGroupData = true;
      appStateProps.GetGroupPreviousOrdersData = true;
    } else if (
      isCacheZeroPopulated(cacheZero) &&
      (!cacheZero.options!.Group || !isEndUserCacheOnePopulated(cacheOne))
    ) {
      appStateProps.GroupIDi = groupID;
      appStateProps.GroupTS = 0;
      appStateProps.GetOptions = false;
      appStateProps.GetGroupData = true;
      appStateProps.GetGroupPreviousOrdersData = true;
    } else {
      if (!cacheZero.options!.Group) {
        captureTentarooError(new Error("Group is not available when dispatching GetPrevOrder in cacheThreePrevOrderLoaders"));
        return false;
      }
      appStateProps.GroupIDi = cacheZero.options!.Group.IDi;
      appStateProps.GroupTS = cacheZero.options!.Group.TS;
      appStateProps.GetOptions = false;
      appStateProps.GetGroupData = false;
      appStateProps.GetGroupPreviousOrdersData = !isCacheTwoPrevOrdersPopulated(cacheTwoPrevOrders);
    }

    // Before requesting an order, always set the order cache from cacheTwo - optimistic load
    if (cacheTwoPrevOrders.PreviousOrders) {
      const prevOrder = cacheTwoPrevOrders.PreviousOrders.find(prev => prev.OrderID === orderId);
      if (prevOrder) {
        reduxStoreService().dispatch(new SetPrevOrder(prevOrder));
      }
    }

    appStateProps.OrderID = orderId;
    reduxStoreService().dispatch(GetPrevOrder.request({
      GroupIDi: appStateProps.GroupIDi,
      GroupTS: appStateProps.GroupTS,
      OrderID: appStateProps.OrderID,
      GetOptions: appStateProps.GetOptions,
      GetGroupData: appStateProps.GetGroupData,
      GetGroupPreviousOrdersData: appStateProps.GetGroupPreviousOrdersData
    }));

    requestedData = true;
  } else if (isStateNavigated) {
    reduxStoreService().dispatch(new SaveState());
  }

  return requestedData;
};