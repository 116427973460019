import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { namespaceWindow } from './constants';
import '../../../styles/elements/modal/title.scss';

export const namespace = (): string => `${namespaceWindow}--title`;

export interface HeaderProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  loading?: boolean;
  className?: string;
  center?: boolean;
  marginBottom?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
}

const Title: React.SFC<HeaderProps> = (
  { loading, children, center, marginBottom, style: inheritedStyle, ...props }: HeaderProps
): React.ReactElement<HeaderProps> => {
  let className = mergeClassNames(namespace(), props);
  if (center) className += ' center';
  if (loading) className += ' loading';
  const style: React.CSSProperties = { ...inheritedStyle };
  if (marginBottom || marginBottom === 0) style.marginBottom = `${marginBottom}px`;
  return (
    <h1 className={className} style={style}>
      {children}
    </h1>
  );
};

export default Title;
