import * as React from 'react';
import { default as AppHeader } from '../../../../../App/Header';
import {RouteComponentProps, WithRouterProps} from "react-router";
import {bindActionCreators} from 'redux';
import {URLS, EVENT} from "../../../../../../constants/urls";
import {withRouter} from "react-router";
import {actionCreators} from "../../../../../../store/Events/Event/Register/Products/Main/actions";
import {navPush} from "../../../../../../utils/navHelper";
import {EventType} from "../../../../../../models/api/cacheOne";
import {EventTypeEvent} from "../../../../../../models/api/cacheTwoEvents";
import {makeSelectedEventSelector} from "../../../../../../store/CacheThreeEvents";
import {makeSelectedEventTypeSelector} from "../../../../../../store/CacheTwoEvents";
import {makeSubtitle} from "../Numbers/Header";
import {
  makeAddedProductsProductsFilter,
  makePreviousProductsProductsFilter
} from "../../../../../../store/CacheFourEventsProducts";
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';

interface HeaderSpec {
  added: boolean;
  search: boolean;
  addedLength?: number;
  onBack: () => void;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string}>;

const getHeaderSpecs = (props: ConnectedProps): HeaderSpec => {
  const { products, actions, router, params, addedProducts, previousProducts } = props;
  if (products.selectedPage === 'products') {
    let prevLocation = `${URLS.EVENTS}/${params.eventTypeId}/${params.eventId}/${params.name}/${EVENT.REGISTRATION}`;
    if (props.previousLocation) prevLocation = props.previousLocation.pathname;
    let addedLength = 0;
    if (addedProducts) addedLength += addedProducts.length;
    if (previousProducts) addedLength += previousProducts.length;
    return {
      onBack: () => navPush(router, prevLocation),
      added: true,
      addedLength: addedLength,
      search: true
    };
  } else {
    return {
      onBack: () => actions.selectPage('products'),
      added: false,
      search: false
    };
  }
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedEvent = makeSelectedEventSelector();
  const selectedEventType = makeSelectedEventTypeSelector();
  const addedProducts = makeAddedProductsProductsFilter();
  const previousProducts = makePreviousProductsProductsFilter();
  return {
    products: state.events.event.register.products.main,
    previousLocation: state.app.previousLocation,
    addedProducts: addedProducts(state),
    previousProducts: previousProducts(state),
    selectedEvent: selectedEvent(state),
    selectedEventType: selectedEventType(state)
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators(actionCreators, dispatch)});

export default (): any => {
  // @todo: subtitle
  const ProductsWizardHeader = (props: ConnectedProps): React.ReactElement<{}> => {
    const specs = getHeaderSpecs(props);
    return <AppHeader
      showDrawer
      added={specs.added}
      addedLength={specs.added ? specs.addedLength : undefined}
      search={specs.search}
      mobileSearch={specs.search}
      title="Products"
      subTitle={makeSubtitle(props.selectedEvent as EventTypeEvent, props.selectedEventType as EventType)}
      onBack={specs.onBack}
    />;
  };

  const ConnectedProductsWizardHeader = connect(
    mapStateToProps,
    mapDispatchToProps,
    getMergeProps<WithRouterProps>(),
  )(ProductsWizardHeader);
  return withRouter<{}>(ConnectedProductsWizardHeader);
};

