import { createValidateActions, createUpdateValueMethod,
  createSimpleUpdateValueMethod, createApiSubmitActions, innerApiSubmitFormMethod } from '../Validation/actionCreator';
import {ActionCreator, ApplicationState} from '../index';
import { IResetPasswordSearchActiveForm, IUpdatePasswordActiveForm, ResetPasswordContext } from '.';
import { GetResetPasswordSearchParams, ResetPasswordUpdateParams } from '../../constants/resetPasswordUrls';
import {actionCreators as appActionCreators} from "../App/actions";
import { IResetPasswordAccount } from '../../models/api/login';
import { ModalTypes } from '../../utils/modalHelper';
import { SaveState } from '../Rollback/actions';
import { makeFormModalPropSelector } from '../App';
import { RESET_PASSWORD_SEARCH_SUFFIX, RESET_PASSWORD_UPDATE_SUFFIX, SUFFIX } from './constants';
import { typeName, Action } from '../../utils/StrongActions';

export const ResetPasswordSearchValidateActions = createValidateActions(RESET_PASSWORD_SEARCH_SUFFIX);
export const UpdatePasswordValidateActions = createValidateActions(RESET_PASSWORD_UPDATE_SUFFIX);
export const ResetPasswordSearchSubmitActions = createApiSubmitActions(RESET_PASSWORD_SEARCH_SUFFIX);
export const ResetPasswordUpdateSubmitActions = createApiSubmitActions(RESET_PASSWORD_UPDATE_SUFFIX);

@typeName("RESET_PASSWORD_UPDATE_PASSWORD_FORM_INIT")
export class ResetPasswordUpdatePasswordFormInit extends Action {
  constructor() { super(); }
}

@typeName("RESET_PASSWORD_SEARCH_FORM_INIT")
export class ResetPasswordSearchFormInit extends Action {
  constructor() { super(); }
}
@typeName("SELECT_ACCOUNT")
export class ResetPasswordSelectAccount extends Action {
  constructor(public account: IResetPasswordAccount | undefined) { super(); }
}

export type Actions = typeof actionCreators;

const resetPasswordSearchFormCreator = (
  ActiveForm: IResetPasswordSearchActiveForm,
  DesiredURL?: string
): GetResetPasswordSearchParams => {
  // NOTE: `ActiveForm.EmailOrUsername` should NOT be undefined here because our validation code will capture and block that
  return {
    EmailOrUsername: ActiveForm.EmailOrUsername!,
    DesiredURL,
  };
};

const resetPasswordUpdateFormCreator = (
  ActiveForm: IUpdatePasswordActiveForm,
  account: IResetPasswordAccount,
  ResetCode: string,
): ResetPasswordUpdateParams => {
  return {
    ResetCode,
    AccountID: account.AccountID,
    IsAdmin: account.IsAdmin,
    NewPassword: ActiveForm.NewPassword!,
  };
};

export const actionCreators = {
  updatePasswordFormUpdateValue: createUpdateValueMethod(UpdatePasswordValidateActions, undefined, (s: ApplicationState) => s.resetPassword.UpdatePasswordForm),
  updatePasswordFormSimpleUpdate: createSimpleUpdateValueMethod(UpdatePasswordValidateActions),
  resetPasswordSearchFormUpdateValue: createUpdateValueMethod(ResetPasswordSearchValidateActions, undefined, (s: ApplicationState) => s.resetPassword.ResetPasswordSearchForm),
  resetPasswordSearchSimpleUpdate: createSimpleUpdateValueMethod(ResetPasswordSearchValidateActions),
  submitResetPasswordSearch: (): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const valid = innerApiSubmitFormMethod(
      dispatch,
      ResetPasswordSearchSubmitActions,
      (s: ApplicationState) => s.resetPassword.ResetPasswordSearchForm,
      undefined,
      undefined,
      undefined,
      true,
    );

    if (valid) {
      dispatch(
        ResetPasswordSearchSubmitActions.request(
          resetPasswordSearchFormCreator(
            state.resetPassword.ResetPasswordSearchForm.ActiveForm,
            state.app.afterLoginPath,
          ),
          null,
        ),
      );
    }
  },
  submitUpdatePassword: (
    account: IResetPasswordAccount,
    resetCode: string,
  ): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const valid = innerApiSubmitFormMethod(
      dispatch,
      ResetPasswordUpdateSubmitActions,
      (s: ApplicationState) => s.resetPassword.UpdatePasswordForm,
      undefined,
      undefined,
      undefined,
      true,
    );

    if (valid) {
      dispatch(appActionCreators.popModal(false, false, ModalTypes.RESET_PASSWORD) as any);
      dispatch(
        ResetPasswordUpdateSubmitActions.request(
          resetPasswordUpdateFormCreator(
            state.resetPassword.UpdatePasswordForm.ActiveForm,
            account,
            resetCode,
          ),
          null,
        ),
      );
    }
  },
  /**
   * This thunk is called when modal is first opened, and when clicking the "Back" button in ModalHeader
   * @returns 
   */
  init: (): ActionCreator => (dispatch, getState) => {
    const resetPasswordContextSelector = makeFormModalPropSelector(ModalTypes.RESET_PASSWORD, "resetPasswordContext");
    const resetPasswordAccountsSelector = makeFormModalPropSelector(ModalTypes.RESET_PASSWORD, "resetPasswordAccounts");

    const context = resetPasswordContextSelector(getState());

    if (context === ResetPasswordContext.SEND_RESET_LINK) {
      dispatch(new ResetPasswordSearchFormInit());
    } else {
      dispatch(new ResetPasswordUpdatePasswordFormInit());

      const resetPasswordAccounts = resetPasswordAccountsSelector(getState());

      // If there's only one reset account, select it right away
      if (resetPasswordAccounts && resetPasswordAccounts.length === 1) {
        dispatch(new ResetPasswordSelectAccount(resetPasswordAccounts[0]));
      }
    }
    dispatch(new SaveState());
  },
};
