import '../../../styles/layouts/filter/filter-column.scss';
import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';
import attributes, { AlignmentProps, MarginProps, LayoutProps } from '../../../styles';

export const namespace = (): string => 'layouts--filter-column';


interface Props extends AlignmentProps, MarginProps, LayoutProps {
  className?: string;
  width?: '198' | '228' | 'half' | 'none';
  hideInMobile?: boolean;
  keep?: boolean;     // keep this column even when there is a search field toggled in the same row
  lowerOrderInMobile?: boolean;   // use this to make this column show first in mobile
  order?: number;
  forceMobile?: boolean;
  alignSelf?: 'flex-start' | 'flex-end' | 'center';
}

class FilterColumn extends React.Component<Props, {}> {

  public render() {
    const {width, lowerOrderInMobile, hideInMobile, keep, order, forceMobile, alignSelf} = this.props;
    let className = attributes(namespace(), this.props);
    const style: React.CSSProperties = {};

    if (width) className += ` width-${width}`;
    if (keep) className += ' keep';
    if (lowerOrderInMobile) className += ' mobile-lower-order';
    if (hideInMobile ) className += ' mobile-hide';
    if (forceMobile) className += ' force-mobile';
    if (alignSelf) style.alignSelf = alignSelf;

    if (order) style.order = order;

    return (
      <div style={style} className={`${mergeClassNames(namespace(), this.props)} ${className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default FilterColumn;