import type {ActionCreator, ApplicationState} from '../../../../../index';
import {SaveState} from "../../../../../Rollback/actions";
import {EventRegisterParticipantSelectPage, EventRegisterParticipantSetPendingPage} from "../Main/actions";
import {createApiSubmitActions} from "../../../../../Validation/actionCreator";
import * as MP from '../../../../../../constants/messages/participantsWizard';
import {eventParticipantsRecalcBody} from "../../../../../../constants/urls";
import {ParticipantTab} from "../Main";
import {
  EventRegistrationParticipantsParticipantType, EventRegistrationPerson,
  PostEventRegistrationClass,
} from "../../../../../../models/api/cacheFourEvents";
import {composeClassRegistrations} from "../../../../../CacheFourEventsNumbers/actions";
import {CacheFourEventsParticipantsState} from "../../../../../CacheFourEventsParticipants";
import {EventRegistrationPaymentStatus} from "../../../../../../models/api/cacheThreeEvents";
import { typeName, Action } from '../../../../../../utils/StrongActions';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../../../utils/dataHelper';

export const SUFFIX = '__PARTICIPANT_TYPE';
export const ApiTypeSubmitActions = createApiSubmitActions(SUFFIX, true);

@typeName(`SELECT_TYPE${SUFFIX}`) export class EventRegisterParticipantSelectType extends Action {
  constructor(public pTypeID: number) { super(); }
}
@typeName(`SHOW_ERROR${SUFFIX}`) export class EventRegisterParticipantShowError extends Action {
  constructor(public errorMessage: string) { super(); }
}
@typeName(`RESET_TYPE${SUFFIX}`)
export class ResetReservationType extends Action {}

export type Actions = typeof actionCreators;

const formCreator = (rootState: ApplicationState, selectedTypeID: number) => {
  const options = rootState.cacheZero.options;
  const eventTypeID = rootState.cacheTwoEvents.eventTypeID;
  const eventType = rootState.cacheTwoEvents.eventType;
  const eventID = rootState.cacheThreeEvents.eventID;
  const c4 = rootState.cacheFourEventsParticipants as CacheFourEventsParticipantsState;
  const typeState = rootState.events.event.register.participant.type;

  let debugPayload: TentarooDebugPayload | undefined;
  if (!options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when submitting ParticipantType form");
  }
  if (!options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when submitting ParticipantType form");
  }

  const EventRegistrationPaymentStatus = c4.EventRegistrationPaymentStatus as EventRegistrationPaymentStatus;

  const classRegistrations: Array<PostEventRegistrationClass> = composeClassRegistrations(c4.EventClassesIndividualRegistered);

  let EventRegistrationPerson: EventRegistrationPerson = {
    ...c4.EventRegistrationPerson
  } as EventRegistrationPerson;

  if (eventType === 'participant') {
    let Amount, AmountMin;
    if (typeState.overwrittenTypes[selectedTypeID]) {
      Amount = typeState.overwrittenTypes[selectedTypeID].Amount;
      AmountMin = typeState.overwrittenTypes[selectedTypeID].AmountMin;
    } else {
      (c4.EventRegistrationParticipantTypes as Array<EventRegistrationParticipantsParticipantType>).forEach((pt) => {
        if (pt.IsYouth === c4.isYouth && pt.ID === selectedTypeID) {
          Amount = pt.Amount;
          AmountMin = pt.AmountMin;
        }
      });
    }

    EventRegistrationPerson = {
      ...c4.EventRegistrationPerson,
      Amount,
      AmountMin
    } as EventRegistrationPerson;
  }

  const route = rootState.routing.locationBeforeTransitions;
  const sp = route.pathname.split('/');
  let HasMultiWeekDiscount = c4.EventRegistrationPerson!.HasMultiWeekDiscount;
  if (sp.length === 8) { // then we're adding
    const pType = !rootState.cacheFourEventsParticipants.EventRegistrationParticipantTypes ? null : rootState.cacheFourEventsParticipants.EventRegistrationParticipantTypes.find(pt => pt.ID === selectedTypeID);
    if (!pType) {
      debugPayload = captureTentarooErrorAndGetRequestPayload('pType not found when saving participant - in Register/Participant/Type');
    } else {
      if (pType.HasMultiWeekDiscount !== undefined) {
        HasMultiWeekDiscount = pType.HasMultiWeekDiscount;
      }
    }
  }

  const payload = eventParticipantsRecalcBody(
    {
      GroupIDi: options?.Group?.IDi as any,
      GroupTS: options?.Group?.TS as any,
      EventTypeID: eventTypeID,
      EventIDi: eventID,
      GroupWeekIDi: EventRegistrationPaymentStatus.IDi,
      PersonIDi: c4.GroupRosterPerson ? c4.GroupRosterPerson.IDi : undefined,
      IsYouth: !!c4.isYouth,
      ClassRegistrations: classRegistrations,
      ParticipantTypeID: selectedTypeID,
      EventRegistrationPerson: EventRegistrationPerson,
      ParticipantRegistrationIDi: (c4.EventRegistrationPerson && c4.EventRegistrationPerson.IDi) ? c4.EventRegistrationPerson.IDi : undefined,
      HasMultiWeekDiscount: HasMultiWeekDiscount,
      ParticipantTypes: !rootState.cacheThreeEvents.EventInfoParticipantTypes ? [] : rootState.cacheThreeEvents.EventInfoParticipantTypes.map((pType) => pType.ID),
    }
  );

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  selectType: (pTypeID: number): ActionCreator => dispatch => dispatch(new EventRegisterParticipantSelectType(pTypeID)),
  typeSubmit: (page: ParticipantTab, isNext: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const {selectedTypeID, forceRecalc} = getState().events.event.register.participant.type;
    const c4 = getState().cacheFourEventsParticipants;
    if (!selectedTypeID || selectedTypeID === -1) {
      dispatch(new EventRegisterParticipantShowError(MP.PTYPE_REQUIRED));
      if (!isNext) {
        // allow on back
        dispatch(new EventRegisterParticipantSelectPage(page));
      }
    } else {
      if ((c4.EventRegistrationPerson as EventRegistrationPerson).ParticipantTypeID === selectedTypeID && !forceRecalc) {
        dispatch(new EventRegisterParticipantSelectPage(page));
      } else {
        dispatch(ApiTypeSubmitActions.request(formCreator(getState(), selectedTypeID), null));
        dispatch(new EventRegisterParticipantSetPendingPage(page));
      }
    }
  },
};
