import { GetAdminEventProductCacheBodyParams } from "../../../constants/adminEventsUrls";
import { CLEAR_CACHE_PREFIX, createRequestActions } from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { HideDrawer, SilentCancelAllAction } from '../../../store/App/actions';
import { ADMIN_EVENT_TYPES_SUFFIX, ADMIN_EVENTS_MODULE_SUFFIX } from "../CacheOne/actions";
import { UPDATE_EVENT_PRODUCT_FORM_SUFFIX } from "../Products/Form/actions";
import { typeName, Action } from "../../../utils/StrongActions";


export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + UPDATE_EVENT_PRODUCT_FORM_SUFFIX + '__CACHE_TWO_PRODUCT';
export const SUFFIX_SAVE = ADMIN_EVENT_TYPES_SUFFIX + '__CACHE_TWO_PRODUCT_SAVING';

export const GetAdminEventProduct = createRequestActions(SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminEventsCacheTwoProduct extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  getProduct: (params: GetAdminEventProductCacheBodyParams): ActionCreator => dispatch => {
    dispatch(GetAdminEventProduct.request(params));
  },
  refreshProduct: (params: GetAdminEventProductCacheBodyParams): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    dispatch(GetAdminEventProduct.request(params));
  },
  clearAdminEventsCacheTwoProduct: (): ActionCreator => dispatch => {
    dispatch(new ClearAdminEventsCacheTwoProduct());
  },
};