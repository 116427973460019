import * as React from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  RadioGroupOption,
  Row,
  SimpleList,
  Text
} from '../../../../../Elements';
import {
  makeFilteredClassesSelector
} from "../../../../../../store/Events/Event/Modals/ClassAddUnscheduled";
import '../../../../../../styles/pages/events/event/modals/class-add-unscheduled/index.scss';
import {actionCreators} from "../../../../../../store/Events/Event/Modals/ClassAddUnscheduled/actions";
import {actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import {bindActionCreators} from 'redux';
import {ClassType} from "../../../../../../models/api/options";
import {noop} from "../../../../../../utils";
import { ApplicationState } from '../../../../../../store';
import { EventTypeRegistrationSettings } from '../../../../../../models/api/cacheTwoEvents';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--event--modals--class-add-unscheduled';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = WithInertAttribute<{}>

class AddUnscheduledModal extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  componentWillMount() {
    this.props.actions.selectClass();
  }

  componentWillUnmount() {
    this.onSearchBack();
  }

  onSearchOpen = () => {
    this.props.actions.showSearch(true);
  };

  onSearchBack = () => {
    this.props.actions.showSearch(false);
    this.props.actions.filterClasses('');
  };

  onSelect = (c: ClassType) => this.props.actions.selectClass(c);
  onAdd = () => {
    const {actions, addUnscheduledClass: {selectedClass}} = this.props;
    if (selectedClass) {
      actions.addClass(selectedClass);
      this.onSearchBack();
      actions.popModal(false, true, ModalTypes.ADD_UNSCHEDULED);
    } else {
      actions.showTopFloatingAlert('Please select a class to add.', true, 'orange');
    }
  };

  itemRenderer = (index) => {
    const c = this.props.filteredClasses[index];
    const {addUnscheduledClass: {selectedClass}} = this.props;

    const selected = selectedClass ? c.IDi === selectedClass.IDi : false;
    return (
      <Row className={`${namespace()}--class`}
           verticalAlignment="middle" onClick={this.onSelect.bind(this, c)}
           horizontalAlignment="left" key={index} paddingVertical={12} paddingHorizontal={16}>
        <RadioGroupOption
          value={selected}
          selected={selected}
          onChange={noop}
        />
        <Text className={`${namespace()}--class--text`} marginLeft={16} wrap={false} weight={selected ? 'bold' : 'regular'}>{c.Name}</Text>
      </Row>
    );
  };

  public render() {
    const { filteredClasses, inert, actions, cacheTwoEvents, addUnscheduledClass: {showSearch} } = this.props;
    const EventTypeRegistrationSettings = cacheTwoEvents.EventTypeRegistrationSettings as EventTypeRegistrationSettings;
    let classesString;
    if (EventTypeRegistrationSettings.NamesRegistrationSettings.ShowParticipantClassesAs) {
      classesString = EventTypeRegistrationSettings.NamesRegistrationSettings.ShowParticipantClassesAs;
    }

    return (
      <Modal inert={inert} big mobileFullScreen className={namespace()} onClose={this.onSearchBack}>
        <ModalHeader
          red
          isSearching={!!showSearch}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={actions.filterClasses}
          onSearchBack={this.onSearchBack}
          className={`${namespace()}--header`}
        >
          {`Unscheduled ${!!classesString ? 'Options' : 'Classes'}`}
        </ModalHeader>
        <ModalContent paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0}>
          <Row layout="vertical" className={`${namespace()}--view`}>
            <SimpleList className={`${namespace()}--list`} itemRenderer={this.itemRenderer} totalLength={filteredClasses.length}/>
          </Row>
          <Row className={`${namespace()}--footer-row`}>
            <Button flat textColor="black" onClick={this.onAdd}>ADD CLASS</Button>
          </Row>
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredClassesSelector = makeFilteredClassesSelector();
  return {
    addUnscheduledClass: state.events.event.modals.addUnscheduledClass,
    filteredClasses: filteredClassesSelector(state),
    cacheTwoEvents: state.cacheTwoEvents,
  };
};

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators
}, dispatch) });

const ConnectedAddUnscheduledModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(AddUnscheduledModal);

export default ConnectedAddUnscheduledModal;
