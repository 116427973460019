import {reduxStoreService} from "../../store/service";

export function isLoginCachePopulated() {
  const rootState = reduxStoreService().getState();
  
  return !!rootState.session.SystemSettings; 
};

export function isCreateFormCachePopulated() {
  const rootState = reduxStoreService().getState();

  return !!rootState.createAccount.Loaded;
};