import * as React from 'react';
import {Main, MainContent} from '../../../../../../Layouts';
import {
  Alert,
  Button,
  Card,
  Column,
  ContentBlock,
  DatePicker,
  FieldSet,
  Link,
  MoneyField,
  NumberField,
  Row,
  Select,
  SubTitle,
  Text,
  Tickets,
  Title
} from '../../../../../../Elements';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import PricingInfo from '../../../PricingInfo';
import '../../../../../../../styles/pages/events/event/register/numbers/index.scss';
import TextField from "../../../../../../Elements/TextField/index";
import {NumbersConnectedProps} from "../index";
import {EventRegistrationParticipantType} from "../../../../../../../models/api/cacheFourEvents";
import {actionCreators} from '../../../../../../../store/Events/Event/Register/Numbers/Spots/actions';
import {bindActionCreators} from 'redux';
import {checkSpotsAndAmountValidation, getParticipantTypeKey, makeIsNewlyAddingRegistrationSelector, makeSpotFilter} from "../../../../../../../store/Events/Event/Register/Numbers/Spots/validation";
import {EventTypeRegistrationSettings} from "../../../../../../../models/api/cacheTwoEvents";
import {EventInfo, EventRegistrationPaymentStatus} from "../../../../../../../models/api/cacheThreeEvents";
import {determineAvailability, spotsOnlySelector} from "../../../../../../../utils/eventsHelper";
import RegisterNumbersSpotCard, {SpotsCardType} from "./Card";
import { ApplicationState } from '../../../../../../../store';
import { openSupportForm } from '../../../../../../../constants/urls';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../../store/service';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--events--event--register--numbers';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = WithInertAttribute<{
  numberProps: NumbersConnectedProps;
}>;

class Numbers extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  getSpotsAvailableText = (available: number) => {
    if (available < 0) return 'FULL';
    return `${available} spot${available !== 1 ? 's' : ''} available`;
  };

  renderSpotCard = (pType: EventRegistrationParticipantType, i) => {
    const k = getParticipantTypeKey(pType);
    const {
      cacheTwoEvents: {eventType, EventTypeRegistrationSettings},
      cacheThreeEvents:{EventInfo, participantTypeMap},
      cacheFourEventsNumbers: {EventRegistrationPaymentStatus},
    } = this.props.numberProps;
    const {spots: {ActiveForm, ValidationRules, EventRegistrationParticipantTypes, EventRegistrationSpots}, SpotsOnly, actions} = this.props;
    const {Name, CurrentAmount, NumAdded, AmountMin} = pType;

    let ParticipantTypeForm;
    let SpotForms: any[] = [];

    if (SpotsOnly) {
      ParticipantTypeForm = EventRegistrationParticipantTypes ? EventRegistrationParticipantTypes.find((pt) => {
        return pt.ActiveForm.ID === pType.ID;
      }) : null;
    } else {
      SpotForms = EventRegistrationSpots ? [...EventRegistrationSpots.filter((spot) => {
        return spot.ActiveForm.PTID === pType.ID;
      })] : [];
    }

    const onNumberChange = (value, vObj) => {
      const overrideStateSelector = SpotsOnly ? (s: ApplicationState) => s.events.event.register.numbers.spots.EventRegistrationParticipantTypes.find((pt) => pt.ActiveForm.ID === pType.ID) : (s: ApplicationState) => s.events.event.register.numbers.spots.EventRegistrationSpots.find((spot) => spot.ActiveForm.PTID === pType.ID);

      actions.updateSpotValue(
        value,
        vObj,
        overrideStateSelector,
        true,
        (s: ApplicationState) => s.events.event.register.numbers.spots,
      );
    };

    const showCheck = (
      eventType === 'both'
      && (EventTypeRegistrationSettings as EventTypeRegistrationSettings).RegistrationSettings.HasNamesRegistration
      && (EventInfo as EventInfo).HasGroupRegistration
      && NumAdded > 0
    );

    let SpotSum = 0;
    
    if (SpotsOnly) SpotSum = ParticipantTypeForm.ActiveForm[k] || 0;
    else {
      SpotForms
      .filter(makeSpotFilter(true))
      .forEach((form) => {
        const parsed = parseInt(form.ActiveForm.NumSpots || 0);
        SpotSum += parsed || 0;
      });
    }
    const available: number | undefined = determineAvailability(
      // (EventInfo as EventInfo).Num,
      (EventRegistrationPaymentStatus as EventRegistrationPaymentStatus).NumEventSpotsAvailable - ActiveForm.SumParticipants, // need to subtract the sub of all pTypes
      pType.NumSpotsAvailable - SpotSum,
      (EventInfo as EventInfo).MaxParticipants,
      participantTypeMap[pType.ID].MaxParticipants,
      true,
    );

    const showAvailable = (EventInfo && EventInfo.MaxParticipants > 0) || (participantTypeMap[pType.ID].MaxParticipants > 0);
    let spotError;
    
    if (!SpotsOnly) {
      spotError = checkSpotsAndAmountValidation(reduxStoreService().getState(), pType, SpotSum);
    }

    return (
      <RegisterNumbersSpotCard
        key={`register_number_spot_card${i}`}
        type={SpotsOnly ? SpotsCardType.SPOTS_ONLY : SpotsCardType.SPOTS_AND_AMOUNT}
        pType={pType}
        available={available}
        MaxParticipants={participantTypeMap[pType.ID].MaxParticipants}
        showCheck={showCheck}
        ParticipantTypeForm={ParticipantTypeForm}
        SpotForms={SpotForms}
        onSpotsSimpleUpdate={actions.simpleUpdateSpot}
        onSpotsChange={onNumberChange}
        onAddSpot={actions.addSpot}
        showAvailable={showAvailable}
        spotError={spotError}
      />
    );
  };

  getSpotsError = (): string | undefined => {
    const {spots, classes, campsite, confirmation, numberProps: {cacheThreeEvents}} = this.props;

    if (!cacheThreeEvents.EventRegistrationNumbers) { // newly adding
      if (spots.SubmitErrorMessage) return spots.SubmitErrorMessage;
    } else {
      if (spots.SubmitErrorMessage) return spots.SubmitErrorMessage;
      else if (classes.SubmitErrorMessage && classes.isError) return classes.SubmitErrorMessage;
      else if (campsite.SubmitErrorMessage) return campsite.SubmitErrorMessage;
      else if (confirmation.SubmitErrorMessage) return confirmation.SubmitErrorMessage;
      else if (classes.SubmitErrorMessage && !classes.isError) return classes.SubmitErrorMessage;
    }
    return undefined;
  };

  onCopyFromProfile = () => {
    this.props.actions.copyFromProfile();
  };

  onApplyLeaderRatios = () => {
    const {actions} = this.props;

    actions.spotsSubmit("spots", undefined, true);
  };

  public render() {
    const {
      cacheTwoEvents: {EventTypeRegistrationSettings},
      cacheThreeEvents: {EventInfo},
      cacheFourEventsNumbers: {EventRegistrationPaymentStatus, EventRegistrationParticipantTypes},
      youthTypes,
      adultTypes,
      apiLoading,
    } = this.props.numberProps;
    if (!EventTypeRegistrationSettings || !EventRegistrationPaymentStatus || !EventRegistrationParticipantTypes) return null;
    const {
      RegistrationSettings: {ShowYouth, ShowAdults},
      NumbersRegistrationSettings: {ShowGroupRegistrationNotes, PromptGroupRegistrationNotes, ShowIsCampingOvernight}
    } = EventTypeRegistrationSettings;
    const {NumEventSpotsAvailable} = EventRegistrationPaymentStatus;
    const {spots: {ActiveForm, ValidationRules}, SpotsOnly, actions, isNewlyAddingRegistration, isAdmin, inert} = this.props;

    let totalSpots = NumEventSpotsAvailable - ActiveForm.SumParticipants;
    let ticketsText: string = '';
    if (totalSpots < 0) {
      ticketsText = `FULL - Reduce the number of spots by ${Math.abs(totalSpots)} before saving.`;
    } else {
      ticketsText = `${totalSpots} spot${totalSpots !== 1 ? 's' : ''} available`;
    }
    let inlineError = this.getSpotsError();

    return (
      <Main
        inert={inert}
        key="numbers"
        mobileBackground="white"
        header={<HeaderProgress loading={apiLoading > 0} c2={this.props.numberProps.cacheTwoEvents} c4={this.props.numberProps.cacheFourEventsNumbers} selected="spots"/>}
        footer={<FooterProgress selected="spots"/>}
      >
        <MainContent loading={apiLoading > 0}>
          {inlineError && <Alert noFlex>{inlineError}</Alert>}
          {!SpotsOnly && isAdmin && <Alert className={`${namespace()}--alert--contact`}>
            <Text color="white" size={14}>Only change amounts here to adjust the number of spots at early, base and late pricing. For discounts and other pricing changes, use individual/group options or pricing tiers. <Link medium externalLink={openSupportForm(true)}>Contact Tentaroo</Link> for more details.</Text></Alert>}
          <ContentBlock>
            <PricingInfo EventInfo={EventInfo}/>
            <div className={`${namespace()}--spots-available-row`}>
              {EventInfo && EventInfo.MaxParticipants > 0 && <Tickets text={ticketsText}
                      strong
                      red={totalSpots < 0}
              />}
              {isAdmin && !SpotsOnly && <Button admin color="white" textColor="black" onClick={this.onApplyLeaderRatios}>APPLY LEADER RATIOS</Button>}
            </div>
            {ShowYouth && <SubTitle mobileMarginTop={0}>Youth</SubTitle>}
            {ShowYouth && youthTypes.map(this.renderSpotCard)}
            {ShowAdults && <SubTitle mobileMarginTop={0}>Adults</SubTitle>}
            {ShowAdults && adultTypes.map(this.renderSpotCard)}

          </ContentBlock>
          {isAdmin && !SpotsOnly && <div className={`${namespace()}--apply-leader-ratios--row`}>
              <Button admin color="white" textColor="black" onClick={this.onApplyLeaderRatios}>APPLY LEADER RATIOS</Button>
            </div>}
          {(isAdmin || ShowIsCampingOvernight || ShowGroupRegistrationNotes || EventTypeRegistrationSettings.NumbersRegistrationSettings.ShowEventContact) && <ContentBlock>
            <Card template="mobile-no-margin" padding="regular">
              <FieldSet
                marginBottom={0}
                fixedLegendFontSize
                className={`${namespace()}--registration-details`}
                newDesign
                legendMarginBottom={24} fontSize={22} name="Registration Details">
                  {isAdmin && <Row marginBottom={8}>
                    <Column span={6} mobileSpan={12}>
                      <DatePicker
                        isAdmin
                        label="Registration Date"
                        value={ActiveForm.DateRegistration}
                        validationRules={ValidationRules.DateRegistration}
                        onSelect={actions.updateValue}
                        onChangeRaw={actions.simpleUpdate}
                        disabled={isNewlyAddingRegistration}
                        removeMax
                      />
                    </Column>
                    <Column span={3} mobileSpan={6}>
                      <MoneyField
                        isAdmin
                        adminBadgePosition="inside"
                        dollarSignPrefix
                        value={ActiveForm.CampsiteDepositAmount}
                        validationRules={ValidationRules.CampsiteDepositAmount}
                        onBlur={actions.updateValue}
                        onChange={actions.simpleUpdate}
                        label='Campsite Deposit'
                        overflowLabel
                        placeHolder="$0.00"
                      />
                    </Column>
                  </Row>}
                  {ShowIsCampingOvernight && <Row marginBottom={8}>
                    <Column span={12}>
                      <Select label="Camping Overnight"
                        onChangeValue={actions.updateValue}
                        value={ActiveForm.CampingOvernight}
                        validationRules={ValidationRules.CampingOvernight}
                        isNumber
                      />
                    </Column>
                  </Row>}
                  {ShowGroupRegistrationNotes && <Row marginBottom={8}>
                    <Column span={12}>
                      <TextField
                        label={PromptGroupRegistrationNotes ? PromptGroupRegistrationNotes : 'Registration Notes'}
                        value={ActiveForm.GroupRegistrationNotes}
                        onChange={actions.simpleUpdate}
                        onBlur={actions.updateValue}
                        validationRules={ValidationRules.GroupRegistrationNotes}
                        rows={3}/>
                    </Column>
                  </Row>}
                  {isAdmin && <Row marginBottom={8}>
                    <Column span={12}>
                      <TextField
                        isAdmin
                        adminBadgePosition="inside"
                        label='Notes for Group'
                        value={ActiveForm.NotesForGroup}
                        onChange={actions.simpleUpdate}
                        onBlur={actions.updateValue}
                        validationRules={ValidationRules.NotesForGroup}
                        rows={3}/>
                    </Column>
                  </Row>}
                  {isAdmin && <Row marginBottom={8}>
                    <Column span={12}>
                      <TextField
                        isAdmin
                        adminBadgePosition="inside"
                        label='Admin Notes'
                        value={ActiveForm.AdminNotes}
                        onChange={actions.simpleUpdate}
                        onBlur={actions.updateValue}
                        validationRules={ValidationRules.AdminNotes}
                        rows={3}/>
                    </Column>
                  </Row>}
              </FieldSet>
              {EventTypeRegistrationSettings.NumbersRegistrationSettings.ShowEventContact && <FieldSet
                newDesign
                marginTop={16}
                marginBottom={0}
                fontSize={18}
                legendMarginBottom={16}
                name="Primary Event Contact"
                controlsNoMarginTop
                className={`${namespace()}--primary-contact`}
                controls={
                  <Button color="white" onClick={this.onCopyFromProfile}>
                    Copy From Profile
                  </Button>
                }
              >
                <Row marginBottom={8}>
                  <Column span={6} mobileSpan={12}>
                    <TextField
                      label='Name'
                      value={ActiveForm.ContactName}
                      onChange={actions.simpleUpdate}
                      onBlur={actions.updateValue}
                      validationRules={ValidationRules.ContactName}
                    />
                  </Column>
                </Row>
                <Row marginBottom={8}>
                  <Column mobileMarginBottom={8} span={6} mobileSpan={12}>
                    <TextField
                      label='Email'
                      value={ActiveForm.ContactEmail}
                      onChange={actions.simpleUpdate}
                      onBlur={actions.updateValue}
                      validationRules={ValidationRules.ContactEmail}
                    />
                  </Column>
                  <Column span={6} mobileSpan={12}>
                    <TextField
                      label='Phone'
                      value={ActiveForm.ContactPhone}
                      onChange={actions.simpleUpdate}
                      onBlur={actions.updateValue}
                      validationRules={ValidationRules.ContactPhone}
                    />
                  </Column>
                </Row>
              </FieldSet>}
              {EventTypeRegistrationSettings.NumbersRegistrationSettings.ShowEventContact && <FieldSet
                newDesign
                marginTop={16}
                marginBottom={0}
                fontSize={18}
                legendMarginBottom={16}
                name="Alternate Contact"
                controlsNoMarginTop
              >
                <Row marginBottom={8}>
                  <Column span={6} mobileSpan={12}>
                    <TextField
                      label='Name'
                      value={ActiveForm.ContactName2}
                      onChange={actions.simpleUpdate}
                      onBlur={actions.updateValue}
                      validationRules={ValidationRules.ContactName2}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column mobileMarginBottom={8} span={6} mobileSpan={12}>
                    <TextField
                      label='Email'
                      value={ActiveForm.ContactEmail2}
                      onChange={actions.simpleUpdate}
                      onBlur={actions.updateValue}
                      validationRules={ValidationRules.ContactEmail2}
                    />
                  </Column>
                  <Column span={6} mobileSpan={12}>
                    <TextField
                      label='Phone'
                      value={ActiveForm.ContactPhone2}
                      onChange={actions.simpleUpdate}
                      onBlur={actions.updateValue}
                      validationRules={ValidationRules.ContactPhone2}
                    />
                  </Column>
                </Row>
              </FieldSet>}
            </Card>
          </ContentBlock>}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const isNewlyAddingRegistrationSelector = makeIsNewlyAddingRegistrationSelector();
  const isAdmin = state.user.user ? state.user.user.str_permissions.hasAdminAccess : false;
  return {
    spots: state.events.event.register.numbers.spots,
    classes: state.events.event.register.numbers.classes,
    campsite: state.events.event.register.numbers.campsite,
    confirmation: state.events.event.register.numbers.confirmation,
    SpotsOnly: spotsOnlySelector(state),
    isAdmin,
    isNewlyAddingRegistration: isNewlyAddingRegistrationSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators
}, dispatch)});

const ConnectedNumbers = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(Numbers);

export default ConnectedNumbers;
