import * as React from 'react';
import '../../../../styles/elements/resource/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton, Checkbox} from '../../index';
import { OptionsIcon, GlobeIcon, LinkIcon, EditIcon, CloseIcon } from '../../../../components/Icons';
import { CMSResource } from '../../../../models/api/adminCMSCacheOne';
import ContextMenuComponent from "./ContextMenu";
import { RouteComponentProps, withRouter, WithRouterProps } from 'react-router';
import { ADMIN_CMS_RESOURCES_PREVIEW_PATH } from '../../../../routes';
import { ApplicationState } from '../../../../store';
import ResourceIcon from '../../ResourceIcon';
import { SELECTED_ITEM_DELETED } from '../../../../constants/messages/generic';
import { stripFileExtension } from '../../../../utils/fileHelper';
import { CardCategory } from '../../Card';
import { Row } from '../../Grid';
import { namespace as cardNS } from "../../Card/constants";
import { isCardClicked } from '../../../../utils/navHelper';
import { isMobile } from '../../../../utils/isMobile';
import { isPathnameMatchingRoute } from '../../../../utils/urlHelper';
import { generateDOMId } from '../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';

export const namespace = (): string => 'elements--resource--card';

export enum ResourceCardType {
  LIST='list',
  EDIT_RESOURCE='edit',
  ADD_RESOURCE='add',
  PREVIEW='preview',
  MODAL_SELECT='modal-select',
  IN_MODAL='in-modal',
  EDIT_PAGE='edit-page',
  MULTIPLE_RESOURCES='multiple-resources',
}
interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin' | 'info' | 'mobile-full';
  type: ResourceCardType;
  resource: CMSResource;
  categoryName?: string;
  noBoxShadow?: boolean;
  showDeletedPrompt?: boolean;
  onEdit?: (resource: CMSResource) => void;
  onPreview?: (resource: CMSResource) => void;
  onCopyLink?: (resource: CMSResource) => void;
  onRestore?: (resource: CMSResource) => void;
  onDelete?: (resource: CMSResource) => void;
  onDownload?: (resource: CMSResource) => void;
  onCardClick?: (resource: CMSResource) => void;
  onRemove?: (resource: CMSResource) => void;
  onMultipleSelect?: (resource: CMSResource) => void;
  checked?: boolean;
  disabled?: boolean;
  cardCategory?: CardCategory;
  routes?: any;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & RouteComponentProps<{},{resourceId: string}>;
class ResourceCard extends React.Component<Props> {
  props: Props & ConnectedProps;

  shouldAllowPropagation = (e) => {
    const { routes } = this.props;
    const route = routes[routes.length - 1];
    const clickedOnCard = isCardClicked(e, namespace);
    return (route.path === ADMIN_CMS_RESOURCES_PREVIEW_PATH && !clickedOnCard);
  };

  onMultipleSelectClick = (e) => {
    const {onMultipleSelect, resource} = this.props;
    !this.props.disabled && onMultipleSelect && onMultipleSelect(resource);
  };
  onPreviewClick = (e) => {
    const {onPreview, resource} = this.props;
    if (this.shouldAllowPropagation(e)) return;
    e.stopPropagation();
    onPreview && onPreview(resource);
  };
  onEditClick = (e) => {
    if (this.shouldAllowPropagation(e)) return;
    const {onEdit, resource} = this.props;
    e.stopPropagation();
    !this.props.disabled && onEdit && onEdit(resource);
  };
  onCopyLinkClick = (e) => {
    const {onCopyLink, resource} = this.props;
    e.stopPropagation();
    onCopyLink && onCopyLink(resource);
  };
  onRestoreClick =(e) => {
    const {onRestore, resource} = this.props;
    if (this.shouldAllowPropagation(e)) return;
    !this.props.disabled && e.stopPropagation();
    onRestore && onRestore(resource);
  };
  onCardClick = (e) => {
    const {type, resource, disabled} = this.props;
    if (resource.Inactive || disabled) return;
    switch (type) {
      case ResourceCardType.LIST:
        this.onPreviewClick(e);
        break;
      case ResourceCardType.PREVIEW:
      case ResourceCardType.ADD_RESOURCE:
      case ResourceCardType.EDIT_RESOURCE:
      case ResourceCardType.MODAL_SELECT:
        this.onDownloadClick(e);
        break;
      case ResourceCardType.IN_MODAL:
      case ResourceCardType.EDIT_PAGE:
        this.props.onCardClick && this.props.onCardClick(resource);
        break;
      case ResourceCardType.MULTIPLE_RESOURCES:
        this.props.onMultipleSelect && this.props.onMultipleSelect(resource);
        break;
      default:
        break;
    }
  };
  onDownloadClick = (e) => {
    const {onDownload, resource} = this.props;
    e.stopPropagation();
    onDownload && onDownload(resource);
  };
  onDeleteClick = (e) => {
    const {onDelete, resource} = this.props;
    e.stopPropagation();
    !this.props.disabled && onDelete && onDelete(resource);
  };
  onRemoveClick = (e) => {
    if (this.shouldAllowPropagation(e)) return;
    e.stopPropagation();
    const {onRemove, resource} = this.props;
    !this.props.disabled && onRemove && onRemove(resource);
  };
  mapFileTypeToIcon = () => {
    const {resource} = this.props;

    if (resource.File) {
      const result = stripFileExtension(resource.File.Filename);
      const ext = result && result.extension;
      if (!ext || ext.length > 4) {
        return <ResourceIcon fill='#294F98' />;
      }
      switch (ext.toLowerCase()) {
        case 'xls':
        case 'xlsx':
        case 'csv':
          return <ResourceIcon title={ext.toLowerCase()} resourceType={ext.toUpperCase()} fill='#458B5B' />;
        case 'doc':
        case 'docx':
        case 'rtf':
        case 'txt':
          return <ResourceIcon title={ext.toLowerCase()} resourceType={ext.toUpperCase()} fill='#294F98' />;
        case 'zip':
        case '7zip':
          return <ResourceIcon title={ext.toLowerCase()} resourceType={ext.toUpperCase()} fill='#F4B343' />;
        case 'pdf':
          return <ResourceIcon title={ext.toLowerCase()} resourceType={ext.toUpperCase()} fill='#E12341' />;
        case 'ppt':
        case 'pptx':
          return <ResourceIcon title={ext.toLowerCase()} resourceType={ext.toUpperCase()} fill='#C65133' />;
        case 'jpeg':
          return <ResourceIcon title='jpg' resourceType='JPG' fill='#52A8F3' />;
        case 'jpg':
        case 'png':
        case 'gif':
        case 'tiff':
          return <ResourceIcon title={ext.toLowerCase()} resourceType={ext.toUpperCase()} fill='#52A8F3' />;
        case 'html':
        case 'htm':
          return <ResourceIcon title={ext.toLowerCase()} resourceType={ext.toUpperCase()} fill='#D65736' />;
        case 'avi':
        case 'mpg':
        case 'mp4':
        case 'mov':
            return <ResourceIcon title={ext.toLowerCase()} resourceType={ext.toUpperCase()} fill='#DD2D1F' />;
        case 'mp3':
        case 'm4a':
        case 'wav':
            return <ResourceIcon title={ext.toLowerCase()} resourceType={ext.toUpperCase()} fill='#2D77CB' />;
        default:
          return <ResourceIcon title={ext.length < 4 ? ext.toLowerCase() : ''} resourceType={ext.length < 4 ? ext.toUpperCase() : ''} fill='#294F98' />;
      }
    }
    return null;
  };
  renderForList = () => {
    const {categoryName, onRestore, resource, routes} = this.props;
    const route = routes[routes.length - 1];
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>{resource.Name}</Row>
          {categoryName && <Row className={`${namespace()}--main--text--subtitle`}>{categoryName}</Row>}
          {!categoryName && resource.File && <Row  className={`${namespace()}--main--text--subtitle`}>{resource.File.Filename}</Row>}
        </div>
        {!resource.Inactive && <ActionButton
          id={generateDOMId(`admin-cms-resource-${resource.ID}-option-btn`)}
          icon={OptionsIcon}
          allowPropagation={route.path === ADMIN_CMS_RESOURCES_PREVIEW_PATH}
          className={`${namespace()}--options ${cardNS()}--options`}
          contextMenu={<ContextMenuComponent
            resourceId={resource.ID}
            onPreview={this.onPreviewClick}
            onCopyLink={this.onCopyLinkClick}
            onEdit={this.onEditClick}
            onDelete={this.onDeleteClick} />}
        />}
        {resource.Inactive && onRestore && <Button className={`${cardNS()}--restore`} flat textColor="red" onClick={this.onRestoreClick}>RESTORE</Button>}
      </Row>
    );
  };
  renderInModal = () => {
    const {categoryName, cardCategory, resource} = this.props;
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>{resource.Name}</Row>
          {categoryName && <Row className={`${namespace()}--main--text--subtitle`}>{categoryName}</Row>}
          {!categoryName && resource.File && <Row className={`${namespace()}--main--text--subtitle`}>{resource.File.Filename}</Row>}
        </div>
        {!resource.Inactive && <ActionButton
          id={generateDOMId(`admin-cms-resource${resource.ID}-edit-btn`)}
          icon={EditIcon}
          onClick={this.onEditClick}
          className={`${cardNS()}--edit ${cardCategory}`}
        />}
      </Row>
    );
  };
  renderModalSelect = () => {
    const {categoryName, disabled, resource, cardCategory} = this.props;

    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>{resource.Name}</Row>
          {categoryName && <Row className={`${namespace()}--main--text--subtitle`}>{categoryName}</Row>}
          {!categoryName && resource.File && <Row className={`${namespace()}--main--text--subtitle`}>{resource.File.Filename}</Row>}
        </div>
        {!resource.Inactive && <ActionButton
          id={generateDOMId(`admin-cms-resource${resource.ID}-edit-btn`)}
          icon={EditIcon}
          disabled={disabled}
          onClick={this.onEditClick}
          className={`${cardNS()}--edit ${cardCategory}`}
        />}
        <ActionButton
          onClick={this.onRemoveClick}
          icon={CloseIcon}
          disabled={disabled}
          className={`${cardNS()}--close`}
        />
      </Row>
    );
  };
  renderWithoutActions = () => {
    const {resource, cardCategory} = this.props;
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>{resource.File && resource.File.Filename}</Row>
        </div>
        <ActionButton title='Remove File' ripple={false} onClick={this.onRemoveClick} icon={CloseIcon} className={`${cardNS()}--action--btn ${cardCategory}`} />
      </Row>
    );
  };
  renderWithActions = (allowRemove?: boolean) => {
    const {resource, cardCategory} = this.props;
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>{resource.File && resource.File.Filename}</Row>
        </div>
        {resource.File && resource.File.ID ? <ActionButton title='Download file' ripple={false} onClick={this.onDownloadClick} icon={GlobeIcon} className={`${cardNS()}--action--btn`} /> : null}
        {resource.File && resource.File.ID ? <ActionButton title='Copy link' ripple={false} onClick={this.onCopyLinkClick} icon={LinkIcon} className={`${cardNS()}--action--btn`} /> : null}
        {allowRemove ? <ActionButton title='Remove File' ripple={false} onClick={this.onRemoveClick} icon={CloseIcon} className={`${cardNS()}--action--btn ${cardCategory}`} /> : null}
      </Row>
    );
  };

  renderInMultipleResources = () => {
    const {categoryName, onRestore, resource, routes, cardCategory} = this.props;
    const route = routes[routes.length - 1];
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>{resource.Name}</Row>
          {categoryName && <Row className={`${namespace()}--main--text--subtitle`}>{categoryName}</Row>}
          {!categoryName && resource.File && <Row className={`${namespace()}--main--text--subtitle`}>{resource.File.Filename}</Row>}
        </div>
        <ActionButton
          id={generateDOMId(`resource-card-edit-btn-${resource.ID}`)}
          icon={EditIcon}
          onClick={this.onEditClick}
          className={`${cardNS()}--edit ${cardCategory}`}
        />
      </Row>
    );
  };

  renderInEditPage = () => {
    const {categoryName, resource, disabled, cardCategory} = this.props;
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>{resource.Name}</Row>
          {categoryName && <Row className={`${namespace()}--main--text--subtitle`}>{categoryName}</Row>}
        </div>
        {!resource.Inactive && <ActionButton
          id={generateDOMId(`admin-cms-resource${resource.ID}-edit-btn`)}
          icon={EditIcon}
          disabled={disabled}
          onClick={this.onEditClick}
          className={`${cardNS()}--edit ${cardCategory}`}
        />}
        {<ActionButton
          icon={CloseIcon}
          disabled={disabled}
          onClick={this.onRemoveClick}
          className={`${cardNS()}--close ${cardCategory}`}
        />}
      </Row>
    );
  };

  render() {
    const {resource, type, template, location, showDeletedPrompt, noBoxShadow, checked, disabled, cardCategory} = this.props;
    let className = attribute(namespace(), undefined, this.props);
  
    const deleted = !resource || resource.Inactive;
    if (showDeletedPrompt) className += ' show-deleted-prompt';
    if (deleted) className += ' deleted';
    if (checked) className += ' checked';
    if (disabled) className += ' disabled';
    if (!resource.File || !resource.File.filePath) className += ' not-uploaded';
    className += ` ${type}`;
    if (isMobile) className += ' mobile';
    
    const selected = type === ResourceCardType.LIST && isPathnameMatchingRoute(location.pathname, ADMIN_CMS_RESOURCES_PREVIEW_PATH, 'resourceId', resource.ID);
    // @todo - add file uploading logic and rendering here

    return (
      <Card
        noBoxShadow={noBoxShadow}
        className={className}
        category={cardCategory || CardCategory.LIST}
        component={<section/>}
        selected={selected}
        template={template || 'mobile-no-margin'}
        padding="none"
      >
        <div id={generateDOMId(`admin-resource-card-${resource.ID}`)} className={`${namespace()}--wrapper`}
              onClick={this.onCardClick}>
          {type === ResourceCardType.MULTIPLE_RESOURCES ? 
            <Checkbox
              selected={!!checked}
              value={!!checked}
              onChange={this.onMultipleSelectClick}
            /> : null}
          {this.mapFileTypeToIcon()}
          {type === ResourceCardType.LIST && this.renderForList()}
          {type === ResourceCardType.ADD_RESOURCE && this.renderWithoutActions()}
          {type === ResourceCardType.EDIT_RESOURCE && this.renderWithActions(true)}
          {type === ResourceCardType.PREVIEW && this.renderWithActions()}
          {type === ResourceCardType.IN_MODAL && this.renderInModal()}
          {type === ResourceCardType.MODAL_SELECT && this.renderModalSelect()}
          {type === ResourceCardType.EDIT_PAGE && this.renderInEditPage()}
          {type === ResourceCardType.MULTIPLE_RESOURCES && this.renderInMultipleResources()}
        </div>
        {deleted && showDeletedPrompt && <section className={`${namespace()}--deleted-prompt`}>{SELECTED_ITEM_DELETED}</section>}
      </Card>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
  };
};

const ConnectedResourceCard = connect(
  mapStateToProps,
  null,
  getMergeProps<Props & WithRouterProps>(),
)(ResourceCard);

export default withRouter<Props>(ConnectedResourceCard);