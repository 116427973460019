import * as React from 'react';

import Text from "../Text";
import MoneyField from "../MoneyField";
import Media from '../Media';
import Button from "../Button";
import RadioGroupOption from "../Radio/StatelessOption";
import Row from "../Grid/Row";
import Column from "../Grid/Column";
import { namespace as ns } from './constants';
import '../../../styles/elements/financial-summary/option.scss';
import { spaceTo_ } from '../../../utils';
import { Validator } from '../../../utils/validator/models';

const namespace = (): string => `${ns()}--option`;

// @todo: maybe this should be 2 separate classes, simple option and custom option
interface Props {
  label: string;
  amount: string;
  amountGray?: boolean;
  selected: boolean;
  value: string;
  otherValue?: number;
  otherValidationRules?: any;
  editable?: boolean;
  requiresSave?: boolean;
  onSave?: (value: number) => void;
  updateValue?: (value: any, vObj: Validator) => void;
  simpleUpdate?: (value: any, vObj: Validator) => void;
  isSaving?: boolean;
  onCancel?: (e:any) => void;
  isWizard?: boolean; // the wizard version doesn't have a save/cancel button, it is simply a text field
  onChange: Function;
  marginBottom?: number;
  wrapMoneyField?: boolean;
  maxMoneyFieldWidth?: string;
}

const renderButton = (editable, onSave, requiresSave, isSaving) => {
  if (editable && onSave && requiresSave) {
    return <Column>
      <Button className={`${namespace()}--save-button`} onClick={onSave} color="white" marginLeft={16}>SAVE</Button>
    </Column>;
  }
  return null;
};

const Option: React.SFC<Props> = ({ onChange, marginBottom, isWizard, label, amount, editable, amountGray, value, selected, requiresSave, onSave, onCancel, isSaving, simpleUpdate, updateValue, otherValue, otherValidationRules, wrapMoneyField, maxMoneyFieldWidth }: Props): React.ReactElement<Props> =>  { 
  const renderMoneyField = (textAlignment: 'left' | 'right', style?: React.CSSProperties) => {
    const columnStyle = Object.assign({}, style, {
      justifyContent: maxMoneyFieldWidth ? (textAlignment === 'left' ? 'flex-start' : 'flex-end') : null,
    });
    return (
      <Column style={selected ? columnStyle : {}} className={`${namespace()}--label--editable ${!isWizard ? 'show-save' : 'hide-save'}`} verticalAlignment="bottom">
        <MoneyField
          className={`${namespace()}--label--editable--money-field`}
          padding={false}
          noPaddingBottom={true}
          align={textAlignment}
          id={`financial-summary-${spaceTo_(label)}`}
          placeHolder="$0.00"
          style={{ display: selected ? 'block' : 'none', maxWidth: maxMoneyFieldWidth ? maxMoneyFieldWidth : 'unset' }}
          value={otherValue}
          
          onChange={simpleUpdate}
          onBlur={updateValue}
          onCancel={!isWizard && requiresSave && !isSaving ? onCancel : undefined}
          validationRules={otherValidationRules}
        />
        {!isWizard && renderButton(editable, onSave, requiresSave, isSaving)}
      </Column>
    );
  };
  // When `wrapMoneyField` is true, it means that in mobile screensize, we will wrap the money field to a second line,
  // and also make its width alignment to be matching its label (i.e. the second column here). A typical example is
  // [Pay other selected](https://projects.animaapp.com/#/p/jkEMj3C/a03m/specs). To achieve this, we did the following:
  // 1. In mobile screen size, we render the money field inside the second column, instead of making it the third column
  // 2. Also, we make the text alignment to be `left`, and add a `flextBasis` style to the second column, to avoid unexpected wrapping, but only apply this when it is NOT a wizard
  // 3. However, in tabletAndGreater, we keep the normal behavior: render money field in the third column and text-align = right
  // 4. Make sure this only happens when `wrapMoneyField` is provided
  return (
    <Row marginBottom={marginBottom} className={`${namespace()} ${selected ? 'selected' : ''} ${editable ? 'editable' : 'not-editable'} ${!isWizard ? 'show-save' : 'hide-save'}`}>
      <label className={`${namespace()}--label ${selected ? 'selected' : ''} ${!isWizard ? 'show-save' : 'hide-save'}`}>
        <Column verticalAlignment="top" marginTop={2}>
          <RadioGroupOption value={value} selected={selected} onChange={onChange}/>
        </Column>
        <Column layout="vertical" className={`${namespace()}--label--column`}>
          <Text size={15}>
            {label}
          </Text>
          <Text
            size={amountGray ? 14 : 15}
            weight={!amountGray ? 'medium' : undefined}
            color={amountGray ? 'gray' : undefined}
            marginBottom={0}
          >
            {amount}
          </Text>
          {wrapMoneyField && <Media mobile>
            {renderMoneyField('left', {marginTop: '16px', paddingBottom: '24px'})}
          </Media>}
        </Column>
        {editable && (
          wrapMoneyField ? (
            <Media tabletAndGreater>
              {renderMoneyField('right', {paddingBottom: '24px'})}
            </Media>
          ) : renderMoneyField('right', {paddingBottom: '24px'})
        )}
      </label>
    </Row>
  );
};

export default Option;
