
import { ToggleNotFound } from "../../store/App/actions";
import { SaveState } from "../../store/Rollback/actions";
import { extractRouteParams, isIdFromUrlValid } from "../urlHelper";
import { GetBlackoutDate } from "../../store/AdminFacilityLocation/CacheTwoBlackoutDates/actions";
import { UpdateBlackoutDateSubmitActions } from "../../store/AdminFacilityLocation/BlackoutDates/Form/actions";
import { getBlackoutDateIdFromPath } from "../helpers/adminFacilityLocationPageHelper";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import { reduxStoreService } from "../../store/service";
import type AdminFacilitiesLocationCacheManager from "../cacheManagers/adminFacilitiesLocationCacheManager";
import { LoadAdminFacilityCacheTwoBlackoutDateParams } from "./helpers/models";
import {isAdminFacilityLocationCacheTwoBlackoutDatePopulated} from "../cachePopulatedCheckers/adminFacilities";

export const ensureAdminFacilityLocationCacheTwoBlackoutDate = (
  params: LoadAdminFacilityCacheTwoBlackoutDateParams & {cacheManager: AdminFacilitiesLocationCacheManager}
): boolean => {
  const {props, isEdit, cacheManager, isStateNavigated} = params;

  if (!isIdFromUrlValid(props.params.locationId)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const rootState = reduxStoreService().getState();
  const adminFacilityLocationCacheTwoBlackoutDates = rootState.adminFacilityLocation.cacheTwoBlackoutDate;

  let requestedData = false;
  if (isEdit) {
    if (!isIdFromUrlValid(props.params.blackoutDateId)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }

    if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminFacilityLocationCacheTwoBlackoutDatePopulated(adminFacilityLocationCacheTwoBlackoutDates)) {
      if (isStateNavigated) {
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      const blackoutDateId = getBlackoutDateIdFromPath(props.params);

      const params = {
        ID: blackoutDateId,
        ...extractRouteParams(props),
      };
      reduxStoreService().dispatch(GetBlackoutDate.request(params));

      requestedData = true;
    }
  } else if (isStateNavigated) {
    requestedData = cacheManager.loadAdminFacilitiesLocationCacheOne({
      props,
      isStateNavigated,
    });

    if (!requestedData) {
      reduxStoreService().dispatch(UpdateBlackoutDateSubmitActions.updateForm());
      reduxStoreService().dispatch(new SaveState());
    }
  }

  return requestedData;
};