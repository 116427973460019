
import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminEventsCacheOneContext } from '@tentaroo/shared';

import { ApplicationState } from '../../../../../store';
import { actionCreators } from '../../../../../store/AdminEvents/MessageCenter/Home/actions';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as cacheOneActionCreators, GetEventTypeCacheAction } from '../../../../../store/AdminEvents/CacheOne/actions';
import { actionCreators as cacheTwoMessageActionCreators } from '../../../../../store/AdminEvents/CacheTwoMessage/actions';
import { LoadingAll, EmptyMessage, Button, Loader, Alert } from '../../../../Elements';
import { Main, MainContent } from '../../../../Layouts';
import '../../../../../styles/pages/admin-events/message-center/home/index.scss';
import MessageCenterFilter from './MessageCenterFilter';
import { makeActiveMessagesSelector, makeFilteredMessagesSelector } from '../../../../../store/AdminEvents/MessageCenter/Home';
import { AdminEventsEmailMessage } from '../../../../../models/api/adminEventsCacheOne';
import { ENTITY_NOT_ADDED, ENTITY_NOT_FOUND } from '../../../../../constants/messages/adminCMS';
import { ADMIN_EMAIL_MESSAGES } from '../../../../../constants/messages/adminEvents';
import { EmptyMessageType } from '../../../../Elements/EmptyMessage';
import { EmailIcon } from '../../../../Icons';
import AdminMessageCard, { AdminMessageCardType } from '../../../../Elements/AdminMessage/Card';
import { CardCategory } from '../../../../Elements/Card';
import MessageForm from '../Form';
import { ADMIN_EVENTS_NEW_MESSAGE_PATH, ADMIN_EVENTS_EDIT_MESSAGE_PATH, ADMIN_EVENTS_MESSAGE_CENTER_PATH } from '../../../../../routes';
import { getEditMessageUrl, constructAdminEventsMessageUrlParams, getNewMessagetUrl, constructAdminEventsUrlParams, getAdminEventsMessageCenterHomeRootUrl } from '../../../../../constants/adminEventsUrls';
import { navPush } from '../../../../../utils';
import { isPathnameMatchingRoute } from '../../../../../utils/urlHelper';
import { actionCreators as messageFormActionCreators } from '../../../../../store/AdminEvents/MessageCenter/Form/actions';
import { checkAdminEventMessagePermission, IAdminMessageRouterParams } from '../../../../../utils/helpers/adminEventsPageHelper';
import { ModalTypes, noOpenedModals } from '../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import AdminEventsCacheManager from '../../../../../utils/cacheManagers/adminEventsCacheManager';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { shouldBlockActions } from '../../../../../utils/cacheLoaders/helpers/blockers';
import { SaveState } from '../../../../../store/Rollback/actions';
import { reduxStoreService } from '../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isAdminEventsCacheOnePopulated} from '../../../../../utils/cachePopulatedCheckers/adminEvents';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--message-center';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> &RouteComponentProps<IAdminMessageRouterParams, {}>
>;

class MessageCenterHome extends ComponentUpdateTemplate<ConnectedProps, {}> {
  public props: ConnectedProps;

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        const {routes} = this.props;
        const route = routes[routes.length - 1];
        if (route.path === ADMIN_EVENTS_EDIT_MESSAGE_PATH || route.path === ADMIN_EVENTS_NEW_MESSAGE_PATH) {
          AdminEventsCacheManager.getInstance().loadCacheTwoMessage({
            props: this.props,
            isStateNavigated,
            isEdit: route.path === ADMIN_EVENTS_EDIT_MESSAGE_PATH,
          });
        } else {
          AdminEventsCacheManager.getInstance().loadAdminEventsCacheOne({
            props: this.props,
            isStateNavigated,
            context: AdminEventsCacheOneContext.MESSAGES_LIST,
          });
        }
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    const {routes} = this.props;
    const thisRoute = routes[routes.length - 1];
    const prevRoute = prevProps.routes[prevProps.routes.length - 1];
    const thisPath = thisRoute.path;
    const prevPath = prevRoute.path;

    if (shouldBlockActions()) return;

    if (
      thisPath !== prevPath &&
      thisPath === ADMIN_EVENTS_MESSAGE_CENTER_PATH &&
      prevPath !== ADMIN_EVENTS_MESSAGE_CENTER_PATH
    ) {
      reduxStoreService().dispatch(new SaveState());
    }
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    const {routes} = nextProps;
    const thisRoute = routes[routes.length - 1];
    const prevRoute = this.props.routes[this.props.routes.length - 1];
    const thisPath = thisRoute.path;
    const prevPath = prevRoute.path;
    
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        if (thisPath === ADMIN_EVENTS_EDIT_MESSAGE_PATH || thisPath === ADMIN_EVENTS_NEW_MESSAGE_PATH) {
          AdminEventsCacheManager.getInstance().loadCacheTwoMessage({
            props: nextProps,
            isStateNavigated,
            isEdit: thisPath === ADMIN_EVENTS_EDIT_MESSAGE_PATH,
          });
        } else {
          AdminEventsCacheManager.getInstance().loadAdminEventsCacheOne({
            props: nextProps,
            isStateNavigated,
            context: prevPath !== ADMIN_EVENTS_EDIT_MESSAGE_PATH && prevPath !== ADMIN_EVENTS_NEW_MESSAGE_PATH ? AdminEventsCacheOneContext.MESSAGES_LIST : undefined,
          });
        }
      }
    );
  }

  componentWillUnmount() {
  }

  onNewMessageClick = (message: AdminEventsEmailMessage) => {
    const {routes, adminEventsCacheOne} = this.props;

    checkAdminEventMessagePermission(
      () => {
        const route = routes[routes.length - 1];
        if (route.path === ADMIN_EVENTS_EDIT_MESSAGE_PATH) return;
        if (!adminEventsCacheOne || !adminEventsCacheOne.EventsEventType) return;
            
        const newMessageUrl = getNewMessagetUrl(constructAdminEventsMessageUrlParams(this.props, adminEventsCacheOne, message));
        navPush(this.props.router, newMessageUrl);
      },
      adminEventsCacheOne,
    );

  };
  onEditMessageClick = (message: AdminEventsEmailMessage) => {
    const {routes, adminEventsCacheOne } = this.props;
    const route = routes[routes.length - 1];
    if (!adminEventsCacheOne || !adminEventsCacheOne.EventsEventType) return;
    if (!adminEventsCacheOne || !adminEventsCacheOne.EventsEventType) return;
        
    const editMessageUrl = getEditMessageUrl(constructAdminEventsMessageUrlParams(this.props, adminEventsCacheOne, message));
    navPush(this.props.router, editMessageUrl);

  };
  onRestoreMessageClick = (message: AdminEventsEmailMessage) => {
    const { messageFormActions, routes, router, adminEventsCacheOne } = this.props;

    checkAdminEventMessagePermission(
      () => {
        messageFormActions.deleteAdminEventMessage(message.ID, router, routes, false);
      },
      adminEventsCacheOne,
      true,
    );
  };
  onDeleteMessageClick = (message: AdminEventsEmailMessage) => {
    const { messageFormActions, adminEventsCacheOne, routes, router } = this.props;

    checkAdminEventMessagePermission(
      () => {
        messageFormActions.deleteAdminEventMessage(message.ID, router, routes, true);
      },
      adminEventsCacheOne,
      true,
    );
  };

  onSendMessageClick = (message: AdminEventsEmailMessage) => {
    const {actions, adminEventsCacheOne} = this.props;
    checkAdminEventMessagePermission(
      () => {
        actions.pushModal(ModalTypes.SEND_MESSAGE, false, true, {messageSubject: message.Name, id: message.ID});
      },
      adminEventsCacheOne,
      false,
      true,
    );
  };
  renderEmptyMessage = (emptyMessage: string) => {
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        admin
        icon={EmailIcon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        noMobilePadding
        description={emptyMessage}
        actions={<Button className={`${namespace()}--empty-message-btn`} color="green" onClick={this.onNewMessageClick}>{'NEW MESSAGE'}</Button>}/>
    );
  };

  renderContent = () => {
    const {filteredMessages} = this.props;

    return (
      <div className={`${namespace()}--list-wrapper`}>
        {filteredMessages.map((m, index) => {
          const selected = isPathnameMatchingRoute(location.pathname, ADMIN_EVENTS_EDIT_MESSAGE_PATH, 'messageId', m.ID);
          return (
            <AdminMessageCard
              key={`message_card${m.ID}`}
              type={AdminMessageCardType.MESSAGE_LIST}
              category={CardCategory.LIST}
              message={m}
              onEdit={this.onEditMessageClick}
              onRestore={this.onRestoreMessageClick}
              onDelete={this.onDeleteMessageClick}
              onSend={this.onSendMessageClick}
              selected={selected}
            />
          );
        })}
      </div>
    );

  };

  onClickMain = (e) => {
    // if we are clicking on the `tab`, dont do anything in this callback (i.e. manually stop propagation)
    if (e.target.classList.contains('mdl-tabs__tab')) return;
    e.stopPropagation();
    const { routes, router, adminEventsCacheOne} = this.props;
    const thisRoute = routes[routes.length - 1];

    if (reduxStoreService().getState().app.apiSaving <= 0 && adminEventsCacheOne.EventsEventType) {
      if (thisRoute.path === ADMIN_EVENTS_NEW_MESSAGE_PATH || thisRoute.path === ADMIN_EVENTS_EDIT_MESSAGE_PATH) {
        const backUrl = getAdminEventsMessageCenterHomeRootUrl(constructAdminEventsUrlParams(this.props, adminEventsCacheOne));
        navPush(router, backUrl);
      }
    }
  };

  public render() {
    const {routes, adminEventsCacheOne, inert, activeMessages, filteredMessages, actions, messageCenterHomeForm, apiLoadingMap, apiSaving } = this.props;
    if (!isAdminEventsCacheOnePopulated(adminEventsCacheOne)) {
      return <LoadingAll />;
    }

    let emptyMessage;
    const thisRoute = routes[routes.length - 1];
    if (activeMessages.length === 0 && filteredMessages.length === 0) {
      emptyMessage = ENTITY_NOT_ADDED(ADMIN_EMAIL_MESSAGES);
    } else if (filteredMessages.length === 0) {
      emptyMessage = ENTITY_NOT_FOUND(ADMIN_EMAIL_MESSAGES);
    }

    const refreshing = apiLoadingMap[GetEventTypeCacheAction.requestType] && noOpenedModals();
    const saving = apiSaving > 0 && noOpenedModals();

    return (
      <Main inert={inert} isLoading={refreshing} isAdminEvents onClick={this.onClickMain}>
        <MainContent className={namespace()} handleCompact>
          <div className={`${namespace()}--list-actions`}>
            <MessageCenterFilter
              ActiveForm={messageCenterHomeForm.ActiveForm}
              ValidationRules={messageCenterHomeForm.ValidationRules}
              reduxActions={actions}
              newButton={
                <Button id={generateDOMId("admin-message-add-btn")} className='control' color='green' textColor='white' onClick={this.onNewMessageClick}>New</Button>}
            />
          </div>
          <Alert className={`${namespace()}--alert prompt`}>Messages are shared between all events in the system. Messages can be sent out to anyone who has registered for the selected event.</Alert>
          {emptyMessage && this.renderEmptyMessage(emptyMessage)}
          {!emptyMessage && this.renderContent()}
          {saving && <Loader className={`${namespace()}--loader`} center />}
          {(thisRoute.path === ADMIN_EVENTS_NEW_MESSAGE_PATH || thisRoute.path === ADMIN_EVENTS_EDIT_MESSAGE_PATH) && (
            <MessageForm
              inert={inert}
              type={thisRoute.path === ADMIN_EVENTS_EDIT_MESSAGE_PATH ? 'edit' : 'add'}
              key='admin-message-sidemodal'
              onDelete={thisRoute.path === ADMIN_EVENTS_EDIT_MESSAGE_PATH ? this.onDeleteMessageClick : undefined}
            />
          )}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const activeMessagesSelector = makeActiveMessagesSelector();
  const filteredMessagesSelector = makeFilteredMessagesSelector();
  
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsCacheTwoMessage: state.adminEvents.cacheTwoMessage,
    messageCenterHomeForm: state.adminEvents.messageCenter.home,
    cacheZero: state.cacheZero,
    activeMessages: activeMessagesSelector(state),
    filteredMessages: filteredMessagesSelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...cacheOneActionCreators,
    ...actionCreators,
    ...cacheTwoMessageActionCreators,
  }, dispatch),
  messageFormActions: bindActionCreators({
    ...messageFormActionCreators,
  }, dispatch),
});
const ConnectedMessageCenterHome = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(MessageCenterHome);

export default withRouter<{}>(ConnectedMessageCenterHome);
