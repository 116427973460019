// Resource
export const INVALID_RESOURCE = 'Resource has been deleted. Please restore from list and try again.';

// Resource Filters
export const RESOURCE = 'resource';
export const RESOURCES = 'resources';
export const RESOURCE_CATEGORIES = 'resource categories';

// Contact
export const INVALID_CONTACT = 'Contact has been deleted. Please restore from contact list and try again.';

// Contact Filters
export const CONTACT = 'contact';
export const CONTACTS = 'contacts';

// Menus
export const INVALID_MENU_ITEM = 'Menu item has been deleted. Please restore from list and try again.';
export const INVALID_MENU_LEVEL = 'Only two levels of menu items are allowed. The children below this item would be too deep after moving. Please try again.';
export const ORDERING_MENU_ITEMS_ALERT = 'Please save or cancel your changes to the menu item order before continuing.';
export const PAGE_MENU_ITEM = 'page menu item';
export const SITE_MENU_ITEM = 'site menu item';
export const MENU_ITEM = 'menu item';
export const MENU_ITEMS = 'menu items';
export const TOGGLE_SHOW_DELETED_BEFORE_CHANGING_MENU_ORDER = 'Please toggle Show Deleted off before making changes to the menu item order.';

// Resource Category
export const INVALID_RESOURCE_CATEGORY = 'Resource category has been deleted. Please restore from list and try again.';
export const RESOURCE_CATEGORY = 'resource category';

// Page
export const PRIMARY_EVENT_PAGE_NOTE = 'Link to this page from the calendar for this event. Only one page can be selected as the primary for each event.';
export const SHOW_REGISTRATION_LINKS_NOTE = 'Automatically show buttons to register for this event on the page.';
export const INCLUDE_EVENT_MODULE_CONTACTS_NOTE = 'Includes the first contact configured under the event in the list of contacts shown on this page. If contacts are shown in the sidebar, this contact will be highlighted and shown first in the list of contacts.';
export const INCLUDE_FACILITY_MODULE_CONTACTS_NOTE = 'Includes the first contact configured under the facility location in the list of contacts shown on this page. If contacts are shown in the sidebar, this contact will be highlighted and shown first in the list of contacts.';
export const INVALID_PAGE = 'Page has been deleted. Please restore from page list and try again.';
export const GALLERY_IMAGE = 'gallery image';
export const TOP_GALLERY_IMAGE = 'top gallery image';
export const BOTTOM_GALLERY_IMAGE = 'bottom gallery image';
export const LOCATION = 'location';
export const CMS_PAGE = 'page';
export const EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE = (action: string) => `Please either wait for the current changes to images to finish or cancel before ${action}.`;
export const INVALID_TOP_GALLERY_IMAGE = 'Top gallery image has been deleted. Please restore from top gallery image list and try again';
export const INVALID_BOTTOM_GALLERY_IMAGE = 'Bottom gallery image has been deleted. Please restore from bottom gallery image list and try again';
export const INVALID_LOCATION = 'Location has been deleted. Please restore from location list and try again';
export const PAGE_INSUFFICIENT_EDIT_PERMISSION = 'Insufficient permissions to edit this page.';

// Page Filters
export const PAGES = 'pages';
export const BLOG_ARTICLES = 'blog articles';
export const CALENDAR_EVENTS = 'events';
export const DISTRICTS = 'district pages';
export const EVENT_MODULES = 'event module pages';
export const FACILITY_MODULES = 'facility module pages';
export const STANDARD_PAGES = 'standard pages';

// Settings
export const SITE_SETTINGS = 'Site settings';
export const SITE_MESSAGES = 'Site messages';

// Util functions
export const ENTITY_PARSE_ERROR = (name: string) => `Unable to parse request body for add/edit ${name}!`;
export const ENTITY_PARSE_ERROR_ON_DELETE = (name: string) => `Unable to parse request body for delete ${name}!`;
export const ENTITY_EDITED = (name: string) => `The ${name} was successfully edited`;
export const ENTITY_SAVED = (name: string) => `${name} have successfully been saved`;
export const ENTITY_CREATED = (name: string) => `New ${name} was created`;
export const ENTITY_DELETED = (name: string) => `The ${name} was deleted`;
export const ENTITY_RESTORED = (name: string) => `The ${name} was restored`;
export const ENTITY_NOT_FOUND = (name: string) => `No ${name} found`;
export const ENTITY_NOT_ADDED = (name: string) => `No ${name} added yet`;
export const HAS_ADD_SAMPLE_REASON = (name: string) => `Sample ${name} add reason`;
export const HAS_EDIT_SAMPLE_REASON = (name: string) => `Sample ${name} edit reason`;