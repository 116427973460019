import { Validator } from '../../../../utils/validator/models';

export interface IValidator {
  ShowDeleted: Validator;
  FilterText: Validator;
}

export const FormDefinition: IValidator = {
  ShowDeleted: {
    key: 'ShowDeleted',
    isRequired: () => false,
    defaultValue: () => false,
  },
  FilterText: {
    key: 'FilterText',
    isRequired: () => false,
    defaultValue: () => '',
  },
};