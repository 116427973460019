import * as React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import '../../../../styles/elements/month/compact/range-group.scss';

import CardTitle from "../../Card/Title";
import ActionButton from "../../ActionButton";
import AdminBadge from '../../AdminBadge';
import Loader from '../../Loader';

import { BackArrowIcon, NextArrowIcon, EditIcon, ArrowIcon } from '../../../Icons';
import { monthNamespace, rangeNamespace } from './constants';
import { easing } from '../../../../utils';
import {Nullable} from '../../../../utils/dataHelper';

const namespace = (): string => 'elements--compact-month-range-group';

interface Props {
  children?: React.ReactNode;
  showLoaders?: boolean;
  hideTitles?: boolean;
  showAdminBadge?: boolean;
  hasEditButton?: boolean;
  hasArrowIcon?: boolean;
}

interface State {
  titles: Array<React.ReactNode>;
  onClickTitles: Array<() => void>;
  subtitle: string | null;
  arrowPosition: number;
  showAdminBadge: boolean[];
}

// @todo: This class needs to be refactored to better handle skeleton and loading cases when we hook the facilities up to redux state.
// It would probably be easier to pass in all the Range Data as a parameter here and have the RangeGroup render all the Ranges
// showLoaders param should be removed and likely replaced with the list of locations
class RangeGroup extends React.Component<Props, State> {
  public static childContextTypes = {
    addTitle: PropTypes.func,
    addOnClickTitle: PropTypes.func,
    onScroll: PropTypes.func,
    addSubTitle: PropTypes.func,
    removeSubTitle: PropTypes.func,
    setShowAdminBadge: PropTypes.func,
  };

  public state: State = {
    titles: [],
    onClickTitles: [],
    subtitle: null,
    arrowPosition: 100,
    showAdminBadge: [],
  };

  componentDidMount() {
    document.addEventListener('scroll', this.handleBodyScroll);
    this.state.arrowPosition = this.getPosition();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleBodyScroll);
  }

  public componentWillReceiveProps(nextProps) {
    if (!this.props.hideTitles && nextProps.hideTitles) {
      this.state.titles = [];
      this.state.showAdminBadge = [];
      this.state.onClickTitles = [];
    } else if (this.props.hideTitles && !nextProps.hideTitles) {
      this.state.titles.pop();
      this.state.showAdminBadge.pop();
      this.state.onClickTitles.pop();
    }
  }

  private callbacks: Array<(position: number) => void> = [];
  
  // TODO: The purpose of this is to update content of this `RangeGroup` component as we render its children.
  // Think about better implementation?
  public getChildContext = () => ({
    addTitle: (title) => {
      this.state.titles.push(title);
      this.forceUpdate();
    },
    addOnClickTitle: (fnc) => {
      this.state.onClickTitles.push(fnc);
    },
    onScroll: (callback) => {
      this.callbacks.push(callback);
    },
    addSubTitle: (subtitle) => {
      this.setState({ subtitle });
    },
    setShowAdminBadge: (show) => {
      if (this.props.showAdminBadge) {
        this.state.showAdminBadge.push(show);
        this.forceUpdate();
      }
    },
    removeSubTitle: (subtitle) => {
      if (this.state.subtitle === subtitle) {
        this.state.subtitle = null;
        this.forceUpdate();
      }
    }
  });

  public handleScroll = (event: React.UIEvent<any>) => {
    const element: HTMLDivElement = event.target as any;
    this.callbacks.forEach(callback => callback(element.scrollLeft));
  };

  handleBodyScroll = (event) => {

    this.setState({arrowPosition: this.getPosition()});
  };

  getPosition = () => {
    let groupTop = document.getElementsByClassName('elements--compact-month-range-group')[0].getBoundingClientRect().top;
    let position: number = 0;
    if (groupTop < 0) {
      position = ( (window.innerHeight) / 2 ) - groupTop - 16;
    } else {
      position = ( (window.innerHeight - groupTop) / 2 ) - 16;
    }
    if (position < 100) position = 100;
    return position;
  };

  private getDayWidth = () => {
    const scrollable = findDOMNode(this.refs.scrollable) as Nullable<HTMLDivElement>;

    if (!scrollable) return 0;

    const days = scrollable
      .getElementsByClassName(rangeNamespace())[0]
      .getElementsByClassName(`${rangeNamespace()}--months--month`)[0]
      .getElementsByClassName(`${monthNamespace()}--days--list--day--day-of-month`) as Nullable<HTMLCollection>;

    if (!days) return 0;

    return days[0].getBoundingClientRect().width;
  };

  public handleNextClick = () => {
    const scrollable = findDOMNode(this.refs.scrollable) as Nullable<HTMLDivElement>;

    if (!scrollable) return;

    const dayWidth = this.getDayWidth();
    const scrollLeft = Math.floor(scrollable.scrollLeft / dayWidth) * dayWidth;
    this.scrollTo(scrollable, scrollLeft + 12 * dayWidth);
  };

  public handlePrevClick = () => {
    const scrollable = findDOMNode(this.refs.scrollable) as Nullable<HTMLDivElement>;

    if (!scrollable) return;

    const dayWidth = this.getDayWidth();
    const scrollLeft = Math.floor(scrollable.scrollLeft / dayWidth) * dayWidth;
    this.scrollTo(scrollable, scrollLeft - 12 * dayWidth);
  };

  public scrollTo = (target: HTMLDivElement, position: number) => {
    window.requestAnimationFrame(
      (time) => this.animateScrollFrame(
        target,
        target.scrollLeft,
        position,
        time
      )
    );
  };

  public animateScrollFrame = (
    target: HTMLDivElement,
    startPosition: number,
    endPosition: number,
    currentTime: number,
    startTime: number | null = null,
    duration: number = 400
  ) => {
    if (!startTime) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const nextValue = easing.easeInOutQuad(timeElapsed, startPosition, endPosition - startPosition, duration);
    target.scrollLeft = nextValue;
    if (timeElapsed < duration) {
      window.requestAnimationFrame(
        (time) => this.animateScrollFrame(
          target,
          startPosition,
          endPosition,
          time,
          startTime,
          duration
        )
      );
      return;
    }
    target.scrollLeft = endPosition;
  };

  public render() {
    const { children, hideTitles, hasEditButton, hasArrowIcon } = this.props;
    const { titles, subtitle, arrowPosition, onClickTitles, showAdminBadge } = this.state;
    return (
      <div className={namespace()}>
        <ActionButton
          icon={BackArrowIcon}
          shadow={true}
          background="white"
          className={`${namespace()}--action prev`}
          onClick={this.handlePrevClick}
          wrapperStyle={{
            top: `${arrowPosition}px`
          }}
        />
        <div ref="scrollable" className={`${namespace()}--scrollable`} onScroll={this.handleScroll}>
          {children}
        </div>
        {!hideTitles && <div className={`${namespace()}--titles ${hasEditButton ? 'full-width' : ''}`}>
          {
          titles.map((title: React.ReactElement<any>, index) => [
              (<div className={`${namespace()}--titles--title-row`} onClick={onClickTitles[index]}>
                {
                  typeof title === 'string' ?
                    <CardTitle size={18}>{title}</CardTitle> :
                    React.cloneElement(title, { ...title.props, key: index })
                }
                {showAdminBadge[index] && <AdminBadge marginLeft={-8} marginRight={56} />}
                {
                  (this.props.showLoaders) ? <Loader size="18px"/> : undefined
                }
                {hasEditButton && <ActionButton icon={EditIcon} className={`${namespace()}--action-edit`} onClick={onClickTitles[index]} />}
                {hasArrowIcon && <div className={`${namespace()}--title--arrow`}><ArrowIcon /></div>}
              </div>
            ),
            <div key={`${index}-sub-title`} className={`${namespace()}--titles--sub-title ${title ? '' : 'skeleton'}`}>{title ? subtitle : ''}</div>,
            <div key={`${index}-spacer`} className={`${namespace()}--titles--spacer`}/>
          ])}
        </div>}
        <ActionButton
          icon={NextArrowIcon}
          shadow={true}
          background="white"
          className={`${namespace()}--action next`}
          onClick={this.handleNextClick}
          wrapperStyle={{
            top: `${arrowPosition}px`
          }}
        />
      </div>
    );
  }
}

export default RangeGroup;
