import * as React from 'react';
import { generateDOMId } from '../../../../utils/cypressHelper';
import { ContextMenu, ContextMenuItem } from '../../../Elements';
import { Props as ContextMenuProps } from '../../../Elements/ContextMenu/index';

interface Props {
  onEdit: (e) => void;
  onDelete: (e) => void;
  facilityTypeId: number;
}

const ContextMenuComponent: React.SFC<ContextMenuProps & Props> = (props: ContextMenuProps & Props): React.ReactElement<ContextMenuProps & Props> => (
  <ContextMenu
    {...props}
    consistentInMobile
    title=""
  >
    <ContextMenuItem id={generateDOMId(`facility-type-${props.facilityTypeId}-edit-option`)} admin onClick={props.onEdit}>Edit</ContextMenuItem>
    <ContextMenuItem id={generateDOMId(`facility-type-${props.facilityTypeId}-delete-option`)} admin onClick={props.onDelete}>Delete</ContextMenuItem>
  </ContextMenu>
);

export default ContextMenuComponent;
