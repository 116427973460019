import '../../../styles/layouts/filter/filter-search.scss';
import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { TextField, ActionButton } from '../../../components/Elements';
import { SearchIcon } from '../../../components/Icons';
import attributes, { AlignmentProps, MarginProps, LayoutProps, PaddingProps } from '../../../styles';
import { isMobileAndSmallerScreenSize } from '../../../utils/isMobile';
import { Validator } from '../../../utils/validator/models';

export const namespace = (): string => 'layouts--filter-search';

interface State {
  showSearch?: boolean;
}

interface Props extends AlignmentProps, MarginProps, LayoutProps, PaddingProps {
  className?: string;
  ActiveForm: any;
  searchKey: string;
  ValidationRules: any;
  disabled?: boolean;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
  };
  width: '198' | '228' | 'full' | 'half';
  forceMobile?: boolean;
  alwaysShowSearchFieldInMobile?: boolean;
  align?: 'flex-end' | 'center';
  onToggleSearch?: (searching: boolean) => any;
}

class FilterSearch extends React.Component<Props, State> {
  public state: State = {
    showSearch: false,
  };

  onSearchChange = (val: string, vObj: Validator) => {
    if (val) {
      this.setState({
        showSearch: true,
      });
    }
    this.props.reduxActions.simpleUpdate(val, vObj);
    if (this.props.onToggleSearch) this.props.onToggleSearch(true);
  };

  onBlur = (val: string, vObj: Validator) => {
    if (!val) {
      this.setState({
        showSearch: false,
      });
    }

    this.props.reduxActions.updateValue(val, vObj);
    if (this.props.onToggleSearch) this.props.onToggleSearch(!!val);
  };
  onSearchCancel = (vObj) => {
    const {ValidationRules} = this.props;
    this.setState({showSearch: false});
    this.props.reduxActions.updateValue(undefined, vObj);

    if (this.props.onToggleSearch) this.props.onToggleSearch(false);
  };
  onSearchClick = () => {
    this.setState({
      showSearch: true,
    });

    if (this.props.onToggleSearch) this.props.onToggleSearch(true);
  };

  public render() {
    const {ActiveForm, ValidationRules, disabled, reduxActions, width, searchKey, forceMobile, align, alwaysShowSearchFieldInMobile} = this.props;

    let searchFieldClassName = attributes(namespace(), this.props);
    const wrapperStyle: React.CSSProperties = {};

    if (width) searchFieldClassName += ` width-${width}`;
    if (align) wrapperStyle.alignSelf = align;

    return (
      <div className={`${mergeClassNames(namespace(), this.props)} ${namespace()}--wrapper ${this.state.showSearch ? 'hide-other-columns' : ''} ${forceMobile ? 'force-mobile' : ''} ${alwaysShowSearchFieldInMobile ? 'always-show-in-mobile' : ''}`} style={wrapperStyle}>
        <TextField
          className={`${namespace()}--search ${searchFieldClassName}`}
          disabled={disabled}
          hideOptional
          icon={<SearchIcon />}
          onChange={this.onSearchChange}
          onCancel={!!ActiveForm[searchKey] ? () => this.onSearchCancel(ValidationRules[searchKey]) : undefined}
          onBlur={this.onBlur}
          value={ActiveForm[searchKey]}
          validationRules={ValidationRules[searchKey]}
          label='Search' />
        {!alwaysShowSearchFieldInMobile && !this.state.showSearch && <ActionButton className={`${namespace()}--mobile--search-button ${searchFieldClassName}`} square onClick={this.onSearchClick} icon={SearchIcon} />}
        {(alwaysShowSearchFieldInMobile || this.state.showSearch) && <TextField
          className={`${namespace()}--mobile--search-field ${searchFieldClassName}`}
          onCancel={(!alwaysShowSearchFieldInMobile || !!ActiveForm[searchKey]) ? () => this.onSearchCancel(ValidationRules[searchKey]) : undefined}
          disabled={disabled}
          hideOptional
          autoFocus={isMobileAndSmallerScreenSize()}
          icon={<SearchIcon />}
          onChange={this.onSearchChange}
          onBlur={reduxActions.updateValue}
          value={ActiveForm[searchKey]}
          validationRules={ValidationRules[searchKey]}
          label='Search' />}
      </div>
    );
  }
}

export default FilterSearch;