import {
  LogoutSuccess, LOGOUT_REQUEST, APISuccessGetLoginForm, APIFailureGetLoginForm,
  getLoginFormRequestAction, LogoutFailure, APIRequestGetLoginForm
} from './actions';
import { LoginActions } from '../Login/actions';
import {ClearAllCache, ShowSnackbarItem} from '../App/actions';
import {loginUrl, getLoginForm, logoutUrl} from '../../constants/urls';
import { ajaxPost, parsePayload } from '../../utils/ajaxHelper';
import {Observable} from "rxjs";
import {ClearSavePointDuringSave, SaveState} from "../Rollback/actions";
import {getCurrentScope} from "@sentry/browser";
import { LoginForm } from '../../models/api/login';
import { ModalTypes, pushModalObservables } from '../../utils/modalHelper';
import { ResetPasswordContext } from '../ResetPassword';

declare var __USE_FS__: any;

const createLoginBody = (action) => {
  return {
    "CampID": 6,
    "user_name": action.form.Username,
    "user_password": action.form.Password
  };
};

// @todo-minor-performance: we could fire less action in these epics
export const loginEpic = (action$, state$) => {
  return action$.ofType(LoginActions.requestType)
    .switchMap((action) => (
      Observable.merge(
        Observable.of(new SaveState(true)),
        Observable.ajax(ajaxPost(loginUrl(), createLoginBody(action)))
            .switchMap(p => {
              const payload = parsePayload(p);
  
              if (payload.parseError) {
                return ([LoginActions.failure(payload)]);
              }
              if (payload && payload.response && payload.response.str_user) {
                const user = payload.response.str_user;
                getCurrentScope().setUser({
                  email: user.Email,
                  id: user.Email,
                  username: user.Username ? user.Username : user.Email,
                  name: `${user.FirstName} ${user.LastName}`
                });
                const usefs = __USE_FS__ as any;
                if (usefs) {
                  (window as any).FS.identify(user.Email, { email: user.email, displayName: `${user.FirstName} ${user.LastName}` });
                }
              }

              /**
               * The order here matters!
               * 1. First we need to `ClearSavePointDuringSave` because the following navigation is NOT a navigation during save
               * 2. Then we need to redirect immediately before `LoginActions.success`; otherwise it will trigger `loadLoginForm` in Login/index.tsx before navigating, and will end up an extra redirection from <ComponentTemplate />, because it now sees that user is logged in but we are still in Login page
               * 3. Finally, populate data to redux
               */
              return Observable.concat(
                Observable.of(new ClearSavePointDuringSave()),
                Observable.of(LoginActions.success(payload)),
              );
            })
        .catch(p => {
          const payload = parsePayload(p);
          return ([
            new ClearSavePointDuringSave(),
            LoginActions.failure(payload),
          ]);
        })
      )
    ));
};

// https://stackoverflow.com/questions/34078676/access-control-allow-origin-not-allowed-when-credentials-flag-is-true-but
// I can't use withCredentials using this backend function

// seems we're missing csrf token?
// https://stackoverflow.com/questions/5207160/what-is-a-csrf-token-what-is-its-importance-and-how-does-it-work
export const logoutEpic = (action$, state$) => {
  return action$.ofType(LOGOUT_REQUEST)
    .switchMap((action) => (
      Observable.merge(
        Observable.of(new SaveState(true)),
        Observable.ajax(ajaxPost(logoutUrl(), {}))
          .mergeMap(p => {
            const payload = parsePayload(p);
            if (payload.parseError) {
              return [new LogoutFailure(payload)];
            }
            return (
              Observable.concat(
                Observable.of(new ClearSavePointDuringSave()),
                Observable.of(new LogoutSuccess(payload)),
                Observable.of(new ClearAllCache()),
                Observable.of(new SaveState()),
              )
            );
          })
          .catch(p => {
            const payload = parsePayload(p);
            return [
              new ClearSavePointDuringSave(),
              new LogoutFailure(payload),
            ];
          }),
      )
    ));
};

// @todo: some of this redirect code might be obsolete since page redirect on componentWillMount
// Replace should always come first, so that the correct loader will run once the request finishes
export const loginForm = (action$, state$) => {
  return action$.ofType(getLoginFormRequestAction)
    .switchMap((action: APIRequestGetLoginForm) => (
      Observable.merge(
        Observable.of(new SaveState(true)),
        Observable.ajax(
          ajaxPost(getLoginForm(), action.resetCode ? {ResetCode: action.resetCode} : {}))
                .switchMap(p => {
                  const payload = parsePayload(p);
  
                  if (payload.parseError) {
                    return handleLoginFormEpicError(payload);
                  }
                  if (payload && payload.response && payload.response.str_user) {
                    const user = payload.response.str_user;
                    getCurrentScope().setUser({
                      "email": user.Email,
                      "id": user.Email,
                      "username": user.Username ? user.Username : user.Email,
                      "name": `${user.FirstName} ${user.LastName}`
                    });
                    const usefs = __USE_FS__ as any;
                    if (usefs) {
                      (window as any).FS.identify(user.Email, { email: user.email, displayName: `${user.FirstName} ${user.LastName}` });
                    }
                  }

                  return Observable.concat(
                    Observable.of(new ClearSavePointDuringSave()),
                    Observable.of(new APISuccessGetLoginForm(payload.response)),
                  );
                })
          .catch(p => {
            // payload = {
            //   status: 500,
            //   xhr: {
            //     response: {}
            //   }
            // };
            const payload = parsePayload(p);
            return handleLoginFormEpicError(payload);
          })
      )
    ));
};

const handleLoginFormEpicError = (p) => {
  const result = [
    new ClearSavePointDuringSave(),
    /**
     * This action is being handled in a couple reducers:
     * 1. Session reducer - that is for normal GetLoginForm and app load behavior
     * 2. App reducer - that is mainly for reset password flow
     *   - When there is error, show it in snackbar
     *   - WHen there is NO error, pop up the reset password modal
     */
    new APIFailureGetLoginForm(p),
  ];

  const requestBody = p.request.body as string | undefined;
  const responseData = p.response as LoginForm;

  /**
   * NOTE: Even the following actions are actions right after `APIFailureGetLoginForm` above, as soon as
   * `APIFailureGetLoginForm` is done, a cacheLoader will get triggerred and the redirection logic will happen
   * before the following actions. Hence, `closeAllModals` during navigation will NOT close the modal below
   * because it is invoked before the following actions.
   */
  if (responseData.HasError === false && requestBody && requestBody.includes("ResetCode")) {
    /**
     * If HasError is false and ResetCode was passed into GetLoginForm,
     * at least one account was found and verification code is valid. 
     */
    const bodyJson = JSON.parse(requestBody);
    result.push(
      ...pushModalObservables({
        modal: ModalTypes.RESET_PASSWORD,
        saveBefore: false,
        saveAfter: false,
        modalProps: {
          resetPasswordContext: ResetPasswordContext.UPDATE_PASSWORD,
          resetPasswordAccounts: responseData.ResetPasswordAccounts,
          resetPasswordCode: bodyJson.ResetCode,
        },
        transformToObservable: false,
      }),
    );
  } else if (responseData.HasError && !!responseData.ResetError) {
    /**
     * If HasError is true, ResetError will be set to a string.
     * Show string in Snackbar as error string.
     */
    result.push(
      new ShowSnackbarItem(responseData.ResetError),
    );
  }

  return result;
};
