import * as React from 'react';
import {Main, MainContent} from '../../../Layouts';
import {
  Button,
  HeaderFilters,
  LoadingAll,
  RosterCard,
  RosterItem,
  RosterList,
  RosterListEmpty,
  Switch
} from '../../../Elements';
import {RouteComponentProps, withRouter} from "react-router";
import {navPush} from "../../../../utils/navHelper";
import {getRosterPersonUrl, MY_ROASTER_ADD, URLS} from "../../../../constants/urls";
import {ApplicationState} from "../../../../store/index";
import {bindActionCreators} from 'redux';
import {actionCreators} from '../../../../store/Settings/Roster/Main/actions';
import {actionCreators as cacheOneActionCreators, ClearCacheBelowOne} from '../../../../store/CacheOne/actions';
import {
  actionCreators as cacheTwoActionCreators,
  GetGroupRosterCache
} from '../../../../store/CacheTwoRoster/actions';
import {
  actionCreators as cacheZeroActionCreators,
} from '../../../../store/CacheZero/actions';
import {makeRosterAdultSelector, makeRosterYouthSelector} from "../../../../store/Settings/Roster/Main";
import {checkPermission} from "../../../../utils/permissionHelper";
import {GroupRosterPerson} from "../../../../models/api/cacheTwoRoster";
import { generateDOMId } from '../../../../utils/cypressHelper';
import EndUserCacheManager from '../../../../utils/cacheManagers/endUserCacheManager';
import { shouldReconfigRouter } from '../../../../utils/cacheLoaders/reloaderHelper';
import { isPathUnderCacheTwoRoster } from '../../../../utils/helpers/endUserPageHelper';
import { connect } from 'react-redux';
import { reduxStoreService } from '../../../../store/service';
import { ComponentUpdateTemplate } from '../../../Templates/ComponentUpdateTemplate';
import {isCacheTwoRosterPopulated} from '../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {}>
>;

@(withRouter as any)
class Roster extends ComponentUpdateTemplate<ConnectedProps> {
  props: ConnectedProps;
  private nextLocation;

  public componentDidMount() {
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheTwoRoster({
          props: this.props,
          isStateNavigated
        });
      }
    );
    this.configRouteLeaveHook();
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheTwoRoster({
          props: nextProps,
          isStateNavigated
        });
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouteLeaveHook();
  }

  componentWillUnmount() {
    const {cacheTwoRoster} = this.props;

    // if we are not navigating to another cache three events page, clear cache
    if (
      this.nextLocation &&
      !isPathUnderCacheTwoRoster(this.nextLocation.pathname) &&
      isCacheTwoRosterPopulated(cacheTwoRoster)
    ) {
      reduxStoreService().dispatch(new ClearCacheBelowOne());
    }
    this.resetRouteLeaveHook();
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  goToAddYouth = () => navPush(this.props.router, `${URLS.MY_ROSTER}/youth/${MY_ROASTER_ADD}`);
  goToAddAdult = () => navPush(this.props.router, `${URLS.MY_ROSTER}/adult/${MY_ROASTER_ADD}`);
  goToPerson = (person: GroupRosterPerson) => navPush(this.props.router, getRosterPersonUrl(person));
  onSwitchShowDeleted = (value) => {
    const {actions, cacheZero: {options}} = this.props;

    if (!options?.Group) return;

    this.props.actions.getGroupRosterCache(
      options.Group.IDi,
      options.Group.TS,
      false,
      false,
      value
    );
  };
  onRestore = (IDi: number, isYouth: boolean, Name: string) => {
    const {actions, cacheZero: {options}, routes} = this.props;

    if (!options?.GeneralPermissions) return;

    checkPermission(
      () => actions.apiRestorePerson(IDi, isYouth, routes),
      options.GeneralPermissions.hasGroup,
      options.GeneralPermissions.hasGroupReason
    );
  };

  renderRosterCard(isYouth: boolean, filteredPeople: Array<GroupRosterPerson>, people: Array<GroupRosterPerson> | null) {

    return (
      <RosterCard
        id={generateDOMId(isYouth ? `roster-add-youth` : `roster-add-adult`)}
        title={isYouth ? 'Youth' : 'Adults'}
        onClick={isYouth? this.goToAddYouth : this.goToAddAdult}
      >
        {filteredPeople.length > 0 &&
          <RosterList>
            {filteredPeople.map((p) => <RosterItem
                Name={p.Name}
                isYouth={isYouth}
                IDi={p.IDi}
                onClick={this.goToPerson}
                onRestoreClick={this.onRestore}
                data={p}
                deleted={p.Inactive}>
                  {p.Name}
                </RosterItem>
            )}
          </RosterList>
        }
        {(people === null || people.length === 0) &&
          <RosterListEmpty message="You haven't added anyone yet" button={
            <Button onClick={isYouth? this.goToAddYouth : this.goToAddAdult} color="green">Add people</Button>}
          />
        }
        {(people !== null && people.length > 0 && filteredPeople.length === 0) &&
          <RosterListEmpty message="No people found" />
        }
      </RosterCard>
    );
  }

  public render() {
    const {
      inert,
      apiLoadingMap, apiSaving, filteredYouth, filteredAdults,
      cacheTwoRoster: {GroupRosterAdults, GroupRosterYouth, showDeletedGroupRoster},
      cacheTwoRoster
    } = this.props;
    if (!isCacheTwoRosterPopulated(cacheTwoRoster) || GroupRosterAdults === undefined || GroupRosterYouth === undefined) return <LoadingAll/>;

    return <Main
      inert={inert}
      header={(
        <HeaderFilters>
          <Switch label="Show Deleted" expand={false} margin={false} onChange={this.onSwitchShowDeleted} value={showDeletedGroupRoster}/>
        </HeaderFilters>
      )}
      mobileBackground="white"
      isLoading={apiLoadingMap[GetGroupRosterCache.requestType] || apiSaving > 0}
    >
      <MainContent handleCompact>
        {this.renderRosterCard(true, filteredYouth, GroupRosterYouth)}
        {this.renderRosterCard(false, filteredAdults, GroupRosterAdults)}
      </MainContent>
    </Main>;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredYouth = makeRosterYouthSelector();
  const filteredAdults = makeRosterAdultSelector();
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    user: state.user,
    cacheZero: state.cacheZero,
    cacheOne: state.cacheOne,
    cacheTwoRoster: state.cacheTwoRoster,
    filteredYouth: filteredYouth(state),
    filteredAdults: filteredAdults(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheOneActionCreators,
    ...cacheZeroActionCreators,
    ...cacheTwoActionCreators
  }, dispatch)
});

const ConnectedRoster = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Roster);

export default ConnectedRoster;
export { default as RosterPerson, Header as RosterPersonHeader } from './Person';
