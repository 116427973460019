import { ActionCreator, ApplicationState } from "../../../..";
import {createApiValidateActions, createValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod} from '../../../../Validation/actionCreator';
import { entityMapper } from "../../../../../utils/dataHelper";
import { CMSContact } from "../../../../../models/api/adminCMSCacheOne";
import { typeName, Action } from "../../../../../utils/StrongActions";
import { SaveState } from "../../../../Rollback/actions";

export const SUFFIX = '__CMS_MULTIPLE_CONTACTS_MODAL';

@typeName("FILTER" + SUFFIX)
export class MultipleContactsModalFilter extends Action {
  constructor(public filterString: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class MultipleContactsToggleFilterTabs extends Action {
  constructor(public searching: boolean) { super(); }
}

@typeName("RESET" + SUFFIX) export class ResetMultipleContactsModal extends Action {}
@typeName("INIT" + SUFFIX)
export class InitMultipleContactsModal extends Action {
  constructor(public contactIds: number[], public contactCategoryId?: number) { super(); }
}

@typeName("TOGGLE_CONTACT_SELECT" + SUFFIX)
export class ToggleContactSelect extends Action {
  constructor(public show: boolean, public contactId: number) { super(); }
}
@typeName("SELECT_ALL" + SUFFIX)
export class SelectAll extends Action { constructor(public contactIds: number[]) { super(); }}
@typeName("DESELECT" + SUFFIX)
export class Deselect extends Action { constructor(public contactIds: number[]) { super(); }}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export type Actions = typeof actionCreators;

export const actionCreators = {
  contactModalUpdateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.contacts.modals.multipleContacts),
  contactModalSimpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  filterMultipleContacts: (filterString: string): ActionCreator => dispatch => dispatch(new MultipleContactsModalFilter(filterString)),
  init: (contactIds: number[]): ActionCreator => (dispatch, getState) => {
    const state = getState();

    const CMSContacts = state.adminCMSSite.cacheOne.CMSContacts;
    let preselectContactCategoryId;

    if (CMSContacts) {
      const contacts = entityMapper<CMSContact>(CMSContacts, contactIds, (c) => c.ID);
      const contactCategoryIds: number[] = [];
      contacts
        .filter((c) => !c.Inactive)
        .forEach((c) => {
          if (c && c.CategoryID && contactCategoryIds.indexOf(c.CategoryID) === -1) contactCategoryIds.push(c.CategoryID);
        });

      if (contactCategoryIds.length === 1) preselectContactCategoryId = contactCategoryIds[0];
    }
    dispatch(new InitMultipleContactsModal(contactIds, preselectContactCategoryId));
    dispatch(new SaveState());
  },
  reset: (): ActionCreator => dispatch => dispatch(new ResetMultipleContactsModal()),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new MultipleContactsToggleFilterTabs(searching)),
  toggleContactSelect: (show: boolean, contactId: number): ActionCreator => dispatch => dispatch(new ToggleContactSelect(show, contactId)),
  selectAll: (contactIds: number[]): ActionCreator => dispatch => dispatch(new SelectAll(contactIds)),
  deselect: (contactIds: number[]): ActionCreator => dispatch => dispatch(new Deselect(contactIds)),
};