import {EventParticipantCalc, EventParticipantsGetPerson, EventParticipantsSave, PostCalcValidate} from './actions';
import {
  E_REGISTER,
  E_RER_PARTICIPANT,
  EVENT,
  eventParticipantsInit,
  getGroupRosterPerson, URLS
} from '../../constants/urls';
import {makeRequestEpic} from "../Validation/epicCreator";
import {Observable} from "rxjs/Rx";
import {ApiRosterSubmitActions as RosterApiSubmitActions} from "../Events/Event/Register/Participant/Roster/actions";
import {ApiTypeSubmitActions as TypeApiSubmitActions} from "../Events/Event/Register/Participant/Type/actions";
import {EventRegisterParticipantTransitionToPending} from "../Events/Event/Register/Participant/Main/actions";
import {ApiSubmitActions as RegisterOverrideFeeSubmitActions} from "../Events/Event/Modals/RegisterOverrideFee/actions";
import {
  ApiSubmitActionsParticipantsClasses,
  EventRegisterParticipantsClearError,
  EventRegisterParticipantsShowError
} from "../Events/Event/Register/Participant/Classes/actions";
import {checkClasses} from "../../utils/classesHelper";
import {replace} from "react-router-redux";
import {NoOp, ShowSnackbarItem} from "../App/actions";
import {getMessageFromErrorResponse} from "../../utils/stringHelper";
import {makeAvailableClassesFilter} from "./index";
import { reduxStoreService } from '../service';

export const cacheFourEventsParticipantsEpic = makeRequestEpic(
  EventParticipantCalc,
  eventParticipantsInit,
  undefined, // body is passed in as is in action.value
  true
);

export const cacheFourEventsParticipantsSaveEpic = makeRequestEpic(
  EventParticipantsSave,
  eventParticipantsInit,
  undefined, // body is passed in as is in action.value
  true,
  (payload, action) => {
    const name = action.form.GroupRosterPerson ? action.form.GroupRosterPerson.Name : action.extra.name;
    if (action.form.ParticipantRegistrationIDi && payload && payload.response) {
      const {EventRegistrationParticipants} = payload.response;
      let eventPerson;
      if (EventRegistrationParticipants) {
        eventPerson = EventRegistrationParticipants.find((person) => {
          return person.IDi === action.form.ParticipantRegistrationIDi && person.IsYouth === action.form.IsYouth;
        });
      }

      if (!eventPerson) {
        return `Registration for ${name} has been removed.`;
      } else if (eventPerson && eventPerson.InCart === -1) {
        return `Removal of registration for ${name} has been added to your cart.`;
      } else if (eventPerson && eventPerson.InCart === 1) {
        return `Changes to registration for ${name} have been added to your cart.`;
      } else {
        return `Changes to registration for ${name} have been saved.`;
      }
    } else {
      return `New registration for ${name} has been added to your cart.`;
    }
  }
);


export const cacheFourEventsParticipantsGetPersonEpic = makeRequestEpic(
  EventParticipantsGetPerson,
  getGroupRosterPerson,
  undefined, // body is passed in as is in action.value
  true
);

export const cacheFourEventsParticipantsRecalcSuccessEpic = action$ => {
  return action$.filter((a) =>
    a.type === RosterApiSubmitActions.successType || a.type === TypeApiSubmitActions.successType ||
    a.type === ApiSubmitActionsParticipantsClasses.successType || a.type === EventParticipantCalc.successType
  )
    .mergeMap((action) => {
      const path = reduxStoreService().getState().routing.locationBeforeTransitions.pathname;

      if (action.type === EventParticipantCalc.successType && (!path.endsWith(`/${E_RER_PARTICIPANT.EDIT}`) || (!path.startsWith(URLS.EVENTS)))) {
        return Observable.of(new NoOp());
      }

      if (action.type === ApiSubmitActionsParticipantsClasses.successType || action.type === RosterApiSubmitActions.successType || action.type === EventParticipantCalc.successType) {
        const rootState = reduxStoreService().getState();
        const c2 = rootState.cacheTwoEvents;
        const c4 = rootState.cacheFourEventsParticipants;
        const {ActiveForm} = rootState.events.event.register.participant.roster;
        const isAdmin = !!rootState.user.user.str_permissions.hasAdminAccess;
        const availableClasses = makeAvailableClassesFilter();
        const classValidation = 
          (
            c4.isYouth !== undefined &&
            !!c2.EventTypeRegistrationSettings &&
            checkClasses(
              c4.EventClassesIndividualRegistered,
              c4.EventClassesIndividualAvailable,
              availableClasses(rootState),
              c4.isYouth,
              false,
              c2.EventTypeRegistrationSettings.NamesRegistrationSettings.EnableClassWaitingList,
              c4.isYouth ? 1 : 0,
              c4.isYouth ? 0 : 1,
              isAdmin,
              ActiveForm.DOB
            )
          );
        if (!!classValidation && classValidation.warnings.length > 0) {
          return Observable.merge(
            Observable.of(new EventRegisterParticipantsShowError(classValidation.warnings[0].message, classValidation.error)),
            Observable.of(new EventRegisterParticipantTransitionToPending()),
          );
        } else {
          return Observable.merge(
            Observable.of(new EventRegisterParticipantsClearError()),
            Observable.of(new EventRegisterParticipantTransitionToPending()),
          );
        }
      }
      return Observable.of(new EventRegisterParticipantTransitionToPending());
    });
};

export const cacheFourEventsParticipantsFailureEpic = action$ => {
  return action$.filter((a) => (
    a.type === EventParticipantsSave.failureType || a.type === RosterApiSubmitActions.failureType ||
    a.type === TypeApiSubmitActions.failureType || a.type === ApiSubmitActionsParticipantsClasses.failureType ||
    a.type === RegisterOverrideFeeSubmitActions.failureType
  ))
    .mergeMap((action) => {
      const route = reduxStoreService().getState().routing.locationBeforeTransitions;
      const sp = route.pathname.split('/');
      const newPath = `/${sp[1]}/${sp[2]}/${sp[3]}/${sp[4]}/${sp[5]}/${EVENT.REGISTRATION}`;
      if (action.response.status === 400) {
        // If 400 with "Incorrect Payment" is the special case
        if (action.response.xhr.response.error.Detail === 'Incorrect Payment') {
          if (sp[2] === 'events' && (sp[6] === EVENT.REGISTRATION || sp[6] === EVENT.REGISTER) && sp.length > 7) {
            return Observable.merge(
              Observable.of(replace(`${newPath}${route.search}`)),
              Observable.of(new ShowSnackbarItem(getMessageFromErrorResponse(action.response)))
            );
          }
          return Observable.of(new ShowSnackbarItem(getMessageFromErrorResponse(action.response)));
        }
      } else if (action.response.status === 409) {
        if (sp[2] === 'events' && (sp[6] === EVENT.REGISTRATION || sp[6] === EVENT.REGISTER) && sp.length > 7) {
          return Observable.merge(
            Observable.of(replace(`${newPath}${route.search}`)),
          );
        }
      }
      return Observable.of(new NoOp());
    });
};
