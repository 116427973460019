
import {makeRequestEpic} from "../../../../Validation/epicCreator";
import { GetEventsList, UpdateFinalEventTypeID, GetSourceClassesList, UpdateEventImportPricingSubmitActions, UpdateEventImportTimeBlocksSubmitActions, UpdateEventImportClassesSubmitActions } from "./actions";
import { getAdminEventsList, getAdminEventsListBody, getSourceClassesList, getSourceClassesListBody, getUpdateAdminEventImportPricing, getUpdateAdminEventImportTimeBlocks, getUpdateAdminEventImportClass } from "../../../../../constants/adminEventsUrls";
import { ENTITY_PARSE_ERROR } from "../../../../../constants/messages/adminCMS";
import { ADMIN_EVENTS } from "../../../../../constants/messages/adminEvents";
import { SaveState } from "../../../../Rollback/actions";
import { AjaxResponse, Observable } from "rxjs/Rx";
import { captureTentarooError } from "../../../../../utils/dataHelper";

export const adminEventsGetEventsListEpic = makeRequestEpic(
  GetEventsList,
  getAdminEventsList,
  getAdminEventsListBody,
  true,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  (payload) => {
    const bodyStr = payload.request.body;
    try {
      const body = JSON.parse(bodyStr);

      return Observable.concat(
        Observable.of(new UpdateFinalEventTypeID(body.AppState.EventTypeID)),
        Observable.of(new SaveState())
      );
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(ADMIN_EVENTS)));
      return '';
    }
  },
);

export const getSourceClassesListEpic = makeRequestEpic(
  GetSourceClassesList,
  getSourceClassesList,
  getSourceClassesListBody,
  true,
);

export const adminEventImportPricingEpic = makeRequestEpic(
  UpdateEventImportPricingSubmitActions,
  getUpdateAdminEventImportPricing,
  undefined,
  true,
  (response: AjaxResponse) => {
    let message = 'Pricing was imported.';

    if (response.response.SkippedDateImport) {
      message += ' At least one date was skipped in import, please review.';
    }

    return message;
  }
);

export const adminEventImportTimeBlocksEpic = makeRequestEpic(
  UpdateEventImportTimeBlocksSubmitActions,
  getUpdateAdminEventImportTimeBlocks,
  undefined,
  true,
  (response: AjaxResponse) => {
    return 'Time Blocks were imported';
  }
);

export const adminEventImportClassEpic = makeRequestEpic(
  UpdateEventImportClassesSubmitActions,
  getUpdateAdminEventImportClass,
  undefined,
  true,
  (response: AjaxResponse) => {
    return 'Selected classes and/or options were imported';
  }
);
