import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators as appActionCreators } from '../../../../../../../store/App/actions';
import { actionCreators as cacheThreeClassTypeActionCreators } from '../../../../../../../store/AdminEvents/CacheThreeClassType/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../../store/Rollback/actions';
import { actionCreators as classTypeFormActionCreators, DeleteAdminEventClassTypeSubmitActions, UpdateAdminEventClassTypeSubmitActions } from '../../../../../../../store/AdminEvents/ClassTypes/Form/actions';
import { actionCreators } from '../../../../../../../store/AdminEvents/Events/Event/ClassTypes/actions';
import { Button, Alert, EmptyMessage, AdminClassTypeCard, SimpleList, PageLoader } from '../../../../../../../components/Elements';
import '../../../../../../../styles/pages/admin-events/events/classes/class-types-tab/index.scss';
import { navPush } from '../../../../../../../utils';
import ClassTypesFilter from './ClassTypesFilter';
import { makeActiveClassTypesSelector, makeFilteredClassTypesSelector } from '../../../../../../../store/AdminEvents/Events/Event/ClassTypes';
import { ClassType } from '../../../../../../../models/api/options';
import { ADMIN_EVENT_CLASS_TYPES } from '../../../../../../../constants/messages/adminEvents';
import { ENTITY_NOT_ADDED, ENTITY_NOT_FOUND } from '../../../../../../../constants/messages/adminCMS';
import { ClassTypesIcon } from '../../../../../../Icons';
import { EmptyMessageType } from '../../../../../../Elements/EmptyMessage';
import { AdminClassTypeCardType } from '../../../../../../Elements/AdminClassType/Card';
import { CardCategory } from '../../../../../../Elements/Card';
import { Main, MainContent } from '../../../../../../Layouts';
import { ADMIN_EVENT_NEW_CLASS_TYPE_PATH, ADMIN_EVENT_EDIT_CLASS_TYPE_PATH } from '../../../../../../../routes';
import ClassTypeForm from './ClassType';
import { getNewClassTypeUrl, constructAdminEventsEventUrlParams, getEditClassTypeUrl, getAdminEventsEventClassTypesUrl } from '../../../../../../../constants/adminEventsUrls';
import { isPathnameMatchingRoute } from '../../../../../../../utils/urlHelper';
import { checkAdminEventClassTypePermission } from '../../../../../../../utils/helpers/adminEventsPageHelper';
import { isPathUnderAdminEvent } from '../../../../../../../utils/eventsHelper';
import { actionCreators as cacheTwoEventActionCreators } from '../../../../../../../store/AdminEvents/CacheTwoEvent/actions';
import { generateDOMId } from '../../../../../../../utils/cypressHelper';
import { shouldReconfigRouter } from '../../../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { noOpenedModals } from '../../../../../../../utils/modalHelper';
import { reduxStoreService } from '../../../../../../../store/service';
import {isAdminEventsCacheTwoEventPopulated} from '../../../../../../../utils/cachePopulatedCheckers/adminEvents';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--event--class-types-tab';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {classTypeId: string}>;

type Props = WithInertAttribute<{
  disabled?: boolean;
}>

class AdminEventsEventClassTypesTab extends React.Component<Props, {}> {
  public props: ConnectedProps & Props;
  private nextLocation;

  componentDidMount() {
    const route = this.props.routes[this.props.routes.length - 1];
    if (route.path !== ADMIN_EVENT_EDIT_CLASS_TYPE_PATH && route.path !== ADMIN_EVENT_NEW_CLASS_TYPE_PATH) this.configRouter();
  }

  private configRouter() {
    const {router, routes} = this.props;
    router.setRouteLeaveHook(routes[routes.length - 1], this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentDidUpdate(prevProps: Props & ConnectedProps) {
    const {routes} = this.props;

    const route = routes[routes.length - 1];
    if (shouldReconfigRouter(prevProps, this.props) && route.path !== ADMIN_EVENT_EDIT_CLASS_TYPE_PATH && route.path !== ADMIN_EVENT_NEW_CLASS_TYPE_PATH) this.configRouter();
  }

  componentWillUnmount() {
    const {actions, adminEventsCacheTwoEvent} = this.props;

    // if we are not navigating to any pages under cache two event, clear cache
    if (
      this.nextLocation &&
      !isPathUnderAdminEvent(this.nextLocation.pathname) &&
      isAdminEventsCacheTwoEventPopulated(adminEventsCacheTwoEvent)
    ) {
      actions.clearAdminEventsCacheTwoEvent();
    }

    this.resetRouteLeaveHook();
  }

  onNewClassTypeClick = () => {
    const {routes, cacheZero, adminEventsCacheOne, adminEventsCacheTwoEvent} = this.props;

    checkAdminEventClassTypePermission(
      () => {
        const route = routes[routes.length - 1];
        if (route.path === ADMIN_EVENT_EDIT_CLASS_TYPE_PATH) return;
        if (!adminEventsCacheOne || !adminEventsCacheOne.EventsEventType) return;
        if (!adminEventsCacheTwoEvent || !adminEventsCacheTwoEvent.EventsEvent) return;
            
        const newClassTypeUrl = getNewClassTypeUrl(constructAdminEventsEventUrlParams(this.props, adminEventsCacheTwoEvent.EventsEvent.IDi, adminEventsCacheTwoEvent.EventsEvent.Name));
        navPush(this.props.router, newClassTypeUrl);
      },
      cacheZero,
    );
  };


  renderEmptyMessage = (emptyMessage: string) => {
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        admin
        icon={ClassTypesIcon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        noMobilePadding
        description={emptyMessage}
        actions={<Button className={`${namespace()}--empty-message-btn`} color="green" onClick={this.onNewClassTypeClick}>{'NEW CLASS TYPE'}</Button>}/>
    );
  };

  onEditClassTypeClick = (classType: ClassType) => {
    const {routes, adminEventsCacheOne, adminEventsCacheTwoEvent} = this.props;
    const route = routes[routes.length - 1];
    if (!adminEventsCacheOne || !adminEventsCacheOne.EventsEventType) return;
    if (!adminEventsCacheTwoEvent || !adminEventsCacheTwoEvent.EventsEvent) return;
        
    const editClassTypeUrl = getEditClassTypeUrl(constructAdminEventsEventUrlParams(this.props, adminEventsCacheTwoEvent.EventsEvent.IDi, adminEventsCacheTwoEvent.EventsEvent.Name, classType.IDi, classType.Name));
    navPush(this.props.router, editClassTypeUrl);

  };

  onRestoreClassTypeClick = (classType: ClassType) => {
    const {router, cacheZero, routes, classTypeFormActions} = this.props;

    checkAdminEventClassTypePermission(
      () => {
        classTypeFormActions.deleteAdminEventClassType(classType.IDi, routes, router, false);
      },
      cacheZero,
      true,
    );
  };

  onDeleteClassTypeClick = (classType: ClassType) => {
    const {router, routes, classTypeFormActions, cacheZero} = this.props;


    checkAdminEventClassTypePermission(
      () => {
        classTypeFormActions.deleteAdminEventClassType(classType.IDi, routes, router, true);
      },
      cacheZero,
      true,
    );
  };

  itemRenderer = (index) => {
    const {filteredClassTypes} = this.props;

    const classType = filteredClassTypes[index];
    const selected = isPathnameMatchingRoute(location.pathname, ADMIN_EVENT_EDIT_CLASS_TYPE_PATH, 'classTypeId', classType.IDi);

    return (
      <AdminClassTypeCard
        key={`class_type_card${classType.IDi}`}
        type={AdminClassTypeCardType.CLASS_TYPE_LIST}
        category={CardCategory.LIST}
        option={classType}
        onEdit={this.onEditClassTypeClick}
        onRestore={this.onRestoreClassTypeClick}
        onDelete={this.onDeleteClassTypeClick}
        selected={selected}
      />
    );
  };

  renderContent = () => {
    const {filteredClassTypes} = this.props;

    return (
      <div className={`${namespace()}--list-wrapper`}>
        {<SimpleList
          itemRenderer={this.itemRenderer}
          totalLength={filteredClassTypes.length}
          threshold={200}
        />}
      </div>
    );
  };

  onClickMain = (e) => {
    // if we are clicking on the `tab`, dont do anything in this callback (i.e. manually stop propagation)
    if (e.target.classList.contains('mdl-tabs__tab')) return;
    e.stopPropagation();
    const { routes, router} = this.props;
    const thisRoute = routes[routes.length - 1];

    if (reduxStoreService().getState().app.apiSaving <= 0 && this.props.adminEventsCacheTwoEvent.EventsEvent) {
      if (thisRoute.path === ADMIN_EVENT_NEW_CLASS_TYPE_PATH || thisRoute.path === ADMIN_EVENT_EDIT_CLASS_TYPE_PATH) {
        this.props.actions.clearAdminEventsCacheThreeClassType();
        const backUrl = getAdminEventsEventClassTypesUrl(constructAdminEventsEventUrlParams(this.props, this.props.adminEventsCacheTwoEvent.EventsEvent.IDi, this.props.adminEventsCacheTwoEvent.EventsEvent.Name));
        navPush(router, backUrl);
      }
    }
  };

  public render() {
    const {actions, apiSavingMap, inert, classTypesTab, activeClassTypes, routes, filteredClassTypes} = this.props;
    
    const thisRoute = routes[routes.length - 1];
    let emptyMessage;

    if (activeClassTypes.length === 0 && filteredClassTypes.length === 0) {
      emptyMessage = ENTITY_NOT_ADDED(ADMIN_EVENT_CLASS_TYPES);
    } else if (filteredClassTypes.length === 0) {
      emptyMessage = ENTITY_NOT_FOUND(ADMIN_EVENT_CLASS_TYPES);
    }
    
    const saving = (
      apiSavingMap[UpdateAdminEventClassTypeSubmitActions.requestType] ||
      apiSavingMap[DeleteAdminEventClassTypeSubmitActions.requestType]
    ) && noOpenedModals();

    return (
      <Main inert={inert} onClick={this.onClickMain}>
        <MainContent className={`${namespace()}`}>
          <div className={`${namespace()}--list-actions`}>
            <ClassTypesFilter
              ActiveForm={classTypesTab.ActiveForm}
              ValidationRules={classTypesTab.ValidationRules}
              reduxActions={actions}
              newButton={
                <Button id={generateDOMId("admin-class-type-add-btn")} className='control' color='green' textColor='white' onClick={this.onNewClassTypeClick}>New</Button>}
            />
          </div>
          <Alert className={`${namespace()}--alert`}>Class Types are shared between all events in the system – any changes made will affect all events.</Alert>
          {emptyMessage && this.renderEmptyMessage(emptyMessage)}
          {!emptyMessage && this.renderContent()}
          {(saving) && <PageLoader className={`${namespace()}--page-loader`} />}
          {(thisRoute.path === ADMIN_EVENT_NEW_CLASS_TYPE_PATH || thisRoute.path === ADMIN_EVENT_EDIT_CLASS_TYPE_PATH) && (
            <ClassTypeForm
              inert={inert}
              type={thisRoute.path === ADMIN_EVENT_EDIT_CLASS_TYPE_PATH ? 'edit' : 'add'}
              key='clas-type-sidemodal'
            />
          )}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const activeClassTypesSelector = makeActiveClassTypesSelector();
  const filteredClassTypesSelector = makeFilteredClassTypesSelector();

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSavingMap: state.app.apiSavingMap,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsCacheTwoEvent: state.adminEvents.cacheTwoEvent,
    adminEventsCacheThreeClassType: state.adminEvents.cacheThreeClassType,
    classTypesTab: state.adminEvents.events.event.classes.classTypesTab,
    cacheZero: state.cacheZero,
    session: state.session,
    user: state.user,
    apiSaving: state.app.apiSaving,
    activeClassTypes: activeClassTypesSelector(state),
    filteredClassTypes: filteredClassTypesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...rollbackActionCreators,
    ...cacheThreeClassTypeActionCreators,
    ...cacheTwoEventActionCreators,
  }, dispatch),
  classTypeFormActions: bindActionCreators({
    ...classTypeFormActionCreators,
  }, dispatch),
});

const ConnectedAdminEventsEventClassTypesTab = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(AdminEventsEventClassTypesTab);

export default withRouter<Props>(ConnectedAdminEventsEventClassTypesTab);
