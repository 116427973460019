import * as Actions from './actions';
import {eventTypeSelector} from '../../CacheOne';
import {EventType} from "../../../models/api/cacheOne";
import {makeNoFilterSelector, makeSimpleSelector} from '../../../utils';
import {createSelector} from "reselect";
import { ClearAllCache, ClearAllEndUserCacheButOptions } from '../../App/actions';
import { Reducer } from 'redux';
import { isActionType } from '../../../utils/StrongActions';
import { ApplicationState } from '../..';

const filterSelector = (state: ApplicationState) => state.app.searchFilter;

const filterFnc = (e: EventType, f: string) => e.Name.toLowerCase().includes(f);

const eventSort = (a, b) => {
  // then sort by name
  if (a.Name.toLowerCase() === b.Name.toLowerCase()) {
    return 0;
  } else if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
    return -1;
  } else {
    return 1;
  }
};

export const makeAvailableSelector = makeSimpleSelector((e: EventType) => e.NumEventsAvailable > 0, filterFnc, eventTypeSelector, filterSelector, eventSort);
export const makeUpcomingSelector = makeSimpleSelector((e: EventType) => e.NumEventsUpcoming > 0, filterFnc, eventTypeSelector, filterSelector, eventSort);
export const makeCurrentSelector = makeSimpleSelector((e: EventType) => e.NumCurrentRegistrations > 0, filterFnc, eventTypeSelector, filterSelector, eventSort);
// export const makePastSelector = makeSimpleSelector((e: EventType) => e.NumEventsPast > 0, filterFnc, eventTypeSelector, filterSelector);
export const makePastSelector = () => createSelector(
  [eventTypeSelector, filterSelector],
  (items: Array<any>, textFilter) => {
    let f;
    if (textFilter && textFilter !== '') f = textFilter.toLowerCase();
    items.sort((a: EventType, b: EventType) => {
      if (b.NumPastRegistrations > 0 && a.NumPastRegistrations === 0) {
        return 1;
      } else if (a.NumPastRegistrations > 0 && b.NumPastRegistrations === 0) {
        return -1;
      }
      // then sort by name
      if (a.Name.toLowerCase() === b.Name.toLowerCase()) {
        return 0;
      } else if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
        return -1;
      } else {
        return 1;
      }
    });
    return items.filter((item: any) => {
      if (f) {
        return item.NumEventsPast > 0 && filterFnc(item, f);
      }
      return item.NumEventsPast > 0;
    });
  }
);

export const makeNoFilterAvailableSelector = makeNoFilterSelector((e: EventType) => e.NumEventsAvailable > 0, eventTypeSelector);
export const makeNoFilterUpcomingSelector = makeNoFilterSelector((e: EventType) => e.NumEventsUpcoming > 0, eventTypeSelector);
export const makeNoFilterCurrentSelector = makeNoFilterSelector((e: EventType) => e.NumCurrentRegistrations > 0, eventTypeSelector);
export const makeNoFilterPastSelector = makeNoFilterSelector((e: EventType) => e.NumEventsPast > 0, eventTypeSelector);

export interface EventsEventTypesState {
  selectedTab: 'available' | 'upcoming' | 'current' | 'past';
  filterString?: string;
}
const getInitialState: () => EventsEventTypesState = () => ({selectedTab: 'available'});

const EventsEventTypes: Reducer<EventsEventTypesState> = (state, action) => {
  if (isActionType(action, Actions.EventEventTypesSelectTab)) {
    return {...state, selectedTab: action.selectedTab};
  } else if (isActionType(action, ClearAllCache) || isActionType(action, ClearAllEndUserCacheButOptions)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default EventsEventTypes;
