import {makeRequestEpic} from "../../../Validation/epicCreator";
import {UpdateBlackoutDateSubmitActions, DeleteBlackoutDateSubmitActions} from "./actions";
import {getUpdateBlackoutDateUrl, getDeleteBlackoutDateUrl} from "../../../../constants/urls";
import {AjaxResponse} from "rxjs/ajax";
import { ENTITY_CREATED, ENTITY_EDITED, ENTITY_PARSE_ERROR, ENTITY_DELETED } from "../../../../constants/messages/adminCMS";
import { BLACKOUT_DATE } from "../../../../constants/messages/adminFacilityLocation";
import { captureTentarooError } from "../../../../utils/dataHelper";

export const adminFacilityLocationUpdateBlackoutDateEpic = makeRequestEpic(
  UpdateBlackoutDateSubmitActions,
  getUpdateBlackoutDateUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      if (body.row.ID) {
        return ENTITY_EDITED(BLACKOUT_DATE);
      }
      return ENTITY_CREATED(BLACKOUT_DATE);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(BLACKOUT_DATE)));
      return '';
    }
  },
);

export const adminFacilityLocationDeleteBlackoutDateEpic = makeRequestEpic(
  DeleteBlackoutDateSubmitActions,
  getDeleteBlackoutDateUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    return ENTITY_DELETED(BLACKOUT_DATE);
  },
);