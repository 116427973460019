import { GLAccount } from "../../models/api/options";
import { AdminSettingsCacheTwoGLAccountState } from "../../store/AdminSettings/CacheTwoGLAccount";
import CacheManager from "./cacheManager";
import { ClearAdminSettingsCacheTwoGLAccount } from "../../store/AdminSettings/CacheTwoGLAccount/actions";
import { ModalTypes, isModalOpened } from "../modalHelper";
import { RouteComponentProps } from "react-router";
import { IGLAccountRouterParams } from "../helpers/adminSettingsPageHelper";
import { ensureAdminSettingsCacheTwoGLAccount } from "../cacheLoaders/adminSettingsCacheTwoGLAccountLoader";
import { AdminSettingsCacheTwoLocationState } from "../../store/AdminSettings/CacheTwoLocation";
import { Location } from "../../models/api/adminCMSCacheOne";
import { ClearAdminSettingsCacheTwoLocation } from "../../store/AdminSettings/CacheTwoLocation/actions";
import { ILocationRouterParams } from "../helpers/sharedPageHelper";
import { ensureAdminSettingsCacheTwoLocation } from "../cacheLoaders/adminSettingsCacheTwoLocationLoader";
import { reduxStoreService } from "../../store/service";
import { LoadAdminSettingsGLAccountParams, LoadAdminSettingsLocationParams } from "../cacheLoaders/helpers/models";
import {isAdminSettingsCacheTwoGLAccountPopulated, isAdminSettingsCacheTwoLocationPopulated} from "../cachePopulatedCheckers/adminSettings";

export default class AdminSettingsCacheManager extends CacheManager {
  private static _instance: AdminSettingsCacheManager;

  public static getInstance(): AdminSettingsCacheManager {
    if (!this._instance) {
      this._instance = new AdminSettingsCacheManager();
    }

    return this._instance;
  }

  constructor() {
    super();
  }

  // Location
  private isPopulatedAdminSettingsCacheTwoLocationInvalid(cacheTwoLocation: AdminSettingsCacheTwoLocationState, locationId: number) {
    const isInvalid = this._isPopulatedCacheInvalid<Location>(
      cacheTwoLocation.Location,
      (location) => location.ID,
      locationId,
    );

    if (isInvalid) {
      reduxStoreService().dispatch(new ClearAdminSettingsCacheTwoLocation());
    }

    return isInvalid;
  };

  public loadCacheTwoLocation(
    params: LoadAdminSettingsLocationParams,
  ) {
    // TODO: Implement this when working on admin settings - like other admin modules
    /*
      const locationId = getDataIdFromUrlOrModalProp<ILocationRouterParams>(
        getLocationIdFromPath,
        props,
        locationIdFromProps,
      );
    */
    const {props, isEdit, locationIdFromProps, isStateNavigated} = params;
    const rootState = reduxStoreService().getState();
    if (this.cacheTwoLocationNeedsReload(
      rootState.adminSettings.cacheTwoLocation,
      props.params.locationId,
      props,
      isEdit,
      locationIdFromProps,
    ) || isStateNavigated) {
      return ensureAdminSettingsCacheTwoLocation({
        ...params,
        locationId: locationIdFromProps,
      });
    }

    return false;
  };

  private cacheTwoLocationNeedsReload(
    sharedCacheTwoLocation: AdminSettingsCacheTwoLocationState,
    locationIdFromUrl: string,
    props: RouteComponentProps<ILocationRouterParams, {}>,
    isEdit: boolean,
    locationIdFromModal?: number
  ) {
    const locationId = locationIdFromUrl || (locationIdFromModal ? `${locationIdFromModal}` : "");

    // TODO: Implement this when working on admin settings - like other admin modules
    /*
      const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
      const cacheOne = (reduxStoreService().getState()).adminCMSSite.cacheOne;
      if (this.isPopulatedAdminCMSCacheOneInvalid(cacheOne, siteId)) return true;
    */

    return (
      (isEdit && !isAdminSettingsCacheTwoLocationPopulated(sharedCacheTwoLocation)) ||
      this.isPopulatedAdminSettingsCacheTwoLocationInvalid(
        sharedCacheTwoLocation,
        Number(locationId),
      )
    );
  }

  // GLAccount
  private isPopulatedCacheTwoGLAccountInvalid(
    cacheTwoGLAccount: AdminSettingsCacheTwoGLAccountState,
    glAccountId: string,
  ) {
    const isInvalid = this._isPopulatedCacheInvalid<GLAccount>(
      cacheTwoGLAccount.GLAccount,
      (glAccount) => glAccount.ID,
      glAccountId,
    );

    if (isInvalid) {
      const glAccountFormModalOpened = isModalOpened(ModalTypes.GL_ACCOUNT_FORM);
      // @todo - clear cache below one action when implementing cache one
      if (glAccountFormModalOpened) reduxStoreService().dispatch(new ClearAdminSettingsCacheTwoGLAccount());
    }

    return isInvalid;
  };

  public loadCacheTwoGLAccount(params: LoadAdminSettingsGLAccountParams) {
    // TODO: Implement this when working on admin settings - like other admin modules
    /*
      const glAccountId = getDataIdFromUrlOrModalProp<IGLAccountRouterParams>(
        getGLAccountIdFromPath,
        props,
        glAccountIdFromModalProps,
      );
    */
    const {props, isEdit, glAccountIdFromModalProps, isStateNavigated} = params;
    const rootState = reduxStoreService().getState();
    if (this.cacheTwoGLAccountNeedsReload(
      rootState.adminSettings.cacheTwoGLAccount,
      props.params.glAccountId,
      props,
      isEdit,
      glAccountIdFromModalProps,
    ) || isStateNavigated) {
      return ensureAdminSettingsCacheTwoGLAccount({
        ...params,
        glAccountId: glAccountIdFromModalProps,
      });
    }

    return false;
  };

  private cacheTwoGLAccountNeedsReload(
    cacheTwoGLAccount: AdminSettingsCacheTwoGLAccountState,
    glAccountIdFromUrl: string,
    props: RouteComponentProps<IGLAccountRouterParams, {}>,
    isEdit: boolean,
    glAccountIdFromModal?: number,
  ) {
    // TODO: Implement this when working on admin settings - like other admin modules
    /*
      const endUserCacheOneInvalid = EndUserCacheManager.getInstance().cacheOneNeedsReload(props);
      const cacheOne = (reduxStoreService().getState()).adminCMSSite.cacheOne;
      if (this.isPopulatedAdminCMSCacheOneInvalid(cacheOne, siteId)) return true;
    */
    const glAccountId = glAccountIdFromUrl || (glAccountIdFromModal ? `${glAccountIdFromModal}` : "");
    return (
      this.isPopulatedCacheTwoGLAccountInvalid(
        cacheTwoGLAccount,
        glAccountId,
      ) ||
      (isEdit && !isAdminSettingsCacheTwoGLAccountPopulated(cacheTwoGLAccount))
    );
  }
}