import { Image } from "./cacheOne";
import * as moment from 'moment';

export interface EventType {
  ID: number;
  Name: string;
  Inactive: boolean;
};

export interface CMSSocial {
  ID: number;
  SocialTypeID: number;
  SocialUser: string;
}

export interface Location {
  ID: number;
  Name: string;
  Address: string;
  City: string;
  StateID: number;
  State: string;
  Zip: string;
  PhoneNumber: string;
  FaxNumber: string;
  Inactive: boolean;
  TS: number;
  NumFacilities?: number;
  hasEdit?: boolean;
  hasEditReason?: string;
};

export interface CMSSite {
  ID: number;
  SiteDomain: string;
  ContactName: string;
  Phone: string;
  Fax: string;
  Email: string;
  Address: string;
  Address2: string;
  City: string;
  StateID: number;
  Zip: string;
  DonateURL: string;
  DonateName: string;
  IsDonateNewWindow: boolean;
  JoinURL: string;
  JoinName: string;
  IsJoinNewWindow: boolean;
  IncludeAllCategoriesInCalendar: boolean;
  CalendarPrimaryCategoryID: number;
  IncludeDistrictsInPrimaryCalendar: boolean;
  ShowCalendarOnHomepage: boolean;
  FeaturedCalendarCodeHTML: string;
  SEOTitle: string;
  SEODescription: string;
  SEOKeywords: string;
  GoogleAnalytics: string;
  MapInFooterHTML: string;
  SocialWidgetHTML: string;
  SiteTS: number;
  HomepageTS: number;
  homepage1TS: number;
  hasResourceAdd: boolean;
  hasResourceAddReason: string;
  hasResourceEdit: boolean;
  hasResourceEditReason: string;
  hasSettingsEdit: boolean;
  hasSettingsEditReason: string;
  canChangeHTML: boolean;
  hasSiteMenuAdd: boolean;
  hasSiteMenuAddReason: string;
  hasSiteMenuEdit: boolean;
  hasSiteMenuEditReason: string;
  hasPageMenuAdd: boolean;
  hasPageMenuAddReason: string;
  hasPageMenuEdit: boolean;
  hasPageMenuEditReason: string;
  hasPageAdd: boolean;
  hasPageAddReason: string;
  hasJustDistrictSites: boolean;
};

export interface CMSSiteMessage {
  ID: number;
  EmbedName: string;
  Title: string;
  Description: string;
  ButtonLabel: string;
  ButtonURL: string;
  IsNewWindow: boolean;
  TS: number;
};

export interface SiteEventCategory {
  ID: number;
  Name: string;
  NameAndIsHidden: string;
  IsShownOnSite: boolean;
  IsDistricts: boolean;
  Inactive: boolean;
};

export interface PageTypesFilter {
  ID: number;
  Name: string;
};

export interface PageType {
  ID: number;
  Name: string;
  ShowWhenAdding: boolean;
  AddingOrder: number;
  AddingName: string;
  AddingDescription: string;
};

export interface ContactLayout {
  ID: number;
  Name: string;
};

export enum PhotoLyaoutIDValue {
  SLIDER=1,
  GALLERY_GRID=2,
  INLINE=3,
  FIRST_LARGE=6,
  FULL_WIDTH_SLIDER=7,
  FULL_WIDTH_INLINE=8,
}

export interface PhotoLayout {
  ID: number;
  Name: string;
};

export enum LinkTypeValue {
  PAGE=1,
  CALENDAR=4,
  RESOURCE=5,
  URL=7,
  NO_LINK=8,
}

export interface LinkType {
  ID: LinkTypeValue;
  Name: string;
};

export interface SocialType {
  ID: number;
  Name: string;
  Ord: number;
  IsFacebook: boolean;
  Inactive: boolean;
};

export interface ContactCategory {
  ID: number;
  Name: string;
};

export interface CMSPagesFilters {
  PageTypeID?: number;
  IncludePastEvents?: boolean;
  IncludeExpiredBlogArticles?: boolean;
  EventCategoryID?: number;
  EventStartDate?: any;
  ShowInactive?: boolean;
};

export enum PageTypeIDValue {
  ALL = 0,
  HOMEPAGE = 1,
  STANDARD_PAGE = 2,
  BLOG_ARTICLE = 5,
  CALENDAR_EVENT = 8,
  EVENT_MODULES = 3,
  FACILITY_MODULES = 4,
  DISTRICT = 6,
}

export interface CMSPage {
  ID: number;
  SiteID: number;
  PageTypeID: number;
  Name: string;
  ShortURL: string;
  Inactive: boolean;
  EventType?: string;
  FacilityLocation?: string;
  District?: string;
  StartDate?: string;
  RowNum: number;
  IsExpired?: boolean;
  hasEdit?: boolean;
  canChangeHTML?: boolean;
  IsPrimaryEventPage?: boolean;
  originalPageId?: number;
};

export interface CMSContactImage extends Image {
  attachPath: string;
  dataUrl?: string;
  inputObj?: any;
}

export interface CMSContact {
  ID: number;
  Name: string;
  Title: string;
  Company: string;
  CategoryID: number;
  SiteID: number;
  Inactive: boolean;
  Color: string;
  FeaturedImage: CMSContactImage;
  Phone: string;
  MobilePhone: string;
  Email: string;
  Fax: string;
  RowNum: number;
  TS: number;
};

export interface CMSResourceCategory {
  ID: number;
  Name: string;
  Inactive: boolean;
  TS: number;
  // use to determine if its just deleted within the modal
  // i.e. should be set upon delete response, and reset upon init
  ClientInactive?: boolean;
};

export interface CMSResourceFile {
  ID: number;
  attachPath: string;
  filePath: string;
  Filename: string;
}

export interface CMSResource {
  ID: number;
  SiteID?: number;
  CategoryID?: number;
  Name?: string;
  Description?: string;
  Inactive?: boolean;
  TS?: number;
  File?: CMSResourceFile;
  RowNum?: number;
  selected?: boolean;   // use in multiple select modal
};

export interface CMSSiteMenuItem {
  ID: number;
  ParentID: number;
  LinkTypeID: number;
  Name: string;
  Name1?: string;
  Name2?: string;
  Ord: number;
  Inactive: boolean;
  TS: number;
  IsLinkNewWindow?: boolean;
  MenuStyle?: string;
  IsAlignRight?: boolean;
  // for parent
  Expand?: boolean;
  SelectedPage?: CMSPage;
};

export interface CMSPageMenuItem {
  ID: number;
  ParentID: number;
  LinkTypeID: number;
  Name: string;
  Ord: number;
  Inactive: boolean;
  TS: number;
  // for parent
  Expand?: boolean;
  SelectedPage?: CMSPage;
}