import {ActionCreator, ApplicationState} from '../../../../../';
import {
  createApiSubmitActions,
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions
} from '../../../../../Validation/actionCreator';
import {BaseEndUserClass, RegisteredClass} from "../../../../../../models/class";
import {SaveState} from "../../../../../Rollback/actions";
import {checkClasses} from "../../../../../../utils/classesHelper";
import {EventRegisterParticipantSelectPage, EventRegisterParticipantSetPendingPage} from "../Main/actions";
import {ParticipantTab} from "../Main";
import {eventParticipantsRecalcBody} from "../../../../../../constants/urls";
import {PostEventRegistrationClass} from "../../../../../../models/api/cacheFourEvents";
import {composeClassRegistrations} from "../../../../../CacheFourEventsNumbers/actions";
import {makeAvailableClassesFilter} from "../../../../../CacheFourEventsParticipants";
import { typeName, Action } from '../../../../../../utils/StrongActions';
import { captureTentarooError, captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../../../utils/dataHelper';

export const SUFFIX = '__PARTICIPANTS_WIZARD_CLASSES';

// @todo: classes doesn't really have a form....
export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ApiSubmitActionsParticipantsClasses = createApiSubmitActions(SUFFIX, true);

@typeName(`ADD_CLASS${SUFFIX}`)
export class EventRegisterParticipantsClassesAdd extends Action {
  constructor(public clazz: BaseEndUserClass) { super(); }
}

@typeName(`REMOVE_CLASS${SUFFIX}`)
export class EventRegisterParticipantsClassesRemove extends Action {
  constructor(public clazz: RegisteredClass) { super(); }
}

@typeName(`WAITLIST_CLASS${SUFFIX}`)
export class EventRegisterParticipantsClassesWaitlist extends Action {
  constructor(public clazz: BaseEndUserClass) { super(); }
}

@typeName(`SHOW_ERROR${SUFFIX}`) export class EventRegisterParticipantsShowError extends Action {
  constructor(public error: string, public isError: boolean) { super(); }
}

@typeName(`CLEAR_ERROR${SUFFIX}`) export class EventRegisterParticipantsClearError extends Action {}
@typeName(`CLEAR_WARNING${SUFFIX}`) export class EventRegisterParticipantsClearWarning extends Action {}


export type Actions = typeof actionCreators;

const formCreator = (rootState: ApplicationState) => {
  const options = rootState.cacheZero.options;
  const eventTypeID = rootState.cacheTwoEvents.eventTypeID;
  const eventID = rootState.cacheThreeEvents.eventID;
  const c4 = rootState.cacheFourEventsParticipants;

  let debugPayload: TentarooDebugPayload | undefined;
  if (!options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when performing event participants recalc");
  }
  if (!options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when performing event participants recalc");
  }
  if (!c4.EventRegistrationPerson) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("c4.EventRegistrationPerson not available when performing event participants recalc");
  }
  if (!c4.EventRegistrationPaymentStatus) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("c4.EventRegistrationPaymentStatus not available when performing event participants recalc");
  }
  const selectedTypeID = rootState.events.event.register.participant.type.selectedTypeID;
  const classRegistrations: Array<PostEventRegistrationClass> = composeClassRegistrations(c4.EventClassesIndividualRegistered);
  const route = rootState.routing.locationBeforeTransitions;
  const sp = route.pathname.split('/');
  let HasMultiWeekDiscount = c4.EventRegistrationPerson?.HasMultiWeekDiscount;
  if (sp.length === 8) { // then we're adding
    const pType = !rootState.cacheFourEventsParticipants.EventRegistrationParticipantTypes ? null : rootState.cacheFourEventsParticipants.EventRegistrationParticipantTypes.find(pt => pt.ID === selectedTypeID);
    if (!pType) {
      debugPayload = captureTentarooErrorAndGetRequestPayload('pType not found when saving participant');
    } else {
      if (pType.HasMultiWeekDiscount !== undefined) {
        HasMultiWeekDiscount = pType.HasMultiWeekDiscount;
      }
    }
  }

  if (c4.isYouth === undefined) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheFourEventsParticipants.isYouth is undefined when performing eventParticipantsRecalc");
  }
  const payload = eventParticipantsRecalcBody(
    {
      GroupIDi: options?.Group?.IDi as any,
      GroupTS: options?.Group?.TS as any,
      EventTypeID: eventTypeID,
      EventIDi: eventID,
      GroupWeekIDi: c4.EventRegistrationPaymentStatus?.IDi as any,
      PersonIDi: c4.GroupRosterPerson ? c4.GroupRosterPerson.IDi : null,
      IsYouth: c4.isYouth as any,
      ClassRegistrations: classRegistrations,
      ParticipantTypeID: selectedTypeID,
      EventRegistrationPerson: c4.EventRegistrationPerson as any,
      ParticipantRegistrationIDi: c4.EventRegistrationPerson?.IDi ? c4.EventRegistrationPerson?.IDi : null,
      HasMultiWeekDiscount: HasMultiWeekDiscount as any,
      ParticipantTypes: !rootState.cacheThreeEvents.EventInfoParticipantTypes ? [] : rootState.cacheThreeEvents.EventInfoParticipantTypes.map((pType) => pType.ID),
    }
  );

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.events.event.register.participant.classes),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  classesSubmit: (page: ParticipantTab, isNext: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());

    const rootState = getState();
    const c2 = rootState.cacheTwoEvents;
    const c4 = rootState.cacheFourEventsParticipants;
    const {ActiveForm} = rootState.events.event.register.participant.roster;
    const isAdmin = !!rootState.user.user.str_permissions.hasAdminAccess;
    const availableClasses = makeAvailableClassesFilter();

    if (!c2.EventTypeRegistrationSettings) {
      captureTentarooError(new Error("c2.EventTypeRegistrationSettings not available when submitting classes"));
      return;
    }

    if (c4.isYouth == undefined) {
      captureTentarooError(new Error("c4.isYouth not available when submitting classes"));
      return;
    }
    const classValidation = checkClasses(
      c4.EventClassesIndividualRegistered,
      c4.EventClassesIndividualAvailable,
      availableClasses(rootState),
      c4.isYouth,
      false,
      c2.EventTypeRegistrationSettings.NamesRegistrationSettings.EnableClassWaitingList,
      c4.isYouth ? 1 : 0,
      c4.isYouth ? 0 : 1,
      isAdmin,
      ActiveForm.DOB
    );
    if (classValidation.error) {
      dispatch(new EventRegisterParticipantsShowError(classValidation.warnings[0].message, classValidation.error));
      // if you have an error, and you're going next, then block it
      if (isNext) return;
    }

    if (classValidation.warnings.length > 0) {
      dispatch(new EventRegisterParticipantsShowError(classValidation.warnings[0].message, classValidation.error));
    }
    if (rootState.events.event.register.participant.classes.isDirty) {
      dispatch(new EventRegisterParticipantSetPendingPage(page));
      dispatch(ApiSubmitActionsParticipantsClasses.request(formCreator(getState()), null));
    } else {
      dispatch(new EventRegisterParticipantSelectPage(page));
    }
  },
  addClass: (clazz: BaseEndUserClass): ActionCreator => dispatch => dispatch(new EventRegisterParticipantsClassesAdd(clazz)),
  removeClass: (clazz: RegisteredClass): ActionCreator => dispatch => dispatch(new EventRegisterParticipantsClassesRemove(clazz)),
  waitlistClass: (clazz: BaseEndUserClass): ActionCreator => dispatch => dispatch(new EventRegisterParticipantsClassesWaitlist(clazz)),
  showError: (error: string, isError: boolean): ActionCreator => dispatch => dispatch(new EventRegisterParticipantsShowError(error, isError)),
  clearWarning: (): ActionCreator => dispatch => dispatch(new EventRegisterParticipantsClearWarning()),
};

