import { ActionCreator, ApplicationState } from '../../../';
import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, createApiSubmitFormMethod
} from '../../../Validation/actionCreator';
import { TripActiveForm } from './index';
import {getUpdateTripBody, getDeleteTripBody, URLS} from "../../../../constants/urls";
import {SaveState} from "../../../Rollback/actions";
import { makeSelectedLocationSelector } from '../../../CacheTwoFacilities';
import { navPush, spaceTo_ } from '../../../../utils';
import { typeName, Action } from '../../../../utils/StrongActions';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../utils/dataHelper';

export const SUFFIX = '__TRIP_FORM';
export const DEL_SUFFIX = '__DELETE_TRIP';

@typeName('RESET_FORM' + SUFFIX)
export class TripResetForm extends Action {}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const AddTripSubmitActions = createApiSubmitActions(SUFFIX);
export const DeleteTripSubmitActions = createApiSubmitActions(DEL_SUFFIX);

@typeName('INITIALIZE' + SUFFIX)
export class TripInitialize extends Action {}

export type Actions = typeof actionCreators;
const formCreator = (rootState: ApplicationState, activeForm: TripActiveForm) => {
  let debugPayload: TentarooDebugPayload | undefined;
  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when submitting Trip Form");
  }
  if (!rootState.cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when submitting Trip Form");
  }
  const GroupIDi = rootState.cacheZero.options?.Group?.IDi as any;
  const GroupTS = rootState.cacheZero.options?.Group?.TS as any;
  const FacilityLocationID = rootState.cacheTwoFacilities.locationID;
  const payload = getUpdateTripBody({
    GroupIDi,
    GroupTS,
    FacilityLocationID
  }, activeForm);

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

const editFormCreator = (rootState: ApplicationState, activeForm: TripActiveForm) => {
  let debugPayload: TentarooDebugPayload | undefined;
  if (!rootState.cacheThreeFacilities.FacilityTrip) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheThreeFacilities.FacilityTrip not available when submitting EditTrip Form");
  }
  if (!rootState.cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when submitting EditTrip Form");
  }
  if (!rootState.cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when submitting EditTrip Form");
  }
  const GroupIDi = rootState.cacheZero.options?.Group?.IDi as any;
  const GroupTS = rootState.cacheZero.options?.Group?.TS as any;
  const FacilityLocationID = rootState.cacheTwoFacilities.locationID;
  const FacilityTripID = rootState.cacheThreeFacilities.tripID;
  const payload = getUpdateTripBody(
    {
      GroupIDi,
      GroupTS,
      FacilityLocationID,
      FacilityTripID
    },
    activeForm,
    rootState.cacheThreeFacilities.FacilityTrip?.TS,
  );

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  initTripForm: (): ActionCreator => dispatch => dispatch(new TripInitialize()),
  apiSubmitForm: createApiSubmitFormMethod(AddTripSubmitActions, (s) => s.facilities.trip.form, formCreator),
  apiEditSubmitForm: createApiSubmitFormMethod(AddTripSubmitActions, (s) => s.facilities.trip.form, editFormCreator),
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.facilities.trip.form),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  resetForm: (): ActionCreator => dispatch => dispatch(new TripResetForm()),
  apiDeleteTrip: (router: any): ActionCreator => (dispatch, getState) => {
    const rootState = getState();

    let debugPayload: TentarooDebugPayload | undefined;
    if (!rootState.cacheThreeFacilities.FacilityTrip) {
      debugPayload = captureTentarooErrorAndGetRequestPayload("cacheThreeFacilities.FacilityTrip not available when submitting Delete Trip");
    }
    if (!rootState.cacheZero.options) {
      debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when submitting Delete Trip");
    }
    if (!rootState.cacheZero.options?.Group) {
      debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when submitting Delete Trip");
    }
    const GroupIDi = rootState.cacheZero.options?.Group?.IDi as any;
    const GroupTS = rootState.cacheZero.options?.Group?.TS as any;
    const FacilityLocationID = rootState.cacheTwoFacilities.locationID;
    const FacilityTripID = rootState.cacheThreeFacilities.tripID;
    const TS = rootState.cacheThreeFacilities.FacilityTrip?.TS;
    dispatch(new SaveState());

    const locId = getState().cacheTwoFacilities.locationID;
    const selectedLocation = makeSelectedLocationSelector();


    const payload = getDeleteTripBody(
      {
        GroupIDi,
        GroupTS,
        FacilityLocationID,
        FacilityTripID
      },
      TS as any,
    );

    let body;
    if (debugPayload) {
      body = withTentarooDebugPayload(payload, debugPayload);
    } else {
      body = payload;
    }

    const url = `${URLS.FACILITIES}/${locId}/${spaceTo_(selectedLocation(getState()).Name)}`;

    navPush(router, url);
    dispatch(DeleteTripSubmitActions.request(body, null));
  }
};
