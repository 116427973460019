import * as React from 'react';
import { ContextMenu, ContextMenuItem } from '../../../../../../Elements';
import { Props as ContextMenuProps } from '../../../../../../Elements/ContextMenu/index';

interface Props {
  onDelete: (e) => void;
  onEdit: (e) => void;
}

// @todo: this to should be onClick to open modal instead
const ContextMenuComponent: React.SFC<ContextMenuProps & Props> = (props: ContextMenuProps & Props): React.ReactElement<ContextMenuProps & Props> => (
  <ContextMenu
    consistentInMobile
    {...props}
    title=""
  >
    <ContextMenuItem admin onClick={props.onEdit}>Edit</ContextMenuItem>
    <ContextMenuItem admin onClick={props.onDelete}>Delete</ContextMenuItem>
  </ContextMenu>
);

export default ContextMenuComponent;
