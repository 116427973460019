import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import {AdminEventsCacheTwoEventContext} from "@tentaroo/shared";

import { ApplicationState } from '../../../../../../store';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as dashboardActionCreators, GenerateAdminEventsEventReportsActions } from '../../../../../../store/AdminEvents/Events/Event/Dashboard/actions';
import { LoadingAll, Button, Row, Column, RadioGroupOption, Text, Alert, Card, BulletList, BulletListItem, FieldSet, Select, PageLoader, Ellipsis } from '../../../../../Elements';
import { Main, MainContent } from '../../../../../Layouts';
import '../../../../../../styles/pages/admin-events/events/dashboard/index.scss';
import EventList from '../../Home/EventList';
import { AdminEventReportType } from '../../../../../../store/AdminEvents/CacheTwoEvent';
import { actionCreators as cacheTwoEventActionCreators, GetAdminEvent } from '../../../../../../store/AdminEvents/CacheTwoEvent/actions';
import { makeSelectedRegistrationMethodSelector } from '../../../../../../store/AdminEvents/EventTypes/EventType/Form';
import { AdminEventRegistrationStatus } from '../../../../../../models/api/adminEventsCacheTwoEvent';
import { GLAccountIcon, PeopleContactIcon, ClassTypesIcon, PageIcon, EmailIcon, ExternalLinkIcon, AttentionAlert2 } from '../../../../../Icons';
import { makeSelectedEventReportSelector } from '../../../../../../store/AdminEvents/Events/Event/Dashboard';
import { navPush, slug } from '../../../../../../utils';
import { getAdminEventsMessageCenterHomeRootUrl, generateEventReport_Get } from '../../../../../../constants/adminEventsUrls';
import { getDomain } from '../../../../../../utils/urlHelper';
import { isJustNames, isUsingNumbers } from '../../../../../../store/AdminEvents/EventTypes/EventType/Form/uiHelpers';
import NotFound from '../../../../NotFound';
import { INVALID_EVENT } from '../../../../../../constants/messages/adminEvents';
import { UpdateEventSubmitActions } from '../../../../../../store/AdminEvents/Events/Event/Form/actions';
import { checkAdminEventGenerateInvoicesPermission, checkAdminEventRecalculatePricingPermission, checkAdminEventReportsPermission, checkEnterClassRequirementsPermission, IAdminEventRouterParams } from '../../../../../../utils/helpers/adminEventsPageHelper';
import { ModalTypes, noOpenedModals } from '../../../../../../utils/modalHelper';
import { isPathUnderAdminEvent } from '../../../../../../utils/eventsHelper';
import AdminEventsCacheManager from '../../../../../../utils/cacheManagers/adminEventsCacheManager';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { shouldReconfigRouter } from '../../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import {isAdminEventsCacheOnePopulated, isAdminEventsCacheTwoEventPopulated} from '../../../../../../utils/cachePopulatedCheckers/adminEvents';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--event-dashboard';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminEventRouterParams, {}>
>;

class AdminEventDashboard extends ComponentUpdateTemplate<ConnectedProps, {}> {
  public props: ConnectedProps;
  private nextLocation;
  private routeParamsBeforeUnmount;

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);
    this.configRouter();

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        // `handleOptimisticSaveRedirection` will get called first and prevent downstream
        // loaders from running if optimistic
        AdminEventsCacheManager.getInstance().loadCacheTwoEvent({
          props: this.props,
          isEdit: true,
          context: AdminEventsCacheTwoEventContext.DASHBOARD,
          isStateNavigated,
        });
      }
    );
  }

  configRouter() {
    const {routes, router} = this.props;
    const route = routes[routes.length - 1];
    router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    const {routes, params} = this.props;

    if (shouldReconfigRouter(prevProps, this.props)) {
      this.configRouter();
    }
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
    this.routeParamsBeforeUnmount = this.props.params;
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentWillUnmount() {
    const {actions, adminEventsCacheTwoEvent} = this.props;

    // if we are not navigating to any pages under cache two event, clear cache
    if (
      this.nextLocation &&
      !isPathUnderAdminEvent(this.nextLocation.pathname) &&
      isAdminEventsCacheTwoEventPopulated(adminEventsCacheTwoEvent) &&
      Number(this.routeParamsBeforeUnmount.eventId) > 0
    ) {
      actions.clearAdminEventsCacheTwoEvent();
    }

    this.resetRouteLeaveHook();
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        AdminEventsCacheManager.getInstance().loadCacheTwoEvent({
          props: nextProps,
          isEdit: true,
          context: AdminEventsCacheTwoEventContext.DASHBOARD,
          isStateNavigated,
        });

        if (isStateNavigated) {
          window.scrollTo(0, 0);
        }
      }
    );
  }

  renderOverviewCard = (label: string, mainText: string, detailTexts?: string[]) => {
    return (
      <Card className={`${namespace()}--overview--card`}>
        <div className={`${namespace()}--overview--card--label`}>{label}</div>
        <div className={`${namespace()}--overview--card--main-text`}>{mainText}</div>
        {detailTexts ? <div className={`${namespace()}--overview--card--detail`}>
          {detailTexts.map((t) => {
            return (
              <div className={`${namespace()}--overview--card--detail--text`}>{t}</div>
            );
          })}
        </div> : null}
      </Card>
    );
  };

  renderOverview = () => {
    const {adminEventsCacheTwoEvent} = this.props;

    if (!adminEventsCacheTwoEvent.EventsEvent) return null;

    const overview = adminEventsCacheTwoEvent.EventsEvent.Overview;

    const NamesFinalized = overview.YouthNamesFinalized + overview.AdultsNamesFinalized;
    const NamesInCart = overview.AdultsNamesInCart + overview.YouthNamesInCart;
    const NumbersFinalized = overview.YouthNumbersFinalized + overview.AdultsNumbersFinalized;

    return (
      <section className={`${namespace()}--overview`}>
        {this.renderOverviewCard('Groups', `${overview.NumGroups}`)}
        {this.renderOverviewCard(
          isJustNames() ? 'Finalized' : 'Numbers',
          `${isJustNames() ? NamesFinalized : NumbersFinalized}`,
          [
            `${isJustNames() ? overview.YouthNamesFinalized : overview.YouthNumbersFinalized} Youth`,
            `${isJustNames() ? overview.AdultsNamesFinalized : overview.AdultsNumbersFinalized} Adult${overview.AdultsNumbersFinalized !== 1 ? 's' : ''}`,
          ]
        )}
        {this.renderOverviewCard(
          isJustNames() ? 'In-Cart' : 'Names',
          `${isJustNames() ? NamesInCart : NamesFinalized}`,
          [
            `${isJustNames() ? overview.YouthNamesInCart : overview.YouthNamesFinalized} Youth`,
            `${isJustNames() ? overview.AdultsNamesInCart : overview.AdultsNamesFinalized} Adult${overview.AdultsNamesFinalized !== 1 ? 's' : ''}`,
          ]
        )}
        {this.renderOverviewCard('Total', `$${overview.TotalFees.toFixed(2)}`, [`$${overview.TotalAmountPaid.toFixed(2)} Paid`])}
      </section>
    );
  };

  renderRegistrationStatus(r: AdminEventRegistrationStatus, i) {
    if (r.Description && r.Status) {
      return (
        <BulletListItem className={`${namespace()}--description--registration-statuses--item`} size={16} key={r.ID}>
          <Text size={16} inline>{r.Status}</Text><br/>
          <span className='desc'>{r.Description}</span>
        </BulletListItem>
      );
    } else if (r.Status) {
      return <BulletListItem className={`${namespace()}--description--registration-statuses--item`} size={16} key={r.ID}>{r.Status}</BulletListItem>;
    }
    return null;
  }

  renderDescription = () => {
    const {adminEventsCacheOne, adminEventsCacheTwoEvent} = this.props;

    const RegistrationStatuses = adminEventsCacheTwoEvent.EventsEvent ? adminEventsCacheTwoEvent.EventsEvent.RegistrationStatuses : null;
    return (
      <Card
        className={`${namespace()}--description`}
      >
        {adminEventsCacheOne.EventsEventType && adminEventsCacheOne.EventsEventType.General.Description ? <div className={`${namespace()}--description--label`}>Event Description</div> : null}
        {adminEventsCacheOne.EventsEventType && adminEventsCacheOne.EventsEventType.General.Description ? <div className={`${namespace()}--description--text`}>
          <Ellipsis
            configs={{
              lines: 3,
              text: adminEventsCacheOne.EventsEventType ? adminEventsCacheOne.EventsEventType.General.Description : '',
            }}
          />
        </div> : null}
        {!!RegistrationStatuses && RegistrationStatuses.length > 0 && <div className={`${namespace()}--description--registration-statuses${!adminEventsCacheOne.EventsEventType || !adminEventsCacheOne.EventsEventType.General.Description ? ' no-desc' : ''}`}>
          <BulletList marginBottom={0}>
            {RegistrationStatuses.map(this.renderRegistrationStatus)}
          </BulletList>
        </div>}
      </Card>
    );
  };

  renderToolButton = (icon: any, label: string, isOrange: boolean, onClick: any, iconAfter?: any) => {
    return (
      <div className={`${namespace()}--tools--btn--wrapper ${label === 'Message Center' ? 'message-center' : ''}`}>
        <Button
          id={generateDOMId(slug(label))}
          onClick={onClick}
          color='white' flat textColor='black'
          className={`${namespace()}--tools--btn ${isOrange ? 'orange' : ''}`}
          icon={icon}
        >
          <div className={`${namespace()}--tools--btn--label`}>{label}</div>
          {iconAfter}
        </Button>
      </div>
    );
  };

  shouldHideRecalculatePricing = () => {
    const { adminEventsCacheOne } = this.props;

    return !isUsingNumbers() || (adminEventsCacheOne.EventsEventType && adminEventsCacheOne.EventsEventType.Pricing4.LockInPricingBasedOnID !== 2);
  };

  onRecalculatePricing = () => {
    const {actions, adminEventsCacheOne} = this.props;
    
    checkAdminEventRecalculatePricingPermission(
      () => {
        actions.pushModal(ModalTypes.RECALCULATE_PRICING, false, true);
      },
      adminEventsCacheOne,
    );
  };
  onEnterClassRequirementsCompleted = () => {
    const {actions, adminEventsCacheTwoEvent} = this.props;
    checkEnterClassRequirementsPermission(
      () => {
        actions.pushModal(ModalTypes.ENTER_CLASS_REQUIREMENT_COMPLETED, false, false);
      },
      adminEventsCacheTwoEvent,
    );
  };
  onGenerateInvoices = () => {
    const {actions, adminEventsCacheOne} = this.props;

    checkAdminEventGenerateInvoicesPermission(
      () => {
        actions.pushModal(ModalTypes.GENERATE_INVOICES, false, false);
      },
      adminEventsCacheOne,
    );
  };
  onMessageCenter = () => {
    const {adminEventsCacheOne} = this.props;

    if (!adminEventsCacheOne.EventsEventType) return;
    navPush(this.props.router, getAdminEventsMessageCenterHomeRootUrl({
      eventTypeId: adminEventsCacheOne.EventsEventType.EventTypeRow.ID,
      eventTypeName: adminEventsCacheOne.EventsEventType.General.Name,
    }));
  };
  onExportInstructorClassRoster = () => {
    const {actions, adminEventsCacheOne} = this.props;

    checkAdminEventReportsPermission(
      () => {
        actions.pushExportInstructorRosterModal({eventToolsModalContext: 'dashboard'});
      },
      adminEventsCacheOne,
    );
  };

  renderTools = () => {
    const recalculatePricing = {icon: <GLAccountIcon width={24} height={24} />, label: 'Recalculate Pricing', orange: true, onClick: this.onRecalculatePricing};
    const enterClassRequirementsCompleted = {icon: <ClassTypesIcon width={24} height={24} />, label: 'Enter Class Requirements Completed', onClick: this.onEnterClassRequirementsCompleted};
    const generateInvoices = {icon: <PageIcon width={24} height={24} />, label: 'Generate Invoices', onClick: this.onGenerateInvoices };
    const messageCenter = {icon: <EmailIcon width={24} height={24} />, label: 'Message Center', onClick: this.onMessageCenter, iconAfter: <ExternalLinkIcon className='icon-after' />};
    const exportInstructorClassRoster = {icon: <PeopleContactIcon width={24} height={24} />, label: 'Export Instructor Class Roster', onClick: this.onExportInstructorClassRoster};

    const col1: any[] = [
      generateInvoices,
      messageCenter,
    ];
    const col2: any[] = [
      enterClassRequirementsCompleted,
      exportInstructorClassRoster,
    ];
    if (!this.shouldHideRecalculatePricing()) {
      col1.unshift(recalculatePricing);
    }
    return (
      <Card className={`${namespace()}--tools`}>
        <FieldSet name="Tools" newDesign fontSize={22} marginBottom={0} legendMarginBottom={16}>
          {!this.shouldHideRecalculatePricing() && <Alert>Please remember to Recalculate Pricing every time pricing goes up for each event that locks in pricing when paid in full.</Alert>}
          <div className={`${namespace()}--tools--btns`}>
            <div className={`${namespace()}--tools--btns--col`}>
              {col1.map((t) => this.renderToolButton(t.icon, t.label, !!t.orange, t.onClick, t.iconAfter))}
            </div>
            <div className={`${namespace()}--tools--btns--col`}>
              {col2.map((t) => this.renderToolButton(t.icon, t.label, !!t.orange, t.onClick, t.iconAfter))}
            </div>
          </div>
        </FieldSet>
      </Card>
    );
  };

  exportClassSchedule = (JustYouth: number) => {
    const {adminEventsCacheTwoEvent} = this.props;
    if (!adminEventsCacheTwoEvent.EventsEvent) return;
    const domain = getDomain(false);

    const url = `${domain}/ClassSchedule.cfm?WeekID=${adminEventsCacheTwoEvent.EventsEvent.IDString}&JustYouth=${JustYouth}`;

    window.open(url);
  };

  onGenerateReport = () => {
    const {dashboardState: {ActiveForm}, adminEventsCacheOne, adminEventsCacheTwoEvent, selectedReport, actions} = this.props;

    checkAdminEventReportsPermission(
      () => {
        if (!ActiveForm.SelectedReportID || !selectedReport) {
          actions.showTopFloatingAlert('Please select a report first.', undefined, 'orange');
          return;
        }
        switch(selectedReport.ID) {
          case AdminEventReportType.EXPORT_CLASS_SCHEDULE_YOUTH:
            this.exportClassSchedule(1);
            break;
          case AdminEventReportType.EXPORT_CLASS_SCHEDULE_ADULTS:
            this.exportClassSchedule(0);
            break;
          case AdminEventReportType.EXPORT_CLASS_SCHEDULE_GROUP:
            this.exportClassSchedule(-1);
            break;
          case AdminEventReportType.INSTRUCTOR_CLASS_ROSTER_PDF:
            this.props.actions.pushExportInstructorRosterModal({reportURL: selectedReport.ReportURL, eventToolsModalContext: 'reports'});
            break;
          case AdminEventReportType.PARTICIPANT_CLASS_SCHEDULES_BY_PARTICIPANT_CSV:
            this.props.actions.pushModal(ModalTypes.EXPORT_PARTICIPANT_CLASS_SCHEDULE, false, false, {reportURL: selectedReport.ReportURL});
            break;
          case AdminEventReportType.EXPORT_SCOUTBOOK_REQUIREMENTS:
            this.props.actions.generateScoutbookRequirements(selectedReport.ReportURL);
            break;
          case AdminEventReportType.EXPORT_BLUE_CARDS:
            this.props.actions.generateBlueCardReports(selectedReport.ReportURL);
            break;
          default:
            if (!adminEventsCacheOne.EventsEventType || !adminEventsCacheTwoEvent.EventsEvent) return;

            if (selectedReport.IsJSONResponse) {
              actions.generateEventReports(
                selectedReport.ReportURL,
                adminEventsCacheOne.EventsEventType.EventTypeRow.ID as number,
                adminEventsCacheTwoEvent.EventsEvent.IDi,
              );
            } else {
              const url = generateEventReport_Get({
                path: selectedReport.ReportURL,
                EventTypeID: adminEventsCacheOne.EventsEventType.EventTypeRow.ID as number,
                EventIDi: adminEventsCacheTwoEvent.EventsEvent.IDi,
              });
              window.open(url);
            }
        }
      },
      adminEventsCacheOne,
    );
  };

  renderBlueCardsOptions = () => {
    const {apiSaving, dashboardState: {ActiveForm, ValidationRules}, actions} = this.props;
    return (
      <Row styles={{alignItems: 'center', paddingLeft: '36px'}} marginBottom={8} mobileMarginBottom={16} marginTop={16}>
        <Column mobileMarginBottom={8} span={6} mobileSpan={12}>
          <Select
            label='Group'
            disabled={apiSaving > 0}
            onChangeValue={actions.updateValue}
            value={ActiveForm.GroupIDi}
            validationRules={ValidationRules.GroupIDi}
            isNumber
          />
        </Column>
        <Column span={3} mobileSpan={6}>
          <RadioGroupOption
            value={!!ActiveForm.IsFront}
            selected={!!ActiveForm.IsFront}
            disabled={apiSaving > 0}
            ValidationRules={ValidationRules.IsFront}
            rawValue={true}
            onChange={actions.updateValue}
            newDesign
            label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>Front</Text>}
          />
        </Column>
        <Column span={3} mobileSpan={6}>
          <RadioGroupOption
            value={!!!ActiveForm.IsFront}
            selected={!!!ActiveForm.IsFront}
            disabled={apiSaving > 0}
            ValidationRules={ValidationRules.IsFront}
            rawValue={false}
            onChange={actions.updateValue}
            newDesign
            label={<Text wrap={false} color='dark-gray' weight="regular" size={16} marginBottom={0}>Back</Text>}
          />
        </Column>
      </Row>
    );
  };

  renderScoutbookRequirementsOptions = () => {
    const {apiSaving, dashboardState: {ActiveForm, ValidationRules}, actions} = this.props;
    return (
      <Row styles={{alignItems: 'center', paddingLeft: '36px'}} marginBottom={8} mobileMarginBottom={16} marginTop={16}>
        <Column mobileMarginBottom={8} span={6} mobileSpan={12}>
          <Select
            label='Group'
            disabled={apiSaving > 0}
            onChangeValue={actions.updateValue}
            value={ActiveForm.GroupIDi_ScoutbookRequirements}
            validationRules={ValidationRules.GroupIDi_ScoutbookRequirements}
            isNumber
          />
        </Column>
      </Row>
    );
  };

  renderReports = () => {
    const {adminEventsCacheOne, dashboardState: {ActiveForm, ValidationRules}, actions} = this.props;

    const Reports = adminEventsCacheOne.EventReportsOptions;

    if (!Reports) return null;
    return (
      <Card className={`${namespace()}--reports`}>
        <FieldSet name="Reports" newDesign fontSize={22} marginBottom={0} legendMarginBottom={16}>
          <Alert>For more reports, go back to the Event Type and go to the Reports tab</Alert>
          {Reports.map((r) => {
            return (
              <div>
                <Row>
                  <Column span={12}>
                    <RadioGroupOption
                      id={generateDOMId(`report-${r.ID}`)}
                      value={ActiveForm.SelectedReportID === r.ID}
                      selected={ActiveForm.SelectedReportID === r.ID}
                      ValidationRules={ValidationRules.SelectedReportID}
                      rawValue={r.ID}
                      onChange={actions.updateValue}
                      newDesign
                      label={<Text wrap={true} color='dark-gray' weight="regular" size={16} marginBottom={0}>{r.Name}</Text>}
                      helperText={r.Description}
                    />
                  </Column>
                </Row>
                {ActiveForm.SelectedReportID === AdminEventReportType.EXPORT_BLUE_CARDS && r.ID === AdminEventReportType.EXPORT_BLUE_CARDS ? this.renderBlueCardsOptions() : null }
                {ActiveForm.SelectedReportID === AdminEventReportType.EXPORT_SCOUTBOOK_REQUIREMENTS && r.ID === AdminEventReportType.EXPORT_SCOUTBOOK_REQUIREMENTS ? this.renderScoutbookRequirementsOptions() : null }
              </div>
            );
          })}
          <Button id={generateDOMId("generate-report-btn")} marginTop={24} onClick={this.onGenerateReport} color='white' textColor='black'>Generate</Button>
        </FieldSet>
      </Card>
    );
  };

  onExportCampsites = () => {
    const {actions, adminEventsCacheOne, adminEventsCacheTwoEvent} = this.props;
    checkAdminEventReportsPermission(
      () => {
        if (!adminEventsCacheOne.EventsEventType || !adminEventsCacheTwoEvent.EventsEvent) return;
        actions.generateEventReports(
          'ExportCampsites',
          adminEventsCacheOne.EventsEventType.EventTypeRow.ID as number,
          adminEventsCacheTwoEvent.EventsEvent.IDi,
        );
      },
      adminEventsCacheOne,
    );
  };

  renderCampsiteDetail = (label: string, text: string, danger?: boolean) => {
    return (
      <div className={`${namespace()}--campsites--card--detail--card`}>
        <div className={`${namespace()}--campsites--card--detail--card--title ${danger ? 'danger' : ''}`}>{label}{danger ? <AttentionAlert2 /> : null}</div>
        <div className={`${namespace()}--campsites--card--detail--card--text ${danger ? 'danger' : ''}`}>{text}</div>
      </div>
    );
  };

  renderCampsites = () => {
    const {adminEventsCacheTwoEvent} = this.props;

    if (!isUsingNumbers()) return null;
    const EventsEventCampsites = adminEventsCacheTwoEvent.EventsEventCampsites;
    return (
      <FieldSet
        name='Campsites'
        fontSize={28}
        marginBottom={0}
        legendMarginBottom={24}
        controls={<Button onClick={this.onExportCampsites} color='white' textColor='black'>EXPORT (EXCEL/CSV)</Button>}
        className={`${namespace()}--campsites`}>
          {EventsEventCampsites && EventsEventCampsites.map((campsite) => {
            return (
              <Card
                className={`${namespace()}--campsites--card`}
                key={campsite.IDi}
              >
                <div className={`${namespace()}--campsites--card--title`}>{campsite.Name}</div>
                <div className={`${namespace()}--campsites--card--detail`}>
                  {this.renderCampsiteDetail('Occupancy', `${campsite.NumYouth + campsite.NumAdults}${campsite.Capacity ? ' / ' + campsite.Capacity : ''}`, campsite.IsOverbooked)}
                  {this.renderCampsiteDetail('Groups', `${campsite.NumGroups}`)}
                  {this.renderCampsiteDetail('Youth', `${campsite.NumYouth}`)}
                  {this.renderCampsiteDetail('Adults', `${campsite.NumAdults}`)}
                </div>
              </Card>
            );
          })}
      </FieldSet>
    );
  };

  public render() {
    const {adminEventsCacheTwoEvent, apiSavingMap, apiLoadingMap, apiSaving, inert } = this.props;
    if (!isAdminEventsCacheOnePopulated()) {
      return <LoadingAll />;
    }

    const loading = (
      !isAdminEventsCacheTwoEventPopulated(adminEventsCacheTwoEvent) 
      ||
      apiLoadingMap[GetAdminEvent.requestType]
    ) && noOpenedModals();
    const showOverlayLoader = (
      apiLoadingMap[GenerateAdminEventsEventReportsActions.requestType]
    );
    const saving = apiSavingMap[UpdateEventSubmitActions.requestType] && noOpenedModals();
    const inactive = adminEventsCacheTwoEvent.EventsEvent && !loading && adminEventsCacheTwoEvent.EventsEvent.Inactive;

    return (
      <Main inert={inert} className={`${namespace()}--main`} isAdminEvents>
        <MainContent
          wide
          className={namespace()}
          leftContent={
            <div className={`${namespace()}--sidebar`}>
              <EventList forceMobile showFilters />
            </div>
          }
          header={<div />}
          rightGutter
        >
          {!loading && !saving && !inactive && adminEventsCacheTwoEvent.EventsEvent && adminEventsCacheTwoEvent.EventsEvent.Overview && <div className={`${namespace()}--wrapper`}>
            {this.renderOverview()}
            {this.renderDescription()}
            {this.renderTools()}
            {this.renderReports()}
            {isUsingNumbers() && adminEventsCacheTwoEvent.EventsEventCampsites ? this.renderCampsites() : null}
          </div>}
          {inactive && <NotFound showCMSSiteHeader message={INVALID_EVENT} />}
          {loading && <PageLoader className={`${namespace()}--page-loader loading`}/>}
        </MainContent>
        {(apiSaving > 0 || showOverlayLoader) && <PageLoader className={`${namespace()}--page-loader`}/>}
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedRegistrationMethodSelector = makeSelectedRegistrationMethodSelector(true);
  const selectedReportSelector = makeSelectedEventReportSelector();
  
  const selectedReport = selectedReportSelector(state);
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSavingMap: state.app.apiSavingMap,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsCacheTwoEvent: state.adminEvents.cacheTwoEvent,
    eventForm: state.adminEvents.events.event.form,
    dashboardState: state.adminEvents.events.event.dashboard,
    cacheZero: state.cacheZero,
    selectedRegistrationMethod: selectedRegistrationMethodSelector(state),
    selectedReport,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...cacheTwoEventActionCreators,
    ...dashboardActionCreators,
  }, dispatch),
});

const ConnectedAdminEventDashboard = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(AdminEventDashboard);

export default withRouter<{}>(ConnectedAdminEventDashboard);
