export const SPECIFY_SCOUT_YOUTH = 'Please specify whether or not this youth is a Scout and try again.';
export const SPECIFY_SCOUT_ADULT = 'Please specify whether or not this adult is a Scouter and try again.';
export const SPECIFY_UNIT_YOUTH = "Please specify this youth's unit or change to a Non-Scout.";
export const SPECIFY_UNIT_ADULT = "Please specify this adult's unit.";
export const SPECIFY_UNIT_OR_NON_SCOUT_YOUTH = "Please specify this youth's unit.";
export const SPECIFY_UNIT_OR_NON_SCOUT_ADULT = "Please specify this adult's unit or change to a Non-Scout.";
export const PACK_REQUIRED = "If this youth is a Cub Scout, please change the Unit Type to Pack and try again.";

export const ADD_ROSTER_SUCCESS = "Person has been added.";
export const EDIT_ROSTER_SUCCESS = "Person has been saved.";
export const DELETE_ROSTER_SUCCESS = "Person has been deleted.";
export const RESTORE_ROSTER_SUCCESS = "Person has been restored.";
