import * as React from 'react';
import {Main, MainContent} from '../../../../../../Layouts';
import {Text, Card, Form, Select, Alert} from '../../../../../../Elements';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import {NumbersConnectedProps} from "../index";
import {actionCreators} from '../../../../../../../store/Events/Event/Register/Numbers/Campsite/actions';
import {bindActionCreators} from 'redux';
import {EventRegistrationNumbersC4} from "../../../../../../../models/api/cacheFourEvents";
import { ApplicationState } from '../../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

// there's actually no scss for this page atm
export const namespace = (): string => 'pages--events--event--register--campsite';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = WithInertAttribute<{
  numberProps: NumbersConnectedProps;
}>;

class CampsiteRanking extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;


  renderCampsiteSelection = () => {
    const {campsite: {ActiveForm, ValidationRules, SubmitErrorMessage}, actions} = this.props;

    return (
      <MainContent>
        {SubmitErrorMessage && <Alert noFlex>{SubmitErrorMessage}</Alert>}
        <Card template="mobile-stretch">
          <Text mobileSize={15} mobileMarginBottom={16}>Please select your TOP 3 choices for your campsite.</Text>
          <br/>
          <Text mobileSize={15}>Once campsites are assigned, they will no longer be listed as available.</Text>

          <Form marginTop={20}>
            <Select label="Campsite Choice #1"
                    onChangeValue={actions.updateValue}
                    value={ActiveForm.CampsiteRank1}
                    validationRules={ValidationRules.CampsiteRank1}
                    isNumber
            />
            <Select label="Campsite Choice #2"
                    onChangeValue={actions.updateValue}
                    value={ActiveForm.CampsiteRank2}
                    validationRules={ValidationRules.CampsiteRank2}
                    isNumber
            />
            <Select label="Campsite Choice #3"
                    onChangeValue={actions.updateValue}
                    value={ActiveForm.CampsiteRank3}
                    validationRules={ValidationRules.CampsiteRank3}
                    isNumber
            />
          </Form>
        </Card>
      </MainContent>
    );
  };

  renderNoSelection = (EventRegistrationNumbers: EventRegistrationNumbersC4) => {
    return (<MainContent>
      <Card template="mobile-stretch">
        <Text mobileSize={15} mobileMarginBottom={16}>Your campsite has been assigned.</Text>
        <br/>
        <Text mobileSize={15}>{`Campsite(s): ${EventRegistrationNumbers.Campsites}`}</Text>
      </Card>
    </MainContent>);
  };

  public render() {
    const {campsite: {ActiveForm, ValidationRules, SubmitErrorMessage}, inert, actions} = this.props;
    const {cacheFourEventsNumbers: {EventRegistrationNumbers}, apiSaving } = this.props.numberProps;
    return (
      <Main
        inert={inert}
        key="numbers"
        mobileBackground="white"
        header={<HeaderProgress loading={apiSaving > 0} c2={this.props.numberProps.cacheTwoEvents} c4={this.props.numberProps.cacheFourEventsNumbers} selected="campsite_ranking"/>}
        footer={<FooterProgress selected="campsite_ranking"/>}
      >
        {EventRegistrationNumbers && EventRegistrationNumbers.Campsites ?
          this.renderNoSelection(EventRegistrationNumbers) : this.renderCampsiteSelection()}
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    campsite: state.events.event.register.numbers.campsite
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators
}, dispatch)});
const ConnectedCampsiteRanking = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(CampsiteRanking);

export default ConnectedCampsiteRanking;
