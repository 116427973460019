import * as React from 'react';
import { isIOS } from './isMobile';
import {Nullable} from './dataHelper';

export default function <P>(
  findDOMNode: (instance) => Nullable<Element>, height?: (height: number) => number
): (WrappedComponent: React.ClassType<P, any, any>) => React.ClassType<P, any, any> {
  return (WrappedComponent) => {
    class Component extends React.PureComponent<{}, {}> {
      private isIos: boolean;
      private currentHeight: number;

      public componentDidMount() {
        this.isIos = isIOS;
        this.handleIOSHeight();
        window.addEventListener('scroll', this.handleIOSHeight);
        window.addEventListener('resize', this.handleIOSHeight);
        document.addEventListener('touchstart', this.handleIOSHeight);
        document.addEventListener('touchend', this.handleIOSHeight);
        document.addEventListener('touchmove', this.handleIOSHeight);
      }

      public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleIOSHeight);
        window.removeEventListener('resize', this.handleIOSHeight);
        document.removeEventListener('touchstart', this.handleIOSHeight);
        document.removeEventListener('touchend', this.handleIOSHeight);
        document.removeEventListener('touchmove', this.handleIOSHeight);
      }

      public handleIOSHeight = () => {
        const element = findDOMNode(this) as Nullable<HTMLDivElement>;
        const nextHeight = height ? height(window.innerHeight) : window.innerHeight;
        if (this.currentHeight !== nextHeight) {
          // 64 is height of header
          const headerHeight = window.innerWidth >= 640 ? 64 : 0;

          if (element) element.style.height = `${nextHeight - headerHeight}px`;
          this.currentHeight = nextHeight - headerHeight;
        }
      };

      public render() {
        return (
          <WrappedComponent {...this.props}/>
        );
      }
    }

    return Component as any;
  };
};
