import type {ActionCreator, ApplicationState} from '../index';
import {createDelayQueueActions, CLEAR_CACHE_PREFIX} from '../Validation/actionCreator';
import {AjaxResponse} from "rxjs/ajax";
import {SaveState} from "../Rollback/actions";
import { ClearAllAdminCMSSiteCacheButOptions, ClearAllAdminCMSSiteCache } from '../AdminCMSSite/CacheOne/actions';
import { ClearAllAdminFacilityLocationCache } from '../AdminFacilityLocation/CacheOne/actions';
import { ClearAllAdminEventsCache } from '../AdminEvents/CacheOne/actions';
import { mapModalNamespace, isModalOpened, ModalProps, ModalTypes } from '../../utils/modalHelper';
import { typeName, Action } from '../../utils/StrongActions';
import {cancelLoadAndRollback} from '../../rollback/helpers';

export const showSnackbarItem = 'SHOW_SNACKBAR_ITEM';
export const SILENT_CANCEL_ALL = 'SILENT_CANCEL_ALL';

export const ShowTopFloatingAlertInQueue = createDelayQueueActions('APP_SHOW_TOP_FLOATING_ALERT');

@typeName("SET_IS_ROUTER_CHANGE_FINISHED")
export class SetIsRouterChangeRollbackFinished extends Action {
  constructor(public finished: boolean) { super(); }
}
@typeName("NO_OP") export class NoOp extends Action {}
@typeName("APP_SHOW_DRAWER") export class ShowDrawer extends Action {}
@typeName("APP_HIDE_DRAWER") export class HideDrawer extends Action {}
// @todo - need to update all direct dispatch against `ShowTopFloatingAlert` to use `ShowTopFloatingAlertInQueue`
@typeName("APP_SHOW_TOP_FLOATING_ALERT") export class ShowTopFloatingAlert extends Action {
  constructor(public message: string, public inModal?: boolean, public color?: 'green' | 'orange') { super(); }
}
@typeName("APP_HIDE_TOP_FLOATING_ALERT") export class HideTopFloatingAlert extends Action {}
@typeName("APP_ADD_GUTTER") export class AddGutter extends Action {
  constructor(public height: number, public mobile: boolean = false) { super(); }
}
@typeName('SET_PREVIOUS_LOCATION') export class SetPreviousLocation extends Action {
  constructor(public location: any) { super(); }
}
@typeName("APP_REMOVE_GUTTER") export class RemoveGutter extends Action {}
@typeName("APP_SHOW_ADDED_HEADER_ACTION") export class ShowAddedHeaderAction extends Action {}
@typeName("APP_HIDE_ADDED_HEADER_ACTION") export class HideAddedHeaderAction extends Action {}
@typeName("APP_SHOW_ADDED_SIDE_BAR") export class ShowAddedSideBar extends Action {}
@typeName("APP_HIDE_ADDED_SIDE_BAR") export class HideAddedSideBar extends Action {}
@typeName("APP_TOGGLE_ADDED_SIDE_BAR") export class ToggleAddedSideBar extends Action {}
@typeName("APP_START_PROGRESS") export class StartProgress extends Action {}
@typeName("APP_SET_PROGRESS") export class SetProgress extends Action {
  constructor(public percent: number) { super(); }
}
@typeName("APP_INCREMENT_PROGRESS") export class IncrementProgress extends Action {
  constructor(public percent: number) { super(); }
}
@typeName("APP_FINISH_PROGRESS") export class FinishProgress extends Action {}
@typeName(showSnackbarItem) export class ShowSnackbarItem extends Action {
  constructor(public message: string) { super(); }
}
@typeName("HIDE_SNACKBAR_ITEM") export class HideSnackbarItem extends Action {}

@typeName("STOP_LOADING_ALL_ACTION") export class StopLoadingAllAction extends Action {}
@typeName("LOADING_ALL_ACTION") export class LoadingAllAction extends Action {}
@typeName(SILENT_CANCEL_ALL) export class SilentCancelAllAction extends Action {
  constructor(public isDuringNavigation?: boolean, public skipRestoreState?: boolean) { super(); }
}
@typeName("TOGGLE_NOT_FOUND") export class ToggleNotFound extends Action {}
export const HEADER_FILTER = 'APP_HEADER_FILTER';
@typeName(HEADER_FILTER) export class HeaderFilter extends Action {
  constructor(public filter: string, public actionFnc: (filter: string) => any) { super(); }
}
@typeName('SET_CACHE_ACTION') export class SetCacheAction extends Action {
  constructor(public response: AjaxResponse, public actions: any, public extra?: any) { super(); }
}
@typeName(`${CLEAR_CACHE_PREFIX}_ALL_END_USER_CACHE_BUT_OPTIONS`) export class ClearAllEndUserCacheButOptions extends Action {
  constructor(public preserveGroup?: boolean, public rootState?: ApplicationState, public skipLoadingAll?: boolean) { super(); }
}
@typeName(`${CLEAR_CACHE_PREFIX}_ALL_CACHE`) export class ClearAllCache extends Action {
  constructor(public clearSession?: boolean) { super(); }
}

/**
 * This action should never be called directly.
 * 
 * Instead, use `pushModal` thunk or `pushModalObservables` to dispatch it
 */
@typeName('PUSH_MODAL') export class PushModal extends Action {
  constructor(public modal: ModalTypes, public saveBefore: boolean, public saveAfter: boolean, public modalNamespace?: string, public props?: ModalProps) { super(); }
}
/**
 * This action should never be called directly.
 * 
 * Instead, use `popModal` thunk or `popModalObservables` to dispatch it
 */
@typeName('POP_MODAL') export class PopModal extends Action {
  constructor(public modal: ModalTypes, public saveBefore: boolean, public saveAfter: boolean) { super(); }
}
@typeName('UPDATE_GLOBAL_SEARCH_TEXT') export class UpdateGlobalSearchText extends Action {
  constructor(public str?: string) { super(); }
}
@typeName('TOGGLE_GLOBAL_SEARCH') export class ToggleGlobalSearch extends Action {
  constructor(public show: boolean) { super(); }
}
@typeName('FLASH_LOADED') export class FlashLoaded extends Action {
  constructor(public loaded: boolean) { super(); }
}

@typeName("SHOW_MODAL_ALERT")
export class ShowModalAlert extends Action {
  constructor(public message: string) { super(); }
}

@typeName("SET_EMPTY_CART")
export class SetEmptyCart extends Action {
  constructor(public getEmptyCartUrl: () => string, public getEmptyCartBody: (params: any) => void, public postNavigate?: string) { super(); }
}

@typeName("SHOW_ADMIN_PAGE_HEADER")
export class ShowAdminPageHeader extends Action {
  constructor(public show: boolean) { super(); }
}
@typeName("TOGGLE_MODAL_SAVING")
export class ToggleModalSaving extends Action {
  constructor(public saving?: boolean) { super(); }
}
@typeName("CLOSE_ALL_MODALS")
export class CloseAllModals extends Action { constructor() { super(); } }

@typeName("SET_AFTER_LOGIN_PATH")
export class SetAfterLoginPath extends Action {
  constructor(public path: string | undefined, public account: string | undefined) {
    super();
  }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  showDrawer: (): ActionCreator => dispatch => dispatch(new ShowDrawer()),
  hideDrawer: (): ActionCreator => dispatch => dispatch(new HideDrawer()),
  showTopFloatingAlert: (message: string, inModal?: boolean, color?: 'green' | 'orange'): ActionCreator => dispatch => {
    dispatch(ShowTopFloatingAlertInQueue.request({message, inModal, color}));
  },
  hideTopFloatingAlert: (): ActionCreator => dispatch => dispatch(new HideTopFloatingAlert()),
  addGutter: (height: number, mobile: boolean): ActionCreator => dispatch => dispatch(new AddGutter(height, mobile)),
  removeGutter: (): ActionCreator => dispatch => dispatch(new RemoveGutter()),
  showAddedHeaderAction: (): ActionCreator => dispatch => dispatch(new ShowAddedHeaderAction()),
  hideAddedHeaderAction: (): ActionCreator => dispatch => dispatch(new HideAddedHeaderAction()),
  showAddedSideBar: (): ActionCreator => dispatch => dispatch(new ShowAddedSideBar()),
  hideAddedSideBar: (): ActionCreator => dispatch => dispatch(new HideAddedSideBar()),
  toggleAddedSideBar: (): ActionCreator => dispatch => {
    dispatch(new ToggleAddedSideBar());
    if (window.innerWidth < 640) {
      window.scrollTo(0,0);
    }
  },
  startProgress: (): ActionCreator => dispatch => dispatch(new StartProgress()),
  setProgress: (percent: number): ActionCreator => dispatch => dispatch(new SetProgress(percent)),
  incrementProgress: (percent: number): ActionCreator => dispatch => dispatch(new IncrementProgress(percent)),
  finishProgress: (): ActionCreator => dispatch => dispatch(new FinishProgress()),
  showSnackbarItem: (message: string): ActionCreator => dispatch => {
    dispatch(new ShowSnackbarItem(message));
  },
  clearAllEndUserCacheButOptions: (preserveGroup?: boolean): ActionCreator => (dispatch, getState) => dispatch(new ClearAllEndUserCacheButOptions(preserveGroup, getState())),
  clearAllCMSSiteCache: (): ActionCreator => dispatch => dispatch(new ClearAllAdminCMSSiteCache()),
  clearAllCMSSiteCacheButOptions: (): ActionCreator => dispatch => dispatch(new ClearAllAdminCMSSiteCacheButOptions()),
  clearAllFacilityLocationCache: (): ActionCreator => dispatch => dispatch(new ClearAllAdminFacilityLocationCache()),
  clearAllAdminEventsCache: (): ActionCreator => dispatch => dispatch(new ClearAllAdminEventsCache()),
  headerFilter: (filter: string, actionFnc: (filter: string) => any): ActionCreator => dispatch => dispatch(new HeaderFilter(filter, actionFnc)),
  pushModal: (modal: ModalTypes, saveBefore: boolean, saveAfter: boolean, props?: ModalProps): ActionCreator => dispatch => {
    const blockModalOpen = cancelLoadAndRollback();
    if (blockModalOpen) return;

    if (saveBefore) dispatch(new SaveState());
    dispatch(new PushModal(modal, saveBefore, saveAfter, mapModalNamespace(modal), props));
    // NOTE: `saveAfter` is then handled in `<Modal />` component, dispatch in componentDidMount if set
  },
  pushCancelRegistrationModal: (
    isYouth?: boolean,
    name?: string,
    participantID?: number,
    inCart?: -1 | 0 | 1,
    eventTypeID?: number,
    eventID?: number,
    groupWeekID?: number,
    goToRegistration?: boolean,
    IsNewlyAdding?: boolean,
  ): ActionCreator => (dispatch) => {
    if (!isModalOpened(ModalTypes.CART)) {
      // Not sure if this is needed, doesn't seem we can open this modal while there's
      // an ongoing load. But it doesn't hurt to have it here.
      cancelLoadAndRollback();
    }
    dispatch(actionCreators.pushModal(ModalTypes.CANCEL_REGISTRATION, true, false, {
      isYouth,
      name,
      participantID,
      inCart,
      eventTypeID,
      eventID,
      groupWeekID,
      goToRegistration,
      IsNewlyAdding,
    }) as any);
  },
  pushCartModal: (): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.CART, false, true) as any);
  },
  pushDeleteBlackoutDateModal: (): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.DELETE_BLACKOUT_DATE, false, true) as any);
  },
  pushDuplicatePageModal: (): ActionCreator => dispatch => {
    // NOTE: DuplicatePage modal will do a save state after init
    dispatch(actionCreators.pushModal(ModalTypes.DUPLICATE_PAGE, false, false) as any);
  },
  pushDeleteMenuItemModal: (): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.DELETE_MENU_ITEM, false, true) as any);
  },
  pushAccountsModal: (): ActionCreator => (dispatch, getState) => {
    const cacheZero = getState().cacheZero;
    const hasTabData = getState().adminAccounts.selectedTab !== "groups" || Boolean(cacheZero.options && cacheZero.options.Groups);
    dispatch(actionCreators.pushModal(ModalTypes.ACCOUNTS_MODAL, !hasTabData, false) as any);
  },
  pushResourcesModal: (): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.RESOURCES, false, false) as any);
  },
  pushNewPageModal: (): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.NEW_PAGE, false, true) as any);
  },
  pushSelectPageModal: (): ActionCreator => dispatch => {
    // NOTE: SelectPage modal will do an init and SaveState on didMount
    dispatch(actionCreators.pushModal(ModalTypes.SELECT_PAGE, false, false) as any);
  },
  pushFacilityTypeSharedSettingsModal: (props?: ModalProps): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.FACILITY_TYPE_SHARED_SETTINGS, false, false, props) as any);
  },
  pushEventFormModal: (props?: ModalProps): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.EVENT_FORM, false, false, props) as any);
  },
  pushSelectLocationModal: (props?: ModalProps): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.SELECT_LOCATION, false, true, props) as any);
  },
  pushSelectGLAccountModal: (props?: ModalProps): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.SELECT_GL_ACCOUNTS, false, true, props) as any);
  },
  pushFormModal: (modal: ModalTypes, isEdit: boolean, props?: ModalProps): ActionCreator => dispatch => {
    if (isEdit) {
      dispatch(actionCreators.pushModal(modal, true, false, props) as any);
    } else {
      dispatch(actionCreators.pushModal(modal, false, false, props) as any);
    }
  },
  pushImportFromFacilityModal: (props?: ModalProps): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.IMPORT_FROM_FACILITY, false, false, props) as any);
  },
  pushNewFacilityModal: (): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.NEW_FACILITY, false, false) as any);
  },

  pushImportFromEventModal: (props?: ModalProps): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.IMPORT_FROM_EVENT, false, false, props) as any);
  },
  pushMultipleFacilitiesModal: (): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.MULTIPLE_FACILITIES, false, false) as any);
  },
  pushExportInstructorRosterModal: (props?: ModalProps): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.EXPORT_INSTRUCTOR_ROSTER, false, false, props) as any);
  },
  pushDeleteAdminEventClassModal: (props?: ModalProps): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.DELETE_ADMIN_EVENTS_CLASS, false, true, props) as any);
  },
  pushMultipleGroupsModal: (props?: ModalProps): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(ModalTypes.MULTIPLE_GROUPS, false, false, props) as any);
  },
  pushConfirmEmptyCartModal: (): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(
      ModalTypes.DELETE_ITEM_OR_CART,
      false,
      true,
      {
        title: "Remove all items from cart?",
        text: "This action cannot be undone.",
        buttonText: "EMPTY CART",
        removeItemContext: "confirm-empty-cart",
      },
    ) as any);
  },
  pushRemoveItemModal: (props: ModalProps): ActionCreator => dispatch => {
    dispatch(actionCreators.pushModal(
      ModalTypes.DELETE_ITEM_OR_CART,
      false,
      true,
      {
        id: props.id,
        to: props.to,
        title: "Remove item from cart?",
        text: "This action cannot be undone.",
        buttonText: "REMOVE ITEM",
        removeItemContext: "remove-item",
      },
    ) as any);
  },
  popModal: (saveBefore: boolean, saveAfter: boolean, modal: ModalTypes): ActionCreator => (dispatch, getState) => {
    const blockModalClose = cancelLoadAndRollback();
    if (blockModalClose) return;

    const state = getState() as ApplicationState;
    if (saveBefore) dispatch(new SaveState());
    dispatch(new PopModal(modal, saveBefore, saveAfter));
    // if saving, no save state after modal closes
    if (saveAfter && state.app.apiSaving === 0) dispatch(new SaveState());
  },
  updateGlobalSearchText: (str?: string): ActionCreator => dispatch => dispatch(new UpdateGlobalSearchText(str)),
  clearAllCache: (): ActionCreator => dispatch => dispatch(new ClearAllCache()),
  showAdminPageHeader: (show: boolean): ActionCreator => dispatch => dispatch(new ShowAdminPageHeader(show)),
  toggleModalSaving: (saving?: boolean): ActionCreator => dispatch => dispatch(new ToggleModalSaving(saving)),
  closeAllModals: (): ActionCreator => (dispatch, getState) => {
    if (getState().app.openedModals.length === 0) return;

    dispatch(new CloseAllModals());
  },
  silentCancelAll: (isDuringNavigation?: boolean, skipRestoreState?: boolean): ActionCreator => dispatch => dispatch(new SilentCancelAllAction(isDuringNavigation, skipRestoreState)),
  setPreviousLocation: (location: any): ActionCreator => (dispatch) => {
    dispatch(new SetPreviousLocation(location));
  },
};
