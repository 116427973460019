import { IValidator, FormDefinition } from "./validation";
import { SUFFIX } from './actions';
import { apiValidationReducerCreator } from "../../../../../store/Validation/reducerCreator";
import * as Actions from './actions';
import { ClearAdminEventsCacheBelowOne, ClearAllAdminEventsCache } from "../../../CacheOne/actions";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../../utils/StrongActions";
import {setDefaults} from "../../../../../utils/validator";
import {ClearAdminEventsCacheTwoEvent} from "../../../CacheTwoEvent/actions";
import { WithRootState } from "../../../../Validation/actionCreator";
import {ClearAllCache} from "../../../../App/actions";

export interface AdminEventsEventClassesTabState {
  ActiveForm: AdminEventsEventClassesTabActiveForm;
  ValidationRules: IValidator;
  expandFilter: boolean;
}

export interface AdminEventsEventClassesTabActiveForm {
  ShowDeleted?: boolean;
  FilterText?: string;
  KindOfClass?: number;
  RegisterUnder?: number;
}

const getInitialState: () => AdminEventsEventClassesTabState = () => ({
  ActiveForm: {
    KindOfClass: 0,
    RegisterUnder: 0,
  },
  expandFilter: false,
  ValidationRules: {...FormDefinition},
});

const checkApiValidation = apiValidationReducerCreator(SUFFIX);


const AdminEventsEventClassesTabReducer: Reducer<AdminEventsEventClassesTabState> = (s, action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, action);
  if (
    isActionType(action, ClearAllAdminEventsCache) ||
    isActionType(action, ClearAdminEventsCacheTwoEvent) ||
    isActionType(action, ClearAdminEventsCacheBelowOne) ||
    isActionType(action, ClearAllCache)
  ) {
    return setDefaults(action.rootState, getInitialState());
  } else if (isActionType(action, Actions.AdminEventsEventClassesTabReset)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        FilterText: '',
      },
    };
  } else if (isActionType(action, Actions.ToggleExpandFilterAction)) {
    return {
      ...state,
      expandFilter: action.expand,
    };
  }
  return state || setDefaults(action.rootState, getInitialState());
};

export default AdminEventsEventClassesTabReducer;