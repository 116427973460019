import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, createApiSubmitFormMethod
} from '../Validation/actionCreator';
import { AddGroup } from '../../models/api/addGroup';
import { ActiveForm } from './index';
import type { ApplicationState } from '..';

export const SUFFIX = '__ADD_GROUP';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ApiSubmitActions = createApiSubmitActions(SUFFIX);

export type Actions = typeof actionCreators;

const valueOrEmpty = (value) => {
  return value === undefined ? '' : value;
};

const formCreator = (rootState: ApplicationState, activeForm: ActiveForm): AddGroup => {
  return {
    row: {
      IDi: 0,
      TS: 0,

      Username: activeForm.Username,
      Password: activeForm.Password,
      Email: activeForm.Email,

      GroupTypeID: activeForm.GroupTypeID,
      UnitTypeID: activeForm.UnitTypeID,
      UnitGenderID: activeForm.UnitGenderID,
      Unit: valueOrEmpty(activeForm.Unit),
      CouncilIDi: activeForm.CouncilIDi,
      DistrictIDi: activeForm.DistrictIDi,

      Suffix: valueOrEmpty(activeForm.Suffix),
      FirstName: activeForm.FirstName,
      LastName: valueOrEmpty(activeForm.LastName),

      PhoneNumber: valueOrEmpty(activeForm.PhoneNumber),
      PhoneNumber2: valueOrEmpty(activeForm.PhoneNumber2),
      Address: valueOrEmpty(activeForm.Address),
      Address2: valueOrEmpty(activeForm.Address2),
      StateID: valueOrEmpty(activeForm.StateID),
      City: valueOrEmpty(activeForm.City),
      Zip: valueOrEmpty(activeForm.Zip),

      Contact2Name: valueOrEmpty(activeForm.Contact2Name),
      Contact2Phone: valueOrEmpty(activeForm.Contact2Phone),
      Contact2Email: valueOrEmpty(activeForm.Contact2Email),

      AllowOnlineBooking: !!activeForm.AllowOnlineBooking,
      UnitSponsor: valueOrEmpty(activeForm.UnitSponsor),
      Organization: valueOrEmpty(activeForm.Organization),
      PricingTypeID: valueOrEmpty(activeForm.PricingTypeID),
      FacilityPricingTypeID: valueOrEmpty(activeForm.FacilityPricingTypeID),
      MI: valueOrEmpty(activeForm.MI),
      MarketingSource: valueOrEmpty(activeForm.MarketingSource),
      Inactive: false
    }
  };
};

export const actionCreators = {
  apiSubmitForm: createApiSubmitFormMethod(ApiSubmitActions, (s) => s.addGroup, formCreator),
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.addGroup),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
};
