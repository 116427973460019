
import { CLEAR_CACHE_PREFIX, createRequestActions } from "../../../store/Validation/actionCreator";
import { ActionCreator } from "../..";
import { GetAdminEventTypesCacheBodyParams } from "../../../constants/adminEventsUrls";
import { HideDrawer, SilentCancelAllAction } from '../../App/actions';
import { UPDATE_YEAR_OVERVIEW_SUFFIX } from '../Reports/YearOverview/actions';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../../utils/suffix';
import { typeName, Action } from "../../../utils/StrongActions";

export const UPDATE_EVENT_TYPE_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__EVENT_TYPE_FORM';
export const ADMIN_EVENT_TYPES_SUFFIX = '__ADMIN_EVENT_TYPES__';
export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + '__CACHE_ONE';
export const ADMIN_EVENTS_MODULE_SUFFIX = '__$ADMIN_EVENTS_CACHE_ONE__HANDLE$';

export const GetEventTypesAction = createRequestActions(SUFFIX + '__GET_EVENT_TYPES__' + ADMIN_EVENTS_MODULE_SUFFIX);
export const GetEventTypeCacheAction = createRequestActions(SUFFIX + ADMIN_EVENTS_MODULE_SUFFIX + UPDATE_YEAR_OVERVIEW_SUFFIX + UPDATE_EVENT_TYPE_FORM_SUFFIX);

@typeName(`${CLEAR_CACHE_PREFIX}_ADMIN_EVENTS_CACHE_BELOW_ONE`) export class ClearAdminEventsCacheBelowOne extends Action {}

@typeName(`${CLEAR_CACHE_PREFIX}_ALL_ADMIN_EVENTS_CACHE`) export class ClearAllAdminEventsCache extends Action {
  constructor() {
    super();
  }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  getEventTypes: (): ActionCreator => dispatch => dispatch(GetEventTypesAction.request({})),
  clearAdminEventsCacheBelowOne: (): ActionCreator => dispatch => dispatch(new ClearAdminEventsCacheBelowOne()),
  clearAllAdminEventsCache: (): ActionCreator => dispatch => dispatch(new ClearAllAdminEventsCache()),
  refresh: (params: GetAdminEventTypesCacheBodyParams, isRefreshingCacheOneForm: boolean): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    // When `!isRefreshingCacheOneForm`, we should skip update cache one form, by setting `skipUpdateForm` to true
    dispatch(GetEventTypeCacheAction.request(params, {skipUpdateForm: !isRefreshingCacheOneForm}));
  },
};