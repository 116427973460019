import {APISuccess} from "../Validation/actionCreator";
import {ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction} from "../App/actions";
import {ClearCacheBelowOne} from "../CacheOne/actions";
import {convertObjToMoment} from "../../utils/dateHelper";
import {PreviousOrder, PreviousOrderItem} from "../../models/api/cacheTwoPrevOrders";
import {ClearCacheBelowTwoPrevOrders} from "../CacheTwoPrevOrders/actions";
import {GetPrevOrder, SetPrevOrder} from "./actions";
import {SubmitManageOrderActions} from "../Settings/PrevOrders/ManageOrder/actions";
import { Reducer } from "redux";
import { Action, isActionType } from "../../utils/StrongActions";

interface CacheThreePrevOrdersCore {
  PreviousOrder?: PreviousOrder | null;
  PreviousOrderItems?: Array<PreviousOrderItem> | null;
}

export interface CacheThreePrevOrderState extends CacheThreePrevOrdersCore {}

const setCacheThreeData = (data: CacheThreePrevOrdersCore) => {
  return {
    PreviousOrder: convertObjToMoment(data.PreviousOrder, ['DatePayment']),
    PreviousOrderItems: data.PreviousOrderItems
  };
};

const handleResponse = (state, responseData, request) => {
  if (responseData.PreviousOrderItems !== undefined){
    return setCacheThreeData(responseData);
  }
  return state;
};

const CacheThreePrevOrder: Reducer<CacheThreePrevOrderState> = (state: CacheThreePrevOrderState, act: Action) => {
  if (isActionType(act, SetPrevOrder)) {
    return { ...state, PreviousOrder: act.order, PreviousOrderItems: undefined};
  } else if (act.type === GetPrevOrder.successType || act.type === SubmitManageOrderActions.successType) {
    const action = <APISuccess> act;
    return handleResponse(state, action.response.response, action.response.request);
  } else if (isActionType(act, SetCacheAction)) {
    return handleResponse(state, act.response.xhr.response, act.response.request);
  } else if (
    isActionType(act, ClearAllCache) || isActionType(act, ClearAllEndUserCacheButOptions) || isActionType(act, ClearCacheBelowOne)
  ) {
    return {};
  } else if (isActionType(act, ClearCacheBelowTwoPrevOrders)) {
    if (state.PreviousOrderItems === undefined) {
      return state;
    }
    return { PreviousOrder: state.PreviousOrder };
  }
  return state || {};
};

export default CacheThreePrevOrder;
