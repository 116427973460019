import './attributes/alignment.scss';
import './attributes/layout.scss';
import './attributes/margin.scss';
import './attributes/padding.scss';

export interface MarginProps {
  marginTop?: number | 'auto';
  marginBottom?: number | 'auto';
  marginLeft?: number | 'auto';
  marginRight?: number | 'auto';
  marginVertical?: number | 'auto';
  marginHorizontal?: number | 'auto';
  mobileMarginTop?: number | 'auto';
  mobileMarginBottom?: number | 'auto';
  mobileMarginLeft?: number | 'auto';
  mobileMarginRight?: number | 'auto';
  mobileMarginVertical?: number | 'auto';
  mobileMarginHorizontal?: number | 'auto';
}

export interface PaddingProps {
  paddingTop?: number | 'auto';
  paddingBottom?: number | 'auto';
  paddingLeft?: number | 'auto';
  paddingRight?: number | 'auto';
  paddingVertical?: number | 'auto';
  paddingHorizontal?: number | 'auto';
  mobilePaddingTop?: number | 'auto';
  mobilePaddingBottom?: number | 'auto';
  mobilePaddingLeft?: number | 'auto';
  mobilePaddingRight?: number | 'auto';
  mobilePaddingVertical?: number | 'auto';
  mobilePaddingHorizontal?: number | 'auto';
}

export interface LayoutProps {
  layout?: 'vertical' | 'horizontal';
  mobileLayout?: 'vertical' | 'horizontal';
}

export interface AlignmentProps {
  horizontalAlignment?: 'left' | 'center' | 'right';
  mobileHorizontalAlignment?: 'left' | 'center' | 'right';
  verticalAlignment?: 'top' | 'middle' | 'bottom';
  mobileVerticalAlignment?: 'top' | 'middle' | 'bottom';
}

interface Attributes extends MarginProps, LayoutProps, AlignmentProps, PaddingProps {
}

const attributeRoots = {
  marginTop: 'margin-top',
  marginBottom: 'margin-bottom',
  marginLeft: 'margin-left',
  marginRight: 'margin-right',
  marginVertical: 'margin-vertical',
  marginHorizontal: 'margin-horizontal',
  paddingTop: 'padding-top',
  paddingBottom: 'padding-bottom',
  paddingLeft: 'padding-left',
  paddingRight: 'padding-right',
  paddingVertical: 'padding-vertical',
  paddingHorizontal: 'padding-horizontal',
  layout: 'layout',
  horizontalAlignment: 'horizontal-alignment',
  verticalAlignment: 'vertical-alignment',
};

const normalPrefix = 'attribute-';
const mobilePrefix = 'attribute-mobile-';

const mobileNumberAttributes = {
  mobileMarginTop: mobilePrefix + attributeRoots.marginTop,
  mobileMarginBottom: mobilePrefix + attributeRoots.marginBottom,
  mobileMarginLeft: mobilePrefix + attributeRoots.marginLeft,
  mobileMarginRight: mobilePrefix + attributeRoots.marginRight,
  mobileMarginVertical: mobilePrefix + attributeRoots.marginVertical,
  mobileMarginHorizontal: mobilePrefix + attributeRoots.marginHorizontal,
  mobilePaddingTop: mobilePrefix + attributeRoots.paddingTop,
  mobilePaddingBottom: mobilePrefix + attributeRoots.paddingBottom,
  mobilePaddingLeft: mobilePrefix + attributeRoots.paddingLeft,
  mobilePaddingRight: mobilePrefix + attributeRoots.paddingRight,
  mobilePaddingVertical: mobilePrefix + attributeRoots.paddingVertical,
  mobilePaddingHorizontal: mobilePrefix + attributeRoots.paddingHorizontal,
};

const normalNumberAttributes = {
  marginTop: normalPrefix + attributeRoots.marginTop,
  marginBottom: normalPrefix + attributeRoots.marginBottom,
  marginLeft: normalPrefix + attributeRoots.marginLeft,
  marginRight: normalPrefix + attributeRoots.marginRight,
  marginVertical: normalPrefix + attributeRoots.marginVertical,
  marginHorizontal: normalPrefix + attributeRoots.marginHorizontal,
  paddingTop: normalPrefix + attributeRoots.paddingTop,
  paddingBottom: normalPrefix + attributeRoots.paddingBottom,
  paddingLeft: normalPrefix + attributeRoots.paddingLeft,
  paddingRight: normalPrefix + attributeRoots.paddingRight,
  paddingVertical: normalPrefix + attributeRoots.paddingVertical,
  paddingHorizontal: normalPrefix + attributeRoots.paddingHorizontal,
};

const numberAttributes = {
  ...mobileNumberAttributes,
  ...normalNumberAttributes,
};

const mobileStringAttributes = {
  mobileLayout: mobilePrefix + attributeRoots.layout,
  mobileHorizontalAlignment: mobilePrefix + attributeRoots.horizontalAlignment,
  mobileVerticalAlignment: mobilePrefix + attributeRoots.verticalAlignment,
};

const normalStringAttributes = {
  layout: normalPrefix + attributeRoots.layout,
  horizontalAlignment: normalPrefix + attributeRoots.horizontalAlignment,
  verticalAlignment: normalPrefix + attributeRoots.verticalAlignment,
};

const stringAttributes = {
  ...mobileStringAttributes,
  ...normalStringAttributes
};

// @todo: could refactor this function to reduce lines
export default (...params: Array<boolean | string | Attributes | undefined>) => {
  let renderMobile = params.filter(param => typeof param === 'boolean');
  let classNames = params.filter(param => typeof param === 'string');
  let attributes = params.filter(param => typeof param === 'object');
  let finalClassName = classNames.join(' ');
  if (attributes.length) {
    if (!renderMobile.length) {
      attributes.forEach((attrs: Attributes) => {
        for (let prop in attrs) {
          if (attrs.hasOwnProperty(prop)) {
            if (typeof numberAttributes[prop] !== 'undefined') {
              if (attrs[prop] && attrs[prop] === 'auto') {
                finalClassName += ` ${numberAttributes[prop]}-${attrs[prop]}`;
              } else if (attrs[prop] || attrs[prop] === 0) {
                finalClassName += ` ${numberAttributes[prop]}-${attrs[prop]}px`;
              }
            } else if (typeof stringAttributes[prop] !== 'undefined') {
              finalClassName += ` ${stringAttributes[prop]}-${attrs[prop]}`;
            } else {
            }
          }
        }
      });
    } else {
      // @todo: string attributes probably do not work with renderMobile right now
      let atts = {};
      attributes.forEach((attrs: Attributes) => {
        for (let prop in attrs) {
          if (attrs.hasOwnProperty(prop)) {
            if (typeof numberAttributes[prop] !== 'undefined') {
              if (typeof mobileNumberAttributes[prop] !== 'undefined') {
                const rootType = mobileNumberAttributes[prop].split(mobilePrefix)[1];
                if (!atts[rootType]) atts[rootType] = {};
                atts[rootType].mobile = attrs[prop];
              } else if (typeof normalNumberAttributes[prop] !== 'undefined') {
                const rootType = normalNumberAttributes[prop].split(normalPrefix)[1];
                if (!atts[rootType]) atts[rootType] = {};
                atts[rootType].normal = attrs[prop];
              }
            } else if (typeof stringAttributes[prop] !== 'undefined') {
              if (typeof mobileStringAttributes[prop] !== 'undefined') {
                const rootType = mobileStringAttributes[prop].split(mobilePrefix)[1];
                if (!atts[rootType]) atts[rootType] = {};
                atts[rootType].mobile = attrs[prop];
              } else if (typeof normalStringAttributes[prop] !== 'undefined') {
                const rootType = normalStringAttributes[prop].split(normalPrefix)[1];
                if (!atts[rootType]) atts[rootType] = {};
                atts[rootType].normal = attrs[prop];
              }
            }
          }
        }
      });
      for (let key in atts) {
        if (atts.hasOwnProperty(key)) {
          const attsObj = atts[key];
          if (attsObj.normal && !attsObj.mobile) {
            if (attsObj.normal && attsObj.normal === 'auto') {
              finalClassName += ` ${normalPrefix}${key}-${attsObj.normal}`;
            } else if (attsObj.normal || attsObj.normal === 0) {
              finalClassName += ` ${normalPrefix}${key}-${attsObj.normal}px`;
            }
          } else {
            if (attsObj.mobile && attsObj.mobile === 'auto') {
              finalClassName += ` ${normalPrefix}${key}-${attsObj.mobile}`;
            } else if (attsObj.mobile || attsObj.mobile === 0) {
              finalClassName += ` ${normalPrefix}${key}-${attsObj.mobile}px`;
            }
          }
        }
      }
    }
  }
  return finalClassName;
};
