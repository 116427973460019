import * as React from 'react';
import { default as TextField, TextFieldProps } from '../TextField';
import { reduxStoreService } from '../../../store/service';

interface Props {
  isInteger?: boolean;
  useFourDecimals?: boolean;
  dollarSignPrefix?: boolean;
  pullDefaultValueFromRule?: boolean;
}

interface State {
  focused?: boolean;
}

// @todo: this needs to be used in conjunction with decimalOnly validationRules to work properly
class MoneyField extends React.PureComponent<TextFieldProps & Props, State> {
  public props: TextFieldProps & Props;
  private inputRef = React.createRef();
  public state = {
    focused: false,
  };

  getFinalValue = (props?: Props & TextFieldProps) => {
    const _props = props ? props : this.props;
    const {value, validationRules, defaultValue, pullDefaultValueFromRule} = _props;

    let finalValue;

    if (value !== undefined) {
      finalValue = value;
    } else if (pullDefaultValueFromRule && validationRules && validationRules.defaultValue) {
      finalValue = validationRules.defaultValue(reduxStoreService().getState());
    } else {
      finalValue = defaultValue;
    }

    return finalValue;
  };

  onChange = (val) => {
    const {onChange, validationRules} = this.props;
    if (onChange && validationRules) {
      // Here we pass down whatever string value users typing in, we don't need to
      // parse the number value as we do in `onBlur`, because we only run validations
      // onBlur
      onChange(val, validationRules);
    }
  };

  onFocus = () => {
    this.setState({focused: true});
  };

  formatAndBlur = (val, unfocused?: boolean) => {
    const {onBlur, isInteger, validationRules, useFourDecimals} = this.props;
    if (unfocused) {
      this.setState({focused: false});
    }

    if (val === undefined || val === null || isNaN(val as number))
      return;

    if (onBlur && validationRules) {
      // Here we always parse the number value from the input and then set it to form
      // by calling `onBlur`. We then do the formatting (i.e. for float number, we usually
      // format the number with certain # of decimals) when rendering.
      // For float number, we need to make sure the number value we pass down to store is
      // consistent with the formatted value because we currently use that to strip decimals
      const decimals = useFourDecimals ? 4 : 2;
      const numberValue = isInteger ? parseInt(val) : parseFloat(parseFloat(val).toFixed(decimals));
      if (isNaN(numberValue)) {
        onBlur(val, validationRules);
      } else {
        onBlur(numberValue, validationRules);
      }
    }
  };

  public render() {
    const {value, useFourDecimals} = this.props;

    // Only try format when NOT in focused
    // If value is type of string, we render it as it is. This is true when users are still focusing and typing in the field
    // If value is type of number, we format it and then render the formatted value. This happens when users finish typing and blur the field
    const decimals = useFourDecimals ? 4 : 2;
    const formattedValue = 
      !this.state.focused ? (typeof value === "string" ? value : value?.toFixed(decimals)) : value;
    return (
      <TextField
        inputRef={this.inputRef}
        onFocusCallback={this.onFocus}
        {...this.props}
        decimalPrecision={decimals}
        onChange={this.onChange}
        onBlur={(val) => this.formatAndBlur(val, true)}
        value={formattedValue}
      />
    );
  }
}

export default MoneyField;
