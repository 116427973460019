import { ActionCreator, ApplicationState } from "../../../..";
import {
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions,
} from "../../../../Validation/actionCreator";
import { typeName, Action } from "../../../../../utils/StrongActions";

export const SUFFIX = "__ADMIN_MANAGE_REQUIREMENTS_MODAL";

@typeName("TOGGLE_REQUIREMENT" + SUFFIX)
export class ToggleRequirement extends Action {
  constructor(public reqID: number) {
    super();
  }
}

@typeName("DESELECT" + SUFFIX)
export class Deselect extends Action {
  constructor() {
    super();
  }
}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export const actionCreators = {
  updateValue: createUpdateValueMethod(
    ValidateActions,
    ApiValidateActions,
    (s: ApplicationState) => s.adminEvents.events.modals.manageRequirements,
  ),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  toggleRequirement:
    (reqID: number): ActionCreator =>
    (dispatch) => {
      dispatch(new ToggleRequirement(reqID));
    },
  deselect: (): ActionCreator => (dispatch) => {
    dispatch(new Deselect());
  },
};
