import {makeRequestEpic} from "../../../Validation/epicCreator";
import {AjaxResponse} from "rxjs/ajax";
import { ENTITY_CREATED, ENTITY_EDITED, ENTITY_PARSE_ERROR, ENTITY_DELETED, ENTITY_RESTORED } from "../../../../constants/messages/adminCMS";
import {DeleteAdminEventClassSubmitActions, UpdateAdminEventClassSubmitActions} from "./actions";
import { getUpdateAdminEventClass } from "../../../../constants/adminEventsUrls";
import { ADMIN_EVENT_CLASS } from "../../../../constants/messages/adminEvents";
import { captureTentarooError } from "../../../../utils/dataHelper";

export const adminEventsUpdateClassEpic = makeRequestEpic(
  UpdateAdminEventClassSubmitActions,
  getUpdateAdminEventClass,
  undefined,
  true,
  (response: AjaxResponse) => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      if (body.row.IDi) {
        return ENTITY_EDITED(ADMIN_EVENT_CLASS);
      }
      return ENTITY_CREATED(ADMIN_EVENT_CLASS);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(ADMIN_EVENT_CLASS)));
      return '';
    }
  },
);

export const adminEventsDeleteClassEpic = makeRequestEpic(
  DeleteAdminEventClassSubmitActions,
  getUpdateAdminEventClass,
  undefined,
  true,
  (response: AjaxResponse) => {
    const item = response.response.EventsEventClass;

    if (item.Inactive) {
      return ENTITY_DELETED(ADMIN_EVENT_CLASS);
    }
    return ENTITY_RESTORED(ADMIN_EVENT_CLASS);
  },
);