import * as React from 'react';
import { Main, MainContent } from '../../Layouts';
import { Media } from '../../Elements';
import SideBar from './SideBar';
import { bindActionCreators } from 'redux';
import { appActionCreators, ApplicationState } from '../../../store';
import { noop } from '../../../utils';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';
import { WithInertAttribute } from '../../Elements/WithInert';

type Props = WithInertAttribute<{
  addedItems: Array<any>;
  children?: React.ReactNode;
  footer?: React.ReactElement<any>;
  header?: React.ReactElement<any>;
  sideBar?: React.ReactElement<any>;
  tabs?: React.ReactElement<any>;
  empty?: React.ReactElement<any>;
  preEmptyChildren?: React.ReactElement<any> | Array<React.ReactElement<any>>;
  showEmpty?: boolean;
  className?: string;
  loading?: boolean;
  hideSidebar?: boolean;
}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class AddedTemplate extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  public componentDidMount() {
    if (this.props.showAddedSideBar) this.props.actions.hideAddedSideBar();
    window.addEventListener('resize', this.handleResize);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth >= 960 && this.props.showAddedSideBar) {
      this.props.actions.hideAddedSideBar();
    }
  };

  public componentWillReceiveProps(nextProps: Props & ConnectedProps) {
    if (nextProps.addedItems && this.props.showAddedSideBar
      && this.props.actions && nextProps.showAddedSideBar && !nextProps.addedItems.length) {
      this.props.actions.hideAddedSideBar();
    }
  }

  onCloseSidebar = () => this.props.actions ? this.props.actions.hideAddedSideBar() : noop;
  onOpenSidebar = () => this.props.actions ? this.props.actions.showAddedSideBar() : noop;

  public render() {
    const { inert, hideSidebar, loading, header, footer, children, sideBar, tabs, showEmpty, empty, addedItems, className, showAddedSideBar, preEmptyChildren } = this.props;
    const addedCount = addedItems ? addedItems.length : 0;
    const appShowSidebar = showAddedSideBar;

    return (
      <Main
        inert={inert}
        mobileBackground="white"
        footer={footer}
        rightSidebar={(
          <SideBar
            open={appShowSidebar}
            onClose={this.onCloseSidebar}
            content={sideBar}
            addedCount={addedCount}
            hideSidebar={hideSidebar}
          />
        )}
      >
        <MainContent
          className={className}
          loading={loading}
          header={header ? React.cloneElement(header, {
            ...header.props,
            tabs: tabs ? React.cloneElement(tabs, {
              ...tabs.props,
              onShowSideBar: this.onOpenSidebar,
              onHideSideBar: this.onCloseSidebar,
              addedSelected: appShowSidebar,
              addedCount: addedCount,
            }) : null
          }) : null}
          showScrollToTop
        >
          {appShowSidebar ? (<Media mobile layout="vertical">{sideBar}</Media>) : null}

          {tabs ? (
            <Media desktop tablet expand={true}>
              {React.cloneElement(tabs, {
                ...tabs.props,
                onShowSideBar: this.onOpenSidebar,
                onHideSideBar: this.onCloseSidebar,
                addedSelected: appShowSidebar,
                addedCount: addedCount,
              })}
            </Media>
          ) : null}
          <Media desktop tablet mobile={!appShowSidebar} layout="vertical">
            {preEmptyChildren}
            {showEmpty && empty}
            {children}
          </Media>
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (s: ApplicationState) => {
  return {
    showAddedSideBar: s.app.showAddedSideBar,
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(appActionCreators as any, dispatch) });

const ConnectedAddedTemplate = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(AddedTemplate);

export default ConnectedAddedTemplate;
export { default as AddedTemplateTabs } from './Tabs';
export { default as AddedTemplateContent } from './Content';
export { default as AddedTemplateSideBar} from './SideBarContent';
export { default as AddedTemplateShowSideBar} from './ShowSideBar';
