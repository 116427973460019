import * as React from 'react';

export interface ContentProps {
  children?: React.ReactNode;
}

const Content: React.SFC<ContentProps> = ({ children }: ContentProps): React.ReactElement<ContentProps> => (
  <div>
    {children}
  </div>
);

export default Content;
