import { IconProps } from '../../Icons';
import * as React from 'react';
import '../../../styles/icons/index.scss';

interface ResourceIconProps extends IconProps {
  resourceType?: string;
  title?: string;
  fill?: string;
}

class ResourceIcon extends React.Component<ResourceIconProps> {

  render() {
    const { className: overrideClassName, width, height, fill, title, resourceType } = this.props;
    const className = 'icon' + (overrideClassName ? ` ${overrideClassName}` : '');

    return (
      <svg className={className} width={`${width || 32}px`} height={`${height || 40}px`} viewBox="0 0 32 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>{title}</title>
        <defs>
            <path d="M22.272,0 L32,9.728 L32,40 L0,40 L0,0 L22.272,0 Z" id="path-1"></path>
        </defs>
        <g id="UI/Placeholder/Circle-Badge" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Group">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>

            <use id="Color" fill={fill} xlinkHref="#path-1"></use>

            <rect id="Rectangle" fillOpacity="0.2" fill="#000000" mask="url(#mask-2)" x="22" y="0" width="10" height="10"></rect>
            <text id="Value" mask="url(#mask-2)" font-family="Roboto-Bold, Roboto" font-size="9" font-weight="bold" line-spacing="16" letter-spacing="0.600000024" fill="#FFFFFF">
              <tspan x="50%" y="22" dominant-baseline="middle" text-anchor="middle">{resourceType}</tspan>
            </text>
          </g>
        </g>
      </svg>
    );
  }
}

export default ResourceIcon;