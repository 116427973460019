import * as React from 'react';
import { RegisterHeaderProgress, RegisterHeaderProgressStep } from '../../../../../Elements';
import {CacheFourEventsParticipantsState} from "../../../../../../store/CacheFourEventsParticipants";
import {showClasses} from "../../../../../../store/Events/Event/Register/Participant/Main/uiHelpers";
import {CacheTwoEventsState} from "../../../../../../store/CacheTwoEvents";
import {EventClassIndividualAvailable, RegisteredClass} from "../../../../../../models/class";

export interface HeaderProgressProps {
  selected: 'roster' | 'type' | 'classes' | 'confirmation';
  cacheTwoEvents: CacheTwoEventsState;
  cacheFourEventsParticipants: CacheFourEventsParticipantsState;
  loading: boolean;
  availableClasses: Array<EventClassIndividualAvailable>;
  conflictingClasses: Array<EventClassIndividualAvailable>;
  registeredClasses: Array<RegisteredClass>;
  tabs?: React.ReactNode;
}

const HeaderProgress: React.SFC<HeaderProgressProps> = ({ availableClasses, conflictingClasses, registeredClasses, cacheFourEventsParticipants, cacheTwoEvents, selected, tabs, loading }: HeaderProgressProps): React.ReactElement<HeaderProgressProps> => (
  <RegisterHeaderProgress tabs={tabs} loading={loading}>
    <RegisterHeaderProgressStep selected={selected === 'roster'}>Participant</RegisterHeaderProgressStep>
    <RegisterHeaderProgressStep selected={selected === 'type'}>Participant Type</RegisterHeaderProgressStep>
    {showClasses(availableClasses, conflictingClasses, registeredClasses) && <RegisterHeaderProgressStep selected={selected === 'classes'}>
      {cacheTwoEvents.EventTypeRegistrationSettings && cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.ShowParticipantClassesAs ?
        cacheTwoEvents.EventTypeRegistrationSettings.NamesRegistrationSettings.ShowParticipantClassesAs : 'Classes'}
    </RegisterHeaderProgressStep>}
    <RegisterHeaderProgressStep selected={selected === 'confirmation'}>Confirmation</RegisterHeaderProgressStep>
  </RegisterHeaderProgress>
);

export default HeaderProgress;
