import * as React from 'react';
import { findDOMNode } from 'react-dom';
import isNode from './isNode';
import isVisible from './isVisible';
import ScrollMagic from 'ScrollMagic';
import {Nullable} from './dataHelper';
//import 'ScrollMagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js'; // show debug lines

interface State {
  enter: boolean | null;
}

const onViewportLeave = (onEnter: () => any, onLeave: () => any): <P>(WrappedComponent: React.ReactType) => React.ClassType<P, any, any> => (WrappedComponent: React.ReactType) => {
  const mappers = {
    onEnter,
    onLeave
  };
  return (
    class extends React.Component<{}, State> {
      private controller: ScrollMagic.Controller;
      private isComponentMounted = false;
      private isStarted = false;

      public state: State = {
        enter: null
      };

      componentDidMount() {
        if (!isNode()) {
          window.addEventListener('resize', this.reset);
          this.reset();
        }
      }

      componentWillUnmount() {
        if (!isNode()) {
          this.isComponentMounted = false;
          this.stop();
          window.removeEventListener('resize', this.reset);
        }
      }

      public reset = () => {
        const element = findDOMNode(this) as Nullable<HTMLElement>;
        if (!element) return;
        if (this.isStarted && !isVisible(element)) {
          this.stop();
        } else if (!this.isStarted && isVisible(element)) {
          this.start();
        }
      };

      public get headerHeight() {
        return 64;
      }

      public start = () => {
        this.isStarted = true;
        const element = findDOMNode(this) as Nullable<HTMLElement>;

        if (!element) return;
        this.controller = new ScrollMagic.Controller();

        const scene = new ScrollMagic.Scene({
          triggerElement: element,
          triggerHook: 'onLeave',
          offset: element.getBoundingClientRect().height - this.headerHeight
        })
        //.addIndicators() // show debug lines
          .addTo(this.controller);

        scene.on('enter', this.handleSceneEnter);
        scene.on('leave', this.handleSceneLeave);
        this.isComponentMounted = true;
      };

      public stop = () => {
        this.isStarted = false;
        if (this.controller) this.controller.destroy();
      };

      handleSceneEnter = () => {
        if (this.isComponentMounted) {
          this.setState({ enter: false });
        }
      };

      handleSceneLeave = () => {
        if (this.isComponentMounted) {
          this.setState({ enter: true });
        }
      };

      render() {
        const { ...props } = this.props;
        let nextProps: any;
        if (this.state.enter) nextProps = mappers.onEnter();
        else if (this.state.enter !== null) nextProps = mappers.onLeave();
        return <WrappedComponent {...props} {...nextProps}/>;
      }
    }
  );
};

export default onViewportLeave;
