import { ActionCreator, ApplicationState } from "../../../..";
import { createValidateActions, createApiValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra } from "../../../../Validation/actionCreator";
import { validate } from "../../../../../utils/validator";
import { GenerateAdminEventsEventReportsActions, SendToClientsActions } from "../../Event/Dashboard/actions";
import { actionCreators as appActionCreators } from "../../../../App/actions";
import { scrollModalToTop } from "../../../../../utils/helpers/adminCMSPageHelper";
import { FormDefinition } from './validation';
import { ModalTypes } from "../../../../../utils/modalHelper";
import { typeName, Action } from "../../../../../utils/StrongActions";
import { SaveState } from "../../../../Rollback/actions";

export const SUFFIX = '__GENERATE_INVOICES';

export const namespace = () => 'pages--events--modal--generate-invoices-modal';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

@typeName("INIT" + SUFFIX) export class InitGenerateInvoicesModal extends Action {}

@typeName("RESET" + SUFFIX) export class ResetGenerateInvoicesModal extends Action {}

@typeName(`CLEAR_ERROR${SUFFIX}`) export class ClearError extends Action {}

@typeName('ADD_GROUPS' + SUFFIX)
export class AddGroups extends Action {
  constructor(public groupsIds: number[]) { super(); }
}

@typeName('REMOVE_GROUP' + SUFFIX)
export class RemoveGroup extends Action {
  constructor(public groupId: number) { super(); }
}

export type Actions = typeof actionCreators;

const validateSelectedGroups = (dispatch, state: ApplicationState) => {
  const modal = state.adminEvents.events.modals.generateInvoices;
  dispatch(ValidateActions.updateCurr(modal.ActiveForm.SelectedGroups, FormDefinition.SelectedGroups.key, FormDefinition.SelectedGroups));
  let validationResults = validate(FormDefinition.SelectedGroups, modal.ActiveForm.SelectedGroups, undefined, (s: ApplicationState) => s.adminEvents.events.modals.generateInvoices);
  dispatch(ValidateActions.update(validationResults, FormDefinition.SelectedGroups.key));

  if (!validationResults.Errors || !validationResults.Errors.SelectedGroups) {
    dispatch(new ClearError());
  }
};

export const actionCreators = {
  init: (): ActionCreator => dispatch => {
    dispatch(new InitGenerateInvoicesModal());
    dispatch(new SaveState());
  },
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.events.modals.generateInvoices),
  resetGenerateInvoicesModal: (): ActionCreator => dispatch => dispatch(new ResetGenerateInvoicesModal()),
  addGroups: (groupIds: number[]): ActionCreator => (dispatch, getState) => {
    dispatch(new AddGroups(groupIds));
    const state = getState() as ApplicationState;
    validateSelectedGroups(dispatch, state);
  },
  removeGroup: (groupId: number): ActionCreator => (dispatch, getState) => {
    dispatch(new RemoveGroup(groupId));
    const state = getState() as ApplicationState;
    validateSelectedGroups(dispatch, state);
  },
  submit: (JustExportPDF?: boolean): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;

    const modalState = state.adminEvents.events.modals.generateInvoices;
    if (!state.adminEvents.cacheOne.EventsEventType) return;
    if (!state.adminEvents.cacheTwoEvent.EventsEvent) return;
    const valid = innerApiSubmitFormMethod(
      dispatch,
      GenerateAdminEventsEventReportsActions,
      (s: ApplicationState) => s.adminEvents.events.modals.generateInvoices,
      undefined,
      undefined,
      true,
      true,
    );

    if (valid) {
      const GroupsCSV = !modalState.ActiveForm.IsAllRegisteredGroups && modalState.ActiveForm.SelectedGroups && modalState.ActiveForm.SelectedGroups.length > 0 ? modalState.ActiveForm.SelectedGroups.join(', ') : '';
      dispatch(appActionCreators.popModal(true, false, ModalTypes.GENERATE_INVOICES) as any);
      dispatch(new ResetGenerateInvoicesModal());
      const body = {
        path: 'ExportGroupInvoices',
        EventTypeID: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID,
        EventIDi: state.adminEvents.cacheTwoEvent.EventsEvent.IDi,
        BillEntireBalance: modalState.ActiveForm.BillEntireBalance,
        BillAmountPerYouth: modalState.ActiveForm.BillEntireBalance ? 0 : modalState.ActiveForm.BillAmountPerYouth,
        IncludeNoBalanceDue: modalState.ActiveForm.IncludeNoBalanceDue,
        DueDate: modalState.ActiveForm.DueDate ? modalState.ActiveForm.DueDate : null,
        InvoiceNotes: modalState.ActiveForm.InvoiceNotes,
        GroupsCSV,
        JustExportPDF: !!JustExportPDF,
      };
      if (JustExportPDF) dispatch(GenerateAdminEventsEventReportsActions.request(body, null));
      else dispatch(SendToClientsActions.request(body, getCacheLevelExtra(false, true)));
    } else {
      scrollModalToTop(namespace());
    }
  },
};