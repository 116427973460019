import { createRequestActions } from "../../Validation/actionCreator";
import { SaveState } from "../../Rollback/actions";
import { ActionCreator } from '../..';
import { typeName, Action } from "../../../utils/StrongActions";

export const SUFFIX = '__ADMIN_EVENTS_SHARED_CACHE';

export const GetRegisteredGroupsAction = createRequestActions('GET_REGISTERED_GROUPS' + SUFFIX);

@typeName("RESET_REGISTERED_GROUPS" + SUFFIX) export class ResetAdminEventsRegisteredGroup extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  getRegisteredGroups: (eventTypeId: number, eventId: number): ActionCreator => dispatch => {
    dispatch(new SaveState());
    dispatch(GetRegisteredGroupsAction.request({EventTypeID: eventTypeId, EventIDi: eventId}));
  },
  resetRegisteredGroups: (): ActionCreator => dispatch => dispatch(new ResetAdminEventsRegisteredGroup()),
};