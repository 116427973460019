import { SELECT_NOT_GREATER_THAN } from "@tentaroo/core";

import * as M from '../../../../../constants/messages/generic';
import { makeDeletedPrefixItemMapper } from "../../../../../utils/dataHelper";
import { SelectValidator, Validator } from "../../../../../utils/validator/models";
import { ApplicationState } from "../../../..";


export interface IValidator {
  ID: Validator;
  Name: Validator;
  CategoryID: Validator;
  Description: Validator;
  File: Validator;
  SiteID: SelectValidator;
}

const getValues = (rootState: ApplicationState) => {
  const sites = rootState.cacheZero && rootState.cacheZero.options && rootState.cacheZero.options.CMSSites;
  if (sites) return sites.filter((s) => {
    return !s.Inactive || (s.Inactive && s.ID === rootState.adminCMSSite.resources.resource.form.ActiveForm.SiteID);
  }).map(makeDeletedPrefixItemMapper('SiteDomain'));
  return [];
};

export const FormDefinition: IValidator = {
  ID: {
    key: 'ID',
    isRequired: () => false,
  },
  Name: {
    key: 'Name',
    isRequired: () => true,
    validatejs: {
      Name: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 200,
          tooLong: M.MAX_LENGTH(200)
        },
      },
    },
  },
  CategoryID: {
    key: 'CategoryID',
    isRequired: () => true,
    customValidate: (rootState) => {
      const form = rootState.adminCMSSite.resources.resource.form.ActiveForm;
      if (!form.CategoryID) {
        return M.INVALID_SELECTED_RESOURCE_CATEGORY;
      } else {
        const CMSResourceCategories = rootState.adminCMSSite.cacheOne.CMSResourceCategories;
        const category = CMSResourceCategories && CMSResourceCategories.find((rc) => rc.ID === form.CategoryID);
        if (category && category.Inactive) {
          return M.INVALID_SELECTED_RESOURCE_CATEGORY;
        }
      }
      return;
    },
    validatejs: {
      CategoryID: {
        presence: {message: '^' + M.INVALID_SELECTED_RESOURCE_CATEGORY},
      },
    },
  },
  Description: {
    key: 'Description',
    isRequired: () => false,
    defaultValue: () => '',
    validatejs: {
      Description: {
        length: {
          maximum: 2048,
          tooLong: M.MAX_LENGTH(2048),
        },
      },
    }
  },
  File: {
    key: 'File',
  },
  SiteID: {
    key: 'SiteID',
    isRequired: (rootState) => {
      return !!rootState.adminCMSSite.cacheTwoResources.CMSResource;
    },
    options: {
      values: (rootState) => [...getValues(rootState)],
      valueKey: (v) => v.ID,
      labelKey: 'SiteDomain'
    },
    customValidate: (rootState) => {
      const SiteID = rootState.adminCMSSite.resources.resource.form.ActiveForm.SiteID;

      if (SiteID) {
        const site = rootState.cacheZero.options && rootState.cacheZero.options.CMSSites ? rootState.cacheZero.options.CMSSites.find((s) => s.ID === SiteID) : null;

        // if REQUIRED and INVALID
        if (!!rootState.adminCMSSite.cacheTwoResources.CMSResource && site && site.Inactive) {
          return M.SELECTED_DROPDOWN_ITEM_DELETED;
        }
      }
      
      return undefined;
    },
    defaultValue: (rootState) => {
      const owners = getValues(rootState);

      if (rootState.adminCMSSite.cacheOne.CMSSite) {
        return rootState.adminCMSSite.cacheOne.CMSSite.ID;
      }
      return owners.length > 0 ? owners[0].ID : -1;
    },
    validatejs: {
      SiteID: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          greaterThan: 0,
          notGreaterThan: SELECT_NOT_GREATER_THAN,
        },
      },
    },
  },
};