import {ActionCreator, ApplicationState} from '../../../../../';
import {
  createApiSubmitActions,
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions
} from '../../../../../Validation/actionCreator';
import {eventNumbersRecalcBody} from '../../../../../../constants/urls';
import {NumberProposed, PostEventRegistrationClass} from "../../../../../../models/api/cacheFourEvents";
import {EventRegisterNumbersSelectPage, EventRegisterNumbersSetPendingPage} from "../Main/actions";
import {SaveState} from "../../../../../Rollback/actions";
import {
  composeClassRegistrations,
  composeNumbersProposed,
  composeOrderedProducts
} from "../../../../../CacheFourEventsNumbers/actions";
import {NumbersErrors, validateAllNumbersPages} from "../../../../../CacheFourEventsNumbers/validationMaster";
import {BaseEndUserClass, RegisteredClass} from "../../../../../../models/class";
import { typeName, Action } from '../../../../../../utils/StrongActions';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../../../utils/dataHelper';

export const SUFFIX = '__NUMBERS_WIZARD_CLASSES';

// @todo: classes doesn't realy have a form....
export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ApiSubmitActionsNumbersClasses = createApiSubmitActions(SUFFIX, true);

@typeName(`ADD_CLASS${SUFFIX}`) export class EventRegisterNumbersClassesAdd extends Action {
  constructor(public clazz: BaseEndUserClass) { super(); }
}

@typeName(`REMOVE_CLASS${SUFFIX}`) export class EventRegisterNumbersClassesRemove extends Action {
  constructor(public clazz: RegisteredClass) { super(); }
}

@typeName(`SHOW_ERROR${SUFFIX}`) export class EventRegisterNumbersClassesShowError extends Action {
  constructor(public error: string, public isError: boolean) { super(); }
}

@typeName(`CLEAR_ERROR${SUFFIX}`) export class ClearError extends Action {}

@typeName(`CLEAR_WARNING${SUFFIX}`) export class ClearWarning extends Action {}


export type Actions = typeof actionCreators;
const formCreator = (rootState: ApplicationState) => {
  const options = rootState.cacheZero.options;
  const eventTypeID = rootState.cacheTwoEvents.eventTypeID;
  const eventID = rootState.cacheThreeEvents.eventID;
  const c3 = rootState.cacheThreeEvents;
  const c4 = rootState.cacheFourEventsNumbers;

  let debugPayload: TentarooDebugPayload | undefined;
  if (!options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("options not available when submitting Register Numbers Classes form");
  }
  if (!options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when submitting Register Numbers Classes form");
  }
  if (!c4.EventRegistrationNumbers) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("c4.EventRegistrationNumbers not available when submitting Register Numbers Classes form");
  }
  const numbersProposed: Array<NumberProposed> = composeNumbersProposed(rootState);
  const classRegistrations: Array<PostEventRegistrationClass> = composeClassRegistrations(c4.EventClassesGroupRegistered);
  const products = composeOrderedProducts(c4.EventProductsOrdered, c4.EventProductsOrdered_Updates);

  const payload = eventNumbersRecalcBody(
    {
      GroupIDi: options?.Group?.IDi as any,
      GroupTS: options?.Group?.TS as any,
      EventTypeID: eventTypeID,
      EventIDi: eventID,
      GroupWeekIDi: c4.EventRegistrationNumbers?.IDi as any,
      NumbersProposed: numbersProposed,
      ClassRegistrations: classRegistrations,
      ProductOrders: products,
      CampsiteDepositAmount: rootState.events.event.register.numbers.spots.ActiveForm.CampsiteDepositAmount,
      ParticipantTypes: !c3.EventInfoParticipantTypes ? [] : c3.EventInfoParticipantTypes.map((pType) => pType.ID),
    }
  );

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.events.event.register.numbers.classes),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  classesSubmit: (page: string, isNext: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());

    const err = validateAllNumbersPages();

    if (err.classes?.error) {
      dispatch(new EventRegisterNumbersClassesShowError(err.classes.warnings[0].message, err.classes.error));
      // if you have an error, and you're going next, then block it
      if (isNext) return;
    }

    if (!getState().events.event.register.numbers.classes.isDirty) {
      dispatch(new EventRegisterNumbersSelectPage(page as 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'confirmation'));
      return;
    }

    if (err.classes && err.classes.warnings.length > 0) {
      dispatch(new EventRegisterNumbersClassesShowError(err.classes.warnings[0].message, err.classes.error));
    }
    dispatch(new EventRegisterNumbersSetPendingPage(page as 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'confirmation'));
    dispatch(ApiSubmitActionsNumbersClasses.request(formCreator(getState()), null));
  },
  addClass: (clazz: BaseEndUserClass): ActionCreator => dispatch => {


    dispatch(new EventRegisterNumbersClassesAdd(clazz));
  },
  removeClass: (clazz: RegisteredClass): ActionCreator => dispatch => dispatch(new EventRegisterNumbersClassesRemove(clazz)),
  showError: (error: string, isError: boolean): ActionCreator => dispatch => dispatch(new EventRegisterNumbersClassesShowError(error, isError)),
  clearWarning: (): ActionCreator => dispatch => dispatch(new ClearWarning())
};

