
import { ToggleNotFound } from "../../store/App/actions";
import { SaveState } from "../../store/Rollback/actions";
import { extractRouteParams, isIdFromUrlValid } from "../urlHelper";
import { GetAdminEventProduct } from "../../store/AdminEvents/CacheTwoProduct/actions";
import { UpdateAdminEventProductSubmitActions } from "../../store/AdminEvents/Products/Form/actions";
import { getAdminEventProductIdFromPath } from "../helpers/adminEventsPageHelper";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import { reduxStoreService } from "../../store/service";
import type AdminEventsCacheManager from "../cacheManagers/adminEventsCacheManager";
import { LoadAdminEventCacheTwoProductParams } from "./helpers/models";
import {isAdminEventsCacheTwoProductPopulated} from "../cachePopulatedCheckers/adminEvents";

export const ensureAdminEventsCacheTwoProduct = (
  params: LoadAdminEventCacheTwoProductParams & {cacheManager: AdminEventsCacheManager}
): boolean => {
  const {props, isEdit, cacheManager, isStateNavigated} = params;

  if (!isIdFromUrlValid(props.params.eventTypeId)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const rootState = reduxStoreService().getState();
  const adminEventsCacheTwoProduct = rootState.adminEvents.cacheTwoProduct;

  let requestedData = false;
  if (isEdit) {
    if (!isIdFromUrlValid(props.params.productId)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }

    if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminEventsCacheTwoProductPopulated(adminEventsCacheTwoProduct)) {
      if (isStateNavigated) {
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      const productId = getAdminEventProductIdFromPath(props.params);
      
      reduxStoreService().dispatch(GetAdminEventProduct.request({
        ProductIDi: productId,
        ...extractRouteParams(props),
      }));

      requestedData = true;
    }
  } else if (isStateNavigated) {
    requestedData = cacheManager.loadAdminEventsCacheOne({
      props,
      isStateNavigated,
    });

    if (!requestedData) {
      reduxStoreService().dispatch(UpdateAdminEventProductSubmitActions.updateForm());
      reduxStoreService().dispatch(new SaveState());
    }
  }

  return requestedData;
};