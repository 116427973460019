import * as React from 'react';
import {Loader, Modal, ModalContent, ModalTitle, Paragraph} from '../../../Elements';
import {WithInertAttribute} from '../../../Elements/WithInert';

type Props = WithInertAttribute<{}>;
class SubmittingPayment extends React.Component<Props, {}> {
  public render() {
    return (
      <Modal inert={this.props.inert} disableDynamicHeight big>
        <ModalTitle center>
          <Loader marginBottom={10}/>
          <span>Submitting Your Order</span>
        </ModalTitle>
        <ModalContent center paddingTop={0} neverLoading>
          <Paragraph color="gray">
            Please DO NOT close or reload this page until this process is finished.
          </Paragraph>
          <Paragraph color="gray">
            This could take a minute.
          </Paragraph>
        </ModalContent>
      </Modal>
    );
  }
}

export default SubmittingPayment;
