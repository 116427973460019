import * as React from 'react';
import {CartIcon, EventsIcon} from '../../../Icons';
import {Main, MainContent} from '../../../Layouts';
import {
  Alert,
  AlertActions,
  Button,
  ContentBlock,
  EmptyMessage,
  EmptyMessageAction,
  Link,
  Media
} from '../../../Elements';
import RightSidebar from '../RightSidebar';
import Contact from '../Contact';
import Event from './Event';
import '../../../../styles/pages/home/my-events/index.scss';
import {HomepageMyEvent} from "../../../../models/api/cacheOne";
import {RouteComponentProps, withRouter} from "react-router";
import {navPush} from "../../../../utils/navHelper";
import {EVENT, URLS} from "../../../../constants/urls";
import {makeMyEventsSelector} from '../../../../store/Home';
import {bindActionCreators} from 'redux';
import {actionCreators} from "../../../../store/Home/actions";
import {actionCreators as cacheOneActionCreators, GetGroupCache} from "../../../../store/CacheOne/actions";
import {
  actionCreators as cacheZeroActionCreators,
} from "../../../../store/CacheZero/actions";
import {spaceTo_} from "../../../../utils/stringHelper";
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';
import { WithInertAttribute } from '../../../Elements/WithInert';

const namespace = (): string => 'pages--home--my-events';

type Props = WithInertAttribute<{}>;
type ConnectedProps = 
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {}>;

@(withRouter as any)
class MyEvents extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  toEventTypes = () => navPush(this.props.router, URLS.EVENT_TYPES);
  onCheckout = () => navPush(this.props.router, URLS.CHECKOUT);

  getMyEventLink = (event:HomepageMyEvent) => {
    return `${URLS.EVENTS}/${event.EventTypeID}/${event.WeekIDi}/${spaceTo_(event.Name)}/${EVENT.REGISTRATION}`;
  };

  public render() {
    const {filteredEvents, inert, apiLoadingMap, cacheOne: {HomepageMyEvents}, router} = this.props;
    let emptyMessage;
    if (filteredEvents.length === 0) {
      if (HomepageMyEvents === null || (HomepageMyEvents && HomepageMyEvents.length === 0)) {
        emptyMessage = 'You have no upcoming event registrations.';
      } else {
        emptyMessage = 'No event registrations found.';
      }
    }
    let showAlert = false;
    if (HomepageMyEvents) {
      const anyInCart = HomepageMyEvents.find((event:HomepageMyEvent) => event.InCart !== 0);
      if (anyInCart) showAlert = true;
    }
    return (
      <Main inert={inert} isLoading={apiLoadingMap[GetGroupCache.requestType]} hideEarly rightSidebar={<RightSidebar/>}>
        <MainContent handleCompact>
          <ContentBlock>
            {showAlert &&
            <Alert
              actions={(
                  <AlertActions>
                    <Button flat textColor="white" icon={CartIcon} onClick={this.onCheckout}>Go to checkout</Button>
                  </AlertActions>
                )}
            >
              There are changes or payments for at least one of your events currently in the cart. Please remember to checkout soon to reserve your spots.
            </Alert>
            }
            {emptyMessage && <EmptyMessage
              icon={EventsIcon}
              description={emptyMessage}
              actions={(
                <EmptyMessageAction onClick={this.toEventTypes}>
                  Go to events
                </EmptyMessageAction>
              )}
            />}
            {filteredEvents.map((event:HomepageMyEvent, index) => (
              <Event
                key={index}
                onClick={() => navPush(router, this.getMyEventLink(event))}
                dateStart={event.StartDateTime}
                dateEnd={event.EndDateTime}
                name={event.Name}
                details={`${event.EventType} @ ${event.Location.Name}`}
                total={event.TotalAmount}
                balance={event.Balance}
                cart={event.InCart !== 0}
                ticketsNames={event.HasNamesRegistration ? event.TotalNames : undefined}
                ticketsNumbers={event.HasNumbersRegistration ? event.TotalNumbers : undefined}
                warning={event.RegistrationStatus}
              />
            ))}
          </ContentBlock>
          {!emptyMessage && <ContentBlock marginLeft={36} mobileMarginLeft={16}>
            <Link onClick={this.toEventTypes} uppercase size={14} medium>
              Go to events
            </Link>
          </ContentBlock>}
          <Media className={`${namespace()}--contact-container`} largeDesktopAndSmaller expand={false}>
            <Contact forceTablet/>
          </Media>
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredEvents = makeMyEventsSelector();
  return {
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    filteredEvents: filteredEvents(state)
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheOneActionCreators,
    ...cacheZeroActionCreators,
  }, dispatch)
});

const ConnectedMyEvents = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(MyEvents);

export default ConnectedMyEvents;
