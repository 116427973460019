import * as React from 'react';
import '../../../../styles/pages/events/event-types/index.scss';
import {Main, MainContent} from '../../../Layouts';
import {EmptyMessage, LoadingAll, Location, Tab, Tabs} from '../../../Elements';
import {RouteComponentProps, withRouter, WithRouterProps} from 'react-router';
import '../../../../styles/pages/home/my-trips/index.scss';
import {
  actionCreators as cacheZeroActionCreators,
} from "../../../../store/CacheZero/actions";
import {
  makeAvailableSelector,
  makeCurrentSelector,
  makeNoFilterAvailableSelector,
  makeNoFilterCurrentSelector,
  makeNoFilterPastSelector,
  makeNoFilterUpcomingSelector,
  makePastSelector,
  makeUpcomingSelector
} from "../../../../store/Events/EventTypes/index";
import {actionCreators} from "../../../../store/Events/EventTypes/actions";
import {actionCreators as appActionCreators} from "../../../../store/App/actions";
import {EventType} from "../../../../models/api/cacheOne";
import {bindActionCreators} from 'redux';
import {createInitials, getImagePath, isImageValid, navPush, spaceTo_} from '../../../../utils';
import {EventsIcon} from '../../../Icons';
import {URLS} from '../../../../constants/urls';
import {
  actionCreators as cacheOneActionCreators,
} from "../../../../store/CacheOne/actions";
import { ApplicationState } from '../../../../store';
import { generateDOMId } from '../../../../utils/cypressHelper';
import EndUserCacheManager from '../../../../utils/cacheManagers/endUserCacheManager';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';
import { SaveState } from '../../../../store/Rollback/actions';
import { reduxStoreService } from '../../../../store/service';
import { ComponentUpdateTemplate } from '../../../Templates/ComponentUpdateTemplate';
import { CacheOneContext } from '@tentaroo/shared';
import {isEndUserCacheOnePopulated} from '../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../Elements/WithInert';

const namespace = (): string => 'pages--events--event-types';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{},{}>
>;

class EventTypes extends ComponentUpdateTemplate<ConnectedProps> {
  public componentDidMount() {
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheOne({
          props: this.props,
          isStateNavigated,
          context: CacheOneContext.EVENT_TYPES,
        });
      }
    );
  }

  public componentWillReceiveProps(nextProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheOne({
          props: nextProps,
          isStateNavigated,
          context: CacheOneContext.EVENT_TYPES,
        });
      },
    );
  }

  public componentWillUnmount() {
  }

  onClickAvailable = () => this.props.actions.selectTab('available');
  onClickUpcoming = () => this.props.actions.selectTab('upcoming');
  onClickCurrent = () => this.props.actions.selectTab('current');
  onClickPast = () => this.props.actions.selectTab('past');

  getEventTypeInfo = (eventType: EventType) => {
    if (eventType.DefaultLocation.UpcomingEventsAtOtherLocations) return 'Different Locations';
    return `${eventType.DefaultLocation.Name} (${eventType.DefaultLocation.City}, ${eventType.DefaultLocation.State})`;
  };

  onClickEventType = (eventType: EventType) => {
    const {router} = this.props;

    if (reduxStoreService().getState().app.apiLoading === 0) reduxStoreService().dispatch(new SaveState());
    navPush(router, `${URLS.EVENT_TYPES}/${eventType.ID}/${spaceTo_(eventType.Name)}`);
  };
  renderEventTypes() {
    const {eventTypes: {selectedTab}, availableEvents, upcomingEvents, currentEvents, pastEvents,
      allAvailableEvents, allUpcomingEvents, allCurrentEvents, allPastEvents, router} = this.props;
    let events: Array<EventType> = [];
    let allEvents: Array<EventType> = [];
    let emptyMessage = '';
    if (selectedTab === 'available') {
      events = availableEvents;
      allEvents = allAvailableEvents;
      emptyMessage = 'No events found that are currently available for registration.';
      if (allEvents.length === 0) emptyMessage = 'No events are currently available for registration.';
    } else if (selectedTab === 'upcoming') {
      events = upcomingEvents;
      allEvents = allUpcomingEvents;
      emptyMessage = 'No upcoming events found.';
      if (allEvents.length === 0) emptyMessage = 'No upcoming events.';
    } else if (selectedTab === 'current') {
      events = currentEvents;
      allEvents = allCurrentEvents;
      emptyMessage = 'No registrations found for current or future events.';
      if (allEvents.length === 0) emptyMessage = 'No registrations for current or future events.';
    } else if (selectedTab === 'past') {
      events = pastEvents;
      allEvents = allPastEvents;
      emptyMessage = 'No registrations found for past events.';
      if (allEvents.length === 0) emptyMessage = 'No registrations for past events.';
    }

    if (events.length > 0) {
      return events.map((eventType: EventType) => <Location
        color={`#${eventType.Color}`}
        name={`${eventType.Name}`}
        info={this.getEventTypeInfo(eventType)}
        initials={createInitials(eventType.Name)}
        cart={eventType.InCart !== 0}
        onClick={() => this.onClickEventType(eventType)}
        image={isImageValid(eventType.FeaturedImage) && eventType.FeaturedImage ? getImagePath(eventType.FeaturedImage) : undefined}
        numEventsAvailable={selectedTab === 'past' ? undefined : eventType.NumEventsAvailable}
        numEventsRegistered={selectedTab === 'past' ? eventType.NumPastRegistrations : eventType.NumCurrentRegistrations}
        registrationStatus={selectedTab === 'past' ? undefined : eventType.RegistrationStatus}
        isPastTab={selectedTab === 'past' ? true : undefined}
        id={generateDOMId(`event-type-${eventType.ID}`)}
      />);
    } else {
      return <EmptyMessage icon={EventsIcon} description={emptyMessage}/>;
    }
  }

  render() {
    const {selectedTab} = this.props.eventTypes;
    const {cacheOne, inert} = this.props;

    if (!isEndUserCacheOnePopulated(cacheOne) || this.props.cacheOne.EventEventTypes === undefined) return <LoadingAll/>;

    return (
      <Main inert={inert}>
        <MainContent handleCompact>
          <Tabs>
            <Tab selected={selectedTab === 'available'} onClick={this.onClickAvailable}>AVAILABLE EVENTS</Tab>
            <Tab selected={selectedTab === 'upcoming'} onClick={this.onClickUpcoming}>ALL UPCOMING</Tab>
            <Tab selected={selectedTab === 'current'} onClick={this.onClickCurrent}>MY EVENTS</Tab>
            <Tab selected={selectedTab === 'past'} onClick={this.onClickPast}>MY PAST EVENTS</Tab>
          </Tabs>
          {this.renderEventTypes()}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const availableEvents = makeAvailableSelector();
  const upcomingEvents = makeUpcomingSelector();
  const currentEvents = makeCurrentSelector();
  const pastEvents = makePastSelector();
  const allAvailableEvents = makeNoFilterAvailableSelector();
  const allUpcomingEvents = makeNoFilterUpcomingSelector();
  const allCurrentEvents = makeNoFilterCurrentSelector();
  const allPastEvents = makeNoFilterPastSelector();
  return {
    eventTypes: state.events.eventTypes,
    availableEvents: availableEvents(state),
    upcomingEvents: upcomingEvents(state),
    currentEvents: currentEvents(state),
    pastEvents: pastEvents(state),
    allAvailableEvents: allAvailableEvents(state),
    allUpcomingEvents: allUpcomingEvents(state),
    allCurrentEvents: allCurrentEvents(state),
    allPastEvents: allPastEvents(state),
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    apiLoading: state.app.apiLoading,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...cacheOneActionCreators,
  ...cacheZeroActionCreators,
  ...appActionCreators,
}, dispatch) });

const ConnectedEventTypes = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(EventTypes);

export default withRouter<{}>(ConnectedEventTypes);

export { default as Header } from './Header';
