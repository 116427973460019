import * as Actions from './actions';
import {FacilityLocation_Trip} from "../../../models/api/cacheTwoFacilties";
import {makeNoFilterSelector, makeSimpleSelector} from "../../../utils";
import {listSelector} from "../../../utils/reselectHelper";
import {GetFacilityLocation} from "../../CacheTwoFacilities/actions";
import {APIFailure, APISuccess} from "../../Validation/actionCreator";
import * as moment from 'moment';
import {GetFacilityAvailabilitiesB} from "../../CacheTwoBFacilities/actions";
import {API_DATE_FORMAT, getPrevAndNext} from "../../../utils/dateHelper";
import {ClearAllCache, ClearAllEndUserCacheButOptions, SetCacheAction} from "../../App/actions";
import {ClearCacheBelowOne} from "../../CacheOne/actions";
import {GetFacilityTripData} from "../../CacheThreeFacilities/actions";
import { Reducer } from 'redux';
import { isActionType } from '../../../utils/StrongActions';

const filterSelector = state => state.app.searchFilter;
const filterFnc = (t: FacilityLocation_Trip, f: string) => t.Name.toLowerCase().includes(f);
const tripsSelector = listSelector(state => state.cacheTwoFacilities.FacilityLocation_Trips);

export const makeCurrentTripSelector = makeSimpleSelector(
  (t: FacilityLocation_Trip) => t.IsTripUpcoming,
  filterFnc,
  tripsSelector,
  filterSelector
);

export const makePastTripSelector = makeSimpleSelector(
  (t: FacilityLocation_Trip) => !t.IsTripUpcoming,
  filterFnc,
  tripsSelector,
  filterSelector
);

export const makeNoFilterCurrentTripSelector = makeNoFilterSelector((t: FacilityLocation_Trip) => t.IsTripUpcoming, tripsSelector);
export const makeNoFilterPastTripSelector = makeNoFilterSelector((t: FacilityLocation_Trip) => !t.IsTripUpcoming, tripsSelector);


export interface FacilityLocationState {
  showMapMobile?: boolean;
  showSidebar?: boolean;
  selectedFacilityTypeTab?: number;
  selectedTripTab: string; /// 'current' | 'past'; having weird typescript problem
  startDate: moment.Moment;
  showContextMenu: boolean;
  disableNext: boolean;
  disablePrev: boolean;
  view: string; // grid or compact, TS don't let me put ViewControlsOptions, but it should go here instead of string
}

const getInitialState = () => {
  return {
    selectedTripTab: 'current',
    startDate: moment().startOf('month'),
    disablePrev: true,
    disableNext: false,
    showContextMenu: false,
    view: 'grid'
  };
};

const FacilityLocationReducer: Reducer<FacilityLocationState> = (state: FacilityLocationState, action: any) => {
  if (action.type === GetFacilityLocation.successType || action.type === GetFacilityAvailabilitiesB.successType || action.type === GetFacilityTripData.successType) {
    const successAction = <APISuccess> action;
    if (successAction.response.response.FacilityLocation_SelectedFacilityTypeID) {
      const FacilitiesAvailabilitiesDates = successAction.response.response.FacilitiesAvailabilitiesDates;
      const startDate = moment(FacilitiesAvailabilitiesDates[0].Date, API_DATE_FORMAT).startOf('month');
      const prevAndNext = getPrevAndNext(startDate);
      return {
        ...state,
        selectedFacilityTypeTab: successAction.response.response.FacilityLocation_SelectedFacilityTypeID,
        startDate: startDate,
        disablePrev: prevAndNext.disablePrev,
        disableNext: prevAndNext.disableNext,
      };
    }
    return state;
  } else if (isActionType(action, SetCacheAction)) {
    const successAction = <APIFailure> action;
    if (successAction.response.xhr.response.FacilityLocation_SelectedFacilityTypeID) {
      const FacilitiesAvailabilitiesDates = successAction.response.xhr.response.FacilitiesAvailabilitiesDates;
      const startDate = moment(FacilitiesAvailabilitiesDates[0].Date, API_DATE_FORMAT).startOf('month');
      const prevAndNext = getPrevAndNext(startDate);
      return {
        ...state,
        selectedFacilityTypeTab: successAction.response.xhr.response.FacilityLocation_SelectedFacilityTypeID,
        startDate: startDate,
        disablePrev: prevAndNext.disablePrev,
        disableNext: prevAndNext.disableNext,
      };
    }
    return state;
  } else if (isActionType(action, Actions.FacilitiesLocationToggleMapMobile)) {
    return {...state, showMapMobile: !state.showMapMobile};
  } else if (isActionType(action, Actions.FacilitiesLocationShowSidebar)) {
    return {...state, showSidebar: action.show};
  } else if (isActionType(action, Actions.SelectFacilityTypeTab)) {
    return {...state, selectedFacilityTypeTab: action.typeID};
  } else if (isActionType(action, Actions.SetView)) {
    return {...state, view: action.view};
  } else if (isActionType(action, Actions.SelectTripTab)) {
    return {...state, selectedTripTab: action.tripType as 'current' | 'past'};
  } else if (isActionType(action, Actions.ChangeDate)) {
    const newState = {...state, startDate: action.date};
    let prevAndNext = getPrevAndNext(action.date);
    newState.disablePrev = prevAndNext.disablePrev;
    newState.disableNext = prevAndNext.disableNext;
    return newState;
  } else if (isActionType(action, Actions.ShowMonthMenu)) {
    return {...state, showContextMenu: action.show};
  } else if (isActionType(action, ClearAllCache) || isActionType(action, ClearAllEndUserCacheButOptions) || isActionType(action, ClearCacheBelowOne)
  ) {
    return {...getInitialState()};
  }
  return state || {...getInitialState()};
};

export default FacilityLocationReducer;
