import * as React from 'react';

import AddedTemplate from "../../Added";
import AddedTemplateTabs from "../../Added/Tabs";
import AddedTemplateShowSideBar from "../../Added/ShowSideBar";

import { Media, EmptyMessage, Button, Tab } from '../../../Elements';
import { PopcornIcon, ClassTypesIcon, AddedIcon } from '../../../Icons';
import { withRouter, RouteComponentProps } from 'react-router';
import {RegisteredClass} from '../../../../models/class';
import { WithInertAttribute } from '../../../Elements/WithInert';

type ConnectedProps = RouteComponentProps<{}, {}>;

export type Props = WithInertAttribute<{
  addedItems: Array<RegisteredClass>
  children?: React.ReactNode;
  footer?: React.ReactElement<any>;
  header?: React.ReactElement<any>;
  sideBar?: React.ReactElement<any>;
  options?: boolean;
  // showEmpty?: boolean;
  emptyMessage?: string;
  preEmptyChildren?: React.ReactElement<any> | Array<React.ReactElement<any>>;
  loading?: boolean;
  classesString?: string;
}>;

const ClassesTemplate: React.SFC<Props> = (
  { classesString, loading, children, footer, header, sideBar, options, emptyMessage, preEmptyChildren, inert, addedItems }: Props & ConnectedProps
): React.ReactElement<Props> => {
  return (
    <AddedTemplate
      inert={inert}
      addedItems={addedItems}
      footer={footer}
      header={header}
      sideBar={sideBar}
      loading={loading}
      tabs={(
        <AddedTemplateTabs>
          <Tab className="no-overflow" selected={!options}>
            {classesString ? classesString : 'Classes'}
          </Tab>
        </AddedTemplateTabs>
      )}
      preEmptyChildren={preEmptyChildren}
      empty={
        <EmptyMessage
          icon={options ? PopcornIcon : ClassTypesIcon}
          description={emptyMessage ? emptyMessage : ''}
          actions={[
            <Media tablet key="tablet">
              <AddedTemplateShowSideBar>
                <Button color="green" big icon={<AddedIcon style={{ width: '21px', height: '14px' }}/>}>
                  SHOW ADDED
                </Button>
              </AddedTemplateShowSideBar>
            </Media>,
            <Media mobile key="mobile">
              <AddedTemplateShowSideBar>
                <Button color="green" big icon={<AddedIcon style={{ width: '21px', height: '14px' }}/>}>
                  SHOW ADDED
                </Button>
              </AddedTemplateShowSideBar>
            </Media>
          ]}
        />
      }
      showEmpty={!!emptyMessage}
    >
      {children}
    </AddedTemplate>
  );
};
export default withRouter<Props>(ClassesTemplate);
export { AddedTemplateContent as ProductsFormTemplateContent } from '../../index';
export { AddedTemplateSideBar as ProductsFormTemplateSideBar } from '../../index';
