import * as React from 'react';
import '../../../styles/elements/circle-icon/index.scss';

export const namespace = (): string => 'elements--circle-icon';

export interface CircleIconProps {
  initials?: string;
  icon?: string;
  color?: string;
}

const CircleIcon: React.SFC<CircleIconProps> = ({ icon, color, initials }: CircleIconProps): React.ReactElement<CircleIconProps> => {
  const style: React.CSSProperties = {};
  if (icon) style.backgroundImage = `url("${icon}")`;
  else if (color) style.backgroundColor = color;

  return (
    <div style={style} className={namespace()}>{initials}</div>
  );
};

export default CircleIcon;
