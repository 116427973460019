import { ApiSubmitActions, ApiValidateActions } from './actions';
import { updateGroup } from '../../constants/urls';
import {makeBackendValidateCancelEpic, makeBackendValidateEpic, makeRequestEpic} from '../Validation/epicCreator';
import { FormDefinition } from './validation';
import { URLS, QP_ACCOUNT } from '../../constants/urls';
import {AjaxResponse} from "rxjs/ajax";

export const addGroupUsernameBackendValidate = makeBackendValidateEpic(FormDefinition.Username.key, ApiValidateActions);
export const addGroupUsernameBackendValidateCancel = makeBackendValidateCancelEpic(FormDefinition.Username.key, ApiValidateActions, FormDefinition.Username);

export const addGroupSubmitForm = makeRequestEpic(
  ApiSubmitActions,
  updateGroup,
  undefined,
  true,
  'The group was successfully created',
  (payload: AjaxResponse) => `${URLS.PROFILE}?${QP_ACCOUNT}=${payload.response.Group.IDi}`
);
