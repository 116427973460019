import * as React from 'react';
import '../../../../styles/app/nav/compact/index.scss';
import { WithInertAttribute, WithInertPage } from '../../../Elements/WithInert';

const namespace = (): string => 'app--compact-nav-container';

interface Props {
  isSuper?: boolean;
  children?: React.ReactNode;
  isCMSSitePage?: boolean;
  isAdminFacilityLocationPage?: boolean;
  isAdminEventsPage?: boolean;
}

type ConnectedProps = WithInertAttribute<{}>;

class CompactNavContainer extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  public render() {
    const { inert, children, isSuper, isCMSSitePage, isAdminFacilityLocationPage, isAdminEventsPage } = this.props;

    return (
      <div inert={inert} className={`${namespace()} ${isSuper ? 'super' : 'compact' } ${isCMSSitePage ? 'cms-site' : ''} ${isAdminFacilityLocationPage ? 'admin-facility-location' : ''} ${isAdminEventsPage ? 'admin-events' : ''}`}>
        {children}
      </div>
    );
  }
}

export default WithInertPage<Props>(CompactNavContainer);
