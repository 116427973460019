import { makeRequestEpic } from "../../../store/Validation/epicCreator";
import { ReportGroupTripsActions, ReportGroupReservationsActions } from './actions';
import { getFacilitiesGroupTripsReportUrl, getFacilitiesGroupTripsBody, getFacilitiesGroupReservationsReportUrl, getFacilitiesGroupReservationsBody } from "../../../constants/urls";
import { MINUTE } from "../../../utils/ajaxHelper";

export const reportGroupTripsEpic = makeRequestEpic(
  ReportGroupTripsActions,
  getFacilitiesGroupTripsReportUrl,
  getFacilitiesGroupTripsBody,
  true,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  5 * MINUTE,
);

export const reportGroupReservationsEpic = makeRequestEpic(
  ReportGroupReservationsActions,
  getFacilitiesGroupReservationsReportUrl,
  getFacilitiesGroupReservationsBody,
  true,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  5 * MINUTE,
);