import * as M from '../../../../../constants/messages/generic';
import { getTimePickerHourValidationRule, getTimePickerMinValidationRule, getTimePickerPeriodValidationRule } from "../../../../AdminCMSSite/Pages/Modals/NewPage/validation";
import { isJustNames, isJustNumbers, isUsingNumbers } from "../../../EventTypes/EventType/Form/uiHelpers";
import { Validator, SelectValidator } from '../../../../../utils/validator/models';

export interface IValidator {
  Name: Validator;
  IsAllDay: Validator;

  StartDate: Validator;
  EndDate: Validator;
  StartHour: SelectValidator;
  StartMin: SelectValidator;
  StartPeriod: SelectValidator;
  EndHour: SelectValidator;
  EndMin: SelectValidator;
  EndPeriod: SelectValidator;

  RegistrationStartDateNumbers: Validator;
  NumbersRegistrationStartHour: SelectValidator;
  NumbersRegistrationStartMin: SelectValidator;
  NumbersRegistrationStartPeriod: SelectValidator;

  RegistrationStartDateNames: Validator;
  NamesRegistrationStartHour: SelectValidator;
  NamesRegistrationStartMin: SelectValidator;
  NamesRegistrationStartPeriod: SelectValidator;

  GLAccountID: Validator;
  AllowReductionsUntilDate: Validator;

  YouthDepositDueDate: Validator;
  YouthDepositAmount: Validator;
  CampsiteDepositDefaultAmount: Validator;

  BasePricingStartDate: Validator;
  LatePricingStartDate: Validator;
}

export const FormDefinition: IValidator = {
  Name: {
    key: 'Name',
    isRequired: () => true,
    defaultValue: () => '',
    validatejs: {
      Name: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 50,
          tooLong: M.MAX_LENGTH(50),
        },
      },
    },
  },
  IsAllDay: {
    key: 'IsAllDay',
    defaultValue: () => false,
  },
  StartDate: {
    key: 'StartDate',
    isRequired: () => true,
    validatejs: {
      StartDate: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          notValid: `^${M.INVALID_DATE}`,
        }
      },
    },
  },
  EndDate: {
    key: 'EndDate',
    isRequired: () => true,
    validatejs: {
      EndDate: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          notValid: `^${M.INVALID_DATE}`,
        }
      },
    },
  },
  StartHour: {
    ...getTimePickerHourValidationRule('StartHour'),
    defaultValue: () => 9,
  },
  StartMin: getTimePickerMinValidationRule('StartMin'),
  StartPeriod: {
    ...getTimePickerPeriodValidationRule('StartPeriod'),
  },
  EndHour: {
    ...getTimePickerHourValidationRule('EndHour'),
    defaultValue: () => 9,
  },
  EndMin: getTimePickerMinValidationRule('EndMin'),
  EndPeriod: {
    ...getTimePickerPeriodValidationRule('EndPeriod'),
  },

  RegistrationStartDateNumbers: {
    key: 'RegistrationStartDateNumbers',
    isRequired: (rootState) => !isJustNames(rootState),
    skip: (rootState) => isJustNames(rootState),
    validatejs: {
      RegistrationStartDateNumbers: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          notValid: `^${M.INVALID_DATE}`,
        }
      },
    },
  },
  NumbersRegistrationStartHour: {
    ...getTimePickerHourValidationRule('NumbersRegistrationStartHour'),
    defaultValue: () => 9,
  },
  NumbersRegistrationStartMin: {
    ...getTimePickerMinValidationRule('NumbersRegistrationStartMin'),
  },
  NumbersRegistrationStartPeriod: {
    ...getTimePickerPeriodValidationRule('NumbersRegistrationStartPeriod'),
  },
  RegistrationStartDateNames: {
    key: 'RegistrationStartDateNames',
    isRequired: (rootState) => !isJustNumbers(rootState),
    skip: (rootState) => isJustNumbers(rootState),
    validatejs: {
      RegistrationStartDateNames: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          notValid: `^${M.INVALID_DATE}`,
        }
      },
    },
  },
  NamesRegistrationStartHour: {
    ...getTimePickerHourValidationRule('NamesRegistrationStartHour'),
    defaultValue: () => 9,
  },
  NamesRegistrationStartMin: {
    ...getTimePickerMinValidationRule('NamesRegistrationStartMin'),
  },
  NamesRegistrationStartPeriod: {
    ...getTimePickerPeriodValidationRule('NamesRegistrationStartPeriod'),
  },
  GLAccountID: {
    key: 'GLAccountID',
    customValidate: (rootState) => {
      const form = rootState.adminEvents.events.event.form.ActiveForm;
      if (!form.GLAccountID) {
        return '';
      } else {
        const GLAccounts = rootState.cacheZero.options ? rootState.cacheZero.options.GLAccounts : [];
        const GLAccount = GLAccounts.find((acc) => acc.ID === form.GLAccountID);
        if (!GLAccount || GLAccount.Inactive) {
          return M.INVALID_SELECTED_GL_ACCOUNT;
        }
      }
      return;
    },
    validatejs: {
      GLAccountID: {
      },
    },
  },
  AllowReductionsUntilDate: {
    key: 'AllowReductionsUntilDate',
    isRequired: () => false,
    validatejs: {
      AllowReductionsUntilDate: {
        datetime: {
          notValid: `^${M.INVALID_DATE}`,
        }
      },
    },
  },
  YouthDepositDueDate: {
    key: 'YouthDepositDueDate',
    isRequired: () => false,
    validatejs: {
      YouthDepositDueDate: {
        datetime: {
          notValid: `^${M.INVALID_DATE}`,
        }
      },
    },
  },
  YouthDepositAmount: {
    key: 'YouthDepositAmount',
    skip: (rootState) => {
      return !rootState.adminEvents.cacheTwoEvent.EventsEvent || !isUsingNumbers(rootState);
    },
    isRequired: () => true,
    decimalOnly: true,
    validatejs: {
      YouthDepositAmount: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
        },
      },
    },
  },
  CampsiteDepositDefaultAmount: {
    key: 'CampsiteDepositDefaultAmount',
    skip: (rootState) => {
      return !rootState.adminEvents.cacheTwoEvent.EventsEvent || !isUsingNumbers(rootState);
    },
    isRequired: () => true,
    decimalOnly: true,
    validatejs: {
      CampsiteDepositDefaultAmount: {
        presence: {message: '^' + M.REQUIRED},
        numericality: {
          notValid: `^${M.INVALID}`,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: M.MIN_VALUE(0),
        },
      },
    },
  },
  BasePricingStartDate: {
    key: 'BasePricingStartDate',
    isRequired: () => true,
    skip: (rootState) => {
      return !rootState.adminEvents.cacheTwoEvent.EventsEvent;
    },
    validatejs: {
      BasePricingStartDate: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          notValid: `^${M.INVALID_DATE}`,
        }
      },
    },
  },
  LatePricingStartDate: {
    key: 'LatePricingStartDate',
    isRequired: () => true,
    skip: (rootState) => {
      return !rootState.adminEvents.cacheTwoEvent.EventsEvent;
    },
    validatejs: {
      LatePricingStartDate: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          notValid: `^${M.INVALID_DATE}`,
        }
      },
    },
  },
};