
import * as M from '../constants/messages/generic';

export const KB = 1024;
export const MB = 1024 * KB;
export function stripFileExtension(fileName: string)
{
	const tokens = fileName.split(".");

	if (!tokens || tokens.length == 0) return null;

	const extension = tokens.splice(tokens.length - 1, 1);
	return {
		name: tokens.join("."),
		extension: extension[0].toLowerCase(),
	};
}

export const VALID_RESOURCE_TYPES = [
	'xls',
	'xlsx',
	'csv',
	'zip',
	'7zip',
	'doc',
	'docx',
	'rtf',
	'txt',
	'pdf',
	'ppt',
	'pptx',
	'jpeg',
	'jpg',
	'png',
	'gif',
	'tiff',
	'tif',
	'html',
	'htm',
	'avi',
	'mpg',
	'mp4',
	'mov',
	'mp3',
	'm4a',
	'wav',
];

export const getValidResourceTypesString = () => {
	const UPPERCASE = VALID_RESOURCE_TYPES.map((val) => `.${val.toUpperCase()}`);
	const LOWERCASE = VALID_RESOURCE_TYPES.map((val) => `.${val}`);

	return `${LOWERCASE.join(',')},${UPPERCASE.join(',')}`;
};

export const VALID_IMAGE_TYPES = [
	'jpg',
	'jpeg',
	'png',
	'gif',
	'tiff',
	'tif',
];

export const getValidFeatureImageTypeString = () => {
	const UPPERCASE = VALID_IMAGE_TYPES.map((val) => `.${val.toUpperCase()}`);
	const LOWERCASE = VALID_IMAGE_TYPES.map((val) => `.${val}`);

	return `${LOWERCASE.join(',')},${UPPERCASE.join(',')}`;
};

export const getValidPageGalleryImageTypesString = () => {
	const UPPERCASE = VALID_IMAGE_TYPES.map((val) => `.${val.toUpperCase()}`);
	const LOWERCASE = VALID_IMAGE_TYPES.map((val) => `.${val}`);

	return `${LOWERCASE.join(',')},${UPPERCASE.join(',')}`;
};

export const validateFileType = (type: string, validTypes: string[]) => {
	return validTypes.some((t) => t === type);
};

export const validateGalleryImage = (file, actions) => {
	let message;

	checkFileSize(
		file.name,
		file.size,
		10,
		(oversizeMessage) => {
			message = oversizeMessage;
		}
	);
	
	const result = stripFileExtension(file.name);
	if (result) {
		const validType = validateFileType(result.extension, VALID_IMAGE_TYPES);
		if (!validType) {
			message = M.UNSUPPORTED_FILE_TYPES(file.name, (file.size / MB).toFixed(2));
		}
	}

	if (message) {
		// @todo - refactor this to a more involved way
		actions.showTopFloatingAlert(message, false, 'orange');
		return false;
	}

	return true;
};

/**
 * Helper function to check if a file size is over the specified max size.
 * 
 * If oversize, will call the `onOversize` with the corresponding error message and return true. Otherwise returns false.
 */
export const checkFileSize = (
	fileName: string,
	fileSizeInBytes: number,
	maxFileSizeInMb: number,
	onOversize: (oversizeMessage: string) => void,
): boolean => {
	if (fileSizeInBytes > maxFileSizeInMb * MB) {
		onOversize(M.MAX_FILE_SIZE(fileName, (fileSizeInBytes / MB).toFixed(2), maxFileSizeInMb));

		return true;
	}

	return false;
};