import * as React from 'react';
import { Main, MainContent } from '../../../../../Layouts';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import Facility from './Facility';
import FinancialSummary from '../../../../Events/Event/Registration/FinancialSummary';
import {CacheZeroState} from "../../../../../../store/CacheZero";
import {Actions as CacheFourFacilitiesActions} from "../../../../../../store/CacheFourFacilities/actions";
import {CacheTwoFacilitiesState} from "../../../../../../store/CacheTwoFacilities";
import {CacheThreeFacilitiesState} from "../../../../../../store/CacheThreeFacilities";
import {CacheFourFacilitiesState} from "../../../../../../store/CacheFourFacilities";
import {API_DATE_FORMAT, FULL_DATE_FORMAT, TIME_FORMAT} from "../../../../../../utils/dateHelper";
import {actionCreators} from "../../../../../../store/Facilities/Trip/Reservation/Confirmation/actions";
import {bindActionCreators} from 'redux';
import {Alert} from "../../../../../Elements";
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

type Props = WithInertAttribute<{
  apiLoadingMap: any;
  parentActions: CacheFourFacilitiesActions;
  cacheZero: CacheZeroState;
  cacheTwoFacilities: CacheTwoFacilitiesState;
  cacheThreeFacilities: CacheThreeFacilitiesState;
  cacheFourFacilities: CacheFourFacilitiesState;
}>

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
class Confirmation extends React.Component<Props, {}> {
  props: Props & ConnectedProps;
  public render() {
    const {
      inert,
      confirmation: {ActiveForm: {selectedFinance, otherValue}, ValidationRules, SubmitErrorMessage},
      cacheFourFacilities:{FacilityAvailabilities, Reservation, TripPaymentStatus}, actions, user
    } = this.props;

    if (!FacilityAvailabilities || !Reservation || !TripPaymentStatus) return null;

    const CheckinTime = Reservation.StartDateTime?.format(FULL_DATE_FORMAT);
    const CheckoutTime = Reservation.EndDateTime?.format(FULL_DATE_FORMAT);

    const fees: Array<{label: string; value: number; subLabel?: string;}> = [];

    if (TripPaymentStatus && TripPaymentStatus.ReservationTotalsByType) {
      TripPaymentStatus.ReservationTotalsByType.forEach((r) => {
        const fee: {label: string; value: number; subLabel?: string;} = {
          label: r.Name,
          value: r.Amount
        };
        if (r.Name === Reservation.Type.Name) {
          fee.subLabel = '(including current reservation)';
        }
        fees.push(fee);
      });
    }

    return (
      <Main
        inert={inert}
        mobileBackground="white"
        footer={<FooterProgress selected="confirmation"/>}
      >
        <MainContent
          header={<HeaderProgress selected="confirmation"/>}
          noMobileMarginBottom
        >
          {SubmitErrorMessage && <Alert noFlex>{SubmitErrorMessage}</Alert>}
          <Facility
            title={FacilityAvailabilities.Name}
            selectedFacilityType={Reservation.Type}
            Amount={Reservation.Amount}
            CheckinTime={CheckinTime}
            CheckoutTime={CheckoutTime}
            showCart={false}
            NumYouth={Reservation.NumYouth}
            NumAdults={Reservation.NumAdults}
            hideManage
          />
          <FinancialSummary
            title="Total Trip Fees"
            fees={fees}
            paymentStatus={TripPaymentStatus}
            showLoaders={false}
            selected={selectedFinance as 'min' | 'full' | 'other' | 'credit'}
            isFinanceDirty={false}
            onSelect={actions.updateValue}
            updateValue={actions.updateValue}
            simpleUpdate={actions.simpleUpdate}
            validationRules={ValidationRules}
            isSaving={false}
            otherValue={otherValue}
            IsAdmin={user.user ? user.user.str_permissions.hasAdminAccess : false}
            isWizard
            hasAccess
            hasAccessMessage=""
          />

        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    confirmation: state.facilities.trip.reservation.confirmation,
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators
  }, dispatch)
});

const ConnectedConfirmation = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(Confirmation);

export default ConnectedConfirmation;
