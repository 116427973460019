import * as React from 'react';
import {Main, MainContent} from '../../../../../../Layouts';
import {
  Alert,
  FinancialSummary as OldFinancialSummary,
  FinancialSummaryLine,
  FinancialSummaryTotal,
  Text,
  View,
} from '../../../../../../Elements';
import FinancialSummary from '../../../Registration/FinancialSummary';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import {CacheTwoEventsState} from "../../../../../../../store/CacheTwoEvents/index";
import {
  CacheFourEventsParticipantsState,
  makeAvailableClassesNoTextFilter,
  makeConflictingClassesNoTextFilter,
  makeRegisteredClassesFilter,
  makeSelectedParticipantTypeSelector
} from "../../../../../../../store/CacheFourEventsParticipants";
import {EventTypeRegistrationSettings} from "../../../../../../../models/api/cacheTwoEvents";
import {
  actionCreators,
} from "../../../../../../../store/Events/Event/Register/Participant/Confirmation/actions";
import {
  actionCreators as cacheFourActionCreators,
} from '../../../../../../../store/CacheFourEventsParticipants/actions';
import {bindActionCreators} from "redux";
import {
  EventRegistrationPerson
} from "../../../../../../../models/api/cacheFourEvents";
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import {navPush} from "../../../../../../../utils";
import {getEventRegistrationUrl} from "../../../../../../../constants/urls";
import {standardCurrencyFormat} from "../../../../../../../utils/classesHelper";
import {showClasses} from "../../../../../../../store/Events/Event/Register/Participant/Main/uiHelpers";
import "../../../../../../../styles/pages/events/event/register/participant/confirmation.scss";
import {CacheThreeEventsState, makeC3OrderedProductsFilter} from "../../../../../../../store/CacheThreeEvents";
import { ApplicationState } from '../../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

const namespace = () => 'pages--events--event--register--participant--confirmation';

type Props = WithInertAttribute<{
  cacheFourEventsParticipants: CacheFourEventsParticipantsState;
  cacheThreeEvents: CacheThreeEventsState;
  cacheTwoEvents: CacheTwoEventsState;
  apiSaving: number;
}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string, ya: string, pId: string, pName: string}>;

class ParticipantConfirmation extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  onDeleteBack = () => navPush(this.props.router, getEventRegistrationUrl(this.props));

  getAlertErrorMessage = (): string | undefined => {
    const {roster, classes, confirmation, noFilterAvailableClasses, noFilterRegisteredClasses, noFilterConflictingClasses} = this.props;

    if (roster.SubmitErrorMessage) return roster.SubmitErrorMessage;
    else if (classes.SubmitErrorMessage && classes.isError && showClasses(noFilterAvailableClasses, noFilterConflictingClasses, noFilterRegisteredClasses)) return classes.SubmitErrorMessage;
    else if (confirmation.SubmitErrorMessage) return confirmation.SubmitErrorMessage;
    else if (classes.SubmitErrorMessage && showClasses(noFilterAvailableClasses, noFilterConflictingClasses, noFilterRegisteredClasses)) return classes.SubmitErrorMessage;
    return undefined;
  };

  public render() {
    const {
      selectedPType, cacheTwoEvents, noFilterAvailableClasses, noFilterRegisteredClasses, noFilterConflictingClasses,
      cacheFourEventsParticipants, apiSaving, confirmation, actions, user, cacheThreeEvents,
      roster: {ActiveForm: {RegistrationNotes, FirstName, LastName, MI, Suffix}}, orderedProducts, inert
    } = this.props;
    const {eventType, EventTypeRegistrationSettings} = cacheTwoEvents;
    const {NamesRegistrationSettings: {ShowRegistrationNotesYouth, ShowRegistrationNotesAdult}} = EventTypeRegistrationSettings as EventTypeRegistrationSettings;
    const {EventRegistrationPaymentStatus} = cacheFourEventsParticipants;
    const { ActiveForm: { selectedFinance, otherValue }, ValidationRules } = confirmation;
    const EventRegistrationPerson = cacheFourEventsParticipants.EventRegistrationPerson as EventRegistrationPerson;
    const numClasses = noFilterRegisteredClasses.length;

    let warningMessage = this.getAlertErrorMessage();
    let showNotes = false;

    if (!EventRegistrationPaymentStatus) return null;

    if (RegistrationNotes) {
      if (cacheFourEventsParticipants.isYouth && ShowRegistrationNotesYouth) {
        showNotes = true;
      } else if (!cacheFourEventsParticipants.isYouth && ShowRegistrationNotesAdult) {
        showNotes = true;
      }
    }

    let classString;
    if (EventTypeRegistrationSettings) {
      classString = EventTypeRegistrationSettings.NamesRegistrationSettings.ShowParticipantClassesAs;
    }

    let summaryTotal: number = EventRegistrationPerson.ClassAmount;
    if (eventType === 'participant') summaryTotal += EventRegistrationPerson.AmountWithOptions;
    const fees: Array<{label: string; value: number;}> = [];
    fees.push({
      label: 'Registration Fees',
      value: EventRegistrationPaymentStatus.RegistrationTotal
    });
    fees.push({
      label: !!classString ? 'Options' : 'Classes',
      value: EventRegistrationPaymentStatus.ClassAmount
    });
    if (orderedProducts && orderedProducts.length > 0) {
      fees.push({
        label: 'Products',
        value: EventRegistrationPaymentStatus.ProductAmount
      });
    }

    return (
      <Main
        inert={inert}
        key="numbers"
        mobileBackground="white"
        header={<HeaderProgress
          selected="confirmation"
          cacheTwoEvents={cacheTwoEvents}
          cacheFourEventsParticipants={cacheFourEventsParticipants}
          loading={apiSaving > 0}
          availableClasses={noFilterAvailableClasses}
          conflictingClasses={noFilterConflictingClasses}
          registeredClasses={noFilterRegisteredClasses}
        />}
        footer={<FooterProgress
          selected="confirmation"
          availableClasses={noFilterAvailableClasses}
          conflictingClasses={noFilterConflictingClasses}
          registeredClasses={noFilterRegisteredClasses}
        />}
      >
        <MainContent loading={apiSaving > 0} noMobileMarginBottom>
          {warningMessage && <Alert noFlex>{warningMessage}</Alert>}
          {/* @todo: this OldFinancialSummary should be deleted and replaced with something else, or at least renamed... */}
          <OldFinancialSummary
            template="mobile-stretch"
            title={`${FirstName} ${MI ? MI : ''} ${LastName} ${Suffix ? Suffix : ''}`}
            subTitle={`${selectedPType.Name} ${cacheFourEventsParticipants.isYouth ? '(Y)' : '(A)'}`}
            subTitleMarginBottom={(eventType === 'participant' || numClasses > 0 ) ? 16 : 0}
            marginBottom={16}
            mobileMarginBottom={0}
          >
            {eventType === 'participant' && <FinancialSummaryLine disabled={false} label="Registration Fees" value={standardCurrencyFormat(EventRegistrationPerson.AmountWithOptions)}/>}
            {numClasses > 0 && <FinancialSummaryLine disabled={false} label={!!classString ? 'Options' : 'Classes'} value={standardCurrencyFormat(EventRegistrationPerson.ClassAmount)}/>}
            {(eventType === 'participant' || numClasses > 0 ) && <FinancialSummaryTotal noMarginBottom title='Total' value={standardCurrencyFormat(summaryTotal)}/>}

            {showNotes && <View layout="vertical" marginTop={16}>
              <Text size={13} color="gray" marginBottom={4}>
                Registration Notes
              </Text>
              <Text>{RegistrationNotes}</Text>
            </View>}
          </OldFinancialSummary>
          <FinancialSummary
            title="Total Event Fees"
            fees={fees}
            paymentStatus={EventRegistrationPaymentStatus}
            showLoaders={false}
            selected={selectedFinance as 'min' | 'full' | 'other' | 'credit'}
            isFinanceDirty={false}
            onSelect={actions.updateValue}
            updateValue={actions.updateValue}
            simpleUpdate={actions.simpleUpdate}
            validationRules={ValidationRules}
            isSaving={false}
            otherValue={otherValue}
            IsAdmin={user.user ? user.user.str_permissions.hasAdminAccess : false}
            isWizard
            hasAccess
            hasAccessMessage={null}
          />
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedPType = makeSelectedParticipantTypeSelector();
  const noFilterAvailableClasses = makeAvailableClassesNoTextFilter();
  const noFilterRegisteredClasses = makeRegisteredClassesFilter();
  const noFilterConflictingClasses = makeConflictingClassesNoTextFilter();
  const orderedProducts = makeC3OrderedProductsFilter();
  return {
    roster: state.events.event.register.participant.roster,
    classes: state.events.event.register.participant.classes,
    confirmation: state.events.event.register.participant.confirmation,
    user: state.user,
    noFilterAvailableClasses: noFilterAvailableClasses(state),
    noFilterRegisteredClasses: noFilterRegisteredClasses(state),
    noFilterConflictingClasses: noFilterConflictingClasses(state),
    selectedPType: selectedPType(state),
    orderedProducts: orderedProducts(state)
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheFourActionCreators
  }, dispatch)
});

const ConnectedParticipantConfirmation = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(ParticipantConfirmation);

export default withRouter<Props>(ConnectedParticipantConfirmation);
