import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';
import { namespace as ns } from './constants';
import '../../../styles/elements/camp-info/right.scss';

export const namespace = (): string => `${ns()}--right`;

export interface RightProps {
  children?: React.ReactNode;
  warning?: React.ReactNode;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  className?: string;
  alignWarning?: 'right';
  expand?: boolean;
  expandContainer?: boolean;
  registration?: boolean;
  zeroMobileBottomPadding?: boolean;
}

const Right: React.SFC<RightProps> = ({ zeroMobileBottomPadding, warning, children, title, subTitle, alignWarning, expand, expandContainer, registration, ...props }: RightProps): React.ReactElement<RightProps> => {
  const hasChildren = Array.isArray(children) ? (children  && children.length > 0 && children.find((c) => !!c)) : !!children;
  return (
    <div className={`${mergeClassNames(namespace(), props)} ${zeroMobileBottomPadding ? 'zero-bottom-mobile' : ''}`}>
      <div className={`${namespace()}--title-group  ${registration ? 'registration' : ''}` + (alignWarning ? ` align-warning-${alignWarning}` : '')}>
        {warning}
        {title}
      </div>
      {subTitle}
      {hasChildren ? <div className={`${namespace()}--children-container ${expandContainer ? 'expand-container' : (expand ? 'expand' : '')}`}>
        {children}
      </div> : null}
    </div>
  );
};

export default Right;
