import * as moment from 'moment';
import type {FacilitiesAvailability, FacilityLocation_FacilityType} from "./cacheTwoFacilties";
import type {TripReservation} from './cacheThreeFacilities';

// Reservation under cacheFour facility, used in a wizard
export interface WizardReservation {
  ReservationID: number | null;
  FacilityID: number | null;
  FacilityTypeID: 4;
  StartDateTime: moment.Moment | null;
  EndDateTime: moment.Moment | null;
  NumAdults: number | null;
  NumYouth: number | null;
  FlatRate: number | null;
  PerPersonRate: number | null;
  MinimumPeopleBilledAtPerPersonRates: number | null;
  AmountChangeInCart: number;
  Amount: number | null;
  Type: FacilityLocation_FacilityType;
}

/**
 * Predicate to differentiate TripReservation, FacilitiesAvailability and WizardReservation, check if an
 * object is WizardReservation
 */
export function isWizardReservation(facilityOrReservation: FacilitiesAvailability | TripReservation | WizardReservation): facilityOrReservation is WizardReservation {
  return "AmountChangeInCart" in facilityOrReservation;
}

export interface TripPaymentStatus {
  FacilityTripID: number;
  ReservationTotal: number;
  AmountPaid: number;
  AmountPaidPending: number;
  AmountInCart: number;
  AmountMin: number;
  AmountMax: number;
  TotalBalance: number;
  ReservationTotalsByType: Array<ReservationTotalByType> | null;
}

export interface ReservationTotalByType {
  Name: string;
  Amount: number;
}
