import { ActionCreator } from '../../../';
import { typeName, Action } from '../../../../utils/StrongActions';

// @todo: turns out this action wasn't needed
@typeName("EVENTS_EVENT_CLASSES_SELECT")
export class EventsEventClassesSelect extends Action {
  constructor(public index: number) { super(); }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  selectClass: (index: number): ActionCreator => dispatch => dispatch(new EventsEventClassesSelect(index))
};
