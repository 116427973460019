import {makeRequestEpic} from "../../../../Validation/epicCreator";
import {UpdateFacilityTypeSubmitActions, DeleteFacilityTypeSubmitActions} from "./actions";
import {getUpdateFacilityTypeUrl} from "../../../../../constants/urls";
import {AjaxResponse} from "rxjs/ajax";
import { ENTITY_CREATED, ENTITY_EDITED, ENTITY_PARSE_ERROR, ENTITY_DELETED, ENTITY_RESTORED } from "../../../../../constants/messages/adminCMS";
import { FACILITY_TYPE } from "../../../../../constants/messages/adminFacilityLocation";
import { captureTentarooError } from "../../../../../utils/dataHelper";
import { Observable } from "rxjs";
import { Action } from "redux";
import { ModalTypes, pushModalObservables } from "../../../../../utils/modalHelper";
import { NoOp } from "../../../../App/actions";

export const adminFacilityLocationUpdateFacilityTypeEpic = makeRequestEpic(
  UpdateFacilityTypeSubmitActions,
  getUpdateFacilityTypeUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      if (body.ID) {
        return ENTITY_EDITED(FACILITY_TYPE);
      }
      return ENTITY_CREATED(FACILITY_TYPE);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(FACILITY_TYPE)));
      return '';
    }
  },
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  (response): Observable<Action> => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      
      if (body.ID && body.BookingTimes && body.BookingTimes.length === 2) {
        const BookingTimes = body.BookingTimes;
        const PreviousBT = BookingTimes[0];
        const NewBT = BookingTimes[1];

        if (
          PreviousBT.IsCheckoutNextDay !== NewBT.IsCheckoutNextDay ||
          PreviousBT.CheckinTime !== NewBT.CheckinTime ||
          PreviousBT.CheckoutTime !== NewBT.CheckoutTime
        ) {
          return Observable.concat(
            // NOTE: No need to set saveAfter: true here because this callback will be executed
            // before the `SaveState` in epicCreator success routine
            ...pushModalObservables({
              modal: ModalTypes.FACILITY_TYPE_CONFIRMATION,
              saveBefore: false,
              saveAfter: false,
              transformToObservable: true,
            })
          );
        }
      }
      return Observable.of(new NoOp());
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(FACILITY_TYPE)));
      return Observable.of(new NoOp());
    }
  }
);

export const adminFacilityLocationDeleteFacilityTypeEpic = makeRequestEpic(
  DeleteFacilityTypeSubmitActions,
  getUpdateFacilityTypeUrl,
  undefined,
  true,
  (response: AjaxResponse) => {

    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);

      if (body.Inactive) {
        return ENTITY_DELETED(FACILITY_TYPE);
      } else {
        return ENTITY_RESTORED(FACILITY_TYPE);
      }
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(FACILITY_TYPE)));
      return '';
    }
  },
);