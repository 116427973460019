import * as React from 'react';
import { findDOMNode } from 'react-dom';
import {mergeClassNames} from '@tentaroo/shared';

import { whichAnimationEvent } from '../../../utils';
import { Text, Button, Column } from '../index';
import '../../../styles/elements/alert/index.scss';
import AlertIcon from '../../../images/elements/alert/alert.svg';
import Actions from './Actions';
import {namespace} from "./constants";

interface Props {
  children?: React.ReactNode | string;
  className?: string;
  alertIcon?: boolean;
  actions?: React.ReactNode;
  hidable?: boolean;
  color?: 'green' | 'orange' | 'red';
  label?: string;
  noFlex?: boolean;
  showMargins?: boolean;
  marginTop?: number;
}

interface State {
  startHide: boolean;
  hide: boolean;
};

class Alert extends React.Component<Props, State> {
  public static defaultProps = {
    color: 'orange'
  };

  public state: State = {
    startHide: false,
    hide: false,
  };

  public hide = () => {
    const element = findDOMNode(this);
    const onAnimationEnd = () => {
      const aniEvent = whichAnimationEvent();
      if (aniEvent) element?.removeEventListener(aniEvent, onAnimationEnd);
      this.setState({ startHide: false, hide: true });
    };
    const aniEvent = whichAnimationEvent();
    if (aniEvent) element?.addEventListener(aniEvent, onAnimationEnd);
    this.setState({ startHide: true, hide: false });
  };

  public render() {
    const { children, actions, alertIcon, hidable, label, color, noFlex, showMargins, marginTop, ...props } = this.props;
    const { startHide, hide } = this.state;
    let className: string = mergeClassNames(namespace(), props);
    if (color !== 'orange') className += ` ${color}`;
    if (startHide) className += ' leave';
    if (noFlex) className += ' no-flex';
    if (showMargins) className += ' margins';

    let finalChildren = children;
    if (typeof finalChildren === 'string') {
      finalChildren = <Text color="white" size={14}>{finalChildren}</Text>;
    }

    if (label) {
      finalChildren = [
        <Column gutter={false} marginRight={10} key={0}>
          <Text weight="bold" color="white" size={14} marginBottom={4}>{label}</Text>
        </Column>,
        <Column expand mobileSpan={12} key={1}>
          <Text color="white" size={13}>{finalChildren}</Text>
        </Column>
      ];
    }

    const style: React.CSSProperties = {};
    if (hide) style.display = 'none';
    if (marginTop !== null) style.marginTop = `${marginTop}px`;

    return (
      <div className={className} style={style}>
        <div className={`${namespace()}--content`}>
          {alertIcon ? <div className={`${namespace()}--content--alert`}><AlertIcon color="white"/></div> : null }
          <div className={`${namespace()}--content--text`}>
            {finalChildren}
          </div>
        </div>
        {actions}
        {hidable ? (
            <Actions>
              <Button flat textColor="white" onClick={this.hide}>Hide this</Button>
            </Actions>
          ) : null}
      </div>
    );
  }
}

export default Alert;
export { default as AlertActions } from './Actions';
