import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../../../../../../styles/pages/admin-events/events/modals/manage-requirements/index.scss";
import { ApplicationState } from "../../../../../../store";
import { actionCreators } from "../../../../../../store/AdminEvents/Events/Modals/ManageRequirements/actions";
import { actionCreators as appActionCreators } from "../../../../../../store/App/actions";
import { actionCreators as classTypeFormActionCreators } from "../../../../../../store/AdminEvents/ClassTypes/Form/actions";
import { AdminEventClassTypeFormDefinition } from "../../../../../../store/AdminEvents/ClassTypes/Form/validation";
import { actionCreators as enterClassRequirementsActionCreators } from "../../../../../../store/AdminEvents/Events/Modals/EnterClassRequirement/actions";
import { makeFormModalPropSelector } from "../../../../../../store/App";
import {
  ManageRequirementsModalContext,
  ModalTypes,
} from "../../../../../../utils/modalHelper";
import { getMergeProps } from "../../../../../../utils/reduxHelper";
import {
  Button,
  Checkbox,
  Column,
  Ellipsis,
  EmptyMessage,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Row,
  Select,
} from "../../../../../Elements";
import { makeFilteredMBRequirementsSelector } from "../../../../../../store/AdminEvents/Events/Modals/ManageRequirements";
import { MBRequirement } from "../../../../../../models/api/adminEventsCacheTwoEvent";
import { ModalHeight } from "../../../../../Elements/Modal";
import {ListIcon} from "../../../../../Icons";
import {EmptyMessageType} from "../../../../../Elements/EmptyMessage";
import {ENTITY_NOT_FOUND} from "../../../../../../constants/messages/adminCMS";
import {CLASS_REQUIREMENTS} from "../../../../../../constants/messages/adminEvents";
import {WithSelected} from "../../../../../../models/helpers";
import { sortByOrd } from "../../../../../../store/CacheZero";
import {WithInertAttribute} from "../../../../../Elements/WithInert";

const namespace = () => "pages--events--modal--manage-requirements-modal";

type ConnectedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type Props = WithInertAttribute<{}>

interface RequirementRowProps {
  requirement: WithSelected<MBRequirement>;
  onClick: (requirement: MBRequirement) => void;
}

function RequirementRow(props: RequirementRowProps) {
  const { requirement, onClick } = props;
  
  const precedingTag = `
    <span className="${namespace()}--requirement-row--reqTag" style="font-weight: 700;">${requirement.reqTag}</span>
    <span className="${namespace()}--requirement-row--space">&nbsp;</span>
  `;
  return (
    <div
      className={`${namespace()}--requirement-row indent-${
        requirement.reqLevel
      }${requirement.IsCheckable ? ' is-checkable' : ''}`}
      onClick={(e) => {
        e.stopPropagation();
        onClick(requirement);
      }}
    >
      <Checkbox
        className={`${namespace()}--requirement-row--checkbox`}
        selected={requirement.selected}
        value={requirement.selected}
        disabled={!requirement.IsCheckable}
      />
      <div className={`${namespace()}--requirement-row--text`}>
        <Ellipsis
          className={`${namespace()}--requirement-row--reqText`}
          configs={{
            text: requirement.reqText.replace(/[\r\n]/g, " "),
            lines: 2,
          }}
          precedingTag={requirement.reqTag ? precedingTag : undefined}
          inline
          onlyExpandOnArrow
        />
      </div>
    </div>
  );
}

class ManageRequirementsModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  private modalContentRef;

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };

  onClickRequirement = (req: MBRequirement) => {
    if (req.IsCheckable) {
      this.props.actions.toggleRequirement(req.ID);
    }
  };

  renderRequirementList = () => {
    const { filteredRequirements } = this.props;

    if (!filteredRequirements || filteredRequirements.length === 0) {
      return (
        <EmptyMessage
          icon={ListIcon}
          admin
          type={EmptyMessageType.PAGE_MARGIN}
          iconHeight="96px"
          iconWidth="96px"
          fixedFontSize
          description={ENTITY_NOT_FOUND(CLASS_REQUIREMENTS)}
        />
      );
    }

    return (
      <div className={`${namespace()}--requirements-list-wrapper`}>
        {filteredRequirements
          .sort(sortByOrd)
          .map((req) => (
            <RequirementRow onClick={this.onClickRequirement} requirement={req} />
          ))}
      </div>
    );
  };

  onSave = () => {
    const { context, participantId, modalState, actions, classTypeFormActions, enterClassRequirementActions } = this.props;

    if (context === "class-type") {
      classTypeFormActions.updateValue(
        modalState.ActiveForm.selectedRequirements,
        AdminEventClassTypeFormDefinition.RequirementsArray
      );
    } else if (context === "requirements-completed") {
      enterClassRequirementActions.updateRequirementsForParticipant(
        participantId,
        modalState.ActiveForm.selectedRequirements || [],
      );
    }
    
    actions.popModal(false, true, ModalTypes.MANAGE_REQUIREMENTS);
  };

  onDeselect = () => {
    this.props.actions.deselect();
  };

  onChangeFilter = (value, vObj) => {
    const {actions} = this.props;

    this.scrollToTop();
    actions.updateValue(value, vObj);
  };

  render() {
    const {
      context,
      actions,
      inert,
      modalState: {ActiveForm, ValidationRules},
    } = this.props;

    const title =
      context === "class-type"
        ? "Select Requirements Taught"
        : "Select Requirements Completed";

    return (
      <Modal
        inert={inert}
        className={`${namespace()}${context!== "class-type" ? ' has-filter' : ''}`}
        mobileFullScreen
        bodyScrollLock
        big
        higherZIndex
        wideModal
        height={ModalHeight.HEIGHT_560}
      >
        <ModalHeader
          className={`${namespace()}--header`}
        >
          {title}
        </ModalHeader>
        <ModalContent
          refCallback={(ref) => (this.modalContentRef = ref)}
          paddingLeft={0}
          paddingRight={0}
          paddingTop={0}
          paddingBottom={0}
        >
          {this.renderRequirementList()}
        </ModalContent>
        {context !== "class-type" ? (
          <Row className={`${namespace()}--filter`}>
            <Column span={12} mobileSpan={12}>
              <Select
                disabled={false}
                hideOptional
                onChangeValue={this.onChangeFilter}
                value={ActiveForm.RequirementTypeID}
                validationRules={ValidationRules.RequirementTypeID}
                isNumber />
              </Column>
          </Row>
        ) : null}
        <ModalActions
          sticky
          notFixed
          left={
            <Button textColor="black" flat onClick={this.onSave}>
              SAVE
            </Button>
          }
          right={
            <Button textColor="black" flat onClick={this.onDeselect}>
              DESELECT
            </Button>
          }
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const contextSelector = makeFormModalPropSelector(
    ModalTypes.MANAGE_REQUIREMENTS,
    "manageRequirementsContext"
  );
  const participantIdSelector = makeFormModalPropSelector(
    ModalTypes.MANAGE_REQUIREMENTS,
    "participantId"
  );
  const filteredRequirementsSelector = makeFilteredMBRequirementsSelector();
  return {
    context: contextSelector(state) as ManageRequirementsModalContext,
    filteredRequirements: filteredRequirementsSelector(state),
    modalState: state.adminEvents.events.modals.manageRequirements,
    participantId: participantIdSelector(state) as number,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...actionCreators,
      ...appActionCreators,
    },
    dispatch
  ),
  classTypeFormActions: bindActionCreators(
    {
      ...classTypeFormActionCreators,
    },
    dispatch
  ),
  enterClassRequirementActions: bindActionCreators(
    {
      ...enterClassRequirementsActionCreators,
    },
    dispatch,
  )
});

const ConnectedManageRequirementsModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>()
)(ManageRequirementsModal);

export default ConnectedManageRequirementsModal;
