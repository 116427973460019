import { typeName, Action } from "../../../../utils/StrongActions";
import {ActionCreator} from "../../../index";

@typeName("VIEW_RESERVATION_SHOW_MORE") export class ViewReservationShowMore extends Action {
  constructor(public show: boolean) { super(); }
}
@typeName("VIEW_RESERVATION_RESET") export class ViewReservationReset extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  reset: (): ActionCreator => dispatch => dispatch(new ViewReservationReset()),
  showMore: (showMore: boolean): ActionCreator => dispatch => dispatch(new ViewReservationShowMore(showMore)),
};
