import * as React from 'react';
import '../../../../styles/pages/events/event/price.scss';
import {namespace as ns} from './constants';
import { Card, Alert } from '../../../Elements';
import {EventInfo} from "../../../../models/api/cacheThreeEvents";
import {displayDate} from "../../../../utils/dateHelper";

export const namespace = (): string => `${ns()}--price`;

export interface Props {
  EventInfo?: EventInfo;
}

const PricingInfo = (props: Props): React.ReactElement<Props> | null => {
  if (!props.EventInfo || !props.EventInfo.PricingInfo.HasPriceChange) return null;
  const {PricingInfo: {CurrentPricingName, NextPricingName, IsFuturePricing, NextPricingDateDisplay, HasPriceChange}} = props.EventInfo;
  const label = `${CurrentPricingName.toUpperCase()} PRICING`;
  let status = `Pricing increased from ${NextPricingName.toLowerCase()} pricing on ${displayDate(NextPricingDateDisplay)}`;
  if (IsFuturePricing) {
    status = `Pricing will increase to ${NextPricingName.toLowerCase()} pricing on ${displayDate(NextPricingDateDisplay)}`;
  }
  return <Alert label={label} color="green">{status}</Alert>;
};


export default PricingInfo;
