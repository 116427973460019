import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminCMSCacheTwoResourceContext } from "@tentaroo/shared";

import { ApplicationState } from '../../../../../../store';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as adminCMSCacheTwoResourceActionCreator, GetResource} from "../../../../../../store/AdminCMSSite/CacheTwoResources/actions";
import { 
  actionCreators as rollbackActionCreators,
} from '../../../../../../store/Rollback/actions';
import { actionCreators } from "../../../../../../store/AdminCMSSite/Resources/Resource/Form/actions";
import Resource from './Form';
import { makeSelectedResourceCategorySelector } from '../../../../../../store/AdminCMSSite/Resources/Resource/Form';
import { LoadingAll } from '../../../../../../components/Elements';
import { NotFound } from '../../../../../../components/Pages';
import { INVALID_RESOURCE } from '../../../../../../constants/messages/adminCMS';
import { ADMIN_CMS_RESOURCES_EDIT_PATH, ADMIN_CMS_RESOURCES_NEW_PATH, ADMIN_CMS_RESOURCES_PREVIEW_PATH } from '../../../../../../routes';
import { checkResourcePermission, IAdminCMSResourceRouterParams } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { isPathnameMatchingRoute } from '../../../../../../utils/urlHelper';
import AdminCMSCacheManager from '../../../../../../utils/cacheManagers/adminCMSCacheManager';
import { shouldReconfigRouter } from '../../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { ComponentUpdateTemplate } from '../../../../../Templates/ComponentUpdateTemplate';
import { reduxStoreService } from '../../../../../../store/service';
import {isAdminCMSCacheOnePopulated, isAdminCMSCacheTwoResourcePopulated} from '../../../../../../utils/cachePopulatedCheckers/adminCMS';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--edit-resource';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminCMSResourceRouterParams, {}>
>;

class ResourcePage extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
  private nextLocation;

  componentDidMount() {
    this.props.actions.showAdminPageHeader(false);
    this.configRouter();

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        const {routes} = this.props;
        const route = routes[routes.length - 1];
        AdminCMSCacheManager.getInstance().loadCacheTwoResource({
          props: this.props,
          isStateNavigated,
          isEdit: route.path === ADMIN_CMS_RESOURCES_EDIT_PATH,
          context: AdminCMSCacheTwoResourceContext.RESOURCE_FORM,
        });
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
  }

  configRouter() {
    const {router, routes} = this.props;
    router.setRouteLeaveHook(routes[routes.length - 1], this.routerWillLeave);
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        const {params, routes} = nextProps;
        AdminCMSCacheManager.getInstance().loadCacheTwoResource({
          props: nextProps,
          isStateNavigated,
          isEdit: !!params.resourceId,
          context: AdminCMSCacheTwoResourceContext.RESOURCE_FORM,
        });
      }
    );
  }

  routerWillLeave = (nextLocation) => {
    const {params, actions, adminCMSCacheTwoResources} = this.props;
    this.nextLocation = nextLocation;

    if (reduxStoreService().getState().app.apiLoading === 0) {
      if (!params.resourceId || isAdminCMSCacheTwoResourcePopulated(adminCMSCacheTwoResources)) {
        actions.saveState();
      }
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentWillUnmount() {
    const {params, actions, adminCMSCacheTwoResources} = this.props;

    // if we are NOT navigating away to ResourcePreview, and cache two is loaded, then clear cache, which will also reset the form to empty
    // otherwise, reset the form to values stored in cache two
    if (
      this.nextLocation &&
      !isPathnameMatchingRoute(this.nextLocation.pathname, ADMIN_CMS_RESOURCES_PREVIEW_PATH)
    ) {
      if (!params.resourceId || isAdminCMSCacheTwoResourcePopulated(adminCMSCacheTwoResources)) {
        actions.clearAdminCMSCacheTwoResource();
      }
    }

    this.resetRouteLeaveHook();
  }

  onSave = () => {
    this.props.actions.apiSubmitForm(this.props.routes);
  };
  onDelete = () => {
    const {adminCMSCacheTwoResources, actions, routes} = this.props;
    if (!adminCMSCacheTwoResources.CMSResource) return;
    actions.deleteResource(adminCMSCacheTwoResources.CMSResource.ID, this.props.router, routes);
  };

  public render() {
    const {adminCMSCacheOne, resourceForm: {ActiveForm, ValidationRules, SubmitErrorMessage}, actions, apiSaving, selectedResourceCategory, adminCMSCacheTwoResources, apiLoadingMap, cacheZero, inert} = this.props;
    const {routes} = this.props;
    const route = routes[routes.length - 1];

    if (!isAdminCMSCacheOnePopulated() || (route.path === ADMIN_CMS_RESOURCES_EDIT_PATH && (!isAdminCMSCacheTwoResourcePopulated(adminCMSCacheTwoResources, ActiveForm) || apiLoadingMap[GetResource.requestType]))) {
      return <LoadingAll />;
    }

    if (route.path === ADMIN_CMS_RESOURCES_EDIT_PATH && (adminCMSCacheTwoResources.CMSResource && adminCMSCacheTwoResources.CMSResource.Inactive)) {
      return (
        <NotFound message={INVALID_RESOURCE}/>
      );
    }

    let unauthorizedReason;
    if (route.path === ADMIN_CMS_RESOURCES_NEW_PATH) {
      unauthorizedReason = checkResourcePermission(
        () => true,
        cacheZero,
        adminCMSCacheOne,
        undefined,
        false,
        true,
      );
    } else if (route.path === ADMIN_CMS_RESOURCES_EDIT_PATH && adminCMSCacheTwoResources.CMSResource) {
      unauthorizedReason = checkResourcePermission(
        () => true,
        cacheZero,
        adminCMSCacheOne,
        adminCMSCacheTwoResources.CMSResource.SiteID,
        false,
        true,
      );
    }
    if (unauthorizedReason) {
      return (
        <NotFound message={unauthorizedReason}/>
      );
    }
    return (
      <Resource
        inert={inert}
        ActiveForm={ActiveForm}
        ValidationRules={ValidationRules}
        adminCMSCacheOne={adminCMSCacheOne}
        resource={adminCMSCacheTwoResources ? adminCMSCacheTwoResources.CMSResource : undefined}
        reduxActions={actions}
        disabled={apiSaving > 0}
        loading={apiSaving > 0}
        action={route.path === ADMIN_CMS_RESOURCES_NEW_PATH ? 'add' : 'edit'}
        onSave={this.onSave}
        onDelete={this.onDelete}
        SubmitErrorMessage={SubmitErrorMessage}
        selectedResourceCategory={selectedResourceCategory}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedResourceCategorySelector = makeSelectedResourceCategorySelector();
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    adminCMSCacheTwoResources: state.adminCMSSite.cacheTwoResources,
    cacheZero: state.cacheZero,
    resourceForm: state.adminCMSSite.resources.resource.form,
    selectedResourceCategory: selectedResourceCategorySelector(state),
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...rollbackActionCreators,
    ...appActionCreators,
    ...adminCMSCacheTwoResourceActionCreator,
  }, dispatch)
});

const ConnectedResourcePage = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(ResourcePage);

export default withRouter<{}>(ConnectedResourcePage);
