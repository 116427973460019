import * as React from 'react';
import '../../../../../styles/pages/admin-events/products/product/index.scss';
import { Main, MainContent } from '../../../../Layouts';
import { Form, Row, Column, TextField, Switch, FieldSet, Alert, FileUploader, Vignette, MoneyField, ModalSelect } from '../../../../Elements';
import { AdminEventsCacheOneState } from '../../../../../store/AdminEvents/CacheOne';
import { Actions } from '../../../../../store/AdminEvents/Products/Form/actions';
import { Actions as AppActions } from '../../../../../store/App/actions';
import { AdminEventProductFormState } from '../../../../../store/AdminEvents/Products/Form';
import { createInitials, getImagePath } from '../../../../../utils';
import { getValidFeatureImageTypeString, stripFileExtension, validateFileType, MB, VALID_IMAGE_TYPES, checkFileSize } from '../../../../../utils/fileHelper';
import { isArray, isObject } from 'util';
import * as M from '../../../../../constants/messages/generic';
import { GLAccount } from '../../../../../models/api/options';
import GLAccountCard, { GLAccountCardType } from '../../../../Elements/GLAccount/Card';
import { CacheZeroState } from '../../../../../store/CacheZero';
import { checkGLAccountPermission } from '../../../../../utils/helpers/adminSettingsPageHelper';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { isMobileAndSmallerScreenSize } from '../../../../../utils/isMobile';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--product';

export type Props = WithInertAttribute<{
  action: 'add' | 'edit';
  disabled: boolean;
  loading?: boolean;
  adminEventsCacheOne: AdminEventsCacheOneState;
  handleCompact?: boolean;
  hideFormAction?: boolean;
  productForm: AdminEventProductFormState;
  onSave?: () => void;
  onDelete?: () => void;
  reduxActions: Actions & AppActions;
  selectedGLAccount?: GLAccount;
  cacheZero: CacheZeroState;
}>;

class AdminEventClassTypeForm extends React.Component<Props, {}> {
  public props: Props;

  isFormDisabled = () => {
    const { adminEventsCacheOne, action } = this.props;
    
    if (adminEventsCacheOne.EventsEventType) {
      const permissions = adminEventsCacheOne.EventsEventType.Permissions;
      
      if (action === 'add') {
        return !permissions.hasAddProduct;
      } else {
        return !permissions.hasEditProduct;
      }
    }
  };

  onCancelImage = () => {
    const {productForm: {ValidationRules}} = this.props;
    this.props.reduxActions.updateValue(undefined, ValidationRules.FeaturedImage);
  };
  onFileChange = (files: any) => {
    const file = isArray(files) && files.length > 0 ? files[0] : (isObject(files) ? files[0] : null);

    const isOversized = checkFileSize(
      file.name,
      file.size,
      10,
      (oversizeMessage) => {
        this.props.reduxActions.showTopFloatingAlert(oversizeMessage, isMobileAndSmallerScreenSize(), 'orange');
      }
    );
    if (isOversized) {
      return;
    }

    const result = stripFileExtension(file.name);
    if (result) {
      const validType = validateFileType(result.extension, VALID_IMAGE_TYPES);
      if (!validType) {
        this.props.reduxActions.showTopFloatingAlert(M.UNSUPPORTED_FILE_TYPES(file.name, (file.size / MB).toFixed(2)), isMobileAndSmallerScreenSize(), 'orange');
        return;
      }
    }

    const reader = new FileReader();
  
    reader.onabort = () => console.log('file reading was aborted for top gallery');
    reader.onerror = () => console.log('file reading has failed for top gallery');
    reader.onload = () => {
    // Do whatever you want with the file contents
      const dataUrl = reader.result;

      this.props.reduxActions.updateValue({
        Filename: file.name,
        inputObj: file,
        dataUrl,
      }, this.props.productForm.ValidationRules.FeaturedImage);
    };
    reader.readAsDataURL(file);
  };
  onEditGLAccount = (account: GLAccount) => {
    const {reduxActions, cacheZero} = this.props;

    checkGLAccountPermission(
      () => {
        reduxActions.pushFormModal(ModalTypes.GL_ACCOUNT_FORM, true, {id: account.ID});
      },
      cacheZero,
      true,
    );
  };
  onRemoveGLAccount = () => {
    const {productForm} = this.props;
    this.props.reduxActions.updateValue(undefined, productForm.ValidationRules.GLAccountID);
  };
  onClickSelectGLAccountButton = () => {
    this.props.reduxActions.pushSelectGLAccountModal({glAccountContext: 'product-form'});
  };

  public render() {
    const {onSave, onDelete, adminEventsCacheOne, action, disabled, reduxActions, selectedGLAccount, productForm: {ActiveForm, ValidationRules, SubmitErrorMessage}, loading, handleCompact, hideFormAction, inert} = this.props;

    const permissions = adminEventsCacheOne.EventsEventType ? adminEventsCacheOne.EventsEventType.Permissions : null;
    return (
      <Main inert={inert}>
        <MainContent loading={loading} className={`${namespace()}--main-content`} handleCompact={handleCompact}>
            <Form
              onSubmit={e => e.preventDefault()}
            >
              {action === 'add' && permissions && permissions.hasAddProductReason ? <Alert className={`${namespace()}--alert`}>{permissions.hasAddProductReason}</Alert> : null}
              {action === 'edit' && permissions && permissions.hasEditProductReason ? <Alert className={`${namespace()}--alert`}>{permissions.hasEditProductReason}</Alert> : null}
              {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
              <Row>
                <Column span={12}>
                  <TextField
                    label="Name"
                    id={generateDOMId("tentaroo-admin-product-name-field")}
                    disabled={this.isFormDisabled()}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Name}
                    value={ActiveForm.Name}/>
                </Column>
              </Row>
              <Row marginTop={8}>
                <Column span={12}>
                  <Switch
                    label="Admin Only"
                    disabled={this.isFormDisabled()}
                    multilineLabel
                    newDesign
                    onChange={reduxActions.updateValue}
                    value={!!ActiveForm.IsAdminOnly}
                    validationRules={ValidationRules.IsAdminOnly}
                  />
                </Column>
              </Row>
              <Row marginTop={24}>
                <Column span={12}>
                  <FieldSet fixedLegendFontSize marginBottom={0} newDesign legendMarginBottom={0} fontSize={20} name="Image"/>
                </Column>
              </Row>
              <Row marginTop={8} mobileMarginTop={16}>
                <Column span={12}>
                  <FileUploader
                    className='no-padding'
                    isImage
                    disabled={this.isFormDisabled()}
                    component={<Vignette
                                  width={144}
                                  height={144}
                                  disabled={this.isFormDisabled()}
                                  onCancel={this.onCancelImage}
                                  className={`${namespace()}--avatar`}
                                  name={createInitials(`${ActiveForm.Name}`)}
                                  image={ActiveForm.FeaturedImage ? (ActiveForm.FeaturedImage.dataUrl ? ActiveForm.FeaturedImage.dataUrl : getImagePath(ActiveForm.FeaturedImage)) : undefined}
                                  borderRadius='72px'  />}
                    maxFileSize={0}
                    supportedFormatsPrompt='Image up to 10MB. Supported formats: JPG, PNG, GIF, TIFF'
                    supportedFormat={getValidFeatureImageTypeString()}
                    value={ActiveForm.FeaturedImage}
                    onChange={this.onFileChange}
                    validationRules={ValidationRules.FeaturedImage}/>
                </Column>
              </Row>
              <Row marginTop={16}>
                <Column span={6}>
                  <MoneyField
                    label="Amount"
                    pullDefaultValueFromRule
                    dollarSignPrefix
                    disabled={this.isFormDisabled()}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Amount}
                    value={ActiveForm.Amount}
                    placeHolder={`$0.00`}
                  />
                </Column>
              </Row>
              <Row marginTop={8}>
                <Column span={12} mobileSpan={12}>
                  <ModalSelect
                    label='GL Account'
                    selectButtonLabel="SELECT"
                    disabled={this.isFormDisabled()}
                    hideError={!!selectedGLAccount}
                    selectedCard={selectedGLAccount ?
                      <GLAccountCard
                        type={GLAccountCardType.MODAL_SELECT}
                        showDeletedPrompt
                        disabled={this.isFormDisabled()}
                        marginTop={12}
                        onEdit={this.onEditGLAccount}
                        onRemove={this.onRemoveGLAccount}
                        account={selectedGLAccount}
                      /> : null}
                    onClickSelectButton={this.onClickSelectGLAccountButton}
                    validationRules={ValidationRules.GLAccountID}
                    value={ActiveForm.GLAccountID}
                  />
                </Column>
              </Row>
              <Row marginTop={!!selectedGLAccount ? 0 : 8} mobileMarginTop={0}>
                <Column span={12}>
                  <TextField
                      label="Description"
                      disabled={this.isFormDisabled()}
                      onChange={reduxActions.simpleUpdate}
                      onBlur={reduxActions.updateValue}
                      validationRules={ValidationRules.Description}
                      value={ActiveForm.Description}
                      rows={4}
                    />
                </Column>
              </Row>
            </Form>
        </MainContent>
      </Main>
    );
  }
}

export default AdminEventClassTypeForm;