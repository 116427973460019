import * as React from 'react';

import { Form, Row, Column, FormActions, TextField, ModalSelect, FileUploader, Select, FieldSet, Vignette, Alert } from '../../../../../Elements';
import { Button } from '../../../../../Elements';
import { Main, MainContent } from '../../../../../Layouts';
import { IValidator } from '../../../../../../store/AdminCMSSite/Contacts/Contact/Form/validation';
import { ContactActiveForm } from '../../../../../../store/AdminCMSSite/Contacts/Contact/Form';
import SocialMediaField from '../../../../../Elements/SocialMediaField';
import { createInitials, getImagePath } from '../../../../../../utils';
import '../../../../../../styles/pages/contacts/form/index.scss';
import { getValidFeatureImageTypeString, MB, stripFileExtension, validateFileType, VALID_IMAGE_TYPES, checkFileSize } from '../../../../../../utils/fileHelper';
import { isArray, isObject } from 'util';
import * as M from '../../../../../../constants/messages/generic';
import { ModalTypes, isModalOpened } from '../../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { Validator } from '../../../../../../utils/validator/models';
import { WithInertAttribute } from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--contact--form';

export type Props = WithInertAttribute<{
  action: 'add' | 'edit';
  disabled: boolean;
  ActiveForm: ContactActiveForm;
  ValidationRules: IValidator;
  loading?: boolean;
  onSave?: () => void;
  onDelete?: () => void;
  hideFormAction?: boolean;
  SubmitErrorMessage?: string;
  handleCompact?: boolean;
  reduxActions: {
    simpleUpdate: (value: any, validationRules?: Validator) => any,
    updateValue: (value: any, validationRules?: Validator) => any,
    updateSocialType: (value: any, validationRules?: Validator) => any,
    removeSocial: (id) => any,
    addSocial: () => any,
    showTopFloatingAlert(message: string, inModal?: boolean, color?: 'green' | 'orange'),
  };
}>;

class Contact extends React.Component<Props, {}> {
  public props: Props;

  onRemoveSocialType = (id: number) => {
    this.props.reduxActions.removeSocial(id);
  };

  onAddSocialType = () => {
    this.props.reduxActions.addSocial();
  };

  onCancelImage = () => {
    const {ValidationRules} = this.props;
    this.props.reduxActions.updateValue(undefined, ValidationRules.FeaturedImage);
  };
  onFileChange = (files: any) => {
    const file = isArray(files) && files.length > 0 ? files[0] : (isObject(files) ? files[0] : null);

    const isOversized = checkFileSize(
      file.name,
      file.size,
      10,
      (oversizeMessage) => {
        this.props.reduxActions.showTopFloatingAlert(oversizeMessage, isModalOpened(ModalTypes.CONTACT_FORM), 'orange');
      }
    );
    if (isOversized) {
      return;
    }

    const result = stripFileExtension(file.name);
    if (result) {
      const validType = validateFileType(result.extension, VALID_IMAGE_TYPES);
      if (!validType) {
        this.props.reduxActions.showTopFloatingAlert(M.UNSUPPORTED_FILE_TYPES(file.name, (file.size / MB).toFixed(2)), isModalOpened(ModalTypes.CONTACT_FORM), 'orange');
        return;
      }
    }

    const reader = new FileReader();
  
    reader.onabort = () => console.log('file reading was aborted for top gallery');
    reader.onerror = () => console.log('file reading has failed for top gallery');
    reader.onload = () => {
    // Do whatever you want with the file contents
      const dataUrl = reader.result;

      this.props.reduxActions.updateValue({
        Filename: file.name,
        inputObj: file,
        dataUrl,
      }, this.props.ValidationRules.FeaturedImage);
    };
    reader.readAsDataURL(file);
  };

  public render() {
    const {onSave, inert, onDelete, action, disabled, reduxActions, ValidationRules, ActiveForm, loading, hideFormAction, SubmitErrorMessage, handleCompact} = this.props;

    const socialMedias: any[] = [];
    for (let key of Object.keys(ActiveForm)) {
      if (key.includes("SocialTypeID")) {
        const index = Number(key.replace('SocialTypeID', ''));
        socialMedias.push(
          <Row key={`${namespace()}--ssn-${index}`} className={`${namespace()}--social-media`}>
            <Column span={12} mobileSpan={12}>
              <SocialMediaField
                disabled={loading}
                onSocialUserChange={reduxActions.simpleUpdate}
                onSocialUserBlur={reduxActions.updateValue}
                onSocialTypeChange={reduxActions.updateSocialType}
                onCancelClick={() => this.onRemoveSocialType(index)}
                iconType='color'
                socialTypeValue={ActiveForm[`SocialTypeID${index}`]}
                socialUserValue={ActiveForm[`SocialUser${index}`]}
                socialUserValidationRule={(ValidationRules as any)[`SocialUser${index}`]}
                socialTypeValidationRule={(ValidationRules as any)[`SocialTypeID${index}`]} />
            </Column>
          </Row>
        );
      }
    }
    return (
        <Main inert={inert}>
          <MainContent loading={loading} handleCompact={handleCompact}>
            <Form
              className={`${namespace()}`}
              onSubmit={e => e.preventDefault()}
              actions={
                hideFormAction ? null : (action === 'add' ? (
                  <FormActions><Button flat textColor="black" big onClick={onSave}>CREATE Contact</Button></FormActions>
                ) : (
                  <FormActions>
                    <Row>
                      <Column expand>
                        <Button id={generateDOMId("admin-contact-save-btn")} color="white" flat textColor="black" big onClick={onSave}>SAVE CHANGES</Button>
                      </Column>
                      <Column>
                        <Button color="white" flat textColor="red" big onClick={onDelete}>DELETE</Button>
                      </Column>
                    </Row>
                  </FormActions>
                ))
              }
            >
              {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
              <Row>
                <Column span={6} mobileSpan={8}>
                  <TextField
                    id={generateDOMId("admin-contact-firstname-field")}
                    label="First Name"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.FirstName}
                    value={ActiveForm.FirstName}/>
                </Column>
                <Column span={3} mobileSpan={4}>
                  <TextField
                    label="MI"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.MI}
                    value={ActiveForm.MI}/>
                </Column>
              </Row>
              <Row>
                <Column span={6} mobileSpan={8}>
                  <TextField
                    label="Last Name"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.LastName}
                    value={ActiveForm.LastName}/>
                </Column>
                <Column span={3} mobileSpan={4}>
                  <TextField
                    label="Suffix"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Suffix}
                    value={ActiveForm.Suffix}/>
                </Column>
              </Row>
              <Row>
                <Column span={6} mobileSpan={12}>
                  <TextField
                    label="Title/Position"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Title}
                    value={ActiveForm.Title}/>
                </Column>
                <Column span={6} mobileSpan={12}>
                  <TextField
                    label="Company Name"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Company}
                    value={ActiveForm.Company}/>
                </Column>
              </Row>
              <Row marginBottom={16}>
                <Column span={6} mobileSpan={12}>
                  <Select label="Category"
                          disabled={disabled}
                          onChangeValue={reduxActions.updateValue}
                          value={ActiveForm.CategoryID}
                          validationRules={ValidationRules.CategoryID}
                          isNumber
                  />
                </Column>
                {action === 'edit' && <Column span={6} mobileSpan={12}>
                  <Select
                    label="Owner"
                    disabled={disabled}
                    hideOptional
                    onChangeValue={reduxActions.updateValue}
                    value={ActiveForm.SiteID}
                    validationRules={ValidationRules.SiteID}
                    isNumber />
                </Column>}
              </Row>
              <FieldSet marginBottom={24} legendMarginBottom={0} fontSize={20} name="Contact Info"></FieldSet>
              <Row>
                <Column span={6} mobileSpan={8}>
                  <TextField
                    label="Phone"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Phone}
                    value={ActiveForm.Phone}/>
                </Column>
                <Column span={3} mobileSpan={4}>
                  <TextField
                    label="Ext"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.PhoneExt}
                    value={ActiveForm.PhoneExt}/>
                </Column>
              </Row>
              <Row>
                <Column span={6} mobileSpan={12}>
                  <TextField
                    label="Mobile"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.MobilePhone}
                    value={ActiveForm.MobilePhone}/>
                </Column>
                <Column span={6} mobileSpan={12}>
                  <TextField
                    label="Fax"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Fax}
                    value={ActiveForm.Fax}/>
                </Column>
              </Row>
              <Row marginBottom={16}>
                <Column span={6} mobileSpan={12}>
                  <TextField
                    label="Email"
                    disabled={disabled}
                    onChange={reduxActions.simpleUpdate}
                    onBlur={reduxActions.updateValue}
                    validationRules={ValidationRules.Email}
                    value={ActiveForm.Email}/>
                </Column>
              </Row>
              <FieldSet marginBottom={16} mobileMarginBottom={24} legendMarginBottom={0} fontSize={20} name="Photo"></FieldSet>
              <Row className={`${ActiveForm.FeaturedImage ? 'has-image' : ''}`} mobileMarginBottom={40} marginBottom={40}>
                <Column span={12} mobileSpan={12}>
                  <FileUploader
                    className='no-padding'
                    isImage
                    component={<Vignette 
                                  width={144}
                                  height={144}
                                  onCancel={this.onCancelImage}
                                  className={`${namespace()}--avatar`}
                                  name={createInitials(`${ActiveForm.FirstName} ${ActiveForm.LastName}`)}
                                  image={ActiveForm.FeaturedImage ? (ActiveForm.FeaturedImage.dataUrl ? ActiveForm.FeaturedImage.dataUrl : getImagePath(ActiveForm.FeaturedImage)) : undefined}
                                  borderRadius='72px'  />}
                    maxFileSize={0}
                    supportedFormatsPrompt='Image up to 10MB. Supported formats: JPG, PNG, GIF, TIFF'
                    supportedFormat={getValidFeatureImageTypeString()}
                    value={ActiveForm.FeaturedImage}
                    onChange={this.onFileChange}
                    validationRules={ValidationRules.FeaturedImage}/>
                </Column>
              </Row>
              <FieldSet mobileMarginBottom={16} marginBottom={24} legendMarginBottom={0} fontSize={20} name="Social Media Accounts"></FieldSet>
              {socialMedias}
              <Button className={`${namespace()}--add-ssn-account`} onClick={this.onAddSocialType} textColor='black' color='white'>ADD ACCOUNT</Button>
            </Form>
          </MainContent>
        </Main>
    );
  }
}

export default Contact;
