import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, getCacheLevelExtra,
} from '../../../../Validation/actionCreator';
import { ActionCreator, ApplicationState } from '../../../../';
import { ResourceActiveForm } from '.';
import { getSubmitResourceBody, getDeleteResourceBody, URLS, CMS_PATHS } from '../../../../../constants/urls';
import { SaveState } from '../../../../../store/Rollback/actions';
import { ShowTopFloatingAlert } from '../../../../../store/App/actions';
import * as M from '../../../../../constants/messages/generic';
import { ADMIN_CMS_RESOURCES_NEW_PATH, ADMIN_CMS_RESOURCES_EDIT_PATH } from '../../../../../routes';
import { ADMIN_CMS_MODULE_SUFFIX } from '../../../../../store/AdminCMSSite/CacheOne/actions';
import { scrollModalToTop } from '../../../../../utils/helpers/adminCMSPageHelper';
import { navPush } from '../../../../../utils/navHelper';
import { ModalTypes, isModalOpened, ResourceFormModalNamespace } from '../../../../../utils/modalHelper';
import { PlainRoute } from 'react-router';
import { ClearAdminCMSCacheTwoResource, UPDATE_CMS_RESOURCE_FORM_SUFFIX } from '../../../CacheTwoResources/actions';

export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX +  '__RESOURCE_FORM' + UPDATE_CMS_RESOURCE_FORM_SUFFIX;
export const DEL_SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__DELETE_RESOURCE' + UPDATE_CMS_RESOURCE_FORM_SUFFIX;


export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const AddResourceSubmitActions = createApiSubmitActions(SUFFIX);
export const DeleteResourceSubmitActions = createApiSubmitActions(DEL_SUFFIX);

const formCreator = (rootState: ApplicationState, activeForm: ResourceActiveForm) => {
  const resourceId = rootState.adminCMSSite.cacheTwoResources.CMSResource ? rootState.adminCMSSite.cacheTwoResources.CMSResource.ID : undefined;
  const formData = new FormData();
  formData.append('JSONData', JSON.stringify(getSubmitResourceBody({
    resourceId,
    resourceTS: rootState.adminCMSSite.cacheTwoResources.CMSResource ? rootState.adminCMSSite.cacheTwoResources.CMSResource.TS : 0
  }, activeForm)));

  if (activeForm.File && activeForm.File.inputObj) formData.append('File', activeForm.File.inputObj);

  return formData;
};

export type Actions = typeof actionCreators;

// We pass the `leavingCacheLevelOnSuccess` extra field to action when we dont want to update
// AdminCMSCacheTwoResources state upon successfully saving. They are
//     - When we are not in EditResource/NewResource page AND
//     - When we are not in preview page
const getSubmitFormExtra = (routes, state: ApplicationState) => {
  const route = routes[routes.length - 1];

  return getCacheLevelExtra(
    !(route.path === ADMIN_CMS_RESOURCES_EDIT_PATH || route.path === ADMIN_CMS_RESOURCES_NEW_PATH),
    !!state.adminCMSSite.cacheTwoResources.CMSResource,
  );
};

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.resources.resource.form),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  apiSubmitForm: (routes: PlainRoute<any>[]): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const isResourceFormModalOpened = isModalOpened(ModalTypes.RESOURCE_FORM);
    // form validation (not including file)
    const valid = innerApiSubmitFormMethod(
      dispatch,
      AddResourceSubmitActions,
      (s: ApplicationState) => s.adminCMSSite.resources.resource.form,
      undefined,
      undefined,
      isResourceFormModalOpened,
      true,
      undefined,
      undefined,
    );
    if (valid) {
      // file validation
      if (!state.adminCMSSite.resources.resource.form.ActiveForm.File) {
        dispatch(new ShowTopFloatingAlert(M.MISSING_FILE, isResourceFormModalOpened, 'orange'));
        return false;
      }
      
      const route = routes[routes.length - 1];

      dispatch(AddResourceSubmitActions.request(
          formCreator(getState(), state.adminCMSSite.resources.resource.form.ActiveForm),
          getSubmitFormExtra(routes, state),
          route,
        )
      );

      return true;
    } else {
      if (isResourceFormModalOpened) {
        scrollModalToTop(ResourceFormModalNamespace());
      }

      return false;
    }
  },
  deleteResource: (resourceId: number, router: any, routes: PlainRoute<any>[], restore?: boolean): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());

    const state = getState();
    const site = state.adminCMSSite.cacheOne.CMSSite;
    const qp = getState().routing.locationBeforeTransitions.search;
    const route = routes[routes.length - 1];

    const body = getDeleteResourceBody({ resourceId }, !restore);
    if (site) {
      navPush(router, `${URLS.ADMIN_SITES}/${site.ID}/${site.SiteDomain}/${CMS_PATHS.RESOURCES}${qp}`);
    }

    dispatch(DeleteResourceSubmitActions.request(
      body,
      getCacheLevelExtra(
        true, 
        !!state.adminCMSSite.cacheTwoResources.CMSResource && state.adminCMSSite.cacheTwoResources.CMSResource.ID === resourceId,
      ),
      route,
    ));
  },
};