import {
  createApiValidateActions, createValidateActions, createUpdateValueMethod,
  createApiSubmitActions, createSimpleUpdateValueMethod, innerApiSubmitFormMethod, SEQUENTIAL_SUBMIT_REQUEST, getCacheLevelExtra,
} from '../../../../Validation/actionCreator';
import type { ActionCreator, ApplicationState } from '../../../../';
import { CMSSiteMenuItem } from '../../../../../models/api/adminCMSCacheOne';
import { CMSPageGalleryImage } from '../../../../../models/api/adminCMSCacheTwoPage';
import { GetDefaultForPrimaryEventPageParams, getDefaultForPrimaryEventPageBody, getSubmitEditPageSiteMenuItemBody, getDeleteEditPageSiteMenuItemBody, getEditPageEditGalleryImageBody, getEditPageDeleteGalleryImageBody, getEditPageAddGalleryImageBody, getDeleteRestorePageBody, getUpdatePageBody, getPagesRootUrl } from '../../../../../constants/urls';
import { ShowTopFloatingAlert, ToggleModalSaving } from '../../../../../store/App/actions';
import { INVALID_MENU_LEVEL } from '../../../../../constants/messages/adminCMS';
import { v4 as uuidv4 } from 'uuid';
import { SaveState } from '../../../../../store/Rollback/actions';
import { navPush } from '../../../../../utils';
import { PageActiveForm } from '.';
import { SUFFIX as SITEMENU_ITEM_ACTION_SUFFIX, scrollMenuItemSidebarToTop } from '../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/actions';
import { SUFFIX as IMAGE_FORM_ACTION_SUFFIX, ImageFormModalNamespace, ImageResetForm } from '../../../../../store/AdminCMSSite/Pages/Modals/ImageForm/actions';
import { ADMIN_CMS_MODULE_SUFFIX } from '../../../../../store/AdminCMSSite/CacheOne/actions';
import { scrollModalToTop } from '../../../../../utils/helpers/adminCMSPageHelper';
import { isMobileAndSmallerScreenSize } from '../../../../../utils/isMobile';
import { UPDATE_FORM_ACTION_SUFFIX } from '../../../../../utils/suffix';
import { PlainRoute } from 'react-router';
import { appActionCreators } from '../../../../App';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { typeName, Action } from '../../../../../utils/StrongActions';
import { Validator } from '../../../../../utils/validator/models';

export const UPDATE_CMS_PAGE_FORM_SUFFIX = UPDATE_FORM_ACTION_SUFFIX + '__CMS_PAGE';
export const SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__PAGE_FORM';
export const DEL_SUFFIX = ADMIN_CMS_MODULE_SUFFIX + '__DELETE_PAGE';
export const GET_DEFAULT_FOR_PRIMARY_EVENT_PAGE = '__GET_DEFAULT_FOR_PRIMARY_EVENT_PAGE' + SUFFIX;
export const UPDATE_SITE_MENU_ITEM_SUFFIX = '__UPDATE_SITE_MENU_ITEM' + SUFFIX + SITEMENU_ITEM_ACTION_SUFFIX;

export const UPDATE_GALLERY_IMAGE_PREFIX = SEQUENTIAL_SUBMIT_REQUEST + '__UPDATE_GALLERY_IMAGE__';
export const ADD_TOP_GALLERY_IMAGE_SUFFIX = UPDATE_GALLERY_IMAGE_PREFIX + '__ADD_TOP_GALLERY_IMAGE' + SUFFIX;
export const ADD_BOTTOM_GALLERY_IMAGE_SUFFIX = UPDATE_GALLERY_IMAGE_PREFIX + '__ADD_BOTTOM_GALLERY_IMAGE' + SUFFIX;
export const EDIT_GALLERY_IMAGE_SUFFIX = '__EDIT_GALLERY_IMAGE' + SUFFIX + IMAGE_FORM_ACTION_SUFFIX;
export const DELETE_GALLERY_IMAGE_SUFFIX = UPDATE_GALLERY_IMAGE_PREFIX + '__DELETE_GALLERY_IMAGE' + SUFFIX;

@typeName('TOGGLE_ADD_SITE_MENU_SIDEBAR' + SUFFIX)
export class ToggleAddSiteMenuSidebar extends Action {
  constructor(public show: boolean) { super(); }
}
@typeName('TOGGLE_EDIT_SITE_MENU_SIDEBAR' + SUFFIX)
export class ToggleEditSiteMenuSidebar extends Action {
  constructor(public editSiteMenuId: number) { super(); }
}
@typeName('REMOVE_RESOURCE_FROM_PAGE' + SUFFIX)
export class RemoveResourceFromPage extends Action {
  constructor(public resourceId: number) { super(); }
}
@typeName('REMOVE_CONTACT_FROM_PAGE' + SUFFIX)
export class RemoveContactFromPage extends Action {
  constructor(public contactId: number) { super(); }
}
@typeName('ADD_CONTACTS_TO_PAGE' + SUFFIX)
export class AddContactsToPage extends Action {
  constructor(public contactIds: number[]) { super(); }
}
@typeName('ADD_RESOURCES_TO_PAGE' + SUFFIX)
export class AddResourcesToPage extends Action {
  constructor(public resourceIds: number[]) { super(); }
}
@typeName('REMOVE_SOCIAL' + SUFFIX)
export class RemoveSocial extends Action {
  constructor(public pageSocialIndex: number) { super(); }
}
@typeName('ADD_SOCIAL' + SUFFIX)
export class AddSocial extends Action { constructor() { super(); } }

@typeName('UPDATE_SOCIAL_TYPE' + SUFFIX)
export class UpdateSocialType extends Action {constructor(public key: string, public vObj: Validator) { super(); }}

@typeName('MOVE_TOP_GALLERY_ITEM' + SUFFIX)
export class MoveTopGalleryItem extends Action {
  constructor(public item: CMSPageGalleryImage, public offset: number) { super(); }
}
@typeName('MOVE_BOTTOM_GALLERY_ITEM' + SUFFIX)
export class MoveBottomGalleryItem extends Action {
  constructor(public item: CMSPageGalleryImage, public offset: number) { super(); }
}
@typeName('TOP_GALLERY_ADD_IMAGE')
export class TopGalleryAddImage extends Action {
  constructor(public dataUrl: string | ArrayBuffer | null, public tempId: number, public options?: CMSPageGalleryImage) {super();}
}
@typeName('BOTTOM_GALLERY_ADD_IMAGE')
export class BottomGalleryAddImage extends Action {
  constructor(public dataUrl: string | ArrayBuffer | null, public tempId: number, public options?: CMSPageGalleryImage) {super();}
}
@typeName('TOGGLE_SECTION')
export class ToggleSectionExpand extends Action {
  constructor(public index: number, public expand: boolean, public inMobile?: boolean) { super(); }
}
@typeName('CLEAR_TEMP_THUMBNAILS' + SUFFIX)
export class ClearTempThumbnails extends Action { constructor() { super(); } }

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const UpdatePageSubmitActions = createApiSubmitActions(SUFFIX + UPDATE_CMS_PAGE_FORM_SUFFIX);
export const DeletePageSubmitActions = createApiSubmitActions(DEL_SUFFIX + UPDATE_CMS_PAGE_FORM_SUFFIX);
export const GetDefaultForPrimaryEventPageSubmitActions = createApiSubmitActions(GET_DEFAULT_FOR_PRIMARY_EVENT_PAGE, true);
export const EditPageUpdateSiteMenuItemSubmitActions = createApiSubmitActions(UPDATE_SITE_MENU_ITEM_SUFFIX);
export const EditPageAddTopGalleryImageSubmitActions = createApiSubmitActions(ADD_TOP_GALLERY_IMAGE_SUFFIX);
export const EditPageAddBottomGalleryImageSubmitActions = createApiSubmitActions(ADD_BOTTOM_GALLERY_IMAGE_SUFFIX);
export const EditPageEditGalleryImageSubmitActions = createApiSubmitActions(EDIT_GALLERY_IMAGE_SUFFIX);
export const EditPageDeleteGalleryImageSubmitActions = createApiSubmitActions(DELETE_GALLERY_IMAGE_SUFFIX);

const addGalleryImageFormCreator = (rootState: ApplicationState, file: any) => {
  const formData = new FormData();
  if (rootState.adminCMSSite.cacheTwoPage.CMSPage) {
    formData.append('JSONData', JSON.stringify(getEditPageAddGalleryImageBody({
      PageID: rootState.adminCMSSite.cacheTwoPage.CMSPage.ID
    })));
  }

  formData.append('File', file);

  return formData;
};

const updatePageFormCreator = (rootState: ApplicationState, activeForm: PageActiveForm) => {
  // get `Socials`
  let foundAllSocialMedias = false;
  let index = 0;
  const Socials: any[] = [];

  while (!foundAllSocialMedias) {
    if (!activeForm[`SocialTypeID${index}`] || !activeForm[`SocialUser${index}`]) {
      foundAllSocialMedias = true;
      break;
    }

    Socials.push({
      ID: activeForm[`SocialID${index}`],
      SocialTypeID: activeForm[`SocialTypeID${index}`],
      SocialUser: activeForm[`SocialUser${index}`],
    });
    index += 1;
  }

  // get `Images`
  let Images: any[] = [];
  Images = Images.concat(activeForm.CMSPageGallery1.map((image, index) => ({ID: image.ID, Ord: index + 1})));
  Images = Images.concat(activeForm.CMSPageGallery2.map((image, index) => ({ID: image.ID, Ord: index + 1})));

  const body = getUpdatePageBody({
    PageID: rootState.adminCMSSite.pages.page.form.ActiveForm.ID,
  }, rootState.adminCMSSite.pages.page.form.ActiveForm);
  body.Images = Images;
  body.Socials = Socials;

  return body;
};

export type Actions = typeof actionCreators;

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.pages.page.form),
  updateValueWithSave: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.pages.page.form, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  getDefaultForPrimaryEventPage: (params: GetDefaultForPrimaryEventPageParams): ActionCreator => dispatch => dispatch(GetDefaultForPrimaryEventPageSubmitActions.request(getDefaultForPrimaryEventPageBody(params), null)),
  toggleAddMenuSidebar: (show?: boolean): ActionCreator => dispatch => dispatch(new ToggleAddSiteMenuSidebar(!!show)),
  toggleEditMenuSidebar: (editSiteMenuId?: number): ActionCreator => dispatch => dispatch(new ToggleEditSiteMenuSidebar(editSiteMenuId || -1)),
  removeResourceFromPage: (resourceId: number): ActionCreator => dispatch => dispatch(new RemoveResourceFromPage(resourceId)),
  removeContactFromPage: (contactId: number): ActionCreator => dispatch => dispatch(new RemoveContactFromPage(contactId)),
  addContactsToPage: (contactIds: number[]): ActionCreator => dispatch => dispatch(new AddContactsToPage(contactIds)),
  addResourcesToPage: (resourceIds: number[]): ActionCreator => dispatch => dispatch(new AddResourcesToPage(resourceIds)),
  updateSocialType: (value: any, vObj: Validator): ActionCreator => (dispatch, getState) => {
    dispatch(new UpdateSocialType(value, vObj));
    createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminCMSSite.pages.page.form)(value, vObj)(dispatch, () => getState());
  },
  removeSocial: (pageSocialIndex: number): ActionCreator => dispatch => dispatch(new RemoveSocial(pageSocialIndex)),
  addSocial: (): ActionCreator => dispatch => dispatch(new AddSocial()),
  moveTopGalleryItem: (item: CMSPageGalleryImage, offset: number): ActionCreator => dispatch => dispatch(new MoveTopGalleryItem(item, offset)),
  moveBottomGalleryItem: (item: CMSPageGalleryImage, offset: number): ActionCreator => dispatch => dispatch(new MoveBottomGalleryItem(item, offset)),
  toggleSectionExpand: (index: number, expand: boolean, inMobile?: boolean): ActionCreator => dispatch => dispatch(new ToggleSectionExpand(index, expand, inMobile)),
  submitSiteMenuItem: (item: CMSSiteMenuItem, pageId: number): ActionCreator => (dispatch, getState) => {
    const state = getState();
    // form validation
    let valid = innerApiSubmitFormMethod(
      dispatch,
      EditPageUpdateSiteMenuItemSubmitActions,
      (s: ApplicationState) => s.adminCMSSite.menus.siteMenuItem.form,
      undefined,
      undefined,
      true,
      true,
      undefined,
      undefined,
    );

    if (state.adminCMSSite.cacheOne.CMSSiteMenuItems && state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.ID && state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.MenuLevel === 2) {
      // if the site menu item is updated to level 2, but already has any active children under it
      state.adminCMSSite.cacheOne.CMSSiteMenuItems.forEach((item) => {
        if (item.ParentID === state.adminCMSSite.menus.siteMenuItem.form.ActiveForm.ID) {
          valid = false;
          dispatch(new ShowTopFloatingAlert(INVALID_MENU_LEVEL, isMobileAndSmallerScreenSize(), 'orange'));
        }
      });
    }

    if (valid) {
      if (item.ID) {
        dispatch(new ToggleEditSiteMenuSidebar(-1));
      } else {
        dispatch(new ToggleAddSiteMenuSidebar(false));
      }
      dispatch(EditPageUpdateSiteMenuItemSubmitActions.request(
        getSubmitEditPageSiteMenuItemBody({
          pageId,
        }, item),
        getCacheLevelExtra(false, true),
      ));
    } else {
      scrollMenuItemSidebarToTop();
    }
  },
  deleteSiteMenuItem: (item: CMSSiteMenuItem, pageId: number): ActionCreator => dispatch => {
    dispatch(EditPageUpdateSiteMenuItemSubmitActions.request(
      getDeleteEditPageSiteMenuItemBody({
        pageId,
        inactive: true,
      }, item),
      getCacheLevelExtra(false, true),
    ));
  },
  submitTopGalleryImage: (file: any, dataUrl: any): ActionCreator => (dispatch, getState) => {
    const tempId = uuidv4();
    // dispatch the sequential request first, otherwise the thumbnail would be cleared
    dispatch(EditPageAddTopGalleryImageSubmitActions.request(
      addGalleryImageFormCreator(getState(), file),
      {
        formDataRequest: true,
        tempId,
        gallery: 'top',
        leavingCacheLevelOnSuccess: false,
        inMatchingCacheLevelOn409: false,
      },
    ));
    dispatch(new TopGalleryAddImage(dataUrl, tempId));
  },
  submitBottomGalleryImage: (file: any, dataUrl: any): ActionCreator => (dispatch, getState) => {
    const tempId = uuidv4();
    // dispatch the sequential request first, otherwise the thumbnail would be cleared
    dispatch(EditPageAddBottomGalleryImageSubmitActions.request(
      addGalleryImageFormCreator(getState(), file),
      {
        formDataRequest: true,
        tempId,
        gallery: 'bottom',
        leavingCacheLevelOnSuccess: false,
        inMatchingCacheLevelOn409: false,
      },
    ));
    dispatch(new BottomGalleryAddImage(dataUrl, tempId));
  },
  updateGalleryImage: (gallery: 'top' | 'bottom'): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const valid = innerApiSubmitFormMethod(
      dispatch,
      EditPageEditGalleryImageSubmitActions,
      (s: ApplicationState) => s.adminCMSSite.pages.modals.imageForm,
      undefined,
      undefined,
      true,
      true,
      undefined,
      undefined,
    );

    if (valid && state.adminCMSSite.cacheTwoPage.CMSPage) {
      dispatch(appActionCreators.popModal(false, false, ModalTypes.GALLERY_IMAGE_FORM) as any);
      dispatch(new ImageResetForm());
      dispatch(new ToggleModalSaving(true));
      dispatch(EditPageEditGalleryImageSubmitActions.request(
        getEditPageEditGalleryImageBody(
          {
            PageID: state.adminCMSSite.cacheTwoPage.CMSPage.ID,
          },
          state.adminCMSSite.pages.modals.imageForm.ActiveForm
        ),
        {
          gallery,
          ...getCacheLevelExtra(
            false,
            true,
          )
        })
      );
    } else {
      scrollModalToTop(ImageFormModalNamespace());
    }
  },
  deleteGalleryImage: (gallery: 'top' | 'bottom', imageId: number, restore?: boolean): ActionCreator => (dispatch, getState) => {
    const state = getState();
    if (state.adminCMSSite.cacheTwoPage.CMSPage) {
      dispatch(EditPageDeleteGalleryImageSubmitActions.request(
        getEditPageDeleteGalleryImageBody({
          PageID: state.adminCMSSite.cacheTwoPage.CMSPage.ID,
          ID: imageId,
        }, !restore),
        {
          gallery,
          leavingCacheLevelOnSuccess: false,
          inMatchingCacheLevelOn409: false,
        },
      ));
    }
  },
  deletePage: (pageId: number, router: any, routes: PlainRoute<any>[], restore?: boolean): ActionCreator => (dispatch, getState) => {
    const state = getState();
    dispatch(new SaveState());
    const route = routes[routes.length - 1];

    const site = state.adminCMSSite.cacheOne.CMSSite;
    const body = getDeleteRestorePageBody({
      PageID: pageId,
    }, !restore);
    if (site && state.adminCMSSite.cacheTwoPage.CMSPage && pageId === state.adminCMSSite.cacheTwoPage.CMSPage.ID) {
      navPush(router, getPagesRootUrl({
        siteId: site.ID,
        domain: site.SiteDomain,
      }));
    }
    dispatch(
      DeletePageSubmitActions.request(
        body,
        getCacheLevelExtra(
          true,
          !!state.adminCMSSite.cacheTwoPage.CMSPage && state.adminCMSSite.cacheTwoPage.CMSPage.ID === pageId,
        ),
        route
      )
    );
  },
  updatePageSubmit: (router: any): ActionCreator => (dispatch, getState) => {
    const state = getState();
    const valid = innerApiSubmitFormMethod(
      dispatch,
      UpdatePageSubmitActions,
      (s: ApplicationState) => s.adminCMSSite.pages.page.form,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
    );
    if (valid && state.adminCMSSite.cacheOne.CMSSite) {
      const form = updatePageFormCreator(getState(), state.adminCMSSite.pages.page.form.ActiveForm);
      navPush(router, getPagesRootUrl({
        siteId: state.adminCMSSite.cacheOne.CMSSite.ID,
        domain: state.adminCMSSite.cacheOne.CMSSite.SiteDomain,
      }));
      dispatch(UpdatePageSubmitActions.request(
          form,
          getCacheLevelExtra(
            true,
            true,
          )
        )
      );
    }
  },
  clearThumbnails: (): ActionCreator => dispatch => dispatch(new ClearTempThumbnails()),
};