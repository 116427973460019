import * as M from '../../../../../constants/messages/generic';
import {chainMerge} from '../../../../../utils/validator';
import {makeSelectedFacilityTypeC4Selector} from "../../../../CacheFourFacilities";
import {isDaySame} from "../../../../../utils";
import {API_DATE_FORMAT} from "../../../../../utils/dateHelper";
import { Validator } from '../../../../../utils/validator/models';
import { toNumber } from '../../../../../utils/dataHelper';

export interface IValidator {
  NumAdults: Validator;
  NumYouth: Validator;
  StartDateTime: Validator;
  EndDateTime: Validator;
  SumPeople: Validator;
  DateRange: Validator;
}

const selectedFacType = makeSelectedFacilityTypeC4Selector();

export const FormDefinition: IValidator = {
  NumAdults: {
    key: 'NumAdults',
    isChainRoot: true,
    chainValue: (rootState, values) => {
      return values.NumAdults;
    },
    chainDependants: ['SumPeople'],
    localDependants: ['NumYouth'],
    skipResetLocalDependantsToDefault: true,
    customValidate: (rootState) => {
      const ActiveForm = rootState.facilities.trip.reservation.reservation.ActiveForm;
      const FacilityTrip = rootState.cacheThreeFacilities.FacilityTrip;

      const NumAdults = toNumber(ActiveForm.NumAdults);
      const NumYouth = toNumber(ActiveForm.NumYouth);
      if (!FacilityTrip) return undefined;
      if (NumAdults > FacilityTrip.NumAdults) {
        return M.MAX_VALUE(FacilityTrip.NumAdults, true);
      }
      
      if (NumAdults === 0 && NumYouth === 0) {
        return M.REQUIRED;
      }

      return undefined;
    },
    validatejs: {
      NumAdults: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: M.MIN_VALUE(0),
          greaterThan: -1,
          notLessThan: M.MAX_VALUE(800),
          lessThan: 801
        }
      }
    },
  },
  NumYouth: {
    key: 'NumYouth',
    isChainRoot: true,
    chainValue: (rootState, values) => {
      return values.NumYouth;
    },
    chainDependants: ['SumPeople'],
    localDependants: ['NumAdults'],
    skipResetLocalDependantsToDefault: true,
    customValidate: (rootState) => {
      const ActiveForm = rootState.facilities.trip.reservation.reservation.ActiveForm;
      const FacilityTrip = rootState.cacheThreeFacilities.FacilityTrip;

      const NumAdults = toNumber(ActiveForm.NumAdults);
      const NumYouth = toNumber(ActiveForm.NumYouth);
      if (!FacilityTrip) return undefined;
      if (NumYouth > FacilityTrip.NumYouth) {
        return M.MAX_VALUE(FacilityTrip.NumYouth, true);
      }
      
      if (NumAdults === 0 && NumYouth === 0) {
        return M.REQUIRED;
      }
    },
    validatejs: {
      NumYouth: {
        presence: {message: '^' + M.REQUIRED},
        numericality : {
          notGreaterThan: M.MIN_VALUE(0),
          greaterThan: -1,
          notLessThan: M.MAX_VALUE(800),
          lessThan: 801
        }
      }
    },
  },
  StartDateTime: {
    key: 'StartDateTime',
    validatejs: {
      StartDateTime: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          // set in reducer
        }
      }
    }
  },
  EndDateTime: {
    key: 'EndDateTime',
    validatejs: {
      EndDateTime: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          // set in reducer
        }
      }
    }
  },
  SumPeople: {
    key: 'SumPeople',
    chainValue: (rootState, values) => {
      const ActiveForm = rootState.facilities.trip.reservation.reservation.ActiveForm;
      const v = chainMerge(ActiveForm, values);
      return toNumber(v.NumYouth) + toNumber(v.NumAdults);
    },
    customValidate: (rootState) => {
      const ActiveForm = rootState.facilities.trip.reservation.reservation.ActiveForm;

      const SumPeople = toNumber(ActiveForm.SumPeople);
      if (!rootState.cacheFourFacilities.FacilityAvailabilities) {
        return undefined;
      }
      const MaxAccommodation = rootState.cacheFourFacilities.FacilityAvailabilities.MaxAccommodation;
      if (MaxAccommodation && SumPeople > MaxAccommodation) {
        return M.MAX_EXCEEDED;
      }
    }
  },
  DateRange: {
    key: 'DateRange',
    customValidate: (rootState) => {
      if (!rootState.cacheFourFacilities.FacilityAvailabilities || !rootState.cacheFourFacilities.FacilityAvailabilities) {
        return undefined;
      }
      const availabilities = rootState.cacheFourFacilities.FacilityAvailabilities.Availabilities;
      const availDates = rootState.cacheFourFacilities.FacilityAvailabilitiesDates;
      const {StartDateTime, EndDateTime} = rootState.facilities.trip.reservation.reservation.ActiveForm;
      const IsCheckoutNextDay: boolean = rootState.cacheFourFacilities.FacilityAvailabilities.IsCheckoutNextDay;
      if (EndDateTime && StartDateTime && typeof EndDateTime !== 'string' && typeof StartDateTime !== 'string' && availDates) {
        const startDateKey = StartDateTime.format(API_DATE_FORMAT);
        const endDateKey = EndDateTime.format(API_DATE_FORMAT);
        const startIndex = availDates.findIndex((a) => a.Date === startDateKey);
        const endIndex = availDates.findIndex((a) => a.Date === endDateKey);
        if (startIndex !== -1) {
          const aID = availabilities[startIndex].AvailID;
          if (aID === 3 || aID === 4 || aID === 5) {
            return M.BAD_START_DATE_RANGE;
          }
        }
        if (endIndex !== -1) {
          const aID = availabilities[endIndex].AvailID;
          if (aID === 4 || aID === 5) {
            return M.BAD_END_DATE_RANGE;
          }
        }
        if (EndDateTime.isBefore(StartDateTime)) {
          return M.BAD_DATE_RANGE;
        }
        if (IsCheckoutNextDay && isDaySame(StartDateTime, EndDateTime)) {
          return M.BAD_DATE_RANGE;
        }
      }
    }
  }
};

