import * as React from 'react';
import {Alert, Class, Row, Text} from '../../../../../../Elements';
import {BaseEndUserClass} from '../../../../../../../models/class';
import {ClassesFormTemplate} from '../../../../../../Templates';
import {Props as ClassesFormTemplateProps} from '../../../../../../Templates/Forms/Classes';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import ClassesAdded from '../../ClassesAdded';
import {actionCreators} from "../../../../../../../store/Events/Event/Modals/ClassOverrideFee/actions";
import {
  actionCreators as appActionCreators,
  ShowTopFloatingAlert
} from "../../../../../../../store/App/actions";
import {
  actionCreators as classesActionCreators,
  Actions as ClassesActions
} from "../../../../../../../store/Events/Event/Register/Numbers/Classes/actions";
import {bindActionCreators} from 'redux';
import {ArrowIcon} from '../../../../../../Icons';
import '../../../../../../../styles/pages/events/event/register/classes.scss';
import {NumbersConnectedProps} from "../index";
import {
  makeAvailableClassesFilter,
  makeAvailableClassesNoFilter,
  makeConflictingClassesFilter,
  makeConflictingClassesNoFilter,
  makeRegisteredClassesFilter
} from "../../../../../../../store/CacheFourEventsNumbers/index";
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { ApplicationState } from '../../../../../../../store';
import { shouldBlockActions } from '../../../../../../../utils/cacheLoaders/helpers/blockers';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../../../store/service';
import { makeTextForConflictingExpander } from '../../../../../../../utils/classesHelper';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

export const namespace = (): string => `pages--events--event--classes--participant-numbers`;

type Props = WithInertAttribute<{
  numberProps: NumbersConnectedProps;
}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface State {
  conflictingExpanded: boolean;
}

class Classes extends React.Component<Props, State> {
  public state: State = {
    conflictingExpanded: false
  };
  public props: Props & ConnectedProps;

  toggleConflicting = () => this.setState({ conflictingExpanded: !this.state.conflictingExpanded });

  componentWillReceiveProps(nextProps: Props & ConnectedProps) {
    if (shouldBlockActions()) return;
    if (nextProps.dispatchWarning)  {
      this.props.actions.clearWarning();
      reduxStoreService().dispatch(new ShowTopFloatingAlert(nextProps.dispatchWarning, false, 'orange'));
    }
  }

  getOnManageClass = () => {
    const {NumCampersYouth, NumCampersAdult} = this.props.spots.ActiveForm;
    const { user, actions } = this.props;
    if (!user.user.str_permissions.hasAdminAccess) return undefined;
    return (c) => {
      actions.pushModal(ModalTypes.OVERRIDE_FEES, false, true, {
        numYouth: NumCampersYouth ? NumCampersYouth : 0,
        numAdults: NumCampersAdult ? NumCampersAdult : 0,
        class: c,
        individual: false,
      });
    };
  };

  renderClass = (actions: ClassesActions, c: BaseEndUserClass, index: number) => {
    const {NumCampersYouth, NumCampersAdult} = this.props.spots.ActiveForm;
    const isAdmin = this.props.user.user.str_permissions.hasAdminAccess;
    return (<Class
      numYouth={NumCampersYouth ? NumCampersYouth : 0}
      numAdults={NumCampersAdult ? NumCampersAdult : 0}
      enableWaitlist={false}
      isPreview={false}
      key={index}
      onAddClass={actions.addClass}
      classModel={c}
      isAdmin={isAdmin}
      isRegistration={false}
      labelOptions
    />);
  };

  renderConflictingExpander = () => {
    const arrowStyle: any = {marginRight: '16px', marginLeft: '8px'};
    if (this.state.conflictingExpanded) {
      arrowStyle.transform = 'rotateZ(180deg)';
    }
    return (
      <Row className={`${namespace()}--conflict`} onClick={this.toggleConflicting} verticalAlignment="middle" marginTop={16} mobileMarginTop={8} marginBottom={24} mobileMarginBottom={16} key="conflict-row">
        <ArrowIcon color="red" style={arrowStyle}/>
        <Text weight="medium" color="red" size={16}>{makeTextForConflictingExpander("Options")}</Text>
      </Row>
    );
  };

  renderConflictingClasses = () => {
    const {actions, conflictingClasses} = this.props;
    if (conflictingClasses.length === 0) {
      return null;
    } else if (!this.state.conflictingExpanded) {
      return this.renderConflictingExpander();
    }
    return [
      this.renderConflictingExpander(),
      ...conflictingClasses.map(this.renderClass.bind(this, actions))
    ];
  };

  public render(): any {
    const {
      actions, availableClasses, registeredClasses, noFilterAvailableClasses, noFilterConflictingClasses, inert,
      classes:{SubmitErrorMessage, isError}, numberProps: {cacheThreeEvents}
    } = this.props;
    const {cacheTwoEvents, cacheFourEventsNumbers, apiLoading} = this.props.numberProps;
    const isNewlyAdding = !cacheThreeEvents.EventRegistrationNumbers;
    const {NumCampersYouth, NumCampersAdult} = this.props.spots.ActiveForm;

    let emptyMessage: string | undefined;
    if (noFilterAvailableClasses && noFilterAvailableClasses.length === 0 && noFilterConflictingClasses && noFilterAvailableClasses.length === 0) {
      emptyMessage = 'No options are available';
    } else if (noFilterAvailableClasses && noFilterAvailableClasses.length === 0) {
      emptyMessage = 'You\'ve added all available options';
    } else if (availableClasses && availableClasses.length === 0) {
      emptyMessage = 'No available options found';
    }
    const templateProps: ClassesFormTemplateProps & {key: string} = {
      inert,
      key: 'numbers',
      footer: <FooterProgress selected="classes"/>,
      header: <HeaderProgress loading={apiLoading > 0} c2={cacheTwoEvents} c4={cacheFourEventsNumbers} selected="classes"/>,
      sideBar: <ClassesAdded
        classes={registeredClasses}
        onRemove={actions.removeClass}
        onManage={this.getOnManageClass()}
        SubmitErrorMessage={isError || !isNewlyAdding? SubmitErrorMessage : undefined}
        numYouth={NumCampersYouth ? NumCampersYouth : 0}
        numAdults={NumCampersAdult ? NumCampersAdult : 0}
        loading={apiLoading > 0}
        isAdmin={this.props.user.user.str_permissions.hasAdminAccess}
        labelOptions
      />,
      emptyMessage: emptyMessage,
      addedItems: registeredClasses,
      loading: apiLoading > 0,
      classesString: 'Options'
    };
    if ((isError || !isNewlyAdding) && SubmitErrorMessage) {
      templateProps.preEmptyChildren = <Row marginBottom={16}>
        <Alert>{SubmitErrorMessage}</Alert>
      </Row>;
    }

    return [
      <ClassesFormTemplate {...templateProps}>
        {/* @todo: maybe bring back this alert later  availableClasses && availableClasses.length !== 0 && <Alert hidable>
          These classes are only applied to the whole group. You'll be able to select personal classes after event registration is completed.
        </Alert> */}
        {availableClasses && availableClasses.map(this.renderClass.bind(this, actions))}
        {this.renderConflictingClasses()}

      </ClassesFormTemplate>,
    ];
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const availableClasses = makeAvailableClassesFilter();
  const conflictingClasses = makeConflictingClassesFilter();
  const registeredClasses = makeRegisteredClassesFilter();
  const noFilterAvailableClasses = makeAvailableClassesNoFilter();
  const noFilterConflictingClasses = makeConflictingClassesNoFilter();
  return {
    classes: state.events.event.register.numbers.classes,
    spots: state.events.event.register.numbers.spots,
    user: state.user,
    availableClasses: availableClasses(state),
    conflictingClasses: conflictingClasses(state),
    registeredClasses: registeredClasses(state),
    noFilterAvailableClasses: noFilterAvailableClasses(state),
    noFilterConflictingClasses: noFilterConflictingClasses(state),
    dispatchWarning: state.cacheFourEventsNumbers.dispatchWarning,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...classesActionCreators
  }, dispatch)
});

const ConnectedClasses = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(Classes);

export default ConnectedClasses;
