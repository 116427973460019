import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import Select from "../../Select";
import '../../../../styles/elements/simple-list-filter/index.scss';
import { SelectValidator } from '../../../../utils/validator/models';

export const namespace = (): string => 'elements--simple-list-filter';

export interface SimpleListProps {
  label?: string;
  className?: string;
  children?: React.ReactNode;
  onChange?: (value: string | number | undefined, validationRules?: SelectValidator) => any; // @todo: typescript
  value?: string | number;
  validationRules?: SelectValidator;
}

const SimpleListFilter: React.SFC<SimpleListProps> = ({ label, children, onChange, value, validationRules,  ...props }: SimpleListProps): React.ReactElement<SimpleListProps> => {
  const className = mergeClassNames(namespace(), props);
  return (
    <div className={className}>
      <Select
        label={label}
        onChangeValue={onChange}
        value={value}
        validationRules={validationRules}
        isNumber
        hideError
      >
        {children}
      </Select>
    </div>
);
};

export default SimpleListFilter;
