import * as React from 'react';
import {Main, MainContent} from '../../Layouts';
import PageLoader from '../PageLoader';

export const namespace = 'pages--dev--loading-all'; // @todo: rename class

const LoadingAll: React.SFC<{}> = ({}): React.ReactElement<{}> => {
  // There's nothing to interact from this component and its descendants,
  // so setting inert to undefined - it's a required parameter for <Main /> component
  return <Main inert={undefined} mobileBackground="white">
    <MainContent className="pages--dev--loading-all">
      <PageLoader/>
    </MainContent>
  </Main>;
};

export default LoadingAll;
