import { GetBlackoutDateParams } from "../../../constants/urls";
import { CLEAR_CACHE_PREFIX, createRequestActions } from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { ADMIN_FACILITY_MODULE_SUFFIX } from "../CacheOne/actions";
import { HideDrawer, SilentCancelAllAction } from '../../../store/App/actions';
import { UPDATE_FACILITY_BLACKOUT_DATE_FORM_SUFFIX } from "../BlackoutDates/Form/actions";
import { typeName, Action } from "../../../utils/StrongActions";


export const SUFFIX = '__CACHE_TWO_BLACKOUT_DATES';
export const SUFFIX_SAVE = '__CACHE_TWO_BLACKOUT_DATES_SAVING';

export const GetBlackoutDate = createRequestActions(SUFFIX + ADMIN_FACILITY_MODULE_SUFFIX + UPDATE_FACILITY_BLACKOUT_DATE_FORM_SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminFacilityLocationCacheTwoBlackoutDate extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  getBlackoutDate: (params: GetBlackoutDateParams): ActionCreator => dispatch => {
    dispatch(GetBlackoutDate.request(params));
  },
  refreshBlackoutDate: (params: GetBlackoutDateParams): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    dispatch(GetBlackoutDate.request(params));
  },
  clearAdminFacilityLocationCacheTwoBlackoutDate: (): ActionCreator => dispatch => dispatch(new ClearAdminFacilityLocationCacheTwoBlackoutDate()),
};