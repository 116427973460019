
import { reduxStoreService } from "../../store/service";

export default class CacheManager {

  constructor() {
    
  }

  /**
   * Generic method to check if cache is invalid. The definition of in valid cache is:
   * 1. Cache is already populated AND
   * 2. The populated cache's id doesn't match the one in URL - we didn't load the expected cache
   * 
   * Please note that this is NOT checking if cache is populated or not, but checking if the populated cache is invalid or not
   * 
   * @param cacheLevel - cache level we are checking
   * @param getIdFromCache - function to get id from cache, because the key could be different from cache to cache
   * @param idFromUrlOrProps - id picked from url or component props
   * @param additionalConditions - any additional condition, for example, modal status
   * @returns 
   */
  protected _isPopulatedCacheInvalid<T>(
    cacheLevel: T | undefined | null,
    getIdFromCache: (cacheLevel: T) => number | null,
    // TODO: Later after changing cache/form and id check in EndUser side, type of this param can always be `number`
    idFromUrlOrProps: number | string,
    additionalConditions?: () => boolean
  ): boolean {
    const rootState = reduxStoreService().getState();

    /**
     * When `isRouterChangeRollbackFinished` flag is set to false, we block cache invalidate because
     * otherwise it will trigger a ClearCache in a wrong timing. Specifically, it will trigger a clearCache
     * against the page we are about to unmount. In general, we want to "freeze" the app when `isRouterChangeRollbackFinished`
     * is set to false.
     */
    if (!rootState.app.isRouterChangeRollbackFinished) return false;
    
    const idInCache = cacheLevel ? getIdFromCache(cacheLevel) : undefined;
    const id = typeof idFromUrlOrProps === "string" ? Number(idFromUrlOrProps) : idFromUrlOrProps;
    return !!cacheLevel && !!idInCache && id !== idInCache && (!additionalConditions || additionalConditions());
  }
}