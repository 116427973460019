import * as React from 'react';
import {default as AppHeader} from '../../../../App/Header';
import {RouteComponentProps, withRouter} from "react-router";

type ConnectedProps = RouteComponentProps<{}, {}>;

const Header: React.SFC<ConnectedProps> = (props): React.ReactElement<undefined> => {
  const sp = props.location.pathname.split('/');

  return <AppHeader
    showDrawer
    back={`/${sp[1]}/${sp[2]}/${sp[3]}/${sp[4]}/${sp[5]}`}
    title="Reservation Summary"
    cart
    refresh
  />;
};

export default withRouter(Header);
