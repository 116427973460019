import * as React from 'react';
import { Shadow, Media, ActionButton } from '../../Elements';
import { RefreshIcon } from '../../Icons';
import Nav from './Nav';
import '../../../styles/app/nav/index.scss';
import Account from './Account';
import {AppState} from '../../../store/App';
import { bindActionCreators } from 'redux';
import { InjectedRouter, RouteComponentProps, withRouter, WithRouterProps } from 'react-router';
import {clearAllCache} from "../Header/Actions/Refresh";
import { NAVS } from '../../../constants/urls';
import NavLogo from '../../../components/Elements/Logo/NavLogo';
import { getAdminCMSCustomRefresh } from '../../../utils/helpers/adminCMSPageHelper';
import { actionCreators as cacheTwoResourcesActionCreators } from '../../../store/AdminCMSSite/CacheTwoResources/actions';
import { actionCreators as cacheTwoContactsActionCreators } from '../../../store/AdminCMSSite/CacheTwoContacts/actions';
import { actionCreators as appActionCreators, Actions as AppActions } from '../../../store/App/actions';
import { actionCreators as cacheOneActionCreators } from '../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as cacheTwoPageMenusActionCreators } from '../../../store/AdminCMSSite/CacheTwoPageMenus/actions';
import { actionCreators as cacheTwoSiteMenusActionCreators } from '../../../store/AdminCMSSite/CacheTwoSiteMenus/actions';
import { actionCreators as rollbackActionCreators } from '../../../store/Rollback/actions';
import { getAdminFacilityLocationCustomRefresh } from '../../../utils/helpers/adminFacilityLocationPageHelper';
import { actionCreators as adminFacilityLocationCacheOneActionCreators } from '../../../store/AdminFacilityLocation/CacheOne/actions';
import { actionCreators as adminFacilityLocationCacheTwoBlackoutDateActionCreators } from '../../../store/AdminFacilityLocation/CacheTwoBlackoutDates/actions';
import { actionCreators as availabilitiesHomeActionCreators } from "../../../store/AdminFacilityLocation/Availabilities/Home/actions";
import { actionCreators as adminEventsCacheOneActionCreators } from '../../../store/AdminEvents/CacheOne/actions';
import { actionCreators as adminEventsCacheTwoProductActionCreators } from '../../../store/AdminEvents/CacheTwoProduct/actions';
import { actionCreators as adminEventsCacheTwoMessageActionCreators } from '../../../store/AdminEvents/CacheTwoMessage/actions';
import { ApplicationState } from '../../../store';
import { getAdminEventsCustomRefresh } from '../../../utils/helpers/adminEventsPageHelper';
import LogoComponent from '../../Elements/Logo/LogoComponent';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';
import {namespace} from "./constants";

export default (selected: string | undefined = undefined) => {
  type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

  const onSelectClient = (router: InjectedRouter, actions: AppActions, app: AppState) => {
    if (app.isDrawerOpen) actions.hideDrawer();
    actions.pushAccountsModal();
  };


  const NavComponent: React.SFC<ConnectedProps> = ({ user, actions, router, app, session, cacheZeroOptions, adminCMSRefreshActions, adminEventsRefreshActions, adminFacilityLocationRefreshActions, ...props}: ConnectedProps): React.ReactElement<{}> => {
    let accountName;
    if (cacheZeroOptions && cacheZeroOptions.Group) {
      accountName = cacheZeroOptions.Group.Name;
    }

    let customRefresh;

    if (selected === NAVS.WEBSITES) {
      customRefresh = getAdminCMSCustomRefresh(props as any, adminCMSRefreshActions);
    } else if (selected === NAVS.FACILITY_LOCATION) {
      customRefresh = getAdminFacilityLocationCustomRefresh(props as any, adminFacilityLocationRefreshActions);
    } else if (selected === NAVS.ADMIN_EVENTS) {
      customRefresh = getAdminEventsCustomRefresh(props as any, adminEventsRefreshActions);
    }
    
    // The className for `<Shadow />` component here corresponds to the classes configured for `App/Header/index.tsx` via `isAdminPage` and `longTabs` flags
    // @todo-minor-performance: bind below
    return (
      <Shadow shadow={2} className={`${namespace()} ${selected === NAVS.WEBSITES ? 'admin-page' : ''} ${selected === NAVS.ADMIN_EVENTS ? 'admin-events' : ''} ${selected === NAVS.FACILITY_LOCATION ? 'facility-location' : ''} ${selected === NAVS.ADMIN ? 'add-group' : ''}`}>
        <div className={`${namespace()}--content`}>
          <Media mobile>
            <div className={`${namespace()}--content--header`}>
              <LogoComponent position="side-bar" />
              {((selected !== NAVS.WEBSITES && selected !== NAVS.FACILITY_LOCATION) || customRefresh) ? <ActionButton className={`${namespace()}--content--header--refresh`} onClick={customRefresh ? customRefresh : clearAllCache}>
                <RefreshIcon className={`${namespace()}--content--header--refresh--image`} width="16px" height="16px"/>
              </ActionButton> : null}
            </div>
          </Media>
          {user.user && user.user.str_permissions.hasAdminAccess && <Account
            selectedClient={accountName}
            onClick={onSelectClient.bind(this, router, actions, app)}
          />}
          <Nav selected={selected} size="normal"/>
          <div className={`${namespace()}--content--branding`}>
            <span>Powered by</span>
            <NavLogo width="120px" height="21px"/>
          </div>
        </div>
      </Shadow>)
      ;
  };

  const mapStateToProps = (state: ApplicationState) => {
    return {
      app: state.app,
      user: state.user,
      session: state.session,
      cacheZeroOptions: state.cacheZero.options,
      availabilitiesHome: state.adminFacilityLocation.availabilities.home,
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({...appActionCreators}, dispatch),
    adminCMSRefreshActions: bindActionCreators({
      ...cacheTwoResourcesActionCreators,
      ...cacheTwoContactsActionCreators,
      ...cacheOneActionCreators,
      ...cacheTwoPageMenusActionCreators,
      ...cacheTwoSiteMenusActionCreators,
      ...rollbackActionCreators,
      ...appActionCreators,
    }, dispatch),
    adminFacilityLocationRefreshActions: bindActionCreators({
      ...adminFacilityLocationCacheOneActionCreators,
      ...adminFacilityLocationCacheTwoBlackoutDateActionCreators,
      ...availabilitiesHomeActionCreators,
    }, dispatch),
    adminEventsRefreshActions: bindActionCreators({
      ...adminEventsCacheOneActionCreators,
      ...adminEventsCacheTwoProductActionCreators,
      ...adminEventsCacheTwoMessageActionCreators,
      ...rollbackActionCreators,
    }, dispatch)
  });

  const ConnectedNavComponent = connect(
    mapStateToProps,
    mapDispatchToProps,
    getMergeProps<ConnectedProps & WithRouterProps>(),
  )(NavComponent);

  return withRouter<ConnectedProps>(ConnectedNavComponent);
};
