import { RegisteredClass } from '../../../../../models/class';
import {IValidator, FormDefinition} from "./validation";
import {apiValidationReducerCreator} from "../../../../Validation/reducerCreator";
import {SUFFIX} from "./actions";
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import { PushModal } from '../../../../App/actions';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface OverrideFeeActiveForm {
  NumOverride?: number;
  Amount?: number;
  AmountMin?: number;
};
export interface OverrideFeeState {
  OverridingClass?: RegisteredClass;
  ActiveForm: OverrideFeeActiveForm,
  ValidationRules: IValidator
}

const getInitialState = () => {
  return {
    ActiveForm: {},
    ValidationRules: FormDefinition
  };
};
const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const OverrideFee: Reducer<OverrideFeeState> = (oldState = {...getInitialState()}, action: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, action);

  if (isActionType(action, PushModal) && action.modal === ModalTypes.OVERRIDE_FEES) {
    const props = action.props;
    if (!props!.individual) {
      return {
        ...state,
        OverridingClass: {...props!.class},
        ActiveForm: {
          NumOverride: props!.class!.NumOverride,
          Amount: props!.class!.Amount,
          AmountMin: props!.class!.AmountMin
        },
        ValidationRules: {
          ...FormDefinition
        }
      };
    }
    return {
      ...state,
      OverridingClass: {...props!.class},
      ActiveForm: {
        NumOverride: undefined,
        Amount: props!.class!.Amount,
        AmountMin: undefined
      },
      ValidationRules: {
        Amount: {...FormDefinition.Amount},
        AllowNegative: {...FormDefinition.AllowNegative}
      }
    };
  }
  return state || {};
};

export default OverrideFee;
