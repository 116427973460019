import {ActionCreator} from '../../../../index';
import {ViewControlsOptions} from "../../../../../components/Elements/Controls/View";
import {SaveState} from "../../../../Rollback/actions";
import {FacilitiesRecalc} from "../../../../CacheFourFacilities/actions";
import {FacilitiesAvailability} from "../../../../../models/api/cacheTwoFacilties";
import {getFacilitiesReservationsRecalcBody} from "../../../../../constants/urls";
import {FacilityTrip} from "../../../../../models/api/cacheThreeFacilities";
import {FacilitiesReservationSetPendingPage} from "../actions";
import { captureTentarooError } from '../../../../../utils/dataHelper';
import { typeName, Action } from '../../../../../utils/StrongActions';

@typeName("FACILITIES_RESERVATION_TYPE_SELECT_TYPE")
export class FacilitiesReservationSelectType extends Action {
  constructor(public selectedType: number) { super(); }
}
@typeName("FACILITIES_RESERVATION_TYPE_SELECT_VIEW")
export class FacilitiesReservationSelectView extends Action {
  constructor(public view: ViewControlsOptions) { super(); }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  selectType: (selectedType: number): ActionCreator => dispatch => dispatch(new FacilitiesReservationSelectType(selectedType)),
  selectView: (view: ViewControlsOptions): ActionCreator => dispatch => dispatch(new FacilitiesReservationSelectView(view)),
  typeSubmit: (typeID: number, avail: FacilitiesAvailability, trip: FacilityTrip): ActionCreator => (dispatch, getState) => {
    // is is always next
    dispatch(new SaveState());

    const rootState = getState();

    if (!rootState.cacheZero.options) {
      captureTentarooError(new Error("cacheZero.options not available when submting ReservationType form"));
      return;
    }
    if (!rootState.cacheZero.options.Group) {
      captureTentarooError(new Error("Group not available when submting ReservationType form"));
      return;
    }
    const GroupIDi = rootState.cacheZero.options.Group.IDi;
    const GroupTS = rootState.cacheZero.options.Group.TS;
    const FacilityLocationID = rootState.cacheTwoFacilities.locationID;
    const FacilityTripID = rootState.cacheThreeFacilities.tripID;
    const FacilityTypeID = typeID;

    dispatch(FacilitiesRecalc.request(getFacilitiesReservationsRecalcBody(
      {
        GroupIDi,
        GroupTS,
        FacilityLocationID,
        FacilityTripID,
        FacilityTypeID
      },
      avail.FacilityID,
      trip.NumYouth,
      trip.NumAdults,
      avail.Pricing.FlatRate,
      avail.Pricing.PerPersonRate,
      avail.Pricing.MinimumPeopleBilledAtPerPersonRates,
      avail.ftbID
    )));
    dispatch(new FacilitiesReservationSetPendingPage('reservation'));
  }
};
