import { RouteComponentProps } from "react-router";
import { UnauthenticatedCacheContext } from "@tentaroo/shared";

import { ensureUnauthenticatedCache } from "../cacheLoaders/cacheUnauthenticatedLoader";
import CacheManager from "./cacheManager";
import { LoadLoginFormParams, LoadRegisterFormParams } from "../cacheLoaders/helpers/models";
import {isCreateFormCachePopulated, isLoginCachePopulated} from "../cachePopulatedCheckers/unauthenticated";

export default class UnauthenticatedCacheManager extends CacheManager {
  private static _instance: UnauthenticatedCacheManager;

  public static getInstance(): UnauthenticatedCacheManager {
    if (!this._instance) {
      this._instance = new UnauthenticatedCacheManager();
    }

    return this._instance;
  }

  constructor() {
    super();
  }

  private unauthenticatedCacheNeedsReload(
    props: RouteComponentProps<any, any>,
    context: UnauthenticatedCacheContext,
  ) {
    if (context === UnauthenticatedCacheContext.LOGIN) {
      return !isLoginCachePopulated();
    } else {
      return !isCreateFormCachePopulated();
    }
  }

  public loadLoginForm(params: LoadLoginFormParams): boolean {
    const {props, isStateNavigated} = params;
    if (this.unauthenticatedCacheNeedsReload(props, UnauthenticatedCacheContext.LOGIN) || isStateNavigated) {
      const resetCode = !props.location.query ? undefined : props.location.query["ResetCode"];

      return ensureUnauthenticatedCache({
        context: UnauthenticatedCacheContext.LOGIN,
        resetCode,
        isStateNavigated,
        props,
      });
    }

    return false;
  };

  public loadRegisterForm(params: LoadRegisterFormParams): boolean {
    const {props, isStateNavigated} = params;
    if (this.unauthenticatedCacheNeedsReload(props, UnauthenticatedCacheContext.CREATE_ACCOUNT) || isStateNavigated) {
      return ensureUnauthenticatedCache({
        context: UnauthenticatedCacheContext.CREATE_ACCOUNT,
        isStateNavigated,
        props,
      });
    }

    return false;
  }
}