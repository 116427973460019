import * as React from 'react';
import { Nav as HeaderNav, NavItem } from '../../App/Header';
import { URLS } from '../../../constants/urls';

export interface NavProps {
  selected:string;
  showNewAccount: boolean;
}

const Nav:React.SFC<NavProps> = (props:NavProps):React.ReactElement<NavProps> => {
  const tabs = [<NavItem key="login" to={URLS.LOGIN} selected={props.selected === 'login'}>Log in</NavItem>];
  if (props.showNewAccount) {
    tabs.push(<NavItem key="new_account" to={URLS.NEW_ACCOUNT} selected={props.selected === 'new_account'}>Create Account</NavItem>);
  }
  return (
    <HeaderNav>
      {tabs}
    </HeaderNav>
  );
};

export default Nav;
