import * as React from 'react';
import {
  Modal, ModalActions, Button, ModalContent, ModalHeader } from '../../../../../Elements';
import {withRouter, RouteComponentProps} from "react-router";
import {bindActionCreators} from "redux";
import {actionCreators as appActionCreators} from "../../../../../../store/App/actions";
import {actionCreators as eventDashboardActionCreators} from "../../../../../../store/AdminEvents/Events/Event/Dashboard/actions";
import {ApplicationState} from "../../../../../../store";
import '../../../../../../styles/pages/admin-events/events/modals/recalculate-pricing/index.scss';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

const namespace = () => 'pages--events--modal--recalculate-pricing';

type Props = WithInertAttribute<{}>;

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

@(withRouter as any)
class RecalculatePricingModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  
  onContinue = (e?: any) => {
    if (e) e.stopPropagation();
    this.props.actions.recalculatePricing();
  };

  public render() {
    const {apiSaving, inert, cacheTwoEvent, apiLoading} = this.props;

    let subtitle = cacheTwoEvent.EventsEvent ? cacheTwoEvent.EventsEvent.Name : '';

    return (
      <Modal inert={inert} className={`${namespace()}`} mobileFullScreen bodyScrollLock big higherZIndex>
        <ModalHeader className={`${namespace()}--header`} subtitle={subtitle}>Recalculate Pricing</ModalHeader>
        <ModalContent hasPadding>
          <div className={`${namespace()}--text`}>This tool will recalculate all fees for the group registrations in this event based on the current event pricing, the event pricing tier set on the groups, selected registration options such as Camperships or discounts under groups/individuals, total payments made by date (early, base or late), and any free leader ratios.</div>
          <br />
          <div className={`${namespace()}--text`}>Any spots in the registration that can't be paid in full with the payments made by the early/base pricing deadlines will be increased to the next higher rate.</div>
        </ModalContent>
        <ModalActions
          noPadding
          sticky
          loading={apiSaving > 0 || apiLoading > 0}
          stacked
          left={<Button expand flat textColor="black" onClick={this.onContinue}>CONTINUE</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    cacheTwoEvent: state.adminEvents.cacheTwoEvent,
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...appActionCreators,
  ...eventDashboardActionCreators,
}, dispatch)});

const ConnectedRecalculatePricingModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(RecalculatePricingModal);

export default ConnectedRecalculatePricingModal;
