import * as React from 'react';
import { namespace as ns } from './constants';
import { CloseIcon } from '../../Icons';

const namespace = (): string => `${ns()}--marker`;

interface Props {
  onClick: (e) => void;
}

const CancelImage:React.SFC<Props> = ({onClick}):React.ReactElement<Props> =>
  <i className={`${namespace()} ${namespace()}--cancel-icon`} onClick={onClick}>
    <CloseIcon width="17px" height="12px" className={`${namespace()}--invalid-icon--image`}/>
  </i>;

export default CancelImage;
