import { Reducer } from 'redux';
import { isActionType } from '../../../../utils/StrongActions';
import * as Actions from './actions';

export interface EventState {
  showMoreProducts: boolean;
}

const getInitialState = (): EventState => ({
  showMoreProducts: false
});

const Event: Reducer<EventState> = (state, action) => {
  if (isActionType(action, Actions.EventShowMoreProducts)) {
    return {
      showMoreProducts: action.showMoreProducts
    };
  } else if (isActionType(action, Actions.EventReset)) {
    return {...getInitialState()};
  }
  return state || {...getInitialState()};
};

export default Event;
