import * as React from 'react';
import {CartIcon, FacilitiesIcon} from '../../../Icons';
import {
  Alert,
  AlertActions,
  Button,
  ContentBlock,
  EmptyMessage,
  EmptyMessageAction,
  Link,
  Media
} from '../../../Elements';
import {Main, MainContent} from '../../../Layouts';
import RightSidebar from '../RightSidebar';
import Contact from '../Contact';
import Trip from './Trip';
import {URLS} from "../../../../constants/urls";
import {RouteComponentProps, withRouter} from 'react-router';
import '../../../../styles/pages/home/my-trips/index.scss';
import {actionCreators as cacheOneActionCreators, GetGroupCache} from "../../../../store/CacheOne/actions";
import {
  actionCreators as cacheZeroActionCreators,
} from "../../../../store/CacheZero/actions";
import {HomepageMyTrip} from "../../../../models/api/cacheOne";
import {navPush} from "../../../../utils/navHelper";
import {makeMyTripsSelector} from '../../../../store/Home';
import {bindActionCreators} from 'redux';
import {actionCreators} from "../../../../store/Home/actions";
import {spaceTo_} from "../../../../utils";
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';
import { WithInertAttribute } from '../../../Elements/WithInert';

const namespace = (): string => 'pages--home--my-trips';

type Props = WithInertAttribute<{}>;
type ConnectedProps = 
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {}>;

@(withRouter as any)
class MyTrips extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  toFacilities = () => navPush(this.props.router, URLS.FACILITIES);
  onCheckout = () => navPush(this.props.router, URLS.CHECKOUT);

  render() {
    const { filteredTrips, inert, apiLoadingMap, cacheOne: {HomepageMyTrips}, router } = this.props;
    let emptyMessage;
    if (filteredTrips.length === 0) {
      if (HomepageMyTrips === null || (HomepageMyTrips && HomepageMyTrips.length === 0)) {
        emptyMessage = 'You have no upcoming trips.';
      } else {
        emptyMessage = 'No trips found.';
      }
    }
    let showAlert = false;
    if (HomepageMyTrips) {
      const anyInCart = HomepageMyTrips.find((trip:HomepageMyTrip) => trip.InCart !== 0);
      if (anyInCart) showAlert = true;
    }
    return (
      <Main inert={inert} isLoading={apiLoadingMap[GetGroupCache.requestType]}  hideEarly rightSidebar={<RightSidebar hideEarly/>}>
        <MainContent handleCompact>
          <ContentBlock>
            {showAlert &&
            <Alert
              actions={(
                <AlertActions>
                  <Button flat textColor="white" icon={CartIcon} onClick={this.onCheckout}>Go to checkout</Button>
                </AlertActions>
              )}
            >
              There are changes or payments for at least one of your trips currently in the cart. Please remember to checkout soon to reserve your spots.
            </Alert>
            }
            {emptyMessage && <EmptyMessage
              icon={FacilitiesIcon}
              description={emptyMessage}
              actions={(
              <EmptyMessageAction onClick={this.toFacilities}>
                Go to facilities
              </EmptyMessageAction>
            )}
            />}
            {filteredTrips.map((trip:HomepageMyTrip, index) => (
              <Trip
                key={index}
                onClick={() => navPush(router, `${URLS.FACILITY_TRIP}/${trip.LocationID}/${trip.GroupTripID}/${spaceTo_(trip.Name)}`)}
                dateStart={trip.StartDateTime}
                dateEnd={trip.EndDateTime}
                name={trip.Name}
                details={`${trip.Location.Name} (${trip.Location.City}, ${trip.Location.State})`}
                total={trip.TotalAmount}
                balance={trip.Balance}
                cart={trip.InCart !== 0}
                youth={trip.NumYouth}
                adults={trip.NumAdults}
              />
            ))}
          </ContentBlock>
          {!emptyMessage && <ContentBlock marginLeft={36} mobileMarginLeft={16}>
            <Link onClick={this.toFacilities} uppercase size={14} medium>
              GO TO FACILITIES
            </Link>
          </ContentBlock>}
          <Media className={`${namespace()}--contact-container`} largeDesktopAndSmaller expand={false}>
            <Contact forceTablet/>
          </Media>
        </MainContent>
      </Main>
    );
  }

}

const mapStateToProps = (state: ApplicationState) => {
  const filteredTrips = makeMyTripsSelector();
  return {
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    filteredTrips: filteredTrips(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheOneActionCreators,
    ...cacheZeroActionCreators
  }, dispatch)
});

const ConnectedMyTrips = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(MyTrips);

export default ConnectedMyTrips;
