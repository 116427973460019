import * as React from 'react';
import Card from "../Card";
import CardTitle from "../Card/Title";
import CardSubTitle from "../Card/SubTitle";
import '../../../styles/elements/financial-summary/index.scss';
import {default as attributes, MarginProps} from '../../../styles';
import {namespace} from "./constants";


interface Props extends MarginProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  subTitle?: string;
  subTitleMarginBottom?: number
  template?: 'mobile-stretch' | 'mobile-no-margin' | 'info';
}

const FinancialSummary: React.SFC<Props> = (
  { className, children, template, style: inheritedStyle, title, subTitle, subTitleMarginBottom, ...props }: Props
): React.ReactElement<Props> => (
  <Card className={attributes(namespace(), className, props)} style={{ ...inheritedStyle }} template={template ? template : 'mobile-no-margin'}>
    <CardTitle marginBottom={subTitle ? 4 : undefined}>{title}</CardTitle>
    {subTitle ? <CardSubTitle marginBottom={subTitleMarginBottom} mobileMarginBottom={subTitleMarginBottom} >{subTitle}</CardSubTitle> : null}
    {children}
  </Card>
);

FinancialSummary.defaultProps = {
  title: 'Financial Summary'
};

export default FinancialSummary;
export { default as FinancialSummaryLine } from './Line';
export { default as FinancialSummaryTotal } from './Total';
export { default as FinancialSummaryDetails } from './Details';
export { default as FinancialSummaryOptions } from './Options';
export { default as FinancialSummaryOption } from './Option';
export { default as FinancialSummaryActions } from './Actions';
