import * as React from 'react';
import { bindActionCreators } from 'redux';
import {withRouter, RouteComponentProps, WithRouterProps} from "react-router";
import { RegisterFooterProgress } from '../../../../../Elements';
import { Step } from '../../../../../Elements/Register/FooterProgress';
import { actionCreators } from "../../../../../../store/Events/Event/Register/Numbers/Main/actions";
import { actionCreators as cacheFourActionCreators } from "../../../../../../store/CacheFourEventsNumbers/actions";
import { actionCreators as spotsActionCreators } from "../../../../../../store/Events/Event/Register/Numbers/Spots/actions";
import { actionCreators as classesActionCreators } from "../../../../../../store/Events/Event/Register/Numbers/Classes/actions";
import { actionCreators as productsActionCreators } from "../../../../../../store/Events/Event/Register/Numbers/Products/actions";
import { actionCreators as campsiteActionCreators } from "../../../../../../store/Events/Event/Register/Numbers/Campsite/actions";
import {
  showClasses, showCampsite, classesNext,
  spotsNext, campsiteBack, showProducts, productsBack, productsNext
} from "../../../../../../store/Events/Event/Register/Numbers/Main/uiHelpers";
import { ApplicationState } from '../../../../../../store';
import { isAdminSelector } from '../../../../../../utils/permissionHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string}>;

export interface FooterProgressProps {
  selected: 'spots' | 'classes' | 'products' | 'campsite_ranking' | 'campsite_assignment' | 'confirmation';
}

const FooterProgress: React.SFC<FooterProgressProps> = ({ apiSaving, apiLoading, selected, actions, isAdmin, cacheTwoEvents, cacheFourEventsNumbers, router, params }: FooterProgressProps & ConnectedProps):React.ReactElement<FooterProgressProps> => {
  const steps: Array<Step> = [
    { selected: selected === 'spots'}
  ];

  const sClasses = showClasses(cacheFourEventsNumbers);
  const sCampsite = showCampsite(cacheTwoEvents);
  const sProducts = showProducts(cacheFourEventsNumbers);

  if (sClasses) steps.push({selected: selected === 'classes'});
  if (sProducts) steps.push({selected: selected === 'products'});
  if (sCampsite) steps.push({ selected: selected === 'campsite_ranking'});
  if (isAdmin) steps.push({ selected: selected === 'campsite_assignment'});
  steps.push({ selected: selected === 'confirmation'});

  let onNextClick, onBackClick, backParam, nextParam;
  if (selected === 'spots') {
    onNextClick = actions.spotsSubmit;
    nextParam = spotsNext(cacheTwoEvents, cacheFourEventsNumbers);
  } else if (selected === 'classes') {
    onNextClick = actions.classesSubmit;
    onBackClick = actions.classesSubmit;
    backParam = 'spots';
    nextParam = classesNext(cacheTwoEvents, cacheFourEventsNumbers);
  } else if (selected === 'products') {
    onNextClick = actions.productsSubmit;
    onBackClick = actions.productsSubmit;
    backParam = productsBack(cacheTwoEvents, cacheFourEventsNumbers);
    nextParam = productsNext(cacheTwoEvents);
  } else if (selected === 'campsite_ranking' || selected === 'campsite_assignment') {
    onBackClick = actions.campsiteSubmit;
    onNextClick = actions.campsiteSubmit;
    backParam = campsiteBack(cacheFourEventsNumbers);
    nextParam = 'confirmation';
  } else if (selected === 'confirmation') {
    if (sCampsite) onBackClick = () => actions.selectPage('campsite_ranking');
    else if (isAdmin) onBackClick = () => actions.selectPage('campsite_assignment');
    else if (sProducts) onBackClick = () => actions.selectPage('products');
    else if (sClasses) onBackClick = () => actions.selectPage('classes');
    else onBackClick = () => actions.selectPage('spots');
  }

  return <RegisterFooterProgress
    loading={apiSaving > 0 || apiLoading > 0}
    onComplete={actions.numbersSave}
    onBackClick={onBackClick}
    backParam={backParam}
    onNextClick={onNextClick}
    nextParam={nextParam}
    steps={steps}
  />;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
    cacheTwoEvents: state.cacheTwoEvents,
    cacheFourEventsNumbers: state.cacheFourEventsNumbers,
    isAdmin: isAdminSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...spotsActionCreators,
  ...campsiteActionCreators,
  ...classesActionCreators,
  ...productsActionCreators,
  ...cacheFourActionCreators
} as any, dispatch) });
const ConnectedFooterProgress = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<FooterProgressProps & WithRouterProps>(),
)(FooterProgress);

export default withRouter<FooterProgressProps>(ConnectedFooterProgress);
