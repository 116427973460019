import * as React from 'react';
import { bindActionCreators } from 'redux';
import { CacheThreeFacilitiesContext } from "@tentaroo/shared";

import { Main, MainContent } from '../../../../Layouts';
import { Alert, Button, ContactCard, ContentBlock, LoadingAll, Media, Row } from '../../../../Elements';
import Sidebar from '../../Sidebar';
import PageHeader from './PageHeader';
import FinancialSummary from '../../../Events/Event/Registration/FinancialSummary';
import Reservations from './Reservations';
import { actionCreators, FacFSSubmitActions } from "../../../../../store/Facilities/Trip/Summary/actions";
import '../../../../../styles/pages/facilities/trip/summary/index.scss';
import { RouteComponentProps, withRouter } from "react-router";
import { navPush } from "../../../../../utils/navHelper";
import { FAC_TRIP_ADD, FAC_TRIP_RESERVATION, getTripItineraryReportUrl, URLS } from "../../../../../constants/urls";
import {
  actionCreators as cacheZeroActionCreators,
} from "../../../../../store/CacheZero/actions";
import {
  actionCreators as appActionCreators,
  ShowTopFloatingAlert
} from "../../../../../store/App/actions";
import {
  actionCreators as cacheTwoFacilitiesActionCreators,
} from "../../../../../store/CacheTwoFacilities/actions";
import {
  actionCreators as cacheThreeFacilitiesActionCreators,
  GetFacilityTripData
} from "../../../../../store/CacheThreeFacilities/actions";
import {
  actionCreators as rollbackActionCreators,
} from "../../../../../store/Rollback/actions";
import {
  actionCreators as cacheOneActionCreators,
} from "../../../../../store/CacheOne/actions";
import { makeSelectedLocationSelector } from "../../../../../store/CacheTwoFacilities";
import { getFullAddress } from "../../../../../utils";
import {
  makeReservationSelector,
  makeSelectedFacilityLocationTripSelector
} from "../../../../../store/CacheThreeFacilities";
import { TripReservation } from "../../../../../models/api/cacheThreeFacilities";
import {
  actionCreators as locationActionCreators,
} from "../../../../../store/Facilities/Location/actions";
import { FacilitiesRecalc } from "../../../../../store/CacheFourFacilities/actions";
import { checkPermission } from "../../../../../utils/permissionHelper";
import { NO_BOOKING } from "../../../../../constants/messages/generic";
import { TRIP_RED_ALERT } from "../../../../../constants/messages/trip";
import EmptyReservation from './EmptyReservation';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { ApplicationState } from '../../../../../store';
import { shouldBlockActions } from '../../../../../utils/cacheLoaders/helpers/blockers';
import { shouldReconfigRouter } from '../../../../../utils/cacheLoaders/reloaderHelper';
import EndUserCacheManager from '../../../../../utils/cacheManagers/endUserCacheManager';
import { isPathUnderEndUserFacilityCacheThree } from '../../../../../utils/helpers/endUserPageHelper';
import { connect } from 'react-redux';
import { reduxStoreService } from '../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isCacheThreeFacilitiesPopulated, isEndUserCacheOnePopulated} from '../../../../../utils/cachePopulatedCheckers/endUser';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--facilities--trip--summary';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, { id: string; locId: string; tripId: string; name: string; }>
>;

@(withRouter as any)
class Summary extends ComponentUpdateTemplate<ConnectedProps> {
  public props: ConnectedProps;
  private nextLocation;

  public componentDidMount() {
    this.configRouter();
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeFacilities({
          props: this.props,
          isEdit: true,
          isStateNavigated,
          context: CacheThreeFacilitiesContext.TRIP_SUMMARY,
        });
      }
    );
  }
  public componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        EndUserCacheManager.getInstance().loadCacheThreeFacilities({
          props: nextProps,
          isEdit: true,
          isStateNavigated,
          context: CacheThreeFacilitiesContext.TRIP_SUMMARY,
        });
      }
    );

    if (shouldBlockActions()) return;
    if (nextProps.summary.isOtherRecentlySelected) {
      nextProps.actions.otherNotRecentlySelected();
      nextProps.actions.financeDirty();
    }
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
  }

  configRouter() {
    const { router, routes } = this.props;
    router.setRouteLeaveHook(routes[routes.length - 1], this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    this.nextLocation = nextLocation;
  };

  componentWillUnmount() {
    const {cacheThreeFacilities} = this.props;

    // if we are not navigating to cache below cache three facility, clear cache
    if (
      this.nextLocation &&
      !isPathUnderEndUserFacilityCacheThree(this.nextLocation.pathname) &&
      isCacheThreeFacilitiesPopulated(cacheThreeFacilities)
    ) {
      this.props.actions.clearCacheBelowTwoFacilities();
    }
    this.resetRouteLeaveHook();
  }

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  closeSidebar = () => this.props.actions.showSidebar(false);
  openSidebar = () => this.props.actions.showSidebar(true);
  onAddReservation = () => {
    const { router, params, cacheThreeFacilities: { FacilityTripPermissions } } = this.props;

    if (!FacilityTripPermissions) return;

    checkPermission(
      () => {
        navPush(router, `${URLS.FACILITY_TRIP}/${params.locId}/${params.tripId}/${params.name}/${FAC_TRIP_RESERVATION}/${FAC_TRIP_ADD}`);
      },
      FacilityTripPermissions.hasAllowAdd,
      FacilityTripPermissions.hasAllowAddReason
    );
  };
  onCheckout = () => navPush(this.props.router, URLS.CHECKOUT);

  onCancelRollback = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.props.actions.restoreState();
  };

  onRestore = (reservation: TripReservation) => {
    const { actions, cacheThreeFacilities: { FacilityTripPermissions }, user } = this.props;
    const isAdmin = user.user.str_permissions.hasAdminAccess;

    if (
      !FacilityTripPermissions
    ) return;

    if (!FacilityTripPermissions.hasAllowChange) {
      reduxStoreService().dispatch(new ShowTopFloatingAlert(FacilityTripPermissions.hasAllowChangeReason ?? "", false, 'orange'));
    } else if (!isAdmin && !reservation.AllowOnlineFacilityBooking) {
      reduxStoreService().dispatch(new ShowTopFloatingAlert(NO_BOOKING, false, 'orange'));
    } else {
      actions.pushModal(ModalTypes.CANCEL_RESERVATION, true, false, {
        inCart: reservation.InCart,
        reservationID: reservation.ID,
        reservationName: reservation.Name,
        wasFinalized: reservation.WasFinalized,
      });
    }
  };

  onSchedule = () => {
    const { cacheThreeFacilities: { tripID, FacilityTripPermissions }, cacheTwoFacilities: { locationID } } = this.props;

    if (!FacilityTripPermissions) return;

    const onReport = () => {
      const url = getTripItineraryReportUrl({ LocationID: locationID, TripID: tripID });
      window.open(url);
    };
    checkPermission(
      onReport,
      FacilityTripPermissions.hasViewReports,
      FacilityTripPermissions.hasViewReportsReason
    );
  };

  onSaveOthers = () => {
    const {actions, cacheThreeFacilities: {FacilityTripPermissions}} = this.props;

    if (!FacilityTripPermissions) return;

    checkPermission(
      () => actions.saveFinance(),
      FacilityTripPermissions.hasFacilityReservations,
      FacilityTripPermissions.hasFacilityReservationsReason,
    );
  };

  private renderContent() {
    const {
      summary: { isFinanceDirty, ActiveForm: { selectedFinance, otherValue }, ValidationRules }, apiSavingMap,
      user, actions, selectedLocation, cacheThreeFacilities: { FacilityTrip, TripReservations, FacilityTripPermissions },
      activeReservations, cacheOne,
    } = this.props;

    if (!FacilityTripPermissions) return;

    const showRedAlert = FacilityTrip && FacilityTrip.InCart !== 0;
    const showOrangeAlert = !FacilityTripPermissions.hasAllowAdd;
    const ret: Array<any> = [];
    ret.push(
      <Media tablet newApi>
        <ContentBlock marginBottom={(showRedAlert || showOrangeAlert) ? 16 : undefined} mobileMarginBottom={0}>
          <ContactCard
            map={true}
            multiple={false}
            onMapClick={this.openSidebar}
            onContactsClick={this.openSidebar}
            title={selectedLocation.Name}
            fullAddress={getFullAddress(selectedLocation.Location)}
          />
        </ContentBlock>
      </Media>
    );


    if (showRedAlert) {
      ret.push(
        <Row>
          <Alert alertIcon color="red" noFlex className={`${namespace()}--alert`}>
            {TRIP_RED_ALERT}
          </Alert>
        </Row>
      );
    }
    if (showOrangeAlert) {
      ret.push(
        <Row>
          <Alert noFlex className={`${namespace()}--alert`}>
            {FacilityTripPermissions.hasAllowAddReason}
          </Alert>
        </Row>
      );
    }
    if (TripReservations && TripReservations.length > 0 && FacilityTrip) {
      const fees: Array<{ label: string; value: number; }> = [];
      if (FacilityTrip.ReservationTotalsByType) {
        FacilityTrip.ReservationTotalsByType.forEach((r) => {
          fees.push({
            label: r.Name,
            value: r.Amount
          });
        });
      }
      ret.push(
        <FinancialSummary
          key="fs"
          fees={fees}
          paymentStatus={FacilityTrip}
          showLoaders={false}
          selected={selectedFinance as 'min' | 'full' | 'other' | 'credit'}
          isFinanceDirty={isFinanceDirty}
          onSelect={actions.updateValue}
          onSaveOther={this.onSaveOthers}
          onCancelOther={this.onCancelRollback}
          onCheckout={this.onCheckout}
          updateValue={actions.updateValue}
          simpleUpdate={actions.simpleUpdate}
          validationRules={ValidationRules}
          isSaving={apiSavingMap[FacFSSubmitActions.requestType]}
          otherValue={otherValue}
          IsAdmin={user.user ? user.user.str_permissions.hasAdminAccess : false}
          hasAccess={FacilityTripPermissions.hasFacilityReservations}
          hasAccessMessage={FacilityTripPermissions.hasFacilityReservationsReason}
          CartOrderItems={this.props.cacheOne.CartOrderItems}
        />
      );
      ret.push(<Reservations
        cacheTwoFacilities={this.props.cacheTwoFacilities}
        cacheThreeFacilities={this.props.cacheThreeFacilities}
        activeReservations={activeReservations}
        onRestore={this.onRestore}
        onSchedule={this.onSchedule}
      />);
    } else {
      ret.push(
        <EmptyReservation
          actions={<Button color="green" big onClick={this.onAddReservation}>ADD RESERVATION</Button>} />
      );
    }
    return ret;
  }

  public render(): any {
    const {
      params, selectedLocation, SystemSettings, facilityLocation: { showSidebar, showMapMobile },
      summary: { restoringReservation }, actions, selectedTrip, apiSaving, apiLoadingMap,
      cacheThreeFacilities: { FacilityTripPermissions, FacilityTrip }, cacheOne, inert
    } = this.props;
    if (!isEndUserCacheOnePopulated(cacheOne) || !selectedLocation || !selectedTrip || !FacilityTripPermissions || !SystemSettings) {
      return <LoadingAll />;
    }

    const ret: Array<any> = [];

    // QUESTION: Why not just render `<Main />`?
    ret.push(
      <Main
        inert={inert}
        leftSidebar={selectedLocation ? <Sidebar
          systemSettings={SystemSettings}
          location={selectedLocation.Location}
          contactInfo={selectedLocation.FacilitiesContactInfo}
          open={!!showSidebar}
          onClose={this.closeSidebar}
          contactInfoLabel="Facilities Contact"
        /> : undefined}
        rightGutter={false}
        isLoading={apiSaving > 0 || apiLoadingMap[FacilitiesRecalc.requestType] || apiLoadingMap[GetFacilityTripData.requestType]}
      >
        <MainContent
          header={<PageHeader
            facilityLocation={selectedLocation}
            tentarooLocation={selectedLocation.Location}
            contactInfo={selectedLocation.FacilitiesContactInfo}
            image={selectedLocation.FeaturedImage}
            systemSettings={SystemSettings}
            showMapMobile={showMapMobile}
            onShowMapMobile={actions.toggleMapMobile}
            facilityTrip={selectedTrip}
            locationId={Number(params.locId)}
            FacilityTripPermissions={FacilityTripPermissions}
          />}
          rightGutter
          className={`${namespace()}--main`}
        >
          {!FacilityTrip ? null : this.renderContent()}
        </MainContent>
      </Main>
    );

    return ret;
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedLocation = makeSelectedLocationSelector();
  const activeReservations = makeReservationSelector();
  const selectedTrip = makeSelectedFacilityLocationTripSelector();
  return {
    user: state.user,
    summary: state.facilities.trip.summary,
    SystemSettings: state.session.SystemSettings,
    facilityLocation: state.facilities.location,
    facilitiesLocations: state.facilities.locations,
    apiSavingMap: state.app.apiSavingMap,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheThreeFacilities: state.cacheThreeFacilities,
    cacheTwoFacilities: state.cacheTwoFacilities,
    cacheOne: state.cacheOne,
    cacheZero: state.cacheZero,
    selectedLocation: selectedLocation(state),
    activeReservations: activeReservations(state),
    selectedTrip: selectedTrip(state),
    app: state.app,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...rollbackActionCreators,
    ...actionCreators,
    ...cacheThreeFacilitiesActionCreators,
    ...cacheTwoFacilitiesActionCreators,
    ...cacheOneActionCreators,
    ...cacheZeroActionCreators,
    ...locationActionCreators,
    ...appActionCreators
  }, dispatch)
});

const ConnectedSummary = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Summary);

export default ConnectedSummary;
export { default as FacilitiesTripSummaryHeader } from './Header';
