import {ActionCreator} from "../../../../index";
import {
  createApiSubmitActions,
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions
} from "../../../../Validation/actionCreator";
import {validateAll} from "../../../../../utils/validator";
import {FacilitiesReservationSelectPage, FacilitiesReservationSetPendingPage} from "../actions";
import {SaveState} from "../../../../Rollback/actions";
import {getFacilitiesReservationsRecalcBody} from "../../../../../constants/urls";
import {FacilitiesAvailability} from "../../../../../models/api/cacheTwoFacilties";
import {FacilityTrip} from "../../../../../models/api/cacheThreeFacilities";
import {makeSelectedFacilityTypeC4Selector} from "../../../../CacheFourFacilities";
import { captureTentarooError } from '../../../../../utils/dataHelper';
import { typeName, Action } from "../../../../../utils/StrongActions";

export const SUFFIX = '__RESERVATION_FORM';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);


export const SUFFIX_CHANGE = '__RESERVATION_FORM_CHANGE';
export const ReservationChange = createApiSubmitActions(SUFFIX_CHANGE, true);

@typeName("FACILITIES_RESERVATION_RESERVATION_SHOW_MORE") export class FacilitiesReservationReservationShowMore extends Action {
  constructor(public show: boolean) { super(); }
}
@typeName("FACILITIES_RESERVATION_RESERVATION_RESET") export class FacilitiesReservationReservationReset extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  reset: (): ActionCreator => dispatch => dispatch(new FacilitiesReservationReservationReset()),
  showMore: (showMore: boolean): ActionCreator => dispatch => dispatch(new FacilitiesReservationReservationShowMore(showMore)),
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.facilities.trip.reservation.reservation),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  reservationChange: (
    typeID: number,
    avail: FacilitiesAvailability,
    trip: FacilityTrip,
    IsUserStartDateChange?: boolean,
    resId?: number,
    FlatRate?: number,
    PerPersonRate?: number
  ): ActionCreator => (dispatch, getState) => {
    const rootState = getState();

    if (!rootState.cacheZero.options) {
      captureTentarooError(new Error("cacheZero.options not available when performing reservationChange"));
      return;
    }
    if (!rootState.cacheZero.options.Group) {
      captureTentarooError(new Error("Group not available when performing reservationChange"));
      return;
    }
    const GroupIDi = rootState.cacheZero.options.Group.IDi;
    const GroupTS = rootState.cacheZero.options.Group.TS;
    const FacilityLocationID = rootState.cacheTwoFacilities.locationID;
    const FacilityTripID = rootState.cacheThreeFacilities.tripID;
    const Reservation = rootState.cacheFourFacilities.Reservation;
    const FacilityTypeID = typeID;
    const {StartDateTime, EndDateTime, NumYouth, NumAdults} = rootState.facilities.trip.reservation.reservation.ActiveForm;

    if (EndDateTime && EndDateTime !== '' && (typeof StartDateTime === 'string' || !StartDateTime)) {
      dispatch(new SaveState());
      return;
    }
    if (typeof EndDateTime === 'string' && EndDateTime !== '') {
      dispatch(new SaveState());
      return;
    }
    // No need to recalculate if only end date is set, or if only start date is set and !IsUserStartDateChange
    if (StartDateTime && !EndDateTime && !IsUserStartDateChange) {
      dispatch(new SaveState());
      return;
    }

    if (!Reservation?.FacilityID) {
      captureTentarooError(new Error("Reservation.FacilityID not available when performing reservationChange"));
      return;
    }
    if (Reservation?.MinimumPeopleBilledAtPerPersonRates === null) {
      captureTentarooError(new Error("Reservation.MinimumPeopleBilledAtPerPersonRates is null when performing reservationChange"));
      return;
    }
    if (NumYouth === undefined) {
      captureTentarooError(new Error("NumYouth is undefined when performing reservationChange"));
      return;
    }
    if (NumAdults === undefined) {
      captureTentarooError(new Error("NumAdults is undefined when performing reservationChange"));
      return;
    }
    dispatch(ReservationChange.request(getFacilitiesReservationsRecalcBody(
      {
        GroupIDi,
        GroupTS,
        FacilityLocationID,
        FacilityTripID,
        FacilityTypeID,
        ReservationID: resId
      },
      Reservation.FacilityID,
      NumYouth,
      NumAdults,
      FlatRate ? FlatRate : Reservation.FlatRate,
      PerPersonRate ? PerPersonRate : Reservation.PerPersonRate,
      Reservation.MinimumPeopleBilledAtPerPersonRates,
      avail.ftbID,
      StartDateTime,
      EndDateTime,
      IsUserStartDateChange
    ), null));
  },
  reservationSubmit: (resId?: number): ActionCreator => (dispatch, getState) => {
    const reservation = getState().facilities.trip.reservation.reservation;
    if (!reservation.recalcSuccess) {

      const validationResults = validateAll(s => s.facilities.trip.reservation.reservation);
      if (validationResults) {
        dispatch(ReservationChange.clientFailure(validationResults, true));
        dispatch(new SaveState());

        const startOfForm = document.getElementsByClassName('reservation-reservation')?.[0];
        if (startOfForm && "offsetTop" in startOfForm && typeof startOfForm.offsetTop === "number") {
          window.scrollTo(0, startOfForm.offsetTop - 70);
        }
      } else {
        const rootState = getState();
        if (!rootState.cacheZero.options) {
          captureTentarooError(new Error("cacheZero.options not available when performing reservationSubmit"));
          return;
        }
        if (!rootState.cacheZero.options.Group) {
          captureTentarooError(new Error("Group not available when performing reservationSubmit"));
          return;
        }
        const GroupIDi = rootState.cacheZero.options.Group.IDi;
        const GroupTS = rootState.cacheZero.options.Group.TS;
        const FacilityLocationID = rootState.cacheTwoFacilities.locationID;
        const FacilityTripID = rootState.cacheThreeFacilities.tripID;
        const Reservation = rootState.cacheFourFacilities.Reservation;
        const FacilityAvailabilities = rootState.cacheFourFacilities.FacilityAvailabilities;
        const selectedFacilityType = makeSelectedFacilityTypeC4Selector();
        const fType = selectedFacilityType(rootState);
        const FacilityTypeID = fType.ID;

        const {StartDateTime, EndDateTime, NumYouth, NumAdults} = rootState.facilities.trip.reservation.reservation.ActiveForm;

        dispatch(new SaveState());
        if (!Reservation?.FacilityID) {
          captureTentarooError(new Error("Reservation.FacilityID not available when performing reservationSubmit"));
          return;
        }
        if (Reservation.MinimumPeopleBilledAtPerPersonRates === null) {
          captureTentarooError(new Error("Reservation.MinimumPeopleBilledAtPerPersonRates is null when performing reservationSubmit"));
          return;
        }
        if (NumYouth === undefined) {
          captureTentarooError(new Error("NumYouth is undefined when performing reservationSubmit"));
          return;
        }
        if (NumAdults === undefined) {
          captureTentarooError(new Error("NumAdults is undefined when performing reservationSubmit"));
          return;
        }
        dispatch(ReservationChange.request(getFacilitiesReservationsRecalcBody(
          {
            GroupIDi,
            GroupTS,
            FacilityLocationID,
            FacilityTripID,
            FacilityTypeID,
            ReservationID: resId
          },
          Reservation.FacilityID,
          NumYouth,
          NumAdults,
          Reservation.FlatRate,
          Reservation.PerPersonRate,
          Reservation.MinimumPeopleBilledAtPerPersonRates,
          FacilityAvailabilities?.ftbID,
          StartDateTime,
          EndDateTime,
          false
        ), null));
        dispatch(new FacilitiesReservationSetPendingPage('confirmation'));
      }

    } else {
      if (reservation.SubmitErrorMessage) {
        const startOfForm = document.getElementsByClassName('reservation-reservation')?.[0];
        if (startOfForm && "offsetTop" in startOfForm && typeof startOfForm.offsetTop === "number") {
          window.scrollTo(0, startOfForm.offsetTop - 70);
        }
        return;
      }
      const validationResults = validateAll(s => s.facilities.trip.reservation.reservation);
      if (validationResults) {
        dispatch(ReservationChange.clientFailure(validationResults, true));

        const startOfForm = document.getElementsByClassName('reservation-reservation')?.[0];
        if (startOfForm && "offsetTop" in startOfForm && typeof startOfForm.offsetTop === "number") {
          window.scrollTo(0, startOfForm.offsetTop - 70);
        }
      } else {
        dispatch(new FacilitiesReservationSelectPage('confirmation'));
      }
    }
  }
};
