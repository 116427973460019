
import { captureException, withScope } from '@sentry/browser';
import * as GM from '../constants/messages/generic';
import { GalleryImage } from '../models/api/cacheOne';
import { BaseValidator } from './validator/models';
import { reduxStoreService } from '../store/service';

// We think the request will use UTF-8 for characters, but we use 2 bytes per character here anyway
const BYTES_PER_CHAR = 2;
const MAXIMUM_SENTRY_PAYLOAD_IN_BYTE = 120 * 1000;  // 120kb

export const makeDeletedPrefixItemMapper = (key: string, preprocess?: (item: any) => any) => {

  return (item: {Inactive: boolean}) => {
    const result = preprocess ? preprocess(item) : item;
    if (result && result.Inactive) {
      return {
        ...result,
        [key]: GM.DROPDOWN_ITEM_DELETED_PREFIX + result[key],
      };
    }

    return result;
  };
};

export const moveItemInArrayWithOffset = (arr: any[], originalIndex: number, offset: number) => {
  // do nothing since item is at the beginning/end of the array already
  if ((originalIndex === 0 && offset < 0) || (originalIndex === arr.length - 1 && offset > 0)) return;
  // To move an item, we do the following operation
  // If moving towards the end of array
  //  - Insert the item we are moving at the target index (originalIndex + offset + 1, because the item has not been removed yet)
  //  - Remove the item from its original index
  // If moving towards the begining of array
  //  - Same operation, except that the target index is now `originalIndex + offset`, because we dont need to count the current item even it is not removed from array yet
  if (offset > 0) {
    arr.splice(originalIndex + offset + 1, 0, arr[originalIndex]);
    arr.splice(originalIndex, 1);
  } else {
    arr.splice(originalIndex + offset, 0, arr[originalIndex]);
    arr.splice(originalIndex + 1, 1);
  }
};

export const filteredPhotoGalleryCombiner = (gallery: GalleryImage[], showDeleted: boolean) => {
  if (showDeleted) return [...gallery];
  return gallery ? gallery.filter((item) => !item.Inactive) : [];
};

export const handleDeleteImageFailure = (images: any[], imageId: number, returnedImage: any) => {
  if (!images) return [];
  return images.map((image) => ({
    ...image,
    Inactive: (image.ID === imageId && !!returnedImage) ? returnedImage.Inactive : image.Inactive,
    loading: image.ID === imageId ? false : image.loading,
    uploading: image.ID === imageId ? false : image.uploading,
  }));
};

export const handleAddImageFailure = (images: any[], tempId: number) => {
  const failedImageIndex = images ? images.findIndex((i) => i.tempId === tempId) : -1;
  if (failedImageIndex !== -1) {
    images.splice(failedImageIndex, 1);
  }
};

export const updateEntityInList = (newState, listKey: string, item, idKey?: string, customDataMapper?: (oldData, newData: any) => any) => {
  if (!newState[listKey]) {
    newState[listKey] = [];
  }
  let found = false;
  newState[listKey] = newState[listKey].map((oldItem) => {
    if ((!idKey && oldItem.ID === item.ID) || (idKey && oldItem[idKey] === item[idKey])) {
      found = true;
      return customDataMapper ? customDataMapper(oldItem, item) : {
        ...item,
        RowNum: oldItem.RowNum,
      };
    }
    return oldItem;
  });

  if (!found) {
    newState[listKey] = [...newState[listKey]];
    newState[listKey].push(item);
  }
};

const validateVObj = (vObj: any, key: string, field: string) => {
  return !!vObj && !!vObj[key] && !!vObj[key][field];
}; 

/**
 * NOTE: Never call this in reducer
 */
export const SectionCreator = (obj: any, keys: any, isPrevious?: boolean, vObj?: any): any => {
  const result = { IsPrevious: !!isPrevious };

  return _formCreator(obj, keys, result, vObj);
};

const _formCreator = (obj: any, keys: any, result: any, vObj?: Record<string, BaseValidator>): any => {
  // It is safe to call `getState()` here, because this function is only called in thunks
  const rootState = reduxStoreService().getState();
  for (let key in obj) {
    if (keys[key]) {
      if (validateVObj(vObj, key, "skip") && vObj?.[key]?.skip?.(rootState)) {
        // if the field is skipped, set it to null
        result[key] = null;
      } else if (!obj[key] && validateVObj(vObj, key, "validatejs") && vObj?.[key].validatejs[key] && vObj?.[key].validatejs[key].datetime && (!vObj?.[key].isRequired || !vObj?.[key].isRequired?.(rootState))) {
        // if the field is an optional date, set it to null
        result[key] = null;
      } else {
        result[key] = obj[key];
      }
    }
  }

  return result;
};

/**
 * NOTE: Never call this in reducer
 */
export const FormCreater = (obj: any, keys: any, vObj?: any) => {
  const result = {};

  return _formCreator(obj, keys, result, vObj);
};

export const captureEmptyFieldInForm = (form: any, fieldKey: string, context: string) => {
  if (form[fieldKey] === null || form[fieldKey] === undefined) {
    withScope((scope) => {
      captureTentarooError(new Error(`form.${fieldKey} is not set in ${context}`));
    });
  }
};

export const isNumberFieldDirty = (
  cacheValue: string | number | null | undefined,
  formValue: string | number | null | undefined,
) => {
  let isDirty = false;
  if (cacheValue === undefined || cacheValue === null) {
    isDirty = !!formValue;
  } else {
    // Not providing a fallback for NaN because we dont want to say NaN
    // and 0 are the same here
    isDirty = toNumber(cacheValue, NaN) !== toNumber(formValue, NaN);
  }

  return isDirty;
};

export function entityMapper<TEntity>(entitySource: TEntity[], ids: number[], getEntityID: (entity: TEntity) => number) {
  const result = ids.map((id) => {
    const foundEntity = entitySource.find((e) => {
      return getEntityID(e) === id;
    });

    return foundEntity;
  }).filter((r) => !!r);

  return result as TEntity[];
}

export function captureTentarooError(error: Error, payload?: string) {
  if (process.env.NODE_ENV === "development") {
    console.error(error);
  } else {
    if (payload) {
      withScope((scope) => {
        scope.setExtra("payload", ensureSentryPayloadLength(payload));
        captureException(error);
      });
    } else {
      captureException(error);
    }
  }
}

export function notNullFilter<TValue>(value: TValue | null): value is TValue {
  return value !== null;
}

export function notEmptyFilter<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const getDataIdFromUrlOrModalProp = <IRouterParams>(
  getIdFromUrl: (params: IRouterParams) => number,
  params: IRouterParams,
  idFromComponentProps?: number,
): number => {
  return getIdFromUrl(params) || idFromComponentProps || 0;
};

export type TentarooDebugPayload = {
  Error: string;
  AppURL: string;
}
const getRequestPayloadWhenError = (errorMessage: string) => {
  return {
    Error: errorMessage,
    AppURL: `${window.location.pathname}${window.location.search}`,
  };
};

export const withTentarooDebugPayload = <T>(originalPayload: T, debugPayload: TentarooDebugPayload): T & TentarooDebugPayload => {
  return {
    ...originalPayload,
    ...debugPayload,
  };
};

export const captureTentarooErrorAndGetRequestPayload = (errorMessage: string): TentarooDebugPayload => {
  captureTentarooError(new Error(errorMessage));

  return getRequestPayloadWhenError(errorMessage);
};

/**
 * Methods to ensure a payload sent to sentry does NOT exceed its maximum payload size.
 * If it exceeded, we will truncate it.
 * 
 * Refer to this doc (now deprecated): https://docs.sentry.io/enriching-error-data/context/?platform=browser#extra-context
 * there is a maximum payload size limitation in Sentry. So if the `responseText` is more than a certain
 * threshold, we should remove it from the context. Here I'm setting it to be around 120kb
 * 
 * TODO: Later when upgrading Sentry, we should re-visit the size here because `setExtra` is deprecated: https://docs.sentry.io/platforms/flutter/enriching-events/context/#additional-data
 * Related doc: https://develop.sentry.dev/sdk/data-handling/#variable-size
 * 
 * @param payload - payload we will be sending over to Sentry
 */
export const ensureSentryPayloadLength = (payload: string) => {
  const validLength = Math.floor(MAXIMUM_SENTRY_PAYLOAD_IN_BYTE / BYTES_PER_CHAR);
  if (payload && payload.length > validLength) {
    return payload.substring(0, validLength);
  }

  return payload;
};

export type Nullable<T> = T | null | undefined;

export function objectHasProperty(a: object, key: string) {
  /**
   * Using this function because we found that router location.query does
   * NOT inherit JS object, and so it doesn't have this "hasOwnProperty"
   * function, and as a result, calling location.query.hasOwnProperty will
   * crash the app.
   */
  if ("hasOwnProperty" in a) return a.hasOwnProperty(key);
  return key in a;
}

export function toNumber(
  // undefined and null will be converted to NaN and fallback
  value: string | number | undefined | null,
  fallbackIfNaN = 0,
) {
  const numValue = typeof value === "string" || value === undefined || value === null ? Number(value) : value;

  return isNaN(numValue) ? fallbackIfNaN : numValue;
}