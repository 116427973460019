import * as React from 'react';
import NormalIcon from '../../../images/elements/add-button/normal.svg';
import ThickIcon from '../../../images/elements/add-button/thick.svg';
import { FABButton as OriginalFABButton } from 'react-mdl';
import '../../../styles/elements/add-button/index.scss';
import { isMobile } from '../../../utils/isMobile';

export const namespace = (): string => 'elements--add-button';

export interface AddButtonProps {
  marginLeft?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
  green?: boolean;
  thick?: boolean;
  onClick?: () => void;
  id?: string;
}

const FABButton: any = OriginalFABButton;

const AddButton: React.SFC<AddButtonProps> = ({marginLeft, id, green, thick, onClick }: AddButtonProps): React.ReactElement<AddButtonProps> => {
  let className: string = namespace();
  if (green) className += ' green';
  if (thick) className += ' thick';
  const style: React.CSSProperties = {};
  if (marginLeft) style.marginLeft = `${marginLeft}px`;

  const Icon = thick ? ThickIcon : NormalIcon;
  return (
    <FABButton
      mini
      ripple={!isMobile}
      style={style}
      className={className}
      onClick={onClick}
      id={id}
    >
      <Icon/>
    </FABButton>
  );
};

export default AddButton;
