import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import Button from "../../Button";
import Text from "../../Text";
import View from "../../View";
import Polarity from "../../Polarity";
import Row from "../../Grid/Row";
import Column from "../../Grid/Column";
import '../../../../styles/elements/cart/item/index.scss';
import {navPush, spaceTo_} from '../../../../utils';
import {CartOrderItem} from "../../../../models/api/cacheOne";
import {standardCurrencyFormat} from "../../../../utils/classesHelper";
import {EVENT, FAC_TRIP_RESERVATION, getEventRegistrationUrl, URLS} from "../../../../constants/urls";
import {makeSelectedReservationSelector} from "../../../../store/Facilities/Trip/ViewReservation";
import {TripReservation} from "../../../../models/api/cacheThreeFacilities";
import {CacheZeroState} from "../../../../store/CacheZero";
import {ShowTopFloatingAlert} from "../../../../store/App/actions";
import { ModalTypes, isModalOpened } from '../../../../utils/modalHelper';
import { generateDOMId } from '../../../../utils/cypressHelper';
import { reduxStoreService } from '../../../../store/service';

import {CCFeeProduct, ConvenienceFeeProduct, eCheckFeeProduct, namespace, RefundProduct} from "./constants";

export interface ItemProps {
  className?: string;
  CartOrderItem: CartOrderItem;
  sidePadding?: boolean;
  router: any;
  isAdmin: boolean;
  onCancelRegistration: (
    isYouth?: boolean,
    name?: string,
    participantID?: number,
    inCart?: -1 | 0 | 1,
    eventTypeID?: number,
    eventID?: number,
    groupWeekID?: number,
    goToRegistration?: boolean,
    IsNewlyAdding?: boolean
  ) => void;
  onRemove: (id: number, to?: string) => void;
  popModal: (saveBefore: boolean, saveAfter: boolean, modal: ModalTypes) => void;
  cacheZero: CacheZeroState;
  fromModal?: boolean;
}

class Item extends React.Component<ItemProps, {}> {

  onRemove = () => {
    const {cacheZero, CartOrderItem: {ID, ItemType, EventTypeID, EventIDi, Event, IsYouth, ParticipantEventIDi, Name, InCart, GroupWeekIDi, IsAdding, Item_GroupTripID}} = this.props;

    if (cacheZero.options!.GeneralPermissions!.hasGroup) {
      if (ItemType === 'YouthChange' || ItemType === 'AdultChange') {
        if (!InCart && IsAdding) {
          this.props.onRemove(ID);
        } else {
          let goToRegistration;
          const pathname = reduxStoreService().getState().routing.locationBeforeTransitions.pathname;
          if (pathname.includes(URLS.EVENTS)) {
            if (IsYouth) {
              goToRegistration = pathname.includes(`${EVENT.REGISTRATION}/y/${ParticipantEventIDi}`);
            } else {
              goToRegistration = pathname.includes(`${EVENT.REGISTRATION}/y/${ParticipantEventIDi}`);
            }
          }
          this.props.onCancelRegistration(
            IsYouth,
            Name,
            ParticipantEventIDi,
            IsAdding ? 1 : -1,
            EventTypeID,
            EventIDi,
            GroupWeekIDi,
            goToRegistration,
            InCart
          );
        }
      } else if (ItemType === 'Product' || ItemType === 'ClassChange' || ItemType === 'Base') {
        this.props.onRemove(ID);
      } else if (ItemType === 'Trip') {
        const pathname = reduxStoreService().getState().routing.locationBeforeTransitions.pathname;
        const sp = pathname.split('/');
        let to: string | undefined;
        if (sp[6] === FAC_TRIP_RESERVATION && Number(sp[4]) === Item_GroupTripID && sp.length === 8) {
          // then I'm on a page that could be removed
          const selectedReservation = makeSelectedReservationSelector()(reduxStoreService().getState()) as TripReservation;
          if (selectedReservation && !selectedReservation.WasFinalized) {
            const bigSplit = pathname.split(`/${FAC_TRIP_RESERVATION}/`);
            to = `${bigSplit}${reduxStoreService().getState().routing.locationBeforeTransitions.search}`;
          }
        }
        this.props.onRemove(ID, to);
      }
    } else {
      reduxStoreService().dispatch(new ShowTopFloatingAlert(cacheZero.options!.GeneralPermissions!.hasGroupReason!, this.props.fromModal, 'orange'));
    }
  };

  onEdit = () => {
    const {
      router,
      CartOrderItem: {
        LocationID, ItemType, EventTypeID, EventIDi, Event, IsYouth, ParticipantEventIDi, Name, IsIndividualRegistration, IsAdding, Item_GroupTripID
      }
    } = this.props;
    if (ItemType === 'Product' || ItemType === 'YouthChange' || ItemType === 'AdultChange' || ItemType === 'ClassChange' || ItemType === 'Base') {
      const sp = Event.split(',');
      let eventName = '';
      for (let i = 0; i < sp.length -1; i++) {
        eventName = sp[i] + ',';
      }
      eventName = eventName.slice(0, -1);
      let urlParams = {
        params: {
          eventTypeId: EventTypeID,
          eventId: EventIDi,
          name: spaceTo_(eventName)
        }
      };
      let baseUrl = getEventRegistrationUrl(urlParams);
      if (ItemType === 'Base' || ParticipantEventIDi === 0 || IsIndividualRegistration === false || !IsAdding) {
        if (isModalOpened(ModalTypes.CART)) this.props.popModal(true, false, ModalTypes.CART);
        navPush(router, baseUrl);
        return;
      }
      baseUrl = `${baseUrl}/${IsYouth ? 'y' : 'a'}/${ParticipantEventIDi}/${spaceTo_(Name)}`;
      if (isModalOpened(ModalTypes.CART))this.props.popModal(true, false, ModalTypes.CART);
      navPush(router, baseUrl);

    } else if (ItemType === 'Trip') {
      const sp = Name.split(',');
      let tripName = '';
      for (let i = 0; i < sp.length -1; i++) {
        tripName = sp[i] + ',';
      }
      tripName = tripName.slice(0, -1);
      if (isModalOpened(ModalTypes.CART))this.props.popModal(true, false, ModalTypes.CART);
      navPush(router, `${URLS.FACILITY_TRIP}/${LocationID}/${Item_GroupTripID}/${spaceTo_(tripName)}`);
    }
  };

  render() {
    const { sidePadding, CartOrderItem, isAdmin } = this.props;
    const {
      ItemType, Name, ClassName, TimeBlock, IsAdding, IsIndividualRegistration, ParticipantType, Amount, Event, Quantity,
      TotalAmount, Item_ProductIDi, InCart, IsFinalizedChange, EventIDi
    } = CartOrderItem;
    const showProductChange = ItemType !== "Product" ||
      isAdmin ||
      (
        Item_ProductIDi !== RefundProduct &&
        Item_ProductIDi !== ConvenienceFeeProduct &&
        Item_ProductIDi !== CCFeeProduct &&
        Item_ProductIDi !== eCheckFeeProduct
      );
    let positive = false;
    let negative = false;
    let showEdit = true;
    let showRemove = true;
    let name = '';
    let info = '';
    if (IsAdding) {
      positive = true;
    } else {
      negative = true;
    }

    if (ItemType === 'Product') {
      name = Name;
      if (Quantity && Quantity > 0) {
        name += `, Quantity: ${Quantity}`;
      }
    } else if (ItemType === 'ClassChange') {
      name = `${ClassName} (${TimeBlock !== 500 ? TimeBlock : 'Unscheduled'})${IsIndividualRegistration ? `, ${Name}` : ''}`;
    } else if (ItemType === 'AdultChange' || ItemType === 'YouthChange') {
      name = `${ParticipantType}: ${Name}`;
    } else if (ItemType === 'Base') {
      name = Amount > 0 ? 'Event Registration Payment / Update' : 'Event Registration Update';
    } else if (ItemType === 'Trip') {
      name = Amount > 0 ? 'Trip Payment / Update' : 'Trip Update';
    }

    if (ItemType === 'ClassChange' || ItemType === 'AdultChange' || ItemType === 'YouthChange' || ItemType === 'Base') {
      info = Event;
    } else if (ItemType === 'Trip') {
      info = Name;
    } else if (ItemType === 'Product') {
      if (EventIDi > 0) {
        info = `${Event}`;
      }
    }

    if (ItemType === 'Product') {
      showEdit = showProductChange && Item_ProductIDi !== RefundProduct && EventIDi > 0;
      showRemove = showProductChange;
    } else if (ItemType === 'ClassChange') {
      showRemove = !InCart && !IsFinalizedChange && IsAdding && IsIndividualRegistration;
    }

    let className = mergeClassNames(namespace(), this.props);

    if (sidePadding) className += ' side-padding';

    return (
      <View className={className}>
        <Column width={40} verticalAlignment="top" gutter={false}>
          <Polarity positive={positive} negative={negative}/>
        </Column>
        <Column expand layout="vertical">
          <Row>
            <Column expand layout="vertical">
              <Text marginBottom={4}>
                {name}
              </Text>
              <Text color="gray" size={14} marginBottom={8}>
                {info}
              </Text>
            </Column>
            <Column verticalAlignment="top">
              <Text weight="medium">
                {standardCurrencyFormat(TotalAmount)}
              </Text>
            </Column>
          </Row>
          <Row>
            {showEdit && <Button color="white" onClick={this.onEdit}>Edit</Button>}
            {showRemove && <Button id={generateDOMId(`remove-cart-item-${CartOrderItem.ID}`)} color="white" textColor="red" onClick={this.onRemove}>Remove</Button>}
          </Row>
        </Column>
      </View>
    );
  }
}

export default Item;
