import {makeRequestEpic} from "../../../../Validation/epicCreator";
import {AddContactSubmitActions, DeleteContactSubmitActions} from "./actions";
import {URLS, CMS_PATHS, getUpdateContactUrl} from "../../../../../constants/urls";
import {AjaxResponse} from "rxjs/ajax";
import {spaceTo_} from "../../../../../utils";
import { ApplicationState } from "../../../../../store";
import { CMSContact } from "../../../../../models/api/adminCMSCacheOne";
import { NoOp } from "../../../../../store/App/actions";
import { ToggleContactSelect } from "../../Modals/MultipleContacts/actions";
import { Observable } from "rxjs";
import { ADMIN_CMS_CONTACT_EDIT_PATH, ADMIN_CMS_CONTACT_NEW_PATH } from "../../../../../routes";
import { ENTITY_EDITED, ENTITY_CREATED, ENTITY_PARSE_ERROR, ENTITY_DELETED, ENTITY_RESTORED, CONTACT } from "../../../../../constants/messages/adminCMS";
import { ModalTypes, isModalOpened } from "../../../../../utils/modalHelper";
import { captureTentarooError } from "../../../../../utils/dataHelper";
import { reduxStoreService } from "../../../../service";

export const adminCMSSiteAddContactEpic = makeRequestEpic(
  AddContactSubmitActions,
  getUpdateContactUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const formData = response.request.body;
    try {
      const JSONDataStr = formData.get('JSONData');
      const JSONData = JSON.parse(JSONDataStr);
      if (JSONData.row.ID) {
        return ENTITY_EDITED(CONTACT);
      }
      return ENTITY_CREATED(CONTACT);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(CONTACT)));
      return '';
    }
  },
  (response: AjaxResponse, action: any) => {
    const contact = response.response.CMSContact as CMSContact;
    const state = reduxStoreService().getState() as ApplicationState;
    const site = state.adminCMSSite.cacheOne.CMSSite;
    const qp = reduxStoreService().getState().routing.locationBeforeTransitions.search;
    const formData = response.request.body;
    try {
      const JSONDataStr = formData.get('JSONData');
      const JSONData = JSON.parse(JSONDataStr);
      if (action.currentRoute && action.currentRoute.path !== ADMIN_CMS_CONTACT_EDIT_PATH && action.currentRoute.path !== ADMIN_CMS_CONTACT_NEW_PATH) {
        // We should stay after the request completes in the following scenarios:
        // - Not in NEW CONTACT endpoint
        // - Not in EDIT CONTACT endpoint
        return '';
      } else if (contact && site) {
        const ContactName = contact.Name;
        return `${URLS.ADMIN_CONTACTS}/${site.ID}/${contact.ID}${ContactName ? `/${spaceTo_(ContactName)}` : '' }${qp}`;
      }
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(CONTACT)));
      return '';
    }
    // otherwise, if `contact` is returned AND CMSSite is available in cacheOne, navigate back to the contact list
    if (contact && site) {
      return `${URLS.ADMIN_SITES}/${site.ID}/${site.SiteDomain}/${CMS_PATHS.CONTACTS}${qp}`;
    }

    return '';
  },
  undefined,
  undefined,
  undefined,
  true,
  (payload) => {
    const contact = payload.response.CMSContact;
    const state = reduxStoreService().getState() as ApplicationState;
    const formData = payload.request.body;
    try {
      const JSONDataStr = formData.get('JSONData');
      const JSONData = JSON.parse(JSONDataStr);

      if (isModalOpened(ModalTypes.MULTIPLE_CONTACTS) && !JSONData.row.ID) {
        // When MultipleContacts modal is opened and we are adding a new contact, select that contact
        return Observable.of(new ToggleContactSelect(true, contact.ID));
      }

      return Observable.of(new NoOp());
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(CONTACT)));
      return '';
    }
  },
);


export const adminCMSSiteDeleteContactEpic = makeRequestEpic(
  DeleteContactSubmitActions,
  getUpdateContactUrl,
  undefined,
  true,
  (response: AjaxResponse) => {
    const contact = response.response.CMSContact;

    if (contact.Inactive) {
      return ENTITY_DELETED(CONTACT);
    }
    return ENTITY_RESTORED(CONTACT);
  },
);