import { CMSPage, PageTypeIDValue, Location, CMSSocial, SocialType } from "../../models/api/adminCMSCacheOne";
import { BlogArticleIcon, CalendarEventIcon, LocationsIcon, EventsIcon, FacilitiesIcon, PageIcon, HomeIcon } from '../../components/Icons';
import * as React from 'react';
import { spaceTo_ } from "../stringHelper";
import { AdminCMSSiteCacheOneState } from "../../store/AdminCMSSite/CacheOne";
import { IValidator } from "../../store/AdminCMSSite/Pages/Page/Form/validation";
import { CacheZeroState } from "../../store/CacheZero";
import { checkPermission } from '../permissionHelper';
import { CMSSite } from "../../models/api/options";
import { PAGE_INSUFFICIENT_EDIT_PERMISSION } from "../../constants/messages/adminCMS";
import { AdminCMSSiteCacheTwoPageState } from "../../store/AdminCMSSite/CacheTwoPages";
import { ADMIN_CMS_RESOURCES_PREVIEW_PATH, ADMIN_CMS_RESOURCES_PATH, ADMIN_CMS_RESOURCES_CATEGORIES_PATH, ADMIN_CMS_CONTACTS_PATH, ADMIN_CMS_CONTACT_PREVIEW_PATH, ADMIN_CMS_SETTINGS_GENERAL_PATH, ADMIN_CMS_SETTINGS_MESSAGES_PATH, ADMIN_CMS_PAGES_PATH, ADMIN_CMS_PAGE_MENUS_PATH, ADMIN_CMS_SITE_MENUS_PATH, ADMIN_CMS_SITE_MENU_EDIT_PATH, ADMIN_CMS_PAGE_MENU_EDIT_PATH } from '../../routes';
import { extractRouteParams } from '../../utils/urlHelper';
import { Actions as CacheTwoResourcesActions } from '../../store/AdminCMSSite/CacheTwoResources/actions';
import { Actions as CacheTwoContactsActions } from '../../store/AdminCMSSite/CacheTwoContacts/actions';
import { Actions as AppActions } from '../../store/App/actions';
import { Actions as CacheOneActions } from '../../store/AdminCMSSite/CacheOne/actions';
import { Actions as CacheTwoPageMenusActions } from '../../store/AdminCMSSite/CacheTwoPageMenus/actions';
import { Actions as CacheTwoSiteMenusActions } from '../../store/AdminCMSSite/CacheTwoSiteMenus/actions';
import { Actions as RollbackActions } from '../../store/Rollback/actions';
import { RouteComponentProps } from "react-router";
import { constructGetPageListBaseParams } from "../../store/AdminCMSSite/Pages/Home/uiHelpers";
import { reduxStoreService } from "../../store/service";

type RefreshActions = CacheTwoResourcesActions & CacheOneActions & CacheTwoContactsActions & RollbackActions & AppActions & CacheTwoPageMenusActions & CacheTwoSiteMenusActions;

export interface IAdminCMSRouterParams {
  [key: string]: any;
  siteId: string;
}
export interface IAdminCMSResourceRouterParams extends IAdminCMSRouterParams {
  resourceId: string | undefined;
}
export interface IAdminCMSContactRouterParams extends IAdminCMSRouterParams {
  contactId: string | undefined;
}
export interface IAdminCMSPageRouterParams extends IAdminCMSRouterParams {
  pageId: string | undefined;
}
export interface IAdminCMSMenuRouterParams extends IAdminCMSRouterParams {
  menuItemId: string | undefined;
  parentItemId: string | undefined;
}


type RefreshProps = RouteComponentProps<IAdminCMSRouterParams, {}>;

export const getPageIcon = (page: CMSPage) => {
  switch (page.PageTypeID) {
    case PageTypeIDValue.BLOG_ARTICLE:
      return <BlogArticleIcon />;
    case PageTypeIDValue.CALENDAR_EVENT:
      return <CalendarEventIcon />;
    case PageTypeIDValue.DISTRICT:
      return <LocationsIcon />;
    case PageTypeIDValue.EVENT_MODULES:
      return <EventsIcon />;
    case PageTypeIDValue.FACILITY_MODULES:
      return <FacilitiesIcon />;
    case PageTypeIDValue.STANDARD_PAGE:
      return <PageIcon />;
    case PageTypeIDValue.HOMEPAGE:
      return <HomeIcon />;
    default:
      // default to standard page icon
      return <PageIcon />;
  }
};

export const getPageUrl = (page: CMSPage, adminCMSCacheOne: AdminCMSSiteCacheOneState) => {
  const siteDomain = adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.SiteDomain : '';
  const ROOT = `https://${siteDomain}`;

  if (page.PageTypeID === PageTypeIDValue.HOMEPAGE) return `${ROOT}`;
  else {
    if (page.ShortURL) {
      if (page.PageTypeID === PageTypeIDValue.CALENDAR_EVENT && adminCMSCacheOne.CMSSite && page.SiteID !== adminCMSCacheOne.CMSSite.ID) {
        return `${ROOT}/${page.SiteID}/${page.ShortURL}`;
      } else {
        return `${ROOT}/${page.ShortURL}`;
      }
    } else {
      return `${ROOT}/content/${page.ID}/${page.Name ? spaceTo_(page.Name) : 'page'}`;
    }
  }
};

export const sectionHasError = (ValidationRules: IValidator, keys: string[]) => {
  for (let key of Object.keys(ValidationRules)) {
    if (keys.indexOf(key) !== -1) {
      const rule = ValidationRules[key];

      if (rule.errors && rule.errors.length > 0) {
        return true;
      }
    }
  }

  return false;
};

/*
export const isDev = () => {
  return location.origin.includes('cmsdev.tentaroo.com') || (process.env.NODE_ENV === 'development' && location.origin.includes('localhost:3000'));
}
*/

export const getSiteForPermission = (
  cacheZero: CacheZeroState,
  adminCMSCacheOne: AdminCMSSiteCacheOneState,
  siteId?: number,
): CMSSite | null => {

  if (!cacheZero.options) return null;
  let site;
  if (!siteId) {
    // when creating, use `hasResourceAdd` stored in cache one to check permission
    site = cacheZero.options.CMSSites ? cacheZero.options.CMSSites.find((s) => !!adminCMSCacheOne.CMSSite && s.ID === adminCMSCacheOne.CMSSite.ID) : null;
  } else {
    site = cacheZero.options.CMSSites ? cacheZero.options.CMSSites.find((s) => siteId === s.ID) : null;
  }

  return site;
};
export const checkResourcePermission = (
  onSuccess: () => void,
  cacheZero: CacheZeroState,
  adminCMSCacheOne: AdminCMSSiteCacheOneState,
  siteId?: number,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  const site = getSiteForPermission(cacheZero, adminCMSCacheOne, siteId);

  if (!site) return;
  checkPermission(
    () => onSuccess(),
    !!siteId ? site.hasResourceEdit : site.hasResourceAdd,
    !!siteId ? site.hasResourceEditReason : site.hasResourceAddReason,
    inModal,
    noAlertOnFail,
  );
};

export const checkResourceCategoryPermission = (
  onSuccess: () => void,
  adminCMSCacheOne: AdminCMSSiteCacheOneState,
  isEdit?: boolean,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  const site = adminCMSCacheOne.CMSSite;

  if (!site) return;

  checkPermission(
    () => onSuccess(),
    isEdit ? site.hasResourceEdit : site.hasResourceAdd,
    isEdit ? site.hasResourceEditReason : site.hasResourceAddReason,
    inModal,
    noAlertOnFail,
  );
};

export const checkContactPermission = (
  onSuccess: () => void,
  cacheZero: CacheZeroState,
  adminCMSCacheOne: AdminCMSSiteCacheOneState,
  siteId?: number,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {

  const site = getSiteForPermission(cacheZero, adminCMSCacheOne, siteId);

  if (!site) return ;
  checkPermission(
    () => onSuccess(),
    !!siteId ? site.hasContactEdit : site.hasContactAdd,
    !!siteId ? site.hasContactEditReason : site.hasContactAddReason,
    inModal,
    noAlertOnFail,
  );
};

export const checkLocationPermission = (
  onSuccess: () => void,
  cacheZero: CacheZeroState,
  location?: Location,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (location) {
    checkPermission(
      () => onSuccess(),
      !!location.hasEdit,
      location.hasEditReason || null,
      inModal,
      noAlertOnFail,
    );
  } else if (cacheZero.options && cacheZero.options.GeneralPermissions) {
    checkPermission(
      () => onSuccess(),
      !!cacheZero.options.GeneralPermissions.hasLocationAdd,
      cacheZero.options.GeneralPermissions.hasLocationAddReason || null,
      inModal,
      noAlertOnFail,
    );
  }
};

export const checkSiteMenuPermission = (
  onSuccess: () => void,
  adminCMSCacheOne: AdminCMSSiteCacheOneState,
  isEdit?: boolean,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  checkPermission(
    () => onSuccess(),
    adminCMSCacheOne.CMSSite ? (isEdit? adminCMSCacheOne.CMSSite.hasSiteMenuEdit : adminCMSCacheOne.CMSSite.hasSiteMenuAdd) : false,
    adminCMSCacheOne.CMSSite ? (isEdit? adminCMSCacheOne.CMSSite.hasSiteMenuEditReason : adminCMSCacheOne.CMSSite.hasSiteMenuAddReason) : '',
    inModal,
    noAlertOnFail,
  );
};

export const checkPageMenuPermission = (
  onSuccess: () => void,
  adminCMSCacheOne: AdminCMSSiteCacheOneState,
  isEdit?: boolean,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  checkPermission(
    () => onSuccess(),
    adminCMSCacheOne.CMSSite ? (isEdit? adminCMSCacheOne.CMSSite.hasPageMenuEdit : adminCMSCacheOne.CMSSite.hasPageMenuAdd) : false,
    adminCMSCacheOne.CMSSite ? (isEdit? adminCMSCacheOne.CMSSite.hasPageMenuEditReason : adminCMSCacheOne.CMSSite.hasPageMenuAddReason) : '',
    inModal,
    noAlertOnFail,
  );
};

export const checkPagePermission = (
  onSuccess: () => void,
  adminCMSCacheOne: AdminCMSSiteCacheOneState,
  pageId?: number,
  adminCMSCacheTwoPages?: AdminCMSSiteCacheTwoPageState,
  inModal?: boolean,
  noAlertOnFail?: boolean,
): void => {
  if (pageId && adminCMSCacheOne.CMSPages) {
    // When in page list, and delete/restore page will check edit permission against cache one
    const page = adminCMSCacheOne.CMSPages.find((p) => p.ID === pageId);
    checkPermission(
      () => onSuccess(),
      page ? !!page.hasEdit : false,
      PAGE_INSUFFICIENT_EDIT_PERMISSION,
      inModal,
      noAlertOnFail,
    );
  } else if (adminCMSCacheTwoPages) {
    // When in EditPage, will always use `pagesRow` to check edit permission
    const pagesRow = adminCMSCacheTwoPages ? adminCMSCacheTwoPages.CMSPagesRow : null;
    checkPermission(
      () => onSuccess(),
      pagesRow ? !!pagesRow.hasEdit : false,
      PAGE_INSUFFICIENT_EDIT_PERMISSION,
      inModal,
      noAlertOnFail,
    );
  } else if (adminCMSCacheOne.CMSSite) {
    checkPermission(
      () => onSuccess(),
      adminCMSCacheOne.CMSSite.hasPageAdd,
      adminCMSCacheOne.CMSSite.hasPageAddReason,
      inModal,
      noAlertOnFail,
    );
  }
};

// Refresh related
const refreshResourcePreview = (props: RefreshProps, actions: RefreshActions) => {
  // NOTE: It is safe to do a type cast here because this can only be called in ResourcePreview page
  const resourceId = getResourceIdFromPath(props.params as IAdminCMSResourceRouterParams);
  actions.refreshResource({
    ID: resourceId,
    MaxResourceID: 0,
    GetResources: true,
    ...extractRouteParams(props),
  }, false);
};
const refreshResources = (props: RefreshProps, actions: RefreshActions) => {
  // refresh resources
  actions.refresh({
    GetResources: true,
    GetResourceCategories: true,
    MaxResourceID: 0,
    ...extractRouteParams(props),
  }, false);
};
const refreshResourceCategories = (props: RefreshProps, actions: RefreshActions) => {
  // refresh resource categories
  actions.refresh({
    GetResourceCategories: true,
    MaxResourceCategoryID: 0,
    ...extractRouteParams(props),
  }, false);
};

const refreshContacts = (props: RefreshProps, actions: RefreshActions) => {
  actions.refresh({
    GetContacts: true,
    MaxContactID: 0,
    ...extractRouteParams(props),
  }, false);
};
const refreshContactPreview = (props: RefreshProps, actions: RefreshActions) => {
  // NOTE: It is safe to do a type cast here because this can only be called in ContactPreview page
  const contactId = getContactIdFromPath(props.params as IAdminCMSContactRouterParams);
  actions.refreshContact({
    ID: contactId,
    GetContacts: true,
    MaxContactID: 0,
    ...extractRouteParams(props),
  }, false);
};
const refreshSettings = (props: RefreshProps, actions: RefreshActions) => {
  actions.refresh({
    GetCMSOptions: false,
    SiteTS: 0,
    ...extractRouteParams(props),
  }, true);
};
const refreshSiteMenus = (props: RefreshProps, actions: RefreshActions) => {
  actions.refresh({
    GetSiteMenus: true,
    MaxSiteMenuID: 0,
    ...extractRouteParams(props),
  }, false);
};
const refreshPageMenus = (props: RefreshProps, actions: RefreshActions) => {
  actions.refresh({
    GetPageMenus: true,
    MaxPageMenuID: 0,
    ...extractRouteParams(props),
  }, false);
};
const refreshSiteMenu = (props: RefreshProps, actions: RefreshActions) => {
  // NOTE: It is safe to do a type cast here because this can only be called in Edit SiteMenu page
  const menuId = getMenuItemIdFromPath(props.params as IAdminCMSMenuRouterParams);
  actions.refreshSiteMenuItem({
    ID: menuId,
    GetSiteMenus: true,
    MaxSiteMenuID: 0,
    ...extractRouteParams(props),
  });
};
const refreshPageMenu = (props: RefreshProps, actions: RefreshActions) => {
  // NOTE: It is safe to do a type cast here because this can only be called in Edit PageMenu page
  const menuId = getMenuItemIdFromPath(props.params as IAdminCMSMenuRouterParams);
  actions.refreshPageMenuItem({
    ID: menuId,
    GetPageMenus: true,
    MaxPageMenuID: 0,
    ...extractRouteParams(props),
  });
};

const refreshPages = (props: RefreshProps, actions: RefreshActions) => {
  /**
   * We pull the filter from the PageList form here because in SelectPages modal, we dont have a cache.
   */
  actions.refresh(
    constructGetPageListBaseParams(
      reduxStoreService().getState().adminCMSSite.pages.home.ActiveForm,
      reduxStoreService().getState().adminCMSSite.pages.home.ValidationRules.EventStartDate,
      props,
    ),
    false,
  );
};
export const getAdminCMSCustomRefresh = (props: RefreshProps, actions: RefreshActions) => {
  const route = props.routes[props.routes.length - 1];
  const state = reduxStoreService().getState();
  const adminCMSCacheTwoContacts = state.adminCMSSite.cacheTwoContacts;
  switch (route.path) {
    // resources
    case ADMIN_CMS_RESOURCES_PREVIEW_PATH:
      return () => refreshResourcePreview(props, actions);
    case ADMIN_CMS_RESOURCES_PATH:
      return () => refreshResources(props, actions);
    case ADMIN_CMS_RESOURCES_CATEGORIES_PATH:
      return () => refreshResourceCategories(props, actions);
    // contacts
    case ADMIN_CMS_CONTACTS_PATH:
      return () => refreshContacts(props, actions);
    case ADMIN_CMS_CONTACT_PREVIEW_PATH:
      if (!adminCMSCacheTwoContacts || !adminCMSCacheTwoContacts.CMSContact) return () => refreshContacts(props, actions);
      return () => refreshContactPreview(props, actions);
    // settings
    case ADMIN_CMS_SETTINGS_GENERAL_PATH:
    case ADMIN_CMS_SETTINGS_MESSAGES_PATH:
      return () => {
        actions.saveState();
        refreshSettings(props, actions);
      };
    // menus
    case ADMIN_CMS_SITE_MENUS_PATH:
      return () => refreshSiteMenus(props, actions);
    case ADMIN_CMS_PAGE_MENUS_PATH:
      return () => refreshPageMenus(props, actions);
    case ADMIN_CMS_SITE_MENU_EDIT_PATH:
      return () => refreshSiteMenu(props, actions);
    case ADMIN_CMS_PAGE_MENU_EDIT_PATH:
      return () => refreshPageMenu(props, actions);
    // pages
    case ADMIN_CMS_PAGES_PATH:
      return () => refreshPages(props, actions);
  }
  return undefined;
};

export const scrollModalToTop = (namespace: string) => {
  const elems = document.getElementsByClassName(namespace);
  const modalForm = elems && elems.length > 1 ? elems[1] : null;
  if (!modalForm) return;
  const contentElems = modalForm.getElementsByClassName('elements--modal--window--content');
  contentElems && contentElems.length > 0 && contentElems[0].scrollTo(0, 0);
};

export const scrollSideModalToTop = (namespace: string) => {
  const elems = document.getElementsByClassName(namespace);
  const modalForm = elems && elems.length > 0 ? elems[0] : null;
  if (!modalForm) return;
  const contentElems = modalForm.getElementsByClassName('elements--side-modal--content');
  contentElems && contentElems.length > 0 && contentElems[0].scrollTo(0, 0);
};

export const getSiteID = (props: {
  params: any
}): number => {
  if (props.params.siteId ) {
    return Number(props.params.siteId);
  }
  return 0;
};

export const getSiteDomain = (props: {
  params: any
}): string => {
  if (props.params.domain) {
    return props.params.domain;
  }
  return '';
};

export const getResourceIdFromPath = (params: IAdminCMSResourceRouterParams): number => {
  if (params.resourceId) {
    return Number(params.resourceId);
  }
  return 0;
};

export const getContactIdFromPath = (params: IAdminCMSContactRouterParams): number => {
  if (params.contactId) {
    return Number(params.contactId);
  }
  return 0;
};
export const getPageIdFromPath = (params: IAdminCMSPageRouterParams): number => {
  if (params.pageId) {
    return Number(params.pageId);
  }
  return 0;
};

/**
 * This function is used to get id from path for both SiteMenuItem and PageMenuItem
 * @param params 
 * @returns 
 */
export const getMenuItemIdFromPath = (params: IAdminCMSMenuRouterParams | IAdminCMSPageRouterParams): number => {
  if (params.menuItemId) {
    return Number(params.menuItemId);
  }
  return 0;
};