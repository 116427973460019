import * as React from 'react';

import Card from "../Card";
import CartIcon from "../CartIcon";
import View from "../View";
import Column from "../Grid/Column";
import * as moment from 'moment';
import Info from './Info';
import Amounts from './Amounts';
import '../../../styles/elements/my-event/index.scss';

import {namespace} from "./constants";

export interface MyEventProps {
  children?: React.ReactNode;
  dateStart?: moment.Moment;  // @deprecated
  dateEnd?: moment.Moment; // @deprecated
  name?: string; // @deprecated
  location?: string; // @deprecated
  total?: number; // @deprecated
  balance?: number; // @deprecated
  quantity?: React.ReactElement<any>; // @deprecated
  warning?: string; // @deprecated
  error?: string; // @deprecated
  cart?: boolean;
  className?: string;
  onClick?: () => void;
  id?: string;
}

const MyEvent: React.SFC<MyEventProps> = (
  { children, dateStart, dateEnd, name, location, warning, total, balance, quantity, cart, id, error, className, onClick }
: MyEventProps): React.ReactElement<MyEventProps> => {
  const legacyComponent = (dateStart || dateEnd || name || location || warning || error || total || balance || quantity);
  // if (legacyComponent) console.warn('MyEvent components has deprecated properties that are being used.'); todo: uncomment this line when ready to deprecate

  let cName = namespace() + ' ' + className;
  return (
    <Card
      className={cName + (total || balance ? ' template-mobile-vertical' : '')}
      padding="small"
      onClick={onClick}
      layout="horizontal"
      id={id}
    >
      <View className={`${namespace()}--wrapper`}>
        {children}
        {(dateStart || dateEnd || name || location || warning || error) ? ( // todo: remove this block when refactored
          <Info
            dateStart={dateStart}
            dateEnd={dateEnd}
            name={name}
            location={location}
            warning={warning}
            error={error}
          />
        ) : null}

        {((total || balance) && quantity) ? ( // todo: remove this block when refactored
          <Column horizontalAlignment="right" mobileSpan={12} mobileMarginTop={4}>
            <Amounts
              total={total}
              balance={balance}
              quantity={quantity}
              cart={cart}
            />
          </Column>
        ) : null}
      </View>
      {(cart) ? (
        <CartIcon
          className={`${namespace()}--cart-icon`}
        />
      ) : null}
    </Card>
  );
};

export default MyEvent;
export { default as MyEventQuantity } from './Quantity';
export { default as MyEventDateRange } from './DateRange';
export { default as MyEventName } from './Name';
export { default as MyEventAmounts } from './Amounts';
export { default as MyEventWarning } from './Warning';
export { default as MyEventDetails } from './Details';
