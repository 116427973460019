import {ApiSubmitActionsNumbersCampsite} from './actions';
import {eventNumbersCalc} from '../../../../../../constants/urls';
import {makeRequestEpic} from '../../../../../Validation/epicCreator';

export const numbersCampsitesNext = makeRequestEpic(
  ApiSubmitActionsNumbersCampsite,
  eventNumbersCalc,
  undefined,
  true
);
