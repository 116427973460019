import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/grid/index.scss';
import deprecated from '../../../utils/deprecated';

import {namespace} from "./constants";

export interface GridProps {
  children?: React.ReactNode;
  className?: string;
  center?: boolean;
  opacity?: number;
}

const Grid: React.SFC<GridProps> = ({ children, center, opacity, ...props }: GridProps): React.ReactElement<GridProps> => {
  let className: string = namespace();
  if (center) className = `${className} ${namespace()}--center`;

  const style: React.CSSProperties = {};
  if (opacity) style.opacity = opacity;

  return (
    <div style={style} className={mergeClassNames(className, props)}>
      {children}
    </div>
  );
};

export default deprecated('Deprecated, use View instead')(Grid);
export { default as Row } from './Row';
export { default as Column } from './Column';
export { default as Align } from './Align';
