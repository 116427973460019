import * as React from 'react';
import {RouteComponentProps, withRouter} from "react-router";
import '../../../styles/elements/section/index.scss';
import Card from '../Card';
import ActionButton from '../ActionButton';
import { EditIcon } from '../../Icons';
import { generateDOMId } from '../../../utils/cypressHelper';
import { slug } from '../../../utils';

export const namespace = (): string => 'elements--section';

type ConnectedProps = RouteComponentProps<{}, {}>;

interface State {
  showSearch?: boolean;
}

interface Props {
  title: string;
  onEditClick?: () => void;
  children?: React.ReactNode;
  toggle?: React.ReactNode;
  hideToggle?: boolean;
  hideEdit?: boolean;
  hideContent?: boolean;
  disabled?: boolean;
  titleFontSize?: number;
  className?: string;
}

class Section extends React.Component<Props, State> {
  public props: ConnectedProps & Props;

  state = {
    showSearch: false,
  };

  componentWillMount() {
    const {route} = this.props;
  }
  public render() {
    const {children, toggle, hideEdit, hideContent, className, disabled, hideToggle, titleFontSize, title, onEditClick} = this.props;
    return (
      <Card className={`${namespace()}${className ? ' ' + className : ''} ${toggle && !hideToggle ? 'has-toggle' : ''} ${toggle && hideContent ? 'hide-content' : ''}`}>
        <div className={`${namespace()}--header`}>
          <div className={`${namespace()}--header--title${titleFontSize ? ' size-' + titleFontSize : ''}`}>{title}</div>
          {!!toggle ? null : (hideEdit ? null : <ActionButton id={generateDOMId(`${slug(title)}-edit-btn`)} className={`${namespace()}--header--edit`} disabled={!!disabled} icon={EditIcon} onClick={onEditClick} />)}
          {toggle && !hideToggle ? toggle : null}
        </div>
        {!hideContent && <div className={`${namespace()}--content`}>
          {children}
        </div>}
      </Card>
    );
  }
}

export default withRouter<Props, {}>(Section);
