export const AMOUNT_TOO_LOW = (min) => {
    return min >= 0 ? `Minimum is $${min}`: `Minimum is -$${Math.abs(min)}`;
};
export const AMOUNT_TOO_HIGH = (max) => {
    return max >= 0 ? `Maximum is $${max}` : `Maximum is -$${Math.abs(max)}`;
};
export const NO_SPOTS_PURCHASED = "No spots added.";
export const N_SPOTS_LEFT = v => `${v} spot${v !== 1 ? 's' : ''} left`;
export const N_SPOTS_AVAILABLE = v => `${v} spot${v !== 1 ? 's' : ''} available`;
export const NO_PARTICIPANTS_REGISTERED = 'No participants registered.';

// Info Classes
export const CLASSES_WARNING = "On this page, the displayed capacity for registration options won't include any spots used by your registration.";


// Override Fees
export const NEGATIVE_OVERRIDE = "Only options can have a negative amount. Please try again or schedule this class as an Option.";
export const UPFRONT_TOO_MUCH = "Amount due upfront must be less than or equal to the registration fee for this class. Please try again.";
export const OVERRIDE_REQUIRED = "Number of spots to override is required.";
export const FEE_REQUIRED = "Registration fee is required.";
export const FEE_INVALID = "Registration fee is invalid.";
export const UPFRONT_REQUIRED = "Upfront amount due is required.";
export const UPFRONT_INVALID = "Upfront amount due is invalid.";
