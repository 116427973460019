
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, ModalActions, Button, Loader, Form, Row, TextField, Column, DatePicker, Alert
} from '../../../../../../Elements';
import '../../../../../../../styles/pages/cms-pages/modals/duplicate-page-form/index.scss';
import { actionCreators, namespace } from '../../../../../../../store/AdminCMSSite/Pages/Modals/DuplicatePageForm/actions';
import { actionCreators as adminCMSCacheOneActionCreators } from '../../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../../../store/App/actions';
import { actionCreators as pageFormActionCreators } from '../../../../../../../store/AdminCMSSite/Pages/Page/Form/actions';
import { ApplicationState } from '../../../../../../../store';
import { PageTypeIDValue } from '../../../../../../../models/api/adminCMSCacheOne';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { shouldBlockActions } from '../../../../../../../utils/cacheLoaders/helpers/blockers';
import {WithInertAttribute} from '../../../../../../Elements/WithInert';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;
type Props = WithInertAttribute<{}>

@(withRouter as any)
class DuplicatePageFormModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;

  componentDidMount() {
    if (shouldBlockActions()) return;

    this.props.actions.initForm();
  }

  reset = () => {
  };
  componentWillUnmount() {
    this.onClose();
  }
  onSave = () => {
    this.props.actions.apiSubmit(this.props.router);
  };

  onClose = () => {
    this.props.actions.resetForm();
  };

  public render() {
    const { apiSaving, actions, duplicatePageFormState: {ActiveForm, ValidationRules, SubmitErrorMessage}, inert} = this.props;

    const title = 'Duplicate Page';

    return (
      <Modal inert={inert} big mobileFullScreen className={namespace()} onClose={this.onClose} >
        <ModalHeader className={`${namespace()}--header`}>{title}</ModalHeader>
        <ModalContent hasPadding>
          <Form
            className={`${namespace()}--form`}
            onSubmit={e => e.preventDefault()}
          >
            {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
            <Row>
              <Column span={12}>
                <TextField
                  label="Page Title"
                  disabled={apiSaving > 0}
                  onChange={actions.simpleUpdate}
                  onBlur={actions.updateValue}
                  validationRules={ValidationRules.Name}
                  value={ActiveForm.Name}/>
              </Column>
            </Row>
            {ActiveForm.PageTypeID === PageTypeIDValue.CALENDAR_EVENT ? <Row>
              <Column span={12}>
                <DatePicker
                  className={`${namespace()}--date`}
                  label="Start Date"
                  value={ActiveForm.StartDate}
                  validationRules={ValidationRules.StartDate}
                  onSelect={actions.updateValue}
                  onChangeRaw={actions.simpleUpdate}
                  removeMax
                />
              </Column>
            </Row> : null}
          </Form>
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button textColor='black' flat onClick={this.onSave}>{'DUPLICATE'}</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    pageFormState: state.adminCMSSite.pages.page.form,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    duplicatePageFormState: state.adminCMSSite.pages.modals.duplicatePageForm,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...actionCreators,
    ...adminCMSCacheOneActionCreators,
  }, dispatch),
  pageFormActions: bindActionCreators({
    ...pageFormActionCreators,
  }, dispatch),
});
const ConnectedDuplicatePageFormModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(DuplicatePageFormModal);

export default ConnectedDuplicatePageFormModal;