import { replace } from 'react-router-redux';
import { Location } from "history";

import { URLS, QP_ACCOUNT, isAdminModulePage } from '../constants/urls';
import { ApplicationState } from '../store';
import { disableBodyScroll as _disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { captureTentarooError } from './dataHelper';
import { reduxStoreService } from '../store/service';
import shallow from './shallowEqual';

export const navPush = (router, path: string, groupId?: number, location?: { query: any }) => {
  nav(router, path, 'push', groupId, location);
};

export const navReplace = (router, path: string, groupId?: number, location?: { pathname?: string, query: any }) => {
  nav(router, path, 'replace', groupId, location);
};

const nav = (router, path: string, type: 'push' | 'replace' , groupId?: number, location?: { query: any }) => {
  const s: ApplicationState = reduxStoreService().getState();
  let gId;
  if (groupId) gId = groupId;
  else if (location) {
    gId = location.query[QP_ACCOUNT];
  } else if (router.location && router.location.query[QP_ACCOUNT]) {
    gId = router.location.query[QP_ACCOUNT];
  }
  if (s.user.user && s.user.user.str_permissions.hasAdminAccess) {
    // when path is one of those not requiring groupId, OR the path already has groupId
    // with it, route directly
    if (path === URLS.LOGIN || path === URLS.NEW_ACCOUNT || path === URLS.ADD_GROUP || path.includes(QP_ACCOUNT)) {
      route(router, path, type);
    } else {
      // Otherwise, if groupId is found in either url or cacheZero, append it to url
      if (gId) {
        route(router, `${path}?${QP_ACCOUNT}=${gId}`, type);
      } else if (s.cacheZero.options && s.cacheZero.options.Group) {
        route(router, `${path}?${QP_ACCOUNT}=${s.cacheZero.options.Group.IDi}`, type);
      } else {
        // If no groupId is found, can still navigate to any admin page; report error
        // if navigating to an end user page
        if (isAdminModulePage(path)) {
          route(router, path, type);
        } else {
          // if for some reason there's no group id, go to groups/add, this should never happen though
          captureTentarooError(new Error('There was no groupId when trying to navigate replace to ' + path));
          route(router, URLS.ADD_GROUP, type);
        }
      }

    }
  } else {
    const ResetCode = location && location.query ? location.query.ResetCode : undefined;
    const ResetCodeQuery = !ResetCode ? "" : `ResetCode=${ResetCode}`;
    route(router, ResetCodeQuery ? `${path}?${ResetCodeQuery}` : path, type);
  }
};

const route = (router, fullPath: string, type: 'push' | 'replace') => {
  if (type === 'push') {
    router.push(fullPath);
  } else if (type === 'replace') {
    router.replace(fullPath);
  }
};

export const getWindowHeight = () => {
  return window.innerHeight;
};

export const disableBodyScroll = (elemRef: any) => {
  _disableBodyScroll(elemRef);
};

export const enableBodyScroll = (elemRef: any, numOfModalsLeft: number = 1) => {
  if (document.getElementsByClassName('elements--modal--container').length === numOfModalsLeft) {
    clearAllBodyScrollLocks();
  }
};

export const isCardClicked = (e, namespace, filter?: (className: string) => boolean) => {
  let clicked = false;

  if (e.target.classList) {
    const classList = e.target.classList;
    for (let item of classList) {
      if (item.includes(`${namespace()}`) && item !== `${namespace()}--options`) {
        clicked = true;
        if (filter) {
          clicked = filter(item);
        }
      }
    }
  }

  return clicked;
};

export const isNavingBetweenSideModalAndListPages = (sideModalRoutes: string[], listRoute: string, thisPath?: string, nextPath?: string) => {
  if (!thisPath || !nextPath) return false;
  const fromSideModalPagesToListPage = sideModalRoutes.indexOf(thisPath) !== -1 && nextPath === listRoute;
  const fromListPageToSideModalPages = sideModalRoutes.indexOf(nextPath) !== -1 && thisPath === listRoute;

  return fromSideModalPagesToListPage || fromListPageToSideModalPages;
};

export function hasLocationChanged(args: {
  previousUrl: string | undefined,
  previousQuery: Location["query"],
  nextUrl: string | undefined,
  nextQuery: Location["query"],
}) {
  const {previousUrl, previousQuery, nextUrl, nextQuery} = args;
  return (
    previousUrl !== nextUrl ||
    !shallow(previousQuery, nextQuery)
  );
}