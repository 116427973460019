import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import { bindActionCreators } from 'redux';
import { namespaceWindow } from './constants';
import ActionButton from "../ActionButton";
import FilterTextField from "../FilterTextField";
import '../../../styles/elements/modal/header.scss';
import { CloseIcon, SearchIcon, BackIcon, AddIcon, RefreshIcon, FilterIcon } from '../../Icons';
import { ApplicationState } from '../../../store';
import { generateDOMId } from '../../../utils/cypressHelper';
import { slug } from '../../../utils';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';
import { makeCurrentModalSelector } from '../../../store/App';
import {actionCreators} from '../../../store/App/actions';

export const namespace = (): string => `${namespaceWindow}--header`;

interface Props {
  children?: React.ReactNode;
  className?: string;
  mobileFullScreen?: boolean;
  controls?: React.ReactNode; // @todo: this is deprecated, use onSearch, onAdd, onRefresh instead
  red?: boolean;
  searchDefaultValue?: string;
  isSearching?: boolean;
  subtitle?: string;
  disableClose?: boolean;
  onSearchOpen?: () => any;
  onSearchChange?: (value: string) => any;
  onSearchBack?: () => any;
  onToggleFilter?: () => any;
  onAdd?: () => any;
  onRefresh?: () => any;
  onClose?: (e: MouseEvent) => any;
  onBack?: () => any;
  filterActive?: boolean;
  loading?: boolean
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class ModalHeader extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;

  onSearchOpen = () => {
    if (this.props.onSearchOpen) this.props.onSearchOpen();
  };

  onSearchBack = () => {
    if (this.props.onSearchBack) this.props.onSearchBack();
  };

  onToggleFilter = () => {
    if (this.props.onToggleFilter) this.props.onToggleFilter();
  };

  private renderSearchHeader(className, onSearchChange) {
    // @todo TextField with onChange
    return (
      <header className={`${className} searching`}>
        <ActionButton
          className={`${namespace()}--back`}
          icon={BackIcon}
          onClick={this.onSearchBack}
        />
        <FilterTextField id={generateDOMId("account-search-field")} onChange={onSearchChange} defaultValue={this.props.searchDefaultValue}/>
      </header>
    );
  }

  onClickClose = (e) => {
    const {onClose, actions, currentModal} = this.props;

    if (onClose) onClose(e);
    if (!currentModal) return;

    actions.popModal(false, true, currentModal.type);
  };

  public render() {
    const {children, onToggleFilter, mobileFullScreen, controls, red, onSearchChange, onAdd, onRefresh, apiSaving, onClose, disableClose, apiLoading, loading, filterActive, subtitle, onBack} = this.props;
    let className = mergeClassNames(namespace(), this.props);
    if (mobileFullScreen) {
      className += ' mobile-full-screen';
    }
    if (onSearchChange && this.props.isSearching) return this.renderSearchHeader(className, onSearchChange);

    if (red) className += ' red';
    if (apiSaving || loading) className += ' loading';

    return (
      <header className={className}>
        {!onBack && !disableClose ? (
          <ActionButton
            id={generateDOMId(`modal-close-btn-${typeof children === "string" ? slug(children).replace("/", "-") : ""}`)}
            className={`${namespace()}--close`}
            icon={CloseIcon}
            onClick={this.onClickClose}
          />
        ) : null}
        {onBack ? 
          <ActionButton
            onClick={onBack}
            className={`${namespace()}--close`}
            icon={BackIcon}
          /> : null}
        {!subtitle && <h1 className={`${namespace()}--title${disableClose ? ' no-close-btn' : ''}`}>
          {children}
        </h1>}
        {subtitle && <div className={`${namespace()}--texts`}>
          <h1 className={`${namespace()}--title`}>
            {children}
          </h1>
          <h1 className={`${namespace()}--subtitle`}>
            {subtitle}
          </h1>
        </div>}
        {(controls || onSearchChange || onAdd || onRefresh) && (
          <div className={`${namespace()}--controls`}>
            {controls}
            {onAdd && <ActionButton
              id={generateDOMId(`${typeof children === "string" ? slug(children) : ""}-add-btn`)}
              icon={AddIcon}
              disabled={apiLoading > 0}
              className={`${namespace()}--add-button`}
              onClick={onAdd}
            />}
            {onToggleFilter && <ActionButton
              icon={FilterIcon}
              className={`${namespace()}--filter-button`}
              onClick={this.onToggleFilter}
              selected={filterActive}
            />}
            {onSearchChange &&
              <ActionButton
                id={generateDOMId("account-modal-search")}
                icon={SearchIcon}
                className={`${namespace()}--search-button`}
                onClick={this.onSearchOpen}
              />
            }
            {onRefresh && <ActionButton
              icon={RefreshIcon}
              className={`${namespace()}--refresh-button`}
              onClick={onRefresh}
            />}
          </div>
        )}
      </header>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {

  const currentModalSelector = makeCurrentModalSelector();
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    currentModal: currentModalSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators(actionCreators, dispatch)});

const ConnectedHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ModalHeader);

export default ConnectedHeader;

// NOTE: This flag is needed and used in <Modal />, so that we can use and pass down the same `onClose`
// method from <Modal />
(ConnectedHeader as any).isModalHeader = true;
