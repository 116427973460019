import {makeSimpleSelector} from "../../../../utils";
import {GroupRosterPerson} from "../../../../models/api/cacheTwoRoster";
import {adultRosterSelector, youthRosterSelector} from "../../../CacheTwoRoster";

const filterSelector = state => state.app.searchFilter;

const filterFnc = (g: GroupRosterPerson, f: string) => g.Name.toLowerCase().includes(f);

export const makeRosterYouthSelector = makeSimpleSelector(() => true, filterFnc, youthRosterSelector, filterSelector);
export const makeRosterAdultSelector = makeSimpleSelector(() => true, filterFnc, adultRosterSelector, filterSelector);
