import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminEventsCacheOneContext } from '@tentaroo/shared';

import { ApplicationState } from '../../../../../store';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as cacheOneActionCreators, GetEventTypeCacheAction } from '../../../../../store/AdminEvents/CacheOne/actions';
import { actionCreators as eventsHomeActionCreators } from '../../../../../store/AdminEvents/Events/Home/actions';
import { LoadingAll, Loader } from '../../../../Elements';
import { Main, MainContent } from '../../../../Layouts';
import '../../../../../styles/pages/admin-events/events/home/index.scss';
import EventList from './EventList';
import AdminEventsCacheManager from '../../../../../utils/cacheManagers/adminEventsCacheManager';
import { IAdminEventsRouterParams } from '../../../../../utils/helpers/adminEventsPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { noOpenedModals } from '../../../../../utils/modalHelper';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isAdminEventsCacheOnePopulated} from '../../../../../utils/cachePopulatedCheckers/adminEvents';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--events';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminEventsRouterParams, {}>
>;

class EventsHome extends ComponentUpdateTemplate<ConnectedProps, {}> {
  public props: ConnectedProps;

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);
      
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        AdminEventsCacheManager.getInstance().loadAdminEventsCacheOne({
          props: this.props,
          isStateNavigated,
          context: AdminEventsCacheOneContext.EVENTS_LIST,
        });
      }
    );
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        AdminEventsCacheManager.getInstance().loadAdminEventsCacheOne({
          props: nextProps,
          isStateNavigated,
          context: AdminEventsCacheOneContext.EVENTS_LIST,
        });
      });
  }

  componentWillUnmount() {
  }

  public render() {
    const {apiLoadingMap, inert, adminEventsCacheOne, apiSaving, params } = this.props;
    if (!isAdminEventsCacheOnePopulated(adminEventsCacheOne)) {
      return <LoadingAll />;
    }

    const refreshingList = apiLoadingMap[GetEventTypeCacheAction.requestType] && noOpenedModals();
    const saving = apiSaving > 0 && noOpenedModals();

    return (
      <Main inert={inert} isLoading={refreshingList} isAdminEvents>
        <MainContent className={namespace()} handleCompact>
          <div className={`${namespace()}--list-wrapper`}>
            <EventList showFilters />
          </div>
          {saving && <Loader className={`${namespace()}--loader`} center />}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    cacheZero: state.cacheZero,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...cacheOneActionCreators,
    ...eventsHomeActionCreators,
  }, dispatch),
});

const ConnectedEventsHome = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(EventsHome);

export default withRouter<{}>(ConnectedEventsHome);
