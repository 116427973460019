import { ActionCreator } from "../../../..";
import { typeName, Action } from "../../../../../utils/StrongActions";
import {createApiValidateActions, createValidateActions} from '../../../../Validation/actionCreator';

export const SUFFIX = '__ADMIN_EVENTS_SELECT_MESSAGE_MODAL';

@typeName("FILTER" + SUFFIX)
export class SelectMessageModalFilter extends Action {
  constructor(public filterString: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class SelectMessageToggleFilterTabs extends Action {
  constructor(public searching: boolean) { super(); }
}

@typeName("RESET" + SUFFIX) export class ResetSelectMessageModal extends Action {}

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export type Actions = typeof actionCreators;

export const actionCreators = {
  filterMessages: (filterString: string): ActionCreator => dispatch => dispatch(new SelectMessageModalFilter
    (filterString)),
  reset: (): ActionCreator => dispatch => dispatch(new ResetSelectMessageModal()),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new SelectMessageToggleFilterTabs(searching)),
};