import {AjaxResponse} from "rxjs/ajax";

import {makeRequestEpic} from "../../../Validation/epicCreator";
import { ENTITY_CREATED, ENTITY_EDITED, ENTITY_PARSE_ERROR, ENTITY_DELETED, ENTITY_RESTORED } from "../../../../constants/messages/adminCMS";
import {AddMessageSubmitActions, DeleteMessageSubmitActions} from './actions';
import { getUpdateAdminEventMessage } from "../../../../constants/adminEventsUrls";
import { ADMIN_EMAIL_MESSAGE } from "../../../../constants/messages/adminEvents";
import { captureTentarooError } from "../../../../utils/dataHelper";
import { GeneralValidateActions as EventTypeGeneralValidateActions } from "../../EventTypes/EventType/Form/actions";
import { EventTypeGeneralFormDefinition } from "../../EventTypes/EventType/Form/validation";
import { validate } from "../../../../utils";
import { reduxStoreService } from "../../../service";
import { Observable } from "rxjs";
import { ModalTypes, isModalOpened, popModalObservables } from "../../../../utils/modalHelper";

export const adminEventsUpdateMessageEpic = makeRequestEpic(
  AddMessageSubmitActions,
  getUpdateAdminEventMessage,
  undefined,
  true,
  (response: AjaxResponse) => {
    const bodyStr = response.request.body;
    try {
      const body = JSON.parse(bodyStr);
      const MessageSendStatus = response.response.MessageSendStatus;
      if (MessageSendStatus && MessageSendStatus.SendingMessage) {
        return `Message${body.row.Message ? ' saved and' : ''} sent to ${MessageSendStatus.NumGroupsSentTo} group${MessageSendStatus.NumGroupsSentTo !== 1 ? 's' : ''}`;
      }

      if (body.row.ID) {
        return ENTITY_EDITED(ADMIN_EMAIL_MESSAGE);
      }
      return ENTITY_CREATED(ADMIN_EMAIL_MESSAGE);
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(ADMIN_EMAIL_MESSAGE)));
      return '';
    }
  },
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  (payload) => {

    const message = payload.response.EventsEmailMessage;
    const state = reduxStoreService().getState();
    const formData = payload.request.body;
    try {
      const JSONData = JSON.parse(formData);

      if (isModalOpened(ModalTypes.SELECT_MESSAGE) && !JSONData.row.ID) {
        const validationResults = validate(EventTypeGeneralFormDefinition.NewRegistrationMessageID, message.ID, undefined, () => state.adminEvents.eventTypes.eventType.form);

        return Observable.concat(
          Observable.of(EventTypeGeneralValidateActions.updateCurr(message.ID, 'NewRegistrationMessageID', EventTypeGeneralFormDefinition.NewRegistrationMessageID)),
          Observable.of(EventTypeGeneralValidateActions.update(validationResults, 'NewRegistrationMessageID')),
          ...popModalObservables({
            modal: ModalTypes.SELECT_MESSAGE,
            saveBefore: false,
            saveAfter: false,
            transformToObservable: true,
          }),
        );
      }
    } catch (e) {
      captureTentarooError(new Error(ENTITY_PARSE_ERROR(ADMIN_EMAIL_MESSAGE)));
      return '';
    }
  }
);

export const adminEventsDeleteMessageEpic = makeRequestEpic(
  DeleteMessageSubmitActions,
  getUpdateAdminEventMessage,
  undefined,
  true,
  (response: AjaxResponse) => {
    const item = response.response.EventsEmailMessage;

    if (item.Inactive) {
      return ENTITY_DELETED(ADMIN_EMAIL_MESSAGE);
    }
    return ENTITY_RESTORED(ADMIN_EMAIL_MESSAGE);
  },
);