import { SetEmptyCart } from "../../store/App/actions";
import { reduxStoreService } from "../../store/service";

export function safelySetEmptyCart(
  getEmptyCartUrl: () => string,
  getEmptyCartBody: (params: any) => void,
  postNavigate?: string,
) {
  const appState = reduxStoreService().getState().app;

  if (
    appState.getEmptyCartUrl !== getEmptyCartUrl ||
    appState.getEmptyCartBody !== getEmptyCartBody ||
    appState.emptyCartPostNavigate !== postNavigate
  ) {
    reduxStoreService().dispatch(new SetEmptyCart(getEmptyCartUrl, getEmptyCartBody, postNavigate));
  }
}