import * as React from 'react';
import { RegisterHeaderProgress, RegisterHeaderProgressStep } from '../../../../../Elements';

export interface HeaderProgressProps {
  selected: 'products' | 'confirmation';
  loading: boolean;
  tabs?: React.ReactNode;
}

const HeaderProgress: React.SFC<HeaderProgressProps> = ({ selected, tabs, loading }: HeaderProgressProps): React.ReactElement<HeaderProgressProps> => (
  <RegisterHeaderProgress tabs={tabs} loading={loading}>
    <RegisterHeaderProgressStep selected={selected === 'products'}>Products</RegisterHeaderProgressStep>
    <RegisterHeaderProgressStep selected={selected === 'confirmation'}>Confirmation</RegisterHeaderProgressStep>
  </RegisterHeaderProgress>
);

export default HeaderProgress;
