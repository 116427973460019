import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as cacheTwoFacilityTypesActionCreators, GetFacilityType } from "../../../../../store/AdminFacilityLocation/CacheTwoFacilityTypes/actions";
import { actionCreators } from "../../../../../store/AdminFacilityLocation/Facilities/FacilityType/Form/actions";
import { LoadingAll } from '../../../../Elements';
import { ADMIN_FACILITY_LOCATION_FACILITY_TYPE_EDIT_PATH, ADMIN_FACILITY_LOCATION_FACILITY_TYPE_NEW_PATH } from '../../../../../routes';
import FacilityTypeForm from './Form';
import NotFound from '../../../../../components/Pages/NotFound';
import { INVALID_FACILITY_TYPE } from '../../../../../constants/messages/adminFacilityLocation';
import { checkFacilityTypePermission, IAdminFacilityTypeRouterParams } from '../../../../../utils/helpers/adminFacilityLocationPageHelper';
import AdminFacilitiesLocationCacheManager from '../../../../../utils/cacheManagers/adminFacilitiesLocationCacheManager';
import { SaveState } from '../../../../../store/Rollback/actions';
import { shouldReconfigRouter } from '../../../../../utils/cacheLoaders/reloaderHelper';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { reduxStoreService } from '../../../../../store/service';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isAdminFacilitiesLocationCacheOnePopulated, isAdminFacilityLocationCacheTwoFacilityTypePopulated} from '../../../../../utils/cachePopulatedCheckers/adminFacilities';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--facility-location--facility-type';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminFacilityTypeRouterParams, {}>
>;

class FacilityType extends ComponentUpdateTemplate<ConnectedProps, {}> {
  public props: ConnectedProps;

  componentDidMount() {
    const {routes} = this.props;
    const route = routes[routes.length - 1];
    this.props.actions.showAdminPageHeader(false);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        AdminFacilitiesLocationCacheManager.getInstance().loadCacheTwoFacilityType({
          props: this.props,
          isStateNavigated,
          isEdit: route.path === ADMIN_FACILITY_LOCATION_FACILITY_TYPE_EDIT_PATH
        });
      }
    );

    this.configRouteLeaveHook();
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouteLeaveHook();
  }

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    const {params, adminFacilityLocationCacheTwoFacilityTypes} = this.props;

    if (reduxStoreService().getState().app.apiLoading === 0) {
      if (!params.facilityTypeId || isAdminFacilityLocationCacheTwoFacilityTypePopulated(adminFacilityLocationCacheTwoFacilityTypes)) {
        reduxStoreService().dispatch(new SaveState());
      }
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  componentWillUnmount() {
    const {actions, params, adminFacilityLocationCacheTwoFacilityTypes} = this.props;

    if (!params.facilityTypeId || isAdminFacilityLocationCacheTwoFacilityTypePopulated(adminFacilityLocationCacheTwoFacilityTypes)) {
      actions.clearAdminFacilityLocationCacheTwoFacilityType();
    }

    this.resetRouteLeaveHook();
  }

  componentWillReceiveProps(nextProps) {
    const {routes} = nextProps;
    const nextPath = routes[routes.length - 1].path;
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        AdminFacilitiesLocationCacheManager.getInstance().loadCacheTwoFacilityType({
          props: nextProps,
          isStateNavigated,
          isEdit: nextPath === ADMIN_FACILITY_LOCATION_FACILITY_TYPE_EDIT_PATH,
        });
      }
    );
  }

  onSave = () => {
    checkFacilityTypePermission(
      () => {
        this.props.actions.apiSubmitForm(this.props.routes, this.props.router);
      },
      this.props.cacheZero,
      true,
    );
  };

  onDelete = (id: number) => {
    checkFacilityTypePermission(
      () => {
        this.props.actions.pushModal(ModalTypes.DELETE_FACILITY_TYPE, false, true, {id});
      },
      this.props.cacheZero,
      true,
    );
  };

  public render() {
    const {inert, apiLoadingMap, routes, apiSaving, actions, adminFacilityLocationCacheOne, cacheZero, adminFacilityLocationCacheTwoFacilityTypes, formState} = this.props;
    const route = routes[routes.length - 1];
    if (
      !isAdminFacilitiesLocationCacheOnePopulated() ||
      (route.path === ADMIN_FACILITY_LOCATION_FACILITY_TYPE_EDIT_PATH && !isAdminFacilityLocationCacheTwoFacilityTypePopulated(adminFacilityLocationCacheTwoFacilityTypes, formState.FacilityTypeRow.ActiveForm))
    ) {
      return <LoadingAll />;
    }

    if (route.path === ADMIN_FACILITY_LOCATION_FACILITY_TYPE_EDIT_PATH && adminFacilityLocationCacheTwoFacilityTypes.FacilitiesType && adminFacilityLocationCacheTwoFacilityTypes.FacilitiesType.FacilityTypeRow.Inactive) {
      return (
        <NotFound message={INVALID_FACILITY_TYPE} />
      );
    }
    const pageLoading = apiLoadingMap[GetFacilityType.requestType];
    return (
      <FacilityTypeForm
        inert={inert}
        handleCompact
        cacheZero={cacheZero}
        adminFacilityLocationCacheOne={adminFacilityLocationCacheOne}
        formState={formState}
        reduxActions={actions}
        disabled={apiSaving > 0}
        loading={apiSaving > 0 || pageLoading}
        action={route.path === ADMIN_FACILITY_LOCATION_FACILITY_TYPE_NEW_PATH ? 'add' : 'edit'}
        onSave={this.onSave}
        onDelete={this.onDelete}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    cacheZero: state.cacheZero,
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
    adminFacilityLocationCacheTwoFacilityTypes: state.adminFacilityLocation.cacheTwoFacilityTypes,
    formState: state.adminFacilityLocation.facilities.facilityType.form,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...cacheTwoFacilityTypesActionCreators,
    ...appActionCreators,
  }, dispatch),
});

const ConnectedFacilityType = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(FacilityType);

export default withRouter<{}>(ConnectedFacilityType);
