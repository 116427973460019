import * as React from 'react';
import '../../../../styles/elements/admin-facility/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton, Vignette, Checkbox} from '../../index';
import ContextMenuComponent from './ContextMenu';
import { AdminFacility } from '../../../../models/api/adminFacilitiesCacheOne';
import { OptionsIcon, CloseIcon } from '../../../../components/Icons';
import { CardCategory } from '../../Card';
import { namespace as cardNS } from "../../Card/constants";
import { getImagePath, createInitials } from '../../../../utils';
import AdminBadge from '../../AdminBadge';
import { ADMIN_FACILITY_LOCATION_FACILITY_EDIT_PATH } from '../../../../routes';
import { AdminFacilityLocationCacheOneState } from '../../../../store/AdminFacilityLocation/CacheOne';
import { Row } from '../../Grid';
import { isPathnameMatchingRoute } from '../../../../utils/urlHelper';
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--admin-facility--card';

export enum AdminFacilityCardType {
  FACILITY_LIST='facility-list',
  SHOW_FACILITY_TYPE='show-facility-type',
  MULTIPLE_FACILITIES='multiple-facilities',
  BLACKOUT_DATE='blackout-date',
  LIST_WITHOUT_IMAGE='list-without-image'
} 

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin' | 'info' | 'mobile-full';
  type: AdminFacilityCardType;
  category: CardCategory;
  noBoxShadow?: boolean;
  selected?: boolean;
  facility: AdminFacility;
  onEdit?: (facility: AdminFacility) => void;
  onDelete?: (facility: AdminFacility) => void;
  onRemove?: (facility: AdminFacility) => void;
  onRestore?: (facility: AdminFacility) => void;
  onMultipleSelect?: (facility: AdminFacility) => void;
  onClick?: (facility: AdminFacility) => void;
  disabled?: boolean;
  routes?: any;
  adminFacilityLocationCacheOne?: AdminFacilityLocationCacheOneState;
  hideOptions?: boolean;
  checked?: boolean;
}


class AdminFacilityCard extends React.Component<Props> {
  onEditClick = (e) => {
    const {onEdit, disabled, facility} = this.props;
    
    e.stopPropagation();
    !disabled && onEdit && onEdit(facility);
  };
  onDeleteClick = (e) => {
    const {onDelete, facility} = this.props;
    e.stopPropagation();
    !this.props.disabled && onDelete && onDelete(facility);
  };
  onRestoreClick = (e) => {
    const {onRestore, facility} = this.props;
    !this.props.disabled && e.stopPropagation();
    onRestore && onRestore(facility);
  };

  onRemoveClick = (e) => {
    const {onRemove, disabled, facility} = this.props;
    e.stopPropagation();
    !disabled && onRemove && onRemove(facility);
  };

  onMultipleSelectClick = (e) => {
    const {disabled, facility, onMultipleSelect} = this.props;
    !disabled && onMultipleSelect && onMultipleSelect(facility);
  };
  onClick = (e) => {
    const {disabled, facility, onClick} = this.props;
    !disabled && onClick && onClick(facility);
  };
  onCardClick = (e) => {
    const {type, facility, disabled} = this.props;

    if (facility.Inactive || disabled) return;

    switch (type) {
      case AdminFacilityCardType.FACILITY_LIST:
        this.onEditClick(e);
        break;
      case AdminFacilityCardType.MULTIPLE_FACILITIES:
        this.onMultipleSelectClick(e);
        break;
      case AdminFacilityCardType.LIST_WITHOUT_IMAGE:
        this.onClick(e);
        break;
  }
  };

  renderInList = () => {
    const {facility, hideOptions} = this.props;
    return (
      <div className={`${namespace()}--main`}>
        <Vignette
          className={`${namespace()}--main--vignette`}
          width={68}
          height={68}
          color={`#${facility.Color}`}
          name={createInitials(facility.Name)}
          image={facility.FeaturedImage ? getImagePath(facility.FeaturedImage) : undefined}
        />
        <div className={`${namespace()}--main--name ${facility.AllowOnlineBookingID !== 1 ? `admin-only` : ''}`}>{facility.Name}</div>
        {facility.AllowOnlineBookingID !== 1 ? <AdminBadge marginLeft={8} /> : null}
        {!facility.Inactive && !hideOptions && <ActionButton
          icon={OptionsIcon}
          className={`${namespace()}--main--options ${cardNS()}--options`}
          contextMenu={<ContextMenuComponent
            onEdit={this.onEditClick}
            onDelete={this.onDeleteClick} />}
        />}
        {facility.Inactive && (
          <Button
            className={`${namespace()}--main--restore ${cardNS()}--restore`}
            onClick={this.onRestoreClick}
            flat
            textColor='red'>RESTORE</Button>
        )}
      </div>
    );
  };

  renderInBlackoutDate = () => {
    const {facility, adminFacilityLocationCacheOne, disabled, category} = this.props;

    if (!adminFacilityLocationCacheOne || !adminFacilityLocationCacheOne.FacilitiesTypes) return null;

    const facilityType = adminFacilityLocationCacheOne.FacilitiesTypes.find((ft) => ft.ID === facility.FacilityTypeID);
    return (
      <Row className={`${namespace()}--main`}>
        <Vignette
          className={`${namespace()}--main--vignette`}
          width={68}
          height={68}
          color={`#${facility.Color}`}
          name={createInitials(facility.Name)}
          image={facility.FeaturedImage ? getImagePath(facility.FeaturedImage) : undefined}
        />
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>
            <div className={`${namespace()}--main--name ${facility.AllowOnlineBookingID !== 1 ? `admin-only` : ''}`}>{facility.Name}</div>
            {facility.AllowOnlineBookingID !== 1 ? <AdminBadge marginLeft={8} /> : null}
          </Row>
          <Row className={`${namespace()}--main--text--subtitle`}>{facilityType ? facilityType.NamePlural : ''}
          </Row>
        </div>
        <ActionButton
          icon={CloseIcon}
          disabled={disabled}
          className={`${cardNS()}--close ${category}`}
          onClick={this.onRemoveClick}
        />
      </Row>
    );
  };

  renderInImportModal = () => {
    const {facility, adminFacilityLocationCacheOne} = this.props;
    if (!adminFacilityLocationCacheOne || !adminFacilityLocationCacheOne.FacilitiesTypes) return null;
    const facilityType = adminFacilityLocationCacheOne.FacilitiesTypes.find((ft) => ft.ID === facility.FacilityTypeID);
    if (!facilityType) return null;
    return (
      <div className={`${namespace()}--main`}>
        <Vignette
          className={`${namespace()}--main--vignette`}
          width={68}
          height={68}
          color={`#${facility.Color}`}
          name={createInitials(facility.Name)}
          image={facility.FeaturedImage ? getImagePath(facility.FeaturedImage) : undefined}
        />
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>
            <div className={`${namespace()}--main--name ${facility.AllowOnlineBookingID !== 1 ? `admin-only` : ''}`}>{facility.Name}</div>
            {facility.AllowOnlineBookingID !== 1 ? <AdminBadge marginLeft={8} /> : null}
          </Row>
          <Row className={`${namespace()}--main--text--subtitle`}>{facilityType.NamePlural}</Row>
        </div>
      </div>
    );
  };
  renderWithoutImage = () => {
    const {facility, adminFacilityLocationCacheOne} = this.props;

    if (!adminFacilityLocationCacheOne || !adminFacilityLocationCacheOne.FacilitiesTypes) return null;

    const facilityType = adminFacilityLocationCacheOne.FacilitiesTypes.find((ft) => ft.ID === facility.FacilityTypeID);
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <Row className={`${namespace()}--main--text--title`}>
            <div className={`${namespace()}--main--name ${facility.AllowOnlineBookingID !== 1 ? `admin-only` : ''}`}>{facility.Name}</div>
            {facility.AllowOnlineBookingID !== 1 ? <AdminBadge marginLeft={8} /> : null}
          </Row>
          <Row className={`${namespace()}--main--text--subtitle`}>{facilityType ? facilityType.NamePlural : ''}
          </Row>
        </div>
      </Row>
    );
  };

  render() {
    const {category, noBoxShadow, template, facility, type, routes, adminFacilityLocationCacheOne, checked, onMultipleSelect} = this.props;
    let className = attribute(namespace(), undefined, this.props);

    if (facility.Inactive && (type === AdminFacilityCardType.FACILITY_LIST || type === AdminFacilityCardType.MULTIPLE_FACILITIES)) className += ' deleted';
    className += ` ${type}`;

    let selected;
    if (routes && routes.length > 0) {
      const route = routes[routes.length - 1];
      selected = type === AdminFacilityCardType.FACILITY_LIST && isPathnameMatchingRoute(location.pathname, ADMIN_FACILITY_LOCATION_FACILITY_EDIT_PATH, 'facilityId', facility.ID);
    }

    return (
      <Card
        noBoxShadow={noBoxShadow}
        className={className}
        component={<section/>}
        selected={selected}
        template={template || 'mobile-no-margin'}
        category={category}
        padding="none"
      >
        <div id={generateDOMId(`admin-fac-facility-card-${facility.ID}`)} onClick={this.onCardClick}>
          {type === AdminFacilityCardType.MULTIPLE_FACILITIES && onMultipleSelect ? 
          <Checkbox
            selected={!!checked}
            value={!!checked}
            onChange={onMultipleSelect}
          /> : null}
          {type === AdminFacilityCardType.FACILITY_LIST ? this.renderInList() : null}
          {type === AdminFacilityCardType.SHOW_FACILITY_TYPE ? this.renderInImportModal() : null}
          {type === AdminFacilityCardType.MULTIPLE_FACILITIES || type === AdminFacilityCardType.LIST_WITHOUT_IMAGE ? this.renderWithoutImage() : null}
          {type === AdminFacilityCardType.BLACKOUT_DATE ? this.renderInBlackoutDate() : null}
        </div>
      </Card>
    );
  }
}

export default AdminFacilityCard;