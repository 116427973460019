import { AdminEventsCacheOneContext } from '@tentaroo/shared';

import {SaveState} from "../../store/Rollback/actions";
import { extractRouteParams, isIdFromUrlValid } from "../urlHelper";
import { GetEventTypeCacheAction } from "../../store/AdminEvents/CacheOne/actions";
import { ToggleNotFound } from "../../store/App/actions";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import type AdminEventsCacheManager from "../cacheManagers/adminEventsCacheManager";
import { reduxStoreService } from "../../store/service";
import { LoadAdminEventsCacheOneParams } from "./helpers/models";
import {isAdminEventsCacheOnePopulated} from '../cachePopulatedCheckers/adminEvents';

export const ensureAdminEventsCacheOne = (
  params: LoadAdminEventsCacheOneParams & {cacheManager: AdminEventsCacheManager}
): boolean => {
  const {props, context, cacheManager, isStateNavigated, isGetYearOverview} = params;
  const state = reduxStoreService().getState();

  if (!isIdFromUrlValid(props.params.eventTypeId)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  // Initializing Reports page OR list filters DONOT require cache one to be loaded, so we can put it here
  if (isStateNavigated && context) {
    cacheManager.initCacheOneListPage(context);
  }

  let requestedData = false;
  if (
    !EndUserCacheManager.getInstance().cacheOneNeedsReload(props) &&
    isAdminEventsCacheOnePopulated() &&
    (!isGetYearOverview || state.adminEvents.cacheOne.YearOverview_Year)
  ) {
    // Initializing Settings page DOES require cache one to be loaded, so we can put it here
      // These inits will happen when navigating to cache one form without any load,
      // and we shouldn't skip form update in this case
    if (isStateNavigated) {
      if (context === AdminEventsCacheOneContext.SETTINGS) {
        reduxStoreService().dispatch(GetEventTypeCacheAction.updateForm());
      }
      reduxStoreService().dispatch(new SaveState());
    }
  } else {
    // After this load request is completed, a `updateForm` for cache one form will be dispatched,
    // we set `skipUpdateForm` to true when we are not in the form pages, to skip updating the form
    reduxStoreService().dispatch(GetEventTypeCacheAction.request(
      {
        ...extractRouteParams(props),
        EventTypeID: Number(props.params.eventTypeId),
        GetYearOverview: !!isGetYearOverview,
      },
      {skipUpdateForm: context !== AdminEventsCacheOneContext.SETTINGS && !isGetYearOverview}
    ));
    requestedData = true;
  }

  return requestedData;
};