import 'react-mdl/extra/material.css';
import 'react-mdl/extra/material.js';
import * as React from 'react';
import PropTypes from 'prop-types';
import Header from './App/Header';
import TopFloatingAlert from './App/TopFloatingAlert';
import '../styles/app/app.scss';
import '../styles/app/reset.scss';
import '../styles/app/gutter.scss';
import '../styles/elements/progress-bar/nprogress-overrides.scss';
import {RouteComponentProps, withRouter} from 'react-router';
import {actionCreators} from "../store/App/actions";
import {actionCreators as cacheOneActionCreators, ClearCacheBelowOne} from "../store/CacheOne/actions";
import {
  actionCreators as sessionActionCreators,
  getLoginFormRequestAction
} from "../store/Session/actions";
import {bindActionCreators} from 'redux';
import {default as ModalOrchestrator, ModalOrchestratorInterface} from "./Modal/ModalOrchestrator";
import NavContainer from './App/Nav/Container';
import CompactNavContainer from './App/Nav/Compact';
import { NAVS, URLS} from '../constants/urls';
import Nav from '../components/App/Nav/Nav';
import {Loader, LoadingAll, Snackbar, ReportFinishedModal, PageLoader} from './Elements';
import {AdminAccountsModal, AdminCMSSiteModal} from './Pages/Admin/Modals';
import {Cart as CartModal, ResetPassword, NotFound, EditEmail} from './Pages';
import {isNavingBetweenSideModalAndListPages} from '../utils/navHelper';
import {actionCreators as rollbackActionCreators} from '../store/Rollback/actions';
import {actionCreators as homeActionCreators} from '../store/Home/actions';
import RemoveItemModal from './Elements/Cart/Item/RemoveItem/index';
import CancelRegistration from './Pages/Events/Event/Modals/CancelRegistration';
import 'rxjs';
import {cartActionCreators} from "../store/Cart/actions"; // don't remove this! we need it for epics
import OrderSubmitted from './Pages/Checkout/OrderSubmitted';
import { isAdminCMSSitePage as _isAdminCMSSitePage, isAdminFacilityLocationPage as _isAdminFacilityLocationPage, isAdminEventsPage as _isAdminEventsPage } from '../constants/urls';
import { ADMIN_CMS_RESOURCES_PATH, ADMIN_CMS_RESOURCES_CATEGORIES_PATH, ADMIN_CMS_RESOURCES_PREVIEW_PATH, ADMIN_CMS_CONTACTS_PATH, ADMIN_CMS_CONTACT_PREVIEW_PATH, ADMIN_CMS_SETTINGS_GENERAL_PATH, ADMIN_CMS_SETTINGS_MESSAGES_PATH, ADMIN_CMS_SITE_MENUS_PATH, ADMIN_CMS_PAGE_MENUS_PATH, ADMIN_CMS_SITE_MENU_EDIT_PATH, ADMIN_CMS_PAGES_PATH, ADMIN_CMS_SITE_MENU_NEW_PATH, ADMIN_CMS_PAGE_MENU_NEW_PATH, ADMIN_CMS_PAGE_MENU_EDIT_PATH, ADMIN_FACILITY_LOCATION_FACILITIES_PATH, ADMIN_FACILITY_LOCATION_AVAILABILITIES_PATH, ADMIN_FACILITY_LOCATION_FACILITY_TYPES_PATH, ADMIN_FACILITY_LOCATION_BLACKOUT_DATES_PATH, ADMIN_FACILITY_LOCATION_REPORTS_PATH, ADMIN_FACILITY_LOCATION_SETTINGS_PATH, ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_NEW_PATH, ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH, ADMIN_EVENTS_EVENTS_PATH, ADMIN_EVENTS_PRODUCTS_PATH, ADMIN_EVENTS_REPORTS_PATH, ADMIN_EVENTS_SETTINGS_PATH, ADMIN_EVENTS_MESSAGE_CENTER_PATH, ADMIN_EVENTS_YEAR_OVERVIEW_PATH, ADMIN_EVENTS_NEW_MESSAGE_PATH, ADMIN_EVENTS_EDIT_MESSAGE_PATH, ADMIN_EVENTS_NEW_PRODUCT_PATH, ADMIN_EVENTS_EDIT_PRODUCT_PATH, ADMIN_EVENT_NEW_CLASS_PATH, ADMIN_EVENT_EDIT_CLASS_PATH, ADMIN_EVENT_CLASSES_PATH, ADMIN_EVENT_NEW_CLASS_TYPE_PATH, ADMIN_EVENT_EDIT_CLASS_TYPE_PATH, ADMIN_EVENT_CLASS_TYPES_PATH } from '../routes';
import { GET_LOGIN_FAIL_MESSAGE } from '../constants/messages/login';
import { INVALID_LOCATION } from '../constants/messages/adminCMS';

import ResourceCategoryModal from './Pages/CMS/Websites/Resources/Modals/ResourceCategory';
import ResourceModal from './Pages/CMS/Websites/Resources/Modals/Resource';
import NewPageModal from './Pages/CMS/Websites/Pages/Modals/NewPage';
import SelectPageModal from './Pages/CMS/Websites/Pages/Modals/SelectPage';
import ResourceCategoryFormModal from './Pages/CMS/Websites/Resources/Modals/ResourceCategory/ResourceCategoryForm';
import PageMenuItemFormModal from './Pages/CMS/Websites/Menus/Modals/PageMenuItem';
import ResourceFormModal from './Pages/CMS/Websites/Resources/Modals/ResourceForm';
import ContactFormModal from './Pages/CMS/Websites/Contacts/Modals/ContactForm';
import SelectLocationModal from './Pages/Shared/Modals/Location';
import LocationFormModal from './Pages/Shared/Modals/LocationForm';
import ImageFormModal from './Pages/CMS/Websites/Pages/Modals/ImageForm';
import FacilityShareSettingsModal from './Pages/FacilityLocation/Facilities/Modals/FacilityTypeShareSettings';
import ImportFromFacilityModal from './Pages/FacilityLocation/Facilities/Modals/ImportFromFacility';
import DeleteMenuItemModal from './Pages/CMS/Websites/Menus/Modals/DeleteMenuItem';
import MultipleContacts from './Pages/CMS/Websites/Contacts/Modals/MultipleContacts';
import MultipleResources from './Pages/CMS/Websites/Resources/Modals/MultipleResources';
import DuplicatePageFormModal from './Pages/CMS/Websites/Pages/Modals/DuplicatePageForm';
import NewFacilityModal from './Pages/FacilityLocation/Facilities/Modals/NewFacility';
import GLAccountModal from './Pages/AdminSettings/GLAccounts/Modals/GLAccount';
import GLAccountFormModal from './Pages/AdminSettings/GLAccounts/Modals/GLAccountForm';
import FacilityTypeFormModal from './Pages/FacilityLocation/Facilities/Modals/FacilityTypeForm';
import MultipleFacilities from './Pages/FacilityLocation/Facilities/Modals/MultipleFacilities';
import DeleteBlackouteDateModal from './Pages/FacilityLocation/BlackoutDates/Modals/DeleteBlackoutDate';
import DeleteFacilityTypeModal from './Pages/FacilityLocation/Facilities/Modals/DeleteFacilityType';
import DeleteOrderAndPaymentModal from './Pages/Settings/Order/DeleteModal';

// Admin Events
import EventTypesModal from './Pages/AdminEvents/EventTypes/Modals/SelectEventTypes';
import NewEventTypeModal from './Pages/AdminEvents/EventTypes/Modals/NewEventType';
import EventFormModal from './Pages/AdminEvents/Events/Modals/EventForm';
import SelectMessageModal from './Pages/AdminEvents/MessageCenter/Modals/SelectMessage';
import ReplaceParticipantTypeModal from './Pages/AdminEvents/EventTypes/Modals/ReplaceParticipantType';
import RemoveParticipantTypeModal from './Pages/AdminEvents/EventTypes/Modals/RemoveParticipantType';
import EventTypeSharedSettingsModal from './Pages/AdminEvents/EventTypes/Modals/EventTypeSharedSettings';
import ImportFromEventModal from './Pages/AdminEvents/Events/Modals/ImportFromEvent';
import RecalculatePricingModal from './Pages/AdminEvents/Events/Modals/RecalculatePricing';
import ExportInstructorRosterModal from './Pages/AdminEvents/Events/Modals/ExportInstructorRoster';
import GenerateInvoicesModal from './Pages/AdminEvents/Events/Modals/GenerateInvoices';
import MultipleGroupsModal from './Pages/AdminEvents/Events/Modals/MultipleGroups';
import MessageFormModal from './Pages/AdminEvents/MessageCenter/Modals/MessageForm';
import SendMessageModal from './Pages/AdminEvents/MessageCenter/Modals/SendMessage';
import EnterClassRequirementModal from './Pages/AdminEvents/Events/Modals/EnterClassRequirement';
import SelectClassTypeModal from './Pages/AdminEvents/Events/Modals/SelectClassType';
import ClassTypeFormModal from './Pages/AdminEvents/Events/Modals/ClassTypeForm';
import DeleteAdminEventClassModal from './Pages/AdminEvents/Events/Modals/DeleteClass';
import ExportParticipantClassScheduleModal from './Pages/AdminEvents/Events/Modals/ExportParticipantClassSchedule';
import SelectMeritBadgeModal from './Pages/AdminEvents/Events/Modals/SelectMeritBadge';
import ManageRequirementsMoldal from './Pages/AdminEvents/Events/Modals/ManageRequirements';
import AdminHeader from './App/AdminHeader';
import AirAppModal from './Pages/Admin/Modals/AirApp';
import { IModal, ModalTypes, isModalOpened } from '../utils/modalHelper';
import { ApplicationState } from '../store';
import { shallowEqual } from '../utils';
import { makeCurrentModalSelector, makeFormModalPropSelector } from '../store/App/index';
import { captureTentarooError } from '../utils/dataHelper';
import SubmittingPayment from './Pages/Checkout/SubmittingPayment';
import PaymentFailed from './Pages/Checkout/PaymentFailed';
import CheckoutMessagesModal from './Pages/Checkout/CheckoutMessagesModal';
import FullClassesModal from './Pages/Checkout/FullClassesModal';
import ClassAddUnscheduled from './Pages/Events/Event/Modals/ClassAddUnscheduled';
import PaymentTools from './Pages/Settings/Orders/PaymentTools';
import ProfileDeletePerson from "./Pages/Settings/Roster/DeletePerson";
import DeletePerson from './Pages/Events/Event/Register/Participant/Roster/DeletePerson';
import RegisterOverrideFeeModal from "./Pages/Events/Event/Modals/RegisterOverrideFee";
import { connect } from 'react-redux';
import { getMergeProps } from '../utils/reduxHelper';
import { Location } from "history";
import UnauthenticatedCacheManager from '../utils/cacheManagers/unauthenticatedCacheManager';
import ConfirmSendResetLink from './Pages/Settings/Profile/ConfirmSendResetLink';
import ChangePassword from './Pages/Settings/Profile/ChangePassword';
import { GetPrevOrders } from '../store/CacheTwoPrevOrders/actions';
import { GetPrevOrder } from '../store/CacheThreePrevOrders/actions';
import ReservationOverrideFeeModal from './Pages/Facilities/Trip/Modals/ReservationOverrideFees';
import ClassOverrideFee from './Pages/Events/Event/Modals/ClassOverrideFee';
import CancelReservation from './Pages/Facilities/Trip/Modals/CancelReservation';
import JoinWaitingListModal from './Elements/Class/JoinWaitingListModal';
import { ActivateAccount } from './Pages/Auth';
import FacilityTypeConfirmation from './Pages/FacilityLocation/Facilities/Modals/FacilityTypeConfirmation';
import { onBrowserUrlChange } from '../rollback/onBrowserUrlChange';
import { onBeforeBrowserUrlChange } from '../rollback/onBeforeBrowserUrlChange';
import { reduxStoreService } from '../store/service';
import { ComponentUpdateTemplate } from './Templates/ComponentUpdateTemplate';
import {isCacheTwoPrevOrdersPopulated} from '../utils/cachePopulatedCheckers/endUser';
import {WithInertModal} from './Elements/WithInert';

interface IRouteParams {
  id: string;
  siteId: string;
  locationId: string;
  eventTypeId: string;
  pageId: string;
  facilityId: string;
  eventId: string;
}
interface Props {
  body: React.ReactElement<any>;
  header?: React.ReactElement<any>;
  footer?: React.ReactElement<any>;
  nav?: React.ReactElement<any>;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, IRouteParams>;

export interface AppInterface {
  addModal(modal: React.ReactNode);
}

@(withRouter as any)
class App extends ComponentUpdateTemplate<Props & ConnectedProps> implements AppInterface {
  public static childContextTypes = {
    app: PropTypes.object,
    initialRoute: PropTypes.bool
  };

  public props: Props & ConnectedProps;
  private _previousLocation: Location | null;
  public refs: {
    modalOrchestrator: ModalOrchestratorInterface
  };
  private deferAddModal: React.ReactElement<any> | null;

  public isInitialRoute = true;
  public getChildContext() {
    return {
      app: this,
      initialRoute: this.isInitialRoute
    };
  }


  public addModal(modal: React.ReactElement<any>) {
    if (!this.refs.modalOrchestrator) {
      this.deferAddModal = modal;
    } else {
      this.refs.modalOrchestrator.add(modal);
    }
  }

  componentWillReceiveProps(nextProps) {
    // If we see a stored `_previousProps`, use it to compare with current props, and clear it.
    const _prevProps: Props & ConnectedProps = {...this.props};
    if (this._previousLocation) {
      _prevProps.location = this._previousLocation;
      this._previousLocation = null;
    }

    const isStateNavigated = super.checkIsStateNavigated(nextProps);
    if (isStateNavigated) {
      /**
       * CMS Related
       */
      const thisRoute = _prevProps.routes[_prevProps.routes.length - 1];
      const nextRoute = nextProps.routes[nextProps.routes.length - 1];
      if (_isAdminCMSSitePage(nextProps.location.pathname)) {
        let shouldScrollToTop = true;

        // When toggling sitemenu side modal in menu list page, pathname will change, but we dont want to scroll
        const fromSiteMenuToMenuHome = (thisRoute.path === ADMIN_CMS_SITE_MENU_EDIT_PATH || thisRoute.path === ADMIN_CMS_SITE_MENU_NEW_PATH) && (nextRoute.path === ADMIN_CMS_SITE_MENUS_PATH || nextRoute.path === ADMIN_CMS_PAGE_MENUS_PATH);
        const fromeMenuHomeToSiteMenu = (thisRoute.path === ADMIN_CMS_SITE_MENUS_PATH || thisRoute.path === ADMIN_CMS_PAGE_MENUS_PATH) && (nextRoute.path === ADMIN_CMS_SITE_MENU_EDIT_PATH || nextRoute.path === ADMIN_CMS_SITE_MENU_NEW_PATH);
        if (fromSiteMenuToMenuHome || fromeMenuHomeToSiteMenu) shouldScrollToTop = false;

        // When toggling pagemenu side modal in menu list page, pathname will change, but we dont want to scroll
        const fromPageMenuToMenuHome = (thisRoute.path === ADMIN_CMS_PAGE_MENU_EDIT_PATH || thisRoute.path === ADMIN_CMS_PAGE_MENU_NEW_PATH) && (nextRoute.path === ADMIN_CMS_SITE_MENUS_PATH || nextRoute.path === ADMIN_CMS_PAGE_MENUS_PATH);
        const fromMenuHomeToPageMenu = (thisRoute.path === ADMIN_CMS_SITE_MENUS_PATH || thisRoute.path === ADMIN_CMS_PAGE_MENUS_PATH) && (nextRoute.path === ADMIN_CMS_PAGE_MENU_EDIT_PATH || nextRoute.path === ADMIN_CMS_PAGE_MENU_NEW_PATH);
        if (fromPageMenuToMenuHome || fromMenuHomeToPageMenu) shouldScrollToTop = false;

        // When toggling preview modal (resources/contacts), pathname will change, but we dont want to scroll
        const fromContactPreviewToContactHome = thisRoute.path === ADMIN_CMS_CONTACT_PREVIEW_PATH && nextRoute.path === ADMIN_CMS_CONTACTS_PATH;
        const fromContactHomeToContactPreview = thisRoute.path === ADMIN_CMS_CONTACTS_PATH && nextRoute.path === ADMIN_CMS_CONTACT_PREVIEW_PATH;
        if (fromContactPreviewToContactHome || fromContactHomeToContactPreview) shouldScrollToTop = false;
        
        const fromResourcePreviewToResourceHome = thisRoute.path === ADMIN_CMS_RESOURCES_PREVIEW_PATH && (nextRoute.path === ADMIN_CMS_RESOURCES_PATH || nextRoute.path === ADMIN_CMS_RESOURCES_CATEGORIES_PATH);
        const fromResourceHomeToResourcePreview = (thisRoute.path === ADMIN_CMS_RESOURCES_PATH || thisRoute.path === ADMIN_CMS_RESOURCES_CATEGORIES_PATH) && nextRoute.path === ADMIN_CMS_RESOURCES_PREVIEW_PATH;
        if (fromResourcePreviewToResourceHome || fromResourceHomeToResourcePreview) shouldScrollToTop = false;
        
        // if pathname is the same, dont need to scroll back to top
        if ((_prevProps.location.pathname === nextProps.location.pathname) || (thisRoute.path === nextRoute.path)) shouldScrollToTop = false;

        if (shouldScrollToTop && nextProps.apiSaving === 0) window.scrollTo(0, 0);
      }

      /**
       * Facilities Location Related
       */
      if (_isAdminFacilityLocationPage(nextProps.location.pathname)) {
        let shouldScrollToTop = true;
        // if pathname is the same, dont need to scroll back to top
        if ((_prevProps.location.pathname === nextProps.location.pathname) || (thisRoute.path === nextRoute.path)) shouldScrollToTop = false;

        // When toggling blackout date side modal, should not scroll to top
        const isNavingBetweenBlackoutDateAndBlackoutDateHome = isNavingBetweenSideModalAndListPages(
          [ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH, ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_NEW_PATH],
          ADMIN_FACILITY_LOCATION_BLACKOUT_DATES_PATH,
          thisRoute.path,
          nextRoute.path,
        );

        if (isNavingBetweenBlackoutDateAndBlackoutDateHome) shouldScrollToTop = false;
        if (shouldScrollToTop && nextProps.apiSaving === 0) window.scrollTo(0, 0);
      }

      /**
       * Admin Events Related
       */
      if (_isAdminEventsPage(nextProps.location.pathname)) {
        let shouldScrollToTop = true;
        // if pathname is the same, dont need to scroll back to top
        if ((_prevProps.location.pathname === nextProps.location.pathname) || (thisRoute.path === nextRoute.path)) shouldScrollToTop = false;

        // When toggling message side modal, should not scroll to top
        const isNavingBetweenMessageAndMessageHome = isNavingBetweenSideModalAndListPages(
          [ADMIN_EVENTS_EDIT_MESSAGE_PATH, ADMIN_EVENTS_NEW_MESSAGE_PATH],
          ADMIN_EVENTS_MESSAGE_CENTER_PATH,
          thisRoute.path,
          nextRoute.path,
        );

        if (isNavingBetweenMessageAndMessageHome) shouldScrollToTop = false;

        // When toggling product side modal, should not scroll to top
        const isNavingBetweenProductAndProductHome = isNavingBetweenSideModalAndListPages(
          [ADMIN_EVENTS_EDIT_PRODUCT_PATH, ADMIN_EVENTS_NEW_PRODUCT_PATH],
          ADMIN_EVENTS_PRODUCTS_PATH,
          thisRoute.path,
          nextRoute.path,
        );

        if (isNavingBetweenProductAndProductHome) shouldScrollToTop = false;

        // When toggling class side modal, should not scroll to top
        const isNavingBetweenClassAndClassHome = isNavingBetweenSideModalAndListPages(
          [ADMIN_EVENT_NEW_CLASS_PATH, ADMIN_EVENT_EDIT_CLASS_PATH],
          ADMIN_EVENT_CLASSES_PATH,
          thisRoute.path,
          nextRoute.path,
        );

        if (isNavingBetweenClassAndClassHome) shouldScrollToTop = false;

        // When toggling class type side modal, should not scroll to top
        const isNavingBetweenClassTypeAndClassTypeHome = isNavingBetweenSideModalAndListPages(
          [ADMIN_EVENT_EDIT_CLASS_TYPE_PATH, ADMIN_EVENT_NEW_CLASS_TYPE_PATH],
          ADMIN_EVENT_CLASS_TYPES_PATH,
          thisRoute.path,
          nextRoute.path,
        );

        if (isNavingBetweenClassTypeAndClassTypeHome) shouldScrollToTop = false;

        if (shouldScrollToTop && nextProps.apiSaving === 0) window.scrollTo(0, 0);
      }
    }
  }

  public shouldComponentUpdate(nextProps: Props & ConnectedProps) {
    if (!reduxStoreService().getState().app.isRouterChangeRollbackFinished) {
      /**
       * Store the oldProps.location before route changed so that we can use it to perform route-change related operations
       * in componentWillReceiveProps:
       * 1. When we see a location change, we need to update `previousLocation` so that back buttons in headers work properly
       * 2. We also use location info to determine if we should scroll the page to top
       * 
       * Since the route change is the first thing to happen after this flag is set to false in `onBeforeBrowserUrlChange`,
       * we are guaranteed that the first `this.props` will always have the previous location
       * 
       * @TODO - We need to revisit this code and make sure concurrency mode wont skip/batch any prop changes when upgrading React to the latest
       */
      if (!this._previousLocation) this._previousLocation = this.props.location;

      return false;
    }
    return !shallowEqual(nextProps, this.props);
  }

  // TODO: Right now `componentDidUpdate` is a side effect for ANY prop change... with latest React, we should
  // change to use `useEffect` so that this code can be broken down to side effects
  // of more specific props change
  // Logic inside this hook will be invoked AFTER all children finish rendering. In other words, they will
  // be run after all children's `componentDidMount` hook
  public componentDidUpdate(prevProps: Props & ConnectedProps) {
    if (
      this.isInactiveAdminFacilityLocationPage(this.props) ||
      (this.isInactiveAdminEventsPage(this.props) && !isModalOpened(ModalTypes.ADMIN_EVENT_TYPES))
    ) {
      this.props.actions.closeAllModals();
    }
    if (reduxStoreService().getState().rollback.isRollbackJustFinished) {
      this.props.actions.clearRollback();
    }
  }
  onLoadWindow = () => window.dispatchEvent(new Event('resize'));

  public componentDidMount() {
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        UnauthenticatedCacheManager.getInstance().loadLoginForm({
          props: this.props,
          isStateNavigated,
        });
      }
    );
    
    if (this.deferAddModal) {
      this.addModal(this.deferAddModal);
      this.deferAddModal = null;
    }
    this.isInitialRoute = false;
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);

    // NOTE: Side effects on browser url change are handled in this subscriber.
    (this.props.router as any).listenBefore((location) => onBeforeBrowserUrlChange(location, reduxStoreService()));
    (this.props.router as any).listen((location) => onBrowserUrlChange(location, reduxStoreService()));
    window.onload = this.onLoadWindow;
  }

  routerWillLeave(nextLocation) {
    return 'Your work is not saved! Are you sure you want to leave?';
  }

  // Given the current path, return what section is currently selected
  private getSelected(path) {
    if (path === URLS.HOME) {
      return NAVS.HOME;
    } else if (path.startsWith(URLS.PROFILE) || path.startsWith(URLS.MY_ROSTER) || path.startsWith(URLS.ORDER_HISTORY)) {
      return NAVS.SETTINGS;
    } else if (path.startsWith(URLS.EVENT_TYPES) || path.startsWith(URLS.EVENTS)) {
      return NAVS.EVENTS;
    } else if (path.startsWith(URLS.FACILITIES) || path.startsWith(URLS.FACILITY_TRIP)) {
      return NAVS.FACILITIES;
    } else if (path.startsWith(URLS.ADMIN_SITES) || path.startsWith(URLS.ADMIN_RESOURCES) || path.startsWith(URLS.ADMIN_CONTACTS) || path.startsWith(URLS.ADMIN_SITE_MENU) || path.startsWith(URLS.ADMIN_PAGE_MENU)) {
      return NAVS.WEBSITES;
    } else if (path.startsWith(URLS.ADMIN_FACILITY_LOCATION) || path.startsWith(URLS.ADMIN_FACILITY_LOCATION_TYPE) || path.startsWith(URLS.ADMIN_FACILITY_LOCATION_BLACKOUT_DATE)) {
      return NAVS.FACILITY_LOCATION;
    } else if (path.startsWith(URLS.ADMIN_EVENTS) || path.startsWith(URLS.ADMIN_EVENT_CLASS) || path.startsWith(URLS.ADMIN_EVENT_CLASS_TYPE) || path.startsWith(URLS.ADMIN_EVENT_PRODUCT) || path.startsWith(URLS.ADMIN_EVENT_MESSAGE)) {
      return NAVS.ADMIN_EVENTS;
    }
    return NAVS.NONE;
  }

  private isAdminMainPage() {
    const {routes} = this.props;

    const route = routes[routes.length - 1];
    const path = route.path;
    const adminCMSMainPagePaths = [
      ADMIN_CMS_RESOURCES_PATH,
      ADMIN_CMS_RESOURCES_CATEGORIES_PATH,
      ADMIN_CMS_RESOURCES_PREVIEW_PATH,
      ADMIN_CMS_CONTACTS_PATH,
      ADMIN_CMS_CONTACT_PREVIEW_PATH,
      ADMIN_CMS_SETTINGS_GENERAL_PATH,
      ADMIN_CMS_SETTINGS_MESSAGES_PATH,
      ADMIN_CMS_SITE_MENUS_PATH,
      ADMIN_CMS_SITE_MENU_EDIT_PATH,
      ADMIN_CMS_SITE_MENU_NEW_PATH,
      ADMIN_CMS_PAGE_MENUS_PATH,
      ADMIN_CMS_PAGE_MENU_NEW_PATH,
      ADMIN_CMS_PAGE_MENU_EDIT_PATH,
      ADMIN_CMS_PAGES_PATH,
    ];
    const adminFacilityLocationMainPagePaths = [
      ADMIN_FACILITY_LOCATION_FACILITIES_PATH,
      ADMIN_FACILITY_LOCATION_FACILITY_TYPES_PATH,
      ADMIN_FACILITY_LOCATION_AVAILABILITIES_PATH,
      ADMIN_FACILITY_LOCATION_BLACKOUT_DATES_PATH,
      ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_NEW_PATH,
      ADMIN_FACILITY_LOCATION_BLACKOUT_DATE_EDIT_PATH,
      ADMIN_FACILITY_LOCATION_REPORTS_PATH,
      ADMIN_FACILITY_LOCATION_SETTINGS_PATH,
    ];
    const adminEventsMainPagePaths = [
      ADMIN_EVENTS_EVENTS_PATH,
      ADMIN_EVENTS_PRODUCTS_PATH,
      ADMIN_EVENTS_NEW_PRODUCT_PATH,
      ADMIN_EVENTS_EDIT_PRODUCT_PATH,
      ADMIN_EVENTS_REPORTS_PATH,
      ADMIN_EVENTS_YEAR_OVERVIEW_PATH,
      ADMIN_EVENTS_MESSAGE_CENTER_PATH,
      ADMIN_EVENTS_NEW_MESSAGE_PATH,
      ADMIN_EVENTS_EDIT_MESSAGE_PATH,
      ADMIN_EVENTS_SETTINGS_PATH,
    ];

    return path && (adminCMSMainPagePaths.indexOf(path) !== -1 || adminFacilityLocationMainPagePaths.indexOf(path) !== -1 || adminEventsMainPagePaths.indexOf(path) !== -1);
  }

  isInactiveAdminFacilityLocationPage = (props?: Props & ConnectedProps) => {
    const {location} = props ? props : this.props;
    const FacilitiesLocation = reduxStoreService().getState().adminFacilityLocation.cacheOne.FacilitiesLocation;

    return _isAdminFacilityLocationPage(location.pathname) && FacilitiesLocation && FacilitiesLocation.Inactive;
  };

  isInactiveAdminEventsPage = (props?: Props & ConnectedProps) => {
    const {location, routes} = props ? props : this.props;

    // Here we need to grab `EventsEventType` from store because on switching from an Inactive EventType to another
    // one, we are relying on the loader to perform clear cache, which happens after router change, and so the router
    // change will trigger `componenDidUpdate` before cache is cleared.
    const EventsEventType = reduxStoreService().getState().adminEvents.cacheOne.EventsEventType;

    const route = routes[routes.length - 1];

    return _isAdminEventsPage(location.pathname) && (route.path !== ADMIN_EVENTS_SETTINGS_PATH) && EventsEventType && EventsEventType.EventTypeRow.Inactive;
  };

  renderBody() {
    const {body, apiSavingMap, SystemSettings, userModel, location} = this.props;
    // GetLoginForm must occur before anything happens, this condition ensures <LoadingAll />
    // before GetLoginForm request is fired and also before GetLoginForm request is returned
    if (apiSavingMap[getLoginFormRequestAction] || Object.keys(apiSavingMap).length === 0) {
      return <LoadingAll />;
    }
    if (!SystemSettings) return <NotFound message={GET_LOGIN_FAIL_MESSAGE} />;
    if (this.isInactiveAdminFacilityLocationPage()) {
      return <NotFound message={INVALID_LOCATION} />;
    }
    return body;
  }

  renderAppHeader = (isAdminCMSSitePage: boolean, isAdminFacilityLocationPage: boolean, isAdminEventsPage: boolean) => {
    const {showAdminPageHeader, isNotFound, SystemSettings} = this.props;

    if (isAdminFacilityLocationPage && this.isInactiveAdminFacilityLocationPage()) return null;
    if (isAdminEventsPage && this.isInactiveAdminEventsPage()) return null;
    if (SystemSettings && showAdminPageHeader && !isNotFound) {
      // TODO: Instead of relying on `showAdminPageHeader`, show `<AdminHeader />` based on URL. Then,
      // remove this flag and its corresponding action from redux.
      return <AdminHeader />;
    }
    return null;
  };

  withInert = (ModalElement: React.ComponentClass, modalType: ModalTypes) => {
    const EnhancedModal = WithInertModal(
      ModalElement,
      modalType,
    );

    return <EnhancedModal key={modalType} />;
  };

  renderModal = (modal: IModal, isAdmin: boolean) => {
    const {
      cacheTwoPrevOrders, actions, getEmptyCartBody, apiLoadingMap,
    } = this.props;
    switch(modal.type) {
      // Shared
      case ModalTypes.SELECT_LOCATION:
        return this.withInert(SelectLocationModal, modal.type);
      case ModalTypes.LOCATION_FORM:
        return this.withInert(LocationFormModal, modal.type);
      // CMS
      case ModalTypes.CMS_SITES:
        return this.withInert(AdminCMSSiteModal, modal.type);
      case ModalTypes.RESOURCE_FORM:
        return this.withInert(ResourceFormModal, modal.type);
      case ModalTypes.CONTACT_FORM:
        return this.withInert(ContactFormModal, modal.type);
      case ModalTypes.PAGE_MENU_ITEM_FORM:
        return this.withInert(PageMenuItemFormModal, modal.type);
      case ModalTypes.RESOURCES:
        return this.withInert(ResourceModal, modal.type);
      case ModalTypes.SELECT_PAGE:
        return this.withInert(SelectPageModal, modal.type);
      case ModalTypes.NEW_PAGE:
        return this.withInert(NewPageModal, modal.type);
      case ModalTypes.MULTIPLE_CONTACTS:
        return this.withInert(MultipleContacts, modal.type);
      case ModalTypes.MULTIPLE_RESOURCES:
        return this.withInert(MultipleResources, modal.type);
      case ModalTypes.DELETE_MENU_ITEM:
        return this.withInert(DeleteMenuItemModal, modal.type);
      case ModalTypes.SELECT_RESOURCE_CATEGORIES:
        return this.withInert(ResourceCategoryModal, modal.type);
      case ModalTypes.RESOURCE_CATEGORY_FORM:
        return this.withInert(ResourceCategoryFormModal, modal.type);
      case ModalTypes.DUPLICATE_PAGE:
        return this.withInert(DuplicatePageFormModal, modal.type);
      case ModalTypes.GALLERY_IMAGE_FORM:
        return this.withInert(ImageFormModal, modal.type);
      // Admin Facilities
      case ModalTypes.SELECT_GL_ACCOUNTS:
        return this.withInert(GLAccountModal, modal.type);
      case ModalTypes.GL_ACCOUNT_FORM:
        return this.withInert(GLAccountFormModal, modal.type);
      case ModalTypes.FACILITY_TYPE_SHARED_SETTINGS:
        return this.withInert(FacilityShareSettingsModal, modal.type);
      case ModalTypes.FACILITY_TYPE_FORM:
        return this.withInert(FacilityTypeFormModal, modal.type);
      case ModalTypes.IMPORT_FROM_FACILITY:
        return this.withInert(ImportFromFacilityModal, modal.type);
      case ModalTypes.NEW_FACILITY:
        return this.withInert(NewFacilityModal, modal.type);
      case ModalTypes.MULTIPLE_FACILITIES:
        return this.withInert(MultipleFacilities, modal.type);
      case ModalTypes.DELETE_BLACKOUT_DATE:
        return this.withInert(DeleteBlackouteDateModal, modal.type);
      case ModalTypes.DELETE_FACILITY_TYPE:
        return this.withInert(DeleteFacilityTypeModal, modal.type);
      case ModalTypes.FACILITY_TYPE_CONFIRMATION:
        return this.withInert(FacilityTypeConfirmation, modal.type);
      // Admin Events
      case ModalTypes.ADMIN_EVENT_TYPES:
        return this.withInert(EventTypesModal, modal.type);
      case ModalTypes.NEW_EVENT_TYPE:
        return this.withInert(NewEventTypeModal, modal.type);
      case ModalTypes.EVENT_FORM:
        return this.withInert(EventFormModal, modal.type);
      case ModalTypes.SELECT_MESSAGE:
        return this.withInert(SelectMessageModal, modal.type);
      case ModalTypes.REPLACE_PARTICIPANT_TYPE:
        return this.withInert(ReplaceParticipantTypeModal, modal.type);
      case ModalTypes.REMOVE_PARTICIPANT_TYPE:
        return this.withInert(RemoveParticipantTypeModal, modal.type);
      case ModalTypes.EVENT_TYPE_SHARED_SETTINGS:
        return this.withInert(EventTypeSharedSettingsModal, modal.type);
      case ModalTypes.IMPORT_FROM_EVENT:
        return this.withInert(ImportFromEventModal, modal.type);
      case ModalTypes.RECALCULATE_PRICING:
        return this.withInert(RecalculatePricingModal, modal.type);
      case ModalTypes.EXPORT_INSTRUCTOR_ROSTER:
        return this.withInert(ExportInstructorRosterModal, modal.type);
      case ModalTypes.GENERATE_INVOICES:
        return this.withInert(GenerateInvoicesModal, modal.type);
      case ModalTypes.MULTIPLE_GROUPS:
        return this.withInert(MultipleGroupsModal, modal.type);
      case ModalTypes.MESSAGE_FORM:
        return this.withInert(MessageFormModal, modal.type);
      case ModalTypes.SEND_MESSAGE:
        return this.withInert(SendMessageModal, modal.type);
      case ModalTypes.ENTER_CLASS_REQUIREMENT_COMPLETED:
        return this.withInert(EnterClassRequirementModal, modal.type);
      case ModalTypes.SELECT_CLASS_TYPE:
        return this.withInert(SelectClassTypeModal, modal.type);
      case ModalTypes.CLASS_TYPE_FORM:
        return this.withInert(ClassTypeFormModal, modal.type);
      case ModalTypes.DELETE_ADMIN_EVENTS_CLASS:
        return this.withInert(DeleteAdminEventClassModal, modal.type);
      case ModalTypes.EXPORT_PARTICIPANT_CLASS_SCHEDULE:
        return this.withInert(ExportParticipantClassScheduleModal, modal.type);
      // Admin
      case ModalTypes.ACCOUNTS_MODAL:
        if (isAdmin) {
          return this.withInert(AdminAccountsModal, modal.type);
        }
      // Checkout
      case ModalTypes.ORDER_SUBMITTED:
        return this.withInert(OrderSubmitted, modal.type);
      case ModalTypes.CART:
        if (getEmptyCartBody) {
          return this.withInert(CartModal, modal.type);
        }
      case ModalTypes.SUBMITTING_PAYMENT:
        return this.withInert(SubmittingPayment, modal.type);
      case ModalTypes.PAYMENT_FAILED:
        return this.withInert(PaymentFailed, modal.type);
      case ModalTypes.DELETE_ITEM_OR_CART:
        return this.withInert(RemoveItemModal, modal.type);
      case ModalTypes.CHECKOUT_MESSAGES:  // This modal is not covered by test yet because it is opened in an epic
        return this.withInert(CheckoutMessagesModal, modal.type);
      case ModalTypes.FULL_CLASSES:       // This modal is not covered by test yet because it is opened in an epic
        return this.withInert(FullClassesModal, modal.type);
      // Report
      case ModalTypes.REPORT_FINISHED:    // This modal is not covered by test yet because it is opened in an epic
        return this.withInert(ReportFinishedModal, modal.type);
      // Orders
      case ModalTypes.DELETE_ORDER:
        const loading = apiLoadingMap[GetPrevOrders.requestType] || (apiLoadingMap[GetPrevOrder.requestType] && !isCacheTwoPrevOrdersPopulated(cacheTwoPrevOrders));
        if (!loading) {
          return this.withInert(DeleteOrderAndPaymentModal, modal.type);
        }
        return null;
      // Others
      case ModalTypes.TENTAROO_AIR_APP:
        return this.withInert(AirAppModal, modal.type);
      case ModalTypes.CANCEL_REGISTRATION:
        return this.withInert(CancelRegistration, modal.type);
      case ModalTypes.CANCEL_RESERVATION:
        return this.withInert(CancelReservation, modal.type);
      case ModalTypes.ADD_UNSCHEDULED:
        return this.withInert(ClassAddUnscheduled, modal.type);
      case ModalTypes.PAYMENT_TOOLS:
        return this.withInert(PaymentTools, modal.type);
      case ModalTypes.PROFILE_DELETE_PERSON:
        return this.withInert(ProfileDeletePerson, modal.type);
      case ModalTypes.DELETE_PERSON:
        return this.withInert(DeletePerson, modal.type);
      case ModalTypes.REGISTER_OVERRIDE_FEE:
        return this.withInert(RegisterOverrideFeeModal, modal.type);
      case ModalTypes.RESERVATION_OVERRIDE_FEE:
        return this.withInert(ReservationOverrideFeeModal, modal.type);
      case ModalTypes.OVERRIDE_FEES:
        return this.withInert(ClassOverrideFee, modal.type);
      case ModalTypes.RESET_PASSWORD:
        return this.withInert(ResetPassword, modal.type);
      case ModalTypes.CONFIRM_SEND_RESET_LINK:
        return this.withInert(ConfirmSendResetLink, modal.type);
      case ModalTypes.CHANGE_PASSWORD:
        return this.withInert(ChangePassword, modal.type);
      case ModalTypes.JOIN_WAITLIST:
        return this.withInert(JoinWaitingListModal, modal.type);
      case ModalTypes.EDIT_EMAIL:
        return this.withInert(EditEmail, modal.type);
      case ModalTypes.ACTIVATE_ACCOUNT:
        return this.withInert(ActivateAccount, modal.type);
      case ModalTypes.SELECT_MERIT_BADGE:
        return this.withInert(SelectMeritBadgeModal, modal.type);
      case ModalTypes.MANAGE_REQUIREMENTS:
        return this.withInert(ManageRequirementsMoldal, modal.type);
      default:
        return null;
    }
  };

  public render() {
    const {
      header, nav, location, userModel, isSessionValidated, SystemSettings, snackbar, gutter, loadingAll, isLoggingOut,
      isNotFound, isNotFoundMessage, openedModals, topFloatingAlert, showAdminPageHeader, isModalSaving,
    } = this.props;
    let pathname = location.pathname;
    if (pathname.endsWith('/') && pathname.length > 1) pathname = pathname.substring(0, pathname.length - 1);
    let isAdmin = false;
    if (userModel) {
      isAdmin = !!userModel.str_permissions.hasAdminAccess;
    }
    let gutterClassName: string = `app--gutter`;
    if (gutter && gutter.mobile) {
      gutterClassName += ` hide-all-except show-mobile`;
    }
    const isAdminCMSSitePage = _isAdminCMSSitePage(pathname);
    const isAdminFacilityLocationPage = _isAdminFacilityLocationPage(pathname);
    const isAdminEventsPage = _isAdminEventsPage(pathname);
    const isMainPage = pathname === URLS.EVENT_TYPES || pathname === URLS.FACILITIES ||
      pathname === URLS.PROFILE || pathname === URLS.MY_ROSTER ||
      pathname === URLS.HOME || pathname === URLS.ADD_GROUP || this.isAdminMainPage();
    let headerToRender = <Header title="" hideIcon alwaysLoad/>;
    // `session.isValidated` could be
    //     - undefined - when page is first loaded (direct visit), and at that time, we wont show header at all
    //     - true - when page is fully loaded, and user is logged in and user session is not expired yet, we would show the header if other conditions are true
    //     - false - when logout successfully, or when user session is expired (fail to get login form); in this case, we will ONLY show header if user is in LOGIN/NEW_ACCOUNT pages
    // By doing so, we could prevent header from re-rendering when corresponding data is missing (during logout)
    if (!loadingAll && (!!isSessionValidated || pathname === URLS.LOGIN || pathname === URLS.NEW_ACCOUNT) && !isNotFound) {
      // TODO: looking at `routes.tsx`, it seems we always pass in a `header` for every page, even for '*' match, doesn't seem like
      // we need a fallback here, altho there's no harm to
      if (header) {
        headerToRender = React.cloneElement(header, {
          ...header.props,
          showDrawer: !!nav,
          isAdminPage: isAdminCMSSitePage || isAdminFacilityLocationPage || isAdminEventsPage,
          longTabs: isAdminFacilityLocationPage || isAdminEventsPage,
        });
      } else {
        headerToRender = <Header isAdminPage={isAdminCMSSitePage || isAdminFacilityLocationPage || isAdminEventsPage} longTabs={isAdminFacilityLocationPage || isAdminEventsPage} showDrawer={!!nav} alwaysLoad/>;
      }
    }

    const selected = this.getSelected(pathname);
    return (
      <div className={`app${isLoggingOut ? ' is-logging-out' : ''}`}>
        {isLoggingOut && <Loader className="app--loader"/>}
        {gutter && gutter.height && <div className={gutterClassName} style={{ height: `${gutter.height}px` }}/>}
        {!SystemSettings ? null : headerToRender}
        {topFloatingAlert && (
          <TopFloatingAlert>
            {topFloatingAlert}
          </TopFloatingAlert>
        )}
        {isMainPage && !loadingAll && SystemSettings && !isNotFound &&
          <CompactNavContainer
            isCMSSitePage={isAdminCMSSitePage && showAdminPageHeader}
            isAdminFacilityLocationPage={isAdminFacilityLocationPage && showAdminPageHeader}
            isAdminEventsPage={isAdminEventsPage && showAdminPageHeader}
          >
            <Nav size="compact" selected={selected} hideClient={pathname === URLS.ADD_GROUP}/>
          </CompactNavContainer>}
        {SystemSettings && nav && <NavContainer>{nav}</NavContainer>}
        {this.renderAppHeader(isAdminCMSSitePage, isAdminFacilityLocationPage, isAdminEventsPage)}
        {isNotFound && location.pathname !== URLS.LOGIN ? <NotFound message={isNotFoundMessage}/> : this.renderBody()}
        {openedModals ? openedModals.map((m) => {
          return this.renderModal(m, isAdmin);
        }) : null}
        {snackbar && <Snackbar message={snackbar.message}/>}
        {isModalSaving && <PageLoader isGlobalLoader className="app--loader--overlay"/>}
        <ModalOrchestrator {...this.props as any} ref="modalOrchestrator"/>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const path = (state as any).routing.locationBeforeTransitions.pathname;
  return {
    userModel: state.user.user,
    SystemSettings: state.session.SystemSettings,
    isSessionValidated: state.session.isValidated,
    cacheZero: state.cacheZero,
    cacheOne: state.cacheOne,
    cacheZeroOptions: state.cacheZero.options,
    cacheTwoPrevOrders: state.cacheTwoPrevOrders,
    CMSSite: state.adminCMSSite.cacheOne.CMSSite,
    CMSPage: state.adminCMSSite.cacheTwoPage.CMSPage,
    FacilitiesLocation: state.adminFacilityLocation.cacheOne.FacilitiesLocation,
    FacilitiesFacility: state.adminFacilityLocation.cacheTwoFacility.FacilitiesFacility,
    EventsEventType: state.adminEvents.cacheOne.EventsEventType,
    EventsEvent: state.adminEvents.cacheTwoEvent.EventsEvent,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSavingMap: state.app.apiSavingMap,
    isRouterChangeRollbackFinished: state.app.isRouterChangeRollbackFinished,
    snackbar: state.app.snackbar,
    gutter: state.app.gutter,
    loadingAll: state.app.loadingAll,
    isLoggingOut: state.app.isLoggingOut,
    isNotFound: state.app.isNotFound,
    isNotFoundMessage: state.app.isNotFoundMessage,
    openedModals: state.app.openedModals,
    topFloatingAlert: state.app.topFloatingAlert,
    showAdminPageHeader: state.app.showAdminPageHeader,
    isModalSaving: state.app.isModalSaving,
    // EmptyCart and Delete Item related
    getEmptyCartBody: state.app.getEmptyCartBody,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...sessionActionCreators,
    ...rollbackActionCreators,
    ...homeActionCreators,
    ...cartActionCreators,
    ...cacheOneActionCreators,
  }, dispatch)
});

const ConnectedApp = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(App);

export default ConnectedApp;
