
import {GetGLAccountList} from "./actions";
import { makeRequestEpic } from "../../../../Validation/epicCreator";
import { getGLAccounts } from "../../../../../constants/urls";

export const adminSettingsGetGLAccountsEpic = makeRequestEpic(
  GetGLAccountList,
  getGLAccounts,
  undefined,
  true,
);