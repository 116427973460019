
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';

import {
  Modal, ModalHeader, ModalContent, ModalActions, Button, Row, Select, PageLoader, SimpleList, EmptyMessage
} from '../../../../../Elements';
import { actionCreators } from '../../../../../../store/AdminFacilityLocation/Facilities/Modals/MultipleFacilities/actions';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as cacheOneActionCreators, GetFacilityLocationCache } from '../../../../../../store/AdminFacilityLocation/CacheOne/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../store/Rollback/actions';
import { actionCreators as blackoutDateFormActionCreators } from '../../../../../../store/AdminFacilityLocation/BlackoutDates/Form/actions';
import { ApplicationState } from '../../../../../../store';
import '../../../../../../styles/pages/admin-facility-location/facilities/modals/multiple-facilities/index.scss';
import { extractRouteParams } from '../../../../../../utils/urlHelper';
import { Facilities2Icon } from '../../../../../../components/Icons';
import { EmptyMessageType } from '../../../../../../components/Elements/EmptyMessage';
import { ModalHeight } from '../../../../../../components/Elements/Modal';
import { makeFilteredFacilitiesSelector } from '../../../../../../store/AdminFacilityLocation/Facilities/Modals/MultipleFacilities';
import { AdminFacility } from '../../../../../../models/api/adminFacilitiesCacheOne';
import AdminFacilityCard, { AdminFacilityCardType } from '../../../../../../components/Elements/AdminFacility/Card';
import { CardCategory } from '../../../../../../components/Elements/Card';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { Validator } from '../../../../../../utils/validator/models';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-facility--modals--multiple-facilities';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{},{contactId: string}>;
type Props = WithInertAttribute<{}>;

@(withRouter as any)
class MultipleFacilitiesModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;
  private modalContentRef;

  componentDidMount() {
    const {actions, blackoutDateForm} = this.props;

    if (shouldBlockActions()) return;

    actions.init(blackoutDateForm.ActiveForm.Facilities || []);
  }

  componentWillUnmount() {
    this.onClose();
  }

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    if (this.props.multipleFacilitiesForm.filterString) {
      this.scrollToTop();
    }
    this.props.actions.toggleFilter(false);
    this.props.actions.filterMultipleFacilities('');
  };
  
  onClose = () => {
    this.onSearchBack();
    this.props.actions.reset();
  };
  onSave = () => {
    this.props.blackoutDateFormActions.addFacilities(this.props.multipleFacilitiesForm.ActiveForm.SelectedFacilityIds);
    this.onClose();
    this.props.actions.popModal(false, true, ModalTypes.MULTIPLE_FACILITIES);
  };
  onRefreshClick = () => {
    const {actions} = this.props;
    actions.getFacilityLocationCache(
      {
        GetFacilities: true,
        MaxFacilityID: 0,
        ...extractRouteParams(this.props),
      },
      false,
      undefined,
      true,
    );
  };

  onFacilitySelect = (facility: AdminFacility) => {
    const {multipleFacilitiesForm} = this.props;
    const facilityIds = multipleFacilitiesForm.ActiveForm.SelectedFacilityIds;

    const existedId = facilityIds.findIndex((fId) => fId === facility.ID);
    const existed = existedId !== -1;
    this.props.actions.toggleFacilitySelect(!existed, facility.ID);
  };

  itemRenderer = (index) => {
    const {filteredFacilities, multipleFacilitiesForm} = this.props;
    const facility: AdminFacility = filteredFacilities[index];
    const facilityIds = multipleFacilitiesForm.ActiveForm.SelectedFacilityIds;

    const existedId = facilityIds.findIndex((fId) => fId === facility.ID);
    return (
      <div className={`multiple_facility_${index}`} key={`multiple_facility_${facility.ID}`}>
        <AdminFacilityCard
          checked={existedId !== -1}
          onMultipleSelect={this.onFacilitySelect}
          routes={this.props.routes}
          adminFacilityLocationCacheOne={this.props.adminFacilityLocationCacheOne}
          type={AdminFacilityCardType.MULTIPLE_FACILITIES}
          category={CardCategory.LIST_MOBILE}
          key={`multiple_facilities_list_item_${facility.ID}`}
          facility={facility} />
      </div>
    );
  };

  onSelectAll = () => {
    const {filteredFacilities} = this.props;
    this.props.actions.selectAll(filteredFacilities.filter((f) => !!f).map((f) => f.ID));
  };

  onDeselect = () => {
    const {filteredFacilities} = this.props;
    this.props.actions.deselect(filteredFacilities.map((f) => f.ID));
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };

  onFacilityTypeChange = (val: any, vObj: Validator) => {
    this.scrollToTop();
    this.props.actions.facilityModalUpdateValue(val, vObj);
  };
  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filterMultipleFacilities(val);
  };
  noFacilities = () => {
    const {allFacilities} = this.props;

    return !allFacilities || allFacilities.length === 0;
  };

  renderEmptyMessage = () => {
    const message = this.noFacilities() ? 'No facilities available' : 'No facilities found';
    return (
      <EmptyMessage
        icon={Facilities2Icon}
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={message}
      /> 
    );
  };

  public render() {
    const { apiLoadingMap, filteredFacilities, blackoutDateForm, cacheTwoBlackoutDate, inert, multipleFacilitiesForm: { ActiveForm, ValidationRules, isTextSearching}} = this.props;

    const title = 'Select Facilities';
    const loading = apiLoadingMap[GetFacilityLocationCache.requestType];
    const subtitle = blackoutDateForm.ActiveForm.Reason || (cacheTwoBlackoutDate && cacheTwoBlackoutDate.FacilitiesBlackoutDate ? 'Edit Blackout Date' : 'New Blackout Date');

    return (
      <Modal inert={inert} onClose={this.onClose} height={ModalHeight.HEIGHT_425} big mobileFullScreen className={namespace()}>
        <ModalHeader
          isSearching={isTextSearching}
          onRefresh={this.onRefreshClick}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={this.onSearchChange}
          onSearchBack={this.onSearchBack}
          subtitle={subtitle}
          className={`${namespace()}--header`}>
            {title}
        </ModalHeader>
        <Row className={`${namespace()}--filter`}>
          <Select
            disabled={false}
            hideOptional
            onChangeValue={this.onFacilityTypeChange}
            value={ActiveForm.SelectedFacilityTypeID}
            validationRules={ValidationRules.SelectedFacilityTypeID}
            isNumber />
        </Row>
        <ModalContent
          refCallback={(ref) => this.modalContentRef = ref}
          neverLoading
          paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0}
        >
          {!apiLoadingMap[GetFacilityLocationCache.requestType] && !(this.noFacilities() || filteredFacilities.length === 0) && <Row className={`${namespace()}--row-2`}>
            <SimpleList
              itemRenderer={this.itemRenderer}
              totalLength={filteredFacilities ? filteredFacilities.length : 0}
            />
          </Row>}
          {loading && <PageLoader className={`${namespace()}--page--loader`} />}
          {!loading && (this.noFacilities() || filteredFacilities.length === 0) ? this.renderEmptyMessage() : null}
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button textColor='black' disabled={loading} flat onClick={this.onSave}>SAVE</Button>}
          right={<div className={`${namespace()}--right-actions`}>
            {ActiveForm.SelectedFacilityIds.length > 0 ? <Button textColor='black' disabled={loading} flat onClick={this.onDeselect}>DESELECT</Button> : null}
            <Button textColor='black' disabled={loading} flat onClick={this.onSelectAll}>SELECT ALL</Button>
          </div>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredFacilitiesSelector = makeFilteredFacilitiesSelector();
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    multipleFacilitiesForm: state.adminFacilityLocation.facilities.modals.multipleFacilities,
    blackoutDateForm: state.adminFacilityLocation.blackoutDates.form,
    cacheTwoBlackoutDate: state.adminFacilityLocation.cacheTwoBlackoutDate,
    allFacilities: state.adminFacilityLocation.cacheOne.Facilities,
    adminFacilityLocationCacheOne: state.adminFacilityLocation.cacheOne,
    filteredFacilities: filteredFacilitiesSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...actionCreators,
    ...cacheOneActionCreators,
    ...rollbackActionCreators,
  }, dispatch),
  blackoutDateFormActions: bindActionCreators({
    ...blackoutDateFormActionCreators,
  }, dispatch),
});
const ConnectedMultipleFacilitiesModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(MultipleFacilitiesModal);

export default ConnectedMultipleFacilitiesModal;