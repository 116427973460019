import { IValidator, FormDefinition } from "./validation";
import { SUFFIX } from './actions';
import { apiValidationReducerCreator } from "../../../../../store/Validation/reducerCreator";
import { createSelector } from "reselect";
import * as Actions from './actions';
import { ADMIN_EVENT_TYPES_SUFFIX, ClearAdminEventsCacheBelowOne, ClearAllAdminEventsCache } from "../../../CacheOne/actions";
import { AdminEventReportType } from '../../../CacheTwoEvent';
import { ApplicationState } from "../../../../../store";
import { AdminEventReportsOption } from "../../../../../models/api/adminEventsCacheTwoEvent";
import { setDefaults } from "../../../../../utils/validator";
import { GetRegisteredGroupsAction } from "../../../Shared/actions";
import { ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX, ClearAdminEventsCacheTwoEvent } from "../../../CacheTwoEvent/actions";
import { SUBMIT_SUCCESS_ACTION, SUCCESS_ACTION, WithRootState } from "../../../../Validation/actionCreator";
import { ClearAllCache, SetCacheAction } from "../../../../App/actions";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../../utils/StrongActions";

export type EventToolsModalContext = 'dashboard' | 'reports' | 'class-list';

export interface AdminEventsEventDashboardState {
  ActiveForm: AdminEventsEventDashboardActiveForm;
  ValidationRules: IValidator;
}

export interface AdminEventsEventDashboardActiveForm {
  SelectedReportID?: AdminEventReportType;
  // For BlueCards report
  GroupIDi?: number;
  IsFront?: boolean;
  // For ScoutbookRequirements report
  GroupIDi_ScoutbookRequirements?: number;
}

const getInitialState: () => AdminEventsEventDashboardState = () => ({
  ActiveForm: { },
  ValidationRules: {...FormDefinition},
});

const checkApiValidation = apiValidationReducerCreator(SUFFIX);
const generateReportsCheckApiValidation = apiValidationReducerCreator('__GENERATE_REPORTS' + SUFFIX);

export const makeSelectedEventReportSelector = () => {
  return createSelector(
    [
      (state: ApplicationState) => state.adminEvents.events.event.dashboard.ActiveForm.SelectedReportID,
      (state: ApplicationState) => state.adminEvents.cacheOne.EventReportsOptions || [],
    ],
    (reportTypeId: AdminEventReportType, reports: AdminEventReportsOption[]) => {
      return reports.find((r) => r.ID === reportTypeId);
    }
  );
};


const AdminEventsEventDashboardReducer: Reducer<AdminEventsEventDashboardState> = (s, action: WithRootState<AnyAction>) => {
  const state = generateReportsCheckApiValidation(checkApiValidation(s, action), action);
  if (
    isActionType(action, ClearAdminEventsCacheBelowOne) ||
    isActionType(action, ClearAllCache) ||
    isActionType(action, ClearAllAdminEventsCache) ||
    isActionType(action, ClearAdminEventsCacheTwoEvent)
  ) {
    return setDefaults(action.rootState, getInitialState());
  } else if (
    (
      action.type === GetRegisteredGroupsAction.successType ||
      // QUESTION: Dont need to reset GroupIDi for other request?
      (action.type.includes(ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX) && action.type.includes(SUCCESS_ACTION) || action.type.includes(SUBMIT_SUCCESS_ACTION)) ||
      (isActionType(action, SetCacheAction) && action.actions.requestType.includes(ADMIN_EVENT_TYPES_SUFFIX))
    ) && action.type !== Actions.GenerateAdminEventsEventReportsActions.successType
  ) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        GroupIDi: 0,
        GroupIDi_ScoutbookRequirements: 0,
      },
    };
  }
  return state || setDefaults(action.rootState, getInitialState());
};

export default AdminEventsEventDashboardReducer;