import { makeRequestEpic } from "../../../store/Validation/epicCreator";
import { GetEventTypesAction, GetEventTypeCacheAction } from "./actions";
import { getEventTypes, getEventTypesBody, getEventTypeCache, getEventTypeBody } from "../../../constants/adminEventsUrls";

export const getAdminEventsCacheOneEpic = makeRequestEpic(
  GetEventTypeCacheAction,
  getEventTypeCache,
  getEventTypeBody,
);

export const getAdminEventTypesEpic = makeRequestEpic(
  GetEventTypesAction,
  getEventTypes,
  getEventTypesBody,
  true,
);