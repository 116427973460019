import {CancelReservationReset, SUFFIX, ValidateActions} from './actions';
import {determineError} from "./reducerHelpers";
import {apiValidationReducerCreator} from '../../../../Validation/reducerCreator';
import {APISuccess, UpdateCurrValue, WithRootState} from "../../../../Validation/actionCreator";
import {FormDefinition} from "./validation";
import {FinancialSummaryInit, initFinancialSummary} from "../../../../../utils/classesHelper";
import {FacilitiesRecalc} from "../../../../CacheFourFacilities/actions";
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import { ClearAllCache, ClearAllEndUserCacheButOptions } from '../../../../App/actions';
import { ClearCacheBelowOne } from '../../../../CacheOne/actions';

export interface CancelReservationState {
  ActiveForm: {
    selectedFinance?: 'min' | 'full' | 'other' | 'credit';
    otherValue?: number;
  },
  ValidationRules: any;
  SubmitErrorMessage?: string;
  minValue?: number;
  maxValue?: number;
}

const checkApiValidation = apiValidationReducerCreator(SUFFIX, determineError);

const getInitialState = (): CancelReservationState => {
  return {
    ActiveForm: {
      selectedFinance: 'min',
      otherValue: 0
    }, ValidationRules: {...FormDefinition}};
};

const CancelReservation: Reducer<CancelReservationState> = (oldState = {...getInitialState()}, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(oldState, act);
  if (act.type === FacilitiesRecalc.successType ) {
    const action = <WithRootState<APISuccess>> act;
    const vals: FinancialSummaryInit = initFinancialSummary(
      action.rootState,
      action.response.response.TripPaymentStatus,
      (rootState) => rootState.facilities.trip.modals.cancelReservation.ActiveForm.selectedFinance === 'other',
      false,
    );

    return {
      ...state,
      ActiveForm: {selectedFinance: vals.paymentType, otherValue: vals.otherDefaultValue},
      ValidationRules: {...state.ValidationRules, otherValue: vals.otherValueRules},
      minValue: action.response.response.TripPaymentStatus.AmountMin,
      maxValue: action.response.response.TripPaymentStatus.AmountMax
    };
  } else if (act.type === ValidateActions.updateCurrType) {
    const action = <WithRootState<UpdateCurrValue>> act;
    const newState = {...state};
    // since `otherValue` is being set to a default value in cases of 
    // `full`, `min` and `credit`, we should delete the related ValidationRules error
    newState.ActiveForm = {...state.ActiveForm};
    if (action.value === 'full') {
      newState.ActiveForm.otherValue = state.maxValue;
    } else if (action.value === 'min') {
      newState.ActiveForm.otherValue = state.minValue;
    } else if (action.value === 'credit') {
      newState.ActiveForm.otherValue = 0;
    } else {
      return state;
    }
    return newState;
  } else if (
    isActionType(act, CancelReservationReset) ||
    isActionType(act, ClearAllCache) ||
    isActionType(act, ClearAllEndUserCacheButOptions) ||
    isActionType(act, ClearCacheBelowOne)
  ) {
    return getInitialState();
  }
  return state;
};

export default CancelReservation;
