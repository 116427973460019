
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, ModalActions, Button
} from '../../../../../Elements';
import { actionCreators, EventFormModalNamespace as namespace } from '../../../../../../store/AdminEvents/Events/Event/Form/actions';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { makeFormModalPropSelector } from '../../../../../../store/App';
import { ApplicationState } from '../../../../../../store';
import '../../../../../../styles/pages/admin-events/events/modals/event-form/index.scss';
import EventForm from '../../Event/Form';
import { INVALID_EVENT } from '../../../../../../constants/messages/adminEvents';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{},{glAccountId: string}>;
type Props = WithInertAttribute<{
}>

@(withRouter as any)
class EventFormModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;

  componentDidMount() {
    if (shouldBlockActions()) return;
    
    this.props.actions.initEventForm();
  }

  onEventInactive() {
    const {actions} = this.props;

    actions.showTopFloatingAlert(INVALID_EVENT, true, 'orange');
  }
  onSave = () => {
    const {actions, routes, router} = this.props;

    actions.apiSubmitForm(routes, 'add', router);
  };

  renderContent = () => {
    const {apiSaving, inert} = this.props;
    return (
      <EventForm
        inert={inert}
        action='add'
        disabled={apiSaving > 0}
        hideFormAction
      />
    );
  };

  public render() {
    const { adminEventsCacheOne, eventId, apiSaving, actions, inert} = this.props;

    const title = !!eventId ? 'Edit Event' : 'New Event';
    const inactive = !!eventId;
    const subtitle = adminEventsCacheOne.EventsEventType ? adminEventsCacheOne.EventsEventType.EventTypeRow.Name : '';

    return (
      <Modal
        inert={inert}
        wideModal
        inactive={inactive}
        onInactive={() => this.onEventInactive()}
        big mobileFullScreen
        className={namespace()}
      >
        <ModalHeader className={`${namespace()}--header`} subtitle={subtitle}>{title}</ModalHeader>
        <ModalContent hasPadding>
          {this.renderContent()}
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button textColor='black' disabled={apiSaving > 0} flat onClick={this.onSave}>{this.props.eventId ? 'SAVE' : 'CREATE'}</Button>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const eventIdSelector = makeFormModalPropSelector(ModalTypes.EVENT_FORM, 'id');
  
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    eventId: eventIdSelector(state),
    eventForm: state.adminEvents.events.event.form,
    adminEventsCacheOne: state.adminEvents.cacheOne,
  };
};

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...appActionCreators,
  ...actionCreators,
}, dispatch) });

const ConnectedEventFormModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(EventFormModal);

export default ConnectedEventFormModal;