import * as React from 'react';
import { Added, Tabs, Tab } from '../../../../../../Elements';

type TabsComponentProps = {
  selected: 'roster' | 'new_youth' | 'new_adult';
  onShowSideBar?: () => void;
  onShowAdded?: () => void;
  onHideAdded?: () => void;
  controlsSelected?: boolean;
  mobile?: boolean;
  ShowYouth: boolean;
  ShowAdults: boolean;
  onChange: (selectTab: 'roster' | 'new_youth' | 'new_adult') => void
};

const TabsComponent: React.SFC<TabsComponentProps> = (
  { ShowYouth, ShowAdults, mobile, controlsSelected, onShowSideBar, onShowAdded, onHideAdded, selected, onChange }: TabsComponentProps
): React.ReactElement<TabsComponentProps> => {
  return (
    <Tabs>
      <Tab
        onClick={() => {
          if (controlsSelected && onHideAdded) onHideAdded();
          onChange('roster');
        }}
        selected={selected === 'roster' && !controlsSelected}
      >
        MY GROUP
      </Tab>
      {ShowYouth && <Tab
        onClick={() => {
          if (controlsSelected && onHideAdded) onHideAdded();
          onChange('new_youth');
        }}
        selected={selected === 'new_youth' && !controlsSelected}
      >
        NEW YOUTH
      </Tab>}
      {ShowAdults && <Tab
        onClick={() => {
          if (controlsSelected && onHideAdded) onHideAdded();
          onChange('new_adult');
        }}
        selected={selected === 'new_adult' && !controlsSelected}
      >
        NEW ADULT
      </Tab>}
    </Tabs>
  );
};

export default TabsComponent;
