import { ActionCreator, ApplicationState } from "../../../..";
import { createValidateActions, createApiValidateActions, createUpdateValueMethod } from "../../../../Validation/actionCreator";
import { AdminEventClass } from "../../../../../models/api/adminEventsCacheTwoEvent";
import { typeName, Action } from "../../../../../utils/StrongActions";
import { SaveState } from "../../../../Rollback/actions";

export const SUFFIX = '__EXPORT_INSTRUCTOR_ROSTER';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

@typeName("INIT" + SUFFIX)
export class InitExportInstructorRosterModal extends Action { constructor(public selectedClass?: AdminEventClass) { super(); } }

@typeName("RESET" + SUFFIX) export class ResetExportInstructorRosterModal extends Action {}

@typeName("RESET_STEP_TWO" + SUFFIX) export class ResetStepTwo extends Action {}

@typeName("FILTER_CLASS" + SUFFIX) export class FilterClass extends Action {
  constructor(public filterText: string) { super(); }
}
@typeName("TOGGLE_FILTER" + SUFFIX)
export class ExportInstructorRosterModalToggleFilter extends Action {
  constructor(public searching: boolean) { super(); }
}

export type Actions = typeof actionCreators;

export const actionCreators = {
  init: (selectedClassId?: number, childClassId?: number): ActionCreator  => (dispatch, getState) => {
    const state = getState() as ApplicationState;

    let selectedClass = state.adminEvents.cacheTwoEvent.EventsEventClasses && state.adminEvents.cacheTwoEvent.EventsEventClasses ? state.adminEvents.cacheTwoEvent.EventsEventClasses .find((o) => o.IDi === selectedClassId) : undefined;

    if (selectedClass) {
      selectedClass = {...selectedClass};
      const allClassTypes = state.cacheZero.options && state.cacheZero.options.ClassTypes ? state.cacheZero.options.ClassTypes : [];

      if (selectedClass.IsCombo && childClassId && selectedClass.ComboClasses) {
        const childClass = selectedClass.ComboClasses.find((child) => child.IDi === childClassId);

        if (childClass) {
          selectedClass.ClassTypeIDi = childClass.ClassTypeIDi;
          selectedClass.IDi = childClass.IDi;
        }
        selectedClass.IsCombo = false;
      }
    }
    dispatch(new InitExportInstructorRosterModal(selectedClass));
    dispatch(new SaveState());
  },
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.events.modals.exportInstructorRoster),
  resetExportInstructorRosterModal: (): ActionCreator => dispatch => dispatch(new ResetExportInstructorRosterModal()),
  filterClass: (filterText: string): ActionCreator => dispatch => dispatch(new FilterClass(filterText)),
  toggleFilter: (searching: boolean): ActionCreator => dispatch => dispatch(new ExportInstructorRosterModalToggleFilter(searching)),
  resetStep2: (): ActionCreator => dispatch => dispatch(new ResetStepTwo()),
};