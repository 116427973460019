
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';

import {
  Modal, ModalHeader, ModalContent, PageLoader, EmptyMessage, Button, Select, ModalActions,
} from '../../../../../Elements';
import { actionCreators } from "../../../../../../store/AdminFacilityLocation/Facilities/Modals/ImportFromFacility/actions";
import '../../../../../../styles/pages/admin-facility-location/facilities/modals/import-from-facility/index.scss';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as facilityFormActionCreators } from '../../../../../../store/AdminFacilityLocation/Facilities/Facility/Form/actions';
import { actionCreators as cacheOneActionCreators, GetFacilityLocationCache } from '../../../../../../store/AdminFacilityLocation/CacheOne/actions';
import { ApplicationState } from '../../../../../../store';
import { makeSelectedFacilitySelector } from '../../../../../../store/AdminFacilityLocation/Facilities/Modals/ImportFromFacility';
import { makeFilteredFacilitiesSelector } from '../../../../../../store/AdminFacilityLocation/Facilities/Modals/ImportFromFacility';
import { AdminFacility } from '../../../../../../models/api/adminFacilitiesCacheOne';
import AdminFacilityCard, { AdminFacilityCardType } from '../../../../../../components/Elements/AdminFacility/Card';
import { CardCategory } from '../../../../../../components/Elements/Card';
import { EmptyMessageType } from '../../../../../../components/Elements/EmptyMessage';
import { Facilities2Icon } from '../../../../../../components/Icons';
import { ENTITY_NOT_FOUND } from '../../../../../../constants/messages/adminCMS';
import { FACILITIES } from '../../../../../../constants/messages/adminFacilityLocation';
import { extractRouteParams } from '../../../../../../utils/urlHelper';
import { makeFormModalPropSelector } from './../../../../../../store/App';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { Validator } from '../../../../../../utils/validator/models';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--facility-location--modals--import-from-facility';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;
type Props = WithInertAttribute<{}>;

export enum ImportType {
  BOOKING_TIMES,
  PRICING,
  PHOTOS,
}

@(withRouter as any)
class ImportFromFacilityModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;
  private listRef;

  componentDidMount() {
    if (shouldBlockActions()) return;

    this.props.actions.init();
  }

  componentWillUnmount() {
    this.onClose();
  }

  onFacilityClick = (facility: AdminFacility) => {
    const { actions, modalState: {ValidationRules} } = this.props;
    actions.updateValue(facility.ID, ValidationRules.SelectedFacility);
  };

  onComplete = () => {
    if (this.props.type !== undefined) {
      this.props.actions.popModal(true, false, ModalTypes.IMPORT_FROM_FACILITY);
      this.props.facilityFormActions.importFromFacility(this.props.type);
    }
  };
  onBack = () => {
    const { actions, modalState: {ValidationRules} } = this.props;
    actions.updateValue(undefined, ValidationRules.SelectedFacility);
  };

  scrollToTop = () => {
    if (this.listRef) this.listRef.scrollTop = 0;
  };

  renderEmptyMessage = () => {
    return (
      <EmptyMessage
        type={EmptyMessageType.PAGE_MARGIN}
        admin
        icon={Facilities2Icon}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={ENTITY_NOT_FOUND(FACILITIES)}
      />
    );
  };

  onFacilityTypeChange = (val: any, vObj: Validator) => {
    this.scrollToTop();
    this.props.actions.updateValue(val, vObj);
  };

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    this.props.actions.toggleFilter(false);
    this.props.actions.filterFacility('');
  };
  onRefreshClick = () => {
    const {actions} = this.props;

    actions.getFacilityLocationCache({
      MaxFacilityID: 0,
      GetFacilities: true,
      ...extractRouteParams(this.props),
    }, false, undefined, true);
  };

  onClose = () => {
    this.props.actions.reset();
  };

  public render() {
    const { actions, apiLoadingMap, type, modalState: {ActiveForm, ValidationRules, isTextSearching}, cacheOne, filteredFacilities, selectedFacility, apiSaving, facilityFormState, inert } = this.props;
    const loading = apiLoadingMap[GetFacilityLocationCache.requestType];
    const subtitle = facilityFormState.Detail.ActiveForm.Name ? facilityFormState.Detail.ActiveForm.Name : 'Edit Facility';
    const typeText = `${type === ImportType.BOOKING_TIMES ? 'Booking Times' : (type === ImportType.PRICING ? 'Pricing' : 'Photos')}`;
    const title = `Import ${typeText}`;
    const stepPromptText1 = !!ActiveForm.SelectedFacility ? 'Step 2 of 2:' : 'Step 1 of 2:';
    const stepPromptText2 = !!ActiveForm.SelectedFacility ? 'Confirm' : 'Select Facility';

    return (
      <Modal inert={inert} onClose={this.onClose} big mobileFullScreen className={namespace()}>
        <ModalHeader
          subtitle={subtitle}
          onBack={ActiveForm.SelectedFacility ? this.onBack : undefined}
          isSearching={!ActiveForm.SelectedFacility ? isTextSearching : false}
          className={`${namespace()}--header`}
          onRefresh={!ActiveForm.SelectedFacility ? this.onRefreshClick : undefined}
          onSearchOpen={!ActiveForm.SelectedFacility ? this.onSearchOpen : undefined}
          onSearchChange={!ActiveForm.SelectedFacility ? actions.filterFacility : undefined}
          onSearchBack={!ActiveForm.SelectedFacility ? this.onSearchBack : undefined}
        >
          {title}
        </ModalHeader>
        <ModalContent
          neverLoading
          paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0}
        >
          <div className={`${namespace()}--step-prompt`}>
            <span>{stepPromptText1}</span>
            <span>{stepPromptText2}</span>
          </div>
          {!ActiveForm.SelectedFacility ? <Select
            hideOptional
            disabled={false}
            onChangeValue={this.onFacilityTypeChange}
            value={ActiveForm.SelectedFacilityType}
            validationRules={ValidationRules.SelectedFacilityType}
            isNumber /> : null}
          {!ActiveForm.SelectedFacility && !loading ? <div ref={(ref) => this.listRef = ref} className={`${namespace()}--facilities-list`}>
            {filteredFacilities && filteredFacilities.map((f) => {
              return (
                <AdminFacilityCard
                  hideOptions
                  facility={f}
                  type={AdminFacilityCardType.LIST_WITHOUT_IMAGE}
                  category={CardCategory.LIST_MOBILE}
                  adminFacilityLocationCacheOne={cacheOne}
                  onClick={this.onFacilityClick}
                />
              );
            })}
            {!filteredFacilities || filteredFacilities.length === 0 ? this.renderEmptyMessage() : null}
          </div> : null}
          {!ActiveForm.SelectedFacility && loading && <PageLoader className={`${namespace()}--page--loader`} />}
          {ActiveForm.SelectedFacility && selectedFacility ? <div className={`${namespace()}--step2--content`}>
            <div className={`${namespace()}--helper-text`}>
              {`The ${type === ImportType.PHOTOS ? 'images' : typeText.toLowerCase()} will be imported from the selected facility:`}
            </div>
            <AdminFacilityCard
              hideOptions
              facility={selectedFacility}
              type={AdminFacilityCardType.SHOW_FACILITY_TYPE}
              category={CardCategory.LIST}
              adminFacilityLocationCacheOne={cacheOne}
            />
          </div> : null}
        </ModalContent>
        {ActiveForm.SelectedFacility ? <ModalActions
          sticky
          notFixed
          left={<Button textColor='black' disabled={apiSaving > 0} flat onClick={this.onComplete}>COMPLETE</Button>}
        /> : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredFacilitiesSelector = makeFilteredFacilitiesSelector();
  const selectedFacilitySelector = makeSelectedFacilitySelector();
  const importFromFacilityTypeSelector = makeFormModalPropSelector(ModalTypes.IMPORT_FROM_FACILITY, 'importFromFacilityType');

  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    modalState: state.adminFacilityLocation.facilities.modals.importFromFacility,
    facilityFormState: state.adminFacilityLocation.facilities.facility.form,
    filteredFacilities: filteredFacilitiesSelector(state),
    selectedFacility: selectedFacilitySelector(state),
    type: importFromFacilityTypeSelector(state),
    cacheOne: state.adminFacilityLocation.cacheOne,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...cacheOneActionCreators,
  }, dispatch),
  facilityFormActions: bindActionCreators({
    ...facilityFormActionCreators,
  }, dispatch),
});

const ConnectedImportFromFacilityModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ImportFromFacilityModal);

export default ConnectedImportFromFacilityModal;