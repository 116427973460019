import * as React from 'react';
import isNode from './isNode';
import {getFullAddress} from './';

// let isGoogleMapsLoaded: boolean = false;
//
// function loadScript(url: string, callback: () => void) {
//   if (!isNode()) {
//     const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
//     const script: HTMLScriptElement = document.createElement('script');
//     script.type = 'text/javascript';
//     script.src = url;
//     script.onload = callback;
//     head.appendChild(script);
//   }
// }
//
// interface State {
//   isReady: boolean,
//   location?: any
// }
//
// interface Props {
//   address: string;
// }
//
// export type GoogleMapsOptions = {
//   key?: string;
//   libraries?: string;
// };

// maybe switch to this URL? it is the one I found in docs, almost the same
// https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAdTBg_nmAQuvg_UKCbo5uYpzhFT_fGvC4"

export const GoogleMapsDefaultOptions = {
  key: 'AIzaSyAdTBg_nmAQuvg_UKCbo5uYpzhFT_fGvC4',
  libraries: 'geometry,drawing,places'
};