import * as React from 'react';
import {default as AppHeader} from '../../../../App/Header';
import {URLS} from "../../../../../constants/urls";
import {makeSelectedLocationSelector} from "../../../../../store/CacheTwoFacilities";
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import {spaceTo_} from "../../../../../utils";
import { ApplicationState } from '../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & RouteComponentProps<{}, {}>;

const Header = (props: ConnectedProps): React.ReactElement<undefined> => {
  // HOME should never occur anymore
  return <AppHeader
    showDrawer
    back={props.selectedLocation ? `${URLS.FACILITIES}/${props.selectedLocation.ID}/${spaceTo_(props.selectedLocation.Name)}` : URLS.HOME}
    title="Trip Summary"
    cart
    mobileCart
    refresh
  />;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedLocation = makeSelectedLocationSelector();
  return {
    selectedLocation: selectedLocation(state)
  };
};

const ConnectedHeader = connect(
  mapStateToProps,
  null,
  getMergeProps<WithRouterProps>(),
)(Header);

export default withRouter<{}>(ConnectedHeader);
