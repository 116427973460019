import * as React from 'react';
import { Form, Row, Column, FormActions, TextField, Alert, ActionButton, FieldSet, ModalSelect, Switch, DatePicker, FormSection, Link, MoneyField, Media, Ellipsis } from '../../../../Elements';
import { Button } from '../../../../Elements';
import { Main, MainContent } from '../../../../Layouts';
import '../../../../../styles/pages/admin-events/events/form/index.scss';
import * as M from '../../../../../constants/messages/generic';
import { CopyIcon, LinkIcon } from '../../../../Icons';
import Card, { CardCategory } from '../../../../Elements/Card';
import { makeAdminEventCapacityParticipantTypesSelector, makeAdminEventSelectedGLAccountSelector } from '../../../../../store/AdminEvents/Events/Event/Form';
import TimePicker from '../../../../Elements/TimePicker';
import PageSection from '../../../CMS/Websites/Pages/Page/Section';
import { copyStringToClipboard, spaceTo_ } from '../../../../../utils';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as eventTypeFormActionCreators, getAdminEventCapacityNameKey, getPricingAmountKey } from '../../../../../store/AdminEvents/Events/Event/Form/actions';
import { ApplicationState } from '../../../../../store';
import { makeSelectedRegistrationMethodSelector, makeSelectedPaymentRequirementsToAddParticipantsOptionSelector, makeSelectedMessageSelector, makeSelectedLocationSelector, makeSelectedEventCategory, makeDefaultGroupTypeSelector, makeDefaultUnitTypeSelector, makeSelectedUpfrontPaymentSelector, makeSelectedMultipleEventDiscountSelector, makePricingTiersSelector, makeSelectedLockInPricingBasedOnOptions, makeParticipantTypesSelector, makeSelectedDistrictSelector } from '../../../../../store/AdminEvents/EventTypes/EventType/Form';
import { bindActionCreators } from 'redux';
import LocationCard, { LocationCardType } from '../../../../Elements/CMSLocation/Card';
import AdminMessageCard, { AdminMessageCardType } from '../../../../Elements/AdminMessage/Card';
import { EventTypeFormSection } from '../../../../../store/AdminEvents/EventTypes/EventType/Form/actions';
import ValidIcon from '../../../../Elements/TextField/ValidIcon';
import InvalidIcon from '../../../../Elements/TextField/InvalidIcon';
import { GLAccount } from '../../../../../models/api/options';
import GLAccountCard, { GLAccountCardType } from '../../../../Elements/GLAccount/Card';
import { ImportFromEventType } from '../Modals/ImportFromEvent';
import { makeFirstLetterUppercase } from '../../../../../utils/stringHelper';
import { getDomain } from '../../../../../utils/urlHelper';
import { isJustNames, isJustNumbers, isUsingNames, isUsingNumbers } from '../../../../../store/AdminEvents/EventTypes/EventType/Form/uiHelpers';
import { checkEventTypePermission } from '../../../../../utils/helpers/adminEventsPageHelper';
import { checkGLAccountPermission } from '../../../../../utils/helpers/adminSettingsPageHelper';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { generateDOMId } from '../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--event--form';

export type Props = WithInertAttribute<{
  action: 'add' | 'edit';
  disabled: boolean;
  loading?: boolean;
  onSave?: () => void;
  onDelete?: () => void;
  hideFormAction?: boolean;
  handleCompact?: boolean;
}>;


type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class EventForm extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  isFormDisabled = () => {
    const {adminEventsCacheOne} = this.props;

    return !!adminEventsCacheOne.EventsEventType && !adminEventsCacheOne.EventsEventType.Permissions.hasEditEvent;
  };

  openSharedSettingsModal = (section: EventTypeFormSection) => {
    const {actions, cacheZero, adminEventsCacheOne} = this.props;

    checkEventTypePermission(
      () => {
        actions.pushFormModal(ModalTypes.EVENT_TYPE_SHARED_SETTINGS, false, {eventTypeFormSection: section});
      },
      cacheZero,
      adminEventsCacheOne,
      undefined,
      true,
    );
  };

  renderEventDatesFields = () => {
    const {disabled, actions, eventForm: {ValidationRules, ActiveForm}} = this.props;
    return (
      <FieldSet fixedLegendFontSize marginBottom={24} newDesign legendMarginBottom={16} fontSize={18} name="Event Dates">
        <Row marginBottom={16}>
          <Column span={12}>
            <Switch
              margin={false}
              newDesign
              disabled={disabled || this.isFormDisabled()}
              label="All-Day Event"
              value={!!ActiveForm.IsAllDay}
              validationRules={ValidationRules.IsAllDay}
              onChange={actions.updateValue}
              multilineLabel
            />
          </Column>
        </Row>
        <Row>
          <Column span={6} mobileSpan={12}>
            <DatePicker
              label="Start Date"
              disabled={disabled || this.isFormDisabled()}
              value={ActiveForm.StartDate}
              validationRules={ValidationRules.StartDate}
              onSelect={actions.updateValue}
              onChangeRaw={actions.simpleUpdate}
              popperPlacement='bottom-start'
              popperModifiers={{
                flip: {
                    behavior: ["bottom"] // don't allow it to flip to be above
                },
                preventOverflow: {
                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                    enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
              removeMax
            />
          </Column>
          {!ActiveForm.IsAllDay && <Column mobileMarginTop={8} span={6} mobileSpan={12}>
            <TimePicker
              label="Time"
              disabled={disabled || this.isFormDisabled()}
              customizedId='startDateTime'
              onChangeValue={actions.updateValue}
              hourValue={ActiveForm.StartHour}
              minValue={ActiveForm.StartMin}
              periodValue={ActiveForm.StartPeriod}
              hourValidationRules={ValidationRules.StartHour}
              minValidationRules={ValidationRules.StartMin}
              periodValidationRules={ValidationRules.StartPeriod}
            />
          </Column>}
        </Row>
        <Row marginTop={8} mobileMarginTop={8}>
          <Column span={6} mobileSpan={12}>
            <DatePicker
              label="End Date"
              disabled={disabled || this.isFormDisabled()}
              value={ActiveForm.EndDate}
              validationRules={ValidationRules.EndDate}
              onSelect={actions.updateValue}
              onChangeRaw={actions.simpleUpdate}
              popperPlacement='bottom-start'
              popperModifiers={{
                flip: {
                    behavior: ["bottom"] // don't allow it to flip to be above
                },
                preventOverflow: {
                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                    enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
              removeMax
            />
          </Column>
          {!ActiveForm.IsAllDay && <Column mobileMarginTop={8} span={6} mobileSpan={12}>
            <TimePicker
              label="Time"
              disabled={disabled || this.isFormDisabled()}
              customizedId='endDateTime'
              onChangeValue={actions.updateValue}
              hourValue={ActiveForm.EndHour}
              minValue={ActiveForm.EndMin}
              periodValue={ActiveForm.EndPeriod}
              hourValidationRules={ValidationRules.EndHour}
              minValidationRules={ValidationRules.EndMin}
              periodValidationRules={ValidationRules.EndPeriod}
            />
          </Column>}
        </Row>
      </FieldSet>
    );
  };

  renderRegistrationDatesFields = () => {
    const {disabled, actions, eventForm: {ValidationRules, ActiveForm}} = this.props;
    return (
      <FieldSet fixedLegendFontSize marginTop={24} marginBottom={0} newDesign legendMarginBottom={16} fontSize={18} name="Registration Dates">
        {isJustNames() ? null : <Row styles={{position: 'relative'}}>
          <Column span={6} mobileSpan={12}>
            <DatePicker
              label={`${isJustNumbers() ? 'Registration Start Date' : 'Numbers Registration Start Date'}`}
              disabled={disabled || this.isFormDisabled()}
              value={ActiveForm.RegistrationStartDateNumbers}
              validationRules={ValidationRules.RegistrationStartDateNumbers}
              onSelect={actions.updateValue}
              onChangeRaw={actions.simpleUpdate}
              helperText={<Media tabletAndGreater>The date when end users can register for this event (if enabled).</Media>}
              helperTextOverflow={true}
              popperPlacement='bottom-start'
              popperModifiers={{
                flip: {
                    behavior: ["bottom"] // don't allow it to flip to be above
                },
                preventOverflow: {
                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                    enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
              removeMax
            />
          </Column>
          <Column mobileMarginTop={8} span={6} mobileSpan={12}>
            <TimePicker
              label="Time"
              disabled={disabled || this.isFormDisabled()}
              customizedId='numbersRegistrationDateTime'
              onChangeValue={actions.updateValue}
              hourValue={ActiveForm.NumbersRegistrationStartHour}
              minValue={ActiveForm.NumbersRegistrationStartMin}
              periodValue={ActiveForm.NumbersRegistrationStartPeriod}
              hourValidationRules={ValidationRules.NumbersRegistrationStartHour}
              minValidationRules={ValidationRules.NumbersRegistrationStartMin}
              periodValidationRules={ValidationRules.NumbersRegistrationStartPeriod}
              helperText={<Media mobileAndSmaller>The date when end users can register for this event (if enabled).</Media>}
            />
          </Column>
        </Row>}
        {isJustNumbers() ? null : <Row marginTop={16} mobileMarginTop={16} styles={{position: 'relative'}}>
          <Column span={6} mobileSpan={12}>
            <DatePicker
              label={`${isJustNames() ? 'Registration Start Date' : 'Names Registration Start Date'}`}
              disabled={disabled || this.isFormDisabled()}
              value={ActiveForm.RegistrationStartDateNames}
              validationRules={ValidationRules.RegistrationStartDateNames}
              onSelect={actions.updateValue}
              onChangeRaw={actions.simpleUpdate}
              helperText={<Media tabletAndGreater>{isJustNames() ? 'The date when end users can register for this event.' : 'The date when individuals can be added to the event registration. Class scheduling will also open on this date.'}</Media>}
              helperTextOverflow={true}
              popperPlacement='bottom-start'
              popperModifiers={{
                flip: {
                    behavior: ["bottom"] // don't allow it to flip to be above
                },
                preventOverflow: {
                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                    enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
              removeMax
            />
          </Column>
          <Column mobileMarginTop={8} span={6} mobileSpan={12}>
            <TimePicker
              label="Time"
              className={`${namespace()}--registration-time-picker`}
              disabled={disabled || this.isFormDisabled()}
              customizedId='namesRegistrationDateTime'
              onChangeValue={actions.updateValue}
              hourValue={ActiveForm.NamesRegistrationStartHour}
              minValue={ActiveForm.NamesRegistrationStartMin}
              periodValue={ActiveForm.NamesRegistrationStartPeriod}
              hourValidationRules={ValidationRules.NamesRegistrationStartHour}
              minValidationRules={ValidationRules.NamesRegistrationStartMin}
              periodValidationRules={ValidationRules.NamesRegistrationStartPeriod}
              helperText={<Media mobileAndSmaller>{isJustNames() ? 'The date when end users can register for this event.' : 'The date when individuals can be added to the event registration. Class scheduling will also open on this date.'}</Media>}
            />
          </Column>
        </Row>}
      </FieldSet>
    );
  };

  onCopyDeepLink = (deepLink: string) => {
    copyStringToClipboard(deepLink);
    this.props.actions.showSnackbarItem(M.LINK_COPIED);
  };

  renderGeneralSection = () => {
    const {disabled, action, actions, adminEventsCacheOne, adminEventsCacheTwoEvent, selectedMessage, selectedEventCategory, selectedDistrict, eventForm: {ValidationRules, ActiveForm}, selectedRegistrationMethod, selectedLocation} = this.props;
    const domain = getDomain(false);
    const eventTypeID = adminEventsCacheOne && adminEventsCacheOne.EventsEventType ? adminEventsCacheOne.EventsEventType.EventTypeRow.ID : -1;
    const eventID = adminEventsCacheTwoEvent && adminEventsCacheTwoEvent.EventsEventRow ? adminEventsCacheTwoEvent.EventsEventRow.IDi : -1;
    const eventName = adminEventsCacheTwoEvent && adminEventsCacheTwoEvent.EventsEventRow ? adminEventsCacheTwoEvent.EventsEventRow.EventName : '';
    const deepLink = `${domain}/admin2/events/${eventTypeID}/${eventID}/${spaceTo_(eventName)}`;

    const adminEventType = adminEventsCacheOne ? adminEventsCacheOne.EventsEventType : null;

    if (!adminEventType || !selectedEventCategory) return null;

    return (
      <PageSection
        expand={true}
        noPaddingBottomMobile
      >
        <Row>
          <Column span={12}>
            <TextField
              label="Name"
              id={generateDOMId("admin-event-name-field")}
              disabled={disabled || this.isFormDisabled()}
              onChange={actions.simpleUpdate}
              onBlur={actions.updateValue}
              validationRules={ValidationRules.Name}
              value={ActiveForm.Name}
            />
          </Column>
        </Row>
        <Row marginTop={8}>
          <Column span={12} style={{flexDirection: 'column'}}>
            <div className={`${namespace()}--label deep-link`}>Deep Link</div>
            <div className={`${namespace()}--deep-link-content`}>
              <div>{deepLink}</div>
              <ActionButton icon={CopyIcon} onClick={() => this.onCopyDeepLink(deepLink)} />
            </div>
          </Column>
        </Row>
        <Row marginTop={16}>
          <FormSection
            disabled={this.isFormDisabled()}
            title={`Shared Settings - ${adminEventType.General.Name}`}
            onEditClick={() => this.openSharedSettingsModal(EventTypeFormSection.GENERAL)}
          >
            <Row>
              <Column span={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label card`}>Registration Method</div>
                <div className={`${namespace()}--selected-method-card`}>
                  <div className={`${namespace()}--selected-method-card--name`}>{selectedRegistrationMethod ? selectedRegistrationMethod.Name : ''}</div>
                  <div className={`${namespace()}--selected-method-card--desc`}>{selectedRegistrationMethod ? selectedRegistrationMethod.Description : ''}</div>
                </div>
              </Column>
            </Row>
            <Row marginTop={12} mobileMarginTop={6}>
              <Column span={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Event Type</div>
                <div className={`${namespace()}--card--field-value`}>{adminEventType.General.Name}</div>
              </Column>
            </Row>
            {adminEventType.General.Description && <Row marginTop={16}>
              <Column span={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Description</div>
                <div className={`${namespace()}--card--field-value`}>
                  <Ellipsis
                    configs={{
                      lines: 3,
                      text: adminEventType.General.Description,
                    }}
                  />
                </div>
              </Column>
            </Row>}
            {(adminEventType.General.LeadersGuideURL || adminEventType.General.WebsiteURL) && <Row marginTop={16}>
              {adminEventType.General.LeadersGuideURL && <Column span={6} mobileSpan={12} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Leader's Guide</div>
                  <div className={`${namespace()}--card--field-value`}>
                    <Link className={`${namespace()}--card--field-open-link`} externalLink={adminEventType.General.LeadersGuideURL} size={14} icon={LinkIcon}>OPEN LINK</Link>
                  </div>
              </Column>}
              {adminEventType.General.WebsiteURL && <Column mobileMarginTop={16} span={6} mobileSpan={12} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Website</div>
                  <div className={`${namespace()}--card--field-value`}>
                    <Link className={`${namespace()}--card--field-open-link`} externalLink={adminEventType.General.WebsiteURL} size={14} icon={LinkIcon}>OPEN LINK</Link>
                  </div>
              </Column>}
            </Row>}
            <Row marginTop={16}>
              <Column span={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label card`}>Location</div>
                {selectedLocation && <LocationCard
                  showDeletedPrompt
                  marginTop={0}
                  location={selectedLocation}
                  cardCategory={CardCategory.LIST}
                  type={LocationCardType.MODAL_SELECT}
                />}
              </Column>
            </Row>
            <Row marginTop={24} mobileMarginTop={16}>
              <Column span={6} mobileSpan={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Event Category</div>
                <div className={`${namespace()}--card--field-value`}>{selectedEventCategory.Name}</div>
              </Column>
              {selectedEventCategory.IsDistricts && selectedDistrict && <Column className={`${namespace()}--card--col`} span={6} mobileSpan={12} mobileMarginTop={16}>
                <div className={`${namespace()}--card--field-label`}>District</div>
                <div className={`${namespace()}--card--field-value`}>{selectedDistrict.Name}</div>
              </Column>}
            </Row>
            {adminEventType.General.NewRegistrationMessageID && <Row marginTop={16}>
              <Column span={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label card`}>New Registration Confirmation Message</div>
                {selectedMessage && <AdminMessageCard
                  type={AdminMessageCardType.EVENT_FORM}
                  category={CardCategory.LIST}
                  message={selectedMessage}
                />}
              </Column>
            </Row>}
            <Row marginTop={!!adminEventType.General.NewRegistrationMessageID ? 24 : 16} mobileMarginTop={16}>
              <Column span={6} mobileSpan={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Primary Contact</div>
                <div className={`${namespace()}--card--field-value`}>{adminEventType.General.PrimaryContactName}</div>
              </Column>
            </Row>
            {(adminEventType.General.AdditionalNotify1_Name || adminEventType.General.AdditionalNotify2_Name) && <Row marginTop={16}>
              {adminEventType.General.AdditionalNotify1_Name && <Column span={6} mobileSpan={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Additional Notify Contact</div>
                <div className={`${namespace()}--card--field-value`}>{adminEventType.General.AdditionalNotify1_Name}</div>
              </Column>}
              {adminEventType.General.AdditionalNotify2_Name && <Column mobileMarginTop={16} span={6} mobileSpan={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Additional Notify Contact</div>
                <div className={`${namespace()}--card--field-value`}>{adminEventType.General.AdditionalNotify2_Name}</div>
              </Column>}
            </Row>}
          </FormSection>
        </Row>
      </PageSection>

    );
  };

  renderDatesSection = () => {
    return (
      <PageSection
        expand={true}
        title='Dates'
      >
        {this.renderEventDatesFields()}
        {this.renderRegistrationDatesFields()}
      </PageSection>
    );
  };

  onEditGLAccount = (account: GLAccount) => {
    const {actions, cacheZero} = this.props;

    checkGLAccountPermission(
      () => {
        actions.pushFormModal(ModalTypes.GL_ACCOUNT_FORM, true, {id: account.ID});
      },
      cacheZero,
      true,
    );
  };
  onRemoveGLAccount = () => {
    const {eventForm} = this.props;
    this.props.actions.updateValue(undefined, eventForm.ValidationRules.GLAccountID);
  };
  onClickSelectGLAccountButton = () => {
    this.props.actions.pushSelectGLAccountModal({glAccountContext: 'event-form'});
  };
  onUpdateYouthDepositDueDate = (v, vObj) => {
    const {actions, eventForm: {ValidationRules, ActiveForm}} = this.props;
    actions.updateValue(v, vObj);

    if (!v) {
      actions.updateValue(0, ValidationRules.YouthDepositAmount);
    }
  };
  onImportPricing = () => {
    this.props.actions.pushImportFromEventModal({importFromEventType: ImportFromEventType.PRICING});
  };
  onCopyFromPricintTierClick = (id: number) => {
    this.props.actions.copyPricingTier(id);
  };
  renderPricingSection = () => {
    const {disabled, action, actions, adminEventsCacheOne, adminEventsCacheTwoEvent, eventForm: {ValidationRules, ActiveForm}, pricingTiers, selectedGLAccount, selectedMultipleEventDiscount, selectedUpfrontPayment, lockInPricingBasedOnOption, participantTypes, systemSettings} = this.props;

    const adminEventType = adminEventsCacheOne ? adminEventsCacheOne.EventsEventType : null;

    if (!adminEventType || !lockInPricingBasedOnOption || !selectedUpfrontPayment || !selectedMultipleEventDiscount) return null;
    return (
      <PageSection
        expand={true}
        title='Pricing'
        noPaddingBottomMobile
        headerControl={<Button disabled={this.isFormDisabled()} textColor='black' color='white' onClick={this.onImportPricing}>IMPORT</Button>}
      >
        <Row>
          <Column span={12}>
            <ModalSelect
              label='GL Account – Registration Fees'
              selectButtonLabel="SELECT GL ACCOUNT"
              disabled={this.isFormDisabled()}
              paddingBottom={0}
              mobilePaddingBottom={0}
              hideError={!!selectedGLAccount}
              selectedCard={selectedGLAccount ?
                <GLAccountCard
                  type={GLAccountCardType.MODAL_SELECT}
                  showDeletedPrompt
                  marginTop={12}
                  disabled={this.isFormDisabled()}
                  onEdit={this.onEditGLAccount}
                  onRemove={this.onRemoveGLAccount}
                  account={selectedGLAccount}
                /> : null}
              onClickSelectButton={this.onClickSelectGLAccountButton}
              validationRules={ValidationRules.GLAccountID}
              value={ActiveForm.GLAccountID}
            />
          </Column>
        </Row>
        {isUsingNumbers() && <Row marginTop={!!selectedGLAccount ? 8 : 24}>
          <FormSection
            disabled={this.isFormDisabled()}
            title={`Shared Settings - ${adminEventType.General.Name}`}
            onEditClick={() => this.openSharedSettingsModal(EventTypeFormSection.PRICING1)}
          >
            <Row>
              <Column span={6} mobileSpan={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Allow Self Registration</div>
                <div className={`${namespace()}--card--field-value--row`}>
                  <div className={`${namespace()}--card--field-value--icon`}>
                    {adminEventType.Pricing1.AllowSelfRegistration ? <ValidIcon /> : <InvalidIcon grey />}
                  </div>
                  <div className={`${namespace()}--card--field-value ${!adminEventType.Pricing1.AllowSelfRegistration ? 'invalid' : ''}`}>{`${adminEventType.Pricing1.AllowSelfRegistration ? 'Allowed' : 'Not Allowed'}`}</div>
                </div>
              </Column>
              <Column span={6} mobileSpan={12} mobileMarginTop={16} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Allow Increases To Event Registration</div>
                <div className={`${namespace()}--card--field-value--row`}>
                  <div className={`${namespace()}--card--field-value--icon`}>
                    {adminEventType.Pricing1.AllowSelfRegistration || adminEventType.Pricing1.AllowIncreasesToRegistration ? <ValidIcon /> : <InvalidIcon grey />}
                  </div>
                  <div className={`${namespace()}--card--field-value ${(!adminEventType.Pricing1.AllowSelfRegistration && !adminEventType.Pricing1.AllowIncreasesToRegistration) ? 'invalid' : ''}`}>{`${adminEventType.Pricing1.AllowSelfRegistration || adminEventType.Pricing1.AllowIncreasesToRegistration ? 'Allowed' : 'Not Allowed'}`}</div>
                </div>
              </Column>
            </Row>
            {adminEventType.Pricing1.MinNumberOfParticipants > 0 && adminEventType.Pricing1.AllowSelfRegistration &&  <Row marginTop={16}>
              <Column span={6} mobileSpan={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Minimum Number of Participants</div>
                <div className={`${namespace()}--card--field-value`}>{adminEventType.Pricing1.MinNumberOfParticipants}</div>
              </Column>
            </Row>}
          </FormSection>
        </Row>}
        <Row styles={{position: 'relative'}} marginTop={isUsingNumbers() ? 24 : 8}>
          <Column span={6} mobileSpan={12}>
            <DatePicker
              label="Allow Reductions Until Date"
              disabled={disabled || this.isFormDisabled()}
              value={ActiveForm.AllowReductionsUntilDate}
              validationRules={ValidationRules.AllowReductionsUntilDate}
              onSelect={actions.updateValue}
              onChangeRaw={actions.simpleUpdate}
              helperText={isUsingNumbers() ? 'End users will be unable to reduce the numbers in their registration after midnight on this date. If blank, reductions to finalized numbers will never be allowed.' : 'If a date is set here, end users will be unable to remove event registrations after midnight at the end of this date. Otherwise, users will be able to remove participants up until registration closes.'}
              removeMax
              helperTextOverflow
            />
          </Column>
        </Row>
        <Row marginTop={24}>
          <FormSection
            disabled={this.isFormDisabled()}
            title={`Shared Settings - ${adminEventType.General.Name}`}
            onEditClick={() => this.openSharedSettingsModal(EventTypeFormSection.PRICING2)}
          >
            <Row>
              <Column span={6} mobileSpan={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Upfront Payment</div>
                <div className={`${namespace()}--card--field-value`}>{selectedUpfrontPayment.Name}</div>
              </Column>
              {selectedUpfrontPayment.ID === 2 && <Column mobileMarginTop={16} span={3} mobileSpan={6} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Youth</div>
                <div className={`${namespace()}--card--field-value`}>{`$${adminEventType.Pricing2.UpfrontPaymentSpecific_Youth.toFixed(2)}`}</div>
              </Column>}
              {selectedUpfrontPayment.ID === 2 && <Column mobileMarginTop={16} span={3} mobileSpan={6} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Adults</div>
                <div className={`${namespace()}--card--field-value`}>{`$${adminEventType.Pricing2.UpfrontPaymentSpecific_Adults.toFixed(2)}`}</div>
              </Column>}
            </Row>
            {isJustNames() && adminEventType.Pricing2.MultipleEventDiscountForYouth && <Row marginTop={16}>
              <Column span={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Multiple Event Discount for Youth Participants</div>
                <div className={`${namespace()}--card--field-value`}>{`${selectedMultipleEventDiscount.Name} - $${adminEventType.Pricing2.MultipleEventDiscountAmount.toFixed(2)}`}</div>
              </Column>
            </Row>}
          </FormSection>
        </Row>
        {isUsingNumbers() && <FieldSet fixedLegendFontSize marginBottom={0} marginTop={24} newDesign legendMarginBottom={8} fontSize={18} name="Deposits">
          <div className={`${namespace()}--helper-text large`}>These deposits will need to be paid before adding individuals and registering them for classes. They do not affect the amount due upfront when adding spots to a registration.</div>
          <Row marginTop={16}>
            <Column span={6} mobileSpan={12}>
              <DatePicker
                label="Youth Participant Deposit Due Date"
                disabled={disabled || this.isFormDisabled()}
                value={ActiveForm.YouthDepositDueDate}
                validationRules={ValidationRules.YouthDepositDueDate}
                onSelect={this.onUpdateYouthDepositDueDate}
                onChangeRaw={actions.simpleUpdate}
                removeMax
              />
            </Column>
            <Column mobileMarginTop={8} span={3} mobileSpan={12}>
              <MoneyField
                label="Amount"
                disabled={!ActiveForm.YouthDepositDueDate || disabled || this.isFormDisabled()}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules.YouthDepositAmount}
                value={ActiveForm.YouthDepositAmount}
                pullDefaultValueFromRule
                dollarSignPrefix
              />
            </Column>
          </Row>
          <Row marginTop={8} styles={{position: 'relative'}}>
            <Column span={6} mobileSpan={12}>
              <MoneyField
                overflowLabel
                label="Default Campsite Deposit Amount"
                disabled={disabled || this.isFormDisabled()}
                onChange={actions.simpleUpdate}
                onBlur={actions.updateValue}
                validationRules={ValidationRules.CampsiteDepositDefaultAmount}
                value={ActiveForm.CampsiteDepositDefaultAmount}
                pullDefaultValueFromRule
                dollarSignPrefix
                infoOverflow
                info='A minimum of this campsite deposit must be paid when end users self-register for this event. The deposit amount can be overridden under each registration.'
              />
            </Column>
          </Row>
        </FieldSet>}
        <Row marginTop={24}>
          <FormSection
            disabled={this.isFormDisabled()}
            title={`Shared Settings - ${adminEventType.General.Name}`}
            onEditClick={() => this.openSharedSettingsModal(EventTypeFormSection.PRICING3)}
          >
            <Row>
              <Column span={12} mobileSpan={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Pricing Levels by Date</div>
                <div className={`${namespace()}--card--field-value`}>{`${makeFirstLetterUppercase(adminEventType.Pricing3.PricingLevelsByDate_Early)}, ${makeFirstLetterUppercase(adminEventType.Pricing3.PricingLevelsByDate_Base)}, ${makeFirstLetterUppercase(adminEventType.Pricing3.PricingLevelsByDate_Late)}`}</div>
              </Column>
            </Row>
            <Row marginTop={16}>
              <Column span={12} mobileSpan={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Allow Late Registrations</div>
                <div className={`${namespace()}--card--field-value--row`}>
                  <div className={`${namespace()}--card--field-value--icon`}>
                    {adminEventType.Pricing3.AllowLateRegistrations ? <ValidIcon /> : <InvalidIcon grey />}
                  </div>
                  <div className={`${namespace()}--card--field-value ${!adminEventType.Pricing3.AllowLateRegistrations ? 'invalid' : ''}`}>{`${adminEventType.Pricing3.AllowLateRegistrations ? `Registration will close ${adminEventType.Pricing3.NumberOfDaysBeforeEventToCloseRegistration} day${adminEventType.Pricing3.NumberOfDaysBeforeEventToCloseRegistration !== 1 ? 's' : ''} before the event at the beginning of the day` : 'Disabled, registration will close on Late Pricing Start Date at the beginning of the day'}`}</div>
                </div>
              </Column>
            </Row>
          </FormSection>
        </Row>
        <FieldSet fixedLegendFontSize marginBottom={0} marginTop={24} newDesign legendMarginBottom={8} fontSize={18} name="Pricing Levels by Date">
          <div className={`${namespace()}--helper-text large`}>Enter date when Early rate should increase to Base rate and date when Base rate should increase to Late rate. Rates will go up at the beginning of the day (previous day at midnight). Late rate will only be used if late registrations are enabled.</div>
          <Row marginTop={16}>
            <Column span={6} mobileSpan={12}>
              <DatePicker
                label="Base Pricing Start Date"
                disabled={disabled || this.isFormDisabled()}
                value={ActiveForm.BasePricingStartDate}
                validationRules={ValidationRules.BasePricingStartDate}
                onSelect={actions.updateValue}
                onChangeRaw={actions.simpleUpdate}
                removeMax
              />
            </Column>
            <Column mobileMarginTop={8} span={6} mobileSpan={12}>
              <DatePicker
                label="Late Pricing Start Date"
                disabled={disabled || this.isFormDisabled()}
                value={ActiveForm.LatePricingStartDate}
                validationRules={ValidationRules.LatePricingStartDate}
                onSelect={actions.updateValue}
                onChangeRaw={actions.simpleUpdate}
                removeMax
              />
            </Column>
          </Row>
          <Row marginTop={8}>
            <Column span={12} mobileSpan={12} className={`${namespace()}--card--col`}>
              <div className={`${namespace()}--card--field-label`}>Registration End Date</div>
              <div className={`${namespace()}--card--field-value`}>{`${adminEventType.Pricing3.AllowLateRegistrations ? `Registration will close ${adminEventType.Pricing3.NumberOfDaysBeforeEventToCloseRegistration} day${adminEventType.Pricing3.NumberOfDaysBeforeEventToCloseRegistration !== 1 ? 's' : ''} before the event at the beginning of the day` : 'Registration will close on Late Pricing Start Date at the beginning of the day'}`}</div>
              <div className={`${namespace()}--helper-text has-margin-top`}>Based on above shared settings for late registrations</div>
            </Column>
          </Row>
        </FieldSet>
        <Row marginTop={24}>
          <FormSection
            disabled={this.isFormDisabled()}
            title={`Shared Settings - ${adminEventType.General.Name}`}
            onEditClick={() => this.openSharedSettingsModal(EventTypeFormSection.PRICING4)}
          >
          <Row>
            <Column span={12} mobileSpan={12} className={`${namespace()}--card--col`}>
              <div className={`${namespace()}--card--field-label`}>Enabled Pricing Tiers</div>
              <div className={`${namespace()}--card--field-value`}>{pricingTiers.filter((p) => p.IsEnabled).map((p) => p.Name).join(', ')}</div>
            </Column>
          </Row>
          <Row marginTop={16}>
            <Column span={12} mobileSpan={12} className={`${namespace()}--card--col`}>
              <div className={`${namespace()}--card--field-label`}>Participant Types</div>
              <div className={`${namespace()}--card--field-value`}>{participantTypes.map((pt) => `${pt.Name} (${pt.IsYouth ? 'Y' : 'A'})`).join(', ')}</div>
            </Column>
          </Row>
          <Row marginTop={16}>
            <Column span={12} mobileSpan={12} className={`${namespace()}--card--col`}>
              <div className={`${namespace()}--card--field-label`}>Lock-In Pricing Based On</div>
              <div className={`${namespace()}--card--field-value`}>{`${lockInPricingBasedOnOption.Name}`}</div>
            </Column>
          </Row>
          </FormSection>
        </Row>
        <FieldSet fixedLegendFontSize marginBottom={0} marginTop={24} newDesign legendMarginBottom={8} fontSize={18} name="Registration Fees">
          {pricingTiers.map((pt) => {
            return (
              <Card className={`${namespace()}--registration-fees--pricing-tier ${!pt.IsEnabled ? 'inactive': ''}`} key={`registration_fees_pricing_tier${pt.ID}`}>
                <div className={`${namespace()}--registration-fees--pricing-tier--header`}>
                  <div className={`${namespace()}--registration-fees--pricing-tier--header--text`}>{pt.Name}</div>
                  {pt.IsEnabled && pt.ID !== 1 && <Button disabled={this.isFormDisabled()} color='white' onClick={() => this.onCopyFromPricintTierClick(pt.ID)} className={`${namespace()}--registration-fees--pricing-tier--header--btn`}>Copy From Default / In-Council</Button>}
                </div>
                {!pt.IsEnabled && <div className={`${namespace()}--registration-fees--pricing-tier--content`}>This pricing tier has been disabled in above Shared Settings. Pricing in this tier will be kept in sync with Default / In-Council pricing.</div>}
                {pt.IsEnabled && <div className={`${namespace()}--registration-fees--pricing-tier--content`}>
                  {participantTypes.map((_pt, index) => {
                    const amountEarlyKey = getPricingAmountKey(pt.ID, _pt.ID, 'AmountEarly');
                    const amountBaseKey = getPricingAmountKey(pt.ID, _pt.ID, 'AmountBase');
                    const amountLateKey = getPricingAmountKey(pt.ID, _pt.ID, 'AmountLate');
                    if (!ValidationRules[amountEarlyKey] || !ValidationRules[amountBaseKey] || !ValidationRules[amountLateKey]) return null;
                    return (
                      <FieldSet
                        fixedLegendFontSize
                        marginBottom={index !== participantTypes.length - 1 ? 16 : 0}
                        newDesign
                        legendMarginBottom={16} fontSize={16}
                        name={`${_pt.Name} (${_pt.IsYouth ? 'Y' : 'A'})`}
                        key={`${pt.ID}_${_pt.ID}`}
                      >
                        <Row wrap={false}>
                          <Column className={`${namespace()}--registration-fees--pricing-tier--col`} marginRight={24} span={3}>
                            <MoneyField
                              label='Early'
                              disabled={disabled || this.isFormDisabled()}
                              onChange={actions.simpleUpdate}
                              onBlur={actions.updateValue}
                              pullDefaultValueFromRule
                              validationRules={ValidationRules[amountEarlyKey]}
                              value={ActiveForm[amountEarlyKey]}
                              dollarSignPrefix
                            />
                          </Column>
                          <Column className={`${namespace()}--registration-fees--pricing-tier--col`} marginRight={24} span={3}>
                            <MoneyField
                              label='Base'
                              disabled={disabled || this.isFormDisabled()}
                              onChange={actions.simpleUpdate}
                              onBlur={actions.updateValue}
                              pullDefaultValueFromRule
                              validationRules={ValidationRules[amountBaseKey]}
                              value={ActiveForm[amountBaseKey]}
                              dollarSignPrefix
                            />
                          </Column>
                          <Column className={`${namespace()}--registration-fees--pricing-tier--col`} span={3}>
                            <MoneyField
                              label='Late'
                              disabled={disabled || this.isFormDisabled()}
                              onChange={actions.simpleUpdate}
                              onBlur={actions.updateValue}
                              pullDefaultValueFromRule
                              validationRules={ValidationRules[amountLateKey]}
                              value={ActiveForm[amountLateKey]}
                              dollarSignPrefix
                            />
                          </Column>
                        </Row>
                      </FieldSet>
                    );
                  })}
                </div>}
              </Card>
            );
          })}
        </FieldSet>
        {systemSettings && (systemSettings.AllowCCPayment || systemSettings.AllowECheckPayment) && <Row marginTop={24}>
          <FormSection
            disabled={this.isFormDisabled()}
            title={`Shared Settings - ${adminEventType.General.Name}`}
            onEditClick={() => this.openSharedSettingsModal(EventTypeFormSection.PRICING5)}
          >
            <Row>
              {systemSettings.AllowCCPayment && <Column span={6} mobileSpan={12} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>Credit Card Payments</div>
                <div className={`${namespace()}--card--field-value--row`}>
                  <div className={`${namespace()}--card--field-value--icon`}>
                    {adminEventType.Pricing5.AllowCCPayment ? <ValidIcon /> : <InvalidIcon grey />}
                  </div>
                  <div className={`${namespace()}--card--field-value ${!adminEventType.Pricing5.AllowCCPayment ? 'invalid' : ''}`}>{`${adminEventType.Pricing5.AllowCCPayment ? `Allowed${adminEventType.Pricing5.CCFeePercent > 0 ? ' - ' + adminEventType.Pricing5.CCFeePercent + '% Extra Fee' : ''}` : 'Not Allowed'}`}</div>
                </div>
              </Column>}
              {systemSettings.AllowECheckPayment && <Column span={6} mobileSpan={12} mobileMarginTop={systemSettings.AllowCCPayment ? 16 : 0} className={`${namespace()}--card--col`}>
                <div className={`${namespace()}--card--field-label`}>eCheck Payments</div>
                <div className={`${namespace()}--card--field-value--row`}>
                  <div className={`${namespace()}--card--field-value--icon`}>
                    {adminEventType.Pricing5.AllowECheckPayment ? <ValidIcon /> : <InvalidIcon grey />}
                  </div>
                  <div className={`${namespace()}--card--field-value ${!adminEventType.Pricing5.AllowECheckPayment ? 'invalid' : ''}`}>{`${adminEventType.Pricing5.AllowECheckPayment ? `Allowed${adminEventType.Pricing5.eCheckFeePercent ? ' - ' + adminEventType.Pricing5.eCheckFeePercent + '% Extra Fee' : ''}` : 'Not Allowed'}`}</div>
                </div>
              </Column>}
            </Row>
          </FormSection>
        </Row>}
      </PageSection>
    );
  };

  renderParticipantField = (text: string, show: boolean, required?: boolean) => {
    if (!show) return null;

    return (
      <div className={`${namespace()}--card--field-value named-participant-field`}>{`${text} (${required ? 'required' : 'optional'})`}</div>
    );
  };

  renderRegistrationSection = () => {
    const {disabled, action, actions, adminEventsCacheOne, adminEventsCacheTwoEvent, eventForm: {ValidationRules, ActiveForm}, selectedPaymentRequirementsToAddParticipantsOption, defaultGroupType, defaultUnitType, eventCapacityParticipantTypes} = this.props;

    const adminEventType = adminEventsCacheOne ? adminEventsCacheOne.EventsEventType : null;

    if (!adminEventType || !defaultGroupType || !defaultUnitType || !selectedPaymentRequirementsToAddParticipantsOption) return null;

    return (
      <PageSection
        expand={true}
        title='Registration'
        noPaddingBottomMobile
      >
        <FieldSet fixedLegendFontSize marginBottom={0} newDesign legendMarginBottom={8} fontSize={18} name="Capacity">
          <div className={`${namespace()}--helper-text large`}>Limit the total number of participants who can register for this event and/or the number of participants of each type. Leave blank if no limit.</div>
          <Row marginTop={16}>
            {eventCapacityParticipantTypes.map((pt, index) => {
              const key = getAdminEventCapacityNameKey(pt.ID || 0);
              return (
                <Column className={`${namespace()}--capacity-col`} key={`capacity_${pt.ID || 0}`} span={6} mobileSpan={12} marginTop={index > 1 ? 8 : 0} mobileMarginTop={index > 1 ? 8 : 0}>
                  <TextField
                    label={pt.Name}
                    disabled={disabled || this.isFormDisabled()}
                    onChange={actions.simpleUpdate}
                    onBlur={actions.updateValue}
                    validationRules={ValidationRules[key]}
                    value={ActiveForm[key]}
                  />
                </Column>
              );
            })}
          </Row>
          <Row marginTop={16}>
            <FormSection
              disabled={this.isFormDisabled()}
              title={`Shared Settings - ${adminEventType.General.Name}`}
              onEditClick={() => this.openSharedSettingsModal(EventTypeFormSection.GROUP_REGISTRATION)}
            >
              <Row>
                <Column mobileSpan={12} span={6} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Default Group Type</div>
                  <div className={`${namespace()}--card--field-value`}>{defaultGroupType.Name}</div>
                </Column>
                <Column mobileSpan={12} span={6} mobileMarginTop={16} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Default Unit Type</div>
                  <div className={`${namespace()}--card--field-value`}>{defaultUnitType.Name}</div>
                </Column>
              </Row>
              <Row marginTop={16}>
                <Column mobileSpan={12} span={6} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Show Group Type During Account Creation</div>
                  <div className={`${namespace()}--card--field-value--row`}>
                    <div className={`${namespace()}--card--field-value--icon`}>
                      {adminEventType.GroupAndRegistration.ShowGroupTypeDuringAccountCreation ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--field-value ${!adminEventType.GroupAndRegistration.ShowGroupTypeDuringAccountCreation ? 'invalid' : ''}`}>{`${adminEventType.GroupAndRegistration.ShowGroupTypeDuringAccountCreation ? 'Enabled' : 'Disabled'}`}</div>
                  </div>
                </Column>
                {isUsingNames() && <Column mobileSpan={12} span={6} mobileMarginTop={16} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Allow Non-Scouter Participants</div>
                  <div className={`${namespace()}--card--field-value--row`}>
                    <div className={`${namespace()}--card--field-value--icon`}>
                      {adminEventType.GroupAndRegistration.AllowNonScouterParticipants ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--field-value ${!adminEventType.GroupAndRegistration.AllowNonScouterParticipants ? 'invalid' : ''}`}>{`${adminEventType.GroupAndRegistration.AllowNonScouterParticipants ? 'Enabled' : 'Disabled'}`}</div>
                  </div>
                </Column>}
              </Row>
              {isUsingNames() && isUsingNumbers() && <Row marginTop={16}>
                <Column span={12} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Payment Requirements to Add Participants</div>
                  <div className={`${namespace()}--card--field-value`}>{`${selectedPaymentRequirementsToAddParticipantsOption.Name}${adminEventType.GroupAndRegistration.PaymentRequirementsToAddParticipantsID === 2 ? ' - ' + adminEventType.GroupAndRegistration.PaymentRequirementsMinimumPercentage + '%' : ''}`}</div>
                </Column>
              </Row>}
              {isJustNumbers() && <Row marginTop={16}>
                <Column mobileSpan={12} span={6} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Show Youth</div>
                  <div className={`${namespace()}--card--field-value--row`}>
                    <div className={`${namespace()}--card--field-value--icon`}>
                      {adminEventType.GroupAndRegistration.ShowYouth ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--field-value ${!adminEventType.GroupAndRegistration.ShowYouth ? 'invalid' : ''}`}>{`${adminEventType.GroupAndRegistration.ShowYouth ? 'Enabled' : 'Disabled'}`}</div>
                  </div>
                </Column>
                <Column mobileSpan={12} span={6} mobileMarginTop={16} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Show Adults</div>
                  <div className={`${namespace()}--card--field-value--row`}>
                    <div className={`${namespace()}--card--field-value--icon`}>
                      {adminEventType.GroupAndRegistration.ShowAdults ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--field-value ${!adminEventType.GroupAndRegistration.ShowAdults ? 'invalid' : ''}`}>{`${adminEventType.GroupAndRegistration.ShowAdults ? 'Enabled' : 'Disabled'}`}</div>
                  </div>
                </Column>
              </Row>}
              {isUsingNumbers() && <Row marginTop={16}>
                <Column mobileMarginTop={16} mobileSpan={12} span={6} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Event Contact</div>
                  <div className={`${namespace()}--card--field-value--row`}>
                    <div className={`${namespace()}--card--field-value--icon`}>
                      {adminEventType.GroupAndRegistration.ShowEventContact ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--field-value ${!adminEventType.GroupAndRegistration.ShowEventContact ? 'invalid' : ''}`}>{`${adminEventType.GroupAndRegistration.ShowEventContact ? `Enabled, ${adminEventType.GroupAndRegistration.RequireEventContact ? 'Required' : 'Optional'}` : 'Disabled'}`}</div>
                  </div>
                </Column>
                <Column mobileSpan={12} span={6} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Campsite Ranking</div>
                  <div className={`${namespace()}--card--field-value--row`}>
                    <div className={`${namespace()}--card--field-value--icon`}>
                      {adminEventType.GroupAndRegistration.ShowCampsiteRanking ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--field-value ${!adminEventType.GroupAndRegistration.ShowCampsiteRanking ? 'invalid' : ''}`}>{`${adminEventType.GroupAndRegistration.ShowCampsiteRanking ? `Enabled, ${adminEventType.GroupAndRegistration.RequireCampsiteRanking ? 'Required' : 'Optional'}` : 'Disabled'}`}</div>
                  </div>
                </Column>
              </Row>}
              {isUsingNumbers() && <Row marginTop={16}>
                <Column mobileSpan={12} span={6} mobileMarginTop={16} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Registration Notes</div>
                  <div className={`${namespace()}--card--field-value--row`}>
                    <div className={`${namespace()}--card--field-value--icon`}>
                      {adminEventType.GroupAndRegistration.ShowEndUserRegistrationNotes ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--field-value ${!adminEventType.GroupAndRegistration.ShowEndUserRegistrationNotes ? 'invalid' : ''}`}>{`${adminEventType.GroupAndRegistration.ShowEndUserRegistrationNotes ? `Enabled, ${adminEventType.GroupAndRegistration.RequireEndUserRegistrationNotes ? 'Required' : 'Optional'}` : 'Disabled'}`}</div>
                  </div>
                </Column>
                {adminEventType.GroupAndRegistration.ShowEndUserRegistrationNotes && adminEventType.GroupAndRegistration.PromptEndUserRegistrationNotes && <Column span={6} mobileSpan={12} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Custom Prompt for Registration Notes</div>
                  <div className={`${namespace()}--card--field-value`}>{adminEventType.GroupAndRegistration.PromptEndUserRegistrationNotes}</div>
                </Column>}
              </Row>}
            </FormSection>
          </Row>
          {isUsingNames() && <Row marginTop={24}>
            <FormSection
              disabled={this.isFormDisabled()}
              title={`Shared Settings - ${adminEventType.General.Name}`}
              onEditClick={() => this.openSharedSettingsModal(EventTypeFormSection.NAMED_PARTICIPANTS)}
            >
              <Row>
                <Column span={12} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Show Youth</div>
                  <div className={`${namespace()}--card--field-value--row`}>
                    <div className={`${namespace()}--card--field-value--icon`}>
                      {adminEventType.NamedParticipants.Youth.ShowYouth ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--field-value ${!adminEventType.NamedParticipants.Youth.ShowYouth ? 'invalid' : ''}`}>{`${adminEventType.NamedParticipants.Youth.ShowYouth ? 'Enabled' : 'Disabled'}`}</div>
                  </div>
                </Column>
              </Row>
              {adminEventType.NamedParticipants.Youth.ShowYouth && <Row marginTop={16}>
                <Column span={12} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Youth Registration Fields</div>
                  <div className={`${namespace()}--card--field-value--row wrap`}>
                    {this.renderParticipantField('Gender', adminEventType.NamedParticipants.Youth.ShowGenderYouth, adminEventType.NamedParticipants.Youth.RequireGenderYouth)}
                    {this.renderParticipantField('Date of Birth', adminEventType.NamedParticipants.Youth.ShowDOBYouth, adminEventType.NamedParticipants.Youth.RequireDOBYouth)}
                    {this.renderParticipantField('Allergies, Dietary Restrictions', adminEventType.NamedParticipants.Youth.ShowAllergiesAndDietaryYouth, false)}
                    {this.renderParticipantField('Notes for Special Needs', adminEventType.NamedParticipants.Youth.ShowSpecialNeedsNotesYouth, false)}
                    {this.renderParticipantField('Event Registration Notes', adminEventType.NamedParticipants.Youth.ShowRegistrationNotesYouth, false)}
                    {this.renderParticipantField('Email', true, adminEventType.NamedParticipants.Youth.RequireEmailYouth)}
                    {this.renderParticipantField('Phone Number', adminEventType.NamedParticipants.Youth.ShowPhoneYouth, adminEventType.NamedParticipants.Youth.RequirePhoneYouth)}
                    {this.renderParticipantField('Address', adminEventType.NamedParticipants.Youth.ShowAddressYouth, adminEventType.NamedParticipants.Youth.RequireAddressYouth)}
                    {this.renderParticipantField('Emergency Contact', adminEventType.NamedParticipants.Youth.ShowEmergencyContact, adminEventType.NamedParticipants.Youth.RequireEmergencyContact)}
                    {this.renderParticipantField('Allow Photography', adminEventType.NamedParticipants.Youth.ShowAllowPhotographyYouth, false)}
                    {this.renderParticipantField('Shirt Size', adminEventType.NamedParticipants.Youth.ShowShirtSizeYouth, adminEventType.NamedParticipants.Youth.RequireShirtSizeYouth)}
                    {this.renderParticipantField('Grade', adminEventType.NamedParticipants.Youth.ShowGradeYouth, adminEventType.NamedParticipants.Youth.RequireGradeYouth)}
                    {this.renderParticipantField('Rank', true, adminEventType.NamedParticipants.Youth.RequireRankYouth)}
                    {this.renderParticipantField('Summer Camp Year', adminEventType.NamedParticipants.Youth.ShowSummerCampYearYouth, false)}
                    {this.renderParticipantField('Unit Position', adminEventType.NamedParticipants.Youth.ShowUnitPositionYouth, adminEventType.NamedParticipants.Youth.RequireUnitPositionYouth)}
                    {this.renderParticipantField('Unit Leader', adminEventType.NamedParticipants.Youth.ShowUnitLeaderYouth, adminEventType.NamedParticipants.Youth.RequireUnitLeaderYouth)}
                    {this.renderParticipantField('BSA ID', adminEventType.NamedParticipants.Youth.ShowBSAIDYouth, adminEventType.NamedParticipants.Youth.RequireBSAIDYouth)}
                    {this.renderParticipantField('Is Staff?', adminEventType.NamedParticipants.Youth.ShowIsStaffYouth, false)}
                  </div>
                </Column>
              </Row>}
              <Row marginTop={16}>
                <Column span={12} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Show Adults</div>
                  <div className={`${namespace()}--card--field-value--row`}>
                    <div className={`${namespace()}--card--field-value--icon`}>
                      {adminEventType.NamedParticipants.Adults.ShowAdults ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--field-value ${!adminEventType.NamedParticipants.Adults.ShowAdults ? 'invalid' : ''}`}>{`${adminEventType.NamedParticipants.Adults.ShowAdults ? 'Enabled' : 'Disabled'}`}</div>
                  </div>
                </Column>
              </Row>
              {adminEventType.NamedParticipants.Adults.ShowAdults && <Row marginTop={16}>
                <Column span={12} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Adult Registration Fields</div>
                  <div className={`${namespace()}--card--field-value--row wrap`}>
                    {this.renderParticipantField('Gender', true, adminEventType.NamedParticipants.Adults.RequireGenderAdult)}
                    {this.renderParticipantField('Date of Birth', adminEventType.NamedParticipants.Adults.ShowDOBAdult, adminEventType.NamedParticipants.Adults.RequireDOBAdult)}
                    {this.renderParticipantField('Allergies, Dietary Restrictions', adminEventType.NamedParticipants.Adults.ShowAllergiesAndDietaryAdult, false)}
                    {this.renderParticipantField('Notes for Special Needs', adminEventType.NamedParticipants.Adults.ShowSpecialNeedsNotesAdult, false)}
                    {this.renderParticipantField('Event Registration Notes', adminEventType.NamedParticipants.Adults.ShowRegistrationNotesAdult, false)}
                    {this.renderParticipantField('Email', true, adminEventType.NamedParticipants.Adults.RequireEmailAdult)}
                    {this.renderParticipantField('Phone Number', adminEventType.NamedParticipants.Adults.ShowPhoneAdult, adminEventType.NamedParticipants.Adults.RequirePhoneAdult)}
                    {this.renderParticipantField('Address', adminEventType.NamedParticipants.Adults.ShowAddressAdult, adminEventType.NamedParticipants.Adults.RequireAddressAdult)}
                    {this.renderParticipantField('Shirt Size', adminEventType.NamedParticipants.Adults.ShowShirtSizeAdult, adminEventType.NamedParticipants.Adults.RequireShirtSizeAdult)}
                    {this.renderParticipantField('Unit Position', adminEventType.NamedParticipants.Adults.ShowUnitPositionAdult, adminEventType.NamedParticipants.Adults.RequireUnitPositionAdult)}
                    {this.renderParticipantField('Youth Protection Training Date', adminEventType.NamedParticipants.Adults.ShowDateYouthProtectionTrainingAdult, adminEventType.NamedParticipants.Adults.RequireDateYouthProtectionTrainingAdult)}
                    {this.renderParticipantField('Baloo Training Date', adminEventType.NamedParticipants.Adults.ShowDateBalooTrainingAdult, adminEventType.NamedParticipants.Adults.RequireDateBalooTrainingAdult)}
                    {this.renderParticipantField('Basic Training Date', adminEventType.NamedParticipants.Adults.ShowDateBasicTrainingForPositionAdult, adminEventType.NamedParticipants.Adults.RequireDateBasicTrainingForPositionAdult)}
                    {this.renderParticipantField('Days in Camp', adminEventType.NamedParticipants.Adults.ShowDaysInCampAdult, false)}
                    {this.renderParticipantField('BSA ID', adminEventType.NamedParticipants.Adults.ShowBSAIDAdult, adminEventType.NamedParticipants.Adults.RequireBSAIDAdult)}
                  </div>
                </Column>
              </Row>}
              {adminEventType.NamedParticipants.OAFields.ShowOAFields && <Row marginTop={16}>
                <Column span={12} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Other Fields</div>
                </Column>
              </Row>}
              {adminEventType.NamedParticipants.OAFields.ShowOAFields && <Row>
                <Column span={12} className={`${namespace()}--card--col`}>
                  {this.renderParticipantField('OA Fields', adminEventType.NamedParticipants.OAFields.ShowOAFields, false)}
                </Column>
              </Row>}
            </FormSection>
          </Row>}
        </FieldSet>
      </PageSection>
    );
  };

  renderClassSchedulingSection = () => {
    const {disabled, action, actions, adminEventsCacheOne, adminEventsCacheTwoEvent, eventForm: {ValidationRules, ActiveForm}} = this.props;

    const adminEventType = adminEventsCacheOne ? adminEventsCacheOne.EventsEventType : null;

    if (!adminEventType) return null;
    return (
      <PageSection
        expand={true}
        title='Class Scheduling'
        noPaddingBottomMobile
      >
        <Row>
          <FormSection
            disabled={this.isFormDisabled()}
            title={`Shared Settings - ${adminEventType.General.Name}`}
            onEditClick={() => this.openSharedSettingsModal(EventTypeFormSection.CLASS_SCHEDULING)}
          >
            {isUsingNames() && 
              <Row>
                <Column span={6} mobileSpan={12} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Enable Waiting List Under Individual Participants</div>
                  <div className={`${namespace()}--card--field-value--row`}>
                    <div className={`${namespace()}--card--field-value--icon`}>
                      {adminEventType.ClassScheduling.EnableClassWaitingListForIndividuals ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--field-value ${!adminEventType.ClassScheduling.EnableClassWaitingListForIndividuals ? 'invalid' : ''}`}>{`${adminEventType.ClassScheduling.EnableClassWaitingListForIndividuals ? 'Enabled' : 'Disabled'}`}</div>
                  </div>
                </Column>
                <Column span={6} mobileSpan={12} mobileMarginTop={16} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Show Full Classes for Participants</div>
                  <div className={`${namespace()}--card--field-value--row`}>
                    <div className={`${namespace()}--card--field-value--icon`}>
                      {adminEventType.ClassScheduling.EnableClassWaitingListForIndividuals || adminEventType.ClassScheduling.ShowFullClassesForIndividuals ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--field-value ${!adminEventType.ClassScheduling.EnableClassWaitingListForIndividuals && !adminEventType.ClassScheduling.ShowFullClassesForIndividuals ? 'invalid' : ''}`}>{`${adminEventType.ClassScheduling.EnableClassWaitingListForIndividuals || adminEventType.ClassScheduling.ShowFullClassesForIndividuals ? 'Enabled' : 'Disabled'}`}</div>
                  </div>
                </Column>
              </Row>}
              {isUsingNumbers() && <Row marginTop={16}>
                <Column span={6} mobileSpan={12} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Show Full Group Classes</div>
                  <div className={`${namespace()}--card--field-value--row`}>
                    <div className={`${namespace()}--card--field-value--icon`}>
                      {adminEventType.ClassScheduling.ShowFullClassesGroup ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--field-value ${!adminEventType.ClassScheduling.ShowFullClassesGroup ? 'invalid' : ''}`}>{`${adminEventType.ClassScheduling.ShowFullClassesGroup ? 'Enabled' : 'Disabled'}`}</div>
                  </div>
                </Column>
              </Row>}
              <Row marginTop={16}>
                <Column span={6} mobileSpan={12} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Require Unique Class Types</div>
                  <div className={`${namespace()}--card--field-value--row`}>
                    <div className={`${namespace()}--card--field-value--icon`}>
                      {adminEventType.ClassScheduling.RequireUniqueClassTypes ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--field-value ${!adminEventType.ClassScheduling.RequireUniqueClassTypes ? 'invalid' : ''}`}>{`${adminEventType.ClassScheduling.RequireUniqueClassTypes ? 'Enabled' : 'Disabled'}`}</div>
                  </div>
                </Column>
                <Column span={6} mobileSpan={12} mobileMarginTop={16} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Show Class Location Field</div>
                  <div className={`${namespace()}--card--field-value--row`}>
                    <div className={`${namespace()}--card--field-value--icon`}>
                      {adminEventType.ClassScheduling.ShowClassLocation ? <ValidIcon /> : <InvalidIcon grey />}
                    </div>
                    <div className={`${namespace()}--card--field-value ${!adminEventType.ClassScheduling.ShowClassLocation ? 'invalid' : ''}`}>{`${adminEventType.ClassScheduling.ShowClassLocation ? 'Enabled' : 'Disabled'}`}</div>
                  </div>
                </Column>
              </Row>
              {isUsingNames() && adminEventType.ClassScheduling.CustomLabelForParticipantClasses && <Row marginTop={16}>
                <Column span={12} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Custom Label for Participant Classes</div>
                  <div className={`${namespace()}--card--field-value`}>{adminEventType.ClassScheduling.CustomLabelForParticipantClasses}</div>
                </Column>
              </Row>}
              {((isUsingNames() && (adminEventType.ClassScheduling.MinimumScheduledTimeBlocks_Youth || adminEventType.ClassScheduling.MinimumScheduledTimeBlocks_Adults)) || (isUsingNumbers() && adminEventType.ClassScheduling.MinimumScheduledTimeBlocks_Group)) && <Row marginTop={16}>
                <Column span={12} mobileSpan={12} className={`${namespace()}--card--col`}>
                  <div className={`${namespace()}--card--field-label`}>Minimum Scheduled Timeblocks</div>
                  {isUsingNames() && adminEventType.ClassScheduling.MinimumScheduledTimeBlocks_Youth && <div className={`${namespace()}--card--field-value`}>{`Youth - Time Block ${adminEventType.ClassScheduling.MinimumScheduledTimeBlocks_Youth}`}</div>}
                  {isUsingNames() && adminEventType.ClassScheduling.MinimumScheduledTimeBlocks_Adults && <div className={`${namespace()}--card--field-value`}>{`Adults - Time Block ${adminEventType.ClassScheduling.MinimumScheduledTimeBlocks_Adults}`}</div>}
                  {isUsingNumbers() && adminEventType.ClassScheduling.MinimumScheduledTimeBlocks_Group && <div className={`${namespace()}--card--field-value`}>{`Group - Time Block ${adminEventType.ClassScheduling.MinimumScheduledTimeBlocks_Group}`}</div>}
                </Column>
              </Row>}
          </FormSection>
        </Row>
      </PageSection>
    );
  };

  public render() {
    const {onSave, onDelete, action, disabled, actions, eventForm: {ValidationRules, ActiveForm, SubmitErrorMessage}, inert, adminEventsCacheOne, loading, hideFormAction, handleCompact} = this.props;

    return (
        <Main inert={inert}>
          <MainContent wide loading={loading} handleCompact={handleCompact}>
            <Form
              className={`${namespace()}`}
              onSubmit={e => e.preventDefault()}
              actions={
                hideFormAction ? null : (action === 'add' ? (
                  <FormActions><Button flat textColor="black" big onClick={onSave}>CREATE</Button></FormActions>
                ) : (
                  <FormActions wide>
                    <Row>
                      <Column expand>
                        <Button id={generateDOMId("admin-event-settings-save-btn")} disabled={this.isFormDisabled()} color="white" flat textColor="black" big onClick={onSave}>SAVE</Button>
                      </Column>
                      <Column>
                        <Button disabled={this.isFormDisabled()} color="white" flat textColor="red" big onClick={onDelete}>DELETE</Button>
                      </Column>
                    </Row>
                  </FormActions>
                ))
              }
            >
              {!!adminEventsCacheOne.EventsEventType && adminEventsCacheOne.EventsEventType.Permissions.hasEditEventReason ? <Alert className={`${namespace()}--alert`}>{adminEventsCacheOne.EventsEventType.Permissions.hasEditEventReason}</Alert> : null}
              {SubmitErrorMessage ? <Alert className={`${namespace()}--alert`}>{SubmitErrorMessage}</Alert> : null}
              {action === 'add' && <Row marginBottom={24} mobileMarginBottom={24}>
                <Column span={12}>
                  <TextField
                    label="Name"
                    id={generateDOMId("admin-event-name-field")}
                    disabled={disabled || this.isFormDisabled()}
                    onChange={actions.simpleUpdate}
                    onBlur={actions.updateValue}
                    validationRules={ValidationRules.Name}
                    value={ActiveForm.Name}
                  />
                </Column>
              </Row>}
              {action === 'add' && this.renderEventDatesFields()}
              {action === 'add' && this.renderRegistrationDatesFields()}
              {action === 'edit' && this.renderGeneralSection()}
              {action === 'edit' && this.renderDatesSection()}
              {action === 'edit' && this.renderPricingSection()}
              {action === 'edit' && this.renderRegistrationSection()}
              {action === 'edit' && this.renderClassSchedulingSection()}
            </Form>
          </MainContent>
        </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedLocationSelector = makeSelectedLocationSelector(true);
  const selectedRegistrationMethodSelector = makeSelectedRegistrationMethodSelector(true);
  const selectedMessageSelector = makeSelectedMessageSelector(true);
  const selectedEventCategorySelector = makeSelectedEventCategory(true);
  const eventCapacityParticipantTypesSelector = makeAdminEventCapacityParticipantTypesSelector();
  const defaultGroupTypeSelector = makeDefaultGroupTypeSelector();
  const defaultUnitTypeSelector = makeDefaultUnitTypeSelector();
  const selectedPaymentRequirementsToAddParticipantsOptionSelector = makeSelectedPaymentRequirementsToAddParticipantsOptionSelector();
  const selectedGLAccountSelector = makeAdminEventSelectedGLAccountSelector();
  const selectedUpfrontPaymentSelector = makeSelectedUpfrontPaymentSelector();
  const selectedMultipleEventDiscountSelector = makeSelectedMultipleEventDiscountSelector();
  const pricingTiersSelector = makePricingTiersSelector(true);
  const lockInPricingBasedOnOptionSelector = makeSelectedLockInPricingBasedOnOptions();
  const participantTypesSelector = makeParticipantTypesSelector(true);
  const selectedDistrictSelector = makeSelectedDistrictSelector();

  const selectedEventCategory = selectedEventCategorySelector(state);
  const selectedUpfrontPayment = selectedUpfrontPaymentSelector(state);
  const selectedMultipleEventDiscount = selectedMultipleEventDiscountSelector(state);
  const defaultGroupType = defaultGroupTypeSelector(state);
  const lockInPricingBasedOnOption = lockInPricingBasedOnOptionSelector(state);
  const defaultUnitType = defaultUnitTypeSelector(state);
  const selectedPaymentRequirementsToAddParticipantsOption = selectedPaymentRequirementsToAddParticipantsOptionSelector(state);

  return {
    adminEventsCacheOne: state.adminEvents.cacheOne,
    adminEventsCacheTwoEvent: state.adminEvents.cacheTwoEvent,
    eventForm: state.adminEvents.events.event.form,
    selectedRegistrationMethod: selectedRegistrationMethodSelector(state),
    selectedLocation: selectedLocationSelector(state),
    selectedEventCategory,
    selectedMessage: selectedMessageSelector(state),
    eventCapacityParticipantTypes: eventCapacityParticipantTypesSelector(state),
    defaultGroupType,
    defaultUnitType,
    selectedPaymentRequirementsToAddParticipantsOption,
    selectedGLAccount: selectedGLAccountSelector(state),
    selectedUpfrontPayment,
    selectedMultipleEventDiscount,
    pricingTiers: pricingTiersSelector(state),
    lockInPricingBasedOnOption,
    participantTypes: participantTypesSelector(state),
    systemSettings: state.session.SystemSettings,
    selectedDistrict: selectedDistrictSelector(state),
    cacheZero: state.cacheZero,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...eventTypeFormActionCreators,
  }, dispatch),
});
const ConnectedEventForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(EventForm);

export default ConnectedEventForm;
