import { createSelector } from 'reselect';
import { ApplicationState } from '../../../..';
import { IValidator, FormDefinition } from './validation';
import { InitReplaceParticipantTypeModal, ResetReplaceParticipantTypeModal, SUFFIX } from './actions';
import { apiValidationReducerCreator } from '../../../../Validation/reducerCreator';
import { makeFormModalPropSelector } from '../../../../App';
import { ParticipantType } from '../../../../../models/api/adminEventsCacheOne';
import { NEW_PARTICIPANT_TYPE } from '../../../../../constants/messages/adminEvents';
import { AdminEventTypeParticipantTypeKeys, getAdminEventTypeParticipantTypeAttrKey } from '../../EventType/Form/actions';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import {setDefaults} from '../../../../../utils/validator';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface ReplaceParticipantTypeModalState {
  ActiveForm: {
    ParticipantTypeID?: number;
  },
  ValidationRules: IValidator;
}
const getInitialState = () => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition},
});

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const participantTypesSelector = (state: ApplicationState) => state.adminEvents.eventTypes.eventType.form.Pricing4.ActiveForm.ParticipantTypes;

export const makeDeletingParticipantTypeSelector = () => {
  const modalPropsSelector = makeFormModalPropSelector(ModalTypes.REPLACE_PARTICIPANT_TYPE, 'participantTypeIndex');
  return createSelector(
    [participantTypesSelector, modalPropsSelector],
    (participantTypes: ParticipantType[], index: number) => {
      if (participantTypes && index >= 0) {
        const participantType = participantTypes[index];
        const nameKey = getAdminEventTypeParticipantTypeAttrKey(participantType.ID, AdminEventTypeParticipantTypeKeys.NAME);
        return {
          ...participantType,
          Name: participantType.ActiveForm[nameKey] || NEW_PARTICIPANT_TYPE,
        };
      }
      return null;
    }
  );
};

const ReplaceParticipantTypeModalReducer: Reducer<ReplaceParticipantTypeModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);

  if (isActionType(act, InitReplaceParticipantTypeModal)) {
    return setDefaults(act.rootState, getInitialState());
  } else if (isActionType(act, ResetReplaceParticipantTypeModal)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default ReplaceParticipantTypeModalReducer;