import { SocialType, CMSContactImage } from "../../../../../models/api/adminCMSCacheOne";
import { apiValidationReducerCreator, shouldSkipUpdateForm } from "../../../../Validation/reducerCreator";
import { SUFFIX, RemoveSocial, AddSocial, UpdateSocialType, UPDATE_CMS_CONTACT_FORM_SUFFIX } from "./actions";
import { IValidator, FormDefinition, getSocialMediasDefinition, getSocialUserValidationRule, getSocialMediaDefaultDefinition } from "./validation";
import { CMSSocial } from "../../../../../models/api/adminCMSCacheOne";
import { getSocialTypeValidationRule } from "../../../Settings/General/validation";
import { setDefaults } from "../../../../../utils/validator";
import { ApplicationState } from "../../../../../store";
import { getSocialTypesValue } from "../../../../../store/AdminCMSSite/Settings/General/validation";
import { ClearAdminCMSSiteCacheBelowOne } from "../../../../../store/AdminCMSSite/CacheOne/actions";
import { ClearAdminCMSCacheTwoContact } from "../../../../../store/AdminCMSSite/CacheTwoContacts/actions";
import { API_UPD } from "../../../../../constants/request";
import { Action, isActionType } from "../../../../../utils/StrongActions";
import { AnyAction, Reducer } from "redux";
import { WithRootState } from "../../../../Validation/actionCreator";

export interface ContactActiveForm {
  ID?: number;
  LastName?: string;
  FirstName?: string;
  MI?: string;
  Suffix?: string;
  Title?: string;
  Company?: string;
  CategoryID?: number;
  Phone?: string;
  PhoneExt?: string;
  MobilePhone?: string;
  Fax?: string;
  Email?: string;
  FeaturedImage?: CMSContactImage;
  SocialTypes: {ID: number, SocialTypeID: number, SocialUser: string}[];
  SiteID: number;
}

export interface ContactFormState {
  ActiveForm: ContactActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
}

const getInitialState = (rootState?: ApplicationState) => {
  return {
    ActiveForm: {
      SocialTypeID0: 15,
      SocialUser0: '',
    },
    ValidationRules: {
      ...FormDefinition,
      ...(rootState ? getSocialMediaDefaultDefinition(rootState) : {})
    },
  };
};

export const sortSocials = (rootState: ApplicationState, contactSocials: CMSSocial[], socialTypes?: SocialType[]) => {
  const finalSocialTypes = socialTypes || rootState.adminCMSSite.cacheOne.SocialTypes as SocialType[];
  // cant sort if no ssn in state tree
  if (!finalSocialTypes) return contactSocials;
  return contactSocials.sort((a, b) => {
    const ssnA = finalSocialTypes.find((s) => s.ID === a.SocialTypeID);
    const ssnB = finalSocialTypes.find((s) => s.ID === b.SocialTypeID);
    if (ssnA && ssnB) {
      if (ssnA.Ord !== ssnB.Ord) {
        return ssnA.Ord - ssnB.Ord;
      } else {
        // @todo - sort by value?
      }
    }
    // dont sort
    return 0;
  });
};

const initSocialTypesActiveForm = (rootState: ApplicationState, contactSocials: CMSSocial[], socialTypes?: SocialType[]) => {
  let activeForm = {};
  if (contactSocials) {
    const sorted = sortSocials(rootState, contactSocials, socialTypes);
    
    if (sorted && sorted.length > 0) {
      sorted.forEach((ss, index) => {
        activeForm[`SocialTypeID${index}`] = ss.SocialTypeID;
        activeForm[`SocialUser${index}`] = ss.SocialUser;
      });
    }
  } else {
    activeForm = {
      SocialTypeID0: socialTypes && socialTypes.length > 0 ? socialTypes[0].ID : 1,
      SocialUser0: '',
    };
  }

  return activeForm;
};

export const removeSocialTypeFromState = (state: any, newState: any, removeIndex) => {
  const socialTypes: any[] = [];
  for (let key of Object.keys(state.ActiveForm)) {
    if (key.includes('SocialTypeID')) {
      const i = key.replace('SocialTypeID', '');
      socialTypes.push({
        SocialTypeID: state.ActiveForm[`SocialTypeID${i}`],
        SocialUser: state.ActiveForm[`SocialUser${i}`],
      });
      delete newState.ActiveForm[`SocialTypeID${i}`];
      delete newState.ActiveForm[`SocialUser${i}`];
      delete newState.ValidationRules[`SocialTypeID${i}`];
      delete newState.ValidationRules[`SocialUser${i}`];
    }
  }

  socialTypes.splice(removeIndex, 1);

  socialTypes.forEach((socialType, index) => {
    newState.ActiveForm[`SocialTypeID${index}`] = socialType.SocialTypeID;
    newState.ActiveForm[`SocialUser${index}`] = socialType.SocialUser;
    newState.ValidationRules[`SocialTypeID${index}`] = getSocialTypeValidationRule(`SocialTypeID${index}`);
    newState.ValidationRules[`SocialUser${index}`] = getSocialUserValidationRule(`SocialUser${index}`, socialType);
  });

  return newState;
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const setContact = (rootState: ApplicationState, state: ContactFormState, contact: any, contactSocials: any[]) => {
  if (!contact) return state;
  const newState: ContactFormState = {
    ...state,
    ActiveForm: {
      ...contact,
      ...initSocialTypesActiveForm(rootState, contactSocials, contact.SocialTypes),
    },
    ValidationRules: {
      ...state.ValidationRules,
      ...getSocialMediasDefinition(rootState, contactSocials),
    }
  };
  return newState;
};

const ContactFormReducer: Reducer<ContactFormState> = (s, act: WithRootState<Action>) => {
  const state = checkApiValidation(s, act);
  
  if (act.type.includes(API_UPD) && act.type.includes(UPDATE_CMS_CONTACT_FORM_SUFFIX)) {
    if (shouldSkipUpdateForm(act)) return state;

    // init and set default
    const newState = setDefaults(act.rootState, getInitialState(act.rootState));

    // if there is contact loaded, load it from cache two to ActiveForm, and override default values
    if (act.rootState.adminCMSSite.cacheTwoContacts.CMSContact) {
      return setContact(act.rootState, newState, act.rootState.adminCMSSite.cacheTwoContacts.CMSContact, act.rootState.adminCMSSite.cacheTwoContacts.CMSContactSocials || []);
    }

    return newState;
  } else if (isActionType(act, RemoveSocial)) {
    const newState = {...state};
    return removeSocialTypeFromState(state, newState, act.contactSocialIndex);
  } else if (isActionType(act, UpdateSocialType)) {
    const newState = { ...state };
    const socialUserKey = act.vObj.key.replace('SocialTypeID', 'SocialUser');
    newState.ValidationRules[socialUserKey] = getSocialUserValidationRule(socialUserKey, {SocialTypeID: act.key, SocialUser: ''} as any);

    return newState;
  } else if (isActionType(act, AddSocial)) {
    let count = 0;
    for (let key of Object.keys(state.ActiveForm)) {
      if (key.includes('SocialTypeID')) {
        count += 1;
      }
    }
    const newState = { ...state };

    const socialTypes = getSocialTypesValue(act.rootState);
    newState.ActiveForm[`SocialID${count}`] = null;
    newState.ActiveForm[`SocialTypeID${count}`] = socialTypes && socialTypes.length > 0 ? socialTypes[0].ID : 1;
    newState.ActiveForm[`SocialUser${count}`] = '';
    newState.ValidationRules[`SocialTypeID${count}`] = getSocialTypeValidationRule(`SocialTypeID${count}`);
    newState.ValidationRules[`SocialUser${count}`] = getSocialUserValidationRule(`SocialUser${count}`, {SocialTypeID: socialTypes && socialTypes.length > 0 ? socialTypes[0].ID : 1, SocialUser: ''} as any);

    return newState;
  } else if (
    isActionType(act, ClearAdminCMSCacheTwoContact) ||
    isActionType(act, ClearAdminCMSSiteCacheBelowOne)
  ) {
    return getInitialState((act as WithRootState<AnyAction>).rootState);
  }

  return state || getInitialState();
};

export default ContactFormReducer;