import * as React from 'react';
import {Actions as AdminCMSCacheOneActions,
} from '../../../../../../store/AdminCMSSite/CacheOne/actions';
import { Actions as AppActions } from '../../../../../../store/App/actions';
import { Actions as RollbackActions } from "../../../../../../store/Rollback/actions";
import { Actions as PageFormActions } from "../../../../../../store/AdminCMSSite/Pages/Page/Form/actions";
import { Actions, ToggleFilterInvalid } from "../../../../../../store/AdminCMSSite/Pages/Home/actions";
import { AdminCMSSiteCacheOneState } from '../../../../../../store/AdminCMSSite/CacheOne';
import { EmptyMessage, Button, SimpleList } from '../../../../../Elements';
import { PageIcon } from '../../../../../Icons';
import { navPush, copyStringToClipboard, shallowEqual } from '../../../../../../utils';
import '../../../../../../styles/pages/cms-pages/home/index.scss';
import PagesFilters from './PagesFilters';
import { CMSPage, PageTypeIDValue } from '../../../../../../models/api/adminCMSCacheOne';
import CMSPageCard, { PageCardType } from '../../../../../../components/Elements/CMSPage/Card';
import { getEditPageRootUrl, constructCMSSiteEditPageUrlParams } from '../../../../../../constants/urls';
import { getPageUrl, checkPagePermission } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { ENTITY_NOT_FOUND, PAGES, ENTITY_NOT_ADDED, BLOG_ARTICLES, CALENDAR_EVENTS, DISTRICTS, EVENT_MODULES, FACILITY_MODULES, STANDARD_PAGES, EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE } from '../../../../../../constants/messages/adminCMS';
import { PageFormState } from '../../../../../../store/AdminCMSSite/Pages/Page/Form';
import { LINK_COPIED } from '../../../../../../constants/messages/generic';
import { EmptyMessageType } from '../../../../../../components/Elements/EmptyMessage';
import { CardCategory } from '../../../../../../components/Elements/Card';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { PagesHomeState } from '../../../../../../store/AdminCMSSite/Pages/Home/index.types';
import { RouteComponentProps, withRouter } from 'react-router';
import { reduxStoreService } from '../../../../../../store/service';

export const namespace = (): string => 'pages--cms--pages';

type ConnectedProps = RouteComponentProps<{}, {}>;

export interface Props {
  adminCMSCacheOne: AdminCMSSiteCacheOneState;
  actions: Actions & AppActions & AdminCMSCacheOneActions & RollbackActions;
  pageFormActions: PageFormActions;
  apiSaving: number;
  apiLoading: number;
  apiLoadingMap: any;
  pagesHome: PagesHomeState;
  pageForm?: PageFormState;
  filteredPages: CMSPage[];
  activePages: CMSPage[];
  disabled?: boolean;
  compact?: boolean;
  context: 'edit-page' | 'page-list';
  cardCategory?: CardCategory;
  filterInvalid: boolean;
}

class PageList extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  componentWillUnmount() {
  }

  shouldComponentUpdate(nextProps, nextState) {
    const _thisProps = {...this.props};
    const _nextProps = {...nextProps};

    const propsToCompare = ['adminCMSCacheOne', 'apiSaving', 'apiLoading', 'apiLoadingMap', 'pagesHome', 'filteredPages', 'activePages', 'disabled', 'params'];
    let result = false;
    for (let key of propsToCompare) {
      result = result || !shallowEqual(_thisProps[key], _nextProps[key]);

    }

    return result || !shallowEqual(nextState, this.state);
  }

  onNewPage = () => {
    checkPagePermission(
      () => {
        this.props.actions.pushNewPageModal();
      },
      this.props.adminCMSCacheOne,
    );
  };

  onClickPageCard = (page: CMSPage) => {
    this.onEditPage(page);
  };
  onRestorePage = (page: CMSPage) => {
    if (this.props.pageForm && this.props.pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    checkPagePermission(
      () => {
        this.props.pageFormActions.deletePage(page.ID, this.props.router, this.props.routes, true);
      },
      this.props.adminCMSCacheOne,
      page.ID,
    );
  };
  onDeletePage = (page: CMSPage) => {
    if (this.props.pageForm && this.props.pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    checkPagePermission(
      () => {
        this.props.pageFormActions.deletePage(page.ID, this.props.router, this.props.routes);
      },
      this.props.adminCMSCacheOne,
      page.ID,
    );
  };
  
  onPreviewPage = (page: CMSPage) => {
    const {adminCMSCacheOne} = this.props;
    const url = getPageUrl(page, adminCMSCacheOne);
    window.open(url);
  };
  onEditPage = (page: CMSPage) => {
    if (this.props.pageForm && this.props.pageForm.processingImages) {
      this.props.actions.showTopFloatingAlert(EDIT_PAGE_NO_ACTIONS_WHEN_PROCESSING_IMAGE('leaving this screen'), undefined, 'orange');
      return;
    }
    if (page.Inactive) return;
    const {adminCMSCacheOne, router} = this.props;
    const url = getEditPageRootUrl(constructCMSSiteEditPageUrlParams(this.props, adminCMSCacheOne, page));
    navPush(router, url);
  };
  onCopyLink = (page: CMSPage) => {
    const {adminCMSCacheOne} = this.props;
    const url = getPageUrl(page, adminCMSCacheOne);
    copyStringToClipboard(url);
    this.props.actions.showSnackbarItem(LINK_COPIED);
  };

  filteredPagesRenderer = (index) => {
    const {filteredPages, adminCMSCacheOne, compact, params, routes, cardCategory, context} = this.props;
    const page = filteredPages[index];
    const key = `filtered_page_${index}`;
    if (!page) return <div key={key} style={{height: '284px'}} />;
    const pageType = adminCMSCacheOne.PageTypes ? adminCMSCacheOne.PageTypes.find((p) => p.ID === page.PageTypeID) : null;
    return (
        <div key={key}>
          <CMSPageCard
            type={PageCardType.LIST}
            page={page}
            params={params}
            onRestore={this.onRestorePage}
            adminCMSCacheOne={adminCMSCacheOne}
            onClick={this.onClickPageCard}
            onDelete={this.onDeletePage}
            onPreview={this.onPreviewPage}
            onCopyLink={this.onCopyLink}
            onEdit={this.onEditPage}
            pageTypeName={pageType ? pageType.Name : ''}
            compact={compact}
            routes={routes}
            context={context}
            cardCategory={cardCategory || CardCategory.LIST}
          />
        </div>
    );
  };

  renderPagesContent = () => {
    const {filteredPages} = this.props;
    return (
      <div className={`${namespace()}--list-wrapper`}>
        <SimpleList
          itemRenderer={this.filteredPagesRenderer}
          totalLength={filteredPages.length}
          threshold={200}
        />
      </div>
    );
  };

  getEmptymessage = (filter?: boolean) => {
    const {adminCMSCacheOne} = this.props;

    const pageId = adminCMSCacheOne.CMSPagesFilters ? adminCMSCacheOne.CMSPagesFilters.PageTypeID : 0;

    switch(pageId) {
      case PageTypeIDValue.ALL:
        // WONT HAPPEN, but still put it here
        return filter ? ENTITY_NOT_FOUND(PAGES) : ENTITY_NOT_ADDED(PAGES);
      case PageTypeIDValue.BLOG_ARTICLE:
        return filter ? ENTITY_NOT_FOUND(BLOG_ARTICLES) : ENTITY_NOT_ADDED(BLOG_ARTICLES);
      case PageTypeIDValue.CALENDAR_EVENT:
        return filter ? ENTITY_NOT_FOUND(CALENDAR_EVENTS) : ENTITY_NOT_ADDED(CALENDAR_EVENTS);
      case PageTypeIDValue.DISTRICT:
        return filter ? ENTITY_NOT_FOUND(DISTRICTS) : ENTITY_NOT_ADDED(DISTRICTS);
      case PageTypeIDValue.EVENT_MODULES:
        return filter ? ENTITY_NOT_FOUND(EVENT_MODULES) : ENTITY_NOT_ADDED(EVENT_MODULES);
      case PageTypeIDValue.FACILITY_MODULES:
        return filter ? ENTITY_NOT_FOUND(FACILITY_MODULES) : ENTITY_NOT_ADDED(FACILITY_MODULES);
      case PageTypeIDValue.STANDARD_PAGE:
        return filter ? ENTITY_NOT_FOUND(STANDARD_PAGES) : ENTITY_NOT_ADDED(STANDARD_PAGES);
    }
  };
  
  renderEmptyMessage = () => {
    const {filteredPages, activePages} = this.props;

    let emptyMessage;

    // when empty, there will always be a null object in `filteredPages` array
    if (((!filteredPages || filteredPages.length === 1) && activePages.length > 0) || this.props.filterInvalid) {
      // filter case
      emptyMessage = this.getEmptymessage(true);
    } else if ((!filteredPages || filteredPages.length === 1) && activePages.length === 0) {
      // new page case
      emptyMessage = this.getEmptymessage();
    }

    return emptyMessage ? (
      <EmptyMessage
        icon={PageIcon}
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={emptyMessage} 
        actions={<Button className={`${namespace()}--empty-message-btn`} color="green" onClick={this.onNewPage}>NEW PAGE</Button>}/>
    ) : null;
  };

  onFilterClick = () => {
    this.props.actions.toggleExpandFilter(!this.props.pagesHome.expandFilter);
  };

  public render() {
    const {routes, location, params, compact, actions, adminCMSCacheOne, pagesHome, disabled, apiLoadingMap, apiSaving, context, pageForm} = this.props;
    const thisRoute = routes[routes.length - 1];
    const emptyMessage = this.renderEmptyMessage();

    const selectedPageID = pagesHome.ActiveForm.PageTypeID || 0;

    return (
      <div style={{height: '100%'}}>
          <div className={`${namespace()}--list-actions`}>
            <PagesFilters
              compact={compact}
              pageForm={pageForm}
              pagesHome={pagesHome}
              adminCMSCacheOne={adminCMSCacheOne}
              selectedPageID={selectedPageID}
              disabled={disabled}
              reduxActions={actions}
              expand={pagesHome.expandFilter}
              context={context}
              onValidate={(invalid: boolean) => reduxStoreService().dispatch(new ToggleFilterInvalid(invalid))}
              control={
                <Button id={generateDOMId("new-page-btn")} className='control' color='green' textColor='white' onClick={this.onNewPage}>New</Button>
              }
            />
          </div>
          <div className={`${namespace()}--content-wrapper`}>
            {!emptyMessage && this.renderPagesContent()}
            {emptyMessage}
          </div>
      </div>
    );
  }
}

export default withRouter(PageList);
