import {makeRequestEpic} from "../../../../Validation/epicCreator";
import {UpdateAdminEventTypeSubmitActions, DeleteAdminEventTypeSubmitActions} from "./actions";
import {AjaxResponse} from "rxjs/ajax";
import { ENTITY_CREATED, ENTITY_EDITED, ENTITY_PARSE_ERROR, ENTITY_DELETED, ENTITY_RESTORED } from "../../../../../constants/messages/adminCMS";
import { getUpdateAdminEventType } from "../../../../../constants/adminEventsUrls";
import { ADMIN_EVENT_TYPE } from "../../../../../constants/messages/adminEvents";

export const adminEventsUpdateEventTypeEpic = makeRequestEpic(
  UpdateAdminEventTypeSubmitActions,
  getUpdateAdminEventType,
  undefined,
  true,
  (response: AjaxResponse) => {
    return ENTITY_EDITED(ADMIN_EVENT_TYPE);
  },
);

export const adminEventsDeleteEventTypeEpic = makeRequestEpic(
  DeleteAdminEventTypeSubmitActions,
  getUpdateAdminEventType,
  undefined,
  true,
  (response: AjaxResponse) => {
    const EventType = response.response.EventsEventType;

    if (EventType.EventTypeRow.Inactive) {
      return ENTITY_DELETED(ADMIN_EVENT_TYPE);
    }
    return ENTITY_RESTORED(ADMIN_EVENT_TYPE);
  },
);