import { GetOptions, UnselectGroup } from "../../store/CacheZero/actions";
import { SaveState } from "../../store/Rollback/actions";
import { reduxStoreService } from "../../store/service";
import {isCacheZeroPopulated} from "../cachePopulatedCheckers/endUser";
import { LoadCacheZeroParams } from "./helpers/models";

export const ensureCacheZero = (
    params: LoadCacheZeroParams,
): boolean => {
    const {isStateNavigated} = params;
    const rootState = reduxStoreService().getState();

    if (!isCacheZeroPopulated(rootState.cacheZero)) {
        reduxStoreService().dispatch(GetOptions.request({}));
        return true;
    } else if (isStateNavigated) {
        if (rootState.cacheZero.options && rootState.cacheZero.options.Group) {
            reduxStoreService().dispatch(new UnselectGroup());
        }
        // `ensureCacheZero` is only called when in AddGroup, if cacheZero
        // is already populated, always init AddGroup form
        reduxStoreService().dispatch(GetOptions.updateForm());
        reduxStoreService().dispatch(new SaveState());
    }

    return false;
};