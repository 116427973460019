import {IValidator, FormDefinition} from "./validation";
import { apiValidationReducerCreator } from "../../../../store/Validation/reducerCreator";
import { SUFFIX } from "./actions";
import * as Actions from "./actions";
import { ClearAllAdminCMSSiteCache, ADMIN_CMS_MODULE_SUFFIX } from "../../CacheOne/actions";
import { ApplicationState } from "../../../../store";
import { createSelector } from "reselect";
import { CMSSiteMenuItem, CMSPageMenuItem } from "../../../../models/api/adminCMSCacheOne";
import { APISuccess, SUCCESS_ACTION } from "../../../../store/Validation/actionCreator";
import { AddSiteMenuItemSubmitActions, DeleteSiteMenuItemSubmitActions, AddPageMenuItemSubmitActions, DeletePageMenuItemSubmitActions } from "../SiteMenuItem/Form/actions";
import { ClearAllCache, PopModal, SetCacheAction } from "../../../../store/App/actions";
import { EditPageUpdateSiteMenuItemSubmitActions } from "../../Pages/Page/Form/actions";
import { ModalAddPageMenuItemSubmitActions } from "../Modals/PageMenuItem/actions";
import { listSelector } from "../../../../utils/reselectHelper";
import { moveItemInArrayWithOffset } from "../../../../utils/dataHelper";
import { ModalTypes } from "../../../../utils/modalHelper";
import { Reducer } from "redux";
import { isActionType } from "../../../../utils/StrongActions";
import { setDefaults } from "../../../../utils/validator";

export interface MenusHomeState {
  ActiveForm: MenusHomeActiveForm;
  ValidationRules: IValidator;
  expandSiteMenuFilter: boolean;
  expandPageMenuFilter: boolean;
  siteMenus: CMSSiteMenuItem[];
  orderedPageMenu?: {[key: string]: CMSPageMenuItem[]};
  orderedSiteMenus?: Array<CMSSiteMenuItem[]>;
  orderingSiteMenu: boolean;
  orderingPageMenu: boolean;
  pageMenus: CMSPageMenuItem[];
  originalPageMenus: CMSPageMenuItem[];
  deletingSiteMenuItem?: CMSSiteMenuItem;
  deletingPageMenuItem?: CMSPageMenuItem;
}

export interface MenusHomeActiveForm {
  SiteMenusShowDeleted?: boolean;
  SiteMenusFilterText?: string;
  PageMenusShowDeleted?: boolean;
  PageMenusFilterText?: string;
}

const getInitialState = (): MenusHomeState => ({
  ActiveForm: {},
  ValidationRules: {...FormDefinition},
  expandSiteMenuFilter: false,
  expandPageMenuFilter: false,
  siteMenus: [],
  orderingSiteMenu: false,
  orderingPageMenu: false,
  orderedPageMenu: undefined,
  orderedSiteMenus: undefined,
  pageMenus: [],
  originalPageMenus: [],
  deletingSiteMenuItem: undefined,
});

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const siteMenuShowDeletedSelector = (state: ApplicationState) => {
  return state.adminCMSSite.menus.home.ActiveForm.SiteMenusShowDeleted;
};

const siteMenuFilterTextSelector = (state: ApplicationState) => {
  return state.adminCMSSite.menus.home.ActiveForm.SiteMenusFilterText;
};

const _siteMenusSelector = (state: ApplicationState) => {
  return state.adminCMSSite.menus.home.siteMenus;
};
export const siteMenusSelector = listSelector(_siteMenusSelector);

export const activeSiteMenusSelector = listSelector(_siteMenusSelector, true);

export const makeFilteredSiteMenusSelector = () => {
  return createSelector(
    [siteMenusSelector, siteMenuShowDeletedSelector, siteMenuFilterTextSelector],
    (siteMenuItems: CMSSiteMenuItem[], showDeleted: boolean, filterText: string) => {
      if (!siteMenuItems) return [];
      const menuItemMap: {[key: string]: CMSSiteMenuItem[]} = {};

      // filter deleted first
      const filteredDeleted = siteMenuItems.filter((item) => {
        return showDeleted || !item.Inactive;
      });

      // filter text next
      if (filterText) {
        const f = filterText.toLowerCase();
        filteredDeleted.forEach((item) => {
          if (item.Name.toLowerCase().includes(f)) {
            if (!item.ParentID) {
              // parent item, include all child items
              menuItemMap[item.ID.toString()] = [{...item, Expand: true}];
              filteredDeleted.forEach((child) => {
                if (child.ParentID === item.ID) {
                  menuItemMap[item.ID.toString()].push(child);
                }
              });
            } else {
              // child item, include it under its parent item
              const parentItem = filteredDeleted.find((i) => i.ID === item.ParentID);
              if (parentItem && !menuItemMap[parentItem.ID.toString()]) {
                // if parent item not exist, append both
                menuItemMap[parentItem.ID.toString()] = [{...parentItem, Expand: true}, item];
              } else if (parentItem && menuItemMap[parentItem.ID.toString()]) {
                // if parent item already exist, append the child item if its not there yet
                const existed = menuItemMap[parentItem.ID.toString()].find((child) => child.ID === item.ID);

                if (!existed) {
                  menuItemMap[parentItem.ID.toString()].push(item);
                }
              }
            }
          }
        });
      } else {
        filteredDeleted.forEach((item) => {
          if (!item.ParentID && !menuItemMap[item.ID.toString()]) {
            // parent
            menuItemMap[item.ID.toString()] = [item];
          } else if (menuItemMap[item.ParentID.toString()]) {
            // child
            menuItemMap[item.ParentID.toString()].push({
              ...item,
            });
          }
        });
      }
      let result: Array<CMSSiteMenuItem[]> = [];

      // Sort each level
      for (let key of Object.keys(menuItemMap)) {
        menuItemMap[key] = menuItemMap[key].sort((a, b) => {
          if (!a.ParentID) return -1;
          if (!b.ParentID) return 1;
          return a.Ord < b.Ord ? -1 : (a.Ord === b.Ord ? 0 : 1);
        });
        result.push(menuItemMap[key]);
      }

      result.sort((a, b) => {
        return a[0].Ord < b[0].Ord ? -1 : (a[0].Ord === b[0].Ord ? 0 : 1);
      });

      return result;
    });
};

const pageMenuShowDeletedSelector = (state: ApplicationState) => {
  return state.adminCMSSite.menus.home.ActiveForm.PageMenusShowDeleted;
};

const pageMenuFilterTextSelector = (state: ApplicationState) => {
  return state.adminCMSSite.menus.home.ActiveForm.PageMenusFilterText;
};

const _pageMenusSelector = (state: ApplicationState) => {
  return state.adminCMSSite.menus.home.pageMenus;
};

export const pageMenusSelector = listSelector(_pageMenusSelector);
export const activePageMenusSelector = listSelector(_pageMenusSelector, true);

// @todo - should refactor this with SiteMenusSelector
export const makeFilteredPageMenusMapSelector = () => {
  return createSelector(
    [pageMenusSelector, pageMenuShowDeletedSelector, pageMenuFilterTextSelector],
    (pageMenuItems: CMSPageMenuItem[], showDeleted: boolean, filterText: string) => {
      if (!pageMenuItems) return [];
      const menuItemMap: {[key: string]: CMSPageMenuItem[]} = {};

      // filter deleted first
      const filteredDeleted = pageMenuItems.filter((item) => {
        return showDeleted || !item.Inactive;
      });

      // filter text next
      if (filterText) {
        const f = filterText.toLowerCase();
        filteredDeleted.forEach((item) => {
          if (item.Name.toLowerCase().includes(f)) {
            if (!item.ParentID) {
              // parent item, include all child items
              menuItemMap[item.ID.toString()] = [{...item, Expand: true}];
              filteredDeleted.forEach((child) => {
                if (child.ParentID === item.ID) {
                  menuItemMap[item.ID.toString()].push(child);
                }
              });
            } else {
              // child item, include it under its parent item
              const parentItem = filteredDeleted.find((i) => i.ID === item.ParentID);
              if (parentItem && !menuItemMap[parentItem.ID.toString()]) {
                // if parent item not exist, append both
                menuItemMap[parentItem.ID.toString()] = [{...parentItem, Expand: true}, item];
              } else if (parentItem && menuItemMap[parentItem.ID.toString()]) {
                // if parent item already exist, append the child item if its not there yet
                const existed = menuItemMap[parentItem.ID.toString()].find((child) => child.ID === item.ID);

                if (!existed) {
                  menuItemMap[parentItem.ID.toString()].push(item);
                }
              }
            }
          }
        });
      } else {
        filteredDeleted.forEach((item) => {
          if (!item.ParentID && !menuItemMap[item.ID.toString()]) {
            // parent
            menuItemMap[item.ID.toString()] = [item];
          } else if (item.ParentID && menuItemMap[item.ParentID.toString()] && menuItemMap[item.ParentID.toString()][0]) {
            // child and its parent already recorded
            menuItemMap[item.ParentID.toString()].push({
              ...item,
            });
          } else {
            // child and its parent is not recorded
              const parentItem = filteredDeleted.find((i) => i.ID === item.ParentID);
              if (parentItem) {
                menuItemMap[parentItem.ID.toString()] = [{...parentItem}, item];
              }
          }

        });
      }
      // Sort each level
      for (let key of Object.keys(menuItemMap)) {
        menuItemMap[key] = menuItemMap[key].sort((a, b) => {
          if (!a.ParentID) return -1;
          if (!b.ParentID) return 1;
          return a.Ord < b.Ord ?  -1 : (a.Ord === b.Ord ? 0 : 1);
        });
      }

      // Sort all parent items - @todo - better approach?
      let sortable: any[] = [];
      for (let key of Object.keys(menuItemMap)) {
        sortable.push(menuItemMap[key]);
      }

      sortable = sortable.sort((a, b) => a[0].Ord < b[0].Ord ?  -1 : (a[0].Ord === b[0].Ord ? 0 : 1));

      const objSorted = {};
      sortable.forEach(function(item){
          objSorted[item[0].Ord.toString()] = item;
      });

      return objSorted;
    });
};

const handleExpandMenu = (siteMenuList: CMSSiteMenuItem[], targetMenuId: number) => {
  return siteMenuList.map((s) => {
    if (s.ID === targetMenuId) {
      return {
        ...s,
        Expand: !s.Expand,
      };
    }
    return s;
  });
};

const configMenuExpand = (old: CMSSiteMenuItem[], next: CMSSiteMenuItem[], newPageItem?: CMSPageMenuItem) => {
  return next.map((item) => {
    const found = old.find((oldItem) => oldItem.ID === item.ID);

    if (found && found.Expand) {
      // dont reset those already expand menu items
      return {
        ...item,
        Expand: true,
      };
    } else if (newPageItem && !newPageItem.ParentID && item.ID === newPageItem.ID) {
      // newly created page menu group, should expand
      return {
        ...item,
        Expand: true,
      };
    } else {
      return item;
    }
  });
};

const MenusHomeReducer: Reducer<MenusHomeState> = (s, act: any) => {
  const state = checkApiValidation(s, act);
  if ((act.type.includes(ADMIN_CMS_MODULE_SUFFIX) && act.type.includes(SUCCESS_ACTION)) || act.type === AddSiteMenuItemSubmitActions.successType || act.type === AddPageMenuItemSubmitActions.successType || act.type === ModalAddPageMenuItemSubmitActions.successType || act.type === DeleteSiteMenuItemSubmitActions.successType || act.type === DeletePageMenuItemSubmitActions.successType || act.type === Actions.SubmitSiteMenuOrderActions.successType || act.type === Actions.SubmitPageMenuOrderActions.successType || act.type === EditPageUpdateSiteMenuItemSubmitActions.successType || isActionType(act, SetCacheAction)) {
    const action = <APISuccess> act;
    const res = action.response.xhr.response;
    const menuItems = res.CMSSiteMenuItems as CMSSiteMenuItem[];
    const pageItems = res.CMSPageMenuItems as CMSPageMenuItem[];
    const siteMenuItem = res.CMSSiteMenuItem as CMSSiteMenuItem;
    const pageMenuItem = res.CMSPageMenuItem as CMSPageMenuItem;

    let siteMenus = !menuItems ? state.siteMenus : configMenuExpand(state.siteMenus, menuItems);
    let pageMenus = !pageItems ? state.pageMenus : configMenuExpand(state.pageMenus, pageItems, res.CMSPageMenuItem);

    if (siteMenuItem) {
      siteMenus = siteMenus.map((item) => {
        if (item.ID === siteMenuItem.ID) {
          return {
            ...siteMenuItem,
            Ord: item.Ord,
          };
        }
        return item;
      });
    }
    if (pageMenuItem) {
      pageMenus = pageMenus.map((item) => {
        if (item.ID === pageMenuItem.ID) {
          return {
            ...pageMenuItem,
            Ord: item.Ord,
          };
        }
        return item;
      });
    }
    return {
      ...state,
      siteMenus,
      pageMenus,
      originalPageMenus: !pageItems ? state.siteMenus : pageItems,
      orderingSiteMenu: act.type === Actions.SubmitSiteMenuOrderActions.successType ? false : state.orderingSiteMenu,
      orderedSiteMenus: act.type === Actions.SubmitSiteMenuOrderActions.successType ? undefined : state.orderedSiteMenus,
      orderingPageMenu: act.type === Actions.SubmitPageMenuOrderActions.successType ? false : state.orderingPageMenu,
      orderedPageMenu: act.type === Actions.SubmitPageMenuOrderActions.successType ? undefined : state.orderedPageMenu,
    };
  } else if (isActionType(act, Actions.ToggleExpandFilterAction)) {
    if (act.target === 'pagemenu') {
      return {...state, expandPageMenuFilter: act.expand};
    }
    return {...state, expandSiteMenuFilter: act.expand};
  } else if (isActionType(act, ClearAllAdminCMSSiteCache) || isActionType(act, ClearAllCache)) {
    return setDefaults(act.rootState, getInitialState());
  } else if (isActionType(act, Actions.ConfigDeleteSiteMenuItemModal)) {
    return {
      ...state,
      deletingSiteMenuItem: act.item,
    };
  } else if (isActionType(act, Actions.ConfigDeletePageMenuItemModal)) {
    return {
      ...state,
      deletingPageMenuItem: act.item,
    };
  } else if (isActionType(act, PopModal) && act.modal === ModalTypes.DELETE_MENU_ITEM) {
    return {
      ...state,
      deletingSiteMenuItem: null,
      deletingPageMenuItem: null,
    };
  } else if (isActionType(act, Actions.CancelReorderMenuItemAction)) {
    return {
      ...state,
      orderedSiteMenus: undefined,
      orderedPageMenu: undefined,
      orderingSiteMenu: false,
      orderingPageMenu: false,
    };
  } else if (isActionType(act, Actions.MoveSiteMenuItemAction)) {
    const siteMenusSelector = makeFilteredSiteMenusSelector();
    let arr = (state.orderedSiteMenus || siteMenusSelector(act.rootState)) as Array<CMSSiteMenuItem[]>;

    if (act.isParent) {
      // parent case
      const parentIndex = arr.findIndex((g) => g[0].ID === act.item[0].ID);
      
      moveItemInArrayWithOffset(arr, parentIndex, act.offset);
    } else [
      // child case
      arr = arr.map((g) => {
        const childIndex = g.findIndex((item) => item.ID === act.item.ID);

        if (childIndex !== -1) {
          moveItemInArrayWithOffset(g, childIndex, act.offset);
        } 

        return g;
      })
    ];

    return {
      ...state,
      orderedSiteMenus: arr,
      orderingSiteMenu: true,
    };
  } else if (isActionType(act, Actions.MovePageMenuItem)) {
    const pageMenusSelector = makeFilteredPageMenusMapSelector();
    const map = (state.orderedPageMenu || pageMenusSelector(act.rootState)) as {[key: string]: CMSPageMenuItem[]};

    for (let key of Object.keys(map)) {
      const index = map[key].findIndex((item) => item.ID === act.item.ID);
      if (index !== -1) {
        moveItemInArrayWithOffset(map[key], index, act.offset);
      }
    }
    return {
      ...state,
      orderedPageMenu: {...map},
      orderingPageMenu: true,
      ActiveForm: {
        ...state.ActiveForm,
      }
    };
  } else if (isActionType(act, Actions.MenusHomeReset)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SiteMenusFilterText: '',
        PageMenusFilterText: '',
      },
      orderedSiteMenus: undefined,
      orderedPageMenu: undefined,
      orderingPageMenu: false,
      orderingSiteMenu: false,
    };
  } else if (isActionType(act, Actions.ExpandSiteMenuAction)) {
    if (!state.orderingSiteMenu) {
      return {
        ...state,
        siteMenus: handleExpandMenu(state.siteMenus, act.menuId),
      };
    } else {
      return {
        ...state,
        orderedSiteMenus: state.orderedSiteMenus.map((g) => {
          return handleExpandMenu(g, act.menuId);
        }),
      };
    }
  } else if (isActionType(act, Actions.ExpandPageMenuAction)) {
    if (!state.orderingPageMenu) {
      return {
        ...state,
        pageMenus: handleExpandMenu(state.pageMenus, act.menuId),
      };
    } else {
      for (let key of Object.keys(state.orderedPageMenu)) {
        state.orderedPageMenu[key] = handleExpandMenu(state.orderedPageMenu[key], act.menuId);
      }
      return {
        ...state,
        orderedPageMenu: {...state.orderedPageMenu},
      };
    }
  }
  return state || setDefaults(act.rootState, getInitialState());
};

export default MenusHomeReducer;