import { Reducer } from 'redux';
import { Action, isActionType } from '../../../../../../utils/StrongActions';
import * as Actions from './actions';

export interface EventRegisterProductsState {
  selectedPage: 'products' | 'confirmation';
  pendingPage?: 'products' | 'confirmation';
}
const getInitialState: () => EventRegisterProductsState = () => ({selectedPage: 'products'});

const EventRegisterProducts: Reducer<EventRegisterProductsState> = (state: EventRegisterProductsState, action: Action) => {
  if (isActionType(action, Actions.EventRegisterProductsSelectPage)) {
    window.scrollTo(0,0);
    return {...state, selectedPage: action.selectedPage};
  } else if (isActionType(action, Actions.EventRegisterProductsSetPendingPage)) {
    return {...state, pendingPage: action.pendingPage};
  } else if (isActionType(action, Actions.EventRegisterProductsTransitionToPending)) {
    if (state.pendingPage) {
      window.scrollTo(0,0);
      return {...state, selectedPage: state.pendingPage, pendingPage: undefined};
    }
    return state;
  } else if (isActionType(action, Actions.EventRegisterProductsReset)) {
    return getInitialState();
  }
  return state || getInitialState();

};

export default EventRegisterProducts;
