import {RestoreState} from "../../store/Rollback/actions";
import { reduxStoreService } from "../../store/service";

export const getTotalAmount = (
  ActiveForm: {
    selectedFinance?: 'min' | 'full' | 'other' | 'credit',
    otherValue?: number
  },
  EventRegistrationPaymentStatus: {AmountMin: number; AmountMax: number;}
): undefined | number => {
  if (!ActiveForm.selectedFinance) {
    return undefined;
  } else if (ActiveForm.selectedFinance === 'min') {
    return EventRegistrationPaymentStatus.AmountMin;
  } else if (ActiveForm.selectedFinance === 'credit') {
    return 0;
  } else if (ActiveForm.selectedFinance === 'full') {
    return EventRegistrationPaymentStatus.AmountMax;
  } else if (ActiveForm.selectedFinance === 'other' && ActiveForm.otherValue !== undefined) {
    return ActiveForm.otherValue;
  }
};

const isClickInFS = (eTarget: any): boolean => {
  if (eTarget.classList && eTarget.classList.value && eTarget.classList.value.includes('elements--financial-summary')) {
    return true;
  }
  if (eTarget.parentElement) {
    return isClickInFS(eTarget.parentElement);
  }
  return false;
};

export const rollbackIfClickOut = (e) => {
  let inFS = false;
  // if (e.path) {
  //   // this code only works in Chrome, I can probably delete it and use the code below on all browsers including chrome
  //   inFS = !!e.path.find((dom: any) => {
  //     if (!dom || !dom.classList) return false;
  //     return (
  //       dom.classList.value.includes('elements--financial-summary')
  //     );
  //   });
  // } else {
    inFS = isClickInFS(e.target);
  // }


  if (!inFS) {
    /**
     * Mainly for Financial Summary - switch back to previous selection when clicking outside (for example, when selecting pay other amount)
     * This is the simplest implementation, and so we keep using RestoreState here..
     */
    reduxStoreService().dispatch(new RestoreState());
  }
};
