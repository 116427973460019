import * as React from 'react';
import { findDOMNode } from 'react-dom';
import {mergeClassNames} from '@tentaroo/shared';

import { whichAnimationEvent } from '../../../utils';
import '../../../styles/elements/slide-in/index.scss';
import {Nullable} from '../../../utils/dataHelper';

export const namespace = (): string => 'elements--slide-in';

export interface SlideInProps {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onClose?: () => any;
  open?: boolean;
  component?: React.ReactElement<any> | string;
  tablet?: boolean;
  mobile?: boolean;
  right?: boolean;
  forceShowDesktop?: boolean;
  forceShowLargeDesktop?: boolean;
}

type SlideInState = {
  enter?: boolean;
  closed?: boolean;
  hiddenOnEnter?: boolean;
}

class SlideIn extends React.Component<SlideInProps, SlideInState> {
  public props: SlideInProps;
  public state: SlideInState = {
    enter: true,
    closed: false,
    hiddenOnEnter: false
  };

  public componentWillMount() {
    const { open } = this.props;
    if (open !== undefined && open !== null) {
      if (!open) this.setState({ enter: false, hiddenOnEnter: true });
      else this.setState({ hiddenOnEnter: true });
    }
  }

  public componentDidMount() {
    const { open } = this.props;
    if (open === undefined || open === null || (open && !this.state.hiddenOnEnter)) {
      this.open();
    }
  }

  public componentWillUpdate(nextProps) {
    if (nextProps.open !== this.props.open) {
      if (nextProps.open) this.open();
      else this.close();
    }
  }

  private open = () => {
    const whichEvent: string | undefined = whichAnimationEvent();
    if (whichEvent) {
      const node = findDOMNode(this) as Nullable<Element>;
      node && node.removeEventListener(whichEvent, this.onCloseAnimationEnd);
      node && node.removeEventListener(whichEvent, this.onOpenAnimationEnd);
      node && node.addEventListener(whichEvent, this.onOpenAnimationEnd);
    }
    this.setState({ hiddenOnEnter: false, enter: true, closed: false });
  };

  private close = () => {
    const whichEvent: string | undefined = whichAnimationEvent();
    if (whichEvent) {
      const node = findDOMNode(this) as Nullable<Element>;
      node && node.removeEventListener(whichEvent, this.onOpenAnimationEnd);
      node && node.removeEventListener(whichEvent, this.onCloseAnimationEnd);
      node && node.addEventListener(whichEvent, this.onCloseAnimationEnd);
    }
  };

  public onOpenAnimationEnd = () => {
    const whichEvent: string | undefined = whichAnimationEvent();
    if (whichEvent) {
      const node = findDOMNode(this) as Nullable<Element>;
      node && node.removeEventListener(whichEvent, this.onOpenAnimationEnd);
    }
    this.setState({ enter: false });
  };

  public onCloseAnimationEnd = () => {
    const whichEvent: string | undefined = whichAnimationEvent();
    if (whichEvent) {
      const node = findDOMNode(this) as Nullable<Element>;

      node && node.removeEventListener(whichEvent, this.onCloseAnimationEnd);
    }
    this.setState({ closed: true });
    if (this.props.onClose) this.props.onClose();
  };

  public render() {
    const { children, component, tablet, mobile, open, right, style, forceShowDesktop, forceShowLargeDesktop } = this.props;
    const { enter, hiddenOnEnter, closed } = this.state;

    let className = mergeClassNames(namespace(), this.props);
    if (right) className += ' right';
    if (hiddenOnEnter && !open) className += ' hidden';
    if (hiddenOnEnter && open) className += ' open';
    else if (closed) className += ' closed';
    else if (!open) className += ' leave';
    else if (enter) className += ' enter';

    if (tablet || mobile) {
      className += ' hide-all-except';
      if (mobile) className += ' show-mobile';
      if (tablet) className += ' show-tablet';
    }

    if (forceShowDesktop) className += ' force-show-desktop';
    if (forceShowLargeDesktop) className += ' force-show-large-desktop';

    if (typeof component === 'string') {
      return React.createElement(component, {
        className: className
      }, children);
    } else if (component && React.isValidElement(component)) {
      let nextComponent: React.ReactElement<any> = component;
      return React.cloneElement(nextComponent, {
        ...nextComponent.props,
        className: className + (nextComponent.props.className ? ` ${nextComponent.props.className}` : '')
      });
    }

    return (
      <div className={className} style={style}>
        {children}
      </div>
    );
  }
}

export default SlideIn;
