import * as React from 'react';
import {Button, Card, Column, CardPadding, CardTitle, Text, Row, Align, Icon, Vignette, Media} from '../../../../Elements';
import {CartIcon} from '../../../../Icons';
import '../../../../../styles/pages/facilities/trip/summary/facility.scss';
import {getImagePath} from "../../../../../utils";
import {FacilityImage} from "../../../../../models/api/cacheTwoFacilties";
import { generateDOMId } from '../../../../../utils/cypressHelper';

const namespace = (): string => 'pages--facilities--trip--summary--facility';

interface Props {
  image?: FacilityImage;
  color?: string;
  name: string;
  subTitle: string;
  checkIn: string;
  checkOut: string;
  price?: string;
  InCart: 1 | 0 | -1;
  IsFacilityType: boolean;
  onClick?: () => void
  onRestore?: () => void;
  id?: string;
}


const Facility: React.SFC<Props> = (
  { image, color, id, name, subTitle, InCart, checkIn, checkOut, price, IsFacilityType, onClick, onRestore }: Props
): React.ReactElement<Props> => {

  return <Card id={id} padding="none" layout="horizontal" onClick={InCart !== -1 ? onClick : undefined} template="mobile-no-margin" className={namespace()}>
    <Vignette
      width={180}
      mobileWidth={80}
      image={image ? getImagePath(image as any) : undefined}
      color={color}
      name={name}
      topLeftBorderRadius
      bottomLeftBorderRadius
      className={`${namespace()}--image ${InCart === -1 ? 'disabled' : ''}`}
    />
    <Column verticalAlignment="top" expand gutter={false} className={`${namespace()}--details ${InCart === -1 ? 'disabled' : ''}`}>
      <CardPadding style={{width: "100%"}} layout="vertical" padding="small">
        <CardTitle size={18} className={`${namespace()}--details--name ${InCart === 1 ? 'in-cart': ''}`} marginBottom={4}>
          {name}
        </CardTitle>
        <Text color="gray" size={14} weight="medium" className={`${namespace()}--details--num`}>
          {subTitle}
        </Text>
        <Row className={`${namespace()}--details--check-in`}>
          <Column span={4} mobileSpan={12}>
            <Text color="gray" size={14}>{IsFacilityType ? 'Check-in:' : 'Starts:'}</Text>
          </Column>
          <Column span={8} mobileSpan={12}>
            <Text size={14}>{checkIn}</Text>
          </Column>
        </Row>
        <Row className={`${namespace()}--details--check-out`} marginBottom={8}>
          <Column span={4} mobileSpan={12}>
            <Text color="gray" size={14} marginBottom={0}>{IsFacilityType ? 'Check-out:' : 'End:'}</Text>
          </Column>
          <Column span={8} mobileSpan={12}>
            <Text size={14} marginBottom={0}>{checkOut}</Text>
          </Column>
        </Row>
        {price && <Media desktop tablet newApi>
          <Align bottom right>
            <Text color="green" weight="bold" marginBottom={0}>{price}</Text>
          </Align>
        </Media>}
        {price && <Media mobile newApi>
          <Text color="green" weight="bold" marginBottom={0}>{price}</Text>
        </Media>}
      </CardPadding>
    </Column>
    {InCart === 1 && <Icon icon={CartIcon} size={28} green style={{position: 'absolute', top: '8px', right: '8px'}}/>}
    {InCart === -1 &&
    <Button id={generateDOMId(`restore-reservation-${id}`)} textColor="red" flat style={{position: 'absolute', top: '8px', right: '8px'}} onClick={onRestore}>
      Restore
    </Button>}
  </Card>;
};

export default Facility;
