import {
  createApiSubmitActions,
  createValidateActions,
  createApiValidateActions,
  createUpdateValueMethod,
  createSimpleUpdateValueMethod,
  innerApiSubmitFormMethod,
} from "../../Validation/actionCreator";
import * as moment from 'moment';
import { ActionCreator, ApplicationState } from '../..';
import { FacilitiesReportType } from "./validation";
import { getFacilitiesTripItineraryReportUrl } from "../../../constants/urls";
import { typeName, Action } from "../../../utils/StrongActions";

export const SUFFIX = '__ADMIN_FACILITY_LOCATION__REPORTS';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ReportGroupTripsActions = createApiSubmitActions('__GROUP_TRIPS' + SUFFIX, true);
export const ReportGroupReservationsActions = createApiSubmitActions('__GROUP_RESERVATIONS' + SUFFIX, true);
export const ApiSubmitActions = createApiSubmitActions(SUFFIX);

@typeName('TOGGLE_INCLUDE_BLACKOUT_DATES' + SUFFIX)
export class ToggleIncludeBlackoutDates extends Action {
  constructor(public context: FacilitiesReportType) { super(); }
}

@typeName('REPORT_TRIP_ITINERARY' + SUFFIX)
export class ReportTripItinerary extends Action {}


export type Actions = typeof actionCreators;
export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminFacilityLocation.reports),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  toggleIncludeBlackoutDates: (context: FacilitiesReportType): ActionCreator => dispatch => dispatch(new ToggleIncludeBlackoutDates(context)),
  reportTripItinerary: (LocationID: number, StartDate: moment.Moment, EndDate: moment.Moment): ActionCreator => dispatch =>  {
    dispatch(new ReportTripItinerary());
    const url = getFacilitiesTripItineraryReportUrl({LocationID, TripID: 0, StartDate, EndDate});
    window.open(url);

  },
  reportGroupTrips: (LocationID: number, StartDate: string | null, EndDate: string | null, IncludeBlackoutDates: boolean): ActionCreator => dispatch => dispatch(ReportGroupTripsActions.request({LocationID, StartDate, EndDate, IncludeBlackoutDates}, null)),
  reportGroupReservations: (LocationID: number, StartDate: string | null, EndDate: string | null, IncludeBlackoutDates: boolean) => dispatch => dispatch(ReportGroupReservationsActions.request({LocationID, StartDate, EndDate, IncludeBlackoutDates}, null)),
  apiSubmitForm: (validCallback: any): ActionCreator => dispatch => {
    const valid = innerApiSubmitFormMethod(
      dispatch,
      ApiSubmitActions,
      (s: ApplicationState) => s.adminFacilityLocation.reports,
      undefined,
      undefined,
      undefined,
      true
    );
    if (valid) {
      validCallback();
    }
  },
};