import { typeName, Action } from '../../../../../../utils/StrongActions';
import { ActionCreator } from '../../../../../index';

@typeName("EVENT_REGISTER_PRODUCTS_SELECT_PAGE")
export class EventRegisterProductsSelectPage extends Action {
  constructor(public selectedPage: 'products' | 'confirmation') { super(); }
}

@typeName("EVENT_REGISTER_PRODUCTS_SET_PENDING_PAGE")
export class EventRegisterProductsSetPendingPage extends Action {
  constructor(public pendingPage?: 'products' | 'confirmation') { super(); }
}

@typeName("EVENT_REGISTER_PRODUCTS_TRANSITION_TO_PENDING")
export class EventRegisterProductsTransitionToPending extends Action {}

@typeName("EVENT_REGISTER_PRODUCTS_RESET")
export class EventRegisterProductsReset extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  reset: (): ActionCreator => dispatch => dispatch(new EventRegisterProductsReset()),
  setPendingPage: (pendingPage?: 'products' | 'confirmation'): ActionCreator => dispatch => dispatch(new EventRegisterProductsSetPendingPage(pendingPage)),
  transitionToPending: (): ActionCreator => dispatch => dispatch(new EventRegisterProductsTransitionToPending()),
  selectPage: (selectedPage: 'products' | 'confirmation'): ActionCreator => dispatch => dispatch(new EventRegisterProductsSelectPage(selectedPage)),
};
