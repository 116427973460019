import {ApiSubmitActions, ApiValidateActions, ApiSubmitDeleteActions} from './actions';
import {AjaxResponse} from "rxjs/ajax";
import {updateGroup, deleteGroup} from '../../../constants/urls';
import {makeBackendValidateCancelEpic, makeBackendValidateEpic, makeRequestEpic} from '../../Validation/epicCreator';
import { FormDefinition } from './validation';

export const editProfileUsernameBackendValidate = makeBackendValidateEpic(FormDefinition.Username.key, ApiValidateActions);
export const editProfileUsernnameBackendValidateCancel = makeBackendValidateCancelEpic(FormDefinition.Username.key, ApiValidateActions, FormDefinition.Username);

export const editProfileSubmitForm = makeRequestEpic(
  ApiSubmitActions,
  updateGroup,
  undefined,
  true,
  'Account profile has been saved.'
);

const successMessage = (res: AjaxResponse) => {
  if (res.response.Group.Inactive) {
    return 'The group was successfully deleted.';
  } else {
    return 'The group was successfully restored.';
  }
};

export const editProfileDeleteForm = makeRequestEpic(ApiSubmitDeleteActions, deleteGroup, undefined, true, successMessage);
