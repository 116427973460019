import { ActionCreator, ApplicationState } from '../../../../index';
import { SaveState } from '../../../../Rollback/actions';
import { createValidateActions, createApiValidateActions, createUpdateValueMethod, createSimpleUpdateValueMethod, createApiSubmitActions, innerApiSubmitFormMethod, getCacheLevelExtra } from '../../../../../store/Validation/actionCreator';
import { ADMIN_EVENT_TYPES_SUFFIX } from '../../../CacheOne/actions';
import { ShowTopFloatingAlert } from '../../../../App/actions';
import { ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX, UPDATE_EVENT_FORM_SUFFIX } from '../../../CacheTwoEvent/actions';
import { appActionCreators } from '../../../../App';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { typeName, Action } from '../../../../../utils/StrongActions';

export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + '__EVENT_DASHBOARD';
export const GenerateAdminEventsEventReportsActions = createApiSubmitActions('__GENERATE_REPORTS' + SUFFIX, true);
export const SendToClientsActions = createApiSubmitActions('__SEND_TO_CLIENTS' + SUFFIX);
export const RecalculatePricingActions = createApiSubmitActions('__RECALCULATE_PRICING' + SUFFIX + ADMIN_EVENTS_CACHE_TWO_EVENT_SUCCESS_HANDLE_SUFFIX + UPDATE_EVENT_FORM_SUFFIX);

export type Actions = typeof actionCreators;

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);

export const actionCreators = {
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s: ApplicationState) => s.adminEvents.events.event.dashboard, true, undefined, true),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  generateEventReports: (path: string, EventTypeID: number, EventIDi: number, IncludeBlankRows?: boolean, IncludeSecondaryTimeblocks?: boolean, ClassIDi?: number, noSave?: boolean): ActionCreator => dispatch => {
    if (!noSave) dispatch(new SaveState());
    dispatch(GenerateAdminEventsEventReportsActions.request({path, EventTypeID, EventIDi, IncludeBlankRows, IncludeSecondaryTimeblocks, ClassIDi}, null));
  },
  generateScoutbookRequirements: (path: string): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    const valid = innerApiSubmitFormMethod(
      dispatch,
      GenerateAdminEventsEventReportsActions,
      (s: ApplicationState) => s.adminEvents.events.event.dashboard,
      undefined,
      undefined,
      true,
      true,
    );

    if (valid && state.adminEvents.cacheOne.EventsEventType && state.adminEvents.cacheTwoEvent.EventsEvent) {
      dispatch(GenerateAdminEventsEventReportsActions.request({
        path,
        EventTypeID: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID,
        EventIDi: state.adminEvents.cacheTwoEvent.EventsEvent.IDi,
        GroupIDi: state.adminEvents.events.event.dashboard.ActiveForm.GroupIDi_ScoutbookRequirements,
      }, null));
    } else {
      dispatch(new ShowTopFloatingAlert('Please select a Group before generating the Scoutbook Requirements report.', undefined, 'orange'));
    }
  },
  generateBlueCardReports: (path: string): ActionCreator => (dispatch, getState) => {
    const state = getState() as ApplicationState;
    const valid = innerApiSubmitFormMethod(
      dispatch,
      GenerateAdminEventsEventReportsActions,
      (s: ApplicationState) => s.adminEvents.events.event.dashboard,
      undefined,
      undefined,
      true,
      true,
    );

    if (valid && state.adminEvents.cacheOne.EventsEventType && state.adminEvents.cacheTwoEvent.EventsEvent) {
      dispatch(GenerateAdminEventsEventReportsActions.request({
        path,
        EventTypeID: state.adminEvents.cacheOne.EventsEventType.EventTypeRow.ID,
        EventIDi: state.adminEvents.cacheTwoEvent.EventsEvent.IDi,
        GroupIDi: state.adminEvents.events.event.dashboard.ActiveForm.GroupIDi,
        IsFront: state.adminEvents.events.event.dashboard.ActiveForm.IsFront,
      }, null));
    } else {
      dispatch(new ShowTopFloatingAlert('Please select a Group before generating the Blue Cards report.', undefined, 'orange'));
    }
  },
  recalculatePricing: (): ActionCreator => dispatch => {
    dispatch(new SaveState());
    dispatch(appActionCreators.popModal(false, false, ModalTypes.RECALCULATE_PRICING) as any);
    dispatch(RecalculatePricingActions.request({}, getCacheLevelExtra(false, true)));
  }
};