import { ToggleNotFound } from "../../store/App/actions";
import { SaveState } from "../../store/Rollback/actions";
import { extractRouteParams, isIdFromUrlValid } from "../urlHelper";
import { GetFacility } from "../../store/AdminFacilityLocation/CacheTwoFacility/actions";
import { getFacilityIdFromPath } from "../helpers/adminFacilityLocationPageHelper";
import EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import { reduxStoreService } from "../../store/service";
import type AdminFacilitiesLocationCacheManager from "../cacheManagers/adminFacilitiesLocationCacheManager";
import { LoadAdminFacilityCacheTwoFacilityParams } from "./helpers/models";
import {isAdminFacilityLocationCacheTwoFacilityPopulated} from "../cachePopulatedCheckers/adminFacilities";

export const ensureAdminFacilityLocationCacheTwoFacility = (
  params: LoadAdminFacilityCacheTwoFacilityParams & {cacheManager: AdminFacilitiesLocationCacheManager},
): boolean => {
  const {props, isEdit, cacheManager, isStateNavigated} = params;
  
  if (!isIdFromUrlValid(props.params.locationId)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }

  const rootState = reduxStoreService().getState();
  const adminFacilityLocationCacheTwoFacility = rootState.adminFacilityLocation.cacheTwoFacility;

  let requestedData = false;
  if (isEdit) {
    if (!isIdFromUrlValid(props.params.facilityId)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }

    const facilityId = getFacilityIdFromPath(props.params);

    if (!EndUserCacheManager.getInstance().cacheOneNeedsReload(props) && isAdminFacilityLocationCacheTwoFacilityPopulated(adminFacilityLocationCacheTwoFacility)) {
      if (isStateNavigated) {
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      const params = {
        ID: facilityId,
        ...extractRouteParams(props),
      };

      reduxStoreService().dispatch(GetFacility.request(params));

      requestedData = true;
    }
  }

  return requestedData;
};