import * as React from 'react';
import {
  Button,
  Column,
  DatePicker,
  MoneyField,
  Row,
  Separator,
  SideModal,
  SideModalActions,
  SideModalContent,
  SideModalContentPadding,
  SideModalHeader,
  Text,
  TextField,
  Title
} from '../../../../Elements';
import '../../../../../styles/pages/settings/order/manage.scss';
import {bindActionCreators} from "redux";
import {actionCreators} from "../../../../../store/Settings/PrevOrders/ManageOrder/actions";
import {ApplicationState} from "../../../../../store";
import {standardCurrencyFormat} from "../../../../../utils/classesHelper";
import {PreviousOrderItem} from "../../../../../models/api/cacheTwoPrevOrders";
import {getItemName} from "../index";
import {
  allowInactive, allowPaymentAmountChange,
  enableAmountChange,
  getInactiveKey,
  getTotalAmountKey
} from "../../../../../store/Settings/PrevOrders/ManageOrder/uiHelpers";
import {ShowTopFloatingAlert} from "../../../../../store/App/actions";
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { SaveState } from '../../../../../store/Rollback/actions';
import { RouteComponentProps, withRouter } from 'react-router';
import { shouldReconfigRouter } from '../../../../../utils/cacheLoaders/reloaderHelper';
import { reduxStoreService } from '../../../../../store/service';
import {isCacheThreePrevOrdersPopulated} from '../../../../../utils/cachePopulatedCheckers/endUser';
import {toNumber} from '../../../../../utils/dataHelper';

export const namespace = (): string => 'pages--settings--order--manage';

export interface EditOrderProps {
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;
@(withRouter as any)
class EditOrder extends React.Component<EditOrderProps,{}> {
  props: EditOrderProps & ConnectedProps;

  public componentDidMount() {
    this.configRouteLeaveHook();
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouteLeaveHook();
  }

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    const { apiLoading, cacheThreePrevOrders } = this.props;

    // We perform a SaveState when leaving this form and also theres a route change!
    // When leaving the form without a route change, we dont need to save, because
    // in Orders page, theres no request when leaving the form without a route change.
    if (reduxStoreService().getState().app.apiLoading === 0) {
      if (isCacheThreePrevOrdersPopulated(cacheThreePrevOrders)) {
        reduxStoreService().dispatch(new SaveState());
      }
    }
  };

  componentWillUnmount() {
    this.resetRouteLeaveHook();
  }

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  onBack = () => {
    this.props.actions.showManageOrder(false, true);
  };

  onClickModal = (e) => {
    e.stopPropagation();
  };

  onDeleteRestore = (item: PreviousOrderItem) => {
    const {actions, manageOrder: { ActiveForm }} = this.props;
    const Inactive = ActiveForm[getInactiveKey(item)];
    actions.setInactive(item.ID, !Inactive);
  };

  onDeletePayment = () => {
    const {cacheThreePrevOrders: {PreviousOrder}} = this.props;
    if (!PreviousOrder) return;

    if (PreviousOrder.PaymentTypeID === 12) {
      reduxStoreService().dispatch(new ShowTopFloatingAlert('This payment cannot be deleted since it is a credit card refund.',window.innerWidth < 640, 'orange'));
    } else {
      this.props.actions.showDeleteModal();
    }
  };
  render() {
    const {apiSaving, actions, manageOrder: { ActiveForm, ValidationRules }, cacheThreePrevOrders: {PreviousOrder, PreviousOrderItems}} = this.props;
    if (!PreviousOrder) return null;
    const appliedCredit = toNumber(ActiveForm.AppliedCredit);
    const Amount = toNumber(ActiveForm.Amount);
    const NotApplied = toNumber(ActiveForm.NotApplied);
    const showItems = PreviousOrderItems !== null && PreviousOrderItems && PreviousOrderItems.length > 0;
    const totalEditable = enableAmountChange(PreviousOrder.PaymentTypeID);

    return <SideModal
      loading={apiSaving > 0}
      onClick={this.onClickModal}
      stickyHeader
      header={<SideModalHeader admin title="Manage Order" onBack={this.onBack}/>}
    >
      <SideModalContent lockBodyScroll>
        <SideModalContentPadding marginBottom={0}>
          <Row>
            <Column span={4} mobileSpan={12}>
              <DatePicker
                label="Date"
                value={ActiveForm.DatePayment}
                validationRules={ValidationRules.DatePayment}
                onSelect={actions.manageUpdateValue}
                onChangeRaw={actions.manageSimpleUpdate}
                hideError
              />
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <TextField
                label="Notes"
                value={ActiveForm.Notes}
                onChange={actions.manageSimpleUpdate}
                onBlur={actions.manageUpdateValue}
                validationRules={ValidationRules.Notes}
                rows={3}
              />
            </Column>
          </Row>
        </SideModalContentPadding>
        <Separator/>

        <SideModalContentPadding>
          <Title>Financial Summary</Title>
          <Row marginBottom={8}>
            <Column expand><Text size={15}>Total Items</Text></Column>
            <Column><Text size={15} weight="medium">{standardCurrencyFormat(Amount + appliedCredit - NotApplied)}</Text></Column>
          </Row>
          {!!ActiveForm.NotApplied && <Row marginBottom={8}>
            <Column expand><Text size={15}>Unapplied</Text></Column>
            <Column><Text size={15} weight="medium">{standardCurrencyFormat(NotApplied)}</Text></Column>
          </Row>}
          {!!appliedCredit && <Row marginBottom={8}>
            <Column expand><Text size={15}>Applied Credit</Text></Column>
            <Column><Text size={15} weight="medium">{standardCurrencyFormat(appliedCredit * -1)}</Text></Column>
          </Row>}
          <Row marginBottom={8}>
            <Column expand><Text size={18} weight="bold">Total Payment</Text></Column>
            {totalEditable && <Column width={120}>
              <MoneyField
                onChange={actions.manageSimpleUpdate}
                onBlur={actions.manageUpdateValue}
                validationRules={ValidationRules.Amount}
                value={ActiveForm.Amount}
                padding={false}
                placeHolder="$0.00"
                hideError
                align="right"
              />
            </Column>}
            {!totalEditable && <Column>
              <Text size={18} weight="bold">{standardCurrencyFormat(Amount)}</Text>
            </Column>}
          </Row>
        </SideModalContentPadding>
        <Separator/>

        {showItems && <SideModalContentPadding>
          <Title num={PreviousOrder.NumItems}>Items</Title>
          {this.renderItems()}
        </SideModalContentPadding>}

      </SideModalContent>

      <SideModalActions>
        <Button flat textColor="black" onClick={actions.manageApiSubmitForm}>SAVE</Button>
        <Button flat textColor="red" onClick={this.onDeletePayment}>DELETE PAYMENT</Button>
      </SideModalActions>

    </SideModal>;
  }

  private renderItems = () => {
    const { cacheThreePrevOrders: {PreviousOrderItems, PreviousOrder}, manageOrder: { ActiveForm, ValidationRules }, actions } = this.props;

    if (!PreviousOrderItems || !PreviousOrder) return null;

    const ret: Array<any> = [];

    PreviousOrderItems.forEach((item: PreviousOrderItem, index) => {
      const {actions, manageOrder: { ActiveForm }} = this.props;
      const Inactive = ActiveForm[getInactiveKey(item)];
      const aInactive = allowInactive(item.ItemType, item.Item_ProductIDi, PreviousOrder.PaymentTypeID);
      const allowChange = allowPaymentAmountChange(item.ItemType);
      ret.push(
        <Row className={`${namespace()}--items--row`} verticalAlignment="middle" key={`admin-item-row-${index}`}>
          <Column expand mobileSpan={12}>
            <Text size={15} color={Inactive ? 'gray' : undefined}>{getItemName(item)}</Text>
          </Column>
          <Column width={90} className={`${namespace()}--items--row--amount-column`}>
            {allowChange && <MoneyField
              align="right"
              onChange={actions.manageSimpleUpdate}
              onBlur={actions.manageUpdateValue}
              validationRules={ValidationRules[getTotalAmountKey((item))]}
              value={ActiveForm[getTotalAmountKey((item))]}
              padding={false}
              placeHolder="$0.00"
              hideError
              disabled={Inactive}
            />}
            {!allowChange && <Text color={Inactive ? 'gray' : undefined} size={15} weight={Inactive ? undefined : 'medium'}>{standardCurrencyFormat(ActiveForm[getTotalAmountKey((item))])}</Text>}
          </Column>
          <Column className={`${namespace()}--items--row--remove-column`}>
            <Button
              className={`${namespace()}--items--remove-button`}
              flat
              textColor="red"
              disabled={!aInactive}
              onClick={() => this.onDeleteRestore(item)}>{Inactive ? 'RESTORE' : 'REMOVE'}</Button>
          </Column>
        </Row>
      );
    });

    return ret;
  };
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    manageOrder: state.settings.prevOrders.manageOrder,
    cacheThreePrevOrders: state.cacheThreePrevOrders
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators({
  ...actionCreators
}, dispatch)});

const ConnectedEditOrder = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<EditOrderProps>(),
)(EditOrder);

export default ConnectedEditOrder;
