import {apiValidationReducerCreator} from '../Validation/reducerCreator';
import {IUpdatePasswordValidator, IResetPasswordSearchValidator, ResetPasswordSearchFormDefinition, UpdatePasswordFormDefinition} from './validation';
import * as Actions from './actions';
import { IResetPasswordAccount } from '../../models/api/login';
import { RESET_PASSWORD_SEARCH_SUFFIX, RESET_PASSWORD_UPDATE_SUFFIX } from './constants';
import { setDefaults } from '../../utils/validator';
import { isActionType } from '../../utils/StrongActions';
import { AnyAction, Reducer } from 'redux';
import { WithRootState } from '../Validation/actionCreator';

export interface IResetPasswordSearchActiveForm {
  EmailOrUsername?: string;
}

export interface IUpdatePasswordActiveForm {
  NewPassword?: string;
  ConfirmNewPassword?: string;
}


export interface ResetPasswordState {
  sendResetLinkStep: number;
  selectedAccount?: IResetPasswordAccount;
  UpdatePasswordForm: {
    ActiveForm: IUpdatePasswordActiveForm;
    ValidationRules: IUpdatePasswordValidator;
    SubmitErrorMessage?: string;
  };
  ResetPasswordSearchForm: {
    ActiveForm: IResetPasswordSearchActiveForm;
    ValidationRules: IResetPasswordSearchValidator;
    SubmitErrorMessage?: string;
  };
  SubmitErrorMessage?: string;
}

export enum ResetPasswordContext {
  SEND_RESET_LINK,
  UPDATE_PASSWORD,
}

const checkResetPasswordSearchApiValidation = apiValidationReducerCreator(RESET_PASSWORD_SEARCH_SUFFIX);
const checkResetPasswordUpdateApiValidation = apiValidationReducerCreator(RESET_PASSWORD_UPDATE_SUFFIX);

const getInitialState = (): ResetPasswordState => {
  return {
    UpdatePasswordForm: {
      ActiveForm: {},
      ValidationRules: {...UpdatePasswordFormDefinition}
    },
    ResetPasswordSearchForm: {
      ActiveForm: {},
      ValidationRules: {...ResetPasswordSearchFormDefinition}
    },
    sendResetLinkStep: 1,
  };
};

const ResetPassword: Reducer<ResetPasswordState> = (
  oldState = getInitialState(),
  action: WithRootState<AnyAction>,
) => {
  const state = {...oldState };
  if (oldState && oldState.ResetPasswordSearchForm) state.ResetPasswordSearchForm = checkResetPasswordSearchApiValidation(oldState.ResetPasswordSearchForm, action);
  if (oldState && oldState.UpdatePasswordForm) state.UpdatePasswordForm = checkResetPasswordUpdateApiValidation(oldState.UpdatePasswordForm, action);

  if (action.type === Actions.ResetPasswordSearchSubmitActions.successType) {
    return { ...state, sendResetLinkStep: 2};
  } else if (isActionType(action, Actions.ResetPasswordSelectAccount)) {
    return {
      ...state,
      selectedAccount: action.account,
    };
  } else if (isActionType(action, Actions.ResetPasswordUpdatePasswordFormInit)) {
    const newState = getInitialState();

    newState.UpdatePasswordForm = setDefaults(action.rootState, newState.UpdatePasswordForm);
    return newState;
  } else if (isActionType(action, Actions.ResetPasswordSearchFormInit)) {
    const newState = getInitialState();

    newState.ResetPasswordSearchForm = setDefaults(action.rootState, newState.ResetPasswordSearchForm);
    return newState;

  }
  return state || {};
};

export default ResetPassword;
