import {ViewControlsOptions} from "../../../../../components/Elements/Controls/View";
import {FacilitiesReservationSelectType, FacilitiesReservationSelectView} from "./actions";
import {FacilitiesReservationReset} from "../actions";
import { Reducer } from "redux";
import { isActionType } from "../../../../../utils/StrongActions";

export interface FacilitiesReservationTypeState {
  selectedType: number;
  selectedView: ViewControlsOptions;
}
const getInitialState = (): FacilitiesReservationTypeState => {
  return {
    selectedType: 0,
    selectedView: 'grid'
  };
};

const FacilitiesReservationType: Reducer<FacilitiesReservationTypeState> = (state: FacilitiesReservationTypeState, action: any) => {
  if (isActionType(action, FacilitiesReservationSelectType)) {
    return {
      ...state,
      selectedType: action.selectedType
    };
  } else if (isActionType(action, FacilitiesReservationSelectView)) {
    return {
      ...state,
      selectedView: action.view
    };
  } else if (isActionType(action, FacilitiesReservationReset)) {
    return {...getInitialState()};
  }
  return state || {...getInitialState()};
};

export default FacilitiesReservationType;
