import MaterialButton from '@material-ui/core/Button';
import * as React from 'react';

import '../../../styles/elements/button/index.scss';
import Loader from '../../Elements/Loader';
import {default as attributes, MarginProps} from "../../../styles/index";
import { isMobile } from '../../../utils/isMobile';
import { WithDisabled } from '../WithDisabled';

const namespace = (): string => 'elements--button';

export interface ButtonProps extends MarginProps {
  id?: string;
  style?: React.CSSProperties;
  children?: string | React.ReactNode;
  contextMenu?: React.ReactElement<any>;
  className?: string;
  onClick?: React.EventHandler<any>;
  icon?: React.ReactType | React.ReactElement<any>;
  type?: string;
  submit?: boolean;
  // Customized disabled behavior, note that it is always disabled when
  // apiSaving > 0, as the <Button /> component is wrapped by the `WithDisabled` HoC
  disabled?: boolean;
  loading?: boolean;
  ripple?: boolean;
  flat?: boolean;
  big?: boolean;
  small?: boolean;
  admin?: boolean;
  expand?: boolean;
  // @todo: white color actually gives transparent background <.<
  color?: 'black' | 'white' | 'green' | 'orange' | 'red' | 'gray' | 'blue';
  textColor?: 'blue' | 'black' | 'green' | 'red' | 'white' | 'dark-black';
}

interface State {
  contextMenuOpen: boolean;
}

class Button extends React.Component<ButtonProps, State> {
  public static defaultProps = {
    ripple: true
  };

  public state: State = {
    contextMenuOpen: false
  };

  refs: {
    element: React.ReactInstance
  };

  public props: ButtonProps;

  public render() {
    const {
      children, id, icon: Icon, flat, small, big, admin, ripple, onClick, submit, expand, contextMenu, style: styleOverride,
      color, textColor, loading, disabled, className: classNameOverride, ...props
    } = this.props;

    let variant: 'raised' | 'outlined' | undefined = 'outlined';
    let className = attributes(namespace(), classNameOverride, props);
    if (!flat) {
      variant = 'raised';
      className += ' colored  raised';
    } else {
      variant = undefined;
      className += ' flat';
    }

    if (color) className += ` ${color}`;
    if (textColor) className += ` ${textColor}-text`;

    if (small) className += ' small';
    if (big) className += ' big';
    if (expand) className += ' expand';
    if (loading) className += ' loading';
    if (isMobile) className += ' mobile';
    if (disabled) className += ' disabled';

    let style: React.CSSProperties = { ...styleOverride };

    let icon: React.ReactElement<any> | undefined;
    if (Icon && !React.isValidElement(Icon)) {
      const IconComponent = Icon as React.ReactType;
      icon = <IconComponent width="18px" height="18px" className={`${namespace()}--icon`}/>;
    } else if (Icon) {
      const iconElement = Icon as React.ReactElement<any>;
      icon = React.cloneElement(iconElement, {
        ...iconElement.props,
        className: (iconElement.props.className ? iconElement.props.className + ' ' : '') + `${namespace()}--icon`
      });
    }
    if (icon) className += ' has-icon';

    const button = (
      <MaterialButton
        id={id}
        disabled={disabled || loading}
        className={className}
        onClick={onClick}
        style={style}
        variant={variant}
        disableRipple={!isMobile ? !ripple : true}
        type={submit ? 'submit' : undefined}
      >
        {admin && <i className={`${namespace()}--admin-badge`}>A</i>}
        {icon}
        <span className={`${namespace()}--text`}>{children}</span>
        <Loader color="white" size="18px" className={`${namespace()}--loader ${loading ? '' : 'hidden'}`}/>
      </MaterialButton>
    );

    if (contextMenu) {
      return (
        <div style={{ position: 'relative' }}>
          {React.cloneElement(button, { ...button.props, onClick: () => this.setState({ contextMenuOpen: !this.state.contextMenuOpen }) })}
          {React.cloneElement(contextMenu, { ...contextMenu.props, open: this.state.contextMenuOpen, onClose: () => this.setState({ contextMenuOpen: false }) })}
        </div>
      );
    }
    return button;
  }
}

export default WithDisabled(Button);
