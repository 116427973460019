import * as React from 'react';
import { Main, MainContent } from '../../Layouts';
import { InfoIcon } from '../../Icons';
import { EmptyMessage, Text, View } from '../../Elements';
import '../../../styles/pages/not-found/index.scss';
import { ApplicationState } from '../../../store';
import { actionCreators as appActionCreators } from '../../../store/App/actions';
import { bindActionCreators } from 'redux';
import { withRouter, RouteComponentProps, WithRouterProps } from 'react-router';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';


const namespace = (): string => 'pages--not-found';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;
type Props = {
  title?: string;
  message?: string;
  showCMSSiteHeader?: boolean;
  inModal?: boolean;
};

class NotFound extends React.Component<Props, {}> {
  props: Props & ConnectedProps;
  componentWillMount() {
    if (!this.props.showCMSSiteHeader) this.props.actions.showAdminPageHeader(false);
  }

  render() {
    const props = this.props;

    // There's nothing to interact for a NotFound component, so let's set inert to undefined
    // here and hence not need to pass anything about inert to this component
    return (
      <Main inert={undefined} mobileBackground="white">
        <MainContent className={`${namespace()} ${props.inModal ? 'in-modal' : ''}`}>
          <EmptyMessage
            icon={InfoIcon}
            description={props.title ? props.title : 'Not Found'}
            actions={<View>
              <Text className={`${namespace()}--text`}>{props.message ? props.message : 'The link is wrong or expired.'}</Text>
            </View>}
          />
        </MainContent>
    </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    app: state.app,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
  }, dispatch),
});

const ConnectedNotFound = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps & Props>(),
)(NotFound);

export default withRouter<Props>(ConnectedNotFound);
export { default as NotFoundHeader } from './Header';
