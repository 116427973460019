import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { orange } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core';
import { default as MdlTextfield } from "@material-ui/core/TextField";
import {mergeClassNames} from '@tentaroo/shared';

import Text from "../Text";
import StaticTextField from "../StaticTextField";
import AdminBadge from "../AdminBadge";

import '../../../styles/elements/text-field/index.scss';

import ValidIcon from './ValidIcon';
import InvalidIcon from './InvalidIcon';
import Loader from './Loader';
import CancelIcon  from './CancelIcon';
import { isOptional } from '../../../utils/validator';

import {namespace} from "./constants";
import { Validator } from '../../../utils/validator/models';
import { reduxStoreService } from '../../../store/service';
import {Nullable} from '../../../utils/dataHelper';

export interface TextFieldProps {
  className?: string;
  width?: number;
  rows?: number;
  floatingLabel?: boolean;
  label?: string;
  style?: React.CSSProperties;
  error?: React.ReactNode; // @todo: remove this
  disabled?: boolean;
  editable?: boolean;
  info?: string;
  infoOverflow?: boolean;
  appendedBoldInfo?: string;
  appendedBoldInfoTextColor?: string;
  icon?: React.ReactNode | React.ReactType;
  padding?: boolean;
  big?: boolean;
  align?: string;
  placeHolder?: string;
  isAdmin?: boolean;
  hideError?: boolean;
  autocomplete?: 'on' | 'off' | 'new-password' | 'username' | 'current-password';
  defaultValue?: string | number; // @todo fix this class so value parameter actually works
  onChange?: (value: string | number | undefined, validationRules?: Validator) => any;
  onBlur?: (value: string | number | undefined, validationRules?: Validator) => any;
  onCancel?: (value: string | number | undefined, validationRules?: Validator) => any;
  onKeyDown?: (event) => any;
  validationRules?: Validator;
  type?: 'text' | 'password';
  value?: string | number; // @todo: should be required, otherwise rollback won't work, going to have a billion typescript errors once you do that
  noPaddingBottom?: boolean;
  hideOptional?: boolean;
  id?: string;
  classes?: any;
  noStateValue?: boolean;
  autoFocus?: boolean;
  dollarSignPrefix?: boolean;
  percentSignSuffix?: boolean;
  decimalPrecision?: number;
  overflowLabel?: boolean;
  onFocusCallback?: () => void;
  inputRef?: React.RefObject<any>;
  adminBadgePosition?: "inside" | "outside";
}

const INTEGER_ONLY_REGEX = /^\d+$/;
const DECIMAL_ONLY_REGEX = /^\d+(\.\d+)?$/;
const ZIP_ONLY_REGEX = /^\d{5}(?:-\d{4})?$/;

const NON_DECIMAL_VALUE_REGEX = /[^0-9.]/;
const NON_INTEGER_VALUE_REGEX = /[^0-9]/;
const NON_ZIPCODE_VALUE_REGEX = /[^0-9\-]/;

export interface TextFieldState {
  value?: string | number; // @todo: need to remove this
  // @todo: hm, maybe I need to remove isEditing..... maybe I can get away with keeping this one
  isEditing?: boolean; // When editing, we will not show any errors
  /**
   * Use this to fake the text field type when user starts typing, we use this
   * to specifically avoid the autocomplete problems for login field
   */
  dynamicType?: string;
  focused?: boolean;
}

class TextField extends React.Component<TextFieldProps, TextFieldState> {
  private infoRef;
  public static defaultProps = {
    label: '',
    floatingLabel: true,
    editable: true,
    padding: true,
    type: 'text'
  };

  public props:TextFieldProps;

  public state:TextFieldState = {
    value: '',
    dynamicType: this.props.type === "password" && this.props.autocomplete === "off" ? "text" : undefined,
    focused: false,
  };

  public get input():any {
    const node = findDOMNode(this) as Nullable<Element>;
    const inputs = node ? node.getElementsByTagName('input') : null;
    const textareas = node ? node.getElementsByTagName('textarea') : null;
    return inputs?.length ? inputs[0] : textareas?.length ? textareas[0] : null;
  }

  public componentWillMount() {
    if (this.props.defaultValue !== undefined) this.state.value = this.props.defaultValue;
  }

  public componentDidMount() {
    this.updatePlaceHolder();
    this.updateInfoStyle();
  }

  public componentDidUpdate() {
    this.updatePlaceHolder();
    this.updateInfoStyle();
  }

  updateInfoStyle = () => {
    if (this.infoRef && this.props.infoOverflow) {
      const textfield = this.infoRef.firstElementChild;
      const info = this.infoRef.lastElementChild;

      if (textfield && info) {
        if (!textfield.classList.contains('has-error')) textfield.style.paddingBottom = `${info.clientHeight + 8}px`;
        else textfield.style.paddingBottom = '0px';
      }
    }
  };

  public updatePlaceHolder = () => {
    if (this.props.placeHolder) {
      if (this.props.rows === undefined) {
        const node = findDOMNode(this) as Nullable<Element>;
        const input = node && node.getElementsByTagName('input')[0];
        if (input) input.setAttribute('placeholder', this.props.placeHolder);
      } else {
        const node = findDOMNode(this) as Nullable<Element>;
        const textarea = node && node.getElementsByTagName('textarea')[0];
        if (textarea) textarea.setAttribute('placeholder', this.props.placeHolder);
      }
    }
  };

  public handleChange = e => {
    const {noStateValue} = this.props;
    this.setState({
      value: noStateValue ? this.state.value : e.target.value,
      isEditing: true,
      dynamicType: this.props.type === "password" && this.props.autocomplete === "off" ? "password" : (this.props.type ? this.props.type : undefined),
    });
    if (this.props.onChange) this.props.onChange(e.target.value, this.props.validationRules);
  };

  public handleBlur = e => {
    this.setState({isEditing: false, focused: false});
    if (this.props.onBlur) this.props.onBlur(e.target.value, this.props.validationRules);
  };

  onFocus = () => {
    this.setState({ focused: true });
    if (this.props.onFocusCallback) this.props.onFocusCallback();
  };

  onCancel = (e) => {
    this.setState({
      value: undefined,
      isEditing: false,
    });
    this.props.onCancel && this.props.onCancel(e);
  };

  onKeyPressAlphaNumeric = (e) => {
    // thoughts: seems some characthers such as Chinese characters wont fire this handler, but it should be fine
    // in our scenarios, and could use as a hack to test validations even when the input is restircted?
    const {validationRules} = this.props;
    if (validationRules) {
      // Intercept and block certain inputs based on validationRules
      if (validationRules.customizedRegexOnly) {
        if (!validationRules.customizedRegexOnly.test(e.key)) {
          e.preventDefault();
        }
      } else if (validationRules.decimalOnly) {
        if (e.key === '.') {
          // allow if the value doesn't have one already
          if (this.props.value && this.props.value.toString().includes('.')) {
            e.preventDefault();
          }
        } else if (e.key === '-' && validationRules && validationRules.allowNegative && validationRules.allowNegative(reduxStoreService().getState())) {
          // only allow if the - is in 0 index
          if (e.currentTarget.selectionStart !== 0 || (this.props.value && this.props.value.toString().includes('-'))) {
            e.preventDefault();
          }
        } else if (NON_DECIMAL_VALUE_REGEX.test(e.key)) {
          e.preventDefault();
        }
      } else if (validationRules.integerOnly) {
        if (NON_INTEGER_VALUE_REGEX.test(e.key)) {
          e.preventDefault();
        } else if (e.key === '-' && validationRules && validationRules.allowNegative && validationRules.allowNegative(reduxStoreService().getState())) {
          // only allow if the - is in 0 index
          if (e.currentTarget.selectionStart !== 0) {
            e.preventDefault();
          }
        }
      } else if (validationRules.zipOnly) {
        if (NON_ZIPCODE_VALUE_REGEX.test(e.key)) {
          e.preventDefault();
        }
      }
    }
  };

  onPasteAlphaNumeric = (e) => {
    const {validationRules} = this.props;

    // If no data in clipboard, we cannot do anything
    // See why we silently fail and do not have any try/catch around code
    // that access clipboard data here: https://tentaroo-camp-management.monday.com/boards/4118418283/pulses/4455364689/posts/2798314274
    if (!e.clipboardData) return;

    if (validationRules) {
      const string = e.clipboardData.getData('Text');

      let regex: RegExp | undefined;

      if (validationRules.customizedRegexOnly) regex = validationRules.customizedRegexOnly;
      else if (validationRules.decimalOnly) regex = DECIMAL_ONLY_REGEX;
      else if (validationRules.integerOnly) regex = INTEGER_ONLY_REGEX;
      else if (validationRules.zipOnly) regex = ZIP_ONLY_REGEX;

      if (regex && !regex.test(string)) {
        // Block paste if regex is specified but input string doesn't pass the test
        e.preventDefault();
      }
    }
  };


  public render() {
    const { placeHolder, value, editable, info, icon, padding, align, label, floatingLabel, rows, style, big, validationRules, dollarSignPrefix, decimalPrecision, infoOverflow,
      noPaddingBottom, hideError, disabled, inputRef, onCancel, onKeyDown, hideOptional, id, autocomplete, classes, percentSignSuffix, isAdmin, appendedBoldInfo, adminBadgePosition, appendedBoldInfoTextColor, autoFocus, overflowLabel } = this.props;
    const stateValue = this.state.value;
    let maxLength = undefined;
    let apiState: 'success' | 'failure' | 'loading' | undefined = undefined;
    let decimalOnly: boolean = false, integerOnly: boolean = false, zipOnly: boolean = false, customizedRegexOnly;
    let error = this.props.error;
    if (validationRules) {
      if (validationRules.errors && validationRules.errors.length > 0) error = validationRules.errors[0];
      if (validationRules.apiCheck) apiState = validationRules.apiCheck.state;
      const k = validationRules.key;
      if (validationRules.validatejs && validationRules.validatejs[k] && validationRules.validatejs[k].length && validationRules.validatejs[k].length.maximum) {
        maxLength = validationRules.validatejs[k].length.maximum;
      }
      if (validationRules.decimalOnly) decimalOnly = true;
      if (validationRules.integerOnly) integerOnly = true;
      if (validationRules.zipOnly) zipOnly = true;
      if (validationRules.customizedRegexOnly) customizedRegexOnly = validationRules.customizedRegexOnly;
    }

    let iconComponent: any = null;
    if (icon && !React.isValidElement(icon)) {
      const Icon: any = icon;
      iconComponent = <Icon width="24px" height="24px"/>;
    } else if (icon) {
      iconComponent = icon;
    }

    let className = mergeClassNames(namespace(), this.props);
    if (!editable) return <StaticTextField {...this.props as any} className={className} />;

    const isDirty = () => {
      return (value !== null && value !== undefined && value !== '') || stateValue || autocomplete === 'username' || autocomplete === 'current-password';
    };

    const wrapperClassName = ` mdl-textfield__wrapper ${className}`;
    className += ' mdl-textfield';
    if (placeHolder) className += " has-placeholder";
    if (floatingLabel) className += ' mdl-textfield--floating-label';
    if (overflowLabel) className += ' overflow-label';
    if (disabled) className += ' disabled-with-appearance';
    if (info && (!error || hideError)) className += ' has-info';
    if (error) className += ' has-error';
    if (iconComponent) className += ' has-icon';
    if (big) className += ' big';
    if (!padding) className += ' no-padding';
    if (isAdmin) className += ' is-admin';
    if (adminBadgePosition) className += ` ${adminBadgePosition}`;
    if (!label) className += ' no-label';
    if (align === 'right') className += ' align-right';
    if (isDirty()) className += ' is-dirty';
    if (noPaddingBottom) className += ' no-padding-bottom';
    if (onCancel) className += ' padding-right';
    if (apiState === "loading" || apiState === "failure" || apiState === "success") className += ' padding-right';
    if (dollarSignPrefix && value) className += ' has-dollar-sign';
    if (percentSignSuffix && value) className += ' has-percent-sign';
    if (infoOverflow) className += ' info-overflow';

    const textField = (
      <div className={`${wrapperClassName}${error ? ' has-error' : ''}${isDirty() ? ' is-dirty' : ''}${this.state.focused ? ' is-focused' : ''}`}>
        {isAdmin &&
          <div className={`admin-badge ${adminBadgePosition}`}>
            <AdminBadge/>
          </div>}
        <MdlTextfield
          id={id}
          autoFocus={autoFocus}
          inputRef={inputRef}
          InputProps={{
            className: 'mdl-textfield__input_wrapper',
            style: {
              marginTop: 0,
              padding: 0,
            },
            classes: {
              // @ts-ignore
              root: classes.cssInputRoot,
              // @ts-ignore
              underline: classes.cssUnderline,
            },
          }}
          InputLabelProps={{
            className: `mdl-textfield__label ${this.state.focused ? 'is-focused' : ''} ${isDirty() ? 'is-dirty' : ''}`,
            // @ts-ignore
            FormLabelClasses: {
              focused: classes.cssInputLabelFocused,
            },
          }}
          inputProps={{
            className: 'mdl-textfield__input',
            maxLength,
            onKeyPress: customizedRegexOnly || decimalOnly || integerOnly || zipOnly ? this.onKeyPressAlphaNumeric : undefined,
            onKeyDown,
            style: {
              padding: 0,
            },
          }}
          FormHelperTextProps={{
            className: "mdl-textfield__error_msg",
            classes: {
              root: classes.cssFormHelperTextRoot,
            },
            style: {
              position: 'absolute',
              alignSelf: align === 'left' ? 'flex-start' : (align === 'right' ? 'flex-end' : undefined),
              bottom: '0',
              marginBottom: '4px',
            },
          }}
          onFocus={this.onFocus}
          className={className}
          helperText={(this.state.isEditing || hideError) ? undefined : error}
          label={label ? label : ''}
          disabled={disabled}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          rows={rows}
          multiline={rows as number> 0}
          value={value !== undefined && value !== null ? (value === 0 ? "0" : value) : ''}
          // error={this.state.isEditing || hideError}
          type={this.state.dynamicType || this.props.type}
          autoComplete={autocomplete || 'off'}
          onPaste={customizedRegexOnly || decimalOnly || integerOnly || zipOnly? this.onPasteAlphaNumeric : undefined}
        >
        </MdlTextfield>
        {dollarSignPrefix && !!value && <div className={`${namespace()}--dollar-sign`}>$</div>}
        {percentSignSuffix && !!value && <div className={`${namespace()}--percent-sign`}>%</div>}
        {iconComponent && <div className={`${namespace()}--icon`}>{iconComponent}</div>}
        {!hideOptional && isOptional(validationRules) && <div className={`${namespace()}--optional ${this.state.focused ? 'focused' : ''} ${isDirty() ? 'dirty' : ''} ${rows as number > 0 ? 'multiline' : ''}`}>optional</div>}
        {apiState === 'loading' && <Loader/>}
        {apiState === 'success' && <ValidIcon/>}
        {apiState === 'failure' && <InvalidIcon/>}
        {onCancel && <CancelIcon onClick={this.onCancel}/>}
      </div>
    );

    if (info) {
      return (
        <div ref={(ref) => this.infoRef = ref} className={`${namespace()}--text-info-container ${error ? 'has-error' : ''} ${infoOverflow ? 'info-overflow' : ''}`} style={style}>
          {textField}
          {!error && <Text additionalInfo className={`${namespace()}--text-info` + (big ? ' big' : '')}>{info}{appendedBoldInfo ? <strong style={appendedBoldInfoTextColor ? {color: appendedBoldInfoTextColor} : {}}>{appendedBoldInfo}</strong> : null}</Text>}
        </div>
      );
    }
    return React.cloneElement(textField, { ...textField.props, style });
  }
}

const styles = {
  cssInputRoot: {
    '&::before': {
      borderBottom: '0 !important',
    },
  },
  cssUnderline: {
    '&': {
      borderBottomColor: "rgba(0, 0, 0, 0.12)",
    },
    '&:after': {
      borderBottomColor: orange[500],
    },
  },
  cssFormHelperTextRoot: {
    color: "#d50000",
    marginTop: '4px',
    lineHeight: '1.4',
    height: '16px',
  },
  cssInputLabelFocused: {
    color: 'rgba(68,68,68,.6) !important',
  },
};

export default withStyles(styles)(TextField);
