import { IValidator, FormDefinition } from "./validation";
import { SUFFIX, AddResourceSubmitActions } from "./actions";
import { apiValidationReducerCreator, shouldSkipUpdateForm } from "../../../../../store/Validation/reducerCreator";
import { createSelector } from "reselect";
import { CMSResourceCategory, CMSResource } from "../../../../../models/api/adminCMSCacheOne";
import { ClearAdminCMSCacheTwoResource, UPDATE_CMS_RESOURCE_FORM_SUFFIX } from "../../../CacheTwoResources/actions";
import { setDefaults } from "../../../../../utils/validator";
import { ClearAdminCMSSiteCacheBelowOne } from "../../../../../store/AdminCMSSite/CacheOne/actions";
import { scrollModalToTop } from "../../../../../utils/helpers/adminCMSPageHelper";
import { API_UPD } from "../../../../../constants/request";
import { ModalTypes, isModalOpened, ResourceFormModalNamespace } from "../../../../../utils/modalHelper";
import { AnyAction, Reducer } from "redux";
import { isActionType } from "../../../../../utils/StrongActions";
import { APISuccess, WithRootState } from "../../../../Validation/actionCreator";

export interface ResourceFile {
  ID?: number;
  attachPath?: string;
  filePath?: string;
  Filename?: string;
  inputObj?: any;
}

export interface ResourceActiveForm {
  ID?: number;
  Name?: string;
  CategoryID?: number;
  Description?: string;
  File?: ResourceFile;
  SiteID?: number;
  SelectedResourceCaetgoryID?: number;
}

export interface ResourceFormState {
  ActiveForm: ResourceActiveForm;
  ValidationRules: IValidator;
  SubmitErrorMessage?: string;
}

const getInitialState = () => {
  return {
    ActiveForm: { },
    ValidationRules: { ...FormDefinition }
  };
};

const resourceCategoriesSelector = state => {
  if (state.adminCMSSite.cacheOne) return state.adminCMSSite.cacheOne.CMSResourceCategories;
  return [];
};

const selectedResourceCategoryIDSelector = state => {
  if (state.adminCMSSite.resources && state.adminCMSSite.resources.resource && state.adminCMSSite.resources.resource.form) return state.adminCMSSite.resources.resource.form.ActiveForm.CategoryID;
  return -1;
};

export const makeSelectedResourceCategorySelector = () => {
  return createSelector(
    [resourceCategoriesSelector, selectedResourceCategoryIDSelector],
    (resourceCategories: Array<CMSResourceCategory>, categoryId: number) => {
      if (categoryId === -1 || !resourceCategories || resourceCategories.length === 0) return undefined;

      return resourceCategories.find((c) => c.ID === categoryId);
    },
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX);

const setResource = (state: ResourceFormState, resource?: CMSResource) => {
  if (!resource) return state;
  const newState: ResourceFormState = {
    ...state,
    ActiveForm: {
      ID: resource.ID,
      Name: resource.Name,
      CategoryID: resource.CategoryID,
      Description: resource.Description,
      File: resource.File,
      SiteID: resource.SiteID,
    },
    ValidationRules: { ...FormDefinition },
  };

  return newState;
};

const ResourceFormReducer: Reducer<ResourceFormState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);
  if (act.type.includes(API_UPD) && act.type.includes(UPDATE_CMS_RESOURCE_FORM_SUFFIX)) {
    if (shouldSkipUpdateForm(act)) return state;

    const newState = setDefaults(act.rootState, getInitialState(), state);
    const rootState = act.rootState;

    // if there is resource loaded, load it from cache two to ActiveForm, and override default values
    if (rootState.adminCMSSite.cacheTwoResources.CMSResource) {
      return setResource(newState, rootState.adminCMSSite.cacheTwoResources.CMSResource);
    }

    return newState;
  } else if (act.type === AddResourceSubmitActions.failureType) {
    const action = <WithRootState<APISuccess>>act;
    if (action.response.status === 400) {
      if (isModalOpened(ModalTypes.RESOURCE_FORM, act.rootState)) scrollModalToTop(ResourceFormModalNamespace());
      else window.scrollTo(0, 0);
    }
  } else if (isActionType(act, ClearAdminCMSCacheTwoResource) || isActionType(act, ClearAdminCMSSiteCacheBelowOne)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default ResourceFormReducer;