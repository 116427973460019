import { SaveState } from "../../store/Rollback/actions";
import { ToggleNotFound } from "../../store/App/actions";
import { GetGroupRosterPerson } from "../../store/CacheThreeRoster/actions";
import { getGroupID } from "../helpers/endUserPageHelper";
import { InitializeAddDefaultValues } from "../../store/Settings/Roster/Add/actions";
import { isIdFromUrlValid, isIdValid } from "../urlHelper";
import { captureTentarooError } from "../dataHelper";
import { reduxStoreService } from "../../store/service";
import type EndUserCacheManager from "../cacheManagers/endUserCacheManager";
import { LoadEndUserCacheThreeRosterParams } from "./helpers/models";
import {isCacheThreeRosterPopulated, isCacheTwoRosterPopulated, isCacheZeroPopulated, isEndUserCacheOnePopulated} from "../cachePopulatedCheckers/endUser";

export const ensureCacheThreeRoster = (
  params: LoadEndUserCacheThreeRosterParams & {cacheManager: EndUserCacheManager}
): boolean => {
  const { props, isEdit, cacheManager, isStateNavigated } = params;

  const groupID = getGroupID(props);
  if (!isIdValid(groupID)) {
    reduxStoreService().dispatch(new ToggleNotFound());
    return false;
  }
  const rootState = reduxStoreService().getState();
  const { cacheZero, cacheOne, cacheTwoRoster, cacheThreeRoster } = rootState;

  let requestedData = false;
  if (isEdit) {
    if (!isIdFromUrlValid(props.params.id)) {
      reduxStoreService().dispatch(new ToggleNotFound());
      return false;
    }

    const PersonIDi = Number(props.params.id);

    if (isCacheThreeRosterPopulated(cacheThreeRoster)) {
      if (isStateNavigated) {
        reduxStoreService().dispatch(new SaveState());
      }
    } else {
      if (!isCacheZeroPopulated(cacheZero)) {
        // Came from page load, need everything
        reduxStoreService().dispatch(GetGroupRosterPerson.request({
          GroupIDi: groupID,
          GroupTS: 0,
          PersonIDi,
          IsYouth: props.params.ya === 'youth',
          GetOptions: true,
          GetGroupData: true,
          GetGroupRosterData: true,
        }));

        requestedData = true;
      } else if (
        isCacheZeroPopulated(cacheZero) &&
        (!cacheZero.options!.Group || !isEndUserCacheOnePopulated(cacheOne))
      ) {
        reduxStoreService().dispatch(GetGroupRosterPerson.request({
          GroupIDi: groupID,
          GroupTS: 0,
          PersonIDi,
          IsYouth: props.params.ya === 'youth',
          GetOptions: false,
          GetGroupData: true,
          GetGroupRosterData: true,
        }));

        requestedData = true;
      } else {
        if (cacheZero.options!.Group) {
          reduxStoreService().dispatch(GetGroupRosterPerson.request({
            GroupIDi: groupID,
            GroupTS: cacheZero.options!.Group.TS,
            PersonIDi,
            IsYouth: props.params.ya === 'youth',
            GetOptions: false,
            GetGroupData: false,
            GetGroupRosterData: !isCacheTwoRosterPopulated(cacheTwoRoster),
          }));

          requestedData = true;
        } else {

          captureTentarooError(new Error("Group not available when dispatching GetGroupRosterPerson in cacheThreeRosterLoader"));

          requestedData = false;
        }
      }
    }
  } else if (isStateNavigated) {
    requestedData = cacheManager.loadCacheTwoRoster({props, isStateNavigated});

    if (!requestedData) {
      reduxStoreService().dispatch(new InitializeAddDefaultValues());
      reduxStoreService().dispatch(new SaveState());
    }
  }

  return requestedData;
};