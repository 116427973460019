import * as React from 'react';
import {default as AppHeader} from '../../../../App/Header';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import {bindActionCreators} from 'redux';
import {isUrlAddReservation} from "../../../../../store/Facilities/Trip/Reservation/index";
import {actionCreators} from "../../../../../store/Facilities/Trip/Reservation/actions";
import {navPush} from "../../../../../utils/navHelper";
import {FAC_TRIP_ADD, FAC_TRIP_RESERVATION, FAC_TRIP_RESERVATION_EDIT} from "../../../../../constants/urls";
import {FacilityTrip} from "../../../../../models/api/cacheThreeFacilities";
import {displayTimeFrame} from "../../../../../utils";
import { ApplicationState } from '../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';

interface HeaderSpec {
  onBack: () => void;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {eventTypeId: string, eventId: string, name: string}>;

const getHeaderSpecs = (props: ConnectedProps): HeaderSpec => {
  const {facilitiesReservation, actions, router, routes, location} = props;
  const route = routes[routes.length - 1];
  if (
    (facilitiesReservation.selectedPage === 'type' && isUrlAddReservation(route.path!)) ||
    (facilitiesReservation.selectedPage === 'reservation' && !isUrlAddReservation(route.path!))
  ) {

    let backPath = '';
    if (location.pathname.includes(`/${FAC_TRIP_RESERVATION}/${FAC_TRIP_ADD}`)) {
      backPath = location.pathname.split(`/${FAC_TRIP_RESERVATION}/${FAC_TRIP_ADD}`)[0];
    } else if (location.pathname.endsWith(`/${FAC_TRIP_RESERVATION_EDIT}`)) {
      backPath = location.pathname.split(`/${FAC_TRIP_RESERVATION_EDIT}`)[0];
    }

    return {
      onBack: () => navPush(router, backPath)
    };
  } else if (facilitiesReservation.selectedPage === 'reservation') {
    return {
      onBack: () => actions.selectPage('type')
    };
  } else {
    return {
      onBack: () => actions.selectPage('reservation')
    };
  }
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    facilitiesReservation: state.facilities.trip.reservation.main,
    cacheThreeFacilities: state.cacheThreeFacilities,
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving
  };
};
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators(actionCreators, dispatch)});

export default (): any => {
  const FaciliesWizardHeader = (props: ConnectedProps): React.ReactElement<{}> => {
    const specs = getHeaderSpecs(props);
    const trip = props.cacheThreeFacilities.FacilityTrip as FacilityTrip;
    const isEdit = location.pathname.endsWith(`/${FAC_TRIP_RESERVATION_EDIT}`) ? true : false;

    return <AppHeader
      showDrawer
      title={isEdit ? 'Edit Reservation' : 'New Reservation'}
      subTitle={trip ? `${trip.Name} (${displayTimeFrame(trip.StartDateTime, trip.EndDateTime)})` : ''}
      onBack={specs.onBack}
      disableBack={props.apiLoading > 0 || props.apiSaving > 0}
      {...props}
    />;
  };

  const ConnectedFaciliesWizardHeader = connect(
    mapStateToProps,
    mapDispatchToProps,
    getMergeProps<WithRouterProps>(),
  )(FaciliesWizardHeader);
  return withRouter<{}>(ConnectedFaciliesWizardHeader);
};
