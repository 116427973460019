import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';

import '../../../styles/elements/form/index.scss';
import Loader from '../Loader';

import {namespace} from "./constants";


interface Props {
  children?: React.ReactNode;
  actions?: React.ReactNode;
  onSubmit?: React.EventHandler<any>;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  opacity?: number;
  /**
   * Whether to enable autocomplete of the form or not
   */
  autocomplete?: "on" | "off";
  marginTop?: number; // @todo: use attributes instead (import attributes, { xxx } from '../../../styles'; interface Props extends xxx {}; className = attributes(className, props); )
}

const defaultOnSubmit = (e) => e.preventDefault();
class Form extends React.PureComponent<Props, any> {
  public render() {
    const { children, actions, disabled, loading, opacity, onSubmit, marginTop, autocomplete, ...props } = this.props;

    const style: React.CSSProperties = {};
    if (opacity) style.opacity = opacity;
    if (marginTop || marginTop === 0) style.marginTop = `${marginTop}px`;

    let className = mergeClassNames(namespace(), props);

    let wrapperClassName = `${namespace()}--wrapper`;
    if (actions) wrapperClassName += ' has-actions';
    if (loading) wrapperClassName += ' loading';
    if (disabled) wrapperClassName += ' disabled';

    return (
      <form
        method="POST"
        noValidate
        style={style}
        className={className}
        // @ts-ignore
        disabled={disabled || loading ? true : false}
        onSubmit={onSubmit || defaultOnSubmit}
        autoComplete={autocomplete}
      >
        <div className={wrapperClassName}>
          {children}
        </div>
        {actions}
        {loading  ? (
          <div className={`${namespace()}--loader`}>
            <Loader cover pop disablePointerEvents/>
          </div>
        ) : null}
      </form>
    );
  }
}

export default Form;
export { default as FormActions } from './Actions';
