import * as React from 'react';
import {
  Button,
  HeaderBlock,
  HeaderBlockPadding,
  ActionButton
} from '../../../../../Elements';
import {bindActionCreators} from 'redux';
import {
  actionCreators as appActionCreators,
} from '../../../../../../store/App/actions';
import { actionCreators as pageFormActionCreators } from '../../../../../../store/AdminCMSSite/Pages/Page/Form/actions';
import {RouteComponentProps, withRouter} from 'react-router';
import { ApplicationState } from '../../../../../../store';
import { CMSPage, PageTypeIDValue } from '../../../../../../models/api/adminCMSCacheOne';
import '../../../../../../styles/pages/cms-pages/edit-page/page-info.scss';
import { LinkIcon, OptionsIcon } from '../../../../../../components/Icons';
import ContextMenuComponent from './ContextMenu';
import { getPageIcon } from '../../../../../../utils/helpers/adminCMSPageHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { noOpenedModals } from '../../../../../../utils/modalHelper';

export const namespace = (): string => 'pages--cms--page--info';

export interface PageInfoProps {
  hasEdit: boolean;
  page?: CMSPage;
  onSave: () => any;
  onDelete: () => any;
  onDuplicate: () => any;
  onCopyLink: () => any;
  onVisitPage: () => any;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {eventTypeId?: string, eventId?: string, name?: string}>;


// @todo: maybe this CampInfo should be same as facilities campinfo? though they look different enough that they can not be the same
@(withRouter as any)
class PageInfo extends React.Component<PageInfoProps, {}> {
  public props: PageInfoProps & ConnectedProps;

  onVisitPageClick = (e) => {
    this.props.onVisitPage();
  };
  onCopyLinkClick = (e) => {
    this.props.onCopyLink();
  };
  onDuplicateClick = (e) => {
    this.props.onDuplicate();
  };
  onSavePageClick = (e) => {
    this.props.onSave();
  };
  onDeletePageClick = (e) => {
    this.props.onDelete();
  };
  public render() {
    const { apiLoading, hasEdit, page, adminCMSCacheOne } = this.props;

    if (!page) return null;

    const isLoading = apiLoading > 0 && noOpenedModals();
    const pageType = adminCMSCacheOne.PageTypes ? adminCMSCacheOne.PageTypes.find((p) => p.ID === page.PageTypeID) : null;
    return (
      <HeaderBlock wide leftGutter hasLeftSideBar leftGutterSize={356}>
        <HeaderBlockPadding padding="16px 0" mobilePadding={false}>
          <div className={`${namespace()}--wrapper`}>
            <div className={`${namespace()}--wrapper--row`}>
              <div className={`${namespace()}--wrapper--title`}>{page.Name}</div>
              <ActionButton
                icon={OptionsIcon}
                id={generateDOMId("page-more-options")}
                disabled={isLoading}
                className={`${namespace()}--options`}
                contextMenu={<ContextMenuComponent
                  renderIds
                  onDelete={this.onDeletePageClick}
                  onVisitPage={this.onVisitPageClick}
                  onCopyLink={this.onCopyLinkClick}
                  onDuplicate={this.onDuplicateClick} />}
              />
              <Button id={generateDOMId("admin-page-save-btn")} disabled={!hasEdit || isLoading} style={{flex: '0 0 auto', height: '36px'}} marginLeft={18} className='control' color='green' textColor='white' onClick={this.onSavePageClick}>SAVE</Button>
            </div>
            <div className={`${namespace()}--wrapper--row`}>
              {pageType ? <div className={`${namespace()}--wrapper--page-type`}>
                <div className={`${namespace()}--wrapper--page-type--icon ${page.PageTypeID === PageTypeIDValue.HOMEPAGE ? 'home' : ''}`}>{getPageIcon(page)}</div>
                {<div className={`${namespace()}--wrapper--page-type--name ${page.PageTypeID === PageTypeIDValue.HOMEPAGE ? 'home' : ''}`}>{page.PageTypeID === PageTypeIDValue.HOMEPAGE ? 'Homepage' : pageType.AddingName}</div>}
              </div> : null}
              {page.ShortURL ? <div className={`${namespace()}--wrapper--short-url`}>
                <div className={`${namespace()}--wrapper--short-url--icon`}><LinkIcon /></div>
                <div className={`${namespace()}--wrapper--short-url--name`}>{`/${page.ShortURL}`}</div>
                </div> : null}
            </div>
          </div>
        </HeaderBlockPadding>
      </HeaderBlock>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    adminCMSCacheTwoPage: state.adminCMSSite.cacheTwoPage,
    pageForm: state.adminCMSSite.pages.page.form,
    apiLoading: state.app.apiLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...pageFormActionCreators,
  }, dispatch)
});

const ConnectedPageInfo = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<PageInfoProps>(),
)(PageInfo);

export default ConnectedPageInfo;
