import {getEventProducts} from "../../../../../../constants/urls";
import {makeRequestEpic} from "../../../../../Validation/epicCreator";
import {ApiSubmitActionsProductsProducts} from "./actions";

export const productsProductsNext = makeRequestEpic(
  ApiSubmitActionsProductsProducts,
  getEventProducts,
  undefined,
  true
);
