import * as React from 'react';

import { Form, FormActions, Paragraph, Select, TextField, Button, View, Row, Column, Alert, Media } from '../../../Elements';
import { Main, MainContent } from '../../../Layouts';
import { bindActionCreators } from 'redux';
import { appActionCreators } from '../../../../store/App';
import { actionCreators as rollbackActionCreators, SaveState } from '../../../../store/Rollback/actions';
import { actionCreators as createAccountActionCreators } from '../../../../store/CreateAccount/actions';
import { RouteComponentProps, withRouter } from 'react-router';
import { NotFound } from '../../';
import {GET_LOGIN_FAIL_MESSAGE, GET_LOGIN_FAIL_TITLE} from "../../../../constants/messages/login";
import { shouldReconfigRouter } from '../../../../utils/cacheLoaders/reloaderHelper';
import { ApplicationState } from '../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../utils/reduxHelper';
import UnauthenticatedCacheManager from '../../../../utils/cacheManagers/unauthenticatedCacheManager';
import { isUnitGenderIDShown } from '../../../../store/AddGroup/validationHelpers';
import { reduxStoreService } from '../../../../store/service';
import { Validator } from '../../../../utils/validator/models';
import { ComponentUpdateTemplate } from '../../../Templates/ComponentUpdateTemplate';
import { WithInertAttribute } from '../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {}>
>;

@(withRouter as any)
class NewAccount extends ComponentUpdateTemplate<ConnectedProps> {

  public props: ConnectedProps;

  public componentDidMount() {
    this.configRouteLeaveHook();
    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        UnauthenticatedCacheManager.getInstance().loadRegisterForm({
          props: this.props,
          isStateNavigated
        });
      }
    );
  }

  public componentWillReceiveProps(nextProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        UnauthenticatedCacheManager.getInstance().loadRegisterForm({
          props: nextProps,
          isStateNavigated
        });
      }
    );
  }

  componentDidUpdate(prevProps: ConnectedProps) {
    if (shouldReconfigRouter(prevProps, this.props)) {
      this.configRouteLeaveHook();
    }
  }

  componentWillUnmount() {
    this.resetRouteLeaveHook();
  }

  private configRouteLeaveHook() {
    const route = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(route, this.routerWillLeave);
  }

  routerWillLeave = (nextLocation) => {
    if (reduxStoreService().getState().app.apiLoading === 0) {
      reduxStoreService().dispatch(new SaveState());
    }
  };

  private resetRouteLeaveHook() {
    const {router, routes} = this.props;

    router.setRouteLeaveHook(routes[routes.length - 1], () => {});
  }

  public handleSubmit = e => {
    const { apiLoading, apiSaving } = this.props;
    e.preventDefault();
    e.stopPropagation();
    if (!(apiLoading > 0 || apiSaving > 0)) this.props.actions.apiSubmitForm();
  };

  public render() {
    const { apiLoading, inert, apiSaving, createAccount, session: {AllowSelfSignup, getLoginFormFailed} } = this.props;
    if (AllowSelfSignup === false) { // undefined doesn't count here, need to make sure i got a response
      return <NotFound title={GET_LOGIN_FAIL_TITLE} message={GET_LOGIN_FAIL_MESSAGE}/>;
    }
    if (getLoginFormFailed) return <NotFound />;
    return (
      <Main inert={inert}>
        <MainContent>
          <Form
            loading={!createAccount.Loaded || apiSaving > 0}
            onSubmit={this.handleSubmit}
            actions={createAccount.ActiveForm.RegistrationType !== undefined && createAccount.Loaded ? (
              <FormActions loading={!createAccount.Loaded || apiSaving > 0}>
                <Button flat textColor="black" submit disabled={apiLoading > 0 || apiSaving > 0}>Complete</Button>
              </FormActions>
            ) : null}
          >
            {createAccount.Loaded && createAccount.RegisterForm && createAccount.RegisterForm.RegistrationTypeOptions.length > 2 && this.renderHeader()}
            {createAccount.Loaded && this.renderForm()}
          </Form>
        </MainContent>
      </Main>
    );
  }

  private renderHeader() {
    const {createAccount: {RegisterForm}, session} = this.props;
    let firstParagraph: string = 'Please select an event or location with facility rentals to create an account.';
    let secondParagraph: string = 'Once created, new accounts can also be used for other event registrations and facility rentals.';
    if (session.SystemSettings && session.SystemSettings.HasActiveEventTypes && !session.SystemSettings.HasActiveFacilities) {
      firstParagraph = 'Please select an event to create an account.';
      secondParagraph = 'Once created, new accounts can also be used for other event registrations.';
    } else if (session.SystemSettings && !session.SystemSettings.HasActiveEventTypes && session.SystemSettings.HasActiveFacilities) {
      firstParagraph = 'Please select a location with facility rentals to create an account.';
      secondParagraph = 'Once created, new accounts can also be used for other facility rentals.';
    }
    return (
      <View layout="vertical">
        <Paragraph>
          {firstParagraph}
        </Paragraph>
        <Paragraph>
          {secondParagraph}
        </Paragraph>
      </View>
    );
  }

  private usernameOnBlur = (value: string | number | undefined, validationRules: Validator) => {
    if (!this.props.createAccount.ActiveForm.Username) {
      this.props.actions.updateValue(value, validationRules);
    }
  };

  private emailOnBlur = (value: string | number | undefined, validationRules: Validator) => {
    if (!this.props.createAccount.ActiveForm.Email) {
      this.props.actions.updateValue(value, validationRules);
    }
  };

  renderUnitFields = () => {
    const {createAccount: {ActiveForm, ValidationRules}, actions } = this.props;
    return (
      [(
        <Media tabletAndGreater key="unit-tablet-and-greater">
          <Row>
            <Column span={6}>
              <Select label="Unit Type"
                      onChangeValue={actions.updateValue}
                      value={ActiveForm.UnitTypeID}
                      validationRules={ValidationRules.UnitTypeID}
                      isNumber
              />
            </Column>
            <Column span={3}>
              <TextField label="Unit"
                          onChange={actions.simpleUpdate}
                          onBlur={actions.updateValue}
                          validationRules={ValidationRules.Unit}
                          value={ActiveForm.Unit}/>
            </Column>
            {isUnitGenderIDShown(() => ActiveForm) && <Column span={3}>
              <Select label="Gender"
                          onChangeValue={actions.updateValue}
                          value={ActiveForm.UnitGenderID}
                          validationRules={ValidationRules.UnitGenderID}
                          isNumber/>
            </Column>}
          </Row>
        </Media>
      ), (
        <Media style={{flexDirection: "column"}} mobile key="unit-mobile">
          <Row>
            <Column span={12}>
              <Select label="Unit Type"
                      onChangeValue={actions.updateValue}
                      value={ActiveForm.UnitTypeID}
                      validationRules={ValidationRules.UnitTypeID}
                      isNumber
              />
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <TextField label="Unit"
                          onChange={actions.simpleUpdate}
                          onBlur={actions.updateValue}
                          validationRules={ValidationRules.Unit}
                          value={ActiveForm.Unit}/>
            </Column>
            {isUnitGenderIDShown(() => ActiveForm) && <Column span={6}>
              <Select label="Gender"
                          onChangeValue={actions.updateValue}
                          value={ActiveForm.UnitGenderID}
                          validationRules={ValidationRules.UnitGenderID}
                          isNumber/>
            </Column>}
          </Row>
        </Media>
      ), (
        <Row key="council-district-row">
          <Column span={6} mobileSpan={12}>
            <Select label="Council"
                    onChangeValue={actions.updateValue}
                    value={ActiveForm.CouncilIDi}
                    validationRules={ValidationRules.CouncilIDi}
                    isNumber
            />
          </Column>
          <Column span={6} mobileSpan={12}>
            <Select label="District"
                    onChangeValue={actions.updateValue}
                    value={ActiveForm.DistrictIDi}
                    validationRules={ValidationRules.DistrictIDi}
                    isNumber
            />
          </Column>
        </Row>
      )]
    );
  };

  private renderForm() {
    const { createAccount: { ActiveForm, ValidationRules, SubmitErrorMessage, RegisterForm }, session, actions } = this.props;
    const regTypeClass = (RegisterForm && RegisterForm.RegistrationTypeOptions.length > 2) ? '' : 'hidden';

    let selectLabel: string = 'Event or location';
    if (session.SystemSettings && session.SystemSettings.HasActiveEventTypes && !session.SystemSettings.HasActiveFacilities) {
      selectLabel = 'Event';
    } else if (session.SystemSettings && !session.SystemSettings.HasActiveEventTypes && session.SystemSettings.HasActiveFacilities) {
      selectLabel = 'Location';
    }

    return (
      <View layout="vertical">
        {SubmitErrorMessage && (
          <Row>
            <Alert>{SubmitErrorMessage}</Alert>
          </Row>
        )}
        <Row>
          <Select
            label={selectLabel}
            bold
            onChangeValue={actions.updateValue}
            value={ActiveForm.RegistrationType}
            validationRules={ValidationRules.RegistrationType}
            className={regTypeClass}
          />
        </Row>

        {ActiveForm.RegistrationType !== undefined
          && ActiveForm.RegistrationType.EventTypeID !== -1
          && ActiveForm.RegistrationType.FacilityLocationID !== -1 && (
          <View layout="vertical">
            {ActiveForm.ShowGroupType && (
              <Row>
                <Column span={12} mobileSpan={12}>
                  <Select
                    label="Account Type"
                    onChangeValue={actions.updateValue}
                    value={ActiveForm.GroupTypeID}
                    validationRules={ValidationRules.GroupTypeID}
                    isNumber
                  />
                </Column>
              </Row>
            )}
            {ActiveForm.GroupTypeID === 4 && (<Row>
              <Column span={12} mobileSpan={12}>
                <TextField label="Organization"
                           onChange={actions.simpleUpdate}
                           onBlur={actions.updateValue}
                           validationRules={ValidationRules.Organization}
                           value={ActiveForm.Organization}/>
              </Column>
            </Row>)}
            {ActiveForm.GroupTypeID === 1 && this.renderUnitFields()}
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField value={ActiveForm.FirstName}
                           label="First Name"
                           onChange={actions.simpleUpdate}
                           onBlur={actions.updateValue}
                           validationRules={ValidationRules.FirstName}/>
              </Column>
              <Column span={6} mobileSpan={12}>
                <TextField value={ActiveForm.LastName}
                           label="Last Name"
                           onChange={actions.simpleUpdate}
                           onBlur={actions.updateValue}
                           validationRules={ValidationRules.LastName}/>
              </Column>
            </Row>
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField value={ActiveForm.PhoneNumber}
                           label="Phone Number"
                           onChange={actions.simpleUpdate}
                           onBlur={actions.updateValue}
                           validationRules={ValidationRules.PhoneNumber}/>
              </Column>
              <Column span={6} mobileSpan={12}>
                <TextField label="Email"
                           onChange={actions.updateValueEmail}
                           onBlur={this.emailOnBlur}
                           validationRules={ValidationRules.Email}
                           value={ActiveForm.Email}/>
              </Column>
            </Row>
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField value={ActiveForm.Username}
                           label="Username"
                           onChange={actions.updateValue}
                           onBlur={this.usernameOnBlur}
                           validationRules={ValidationRules.Username}/>
              </Column>
            </Row>
            <Row>
              <Column span={6} mobileSpan={12}>
                <TextField value={ActiveForm.Password}
                           label="Password"
                           type="password"
                           onBlur={actions.updateValue}
                           onChange={actions.simpleUpdate}
                           validationRules={ValidationRules.Password}/>
              </Column>
              <Column span={6} mobileSpan={12}>
                <TextField value={ActiveForm.ConfirmPassword}
                           label="Confirm Password"
                           type="password"
                           onBlur={actions.updateValue}
                           onChange={actions.simpleUpdate}
                           validationRules={ValidationRules.ConfirmPassword}/>
              </Column>
            </Row>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    session: state.session,
    createAccount: state.createAccount,
    apiLoading: state.app.apiLoading,
    apiSaving: state.app.apiSaving,
    apiLoadingMap: state.app.apiLoadingMap,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...appActionCreators, ...createAccountActionCreators, ...rollbackActionCreators }, dispatch)
});

const ConnectedNewAccount = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(NewAccount);

export default ConnectedNewAccount;
export { default as Header } from './Header';
