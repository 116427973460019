import * as React from 'react';
import {default as AppHeader} from '../../../../App/Header';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { FACILITY_LOCATION_FACILITY_TYPE, getFacilityTypesRootUrl } from '../../../../../constants/urls';
import { ApplicationState } from '../../../../../store';
import { bindActionCreators } from 'redux';
import { actionCreators as rollbackActionCreators } from '../../../../../store/Rollback/actions';
import { actionCreators as cacheTwoFacilityTypesActionCreators } from '../../../../../store/AdminFacilityLocation/CacheTwoFacilityTypes/actions';
import { extractRouteParams } from '../../../../../utils/urlHelper';
import { getFacilityTypeIdFromPath, IAdminFacilityTypeRouterParams } from '../../../../../utils/helpers/adminFacilityLocationPageHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<IAdminFacilityTypeRouterParams, {}>;

const Header: React.SFC<ConnectedProps> = (props: ConnectedProps): React.ReactElement<undefined> => {
  const sp = props.location.pathname.split('/');
  const isAdd = sp[sp.length - 1] === FACILITY_LOCATION_FACILITY_TYPE.NEW;
  
  const location = props.FacilitiesLocation;

  const customRefresh = () => {
    // always save state before refreshing during edit
    props.actions.saveState();
    !isAdd && location && props.actions.refreshFacilityType({
      ID: getFacilityTypeIdFromPath(props.params),
      ...extractRouteParams(props),
    });
  };
  // fall back to root
  const backUrl = location ? getFacilityTypesRootUrl({
    locationId: location.ID,
    locationName: location.Name,
  }) : '/admin2';

  return <AppHeader
    showDrawer
    back={backUrl}
    disableBack={!location}
    title={isAdd ? 'New Facility Type' : 'Edit Facility Type'}
    subTitle={location ? location.Name : ''}
    refresh={!isAdd}
    customRefresh={customRefresh}
  />;
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    FacilitiesLocation: state.adminFacilityLocation.cacheOne.FacilitiesLocation,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    cacheZero: state.cacheZero,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...rollbackActionCreators,
    ...cacheTwoFacilityTypesActionCreators,
  }, dispatch),
});
const ConnectedHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(Header);
export default withRouter<{}>(ConnectedHeader);
