import {GetFacilityTripData} from './actions';
import {getFacilityTripData, getFacilityTripDataBody} from '../../constants/urls';
import {makeRequestEpic} from "../Validation/epicCreator";

export const cacheThreeFacilitiesEpic = makeRequestEpic(
  GetFacilityTripData,
  getFacilityTripData,
  getFacilityTripDataBody,
  true
);
