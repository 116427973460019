import * as React from 'react';
import Header, {headerOptions} from '../../Header';
import {GroupRosterPerson} from '../../../../../models/api/cacheTwoRoster';
import { captureTentarooError } from '../../../../../utils/dataHelper';
import { ApplicationState } from '../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { RouteComponentProps } from 'react-router';

type ConnectedProps = ReturnType<typeof mapStateToProps> & RouteComponentProps<{}, {id: string; ya: string;}>;

export default (options: headerOptions) => {
  const HeaderComponent = Header(options);


  const Component:React.SFC<ConnectedProps> = (props: ConnectedProps):React.ReactElement<ConnectedProps> => {
    if (props.routeParams.id) {
      if (props.cacheThreeRoster.GroupRosterPerson) {
        return <HeaderComponent {...props as any} title={`Edit ${props.cacheThreeRoster.GroupRosterPerson.Name}`}/>;
      }
      let name: string = 'Person'; // can happen, see NOTE below
      let people: Array<GroupRosterPerson> | undefined | null = [];
      if (props.routeParams.ya === 'youth') people = props.cacheTwoRoster.GroupRosterYouth;
      else people = props.cacheTwoRoster.GroupRosterAdults;

      if (people) {
        const findIDi = Number(props.routeParams.id);
        const person = people.find((p) => p.IDi === findIDi);
        if (person) {
          // NOTE: `person` could be undefined when we are navigating to a deleted person from cacheTwo page,
          // but we didn't toggle "Show Deleted", in that case, we show "Edit Person" momentarily before cache
          // three is loaded
          name = person.Name;
        }
      }

      return <HeaderComponent {...props as any} title={`Edit ${name}`} />;
    }
    let title = 'New Adult';
    if (props.routeParams.ya === 'youth') title = 'New Youth';
    return <HeaderComponent {...props as any} title={title} />;
  };

  const ConnectedComponent = connect(
    mapStateToProps,
    null,
    getMergeProps<{}>(),
  )(Component);
  
  return ConnectedComponent;
};

const mapStateToProps = (state: ApplicationState) => (
  {cacheThreeRoster: state.cacheThreeRoster, cacheTwoRoster: state.cacheTwoRoster}
);
