import * as React from 'react';
import {mergeClassNames} from '@tentaroo/shared';
import { namespace as ns } from './constants';
import '../../../styles/elements/camp-info/warning.scss';

export const namespace = (): string => `${ns()}--warning`;

export interface WarningProps {
  className?: string;
  children?: React.ReactNode;
  size?: number;
  line?: number;
  float?: boolean;
}

const Warning: React.SFC<WarningProps> = ({ children, size, line, float, ...props }: WarningProps): React.ReactElement<WarningProps> => (
  <div className={`${mergeClassNames(namespace(), props)} ${size ? `size-${size}` : ''} ${line ? `line-${line}` : ''} ${float ? 'float' : ''}`}>
    {children}
  </div>
);

export default Warning;
