import * as React from 'react';
import {GroupIcon, LocationsIcon, MailIcon, OrdersIcon, PhoneIcon, ProfileIcon} from '../../Icons';
import {Button, Card, IconRow, IconRowLink, Media, Separator, SideBarNav, SideBarNavItem, Title} from '../../Elements';
import {RouteComponentProps, withRouter} from 'react-router';
import {getFullAddress, navPush} from '../../../utils';
import {openSupportForm, URLS} from '../../../constants/urls';
import '../../../styles/pages/home/contact.scss';
import {bindActionCreators} from 'redux';
import {actionCreators} from "../../../store/App/actions";
import {ApplicationState} from "../../../store";
import { captureTentarooError } from '../../../utils/dataHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

const namespace = (): string => 'pages--home--contact';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;

export interface ContactProps {
  component?: React.ReactElement<any>;
  forceTablet?: boolean;
}

@(withRouter as any)
class Contact extends React.PureComponent<ContactProps, {}> {
  public props: ContactProps & ConnectedProps;

  onManageProfile = () => navPush(this.props.router, URLS.PROFILE);
  onManageMyGroup = () => navPush(this.props.router, URLS.MY_ROSTER);
  onManageOrderHistory = () => navPush(this.props.router, URLS.ORDER_HISTORY);
  showFeedback = () => {
    openSupportForm();
  };
  public render() {
    const {component, forceTablet, session} = this.props;
    let renderComponent;
    if (!component) renderComponent = <Card className={namespace()} padding="none" template="mobile-no-margin"/>;
    else renderComponent = component;
    if (!session.SystemSettings) {
      captureTentarooError(new Error('Home page loading without system settings'));
      return null;
    }
    return React.cloneElement(renderComponent, {
      ...renderComponent.props,
      children: [
        <SideBarNav key={0} forceTablet={forceTablet}>
          <SideBarNavItem icon={<ProfileIcon/>} onClick={this.onManageProfile} forceTablet={forceTablet}>Manage Profile</SideBarNavItem>
          {/*<SideBarNavItem icon={<BillingIcon/>} forceTablet={forceTablet}>Manage Billing Info</SideBarNavItem>*/}
          <SideBarNavItem icon={<GroupIcon/>} onClick={this.onManageMyGroup} forceTablet={forceTablet}>Manage My Group</SideBarNavItem>
          <SideBarNavItem icon={<OrdersIcon/>} onClick={this.onManageOrderHistory} forceTablet={forceTablet}>View Previous Orders</SideBarNavItem>
        </SideBarNav>,
        <Separator key={1}/>,
        <Title
          size={20}
          key={2}
          className={`${namespace()}--title`}
          controls={(
          <Media tablet desktopOnly>
            <Button color="white" onClick={this.showFeedback}>
              LEAVE FEEDBACK
            </Button>
          </Media>
        )}
        >
          {session.SystemSettings.CampName}
        </Title>,
        <IconRow className={`${namespace()}--icon-link`} icon={LocationsIcon} key={3}>
          {getFullAddress(session.SystemSettings)}
        </IconRow>,
        session.SystemSettings.Phone ? <IconRowLink className={`${namespace()}--icon-link`} icon={PhoneIcon} key={4}>
          {session.SystemSettings.Phone}
        </IconRowLink> : null,
        <IconRowLink className={`${namespace()}--last-link ${namespace()}--icon-link`} icon={MailIcon} key={5}>
          {session.SystemSettings.FromEmail}
        </IconRowLink>,
        <Media mobile largeDesktopAndGreater className={`${namespace()}--button`} key={7}>
          <Button color="white" onClick={this.showFeedback}>
            LEAVE FEEDBACK
          </Button>
        </Media>
      ]
    });
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    user: state.user,
    options: state.cacheZero.options,
    session: state.session
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...actionCreators }, dispatch)
});

const ConnectedContact = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<ContactProps>(),
)(Contact);

export default ConnectedContact;
