
import {mergeClassNames} from '@tentaroo/shared';

import * as React from 'react';

import '../../../styles/elements/select/index.scss';
import {slug} from '../../../utils';
import ArrowIcon from '../../../images/elements/select/arrow.svg';
import {isOptional} from '../../../utils/validator';
import {Option, StaticTextField, Text} from '../';
import { SelectValidator, Validator } from '../../../utils/validator/models';
import { reduxStoreService } from '../../../store/service';

export const namespace = (): string => 'elements--select';

// @todo: is there a reason we're just using straight up <select> here and not a lib?

export interface SelectProps {
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  label?: string;
  customizedId?: string;
  value?: any;
  bold?: boolean;
  disabled?: boolean;
  onChange?: React.EventHandler<any>; // @todo: hm this doesn't exception function?
  onChangeValue?: (value: string | number | undefined, validationRules?: Validator) => any; // @todo: typescript
  noBottomPadding?: boolean; // @todo: this probably just shouldn't have default padding... I'd like to remove it, but I don't wanna break everything relying on it
  validationRules?: SelectValidator;
  isNumber?: boolean; // @todo: remove this one day when select allows number/object and not just string
  hideError?: boolean;
  editable?: boolean;
  info?: any;
  hideOptional?: boolean;
  noBottomBorder?: boolean;
  mapValueToIcon?: (id: number) => React.ReactNode;
  infoOverflow?: boolean;
  overflowLabel?: boolean;
}

interface State {
  showOptions?: boolean;
}

class Select extends React.Component<SelectProps, State> {
  private infoRef;
  private selectRef;
  private containerRef;

  public static defaultProps = {
    editable: true
  };

  state = {
    showOptions: false,
  };

  public props: SelectProps;

  handleClickOutside = (e) => {
    if (!this.selectRef || !(this.selectRef as any).contains(e.target)) {
      this.setState({
        showOptions: false,
      });
    }
  };

  public componentDidMount() {
    const {icon} = this.props;
    icon && document.addEventListener('click', this.handleClickOutside);
    this.updateInfoStyle();
  }

  public componentDidUpdate() {
    this.updateInfoStyle();
  }

  updateInfoStyle = () => {
    if (this.infoRef && this.props.infoOverflow) {
      const select = this.infoRef.firstElementChild;
      const info = this.infoRef.lastElementChild;

      if (select && info) {
        if (!select.classList.contains('error')) select.style.paddingBottom = `${info.clientHeight + 8}px`;
        else select.style.paddingBottom = '24px';
      }
    } else if (this.containerRef) {
      this.containerRef.style.paddingBottom = '0px';
    }
  };

  public componentWillUnmount() {
    this.props.icon && document.removeEventListener('click', this.handleClickOutside);
  }

  public handleChange = e => {
    if (this.props.onChange) this.props.onChange(e);
    if (this.props.onChangeValue) {
      let val = e.target.value;
      if (this.props.isNumber) val = Number(val);
      this.props.onChangeValue(val, this.props.validationRules);
    }
  };

  private renderValidationOptions = (v, index) => {
    const {validationRules} = this.props;
    if (validationRules) {
      return <Option key={index} value={validationRules.options.valueKey(v)}>{v[validationRules.options.labelKey]}</Option>;
    }
    return null;
  };

  private renderOptions() {
    const {children, validationRules} = this.props;
    if (validationRules && validationRules.options) {
      return validationRules.options.values(reduxStoreService().getState()).map(this.renderValidationOptions);
    }
    return children;
  }

  private renderValidationOptionsWithIcon = (v, index) => {
    const {validationRules, icon, mapValueToIcon, onChangeValue} = this.props;

    if (validationRules) {
      const value = validationRules.options.valueKey(v);
      if (icon && mapValueToIcon){
        return (
          <div className={`${namespace()}--option-wrapper`} onClick={() => {icon && this.setState({showOptions: false}); onChangeValue && onChangeValue(value, validationRules);}}>
            <div className={`${namespace()}--option-icon-wrapper`}>{mapValueToIcon(Number(value))}</div>
            <div className={`${namespace()}--option-label`}>{v[validationRules.options.labelKey]}</div>
          </div>
        );
      }
    }
  };

  private renderOptionsWithIcon = () => {
    const {children, validationRules} = this.props;
    if (validationRules && validationRules.options) {
      return (
        <div className={`${namespace()}--icon-optoins-wrapper`}>
          {validationRules.options.values(reduxStoreService().getState()).map(this.renderValidationOptionsWithIcon)}
        </div>
      );
    }
    return children;
  };

  public render() {
    const props = this.props;
    const { noBottomPadding, infoOverflow, validationRules, onChangeValue, hideError, disabled, editable, info, hideOptional, icon, overflowLabel, customizedId, noBottomBorder } = this.props;
    const error = validationRules && !hideError ? validationRules.errors : undefined;
    let value = this.props.value;
    if (value && validationRules && validationRules.getValue) value = validationRules.options.valueKey(this.props.value);
    let label;
    const id = customizedId ? customizedId : (props.label ? `${namespace()}-${slug(props.label)}` : undefined);
    const labelClassName = `${namespace()}--label`;
    let selectClassName = `${namespace()}--select`;
    if (props.value === undefined || props.value === null || (validationRules && validationRules.options && props.value === validationRules.options.emptyValue)) {
      label = (
        <label htmlFor={id} className={labelClassName + (props.bold ? ' bold' : '')}>
          {props.label}
        </label>
      );
      selectClassName += ' hidden';
    } else if (props.label) {
      label = <label htmlFor={id} className={`${labelClassName} fixed`}>{props.label}</label>;
    }

    if (props.bold) selectClassName += ' bold';
    if (disabled) {
      selectClassName += ' disabled-with-appearance';
    }
    if (!editable) {
      if (validationRules) {
        const actualValue = validationRules.options.values(reduxStoreService().getState()).find((val) => value === validationRules.options.valueKey(val));
        if (!actualValue) {
          return <StaticTextField {...this.props as any} value={value}/>;
        }
        return <StaticTextField {...this.props as any} value={actualValue[validationRules.options.labelKey]}/>;
      }
    }

    const select = (
      <div
        ref={(ref) => this.selectRef = ref}
        className={`${mergeClassNames(namespace(), props)} ${noBottomPadding ? 'no-padding-bottom' : ''} ${error && error.length > 0 ? 'error' : '' } ${info ? 'has-info' : ''} ${icon ? 'icon' : ''} ${noBottomBorder ? 'no-border-bottom' : ''}${overflowLabel ? ' label-overflow' : ''}`}
        onClick={() => icon && !disabled && this.setState({showOptions: !this.state.showOptions})}>
        <div ref={(ref) => this.containerRef = ref} className={`${namespace()}--container`}>
          <select
            disabled={!!icon || disabled}
            onChange={this.handleChange}
            id={id}
            className={selectClassName}
            value={value}
          >
            {!icon && this.renderOptions()}
          </select>
          {icon && <div className={`${namespace()}--icon-wrapper`}>{icon}</div>}
          {icon && this.state.showOptions && this.renderOptionsWithIcon()}
          {label}
          <ArrowIcon width="11px" height="7px" className={`${namespace()}--arrow`}/>
          {!hideOptional && isOptional(validationRules) && editable && <div className={`${namespace()}--optional ${!value || (validationRules && validationRules.options && value === validationRules.options.emptyValue) ? "emptyField" : ""}`}>optional</div>}
        </div>
        {error && error.length > 0 && <span className={`${namespace()}--error`}>{error[0]}</span>}
      </div>
    );

    if (info) {
      return (
        <div ref={(ref) => this.infoRef = ref} className={`${namespace()}--select-container ${error && error.length > 0 ? 'error' : '' } ${infoOverflow ? 'info-overflow' : ''}`}>
          {select}
          <Text additionalInfo className={`${namespace()}--text-info`}>{info}</Text>
        </div>
      );
    }
  
    return select;
  }
}

export { default as Option } from './Option';
export default Select;
