import { createSelector } from 'reselect';
import { SendMessageModalFilter, SendMessageToggleFilterTabs, ResetSendMessageModal, AddGroups, RemoveGroup, InitStepTwo, ClearError } from './actions';
import { ApplicationState } from '../../../..';
import { IValidator, FormDefinition } from './validation';
import { apiValidationReducerCreator } from '../../../../Validation/reducerCreator';
import { SUFFIX } from './actions';
import { AdminEventsEvent } from '../../../../../models/api/adminEventsCacheOne';
import { filteredEventsFromCurrentEventTypeCombiner } from '../../../Events/Modals/ImportFromEvent';
import { eventsSelector } from '../../../Events/Home';
import { groupsSelector } from '../../../Events/Modals/MultipleGroups';
import { AdminEventsGroupEventRegistration } from '../../../../../models/api/adminEventsCacheTwoEvent';
import { ResetAdminEventsRegisteredGroup } from '../../../Shared/actions';
import { setDefaults } from '../../../../../utils/validator';
import { AnyAction, Reducer } from 'redux';
import { isActionType } from '../../../../../utils/StrongActions';
import { WithRootState } from '../../../../Validation/actionCreator';

export interface SendMessageModalActiveFormState {
  SelectedEventID?: number;
  ShowEventsFromPastYear?: boolean;
  IsAllRegisteredGroups?: boolean;
  SelectedGroups?: number[];
}

export interface SendMessageModalState {
  isRefreshing: boolean;
  filterString: string;
  isTextSearching: boolean;
  ValidationRules: IValidator;
  ActiveForm: SendMessageModalActiveFormState;
  SubmitErrorMessage?: string;
}
const getInitialState = () => ({
  isRefreshing: false,
  filterString: '',
  isTextSearching: false,
  ValidationRules: {...FormDefinition},
  ActiveForm: { },
});

const filterSelector = (state: ApplicationState) => state.adminEvents.messageCenter.modals.sendMessage.filterString;
const showEventsFromPastYearsSelector = (state: ApplicationState) => state.adminEvents.messageCenter.modals.sendMessage.ActiveForm.ShowEventsFromPastYear;

export const makeFilteredEventsSelector = () => {
  return createSelector(
    [eventsSelector, filterSelector, showEventsFromPastYearsSelector],
    filteredEventsFromCurrentEventTypeCombiner,
  );
};

const selectedGroupIdsSelector = (state: ApplicationState) => state.adminEvents.messageCenter.modals.sendMessage.ActiveForm.SelectedGroups;

export const multipleSelectedGroupsCombiner = (groups: AdminEventsGroupEventRegistration[], groupIDs: number[]) => {
  if (!groupIDs || !groups) return [];
  return groupIDs.map((id) => {
    return groups.find((g) => g.GroupIDi === id);
  });
};
export const makeMultipleSelectedGroupsSelector = () => {
  return createSelector(
    [groupsSelector, selectedGroupIdsSelector],
    multipleSelectedGroupsCombiner,
  );
};

const selectedEventIDSelector = (state: ApplicationState) => state.adminEvents.messageCenter.modals.sendMessage.ActiveForm.SelectedEventID;
export const makeSelectedEventSelector = () => {
  return createSelector(
    [eventsSelector, selectedEventIDSelector],
    (events: AdminEventsEvent[], id: number) => {
      if (!events) return undefined;
      return events.find((e) => e.IDi === id);
    }
  );
};

const checkApiValidation = apiValidationReducerCreator(SUFFIX, undefined, true);

const SendMessageModalReducer: Reducer<SendMessageModalState> = (s, act: WithRootState<AnyAction>) => {
  const state = checkApiValidation(s, act);
  if (isActionType(act, AddGroups)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        SelectedGroups: [...act.groupsIds],
      },
    };
  } else if (isActionType(act, RemoveGroup)) {
    const newState = {...state};
    const index = newState.ActiveForm.SelectedGroups.findIndex((g) => g === act.groupId);
    newState.ActiveForm.SelectedGroups.splice(index, 1);

    return newState;
  } else if (isActionType(act, SendMessageToggleFilterTabs)) {
    return {
      ...state,
      isTextSearching: act.searching,
    };
  } else if (isActionType(act, SendMessageModalFilter)) {
    return {
      ...state,
      filterString: act.filterString,
    };
  } else if (isActionType(act, ClearError)) {
    return {
      ...state,
      SubmitErrorMessage: undefined,
    };
  } else if (isActionType(act, InitStepTwo)) {
    return setDefaults(act.rootState, {...state}, state);
  } else if (isActionType(act, ResetSendMessageModal) || isActionType(act, ResetAdminEventsRegisteredGroup)) {
    return getInitialState();
  }
  return state || getInitialState();
};

export default SendMessageModalReducer;