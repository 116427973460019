
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, Button, ModalActions, Switch,
} from '../../../../../Elements';
import { actionCreators, GetEventsList, GetSourceClassesList } from "../../../../../../store/AdminEvents/Events/Modals/ImportFromEvent/actions";
import '../../../../../../styles/pages/admin-events/events/modals/import-from-event/index.scss';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../store/Rollback/actions';
import { actionCreators as cacheOneActionCreators, GetEventTypeCacheAction } from '../../../../../../store/AdminEvents/CacheOne/actions';
import { ApplicationState } from '../../../../../../store';
import { extractRouteParams } from '../../../../../../utils/urlHelper';
import { makeFormModalPropSelector } from './../../../../../../store/App';
import { makeFilteredEventsSelector, makeSelectedEventSelector, makeFilteredClassesSelector, makeSelectedClassesSelector } from '../../../../../../store/AdminEvents/Events/Modals/ImportFromEvent';
import { ModalHeight } from '../../../../../Elements/Modal';
import SelectEvents from './SelectEvents';
import SelectClasses from './SelectClasses';
import Confirmation from './Confirmation';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { shouldBlockActions } from '../../../../../../utils/cacheLoaders/helpers/blockers';
import { reduxStoreService } from '../../../../../../store/service';
import {WithInertAttribute} from '../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--modals--import-from-event';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{},{}>;
type Props = WithInertAttribute<{}>

export enum ImportFromEventType {
  TIME_BLOCKS,
  PRICING,
  CLASSES,
}

export enum ImportFromEventStep {
  SELECT_EVENTS,
  SELECT_CLASSES,
  CONFIRMATION,
}

@(withRouter as any)
class ImportFromEventModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;

  componentDidMount() {
    if (shouldBlockActions()) return;
    if (this.props.type !== undefined) {
      this.props.actions.init(this.props.type);
    }
  }

  componentWillUnmount() {
    this.onClose();
  }

  componentDidUpdate(prevProps) {
    const {type, filteredClasses, modalState: {ActiveForm}} = this.props;

    if (type === ImportFromEventType.CLASSES && this.currentStep() === ImportFromEventStep.CONFIRMATION && ActiveForm.SelectedClassIds && ActiveForm.SelectedClassIds.length === 0) {
      this.onBack();
    }
  }
  onBack = () => {
    const { actions, modalState: {ActiveForm} } = this.props;
    
    if (!ActiveForm.SelectedClasses) {
      actions.resetStep2();
    } else {
      actions.resetStep3();
    }

    if (reduxStoreService().getState().app.apiLoading > 0) {
      actions.silentCancelAll();
    }
    actions.saveState();
  };

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    this.props.actions.toggleFilter(false);
    this.props.actions.filterEvent('');
  };
  onRefreshClick = () => {
    const {actions, type, modalState: {ActiveForm}} = this.props;

    actions.saveState();
    if (type === ImportFromEventType.CLASSES && ActiveForm.SelectedEventType) {
      if (!ActiveForm.SelectedEvent) {
        actions.getEventsList(ActiveForm.SelectedEventType);
      } else {
        actions.getClassesList(ActiveForm.SelectedEventType, ActiveForm.SelectedEvent);
      }
    } else {
      actions.refresh({
        GetEvents: true,
        MaxEventIDi: 0,
        ...extractRouteParams(this.props),
      }, false);
    }
  };

  onClose = () => {
    this.props.actions.reset();
  };

  onDeselect = () => {
    const {filteredClasses} = this.props;
    this.props.actions.deselect(filteredClasses.map((c) => c.IDi));
  };

  onSelectAll = () => {
    const {filteredClasses} = this.props;
    this.props.actions.selectAll(filteredClasses.filter((c) => !c.IsDuplicate).map((c) => c.IDi));
  };

  onSaveClasses = () => {
    const { modalState: {ValidationRules} } = this.props;
    this.props.actions.updateValue(true, ValidationRules.SelectedClasses);
    this.onSearchBack();
  };

  onComplete = () => {
    const {type, actions, selectedEvent} = this.props;

    if (!selectedEvent || type === undefined) return;

    actions.popModal(true, false, ModalTypes.IMPORT_FROM_EVENT);
    switch (type) {
      case ImportFromEventType.PRICING:
        actions.importPricing(selectedEvent.IDi);
        return;
      case ImportFromEventType.TIME_BLOCKS:
        actions.importTimeBlocks(selectedEvent.IDi);
        return;
      case ImportFromEventType.CLASSES:
        actions.importClasses();
        return;
      default:
        return;
    }
  };

  renderActions = (loading: boolean) => {
    const { modalState: { ActiveForm, ValidationRules }, actions, filteredClasses, apiLoading, type, apiSaving, selectedClasses } = this.props;

    const EventListActions = (
      <ModalActions
        sticky
        notFixed
        className={`${namespace()}--show-events-from-past-years`}
        left={<Switch
          margin={false}
          newDesign
          label="Show Events From Past Years"
          value={!!ActiveForm.ShowEventsFromPastYear}
          validationRules={ValidationRules.ShowEventsFromPastYear}
          onChange={actions.updateValueWithoutSave}
        />}
      />
    );
    const CompleteActions = (
      <ModalActions
        sticky
        notFixed
        left={<Button textColor='black' disabled={apiSaving > 0} flat onClick={this.onComplete}>COMPLETE</Button>}
      />
    );

    if (type === ImportFromEventType.CLASSES) {
      if (ActiveForm.SelectedEvent) {
        if (ActiveForm.SelectedClasses) {
          return CompleteActions;
        } else {
          return (
            <ModalActions
              sticky
              notFixed
              left={<Button textColor='black' disabled={apiSaving > 0 || loading || selectedClasses.length === 0} flat onClick={this.onSaveClasses}>CONTINUE</Button>}
              right={<div className={`${namespace()}--right-actions`}>
                {ActiveForm.SelectedClassIds && ActiveForm.SelectedClassIds.length > 0 ? <Button textColor='black' disabled={apiLoading > 0} flat onClick={this.onDeselect}>DESELECT</Button> : null}
                <Button textColor='black' disabled={apiLoading > 0} flat onClick={this.onSelectAll}>SELECT ALL</Button>
              </div>}
            />
          );
        }
      }
      return EventListActions;
    }

    return ActiveForm.SelectedEvent ? CompleteActions : EventListActions;
  };

  currentStep = (): ImportFromEventStep => {
    const {modalState: {ActiveForm}, type} = this.props;

    if (!ActiveForm.SelectedEvent) {
      return ImportFromEventStep.SELECT_EVENTS;
    } else {
      if (type === ImportFromEventType.CLASSES) {
        if (!ActiveForm.SelectedClasses) {
          return ImportFromEventStep.SELECT_CLASSES;
        } else {
          return ImportFromEventStep.CONFIRMATION;
        }
      } else {
        return ImportFromEventStep.CONFIRMATION;
      }
    }
  };

  renderContent = () => {
    const currentStep = this.currentStep();

    switch(currentStep) {
      case (ImportFromEventStep.SELECT_EVENTS):
        return <SelectEvents onSearchBack={this.onSearchBack} />;
      case (ImportFromEventStep.SELECT_CLASSES):
        return <SelectClasses onSearchBack={this.onSearchBack} />;
      case (ImportFromEventStep.CONFIRMATION):
        return <Confirmation />;
    }
  };

  public render() {
    const { actions, apiLoadingMap, type, filteredClasses, modalState: {ActiveForm, ValidationRules, isTextSearching}, selectedClasses, cacheTwoEvent, selectedEvent, eventFormState, apiSaving, inert } = this.props;
    const loading = apiLoadingMap[GetEventTypeCacheAction.requestType] || apiLoadingMap[GetEventsList.requestType] || apiLoadingMap[GetSourceClassesList.requestType];
    const subtitle = eventFormState.ActiveForm.Name || 'Edit Event';
    const curentStep = this.currentStep();
    let typeText = '';

    if (type === ImportFromEventType.TIME_BLOCKS) {
      typeText = 'Time Blocks';
    }
    else if (type === ImportFromEventType.PRICING) {
      typeText = 'Pricing';
    }
    else if (type === ImportFromEventType.CLASSES) {
      typeText = 'Classes';
    }
    const title = `Import ${typeText}`;

    let modalHeight;

    if (type === ImportFromEventType.CLASSES) {
      if (ActiveForm.SelectedEvent) {
        modalHeight = ModalHeight.HEIGHT_560;
      }
    }

    return (
      <Modal inert={inert} height={modalHeight} onClose={this.onClose} big mobileFullScreen className={namespace()}>
        <ModalHeader
          subtitle={subtitle}
          onBack={curentStep !== ImportFromEventStep.SELECT_EVENTS ? this.onBack : undefined}
          isSearching={curentStep !== ImportFromEventStep.CONFIRMATION ? isTextSearching : false}
          className={`${namespace()}--header`}
          onRefresh={curentStep !== ImportFromEventStep.CONFIRMATION  ? this.onRefreshClick : undefined}
          onSearchOpen={curentStep !== ImportFromEventStep.CONFIRMATION  ? this.onSearchOpen : undefined}
          onSearchChange={curentStep !== ImportFromEventStep.CONFIRMATION  ? actions.filterEvent : undefined}
          onSearchBack={curentStep !== ImportFromEventStep.CONFIRMATION  ? this.onSearchBack : undefined}
        >
          {title}
        </ModalHeader>
        <ModalContent
          neverLoading
          paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0}
        >{this.renderContent()}</ModalContent>
        {this.renderActions(loading)}
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const importFromEventSelector = makeFormModalPropSelector(ModalTypes.IMPORT_FROM_EVENT, 'importFromEventType');
  const filteredEventsSelector = makeFilteredEventsSelector(true);
  const filteredEventsByEventTypeSelector = makeFilteredEventsSelector();
  const selectedEventSelector = makeSelectedEventSelector();
  const filteredClassesSelector = makeFilteredClassesSelector();
  const selectedClassesSelector = makeSelectedClassesSelector();
  
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    modalState: state.adminEvents.events.modals.importFromEvent,
    type: importFromEventSelector(state),
    cacheOne: state.adminEvents.cacheOne,
    cacheTwoEvent: state.adminEvents.cacheTwoEvent,
    eventFormState: state.adminEvents.events.event.form,
    filteredEvents: filteredEventsSelector(state),
    filteredEventsByEventType: filteredEventsByEventTypeSelector(state),
    selectedEvent: selectedEventSelector(state),
    filteredClasses: filteredClassesSelector(state),
    selectedClasses: selectedClassesSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...appActionCreators,
    ...cacheOneActionCreators,
    ...rollbackActionCreators,
  }, dispatch),
});

const ConnectedImportFromEventModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(ImportFromEventModal);

export default ConnectedImportFromEventModal;