
import { push } from 'react-router-redux';
import type { ActionCreator, ApplicationState } from '../../../';
import {createRequestActions} from "../../../Validation/actionCreator";
import { SaveState } from '../../../Rollback/actions';
import {deleteOrRestoreGroupRosterPersonBody, URLS} from '../../../../constants/urls';
import { PlainRoute } from 'react-router';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../utils/dataHelper';

export const SUFFIX = '__SETTINGS_ROSTER';
export const SUFFIX_RESTORE = '__SETTINGS_ROSTER_RESTORE';

// export const GetGroupRoster = createRequestActions(SUFFIX);
export const RestoreGroupRoster = createRequestActions(SUFFIX_RESTORE, true);

export type Actions = typeof actionCreators;

const restoreFormCreator = (rootState: ApplicationState, IDi: number, isYouth: boolean) => {
  const {cacheZero} = rootState;

  let debugPayload: TentarooDebugPayload | undefined;
  if (!cacheZero.options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when creating deleteOrRestoreGroupRosterPersonBody");
  }
  if (!cacheZero.options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload("Group not available when creating deleteOrRestoreGroupRosterPersonBody");
  }
  const payload = deleteOrRestoreGroupRosterPersonBody(
    cacheZero.options?.Group?.IDi as any,
    cacheZero.options?.Group?.TS as any,
    IDi,
    isYouth,
    false
  );

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

export const actionCreators = {
  apiRestorePerson: (IDi: number, isYouth: boolean, routes: PlainRoute<any>[]): ActionCreator => (dispatch, getState) => {
    dispatch(new SaveState());
    const route = getState().routing.locationBeforeTransitions;
    dispatch(push(`${URLS.MY_ROSTER}${route.search}`));
    const requestBody = restoreFormCreator(getState(), IDi, isYouth);
    dispatch(RestoreGroupRoster.request(requestBody, undefined, routes[routes.length - 1]));
  }
};
