import * as React from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  PageLoader,
  Row,
  Tab,
  Tabs
} from '../../../../Elements';
import {RouteComponentProps, withRouter} from 'react-router';
import {URLS} from '../../../../../constants/urls';
import {
  actionCreators,
} from "../../../../../store/Admin/Modals/Accounts/actions";
import {actionCreators as appActionCreators} from '../../../../../store/App/actions';
import {
  actionCreators as rollbackActionCreators,
} from '../../../../../store/Rollback/actions';

import {bindActionCreators} from 'redux';
import {navPush, disableBodyScroll, enableBodyScroll} from '../../../../../utils/navHelper';
import '../../../../../styles/pages/admin/modals/accounts/index.scss';
import { ModalHeight } from '../../../../../components/Elements/Modal';
import {GroupsContent, GroupsFooter} from './GroupsTab';
import {ParticipantsContent, ParticipantsFilters} from './ParticipantsTab';
import { RegistrationsFilters, RegistrationsContent } from './RegistrationsTab';
import { TripsFilters, TripsContent } from './TripTab';
import { ReservationsFilters, ReservationsContent } from './ReservationsTab';
import { ApplicationState } from '../../../../../store';
import { ModalTypes } from '../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import {reduxStoreService} from '../../../../../store/service';
import {WithInertAttribute} from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin--modals--accounts';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{},{}>;

type Props = WithInertAttribute<{}>;

@(withRouter as any)
class AdminAccountsModal extends React.PureComponent<Props, {}> {
  public props: Props & ConnectedProps;
  private modalContentRef;
  private tabRef;

  public componentDidMount() {
    disableBodyScroll(this.modalContentRef);
    disableBodyScroll(this.tabRef);
  }

  componentDidUpdate() {
    if (reduxStoreService().getState().rollback.isRollbackJustFinished) {
      this.onSearchBack();
    }
  }

  public componentWillUnmount() {
    enableBodyScroll(this.modalContentRef);
    enableBodyScroll(this.tabRef);
    this.onSearchBack();
  }

  onAddClick = () => {
    this.props.actions.popModal(false, false, ModalTypes.ACCOUNTS_MODAL);
    navPush(this.props.router, URLS.ADD_GROUP);
  };

  onRefreshClick = () => {
    const { actions: { getGroups, getParticipants, getRegistrations, getTrips, getReservations }, adminAccounts: { selectedTab, ActiveForm: { Year, EventTypeID, ShowInactive, RegistrationsEventTypeID, RegistrationsEventID, RegistrationsYear, TripsLocationID, TripsYear, ReservationsLocationID, ReservationsFacilityTypeID, ReservationsYear }}} = this.props;
    if (selectedTab === 'groups') {
      getGroups(ShowInactive);
    } else if (selectedTab === 'registrations') {
      getRegistrations(RegistrationsEventTypeID, RegistrationsYear, RegistrationsEventID);
    } else if (selectedTab === 'participants') {
      getParticipants(EventTypeID, Year);
    } else if (selectedTab === 'trips') {
      getTrips(TripsLocationID, TripsYear);
    } else if (selectedTab === 'reservations') {
      getReservations(ReservationsLocationID, ReservationsYear, ReservationsFacilityTypeID);
    }
  };

  onSearchOpen = () => {
    this.props.actions.showFilterTabs(false);
  };

  onSearchBack = () => {
    // @todo-minor-performance: just use 1 action
    if (this.props.adminAccounts.filterString) {
      this.scrollToTop();
    }
    this.props.actions.showFilterTabs(true);
    this.props.actions.filterAccounts('');
  };

  selectGroupsTab = () => {
    this.props.actions.selectTab('groups');
    this.scrollToTop();
  };
  selectRegistrationsTab = () => {
    this.props.actions.selectTab('registrations');
    this.scrollToTop();
  };
  selectParticipantsTab = () => {
    this.props.actions.selectTab('participants');
    this.scrollToTop();
  };
  selectTripsTab = () => {
    this.props.actions.selectTab('trips');
    this.scrollToTop();
  };
  selectReservationsTab = () => {
    this.props.actions.selectTab('reservations');
    this.scrollToTop();
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };
  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filterAccounts(val);
  };

  renderFilters = () => {
    const {adminAccounts: {showFilterTabs, selectedTab}} = this.props;

    if (!showFilterTabs) return null;
    switch (selectedTab) {
      case 'participants':
        return <ParticipantsFilters />;
      case 'registrations':
        return <RegistrationsFilters />;
      case 'trips': 
        return <TripsFilters />;
      case 'reservations':
        return <ReservationsFilters />;
      default:
        return null;
    }
  };

  renderContent = () => {
    const {adminAccounts: {selectedTab}} = this.props;

    switch(selectedTab) {
      case 'groups':
        return <GroupsContent scrollToTop={() => this.scrollToTop()} />;
      case 'registrations':
        return <RegistrationsContent scrollToTop={() => this.scrollToTop()} />;
      case 'participants':
        return <ParticipantsContent scrollToTop={() => this.scrollToTop()} />;
      case 'trips':
        return <TripsContent scrollToTop={() => this.scrollToTop()} />;
      case 'reservations':
        return <ReservationsContent scrollToTop={() => this.scrollToTop()} />;
      default:
        return null;
    }
  };

  renderFooter = () => {
    const {adminAccounts: {selectedTab}} = this.props;

    switch(selectedTab) {
      case 'groups':
        return <GroupsFooter />;
      default:
        return null;
    }
  };

  public render() {
    const {apiLoading, cacheZero,
      adminAccounts: { selectedTab, showFilterTabs, ActiveForm, ValidationRules, isTextSearching } } = this.props;
    return (
      <Modal
        inert={this.props.inert}
        big mobileFullScreen wideModal
        className={namespace()}
        onClose={this.onSearchBack}
        height={ModalHeight.HEIGHT_480}
      >
        <ModalHeader
          isSearching={isTextSearching}
          onAdd={selectedTab === 'groups' ? this.onAddClick : undefined}
          onRefresh={this.onRefreshClick}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={this.onSearchChange}
          onSearchBack={this.onSearchBack}
          className={`${namespace()}--header`}
        >
          Accounts
        </ModalHeader>
        <ModalContent
          noBodyScrollLock
          paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0} >
          {showFilterTabs && <Row refCallback={(ref) => this.tabRef = ref} className={`${namespace()}--row-1 ${selectedTab === 'groups' ? 'shadow' : ''}`}>
            <Tabs>
              <Tab onClick={this.selectGroupsTab} selected={selectedTab === 'groups'}>GROUPS</Tab>
              <Tab onClick={this.selectRegistrationsTab} selected={selectedTab === 'registrations'}>REGISTRATIONS</Tab>
              <Tab onClick={this.selectParticipantsTab} selected={selectedTab === 'participants'}>PARTICIPANTS</Tab>
              <Tab onClick={this.selectTripsTab} selected={selectedTab === 'trips'}>TRIPS</Tab>
              <Tab onClick={this.selectReservationsTab} selected={selectedTab === 'reservations'}>RESERVATIONS</Tab>
            </Tabs>
          </Row>}
          {this.renderFilters()}
          <Row className={`${namespace()}--row-2`}>
            <div ref={(ref) => this.modalContentRef = ref} className={`${namespace()}--content-wrapper`}>
              {this.renderContent()}
            </div>
            {apiLoading > 0 && <PageLoader />}
          </Row>
          {this.renderFooter()}
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    adminAccounts: state.adminAccounts,
    cacheZero: state.cacheZero,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    rollback: state.rollback,
  };
};

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
  ...rollbackActionCreators}, dispatch) });

const ConnectedAdminAccountsModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(AdminAccountsModal);

export default ConnectedAdminAccountsModal;
