import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { bindActionCreators } from 'redux';
import { AdminEventsCacheOneContext } from '@tentaroo/shared';

import { ApplicationState } from '../../../../../store';
import { actionCreators as appActionCreators } from '../../../../../store/App/actions';
import { actionCreators as eventTypeFormActionCreators } from '../../../../../store/AdminEvents/EventTypes/EventType/Form/actions';
import { actionCreators as cacheOneActionCreators, GetEventTypeCacheAction } from '../../../../../store/AdminEvents/CacheOne/actions';
import { LoadingAll, Button, ActionButton, Row, Column, FormActions, PageLoader } from '../../../../Elements';
import ContextMenuComponent from './ContextMenu';
import { Main, MainContent } from '../../../../Layouts';
import '../../../../../styles/pages/admin-events/settings/home/index.scss';
import EventTypeForm from '../../EventTypes/EventType/Form';
import { OptionsIcon } from '../../../../Icons';
import { checkEventTypePermission, IAdminEventsRouterParams } from '../../../../../utils/helpers/adminEventsPageHelper';
import { ModalTypes, noOpenedModals } from '../../../../../utils/modalHelper';
import AdminEventsCacheManager from '../../../../../utils/cacheManagers/adminEventsCacheManager';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../utils/reduxHelper';
import { ComponentUpdateTemplate } from '../../../../Templates/ComponentUpdateTemplate';
import {isAdminEventsCacheOnePopulated} from '../../../../../utils/cachePopulatedCheckers/adminEvents';
import { WithInertAttribute } from '../../../../Elements/WithInert';

export const namespace = (): string => 'pages--admin-events--settings';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IAdminEventsRouterParams, {}>
>;

interface State {
  showFormActions: boolean;
}

const HEADER_HEIGHT = 74;

class SettingsHome extends ComponentUpdateTemplate<ConnectedProps, State> {
  public props: ConnectedProps;

  constructor(props) {
    super(props, {
      showFormActions: false,
    });
  }

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);
    window.addEventListener('scroll', this.scrollHandler);

    super.loadAndSetData(
      this.props,
      (isStateNavigated) => {
        AdminEventsCacheManager.getInstance().loadAdminEventsCacheOne({
          props: this.props,
          isStateNavigated,
          context: AdminEventsCacheOneContext.SETTINGS,
        });
      }
    );
  }

  componentWillReceiveProps(nextProps: ConnectedProps) {
    super.loadAndSetData(
      nextProps,
      (isStateNavigated) => {
        AdminEventsCacheManager.getInstance().loadAdminEventsCacheOne({
          props: nextProps,
          isStateNavigated,
          context: AdminEventsCacheOneContext.SETTINGS,
        });
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandler);
  }

  isFormDisabled = () => {
    const {apiLoading, adminEventsCacheOne} = this.props;

    return apiLoading > 0 || (!adminEventsCacheOne.EventsEventType || adminEventsCacheOne.EventsEventType.EventTypeRow.Inactive || !adminEventsCacheOne.EventsEventType.Permissions.hasEditEventType);

  };

  onSave = () => {
    const {actions, cacheZero, adminEventsCacheOne, routes, router} = this.props;

    checkEventTypePermission(
      () => {
        actions.apiSubmitForm(routes, router);
      },
      cacheZero,
      adminEventsCacheOne,
      undefined,
      true,
    );
  };

  onDelete = () => {
    const {actions, cacheZero, adminEventsCacheOne} = this.props;

    checkEventTypePermission(
      () => {
        if (!adminEventsCacheOne.EventsEventType) return;

        actions.deleteEventType(adminEventsCacheOne.EventsEventType.EventTypeRow.ID as number, true);
      },
      cacheZero,
      adminEventsCacheOne,
      undefined,
      true,
    );
  };

  onDuplicate = () => {
    const {adminEventsCacheOne, cacheZero} = this.props;

    checkEventTypePermission(
      () => {
        if (!adminEventsCacheOne.EventsEventType) return;
        this.props.actions.pushFormModal(ModalTypes.NEW_EVENT_TYPE, false, {duplicateEventTypeId: adminEventsCacheOne.EventsEventType.EventTypeRow.ID as any});
      },
      cacheZero,
    );
  };

  scrollHandler = () => {
    if (window.scrollY >= HEADER_HEIGHT && !this.state.showFormActions) {
      this.setState({
        showFormActions: true,
      });
    } else if (window.scrollY < HEADER_HEIGHT && this.state.showFormActions) {
      this.setState({
        showFormActions: false,
      });
    }
  };

  onRestore = () => {
    const {actions, cacheZero, adminEventsCacheOne} = this.props;

    checkEventTypePermission(
      () => {
        if (!adminEventsCacheOne.EventsEventType) return;

        actions.deleteEventType(adminEventsCacheOne.EventsEventType.EventTypeRow.ID as number, false);
      },
      cacheZero,
      adminEventsCacheOne,
      undefined,
      true,
    );
  };

  renderContent = () => {
    const {adminEventsCacheOne, actions, eventTypeForm, inert, apiSaving} = this.props;

    const inactive = !adminEventsCacheOne.EventsEventType || adminEventsCacheOne.EventsEventType.EventTypeRow.Inactive;
    const unauthorized = adminEventsCacheOne.EventsEventType && !adminEventsCacheOne.EventsEventType.Permissions.hasEditEventType;
    return (
      <EventTypeForm
        inert={inert}
        action='edit'
        reduxActions={actions}
        formState={eventTypeForm}
        SubmitErrorMessage={eventTypeForm.General.SubmitErrorMessage}
        hideFormAction={!this.state.showFormActions}
        formActions={
        <FormActions>
          <Row>
            {!inactive && <Column expand>
              <Button disabled={this.isFormDisabled()} color="white" flat textColor="black" big onClick={this.onSave}>SAVE</Button>
            </Column>}
            {inactive && <Column expand>
              <Button color="white" disabled={unauthorized} flat textColor="red" big onClick={this.onRestore}>RESTORE</Button>
            </Column>}
            {!inactive && <Column style={{alignItems: 'center'}}>
              <ActionButton
                icon={OptionsIcon}
                className={`${namespace()}--options`}
                contextMenu={<ContextMenuComponent
                  direction='top'
                  onDelete={this.onDelete}
                  onSave={this.onSave}
                  onDuplicate={this.onDuplicate} />}
              />
            </Column>}
          </Row>
        </FormActions>}
      />
    );
  };

  public render() {
    const {adminEventsCacheOne, inert, eventTypeForm, apiLoadingMap, apiSaving } = this.props;
    if (!isAdminEventsCacheOnePopulated(adminEventsCacheOne, eventTypeForm.EventTypeRow.ActiveForm)) {
      return <LoadingAll />;
    }

    const refreshing = apiLoadingMap[GetEventTypeCacheAction.requestType] && noOpenedModals();
    const saving = apiSaving > 0 && noOpenedModals();

    return (
      <Main inert={inert} isLoading={refreshing} isAdminEvents>
        <MainContent className={namespace()} handleCompact>
          {this.renderContent()}
          {saving && <PageLoader fullScreen />}
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminEventsCacheOne: state.adminEvents.cacheOne,
    eventTypeForm: state.adminEvents.eventTypes.eventType.form,
    cacheZero: state.cacheZero,
    isRollbackJustFinished: state.rollback.isRollbackJustFinished,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...cacheOneActionCreators,
    ...eventTypeFormActionCreators,
  }, dispatch),
});
const ConnectedSettingsHome = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<WithRouterProps>(),
)(SettingsHome);

export default withRouter<{}>(ConnectedSettingsHome);
