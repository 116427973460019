import * as React from 'react';
import DatePicker from 'jsweetman-react-datepicker';
import moment from 'moment';
import { DATE_PICKER_FORMAT, MIN_DATE } from '@tentaroo/shared';

import {slug} from '../../../utils';
import {default as attributes, MarginProps} from '../../../styles';
import ArrowIcon from '../../../images/elements/select/arrow.svg';
import '../../../styles/elements/date-picker/calendar.scss';
import '../../../styles/elements/date-picker/input.scss';
import calendarFormat = moment.calendarFormat;
import {isOptional} from "../../../utils/validator";
import {validateDate} from "../../../utils/dateHelper";
import device from 'current-device';
import { AdminBadge } from '..';
import { Validator } from '../../../utils/validator/models';

const ReactDatePicker: React.ReactType = DatePicker;
const namespace = (): string => 'elements--date-picker';

export interface DatePickerProps extends MarginProps {
  label?: string;
  className?: string;
  value?: moment.Moment;
  hideError?: boolean;
  validationRules?: Validator;
  defaultValue?: moment.Moment;
  onSelect?: (value: string | number | undefined | moment.Moment, validationRules?: Validator) => any;
  onChangeRaw?: (value: string | number | undefined, validationRules?: Validator) => any;
  disabled?: boolean;
  removeMax?: boolean;
  minDate?: number;
  maxDate?: number;
  // These two props are not well documented, but found here: https://github.com/Hacker0x01/react-datepicker/issues/1246
  popperPlacement?: string;
  popperModifiers?: any;
  helperText?: string | React.ReactNode;
  helperTextOverflow?: boolean;
  isAdmin?: boolean;
}

export interface DatePickerState {
  open?: boolean;
}

class DatePickerComponent extends React.Component<DatePickerProps, DatePickerState> {
  private infoRef;
  public props: DatePickerProps;
  public state: DatePickerState = {
    open: false
  };
  public refs: any;

  public constructor(props) {
    super(props);
  }

  handleChangeRaw = (e) => {
    if (this.props.onChangeRaw) {
      this.props.onChangeRaw(e.target.value, this.props.validationRules);
    }
  };

  handleChange = (date, e, input) => {
    if (this.props.onSelect) {
      if (date) {
        this.props.onSelect(date, this.props.validationRules);
      } else {
        this.props.onSelect('', this.props.validationRules);
      }
    }
  };

  updateInfoStyle = () => {
    if (this.infoRef && this.props.helperTextOverflow) {
      const datePicker = this.infoRef.firstElementChild;
      const info = this.infoRef.lastElementChild;

      if (datePicker && info) {
        if (!datePicker.classList.contains('error')) datePicker.style.marginBottom = `${(info.clientHeight || 8) + 8}px`;
        else datePicker.style.marginBottom = '24px';
      }
    }
  };

  handleBlur = (e) => {
    if (this.props.onSelect) {
      if (e.target.value) {
        const isValid = validateDate(e.target.value);
        if (isValid) {
          const date = moment(e.target.value);
          this.props.onSelect(date, this.props.validationRules);
        } else {
          this.props.onSelect(e.target.value, this.props.validationRules);
        }
      } else {
        this.props.onSelect('', this.props.validationRules);
      }
    }
    this.setState({open: false});
  };

  handleFocus = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    const isMobile = document.documentElement.clientWidth <= 639 || document.documentElement.clientHeight <= 450 ||
      device.mobile() || device.tablet();
    if (isMobile) {
      (document.activeElement as any).blur();
    }
    this.setState({open: false});
  };

  componentDidMount() {
    this.updateInfoStyle();
    window.addEventListener('resize', this.updateInfoStyle);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateInfoStyle);
  }

  componentDidUpdate() {
    this.updateInfoStyle();
  }

  public render() {
    const { open } = this.state;
    const {
      minDate, maxDate, value, validationRules, hideError, label,
      className: classNameOverride, disabled, removeMax, popperPlacement, popperModifiers, isAdmin, helperTextOverflow, helperText, ...props
    } = this.props;
    const error = validationRules && !hideError ? validationRules.errors : undefined;

    let className = attributes(namespace(), classNameOverride, props);

    const id: string | undefined = label ? `${namespace()}-${slug(label)}` : undefined;
    let labelClassName: string = `${namespace()}--label`;
    let inputClassName: string = `${namespace()}--input`;
    if (open || value) labelClassName += ' fixed';

    let labelComponent: React.ReactNode = (
      <label htmlFor={id} className={labelClassName}>
        {label}
      </label>
    );

    let isString = false;
    if (typeof value === 'string' && value !== '') {
      // If its a string, then it must not be a valid date
      isString = true;
    }

    if (disabled) inputClassName += ' disabled';

    // @todo: shouldCloseOnSelect={false} maybe?
    const isMobile = document.documentElement.clientWidth <= 639 || document.documentElement.clientHeight <= 450 || device.mobile() || device.tablet();

    if (isMobile) inputClassName += ' isMobile';
    if (helperText) className += ' has-helper-text';
    if (helperTextOverflow) className += ' helper-text-overflow';
    if (error) className += ' error';
    if (isAdmin) className += ' is-admin';
    if (!value) className += ' empty-value';

    const datePicker = (
      <div className={className}>
        <div className={`${namespace()}--container`}>
          <ReactDatePicker
            popperPlacement={popperPlacement}
            popperModifiers={popperModifiers}
            readOnly={isMobile}
            disabled={disabled}
            selected={isString ? undefined : value}
            value={isString ? value : (!value ? undefined : value.format(DATE_PICKER_FORMAT))}
            showMonthDropdown
            showYearDropdown
            onChangeRaw={this.handleChangeRaw}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onClose={this.handleClose}
            id={id}
            className={inputClassName}
            minDate={minDate ? moment(minDate) : MIN_DATE}
            maxDate={maxDate ? moment(maxDate) : (!removeMax ? moment() : undefined)}
            dropdownMode="select"
            dateFormat={DATE_PICKER_FORMAT}
            popoverTargetOffset="-40px 0px"
            tetherConstraints={[{
              to: 'window',
              attachment: 'none',
              pin: true
            }]}
            withPortal={isMobile}
            preventOpenOnFocus
            disabledKeyboardNavigation
            selectArrow={<ArrowIcon width="11px" height="7px" className={`${namespace()}--inner-arrow`}/>}
            hideClear={!isOptional(validationRules)}
            autoComplete="off"
          />
          {labelComponent}
          <ArrowIcon width="11px" height="7px" className={`${namespace()}--arrow`}/>
          {isAdmin && <div className={`admin-badge`}><AdminBadge /></div>}
        </div>
        {isOptional(validationRules) && <div className={`${namespace()}--optional ${!value && !open ? 'emptyField' : ''}`}>optional</div>}
        {!open && error && error.length > 0 && <span className={`${namespace()}--error`}>{error[0]}</span>}
      </div>
    );

    if (helperText) {
      return <div ref={(ref) => this.infoRef = ref} className={`${namespace()}--wrapper`}>
        {datePicker}
        {helperText && (!error || error.length === 0) && <div className={`${namespace()}--helper-text ${helperTextOverflow ? 'overflow' : ''}`}>{helperText}</div>}
      </div>;
    };

    return datePicker;
  }
}

export default DatePickerComponent;
