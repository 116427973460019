
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, Row, SimpleList, PageLoader, Select, Column, Switch, DatePicker, EmptyMessage,
} from '../../../../../../Elements';
import '../../../../../../../styles/pages/cms-pages/modals/page-type/index.scss';
import { actionCreators as appActionCreators } from '../../../../../../../store/App/actions';
import {
    actionCreators as adminCMSCacheOneActionCreators, GetSiteCache,
} from '../../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators, ToggleFilterInvalidInSelectPageModal } from '../../../../../../../store/AdminCMSSite/Pages/Modals/SelectPage/actions';
import { FormDefinition } from '../../../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/validation';
import {
    actionCreators as siteMenuItemFormActionCreators,
} from '../../../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/actions';
import { ApplicationState } from '../../../../../../../store';
import { makeFilteredPagesSelector } from '../../../../../../../store/AdminCMSSite/Pages/Modals/SelectPage';
import { CMSPage, PageTypeIDValue } from '../../../../../../../models/api/adminCMSCacheOne';
import CMSPageCard, { PageCardType } from '../../../../../../Elements/CMSPage/Card';
import { PageIcon } from '../../../../../../Icons';
import { ENTITY_NOT_FOUND, PAGES, ENTITY_NOT_ADDED, BLOG_ARTICLES, CALENDAR_EVENTS, DISTRICTS, EVENT_MODULES, FACILITY_MODULES, STANDARD_PAGES } from '../../../../../../../constants/messages/adminCMS';
import { EmptyMessageType } from '../../../../../../Elements/EmptyMessage';
import { ModalHeight } from '../../../../../../Elements/Modal';
import { CardCategory } from '../../../../../../Elements/Card';
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { constructGetPageListBaseParams, validateAndGetParamsOnPageFilterChange } from '../../../../../../../store/AdminCMSSite/Pages/Home/uiHelpers';
import { reduxStoreService } from '../../../../../../../store/service';
import { shouldBlockActions } from '../../../../../../../utils/cacheLoaders/helpers/blockers';
import {WithInertAttribute} from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--modals--page-types';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {}>;
type Props = WithInertAttribute<{}>;

@(withRouter as any)
class SelectPageModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;
  private modalContentRef;

  componentDidMount() {
    if (shouldBlockActions()) return;

    this.props.actions.init();
  }

  componentDidUpdate() {
    if (reduxStoreService().getState().rollback.isRollbackJustFinished) {
      this.onSearchBack();
    }
  }

  componentWillUnmount() {
    // we might not need to reset, since we will init everytime launching this form
    // this.props.actions.reset();
    this.onSearchBack();
  }

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    if (this.props.pageTypeModal.filterString) {
      this.scrollToTop();
    }
    this.props.actions.toggleFilter(false);
    this.props.actions.filterPageTypes('');
  };
  onToggleFilter = () => {
    this.props.actions.showAdvancedFilter(!this.props.pageTypeModal.showAdvancedFilter);
  };
  onRefreshClick = () => {
    const {actions, pageTypeModal: {ActiveForm, ValidationRules}} = this.props;
    actions.getSiteCache(constructGetPageListBaseParams(
      ActiveForm,
      ValidationRules.EventStartDate,
      this.props,
    ), false);
  };
  onClickPageElement = (p: CMSPage) => {
    if (!p.Inactive) {
      this.props.actions.updateValue(p.ID, FormDefinition.PageID as any);
      this.props.actions.updateSelectedPage(p.ID);
      this.onSearchBack();
      this.props.actions.popModal(false, true, ModalTypes.SELECT_PAGE);
    }
  };

  itemRenderer = (index) => {
    const {filteredPages, adminCMSCacheOne} = this.props;
    const page = filteredPages[index];
    const pageType = adminCMSCacheOne.PageTypes ? adminCMSCacheOne.PageTypes.find((p) => p.ID === page.PageTypeID) : null;

    return (
      <CMSPageCard
        type={PageCardType.IN_MODAL}
        key={`page_modal_list_item_${page.ID}`}
        cardCategory={CardCategory.LIST_MOBILE}
        page={page}
        adminCMSCacheOne={adminCMSCacheOne}
        onClick={this.onClickPageElement}
        pageTypeName={pageType ? pageType.Name : ''}
      />
    );
  };

  onIncludePastEventsChange = (v, vObj) => {
    this.onPageFilterChange(v, vObj, "IncludePastEvents");
  };
  onIncludeExpiredBlogArticlesChange = (v, vObj) => {
    this.onPageFilterChange(v, vObj, "IncludeExpiredBlogArticles");
  };

  onEventCategoryChange = (v, vObj) => {
    this.onPageFilterChange(v, vObj, "EventCategoryID");
  };

  onEventStartDateChange = (v, vObj) => {
    this.onPageFilterChange(v, vObj, "EventStartDate");
  };

  onPageTypeChange = (v, vObj) => {
    this.onPageFilterChange(v, vObj, 'PageTypeID');
  };

  onPageFilterChange = (v, vObj, key: string) => {
    const {actions, pageTypeModal: {ActiveForm, ValidationRules}} = this.props;
    
    // Generate params, will be `undefined` if invalid
    const params = validateAndGetParamsOnPageFilterChange(
      v,
      vObj,
      key,
      actions.pageTypeModalUpdateValue,
      ActiveForm,
      ValidationRules,
      () => reduxStoreService().getState().adminCMSSite.pages.modals.pageType,
      () => reduxStoreService().dispatch(new ToggleFilterInvalidInSelectPageModal(true)),
      this.props,
    );

    // Send request if params is returned
    if (params) actions.getSiteCache(params, false);
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };

  renderAllPagesAdvancedFilter = () => {
    const {pageTypeModal: {ActiveForm, ValidationRules} } = this.props;
    return (
      <Column span={12} mobileSpan={12} className={`${namespace()}--advanced-filter`}>
        <Row>
          <Column span={6} mobileSpan={12} mobileMarginTop={4}>
            <Switch
                label="Include Past Events"
                newDesign
                onChange={this.onIncludePastEventsChange}
                value={!!ActiveForm.IncludePastEvents}
                validationRules={ValidationRules.IncludePastEvents}
            />
          </Column>
          <Column span={6} mobileSpan={12} mobileMarginBottom={8}>
            <Switch
                label="Incl. Expired Blog Articles"
                newDesign
                onChange={this.onIncludeExpiredBlogArticlesChange}
                value={!!ActiveForm.IncludeExpiredBlogArticles}
                validationRules={ValidationRules.IncludeExpiredBlogArticles}
            />
          </Column>
        </Row>
      </Column>
    );
  };

  renderBlogArticleAdvancedFilter = () => {
    const {pageTypeModal: {ActiveForm, ValidationRules} } = this.props;
    return (
      <Column span={12} mobileSpan={12} className={`${namespace()}--advanced-filter`}>
        <Row>
          <Column span={6} mobileSpan={12}>
            <Switch
                label="Incl. Expired Blog Articles"
                newDesign
                onChange={this.onIncludeExpiredBlogArticlesChange}
                value={!!ActiveForm.IncludeExpiredBlogArticles}
                validationRules={ValidationRules.IncludeExpiredBlogArticles}
            />
          </Column>
        </Row>
      </Column>
    );
  };

  renderCalendarEventAdvancedFilter = () => {

    const {pageTypeModal: {ActiveForm, ValidationRules}, actions } = this.props;
    return (
      <Column span={12} mobileSpan={12} className={`${namespace()}--advanced-filter`}>
        <Row>
          <Column style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}} span={6} mobileSpan={12}>
            <Select
              className={`${namespace()}--advanced-select`}
              label="Event Category"
              noBottomBorder
              hideOptional
              onChangeValue={this.onEventCategoryChange}
              value={ActiveForm.EventCategoryID}
              validationRules={ValidationRules.EventCategoryID}
              isNumber />
          </Column>
          <Column span={6} mobileSpan={12}>
            <DatePicker
              className={`${namespace()}--advanced-select`}
              label="Start Date From"
              hideError
              value={ActiveForm.EventStartDate}
              validationRules={ValidationRules.EventStartDate}
              onSelect={this.onEventStartDateChange}
              onChangeRaw={actions.pageTypeModalSimpleUpdate}
              removeMax
            />
          </Column>
        </Row>
      </Column>
    );
  };

  getEmptymessage = (filter?: boolean) => {
    const {pageTypeModal: {ActiveForm}} = this.props;

    switch(ActiveForm.PageTypeID) {
      case PageTypeIDValue.ALL:
        // WONT HAPPEN, but still put it here
        return filter ? ENTITY_NOT_FOUND(PAGES) : ENTITY_NOT_ADDED(PAGES);
      case PageTypeIDValue.BLOG_ARTICLE:
        return filter ? ENTITY_NOT_FOUND(BLOG_ARTICLES) : ENTITY_NOT_ADDED(BLOG_ARTICLES);
      case PageTypeIDValue.CALENDAR_EVENT:
        return filter ? ENTITY_NOT_FOUND(CALENDAR_EVENTS) : ENTITY_NOT_ADDED(CALENDAR_EVENTS);
      case PageTypeIDValue.DISTRICT:
        return filter ? ENTITY_NOT_FOUND(DISTRICTS) : ENTITY_NOT_ADDED(DISTRICTS);
      case PageTypeIDValue.EVENT_MODULES:
        return filter ? ENTITY_NOT_FOUND(EVENT_MODULES) : ENTITY_NOT_ADDED(EVENT_MODULES);
      case PageTypeIDValue.FACILITY_MODULES:
        return filter ? ENTITY_NOT_FOUND(FACILITY_MODULES) : ENTITY_NOT_ADDED(FACILITY_MODULES);
      case PageTypeIDValue.STANDARD_PAGE:
        return filter ? ENTITY_NOT_FOUND(STANDARD_PAGES) : ENTITY_NOT_ADDED(STANDARD_PAGES);
    }
  };
  
  renderEmptyMessage = () => {
    const {filteredPages, apiLoadingMap} = this.props;

    if (apiLoadingMap[GetSiteCache.requestType]) return null;

    let emptyMessage;

    if (!filteredPages || filteredPages.length === 0 || this.props.filterInvalid) {
      // filter case
      emptyMessage = this.getEmptymessage(true);
    }

    return emptyMessage ? (
      <EmptyMessage
        icon={PageIcon}
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={emptyMessage}
      />
    ) : null;
  };
  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filterPageTypes(val);
  };

  public render() {
    const { apiLoading, apiLoadingMap, menuItemForm, cacheTwoPageMenus, cacheTwoSiteMenus, pageTypeModal: { isTextSearching, ActiveForm, ValidationRules, showAdvancedFilter }, filteredPages, inert, actions} = this.props;

    const showFilterInHeader = (ActiveForm.PageTypeID === 0 || ActiveForm.PageTypeID === PageTypeIDValue.BLOG_ARTICLE || ActiveForm.PageTypeID === PageTypeIDValue.CALENDAR_EVENT);
    const calendarShowToggleFilters = ActiveForm.PageTypeID === PageTypeIDValue.CALENDAR_EVENT && showAdvancedFilter;
    const blogArticlesShowToggleFilters = ActiveForm.PageTypeID === PageTypeIDValue.BLOG_ARTICLE && showAdvancedFilter;
    const allPageShowToggleFilters = ActiveForm.PageTypeID === 0 && showAdvancedFilter;
    let contentClassName;
    
    if (calendarShowToggleFilters) contentClassName = 'calendar show-toggles';
    if (blogArticlesShowToggleFilters) contentClassName = 'blog show-toggles';
    if (allPageShowToggleFilters) contentClassName = 'all show-toggles';
    // subtitle should be
    // - if Menu Item name is blank, show `New Menu Item` or `Edit Menu Item`
    // - otherwise, show name of the Menu Item (the one stored in ActiveForm)
    const subtitle = menuItemForm.ActiveForm && menuItemForm.ActiveForm.Name ? menuItemForm.ActiveForm.Name : ((cacheTwoPageMenus.CMSPageMenuItem || cacheTwoSiteMenus.CMSSiteMenuItem) ? 'Edit Menu Item' : 'New Menu Item');
    
    return (
      <Modal inert={inert} height={ModalHeight.HEIGHT_425} onClose={this.onSearchBack} big mobileFullScreen className={namespace()}>
        <ModalHeader
          isSearching={isTextSearching}
          onToggleFilter={showFilterInHeader ? this.onToggleFilter : undefined}
          onRefresh={this.onRefreshClick}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={this.onSearchChange}
          onSearchBack={this.onSearchBack}
          filterActive={showAdvancedFilter}
          subtitle={subtitle}
          className={`${namespace()}--header`}>
          Select Page
        </ModalHeader>

        <ModalContent
          refCallback={(ref) => this.modalContentRef = ref}
          className={`${contentClassName}`}
          paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0}
        >
          {apiLoading > 0 && <PageLoader className={`${namespace()}--page-loader`}/>}
          <Row
            className={`${namespace()}--row-2 ${contentClassName}`}>
            {!apiLoadingMap[GetSiteCache.requestType] && filteredPages.length > 0 && !this.props.filterInvalid && <SimpleList
              itemRenderer={this.itemRenderer}
              totalLength={filteredPages ? filteredPages.length : 0}
            />}
            {((!apiLoadingMap[GetSiteCache.requestType] && filteredPages.length === 0) || this.props.filterInvalid) && this.renderEmptyMessage()}
          </Row>
          {(apiLoading > 0 || apiLoadingMap[GetSiteCache.requestType]) && <PageLoader className={`${namespace()}--page-loader`}/>}
        </ModalContent>
        <Row className={`${namespace()}--filter`}>
          <Column span={12} mobileSpan={12}>
            <Select
              disabled={false}
              hideOptional
              onChangeValue={this.onPageTypeChange}
              value={ActiveForm.PageTypeID}
              validationRules={ValidationRules.PageTypeID}
              isNumber />
            </Column>
            {showAdvancedFilter && ActiveForm.PageTypeID === 0 && this.renderAllPagesAdvancedFilter()}
            {showAdvancedFilter && ActiveForm.PageTypeID === PageTypeIDValue.BLOG_ARTICLE && this.renderBlogArticleAdvancedFilter()}
            {showAdvancedFilter && ActiveForm.PageTypeID === PageTypeIDValue.CALENDAR_EVENT && this.renderCalendarEventAdvancedFilter()}
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredPageSelector = makeFilteredPagesSelector();
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    pageTypeModal: state.adminCMSSite.pages.modals.pageType,
    filteredPages: filteredPageSelector(state),
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    cacheTwoSiteMenus: state.adminCMSSite.cacheTwoSiteMenuItems,
    cacheTwoPageMenus: state.adminCMSSite.cacheTwoPageMenuItems,
    menuItemForm: state.adminCMSSite.menus.siteMenuItem.form,
    rollback: state.rollback,
    filterInvalid: state.adminCMSSite.pages.modals.pageType.filterInvalid,
  };
};
const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators({
  ...actionCreators,
  ...appActionCreators,
  ...adminCMSCacheOneActionCreators,
  ...siteMenuItemFormActionCreators,
}, dispatch) });
const ConnectedSelectPageModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(SelectPageModal);

export default ConnectedSelectPageModal;
