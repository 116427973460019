import * as React from 'react';
import {
  Button,
  CartIcon,
  Column,
  ContentBlock,
  ContextMenu,
  ContextMenuInfo,
  ContextMenuItem,
  Row,
  Text,
  Title,
  View
} from '../../../../Elements';
import {namespace as ns} from './index';
import '../../../../../styles/pages/events/event/registration/registration.scss';
import {EventRegistrationNumbers} from "../../../../../models/api/cacheThreeEvents";
import {Actions} from "../../../../../store/Events/Event/Registration/actions";
import {Actions as CacheThreeActions} from "../../../../../store/CacheThreeEvents/actions";
import {Actions as CacheTwoActions} from "../../../../../store/CacheTwoEvents/actions";
import {Actions as CacheOneActions} from "../../../../../store/CacheOne/actions";
import {Actions as EventTypeActions} from "../../../../../store/Events/EventType/actions";
import {Actions as RollbackActions} from "../../../../../store/Rollback/actions";
import {Actions as EventActions} from "../../../../../store/Events/Event/Main/actions";
import {Actions as AppActions} from "../../../../../store/App/actions";
import { generateDOMId } from '../../../../../utils/cypressHelper';

export const namespace = (): string => `${ns()}--registration`;

interface Props {
  eventType: 'numbers' | 'participant' | 'both';
  actions: Actions & CacheThreeActions & CacheTwoActions & CacheOneActions & EventTypeActions & RollbackActions & EventActions & AppActions;
  registration: EventRegistrationNumbers;
  showCart: boolean;
  onManage: () => void;
  onReportInvoice: () => void;
  onReportParticipantRoster: () => void;
  onReportClassSchedule: () => void;
  onReportClassSchedulePerson: () => void;
  onReportRequirementsCompleted: () => void;
  onReportRequirementsScoutbook: () => void;
  onReportRequirementsCompletedPDF: () => void;
  onReportBlueCardsFront: () => void;
  onReportBlueCardsBack: () => void;
}


class Registration extends React.Component<Props, {}> {
  props: Props;

  render() {
    const {registration, showCart, onManage, eventType} = this.props;
    const {Campsites} = registration;
    // width: 260px;
    return (
      <ContentBlock>
        <Title
          className={`${namespace()}--title`}
          size={28} mobileSize={22}
          marginBottom={0}
          controls={
            <Row wrap={false}>
              <Column gutter={false} marginRight={16}>
                <Button id={generateDOMId("manage-registration-btn")} color="white" key={1} onClick={onManage}>
                  Manage
                </Button>
              </Column>
              <Column className={`${namespace()}--reports-column`}>
                <Button color="white" key={2} contextMenu={(
                  <ContextMenu title="Generate Reports"
                               info={<ContextMenuInfo>Reports will not include any changes that are still in the cart</ContextMenuInfo>}>
                    <ContextMenuItem onClick={this.props.onReportInvoice}>Invoice, PDF</ContextMenuItem>
                    {eventType !== 'numbers' && <ContextMenuItem onClick={this.props.onReportParticipantRoster}>Participant Roster, PDF</ContextMenuItem>}
                    {eventType !== 'numbers' && <ContextMenuItem onClick={this.props.onReportClassSchedule}>Class Schedule, PDF</ContextMenuItem>}
                    {eventType !== 'numbers' && <ContextMenuItem onClick={this.props.onReportClassSchedulePerson}>Class Schedule by Person, PDF</ContextMenuItem>}
                    {eventType !== 'numbers' && <ContextMenuItem onClick={this.props.onReportRequirementsCompleted}>Class Schedule, Excel/CSV</ContextMenuItem>}
                    {eventType !== 'numbers' && <ContextMenuItem onClick={this.props.onReportRequirementsCompletedPDF}>Requirements Completed, PDF</ContextMenuItem>}
                    {eventType !== 'numbers' && <ContextMenuItem onClick={this.props.onReportRequirementsCompleted}>Requirements Completed, Excel/CSV</ContextMenuItem>}
                    {eventType !== 'numbers' && <ContextMenuItem onClick={this.props.onReportRequirementsScoutbook}>Requirements for Scoutbook, Excel/CSV</ContextMenuItem>}
                    {eventType !== 'numbers' && <ContextMenuItem onClick={this.props.onReportBlueCardsFront}>Blue Cards Front, PDF</ContextMenuItem>}
                    {eventType !== 'numbers' && <ContextMenuItem onClick={this.props.onReportBlueCardsBack}>Blue Cards Back, PDF</ContextMenuItem>}
                  </ContextMenu>
                )}>
                  REPORTS
                </Button>
              </Column>
            </Row>
          }
          extra={showCart ? <CartIcon className={`${namespace()}--cart-icon`}/> : undefined}
        >
          Registration
        </Title>
      </ContentBlock>
    );

  }
}

export default Registration;
