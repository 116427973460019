
import {makeRequestEpic} from "../../Validation/epicCreator";
import {GetLocation} from "./actions";
import { getLocation, getLocationBody } from "../../../constants/urls";

export const adminSettingsCacheTwoLocationEpic = makeRequestEpic(
  GetLocation,
  getLocation,
  getLocationBody,
  true,
);