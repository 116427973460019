import * as React from 'react';
import moment from 'moment';
import { createMonth } from '../../../../utils';
import '../../../../styles/elements/month/standard/index.scss';
import { Loader } from '../../../Elements';
import {Availability, FacilitiesAvailabilitiesDate} from "../../../../models/api/cacheTwoFacilties";
import { extendMoment } from 'moment-range';
import {API_DATE_FORMAT} from "../../../../utils/dateHelper";
const momentRange = extendMoment(moment);

const namespace = (): string => 'elements--month';

export interface Props {
  month: moment.Moment;
  offset: number;
  availabilities?: Array<Availability>;
  availabilityDates: Array<FacilitiesAvailabilitiesDate>;
}

export const getAvail = (internalOffset, offset: number, weekIndex: number, dayIndex: number, isLoading: boolean, hidden: boolean, availabilities?: Array<Availability>) => {
  if (hidden) return ' placeholder';
  if (isLoading) return ' loading';
  const index = offset + (weekIndex * 7) + dayIndex - internalOffset;
  if (index < 0) {
    return '';
  }
  if (availabilities && index < availabilities.length) {
    const availID = availabilities[index].AvailID;
    if (availID === 1 || availID === 2) return ' available';
    if (availID === 3) return ' half-available';
    return '';
  }
  return ' loading';
};

const Month: React.SFC<Props> = ({ offset, month, availabilities, availabilityDates }: Props): React.ReactElement<Props> => {
  const weeks = createMonth(month);
  let internalOffset = 0;
  for (let i = 0; i < weeks[0].length; i++) {
    if (weeks[0][i].day === 1) break;
    internalOffset++;
  }
  let isLoading = false;
  if (!availabilities || !availabilityDates) {
    isLoading = true;
  } else {
    const startAvail = moment(availabilityDates[0].Date, API_DATE_FORMAT);
    const endAvail = moment(availabilityDates[availabilityDates.length-1].Date, API_DATE_FORMAT);
    const range = momentRange().range(startAvail, endAvail);
    if (!range.contains(month)) {
      isLoading = true;
    }
  }

  return (
    <div className={`${namespace()}`}>
      <div className={`${namespace()}--header`}>
        <span className={`${namespace()}--header--text`}>{month.format('MMM YYYY')}</span>
        {isLoading && <Loader size="16px" className={`${namespace()}--loader`}/>}
      </div>
      <div className={`${namespace()}--week-days`}>
        <ul className={`${namespace()}--week-days--list`}>
          <li className={`${namespace()}--week-days--list--item`}>S</li>
          <li className={`${namespace()}--week-days--list--item`}>M</li>
          <li className={`${namespace()}--week-days--list--item`}>T</li>
          <li className={`${namespace()}--week-days--list--item`}>W</li>
          <li className={`${namespace()}--week-days--list--item`}>T</li>
          <li className={`${namespace()}--week-days--list--item`}>F</li>
          <li className={`${namespace()}--week-days--list--item`}>S</li>
        </ul>
      </div>
      <div className={`${namespace()}--weeks`}>
        <ul className={`${namespace()}--weeks--list`}>
          {weeks.map((week, weekIndex) => (
            <li key={weekIndex} className={`${namespace()}--weeks--list--item`}>
              <ul className={`${namespace()}--weeks--list--item--days`}>
                {week.map((day, dayIndex) => {
                  const hidden = (weekIndex === 0 && day.day > 20) || (weekIndex >= weeks.length - 2 && day.day < 10);
                  return <li
                    key={day.date}
                    className={`${namespace()}--weeks--list--item--days--day ${getAvail(internalOffset, offset, weekIndex, dayIndex, isLoading, hidden, availabilities)}`}
                  >
                    {!hidden ? day.day : ''}
                  </li>;
                })}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Month;
export { default as MonthRange } from './Range';
