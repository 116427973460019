import {API_REQ} from '../../constants/request';
import {SAVE, createRequestActions} from '../Validation/actionCreator';
import { ActionCreator } from '../';
import { SaveState } from '../Rollback/actions';
import { typeName, Action } from '../../utils/StrongActions';
import {UPDATE_ADD_GROUP_FORM_SUFFIX} from '../AddGroup/constants';

export const SUFFIX = '__CACHE_ZERO_OPTIONS';
export const LOCATIONS_SUFFIX = SUFFIX + '__LOCATIONS';
export const GetOptions = createRequestActions(SUFFIX + UPDATE_ADD_GROUP_FORM_SUFFIX);
export const GetLocations = createRequestActions(LOCATIONS_SUFFIX);

const GET_ADMIN_OPTIONS = 'C0_GET_ADMIN_OPTIONS';
export const API_REQ_GET_ADMIN_OPTIONS = API_REQ + SAVE + GET_ADMIN_OPTIONS;

@typeName('CACHE_ZERO_UNSELECT_GROUP')
export class UnselectGroup extends Action {}

export type Actions = typeof actionCreators;

export const actionCreators = {
  getLocations: (routes?: any): ActionCreator => dispatch => {
    dispatch(new SaveState());
    // TODO: Later need to pass `skipUpdateForm`
    dispatch(GetLocations.request({}, {route: routes[routes.length - 1]}));
  },
};
