import * as Actions from './actions';
import { SUFFIX } from './actions';
import { createSelector } from 'reselect';
import {Group, Participant, AccountsEventRegistration, AccountsTrip, AccountsReservation, AccountsEventTypeEvents} from '../../../../models/api/options';
import { IValidator, FormDefinition } from './validation';
import { apiValidationReducerCreator } from '../../../Validation/reducerCreator';
import { LogoutSuccess } from '../../../../store/Session/actions';
import { APISuccess, WithRootState } from '../../../../store/Validation/actionCreator';
import { ApplicationState } from '../../../../store';
import { AdminFacilityType } from '../../../../models/api/adminFacilitiesCacheOne';
import { compareDataByRowNum } from '../../../../utils/dateHelper';
import { Action, isActionType } from '../../../../utils/StrongActions';
import { Reducer } from 'redux';
import {setDefaults} from '../../../../utils/validator';

const groupsSelector = state => {
  if (state.cacheZero.options) return state.cacheZero.options.Groups;
  return [];
};
const participantsSelector = (state: ApplicationState) => {
  return state.adminAccounts.Participants || [];
};
const filterSelector = state => state.adminAccounts.filterString;

export const makeFilteredGroupsSelector = () => {
  return createSelector(
    [groupsSelector, filterSelector],
    (groups: Array<Group>, filter: string) => {
      if (!filter || filter === '' || !groups) return groups ? groups.sort(compareDataByRowNum) : null;
      const f = filter.toLowerCase();
      return groups.filter(group => (group.Name.toLowerCase().includes(f) || group.ContactName.toLowerCase().includes(f) ||
        group.Email.toLowerCase().includes(f) || group.Username.toLowerCase().includes(f) ||
        group.UnitSponsor.toLowerCase().includes(f))
      ).sort(compareDataByRowNum);
    }
  );
};

export const makeFilteredParticipantsSelector = () => {
  return createSelector(
    [participantsSelector, filterSelector],
    (participants: Array<Participant>, filter: string) => {
      if (!filter || filter === '') return participants;
      const f = filter.toLowerCase();
      return participants.filter(participant => (participant.ParticipantName.toLowerCase().includes(f) || participant.GroupName.toLowerCase().includes(f)));
    }
  );
};

const registrationsSelector = (state: ApplicationState) => state.adminAccounts.AccountsEventRegistrations;
const registrationsSelectedEventIDSelector = (state: ApplicationState) => state.adminAccounts.ActiveForm.RegistrationsEventID;
const registrationsEventsSelector = (state: ApplicationState) => state.adminAccounts.AccountsEventTypeEvents;
export const makeFilteredRegistrationsSelector = () => {
  return createSelector(
    [registrationsSelector, registrationsSelectedEventIDSelector, filterSelector],
    (registrations: Array<AccountsEventRegistration>, eventID: number, filter: string) => {
      if (!registrations) return [];
      return registrations.filter((r) => {
        let passed = true;
        if (filter) {
          const f = filter.toLowerCase();
          passed = passed && r.GroupName.toLowerCase().includes(f);
        }

        return passed && (eventID === 0 || r.EventIDi === eventID);
      }).sort(compareDataByRowNum);
    },
  );
};
export const makeRegistrationSelectedEventSelector = () => {
  return createSelector(
    [registrationsEventsSelector, registrationsSelectedEventIDSelector],
    (events: Array<AccountsEventTypeEvents>, eventID: number) => {
      if (!events) return null;
      return events.find((e) => e.IDi === eventID);
    }
  );
};

const tripsSelector = (state: ApplicationState) => state.adminAccounts.AccountsTrips;
export const makeFilteredTripsSelector = () => {
  return createSelector(
    [tripsSelector, filterSelector],
    (trips: Array<AccountsTrip>, filter: string) => {
      if (!trips) return [];
      if (!filter) return trips.sort(compareDataByRowNum);
      return trips.filter((t) => {
        const f = filter.toLowerCase();

        return t.GroupName.toLowerCase().includes(f) || t.TripName.toLowerCase().includes(f);
      }).sort(compareDataByRowNum);
    },
  );
};

const reservationsSelector = (state: ApplicationState) => state.adminAccounts.AccountsReservations;
const reservationsSelectedFacilityTypeSelector = (state: ApplicationState) => state.adminAccounts.ActiveForm.ReservationsFacilityTypeID;
const accountsLocationFacilityTypesSelector = (state: ApplicationState) => state.adminAccounts.AccountsLocationFacilityTypes || [];
export const makeFilteredReservationsSelector = () => {
  return createSelector(
    [reservationsSelector, reservationsSelectedFacilityTypeSelector, filterSelector, accountsLocationFacilityTypesSelector],
    (reservations: Array<AccountsReservation>, facilityTypeID: number, filter: string, facilityTyes: AdminFacilityType[]) => {
      if (!reservations) return [];
      return reservations.filter((r) => {
        let passed = true;
        if (filter) {
          const f = filter.toLowerCase();
          const facilityType = facilityTyes.find((ft) => ft.ID === r.FacilityTypeID);
          passed = passed && (r.GroupName.toLowerCase().includes(f) || r.Facility.toLowerCase().includes(f) || (!!facilityType && facilityType.NamePlural.toLowerCase().includes(f)));
        }

        return passed && (facilityTypeID === 0 || facilityTypeID === r.FacilityTypeID);
      }).sort(compareDataByRowNum);
    },
  );
};

export interface AccountsState {
  isRefreshing: boolean;
  filterString: string;
  selectedTab: string;
  isTextSearching: boolean;
  ActiveForm: {
    // Participants
    Year?: number;
    EventTypeID?: number;
    EventTypeID_Final?: number;
    // Registrations
    RegistrationsYear?: number;
    RegistrationsEventTypeID?: number;
    RegistrationsEventTypeID_Final?: number;    // use this to determine SelectEventType Placeholder
    RegistrationsEventID?: number;
    // Trips
    TripsLocationID?: number;
    TripsLocationID_Final?: number;             // use this to determine SelectLocation Placeholder
    TripsYear?: number;
    // Reservations
    ReservationsLocationID?: number;
    ReservationsLocationID_Final?: number;    // use this to determine SelectLocation Placeholder
    ReservationsFacilityTypeID?: number;
    ReservationsYear?: number;
    ShowInactive?: boolean;
  };
  ValidationRules: IValidator;
  showFilterTabs: boolean;
  // For Participants Tab
  Participants?: Array<Participant>;
  // For Registrations Tab
  AccountsEventTypeEvents: Array<AccountsEventTypeEvents>;
  AccountsEventRegistrations: Array<AccountsEventRegistration>;
  // For Trips Tab
  AccountsTrips: Array<AccountsTrip>;
  // For Reservations Tab
  AccountsLocationFacilityTypes: Array<AdminFacilityType>;
  AccountsReservations: Array<AccountsReservation>;
}

const checkApiValidation = apiValidationReducerCreator(SUFFIX);
const getInitialState = () => ({
  isRefreshing: false,
  filterString: '',
  selectedTab: 'groups',
  isTextSearching: false,
  ActiveForm: {},
  ValidationRules: FormDefinition,
  showFilterTabs: true,
  AccountsEventTypeEvents: [],
  AccountsEventRegistrations: [],
  AccountsTrips: [],
  AccountsLocationFacilityTypes: [],
  AccountsReservations: [],
  Participants: [],
});

const initialState = getInitialState();

const AdminModalAccounts: Reducer<AccountsState> = (oldState = initialState, action: WithRootState<Action>) => {
  const state = checkApiValidation(oldState, action);
  if (isActionType(action, Actions.AdminAccountsFilter)) {
    return {...state, filterString: action.filterString};
  } else if (isActionType(action, Actions.AdminAccountsShowFilterTabs)) {
    return {...state, showFilterTabs: action.show, isTextSearching: !action.show};
  } else if (action.type === Actions.GetRegistrationsActions.successType) {
    const act = <WithRootState<APISuccess>> action;

    if (!act.response || !act.response.response || act.response.response.AccountsEventTypeEvents === undefined) {
      return state;
    }
    return {
      ...state,
      AccountsEventRegistrations: act.response.response.AccountsEventRegistrations,
      AccountsEventTypeEvents: act.response.response.AccountsEventTypeEvents,
      ActiveForm: {
        ...state.ActiveForm,
        RegistrationsEventTypeID_Final: state.ActiveForm.RegistrationsEventTypeID,
      },
    };
  } else if (action.type === Actions.GetTripsActions.successType) {
    const act = <WithRootState<APISuccess>> action;

    if (!act.response || !act.response.response || act.response.response.AccountsTrips === undefined) {
      return state;
    }
    return {
      ...state,
      AccountsTrips: act.response.response.AccountsTrips,
      ActiveForm: {
        ...state.ActiveForm,
        TripsLocationID_Final: state.ActiveForm.TripsLocationID,
      },
    };
  } else if (action.type === Actions.GetReservationsActions.successType) {
    const act = <WithRootState<APISuccess>> action;

    if (!act.response || !act.response.response || act.response.response.AccountsLocationFacilityTypes === undefined) {
      return state;
    }
    return {
      ...state,
      AccountsReservations: act.response.response.AccountsReservations,
      AccountsLocationFacilityTypes: act.response.response.AccountsLocationFacilityTypes,
      ActiveForm: {
        ...state.ActiveForm,
        ReservationsLocationID_Final: state.ActiveForm.ReservationsLocationID,
      },
    };
  } else if (action.type === Actions.GetParticipantsActions.successType) {
    const act = <WithRootState<APISuccess>>action;

    if (!act.response || !act.response.response || act.response.response.Participants === undefined) {
      return state;
    }
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        EventTypeID_Final: state.ActiveForm.EventTypeID,
      },
      Participants: act.response.response.Participants,

    };
  } else if (isActionType(action, Actions.AdminAccountsSelectTab)) {
    return {...state, selectedTab: action.tab};
  } else if (isActionType(action, Actions.AdminAccountsResetRegistrationsTab)) {
    return {
      ...state,
      AccountsEventRegistrations: [],
      AccountsEventTypeEvents: [],
      ActiveForm: {
        ...state.ActiveForm,
        RegistrationsEventTypeID_Final: -1,
      },
    };
  } else if (isActionType(action, Actions.InitAccountsModal)) {
    if (state.ActiveForm.RegistrationsEventID === undefined) {
      return setDefaults((action as WithRootState<Actions.InitAccountsModal>).rootState, state);
    }
    
    return state;
  } else if (isActionType(action, Actions.AdminAccountsResetTripsTab)) {
    return {
      ...state,
      AccountsTrips: [],
      ActiveForm: {
        ...state.ActiveForm,
        TripsLocationID_Final: -1,
      },
    };
  } else if (isActionType(action, Actions.AdminAccountsReservationsTab)) {
    return {
      ...state,
      AccountsReservations: [],
      AccountsLocationFacilityTypes: [],
      ActiveForm: {
        ...state.ActiveForm,
        ReservationsLocationID_Final: -1,
      },
    };
  } else if (isActionType(action, Actions.AdminAccountsResetParticipants)) {
    return {
      ...state,
      ActiveForm: {
        ...state.ActiveForm,
        EventTypeID_Final: -1,
      },
    };
  } else if (isActionType(action, LogoutSuccess)) {
    return getInitialState();
  }
  return state;
};

export default AdminModalAccounts;
