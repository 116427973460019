import * as React from 'react';
import '../../../../styles/elements/cms-location/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, ActionButton} from '../../index';
import { EditIcon, CloseIcon, PhoneIcon } from '../../../../components/Icons';
import { AdminCMSSiteCacheOneState } from '../../../../store/AdminCMSSite/CacheOne';
import { Location } from '../../../../models/api/adminCMSCacheOne';
import { SELECTED_ITEM_DELETED } from '../../../../constants/messages/generic';
import { Row, Column } from '../../Grid';
import { CardCategory } from '../../Card';
import { namespace as cardNS } from "../../Card/constants";
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--location--card';

export enum LocationCardType {
  IN_MODAL='in-modal',
  MODAL_SELECT='modal-select',
}
interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin';
  type: LocationCardType;
  location: Location;
  onEdit?: (location: Location) => void;
  onDelete?: (location: Location) => void;
  onCardClick?: (location: Location) => void;
  onRemove?: (location: Location) => void;
  adminCMSCacheOne?: AdminCMSSiteCacheOneState;
  showDeletedPrompt?: boolean;
  isFacilities?: boolean;
  disabled?: boolean;
  cardCategory?: CardCategory;
  allowInactive?: boolean;
}

const LocationCard: React.SFC<Props> = ({ children, type, template, onEdit, onDelete, location, onRemove, adminCMSCacheOne, onCardClick, allowInactive, showDeletedPrompt, isFacilities, disabled, cardCategory, ...props }: Props): React.ReactElement<Props> => {
  let className = attribute(namespace(), undefined, props);

  const onEditClick = (e) => {
    e.stopPropagation();
    onEdit && onEdit(location);
  };
  const onRemoveClick = (e) => {
    e.stopPropagation();
    onRemove && onRemove(location);
  };
  const renderForList = () => {
    return (
      <div className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--text`}>
          <div className={`${namespace()}--main--text--title`}>{location.Name}</div>
          <div className={`${namespace()}--main--text--subtitle`}>
            {!isFacilities ? location.Address : (location.NumFacilities ? `${location.NumFacilities} Facilit${location.NumFacilities > 1 ? 'ies' : 'y'}` : 'No Facilities')}
          </div>
        </div>
        <ActionButton
          id={generateDOMId(`location-${location.ID}-edit-btn-list`)}
          icon={EditIcon}
          onClick={onEditClick}
          className={`${namespace()}--edit ${cardNS()}--edit ${cardCategory}`}
        />
      </div>
    );
  };
  const renderSelected = () => {
    return (
      <Row className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--row`}>
          <div className={`${namespace()}--main--text`}>
            <div className={`${namespace()}--main--text--title`}>{location.Name}</div>
            <div className={`${namespace()}--main--text--subtitle`}>
              {location.Address}
            </div>
          </div>
          {!location.Inactive && onEdit ? <ActionButton
            disabled={disabled}
            icon={EditIcon}
            id={generateDOMId("admin-location-card-edit-btn")}
            onClick={onEditClick}
            className={`${namespace()}--edit ${cardNS()}--edit ${cardCategory}`}
          /> : null}
          {onRemove && <ActionButton
            disabled={disabled}
            icon={CloseIcon}
            onClick={onRemoveClick}
            className={`${namespace()}--close ${cardNS()}--close ${cardCategory}`}
          />}
        </div>
        {(location.PhoneNumber || location.FaxNumber) ? <Row marginTop={8}>
          {location.PhoneNumber ? <Column className={`${namespace()}--main--contact`} span={6} mobileSpan={12}>
            <div className={`${namespace()}--main--contact--icon`}><PhoneIcon /></div>
            <div className={`${namespace()}--main--contact--text`}>{location.PhoneNumber}</div>
            <div className={`${namespace()}--main--contact--note`}>Phone</div>
          </Column> : null}
          {location.FaxNumber ? <Column className={`${namespace()}--main--contact`} span={6} mobileSpan={12}>
            <div className={`${namespace()}--main--contact--icon`}><PhoneIcon /></div>
            <div className={`${namespace()}--main--contact--text`}>{location.FaxNumber}</div>
            <div className={`${namespace()}--main--contact--note`}>Fax</div>
          </Column> : null}
          </Row> : null}
      </Row>
    );
  };

  const deleted = !location || location.Inactive;
  className += ` ${type}`;
  if (showDeletedPrompt) className += ' show-deleted-prompt';
  if (location.Inactive && !allowInactive) className += ' deleted';

  const onClick = (e) => {
    if (location.Inactive) return;
    switch (type) {
      case LocationCardType.IN_MODAL:
        onCardClick && onCardClick(location);
        break;
      default:
        break;
    }
  };

  return (
    <Card
      className={className}
      component={<section/>}
      template={template}
      padding="none"
      category={cardCategory || CardCategory.LIST}
    >
      <div id={generateDOMId(`admin-location-card-${location.ID}`)} className={`${namespace()}--wrapper`}
          onClick={onClick}>
        {type === LocationCardType.IN_MODAL && renderForList()}
        {type === LocationCardType.MODAL_SELECT && renderSelected()}
      </div>
      {deleted && showDeletedPrompt && <section className={`${namespace()}--deleted-prompt`}>{SELECTED_ITEM_DELETED}</section>}
    </Card>
  );
};

LocationCard.defaultProps = {
  template: 'mobile-no-margin',
};

export default LocationCard;