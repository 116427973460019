import * as React from 'react';
import {RouteComponentProps, withRouter, WithRouterProps} from "react-router";
import { ApplicationState } from '../../../../../../store';
import { bindActionCreators } from 'redux';
import {
    actionCreators as adminCMSCacheOneActionCreators,
} from '../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../../store/App/actions';
import { actionCreators as adminCMSCacheTwoSiteMenusActions, GetSiteMenuItem} from "../../../../../../store/AdminCMSSite/CacheTwoSiteMenus/actions";
import { 
  actionCreators as rollbackActionCreators,
} from '../../../../../../store/Rollback/actions';
import { actionCreators, MenuItemSideModalNamespace as namespace } from "../../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form/actions";
import { actionCreators as pageFormActionCreators } from "../../../../../../store/AdminCMSSite/Pages/Page/Form/actions";
import { SideModal, SideModalContent, SideModalHeader, Loader, SideModalActions, Button } from '../../../../../../components/Elements';
import Form from './Form';
import { makeSelectedResourceSelector, makeSelectedEventCategorySelector } from '../../../../../../store/AdminCMSSite/Menus/SiteMenuItem/Form';
import '../../../../../../styles/pages/menus/site-menu-item/index.scss';
import { getSiteMenusRootUrl, constructCMSSiteUrlParams } from '../../../../../../constants/urls';
import { navPush } from '../../../../../../utils';
import '../../../../../../styles/pages/menus/site-menu-item/form.scss';
import { INVALID_MENU_ITEM } from '../../../../../../constants/messages/adminCMS';
import { NotFound } from '../../../../../../components/Pages';
import { CloseIcon } from '../../../../../../components/Icons';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { shouldReconfigRouter } from '../../../../../../utils/cacheLoaders/reloaderHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { isPathnameMatchingRoute } from '../../../../../../utils/urlHelper';
import { ADMIN_CMS_PAGE_PATH } from '../../../../../../routes';
import { noOpenedModals } from '../../../../../../utils/modalHelper';
import { reduxStoreService } from '../../../../../../store/service';
import {isAdminCMSCacheTwoSiteMenuItemPopulated} from '../../../../../../utils/cachePopulatedCheckers/adminCMS';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {menuItemId: string}>;

interface Props {
  type: 'add' | 'edit';
  parentContext?: 'edit-page';
  menuItemId?: number;
  onDelete?: (item: any) => void;
  onInactive?: () => void;
  onBack?: () => void;
  onShadowClick?: () => void;
}
class EditSiteMenuItem extends React.Component<Props, {}> {
  public props: ConnectedProps & Props;

  routerWillLeave = (nextLocation) => {
    // This function will not get triggered for SiteMenu under EditPage, because there's no
    // router change on closing the SiteMenu
    const {type, actions, adminCMSCacheTwoSiteMenuItems} = this.props;

    if (reduxStoreService().getState().app.apiLoading === 0) {
      if (type !== "edit" || isAdminCMSCacheTwoSiteMenuItemPopulated(adminCMSCacheTwoSiteMenuItems)) {
        actions.saveState();
      }
    }
  };

  componentDidUpdate(prevProps: ConnectedProps & Props) {
    const { pageForm } = this.props;
    if (shouldReconfigRouter(prevProps, this.props)) this.configRouter();
    if (!pageForm.ActiveForm.CMSPageSiteMenuItems) {
      this.props.onInactive && this.props.onInactive();
      return;
    }
    const item = pageForm.ActiveForm.CMSPageSiteMenuItems.find((item) => item.ID === this.props.menuItemId);

    if (!item || item.Inactive) {
      this.props.onInactive && this.props.onInactive();
    }
  }

  componentWillUnmount() {
    const {parentContext, adminCMSCacheTwoSiteMenuItems, type, actions} = this.props;
    if (!parentContext) {
      if (type !== "edit" || isAdminCMSCacheTwoSiteMenuItemPopulated(adminCMSCacheTwoSiteMenuItems)) {
        actions.clearAdminCMSCacheTwoSiteMenuItem();
      }
    }

    this.resetRouteLeaveHook();
  }

  private resetRouteLeaveHook() {
    const {router, routes, location} = this.props;

    const isInEditPage = isPathnameMatchingRoute(location.pathname, ADMIN_CMS_PAGE_PATH);

    if (!isInEditPage) {
      router.setRouteLeaveHook(routes[routes.length - 1], () => {});
    }
  }

  configRouter () {
    const {router, routes, location} = this.props;

    const isInEditPage = isPathnameMatchingRoute(location.pathname, ADMIN_CMS_PAGE_PATH);

    if (!isInEditPage) {
      router.setRouteLeaveHook(routes[routes.length - 1], this.routerWillLeave);
    }
  }

  componentDidMount() {
    this.props.actions.showAdminPageHeader(true);
    this.configRouter();
  }

  onSave = () => {
    if (!this.props.parentContext) {
      this.props.actions.apiSubmitMenuItemForm(this.props.router);
    } else {
      this.props.pageFormActions.submitSiteMenuItem(this.props.siteMenuItemForm.ActiveForm as any, this.props.pageForm.ActiveForm.ID || 0);
    }
  };
  onDelete = () => {
    if (!this.props.adminCMSCacheTwoSiteMenuItems.CMSSiteMenuItem) return;
    this.props.onDelete && this.props.onDelete(this.props.adminCMSCacheTwoSiteMenuItems.CMSSiteMenuItem);
  };
  onClickModal = (e) => {
    e && e.stopPropagation();
  };
  onBack = () => {
    if (this.props.onBack) {
      this.props.onBack();
      return;
    }
    if (!this.props.adminCMSCacheOne.CMSSite) return;
    this.props.actions.clearAdminCMSSiteCacheBelowOne();
    const backUrl = getSiteMenusRootUrl(constructCMSSiteUrlParams(this.props, this.props.adminCMSCacheOne));
    navPush(this.props.router, backUrl);
  };

  renderNotFound = () => {
    return (
      <NotFound showCMSSiteHeader inModal message={INVALID_MENU_ITEM}/>
    );
  };

  getPermission = () => {
    const {type, adminCMSCacheOne} = this.props;
    let enabled = false, reason;

    if (type === 'add') {
      if (adminCMSCacheOne.CMSSite) {
        enabled = adminCMSCacheOne.CMSSite.hasSiteMenuAdd;
        reason = adminCMSCacheOne.CMSSite.hasSiteMenuAddReason;
      }
    } else {
      if (adminCMSCacheOne.CMSSite) {
        enabled = adminCMSCacheOne.CMSSite.hasSiteMenuEdit;
        reason = adminCMSCacheOne.CMSSite.hasSiteMenuEditReason;
      }
    }
    return {enabled, reason};
  };

  renderContent = () => {
    const {selectedResource, selectedEventCategory, type, siteMenuItemForm: {ActiveForm, ValidationRules, SubmitErrorMessage}, actions, adminCMSCacheOne, parentContext, cacheZero} = this.props;

    const {enabled, reason} = this.getPermission();

    return [
      <SideModalContent lockBodyScroll key='site-menu-content'>
        <Form
          parentContext={parentContext}
          ActiveForm={ActiveForm}
          ValidationRules={ValidationRules}
          SubmitErrorMessage={SubmitErrorMessage}
          reduxActions={actions}
          disabled={!enabled}
          disabledReason={reason}
          action={type}
          cacheZero={cacheZero}
          adminCMSCacheOne={adminCMSCacheOne}
          selectedResource={selectedResource}
          selectedEventCategory={selectedEventCategory}
          selectedPage={ActiveForm.SelectedPage}
        />
      </SideModalContent>,
      <SideModalActions key='site-menu-actions'>
        {type === 'add' && <Button disabled={!enabled} flat textColor="black" onClick={this.onSave}>CREATE</Button>}
        {type === 'edit' && <Button id={generateDOMId("admin-site-menu-save-btn")} disabled={!enabled} flat textColor="black" onClick={this.onSave}>SAVE CHANGES</Button>}
        {type === 'edit' && !parentContext && <Button id={generateDOMId("site-menu-item-delete-btn")} disabled={!enabled} flat textColor="red" onClick={this.onDelete}>DELETE</Button>}
      </SideModalActions>
    ];
  };

  public render() {
    const {onShadowClick, apiLoadingMap, pageForm, adminCMSCacheTwoSiteMenuItems, type, parentContext, siteMenuItemForm: {ActiveForm}, adminCMSCacheTwoPage, adminCMSCacheOne } = this.props;
    const loading = (
      ((type === 'edit' && !parentContext) && !isAdminCMSCacheTwoSiteMenuItemPopulated(adminCMSCacheTwoSiteMenuItems, ActiveForm)) ||
      apiLoadingMap[GetSiteMenuItem.requestType]
    ) && noOpenedModals();

    let subtitle;

    if (!!adminCMSCacheTwoPage.CMSPage) {
      subtitle = !!pageForm.ActiveForm.Name ? pageForm.ActiveForm.Name : 'Edit Page';
    } else {
      subtitle = adminCMSCacheOne.CMSSite ? adminCMSCacheOne.CMSSite.SiteDomain : '';
    }

    const itemInactive = !adminCMSCacheTwoSiteMenuItems.CMSSiteMenuItem || adminCMSCacheTwoSiteMenuItems.CMSSiteMenuItem.Inactive;
    let content;

    if (type === 'add' || parentContext) {
      content = this.renderContent();
    } else {
      if (!isAdminCMSCacheTwoSiteMenuItemPopulated(adminCMSCacheTwoSiteMenuItems, ActiveForm)) {
        content = null;
      } else if (itemInactive) {
        content = this.renderNotFound();
      } else {
        content = this.renderContent();
      }
    }

    return (
      <SideModal
        allowShadowClickDefault
        className={`${namespace()}`}
        onShadowClick={onShadowClick}
        onClick={this.onClickModal}
        stickyHeader
        header={<SideModalHeader
                  className={`${namespace()}--header`}
                  onBack={this.onBack}
                  subtitle={subtitle}
                  onlyShowSubTitleInMobile
                  customizedBackIcon={!!adminCMSCacheTwoPage.CMSPage ? CloseIcon : null}
                  title={type === 'add' ? 'New Menu Item' : "Edit Menu Item"} />}
        >
          {content}
        {loading ? <Loader className={`${namespace()}--loader`} center /> : null}
      </SideModal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const selectedResourceSelector = makeSelectedResourceSelector();
  const selectedEventCategorySelector = makeSelectedEventCategorySelector();
  return {
    apiSaving: state.app.apiSaving,
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    adminCMSCacheTwoSiteMenuItems: state.adminCMSSite.cacheTwoSiteMenuItems,
    adminCMSCacheTwoPage: state.adminCMSSite.cacheTwoPage,
    cacheZero: state.cacheZero,
    pageForm: state.adminCMSSite.pages.page.form,
    siteMenuItemForm: state.adminCMSSite.menus.siteMenuItem.form,
    selectedResource: selectedResourceSelector(state),
    selectedEventCategory: selectedEventCategorySelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...actionCreators,
    ...rollbackActionCreators,
    ...appActionCreators,
    ...adminCMSCacheOneActionCreators,
    ...adminCMSCacheTwoSiteMenusActions,
  }, dispatch),
  pageFormActions: bindActionCreators({
    ...pageFormActionCreators,
  }, dispatch),
});

const ConnectedEditSiteMenuItem = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props & WithRouterProps>(),
)(EditSiteMenuItem);

export default withRouter<Props>(ConnectedEditSiteMenuItem);
