import type {ApplicationState} from "../../..";
import * as M from "../../../../constants/messages/generic";
import {PreviousOrderItem} from "../../../../models/api/cacheTwoPrevOrders";
import { toNumber } from "../../../../utils/dataHelper";
import { Validator } from "../../../../utils/validator/models";
import {findC3OrderItem, mergeItems, mergeOrder} from "./reducerHelpers";
import {isRefund} from "./uiHelpers";

type IValidator = {
  Notes: Validator;
  DatePayment: Validator;
  Amount: Validator;
  Save: Validator;
}
export const FormDefinition: IValidator = {
  Notes: {
    key: 'Notes',
    validatejs: {
      Notes: {
        length: {
          maximum: 1024,
          tooLong: M.S_TOO_LONG('Notes', 1024)
        }
      }
    }
  },
  DatePayment: {
    key: 'DatePayment',
    validatejs: {
      DatePayment: {
        presence: {message: M.S_REQUIRED('Date')},
        datetime: {
          notValid: M.S_INVALID_DATE('Date')
        }
      }
    }
  },
  // maybe rename this to Total Payment?
  Amount: {
    key: 'Amount',
    decimalOnly: true,
    allowNegative: () => true,
    validatejs: {
      Amount: {
        presence: {message: M.S_REQUIRED('Total Payment')},
        numericality: {
          notValid: M.S_INVALID('Total Payment')
        }
      }
    },
    customValidate: (rootState) => {
      const currentAmount = toNumber(rootState.settings.prevOrders.manageOrder.ActiveForm.Amount);
      const oldAmount = !rootState.cacheThreePrevOrders.PreviousOrder ? undefined : rootState.cacheThreePrevOrders.PreviousOrder.Amount;

      if (oldAmount !== undefined) {
        if (oldAmount < 0 && currentAmount > 0) {
          return 'The payment amount cannot be more than $0.';
        }
        if (oldAmount > 0 && currentAmount < 0) {
          return 'The payment amount cannot be less than $0.';
        }
      }

      //TODO - Not sure that we need this one
      // if (Math.abs(currentAmount) > Math.abs(oldAmount)) {
      //   return `The payment amount cannot be more than ${oldAmount}`;
      // }

      return undefined;
    }
  },

  // from https://github.com/mgroger/Camps/blob/master/org/echockotee/camps/views/ManageOrders.mxml#L443
  Save: {
    key: 'Save',
    customValidate: (rootState) => {
      const PreviousOrderItems = mergeItems(rootState, rootState.settings.prevOrders.manageOrder.ActiveForm);
      const PreviousOrder = mergeOrder(rootState, rootState.settings.prevOrders.manageOrder.ActiveForm);
      const OldPreviousOrder = rootState.cacheThreePrevOrders.PreviousOrder;
      const Refund = isRefund(rootState, PreviousOrder.PaymentTypeID);

      if (!checkOrderItemAmounts(rootState, PreviousOrderItems)) {
        return `Please verify the amounts being paid for the order items. The item amounts cannot be increased.`;
      } else if (PreviousOrder.NotApplied < 0) {
        return `The payment amount plus any applied credit is not enough to pay for the items in this order. Please either increase the payment amount or remove order items.`;
      } else if (Refund && PreviousOrder.Amount > 0) {
        return `The payment amount is too large for a refund. Please change this order to $0 if necessary and create another order to add a payment.`;
      } else if (!Refund && PreviousOrder.Amount < 0) {
        return `The payment amount is too small for a non-refund order. Please change this order to $0 if necessary and create another order to add a refund.`;
      }
    }
  }
};



const checkOrderItemAmounts = (rootState: ApplicationState, OrderItems: Array<PreviousOrderItem> | null): boolean => {
  let itemAmount: number;
  if (OrderItems) {
    for (let i: number = 0; i < OrderItems.length; i++) {
      let temp: PreviousOrderItem = OrderItems[i];
      const c3Item = findC3OrderItem(rootState, temp.ID);
      if (!c3Item) return false;
      itemAmount = c3Item.TotalAmount;
      if (temp.TotalAmount > itemAmount) {
        return false;
      }
    }
  }
  return true;
};
