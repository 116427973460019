// Spots
export const OVERBOOKED_PARTICIPANT_TOTAL = "There are not enough spots in the event for at least one type of participant in this registration. " +
  "Please review the overbooked spots and reduce your registration as necessary.";
export const CHANGE_TOO_LOW = "You've requested an overall reduction to your registration amount. " +
  "This reduction will require approval by the Camping Department. " +
  "Please either add additional participants or cancel your changes and contact the Camping Department " +
  "to make these changes.";
export const AT_LEAST_X_PARTICIPANT = (min) => `Please specify at least ${min} participants. If you need to register less participants`
+ ` than this minimum, please contact us directly. Please try again or cancel.`;
export const AT_LEAST_ONE_PARTICIPANT = "Please specify at least one participant. Please try again or cancel.";
export const NEW_EVENT_OVERBOOKED_AND_PTYPE = value =>
  `The event is also overbooked by ${value} participant${value !== 1?'s':''}, please reduce from any participant type.`;
export const NEW_EVENT_OVERBOOKED = value =>
  `There are not enough spots in the event for this registration. Please reduce your registration by ${value}` +
  ` participant${value !== 1?'s':''} and try again or cancel`;
export const PARTICIPANT_OVERBOOKED = 'There are one or more participants types that are overbooked. Please reduce the number of spots before saving.';
export const SPOTS_LESS_THAN_REGISTERED_PARTICIPANTS = (min: number, noPrefix?: boolean) => `${noPrefix? '' : '^'}Must have at least ${min} spot${min !== 1 ? 's' : ''} for the ${min} participants registered.`;
export const SPOTS_FULL_ERROR_MESSAGE = (num: number) => `FULL - Reduce the number of spots by ${num} before saving.`;

const co = (option?: boolean) => option ? 'option' : 'class';
const cos = (option?: boolean) => option ? 'options' : 'classes';

// Classes
export const SCHEDULE_NOT_FULL_PART = (option?: boolean) => (
  `This participant doesn't have a full schedule of ${cos(option)}. Please add additional ${cos(option)} before checking out.`
);
export const SCHEDULE_NOT_FULL_GROUP = (option?: boolean) => (
  `This event registration doesn't have a full schedule of ${cos(option)}. Please add additional ${cos(option)} before checking out.`
);
export const NO_CLASS_YET = (option?: boolean) => (
  `You haven't picked any ${cos(option)} yet.`
);
export const MUST_FULL = (option?: boolean) => (
  ` A full schedule of ${cos(option)} must be selected before checking out.`
);
export const ONE_UNAVAILABLE = (option?: boolean) => (
  `At least one ${co(option)} is unavailable. Please review the selected ${cos(option)} and remove any that are full or unavailable.`
);
export const CLASSES_MINIMUM_AGE_WARNING = (option?: boolean) => (
  `At least one ${co(option)} doesn't meet the minimum age requirements. Please review the selected ${cos(option)} and try again.`
);
// @todo: put age and other participant errors too. make sure to use option parameter


// Campsite
export const RANK_CAMPSITES = 'Please choose which campsite you would like on the Campsites step. Please choose an option for all three ranking fields.';
export const CAMPSITE_CRITICAL = "Campsite ranking is required; however, no campsites are currently available. Please contact the camp if you have any questions.";
export const CAMPSITE_OVERBOOKED = "Must be less than or equal to the Total Number of Participants";
export const CAMPSITE_OVERBOOKED_INLINE = "Total assigned spots must be less than or equal to the total number of participants";

// Confirmation
export const INVALID_PAYMENT = 'Please enter a valid value for pay other amount.';