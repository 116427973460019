import * as React from 'react';

import {Main, MainContent} from '../../../../../Layouts';
import {
  Alert,
  Align,
  Button,
  Card,
  CardTitle,
  Column,
  ContentBlock,
  DatePicker,
  Days,
  Label,
  NumberField,
  Row,
  Text
} from '../../../../../Elements';
import HeaderProgress from '../HeaderProgress';
import FooterProgress from '../FooterProgress';
import Moment from 'moment';
import * as Range from 'moment-range';
import {
  actionCreators,
} from '../../../../../../store/Facilities/Trip/Reservation/Reservation/actions';
import {
  actionCreators as overrideActionCreators,
} from '../../../../../../store/Facilities/Trip/Modals/ReservationOverrideFee/actions';
import {
  actionCreators as rollbackActionCreators,
} from '../../../../../../store/Rollback/actions';
import {
  actionCreators as appActionCreators,
} from '../../../../../../store/App/actions';
import {bindActionCreators} from 'redux';
import ReservationCard from './ReservationCard';
import {CacheFourFacilitiesState} from "../../../../../../store/CacheFourFacilities";
import {CacheZeroState} from "../../../../../../store/CacheZero";
import {CacheThreeFacilitiesState} from "../../../../../../store/CacheThreeFacilities";
import {Actions as CacheFourFacilitiesActions} from "../../../../../../store/CacheFourFacilities/actions";
import {CacheTwoFacilitiesState} from "../../../../../../store/CacheTwoFacilities";
import {REQUIRED} from "../../../../../../constants/messages/generic";
import {standardCurrencyFormat} from "../../../../../../utils/classesHelper";
import {checkPermission} from "../../../../../../utils/permissionHelper";
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { ApplicationState } from '../../../../../../store';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../utils/reduxHelper';
import { generateDOMId } from '../../../../../../utils/cypressHelper';
import { reduxStoreService } from '../../../../../../store/service';
import { Validator } from '../../../../../../utils/validator/models';
import { WithInertAttribute } from '../../../../../Elements/WithInert';
import { toNumber } from '../../../../../../utils/dataHelper';

const moment = (Range as any).extendMoment(Moment);

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type Props = WithInertAttribute<{
  apiLoadingMap: any;
  parentActions: CacheFourFacilitiesActions;
  cacheZero: CacheZeroState;
  cacheTwoFacilities: CacheTwoFacilitiesState;
  cacheThreeFacilities: CacheThreeFacilitiesState;
  cacheFourFacilities: CacheFourFacilitiesState;
  params: any;
}>

class Reservation extends React.Component<Props, {}> {
  public props: Props & ConnectedProps;

  private _endDateTime: any;

  public componentDidMount() {
  }

  shouldReservationChange = (startRules: Validator, endRules: Validator) => {
    // validatejs can't handle this case, so we're doing it manually
    // get latest values
    if (startRules.errors) {
      if (startRules.errors[0] === REQUIRED && endRules.errors && endRules.errors[0] === REQUIRED) {
        return true;
      } else if (startRules.errors[0] !== REQUIRED) {
        return false;
      }
    }
    if (endRules.errors) {
      if (endRules.errors[0] !== REQUIRED) {
        return false;
      }
    }
    return true;
  };

  getResId = (): number | undefined => {
    const {params} = this.props;
    if (params && params.resId) {
      return Number(params.resId);
    }
    return undefined;
  };

  updateYouthOrAdultValue = (
    v,
    validationRule: Validator,
  ) => {
    const {
      actions,
      cacheFourFacilities: {FacilityAvailabilities, Reservation},
      cacheThreeFacilities: {FacilityTrip},
      reservation: {ValidationRules},
    } = this.props;
    if (!Reservation || !FacilityAvailabilities || !FacilityTrip) return;
    if (this.shouldReservationChange(ValidationRules.StartDateTime, ValidationRules.EndDateTime)) {
      // We don't do any save state before the request here because we are relying on the following save state to ensure the save point
      // 1. The one in the else block below when dont send out a new request
      // 2. The one dispatched when reservationChange is successful
      actions.updateValue(v, validationRule);
      actions.reservationChange(Reservation.Type.ID, FacilityAvailabilities, FacilityTrip, undefined, this.getResId());
    } else {
      actions.updateValue(v, validationRule);
      actions.silentCancelAll(false, true);
      actions.saveState();
    }
  };

  onYouthChange = (value) => {
    const {reservation: {ValidationRules} } = this.props;
    let v;
    if (value || value === 0) v = parseInt(value);

    this.updateYouthOrAdultValue(
      isNaN(v) ? 0 : v,
      ValidationRules.NumYouth,
    );
  };

  onAdultChange = (value) => {
    const {reservation: {ValidationRules} } = this.props;
    let v;
    if (value || value === 0) v = parseInt(value);

    this.updateYouthOrAdultValue(
      isNaN(v) ? 0 : v,
      ValidationRules.NumAdults,
    );
  };

  onStartChange = (value) => {
    const {actions, reservation: {ValidationRules, ActiveForm}, cacheFourFacilities: {FacilityAvailabilities, Reservation}, cacheThreeFacilities: {FacilityTrip} } = this.props;

    if (!Reservation || !FacilityAvailabilities || !FacilityTrip) return;
    
    actions.updateValue(value, ValidationRules.StartDateTime);
    // we don't want to do below if updateValue caused an error, pull from state directly to inspect the latest value
    const latestStartRules = reduxStoreService().getState().facilities.trip.reservation.reservation.ValidationRules.StartDateTime;
    let latestEndRules = reduxStoreService().getState().facilities.trip.reservation.reservation.ValidationRules.EndDateTime;


    // if start date > end date, or if they are the same when IsCheckoutNextDay is true
    // we clear end date, and update latestEndRules, in order to trigger reservationChange
    const shouldDoReservationChangeForEndDate = (
      ActiveForm.EndDateTime && typeof ActiveForm.EndDateTime !== 'string' &&
      value && typeof value !== 'string' && (value as Moment.Moment).isAfter(ActiveForm.EndDateTime)) ||
      (ActiveForm.EndDateTime && FacilityAvailabilities && FacilityAvailabilities.IsCheckoutNextDay && ActiveForm.EndDateTime.isSame(value)
    );

    // We don't do any save state before the request here because we are relying on the following save state to ensure the save point
    // 1. The one in the else block below when dont send out a new request
    // 2. The one dispatched when reservationChange is successful
    if (shouldDoReservationChangeForEndDate) {
      actions.updateValue(undefined, ValidationRules.EndDateTime);
      latestEndRules = reduxStoreService().getState().facilities.trip.reservation.reservation.ValidationRules.EndDateTime;
    }

    // If no request is we do a save state after update. Otherwise, we do a save state before so that it could rollback
    // to previous value
    if (this.shouldReservationChange(latestStartRules, latestEndRules)) {
      actions.reservationChange(Reservation.Type.ID, FacilityAvailabilities, FacilityTrip, true, this.getResId());
    } else {
      actions.silentCancelAll(false, true);
      actions.saveState();
    }
  };

  onEndChange = (value) => {
    const {
      actions, reservation: {ValidationRules, ActiveForm}, cacheFourFacilities: {FacilityAvailabilities, Reservation},
      cacheThreeFacilities: {FacilityTrip}
    } = this.props;

    if (!Reservation || !FacilityAvailabilities || !FacilityTrip) return;

    actions.updateValue(value, ValidationRules.EndDateTime);
    // we don't want to do below if updateValue caused an error, pull from state directly to inspect the latest value
    const latestStartRules = reduxStoreService().getState().facilities.trip.reservation.reservation.ValidationRules.StartDateTime;
    const latestEndRules = reduxStoreService().getState().facilities.trip.reservation.reservation.ValidationRules.EndDateTime;

    const isNewEndDateBeforeStartDate = (
      ActiveForm.StartDateTime && typeof ActiveForm.StartDateTime !== 'string' &&
      value && typeof value !== 'string' && (value as Moment.Moment).isBefore(ActiveForm.StartDateTime)
    );
    if (isNewEndDateBeforeStartDate) {
      actions.updateValue(undefined, ValidationRules.StartDateTime);
      actions.silentCancelAll(false, true);
      actions.saveState();
    } else if (this.shouldReservationChange(latestStartRules, latestEndRules)) {
      // We don't do any save state before the request here because we are relying on the following save state to ensure the save point
      // 1. The one in the if block above and else block below when dont send out a new request
      // 2. The one dispatched when reservationChange is successful
      actions.reservationChange(Reservation.Type.ID, FacilityAvailabilities, FacilityTrip, undefined, this.getResId());
    } else {
      actions.silentCancelAll(false, true);
      actions.saveState();
    }
  };

  onSelectDays = (oldStart, oldEnd) => {
    // const start = Moment(oldStart);
    // const end = Moment(oldEnd);
    // const {actions, reservation: {ValidationRules}, selectedFacilityType, cacheFourFacilities: {FacilityAvailabilities}, cacheThreeFacilities: {FacilityTrip} } = this.props;
    // actions.updateValue(start, ValidationRules.StartDateTime);
    // actions.updateValue(end, ValidationRules.EndDateTime);
    // // we don't want to do below if updateValue caused an error, pull from state directly to inspect the latest value
    // const latestStartRules = reduxStoreService().getState().facilities.trip.reservation.reservation.ValidationRules.StartDateTime;
    // const latestEndRules = reduxStoreService().getState().facilities.trip.reservation.reservation.ValidationRules.EndDateTime;
    // if (this.shouldReservationChange(latestStartRules, latestEndRules)) {
    //   actions.reservationChange(selectedFacilityType.ID, FacilityAvailabilities!, FacilityTrip!, undefined, this.getResId());
    // }
  };

  onOverride = () => {
    const {
      actions, cacheFourFacilities: {Reservation, FacilityAvailabilities, TripPaymentStatus},
      cacheThreeFacilities: {FacilityTripPermissions}
    } = this.props;

    if (!Reservation || !FacilityAvailabilities || !FacilityTripPermissions) return;
    checkPermission(
      () => {
        actions.pushModal(
          ModalTypes.RESERVATION_OVERRIDE_FEE,
          false,
          true,
          {
            reservation: Reservation,
            facilityAvailabilitiesName: FacilityAvailabilities.Name,
            accommodation: Reservation.Type.NameSingular,
            tripPaymentStatus: TripPaymentStatus,
          },
        );
      },
      FacilityTripPermissions.hasAllowOverride,
      FacilityTripPermissions.hasAllowOverrideReason
    );
  };

  public render() {
    const {
      user, actions, cacheFourFacilities,
      cacheThreeFacilities: {FacilityTrip}, apiLoadingMap,
      cacheFourFacilities: {Reservation}, inert,
      reservation: {showMore, ActiveForm, ValidationRules, SubmitErrorMessage, recalcSuccess}
    } = this.props;
    if (!Reservation || !FacilityTrip || !cacheFourFacilities.FacilityAvailabilities || !cacheFourFacilities.FacilityAvailabilitiesDates) return null;

    const range = moment.range(FacilityTrip.StartDateTime, FacilityTrip.EndDateTime);

    let total = '$0';
    if (!recalcSuccess) {
      total = 'Calculating...';
    } else if (ActiveForm.StartDateTime && ActiveForm.EndDateTime) {
      total = standardCurrencyFormat(Reservation.Amount || 0);
    }

    const NumYouth = toNumber(ActiveForm?.NumYouth);
    const NumAdults = toNumber(ActiveForm?.NumAdults);
    let youthLeft = `${FacilityTrip.NumYouth - NumYouth} of ${FacilityTrip.NumYouth} left`;
    let adultsLeft = `${FacilityTrip.NumAdults - NumAdults} of ${FacilityTrip.NumAdults} left`;

    const disableOverride = !ActiveForm.StartDateTime || !ActiveForm.EndDateTime
      || !!ValidationRules.StartDateTime.errors || !!ValidationRules.EndDateTime.errors;
    return (
      <Main
        inert={inert}
        mobileBackground="white"
        footer={<FooterProgress selected="reservation"/>}
      >
        <MainContent
          mobileCardsFull
          header={<HeaderProgress selected="reservation"/>}
        >
          <ReservationCard
            facilityOrReservation={cacheFourFacilities.FacilityAvailabilities}
            showMore={showMore}
            onShowMore={actions.showMore}
            selectedFacilityType={Reservation.Type}
            Reservation={Reservation}
          />

          <div className="reservation-reservation"></div>
          {SubmitErrorMessage && <Row key="alert">
            <Alert>{SubmitErrorMessage}</Alert>
          </Row>}

          <Card template="mobile-stretch">
            <ContentBlock>
              <CardTitle>Reservation</CardTitle>
              <Row marginBottom={16} layout="vertical">
                <Label>
                  Choose Dates:
                </Label>
                <Days
                  StartDateTime={ActiveForm.StartDateTime}
                  EndDateTime={ActiveForm.EndDateTime}
                  avail={cacheFourFacilities.FacilityAvailabilities}
                  range={range}
                  availabilities={cacheFourFacilities.FacilityAvailabilitiesDates}
                  onSelect={this.onSelectDays}
                />
              </Row>
              <Row marginBottom={16} mobileMarginBottom={0}>
                <Column mobileMarginBottom={16} span={6} mobileSpan={12} layout="vertical" verticalAlignment="top">
                  <DatePicker
                    marginBottom={4}
                    label="Start Date"
                    value={ActiveForm.StartDateTime}
                    validationRules={ValidationRules.StartDateTime}
                    onSelect={this.onStartChange}
                    onChangeRaw={actions.simpleUpdate}
                    minDate={ValidationRules.StartDateTime.validatejs.StartDateTime.datetime.earliest}
                    maxDate={ValidationRules.StartDateTime.validatejs.StartDateTime.datetime.latest}
                  />
                </Column>
                <Column mobileMarginBottom={16} span={6} mobileSpan={12} verticalAlignment="top">
                  <DatePicker
                    marginBottom={4}
                    label="End Date"
                    value={ActiveForm.EndDateTime}
                    validationRules={ValidationRules.EndDateTime}
                    onSelect={this.onEndChange}
                    onChangeRaw={actions.simpleUpdate}
                    minDate={ValidationRules.EndDateTime.validatejs.EndDateTime.datetime.earliest}
                    maxDate={ValidationRules.EndDateTime.validatejs.EndDateTime.datetime.latest}
                  />
                </Column>
              </Row>
              <Row>
                <Column span={6}>
                  <NumberField
                    label="Youth"
                    labelWarning={youthLeft}
                    labelBig
                    value={ActiveForm.NumYouth}
                    validationRules={ValidationRules.NumYouth}
                    minimum={ValidationRules.NumYouth.validatejs.NumYouth.numericality.greaterThan + 1}
                    maximum={FacilityTrip.NumYouth}
                    onChange={this.onYouthChange}
                    noNegative
                  />
                </Column>
                <Column span={6}>
                  <NumberField
                    label="Adults"
                    labelWarning={adultsLeft}
                    labelBig
                    value={ActiveForm.NumAdults}
                    validationRules={ValidationRules.NumAdults}
                    minimum={ValidationRules.NumAdults.validatejs.NumAdults.numericality.greaterThan + 1}
                    maximum={FacilityTrip.NumAdults}
                    onChange={this.onAdultChange}
                    noNegative
                  />
                </Column>
              </Row>
            </ContentBlock>
            <Row>
              <Column span={6}><Text weight="bold" size={18}>Total</Text></Column>
              <Column span={6} horizontalAlignment="right"><Text weight="bold" size={18}>{total}</Text></Column>
            </Row>
            {user.user && user.user.str_permissions.hasAdminAccess && <Row marginTop={16}>
              <Align right>
                <Button
                  id={generateDOMId("reservation-override-btn")}
                  disabled={disableOverride}
                  color="white"
                  textColor="black"
                  admin
                  onClick={this.onOverride}
                >
                  OVERRIDE
                </Button>
              </Align>
            </Row>}
          </Card>
        </MainContent>
      </Main>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    reservation: state.facilities.trip.reservation.reservation,
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...overrideActionCreators,
    ...actionCreators,
    ...appActionCreators,
    ...rollbackActionCreators,
  }, dispatch)
});

const ConnectedReservation = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(Reservation);

export default ConnectedReservation;
