import { RouteComponentProps } from "react-router";
import { reduxStoreService } from "../../../store/service";

/**
 * Helper function to see if we should block actions when components
 * are re-rendered
 */
export const shouldBlockActions = () => {
  const rootState = reduxStoreService().getState();

  const {apiSaving, apiLoading, isRouterChangeRollbackFinished} = rootState.app;
  const {isRollbackJustFinished} = rootState.rollback;

  // Block when `isRollbackJustFinished` is true, because we dont want to do anything until rollback
  // is completed. This is enforced in `<ComponentUpdateTemplate />` by
  // - not running data clear/load logic when this function returns true
  // - not updating the internal state in that component when this function returns true
  // - runs data clear/load logic when we see browser url/modal state has changed

  if (isRollbackJustFinished) return true;

  // Ensure that rollback through router is atomic, and don't do anything
  if (!isRouterChangeRollbackFinished) return true;

  // Don't do anything if a save is occurring! Delay until it is finished
  if (apiSaving > 0 || apiLoading > 0) return true;

  return false;
};

/**
 * Close wizard on load when we see a pop action from router.
 * 
 * `action === 'POP'` covers both direct visit and browser back cases, where we
 * both want to close the wizard on load
 * 
 * We have been including 'REPLACE' action before, but decide to remove it because
 * that can only happen on rollback, and is triggered by us on purpose, meaning we
 * know we do NOT want to close wizard in that case. Also, users can never trigger
 * a 'REPLACE' action
 */
export const shouldCloseWizardOnLoad = (
  location: RouteComponentProps<{}, {}>["location"],
) => {
  return location.action === 'POP';
};