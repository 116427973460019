import * as React from 'react';
import { default as SideBar, SideBarProps } from '../SideBar';
import '../../../../styles/layouts/main/right-side-bar/index.scss';

export interface RightSideBarProps extends SideBarProps {
  loading?: boolean
  hideEarly?: boolean;
}
const RightSideBar: React.SFC<RightSideBarProps> = (props: RightSideBarProps): React.ReactElement<RightSideBarProps> => (
  <SideBar {...props} side="right"/>
);

export default RightSideBar;
