import {ApiSubmitActionsNumbersSpots} from './actions';
import {eventNumbersCalc} from '../../../../../../constants/urls';
import {makeRequestEpic} from '../../../../../Validation/epicCreator';

export const numbersSpotsNext = makeRequestEpic(
  ApiSubmitActionsNumbersSpots,
  eventNumbersCalc,
  undefined,
  true
);
