import * as React from 'react';
import '../../../../styles/elements/facility-type/card/index.scss';
import { default as attribute, MarginProps } from '../../../../styles';
import {Card, Button, ActionButton} from '../../index';
import ContextMenuComponent from './ContextMenu';
import { AdminFacilityType } from '../../../../models/api/adminFacilitiesCacheOne';
import { OptionsIcon, EditIcon } from '../../../../components/Icons';
import { CardCategory } from '../../Card';
import { namespace as cardNS } from "../../Card/constants";
import { generateDOMId } from '../../../../utils/cypressHelper';

export const namespace = (): string => 'elements--facility-type--card';

export enum FacilityTypeCardType {
  FACILIT_TYPES_LIST='facility-types-list',
  NEW_FACILITY_MODAL='new-facility-modal',
}

interface Props extends MarginProps {
  children?: React.ReactNode;
  template?: 'mobile-stretch' | 'mobile-no-margin' | 'info' | 'mobile-full';
  type: FacilityTypeCardType;
  category: CardCategory;
  noBoxShadow?: boolean;
  selected?: boolean;
  facilityType: AdminFacilityType;
  onEdit?: (facilityType: AdminFacilityType) => void;
  onDelete?: (facilityType: AdminFacilityType) => void;
  onRestore?: (facilityType: AdminFacilityType) => void;
  onClick?: (facilityType: AdminFacilityType) => void;
  disabled?: boolean;
}


class FacilityTypeCard extends React.Component<Props> {
  onEditClick = (e) => {
    const {onEdit, facilityType} = this.props;
    
    e.stopPropagation();
    onEdit && onEdit(facilityType);
  };
  onDeleteClick = (e) => {
    const {onDelete, facilityType} = this.props;
    e.stopPropagation();
    !this.props.disabled && onDelete && onDelete(facilityType);
  };
  onRestoreClick = (e) => {
    const {onRestore, facilityType} = this.props;
    !this.props.disabled && e.stopPropagation();
    onRestore && onRestore(facilityType);
  };
  onCardClick = (e) => {
    const {type, facilityType, disabled} = this.props;

    if (facilityType.Inactive || disabled) return;

    switch (type) {
      case FacilityTypeCardType.FACILIT_TYPES_LIST:
        this.onEditClick(e);
        break;
      case FacilityTypeCardType.NEW_FACILITY_MODAL:
        this.props.onClick && this.props.onClick(facilityType);
        break;
    }
  };

  renderInList = () => {
    const {facilityType} = this.props;
    return (
      <div className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--name`}>{facilityType.NamePlural}</div>
        {!facilityType.Inactive && <ActionButton
          icon={OptionsIcon}
          id={generateDOMId(`facility-type-${facilityType.ID}-option`)}
          className={`${namespace()}--main--options ${cardNS()}--options`}
          contextMenu={<ContextMenuComponent
            facilityTypeId={facilityType.ID}
            onEdit={this.onEditClick}
            onDelete={this.onDeleteClick} />}
        />}
        {facilityType.Inactive && (
          <Button
            className={`${namespace()}--main--restore ${cardNS()}--restore`}
            onClick={this.onRestoreClick}
            flat
            textColor='red'>RESTORE</Button>
        )}
      </div>
    );
  };

  renderInNewFacilityModal = () => {
    const {facilityType, category} = this.props;
    return (
      <div className={`${namespace()}--main`}>
        <div className={`${namespace()}--main--name`}>{facilityType.NamePlural}</div>
        <ActionButton
          id={generateDOMId(`facility-type-edit-btn-${facilityType.ID}`)}
          icon={EditIcon}
          onClick={this.onEditClick}
          className={`${namespace()}--main--edit ${cardNS()}--edit ${category}`}
        />
      </div>
    );
  };

  render() {
    const {category, noBoxShadow, selected, template, facilityType, type} = this.props;
    let className = attribute(namespace(), undefined, this.props);

    if (facilityType.Inactive && type === FacilityTypeCardType.FACILIT_TYPES_LIST) className += ' deleted';
    className += ` ${type}`;

    return (
      <Card
        noBoxShadow={noBoxShadow}
        className={className}
        component={<section/>}
        selected={selected}
        template={template || 'mobile-no-margin'}
        category={category}
        padding="none"
      >
        <div id={generateDOMId(`admin-fac-facility-type-card-${facilityType.ID}`)} onClick={this.onCardClick}>
          {type === FacilityTypeCardType.FACILIT_TYPES_LIST ? this.renderInList() : null}
          {type === FacilityTypeCardType.NEW_FACILITY_MODAL ? this.renderInNewFacilityModal(): null}
        </div>
      </Card>
    );
  }
}

export default FacilityTypeCard;