import { ValidationResults } from "@tentaroo/core";

import type {ActionCreator, ApplicationState} from '../../../../../index';

import {actionCreators as appActionCreators} from "../../../../../App/actions";

import {SaveState} from "../../../../../Rollback/actions";
import {validateAll} from "../../../../../../utils";
import {PersonFormActiveForm} from "../../../../../../utils/personFormValidationRules";
import {deleteOrRestoreGroupRosterPersonBody, eventParticipantsRecalcBody} from "../../../../../../constants/urls";
import {EventRegisterParticipantSelectPage, EventRegisterParticipantSetPendingPage} from "../Main/actions";
import {
  createApiSubmitActions,
  createApiValidateActions,
  createSimpleUpdateValueMethod,
  createUpdateValueMethod,
  createValidateActions
} from '../../../../../Validation/actionCreator';
import {EventPersonActiveForm, SimplePerson} from "./index";
import {PostEventRegistrationClass} from "../../../../../../models/api/cacheFourEvents";
import {composeClassRegistrations} from "../../../../../CacheFourEventsNumbers/actions";
import {ParticipantTab} from "../Main";
import { typeName, Action } from '../../../../../../utils/StrongActions';
import { ModalTypes } from '../../../../../../utils/modalHelper';
import { captureTentarooErrorAndGetRequestPayload, TentarooDebugPayload, withTentarooDebugPayload } from '../../../../../../utils/dataHelper';

// @todo: some of these actions should be removed?
export const SUFFIX = '__PARTICIPANT_PERSON';
export const SUFFIX_DELETE = '__PARTICIPANT_ROSTER_DELETE';

export const ValidateActions = createValidateActions(SUFFIX);
export const ApiValidateActions = createApiValidateActions(SUFFIX);
export const ApiRosterSubmitActions = createApiSubmitActions(SUFFIX, true);
export const ApiDeleteRosterActions = createApiSubmitActions(SUFFIX_DELETE);

@typeName("EVENT_REGISTER_PARTICIPANT_ROSTER_SELECT_TAB") export class EventRegisterParticipantRosterSelectTab extends Action {
  constructor(public selectedTab: 'roster' | 'new_youth' | 'new_adult') { super(); }
}
@typeName(`INIT_DEFAULT${SUFFIX}`)
export class InitializeDefaultValues extends Action {}
@typeName(`RESET${SUFFIX}`)
export class Reset extends Action {}

export type Actions = typeof actionCreators;

const formCreator = (rootState: ApplicationState, ActiveForm: PersonFormActiveForm & EventPersonActiveForm) => {
  const options = rootState.cacheZero.options;
  const eventTypeID = rootState.cacheTwoEvents.eventTypeID;
  const eventID = rootState.cacheThreeEvents.eventID;
  const c4 = rootState.cacheFourEventsParticipants;
  let isYouth = true;

  if (c4.GroupRosterPerson) isYouth = c4.GroupRosterPerson.IsYouth;
  else {
    const selectedTab = rootState.events.event.register.participant.roster.selectedTab;
    if (selectedTab === 'new_youth') {
      isYouth = true;
    } else if (selectedTab === 'new_adult') {
      isYouth = false;
    }
  }

  const classRegistrations: Array<PostEventRegistrationClass> = composeClassRegistrations(c4.EventClassesIndividualRegistered);
  const route = rootState.routing.locationBeforeTransitions;
  const sp = route.pathname.split('/');
  const selectedTypeID = rootState.events.event.register.participant.type.selectedTypeID;

  let debugPayload: TentarooDebugPayload | undefined;
  if (!c4.EventRegistrationPerson) {
    debugPayload = captureTentarooErrorAndGetRequestPayload('c4.EventRegistrationPerson not available when submitting ParticipantRoster form');
  }
  if (!c4.EventRegistrationParticipantTypes) {
    debugPayload = captureTentarooErrorAndGetRequestPayload('c4.EventRegistrationParticipantTypes not available when submitting ParticipantRoster form');
  }
  if (!options) {
    debugPayload = captureTentarooErrorAndGetRequestPayload('options not available when submitting ParticipantRoster form');
  }
  if (!c4.EventRegistrationPaymentStatus) {
    debugPayload = captureTentarooErrorAndGetRequestPayload('c4.EventRegistrationPaymentStatus not available when submitting ParticipantRoster form');
  }
  if (!options?.Group) {
    debugPayload = captureTentarooErrorAndGetRequestPayload('Group not available when submitting ParticipantRoster form');
  }
  let HasMultiWeekDiscount = c4.EventRegistrationPerson?.HasMultiWeekDiscount;

  if (c4.EventRegistrationPerson?.ParticipantTypeID && c4.EventRegistrationPerson?.ParticipantTypeID > 0 && sp.length === 8) {
    const pType = c4.EventRegistrationParticipantTypes?.find(pt => !!c4.EventRegistrationPerson && pt.ID === c4.EventRegistrationPerson.ParticipantTypeID);
    if (!pType) {
      debugPayload = captureTentarooErrorAndGetRequestPayload('pType not found when roster recalc participant');
    } else {
      if (pType.HasMultiWeekDiscount !== undefined) {
        HasMultiWeekDiscount = pType.HasMultiWeekDiscount;
      }
    }
  } else if (selectedTypeID && selectedTypeID > 0) {
    const pType = c4.EventRegistrationParticipantTypes?.find(pt => pt.ID === selectedTypeID);
    if (!pType) {
      debugPayload = captureTentarooErrorAndGetRequestPayload('pType not found when roster recalc participant');
    } else {
      if (pType.HasMultiWeekDiscount !== undefined) {
        HasMultiWeekDiscount = pType.HasMultiWeekDiscount;
      }
    }
  }

  const payload = eventParticipantsRecalcBody(
    {
      GroupIDi: options?.Group?.IDi as any,
      GroupTS: options?.Group?.TS as any,
      EventTypeID: eventTypeID,
      EventIDi: eventID,
      GroupWeekIDi: c4.EventRegistrationPaymentStatus?.IDi as any,
      PersonIDi: c4.GroupRosterPerson ? c4.GroupRosterPerson.IDi : null,
      IsYouth: isYouth,
      ClassRegistrations: classRegistrations,
      EventRegistrationPerson: c4.EventRegistrationPerson as any,
      ParticipantTypeID: c4.EventRegistrationPerson?.ParticipantTypeID,
      ParticipantRegistrationIDi: c4.EventRegistrationPerson?.IDi ? c4.EventRegistrationPerson?.IDi : null,
      HasMultiWeekDiscount: HasMultiWeekDiscount as any,
      ParticipantTypes: !rootState.cacheThreeEvents.EventInfoParticipantTypes ? [] : rootState.cacheThreeEvents.EventInfoParticipantTypes.map((pType) => pType.ID),
    }
  );

  if (debugPayload) {
    return withTentarooDebugPayload(payload, debugPayload);
  } else {
    return payload;
  }
};

const deleteFormCreator = (rootState: ApplicationState, person: SimplePerson) => {
  const {cacheZero} = rootState;

  if (!cacheZero.options) {
    return captureTentarooErrorAndGetRequestPayload("cacheZero.options not available when submitting deleteParticipantRoster form");
  }

  if (!cacheZero.options.Group) {
    return captureTentarooErrorAndGetRequestPayload("Group not available when submitting deleteParticipantRoster form");
  }
  return deleteOrRestoreGroupRosterPersonBody(
    cacheZero.options.Group.IDi,
    cacheZero.options.Group.TS,
    person.PersonIDi,
    person.IsYouth,
    true
  );
};

export const actionCreators = {
  selectTab: (selectedTab: 'roster' | 'new_youth' | 'new_adult'): ActionCreator => dispatch => dispatch(new EventRegisterParticipantRosterSelectTab(selectedTab)),
  rosterSubmit: (page: ParticipantTab): ActionCreator => (dispatch, getState) => {
    // is always from next button
    dispatch(new SaveState());
    const {ActiveForm, isDirty} = getState().events.event.register.participant.roster;

    const validationResults: ValidationResults | undefined = validateAll(s => s.events.event.register.participant.roster);

    if (validationResults) {
      dispatch(ApiRosterSubmitActions.clientFailure(validationResults));
    } else {
      if (!isDirty) {
        dispatch(new EventRegisterParticipantSelectPage(page));
      } else {
        dispatch(ApiRosterSubmitActions.request(formCreator(getState(), ActiveForm), null));
        dispatch(new EventRegisterParticipantSetPendingPage(page));
      }
    }
  },
  updateValue: createUpdateValueMethod(ValidateActions, ApiValidateActions, (s) => s.events.event.register.participant.roster),
  simpleUpdate: createSimpleUpdateValueMethod(ValidateActions),
  initializeAddDefaultValues: (): ActionCreator => dispatch => dispatch(new InitializeDefaultValues()),
  reset: (): ActionCreator => dispatch => dispatch(new Reset()),
  deleteFromRoster: (person: SimplePerson): ActionCreator => (dispatch, getState) => {
    dispatch(appActionCreators.popModal(true, false, ModalTypes.DELETE_PERSON) as any);
    dispatch(ApiDeleteRosterActions.request(deleteFormCreator(getState(), person), null));
  },
};
