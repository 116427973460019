import {makeRequestEpic} from "../../../../../Validation/epicCreator";
import {eventParticipantsInit} from "../../../../../../constants/urls";
import {ApiTypeSubmitActions, ResetReservationType} from "./actions";
import {ApiRosterSubmitActions} from "../Roster/actions";
import {Observable} from "../../../../../../../node_modules/rxjs";
import { NoOp } from "../../../../../App/actions";
import { reduxStoreService } from "../../../../../service";

export const participantsTypeNext = makeRequestEpic(
  ApiTypeSubmitActions,
  eventParticipantsInit,
  undefined, // body is passed in as is in action.value
  true
);

export const participantsTypeResetEpic = action$ => {
  return action$.filter((a) => (
    a.type === ApiRosterSubmitActions.requestType
  ))
    .mergeMap((action) => {
      const lastIsYouth = reduxStoreService().getState().cacheFourEventsParticipants.lastIsYouth;
      const isYouth = action.form.IsYouth;
      if (lastIsYouth !== undefined && isYouth !== lastIsYouth) {
        return Observable.of(new ResetReservationType());
      }
      return Observable.of(new NoOp());
    });
};
