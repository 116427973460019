
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';

import {
  Modal, ModalHeader, ModalContent, ModalActions, Button, Row, Select, PageLoader, SimpleList, EmptyMessage
} from '../../../../../../Elements';
import { actionCreators } from '../../../../../../../store/AdminCMSSite/Contacts/Modals/MultipleContacts/actions';
import { actionCreators as adminCMSCacheTwoContactsActionCreators } from '../../../../../../../store/AdminCMSSite/CacheTwoContacts/actions';
import { actionCreators as adminCMSCacheOneActionCreators, GetSiteCache } from '../../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../../../store/App/actions';
import { actionCreators as pageFormActionCreators } from '../../../../../../../store/AdminCMSSite/Pages/Page/Form/actions';
import { actionCreators as rollbackActionCreators } from '../../../../../../../store/Rollback/actions';
import { ApplicationState } from '../../../../../../../store';
import '../../../../../../../styles/pages/contacts/modals/multiple-contacts/index.scss';
import ContactCard, { AdminCMSContactCardType } from '../../../../../../Elements/AdminContact/CMSCard';
import { makeFilteredContactsSelector } from '../../../../../../../store/AdminCMSSite/Contacts/Modals/MultipleContacts';
import { CMSContact } from '../../../../../../../models/api/adminCMSCacheOne';
import { extractRouteParams } from '../../../../../../../utils/urlHelper';
import { PeopleContactIcon } from '../../../../../../../components/Icons';
import { checkContactPermission } from '../../../../../../../utils/helpers/adminCMSPageHelper';
import { EmptyMessageType } from '../../../../../../../components/Elements/EmptyMessage';
import { ModalHeight } from '../../../../../../../components/Elements/Modal';
import { CardCategory } from '../../../../../../../components/Elements/Card';
import { activeContactsSelector } from '../../../../../../../store/AdminCMSSite/Contacts/Home';
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { Validator } from '../../../../../../../utils/validator/models';
import { shouldBlockActions } from '../../../../../../../utils/cacheLoaders/helpers/blockers';
import {WithInertAttribute} from '../../../../../../Elements/WithInert';

export const namespace = (): string => 'pages--cms--modals--multiple-contacts';

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{},{contactId: string}>;
type Props = WithInertAttribute<{}>;

@(withRouter as any)
class MultipleContactsModal extends React.PureComponent<Props, {}> {

  public props: Props & ConnectedProps;
  private modalContentRef;

  componentDidMount() {
    if (shouldBlockActions()) return;

    const {actions, pageForm} = this.props;

    actions.init(pageForm.ActiveForm.CMSPageContacts || []);
  }

  componentWillUnmount() {
    this.onClose();
  }

  onSearchOpen = () => {
    this.props.actions.toggleFilter(true);
  };
  onSearchBack = () => {
    if (this.props.multipleContactsForm.filterString) {
      this.scrollToTop();
    }
    this.props.actions.toggleFilter(false);
    this.props.actions.filterMultipleContacts('');
  };
  onAddClick = () => {
    checkContactPermission(
      () => {
        this.props.actions.pushFormModal(ModalTypes.CONTACT_FORM, false);
      },
      this.props.cacheZero,
      this.props.adminCMSCacheOne,
      undefined,
      true,
    );
  };

  onClose = () => {
    this.onSearchBack();
    this.props.actions.reset();
  };

  onSave = () => {
    this.onClose();
    this.props.pageFormActions.addContactsToPage(this.props.multipleContactsForm.ActiveForm.SelectedContactIds);
    this.props.actions.popModal(false, true, ModalTypes.MULTIPLE_CONTACTS);
  };
  onRefreshClick = () => {
    const {actions} = this.props;
    actions.saveState();
    actions.refresh(
      {
        GetContacts: true,
        MaxContactID: 0,
        ...extractRouteParams(this.props),
      },
      false,
    );
  };

  onContactEditClick = (contact: CMSContact) => {
    checkContactPermission(
      () => {
        this.props.actions.pushFormModal(ModalTypes.CONTACT_FORM, true, {id: contact.ID});
      },
      this.props.cacheZero,
      this.props.adminCMSCacheOne,
      contact.SiteID,
      true,
    );
  };
  onContactSelect = (contact: CMSContact) => {
    const {multipleContactsForm} = this.props;
    const contactIds = multipleContactsForm.ActiveForm.SelectedContactIds;

    const existedId = contactIds.findIndex((cId) => cId === contact.ID);
    const existed = existedId !== -1;
    this.props.actions.toggleContactSelect(!existed, contact.ID);
  };

  itemRenderer = (index) => {
    const {filteredContacts, multipleContactsForm} = this.props;
    const contact: CMSContact = filteredContacts[index];
    const contactIds = multipleContactsForm.ActiveForm.SelectedContactIds;

    const existedId = contactIds.findIndex((cId) => cId === contact.ID);
    return (
      <div className={`multiple_contact_${index}`} key={`multiple_contact_${contact.ID}`}>
        <ContactCard
          checked={existedId !== -1}
          onEdit={this.onContactEditClick}
          onMultipleSelect={this.onContactSelect}
          routes={this.props.routes}
          adminCMSCacheOne={this.props.adminCMSCacheOne}
          type={AdminCMSContactCardType.MULTIPLE_CONTACTS}
          cardCategory={CardCategory.LIST_MOBILE}
          key={`multiple_contacts_list_item_${contact.ID}`}
          contact={contact} />
      </div>
    );
  };

  onSelectAll = () => {
    const {filteredContacts} = this.props;
    this.props.actions.selectAll(filteredContacts.filter((c) => !!c).map((c) => c.ID));
  };

  onDeselect = () => {
    const {filteredContacts} = this.props;
    this.props.actions.deselect(filteredContacts.map((c) => c.ID));
  };

  scrollToTop = () => {
    if (this.modalContentRef) this.modalContentRef.scrollTop = 0;
  };

  onContactCategoryChange = (val: any, vObj: Validator) => {
    this.scrollToTop();
    this.props.actions.contactModalUpdateValue(val, vObj);
  };
  onSearchChange = (val: string) => {
    this.scrollToTop();
    this.props.actions.filterMultipleContacts(val);
  };

  renderEmptyMessage = () => {
    const {activeContacts, filteredContacts} = this.props;

    const message = activeContacts.length === 0 && filteredContacts.length === 0 ? 'No contacts available' : 'No contacts found';
    return (
      <EmptyMessage
        icon={PeopleContactIcon}
        type={EmptyMessageType.PAGE_MARGIN}
        iconHeight='96px'
        iconWidth='96px'
        fixedFontSize
        description={message}
        actions={<Button className={`${namespace()}--empty-message-btn`} color='white' textColor='black' onClick={this.onAddClick}>NEW CONTACT</Button>}
      /> 
    );
  };

  public render() {
    const { apiLoadingMap, filteredContacts, actions, activeContacts, pageForm, multipleContactsForm: { ActiveForm, ValidationRules, isTextSearching}, inert} = this.props;

    const title = 'Select Contacts';
    const loading = apiLoadingMap[GetSiteCache.requestType];
    const subtitle = pageForm.ActiveForm && pageForm.ActiveForm.Name ? pageForm.ActiveForm.Name : 'Edit Page';

    return (
      <Modal inert={inert} height={ModalHeight.HEIGHT_425} big mobileFullScreen className={namespace()} onClose={this.onClose}>
        <ModalHeader
          isSearching={isTextSearching}
          onAdd={this.onAddClick}
          onRefresh={this.onRefreshClick}
          onSearchOpen={this.onSearchOpen}
          onSearchChange={this.onSearchChange}
          onSearchBack={this.onSearchBack}
          subtitle={subtitle}
          className={`${namespace()}--header`}>
            {title}
        </ModalHeader>
        <Row className={`${namespace()}--filter`}>
          <Select
            disabled={false}
            hideOptional
            onChangeValue={this.onContactCategoryChange}
            value={ActiveForm.SelectedContactCategoryID}
            validationRules={ValidationRules.SelectedContactCategoryID}
            isNumber />
        </Row>
        <ModalContent
          refCallback={(ref) => this.modalContentRef = ref}
          paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0}
        >
          {!apiLoadingMap[GetSiteCache.requestType] && !(activeContacts.length === 0 || filteredContacts.length === 0) && <Row className={`${namespace()}--row-2`}>
            <SimpleList
              itemRenderer={this.itemRenderer}
              totalLength={filteredContacts ? filteredContacts.length : 0}
            />
          </Row>}
          {loading && <PageLoader className={`${namespace()}--page--loader`} />}
          {!apiLoadingMap[GetSiteCache.requestType] && (activeContacts.length === 0 || filteredContacts.length === 0) ? this.renderEmptyMessage() : null}
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button textColor='black' disabled={loading} flat onClick={this.onSave}>SAVE</Button>}
          right={<div className={`${namespace()}--right-actions`}>
            {ActiveForm.SelectedContactIds.length > 0 ? <Button textColor='black' disabled={loading} flat onClick={this.onDeselect}>DESELECT</Button> : null}
            <Button textColor='black' disabled={loading} flat onClick={this.onSelectAll}>SELECT ALL</Button>
          </div>}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const filteredContactsSelector = makeFilteredContactsSelector();
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    cacheZero: state.cacheZero,
    multipleContactsForm: state.adminCMSSite.contacts.modals.multipleContacts,
    pageForm: state.adminCMSSite.pages.page.form,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    activeContacts: activeContactsSelector(state),
    filteredContacts: filteredContactsSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...actionCreators,
    ...adminCMSCacheOneActionCreators,
    ...adminCMSCacheTwoContactsActionCreators,
    ...rollbackActionCreators,
  }, dispatch),
  pageFormActions: bindActionCreators({
    ...pageFormActionCreators,
  }, dispatch),
});

const ConnectedMultipleContactsModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<Props>(),
)(MultipleContactsModal);

export default ConnectedMultipleContactsModal;