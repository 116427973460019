import { GetEventTypeParams } from "../../../constants/adminEventsUrls";
import { CLEAR_CACHE_PREFIX, createRequestActions } from "../../Validation/actionCreator";
import { ActionCreator } from '../..';
import { HideDrawer, SilentCancelAllAction } from '../../../store/App/actions';
import { ADMIN_EVENT_TYPES_SUFFIX } from "../CacheOne/actions";
import { UPDATE_EVENT_TYPE_FORM_SUFFIX } from "../CacheOne/actions";
import { typeName, Action } from "../../../utils/StrongActions";


export const SUFFIX = ADMIN_EVENT_TYPES_SUFFIX + '__CACHE_TWO_EVENT_TYPE' + UPDATE_EVENT_TYPE_FORM_SUFFIX;
export const SUFFIX_SAVE = ADMIN_EVENT_TYPES_SUFFIX + '__CACHE_TWO_EVENT_TYPE_SAVING';

export const GetEventType = createRequestActions(SUFFIX);

@typeName(CLEAR_CACHE_PREFIX + SUFFIX) export class ClearAdminEventsCacheTwoEventType extends Action { }

export type Actions = typeof actionCreators;

export const actionCreators = {
  getGLAccount: (params: GetEventTypeParams): ActionCreator => dispatch => {
    dispatch(GetEventType.request(params));
  },
  refreshEventType: (params: GetEventTypeParams): ActionCreator => (dispatch, getState) => {
    if (getState().app.isDrawerOpen) dispatch(new HideDrawer());
    if (getState().app.apiLoading !== 0) dispatch(new SilentCancelAllAction());
    dispatch(GetEventType.request(params));
  },
  clearAdminEventsCacheTwoEventType: (): ActionCreator => dispatch => {
    dispatch(new ClearAdminEventsCacheTwoEventType());
  },
};