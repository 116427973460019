import * as React from 'react';
import Media from '../Media';
import { LocationsIcon, PhoneIcon } from '../../Icons';
import '../../../styles/elements/contact-card/index.scss';
import { default as attributes, MarginProps } from '../../../styles';
import { Button } from 'react-mdl';
import { isMobile } from '../../../utils/isMobile';

export const namespace = (): string => 'elements--contact-card';

export interface ContactCardProps extends MarginProps {
  className?: string;
  mapIsSelected?: boolean;
  contacts?: boolean;
  map?: boolean;
  multiple?: boolean;
  borderRadius?: number;
  onMapClick?: () => any;
  onContactsClick?: () => any;
  title: string;
  fullAddress: string;
}

const ContactCard: React.SFC<ContactCardProps> = (
  { borderRadius, onMapClick, onContactsClick, contacts, map, mapIsSelected, multiple, className, title, fullAddress, ...props }: ContactCardProps
): React.ReactElement<ContactCardProps> => {
  const style: React.CSSProperties = {};
  if (borderRadius !== null && borderRadius !== undefined) {
    style.borderTopRightRadius = `${borderRadius}px`;
    style.borderTopLeftRadius = `${borderRadius}px`;
    style.borderBottomRightRadius = `${borderRadius}px`;
    style.borderBottomLeftRadius = `${borderRadius}px`;
  }

  className = attributes(namespace(), className, props);

  return (
    <div className={className} style={style}>
      {multiple ? (
          <div className={`${namespace()}--info`}>
            <div className={`${namespace()}--info--address`}>Events have different locations.<br/>Select specific event to see its location.</div>
          </div>
        ) : (
          <div className={`${namespace()}--info`}>
            <h1 className={`${namespace()}--info--title`}>{title}</h1>
            <address className={`${namespace()}--info--address`}>{fullAddress}</address>
          </div>
        )
      }
      {contacts && <Button
        ripple={!isMobile}
        className={`${namespace()}--action`}
        onClick={onContactsClick}
      >
        <PhoneIcon width="20px" height="20px" className={`${namespace()}--action--icon`}/>
        <span className={`${namespace()}--action--text`}>Contacts</span>
      </Button>}
      {map && <Media mobile flex className={`${namespace()}--map-container`}>
        <Button
          ripple={!isMobile}
          className={`${namespace()}--action` + (mapIsSelected ? ' selected' : '')}
          onClick={onMapClick}
        >
          <LocationsIcon width="20px" height="20px" className={`${namespace()}--action--icon`}/>
          <span className={`${namespace()}--action--text`}>Map</span>
        </Button>
      </Media>}
    </div>
  );
};

ContactCard.defaultProps = {
  contacts: true,
  map: true
};

export default ContactCard;
