import * as moment from 'moment';
import type { Image } from './cacheOne';
import type { AdminFacilitiesAvailability } from './adminFacilitiesAvailability';

export interface FacilityLocation_FacilityType {
  ID: number;
  IsFacilityType: boolean;
  Name: string;
  NameSingular: string;
  Description: string;
  FacilityMapURL: string;
  IsCheckoutNextDay: boolean;
}

export interface FacilityLocation_Trip {
  GroupTripID: number;
  Name: string;
  StartDateTime: moment.Moment;
  EndDateTime: moment.Moment;
  InCart: number;
  NumAdults: number;
  NumYouth: number;
  TotalAmount: number;
  Balance: number;
  IsTripUpcoming: boolean;
}

export interface FacilityLocationPermissions {
  hasAllowNewTrip: boolean;
  hasAllowNewTripReason: string | null;
}

/**
 * Common fields between `FacilitiesAvailability` and `TripReservation`
 */
export type FacilityInfo = {
  FacilityID: number;
  FacilityTypeID: number;
  Name: string;
  Description: string;
  MaxAccommodation: number | null;
  MinBookingLength: number;
  MaxBookingLength: number;
  RequireEntireWeekend: boolean;
  RequireSundayStart: boolean;
  AllowOnlineFacilityBooking: boolean;
  ShowTimes: boolean;
  Pricing: Pricing;
  Color: string;
  Images: Array<Image>;
  Features: Array<Feature>;
}
export type FacilitiesAvailability = FacilityInfo & {
  ftbID: number | null;
  FacilityName: string;
  IsAllDay: boolean;
  IsCheckoutNextDay: boolean;
  CheckinTime: string;
  CheckoutTime: string;
  Availabilities: Array<Availability>;
}

/**
 * Predicate to check if an availability is from an end user facility.
 * 
 * One difference between an end user and admin facility availability is that end user
 * one contains `MaxAccommodation` field
 */
export function isEndUserFacilitiesAvailability(availability: AdminFacilitiesAvailability | FacilitiesAvailability): availability is FacilitiesAvailability {
  return "MaxAccommodation" in availability;
}

export interface Feature {
  ID: number;
  Name: string;
  IsAvailable: boolean;
}

export interface Pricing {
  FlatRate: number;
  FlatRateAmountMin: number;
  PerPersonRate: number;
  PerPersonRateAmountMin: number;
  MinimumPeopleBilledAtPerPersonRates: number;
}

export interface Availability {
  n: number;
  AvailID: number;
}

export interface FacilitiesAvailabilitiesDate {
  Date: string;
}

export {
  Image as FacilityImage,
};