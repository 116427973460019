
import * as React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import { bindActionCreators } from 'redux';
import {
  Modal, ModalHeader, ModalContent, ModalActions, Button
} from '../../../../../../Elements';
import '../../../../../../../styles/pages/cms-pages/modals/image-form/index.scss';
import { actionCreators, ImageFormModalNamespace as namespace, ImageResetForm } from '../../../../../../../store/AdminCMSSite/Pages/Modals/ImageForm/actions';
import { actionCreators as adminCMSCacheOneActionCreators } from '../../../../../../../store/AdminCMSSite/CacheOne/actions';
import { actionCreators as appActionCreators } from '../../../../../../../store/App/actions';
import { actionCreators as pageFormActionCreators } from '../../../../../../../store/AdminCMSSite/Pages/Page/Form/actions';
import { ApplicationState } from '../../../../../../../store';
import ImageForm from './Form';
import { INVALID_TOP_GALLERY_IMAGE, INVALID_BOTTOM_GALLERY_IMAGE } from '../../../../../../../constants/messages/adminCMS';
import { checkPagePermission } from '../../../../../../../utils/helpers/adminCMSPageHelper';
import { makeFormModalPropSelector } from '../../../../../../../store/App';
import { ModalTypes } from '../../../../../../../utils/modalHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../../../../../utils/reduxHelper';
import { captureTentarooError } from '../../../../../../../utils/dataHelper';
import { reduxStoreService } from '../../../../../../../store/service';
import { shouldBlockActions } from '../../../../../../../utils/cacheLoaders/helpers/blockers';
import { WithInertAttribute } from '../../../../../../Elements/WithInert';

type ConnectedProps = WithInertAttribute<
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{}, {}>
>;
@(withRouter as any)
class ImageFormModal extends React.PureComponent<ConnectedProps, {}> {

  public props: ConnectedProps;
  private editor: any;

  componentDidMount() {
    const {imageId, target, pageFormState} = this.props;

    if (shouldBlockActions()) return;

    if (imageId) {
      const image = target === 'top-gallery' ?
        pageFormState.ActiveForm.CMSPageGallery1.find((l) => l.ID === imageId) :
        pageFormState.ActiveForm.CMSPageGallery2.find((l) => l.ID === imageId);
      
      if (!image) {
        captureTentarooError(new Error(`Opening ImageForm for image ${imageId}, but it is not found in PageGallery. PageID: ${pageFormState.ActiveForm.ID}`));
        return;
      }
      this.props.actions.initForm(image);
    }
  }

  reset = () => {
  };

  componentWillUnmount() {
    if (this.editor && this.editor.getCommand("maximize").state === 1) {
      // if ckeditor is maximized, minimize it before unloading this page
      this.editor.execCommand('maximize');
    }
    this.onClose();
  }
  onSave = () => {
    const {actions, target, pageFormActions, imageFormState: {ValidationRules}, pageFormState} = this.props;

    const ckeditorData = !this.editor ? '' : this.editor.getData();
    if (ckeditorData) actions.simpleUpdate(ckeditorData, ValidationRules.Description);
    checkPagePermission(
      () => {
        pageFormActions.updateGalleryImage(target === 'top-gallery' ? 'top' : 'bottom');
      },
      this.props.adminCMSCacheOne,
      undefined,
      this.props.adminCMSCacheTwoPage,
      true,
      undefined,
    );
  };

  onImageInactive = () => {
    const {actions, target} = this.props;
    actions.showTopFloatingAlert(target === 'top-gallery' ? INVALID_TOP_GALLERY_IMAGE : INVALID_BOTTOM_GALLERY_IMAGE, true, 'orange');
  };

  onDelete = () => {
    const {target, actions, pageFormActions, imageId, pageFormState} = this.props;
    checkPagePermission(
      () => {
        if (imageId) {
          actions.popModal(true, false, ModalTypes.GALLERY_IMAGE_FORM);
          pageFormActions.deleteGalleryImage(target === 'top-gallery' ? 'top' : 'bottom', imageId);
        }
      },
      this.props.adminCMSCacheOne,
      undefined,
      this.props.adminCMSCacheTwoPage,
      true,
      undefined,
    );
  };

  onClose = () => {
    reduxStoreService().dispatch(new ImageResetForm());
  };

  onCKEditorReady = (eventInfo) => {
    if (this.editor !== eventInfo.editor) this.editor = eventInfo.editor;
  };

  public render() {
    const { pageFormState, inert, imageId, actions, imageFormState } = this.props;

    const title = imageId ? 'Edit Image' : 'Image Upload';
    const image = this.props.pageFormState.ActiveForm.CMSPageGallery1.find((l) => l.ID === this.props.imageId) || this.props.pageFormState.ActiveForm.CMSPageGallery2.find((l) => l.ID === this.props.imageId);
    const subtitle = pageFormState.ActiveForm && pageFormState.ActiveForm.Name ? pageFormState.ActiveForm.Name : 'Edit Page';

    return (
      <Modal
        inert={inert}
        onClose={this.onClose}
        wideModal big mobileFullScreen
        inactive={!image || image.Inactive}
        onInactive={this.onImageInactive}
        className={namespace()}>
        <ModalHeader
          className={`${namespace()}--header`}
          subtitle={subtitle}
        >{title}</ModalHeader>
        <ModalContent paddingLeft={0} paddingRight={0} paddingTop={0} paddingBottom={0} >
          <ImageForm
            inert={inert}
            action={imageId ? 'edit' : 'add'}
            namespace={namespace}
            SubmitErrorMessage={imageFormState.SubmitErrorMessage}
            ActiveForm={imageFormState.ActiveForm}
            ValidationRules={imageFormState.ValidationRules}
            image={image}
            reduxActions={actions}
            onCKEditorReady={this.onCKEditorReady}
          />
        </ModalContent>
        <ModalActions
          sticky
          notFixed
          left={<Button textColor='black' flat onClick={this.onSave}>{imageId ? 'SAVE CHANGES' : 'ADD IMAGE'}</Button>}
          right={imageId ? <Button textColor='red' flat onClick={this.onDelete}>{'DELETE'}</Button> : undefined}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  const imageIdSelector = makeFormModalPropSelector(ModalTypes.GALLERY_IMAGE_FORM, 'id');
  const imageTargetSelector = makeFormModalPropSelector(ModalTypes.GALLERY_IMAGE_FORM, 'target');
  return {
    apiLoading: state.app.apiLoading,
    apiLoadingMap: state.app.apiLoadingMap,
    apiSaving: state.app.apiSaving,
    pageFormState: state.adminCMSSite.pages.page.form,
    adminCMSCacheOne: state.adminCMSSite.cacheOne,
    adminCMSCacheTwoPage: state.adminCMSSite.cacheTwoPage,
    imageFormState: state.adminCMSSite.pages.modals.imageForm,
    imageId: imageIdSelector(state),
    target: imageTargetSelector(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...appActionCreators,
    ...actionCreators,
    ...adminCMSCacheOneActionCreators,
  }, dispatch),
  pageFormActions: bindActionCreators({
    ...pageFormActionCreators,
  }, dispatch),
});
const ConnectedImageFormModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<{}>(),
)(ImageFormModal);

export default ConnectedImageFormModal;