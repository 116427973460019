import * as React from 'react';
import '../../../styles/pages/events/camp-info.scss';
import {
  Button,
  CampInfo,
  CampInfoAction,
  CampInfoImage,
  CampInfoLeft,
  CampInfoRight,
  CampInfoSubTitle,
  CampInfoSynopsis,
  CampInfoTitle,
  CampInfoWarning,
  ContactCard,
  HeaderBlock,
  HeaderBlockPadding,
  Link,
  Map,
  Media,
  Row
} from '../../Elements';
import {bindActionCreators} from 'redux';
import {
  actionCreators as appActionCreators,
} from '../../../store/App/actions';
import {E_REGISTER, EVENT, getEventRegistrationUrl, getEventRootUrl} from '../../../constants/urls';
import {getImagePath} from '../../../utils';
import {RouteComponentProps, withRouter} from 'react-router';
import {navPush} from '../../../utils/navHelper';
import {PublicWebsiteIcon} from '../../Icons';
import LeadersGuideIcon from '../../../images/pages/events/event-type-info/leaders-guide.svg';
import {EventContactInfo, Image, Location} from "../../../models/api/cacheOne";
import {SystemSettings} from "../../../models/api/registerForm";
import {getFullAddress} from "../../../utils/addressHelper";
import ContactInfos from '../Facilities/ContactInfos';
import {isImageValid} from "../../../utils/imageHelper";
import {EventInfoPermissions} from "../../../models/api/cacheThreeEvents";
import {checkPermission} from "../../../utils/permissionHelper";
import {ApplicationState} from "../../../store";
import {setSentryContext} from "../../../config/sentry";
import { getAdminEventsEventDashboardUrl } from '../../../constants/adminEventsUrls';
import { captureTentarooError } from '../../../utils/dataHelper';
import { connect } from 'react-redux';
import { getMergeProps } from '../../../utils/reduxHelper';

const namespace = () => "event--camp-info";

export interface CampInfoProps {
  multiple?: boolean;
  registration?: boolean;
  warning?: string;

  title: string;
  subTitle?: string;
  onShowSidebar?: () => any;
  isAdmin?: boolean;

  eventType?: 'participant' | 'numbers' | 'both';

  campLocation: Location;
  contactInfo: EventContactInfo; // @todo: what type is this? probably make base contact info and have both extend
  image?: Image | null;
  systemSettings: SystemSettings;
  showMapMobile?: boolean;
  onShowMapMobile: () => void;
  description?: string;
  EventInfoPermissions?: EventInfoPermissions;
  HasGroupRegistration?: boolean;
  hideRegister?: boolean;
  showContactsComp?: React.ReactElement<any>;
}

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<{}, {eventTypeId?: string, eventId?: string, name?: string}>;


// @todo: maybe this CampInfo should be same as facilities campinfo? though they look different enough that they can not be the same
@(withRouter as any)
class CampInfoComponent extends React.Component<CampInfoProps, {}> {
  public props: CampInfoProps & ConnectedProps;

  componentDidCatch(error: Error, errorInfo) {
    setSentryContext();
    error.message += " Error occurred in component containing map - Events/CampInfoComponent";
    captureTentarooError(error);
    throw error;
  };

  onShowInAdmin = () => {
    const {router, params, cacheThreeEvents} = this.props;
    if (params.eventTypeId && params.eventId && cacheThreeEvents.EventInfo) {
      navPush(router, getAdminEventsEventDashboardUrl({
        eventTypeId: params.eventTypeId,
        eventId: Number(params.eventId),
        eventName: cacheThreeEvents.EventInfo.Name,
      }));
    }
  };

  onRegisterForEvent = () => {
    const {router, EventInfoPermissions, HasGroupRegistration, eventType} = this.props;

    if (!EventInfoPermissions) return;

    let rootUrl = getEventRootUrl(this.props);
    let registerUrl = `${rootUrl}/${EVENT.REGISTER}/`;
    if (eventType === 'participant') {
      registerUrl = registerUrl + E_REGISTER.PARTICIPANT;
      const onRegister = () => {
        navPush(router, registerUrl);
      };
      checkPermission(
        onRegister,
        EventInfoPermissions.hasRegistrationNames,
        EventInfoPermissions.hasRegistrationNamesReason
      );
    } else {
      registerUrl = registerUrl + E_REGISTER.NUMBERS;
      const onRegister = () => {
        navPush(router, registerUrl);
      };
      checkPermission(
        onRegister,
        EventInfoPermissions.hasRegistrationNumbersAdd,
        EventInfoPermissions.hasRegistrationNumbersAddReason
      );
    }
  };

  onMyRegister = () => {
    const {router} = this.props;
    navPush(router, getEventRegistrationUrl(this.props));
  };

  public render() {
    const {
      title, subTitle, eventType, warning, multiple, registration, isAdmin, image,
      contactInfo, showMapMobile, onShowMapMobile, campLocation, systemSettings, description,
      HasGroupRegistration, params, cacheThreeEvents, hideRegister, showContactsComp, EventInfoPermissions
    } = this.props;

    const buttonText = HasGroupRegistration ? 'MY REGISTRATION' : 'REGISTER FOR EVENT';
    const onRegister = HasGroupRegistration ? this.onMyRegister : this.onRegisterForEvent;
    const disableRegister = !HasGroupRegistration && !EventInfoPermissions;
    return (
      <HeaderBlock hasLeftSideBar>
        <HeaderBlockPadding mobilePadding={false}>
          <CampInfo>
            {isImageValid(image) && <CampInfoLeft>
              {isImageValid(image) && image && !multiple && <CampInfoImage imageUrl={getImagePath(image, "720px")}/>}
              {eventType && <Media tabletAndGreater>
                <CampInfoAction onClick={onRegister} disabled={disableRegister}>{buttonText}</CampInfoAction>
              </Media>}
            </CampInfoLeft>}
            <CampInfoRight
              alignWarning={registration ? "right" : undefined}
              warning={warning ? <CampInfoWarning size={registration ? 14 : undefined}>{warning}</CampInfoWarning> : undefined}
              title={<CampInfoTitle size={registration ? 22 : undefined}>{title}</CampInfoTitle>}
              subTitle={subTitle && <CampInfoSubTitle size={registration ? 14 : undefined}>{subTitle}</CampInfoSubTitle>}
              expand={isAdmin && registration}
              expandContainer={!!description && !image}
              registration={registration}
            >
              {description && <CampInfoSynopsis text={description} />}
              {(contactInfo.LeadersGuide || contactInfo.WebsiteURL || isAdmin) && <Row className={`${namespace()}--row`}>
                {(contactInfo.LeadersGuide || contactInfo.WebsiteURL) && <Row verticalAlignment="middle">
                  {contactInfo.LeadersGuide &&
                    <Link externalLink={contactInfo.LeadersGuide} size={14} icon={LeadersGuideIcon} marginRight={24}>
                      LEADER'S GUIDE
                    </Link>
                  }
                  {contactInfo.WebsiteURL &&
                    <Link externalLink={contactInfo.WebsiteURL} size={14} icon={PublicWebsiteIcon}>
                      WEBSITE
                    </Link>
                  }
                </Row>}
                {isAdmin &&
                  <Button onClick={this.onShowInAdmin} mobileMarginLeft={0} marginLeft="auto" mobileMarginTop={contactInfo.LeadersGuide || contactInfo.WebsiteURL ? 16 : 0} color="white" admin textColor="black">Go to Event</Button>
                }
              </Row>}
              {!hideRegister && eventType && !image && <CampInfoAction onClick={onRegister} disabled={disableRegister}>{buttonText}</CampInfoAction>}
              {!hideRegister && eventType && image && <Media mobile>
                <CampInfoAction onClick={onRegister} disabled={disableRegister}>{buttonText}</CampInfoAction>
              </Media>}
            </CampInfoRight>
          </CampInfo>
        </HeaderBlockPadding>
        {!registration ? (
            <Media mobile>
              {!multiple && <ContactCard
                contacts={false}
                mapIsSelected={showMapMobile}
                onMapClick={onShowMapMobile}
                title={campLocation.Name}
                fullAddress={getFullAddress(campLocation)}
              />}
              {showMapMobile ? <Map width="100%" height="240px" marginBottom={10} address={getFullAddress(campLocation)}/> : null}
              <HeaderBlockPadding padding="6px 0">
                <ContactInfos
                  contactInfoLabel="Event Contact"
                  location={campLocation}
                  contactInfo={contactInfo}
                  systemSettings={systemSettings}
                  multiple={multiple}
                />
              </HeaderBlockPadding>
            </Media>
          ) : null}
        {showContactsComp}
      </HeaderBlock>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => {
  return {
    cacheThreeEvents: state.cacheThreeEvents,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...appActionCreators }, dispatch)
});

const ConnectedCampInfoComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
  getMergeProps<CampInfoProps>(),
)(CampInfoComponent);

export default ConnectedCampInfoComponent;
