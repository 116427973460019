
export const cacheZeroKeys = {
  Login: true,
  ABAAccountTypes: true,
  ScoutTypes: true,
  LeaderScoutTypes: true,
  GradeOptions: true,
  ShirtSizes: true,
  RankOptions: true,
  CubRankOptions: true,
  StateOptions: true,
  PaymentTypes: true,
  EventPricingTypes: true,
  FacilityPricingTypes: true,

  GeneralPermissions: true,

  ClassTypes: true,
  GroupTypes: true,
  UnitTypes: true,
  UnitGenders: true,
  Councils: true,
  Districts: true,
  EventTypes: true,
  Groups: true,
  Locations: true,
  IsAdmin: true,
  GeneralOptions: true,
  AdminNavigationOptions: true,
  CMSSites: true,
  OAStatusTypes: true,

  EventTypeRegistrationSettings_Roster: true,
  EventTypeRegistrationMethodOptions: true,
  EventCategories: true,

  GLAccounts: true,
  ClassCompletionOptions: true,
  MeritBadges: true,
};