import * as M from '../../../../constants/messages/generic';
import { getTimePickerHourValidationRule, getTimePickerMinValidationRule, getTimePickerPeriodValidationRule } from "../../../../store/AdminCMSSite/Pages/Modals/NewPage/validation";
import { SelectValidator, Validator } from '../../../../utils/validator/models';

export interface IBlackoutDateValidator {
  StartDate: Validator;
  EndDate: Validator;
  StartHour: SelectValidator;
  StartMin: SelectValidator;
  StartPeriod: SelectValidator;
  EndHour: SelectValidator;
  EndMin: SelectValidator;
  EndPeriod: SelectValidator;
  Reason: Validator;
}

export const FacilityTypeBlackoutDateFormDefinition: IBlackoutDateValidator = {
  StartDate: {
    key: 'StartDate',
    isRequired: () => true,
    validatejs: {
      StartDate: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          notValid: `^${M.INVALID_DATE}`,
        }
      },
    },
  },
  EndDate: {
    key: 'EndDate',
    isRequired: () => true,
    validatejs: {
      EndDate: {
        presence: {message: '^' + M.REQUIRED},
        datetime: {
          notValid: `^${M.INVALID_DATE}`,
        }
      },
    },
  },
  StartHour: {
    ...getTimePickerHourValidationRule('StartHour'),
    defaultValue: () => 13,
  },
  StartMin: getTimePickerMinValidationRule('StartMin'),
  StartPeriod: {
    ...getTimePickerPeriodValidationRule('StartPeriod'),
    defaultValue: () => 1,
  },
  EndHour: {
    ...getTimePickerHourValidationRule('EndHour'),
    defaultValue: () => 11,
  },
  EndMin: getTimePickerMinValidationRule('EndMin'),
  EndPeriod: {
    ...getTimePickerPeriodValidationRule('EndPeriod'),
  },
  Reason: {
    key: 'Reason',
    isRequired: () => true,
    validatejs: {
      Reason: {
        presence: {message: '^' + M.REQUIRED},
        length: {
          maximum: 150,
          tooLong: M.MAX_LENGTH(150)
        },
      },
    },
  },
};