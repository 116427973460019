import {reduxStoreService} from "../../../../service";
import {RegistrationMethodType} from "../../../CacheOne";
import {makeSelectedRegistrationMethodSelector} from "./selectors";

export const isJustNames = (rootState = reduxStoreService().getState()) => {
  const selectedRegistrationMethodSelector = makeSelectedRegistrationMethodSelector(true);
  const method = selectedRegistrationMethodSelector(rootState);

  return !!method && method.ID === RegistrationMethodType.JUST_NAMES;
};

export const isJustNumbers = (rootState = reduxStoreService().getState()) => {
  const selectedRegistrationMethodSelector = makeSelectedRegistrationMethodSelector(true);
  const method = selectedRegistrationMethodSelector(rootState);

  return !!method && method.ID === RegistrationMethodType.JUST_NUMBERS;
};

export const isUsingNames = (rootState = reduxStoreService().getState()) => {
  const selectedRegistrationMethodSelector = makeSelectedRegistrationMethodSelector(true);
  const method = selectedRegistrationMethodSelector(rootState);

  return !!method && (method.ID === RegistrationMethodType.JUST_NAMES || method.ID === RegistrationMethodType.NUMBERS_AND_NAMES);
};

export const isUsingNumbers = (rootState = reduxStoreService().getState()) => {
  const selectedRegistrationMethodSelector = makeSelectedRegistrationMethodSelector(true);
  const method = selectedRegistrationMethodSelector(rootState);

  return !!method && (method.ID === RegistrationMethodType.JUST_NUMBERS || method.ID === RegistrationMethodType.NUMBERS_AND_NAMES);
};