import {List, AutoSizer, WindowScroller} from 'react-virtualized';
import * as React from 'react';
import '../../../styles/elements/virtual-list/index.scss';
import { CellMeasurer, CellMeasurerCache } from "react-virtualized";
import { isMobileAndSmallerScreenSize } from '../../../utils/isMobile';
import { shallowEqual } from '../../../utils';

export const namespace = (): string => 'elements--virtual-list';

type Data = any;
export interface VirtualListProps {
  rowCount: number;
  rowRenderer: Function;
  rowHeight?: number;
  mobileRowHeight?: number;
  overscanRowCount?: number;
  dynamicHeight?: boolean;
  minHeight?: number;
  keyMapper?: (rowIndex: number, colIndex: number) => any;
  forwardListRef?: (ref) => void;
  scrollElement?: any;
  className?: string;
  dynamicCellClassName?: string;
  list: Data[];
}

/**
 * Better to use this component with `<VirtualListWrapper />`, see more comments
 * in that component
 */
class VirtualList extends React.Component<VirtualListProps> {
  private _windowScroller?: WindowScroller;
  private _cache: any;
  private _listRef: any;
  private previousWidth: number;

  constructor(props) {
    super(props);

    const {dynamicHeight, keyMapper, minHeight} = this.props;

    if (dynamicHeight) {
      this._cache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight,
        keyMapper,
      });
    }
  }

  componentDidMount() {
    if (this.props.dynamicHeight) {
      window.addEventListener("resize", this.onResize);
    }
  }

  componentWillUnmount() {
    if (this.props.dynamicHeight) {
      window.removeEventListener("resize", this.onResize);
    }
  }

  onResize = (e) => {
    const currentWidth = window.innerWidth;
    let shouldForceUpdate = false;
    if (this.previousWidth) {
      if (currentWidth < 640) {
        shouldForceUpdate = true;
      } else if (currentWidth >= 640 && currentWidth < 732 && (this.previousWidth < 640 || this.previousWidth >= 732)) {
        shouldForceUpdate = true;
      } else if (currentWidth >= 732 && currentWidth < 960 && (this.previousWidth < 732 || this.previousWidth >= 960)) {
        shouldForceUpdate = true;
      } else if (currentWidth >= 960 && currentWidth < 1080 && (this.previousWidth < 960 || this.previousWidth >= 1080)) {
        shouldForceUpdate = true;
      } else if (currentWidth >= 1080 && this.previousWidth < 1080) {
        shouldForceUpdate = true;
      }

      if (shouldForceUpdate && this._listRef) {
        this._cache.clearAll();
      }
    }
    this.previousWidth = currentWidth;
  };

  componentDidUpdate(prevProps: VirtualListProps) {
    const {list, dynamicHeight} = this.props;
    if (this._listRef) {
      this._listRef.forceUpdateGrid();
    }
    
    if (dynamicHeight && !shallowEqual(prevProps.list, list)) {
      this._cache.clearAll();
    }
  }

  dynamicHeightRenderer = (row) => {
    const {rowRenderer, dynamicCellClassName} = this.props;
    const {index, key, parent, style} = row;

    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}>
          {({measure, registerChild}) => {
            return (
              <div className={dynamicCellClassName} style={style} ref={registerChild}>{rowRenderer(row, measure)}</div>
            );
          }}
        </CellMeasurer>
    );
  };

  _setRef = windowScroller => {
    this._windowScroller = windowScroller;
  };
  
  render() {
    const {overscanRowCount, scrollElement, forwardListRef, rowRenderer, dynamicHeight, mobileRowHeight, rowHeight, className, rowCount} = this.props;

    return (
      <WindowScroller scrollElement={scrollElement} ref={this._setRef}>
        {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => {
          return (
            <div className={namespace()}>
              <AutoSizer
                disableHeight={true}
              >
                {({width: _width}) => {
                  return (
                    <div ref={registerChild}>
                      <List
                        className={`${namespace()}--list${className ? className : ""}`}
                        autoHeight
                        height={height || 100}
                        isScrolling={isScrolling}
                        onScroll={onChildScroll}
                        scrollTop={scrollTop}
                        ref={(ref) => {
                          this._listRef = ref;
                          if (forwardListRef) {
                            forwardListRef(ref);
                          }
                        }}
                        rowCount={rowCount}
                        rowHeight={dynamicHeight ? this._cache.rowHeight : (isMobileAndSmallerScreenSize() ? mobileRowHeight : rowHeight)}
                        rowRenderer={dynamicHeight ? this.dynamicHeightRenderer : rowRenderer}
                        overscanRowCount={overscanRowCount || 5}
                        width={_width}
                      />
                    </div>
                  );
                }}
              </AutoSizer>
            </div>
          );
        }}
      </WindowScroller>
    ); 
  }
}

export default VirtualList;